import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { EmailCommandModel } from "../../components/email/shared";
import { ItinerarEmailCommandModel, ItineraryPriceDetailModel } from "../../models/order-model";
import { ItineraryEmailModel } from "../../models/order-model/order-itinerary";

@Injectable({
    providedIn: 'root'
})
export class OrderItineraryService extends ApiServiceBase {
    private readonly PATH: string = "/order/orderItinerarys"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getItineraryPriceDetailByOrderTransport(orderId: string): Observable<ItineraryPriceDetailModel[]> {
        return this.httpGet<ItineraryPriceDetailModel[]>(this.PATH + "/price-detail/" + orderId + "/transport", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getItineraryEmailFile(command: ItinerarEmailCommandModel): Observable<EmailCommandModel> {
        return this.httpPost<EmailCommandModel>(this.PATH + "/order-itinerarys/file", command)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public sendItineraryEmail(command: ItinerarEmailCommandModel) {
        return this.httpPost(this.PATH + "/order-itinerarys", command)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public GetItineraryDetail(command: ItinerarEmailCommandModel): Observable<ItineraryEmailModel> {
        return this.httpPost<ItineraryEmailModel>(this.PATH + "/order-itinerarys/detail", command)
            .pipe(
                map(response => {
                    console.log(response.body);
                    return response.body;
                })
            );
    }
}
import {
    AfterViewInit, ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import {
    OrganisationGroupReferenceModel,
    OrganisationModel,
    OrganisationRoleReferenceModel,
    OrganisationTypeReferenceModel
} from 'src/app/core/models/organisation-model';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { ProductInventoryDimensionCommandModel, ProductInventoryDimensionViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import {
    ProductRestrictionLocationViewModel,
    ProductRestrictionOrganisationViewModel,
    ProductRestrictionProductNumberViewModel,
    ProductRestrictionProductViewModel,
    ProductRestrictionRouteViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import {
    SpecialServiceAddCommandModel,
    SpecialServiceEditCommandModel,
    SpecialServiceViewModel
} from 'src/app/core/models/product-model/specialservice-model';
import { SpecialServiceInventoryViewModel } from 'src/app/core/models/product-model/specialservice-model/specialservice-inventory-view.model';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    CountryReferenceModel,
    DateTimeDimensionReferenceModel,
    DomainAttributeModel,
    HashTagReferenceModel,
    LocationGroupModel,
    RegionReferenceModel,
    StatusReferenceModel,
    UsageTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel,
    ProductTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-product-model';
import {
    DomainAttributeService,
    HashTagReferenceService,
    LanguageReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService
} from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { SpecialServiceService } from 'src/app/core/services/product-services';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service/product-type-group.service';
import {
    CalendarValidityService,
    ConditionReferenceService,
    CountryReferenceService,
    DateTimeDimensionReferenceService,
    OrganisationTypeReferenceService,
    ProductCategoryReferenceService,
    ProductGroupReferenceService,
    ProductLocationPointReferenceService,
    ProductLocationTypeReferenceService,
    ProductNumberTypeReferenceService,
    StatusReferenceService,
    UsageTypeReferenceService
} from 'src/app/core/services/system-services';
import { SeasonReferenceService } from 'src/app/core/services/system-services/season-reference.service';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import {
    CancelButtonModel,
    CopyButtonModel,
    DeleteButtonModel,
    NewButtonModel,
    RefreshButtonModel,
    SaveButtonModel
} from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { TreeMapperService } from '../../../shared/mapper/tree-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { InventoryAttributeComponent } from './inventory-attribute/inventory-attribute.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryDirective } from './inventory/inventory.directive';
import { InventoryView } from './inventory/views/inventory.view';
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from 'src/app/core/services/reference-service/reference-media-service';
import { MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MapperService } from '../special-service-search/shared/mapper.service';
import { MediaViewModel } from 'src/app/core/models/media-model';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';
import { InventoryMapperService } from './inventory/shared/inventory-mapper.service';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationMediaMapperService } from 'src/app/core/components/translation-media/shared/translation-media-mapper.service';
import { TranslationTextMapperService } from 'src/app/core/components/translation-text/shared/translation-text-mapper.service';
import { TranslationTextComponent } from 'src/app/core/components/translation-text/translation-text.component';
import { TranslationMediaComponent } from 'src/app/core/components/translation-media/translation-media.component';
import { TextMapperService } from 'src/app/core/components/text/shared/text-mapper.service';
import { UIService } from './shared/ui.service';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { InventoryAttributeMapperService } from '../../../shared/mapper/inventory-attribute-mapper.service';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { SpecialServiceAttributeComponent } from '../special-service-attribute/special-service-attribute.component';
import { TextMediaComponent } from 'src/app/core/components/text-media/text-media.component';
import { ProductTypeReferenceService } from 'src/app/core/services/reference-service/reference-product-service';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { UntypedFormBuilder } from '@angular/forms';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActivityDomainComponent } from 'src/app/core/components/activity-domain/activity-domain.component';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { LanguageReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { HttpClient } from '@angular/common/http';
import { PointOfSalesModel, RestrictionModel, ValidityModel } from 'src/app/core/models/merchandizing-config';

@Component({
    selector: 'op-special-service-detail',
    templateUrl: './special-service-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TreeMapperService,
        ProductTypeGroupService,
        HashTagMapperService,
        Select2DataMapperService,
        AttributeMapperService,
        InventoryAttributeMapperService,
        NumberMapperService,
        PointOfSalesMapperService,
        RestrictionMapperService,
        ValidityMapperService,
        MapperService,
        MediaMapperService,
        InventoryMapperService,
        TranslationMediaMapperService,
        TranslationTextMapperService,
        TextMapperService,
        UIService,
        TranslationNameMapperService,
        ActionbarService,
        AlertBarService
    ]
})
export class SpecialServiceDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly SERVICE_PRODUCT_CATE_CODE = 'SERVICE';
    private readonly GENERAL_INFO_TAB = 'general';
    private readonly INVENTORY_TAB = 'inventory';
    private readonly TRANSLATION_TAB = 'translation';
    private readonly ATTRIBUTE_TAB = 'attribute';
    private readonly TEXT_TAB = 'text';
    private readonly MEDIA_TAB = 'media';
    private readonly COPY_ADD_NAME = ' - copy';
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";
    private readonly SERVICECATE_CODE = "SERVICECATEGORY";
    private readonly SSR_CODE = "SERVICE";
    private readonly DIMENSION_TYPE_CODE: string = "ATTRIBUTE";
    private readonly INVGROUPCTRL_CODE: string = "INVGROUPCONTROL";
    public readonly ENTITY_NAME: string = "Product";
    private readonly TRANSLATION_NAME = "Airline translation set";
    private readonly USAGETYPE_DATA: string = "DATA";
    private readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    private readonly GENERAL_PANEL_NAME: string = "General Information";
    private readonly ATTRIBUTE_PANEL_NAME: string = "Attribute";
    private readonly INVCTRL_CODE: string = "INVENTORYCONTROL";
    private readonly DELAY_TIME: 500;
    private readonly INVENTORY_PANEL_NAME: string = "Inventory Rule";
    private readonly VALIDITY_PANEL_NAME: string = "Validity";
    private readonly ATTRIBUTECHOICECODE_YES = "YES";

    public newProduct = false;
    public copyProduct = false;
    public hiddenTree = false;
    public showTranslate = false;
    public selectedTab = this.GENERAL_INFO_TAB;
    public productId: string;
    public parentId: string;
    public generalInfo$ = new BehaviorSubject<any>(null);
    public tree$ = new BehaviorSubject<TreeNode[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public serviceProductCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    private unsubscribe$ = new Subject();
    public heightPanelGeneral: number;
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productHashtagValueViews: ProductHashTagView[];
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productGroupCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public rootLevel: boolean = false;
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    public serviceCateSelected: boolean = false;
    public draftFlag: boolean = true;
    public invGroupContrl: boolean = false;
    public inventoryData: InventoryView[] = new Array();
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public productName$ = new BehaviorSubject<string>(null);
    public newFromParent: boolean = false;
    public completeLoadReference: boolean = false;
    public productTypeCode: String;
    public productGroupCode: String;
    public productTypeReferences$ = new BehaviorSubject<ProductTypeReferenceModel[]>(null);
    public saveAction: boolean = false;
    public userSecurity: SecurityGroupSecurityModel;
    public pagingDataView: PagingDataView[];
    public currentPageIndex: number;
    private draftFlagRoot: boolean = true;
    public navigateParam: any;
    public noInvControl: boolean = false;
    private _jsonURL = 'assets/data/merchandizing/attributes&rules_config.json';
    public validityConfig: ValidityModel;
    public pointOfSalesConfig: PointOfSalesModel;
    public restrictionConfig: RestrictionModel;
    public displayName: string;

    private newBtn = new NewButtonModel();
    private copyBtn = new CopyButtonModel();
    private saveBtn = new SaveButtonModel();
    private deleteBtn = new DeleteButtonModel();

    actionBarHandler: ActionBarHandlerModel;

    @ViewChild(GeneralInformationComponent) generalInfo: GeneralInformationComponent;
    @ViewChild(SpecialServiceAttributeComponent) attributeAndRuleComponent: SpecialServiceAttributeComponent;
    @ViewChild(InventoryAttributeComponent) inventoryAttributeComponent: InventoryAttributeComponent;
    @ViewChild(InventoryComponent) inventoryComponent: InventoryComponent;
    @ViewChild(InventoryDirective) inventoryDirective: InventoryDirective;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;
    @ViewChild(TextMediaComponent) textMedia: TextMediaComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;

    @ViewChild("productDetailTextRef") productDetailTextRef: ElementRef;

    constructor(private navigationService: NavigationService,
        private specialServiceService: SpecialServiceService,
        private changeDetectionRef: ChangeDetectorRef,
        private treeMapperService: TreeMapperService,
        private actionbarService: ActionbarService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private focusingService: FocusingService,
        private loadingSpinner: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private hashTagMapperService: HashTagMapperService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private numberMapperService: NumberMapperService,
        private attributeMapperService: AttributeMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private validityMapperService: ValidityMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private inventoryAttributeMapperService: InventoryAttributeMapperService,
        private languageReferenceService: LanguageReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mapperService: MapperService,
        private mediaMapperService: MediaMapperService,
        private inventoryMapperService: InventoryMapperService,
        private translationMediaMapperService: TranslationMediaMapperService,
        private translationTextMapperService: TranslationTextMapperService,
        private textMapperService: TextMapperService,
        public uiService: UIService,
        public translationNameMapperService: TranslationNameMapperService,
        public productTypeReference: ProductTypeReferenceService,
        public alertBarService: AlertBarService,
        private formBuilder: UntypedFormBuilder,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService,
        private http: HttpClient) {
        this.getParam();
        this.checkNewProduct();
    }

    ngOnInit() {
        this.getReferencesData().then(() => {
            this.updateLoadReferenceCompleted().then(() => {
                this.getProductData();
            })
        });
    }

    ngAfterViewInit(): void {
        this.setupActionBar();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private setupActionBar() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.disabled = true;
        this.saveBtn.enable(this.saveFlag);
        this.deleteBtn.disabled = true;

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            new CancelButtonModel(),
            this.deleteBtn,
            new RefreshButtonModel()
        )

        this.actionbarService.updateState(this.actionBarHandler);
    }

    private getParam() {
        let params = this.navigationService.getParams();
        if (!params)
            params = this.navigationService.getPreviousTabParams()?.params;

        this.navigateParam = params;
        this.newProduct = params?.newProduct ?? params?.newFlag ?? false;
        this.copyProduct = params?.copyProduct ?? false;
        this.pagingDataView = params?.pagingDataView ?? [];
        this.currentPageIndex = params?.currentPageIndex;
        let id = params?.productId ?? params?.id;
        this.rootLevel = this.newProduct;
        this.userSecurity = params?.userSecurity;

        this.productId = id;
        if (id != null) {
            this.hiddenTree = false;
        } else {
            this.activityStore();
        }
        this.addAction();
    }

    public onPageChange(id: string): void {
        this.getProductDetail(id);
    }

    get saveFlag() {
        return ((this.newProduct == true || this.newFromParent == true) && this.userSecurity?.newFlag == true) || ((this.newProduct == false && this.newFromParent == false) && this.userSecurity?.editFlag == true);
    }

    public getProductDetail(productId, showLoading: boolean = true) {
        if (showLoading) {
            this.loadingSpinner.show();
        }
        this.specialServiceService.getByProductId(productId)
            .subscribe(
                (responses) => {
                    setTimeout(() => {
                        this.fillProductData(responses, showLoading);
                    }, this.DELAY_TIME);
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private getProductGeneralInfo(responses: SpecialServiceViewModel, copy: boolean) {
        let product = {
            no: null,
            id: responses.productId,
            name: responses.productName,
            productGroupCode: responses.productGroupCode,
            productTypeCode: responses.productTypeCode,
            productDescription: responses.productDescription,
            draftFlag: responses.draftFlag,
            usageTypeCode: responses.usageTypeCode,
            statusCode: responses.statusCode,
            commitBy: responses.commitFirstname + responses.commitLastname,
            commitDateTime: responses.commitDateTime
        }
        if (copy == true) {
            product.name = product.name + this.COPY_ADD_NAME;
        }
        this.generalInfo$.next(product);
        this.productName$.next(responses.productName);
        this.productId = responses.productId;
        this.disableDraftFlag();
        this.disableUsageType();
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.cancel:
                this.backToCategory();
                break;
            case ACTION_STATUS.back:
                this.backToSearch();
                break;
            case ACTION_STATUS.save:
                this.onSave();
                break;
            case ACTION_STATUS.new:
                this.newClick();
                break;
            case ACTION_STATUS.refresh:
                this.getProductDetail(this.productId);
        }
    }

    backToSearch() {
        this.navigationService.navigate('/main/product/home', null, null, this.navigateParam);
    }

    backToCategory() {
        let params = {
            tab: 'search',
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate('/main/product/home', null, null, params);
    }

    public onTabChange(activeId: string) {
        switch (activeId) {
            case this.GENERAL_INFO_TAB:
                this.focusingService.focus(this.generalInfo.focusingDirective);
                this.showTranslate = false;
                break;
            case this.ATTRIBUTE_TAB:
                this.focusingService.focus(this.attributeAndRuleComponent.hashtagComponent.focusingDirective);
                this.showTranslate = false;
                break;
            case this.TEXT_TAB:
                this.focusOnText();
                this.showTranslate = false;
                break;
            case this.MEDIA_TAB:
                this.focusOnMedia();
                this.showTranslate = false;
                break;
            case this.INVENTORY_TAB:
                this.showTranslate = false;
                this.focusingService.focus(this.inventoryComponent.focusingDirective);
                break;
            case this.TRANSLATION_TAB:
                this.showTranslate = true;
                this.focusingService.focus(this.translationNameComponent.focusingDirective);
                break;
        }
    }

    public selectTreeNode(node: TreeNode) {
        this.resetDetail();
        this.productId = node.data;
        this.parentId = node.parent?.data;

        this.getProductDetail(this.productId);
    }

    private resetDetail() {
        this.attributeAndRuleComponent.hashtagComponent.delete();
        this.attributeAndRuleComponent.productNumberComponent.clearForm();
        this.attributeAndRuleComponent.attributeComponent.clearForms();
        this.attributeAndRuleComponent.inventoryComponent.clearForm();
        this.attributeAndRuleComponent.seasonComponent.delete();
        this.attributeAndRuleComponent.restrictionComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.validityComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.clearForm();
    }

    private filterUsageTypeReference(responses: UsageTypeReferenceModel[]) {
        return responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA || x.usageTypeCode == this.USAGETYPE_TEMPLATE);
    }

    public getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height;
    }

    private checkNewProduct() {
        if (this.newProduct == false || this.copyProduct == true) {
            this.hiddenTree = false;
        } else {
            this.hiddenTree = true;
        }
    }

    public validateDetail(): boolean {
        if (!this.generalInfo.validGeneral()) {
            this.displayGeneralError();
            return false;
        } else if (!this.attributeValid()) {
            this.displayAttributeError()
            return false;
        } else if (!this.inventoryRuleValid()) {
            this.displayInventoryRuleError();
            return false;
        } else if (this.validityEndMoreThanStart()) {
            this.displayValidityError();
            return false;
        }
        this.alertBarService.hide();
        return true
    }

    private displayGeneralError() {
        this.alertBarService.show(this.GENERAL_PANEL_NAME, this.generalInfo.getErrorMessage());
        this.focusGeneral();
    }

    private focusGeneral() {
        this.focusingService.focus(this.generalInfo.focusingDirective);
    }

    private displayAttributeError() {
        this.alertBarService.show(this.ATTRIBUTE_PANEL_NAME, this.attributeAndRuleComponent.attributeComponent.getErrorMessage());
        this.focusAttribute();
    }

    private focusAttribute() {
        this.focusingService.focus(this.attributeAndRuleComponent.attributeComponent.focusingDirective);
    }

    public onSave() {
        this.saveAction = true;
        if (this.newProduct == true || this.newFromParent) {
            let command = this.fillFormGroupToAddModel();
            this.addProduct(command);
        } else {
            let command = this.fillFormGroupToEditModel();
            this.editProduct(command);
        }
        this.addAction();
    }

    private addProduct(command: SpecialServiceAddCommandModel) {
        if (command) {
            this.loadingSpinner.show();
            this.specialServiceService.addProduct(command)
                .subscribe(
                    (response) => {
                        this.loadingSpinner.saveComplete();
                        this.newProduct = false;
                        this.newFromParent = false;
                        this.productId = null;
                        this.changeDetectionRef.detectChanges();
                        this.getProductDetail(response.body, false);
                        this.saveAction = false;
                        this.hiddenTree = false;
                    },
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private editProduct(command: SpecialServiceEditCommandModel) {
        if (command) {
            this.loadingSpinner.showSaving();
            this.activityDomainComponent.save();
            this.specialServiceService.editProduct(command)
                .subscribe(
                    () => {
                        this.loadingSpinner.saveComplete();
                        this.getProductDetail(this.productId , false);
                        this.saveAction = false;
                    },
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private fillFormGroupToAddModel(): SpecialServiceAddCommandModel {
        if (this.validateDetail()) {
            let formValue = this.generalInfo.formGroup.value;
            let productHashTags = this.hashTagMapperService.productHashTagModels(
                this.productHashtagValueViews);

            let productNumbers = this.numberMapperService.productNumberFormToModels(
                this.attributeAndRuleComponent.productNumberComponent?.forms,
                this.provider$);

            let productAttributes = this.attributeMapperService.ssrToAttributeCommands(
                this.attributeAndRuleComponent.attributeComponent.ssrValue,
                this.attributeAndRuleComponent.attributeComponent.ssrIdList,
                this.attributeAndRuleComponent.attributeComponent.ssrDataList,
                this.SSR_CODE, null, this.attributeAndRuleComponent.attributeComponent?.serviceReference$);

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                this.attributeAndRuleComponent.attributeComponent?.forms, productAttributes);
            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                this.attributeAndRuleComponent.inventoryComponent?.forms, productAttributes);

            let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
                this.attributeAndRuleComponent.restrictionComponent?.ruleComponent?.forms);
            let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);

            let productValidities = this.validityMapperService.productValidityFormToModels(
                this.attributeAndRuleComponent.validityComponent?.ruleComponent?.forms);

            let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
                this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.forms);
            let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

            let productInventoryDimensions = this.inventoryAttributeMapperService.productInventoryDimensionToModels(this.inventoryAttributeComponent.productAttributeInventoryValue, this.productInventoryDimensions, this.DIMENSION_TYPE_CODE);
            let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.productId);

            medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.productId);

            let addCommand: SpecialServiceAddCommandModel = {
                productCategoryCode: this.SERVICE_PRODUCT_CATE_CODE,
                productGroupCode: formValue.productGroupCode,
                productTypeCode: null,
                providerId: null,
                parentProductId: this.parentId,
                productName: formValue.productName,
                productDescription: formValue.productDescription,
                supplierId: null,
                displayCode: null,
                draftFlag: formValue.draftFlag,
                finalFlag: null,
                searchName: null,
                searchUsageTypeCode: null,
                searchStatusCode: null,
                usageTypeCode: formValue.usageTypeCode,
                isOwnerFilter: null,
                filterUserAccountId: null,
                statusCode: formValue.statusCode,
                productHashTags: productHashTags,
                productNumbers: productNumbers,
                productRestrictionProducts: productRestrictionProducts,
                productRestrictionProductNumbers: productRestrictionProductNumbers,
                productRestrictionRoutes: productRestrictionRoutes,
                productRestrictionLocations: productRestrictionLocations,
                productValidities: productValidities,
                productAttributes: productAttributes,
                productInventoryDimensions: productInventoryDimensions,
                productRestrictionOrganisations: productRestrictionOrganisations,
                medias: medias,
                seasonCode: <string>this.attributeAndRuleComponent.seasonComponent.seasonCode,
                languageTranslationSet: null
            };
            return addCommand;
        }
        return null;

    }

    private fillFormGroupToEditModel(): SpecialServiceEditCommandModel {
        if (this.validateDetail()) {
            let formValue = this.generalInfo.formGroup.value;
            let productHashTags = this.hashTagMapperService.productHashTagModels(
                this.productHashtagValueViews);

            let productNumbers = this.numberMapperService.productNumberFormToModels(
                this.attributeAndRuleComponent.productNumberComponent?.forms,
                this.provider$);

            let productAttributes = this.attributeMapperService.ssrToAttributeCommands(
                this.attributeAndRuleComponent.attributeComponent?.ssrValue,
                this.attributeAndRuleComponent.attributeComponent?.ssrIdList,
                this.attributeAndRuleComponent.attributeComponent?.ssrDataList,
                this.SSR_CODE, null, this.attributeAndRuleComponent.attributeComponent?.serviceReference$);

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                this.attributeAndRuleComponent.attributeComponent?.forms, productAttributes);

            let inventoryRuleValue = this.attributeAndRuleComponent.inventoryComponent?.forms.getRawValue()
            let inventoryRuleForm = this.formBuilder.array(inventoryRuleValue.map(item => this.formBuilder.control(item)));

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                inventoryRuleForm, productAttributes);


            let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
                this.attributeAndRuleComponent.restrictionComponent.ruleComponent?.forms);
            let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);

            let productValidities = this.validityMapperService.productValidityFormToModels(
                this.attributeAndRuleComponent.validityComponent?.ruleComponent?.forms);

            let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
                this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.forms);
            let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

            let productInventoryDimensions: ProductInventoryDimensionCommandModel[] = new Array()
            if (this.draftFlag) {
                productInventoryDimensions = this.inventoryAttributeMapperService.productInventoryDimensionToModels(this.inventoryAttributeComponent.productAttributeInventoryValue, this.productInventoryDimensions, this.DIMENSION_TYPE_CODE);
            }

            let productInventories = this.inventoryMapperService.inventoryViewToModels(this.inventoryData);

            let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
                this.translationNameComponent?.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.productId, this.translationNameComponent?.translationViews);

            let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent?.mediaTexts, this.ENTITY_NAME, this.productId);
            medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent?.mediaTranslationChildTexts, medias);

            medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent?.mediaFiles, this.ENTITY_NAME, this.productId);
            medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent?.mediaTranslationChildFiles, medias);
            let editCommand: SpecialServiceEditCommandModel = {
                productId: this.productId,
                productCategoryCode: this.SERVICE_PRODUCT_CATE_CODE,
                productGroupCode: formValue.productGroupCode,
                productTypeCode: this.getProductTypeCode(),
                providerId: null,
                parentProductId: this.parentId,
                productName: formValue.productName,
                productDescription: formValue.productDescription,
                supplierId: null,
                displayCode: null,
                draftFlag: formValue.draftFlag,
                finalFlag: null,
                searchName: null,
                searchUsageTypeCode: null,
                searchStatusCode: null,
                usageTypeCode: formValue.usageTypeCode,
                isOwnerFilter: null,
                filterUserAccountId: null,
                statusCode: formValue.statusCode,
                productHashTags: productHashTags,
                productNumbers: productNumbers,
                productRestrictionProducts: productRestrictionProducts,
                productRestrictionProductNumbers: productRestrictionProductNumbers,
                productRestrictionRoutes: productRestrictionRoutes,
                productRestrictionLocations: productRestrictionLocations,
                productValidities: productValidities,
                productAttributes: productAttributes,
                productInventoryDimensions: productInventoryDimensions,
                productInventories: productInventories,
                productRestrictionOrganisations: productRestrictionOrganisations,
                languageTranslationSet: languageTranslationSet,
                medias: medias,
                seasonCode: <string>this.attributeAndRuleComponent.seasonComponent.seasonCode
            };
            return editCommand;
        }
        return null;
    }

    private getProductTypeCode(): string {
        if (this.productTypeCode$.value) {
            return this.productTypeCode$.value;
        }
        return null;
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference$.value,
            value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTag$.next(productHashtagViews);
    }

    public hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    private getLevelHierarchy(responses: SpecialServiceViewModel) {
        if (!responses.parentProductId) {
            this.rootLevel = true;
        } else {
            this.rootLevel = false;
        }
    }

    private fillModelToSeason(seasonCode: string) {
        this.clearSeason();
        if (seasonCode) {
            this.attributeAndRuleComponent.seasonComponent.fillData(seasonCode);
        }
    }

    private fillModelToProductInventoryAttributeType(productInventoryDimensions: ProductInventoryDimensionViewModel[]) {
        this.getServiceCatAttribute(productInventoryDimensions);
        this.productInventoryDimensions = productInventoryDimensions;
    }

    private getServiceCatAttribute(productInventoryDimensions: ProductInventoryDimensionViewModel[]) {
        let filter = productInventoryDimensions.filter(x => x.attributeTypeCode == this.SERVICECATE_CODE && x.inheritInventoryAttribute == false);
        if (filter?.length) {
            this.serviceCateSelected = true;
        } else {
            this.serviceCateSelected = false;
        }
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        this.attributeAndRuleComponent.productNumberComponent.addViewToFormGroup(productNumbers);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        this.attributeAndRuleComponent.attributeComponent.clearForms();
        this.attributeAndRuleComponent.attributeComponent.clearInheritForms();
        this.attributeAndRuleComponent.attributeComponent.productAttributeSSR = productAttributes.filter(x => x.inheritAttribute == false && x.attributeTypeCode == this.SSR_CODE);
        this.attributeAndRuleComponent.attributeComponent.productAttributeSSRInherit = productAttributes.filter(x => x.inheritAttribute == true && x.attributeTypeCode == this.SSR_CODE);
        this.attributeAndRuleComponent.attributeComponent.productAttributes = productAttributes.filter(x => x.inheritAttribute == false && x.attributeTypeCode != this.SSR_CODE);
        this.attributeAndRuleComponent.attributeComponent.productAttributesInherit = productAttributes.filter(x => x.inheritAttribute == true && x.attributeTypeCode != this.SSR_CODE);
        this.attributeAndRuleComponent.attributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.attributeComponent.addDataSSR();
        this.attributeAndRuleComponent.attributeComponent.getAttributeByProductType();
    }

    private fillModelToInventoryRule(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        this.attributeAndRuleComponent.inventoryComponent.draftFlag = draftFlag;
        let productAttributeInventoryRules = this.filterProductAttributeInventoryRule(productAttributes);
        this.attributeAndRuleComponent.inventoryComponent.productAttributes = productAttributeInventoryRules.filter(x => x.inheritAttribute == false && x.attributeTypeCode != this.SSR_CODE);
        this.attributeAndRuleComponent.inventoryComponent.productAttributesInherit = productAttributeInventoryRules.filter(x => x.inheritAttribute == true && x.attributeTypeCode != this.SSR_CODE);
        this.attributeAndRuleComponent.inventoryComponent.addViewToFormGroup();
        this.attributeAndRuleComponent.inventoryComponent.addViewInheritToFormGroup();
    }

    private filterProductAttributeInventoryRule(productAttributes: ProductAttributeViewModel[]) {
        let attributeTypeCodeINV = this.domainAttributeINV$.value.map(x => x.attributeTypeCode);
        return productAttributes.filter(x => attributeTypeCodeINV.includes(x.attributeTypeCode));
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[]) {

        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            null, false);

        let productRestrictionInheritViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            null, true);

        this.attributeAndRuleComponent.restrictionComponent?.ruleComponent?.addRestrictionViewToFormGroup(productRestrictionViews ?? []);
        this.attributeAndRuleComponent.restrictionComponent?.ruleComponent?.addRestrictionInheritToFormGroup(productRestrictionInheritViews ?? []);
        this.attributeAndRuleComponent.restrictionComponent.detectChanges();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        var productValiditiesInherit = this.validityMapperService.productValiditiesToForm(productValidities, true);
        this.attributeAndRuleComponent.validityComponent?.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
        this.attributeAndRuleComponent.validityComponent?.ruleComponent?.addValidityInheritToFormGroup(productValiditiesInherit);
        this.attributeAndRuleComponent.validityComponent.detectChanges();
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        let pointOfSalesInheritViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, true);
        this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.addPointOfSalesInheritToFormGroup(pointOfSalesInheritViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private getInvGroupCtrl(productAttributes: ProductAttributeViewModel[]) {
        let value: boolean = false;
        let filter = productAttributes.filter(x => x.attributeTypeCode == this.INVGROUPCTRL_CODE);
        if (filter?.length) {
            if (filter[0].attributeChoiceCode == this.ATTRIBUTECHOICECODE_YES) {
                value = true;
            }
        }
        this.invGroupContrl = value;
    }

    returnInvGroupCtrl(value: boolean) {
        this.invGroupContrl = value;
    }

    private fillModelToInventory(productInventorySSR: SpecialServiceInventoryViewModel[], productAttributes: ProductAttributeViewModel[]) {
        this.getInvGroupCtrl(productAttributes);
        this.inventoryComponent.displayData(productInventorySSR, this.invGroupContrl);
        this.inventoryData = this.inventoryComponent.ssrInventoryView;
    }

    public saveInventoryData(value) {
        this.inventoryData = value;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public onTreeCollasped(collapsedStatus: boolean) {
        if (collapsedStatus == true) {
            this.hiddenTree = true;
        } else {
            this.hiddenTree = false
        }
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMedia.mediaComponent.fillModelToForm(mediaViews);
    }

    private fillModelToTranslationName(productName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.translationNameComponent.fillModelToForm(productName, languageTranslationSet);
    }

    private fillModelToTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }

    private fillModelToTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }

    public displayMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryToTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildFiles = this.translationMediaMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildFiles);
        }
    }

    public deleteMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildTexts = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationChildFileViews(
                value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildTexts);
        }
    }

    private fillModelToText(medias: MediaViewModel[]) {
        var mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMedia.textComponent.fillModelToForm(mediaTextViews);
    }

    public displayTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryToTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    public deleteTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationChildTextViews(
                value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    focusOnMedia() {
        this.focusingService.focus(this.textMedia.mediaComponent.focusingDirective);
    }

    focusOnText() {
        this.focusingService.focus(this.textMedia.textComponent.focusingDirective);
    }

    public newClick() {
        this.rootLevel = true;
        this.newProduct = true;
        this.newFromParent = false;
        this.hiddenTree = true;
        this.parentId = null;
        this.productId = null;
        this.pagingDataView = [];
        this.currentPageIndex = null;
        this.rootLevel = this.newProduct;
        this.tree$.next(null);
        this.clearGeneralInfo();
        this.clearTranslation();
        this.clearAttributeAndRules();
        this.clearMedia();
        this.clearText();
        this.addAction();
        this.setupActionBar();
        this.activityStore();
        this.displayName = "";
        this.alertBarService.hide();
    }

    public canLoadAttributeAndRules(): boolean {
        if (this.dateTimeDimensionReference$.value &&
            this.calendarValidityReference$.value &&
            this.conditionReference$.value &&
            this.organisationSelect2Data &&
            this.countryReferenceSelect2Data) {
            return true;
        }
        return false;
    }

    public productTypeCodeChange(value) {
        this.productTypeCode$.next(value);
    }

    public productGroupCodeChange(value) {
        this.productGroupCode$.next(value);
        if (this.saveAction === true) {
            this.validateDetail()
        }
    }

    public returnServiceCateSelected(value: boolean) {
        this.serviceCateSelected = value;
        this.serviceCategoryChange();
    }

    public attributeValid() {
        let attributetRequire = this.attributeAndRuleComponent.attributeComponent.findRequireAttribute();
        var ssrValidate = this.attributeAndRuleComponent.attributeComponent.validateSSR();
        return (!attributetRequire && ssrValidate);
    }

    public onAlertBarClick() {
        if (this.generalInfo.validGeneral() == false) {
            this.focusGeneral();
        } else if (this.attributeValid()) {
            this.focusAttribute();
        }
    }

    public ssrValueChange() {
        if (this.saveAction == true) {
            this.validateDetail();
        }
    }

    private addAction() {
        if (this.productId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.productId, this.productName$.value);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            this.SERVICE_PRODUCT_CATE_CODE,
            null);
    }

    private clearGeneralInfo() {
        this.generalInfo.formGroup.get('draftFlag').enable();
        this.generalInfo.formGroup.get('usageTypeCode').enable();
        this.generalInfo.formGroup.get('productGroupCode').enable();
        this.generalInfo.formGroup.reset();
        this.generalInfo.initNewform(null, null, null, null);
        this.productTypeCode$.next(null);
        this.productGroupCode$.next(null);
        this.draftFlag = true;
        this.draftFlagRoot = true;
        this.changeDetectionRef.detectChanges();
    }

    private clearTranslation() {
        this.translationNameComponent.clearForm();
        this.translationTextComponent.clearForm();
    }

    private clearSeason() {
        this.attributeAndRuleComponent.seasonComponent.clearSeason();
    }

    private clearAttributeAndRules() {
        this.setProductHashTagValue(null);
        this.attributeAndRuleComponent.productNumberComponent?.clearForm();
        this.clearAttribute();
        this.serviceCateSelected = null;
    }

    private clearAttribute() {
        this.fillModelToProductAttribute([], true);
        this.fillModelToInventoryRule([], true);
        this.resetInventoryRule();
        this.attributeAndRuleComponent.attributeComponent.addDataSSR();
        this.attributeAndRuleComponent.attributeComponent.addDataSSRInherit();
        this.clearSeason();
        this.clearRestriction();
        this.clearValidity();
        this.clearPointOfSales();
        this.inventoryAttributeComponent.createProductAttributeType();
        this.productInventoryDimensions = [];
    }

    private resetInventoryRule() {
        this.attributeAndRuleComponent.inventoryComponent.geProductAttributeType();
        this.attributeAndRuleComponent.inventoryComponent.removedAttribute = [];
        this.attributeAndRuleComponent.inventoryComponent.disableChoice = false;
    }

    private clearRestriction() {
        this.fillModelToProductRestriction([], [], [], []);
        this.attributeAndRuleComponent.restrictionComponent.detectChanges()
    }

    private clearValidity() {
        this.attributeAndRuleComponent.validityComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.validityComponent.ruleComponent
            ?.validityInheritComponent?.clearForm();
        this.attributeAndRuleComponent.validityComponent.detectChanges();
    }

    private clearPointOfSales() {
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent
            ?.pointofsalesInheritComponent?.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private clearMedia() {
        this.fillModelToMedia([]);
        this.fillModelToTranslationMedia([]);
    }

    private clearText() {
        this.fillModelToText([]);
        this.fillModelToTranslationText([]);
    }

    private assignRootDraftFlag(draftFlag: boolean) {
        if (this.rootLevel) {
            this.draftFlagRoot = draftFlag;
        }
    }

    private disableDraftFlag() {
        if (!this.draftFlagRoot || !this.rootLevel) {
            this.generalInfo.formGroup.get('draftFlag').disable();
        } else {
            this.generalInfo.formGroup.get('draftFlag').enable();
        }
    }

    private hideInvPanel(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        if (draftFlag && this.rootLevel) {
            this.noInvControl = true;
            return;
        }
        let filter = productAttributes.filter(x => x.attributeTypeCode == this.INVCTRL_CODE);
        if (filter?.length) {
            if (filter[0].attributeChoiceCode.toUpperCase() == "NO") {
                this.noInvControl = true;
                return;
            }
        }
        this.noInvControl = false;
    }

    private getReferencesData(): Promise<void> {
        this.loadingSpinner.show();
        return new Promise((resolve, reject) => {
            forkJoin({
                productCategoryReferences: this.productCategoryReferenceService.getAll(),
                productGroupReferences: this.productGroupReferenceService.getAll(),
                productTypeGroups: this.productTypeGroupService.getAll(),
                statusReferences: this.statusReferenceService.getAll(),
                userTypeReferences: this.usageTypeReferenceService.getUsageTypeReferences(),
                hashTagReferences: this.hashTagReferenceService.getAll(),
                suppliers: this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE),
                providers: this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE),
                productNumberTypeReferences: this.productNumberTypeReferenceService.getAll(),
                domainAttributeTypeSearch: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", true),
                domainAttributeTypeService: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE),
                domainAttributeTypeInventory: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV),
                seasonReferences: this.seasonReferenceService.getAll(),
                ruleConfigData: this.http.get(this._jsonURL),
                conditionReferences: this.conditionReferenceService.getConditionsByCodes([]),
                countryReferences: this.countryReferenceService.searchAllCountry(),
                locationGroups: this.locationGroupService.getAll(),
                productLocationPointReferences: this.productLocationPointReferenceService.getAll(),
                productLocationTypeReference: this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE),
                regionReferences: this.regionReferenceService.getRegionReference(),
                calendarValidityReferences: this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE),
                dateTimeDimensionReferences: this.dateTimeDimensionReferenceService.getDateTimeDimensionReference(),
                organisationTypeReferences: this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS),
                organisationGroupReferences: this.organisationGroupReferenceService.getOrganisationGroupReference(),
                organisationRoleReferences: this.organisationRoleReferenceService.getAllActive(),
                organisationReferences: this.organisationService.getByStatus(this.ACTIVE_STATUS),
                languageReferences: this.languageReferenceService.getByOrganisation(),
                mediaTypeReferences: this.mediaTypeReferenceService.getAll(),
                mediaUseReferences: this.mediaUseReferenceService.getAll(),
                mediaHashTags: this.mediaHashTagService.getAll(),
                mediaTypeFileTypes: this.mediaTypeFileTypeService.getAll(),
                productTypeReferences: this.productTypeReference.searchProductTypeReference('', '', this.ACTIVE_STATUS)
            })
                .subscribe(({
                    productCategoryReferences,
                    productGroupReferences,
                    productTypeGroups,
                    statusReferences,
                    userTypeReferences,
                    hashTagReferences,
                    suppliers,
                    providers,
                    productNumberTypeReferences,
                    domainAttributeTypeSearch,
                    domainAttributeTypeService,
                    domainAttributeTypeInventory,
                    seasonReferences,
                    ruleConfigData,
                    conditionReferences,
                    countryReferences,
                    locationGroups,
                    productLocationPointReferences,
                    productLocationTypeReference,
                    regionReferences,
                    calendarValidityReferences,
                    dateTimeDimensionReferences,
                    organisationTypeReferences,
                    organisationGroupReferences,
                    organisationRoleReferences,
                    organisationReferences,
                    languageReferences,
                    mediaTypeReferences,
                    mediaUseReferences,
                    mediaHashTags,
                    mediaTypeFileTypes,
                    productTypeReferences
                }) => {
                    this.fillProuctCategoryReferences(productCategoryReferences);
                    this.fillProductGroupReferences(productGroupReferences);
                    this.fillProductTypeGroups(productTypeGroups);
                    this.fillStatusReferences(statusReferences);
                    this.fillUserTypeReferences(userTypeReferences);
                    this.fillHashTagReferences(hashTagReferences);
                    this.fillSupplieres(suppliers);
                    this.fillProviders(providers);
                    this.fillProductTypeNumberReferences(productNumberTypeReferences);
                    this.fillDomainAttributeTypeSearch(domainAttributeTypeSearch);
                    this.fillDomainAttributeTypeService(domainAttributeTypeService);
                    this.fillDomainAttributeTypeInventory(domainAttributeTypeInventory);
                    this.fillSeasonReferences(seasonReferences);
                    this.fillRuleConfigData(ruleConfigData);
                    this.fillConditionReferences(conditionReferences);
                    this.fillCountryReferences(countryReferences);
                    this.fillLocationGroup(locationGroups);
                    this.fillProductLocationPointReferences(productLocationPointReferences);
                    this.fillProductLocationTypeReferences(productLocationTypeReference);
                    this.fillRegionReferences(regionReferences);
                    this.fillCalendarValidityReferences(calendarValidityReferences);
                    this.fillDateTimeDimensionReferences(dateTimeDimensionReferences);
                    this.fillOrganisationTypeReferences(organisationTypeReferences);
                    this.fillOrganisationGroupReferences(organisationGroupReferences);
                    this.fillOrganisationRoleReferences(organisationRoleReferences);
                    this.fillOrganisationReferences(organisationReferences);
                    this.fillLanguageReferences(languageReferences);
                    this.fillMediaTypeReferences(mediaTypeReferences);
                    this.fillMediaUseRerferences(mediaUseReferences);
                    this.fillMediaHashTags(mediaHashTags);
                    this.fillMediaTypeFileTypes(mediaTypeFileTypes);
                    this.fillProductTypeReferences(productTypeReferences);
                    resolve();
                })
        });
    }

    private getProductData() {
        if (this.newProduct === false || this.copyProduct == true) {
            this.getProductDetail(this.productId);
        } else {
            this.loadingSpinner.hide();
        }
    }

    private updateLoadReferenceCompleted(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.completeLoadReference = true;
            this.changeDetectionRef.detectChanges();
            resolve();
        });
    }

    private fillProuctCategoryReferences(productCategoryReferences: ProductCategoryReferenceModel[]) {
        this.productCategoryReference$.next(productCategoryReferences);
        this.serviceProductCategoryReference$.next(productCategoryReferences
            .filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE));
    }

    private fillProductGroupReferences(productGroupReferences: ProductGroupReferenceModel[]) {
        this.productGroupReference$.next(productGroupReferences);
        this.serviceProductGroupReference$.next(productGroupReferences
            .filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE));
    }

    private fillProductTypeGroups(productTypeGroups: ProductTypeGroupModel[]) {
        this.productTypeGroup$.next(productTypeGroups);
    }

    private fillStatusReferences(statusReferences: StatusReferenceModel[]) {
        this.statusReference$.next(statusReferences);
    }

    private fillUserTypeReferences(userTypeReferences: UsageTypeReferenceModel[]) {
        if (userTypeReferences) {
            this.usageTypeReference$.next(this.filterUsageTypeReference(userTypeReferences));
        }
    }

    private fillHashTagReferences(hashTagReferences: HashTagReferenceModel[]) {
        this.hashTagReference$.next(hashTagReferences);
        if (!this.productId) {
            this.setProductHashTagValue([]);
        }
    }

    private fillSupplieres(suppliers: OrganisationModel[]) {
        this.supplier$.next(suppliers);
    }

    private fillProviders(providers: OrganisationModel[]) {
        this.provider$.next(providers
            .filter(x => x.providerIataCode)
            .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
    }

    private fillProductTypeNumberReferences(productTypeNumberReferences: ProductNumberTypeReferenceModel[]) {
        this.productNumberTypeReference$.next(productTypeNumberReferences);
    }

    private fillDomainAttributeTypeSearch(domainAttributes: DomainAttributeModel[]) {
        domainAttributes.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        this.domainAttributeSearch$.next(domainAttributes);
    }

    private fillDomainAttributeTypeService(domainAttributes: DomainAttributeModel[]) {
        this.domainAttributeService$.next(domainAttributes);
    }

    private fillDomainAttributeTypeInventory(domainAttributes: DomainAttributeModel[]) {
        if (domainAttributes) {
            this.domainAttributeINV$.next(
                domainAttributes.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
        }
    }

    private fillSeasonReferences(seasonReferences: SeasonReferenceModel[]) {
        this.seasonReference$.next(seasonReferences)
    }

    private fillRuleConfigData(ruleConfigData: any) {
        this.validityConfig = this.removeLocalTimezone(ruleConfigData.validity);
        this.pointOfSalesConfig = ruleConfigData.pointOfSales;
        this.restrictionConfig = ruleConfigData.restrictions;
    }

    private removeLocalTimezone(validityData) {
        validityData?.types[0].dateType?.forEach(dateType => {
            dateType.calendarType?.forEach(type => {
                if (type.timeZone) {
                    let localIndex = type.timeZone.findIndex(item => item.timeZoneCode == 'utc');
                    type.timeZone.splice(localIndex, 1);
                    let index = type.timeZone.findIndex(item => item.timeZoneCode == '');
                    type.timeZone.splice(index, 1);
                }
            })
        })
        return validityData;
    }

    private fillConditionReferences(conditionReferences: ConditionReferenceModel[]) {
        this.conditionReference$.next(conditionReferences);
    }

    private fillCountryReferences(countryReferences: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(countryReferences);
    }

    private fillLocationGroup(locationGroups: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(locationGroups);
    }

    private fillProductLocationPointReferences(productLocationPointReferences: ProductLocationPointReferenceModel[]) {
        this.productLocationPointReference$.next(productLocationPointReferences);
    }

    private fillProductLocationTypeReferences(productLocationTypeReference: ProductLocationTypeReferenceModel) {
        let productLocationTypeReferences = new Array<ProductLocationTypeReferenceModel>();
        productLocationTypeReferences.push(productLocationTypeReference);
        this.productLocationTypeReference$.next(productLocationTypeReferences);
    }

    private fillRegionReferences(regionReferences: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(regionReferences);
    }

    private fillCalendarValidityReferences(calendarValidityReferences: CalendarValidityReferenceModel[]) {
        this.calendarValidityReference$.next(calendarValidityReferences);
    }

    private fillDateTimeDimensionReferences(dateTimeDimensionReferences: DateTimeDimensionReferenceModel[]) {
        this.dateTimeDimensionReference$.next(dateTimeDimensionReferences);
    }

    private fillOrganisationTypeReferences(organisationTypeReferences: OrganisationTypeReferenceModel[]) {
        this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(organisationTypeReferences);
    }

    private fillOrganisationGroupReferences(organisationGroupReferences: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(organisationGroupReferences);
    }

    private fillOrganisationRoleReferences(organisationRoleReferences: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(organisationRoleReferences);
    }

    private fillOrganisationReferences(organisationReferences: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(organisationReferences);
    }

    private fillLanguageReferences(languageReferences: LanguageReferenceModel[]) {
        this.languageReference$.next(languageReferences);
    }

    private fillMediaTypeReferences(mediaTypeReferences: MediaTypeReferenceModel[]) {
        this.mediaTypeReference$.next(mediaTypeReferences);
    }

    private fillMediaUseRerferences(mediaUseReferences: MediaUseReferenceModel[]) {
        this.mediaUseReference$.next(mediaUseReferences);
    }

    private fillMediaHashTags(mediaHashTags: MediaHashTagModel[]) {
        this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(mediaHashTags);
    }

    private fillMediaTypeFileTypes(mediaTypeFileTypes: MediaTypeFileTypeModel[]) {
        this.mediaTypeFileType$.next(mediaTypeFileTypes);
    }

    private fillProductTypeReferences(productTypeReferences: ProductTypeReferenceModel[]) {
        this.productTypeReferences$.next(productTypeReferences);
    }

    private fillProductData(responses: SpecialServiceViewModel, showLoading: boolean = true) {
        if (showLoading) {
            this.loadingSpinner.hide();
        }
        this.getLevelHierarchy(responses);
        this.assignRootDraftFlag(responses.draftFlag);
        this.getProductGeneralInfo(responses, this.copyProduct);
        this.parentId = responses.parentProductId;
        this.displayName = this.displayProductName(responses.productName);
        this.tree$.next(this.treeMapperService.productTreeView(responses.productHierarchy));

        this.productTypeCode$.next(responses.productTypeCode);
        this.productGroupCode$.next(responses.productGroupCode);
        this.draftFlag = this.draftFlagRoot;
        this.setProductHashTagValue(responses.productHashtags);
        this.fillModelToProductNumber(responses.productNumbers);
        this.fillModelToProductAttribute(responses.productAttributes, responses.draftFlag)
        this.fillModelToInventoryRule(responses.productAttributes, responses.draftFlag);
        this.fillModelToSeason(responses.seasonCode);
        this.fillModelToInventory(responses.productInventorySpecialServices, responses.productAttributes);
        this.fillModelToProductInventoryAttributeType(responses.productInventoryDimensions);
        this.fillModelToMedia(responses.medias);
        this.fillModelToTranslationName(responses.productName, responses.languageTranslationSet);
        this.fillModelToTranslationText(responses.medias);
        this.fillModelToTranslationMedia(responses.medias);
        this.fillModelToText(responses.medias);
        this.fillModelToProductRestriction(responses.productRestrictionProducts,
            responses.productRestrictionProductNumbers,
            responses.productRestrictionRoutes,
            responses.productRestrictionLocations
        );
        this.fillModelToProductValidity(responses.productValidities);
        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
        this.hideInvPanel(responses.productAttributes, this.draftFlagRoot)
        if (this.copyProduct == true) {
            this.productId == null
        }

        this.addAction();
        this.alertBarService.hide();
        this.changeDetectionRef.detectChanges();
    }

    public inventoryRuleValid() {
        let attributeInvCountrolValid = this.attributeAndRuleComponent.inventoryComponent.validateAttributeINVControl();
        return attributeInvCountrolValid;
    }

    private displayInventoryRuleError() {
        this.alertBarService.show(this.INVENTORY_PANEL_NAME, this.attributeAndRuleComponent.inventoryComponent.getErrorMessage());
        this.focusInventoryRule();
    }

    private focusInventoryRule() {
        this.focusingService.focus(this.attributeAndRuleComponent.inventoryComponent.focusingDirective);
    }

    public serviceCategoryChange() {
        if (this.saveAction == true) {
            this.validateDetail();
        }
    }

    private validityEndMoreThanStart(): boolean {
        let validities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityComponent.ruleComponent.forms);
        if (validities?.length) {
            let conditionCodes = validities.filter(x => x.conditionCode == "<>" || x.conditionCode == "><");
            let filterEndMoreThenStart = conditionCodes.filter(x => x.startDateTime > x.endDateTime);
            if (filterEndMoreThenStart?.length) {
                return true;
            }
        }
        return false;
    }

    private displayValidityError() {
        this.alertBarService.show(this.VALIDITY_PANEL_NAME, this.attributeAndRuleComponent.validityComponent.ERROR_END_MORETHAN_START);
        this.focusingService.focus(this.attributeAndRuleComponent.validityComponent.focusingDirective);
    }

    private displayProductName(productName: string) {
        return " :: " + productName;
    }

    private disableUsageType() {
        if (!this.draftFlagRoot || !this.rootLevel) {
            this.generalInfo.formGroup.get('usageTypeCode').disable();
        } else {
            this.generalInfo.formGroup.get('usageTypeCode').enable();
        }
    }
}

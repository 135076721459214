import { ButtonBase } from "./button.base";

export class CustomButtonModel extends ButtonBase {
    public label: string;
    public action: string;
    public matIconName: string;
    public colorClass: string;

    constructor(
        label: string,
        action: string,
        matIconName: string,
        colorClass: string) {
            super();
            this.label = label;
            this.action = action;
            this.matIconName = matIconName;
            this.colorClass = colorClass;
    }
}
import { ProductInventoryLedgerCommandModel } from './product-inventory-ledger-command.model';

export class ProductInventoryCommandModel {
    public productInventoryId: string;
    public productId: string;
    public operatingProductId: string;
    public unitCode: string;
    public sellCapacity: number;
    public requestCapacity: number;
    public groupCapacity: number;
    public overSellFactor: number;
    public availableNetUnits: number;
    public maximumAvailableUnits: number;
    public expectedToService: number;
    public vehicleConfigurationProductId: string;
    public rootProductId: string;
    public operatingServiceProductId: string;
    public salesBucketCode: string;
    public productInventoryLedgers: ProductInventoryLedgerCommandModel[];
    public sequence: number;
}
import { Injectable, Injector } from '@angular/core';

import { IPaymentGatewayProvider, paymentProvider } from './payment-gateway-provider';
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class PaymentGatewayFactory {

    constructor(private injector: Injector) {

    }

    public create(): IPaymentGatewayProvider {
        const prividerInstance = paymentProvider.get(environment.gatewayProvider.code);
        if (!prividerInstance) {
            return null;
        }
        return this.injector.get(prividerInstance);
    }
}
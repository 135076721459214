export class PriceRuleServiceCategoryModel {
    public priceRuleServiceCategoryId: string;
    public serviceCategoryCode: string;
    public inventoryDimensionCode: string;
    public conditionCode: string;
    public thresholdValue: number;
    public priceRuleAttributeGroupCode: string;
    public dimensionUnitCode: string;
    public displaySequence: number;
    public excludeFlag: boolean;
    public children: PriceRuleServiceCategoryModel[];
}
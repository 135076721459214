import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditAccountContentComponent } from './credit-account-content.component';

const routes: Routes = [
    {
        path: '',
        component: CreditAccountContentComponent
    },
    {
        path: 'summary',
        loadChildren: () => 
            import('./account-summary/account-summary.module').then(
                (m) => m.AccountSummaryModule
            )
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class CreditAccountContentRoutingModule { }

export class IndividualNamesModel {
    public no: number;
    public individualNameId: string;
    public individualId: string;
    public individualTitleCode: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public nickName: string;
    public languageCode: string;
    public primaryFlag: boolean;
    public status: string;
    public titleName: string;
    public languageName: string;
    public statusName: string;
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { DateTimeSystemPropertyModel } from "../../models/system-property-model/datetime-system-property.model";

@Injectable({
    providedIn: 'root'
})
export class SystemPropertyService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/SystemProperties"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getDateTimeSystemProperty(): Observable<DateTimeSystemPropertyModel[]> {
        return this.httpGet<DateTimeSystemPropertyModel[]>(this.REFERENCE_PATH + '/dateTimeFormats', null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { CountryReferenceModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class CountryReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/Countries"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }


    public searchAllCountry(): Observable<CountryReferenceModel[]> {
        return this.httpGet<any>(this.REFERENCE_PATH, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getCountryReference(codes: string[] = []): Observable<CountryReferenceModel[]> {
        const countryCodeParamName = 'countryCodes';
        let params = this.stringHelperService.createQueryParamFromArray(countryCodeParamName, codes);
        return this.httpGet<CountryReferenceModel[]>(this.REFERENCE_PATH + params, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getCountryByName(name: string): Observable<CountryReferenceModel[]> {
        return this.httpGet<CountryReferenceModel[]>(this.REFERENCE_PATH + "/name/" + name, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getCountryInOrderProductPoint(): Observable<CountryReferenceModel[]> {
        return this.httpGet<CountryReferenceModel[]>(this.REFERENCE_PATH + "/orderProductPoint", null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByLogonOrganisation(): Observable<CountryReferenceModel> {
        return this.httpGet<CountryReferenceModel>(this.REFERENCE_PATH + "/logon", null)
            .pipe(
                map(res => res.body)
            )
    }
}
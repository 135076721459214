import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    ViewChild,
    AfterViewInit,
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { PriceRuleModel } from "src/app/core/models/pricing-model";
import { PricingService } from "src/app/core/services/pricing-services";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { PricingConverter } from "../../../shared/pricing.converter";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: "op-price-rule-search-table",
    templateUrl: "./search-table.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PricingService, PricingConverter],
})
export class SearchTableComponent implements OnChanges, AfterViewInit {
    public readonly EXPORT_FILE_NAME = "PriceRuleSearchResult";
    @Input() classIcon: string;
    @Input() priceRuleSearchResults: PriceRuleModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSelected = new EventEmitter<any>();
    @Output() onNew = new EventEmitter();
    @Output() onCopy = new EventEmitter<any>();
    @Output() onStatusChange = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter<any>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false })
    dataGrid: DxDataGridComponent;
    focusing = false;
    selectedItem: any;
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };

    public statusReferences: StatusReferenceModel[];

    constructor(
        private statusReferenceService: StatusReferenceService,
        private dataConverterService: DateConverterService
    ) {
        this.statusReferenceService.getAll().subscribe((response) => {
            this.statusReferences = response;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["priceRuleSearchResults"]) {
            this.selectedItem = null;
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    doubleClick(item) {
        this.onSelected.emit(item.key);
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
    }

    newItem() {
        this.onNew.emit();
    }

    editItem() {
        if (this.selectedItem) {
            this.onSelected.emit(this.selectedItem);
        }
    }

    deleteItem() {
        if (this.selectedItem && this.selectedItem.statusCode != StatusConstant.DELETED) {
            this.onStatusChange.emit({
                item: this.selectedItem,
                statusCode: StatusConstant.DELETED
            });
        }
    }

    copyItem() {
        if (this.selectedItem) {
            this.onCopy.emit(this.selectedItem);
        }
    }

    getRowNo(item: any): number {
        return this.getRowNoByModel(item.data);
    }

    getRowNoByModel(model: PriceRuleModel) {
        return this.priceRuleSearchResults?.indexOf(model) + 1;
    }

    refreshResult() {
        this.onRefresh.emit();
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Code: data.priceRuleCode,
            Name: data.priceRuleName,
            PriceRuleGroup: data.priceRuleGroupCode,
            PriceRuleType: data.priceRuleTypeCode,
            UsageType: data.usageTypeCode,
            CommitBy: data.commitBy,
            CommitDateTime: this.dataConverterService.convertDate(
                data.commitDateTime
            ),
            Status: this.getStatusName(data.statusCode),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences.find((item) => item.statusCode == statusCode)
            ?.displayName;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, this.getRowNoByModel(data));
            })
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public updateStatus(statusCode: string) {
        this.onStatusChange.emit({
            item: this.selectedItem,
            statusCode: statusCode
        })
    }

    get copyDisabled() {
        return (!this.selectedItem || (this.selectedItem?.statusCode == 'D' && this.selectedItem?.usageTypeCode == 'FILTER')) || !this.userSecurity?.copyFlag;
    }

    get deleteFlag(): boolean {
        return this.userSecurity?.deleteFlag ?? false;
    }
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ActionBarDetailHandlerModel } from './actionbar-detail-handler.model';
import { ACTION_STATUS } from './actionbar-detail.constant';
import { ActionbarDetailNotifier } from './actionbar-detail.notifier';
import { ActionbarDetailService } from './actionbar-detail.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { GlobalStateService } from '../uiservice/global-state.service';

@Component({
    selector: 'op-actionbar-detail',
    templateUrl: './actionbar-detail.component.html',
})
export class ActionbarDetailComponent implements OnInit {
    public readonly componentId = this.stringHelperService.NewGuid();
    notifier: ActionbarDetailNotifier = new ActionbarDetailNotifier();
    actionBarHandler: ActionBarDetailHandlerModel = new ActionBarDetailHandlerModel();
    action$ = new Subject<string>();
    actionStatus = ACTION_STATUS;

    @Input('actionBarHandler') a: ActionBarDetailHandlerModel;
    @Input() useGlobalState: boolean = true;

    get globalDisableFlag$(): Observable<boolean> {
        if (!this.globalStateService) return of(false);
        return this.globalStateService.disabled$(this.componentId);
    }

    constructor(private actionbarService: ActionbarDetailService,
        private changeDetectorRef: ChangeDetectorRef,
        private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService) { }

    ngOnInit(): void {
        this.notifier = this.actionbarService.notifier;
        this.notifier.getCurrentState = () => {
            return this.actionBarHandler;
        }

        this.notifier.updateState = (handler: ActionBarDetailHandlerModel) => {
            this.actionBarHandler = handler;
            this.changeDetectorRef.detectChanges();
        }

        if (this.useGlobalState) {
            this.registerGlobalDisable();
        }
    }

    onClick(event, actionId: string) {
        event.preventDefault();
        this.notifier.action$.next(actionId);
    }

    isPayButton(actionId: string) {
        return actionId == this.actionStatus.pay;
    }

    private registerGlobalDisable() {
        if (!this.globalStateService) return;
        this.globalStateService.register(this.componentId);
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { AttributeMaintenanceService } from 'src/app/core/services/system-services/attribute-maintenance.service';
import { ExportButtonGroupComponent } from 'src/app/shared/ui/export-button-group/export-button-group.component';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AttributeSearchTableView } from '../../shared/view/attribute-search-table.view';

@Component({
    selector: 'op-attribute-search-table',
    templateUrl: './attribute-search-table.component.html'
})
export class AttributeSearchTableComponent extends DatatableComponentBase implements OnInit, AfterViewInit {
    @Input() statusReference: StatusReferenceModel[];
    @Input() attributeSearchResults: AttributeSearchTableView[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSelected = new EventEmitter<string>();
    @Output() onNew = new EventEmitter();
    @Output() onEdit = new EventEmitter<AttributeSearchTableView>();

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    showHeaderFilter: boolean = true;
    selectedItem: boolean = false;
    applyFilterTypes: any;
    currentFilter: any;
    itemSelected: any;
    exportData: any;
    header: string[];

    public collapsed: boolean;
    public focused: boolean;
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    public rows = 0;

    constructor(private ref: ChangeDetectorRef,
        private attributeMaintenanceService: AttributeMaintenanceService,
        private navigationService: NavigationService) {
            super();
    }

    ngOnInit(): void {
        this.applyFilterTypes = [{
            key: "auto",
            name: "Immediately"
        }];
        setTimeout(() => {
            this.currentFilter = this.applyFilterTypes[0].key;
        }, 50);
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
        this.assignFlagToExportbuttonGroup();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.ref.detectChanges();
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
    }

    doubleClick() {
        this.editItem();
    }

    editItem() {
        if (this.selectedItem) {
            this.onEdit.emit(this.itemSelected);
        }
    }

    newItem() {
        this.onNew.emit();
    }

    updateStatus(data: any, e: any) {
        this.attributeMaintenanceService.updateAttributeStatus(data.attributeCode, e)
            .subscribe(
                () => {
                    data.statusCode = e;
                    this.ref.detectChanges();
                }
            );
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Code: dat.attributeCode,
                Name: dat.attributeName,
                DimensionUnitCode: dat.dimensionType ?? '',
                ShowOnNew: dat.showOnNew,
                RequiredAttribute: dat.requiredAttribute,
                SortSequence: dat.sortSequence,
                CommitBy: dat.commitByName ?? '',
                CommitDateTime: dat.commitDateTime ?? '',
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReference.filter((dat) => dat.statusCode == statusCode)[0]?.displayName;
    }

    public refreshTable() {
        this.dataGrid.instance.refresh();
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    private assignFlagToExportbuttonGroup() {
        this.exportButtonGroup.copyToClipboardFlag = this.userSecurity?.copyToClipboardFlag;
        this.exportButtonGroup.printFlag = this.userSecurity?.printFlag;
        this.exportButtonGroup.exportFlag = this.userSecurity?.exportFlag;
    }

    public copyAttribute() {
        if (this.selectedItem) {
            let param = {
                id: this.itemSelected.attributeCode,
                attributeName: this.itemSelected.attributeName,
                newFlag: false,
                userSecurity: this.userSecurity,
                copyFlag: true
            }
            this.navigationService.navigate('/main/settings/attribute/details', null, null, param);
        }
    }
}


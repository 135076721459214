import { Injectable } from '@angular/core';
import { ServiceCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { VehicleConfigurationCompartmentCommandModel, VehicleConfigurationCompartmentModel } from 'src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment';
import { ServiceCategoryView } from './servicecatetgory.view';


@Injectable()
export class ServiceCategoryMapperService {

    public toNewServiceCategoryViews(srvCateModels: ServiceCategoryReferenceModel[]): ServiceCategoryView[] {
        let views: ServiceCategoryView[] = new Array();
        if (views) {
            for (let srvCateModel of srvCateModels) {
                views.push(this.toNewServiceCategoryView(srvCateModel));
            }
        }
        return views;
    }

    private toNewServiceCategoryView(model: ServiceCategoryReferenceModel): ServiceCategoryView {
        var view = {} as ServiceCategoryView;
        view.serviceCategoryCode = model.serviceCategoryCode;
        view.serviceCategoryName = model.serviceCategoryName;
        view.use = false;
        return view;
    }

    public toEditServiceCategoryViews(srvCateModels: ServiceCategoryReferenceModel[], vccModels: VehicleConfigurationCompartmentModel[]): ServiceCategoryView[] {
        let views: ServiceCategoryView[] = new Array();
        if (views) {
            for (let srvCateModel of srvCateModels) {
                views.push(this.toEditServiceCategoryView(srvCateModel, vccModels));
            }
        }
        return views;
    }

    private toEditServiceCategoryView(srvCateModels: ServiceCategoryReferenceModel, vccModels: VehicleConfigurationCompartmentModel[]): ServiceCategoryView {
        var view = {} as ServiceCategoryView;
        view.serviceCategoryCode = srvCateModels.serviceCategoryCode;
        view.serviceCategoryName = srvCateModels.serviceCategoryName;
        let filter = vccModels.filter(x => x.serviceCategoryCode == srvCateModels.serviceCategoryCode);
        if (filter?.length) {
            view.use = true;
            view.vehicleConfigurationCompartmentId = filter[0].vehicleConfigurationCompartmentId;
            view.physicalCapacity = filter[0].physicalCapacity;
            view.columnLayout = filter[0].columnLayout.toString();
            view.row = filter[0].numberOfRows;
            view.columnCode = filter[0].columnCode?.toUpperCase();
        }
        else {
            view.use = false;
        }
        return view;
    }

    public toServiceCategoryCommands(views: ServiceCategoryView[]): VehicleConfigurationCompartmentCommandModel[] {
        let commands: VehicleConfigurationCompartmentCommandModel[] = new Array();
        if (views) {
            for (let view of views.filter(x => x.use == true && !x.vehicleConfigurationCompartmentId)) {
                commands.push(this.toServiceCategoryCommand(view));
            }
        }
        return commands;
    }

    public toServiceCategoryCopyCommands(views: ServiceCategoryView[]): VehicleConfigurationCompartmentCommandModel[] {
        let commands: VehicleConfigurationCompartmentCommandModel[] = new Array();
        if (views) {
            for (let view of views.filter(x => x.use == true)) {
                commands.push(this.toServiceCategoryCommand(view));
            }
        }
        return commands;
    }


    private toServiceCategoryCommand(view: ServiceCategoryView): VehicleConfigurationCompartmentCommandModel {
        var command = new VehicleConfigurationCompartmentCommandModel();
        if (view.vehicleConfigurationCompartmentId) {
            command.vehicleConfigurationCompartmentId = view.vehicleConfigurationCompartmentId;
        }
        command.serviceCategoryCode = view.serviceCategoryCode;
        command.columnLayout = view.columnLayout.toString();
        command.columnCode = view.columnCode?.toUpperCase();
        command.physicalCapacity = view.physicalCapacity;
        command.numberOfRows = view.row;
        command.statusCode = "A"
        return command;
    }
}
export class MediaTypeReferenceModel {
    public mediaTypeCode: string;
    public mediaTypeName: string;
    public maximumSize: number;
    public maximumHeight: number;
    public maximumWidth: number;
    public textOnlyFlag: boolean;
    public teletypeFlag: boolean;
    public mediaUseCode: string;
    public statusCode: string;
}
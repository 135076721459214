import { Component, ViewChild } from '@angular/core';
import { PriceRuleCurrencyModel } from 'src/app/core/models/pricing-model';
import { ProductPriceConditionViewModel } from 'src/app/core/models/product-model/fee-model';
import { UnitReferenceModel, IndividualAgeGroupReferenceModel, IndividualSocialTypeReferenceModel, ConditionReferenceModel, CurrencyReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { IndividualAgeGroupTypeReferenceService } from 'src/app/core/services/airline-services';
import { PricingService } from 'src/app/core/services/pricing-services';
import { ConditionReferenceService, IndividualSocialTypeReferenceService, UnitReferenceService, CurrencyReferenceService } from 'src/app/core/services/system-services';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { PriceModel } from 'src/app/modules/pricing/prices/shared/models';
import { PriceService } from 'src/app/modules/pricing/prices/shared/price.service';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { PricingDetailConstant } from 'src/app/modules/pricing/rules/price-rule-detail/shared/pricing-detail.constant';
import { BehaviorSubject, forkJoin } from "rxjs";
import { startWith } from "rxjs/operators";
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'op-voucher-product-condition',
    templateUrl: './voucher-product-condition.component.html'
})
export class VoucherProductConditionComponent {
    private readonly ACTIVE_STATUS = 'A';
    private readonly defaultUsageTypeCode = 'PRODUCT';
    private readonly requiredFields = ['priceCode', 'priceName'];
    public readonly errorInvalidCondition = 'Condition is invalid.';

    public focusing: boolean = false;

    private defaultPriceSearchCriteria: PriceModel;
    private productPriceConditions: ProductPriceConditionViewModel[];
    private doneLoading = false;
    private requiredValidate = false;
    public collapseFlag$ = new BehaviorSubject<boolean>(true);

    public rows = 0;
    public focusedRowKey: any;
    public dataSource: DataSource = this.createDataSource(null);
    public prices: PriceModel[];
    public priceRuleCurrencies: PriceRuleCurrencyModel[];

    public individualAgeGroupTypeReferences: IndividualAgeGroupReferenceModel[];
    public conditionReferences: ConditionReferenceModel[];
    public individualSocialTypeReferences: IndividualSocialTypeReferenceModel[];
    public unitReferences: UnitReferenceModel[];
    public currencyReferences: CurrencyReferenceModel[];

    @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
    
    constructor(private stringHelperService: StringHelperService,
        private priceService: PriceService,
        private pricingService: PricingService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private unitReferenceService: UnitReferenceService,
        private currencyReferenceService: CurrencyReferenceService) { 
            this.setPriceSearchDefaultCriteria()
            this.getData();
        }

    get hasData(): boolean {
        return !!(this.productPriceConditions?.length);
    }

    get allowAdd(): boolean {
        return this.doneLoading && this.dataSource?.items()?.length < 1
    }

    private setPriceSearchDefaultCriteria() {
        this.defaultPriceSearchCriteria = new PriceModel();
        this.defaultPriceSearchCriteria.status = this.ACTIVE_STATUS;
        this.defaultPriceSearchCriteria.usageTypeCode = this.defaultUsageTypeCode;
    }

    private createDataSource(productPriceConditions: ProductPriceConditionViewModel[]): DataSource {
        return new DataSource({
            key: 'productPriceConditionId',
            store: this.createArrayStore(productPriceConditions)
        });
    }

    private createArrayStore(productPriceConditions: ProductPriceConditionViewModel[]): ArrayStore {
        return new ArrayStore({
            key: 'productPriceConditionId',
            data: productPriceConditions?.map(ppc => {
                const price = this.prices?.find(pr => pr.priceId == ppc.priceId);
                return {
                    productPriceConditionId: ppc.productPriceConditionId,
                    priceCode: price.priceId,
                    priceName: price.priceId
                }
            }) ?? []
        })
    }

    private getData() {
        forkJoin({
            prices: this.priceService.search(this.defaultPriceSearchCriteria),
            priceRuleCurrencies: this.pricingService.getPriceRuleCurrencies(),
            individualAgeGroupTypeReferences: this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences(),
            conditionReferences: this.conditionReferenceService
                .getConditionsByCodes([
                    PricingDetailConstant.IS_OPERATOR_CODE,
                    PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
                    PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
                    PricingDetailConstant.TIER_CODE,
                ])
                .pipe(
                    startWith([
                        {
                            conditionCode: PricingDetailConstant.IS_OPERATOR_CODE,
                            conditionName: 'is, are',
                            sortSequence: 1,
                            statusCode: this.ACTIVE_STATUS
                        },
                    ])
                ),
            individualSocialTypeReferences: this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences(),
            unitReferences: this.unitReferenceService.getUnitReferences(),
            currencyReferences: this.currencyReferenceService.getCurrencyReferences()
        })
        .subscribe(
            ({
                prices,
                priceRuleCurrencies,
                individualAgeGroupTypeReferences,
                conditionReferences,
                individualSocialTypeReferences,
                unitReferences,
                currencyReferences
            }) => {
                this.prices = prices;
                this.priceRuleCurrencies = priceRuleCurrencies;
                this.individualAgeGroupTypeReferences = individualAgeGroupTypeReferences;
                this.conditionReferences = conditionReferences;
                this.individualSocialTypeReferences = individualSocialTypeReferences;
                this.unitReferences = unitReferences;
                this.currencyReferences = currencyReferences;
                this.doneLoading = true;
            }
        )
    }

    public setPriceCode(newData, value) {
        newData.priceName = value;
        (<any>this).defaultSetCellValue(newData, value);
    }

    public setPriceName(newData, value) {
        newData.priceCode = value;
        (<any>this).defaultSetCellValue(newData, value);
    }

    public onCellPrepared(e) {
        this.setInvalidCell(e);
        this.removeExpandButtonIfNewRow(e);
    }

    private removeExpandButtonIfNewRow(e: any) {
        if (e.rowType === "data" && e.column.command === "expand") {
            if (!e.data.priceCode) {
                e.cellElement.removeClass("dx-datagrid-expand");
                e.cellElement.empty();
            }
        }
    }

    private setInvalidCell(e: any) {
        if (e.rowType == 'data' &&
            this.requiredFields.includes(e.column.dataField) &&
            this.requiredValidate &&
            !e.value) {
            e.cellElement.addClass('invalid-cell');
        } else {
            e.cellElement.removeClass('invalid-cell');
        }
    }

    private showErrorCell(isShow: boolean) {
        this.requiredValidate = isShow;
        this.datagrid.instance.repaint();
    }

    public onSaved(e) {
        if (this.requiredValidate && !!(e.changes[0]?.data?.priceCode)) {
            this.showErrorCell(false);
        }
    }

    public refresh() {
        this.populateData(this.productPriceConditions);
    }

    public newItem() {
        let newId = this.stringHelperService.NewGuid();
        this.dataSource.store()
            .insert({
                priceRulePriceId: newId
            })
            .then(() => {
                this.dataSource.reload();
                this.collapseFlag$.next(false);
                this.focusedRowKey = newId;
            })
    }

    public delete() {
        if (!this.focusedRowKey) {
            return;
        }
        this.dataSource.store()
            .remove(this.focusedRowKey)
            .then(() => {
                this.focusedRowKey = null;
                this.dataSource.reload();
                if (this.hasData == false) {
                    this.collapseFlag$.next(true);
                }
            })
    }

    public getCurrencies(e) {
        const priceId = e.data.priceCode;
        if (!priceId) {
            return '';
        }
        
        return this.getCurrencyNames(this.getDistinctCurrencyCodes(priceId)).join(', ');
    }

    private getDistinctCurrencyCodes(priceId: string) {
        return [...new Set(this.priceRuleCurrencies
            .filter(c => c.priceId == priceId)
            .map(c => c.currencyCode))]
    }

    private getCurrencyNames(currencyCodes: string[]): string[] {
        return currencyCodes.map(currencyCode => 
            this.currencyReferences.find(cr => cr.currencyCode == currencyCode).currencyName);
    }

    private populateData(productPriceConditions: ProductPriceConditionViewModel[]) {
        this.dataSource = this.createDataSource(productPriceConditions);
        this.collapseFlag$.next(!this.hasData);
        this.datagrid?.instance?.collapseAll(-1);
        this.focusedRowKey = null;
    }

    public fillModelsToForm(productPriceConditions: ProductPriceConditionViewModel[]) {
        if (!productPriceConditions) {
            return;
        }
        this.productPriceConditions = productPriceConditions;
        this.populateData(this.productPriceConditions);
    }

    public fillFormsToModels(): ProductPriceConditionViewModel[] {
        const items = this.dataSource?.items();
        let productPriceConditions = [];
        if (!items?.length) {
            return productPriceConditions;
        }
        for (let item of items) {
            if (!item.priceCode) {
                this.showErrorCell(true);
                return null;
            }

            productPriceConditions.push({
                productPriceConditionId: item.productPriceConditionId,
                priceId: item.priceCode
            })
        }
        return productPriceConditions;
    }

    public validateForm(): boolean {
        const items = this.dataSource?.items();
        if (items.some(item => !item.priceCode)) {
            this.showErrorCell(true);
            return false;
        }
        return true;
    }

    getErrorMessage() {
        if (!this.validateForm()) {
            return this.errorInvalidCondition;
        }
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { IndividualSearchTableModule } from "src/app/core/components/individual-search-table";
import { OrganisationSelectionModule } from "src/app/core/components/organisation-selection";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { AttributeModule } from "../insight-detail/insight-detail-attribute/attribute-detail.module";
import { ProfileModule } from "../insight-detail/insight-detail-profile/profile-detail.module";
import { InsightSearchConditionComponent } from "./insight-search-condition/insight-search-condition.component";
import { InsightSearchDocumentComponent } from "./insight-search-document/insight-search-document.component";
import { InsightSearchFilterNameComponent } from "./insight-search-filter-name/insight-search-filter-name.component";
import { InsightSearchTableComponent } from "./insight-search-table/insight-search-table.component";
import { InsightSearchComponent } from "./insight-search.component";

@NgModule({
    declarations: [
        InsightSearchFilterNameComponent,
        InsightSearchTableComponent,
        InsightSearchDocumentComponent,
        InsightSearchComponent,
        InsightSearchConditionComponent
    ],
    exports: [
        InsightSearchFilterNameComponent,
        InsightSearchTableComponent,
        InsightSearchDocumentComponent,
        InsightSearchComponent,
        InsightSearchConditionComponent
    ],
    imports: [
        AlertBarModule,
        AttributeModule,
        ButtonsModule,
        CollapseModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DynamicRoutingModule,
        DxPopoverModule,
        DxTemplateModule,
        FocusingModule,
        FormsModule,
        IndividualSearchTableModule,
        ItemsFoundModule,
        MatIconModule,
        MatMenuModule,
        DxTemplateModule,
        NgbNavModule,
        OopsCategoriesModule,
        OrganisationSelectionModule,
        PanelsModule,
        ProfileModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        UiModule
    ],
    providers: [],
})
export class InsightSearchModule {    
}
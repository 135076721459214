import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxChartModule, DxDataGridModule } from "devextreme-angular";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductDashboardMerchandizeCompareLast30DComponent } from "./product-dashboard-merchandize-compare-last30d/product-dashboard-merchandize-compare-last30d.component";
import { ProductDashboardMerchandizeLastReceivedComponent } from "./product-dashboard-merchandize-lastreceived/product-dashboard-merchandize-lastreceived.component";
import { ProductDashboardMerchandizeMiniChartsComponent } from "./product-dashboard-merchandize-mini-charts/product-dashboard-merchandize-mini-charts.component";
import { ProductDashboardMerchandizeRevenueLast30DComponent } from "./product-dashboard-merchandize-revenue-last30d/product-dashboard-merchandize-revenue-last30d.component";
import { ProductDashboardMerchandizeRevenueLast7DComponent } from "./product-dashboard-merchandize-revenue-last7d/product-dashboard-merchandize-revenue-last7d.component";
import { ProductDashboardMerchandizeSoldLast30DComponent } from "./product-dashboard-merchandize-sold-last30d/product-dashboard-merchandize-sold-last30d.component";
import { ProductDashboardMerchandizeSoldLast7DComponent } from "./product-dashboard-merchandize-sold-last7d/product-dashboard-merchandize-sold-last7d.component";
import { ProductDashboardMerchandizeStatisticsComponent } from "./product-dashboard-merchandize-statistics/product-dashboard-merchandize-statistics.component";
import { ProductDashboardMerchandizeSummaryComponent } from "./product-dashboard-merchandize-summary/product-dashboard-merchandize-summary.component";
import { ProductDashboardMerchandizeComponent } from "./product-dashboard-merchandize.component";
import { DataGridRowNumberModule } from "../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductDashboardMerchandizeComponent,
        ProductDashboardMerchandizeCompareLast30DComponent,
        ProductDashboardMerchandizeLastReceivedComponent,
        ProductDashboardMerchandizeMiniChartsComponent,
        ProductDashboardMerchandizeRevenueLast7DComponent,
        ProductDashboardMerchandizeRevenueLast30DComponent,
        ProductDashboardMerchandizeSoldLast7DComponent,
        ProductDashboardMerchandizeSoldLast30DComponent,
        ProductDashboardMerchandizeSummaryComponent,
        ProductDashboardMerchandizeStatisticsComponent
    ],
    exports: [
        ProductDashboardMerchandizeComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxChartModule,
        DxDataGridModule,
        FocusingModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        UiModule
    ]
})
export class ProductDashboardMerchandizeModule { }
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { InsightOrderSearchFilterModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search-filter.model";
import { InsightOrderSearchModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search.model";
import { OrderProductModel } from "src/app/core/models/order-model";
import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightOrderService } from "src/app/core/services/individul-services/insight-order.service";
import { OrderDetailChildLoadData } from "src/app/modules/order/shared";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { InsightSalesSummaryFilterComponent } from "./insight-sales-summary-filter/insight-sales-summary-filter.component";

@Component({
    selector: 'op-insight-sales-summary',
    templateUrl: './insight-sales-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightSalesSummaryComponent {
    public insightOrderSearchResults: InsightOrderSearchModel[];
    public productSearchResults: OrderProductModel[];
    public productAmount = 0;

    @Input() individualId: string;
    @Input() countryReferences: CountryReferenceModel[];


    @ViewChild(InsightSalesSummaryFilterComponent) insightSalesSummaryFilterComonent: InsightSalesSummaryFilterComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef,
        private insightOrderService: InsightOrderService) {

    }

    ngOnInit(): void {
        this.getInsightOrders(this.individualId);
    }

    private getInsightOrders(individualId: string) {
        let filter : InsightOrderSearchFilterModel = {
            individualId: individualId,
            productCategorySearches: null,
            productNumberSearches: null,
            orderDateSearches: null,
            serviceDateSearches: null,
            locationAirportCitySearches: null,
            locationCountrySearches: null,
            locationGroupSearches: null,
            locationRegionSearches: null,
            orderStatusSearches: null,
            productStatusSearches: null,
            outstandingBalanceSearch: null
        }

        this.insightOrderService.searchInsightOrders(filter)
            .subscribe(
                (responses: InsightOrderSearchModel[]) => {
                    this.insightOrderSearchResults = responses;   
                    this.changeDetector.detectChanges();
                },
            )
    }

    countProductList(productOrder: OrderDetailChildLoadData) {
        this.productAmount = productOrder.recordCount;
        this.changeDetector.detectChanges();
    }

    serchByFilter(filter: InsightOrderSearchFilterModel) {
        this.insightOrderSearchResults = [];

        filter.individualId = this.individualId;
        this.insightOrderService.searchInsightOrders(filter)
            .subscribe(
                (responses: InsightOrderSearchModel[]) => {
                    this.insightOrderSearchResults = responses;   
                    this.changeDetector.detectChanges();
                },
            )
    }
}
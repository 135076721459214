import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';

import { DateTimeDimensionReferenceModel } from '../../models/reference-model/reference-general-model';


@Injectable({
  providedIn: 'root'
})
export class DateTimeDimensionReferenceService extends ApiServiceBase {

  private readonly REFERENCE_PATH = "/system/DateTimeDimensionReferences"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private stringHelperService: StringHelperService
  ) {
    super(httpClient, authService);
  }

  public getDateTimeDimensionReference(...dateTimeDimensionCodes: string[]): Observable<DateTimeDimensionReferenceModel[]> {
    let dateTimeDimensionCodesParam = this.stringHelperService.createQueryParamFromArray('dateTimeDimensionCodes', dateTimeDimensionCodes);
    return this.httpGet<DateTimeDimensionReferenceModel[]>(this.REFERENCE_PATH + dateTimeDimensionCodesParam, null)
      .pipe(
        map(
          res => res.body
        )
      )
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { OopsCategoriesModule } from 'src/app/core/components/categories/oops-categories.module';
import { MatIconModule } from '@angular/material/icon';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { AncillaryServiceContentComponent } from './ancillary-service-content.component';
import { AncillaryServiceTabsComponent } from './ancillary-service-tabs/ancillary-service-tabs.component';
import { AncillaryServiceSearchModule } from './ancillary-service-search/ancillary-service-search.module';
import { AncillaryServiceAttributeModule } from './ancillary-service-attribute/ancillary-service-attribute.module';
import { AncillaryServiceDetailModule } from './ancillary-service-detail/ancillary-service-detail.module';

@NgModule({
    declarations: [
        AncillaryServiceContentComponent,
        AncillaryServiceTabsComponent
    ],
    imports: [
        AncillaryServiceAttributeModule,
        AncillaryServiceSearchModule,
        AncillaryServiceDetailModule,
        CommonModule,
        FreezingTabModule,
        MatIconModule,
        NgbNavModule,
        OopsCategoriesModule,
        UiModule
    ],
    exports: [
        AncillaryServiceContentComponent
    ]
})
export class AncillaryServiceContentModule { }

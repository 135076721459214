import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { DomainAttributeService, HashTagReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { AncillaryServiceService } from 'src/app/core/services/product-services';
import { ProductSearchFilterService } from 'src/app/core/services/product-services/product-search-filter.service';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService } from 'src/app/core/services/system-services';
import { SeasonReferenceService } from 'src/app/core/services/system-services/season-reference.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { AttributeSearchModel } from 'src/app/core/models/attribute-type-reference-model';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { MapperService } from './shared/mapper.service';
import { AncillaryServiceSearchTableView } from './shared/ancillary-service-search-table.view';
import { AncillaryServiceSearchConditionComponent } from './ancillary-service-search-condition/ancillary-service-search-condition.component';
import { AncillaryServiceSearchTableComponent } from './ancillary-service-search-table/ancillary-service-search-table.component';
import { AncillaryServiceSearchCommandModel, AncillaryServiceViewModel } from 'src/app/core/models/product-model/ancillary-service-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ProductSearchFilterModel } from 'src/app/core/models/product-model/product-search-filter-model/product-search-filter-model';

@Component({
    selector: 'op-ancillary-service-search',
    templateUrl: './ancillary-service-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProductTypeGroupService,
        MapperService,
        Select2DataMapperService,
        HashTagMapperService
    ]
})
export class AncillaryServiceSearchComponent implements OnInit, AfterViewInit {
    readonly SERVICE_PRODUCT_CATE_CODE = 'ANCILLARY';
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";

    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public productSearchFilters$ = new BehaviorSubject<Select2Data[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSearchResults$ = new BehaviorSubject<AncillaryServiceSearchTableView[]>(null);
    public paggingView: PagingDataView[];
    public selectedItem: any;
    private unsubscribe$ = new Subject();
    public advanceSearchModeEnabled = false;
    public ancillaryServiceView$ = new BehaviorSubject<AncillaryServiceViewModel>(null);
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public ancillaryServiceFilterModel: AncillaryServiceViewModel;
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    private actionBarHandler: ActionBarHandlerModel;
    public userSecurity: SecurityGroupSecurityModel;
    private searchCriteria: AncillaryServiceViewModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    @Input() productGroup: string;
    @Input() selectedProductCategory: ProductCategory;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @ViewChild(AncillaryServiceSearchConditionComponent) searchConditionComponent: AncillaryServiceSearchConditionComponent;
    @ViewChild(AncillaryServiceSearchTableComponent) searchTableComponent: AncillaryServiceSearchTableComponent;

    constructor(
        private productGroupReferenceService: ProductGroupReferenceService,
        private productSearchFilterService: ProductSearchFilterService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private ancillaryServiceService: AncillaryServiceService,
        private mapperService: MapperService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private navigationService: NavigationService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.getParams();
    }

    ngOnInit(): void {
        this.getReferencesData().then(() => {
            this.changeDetectionRef.detectChanges();
            this.searchDefault();
        });
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.getSelectedServiceGroup();

        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private getSelectedServiceGroup() {
        if (this.productGroup != null) {
            this.searchConditionComponent.productGroupCode = this.productGroup
        }
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferenceFilter$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    public onSearch(condition: AncillaryServiceViewModel) {
        if (!condition) {
            return;
        }
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
        this.focusOnSearchResult();
    }

    private search(searchCommand: AncillaryServiceSearchCommandModel) {
        this.ancillaryServiceService.search(searchCommand)
            .subscribe(
                (responses: AncillaryServiceViewModel[]) => {
                    this.paggingView = new Array<PagingDataView>();
                    let searchResults = this.mapperService.ancillaryServiceSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                    this.getSearchCriteriaParams();
                }
            );
    }

    private focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    public onClearCondition(condition: AncillaryServiceViewModel) {
        this.advanceSearchModeEnabled = false;
        if (!condition) {
            condition = {} as AncillaryServiceViewModel;
            condition.statusCode = this.ACTIVE_STATUS;
        }
        this.ancillaryServiceView$.next(condition);
        this.searchTableComponent.dataGrid.instance.clearFilter();
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.searchTableComponent.onNew();
                break;
            case ACTION_STATUS.copy:
                this.onCopyClick();
                break;
        }
    }

    onAncillaryServiceSearchFilterIdChange(productId: string) {
        if (productId) {
            this.getAncillaryService(productId);
        } else {
            this.onClearCondition(null);
        }
    }

    private getAncillaryService(productId: string) {
        this.spinnerService.show();
        this.ancillaryServiceService.getByProductId(productId)
            .subscribe(
                (response: AncillaryServiceViewModel) => {
                    this.spinnerService.hide();
                    response = this.getIsOwnerFilter(response);
                    this.ancillaryServiceView$.next(response);
                    this.ancillaryServiceFilterModel = response;
                    this.toggleCopyButton();
                },
                () => {
                    this.spinnerService.hide()
                }
            )
    }

    public onCopyClick() {
        if (this.ancillaryServiceFilterModel) {
            this.copyFilter();
        } else {
            this.searchTableComponent.onCopy();
        }
    }

    onSelectedChange(item: AncillaryServiceViewModel) {
        this.selectedItem = item;
    }

    async copyFilter() {
        if (!this.ancillaryServiceFilterModel) {
            return;
        }
        this.advanceSearchModeEnabled = true;
        this.ancillaryServiceFilterModel.productName += ' - copy';
        this.ancillaryServiceFilterModel.productId = null;

        this.ancillaryServiceView$.next(this.ancillaryServiceFilterModel);

        this.ancillaryServiceFilterModel = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).enable(!!(this.ancillaryServiceFilterModel))
            this.actionbarService.updateState(handler);
        }, 0);
    }

    editFilter(productId: string) {
        this.advanceSearchModeEnabled = true;
        this.getAncillaryService(productId);
    }

    getIsOwnerFilter(response: AncillaryServiceViewModel): AncillaryServiceViewModel {
        response.isOwnerFilter = response.filterUserAccountId ? true : false;
        return response
    }

    checkSpecialServiceAttribute(searchAttributeCode: string, serviceAttributes: AttributeSearchModel[]) {
        return serviceAttributes.findIndex(item => item.attributeCode == searchAttributeCode) !== -1;
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchConditionComponent.doSearch();
                break;
            default:
                break;
        }
    }

    private getReferencesData(): Promise<void> {
        return new Promise((resolve, reject) => {
            forkJoin({
                productSearchFilters: this.productSearchFilterService.getByProductCategory(this.SERVICE_PRODUCT_CATE_CODE),
                productGroupReferences: this.productGroupReferenceService.getAll(),
                productTypeGroups: this.productTypeGroupService.getAll(),
                statusReferences: this.statusReferenceService.getAll(),
                usageTypeReferences: this.usageTypeReferenceService.getUsageTypeReferences(),
                hashTagReferences: this.hashTagReferenceService.getAll(),
                suppliers: this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE),
                providers: this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE),
                productNumberTypeReferences: this.productNumberTypeReferenceService.getAll(),
                domainAttributeServices: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE),
                domainAttributeInventories: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV),
                seasonReferences: this.seasonReferenceService.getAll(),
                conditionreferences: this.conditionReferenceService.getConditionsByCodes([]),
                countryReferences: this.countryReferenceService.searchAllCountry(),
                locationGroups: this.locationGroupService.getAll(),
                productLocationPointReferences: this.productLocationPointReferenceService.getAll(),
                productLocationTypeReferences: this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE),
                regionReferences: this.regionReferenceService.getRegionReference(),
                calendarValidityReferences: this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE),
                dateTimeReferences: this.dateTimeDimensionReferenceService.getDateTimeDimensionReference(),
                organisations: this.organisationService.getByStatus(this.ACTIVE_STATUS),
                organisationGroupReferences: this.organisationGroupReferenceService.getOrganisationGroupReference(),
                organisationTypeReferences: this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS),
                organisationRoleReferences: this.organisationRoleReferenceService.getAllActive(),
                productCategoryReferences: this.productCategoryReferenceService.getAll()
            })
                .subscribe(({
                    productSearchFilters,
                    productGroupReferences,
                    productTypeGroups,
                    statusReferences,
                    usageTypeReferences,
                    hashTagReferences,
                    suppliers,
                    providers,
                    productNumberTypeReferences,
                    domainAttributeServices,
                    domainAttributeInventories,
                    seasonReferences,
                    conditionreferences,
                    countryReferences,
                    locationGroups,
                    productLocationPointReferences,
                    productLocationTypeReferences,
                    regionReferences,
                    calendarValidityReferences,
                    dateTimeReferences,
                    organisations,
                    organisationGroupReferences,
                    organisationTypeReferences,
                    organisationRoleReferences,
                    productCategoryReferences
                }) => {
                    this.fillProductSearchFilters(productSearchFilters);
                    this.fillProductGroupReferences(productGroupReferences);
                    this.fillProductTypeGroups(productTypeGroups);
                    this.fillStatusReferences(statusReferences);
                    this.fillUsageTypeReferences(usageTypeReferences);
                    this.fillHashTagReferences(hashTagReferences);
                    this.fillSuppliers(suppliers);
                    this.fillProviders(providers);
                    this.fillProductNumberTypeReferences(productNumberTypeReferences);
                    this.fillDomainAttributeTypeServices(domainAttributeServices);
                    this.fillDomainAttributeTypeInventories(domainAttributeInventories);
                    this.fillSeasonReferences(seasonReferences);
                    this.fillConditionReferences(conditionreferences);
                    this.fillCountryReferences(countryReferences);
                    this.fillLocationGroups(locationGroups);
                    this.fillProductLocationPointReferences(productLocationPointReferences);
                    this.fillProductLocationTypeReferences(productLocationTypeReferences);
                    this.fillRegionReferences(regionReferences);
                    this.fillCalendarValidityReferences(calendarValidityReferences);
                    this.fillDateTimeReferences(dateTimeReferences);
                    this.fillOrganisations(organisations);
                    this.fillOrganisationGroupReferences(organisationGroupReferences);
                    this.fillOrganisationTypeReferences(organisationTypeReferences);
                    this.fillOrganisationRoleReferences(organisationRoleReferences);
                    this.fillProductCategoryReferences(productCategoryReferences)
                    resolve();
                })
        });
    }

    private fillProductSearchFilters(productSearchFilters: ProductSearchFilterModel[]) {
        let searchfilters = productSearchFilters.map(r => new Select2Data(r.productId, r.productName));
        this.productSearchFilters$.next(searchfilters);
    }

    private fillProductGroupReferences(productGroupReferences: ProductGroupReferenceModel[]) {
        let serviceProductGroup = productGroupReferences.filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE);
        this.serviceProductGroupReference$.next(serviceProductGroup);
    }

    private fillProductTypeGroups(productTypeGroups: ProductTypeGroupModel[]) {
        this.productTypeGroup$.next(productTypeGroups);
    }

    private fillStatusReferences(statusReferences: StatusReferenceModel[]) {
        this.statusReference$.next(statusReferences);
    }

    private fillUsageTypeReferences(usageTypeReferences: UsageTypeReferenceModel[]) {
        if (usageTypeReferences) {
            this.getUsageTypeReferenceWithFilter(usageTypeReferences);
        }
    }

    private fillHashTagReferences(hashTagReferences: HashTagReferenceModel[]) {
        this.hashTagReference$.next(hashTagReferences);
    }

    private fillSuppliers(suppliers: OrganisationModel[]) {
        this.supplier$.next(suppliers);
    }

    private fillProviders(providers: OrganisationModel[]) {
        this.provider$.next(providers);
    }

    private fillProductNumberTypeReferences(productNumberTypeReferences: ProductNumberTypeReferenceModel[]) {
        this.productNumberTypeReference$.next(productNumberTypeReferences);
    }

    private fillDomainAttributeTypeServices(domainAttributeServices: DomainAttributeModel[]) {
        this.domainAttributeService$.next(domainAttributeServices);
    }

    private fillDomainAttributeTypeInventories(domainAttributeInventories: DomainAttributeModel[]) {
        if (domainAttributeInventories) {
            this.domainAttributeINV$.next(
                domainAttributeInventories.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
        }
    }

    private fillSeasonReferences(seasonReferences: SeasonReferenceModel[]) {
        this.seasonReference$.next(seasonReferences);
    }

    private fillConditionReferences(conditionReferences: ConditionReferenceModel[]) {
        this.conditionReference$.next(conditionReferences);
    }

    private fillCountryReferences(countryReferences: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(countryReferences);
    }

    private fillLocationGroups(locationGroups: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(locationGroups);
    }

    private fillProductLocationPointReferences(productLocationPointReferences: ProductLocationPointReferenceModel[]) {
        this.productLocationPointReference$.next(productLocationPointReferences);
    }

    private fillProductLocationTypeReferences(productLocationTypeReferences: ProductLocationTypeReferenceModel) {
        if (productLocationTypeReferences) {
            let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
            productLocationTypeReference.push(productLocationTypeReferences);
            this.productLocationTypeReference$.next(productLocationTypeReference);
        }
    }

    private fillRegionReferences(regionreferences: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(regionreferences);
    }

    private fillCalendarValidityReferences(calendarValidityReferences: CalendarValidityReferenceModel[]) {
        this.calendarValidityReference$.next(calendarValidityReferences);
    }

    private fillDateTimeReferences(datetimeReferences: DateTimeDimensionReferenceModel[]) {
        this.dateTimeDimensionReference$.next(datetimeReferences);
    }

    private fillOrganisations(organisations: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(organisations);
    }

    private fillOrganisationGroupReferences(organisationGroupReferences: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(organisationGroupReferences);
    }

    private fillOrganisationTypeReferences(organisationTypeReferences: OrganisationTypeReferenceModel[]) {
        this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(organisationTypeReferences);
    }

    private fillOrganisationRoleReferences(organisationRoleReferences: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(organisationRoleReferences);
    }

    private fillProductCategoryReferences(productCategoryReferences: ProductCategoryReferenceModel[]) {
        this.productCategoryReference$.next(productCategoryReferences);
    }

    private getSearchCriteriaParams() {
        this.searchCriteria = this.searchConditionComponent.getValues();
        this.searchTableComponent.searchCriteria = this.searchCriteria;
    }

    private getParams() {
        let params = this.navigationService.getParams();
        this.searchCriteria = params?.searchCriteria;
    }

    private searchDefault() {
        if (this.searchCriteria) {
            this.fillSearchCriteria().then(() => {
                this.searchConditionComponent.doSearch();
            })
        } else {
            this.searchTableComponent.searchCriteria = null;
            this.searchAll();
        }
    }

    private fillSearchCriteria(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.fillModelToCondition(this.searchCriteria);
            this.changeDetectionRef.detectChanges();
            resolve();
        });
    }

    private fillModelToCondition(views: AncillaryServiceViewModel) {
        this.searchConditionComponent.fillViewToForm(views);
        this.changeDetectionRef.detectChanges();
    }

    private searchAll() {
        if (this.executeFlag == true) {
            this.searchConditionComponent.doSearch();
        }
    }
}

export const select2OperatorRestrictionVehicle = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2VehicleGroup = {
    placeholder: "<Vehicle Group>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2VehicleType = {
    placeholder: "<Vehicle Type>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2VehicleComposition = {
    placeholder: "<Composition>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}
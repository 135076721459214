export class Select2Data {
    public id: string;
    public text: string;
    public selected: boolean;
    public disabled: boolean;
    public action?: any;
    public elementId?: string;
    
    constructor(id: string = null, text: string = null, selected: boolean = null){
        if (id && text){
            this.id = id;
            this.text = text;
        }
        if (selected) {
            this.selected = selected
        }
    }
}
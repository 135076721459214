import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories";
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { DataGridRowNumberModule } from "../../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";
import { SchedulePeriodContentComponent } from "./schedule-period-content.component";
import { SchedulePeriodHomeComponent } from "./schedule-period-home/schedule-period-home.component";
import { SchedulePeriodTableComponent } from "./schedule-period-table/schedule-period-table.component";
import { ScheduleProductTableComponent } from "./schedule-period-table/schedule-product-table/schedule-product-table.component";
import { StateColorDropdownModule } from "src/app/shared/ui/forms/inputs/state-color-dropdown/state-color-dropdown.module";
import { ProductValidationComponent } from "./schedule-period-table/product-validation-table/product-validation-table.component";

@NgModule({
    declarations: [
        ProductValidationComponent,
        SchedulePeriodContentComponent,
        SchedulePeriodHomeComponent,
        SchedulePeriodTableComponent,
        ScheduleProductTableComponent
    ],
    exports: [
        SchedulePeriodContentComponent
    ],
    imports: [
        ButtonsModule,
        CollapseModule,
        CommonModule,
        DataGridRowNumberModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DynamicRoutingModule,
        FocusingModule,
        FormsModule,
        LoadingSpinnerModule,
        MatIconModule,
        MatMenuModule,
        NgbModule,
        NgbNavModule,
        OopsCategoriesModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        StateColorDropdownModule,
        UiModule
    ]
})
export class SchedulePeriodContentModule {}
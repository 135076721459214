import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { CountryReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { select2SearchCity, select2SearchCountry, select2SearchFilter, 
    select2SearchOrganisation, select2SearchStatus, select2SearchUsageType } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-search-condition',
    templateUrl: './insight-search-condition.component.html',
})
export class InsightSearchConditionComponent implements AfterViewInit {
    public searchFilterOption: any;
    public organisationOption: any;
    public usageTypeOption: any;
    public cityOption: any;
    public countryOption: any;   
    public statusOption: any;
    public blurAfterSelected: boolean = true;
    public focused: boolean = false;
    
    @Input() filter: InsightPassengerSearchModel;    
    @Input() statusReferences: StatusReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Input() advanceSearchMode: boolean;
    @Input() insightPassengerFilters: InsightPassengerSearchModel[];
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;

    @Output() searchClick = new EventEmitter<InsightPassengerSearchModel>();
    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() clearSearchFilter = new EventEmitter();
    @Output() searchFilterIdChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor (private actionService: ActionService) {
        this.searchFilterOption = select2SearchFilter;
        this.organisationOption = select2SearchOrganisation;
        this.usageTypeOption = select2SearchUsageType;
        this.cityOption = select2SearchCity;
        this.countryOption = select2SearchCountry;
        this.statusOption = select2SearchStatus;
    }

    ngAfterViewInit(): void {
        if (this.searchFilterId) {
            this.filter.individualId = this.searchFilterId;
            this.onSearchFilterIdChange(this.searchFilterId);
        }
    }

    public searchButtoneClick() {
        this.searchClick.emit();
    }

    public onAdvanceSearchClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    public getConditionValue(): InsightPassengerSearchModel {
        return this.filter;
    }

    public clearCondition() {
        this.clearSearchFilter.emit();
    }

    public onSearchFilterIdChange(individualId: any) {
        this.filter.individualId = individualId;
        this.searchFilterIdChange.emit(individualId);
        this.actionService.add(FavoriteConstant.SEARCH_ACTION, individualId, this.getSearchFilterName(individualId));
        if (!individualId) {
            this.clearSearchFilter.emit();
        }
    }

    private getSearchFilterName(filterId: string) {
        return this.insightPassengerFilters?.filter(item => item.individualId == filterId)[0]?.searchName;
    }

    trimInput(input) {
        input.target.value = input.target.value.trim();
    }

    public onValueChange(value: any, fieldName: string) {
        this.filter[fieldName] = value;
    }
} 
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxChartModule, DxDataGridModule } from "devextreme-angular";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductDashboardSoldLast30DComponent } from "./product-dashboard-sold-last30d/product-dashboard-sold-last30d.component";
import { ProductDashboardSoldLast7DComponent } from "./product-dashboard-sold-last7d/product-dashboard-sold-last7d.component";
import { ProductDashboardSummaryComponent } from "./product-dashboard-sales-summary/product-dashboard-summary.component";
import { ProductDashboardStatisticsComponent } from "./product-dashboard-statistics/product-dashboard-statistics.component";
import { ProductDashboardComponent } from "./product-dashboard.component";
import { ProductDashboardRevenueLast7DComponent } from "./product-dashboard-revenue-last7d/product-dashboard-revenue-last7d.component";
import { ProductDashboardRevenueLast30DComponent } from "./product-dashboard-revenue-last30d/product-dashboard-revenue-last30d.component";
import { ProductDashboardCompareLast30DComponent } from "./product-dashboard-compare-last30d/product-dashboard-compare-last30d.component";
import { ProductDashboardLastReceivedComponent } from "./product-dashboard-lastreceived/product-dashboard-lastreceived.component";
import { ProductDashboardMiniChartsComponent } from "./product-dashboard-mini-charts/product-dashboard-mini-charts.component";
import { DataGridRowNumberModule } from "../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductDashboardComponent,
        ProductDashboardCompareLast30DComponent,
        ProductDashboardLastReceivedComponent,
        ProductDashboardMiniChartsComponent,
        ProductDashboardRevenueLast7DComponent,
        ProductDashboardRevenueLast30DComponent,
        ProductDashboardSoldLast7DComponent,
        ProductDashboardSoldLast30DComponent,
        ProductDashboardSummaryComponent,
        ProductDashboardStatisticsComponent
    ],
    exports: [
        ProductDashboardComponent,
        ProductDashboardMiniChartsComponent,
        ProductDashboardRevenueLast7DComponent,
        ProductDashboardStatisticsComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxChartModule,
        DxDataGridModule,
        FocusingModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        UiModule
    ]
})
export class ProductDashboardModule { }
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { SecurityLevel2ContextMenuItem } from './security-level2-context-menu-item.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-level2-context-menu',
    templateUrl: './security-Level2-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityLevel2ContextMenuComponent {
    @Input() menuItems: SecurityLevel2ContextMenuItem[];
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Input() securities: SecurityGroupSecurityModel[];
    @Output() onSelectMessageLogRule: EventEmitter<string> = new EventEmitter<string>();

    get editFlag(): boolean {
        return this.moduleSecurity?.editFlag;
    }

    get copyFlag(): boolean {
        return this.moduleSecurity?.copyFlag;
    }

    public showContextMenu(): boolean {
        return false;
    }

    public selectMessageLogRuleMenu(e) {
        this.onSelectMessageLogRule.emit(e);
    }

    public checkDisable(menu: SecurityLevel2ContextMenuItem): boolean {
        switch (menu.code) {
            case 'EDIT':
                return this.editFlag;
            case 'COPY':
                return this.copyFlag;
            case 'CODE':
                return this.checkSecurityCode();
            case 'GROUP':
                return this.checkSecurityGroup();
            default:
                return !menu.disable;
        }
    }

    private checkSecurityCode(): boolean {
        return !!this.securities?.find(item => item.securityCode == 'SECURITYCODE');
    }

    private checkSecurityGroup(): boolean {
        return !!this.securities?.find(item => item.securityCode == 'SECURITYGROUP');
    }
}
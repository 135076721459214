import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";


export const PRODUCT_DASHBOARD_SPECIALSERVICE_LASTRECEIVED_DEMO: ProductDashboardLastReceivedModel[] = [
    {
        productGroupCode: "COMFORT",
        productType: "Comfort",
        productName: "Comfort",
        departureName: "Sri Lanka",
        arrivalName: "India", 
        productCount: 67,
        amount: 453207.36
    },
    {
        productGroupCode: "DISB",
        productType: "Passenger Assistance",
        productName: "Passenger Assistance",
        departureName: "Delhi",
        arrivalName: "Patna", 
        productCount: 51,
        amount: 890365.43
    },
    {
        productGroupCode: "INFANT",
        productType: "Infants",
        productName: "Infants",
        departureName: "Mumbai",
        arrivalName: "Siliguri", 
        productCount: 5,
        amount: 725361.09
    },
    {
        productGroupCode: "MEAL",
        productType: "Meals",
        productName: "Kosher Meal",
        departureName: "Singapore",
        arrivalName: "Tokyo", 
        productCount: 43,
        amount: 135265.93
    },
    {
        productGroupCode: "MEDICAL",
        productType: "Medical Issues",
        productName: " Gauze Sponges",
        departureName: "Beijing",
        arrivalName: "Chengdu", 
        productCount: 24,
        amount: 65462.77
    },
    {
        productGroupCode: "MEMBER",
        productType: "Frequent Flyer",
        productName: "Frequent Flyer",
        departureName: "Lisbon",
        arrivalName: "Sao Paulo", 
        productCount: 75,
        amount: 985800.43
    },
    {
        productGroupCode: "PET",
        productType: "Animals",
        productName: "Animals Cage",
        departureName: "Tel Aviv",
        arrivalName: "Bangkok", 
        productCount: 36,
        amount: 384900.50
    },
    {
        productGroupCode: "WCHR",
        productType: "Wheelchairs",
        productName: "Wheelchairs",
        departureName: "Wellington",
        arrivalName: "Sydney", 
        productCount: 18,
        amount: 112387.00
    },
    {
        productGroupCode: "OTHS",
        productType: "Others",
        productName: "Others",
        departureName: "Cape Town",
        arrivalName: "Port Elizabeth", 
        productCount: 50,
        amount: 655326.24
    }
]

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { DxDataGridComponent } from "devextreme-angular";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { TransportService } from "src/app/core/services/product-services";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { OperatingProductListMapperService } from "./operating-product-list.mapper.service";
import { OperatingProductListView } from "./operating-product-list.view";
import { TransportOperatingProductViewModel } from "src/app/core/models/product-model/transport-model/transport-operating-product-view.model";
import { NavigationService } from "src/app/shared/utils/navigation";
import { OperatingProductService } from "src/app/core/services/schedule-services";
import { OperatingProductSearchDetailViewModel } from "src/app/core/models/product-model/operating-product-model";

@Component({
    selector: 'op-operating-product-listview',
    templateUrl: './operating-product-listview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OperatingProductListMapperService]
})
export class OperatingProductListViewComponent implements AfterViewInit, OnChanges {

    public readonly EXPORT_FILE_NAME = "OperatingProducts";
    public readonly SPINNER_NAME: string = "operatingProductSpinner";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    private readonly STARUS_ARRIVED = 'ARRIVED';
    private readonly STARUS_CANCELLED = 'CANCELLED';
    private readonly STARUS_DELAYED = 'DELAYED';
    private readonly STARUS_DELIVERED = 'DELIVERED';
    private readonly STARUS_DEPARTED = 'DEPARTED';
    @Input() productId: string;
    @Input() refresh: boolean;
    @Input() fullView: boolean;
    @Input() routeName: string;
    @Output() onCalendarViewClick = new EventEmitter();
    @ViewChild('gridResults', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public focusing: boolean = false;
    public operatingProducts: OperatingProductListView[];
    public loadingNotifier = new LoadingNotifier();
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };
    public selectedItem: any;
    public dataSourceStorage: any; 

    constructor(private mapperService: OperatingProductListMapperService,
        private transportService: TransportService,
        private changeDetectionRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private operatingProductService: OperatingProductService) {
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['productId'] || this.refreshChange(changes)) {
            this.selectedItem = null;
            this.dataSourceStorage = [];
            this.getOperatingProduct();
        }
    }

    public getOperatingProduct() {
        if (this.productId) {
            this.clearForm();
            this.loadingNotifier.show(this.SPINNER_NAME);
            this.transportService.getOperatingProduct(this.productId)
                .subscribe(
                    (response: TransportOperatingProductViewModel[]) => {
                        this.fillOperatingProduct(response);
                        this.refresh = false;
                        this.loadingNotifier.hide(this.SPINNER_NAME);
                    }
                ),
                () => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                }
        }
    }

    private fillOperatingProduct(operatingProducts: TransportOperatingProductViewModel[]) {
        let operatingProductViews = this.mapperService.toViews(operatingProducts);
        this.operatingProducts = cloneDeep(operatingProductViews);
        this.dataGrid.instance.refresh();
        this.changeDetectionRef.detectChanges();
    }

    public clearForm() {
        this.dataGrid?.instance?.state({});
        this.operatingProducts = [];
        this.changeDetectionRef.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().filter(item => item.caption).map(item => item.name);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

    private mapData(data: any, no: any): any {
        return {
            No: no,
            Date: data.localDate,
            Day: data.localDay,
            Time: data.localTime,
            Dep: data.dept,
            Arr: data.arr,
            DepLoad: data.deptLoad,
            ArrLoad: data.arrLoad,
            Booked: data.booked,
            Avai: data.avai,
            Factor: data.load,
            Status: data.status
        };
    }

    private refreshChange(changes: SimpleChanges): boolean {
        if (changes['refresh'] && this.refresh) {
            return true;
        }
        return false;
    }

    onRowPrepared(e) {
        if (e.rowType == 'data') {
            this.setLoadBackGround(e);
        }
    }

    private setLoadBackGround(e) {
        let colLoad = 11;
        e.cells[colLoad].cellElement?.removeClass("dx-cell-load-80");
        e.cells[colLoad].cellElement?.removeClass("dx-cell-load-50");
        e.cells[colLoad].cellElement?.removeClass("dx-cell-load-0");
        e.cells[colLoad].cellElement?.removeClass("dx-cell-load-negative");
        if (e.cells[colLoad].value >= 80) {
            e.cells[colLoad].cellElement?.addClass("dx-cell-load-80");
        } else if (e.cells[colLoad].value >= 50) {
            e.cells[colLoad].cellElement?.addClass("dx-cell-load-50");
        } else if (e.cells[colLoad].value >= 0) {
            e.cells[colLoad].cellElement?.addClass("dx-cell-load-0");
        } else {
            e.cells[colLoad].cellElement?.addClass("dx-cell-load-negative");
        }
    }

    public getStatusClass(status) : string {
        let returnValue = "";
        switch (status.toUpperCase()) {
            case this.STARUS_ARRIVED:
                returnValue = 'operational-status-arrival';
                break;
            case this.STARUS_CANCELLED:
                returnValue = 'operational-status-cancelled';
                break;
            case this.STARUS_DELAYED:
                returnValue = 'operational-status-delayed';
                break;
            case this.STARUS_DELIVERED:
                returnValue = 'operational-status-delivered';
                break;
            case this.STARUS_DEPARTED:
                returnValue = 'operational-status-departed';
                break;
            default:
                returnValue = 'operational-status-nostatus';
                break;
        }
        return returnValue;
    }

    public doubleClick(item) {
        this.gotoOperatingProductPage(item.data.operatingProductId, item.data.operatingProductSeriesId);
    }


    private gotoOperatingProductPage(operatingProductId: string, operatingProductSeriesId: string) {
        let params = {
            productId: operatingProductId,
            operatingProductSeriesId: operatingProductSeriesId,
            product: true
        }
        this.navigationService.navigate('main/transport/' + Date.now().toString(), "Operating Transport Management", true, params);
    }

    public onCalendarButtonClick() {
        this.onCalendarViewClick.emit();
    }

    public diplayRouteName(): string {
        if (this.fullView) {
            return this.routeName;
        }
        return "";
    }

    public onRowSelected(event) {
        this.selectedItem = event.key;
    }

    public getDetail(operatingProductSeriesId: string) {
        let item = this.dataSourceStorage.find((i) => i.key === operatingProductSeriesId);
        if (!item) {
            this.operatingProductService.getSearchDetail(this.productId, operatingProductSeriesId)
                .subscribe(
                    (responses: OperatingProductSearchDetailViewModel[]) => {
                        return this.createDetailDatatSource(operatingProductSeriesId, responses);
                    }
                )
        }
        else {
            return item.dataSourceInstance;
        }
    }

    private createDetailDatatSource(operatingProductSeriesId: string, responses: OperatingProductSearchDetailViewModel[]){
        let detail = this.mapperService.searchDetailModelToViews(responses);
        let item = {
            key: operatingProductSeriesId,
            dataSourceInstance: new DataSource({
                store: new ArrayStore({
                    data: detail,
                    key: "operatingProductSeriesId"
                }),
                filter: ["operatingProductSeriesId", "=", operatingProductSeriesId]
            })
        };
        this.dataSourceStorage.push(item);
        this.changeDetectionRef.detectChanges();
        return item.dataSourceInstance;
    }

    public getSegmentText(data) {
        var dataArr = data.displayValue.split(':');
        return dataArr[1];
    }
}
import { Injectable } from "@angular/core";
import { InsightPassengerNameModel } from "src/app/core/models/individual-model";
import { NameView } from "./name-view";

@Injectable()
export class NameMapperService {
    public nameViews(models: InsightPassengerNameModel[]): NameView[] {
        if (!models) {
            return null;
        }

        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.nameView(i, model));
        }
        return views;
    }

    private nameView(index: number, model: InsightPassengerNameModel): NameView {
        let view = new NameView();
        view.no = index;
        view.individualNameId = model.individualNameId;
        view.individualId = model.individualId;
        view.languageCode = model.languageCode;
        view.lastName = model.lastName;
        view.firstName = model.firstName;
        view.middleName = model.middleName;
        view.nickName = model.nickName;
        view.primaryFlag = model.primaryFlag;
        view.statusCode = model.statusCode;
        view.commitBy = model.commitBy;
        view.commitDateTime = model.commitDateTime;
        view.lastNameNormal = model.lastNameNormal;
        view.lastNameSound = model.lastNameSound;
        view.firstNameNormal = model.firstNameNormal;
        view.firstNameSound = model.firstNameSound;
        view.middleNameNormal = model.middleNameNormal;
        view.middleNameSound = model.middleNameSound;
        view.individualTitleCode = model.individualTitleCode;
        view.lastNameLegacy = model.lastNameLegacy;
        view.middleNameLegacy = model.middleNameLegacy;
        view.firstNameLegacy = model.firstNameLegacy;
        return view;
    }

    public nameViewToModels(views: NameView[]): InsightPassengerNameModel[] {
        if (!views) {
            return null;
        }

        let models = new Array();
        for (let view of views) {
            models.push(this.nameViewToModel(view));
        }
        return models;
    }

    private nameViewToModel(view: NameView): InsightPassengerNameModel {
        let model: InsightPassengerNameModel = {
            individualNameId: view.individualNameId ?? null,
            individualId: view.individualId ?? null,
            languageCode: view.languageCode ?? "",
            lastName: view.lastName ?? "",
            firstName: view.firstName ?? "",
            middleName: view.middleName ?? "",
            nickName: view.nickName ?? "",
            primaryFlag: view.primaryFlag ?? false,
            statusCode: view.statusCode ?? "",
            lastNameNormal: view.lastNameNormal ?? "",
            lastNameSound: view.lastNameSound ?? "",
            firstNameNormal: view.firstNameNormal ?? "",
            firstNameSound: view.firstNameSound ?? "",
            middleNameNormal: view.middleNameNormal ?? "",
            middleNameSound: view.middleNameSound ?? "",
            individualTitleCode: view.individualTitleCode ?? "",
            lastNameLegacy: view.lastNameLegacy ?? "",
            middleNameLegacy: view.middleNameLegacy ?? "",
            firstNameLegacy: view.firstNameLegacy ?? ""
        }
        
        return model;
    }
}
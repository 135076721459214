import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ActionBarHandlerModel, ActionbarNotifier } from ".";

@Injectable()
export class ActionbarService {
    public notifier: ActionbarNotifier = new ActionbarNotifier();

    public updateState(handler: ActionBarHandlerModel) {
        this.notifier.updateState(handler);
    }

    public getCurrentState(): ActionBarHandlerModel {
        return this.notifier.getCurrentState();
    }

    get action$(): Subject<string> {
        return this.notifier.action$;
    }
}
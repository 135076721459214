import { Injectable } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { Helper } from 'src/app/shared/helper/app.helper';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { PriceRuleTypeReferenceModel } from 'src/app/core/models/pricing-model';
import { OrganisationTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrderTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/order-type-reference.model';
import { OrderStateReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/order-state-reference.model';

@Injectable()
export class AttributeConverter {
    constructor(private helper: Helper){

    }

    public ToProductCategoryReferenceSelect2Data(model: ProductCategoryReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.productCategoryCode;
        option.text = model.productCategoryName;
        return option;
    }

    public ToProductCategoryReferenceSelect2DataList(models: ProductCategoryReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.ToProductCategoryReferenceSelect2Data(model));
        }
        return optionList;
    }

    public toProductGroupReferenceSelect2Data(model: ProductGroupReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.productGroupCode;
        option.text = model.productGroupName;
        return option;
    }

    public toProductGroupReferenceSelect2DataList(models: ProductGroupReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toProductGroupReferenceSelect2Data(model));
        }
        return optionList;
    }

    public toProductTypeRefefrenceSelect2Data(model: ProductTypeGroupModel): Select2Data {
        let option = new Select2Data();
        option.id = model.productTypeCode;
        option.text = model.productTypeName;
        return option;
    }

    public toProductTypeRefefrenceSelect2DataList(models: ProductTypeGroupModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toProductTypeRefefrenceSelect2Data(model));
        }
        return optionList;
    }

    public toPriceRuleTypeReferenceSelect2Data(model: PriceRuleTypeReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.priceRuleTypeCode;
        option.text = model.priceRuleTypeName;
        return option;
    }

    public toPriceRuleTypeReferenceSelect2DataList(models: PriceRuleTypeReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toPriceRuleTypeReferenceSelect2Data(model));
        }
        return optionList;
    }

    public toOrganisationTypeReferenceSelect2Data(model: OrganisationTypeReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.organisationTypeCode;
        option.text = model.organisationTypeName;
        return option;
    }

    public toOrganisationTypeReferenceSelect2DataList(models: OrganisationTypeReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toOrganisationTypeReferenceSelect2Data(model));
        }
        return optionList;
    }

    public toOrderTypeReferenceSelect2Data(model: OrderTypeReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.orderTypeCode;
        option.text = model.orderTypeName;
        return option;
    }

    public toOrderTypeReferenceSelect2DataList(models: OrderTypeReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toOrderTypeReferenceSelect2Data(model));
        }
        return optionList;
    }

    public toOrderStateReferenceSelect2Data(model: OrderStateReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.orderStateCode;
        option.text = model.orderStateName;
        return option;
    }   

    public toOrderStateReferenceSelect2DataList(models: OrderStateReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toOrderStateReferenceSelect2Data(model));
        }
        return optionList;
    }

}
import { Injectable } from '@angular/core';
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { SettingsManagementModel } from 'src/app/core/models/reference-model/reference-general-model/settings-management.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SettingsCategories, SettingsSecurityCodes } from './settings-categories';
import { MODULE_NAME } from '../shared/title-constant';
import { AttributeContextMenu, ExchangeRateContextMenu, GeographyContextMenu, ContextMenu, NewActionId, DocumentDistributionContextMenu, SecurityContextMenu, VehicleContextMenu } from './settings-context-menu';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';

@Injectable()
export class SettingsCategoriesMapperService {

    constructor(private dateConverterService: DateConverterService) { }

    public getCategories(settingsManagementModels: SettingsManagementModel[], securities: SecurityGroupSecurityModel[], logCommitMap: Map<string, LogCommitModel>): OopsCategory[] {
        const categories = []
        for (let category of [...SettingsCategories]) {
            const moduleName = category.name.toLowerCase()
            const settingsInfo = settingsManagementModels?.find(s => s.settingName.toLowerCase() == moduleName)

            if (SettingsSecurityCodes.has(moduleName)) {
                const userSec = securities?.find(s => s.securityCode == SettingsSecurityCodes.get(moduleName))

                if (userSec) {
                    this.mergeCategoryInfo(category, settingsInfo, userSec, moduleName, logCommitMap)
                    categories.push(category)
                }              
            }
            else {
                this.mergeCategoryInfo(category, settingsInfo, null, moduleName, logCommitMap)
                categories.push(category)
            }
        }
        return categories
    }

    private mergeCategoryInfo(category: OopsCategory, settingsInfo: SettingsManagementModel, userSecurity: SecurityGroupSecurityModel, moduleName: string, logCommitMap: Map<string, LogCommitModel>): void {
        const logCommit = logCommitMap.get(moduleName)
        if (logCommit) {
            category.modifiedBy = logCommit?.commitByName;
            category.modifiedDateTime = this.dateConverterService.convertDateTime24(logCommit?.commitDateTime);
        }
        else {
            category.modifiedBy = settingsInfo?.commitByName;
            category.modifiedDateTime = this.dateConverterService.convertDateTime24(settingsInfo?.commitDateTime);
        }
        
        category.security = userSecurity;
        this.setContextMenus(category, moduleName)
    }

    private setContextMenus(category: OopsCategory, moduleName: string) {
        switch (moduleName) {
            case MODULE_NAME.attribute:
                category.contextMenus = AttributeContextMenu
                break
            case MODULE_NAME.document_distribution:
                category.contextMenus = DocumentDistributionContextMenu
                break
            case MODULE_NAME.exchange_rate:
                category.contextMenus = ExchangeRateContextMenu
                break
            case MODULE_NAME.geography:
                category.contextMenus = GeographyContextMenu
                break
            case MODULE_NAME.security:
                category.contextMenus = SecurityContextMenu
                break
            case MODULE_NAME.vehicle:
                category.contextMenus = VehicleContextMenu
                break
            default:
                category.contextMenus = ContextMenu
                break
        }
        this.checkSecurityCode(category);
    }

    private checkSecurityCode(category: OopsCategory) {
        if (category.security != null) {
            if (category.security.newFlag == false) {
                const menu = category.contextMenus.find(item => item.actionId == NewActionId);

                if (menu) {
                    menu.disable = true;
                }
            }
        }
    }

}

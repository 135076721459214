import { AfterViewInit, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-counter-sales-tabs',
    templateUrl: './counter-sales-tabs.component.html',
    providers: [ActionbarService]
})
export class CounterSalesTabsComponent implements AfterViewInit {
    public readonly SPINNER_NAME: string = "cashbook";
    public readonly statusTab = 'StatusTab';

    public loadingNotifier = new LoadingNotifier();
    public cancelButton = new CancelButtonModel();
    public copyButton = new CopyButtonModel();
    public newButton = new NewButtonModel();
    public refreshButton = new RefreshButtonModel();
    public saveButton = new SaveButtonModel();
    public selectedTab: string = 'SEARCH';
    public refreshValue: boolean = false;
    public userSecurity: SecurityGroupSecurityModel;

    private unsubscribe$ = new Subject();

    constructor(private actionbarService: ActionbarService,
        private navigateService: NavigationService) { 
            this.extractNavigationParams();
        }

    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );

    ngAfterViewInit(): void {
        this.createActionBarButton();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.back:
                        this.back();
                        break;
                    case ACTION_STATUS.cancel:
                        if (this.selectedTab != this.statusTab) {
                            this.selectedTab = this.statusTab;
                        }
                    case ACTION_STATUS.refresh:
                        this.onRefresh();
                        break;
                }
            }
        )
    }

    private createActionBarButton() {
        this.newButton.enable(this.userSecurity?.newFlag ?? false);
        this.copyButton.enable(this.userSecurity?.copyFlag ?? false);
        this.saveButton.disable();
        this.cancelButton.disable();
        let actionbarDefaultState = new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        );
        this.actionbarService.updateState(actionbarDefaultState);
    }

    back() {
        this.navigateService.navigate("main/cashbook/home", null);
    }

    onRefresh() {
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.refreshValue = true;
    }

    setRefreshValue(value: boolean) {
        this.refreshValue = value;
        if (value == false) {
            this.loadingNotifier.hide(this.SPINNER_NAME);
            return;
        }
        this.loadingNotifier.show(this.SPINNER_NAME);
    }

    extractNavigationParams() {
        let params = this.navigateService.getParams();
        this.userSecurity = params?.userSecurity;
      }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductSchedulePeriodModel } from "../../models/product-model/product-schedule-period-model";

@Injectable({
    providedIn: 'root'
})
export class ProductSchedulePeriodService extends ApiServiceBase {
    private readonly PRODUCT_PATH = "/product/productscheduleperiod"
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getProductSchedulePeriodAll(): Observable<ProductSchedulePeriodModel[]> {
        return this.httpGet<ProductSchedulePeriodModel[]>(this.PRODUCT_PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
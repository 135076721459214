import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { PricingCategoryModel } from 'src/app/core/models/pricing-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PricingService } from 'src/app/core/services/pricing-services';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';

import { OopsCategory } from '../../../../core/components/categories/oops-category.model';
import { PricingConstant } from '../shared/constants/pricing.constant';
import { cloneDeep } from 'lodash';
@Component({
    selector: "op-pricing-categories",
    templateUrl: "./pricing-categories.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCategoriesComponent implements AfterViewInit, OnDestroy, OnChanges {
    private readonly displayActionId = "DISPLAY";
    private readonly searchActionId = "SEARCH";
    private readonly newActionId = "NEW";
    private readonly priceRuleUrl = "/price-rule/";

    private defaultContextMenu: OopsCategoryContextMenu[] = [
        {
            name: "Search",
            actionId: this.searchActionId,
        },
        {
            name: "New",
            actionId: this.newActionId,
        }
    ];
    public categoriesItems: OopsCategory[];
    public selectedItem: OopsCategory;
    private unsubscribe$ = new Subject();

    @Input() pricingSecurities: SecurityGroupSecurityModel[] = [];
    @Output() categorySelected = new EventEmitter<OopsCategory>();
    @Output() categoryChanged = new EventEmitter<OopsCategory>();

    actionbarDefaultState = new ActionBarHandlerModel(
        new NewButtonModel(),
        new RefreshButtonModel()
    );

    constructor(
        private pricingService: PricingService,
        private changeDetectorRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pricingSecurities'] && this.pricingSecurities) {
            this.getPriceCategories();
        }
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionbarDefaultState);
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((actionId) => {
                switch (actionId) {
                    case ACTION_STATUS.refresh:
                        this.getPriceCategories();
                        break;
                    case ACTION_STATUS.new:
                        this.newItem();
                        break;
                    default:
                        break;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private newItem() {
        if (this.selectedItem?.security?.newFlag == false) {
            return;
        }
        let url = this.selectedItem?.url ?? PricingConstant.rules.url;
        this.goToDetails(url);
    }

    private getPriceCategories() {
        this.loadingSpinnerService.show();
        this.pricingService.getPricingCategories().subscribe(
            (response: PricingCategoryModel[]) => {
                this.categoriesItems = response;
                this.setContextMenus(this.categoriesItems);
                this.categoriesItems = this.categoriesItems.filter(item => item.security);
                this.selectedItem = this.categoriesItems.find(item => item.url == this.priceRuleUrl);
            },
            () => {
                this.loadingSpinnerService.hide();
            },
            () => {
                this.loadingSpinnerService.hide();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    private setContextMenus(categoriesItems: OopsCategory[]) {
        for (let item of categoriesItems) {
            item.contextMenus = this.defaultContextMenu;
            this.setPricingSecurity(item);
        }
    }

    onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case this.displayActionId:
            case this.searchActionId:
                this.categorySelected.emit(this.selectedItem);
                break;
            case this.newActionId:
                this.newItem();
                break;
        }
    }

    private goToDetails(url: string) {
        this.navigationService.navigate(`/main/pricing${url}details`, null, null, {
            previousPage: PricingConstant.categories.id,
            userSecurity: this.selectedItem ? this.selectedItem.security : this.categoriesItems.find(item => item.url == PricingConstant.rules.url)?.security
        });
    }

    onMenuSelected(item: OopsCategory) {
        this.selectedItem = item;
        this.categoryChanged.emit(this.selectedItem);
    }

    private setPricingSecurity(categoryItem: OopsCategory) {
        let categoryCode = categoryItem.name.replace(' ', '').slice(0, -1).toUpperCase();
        let security = this.pricingSecurities?.find(item => item.securityCode == categoryCode);
        categoryItem.security = security;
        categoryItem.contextMenus = this.setContextMenutSecurity(categoryItem.contextMenus, security);
    }

    private setContextMenutSecurity(menu: OopsCategoryContextMenu[], security: SecurityGroupSecurityModel) {
        let newBtnIndex = menu.findIndex(item => item.actionId == this.newActionId);
        let newMenu = cloneDeep(menu);

        newMenu[newBtnIndex].disable = !security?.newFlag;
        return newMenu;
    }
}
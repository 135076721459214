import { Component, AfterViewInit, ViewChild } from '@angular/core'
import { DataTableDirective } from 'angular-datatables';

@Component({
    template: ''
})
export abstract class DatatableComponentBase implements AfterViewInit {

    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    _datatableInitialized: boolean = false;

    ngAfterViewInit(): void {
        this.cleanDatatable();
    }

    public abstract cleanDatatable();

    protected destroyDatatable() {
        if (this._datatableInitialized) {
            if(this.datatableElement && this.datatableElement.dtInstance){
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy();
                });
            }
            
        } else {
            this._datatableInitialized = true;
        }
    }

    protected getDatatableInstance(){
        return this.datatableElement?.dtInstance;
    }

    ngOnDestroy() {
        this.destroyDatatable();
    }
}
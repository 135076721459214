import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";
import { PRODUCT_DASHBOARD_MERCHANDIZE_LASTRECEIVED_DEMO } from "./product-dashboard-merchandize-lastreceived-demo-data";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-merchandize-lastreceived.component.html',
    selector: 'op-product-dashboard-merchandize-lastreceived'
})
export class ProductDashboardMerchandizeLastReceivedComponent implements AfterViewInit {
    public lastReceivedInfo: ProductDashboardLastReceivedModel[];
    
    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    private loadData() {
        setTimeout(() => {
            this.lastReceivedInfo = PRODUCT_DASHBOARD_MERCHANDIZE_LASTRECEIVED_DEMO;
            this.changeDetection.detectChanges();
        }, 500);
    }
}
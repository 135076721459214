import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NationalityView } from "../shared/nationality-view";
import { select2Country } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-nationality-detail',
    templateUrl: './nationality-detail.component.html'
})
export class InsightNationalityDetailComponent {
    readonly ERROR_NATIONALITY_REQUIRED = 'Nationality is required.'

    public countryOption: any;
    public processing: boolean = false;

    @Input() insightNationality = {} as NationalityView;
    @Input() countryReferences: CountryReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();
    
    @ViewChild('nationalityDetailForm') nationalityDetailForm: NgForm;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {     
        this.countryOption = select2Country;
    }

    getInsightNationality() : NationalityView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightNationality;
    }

    public countryCodeChange(value) {
        this.insightNationality.countryCode = value;
        this.insightNationality.countryName = this.countryReferences.filter(it => it.countryCode == value)[0]?.countryName ?? null;
        if (!this.insightNationality.countryOfBirthCode) {
            this.insightNationality.countryOfBirthCode = value;
        }
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.nationalityDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.nationalityDetailForm.valid) {
            return this.ERROR_NATIONALITY_REQUIRED;
        }
    }

    public countryOfBirthCodeChange(countryOfBirthCode: string | string[]) {
        this.insightNationality.countryOfBirthCode = countryOfBirthCode as string;
        if (!this.insightNationality.countryCode) {
            this.countryCodeChange(countryOfBirthCode);
        }
    }

}
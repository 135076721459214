import { Component, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PricingDetailConstant } from '../../shared/pricing-detail.constant';
import { ProductEligibleRestrictedConverter } from '../product-eligible-restricted/shared/product-eligible-restricted.converter';
import { Select2OptionsService } from '../product-eligible-restricted/shared/select2-options.service';
import { ServiceCategoriesAndSalesBucketBase as ServiceCategoriesAndSalesBucketBase } from './shared/service-categories-and-sales-bucket.base';
import { ServiceCategoriesAndSalesBucketConverter } from './shared/service-categories-and-sales-bucket.converter';
import { ConditionReferenceService } from 'src/app/core/services/system-services';
import { PricingService } from 'src/app/core/services/pricing-services';

@Component({
    selector: 'op-service-categories-and-sales-bucket',
    templateUrl: './shared/service-categories-and-sales-bucket.base.html',
    providers: [
        ServiceCategoriesAndSalesBucketConverter,
        ProductEligibleRestrictedConverter
    ]
})

export class ServiceCategoriesAndSalesBucketComponent
    extends ServiceCategoriesAndSalesBucketBase {
    priceRuleAttributeGroupCode = PricingDetailConstant.SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE
    title = 'Service Categories and Sales Bucket'
    constructor(zone: NgZone,
        converter: ServiceCategoriesAndSalesBucketConverter,
        productEligibleConverter: ProductEligibleRestrictedConverter,
        service: PricingService,
        toastrService: ToastrService,
        conditionReferenceService: ConditionReferenceService,
        select2OptionsService: Select2OptionsService) {
        super(zone,
            converter,
            productEligibleConverter,
            service,
            toastrService,
            conditionReferenceService,
            select2OptionsService)
    }
}

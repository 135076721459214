import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { CreditAccountSearchModel } from 'src/app/core/models/credit-account-model/credit-account-search.model';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationCreditAccountService } from 'src/app/core/services/organisation-services/organisation-credit-account.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { CreditAccountOrganisationSearchView } from './credit-account-organisation-search.view';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-credit-account-search-table',
    templateUrl: './credit-account-search-table.component.html',
    styleUrls: ['./credit-account-search-table.component.scss'],
})
export class CreditAccountSearchTableComponent extends DatatableComponentBase implements AfterViewInit {
    public focusing: boolean = false;
    public rows: number;
    public header: string[];
    public exportData: any;

    public organisationData: CreditAccountOrganisationSearchView[];
    public selectedItem: CreditAccountOrganisationSearchView;
    public recentCondition: CreditAccountSearchModel;
    public orgSecurityCode: string;
    public displayPaymentForm: Boolean = false;

    @Input() statusReference: StatusReferenceModel[];
    @Input() userOrganisation: OrganisationModel;
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() onTopUpClick = new EventEmitter<CreditAccountOrganisationSearchView>();

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef,
        private organisationCreditAccountService: OrganisationCreditAccountService,
        private focusingService: FocusingService,
        private loadSpinner: LoadingSpinnerService,
        private stringHelperService: StringHelperService,
        private navigationService: NavigationService) {
        super()
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader()
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.changeDetector.detectChanges();
    }

    public onRowSelected(e) {
        this.selectedItem = e.data;
    }

    public advanceSearchOrganisation(condition: CreditAccountSearchModel) {
        this.loadSpinner.show();
        this.recentCondition = condition;
        this.organisationCreditAccountService.getOrganisationCreditAccount(condition)
        .subscribe(
            (responses: CreditAccountSearchModel[]) => {
                let orgTableViews = new Array<CreditAccountOrganisationSearchView>();
                let index = 1;
                responses.forEach(org => {
                    let view: CreditAccountOrganisationSearchView = {
                        no: index,
                        organisationId: org.organisationId,
                        organisationCode: org.organisationCode,
                        organisationCallName: org.organisationCallName,
                        currency: org.currencyCode,
                        credit: this.stringHelperService.amountString(org.ledgerTotal),
                        debit: this.stringHelperService.amountString(org.salesTotal),
                        balance: this.stringHelperService.amountString(org.creditAccountBalance)
                    }
                    orgTableViews.push(view);
                    index += 1;
                })
                this.organisationData = orgTableViews;
                this.changeDetector.detectChanges();
                this.focusingService.focus(this.focusingDirective);
                this.loadSpinner.hide();
            },
            () => {
                this.loadSpinner.hide();
            }
        );    
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Code: dat.organisationCode,
                Name: dat.organisationCallName,
                Currency: dat.currency,
                Credit: dat.credit,
                Debit: dat.debit,
                Balance: dat.balance
            }
        ));
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public topUpClick(data: CreditAccountOrganisationSearchView) {
        if (this.userSecurity?.newFlag) {
            this.onTopUpClick.emit(data);
        }
    }

    public summaryClick(data: CreditAccountOrganisationSearchView) {
        this.gotoAccountSummary(data.organisationId);
    }

    private gotoAccountSummary(organisationId: string) {
        let param = {
            organisationId: organisationId,
            searchCondition: this.recentCondition,
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate("/main/cashbook/credit-account/summary", null, false, param);
    }
}

import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class ProductTypeValidationService {

    public findDuplicateProductType(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "producttype") {     
                    if (ctl.value.operatorProductType == ctlCheck.value.operatorProductType &&
                        ctl.value.productCat == ctlCheck.value.productCat &&
                        ctl.value.productGroup == ctlCheck.value.productGroup &&
                        ctl.value.productGroupType == ctlCheck.value.productGroupType 
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }
}
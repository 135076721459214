import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductTypeGroupModel } from "../../models/reference-model/reference-product-model";

@Injectable({
    providedIn: 'root'
})
export class ProductTypeGroupService extends ApiServiceBase {
    private readonly REFERENCE_PATH = '/airline/ProductTypeGroups';
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getProductTypeGroupByProductGroupCode(productGroupCode: string): Observable<ProductTypeGroupModel[]> {
        return this.httpGet<ProductTypeGroupModel[]>(this.REFERENCE_PATH + '?productGroupCode=' + productGroupCode, null)
            .pipe(
                map(res => res.body)
            )
    }
}
export const select2CommunicationOtherType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Language = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}
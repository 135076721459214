import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeContentComponent } from './attribute-content.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AttributeTabsComponent } from './attribute-tabs/attribute-tabs.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AttributeSearchComponent } from './attribute-search/attribute-search.component';
import { AttributeSearchConditionComponent } from './attribute-search/attribute-search-condition/attribute-search-condition.component';
import { AttributeSearchTableComponent } from './attribute-search/attribute-search-table/attribute-search-table.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule, DxTreeViewModule } from 'devextreme-angular';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatIconModule } from '@angular/material/icon';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { AttributeDetailFormComponent } from './attribute-detail/attribute-detail-form/attribute-detail-form.component';
import { CounterTextModule } from 'src/app/core/pipes/counter-texts/counter-text.module';
import { AttributeAssignmentComponent } from './attribute-detail/attribute-assignment/attribute-assignment.component';
import { AttributeDetailComponent } from './attribute-detail/attribute-detail.component';
import { AngularResizeEventModule } from 'angular-resize-event-package';
import { AttributeChoiceComponent } from './attribute-detail/attribute-choice/attribute-choice.component';
import { AttributeChoiceDetailComponent } from './attribute-detail/attribute-choice/attribute-choice-detail/attribute-choice-detail.component';
import { AttributeChoiceTableComponent } from './attribute-detail/attribute-choice/attribute-choice-table/attribute-choice-table.component';
import { AttributeChoiceDetailDirective } from './attribute-detail/attribute-choice/attribute-choice-detail/attribute-choice-detail.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';

@NgModule({
    declarations: [
        AttributeAssignmentComponent,
        AttributeChoiceComponent,
        AttributeChoiceDetailComponent,
        AttributeChoiceTableComponent,
        AttributeChoiceDetailDirective,
        AttributeContentComponent,
        AttributeDetailComponent,
        AttributeDetailFormComponent,
        AttributeTabsComponent,
        AttributeSearchComponent,
        AttributeSearchConditionComponent,
        AttributeSearchTableComponent
    ],
    imports: [
        AngularResizeEventModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        CounterTextModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DxTreeViewModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    exports: [
        AttributeContentComponent,
        AttributeSearchComponent
    ]
})
export class AttributeContentModule { }

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { VehicleGroupComponent } from "./vehicle-group/vehicle-group.component";
import { VehicleContentComponent } from "./vehicle-content.component";
import { VehicleSearchComponent } from "./vehicle-search/vehicle-search.component";
import { VehicleTabsComponent } from "./vehicle-tabs/vehicle-tabs.component";
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { UiModule } from "src/app/shared/ui/ui.module";

import { VehicleConfigurationSearchModule } from 'src/app/modules/settings-management/settings-categories-content/vehicle-configuration/vehicle-configuration-search/vehicle-configuration-search.module';
import { VehicleConfigurationDetailModule } from 'src/app/modules/settings-management/settings-categories-content/vehicle-configuration/vehicle-configuration-detail/vehicle-configuration-detail.module';
import { VehicleCompositionSearchModule } from 'src/app/modules/settings-management/settings-categories-content/vehicle-composition/vehicle-composition-search/vehicle-composition-search.module';
import { VehicleCompositionDetailModule } from 'src/app/modules/settings-management/settings-categories-content/vehicle-composition/vehicle-composition-detail/vehicle-composition-detail.module';

@NgModule({
    declarations: [
        VehicleGroupComponent,
        VehicleContentComponent,
        VehicleSearchComponent,
        VehicleTabsComponent
    ], 
    imports: [
        CommonModule,
        DynamicRoutingModule,
        NgbNavModule,
        RouterModule,
        NgbModule,
        FormsModule,
        PanelsModule,
        OopsCategoriesModule,
        DxDataGridModule,
        Select2Module,
        MatIconModule,
        MatMenuModule,
        FocusingModule,
        DxTemplateModule,    
        CollapseModule,
        ReactiveFormsModule,
        ButtonsModule,
        DxPopoverModule,
        UiModule,
        VehicleConfigurationSearchModule,
        VehicleConfigurationDetailModule,
        VehicleCompositionSearchModule,
        VehicleCompositionDetailModule
    ],
    exports: [
        VehicleContentComponent
    ]
})
export class VehicleContentModule { }
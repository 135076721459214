import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { RegionReferenceModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/airline/regionreferences"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private stringHelperService: StringHelperService
  ) {
    super(httpClient, authService);
  }

  public getRegionReference(ids: string[] = []): Observable<RegionReferenceModel[]> {
    const regionCodeParamName = 'regionCode';
    let params = this.stringHelperService.createQueryParamFromArray(regionCodeParamName, ids);
    return this.httpGet<RegionReferenceModel[]>(this.REFERENCE_PATH + params, null)
      .pipe(
        map(res => res.body)
      )
  }

  public getRegionByName(name: string): Observable<RegionReferenceModel[]> {
    return this.httpGet<RegionReferenceModel[]>(this.REFERENCE_PATH + "/name/" + name, null)
      .pipe(
        map(res => res.body)
      )
  }

  public getRegionInOrderProductPoint(): Observable<RegionReferenceModel[]> {
    return this.httpGet<RegionReferenceModel[]>(this.REFERENCE_PATH + "/orderProductPoint", null)
      .pipe(
        map(res => res.body)
      )
  }
}
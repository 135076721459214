import {
    ProductRestrictionLocationCommandModel,
    ProductRestrictionOrganisationCommandModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction'

export class PointOfSalesCommandView {
    public no: number;
    public type: string;
    public productRestrictionLocationCommand: ProductRestrictionLocationCommandModel;
    public productRestrictionOrganisationCommand: ProductRestrictionOrganisationCommandModel;
}
import { Injectable } from '@angular/core';
import { ProductInventoryCommandModel } from 'src/app/core/models/product-model/product-base-model/product-inventory';
import { ProductInventoryServiceCateViewModel } from 'src/app/core/models/product-model/transport-model';
import { ServiceCategoryTableView } from './servicecategory-table.view';
import { CalculateHelperService } from 'src/app/core/utils/calculate-helper.service';

@Injectable()
export class ServiceCategoryMapperService {

    constructor(private calculateHelperService: CalculateHelperService) {
    }

    public serviceCategoryModelToViews(models: ProductInventoryServiceCateViewModel[]): ServiceCategoryTableView[] {
        let views: ServiceCategoryTableView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.serviceCategoryModelToView(i, views, model))
            }
        }
        return views;
    }

    private serviceCategoryModelToView(index: number, views: ServiceCategoryTableView[], model: ProductInventoryServiceCateViewModel): ServiceCategoryTableView {
        let view = {} as ServiceCategoryTableView;
        let segmentExist = this.segmentExist(model.sortSequenceFrom, views);
        if (segmentExist?.length) {
            view.segmentName = segmentExist[0].segmentName;
            view.segmentNameWithNo = segmentExist[0].segmentNameWithNo;
            view.sortSequence = segmentExist[0].sortSequence;
        } else {
            view.segmentName = this.createSegmentName(model.locationNameFrom, model.locationNameTo);
            view.segmentNameWithNo = this.createSegmentNameWithNo(index, model.locationNameFrom, model.locationNameTo);
            view.sortSequence = model.sortSequenceFrom;
        }
        view.no = index;
        view.serviceCategory = model.serviceCategoryName;
        view.productInventoryId = model.productInventoryId;
        view.capacity = model.sellCapacity ? model.sellCapacity : null;
        view.obf = model.overSellFactor ? model.overSellFactor : null;
        view.grpcap = model.groupCapacity ? model.groupCapacity : null;
        view.ocab = this.createOcap(view.capacity, view.grpcap);
        view.au = this.createAu(view.ocab, view.obf);
        view.nav = this.calculateHelperService.createNAV(view.capacity, view.obf);
        view.serviceCategoryColor = model.serviceCategoryColor;
        return view;
    }

    private segmentExist(sortSequence: number, views: ServiceCategoryTableView[]): ServiceCategoryTableView[] {
        return views.filter(x => x.sortSequence == sortSequence);
    }

    private createSegmentName(locationNameFrom: string, locationNameTo: string): string {
        return locationNameFrom + " - " + locationNameTo;
    }

    private createSegmentNameWithNo(no: number, locationNameFrom: string, locationNameTo: string): string {
        return no.toString() + ":" + locationNameFrom + " - " + locationNameTo;
    }

    public createOcap(capacity: number, grpcap: number): number {
        if (capacity == null || grpcap == null) {
            return null;
        }
        return (capacity - grpcap);
    }

    public createAu(ocap: number, obf: number): number {
        if (ocap == null || obf == null) {
            return null;
        }
        return parseFloat(((ocap * obf) / 100).toFixed(2));
    }

    public serviceCategoryViewToModels(views: ServiceCategoryTableView[]): ProductInventoryCommandModel[] {
        let models: ProductInventoryCommandModel[] = new Array();
        if (views) {
            for (let i = 0; i < views.length; i++) {
                models.push(this.serviceCategoryViewToModel(views[i]))
            }
        }
        return models;
    }

    private serviceCategoryViewToModel(view: ServiceCategoryTableView): ProductInventoryCommandModel {
        let model = new ProductInventoryCommandModel();
        model.productInventoryId = view.productInventoryId;
        model.sellCapacity = view.capacity ?? 0;
        model.groupCapacity = view.grpcap ?? 0;
        model.overSellFactor = view.obf ?? 0;
        return model;
    }
}

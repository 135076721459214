import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { LoadingNotifier, LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { SchedulePeriodService } from "src/app/core/services/schedule-services/schedule-period.service";
import { ProductSchedulePeriodCountModel } from "src/app/core/models/schedule-period-model";

@Component({
    selector: 'op-schedule-period-table',
    templateUrl: './schedule-period-table.component.html',
})
export class SchedulePeriodTableComponent extends DatatableComponentBase implements AfterViewInit {
    readonly SPINNER_NAME: string = "productValidationSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;

    @Input() userSecurity: SecurityGroupSecurityModel;
    @ViewChild('dataGridPeriod', { static: false }) dataGrid: DxDataGridComponent;

    public productSchedulePeriods: ProductSchedulePeriodCountModel[];
    
    public header: string[];
    public rows = 0;
    public selectedItem = false;
    public itemSelected: any;
    public exportData: any;

    private unsubscribe$ = new Subject();
    public loadingNotifier = new LoadingNotifier();

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private dateConverterService: DateConverterService,
        private schedulePeriodService: SchedulePeriodService,
        private actionbarService: ActionbarService) {
        super()
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
        this.getProductSchedulePeriodData();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getProductSchedulePeriodData() {
        let dynamicDataArray = new Array();
        this.loadingSpinnerService.show();
        this.schedulePeriodService.getAll()
            .subscribe(
                (responses: ProductSchedulePeriodCountModel[]) => {
                    let no = 1;
                    responses.forEach(item => {
                        let dataTemp = {};
                        dataTemp["no"] = no;
                        dataTemp["productSchedulePeriodId"] = item.productSchedulePeriodId;
                        dataTemp["periodName"] = item.periodName;
                        dataTemp["periodStartDate"] =  this.dateConverterService.toDateFormat(item.periodStartDate);
                        dataTemp["periodEndDate"] =  this.dateConverterService.toDateFormat(item.periodEndDate);
                        dataTemp["seasonName"] = item.seasonName;
                        dataTemp["productScheduleVersionId"] = item.productScheduleVersionId;
                        dataTemp["versionName"] = item.versionName;
                        dataTemp["countProductAll"] = item.countProductAll;
                        dataTemp["countProductDraft"] = item.countProductDraft;
                        dataTemp["countProductNotDraft"] = item.countProductNotDraft;
                        dataTemp["countProductFinal"] = item.countProductFinal;
                        dynamicDataArray.push(dataTemp);
                        no++;
                    });
                    this.loadingSpinnerService.hide();
                    this.productSchedulePeriods = dynamicDataArray;
                    this.rows = this.productSchedulePeriods.length;
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.changeDetectorRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                Period: dat.periodName,
                Start: dat.periodStartDate ?? '',
                End: dat.periodEndDate ?? '',
                SeasonName: dat.seasonName ?? '',
                VersionName: dat.versionName ?? '',
                Schedule: dat.countProductAll ?? '',
                Draft: dat.countProductDraft ?? '',
                None: dat.countProductNotDraft ?? '',
                Final: dat.countProductFinal ?? ''
            }
        ));
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().filter(item => item.caption).map(item => item.caption);
    }

    private actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
        }
    }

    private refresh() {
        this.selectedItem = false;
        this.itemSelected = null;
        this.getProductSchedulePeriodData();
    }
}
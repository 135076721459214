import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CommunicationPhoneTypeReferenceModel, CountryReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommunicationPhoneView } from "../shared/communication-phone-view";
import { select2Language, select2PhonePrefix, select2PhoneType } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-communication-phone-detail',
    templateUrl: './communication-phone-detail.component.html',
})
export class InsightCommunicationPhoneDetailComponent {
    readonly ERROR_REQUIRED = ' is required.'

    public phoneTypeOption: any;
    public phonePrefixOption: any;
    public languageOption: any;
    public processing: boolean = false;

    @Input() insightCommunicationPhone = new CommunicationPhoneView(); 
    @Input() communicationPhoneTypeReferences: CommunicationPhoneTypeReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];   
    @Input() languageReferences: LanguageReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();

    @ViewChild('communicationPhoneDetailForm') communicationPhoneDetailForm: NgForm;

    get isNullOrganisationId() : boolean {
        if (this.insightCommunicationPhone.organisationId) {
            return true;
        }

        return false;
    }

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {     
        this.phoneTypeOption = select2PhoneType;
        this.languageOption = select2Language;
        this.phonePrefixOption = select2PhonePrefix;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    getInsightCommunicationPhone() : CommunicationPhoneView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightCommunicationPhone;
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.communicationPhoneDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false; 
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.communicationPhoneDetailForm.controls['phoneTypeCode'].value) {
            return 'Phone Type' + this.ERROR_REQUIRED;
        } else if (!this.communicationPhoneDetailForm.controls['phonePrefixCode'].value) {
            return 'Prefix' + this.ERROR_REQUIRED;
        } else if (!this.communicationPhoneDetailForm.controls['phoneNumber'].value) {
            return 'Number' + this.ERROR_REQUIRED;
        }
    }

    public phoneTypeChange(value) {
        this.insightCommunicationPhone.communicationPhoneTypeCode = value
        this.insightCommunicationPhone.communicationPhoneTypeName = this.communicationPhoneTypeReferences.filter(it => it.communicationPhoneTypeCode == value)[0]?.communicationPhoneTypeName ?? null;
    }

    public languageCodeChange(value) {
        this.insightCommunicationPhone.languageCode = value
    }

    public phonePrefixChange(value) {
        this.insightCommunicationPhone.countryCode = value
        this.insightCommunicationPhone.communicationPhonePrefix = this.countryReferences.filter(it => it.countryCode == value)[0]?.countryPhonePrefix ?? null;
    }
}
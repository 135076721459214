import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export const AttributeSearchConstant = {
    //Domain Constants
    DOMAIN_PRODUCT_CODE: 'PRODUCT',
    DOMAIN_ORGANISATION_CODE: 'ORGANISATION',
    DOMAIN_PRICE_RULE_CODE: 'PRICERULE',
    DOMAIN_ORDER_CODE: 'ORDER',

    //Order Mapping Constants
    MAPPING_PRODUCT_CODE: 'PRODUCT',
    MAPPING_TYPE_CODE: 'TYPE',
    MAPPING_STATE_CODE: 'STATE',
    ORDER_MAPPING: [
        new Select2Data('TYPE', 'Type'),
        new Select2Data('STATE', 'State'),
        new Select2Data('PRODUCT', 'Product')
    ],

    //Status Constant
    STATUS_ACTIVE_CODE: 'A'
}
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { FocusingService } from './focusing.service';

declare var $: any;

@Directive({
    selector: '[focusing]'
})
export class FocusingDirective implements OnChanges, OnDestroy {
    public readonly id = this.stringHelperService.NewGuid();
    @Input() focusing = false;
    @Input() autoFocus = true;
    @Input() moveTop = true;
    @Output() focusingChange = new EventEmitter();

    readonly fixedTopSize = 160;

    constructor(public el: ElementRef,
        private focusingService: FocusingService,
        private stringHelperService: StringHelperService) {
            focusingService.push(this);
    }

    @HostListener('click', ['$event'])
    onClick() {
        if (!this.autoFocus) {
            return;
        }
        if (!this.focusing) {
            this.blurInactiveDirectives();
            this.focusing = true;
            if (this.moveTop == true) {
                this.scrollToElement();
            }
            this.focusingChange.emit(this.focusing);
        }
    }

    @HostListener('keyup.tab', ['$event'])
    onKeyTab() {
        if (!this.autoFocus) {
            return;
        }
        let focusing = this.focusing;
        this.blurInactiveDirectives();
        this.focusActiveDirectives();
        if (focusing == false && this.moveTop) {
            this.scrollToElement();
        }
    }

    @HostListener('keyup.shift.tab', ['$event'])
    onKeyShiftTab() {
        if (!this.autoFocus) {
            return;
        }
        let focusing = this.focusing;
        this.blurInactiveDirectives();
        this.focusActiveDirectives();
        if (focusing == false && this.moveTop) {
            this.scrollToElement();
        }
    }

    ngOnChanges() {
        if (this.focusing && this.autoFocus && this.moveTop) {
            this.scrollToElement();
        }
    }

    ngOnDestroy(): void {
        this.focusingService.remove(this.id);
    }

    public scrollToElement() {
        let exist = $(document).find(this.el.nativeElement)?.length > 0;
        if (!exist) {
            return;
        }

        let topPos = this.el.nativeElement.getBoundingClientRect().top + window.scrollY;
        let freezingElementHeight = 0;
        $(document).find('.freeze-tabs').each(function() {
            freezingElementHeight += $(this).outerHeight();
        });
        let toScrollTop = topPos - freezingElementHeight - this.fixedTopSize;
        window.scrollTo({ top: toScrollTop, behavior: 'smooth' });
    }

    private blurInactiveDirectives() {
        this.focusingService
            .focusingDirectives
            .filter(dir => dir.focusing == true)
            .forEach(dir => {
                if (dir.el.nativeElement.contains(document.activeElement) == false) {
                    dir.focusing = false;
                    dir.focusingChange.emit(dir.focusing);
                }
            });
    }

    private focusActiveDirectives() {
        this.focusingService.focusingDirectives.forEach(dir => {
            if (dir.el.nativeElement.contains(document.activeElement)) {
                dir.focusing = true;
                dir.focusingChange.emit(dir.focusing);
            }
        })
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganisationComponent } from './organisation.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: OrganisationComponent,
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('./organisation-content/organisation-content.module').then(
                        (m) => m.OrganisationContentModule
                    )
            },
            {
                path: 'details',
                loadChildren: () =>
                    import('./organisation-detail/organisation-detail.module').then(
                        (m) => m.OrganisationDetailModule
                    )
            }
        ]
    },
    
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class OrganisationRoutingModule { }
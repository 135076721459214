import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { VouchersTabTableComponent } from './vouchers-tab-table/vouchers-tab-table.component';
import { VouchersTabSearchComponent } from './vouchers-tab-search/vouchers-tab-search.component';
import { VoucherStatusReferenceService } from 'src/app/core/services/system-services/voucher-status-reference.service';
import { VoucherStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/voucher-status-reference.model';
import { OrganisationAttributeService } from 'src/app/core/services/organisation-services/organistion-attribute.service';
import { AttributeMaintenanceService } from 'src/app/core/services/system-services';
import { OrganisationAttributeModel } from 'src/app/core/models/organisation-model';
import { AttributeChoiceView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/attribute-choice.view';

@Component({
    selector: 'op-vouchers-tab',
    templateUrl: './vouchers-tab.component.html'
})
export class VouchersTabComponent implements OnInit, AfterViewInit {
    private readonly SHORTDATE_FORMAT_ATTRIBUTE = 'SHORTDATE';
    public voucherStatusReferences$ = new BehaviorSubject<VoucherStatusReferenceModel[]>(null);
    public organisationShortDateFormat$ = new BehaviorSubject<string>(null);

    @Input() voucherTypeReferences$: BehaviorSubject<ProductTypeGroupModel[]>;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() productId: string;
    @Input() newVoucher: boolean = false;

    @ViewChild(VouchersTabSearchComponent) conditionComponent: VouchersTabSearchComponent;
    @ViewChild(VouchersTabTableComponent) tableComponent: VouchersTabTableComponent;

    constructor(private voucherStatusReferenceService: VoucherStatusReferenceService,
        private organisationAttributeService: OrganisationAttributeService,
        private attributeMaintenanceService: AttributeMaintenanceService) { }

    ngOnInit(): void {
        this.getOrganisationDatetimeFormat();
        this.getVoucherStatusReference();
    }

    ngAfterViewInit(): void {
        if (!this.newVoucher) {
            this.searchVoucher();
        }
    }

    public getVoucherStatusReference() {
        this.voucherStatusReferenceService.getAll()
            .subscribe(
                (response: VoucherStatusReferenceModel[]) => {
                    this.voucherStatusReferences$.next(response);
                }
            )
    }

    public searchVoucher() {
        this.tableComponent.searchVoucher(this.conditionComponent.organisationId,
            this.conditionComponent.voucherTypeCode as string,
            this.conditionComponent.issueDateFrom,
            this.conditionComponent.issueDateTo);
    }

    public clearSearch() {
        this.tableComponent.voucherSearchResults = null;
    }

    public getOrganisationDatetimeFormat() {
        forkJoin({
            organisationAttributes: this.organisationAttributeService.getOrganisationAttribute(),
            dateTimeFormatReferernce: this.attributeMaintenanceService.getAttributeChoiceByAttributeTypeCode(this.SHORTDATE_FORMAT_ATTRIBUTE)
        })
        .subscribe((responses) => {
            let organisationDateTimeFormat = responses?.organisationAttributes?.find(item => item.organisationAttributeTypeCode == this.SHORTDATE_FORMAT_ATTRIBUTE);
            if (organisationDateTimeFormat && responses?.dateTimeFormatReferernce) {
                this.getOrganisationDatetimeFormatFromAttribute(organisationDateTimeFormat, responses?.dateTimeFormatReferernce);
            }
        });
    }

    private getOrganisationDatetimeFormatFromAttribute(dateTimeAttribute: OrganisationAttributeModel, dateTimeChoices: AttributeChoiceView[]) {
        this.organisationShortDateFormat$.next(dateTimeChoices?.find(item => item.attributeChoiceId == dateTimeAttribute.organisationAttributeChoiceId)?.attributeChoiceName);
    }
}

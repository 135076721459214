import { Component, Input } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-icon',
    templateUrl: './security-icon.component.html'
})
export class SecurityIconComponent {
    @Input() security: SecurityGroupSecurityModel;

    get newFlag() {
        return this.security?.newFlag;
    }

    get editFlag() {
        return this.security?.editFlag;
    }

    get copyFlag() {
        return this.security?.copyFlag;
    }

    get deleteFlag() {
        return this.security?.deleteFlag;
    }

    get printFlag() {
        return this.security?.printFlag;
    }

    get exportFlag() {
        return this.security?.exportFlag;
    }
    
    get copyToClipboardFlag() {
        return this.security?.copyToClipboardFlag;
    }

}

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { ActivityStoreService } from "src/app/core/utils/activity-store.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { Helper } from "src/app/shared/helper/app.helper";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { SearchConverter } from "../price-dimensions/shared";
import { SearchConditionView, SearchResultView } from "../price-dimensions/shared/views";
import { PriceService } from "../prices/shared/price.service";
import { PricingConstant } from "../pricing-content/shared/constants/pricing.constant";
import { DetailsComponent } from "./details/details.component";

@Component({
    selector: 'op-price-conditions',
    templateUrl: './price-conditions.component.html'
})
export class PriceConditionsComponent implements AfterViewInit, OnDestroy {
    private readonly defaultUsageTypeCode = 'PRICECONDTEMPLATE';
    priceId: string;
    copyMode: boolean;
    searchConditionView = new SearchConditionView(this.defaultUsageTypeCode);

    searchResultViews$ = new Observable<SearchResultView[]>(null);

    @Input() executeFlag: boolean = true;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @ViewChild(DetailsComponent) detailsComponent: DetailsComponent;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    defaultActionbarHandler: ActionBarHandlerModel;

    unsubscribe$ = new Subject();

    private pagingDataViews: PagingDataView[] = [];

    constructor(protected helper: Helper,
        private priceService: PriceService,
        private converter: SearchConverter,
        private cdr: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private activityStoreService: ActivityStoreService) {
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.defaultActionbarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.refresh:
                        this.searchPriceConditions(this.searchConditionView);
                        break;
                    case ACTION_STATUS.new:
                        this.newPriceConditions();
                        break;
                    default:
                        break;
                }
            }
        )
        if (this.executeFlag == true) {
            this.searchPriceConditions(this.searchConditionView);
        }
        this.activityStore();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    setupActionbarSecurity() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);

        this.defaultActionbarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            new CancelButtonModel(),
            new RefreshButtonModel()
        )

    }

    searchPriceConditions(view: SearchConditionView) {
        this.searchConditionView = view;
        this.loadingSpinnerService.show();
        this.searchResultViews$ = this.priceService
            .search(this.converter.toModel(view))
            .pipe(map(models => {
                this.loadingSpinnerService.hide();
                this.pagingDataViews = this.converter.toPagingDataViews(models);
                return this.converter.toViews(models) 
            }))
    }

    newPriceConditions() {
        this.priceId = null;
        this.goToDetails(this.priceId);
    }

    clearSearchCondition() {
        this.searchConditionView = new SearchConditionView(this.defaultUsageTypeCode);
        this.searchResultViews$ = of([]);
    }

    switchToSearchMode() {
        this.priceId = null;
        this.copyMode = false;
    }

    editPriceConditions(priceId: string) {
        this.priceId = priceId;
        this.goToDetails(this.priceId);
    }

    private goToDetails(priceId: string) {
        this.navigationService.navigate('/main/pricing/condition/details', null, null, {
            priceId: priceId,
            previousPage: PricingConstant.conditions.id,
            pagingDataViews: priceId ? this.pagingDataViews : [],
            userSecurity: this.userSecurity
        });
    }

    deletePriceConditions(priceId: string) {
        if (priceId) {
            this.loadingSpinnerService.show();
            this.priceService.updateStatus(priceId, StatusConstant.DELETED)
                .subscribe(
                    () => { 
                        this.searchPriceConditions(this.searchConditionView);
                    },
                    () => {
                    },
                    () => {
                        this.loadingSpinnerService.hide();
                        this.cdr.detectChanges();
                    }
                )
        }
    }

    copyPriceConditions(priceId: string) {
        this.copyMode = true;
        this.editPriceConditions(priceId);
    }

    onPriceConditionDetailsLoadCompleted() {
        if (this.copyMode) {
            this.detailsComponent.initializeCopyMode();
        }
    }

    onRefresh() {
        this.searchPriceConditions(this.searchConditionView);
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null,
            "PRICECONDITION");
    }
}
import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Select2DataMapperService } from "src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service";
import { OrganisationModel, OrganisationGroupReferenceModel, OrganisationTypeReferenceModel } from "src/app/core/models/organisation-model";
import { FeeSearchCommandModel, FeeViewModel } from "src/app/core/models/product-model/fee-model";
import { StatusReferenceModel, UsageTypeReferenceModel, HashTagReferenceModel, DomainAttributeModel, ConditionReferenceModel, CalendarValidityReferenceModel, DateTimeDimensionReferenceModel, CountryReferenceModel, LocationGroupModel, RegionReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SeasonReferenceModel } from "src/app/core/models/reference-model/reference-general-model/season-reference.model";
import { ProductGroupReferenceModel, ProductTypeGroupModel, ProductNumberTypeReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductCategoryReferenceModel } from "src/app/core/models/reference-model/reference-product-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { HashTagReferenceService, DomainAttributeService, RegionReferenceService, OrganisationGroupReferenceService } from "src/app/core/services/airline-services";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { FeeService, ProductSearchFilterService } from "src/app/core/services/product-services";
import { LocationGroupService } from "src/app/core/services/reference-service/reference-general-service";
import { ProductTypeGroupService } from "src/app/core/services/reference-service/reference-product-service";
import { ProductGroupReferenceService, StatusReferenceService, UsageTypeReferenceService, ProductNumberTypeReferenceService, ConditionReferenceService, CountryReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, CalendarValidityService, DateTimeDimensionReferenceService, ProductCategoryReferenceService, OrganisationTypeReferenceService } from "src/app/core/services/system-services";
import { SeasonReferenceService } from "src/app/core/services/system-services/season-reference.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { AttributeSearchModel } from "src/app/core/models/attribute-type-reference-model";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { CopyButtonModel, SaveButtonModel, SaveAsButtonModel, CancelButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { ProductCategory } from "../../../product-management-content/product-categories/product-category";
import { HashTagMapperService } from "../../shared/mapper/hashtag-mapper.service";
import { ProductHashTagView } from "../../shared/views/product-hashtag.view";
import { FeeSearchConditionComponent } from "./fee-search-condition/fee-search-condition.component";
import { FeeSearchTableComponent } from "./fee-search-table/fee-search-table.component";
import { MapperService } from "./shared/mapper.service";

@Component({
    selector: 'op-fee-search',
    templateUrl: './fee-search.component.html',
    providers: [
        ProductTypeGroupService,
        MapperService,
        Select2DataMapperService,
        HashTagMapperService
    ]
})
export class FeeSearchComponent {
    readonly SERVICE_PRODUCT_CATE_CODE = 'FEE';
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";

    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public productSearchFilters$ = new BehaviorSubject<Select2Data[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSearchResults$ = new BehaviorSubject<any[]>(null);
    public paggingView: PagingDataView[];
    public selectedItem: any;
    private unsubscribe$ = new Subject();
    public advanceSearchModeEnabled = false;
    public feeView$ = new BehaviorSubject<FeeViewModel>(null);
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public feeFilterModel: FeeViewModel;
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    private actionBarHandler: ActionBarHandlerModel;
    public userSecurity: SecurityGroupSecurityModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    @Input() productGroup: string;
    @Input() selectedProductCategory: ProductCategory;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @ViewChild(FeeSearchConditionComponent) searchConditionComponent: FeeSearchConditionComponent;
    @ViewChild(FeeSearchTableComponent) searchTableComponent: FeeSearchTableComponent;

    constructor(
        private productGroupReferenceService: ProductGroupReferenceService,
        private productSearchFilterService: ProductSearchFilterService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private feeService: FeeService,
        private mapperService: MapperService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private productCategoryReferenceService: ProductCategoryReferenceService) { }

    ngOnInit(): void {
        this.getProductSearchFilter();
        this.getProductGroupReference();
        this.getProductTypeGroup();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getHashTagReference();
        this.getSupplier();
        this.getProvider();
        this.getProductNumberTypeReference();
        this.getDomainAttributeTypeService();
        this.getDomainAttributeTypeInventory();
        this.getSeasonReference();
        this.getConditionReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getProductLocationPointReference();
        this.getProductLocationTypeReference();
        this.getRegionReference();
        this.getCalendarValidityReference();
        this.getDateTimeDimensionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getProductCategoryReference();
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.getSelectedServiceGroup();
        if (this.executeFlag == true) {
            this.searchConditionComponent.doSearch();
        }
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private getSelectedServiceGroup() {
        if (this.productGroup != null) {
            this.searchConditionComponent.productGroupCode = this.productGroup
        }
    }

    private getProductSearchFilter() {
        this.productSearchFilterService.getByProductCategory(this.SERVICE_PRODUCT_CATE_CODE)
            .subscribe(
                (responses) => {
                    let searchfilters = responses.map(r => new Select2Data(r.productId, r.productName));
                    this.productSearchFilters$.next(searchfilters);
                }
            )
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReference$.next(responses);
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    let serviceProductGroup = responses.filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE);
                    this.serviceProductGroupReference$.next(serviceProductGroup);
                }
            )
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.getUsageTypeReferenceWithFilter(responses);
                    }
                }
            )
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferenceFilter$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    public onSearch(condition: FeeViewModel) {
        if (!condition) {
            return;
        }
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
        this.focusOnSearchResult();
    }

    private search(searchCommand: FeeSearchCommandModel) {
        this.feeService.search(searchCommand)
            .subscribe(
                (responses: FeeViewModel[]) => {
                    this.paggingView = new Array<PagingDataView>();
                    let searchResults = this.mapperService.feeSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                }
            );
    }

    private focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    public onClearCondition(condition: FeeViewModel) {
        this.advanceSearchModeEnabled = false;
        if (!condition) {
            condition = {} as FeeViewModel;
            condition.statusCode = this.ACTIVE_STATUS;
        }
        this.feeView$.next(condition);
        this.searchTableComponent.dataGrid.instance.clearFilter();
        let searchCommand = this.mapperService.convertToSearchCommand(condition);
        this.search(searchCommand);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.searchTableComponent.onNew();
                break;
            case ACTION_STATUS.copy:
                this.onCopyClick();
                break;
        }
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReference$.next(responses);
                }
            )
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses);
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeService() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    this.domainAttributeService$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeInventory() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    if (responses) {
                        this.domainAttributeINV$.next(
                            responses.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
                    }
                }
            )
    }

    private getSeasonReference() {
        this.seasonReferenceService.getAll()
            .subscribe(
                (responses: SeasonReferenceModel[]) => {
                    this.seasonReference$.next(responses);
                }
            )
    }

    private getConditionReference() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getProductLocationPointReference() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReference$.next(responses);
                }
            )
    }

    private getProductLocationTypeReference() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
                        productLocationTypeReference.push(response);
                        this.productLocationTypeReference$.next(productLocationTypeReference);
                    }
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getCalendarValidityReference() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReference$.next(responses);
                }
            )
    }

    private getDateTimeDimensionReference() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReference$.next(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    public onCopyClick() {
        if (this.feeFilterModel) {
            this.copyFilter();
        } else {
            this.searchTableComponent.onCopy();
        }
    }

    onSelectedChange(item: FeeViewModel) {
        this.selectedItem = item;
    }

    async copyFilter() {
        if (!this.feeFilterModel) {
            return;
        }
        this.advanceSearchModeEnabled = true;
        this.feeFilterModel.productName += ' - copy';
        this.feeFilterModel.productId = null;

        this.feeView$.next(this.feeFilterModel);

        this.feeFilterModel = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).enable(!!(this.feeFilterModel))
            this.actionbarService.updateState(handler);
        }, 0);
    }

    getIsOwnerFilter(response: FeeViewModel): FeeViewModel {
        response.isOwnerFilter = response.filterUserAccountId ? true : false;
        return response
    }

    checkSpecialServiceAttribute(searchAttributeCode: string, serviceAttributes: AttributeSearchModel[]) {
        return serviceAttributes.findIndex(item => item.attributeCode == searchAttributeCode) !== -1;
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchConditionComponent.doSearch();
                break;
            default:
                break;
        }
    }
}
export class PriceRuleAttributeModel {
    public priceRuleAttributeId: string;
    public priceRuleId: string;
    public attributeTypeCode: string;
    public attributeChoiceId: string;
    public attributeText: string;
    public attributeDateTime: string;
    public attributeValue: number;
    public conditionCode: string;
    public displaySequence: number;
}
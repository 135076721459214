import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { PaymentTypeItem } from 'src/app/core/models/cashbook-model';
import { FormOfPaymentAndPaymentCurrencyItemModel } from 'src/app/core/models/cashbook-model/cashbook-document-fopapc-item.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-cashbook-detail-fopapc',
    templateUrl: './cashbook-detail-fopapc.component.html',
})
export class CashbookDetailFopapcComponent {
    public collapsed: boolean = false;
    public organisations: any;
    public focusing: boolean = false;

    constructor(private changeDetection: ChangeDetectorRef) { }

    @Input() dataDetail: FormOfPaymentAndPaymentCurrencyItemModel[];

    @ViewChild("panel") panel: any;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public getPaymentTypeItem(item): PaymentTypeItem[] {
        let paymentItem = [];
        item.forEach((element, i) => {
            element['no'] = i;
            paymentItem.push(element);
        });
        return item
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    getShowPanel(dataItem) {
        if (dataItem.showPanel) {
            dataItem.showPanel
        }
        return true;
    }

    public togglePanelHeader($event: MouseEvent) {
        this.panel.toggleCollapse($event);
        this.collapsed = !this.collapsed;
    }

    public togglePanel($event: MouseEvent, dataItem) {
        $event.preventDefault();
        dataItem.showPanel = !dataItem.showPanel;
        this.changeDetection.detectChanges();
    }

    public togglePanelTop($event: MouseEvent, index, data) {
        $event.preventDefault();
        this.dataDetail[index]['showPanel'] = !((data.showPanel) ? data.showPanel : false);
        this.changeDetection.detectChanges();
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public getShowDivSection(rowIndex: number): boolean {
        if (rowIndex === 0) {
            return false;
        }
        return true;
    }

}

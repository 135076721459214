import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailsComponent as PriceConditionDetailsComponent } from './price-conditions/details/details.component';
import { DetailsComponent as PriceDimensionDetailsComponent } from './price-dimensions/details/details.component';
import { PriceDetailComponent } from './prices/price-detail/price-detail.component';
import { PricingContentComponent } from './pricing-content/pricing-content.component';
import { PricingComponent } from './pricing.component';
import { PriceRuleDetailComponent } from './rules/price-rule-detail/price-rule-detail.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: PricingComponent,
        children: [
            {
                path: 'home',
                component: PricingContentComponent
            },
            {
                path: 'price-rule/details',
                component: PriceRuleDetailComponent
            },
            {
                path: 'price/details',
                component: PriceDetailComponent
            },
            {
                path: 'dimension/details',
                component: PriceDimensionDetailsComponent
            },
            {
                path: 'condition/details',
                component: PriceConditionDetailsComponent
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class PricingRoutingModule { }
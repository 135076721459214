import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { InvoiceOrganisationSearchModel } from 'src/app/core/models/organisation-model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { TranslationService } from 'src/app/core/services/translation-services';
import { InvoiceOrganisationDetailComponent } from './invoice-organisation-detail/invoice-organisation-detail.component';
import { InvoiceOrganisationSearchComponent } from './invoice-organisation-search/invoice-organisation-search.component';

@Component({
    selector: 'op-organisation-document-invoice',
    templateUrl: './organisation-document-invoice.component.html',
})
export class OrganisationDocumentInvoiceComponent implements OnInit {
    public languageTransaltionSets$ = new BehaviorSubject<LanguageTranslationSetViewModel[]>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public selectedOrganisation: InvoiceOrganisationSearchModel;

    @Output() onSelecOrganisation = new EventEmitter<InvoiceOrganisationSearchModel>();
    @ViewChild(InvoiceOrganisationDetailComponent) detailComponent: InvoiceOrganisationDetailComponent;
    @ViewChild(InvoiceOrganisationSearchComponent) searchComponent: InvoiceOrganisationSearchComponent;

    constructor(private translationService: TranslationService,
        private statusReferenceService: StatusReferenceService) { }

    ngOnInit(): void {
        this.getTransaltionSets();
        this.getStatusReferences();
    }

    private getTransaltionSets() {
        this.translationService.searchLangugeTranslationSet()
            .subscribe(
                (responses: LanguageTranslationSetViewModel[]) => {
                    this.languageTransaltionSets$.next(responses);
                }
            )
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReferences$.next(responses);
                }
            )
    }

    public selectOrganisation(organisation: InvoiceOrganisationSearchModel) {
        this.selectedOrganisation = organisation;
        this.detailComponent.getOrganisationDetail(organisation);
        this.onSelecOrganisation.emit(organisation);
    }

    public onClearOrganisation() {
        this.selectedOrganisation = null;
        this.onSelecOrganisation.emit(null);
    }

    get hideSearch(): boolean {
        return !!this.selectedOrganisation
    }

    public focusSearchCondition() {
        this.searchComponent.focusConditionPanel();
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxDataGridModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorModule } from "src/app/shared/ui/forms/inputs/status-color/status-color.module";
import { VehicleCompositionSearchComponent } from "./vehicle-composition-search.component";
import { SearchConditionComponent } from "./search-condition/search-condition.component";
import { SearchTableComponent } from "./search-table/search-table.component";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { UiModule } from "src/app/shared/ui/ui.module";

@NgModule({
    declarations: [
        VehicleCompositionSearchComponent,
        SearchConditionComponent,
        SearchTableComponent
    ],
    
    exports: [
        VehicleCompositionSearchComponent
    ],
    imports: [
        BsDropdownModule,
        CommonModule,
        DaterangepickerModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorModule,
        TooltipModule,
        StatusColorDropdownModule,
        UiModule
    ]
})
export class VehicleCompositionSearchModule { }
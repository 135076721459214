import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormOfPaymentSelectionComponent } from './form-of-payment-selection.component';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';

@NgModule({
    declarations: [
        FormOfPaymentSelectionComponent
    ],
    imports: [
        CommonModule,
        Select2Module
    ],
    exports: [
        FormOfPaymentSelectionComponent
    ]
})
export class FormOfPaymentSelectionModule { }

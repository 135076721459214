import { Component, Input } from '@angular/core';

@Component({
  selector: 'priority-color',
  templateUrl: 'priority-color.component.html',
  styleUrls: ['priority-color.component.scss']
})
export class PriorityColorComponent {
    readonly PRIORTY_HIGH = 'HIGH';
    readonly PRIORTY_IMPORTANT = 'IMPORTANT';
    readonly PRIORTY_LOW = 'LOW';
    readonly PRIORTY_MEDIUM = 'MEDIUM';
    @Input() value: string;
}

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { DocumentView } from "../shared/document-view";

@Component({
    selector: 'op-insight-document-table',
    templateUrl: './document-table.component.html'
})
export class InsightDocumentTableComponent implements AfterViewInit {
    public selectedItem: DocumentView;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightDocuments: DocumentView[] = new Array(); 
    @Input() countryReferences: CountryReferenceModel[];
    @Output() onEdit = new EventEmitter();
    
    @ViewChild('dataGridDocument', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }
    
    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
        this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                DocumentType: dat.identityDocumentName,
                Primary: dat.primaryFlag,
                DocumentNumber: dat.identityDocumentNumber,
                Country: this.countryReferences?.find(item => item.countryCode == dat.countryCode)?.countryName,
                PlaceOfIssue: dat.placeOfIssue,
            }
        ));
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    getCountryName = (row: DocumentView) => {
        return this.countryReferences?.filter(item => item.countryCode == row.countryCode)[0]?.countryName;
    }

    editInsightDocumentDetail() {
        this.onEdit.emit();
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }
}
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { DocumentDistributionSearchModel } from '../../models/document-distribution-model/document-distribution-search.model';
import { DocumentDistributionCommandModel, DocumentDistributionModel } from '../../models/document-distribution-model';

@Injectable({
    providedIn: 'root'
})
export class DocumentDistributionService extends ApiServiceBase {

  private readonly PATH = "/system/DocumentDistribution";

  constructor(httpClient: HttpClient,
      authService: AuthService) {
        super(httpClient, authService);
      }

  public search(filter: DocumentDistributionSearchModel): Observable<DocumentDistributionSearchModel[]> {
      return this.httpPost<DocumentDistributionSearchModel[]>(this.PATH + "/search/", filter)
          .pipe(
              map(response => {
              return response.body;
              })
          );
  }

  public updateStatus(documentDistributionId: string, statusCode: string) {
    return this.httpPut(this.PATH + "/status/" + documentDistributionId + '?statusCode=' + statusCode, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public addDocumentDistribution(command: DocumentDistributionCommandModel): Observable<DocumentDistributionModel> {
    return this.httpPost<DocumentDistributionModel>(this.PATH + "/", command)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public editDocumentDistribution(command: DocumentDistributionCommandModel, documentDistributionId: string): Observable<DocumentDistributionModel> {
    return this.httpPut<DocumentDistributionModel>(this.PATH + "/" + documentDistributionId, command)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
  
  public getDocumentDistribution(documentDistributionId: string): Observable<DocumentDistributionModel> {
    return this.httpGet<DocumentDistributionModel>(this.PATH + "/" + documentDistributionId, null)
      .pipe(
        map(response => response.body)
      )
  }

  public getPrimaryDocumentDistribution(documentDistributionId: string): Observable<DocumentDistributionModel[]> {
    return this.httpGet<DocumentDistributionModel[]>(this.PATH + "/primarydocumentdistribution/" + documentDistributionId, null)
      .pipe(
        map(response => response.body)
      )
  }
}
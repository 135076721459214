import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductGroupView } from 'src/app/modules/product-management/product-categories-content/shared/views/product-group.view';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService extends ApiServiceBase {
    private readonly PATH = "/product/ProductGroup";

    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient, authService);
    }

    public getProductGroupByProductCategoryCode(productCategoryCode: string): Observable<ProductGroupView[]> {
        let params = new HttpParams()
            .set('productCategoryCode', productCategoryCode);
        return this.httpGet<ProductGroupView[]>(this.PATH + '/ProductCategoryCode', params)
            .pipe(
                map((response) => response.body)
            )
    }
}

import { OperatorModel } from './operator.model';
import { TimeZoneModel } from './timezone.model';

export class CalendarTypeModel {
    public calendarTypeCode:string;
    public calendarTypeName:string;
    public timeZone :TimeZoneModel[];
    public operators: OperatorModel[];
    public operatorDefault:string;
    public min:string;
    public max:string;
}
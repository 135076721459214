import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { ScheduleQueueModule } from "./schedule-categories-content/schedule-queue/schedule-queue.module";
import { StandardScheduleInformationModule } from "./schedule-categories-content/standard-schedule-information/standard-schedule-information.module";
import { ScheduleContentModule } from "./schedule-content/schedule-content.module";
import { ScheduleRoutingModule } from "./schedule-routing.module";
import { ScheduleComponent } from "./schedule.component";
import { ScheduleDistributionModule } from "./schedule-categories-content/schedule-distribution/schedule-distribution.module";
import { SchedulePeriodModule } from "./schedule-categories-content/schedule-period/schedule-period.module";

@NgModule({
    declarations: [
        ScheduleComponent,
    ],
    imports: [
        CommonModule,
        DynamicRoutingModule,
        ScheduleContentModule,    
        ScheduleQueueModule,
        ScheduleDistributionModule,
        SchedulePeriodModule,
        ScheduleRoutingModule,
        StandardScheduleInformationModule,
        UiModule
    ],
    providers: [

    ]
})
export class ScheduleModule { }
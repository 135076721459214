import { createAction } from '@ngrx/store';
import { NavigationActionModel } from 'src/app/core/models/navigation-action/navigation-action.model';

export const addNavigationAction = createAction(
    '[NavigationAction] Add Navigation Action',
    (navigationAction: NavigationActionModel) => (navigationAction)
);

export const NavigationActionActionType: string[] = [
    addNavigationAction.type
];
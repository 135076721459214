export const field = {
    ATTR_ID: "productAttributeId",
    CHOICE_ID: "productAttributeChoiceId",
    ATTRTYPE_CODE: "productAttributeTypeCode",
    ATTRTYPE_DATA: "productAttributeTypeData",
    DIMENSIONUNIT_CODE: "dimensionUnitCode",
    MULCHOICE_FLAG: "multipleChoiceFlag",
    CHOICE_DATA: "choiceData",
    CHOICE_VALUE: "choiceValue",
    CHOICE_OPTOIN: "choiceOption",
    DUPLICATE: "productAttributeDuplicate",
    SUBMITTED: "submitted",
    REQUIRED: "required",
    ATTR_DATA: "attributeData",
    MIN: "min",
    MAX: "max"
}
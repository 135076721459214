import { Injectable } from "@angular/core";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable()
export class ScheduleQueueMapperService {

    constructor(private dateConverterService: DateConverterService) { }

    public bindData(tasks: ScheduleQueueSearchModel[]) : any[]  {
        let dynamicDataArray = new Array();
        tasks.forEach(item => {
            let scheduleTemps = {};
            scheduleTemps["productNumber"] = item.providerIataCode + ' ' + item.productNumber;
            scheduleTemps["id"] = item.scheduleQueueId;
            scheduleTemps["originLocationCode"] = item.originLocationCode;
            scheduleTemps["destinationLocationCode"] = item.destinationLocationCode;
            scheduleTemps["schedulePeriodFrom"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.startDateTime)?.toUpperCase();
            scheduleTemps["schedulePeriodTo"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.endDateTime)?.toUpperCase();
            scheduleTemps["weekdays"] = item.weekdays;
            scheduleTemps["departureTime"] = this.dateConverterService.convertTime24(item.productScheduledDepartureTime);
            scheduleTemps["arrivalTime"] = this.dateConverterService.convertTime24(item.productScheduledArrivalTime);
            scheduleTemps["vehicleType"] = item.vehicleTypeCode;
            scheduleTemps["vehicleComp"] = item.vehicleCompositionCode;
            scheduleTemps["action"] = item.scheduleQueueActionCode;
            scheduleTemps["process"] = this.setProcessData(item);
            scheduleTemps["progress"] = item.processStatusCode;
            scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
            scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
            scheduleTemps["scheduleQueueTasks"] = item.scheduleQueueTasks;
            dynamicDataArray.push(scheduleTemps);
        });
        return dynamicDataArray;
    }

    public bindTaskData(tasks: ScheduleQueueSearchModel[]): any[] {
        let dynamicDataArray = new Array();
        let no = 1;
        tasks.forEach(item => {
            let scheduleTemps = {};
            scheduleTemps["no"] = no;
            scheduleTemps["id"] = item.scheduleQueueId;
            scheduleTemps["taskCode"] = item.scheduleQueueTaskCode;
            scheduleTemps["taskName"] = item.scheduleQueueTaskName;
            scheduleTemps["numberOfOrders"] = item.numberOfOrders;
            scheduleTemps["numberOfProducts"] = item.numberOfProducts;
            scheduleTemps["numberOfIndividuals"] = item.numberOfIndividuals;
            scheduleTemps["startDateTime"] = this.dateConverterService.formatDateYearToSecond(item.processStartDateTime);
            scheduleTemps["endDateTime"] = this.dateConverterService.formatDateYearToSecond(item.processEndDateTime);
            scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
            scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
            scheduleTemps["orderNumber"] = item.orderNumber;
            scheduleTemps["serviceDate"] = this.dateConverterService.toDateFormat(item.serviceDate);
            dynamicDataArray.push(scheduleTemps);
            no++;
        });
        return dynamicDataArray;
    }

    private setProcessData(item: ScheduleQueueSearchModel): string {
        if (item.scheduledStartDateTime && !item.autoProcessFlag) {
            return this.dateConverterService.formatDateYearToSecond(item.scheduledStartDateTime);
        }
        if (item.autoProcessFlag) {
            return "Auto";
        }
        return "";
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSearchConditionView } from '../shared/security-group-search-condition.view';
import { SecurityGroupSearchTableView } from '../shared/security-group-search-table.view';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityGroupTreeModel } from 'src/app/core/models/security-model/security-group-tree.model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { StatusCode } from 'src/app/core/constants/status-constants';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-group-search-table',
    templateUrl: './security-group-search-table.component.html'
})
export class SecurityGroupSearchTableComponent extends DatatableComponentBase implements AfterViewInit {
    private readonly SEARCH_TABID = 'SEARCH';
    @Input() statusReferences: StatusReferenceModel[];
    @Input() executeFlag = true;
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Input() securities: SecurityGroupSecurityModel[];

    @Output() onSearchRefresh = new EventEmitter(); 

    public collapsed: boolean;
    public focused: boolean;
    public securityGroupSearchData: SecurityGroupSearchTableView[];
    public rows = 0;
    public selectedItem = false;
    public itemSelected: SecurityGroupSearchTableView;
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    public exportData: any;
    public header: string[];
    public paggingView: PagingDataView[] = [];

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    constructor(private ref: ChangeDetectorRef,
        private securityGroupService: SecurityGroupService,
        private spinner: LoadingSpinnerService,
        private navigateService: NavigationService) {
            super();
        }
    
    get newButtonDisable(): boolean {
        return this.moduleSecurity?.newFlag;
    }

    get copyButtonDisable(): boolean {
        return this.moduleSecurity?.copyFlag && this.selectedItem;
    }

    get copyToClipboardFlag(): boolean {
        return this.moduleSecurity?.copyToClipboardFlag;
    }

    get exportFlag(): boolean {
        return this.moduleSecurity?.exportFlag;
    }

    get printFlag(): boolean {
        return this.moduleSecurity?.printFlag;
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    searchSecurityGroup(condition: SecurityGroupSearchConditionView) {
        this.spinner.show();
        var securityGroupName = condition?.securityGroupName ?? '';
        var securityCode = condition?.securityCode ?? '';
        var securityName = condition?.securityName ?? '';
        var userLogon = condition?.userLogon ?? '';
        var action = condition?.action ?? '';
        var statusCode = condition?.statusCode ?? '';
        var dynamicDataArray = new Array();

        this.securityGroupService.searchSecurityGroup(securityGroupName, securityCode, securityName, userLogon, action, statusCode)
            .subscribe(
                (response: SecurityGroupSearchTableView[]) => {
                    this.cleanDatatable();
                    var index = 1;
                    response.forEach(item => {
                        var securityGroupTemp = {};
                        
                        securityGroupTemp['no'] = index;
                        securityGroupTemp['securityGroupId'] = item.securityGroupId;
                        securityGroupTemp['securityGroupName'] = item.securityGroupName;
                        securityGroupTemp['count'] = item.count;
                        securityGroupTemp['commitBy'] = item.commitByName;
                        securityGroupTemp['commitDateTime'] = item.commitDateTime;
                        securityGroupTemp['statusCode'] = item.statusCode;

                        dynamicDataArray.push(securityGroupTemp);
                        this.addPaggingView(index, item.securityGroupId);
                        index += 1;
                    });
                    this.securityGroupSearchData = dynamicDataArray;
                    this.rows = this.securityGroupSearchData.length;
                    this.ref.detectChanges();
                }, 
                () => {
                    this.spinner.hide();
                },
                () => {
                    this.spinner.hide();
                }
            )
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                SecurityGroupName: dat.securityGroupName,
                Count: dat.count,
                CommitBy: dat.commitBy ?? null,
                CommitDateTime: dat.commitDateTime ?? null,
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    private getStatusName(statusCode: string): string {
        return this.statusReferences.filter(dat => dat.statusCode == statusCode)[0]?.displayName;
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        setTimeout(() => {
            this.getExportData()?.done((filteredData) => {
                this.exportData = this.mapExportData(filteredData);
            });
        }, 200);
        
    }

    public refreshTable() {
        this.searchSecurityGroup(null);
    }

    public new() {
        if (this.itemSelected) {
            this.checkAddChildNode(this.itemSelected.securityGroupId);
        } else {
            this.newGroup();
        }
    }

    public editSecurityGroupDetail() {
        let param = {
            id: this.itemSelected?.securityGroupId,
            newFlag: false,
            selectedTab: this.SEARCH_TABID,
            currentIndex: this.itemSelected?.no,
            paggingView: this.paggingView,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigateService.navigate('/main/settings/security-group/details', null, false, param);
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public copySecuityGroup() {
        if (this.itemSelected) {
            let param = {
                id: this.itemSelected.securityGroupId,
                newFlag: true,
                copy: true,
                selectedTab: this.SEARCH_TABID,
                currentIndex: this.itemSelected?.no,
                paggingView: this.paggingView,
                securities: this.securities,
                moduleSecurity: this.moduleSecurity
            }
            this.navigateService.navigate('/main/settings/security-group/details', null, false, param);
        }
    }

    public deleteSecurityGroup() {
        if (this.itemSelected) {
            this.securityGroupService.changeSecurityGroupStatus(this.itemSelected.securityGroupId, StatusCode.Delete)
                .subscribe(
                    () => {
                        this.onSearchRefresh.emit();
                    }
                );
        }
    }

    newGroup() {
        let param = {
            newFlag: true,
            selectedTab: this.SEARCH_TABID,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigateService.navigate('/main/settings/security-group/details', null, false, param);
    }

    newChildNode() {
        let param = {
            newFlag: true,
            id: this.itemSelected?.securityGroupId,
            selectedTab: this.SEARCH_TABID,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigateService.navigate('/main/settings/security-group/details', null, false, param);
    }

    private checkAddChildNode(id: string) {
        this.securityGroupService.getSecurityGroupTree(id)
            .subscribe(
                (response: SecurityGroupTreeModel) => {
                    if (response.id == id) {
                        this.newGroup();
                    } else {
                        this.newChildNode();
                    }
                },
                () => {
                    this.newGroup();
                }
            )
    }
}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityCodeService } from 'src/app/core/services/system-services/security-code.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { select2Status } from '../../security-code-search/shared/select2-configuration';
import { SecurityCodeDetailFormOption } from '../shared/form-config';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-code-detail-form',
    templateUrl: './security-code-detail-form.component.html'
})
export class SecurityCodeDetailFormComponent extends OopsComponentFormBase implements OnChanges {
    private readonly DELETE_STATUS_CODE = 'D';

    @Input() securityCodeDetail: SecurityCodeReferenceModel;
    @Input() newSecurityCode = false;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() fromEdit: boolean = false;
    @Input() moduleSecurity: SecurityGroupSecurityModel; 
    @Output() onSave = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    public statusOption: any;
    public focused = true;
    public collapsed: boolean;
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild('securityCodeDetailFormPanel') securityCodeDetailFormPanel: ElementRef;
    @ViewChild('securityCode') securityCodeBox: ElementRef;

    constructor(formBuilder: UntypedFormBuilder,
        private securityCodeService: SecurityCodeService,
        private changeDetectionRef: ChangeDetectorRef) {
        super(formBuilder);
        this.statusOption = select2Status;
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['securityCodeDetail']) {
            this.formSetup();
            this.checkDisable();
        }
    }

    public initForm() {
        this.formSetup();
        this.checkDisable();
    }

    checkDisable() {
        this.checkDisableSecurityCode();
        this.checkDisableLimitationName();
    }

    checkDisableSecurityCode() {
        if (this.fromEdit == false && this.newSecurityCode == false) {
            this.formGroup.controls['securityCode'].disable();
        } else {
            this.formGroup.controls['securityCode'].enable();
        }
    }

    checkDisableLimitationName() {
        if (this.formGroup.controls['hasLimitationFlag'].value == true) {
            this.formGroup.controls['limitationName'].enable();
        } else {
            this.formGroup.controls['limitationName'].setValue(null);
            this.formGroup.controls['limitationName'].disable();

        }
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(SecurityCodeDetailFormOption);
        if (!this.securityCodeDetail) {
            this.initNewForm();
        } else {
            this.fillModelToForm(this.securityCodeDetail);
        }
    }

    private initNewForm() {
        this.formGroup.patchValue({
            securityCode: null,
            securityName: null,
            limitationName: null,
            isCrudFlag: null,
            securityOverrideFlag: null,
            hasLimitationFlag: null,
            status: 'A',
            commitByName: null,
            commitDateTime: null
        })
    }

    private fillModelToForm(model: SecurityCodeReferenceModel) {
        this.formGroup.patchValue({
            securityCode: model.securityCode,
            securityName: model.securityName,
            limitationName: model.limitationName,
            isCrudFlag: model.isCrudFlag ?? false,
            securityOverrideFlag: model.securityOverrideFlag ?? false,
            hasLimitationFlag: model.hasLimitationFlag ?? false,
            status: model.statusCode ?? 'A',
            commitByName: model.commitByName,
            commitDateTime: model.commitDateTime
        })
    }

    public getValue(): SecurityCodeReferenceModel {
        this.markRequiredControlAsTouched();
        this.startProcessing();
        if (!this.formGroup.valid) {
            return;
        }
        let model = this.fillFormToModel();
        this.completeProcessing();
        return model
    }

    private fillFormToModel(): SecurityCodeReferenceModel {
        let model: SecurityCodeReferenceModel = {
            securityCode: this.formGroup.controls['securityCode'].value,
            securityName: this.formGroup.controls['securityName'].value,
            limitationName: this.formGroup.controls['limitationName'].value ?? null,
            isCrudFlag: this.formGroup.controls['isCrudFlag'].value ?? false,
            securityOverrideFlag: this.formGroup.controls['securityOverrideFlag'].value ?? false,
            hasLimitationFlag: this.formGroup.controls['hasLimitationFlag'].value ?? false,
            statusCode: this.formGroup.controls['status'].value ?? 'A',
            commitByName: null,
            commitDateTime: null,
        }
        return model;
    }

    securityCodeChange() {
        let code: string = this.formGroup.controls['securityCode'].value;
        this.formGroup.controls['securityCode'].setValue(code.toUpperCase().trim());
    }

    public valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    saveClick() {
        this.onSave.emit();
    }

    cancelClick() {
        this.onCancel.emit();
        this.newSecurityCode = true;
    }

    refresh() {
        this.formSetup();
    }

    validateDuplicateSecurityCode() {
        let securityCode = this.formGroup.controls['securityCode'].value;
        if (securityCode == '') {
            return;
        }

        this.securityCodeService.getSecurityCodeDetail(securityCode)
            .subscribe(
                (response: SecurityCodeReferenceModel) => {
                    if (response != null && response.securityCode != this.securityCodeDetail?.securityCode) {
                        this.formGroup.controls['securityCode'].setErrors({ duplicate: true });
                        this.changeDetectionRef.detectChanges();
                    } else {
                        this.formGroup.controls['securityCode'].setErrors(null);
                    }
                }
        )
    }

    markRequiredControlAsTouched() {
        this.formGroup.controls['securityCode'].markAsTouched();
        this.formGroup.controls['securityName'].markAsTouched();
        this.formGroup.controls['status'].markAsTouched();
    }

    public copySecurityName() {
        let name = this.formGroup.get('securityName').value;
        name += ' - Copy';
        this.formGroup.get('securityName').setValue(name);
        this.changeDetectionRef.detectChanges();
    }

    public disableDelete(statusCode: string): boolean {
        return statusCode == this.DELETE_STATUS_CODE && !this.moduleSecurity?.deleteFlag;
    }
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { VehicleGroupMapperService } from './vehicle-group.mapper';

import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { LogCommitService } from 'src/app/core/services/system-services';
import { combineLatest } from 'rxjs';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';

@Component({
    selector: 'op-vehicle-group',
    templateUrl: './vehicle-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [VehicleGroupMapperService]
})
export class VehicleGroupComponent implements AfterViewInit {
    @Output() rowSelected = new EventEmitter<OopsCategory>();
    @Output() menuSelected = new EventEmitter<string>();
    public categoriesItems: OopsCategory[] = [];
    readonly VEHICLECOMPOSITION = 'VEHICLECOMPOSITION';
    readonly VEHICLECONFIGURATION = 'VEHICLECONFIGURATION';

    constructor(
        private logCommitService: LogCommitService,
        private securityGroupService: SecurityGroupService,
        private mainSpinner: LoadingSpinnerService,
        private vehicleGroupMapperService: VehicleGroupMapperService,
        private changeDetectionRef: ChangeDetectorRef
    ) { }

    ngAfterViewInit(): void {
        this.getVehicleGroup();
    }

    public getVehicleGroup() {
        this.mainSpinner.show();
  
        combineLatest([
            this.securityGroupService.userSecurityGroupSecurity,
            this.logCommitService.getByCode(this.VEHICLECOMPOSITION),
            this.logCommitService.getByCode(this.VEHICLECONFIGURATION),
        ])
            .subscribe(
                ([
                    userSecurityModels,
                    logCommitVehicleComposition,
                    logCommitVehicleConfiguration
                ]) => {
                    const logCommitMap = new Map<string, LogCommitModel>()
                        logCommitMap.set(this.VEHICLECOMPOSITION, logCommitVehicleComposition)
                        logCommitMap.set(this.VEHICLECONFIGURATION, logCommitVehicleConfiguration)
                    this.categoriesItems = this.vehicleGroupMapperService.toOopsCategoryViews(logCommitMap, userSecurityModels);
                    this.mainSpinner.hide();
                    this.changeDetectionRef.detectChanges();
                },
                error => {
                    this.mainSpinner.hide();
                }
            );
    }

    onRowSelected(item: OopsCategory) {
        this.rowSelected.emit(item);
    }

    onContextMenuSelected(actionId: string) {
        this.menuSelected.emit(actionId);
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { TreeNode } from "primeng/api";
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { ActivityDomainComponent } from "src/app/core/components/activity-domain/activity-domain.component";
import { PointOfSalesMapperService } from "src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service";
import { RestrictionMapperService } from "src/app/core/components/rules-config/shared/mappers/restriction-mapper.service";
import { Select2DataMapperService } from "src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service";
import { ValidityMapperService } from "src/app/core/components/rules-config/shared/mappers/validity-mapper.service";
import { TranslationTextMapperService } from "src/app/core/components/translation-text/shared/translation-text-mapper.service";
import { MediaSearchCommandModel, MediaViewModel } from "src/app/core/models/media-model";
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from "src/app/core/models/organisation-model";
import { FeeAddCommandModel, FeeEditCommandModel, FeeViewModel, ProductPriceConditionViewModel } from "src/app/core/models/product-model/fee-model";
import { ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { ProductHashTagViewModel } from "src/app/core/models/product-model/product-base-model/product-hashtag";
import { ProductHierarchyViewModel } from "src/app/core/models/product-model/product-base-model/product-hierarchy";
import { ProductInventoryDimensionViewModel } from "src/app/core/models/product-model/product-base-model/product-inventory-dimension";
import { ProductInventoryHierarchyViewModel } from "src/app/core/models/product-model/product-base-model/product-inventory-hierarchy";
import { ProductNumberViewModel } from "src/app/core/models/product-model/product-base-model/product-number";
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel, ProductRestrictionProductNumberViewModel, ProductRestrictionProductViewModel, ProductRestrictionRouteViewModel, ProductRestrictionVehicleViewModel } from "src/app/core/models/product-model/product-base-model/product-restriction";
import { ProductValidityViewModel } from "src/app/core/models/product-model/product-base-model/product-validity";
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LanguageReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel, VehicleCompositionModel, VehicleGroupReferenceModel, VehicleTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MediaTypeReferenceModel, MediaTypeFileTypeModel, MediaUseReferenceModel, MediaHashTagModel } from "src/app/core/models/reference-model/reference-media-model";
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DomainAttributeService, HashTagReferenceService, LanguageReferenceService, OrganisationGroupReferenceService, RegionReferenceService, VehicleCompositionService } from "src/app/core/services/airline-services";
import { OrganisationRoleReferenceService, OrganisationService } from "src/app/core/services/organisation-services";
import { FeeService } from "src/app/core/services/product-services";
import { LocationGroupService } from "src/app/core/services/reference-service/reference-general-service";
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from "src/app/core/services/reference-service/reference-media-service";
import { ProductTypeGroupService } from "src/app/core/services/reference-service/reference-product-service";
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService, VehicleGroupReferenceService, VehicleTypeReferenceService } from "src/app/core/services/system-services";
import { ActionService } from "src/app/core/utils/action.service";
import { ActivityStoreService } from "src/app/core/utils/activity-store.service";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, SaveAsButtonModel, CancelButtonModel, DeleteButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { NavigationService } from "src/app/shared/utils/navigation";
import { UIService } from 'src/app/shared/ui/uiservice/ui.service';
import { ProductAttributeAndRuleComponent } from "../../merchandize/attribute-and-rule/attribute-and-rule.component";
import { generalInfoFormOption } from "../../merchandize/merchandize-detail/shared/form-config";
import { MapperService } from "../../merchandize/merchandize-detail/shared/mapper.service";
import { USAGE_TYPE } from "../../shared/constant/usage-type.constant";
import { AttributeMapperService } from "../../shared/mapper/attribute-mapper.service";
import { HashTagMapperService } from "../../shared/mapper/hashtag-mapper.service";
import { InventoryAttributeMapperService } from "../../shared/mapper/inventory-attribute-mapper.service";
import { NumberMapperService } from "../../shared/mapper/number-mapper.service";
import { TreeMapperService } from "../../shared/mapper/tree-mapper.service";
import { ProductHashTagView } from "../../shared/views/product-hashtag.view";
import { FEE_GENERAL_KEYS, FEE_REFERENCE_CODES } from "../shared/constants/fee-reference-codes.constant";
import { GeneralComponent } from "./general/general.component";
import { ProductConditionComponent } from "./product-condition/product-condition.component";
import { ServiceCategorySalesBucketMapperService } from "./service-category-sales-bucket/service-category-sales-bucket-mapper.service";
import { ServiceCategorySalesBucketComponent } from "./service-category-sales-bucket/service-category-sales-bucket.component";
import { ATTRBUTE_GROUP_CODE, SALESBUCKET_CODE, SERVCAT_CODE } from "./shared/fee-detail.constant";
import { Helper } from "src/app/shared/helper/app.helper";
import { TranslationNameComponent } from "src/app/core/components/translation-name/translation-name.component";
import { LanguageTranslationSetViewModel } from "src/app/core/models/language-translation-set-model";
import { TranslationService } from "src/app/core/services/translation-services";
import { TranslationNameMapperService } from "src/app/core/components/translation-name/translation-name.mapper.service";
import { TranslationTextComponent } from "src/app/core/components/translation-text/translation-text.component";
import { TextMediaComponent } from "src/app/core/components/text-media/text-media.component";
import { TextMapperService } from "src/app/core/components/text/shared/text-mapper.service";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { TranslationMediaMapperService } from "src/app/core/components/translation-media/shared/translation-media-mapper.service";
import { TranslationMediaComponent } from "src/app/core/components/translation-media/translation-media.component";
import { RestrictionModel } from "src/app/core/models/merchandizing-config";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'op-fee-detail',
    templateUrl: './fee-detail.component.html',
    providers: [
        AttributeMapperService, HashTagMapperService, InventoryAttributeMapperService,
        MapperService, MediaMapperService, NumberMapperService, PointOfSalesMapperService, ProductTypeGroupService, RestrictionMapperService,
        Select2DataMapperService, TextMapperService, TranslationTextMapperService, TreeMapperService, UIService, ValidityMapperService,
        ServiceCategorySalesBucketMapperService, TranslationNameMapperService, TranslationMediaMapperService, ActionbarService,
        AlertBarService
    ]
})
export class FeeDetailComponent extends OopsComponentFormBase implements OnInit, OnDestroy, AfterViewInit {
    readonly jsonUrl = 'assets/data/fee/attributes&rules_config.json';
    readonly generalTabId: string = 'general';
    readonly attributesTabId: string = 'attributes';
    readonly textTabId: string = 'text';
    readonly mediaTabId: string = 'media';
    readonly conditionTabId: string = 'condition';
    readonly translationTabId: string = 'translation';
    readonly inventoryTab: string = "inventory";

    readonly generalPanelName: string = 'General Information';
    readonly productNumberPanelName: string = 'Product Number';
    readonly attributePanelName: string = 'Attribute';
    readonly restrictionPanelName: string = 'Restriction';
    readonly validityPanelName: string = 'Validity';
    readonly pointOfSalesPanelName: string = 'Point Of Sales';
    readonly serviceCategorySalesBucketPanelName: string = 'Service Category and Sales Bucket';
    readonly productConditionPanelName: string = 'Product Condition';
    
    readonly TRANSLATION_NAME = "Airline translation set";
    readonly ALERT_HEADER: string = "Product";
    readonly FAILED: string = "Failed save data.";
    private readonly DELAY_TIME = 100;
    public readonly ENTITY_NAME: string = "Product";

    private readonly SERVICE_CAT_SALES_BUCKET_ATTRIBUTE_TYPES = [
        'SERVICECATEGORY',
        'SALESBUCKET'
    ]

    private readonly actionMap = {
        [ACTION_STATUS.refresh]: () => {
            this.getProduct(this.id);
        },
        [ACTION_STATUS.cancel]: () => {
            this.cancel();
        },
        [ACTION_STATUS.back]: () => {
            this.backToSearch();
        },
        [ACTION_STATUS.save]: () => {
            this.onSaveClick();
        },
        [ACTION_STATUS.new]: () => {
            this.createNewProduct();
        }
    }

    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() rootProductId: string;

    @Output() srvCategoryValid = new EventEmitter<boolean>();

    @ViewChild(GeneralComponent) generalComponent: GeneralComponent;
    @ViewChild(ProductAttributeAndRuleComponent) attributeAndRuleComponent: ProductAttributeAndRuleComponent;
    @ViewChild(ServiceCategorySalesBucketComponent) serviceCategorySalesBucketComponent: ServiceCategorySalesBucketComponent;
    @ViewChild(ProductConditionComponent) productConditionComponent: ProductConditionComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TextMediaComponent) textMedia: TextMediaComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;

    public isReferencesBinding: boolean = false;
    public calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public hashTagReferences: HashTagReferenceModel[];
    public languageReferences$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public locationGroupSelect2Data: Select2Data[];
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeReferences$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaTypeFileTypes$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public mediaUseReferences$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public organisationGroupSelect2Data: Select2Data[];
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    private productAttributesInherit: ProductAttributeViewModel[];
    public productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferencesAll$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productHashtagValueViews: ProductHashTagView[] = new Array();
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    public productInventoryHierarchy: ProductInventoryHierarchyViewModel[];
    public productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public productTypeGroups$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public regionReferenceSelect2Data: Select2Data[];
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public salesBucketChoice$ = new BehaviorSubject<Select2Data[]>(null);
    public serviceCategoryChoice$ = new BehaviorSubject<Select2Data[]>(null);
    public productName$ = new BehaviorSubject<string>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    public vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);

    private parentId: string;
    private copy: boolean = false;
    public newFromParent: boolean = false;
    public nodeSelected: boolean = false;
    public inventoryAttributeLowerLevel: string;
    public feeTree: TreeNode[];
    public feeDetailFormGroup: UntypedFormGroup;
    public productAttributeValue: any;
    public productTypeCode: string;
    public rootCommandData: FeeEditCommandModel;
    private inventoryData: any;
    public heightPanelGeneral: number = 435;
    public heightPanelNameTranslation: number = 402;

    public showReadMode: boolean = false;
    public showInvAttributeType: boolean = true;
    public showInv: boolean = false;
    public saveAction: boolean = false;

    public selectedTab = this.generalTabId;
    public classIcon: string = this.helper.getClassIcon();

    public restrictionConfig: RestrictionModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;
    unsubscribe$ = new Subject();
    productNameUnsubscriber$ = new Subject();

    private currentHierarchy: ProductHierarchyViewModel;
    private subscriptionPool = [];
    private languageTranslationSet: LanguageTranslationSetViewModel;
    public isNewChild = false;
    public userSecurity: SecurityGroupSecurityModel;

    get hideGeneralTab(): boolean {
        let hide = this.selectedTab != this.generalTabId &&
            this.selectedTab != this.attributesTabId &&
            this.selectedTab != this.conditionTabId;
        return hide;
    }

    get hiddenTree(): boolean {
        return !!(this.newProduct);
    }

    get translationMode(): boolean {
        return this.selectedTab == this.translationTabId;
    }

    get productName(): string {
        return this.feeDetailFormGroup?.controls?.generalInfo?.get('productName')?.value;
    }

    constructor(
        private actionbarService: ActionbarService,
        public alertBarService: AlertBarService,
        private alertService: ToastrService,
        private attributeMapperService: AttributeMapperService,
        private calendarValidityReferenceService: CalendarValidityService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private dateConverterService: DateConverterService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private domainAttributeService: DomainAttributeService,
        private changeDetectionRef: ChangeDetectorRef,
        private focusingService: FocusingService,
        private formBuilder: UntypedFormBuilder,
        private hashTagMapperService: HashTagMapperService,
        private hashTagReferenceService: HashTagReferenceService,
        private languageReferenceService: LanguageReferenceService,
        private locationGroupService: LocationGroupService,
        private mapperService: MapperService,
        private mediaHashTagService: MediaHashTagService,
        private mediaMapperService: MediaMapperService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private navigationService: NavigationService,
        private numberMapperService: NumberMapperService,
        private organisationService: OrganisationService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private regionReferenceService: RegionReferenceService,
        private restrictionMapperService: RestrictionMapperService,
        private select2DataMapperService: Select2DataMapperService,
        private spinnerService: LoadingSpinnerService,
        private statusReferenceService: StatusReferenceService,
        private textMapperService: TextMapperService,
        private translationTextMapperService: TranslationTextMapperService,
        private treeMapperService: TreeMapperService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private validityMapperService: ValidityMapperService,
        private feeService: FeeService,
        private serviceCategorySalesBucketMapperService: ServiceCategorySalesBucketMapperService,
        private stringHelperService: StringHelperService,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService,
        private helper: Helper,
        private translationService: TranslationService,
        private translationNameMapperService: TranslationNameMapperService,
        private translationMediaMapperService: TranslationMediaMapperService,
        private http: HttpClient,
        private vehicleGroupReferenceService: VehicleGroupReferenceService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private vehicleCompositionService: VehicleCompositionService
    ) {
        super(formBuilder);
        this.http.get(this.jsonUrl).subscribe(
            (value: any) => {
                this.restrictionConfig = Object.assign(new RestrictionModel(), value.restrictions);
            }
        )
        this.addSubscriptionsToPool();
        this.setDefaultActionBarHandler();
        this.init();
    }

    private addSubscriptionsToPool() {
        this.subscriptionPool.push(this.unsubscribe$);
        this.subscriptionPool.push(this.productNameUnsubscriber$);
    }

    private navigationRequestParams: any;

    ngOnInit(): void {
        
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.defaultActionBarHandler);
        this.subscribeToActionBar();
        this.getEmptyLanguageTranslationSet();
        this.getMediaTypeReference();
        this.getLanguageReference();
        this.getMediaUseReference();
        this.getMediaTypeFileType();
        this.getVehicleTypeReferences();
        this.getVehicleGroupReferences();
        this.getVehicleCompositions();
    }

    ngOnDestroy(): void {
        this.destroySubscriptions();
    }

    private setupActionbarSecurity() {
        this.defaultActionBarHandler.get(ACTION_STATUS.new).enable(this.userSecurity?.newFlag ?? false);
        this.defaultActionBarHandler.get(ACTION_STATUS.copy).enable(this.userSecurity?.copyFlag ?? false);
        this.defaultActionBarHandler.get(ACTION_STATUS.save).enable(this.saveFlag);
        this.defaultActionBarHandler.get(ACTION_STATUS.delete).enable(this.userSecurity?.deleteFlag ?? false);
    }

    get saveFlag() {
        return ((this.newProduct == true || this.isNewChild == true) && this.userSecurity?.newFlag == true) || ((this.newProduct == false && this.isNewChild == false) && this.userSecurity?.editFlag == true);
    }

    private destroySubscriptions() {
        this.subscriptionPool.forEach(sub => {
            sub.next();
            sub.unsubscribe();
        })
    }

    private init() {
        this.navigationRequestParams = this.navigationService.getParams();
        if (!this.navigationRequestParams)
            this.navigationRequestParams = this.navigationService.getPreviousTabParams()?.params;

        this.id = this.navigationRequestParams?.productId ?? this.navigationRequestParams?.id ?? null;
        this.copy = this.navigationRequestParams?.copy ?? false;
        this.newProduct = this.copy ? true : this.id == null;
        this.userSecurity = this.navigationRequestParams?.userSecurity;
        this.formSetup();
        this.loadPage();
        this.addAction();
        if (!this.id) {
            this.activityStore();
        }
    }

    public initForm() {

    }

    private createNewProduct() {
        if (this.newProduct || this.isNewChild) {
            return;
        }
        this.parentId = this.id;
        this.id = this.stringHelperService.NewGuid();
        this.isNewChild = true;
        this.resetForms();
        this.setupActionbarSecurity();
        this.activityStore();
    }

    private resetForms() {
        let editCommand = this.fillFormGroupToEditModel();
        this.changeDetectionRef.markForCheck();
        var generalInfo = this.feeDetailFormGroup.controls.generalInfo;
        generalInfo.patchValue({
            productCategoryCode: FEE_REFERENCE_CODES.productCategoryCode,
            productGroupCode: editCommand.productGroupCode,
            productTypeGroupId: editCommand.productTypeCode,
            providerId: editCommand.providerId,
            parentProductId: this.parentId,
            productName: '',
            productDescription: '',
            statusCode: editCommand.statusCode,
            draftFlag: editCommand.draftFlag,
            usageTypeCode: editCommand.usageTypeCode,
            commitDateTime: '',
            commitByName: ''
        });
        this.disableContrtolGeneral(generalInfo, editCommand.draftFlag);

        //for display attribute 
        this.productTypeCode = editCommand.productTypeCode;
        this.setProductHashTagValue([]);
        this.fillModelToProductNumber([]);
        this.fillModelToProductRestriction([], [], [], [], []);
        this.fillModelToProductValidity([]);
        this.fillModelToProductAttribute([], [], false, editCommand.draftFlag);
        this.fillModelToPointOfSales([], []);
        this.serviceCategorySalesBucketComponent.resetForms();
        this.fillModelToText([]);
        this.fillModelToMedia([]);
        this.fillProductPriceCondition([]);
        this.addNewChildToTree(editCommand);
        this.translationNameComponent.clearForm();
        this.translationNameComponent?.fillModelToForm(null, this.languageTranslationSet);
        this.changeDetectionRef.detectChanges();
    }

    private addNewChildToTree(editCommand: FeeEditCommandModel) {
        let newHierarchy = Object.assign({}, this.currentHierarchy);
        let selectingNode = this.findSelectingNode(this.parentId, newHierarchy);
        if (!selectingNode) {
            return;
        }
        selectingNode.childProduct.push({
            productId: this.id,
            productName: '',
            statusCode: editCommand.statusCode,
            childProduct: []
        } as ProductHierarchyViewModel);
        this.feeTree = this.treeMapperService.productTreeView(newHierarchy);
    }

    private findSelectingNode(productId: string, productHierarchy: ProductHierarchyViewModel): ProductHierarchyViewModel {
        if (productHierarchy.productId == productId) {
            return productHierarchy;
        }
        for (let child of productHierarchy.childProduct) {
            let found = this.findSelectingNode(productId, child);
            if (found) {
                return found;
            }
        }
        return null;
    }

    private formSetup() {
        this.feeDetailFormGroup = this.formBuilder.group({
            generalInfo: this.formBuilder.group(generalInfoFormOption)
        });
        this.initNewform(null, null, null, null);
    }

    private loadProductByIdIfValidAfterFinishReferences() {
        if (this.id) {
            this.getProduct(this.id)
            if (this.copy) {
                this.rootProductId = this.id;
                this.id = null;
            }
        }
    }

    private initNewform(parentId, productGroupCode, productTypeGroupId, providerId) {
        this.feeDetailFormGroup.controls.generalInfo.patchValue({
            parentProductId: parentId,
            productName: "",
            productCategoryCode: FEE_REFERENCE_CODES.productCategoryCode,
            productGroupCode: productGroupCode,
            productTypeGroupId: productTypeGroupId,
            providerId: providerId,
            draftFlag: true,
            statusCode: "A",
            productDescription: "",
            usageTypeCode: "DATA"
        });
        this.productTypeCode = productTypeGroupId;
    }

    private loadPage() {
        this.spinnerService.show();

        if (!this.isReferencesBinding) {
            this.isReferencesBinding = true;
            this.fetchData();
        }
    }

    private fetchData() {
        let services = [];
        // General
        services = services.concat(this.organisationService.getByOrganisationType(FEE_REFERENCE_CODES.orgTypeSupplierCode))
        services = services.concat(this.productCategoryReferenceService.getAll());
        services = services.concat(this.productGroupReferenceService.getByProductCategory(FEE_REFERENCE_CODES.productCategoryCode));
        services = services.concat(this.productGroupReferenceService.getAll());
        services = services.concat(this.productTypeGroupService.getAll());
        services = services.concat(this.statusReferenceService.getAll());
        services = services.concat(this.usageTypeReferenceService.getUsageTypeReferences());

        // Attribute & Rule
        services = services.concat(this.calendarValidityReferenceService.getByCalendarValidityCodes(FEE_REFERENCE_CODES.calendarValidityCode));
        services = services.concat(this.conditionReferenceService.getConditionsByCodes([]));
        services = services.concat(this.countryReferenceService.searchAllCountry());
        services = services.concat(this.dateTimeDimensionReferenceService.getDateTimeDimensionReference());
        services = services.concat(this.domainAttributeService.getByProductType(FEE_REFERENCE_CODES.attributeGroupCode, "", true));

        services = services.concat(this.hashTagReferenceService.getAll());
        services = services.concat(this.languageReferenceService.getByOrganisation());
        services = services.concat(this.locationGroupService.getAll());
        services = services.concat(this.mediaHashTagService.getAll());
        services = services.concat(this.mediaTypeFileTypeService.getAll());
        services = services.concat(this.mediaTypeReferenceService.getAll());
        services = services.concat(this.mediaUseReferenceService.getAll());
        services = services.concat(this.organisationService.getByStatus(FEE_REFERENCE_CODES.activeStatus));
        services = services.concat(this.organisationGroupReferenceService.getOrganisationGroupReference());
        services = services.concat(this.organisationTypeReferenceService.getByStatus(FEE_REFERENCE_CODES.activeStatus));
        services = services.concat(this.organisationRoleReferenceService.getAllActive());

        services = services.concat(this.organisationService.getByOrganisationType(FEE_REFERENCE_CODES.orgTypeProviderCode)); // Providers
        services = services.concat(this.productLocationPointReferenceService.getAll());
        services = services.concat(this.productLocationTypeReferenceService.getByProductLocationTypeCode(FEE_REFERENCE_CODES.productLocationTypeCode));
        services = services.concat(this.productNumberTypeReferenceService.getAll());
        services = services.concat(this.regionReferenceService.getRegionReference());
        services = services.concat(this.domainAttributeService.getByProductType(ATTRBUTE_GROUP_CODE));

        forkJoin(services).subscribe(
            res => {
                let i = 0;
                this.extractOrgTypeSupplierReferences(res[i++] as OrganisationModel[])
                this.extractProductCategoryReferences(res[i++] as ProductCategoryReferenceModel[]);
                this.extractProductGroupReferences(res[i++] as ProductGroupReferenceModel[]);
                this.extractProductGroupReferencesAll(res[i++] as ProductGroupReferenceModel[]);
                this.extractProductTypeGroup(res[i++] as ProductTypeGroupModel[]);
                this.extractStatusReferences(res[i++] as StatusReferenceModel[]);
                this.extractUsageTypeReferences(res[i++] as UsageTypeReferenceModel[]);

                // Attribute & Rules
                this.extractCalendarValidityReferences(res[i++] as CalendarValidityReferenceModel[]);
                this.extractConditionReferences(res[i++] as ConditionReferenceModel[]);
                this.extractCountryReferences(res[i++] as CountryReferenceModel[]);
                this.extractDateTimeDimensionReferences(res[i++] as DateTimeDimensionReferenceModel[]);
                this.extractDomainAttributeTypes(res[i++] as DomainAttributeModel[]);

                this.extractHashTagReferences(res[i++] as HashTagReferenceModel[]);
                this.extractLanguageReferences(res[i++] as LanguageReferenceModel[]);
                this.extractLocationGroups(res[i++] as LocationGroupModel[]);
                this.extractMediaHashTags(res[i++] as MediaHashTagModel[]);
                this.extractMediaTypeFileTypes(res[i++] as MediaTypeFileTypeModel[]);
                this.extractMediaTypeReferences(res[i++] as MediaTypeReferenceModel[]);
                this.extractMediaUseReferences(res[i++] as MediaUseReferenceModel[]);
                this.extractOrganisations(res[i++] as OrganisationModel[]);
                this.extractOrganisationGroupReferences(res[i++] as OrganisationGroupReferenceModel[]);
                this.extractOrganisationTypeReferences(res[i++] as OrganisationTypeReferenceModel[]);
                this.extractOrganisationRoleReferences(res[i++] as OrganisationRoleReferenceModel[]);

                this.extractOrgTypeProviderReferences(res[i++] as OrganisationModel[]);
                this.extractProductLocationPointReferences(res[i++] as ProductLocationPointReferenceModel[]);
                this.extractProductLocationTypeReferences(res[i++] as ProductLocationTypeReferenceModel)
                this.extractProductNumberTypeReferences(res[i++] as ProductNumberTypeReferenceModel[]);
                this.extractRegionReferences(res[i++] as RegionReferenceModel[]);
                this.geAttributeByProductType(res[i++] as DomainAttributeModel[]);

                this.spinnerService.hide();
                this.loadProductByIdIfValidAfterFinishReferences();
            }
        )

    }

    private extractCalendarValidityReferences(res: CalendarValidityReferenceModel[]) {
        this.calendarValidityReferences$.next(res);
    }

    private extractConditionReferences(res: ConditionReferenceModel[]) {
        this.conditionReferences$.next(res);
    }

    private extractCountryReferences(res: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(res);
    }

    private extractDateTimeDimensionReferences(res: DateTimeDimensionReferenceModel[]) {
        this.dateTimeDimensionReferences$.next(res);
    }

    private extractDomainAttributeTypes(res: DomainAttributeModel[]) {
        res.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        this.domainAttributeSearch$.next(res);
    }

    private extractHashTagReferences(res: HashTagReferenceModel[]) {
        this.hashTagReferences = res;
        this.setProductHashTagValue(null);
    }

    private extractLocationGroups(res: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(res);
    }

    private extractMediaHashTags(res: MediaHashTagModel[]) {
        this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(res);
    }

    private extractMediaTypeFileTypes(res: MediaTypeFileTypeModel[]) {
        this.mediaTypeFileTypes$.next(res);
    }

    private extractMediaTypeReferences(res: MediaTypeReferenceModel[]) {
        this.mediaTypeReferences$.next(res);
    }

    private extractMediaUseReferences(res: MediaUseReferenceModel[]) {
        this.mediaUseReferences$.next(res);
    }

    private extractOrganisations(res: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(res);
    }

    private extractOrganisationGroupReferences(res: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(res);
    }

    private extractOrganisationTypeReferences(res: OrganisationTypeReferenceModel[]) {
        this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(res);
    }

    private extractOrganisationRoleReferences(res: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(res);
    }

    private extractOrgTypeProviderReferences(res: OrganisationModel[]) {
        this.providers$.next(res
            .filter(x => x.providerIataCode)
            .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
    }

    private extractOrgTypeSupplierReferences(res: OrganisationModel[]) {
        this.suppliers$.next(res);
    }

    private extractProductCategoryReferences(res: ProductCategoryReferenceModel[]) {
        this.productCategoryReferences$.next(res);
    }

    private extractProductGroupReferences(res: ProductGroupReferenceModel[]) {
        this.productGroupReferences$.next(res);
    }

    private extractProductGroupReferencesAll(res: ProductGroupReferenceModel[]) {
        this.productGroupReferencesAll$.next(res);
    }

    private extractProductLocationPointReferences(res: ProductLocationPointReferenceModel[]) {
        this.productLocationPointReferences$.next(res);
    }

    private extractProductLocationTypeReferences(res: ProductLocationTypeReferenceModel) {
        if (res) {
            let values = new Array<ProductLocationTypeReferenceModel>();
            values.push(res);
            this.productLocationTypeReferences$.next(values);
        }
    }

    private extractProductNumberTypeReferences(res: ProductNumberTypeReferenceModel[]) {
        if (res) {
            this.productNumberTypeReferences$.next(res);
        }
    }

    private extractProductTypeGroup(res: ProductTypeGroupModel[]) {
        if (res) {
            this.productTypeGroups$.next(res);
        }
    }

    private extractRegionReferences(res: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(res);
    }

    private extractStatusReferences(res: StatusReferenceModel[]) {
        this.statusReferences$.next(res);
    }

    private extractUsageTypeReferences(res: UsageTypeReferenceModel[]) {
        let values = res.filter(x => x.usageTypeCode == USAGE_TYPE.data || x.usageTypeCode == USAGE_TYPE.template);
        this.usageTypeReferences$.next(values);
    }

    private extractLanguageReferences(res: LanguageReferenceModel[]) {
        this.languageReferences$.next(res);
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        let productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReferences, value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTags$.next(productHashtagViews);
    }

    public hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    public returnProductAttributeValue(value) {
        this.productAttributeValue = value;
    }

    public productTypeCodeChange(value) {
        this.productTypeCode = value;
    }

    private getProduct(productId) {
        if (productId) {
            this.spinnerService.show();
            this.storeRootData();
            this.feeService.getByProductId(productId)
                .subscribe(
                    (responses: FeeViewModel) => {
                        this.isNewChild = false;
                        this.populateData(responses);
                    },
                    error => {
                        this.changeDetectionRef.detectChanges;
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private populateData(responses: FeeViewModel) {
        this.fillModelToForm(responses);
        this.currentHierarchy = responses.productHierarchy;
        this.feeTree = this.treeMapperService.productTreeView(responses.productHierarchy);
        this.setProductHashTagValue(responses.productHashtags);
        this.fillModelToProductNumber(responses.productNumbers);
        this.fillModelToProductRestriction(responses.productRestrictionProducts,
            responses.productRestrictionProductNumbers,
            responses.productRestrictionRoutes,
            responses.productRestrictionLocations,
            responses.productRestrictionVehicles
        );
        this.fillModelToProductValidity(responses.productValidities);
        this.fillModelToProductAttribute(responses.productInventoryDimensions, responses.productAttributes, responses.isHighestLevelAttrInv, responses.draftFlag);
        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
        this.fillModelToServiceCategorySalesBucket(responses.productAttributes);
        this.fillModelToText(responses.medias);
        this.fillModelToTranslationText(responses.medias);
        this.fillModelToMedia(responses.medias);
        this.fillModelToTranslationMedia(responses.medias);
        this.fillProductPriceCondition(responses.productPriceConditions);
        this.fillModelToTranslationName(responses.productName, responses.languageTranslationSet);

        this.newFromParent = false;
        this.attributeAndRuleComponent.productAttributeComponent.newFromParent = this.newFromParent;
        this.setupActionbarSecurity();
    }

    private storeRootData() {
        if (this.copy && this.id == this.rootProductId && this.attributeAndRuleComponent) {
            this.rootCommandData = this.fillFormGroupToEditModel();
        }
    }

    private fillFormGroupToEditModel(): FeeEditCommandModel {
        let generalInfo = this.feeDetailFormGroup.controls.generalInfo;
        let formValue = generalInfo.value;
        var productGroupCode = generalInfo.get("productGroupCode").value;
        var productTypeGroupId = generalInfo.get("productTypeGroupId").value;
        var draftFlag = generalInfo.get("draftFlag").value;
        var usageTypeCode = generalInfo.get("usageTypeCode").value;

        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms, this.providers$);

        let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
            this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);

        let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
        let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
        let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
        let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
        let productRestrictionVehicles = this.restrictionMapperService.productRestrictionVehicleCommandModels(productRestrictionViews);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

        let productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.productAttributeComponent.forms);

        productAttributes = productAttributes.concat(this.serviceCategorySalesBucketComponent.fillFormsToModels());

        let productInventories = this.mapperService.productInventoryToModels(this.inventoryData);

        let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);

        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);
        medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, medias);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.id, this.translationNameComponent.translationViews);
            
        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let productPriceConditions = this.productConditionComponent.fillFormsToModels();

        let editCommand: FeeEditCommandModel = {
            productId: this.id,
            productCategoryCode: FEE_REFERENCE_CODES.productCategoryCode,
            productGroupCode: productGroupCode,
            productTypeCode: productTypeGroupId,
            providerId: formValue.providerId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            supplierId: null,
            displayCode: null,
            draftFlag: draftFlag,
            finalFlag: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            usageTypeCode: usageTypeCode,
            isOwnerFilter: null,
            filterUserAccountId: null,
            statusCode: formValue.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: [],
            productInventories: productInventories,
            productRestrictionOrganisations: productRestrictionOrganisations,
            languageTranslationSet: languageTranslationSet,
            medias: medias,
            productPriceConditions: productPriceConditions,
            productRestrictionVehicles: productRestrictionVehicles
        };
        return editCommand;
    }

    private fillModelToForm(responses: FeeViewModel) {
        this.id = null;
        this.changeDetectionRef.detectChanges();
        this.generalComponent.filterProductTypeGroup(responses.productGroupCode);
        var generalInfo = this.feeDetailFormGroup.controls.generalInfo;
        var productName = this.getProductName(responses.productName);
        var usageTypeCode = this.getUsageTypeCode(responses.usageTypeCode);
        generalInfo.patchValue({
            productCategoryCode: FEE_REFERENCE_CODES.productCategoryCode,
            productGroupCode: responses.productGroupCode,
            productTypeGroupId: responses.productTypeCode,
            providerId: responses.providerId,
            parentProductId: responses.parentProductId,
            productName: productName,
            productDescription: responses.productDescription,
            statusCode: responses.statusCode,
            draftFlag: responses.draftFlag,
            usageTypeCode: usageTypeCode,
            commitDateTime: this.dateConverterService.convertDateTime(responses.commitDateTime),
            commitByName: responses.commitFirstname + ' ' + responses.commitLastname
        });
        this.productName$.next(productName);
        this.subscribeForProductNameChange(generalInfo);
        this.id = responses.productId;
        this.disableContrtolGeneral(generalInfo, responses.draftFlag);
        this.addAction();
        // if not node selected read parent product        
        if (!this.nodeSelected) {
            this.parentId = responses.productId;
        }

        //for display attribute 
        this.productTypeCode = responses.productTypeCode;
        this.changeDetectionRef.detectChanges();
    }

    private subscribeForProductNameChange(generalInfo: AbstractControl) {
        this.productNameUnsubscriber$.next();
        generalInfo.get('productName').valueChanges
            .pipe(takeUntil(this.productNameUnsubscriber$))
            .subscribe(
                (productName: string) => {
                    this.productName$.next(productName);
                }
            );
    }

    private disableContrtolGeneral(generalInfo: AbstractControl, draftFlag: boolean) {
        generalInfo.get('productGroupCode').disable();
        generalInfo.get('productTypeGroupId').disable();
        this.disableDraftFlag(generalInfo, draftFlag);
        this.disableUsageType(generalInfo, draftFlag);
    }

    private disableDraftFlag(generalInfo: AbstractControl, draftFlag: boolean) {
        if (!draftFlag) {
            generalInfo.get('draftFlag').disable();
        }
        else {
            generalInfo.get('draftFlag').enable();
        }
    }

    private disableUsageType(generalInfo: AbstractControl, draftFlag: boolean) {
        if ((this.copy && (this.rootProductId == this.id)) || (!this.copy && draftFlag)) {
            generalInfo.get('usageTypeCode').enable();
        } else {
            generalInfo.get('usageTypeCode').disable();
        }
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        if (this.attributeAndRuleComponent?.productNumberComponent) {
            this.attributeAndRuleComponent.productNumberComponent.addViewToFormGroup(productNumbers);
        }
    }

    private fillModelToProductAttribute(productInventoryDimensions: ProductInventoryDimensionViewModel[],
        productAttributes: ProductAttributeViewModel[],
        isHighestProductInventoryLevel: boolean,
        draftFlag: boolean) {
        productAttributes = productAttributes.filter(p => this.SERVICE_CAT_SALES_BUCKET_ATTRIBUTE_TYPES.includes(p.attributeTypeCode) == false);
        this.productAttributesInherit = productAttributes;
        this.attributeAndRuleComponent.productAttributeComponent.productAttributes = this.attributeMapperService.productAttributesToForm(productAttributes, false);

        this.attributeAndRuleComponent.productAttributeComponent.productInventoryDimensionExist = productInventoryDimensions;
        this.attributeAndRuleComponent.productAttributeComponent.isHighestProductInventoryLevel = isHighestProductInventoryLevel;
        this.attributeAndRuleComponent.productAttributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.productAttributeComponent.addViewToFormGroup();
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[],
        productRestrictionVehicles: ProductRestrictionVehicleViewModel[]) {

        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            productRestrictionVehicles,
            false);

        this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.addRestrictionViewToFormGroup(productRestrictionViews);
        this.attributeAndRuleComponent.restrictionRuleComponent.detectChanges();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityToFormGroup(productValiditiesProduct);
        this.attributeAndRuleComponent.validityRuleComponent.detectChanges();
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private fillModelToServiceCategorySalesBucket(productAttributes: ProductAttributeViewModel[]) {
        this.serviceCategorySalesBucketComponent.fillModelsToForms(productAttributes);
    }

    private fillModelToText(medias: MediaViewModel[]) {
        let mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMedia.textComponent.fillModelToForm(mediaTextViews);
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        let mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMedia.mediaComponent.fillModelToForm(mediaViews);
    }

    private fillProductPriceCondition(productPriceConditions: ProductPriceConditionViewModel[]) {
        this.productConditionComponent.fillModelsToForm(productPriceConditions);
    }

    public saveInventoryData(value) {
        this.inventoryData = value;
    }

    private getProductName(productName: string): string {
        if (this.copy) {
            return productName + " -copy";
        }
        return productName;
    }

    private getUsageTypeCode(usageypeCode: string): string {
        if (this.copy && this.rootCommandData) {
            return this.rootCommandData.usageTypeCode;
        } else if (this.copy) {
            return "DATA";
        }
        return usageypeCode;
    }

    public parentChange(value) {
        this.nodeSelected = true;
        this.getProduct(value);
        this.parentId = value;
    }

    public onGeneralFormStatusChange() {
        this.updateInputValidation();
    }

    public onPanelGeneralHeightChange(height) {
        this.heightPanelGeneral = height + 31.8;
    }

    setDefaultActionBarHandler() {
        this.copyBtn.disable();
        this.deleteBtn.disable();

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    subscribeToActionBar() {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(actionId => this.actionMap[actionId]());
    }

    onActiveIdChange(activeId: string) {
        this.selectedTab = activeId;
        switch (activeId) {
            case this.generalTabId:
                this.focusingService.focus(this.generalComponent.focusingDirective);
                break;
            case this.attributesTabId:
                this.focusingService.focus(this.attributeAndRuleComponent.focusingDirective);
                break;
            case this.textTabId:
                this.focusingService.focus(this.textMedia.textComponent.focusingDirective);
                break;
            case this.mediaTabId:
                this.focusingService.focus(this.textMedia.mediaComponent.focusingDirective);
                break;
            case this.conditionTabId:
                this.focusingService.focus(this.productConditionComponent.focusingDirective);
                break;
            case this.translationTabId:
                this.focusingService.focus(this.translationNameComponent.focusingDirective);
                break;
            default:
                break;
        }
    }

    cancel() {
        let params = {
            tab: null,
            module: null
        }
        this.navigationService.navigate('/main/product/home', null, null, params);
    }


    private onSaveClick() {
        this.saveAction = true;

        if (!this.validateAll()) {
            this.displayAlertBar();
            this.onAlertBarClicked();
            return;
        }
        this.generalComponent.completeProcessing();
        this.alertBarService.hide();

        if (this.newProduct || this.isNewChild) {
            this.addProduct();
        } else {
            this.updateProduct();
        }
    }

    private updateInputValidation() {
        this.displayAlertBar();
    }

    private validateAll(): boolean {
        let generalValid = this.generalComponent.validateForm();
        if (!generalValid) {
            this.feeDetailFormGroup.controls.generalInfo.markAllAsTouched();
        }

        let productNumberValid = this.attributeAndRuleComponent.productNumberComponent.validateForm();
        let productAttributeValid = this.attributeAndRuleComponent.productAttributeComponent.validateForm();
        let restrictionValid = this.attributeAndRuleComponent.restrictionRuleComponent.validateForm();
        let validityValid = this.attributeAndRuleComponent.validityRuleComponent.validateForm();
        let pointOfSalesValid = this.attributeAndRuleComponent.pointOfSalesComponent.validateForm();
        let serviceCatSalesBucketValid = this.serviceCategorySalesBucketComponent.validateForm();
        let productPriceConditionValid = this.productConditionComponent.validateForm();

        return generalValid && productNumberValid && productAttributeValid && restrictionValid && validityValid && pointOfSalesValid && serviceCatSalesBucketValid && productPriceConditionValid;
    }

    private displayAlertBar() {
        if (!this.generalComponent.validForm()) {
            this.alertBarService.show(this.generalPanelName, this.generalComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.productNumberComponent.validateForm()) {
            this.alertBarService.show(this.productNumberPanelName, this.attributeAndRuleComponent.productNumberComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.productAttributeComponent.validateForm()) {
            this.alertBarService.show(this.attributePanelName, this.attributeAndRuleComponent.productAttributeComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.restrictionRuleComponent.validateForm()) {
            this.alertBarService.show(this.restrictionPanelName, this.attributeAndRuleComponent.restrictionRuleComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.validityRuleComponent.validateForm()) {
            this.alertBarService.show(this.validityPanelName, this.attributeAndRuleComponent.validityRuleComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.pointOfSalesComponent.validateForm()) {
            this.alertBarService.show(this.pointOfSalesPanelName, this.attributeAndRuleComponent.pointOfSalesComponent.getErrorMessageForm());
        } else if (!this.serviceCategorySalesBucketComponent.validateForm()) {
            this.alertBarService.show(this.serviceCategorySalesBucketPanelName, this.serviceCategorySalesBucketComponent.getErrorMessageForm());
        } else if (!this.productConditionComponent.validateForm()) {
            this.alertBarService.show(this.productConditionPanelName, this.productConditionComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    public onAlertBarClicked() {
        if (!this.generalComponent.validForm()) {
            this.onActiveIdChange(this.generalTabId);
        } else {
            this.onActiveIdChange(this.attributesTabId);
            if (!this.attributeAndRuleComponent.productNumberComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.productNumberComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.productAttributeComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.productAttributeComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.restrictionRuleComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.restrictionRuleComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.validityRuleComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.validityRuleComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.pointOfSalesComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.pointOfSalesComponent.focusingDirective)
            } else if (!this.serviceCategorySalesBucketComponent.validateForm()) {
                this.focusingService.focus(this.serviceCategorySalesBucketComponent.focusingDirective)
            } else if (!this.productConditionComponent.validateForm()) {
                this.focusingService.focus(this.productConditionComponent.focusingDirective)
            }
            else {
                this.alertBarService.hide();
            }
        }
    }

    private addProduct() {
        let addCommand = this.fillFormGroupToAddModel();
        this.spinnerService.showSaving();
        this.feeService.addProduct(addCommand)
            .subscribe(
                (response) => {
                    this.getProduct(response.body);
                    this.setformToEditMode();
                    this.spinnerService.saveComplete();
                },
                error => {
                    this.spinnerService.hide();
                }
            )
    }

    private updateProduct() {
        let editCommand = this.fillFormGroupToEditModel();
        this.spinnerService.showSaving();
        this.activityDomainComponent.save();
        this.feeService.editProduct(editCommand)
            .subscribe(
                (response) => {
                    this.nodeSelected = false;
                    this.spinnerService.saveComplete();
                    this.getProduct(this.id);
                },
                error => {
                    this.spinnerService.hide();
                    this.alertService.error(this.FAILED, this.ALERT_HEADER, { timeOut: 2000 });
                }
            )
    }

    private fillFormGroupToAddModel(): FeeAddCommandModel {
        this.enableFieldEditMode();
        let generalForm = this.feeDetailFormGroup.controls.generalInfo;
        let formValue = generalForm.value;
        let disableFormValue = (generalForm as UntypedFormGroup).getRawValue();
        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms, this.providers$);

        let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
            this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);
        let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
        let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
        let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
        let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
        let productRestrictionVehicles = this.restrictionMapperService.productRestrictionVehicleCommandModels(productRestrictionViews);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

        let productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.productAttributeComponent.forms);

        productAttributes = productAttributes.concat(this.serviceCategorySalesBucketComponent.fillFormsToModels());

        let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);

        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);
        medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, medias);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, null, this.translationNameComponent.translationViews);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let productPriceConditions = this.productConditionComponent.fillFormsToModels();

        let addCommand: FeeAddCommandModel = {
            productCategoryCode: formValue.productCategoryCode ?? disableFormValue.productCategoryCode,
            productGroupCode: formValue.productGroupCode ?? disableFormValue.productGroupCode,
            productTypeCode: formValue.productTypeGroupId ?? disableFormValue.productTypeGroupId,
            providerId: formValue.providerId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            supplierId: null,
            displayCode: null,
            draftFlag: formValue.draftFlag ?? disableFormValue.draftFlag,
            finalFlag: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            usageTypeCode: formValue.usageTypeCode ?? disableFormValue.usageTypeCode,
            isOwnerFilter: null,
            filterUserAccountId: null,
            statusCode: formValue.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: [],
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: medias,
            languageTranslationSet: languageTranslationSet,
            productPriceConditions: productPriceConditions,
            productRestrictionVehicles: productRestrictionVehicles
        };
        this.disableFieldEditMode();
        return addCommand;
    }

    private enableFieldEditMode() {
        if (this.newFromParent) {
            FEE_GENERAL_KEYS.forEach(key => {
                this.feeDetailFormGroup.controls.generalInfo.get(key).enable();
            });
        }
    }

    private disableFieldEditMode() {
        if (this.newFromParent) {
            FEE_GENERAL_KEYS.forEach(key => {
                this.feeDetailFormGroup.controls.generalInfo.get(key).disable();
            });
        }
    }

    private setformToEditMode() {
        this.newProduct = false;
        this.nodeSelected = false;

        this.changeDetectionRef.detectChanges();
    }

    public textImageUploadClick() {
        setTimeout(() => {
            this.textMedia.mediaComponent.textImageUploadProcess();
        }, this.DELAY_TIME);
    }

    public textVideoUploadClick() {
        setTimeout(() => {
            this.textMedia.mediaComponent.textVideoUploadProcess();
        }, this.DELAY_TIME);
    }

    public textImageSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.textMedia.mediaComponent.textImageSelectLibraryProcess();
            this.scrollFromTextToMediaLibrary(searchCommand);
        }, this.DELAY_TIME);
    }

    public textVideoSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.textMedia.mediaComponent.textVideoSelectLibraryProcess();
        }, this.DELAY_TIME);
    }

    private scrollFromTextToMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.textMedia.mediaComponent.addDisplayMediaLibrary(searchCommand);
    }

    private changeFocusPanelTextToMedia() {
        this.textMedia.textComponent.minimizeTextPanel();
        this.changeDetectionRef.detectChanges();
    }

    public cancelCallFromText() {
        this.textMedia.mediaComponent.cancelCallFromText();
    }

    public cancelTextUpload() {
        setTimeout(() => {
            this.textMedia.textComponent.mediaUploadCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextImageUpload(value) {
        setTimeout(() => {
            this.textMedia.textComponent.mediaImageUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextVideoUpload(value) {
        setTimeout(() => {
            this.textMedia.textComponent.mediaVideoUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public cancelTextSelectLibrary() {
        setTimeout(() => {
            this.textMedia.textComponent.mediaSelectLibraryCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextSelectImageLibrary(value) {
        setTimeout(() => {
            this.textMedia.textComponent.mediaImageSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextSelectVideoLibrary(value) {
        setTimeout(() => {
            this.textMedia.textComponent.mediaVideoSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public onSrvCategoryChange(value) {
        this.srvCategoryValid.emit(value);
    }

    public geAttributeByProductType(responses: DomainAttributeModel[]) {
        this.domainAttribute$.next(responses);
        this.populateServiceCategoryChoices(responses);
        this.populateSalesBucketChoices(responses);
    }

    private populateSalesBucketChoices(responses: DomainAttributeModel[]) {
        this.salesBucketChoice$.next(this.serviceCategorySalesBucketMapperService.getSalesBucketChoices(responses, SERVCAT_CODE, SALESBUCKET_CODE));
    }

    private populateServiceCategoryChoices(responses: DomainAttributeModel[]) {
        this.serviceCategoryChoice$.next(this.serviceCategorySalesBucketMapperService.getServiceCategoryChoices(responses, SERVCAT_CODE));
    }

    private addAction() {
        if (this.id) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.id, this.productName$.value);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            FEE_REFERENCE_CODES.productCategoryCode,
            null);
    }

    private getEmptyLanguageTranslationSet() {
        this.translationService.searchTranslationByEntityNameAndEntityPrimary(this.ENTITY_NAME, null)
            .subscribe(
                (languageTranslationSets: LanguageTranslationSetViewModel[]) => {
                    if (languageTranslationSets?.length) {
                        this.languageTranslationSet = languageTranslationSets[0];
                        this.translationNameComponent?.fillModelToForm(null, this.languageTranslationSet);
                    }
                }
            );
    }

    private fillModelToTranslationName(productName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.translationNameComponent.fillModelToForm(productName, languageTranslationSet);
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference$.next(responses);
                }
            )
    }
    private fillModelToTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }
    
    private getLanguageReference() {
        this.languageReferenceService.getByOrganisation()
            .subscribe(
                (responses: LanguageReferenceModel[]) => {
                    this.languageReference$.next(responses);
                }
            )
    }

    private getMediaUseReference() {
        this.mediaUseReferenceService.getAll()
            .subscribe(
                (responses: MediaUseReferenceModel[]) => {
                    this.mediaUseReference$.next(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }

    private fillModelToTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }

    private backToSearch() {
        this.navigationService.navigate('/main/product/home', null, null, this.navigationRequestParams);
    }

    private getVehicleGroupReferences() {
        this.vehicleGroupReferenceService.getVehicleGroupReference()
            .subscribe(
                (response) => {
                    this.vehicleGroupReferences$.next(response);
                }
            )
    }

    private getVehicleTypeReferences() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (response) => {
                    this.vehicleTypeReferences$.next(response);
                }
            )
    }

    private getVehicleCompositions() {
        this.vehicleCompositionService.getAll()
            .subscribe(
                (response) => {
                    this.vehicleCompositions$.next(response);
                }
            )
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { ProductCategoryViewModel } from "src/app/core/models/reference-model/reference-product-model/product-category-view.model";

@Injectable({
    providedIn: 'root'
})
export class ProductCategoryService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/product"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getAllActive(): Observable<ProductCategoryViewModel[]> {
        return this.httpGet<ProductCategoryViewModel[]>(this.REFERENCE_PATH + "/productcategory", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getAllProductType(): Observable<ProductTypeGroupModel[]> {
        return this.httpGet<ProductTypeGroupModel[]>(this.REFERENCE_PATH + "/ProductType", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getCategoryCode(productId: string): Observable<string> {
        return this.httpGet<{categoryCode}>(this.REFERENCE_PATH + "/productcategory/" + productId, null)
            .pipe(
                map(response => {
                    return response.body?.categoryCode;
                })
            );
    }
}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PaymentSearch } from "./payment-search.model";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";


@Injectable({
    providedIn: 'root'
})
export class PaymentInvoiceService extends ApiServiceBase {

    constructor(httpClient: HttpClient,
        authService: AuthService,
    ) {
        super(httpClient, authService);
    }

    private _paymentSearch$ = new BehaviorSubject<PaymentSearch>(null)

    get paymentSearch$() {
        return this._paymentSearch$.asObservable()
    }

    selectPaymentSearch(paymentSearch: PaymentSearch) {
        this._paymentSearch$.next(paymentSearch)
    }

    updatePaymentToPaidStatus(paymentId: string, paymentDateTime: string | null) {
        return this.httpPut(`/payment/PaymentsSearch/${paymentId}/status/PAID`, {paymentDateTime})
    }
}
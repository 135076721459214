import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { LocationReferenceModel, ProductSchedulePeriodModel, ProductScheduleVersionModel, ScheduleQueueActionReferenceModel, ScheduleQueueResultReferenceModel, VehicleCompositionModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ScheduleQueueSearchModel } from 'src/app/core/models/schedule-queue-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { LocationReferenceService, VehicleCompositionService } from 'src/app/core/services/airline-services';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { ProductSchedulePeriodService, ProductScheduleVersionService, VehicleTypeReferenceService } from 'src/app/core/services/system-services';
import { ScheduleQueueActionReferenceService } from 'src/app/core/services/system-services/schedule-queue-action-reference.service';
import { ScheduleQueueResultReferenceService } from 'src/app/core/services/system-services/schedule-queue-result-reference.service';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ScheduleQueueTableComponent } from '../schedule-queue-table/schedule-queue-table.component';

@Component({
    selector: 'op-schedule-queue-home',
    templateUrl: './schedule-queue-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleQueueHomeComponent {
    readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    readonly SSIM_CODE = 'SSIM';
    readonly OTABATCH_CODE = 'OTABATCH';
    readonly RMSBATCH_CODE = 'RMSBATCH';
    readonly SPINNER_NAME: string = "scheduleQueueSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;
    readonly PENDING_CODE = "PENDING";

    public loadingNotifier = new LoadingNotifier();

    public providerReferences: OrganisationModel[];
    public vehicleTypeReferences: VehicleTypeReferenceModel[];
    public locationReferences: LocationReferenceModel[];
    public productSchedulePeriods: ProductSchedulePeriodModel[];
    public productScheduleVersions: ProductScheduleVersionModel[];
    public vehicleCompositions: VehicleCompositionModel[];
    public scheduleQueueResultReferences: ScheduleQueueResultReferenceModel[];
    public scheduleQueueActionReferences: ScheduleQueueActionReferenceModel[];
    public executeFlag: boolean;

    public condition: ScheduleQueueSearchModel;

    public defaultCondition: ScheduleQueueSearchModel = {
        scheduleQueueId: null,
        productId: null,
        autoProcessFlag: null,
        scheduledStartDateTime: null,
        scheduleQueueActionCode: null,
        scheduleQueueResultCode: null,
        processStartDateTime: null,
        processEndDateTime: null,
        commitDateTime: null,
        scheduleQueueActionName: null,
        scheduleQueueResultName: null,
        scheduleQueueResultText: null,
        userName: null,
        productScheduleCount: null,
        productScheduledDepartureTime: null,
        productScheduledArrivalTime: null,
        productNumberId: null,
        originLocationId: null,
        destinationLocationId: null,
        productValidityId: null,
        vehicleTypeCode: null,
        vehicleCompositionId: null,
        productNumber: null,
        providerId: null,
        organisationCallName: null,
        organisationCode: null,
        providerIataCode: null,
        originLocationCode: null,
        originLocationName: null,
        destinationLocationCode: null,
        destinationLocationName: null,
        startDateTime: null,
        endDateTime: null,
        mondayFlag: null,
        tuesdayFlag: null,
        wednesdayFlag: null,
        thursdayFlag: null,
        fridayFlag: null,
        saturdayFlag: null,
        sundayFlag: null,
        compositionCode: null,
        compositionName: null,
        weekdays: null,
        processStatusCode: null,
        vehicleCompositionCode: null,
        productSchedulePeriodId: null,
        productScheduleVersionId: null,
        scheduleQueueProgressCode: this.PENDING_CODE,
        scheduleQueueTasks: [],
        rootScheduleQueueId: null,
        scheduleQueueTaskCode: null,
        scheduleQueueTaskName: null,
        numberOfProducts: null,
        numberOfOrders: null,
        numberOfIndividuals: null,
        orderNumber: null,
        parentScheduleQueueId: null,
        serviceDate: null
    }

    @Input() selectedTab: string = 'scheduleQueue';
    @Input() userSecurity: SecurityGroupSecurityModel;

    @ViewChild(ScheduleQueueTableComponent) searchTable: ScheduleQueueTableComponent;

    constructor(private navigateService: NavigationService,
        private organisationService: OrganisationService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private locationReferenceService: LocationReferenceService,
        private productSchedulePeriodService: ProductSchedulePeriodService,
        private productScheduleVersionService: ProductScheduleVersionService,
        private vehicleCompositionService: VehicleCompositionService,
        private scheduleQueueResultReferenceService: ScheduleQueueResultReferenceService,
        private scheduleQueueActionReferenceService: ScheduleQueueActionReferenceService,
        ) {
        this.condition = {} as ScheduleQueueSearchModel;
        this.condition.scheduleQueueProgressCode = this.PENDING_CODE;
        
        this.getProviderReference();
        this.getVehicleTypeReference();
        this.getLocationReference();
        this.getProductSchedulePeriod();
        this.getProductScheduleVersion();
        this.getVehicleComposition();
        this.getScheduleQueueResultReference();
        this.getScheduleQueueActionReference();
        this.extractParameters();
    }

    private getProviderReference() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.providerReferences = responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1));
                }
            )
    } 

    private getVehicleTypeReference() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (responses: VehicleTypeReferenceModel[]) => {
                    this.vehicleTypeReferences = responses;
                }
            )
    }

    private getLocationReference() {
        this.locationReferenceService.getLocationReference()
            .subscribe(
                (responses: LocationReferenceModel[]) => {
                    this.locationReferences = responses;
                }
            )
    }

    private getProductSchedulePeriod() {
        this.productSchedulePeriodService.getProductSchedulePeriods()
            .subscribe(
                (responses: ProductSchedulePeriodModel[]) => {
                    this.productSchedulePeriods = responses;
                }
            )
    }

    private getProductScheduleVersion() {
        this.productScheduleVersionService.getProductScheduleVersions()
            .subscribe(
                (responses: ProductScheduleVersionModel[]) => {
                    this.productScheduleVersions = responses;
                }
            )
    }

    private getVehicleComposition() {
        this.vehicleCompositionService.getAll()
            .subscribe(
                (responses: VehicleCompositionModel[]) => {
                    this.vehicleCompositions = responses;
                }
            )
    }

    private getScheduleQueueResultReference() {
        this.scheduleQueueResultReferenceService.getScheduleQueueResultReferences()
            .subscribe(
                (responses: ScheduleQueueResultReferenceModel[]) => {
                    this.scheduleQueueResultReferences = responses;
                }
            )
    }

    private getScheduleQueueActionReference() {
        this.scheduleQueueActionReferenceService.getScheduleQueueActionReferences()
            .subscribe(
                (responses: ScheduleQueueActionReferenceModel[]) => {
                    this.scheduleQueueActionReferences = responses.filter(x => x.scheduleQueueActionCode != this.SSIM_CODE && 
                                                                               x.scheduleQueueActionCode != this.OTABATCH_CODE &&
                                                                               x.scheduleQueueActionCode != this.RMSBATCH_CODE);
                }
            )
    }
    
    public searchClick(condition: ScheduleQueueSearchModel) {
        this.searchTable.getScheduleQueueData(condition);
    }

    public extractParameters() {
        let params = this.navigateService.getParams();
        this.executeFlag = params?.executeFlag ?? true;
    }
}
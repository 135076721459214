import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CalculateHelperService {

    public createNAV(capacity: number, obf: number): number {
        if (capacity == null) {
            return 0;
        }
        var obfValue: number = 0;
        if (obf != null && obf != 0) {
            obfValue = Math.floor((capacity * obf) / 100);
        }
        return parseInt((capacity + obfValue).toString());
    }

    public createNAVBooked(capacity: number, obf: number, booked: number): number {
        if (capacity == null) {
            return 0;
        }
        var obfValue: number = 0;
        if (obf != null && obf != 0) {
            obfValue = Math.floor((capacity * obf) / 100);
        }
        return parseInt((capacity + obfValue - booked).toString());
    }
}
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2TypeOption } from '../../../organisation-detail/shared/select2-configuration';

@Component({
  selector: 'op-organisation-type',
  templateUrl: './organisation-type.component.html'
})
export class OrganisationTypeComponent {
    private readonly OWNER_TYPE = 'OWNER';

    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() selectedOrganisationType: string[];
    @Input() isFilterPanel: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() newOrganisation: boolean = false;

    @Output() organisationTypeChange = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public model = new Array();
    public typeOption: any;
    public showTypeRow: boolean = false;
    public edited: boolean = false;
    public typeInvalid: boolean = false
    public focusing: boolean = false;

    constructor() {
        this.typeOption = select2TypeOption;
    }

    public add() {
        this.setShowTypeRow(true);
    }

    typeChange(value) {
        this.selectedOrganisationType = value;
        this.edited = true;
        this.typeInvalid = false;
        this.organisationTypeChange.emit(value);
    }  

    public delete() {
        this.setShowTypeRow(false);
        this.selectedOrganisationType = [];
        this.edited = true;
        this.organisationTypeChange.emit([]);
    }
    
    public setShowTypeRow(value: boolean) {
        this.showTypeRow = value;
    }

    get editFlag() {
        return this.isFilterPanel || (this.userSecurity?.editFlag || this.newOrganisation);
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.onSearch.emit();
                break;
            default:
                break;
        }
    }

    public isOwnerType(type: string): boolean {
        return type == this.OWNER_TYPE;
    }

}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { cloneDeep } from "lodash";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FormOption } from "./configuration-detail-form-config";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { select2Status } from "../../../shared/select2-configuration";
import { ConfigurationView } from "../shared/configuration.view";
import { VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";

@Component({
    selector: 'op-vehicle-composition-configuration-detail',
    templateUrl: './configuration-detail.component.html',
    providers: []
})
export class ConfigurationDetailComponent extends OopsComponentFormBase implements OnChanges {
    readonly ERROR_CONFIGURATION_REQUIRED = 'Configuration is required.';
    readonly ERROR_SEQUENCE_REQUIRED = 'Sequence is required.';
    readonly ERROR_STATUS_REQUIRED = 'Status is required.';

    @Input() id: string;
    @Input() configurationViews: ConfigurationView[] = new Array();
    @Input() vehicleConfigurations: VehicleConfigurationModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() model: ConfigurationView;
    @Input() noRecord: boolean = false;
    @Input() singleRecord: boolean = false;
    @Input() multipleRecord: boolean = false;
    @Input() newFromGrid: boolean = false;
    @Input() newFromDetail: boolean = false;
    @Input() editFromGrid: boolean = false;
    @Output() view = new EventEmitter<ConfigurationView>();
    @Output() new = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() formStatus = new EventEmitter<boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public focusing: boolean = false;
    public statusOption = select2Status;
    public applied: boolean = false;

    constructor(formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model']) {
            this.formSetup();
        }
    }

    initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(cloneDeep(FormOption));
        if (!this.model) {
            this.clearForm();
        }
        this.fillModelToForm(this.model);
        this.focusForm();
        this.displayInCondition();

        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private fillModelToForm(model: ConfigurationView) {
        this.formGroup.patchValue({
            no: model.no,
            vehicleCompositionConfigurationId: model.vehicleCompositionConfigurationId,
            vehicleConfigurationId: this.model.vehicleConfigurationId,
            code: model.vehicleConfigurationCode,
            name: model.vehicleConfigurationName,
            sequence: model.sequence,
            status: model.statusCode,
        });
    }

    private applyToModel() {
        let formValue = this.formGroup.getRawValue();
        let model: ConfigurationView = {
            no: formValue.no,
            vehicleCompositionConfigurationId: formValue.vehicleCompositionConfigurationId,
            vehicleConfigurationId: formValue.vehicleConfigurationId,
            vehicleConfigurationCode: this.getVehicleConfigurationCode(formValue.vehicleConfigurationId),
            vehicleConfigurationName: this.getVehicleConfigurationName(formValue.vehicleConfigurationId),
            sequence: formValue.sequence,
            statusCode: formValue.status
        }
        this.applied = true;
        this.view.emit(model);
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    private getVehicleConfigurationCode(vehicleConfigurationId: string): string {
        let filter = this.vehicleConfigurations.filter(x => x.vehicleConfigurationId == vehicleConfigurationId);
        return filter[0].vehicleConfigurationCode;
    }

    private getVehicleConfigurationName(vehicleConfigurationId: string): string {
        let filter = this.vehicleConfigurations.filter(x => x.vehicleConfigurationId == vehicleConfigurationId);
        return filter[0].vehicleConfigurationName;
    }

    private clearForm() {
        this.formGroup.reset();
        this.completeProcessing();
        this.model = {} as ConfigurationView;
        this.applied = false;
        this.fillDefaultValue();
        this.formStatus.emit(true);
    }

    private focusForm() {
        if (this.newFromGrid || this.editFromGrid) {
            this.focusing = true;
        }
    }

    private displayInCondition() {
        if (this.singleRecord && !this.newFromDetail) {
            this.applied = true;
        } else {
            this.applied = false;
        }
    }

    validateNo(e): boolean {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false
        }
        return true
    }

    onApply() {
        if (this.validateForm()) {
            this.applyToModel();
        }
        else {
            this.formStatus.emit(false);
        }
    }

    onCancel() {
        this.clearForm();
        this.cancel.emit();
    }

    onNew() {
        this.new.emit();
    }

    onDelete() {
        this.delete.emit();
    }

    getErrorMessageForm(): string {
        if (!this.formGroup.controls["vehicleConfigurationId"].valid) {
            return this.ERROR_CONFIGURATION_REQUIRED;
        } else if (!this.formGroup.controls["sequence"].valid) {
            return this.ERROR_SEQUENCE_REQUIRED;
        } else if (!this.formGroup.controls["status"].valid) {
            return this.ERROR_STATUS_REQUIRED;
        }
    }

    private fillDefaultValue() {
        this.model.statusCode = "A";
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { SpecialServiceViewModel } from 'src/app/core/models/product-model/specialservice-model';
import { StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2DefaultOption, select2DefaultWithSearchBox } from '../shared/specialservice-search-information-configuration';

@Component({
    selector: 'op-special-service-search-condition',
    templateUrl: './special-service-search-condition.component.html'
})
export class SpecialServiceSearchConditionComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorGroupRequired = ' Product group is required.';
    readonly ACTIVE_STATUS: string = "A";
    @Input() advanceSearchMode: boolean;
    @Input() productSearchFilterId: string = null;
    @Input() specialServiceView: SpecialServiceViewModel;
    @Input() productSearchFilters: Select2Data[];
    @Input() productGroupReference: ProductGroupReferenceModel[];
    @Input() productTypeGroup: ProductTypeGroupModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Input() statusReference: StatusReferenceModel[];
    @Input() saveFilter: boolean = false;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onSearch = new EventEmitter<SpecialServiceViewModel>();
    @Output() onClear = new EventEmitter<SpecialServiceViewModel>();
    @Output() productSearchFilterIdChange = new EventEmitter<string>();
    @Output() formStatus = new EventEmitter<boolean>();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public productTypeGroupFilter: ProductTypeGroupModel[];

    focusing: boolean = false;
    select2DefaultOptionWithSearchBox = select2DefaultWithSearchBox;
    select2DefaultOption = select2DefaultOption;

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get productTypeGroupCode(): string | string[] {
        return this.form.controls['productTypeGroupCode'].value;
    }

    set productTypeGroupCode(value: string | string[]) {
        this.form.controls['productTypeGroupCode'].patchValue(value);
    }

    get productGroupCode(): string | string[] {
        return this.form.controls['productGroupCode'].value;
    }

    set productGroupCode(value: string | string[]) {
        this.form.controls['productGroupCode'].patchValue(value);
    }

    get productSearchFilterIdData(): string | string[] {
        return this.productSearchFilterId;
    }

    set productSearchFilterIdData(value: string | string[]) {
        this.productSearchFilterId = <string>value;
    }

    get usageTypeCode(): string | string[] {
        return this.form.controls['usageTypeCode'].value;
    }

    set usageTypeCode(value: string | string[]) {
        this.form.controls['usageTypeCode'].patchValue(value);
    }

    get statusCode(): string | string[] {
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private actionService: ActionService) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["specialServiceView"]) {
            let view = this.specialServiceView;
            this.productSearchFilterId = view?.productId ? view?.productId : this.productSearchFilterId;
            if (this.formGroup) {
                this.fillViewToForm(view);
            }
        }

        if (changes["advanceSearchMode"]) {
            this.saveFilter = false;
        }
    }

    public initForm() {
        this.formGroup = this.formBuilder.group({
            productTypeGroupCode: [null],
            productGroupCode: [null, Validators.required],
            productName: [null],
            usageTypeCode: [null],
            statusCode: [this.ACTIVE_STATUS]
        });
        this.productSearchFilterIdData = null;
        if (this.productSearchFilterId) {
            this.onProductSearchFilterIdChange(this.productSearchFilterId);
        }
        this.formGroup.statusChanges
        .subscribe(val => this.onFormStatusChange());
    }

    public fillViewToForm(view: SpecialServiceViewModel) {
        this.formGroup.patchValue({
            productTypeGroupCode: view.productTypeCode,
            productName: view.searchName,
            productGroupCode: view.productGroupCode,
            usageTypeCode: view.searchUsageTypeCode,
            statusCode: view.searchStatusCode
        });
        this.filterProductTypeGroup(view.productGroupCode);
    }

    onAdvanceSearchClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    doSearch() {
        this.onSearch.emit(this.getValues());
    }

    clearCondition() {
        this.saveFilter = false;
        this.initForm();
        this.productSearchFilterId = null;
        this.onClear.emit(this.getValues());
    }

    onProductSearchFilterIdChange(productId: any) {
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/SERVICE', productId, this.getSearchFilterName(productId));
        this.productSearchFilterIdChange.emit(productId);
        if (!productId) {
            this.initForm();
        }
    }

    public getValues(saveFilter: boolean = false): SpecialServiceViewModel {
        this.saveFilter = saveFilter;
        this.changeDetectionRef.detectChanges();
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): SpecialServiceViewModel {
        let model = new SpecialServiceViewModel();
        model.searchName = this.getFormControlValue("productName");
        model.productGroupCode = this.getFormControlValue("productGroupCode");
        model.productTypeCode = this.getFormControlValue("productTypeGroupCode");
        model.searchUsageTypeCode = this.getFormControlValue("usageTypeCode");
        model.searchStatusCode = this.getFormControlValue("statusCode");
        model.statusCode = this.ACTIVE_STATUS;
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public productGroupChange(value) {
        this.filterProductTypeGroup(value);
    }

    public filterProductTypeGroup(productGroupCode) {
        this.productTypeGroupFilter = [];
        if (productGroupCode) {
            var filter = this.productTypeGroup.filter(x => x.productGroupCode == productGroupCode);
            this.productTypeGroupFilter = filter;
        }
        
    }

    getSearchFilterName(productId: string) {
        return this.productSearchFilters?.find(item => item.id == productId)?.text
    }

    public validateForm(): boolean {
        this.startProcessing();
        this.changeDetectionRef.detectChanges();
        return this.validForm();
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.errorGroupRequired;
        }
    }

    public productGroupEmpty(): boolean {
        if (this.productGroupCode) {
            return false;
        }
        return true;
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }


}

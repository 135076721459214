import { Injectable } from '@angular/core';
import { AttributeTypeReference } from 'src/app/core/models/reference-model/reference-general-model/attribute-type-reference.model';
import { AttributeDetailView } from './view/attribute-detail-view.view';
import { DomainAttributeView } from './view/domain-attribute.view';

@Injectable()
export class MapperService {
  public attributeTypeToAttributeDetailView(model: AttributeTypeReference): AttributeDetailView {
    var view: AttributeDetailView = {
        attributeCode: model.attributeCode,
        attributeName: model.attributeName,
        dimensionUnitCode: model.dimensionUnitCode,
        minimumValue: model.minimumValue,
        maximumValue: model.maximumValue,
        defaultValue: model.defaultValue,
        showOnNew: model.showOnNew,
        multipleChoice: model.multipleChoice,
        requiredAttribute: model.requiredAttribute,
        dimensionAttribute: model.dimensionAttribute,
        searchFlag: model.searchFlag,
        statusCode: model.statusCode,
        commitBy: model.commitBy,
        commitByName: model.commitByName,
        commitDateTime: model.commitDateTime,
        attributeGroupCode: null,
        override: false,
        startAttribute: false,
        endAttribute: false,
        productAttribute: false,
        scheduleAttribute: false,
        operationAttribute: false,
        domainCode: '',
        domainTypeCode: '',
        sortSequence: null,
        readOnlyFlag: false
    }
    
    return view;
  }

  public attributeDetailViewToAttributeTypeReference(view: AttributeDetailView): AttributeTypeReference {
    var model: AttributeTypeReference = {
        attributeCode: view.attributeCode,
        attributeName: view.attributeName,
        dimensionUnitCode: view.dimensionUnitCode,
        showOnNew: view.showOnNew,
        multipleChoice: view.multipleChoice,
        requiredAttribute: view.requiredAttribute,
        dimensionAttribute: view.dimensionAttribute,
        searchFlag: view.searchFlag,
        minimumValue: view.minimumValue ?? null,
        maximumValue: view.maximumValue ?? null,
        defaultValue: view.defaultValue ?? null,
        statusCode: view.statusCode ?? "A",
        commitBy: view.commitBy,
        commitByName: view.commitByName,
        commitDateTime: view.commitDateTime
    }
    
    return model;
  }

  public domainAttributeViewListToAttributeDetailViewList(domainAttrViews: DomainAttributeView[]): AttributeDetailView[] {
    var views = new Array<AttributeDetailView>();
    for (let model of domainAttrViews) {
      views.push(this.domainAttributeViewToAttributeDetailView(model));
    }
    return views;
  }

  public domainAttributeViewToAttributeDetailView(domainAttrView: DomainAttributeView): AttributeDetailView {
    var view: AttributeDetailView = {
        domainCode: domainAttrView.domainCode,
        domainTypeCode: domainAttrView.domainTypeCode,
        attributeCode: domainAttrView.attributeTypeCode,
        attributeGroupCode: domainAttrView.attributeGroupCode,
        minimumValue: domainAttrView.minimumValue,
        maximumValue: domainAttrView.maximumValue,
        defaultValue: domainAttrView.defaultValue,
        override: domainAttrView.overrideFlag ?? false,
        showOnNew: domainAttrView.showOnNewFlag ?? false,   
        requiredAttribute: domainAttrView.requiredFlag ?? false,
        dimensionAttribute: domainAttrView.dimensionFlag ?? false,
        multipleChoice: domainAttrView.multipleChoiceFlag ?? false,
        searchFlag: domainAttrView.searchFlag ?? false,
        startAttribute: domainAttrView.startFlag ?? false,
        endAttribute: domainAttrView.endFlag ?? false,
        productAttribute: domainAttrView.productFlag ?? false,
        scheduleAttribute: domainAttrView.scheduleFlag ?? false,
        operationAttribute: domainAttrView.operationFlag ?? false,
        commitBy: domainAttrView.commitBy,
        commitByName: domainAttrView.commitByName,
        commitDateTime: domainAttrView.commitDateTime,
        attributeName: '',
        dimensionUnitCode: null,
        sortSequence: null,
        statusCode: null,
        readOnlyFlag: domainAttrView.readOnlyFlag
    }
    
    return view;
  }

  public AttributeDetailViewListToDomainAttributeList(detailViews: AttributeDetailView[]): DomainAttributeView[] {
    var views = new Array<DomainAttributeView>();
    for (let detailView of detailViews) {
      views.push(this.AttributeDetailViewToDomainAttribute(detailView));
    }
    return views;
  }
  
  public AttributeDetailViewToDomainAttribute(detailView: AttributeDetailView): DomainAttributeView {
    var view: DomainAttributeView = {
        domainCode: detailView.domainCode,
        domainTypeCode: detailView.domainTypeCode,
        attributeTypeCode: detailView.attributeCode,
        attributeGroupCode: detailView.attributeGroupCode,
        minimumValue: detailView.minimumValue ?? null,
        maximumValue: detailView.maximumValue ?? null,
        defaultValue: detailView.defaultValue ?? null,
        overrideFlag: detailView.override,
        showOnNewFlag: detailView.showOnNew,   
        requiredFlag: detailView.requiredAttribute,
        dimensionFlag: detailView.dimensionAttribute,
        multipleChoiceFlag: detailView.multipleChoice,
        searchFlag: detailView.searchFlag,
        startFlag: detailView.startAttribute,
        endFlag: detailView.endAttribute,
        productFlag: detailView.productAttribute,
        scheduleFlag: detailView.scheduleAttribute,
        operationFlag: detailView.operationAttribute,
        commitBy: detailView.commitBy,
        commitByName: detailView.commitByName,
        commitDateTime: detailView.commitDateTime,
        domainAttributeId: null,
        sortSequence: null,
        readOnlyFlag: detailView.readOnlyFlag
    }
    
    return view;
  }
}

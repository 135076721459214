import { Component, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { LanguageReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { IndividualTitleModel } from "src/app/core/models/user-model/individual-title.model";
import { NameView } from "../shared/name-view";
import { select2Language, select2Status, select2Title } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-name-detail',
    templateUrl: './name-detail.component.html'   
})
export class InsightNameDetailComponent {
    private readonly ACTIVE_STATUS = 'A';
    readonly ERROR_REQUIRED = ' is required.';

    public titleOption: any;
    public statusOption: any;
    public languageOption: any;
    public processing: boolean = false;

    @Input() insightName = this.getDefaultNewName();
    @Input() statusReferences: StatusReferenceModel[];  
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() individualTitleReferences: IndividualTitleModel[]; 

    @ViewChild('nameDetailForm') nameDetailForm: NgForm;

    constructor() {
        this.setOptionControl();
    }

    private setOptionControl() {
        this.titleOption = select2Title;
        this.statusOption = select2Status;
        this.languageOption = select2Language;
    }

    getInsightName() : NameView {
        this.processing = true;
        if (!this.nameDetailForm.valid) {
            return null;
        }
        this.processing = false;
        return this.insightName
    }

    public isValidForm() : boolean {
        this.processing = true;

        if(!this.nameDetailForm.valid && this.nameDetailForm.disabled != true) {
            return false;
        }

        this.processing = false; 
        return true;
    }

    getDefaultNewName(): NameView {
        let view = new NameView();
        view.statusCode = this.ACTIVE_STATUS;
        return view;
    }

    getErrorMessageForm(): string {
        if (!this.nameDetailForm.controls['lastName'].value) {
            return 'Last Name' + this.ERROR_REQUIRED;
        } else if (!this.nameDetailForm.controls['firstName'].value) {
            return 'First Name' + this.ERROR_REQUIRED;
        } else if (!this.nameDetailForm.controls['languageCode'].value) {
            return 'Language' + this.ERROR_REQUIRED;
        } else if (!this.nameDetailForm.controls['statusCode'].value) {
            return 'Status' + this.ERROR_REQUIRED;
        }
    }

    public onValueChange(value: any, fieldName: string) {
        this.insightName[fieldName] = value;
    }
}
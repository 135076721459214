import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CashbookSearchCommandModel, CashbookSearchTableModel } from 'src/app/core/models/cashbook-model';
import { OrganisationAttributeModel, OrganisationModel } from 'src/app/core/models/organisation-model';
import { CashbookService } from 'src/app/core/services/cashbook-services/index';
import { UserAccountOrganisationService } from "src/app/core/services/user-account-services/user-account-organisation.service";
import { CashbookSearchConditionComponent } from './cashbook-search-condition/cashbook-search-condition.component';
import { CashbookSearchTableComponent } from './cashbook-search-table/cashbook-search-table.component';
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { AttributeMaintenanceService, CurrencyReferenceService, OrderPaymentStatusReferenceService, PaymentStatusReferenceService, PaymentTypeReferenceService } from 'src/app/core/services/system-services';
import { CurrencyReferenceModel, OrderPaymentStatusReferenceModel, PaymentStatusReferenceModel, PaymentTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { ActionService } from 'src/app/core/utils/action.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { OrganisationAttributeService } from 'src/app/core/services/organisation-services/organistion-attribute.service';
import { AttributeChoiceView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/attribute-choice.view';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'op-cashbook-search',
  templateUrl: './cashbook-search.component.html',
})
export class CashbookSearchComponent implements OnInit, OnChanges {
  readonly SPINNER_NAME: string = "cashbookSearchSpinner";
  private readonly CASHBOOK_ALL_CODE = 'CASHBOOKALL';
  private readonly DATETIME_FORMAT_ATTRIBUTE = 'DATETIME';
  public organisations$ = new BehaviorSubject<OrganisationModel[]>(null);
  public paymentTypeCode$ = new BehaviorSubject<PaymentTypeReferenceModel[]>(null);
  public paymentCurrency$ = new BehaviorSubject<CurrencyReferenceModel[]>(null);
  public paymentStatus$ = new BehaviorSubject<PaymentStatusReferenceModel[]>(null);
  public searchResults$ = new BehaviorSubject<CashbookSearchTableModel[]>(null);

  @ViewChild(CashbookSearchConditionComponent) searchCondition: CashbookSearchConditionComponent;
  @ViewChild(CashbookSearchTableComponent) searchTable: CashbookSearchTableComponent;
  @Output() onSelectItem = new EventEmitter();
  @Output() getValueCondition = new EventEmitter();
  @Output() setRefreshValue = new EventEmitter();
  @Input() refreshValue: boolean;
  @Input() userSecurity: SecurityGroupSecurityModel;

  public organisationId: string;
  public paggingView: PagingDataView[];
  public loadingNotifier = new LoadingNotifier();
  public organisationDateTimeFormat$ = new BehaviorSubject<string>(null);
  public orderPaymentStatusReferences$ = new BehaviorSubject<OrderPaymentStatusReferenceModel[]>(null);

  constructor(
    public userAccountOrganisationService: UserAccountOrganisationService,
    public cashbookService: CashbookService,
    public currencyReferenceService: CurrencyReferenceService,
    public paymentTypeReferenceService: PaymentTypeReferenceService,
    public paymentStatusReferenceService: PaymentStatusReferenceService,
    private focusingService: FocusingService,
    private organisationService: OrganisationService,
    private actionService: ActionService,
    private navigateService: NavigationService,
    private organisationAttributeService: OrganisationAttributeService,
    private attributeMaintenanceService: AttributeMaintenanceService,
    private orderPaymentStatusReferenceService: OrderPaymentStatusReferenceService,
    private authService: AuthService
  ) {
    this.organisationId = this.authService.getSelectedOrganisation();
    this.getOrganisationDatetimeFormat();
    this.getOrderPaymentStatusReferences();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshValue']) {
      if (this.refreshValue == true) {
        this.reSearch();
      }
    }
  }

  ngOnInit(): void {
    this.getOrganisation();
    this.getPaymentTypeCode();
    this.getPaymentCurrency();
    this.getPaymentStatus();

    this.actionService.add('SEARCH', null, null)
    
    const params = this.navigateService.getParams()
    if (params?.executeFlag) {
      this.onSearch(<CashbookSearchCommandModel>{});
    }
  }

  private getOrganisation() {
    if (this.userSecurity?.securityCode == this.CASHBOOK_ALL_CODE) {
        this.getOrganisationAll();
        return;
    }
    this.getUserOrganisation();
  }

  private getUserOrganisation() {
    this.userAccountOrganisationService.getOrganisationUser()
      .subscribe(
        (responses: any) => {
          this.organisations$.next(responses);
        }
      )
  }

  private getOrganisationAll() {
    const ORG_ALL_CODE = 'ORGANISATIONALL'
    let condition = new OrganisationModel();
    condition.searchStatusCode = 'A';
    condition.searchUsageTypeCode = 'DATA';
    this.organisationService.advanceSearchOrganisation(condition, ORG_ALL_CODE)
      .subscribe(
        (response: any) => {
          this.organisations$.next(response);
        })
  }

  private getPaymentTypeCode() {
    this.paymentTypeReferenceService.getPaymentTypeReference()
      .subscribe(
        (response: PaymentTypeReferenceModel[]) => {
          this.paymentTypeCode$.next(response);
        }
      )
  }

  private getPaymentCurrency() {
    this.currencyReferenceService.getCurrencyReferences()
      .subscribe(
        (responses: CurrencyReferenceModel[]) => {
          this.paymentCurrency$.next(responses);
        }
      )
  }

  private getPaymentStatus() {
    this.paymentStatusReferenceService.getPaymentStatusReference()
      .subscribe(
        (response: PaymentStatusReferenceModel[]) => {
          this.paymentStatus$.next(response);
        }
      )
  }

  public onSearch(command: CashbookSearchCommandModel) {
    this.loadingNotifier.show(this.SPINNER_NAME);
    this.cashbookService.searchCashbook(command)
      .subscribe(
        (response: CashbookSearchTableModel[]) => {
          this.searchResults$.next(response);
          this.loadingNotifier.hide(this.SPINNER_NAME);
          this.setRefreshValue.emit(false);
        }
      ), () => {
        this.loadingNotifier.hide(this.SPINNER_NAME);
        this.setRefreshValue.emit(false);
      }
  }

  reSearch() {
    this.searchCondition.doSearch();
  }

  onClear() {
    this.searchCondition.initForm();
  }

  focusOnSearchResult(panel: string) {
    if (panel == 'searchPanel') {
      this.focusingService.focus(this.searchTable.focusingDirective)
    }
  }

  selectItem(e: any) {
    this.onSelectItem.emit(e);
  }

  getConditionValue(e: any) {
    this.getValueCondition.emit(e);
    if (!this.searchTable) {
        return;
    }
    this.searchTable.searchCondition = e;
    this.searchTable.organisationId = e?.organisationId;
    this.searchTable.organisationCallName = e?.organisationCallName;
  }

  getPaggingView(paggingView) {
    this.paggingView = paggingView;
  }
  
    public getOrganisationDatetimeFormat() {
        forkJoin({
            organisationAttributes: this.organisationAttributeService.getOrganisationAttribute(),
            dateTimeFormatReferernce: this.attributeMaintenanceService.getAttributeChoiceByAttributeTypeCode(this.DATETIME_FORMAT_ATTRIBUTE)
        })
        .subscribe((responses) => {
            let organisationDateTimeFormat = responses?.organisationAttributes?.find(item => item.organisationAttributeTypeCode == this.DATETIME_FORMAT_ATTRIBUTE);
            if (organisationDateTimeFormat && responses?.dateTimeFormatReferernce) {
                this.getOrganisationDatetimeFormatFromAttribute(organisationDateTimeFormat, responses?.dateTimeFormatReferernce);
            }
        });
    }

    private getOrganisationDatetimeFormatFromAttribute(dateTimeAttribute: OrganisationAttributeModel, dateTimeChoices: AttributeChoiceView[]) {
        this.organisationDateTimeFormat$.next(dateTimeChoices?.find(item => item.attributeChoiceId == dateTimeAttribute.organisationAttributeChoiceId)?.attributeChoiceName);
    }

    private getOrderPaymentStatusReferences() {
        this.orderPaymentStatusReferenceService.getOrderPaymentStatusReference()
            .subscribe(
                (response: OrderPaymentStatusReferenceModel[]) => {
                    this.orderPaymentStatusReferences$.next(response);
                }
            )
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { EmailCommandModel } from '../../components/email/shared';
import { CashbookEmailCommand } from '../../models/cashbook-model';

@Injectable({
    providedIn: 'root'
})
export class EmailService extends ApiServiceBase {
    private readonly PATH = '/system/Email';
    private readonly PATHCASHBOOK: string = "/order"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public sendEmail(emailCommand: EmailCommandModel): Observable<string[]> {
        return this.httpPost<string[]>(this.PATH, emailCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }

    public sendEmailCashbook(emailCommand: CashbookEmailCommand): Observable<string[]> {
        return this.httpPost<string[]>(this.PATHCASHBOOK + "/cashbooks/email", emailCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }
}
import { PricingDetailConstant } from '../../../../shared/pricing-detail.constant';

export class PriceRuleConditionBase {
    public excludeFlag: boolean = false;
    public get condition() {
        if (this.excludeFlag == true) {
            return PricingDetailConstant.IS_NOT_OPERATOR_CODE;
        }
        return PricingDetailConstant.IS_OPERATOR_CODE;
    }
    public set condition(value) {
        if (!value) {
            return;
        }
        if (value == PricingDetailConstant.IS_NOT_OPERATOR_CODE) {
            this.excludeFlag = true;
        } else {
            this.excludeFlag = false;
        }
    }
}
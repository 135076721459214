import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import {
    PriceRuleCommand,
    PriceRuleCurrencyModel,
    PriceRuleModel,
    PriceRuleTreeModel,
    PricingCategoryModel
} from '../../models/pricing-model';
import { ProductNumberTypeReferenceModel } from '../../models/reference-model/reference-product-model';
import {
    InventoryDimensionReferenceModel,
    LocationGroupModel,
    SalesBucketReferenceModel,
    ServiceCategoryReferenceModel,
    VehicleCompositionModel
} from '../../models/reference-model/reference-general-model';
import { AuthService } from '../../authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class PricingService extends ApiServiceBase {
    private readonly PRICING_PATH = "/pricing"
    constructor(
        httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService)
    }

    public addPriceRule(command: PriceRuleCommand): Observable<PriceRuleModel> {
        return this.httpPost<PriceRuleModel>(this.PRICING_PATH + '/PriceRule', command)
            .pipe(
                map(res => res.body)
            )
    }

    public updatePriceRule(command: PriceRuleCommand): Observable<PriceRuleModel> {
        return this.httpPut<PriceRuleModel>(this.PRICING_PATH + '/PriceRule', command)
            .pipe(
                map(res => res.body)
            )
    }

    public searchPriceRules(model: PriceRuleModel): Observable<PriceRuleModel[]> {
        return this.httpPost<PriceRuleModel[]>(this.PRICING_PATH + '/PriceRule/Search', model)
            .pipe(
                map(res => res.body)
            )
    }

    public getPriceRuleSearchFilters(): Observable<PriceRuleModel[]> {
        return this.httpGet<PriceRuleModel[]>(this.PRICING_PATH + '/PriceRule/filters', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPriceRule(id: string): Observable<PriceRuleModel> {
        return this.httpGet<PriceRuleModel>(this.PRICING_PATH + `/PriceRule/${id}`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getProductNumberTypeReference(...productNumberTypeCodes: string[]): Observable<ProductNumberTypeReferenceModel[]> {
        const productNumberTypeCodeParamName = 'productNumberTypeCode';

        let params = this.stringHelperService.createQueryParamFromArray(productNumberTypeCodeParamName, productNumberTypeCodes);
        return this.httpGet<ProductNumberTypeReferenceModel[]>(this.PRICING_PATH + '/ProductNumberType' + params, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationGroup(ids: string[]): Observable<LocationGroupModel[]> {
        const locationGroupIdParamName = 'locationGroupId';
        let params = this.stringHelperService.createQueryParamFromArray(locationGroupIdParamName, ids);
        return this.httpGet<LocationGroupModel[]>(this.PRICING_PATH + '/LocationGroup', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getVehicleComposition(vehicleTypeCode: string): Observable<VehicleCompositionModel[]> {
        return this.httpGet<VehicleCompositionModel[]>(this.PRICING_PATH + '/VehicleComposition/' + vehicleTypeCode, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getServiceCategoryReference(): Observable<ServiceCategoryReferenceModel[]> {
        return this.httpGet<ServiceCategoryReferenceModel[]>(this.PRICING_PATH + '/ServiceCategoryReference', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getSalesBucketReference(): Observable<SalesBucketReferenceModel[]> {
        return this.httpGet<SalesBucketReferenceModel[]>(this.PRICING_PATH + '/SalesBucketReference', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getInventoryDimensionReference(): Observable<InventoryDimensionReferenceModel[]> {
        return this.httpGet<InventoryDimensionReferenceModel[]>(this.PRICING_PATH + '/InventoryDimensionReference', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPriceRuleTree(priceRuleId: string): Observable<PriceRuleTreeModel> {
        return this.httpGet<PriceRuleTreeModel>(this.PRICING_PATH + '/priceRule/' + priceRuleId + '/tree', null)
            .pipe(
                map(res => res.body)
            )
    }

    public updatePriceRuleStatus(priceRuleId: string, statusCode: string) {
        return this.httpPut(this.PRICING_PATH + `/PriceRule/${priceRuleId}/status/${statusCode}`, null);
    }

    public getPricingCategories(): Observable<PricingCategoryModel[]> {
        return this.httpGet<PricingCategoryModel[]>(this.PRICING_PATH + '/PriceCategories/', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPriceRuleCurrencies(): Observable<PriceRuleCurrencyModel[]> {
        return this.httpGet<PriceRuleCurrencyModel[]>(this.PRICING_PATH + '/PriceRule/currencies/', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getAllPriceRulesInHierarchy(priceRuleId: string): Observable<PriceRuleModel> {
        return this.httpGet<PriceRuleModel>(this.PRICING_PATH + '/PriceRule/' + priceRuleId + '/hierarchy', null)
            .pipe(
                map(res => res.body)
            )
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { InsightManagementContentModule } from "./insight-management-content/insight-management-content.module";
import { InsightManagementRoutingModule } from "./insight-management-routing.module";
import { InsightManagementComponent } from "./insight-management.component";

@NgModule({
    declarations: [
        InsightManagementComponent,
    ],
    imports: [
        CommonModule,
        DynamicRoutingModule,
        InsightManagementContentModule,
        InsightManagementRoutingModule,
        UiModule
    ],
    providers: [

    ]
})
export class InsightManagementModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ExchangeRateAmountComponent } from './exchange-rate-amount.component';
import { ExchangeRateAmountContentModule } from './exchange-rate-amount-content/exchange-rate-amount-content.module';

@NgModule({
    declarations: [
        ExchangeRateAmountComponent
    ],
    imports: [
        ExchangeRateAmountContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [ExchangeRateAmountComponent]
})

export class ExchangeRateAmountModule { }

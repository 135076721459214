import { AfterViewInit, Component } from "@angular/core";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    templateUrl: './pricing.component.html'
})

export class PricingComponent implements AfterViewInit {
    constructor(private navigationService: NavigationService) {

    }

    ngAfterViewInit(): void {
        this.navigationService.navigate('/main/pricing/home', null, false, {})
    }

    navigateSuccess() {

    }
}
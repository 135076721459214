import { Injectable } from "@angular/core";
import { InsightPassengerCommunicationPhoneModel } from "src/app/core/models/individual-model";
import { CommunicationPhoneView } from "./communication-phone-view";

@Injectable()
export class CommunicationPhoneMapperService {
    public communicationPhoneModelToViews(models: InsightPassengerCommunicationPhoneModel[]) : CommunicationPhoneView[] {
        let views = new Array();
        if (models?.length) {
            for (let i = 0; i < models.length; i++) {
                views.push(this.communicationPhoneModelToView(i + 1, models[i]));
            } 
        }
        return views;
    }

    private communicationPhoneModelToView(index: number, model: InsightPassengerCommunicationPhoneModel) : CommunicationPhoneView {
        let view = new CommunicationPhoneView();
        view.no = index;
        view.communicationPhoneId = model.communicationPhoneId;
        view.organisationId = model.organisationId;
        view.individualId = model.individualId;
        view.communicationPhoneTypeCode = model.communicationPhoneTypeCode;
        view.communicationPhoneTypeName = model.communicationPhoneTypeName;
        view.countryCode = model.countryCode;
        view.languageCode = model.languageCode;
        view.phoneNumber = model.phoneNumber;
        view.extension = model.extension;
        view.commentText = model.commentText;
        view.primaryPhone = model.primaryFlag;
        view.communicationPhonePrefix = model.communicationPhonePrefix;
        view.phoneNumberReverse = model.phoneNumberReverse;
        this.setPhoneLockFlag(view);
        return view;
    }

    private setPhoneLockFlag(view: CommunicationPhoneView){
        if(view.organisationId){
            view.phoneLocked = true;
        }
        else {
            view.phoneLocked = false;
        }
    }

    public communicationPhoneViewToModels(views: CommunicationPhoneView[]): InsightPassengerCommunicationPhoneModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.communicationPhoneViewToModel(view));
        }
        return models;
    }

    private communicationPhoneViewToModel(view: CommunicationPhoneView): InsightPassengerCommunicationPhoneModel {
        let model: InsightPassengerCommunicationPhoneModel = {
            communicationPhoneId: view.communicationPhoneId,
            organisationId: view.organisationId,
            individualId: view.individualId,
            communicationPhoneTypeCode: view.communicationPhoneTypeCode,
            communicationPhoneTypeName: view.communicationPhoneTypeName,
            countryCode: view.countryCode,
            languageCode: view.languageCode,
            phoneType: view.phoneType,
            phoneNumber: view.phoneNumber,
            extension: view.extension,
            commentText: view.commentText?.trim(),
            primaryFlag: view.primaryPhone,
            communicationPhonePrefix: view.communicationPhonePrefix,
            phoneNumberReverse: view.phoneNumberReverse
        };
        
        return model;
    }
}
export const FavoriteConstant = {
    SEARCH_ACTION: 'SEARCH',
    NEW_ACTION: 'NEW',
    DETAIL_ACTION: 'ID',
    DASHBOARD_ACTION: 'DASHBOARD',
    STATUS_ACTION: 'STATUS',
    MYACTIVITY_ACTION: 'MYACTIVITY',
}

export const FavoriteNameConstant = {
    SEARCH_NAME: 'Search',
    NEW_NAME: 'New',
    STATUS_NAME: 'Status',
    DASHBOARD_NAME: 'Dashboard',
    MYACTIVITY_NAME: 'My Activities',
}

export const GroupName = {
    SECURITY: {
        GROUP: 'Group',
        CODE: 'Code',
        AUDIT: 'Audits',
        REPORT: 'Report'
    },
    VEHICLE: {
        COMPOSITION: 'Composition',
        CONFIGURATION: 'Configuration',
        SEATMAP: 'Seat Map'
    },
    SCHEDULE: {
        SCHEDULEQUEUE: 'Queue',
        SSIM: 'Information',
        DISTRIBUTION: 'Distribution',
    },
    PRODUCT: {
        ACCOMMODATION: 'Accommodation',
        AMENITIES: 'Amenities',
        ANCILLARY: 'Ancillary Services',
        BUNDLE: 'Bundles',
        EVENT: 'Events',
        FEE: 'Fee',
        INSURANCE: 'Insurance',
        MERCHANDISE: 'Merchandizing',
        RENTALCAR: 'Rental Car',
        SERVICE: 'Services',
        TRANSPORT: 'Transport',
        TRAVEL: 'Travels',
        VOUCHER: 'Vouchers'
    },
    PRICE: {
        PRICEDIMENSION: 'Dimension',
        PRICECONDITION: 'Condition',
    },
    INVENTORY: {
        REVENUEEXPORT: 'Revenue Management Export',
    },
}

export const ProductCategoryUrl = [
    'merchandises',
    'services',
    'transport',
    'ancillary',
    'fee',
    'voucher'
]

export const TransportCategoryUrl = [
    'pax-reaccom',
    'operating-transport'
]

export const PricingCategoryUrl = {
    PRICE: 'price',
    PRICERULE: 'price-rule',
    PRICEDIMENSION: 'dimension',
    PRICECONDITION: 'condition'
}

export const PageTitle = [
    {
        domain: 'SETTING',
        title: 'Setting Management',
    },
    {
        domain: 'ATTRIBUTE',
        title: 'Attribute Management',
    },
    {
        domain: 'VEHICLE',
        title: 'Vehicle Management',
        groups: [
            {
                domain: 'CONFIGURATION',
                title: 'Vehicle Configuration',
            },
            {
                domain: 'COMPOSITION',
                title: 'Vehicle Composition'
            }
        ]
    },
    {
        domain: 'SECURITY',
        title: 'Security Management',
        groups: [
            {
                domain: 'CODE',
                title: 'Security Code'
            },
            {
                domain: 'GROUP',
                title: 'Security Group'
            },
            {
                domain: 'AUDIT',
                title: 'Security Audits'
            },
            {
                domain: 'REPORT',
                title: 'Security Reports'
            },
        ]
    },
    {
        domain: 'INSIGHT',
        title: 'Insight Management'
    },
    {
        domain: 'ORGANISATION',
        title: 'Organisation Management'
    },
    {
        domain: 'USER',
        title: 'User Management'
    },
    {
        domain: 'SCHEDULE',
        title: 'Schedule Management',
        groups: [
            {
                domain: 'SCHEDULEQUEUE',
                title: 'Schedule Queue'
            },
            {
                domain: 'SSIM',
                title: 'Standard Schedule Information'
            },
            {
                domain: 'DISTRIBUTION',
                title: 'Schedule Distribution'
            }
        ]
    },
    {
        domain: 'SHOPPING',
        title: 'Shopping'
    },
    {
        domain: 'ORDER',
        title: 'Order Management'
    },
    {
        domain: 'FAVORITE',
        title: 'My Favorites',
    },
    {
        domain: 'HOME',
        title: 'Home'
    },
    {
        domain: 'NEWS',
        title: 'News'
    },
    {
        domain: 'TRANSPORT',
        title: 'Operating Transport Management'
    },
    {
        domain: 'PRODUCT',
        title: 'Product Management',
        groups: [
            {
                domain: 'MERCHANDISE',
                title: 'Merchandizing Management'
            },
            {
                domain: 'SERVICE',
                title: 'Services Management'
            },
            {
                domain: 'ANCILLARY',
                title: 'Ancillary Services Management'
            },
            {
                domain: 'TRANSPORT',
                title: 'Transport Management'
            },
            {
                domain: 'FEE',
                title: 'Fees Management'
            },
            {
                domain: 'VOUCHER',
                title: 'Voucher Management'
            }
        ]
    },
    {
        domain: 'PRICE',
        title: 'Price Management',
        groups: [
            {
                domain: 'PRICEDIMENSION',
                title: 'Price Dimensions Management'
            },
            {
                domain: 'PRICECONDITION',
                title: 'Price Conditions Management'
            }
        ]
    },
    {
        domain: 'PRICERULE',
        title: 'Price Rule Management'
    },
    {
        domain: 'PRICING',
        title: 'Pricing Management'
    },
    {
        domain: 'ACTIVITY',
        title: 'Activity Management'
    },
    {
        domain: 'INVENTORY',
        title: 'Inventory Management',
        groups: [
            {
                domain: 'REVENUEEXPORT',
                title: 'Revenue Management Export'
            },
        ]
    },
    {
        domain: 'FINANCE',
        title: 'Finance'
    },
    {
        domain: 'CASHBOOK',
        title: 'Cashbook'
    },
    {
        domain: 'COUNTERSALES',
        title: 'Counter Sales'
    },
    {
        domain: 'PAYMENT',
        title: 'Payment'
    },
    {
        domain: 'CREDITACCOUNT',
        title: 'Credit Account'
    },
]

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { SecurityCodeService } from 'src/app/core/services/system-services/security-code.service';
import { ActionService } from 'src/app/core/utils/action.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel, DeleteButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityCodeDetailFormComponent } from './security-code-detail-form/security-code-detail-form.component';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-code-detail',
    templateUrl: './security-code-detail.component.html',
    providers: [ActionbarService],
})
export class SecurityCodeDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly FUNCTION_ID = 'FUNCTION';
    public securityCode: string;
    public securityRefDetail$ = new BehaviorSubject<SecurityCodeReferenceModel>(null);
    public newSecurityCode: boolean = false;
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public copySecurityCode: boolean = false;
    public selectedTab: string;
    public paggingView: PagingDataView[];
    public currentIndex: number;
    public fromEdit: boolean;
    
    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new DeleteButtonModel(),
        new RefreshButtonModel()
    );

    private securities: SecurityGroupSecurityModel[];
    public moduleSecurity: SecurityGroupSecurityModel;

    private unsubscribe$ = new Subject();

    @ViewChild(SecurityCodeDetailFormComponent) securityCodeDetailFormComponent: SecurityCodeDetailFormComponent;

    constructor(
        private securityCodeService: SecurityCodeService,
        private statusReferenceService: StatusReferenceService,
        private navigateService: NavigationService,
        private actionbarService: ActionbarService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.getStatusReferences();
        let param = this.navigateService.getParams();
        this.securityCode = param?.id;
        this.newSecurityCode = param?.newFlag ?? false;
        this.copySecurityCode = param?.copy ?? false;
        this.selectedTab = param?.selectedTab;
        this.paggingView = param?.paggingView ?? [];
        this.currentIndex = param?.currentIndex;
        this.fromEdit = param?.fromEdit ?? false;
        this.securities = param?.securities;
        this.moduleSecurity = param?.moduleSecurity;

        if (this.securityCode) {
            this.getSecurityCode(this.securityCode);
        } else {
            this.new();
        }
        this.addAction();
    }

    ngAfterViewInit(): void {
        this.setupSecurityActionbar();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.actionbarClick(actionId);
        });
    }

    private saveDisable(): boolean {
        if (this.copySecurityCode) {
            return this.moduleSecurity?.copyFlag;
        }
        if (this.newSecurityCode) {
            return this.moduleSecurity?.newFlag;
        }
        return this.moduleSecurity?.editFlag;
    }

    setupSecurityActionbar() {
        this.actionBarHandler.get(ACTION_STATUS.new).disable(!this.moduleSecurity?.newFlag);
        this.actionBarHandler.get(ACTION_STATUS.copy).disable(!this.moduleSecurity?.copyFlag);
        this.actionBarHandler.get(ACTION_STATUS.save).disable(!this.saveDisable());
        this.actionBarHandler.get(ACTION_STATUS.delete).disable();
        this.actionbarService.updateState(this.actionBarHandler);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    getSecurityCode(securityCode: string) {
        this.loadingSpinnerService.show();
        this.securityCodeService
            .getSecurityCodeDetail(securityCode)
            .subscribe(
                (response: SecurityCodeReferenceModel) => {
                    this.securityRefDetail$.next(response);
                    if (this.copySecurityCode == true) {
                        setTimeout(() => {
                            this.copy();
                        }, 300);
                    }
                    this.addAction();
                    this.loadingSpinnerService.hide();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            );
    }

    getStatusReferences() {
        this.statusReferenceService.getAll().subscribe(response => {
            this.statusReferences$.next(response);
        });
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.copy:
                this.copy();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.cancel:
                this.cancel();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
        }
    }

    save() {
        let newSecurityRefDetail = this.securityCodeDetailFormComponent.getValue();
        if (newSecurityRefDetail != null) {
            if (this.newSecurityCode == false) {
                this.saveSecurityRef(newSecurityRefDetail);
            } else {
                this.addSecurityRef(newSecurityRefDetail);
            }
        }
    }

    cancel() {
        let param = {
            selectedTab: this.FUNCTION_ID,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities
        }
        this.navigateService.navigate('main/settings/security', null, false, param);
    }

    saveSecurityRef(newSecurityRefDetail: SecurityCodeReferenceModel) {
        this.loadingSpinnerService.showSaving();
        this.securityCodeService.SaveSecurityReference(newSecurityRefDetail, this.securityCode).subscribe(
            () => {
                this.loadingSpinnerService.saveComplete();
            },
            () => {
                this.loadingSpinnerService.hide();
            },
            () => {
                this.getSecurityCode(newSecurityRefDetail.securityCode);
                this.securityCode = newSecurityRefDetail.securityCode;
                this.newSecurityCode = false;
                this.copySecurityCode = false;
                this.setupSecurityActionbar();
            }
        );
    }

    addSecurityRef(newSecurityRefDetail: SecurityCodeReferenceModel) {
        this.loadingSpinnerService.show();
        this.securityCodeService.AddSecurityReference(newSecurityRefDetail).subscribe(
            () => {
                this.getSecurityCode(newSecurityRefDetail.securityCode);
                this.securityCode = newSecurityRefDetail.securityCode;
                this.newSecurityCode = false;
                this.copySecurityCode = false;
                this.setupSecurityActionbar();
                this.loadingSpinnerService.saveComplete();
            },
            () => {
                this.loadingSpinnerService.hide();
            }
        );
    }

    new() {
        this.newSecurityCode = true;
        this.securityCode = null;
        this.securityRefDetail$.next(null);
        this.addAction();
        this.setupSecurityActionbar();
    }

    copy() {
        this.newSecurityCode = true;
        this.securityCodeDetailFormComponent.copySecurityName();
        this.securityCode = null;
        this.securityCodeDetailFormComponent.validateDuplicateSecurityCode();
        this.fromEdit = true;
        this.changeDetectorRef.detectChanges();
        this.securityCodeDetailFormComponent.checkDisable();
        this.addAction();
        this.setupSecurityActionbar();
    }

    refresh() {
        if (this.newSecurityCode == false) {
            this.getSecurityCode(this.securityCode);
        }
    }

    back() {
        let param = {
            selectedTab: this.selectedTab,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities
        }
        this.navigateService.navigate('main/settings/security-code', null, false, param);
    }

    addAction() {
        if (!this.newSecurityCode) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.securityCode, this.securityRefDetail$.value?.securityName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    onPageChange(id: string) {
        this.securityCode = id;
        this.newSecurityCode = false;
        this.copySecurityCode = false;
        this.getSecurityCode(id);
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { CommentView } from "../shared/comment.view";

@Component({
    selector: 'op-comment-table',
    templateUrl: './comment-table.component.html'
})
export class CommentTableComponent implements OnInit {
    public selected: CommentView;
    public disableEdit: boolean = false;
    public disableAdd: boolean = false;

    @Input() commentViews: CommentView[] = new Array();
    @Input() isAllowReadOnlyMode: boolean = true;

    @Output() onAdd = new EventEmitter();
    @Output() onEdit = new EventEmitter<CommentView>();
    @Output() onDelete = new EventEmitter<CommentView>();

    @ViewChild('dataGridComment', { static: false }) dataGrid: DxDataGridComponent;

    ngOnInit(): void {
        this.selected = null;
    }

    click(e) {
        this.selected = e.data;
    }

    edit() {
        if (this.selected) {
            this.onEdit.emit(this.selected);
            this.disableEdit = true;
            this.disableAdd = false;
        }
    }

    delete() {
        if (this.selected) {
            this.onDelete.emit(this.selected);
        }
    }

    add() {
        this.onAdd.emit();
        this.disableAdd = true;
        this.disableEdit = false;
    }
}
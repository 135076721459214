import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_TRANSPORT_SOLD_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 290,
        salesChannel1Name: "GDS",
        salesChannel2: 264,
        salesChannel2Name: "API",
        salesChannel3: 127,
        salesChannel3Name: "ISE",
        salesChannel4: 367,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 306,
        salesChannel1Name: "GDS",
        salesChannel2: 384,
        salesChannel2Name: "API",
        salesChannel3: 359,
        salesChannel3Name: "ISE",
        salesChannel4: 248,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 118,
        salesChannel1Name: "GDS",
        salesChannel2: 440,
        salesChannel2Name: "API",
        salesChannel3: 159,
        salesChannel3Name: "ISE",
        salesChannel4: 164,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 399,
        salesChannel1Name: "GDS",
        salesChannel2: 389,
        salesChannel2Name: "API",
        salesChannel3: 330,
        salesChannel3Name: "ISE",
        salesChannel4: 378,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 334,
        salesChannel1Name: "GDS",
        salesChannel2: 433,
        salesChannel2Name: "API",
        salesChannel3: 134,
        salesChannel3Name: "ISE",
        salesChannel4: 283,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 234,
        salesChannel1Name: "GDS",
        salesChannel2: 295,
        salesChannel2Name: "API",
        salesChannel3: 121,
        salesChannel3Name: "ISE",
        salesChannel4: 261,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 312,
        salesChannel1Name: "GDS",
        salesChannel2: 380,
        salesChannel2Name: "API",
        salesChannel3: 357,
        salesChannel3Name: "ISE",
        salesChannel4: 326,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 128,
        salesChannel1Name: "GDS",
        salesChannel2: 261,
        salesChannel2Name: "API",
        salesChannel3: 413,
        salesChannel3Name: "ISE",
        salesChannel4: 190,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 463,
        salesChannel1Name: "GDS",
        salesChannel2: 355,
        salesChannel2Name: "API",
        salesChannel3: 495,
        salesChannel3Name: "ISE",
        salesChannel4: 123,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 337,
        salesChannel1Name: "GDS",
        salesChannel2: 247,
        salesChannel2Name: "API",
        salesChannel3: 477,
        salesChannel3Name: "ISE",
        salesChannel4: 175,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 207,
        salesChannel1Name: "GDS",
        salesChannel2: 222,
        salesChannel2Name: "API",
        salesChannel3: 261,
        salesChannel3Name: "ISE",
        salesChannel4: 121,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 371,
        salesChannel1Name: "GDS",
        salesChannel2: 168,
        salesChannel2Name: "API",
        salesChannel3: 294,
        salesChannel3Name: "ISE",
        salesChannel4: 489,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 443,
        salesChannel1Name: "GDS",
        salesChannel2: 229,
        salesChannel2Name: "API",
        salesChannel3: 384,
        salesChannel3Name: "ISE",
        salesChannel4: 233,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 385,
        salesChannel1Name: "GDS",
        salesChannel2: 141,
        salesChannel2Name: "API",
        salesChannel3: 327,
        salesChannel3Name: "ISE",
        salesChannel4: 460,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 169,
        salesChannel1Name: "GDS",
        salesChannel2: 305,
        salesChannel2Name: "API",
        salesChannel3: 457,
        salesChannel3Name: "ISE",
        salesChannel4: 206,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 183,
        salesChannel1Name: "GDS",
        salesChannel2: 431,
        salesChannel2Name: "API",
        salesChannel3: 166,
        salesChannel3Name: "ISE",
        salesChannel4: 335,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 462,
        salesChannel1Name: "GDS",
        salesChannel2: 457,
        salesChannel2Name: "API",
        salesChannel3: 215,
        salesChannel3Name: "ISE",
        salesChannel4: 376,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 188,
        salesChannel1Name: "GDS",
        salesChannel2: 141,
        salesChannel2Name: "API",
        salesChannel3: 136,
        salesChannel3Name: "ISE",
        salesChannel4: 482,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 222,
        salesChannel1Name: "GDS",
        salesChannel2: 411,
        salesChannel2Name: "API",
        salesChannel3: 389,
        salesChannel3Name: "ISE",
        salesChannel4: 148,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 224,
        salesChannel1Name: "GDS",
        salesChannel2: 487,
        salesChannel2Name: "API",
        salesChannel3: 135,
        salesChannel3Name: "ISE",
        salesChannel4: 465,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 447,
        salesChannel1Name: "GDS",
        salesChannel2: 429,
        salesChannel2Name: "API",
        salesChannel3: 303,
        salesChannel3Name: "ISE",
        salesChannel4: 478,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 375,
        salesChannel1Name: "GDS",
        salesChannel2: 197,
        salesChannel2Name: "API",
        salesChannel3: 495,
        salesChannel3Name: "ISE",
        salesChannel4: 272,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 216,
        salesChannel1Name: "GDS",
        salesChannel2: 347,
        salesChannel2Name: "API",
        salesChannel3: 330,
        salesChannel3Name: "ISE",
        salesChannel4: 233,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 226,
        salesChannel1Name: "GDS",
        salesChannel2: 355,
        salesChannel2Name: "API",
        salesChannel3: 325,
        salesChannel3Name: "ISE",
        salesChannel4: 475,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 320,
        salesChannel1Name: "GDS",
        salesChannel2: 180,
        salesChannel2Name: "API",
        salesChannel3: 143,
        salesChannel3Name: "ISE",
        salesChannel4: 484,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 241,
        salesChannel1Name: "GDS",
        salesChannel2: 262,
        salesChannel2Name: "API",
        salesChannel3: 164,
        salesChannel3Name: "ISE",
        salesChannel4: 325,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 406,
        salesChannel1Name: "GDS",
        salesChannel2: 195,
        salesChannel2Name: "API",
        salesChannel3: 264,
        salesChannel3Name: "ISE",
        salesChannel4: 143,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 349,
        salesChannel1Name: "GDS",
        salesChannel2: 454,
        salesChannel2Name: "API",
        salesChannel3: 176,
        salesChannel3Name: "ISE",
        salesChannel4: 302,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 354,
        salesChannel1Name: "GDS",
        salesChannel2: 304,
        salesChannel2Name: "API",
        salesChannel3: 433,
        salesChannel3Name: "ISE",
        salesChannel4: 498,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 244,
        salesChannel1Name: "GDS",
        salesChannel2: 378,
        salesChannel2Name: "API",
        salesChannel3: 118,
        salesChannel3Name: "ISE",
        salesChannel4: 488,
        salesChannel4Name: "OWN"
    }
]

import { Injectable } from "@angular/core";
import { MediaCommandModel, MediaViewModel } from "src/app/core/models/media-model";
import * as moment from 'moment';
import { MediaContentCommandModel, MediaContentViewModel } from "src/app/core/models/media-model/media-content";
import { MediaFileAssociationCommandModel, MediaFileAssociationViewModel } from "src/app/core/models/media-model/media-fileassociation";
import { MediaMediaHashTagCommandModel, MediaMediaHashTagViewModel } from "src/app/core/models/media-model/media-media-hashtag";
import { MediaUseCommandModel, MediaUseViewModel } from "src/app/core/models/media-model/media-use";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable({
    providedIn: 'root'
})
export class PricingMediaConverter {
    constructor(private dateConverterService: DateConverterService) {

    }
    public commandToMediaViewModels(commands: MediaCommandModel[], entityName: string, associationId: string): MediaViewModel[] {
        let models: MediaViewModel[] = new Array();
        if (commands) {
            for (let command of commands) {
                models.push(this.commandToMediaViewModel(command, entityName, associationId));
            }
        }
        return models;
    }

    private commandToMediaViewModel(command: MediaCommandModel, entityName: string, associationId: string): MediaViewModel {
        let model = new MediaViewModel();
        if (command.mediaId) {
            model.mediaId = command.mediaId;
        }
        model.mediaTypeCode = command.mediaTypeCode;
        model.mediaName = command.mediaName;
        if (command.validFromDateTime) {
            model.validFromDateTime = moment(command.validFromDateTime).toDate();
        }
        if (command.validToDateTime) {
            model.validToDateTime = moment(command.validToDateTime).toDate();
        }
        model.mediaContents = this.commandToMediaContentViewModels(command);
        let mediaFileAssociation = this.commandToMediaFileAssociationViewModel(command, entityName, associationId);
        if (mediaFileAssociation) {
            model.mediaFileAssociations = [mediaFileAssociation];
        }
        model.mediaMediaHashTags = this.commandToMediaMediaHashTagViewModels(command);
        model.mediaUses = this.commandToMediaUseViewModels(command);
        return model;
    }

    private commandToMediaContentViewModels(command: MediaCommandModel): MediaContentViewModel[] {
        let models: MediaContentViewModel[] = new Array();
        for (let mediaContent of command.mediaContents) {
            let model = new MediaContentViewModel();
            if (mediaContent.mediaContentId) {
                model.mediaContentId = mediaContent.mediaContentId;
            }
            model.organisationId = mediaContent.organisationId;
            model.languageCode = mediaContent.languageCode;
            model.primaryLanguageFlag = mediaContent.primaryLanguageFlag;
            model.mediaText = mediaContent.mediaText;
            model.originalFileName = mediaContent.originalFileName;
            model.logicalLocation = mediaContent.logicalLocation;
            model.physicalLocation = mediaContent.physicalLocation;
            model.thumbnailLogicalLocation = mediaContent.thumbnailLogicalLocation;
            model.thumbnailPhysicalLocation = mediaContent.thumbnailPhysicalLocation;
            model.mediaFileBase64 = mediaContent.mediaFileBase64;
            models.push(model);
        }
        return models;
    }

    private commandToMediaFileAssociationViewModel(command: MediaCommandModel, entityName: string, associationId: string): MediaFileAssociationViewModel {
        let model = null;
        let mediaFileAssociation = command.mediaFileAssociation;
        if (mediaFileAssociation) {
            model = new MediaFileAssociationViewModel();
            if (mediaFileAssociation.mediaFileAssociationId) {
                model.mediaFileAssociationId = mediaFileAssociation.mediaFileAssociationId;
            }
            model.entityName = entityName;
            if (associationId) {
                model.associationId = associationId;
            }
            model.mediaUseCode = mediaFileAssociation.mediaUseCode;
            model.mediaId = mediaFileAssociation.mediaId;
        }
        return model;
    }

    private commandToMediaMediaHashTagViewModels(command: MediaCommandModel): MediaMediaHashTagViewModel[] {
        let models: MediaMediaHashTagViewModel[] = new Array();
        for (let mediaHashTag of command.mediaMediaHashTags) {
            let model = new MediaMediaHashTagViewModel();
            if (mediaHashTag.mediaMediaHashTagId) {
                model.mediaMediaHashTagId = mediaHashTag.mediaMediaHashTagId;
            }
            model.mediaHashtagId = mediaHashTag.mediaHashtagId;
            model.displaySequence = mediaHashTag.displaySequence;
            models.push(model);
        }
        return models;
    }

    private commandToMediaUseViewModels(command: MediaCommandModel): MediaUseViewModel[] {
        let models: MediaUseViewModel[] = new Array();
        for (let mediaUse of command.mediaUses) {
            let model = new MediaUseViewModel();
            if (mediaUse.mediaMediaUseId) {
                model.mediaMediaUseId = mediaUse.mediaMediaUseId;
            }
            model.mediaUseCode = mediaUse.mediaUseCode;
            models.push(model);
        }
        return models;
    }

    public mediaViewModelsToCommand(views: MediaViewModel[]): MediaCommandModel[] {
        let commands: MediaCommandModel[] = new Array();
        if (views?.length) {
            for (let view of views) {
                commands.push(this.mediaViewModelToCommand(view));
            }
        }
        return commands;
    }

    private mediaViewModelToCommand(view: MediaViewModel): MediaCommandModel {
        let command = new MediaCommandModel();
        if (view.mediaId) {
            command.mediaId = view.mediaId;
        }
        command.mediaTypeCode = view.mediaTypeCode;
        command.mediaName = view.mediaName;
        command.validFromDateTime = this.dateConverterService.toDateFormat(view.validFromDateTime);
        command.validToDateTime = this.dateConverterService.toDateFormat(view.validToDateTime);
        command.mediaContents = this.mediaContentViewModelsToCommand(view);
        command.mediaFileAssociation = this.mediaFileAssociationViewModelToCommand(view)
        command.mediaMediaHashTags = this.mediaMediaHashTagViewModelsToCommand(view);
        command.mediaUses = this.mediaUseViewModelsToCommand(view);
        return command;
    }

    private mediaContentViewModelsToCommand(view: MediaViewModel): MediaContentCommandModel[] {
        let commands: MediaContentCommandModel[] = new Array();
        for (let mediaContent of view.mediaContents) {
            let command = new MediaContentCommandModel();
            if (mediaContent.mediaContentId) {
                command.mediaContentId = mediaContent.mediaContentId;
            }
            command.organisationId = mediaContent.organisationId;
            command.languageCode = mediaContent.languageCode;
            command.primaryLanguageFlag = mediaContent.primaryLanguageFlag;
            command.mediaText = mediaContent.mediaText;
            command.originalFileName = mediaContent.originalFileName;
            command.logicalLocation = mediaContent.logicalLocation;
            command.physicalLocation = mediaContent.physicalLocation;
            command.thumbnailLogicalLocation = mediaContent.thumbnailLogicalLocation;
            command.thumbnailPhysicalLocation = mediaContent.thumbnailPhysicalLocation;
            command.mediaFileBase64 = mediaContent.mediaFileBase64;
            commands.push(command);
        }
        return commands;
    }

    private mediaFileAssociationViewModelToCommand(view: MediaViewModel): MediaFileAssociationCommandModel {
        let command = null;
        let mediaFileAssociation = view.mediaFileAssociations?.shift();
        if (mediaFileAssociation) {
            command = new MediaFileAssociationCommandModel();
            if (mediaFileAssociation.mediaFileAssociationId) {
                command.mediaFileAssociationId = mediaFileAssociation.mediaFileAssociationId;
            }
            command.entityName = mediaFileAssociation.entityName;
            if (mediaFileAssociation.associationId) {
                command.associationId = mediaFileAssociation.associationId;
            }
            command.mediaUseCode = mediaFileAssociation.mediaUseCode;
            command.mediaId = mediaFileAssociation.mediaId;
        }
        return command;
    }

    private mediaMediaHashTagViewModelsToCommand(view: MediaViewModel): MediaMediaHashTagCommandModel[] {
        let commands: MediaMediaHashTagCommandModel[] = new Array();
        for (let mediaHashTag of view.mediaMediaHashTags) {
            let command = new MediaMediaHashTagCommandModel();
            if (mediaHashTag.mediaMediaHashTagId) {
                command.mediaMediaHashTagId = mediaHashTag.mediaMediaHashTagId;
            }
            command.mediaHashtagId = mediaHashTag.mediaHashtagId;
            command.displaySequence = mediaHashTag.displaySequence;
            commands.push(command);
        }
        return commands;
    }

    private mediaUseViewModelsToCommand(view: MediaViewModel): MediaUseCommandModel[] {
        let commands: MediaUseCommandModel[] = new Array();
        for (let mediaUse of view.mediaUses) {
            let command = new MediaUseCommandModel();
            if (mediaUse.mediaMediaUseId) {
                command.mediaMediaUseId = mediaUse.mediaMediaUseId;
            }
            command.mediaUseCode = mediaUse.mediaUseCode;
            commands.push(command);
        }
        return commands;
    }
}
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";

@Component({
    selector: 'op-schedule-period-home',
    templateUrl: './schedule-period-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulePeriodHomeComponent {
    readonly SPINNER_NAME: string = "schedulePeriodSpinner";
    public loadingNotifier = new LoadingNotifier();
    @Input() userSecurity: SecurityGroupSecurityModel;
}
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { ValidityModel } from 'src/app/core/models/merchandizing-config';
import { CalendarValidityReferenceModel, DateTimeDimensionReferenceModel, ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
    selector: 'op-voucher-validity',
    templateUrl: './voucher-validity.component.html'
})
export class VoucherValidityComponent {
    private _jsonURL = 'assets/data/voucher/attributes&rules_config.json';
    public readonly errorDuplicatedValidities = 'Validities are duplicated.';
    public readonly errorInvalidValidity = 'Validity is invalid.';
    public focusing: boolean = false;
    public validityConfig: ValidityModel;

    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
    
    constructor(private changeDetectorRef: ChangeDetectorRef, 
        private http: HttpClient) {
        this.getJSON().subscribe(data => {
            this.validityConfig = data.validity;
            this.changeDetectorRef.detectChanges();
        });
    }

    get validityLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    get allowPanelToggle() {
        return this.validityLength > 0;
    }

    get panelCollapsed(): boolean {
        return this.validityLength == 0;
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    public add() {
        this.ruleComponent.add();
    }

    public getErrorMessage() {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return this.errorDuplicatedValidities;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return this.errorInvalidValidity;
        }
    }
}

import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { InsightPassengerAddressModel } from "src/app/core/models/individual-model/insight-passenger-address.model";
import { AddressTypeReferenceModel, CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightAddressDetailComponent } from "./detail/address-detail.component";
import { AddressView } from "./shared/address-view";
import { cloneDeep } from "lodash";
import { AddressDetailDirective } from "./detail/address-detail.directive";
import { InsightAddressTableComponent } from "./table/address-table.component";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-address',
    templateUrl: './insight-detail-address.component.html',
})

export class InsightDetailAddressComponent {
    readonly CROSS_REFFERENCE_ERROR = "Cross-reference address cannot be deleted.";

    @Input() addressTypeReferences: AddressTypeReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];
    @Input() useAddressDropdown: InsightPassengerAddressModel[];

    @Output() addressError = new EventEmitter<string>();
    
    public isCollapsedDetail: boolean = false;
    public isAddressesEmpty: boolean = true;
    public isMoreThanOneAddresses: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;
    public address = new AddressView();
    public addresses: AddressView[] = new Array();
    public previousAddresses: AddressView[] = new Array();

    public focusing: boolean = false;
    public focused: boolean = false;

    public disabledDelBtn: boolean = false;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;
    @Input() actionSecurity: SecurityGroupSecurityModel;

    @ViewChild(AddressDetailDirective) addressDetailDirective: AddressDetailDirective;
    @ViewChild(InsightAddressDetailComponent) addressDetailComponent: InsightAddressDetailComponent;
    @ViewChild(InsightAddressTableComponent) addressTableComponent: InsightAddressTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public newAddressDetail: InsightAddressDetailComponent;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {}

    public fillModelToForm(passengerAddresses: AddressView[]) {
        this.clearData();
        if (passengerAddresses && passengerAddresses.length > 0) {
            this.fillModelInCase(passengerAddresses);
        }

        this.addresses = passengerAddresses;
        this.previousAddresses = passengerAddresses;

        if (this.addresses.length > 0) {
            this.isAddressesEmpty = false;
        }

        if (this.addresses.length == 1) {
            this.isMoreThanOneAddresses = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.address = new AddressView();
    }

    private fillModelInCase(addresses: AddressView[]) {
        if (addresses.length == 1) {
            this.fillModelSingleRecord(addresses[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(model: AddressView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.address = model;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneAddresses = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: AddressView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightAddressDetailComponent);

        let viewContainerRef = this.addressDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightAddressDetailComponent = (<InsightAddressDetailComponent>componentRefs.instance);
        component.addressTypeReferences = this.addressTypeReferences;
        component.countryReferences = this.countryReferences;
        component.useAddressDropdown = this.useAddressDropdown;
        
        if (dataEdit != null) {
            component.insightAddress = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newAddressDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightAddress = this.addressDetailComponent.getInsightAddress();
        if (insightAddress) {
            this.savedata(insightAddress);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewPassenger() {
        this.isApplyBtnClicked = true;
        let insightAddress = this.newAddressDetail.getInsightAddress();
        if (!insightAddress) {
            return;
        }

        if (insightAddress?.organisationId) {
            insightAddress.addressLocked = true;
        }
        else {
            insightAddress.addressLocked = false;
        }

        if (insightAddress) {
            insightAddress.locationDataSave = this.getLocationSave(insightAddress);
            this.savedata(insightAddress);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    public getLocationSave(view: AddressView) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (view.locationId) {
            let data = new Select2Data(view.locationId, view.locationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    private savedata(data: AddressView) {
        if (this.addresses?.length) {
            var filterIndex = this.addresses.findIndex(x => x.no == data.no);
            if (data.primaryAddress === undefined) {
                data.primaryAddress = false;
            }

            this.updateAddressViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryAddress = true;
            this.address = data;
            this.addresses.push(data);
        }

        this.isAddressesEmpty = false;
        this.addressTableComponent.dataGrid.instance.refresh();
    }

    private updateAddressViews(filterIndex: number, data: AddressView) {
        if (this.addresses?.length > 0 && data.primaryAddress) {
            this.setOtherAddressPrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.addresses.length + 1;
            this.addresses.push(data);
        }
        else {
            this.addresses[filterIndex] = data;
        }
    }

    private setOtherAddressPrimaryFlag(filterIndex: number) {
        let otherAddresses = this.addresses;
        for (let otherAddress of otherAddresses) {
            if (otherAddress.no == filterIndex + 1) {
                continue;
            }

            otherAddress.primaryAddress = false;
        }

        this.addresses = otherAddresses;
    }

    private displayAfterSave() {
        if (this.addresses?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    public toggleDetail() {
        if (this.addresses.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.addressDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.addresses.length == 0) {
            this.address = new AddressView();
        }

        if (this.addresses.length <= 1) {
            this.isMoreThanOneAddresses = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.addressDetailComponent) {
            return this.addressDetailComponent.setProcessing(false);
        }

        if (this.newAddressDetail) {
            return this.newAddressDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.addresses.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    onRowSelected() {
        let selectedRow = this.addressTableComponent.selectedItem;
        
        if (selectedRow.addressLocked) {
            this.disabledDelBtn = true;
        }
        else {
            this.disabledDelBtn = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.addressTableComponent.selectedItem);
    }

    private isSingleRecord(): boolean {
        if (this.addresses.length == 0 || this.addresses.length == 1) {
            return true;
        }
        return false;
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        if (this.checkAddressIsUsed(data)) {
            return;
        }

        this.setMoreThanOneAddresses();
        this.deleteDataFromAddressViews(data);     
    }

    getDeleteData(): AddressView {
        if (this.addresses.length > 1) {
            return this.addressTableComponent.selectedItem;
        }

        return this.address;
    }

    setMoreThanOneAddresses() {
        if (this.addresses.length > 1) {
            this.isMoreThanOneAddresses = true;
        } 
        else {
            this.isMoreThanOneAddresses = false;
        }
    }

    deleteDataFromAddressViews(data: AddressView) {
        let views = new Array<AddressView>();
        if (this.addresses != null) {
            views = this.addresses;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.addresses = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.addresses.length == 1) {
            this.addresses[0].primaryAddress = true;
        }
    }

    checkAddressIsUsed(addressSelected: AddressView) : boolean {
        if (this.checkIfDeleteAddressIsUsed(addressSelected)) {
            this.addressError.emit(this.CROSS_REFFERENCE_ERROR);
            return true;
        }

        this.addressError.emit();
        return false;
    }

    checkIfDeleteAddressIsUsed(addressSelected: AddressView): boolean {
        return this.addresses.filter(it => it.useAddressId == addressSelected.addressId && addressSelected.addressId).length > 0
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForAddressDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private setPropertyForAddressDetailForm() {
        if (this.addresses.length == 1) {
            this.address = this.addresses[0];
            this.showAdd = true;
        }
        else {
            this.address = new AddressView();
            this.showAdd = false;
        }
    }

    copy() {
        if (this.addresses.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.addresses.length == 1) {
            let copyAddress = cloneDeep(this.addresses[0]);
            this.openCopyDetailForm(copyAddress);
            return;
        }

         if (!this.addressTableComponent.selectedItem) {
            return;
        }

        let copyAddress = cloneDeep(this.addressTableComponent.selectedItem);
        this.openCopyDetailForm(copyAddress);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneAddresses = true;
    }

    setCopyAddressProperty(copyAddress: AddressView) {
        copyAddress.organisationId = null;
        copyAddress.no = null;
        copyAddress.addressId = null;
        copyAddress.primaryAddress = false;
    }

    openCopyDetailForm(copyAddress: AddressView) {
        this.setCopyAddressProperty(copyAddress);
        this.openDetail();
        this.loadDetailForm(copyAddress);
    }

    validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

         if (this.addresses.length <= 0) {
            return true;
        }

        if (this.addressDetailComponent && this.isApplyBtnClicked) {
            return this.addressDetailComponent.isValidForm();
        }

        if (this.newAddressDetail && this.isApplyBtnClicked) {
            return this.newAddressDetail.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.addressDetailComponent) {
            return this.addressDetailComponent.getErrorMessageForm();
        }

        if (this.newAddressDetail) {
            return this.newAddressDetail.getErrorMessageForm();
        }
    }
}
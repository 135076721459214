export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AttributeDisabled = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    disabled: true
}

export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2Choice = {
    placeholder: "<Dimension>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2ChoiceMultiple = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}


export const timeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'HH:mm'
    },
    timePicker24Hour:true     
};
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import {
    Subject,
    Observable
} from 'rxjs';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { OrderDetailIndividualModel } from 'src/app/core/models/order-model';
import {
    OrdersMapperService,
    OrderDetailIndividualView,
    OrderDetailCommentView,
    OrderDetailChildLoadData,
    OrderHeaderLoadData
} from '../../../shared';
import { CountryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrderSalesStatusCode } from 'src/app/core/constants/order-constants';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-individual-list',
    templateUrl: './order-detail-individual-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailIndividualListComponent implements OnDestroy {

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("changeSessionId") changeSessionId: string;
    @Input("countryReferences") countryReferences: CountryReferenceModel[] = [];
    @Input("orderDetailGuardianViews") orderDetailGuardianViews: OrderDetailIndividualView[] = new Array<OrderDetailIndividualView>();
    @Input("orderHeaderData") orderHeaderData: OrderHeaderLoadData;
    
    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();

    public collapsed: boolean;

    public focused: boolean;
    public orderIndividualViews: OrderDetailIndividualView[] = new Array<OrderDetailIndividualView>();
    public orderLock: boolean;
    public rootOrderId: string;
    public countNumberOfItems: number = 0;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }

    get primaryCommunicationOthers(): {
        communicationOtherTypeName: string,
        communicationOtherTypeCode: string,
        mailAddress: string
    }[] {
        return this.orderIndividualViews
            ?.map(view => {
                if (!view?.orderIndividualCommunicationOthers?.length) {
                    return {
                        communicationOtherTypeName: 'Email Address',
                        communicationOtherTypeCode: 'EMAIL',
                        mailAddress: view.mailAddress
                    }
                }
                const primary = view.orderIndividualCommunicationOthers
                    .find(item => item.primaryFlag == true) ?? view.orderIndividualCommunicationOthers[0];
                return {
                    communicationOtherTypeName: primary.communicationOtherTypeName,
                    communicationOtherTypeCode: primary.communicationOtherTypeCode,
                    mailAddress: primary.mailAddress
                }
            });
    }

    get nationalityCountryNames(): string[] {
        if (!this.countryReferences?.length) return [];
        let names = [];
        for (const countryReference of this.countryReferences) {
            names[countryReference.countryCode] = countryReference.countryName;
        }
        return names;
    }

    get guardianNames(): string[] {
        return this.orderDetailGuardianViews
            ?.map(view => {
                return this.toDisplayFormat(' ', view?.titleName, view?.firstName, view?.lastName);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private toDisplayFormat(separator: string, ...params: string[]): string {
        if (!params?.length) return '';
        return params.filter(item => !!item)?.join(separator);
    }
    
    public loadIndividualByProduct(orderId: string, productOrderId: string): Observable<boolean> {

        if (!orderId) {
            return;
        }
        return new Observable(observer => {
            this.orderDetailService.getOrderProductDetailIndividuals(orderId, productOrderId, this.changeSessionId)
                .subscribe(
                    (result: OrderDetailIndividualModel[]) => {
                        if (result) {

                            this.mapIndividualData(result);
                            this.fillNumberOfItems(this.orderIndividualViews);
                            this.childLoadCompleted.emit({
                                recordCount: result.length
                            })
                            this.checkCollape();
                            this.changeDetection.detectChanges();
                        }
                        observer.next(true);
                        observer.complete();
                    });
        });
    }

    private mapIndividualData(result: OrderDetailIndividualModel[]) {
        if (this.orderHeaderData?.orderSalesStatusCode != OrderSalesStatusCode.Confirm) {
            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result);
        } else {
            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result, true);
        }
    }
    
    public loadAllStatusIndividualByProduct(orderId: string, productOrderId: string, sessionId: string = null): Observable<boolean> {
        if (!orderId) {
            return;
        }
        return new Observable(observer => {
            this.orderDetailService.getOrderProductDetailIndividuals(orderId, productOrderId, sessionId)
                .subscribe(
                    (result: OrderDetailIndividualModel[]) => {
                        if (result) {
                            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result);
                            this.fillNumberOfItems(this.orderIndividualViews);
                            this.childLoadCompleted.emit({
                                recordCount: result.length
                            })
                            this.checkCollape()
                            this.changeDetection.detectChanges();
                        }
                        observer.next(true);
                        observer.complete();
                    });
        });
    }

    public loadIndividualByPartnerId(orderId: string, partnerOrderId: string, sessionId: string = null): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId || !partnerOrderId) {
                this.checkCollape();
                this.changeDetection.detectChanges();
                observer.next(true);
                observer.complete();
                return;
            }
            this.orderDetailService.getOrderDetailPartner(orderId, partnerOrderId, sessionId)
                .subscribe(
                    (result: OrderDetailIndividualModel[]) => {
                        if (result) {
                            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result);
                            this.fillNumberOfItems(this.orderIndividualViews);
                            this.childLoadCompleted.emit({
                                recordCount: result.length
                            })
                            this.checkCollape();
                            this.changeDetection.detectChanges();
                        }
                        observer.next(true);
                        observer.complete();
                    });
        });
    }

    public loadIndividualByIndividualId(orderId: string, IndividualId: string): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId || !IndividualId) {
                this.checkCollape();
                this.changeDetection.detectChanges();
                observer.next(true);
                observer.complete();
                return;
            }
            this.orderDetailService.getOrderDetailIndividual(orderId, IndividualId)
                .subscribe(
                    (result: OrderDetailIndividualModel[]) => {
                        if (result) {
                            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result);
                            this.fillNumberOfItems(this.orderIndividualViews);
                            this.childLoadCompleted.emit({
                                recordCount: result.length
                            })
                            this.checkCollape();
                            this.changeDetection.detectChanges();
                        }
                        
                        observer.next(true);
                        observer.complete();
                    });
        });
    }

    public loadIndividualByLedgerId(orderId: string, ledgerId: string, sessionId: string): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId || !ledgerId) {
                this.checkCollape();
                this.changeDetection.detectChanges();
                observer.next(true);
                observer.complete();
                return;
            }
            this.orderDetailService.getOrderPaymentIndividual(orderId, ledgerId, sessionId)
                .subscribe(
                    (result: OrderDetailIndividualModel[]) => {
                        if (result) {
                            this.orderIndividualViews = this.mapperService.toOrderDetailIndividualViews(result);
                            this.fillNumberOfItems(this.orderIndividualViews);
                            this.childLoadCompleted.emit({
                                recordCount: result.length
                            })
                            this.checkCollape();
                            this.changeDetection.detectChanges();
                        }
                        
                        observer.next(true);
                        observer.complete();
                    });
        });
    }

    private checkCollape(): void {
        if (!this.orderIndividualViews || this.orderIndividualViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public getPhoneCaption(phoneType: string): string {
        const phoneCaption: string = "Phone";
        if (!phoneType) {
            return phoneCaption;
        }
        return phoneCaption + " - " + phoneType;
    }
    public getLanguageFlag(languageCode: string) {
        const flagDirectory: string = "../../../../../assets/img/flags/";
        if (!languageCode || languageCode.length > 0) {
            return flagDirectory + languageCode?.toLowerCase() + ".svg";
        }
        return "";
    }
    public showComment(comments: OrderDetailCommentView[]): boolean {
        if (!comments || comments.length === 0) {
            return false;
        }
        return true;
    }
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }
    private fillNumberOfItems(views: OrderDetailIndividualView[]) {
        if (!views || views.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = views.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
}
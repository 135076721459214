import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DxCheckBoxModule, DxDataGridModule, DxTreeListModule } from 'devextreme-angular';
import { TreeModule } from 'primeng/tree';
import { ActivityDomainModule } from 'src/app/core/components/activity-domain/activity-domain.module';
import { TranslationNameModule } from 'src/app/core/components/translation-name/translation-name.module';
import { OrdinalModule } from 'src/app/core/pipes/ordinal/ordinal.module';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { AlphanumericModule } from 'src/app/shared/ui/forms/inputs/alphanumeric/alphanumeric.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AmountComponent } from './amount/amount.component';
import { ConditionComponent } from './condition/condition.component';
import { GeneralComponent } from './general/general.component';
import { PriceDetailComponent } from './price-detail.component';
import { PriceDimensionLevelComponent } from './price-dimension-level/price-dimension-level.component';
import { AttributesComponent } from './price-dimension/attributes/attributes.component';
import { PriceDimensionComponent } from './price-dimension/price-dimension.component';
import { TemplateComponent } from './price-dimension/template/template.component';
import { PriceRuleMappingComponent } from './price-rule-mapping/price-rule-mapping.component';
import { TreeComponent } from './tree/tree.component';

@NgModule({
    declarations: [
        AmountComponent,
        AttributesComponent,
        ConditionComponent,
        GeneralComponent,
        PriceDetailComponent,
        PriceDimensionComponent,
        PriceDimensionLevelComponent,
        PriceRuleMappingComponent,
        TemplateComponent,
        TreeComponent,
    ],
    exports: [PriceDimensionComponent],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        AlphanumericModule,
        CommonModule,
        DaterangepickerModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FreezingTabModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        OrdinalModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        TranslationNameModule,
        TreeModule,
        UiModule,
    ],
})
export class PriceDetailModule {}

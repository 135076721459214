import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { ActionbarService } from "src/app/shared/ui/actionbar";

@Component({
    selector: 'op-schedule-content',
    templateUrl: './schedule-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class ScheduleContentComponent {
}
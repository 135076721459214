import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrderServicePassengerModel } from "../../models/order-model";

@Injectable({
    providedIn: 'root'
})
export class OrderServicePassengerService extends ApiServiceBase {
    private readonly PATH: string = "/order"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getOrderServicePassengerByOrderTransport(orderTransportId: string): Observable<OrderServicePassengerModel[]> {
        return this.httpGet<OrderServicePassengerModel[]>(this.PATH + "/OrderServicePassengers/" + orderTransportId + "/transport", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
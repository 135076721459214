import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ACTION_STATUS } from './actionbar-constant';
import { ActionBarHandlerModel } from './actionbar-handler.model';
import { ActionbarNotifier, ActionbarService } from '.';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'op-actionbar',
    templateUrl: './actionbar.component.html'
})
export class ActionbarComponent implements OnDestroy, OnInit {
    public currentDateTime: string;
    private intervalId;
    notifier: ActionbarNotifier = new ActionbarNotifier();
    actionBarHandler: ActionBarHandlerModel = new ActionBarHandlerModel();
    action$ = new Subject<string>();
    actionStatus = ACTION_STATUS;

    @Input('actionBarHandler') a: ActionBarHandlerModel;

    constructor(private actionbarService: ActionbarService,
        private changeDetectorRef: ChangeDetectorRef,
        private dateTimeConverter: DateConverterService) {
    }
    ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }

    ngOnInit(): void {
        this.notifier = this.actionbarService.notifier;
        this.notifier.getCurrentState = () => {
            return this.actionBarHandler;
        }

        this.notifier.updateState = (handler: ActionBarHandlerModel) => {
            this.actionBarHandler = handler;
            this.changeDetectorRef.detectChanges();
        }
        this.intervalId = setInterval(() => {
            this.formatDate();
        }, 1000);
    }

    private formatDate() {
        this.currentDateTime = this.dateTimeConverter.dayWeekMonthDateTimeFormat(new Date().toString());
        this.changeDetectorRef.detectChanges();
    }
    onClick(event, actionId: string) {
        event.preventDefault();
        this.notifier.action$.next(actionId);
    }
}
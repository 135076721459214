import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommunicationPhoneTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommunicationPhoneTypeReferenceService } from '../../services/system-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { takeUntil } from 'rxjs/operators';

const PHONE_TYPE_SELECTION_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}
@Component({
    selector: 'op-phone-type-selection',
    templateUrl: './phone-type-selection.component.html',
    providers: [
        CommunicationPhoneTypeReferenceService
    ]
})
export class PhoneTypeSelectionComponent implements OnInit, OnDestroy {
    public phoneTypeOption = PHONE_TYPE_SELECTION_OPTION;
    public _value: string | string[];
    public loadSuccess: boolean = false;
    public communicationPhoneTypeReference$ = new BehaviorSubject<CommunicationPhoneTypeReferenceModel[]>(null); 
    public select2Datas$ = new BehaviorSubject<Select2Data[]>(null);

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input('disabled') disableFlag: boolean = false;
    @Input('tabIndexNo') tabIndexNo: number = null;
    @Input() classError: string = "";
    @Output() dataChange: EventEmitter<string> = new EventEmitter();
    @Output() action = new EventEmitter<string>();

    private readonly emptySelect2Data: Select2Data = {
        disabled: false,
        id: '',
        selected: false,
        text: ''
    }
    private destroy$ = new Subject();

    constructor(private communicationPhoneTypeReferenceService: CommunicationPhoneTypeReferenceService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.communicationPhoneTypeReference$
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                value => {
                    this.populateSelect2DatasFromCommunicationPhoneTypeReferences(value);
                }
            )
    }

    ngOnInit(): void {
        this.getCommunicationPhoneTypeReference();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getCommunicationPhoneTypeReference() {
        this.loadSuccess = false;
        this.communicationPhoneTypeReferenceService.getCommunicationPhoneTypeReferences().subscribe(
            (responses: CommunicationPhoneTypeReferenceModel[]) => {
                this.communicationPhoneTypeReference$.next(responses);
                this.onChange(this._value)
                this.loadSuccess = true;
                this.changeDetectorRef.detectChanges();
            }
        );
    }

    private populateSelect2DatasFromCommunicationPhoneTypeReferences(communicationPhoneTypeReferences: CommunicationPhoneTypeReferenceModel[]) {
        this.select2Datas$.next([
            this.emptySelect2Data,
            ...(communicationPhoneTypeReferences?.map(item => new Select2Data(item.communicationPhoneTypeCode, item.communicationPhoneTypeName)) || [])]);
    }

    public populateSelect2Datas(select2Datas: Select2Data[]) {
        this.select2Datas$.next([
            this.emptySelect2Data,
            ...(select2Datas || [])]);
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    get disablePhoneType() {
        return (!this.loadSuccess) || this.disableFlag
    }

    public getSelectedText(): string {
        return this.select2Datas$.value
            ?.find(item => item.id == this._value)
            ?.text;
    }

    onActionClick(action: string) {
        this.action.emit(action);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserManagementComponent } from './user-management.component';
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';

@NgModule({
  declarations: [
    UserManagementComponent
  ],
  imports: [
    CommonModule,
    DynamicRoutingModule,
    UserManagementRoutingModule
  ]
})
export class UserManagementModule { }

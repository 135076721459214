export const select2AreaType2 = {
    placeholder: "<Organisation Type>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2Operator2 = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2OrgTypeOption = {
    placeholder: "<Select>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2OrgGroupOption = {
    placeholder: "<Select>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2OrgRoleOption = {
    placeholder: "<Select>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2OrgSpecificOption = {
    placeholder: "<Select>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from "lodash";
import { DxDataGridComponent } from 'devextreme-angular';
import { ExchangeRateAmountCommandModel, ExchangeRateAmountModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ExchangeRateAmountService } from 'src/app/core/services/system-services';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ExchangeRateAmountMapperService } from './exchange-rate-amount-list.mapper.service';
import { ExchangeRateAmountView } from './exchange-rate-amount.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-exchange-rate-amount-list',
    templateUrl: './exchange-rate-amount-list.component.html',
    providers: [ExchangeRateAmountMapperService]
})
export class ExchangeRateAmountListComponent implements OnInit {
    public readonly EXPORT_FILE_NAME = "ExchangeRate";
    public readonly precision  = 6;
    public focusing = false;
    public selectedItem: ExchangeRateAmountView;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    }
    
    @Input() userSecurity: SecurityGroupSecurityModel
    @Output() onSelectedItem = new EventEmitter<ExchangeRateAmountView>();
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    public exchangeRateAmountViews: ExchangeRateAmountView[];
    public exchangeRateAmountSaveViews: ExchangeRateAmountView[];
    public disabledApply: boolean = true;

    constructor(private spinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private exchangeRateAmountService: ExchangeRateAmountService,
        private mapperService: ExchangeRateAmountMapperService) {
    }

    ngOnInit(): void {
        this.getExchangeRateAmount();
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public getExchangeRateAmount() {
        this.spinnerService.show();
        this.exchangeRateAmountService.getAll()
            .subscribe(
                (responses: ExchangeRateAmountModel[]) => {
                    this.exchangeRateAmountViews = this.mapperService.toViews(responses);
                    this.spinnerService.hide();
                    this.selectedItem = null;
                    this.disabledApply = true;
                    this.onSelectedItem.emit(this.selectedItem);
                    this.changeDetectorRef.detectChanges();
                }
            )
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.name);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Base: data.baseCurrencyCode,
            Exchange: data.exchangeCurrencyCode,
            Factor: data.factor,
            Sell: data.sell,
            Buy: data.buy,
            ValidFrom: data.validFrom,
            SellNew: data.sellNew,
            BuyNew: data.buyNew,
            ValidFromNew: data.validFromNew
        };
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    public getDateString(rowData) {
        if (rowData.validFrom) {
          var date = new Date(rowData.validFrom);
          return date.toLocaleString([], { hour12: true, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        }
        return null;
      }

    onRowSelected(event) {
        this.selectedItem = event.data;
        this.onSelectedItem.emit(this.selectedItem);
    }

    public onApply() {
        this.exchangeRateAmountSaveViews = this.filterSaveData();
    }

    public onCancel() {
        this.exchangeRateAmountSaveViews = [];
        this.getExchangeRateAmount();
    }

    private filterSaveData(): ExchangeRateAmountView[] {
        return cloneDeep(this.exchangeRateAmountViews.filter(x => x.buyNew || x.sellNew));
    }

    public getValue(): ExchangeRateAmountCommandModel[] {
        return this.mapperService.toModels(this.exchangeRateAmountSaveViews);
    }

    public onSavedGridValue(value) {
        this.disabledApply = false;
    }    
}
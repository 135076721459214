import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { PriceRuleTypeReferenceModel } from 'src/app/core/models/pricing-model';
import { OrganisationTypeReferenceModel, StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { DomainReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/domain-reference.model';
import { OrderStateReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/order-state-reference.model';
import { OrderTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/order-type-reference.model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ProductTypeGroupService } from 'src/app/core/services/airline-services';
import { OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService } from 'src/app/core/services/system-services';
import { DomainReferenceService } from 'src/app/core/services/system-services/domain-reference.service';
import { OrderStateReferenceService } from 'src/app/core/services/system-services/order-state-reference.service';
import { OrderTypeReferenceService } from 'src/app/core/services/system-services/order-type-reference.service';
import { PriceRuleTypeReferenceService } from 'src/app/core/services/system-services/price-rule-type-reference.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { AttributeSearchConstant } from '../../shared/attribute-search.constant';
import { AttributeConverter } from '../../shared/attribute.converter';
import { select2Status } from '../../shared/select2-configuration';
import { AttributeSearchConditionView } from '../../shared/view/attribute-search-condition.view';

@Component({
  selector: 'op-attribute-search-condition',
  templateUrl: './attribute-search-condition.component.html',
  providers: [
      AttributeConverter
  ]
})
export class AttributeSearchConditionComponent extends OopsComponentFormBase implements OnInit {
    @Input() statusReference: StatusReferenceModel[];
    @Output() searchClick = new EventEmitter<AttributeSearchConditionView>();
    @Output() clearClick = new EventEmitter();

    @ViewChild('mappingField') inputField: ElementRef; 

    public collapsed: boolean;
    public focused: boolean;
    public statusOption: any;
    public productHidden = true;
    public organisationHidden = true;
    public orderHidden = true;
    public priceruleHidden = true;
    public orderMappingHidden = true;
    public condition = new AttributeSearchConditionView();
    public typeFieldTitle  = "Type";

    public domainRef$ = new BehaviorSubject<DomainReferenceModel[]>(null);
    public category$ = new BehaviorSubject<Select2Data[]>(null);
    public group$ = new BehaviorSubject<Select2Data[]>(null);
    public type$ = new BehaviorSubject<Select2Data[]>(null);
    public orderMapping = AttributeSearchConstant.ORDER_MAPPING;

    constructor(private productCategoryReferenceService:ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private organisationTypeService: OrganisationTypeReferenceService,
        private priceRuleTypeReferenceService: PriceRuleTypeReferenceService,
        private domainReferenceService: DomainReferenceService,
        private orderTypeReferenceService: OrderTypeReferenceService,
        private orderStateReferenceService: OrderStateReferenceService,
        private attributeConverter: AttributeConverter,
        private formBuilder: UntypedFormBuilder) {
            super(formBuilder);
            this.statusOption = select2Status;
    }

    public initForm() {
        this.getDomain();
        this.formSetup();
    }

    public formSetup() {
        this.formGroup = this.formBuilder.group({
            code: [null],
            name: [null],
            domainCode: [null],
            mapping: [null],
            categoryCode: [null],
            groupCode: [null],
            typeCode: [null],
            statusCode: ['A']
        })
    }

    public searchAttribute() {
        let condition = this.fillFormToConditionView();
        this.searchClick.emit(condition);
    }

    clearCondition() {
        this.formSetup();
        this.clearCodeCriteria();
        this.clearClick.emit();
    }

    fillFormToConditionView(): AttributeSearchConditionView {
        let formValue = this.formGroup.value;
        let condition: AttributeSearchConditionView = {
            code: formValue.code?.trim(),
            name: formValue.name?.trim(),
            domainCode: formValue.domainCode,
            mapping: formValue.mapping,
            categoryCode: formValue.categoryCode,
            groupCode: formValue.groupCode,
            typeCode: formValue.typeCode,
            statusCode: formValue.statusCode
        };
        this.setTrimedCodeAndName(formValue.code?.trim(), formValue.name?.trim())
        return condition;
    }

    private setTrimedCodeAndName(code: string, name: string) {
        this.setFormControlValue('code', code);
        this.setFormControlValue('name', name);
    }

    private getDomain(){
        this.domainReferenceService.getDomainReference()
        .subscribe(
            (response: DomainReferenceModel[]) => {
            this.domainRef$.next(response);
            }
        );
    }

    public onDomainChange(domainCode: string | string[]) {
        let newDomainCode = domainCode as string;
        this.formGroup.controls['domainCode'].setValue(newDomainCode);
        this.clearCodeCriteria();

        if (newDomainCode == AttributeSearchConstant.DOMAIN_PRODUCT_CODE) {
            this.productHidden = false;
            this.getCategory();
        }
        else if (newDomainCode == AttributeSearchConstant.DOMAIN_ORGANISATION_CODE) {
            this.organisationHidden = false;
            this.getType();
        }
        else if (newDomainCode == AttributeSearchConstant.DOMAIN_PRICE_RULE_CODE) {
            this.priceruleHidden = false;
            this.getType();
        }
        else if (newDomainCode == AttributeSearchConstant.DOMAIN_ORDER_CODE) {
            this.orderMappingHidden = false;
        }
    }

    public onStatusChange(statusCode: string | string[]){
        let newStatusCode = statusCode as string;
        this.formGroup.controls['statusCode'].setValue(newStatusCode);
    }

    public onMappingChange(mapping: string | string[]) {
        let newMapping = mapping as string;
        this.formGroup.controls['mapping'].setValue(newMapping);
        if (newMapping.toUpperCase() == AttributeSearchConstant.MAPPING_PRODUCT_CODE) {
            this.orderHidden = true;
            this.productHidden = false;
            this.getCategory();
        }
        else if (newMapping.toUpperCase() == AttributeSearchConstant.MAPPING_TYPE_CODE || newMapping.toUpperCase() == AttributeSearchConstant.MAPPING_STATE_CODE) {
            this.productHidden = true;
            this.orderHidden = false;
            this.getType();
        }
        else {
            this.productHidden = true;
            this.orderHidden = true;
            this.clearCategory();
            this.clearGroup();
            this.clearType();
        }
    }

    public getCategory() {
        this.clearCategory();
        this.clearGroup();
        this.clearType();
        let formValue = this.formGroup.value;
        if (formValue.domainCode == AttributeSearchConstant.DOMAIN_PRODUCT_CODE || 
            (formValue.domainCode == AttributeSearchConstant.DOMAIN_ORDER_CODE && formValue.mapping.toUpperCase() == AttributeSearchConstant.MAPPING_PRODUCT_CODE)) {
            this.productCategoryReferenceService.getAll()
                .subscribe(
                    (response: ProductCategoryReferenceModel[]) => {
                        let catagoryTemp = this.attributeConverter.ToProductCategoryReferenceSelect2DataList(response);
                        this.category$.next(catagoryTemp);
                    }
                );
        }
    }

    public onCategoryChange(category: string | string[]) {
        let newCategory = category as string;
        this.formGroup.controls['categoryCode'].setValue(newCategory);
        this.getGroup();
    }

    public getGroup() {
        this.clearGroup();
        this.clearType(); 
        let formValue = this.formGroup.value;
        if (formValue.domainCode == AttributeSearchConstant.DOMAIN_PRODUCT_CODE || 
            (formValue.domainCode == AttributeSearchConstant.DOMAIN_ORDER_CODE && formValue.mapping.toUpperCase()  == AttributeSearchConstant.MAPPING_PRODUCT_CODE)) {
            this.productGroupReferenceService.getByProductCategory(formValue.categoryCode)
                .subscribe(
                    (response: ProductGroupReferenceModel[]) => {
                        let groupTemp = this.attributeConverter.toProductGroupReferenceSelect2DataList(response);
                        this.group$.next(groupTemp);
                    }
                );
        }
    }

    public onGroupChange(group: string | string[]) {
        let newGroup = group as string;
        this.formGroup.controls['groupCode'].setValue(newGroup);
        this.getType();
    }

    public getType() {
        this.clearType();
        this.typeFieldTitle = "Type";
        let formValue = this.formGroup.value;
        if (formValue.domainCode == AttributeSearchConstant.DOMAIN_PRODUCT_CODE || 
            (formValue.domainCode == AttributeSearchConstant.DOMAIN_ORDER_CODE && formValue.mapping.toUpperCase()  == AttributeSearchConstant.MAPPING_PRODUCT_CODE)) {
            this.productTypeGroupService.getProductTypeGroupByProductGroupCode(formValue.groupCode)
                .subscribe(
                    (response: ProductTypeGroupModel[]) => {
                        let typeTemp = this.attributeConverter.toProductTypeRefefrenceSelect2DataList(response);
                        this.type$.next(typeTemp);
                    }
                );
        }
        else if (formValue.domainCode == AttributeSearchConstant.DOMAIN_ORGANISATION_CODE) {
            this.organisationTypeService.getByStatus(AttributeSearchConstant.STATUS_ACTIVE_CODE)
                .subscribe(
                    (response: OrganisationTypeReferenceModel[]) => {
                        let typeTemp = this.attributeConverter.toOrganisationTypeReferenceSelect2DataList(response);
                        this.type$.next(typeTemp);
                    }
                )
        }
        else if (formValue.domainCode == AttributeSearchConstant.DOMAIN_PRICE_RULE_CODE) {
            this.priceRuleTypeReferenceService.getPriceRuleTypeReferences()
                .subscribe(
                    (response: PriceRuleTypeReferenceModel[]) => {
                        let typeTemp = this.attributeConverter.toPriceRuleTypeReferenceSelect2DataList(response);
                        this.type$.next(typeTemp);
                    }
                )
        }
        else if (formValue.domainCode == AttributeSearchConstant.DOMAIN_ORDER_CODE) {
            if (formValue.mapping.toUpperCase() == AttributeSearchConstant.MAPPING_TYPE_CODE) {
                this.orderTypeReferenceService.getOrderTypeReferences()
                .subscribe(
                    (response: OrderTypeReferenceModel[]) => {
                        let typeTemp = this.attributeConverter.toOrderTypeReferenceSelect2DataList(response);
                        this.type$.next(typeTemp);
                    }
                )
            }
            else if (formValue.mapping.toUpperCase() == AttributeSearchConstant.MAPPING_STATE_CODE) {
                this.typeFieldTitle  = "Name";
                this.orderStateReferenceService.getOrderStateReference()
                .subscribe(
                    (response: OrderStateReferenceModel[]) => {
                        let typeTemp = this.attributeConverter.toOrderStateReferenceSelect2DataList(response);
                        this.type$.next(typeTemp);
                    }
                )
            }
        }
    }

    public onTypeChange(type: string | string[]) {
        let newType = type as string;
        this.formGroup.controls['typeCode'].setValue(newType);
    }

    private clearCategory() {
        this.category$ = new BehaviorSubject<any>(null);
        this.formGroup.patchValue({
            categoryCode: null
        })
    }

    private clearGroup() {
        this.group$ = new BehaviorSubject<any>(null);
        this.formGroup.patchValue({
            groupCode: null
        })
    }

    private clearType() {
        this.type$ = new BehaviorSubject<any>(null);
        this.formGroup.patchValue({
            typeCode: null
        })
    }

    private clearCodeCriteria() {
        this.clearCategory();
        this.clearGroup();
        this.clearType();
        this.clearMapping();

        this.productHidden = true;
        this.organisationHidden = true;
        this.orderHidden = true;
        this.orderMappingHidden = true;
        this.priceruleHidden = true;
    }

    private clearMapping() {
        this.formGroup.patchValue({
            mapping: null
        })
    }

    fillViewToForm(view: AttributeSearchConditionView) {
        this.fillDomain(view.domainCode);
        this.fillMapping(view.mapping);
        this.fillCategory(view.categoryCode);
        this.fillGroup(view.groupCode);
        this.formGroup.patchValue({
            code: view.code,
            name: view.name,
            typeCode: view.typeCode,
            statusCode: view.statusCode
        });

        this.searchAttribute();
    }

    fillDomain(domainCode: string) {
        if (domainCode) {
            this.formGroup.controls['domainCode'].setValue(domainCode);
            this.onDomainChange(domainCode);
        }
    }

    fillMapping(mapping: string) {
        if (mapping) {
            this.formGroup.controls['mapping'].setValue(mapping);
            this.onMappingChange(mapping);
        }
    }

    fillCategory(categoryCode: string) {
        if (categoryCode) {
            this.formGroup.controls['categoryCode'].setValue(categoryCode);
            this.getGroup();
        }
    }

    fillGroup(groupCode: string) {
        if (groupCode) {
            this.formGroup.controls['groupCode'].setValue(groupCode);
            this.getType();
        }
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchAttribute();
                break;
            default:
                break;
        }
    }

    public trimInput(input, controlName: string) {
        let trimedValue = input.target.value.trim();
        input.target.value = trimedValue
        this.setFormControlValue(controlName, trimedValue);
    }

    public setFormControlValue(formControlName: string, value: any) {
        this.formGroup.controls[formControlName].setValue(value);
    }
}

import { Injectable } from '@angular/core';

const SPLIT_COOKIES_SYMBOL: string = ";";
const SPLIT_COOKIES_KEY_VALUE_SYMBOL = "=";
const SPLIT_COOKIES_EXPIRY_TIME_SYMBOL = '{time}';

@Injectable({
    providedIn: 'root'
})
export class CookiesService {

    public getItem(key: string): string {
        var rawValue = this.getRawCookiesValue(key);
        if (!rawValue) {
            return null;
        }
        return this.getRawCookiesValue(key).split(SPLIT_COOKIES_EXPIRY_TIME_SYMBOL)[0];
    }

    private getRawCookiesValue(key: string): string {
        let cookie = {};
        document.cookie.split(SPLIT_COOKIES_SYMBOL).forEach((cookieElement) => {
            let [cookieKey, value] = cookieElement.split(SPLIT_COOKIES_KEY_VALUE_SYMBOL);
            cookie[cookieKey.trim()] = value;
        });
        if (!cookie[key]) {
            return null;
        }
        return cookie[key];
    }

    public setItem(key: string,
        value: string,
        expireTime: number,
        takeExistingExpiryDate: boolean = false): void {

        var expiryDate = this.getExpiryDate(
            (takeExistingExpiryDate) ? this.getCookiesExpiryTime(key) : null,
            expireTime);
        var cookiesValue = value + SPLIT_COOKIES_EXPIRY_TIME_SYMBOL + expiryDate;
        document.cookie = `${key}=${cookiesValue};expires='${expiryDate}'`;
    }

    private getCookiesExpiryTime(key): string {
        var cookies = this.getRawCookiesValue(key);
        if (!cookies) {
            return null;
        }
        return cookies.split(SPLIT_COOKIES_EXPIRY_TIME_SYMBOL)[1];
    }
    private getExpiryDate(expiryDateString: string, expireTime: number) {
        if (!expiryDateString || expiryDateString.length == 0) {
            let expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + expireTime);
            return expireDate.toUTCString();
        }
        return expiryDateString;
    }
    public cookiesExist(key: string): boolean {
        const cookie = this.getItem(key);
        if (cookie) {
            return true;
        }
        return false;
    }

    public deleteCookies(key: string): void {
        document.cookie = key + '=; Max-Age=0';
    }
}
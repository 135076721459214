import { Component, Input } from "@angular/core";
import { InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { CommentTypeReferenceModel, IdentityDocumentReferenceModel, MembershipTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";
import { select2CommentType } from "../../insight-detail/insight-detail-comment/shared/select2-configuration";
import { select2MembershipType } from "../../insight-detail/insight-detail-membership/shared/select2-configuration";
import { select2DocumentType } from "../../insight-detail/insight-document/shared/select2-configuration";
import { InsightPassengerDocumentSearchView } from "../shared/insight-document-search-view";

@Component({
    selector: 'op-insight-search-document',
    templateUrl: './insight-search-document.component.html'
})
export class InsightSearchDocumentComponent {   
    public documentTypeOption: any;
    public membershipTypeOption: any;
    public commentTypeOption: any;

    public focused:boolean = false;
    
    @Input() filter: InsightPassengerSearchModel = {
        individualId: null,
        searchName: null,
        firstName: null,
        lastName: null,
        middleName: null,
        gender: null,
        nationality: null,
        language: null,
        email: null,
        phoneType: null,
        phoneNumber: null,
        memberNumber: null,
        identityDocumentNumber: null,
        statusCode: null,
        usageTypeCode: null,
        city: null,
        countryCode: null,
        identityDocumentCode: null,
        commentTypeCode: null,
        membershipLevelCode: null,
        membershipLevelId: null,
        insightPassengerAgeGroups: [],
        insightPassengerNationalities: [],
        insightPassengerLanguages: [],
        insightPassengerAttributes: [],
        insightPassengerCivilStatuses: [],
        insightPassengerInterests: [],
        insightPassengerPositions: [],
        insightPassengerPreferences: [],
        insightPassengerProfessions: [],
        insightPassengerSocials: [],
        isOwnerFilter: false,
        organisationId: null,
        dateOfBirth: null
    };
    @Input() identityDocumentTypeReferences: IdentityDocumentReferenceModel[];
    @Input() commentTypeReferences: CommentTypeReferenceModel[];
    @Input() membershipTypeReferences: MembershipTypeReferenceModel[];
    @Input() membershipLevels: MembershipLevelModel[];

    public defaultDocumentFilter: InsightPassengerDocumentSearchView = { 
        commentTypeCode: null,
        identityDocumentCode: null,
        identityDocumentNumber: null,
        memberNumber: null,
        membershipLevelCode: null
    }

    constructor() {
        this.setOptionControl();
    }

    private setOptionControl() {
        this.documentTypeOption = select2DocumentType;
        this.membershipTypeOption = select2MembershipType;
        this.commentTypeOption = select2CommentType;
    }

    public membershipLevelChange(membershipLevelCode: any) {
        this.filter.membershipLevelId = this.membershipLevels?.filter(item => item.membershipLevelCode == membershipLevelCode)[0]?.membershipLevelId;
        this.filter.membershipLevelCode = membershipLevelCode;
    }

    public onValueChange(value: any, fieldName: string) {
        this.filter[fieldName] = value;
    }
}
export class MediaContentCommandModel {
    public mediaContentId: string;
    public organisationId: string;
    public languageCode: string;
    public primaryLanguageFlag: boolean;
    public mediaText: string;
    public originalFileName: string;
    public logicalLocation: string;
    public physicalLocation: string;
    public thumbnailLogicalLocation: string;
    public thumbnailPhysicalLocation: string;
    public mediaFileBase64: string;    
}
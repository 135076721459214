declare var moment: any;

export const calendarDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}

export const select2Provider = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2Location = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    focus: true,
}
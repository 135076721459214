import { Injectable } from "@angular/core";
import { InsightPassengerCommentModel } from "src/app/core/models/individual-model";
import { CommentView } from "./comment-view";

@Injectable()
export class CommentMapperService {
    public commentModelToViews(models: InsightPassengerCommentModel[]) : CommentView[] {
        let views = new Array();

        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.commentModelToView(i, model));
        }
        
        return views;
    }

    private commentModelToView(index: number, model: InsightPassengerCommentModel) : CommentView {
        let view = {} as CommentView;

        view.no = index;
        view.commentId = model.commentId;
        view.individualId = model.individualId;
        view.organisationId = model.organisationId;
        view.commentTypeCode = model.commentTypeCode;
        view.commentTypeName = model.commentTypeName;
        view.commentClassificationCode = model.commentClassificationCode;
        view.commentClassificationName = model.commentClassificationName;
        view.commentText = model.commentText;
        view.privateFlag = model.privateFlag;
        view.warningFlag = model.warningFlag;
        view.lockedFlag = model.lockedFlag;
        this.setLockFlag(view);
        return view;
    }

    private setLockFlag(view: CommentView){
        if(view.organisationId){
            view.commentLocked = true;
        }
    }

    public commentViewToModels(views: CommentView[]): InsightPassengerCommentModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.commentViewToModel(view));
        }
        return models;
    }

    private commentViewToModel(view: CommentView): InsightPassengerCommentModel {
        let model = {} as InsightPassengerCommentModel;
        model.commentId = view.commentId;
        model.individualId = view.individualId;
        model.organisationId = view.organisationId;
        model.commentTypeCode = view.commentTypeCode;
        model.commentClassificationCode = view.commentClassificationCode;
        model.commentText = view.commentText?.trim();
        model.privateFlag = view.privateFlag;
        model.warningFlag = view.warningFlag;
        model.lockedFlag = view.lockedFlag;
        return model;
    }
}
import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { cloneDeep } from "lodash";
import { MembershipTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MembershipView } from "./shared/membership-view";
import { MembershipDetailDirective } from "./detail/membership.directive";
import { InsightMembershipDetailComponent } from "./detail/membership-detail.component";
import { InsightMembershipTableComponent } from "./table/membership-table.component";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";

@Component({
    selector: 'op-insight-detail-membership',
    templateUrl: './insight-detail-membership.component.html'
})

export class InsightDetailMembershipComponent {
    @Input() membershipTypeReferences: MembershipTypeReferenceModel[];
    @Input() membershipLevels: MembershipLevelModel[];
    @Input() organisationAirlines: OrganisationModel[];
    
    public isCollapsedDetail: boolean = false;
    public isMembershipsEmpty: boolean = true;
    public isMoreThanOneMemberships: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public membership = {} as MembershipView;
    public memberships: MembershipView[] = new Array();
    public previousMemberships: MembershipView[] = new Array();

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @ViewChild(MembershipDetailDirective) membershipDetailDirective: MembershipDetailDirective;
    @ViewChild(InsightMembershipDetailComponent) membershipDetailComponent: InsightMembershipDetailComponent;
    @ViewChild(InsightMembershipTableComponent) membershipTableComponent: InsightMembershipTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newMembershipDetail: InsightMembershipDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerMemberships: MembershipView[]) {
        this.clearData();
        if (passengerMemberships && passengerMemberships.length > 0) {
            this.fillModelInCase(passengerMemberships);
        }

        this.memberships = passengerMemberships;
        this.previousMemberships = passengerMemberships;

        if (this.memberships.length > 0) {
            this.isMembershipsEmpty = false;
        }

        if (this.memberships.length == 1) {
            this.isMoreThanOneMemberships = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.membership = {} as MembershipView;
    }

    private fillModelInCase(memberships: MembershipView[]) {
        if (memberships.length == 1) {
            this.fillModelSingleRecord(memberships[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: MembershipView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.membership = view;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneMemberships = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: MembershipView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightMembershipDetailComponent);

        let viewContainerRef = this.membershipDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightMembershipDetailComponent = (<InsightMembershipDetailComponent>componentRefs.instance);
        component.membershipTypeReferences = this.membershipTypeReferences;
        component.organisationAirlines = this.organisationAirlines;
         
        if (dataEdit != null) {
            component.insightMembership = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newMembershipDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightMembership = this.membershipDetailComponent.getInsightMembership();
        if (insightMembership) {
            this.savedata(insightMembership);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewMembership() {
        this.isApplyBtnClicked = true;
        let insightMembership = this.newMembershipDetail.getInsightMembership();
        if (insightMembership) {
            this.savedata(insightMembership);
            this.showAdd = true;         
            this.displayAfterSave();
        }
    }

    private savedata(data: MembershipView) {
        if (this.memberships?.length) {
            var filterIndex = this.memberships.findIndex(x => x.no == data.no);
            if (data.primaryFlag === undefined) {
                data.primaryFlag = false;
            }

            this.updateMembershipViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryFlag = true;
            this.membership = data;
            this.memberships.push(data);
        }

        this.isMembershipsEmpty = false;
        this.membershipTableComponent.dataGrid.instance.refresh();
    }

    private updateMembershipViews(filterIndex: number, data: MembershipView) {
        if (this.memberships?.length > 0 && data.primaryFlag) {
            this.setOtherMembershipPrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.memberships.length + 1;
            this.memberships.push(data);
        }
        else {
            this.memberships[filterIndex] = data;
        }
    }

    private setOtherMembershipPrimaryFlag(filterIndex: number) {
        let otherMemberships = this.memberships;
        for (let otherMembership of otherMemberships) {
            if (otherMembership.no == filterIndex + 1) {
                continue;
            }

            otherMembership.primaryFlag = false;;
        }

        this.memberships = otherMemberships;
    }

    private displayAfterSave() {
        if (this.memberships?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.membershipDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.memberships.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        data = this.getDeleteData();

        this.setMoreThanOneMemberships();
        this.deleteDataFromMembershipViews(data);
    }

    getDeleteData(): MembershipView {
        if (this.memberships.length > 1) {
            return this.membershipTableComponent.selectedItem;
        }

        return this.membership;
    }

    private setMoreThanOneMemberships() {
        if (this.memberships.length > 1) {
            this.isMoreThanOneMemberships = true;
        } 
        else {
            this.isMoreThanOneMemberships = false;
        }
    }

    private deleteDataFromMembershipViews(data: MembershipView) {
        let views = new Array<MembershipView>();
        if (this.memberships != null) {
            views = this.memberships;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.memberships = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.memberships.length == 1) {
            this.memberships[0].primaryFlag = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForMembershipDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.memberships.length == 0 || this.memberships.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForMembershipDetailForm() {
        if (this.memberships.length == 1) {
            this.membership = this.memberships[0];
            this.showAdd = true;
        }
        else {
            this.membership = {} as MembershipView;
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.membershipTableComponent.selectedItem);
    }

    onCancel() {
        this.resetFormValidFlag();
        
        if (this.memberships.length == 0) {
            this.membership = {} as MembershipView;
        }

        if (this.memberships.length <= 1) {
            this.isMoreThanOneMemberships = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.membershipDetailComponent) {
            return this.membershipDetailComponent.setProcessing(false);
        }

        if (this.newMembershipDetail) {
            return this.newMembershipDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.memberships.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.memberships.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.memberships.length == 1) {
            let copyMembership = cloneDeep(this.memberships[0]);
            this.openCopyDetailForm(copyMembership);
            return;
        }

         if (!this.membershipTableComponent.selectedItem) {
            return;
        }

        let copyMembership = cloneDeep(this.membershipTableComponent.selectedItem);
        this.openCopyDetailForm(copyMembership);
    }

    private setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneMemberships = true;
    }

    private setCopyMembershipProperty(copyMembership: MembershipView) {
        copyMembership.no = null;
        copyMembership.primaryFlag = false;
    }

    private openCopyDetailForm(copyMembership: MembershipView) {
        this.setCopyMembershipProperty(copyMembership);
        this.openDetail();
        this.loadDetailForm(copyMembership);
    }

    public validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.memberships.length <= 0) {
            return true;
        }

        if (this.membershipDetailComponent && this.isApplyBtnClicked) {
            return this.membershipDetailComponent.isValidForm();
        }

        if (this.newMembershipDetail && this.isApplyBtnClicked) {
            return this.newMembershipDetail.isValidForm();
        }

        return true;
    }

    public getErrorMessageForm() {
        if (this.membershipDetailComponent) {
            return this.membershipDetailComponent.getErrorMessageForm();
        }

        if (this.newMembershipDetail) {
            return this.newMembershipDetail.getErrorMessageForm();
        }
    }
}
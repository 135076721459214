import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { AttributeTree } from '../../models/reference-model/reference-general-model/attribute-tree.model';
import { DomainAttributeView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/domain-attribute.view';
import { AttributeTypeReference } from '../../models/reference-model/reference-general-model/attribute-type-reference.model';
import { AttributeChoiceView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/attribute-choice.view';
import { AttributeTypeCommandView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/attribute-type-command.view';
import { AttributeSearchModel } from '../../models/attribute-type-reference-model';

@Injectable({
  providedIn: 'root'
})
export class AttributeMaintenanceService extends ApiServiceBase {
  private readonly PATH = "/system";

  constructor(httpClient: HttpClient,
    authService: AuthService) {
      super(httpClient, authService);
    }

  public getAttributesTree(): Observable<AttributeTree> {
    return this.httpGet<AttributeTree>(this.PATH + "/DomainAttribute/tree", null)
      .pipe(
        map(response => response.body)
      )
  }
/**
 * @deprecated The function will be deleted in the future. Please use function in "attribute-type-references.service.ts"
 */
  public searchAttribute(code: string, name: string, domainCode: string, mapping: string, categoryCode: string, groupCode: string, typeCode: string, statusCode: string): Observable<AttributeSearchModel[]> {
    let param = new HttpParams()
      .set("code", code)
      .set("name", name)
      .set("domainCode", domainCode)
      .set("mapping", mapping)
      .set("categoryCode", categoryCode)
      .set("groupCode", groupCode)
      .set("typeCode", typeCode)
      .set("statusCode", statusCode);
    return this.httpGet<AttributeSearchModel[]>(this.PATH + "/AttributeTypeReferences", param)
      .pipe(
        map(response => response.body)
      )
  }

  public updateAttributeStatus(code: string, status: string): Observable<HttpResponse<AttributeSearchModel>> {
    return this.httpPut<AttributeSearchModel>(this.PATH + "/AttributeTypeReferences" + "/status?attributeCode=" + code + "&statusCode=" + status, null);
  }

  public getDomainTypeAttributeByAttributeType(code: string): Observable<DomainAttributeView[]> {
    return this.httpGet<DomainAttributeView[]>(this.PATH + "/DomainAttribute/DomainTypeAttribute/" + code, null)
      .pipe(
        map(response => response.body)
      )
  }
  public getAttributeByCode(code: string): Observable<AttributeTypeReference> {
    let param = new HttpParams()
      .set("attributeCode", code);
    return this.httpGet<AttributeTypeReference>(this.PATH + "/AttributeTypeReferences/GetByCode", param)
      .pipe(
        map(response => response.body)
      )
  }

  public getDomainAttributeByAttributeType(code: string): Observable<DomainAttributeView[]> {
    return this.httpGet<DomainAttributeView[]>(this.PATH + "/DomainAttribute/" + code, null)
      .pipe(
        map(response => response.body)
      )
  }

  public getAttributeChoiceByAttributeTypeCode(code: string): Observable<AttributeChoiceView[]> {
    return this.httpGet<AttributeChoiceView[]>(this.PATH + "/AttributeChoice/AttributeType/" + code, null)
      .pipe(
        map(response => response.body)
      )
  }

  public addAttributeTypeReference(attribute: AttributeTypeCommandView) {
    return this.httpPost(this.PATH + "/AttributeTypeReferences", attribute);
  }

  public editAttributeTypeReference(attribute: AttributeTypeCommandView) {
    return this.httpPut(this.PATH + "/AttributeTypeReferences", attribute);
  }
}

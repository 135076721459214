import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { SalesBucketReferenceModel, ServiceCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ServiceReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/service-reference.model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { StringUtils } from 'src/app/shared/utils/string-utils';
import { select2Status } from '../../../shared/select2-configuration';
import { AttributeChoiceView } from '../../../shared/view/attribute-choice.view';

@Component({
    selector: 'op-attribute-choice-detail',
    templateUrl: './attribute-choice-detail.component.html'
})
export class AttributeChoiceDetailComponent extends OopsComponentFormBase implements OnChanges {
    private readonly DASH = '-';
    public disabledSave: boolean = true;
    public selectOption: any;
    public newChoice: boolean = true;

    @Input() attributeChoiceView: AttributeChoiceView = {
        attributeChoiceId: null,
        attributeChoiceCode: null,
        attributeChoiceName: null,
        attributeChoiceValue: null,
        specialServiceCode: null,
        specialServiceName: null,
        salesBucketCode: null,
        salesBucketName: null,
        serviceCategoryCode: null,
        serviceCategoryName: null,
        defaultFlag: false,
        sortSequence: null,
        commitBy: null,
        commitByName: null,
        commitDateTime: null
    };
    @Input() showAdd: boolean = true;
    @Input() attributeChoices: AttributeChoiceView[];
    @Input() serviceCategoryReference: ServiceCategoryReferenceModel[];
    @Input() serviceReference: ServiceReferenceModel[];
    @Input() salesBucketReference: SalesBucketReferenceModel[];
    @Output() onAdd = new EventEmitter();
    @Output() onCancel = new EventEmitter();
    @Output() onSave = new EventEmitter<AttributeChoiceView>();

    @ViewChild('attributeChoiceDetailForm') attributeChoiceDetailForm: NgForm;
    
    constructor(private formBuilder: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private stringUtils: StringUtils,
        private stringHelperService: StringHelperService) {
        super(formBuilder);
        this.setOptionControl();
        }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['attributeChoiceView']) {
            this.formSetup();
            this.checkNewChoiceFlag();
        }
    }

    public initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = this.formBuilder.group({
            attributeChoiceCode: [null, Validators.required],
            attributeChoiceName: [null, Validators.required],
            attributeChoiceValue: [null],
            specialServiceCode: [null],
            salesBucketCode: [null],
            serviceCategoryCode: [null],
            defaultFlag: [null],
            sortSequence: [null],
            commitByName: [{value: null, disabled: true}],
            commitDateTime: [{value: null, disabled: true}]
        })
        if (!this.attributeChoiceView) {
            return this.formGroup;
        } else {
            this.fillViewToForm(this.attributeChoiceView);
        }
    }

    fillViewToForm(view: AttributeChoiceView) {
        this.formGroup.patchValue({
            attributeChoiceCode: view.attributeChoiceCode,
            attributeChoiceName: view.attributeChoiceName,
            attributeChoiceValue: view.attributeChoiceValue,
            specialServiceCode: view.specialServiceCode,
            salesBucketCode: view.salesBucketCode,
            serviceCategoryCode: view.serviceCategoryCode,
            defaultFlag: view.defaultFlag,
            sortSequence: view.sortSequence,
            commitByName: view.commitByName,
            commitDateTime: view.commitDateTime
        })
    }

    fillFormToView(): AttributeChoiceView {
        let formValue = this.formGroup.value;
        let view: AttributeChoiceView = {
            attributeChoiceId: this.attributeChoiceView.attributeChoiceId ?? this.stringHelperService.NewGuid(),
            attributeChoiceCode: formValue.attributeChoiceCode,
            attributeChoiceName: formValue.attributeChoiceName,
            attributeChoiceValue: formValue.attributeChoiceValue,
            specialServiceCode: formValue.specialServiceCode,
            specialServiceName: this.getServiceName(formValue.specialServiceCode),
            salesBucketCode: formValue.salesBucketCode,
            salesBucketName: this.getSalesBucketName(formValue.salesBucketCode),
            serviceCategoryCode: formValue.serviceCategoryCode,
            serviceCategoryName: this.getServiceCategoryName(formValue.serviceCategoryCode),
            sortSequence: formValue.sortSequence,
            defaultFlag: formValue.defaultFlag ?? false,
            commitBy: null,
            commitByName: null,
            commitDateTime: null
        }
        return view;
    }

    checkNewChoiceFlag() {
        if (this.newChoice === false) {
            this.formGroup.controls['attributeChoiceCode'].disable();
        } else {
            this.formGroup.controls['attributeChoiceCode'].enable();
        }
    }

    private setOptionControl() {
        this.selectOption = select2Status;
    }

    getAttributeChoice(): AttributeChoiceView {
        this.startProcessing();
        if (this.validForm()) {
            this.completeProcessing();
            return this.fillFormToView();
        }
        this.changeDetectorRef.detectChanges();
        return null;
    }

    public cancel() {
        this.attributeChoiceView = {
            attributeChoiceId: null,
            attributeChoiceCode: null,
            attributeChoiceName: null,
            attributeChoiceValue: null,
            specialServiceCode: null,
            specialServiceName: null,
            salesBucketCode: null,
            salesBucketName: null,
            serviceCategoryCode: null,
            serviceCategoryName: null,
            defaultFlag: false,
            sortSequence: null,
            commitBy: null,
            commitByName: null,
            commitDateTime: null
        };
    }

    public isSaveDisable(form: NgForm): boolean {
        if (!form.valid) {
            return true;
        }
        return false;
    }

    private getServiceCategoryName(serviceCategoryCode: string): string {
        return this.serviceCategoryReference?.filter(item => item.serviceCategoryCode == serviceCategoryCode)[0]?.serviceCategoryName;
    }

    private getServiceName(serviceCode: string): string {
        return this.serviceReference?.filter(item => item.serviceCode == serviceCode)[0]?.serviceName;
    }

    private getSalesBucketName(salesBucketCode: string): string {
        return this.salesBucketReference?.filter(item => item.salesBucketCode == salesBucketCode)[0]?.salesBucketName;
    }

    public onChoiceCodeChange(event) {
        let newCode: string = this.formGroup.controls['attributeChoiceCode'].value;
        this.checkCodeDuplicate();
        if (newCode != null) {
            this.formGroup.controls['attributeChoiceCode'].setValue(newCode.trim().toUpperCase());
        }
        if (this.stringUtils.validateSpecialCharacterExcept(newCode, this.DASH)) {
            this.formGroup.controls['attributeChoiceCode'].setErrors({specialCharacter: true});
        } else if (newCode.startsWith(this.DASH) || newCode.endsWith(this.DASH)) {
            this.formGroup.controls['attributeChoiceCode'].setErrors({dash: true});
        }
    }

    checkCodeDuplicate() {
        let choiceCode = this.formGroup.controls['attributeChoiceCode'].value;
        if (this.attributeChoices?.findIndex(item => item.attributeChoiceCode == choiceCode) != -1) {
            this.formGroup.controls['attributeChoiceCode'].setErrors({duplicate: true});
            this.formGroup.controls['attributeChoiceCode'].markAsTouched();
        }
    }

    public valueChange(value, name) {
        this.formGroup.get(name).setValue(value);
    }

}

import moment from "moment"

export const counterSelectionCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    allowClear: true,
    startDate: moment(),
    endDate: moment(),
    maxDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
}

export const select2Search = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}
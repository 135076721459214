import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { VoucherSearchView } from '../../shared/voucher-search.view';
import { VoucherService } from 'src/app/core/services/product-services/voucher.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { VoucherSearchCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-search-command.model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';

@Component({
    selector: 'op-voucher-search-table',
    templateUrl: './voucher-search-table.component.html'
})
export class VoucherSearchTableComponent implements AfterViewInit {
    public readonly EXPORT_FILE_NAME = 'VoucherSearchResult'
    public readonly SEARCH_TAB_ID = 'search';
    public readonly FILTER_USAGE_TYPE = 'FILTER';
    public readonly DATA_USAGE_TYPE = 'DATA';
    public focusing: boolean = false;
    public selectedItem: any;
    public rows = 0;
    public exportData: any;
    public header: string[];

    public voucherSearchResults: any;
    public pagingDatas: PagingDataView[];

    @Input() selectedProductCategory: ProductCategory;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() statusReference: StatusReferenceModel[];
    @Output() onSelectVoucher = new EventEmitter();
    @Output() onFilterSelect = new EventEmitter<string>();
    @Output() onCopyFilter = new EventEmitter<string>();

    @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
    
    constructor(private changeDetector: ChangeDetectorRef,
        private navigationService: NavigationService,
        private voucherService: VoucherService,
        private loadingSpinner: LoadingSpinnerService) { }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public onRowSelected(e) { 
        this.selectedItem = e.data;
        this.onSelectVoucher.emit(this.selectedItem);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = filteredData;
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    get deleteFlag() {
        return this.userSecurity?.deleteFlag ?? false;
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    private getVoucherSearchResult(vouchers: VoucherSearchView[]) {
        let datas = new Array();
        this.pagingDatas = [];
        let index = 1;
        for (let voucher of vouchers) {
            let data = {};
            data['no'] = index;
            data['voucherId'] = voucher.voucherId;
            data['groupCode'] = voucher.productGroupCode;
            data['groupName'] = voucher.productGroupName;
            data['typeCode'] = voucher.productTypeCode;
            data['typeName'] = voucher.productTypeName;
            data['productName'] = voucher.voucherName;
            data['organisationName'] = voucher.organisationName;
            data['usageType'] = voucher.usageTypeName;
            data['statusCode'] = voucher.statusCode;
            datas.push(data);
            this.addToPagingDatas(index, voucher.voucherId);
            index++;
        }
        this.voucherSearchResults = datas;
        this.changeDetector.detectChanges();
    }

    public newVoucherDetail() {
        let params = {
            newVoucher: true,
            tab: this.SEARCH_TAB_ID,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate('main/product/voucher/details', null, false, params);
    }

    public onDoubleClick(e) {
        let data = e.data;
        switch (data.usageType.toUpperCase()) {
            case this.FILTER_USAGE_TYPE:
                this.onFilterSelect.emit(data.voucherId);
                break;
            default: 
                this.openVoucherDetail(data.voucherId, data.no);
                break;
        }
    }

    public openVoucherDetail(voucherId: string, voucherIndex: number) {
        let params = {
            newVoucher: false,
            tab: this.SEARCH_TAB_ID,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity,
            voucherId: voucherId,
            pagingDatas: this.pagingDatas,
            currentIndex: voucherIndex
        }
        this.navigationService.navigate('main/product/voucher/details', null, false, params);
    }

    public searchVoucher(condition: VoucherSearchCommandModel) {
        this.loadingSpinner.show();
        this.voucherService.searchVoucher(condition)
            .subscribe(
                (responses: VoucherSearchView[]) => {
                    this.getVoucherSearchResult(responses);
                    this.loadingSpinner.hide();
                    this.changeDetector.detectChanges();
                },
                () => this.loadingSpinner.hide()
            )
    }

    public updateVoucherStatus(voucher: VoucherSearchView, statusCode: string) {
        this.loadingSpinner.show();
        this.voucherService.updateVoucher(voucher.voucherId, statusCode)
            .subscribe(
                () => {
                    this.loadingSpinner.saveComplete();
                    this.changeDetector.detectChanges();
                },
                () => this.loadingSpinner.hide()
                
            )
    }

    public clearResult() {
        this.voucherSearchResults = null;
        this.dataGrid.instance.clearFilter();
    }

    public editClick() {
        if (!this.selectedItem) {
            return;
        }
        switch (this.selectedItem.usageType.toUpperCase()) {
            case this.FILTER_USAGE_TYPE:
                this.onFilterSelect.emit(this.selectedItem.voucherId);
                break;
            default:
                this.openVoucherDetail(this.selectedItem.voucherId, this.selectedItem.no);
                break;
        }
        
    }

    public copyClick() {
        if (!this.selectedItem) {
            return;
        }
        switch (this.selectedItem.usageType.toUpperCase()) {
            case this.FILTER_USAGE_TYPE:
                this.onCopyFilter.emit(this.selectedItem.voucherId);
                break;
            default:
                this.copyVoucher(this.selectedItem.voucherId);
                break;
        }
    }

    public copyVoucher(voucherId: string) {
        let params = {
            newVoucher: false,
            tab: this.SEARCH_TAB_ID,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity,
            voucherId: voucherId,
            copyVoucher: true
        }
        this.navigationService.navigate('main/product/voucher/details', null, false, params);
    }

    public addToPagingDatas(index: number, voucherId: string) {
        let paginingData  = new PagingDataView();
        paginingData.no = index;
        paginingData.id = voucherId;
        this.pagingDatas.push(paginingData);
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, share } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ActivitySearchCriteriaView } from "src/app/modules/activity/shared/views/activity-search-criteria.view";
import { Observable } from "rxjs";
import { ActivitySearchResultView } from "src/app/modules/activity/shared/views/activity-search-result.view";

@Injectable({
    providedIn: 'root'
})
export class ActivitySearchService extends ApiServiceBase {
    private readonly ACTIVITY_SEARCH_PATH = '/activity/search';

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {
        super(httpClient, authService);
    }

    public searchByCriteria(criteria: ActivitySearchCriteriaView): Observable<ActivitySearchResultView[]> {
        return this.httpPost<ActivitySearchResultView[]>(this.ACTIVITY_SEARCH_PATH, criteria)
            .pipe(map((response) => {
              return response.body;
            }),
            share()
          );
    }

    public updateActivityStatus(activityId: string, newStatus: string): Observable<unknown> {
        return this.httpPut<unknown>(`${this.ACTIVITY_SEARCH_PATH}/${activityId}/activitystatus`, {newStatus})
            .pipe(map((response) => {
              return response.body;
            })
          );
    }
}
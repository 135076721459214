export class OrganisationCommunicationOtherModel {
    public communicationOtherId: string;
    public communicationOtherTypeCode: string;
    public communicationOtherTypeName: string;
    public individualId: string;
    public languageCode: string;
    public languageName: string;
    public mailAddress: string;
    public logon: string;
    public password: string;
    public token: string;
    public commentText: string;
    public primaryFlag: boolean;
    public communicationPhonePrefix: string;
}
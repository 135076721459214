import { Injectable } from '@angular/core';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { ProductInventoryCommandModel } from 'src/app/core/models/product-model/product-base-model/product-inventory';
import { ProductDateTimeCommandModel, ProductInventorySalesBucketViewModel } from 'src/app/core/models/product-model/transport-model';
import { SalesBucketTableView } from './salesbucket-table.view';

@Injectable()
export class SalesBucketMapperService {
    private readonly FIRM_OPEN_HOURS = "FIRMOPENHOURS";
    private readonly FIRM_CLOSE_HOURS = "FIRMCLOSEHOURS";
    private readonly WAIT_LIST_OPEN_HOURS = "WAITLISTOPENHOURS";
    private readonly WAIT_LIST_CLOSE_HOURS = "WAITLISTCLOSEHOURS";

    constructor(private dateConverterService: DateConverterService) {}

    public salesBucketModelToViews(models: ProductInventorySalesBucketViewModel[]): SalesBucketTableView[] {
        let views: SalesBucketTableView[] = new Array();
        if (models) {
            let i = 0;
            let noSegment = 0;
            for (let model of models) {
                ++i;
                let segmentExist = this.segmentExist(model.sortSequenceFrom, model.sortSequenceTo, views);
                if (!segmentExist?.length) {
                    ++noSegment;
                }
                views.push(this.salesBucketModelToView(i, noSegment, segmentExist, model))
            }
        }
        return views;
    }

    private salesBucketModelToView(index: number, noSegment: number, segmentExist: SalesBucketTableView[], model: ProductInventorySalesBucketViewModel): SalesBucketTableView {
        let view = {} as SalesBucketTableView;
        if (segmentExist?.length) {
            view.noSegment = segmentExist[0].noSegment;
            view.segmentName = segmentExist[0].segmentName;
            view.segmentNameWithNo = segmentExist[0].segmentNameWithNo;
            view.sortSequenceFrom = segmentExist[0].sortSequenceFrom;
            view.sortSequenceTo = segmentExist[0].sortSequenceTo;
        } else {
            view.noSegment = noSegment;
            view.segmentName = this.createSegmentName(model.locationNameFrom, model.locationNameTo);
            view.segmentNameWithNo = this.createSegmentNameWithNo(noSegment, model.locationNameFrom, model.locationNameTo);
            view.sortSequenceFrom = model.sortSequenceFrom;
            view.sortSequenceTo = model.sortSequenceTo;
        }

        view.no = index;
        view.serviceCategory = model.serviceCategoryName;
        view.productInventoryIdFirm = model.productInventoryIdFirm;
        view.productInventoryIdWaitList = model.productInventoryIdWaitList;
        view.productDateTimeIdFirmOpen = model.productDateTimeIdFirmOpen;
        view.productDateTimeIdFirmClose = model.productDateTimeIdFirmClose;
        view.productDateTimeIdWaitListOpen = model.productDateTimeIdWaitListOpen;
        view.productDateTimeIdWaitListClose = model.productDateTimeIdWaitListClose;
        view.salesBucket = model.salesBucketCode;
        view.capacityFirm = model.sellCapacityFirm ? model.sellCapacityFirm : 0;
        view.openhoursFirm = this.getHour(model.localDateTimeFirmOpen);
        view.closehoursFirm = this.getHour(model.localDateTimeFirmClose);
        view.capacityWaitlist = model.requestCapacityWaitList ? model.requestCapacityWaitList : 0;
        view.openhoursWaitlist = this.getHour(model.localDateTimeWaitListOpen);
        view.closehoursWaitlist = this.getHour(model.localDateTimeWaitListClose);
        view.sortSequenceServiceCate = model.sortSequenceServiceCate;
        view.sortSequenceSalesBucket = model.sortSequenceSalesBucket;
        view.serviceCategorySort = view.sortSequenceServiceCate + ":" + view.serviceCategory;
        view.salesBucketSort = view.sortSequenceSalesBucket + ":" + view.salesBucket;
        view.serviceCategoryCode = model.serviceCategoryCode;
        view.serviceCategoryColor = model.serviceCategoryColor;
        view.productId = model.productId;
        return view;
    }

    private segmentExist(sortSequenceFrom: number, sortSequenceTo: number, views: SalesBucketTableView[]): SalesBucketTableView[] {
        return views.filter(x => x.sortSequenceFrom == sortSequenceFrom && x.sortSequenceTo == sortSequenceTo);
    }

    private createSegmentName(locationNameFrom: string, locationNameTo: string): string {
        return locationNameFrom + " - " + locationNameTo;
    }

    private createSegmentNameWithNo(no: number, locationNameFrom: string, locationNameTo: string): string {
        return no.toString() + ":" + locationNameFrom + " - " + locationNameTo;
    }

    private getHour(localDateTimeFirmClose: Date): number {
        if (!localDateTimeFirmClose) {
            return 0;
        }
        return this.dateConverterService.convertDateTimeToHour(new Date(localDateTimeFirmClose));
    }

    public salesBucketViewToProductInventoryModels(models: ProductInventoryCommandModel[], views: SalesBucketTableView[]): ProductInventoryCommandModel[] {
        if (views) {
            for (let view of views) {
                models.push(this.salesBucketViewToModel(view));
            }
        }
        return models;
    }

    private salesBucketViewToModel(view: SalesBucketTableView): ProductInventoryCommandModel {
        let model = new ProductInventoryCommandModel();
        model.productInventoryId = view.productInventoryIdFirm;
        model.sellCapacity = view.capacityFirm;
        model.requestCapacity = view.capacityWaitlist;
        return model;
    }

    public salesBucketViewToProductDateTimeModels(views: SalesBucketTableView[]): ProductDateTimeCommandModel[] {
        let  models: ProductDateTimeCommandModel[] = new Array();
        if (views) {
            for (let view of views) {
                models.push(this.productDateTimeOpenhoursFirm(view));
                models.push(this.productDateTimeClosehoursFirm(view));
                models.push(this.productDateTimeOpenhoursWaitlist(view));
                models.push(this.productDateTimeClosehoursWaitlist(view))
            }
        }
        return models;
    }

    private productDateTimeOpenhoursFirm(view: SalesBucketTableView): ProductDateTimeCommandModel {
        let model = new ProductDateTimeCommandModel();
        model.productDateTimeId = view.productDateTimeIdFirmOpen;
        model.localDateTime = this.dateConverterService.convertNumberToMinDateWithTime(view.openhoursFirm);
        model.productId = view.productId;
        model.dateTimeCode = this.FIRM_OPEN_HOURS;
        return model;
    }

    private productDateTimeClosehoursFirm(view: SalesBucketTableView): ProductDateTimeCommandModel {
        let model = new ProductDateTimeCommandModel();
        model.productDateTimeId = view.productDateTimeIdFirmClose;
        model.localDateTime = this.dateConverterService.convertNumberToMinDateWithTime(view.closehoursFirm);
        model.productId = view.productId;
        model.dateTimeCode = this.FIRM_CLOSE_HOURS;
        return model;
    }

    private productDateTimeOpenhoursWaitlist(view: SalesBucketTableView): ProductDateTimeCommandModel {
        let model = new ProductDateTimeCommandModel();
        model.productDateTimeId = view.productDateTimeIdWaitListOpen;
        model.localDateTime = this.dateConverterService.convertNumberToMinDateWithTime(view.openhoursWaitlist);
        model.productId = view.productId;
        model.dateTimeCode = this.WAIT_LIST_OPEN_HOURS;
        return model;
    }

    private productDateTimeClosehoursWaitlist(view: SalesBucketTableView): ProductDateTimeCommandModel {
        let model = new ProductDateTimeCommandModel();
        model.productDateTimeId = view.productDateTimeIdWaitListClose;
        model.localDateTime = this.dateConverterService.convertNumberToMinDateWithTime(view.closehoursWaitlist);
        model.productId = view.productId;
        model.dateTimeCode = this.WAIT_LIST_CLOSE_HOURS;
        return model;
    }
}

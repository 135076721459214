import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocationDetail, LocationReference, LocationReferenceSearchCriteria, LocationReferenceSearchResult, LocationSort, LocationUpdateSortSequence } from "../../models/location-model";
import { DomainAttribute } from "../../components/domain-attribute/domain-attribute-panel";

@Injectable({
    providedIn: 'root'
})
export class LocationReferenceService extends ApiServiceBase {

    private readonly Path = "/system/locationreferences"

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) {
        super(httpClient, authService);
    }

    public getLocationByCode(locationCode: string): Observable<LocationReference> {
        return this.httpGet<LocationReference>(`${this.Path}/locationCode/${locationCode}`, null)
            .pipe(
                map(res => res.body)
            )
    }


    public getLocationReferenceSearch(criteria: LocationReferenceSearchCriteria): Observable<LocationReferenceSearchResult[]> {
        const queryParams = new HttpParams({ fromObject: <any>criteria })
        return this.httpGet<LocationReferenceSearchResult[]>(`${this.Path}/search`, queryParams)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationReferenceSort(): Observable<LocationSort[]> {
        return this.httpGet<LocationSort[]>(`${this.Path}/sort`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationDetail(locationId: string): Observable<LocationDetail> {
        return this.httpGet<LocationDetail>(`${this.Path}/${locationId}/detail`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationDetailLite(): Observable<LocationDetail> {
        return this.httpGet<LocationDetail>(`${this.Path}/detail`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatusCode(locationId: string, statusCode: string): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${locationId}/statusCode`, { statusCode })
            .pipe(
                map(res => res.body)
            )
    }

    public updateSortSequence(items: LocationUpdateSortSequence[]): Observable<never> {
        return this.httpPut<never>(`${this.Path}/sort`, items)
            .pipe(
                map(res => res.body)
            )
    }

    public createLocationgrouplocations(locationCode: string,
        locationName: string,
        countryCode: string,
        stateId: string,
        timezoneCode: string,
        longitude: string,
        latitude: string,
        status: string,
        locationGroupIdForMapped: string[],
        locationGroupIdForUnmapped: string[],
        locationAttributes: DomainAttribute[]): Observable<string> {
        return this.httpPost<string>(`${this.Path}/locationgrouplocations`,
            {
                locationCode,
                locationName,
                countryCode,
                stateId,
                timezoneCode,
                longitude,
                latitude,
                status,
                locationGroupIdForMapped,
                locationGroupIdForUnmapped,
                locationAttributes
            })
            .pipe(
                map(res => res.body)
            )
    }

    public updateLocationgrouplocations(locationId: string,
        locationCode: string,
        locationName: string,
        countryCode: string,
        stateId: string,
        timezoneCode: string,
        longitude: string,
        latitude: string,
        status: string,
        locationGroupIdForMapped: string[],
        locationGroupIdForUnmapped: string[],
        locationAttributes: DomainAttribute[]): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${locationId}/locationgrouplocations`,
            {
                locationCode,
                locationName,
                countryCode,
                stateId,
                timezoneCode,
                longitude,
                latitude,
                status,
                locationGroupIdForMapped,
                locationGroupIdForUnmapped,
                locationAttributes
            })
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationReferenceList(): Observable<LocationReference[]> {
        return this.httpGet<LocationReference[]>(`${this.Path}`, null)
            .pipe(
                map(res => res.body)
            )
    }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { FinancePanelConstant, FinanceTabConstant } from '../../shared';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { FinanceCategoriesComponent } from './finance-categories/finance-categories.component';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActionService } from 'src/app/core/utils/action.service';
import { SecurityPaymentCodes } from '../../payment-search';

@Component({
    selector: 'op-finance-home',
    templateUrl: './finance-home.component.html',
    providers: [ActionbarService],
})
export class FinanceHomeComponent implements OnDestroy, OnInit {
    private readonly CASHBOOK_SECURITY_CODES = ['CASHBOOK', 'CASHBOOKALL', 'CASHBOOKCATALOG'];
    private readonly COUNTERSALES_SECURITY_CODES = ['COUNTERSALES', 'COUNTERSALESALL', 'COUNTERSALESCATALOG'];
    private readonly CREDITACCOUNT_SECURITY_CODES = ['CREDITACCOUNT', 'CREDITACCOUNTALL', 'CREDITACCOUNTCATALOG'];
    public cancelButton = new CancelButtonModel();
    public copyButton = new CopyButtonModel();
    public newButton = new NewButtonModel();
    public refreshButton = new RefreshButtonModel();
    public saveButton = new SaveButtonModel();
    private unsubscribe$ = new Subject();
    public selectedTab: string = FinanceTabConstant.CategoryTab;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );
    public activeTabId: string;
    public menuSelect: string;
    public securityCategoriesItem: Map<string, SecurityGroupSecurityModel>;

    @ViewChild("financeCategories") financeCategories: FinanceCategoriesComponent;

    constructor(private actionbarService: ActionbarService,
        private navigateService: NavigationService,
        private securityGroupService: SecurityGroupService,
        private changeDetectorRef: ChangeDetectorRef,
        private actionService: ActionService,) { }

    ngOnInit(): void {
        this.getUserSecurity();
    }

    ngAfterViewInit(): void {
        this.createActionBarButton();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.back:
                        break;
                    case ACTION_STATUS.cancel:
                        if (this.selectedTab != FinanceTabConstant.StatusTab) {
                            this.selectedTab = FinanceTabConstant.StatusTab;
                        }
                    case ACTION_STATUS.refresh:
                        this.financeCategories.loadCategories();
                        break;
                }
            }
        )
        this.actionService.add(null, null, null)
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private createActionBarButton() {
        this.newButton.disable();
        this.copyButton.disable();
        this.saveButton.disable();
        this.cancelButton.disable();
        let actionbarDefaultState = new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        );
        this.actionbarService.updateState(actionbarDefaultState);
    }

    public onCategoryDoubleClick(item: string) {
        this.onCategoryContextMenuClick(item);
    }

    public onCategoryContextMenuClick(item: string) {
        switch (item) {
            case FinancePanelConstant.Cashbook:
                this.gotoSearchCashbook();
                break;
            case FinancePanelConstant.CounterSale:
                this.gotoCounterSales();
                break;
            case FinancePanelConstant.CashbookSearch:
                this.gotoSearchCashbook();
                this.activeTabId = FinanceTabConstant.SearchlTab;
                break;
            case FinancePanelConstant.CounterSaleSearch:
                this.gotoCounterSales();
                this.activeTabId = FinanceTabConstant.SearchlTab;
                break;
            case FinancePanelConstant.CreditAccountSearch:
            case FinancePanelConstant.CreditAccount:
                this.gotoCreditAccount();
                this.activeTabId = FinanceTabConstant.SearchlTab;
                break;
            case FinancePanelConstant.PaymentSearch:
                this.gotoPaymentSearch();
                this.activeTabId = FinanceTabConstant.SearchlTab;
            default:
                break;
        }
    }

    onRowSelected(item: string) {
        this.menuSelect = item;
    }

    onTabChange(activeId: string) {
        this.selectedTab = activeId;
        this.onSelectedTabChange(this.selectedTab);
    }

    public onSelectedTabChange(activeTabId: string) {
        if (activeTabId == FinanceTabConstant.SearchlTab) {
            switch (this.menuSelect) {
                case FinancePanelConstant.Cashbook:
                    this.gotoSearchCashbook();
                    break;
                case FinancePanelConstant.CounterSale:
                    this.gotoCounterSales();
                    break;
                case FinancePanelConstant.PaymentSearch:
                    this.gotoPaymentSearch();
                    break;
                default:
                    break;
            }
        }
    }

    private gotoSearchCashbook() {
        let params = {
            userSecurity: this.securityCategoriesItem.get(FinancePanelConstant.Cashbook)
        }
        this.navigateService.navigate("main/cashbook/cashbook", null, false, params);
    }

    private gotoCounterSales() {
        let params = {
            userSecurity: this.securityCategoriesItem.get(FinancePanelConstant.CounterSale)
        }
        this.navigateService.navigate("main/cashbook/counter-salse", null, false, params);
    }

    private gotoCreditAccount() {
        this.navigateService.navigate("main/cashbook/credit-account", null, false);
    }

    private gotoPaymentSearch() {
        let params = {
            fromFinanceHome: true
        };
        this.navigateService.navigate("main/cashbook/payment", null, false, params);
    }

    private getUserSecurity() {
        this.securityCategoriesItem = new Map()
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (securityModels: SecurityGroupSecurityModel[]) => {
                    this.addSecurityToMappingList(securityModels)
                    this.changeDetectorRef.detectChanges()
                    this.financeCategories.loadCategories()
                }
            )
    }

    private addSecurityToMappingList(securityModels: SecurityGroupSecurityModel[]) {
        const cashbookSecurity = securityModels.find(item => this.CASHBOOK_SECURITY_CODES.includes(item.securityCode))
        if (cashbookSecurity) {
            this.securityCategoriesItem.set(FinancePanelConstant.Cashbook, cashbookSecurity)
        }
       
        const counterSalesSecurity = securityModels.find(item => this.COUNTERSALES_SECURITY_CODES.includes(item.securityCode))
        if (counterSalesSecurity) {
            this.securityCategoriesItem.set(FinancePanelConstant.CounterSale, counterSalesSecurity)
        }

        const paymentSearchSecurity = securityModels.find(security => SecurityPaymentCodes.includes(security.securityCode))
        if (paymentSearchSecurity) {
            this.securityCategoriesItem.set(FinancePanelConstant.PaymentSearch, paymentSearchSecurity)
        }

        const creditAccountSecurity = securityModels.find(security => this.CREDITACCOUNT_SECURITY_CODES.includes(security.securityCode));
        if (creditAccountSecurity) {
            this.securityCategoriesItem.set(FinancePanelConstant.CreditAccount, creditAccountSecurity)
        }
    }
}

import { Injectable } from '@angular/core';
import { ScheduleDistributionSearchModel } from 'src/app/core/models/schedule-distribution-model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import ScheduleDistributionSearchView from './schedule-distribution-search.view';

@Injectable()
export class MapperService {
    constructor(private dateConverterService: DateConverterService) {
    }

    public toScheduleDistrbutionSearchView(model: ScheduleDistributionSearchModel, index: number): ScheduleDistributionSearchView {
        return {
            no: index,
            scheduleQueueId: model.scheduleQueueId,
            productId: model.productId,
            autoProcessFlag: model.autoProcessFlag,
            scheduledStartDateTime: this.dateConverterService.dateTimeShortDateWithMonthNameFormat(model.scheduledStartDateTime)?.toUpperCase(),
            scheduleQueueActionCode: model.scheduleQueueActionCode,
            scheduleQueueResultCode: model.scheduleQueueResultCode,
            scheduleQueueResultText: model.scheduleQueueResultText,
            processStartDateTime: this.dateConverterService.convertDateTime24(model.processStartDateTime),
            processEndDateTime: this.dateConverterService.convertDateTime24(model.processEndDateTime),
            commitDateTime: model.commitDateTime,
            scheduleQueueActionName: model.scheduleQueueActionName,
            scheduleQueueResultName: model.scheduleQueueResultName,
            userName: model.userName,
            productScheduleCount: model.productScheduleCount,
            productScheduledDepartureTime: this.dateConverterService.convertTime24(model.productScheduledDepartureTime),
            productScheduledArrivalTime: this.dateConverterService.convertTime24(model.productScheduledArrivalTime),
            productNumberId: model.productNumberId,
            originLocationId: model.originLocationId,
            destinationLocationId: model.destinationLocationId,
            productValidityId: model.productValidityId,
            vehicleTypeCode: model.vehicleTypeCode,
            productNumber: model.productNumber,
            providerId: model.providerId,
            organisationCallName: model.organisationCallName,
            providerIataCode: model.providerIataCode,
            originLocationCode: model.originLocationCode,
            originLocationName: model.originLocationName,
            destinationLocationCode: model.destinationLocationCode,
            destinationLocationName: model.destinationLocationName,
            scheduledEndDateTime: this.dateConverterService.dateTimeShortDateWithMonthNameFormat(model.scheduledEndDateTime)?.toUpperCase(),
            mondayFlag: model.mondayFlag,
            tuesdayFlag: model.tuesdayFlag,
            wednesdayFlag: model.wednesdayFlag,
            thursdayFlag: model.thursdayFlag,
            fridayFlag: model.fridayFlag,
            saturdayFlag: model.saturdayFlag,
            sundayFlag: model.sundayFlag,
            compositionCode: model.compositionCode,
            compositionName: model.compositionName,
            weekdays: model.weekdays,
            processStatusCode: model.processStatusCode,
            productSchedulePeriodId: model.productSchedulePeriodId,
            productScheduleVersionId: model.productScheduleVersionId,
            vehicleCompositionId: model.vehicleCompositionId,
            scheduleQueueProgressCode: model.scheduleQueueProgressCode,
            productNumberDisplay: model.providerIataCode + ' ' + model.productNumber,
            scheduleTasks: model.scheduleTasks,
            distributeOrganisationCallName: model.distributeOrganisationCallName,
            distributeOrganisationId: model.distributeOrganisationId,
            numberOfProducts: model.numberOfProducts
        };
    }
}
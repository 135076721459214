import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import { HashtagComponent } from './hashtag/hashtag.component';
import { NumberComponent } from './number/number.component';
import { AttributeComponent } from './attribute/attribute.component';
import { InventoryRuleComponent } from './inventory-rule/inventory-rule.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { ValidityComponent } from './validity/validity.component';
import { OriginDestinationComponent } from './origin-destination/origin-destination.component';

import { HashTagMapperService } from '../../../../shared/mapper/hashtag-mapper.service';

import { ProductNumberTypeReferenceModel } from '../../../../../../../core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, VehicleCompositionModel, VehicleModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductHashTagView } from '../../../../shared/views/product-hashtag.view';
import { VehicleComponent } from './vehicle/vehicle.component';
import { cloneDeep } from "lodash";
import { PointOfSalesModel, ValidityModel } from 'src/app/core/models/merchandizing-config';

declare var $: any;

@Component({
    selector: 'op-attribute-and-rule',
    templateUrl: './attribute-and-rule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HashTagMapperService]
})
export class AttributeAndRuleComponent {

    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() rootLevelWithProductPoint: boolean = false;
    @Input() draftFlag: boolean = true;
    @Input() departureLevel: boolean = false;
    @Input() arrivalLevel: boolean = false;
    @Input() rootWithOD: boolean = false;
    @Input() searchMode: boolean = false;
    @Input() panelTitle = 'ATTRIBUTES AND RULES';
    @Input() panelIcon = 'fal fa-calendar-check';
    @Input() showHashtagRow: boolean = false;
    @Input() isRoot: boolean = true;
    
    //Hashtag
    @Input() hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    @Input() productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    @Output() hashTagValueReturn = new EventEmitter<ProductHashTagView[]>();

    //Product number
    @Input() productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() productTypeCode: string;
    @Output() productNumber = new EventEmitter<string>();
    @Output() providerId = new EventEmitter<string>();
    @Output() supplierId = new EventEmitter<string>();

    //Point of sales
    @Input() conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];

    //Product validity
    @Input() calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    @Input() vehicleTypeReference$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    @Input() vehicle$ = new BehaviorSubject<VehicleModel[]>(null);
    @Input() productGroupCode: string;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() domainAttributeOrgDescSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    @Input() validityConfig: ValidityModel;
    @Input() pointOfSalesConfig: PointOfSalesModel;
    @Output() focusEmit = new EventEmitter<any>();
    @Output() srvCategoryValid = new EventEmitter<boolean>();
    @Output() salesBucketValid = new EventEmitter<boolean>();
    @Output() routeValid = new EventEmitter<boolean>();
    @Output() validityFormStatus = new EventEmitter<boolean>();

    @ViewChild(HashtagComponent) productHashTagComponent: HashtagComponent;
    @ViewChild(NumberComponent) productNumberComponent: NumberComponent;
    @ViewChild(AttributeComponent) attributeComponent: AttributeComponent;
    @ViewChild(InventoryRuleComponent) inventoryRuleComponent: InventoryRuleComponent;
    @ViewChild(PointOfSalesComponent) pointOfSalesComponent: PointOfSalesComponent;
    @ViewChild(ValidityComponent) validityRuleComponent: ValidityComponent;
    @ViewChild(OriginDestinationComponent) originDestinationComponent: OriginDestinationComponent;
    @ViewChild(VehicleComponent) vehicleComponent: VehicleComponent;

    constructor(private hashtagMapperService: HashTagMapperService) {
    }

    public hashtagValueReturn(value) {
        let productHashTag = this.hashtagMapperService.productHashTagValueToViews(value, this.productHashTag$.value);
        this.clearAllHashtags(productHashTag);
        this.hashTagValueReturn.emit(productHashTag);
    }

    focusFunction() {
        this.focusEmit.emit(true);
    }

    public clearForm() {
        this.productHashTagComponent.setShowHashtagRow(false);
        this.validityRuleComponent.limitationFlag = false;
        this.validityRuleComponent.detectChanges();
        this.pointOfSalesComponent.detectChanges();
    }

    private clearAllHashtags(productHashTags: ProductHashTagView[]) {
        if (this.productHashTag$.value && !productHashTags?.length) {
            let hashTags = cloneDeep(this.productHashTag$.value);
            hashTags.forEach(hasTag => { hasTag.productHashTagId = null });
            this.productHashTag$.next(hashTags);
        }
    }

    public onNumberChange(value) {
        this.productNumber.emit(value);
    }

    public onProviderChange(value) {
        this.providerId.emit(value);
    }

    public onSupplierChange(value) {
        this.supplierId.emit(value);
    }

    public onSrvCategoryChange(value) {
        this.srvCategoryValid.emit(value);
    }

    public onRouteChange(value) {
        this.routeValid.emit(value);
    }

    public onValidityFormStatusChange(formStatus: any) {
        this.validityFormStatus.emit(formStatus);
    }

    public onSalesBucketChange(value) {
        this.salesBucketValid.emit(value);
    }
}
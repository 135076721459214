import { BehaviorSubject } from "rxjs";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleProductView extends PriceRuleConditionBase {
    public productId: string;
    public priceRuleProductId: string;
    public productCategoryCode: string;
    public productGroupCode: string;
    public productTypeCode: string;
    public productGroupDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public productTypeDataList$ = new BehaviorSubject<Select2Data[]>(null);
}
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { Helper } from "src/app/shared/helper/app.helper";
import { SearchResultView } from "../../../price-dimensions/shared/views";

@Component({
    selector: 'op-price-conditions-search-result',
    templateUrl: './result.component.html'
})

export class ResultComponent implements OnChanges, AfterViewInit {
    public readonly EXPORT_FILE_NAME = "PriceConditionsSearchResult";
    private readonly DELETED_STATUS = 'D';
    focusing = false;
    selectedRow: SearchResultView;
    header: string[];
    rows = 0;
    exportData: any;

    @Input() priceConditions: SearchResultView[] = [];
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() onNew = new EventEmitter();
    @Output() onShowDetails = new EventEmitter<string>();
    @Output() onDelete = new EventEmitter<string>();
    @Output() onCopy = new EventEmitter<string>();
    @Output() onRefresh = new EventEmitter();

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    private statusReferences: StatusReferenceModel[];

    get isNotAllowToCopy(): boolean {
        return !(this.selectedRow) || !this.userSecurity?.copyFlag;
    }
    get isNotAllowToDelete(): boolean {
        if (!this.selectedRow) {
            return true;
        }
        return this.selectedRow.status == this.DELETED_STATUS;
    }

    constructor(protected helper: Helper,
        private dateConverterService: DateConverterService,
        private statusReferenceService: StatusReferenceService) {
        this.statusReferenceService.getAll().subscribe((response) => {
            this.statusReferences = response;
        });
    }

    ngOnChanges() {
        this.selectedRow = null;
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    doubleClick(e: any) {
        this.editItem();
    }

    onRowSelected(e) {
        this.selectedRow = e.data;
    }

    onDeleteClick() {
        this.onDelete.emit(this.selectedRow.priceId);
        this.selectedRow = null;
    }

    newItem() {
        this.onNew.emit();
    }

    onCopyClick() {
        this.onCopy.emit(this.selectedRow.priceId);
        this.selectedRow = null;
    }

    refreshResult() {
        this.onRefresh.emit();
    }

    editItem() {
        this.onShowDetails.emit(this.selectedRow.priceId);
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Code: data.priceCode,
            Name: data.priceName,
            Status: this.getStatusName(data.status),
            CommitBy: data.commitBy,
            CommitDateTime: this.dateConverterService.convertDate(
                data.commitDateTime
            ),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences?.find((item) => item.statusCode == statusCode)
            ?.displayName;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, this.getRowNoByModel(data));
            })
        });
    }

    getRowNoByModel(view: SearchResultView) {
        return this.priceConditions?.indexOf(view) + 1;
    }
}
import { ProductViewModel } from "../product-base-model";
import { ProductPointViewModel } from "./product-point-view.model";
import { OriginDestinationAttributeViewModel } from "./origin-destination-attribute-view.model";
import { ProductInventoryServiceCateViewModel } from "./product-inventory-servicecate.view.model";
import { ProductInventorySalesBucketViewModel } from "./product-inventory-salesbucket.view.model";
import { ServiceTimeViewModel } from ".";

export class TransportViewModel extends ProductViewModel {
    public productScheduleVersionId: string;
    public vehicleTypeCode: string;
    public vehicleTypeName: string;
    public vehicleConfigurationCode: string;
    public vehicleConfigurationName: string;
    public vehicleId: string;
    public vehicleCompositionId: string;
    public usageTypeCode: string;
    public productPointId: string;
    public productPointIdParent: string;
    public hierarchyKey: string;
    public hierarchyLength: number;
    public vehicleTypeCodeInherit: string;
    public vehicleTypeNameInherit: string;
    public vehicleConfigurationCodeInherit: string;
    public vehicleConfigurationNameInherit: string;
    public vehicleIdInherit: string;
    public vehicleCompositionIdInherit: string;    
    public productPoints: ProductPointViewModel[];
    public originDestinationAttributes: OriginDestinationAttributeViewModel[];
    public productInventoryServiceCategories: ProductInventoryServiceCateViewModel[];
    public productInventorySalesBuckets: ProductInventorySalesBucketViewModel[];
    public serviceTimes: ServiceTimeViewModel[];
    public referenceProductId: string;
    public weekDay: string;
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { VehicleGroupModel } from '../../models/vehicle-model/vehicle-group.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleGroupService extends ApiServiceBase {
    private readonly PATH = "/vehicle/vehiclegroup"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<VehicleGroupModel[]> {
        return this.httpGet<VehicleGroupModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}
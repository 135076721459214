import { Component, Input } from '@angular/core';

@Component({
  selector: 'lock',
  templateUrl: 'lock.component.html'
})
export class LockComponent {

  @Input() lockFlag: boolean;
}

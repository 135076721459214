import { Directive, HostListener, Input } from "@angular/core";

@Directive({
    selector: '[opAlphanumeric]'
})
export class AlphanumericDirective {
    private readonly lowercaseRegex = 'a-z';
    private readonly uppercaseRegex = 'A-Z';
    private readonly numberRegex = '0-9';

    @Input() uppercase: boolean = true;
    @Input() lowercase: boolean = true;
    @Input() number: boolean = true;
    @Input() space: boolean = false;

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        // [a-zA-Z0-9 ] based on Inputs
        const regex = new RegExp(`[${this.lowercase ? this.lowercaseRegex : ''}${this.uppercase ? this.uppercaseRegex : ''}${this.number ? this.numberRegex : ''}${this.space ? ' ': ''}]`);
        if (regex.test(event.key)) {
            return;
        }

        event.preventDefault();
    }
}
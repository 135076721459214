import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FormOption } from "./activity-domain-detail-form-config";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { dateOption, select2Option, timeOption } from "./activity-domain-detail-configuration";
import { ActivityDomainView } from "../shared/activity-domain.view";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ActivityPriorityReferenceModel } from "src/app/core/models/reference-model/reference-general-model/activity-priority-reference.model";
import { ActivityReasonReferenceModel } from "src/app/core/models/reference-model/reference-general-model/activity-reason-reference.model";
import { DomainReferenceModel } from "src/app/core/models/reference-model/reference-general-model/domain-reference.model";
import { OrganisationSelectionModel } from "src/app/core/models/organisation-model/organisation-selection.model";
import { BehaviorSubject } from "rxjs";
import { ActivityTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model/activity-type-reference.model";
import { ActivityTypeReferenceService } from "src/app/core/services/system-services/activity-type-reference.service";

@Component({
    selector: 'op-activity-domain-detail',
    templateUrl: './activity-domain-detail.component.html',
    providers: []
})
export class ActivityDomainDetailComponent extends OopsComponentFormBase  {
    readonly ACTIVITY_TYPE_TIME_LIMIT = 'TIMELIMIT';
    readonly ACTIVITY_PRIORITY_HIGH = 'HIGH';
    readonly ERROR_TYPE_REQUIRED = 'Comment Type is required.';
    readonly ERROR_TEXT_REQUIRED = 'Comment is required.';
    public readonly ERROR_TIME_LIMIT_DATE_REQUIRED = 'Time Limit Date is required.';
    public readonly ERROR_TIME_LIMIT_TIME_REQUIRED = 'Time Limit Time is required.';
    public readonly TIME_ONLY_FORMAT = 'HH:mm';
    public readonly dateFormat: string = "YYYY-MM-DD";
    @Input() id: string;
    @Input() activityDomainViews: ActivityDomainView[] = new Array();
    @Input() activityPriorityRef: ActivityPriorityReferenceModel[];
    @Input() activityReasonRef: ActivityReasonReferenceModel[];
    @Input() domainRef: DomainReferenceModel[];
    @Input() organisationRef: OrganisationSelectionModel[];
    @Input() model: ActivityDomainView;
    @Input() noRecord: boolean = false;
    @Input() singleRecord: boolean = false;
    @Input() multipleRecord: boolean = false;
    @Input() newFromGrid: boolean = false;
    @Input() newFromDetail: boolean = false;
    @Input() editFromGrid: boolean = false;
    @Output() view = new EventEmitter<ActivityDomainView>();
    @Output() new = new EventEmitter();
    @Output() delete = new EventEmitter<ActivityDomainView>();
    @Output() cancel = new EventEmitter();
    @Output() formStatus = new EventEmitter<Boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public focusing: boolean = false;
    public select2Option = cloneDeep(select2Option);
    public dateOption = cloneDeep(dateOption);
    public daterangepickerTimeOption: any = cloneDeep(timeOption);
    public applied: boolean = false;
    public activityTypeRef$ = new BehaviorSubject<ActivityTypeReferenceModel[]>(null);

    get showTimeLimitDateError(): boolean {
        return this.processing &&
            this.formGroup.get('timelimitDate').errors &&
            this.formGroup.get('timelimitDate').errors.required;
    }

    get showTimeLimitTimeError(): boolean {
        return this.processing &&
            this.formGroup.get('timelimitTime').errors &&
            this.formGroup.get('timelimitTime').errors.required;
    }

    constructor(formBuilder: UntypedFormBuilder,
        private dateConverterService: DateConverterService,
        private activityTypeReferenceService: ActivityTypeReferenceService) {
        super(formBuilder);
    }

    initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(FormOption);
        if (!this.model) {
            this.clearForm();
        }
        this.fillModelToForm(this.model);
        this.focusForm();
        this.displayInCondition();

        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private fillModelToForm(model: ActivityDomainView) {
        this.getActivityTypeReferences(model.domainCode);
        this.formGroup.patchValue({
            no: model.no,
            organisationId: model.organisationId,
            domainCode: model.domainCode,
            activityTypeCode: model.activityTypeCode,
            activityPriorityCode: model.activityPriorityCode,
            followUpDate: this.setDate(model.followUpDateTime),
            followUpTime: this.setTime(model.followUpDateTime),
            activityReasonCode: this.model.activityReasonCode,
            activityCommentId: model.activityCommentId,
            commentId: model.commentId,
            commentTypeCode: model.commentTypeCode,
            classificationCode: model.commentClassificationCode,
            commentText: model.commentText,
            timelimitDate: this.setDate(model.timelimitDateTime),
            timelimitTime: this.setTime(model.timelimitDateTime)
        });
        this.toggleDateTimeElements(model.activityTypeCode)
    }

    private applyToModel() {
        let formValue = this.formGroup.getRawValue();
        let model: ActivityDomainView = {
            no: formValue.no,
            activityId: formValue.activityId,
            organisationId: formValue.organisationId,
            organisationName: this.getOrganisationCallName(formValue.organisationId),
            domainCode: formValue.domainCode,
            activityTypeCode: formValue.activityTypeCode,
            activityTypeName: this.getActivityTypeName(formValue.activityTypeCode),
            activityPriorityCode: formValue.activityPriorityCode,
            activityPriorityName: this.getPriorityName(formValue.activityPriorityCode),
            activityDateTime: new Date(),
            followUpDateTime: this.createDateTime(this.followUpDate.value, this.followUpTime.value),
            activityReasonCode: formValue.activityReasonCode,
            activityReasonName: this.getReasonName(formValue.activityReasonCode),
            activityCommentId: formValue.activityCommentId,
            commentId: formValue.commentId,
            commentText: formValue.commentText,
            commentTypeCode: formValue.commentTypeCode,
            commentClassificationCode: formValue.classificationCode,
            timelimitDateTime: this.createDateTime(this.timelimitDate.value, this.timelimitTime.value),
            media: null
        }
        this.applied = true;
        this.view.emit(model);
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    private getActivityTypeReferences(domainCode: string) {
        if (domainCode) {
            this.activityTypeReferenceService.getActivityTypeReferencesByDomainCode(domainCode)
                .subscribe((responses: ActivityTypeReferenceModel[]) => {
                    if (responses?.length) {
                        this.valueChange(responses[0].activityTypeCode, "activityTypeCode");
                    }
                    this.activityTypeRef$.next(responses)
                });
        }
        else {
            this.activityTypeRef$.next(null);
        }
    }

    private getOrganisationCallName(organisationId: string): string {
        let filter = this.organisationRef.find(x => x.organisationId == organisationId);
        return filter?.organisationCallName;
    }

    private getActivityTypeName(activityTypeCode: string): string {
        let filter = this.activityTypeRef$.value.find(x => x.activityTypeCode == activityTypeCode);
        return filter?.activityTypeName;
    }

    private getPriorityName(activityPriorityCode: string): string {
        let filter = this.activityPriorityRef.find(x => x.activityPriorityCode == activityPriorityCode);
        return filter?.activityPriorityName;
    }

    private getReasonName(activityReasonCode: string): string {
        let filter = this.activityReasonRef.find(x => x.activityReasonCode == activityReasonCode);
        return filter?.activityReasonName;
    }

    private clearForm() {
        this.formGroup.reset();
        this.completeProcessing();
        this.model = {} as ActivityDomainView;
        this.applied = false;
        this.formStatus.emit(true);
    }

    private focusForm() {
        if (this.newFromGrid || this.editFromGrid) {
            this.focusing = true;
        }
    }

    private displayInCondition() {
        if (this.singleRecord && !this.newFromDetail) {
            this.applied = true;
        } else {
            this.applied = false;
        }
    }

    onApply() {
        if (this.validateForm()) {
            this.applyToModel();
        }
        else {
            this.formStatus.emit(false);
        }
    }

    onCancel() {
        this.clearForm();
        this.cancel.emit();
    }

    onNew() {
        this.new.emit();
    }

    onDelete() {
        this.delete.emit(this.model);
    }

    getErrorMessageForm(): string {
        if (!this.formGroup.controls["commentTypeCode"].valid) {
            return this.ERROR_TYPE_REQUIRED;
        } else if (!this.formGroup.controls["commentText"].valid) {
            return this.ERROR_TEXT_REQUIRED;
        }
        return null;
    }

    public getPanelHeader(): string {
        if (this.multipleRecord) {
            return "Activity Detail";
        }
        return "Activity"
    }

    get followUpTime() {
        return (<UntypedFormControl>this.formGroup.get('followUpTime'));
    }

    get followUpDate() {
        return (<UntypedFormControl>this.formGroup.get('followUpDate'));
    }

    get timelimitTime() {
        return (<UntypedFormControl>this.formGroup.get('timelimitTime'));
    }

    get timelimitDate() {
        return (<UntypedFormControl>this.formGroup.get('timelimitDate'));
    }

    private setTime(localDateTime: Date): string {
        if (localDateTime) {
            let time = this.dateConverterService.convertDateToFormat(localDateTime, this.TIME_ONLY_FORMAT);
            return time;
        }
        return null;
    }

    private setDate(localDateTime: Date): string {
        let date = this.dateConverterService.toDateFormat(localDateTime);
        return date;
    }

    private createDateTime(localDate: Date, localTime: string): any {
        if (localDate) {
            if (localTime) {
                return new Date(localDate + " " + localTime);
            }
            return new Date(localDate + " 00:00");
        }
        return null;
    }

    public onDomainCodeChange(domainCode) {
        this.getActivityTypeReferences(domainCode);
    }

    public onActivityTypeCodeChange(activityTypeCode: any) {
        this.valueChange(activityTypeCode, 'activityTypeCode');
        this.toggleDateTimeElements(activityTypeCode);
    }

    private toggleDateTimeElements(activityTypeCode: string) {
        if (activityTypeCode == this.ACTIVITY_TYPE_TIME_LIMIT) {
            this.followUpDate.disable();
            this.followUpDate.patchValue(null);

            this.followUpTime.disable();
            this.followUpTime.patchValue(null);

            this.timelimitDate.enable();
            this.timelimitDate.patchValue(null);
            this.timelimitDate.setValidators([Validators.required])
            this.timelimitDate.updateValueAndValidity();

            this.timelimitTime.enable();
            this.timelimitTime.patchValue(null);
            this.timelimitTime.setValidators([Validators.required])
            this.timelimitTime.updateValueAndValidity();

            this.formGroup.get('activityPriorityCode').patchValue(this.ACTIVITY_PRIORITY_HIGH);
        } else {
            this.followUpDate.enable();
            this.followUpDate.patchValue(null);

            this.followUpTime.enable();
            this.followUpTime.patchValue(null);

            this.timelimitDate.disable();
            this.timelimitDate.patchValue(null);
            this.timelimitDate.clearValidators();
            this.timelimitDate.updateValueAndValidity();

            this.timelimitTime.disable();
            this.timelimitTime.patchValue(null);
            this.timelimitTime.clearValidators();
            this.timelimitTime.updateValueAndValidity();
        }
    }

    public isRequired(ctrl: UntypedFormControl): boolean {
        return ctrl.hasValidator(Validators.required);
    }
}
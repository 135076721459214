import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";

export const PRODUCT_DASHBOARD_FEES_COMPARE_MONM_DEMO: OrdersDashboardCompareValuesModel[] = [
    {
        dayNo: 1,
        value1: 238,
        value1Name: "2021",
        value2: 123,
        value2Name: "2022"
    },
    {
        dayNo: 2,
        value1: 81,
        value1Name: "2021",
        value2: 112,
        value2Name: "2022"
    },
    {
        dayNo: 3,
        value1: 191,
        value1Name: "2021",
        value2: 128,
        value2Name: "2022"
    },
    {
        dayNo: 4,
        value1: 187,
        value1Name: "2021",
        value2: 172,
        value2Name: "2022"
    },
    {
        dayNo: 5,
        value1: 206,
        value1Name: "2021",
        value2: 131,
        value2Name: "2022"
    },
    {
        dayNo: 6,
        value1: 63,
        value1Name: "2021",
        value2: 145,
        value2Name: "2022"
    },
    {
        dayNo: 7,
        value1: 227,
        value1Name: "2021",
        value2: 167,
        value2Name: "2022"
    },
    {
        dayNo: 8,
        value1: 113,
        value1Name: "2021",
        value2: 129,
        value2Name: "2022"
    },
    {
        dayNo: 9,
        value1: 110,
        value1Name: "2021",
        value2: 178,
        value2Name: "2022"
    },
    {
        dayNo: 10,
        value1: 144,
        value1Name: "2021",
        value2: 179,
        value2Name: "2022"
    },
    {
        dayNo: 11,
        value1: 135,
        value1Name: "2021",
        value2: 160,
        value2Name: "2022"
    },
    {
        dayNo: 12,
        value1: 174,
        value1Name: "2021",
        value2: 209,
        value2Name: "2022"
    },
    {
        dayNo: 13,
        value1: 169,
        value1Name: "2021",
        value2: 228,
        value2Name: "2022"
    },
    {
        dayNo: 14,
        value1: 61,
        value1Name: "2021",
        value2: 161,
        value2Name: "2022"
    },
    {
        dayNo: 15,
        value1: 64,
        value1Name: "2021",
        value2: 114,
        value2Name: "2022"
    },
    {
        dayNo: 16,
        value1: 197,
        value1Name: "2021",
        value2: 84,
        value2Name: "2022"
    },
    {
        dayNo: 17,
        value1: 168,
        value1Name: "2021",
        value2: 192,
        value2Name: "2022"
    },
    {
        dayNo: 18,
        value1: 221,
        value1Name: "2021",
        value2: 128,
        value2Name: "2022"
    },
    {
        dayNo: 19,
        value1: 170,
        value1Name: "2021",
        value2: 154,
        value2Name: "2022"
    },
    {
        dayNo: 20,
        value1: 53,
        value1Name: "2021",
        value2: 203,
        value2Name: "2022"
    },
    {
        dayNo: 21,
        value1: 86,
        value1Name: "2021",
        value2: 250,
        value2Name: "2022"
    },
    {
        dayNo: 22,
        value1: 145,
        value1Name: "2021",
        value2: 136,
        value2Name: "2022"
    },
    {
        dayNo: 23,
        value1: 112,
        value1Name: "2021",
        value2: 225,
        value2Name: "2022"
    },
    {
        dayNo: 24,
        value1: 58,
        value1Name: "2021",
        value2: 77,
        value2Name: "2022"
    },
    {
        dayNo: 25,
        value1: 108,
        value1Name: "2021",
        value2: 91,
        value2Name: "2022"
    },
    {
        dayNo: 26,
        value1: 224,
        value1Name: "2021",
        value2: 112,
        value2Name: "2022"
    },
    {
        dayNo: 27,
        value1: 127,
        value1Name: "2021",
        value2: 244,
        value2Name: "2022"
    },
    {
        dayNo: 28,
        value1: 133,
        value1Name: "2021",
        value2: 103,
        value2Name: "2022"
    }, 
    {
        dayNo: 29,
        value1: 130,
        value1Name: "2021",
        value2: 189,
        value2Name: "2022"
    },
    {
        dayNo: 30,
        value1: 118,
        value1Name: "2021",
        value2: 144,
        value2Name: "2022"
    }
]
export class PriceRuleLocationModel {
    public priceRuleLocationId: string;
    public priceRuleLocationTypeCode: string;
    public priceRuleLocationPointCode: string;
    public locationId: string;
    public locationGroupId: string;
    public regionCode: string;
    public countryCode: string;
    public excludeFlag: boolean;
    public displaySequence: number;
}
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

import { Helper } from 'src/app/shared/helper/app.helper';
import { PriceModel } from '../../shared/models';
import { CombinedPriceModel } from '../../shared/models/combined-price.model';

@Component({
    selector: 'op-price-tree',
    templateUrl: './tree.component.html',
    providers: [DateConverterService],
})
export class TreeComponent implements OnInit, OnChanges {
    treeData$ = new BehaviorSubject<TreeNode[]>(null);
    selectedNode: TreeNode;
    isLowestLevelSelected = false;

    public focusing = false;

    @Input() price: PriceModel;
    @Input() heightPanelGeneral: Number = 325;
    @Input() key = new Array<string>();
    @Input() combinedPrice: CombinedPriceModel;
    @Input() priceName: string;

    @Output() onHide = new EventEmitter();
    @Output() onSelected = new EventEmitter<string[]>();
    @Output() onLowestLevelSelected = new EventEmitter<boolean>();
    @Output() combinedPriceChange = new EventEmitter<CombinedPriceModel>();

    constructor(public helper: Helper) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['price']) {
            this.resetSelection();
            this.resetLocalParams();
        }
        if (this.price) {
            this.combinedPrice.isCombinedPrice = this.price.combinationFlag;
            let allNodes = new Array<TreeNode>();
            let rootNode = this.createHierarchyNodes(this.price);
            allNodes.push(rootNode);
            this.treeData$.next(allNodes);
            this.selectNodeIfKeyExist();
            this.combinedPriceChange.emit(this.combinedPrice);
        }
        if (changes['key']?.currentValue?.length) {
            this.selectNodeIfKeyExist();
        }
        if (changes['priceName']?.currentValue) {
            let treeNodes = this.treeData$.value;
            treeNodes[0].label = this.priceName;
            this.treeData$.next(treeNodes);
        }
    }

    private resetLocalParams() {
        this.combinedPrice = {
            combinedPriceId: null,
            isCombinedPrice: false,
            currencyPriceIds: new Array<string>(),
        };
    }

    private createHierarchyNodes(price: PriceModel): TreeNode {
        this.getCombinedPriceId(price);
        this.getCurrencyPriceIds(price);
        let node = this.createTreeNode(price.priceId, price.priceName);
        if (price.children?.length) {
            node.children = new Array<TreeNode>();
            for (let child of price.children) {
                node.children.push(this.createHierarchyNodes(child));
            }
        }
        return node;
    }

    private getCombinedPriceId(price: PriceModel) {
        if (
            !!this.combinedPrice.combinedPriceId ||
            this.combinedPrice.isCombinedPrice == false ||
            !price.parentPriceId ||
            price.priceName != 'Combined Price'
        ) {
            return;
        }
        this.combinedPrice.combinedPriceId = price.priceId;
    }

    private getCurrencyPriceIds(price: PriceModel) {
        if (!price.parentPriceId) {
            return;
        }

        if (price.currencies?.length && this.combinedPrice.currencyPriceIds.includes(price.priceId) == false) {
            this.combinedPrice.currencyPriceIds.push(price.priceId);
        }
    }

    private resetSelection() {
        this.selectedNode = null;
        this.key = [];
        this.isLowestLevelSelected = false;
        this.onLowestLevelSelected.emit(this.isLowestLevelSelected);
    }

    private selectNodeIfKeyExist() {
        if (this.key?.length) {
            this.expandToNode();
            if (!this.isLowestLevelKey(this.key)) {
                this.selectedNode = null;
            }
        }
    }

    private isLowestLevelKey(key: string[]): boolean {
        if (!this.treeData$.value?.length) {
            return false;
        }
        return !this.getNodeByKey(this.treeData$.value[0], key[key.length - 1])?.children?.length;
    }

    private getNodeByKey(node: TreeNode, key: string): TreeNode {
        if (node.data == key) {
            return node;
        }
        if (node?.children?.length) {
            for (let child of node.children) {
                let found = this.getNodeByKey(child, key);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    private expandToNode() {
        let keyTemp = this.key.map(k => k);
        let treeData = this.treeData$.value;
        while (keyTemp?.length) {
            let key = keyTemp.shift();
            let node = treeData?.find(node => node.data == key);
            if (node) {
                node.expanded = true;
                this.selectedNode = node;
                if (node.children?.length) {
                    treeData = node.children;
                }
            }
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    ngOnInit(): void {}

    private createTreeNode(data: string, label: string): TreeNode {
        let node: TreeNode = {
            data: data,
            label: label,
        };
        return node;
    }

    onSelectionChange() {
        if (this.selectedNode) {
            this.emitKeyViaOnSelected();
            this.isLowestLevelSelected = !this.selectedNode?.children?.length;
            this.onLowestLevelSelected.emit(this.isLowestLevelSelected);
        }
    }

    private emitKeyViaOnSelected() {
        let key = [this.selectedNode.data];
        let node = this.selectedNode;
        while (node.parent) {
            node = node.parent;
            key.unshift(node.data);
        }
        this.onSelected.emit(key);
    }

    public hideTree() {
        this.onHide.emit();
    }

    getConjunctionWord(node: TreeNode): string {
        if (this.isCurrencyNode(node) == false || node.parent.children.length == 1) {
            return null;
        }

        if (this.isUnderCombinedPrice(node) && this.isFirstAmongChildren(node)) {
            return 'And';
        }
        return 'Or';
    }

    isCurrencyNode(node: TreeNode): boolean {
        return this.combinedPrice.currencyPriceIds.includes(node.data);
    }

    isUnderCombinedPrice(node: TreeNode): boolean {
        let parent = node.parent;
        while (parent) {
            if (parent.data == this.combinedPrice.combinedPriceId) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    isFirstAmongChildren(node: TreeNode): boolean {
        return node.parent.children.indexOf(node) == 0;
    }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ValidationConstant } from 'src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/validation/validation.constant';
import { ValidationView } from 'src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/validation/validation.view';

@Component({
    selector: 'op-product-validation-table',
    templateUrl: './product-validation-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductValidationComponent {
    @Input() validates: ValidationView[] = [];
    public rows = 0;
    
    public getValidateName(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = ValidationConstant.VALIDATION_MENDATORY_NAME;
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = ValidationConstant.VALIDATION_CONDITION_NAME;
                break;
        }
        return returnValue;
    }

    public getValidateClass(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = 'status-mendatory';
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = 'status-condition';
                break;
        }
        return returnValue;
    }

}
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, SimpleChange, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import {
    select2TypeInherit, select2DimensionInherit, select2Type, select2Dimension
} from './vehicle-configuration';

import { VehicleCompositionModel, VehicleModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { VehicleConfigurationView } from './vehicle-configuration.view';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
declare var $: any
@Component({
    selector: 'op-vehicle',
    templateUrl: './vehicle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})

export class VehicleComponent implements OnChanges {

    @Input() id: string;
    @Input() vehicleTypeReference$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    @Input() vehicle$ = new BehaviorSubject<VehicleModel[]>(null);
    @Input() productGroupCode: string;
    @Input() searchMode: boolean = false;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("vehicleType") vehicleType: ElementRef;
    @ViewChild("vehicleConfiguration") vehicleConfiguration: ElementRef; 
    @ViewChild("vehicle") vehicle: ElementRef; 

    public focusing: boolean = false;
    public typeInheritOption: any = select2TypeInherit;
    public dimensionInheritOption: any = select2DimensionInherit;
    public typeOption: any = cloneDeep(select2Type);
    public dimensionOption: any = cloneDeep(select2Dimension);
    public vehicleTypeReferenceFilter$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
    public vehicleTypeReferenceInherit$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleCompositionInherit$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public vehicleInherit$ = new BehaviorSubject<VehicleModel[]>(null);
    public vehicleView = new VehicleConfigurationView();
    public vehicleInheritView = new VehicleConfigurationView();
    public newItem: boolean = false;
    public finalFlag: boolean = false;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if ((changes['productGroupCode'] || changes['id']) && !this.searchMode) {
            this.createVehicleTypeRefeferenceFilter();
        }
        else if (this.searchMode) {
            this.vehicleTypeReferenceFilter$ = this.vehicleTypeReference$;
        }
    }

    private createVehicleTypeRefeferenceFilter() {
        this.vehicleTypeReferenceFilter$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
        if (this.vehicleTypeReference$.value && this.productGroupCode) {
            this.filterVehicleType();
        }
    }

    private filterVehicleType() {
        var vehicleTypeFilter = this.vehicleTypeReference$.value.filter(x => x.vehicleGroupCode == this.convertProductGroupToVehicleGroupCode());
        this.vehicleTypeReferenceFilter$.next(vehicleTypeFilter);
        if (!this.id) {
            this.vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
            this.vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
        }
    }

    public createVehicleCompositonAndVehicle(vehicleTypeCode) {
        this.createVehicleCompositionFilter(vehicleTypeCode);
        this.createVehicleFilter(vehicleTypeCode);
    }

    private createVehicleCompositionFilter(vehicleTypeCode) {
        this.vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
        if (this.vehicleComposition$.value && vehicleTypeCode) {
            this.filterVehicleComposition(vehicleTypeCode);
        }
    }

    private filterVehicleComposition(vehicleTypeCode) {
        var vehicleComposition = this.vehicleComposition$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
        this.vehicleCompositionFilter$.next(vehicleComposition);
        if (vehicleComposition?.length && !this.searchMode) {
            this.vehicleView.vehicleCompositionId = vehicleComposition[0].vehicleCompositionId;
        }
        else {
            this.vehicleView.vehicleCompositionId = null;
        }
        this.changeDetectionRef.detectChanges();
    }

    private createVehicleFilter(vehicleTypeCode) {
        this.vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
        if (this.vehicle$.value && vehicleTypeCode) {
            this.filterVehicle(vehicleTypeCode);
        }
    }

    private filterVehicle(vehicleTypeCode) {
        var vehicle = this.vehicle$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
        this.vehicleFilter$.next(vehicle);
        this.vehicleView.vehicleId = null;
    }

    private convertProductGroupToVehicleGroupCode(): string {
        var vehicleGroupCode: string
        switch (this.productGroupCode) {
            case "AIR": {
                vehicleGroupCode = "AIRCRAFT";
                break;
            }
            case "RAIL": {
                vehicleGroupCode = "TRAIN";
                break;
            }
            case "BUS": {
                vehicleGroupCode = "BUS";
                break;
            }
            case "SHIP": {
                vehicleGroupCode = "VESSEL";
                break;
            }
        }
        return vehicleGroupCode;
    }

    public fillData(vehicleTypeCodeInherit: string, vehicleCompositionIdInherit: string, vehicleIdInherit: string,
        vehicleTypeCode: string, vehicleCompositionId: string, vehicleId: string, finalFlag: boolean = false) {
        this.clearForm();
        this.finalFlag = finalFlag;
        this.disableType();
        this.assignDataToVehicleInheritView(vehicleTypeCodeInherit, vehicleCompositionIdInherit, vehicleIdInherit);
        this.assignDataToVehicleView(vehicleTypeCode, vehicleCompositionId, vehicleId);
        this.toggleEditCondition();
        this.changeDetectionRef.detectChanges();
    }

    public clearForm() {
        this.finalFlag = false;
        this.disableType();
        this.vehicleView = new VehicleConfigurationView();
        this.vehicleInheritView = new VehicleConfigurationView();
        this.toggleEditCondition();
        this.changeDetectionRef.detectChanges();
    }

    private assignDataToVehicleView(vehicleTypeCode: string, vehicleCompositionId: string, vehicleId: string) {
        if (vehicleTypeCode) {
            this.vehicleView.vehicleTypeCode = vehicleTypeCode;
            this.createVehicleCompositonAndVehicle(vehicleTypeCode);
            this.vehicleView.vehicleCompositionId = vehicleCompositionId;
            this.vehicleView.vehicleId = vehicleId;
        }
    }

    private assignDataToVehicleInheritView(vehicleTypeCode: string, vehicleCompositionId: string, vehicleId: string) {
        if (vehicleTypeCode) {
            this.createVehicleTypeReferenceInherit(vehicleTypeCode);
            this.createVehicleCompositionInherit(vehicleCompositionId);
            this.createVehicleInherit(vehicleId);
            this.vehicleInheritView.vehicleTypeCode = vehicleTypeCode;
            this.vehicleInheritView.vehicleCompositionId = vehicleCompositionId;
            this.vehicleInheritView.vehicleId = vehicleId;
        }
    }

    private createVehicleTypeReferenceInherit(vehicleTypeCode: string) {
        let vehicleTypeReference = this.vehicleTypeReference$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
        this.vehicleTypeReferenceInherit$.next(vehicleTypeReference);
    }

    private createVehicleCompositionInherit(vehicleCompositionId: string) {
        let vehicleComposition = this.vehicleComposition$.value.filter(x => x.vehicleCompositionId == vehicleCompositionId);
        this.vehicleCompositionInherit$.next(vehicleComposition);
    }

    private createVehicleInherit(vehicleId: string) {
        let vehicle = this.vehicle$.value.filter(x => x.vehicleId == vehicleId);
        this.vehicleInherit$.next(vehicle);
    }

    public add() {
        this.newItem = !this.newItem;
    }

    public deleteVehicleType() {
        this.vehicleView = new VehicleConfigurationView();
        this.vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
        this.vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
    }

    public deleteVehicleConfig() {
        this.vehicleView.vehicleCompositionId = null;
        this.vehicleView.vehicleId = null;
    }

    public deleteVehicle() {
        this.vehicleView.vehicleId = null;
    }

    private toggleEditCondition() {
        if (!this.vehicleInheritView.vehicleTypeCode &&
            !this.vehicleView.vehicleTypeCode &&
            !this.vehicleView.vehicleCompositionId &&
            !this.vehicleView.vehicleId) {
            this.newItem = false;
        }
        else {
            this.newItem = true;
        }
    }

    public disableControl(): boolean {
        if (this.finalFlag) {
            return true;
        }
        return false;
    }

    private disableType() {
        if (this.finalFlag) {
            this.typeOption = cloneDeep(select2TypeInherit);
        } else {
            this.typeOption = cloneDeep(select2Type);
        }
        $(this.vehicleType.nativeElement).select2(this.typeOption);
        $(this.vehicleConfiguration.nativeElement).select2(this.typeOption);
        $(this.vehicle.nativeElement).select2(this.typeOption);
    }

    get vehicleTypeCode(): string | string[] {
        return this.vehicleView.vehicleTypeCode;
    }

    set vehicleTypeCode(value: string | string[]) {
        this.vehicleView.vehicleTypeCode = <string>value;
    }

    get vehicleCompositionId(): string | string[] {
        return this.vehicleView.vehicleCompositionId;
    }

    set vehicleCompositionId(value: string | string[]) {
        this.vehicleView.vehicleCompositionId = <string>value;
    }

    get vehicleId(): string | string[] {
        return this.vehicleView.vehicleId;
    }

    set vehicleId(value: string | string[]) {
        this.vehicleView.vehicleId = <string>value;
    }
}
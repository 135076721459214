export class CommentView {
    public no: number;
    public commentId: string;
    public individualId: string;
    public organisationId: string;
    public commentTypeCode: string;
    public commentTypeName: string;
    public commentClassificationCode: string;
    public commentClassificationName: string;
    public commentText: string;
    public commitBy: string;
    public commitDateTime: string;
    public privateCommentFlag: boolean;
    public warningCommentFlag: boolean;
    public lockedCommentFlag: boolean;
    public commentLocked: boolean;
}
import { Component, Input } from '@angular/core';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'activity-followup-datetime',
    templateUrl: 'activity-followup-datetime.component.html',
    styleUrls: ['activity-followup-datetime.component.scss']
})
export class ActivityFollowupDateTimeComponent {
    private readonly hoursInADay = 24;
    public readonly STATUS_PENDING = "PENDING";
    @Input() value: Date;
    @Input() status: string;

    constructor(private dateConversion: DateConverterService) {
        
    }
    get displayInColor(): boolean {
        if (this.status && this.status != this.STATUS_PENDING) {
            return false;
        }
        return this.passDateOrIn24Hours();
    }

    public passDateOrIn24Hours(): boolean {
        if (this.value) {
            let now = new Date();
            let d = new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate());
            let currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const diffInMilliseconds = currentDate.getTime() - d.getTime();
            const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
            return diffInHours >= -this.hoursInADay;
        }
        return false;
    }

    public displayDate(): string {
        return this.dateConversion.convertDate(this.value);
    }
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { LocationReferenceModel, ProductSchedulePeriodModel, ProductScheduleVersionModel, ScheduleQueueActionReferenceModel, ScheduleQueueResultReferenceModel, VehicleCompositionModel, VehicleTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { RouteDataService } from "src/app/modules/insight-management/insight-management-content/insight-detail/insight-detail-address/shared/route-data-service";
import { selectAction, selectArrival, selectDeparture, selectProgress, selectProvider, selectResult, selectSchedulePeriod, selectScheduleVersion, selectVehicleComposition, selectVehicleType } from "../shared/select2-configuration";

@Component({
    selector: 'op-schedule-queue-search',
    templateUrl: './schedule-queue-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [RouteDataService] 
})

export class ScheduleQueueSearchComponent {
    public providerOption: any;
    public departureOption: any;
    public arrivalOption: any;
    public schedulePeriodOption: any;
    public scheduleVersionOption: any;   
    public vehicleTypeOption: any;
    public vehicleCompositionOption: any;
    public actionOption: any;
    public progressOption: any;
    public resultOption: any;
    public isScheduleVersionDisabled: boolean = true;
    public productScheduleVersionFilter: ProductScheduleVersionModel[];
    
    @Input() providerReferences: OrganisationModel[];
    @Input() vehicleTypeReferences: VehicleTypeReferenceModel[];
    @Input() locationReferences: LocationReferenceModel[];
    @Input() productSchedulePeriods: ProductSchedulePeriodModel[];
    @Input() productScheduleVersions: ProductScheduleVersionModel[];
    @Input() vehicleCompositions: VehicleCompositionModel[];
    @Input() scheduleQueueResultReferences: ScheduleQueueResultReferenceModel[];
    @Input() scheduleQueueActionReferences: ScheduleQueueActionReferenceModel[];
    @Input() condition: ScheduleQueueSearchModel;

    @Output() searchClick = new EventEmitter<ScheduleQueueSearchModel>();

    constructor(private routeDataService: RouteDataService,
        private changeDetectionRef: ChangeDetectorRef,) {
        this.setOptionControl();
    }

    private setOptionControl() {
        this.providerOption = selectProvider;
        this.departureOption = selectDeparture;
        this.departureOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
        this.arrivalOption = selectArrival;
        this.arrivalOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
        this.schedulePeriodOption = selectSchedulePeriod;
        this.scheduleVersionOption = selectScheduleVersion;
        this.vehicleTypeOption = selectVehicleType;
        this.vehicleCompositionOption = selectVehicleComposition;
        this.actionOption = selectAction;
        this.progressOption = selectProgress;
        this.resultOption = selectResult;
    }

    public searchButtoneClick() {
        this.searchClick.emit(this.condition);
    }

    public onProviderChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.providerId = null;
            return;
        }
        this.condition.providerId = v;
    }

    public onDepartureChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.originLocationId = null
            return;
        }
        this.condition.originLocationId = v;
    }

    public onArrivalChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.destinationLocationId = null;
            return;
        }
        this.condition.destinationLocationId = v;
    }

    public onSchedulePeriodChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.productSchedulePeriodId = null;
            this.condition.productScheduleVersionId = null;
            this.isScheduleVersionDisabled = true;
            return;
        }

        this.isScheduleVersionDisabled = false;
        this.productScheduleVersionFilter = this.productScheduleVersions.filter(x => x.productSchedulePeriodId == v)
        this.condition.productSchedulePeriodId = v;
    }

    public onScheduleVersionChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.productScheduleVersionId = null;
            return;
        }
        this.condition.productScheduleVersionId = v;
    }

    public onVehicleTypeChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.vehicleTypeCode = null;
            return;
        }
        this.condition.vehicleTypeCode = v;
    }

    public onVehicleCompositionChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.vehicleCompositionId = null;
            return;
        }
        this.condition.vehicleCompositionId = v;
    }

    public onActionChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.scheduleQueueActionCode = null;
            return;
        }
        this.condition.scheduleQueueActionCode = v;
    }

    public onProgressChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.scheduleQueueProgressCode = null;
            return;
        }
        this.condition.scheduleQueueProgressCode = v;
    }

    public onResultChange(value: string | string[]) {
        let v = value as string;
        if (!v) {
            this.condition.scheduleQueueResultCode = null;
            return;
        }
        this.condition.scheduleQueueResultCode = v;
    }

    public clearCondition() {
        this.condition = {} as ScheduleQueueSearchModel;
        this.changeDetectionRef.detectChanges();
    }
}
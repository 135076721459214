import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { ActionService } from 'src/app/core/utils/action.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SettingsCategoriesComponent } from '../settings-categories/settings-categories.component';
import { PAGE_TITLE } from '../shared/title-constant';
import { TabService } from 'src/app/core/utils/tab.service';

@Component({
    selector: 'op-settings-tabs',
    templateUrl: './settings-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTabsComponent implements OnInit, AfterViewInit {
    public readonly CATEGORIES_TABID = 'CATEGORIES';
    public readonly SEARCH_TABID = 'SEARCH';
    private readonly ATTRIBUTE_NAME = 'Attribute'
    public selectedCategory: OopsCategory;
    public selectedTab = this.CATEGORIES_TABID;
    public favoriteCategoryName: string;
    public executeFlag = true;
    public previousSerchCondition: any;

    @Output() onTitleChange = new EventEmitter<string>();

    @ViewChild(SettingsCategoriesComponent) categoriesComponent: SettingsCategoriesComponent;

    constructor(private actionService: ActionService,
        private navigationService: NavigationService,
        private changeDetector: ChangeDetectorRef,
        private tabService: TabService) { }

    ngAfterViewInit(): void {
        this.getNavigationParameter();
    }

    ngOnInit(): void {
        this.addAction(this.CATEGORIES_TABID);
        this.tabService.changeTabName(PAGE_TITLE.settings);
    }

    private getNavigationParameter() {
        let param = this.navigationService.getParams();
        this.previousSerchCondition = param?.searchCondition;
        this.executeFlag = param?.executeFlag ?? true;
        if (param?.selectedCategory) {
            this.favoriteCategoryName = param?.selectedCategory;
            if (param?.selectedCategoryItem) {
                this.categoriesComponent.setActiveCatagoryItem(param?.selectedCategoryItem);
            } else {
                this.categoriesComponent.setActiveCatagory(this.favoriteCategoryName);
            }
        }
        this.selectedTab = param?.activeId ?? this.CATEGORIES_TABID;
        this.changeDetector.detectChanges();
    }

    public onSelectedCategoryChange(category: OopsCategory) {
        this.selectedCategory = category;
    }

    openSearchTab() {
        this.selectedTab = 'SEARCH';
        this.onTabChange(this.selectedTab);
    }

    onTabChange(activeId: string) {
        switch (activeId) {
            case this.SEARCH_TABID:
                this.onTitleChange.emit(this.categoriesComponent.getPageTitle(this.selectedCategory));
                break;
            default:
                this.favoriteCategoryName = null;
                this.executeFlag = true;
                this.onTitleChange.emit(PAGE_TITLE.settings);
                break;
        }
        this.addAction(activeId);
    }

    addAction(actionCode: string) {
        if (actionCode == this.SEARCH_TABID && this.selectedCategory?.name == this.ATTRIBUTE_NAME) {
            this.actionService.add(actionCode, null, null);
            return;
        }
        this.actionService.add(null, null, null);
    }

    onCancelRequested() {
        this.selectedTab = this.CATEGORIES_TABID;
        this.onTabChange(this.CATEGORIES_TABID);
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import { Observable, Subscriber } from 'rxjs';
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { OrderDetailServiceModel } from 'src/app/core/models/order-model';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import {
    OrdersMapperService,
    OrderDetailChildLoadData,
    OrderDetailServiceView
} from '../../../shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-service-list',
    templateUrl: './orders-detail-service-list.component.html',
    providers: [OrdersMapperService]
})
export class OrdersDetailServiceListComponent {
    public readonly SPINNER_NAME: string = "orderDetailService";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;

    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderDetailServiceViews: OrderDetailServiceView[] = new Array<OrderDetailServiceView>();
    public orderLock: boolean;
    public countNumberOfItems: number = 0;

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }

    public loadOrderDetailServicesByOrder(parentOrderId: string, orderId: string, orderLock: boolean): Observable<boolean> {

        this.orderLock = orderLock;
        return new Observable(observer => {
            if (!orderId) {
                observer.next(true);
                observer.complete();
                return;
            }
            this.orderDetailService.getOrderDetailServicesByOrder(parentOrderId, orderId)
                .subscribe(
                    (result: OrderDetailServiceModel[]) => this.executeServiceResult(result, observer)
                )
        });
    }
    private executeServiceResult(result: OrderDetailServiceModel[], observer: Subscriber<boolean>) {
        this.fillNumberOfItems(result);
        this.orderDetailServiceViews = this.mapperService.toOrderDetailServiceViews(result);
        this.childLoadCompleted.emit({
            recordCount: result?.length
        });
        this.checkCollape();
        this.changeDetection.detectChanges();
        observer.next(true);
        observer.complete();
    }
    private checkCollape() {
        if (!this.orderDetailServiceViews || this.orderDetailServiceViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }
    public displayPartnerRow(view: OrderDetailServiceView) {
        if (!view || (!view.lastName && !view.firstName && !view.title)) {
            return false;
        }
        return true;
    }
    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    private fillNumberOfItems(models: OrderDetailServiceModel[]) {
        if (!models || models.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = models.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }

}
import { Injectable } from "@angular/core";
import {
    LanguageTranslationSetCommandModel,
    LanguageTranslationSetViewModel,
    LanguageTranslationCommandModel,
    LanguageTranslationViewModel
} from "src/app/core/models/language-translation-set-model";

import { TranslationNameView } from "./views/translationName.view";
import { TranslationView } from "./views/translation.view";

@Injectable()
export class TranslationNameMapperService {

    public nameToNameViews(name: string): TranslationNameView[] {
        let views: TranslationNameView[] = new Array();
        let view = new TranslationNameView();
        view.id = 1;
        view.name = name;
        views.push(view);
        return views;
    }

    public languageTranslationSetModelToTranslationViews(languageTranslationSet: LanguageTranslationSetViewModel): TranslationView[] {
        let views: TranslationView[] = new Array();
        if (languageTranslationSet) {
            let i = 0;
            for (let languageTranslation of languageTranslationSet.languageTranslations) {
                i++;
                views.push(this.languageTranslationModelToView(i, languageTranslation));
            }
        }
        return views;
    }

    private languageTranslationModelToView(index: number, languageTranslation: LanguageTranslationViewModel): TranslationView {
        let view = new TranslationView();
        view.nameId = 1;
        view.id = index;
        view.languageTranslationId = languageTranslation.languageTranslationId;
        view.languageCode = languageTranslation.languageCode;
        view.languageName = languageTranslation.languageName;
        view.translation = languageTranslation.translation;
        view.selected = false;
        return view;
    }

    public translationViewsToLanguageTranslationSetModel(languageTranslationSetId: string, 
            translationName: string,
            entityName: string, 
            entityPrimaryKey: string, 
            translationView: TranslationView[]): LanguageTranslationSetCommandModel {
            let model = new LanguageTranslationSetCommandModel();
            if (translationView) {
                model.languageTranslationSetId = languageTranslationSetId;
                model.languageTranslationName = translationName;
                model.statusCode = "A";
                let languageTranslations: LanguageTranslationCommandModel[] = new Array();
                for (let translation of translationView) {
                    languageTranslations.push(this.translationViewsToLanguageTranslationModel(entityName, entityPrimaryKey, translation));
                }
                model.languageTranslations = languageTranslations;
                if (!languageTranslations?.length){
                    return null;
                }
            }
            return model;
    }

    private translationViewsToLanguageTranslationModel(entityName: string, entityPrimaryKey: string, translationView: TranslationView): LanguageTranslationCommandModel {
        let model = new LanguageTranslationCommandModel();
        model.languageTranslationId = translationView.languageTranslationId;
        model.languageCode = translationView.languageCode;
        model.entityPrimaryKey = entityPrimaryKey;
        model.entityName = entityName;
        model.translation = translationView.translation;
        return model;
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Helper } from "src/app/shared/helper/app.helper";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { PriceModel } from "../../../prices/shared/models";
import { AttributesComponent } from "./attributes/attributes.component";

@Component({
    selector: 'op-price-conditions-details-dimensions',
    templateUrl: './dimensions.component.html'
})
export class DimensionsComponent implements OnChanges {
    @Input() price: PriceModel;
    @Input() panelTitle = 'Price Dimension Attribute';
    @Input() panelIcon = 'fal fa-hand-holding-usd';
    @Input() disabled: boolean = false;

    @Output() unitCodeChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter();
    
    @ViewChild(AttributesComponent) attributesComponent: AttributesComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    focusing = false;
    classIcon: string = this.helper.getClassIcon();
    price$ = new BehaviorSubject<PriceModel>(null);

    constructor(private helper: Helper) {
    }

    ngOnChanges() {
        this.price$.next(this.price);
    }

    public getValues(priceModel: PriceModel): PriceModel {
        return this.attributesComponent.getValues(priceModel);
    }

    public triggerAttributesError() {
        this.attributesComponent.validateFormGroup();
    }

    onUnitCodeChange(unitCode: string) {
        this.unitCodeChange.emit(unitCode);
    }

    public resetForm() {
        this.attributesComponent.resetForm();
    }

    public refresh() {
        this.attributesComponent.refresh();
    }

    public onFocusingChange(focusing: boolean) {
        if (focusing) {
            this.focus.emit();
        }
    }
}

export class ProductPointViewModel {
    public productPointId: string;
    public productId: string;
    public locationId: string;
    public locationCode: string;
    public locationName: string;
    public sortSequence: number;
    public latitude: number;
    public longitude: number;
    public countryCode: string;
    public countryName: string;
} 
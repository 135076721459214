import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CashbookSearchTableModel } from 'src/app/core/models/cashbook-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner/loading-notifier';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { CashbookSearchComponent } from '../cashbook-search/cashbook-search.component';

@Component({
  selector: 'op-cashbook-tabs',
  templateUrl: './cashbook-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionbarService],
})
export class CashbookTabsComponent implements AfterViewInit, OnDestroy {
  public readonly SPINNER_NAME: string = "cashbook";
  public loadingNotifier = new LoadingNotifier();
  readonly statusTab = 'StatusTab';
  readonly searchTabId = "searchTabId";
  readonly detailTab = "detailTab";
  readonly documentTab = "documentTab"
  public selectedTab: string = 'SEARCH';
  private unsubscribe$ = new Subject();
  public cancelButton = new CancelButtonModel();
  public copyButton = new CopyButtonModel();
  public newButton = new NewButtonModel();
  public refreshButton = new RefreshButtonModel();
  public saveButton = new SaveButtonModel();
  public newUserAccountFlag: boolean = false;
  public pagingDataView: PagingDataView[];
  public selectedItem: boolean = false;
  public cashbookItem: CashbookSearchTableModel;
  public searchCondition: any;
  public refreshValue: boolean = false;
  public panelId: string;
  public userSecurity: SecurityGroupSecurityModel;

  @ViewChild(CashbookSearchComponent) cashbookSearchComponent: CashbookSearchComponent;
  constructor(private actionbarService: ActionbarService,
    private navigateService: NavigationService) { 
        this.extractNavigationParams();
    }

  public actionBarHandler = new ActionBarHandlerModel(
    new NewButtonModel(),
    new CopyButtonModel(),
    new SaveButtonModel(),
    new CancelButtonModel(),
    new RefreshButtonModel()
  );

  get showDetailTab(): boolean {
    return this.selectedItem;
  }

  ngAfterViewInit(): void {
    this.createActionBarButton();
    this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      actionId => {
        switch (actionId) {
          case ACTION_STATUS.back:
            this.back();
            break;
          case ACTION_STATUS.cancel:
            if (this.selectedTab != this.statusTab) {
              this.selectedTab = this.statusTab;
            }
          case ACTION_STATUS.refresh:
            this.onRefresh();
            break;
        }
      }
    )
  }

  onRefresh() {
    if (this.panelId == "SEARCH") {
      this.loadingNotifier.show(this.SPINNER_NAME);
      this.refreshValue = true;
    }
  }

  setRefreshValue(value: boolean) {
    this.refreshValue = value;
    if (value == false) {
      this.loadingNotifier.hide(this.SPINNER_NAME);
    }
  }

  back() {
    this.navigateService.navigate("main/cashbook/home", null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private createActionBarButton() {
    this.newButton.enable(this.userSecurity?.newFlag);
    this.copyButton.enable(this.userSecurity?.copyFlag);
    this.saveButton.disable();
    this.cancelButton.disable();
    let actionbarDefaultState = new ActionBarHandlerModel(
      this.newButton,
      this.copyButton,
      this.saveButton,
      this.cancelButton,
      this.refreshButton
    );
    this.actionbarService.updateState(actionbarDefaultState);
  }

  onActiveIdChange(activeId: string) {
    this.panelId = activeId;
    if (activeId == this.searchTabId) {
      this.navigateService.navigate("main/cashbook/cashbook", null);
    }

    if (activeId == this.detailTab) {
      let paggingView = this.cashbookSearchComponent.paggingView;

      this.selectedItem = false;
      if (this.cashbookItem) {
        let param = {
          paymentId: this.cashbookItem.paymentId,
          documentNumber: this.cashbookItem.documentNumber,
          currentIndex: this.cashbookItem.no,
          pagingDataView: paggingView,
          ledgerId: this.cashbookItem.ledgerId,
          organisationId: this.searchCondition?.organisationId,
          organisationCallName: this.searchCondition?.organisationCallName,
          paymentDateFrom: this.searchCondition?.paymentDateFrom,
          paymentDateTo: this.searchCondition?.paymentDateTo,
          userSecurity: this.userSecurity,
          searchCondition: this.searchCondition
        }
        this.navigateService.navigate('main/cashbook/detail', null, null, param);
      }
    }

    if (activeId == this.documentTab) {
      let param = {
        organisationId: this.searchCondition?.organisationId,
        organisationCallName: this.searchCondition?.organisationCallName,
        paymentDateFrom: this.searchCondition?.paymentDateFrom,
        paymentDateTo: this.searchCondition?.paymentDateTo,
        userSecurity: this.userSecurity,
        searchCondition: this.searchCondition
      }
      this.navigateService.navigate('main/cashbook/document', null, null, param);
    }
  }

  getValueCondition(e: any) {
    this.searchCondition = e;
  }

  onSelectItem(e: any) {
    this.selectedItem = true;
    this.cashbookItem = e;
  }

  extractNavigationParams() {
    let params = this.navigateService.getParams();
    this.userSecurity = params?.userSecurity;
  }
}

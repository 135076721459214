import { Injectable } from '@angular/core';
import { OrderDetailProductChangeAvailabilityView } from './views/order-detail-product-change-availability.view';
import { OrderDetailProductChangeAvailabilityDateView } from './views/order-detail-product-change-availability-date.view';

import { DateConverterService } from '../../../core/utils/date-converter.service';
import { StringHelperService } from '../../../core/utils/string-helper.service';

import moment from 'moment';
import { OrderDetailProductChangeAvailabilityFlightView } from './views/order-detail-product-change-availability-flight.view';
import { DateHelperService } from 'src/app/core/utils/date-helper.service';
import { OrderDetailProductChangeAvailabilityFareView } from './views/order-detail-product-change-availability-fare.view';
import { OrderDetailProductChangeAvailabilityProductDetailView } from './views/order-detail-product-change-availability-product-detail.view';
import { OrderDetailProductChangeAvailabilityFareDescriptionView } from './views/order-detail-product-change-availability-fare-description.view';
import { OrderDetailProductChangeAvailabilityFareDescriptionDetailView } from './views/order-detail-product-change-availability-fare-description-detail.view';

@Injectable()
export class ProductReshopMapperService {

    private readonly LOCALIZATION: string = "en-Us";

    constructor(private dateConversion: DateConverterService,
        private stringUtils: StringHelperService,
        private dateHelper: DateHelperService) {

    }
    public toSearchResultViews(reshopOffer: any,
        departureDate: string,
        arrivalAirportCode: string,
        arrivalAirportName: string,
        departureAirportCode: string,
        departureAirportName: string): OrderDetailProductChangeAvailabilityView {

        return this.createAvailabilityView(reshopOffer,
            departureDate,
            departureAirportCode,
            departureAirportName,
            arrivalAirportCode,
            arrivalAirportName);
    }

    private createAvailabilityView(reshopOffer: any,
        departureDate: string,
        departureAirportCode: string,
        departureAirportName: string,
        arrivalAirportCode: string,
        arrivalAirportName: string): OrderDetailProductChangeAvailabilityView {

        let dateRanges = this.toSearchResultDate(reshopOffer,
            departureDate,
            departureAirportCode,
            arrivalAirportCode);

        const selectedOfferIds: string[] = this.getActiveTabId(dateRanges);
        return {
            departureAirportCode: departureAirportCode,
            departureAirportName: departureAirportName,
            arrivalAirportCode: arrivalAirportCode,
            arrivalAirportName: arrivalAirportName,
            departureDate: departureDate,
            activeTabId: this.combineSelectedJourneyId(selectedOfferIds),
            flightDateRanges: dateRanges,
            selectedOfferIds: selectedOfferIds
        }
    }
    private combineSelectedJourneyId(journeyIds: string[]): string {
        return journeyIds.join('|');
    }
    private getActiveTabId(dateRange: OrderDetailProductChangeAvailabilityDateView[]): string[] {
        if (!dateRange || dateRange.length === 0) {
            return null;
        }
        for (let i = 3; i < dateRange.length; i++) {
            const journeyId = dateRange[i].journeyIds;
            if (!journeyId || journeyId.length === 0) {
                continue;
            }
            return journeyId;
        }
        for (let i = 3; i > -1; i--) {
            const journeyId = dateRange[i].journeyIds;
            if (!journeyId || journeyId.length === 0) {
                continue;
            }
            return journeyId;
        }
    }
    private toSearchResultDate(reshopOffer: any,
        departureDate: string,
        departureLocationCode: string,
        arrivalLocationCode: string): OrderDetailProductChangeAvailabilityDateView[] {
        const numberOfDayAfter: number = -7;

        let views = new Array<OrderDetailProductChangeAvailabilityDateView>();

        for (let i = numberOfDayAfter; i < 0; i++) {

            const dayPlusMinus: number = (i + 4);
            const dateAddResult = moment(departureDate).add(dayPlusMinus, 'days').toDate();
            const journeys: any[] = this.findJourneys(dateAddResult, reshopOffer, departureLocationCode, arrivalLocationCode);
            if (!journeys || journeys.length == 0) {
                views.push({
                    flightDate: this.dateConversion.convertDayWeekMonthDate(dateAddResult),
                    journeyIds: null,
                    lowestFareAmount: null
                });
                continue;
            }

            const journeyIds = journeys.map(x => x.id);
            views.push({
                flightDate: this.dateConversion.convertDayWeekMonthDate(dateAddResult),
                journeyIds,
                lowestFareAmount: this.getLowestFair(reshopOffer?.response?.reshopResults?.reshopOffer?.offers, journeyIds)
            });
        }
        return views
    }
    private findJourneys(selectedDate: Date,
        offer: any,
        departureLocationCode: string,
        arrivalLocationCode: string): any[] {

        const originDestinationJourneyMap = this.getJourneyFromOriginDestination(offer,
            departureLocationCode,
            arrivalLocationCode);
        let journeys = new Array<any>();
        for (let journey of originDestinationJourneyMap) {
            const segmentMaps = this.getSegmentMapFromJourney(offer, journey);
            for (let segment of segmentMaps) {
                if (this.findDateFromSegmentMap(offer, segment.marketingSegmentId, selectedDate)) {
                    journeys.push(journey)
                }
            }
        }
        return journeys;
    }
    private getSegmentMapFromJourney(offer: any, journey: any): any[] {
        const segmentMap = offer.response.data.segmentMap;
        let segmentMaps = new Array<any>();
        for (let segmentId of journey.segmentIdList) {
            segmentMaps.push(segmentMap[segmentId]);
        }
        return segmentMaps;
    }
    private findDateFromSegmentMap(offer: any,
        marketingSegmentMapId: string,
        selectedDate: Date): boolean {

        const marketingSegmentMap = offer
            .response
            .data
            .marketingSegmentMap[marketingSegmentMapId];

        const scheduleTime = new Date(marketingSegmentMap.departurePoint.scheduledTime);
        const departureDate = new Date(this.dateConversion.convertUtcOffsetDateTime24(scheduleTime, marketingSegmentMap.departurePoint.utcOffset));
        return departureDate.toDateString() === selectedDate.toDateString();
    }
    private getLowestFair(offers: any, journeyIds: string[]): string {

        if (!journeyIds?.length || !offers?.length) {
            return null;
        }
        let amounts: number[] = [];
        let currency: string;
        for (let journeyId of journeyIds) {
            if (!journeyId) {
                continue;
            }
            let offerResults = this.getOfferFromJourney(offers, journeyId);
            for (let offer of offerResults) {
                if (!offer) {
                    continue;
                }
                currency = this.stringUtils.getCurrencySymbol(this.LOCALIZATION, offer.addOfferItem[0]
                    .reshopPrice
                    .priceDifferential
                    .diffPrice
                    .price
                    .totalAmount
                    .currencyCode);
                amounts.push(this.getTotalAmountDifferent(offer.addOfferItem));
            }
        }
        if (!amounts?.length) {
            return null;
        }
        return `${currency} ${this.stringUtils.amountString(Math.min(...amounts), 2)}`;
    }

    private getTotalAmountDifferent(addOfferItems: any): number {
        if (!addOfferItems) return 0;
        let differentAmount: number = 0;
        for (let addOfferItem of addOfferItems) {

            let differentPrice = addOfferItem
                .reshopPrice
                .priceDifferential
                .diffPrice
                .price
                .totalAmount
                .value;
            let numberOfPassenger = addOfferItem
                .reshopPrice
                .priceDifferential
                .newPrice
                .fareDetails[0]
                .passengerIdList.length;
            differentAmount += (differentPrice * numberOfPassenger);
        }
        return differentAmount;
    }

    public getOfferFromJourney(offers: any, journeyId: string): any[] {
        let offerResults = new Array<any>();
        for (let offer of offers) {
            const findOffer: boolean = offer.journeyOverview.journeyPriceClasses.some(x => x.journeyId === journeyId);
            if (findOffer) {
                offerResults.push(offer);
            }
        }
        return offerResults;
    }

    private getJourneyFromOriginDestination(offer: any,
        departureLocationCode: string,
        arrivalLocationCode: string) {

        const originDestinationMap = offer.response.data.originDestinationMap;
        const journeyMap = offer.response.data.journeyMap;

        for (let originDestination in originDestinationMap) {
            if (originDestinationMap.hasOwnProperty(originDestination)) {
                const data = originDestinationMap[originDestination];
                if (data.departureLocationCode === departureLocationCode
                    && data.arrivalLocationCode === arrivalLocationCode) {

                    return data.journeyIdList.map(x => {
                        return journeyMap[x];
                    });
                }
            }
        }
        return null;
    }

    public toFlightOfferViews(journeyIds: string[],
        reshopOffer: any): OrderDetailProductChangeAvailabilityFlightView[] {

        let flightOffers = new Array<OrderDetailProductChangeAvailabilityFlightView>();
        for (let id of journeyIds) {
            let marketingIds = this.getMarketingIdsMapFromOffer(reshopOffer, id);
            for (let i = 0; i < marketingIds.length; i++) {

                const marketingId = marketingIds[i];
                const marketingMap = reshopOffer.response.data.marketingSegmentMap[marketingId];
                const operatingMap = reshopOffer.response.data.operatingSegmentMap[marketingMap.operatingSegmentId];

                const [operatingLegId] = operatingMap?.operatingLegIdList;
                const operatingLegMap = reshopOffer.response.data.operatingLegMap[operatingLegId];

                const departurePoint = marketingMap.departurePoint;
                const arrivalPoint = marketingMap.arrivalPoint;
                const offers = reshopOffer?.response?.reshopResults?.reshopOffer?.offers;
                const priceClassMap = reshopOffer?.response?.data?.priceClassMap;
                const departureUtcOffset = this.getOfferUtcOffsetTime(operatingLegMap, departurePoint.locationCode);
                const arrivalUtcOffset = this.getOfferUtcOffsetTime(operatingLegMap, arrivalPoint.locationCode);
                flightOffers.push({
                    departureDateTime: this.dateConversion.convertUtcOffsetDateTime24(departurePoint.scheduledTime, departureUtcOffset),
                    departureTime: this.dateConversion.convertUtcOffsetSystemShortTime(departurePoint.scheduledTime
                        , departureUtcOffset),
                    arrivalTime: this.dateConversion.convertUtcOffsetSystemShortTime(arrivalPoint.scheduledTime
                        , arrivalUtcOffset),
                    provider: this.getProvider(marketingMap, operatingMap),
                    duration: this.getHoursMinuteDuration(operatingMap.duration),
                    stop: "Non Stops",
                    vehicle: "A340-400",
                    lowestOffer: this.getLowestFair(offers, null),
                    operatingSegmentId: marketingMap.operatingSegmentId,
                    operatingCode: operatingMap.carrier?.code,
                    operatingName: operatingMap.carrier?.name,
                    marketingSegmentId: marketingMap.id,
                    offerId: id,
                    showDetail: false,
                    fareDetails: this.getFareDetail(id, offers, priceClassMap),
                    fareDescription: null,
                    dayChange: this.getDayChange(departurePoint, arrivalPoint, departureUtcOffset, arrivalUtcOffset)
                });
            }
        }
        flightOffers.sort((a, b) => (a.departureDateTime < b.departureDateTime ? -1 : 1));
        return flightOffers;
    }

    private getFareDetail(journeyId: string,
        offers: any,
        priceClassMap: any): OrderDetailProductChangeAvailabilityFareView[] {

        let selectedOffers = this.getOffersFromJourney(offers, journeyId);
        let fareDetails: OrderDetailProductChangeAvailabilityFareView[] = [];

        for (let selectedOffer of selectedOffers) {
            let offer = offers.find(x => x.offerId === selectedOffer.offerId);
            let totalAmount = this.getTotalAmountDifferent(offer.addOfferItem);
            let totalCurrencyCode = offer.addOfferItem[0]
                .reshopPrice
                .priceDifferential
                .diffPrice
                .price
                .totalAmount
                .currencyCode;
            let classTypeDescription = this.getPriceClass(offers, priceClassMap, selectedOffer.offerId);

            fareDetails.push({
                priceClassId: classTypeDescription?.id,
                priceClass: classTypeDescription?.name,
                fareAmountNumber: totalAmount ?? 0.0,
                fareAmount: this.stringUtils.validateNullAmountDecimal(totalAmount),
                fareCurrency: this.stringUtils.getCurrencySymbol(this.LOCALIZATION, totalCurrencyCode),
                availableSeat: offer.seatsRemaining.count,
                fareStyleClass: null,
                offerId: selectedOffer.offerId,
                journeyId: journeyId,
                selectedFare: false
            });
        }
        return fareDetails.sort((a, b) => a.fareAmountNumber - b.fareAmountNumber);
    }

    private getPriceClass(offers: any, priceClassMap: any, offerId: string): any {

        if (!offerId) return;
        const selectedOffer = offers.find(x => x.offerId === offerId);
        if (!selectedOffer) {
            return null;
        }
        let priceClassId = selectedOffer?.journeyOverview?.priceClassId;
        if (!priceClassId) {
            return null;
        }
        let classMap = priceClassMap[priceClassId];
        return classMap;
    }

    private getOffersFromJourney(offers: any, journeyId: string): any[] {
        if (!offers?.length) return [];
        let selectedOffers = new Array<any>();
        for (let offer of offers) {

            const findOffer: boolean = offer.journeyOverview.journeyPriceClasses.some(x => x.journeyId === journeyId);
            if (findOffer) {
                selectedOffers.push(offer);
            }
        }
        return selectedOffers;
    }

    private getDayChange(departurePoint: any, arrivalPoint: any, departureUtcOffset: number, arrivalUtcOffset: number): string {

        const departureDate = this.dateConversion.convertOffsetDate(departurePoint.scheduledTime,
            departureUtcOffset);
        const arrivalDate = this.dateConversion.convertOffsetDate(arrivalPoint.scheduledTime,
            arrivalUtcOffset);
        const dayDifferent = this.dateHelper.DayDifferent(new Date(departureDate),
            new Date(arrivalDate));
        if (dayDifferent === 0) {
            return "";
        }
        if (dayDifferent > 0) {
            return `+ ${dayDifferent} day`;
        }
        return `${dayDifferent} day`;
    }

    private getProvider(marketingMap: any, operatingMap: any) {
        if (!marketingMap?.carrier || !marketingMap?.productNumber) {
            return `${operatingMap?.carrier?.code} ${operatingMap.productNumber}`;
        }
        return `${marketingMap?.carrier?.code} ${marketingMap.productNumber}`;
    }

    private getHoursMinuteDuration(duration: number): string {
        let minutes = Math.round(duration / 1000 / 60);
        const hours = Math.trunc(minutes / 60);
        minutes = minutes - hours * 60;

        return `${hours} hrs ${minutes} mins`;
    }

    private getMarketingIdsMapFromOffer(reshopOffer: any, journeyId: string): string[] {
        const journeyMap = reshopOffer.response.data.journeyMap[journeyId];
        let marketingSegmentIds = new Array<string>();
        for (let segmentId of journeyMap.segmentIdList) {
            const segment = reshopOffer?.response?.data?.segmentMap[segmentId];
            if (!segment) {
                continue;
            }
            marketingSegmentIds.push(segment.marketingSegmentId)
        }
        return marketingSegmentIds;
    }
    public getOfferUtcOffsetTime(operatingLegMap: any, airportCode: string): number {
        if (operatingLegMap.departurePoint.locationCode == airportCode) {
            return operatingLegMap.departurePoint.utcOffset;
        }
        if (operatingLegMap.arrivalPoint.locationCode == airportCode) {
            return operatingLegMap.arrivalPoint.utcOffset;
        }
        return 0;
    }
    public getShoppingProductDetail(operatingSegmentId: string,
        marketingSegmentId: string,
        reshopOffer: any): OrderDetailProductChangeAvailabilityProductDetailView {

        let marketing = reshopOffer.response.data.marketingSegmentMap[marketingSegmentId];
        let operating = reshopOffer.response.data.operatingSegmentMap[operatingSegmentId];
        let operatingLeg = reshopOffer.response.data.operatingLegMap[operating.operatingLegIdList[0]];
        let locationMap = reshopOffer.response.data.locationMap;
        return {
            aircraftType: operatingLeg.aircraftType.name,
            aircraftConfiguration: "",
            marketingProductNumber: this.getMarketingSegment(marketing),
            operatingProductNumber: `${operating.carrier.code} ${operating.productNumber}`,
            departureAirportCode: marketing.departurePoint.locationCode,
            departureAirportName: locationMap[marketing.departurePoint.locationCode].name,
            arrivalAirportCode: marketing.arrivalPoint.locationCode,
            arrivalAirportName: locationMap[marketing.arrivalPoint.locationCode].name,
            irregularity: null,
            originWeather: null,
            destinationWeather: null,
            parentProductId: null,
            departureTerminal: operatingLeg.departurePoint.terminal,
            arrivalTerminal: operatingLeg.arrivalPoint.terminal,
            departureGate: null,
            arrivalGate: null
        }
    }
    private getMarketingSegment(marketingMap: any): string {
        if (!marketingMap?.productNumber) {
            return "";
        }
        return marketingMap.carrier.code + " " + marketingMap.productNumber
    }

    public getFareDescription(priceClassId: string,
        priceClassMap: any): OrderDetailProductChangeAvailabilityFareDescriptionView {

        let fareClassDetail = this.getPriceClassDetail(priceClassMap, priceClassId)
        if (!fareClassDetail) {
            return;
        }
        return {
            priceClassId: priceClassId,
            details: this.createFareDescriptionDetail(fareClassDetail.ISEBASIC),
            more: fareClassDetail.ISEMORE
        };
    }

    private getPriceClassDetail(priceClassMap: any, priceClassId: string): any {
        let priceClass = priceClassMap[priceClassId]
        if (!priceClass) {
            return null;
        }
        return priceClass?.description?.map;
    }
    private createFareDescriptionDetail(priceClassMapMore: any): OrderDetailProductChangeAvailabilityFareDescriptionDetailView[] {
        if (!priceClassMapMore) {
            return null;
        }
        let fareDescriptions = new Array<OrderDetailProductChangeAvailabilityFareDescriptionDetailView>();
        for (let detail of priceClassMapMore) {
            fareDescriptions.push({
                type: detail.category,
                text: detail.text
            });
        }
        return fareDescriptions;
    }
}
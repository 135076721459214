import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductTypeGroupModel } from "../../models/reference-model/reference-product-model";

@Injectable({
    providedIn: "root",
})
export class ProductTypeGroupService extends ApiServiceBase {
    private readonly PATH = "/system/ProductTypeGroups";

    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getByProductGroupCode(productGroupCode: string): Observable<ProductTypeGroupModel[]> {
        const params = new HttpParams()
            .set('productGroupCode', productGroupCode);
        return this.httpGet<ProductTypeGroupModel[]>(this.PATH, params)
            .pipe(
                map(res => res.body)
            )
    }

    public getProductTypeGroupInOrderProduct(): Observable<ProductTypeGroupModel[]> {
        return this.httpGet<ProductTypeGroupModel[]>(this.PATH + "/orderProduct", null)
            .pipe(
                map(response => response.body)
            )
      }
}
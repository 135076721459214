import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeComponent } from './attribute.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AttributeContentModule } from './attribute-content/attribute-content.module';

@NgModule({
    declarations: [
        AttributeComponent
    ],
    imports: [
        AttributeContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [AttributeComponent]
})

export class AttributeModule { }

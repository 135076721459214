import { Component, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import {
  selectNavigationItems,
  NavigationItem,
} from 'src/app/store/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectSettingsState } from 'src/app/store/settings';
import { getRouterState } from './../../../store/router/router.selectors';

@Component({
  selector: 'smart-nav',
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavComponent implements OnDestroy {
  
  private destroy$: Subject<boolean> = new Subject<boolean>();
  vm$ = this.store.select(
    createSelector(
      selectNavigationItems,
      selectSettingsState,
      (items, settings) => ({ items, minified: settings.minifyNavigation })
    )
  );

  constructor(private store: Store<any>) {
    const authPaths = ['/auth', '/authentication', '/select-organisation', '/auth/forgot-password', '/auth/forgot-username'];
    this.store.select(getRouterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((routeState) => {
        if (authPaths.includes(routeState.state.url) || routeState.state.url.includes('/auth/change-password')) {
          this.vm$
            .pipe(takeUntil(this.destroy$))
            .subscribe((item) => {
              item.items = [];
            });
        }
      });
  }
 
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  trackByFn(idx: number, item: NavigationItem) {
    return item.title + '_' + idx;
  }
}

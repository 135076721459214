import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';

@Component({
    selector: 'op-navigation-index',
    templateUrl: './navigation-index.component.html'
})
export class NavigationIndexComponent {

    @Input() data: PagingDataView[] = new Array();
    @Input() page: number = 1;
    @Input() showToolTip: boolean =  false;
    @Input() disabled: boolean = false;
    @Output() pageChange = new EventEmitter<string>();

    messageSize: number = 1;
    maxSize: number = 5;
    pageSize: number = 1;
    delimiter: string = "|";
    id = new Date().getTime();
    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.messageSize = this.data.length;
    }

    onPageChange(page) {
        if (this.data.length) {
            var currentPage = this.data.filter(x => x.no == page);
            if (!currentPage) {
                return;
            }
            if (!currentPage[0].seriesId){
                this.pageChange.emit(currentPage[0].id);
            }
            else {
                this.pageChange.emit(currentPage[0].id + 
                this.delimiter + currentPage[0].seriesId); 
            }
        }
    }

    public getPageName(page) {
        var currentPage = this.data.find(x => x.no == page);
        if (!currentPage?.name) {
            return;
        }
        return currentPage.name;
    }

    public getTarget(p) :Element {
        return $("#divPage" + this.id + p)[0].offsetParent;
    }
}
import { Component, ViewChild, ElementRef, ChangeDetectorRef, SimpleChanges, OnChanges, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';

import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { UIService } from 'src/app/shared/ui/uiservice/ui.service';

import { SearchConditionComponent } from './search-condition/search-condition.component';
import { SearchTableComponent } from './search-table/search-table.component';
import { VehicleCompositionService } from 'src/app/core/services/vehicle-services';
import { VehicleCompositionModel, VehicleCompositionSearchCommandModel } from 'src/app/core/models/vehicle-model/vehicle-composition';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

declare var $: any;

@Component({
    selector: 'op-vehicle-composition-search',
    templateUrl: './vehicle-composition-search.component.html',
    providers: [UIService, FocusingService]
})
export class VehicleCompositionSearchComponent implements OnChanges, OnInit {
    readonly VEHICLECOMPOSITION = 'VEHICLECOMPOSITION';
    readonly STATUS_DELETE = 'D';

    @Input() refresh: boolean = false;
    @Input() executeFlag: boolean = true;
    @Output() onRefreshed = new EventEmitter();
    @ViewChild('searchConditionRef') searchConditionRef: ElementRef;
    @ViewChild('searchTableRef') searchTableRef: ElementRef;
    @ViewChild(FocusingDirective) searchResultFocusingDirective: FocusingDirective;
    @ViewChild(SearchConditionComponent) searchConditionCompoennt: SearchConditionComponent;
    @ViewChild(SearchTableComponent) searchTableComponent: SearchTableComponent;

    public doubleClickSubject$ = new Subject();
    public searchResults$ = new Observable<VehicleCompositionModel[]>();
    userSecurity: SecurityGroupSecurityModel
    
    constructor(
        private store: Store<any>,
        private uiService: UIService,
        private focusingService: FocusingService,
        private vehicleCompositionService: VehicleCompositionService,
        private securityGroupService: SecurityGroupService,
        private spinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.executeFlag === true) {
            this.searchResults$ = this.vehicleCompositionService
            .search(<VehicleCompositionSearchCommandModel>{
                statusCode: 'A'
            })
        }
        this.executeFlag = true;
        this.securityGroupService.userSecurityGroupSecurity
            .subscribe(userSecurity => this.userSecurity = userSecurity.find(s => s.securityCode == this.VEHICLECOMPOSITION))
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['refresh']) {
            this.refreshSearch();
        }
    }
    
    onSearch(command: VehicleCompositionSearchCommandModel) {
        this.searchResults$ = this.vehicleCompositionService.search(command);
        this.focusOnSearchResult();
    }

    private focusOnSearchResult() {
        this.uiService.scrollToPanel(this.store, this.searchTableRef);
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    private focusOnSearchCondition() {
        this.uiService.scrollToPanel(this.store, this.searchConditionRef);
        this.focusingService.focus(this.searchConditionCompoennt.focusingDirective);
    }

    onSearchConditionPanelClick(event) {
        let searchBtn = $('#searchConditionSearchBtn')?.get(0);
        let searchBtnClicked = searchBtn?.contains(event.srcElement);
        if (searchBtnClicked) {
            this.focusOnSearchResult();
        } else {
            this.focusOnSearchCondition();
        }
    }
    
    onClear() {
        this.searchConditionCompoennt.initForm();
        this.searchResults$ = new Observable<VehicleCompositionModel[]>();
        this.searchTableComponent.dataGrid.instance.clearFilter();
    }

    reSearch() {
        this.searchConditionCompoennt.doSearch();
    }

    onUpdateStatus(item) {
        this.updateStatus(item.vehicleCompositionId, item.statusCode);
    }

    onDeleteItem(item) {
        this.updateStatus(item.vehicleCompositionId, this.STATUS_DELETE);
    }

    updateStatus(vehicleCompositionId: string, statusCode: string) {
        if (!vehicleCompositionId || !statusCode) {
            return;
        }
        this.spinnerService.showSaving();
        this.vehicleCompositionService.updateStatus(vehicleCompositionId, statusCode)
            .subscribe(
                () => {
                    this.spinnerService.saveComplete();
                    this.searchConditionCompoennt.doSearch();
                    this.changeDetectorRef.detectChanges();
                }
            )
    }

    private refreshSearch() {
        if (this.refresh) {
            this.onClear();
            this.reSearch();
            this.onRefreshed.emit();
        }
    }
    
    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchConditionCompoennt.doSearch();
                break;
            default:
                break;
        }
    }    
}
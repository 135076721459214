export const ACCEPT_COOKIES = 'accept_cookies'
export const AUTH_TOKEN: string = 'auth_token';
export const ORGANISATION_COUNT = 'org_count';
export const REMEMBER_ME_COOKIES = 'remember_me'
export const REMEMBER_ME_SPLIT_SYMBOL = "{}";
export const REMEMBER_ME_SELECTOR = 1;
export const REMEMBER_ME_VALIDATOR = 2;
export const REMEMBER_ME_SELECTOR_EQUAL_ENCODE = "{equal}"
export const REMEMBER_ME_SELECTOR_EQUAL_DECODE = "="
export const STORAGE_LOGIN_ORGANISATION_ID = 'organisation';
export const STORAGE_LOGIN_USER = 'login_user';
export const USER_INFORMATION = 'user_info';
export const USER_LOGON_DATA = 'user_logon_data';
export const USER_LOGON_NAME = 'user_logon_name';
export const USER_PROFILE_IMAGE = 'user_profile_img';
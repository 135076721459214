import { Select2Themes } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service";

export const TypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<type>",
    disabled: true,
    theme: Select2Themes.DISABLED_THEME,
    width: "auto"
}

export const UnitTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Unit Type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ConditionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    theme: Select2Themes.DISABLED_THEME,
    width: "auto",
    disabled: true
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiServiceBase } from 'src/app/core/base/api-service-base';

import {
    OrganisationGroupReferenceModel,
    OrganisationRoleReferenceModel,
    OrganisationTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import {
    OrganisationRoleReferenceService,
    OrganisationTypeReferenceService
} from 'src/app/core/services/system-services';
import { OrganisationGroupReferenceService } from 'src/app/core/services/airline-services/organisation-group-reference.service';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { OrganisationModel } from 'src/app/core/models/organisation-model';

@Injectable({
    providedIn: 'root'
})
export class PointOfSalesAjaxService extends ApiServiceBase {
    private readonly PRICING_PATH = "/pricing"
    private readonly REFERENCE_PATH = "/airline"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private organisationTypeRefrenceService: OrganisationTypeReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationService: OrganisationService
    ) {
        super(httpClient, authService);
    }
    public toOrganisationTypeAjaxOption() {

        return {
            transport: (params, success, failure) => {
                var request = this.organisationTypeRefrenceService.getOrganisationTypeByName(params.data.term)
                    .subscribe((response: OrganisationTypeReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.organisationTypeCode, d.organisationTypeName))
                        })
                    });
            }
        }
    }

    public toOrganisationRoleAjaxOption() {
        return {
            transport: (params, success, failure) => {
                var request = this.organisationRoleReferenceService.getByName(params.data.term)
                    .subscribe((response: OrganisationRoleReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.organisationRoleCode, d.organisationRoleName))
                        })
                    });
            }
        };
    }

    public toOrganisationGroupAjaxOption() {
        return {
            transport: (params, success, failure) => {
                var request = this.organisationGroupReferenceService.getOrganisationGroupReference()
                    .subscribe((response: OrganisationGroupReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.organisationGroupCode, d.organisationGroupName))
                        })
                    });
            }
        }
    }

    public toOrganisationIdAjaxOption() {
        return {
            transport: (params, success, failure) => {
                var request = this.organisationService
                    .getByStatus('A')
                    .subscribe((response: OrganisationModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.organisationId, d.organisationCallName))
                        })
                    });
            }
        }
    }
}

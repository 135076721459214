import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LocationPointModel } from 'src/app/core/models/merchandizing-config';

@Injectable()
export class LocationSetDefaultService {

    public setDefaultLocationPoint(f: UntypedFormGroup, locationPoint$: BehaviorSubject<LocationPointModel[]>, locationPointOption) {
        if (locationPoint$.value) {
            var filterplaceHolder = locationPoint$.value.filter(x => x.locationPointCode == "");
            if (filterplaceHolder.length != 0) {
                locationPointOption.placeholder = filterplaceHolder[0].locationPointName;
            }
            else {
                if (locationPoint$.value.length != 0) {
                    f.controls['locationPoint'].setValue(locationPoint$.value[0].locationPointCode);
                }
            }
        }
    }

    public setDefaultLocationPointArea(f: UntypedFormGroup, locationPoint$: BehaviorSubject<LocationPointModel[]>, areaTypeOption) {
        if (locationPoint$.value) {
            var filterLocationPoint = locationPoint$.value.filter(x => x.locationPointCode != "");
            if (filterLocationPoint.length != 0) {
                let areaData = filterLocationPoint[0].areas.filter(x => x.areaCode != "");
                f.controls['locationPointAreasData'].setValue(areaData);

                var filterplaceHolder = filterLocationPoint[0].areas.filter(x => x.areaCode == "");
                if (filterplaceHolder.length != 0) {
                    areaTypeOption.placeholder = filterplaceHolder[0].areaName;
                }
                else {
                    f.controls['locationPointAreas'].setValue(areaData[0].areaCode);
                }
            }
        }
    }

    public setDefaultLocationOperator(f: UntypedFormGroup, locationPoint$: BehaviorSubject<LocationPointModel[]>, locationTypeOption) {
        if (locationPoint$.value) {
            var filterLocationPoint = locationPoint$.value.filter(x => x.locationPointCode != "");
            if (filterLocationPoint.length != 0) {
                f.controls['locationPointOperatorData'].setValue(filterLocationPoint[0].operators);

                var filterplaceHolder = filterLocationPoint[0].operators.filter(x => x.operatorCode == "");
                if (filterplaceHolder.length != 0) {
                    locationTypeOption.placeholder = filterplaceHolder[0].operatorName;
                }
                else {
                    f.controls['locationPointOperator'].setValue(filterLocationPoint[0].operators[0].operatorCode);
                }
            }
        }
    }

    public setDefaultLocationAreaOD(f: UntypedFormGroup, locationPoint$: BehaviorSubject<LocationPointModel[]>, locationTypeOption) {
        if (locationPoint$.value) {
            var filterLocationPoint = locationPoint$.value.filter(x => x.locationPointCode == "service");
            if (filterLocationPoint.length != 0) {
                let odData = filterLocationPoint[0].ods.filter(x => x.odCode != "");
                f.controls['locationPointODsData'].setValue(odData);
                var filterplaceHolder = filterLocationPoint[0].ods.filter(x => x.odCode == "");
                if (filterplaceHolder.length != 0) {
                    locationTypeOption.placeholder = filterplaceHolder[0].odName;
                }
                else {
                    f.controls['locationPointODs'].setValue(odData[0].odCode);
                }
            }
        }
    }

    public setDefaultLocationArea(f: UntypedFormGroup, config, locationAreasOption) {
        var filterType = config.types.filter(x => x.typeCode == "location");
        if (filterType.length != 0) {
            let locationAreasData = filterType[0].areas.filter(x => x.areaCode != "");
            f.controls['locationAreasData'].setValue(locationAreasData);

            var filterplaceHolder = filterType[0].areas.filter(x => x.areaCode == "");
            if (filterplaceHolder.length != 0) {
                locationAreasOption.placeholder = filterplaceHolder[0].areaName;
            }
            else {
                f.controls['locationAreas'].setValue(locationAreasData[0].areaCode);
            }
        }
    }

    public setDefaultLocationAreaOperator(f: UntypedFormGroup, config, locationAreasOperatorOption) {
        var filterType = config.types.filter(x => x.typeCode == "location");
        if (filterType.length != 0) {
            let locationAreasData = filterType[0].areas.filter(x => x.areaCode != "");
            let operatorData = locationAreasData[0].operators;
            f.controls['locationAreasOperatorsData'].setValue(operatorData);
            var filterplaceHolder = operatorData.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                locationAreasOperatorOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                f.controls['locationAreasOperators'].setValue(operatorData[0].operatorCode);
            }
        }
    }

}
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { PaymentSearch, PaymentSearchCriteria } from "./payment-search.model";
import { PaymentSearchService } from "./payment-search.service";
import { ActionService } from "src/app/core/utils/action.service";

@Component({
    selector: 'op-payment-search',
    templateUrl: './payment-search.component.html'
})
export class PaymentSearchComponent implements OnInit, OnDestroy {

    readonly LoadingName = 'payment'

    paymentSearch: PaymentSearch[]
    subscriptions: Subscription[]
    loadingNotifier = new LoadingNotifier()
    searchCriteria: PaymentSearchCriteria

    constructor(private paymentSearchService: PaymentSearchService,
        private actionService: ActionService,) {
    }

    ngOnInit(): void {
        this.subscriptions = []
        this.subscriptions.push(this.paymentSearchService.getUserOrganisationUser().subscribe())
        this.subscriptions.push(this.paymentSearchService.getPaymentCurrency().subscribe())
        this.subscriptions.push(this.paymentSearchService.getPaymentStatus().subscribe())
        this.subscriptions.push(this.paymentSearchService.getPaymentType().subscribe())
        this.subscriptions.push(this.paymentSearchService.getPaymentAdvanceSearch().subscribe())
        this.subscriptions.push(this.paymentSearchService.getBalanceAdvanceSearch().subscribe())
        this.subscriptions.push(this.paymentSearchService.getVoidedAdvanceSearch().subscribe())
        this.subscriptions.push(this.paymentSearchService.getPaymentOrganisations().subscribe())
        this.subscriptions.push(this.paymentSearchService.loading$.subscribe(
            present => present ? this.loadingNotifier.show(this.LoadingName) : this.loadingNotifier.hide(this.LoadingName)))
        this.subscriptions.push(this.paymentSearchService.executeSearch$.subscribe(
            () => this.search()))
        this.actionService.add('SEARCH', null, null)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    onSubmit(criteria: PaymentSearchCriteria) {
        this.searchCriteria = criteria
        this.search()
    }

    search() {
        this.loadingNotifier.show(this.LoadingName)
        this.subscriptions.push(this.paymentSearchService.getPaymentSearch(this.searchCriteria).subscribe(
            () => this.loadingNotifier.hide(this.LoadingName),
            () => this.loadingNotifier.hide(this.LoadingName)
        ))
    }
}

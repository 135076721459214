import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { ProductServiceRequestModel } from "src/app/core/models/product-model/specialservice-model";
import { SeatPropertyGroupReferenceModel, SeatPropertyReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { VehicleConfigurationCompartmentPropertyCommandModel, VehicleConfigurationCompartmentPropertyModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment-property";
import { UiHelperService } from "src/app/core/utils/ui-helper.service";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { VehicleConfigurationSeatPropertyDetailComponent } from "./seatproperty-detail/seatproperty-detail.component";
import { SeatPropertyView } from "./shared/seatproperty.view";
import { VehicleConfigurationSeatPropertyListComponent } from "./seatproperty-list/seatproperty-list.component";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { VehicleConfigurationCompartmentModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment";
import { SeatPropertyMapperService } from "./shared/seatproperty-mapper.service";

@Component({
    selector: 'op-vehicle-configuration-seatproperty',
    templateUrl: './seatproperty.component.html',
    providers: [SeatPropertyMapperService]
})
export class VehicleConfigurationSeatPropertyComponent implements OnChanges {
    readonly SINGLE_POSITION_OFFSET = 720;
    readonly MULTIPLE_POSITION_OFFSET = 330;
    readonly PROPERTYLIST_POSITION_OFFSET = 320;
    @Input() id: string;
    @Input() serviceCategorydSelected: Select2Data;
    @Input() seatMapRowSelected: number[];
    @Input() seatMapColSelected: string;
    @Input() seatMapColAll: string;
    @Input() seatPropertyReferences: SeatPropertyReferenceModel[];
    @Input() seatPropertyGroupReferences: SeatPropertyGroupReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() productServiceRequests: ProductServiceRequestModel[];
    @Input() vehicleConfigurationCompartments: VehicleConfigurationCompartmentModel[];
    @Output() formStatus = new EventEmitter<boolean>();
    @Output() seatPropertySelected = new EventEmitter<SeatPropertyView>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(VehicleConfigurationSeatPropertyListComponent) seatMapListComponent: VehicleConfigurationSeatPropertyListComponent;
    @ViewChild(VehicleConfigurationSeatPropertyDetailComponent) seatMapDetailComponent: VehicleConfigurationSeatPropertyDetailComponent;
    @ViewChild("seatMapDetail") seatMapDetail: ElementRef;
    @ViewChild("seatMapList") seatMapList: ElementRef;

    focusing: boolean = false;
    newFromDetail: boolean = false;
    newFromGrid: boolean = false;
    editFromGrid: boolean = false;
    seatPropertyViews: SeatPropertyView[] = new Array();
    seatPropertyServiceCategoryViews: SeatPropertyView[] = new Array();
    model: SeatPropertyView;
    constructor(private focusingService: FocusingService,
        private uiHelper: UiHelperService,
        private changeDetectorRef: ChangeDetectorRef,
        private seatPropertyMapperService: SeatPropertyMapperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['serviceCategorydSelected']) {
            this.getVehicleConfigurationCompartmentProperty();
        }
    }

    private getVehicleConfigurationCompartmentProperty() {
        this.getToSelctedServiceCategoryViews();
        this.getToAllServiceCategoryViews();
    }

    onApply(view: SeatPropertyView) {
        this.saveToSelectedServiceCategoryViews(view);
        this.saveToAllViews(view);
        if (this.isMultipleRecord()) {
            this.resetModeMultiple();
            this.seatPropertySelected.emit(null);
        }
        this.displayInCondition();
    }

    private getToSelctedServiceCategoryViews() {
        if (this.vehicleConfigurationCompartments) {
            if (this.isNotInAllViews()) {
                this.getFromVehicleConfigurationCompartment();
            } else {
                this.getFromAllViews();
            }
        } else {
            this.seatPropertyServiceCategoryViews = [];
            this.model = null;
        }
    }

    private getToAllServiceCategoryViews() {
        if (this.vehicleConfigurationCompartments) {
            if (this.isNotInAllViews()) {
                let filter = this.vehicleConfigurationCompartments.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
                if (filter?.length) {
                    this.seatPropertyViews = this.seatPropertyMapperService.toSeatPropertyViews(filter[0].vehicleConfigurationCompartmentProperties, this.seatPropertyViews);
                }
            }
        }
        else {
            this.seatPropertyViews = [];
        }
    }

    private isNotInAllViews(): boolean {
        let filter = this.seatPropertyViews.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
        if (!filter?.length) {
            return true
        }
        return false;
    }

    private getFromVehicleConfigurationCompartment() {
        let filter = this.vehicleConfigurationCompartments.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
        if (filter?.length) {
            this.seatPropertyServiceCategoryViews = this.seatPropertyMapperService.toSeatPropertyViews(filter[0].vehicleConfigurationCompartmentProperties);
        }
        this.displayInCondition();
    }

    private getFromAllViews() {
        let filter = this.seatPropertyViews.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
        this.seatPropertyServiceCategoryViews = cloneDeep(filter);
        this.displayInCondition();
    }

    private displayInCondition() {
        if (this.isNoRecord()) {
            this.model = null;
        } else if (this.isSingleRecord()) {
            this.model = this.seatPropertyServiceCategoryViews[0];
        }

        if (this.isMultipleRecord()) {
            this.seatPropertySelected.emit(null);
        } else {
            this.seatPropertySelected.emit(this.model);
        }
    }

    public updateIdToExistView(vehicleConfigurationCompartments: VehicleConfigurationCompartmentModel[]) {
        this.vehicleConfigurationCompartments = vehicleConfigurationCompartments;
        this.updateIdToSelectedServiceCategoryViews();
        this.updateIdToAllViews();
        this.updateIdToModel();
    }

    private updateIdToSelectedServiceCategoryViews() {
        for (let view of this.seatPropertyServiceCategoryViews) {
            let filterCompartment = this.filterCompartment(view.vehicleConfigurationCompartmentId);
            if (filterCompartment?.length) {
                let filterSeat = this.filterSeatProperty(filterCompartment[0], view.seatPropertyId, view.seatRowFrom, view.seatRowTo);
                if (filterSeat?.length) {
                    view.vehicleConfigurationCompartmentPropertyId = filterSeat[0].vehicleConfigurationCompartmentPropertyId;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    private updateIdToAllViews() {
        for (let view of this.seatPropertyViews) {
            let filterCompartment = this.filterCompartment(view.vehicleConfigurationCompartmentId);
            if (filterCompartment?.length) {
                let filterSeat = this.filterSeatProperty(filterCompartment[0], view.seatPropertyId, view.seatRowFrom, view.seatRowTo);
                if (filterSeat?.length) {
                    view.vehicleConfigurationCompartmentPropertyId = filterSeat[0].vehicleConfigurationCompartmentPropertyId;
                }
            }
        }
    }

    private updateIdToModel() {
        if (this.isSingleRecord()) {
            this.model = this.seatPropertyServiceCategoryViews[0];
        }
    }

    updateSeatMapRowSelected(seapMapRowSelected: number[]) {
        this.seatMapRowSelected = seapMapRowSelected;
        if (this.seatMapDetailComponent) {
            this.seatMapDetailComponent.updateSeatMapRowSelected(this.seatMapRowSelected);
        }
    }

    updateSeatMapColAll(seatMapColAll: string) {
        this.seatMapColAll = seatMapColAll;
        if (this.seatMapDetailComponent) {
            this.seatMapDetailComponent.updateSeatMapColAll(this.seatMapColAll);
        }
    }


    updateSeatMapColSelected(seatMapColSelected: string, seatMapColAll: string) {
        this.seatMapColSelected = seatMapColSelected;
        this.seatMapColAll = seatMapColAll;
        if (this.seatMapDetailComponent) {
            this.seatMapDetailComponent.updateSeatMapColSelected(this.seatMapColSelected, this.seatMapColAll);
        }
    }

    updateSeatPropertyServiceCategoryViewsToDetail() {
        this.seatMapDetailComponent.updateSeatPropertyServiceCategoryViews(this.seatPropertyServiceCategoryViews);
    }

    private fillRowAndColumn() {
        this.seatMapDetailComponent.updateSeatMapRowSelected(this.seatMapRowSelected);
        this.seatMapDetailComponent.updateSeatMapColSelected(this.seatMapColSelected, this.seatMapColAll);
    }

    private filterCompartment(vehicleConfigurationCompartmentId: string): VehicleConfigurationCompartmentModel[] {
        return this.vehicleConfigurationCompartments.filter(
            x => x.vehicleConfigurationCompartmentId == vehicleConfigurationCompartmentId);
    }

    private filterSeatProperty(compartment: VehicleConfigurationCompartmentModel, seatPropertyId: string, seatRowFrom: number, seatRowTo: number): VehicleConfigurationCompartmentPropertyModel[] {
        return compartment.vehicleConfigurationCompartmentProperties.filter(
            x => x.seatPropertyId == seatPropertyId &&
                x.seatRowFrom == seatRowFrom &&
                x.seatRowTo == seatRowTo);
    }

    private saveToSelectedServiceCategoryViews(view: SeatPropertyView) {
        var index = this.seatPropertyServiceCategoryViews.findIndex(x => x.no == view.no);
        if (index == -1) {
            view.no = this.seatPropertyServiceCategoryViews.length + 1;
            this.seatPropertyServiceCategoryViews.push(view);
        }
        else {
            this.seatPropertyServiceCategoryViews[index] = view;
        }
    }

    private saveToAllViews(view: SeatPropertyView) {
        var index = this.seatPropertyViews.findIndex(x => x.vehicleConfigurationCompartmentId == view.vehicleConfigurationCompartmentId && x.no == view.no);
        if (index == -1) {
            view.no = this.seatPropertyViews.filter(x => x.vehicleConfigurationCompartmentId == view.vehicleConfigurationCompartmentId).length + 1;
            this.seatPropertyViews.push(view);
        }
        else {
            this.seatPropertyViews[index] = view;
        }
    }

    isSingleRecord(): boolean {
        return (this.seatPropertyServiceCategoryViews?.length == 1);
    }

    isNoRecord(): boolean {
        return (!this.seatPropertyServiceCategoryViews?.length == true);
    }

    isMultipleRecord(): boolean {
        return (this.seatPropertyServiceCategoryViews?.length > 1);
    }

    onNewFromDetail() {
        this.setNewModeFromDetail().then(() => {
            this.clearSeatSelected();
            this.fillRowAndColumn();
            this.updateSeatPropertyServiceCategoryViewsToDetail();
            this.moveToDetail(this.SINGLE_POSITION_OFFSET);
            this.focusingService.focus(this.seatMapDetailComponent.focusingDirective);
        });
    }

    onNewFromGrid() {
        this.setNewMode().then(() => {
            this.clearSeatSelected();
            this.fillRowAndColumn();
            this.updateSeatPropertyServiceCategoryViewsToDetail();
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onEdit(view: SeatPropertyView) {
        this.setEditMode(view).then(() => {
            this.updateSeatPropertyServiceCategoryViewsToDetail();
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onCopy(view: SeatPropertyView) {
        this.setCopyMode(view).then(() => {
            this.updateSeatPropertyServiceCategoryViewsToDetail();
        });
    }

    onCancel() {
        if (this.isNoRecord()) {
            this.resetModeNoRecord();
            this.clearDataCancel();
        } else if (this.isSingleRecord() && !this.newFromDetail) {
            this.resetModeSingle();
            this.clearDataCancel();
        } else if (this.isSingleRecord() && this.newFromDetail) {
            this.resetModeSingleNewDetail();

        } else {
            this.resetModeMultiple();
        }
    }

    onRefresh() {
        this.resetMode().then(() => {
            this.refreshCurrentServiceCateView();
            this.refreshAllView();
            this.displayInCondition();
        });
    }

    private refreshCurrentServiceCateView() {
        this.seatPropertyServiceCategoryViews = [];
        let filterExists = this.vehicleConfigurationCompartments.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
        if (filterExists?.length) {
            this.seatPropertyServiceCategoryViews = this.seatPropertyMapperService.toSeatPropertyViews(filterExists[0].vehicleConfigurationCompartmentProperties);
        }
    }

    private refreshAllView() {
        this.removeServiceCateSelected().then(() => {
            let filterExists = this.vehicleConfigurationCompartments.filter(x => x.vehicleConfigurationCompartmentId == this.serviceCategorydSelected.id);
            if (filterExists?.length) {
                this.seatPropertyViews = this.seatPropertyMapperService.toSeatPropertyViews(filterExists[0].vehicleConfigurationCompartmentProperties, this.seatPropertyViews);
            }
        });
    }

    private removeServiceCateSelected(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.seatPropertyViews.forEach((item, index) => {
                if (item.vehicleConfigurationCompartmentId === this.serviceCategorydSelected.id) this.seatPropertyViews.splice(index, 1);
            });
            resolve();
        });
    }

    private resetModeNoRecord() {
        this.resetMode().then(() => {
            this.focusingService.focus(this.seatMapDetailComponent.focusingDirective);
        });
    }

    private resetModeSingle() {
        this.resetMode().then(() => {
            this.seatPropertyServiceCategoryViews = [];
            this.focusingService.focus(this.seatMapDetailComponent.focusingDirective);
        });
    }

    private resetModeSingleNewDetail() {
        this.resetModeNewFromDetaill().then(() => {
            this.focusingService.focus(this.seatMapDetailComponent.focusingDirective);
            this.seatMapDetailComponent.applied = true;
        });
    }

    private resetModeMultiple() {
        this.resetMode().then(() => {
            this.moveToList(this.PROPERTYLIST_POSITION_OFFSET);
        });
    }

    private resetMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.seatMapDetailComponent?.completeProcessing();
            this.updateSeatMapColAll(this.seatMapColAll);
            this.updateSeatMapRowSelected(null);
            this.seatPropertySelected.emit(null);
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private resetModeNewFromDetaill(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = this.seatPropertyServiceCategoryViews[0];
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewModeFromDetail(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromDetail = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setEditMode(view: SeatPropertyView): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = view;
            this.newFromGrid = false;
            this.editFromGrid = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setCopyMode(view: SeatPropertyView): Promise<void> {
        return new Promise((resolve, reject) => {
            let copyData = cloneDeep(view);
            copyData.no = null;
            copyData.vehicleConfigurationCompartmentPropertyId = null;
            this.model = copyData;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    public moveToDetail(offset: number) {
        this.uiHelper.moveScrollPosition(this.seatMapDetail, offset);
    }

    private moveToList(offset: number) {
        this.uiHelper.moveScrollPosition(this.seatMapList, offset);
        this.focusingService.focus(this.seatMapListComponent.focusingDirective);
    }

    getValue(): VehicleConfigurationCompartmentPropertyCommandModel[] {
        return this.seatPropertyMapperService.toSeatPropertyModels(this.seatPropertyViews);
    }

    validateForm(): boolean {
        if (!this.seatMapDetailComponent) {
            return true;
        }

        if (this.seatMapDetailComponent.processing) {
            return this.seatMapDetailComponent.validateForm();
        }
        return true;
    }

    getErrorMessageForm(): string {
        if (this.seatMapDetailComponent) {
            return this.seatMapDetailComponent.getErrorMessageForm();
        }
    }

    onformStatusChange(formStatus: boolean) {
        this.formStatus.emit(formStatus);
    }

    onPropertyListRowSelelected(view: SeatPropertyView) {
        this.seatPropertySelected.emit(view);
    }

    private clearSeatSelected() {
        this.seatMapRowSelected = [];
        this.seatMapColSelected = "";
        this.seatPropertySelected.emit(null);
    }

    private clearDataCancel() {
        this.removeFromViews();
    }

    private removeFromViews() {
        this.seatPropertyViews = this.seatPropertyViews?.
        filter(x => x.vehicleConfigurationCompartmentId !== 
        this.seatPropertyServiceCategoryViews[0].vehicleConfigurationCompartmentId);
        this.seatPropertyServiceCategoryViews = [];
    }
}
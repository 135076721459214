import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrderRevenueCommandModel } from "../../models/order-model/order-dashboard-model/order-revenue-command.model";
import { OrderRevenueOrganisationViewModel } from "../../models/order-model/order-dashboard-model/order-revenue-organisation-view.model";
import { OrderRevenueProductViewModel } from "../../models/order-model/order-dashboard-model/order-revenue-product-view.model";

@Injectable({
    providedIn: 'root'
})
export class OrderRevenueService extends ApiServiceBase {
    private readonly PATH: string = "/order/orderrevenues"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getOrderRevenueByOrganisationType(command: OrderRevenueCommandModel): Observable<OrderRevenueOrganisationViewModel[]> {
        return this.httpPost<OrderRevenueOrganisationViewModel[]>(this.PATH + '/organisationtype', command)
          .pipe(
            map(res => res.body)
          );
    }

    public getOrderRevenueByProductType(command: OrderRevenueCommandModel): Observable<OrderRevenueProductViewModel[]> {
      return this.httpPost<OrderRevenueProductViewModel[]>(this.PATH + '/producttype', command)
        .pipe(
          map(res => res.body)
        );
  }
}
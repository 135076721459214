import { Injectable } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    ProductRestrictionLocationViewModel,
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import {
    ProductRestrictionOrganisationViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { PointOfSalesView } from '../views/point-of-sales.view';

@Injectable()
export class PointOfSalesViewMapperService {

    public productPointOfSalesFormToViews(forms): PointOfSalesView[] {
        let commandViews: PointOfSalesView[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == "VALID") {
                    ++i;
                    switch (ctl.value.type) {
                        case 'location': {
                            this.location(ctl, commandViews, i);
                            break;
                        }
                        case 'organisation': {
                            this.organisation(ctl, commandViews, i);
                            break;
                        }
                    }
                }
            }
        }
        return commandViews;
    }

    private productRestrictionLocationAreasRegionView(index: number, value, regionCode): PointOfSalesView {
        let model = new ProductRestrictionLocationViewModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, regionCode);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.regionCode = regionCode;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationView = model;
        return commandView;
    }

    private productRestrictionLocationAreasCountryView(index: number, value, countryCode): PointOfSalesView {
        let model = new ProductRestrictionLocationViewModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, countryCode);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.countryCode = countryCode;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationView = model;
        return commandView;
    }

    private productRestrictionLocationAreasAirportView(index: number, value, locationId): PointOfSalesView {
        let model = new ProductRestrictionLocationViewModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationId);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.locationId = locationId;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationView = model;
        return commandView;
    }

    private productRestrictionLocationAreasGroupView(index: number, value, locationGroupId): PointOfSalesView {
        let model = new ProductRestrictionLocationViewModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationGroupId);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.locationGroupId = locationGroupId;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationView = model;
        return commandView;
    }

    private productRestrictionOrganisationTypeView(index: number, value, organisationTypeCode): PointOfSalesView {
        let model = new ProductRestrictionOrganisationViewModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationTypeCode);
        }
        model.organisationTypeCode = organisationTypeCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationView = model;
        return commandView;
    }

    private productRestrictionOrganisationGroupView(index: number, value, organisationGroupCode): PointOfSalesView {
        let model = new ProductRestrictionOrganisationViewModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationGroupCode);
        }
        model.organisationGroupCode = organisationGroupCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationView = model;
        return commandView;
    }

    private productRestrictionOrganisationRoleView(index: number, value, organisationRoleCode: string): PointOfSalesView {
        let model = new ProductRestrictionOrganisationViewModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationRoleCode);
        }
        model.organisationRoleCode = organisationRoleCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationView = model;
        return commandView;
    }

    private productRestrictionOrganisationSpecificView(index: number, value, organisationId): PointOfSalesView {
        let model = new ProductRestrictionOrganisationViewModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationId);
        }
        model.organisationId = organisationId;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;
        model.inherit = false;

        let commandView = new PointOfSalesView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationView = model;
        return commandView;
    }

    public productRestrictionLocationPointOfSalesViewModels(views: PointOfSalesView[]): ProductRestrictionLocationViewModel[] {
        let models: ProductRestrictionLocationViewModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == "location");
            for (let view of viewFilters) {
                if (view.productRestrictionLocationView) {
                    models.push(view.productRestrictionLocationView);
                }
            }
        }
        return models;
    }

    public productRestrictionOrganisationViewModels(views: PointOfSalesView[]): ProductRestrictionOrganisationViewModel[] {
        let models: ProductRestrictionOrganisationViewModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == "organisation");
            for (let view of viewFilters) {
                if (view.productRestrictionOrganisationView) {
                    models.push(view.productRestrictionOrganisationView);
                }
            }
        }
        return models;
    }

    public mergePointOfSalesToProductLocationRestrictionView(restrictions: ProductRestrictionLocationViewModel[], pointOfSales: ProductRestrictionLocationViewModel[]): ProductRestrictionLocationViewModel[] {
        for (let command of pointOfSales) {
            restrictions.push(command);
        }
        return restrictions;
    }   

    private getExistProductRestrictionLocationId(locationArrayDBId: Select2Data[], value) {
        if (locationArrayDBId) {
            var filter = locationArrayDBId.filter(x => x.text == value);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
    }

    private location(ctl: any, commandViews: PointOfSalesView[], i: number) {
        switch (ctl.value.locationAreas) {
            case 'region': {
                this.region(ctl, commandViews, i);
                break;
            }
            case 'country': {
                this.country(ctl, commandViews, i);
                break;
            }
            case 'airport': {
                this.airport(ctl, commandViews, i);
                break;
            }
            case 'locationgroup': {
                this.locationgroup(ctl, commandViews, i);
                break;
            }
        }
    }

    private region(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let regionCode of ctl.value.locationAreasValueRegion) {
            commandViews.push(this.productRestrictionLocationAreasRegionView(i, ctl.value, regionCode));
        }
    }

    private country(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let countryCode of ctl.value.locationAreasValueCountry) {
            commandViews.push(this.productRestrictionLocationAreasCountryView(i, ctl.value, countryCode));
        }
    }

    private airport(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let locationId of ctl.value.locationAreasValueAirport) {
            commandViews.push(this.productRestrictionLocationAreasAirportView(i, ctl.value, locationId));
        }
    }

    private locationgroup(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let locationGroupId of ctl.value.locationAreasValueGroup) {
            commandViews.push(this.productRestrictionLocationAreasGroupView(i, ctl.value, locationGroupId));
        }
    }

    private organisation(ctl: any, commandViews: PointOfSalesView[], i: number) {
        switch (ctl.value.orgType) {
            case 'type':
                this.type(ctl, commandViews, i);
                break;
            case 'group':
                this.group(ctl, commandViews, i);
                break;
            case 'role': 
                this.role(ctl, commandViews, i);
                break;
            case 'specific':
                this.specific(ctl, commandViews, i);
                break;
        }
    }

    private type(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let organisationTypeCode of ctl.value.orgTypeValueType) {
            commandViews.push(this.productRestrictionOrganisationTypeView(i, ctl.value, organisationTypeCode));
        }
    }

    private group(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let organisationGroupCode of ctl.value.orgTypeValueGroup) {
            commandViews.push(this.productRestrictionOrganisationGroupView(i, ctl.value, organisationGroupCode));
        }
    }

    private role(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let organisationRoleCode of ctl.value.orgTypeValueRole) {
            commandViews.push(this.productRestrictionOrganisationRoleView(i, ctl.value, organisationRoleCode));
        }
    }

    private specific(ctl: any, commandViews: PointOfSalesView[], i: number) {
        for (let organisationId of ctl.value.orgTypeValueSpecific) {
            commandViews.push(this.productRestrictionOrganisationSpecificView(i, ctl.value, organisationId));
        }
    }

}
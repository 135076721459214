import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { FeeAddCommandModel, FeeEditCommandModel, FeeSearchCommandModel, FeeViewModel } from "../../models/product-model/fee-model";

@Injectable({
    providedIn: 'root'
})
export class FeeService extends ApiServiceBase {
    private readonly PATH = "/product/fees/"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public search(command: FeeSearchCommandModel): Observable<FeeViewModel[]> {
        return this.httpPost<FeeViewModel[]>(this.PATH + 'search', command)
            .pipe(
                map(res => res.body)
            )
    }

    public addProduct(model: FeeAddCommandModel) {
        return this.httpPost(this.PATH, model);
    }

    public editProduct(model: FeeEditCommandModel) {
        return this.httpPut(this.PATH, model);
    }

    public getByProductId(productId): Observable<FeeViewModel> {
        return this.httpGet<FeeViewModel>(this.PATH + productId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrderSeatModel } from "../../models/order-model";

@Injectable({
    providedIn: 'root'
})
export class OrderSeatService extends ApiServiceBase {
    private readonly PATH: string = "/order"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getOrderSeatByOrderTransport(orderId: string): Observable<OrderSeatModel[]> {

        return this.httpGet<OrderSeatModel[]>(this.PATH + "/orderSeats/" + orderId + "/transport", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
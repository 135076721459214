import { UntypedFormControl, Validators } from "@angular/forms";

export const FormOption = {
    no: new UntypedFormControl(null),
    vehicleCompositionConfigurationId: new UntypedFormControl(null),
    vehicleConfigurationId: new UntypedFormControl(null, Validators.required),
    code: new UntypedFormControl(null),
    name: new UntypedFormControl(null),
    sequence: new UntypedFormControl(null, Validators.required),
    status: new UntypedFormControl(null, Validators.required)
}
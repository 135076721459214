import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

import {
    select2HashtagOption
} from '../../../shared/configuration/select2-hashtag-configuration';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';

declare var $: any;

@Component({
    selector: 'op-hashtag',
    templateUrl: './hashtag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HashtagComponent implements OnInit {

    @Input() productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    @Input() searchMode: boolean = false;
    @Input() showHashtagRow: boolean = false;
    @Output() showHashtagRowChange = new EventEmitter<boolean>();
    @Output() valueReturn = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public hashTagOption: any;
    public hashTagId: string = this.getHastTagId();
    private subscription: Subscription
    public focusing: boolean = false;
    
    constructor() {
        this.hashTagOption = select2HashtagOption;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productHashTags$']) {
            this.subscription = this.productHashTags$.subscribe((values) => {
                if (values) {
                    let show = this.selectedExists();
                    this.setShowHashtagRow(show);
                } else {
                    this.setShowHashtagRow(false);
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    public selectedExists() : boolean {
        if (this.productHashTags$.value) {
            let isExists = this.productHashTags$.value.filter(x => x.productHashTagId != null).length > 0;
            if (isExists && !this.showHashtagRow) {
                this.setShowHashtagRow(true);
            }
            return isExists;
        }
        return false;
    }

    public hashTagChange(value) {
        this.valueReturn.emit(value);
    }

    public focusHashtag() {
        setTimeout(() => {
            let elementReference = document.querySelector('#' + this.hashTagId);
            if (elementReference instanceof HTMLElement) {
                elementReference.focus();
            }
        }, 100)
    }

    private getHastTagId(): string {
        if (this.searchMode) {
            return "ddlHashTag";
        }
        return "ddlHashTagSearch";
    }

    public add() {
        this.setShowHashtagRow(true);
    }

    public delete() {
        this.setShowHashtagRow(false);
        this.valueReturn.emit([]);
    }

    public setShowHashtagRow(value: boolean) {
        this.showHashtagRow = value;
        this.showHashtagRowChange.emit(this.showHashtagRow);
    }
}
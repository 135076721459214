export class PriceRuleValidityModel {
    public priceRuleValidityId: string;
    public priceRuleAttributeGroupCode: string;
    public calendarValidityCode: string;
    public calendarValidityPrefixCode: string;
    public dateTimeDimensionCode: string;
    public startDateTime: Date;
    public endDateTime: Date;
    public startValue: number;
    public endValue: number;
    public mondayFlag: boolean;
    public tuesdayFlag: boolean;
    public wednesdayFlag: boolean;
    public thursdayFlag: boolean;
    public fridayFlag: boolean;
    public saturdayFlag: boolean;
    public sundayFlag: boolean;
    public januaryFlag: boolean;
    public februaryFlag: boolean;
    public marchFlag: boolean;
    public aprilFlag: boolean;
    public mayFlag: boolean;
    public juneFlag: boolean;
    public julyFlag: boolean;
    public augustFlag: boolean;
    public septemberFlag: boolean;
    public octoberFlag: boolean;
    public novemberFlag: boolean;
    public decemberFlag: boolean;
    public utcFlag: boolean;
    public conditionCode: string;
    public displaySequence: number;
    public minutesStartValue: number;
    public minutesEndValue: number;
    public hoursStartValue: number;
    public hoursEndValue: number;
}
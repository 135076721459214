import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiServiceBase } from 'src/app/core/base/api-service-base';

import {
    CountryReferenceModel,
    LocationGroupModel,
    LocationReferenceModel,
    RegionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';

import {
    LocationReferenceService,
    RegionReferenceService
} from 'src/app/core/services/airline-services';

import { CountryReferenceService } from 'src/app/core/services/system-services';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PriceRuleAjaxService extends ApiServiceBase {
    private readonly PRICING_PATH = "/pricing";
    private readonly ORGANISATION_PATH = "/organisation";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private countryService: CountryReferenceService,
        private locationReferenceService: LocationReferenceService,
        private regionService: RegionReferenceService
    ) {
        super(httpClient, authService);
    }

    public toLocationReferenceAjaxOption() {
        return {
            transport: (params, success, failure) => {
                var request = this.locationReferenceService.getLocationReferenceByQueryString(params.data.term, '')
                    .subscribe((response: LocationReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }

    public toProviderAjaxOption() {
        return {
            headers: this.createAuthHeaderAjax(),
            url: (args) => {
                return environment.apiUrl + this.ORGANISATION_PATH + '/organisations?type=AIRLINE&status=A&code=' + args.term ?? '';
            },
            dataType: 'json',
            delay: 250,
            method: 'GET',
            data: function (params) {
                return null;
            },
            processResults: (data: OrganisationModel[]) => {
                return {
                    results: data.map(d => new Select2Data(d.organisationId, d.organisationCode))
                };
            }
        }
    }

    public toRegionReferenceAjaxOption() {
        return {
            transport: (params, success, failure) => {
                this.regionService.getRegionByName(params.data.term)
                    .subscribe((response: RegionReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.regionCode, d.regionName))
                        })
                    });
            }
        }
    }

    public toCountryReferenceAjaxOption() {
        return {
            transport: (params, success, failure) => {
                var request = this.countryService.getCountryByName(params.data.term)
                    .subscribe((response: CountryReferenceModel[]) => {
                        success({
                            results: response.map(d => new Select2Data(d.countryCode, d.countryName))
                        })
                    });
            }
        }
    }

    public toLocationGroupAjaxOption() {
        return {
            headers: this.createAuthHeaderAjax(),
            url: (args) => {
                return environment.apiUrl + this.PRICING_PATH + '/LocationGroup/search/' + args.term ?? '';
            },
            dataType: 'json',
            delay: 250,
            method: 'GET',
            data: function (params) {
                return null;
            },
            processResults: (data: LocationGroupModel[]) => {
                return {
                    results: data.map(d => new Select2Data(d.locationGroupId, d.locationGroupName))
                };
            }
        }
    }
}
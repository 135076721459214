import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
    declarations: [
        LoadingSpinnerComponent
    ],
    exports: [
        LoadingSpinnerComponent
    ],
    imports: [
        CommonModule,
        NgxSpinnerModule
    ],
    providers: [
        NgxSpinnerService
    ]
})
export class LoadingSpinnerModule { }
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { 
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    CountryReferenceModel,
    DateTimeDimensionReferenceModel,
    DomainAttributeModel,
    HashTagReferenceModel,
    LocationGroupModel,
    RegionReferenceModel,
    StatusReferenceModel, 
    UsageTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { 
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel, 
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel 
} from 'src/app/core/models/reference-model/reference-product-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { 
    CalendarValidityService,
    ConditionReferenceService,
    CountryReferenceService,
    DateTimeDimensionReferenceService,
    OrganisationTypeReferenceService,
    ProductCategoryReferenceService,
    ProductGroupReferenceService, 
    ProductLocationPointReferenceService,
    ProductLocationTypeReferenceService,
    ProductNumberTypeReferenceService,
    StatusReferenceService, 
    UsageTypeReferenceService
} from 'src/app/core/services/system-services';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NewButtonModel, CopyButtonModel, SaveButtonModel, SaveAsButtonModel, CancelButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { ProductCategory } from '../../../product-management-content/product-categories/product-category';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DomainAttributeService, HashTagReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from 'src/app/core/services/airline-services';
import { OrganisationService } from 'src/app/core/services/organisation-services/organisation.service';
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { ProductHashTagView } from '../../shared/views/product-hashtag.view';
import { HashTagMapperService } from '../../shared/mapper/hashtag-mapper.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { VoucherSearchView } from '../shared/voucher-search.view';
import { VoucherSearchTableComponent } from './voucher-search-table/voucher-search-table.component';
import { VoucherSearchCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-search-command.model';
import { VoucherAttributeAndRuleComponent } from '../voucher-attribute-and-rule/voucher-attribute-and-rule.component';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { VoucherService } from 'src/app/core/services/product-services/voucher.service';
import { VoucherViewModel } from 'src/app/core/models/product-model/voucher-model/voucher-view.model';
import { VoucherSearchConditionComponent } from './voucher-search-condition/voucher-search-condition.component';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductRestrictionOrganisationViewModel, ProductRestrictionLocationViewModel, ProductRestrictionProductNumberViewModel, ProductRestrictionRouteViewModel, ProductRestrictionProductViewModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { VoucherAdvanceSearchComponent } from './voucher-advance-search/voucher-advance-search.component';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { VoucherMapperService } from '../shared/voucher-mapper.service';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { ProductSearchFilterService } from 'src/app/core/services/product-services';
import { ProductSearchFilterModel } from 'src/app/core/models/product-model/product-search-filter-model/product-search-filter-model';
import { OrganisationRoleReferenceService } from 'src/app/core/services/organisation-services';

@Component({
    selector: 'op-voucher-search',
    templateUrl: './voucher-search.component.html',
    providers: [
        Select2DataMapperService,
        HashTagMapperService,
        VoucherMapperService
    ]
})
export class VoucherSearchComponent implements OnInit, AfterViewInit {
    private readonly VOUCHER_CODE = 'VOUCHER';
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRBUTE_GROUP_CODE_ORG_DESC: string = "ORIGINDESTINATION";
    private readonly ACTIVE_STATUS = "A";
    private readonly VALIDITY_SERVICE_CODE = "SERVICE";
    private readonly VALIDITY_SALE_CODE = "SALE";
    private readonly VALIDITY_PAYMENT_CODE = "PAYMENT";
    public readonly SEARCH_TAB_ID = 'search';
    private readonly USAGETYPE_DATA: string = "DATA";
    private readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    private readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly FILTERNAME_PANEL: string = "Advance Search";
    private readonly CONDITION_PANEL: string = "Search Condition";

    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public voucherProductGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSearchFilters$ = new BehaviorSubject<Select2Data[]>(null);

    public hashTagReferences$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public voucherSearchAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);

    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];

    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    
    public domainAttributeOrgDescSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    public advanceSearchMode: boolean = false;
    public selectedItem: any;
    public voucherFilterView: VoucherViewModel;

    public userSecurity: SecurityGroupSecurityModel;
    private actionBarHandler: ActionBarHandlerModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();

    private unsubscribe$ = new Subject();

    @Input() selectedProductCategory: ProductCategory;
    @Output() cancelRequest = new EventEmitter();

    @ViewChild(VoucherSearchTableComponent) searchTableComponent: VoucherSearchTableComponent; 
    @ViewChild(VoucherAttributeAndRuleComponent) attributeAndRuleComponent: VoucherAttributeAndRuleComponent;
    @ViewChild(VoucherSearchConditionComponent) conditionComponent: VoucherSearchConditionComponent;
    @ViewChild(VoucherAdvanceSearchComponent) advanceSearchComponent: VoucherAdvanceSearchComponent;

    constructor(
        private productTypeGroupService: ProductTypeGroupService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private statusReferenceService: StatusReferenceService,
        private actionbarService: ActionbarService,
        private hashTagReferenceService: HashTagReferenceService,
        private domainAttributeService: DomainAttributeService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private hashTagMapperService: HashTagMapperService,
        private changeDetectorRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private loadingSpinner: LoadingSpinnerService,
        private voucherService: VoucherService,
        private alertBarService: AlertBarService,
        private voucherMapperService: VoucherMapperService,
        private focusingService: FocusingService,
        private productSearchFilterService: ProductSearchFilterService) { }

    ngOnInit(): void {
        this.getProductTypeGroup();
        this.getProductGroupReference();
        this.getProductSearchFilter();
        this.getStatusReference();
        this.getHashTagReference();
        this.getVoucherSearchAttribute();
        this.getProductCategoryReference();
        this.getProductNumberTypeReference();
        this.getProvider();
        this.getSupplier();
        this.getConditionReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getProductLocationPointReference();
        this.getProductLocationTypeReference();
        this.getRegionReference();
        this.getCalendarValidityReference();
        this.getDateTimeDimensionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getOrganisationRoleReferences();
        this.getDomainAttributeTypeOrgDescSearch();
        this.getUsageTypeReferences();
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    this.voucherProductGroupReferences$.next(responses?.filter(item => item.productCategoryCode == this.VOUCHER_CODE));
                    this.productGroupReferences$.next(responses);
                }
            )
    }

    private getProductSearchFilter() {
        this.productSearchFilterService.getByProductCategory(this.VOUCHER_CODE)
            .subscribe(
                (responses: ProductSearchFilterModel[]) => {
                    let searchFilters = responses.map(item => new Select2Data(item.productId, item.productName));
                    this.productSearchFilters$.next(searchFilters);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReferences$.next(responses);
                }
            )
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReferences$.next(responses);
                    this.setProductHashTagValue(null);
                    this.changeDetectorRef.detectChanges();
                }
            )
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        this.attributeAndRuleComponent?.setProductHashTagValue(value);
    }

    private getVoucherSearchAttribute() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", true, "", "", this.VOUCHER_CODE)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    this.voucherSearchAttribute$.next(responses);
                }
            )
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReferences$.next(responses);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getConditionReference() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getProductLocationPointReference() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReference$.next(responses);
                }
            )
    }

    private getProductLocationTypeReference() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
                        productLocationTypeReference.push(response);
                        this.productLocationTypeReference$.next(productLocationTypeReference);
                    }
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getCalendarValidityReference() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(this.VALIDITY_SERVICE_CODE, this.VALIDITY_SALE_CODE, this.VALIDITY_PAYMENT_CODE)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReference$.next(responses);
                }
            )
    }

    private getDateTimeDimensionReference() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReference$.next(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    private getDomainAttributeTypeOrgDescSearch() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_ORG_DESC, "", true)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    let filterOrgDesc = this.getAtttributeOrgDesc(responses);
                    this.domainAttributeOrgDescSearch$.next(filterOrgDesc);
                }
            )
    }

    private getAtttributeOrgDesc(response: DomainAttributeModel[]) {
        if (response) {
            return response.filter(x => (x.startFlag == true || x.endFlag == true) && x.scheduleProductFlag == true)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        }
    }

    private getUsageTypeReferences() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.getUsageTypeReferenceWithFilter(responses); 
                    }
                }
            )
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferences$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    private getDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            new RefreshButtonModel()
        )
    }

    private setDefaultActionBarHandler() {
        this.getDefaultActionBarHandler();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.newVoucher();
                break;
            case ACTION_STATUS.save:
                this.onSaveFilterClick();
                break;
            case ACTION_STATUS.saveAs:
                this.onSaveFilterClick(true);
                break;
            case ACTION_STATUS.copy:
                if (this.selectedItem) {
                    this.onCopyItem(this.selectedItem)
                }
        }
    }

    private newVoucher() {
        let params = {
            newVoucher: true,
            tab: this.SEARCH_TAB_ID,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate('main/product/voucher/details', null, false, params);
        
    }

    private onCopyItem(data: any) {
        let usageTypeCode = data.usageType;
        switch(usageTypeCode.toUpperCase()) {
            case this.USAGETYPE_FILTER:
                this.getFilter(data.voucherId, true);
                this.onAdvanceSearchToggle(true);
                this.updateActionBarHandlerState();
                break;
        }
    }

    public onAdvanceSearchToggle(advanceSearchMode: boolean = null) {
        this.advanceSearchMode = advanceSearchMode ? advanceSearchMode : !this.advanceSearchMode;
        this.updateActionBarHandlerState();
    }

    public selectVoucher(voucher: any) {
        this.selectedItem = voucher;
    }

    public onSearchVoucher(condition: VoucherSearchView) {
        let searchCommand = this.getSearchCommand(condition);
        this.searchTableComponent.searchVoucher(searchCommand);
    }

    private getSearchCommand(condition: VoucherSearchView): VoucherSearchCommandModel {
        let searchCommand: VoucherSearchCommandModel = {
            productCategoryCode: this.VOUCHER_CODE,
            productGroupCode: condition.productGroupCode,
            productTypeCode: condition.productTypeCode,
            productName: null,
            searchName: condition.voucherName,
            searchUsageTypeCode: condition.usageTypeCode,
            searchStatusCode: condition.statusCode,
            filterUserAccountId: null,
            statusCode: null,
            productHashTags: null,
            productNumbers: null,
            productRestrictionProducts: null,
            productRestrictionProductNumbers: null,
            productRestrictionRoutes: null,
            productRestrictionLocations: null,
            productValidities: null,
            productAttributes: null,
            productRestrictionOrganisations: null
        }
        if (this.advanceSearchMode) {
            this.attributeAndRuleComponent.fillAttributeAndRulesToSearchCommand(searchCommand);
        }
        return searchCommand;
    }

    public onClearClick() {
        this.searchTableComponent.clearResult();
        this.advanceSearchComponent.clearAdvanceSearchPanel();
        this.attributeAndRuleComponent.clearAttributeAndRule();
    }

    public searchFilterChange(productId: string) {
        if (productId) {
            this.getFilter(productId);
        }
    }

    public updateActionBarHandlerState() { 
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (this.advanceSearchMode) {
            actionBarHandler.get(ACTION_STATUS.delete)?.enable(this.canDelete());
            let saveBtn = actionBarHandler.get(ACTION_STATUS.save);
            saveBtn?.enable();
            saveBtn?.getDropdownBtn(ACTION_STATUS.save)?.enable(this.canSaveToSameOrNewId());
            saveBtn?.getDropdownBtn(ACTION_STATUS.saveAs)?.enable(this.alwaysSaveToNewId());
        } else {
            actionBarHandler.get(ACTION_STATUS.delete)?.disable();
            actionBarHandler.get(ACTION_STATUS.copy)?.disable();
            actionBarHandler.get(ACTION_STATUS.save)?.disable();
        }
        this.actionbarService.updateState(actionBarHandler);
    }

    private canDelete(): boolean {
        return !!(this.voucherFilterView?.isOwnerFilter);
    }

    private canSaveToSameOrNewId(): boolean {
        return (!!(this.voucherFilterView?.organisationId) && this.voucherFilterView?.isOwnerFilter) || !(this.voucherFilterView?.organisationId);
    }

    private alwaysSaveToNewId(): boolean {
        return !!(this.voucherFilterView?.organisationId);
    }

    getFilter(voucherId: string, copyAction: boolean = false) {
        if (voucherId) {
            this.loadingSpinner.show();
            this.voucherService.getVoucherById(voucherId)
                .subscribe(
                    (responses: VoucherViewModel) => {
                        this.voucherFilterView = responses;
                        this.loadingSpinner.hide();
                        this.fillModelToCondition(responses, copyAction);
                        this.fillModelToAdvanceSearch(responses, copyAction);
                        this.setProductHashTagValue(responses.productHashtags);
                        this.fillModelToProductAttribute(responses.productAttributes);
                        this.fillModelToOriginDestination(responses.productAttributes);
                        this.fillModelToProductValidity(responses.productValidities);
                        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
                        this.fillModelToProductRestriction(responses.productRestrictionProducts,
                            responses.productRestrictionProductNumbers,
                            responses.productRestrictionRoutes,
                            responses.productRestrictionLocations
                        );
                        this.updateActionBarHandlerState();
                        
                    },
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private fillModelToCondition(response: VoucherViewModel, copyAction: boolean = false) {
        let view: VoucherSearchView = {
            voucherId: copyAction ? null : response.productId,
            productTypeCode: response.productGroupCode,
            productTypeName: response.productGroupName,
            productGroupCode: response.productTypeCode,
            productGroupName: response.productTypeName,
            voucherName: response.searchName,
            usageTypeCode: response.searchUsageTypeCode,
            usageTypeName: null,
            statusCode: response.searchStatusCode,
            organisationId: response.organisationId,
            organisationName: null
        }
        this.conditionComponent.condition = view;
        this.changeDetectorRef.detectChanges();
    }

    private fillModelToAdvanceSearch(response: VoucherViewModel, copyAction: boolean = false) {
        this.advanceSearchComponent?.fillAdvanceSearchForm(response, copyAction);
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        this.attributeAndRuleComponent?.fillValiditiesToForm(productValidities);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[]) {
        this.attributeAndRuleComponent?.fillAttributesToForm(productAttributes);
    }

    private fillModelToOriginDestination(productAttributes: ProductAttributeViewModel[]) {
        this.attributeAndRuleComponent?.fillOriginDestinationToForm(productAttributes);
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        this.attributeAndRuleComponent?.fillPointOfSalesToForm(productRestrictionOrganisations, productRestrictionLocations);
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
            this.attributeAndRuleComponent?.fillRestrictionsToForm(productRestrictionProducts,
                productRestrictionProductNumbers,
                productRestrictionRoutes,
                productRestrictionLocations)
    }

    onSaveFilterClick(isSaveAs: boolean = false) {
        if (!this.validateForm()) {
            this.displayAlertBar();
            return;
        }

        let product = this.advanceSearchComponent.getSaveModel();
        this.conditionComponent.fillConditionToViewModel(product);
        if (!product) {
            return;
        }

        product.productId = isSaveAs ? null : this.voucherFilterView.productId;
        product.usageTypeCode = this.USAGETYPE_FILTER;
        if (!product.productId) {
            this.addNewFilter(product);
        }
        else {
            this.updateFilter(product);
        }
    }

    private validateForm() {
        return this.advanceSearchComponent.validate() && this.conditionComponent.validate();
    }

    private displayAlertBar() {
        if (!this.advanceSearchComponent.validate()) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.advanceSearchComponent.getErrorMessage());
            this.focusingService.focus(this.advanceSearchComponent.focusingDirective);
        } else if (!this.conditionComponent.validate()) {
            this.alertBarService.show(this.CONDITION_PANEL, this.conditionComponent.getErrorMessage());
            this.focusingService.focus(this.conditionComponent.focusingDirective);
        } else {
            this.alertBarService.hide();
        }
    }

    public onAlertNotifyClicked() {
        this.displayAlertBar();
    }

    private addNewFilter(product: VoucherViewModel) {
        let command = this.voucherMapperService.viewModelToAddCommand(product, this.attributeAndRuleComponent);
        if (!command) {
            return;
        }
        this.loadingSpinner.show();
        this.voucherService.addVoucher(command)
            .subscribe(
                (voucherId: string) => {
                    this.getFilter(voucherId);
                    this.getProductSearchFilter();
                    this.loadingSpinner.saveComplete();
                    this.changeDetectorRef.detectChanges();
                },
                () => this.loadingSpinner.hide()
            )
    }

    private updateFilter(product: VoucherViewModel) { 
        let command = this.voucherMapperService.viewModelToEditCommand(product, this.attributeAndRuleComponent);
        if (!command) {
            return;
        }
        this.loadingSpinner.show();
        this.voucherService.editVoucher(command)
            .subscribe(
                () => {
                    this.getFilter(this.voucherFilterView.productId);
                    this.getProductSearchFilter();
                    this.loadingSpinner.saveComplete();
                    this.changeDetectorRef.detectChanges();
                },
                () => this.loadingSpinner.hide()
            )
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.conditionComponent.searchClick();
                break;
            default:
                break;
        }
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportSearchComponent } from './transport-search.component';
import { TransportSearchConditionComponent } from './transport-search-condition/transport-search-condition.component';
import { TransportSearchTableComponent } from './transport-search-table/transport-search-table.component';
import { TransportSearchFilterNameComponent } from './transport-filter-name/transport-filter-name.component';
import { DxDataGridModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { TransportAttributeAndRuleModule } from '../shared/attribute-and-rule/attribute-and-rule.module';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { DataGridRowNumberModule } from "../../../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        TransportSearchComponent,
        TransportSearchConditionComponent,
        TransportSearchFilterNameComponent,
        TransportSearchTableComponent
    ],
    exports: [
        TransportSearchComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        TransportAttributeAndRuleModule,
        UiModule
    ]
})
export class TransportSearchModule { }

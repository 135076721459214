declare var moment: any;

export const select2SearchFilter = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
export const select2AirportOption = {
    placeholder: "",
    allowClear: true,
    minimumInputLength: 3,
    ajax: null
}
export const orderSearchDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    }
}
export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}
export const select2ChoiceMultiple = {
    placeholder: "<Attribute>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
export const select2CommentType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommentClassification = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}
export const select2PartySelection = {
    placeholder: "<Select>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}
export const flightSelectionCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: new Date(),
    maxDate: new Date().getFullYear() + 3,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isInvalidDate: null,
    isCustomDate: null
};
export const individualBirthdayCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}
export const individualIssueDocumentCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: new Date(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}
export const individualExpireDocumentCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: new Date(),
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}
export const orderPercentageOption = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2PhonePrefix = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2Language = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommunicationType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2UsageType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SingleChoice = {
    placeholder: "Type",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2FilterSingleChoice = {
    allowClear: false,
    minimumResultsForSearch: 1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2ProductCategory = {
    placeholder: "Product Category",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2FilterProductGroup = {
    placeholder: "Product Group",
    allowClear: false,
    minimumResultsForSearch: 1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2FilterProductType = {
    placeholder: "Product Type Group",
    allowClear: false,
    minimumResultsForSearch: 1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2FilterProvider = {
    placeholder: "Provider",
    allowClear: false,
    minimumResultsForSearch: 1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2FilterCurrency = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: 1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { PaymentSearch } from "../payment-search.model";
import { PaymentSearchService } from "../payment-search.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PaymentCreditInvoiceService } from "../payment-credit-invoice.service";
import { GATEWAY_PAYMENT_STATUS, PaymentGatewayFactory } from "src/app/core/services/payment-creditcard-services";
import { ExternalPaymentModel, PaymentStatusModel } from "src/app/core/models/payment-models";

type PaymentActionType = 'PAID' | 'VOID' | 'CANCELLED' | 'REVERSE' | 'UNDO'

@Component({
    selector: 'op-payment-search-actions',
    templateUrl: './payment-search-actions.component.html'
})
export class PaymentSearchActionsComponent implements OnInit, OnDestroy {

    paymentSearchSelection$ = new Observable<PaymentSearch>()
    paymentSearch: PaymentSearch
    currentActionType: PaymentActionType
    subscriptions: Subscription[]

    constructor(private paymentSearchService: PaymentSearchService,
        private paymentCreditInvoiceService: PaymentCreditInvoiceService,
        private paymentGatewayFactory: PaymentGatewayFactory,
        private navigateService: NavigationService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscriptions = []
        this.paymentSearchSelection$ = this.paymentSearchService.paymentSearchSelection$.pipe(
            tap((v) => {
                this.paymentSearch = v
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    selectPaymentType(actionType: PaymentActionType, canAction: boolean) {
        if (canAction) {
            this.currentActionType = actionType
            this.handlePaymentAction();
        }
    }

    validateAndUpdatePaymentStatusCode() {
        this.validatePaymentAction(
            this.updatePaymentStatusCode
        )
    }

    updatePaymentStatusCode() {
        this.paymentSearchService.setLoading(true)
        this.cdr.detectChanges()
        this.subscriptions.push(
            this.paymentSearchService.updatePaymentStatusCode(this.paymentSearch.paymentId, this.currentActionType).subscribe(
                () => this.paymentIsSaved(),
                (error) => this.paymentCouldNotBeSaved(error),
                () => this.paymentSearchService.setLoading(false))
        )
    }

    private handlePaymentAction() {
        switch (this.currentActionType) {
            case 'PAID':
                this.handlePaidPayment();
                break;
            case 'REVERSE':
                this.validatePaymentAction(this.redirectToReversePaymentTab);
                break;
            case 'UNDO':
                this.updatePaymentStatusCode()
                break;
            default:
                break;
        }
    }

    private handlePaidPayment() {
        if (this.paymentSearch.isDocumentInvoicePayment) {
            this.navigateService.navigate('main/cashbook/payment/invoice', null, false, this.paymentSearch);
        }
        else if (this.paymentSearch.isCreditCardPayment) {
            this.loadDataTransInitial();
        }
    }

    private validatePaymentAction(fn: Function) {
        this.paymentSearchService.setLoading(true);
        this.subscriptions.push(
            this.paymentSearchService.validatePaymentAction(this.paymentSearch.paymentId, this.currentActionType,
                this.paymentSearch.paymentSearchLedgers.find(l => l.referenceOrderId != null).referenceOrderId)
                .subscribe(
                    () => fn.apply(this),
                    (error) => this.paymentCouldNotBeSaved(error),
                    () => this.paymentSearchService.setLoading(false))
        )
    }

    private redirectToReversePaymentTab() {
        let url = 'main/cashbook/payment/credit-invoice'
        if (this.paymentSearch.isCreditAccountPayment) {
            url = 'main/cashbook/payment/credit-creditaccount'
        }
        this.navigateService.navigate(url, null, false, this.paymentSearch)
    }

    private loadDataTransInitial() {
        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentCreditInvoiceService.getDatatransInitial(this.paymentSearch.paymentId).subscribe(
                initialModel => this.showDataTransPopup(initialModel),
                () => this.paymentCouldNotBeSaved())
        );
    }

    private showDataTransPopup(initialModel: ExternalPaymentModel) {
        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentGatewayFactory.create()
                .processPayment(initialModel).pipe(
                    filter(f => !!f),
                )
                .subscribe(paymentStatusModel => this.handleDataTransResponse(paymentStatusModel),
                    () => this.paymentCouldNotBeSaved())
        );
    }

    private handleDataTransResponse(paymentStatusModel: PaymentStatusModel) {
        switch (paymentStatusModel.status) {
            case GATEWAY_PAYMENT_STATUS.SUCCESS:
                this.paymentIsSaved()
                break;
            default:
                this.paymentCouldNotBeSaved()
        }
    }

    private paymentIsSaved() {
        this.paymentSearchService.setLoading(false)
        this.paymentSearchService.executeSearch()
        this.paymentSearchService.alertSuccess('Payment is saved')
    }

    private paymentCouldNotBeSaved(error: string = null) {
        this.paymentSearchService.setLoading(false)
        this.paymentSearchService.alertError(error ?? 'Payment could not be saved')
    }
}

import { NgModule } from "@angular/core";
import { MerchandizeSearchModule } from "./merchandize-search/merchandize-search.module";

@NgModule({
    imports: [
        MerchandizeSearchModule
    ],
    exports: [MerchandizeSearchModule]
})
export class MerchandizeModule {
}
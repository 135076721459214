import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { DxChartComponent } from "devextreme-angular";
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";
import { PRODUCT_CHART_COLORS } from "../../../../../shared/ui/chart/chart-color.constant";
import { PRODUCT_DASHBOARD_MERCHANDIZE_REVENUE_LAST7D_DEMO } from "./product-dashboard-merchandize-revenue-last7d-demo-data";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-merchandize-revenue-last7d.component.html',
    selector: 'op-product-dashboard-merchandize-revenue-last7d'
})
export class ProductDashboardMerchandizeRevenueLast7DComponent implements AfterViewInit {
    public readonly ccolor = PRODUCT_CHART_COLORS;

    @ViewChild('chartLast7d', { static: false }) chartLast7d: DxChartComponent;

    public productLast7dInfo: OrdersDashboardOrdersCountModel[];
    public productLast7dCaptions: string[];

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngOnInit(): void {
        this.productLast7dCaptions = ["-", "-", "-", "-"];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.chartLast7d.instance.render();
            this.changeDetection.detectChanges();

            this.loadData();
        }, 300);
    }

    private loadData() {
        setTimeout(() => {
            let first = PRODUCT_DASHBOARD_MERCHANDIZE_REVENUE_LAST7D_DEMO[0];
            this.productLast7dCaptions = [
                first.salesChannel1Name,
                first.salesChannel2Name,
                first.salesChannel3Name,
                first.salesChannel4Name
            ];
            this.productLast7dInfo = PRODUCT_DASHBOARD_MERCHANDIZE_REVENUE_LAST7D_DEMO;
            this.changeDetection.detectChanges();
        }, 500);
    }

    public customizeLastTooltip(arg: any) {
        let order = arg.point.data as OrdersDashboardOrdersCountModel;
        return {
            html: `<table>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel1Name ? "<b>" + order.salesChannel1Name + "</b>" : order.salesChannel1Name}</td>
                        <td>${ arg.seriesName == order.salesChannel1Name ? "<b>" + order.salesChannel1 + "</b>" : order.salesChannel1}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel2Name ? "<b>" + order.salesChannel2Name + "</b>" : order.salesChannel2Name}</td>
                        <td>${ arg.seriesName == order.salesChannel2Name ? "<b>" + order.salesChannel2 + "</b>" : order.salesChannel2}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel3Name ? "<b>" + order.salesChannel3Name + "</b>" : order.salesChannel3Name}</td>
                        <td>${ arg.seriesName == order.salesChannel3Name ? "<b>" + order.salesChannel3 + "</b>" : order.salesChannel3}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel4Name ? "<b>" + order.salesChannel4Name + "</b>" : order.salesChannel4Name}</td>
                        <td>${ arg.seriesName == order.salesChannel4Name ? "<b>" + order.salesChannel4 + "</b>" : order.salesChannel4}</td>
                    </tr>
                </table>`
        };
    }
}
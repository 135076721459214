import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { SecurityLevel2ContextMenuComponent } from './context-menu/security-level2-context-menu.component';

import { SecurityLevel2TableComponent } from './security-level2-table.component';
import { SecurityLevel2TableDirective } from './security-level2-table.directive';

@NgModule({
    declarations: [
        SecurityLevel2ContextMenuComponent,
        SecurityLevel2TableComponent,
        SecurityLevel2TableDirective,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        NgbNavModule
    ],
    exports: [
        SecurityLevel2TableComponent
    ]
})
export class SecurityLevel2TableModule { }

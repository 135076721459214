import {
    Directive,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    Subject
} from 'rxjs';

import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import {
    OrderDetailChildLoadData,
    OrderHeaderLoadData,
} from '../../../shared';

import { StatusReferenceType } from 'src/app/shared/ui/forms/inputs/status-badge/status-reference.type';

@Directive()
export abstract class OrderDetailProductListDeclarationBase {
 
    public readonly SPINNER_NAME: string = "orderProductList";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    
    protected readonly PAID_STATUS = ["PAID", "ISSUED"];

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderLock: boolean;

    public StatusReferenceType = StatusReferenceType;
    public countNumberOfItems: number = 0;

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input() isListView: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("orderHeaderData") orderHeaderData: OrderHeaderLoadData = null;

    @Input() headerName: string = "Product";
    @Input("changeSessionId") changeSessionId: string;
    
    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();
    @Output() onChangeListView = new EventEmitter();

    @ViewChild("panel") panel: any;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    protected destroy$: Subject<boolean> = new Subject<boolean>();
}
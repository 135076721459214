import { Injectable } from '@angular/core';
import { VehicleConfigurationCompartmentPropertyCommandModel, VehicleConfigurationCompartmentPropertyModel } from 'src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment-property';
import { SeatPropertyView } from './seatproperty.view';

@Injectable()
export class SeatPropertyMapperService {

    public toSeatPropertyViews(models: VehicleConfigurationCompartmentPropertyModel[], views: SeatPropertyView[] = new Array()): SeatPropertyView[] {
        let i = 0;
        if (models) {
            for (let model of models) {
                ++i;
                views.push(this.toSeatPropertyView(i, model));
            }
        }
        return views;
    }

    private toSeatPropertyView(i: number, model: VehicleConfigurationCompartmentPropertyModel): SeatPropertyView {
        let view = {} as SeatPropertyView;
        view.no = i;
        view.vehicleConfigurationCompartmentPropertyId = model.vehicleConfigurationCompartmentPropertyId;
        view.vehicleConfigurationCompartmentId = model.vehicleConfigurationCompartmentId;
        view.seatPropertyGroupCode = model.seatPropertyGroupCode;
        view.seatPropertyGroupName = model.seatPropertyGroupName;
        view.seatPropertyId = model.seatPropertyId;
        view.seatPropertyName = model.seatPropertyName;
        view.productId = model.productId;
        view.productName = model.productName;
        view.seatRowFrom = model.seatRowFrom;
        view.seatRowTo = model.seatRowTo;
        view.seatColumnRange = model.seatColumnRange;
        view.byRowFlag = model.byRowFlag;
        view.maxCount = model.maxCount;
        view.bayNumber = model.bayNumber;
        view.seatRow = model.seatRow;
        view.seatColumn = model.seatColumn;
        view.statusCode = model.statusCode;
        return view;
    }

    public toSeatPropertyModels(seatPropertyViews: SeatPropertyView[]): VehicleConfigurationCompartmentPropertyCommandModel[] {
        let commands: VehicleConfigurationCompartmentPropertyCommandModel[] = new Array();
        if (seatPropertyViews) {
            for (let view of seatPropertyViews) {
                commands.push(this.toSeatPropertyModel(view));
            }
        }
        return commands;
    }


    private toSeatPropertyModel(seatPropertyViews: SeatPropertyView): VehicleConfigurationCompartmentPropertyCommandModel {
        let command = {} as VehicleConfigurationCompartmentPropertyCommandModel;
        command.vehicleConfigurationCompartmentPropertyId = seatPropertyViews.vehicleConfigurationCompartmentPropertyId;
        command.vehicleConfigurationCompartmentId = seatPropertyViews.vehicleConfigurationCompartmentId;
        command.seatPropertyId = seatPropertyViews.seatPropertyId;
        command.productId = seatPropertyViews.productId;
        command.seatRowFrom = seatPropertyViews.seatRowFrom;
        command.seatRowTo = seatPropertyViews.seatRowTo;
        command.seatColumnRange = seatPropertyViews.seatColumnRange;
        command.byRowFlag = seatPropertyViews.byRowFlag;
        command.maxCount = seatPropertyViews.maxCount;
        command.bayNumber = seatPropertyViews.bayNumber;
        command.seatRow = seatPropertyViews.seatRow;
        command.seatColumn = seatPropertyViews.seatColumn;
        command.statusCode = seatPropertyViews.statusCode;
        return command;
    }
}
import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { StatusReferenceService, VehicleGroupReferenceService, VehicleTypeReferenceService } from "src/app/core/services/system-services";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2DefaultOption, select2DefaultOptionWithAllowClear, select2VehicleType } from "./search-condition-configuration";
import { VehicleCompositionSearchCommandModel } from 'src/app/core/models/vehicle-model/vehicle-composition';
import { BehaviorSubject } from "rxjs";
import { VehicleGroupReferenceModel, VehicleTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-composition-search-condition',
    templateUrl: './search-condition.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchConditionComponent extends OopsComponentFormBase {
    @Input() userSecurity: SecurityGroupSecurityModel
    @Output() onSearch = new EventEmitter<VehicleCompositionSearchCommandModel>();
    @Output() onClear = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public focusing: boolean = false;
    public select2DefaultOption = select2DefaultOptionWithAllowClear;
    public select2VehicleTypeOption = select2VehicleType;
    public select2DefaultOptionMandatory = select2DefaultOption;
    public vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    public vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleTypeReferenceFilters: VehicleTypeReferenceModel[] = new Array();
    public statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))));

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get code(): string {
        return this.form.controls['code'].value;
    }

    set code(value: string) {
        this.form.controls['code'].patchValue(value);
    }

    get name(): string {
        return this.form.controls['name'].value;
    }

    set name(value: string) {
        this.form.controls['name'].patchValue(value);
    }

    constructor(
        fb: UntypedFormBuilder,
        private statusReferenceService: StatusReferenceService,
        private vehicleGroupReferenceService: VehicleGroupReferenceService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService) {
        super(fb);
        this.getVehicleGroupReferences();
        this.getVehicleTypeReferences();
    }

    getVehicleGroupReferences() {
        this.vehicleGroupReferenceService.getVehicleGroupReference()
            .subscribe(
                (response) => {
                    this.vehicleGroupReferences$.next(response);
                }
            )
    }

    getVehicleTypeReferences() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (response) => {
                    this.vehicleTypeReferences$.next(response);
                }
            )
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            code: new UntypedFormControl(null),
            name: new UntypedFormControl(null),
            vehicleGroup: new UntypedFormControl(null),
            vehicleType: new UntypedFormControl(null),
            statusCode: new UntypedFormControl("A"),
        })
    }

    public doSearch() {
        this.onSearch.emit(this.getValues());
    }

    clearCondition() {
        this.onClear.emit();
    }

    public getValues(): VehicleCompositionSearchCommandModel {
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): VehicleCompositionSearchCommandModel {
        let model = {} as VehicleCompositionSearchCommandModel;
        model.compositionCode = this.getFormControlValue("code")?.trim();
        model.compositionName = this.getFormControlValue("name")?.trim();
        model.vehicleGroupCode = this.getFormControlValue("vehicleGroup");
        model.vehicleTypeCode = this.getFormControlValue("vehicleType");
        model.statusCode = this.getFormControlValue("statusCode");
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    vehicleGroupChange(value, name) {
        this.formGroup.controls[name].setValue(value);
        this.fillterVehicleType(value);
    }

    private fillterVehicleType(vehicleTypeGroup: string) {
        if (vehicleTypeGroup && this.vehicleTypeReferences$.value) {
            this.vehicleTypeReferenceFilters = this.vehicleTypeReferences$.value.filter(x => x.vehicleGroupCode == vehicleTypeGroup);
            if (this.vehicleTypeReferenceFilters?.length) {
                this.formGroup.controls['vehicleType'].setValue(this.vehicleTypeReferenceFilters[0].vehicleTypeCode);
                return;
            }
        }
        this.vehicleTypeReferenceFilters = [];
        this.formGroup.controls['vehicleType'].setValue(null);
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashbookComponent } from './cashbook.component';
import { CashbookRoutingModule } from './cashbook-routing.module';
import { CashbookContentModule } from './cashbook-content/cashbook-content.module';
import { OopsCategoriesModule } from 'src/app/core/components/categories';
import { CounterSalesContentComponent } from './counter-sales-content/counter-sales-content.component';
import { CounterSalesContentModule } from './counter-sales-content/counter-sales-content.module';
import { CreditAccountContentModule } from './credit-account-content/credit-account-content.module';
import { PaymentSearchModule } from './payment-search';

@NgModule({
    declarations: [
        CashbookComponent,
        CounterSalesContentComponent
    ],
    imports: [
        CashbookContentModule,
        CashbookRoutingModule,
        CreditAccountContentModule,
        CounterSalesContentModule,
        PaymentSearchModule,
        CommonModule,
        OopsCategoriesModule
    ]
})
export class CashbookModule { }

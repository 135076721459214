import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export class TextView {
    public no: number;
    public mediaId: string;
    public mediaName: string;
    public validFrom: string;
    public validTo: string;
    public mediaHashTagIds: string[];
    public mediaHashTagName: string;
    public mediaUseCode: string;
    public mediaUseName: string;
    public mediaTypeCode: string;
    public mediaTypeName: string;
    public languageCode: string;
    public languageName: string;
    public text: string;
    public mediaMediaHashTagIds: Select2Data[];
    public mediaContentId: string;
    public mediaFileAssociationId: string;
    public mediaMediaUseId: string;
    public mediaTranslationId: string;
    public selectedFromLibrary: boolean;
}
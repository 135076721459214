import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Helper } from 'src/app/shared/helper/app.helper';
import 'devextreme/integration/jquery';
import { DxDataGridComponent } from 'devextreme-angular';
import { PricingDetailConstant } from '../../../rules/price-rule-detail/shared/pricing-detail.constant';
import { PriceConditionView } from './views/price-condition.view';
import { PriceConditionModel, PriceIndividualModel, PriceModel } from '../../shared/models';
import { ToastrService } from 'ngx-toastr';
import {
    ConditionReferenceModel,
    IndividualAgeGroupReferenceModel,
    IndividualSocialTypeReferenceModel,
    UnitReferenceModel,
} from 'src/app/core/models/reference-model/reference-general-model';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PriceService } from '../../shared/price.service';
import { map } from 'rxjs/operators';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-price-condition',
    templateUrl: './condition.component.html'
})
export class ConditionComponent implements OnChanges, AfterViewInit {
    private readonly TITLE = 'Price Condition';
    private readonly UNIT_CODE_MISSING_WARNING = 'Please Select Unit Type in Price Dimension First.';
    private readonly AT_LEAST_AN_INDIVIDUAL_REQUIRED = 'At Least an Individual is required in each Condition.';
    private readonly NUMBER_OF_UNIT = 'numberOfUnit';
    private readonly CONDITION_CODE = 'conditionCode';
    private readonly UNIT_TYPE = 'unitType';
    private readonly INDIVIDUAL_AGE_GROUP = 'individualAgeGroup';
    private readonly SOCIAL_TYPE = 'socialType';
    private readonly UNIT_REFERENCE = 'unitReference';
    private readonly INDIVIDUAL_UNIT_CODE = 'INDIVIDUAL';
    private readonly ARITHMETIC_OPERATOR = 'arithmeticOperator';
    private readonly ACTIVE_STATUS = 'A';
    private readonly PRICE_CONDITION_TEMPLATE_CODE = 'PRICECONDTEMPLATE';
    public readonly EXPORT_FILE_NAME = 'PriceCondition';

    focusing = false;
    classIcon = this.helper.getClassIcon();
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    }

    pageSize = 10;
    priceConditionTemplateOption = {
        allowClear: true,
        minimumResultsForSearch: -1,
        placeholder: '<Select Price Condition Template>',
    };

    public arithmeticOperators = [
        new Select2Data('+', '+'),
        new Select2Data('-', '-'),
        new Select2Data('*', '*'),
        new Select2Data('=', '='),
    ];

    priceConditionTemplateViews$ = this.priceService
        .search(this.createPriceDimensionTemplateSearchModel())
        .pipe(
            map(res =>
                res
                    .sort((a, b) => this.sortByName(a.priceName, b.priceName))
                    .map(r => new Select2Data(r.priceId, r.priceName))
            )
        );

    @Input() price: PriceModel;
    @Input() unitCode: string;
    @Input() individualAgeGroupTypeReferences: IndividualAgeGroupReferenceModel[];
    @Input() conditionReferences: ConditionReferenceModel[];
    @Input() individualSocialTypeReferences: IndividualSocialTypeReferenceModel[];
    @Input() unitReferences: UnitReferenceModel[];
    @Input() editFlag: boolean = true;
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() unitCodeMissing = new EventEmitter();
    @Output() priceConditionsChange = new EventEmitter<PriceConditionModel[]>();
    @Output() priceConditionTemplateChange = new EventEmitter<string>();

    @ViewChild(DxDataGridComponent, { static: false })
    dataGrid: DxDataGridComponent;

    conditionData = new Array<PriceConditionView>();
    dataSource: DataSource;
    selectedItem: any;

    get isNewOrDraft(): boolean {
        return !(this.price?.priceId) || this.price.draftFlag;
    }

    constructor(
        private helper: Helper,
        private toastrService: ToastrService,
        private cdr: ChangeDetectorRef,
        private priceService: PriceService,
        private stringHelperService: StringHelperService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['price']?.currentValue) {
            this.loadData();
        }
        if (changes['unitCode']?.currentValue && this.price?.unitCode != this.unitCode) {
            this.removeIndividualRowsWhenUnitCodeIsNotIndividual(changes);
            this.changeUnitTypeOnAllRows(changes);
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public loadData() {
        this.unitCode = this.price.unitCode;
        this.convertPriceConditionModelsToPriceConditionViews();
    }

    private convertPriceConditionModelsToPriceConditionViews() {
        if (this.price?.conditions?.length) {
            let views = new Array<PriceConditionView>();
            for (let condition of this.price.conditions) {
                let conditionView = new PriceConditionView();
                conditionView.priceConditionId = condition.priceConditionId;
                conditionView.numberOfUnit = condition.numberOfUnits;
                conditionView.conditionCode = condition.conditionCode;
                conditionView.unitType = this.price.unitCode;
                views.push(conditionView);
                this.convertPriceIndividualModelToPriceConditionViews(condition, views);
            }
            this.conditionData = views;
            this.createDataSourceFromConditionData();
        } else {
            this.conditionData = [];
            this.createDataSourceFromConditionData();
        }
    }

    private createDataSourceFromConditionData() {
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.conditionData,
            }),
            onChanged: () => {
                this.notifyChange();
            },
        });
        if (this.selectedItem) {
            this.setSelectedRowStyle(this.selectedItem);
        }
    }

    private convertPriceIndividualModelToPriceConditionViews(
        condition: PriceConditionModel,
        views: PriceConditionView[]
    ) {
        if (condition.individuals?.length) {
            for (let individual of condition.individuals) {
                let individualView = new PriceConditionView();
                individualView.priceConditionId = condition.priceConditionId;
                individualView.priceIndividualId = individual.priceIndividualId;
                individualView.isIndividual = true;
                individualView.individualAgeGroup = individual.individualAgeGroupCode;
                individualView.socialType = individual.individualSocialTypeCode;
                individualView.unitReference = individual.inRelationToIndividualAgeGroupCode;
                individualView.unitType = this.price.unitCode;
                individualView.arithmeticOperator = individual.arithmeticOperator;
                views.push(individualView);
            }
        }
    }

    private removeIndividualRowsWhenUnitCodeIsNotIndividual(changes: SimpleChanges) {
        if (changes['unitCode']?.currentValue != this.INDIVIDUAL_UNIT_CODE) {
            this.conditionData = this.conditionData.filter(d => d.isIndividual == false);
            for (let i = 0; i < this.conditionData.length; i++) {
                if (!this.conditionData[i]?.isIndividual) {
                    this.addPriceIndividual(i);
                }
            }
            this.createDataSourceFromConditionData();
        }
    }

    private changeUnitTypeOnAllRows(changes: SimpleChanges) {
        let temp = this.conditionData;
        for (let t of temp) {
            t.unitType = changes['unitCode']?.currentValue;
        }
        this.conditionData = temp.map(t => t);
        this.createDataSourceFromConditionData();
    }

    addPriceIndividual(rowIndex: number = null) {
        let newRow = new PriceConditionView();
        newRow.isIndividual = true;
        newRow.unitType = this.unitCode;
        newRow.priceIndividualId = this.stringHelperService.NewGuid();

        if (rowIndex != null) {
            let indexToInsert = this.getIndexToInsert(rowIndex);
            newRow.priceConditionId = this.conditionData[indexToInsert - 1]?.priceConditionId;
            this.conditionData.splice(indexToInsert, 0, newRow);
        } else {
            newRow.priceConditionId = this.conditionData[this.conditionData.length - 1]?.priceConditionId;
            this.conditionData.push(newRow);
        }
        this.createDataSourceFromConditionData();
    }

    getIndexToInsert(rowIndex: number): number {
        let indexToInsert = rowIndex + 1;
        for (let i = rowIndex + 1; i <= this.conditionData.length; i++) {
            indexToInsert = i;
            if (this.conditionData[i]?.isIndividual == false) {
                break;
            }
        }
        return indexToInsert;
    }

    deleteRow(event) {
        if (event.data?.isIndividual) {
            this.deleteIndividualRow(event);
        } else {
            this.deleteConditionRow(event);
        }
    }

    deleteIndividualRow(event) {
        this.conditionData = this.conditionData.filter(data => data != this.conditionData[event.row.rowIndex]);
        this.createDataSourceFromConditionData();
    }

    deleteConditionRow(event) {
        let rowIndex = event.row?.rowIndex;
        let numberOfIndividualsInCondition = this.getNumberOfIndividualsInCondition(rowIndex);
        this.deleteIndividualAndConditionRows(rowIndex, numberOfIndividualsInCondition);
    }

    private deleteIndividualAndConditionRows(rowIndex: any, numberOfIndividualsInCondition: number) {
        for (let i = rowIndex + numberOfIndividualsInCondition; i >= rowIndex; i--) {
            this.conditionData = this.conditionData.filter(d => d != this.conditionData[i]);
        }
        this.createDataSourceFromConditionData();
    }

    getNumberOfIndividualsInCondition(rowIndex: number): number {
        let numberOfIndividualsInThisCondition = 0;
        for (let i = rowIndex + 1; i < this.conditionData.length; i++) {
            let data = this.conditionData[i];
            if (data.isIndividual) {
                numberOfIndividualsInThisCondition++;
            } else {
                break;
            }
        }
        return numberOfIndividualsInThisCondition;
    }

    editingStarting(e: any) {
        this.dataGrid.instance.clearSelection();
    }

    editorPreparing(options) {
        if (this.isDisableCell(options.row.data.isIndividual, options.dataField)) {
            this.disableCell(options);
        }
        if (options.dataField == this.NUMBER_OF_UNIT) {
            options.editorElement.keypress(e => {
                let str = String.fromCharCode(e.keyCode);
                if (!/^[0-9]+$/.test(str)) {
                    e.preventDefault();
                }
            });
        }
    }

    private disableCell(options: any) {
        options.editorOptions.disabled = true;
        options.editorOptions.readOnly = true;
        if (options.dataField == this.UNIT_TYPE && options.value) {
            options.editorElement.parent().text(this.unitReferences.find(u => u.unitCode == options.value).unitName);
        } else {
            options.editorElement.parent().text(options.value);
        }
        options.editorElement.hide();
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        this.setCellStyle(e);
        this.setSelectedRowStyle(e);
    }

    setCellStyle(e) {
        if (this.isDisableCell(e.data.isIndividual, e.column.dataField)) {
            e.cellElement.addClass("dx-cell-disabled");
        } else {
            e.cellElement.addClass('dx-cell-editable');
        }
    }

    isDisableCell(isIndividual: boolean, dataField: string): boolean {
        if (isIndividual) {
            if (this.unitCode != this.INDIVIDUAL_UNIT_CODE) {
                return [
                    this.NUMBER_OF_UNIT,
                    this.CONDITION_CODE,
                    this.UNIT_TYPE,
                    this.INDIVIDUAL_AGE_GROUP,
                    this.SOCIAL_TYPE,
                    this.UNIT_REFERENCE,
                ].includes(dataField);
            }
            return [this.NUMBER_OF_UNIT, this.CONDITION_CODE, this.UNIT_TYPE].includes(dataField);
        }
        return [
            this.UNIT_TYPE,
            this.INDIVIDUAL_AGE_GROUP,
            this.SOCIAL_TYPE,
            this.UNIT_REFERENCE,
            this.ARITHMETIC_OPERATOR,
        ].includes(dataField);
    }

    newCondition() {
        if (!this.unitCode) {
            this.toastrService.warning(this.UNIT_CODE_MISSING_WARNING, this.TITLE, {
                timeOut: 4000,
            });
            this.unitCodeMissing.emit();
            return;
        }
        let newRow = new PriceConditionView();
        newRow.numberOfUnit = 1;
        newRow.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
        newRow.unitType = this.unitCode;
        newRow.priceConditionId = this.stringHelperService.NewGuid();
        this.conditionData.push(newRow);
        this.addPriceIndividual();
        this.createDataSourceFromConditionData();
    }

    public getValues(price: PriceModel): PriceModel {
        this.focusing = false;
        this.cdr.detectChanges();
        if (!price) {
            return;
        }

        return this.getRawValues(price);
    }

    public getRawValues(price: PriceModel): PriceModel {
        let conditions = this.getConditionsFromDataSource();
        if (conditions?.length) {
            for (let condition of conditions) {
                if (condition.individuals?.length == 0) {
                    this.toastrService.error(this.AT_LEAST_AN_INDIVIDUAL_REQUIRED, this.TITLE);
                    this.focusing = true;
                    return null;
                }
            }
        }
        price.conditions = conditions;
        return price;
    }

    private getConditionsFromDataSource(): PriceConditionModel[] {
        let conditions = new Array<PriceConditionModel>();
        if (!this.dataSource) {
            return conditions;
        }
        for (let data of this.dataSource.items()) {
            if (data.isIndividual) {
                this.addIndividualToLastCondition(data, conditions);
            } else {
                this.addCondition(data, conditions);
            }
        }
        return conditions;
    }

    private addCondition(data: PriceConditionView, conditions: PriceConditionModel[]) {
        let condition = new PriceConditionModel();
        condition.priceConditionId = data.priceConditionId ?? this.stringHelperService.NewGuid();
        condition.conditionCode = data.conditionCode;
        condition.numberOfUnits = data.numberOfUnit;
        condition.statusCode = 'A';
        conditions.push(condition);
    }

    private addIndividualToLastCondition(data: PriceConditionView, conditions: PriceConditionModel[]) {
        let individual = this.createPriceIndividualFromView(data);
        conditions[conditions.length - 1].individuals.push(individual);
    }

    private createPriceIndividualFromView(data: PriceConditionView): PriceIndividualModel {
        let individual = {} as PriceIndividualModel;
        individual.priceIndividualId = data.priceIndividualId ?? this.stringHelperService.NewGuid();
        individual.inRelationToIndividualAgeGroupCode = data.unitReference ?? null;
        individual.individualAgeGroupCode = data.individualAgeGroup ?? null;
        individual.individualSocialTypeCode = data.socialType ?? null;
        individual.arithmeticOperator = data.arithmeticOperator ?? null;
        return individual;
    }

    onRowValidating(options) {
        if (options.key.isIndividual) {
            options.isValid = true;
        }
    }

    allowToDelete(e: any): boolean {
        if (e.data.isIndividual == false) {
            return true;
        }
        return this.getNumberOfIndividualsUnderCondition(e) > 1;
    }

    private getNumberOfIndividualsUnderCondition(e: any): number {
        let individualCount = 0;
        let conditionIndex = -1;
        for (let i = e.rowIndex; i >= 0; i--) {
            if (!this.conditionData[i]?.isIndividual) {
                conditionIndex = i;
                break;
            }
        }
        for (let i = conditionIndex; i < this.conditionData.length; i++) {
            if (i != conditionIndex && !this.conditionData[i]?.isIndividual) {
                break;
            } else if (this.conditionData[i]?.isIndividual) {
                individualCount++;
            }
        }
        return individualCount;
    }

    notifyChange() {
        this.priceConditionsChange.emit(this.getConditionsFromDataSource());
    }

    private createPriceDimensionTemplateSearchModel(): PriceModel {
        let model = new PriceModel();
        model.status = this.ACTIVE_STATUS;
        model.usageTypeCode = this.PRICE_CONDITION_TEMPLATE_CODE;
        return model;
    }

    onPriceConditionTemplateChange(priceId: string | string[]) {
        this.priceConditionTemplateChange.emit(priceId as string);
    }

    private sortByName(priceNameA: string, priceNameB: string): number {
        if (priceNameA < priceNameB) {
            return -1;
        }
        if (priceNameA > priceNameB) {
            return 1;
        }
        return 0;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource.store().load({
            filter: filterExpr,
        });
    }

    private mapData(data: any): any {
        return {
            UnitType: this.unitReferences?.find(i => i.unitCode == data?.unitType)?.unitName ?? '',
            Condition: this.conditionReferences?.find(i => i.conditionCode == data?.conditionCode)?.conditionName ?? '',
            NumberOfUnit: data?.numberOfUnit ?? '',
            IndividualAgeGroup:
                this.individualAgeGroupTypeReferences?.find(i => i.individualAgeGroupCode == data?.individualAgeGroup)
                    ?.individualAgeGroupName ?? '',
            SocialType:
                this.individualSocialTypeReferences?.find(i => i.individualSocialTypeCode == data?.socialType)
                    ?.individualSocialTypeName ?? '',
            UnitReference:
                this.individualAgeGroupTypeReferences?.find(i => i.individualAgeGroupCode == data?.unitReference)
                    ?.individualAgeGroupName ?? '',
            Arithmetic: data?.arithmeticOperator ?? '',
        };
    }

    onCellClick(e) {
        if (e.rowType == 'data') {
            this.selectedItem = e;
            this.setSelectedRowStyle(e);
        }
    }

    onAddNewUnitClick() {
        if (this.selectedItem && this.selectedItem.row.key.isIndividual == false && this.unitCode == 'INDIVIDUAL') {
            this.addPriceIndividual(this.selectedItem?.rowIndex);
        }
    }

    delete() {
        if (this.selectedItem && this.allowToDelete(this.selectedItem)) {
            if (this.selectedItem.data.isIndividual) {
                this.deleteIndividualRow(this.selectedItem);
            } else {
                this.deleteConditionRow(this.selectedItem);
            }
            this.selectedItem = null;
        }
    }

    showOrdinal(cell): boolean {
        return cell.data.conditionCode == PricingDetailConstant.TIER_CODE && cell.data.numberOfUnit > 0;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData().done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data);
            })
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    setSelectedRowStyle(e) {
        if (!this.selectedItem) {
            return;
        }
        const rows = e.cellElement.parent().parent().children();
        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            if (this.selectedItem.rowIndex == index) {
                $(row).addClass('dx-row-selected');
            } else {
                $(row).removeClass('dx-row-selected');
            }
        }
    }

}

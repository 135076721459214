import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { NavigationService } from 'src/app/shared/utils/navigation';
import { selectTabSessions } from './tab-session.selectors';
import * as TabSessionActions from './tab-session.actions';

@Injectable()
export class TabSessionEffects {

    constructor(private actions$: Actions,
        private navigationService: NavigationService,
        private store$: Store) {
    }

    tabSessionLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TabSessionActions.loadTabSesssion),
            withLatestFrom(this.store$.select(selectTabSessions)),
            tap(([_, tabSessions]) => {
                if (tabSessions) {
                    for (let i = 0; i< tabSessions.length; i++) {
                        this.navigationService.addPreloadTab(tabSessions[i].baseUrl,
                            tabSessions[i].name, null);
                    }
                }
            })
        ),
        { dispatch: false }
    );
}
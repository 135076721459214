import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StatusReferenceType } from './status-reference.type';
import { OrderDeliveryStatusReferenceService, OrderPaymentStatusReferenceService, OrderSalesStatusReferenceService, PaymentStatusReferenceService } from 'src/app/core/services/system-services';
import { StatusReferenceBase } from 'src/app/core/models/reference-model/reference-general-model/status-reference.base';
import { OrderDeliveryStatusReferenceModel, OrderPaymentStatusReferenceModel, OrderSalesStatusReferenceModel, PaymentStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
  selector: 'status-badge',
  templateUrl: 'status-badge.component.html',
  styleUrls: ['status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit {
  

    @Input() statusCode: string;
    @Input() statusType: StatusReferenceType;
    @Input() checkTransactionCodeFlag: boolean = false;
    @Input() ledgerTransactionCode: string;

    public statusReferences: StatusReferenceBase[];
    public paymentStatusReferences: PaymentStatusReferenceModel[];
    public orderSalesStatusReferences: OrderSalesStatusReferenceModel[];
    public orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[];
    public orderDeliveryStatusReferenceModel: OrderDeliveryStatusReferenceModel[];
    

    get statusHighlightColor(): string {
        return this.getHighlightColor();
    }

    get statusName(): string {
        return this.getStatusName();
    }

    constructor(private paymentStatusReferenceService: PaymentStatusReferenceService,
        private orderPaymentStatusReferenceService: OrderPaymentStatusReferenceService,
        private orderSalesStatusReferenceService: OrderSalesStatusReferenceService,
        private orderDeliveryStatusReferenceService: OrderDeliveryStatusReferenceService,
        private changeDetection: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        switch(this.statusType) {
            case StatusReferenceType.Sales:
                this.getOrderSalesStatusReferences();
                break;
            case StatusReferenceType.OrderPayment:
                this.getOrderPaymentStatusReferences();
                break;
            case StatusReferenceType.Payment:
                this.getPaymentStatusReferences();
                break;
            case StatusReferenceType.Delivery:
                this.getOrderDeliveryStatusReferences();
                break;
            default:
                break;
        }
    }

    private getHighlightColor(): string {
        switch(this.statusType) {
            case StatusReferenceType.Sales:
                return this.orderSalesStatusReferences?.find(x => x.orderSalesStatusCode == this.statusCode)?.highlightColor;
            case StatusReferenceType.Payment:
                return this.getHighlightForPaymentType();
            case StatusReferenceType.OrderPayment:
                return this.orderPaymentStatusReferences?.find(x => x.orderPaymentStatusCode == this.statusCode)?.highlightColor;
            case StatusReferenceType.Delivery:              
                return this.orderDeliveryStatusReferenceModel?.find(x => x.orderDeliveryStatusCode == this.statusCode)?.highlightColor;
            default:
                return "";
        }
    }

    private getHighlightForPaymentType(): string {
        if (this.checkTransactionCodeFlag) {
            if (this.statusCode == "PAID" && this.ledgerTransactionCode == "DR") {
                return this.paymentStatusReferences?.find(x => x.paymentStatusCode == "VOID")?.highlightColor;
            }
        }

        return this.paymentStatusReferences?.find(x => x.paymentStatusCode == this.statusCode)?.highlightColor;
    }

    private getStatusName(): string {
        switch(this.statusType) {
            case StatusReferenceType.Sales:
                return this.orderSalesStatusReferences?.find(x => x.orderSalesStatusCode == this.statusCode)?.orderSalesStatusName;
            case StatusReferenceType.Payment:
                return this.paymentStatusReferences?.find(x => x.paymentStatusCode == this.statusCode)?.paymentStatusName;
            case StatusReferenceType.OrderPayment:
                return this.orderPaymentStatusReferences?.find(x => x.orderPaymentStatusCode == this.statusCode)?.orderPaymentStatusName;
            case StatusReferenceType.Delivery:    
                return this.orderDeliveryStatusReferenceModel?.find(x => x.orderDeliveryStatusCode == this.statusCode)?.orderDeliveryStatusName;
            default:
                return "";
        }
    }

    private getPaymentStatusReferences() {
        this.paymentStatusReferenceService.getPaymentStatusReference()
            .subscribe(
                result => {
                    this.paymentStatusReferences = result;   
                    this.changeDetection.markForCheck();
            })
    }

    private getOrderSalesStatusReferences() {
        this.orderSalesStatusReferenceService.getOrderSalesStatusReference()
            .subscribe(
                result => {
                    this.orderSalesStatusReferences = result;
                    this.changeDetection.markForCheck();
            })
    }

    private getOrderPaymentStatusReferences() {
        this.orderPaymentStatusReferenceService.getOrderPaymentStatusReference()
            .subscribe(
                result => {
                    this.orderPaymentStatusReferences = result;
                    this.changeDetection.markForCheck();
            })
    }

    private getOrderDeliveryStatusReferences() {
        this.orderDeliveryStatusReferenceService.getOrderDeliveryStatusReference()
            .subscribe(
                result => {
                    this.orderDeliveryStatusReferenceModel = result;
                    this.changeDetection.markForCheck();
            })
    }
    
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationPointModel } from 'src/app/core/models/merchandizing-config';

import { ProductCodeSetRequireFieldService } from './productcode/productcode-set-require-field.service';
import { ProductTypeSetRequireFieldService } from './producttype/producttype-set-require-field.service';
import { RouteSetRequireFieldService } from './route/route-set-require-field.service';
import { LocationSetRequireFieldService } from './location/location-set-require-field.service';
import { OrganisationSetRequireFieldService } from './organisation/organisation-set-require-field.service';
import { CalendarSetRequireFieldService } from './calendar/calendar-set-require-field.service';
import { VehicleSetRequireFieldService } from './vehicle/vehicle-set-require-field.service';

@Injectable()
export class RuleConfigSetRequireFieldService {

    constructor(
        private productCodeSetRequireFieldService: ProductCodeSetRequireFieldService,
        private productTypeSetRequireFieldService: ProductTypeSetRequireFieldService,
        private routeSetRequireFieldService: RouteSetRequireFieldService,
        private locationSetRequireFieldService: LocationSetRequireFieldService,
        private organisationSetRequireFieldService: OrganisationSetRequireFieldService,
        private calendarSetRequireFieldService: CalendarSetRequireFieldService,
        private vehicleSetRequireFieldService: VehicleSetRequireFieldService) {
    }

    public setRequiredField(value: string, ctl, config, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        this.clearAllRequireField(ctl, config);
        if (value.toLowerCase() == "productcode") {
            this.productCodeSetRequireFieldService.setRequireProductCode(ctl);
        } else if (value.toLowerCase() == "producttype") {
            this.productTypeSetRequireFieldService.setRequireProductType(ctl);
        } else if (value.toLowerCase() == "route") {
            this.routeSetRequireFieldService.setRequireRoute(ctl);
        } else if (value.toLowerCase() == "location") {
            if (locationPoint$.value.length > 0) {
                this.locationSetRequireFieldService.setRequireLocationPoint(ctl);
            } else {
                this.locationSetRequireFieldService.setRequireLocation(ctl);
            }
        } else if (value.toLowerCase() == "organisation") {
            this.organisationSetRequireFieldService.setRequireOrganisation(ctl);
        } else if (value.toLowerCase() == "calendar") {
            this.calendarSetRequireFieldService.setRequireCalendar(ctl);
        } else if (value.toLowerCase() == "vehicle") {
            this.vehicleSetRequireFieldService.setRequireVehicle(ctl);
        }
    }

    private clearAllRequireField(ctl, config) {
        config.types.forEach(types => {
            switch (types.typeCode) {
                case 'productcode': {
                    this.productCodeSetRequireFieldService.clearRequireProductCode(ctl);
                    break;
                }
                case 'producttype': {
                    this.productTypeSetRequireFieldService.clearRequireProductType(ctl);
                    break;
                }
                case 'route': {
                    this.routeSetRequireFieldService.clearRequireRoute(ctl);
                    break;
                }
                case 'location': {
                    if (types.locationPoint) {
                        this.locationSetRequireFieldService.clearRequireLocationPoint(ctl);
                    }
                    else {
                        this.locationSetRequireFieldService.clearRequireLocation(ctl);
                    }
                    break;
                }
                case 'organisation': {
                    this.organisationSetRequireFieldService.clearRequireOrganisation(ctl);
                    break;
                }
                case 'calendar': {
                    this.calendarSetRequireFieldService.clearRequireCalendar(ctl);
                    break;
                }
                case 'vehicle': {
                    this.vehicleSetRequireFieldService.clearRequireVehicle(ctl);
                    break;
                }
            }
        });
    }

}
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from '../../base/api-service-base';
import { OrderBookingStatusReferenceModel } from "../../models/reference-model/reference-general-model/order-booking-status-reference.model";

@Injectable({
  providedIn: 'root'
})
export class OrderBookingStatusReferenceService extends ApiServiceBase {
  private readonly PATH = "/system/OrderBookingStatusReferences";

  constructor(private httpClient: HttpClient,
    private authService: AuthService) {
      super(httpClient, authService);
  }

  public getOrderBookingStatusReference(): Observable<OrderBookingStatusReferenceModel[]> {
    return this.httpGet<OrderBookingStatusReferenceModel[]>(this.PATH, null)
      .pipe(
        map(
          response => response.body
        )
      )
  }
}

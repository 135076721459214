import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionService } from 'src/app/core/utils/action.service';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CopyButtonModel, NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-ancillary-service-tabs',
    templateUrl: './ancillary-service-tabs.component.html',
    providers: [
        ActionbarService
    ]
})
export class AncillaryServiceTabsComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly searchTabId = 'search';
    readonly groupTabId = 'group';
    public readonly title = 'Ancillary Services Management';
    private readonly categoryCode = 'ANCILLARY';

    public selectedTab: string = this.groupTabId;
    public selectedServiceGroup: string;
    public searchFilterId: string;
    public executeFlag: boolean = true
    public selectedProductCategory: ProductCategory;
    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new RefreshButtonModel()
    );
    private unsubscribe$ = new Subject();
    private _navigationSubscription: Subscription;

    constructor(private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private actionServie: ActionService,
        private activityStoreService: ActivityStoreService) {}

    ngOnInit() {
        let param = this.navigationService.getParams();
        this.selectedTab = param?.tab ?? param?.selectedTab?.toLowerCase() ?? this.groupTabId;
        this.searchFilterId = param?.id;
        this.executeFlag = param?.executeFlag ?? true;
        this.selectedProductCategory = param?.selectedItem ?? param?.module;
        this.addAction(this.selectedTab);
        this.activityStore(this.selectedTab);
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    public onSearch(event) {
        this.selectedTab = this.searchTabId;
        this.selectedServiceGroup = event;
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.back) {
            this.navigationService.navigate('main/product/home', null, null);
        }
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public onActiveIdChange(activeId: string) {
        this.addAction(activeId);
        this.activityStore(activeId);
    }

    private addAction(activeId: string = null) {
        if (activeId == FavoriteConstant.SEARCH_ACTION.toLowerCase()) {
            this.actionServie.add(FavoriteConstant.SEARCH_ACTION, null, null);
        } else {
            this.actionServie.add(null, null, null);
        }
    }

    private activityStore(activeTabId: string) {
        this.activityStoreService.add(
            null,
            null,
            null,
            activeTabId,
            this.categoryCode,
            null);
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrderSeatModel } from "../../models/order-model";
import { OrderReshopResponse } from '../../models/order-model';

//This function will be remove when actual implementation of seat reshop is implement.
import orderReshopJson from '../../components/transport-seatmap/shared/mock-data/seat-order-reshop-mock.json';

@Injectable({
    providedIn: 'root'
})
export class OrderSeatService extends ApiServiceBase {
    private readonly PATH: string = "/order"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getOrderSeatByOrderTransport(orderId: string): Observable<OrderSeatModel[]> {

        return this.httpGet<OrderSeatModel[]>(this.PATH + "/orderSeats/" + orderId + "/transport", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    //This function will be remove when actual implementation of seat reshop is implement.
    public getOrderReshopSeatMock(): OrderReshopResponse {
        return orderReshopJson;
    }
}
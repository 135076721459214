import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core'
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { selectSettingsState } from './../../../store/settings/settings.selectors';
import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { InsightManagementModel } from '../../models/reference-model/reference-general-model/Insight-management.model';

@Injectable({
    providedIn: 'root'
})
export class InsightManagementService extends ApiServiceBase implements OnDestroy {
    private readonly PATH = "/system/InsightManagements"
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(httpClient: HttpClient,
        authService: AuthService, private store: Store<any>) {
        super(httpClient, authService);
    }

    public getAll(): Observable<InsightManagementModel[]> {
        return this.httpGet<InsightManagementModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getNavBarMinifyState(): boolean {
        let minifyState;
        this.store.select(selectSettingsState).pipe(
            takeUntil(this.destroy$))
            .subscribe(state => {
                minifyState = state.minifyNavigation
            })
        return minifyState;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe()
    }

}
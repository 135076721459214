import { Injectable } from '@angular/core';
import { RouteViewModel, ServiceTimeViewModel } from 'src/app/core/models/product-model/transport-model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { RouteView } from './route.view';
import { ProductAttributeCommandModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';

@Injectable()
export class RouteMapperService {

    private readonly ROOT_LEVEL_HL = 3;
    private readonly TIME_ONLY_FORMAT = 'HH:mm';
    private readonly TYPE_DEP = 'DEP';
    private readonly TYPE_ARR = 'ARR';
    private readonly ATTRIBUTE_TYPE_DEPTERMINAL = 'DEPTTERMINAL';
    private readonly ATTRIBUTE_TYPE_ARRTERMINAL = 'ARRTERMINAL';
    private readonly ATTRIBUTE_TYPE_DEPGATE = 'DEPTGATE';
    private readonly ATTRIBUTE_TYPE_ARRGATE = 'ARRGATE';
    private readonly ATTRIBUTE_TYPE_DAYCHG = 'ARRDAYCHG';

    constructor(private dateConverterService: DateConverterService) { }

    public routeModelToViews(models: RouteViewModel[], rootProductId: string): RouteView[] {
        let routeViews: RouteView[] = new Array();
        if (models) {
            for (let model of models) {
                if (model.hierarchyLength == this.ROOT_LEVEL_HL || model.type) {
                    routeViews.push(this.routeModelToView(model, rootProductId))
                }
            }
        }
        return routeViews;
    }

    private routeModelToView(model: RouteViewModel, rootProductId: string): RouteView {
        let view = {} as RouteView;
        view.productId = model.productId;
        view.parentProductId = model.parentProductId;
        view.airport = model.parentProductId ? model.locationName : model.productName;
        view.hierarchyKey = model.hierarchyKey;
        view.hierarchyLength = model.hierarchyLength;
        view.time = this.dateConverterService.convertTime24(model.time);
        view.dayChangeProductAttributeId = model.dayChangeProductAttributeId;
        view.dayChange = model.dayChange ? model.dayChange.toString() : null;
        view.terminalProductAttributeId = model.terminalProductAttributeId;
        view.terminal = model.terminal;
        view.gateProductAttributeId = model.gateProductAttributeId;
        view.gate = model.gate;
        view.rootProductId = rootProductId;
        view.locationId = model.locationId;
        view.productDateTimeId = model.productDateTimeId;
        view.depTime = model.depTime ? this.dateConverterService.convertTime24(model.depTime) : null;
        view.arrTime = model.arrTime ? this.dateConverterService.convertTime24(model.arrTime) : null;
        view.type = model.type;
        return view;
    }

    public routeToServiceTimeModels(routeViews: RouteView[]): ServiceTimeViewModel[] {
        let models = new Array<ServiceTimeViewModel>();
        let depTimes = routeViews.filter(x => x.type == this.TYPE_DEP);
        let arrTimes = routeViews.filter(x => x.type == this.TYPE_ARR);
        for (let depTime of depTimes) {
            if (this.hasTimeData(depTime.productDateTimeId, depTime.depTime)) {
                models.push(this.routeToServiceTimeModel(depTime.productDateTimeId, depTime.productId, depTime.locationId, depTime.depTime));
            }
        }

        for (let arrTime of arrTimes) {
            if (this.hasTimeData(arrTime.productDateTimeId, arrTime.arrTime)) {
                models.push(this.routeToServiceTimeModel(arrTime.productDateTimeId, arrTime.productId, arrTime.locationId, arrTime.arrTime));
            }
        }

        return models;
    }

    private routeToServiceTimeModel(productDateTimeId: string, productId: string, locationId: string, time: string): ServiceTimeViewModel {
        let model: ServiceTimeViewModel = {
            dateTimeCode: "SERVICETIME",
            localDateTime: this.dateConverterService.convertTimeStringToMinDateWithTime(time, this.TIME_ONLY_FORMAT),
            displaySequence: null,
            productDateTimeId: productDateTimeId ?? undefined,
            productId: productId,
            serviceTimeHierarchyKey: null,
            locationId: locationId
        };
        return model;
    }

    private hasTimeData(productDateTimeId: string, time: string) {
        return (productDateTimeId || time);
    }

    public routeToAttributeCommandModels(routeViews: RouteView[]): ProductAttributeCommandModel[] {
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let dayChangeProductAttributes = this.getDayChangeProductAttributes(routeViews);
        if (dayChangeProductAttributes?.length) {
            productAttributes.push(...dayChangeProductAttributes);
        }


        let terminalDepProductAttributes = this.getTerminalProductAttributes(routeViews, this.TYPE_DEP, this.ATTRIBUTE_TYPE_DEPTERMINAL);
        if (terminalDepProductAttributes?.length) {
            productAttributes.push(...terminalDepProductAttributes);
        }

        let terminalArrProductAttributes = this.getTerminalProductAttributes(routeViews, this.TYPE_ARR, this.ATTRIBUTE_TYPE_ARRTERMINAL);
        if (terminalArrProductAttributes?.length) {
            productAttributes.push(...terminalArrProductAttributes);
        }

        let gateDepProductAttributes = this.getGateProductAttributes(routeViews, this.TYPE_DEP, this.ATTRIBUTE_TYPE_DEPGATE);
        if (gateDepProductAttributes?.length) {
            productAttributes.push(...gateDepProductAttributes);
        }

        let gateArrProductAttributes = this.getGateProductAttributes(routeViews, this.TYPE_ARR, this.ATTRIBUTE_TYPE_ARRGATE);
        if (gateArrProductAttributes?.length) {
            productAttributes.push(...gateArrProductAttributes);
        }

        return productAttributes;
    }

    private getDayChangeProductAttributes(routeViews: RouteView[]): ProductAttributeCommandModel[] {
        let commands: ProductAttributeCommandModel[] = new Array();
        let dayChanges = routeViews.filter(x => x.type == this.TYPE_ARR);
        if (!dayChanges?.length) {
            return null;
        }

        for (let dayChange of dayChanges) {
            if (this.hasDayChangeData(dayChange.dayChangeProductAttributeId, dayChange.dayChange)) {
                commands.push(this.routeDayChangeToAttributeModel(dayChange));
            }
        }
        return commands;
    }

    private routeDayChangeToAttributeModel(route: RouteView): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        if (route.dayChangeProductAttributeId) {
            model.productAttributeId = route.dayChangeProductAttributeId;
        }
        model.productId = route.productId;
        model.attributeTypeCode = this.ATTRIBUTE_TYPE_DAYCHG;
        model.attributeValue = Number(route.dayChange);
        model.displaySequence = 1;
        return model;
    }

    private hasDayChangeData(dayChangeProductAttributeId, dayChange): boolean {
        return (dayChangeProductAttributeId || dayChange);
    }

    private getTerminalProductAttributes(routeViews: RouteView[], type: string, attributeTypeCode: string): ProductAttributeCommandModel[] {
        let commands: ProductAttributeCommandModel[] = new Array();
        let terminals = routeViews.filter(x => x.type == type);
        if (!terminals?.length) {
            return null;
        }

        for (let terminal of terminals) {
            if (this.hasTerminalData(terminal.terminalProductAttributeId, terminal.terminal)) {
                commands.push(this.routeTerminalToAttributeModel(terminal, attributeTypeCode));
            }
        }
        return commands;
    }

    private routeTerminalToAttributeModel(terminal: RouteView, attributeTypeCode: string): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        if (terminal.terminalProductAttributeId) {
            model.productAttributeId = terminal.terminalProductAttributeId;
        }
        model.productId = terminal.productId;
        model.attributeTypeCode = attributeTypeCode;
        model.attributeText = terminal.terminal;
        model.displaySequence = 1;
        return model;
    }

    private hasTerminalData(terminalProductAttributeId, terminal): boolean {
        return (terminalProductAttributeId || terminal);
    }

    private getGateProductAttributes(routeViews: RouteView[], type: string, attributeTypeCode: string): ProductAttributeCommandModel[] {
        let commands: ProductAttributeCommandModel[] = new Array();
        let gates = routeViews.filter(x => x.type == type);
        if (!gates?.length) {
            return null;
        }

        for (let gate of gates) {
            if (this.hasGateData(gate.gateProductAttributeId, gate.gate)) {
                commands.push(this.routeGateToAttributeModel(gate, attributeTypeCode));
            }
        }
        return commands;
    }

    private routeGateToAttributeModel(gate: RouteView, attributeTypeCode: string): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        if (gate.gateProductAttributeId) {
            model.productAttributeId = gate.gateProductAttributeId;
        }
        model.productId = gate.productId;
        model.attributeTypeCode = attributeTypeCode;
        model.attributeText = gate.gate;
        model.displaySequence = 1;
        return model;
    }

    private hasGateData(gateProductAttributeId, gate): boolean {
        return (gateProductAttributeId || gate);
    }

}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { OrdersDetailShareComponentModule } from "src/app/modules/order/shared";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { InsightSalesSummaryFilterComponent } from "./insight-sales-summary-filter/insight-sales-summary-filter.component";
import { InsightSalesSummaryResultComponent } from "./insight-sales-summary-result/insight-sales-summary-result.component";
import { InsightSalesSummaryResultOrderListComponent } from "./insight-sales-summary-result/order-list/sales-summary-result-order-list.component";
import { InsightSalesSummaryResultProductListComponent } from "./insight-sales-summary-result/product-list/sales-summary-result-product-list.component";
import { InsightSalesSummarySummaryComponent } from "./insight-sales-summary-summary/insight-sales-summary-summary.component";
import { InsightSalesSummaryComponent } from "./insight-sales-summary.component";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { StatusBadgeModule } from "src/app/shared/ui/forms/inputs/status-badge/status-badge.module";

@NgModule({
    declarations: [
        InsightSalesSummaryComponent,
        InsightSalesSummaryFilterComponent,
        InsightSalesSummarySummaryComponent,
        InsightSalesSummaryResultComponent,
        InsightSalesSummaryResultOrderListComponent,
        InsightSalesSummaryResultProductListComponent,
    ],
    exports: [
        InsightSalesSummaryComponent
    ],
    imports: [
        ButtonsModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        DaterangepickerModule,
        DxDataGridModule,
        DxTemplateModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        NgbModule,
        ReactiveFormsModule,
        PanelsModule,
        Select2Module,
        OrdersDetailShareComponentModule,
        StatusBadgeModule,
        UiModule
    ],
    providers: []
})
export class InsightSalesSummaryModule {    
}
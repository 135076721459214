import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import {
  OrganisationModel,
  OrganisationEditCommandModel,
  OrganisationLanguageModel,
  OrganisationTreeModel,
  OrganisationTypeReferenceModel,
  UserAccountLogonModel,
  ChangePasswordCommandModel,
} from '../../models/organisation-model';
import { SecurityGroupModel, UserLoginModel } from '../../models/user-model';
import { IOrganisationUserLoginModel } from './../../models/organisation-model/organisation-user-login.model';
import { IOrganisationUserLoginSystemPropertyModel } from './../../models/organisation-model/organisation-user-login-system-property.model';
import { IOtpCode, IQrcodeUrl } from '../../models/qr-code-url-model/qr-code-url-model';
import * as AuthActions from './../../../store/auth/auth.actions';
import { UserAccountLogonHistoryService } from '../system-services/user-account-logon-history.service';
import { USER_LOGON_DATA, USER_INFORMATION, USER_LOGON_NAME, ORGANISATION_COUNT } from 'src/app/modules/auth/shared/constants/auth.constant';
import { UserAccountOrganisationService } from '../user-account-services/user-account-organisation.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { selectAuthState } from 'src/app/store/auth/auth.selectors';
import { OrganisationSelectionModel } from '../../models/organisation-model/organisation-selection.model';
import { StringHelperService } from '../../utils/string-helper.service';
import { SecurityGroupOrganisationModel } from '../../models/security-model/security-group-organisation.model';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService extends ApiServiceBase {
  private readonly ORGANISATION_PATH = '/organisation';
  private readonly mainPageLink: string = '/main';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private buildVersionLabel;
  private envNamLabel;
  private afterUserSelectOrgInfo;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private store: Store<any>,
    private userAccountLogonHistoryService: UserAccountLogonHistoryService,
    private userAccountOrganisationService: UserAccountOrganisationService,
    private router: Router,
    private navigationService: NavigationService,
    private stringHelperService: StringHelperService
  ) {
    super(httpClient, authService);
    this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (state) {
          this.buildVersionLabel = state.buildVersion || ''
          this.envNamLabel = state.envName || ''
        }
      })
  }

  public searchOrganisation(
    organisationCode: string,
    organisationName: string,
    organisationCallName: string,
    statusCode: string
  ): Observable<OrganisationModel[]> {
    let params = new HttpParams()
      .set('code', organisationCode)
      .set('name', organisationName)
      .set('type', organisationCallName)
      .set('status', statusCode);

    return this.httpGet<any>(
      this.ORGANISATION_PATH + '/organisations',
      params
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public searchOrganisationById(
    organisaionId: string
  ): Observable<OrganisationModel> {
    return this.httpGet<OrganisationModel>(
      this.ORGANISATION_PATH + '/organisations/' + organisaionId,
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public searchOrganisationByCode(
    organisationCode: string
  ): Observable<OrganisationModel> {
    return this.httpGet<OrganisationModel>(
      this.ORGANISATION_PATH + '/organisations/code/' + organisationCode,
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public editOrganisation(
    organisationId: string,
    model: OrganisationEditCommandModel
  ): Observable<OrganisationModel> {
    return this.httpPut<OrganisationModel>(
      this.ORGANISATION_PATH + '/organisations/' + organisationId,
      model
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public addOrganisation(
    model: OrganisationEditCommandModel
  ): Observable<OrganisationModel> {
    return this.httpPost<OrganisationModel>(
      this.ORGANISATION_PATH + '/organisations/',
      model
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getOrganisation(id: string): Observable<OrganisationModel> {
    return this.httpGet<OrganisationModel>(
      this.ORGANISATION_PATH + '/Organisations/' + id,
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getByOrganisationType(
    organisationTypeCode: string
  ): Observable<OrganisationModel[]> {
    return this.httpGet<any>(
      this.ORGANISATION_PATH +
      '/Organisations/TypeCode/' +
      organisationTypeCode,
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getByStatus(statusCode: string): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(
      this.ORGANISATION_PATH + '/Organisations/status/' + statusCode,
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getOrganisationSelectionsById(organisationId: string): Observable<OrganisationSelectionModel[]> {
    let params = new HttpParams()
      .set('ownwithchildorganisationid', organisationId);

    return this.httpGet<OrganisationSelectionModel[]>(
      this.ORGANISATION_PATH + '/Organisations', params
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getSecurityGroupByOrganisationId(
    organisationId: string
  ): Observable<SecurityGroupModel[]> {
    return this.httpGet<any>(
      this.ORGANISATION_PATH +
      '/organisations/' +
      organisationId +
      '/securityGroup/',
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getSecurityGroupAll(): Observable<SecurityGroupModel[]> {
    return this.httpGet<any>(this.ORGANISATION_PATH + '/organisations/securityGroup/', null)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  public getSecurityGroupOrganisationByOrganisationId(organisationId): Observable<SecurityGroupOrganisationModel[]> {
    let param = organisationId ? '/' + organisationId : '';
    return this.httpGet<any>(this.ORGANISATION_PATH + '/organisations/securityGroupOrganisation' + param, null)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  public getSecurityGroupsByOrganisationId(organisationId): Observable<SecurityGroupModel[]> {
    return this.httpGet<any>(this.ORGANISATION_PATH + '/organisations/securityGroupOrganisation/distinct/' + organisationId, null)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  public getOrganisationLanguage(): Observable<OrganisationLanguageModel[]> {
    return this.httpGet<any>(
      this.ORGANISATION_PATH + '/organisations/languages',
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getOrganisationTree(
    organisationId: string
  ): Observable<OrganisationTreeModel> {
    return this.httpGet<OrganisationTreeModel>(
      this.ORGANISATION_PATH + '/organisations/' + organisationId + '/tree',
      null
    ).pipe(map((res) => res.body));
  }

  // Get organisation by user, mapped for select organisation page.
  public getOrganisationListByUserLogin(): Observable<IOrganisationUserLoginModel[]> {

    return this.httpGet<IOrganisationUserLoginModel[]>(
      this.ORGANISATION_PATH + '/organisationUserLogin',
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getSystemPropertyCode(): Observable<IOrganisationUserLoginSystemPropertyModel[]> {
    return this.httpGet<IOrganisationUserLoginSystemPropertyModel[]>(
      this.ORGANISATION_PATH + '/organisationUserLogin/systemPropertyCode',
      null
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getQrCodeUrl(username: string): Observable<IQrcodeUrl> {
    return this.httpGet<IQrcodeUrl>(this.ORGANISATION_PATH + "/otpGenerate/" + username, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }



  public verifyOtpCode(otpCode: IOtpCode) {
    const body = {
      otpCode: otpCode
    }

    return this.httpPost<any>(
      this.ORGANISATION_PATH + '/otpGenerate/verifyOtpCode', body
    ).pipe(
      map((response) => {
        return response.body;
      })
    );
  }

  public getOwnerOrganisation(systemPropertyCode: string) {
    return this.httpGet<any>(this.ORGANISATION_PATH + "/organisationUserLogin/ownerOrganisationSystemProperty/" + systemPropertyCode, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getByOrganisationOwner() {
    return this.httpGet<any>(this.ORGANISATION_PATH + "/organisations/owner", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public async updateSelectedOrg(orgId: string) {

    this.store.dispatch(AuthActions.orgSelected({ orgSelected: true }));
    let organisation = this.authService.getSelectedOrganisation();
    this.authService.setSelectedOrganisation(orgId);
    if (organisation) {
      await this.userAccountLogonHistoryService
        .AddOrganisation()
        .toPromise();
    } else {
      await this.userAccountLogonHistoryService
        .UpdateOrganisation()
        .toPromise();
    }
  }

  updateOrgIdSession(orgId) {
    const userData = this.authService.getCurrentUserData();
    const loadedUser = new UserLoginModel(
      userData?.token,
      userData?.expires_in,
      userData?.username,
      orgId,
      userData?.isVerifyCode
    )
    this.setSessionItem(USER_LOGON_DATA, JSON.stringify(loadedUser))
    if (loadedUser.token) {
      this.authService.updateUserData(loadedUser)
    }
  }

  public setSessionItem(key: string, value: string) {
    return this.authService.sessionStorageSetItem(key, value)
  }

  getUserLogonEmail(userAccountId, afterUserSelectOrgInfo) {
    this.afterUserSelectOrgInfo = afterUserSelectOrgInfo;
    this.userAccountOrganisationService.getUserAccount(userAccountId).subscribe(resp => {
      if (resp && resp.communicationOthers.length > 0) {
        resp.communicationOthers.forEach(item => {
          if (item.primaryFlag && item.communicationOtherTypeCode === 'EMAIL' && item.organisationId) {
            this.afterUserSelectOrgInfo.email = item.mailAddress
          }
        })
      }
      this.setSessionItem(USER_INFORMATION, JSON.stringify(this.afterUserSelectOrgInfo))
      this.store.dispatch(AuthActions.afterUserSelectOrg(this.afterUserSelectOrgInfo))
      this.navigateToMain();
    });
  }

  navigateToMain() {
    this.router.navigate([this.mainPageLink]);
  }

  checkByPassOrgPage(userAccontId) {
   this.userAccountOrganisationService.searchUserAccountOrganisationByUserAccountId(userAccontId)
      .pipe(take(1))
      .subscribe(resp => {
        const userDetail = resp[0];
        const updateUserInfo = { userAccountId: userDetail.userAccountId, firstName: userDetail.firstName, lastName: userDetail.lastName }
        this.authService.sessionStorageSetItem(USER_LOGON_NAME, JSON.stringify(updateUserInfo));
        this.getOrganisationListByUserLogin()
          .subscribe(
            (orgList) => {
              const orgCount = orgList.length;
              this.store.dispatch(AuthActions.orgCount({ orgCount: orgCount }))
              this.setSessionItem(ORGANISATION_COUNT, JSON.stringify(orgCount))
              const currentOrgList = orgList;
              if (!orgList || orgList.length == 0) {
                return throwError('Organisation not found.');
              }

              if (orgList.length > 1) {
                this.navigationService.navigate(
                  'select-organisation',
                  'Organisation',
                  true
                );
              } else {
                const item = currentOrgList[0]
                const orgId = item.userOrgId;
                this.updateSelectedOrg(orgId);
                this.updateOrgIdSession(orgId);
                const afterUserSelectOrgInfo = {
                  orgCallName: item.userOrgName,
                  securityGroupName: item.userOrgSecurityGroup,
                  lastLogon: item.userOrgLastLogon,
                  buildVersion: this.buildVersionLabel,
                  envName: this.envNamLabel,
                  organisationId: item.userOrgId,
                  email: null
                }
                this.setSessionItem(USER_INFORMATION, JSON.stringify(afterUserSelectOrgInfo))
                this.store.dispatch(AuthActions.afterUserSelectOrg(afterUserSelectOrgInfo))
                this.getUserLogonEmail(userDetail.userAccountId, afterUserSelectOrgInfo);
              }
            });
      });
  }

  public advanceSearchOrganisation(model: OrganisationModel, securityCode: string): Observable<OrganisationModel[]> {
    return this.httpPost<OrganisationModel[]>(this.ORGANISATION_PATH + "/organisations/search?securityCode=" + securityCode, model)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getOrganisationFilter(): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + '/organisations/search/filter', null)
      .pipe(
        map(res => res.body)
      )
  }

  public updateOrganisationStatus(organisationId: string, statusCode: string) {
    return this.httpPut<OrganisationModel>(this.ORGANISATION_PATH
      + "/organisations/Status/" + organisationId + '?statusCode=' + statusCode, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getScheduleDistribution() {
    let params = new HttpParams()
      .set('organisationRoleCode', 'DISTRIBUTION')
      .set('type', 'OTA')
      .set('status', 'A');

    return this.httpGet<any>(
      this.ORGANISATION_PATH + '/organisations', params).pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  public getOrganisationInOrderPointOfSales(): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + "/organisations/orderPointOfSales", null)
      .pipe(
        map(response => {
          return response.body;
        })
      )
  }

  public getOrderProductProvider(): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + "/organisations/orderProductProvider", null)
      .pipe(
        map(response => {
          return response.body;
        })
      )
  }

  public getOrderProductSupplier(): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + "/organisations/orderProductSupplier", null)
      .pipe(
        map(response => {
          return response.body;
        })
      )
  }

  public getOrderMembershipProvider(): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + "/organisations/orderMembershipProvider", null)
      .pipe(
        map(response => {
          return response.body;
        })
      )
  }

  public getRmsOrganisation() {
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + '/organisations/typecode/REVENUEMANAGEMENT', null)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  public getOrganisationTypes(organisationId: string): Observable<OrganisationTypeReferenceModel[]> {
    return this.httpGet<OrganisationTypeReferenceModel[]>(`${this.ORGANISATION_PATH}/organisations/${organisationId}/organisationType`, null)
      .pipe(
        map(response => response.body)
      )
  }

  public getByOrganisationTypes(organisationTypeCodes: string[]): Observable<OrganisationModel[]> {
    const paramName = 'organisationTypeCodes';
    let params = "";
    if (organisationTypeCodes?.length) {
      params = this.stringHelperService.createQueryParamFromArray(paramName, organisationTypeCodes);
    }
    return this.httpGet<OrganisationModel[]>(this.ORGANISATION_PATH + '/organisations/organisationTypes' + params, null)
      .pipe(
        map(response => response.body)
      )
  }

  public getUserAccountLogon(userAccountLogon: string): Observable<UserAccountLogonModel[]> {
    return this.httpGet<UserAccountLogonModel[]>(this.ORGANISATION_PATH + "/useraccounts/useraccount-logon/" + userAccountLogon, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public changePassword(userAccountId: string, command: ChangePasswordCommandModel) {
    return this.httpPut(
      this.ORGANISATION_PATH + '/useraccounts/changepassword/' + userAccountId, command)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportComponent } from './transport.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { TransportContentModule } from './transport-content/transport-content.module';

@NgModule({
  declarations: [
    TransportComponent
  ],
  imports: [
    CommonModule,
    TransportContentModule,
    UiModule
  ]
})
export class TransportModule { }

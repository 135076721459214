export const CREDITACCOUNT_SECURITY = 'CREDITACCOUNT'
export const CREDITACCOUNTALL_SECURITY = 'CREDITACCOUNTALL'
export const CREDITACCOUNTCATALOG_SECURITY = 'CREDITACCOUNTCATALOG'

export const CREDITACCOUNT_SECURITY_CODES = [
    CREDITACCOUNT_SECURITY,
    CREDITACCOUNTALL_SECURITY,
    CREDITACCOUNTCATALOG_SECURITY,
]

export const findCreditAccountSecurity = (userSecurity) => {
    const creditAccountSecurity = userSecurity.filter(s => CREDITACCOUNT_SECURITY_CODES.includes(s.securityCode))
    const all = creditAccountSecurity.find(s => s.securityCode == CREDITACCOUNTALL_SECURITY)
    if (all)
        return all

    const catalog = creditAccountSecurity.find(s => s.securityCode == CREDITACCOUNTCATALOG_SECURITY)
    if (catalog)
        return catalog

    const own = creditAccountSecurity.find(s => s.securityCode == CREDITACCOUNT_SECURITY)
    if (own)
        return own

    return null
}

import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { InsightOrderSearchFilterModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search-filter.model";
import { LocationAirportCitySearchModel } from "src/app/core/models/individual-model/individual-order-model/location-airport-city-search.model";
import { LocationCountrySearchModel } from "src/app/core/models/individual-model/individual-order-model/location-country-search.model";
import { LocationGroupSearchModel } from "src/app/core/models/individual-model/individual-order-model/location-group-search.model";
import { LocationRegionSearchModel } from "src/app/core/models/individual-model/individual-order-model/location-region-search.model";
import { OrderDateSearchModel } from "src/app/core/models/individual-model/individual-order-model/order-date-search.model";
import { OrderStatusSearchModel } from "src/app/core/models/individual-model/individual-order-model/order-status-search.model";
import { OutstandingBalanceModel } from "src/app/core/models/individual-model/individual-order-model/outstanding-balance-search.model";
import { ProductCategorySearchModel } from "src/app/core/models/individual-model/individual-order-model/product-category-search.model";
import { ProductNumberSearchModel } from "src/app/core/models/individual-model/individual-order-model/product-number-search.model";
import { ProductStatusSearchModel } from "src/app/core/models/individual-model/individual-order-model/product-status-search.model";
import { ServiceDateSearchModel } from "src/app/core/models/individual-model/individual-order-model/service-date-search.model";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { ConditionReferenceModel, CountryReferenceModel, LocationGroupModel, LocationReferenceModel, OrderBookingStatusReferenceModel, OrderDeliveryStatusReferenceModel, OrderPaymentStatusReferenceModel, OrderSalesStatusReferenceModel, RegionReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ProductCategoryUseModel, ProductGroupUseModel, ProductNumberTypeReferenceModel, ProductTypeUseModel } from "src/app/core/models/reference-model/reference-product-model";
import { LocationReferenceService, RegionReferenceService } from "src/app/core/services/airline-services";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { LocationGroupService } from "src/app/core/services/reference-service/reference-general-service";

import { ConditionReferenceService, OrderBookingStatusReferenceService, OrderDeliveryStatusReferenceService, OrderPaymentStatusReferenceService, OrderSalesStatusReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductNumberTypeReferenceService, ProductTypeService } from "src/app/core/services/system-services";
import { duplicateGroupValidation } from "src/app/core/validators";
import { Select2Themes } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service";
import { PricingDetailConstant } from "src/app/modules/pricing/rules/price-rule-detail/shared/pricing-detail.constant";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { DateTypeFilterConstant, DateTypeFilterTypeData, LocationPointTypeFilterData, LocationTypeFilterConstant, LocationTypeFilterTypeData, OrderStatusFilterTypeData, OutstandingBalanceFilterConstant, OutstandingBalanceFilterData, ProductStatusFilterTypeData, ProductTypeFilterConstant, ProductTypeFilterTypeData, SalesSummaryFilterConstant, SalesSummaryFilterTypeData, StatusFilterConstant, StatusTypeFilterConstant, StatusTypeFilterTypeData } from "../shared/constants/sales-summary-filter";
import { AirportCityOption, ConditionOption, CountryOption, DateConditionOption, EndDateOption, LocationGroupOption, 
    LocationPointTypeOption, OrderStatusOption, OutStandingBalanceOption, ProductCategoryOption, 
    ProductGroupOption, ProductNumberTypeOption, ProductStatusOption, ProductTypeOption, ProviderOption, RegionOption, select2InheritMultiple, 
    StartDateOption, StatusCodeOption, TypeDateOption, 
    TypeLocationOption, TypeOption, TypeProductOption, 
    TypeStatusOption } from "../shared/options/select2-sales-summary-fillter.options";

declare var moment: any;

@Component({
    selector: 'op-insight-sales-summary-filter',
    templateUrl: './insight-sales-summary-filter.component.html'
})
export class InsightSalesSummaryFilterComponent extends OopsComponentFormBase {
    private readonly TYPE = "type";
    private readonly TYPE_PRODUCT = "typeProduct";
    private readonly TYPE_DATE = "typeDate";
    private readonly TYPE_STATUS = "typeStatus";
    private readonly TYPE_LOCATION = "typeLocation";
    private readonly PRODUCT_CATEGORY = "productCategory";
    private readonly PRODUCT_GROUP = "productGroup";
    private readonly PRODUCT_TYPE = "productType";
    private readonly PRODUCT_NUMBER_TYPE = "productNumberType";
    private readonly PROVIDER = "provider";
    private readonly PRODUCT_NUMBER = "productNumber";
    private readonly ORDER_STATUS = "orderStatus";
    private readonly PRODUCT_STATUS = "productStatus";
    private readonly ORDER_DATE = "orderDate";
    private readonly SERVICE_DATE = "serviceDate";
    private readonly CONDITION = "conditionCode";
    private readonly DATE_CONDITION = "dateCondition";
    private readonly STATUS_CODE = "statusCode";
    private readonly OUTSTANDING_BALANCE = "outStandingBalance";
    private readonly LOCATION_POINT_TYPE = "locationPointType";
    private readonly COUNTRY = "country";
    private readonly REGION = "region";
    private readonly LOCATION_GROUP = "locationGroup";
    private readonly AIRPORT_CITY = "airportCity";
    private readonly START_DATE = "startDate";
    private readonly END_DATE = "endDate";

    private readonly ACTIVE_CODE = "A";

    private readonly dateTimeFormat = 'YYYY/MM/DD HH:mm'

    public isReadonly: boolean = false;
    public focusing: boolean = false;

    typeOption = TypeOption;
    typeProductOption = TypeProductOption;
    typeDateOption = TypeDateOption;
    typeStatusOption = TypeStatusOption;
    typeLocationOption = TypeLocationOption;
    productStatusOption = ProductStatusOption;
    orderStatusOption = OrderStatusOption;
    conditionOption = ConditionOption;
    dateConditionOption = DateConditionOption;
    statusCodeOption = StatusCodeOption;
    outStandingBalanceOption = OutStandingBalanceOption;
    locationPointTypeOption = LocationPointTypeOption;
    airportCityOption: any;
    startDateOption = StartDateOption;
    endDateOption = EndDateOption;
    productCategoryOption = ProductCategoryOption;
    productNumberTypeOption = ProductNumberTypeOption;
    providerOption = ProviderOption;
    productGroupOption = ProductGroupOption;
    productTypeOption = ProductTypeOption;    
    regionOption = RegionOption;    
    countryOption = CountryOption;    
    locationGroupOption = LocationGroupOption;    

    conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    orderSalesStatusReferences$ = new BehaviorSubject<Select2Data[]>(null);
    orderBookingStatusReferences$ = new BehaviorSubject<Select2Data[]>(null);
    orderDeliveryStatusReferences$ = new BehaviorSubject<Select2Data[]>(null);
    orderPaymentStatusReferences$ = new BehaviorSubject<Select2Data[]>(null);
    productCategoryUse$ = new BehaviorSubject<Select2Data[]>(null);
    productGroupUse$ = new BehaviorSubject<Select2Data[]>(null);
    productTypeUse$ = new BehaviorSubject<Select2Data[]>(null);
    regionReferences$ = new BehaviorSubject<Select2Data[]>(null);
    locationGroupReferences$ = new BehaviorSubject<Select2Data[]>(null);
    productNumberTypeReferences$ = new BehaviorSubject<Select2Data[]>(null);
    organisationReferences$ = new BehaviorSubject<Select2Data[]>(null);
    country$ = new BehaviorSubject<Select2Data[]>(null);

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @Input() searchMode = false;
    @Input() individualId: string;
    @Input() countryReferences: CountryReferenceModel[];

    @Output() numberOfRowsChange = new EventEmitter<number>();
    @Output() filterChange = new EventEmitter<InsightOrderSearchFilterModel>();

    get rows(): UntypedFormArray {
        return this.formGroup?.get("rows") as UntypedFormArray;
    }

    get typeDataSet(): { id: string; text: string }[] {
        return SalesSummaryFilterTypeData;
    }

    get typeProductDataSet(): { id: string; text: string }[] {
        return ProductTypeFilterTypeData;
    }

    get typeDateDataSet(): { id: string; text: string }[] {
        return DateTypeFilterTypeData;
    }

    get typeStatusDataSet(): { id: string; text: string }[] {
        return StatusTypeFilterTypeData;
    }

    get typeLocationDataSet(): { id: string; text: string }[] {
        return LocationTypeFilterTypeData;
    }

    get orderStatusDataSet(): { id: string; text: string }[] {
        return OrderStatusFilterTypeData;
    }

    get productStatusDataSet(): { id: string; text: string }[] {
        return ProductStatusFilterTypeData;
    }

    get locationPointTypeDataSet(): { id: string; text: string }[] {
        return LocationPointTypeFilterData;
    } 

    get outStandingBalanceDataSet(): { id: string; text: string }[] {
        return OutstandingBalanceFilterData;
    }

    constructor(public fb: UntypedFormBuilder,
        private conditionReferenceService: ConditionReferenceService,
        private orderSalesStatusReferenceService: OrderSalesStatusReferenceService,
        private orderPaymentStatusReferenceService: OrderPaymentStatusReferenceService,
        private orderBookingStatusReferenceService: OrderBookingStatusReferenceService,
        private orderDeliveryStatusReferenceService: OrderDeliveryStatusReferenceService,
        private locationReferenceService: LocationReferenceService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeReferenceService: ProductTypeService,
        private regionReferenceService: RegionReferenceService,
        private locationGroupReferenceService: LocationGroupService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private organisationService: OrganisationService) {
        super(fb);
    }

    private getConditionReferences() {
        this.conditionReferenceService
            .getConditionsByCodes([
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE,
            ])
            .subscribe((conditionReferences: ConditionReferenceModel[]) => {
                this.conditionReferences$.next(conditionReferences);
            });  
    }

    private getOrderSalesStatusReferences() {
        this.orderSalesStatusReferenceService.getOrderSalesStatusReference()
        .subscribe((orderSalesStatusReferences: OrderSalesStatusReferenceModel[]) => {
                this.orderSalesStatusReferences$.next(orderSalesStatusReferences.map(x => new Select2Data(x.orderSalesStatusCode, x.orderSalesStatusName)))
            });

    }

    private getOrderPaymentStatusReferences() {
        this.orderPaymentStatusReferenceService.getOrderPaymentStatusReference()
        .subscribe((orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[]) => {
                this.orderPaymentStatusReferences$.next(orderPaymentStatusReferences.map(x => new Select2Data(x.orderPaymentStatusCode, x.orderPaymentStatusName)))
            });

    }

    private getOrderBookingStatusReferences() {
        this.orderBookingStatusReferenceService.getOrderBookingStatusReference()
        .subscribe((orderBookingStatusReferences: OrderBookingStatusReferenceModel[]) => {
                this.orderBookingStatusReferences$.next(orderBookingStatusReferences.map(x => new Select2Data(x.orderBookingStatusCode, x.orderBookingStatusName)))
            });

    }

    private getOrderDeliveryStatusReferences() {
        this.orderDeliveryStatusReferenceService.getOrderDeliveryStatusReference()
        .subscribe((orderDeliveryStatusReferences: OrderDeliveryStatusReferenceModel[]) => {
                this.orderDeliveryStatusReferences$.next(orderDeliveryStatusReferences.map(x => new Select2Data(x.orderDeliveryStatusCode, x.orderDeliveryStatusName)))
            });
    }

    private getRegionReferences() {
        this.regionReferenceService.getRegionReference()
        .subscribe((responses: RegionReferenceModel[]) => {
                this.regionReferences$.next(responses.map(x => new Select2Data(x.regionCode, x.regionName)))
            });
    }

    private getLocationGroupReferences() {
        this.locationGroupReferenceService.getAll()
        .subscribe((responses: LocationGroupModel[]) => {
                this.locationGroupReferences$.next(responses.map(x => new Select2Data(x.locationGroupId, x.locationGroupName)))
            });
    }

    private getProductNumberTypeReferences() {
        this.productNumberTypeReferenceService.getAll()
        .subscribe((responses: ProductNumberTypeReferenceModel[]) => {
                this.productNumberTypeReferences$.next(responses.map(x => new Select2Data(x.productNumberTypeCode, x.productNumberTypeName)))
            });
    }

    private getOrganisationReferences() {
        this.organisationService.getByStatus(this.ACTIVE_CODE)
        .subscribe((responses: OrganisationModel[]) => {
                this.organisationReferences$.next(responses.map(x => new Select2Data(x.organisationId, x.providerIataCode)))
            });
    }

    private getProductCategoryUseByIndividual() {
        this.productCategoryReferenceService.getProductCategoryUseByIndividual(this.individualId)
        .subscribe((productCategoryUse: ProductCategoryUseModel[]) => {
                this.productCategoryUse$.next(productCategoryUse.map(x => new Select2Data(x.productCategoryCode, x.productCategoryName)))
            });
    }

    private getCountryReferences() {
        this.country$.next(this.countryReferences.map(x => new Select2Data(x.countryCode, x.countryName)))               
    }

    private getProductGroupUseByIndividual(catagoryCode: string) {
        this.productGroupReferenceService.getProductGroupUseByIndividual(this.individualId, catagoryCode)
            .subscribe((productGroupUse: ProductGroupUseModel[]) => {
                this.productGroupUse$.next(productGroupUse.map(x => new Select2Data(x.productGroupCode, x.productGroupName)))
            });
    }

    private getProductTypeUseByIndividual(groupCode: string) {
        this.productTypeReferenceService.getProductTypeUseByIndividual(this.individualId, groupCode)
        .subscribe((productTypeUse: ProductTypeUseModel[]) => {
                this.productTypeUse$.next(productTypeUse.map(x => new Select2Data(x.productTypeCode, x.productTypeName)))               
            });
    }


    public getStatusSelectList(row: any): Select2Data[] {
        let type: string;

        if (row.controls[this.ORDER_STATUS]?.value) {
            type = row.controls[this.ORDER_STATUS]?.value;
        }
        else {
            type = row.controls[this.PRODUCT_STATUS]?.value;
        }

        switch (type) {
            case StatusFilterConstant.salesStatus.id:
                return this.orderSalesStatusReferences$.value;
            case StatusFilterConstant.paymentStatus.id:
                return this.orderPaymentStatusReferences$.value;
            case StatusFilterConstant.bookingStatus.id:
                return this.orderBookingStatusReferences$.value;
            case StatusFilterConstant.deliveryStatus.id:
                return this.orderDeliveryStatusReferences$.value;;
            default:
                return null;
        }
    }

    getDeleteBtnVisibility(row: any): string {
        if (this.searchMode) {
            return "visible";
        }
        return row.controls["unitType"] || row.controls["currency"]
            ? "hidden"
            : "visible";
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            rows: new UntypedFormArray([], duplicateGroupValidation),
        });

        this.getConditionReferences();
        this.getOrderSalesStatusReferences();
        this.getOrderPaymentStatusReferences();
        this.getOrderBookingStatusReferences();
        this.getOrderDeliveryStatusReferences();
        this.getRegionReferences();
        this.getLocationGroupReferences();
        this.getProductNumberTypeReferences();
        this.getOrganisationReferences();

        this.airportCityOption = AirportCityOption;
        this.airportCityOption.ajax = this.select2AjaxSearchByLocationCode();

        this.getProductCategoryUseByIndividual();
        this.getCountryReferences();
    }

    public select2AjaxSearchByLocationCode() {
        return {
            transport: (params, success, failure) => {
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, null)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }

    public addRow() {
        if (this.someEmptyEntriesFound() || this.duplicateEntriesFound()) {
            this.startProcessing();
            return;
        }
        this.rows.push(this.createNewFormGroup());
        this.numberOfRowsChange.emit(this.rows?.length);
    }

    private someEmptyEntriesFound(): boolean {
        return this.rows.controls.some((group: UntypedFormGroup) => {
            return Object.keys(group.controls).some((key) =>
                group.controls[key].hasError("required")
            );
        });
    }

    private duplicateEntriesFound(): boolean {
        return this.rows.hasError("duplicate");
    }

    private createNewFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            type: new UntypedFormControl(null, Validators.required),
        });
    }

    public getSingleOption(option: any, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.SINGLE_VALUE_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    public getMultipleOption(option: any, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    onTypeChange(type: any, row: any) {
        row.controls['type'].setValue(type);

        this.resetFormGroup(row);
        switch (type) {
            case SalesSummaryFilterConstant.product.id:
                this.addProductFilterControls(row);
                break;
            case SalesSummaryFilterConstant.date.id:
                this.addDateFilterControls(row);
                break;
            case SalesSummaryFilterConstant.status.id:
                this.addStatusFilterControls(row);
                break;
            case SalesSummaryFilterConstant.location.id:
                this.addLocationFilterControls(row);
                break;
        }
    }
  
    onTypeDateChange(type: any, row: any) {
        row.controls['typeDate'].setValue(type);

        this.resetTypeDateFormGroup(row);
        switch (type) {
            case DateTypeFilterConstant.orderDate.id:
                this.addDateControl(row)
                break;
            case DateTypeFilterConstant.serviceDate.id:
                this.addDateControl(row)
                break;
        }
    }

    private resetTypeDateFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.DATE_CONDITION);
        row.removeControl(this.START_DATE);
        row.removeControl(this.END_DATE);
    }

    addDateControl(row: UntypedFormGroup) {
        this.addControl(row, this.DATE_CONDITION, '=');
        this.addControl(row, this.START_DATE);
    }

    onTypeStatusChange(type: any, row: any) {
        row.controls['typeStatus'].setValue(type);

        this.resetStatusTypeFormGroup(row);
        switch (type) {
            case StatusTypeFilterConstant.order.id:
                this.addControl(row, this.ORDER_STATUS);
                break;
            case StatusTypeFilterConstant.product.id:
                this.addControl(row, this.PRODUCT_STATUS);
                break;
        }
    }

    onTypeProductChange(type: any, row: any) {
        row.controls['typeProduct'].setValue(type);
        
        this.resetTypeProductFormGroup(row);
        switch (type) {
            case ProductTypeFilterConstant.productCategory.id:
                this.addControl(row, this.PRODUCT_CATEGORY);
                break;
            case ProductTypeFilterConstant.productNumber.id:
                this.addControl(row, this.PRODUCT_NUMBER_TYPE);
                this.addControl(row, this.PROVIDER);
                this.addControl(row, this.PRODUCT_NUMBER);
                break;
        }
    }

    private resetTypeProductFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.PRODUCT_CATEGORY);
        row.removeControl(this.PRODUCT_GROUP);
        row.removeControl(this.PRODUCT_TYPE);
        row.removeControl(this.PRODUCT_NUMBER_TYPE);
        row.removeControl(this.PROVIDER);
        row.removeControl(this.PRODUCT_NUMBER);
    }

    onProductCategoryChange(type: any, row: any) {
        row.controls['productCategory'].setValue(type);

        this.resetProductCategoryFormGroup(row);
        this.addControl(row, this.PRODUCT_GROUP);
        this.getProductGroupUseByIndividual(type)   
        setTimeout(() => {
            this.setFilterModels();
        }, 0); 
    }

    private resetProductCategoryFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.PRODUCT_GROUP);
        row.removeControl(this.PRODUCT_TYPE);
    }

    onProductGroupChange(type: any, row: any) {
        row.controls[this.PRODUCT_GROUP].setValue(type);

        this.resetProductGroupFormGroup(row);
        this.addControl(row, this.PRODUCT_TYPE);
        this.getProductTypeUseByIndividual(type)   
        setTimeout(() => {
            this.setFilterModels();
        }, 0);
    }
    
    private resetProductGroupFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.PRODUCT_TYPE);
    }
    
    onProductTypeChange(type: any, row: any) {
        row.controls[this.PRODUCT_TYPE].setValue(type);

        this.setFilterModels();
    }

    onProductNumberTypeChange(type: any, row: any) {
        row.controls[this.PRODUCT_NUMBER_TYPE].setValue(type);
        this.setFilterModels()
    }    

    onProductStatusChange(type: any, row: any) {
        row.controls[this.PRODUCT_STATUS].setValue(type);

        this.resetProductStatusFormGroup(row);
        switch (type) {
            case StatusFilterConstant.salesStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.bookingStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.deliveryStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.paymentStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.outstandingBalance.id:
                this.addOutStandingBalanceControl(row);
                break;
        }
    }

    private addOutStandingBalanceControl(row: UntypedFormGroup) {
        row.addControl(
            this.CONDITION,
            new UntypedFormControl(
                {
                    value: '=',
                    disabled: true,
                }
            )
        );

        this.addControl(row, this.OUTSTANDING_BALANCE);
    }

    private resetProductStatusFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.CONDITION);
        group.removeControl(this.STATUS_CODE);
        group.removeControl(this.OUTSTANDING_BALANCE);
    }

    onTypeLocationChange(type: any, row: any) {
        row.controls[this.TYPE_LOCATION].setValue(type);

        this.resetTypeLocationFormGroup(row);
        row.addControl(
            this.CONDITION,
            new UntypedFormControl(
                {
                    value: '=',
                    disabled: true,
                },
                Validators.required
            )
        );

        this.addControl(row, this.LOCATION_POINT_TYPE);

        switch (type) {
            case LocationTypeFilterConstant.region.id:
                this.addControl(row, this.REGION);
                break;
            case LocationTypeFilterConstant.country.id:
                this.addControl(row, this.COUNTRY);
                break;
            case LocationTypeFilterConstant.locationGroup.id:
                this.addControl(row, this.LOCATION_GROUP);
                break;
            case LocationTypeFilterConstant.airportCity.id:
                this.addControl(row, this.AIRPORT_CITY);              
                break;
        }        
    }

    private resetTypeLocationFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.CONDITION);
        row.removeControl(this.LOCATION_POINT_TYPE);
        row.removeControl(this.REGION);
        row.removeControl(this.AIRPORT_CITY);
        row.removeControl(this.COUNTRY);
        row.removeControl(this.LOCATION_GROUP);
    }

    onOrderStatusChange(type: any, row: any) {
        row.controls[this.ORDER_STATUS].setValue(type);

        this.resetOrderStatusFormGroup(row);
        switch (type) {
            case StatusFilterConstant.salesStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.paymentStatus.id:
                this.addStatusControl(row);
                break;
            case StatusFilterConstant.outstandingBalance.id:
                this.addOutStandingBalanceControl(row);
                break;
        }
    }

    addStatusControl(row: UntypedFormGroup) {
        row.addControl(
            this.CONDITION,
            new UntypedFormControl(
                {
                    value: '=',
                    disabled: true,
                },
                Validators.required
            )
        );

        this.addControl(row, this.STATUS_CODE);
    }

    private resetOrderStatusFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.CONDITION);
        group.removeControl(this.STATUS_CODE);
        group.removeControl(this.OUTSTANDING_BALANCE);
    }

    private resetStatusTypeFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.ORDER_STATUS);
        group.removeControl(this.PRODUCT_STATUS);
        group.removeControl(this.CONDITION);
        group.removeControl(this.STATUS_CODE);
        group.removeControl(this.OUTSTANDING_BALANCE);
    }

    startDateTimeChange(date: any, row: any) {
        if (row.controls[this.DATE_CONDITION].value == PricingDetailConstant.IS_OPERATOR_CODE) {
            this.setFilterModels();
        }
        else if (row.controls[this.END_DATE].value){
            this.setFilterModels();
        }
    }

    endDateTimeChange(date: any, row: any) {
        if (row.controls[this.START_DATE].value) {
            this.setFilterModels();
        }
    }

    private resetFormGroup(group: UntypedFormGroup) {
        Object.keys(group.controls).forEach((key) => {
            switch (key) {
                case this.TYPE:
                    break;
                default:
                    group.removeControl(key);
                    break;
            }
        });
    }

    onConditionChange(type: any, row: any, name: any) {
        if (!(row.controls[this.TYPE_DATE]?.value)) {
            return;   
        }

        row.controls[name].setValue(type);

        this.resetDateFormGroup(row);
        if (type == PricingDetailConstant.IS_OPERATOR_CODE) {
            this.addControl(row, this.START_DATE);
        }
        else {
            this.addControl(row, this.START_DATE);
            this.addControl(row, this.END_DATE);     
        }
        
    }

    private resetDateFormGroup(row: UntypedFormGroup) {
        row.removeControl(this.START_DATE);
        row.removeControl(this.END_DATE);
    }

    private addProductFilterControls(
        row: UntypedFormGroup,
    ) {
        this.addControl(row, this.TYPE_PRODUCT);
    }

    private addDateFilterControls(
        row: UntypedFormGroup,
    ) {
        this.addControl(row, this.TYPE_DATE);
    }

    private addStatusFilterControls(
        row: UntypedFormGroup,
    ) {
        this.addControl(row, this.TYPE_STATUS);
    }

    private addLocationFilterControls(
        row: UntypedFormGroup,
    ) {
        this.addControl(row, this.TYPE_LOCATION);
    }

    private addControl(
        row: UntypedFormGroup,
        controlName: string,
        defaultValue: any = null
    ) {
        row.addControl(
            controlName,
            new UntypedFormControl(defaultValue)
        );
    }

    deleteRow(index: number) {
        this.rows.removeAt(index);
        this.setFilterModels();
        this.numberOfRowsChange.emit(this.rows?.length);
    }

    clear() {
        this.formGroup = new UntypedFormGroup({
            rows: new UntypedFormArray([], duplicateGroupValidation),
        });

        this.setFilterModels();
    }

    onAirportCityChange(type: any, row: any) {
        row.controls[this.AIRPORT_CITY].setValue(type)
        setTimeout(() => {
            this.setFilterModels();
        }, 0);    
    }

    onCountryChange(type: any, row: any) {
        row.controls[this.COUNTRY].setValue(type);
        setTimeout(() => {
            this.setFilterModels();
        }, 0);  
    }

    onStatusCodeChange(type: any, row: any) {
        row.controls[this.STATUS_CODE].setValue(type);
        this.setFilterModels();
    }

    onOutStandingBalanceChange(type: any, row: any) {
        row.controls[this.OUTSTANDING_BALANCE].setValue(type);
        this.setFilterModels();
    }

    onLocationPointTypeChange(type: any, row: any) {
        row.controls[this.LOCATION_POINT_TYPE].setValue(type);
        if (row.controls[this.REGION]?.value ||
            row.controls[this.COUNTRY]?.value ||
            row.controls[this.AIRPORT_CITY]?.value ||
            row.controls[this.LOCATION_GROUP]?.value )
        {
            this.setFilterModels();
        }
    }

    private productNumberChangeTimeoutId = null;
    onProductNumberChange(type: string) {
        clearTimeout(this.productNumberChangeTimeoutId);
        this.productNumberChangeTimeoutId = setTimeout(() => {
            this.setFilterModels();
        }, 300)
    }

    onProviderChange(type: any, row: any) {
        row.controls[this.PROVIDER].setValue(type);

        this.setFilterModels();
    }


    public setFilterModels() {
        let filter : InsightOrderSearchFilterModel = {
            individualId: null,
            productCategorySearches: null,
            productNumberSearches: null,
            orderDateSearches: null,
            serviceDateSearches: null,
            locationAirportCitySearches: null,
            locationCountrySearches: null,
            locationGroupSearches: null,
            locationRegionSearches: null,
            orderStatusSearches: null,
            productStatusSearches: null,
            outstandingBalanceSearch: null
        }

        for (let i = 0; i < this.rows.controls.length; i++) {          
            let control = this.rows.controls[i] as UntypedFormGroup;
            switch(control.get(this.TYPE).value) {
                case SalesSummaryFilterConstant.product.id:
                    this.setFilterForTypeProduct(control, filter);
                    break;
                case SalesSummaryFilterConstant.date.id:
                    this.setFilterForTypeDate(control, filter);
                    break;
                case SalesSummaryFilterConstant.status.id:
                    this.setFilterForTypeStatus(control, filter);
                    break;
                case SalesSummaryFilterConstant.location.id:
                    this.setFilterForTypeLocation(control, filter);
                    break;
                default:
                    break;
            }
        }

        this.filterChange.emit(filter);
    }

    private setFilterForTypeProduct(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        switch(control.get(this.TYPE_PRODUCT).value) {
            case ProductTypeFilterConstant.productCategory.id:
                this.setFilterForProductCategory(control, filter);
                break;
            case ProductTypeFilterConstant.productNumber.id:
                this.setFilterForProductNumber(control, filter);
                break;
        }
    }

    private setFilterForProductNumber(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(!control.get(this.PRODUCT_NUMBER).value) {
            return;
        }


        let filt: ProductNumberSearchModel = {
            numberTypeCode: control.get(this.PRODUCT_NUMBER_TYPE)?.value,
            productNumber: control.get(this.PRODUCT_NUMBER)?.value,
            providerCode: control.get(this.PROVIDER)?.value,
        }

        filter.productNumberSearches = new Array();
        filter.productNumberSearches.push(filt);
    }

    private setFilterForProductCategory(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        let filt: ProductCategorySearchModel = {
            categoryCode: control.get(this.PRODUCT_CATEGORY)?.value,
            groupCode: control.get(this.PRODUCT_GROUP)?.value,
            typeCode: control.get(this.PRODUCT_TYPE)?.value,
        }

        filter.productCategorySearches = new Array();
        filter.productCategorySearches.push(filt);
    }

    private setFilterForTypeDate(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        switch(control.get(this.TYPE_DATE).value) {
            case DateTypeFilterConstant.orderDate.id:
                this.setFilterForOrderDate(control, filter);
                break;
            case DateTypeFilterConstant.serviceDate.id:
                this.setFilterForServiceDate(control, filter);
                break;
        }   
    }

    private setFilterForOrderDate(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        let flit: OrderDateSearchModel = {
            conditionCode: control.get(this.DATE_CONDITION).value,
            startDate: this.getDateFormat(control.get(this.START_DATE).value),
            endDate:this.getDateFormat(control.get(this.END_DATE)?.value),
        }

        filter.orderDateSearches = new Array();
        filter.orderDateSearches.push(flit);
    }

    private setFilterForServiceDate(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        let flit: ServiceDateSearchModel = {
            conditionCode: control.get(this.DATE_CONDITION).value,
            startDate: this.getDateFormat(control.get(this.START_DATE).value),
            endDate:this.getDateFormat(control.get(this.END_DATE)?.value),
        }

        filter.serviceDateSearches = new Array();
        filter.serviceDateSearches.push(flit);
    }

    private getDateFormat(dateString: string): Date {
        if(!dateString) {
            return null;
        }

        return moment.utc(dateString + ' 00:00', this.dateTimeFormat).toDate()
    }

    private setFilterForTypeStatus(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        switch(control.get(this.TYPE_STATUS).value) {
            case StatusTypeFilterConstant.order.id:
                this.setFilterForOrderStatus(control, filter);
                break;
            case StatusTypeFilterConstant.product.id:
                this.setFilterForProductStatus(control, filter);
                break;
        }  
    }

    private setFilterForOrderStatus(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(control.get(this.ORDER_STATUS).value == StatusFilterConstant.outstandingBalance.id) {
            this.setOutstandingBalance(control, filter);
            return;
        }

        let flit: OrderStatusSearchModel = {
            statusType: control.get(this.ORDER_STATUS).value,
            statusValues: control.get(this.STATUS_CODE).value
        }

        filter.orderStatusSearches = new Array();
        filter.orderStatusSearches.push(flit);
    }

    private setFilterForProductStatus(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(control.get(this.PRODUCT_STATUS).value == StatusFilterConstant.outstandingBalance.id) {
            this.setOutstandingBalance(control, filter);
            return;
        }

        let flit: ProductStatusSearchModel = {
            statusType: control.get(this.PRODUCT_STATUS).value,
            statusValues: control.get(this.STATUS_CODE).value
        }

        filter.productStatusSearches = new Array();
        filter.productStatusSearches.push(flit);
    }

    private setOutstandingBalance(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        let flit: OutstandingBalanceModel = {
            outstandingBalanceFlag: control.get(this.OUTSTANDING_BALANCE).value == OutstandingBalanceFilterConstant.yes.id ? true : false,
        }

        filter.outstandingBalanceSearch = flit;
    }

    private setFilterForTypeLocation(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        switch(control.get(this.TYPE_LOCATION).value) {
            case LocationTypeFilterConstant.airportCity.id:
                this.setFilterForAirportCity(control, filter);
                break;
            case LocationTypeFilterConstant.country.id:
                this.setFilterForCountry(control, filter);
                break;
            case LocationTypeFilterConstant.locationGroup.id:
                this.setFilterForLocationGroup(control, filter);
                break;
            case LocationTypeFilterConstant.region.id:
                this.setFilterForLocationRegionStatus(control, filter);
                break;
        }
    }

    private setFilterForAirportCity(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(!control.get(this.AIRPORT_CITY)?.value) {
            return;
        }

        let flit: LocationAirportCitySearchModel = {
            pointType: control.get(this.LOCATION_POINT_TYPE).value,
            pointId: control.get(this.AIRPORT_CITY).value,
            pointValue: null
        }

        filter.locationAirportCitySearches = new Array();
        filter.locationAirportCitySearches.push(flit);
    }

    private setFilterForCountry(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(!control.get(this.COUNTRY)?.value) {
            return;
        }

        let flit: LocationCountrySearchModel = {
            pointType: control.get(this.LOCATION_POINT_TYPE).value,
            pointId: null,
            pointValue: control.get(this.COUNTRY).value,
        }

        filter.locationCountrySearches = new Array();
        filter.locationCountrySearches.push(flit);
    }

    private setFilterForLocationGroup(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(!control.get(this.LOCATION_GROUP)?.value) {
            return;
        }

        let flit: LocationGroupSearchModel = {
            pointType: control.get(this.LOCATION_POINT_TYPE).value,
            pointId: control.get(this.LOCATION_GROUP).value,
            pointValue: null
        }

        filter.locationGroupSearches = new Array();
        filter.locationGroupSearches.push(flit);
    }

    private setFilterForLocationRegionStatus(control: UntypedFormGroup, filter: InsightOrderSearchFilterModel) {
        if(!control.get(this.REGION)?.value) {
            return;
        }

        let flit: LocationRegionSearchModel = {
            pointType: control.get(this.LOCATION_POINT_TYPE).value,
            pointId: null,
            pointValue: control.get(this.REGION).value
        }

        filter.locationRegionSearches = new Array();
        filter.locationRegionSearches.push(flit);
    }
}
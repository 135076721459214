import { Injectable } from "@angular/core";
import { InsightPassengerNationalityModel } from "src/app/core/models/individual-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { NationalityView } from "./nationality-view";

declare var moment: any;

@Injectable()
export class NationalityMapperService {
    constructor(private dateConverterService: DateConverterService,) {

    }

    public nationalityModelToViews(models: InsightPassengerNationalityModel[]): NationalityView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.nationalityModelToView(i, model));
        }
        return views;
    }

    private nationalityModelToView(index: number, model: InsightPassengerNationalityModel): NationalityView {
        let view = {} as NationalityView;
        view.no = index;
        view.individualNationalityId = model.individualNationalityId;
        view.individualId = model.individualId;
        view.countryCode = model.countryCode;
        view.countryName = model.countryName;
        view.placeOfBirth = model.placeOfBirth;
        view.primaryFlag = model.primaryFlag;
        view.countryOfBirthCode = model.countryOfBirthCode;

        return view;
    }

    public nationalityViewToModels(views: NationalityView[]): InsightPassengerNationalityModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.nationalityViewToModel(view));
        }
        return models;
    }

    private nationalityViewToModel(view: NationalityView): InsightPassengerNationalityModel {
        let model:InsightPassengerNationalityModel = {
            individualNationalityId: view.individualNationalityId,
            individualId: view.individualId,
            countryCode: view.countryCode,
            countryName: view.countryName,
            placeOfBirth: view.placeOfBirth,
            primaryFlag: view.primaryFlag,
            countryOfBirthCode: view.countryOfBirthCode
        };

        return model;
    }
}
import { Component, Input } from "@angular/core";
import {
    DomainAttributeService,
    ProductTypeGroupService
} from "src/app/core/services/airline-services";
import { MerchandizeService } from "src/app/core/services/product-services";
import { ProductService } from "src/app/core/services/product-services/product.service";
import { AttributeBase } from "../attribute/shared/attribute.base";
import { Select2OptionsService } from "../product-eligible-restricted/shared/select2-options.service";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Component({
    selector: 'op-product-attribute',
    templateUrl: '../attribute/shared/attribute.base.html'
})

export class ProductAttributeComponent extends AttributeBase {
    title: string = 'Product Attribute'
    priceRuleAttributeGroupCode: string = 'PRODUCTATTRIBUTE'
    focusing: boolean = false;

    @Input() priceRuleTypeCode: string = "";

    constructor(
        domainAttributeService: DomainAttributeService,
        select2OptionsService: Select2OptionsService,
        productTypeGroupService: ProductTypeGroupService,
        productService: ProductService,
        dateConverterService: DateConverterService) {
        super(
            domainAttributeService,
            select2OptionsService,
            productTypeGroupService,
            productService,
            dateConverterService);
    }
}
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class LocationValidationService {

    public findDuplicateLocationRegion(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationAreas == "region") {
                    if (ctl.value.locationAreas == ctlCheck.value.locationAreas &&
                        ctl.value.locationAreasOperator == ctlCheck.value.locationAreasOperator &&
                        this.checkArrayEqual(ctl.value.locationAreasValueRegion, ctlCheck.value.locationAreasValueRegion)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationCountry(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationAreas == "country") {
                    if (ctl.value.locationAreas == ctlCheck.value.locationAreas &&
                        ctl.value.locationAreasOperator == ctlCheck.value.locationAreasOperator &&
                        this.checkArrayEqual(ctl.value.locationAreasValueCountry, ctlCheck.value.locationAreasValueCountry)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationAirport(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationAreas == "airport") {
                    if (ctl.value.locationAreas == ctlCheck.value.locationAreas &&
                        ctl.value.locationAreasOperator == ctlCheck.value.locationAreasOperator &&
                        this.checkArrayEqual(ctl.value.locationAreasValueAirport, ctlCheck.value.locationAreasValueAirport)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationGroup(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationAreas == "locationgroup") {
                    if (ctl.value.locationAreas == ctlCheck.value.locationAreas &&
                        ctl.value.locationAreasOperator == ctlCheck.value.locationAreasOperator &&
                        this.checkArrayEqual(ctl.value.locationAreasValueGroup, ctlCheck.value.locationAreasValueGroup)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

}

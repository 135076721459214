export class ProductInventoryViewModel {
    public productInventoryId: string;
    public unitCode: string;
    public operatingProductId: string;
    public overConfirmFactor: Number;
    public expectedToService: Number;
    public inventoryConfirmOpenFlag: Number;
    public inventoryRequestOpenFlag: Number;
    public vehicleTypeCode: string;
    public vehicleCompositionId: string;
    public vehicleId: string;
    public productValidityId: string;
    public inventoryConfirmBaseUnits: Number;
    public inventoryRequestBaseUnits: Number;
    public commitLastname:string;
    public commitFirstname:string;
    public commitTitle:string;
    public commitDateTime:Date;    
}
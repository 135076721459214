// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.security-icon {
    height: -moz-fit-content;
    height: fit-content;
    vertical-align: middle;
    padding: 0px 6px 0px 6px;
}

mat-icon.ma {
    margin: 0px 0px 0px 0px;
    height: 24px;
}

mat-icon.ma.copy-icon {
    font-size: 21px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/settings-management/settings-categories-content/security-management/security-functions/functions-table/security-function-table.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["td.security-icon {\r\n    height: fit-content;\r\n    vertical-align: middle;\r\n    padding: 0px 6px 0px 6px;\r\n}\r\n\r\nmat-icon.ma {\r\n    margin: 0px 0px 0px 0px;\r\n    height: 24px;\r\n}\r\n\r\nmat-icon.ma.copy-icon {\r\n    font-size: 21px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import { ProductInventoryDimensionViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { ProductInventoryHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-hierarchy';
import { InventoryHeirarchyUpperLevelView } from './views/inventory-heirarchy-upper-level.view';
import { MapperService } from './shared/mapper.service';

declare var $: any;

@Component({
    selector: 'op-inventory',
    templateUrl: './inventory.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapperService]
})
export class InventoryComponent implements OnInit {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() heightPanelGeneral: number = 450;
    @Input() specific: boolean = false;

    @Output() valueReturn = new EventEmitter<any>();

    @ViewChild('dataGridVar', { static: false }) dataGrid: DxDataGridComponent;
    private productInventoryHierarchy: ProductInventoryHierarchyViewModel[];
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    private inventoryAttributeLowerLevel: string;
    private copyItem: any;
    public inventoryData: any;
    private heirarchyInvUpperLevel: InventoryHeirarchyUpperLevelView[] = new Array();
    public focusing: boolean = false;
    
    constructor(private changeDetectorRef: ChangeDetectorRef,
        private mapperService: MapperService,) {
    }

    ngOnInit(): void {
    }

    public fillModelToForm(productInventoryHierarchy: ProductInventoryHierarchyViewModel[], inventoryAttributeLowerLevel: string) {
        this.dataGrid.instance.state({});
        this.productInventoryHierarchy = productInventoryHierarchy;
        this.productInventoryDimensions = this.mapperService.createProductInventoryDimension(productInventoryHierarchy);
        this.inventoryAttributeLowerLevel = inventoryAttributeLowerLevel;
        this.inventoryData = this.createDynamicColumn();
        this.valueReturn.emit(this.inventoryData);
        this.changeDetectorRef.detectChanges();
    }

    private createDynamicColumn() {
        var dynamicDataArray = new Array();
        for (let data of this.productInventoryHierarchy) {
            if (data.productInventoryId) {
                var inventoryDataSourceTemp = {};
                inventoryDataSourceTemp["All"] = " ";
                inventoryDataSourceTemp["hierarchyKey"] = data.hierarchyKey;
                inventoryDataSourceTemp["hierarchyLength"] = data.hierarchyLength;
                for (let fieldName of this.productInventoryDimensions) {
                    if (fieldName.attributeTypeName == this.inventoryAttributeLowerLevel) {
                        inventoryDataSourceTemp[fieldName.attributeTypeName] = data.productName;
                    }
                    else {
                        inventoryDataSourceTemp[fieldName.attributeTypeName] = this.mapperService.getParentName(data, this.heirarchyInvUpperLevel, fieldName.attributeTypeCode);
                    }
                }
                inventoryDataSourceTemp["productInventoryId"] = data.productInventoryId;
                inventoryDataSourceTemp["productId"] = data.productId;
                inventoryDataSourceTemp["productName"] = data.productName;
                inventoryDataSourceTemp["parentProductId"] = data.parentProductId;
                inventoryDataSourceTemp["availableUnit"] = data.availableUnits;
                inventoryDataSourceTemp["availableUnitOriginal"] = data.availableUnits;
                inventoryDataSourceTemp["unitCode"] = data.unitCode;
                inventoryDataSourceTemp["unitName"] = data.unitName;

                dynamicDataArray.push(inventoryDataSourceTemp);
            }
            else {
                this.heirarchyInvUpperLevel.push(this.mapperService.createInventoryHeirarchyUpperLevel(data));
            }
        }
        return dynamicDataArray;
    }

    public getGroupIndex(col) {
        return col.displaySequence - 1;
    }

    public isInventoryCol(col): boolean {
        if (col.attributeTypeName == this.inventoryAttributeLowerLevel) {
            return true;
        }
        else {
            return false;
        }
    }

    public noInvData() {
        if (!this.productInventoryHierarchy) {
            return true;
        }
        else {
            if (this.productInventoryHierarchy.length == 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    public save() {
        this.valueReturn.emit(this.inventoryData);
    }

    public copyClick(item) {
        this.copyItem = item;
    }

    public pasteClick(item) {
        if (this.copyItem != null) {
            item.data.availableUnit = this.copyItem.availableUnit;
            item.component.refresh();
        }
    }

    public deleteClick(item) {
        item.data.availableUnit = 0;
        item.component.refresh();
    }

    public onEditorPreparing(e) {
        if (e.dataField == "availableUnit" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
        }
    }

    public cancel() {
        this.inventoryData = this.createDynamicColumn();
        this.valueReturn.emit(this.inventoryData);
        this.dataGrid.instance.refresh();
    }

    public calculateHeight(value: number): string {
        if (this.specific) {
            return (value - 31).toString();
        }
        else {
            return "100%";
        }
    }

    public getHeightGrid(): string {
        if (this.specific) {
            return "460px"
        }
        else {
            return "'100%'";
        }
    }

}
import { PriceRuleConditionBase } from ".";

export class PriceRuleFormOfPaymentView extends PriceRuleConditionBase {
    priceRuleFormOfPaymentId: string;
    priceRuleId?: string
    priceId?: string;
    formOfPaymentTypeCode: string;
    formOfPaymentCode: string;
    formOfPaymentIssuerCode: string;
    displaySequence?: number;
}
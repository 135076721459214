import { Injectable } from "@angular/core";
import { ProductCategoryViewModel } from "src/app/core/models/reference-model/reference-product-model/product-category-view.model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ProductCategory } from "./product-category";



@Injectable()
export class ProductCategoryMapperService {
    readonly PRODUCT_ROUTE_PATH = '/main/product/';
    constructor(private dateConverterService: DateConverterService) {
    }

    public toOopsCategoryViews(models: ProductCategoryViewModel[]): ProductCategory[] {
        let views: ProductCategory[] = new Array();
        if (views) {
            for (let model of models) {
                views.push(this.toOopsCategoryView(model));
            }
        }
        return views;
    }

    private toOopsCategoryView(model: ProductCategoryViewModel): ProductCategory {
        var view = {} as ProductCategory
        view.code = model.productCategoryCode;
        view.name = model.productCategoryName;
        view.modifiedBy = model.commitByName;
        view.modifiedDateTime = this.dateConverterService.convertDateTime24(model.commitDateTime);
        view.url = this.getUrl(view.name, view.code);
        view.productCount = model.productCount;
        return view;
    }

    private getUrl(categoryName: string, categoryCode: string): string {
        if (categoryCode == 'SERVICE') {
            return this.PRODUCT_ROUTE_PATH + categoryName.toLowerCase().replace(/ /g,"-");
        }
        return this.PRODUCT_ROUTE_PATH + categoryCode.toLowerCase();
    }
}
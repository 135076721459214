import { Injectable, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class UiHelperService {

    constructor(private viewportScroller: ViewportScroller) { }

    public getElementPosition(element: ElementRef): number {
        return element.nativeElement.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
    }

    public moveScrollPosition(element: ElementRef, offsetNumber: number) {
        let topPosition = this.getElementPosition(element);
        this.viewportScroller.scrollToPosition([0, topPosition - offsetNumber]);
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrderCommandModel } from "../../models/order-model/order";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { OrderFilterModel } from "../../models/order-model/order-filter";

@Injectable({
    providedIn: 'root'
})
export class OrderFilterService extends ApiServiceBase {
    private readonly PATH: string = "/order/orderFilters"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient, authService);
    }

    public getOrderFilterById(orderId: string): Observable<OrderFilterModel> {
        return this.httpGet<OrderFilterModel>(this.PATH + "/" + orderId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public addOrderFilter(model: OrderCommandModel): Observable<string> {
        return this.httpPost<string>(this.PATH + "/", model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public editOrderFilter(model: OrderCommandModel, orderId: string): Observable<string> {
        return this.httpPut<string>(this.PATH + "/" + orderId, model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";


export const PRODUCT_DASHBOARD_LASTRECEIVED_DEMO: ProductDashboardLastReceivedModel[] = [
    {
        productGroupCode: "FEE",
        productType: "Individual",
        productName: "Payment Fee",
        productCount: 15,
        amount: 300000.00
    },
    {
        productGroupCode: "FEE",
        productType: "Payment",
        productName: "Payment Fee",
        productCount: 59,
        amount: 650000.00
    },
    {
        productGroupCode: "MERCHANDIZE",
        productType: "Cleanser",
        productName: "OOPS Cleanser",
        productCount: 60,
        amount: 740000.00
    },
    {
        productGroupCode: "MERCHANDIZE",
        productType: "Adapters",
        productName: "OOPS Adapters",
        productCount: 38,
        amount: 150200.00
    },
    {
        productGroupCode: "MERCHANDIZE",
        productType: "Perfume",
        productName: "OOPS Perfume",
        productCount: 72,
        amount: 254890.00
    },
    {
        productGroupCode: "SERVICE",
        productType: "Meals",
        productName: "Kosher Meal",
        productCount: 98,
        amount: 487800.00
    },
    {
        productGroupCode: "SERVICE",
        productType: "Wheelchairs",
        productName: "Kosher Meal",
        productCount: 178,
        amount: 378900.00
    },
    {
        productGroupCode: "TRANSPORT",
        productType: "Car",
        productName: "OOPS Sedan",
        productCount: 54,
        amount: 612387.00
    },
    {
        productGroupCode: "TRANSPORT",
        productType: "Bus",
        productName: "OOPS Bus",
        productCount: 102,
        amount: 980150.00
    }
]
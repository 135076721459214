export const select2Attribute = {
    placeholder: "<Attribute>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2Dimension = {
    placeholder: "<Dimension>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

import { Observable, Subscriber } from 'rxjs';

import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { OrderIndividualAccountableDocumentModel } from 'src/app/core/models/order-model';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import {
    OrdersMapperService,
    OrderDetailChildLoadData,
    OrderIndividualAccountableDocumentView
} from '../..';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-accountable-document-list',
    templateUrl: './order-detail-accountable-document-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailAccountableDocumentListComponent {
    public readonly SPINNER_NAME: string = "orderIndividualAccountableDocument";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;

    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();
    @Input("changeSessionId") changeSessionId: string;

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderIndividualAccountableDocumentViews: OrderIndividualAccountableDocumentView[] = new Array<OrderIndividualAccountableDocumentView>();
    public orderLock: boolean;
    public countNumberOfItems: number = 0;

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }

    private checkCollape(): void {
        if (!this.orderIndividualAccountableDocumentViews || this.orderIndividualAccountableDocumentViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }
    public startLoading() {
        this.loadingNotifier.show(this.SPINNER_NAME);
    }

    public stopLoading() {
        this.loadingNotifier.hide(this.SPINNER_NAME);
    }
    public loadIndividualAccountableDocuments(orderId: string, orderLock: boolean): Observable<boolean> {

        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderIndividualAccountableDocuments(orderId)
            .subscribe(
                (result: OrderIndividualAccountableDocumentModel[]) => this.executeServiceResult(result, observer)
            )
        });   
    }
    public loadIndividualDetailAccountableDocuments(orderId: string, 
        partnerOrderId: string, 
        orderLock: boolean): Observable<boolean> {

        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderIndividualDetailAccountableDocuments(orderId, partnerOrderId, this.changeSessionId)
            .subscribe(
                (result: OrderIndividualAccountableDocumentModel[]) => this.executeServiceResult(result, observer)
            )
        });   
    }
    public loadProductDetailAccountableDocuments(orderId: string, 
        orderProductId: string, 
        orderLock: boolean,
        sessionId: string = null): Observable<boolean> {

        if (!orderId || !orderProductId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderProductAccountableDocuments(orderId, orderProductId, sessionId)
            .subscribe(
                (result: OrderIndividualAccountableDocumentModel[]) => this.executeServiceResult(result, observer)
            )
        });   
    }
    private executeServiceResult(result: OrderIndividualAccountableDocumentModel[], observer: Subscriber<boolean>) {
        this.fillNumberOfItems(result);
        this.orderIndividualAccountableDocumentViews = this.mapperService.toIndividualAccountableDocumentViews(result);
        this.childLoadCompleted.emit({
            recordCount: result?.length
        });
        this.checkCollape();
        this.changeDetection.detectChanges();
        observer.next(true);
        observer.complete();
    }
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    private fillNumberOfItems(models: OrderIndividualAccountableDocumentModel[]) {
        if (!models || models.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = models.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }

}
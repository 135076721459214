import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OrganisationUserView } from "src/app/modules/user-account/user-account-detail/shared/user-account-organisation-user.view";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { UserAccountModel, UserAccountOrganisationModel } from "../../models/user-model";
import { UserAccountSelectionModel } from "../../models/user-model/user-selection-view.model";

@Injectable({
  providedIn: 'root'
})
export class UserAccountOrganisationService extends ApiServiceBase {
  private readonly USERACCOUNTORGANISATION_PATH = "/organisation/userAccounts"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public searchUserAccountOrganisation(organisationId: string, securityGroupName: string, userLogon: string, firstName: string, lastName: string, statusCode: string, securityCode: string): Observable<UserAccountOrganisationModel[]> {
    let params = new HttpParams()
      .set("organisationId", organisationId)
      .set("securityGroupName", securityGroupName)
      .set("userLogon", userLogon)
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("status", statusCode)
      .set("securityCode", securityCode);
    return this.httpGet<any>(this.USERACCOUNTORGANISATION_PATH, params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public searchUserAccountOrganisationByUserLogon(userLogon: string): Observable<UserAccountOrganisationModel[]> {
    let params = new HttpParams()
      .set("userLogon", userLogon)
    return this.httpGet<any>(this.USERACCOUNTORGANISATION_PATH, params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getUserAccount(id: string): Observable<UserAccountModel> {
    return this.httpGet<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/" + id, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public editUserAccount(model: UserAccountModel): Observable<UserAccountModel> {
    return this.httpPut<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH, model)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public addUserAccount(model: UserAccountModel): Observable<UserAccountModel> {
    return this.httpPost<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH, model)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public validateExist(userLogon: string, userPin: string, userAccountId: string) {
    let params = new HttpParams()
      .set("userLogon", userLogon)
      .set("userPin", userPin)
      .set("userAccountId", userAccountId)

    return this.httpGet<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/validate", params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getUseAddress(individualId: string) {
    let params = new HttpParams()
      .set("individualId", individualId)

    return this.httpGet<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/useAddress", params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public validateMailAddress(mailAddress: string, id: string) {
    let params = new HttpParams()
      .set("mailAddress", mailAddress)
      .set("id", id)

    return this.httpGet<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/validateMailAddress", params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getOrganisationUser() {
    let organisationUserPath: string = "/organisationUser";
    return this.httpGet<OrganisationUserView>(this.USERACCOUNTORGANISATION_PATH + organisationUserPath, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
  public getOrganisationUserByOrganisationId(organisationId: string) {
    let organisationUserPath: string = `/organisationUser/${organisationId}`;
    return this.httpGet<OrganisationUserView>(this.USERACCOUNTORGANISATION_PATH + organisationUserPath, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getOrganisationUserByOrganisationIdAndUserAccountId(organisationId: string, userAccountId: string) {
    let organisationUserPath: string = `/organisationUser/${organisationId}/userAccount/${userAccountId}`;
    return this.httpGet<OrganisationUserView>(this.USERACCOUNTORGANISATION_PATH + organisationUserPath, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public updateUserAccountStatus(id: string, status: string): Observable<HttpResponse<UserAccountModel>> {
    return this.httpPut<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/status?userAccountId=" + id + "&statusCode=" + status, null);
  }

  public updateMultiFactorAuthenDateTime(userAccountId: string): Observable<HttpResponse<UserAccountModel>> {
    return this.httpPut<UserAccountModel>(this.USERACCOUNTORGANISATION_PATH + "/multiFactorAuthenDateTime?userAccountId=" + userAccountId, null);
  }

  public getByOrganisation(organisationId: string): Observable<UserAccountSelectionModel[]> {
    return this.httpGet<UserAccountSelectionModel[]>(this.USERACCOUNTORGANISATION_PATH + '/organisation/' + organisationId, null)
        .pipe(
            map(res => res.body)
        )
  }

  public searchUserAccountOrganisationByUserAccountId(userAccountId: string): Observable<UserAccountOrganisationModel[]> {
    return this.httpGet<any>(this.USERACCOUNTORGANISATION_PATH + '/useraccount-logon-detail/' + userAccountId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export class MediaLibraryView {
    public no: number;
    public selected: boolean;
    public mediaId: string;
    public mediaName: string;
    public validFrom: string;
    public validTo: string;
    public mediaHashTagIds: string[];
    public mediaHashTagName: string;
    public mediaUseCode: string;
    public mediaUseName: string;
    public mediaTypeCode: string;
    public mediaTypeName: string;
    public languageCode: string;
    public languageName: string;
    public mediaFile: File;
    public mediaFileBase64: any;
    public mediaFilePreview: File;
    public mediaFilePreviewBase64: any;
    public mediaFileNotFound: boolean;
    public mediaMediaHashTagIds: Select2Data[];
    public mediaContentId: string;
    public mediaMediaUseId: string;
    public totalUsedCount: Number;
}
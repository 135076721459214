import { BehaviorSubject } from "rxjs";
import { QueryList } from "@angular/core";
import { NgForm } from "@angular/forms";
import { PriceRuleAttributeAndRuleRowView } from "../product-eligible-restricted/shared/views";
import { Select2Option } from "./attribute-and-rule/views/select2.option";
import { Select2Themes } from "../product-eligible-restricted/shared/select2-options.service";

export abstract class AttributeAndRuleBase {
    abstract forms: QueryList<NgForm>;
    abstract rows$: BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>;

    abstract attemptToSubmit();

    protected addErrorFlag = false;

    public singleDisabledOption: Select2Option;
    public multipleDisabledOption: Select2Option;

    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);

    get allowToggle(): boolean {
        return !!(this.rows$.getValue()?.length);
    }

    public delete(row) {
        this.rows$.next(this.rows$.value.filter((r) => r != row));
        this.resetErrorFlags();
        if (!this.allowToggle) {
            this.togglePanelCollapseStatus(true);
        }
    }

    public resetErrorFlags() {
        this.addErrorFlag = false;
        let rows = this.rows$.value;
        for (let i = 0; i < this.rows$.value?.length; i++) {
            rows[i].duplicated = false;
        }
        this.rows$.next(rows);
    }

    protected noDuplicatedRowFound() {
        if (!this.rows$.value || this.rows$.value?.length < 2) {
            return true;
        }
        let temp = this.rows$.value;
        this.sortFormsBeforeCompare();
        this.setDuplicatedFlagIfFound(temp);
        this.rows$.next(temp);
        return this.rows$.value.every((row) => row.duplicated == false);
    }

    private setDuplicatedFlagIfFound(temp: PriceRuleAttributeAndRuleRowView[]) {
        let i = 0;
        for (let form of this.forms) {
            if (!temp[i].isInherit) {
                let currentFormValue = JSON.stringify(this.generateJsonFromNgForm(form));
                let formsToCompare = this.forms.filter((f, index) => f != form && !temp[index].isInherit);
                for (let formCompare of formsToCompare) {
                    let formCompareValue = JSON.stringify(this.generateJsonFromNgForm(formCompare));
                    if (currentFormValue === formCompareValue) {
                        temp[i].duplicated = true;
                    }
                }
            }
            i++;
        }
    }

    private generateJsonFromNgForm(form: NgForm) {
        let json = {};
        for (let key of Object.keys(form.controls)) {
            json[key] = form.controls[key].value;
        }
        return json;
    }

    private sortFormsBeforeCompare() {
        for (let form of this.forms) {
            let formValue = form.value;
            Object.keys(formValue).forEach((key) => {
                if (Array.isArray(formValue[key])) {
                    formValue[key] = formValue[key].sort();
                }
            });
        }
    }

    public isDuplicated(row) {
        return row.duplicated && this.addErrorFlag;
    }

    public isControlHasError(row, item) {
        return (item.control?.invalid || row.duplicated) && this.addErrorFlag;
    }

    public validForms() {
        if (this.forms?.length == 0) {
            return true;
        }
        return this.forms
            .toArray()
            .every((form) =>
                Object.keys(form.controls).every((key) => form.controls[key].valid || form.controls[key].disabled)
            );
    }

    public getConditionOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.CONDITION_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    public getSingleOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.SINGLE_VALUE_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    public getMultipleOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.disabled = true;
            return option;
        }
        delete option.disabled;
        return option;
    }

    onCollapseStatusChange(isCollapsed: boolean) {
        this.togglePanelCollapseStatus(isCollapsed);
    }

    togglePanelCollapseStatus(isCollapse: boolean) {
        if (!isCollapse && !this.rows$?.getValue()?.length) {
            return;
        }
        this.panelCollapseFlag$.next(isCollapse);
    }
}
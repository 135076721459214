import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AccountRecoveryService } from 'src/app/core/services/account-recovery-services/account-recovery.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { Globals } from "src/app/app.global-variable";
import { EmailPatternValidatorDirective } from 'src/app/core/validators';

@Component({
    selector: 'op-forgot-username',
    templateUrl: './forgot-username.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotUserNameComponent implements OnInit, OnDestroy {

    private readonly FAILED_MESSAGE = "User with this email does not exist or user is not active";
    private readonly SUCCESS_MESSAGE = "An email with your username has been sent. If the email does not arrive soon, please check your spam folder.";
    private readonly EMAIL_INVALID_MESSAGE = "Email address is invalid";
    private readonly CONTROL_EMAIL: string = "email";

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public errorText$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public loginForm: UntypedFormGroup;
    public disableSubmit: boolean = false;
    public changePasswordLinkInvalid: boolean = false;
    public changePasswordLinkInvalidMessage: string;

    constructor(private fb: UntypedFormBuilder,
        private navigationService: NavigationService,
        private accountRecoveryService: AccountRecoveryService,
        private loadingSpinner: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private changeDetectionRef: ChangeDetectorRef,
        private globals: Globals) {
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: [null, [Validators.required]
            ]
        });
    }

    public gotoLogon() {
        this.navigationService.navigate('auth', 'Logon');
    }

    public onforgotUserName() {
        this.errorText$.next("");
        if (this.loginForm.controls[this.CONTROL_EMAIL]?.errors?.notEmail) {
            this.errorText$.next(this.EMAIL_INVALID_MESSAGE);
            return;
        }
        let email = this.loginForm.controls[this.CONTROL_EMAIL].value;
        if (email) {
            this.loadingSpinner.showSaving();
            this.accountRecoveryService.forgotUserName(email)
                .subscribe(
                    () => {
                        this.loadingSpinner.saveComplete();
                        this.disabledForm();
                        this.displayAlertBarWarning();
                        this.changeDetectionRef.detectChanges();
                    },
                    (error) => this.handleError(),
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private disabledForm() {
        this.loginForm.controls[this.CONTROL_EMAIL].disable();
        this.disableSubmit = true;
        this.errorText$.next("");
    }

    private displayAlertBarWarning() {
        this.alertBarService.showSuccess(this.SUCCESS_MESSAGE);
    }

    private handleError() {
        this.errorText$.next(this.FAILED_MESSAGE);
    }

    ngOnDestroy(): void {
        this.loadingSpinner.hide();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { OopsCategory } from "src/app/core/components/categories/oops-category.model";

import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PAGE_TITLE, MODULE_NAME } from "../../../settings-management-content/shared/title-constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { takeUntil } from "rxjs/operators";
import { ExchangeRateTabsComponent } from "./exchange-rate-tabs/exchange-rate-tabs.component";

@Component({
    selector: 'op-exchange-rate-content',
    templateUrl: './exchange-rate-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class ExchangeRateContentComponent implements AfterViewInit, OnDestroy {
    @ViewChild(ExchangeRateTabsComponent) exchangRateTabComponent: ExchangeRateTabsComponent;
    private previousTitle: string = PAGE_TITLE.settings;
    private defaultTitle = PAGE_TITLE.exchange_rate;
    private unsubscribe$ = new Subject();
    public title: string = this.defaultTitle;
    public selectedItem: OopsCategory;
    actionBarHandler = new ActionBarHandlerModel(new RefreshButtonModel());
    private currentTabId: string = "";
    private _navigationSubscription: Subscription;
    private readonly CATEGORIES_TAB_ID = "CATEGORIES";

    constructor(private navigationService: NavigationService, 
        private actionbarService: ActionbarService) { }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.refresh) {
            this.refreshItem();
        }

        if (e == ACTION_STATUS.back) {
            this.navigationService.navigate('/main/settings/home', this.previousTitle);
        }

    }

    onSelectedTabChange(activeId: string) {
        this.currentTabId = activeId;
        this.gotoItem();
    } 

    onRowSelected(item: OopsCategory) {
        this.selectedItem = item;
    }

    private displayHeader(): string {
        let title: string;
        if (this.selectedItem) {
            title = this.createTitle(this.selectedItem.name);
        } else {
            title = this.defaultTitle;
        }
        return title;
    }

    private gotoItem() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.exchange_currency:
                    this.gotoExchangeCurrency();
                    break;
                case MODULE_NAME.exchange_rate_amount:
                    this.gotoExchangeRateAmount();
                    break;
            }
        }
    }

    private gotoExchangeCurrency() {
        let title = this.displayHeader();
        this.navigationService.navigate('/main/settings/exchange-currency', title);
    }

    private gotoExchangeRateAmount() {
        let title = this.displayHeader();
        this.navigationService.navigate("/main/settings/exchange-rate-amount", title);
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private createTitle(name: string): string {
        return name + ' Management';
    }

    private refreshItem() {
        if (this.currentTabId == this.CATEGORIES_TAB_ID) {
            this.exchangRateTabComponent.exchangeRateGroupComponent.getExchangeRateGroup();
        }
    }
}

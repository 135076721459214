import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class OrganisationSetRequireFieldService {

    public clearRequireOrganisation(ctl) {
        ctl.get('orgType').clearValidators();
        ctl.get('orgType').updateValueAndValidity();

        ctl.get('orgTypeOperators').clearValidators();
        ctl.get('orgTypeOperators').updateValueAndValidity();

        ctl.get('orgTypeValueType').clearValidators();
        ctl.get('orgTypeValueType').updateValueAndValidity();

        ctl.get('orgTypeValueGroup').clearValidators();
        ctl.get('orgTypeValueGroup').updateValueAndValidity();

        ctl.get('orgTypeValueRole').clearValidators();
        ctl.get('orgTypeValueRole').updateValueAndValidity();

        ctl.get('orgTypeValueSpecific').clearValidators();
        ctl.get('orgTypeValueSpecific').updateValueAndValidity();
    }

    public setRequireOrganisation(ctl) {
        ctl.get('orgType').setValidators(Validators.required);
        ctl.get('orgType').updateValueAndValidity();

        ctl.get('orgTypeOperators').setValidators(Validators.required);
        ctl.get('orgTypeOperators').updateValueAndValidity();

        switch (ctl.get('orgType').value) {
            case 'type': {
                ctl.get('orgTypeValueType').setValidators(Validators.required);
                ctl.get('orgTypeValueType').updateValueAndValidity();
                break;
            }
            case 'group': {
                ctl.get('orgTypeValueGroup').setValidators(Validators.required);
                ctl.get('orgTypeValueGroup').updateValueAndValidity();
                break;
            }
            case 'role': {
                ctl.get('orgTypeValueRole').setValidators(Validators.required);
                ctl.get('orgTypeValueRole').updateValueAndValidity();
                break;
            }
            case 'specific': {
                ctl.get('orgTypeValueSpecific').setValidators(Validators.required);
                ctl.get('orgTypeValueSpecific').updateValueAndValidity();
                break;
            }
        }
    }

}
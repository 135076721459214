import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'smart-input-field',
  templateUrl: './input-field.component.html'
})
export class InputFieldComponent {

  @Input() iconPrefixName: string;
  @Input() iconSuffixName: string;
  @Input() inputPlaceholder: string = '';
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  
  constructor() { }

  public clearInput() {
    this.value = "";
    this.valueChange.emit(this.value);
  }
  public onChange(event) {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
  }
}

export const restrictionConfigData = {
    types: [
        {
            typeCode: "productcode",
            typeName: "Product Number",
            operators: [
                {
                    operatorCode: "=",
                    operatorName: "is"
                },
                {
                    operatorCode: "!=",
                    operatorName: "is not"
                }
            ]
        },
        {
            typeCode: "producttype",
            typeName: "Product Type",
            operators: [
                {
                    operatorCode: "=",
                    operatorName: "is"
                },
                {
                    operatorCode: "!=",
                    operatorName: "is not"
                }
            ]
        },
        {
            typeCode: "route",
            typeName: "Route",
            operators: [
                {
                    operatorCode: "=",
                    operatorName: "is"
                },
                {
                    operatorCode: "!=",
                    operatorName: "is not"
                }
            ]
        },
        {
            typeCode: "location",
            typeName: "Location",
            locationPoint: [
                {
                    locationPointCode: "restriction",
                    locationPointName: "Restriction",
                    areas: [
                        {
                            areaCode: "",
                            areaName: "<area>"
                        },
                        {
                            areaCode: "region",
                            areaName: "Region"
                        },
                        {
                            areaCode: "country",
                            areaName: "Country"
                        },
                        {
                            areaCode: "airport",
                            areaName: "Airport/City"
                        },
                        {
                            areaCode: "group",
                            areaName: "Group"
                        }
                    ],
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ],
                    ods: [
                        {
                            odCode: "",
                            odName: "<O/D>"
                        },
                        {
                            odCode: "origin",
                            odName: "Origin"
                        },
                        {
                            odCode: "destination",
                            odName: "Destination"
                        }
                    ]
                }
            ]
        }
    ]
}
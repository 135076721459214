import { LanguageTranslationSetViewModel } from "src/app/core/models/language-translation-set-model";
import { PriceAttributeView,
	PricePriceDimensionView,
	PriceConditionView,
    PriceHierarchyView,
    PriceRuleOrganisationView,
    PriceRuleLocationView,
    PriceRuleValidityView,
    PriceRuleMembershipView,
    PriceRulePriceView
} from ".";
import { 
    PriceRuleCurrencyView,
	PriceRuleProductNumberView,
	PriceRuleRouteView,
	PriceRuleSalesBucketView,
	PriceRuleServiceCategoryView
} from "../../../rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/views";

export class PriceView {
    public no: number;
    public priceId: string;
    public priceCode: string;
    public priceName: string;
    public status: string;
    public parentPriceId: string;
    public organisationId: string;
    public usageTypeCode: string;
    public unitCode: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public ownerFilterUserAccountId: string;
    public isOwnerFilter: boolean;
    public combinationFlag: boolean;
    public draftFlag: boolean;
    public commitBy: string;
    public commitDateTime: Date;
    public productNumbers = new Array<PriceRuleProductNumberView>();
    public organisations = new Array<PriceRuleOrganisationView>();
    public routes = new Array<PriceRuleRouteView>();
    public locations = new Array<PriceRuleLocationView>();
    public validities = new Array<PriceRuleValidityView>();
    public serviceCategories = new Array<PriceRuleServiceCategoryView>();
    public salesBuckets = new Array<PriceRuleSalesBucketView>();
    public memberships = new Array<PriceRuleMembershipView>();
    public currencies = new Array<PriceRuleCurrencyView>();
    public attributes = new Array<PriceAttributeView>();
    public pricePriceDimensions = new Array<PricePriceDimensionView>();
    public conditions = new Array<PriceConditionView>();
    public children = new Array<PriceView>();
    public hierarchy: PriceHierarchyView;
    public priceRulePrices = new Array<PriceRulePriceView>();
    public languageTranslationSet: LanguageTranslationSetViewModel;
}
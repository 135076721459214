import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
    select2Status, select2HashtagOption, select2UsageType
} from '../shared/pricing-detail-information-configuration';

import { Helper } from 'src/app/shared/helper/app.helper';
import { Locale, Select2Option } from '../attribute-and-rule/shared/attribute-and-rule/views/select2.option';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PricingService } from 'src/app/core/services/pricing-services';
import { PricingConverter } from '../../../shared/pricing.converter';
import { PriceRuleView } from '../../../shared/views/price-rule.view'; 0
import { PriceRuleModel } from 'src/app/core/models/pricing-model';
import { BehaviorSubject, forkJoin } from 'rxjs';
import {
    PriceRuleGroupReferenceService,
    PriceRuleTypeGroupService
} from 'src/app/core/services/system-services';
import {
    PriceRuleGroupReferenceModel,
    PriceRuleTypeGroupModel
} from 'src/app/core/models/reference-model/reference-pricing-model';
import { UsageTypeReferenceService } from 'src/app/core/services/system-services/usage-type-reference.service';
import { map } from 'rxjs/operators';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { AlertBarService } from 'src/app/shared/layout/alertbar';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'op-general',
    templateUrl: './general.component.html',
    styles: [
        `
        .loading-spinner {
            margin-top: 12px;
            position: absolute;
            right: 34px;
        }
        `
    ],
    providers: [
        PricingService,
        PricingConverter
    ]
})
export class GeneralComponent implements OnInit, OnChanges {
    private readonly TEMPLATE_USAGE_TYPE = 'TEMPLATE';
    private readonly DEFAULT_USAGE_TYPE = 'DATA';
    private readonly FILTER_USAGE_TYPE = 'FILTER';
    private readonly PANEL_NAME = 'General Information';
    public readonly PRICE_RULE_CODE_REQUIRED = 'Price Rule Code is required.';
    public readonly PRICE_RULE_NAME_REQUIRED = 'Price Rule Name is required.';
    public readonly PRICE_RULE_CODE_DUPLICATED = 'Price Rule Code is duplicated.';
    public readonly PRICE_RULE_NAME_DUPLICATED = 'Price Rule Name is duplicated.';
    public readonly USAGE_TYPE_REQUIRED = 'Usage Type is required.';
    @Input() priceRuleView: PriceRuleView;
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Input() classIcon: string;
    @Input() priceRuleCategoryReferences: Select2Data[];
    @Input() statusData: Select2Data[];
    @Input() disabled: boolean = false;
    @Output() classIconOutput = new EventEmitter<string>();
    @Output() onSave = new EventEmitter<PriceRuleModel>();
    @Output() priceRuleViewChange = new EventEmitter<PriceRuleView>();
    @Output() priceRuleNameChange = new EventEmitter<string>();
    @Output() priceRuleDisplayNameChange = new EventEmitter<string>();
    @Output() statusCodeChange = new EventEmitter<string>();

    @ViewChild('priceRuleForm') priceRuleForm: NgForm;
    @ViewChild('submitBtn') submitBtn: ElementRef;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing: boolean = true;

    public priceRuleView$ = new BehaviorSubject<PriceRuleView>(null);
    public priceRuleGroupReferences$ = new BehaviorSubject<PriceRuleGroupReferenceModel[]>(null);
    public priceRuleTypeGroups$ = new BehaviorSubject<PriceRuleTypeGroupModel[]>(null);
    usageTypeReferences$ = this.usageTypeReferenceService.getUsageTypeReferences()
        .pipe(map(res => res.filter(r => this.displayUsageType.includes(r.usageTypeCode))))

    public submitted = false;

    public statusOption: any;
    public hashTagOption: any;
    public settings: any;

    public dateRangePickerOption: Select2Option;
    usageTypeOption = select2UsageType;

    public publishSegmentValue = 'NOT_PUBLISH';

    private priceRuleGroupReferences: PriceRuleGroupReferenceModel[];
    private priceRuleTypeGroups: PriceRuleTypeGroupModel[];

    private readonly displayUsageType = [
        this.DEFAULT_USAGE_TYPE,
        this.TEMPLATE_USAGE_TYPE
    ]

    get invalidPriceRuleCode(): boolean {
        let view = this.priceRuleView$.getValue();
        if (!view?.priceRuleCode && view?.usageTypeCode != this.TEMPLATE_USAGE_TYPE) {
            return true;
        }
        return false;
    }

    get showRequiredFieldAsterisk(): boolean {
        let view = this.priceRuleView$.getValue();
        return view?.usageTypeCode != this.TEMPLATE_USAGE_TYPE;
    }

    get errorList(): any {
        let errors = [];
        let view = this.priceRuleView$.value;
        if (this.invalidPriceRuleCode) {
            errors.push(() => this.alertbarService.show(this.PANEL_NAME, this.PRICE_RULE_CODE_REQUIRED));
        }
        if (!view?.priceRuleName) {
            errors.push(() => this.alertbarService.show(this.PANEL_NAME, this.PRICE_RULE_NAME_REQUIRED));
        }
        if (!view?.usageTypeCode) {
            errors.push(() => this.alertbarService.show(this.PANEL_NAME, this.USAGE_TYPE_REQUIRED));
        }
        return errors;
    }

    constructor(private helper: Helper,
        private converter: PricingConverter,
        private cdr: ChangeDetectorRef,
        private priceRuleGroupReferenceService: PriceRuleGroupReferenceService,
        private priceRuleTypeGroupService: PriceRuleTypeGroupService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private alertbarService: AlertBarService) {

        this.statusOption = select2Status;
        this.hashTagOption = select2HashtagOption;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.submitted = false;
        if (changes['priceRuleView']) {
            this.setupPriceRuleView(changes);
            this.priceRuleGroupReferences$.next(this.priceRuleGroupReferences?.filter(r => r.priceRuleCategoryCode == this.priceRuleView$.getValue().priceRuleCategoryCode));
            this.priceRuleTypeGroups$.next(this.priceRuleTypeGroups?.filter(p => p.priceRuleGroupCode == this.priceRuleView$.getValue()?.priceRuleGroupCode))
        }
    }

    private setupPriceRuleView(changes: SimpleChanges) {
        if (changes['priceRuleView'].currentValue) {
            this.priceRuleView$.next(changes['priceRuleView'].currentValue);
        } else {
            this.priceRuleView$.next(this.createNewPriceRule());
        }
    }

    private createNewPriceRule(): PriceRuleView {
        let newPriceRuleView = new PriceRuleView();
        newPriceRuleView.usageTypeCode = this.DEFAULT_USAGE_TYPE;
        return newPriceRuleView;
    }

    ngOnInit(): void {
        this.priceRuleView = this.createNewPriceRule();
        this.dateRangePickerOption = this.createDatetimePickerOption('Enter DateTime');

        this.priceRuleGroupReferenceService
            .getPriceRuleGroupReferences()
            .subscribe(
                res => {
                    this.priceRuleGroupReferences = res;
                    this.priceRuleGroupReferences$.next(this.priceRuleGroupReferences.filter(r => r.priceRuleCategoryCode == this.priceRuleView$.getValue().priceRuleCategoryCode));
                }
            );


        this.priceRuleTypeGroupService
            .getPriceRuleTypeGroups()
            .subscribe(
                res => {
                    this.priceRuleTypeGroups = res;
                    this.priceRuleTypeGroups$.next(this.priceRuleTypeGroups.filter(p => p.priceRuleGroupCode == this.priceRuleView$.getValue()?.priceRuleGroupCode));
                    if (this.priceRuleView$.value && !this.priceRuleView$.value.priceRuleTypeCode) {
                        this.setDefaultPriceRuleTypeCode()
                    }
                }
            )
    }

    private createDatePickerOption(placeholder: string = null): Select2Option {
        let datePickerOptionLocale = new Locale();
        datePickerOptionLocale.cancelLabel = 'Clear';
        datePickerOptionLocale.format = 'YYYY/MM/DD';

        let datePickerOption = new Select2Option();
        datePickerOption.singleDatePicker = true;
        datePickerOption.showDropdowns = true;
        datePickerOption.autoApply = true;
        datePickerOption.autoUpdateInput = true;
        datePickerOption.startDate = moment();
        datePickerOption.endDate = moment();
        datePickerOption.locale = datePickerOptionLocale;
        if (placeholder) {
            datePickerOption.placeholder = placeholder;
        }
        return datePickerOption;
    }

    private createDatetimePickerOption(placeholder: string = null): Select2Option {
        let option = this.createDatePickerOption(placeholder);
        option.timePicker = true;
        option.timePicker24Hour = true;
        option.locale.format = 'YYYY/MM/DD HH:mm';
        return option;
    }

    onResized(event: any) {
        this.onPanelHeightChange.emit(event.newRect.height);
    }

    getClassIcon() {
        let value = this.helper.getClassIcon();
        this.classIconOutput.emit(value);
        return value;
    }

    setFocusName() {
        let elementReference = document.querySelector('#txtItemReferenceNo');
        if (elementReference instanceof HTMLElement) {
            elementReference.focus();
        }
    }

    public submit() {
        this.submitted = true;
        let model = this.converter.toModel(this.priceRuleView$.value)
        if (!model.priceRuleName || this.invalidPriceRuleCode || !model.usageTypeCode) {
            return;
        }
        this.onSave.emit(model);
    }

    public getValues(): PriceRuleModel {
        this.submitted = true;
        if (this.errorList?.length) {
            this.displayError();
            return null;
        }
        let model = this.converter.toModel(this.priceRuleView$.value)
        return model;
    }

    onNameChange() {
        this.displayError();
        this.priceRuleNameChange.emit(this.priceRuleView$.value.priceRuleName);
    }

    public onDisplayNameChange() {
        this.priceRuleDisplayNameChange.emit(this.priceRuleView$.value.priceRuleDisplayName);
    }

    onStatusCodeChange(statusCode: any) {
        this.priceRuleView$.value.statusCode = statusCode;
        this.statusCodeChange.emit(this.priceRuleView$.value.statusCode)
    }

    onPriceRuleCategoryCodeChange(priceRuleCategoryCode: any, view: any) {
        this.onValueChange(priceRuleCategoryCode, view, 'priceRuleCategoryCode');
        this.priceRuleGroupReferences$.next(this.priceRuleGroupReferences?.filter(r => r.priceRuleCategoryCode == priceRuleCategoryCode));
        if (this.priceRuleGroupReferences$.value?.length) {
            let defaultPriceRuleGroupCode = this.priceRuleGroupReferences$.value[0].priceRuleGroupCode
            let priceRuleView = this.priceRuleView$.value;
            priceRuleView.priceRuleGroupCode = defaultPriceRuleGroupCode;
            this.priceRuleView$.next(priceRuleView);
            this.resetAllAttributesAndRules(priceRuleView);
            this.onPriceRuleGroupCodeChange(defaultPriceRuleGroupCode)
        }
    }

    onPriceRuleGroupCodeChange(priceRuleGroupCode: any) {
        this.priceRuleView$.value.priceRuleGroupCode = priceRuleGroupCode;
        this.priceRuleTypeGroups$.next(this.priceRuleTypeGroups?.filter(p => p.priceRuleGroupCode == priceRuleGroupCode))
        this.setDefaultPriceRuleTypeCode();
    }

    private setDefaultPriceRuleTypeCode() {
        if (this.priceRuleTypeGroups$.value?.length) {
            let defaultPriceRuleTypeCode = this.priceRuleTypeGroups$.value[0].priceRuleTypeCode;
            let priceRuleView = this.priceRuleView$.value;
            priceRuleView.priceRuleTypeCode = defaultPriceRuleTypeCode;
            this.priceRuleView$.next(Object.assign({}, priceRuleView));
            this.onPriceRuleTypeCodeChange(defaultPriceRuleTypeCode)
        }
    }

    onPriceRuleTypeCodeChange(priceRuleTypeCode: any) {
        this.priceRuleView$.value.priceRuleTypeCode = priceRuleTypeCode;
        this.priceRuleViewChange.emit(this.priceRuleView$.getValue());
    }

    private resetAllAttributesAndRules(priceRuleView: PriceRuleView) {
        priceRuleView.productEligibleData = [];
        priceRuleView.pointOfSalesData = [];
        priceRuleView.roundtripDefinitionData = [];
        priceRuleView.serviceCategoriesSalesBucketsData = [];
        priceRuleView.parentServiceCategoriesSalesBucketsData = [];
        priceRuleView.attributeData = [];
        priceRuleView.customerData = [];
        this.priceRuleViewChange.emit(Object.assign({}, priceRuleView));
    }

    public onPriceRuleCodeChange(priceRuleCode: string) {
        this.displayError();
    }

    private displayError() {
        if (!this.submitted) {
            return;
        }
        if (this.errorList?.length) {
            this.errorList[0]();
        } else {
            this.alertbarService.hide();
        }
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
    }
}
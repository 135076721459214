import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class LocationSetRequireFieldService {

    public clearRequireLocationPoint(ctl) {
        ctl.get('locationPoint').clearValidators();
        ctl.get('locationPoint').updateValueAndValidity();

        ctl.get('locationPointAreas').clearValidators();
        ctl.get('locationPointAreas').updateValueAndValidity();

        ctl.get('locationPointOperator').clearValidators();
        ctl.get('locationPointOperator').updateValueAndValidity();

        ctl.get('locationPointODs').clearValidators();
        ctl.get('locationPointODs').updateValueAndValidity();

        ctl.get('locationPointValueRegion').clearValidators();
        ctl.get('locationPointValueRegion').updateValueAndValidity();

        ctl.get('locationPointValueCountry').clearValidators();
        ctl.get('locationPointValueCountry').updateValueAndValidity();

        ctl.get('locationPointValueAirport').clearValidators();
        ctl.get('locationPointValueAirport').updateValueAndValidity();

        ctl.get('locationPointValueGroup').clearValidators();
        ctl.get('locationPointValueGroup').updateValueAndValidity();
    }

    public setRequireLocationPoint(ctl) {
        ctl.get('locationPoint').setValidators(Validators.required);
        ctl.get('locationPoint').updateValueAndValidity();

        ctl.get('locationPointAreas').setValidators(Validators.required);
        ctl.get('locationPointAreas').updateValueAndValidity();

        ctl.get('locationPointOperator').setValidators(Validators.required);
        ctl.get('locationPointOperator').updateValueAndValidity();

        ctl.get('locationPointODs').setValidators(Validators.required);
        ctl.get('locationPointODs').updateValueAndValidity();


        switch (ctl.get('locationPointAreas').value) {
            case 'region': {
                ctl.get('locationPointValueRegion').setValidators(Validators.required);
                ctl.get('locationPointValueRegion').updateValueAndValidity();
                break;
            }
            case 'country': {
                ctl.get('locationPointValueCountry').setValidators(Validators.required);
                ctl.get('locationPointValueCountry').updateValueAndValidity();
                break;
            }
            case 'airport': {
                ctl.get('locationPointValueAirport').setValidators(Validators.required);
                ctl.get('locationPointValueAirport').updateValueAndValidity();
                break;
            }
            case 'group': {
                ctl.get('locationPointValueGroup').setValidators(Validators.required);
                ctl.get('locationPointValueGroup').updateValueAndValidity();
                break;
            }
        }
    }

    public clearRequireLocation(ctl) {
        ctl.get('locationAreas').clearValidators();
        ctl.get('locationAreas').updateValueAndValidity();

        ctl.get('locationAreasOperators').clearValidators();
        ctl.get('locationAreasOperators').updateValueAndValidity();

        ctl.get('locationAreasValueRegion').clearValidators();
        ctl.get('locationAreasValueRegion').updateValueAndValidity();

        ctl.get('locationAreasValueCountry').clearValidators();
        ctl.get('locationAreasValueCountry').updateValueAndValidity();

        ctl.get('locationAreasValueAirport').clearValidators();
        ctl.get('locationAreasValueAirport').updateValueAndValidity();

        ctl.get('locationAreasValueGroup').clearValidators();
        ctl.get('locationAreasValueGroup').updateValueAndValidity();
    }

    public setRequireLocation(ctl) {
        ctl.get('locationAreas').setValidators(Validators.required);
        ctl.get('locationAreas').updateValueAndValidity();

        ctl.get('locationAreasOperators').setValidators(Validators.required);
        ctl.get('locationAreasOperators').updateValueAndValidity();

        switch (ctl.get('locationAreas').value) {
            case 'region': {
                ctl.get('locationAreasValueRegion').setValidators(Validators.required);
                ctl.get('locationAreasValueRegion').updateValueAndValidity();
                break;
            }
            case 'country': {
                ctl.get('locationAreasValueCountry').setValidators(Validators.required);
                ctl.get('locationAreasValueCountry').updateValueAndValidity();
                break;
            }
            case 'airport': {
                ctl.get('locationAreasValueAirport').setValidators(Validators.required);
                ctl.get('locationAreasValueAirport').updateValueAndValidity();
                break;
            }
            case 'locationgroup': {
                ctl.get('locationAreasValueGroup').setValidators(Validators.required);
                ctl.get('locationAreasValueGroup').updateValueAndValidity();
                break;
            }
        }
    }

}

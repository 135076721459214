import { Injectable } from '@angular/core';

import { DateConverterService } from "src/app/core/utils/date-converter.service";

import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { cloneDeep } from "lodash";

@Injectable()
export class VehicleGroupMapperService {
    private newActionId = 'NEW';
    private searchActionId = 'SEARCH';
    readonly OWN_MENU_FLG = 'OWN_MENU';
    readonly MENU_KEYPAIR_LIST:string[] = [
        'VEHICLECOMPOSITION=Vehicle Composition', 
        'VEHICLECONFIGURATION=Vehicle Configuration', 
        'VEHICLECONFIGURATION=Seat Map,OWN_MENU'
    ]
    private vehicleContextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'New',
            actionId: this.newActionId,
            disable: false
        },
        {
            name: 'Search',
            actionId: this.searchActionId
        },
    ]
    private seatMapContextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'Search',
            actionId: this.searchActionId
        },
    ]
    private contextMenuMap = new Map<string, OopsCategoryContextMenu[]>()
        .set('Seat Map', this.seatMapContextMenu)
    
    constructor(private dateConverterService: DateConverterService) {
    }

    public toOopsCategoryViews(logCommitMap: Map<string, LogCommitModel>, securities: SecurityGroupSecurityModel[]): OopsCategory[] {
        let views: OopsCategory[] = new Array();
        for (let i = 0; i < this.MENU_KEYPAIR_LIST.length; i++) {
            const splitKey = this.MENU_KEYPAIR_LIST[i].split('=')
            const userSec = securities?.find(s => s.securityCode == splitKey[0])
            if (userSec) {
                views.push(this.getOopsCategoryView(splitKey[1], logCommitMap.get(splitKey[0]), userSec));
            }
        }
        
        return views;
    }

    private getOopsCategoryView(moduleName: string, logCommitModel: LogCommitModel, userSecurity: SecurityGroupSecurityModel): OopsCategory {
        var view = {} as OopsCategory
        let ownMenuFlg = false
        if (moduleName.indexOf(',') !== -1) {
            let splitValue = moduleName.split(',')
            view.name = splitValue[0]
            ownMenuFlg = splitValue[1] == this.OWN_MENU_FLG
        }
        else {
            view.name = moduleName
        }
        
        view.modifiedBy = logCommitModel?.commitByName;
        view.modifiedDateTime = this.dateConverterService.convertDateTime24(logCommitModel?.commitDateTime);
        view.security = userSecurity;

        if (ownMenuFlg) {
            view.contextMenus = this.contextMenuMap.get(view.name)
        }
        else {
            view.contextMenus = cloneDeep(this.vehicleContextMenu)
            view.contextMenus[0].disable = !userSecurity.newFlag
        }

        return view;
    }

}
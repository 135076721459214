import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { CommitInformationCode } from "../../models/common/commit-information-code.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CommitInformationService extends ApiServiceBase {
    private readonly PATH = "/schedule/CommitInformations"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getCommits(module: 'INVENTORY' | 'TRANSPORT'): Observable<CommitInformationCode[]> {
        return this.httpGet<CommitInformationCode[]>(this.PATH + "/" + module, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Component, ChangeDetectionStrategy, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaSearchCommandModel, MediaViewModel } from '../../models/media-model';
import { LanguageReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from '../../models/reference-model/reference-media-model';
import { SecurityGroupSecurityModel } from '../../models/security-model/security-group-security.model';
import { MediaComponent } from '../media/media.component';
import { TextComponent } from '../text/text.component';

@Component({
    selector: 'op-text-media',
    templateUrl: './text-media.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class TextMediaComponent {

    private readonly DELAY_TIME = 100;
    @Input() id: string;
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() ENTITY_NAME: string;
    @Input() disabled: boolean = false;
    
    @Output() mediaLibrarySelected = new EventEmitter<MediaViewModel[]>();
    @Output() mediaIdDeleted = new EventEmitter<String>();
    @Output() textLibrarySelected = new EventEmitter<MediaViewModel[]>();

    @ViewChild(MediaComponent) mediaComponent: MediaComponent;
    @ViewChild(TextComponent) textComponent: TextComponent;

    public actionSecurity = new SecurityGroupSecurityModel();

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private focusingService: FocusingService) {
        this.actionSecurity.editFlag = true;
    }

    public onTextLibrarySelected(mediaView: MediaViewModel[]) {
        this.textLibrarySelected.emit(mediaView);
    }

    public onMediaDeleted(mediaId) {
        this.mediaIdDeleted.emit(mediaId);
    }

    public onMediaLibrarySelected(mediaView: MediaViewModel[]) {
        this.mediaLibrarySelected.emit(mediaView);
    }

    public textImageUploadClick() {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.mediaComponent.textImageUploadProcess();
            this.scrollFromTextToMedia();
        }, this.DELAY_TIME);
    }

    public textVideoUploadClick() {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.mediaComponent.textVideoUploadProcess();
            this.scrollFromTextToMedia();
        }, this.DELAY_TIME);
    }

    public textImageSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.mediaComponent.textImageSelectLibraryProcess();
            this.scrollFromTextToMediaLibrary(searchCommand);
        }, this.DELAY_TIME);
    }

    public textVideoSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.mediaComponent.textVideoSelectLibraryProcess();
            this.scrollFromTextToMediaLibrary(searchCommand);
        }, this.DELAY_TIME);
    }

    public cancelTextUpload() {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaUploadCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextImageUpload(value) {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaImageUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextVideoUpload(value) {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaVideoUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public cancelTextSelectLibrary() {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaSelectLibraryCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public cancelCallFromText() {
        this.mediaComponent.cancelCallFromText();
    }
    public saveTextSelectImageLibrary(value) {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaImageSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextSelectVideoLibrary(value) {
        setTimeout(() => {
            this.changeFocusPanelMediaToText();
            this.textComponent.mediaVideoSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    private changeFocusPanelTextToMedia() {
        this.textComponent.minimizeTextPanel();
        this.focusOnMedia();
        this.changeDetectionRef.detectChanges();
    }

    private scrollFromTextToMedia() {
        if (!this.mediaComponent.mediaFiles?.length) {
            this.mediaComponent.scrollFromTextToDetail();
            this.changeDetectionRef.detectChanges();
        }
        else {
            this.mediaComponent.add();
        }
    }

    private scrollFromTextToMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaComponent.addDisplayMediaLibrary(searchCommand);
    }

    private changeFocusPanelMediaToText() {
        this.focusOnText();
    }

    private focusOnMedia() {
        this.focusingService.focus(this.mediaComponent.focusingDirective);
    }

    private focusOnText() {
        this.focusingService.focus(this.textComponent.focusingDirective);
    }
}
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { select2Inherit, select2InheritMultiple } from './restriction-inherit-configuration';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import { RestrictionView } from '../../shared/views/restriction.view';
import {
    AreaModel,
    ODModel,
    LocationPointModel,
    OperatorModel
} from 'src/app/core/models/merchandizing-config';

import {
    ConditionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';

import { ProductTypeDataService } from '../../shared/producttype/producttype-data.service';
import { LocationDataService } from '../../shared/location/location-data.service';

@Component({
    selector: 'op-restriction-inherit',
    templateUrl: './restriction-inherit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestrictionInheritComponent implements OnInit {

    @Input() config: any;

    //Type option
    public typeOption: any = select2Inherit;

    //Product Type
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;

    public operatorProductTypeOption: any = select2Inherit;
    public operatorProductTypes$ = new BehaviorSubject<OperatorModel[]>(null);
    public prouctCatOption: any = select2Inherit;
    public productGroupOption: any = select2Inherit;
    public productGroupTypeOption: any = select2Inherit;

    //Product Code
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    public codeTypeOption: any = select2Inherit;
    public operatorProductCodeOption: any = select2Inherit;
    public operatorProductCodes$ = new BehaviorSubject<OperatorModel[]>(null);
    public providerOption: any = select2Inherit;
    public supplierOption: any = select2Inherit;

    //Route
    public operatorRouteOption: any = select2Inherit;
    public operatorRoutes$ = new BehaviorSubject<OperatorModel[]>(null);
    public originRouteOption: any = select2Inherit;
    public destinationRouteOption: any = select2Inherit;

    //Location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    public locationPointOption: any = select2Inherit;
    public locationPoints$ = new BehaviorSubject<LocationPointModel[]>(null);
    public areaTypeOption: any = select2Inherit;
    public operatorLocationOption: any = select2Inherit;
    public locationTypeOption: any = select2Inherit;
    public locationRegionOption: any = select2InheritMultiple;
    public locationCountryOption: any = select2InheritMultiple;
    public locationAirportOption: any = select2InheritMultiple;
    public locationGroupOption: any = select2InheritMultiple;
    public locationAreasOption: any = select2Inherit;
    public locationAreasOperatorOption: any = select2Inherit;


    public inheritForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private productTypeDataService: ProductTypeDataService,
        private locationDataService: LocationDataService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    ngOnInit(): void {
        this.getOperatorProductCodeData();
        this.getOperatorProductTypeData();
        this.getOperatorRouteData();
        this.getLocationData();
    }

    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    public addRestrictionInheritToFormGroup(productRestrictions: RestrictionView[]) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });

        let no = 0;
        var locationRestriction = productRestrictions.filter(x => x.type == "location");

        for (let productRestriction of productRestrictions) {
            if (productRestriction.type == "location") {
                if (productRestriction.no != no) {
                    var locationSameLine = locationRestriction.filter(x => x.no == productRestriction.no);
                    var locationArrayValue: string[] = new Array<string>();
                    var locationArrayDBId: Select2Data[] = new Array<Select2Data>();
                    var locationAirportSelect2Datas: Select2Data[] = new Array<Select2Data>();

                    for (let location of locationSameLine) {
                        if (location.productRestrictionLocationView.regionCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.regionCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.regionCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.countryCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.countryCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.countryCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationId);

                            let airportData = new Select2Data();
                            airportData.id = location.productRestrictionLocationView.locationId;
                            airportData.text = location.productRestrictionLocationView.locationName;
                            locationAirportSelect2Datas.push(airportData);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationId;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationGroupId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationGroupId);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationGroupId;
                            locationArrayDBId.push(dbIdData);
                        }
                    }
                    (<UntypedFormArray>this.inheritForm.get('inheritforms')).push(this.createFormGroupProductRestriction(productRestriction, locationArrayValue, locationArrayDBId));
                    let formCurrent = this.inheritforms.controls[this.inheritforms.controls.length - 1];
                    this.locationDataService.fillLocationPointAreaData(formCurrent, this.locationPoints$);
                    this.locationDataService.filllocationPointOperatorData(formCurrent, this.locationPoints$);
                    this.locationDataService.fillLocationAirportData(formCurrent, productRestriction, locationAirportSelect2Datas);
                    no = productRestriction.no;
                }
            }
            else {
                (<UntypedFormArray>this.inheritForm.get('inheritforms')).push(this.createFormGroupProductRestriction(productRestriction));
                let formCurrent = this.inheritforms.controls[this.inheritforms.controls.length - 1];
                this.fillProductTypeData(formCurrent, productRestriction);
                this.locationDataService.fillOriginRouteData(formCurrent, productRestriction);
                this.locationDataService.fillDestinationRouteData(formCurrent, productRestriction);
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    private createFormGroupProductRestriction(productRestriction: RestrictionView,
        locationValueArray: string[] = null,
        locationArrayDBId: Select2Data[] = null) {
        let group = {
            'type': [{ value: productRestriction.type, disabled: true }, [Validators.required]],
            'submitted': [true, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [false, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]]
        };

        switch (productRestriction.type) {
            case 'productcode': {
                let restrictionProductNumber = productRestriction.productRestrictionProductNumberView;
                group['productCodeShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionProductNumberId'] = [restrictionProductNumber.productRestrictionProductNumberId, [Validators.nullValidator]];
                group['productCodeType'] = [{ value: restrictionProductNumber.productNumberTypeCode, disabled: true }, [Validators.nullValidator]];
                group['operatorProductCode'] = [{ value: (restrictionProductNumber.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['provider'] = [{ value: restrictionProductNumber.providerId, disabled: true }, [Validators.nullValidator]];
                group['productCodeValue'] = [{ value: restrictionProductNumber.productNumber, disabled: true }, [Validators.nullValidator]];
                group['supplier'] = [{ value: restrictionProductNumber.supplierId, disabled: true }, [Validators.nullValidator]];
                break;
            }
            case 'producttype': {
                let restrictionProduct = productRestriction.productRestrictionProductView;
                group['productTypeShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionProductId'] = [{ value: restrictionProduct.productRestrictionProductId, disabled: true }, [Validators.nullValidator]];
                group['operatorProductType'] = [{ value: (restrictionProduct.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['productCat'] = [{ value: restrictionProduct.productCategoryCode, disabled: true }, [Validators.nullValidator]];
                group['productGroup'] = [{ value: restrictionProduct.productGroupCode, disabled: true }, [Validators.nullValidator]];
                group['productGroupData'] = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
                group['productGroupType'] = [{ value: restrictionProduct.productTypeCode, disabled: true }, [Validators.nullValidator]];
                group['productGroupTypeData'] = new BehaviorSubject<ProductTypeGroupModel[]>(null);
                break;
            }
            case 'route': {
                let restrictionRoute = productRestriction.productRestrictionRouteView;
                group['routeShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionRouteId'] = [{ value: restrictionRoute.productRestrictionRouteId, disabled: true }, [Validators.nullValidator]];
                group['operatorRoute'] = [{ value: (restrictionRoute.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['originRoute'] = [{ value: restrictionRoute.originLocationId, disabled: true }, [Validators.nullValidator]];
                group['originRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                group['destinationRoute'] = [{ value: restrictionRoute.destinationLocationId, disabled: true }, [Validators.nullValidator]];
                group['destinationRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                break;
            }
            case 'location': {
                let restrictionLocation = productRestriction.productRestrictionLocationView;
                group['locationShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionLocationId'] = [locationArrayDBId, [Validators.nullValidator]];
                group['locationPoint'] = [{ value: restrictionLocation.productLocationTypeCode, disabled: true }, [Validators.nullValidator]];
                let locationPointAreas = '';
                if (restrictionLocation.regionCode) {
                    locationPointAreas = 'region';
                    group['locationPointValueRegion'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationPointValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.countryCode) {
                    locationPointAreas = 'country';
                    group['locationPointValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueCountry'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationPointValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.locationId) {
                    locationPointAreas = 'airport';
                    group['locationPointValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirport'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationPointValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.locationGroupId) {
                    locationPointAreas = 'group';
                    group['locationPointValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationPointValueGroup'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                }
                group['locationPointAreas'] = [{ value: locationPointAreas, disabled: true }, [Validators.nullValidator]];
                group['locationPointAreasData'] = new BehaviorSubject<AreaModel[]>(null);

                group['locationPointOperator'] = [{ value: (restrictionLocation.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['locationPointOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);

                group['locationPointODs'] = [{ value: restrictionLocation.productLocationPointCode, disabled: true }, [Validators.nullValidator]];
                group['locationPointODsData'] = new BehaviorSubject<ODModel[]>(null);
                break;
            }
        }

        let f = this.fb.group(group);
        return f;
    }

    private fillProductTypeData(f, productRestriction: RestrictionView) {
        if (productRestriction.type == "producttype") {
            this.productTypeDataService.getProductGroupData(f, this.productGroupReferences$);
            this.productTypeDataService.getProductTypeGroupData(f, this.productTypeGroups$);
        }
    }

    private getOperatorProductCodeData() {
        var filterProductCode = this.config.types.filter(x => x.typeCode == 'productcode');
        if (filterProductCode.length != 0) {
            this.operatorProductCodes$.next(filterProductCode[0].operators);
        }
    }

    private getOperatorProductTypeData() {
        var filterProductType = this.config.types.filter(x => x.typeCode == 'producttype');
        if (filterProductType.length != 0) {
            this.operatorProductTypes$.next(filterProductType[0].operators);
        }
    }

    private getOperatorRouteData() {
        var filterRoute = this.config.types.filter(x => x.typeCode == 'route');
        if (filterRoute.length != 0) {
            this.operatorRoutes$.next(filterRoute[0].operators);
        }
    }

    private getLocationData() {
        //Map DB With JSON
        let values = new Array();
        if (this.productLocationTypeReferences$.value) {
            var filterLocation = this.config.types.filter(x => x.typeCode == 'location'); //find in JSON
            if (filterLocation.length != 0) {
                var locationPoints = filterLocation[0].locationPoint;

                for (let locationPoint of locationPoints) {
                    var filterProductLocationType = this.productLocationTypeReferences$.value.filter(locationPoint.locationPointCode = x => x.productLocationTypeCode); // find in DB
                    if (filterProductLocationType.length != 0) {
                        let value = <LocationPointModel>{};
                        value.locationPointCode = filterProductLocationType[0].productLocationTypeCode;
                        value.locationPointName = filterProductLocationType[0].productLocationTypeName;
                        value.areas = locationPoint.areas;
                        value.operators = locationPoint.operators;
                        value.ods = locationPoint.ods;
                        values.push(value);
                    }
                }
            }
        }
        if (values) {
            this.locationPoints$.next(values);
        }
    }

}
import { MediaContentViewModel } from './media-content';
import { MediaFileAssociationViewModel } from './media-fileassociation';
import { MediaMediaHashTagViewModel } from './media-media-hashtag';
import { MediaUseViewModel } from './media-use';

export class MediaViewModel {
    public mediaId: string;
    public organisationId: string;
    public mediaTypeCode: string;
    public mediaTypeName: string;
    public mediaName: string;
    public validFromDateTime: Date;
    public validToDateTime: Date;
    public mediaContents: MediaContentViewModel[];
    public mediaFileAssociations: MediaFileAssociationViewModel[];
    public mediaMediaHashTags: MediaMediaHashTagViewModel[];
    public mediaUses: MediaUseViewModel[];
}
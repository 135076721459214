import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { IndividualInterestTypeReferenceModel } from "../../models/reference-model/reference-general-model";

@Injectable({
    providedIn: 'root'
})
export class IndividualInterestTypeReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = '/system/individualInterestTypeReferences';
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getIndividualInterestTypeReferences(): Observable<IndividualInterestTypeReferenceModel[]> {
        return this.httpGet<IndividualInterestTypeReferenceModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
import { Injectable } from '@angular/core';
import { AlertBarMainNotifier } from './alertbar-main-notifier';

@Injectable({
    providedIn: 'root'
})
export class AlertBarMainService {
    public alertBarNotifier = new AlertBarMainNotifier();

    public showError(panelName: string, errorMessage: string): void {
        this.alertBarNotifier.showError(panelName, errorMessage);
    }

    public hideError(): void {
        this.alertBarNotifier.hideError();
    }

    public showWarning(warningMessage: string): void {
        this.alertBarNotifier.showWarning(warningMessage);
    }

    public hideWarning(): void {
        this.alertBarNotifier.hideWarning();
    }
    public showInformation(panelName: string, informationMessage: string): void {
        this.alertBarNotifier.showInformation(panelName, informationMessage);
    }

    public hideInformation(): void {
        this.alertBarNotifier.hideInformation();
    }

    public showSuccess(successMessage: string): void {
        this.alertBarNotifier.showSuccess(successMessage);
    }

    public hideSuccess(): void {
        this.alertBarNotifier.hideSuccess();
    }
}
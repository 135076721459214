export const selectProvider = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectDeparture = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    focus: true,
}

export const selectArrival = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    focus: true,
}

export const selectSchedulePeriod = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectScheduleVersion = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectVehicleType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectVehicleComposition = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectAction = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectProgress = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const selectResult = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

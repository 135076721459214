import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { FormOfPaymentReferenceModel } from "../../models/form-of-payment-model";

@Injectable({
    providedIn: 'root'
})
export class FormOfPaymentReferenceService extends ApiServiceBase {
    private readonly PATH = '/system/FormOfPaymentReferences';
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }
    public getBy(formOfPaymentTypeCode: string): Observable<FormOfPaymentReferenceModel[]> {
        let params = new HttpParams()
            .set('formOfPaymentTypeCode', formOfPaymentTypeCode ?? '')
        return this.httpGet<FormOfPaymentReferenceModel[]>(this.PATH, params)
            .pipe(
                map(res => res.body)
            )
    }
    public getSalesFOPByOrganisation(): Observable<FormOfPaymentReferenceModel[]> {
        return this.httpGet<FormOfPaymentReferenceModel[]>(this.PATH + '/organisation/sales', null)
            .pipe(
                map(res => res.body)
            )
    }
    public getTopUpFOPByOrganisation(): Observable<FormOfPaymentReferenceModel[]> {
        return this.httpGet<FormOfPaymentReferenceModel[]>(this.PATH + '/organisation/topup', null)
            .pipe(
                map(res => res.body)
            )
    }
}
import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { TextView } from './shared/text.view';
import { TextDetailComponent } from './text-detail/text-detail.component';
import { TextDetailDirective } from './text-detail/text-detail.directive';
import { TextTableComponent } from './text-table/text-table.component';
import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaService } from '../../services/media-services';
import { MediaSearchCommandModel, MediaViewModel } from '../../models/media-model';
import { TextMapperService } from './shared/text-mapper.service';
import { TextLibraryTableComponent } from './text-library-table/text-library-table.component';
import { TextLibraryTableDirective } from './text-library-table/text-library-table.directive';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { TextSessionData } from './shared/text-session.data';

declare var $: any;

@Component({
    selector: 'op-text',
    templateUrl: './text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TextMapperService]
})
export class TextComponent implements OnInit, OnChanges {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() classIcon: string;
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() ENTITY_NAME: string;
    @Input() disabled: boolean = false;

    @Output() textLibrarySelected = new EventEmitter<MediaViewModel[]>();
    @Output() mediaIdDeleted = new EventEmitter<String>();
    @Output() textImageUploadClick = new EventEmitter();
    @Output() textVideoUploadClick = new EventEmitter();
    @Output() textImageSelectLibraryClick = new EventEmitter<MediaSearchCommandModel>();
    @Output() textVideoSelectLibraryClick = new EventEmitter<MediaSearchCommandModel>();
    @Output() cancelCallFromText = new EventEmitter();

    @ViewChild("codeDetailRef") detailElement: ElementRef;
    @ViewChild("codeRef") codeElement: ElementRef;
    @ViewChild("textDetailLibraryTableRef") textLibraryElement: ElementRef;

    @ViewChild(TextDetailDirective) textDetailDirective: TextDetailDirective;
    @ViewChild(TextTableComponent) textTable: TextTableComponent;
    @ViewChild(TextDetailComponent) textDetailComponent: TextDetailComponent;
    @ViewChild(TextLibraryTableDirective) textLibraryTableDirective: TextLibraryTableDirective;
    @ViewChild(TextLibraryTableComponent) textLibraryTableComponent: TextLibraryTableComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    readonly ADD_BINDING_DELAY_TIME = 50;
    readonly SCROLL_OFFSET_DETAIL_SINGLE = -840;
    readonly SCROLL_OFFSET_DETAIL = -310;
    readonly SCROLL_OFFSET_SEARCH = -350;
    readonly SCROLL_OFFSET_LIBRARY = -940;
    readonly SCROLL_OFFSET_LIBRARY_DETAIL = -400;
    public singleRecord: boolean = true;
    public miniMizePanel: boolean = false;
    public miniMizeDetailPanel: boolean = false;
    public isCollapsed2: boolean = false;
    public showAdd: boolean = false;
    public model = new TextView();
    public mediaTexts: TextView[] = new Array();
    public showBrowse: boolean = true;
    public mediaProcess: boolean = false;
    private mediaSelectFromLibrary: boolean = false;
    public isCollapsedMediaLibrary: boolean = false;
    public isTextEmpty: boolean = true;
    public isMoreThanOneText: boolean = true;
    private rowSelected: TextView;

    private isSessionMode = false;
    private sessionDatas = new Array<TextSessionData>();

    constructor(private changeDectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private uiHelper: UiHelperService,
        private mediaService: MediaService,
        private textMapperService: TextMapperService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['id']) {
            this.saveSession(changes['id'].previousValue);
        }
    }

    private saveSession(previousId: string) {
        if (this.isSessionMode) {
            let session = this.sessionDatas.find(sd => sd.id == previousId);
            if (session) {
                session.mediaTexts = cloneDeep(this.mediaTexts);
            } else {
                this.sessionDatas.push({
                    id: previousId,
                    mediaTexts: cloneDeep(this.mediaTexts)
                });
            }
            if (this.isCollapsed2) {
                this.cancel();
            }
        }
    }

    add() {
        this.toggleDetail();
        this.singleRecord = false;
        setTimeout(() => {
            this.loadDetailForm();
            this.changeDectionRef.detectChanges();
        }, this.ADD_BINDING_DELAY_TIME);
    }

    edit() {
        if (this.rowSelected) {
            this.toggleDetail();
            this.loadDetailForm(this.rowSelected);
        }
    }

    save(data: TextView) {
        if (data) {
            this.savedata(data);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }


    delete(data: TextView) {
        this.deletedata(data);
        this.displayAfterDelete();
        if (data.mediaId) {
            this.deleteTextTranslation(data.mediaId);
        }
        else {
            this.deleteTextTranslation(data.mediaTranslationId);
        }
    }

    cancel() {
        if (!this.isTextEmpty){
            this.toggleDetail();
        }
        this.displayAfterCancel();
    }

    public toggleDetail() {
        this.isCollapsed2 = !this.isCollapsed2
        this.changeDectionRef.markForCheck();
        if (this.isCollapsed2) {
            this.scrollToDetail();
        } else {
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {  
        this.scrollTo(this.codeElement, this.SCROLL_OFFSET_SEARCH);
    }

    public scrollToDetail() {
        let yOffset = 0; 
        if (this.singleRecord) {
            yOffset = this.SCROLL_OFFSET_DETAIL_SINGLE;
        } else {
            yOffset = this.SCROLL_OFFSET_DETAIL;
        }
        this.scrollTo(this.detailElement, yOffset);
    }

    public scrollToTextLibrary() {
        this.scrollTo(this.textLibraryElement, this.SCROLL_OFFSET_LIBRARY);
    }

    public scrollFromLibraryToDetail() {  
        this.scrollTo(this.detailElement, this.SCROLL_OFFSET_LIBRARY_DETAIL);
    }

    private loadDetailForm(dataEdit: TextView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(TextDetailComponent);

        let viewContainerRef = this.textDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: TextDetailComponent = (<TextDetailComponent>componentRefs.instance);
        component.languageReference$ = this.languageReference$;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.mediaUseReference$ = this.mediaUseReference$;
        component.mediaHashTagSelect2Data = this.mediaHashTagSelect2Data;
        component.showAdd = false;
        component.singleRecord = false;
        if (dataEdit != null) {
            component.model = cloneDeep(dataEdit);
            component.showBrowse = false;
        }
        else {
            component.showBrowse = true;
        }
        component.onSave.subscribe(
            (dataSave: TextView) => {
                this.save(dataSave);
            }
        );
        component.onCancel.subscribe(
            response => {
                this.displayAfterCancel();
            }
        );
        component.onSaveMediaLibrary.subscribe(
            (dataSave: TextView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
        component.notCopyTextTranslation.subscribe(
            (mediaId: string) => {
                this.deleteTextTranslation(mediaId);
            }
        );
        this.onClickMediaLibraryEvent(component);
        this.onClickImageUploadEvent(component);
        this.onClickVideoUploadEvent(component);
        this.onClickImageSelectLibraryEvent(component);
        this.onClickVideoSelectLibraryEvent(component);

        this.textDetailComponent = component;
    }

    private savedata(data: TextView) {
        let cloneData = cloneDeep(data);
        if (this.mediaTexts != null) {
            var filterIndex = this.mediaTexts.findIndex(x => x.no == cloneData.no);
            if (filterIndex == -1) {
                cloneData.no = this.mediaTexts.length + 1;
                this.mediaTexts.push(cloneData);
                this.updateModelInCondition();
            }
            else {
                this.mediaTexts[filterIndex] = cloneData;
            }
        }
        else {
            cloneData.no = 1;
            this.mediaTexts.push(cloneData);
        }
        this.textTable.dataGrid.instance.refresh();
    }

    private deletedata(data: TextView) {
        let views = new Array<TextView>();
        if (this.mediaTexts != null) {
            views = this.mediaTexts;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.mediaTexts = views;
        }
    }

    private displayAfterSave() {
        if (this.mediaTexts != null) {
            if (this.mediaTexts.length > 1) {
                this.updateMultiRecord();
                this.formDetailClose();
                this.toggleDetail();
            }
            else {
                this.updateSingleRecord();
            }
        }
        else {
            this.updateNoRecord();
        }
    }

    private displayAfterDelete() {
        if (this.mediaTexts.length == 0 || this.mediaTexts.length == 1) {
            if (this.mediaTexts.length == 1) {
                this.model = this.mediaTexts[0];
                this.showBrowse = false;
                this.showAdd = true;
                this.isTextEmpty = false;
            }
            else {
                this.model = new TextView();
                this.showAdd = false;
                this.showBrowse = true;
                this.isTextEmpty = true;
            }
            this.singleRecord = true;
            this.isMoreThanOneText = false;
        }

        if (this.isCollapsed2) {
            this.toggleDetail();
        }
    }

    private displayAfterCancel() {
        if (this.mediaTexts.length == 0 || this.mediaTexts.length == 1) {
            if (this.mediaTexts.length == 1) {
                this.model = this.mediaTexts[0];
                this.showBrowse = false;
                this.showAdd = true;
                this.isTextEmpty = false;
            }
            else {
                this.model = new TextView();
                this.showAdd = false;
                this.showBrowse = true;
                this.isTextEmpty = true;
            }
            this.isMoreThanOneText = false;
            this.singleRecord = true;  
        } else {
          this.formDetailClose();
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.textDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public fillModelToForm(mediaTextViews: TextView[]) {
        this.clearData();
        mediaTextViews = this.getSessionValue(mediaTextViews);
        if (mediaTextViews && mediaTextViews.length > 0) {
            this.fillModelInCase(mediaTextViews);
        }
        else {
            this.clearDetailForm();
        }
        this.mediaTexts = mediaTextViews;
        this.updateAllDisplayStatus();
        this.changeDectionRef.detectChanges();
    }

    private getSessionValue(mediaTextViews: TextView[]) {
        if (this.isSessionMode) {
            let session = this.sessionDatas.find(sd => sd.id == this.id);
            if (session) {
                mediaTextViews = cloneDeep(session.mediaTexts);
            }
        }
        return mediaTextViews;
    }

    public saveMediaLibrary(textViews: TextView[]) {
        if (textViews) {
            var mediaIds: string[] = new Array();
            for (let data of textViews) {
                if (!this.exists(data)) {
                    this.savedata(data);
                    mediaIds.push(data.mediaId);
                }
            }
            this.showAdd = true;
            this.displayAfterSaveMediaLibrary();
            this.displayTextTranslationFromMediaLibrary(mediaIds);
            this.minimixeMediaLibraryPanel();
        }
    }

    private onClickMediaLibraryEvent(component: TextDetailComponent) {
        component.onClickMediaLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibrary(searchCommand);
            }
        );
    }

    private onClickImageUploadEvent(component: TextDetailComponent) {
        component.onClickImageUpload.subscribe(
            () => {
                this.clickImageUpload();
            }
        );
    }

    private onClickVideoUploadEvent(component: TextDetailComponent) {
        component.onClickVideoUpload.subscribe(
            () => {
                this.clickVideoUpload();
            }
        );
    }

    private onClickImageSelectLibraryEvent(component: TextDetailComponent) {
        component.onClickImageSelectLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickImageSelectLibrary(searchCommand);
            }
        );
    }

    private onClickVideoSelectLibraryEvent(component: TextDetailComponent) {
        component.onClickVideoSelectLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickVideoSelectLibrary(searchCommand);
            }
        );
    }

    private fillModelInCase(mediaTextViews: TextView[]) {
        if (mediaTextViews.length == 1) {
            this.fillModelSingleRecord(mediaTextViews[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(mediaTextViews: TextView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.showBrowse = false;
        this.model = mediaTextViews;
        this.clearDetailForm();
    }

    private clearData() {
        this.singleRecord = true;
        this.showAdd = false;
        this.model = new TextView();
        this.textMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.showBrowse = true;
    }

    public clearDetailForm() {
        if (this.textDetailComponent?.textForm) {
            this.textDetailComponent.textForm.resetForm(this.model);
        }
    }

    private displayAfterSaveMediaLibrary() {
        if (this.mediaTexts != null) {
            this.displayAfterSaveInCase();
        }
        else {
            this.displayAfterSaveNoData();
        }
    }

    private displayAfterSaveInCase() {
        if (this.mediaTexts.length > 1) {
            this.displayAfterSaveInList();
        }
        else {
            this.displayAfterSaveSingleRecord();
        }
    }
    private displayAfterSaveInList() {
        this.singleRecord = false;
        this.isTextEmpty = false;
        this.showBrowse = false;
        this.isMoreThanOneText = true;
        this.formDetailClose();
        if (this.isCollapsed2) {
            this.toggleDetail();
        }
        else {
            this.scrollToSearch();
        }
    }

    private displayAfterSaveSingleRecord() {
        this.model = this.mediaTexts[0];
        this.singleRecord = true;
        this.isTextEmpty = false;
        this.showBrowse = false;
        this.isMoreThanOneText = false;
        this.scrollFromLibraryToDetail();
    }

    private displayAfterSaveNoData() {
        this.singleRecord = true;
        this.isTextEmpty = true;
        this.showBrowse = true;
    }

    private displayTextTranslationFromMediaLibrary(mediaIds: string[]) {
        if (mediaIds) {
            this.mediaService.getByIds(mediaIds)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        this.textLibrarySelected.emit(responses);
                    }
                )
        }
    }

    public deleteTextTranslation(mediaId: string) {
        if (mediaId) {
            this.mediaIdDeleted.emit(mediaId);
        }
    }

    private exists(textView: TextView): boolean {
        var filter = this.mediaTexts.filter(x => x.mediaId == textView.mediaId);
        if (filter.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public clickMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.displayTextLibrary().then(() => {
            this.loadTextLibraryTableForm(searchCommand).then(() => {
                this.scrollToTextLibrary();
            });
        });
    }

    public displayTextLibrary(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.mediaTexts?.length) {
                this.miniMizePanel = true;
            }
            else {
                this.miniMizeDetailPanel = true;
            }
            this.isCollapsedMediaLibrary = true;
            if (this.textDetailComponent) {
                this.textDetailComponent.browseLibrary = false;
            }
            resolve();
        });
    }

    public clickImageUpload() {
        this.mediaSelectFromLibrary = false;
        this.textImageUploadClick.emit();
    }

    public clickImageSelectLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaSelectFromLibrary = true;
        this.textImageSelectLibraryClick.emit(searchCommand);
    }

    public clickVideoUpload() {
        this.mediaSelectFromLibrary = false;
        this.textVideoUploadClick.emit();
    }

    public clickVideoSelectLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaSelectFromLibrary = true;
        this.textVideoSelectLibraryClick.emit(searchCommand);
    }

    public clearForm() {
        this.singleRecord = true;
        this.isCollapsed2 = false;
        this.showAdd = false;
        this.model = new TextView();
        this.mediaTexts = [];
        this.showBrowse = true;
        this.mediaSelectFromLibrary = false;
        this.isTextEmpty = true;
        this.changeDectionRef.detectChanges();
    }

    public mediaUploadCancel() {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaUploadCancel();
        this.moveToPanelAfterMediaProcess();
    }

    public mediaImageUploadCompleted(mediaContentId: string) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaImageUploadCompleted(mediaContentId);
        this.moveToPanelAfterMediaProcess();
    }

    public mediaVideoUploadCompleted(mediaContentId: string) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaVideoUploadCompleted(mediaContentId);
        this.moveToPanelAfterMediaProcess();
    }

    public mediaSelectLibraryCancel() {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaUploadCancel();
        this.moveToPanelAfterMediaProcess();
    }

    public mediaImageSelectLibraryCompleted(mediaContentIds: string[]) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaImageSelectLibraryCompleted(mediaContentIds);
        this.moveToPanelAfterMediaProcess();
    }

    public mediaVideoSelectLibraryCompleted(mediaContentIds: string[]) {
        this.mediaProcess = false;
        this.mediaSelectFromLibrary = false;
        this.textDetailComponent.mediaVideoSelectLibraryCompleted(mediaContentIds);
        this.moveToPanelAfterMediaProcess();
    }

    private moveToPanelAfterMediaProcess() {
        if (!this.singleRecord) {
            this.uiHelper.moveScrollPosition(this.detailElement, -160);
        }
    }

    public minimizeTextPanel() {
        this.mediaProcess = true;
    }

    public checkCollapsedStatus(collapsed) {
        if (!collapsed) {
            if (this.mediaProcess || this.mediaSelectFromLibrary) {
                this.cancelCallFromText.emit();
                this.mediaUploadCancel();
                this.mediaSelectLibraryCancel();
            }
        }
    }

    private loadTextLibraryTableForm(searchCommand: MediaSearchCommandModel): Promise<void> {
        return new Promise((resolve, reject) => {
            let componentFactory = this.componentFactoryResolver
                .resolveComponentFactory(TextLibraryTableComponent);

            let viewContainerRef = this.textLibraryTableDirective.viewContainerRef;
            viewContainerRef.clear();

            let componentRefs = viewContainerRef.createComponent(componentFactory);
            let component: TextLibraryTableComponent = (<TextLibraryTableComponent>componentRefs.instance);
            this.assignInitialDataMediaLibraryTableForm(component, searchCommand);
            this.onSaveMediaLibraryEvent(component);
            this.onCancelBrowseClickEvent(component);
            this.textLibraryTableComponent = component;
            resolve();
        });
    }

    private assignInitialDataMediaLibraryTableForm(component: TextLibraryTableComponent, searchCommand: MediaSearchCommandModel) {
        component.mediaSearchCommand = searchCommand;
        component.mediaTypeReference$ = this.mediaTypeReference$;
    }

    private onSaveMediaLibraryEvent(component: TextLibraryTableComponent) {
        component.onSaveMediaLibrary.subscribe(
            (dataSave: TextView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
    }

    private onCancelBrowseClickEvent(component: TextLibraryTableComponent) {
        component.onCancelBrowseClick.subscribe(
            () => {
                this.cancelBrowse();
                this.formMediaLibraryTableClose();
            }
        );
    }

    public cancelBrowse() {
        this.minimixeMediaLibraryPanel();
    }

    private minimixeMediaLibraryPanel() {
        this.isCollapsedMediaLibrary = false;
        this.miniMizePanel = false;
        this.miniMizeDetailPanel = false;
        if (this.textDetailComponent) {
            this.textDetailComponent.browseLibrary = false;
        }
    }

    private formMediaLibraryTableClose() {
        let viewContainerRef = this.textLibraryTableDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public onBrowse() {
        this.textDetailComponent.browseLibraryClick();
    }

    public saveTextBrowse() {
        this.textLibraryTableComponent.saveMediaBrowse();
    }

    public copy() {
        if (this.mediaTexts.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.mediaTexts.length == 1) {
            let copyMediaship = cloneDeep(this.mediaTexts[0]);
            this.openCopyDetailForm(copyMediaship);
            return;
        }

         if (!this.textTable.selected) {
            return;
        }

        let copyMediaship = cloneDeep(this.textTable.selected);
        this.openCopyDetailForm(copyMediaship);
    }

    private setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneText = true;
    }

    private openCopyDetailForm(copyMedia: TextView) {
        this.setCopyMediashipProperty(copyMedia);
        this.toggleDetail();
        this.loadDetailForm(copyMedia);
    }

    private setCopyMediashipProperty(copyMedia: TextView) {
        copyMedia.no = null;
    }
    
    public onDelete() {
        if (this.mediaTexts.length == 1) {
            this.delete(this.model);
            return;
        }
        this.textTable.delete();
    }

    public onSaveNewText() {
        let newText = this.textDetailComponent.textForm;
        this.textDetailComponent.save(newText);
    }

    public onRowSelected(data: TextView) {
        this.rowSelected = data;
    }

    private updateAllDisplayStatus() {
        if (this.mediaTexts?.length) {
            if (this.mediaTexts.length > 1) {
                this.updateMultiRecord();
            } else {
                this.updateSingleRecord();
            }
        } else {
            this.updateNoRecord();
        }
    }

    private updateNoRecord() {
        this.singleRecord = true;
        this.isTextEmpty = true;
        this.showBrowse = true;
        this.isMoreThanOneText = false;
    }

    private updateSingleRecord() {
        this.singleRecord = true;
        this.isTextEmpty = false;
        this.showBrowse = false;
        this.isMoreThanOneText = false;
    }

    private updateMultiRecord() {
        this.singleRecord = false;
        this.isTextEmpty = false;
        this.isMoreThanOneText = true;
    }

    private scrollTo(element: ElementRef, offsetNumber: number) {
        const y = element.nativeElement.getBoundingClientRect().top + window.scrollY + offsetNumber;
        window.scrollTo({top: y, behavior: 'smooth'}); 
    }

    public refresh() {
        if (this.id) {
            this.mediaService.getByEntityNameAndAssociation(this.ENTITY_NAME, this.id)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        var mediaViews = this.textMapperService.mediaToTextViews(responses);
                        this.fillModelToForm(mediaViews);
                    }
                )
        } else {
            this.clearForm();
        }
    }    

    public activateSessionMode(enabled: boolean) {
        this.isSessionMode = enabled;
    }

    public clearSessionData() {
        this.sessionDatas = [];
    }

    public getSessionData() {
        return this.sessionDatas;
    }

    private updateModelInCondition() {
        if (this.mediaTexts.length == 1) {
            this.model = this.mediaTexts[0];
        }
    }
}
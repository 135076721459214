import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { ProductScheduleModel } from "src/app/core/models/schedule-period-model";
import { SchedulePeriodService } from "src/app/core/services/schedule-services/schedule-period.service";
import { ScheduleProductMapperService } from "./schedule-product.mapper.service";
import { DxDataGridComponent } from "devextreme-angular";
import { TransportService } from "src/app/core/services/product-services";
import { RouteViewModel, TransportViewModel } from "src/app/core/models/product-model/transport-model";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { forkJoin } from "rxjs";
import { ValidationView } from "src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/validation/validation.view";
import { RouteMapperService } from "src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/route/route-mapper.service";
import { ServiceCategoryMapperService } from "src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/servicecategory/servicecategory-mapper.service";
import { SalesBucketMapperService } from "src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/salesbucket/salesbucket-mapper.service";
import { ValidationMapperService } from "src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/validation/validation.mapper.service";
import { StateConstant } from "src/app/shared/ui/forms/inputs/state-color-dropdown/shared/state.constant";
import { OperatingProductService } from "src/app/core/services/schedule-services";
import { OperatingProductVerificationModel } from "src/app/core/models/product-model/operating-product-model";
import { NavigationService } from "src/app/shared/utils/navigation";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-schedule-product-table',
    templateUrl: './schedule-product-table.component.html',
    providers: [ScheduleProductMapperService,
        RouteMapperService,
        ServiceCategoryMapperService,
        SalesBucketMapperService,
        ValidationMapperService]
})
export class ScheduleProductTableComponent implements OnInit {

    @Input() productScheduleVersionId: string;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @ViewChild('dataGridScheduleProduct', { static: false }) dataGrid: DxDataGridComponent;
    public scheduleProductData: any;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private schedulePeriodService: SchedulePeriodService,
        private scheduleProductMapperService: ScheduleProductMapperService,
        private transportService: TransportService,
        private operatingProductService: OperatingProductService,
        private routeMapperService: RouteMapperService,
        private serviceCategoryMapperService: ServiceCategoryMapperService,
        private salesBucketMapperService: SalesBucketMapperService,
        private validationMapperService: ValidationMapperService,
        private loadingSpinnerService: LoadingSpinnerService,
        private navigationService: NavigationService
    ) { }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        if (this.productScheduleVersionId) {
            this.schedulePeriodService.getProductSchedule(this.productScheduleVersionId)
                .subscribe(
                    (responses: ProductScheduleModel[]) => {
                        this.bindData(responses);
                    });
        }
    }

    private bindData(productSchedules: ProductScheduleModel[]) {
        this.scheduleProductData = this.scheduleProductMapperService.toViews(productSchedules);
        this.changeDetectorRef.detectChanges();
    }

    public isDisabled(draftFlag: boolean, finalFlag: boolean, state: string): boolean {
        if (state == StateConstant.NONE) {
            return !this.userSecurity.editFlag
        } else if (finalFlag || draftFlag) {
            return true;
        }
        return false;
    }

    public updateState(state, cell) {
        this.getProduct(state, cell);
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        this.removeExpand(e);
    }

    private removeExpand(e) {
        if (e.rowType === "data" && e.column.command === "expand") {
            e.cellElement.removeClass("dx-datagrid-expand");
            e.cellElement.empty();
        }
    }

    private getProduct(state, cell) {
        if (cell?.data?.productId) {
            this.loadingSpinnerService.show();
            forkJoin({
                productTransportViewModel: this.transportService.getByProductId(cell.data.productId),
                routeViewModels: this.transportService.getRoute(cell.data.productId),
                verifyViewModels: this.operatingProductService.getVerifyResult(cell.data.productId)
            })
                .subscribe(({
                    productTransportViewModel,
                    routeViewModels,
                    verifyViewModels
                }) => {
                    this.loadingSpinnerService.hide();
                    if (this.validation(cell, productTransportViewModel, routeViewModels, verifyViewModels)) {
                        this.generateOperatingProduct(state, cell);
                    } else {
                        this.dataGrid.instance.expandRow(cell.key);
                        state = StateConstant.NONE;
                        cell.data.state = state;
                        this.dataGrid.instance.refresh();
                    }
                })
        }
    }

    private validation(cell, productTransportViewModel: TransportViewModel, routeViewModels: RouteViewModel[], verifyViewModels: OperatingProductVerificationModel): boolean {
        let routeViews = this.routeMapperService.routeModelToViews(routeViewModels, cell.data.productId);
        let validityRequired = this.validityRequired(productTransportViewModel);
        let validityPeriod = this.validityEndEqualStart(productTransportViewModel);
        let serviceCategoryViews = this.serviceCategoryMapperService.serviceCategoryModelToViews(productTransportViewModel.productInventoryServiceCategories);
        let salesBucketViews = this.salesBucketMapperService.salesBucketModelToViews(productTransportViewModel.productInventorySalesBuckets);
        let validates: ValidationView[] = [];
        this.validationMapperService.validation(validates,
            routeViews,
            validityRequired,
            validityPeriod,
            verifyViewModels,
            serviceCategoryViews,
            salesBucketViews);
        cell.data.validates = validates;
        if (this.hasValidationMendatory(validates)) {
            return false;
        }
        return true;
    }

    private validityRequired(productTransportViewModel: TransportViewModel): boolean {
        let validities = productTransportViewModel?.productValidities
        if (validities?.length == 0) {
            return true;
        }
        return false;
    }

    private validityEndEqualStart(productTransportViewModel: TransportViewModel): boolean {
        let validities = productTransportViewModel?.productValidities
        if (validities?.length) {
            let conditionCodes = validities.filter(x => x.conditionCode == "<>" || x.conditionCode == "><");
            let filterEndMoreThenStart = conditionCodes.filter(x => x.startDateTime == x.endDateTime);
            if (filterEndMoreThenStart?.length) {
                return true;
            }
        }
        return false;
    }

    private hasValidationMendatory(validates: ValidationView[]): boolean {
        if (validates.length > 0) {
            return true;
        }
        return false;
    }

    private generateOperatingProduct(state, cell) {
        if (cell.data.productId) {
            this.loadingSpinnerService.showSaving();
            this.transportService.updateFinalFlagAndGenerateOperatingProduct(cell.data.productId)
            .subscribe(
                () => {
                    cell.data.finalFlag = true;
                    cell.data.state = state;
                    this.dataGrid.instance.refresh();
                    this.loadingSpinnerService.hide();
                    this.loadingSpinnerService.saveComplete();
                }
            )
        }
    }

    public onRowdblClick(row) {
        let params = {
            id: row.data.productId,
            copy: false,
            paggingDataView: [],
            currentIndex: null,
            userSecurity: this.userSecurity,
            module: null,
            searchCriteria: null,
            transportDetail: true
        }
        this.navigationService.navigate('main/product/'+ Date.now().toString() , "Product Management", true, params);        
    }
}
import { AddressModel } from "../../components/address/shared/address.model";
import { CommentModel } from "../../components/comment/shared/comment.model";
import { CommunicationPhoneModel } from "../../components/communication-phone/shared/communication-phone.model";
import { CommunicationOtherModel } from "./communication-other.model";
import { IndividualNamesModel } from "./individual-name.model";
import { OrganisationUserModel } from "./organisation-user.model";

export class UserAccountModel {
    public userAccountId: string;
    public individualNameId: string;
    public userLogon: string;
    public userPassword: string;
    public userInitials: string;
    public userPin: string;
    public uniqueUserAccountReference: string;
    public status: string;
    public individualId: string;
    public gender: string;
    public dateOfBirth: string;
    public individualNames: IndividualNamesModel[];
    public addresses: AddressModel[];
    public communicationPhones: CommunicationPhoneModel[];
    public communicationOthers: CommunicationOtherModel[];
    public comments: CommentModel[];
    public organisationUsers: OrganisationUserModel[];
    public commitByName: string;
    public commitDateTime: string;
    public multiFactorAuthenticationDateTime: string;
    public changePassword: boolean;
}
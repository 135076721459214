import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { readonlyOption } from '../../../voucher-search/voucher-search-condition/select2-configuration';
import { ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model/product-type-group.model';

@Component({
    selector: 'op-vouchers-tab-search',
    templateUrl: './vouchers-tab-search.component.html'
})
export class VouchersTabSearchComponent {
    public focusing: boolean = false;
    public defaultOption = readonlyOption;

    public organisationId: string;
    public issueDateFrom: string;
    public issueDateTo: string;
    public voucherTypeCode: string | string[];

    @Input() voucherTypeReferences: ProductTypeGroupModel[];
    @Output() onSearchClick = new EventEmitter();
    @Output() onClearClick = new EventEmitter();

    public constructor(private changeDetector: ChangeDetectorRef) { }

    public clearCondition() {
        this.organisationId = null;
        this.issueDateFrom = null;
        this.issueDateTo = null;
        this.onClearClick.emit();
    }
    
    public searchVoucher() {
        this.onSearchClick.emit();
    }

    public assignVoucherTypeCode(typeCode: string) {
        this.voucherTypeCode = typeCode;
        this.changeDetector.detectChanges();
    }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { PriceRuleTypeGroupModel } from "../../models/reference-model/reference-pricing-model";

@Injectable({
    providedIn: 'root'
})
export class PriceRuleTypeGroupService extends ApiServiceBase {
    private readonly REFERENCE_PATH = '/system/PriceRuleTypeGroups'
    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getPriceRuleTypeGroups(): Observable<PriceRuleTypeGroupModel[]> {
        return this.httpGet<PriceRuleTypeGroupModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
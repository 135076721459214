import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { AncillaryServiceAddCommandModel, AncillaryServiceEditCommandModel, AncillaryServiceSearchCommandModel, AncillaryServiceViewModel } from '../../models/product-model/ancillary-service-model';

@Injectable({
  providedIn: 'root'
})
export class AncillaryServiceService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/product/ancillaryservices/"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public search(searchCommand: AncillaryServiceSearchCommandModel): Observable<AncillaryServiceViewModel[]> {
    return this.httpPost<AncillaryServiceViewModel[]>(this.PRODUCT_PATH + 'search', searchCommand)
      .pipe(
        map(res => res.body)
      )
  }

  public getByProductId(productId) {
    return this.httpGet<any>(this.PRODUCT_PATH + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public changeStatus(productId: string, statusCode: string) {
    return this.httpPut(this.PRODUCT_PATH + "status?productId=" + productId + "&statusCode=" + statusCode, null);
  }

  public addProduct(model: AncillaryServiceAddCommandModel) {
    return this.httpPost(this.PRODUCT_PATH, model);
  }

  public editProduct(model: AncillaryServiceEditCommandModel) {
    return this.httpPut(this.PRODUCT_PATH, model);
  }

  public addRootProduct(model: AncillaryServiceAddCommandModel) {
    return this.httpPost(this.PRODUCT_PATH + "root", model);
  }

  public editRootProduct(model: AncillaryServiceEditCommandModel) {
    return this.httpPut(this.PRODUCT_PATH + "root", model);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { InvoiceOrganisationSearchModel } from '../../models/organisation-model/invoice-organisation-search.model';

@Injectable({
    providedIn: 'root'
})
export class InvoiceOrganisationService extends ApiServiceBase {
    private readonly PATH = '/organisation/invoiceOrganisation';

    constructor(private httpClient: HttpClient,
        private authService: AuthService) { 
            super(httpClient, authService)
    }

    public searchInvoiceOrganisation(condition: InvoiceOrganisationSearchModel): Observable<InvoiceOrganisationSearchModel[]> {
        return this.httpPost<InvoiceOrganisationSearchModel[]>(this.PATH + '/search', condition)
            .pipe(
                map(response => response.body)
            )
    }
}

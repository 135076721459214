import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule, DxTreeViewModule } from 'devextreme-angular';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatIconModule } from '@angular/material/icon';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { CounterTextModule } from 'src/app/core/pipes/counter-texts/counter-text.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { ExchangeCurrencyListComponent } from './exchange-currency-list/exchange-currency-list.component';
import { ExchangeCurrencyContentComponent } from './exchange-currency-content.component';

@NgModule({
    declarations: [
        ExchangeCurrencyContentComponent,
        ExchangeCurrencyListComponent
    ],
    imports: [
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        CounterTextModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DxTreeViewModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    exports: [
        ExchangeCurrencyContentComponent
    ] 
})
export class ExchangeCurrencyContentModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationDocumentInvoiceComponent } from './organisation-document-invoice.component';
import { InvoiceOrganisationSearchConditionComponent } from './invoice-organisation-search/invoice-organisation-search-condition/invoice-organisation-search-condition.component';
import { InvoiceOrganisationSearchResultComponent } from './invoice-organisation-search/invoice-organisation-search-result/invoice-organisation-search-result.component';
import { InvoiceOrganisationSearchComponent } from './invoice-organisation-search/invoice-organisation-search.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DxDataGridModule } from 'devextreme-angular';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { CountrySelectionModule } from 'src/app/core/components/country-selection/country-selection.module';
import { InvoiceOrganisationDetailComponent } from './invoice-organisation-detail/invoice-organisation-detail.component';

@NgModule({
    declarations: [
        InvoiceOrganisationSearchComponent,
        InvoiceOrganisationSearchConditionComponent,
        InvoiceOrganisationSearchResultComponent,
        OrganisationDocumentInvoiceComponent,
        InvoiceOrganisationDetailComponent
    ],
    imports: [
        CommonModule,
        CountrySelectionModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        Select2Module,
        UiModule
    ],
    exports: [
        OrganisationDocumentInvoiceComponent
    ]
})
export class OrganisationDocumentInvoiceModule { }

import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";

export const PRODUCT_DASHBOARD_TRANSPORT_COMPARE_MONM_DEMO: OrdersDashboardCompareValuesModel[] = [
    {
        dayNo: 1,
        value1: 112,
        value1Name: "2021",
        value2: 80,
        value2Name: "2022"
    },
    {
        dayNo: 2,
        value1: 143,
        value1Name: "2021",
        value2: 228,
        value2Name: "2022"
    },
    {
        dayNo: 3,
        value1: 144,
        value1Name: "2021",
        value2: 65,
        value2Name: "2022"
    },
    {
        dayNo: 4,
        value1: 176,
        value1Name: "2021",
        value2: 79,
        value2Name: "2022"
    },
    {
        dayNo: 5,
        value1: 116,
        value1Name: "2021",
        value2: 73,
        value2Name: "2022"
    },
    {
        dayNo: 6,
        value1: 204,
        value1Name: "2021",
        value2: 128,
        value2Name: "2022"
    },
    {
        dayNo: 7,
        value1: 187,
        value1Name: "2021",
        value2: 113,
        value2Name: "2022"
    },
    {
        dayNo: 8,
        value1: 193,
        value1Name: "2021",
        value2: 218,
        value2Name: "2022"
    },
    {
        dayNo: 9,
        value1: 98,
        value1Name: "2021",
        value2: 126,
        value2Name: "2022"
    },
    {
        dayNo: 10,
        value1: 244,
        value1Name: "2021",
        value2: 173,
        value2Name: "2022"
    },
    {
        dayNo: 11,
        value1: 135,
        value1Name: "2021",
        value2: 69,
        value2Name: "2022"
    },
    {
        dayNo: 12,
        value1: 103,
        value1Name: "2021",
        value2: 131,
        value2Name: "2022"
    },
    {
        dayNo: 13,
        value1: 177,
        value1Name: "2021",
        value2: 153,
        value2Name: "2022"
    },
    {
        dayNo: 14,
        value1: 151,
        value1Name: "2021",
        value2: 226,
        value2Name: "2022"
    },
    {
        dayNo: 15,
        value1: 206,
        value1Name: "2021",
        value2: 115,
        value2Name: "2022"
    },
    {
        dayNo: 16,
        value1: 113,
        value1Name: "2021",
        value2: 57,
        value2Name: "2022"
    },
    {
        dayNo: 17,
        value1: 190,
        value1Name: "2021",
        value2: 96,
        value2Name: "2022"
    },
    {
        dayNo: 18,
        value1: 101,
        value1Name: "2021",
        value2: 148,
        value2Name: "2022"
    },
    {
        dayNo: 19,
        value1: 155,
        value1Name: "2021",
        value2: 170,
        value2Name: "2022"
    },
    {
        dayNo: 20,
        value1: 109,
        value1Name: "2021",
        value2: 88,
        value2Name: "2022"
    },
    {
        dayNo: 21,
        value1: 186,
        value1Name: "2021",
        value2: 240,
        value2Name: "2022"
    },
    {
        dayNo: 22,
        value1: 120,
        value1Name: "2021",
        value2: 209,
        value2Name: "2022"
    },
    {
        dayNo: 23,
        value1: 97,
        value1Name: "2021",
        value2: 106,
        value2Name: "2022"
    },
    {
        dayNo: 24,
        value1: 152,
        value1Name: "2021",
        value2: 128,
        value2Name: "2022"
    },
    {
        dayNo: 25,
        value1: 179,
        value1Name: "2021",
        value2: 214,
        value2Name: "2022"
    },
    {
        dayNo: 26,
        value1: 161,
        value1Name: "2021",
        value2: 168,
        value2Name: "2022"
    },
    {
        dayNo: 27,
        value1: 82,
        value1Name: "2021",
        value2: 239,
        value2Name: "2022"
    },
    {
        dayNo: 28,
        value1: 98,
        value1Name: "2021",
        value2: 97,
        value2Name: "2022"
    }, 
    {
        dayNo: 29,
        value1: 52,
        value1Name: "2021",
        value2: 193,
        value2Name: "2022"
    },
    {
        dayNo: 30,
        value1: 226,
        value1Name: "2021",
        value2: 121,
        value2Name: "2022"
    }
]

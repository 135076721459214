import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { SettingsManagementService } from 'src/app/core/services/system-services/settings-management.service';
import * as settings from 'src/app/store/settings';

@Component({
  selector: 'smart-nav-footer',
  templateUrl: './nav-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavFooterComponent {

  constructor(private store: Store<any>, private settingService: SettingsManagementService) { }

  toggleMinifyNavigation($event: MouseEvent) {
    $event.preventDefault();
    const minifyState = this.settingService.getNavBarMinifyState()
    this.store.dispatch(settings.toggleMinifyNavigation({ minifyNavigation: !minifyState }));
  }
}

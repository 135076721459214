import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from "../oops-select2/select2.module";
import { StatusColorDropdownComponent } from "./status-color-dropdown.component";

@NgModule({
    imports: [
        CommonModule, 
        Select2Module, 
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [StatusColorDropdownComponent],
    exports: [StatusColorDropdownComponent]
})
export class StatusColorDropdownModule {

}
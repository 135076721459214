import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { CountryReferenceModel, IndividualAgeGroupReferenceModel, IndividualCivilStatusTypeReferenceModel, IndividualInterestTypeReferenceModel, IndividualPositionTypeReferenceModel, IndividualPreferenceTypeReferenceModel, IndividualProfessionTypeReferenceModel, IndividualSocialTypeReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { CountryReferenceService, IndividualCivilStatusTypeReferenceService, IndividualInterestTypeReferenceService, IndividualPositionTypeReferenceService, IndividualPreferenceTypeReferenceService, IndividualProfessionTypeReferenceService, IndividualSocialTypeReferenceService, LanguageReferenceService } from "src/app/core/services/system-services";
import { IndividualAgeGroupReferenceService } from "src/app/core/services/system-services/individual-age-group-reference.service";
import { Select2Option } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/shared/attribute-and-rule/views/select2.option";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { ProfileRowView } from "./shared/profile-row-view";

@Component({
    selector: 'op-insight-detail-profile',
    templateUrl: './insight-detail-profile.component.html'
})

export class InsightDetailProfileComponent implements OnInit {
    private readonly CIVIL_STATUS = "CIVIL_STATUS";
    private readonly INTEREST = "INTEREST";
    private readonly POSITION = "POSITION";
    private readonly PREFERENCE = "PREFERENCE";
    private readonly PROFESSION = "PROFESSION";
    private readonly SOCIAL = "SOCIAL"; 
    private readonly LANGUAGE = "LANGUAGE"; 
    private readonly NATIONALITY = "NATIONALITY"; 
    private readonly AGE_GROUP = "AGE_GROUP"; 
    public readonly title = "Profile";

    @ViewChildren(NgForm) forms: QueryList<NgForm>;
    
    public insightProfiles: ProfileRowView[] = new Array();
    public rows$ = new BehaviorSubject<ProfileRowView[]>(null);
    public select2ProfileTypeOption = new Select2Option("<Select>");
    public addErrorFlag = false;
    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);
    public civilStatusTypeReference$ = new BehaviorSubject<IndividualCivilStatusTypeReferenceModel[]>(null);
    public interestTypeReference$ = new BehaviorSubject<IndividualInterestTypeReferenceModel[]>(null);
    public positionTypeReference$ = new BehaviorSubject<IndividualPositionTypeReferenceModel[]>(null);
    public preferenceTypeReference$ = new BehaviorSubject<IndividualPreferenceTypeReferenceModel[]>(null);
    public professionTypeReference$ = new BehaviorSubject<IndividualProfessionTypeReferenceModel[]>(null);
    public socialTypeReference$ = new BehaviorSubject<IndividualSocialTypeReferenceModel[]>(null);

    public civilStatusTypeReferences: Select2Data[] = new Array;
    public interestTypeReferences: Select2Data[] = new Array;
    public positionTypeReferences: Select2Data[] = new Array;
    public preferenceTypeReferences: Select2Data[] = new Array;
    public professionTypeReferences: Select2Data[] = new Array;
    public socialTypeReferences: Select2Data[] = new Array;
    public ageGroupReferences: Select2Data[] = new Array;
    public languageReferences: Select2Data[] = new Array;
    public nationalityReferences: Select2Data[] = new Array;
    
    public choiceArray = new Array<Select2Data[]>();
    public focusing: boolean = false;

    @Input() filterMode: boolean = false;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() advanceSearchMode: boolean = false;
    @Input() copyMode: boolean = false;

    @Input('blurAfterSelected') blurAfterSelected: boolean = false;

    get isReadonly(): boolean {
        if (this.advanceSearchMode) {
            return false;
        }

        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    get selectedArray(): string[] {
        return this.rows$?.value?.map(item => item.profileType);
    }

    public profileTypeReferences = [
        new Select2Data(this.CIVIL_STATUS, "Civil Status"),
        new Select2Data(this.INTEREST, "Interest"),
        new Select2Data(this.POSITION, "Position"),
        new Select2Data(this.PREFERENCE, "Preference"),
        new Select2Data(this.PROFESSION, "Profession"),
        new Select2Data(this.SOCIAL, "Social type"),
    ] 

    public profileTypeForFilterReferences = [
        new Select2Data(this.CIVIL_STATUS, "Civil Status"),
        new Select2Data(this.INTEREST, "Interest"),
        new Select2Data(this.POSITION, "Position"),
        new Select2Data(this.PREFERENCE, "Preference"),
        new Select2Data(this.PROFESSION, "Profession"),
        new Select2Data(this.SOCIAL, "Social type"),
        new Select2Data(this.LANGUAGE, "Language"),
        new Select2Data(this.NATIONALITY, "Nationality"),
        new Select2Data(this.AGE_GROUP, "Age group")
    ] 

    public select2ProfileTypeCodeOption = {
        placeholder: "<Select>",
        allowClear: true,
        multiple: "multiple",
        theme: "select2-builder-multiple-gray",
        width: 'auto',
        dropdownAutoWidth: true
    }

    ngOnInit(): void {
        this.getIndividualCivilStatusTypeReferences();
        this.getIndividualInterestTypeReferences();
        this.getIndividualPositionTypeReferences();
        this.getIndividualPreferenceTypeReferences();
        this.getIndividualProfessionTypeReferences();
        this.getIndividualSocialTypeReferences();

        if (this.filterMode) {
            this.getIndividualAgeGroupReferences();
            this.getIndividualLanguageReferences();
            this.getIndividualCountryReferences();
        }
    }

    constructor(private individualCivilStatusTypeReferenceService: IndividualCivilStatusTypeReferenceService,
        private individualInterestTypeReferenceService: IndividualInterestTypeReferenceService,
        private individualPositionTypeReferenceService: IndividualPositionTypeReferenceService,
        private individualPreferenceTypeReferenceService: IndividualPreferenceTypeReferenceService,
        private individualProfessionTypeReferenceService: IndividualProfessionTypeReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private individualAgeGroupService: IndividualAgeGroupReferenceService,
        private languageReferenceService: LanguageReferenceService,
        private countryService: CountryReferenceService) {        
    }

    private getIndividualCivilStatusTypeReferences() {
        this.individualCivilStatusTypeReferenceService.getIndividualCivilStatusTypeReferences()
        .subscribe(
            (response) => {
                this.civilStatusTypeReference$.next(response);
                this.createSelect2DataForIndividualCivilStatusTypeReferences(response);
                this.choiceArray[this.CIVIL_STATUS] = this.civilStatusTypeReferences;
            }
        )
    }

    private getIndividualInterestTypeReferences() {
        this.individualInterestTypeReferenceService.getIndividualInterestTypeReferences()
        .subscribe(
            (response) => {
                this.interestTypeReference$.next(response);
                this.createSelect2DataForIndividualInterestTypeReferences(response);
                this.choiceArray[this.INTEREST] = this.interestTypeReferences;
            }
        )
    }

    private getIndividualPositionTypeReferences() {
        this.individualPositionTypeReferenceService.getIndividualPositionTypeReferences()
        .subscribe(
            (response) => {
                this.positionTypeReference$.next(response);
                this.createSelect2DataForIndividualPositionTypeReferences(response)
                this.choiceArray[this.POSITION] = this.positionTypeReferences;
            }
        )
    }

    private getIndividualPreferenceTypeReferences() {
        this.individualPreferenceTypeReferenceService.getIndividualPreferenceTypeReferences()
        .subscribe(
            (response) => {
                this.preferenceTypeReference$.next(response);
                this.createSelect2DataForIndividualPreferenceTypeReferences(response)
                this.choiceArray[this.PREFERENCE] = this.preferenceTypeReferences;
            }
        )
    }

    private getIndividualProfessionTypeReferences() {
        this.individualProfessionTypeReferenceService.getIndividualProfessionTypeReferences()
        .subscribe(
            (response) => {
                this.professionTypeReference$.next(response);
                this.createSelect2DataForIndividualProfessionTypeReferences(response);
                this.choiceArray[this.PROFESSION] = this.professionTypeReferences;
            }
        )
    }

    private getIndividualSocialTypeReferences() {
        this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences()
        .subscribe(
            (response) => {
                this.socialTypeReference$.next(response);
                this.createSelect2DataForIndividualSocialTypeReferences(response);
                this.choiceArray[this.SOCIAL] = this.socialTypeReferences;
            }
        )
    }

    private getIndividualAgeGroupReferences() {
        this.individualAgeGroupService.getIndividualAgeGroupReferences()
            .subscribe(
                (response) => {
                    this.createSelect2DataForIndividualAgeGroupReferences(response);
                    this.choiceArray[this.AGE_GROUP] = this.ageGroupReferences;
                }
            )
    }

    private getIndividualLanguageReferences() {
        this.languageReferenceService.getLanguageReferences()
            .subscribe(
                (response) => {
                    this.createSelect2DataForLanguageReferences(response);
                    this.choiceArray[this.LANGUAGE] = this.languageReferences;
                }
            )
    }

    private getIndividualCountryReferences() {
        this.countryService.getCountryReference()
            .subscribe(
                (response) => {
                    this.createSelect2DataForNationalityReferences(response);
                    this.choiceArray[this.NATIONALITY] = this.nationalityReferences;
                }
            )
    }

    public fillModelToForm(profiles: ProfileRowView[]) {
        if (profiles && profiles.length > 0) {
            this.clearProfile();
            this.insightProfiles = profiles;
            
            for (let insightProfile of this.insightProfiles) {
                let rows = this.rows$.value ?? [];
                rows.push(insightProfile);
                this.rows$.next(rows);
            }   

            this.togglePanelCollapseStatus(false);
        }
    }

    get allowToggle(): boolean {
        return !!(this.rows$.getValue()?.length);
    }

    public getInsightPassengerProfile() : ProfileRowView[] {
        return this.rows$.value
    }

    add() {
        let rows = this.rows$.value ?? [];
        rows.push(this.createNewRow());
        this.rows$.next(rows);
        this.togglePanelCollapseStatus(false);
    }

    private createNewRow(): ProfileRowView {
        let row  = new ProfileRowView();
        row.profileTypeCodes = new Array();
        return row;
    }

    togglePanelCollapseStatus(isCollapse: boolean) {
        if (!isCollapse && !this.rows$?.getValue()?.length) {
            return;
        }
        this.panelCollapseFlag$.next(isCollapse);
    }

    public delete(row) {
        this.rows$.next(this.rows$.value.filter((r) => r != row));
        
        if (!this.allowToggle) {
            this.togglePanelCollapseStatus(true);
        }
    }
    
    public resetErrorFlags() {
        this.addErrorFlag = false;
        let rows = this.rows$.value;
        for (let i = 0; i < this.rows$.value?.length; i++) {
            rows[i].duplicated = false;
        }
        this.rows$.next(rows);
    }

    public profileTypeChange(row: ProfileRowView, event) {
        row.profileType = event;
    }

    public profileChoiceChange(row: ProfileRowView, event) {
        row.codes = event;
    }

    public getProfileTypeCodes(profileType: string) {
        switch(profileType) {
            case this.CIVIL_STATUS:
                return this.civilStatusTypeReferences;                
            case this.INTEREST:
                return this.interestTypeReferences;
            case this.POSITION:
                return this.positionTypeReferences;
            case this.PREFERENCE:
                return this.preferenceTypeReferences;
            case this.PROFESSION:
                return this.professionTypeReferences;
            case this.SOCIAL:
                return this.socialTypeReferences;
            default:
                return null;
        }
    }

    
    private createSelect2DataForIndividualCivilStatusTypeReferences(models: IndividualCivilStatusTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualCivilStatusTypeCode, model.individualCivilStatusTypeName);
            selects.push(select);
        }
        
        this.civilStatusTypeReferences = selects
    }

    private createSelect2DataForIndividualInterestTypeReferences(models: IndividualInterestTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualInterestTypeCode, model.individualInterestTypeName);
            selects.push(select);
        }
        
        this.interestTypeReferences = selects
    }

    private createSelect2DataForIndividualPositionTypeReferences(models: IndividualPositionTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualPositionTypeCode, model.individualPositionTypeName);
            selects.push(select);
        }
        
        this.positionTypeReferences = selects
    }

    private createSelect2DataForIndividualPreferenceTypeReferences(models: IndividualPreferenceTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualPreferenceTypeCode, model.individualPreferenceTypeName);
            selects.push(select);
        }
        
        this.preferenceTypeReferences = selects
    }

    private createSelect2DataForIndividualProfessionTypeReferences(models: IndividualProfessionTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualProfessionTypeCode, model.individualProfessionTypeName);
            selects.push(select);
        }
        
        this.professionTypeReferences = selects
    }

    private createSelect2DataForIndividualSocialTypeReferences(models: IndividualSocialTypeReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualSocialTypeCode, model.individualSocialTypeName);
            selects.push(select);
        }
        
        this.socialTypeReferences = selects
    }   

    private createSelect2DataForIndividualAgeGroupReferences(models: IndividualAgeGroupReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.individualAgeGroupCode, model.individualAgeGroupName);
            selects.push(select);
        }
        
        this.ageGroupReferences = selects
    } 

    private createSelect2DataForLanguageReferences(models: LanguageReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.languageCode, model.languageName);
            selects.push(select);
        }
        
        this.languageReferences = selects
    } 

    private createSelect2DataForNationalityReferences(models: CountryReferenceModel[]) {
        let selects = new Array();

        for (let model of models) {
            let select = new Select2Data(model.countryCode, model.countryName);
            selects.push(select);
        }
        
        this.nationalityReferences = selects
    } 
    
    public getProfileTypeSelectList(type: string) : Select2Data[] {     
        if (this.filterMode) {
            return this.profileTypeForFilterReferences.filter(x => this.selectedArray.includes(x.id) == false || x.id == type).map(item => item);
        }

        return this.profileTypeReferences.filter(x => this.selectedArray.includes(x.id) == false || x.id == type).map(item => item);
    }

    public clearProfile() {
        this.insightProfiles = [];
        this.rows$.next(null)
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { OrganisationModel, OrganisationGroupReferenceModel, OrganisationTypeReferenceModel, OrganisationRoleReferenceModel } from 'src/app/core/models/organisation-model';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { StatusReferenceModel, HashTagReferenceModel, DomainAttributeModel, ConditionReferenceModel, CalendarValidityReferenceModel, DateTimeDimensionReferenceModel, CountryReferenceModel, LocationGroupModel, RegionReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductTypeGroupModel, ProductGroupReferenceModel, ProductCategoryReferenceModel, ProductNumberTypeReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel } from 'src/app/core/models/reference-model/reference-product-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { HashTagReferenceService, DomainAttributeService, RegionReferenceService, OrganisationGroupReferenceService, LanguageReferenceService } from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { ProductGroupReferenceService, StatusReferenceService, ProductCategoryReferenceService, ProductNumberTypeReferenceService, ConditionReferenceService, CountryReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, CalendarValidityService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, UsageTypeReferenceService } from 'src/app/core/services/system-services';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, EditButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { HashTagMapperService } from '../../shared/mapper/hashtag-mapper.service';
import { ProductHashTagView } from '../../shared/views/product-hashtag.view';
import { takeUntil } from 'rxjs/operators';
import { MediaTypeReferenceModel, MediaUseReferenceModel, MediaHashTagModel, MediaTypeFileTypeModel, LanguageReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaTypeReferenceService, MediaUseReferenceService, MediaHashTagService, MediaTypeFileTypeService } from 'src/app/core/services/reference-service/reference-media-service';
import { MapperService } from '../../special-service/special-service-content/special-service-search/shared/mapper.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { TreeNode } from 'primeng/api';
import { VoucherGeneralComponent } from './voucher-general/voucher-general.component';
import { VoucherTreeComponent } from './voucher-tree/voucher-tree.component';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationTextComponent } from 'src/app/core/components/translation-text/translation-text.component';
import { TranslationMediaComponent } from 'src/app/core/components/translation-media/translation-media.component';
import { TranslationMediaMapperService } from 'src/app/core/components/translation-media/shared/translation-media-mapper.service';
import { TranslationTextMapperService } from 'src/app/core/components/translation-text/shared/translation-text-mapper.service';
import { VoucherService } from 'src/app/core/services/product-services/voucher.service';
import { VoucherViewModel } from 'src/app/core/models/product-model/voucher-model/voucher-view.model';
import { VoucherAttributeAndRuleComponent } from '../voucher-attribute-and-rule/voucher-attribute-and-rule.component';
import { MediaViewModel } from 'src/app/core/models/media-model';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';
import { TextMapperService } from 'src/app/core/components/text/shared/text-mapper.service';
import { TextMediaComponent } from 'src/app/core/components/text-media/text-media.component';
import { ProductPriceConditionViewModel } from 'src/app/core/models/product-model/fee-model';
import { VoucherProductConditionComponent } from './voucher-product-condition/voucher-product-condition.component';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { VoucherMapperService } from '../shared/voucher-mapper.service';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { VoucherEditCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-edit-command.model';
import { VoucherAddCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-add-command.model';
import { TreeMapperService } from '../../shared/mapper/tree-mapper.service';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { VouchersTabComponent } from './vouchers-tab/vouchers-tab.component';

@Component({
    selector: 'op-voucher-detail',
    templateUrl: './voucher-detail.component.html',
    providers:[
        Select2DataMapperService,
        HashTagMapperService,
        MapperService,
        TranslationMediaMapperService,
        TranslationTextMapperService,
        MediaMapperService,
        TextMapperService,
        VoucherMapperService,
        TranslationNameMapperService,
        TreeMapperService,
        ActionbarService,
        AlertBarService
    ]
})
export class VoucherDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly generalTabId: string = 'general';
    public readonly translationTabId: string = 'translation';
    public readonly voucherTabId: string = 'voucher';
    public readonly attributeTabId = 'attribute';
    public readonly textTabId = 'text';
    public readonly mediaTabId = 'media';
    private readonly VOUCHER_CODE = 'VOUCHER';
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ACTIVE_STATUS = "A";
    private readonly VALIDITY_SERVICE_CODE = "SERVICE";
    private readonly VALIDITY_SALE_CODE = "SALE";
    private readonly VALIDITY_PAYMENT_CODE = "PAYMENT"
    public readonly ENTITY_NAME: string = "Product";
    private readonly USAGETYPE_DATA: string = "DATA";
    private readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    private readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly TRANSLATION_NAME = "Airline translation set";
    private readonly ATTRBUTE_GROUP_CODE_ORG_DESC: string = "ORIGINDESTINATION";

    public readonly generalPanelName = 'General Information';
    public readonly attributePanelName = 'Attribute';
    public readonly restrictionPanelName = 'Restriction';
    public readonly validityPanelName = 'Validity';
    public readonly posPanelName = 'Point of Sales';
    public readonly conditionPanelName = 'Product Condition';

    public newVoucher: boolean = false;
    public newFromParent: boolean = false;
    public selectedTab = this.generalTabId;
    public paggingDataView: PagingDataView[] = [];
    public currentIndex: number;
    public heightPanelGeneral: number;
    public voucherId: string;
    public parentId: string;
    public navigationParams: any;
    public copyFlag: boolean = false;

    private unsubscribe$ = new Subject();
    public generalInfo$ = new BehaviorSubject<VoucherViewModel>(this.defaultVoucher);
    public tree$ = new BehaviorSubject<TreeNode[]>(null);
    public userSecurity: SecurityGroupSecurityModel;
    public actionBarHandler: ActionBarHandlerModel;
    public showTranslate: boolean = false;
    public showVoucher: boolean = false;
    public productName$ = new BehaviorSubject<string>(null);
    public languageTransaltion;
    public completeLoadReference: boolean = false;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    editBtn = new EditButtonModel();
    saveBtn = new SaveButtonModel();
    cancelBtn = new CancelButtonModel();

    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public voucherProductGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);

    public hashTagReferences$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public voucherAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeOrgDesc$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);

    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];

    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);

    public voucherTypeReferences$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);

    @ViewChild(VoucherGeneralComponent) generalComponent: VoucherGeneralComponent;
    @ViewChild(VoucherTreeComponent) treeComponent: VoucherTreeComponent;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;
    @ViewChild(VoucherAttributeAndRuleComponent) attributeAndRuleComponent: VoucherAttributeAndRuleComponent;
    @ViewChild(TextMediaComponent) textMediaComponent: TextMediaComponent;
    @ViewChild(VoucherProductConditionComponent) conditionComponent: VoucherProductConditionComponent;
    @ViewChild(VouchersTabComponent) voucherTabComponent: VouchersTabComponent;

    constructor(public alertBarService: AlertBarService,
        private productTypeGroupService: ProductTypeGroupService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private statusReferenceService: StatusReferenceService,
        private actionbarService: ActionbarService,
        private hashTagReferenceService: HashTagReferenceService,
        private domainAttributeService: DomainAttributeService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private changeDetector: ChangeDetectorRef,
        private languageReferenceService: LanguageReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mapperService: MapperService,
        private navigationService: NavigationService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private translationMediaMapperService: TranslationMediaMapperService,
        private translationTextMapperService: TranslationTextMapperService,
        private voucherService: VoucherService,
        private mediaMapperService: MediaMapperService,
        private textMapperService: TextMapperService,
        public alertbarService: AlertBarService,
        private loadingSpinner: LoadingSpinnerService,
        private voucherMapperService: VoucherMapperService,
        private translationNameMapperService: TranslationNameMapperService,
        private treeMapperService: TreeMapperService,
        private focusingService: FocusingService) {
            this.getParams();
        }

    ngOnInit(): void {
        this.getReferences().then(() => {
            this.updateLoadReferenceCompleted().then(() => {
                this.setProductHashTagValue(null);
                if (this.voucherId) {
                    this.getVoucherDetail(this.voucherId);
                }
            })
        });
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    onAlertBarClick() {
        this.alertBarService.hide();
    }

    getParams() {
        this.navigationParams = this.navigationService.getParams();
        if (!this.navigationParams)
            this.navigationParams = this.navigationService.getPreviousTabParams()?.params;

        this.voucherId = this.navigationParams?.voucherId ?? this.navigationParams?.id;
        this.userSecurity = this.navigationParams?.userSecurity;
        this.newVoucher = this.navigationParams?.newVoucher ?? false;
        this.copyFlag = this.navigationParams?.copyVoucher ?? false;
        this.paggingDataView = this.navigationParams?.pagingDatas ?? [];
        this.currentIndex = this.navigationParams?.currentIndex;
    }

    private getReferences(): Promise<void> {
        this.loadingSpinner.show();
        return new Promise((resolve, reject) => {
            forkJoin({
                productTypeGroups: this.productTypeGroupService.getAll(),
                productGroupReferences: this.productGroupReferenceService.getAll(),
                statusReferences: this.statusReferenceService.getAll(),
                hashTagReferences: this.hashTagReferenceService.GetOrganisationHashtag(),
                voucherAttributes: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, '', false, '', '', this.VOUCHER_CODE),
                orgDescAttribute: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_ORG_DESC, '', false, '', '', this.VOUCHER_CODE),
                productCategories: this.productCategoryReferenceService.getAll(),
                productNumberTypeReferences: this.productNumberTypeReferenceService.getAll(),
                providers: this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE),
                suppliers: this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE),
                conditions: this.conditionReferenceService.getConditionsByCodes([]),
                countryReferences: this.countryReferenceService.searchAllCountry(),
                locationGroups: this.locationGroupService.getAll(),
                productLocationPoint: this.productLocationPointReferenceService.getAll(),
                productLocationType: this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE),
                regionReferences: this.regionReferenceService.getRegionReference(),
                calendarValidityReferences: this.calendarValidityReferenceService.getByCalendarValidityCodes(this.VALIDITY_SERVICE_CODE, this.VALIDITY_SALE_CODE, this.VALIDITY_PAYMENT_CODE),
                dateTimeDimentions: this.dateTimeDimensionReferenceService.getDateTimeDimensionReference(),
                organisations: this.organisationService.getByStatus(this.ACTIVE_STATUS),
                organisationGroups: this.organisationGroupReferenceService.getOrganisationGroupReference(),
                organiastionTypes: this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS),
                organisationRoleReferences: this.organisationRoleReferenceService.getAllActive(),
                languages: this.languageReferenceService.getByOrganisation(),
                mediaTypeReferences: this.mediaTypeReferenceService.getAll(),
                mediaUseReferences: this.mediaUseReferenceService.getAll(),
                mediaHashTags: this.mediaHashTagService.getAll(),
                mediaTypeFileTypes: this.mediaTypeFileTypeService.getAll(),
                usageTypeReferences: this.usageTypeReferenceService.getUsageTypeReferences()
            })
            .subscribe(({
                productTypeGroups,
                productGroupReferences,
                statusReferences,
                hashTagReferences,
                voucherAttributes,
                orgDescAttribute,
                productCategories,
                productNumberTypeReferences,
                providers,
                suppliers,
                conditions,
                countryReferences,
                locationGroups,
                productLocationPoint,
                productLocationType,
                regionReferences,
                calendarValidityReferences,
                dateTimeDimentions,
                organisations,
                organisationGroups,
                organiastionTypes,
                organisationRoleReferences,
                languages,
                mediaTypeReferences,
                mediaUseReferences,
                mediaHashTags,
                mediaTypeFileTypes,
                usageTypeReferences
            }) => {
                this.productTypeGroup$.next(productTypeGroups);
                this.fillProductGroupReferences(productGroupReferences);
                this.statusReferences$.next(statusReferences);
                this.fillHashTagReference(hashTagReferences);
                this.voucherAttribute$.next(voucherAttributes);
                this.fillOrgDescDomainAttribute(orgDescAttribute);
                this.productCategoryReferences$.next(productCategories);
                this.productNumberTypeReference$.next(productNumberTypeReferences);
                this.provider$.next(providers
                    .filter(x => x.providerIataCode)
                    .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
                this.supplier$.next(suppliers);
                this.conditionReference$.next(conditions);
                this.fillCountryReference(countryReferences);
                this.fillLocationGroup(locationGroups);
                this.productLocationPointReference$.next(productLocationPoint);
                this.fillProductLocationType(productLocationType);
                this.fillRegionReference(regionReferences);
                this.calendarValidityReference$.next(calendarValidityReferences);
                this.dateTimeDimensionReference$.next(dateTimeDimentions);
                this.fillOrganisation(organisations);
                this.fillOrganisationGroupReference(organisationGroups);
                this.fillOrganisationTypeReference(organiastionTypes);
                this.fillOrganisationRoleReferences(organisationRoleReferences);
                this.languageReference$.next(languages);
                this.mediaTypeReference$.next(mediaTypeReferences);
                this.mediaUseReference$.next(mediaUseReferences);
                this.fillMediaHashTag(mediaHashTags);
                this.mediaTypeFileType$.next(mediaTypeFileTypes);
                this.getUsageTypeReferenceWithFilter(usageTypeReferences);
                this.getVoucherTypeReferernce();
                this.loadingSpinner.hide();
                resolve();
            })
        })
    }

    private updateLoadReferenceCompleted() : Promise<void> {
        return new Promise((resolve, reject) => {
            this.completeLoadReference = true;
            this.changeDetector.detectChanges();
            resolve();
        }); 
    }

    getVoucherDetail(voucherId: string) {
        this.loadingSpinner.show()
        this.voucherService.getVoucherById(voucherId)
            .subscribe(
                (response: VoucherViewModel) => {
                    this.loadingSpinner.hide();
                    this.voucherId = response.productId;
                    this.newVoucher = false;
                    this.newFromParent = false;
                    this.voucherTabComponent.conditionComponent.assignVoucherTypeCode(response.productTypeCode);
                    this.productName$.next(response.productName);
                    this.generalComponent.fillValueToForm(response);
                    this.tree$.next(this.treeMapperService.productTreeView(response.productHierarchy));
                    this.parentId = response.parentProductId;
                    this.setProductHashTagValue(response.productHashtags);
                    this.attributeAndRuleComponent?.fillAttributesToForm(response.productAttributes);
                    this.attributeAndRuleComponent?.fillOriginDestinationToForm(response.productAttributes);
                    this.attributeAndRuleComponent?.fillRestrictionsToForm(response.productRestrictionProducts,
                        response.productRestrictionProductNumbers,
                        response.productRestrictionRoutes,
                        response.productRestrictionLocations);
                    this.attributeAndRuleComponent?.fillValiditiesToForm(response.productValidities);
                    this.attributeAndRuleComponent?.fillPointOfSalesToForm(response.productRestrictionOrganisations,
                        response.productRestrictionLocations);
                    this.attributeAndRuleComponent?.fillPointOfPaymentsToForm(response.productRestrictionOrganisations);
                    this.fillModelToTranslationName(response.productName, response.languageTranslationSet);
                    this.fillModelToText(response.medias);
                    this.fillTranslationText(response.medias);
                    this.fillModelToMedia(response.medias);
                    this.fillTranslationMedia(response.medias);
                    this.fillProductPriceCondition(response.productPriceConditions);
                    this.changeDetector.detectChanges();

                    if (this.copyFlag) {
                        this.copyVoucher();
                    }
                    
                }
            )
    }

    get defaultVoucher(): VoucherViewModel {
        return {
            productId: null,
            organisationId: null,
            parentProductId: null,
            productGroupCode: null,
            productGroupName: null,
            productTypeCode: null,
            productTypeName: null,
            supplierId: null,
            supplierName: null,
            providerId: null,
            providerName: null,
            productName: null,
            productDescription: null,
            displayCode: null,
            displayName: null,
            draftFlag: null,
            finalFlag: null,
            filterUserAccountId: null,
            isOwnerFilter: null,
            usageTypeCode: null,
            usageTypeName: null,
            rootProductId: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            ownerFilterUserAccountId: null,
            statusCode: null,
            isHighestLevelAttrInv: null,
            inventoryAttributeLowerLevel: null,
            commitBy: null,
            commitLastname: null,
            commitFirstname: null,
            commitTitle: null,
            commitDateTime: null,
            productHierarchy: null,
            productHashtags: null,
            productNumbers: null,
            productRestrictionProducts: null,
            productRestrictionProductNumbers: null,
            productRestrictionRoutes: null,
            productRestrictionLocations: null,
            productValidities: null,
            productAttributes: null,
            productInventoryAttributeTypes: null,
            productInventoryDimensions: null,
            productInventoryHierarchy: null,
            productRestrictionOrganisations: null,
            languageTranslationSet: null,
            medias: null,
            hierarchyLevel: null,
            productPriceConditions: null
        }
    }

    public getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height;
    }

    private setDefaultActionBarHandler() {
        this.getDefaultActionBarHandler();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private getDefaultActionBarHandler() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.enable(this.userSecurity?.editFlag ?? false);
        this.editBtn.disable();

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.editBtn,
            this.saveBtn,
            this.cancelBtn,
            new RefreshButtonModel()
        )
    }

    public actionbarClick(actionId: string) {
        switch (actionId) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.cancel:
                this.cancel();
                break;
            case ACTION_STATUS.new:
                this.newClick();
                break;
            case ACTION_STATUS.save:
                this.onSaveClick();
                break;
            case ACTION_STATUS.copy:
                this.copyVoucher();
                break;
            default:
                break;
        }
    }

    public onActiveIdChange(activeId: string) {
        switch (activeId) {
            case this.generalTabId:
                this.displayGeneralDetail();
                this.focusGeneral();
                break;
            case this.translationTabId:
                this.showTranslate = true;
                this.showVoucher = false;
                break;
            case this.voucherTabId:
                this.getVoucherTypeReferernce();
                this.showTranslate = false;
                this.showVoucher = true;
                break;
            case this.attributeTabId:
                this.displayGeneralDetail();
                this.focusAttributePanel();
                break;
            case this.textTabId:
                this.displayGeneralDetail();
                this.focusOnText();
                break;
            case this.mediaTabId:
                this.displayGeneralDetail();
                this.focusOnMedia();
                break;
        }
    }

    private displayGeneralDetail() {
        this.showTranslate = false;
        this.showVoucher = false;
    }

    private focusGeneral() {
        this.focusingService.focus(this.generalComponent.focusingDirective)
    }

    private focusAttributePanel() {
        this.focusingService.focus(this.attributeAndRuleComponent.attributeComponent.focusingDirective);
    }

    private focusOnMedia() {
        this.focusingService.focus(this.textMediaComponent.mediaComponent.focusingDirective);
    }

    private focusOnText() {
        this.focusingService.focus(this.textMediaComponent.textComponent.focusingDirective);
    }   

    get showVoucherDetail(): boolean {
        return this.showTranslate || this.showVoucher
    }

    private back() {
        this.navigationService.navigate('/main/product/home', null, null, this.navigationParams);
    }

    private fillProductGroupReferences(productGroupReferences: ProductGroupReferenceModel[]) {
        this.voucherProductGroupReferences$.next(productGroupReferences?.filter(item => item.productCategoryCode == this.VOUCHER_CODE));
        this.productGroupReferences$.next(productGroupReferences);
    }

    private fillHashTagReference(hashTagReferences: HashTagReferenceModel[]) {
        this.hashTagReferences$.next(hashTagReferences);
        this.changeDetector.detectChanges();
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        this.attributeAndRuleComponent?.setProductHashTagValue(value)
    }

    private fillOrgDescDomainAttribute(attributes: DomainAttributeModel[]) {
        let filterOrgDesc = this.getAtttributeOrgDesc(attributes);
        this.domainAttributeOrgDesc$.next(filterOrgDesc);
    }

    private getAtttributeOrgDesc(response: DomainAttributeModel[]) {
        if (response) {
            return response.filter(x => (x.startFlag == true || x.endFlag == true) && x.scheduleProductFlag == true)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        }
    }

    private fillCountryReference(responses: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
    }

    private fillLocationGroup(responses: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
    }

    private fillProductLocationType(response: ProductLocationTypeReferenceModel) {
        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
        productLocationTypeReference.push(response);
        this.productLocationTypeReference$.next(productLocationTypeReference);
    }

    private fillRegionReference(responses: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
    }

    private fillOrganisation(responses: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
        this.organisationSelect2Data = this.organisationSelect2Data.filter((value, index) => this.organisationSelect2Data.findIndex(item => item.id == value.id) === index);
    }

    private fillOrganisationGroupReference(responses: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
    }

    private fillOrganisationTypeReference(responses: OrganisationTypeReferenceModel[]) {
        this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
    }

    private fillOrganisationRoleReferences(organisationRoleReferences: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(organisationRoleReferences);
    }

    private fillMediaHashTag(responses: MediaHashTagModel[]) {
        this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(responses);
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferences$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    public newClick() {
            if (this.newVoucher || this.newFromParent) {
                return;
            }
            this.parentId = this.voucherId;
            this.voucherId = null;
            this.newFromParent = true;
            this.treeComponent.addNewNode(this.parentId, '');
            this.resetForm();
    }
    
    private fillModelToTranslationName(productName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.translationNameComponent.fillModelToForm(productName, languageTranslationSet);
    }

    public displayTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryToTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
            this.changeDetector.detectChanges();
        }
    }

    public deleteTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationChildTextViews(
                value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    public deleteMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildTexts = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationChildFileViews(
                value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildTexts);
        }
    }

    public displayMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryToTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildFiles = this.translationMediaMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildFiles);
        }
    }

    private fillModelToText(medias: MediaViewModel[]) {
        var mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMediaComponent.textComponent.fillModelToForm(mediaTextViews);
    }

    private fillTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMediaComponent.mediaComponent.fillModelToForm(mediaViews);
    }

    private fillTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }

    private getVoucherTypeReferernce() {
        let voucherGroups = this.productGroupReferences$.value?.filter(group => group.productCategoryCode == this.VOUCHER_CODE).map(group => group.productGroupCode);
        let coucherTypes = this.productTypeGroup$.value?.filter(type => voucherGroups.includes(type.productGroupCode));
        this.voucherTypeReferences$.next(coucherTypes);
    }

    private fillProductPriceCondition(productPriceConditions: ProductPriceConditionViewModel[]) {
        this.conditionComponent.fillModelsToForm(productPriceConditions);
    }

    public voucherNameChanged(voucherName: string) {
        this.treeComponent.onSelectedNodeNameChange(voucherName);
    }

    public nodeSelect(id: string) {
        this.newVoucher = false;
        this.copyFlag = false;
        this.newFromParent = false;
        this.voucherId = id;
        this.getVoucherDetail(id);
    }

    private onSaveClick() {
        if (!this.validateForm()) {
            this.displayAlertBar();
            return;
        }
        this.alertBarService.hide();
        if (this.newVoucher || this.newFromParent) {
            this.addProduct();
        } else {
            this.updateProduct();
        }
    }

    private validateForm(): boolean {
        let general = this.generalComponent.validate();
        let attribute = this.attributeAndRuleComponent?.attributeComponent?.validateAttribute();
        let restriction = this.attributeAndRuleComponent?.restrictionComponent?.ruleComponent?.validateForm();
        let validity = this.attributeAndRuleComponent?.validityComponent?.ruleComponent?.validateForm();
        let pointOfSales = this.attributeAndRuleComponent?.pointOfSalesComponent?.ruleComponent?.validateForm();
        let condition = this.conditionComponent.validateForm();
        
        return general && attribute && restriction && validity && pointOfSales && condition;
    }

    private displayAlertBar() {
        if (!this.generalComponent.validate()) {
            this.alertBarService.show(this.generalPanelName, this.generalComponent.getErrorMessage());
        } else if (!this.attributeAndRuleComponent?.attributeComponent?.validateAttribute()) {
            this.alertBarService.show(this.attributePanelName, this.attributeAndRuleComponent?.attributeComponent?.getErrorMessage());
        } else if (!this.attributeAndRuleComponent?.restrictionComponent?.ruleComponent?.validateForm()) {
            this.alertBarService.show(this.restrictionPanelName, this.attributeAndRuleComponent?.restrictionComponent?.getErrorMessage());
        } else if (!this.attributeAndRuleComponent?.validityComponent?.ruleComponent?.validateForm()) {
            this.alertBarService.show(this.validityPanelName, this.attributeAndRuleComponent?.validityComponent?.getErrorMessage());
        } else if (!this.attributeAndRuleComponent?.pointOfSalesComponent?.ruleComponent?.validateForm()) {
            this.alertBarService.show(this.posPanelName, this.attributeAndRuleComponent?.pointOfSalesComponent?.getErrorMessage());
        } else if (!this.conditionComponent.validateForm()) {
            this.alertBarService.show(this.conditionPanelName, this.conditionComponent.getErrorMessage());
        }
    }

    private addProduct() {
        let addCommand = this.createAddModel();
        this.loadingSpinner.showSaving();
        this.voucherService.addVoucher(addCommand)
            .subscribe(
                (response: string) => {
                    this.voucherId = response;
                    this.newVoucher = false;
                    this.newFromParent = false;
                    this.copyFlag = false;
                    this.getVoucherDetail(response);
                    this.loadingSpinner.saveComplete();
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private createAddModel(): VoucherAddCommandModel {
        let view = this.defaultVoucher;
        view.parentProductId = this.parentId;
        this.generalComponent.fillFormToVoucherViewModel(view);
        let addModel = this.voucherMapperService.viewModelToAddCommand(view, this.attributeAndRuleComponent);

        let medias = this.textMapperService.textToMediaModels(this.textMediaComponent?.textComponent?.mediaTexts, this.ENTITY_NAME, this.voucherId);
        medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent?.mediaTranslationChildTexts  ?? [], medias);

        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMediaComponent?.mediaComponent?.mediaFiles, this.ENTITY_NAME, this.voucherId);
        medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent?.mediaTranslationChildFiles ?? [], medias);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent?.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.voucherId, this.translationNameComponent?.translationViews);

        let productPriceConditions = this.conditionComponent.fillFormsToModels();

        addModel.medias = medias;
        addModel.languageTranslationSet = languageTranslationSet;
        addModel.productPriceConditions = productPriceConditions;

        return addModel;
    }

    private updateProduct() {
        let editCommand = this.createEditModel();
        this.loadingSpinner.showSaving();
        this.voucherService.editVoucher(editCommand)
            .subscribe(
                () => {
                    this.loadingSpinner.saveComplete();
                    this.getVoucherDetail(this.voucherId);
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private createEditModel(): VoucherEditCommandModel {
        let view = this.defaultVoucher;
        view.productId = this.voucherId;
        view.parentProductId = this.parentId;
        this.generalComponent.fillFormToVoucherViewModel(view);
        let addModel = this.voucherMapperService.viewModelToEditCommand(view, this.attributeAndRuleComponent);

        let medias = this.textMapperService.textToMediaModels(this.textMediaComponent.textComponent.mediaTexts, this.ENTITY_NAME, this.voucherId);
        medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);

        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMediaComponent.mediaComponent.mediaFiles, this.ENTITY_NAME, this.voucherId);
        medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, medias);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.voucherId, this.translationNameComponent.translationViews);

        let productPriceConditions = this.conditionComponent.fillFormsToModels();

        addModel.medias = medias;
        addModel.languageTranslationSet = languageTranslationSet;
        addModel.productPriceConditions = productPriceConditions;

        return addModel;
    }

    private resetForm() {
        this.generalComponent.createNewChildGeneralInfo();
        this.attributeAndRuleComponent.clearAttributeAndRule();
        this.fillModelToText([]);
        this.fillModelToMedia([]);
        this.fillProductPriceCondition([]);
        this.translationNameComponent.clearForm();
        this.translationTextComponent.clearForm();
        this.attributeAndRuleComponent.setupAttributeForNew();
    }

    public copyVoucher() {
        if (!this.voucherId) {
            return;
        }
        this.copyFlag = true;
        this.newVoucher = this.parentId ? false : true;
        this.newFromParent = this.parentId ? true : false;
        this.voucherId = null;
        this.treeComponent.createCopiedChild(this.parentId);
    }

    onNewNodeNameChange(newNodeName: string) {
        this.generalComponent.formGroup.get('productName').patchValue(newNodeName);
    }

    private cancel() {
        let params = {
            tab: null,
            module: null
        }
        this.navigationService.navigate('/main/product/home', null, null, params);
    }

    public onPageChange(productId: string) {
        if (!this.voucherId) {
            return;
        }
        if (this.voucherId == productId) {
            return;
        }
        this.voucherId = productId;
        this.getVoucherDetail(this.voucherId)
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { PointOfSalesMapperService } from "src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service";
import { RestrictionMapperService } from "src/app/core/components/rules-config/shared/mappers/restriction-mapper.service";
import { Select2DataMapperService } from "src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service";
import { ValidityMapperService } from "src/app/core/components/rules-config/shared/mappers/validity-mapper.service";
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from "src/app/core/models/organisation-model";
import { MerchandizeViewModel } from "src/app/core/models/product-model/merchandize-model";
import { MerchandizeSearchCommandModel } from "src/app/core/models/product-model/merchandize-model/merchandize-seach-command.model";
import { ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { ProductHashTagViewModel } from "src/app/core/models/product-model/product-base-model/product-hashtag";
import { ProductInventoryDimensionViewModel } from "src/app/core/models/product-model/product-base-model/product-inventory-dimension";
import { ProductNumberViewModel } from "src/app/core/models/product-model/product-base-model/product-number";
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel, ProductRestrictionProductNumberViewModel, ProductRestrictionProductViewModel, ProductRestrictionRouteViewModel } from "src/app/core/models/product-model/product-base-model/product-restriction";
import { ProductValidityViewModel } from "src/app/core/models/product-model/product-base-model/product-validity";
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { DomainAttributeService, HashTagReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from "src/app/core/services/airline-services";
import { OrganisationRoleReferenceService, OrganisationService } from "src/app/core/services/organisation-services";
import { MerchandizeService } from "src/app/core/services/product-services";
import { ProductSearchFilterService } from "src/app/core/services/product-services/product-search-filter.service";
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { LocationGroupService } from "src/app/core/services/reference-service/reference-general-service";
import { ProductTypeGroupService } from "src/app/core/services/reference-service/reference-product-service";
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ACTION_HANDLER_STATUS, ACTION_STATUS } from "src/app/shared/ui/actionbar/actionbar-constant";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { UIService } from 'src/app/shared/ui/uiservice/ui.service';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { HashTagMapperService } from "../../shared/mapper/hashtag-mapper.service";
import { MerchandizeModeHelperService } from "../../shared/merchandize-mode-helper.service";
import { ProductHashTagView } from "../../shared/views/product-hashtag.view";
import { ProductAttributeAndRuleComponent } from "../attribute-and-rule/attribute-and-rule.component";
import { MerchandizeSearchFilterNameComponent } from "./merchandize-filter-name/merchandize-filter-name.component";
import { MerchandizeSearchConditionComponent } from "./merchandize-search-condition/merchandize-search-condition.component";
import { MerchandizeSearchTableComponent } from "./merchandize-search-table/merchandize-search-table.component";
import { MerchandizeSearchTableView } from "./shared/merchandize-search-table.view";
import { MapperService } from "./shared/mapper.service";
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from "src/app/shared/ui/actionbar/models";
import { NumberMapperService } from "../../shared/mapper/number-mapper.service";
import { ActionbarService } from "src/app/shared/ui/actionbar";
import { MERCHANDIZE_REF_CODE } from "../../shared/constant/merchandize-ref-code.constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { ProductCategory } from "../../../product-management-content/product-categories/product-category";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-merchandize-search',
    templateUrl: './merchandize-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AttributeMapperService,
        CountryReferenceService, HashTagMapperService,
        MapperService, NumberMapperService, PointOfSalesMapperService, ProductTypeGroupService, RestrictionMapperService, 
        Select2DataMapperService, UIService, ValidityMapperService, MerchandizeModeHelperService
    ]
})
export class MerchandizeSearchComponent implements OnInit {
    readonly FILTER_USAGE_TYPE: string = 'FILTER';
    readonly ALERT_HEADER: string = 'Merchandize Search';
    readonly SEARCH_FILTER_SAVE_SUCCESS: string = "Search Filter save successfully.";
    readonly SEARCH_FILTER_DELETE_SUCCESS: string = "Search Filter delete successfully.";
    readonly SPINNER_NAME: string = "merchandiseSearchSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;
    readonly DELETE_SUCCESS: string = "Merchandize delete successfully.";
    readonly FAILED: string = "Failed save filter.";
    readonly FAILED_GET: string = "Failed get filter.";
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    readonly SEARCHCONDITION_PANEL = 'Search';
    readonly FILTERNAME_PANEL = 'Filter Name';

    @Input() selectedProductCategory: ProductCategory;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;

    public advanceSearchModeEnabled = false;
    @Output() showAdvanceSearchChanged = new EventEmitter<boolean>(this.advanceSearchModeEnabled);
    @Output() cancelRequest = new EventEmitter();

    @ViewChild(ProductAttributeAndRuleComponent) attributeAndRuleComponent: ProductAttributeAndRuleComponent;
    @ViewChild(MerchandizeSearchTableComponent) searchTableComponent: MerchandizeSearchTableComponent;
    @ViewChild(MerchandizeSearchConditionComponent) productSearchConditionComponent: MerchandizeSearchConditionComponent;
    @ViewChild(MerchandizeSearchFilterNameComponent) filterNameComponent: MerchandizeSearchFilterNameComponent;
    @ViewChild('merchandizeDetailContainer', {static: true, read: ViewContainerRef}) merchandizeDetailContainer: ViewContainerRef;

    public currentActionBarMode = ACTION_HANDLER_STATUS.none;

    public calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public loadingNotifier = new LoadingNotifier();
    public locationGroupSelect2Data: Select2Data[];
    public merchandizeView$ = new BehaviorSubject<MerchandizeViewModel>(this.getDefaultProductSearchCondition());
    public organisationGroupSelect2Data: Select2Data[];
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public paggingView: PagingDataView[];
    public productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReferenceMerchandizes: ProductGroupReferenceModel[] = new Array();
    public productGroupReferenceMerchandizes$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public showHashtagRow: boolean = false;
    public productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public productSearchFilters$ = this.getProductSearchFilter();
    public productSearchResults$ = new BehaviorSubject<MerchandizeSearchTableView[]>(null);
    public productTypeGroups$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public regionReferenceSelect2Data: Select2Data[];
    public searchCommand: MerchandizeSearchCommandModel;
    public searchMode: boolean = true;
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public userSecurity: SecurityGroupSecurityModel;

    private currentConditionSearch: MerchandizeViewModel;
    private hashTagReferences: HashTagReferenceModel[];
    private productHashtagValueViews: ProductHashTagView[] = new Array();
    private productLocationTypeReferences: ProductLocationTypeReferenceModel[];
    private selectedItem : any;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;
    unsubscribe$ = new Subject();
    private formDetailCloseSubscription$: any;
    
    constructor(
        private actionbarService: ActionbarService,
        private alertService: ToastrService,
        public alertBarService: AlertBarService,
        private attributeMapperService: AttributeMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private calendarValidityReferenceService: CalendarValidityService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private domainAttributeService: DomainAttributeService,
        private focusingService: FocusingService,
        private hashTagMapperService: HashTagMapperService,
        private hashTagReferenceService: HashTagReferenceService,
        private locationGroupService: LocationGroupService,
        private mapperService: MapperService,
        private merchandizeService: MerchandizeService,
        private navigationService: NavigationService,
        private organisationService: OrganisationService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private productSearchFilterService: ProductSearchFilterService,
        private productTypeGroupService: ProductTypeGroupService,
        private regionReferenceService: RegionReferenceService,
        private restrictionMapperService: RestrictionMapperService,
        private select2DataMapperService: Select2DataMapperService,
        private spinnerService: LoadingSpinnerService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private validityMapperService: ValidityMapperService,
        private merchandizeModeHelperService: MerchandizeModeHelperService
        ) { }
    
    ngOnInit(): void {
        this.getCalendarValidityReferences();
        this.getConditionReferences();
        this.getCountryReferences();
        this.getDateTimeDimensionReferences();
        this.getDomainAttributeTypes();
        this.getHashTagReferences();
        this.getLocationGroups();
        this.getOrganisation();
        this.getOrganisationGroupReferences();
        this.getOrganisationTypeReferences();
        this.getOrganisationRoleReferences();
        this.getProductCategoryReferences();
        this.getProductGroupReferences();
        this.getProductLocationPointReferences();
        this.getProductLocationTypeReferences();
        this.getProductNumberTypeReferences();        
        this.getProductTypeGroups();
        this.getProviders();
        this.getRegionReferences();
        this.getStatusReferences();
        this.getSuppliers();
        this.getUsageTypeReference();
        this.setDefaultActionBarHandler();
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.defaultActionBarHandler);
        this.subscribeToActionBar();
        if (this.executeFlag == true) {
            setTimeout(() => {
                this.onSearchCondition(this.getDefaultProductSearchCondition());
            }, 500);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();

        if (this.formDetailCloseSubscription$) {
            this.formDetailCloseSubscription$.unsubscribe();
        }
    }
    
    setDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private getDefaultProductSearchCondition() {
        let view = new MerchandizeViewModel();
        view.productTypeCode = '';
        view.searchStatusCode = 'A';
        return view;
    }

    private getCalendarValidityReferences() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(MERCHANDIZE_REF_CODE.calendarValidityCode)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReferences$.next(responses);
                }
            )
    }

    private getConditionReferences() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReferences$.next(responses);
                }
            )
    }

    private getCountryReferences() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getDateTimeDimensionReferences() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReferences$.next(responses);
                }
            )
    }

    private getDomainAttributeTypes() {
        this.domainAttributeService.getByProductType(MERCHANDIZE_REF_CODE.attributeGroupCode, "", true)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    responses.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                    this.domainAttributeSearch$.next(responses);
                }
            )
    }

    private getLocationGroups() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(MERCHANDIZE_REF_CODE.activeStatus)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReferences() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReferences() {
        this.organisationTypeReferenceService.getByStatus(MERCHANDIZE_REF_CODE.activeStatus)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    private getProductCategoryReferences() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReferences$.next(responses);
                }
            )
    }

    private getProductGroupReferences() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    this.productGroupReferenceMerchandizes = responses.filter(x => x.productCategoryCode == MERCHANDIZE_REF_CODE.productCategoryCode);
                    this.productGroupReferenceMerchandizes$.next(this.productGroupReferenceMerchandizes);
                    this.productGroupReferences$.next(this.productGroupReferenceMerchandizes);
                    this.productSearchConditionComponent.productGroupReferences$.next(this.productGroupReferenceMerchandizes);
                }
            )
    }

    private getProductLocationPointReferences() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReferences$.next(responses);
                }
            )
    }

    private getProductSearchFilter() {
        return this.productSearchFilterService.getByProductCategory(MERCHANDIZE_REF_CODE.productCategoryCode)
            .pipe(map(res => res.map(r => new Select2Data(r.productId, r.productName))));
    }

    private getProductTypeGroups() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroups$.next(responses);
                    this.productSearchConditionComponent.productTypeGroups$.next(responses);
                }
            )
    }

    private getProviders() {
        this.organisationService.getByOrganisationType(MERCHANDIZE_REF_CODE.orgTypeProviderCode)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.providers$.next(responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
                }
            )
    }

    private getRegionReferences() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    private getHashTagReferences() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReferences = responses;
                    this.setProductHashTagValue(null);
                }
            )
    }

    private getProductLocationTypeReferences() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(MERCHANDIZE_REF_CODE.productLocationTypeCode)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        this.productLocationTypeReferences = new Array<ProductLocationTypeReferenceModel>();
                        this.productLocationTypeReferences.push(response);
                        this.productLocationTypeReferences$.next(this.productLocationTypeReferences);
                    }
                }
            )
    }

    private getProductNumberTypeReferences() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReferences$.next(responses);
                }
            )
    }

    private getSuppliers() {
        this.organisationService.getByOrganisationType(MERCHANDIZE_REF_CODE.orgTypeSupplierCode)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.suppliers$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.usageTypeReferences$.next(
                            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                                x.usageTypeCode == this.USAGETYPE_TEMPLATE));

                        this.usageTypeReferenceFilter$.next(
                            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
                    }
                }
            )
    }

    focusOnSearchCondition() {
        this.focusingService.focus(this.productSearchConditionComponent.focusingDirective);
    }

    focusOnFilterName() {
        this.focusingService.focus(this.filterNameComponent.focusingDirective);
    }

    focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    onAdvanceSearchChanged(value) {
        this.advanceSearchModeEnabled = value;
        this.showAdvanceSearchChanged.emit(value);
        if (value) {
            this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.editingNew);
        } else {
            this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.selected : ACTION_HANDLER_STATUS.none);
            this.alertBarService.hide();
        }
    }

    onSearchCondition(condition: MerchandizeViewModel, autoFocus: boolean = true) {
        this.currentConditionSearch = condition;
        if (!condition) {
            return;
        }
        this.searchCommand = this.mapperService.convertToSearchCommand(condition, 
            this.advanceSearchModeEnabled ? this.attributeAndRuleComponent : null, 
            this.productHashtagValueViews, 
            this.providers$);
        this.search(this.searchCommand);
        if (autoFocus) {
            this.focusOnSearchResult();
        }
        
    }

    private search(searchCommand: MerchandizeSearchCommandModel) {
        if (!this.searchCommand) {
            this.searchCommand = searchCommand
        }
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.merchandizeService.search(searchCommand)
            .subscribe(
                (responses: MerchandizeViewModel[]) => {
                    this.paggingView = new Array();
                    let searchResults = this.mapperService.MerchandizeSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                },
                () => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                }
            );
    }

    public onClearCondition() {
        this.clearSearchCondition();
    }

    private clearSearchCondition() {
        let condition = this.getDefaultProductSearchCondition();
        this.merchandizeView$.next(condition);
        this.productSearchFilters$ = this.getProductSearchFilter();
        this.setProductHashTagValue(null);
        this.filterNameComponent.completeProcessing();
        this.clearAdvanceSearchForm();
        this.selectedItem = null;
        this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editingNew : ACTION_HANDLER_STATUS.none);
        this.alertBarService.hide();
        this.productSearchResults$.next([]);
    }

    public onSearchResultTableRefresh() {
        if (this.currentConditionSearch != null) {
            this.onSearchCondition(this.currentConditionSearch);
        }
        this.loadingNotifier.show(this.SPINNER_NAME, 3000);
    }

    onProductSearchFilterIdChange(productId: string) {
        if (productId) {
            this.alertBarService.hide();
            this.getFilter(productId);
            this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.selected);
        } else {
            this.clearSearchCondition();
        }
    }

    subscribeToActionBar() {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                actionId => {
                    switch (actionId) {
                        case ACTION_STATUS.new:
                            this.onNewClick();
                            break;
                        case ACTION_STATUS.refresh:
                            this.clearSearchCondition();
                            this.onSearchCondition(this.getDefaultProductSearchCondition());
                            break;
                        case ACTION_STATUS.back:
                        case ACTION_STATUS.cancel:
                            this.cancelRequest.emit();
                            break;
                        case ACTION_STATUS.copy:
                            if (this.selectedItem) {
                                this.onCopyItem(this.selectedItem);
                            }
                            break;
                        case ACTION_STATUS.save:
                            this.onSaveFilterClick();
                            break;
                        case ACTION_STATUS.saveAs:
                            this.onSaveFilterClick(true);
                            break;
                        default:
                            break;
                    }
                }
            );
    }

    public onAlertNotifyClicked() {
        this.focusOnFilterName();
    }

    public onNewClick() {
        this.newProduct();
    }

    newProduct() {
        this.searchMode = false;
        this.loadDetailForm(null);
    }

    onSelectedProduct(data) {
        this.selectedItem = data;
        if (data) {
            if (data.usageTypeCode == this.FILTER_USAGE_TYPE) {
                if (data.statusCode == 'A') {
                    this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.selected);
                }
            }
        } else {
            this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editingNew : ACTION_HANDLER_STATUS.none);
        }
    }

    onEditProduct(data) {
        if (data.usageTypeCode == this.FILTER_USAGE_TYPE) {
            if (data.statusCode == 'A') {
                this.onProductSearchFilterIdChange(data.id);
                this.onAdvanceSearchChanged(true);
                this.focusOnSearchCondition();
                this.setActionBarMode(ACTION_HANDLER_STATUS.editing);
            }
        } else {
            this.searchMode = false;
            this.loadDetailForm(data.id);
        }
    }

    private copyProduct(productId) {
        this.loadDetailForm(productId, true);
    }

    private loadDetailForm(productId, copy: boolean = false) {
        const queryParams = {
            id: productId,
            copy: copy,
            tab: 'search',
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate('/main/product/merchandises/details', null, false, queryParams);
    }

    private formClose() {
        this.searchMode = true;
    }


    onSaveFilterClick(isSaveAs: boolean = false) {
        if (!this.validateAll()) {
            this.displayAlertBar();
            return;
        }

       let product = this.filterNameComponent.getSaveModel(isSaveAs);
       let condition = this.productSearchConditionComponent.getValues(true);
        if (!condition || !product) {
            return;
        }

        condition.productId = product.productId;
        condition.productName = product.productName;
        condition.isOwnerFilter = product.isOwnerFilter;
        condition.usageTypeCode = this.FILTER_USAGE_TYPE;
        if (!product.productId) {
            this.addNewFilter(condition);
        }
        else {
            this.updateFilter(condition);
        }
    }

    private validateAll(): boolean {

        return this.filterNameComponent.validateForm() && this.productSearchConditionComponent.validateForm();
    }


    public onSearchConditionFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.productSearchConditionComponent.getErrorMessageForm());
        }
    }

    public onFilterNameFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.filterNameComponent.getErrorMessageForm());
        }
    }

    private displayAlertBar() {
        if (!this.productSearchConditionComponent.validateForm()) {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.productSearchConditionComponent.getErrorMessageForm());
        } else if (!this.filterNameComponent.validateForm()) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.filterNameComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    onCopyItem(data) {
        let usageTypeCode = data.usageTypeCode;
        switch(usageTypeCode) {
            case this.FILTER_USAGE_TYPE:
                this.getFilter(data.id ?? data.productId, true);
                this.onAdvanceSearchChanged(true);
                this.setActionBarMode(ACTION_HANDLER_STATUS.new);
                this.focusOnSearchCondition();
                break;
            case this.USAGETYPE_TEMPLATE:
            case this.USAGETYPE_DATA:
                this.copyProduct(data.id);
                break;
        }
    }

    private setActionBarMode(mode: string = ACTION_HANDLER_STATUS.none) {
        let model = this.merchandizeModeHelperService.getActionBarHandlerModel(mode, true);
        this.currentActionBarMode = mode;
        if (mode == ACTION_HANDLER_STATUS.editing) {
            if (!this.merchandizeView$.value.isOwnerFilter) {
                model
                    .get(ACTION_STATUS.save)
                    .getDropdownBtn(ACTION_STATUS.saveAs)
                    .disable();
            } else {
                model
                    .get(ACTION_STATUS.save)
                    .getDropdownBtn(ACTION_STATUS.saveAs)
                    .enable(this.userSecurity?.newFlag ?? false);
            }
        }

        this.actionbarService.updateState(model);
    }

    private addNewFilter(condition: MerchandizeViewModel) {
        let command = this.mapperService.convertToAddCommand(condition, this.attributeAndRuleComponent, this.productHashtagValueViews, this.providers$);
        if (!command) {
            return;
        }
        this.spinnerService.showSaving();
        this.merchandizeService.addProduct(command)
            .subscribe(
                (response) => {
                    this.getFilter(response.body);
                    this.productSearchFilters$ = this.getProductSearchFilter();
                    this.setActionBarMode(ACTION_HANDLER_STATUS.editing);
                    this.spinnerService.saveComplete();
                    this.changeDetectionRef.detectChanges();
                },
                () => {
                    this.spinnerService.hide();
                    this.alertService.error(this.FAILED, this.ALERT_HEADER, { timeOut: 2000 });
                }
            )
    }

    private updateFilter(condition: MerchandizeViewModel) {
        let command = this.mapperService.convertToEditCommand(condition, this.attributeAndRuleComponent, this.productHashtagValueViews, this.providers$);
        if (!command) {
            return;
        }
        this.spinnerService.showSaving();
        this.merchandizeService.editProduct(command)
            .subscribe(
                (response) => {
                    this.getFilter(response.body);
                    this.productSearchFilters$ = this.getProductSearchFilter();
                    this.spinnerService.saveComplete();
                    this.onSearchResultTableRefresh();
                    this.changeDetectionRef.detectChanges();
                },
                () => {
                    this.spinnerService.hide();
                    this.alertService.error(this.FAILED, this.ALERT_HEADER, { timeOut: 2000 });
                }
            )
    }

    private getFilter(productId, copyAction: boolean = false) {
        if (productId) {
            this.spinnerService.show();
            this.merchandizeService.getByProductId(productId)
                .subscribe(
                    (response: MerchandizeViewModel) => {
                        this.selectedItem = response;
                        this.fillModelToCondition(response, copyAction);
                        this.setProductHashTagValue(response.productHashtags);
                        this.fillModelToProductNumber(response.productNumbers);
                        this.fillModelToProductRestriction(response.productRestrictionProducts,
                            response.productRestrictionProductNumbers,
                            response.productRestrictionRoutes,
                            response.productRestrictionLocations
                        );
                        
                        this.fillModelToProductAttribute(response.productInventoryDimensions, response.productAttributes, response.isHighestLevelAttrInv, response.draftFlag);
                        this.fillModelToProductValidity(response.productValidities);
                        this.fillModelToPointOfSales(response.productRestrictionOrganisations, response.productRestrictionLocations);
                         
                        if (this.currentActionBarMode == ACTION_HANDLER_STATUS.editing) {
                            // re-setup disables again
                            this.setActionBarMode(ACTION_HANDLER_STATUS.editing);
                        }
                        this.spinnerService.hide();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private fillModelToCondition(model: MerchandizeViewModel, copyAction: boolean = false) {
        let view = new MerchandizeViewModel();
        view.productId = copyAction ? null : model.productId;
        view.productGroupCode = model.productGroupCode;
        view.productTypeCode = model.productTypeCode;
        view.searchStatusCode = model.searchStatusCode;
        view.productName = model.productName + (copyAction ? '-copy' : '');
        view.searchName = model.searchName;
        view.searchUsageTypeCode = model.searchUsageTypeCode;
        view.isOwnerFilter = model.filterUserAccountId ? true : false;
        view.usageTypeCode = model.usageTypeCode;
        
        this.productSearchConditionComponent.filterProductTypeGroup(view.productGroupCode);
        this.merchandizeView$.next(view);
        this.changeDetectionRef.detectChanges();
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[]) {

        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations, null, false);

        let productRestrictionInheritViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations, null, true);

        this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent?.addRestrictionViewToFormGroup(productRestrictionViews);
        this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent?.addRestrictionInheritToFormGroup(productRestrictionInheritViews);
        this.attributeAndRuleComponent.restrictionRuleComponent.detectChanges();
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        let productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReferences, value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTags$.next(productHashtagViews);
    }

    private clearAdvanceSearchForm() {
        this.mapperService.clearAttributeAndRule(this.attributeAndRuleComponent);
        this.attributeAndRuleComponent?.clearForm();
        
    }

    public hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        this.attributeAndRuleComponent?.productNumberComponent?.addViewToFormGroup(productNumbers);
    }

    private fillModelToProductAttribute(productInventoryDimensions: ProductInventoryDimensionViewModel[],
        productAttributes: ProductAttributeViewModel[],
        isHighestProductInventoryLevel: boolean,
        draftFlag: boolean) {
        this.attributeAndRuleComponent.productAttributeComponent.productAttributes = this.attributeMapperService.productAttributesToForm(productAttributes, false);
        this.attributeAndRuleComponent.productAttributeComponent.productInventoryDimensionExist = productInventoryDimensions;
        this.attributeAndRuleComponent.productAttributeComponent.isHighestProductInventoryLevel = isHighestProductInventoryLevel;
        this.attributeAndRuleComponent.productAttributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.productAttributeComponent.addViewToFormGroup();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        var productValiditiesInherit = this.validityMapperService.productValiditiesToForm(productValidities, true);
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent?.addValidityInheritToFormGroup(productValiditiesInherit);
        this.attributeAndRuleComponent.validityRuleComponent.detectChanges();
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        let pointOfSalesInheritViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, true);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent?.addPointOfSalesInheritToFormGroup(pointOfSalesInheritViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }
}

import * as moment from 'moment';

export const select2DateType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2CalendarType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2TimeZone = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2CalendarOperator = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width :'auto',
    dropdownAutoWidth : true
}

export const validityDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    } 
};

export const validityDateTimeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment().startOf('hour'),
    endDate: moment().startOf('hour').add(32, 'hour'),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD HH:mm'
    },
    timePicker24Hour:true 
};

export const validityTimeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'HH:mm'
    },
    timePicker24Hour:true     
};

export const select2DayStart = {
    placeholder: "<Select Start Day>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme :"select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
};

export const select2DayEnd = {
    placeholder: "<Select End Day>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme :"select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
};


export const validityWeekDaysOption = {
    placeholder: "<Weekday>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const AreaConstant = {
    region: {
        id: 'REGION',
        text: 'Region'
    },
    country: {
        id: 'COUNTRY',
        text: 'Country'
    },
    airportOrCity: {
        id: 'AIRPORT_CITY',
        text: 'Airport/City'
    }
}

export const AreaData = [
    AreaConstant.region,
    AreaConstant.country,
    AreaConstant.airportOrCity
]
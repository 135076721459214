import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class LocationPointValidationService {

    public findDuplicateLocationPointRegion(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationPointAreas == "region") {
                    if (ctl.value.locationPoint == ctlCheck.value.locationPoint &&
                        ctl.value.locationPointOperator == ctlCheck.value.locationPointOperator &&
                        this.checkArrayEqual(ctl.value.locationPointValueRegion, ctlCheck.value.locationPointValueRegion)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationPointCountry(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationPointAreas == "country") {
                    if (ctl.value.locationPoint == ctlCheck.value.locationPoint &&
                        ctl.value.locationPointOperator == ctlCheck.value.locationPointOperator &&
                        this.checkArrayEqual(ctl.value.locationPointValueCountry, ctlCheck.value.locationPointValueCountry)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationPointAirport(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationPointAreas == "airport") {
                    if (ctl.value.locationPoint == ctlCheck.value.locationPoint &&
                        ctl.value.locationPointOperator == ctlCheck.value.locationPointOperator &&
                        this.checkArrayEqual(ctl.value.locationPointValueAirport, ctlCheck.value.locationPointValueAirport)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateLocationPointGroup(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "location" && ctl.value.locationPointAreas == "group") {
                    if (ctl.value.locationPoint == ctlCheck.value.locationPoint &&
                        ctl.value.locationPointOperator == ctlCheck.value.locationPointOperator &&
                        this.checkArrayEqual(ctl.value.locationPointValueGroup, ctlCheck.value.locationPointValueGroup)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './shared/layout/main/main.component';
import { LayoutModule } from './shared/layout/layout.module';
import { AuthComponent } from './modules/auth/auth.component';
import { OopsSolutionGuard } from './core/guards/oops-solution.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/main',
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/external-payment/external-payment.module').then((m) => m.ExternalPaymentModule)
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [OopsSolutionGuard],
    children: [
      {
        path: 'home/:tab',
        loadChildren: () =>
          import('./modules/home/home.module').then(
            (m) => m.HomeModule
          )
      },
      {
        path: 'activity/:tab',
        loadChildren: () =>
          import('./modules/activity/activity.module').then(
            (m) => m.ActivityModule
          )
      },
      {
        path: 'orders/:tab',
        loadChildren: () =>
          import('./modules/order/order.module').then(
            (m) => m.OrdersModule
          )
      },
      {
        path: 'orders/:tab/itinerary/:id',
        loadChildren: () =>
          import('./modules/order/order-detail/order-detail-itinerary/order-detail-itinerary.module').then(
            (m) => m.OrdersDetailItineraryModule
          ),
      },
      {
        path: 'orders/:tab/order-payment-invoice/:id',
        loadChildren: () =>
          import('./modules/order/order-detail/order-payment-invoice/order-payment-invoice.module').then(
            (m) => m.OrderDetailInvoiceModule
          ),
      },
      {
        path: 'orders/:tab/order-payment-receipt/:id',
        loadChildren: () =>
          import('./modules/order/order-detail/order-payment-receipt/order-payment-receipt.module').then(
            (m) => m.OrderDetailReceiptModule
          ),
      },
      {
        path: 'orders/:tab/order-payment-credit-note/:id',
        loadChildren: () =>
          import('./modules/order/order-detail/order-payment-credit-note/order-payment-credit-note.module').then(
            (m) => m.OrderDetailCreditNoteModule
          ),
      },
      {
        path: 'orders/:tab/itinerary/:id/:partnerId',
        loadChildren: () =>
          import('./modules/order/order-detail/order-detail-itinerary/order-detail-itinerary.module').then(
            (m) => m.OrdersDetailItineraryModule
          ),
      },
      {
        path: 'orders/:tab/itinerary/:id/:priceDetail',
        loadChildren: () =>
          import('./modules/order/order-detail/order-detail-itinerary/order-detail-itinerary.module').then(
            (m) => m.OrdersDetailItineraryModule
          ),
      },
      {
        path: 'orders/:tab/itinerary/:id/:partnerId/:priceDetail',
        loadChildren: () =>
          import('./modules/order/order-detail/order-detail-itinerary/order-detail-itinerary.module').then(
            (m) => m.OrdersDetailItineraryModule
          ),
      },
      {
        path: 'product/:tab',
        loadChildren: () =>
          import('./modules/product-management/product-management.module').then(
            (m) => m.ProductManagementModule
          ),
      },
      {
        path: 'pricing/:tab',
        loadChildren: () =>
          import('./modules/pricing/pricing.module').then(
            (m) => m.PricingModule
          ),
      },
      {
        path: 'organisation/:tab',
        loadChildren: () =>
          import('./modules/organisation/organisation.module').then(
            (m) => m.OrganisationModule
          ),
      },
      {
        path: 'insight/:tab',
        loadChildren: () =>
          import('./modules/insight-management/insight-management.module').then(
            (m) => m.InsightManagementModule
          ),
      },
      {
        path: 'operating-transports/:tab/schedule-queue-tasks/:id',
        loadChildren: () =>
          import('./modules/schedule/schedule-categories-content/schedule-queue/schedule-queue-content/operating-transport/operating-transport.module').then(
            (m) => m.OperatingTransportModule
          ),
      },
      {
        path: 'passengers/:tab/operating-products/:id',
        loadChildren: () =>
          import('./modules/schedule/schedule-categories-content/schedule-queue/schedule-queue-content/passenger/passenger.module').then(
            (m) => m.PassengerModule
          ),
      },
      {
        path: 'schedule/:tab',
        loadChildren: () =>
          import('./modules/schedule/schedule.module').then(
            (m) => m.ScheduleModule
        ),
      },
      {
        path: 'settings/:tab',
        loadChildren: () => import('./modules/settings-management/settings-management.module').then(m => m.SettingsManagementModule)
      },
      {
        path: 'user-account/:tab',
        loadChildren: () =>
          import('./modules/user-account/user-account.module').then(
            (m) => m.UserAccountModule
          ),
      },
      {
        path: 'user/:tab',
        loadChildren: () =>
          import(
            './modules/user-management/user-management.module'
          ).then((m) => m.UserManagementModule)
      },
      {
        path: 'transport/:tab',
        loadChildren: () =>
          import(
            './modules/transport/transport.module'
          ).then((m) => m.TransportModule)
      },
      {
        path: 'cashbook/:tab',
        loadChildren: () =>
          import(
            './modules/cashbook/cashbook.module'
          ).then((m) => m.CashbookModule)
      },
      {
        path: 'favorite/:tab',
        loadChildren: () =>
          import(
            './modules/favorite/favorite.module'
          ).then((m) => m.FavoriteModule)
      },
      {
        path: 'news/:tab',
        loadChildren: () =>
          import(
            './modules/news/news.module'
          ).then((m) => m.NewsModule)
      },
      {
        path: 'shopping/:tab',
        loadChildren: () =>
          import(
            './modules/shopping/shopping.module'
          ).then((m) => m.ShoppingModule)
      },
      {
        path: 'inventory/:tab',
        loadChildren: () =>
            import(
              './modules/inventory/inventory.module'
            ).then((m) => m.InventoryModule)
      },
      {
        path: 'operating-transports/:tab/rms/:id',
        loadChildren: () =>
          import('./modules/inventory/rms-export/rms-export-search/rms-operating-transport/rms-operating-transport.module').then(
            (m) => m.RmsOperatingTransportModule
          ),
      },
    ],
  }
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

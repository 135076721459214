import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Input, ViewChild } from "@angular/core";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { DocumentDistributionRuleView } from "./shared/document-distribution-rule.view";
import { DocumentDistributionRuleDetailComponent } from "./detail/rule-detail.component";
import { DocumentDistributionRuleTableComponent } from "./table/rule-table.component";
import { RuleDetailDirective } from "./detail/rule-detail.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { cloneDeep } from "lodash";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";

@Component({
    selector: 'op-document-distribution-rule',
    templateUrl: './document-distribution-rule.component.html'
})

export class DocumentDistributionRuleComponent {
    public focusing: boolean = false;
    public focused: boolean = false;

    public isCollapsedDetail: boolean = false;
    public isRuleEmpty: boolean = true;
    public isMoreThanOneRules: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;
    public rule = new DocumentDistributionRuleView();
    public rules: DocumentDistributionRuleView[] = new Array();
    public previousRules: DocumentDistributionRuleView[] = new Array();

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @Input() providerReferences: OrganisationModel[];  
    @Input() newDocumentDistribution: boolean = false;
    @Input() copyMode: boolean = false;
    @Input() actionSecurity: SecurityGroupSecurityModel;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @ViewChild(RuleDetailDirective) ruleDetailDirective: RuleDetailDirective;
    @ViewChild(DocumentDistributionRuleDetailComponent) ruleDetailComponent: DocumentDistributionRuleDetailComponent;
    @ViewChild(DocumentDistributionRuleTableComponent) ruleTableComponent: DocumentDistributionRuleTableComponent;
    @ViewChild("ruleDetail") detail: ElementRef;
    
    public newRuleDetail: DocumentDistributionRuleDetailComponent;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newDocumentDistribution) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef,
        private focusingService: FocusingService) {
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneRules = true;
        this.loadDetailForm();
        this.moveToDetailAndFocus();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: DocumentDistributionRuleView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(DocumentDistributionRuleDetailComponent);

        let viewContainerRef = this.ruleDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: DocumentDistributionRuleDetailComponent = (<DocumentDistributionRuleDetailComponent>componentRefs.instance);
        component.providerReferences = this.providerReferences;
        
        if (dataEdit != null) {
            component.rule = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newRuleDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    public fillModelToForm(rules: DocumentDistributionRuleView[]) {
        this.clearData();
        if (rules && rules?.length > 0) {
            this.fillModelInCase(rules);
        }

        this.rules = rules;
        this.previousRules = rules;

        if (this.rules?.length > 0) {
            this.isRuleEmpty = false;
        }

        if (this.rules?.length == 1) {
            this.isMoreThanOneRules = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.rule = new DocumentDistributionRuleView();
    }

    private fillModelInCase(Rules: DocumentDistributionRuleView[]) {
        if (Rules.length == 1) {
            this.fillModelSingleRecord(Rules[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(model: DocumentDistributionRuleView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.rule = model;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let distributionRule = this.ruleDetailComponent.getDocumentDistributionRule();
        if (distributionRule) {
            this.updateRequiredValue(distributionRule);
            this.savedata(distributionRule);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private displayAfterSave() {
        if (this.rules?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private savedata(data: DocumentDistributionRuleView) {
        if (this.rules?.length) {
            var filterIndex = this.rules.findIndex(x => x.no == data.no);

            this.updateRuleViews(filterIndex, data);
        }
        else {
            data.no = 1;
            this.rule = data;
            this.rules.push(data);
        }

        this.isRuleEmpty = false;
        this.ruleTableComponent.dataGrid.instance.refresh();
    }

    private updateRuleViews(filterIndex: number, data: DocumentDistributionRuleView) {
        if (filterIndex == -1) {
            data.no = this.rules.length + 1;
            this.rules.push(data);
        }
        else {
            this.rules[filterIndex] = data;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.ruleDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.rules.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onSaveNewRule() {
        this.isApplyBtnClicked = true;
        let rule = this.newRuleDetail.getDocumentDistributionRule();
        if (!rule) {
            return;
        }

        if (rule) {
            this.updateRequiredValue(rule);
            rule.originDataSave = this.getLocationSave(rule, true);
            rule.destinationDataSave = this.getLocationSave(rule, false);
            this.savedata(rule);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    public getLocationSave(view: DocumentDistributionRuleView, isOriginFlag: boolean) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (isOriginFlag && view.originId) {
            let data = new Select2Data(view.originId, view.originName);
            valueReturen.push(data);
            return valueReturen;
        }
        
        if (!isOriginFlag && view.destinationId) {
            let data = new Select2Data(view.destinationId, view.destinationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.rules.length == 0) {
            this.rule = new DocumentDistributionRuleView();
        }

        if (this.rules.length <= 1) {
            this.isMoreThanOneRules = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.ruleDetailComponent) {
            return this.ruleDetailComponent.setProcessing(false);
        }

        if (this.newRuleDetail) {
            return this.newRuleDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.rules.length == 1) {
            this.singleRecord = true;
        }
    }

    copy() {
        if (this.rules.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.rules.length == 1) {
            let copyAddress = cloneDeep(this.rules[0]);
            this.openCopyDetailForm(copyAddress);
            this.moveToDetailAndFocus();
            return;
        }

         if (!this.ruleTableComponent.selectedItem) {
            return;
        }

        let copyRule = cloneDeep(this.ruleTableComponent.selectedItem);
        this.openCopyDetailForm(copyRule);
        this.moveToDetailAndFocus();
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneRules = true;
    }

    openCopyDetailForm(copyRule: DocumentDistributionRuleView) {
        this.setCopyAddressProperty(copyRule);
        this.openDetail();
        this.loadDetailForm(copyRule);
    }

    setCopyAddressProperty(copyRule: DocumentDistributionRuleView) {
        copyRule.documentDistributionRuleId = null;
        copyRule.no = null;
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.ruleTableComponent.selectedItem);
        this.moveToDetailAndFocus();
    }

    private isSingleRecord(): boolean {
        if (this.rules.length == 0 || this.rules.length == 1) {
            return true;
        }
        return false;
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        this.deleteDataFromRuleTableViews(data);
        this.setMoreThanOneRules();
        this.setRuleIsEmpty();
    }

    getDeleteData(): DocumentDistributionRuleView {
        if (this.rules?.length > 1) {
            return this.ruleTableComponent.selectedItem;
        }

        return this.rule;
    }

    private setMoreThanOneRules() {
        if (this.rules?.length > 1) {
            this.isMoreThanOneRules = true;
        } 
        else {
            this.isMoreThanOneRules = false;
        }
    }

    private setRuleIsEmpty() {
        if (this.rules?.length > 0) {
            this.isRuleEmpty = false;
        } 
        else {
            this.isRuleEmpty = true;
        }
    }

    private deleteDataFromRuleTableViews(data: DocumentDistributionRuleView) {
        let views = new Array<DocumentDistributionRuleView>();
        if (this.rules != null) {
            views = this.rules;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.rules = views;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForRuleDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private setPropertyForRuleDetailForm() {
        if (this.rules.length == 1) {
            this.rule = this.rules[0];
            this.showAdd = true;
        }
        else {
            this.rule = new DocumentDistributionRuleView();
            this.showAdd = false;
        }
    }

    private updateRequiredValue(rule: DocumentDistributionRuleView) {
        if (rule.productNumberFrom && !rule.productNumberTo) {
            rule.productNumberTo = rule.productNumberFrom;
        }
        if (!rule.productNumberFrom && rule.productNumberTo) {
            rule.productNumberFrom = rule.productNumberTo;
        }
        if (rule.serviceDateFrom && !rule.serviceDateTo) {
            rule.serviceDateTo = rule.serviceDateFrom;
        }
        if (!rule.serviceDateFrom && rule.serviceDateTo) {
            rule.serviceDateFrom = rule.serviceDateTo;
        }
    }

    private moveToDetailAndFocus() {
        setTimeout(() => {
            this.scrollToElement(this.detail);
            this.focusingService.focus(this.newRuleDetail.focusingDirective);
        }, 100);
    }

    private scrollToElement(elementRef: ElementRef) {
        if (!elementRef)
            return

        const headerHeight = 125
        const paddingTop = 40

        setTimeout(() => {
            let el = elementRef['el']
            if (el == null)
                el = elementRef

            window.scrollTo({
                top: el.nativeElement.offsetTop - headerHeight - paddingTop,
                behavior: 'smooth'
            })
        }, 100)
    }
}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductSearchFilterModel } from "../../models/product-model/product-search-filter-model/product-search-filter-model";

@Injectable({
    providedIn: 'root'
})
export class ProductSearchFilterService extends ApiServiceBase {
    private readonly PRODUCT_PATH = "/product/searchfilters"
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getByProductCategory(productCategoryCode: string): Observable<ProductSearchFilterModel[]> {
        let params = new HttpParams()
            .set("productCategoryCode", productCategoryCode);

        let values = this.httpGet<ProductSearchFilterModel[]>(this.PRODUCT_PATH, params)
            .pipe(
                map(res => res.body)
            )
            return values;
    }
}
export const select2AttributeRoute = {
    placeholder: "<Attribute>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true,
    templateResult: null,
    templateSelection: null
}

export const select2AttributeRouteInherit = {
    placeholder: "<Attribute>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateResult: null,
    templateSelection: null
}

export const select2RouteOption = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    ajax: null,
    templateSelection: null
}

export const select2RouteOptionInherit = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateSelection: null
}

export const select2ChoiceMultiple = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AttributeDisabled = {
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    disabled: true
}

export const select2Dimension = {
    placeholder: "<Dimension>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
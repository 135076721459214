import { Injectable } from "@angular/core";

import { MediaCommandModel, MediaViewModel } from "src/app/core/models/media-model";
import { LanguageReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { TranslationTextView } from "./translation-text.view";
import { TranslationTextChildView } from "./translation-text-child.view";
import { MediaContentCommandModel, MediaContentViewModel } from "src/app/core/models/media-model/media-content";

@Injectable()
export class TranslationTextMapperService {

    constructor() {
    }

    public mediaToTranslationTextViews(models: MediaViewModel[]): TranslationTextView[] {
        let views: TranslationTextView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                var mediaContentPrimary = this.findMediaContentPrimaryLanguagemodel(model);
                if (mediaContentPrimary) {
                    if (mediaContentPrimary.mediaText) {
                        ++i;
                        views.push(this.mediaToTranslationTextView(i, model));
                    }
                }
            }
        }
        return views;
    }

    private mediaToTranslationTextView(i: number, model: MediaViewModel): TranslationTextView {
        let view = new TranslationTextView();
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaTypeName;
        if (model.mediaContents) {
            var filter = model.mediaContents.filter(x => x.primaryLanguageFlag == true);
            if (filter.length > 0) {
                view.text = filter[0].mediaText;
            }
        }
        return view;
    }

    public mediaToTranslationTextChildViews(models: MediaViewModel[],
        languageReferences: LanguageReferenceModel[]): TranslationTextChildView[] {
        let views: TranslationTextChildView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                var primaryLanguageCode = this.findMediaContentPrimaryLanguage(model);
                for (let language of languageReferences) {
                    if (language.languageCode != primaryLanguageCode) {
                        ++i
                        views.push(this.mediaToTranslationTextChildView(i, model, language));
                    }
                };
            }
        }
        return views;
    }

    private mediaToTranslationTextChildView(i: number, model: MediaViewModel, language: LanguageReferenceModel): TranslationTextChildView {
        let view = new TranslationTextChildView();
        view.selected = false;
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        view.languageCode = language.languageCode
        view.languageName = language.languageName
        view.mediaTypeCode = model.mediaTypeCode;
        view.text = "";
        if (model.mediaContents) {
            var filterTranslationText = model.mediaContents.filter(x => x.languageCode == language.languageCode);
            if (filterTranslationText.length > 0) {
                view.mediaContentId = filterTranslationText[0].mediaContentId;
                view.text = filterTranslationText[0].mediaText;
            }
        }
        return view;
    }

    private findMediaContentPrimaryLanguage(model: MediaViewModel): string {
        if (model.mediaContents) {
            var filter = model.mediaContents.filter(x => x.primaryLanguageFlag == true);
            if (filter.length > 0) {
                return filter[0].languageCode;
            }
        }
    }

    public displayTextMediaInGrid(text: string) {
        if (text) {
            let textReturn = text.substring(0, 255).replace(/(?:\r\n|\r|\n)/g, "<br>");
            if (text.length > 255) {
                textReturn += " ...";
            }
            return textReturn;
        }
    }

    public textTranslationToMediaModels(textTranslations: TranslationTextChildView[], medias: MediaCommandModel[]): MediaCommandModel[] {
        if (medias) {
            for (let media of medias) {
                var mediaId = this.getMediaId(media);
                if (textTranslations){
                    var filterMedia = textTranslations.filter(x => x.mediaId == mediaId && x.text.trim() != "");
                    if (filterMedia.length > 0) {
                        media.mediaContents = this.textTranslationToMediaContentModels(filterMedia, media);
                    }
                }
            }
        }
        return medias;
    }

    private getMediaId(media: MediaCommandModel): string {
        if (media.mediaId) {
            return media.mediaId;
        }
        else {
            return media.mediaTranslationId;
        }
    }

    private textTranslationToMediaContentModels(textTranslations: TranslationTextChildView[], media: MediaCommandModel): MediaContentCommandModel[] {
        if (textTranslations) {
            for (let textTranslation of textTranslations) {
                media.mediaContents.push(this.textTranslationToMediaContentModel(textTranslation, media.mediaTranslationId))
            }
        }
        return media.mediaContents;
    }

    private textTranslationToMediaContentModel(textTranslation: TranslationTextChildView, mediaTranslationId: string): MediaContentCommandModel {
        let model = new MediaContentCommandModel();
        if (textTranslation) {
            if (textTranslation.mediaContentId && !mediaTranslationId) {
                model.mediaContentId = textTranslation.mediaContentId;
            }
            model.languageCode = textTranslation.languageCode;
            model.mediaText = textTranslation.text;
            model.primaryLanguageFlag = false;
        }
        return model;
    }

    public mediaTextLibraryToTranslationTextViews(models: MediaViewModel[], mediaTranslationTexts: TranslationTextView[]): TranslationTextView[] {
        if (models) {
            let i = mediaTranslationTexts.length;
            for (let model of models) {
                ++i;
                mediaTranslationTexts.push(this.mediaToTranslationTextView(i, model));
            }
        }
        return mediaTranslationTexts;
    }

    public mediaTextToTranslationTextChildViews(models: MediaViewModel[], languageReferences: LanguageReferenceModel[],
        mediaTranslationChildTexts: TranslationTextChildView[]): TranslationTextChildView[] {
        if (models) {
            let i = mediaTranslationChildTexts.length;
            for (let model of models) {
                var primaryLanguageCode = this.findMediaContentPrimaryLanguage(model);
                for (let language of languageReferences) {
                    if (language.languageCode != primaryLanguageCode) {
                        ++i
                        mediaTranslationChildTexts.push(this.mediaToTranslationTextChildView(i, model, language));
                    }
                };
            }
        }
        return mediaTranslationChildTexts;
    }

    public mediaTextLibraryDeleteTranslationTextViews(mediaId: string, mediaTranslationTexts: TranslationTextView[]): TranslationTextView[] {
        if (mediaId) {
            var mediaDelete = mediaTranslationTexts.filter(x => x.mediaId == mediaId);
            if (mediaDelete.length > 0) {
                mediaTranslationTexts.splice(mediaTranslationTexts.indexOf(mediaDelete[0]), 1);
                let i = 0;
                for (let media of mediaTranslationTexts) {
                    ++i;
                    media.no = i;
                }
            }
        }
        return mediaTranslationTexts;
    }

    public mediaTextLibraryDeleteTranslationChildTextViews(mediaId: string, mediaTranslationChildTexts: TranslationTextChildView[]): TranslationTextChildView[] {
        if (mediaId) {
            var mediaDeletes = mediaTranslationChildTexts.filter(x => x.mediaId == mediaId);
            for (let mediaDelete of mediaDeletes) {
                mediaTranslationChildTexts.splice(mediaTranslationChildTexts.indexOf(mediaDelete), 1);
            }
            let i = 0;
            for (let media of mediaTranslationChildTexts) {
                ++i;
                media.no = i;
            }
        }
        return mediaTranslationChildTexts;
    }

    private findMediaContentPrimaryLanguagemodel(model: MediaViewModel): MediaContentViewModel {
        if (model.mediaContents) {
            var filter = model.mediaContents.filter(x => x.primaryLanguageFlag == true);
            if (filter.length > 0) {
                return filter[0]
            }
        }
    }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import {
    NodeIdValueModel,
    OrderAmountModel,
    OrderDataModel,
    OrderJsonModel,
    OrderPartnerNameModel,
    OrderProductDateTimeModel,
    OrderProductNumberModel,
    OrderProductPointModel
} from 'src/app/core/models/order-model/order-json';
import { OrderPaymentModel } from 'src/app/core/models/order-model/order-json/order-payment.model';
import { AmountModel, LedgersModel, PaymentsModel } from 'src/app/core/models/payment-models';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { USER_INFORMATION, USER_LOGON_NAME } from 'src/app/modules/auth/shared/constants/auth.constant';
import { OrderPendingPaymentTableView } from 'src/app/modules/order/order-detail/order-pending-payment/order-pending-payment-table.view';
import { selectAuthState } from 'src/app/store/auth/auth.selectors';
import { FormOfPayment, OrderPaymentItemType, OrdersConstant, PaymentConstant, ProductCategoryConstant } from '../../../order/shared/order.constants';
import {
    OrderShoppingPaymentTreeView,
    OrderShoppingPaymentView
} from '../views';
import { ShoppingPriceDetailView } from '../views/shopping-individual-price-detail.view';
import { PriceDetailView } from '../views/shopping-price-detail.view';
import { OrderAmountTypeCode, OrderSalesStatusCode, OrderStateCode } from "src/app/core/constants/order-constants";
import { ProductNumberType } from '../constants/shopping-constants';

@Injectable()
export class OrderShoppingPaymentMapperService {

    private readonly PRODUCT_SEAT_CODE: string = "SEAT";
    private readonly PRODUCT_XBAG_CODE: string = "XBAG";
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public readonly PAYMENT_CONSTANT = PaymentConstant;
    private readonly TOTAL_PRICE_CODE: string = "TOTAL";
    private readonly PRICE_ORDER_AMOUNT_TYPE_NAME: string = 'Price';
    private readonly EMPTY_STRING: string = "";
    private readonly ORDER_PAYMENT_STATUS_PAID = "PAID";
    private readonly VOUCHER_ICONS = {
        "DISCOUNT": "percent",
        "EXCHANGE": "currency_exchange",
        "GIFT": "redeem",
        "PROMOTION": "credit_card_heart"
    }

    constructor(private stringUtils: StringHelperService,
        private dateConversion: DateConverterService,
        private authService: AuthService,
        private store: Store<any>) {

    }
    public toOrderPaymentTreeView(model: OrderJsonModel): OrderShoppingPaymentTreeView[] {
        const itemUnitCode: string = "ITEM";

        if (!model) {
            return null;
        }

        let views: OrderShoppingPaymentTreeView[] = [];

        const orders = model.oopsOrder?.order;
        if (!orders?.length) return null;

        const orderStateItems = orders.filter(o => o.orderState?.code == itemUnitCode);
        if (!orderStateItems?.length) return null;

        for (const orderStateItem of orderStateItems) {
            const itemIndex = orders.findIndex(o => o == orderStateItem);

            this.fillTotalToHierarchy(model.oopsOrder.order, itemIndex, views);
            this.fillFlightRelatedToHierarchy(orders, orderStateItem, model, itemIndex, views);
            this.fillProductRelatedToHierarchy(orders, orderStateItem, views);
        }
        if (this.isFlightHierarchy(orders)) {
            this.correctFlightAmount(views);
        }
        return views;
    }

    private fillProductRelatedToHierarchy(orders: OrderDataModel[], orderStateItem: OrderDataModel, views: OrderShoppingPaymentTreeView[]) {
        const orderStateProductsLevelItem = orders
            .filter(o => o.parentOrderId == orderStateItem.orderId && o.orderState?.code == OrderStateCode.Product);
        const orderStateProductsLevelHeader = orderStateProductsLevelItem
            ?.map(oh => orders.find(o => o.orderId == oh.referenceOrderId));
        const orderStateProductsLevelHeaderNotTransport = orderStateProductsLevelHeader?.filter(o => !this.transportNode(o));
        if (!orderStateProductsLevelHeaderNotTransport?.length) return;
        const orderStateProductsLevelItemNotTransport = orderStateProductsLevelHeaderNotTransport
            .map(o => orderStateProductsLevelItem.find(oi => oi.referenceOrderId == o.orderId));
        this.fillProductToHierarchy(orderStateProductsLevelItemNotTransport, orders, views);
    }

    private fillFlightRelatedToHierarchy(orders: OrderDataModel[],
        orderStateItem: OrderDataModel,
        model: OrderJsonModel,
        itemIndex: number,
        views: OrderShoppingPaymentTreeView[]) {
        const orderStateProductsLevelItem = orders
            .filter(o => o.parentOrderId == orderStateItem.orderId && o.orderState?.code == OrderStateCode.Product);
        const orderStateProductsLevelHeader = orderStateProductsLevelItem
            ?.map(oh => orders.find(o => o.orderId == oh.referenceOrderId));
        const containProductTransport = orderStateProductsLevelHeader?.some(o => this.transportNode(o));
        if (!containProductTransport) return;
        this.fillFlightToHierarchy(model.oopsOrder.order, views);
        this.fillIndividualToHierarchy(model.oopsOrder.order, itemIndex, views);
        this.fillServicesToHierarchy(model.oopsOrder.order, views);
    }

    private fillHierarchyViews(orderDataModel: OrderDataModel,
        referenceNode: OrderDataModel,
        hierarchyId: string,
        parentHierarchyId: string,
        saleAmount: number,
        paymentAmount: number,
        views: OrderShoppingPaymentTreeView[],
        summaryStatus: number,
        selectable: boolean,
        numberOfUnits: number,
        type: OrderPaymentItemType): void {
        saleAmount = saleAmount ? saleAmount : 0;
        paymentAmount = paymentAmount ? paymentAmount : 0;
        const outstandingBalance = saleAmount - paymentAmount;
        const paid = (saleAmount > 0 && outstandingBalance == 0) || saleAmount == 0;
        const payable = type != OrderPaymentItemType.Product && !paid;
        views.push({
            hierarchyId,
            parentHierarchyId,
            orderId: orderDataModel.orderId,
            parentOrderId: (views.length === 0) ? null : orderDataModel.parentOrderId,
            referenceOrderId: orderDataModel.referenceOrderId,
            display: this.getPaymentTreeDisplay(views, referenceNode),
            productDateTime: this.getProductDateTime(referenceNode),
            orderSalesStatus: (!saleAmount) ? "" : this.getOrderSalesStatus(orderDataModel, referenceNode),
            orderPaymentStatus: (!saleAmount) ? "" : orderDataModel.orderPaymentStatus?.code,
            orderDeliveryStatus: (!saleAmount) ? "" : orderDataModel.orderDeliveryStatus?.code,
            currencyName: (!saleAmount) ? "" : (views.length === 0) ? referenceNode.currency.name 
                : orderDataModel.salesCurrency?.name ?? orderDataModel.sales?.currency,
            currencyCode: (!saleAmount) ? "" : (views.length === 0) ? referenceNode.currency?.code
                : orderDataModel.salesCurrency?.code ?? orderDataModel.sales?.currency,
            salesAmount: saleAmount.toString(),
            paymentAmount: paymentAmount.toString(),
            outstandingAmount: (outstandingBalance === 0) ? "" : outstandingBalance.toString(),
            icon: this.getPaymentDisplayIcon(referenceNode),
            summaryStatus,
            numberOfUnits: numberOfUnits,
            selectable: selectable,
            type: type,
            payFlag: false,
            payable: payable,
            paid: paid
        })
    }

    private getOrderSalesStatus(orderDataModel: OrderDataModel,
        referenceNode: OrderDataModel): string {
            if (this.salesNode(orderDataModel)) {
                return referenceNode.orderSalesStatus?.code;
            }
            return orderDataModel.orderSalesStatus?.code;
        }

    private getPaymentDisplayIcon(orderDataModel: OrderDataModel) {

        if (this.transportNode(orderDataModel)) {
            return "flight";
        } else if (this.individualNode(orderDataModel)) {
            return "group";
        } else if (this.noneTransportProductNode(orderDataModel, "SEAT")) {
            return "airline_seat_recline_extra";
        } else if (this.noneTransportProductNode(orderDataModel, "XBAG")) {
            return "luggage";
        } else if (this.serviceNode(orderDataModel)) {
            return "room_service";
        } else if (this.voucherNode(orderDataModel)) {
            const groupCode = orderDataModel?.orderProduct?.[0]?.productGroup?.code;
            if (!groupCode) return null;
            return this.VOUCHER_ICONS[groupCode];
        }
        return "payments";
    }
    private fillTotalToHierarchy(orderDataModels: OrderDataModel[],
        firstIndex: number,
        views: OrderShoppingPaymentTreeView[]): void {

        const orderHeaderIndex: number = 0;
        const saleAmount = orderDataModels[orderHeaderIndex]?.sales?.amount;
        const paymentAmount = orderDataModels[orderHeaderIndex]?.payment?.amount;
        const summaryStatus = 1;
        const orderDataModel = orderDataModels[firstIndex];
        this.fillHierarchyViews(orderDataModel,
            orderDataModels[orderHeaderIndex],
            orderDataModel.orderId,
            null,
            saleAmount,
            paymentAmount,
            views,
            summaryStatus,
            false,
            null,
            OrderPaymentItemType.Total);
        this.fillPointOfSalesInfo(orderDataModels, views);
    }

    private fillPointOfSalesInfo(orderDataModels: OrderDataModel[], views: OrderShoppingPaymentTreeView[]) {
        const pos = orderDataModels?.find(o => o.orderState?.code == OrderStateCode.PointOfSales)
        const total = views?.find(v => v.type == OrderPaymentItemType.Total);
        this.changeTotalDisplayToOrderNumber(pos, total);
        this.fillRecordLocatorInfo(pos, total);
    }

    private fillRecordLocatorInfo(pos: OrderDataModel, total: OrderShoppingPaymentTreeView) {
        const recordLocator = pos?.orderPointOfSales?.find(p => p.orderNumberType?.code == 'RLOC');
        total.bookingReference = recordLocator?.orderNumber;
        total.organisation = recordLocator?.organisation?.name;
    }

    private changeTotalDisplayToOrderNumber(pos: OrderDataModel, total: OrderShoppingPaymentTreeView) {
        if (!total) return;
        const orderNumberDisplay = pos?.orderPointOfSales
            ?.find(opos => opos.orderNumberType?.code == 'ORDER')
            ?.orderNumberDisplay;
        if (orderNumberDisplay) {
            total.display = orderNumberDisplay;
        }
    }

    private fillFlightToHierarchy(orderDataModels: OrderDataModel[],
        views: OrderShoppingPaymentTreeView[]): void {
        const summaryStatus = 1;
        const item = orderDataModels.find(o => o.orderState?.code == OrderStateCode.Item);
        const products = orderDataModels.filter(o => o.orderState?.code == OrderStateCode.Product
            && o.parentOrderId == item.orderId);
        if (!products?.length) return;
        for (const flightOrder of products) {
            const referenceNode = this.getReferenceNode(orderDataModels, flightOrder);
            if (!this.transportNode(referenceNode)) {
                continue;
            }
            const saleAmount = flightOrder?.sales?.amount;
            const paymentAmount = flightOrder?.payment?.amount;
            let hierarchyId: string = "";
            hierarchyId = this.generateHierrarchyId(orderDataModels, flightOrder, hierarchyId);
            this.fillHierarchyViews(flightOrder,
                referenceNode,
                hierarchyId,
                flightOrder.parentOrderId,
                saleAmount,
                paymentAmount,
                views,
                summaryStatus,
                false,
                null,
                OrderPaymentItemType.Flight);
        }

        views = views.sort((a, b)=> this.sortByDateTime(a.productDateTime, b.productDateTime));
    }
    private fillProductToHierarchy(
        orderStateProducts: OrderDataModel[],
        orderDataModels: OrderDataModel[],
        views: OrderShoppingPaymentTreeView[]): void {
        const summaryStatus = 1;
        for (const order of orderStateProducts) {
            const sales = orderDataModels
                .find(o => o.parentOrderId == order.orderId && o.orderState?.code == OrderStateCode.Sales);
            if (!sales) continue;
            const referenceNode = this.getReferenceNode(orderDataModels, order);
            const saleAmount = order?.sales?.amount;
            const paymentAmount = order?.payment?.amount;
            let hierarchyId: string = "";
            hierarchyId = this.generateHierrarchyId(orderDataModels, order, hierarchyId);
            const cloneOrder = Object.assign({}, order);
            cloneOrder.orderId = sales.orderId;
            this.fillHierarchyViews(cloneOrder,
                referenceNode,
                hierarchyId,
                order.parentOrderId,
                saleAmount, paymentAmount,
                views,
                summaryStatus,
                false,
                order.numberOfUnits,                                                                                                                                          
                OrderPaymentItemType.Product);
        }

        this.makeProductsPayable(views);
        views = views.sort((a, b)=> this.sortByDateTime(a.productDateTime, b.productDateTime));
    }

    private makeProductsPayable(views: OrderShoppingPaymentTreeView[]) {
        const isTransportPayment = views?.some(view => view.type == OrderPaymentItemType.Flight);
        if (isTransportPayment) return;

        for (const view of views) {
            if (view.type == OrderPaymentItemType.Product && Number(view.outstandingAmount ?? 0) > 0) {
                view.payable = true;
            }
        }
    }

    private sortByDateTime(date1: Date, date2: Date): number {
        if (date1 > date2) {
            return 1;
        }
        if (date1 == date2) {
            return 0;
        }
        return -1;
    }

    private fillIndividualToHierarchy(orderDataModels: OrderDataModel[],
        firstIndex: number,
        views: OrderShoppingPaymentTreeView[]): void {
        const summaryStatus = 0;
        for (let i = firstIndex; i < orderDataModels.length; i++) {
            const sales = orderDataModels[i];
            const individual = orderDataModels.find(x => x.orderId === sales.parentOrderId);
            const individualParent = orderDataModels.find(x => x.orderId === individual.parentOrderId);
            let parentReferenceNode = (individualParent) ? this.getReferenceNode(orderDataModels, individualParent) : null;
            
            if (!this.individualNode(individual) || !this.transportNode(parentReferenceNode) || sales.orderState.code != OrderStateCode.Sales) {
                continue;
            }
            const saleAmount = sales?.sales?.amount;
            const paymentAmount = sales?.payment?.amount;
            let hierarchyId: string = "";
            hierarchyId = this.generateHierrarchyIndividualId(orderDataModels, individual, hierarchyId);
            const splitHierarchyId = hierarchyId.split("_");
            hierarchyId = individual.orderId;
            const partner = orderDataModels.find(o => o.orderId == individual.referenceOrderId);
            const amountClone = Object.assign({}, sales);
            amountClone.referenceOrderId = partner.orderId;
            this.fillHierarchyViews(amountClone,
                individual,
                hierarchyId,
                splitHierarchyId[0],
                saleAmount,
                paymentAmount,
                views,
                summaryStatus,
                true,
                null,
                OrderPaymentItemType.Individual);
        }
    }
    private fillServicesToHierarchy(orderDataModels: OrderDataModel[],
        views: OrderShoppingPaymentTreeView[]): void {
        const transports = orderDataModels.filter(o => this.transportNode(o))
        for (const transport of transports) {
            const transportView = views.find(view => view.referenceOrderId == transport.orderId);
            const individualTransportViews = views.filter(view => transportView.hierarchyId == view.parentHierarchyId);
            const transportItem = orderDataModels.find(o => o.referenceOrderId == transport.orderId);
            const productItems = orderDataModels.filter(o => o.parentOrderId == transportItem.orderId
                && o.orderState?.code == OrderStateCode.Product);
            let productsorted = this.sortServiceProduct(orderDataModels, productItems)
            for (const productItem of productsorted) {
                const productHeader = orderDataModels.find(o => o.orderId == productItem.referenceOrderId);
                const { display, icon } = this.getServiceDisplayIcon(productHeader);
                const individuals = orderDataModels.filter(o => o.parentOrderId == productItem.orderId);
                const saleAmount = productItem.sales?.amount;
                var paymentAmount = productItem.sales?.amount;
                if (productItem.orderPaymentStatus?.code != this.ORDER_PAYMENT_STATUS_PAID) {
                    paymentAmount = 0;
                }
                const outstandingBalance = saleAmount - paymentAmount;
                for (const individual of individuals) {
                    const partner = orderDataModels.find(o => o.orderId == individual.referenceOrderId);
                    const individualTransportView = individualTransportViews.find(i => i.referenceOrderId == partner.orderId);
                    const productProduct = orderDataModels.find(o => o.orderId == productItem.parentOrderId);
                    const productIndividual = orderDataModels.find(o => o.parentOrderId == productProduct.orderId &&
                        o.referenceOrderId == individual.referenceOrderId);
                    const salesIndividual = orderDataModels.find(o => o.parentOrderId == productIndividual.orderId &&
                        o.orderState?.code == OrderStateCode.Sales);
                    const salesProduct = orderDataModels.find(o => o.parentOrderId == individual.orderId);
                    const paid = (salesProduct ?? salesIndividual)?.orderPaymentStatus?.code == this.ORDER_PAYMENT_STATUS_PAID;
                    const payable = !!salesProduct && !paid;
                    views.push({
                        hierarchyId: `${productItem.orderId}_${individual.orderId}`,
                        parentHierarchyId: individualTransportView.hierarchyId,
                        orderId: salesProduct?.orderId,
                        parentOrderId: individual.parentOrderId,
                        referenceOrderId: individual.referenceOrderId,
                        orderSalesStatus: productItem.orderSalesStatus?.code,
                        orderPaymentStatus: productItem.orderPaymentStatus?.code,
                        orderDeliveryStatus: null,
                        currencyName: productItem.sales?.currency,
                        currencyCode: productItem.sales?.currency,
                        salesAmount: this.stringUtils.validateNullAmountDecimal(saleAmount),
                        paymentAmount: this.stringUtils.validateNullAmountDecimal(paymentAmount),
                        outstandingAmount: this.stringUtils.validateNullAmountDecimal(outstandingBalance),
                        display: display,
                        icon: icon,
                        summaryStatus: null,
                        productDateTime: null,
                        numberOfUnits: productItem?.numberOfUnits ?? 1,
                        selectable: false,
                        type: OrderPaymentItemType.Product,
                        payFlag: false,
                        payable: payable,
                        paid: paid
                    })
                }
            }
        }
    }

    private sortServiceProduct(orderDataModels: OrderDataModel[], productItems :OrderDataModel[]) {
        let productHeaders = productItems.map(item => orderDataModels.find(o => o.orderId == item.referenceOrderId));
        let sortedGroupedProduct = this.sortServiceProductByProductCategory(productHeaders);
        return sortedGroupedProduct.map(product => {
            let productItem = productItems.find(item => item.referenceOrderId == product.orderId);
            let index = productItems.indexOf(productItem);
            productItems.splice(index, 1);
            return productItem;
        });
    }

    private sortServiceProductByProductCategory(productHeaders: OrderDataModel[]) : OrderDataModel[] {
        let groupedProduct = productHeaders.reduce((groups, item) => {
            if (item.orderProduct?.[0]?.productCategory.sequence) {
                (groups[item.orderProduct?.[0]?.productCategory.sequence] ||= []).push(item);
                return groups;
            }
            (groups[item.orderProduct?.[0]?.productCategory.name] ||= []).push(item);
            return groups;
        }, {})
        let keys = Object.keys(groupedProduct);
        for (let group of Object.keys(groupedProduct)) {
            groupedProduct[group] = groupedProduct[group].sort((a, b) => a.orderProduct?.[0]?.productCategory.name < b.orderProduct?.[0]?.productCategory.name ? -1 : 1);
            groupedProduct[group] = this.sortServiceProductByProductGroup(groupedProduct[group]);
        }
        return keys.reduce((list, key) => {
            list.push(groupedProduct[key]);
            return list.flat();
        }, [])
    }

    private sortServiceProductByProductGroup(productHeaders: OrderDataModel[]) : OrderDataModel[] {
        let groupedProduct = productHeaders.reduce((groups, item) => {
            if (item.orderProduct?.[0]?.productGroup.sequence) {
                (groups[item.orderProduct?.[0]?.productGroup.sequence] ||= []).push(item);
                return groups;
            }
            (groups[item.orderProduct?.[0]?.productGroup.name] ||= []).push(item);
            return groups;
        }, {})
        let keys = Object.keys(groupedProduct);
        for (let group of keys) {
            groupedProduct[group] = groupedProduct[group].sort((a, b) => a.orderProduct?.[0]?.productGroup.name < b.orderProduct?.[0]?.productGroup.name ? -1 : 1);
            this.sortServiceProductByProductName(groupedProduct[group]);
        }
        return keys.reduce((list, key) => {
            list.push(groupedProduct[key]);
            return list.flat();
        }, [])
    }

    private sortServiceProductByProductName(productHeaders: OrderDataModel[]) {
        return productHeaders.sort((a, b) => a.orderProduct?.[0]?.orderProductName < b.orderProduct?.[0]?.orderProductName ? -1 : 1);
    }

    private getPaymentTreeDisplay(views: OrderShoppingPaymentTreeView[],
        parentNode: OrderDataModel): string {

        if (views.length === 0) {  
            return "Total"
        }
        if (this.transportNode(parentNode)) {
            return this.getTransportDescription(parentNode);
        } else if (this.individualNode(parentNode)) {
            return this.getPartnerDescription(parentNode);
        } else if (this.noneTransportProductNode(parentNode, "SEAT")) {
            return this.getSeatDescription(parentNode);
        } else if (this.noneTransportProductNode(parentNode, "XBAG")) {
            return this.getBagDescription(parentNode);
        } else if (this.serviceNode(parentNode)) {
            return this.getServiceDescription(parentNode);
        } else if (this.voucherNode(parentNode)) {
            return this.getVoucherDescription(parentNode);
        }
        return null;
    }

    private getVoucherDescription(node: OrderDataModel): string {
        return node?.orderProduct?.[0]?.orderProductName;
    }

    private getProductDateTime(parentNode: OrderDataModel): Date {
        if (!this.transportNode(parentNode)) {
            return null;
        }

        let orderTransport = parentNode.orderProduct[0];
        let earlyProductDateTime = orderTransport.orderProductDateTime
            .reduce((a, b) => a.localDateTime < b.localDateTime ? a : b);

        return earlyProductDateTime.localDateTime;
    }

    private transportNode(orderData: OrderDataModel): boolean {
        const transportCode: string = "TRANSPORT";
        if (!orderData) {
            return false;
        }
        if (!orderData?.orderProduct || orderData.orderProduct.length === 0) {
            return false;
        }
        const cancelledFlight = orderData.orderSalesStatus?.code == OrderSalesStatusCode.Cancel;
        return orderData.orderProduct[0].productCategory.code === transportCode && !cancelledFlight;
    }

    private voucherNode(orderData: OrderDataModel): boolean {
        const voucherCode: string = "VOUCHER";
        if (!orderData) {
            return false;
        }
        if (!orderData?.orderProduct || orderData.orderProduct.length === 0) {
            return false;
        }
        return orderData.orderProduct[0].productCategory.code === voucherCode;
    }

    private noneTransportProductNode(orderData: OrderDataModel, productTypeCode: string): boolean {
        const ancillaryCode: string = "ANCILLARY";
        if (!orderData) {
            return false;
        }
        if (!orderData?.orderProduct || orderData.orderProduct.length === 0) {
            return false;
        }
        return orderData.orderProduct[0].productCategory?.code === ancillaryCode
            && orderData.orderProduct[0].productType?.code === productTypeCode;
    }

    private serviceNode(orderData: OrderDataModel): boolean {
        const serviceCode: string = "SERVICE";

        if (!orderData) {
            return false;
        }
        if (!orderData?.orderService || orderData.orderService.length === 0) {
            return false;
        }
        return orderData.orderState.code === serviceCode;
    }

    private salesNode(orderData: OrderDataModel): boolean {
        if (!orderData) {
            return false;
        }
        if (!orderData?.orderAmount || orderData.orderAmount.length === 0) {
            return false;
        }
        return orderData.orderState.code === OrderStateCode.Sales;
    }

    private getReferenceNode(orderJsons: OrderDataModel[],
        orderData: OrderDataModel): OrderDataModel {

        if (!orderData.referenceOrderId) {
            return null;
        }
        const parentNode = orderJsons.find(x => x.orderId === orderData.referenceOrderId);
        if (!parentNode) {
            return null;
        }
        return parentNode;
    }

    private getReferenceIndividualNode(orderJsons: OrderDataModel[],
        orderData: OrderDataModel): OrderDataModel {

        if (!orderData.referenceOrderId) {
            return null;
        }
        const parentNode = orderJsons.find(x => x.orderId === orderData.parentOrderId);
        if (!parentNode) {
            return null;
        }
        return parentNode;
    }

    private getTransportDescription(orderData: OrderDataModel): string {

        const orderTransport = orderData.orderProduct[0];
        const earlyProductDateTime = this.getEarlyTransportDateTime(orderTransport.orderProductDateTime);
        const transportAirport = this.getTransportAirport(orderTransport.orderProductPoint);
        const transportNumber = this.getTransportProductNumber(orderTransport.orderProductNumber);
        return `${earlyProductDateTime} ${transportAirport} ${transportNumber}`;
    }
    private getSeatDescription(orderData: OrderDataModel): string {

        const orderProductAncillary = orderData.orderProduct[0];
        const orderProductSeat = orderProductAncillary.orderProductSeat[0];

        return `${orderProductAncillary.productType.name} ${orderProductSeat.seatText}`;
    }
    private getBagDescription(orderData: OrderDataModel): string {

        const orderProductAncillary = orderData.orderProduct[0];

        return `${orderProductAncillary.productType.name}`;
    }
    private getServiceDescription(orderData: OrderDataModel): string {

        const orderService = orderData.orderService[0];

        return orderService.service.name;
    }
    private getEarlyTransportDateTime(orderProductDateTimes: OrderProductDateTimeModel[]): string {

        const earlyProductDateTime = orderProductDateTimes
            .reduce((a, b) => a.localDateTime < b.localDateTime ? a : b);
        return this.dateConversion.convertDayWeekMonthDate(earlyProductDateTime.localDateTime);
    }
    private getTransportAirport(orderProductPoints: OrderProductPointModel[]): string {
        const firstRecordIndex: number = 0;
        const sortedTransportPoints = orderProductPoints
            .sort((a, b) => a.pointSequence - b.pointSequence);
        let transportPointResult: string = "";
        for (let i = 0; i < sortedTransportPoints.length; i++) {
            const transportPoint = sortedTransportPoints[i];
            if (firstRecordIndex !== i) {
                transportPointResult += `- ${transportPoint.location.name} (${transportPoint.location.code})`;
                continue;
            }
            transportPointResult += `${transportPoint.location.name} (${transportPoint.location.code})`;
        }
        return transportPointResult;
    }
    private getTransportProductNumber(orderProductNumbers: OrderProductNumberModel[]): string {
        if (!orderProductNumbers || orderProductNumbers.length === 0) {
            return "";
        }
        const marketingProductNumber = orderProductNumbers.find(x => x.productNumberType.code == ProductNumberType.marketing);
        if (marketingProductNumber) {
            return `${marketingProductNumber.provider.code} ${marketingProductNumber.productNumber}`;
        }
        const operatingProductNumber = orderProductNumbers.find(x => x.productNumberType.code == ProductNumberType.operating);
        return `${operatingProductNumber.provider.code} ${operatingProductNumber.productNumber}`;
        
    }

    private individualNode(orderData: OrderDataModel): boolean {
        const partnerCode: string = "PASSENGER";
        if (!orderData?.orderPartner) {
            return false;
        }
        return orderData.orderPartner[0].orderPartnerRole?.code === partnerCode;
    }

    private getPartnerDescription(orderData: OrderDataModel): string {

        const orderPartner = orderData.orderPartner[0];
        const partnerName = orderPartner.orderPartnerName;
        const ageGroup = orderPartner.individualAgeGroup;

        return `${partnerName.lastName} ${partnerName.firstName} ${this.getIndividualTitleName(partnerName)} ${this.getAgeGroup(ageGroup)}`;
    }
    private getIndividualTitleName(partnerName: OrderPartnerNameModel): string {
        if (!partnerName?.individualTitle) {
            return "";
        }
        return partnerName.individualTitle.name;
    }
    private getAgeGroup(ageGroup: NodeIdValueModel): string {
        if (!ageGroup?.name) {
            return "";
        }
        return ageGroup.name;
    }
    private generateHierrarchyId(orderNodes: OrderDataModel[],
        currentNode: OrderDataModel,
        id: string): string {

        const itemParentNode = this.findParentHeirarchy(orderNodes, currentNode.parentOrderId);
        const referenceNode = this.getReferenceNode(orderNodes, currentNode);
        const nodeId = this.getNodeHeirarchyId(referenceNode);
        if (nodeId) {
            if (id.length === 0) {
                id = nodeId;
            } else {
                id = `${nodeId}_${id}`;
            }
        }
        if (!itemParentNode) {
            return id;
        }
        return this.generateHierrarchyId(orderNodes, itemParentNode, id);
    }
    private generateHierrarchyIndividualId(orderNodes: OrderDataModel[],
        currentNode: OrderDataModel,
        id: string): string {
        const itemParentNode = this.findParentHeirarchy(orderNodes, currentNode.parentOrderId);
        const nodeId = this.getNodeHeirarchyId(currentNode);
        if (nodeId) {
            if (id.length === 0) {
                id = nodeId;
            } else {
                id = `${nodeId}_${id}`;
            }
        }
        if (!itemParentNode) {
            return id;
        }
        return this.generateHierrarchyId(orderNodes, itemParentNode, id);
    }
    private findParentHeirarchy(orderNodes: OrderDataModel[], parentOrderId: string): OrderDataModel {
        return orderNodes.find(x => x.orderId === parentOrderId);
    }
    private getNodeHeirarchyId(referenceNode: OrderDataModel): string {
        if (this.transportNode(referenceNode)) {
            return this.getTransportId(referenceNode);
        } else if (this.individualNode(referenceNode)) {
            return this.getPartnerId(referenceNode);
        } else if (this.noneTransportProductNode(referenceNode, this.PRODUCT_SEAT_CODE)) {
            return this.getProductId(referenceNode);
        } else if (this.noneTransportProductNode(referenceNode, this.PRODUCT_XBAG_CODE)) {
            return this.getProductId(referenceNode);
        } else if (this.serviceNode(referenceNode)) {
            return this.getServiceId(referenceNode);
        } else if (this.voucherNode(referenceNode)) {
            return referenceNode.orderId;
        }
        return null;
    }
    private getTransportId(orderData: OrderDataModel): string {

        const orderTransport = orderData.orderProduct[0];
        const transportAirport = this.getTransportAirportId(orderTransport.orderProductPoint);
        return `${transportAirport}`;
    }
    private getTransportAirportId(orderProductPoints: OrderProductPointModel[]): string {
        const firstRecordIndex: number = 0;
        const sortedTransportPoints = orderProductPoints
            .sort((a, b) => a.pointSequence - b.pointSequence);
        let transportPointResult: string = "";
        for (let i = 0; i < sortedTransportPoints.length; i++) {
            const transportPoint = sortedTransportPoints[i];
            if (firstRecordIndex !== i) {
                transportPointResult += `-${transportPoint.location.code}`;
                continue;
            }
            transportPointResult += `${transportPoint.location.code}`;
        }
        return transportPointResult;
    }
    private getPartnerId(orderData: OrderDataModel): string {

        const orderPartner = orderData.orderPartner[0];
        const partnerName = orderPartner.orderPartnerName;

        return `${partnerName.lastName}-${partnerName.firstName}-${this.getIndividualTitleName(partnerName)}`;
    }
    private getProductId(orderData: OrderDataModel): string {

        const orderProductAncillary = orderData.orderProduct[0];
        return `${orderProductAncillary.productType.code}`;
    }
    private getServiceId(orderData: OrderDataModel): string {

        const orderService = orderData.orderService[0];

        return `${orderService.service.code}`;
    }
    public toPaymentFromView(model: OrderJsonModel): OrderShoppingPaymentView {
        if (!model) {
            return null;
        }
        const headerOrder = model.oopsOrder.order.find(x => x.orderState.code == OrderStateCode.Header);
        return {
            saleAmount: this.stringUtils.validateNullAmountDecimal(headerOrder.sales.amount),
            saleCurrency: headerOrder.sales.currency,
            paymentAmount: this.stringUtils.validateNullAmountDecimal(headerOrder.sales.amount),
            paymentCurrency: headerOrder.sales.currency,
        };
    }
    public toPaymentModel(model: OrderJsonModel) {
        if (!model) {
            return null;
        }

        const userNameInfo = JSON.parse(this.getSessionItem(USER_LOGON_NAME));
        const userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION));
        let comitBy = (userNameInfo.userAccountId) ? userNameInfo.userAccountId : this.getUserId();

        const headerOrder = model.oopsOrder.order.find(x => x.orderState.code == OrderStateCode.Header);
        let paymentId = this.stringUtils.NewGuid();
        let ledgerId = this.stringUtils.NewGuid();
        let ledgersModel = new Array<LedgersModel>();
        let salesAll = 0;
        let saleCurrency = "";

        const orderAmounts = model.oopsOrder.order.filter(x => (x.orderState.code == OrderStateCode.Sales) && x.orderAmount);
        orderAmounts.forEach(element => {
            let data = element.orderAmount.find(x => x.orderAmountType.code == OrderStateCode.Total);
            if (data) {
                const totalSalesAmount = element.sales?.amount || 0;
                const totalLedgerAmount = element.payment?.amount || 0;
                const outstandingAmount = totalSalesAmount - totalLedgerAmount;
                let sales = this.getAmount(outstandingAmount, data.sales.currency);
                let payment = this.getAmount(outstandingAmount, data.sales.currency);
                let ledgerModel: LedgersModel = this.mapLedger(this.stringUtils.NewGuid(), ledgerId, null, userInfo, element.orderId, data, PaymentConstant.dr, sales, payment);
                ledgersModel.push(ledgerModel);
                salesAll += data.sales.amount;
                saleCurrency = data.sales.currency;
            }
        });
        let salesParent = this.getAmount(salesAll, saleCurrency);
        let paymentParent = this.getAmount(salesAll, saleCurrency);
        let ledgerModel: LedgersModel = this.mapLedger(ledgerId, null, paymentId, userInfo, null, headerOrder, PaymentConstant.cr, salesParent, paymentParent);
        ledgersModel.push(ledgerModel);
        return this.mapPayment(userInfo, paymentId, comitBy, headerOrder, ledgersModel);
    }    
    private getUserId() {
        this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
            .subscribe(state => {
                return state.userAccountId;
            });
    }
    private mapLedger(ledgerId, parentLedgerId, paymentId, userInfo, orderId, order, ledgerTransactionCode, sales, payment): LedgersModel {
        return {
            ledgerId: ledgerId,
            parentLedgerId: parentLedgerId,
            paymentId: paymentId,
            organisationId: userInfo.organisationId,
            ledgerOrganisationId: null,
            ledgerIndividualId: null,
            orderId: orderId,
            ledgerTransactionCode: ledgerTransactionCode,
            sales: sales,
            payment: payment,
            exchangeRate: null
        };
    }
    private mapPayment(userInfo, paymentId, comitBy, headerOrder, ledgersModel) {
        let paymentModel: PaymentsModel = {
            paymentId: paymentId,
            referencePaymentId: null,
            organisationId: userInfo.organisationId,
            paymentOrganisationId: null,
            paymentIndividualId: null,
            paymentAddressId: null,
            paymentStatusCode: null,
            formOfPaymentTypeCode: null,
            formOfPaymentCode: null,
            accountableDocumentTypeCode: null,
            documentNumber: null,
            documentDate: null,
            paymentDate: null,
            paymentDateTime: null,
            issueDateTime: null,
            externalReference: null,
            transactionReference: null,
            externalTransactionId: null,
            paymentToken: null,
            paymentText: null,
            commitBy: comitBy,
            sales: this.getAmount(headerOrder.sales?.amount, headerOrder.sales?.currency),
            payment: this.getAmount(headerOrder.sales?.amount, headerOrder.sales?.currency),
            ledger: ledgersModel
        };
        return paymentModel;
    }
    private getAmount(amount: number, currencyCode: string): AmountModel {
        let model: AmountModel = {
            amount: amount,
            currencyCode: currencyCode
        }
        return model;
    }
    private getSessionItem(key: string) {
        return this.authService.sessionStorageGetItem(key)
    }

    public paymentTreeViewToPaymentFormView(treeView: OrderShoppingPaymentTreeView): OrderShoppingPaymentView {
        return {
            saleAmount: treeView.salesAmount,
            saleCurrency: treeView.currencyCode,
            paymentAmount: treeView.salesAmount,
            paymentCurrency: treeView.currencyCode,
        }
    }

    public toShoppingPriceDetailViews(json: OrderJsonModel): ShoppingPriceDetailView[] {
        let individualOrders = json?.oopsOrder?.order?.filter(item => item.orderState.code == OrderStateCode.Individual);
        let shoppingPriceDetailViews = new Array<ShoppingPriceDetailView>();
        individualOrders?.forEach(order => {
            let individualPriceDeatil = this.toShoppingPriceDetailView(order.orderId, json.oopsOrder.order);
            if (individualPriceDeatil?.priceDetails) {
                shoppingPriceDetailViews.push(individualPriceDeatil);
            }
        })
        if (shoppingPriceDetailViews?.length) {
           return shoppingPriceDetailViews;
        }

        const orders = json?.oopsOrder?.order;
        const salesOrders = orders?.filter(o => o.orderState?.code == OrderStateCode.Sales);
        if (!salesOrders?.length) {
            return [];
        }
        salesOrders.forEach(salesOrder => {
            const productOrder = orders?.find(o => o.orderState?.code == OrderStateCode.Product && o.orderId == salesOrder.parentOrderId);
            const view = this.toShoppingPriceDetailView(productOrder.orderId, json.oopsOrder.order);
            if (view) shoppingPriceDetailViews.push(view);
        })
        return shoppingPriceDetailViews;
    }

    private toShoppingPriceDetailView(individualOrderId: string, orders: OrderDataModel[]): ShoppingPriceDetailView {
        let individualAmountOrder = orders?.find(order => order.parentOrderId == individualOrderId && order.orderState.code == OrderStateCode.Sales);
        if (individualAmountOrder) {
            return {
                orderId: individualAmountOrder?.orderId,
                priceDetails: this.toPriceDetailViews(individualAmountOrder, orders)
            };
        }
        return null;
    }

    private getNonTransportProductName(individualAmountOrder: OrderDataModel,orders: OrderDataModel[]): string {
        const individual = orders?.find(o => o.orderId == individualAmountOrder?.parentOrderId);
        const product = orders?.find(o => o.orderId == individual?.parentOrderId);
        const productReference = orders?.find(o => o.orderId == product?.referenceOrderId);
        if (!productReference) return;

        let productName = productReference.orderProduct?.[0]?.product?.name ?? 'n/a';

        if (product.numberOfUnits > 1) {
            productName += ` ${product.numberOfUnits}x`
        }

        return productName;
    }

    private isSalesOfTransportProduct(individualAmountOrder: OrderDataModel, orders: OrderDataModel[]): boolean {
        const individual = orders?.find(o => o.orderId == individualAmountOrder?.parentOrderId);
        const product = orders?.find(o => o.orderId == individual?.parentOrderId);
        const productReference = orders?.find(o => o.orderId == product?.referenceOrderId);
        return productReference?.orderProduct?.[0]?.productCategory?.code == ProductCategoryConstant.Transport;
    }

    private toPriceDetailViews(order: OrderDataModel, orders: OrderDataModel[]): PriceDetailView[] {
        const orderAmounts = order.orderAmount;
        if (!orderAmounts) {
            return;
        }
        const numberOfUnits = this.getNumberOfUnits(order, orders);
        let priceDetails = new Array<PriceDetailView>();
        let priceGroupNames = new Array<string>();
        orderAmounts.sort((a, b) => a.displaySequence - b.displaySequence || a.priceRuleDisplay?.name?.localeCompare(b.priceRuleDisplay?.name));
        let totalAmount: OrderAmountModel;
        for (let amount of orderAmounts) {
            this.checkPriceGroupDisplay(priceGroupNames, amount);
            if (amount.orderAmountType.code == this.TOTAL_PRICE_CODE) {
                totalAmount = amount;
                continue;
            }
            priceDetails.push(this.toPriceDetailView(amount, this.getPriceGroup(amount, order, orders), numberOfUnits));
        }
        priceDetails.push(this.toPriceDetailView(totalAmount, this.getPriceGroup(totalAmount, null, null)));
        return priceDetails;
    }

    private getNumberOfUnits(order: OrderDataModel, orders: OrderDataModel[]): number {
        if (this.isSalesOfTransportProduct(order, orders)) {
            return 1;
        }

        const individual = orders?.find(o => o.orderId == order?.parentOrderId);
        const product = orders?.find(o => o.orderId == individual?.parentOrderId);
        return product?.numberOfUnits ?? 1;
    }

    private getPriceGroup(amount: OrderAmountModel, order: OrderDataModel, orders): string {
        if (this.isSalesOfTransportProduct(order, orders) || amount?.orderAmountType?.code != OrderAmountTypeCode.Price) {
            return amount?.orderAmountType.name;
        }
        return this.getNonTransportProductName(order, orders);
    }

    private toPriceDetailView(orderAmount: OrderAmountModel, priceGroup: string, numberOfUnits: number = 1): PriceDetailView {
        let priceAmount = orderAmount?.price?.amount;
        let salesAmount = orderAmount?.sales?.amount;
        priceAmount = priceAmount ? priceAmount * numberOfUnits : priceAmount;        
        salesAmount = salesAmount ? salesAmount * numberOfUnits : salesAmount;            
        let priceDetail: PriceDetailView = {
            priceGroup: priceGroup,
            priceName: this.getPriceName(orderAmount),
            priceCurrency: orderAmount?.price?.currency,
            priceAmount: this.stringUtils?.amountString(priceAmount),
            salesCurrency: orderAmount?.sales?.currency,
            salesAmount: this.stringUtils.amountString(salesAmount),
            exchangeRate: orderAmount?.exchangeRate
        }
        return priceDetail;
    }

    private getPriceName(orderAmount: OrderAmountModel): string {
        if (orderAmount.orderAmountType.code == this.TOTAL_PRICE_CODE) {
            return this.EMPTY_STRING;
        }
        return orderAmount?.priceRuleDisplay?.name ?? orderAmount?.priceRule?.name;
    }

    private checkPriceGroupDisplay(priceGroupCodes: string[], orderAmount: OrderAmountModel) {
        if (priceGroupCodes.includes(orderAmount.orderAmountType.name)) {
            orderAmount.orderAmountType.name = this.EMPTY_STRING;
        } else {
            priceGroupCodes.push(orderAmount.orderAmountType.name)
        }
    }

    public orderPaymentsToPendingPaymentTableViews(payments: OrderPaymentModel[]): OrderPendingPaymentTableView[] {
        if (!payments || payments.length === 0) {
            return null;
        }
        let orderPayments = new Array<OrderPendingPaymentTableView>();
        payments.forEach(payment => {
            orderPayments.push(this.orderPaymentToPendingPaymentTableView(payment));
        })
        return orderPayments;
    }

    private orderPaymentToPendingPaymentTableView(payment: OrderPaymentModel): OrderPendingPaymentTableView {
        if (payment.formOfPayment?.code == FormOfPayment.CreditNote) {
            return this.orderPaymentToCreditNotePendingPaymentTableView(payment);
        }
        let crLedger = payment.ledger?.find(ledger => ledger.ledgerTransaction.code == PaymentConstant.cr);
        let pendingPayment: OrderPendingPaymentTableView = {
            saleCurrency: crLedger?.sales?.currency,
            saleAmount: this.stringUtils.amountString(crLedger?.sales?.amount),
            paymentId: payment.paymentId,
            externalTransactionId: payment?.externalTransactionId,
            paymentDate: this.dateConversion.toDateFormat(payment?.paymentDate),
            formOfPaymentTypeCode: payment?.formOfPaymentType?.code,
            formOfPaymentTypeName: payment?.formOfPaymentType?.name,
            formOfPaymentCode: payment?.formOfPayment?.code,
            formOfPaymentName: payment?.formOfPayment?.name,
            documentNumber: payment?.documentNumber,
            documentDate: this.dateConversion.toDateFormat(payment?.documentDate),
            paymentIndividualId: payment?.paymentIndividual?.id,
            paymentOrganisationId: payment?.paymentOrganisation?.id,
            paymentDueDate: this.dateConversion.toDateFormat(payment?.paymentDueDate),
            paymentAmount: this.stringUtils.amountString(crLedger?.ledger?.amount),
            paymentCurrency: crLedger?.ledger?.currency
        }
        return pendingPayment;
    }

    private orderPaymentToCreditNotePendingPaymentTableView(payment: OrderPaymentModel): OrderPendingPaymentTableView {
        let drLedger = payment.ledger?.find(ledger => ledger.ledgerTransaction.code == PaymentConstant.dr);
        let pendingPayment: OrderPendingPaymentTableView = {
            saleCurrency: drLedger?.transaction?.currency,
            saleAmount: this.stringUtils.amountString(drLedger?.transaction?.amount),
            paymentId: payment.paymentId,
            externalTransactionId: payment?.externalTransactionId,
            paymentDate: this.dateConversion.toDateFormat(payment?.paymentDate),
            formOfPaymentTypeCode: payment?.formOfPaymentType?.code,
            formOfPaymentTypeName: payment?.formOfPaymentType?.name,
            formOfPaymentCode: payment?.formOfPayment?.code,
            formOfPaymentName: payment?.formOfPayment?.name,
            documentNumber: payment?.documentNumber,
            documentDate: this.dateConversion.toDateFormat(payment?.documentDate),
            paymentIndividualId: payment?.paymentIndividual?.id,
            paymentOrganisationId: payment?.paymentOrganisation?.id,
            paymentDueDate: this.dateConversion.toDateFormat(payment?.paymentDueDate),
            paymentAmount: this.stringUtils.amountString(drLedger?.transaction?.amount), 
            paymentCurrency: drLedger?.transaction?.currency
        }
        return pendingPayment;
    }

    public clearPayment(dataPayment: PaymentsModel) {
        let paymentId = this.stringUtils.NewGuid();
        let ledgerId = this.stringUtils.NewGuid();
        dataPayment.paymentId = paymentId;        
        let ladgerCredit = dataPayment.ledger.find(x => x.ledgerTransactionCode == PaymentConstant.cr);
        ladgerCredit.ledgerId = ledgerId;
        ladgerCredit.paymentId = paymentId;        
        let ledgerDebit = dataPayment.ledger.filter(x => x.ledgerTransactionCode == PaymentConstant.dr);
        dataPayment.ledger = [];
        ledgerDebit.forEach(x => {
            x.ledgerId = this.stringUtils.NewGuid();
            x.parentLedgerId = ledgerId;
            dataPayment.ledger.push(x);
        });        
        dataPayment.ledger.push(ladgerCredit);
        return dataPayment;
    }

    private getServiceDisplayIcon(orderDataModel: OrderDataModel): 
    { 
        display: string, 
        icon: string 
    } {
        const fee = 'FEE';
        const [orderProduct] = orderDataModel.orderProduct;
        if (!orderProduct) {
            return {
                display: 'Service Name Unvailable',
                icon: ''
            };
        }
        const [seat] = orderProduct.orderProductSeat || [];
        if (seat) {
            const display = seat.seatNumber ? seat.seatNumber : orderProduct.product?.name;
            return {
                display: `Seat ${display}`,
                icon: 'airline_seat_recline_extra'
            };
        }

        const { name } = orderProduct.product;
        const productCategoryCode = orderProduct.productCategory?.code;
        if (productCategoryCode == fee) {
            return {
                display: name,
                icon: 'paid'
            };    
        }

        return {
            display: name,
            icon: 'add_shopping_cart'
        };
    }

    private correctFlightAmount(views: OrderShoppingPaymentTreeView[]) {
        let root = views.find(x => x.parentOrderId == null);
        if (root) {
            let flights = views.filter(x => x.parentOrderId == root.orderId);
            for (let flight of flights) {
                const salesAmount = this.sumAmount(views, flight, 'salesAmount');
                const paymentAmount = this.sumAmount(views, flight, 'paymentAmount');
                const outstandingAmount = this.sumAmount(views, flight, 'outstandingAmount');
                flight.salesAmount = `${salesAmount ?? ""}`;
                flight.paymentAmount = `${paymentAmount ?? ""}`;
                flight.outstandingAmount = `${outstandingAmount ?? ""}`;
                flight.paid = (salesAmount > 0 && outstandingAmount == 0) || salesAmount == 0;
                flight.payable = !flight.paid;
            }
        }
    }

    private sumAmount(views: OrderShoppingPaymentTreeView[], view: OrderShoppingPaymentTreeView, propertyName: string, sum: number = 0): number {
        if (!view) return sum;
        const children = views.filter(v => v.parentHierarchyId == view.hierarchyId);
        if (children?.length) {
            for (const child of children) {
                sum += child[propertyName] ?? 0;
                sum += this.sumAmount(views, child, propertyName);
            }
        }
        return sum;
    }

    private sumFlightAmount(hierarchyId: string, views: OrderShoppingPaymentTreeView[]) : number {
        let individuals = views.filter(x => x.parentHierarchyId == hierarchyId);
        let sumFlightAmount = 0;
        for (let individual of individuals) {
            let sumProductAmount = this.sumProductAmount(individual.hierarchyId, views);
            sumFlightAmount += Number(individual.salesAmount) + sumProductAmount;
        }
        return sumFlightAmount;
    }

    private sumProductAmount(hierarchyId: string, views: OrderShoppingPaymentTreeView[]) : number {
        let products = views.filter(x => x.parentHierarchyId == hierarchyId);
        let sumAmount = 0;
        for (let product of products) {
            sumAmount += Number(product.salesAmount);
        }
        return sumAmount;
    }

    private isFlightHierarchy(orders : OrderDataModel[]) : boolean {
        let orderStateIndividuals = orders.filter(x => x.orderState.code == "INDIVIDUAL");
        if (orderStateIndividuals?.length) {
            return true;
        } else {
            return false;
        }
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextMediaComponent } from './text-media.component';
import { MediaModule } from '../media/media.module';
import { TextModule } from '../text/text.module';

@NgModule({
    declarations: [
        TextMediaComponent
    ],
    imports: [
        CommonModule,
        MediaModule,
        TextModule
    ],
    exports: [
        TextMediaComponent
    ]
})
export class TextMediaModule { }

import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export const select2ExportFormatOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const exportFormatList = ['PDF', 'CSV'];

export const documentDeliveryList: Select2Data[] = [
    new Select2Data("", ""),
    new Select2Data("EMAIL", "Email"),
    new Select2Data("SMS", "Sms"),
]
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { DataTablesModule } from "angular-datatables";
import { DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxSummernoteModule } from "ngx-summernote";
import { TreeModule } from "primeng/tree";
import { ActivityDomainModule } from "src/app/core/components/activity-domain/activity-domain.module";
import { TextMediaModule } from "src/app/core/components/text-media/text-media.module";
import { TranslationNameMapperService } from "src/app/core/components/translation-name/translation-name.mapper.service";
import { TranslationNameModule } from "src/app/core/components/translation-name/translation-name.module";
import { OrdinalModule } from "src/app/core/pipes/ordinal/ordinal.module";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { AlphanumericModule } from "src/app/shared/ui/forms/inputs/alphanumeric/alphanumeric.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { PricingConverter } from "../../shared/pricing.converter";
import { AttributeAndRuleModule } from "./attribute-and-rule/attribute-and-rule.module";
import { BaggageAllowanceModule } from "./baggage-allowance/baggage-allowance.module";
import { GeneralComponent } from "./general/general.component";
import { InclusiveOfProductsComponent } from "./inclusive-of-products/inclusive-of-products.component";
import { InclusiveOfProductsModule } from "./inclusive-of-products/inclusive-of-products.module";
import { PriceMappingDetailComponent } from "./price-mapping/price-mapping-detail/price-mapping-detail.component";
import { PriceMappingComponent } from "./price-mapping/price-mapping.component";
import { PriceRuleDetailComponent } from "./price-rule-detail.component";
import { TreeComponent } from "./tree/tree.component";
import { TranslationTextModule } from "src/app/core/components/translation-text/translation-text.module";
import { TranslationMediaModule } from "src/app/core/components/translation-media/translation-media.module";

@NgModule({
    declarations: [
        GeneralComponent,
        PriceMappingComponent,
        PriceMappingDetailComponent,
        PriceRuleDetailComponent,
        TreeComponent,
    ],
    exports: [
        GeneralComponent,
        InclusiveOfProductsComponent,
        PriceRuleDetailComponent,
        TreeComponent,
    ],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        AlphanumericModule,
        AttributeAndRuleModule,
        BaggageAllowanceModule,
        CollapseModule,
        CommonModule,
        DataTablesModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        InclusiveOfProductsModule,
        MatIconModule,
        NavigationIndexModule,
        NgbPaginationModule,
        NgbNavModule,
        NgxDropzoneModule,
        NgxSummernoteModule,
        OrdinalModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        TextMediaModule,
        TranslationNameModule,
        TranslationTextModule,
        TranslationMediaModule,
        TreeModule,
        UiModule,
    ],
    providers: [PricingConverter, TranslationNameMapperService],
})
export class PriceRuleDetailModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DocumentDistributionComponent } from "./document-distribution.component";
import { DocumentDistributionContentModule } from "./document-distribution-content/document-distribution-content.module";

@NgModule({
    declarations: [
        DocumentDistributionComponent
    ],    
    imports: [
        CommonModule,
        DocumentDistributionContentModule,
        UiModule
    ],
    exports: [
        DocumentDistributionComponent
    ]
})

export class DocumentDistributionModule {}

import { Injectable } from '@angular/core';

declare var moment: any;
@Injectable({
    providedIn: 'root'
})
export class CollectionHelperService {

    public flatten(arr: Array<any>) {
        return arr.reduce(function (a, b) { return a.concat(b) }, []);
    }
}
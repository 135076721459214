import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { NavigationItem, toggleNavSection } from 'src/app/store/navigation';
import { NavigationService } from '../../utils/navigation';
import { makeSlideInOut } from '../../utils/animations';
import { selectTabs } from '../../../store/tab';
import { TabModel } from '../../../core/models/tab/tab.model';
import { TabService } from 'src/app/core/utils/tab.service';
import { AlertBarMainService } from '../../layout/alertbar';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[smart-nav-item]',
  templateUrl: './nav-item.component.html',
  animations: [makeSlideInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent implements OnDestroy, OnInit {

  private readonly ERR_EXCEED_TAB_LIMIT: string = "Maximum number of open Tabs reached - close unused Tabs";

  @Input() item: NavigationItem;
  @Input() minified: boolean;

  @HostBinding('class.active') get active() { return this.item.active; }
  @HostBinding('class.open') get open() { return this.item.open; }
  @HostBinding('class.js-filter-hide]') get hidden() { return this.item.matched !== null && !this.item.matched; }
  @HostBinding('class.js-filter-show]') get shown() { return this.item.matched !== null && this.item.matched; }
  @HostBinding('class.nav-title') get navTitle() { return this.item.navTitle }

  public tabs: TabModel[];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  get isSubMenu(): boolean {
    return !this.item.items && this.item.routerLink && !this.item.mainMenu;
  }

  constructor(private navigationService: NavigationService,
    private store: Store<any>,
    private tabService: TabService,
    private alertBarService: AlertBarMainService) {

  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.store.select(selectTabs)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tabs: TabModel[]) => {
        this.tabs = tabs;
      });
  }

  public toggleSection($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleNavSection({ item: this.item }));
  }

  public trackByFn(idx: number, item: NavigationItem) {
    return item.title + '_' + idx;
  }

  public openTab($event: MouseEvent, name: string, url: string, singleTab: boolean, params: any = null): boolean {
    $event.preventDefault();
    if (this.tabService.exceedAllowedOpenTab(this.tabs)) {
      this.alertBarService.showWarning(this.ERR_EXCEED_TAB_LIMIT);
      return false;
    }
    let tab = this.tabs.find(tab => tab.url.startsWith(url));
    if (singleTab && tab) {
      this.tabService.setSelectedTab(tab);
      return false;
    }
    this.navigationService.navigate(url + "/" + Date.now().toString(), name, true, params);
    return false;
  }
}

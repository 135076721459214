import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { InsightOrderSearchModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search.model";
import { OrderProductModel } from "src/app/core/models/order-model";
import { OrderDetailServices } from "src/app/core/services/order-services";
import { OrderDetailChildLoadData } from "src/app/modules/order/shared";
import { OrderDetailProductListComponent } from "src/app/modules/order/shared/components/order-detail-product-list";
import { OrderSearchView } from "../shared/views/order-search-view";
import { forkJoin } from "rxjs";

@Component({
    selector: 'op-insight-sales-summary-result',
    templateUrl: './insight-sales-summary-result.component.html',
})
export class InsightSalesSummaryResultComponent implements OnChanges {
    public showProductList = false;

    public exportData: any;
    public header: string[];
    public orderSearchResult: OrderSearchView[]
    public focused: boolean = false;
    public resultCount: number = 0;
    public selectedItem = false;
    public products: OrderProductModel[];
    public productSearchResults: OrderProductModel[] = [];

    @Input() individualId: string;
    @Input() insightOrderSearchResults: InsightOrderSearchModel[];

    @Output("productCount") productCount = new EventEmitter<OrderDetailChildLoadData>();

    @ViewChild(OrderDetailProductListComponent) productListComponent: OrderDetailProductListComponent;

    constructor(private orderDetailService: OrderDetailServices) {

    }

    changeListView() {
        this.showProductList = !this.showProductList;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insightOrderSearchResults']) {
            this.productSearchResults = new Array<OrderProductModel>(); 
            this.products = new Array<OrderProductModel>();

            if (!(this.insightOrderSearchResults?.length)) {
                this.productCount.emit({
                    recordCount: 0
                });;
                return;
            }

            let orderIds = this.insightOrderSearchResults?.map(order => order.orderId);
            let lastOrderId = orderIds?.pop();

            forkJoin(
                orderIds?.map(orderId => this.orderDetailService.getOrderDetailProduct(orderId))
            ).subscribe(
                responses => {
                    if (responses?.length) {
                        for (let i = 0; i < responses?.length; i++) {
                            this.productSearchResults = this.productSearchResults.concat(responses[i]);
                        }
                    }
                    if (lastOrderId) {
                        let partnerOrderId = this.insightOrderSearchResults?.find(order => order.orderId == lastOrderId)?.patnerOrderId;
                        this.getOrderProductPartnerData(lastOrderId, partnerOrderId, () => {
                            this.productListComponent.bindProductData(this.productSearchResults)
                        })
                    }
                }
            )
        }
    }

    getOrderProductData(orderId: string, callback) {
        this.orderDetailService.getOrderDetailProduct(orderId)
                .subscribe(
                    (responses: OrderProductModel[]) => {
                        this.productSearchResults = this.productSearchResults.concat(responses);
                        if (callback) {
                            callback();
                        }
                    }
                ); 
    }

    getOrderProductPartnerData(orderId: string, partnerOrderId: string, callback) {
        this.orderDetailService.getIndividualOrderDetailProduct(orderId, partnerOrderId)
                .subscribe(
                    (responses: OrderProductModel[]) => {
                        this.productSearchResults = this.productSearchResults.concat(responses.filter(x => x.productCategoryCode == 'TRANSPORT'));
                        if (callback) {
                            callback();
                        }
                    }
                ); 
    }

    countProductList(productOrder: OrderDetailChildLoadData) {
        this.productCount.emit(productOrder);
    }
}
import { Component, ChangeDetectionStrategy, OnInit, Input, SimpleChange, OnChanges } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    select2Attribute, select2AttributeInherit, select2ChoiceMultiple
} from '../../../../shared/configuration/select2-inventory-attribute-configuration';

declare var $: any;

@Component({
    selector: 'op-inventory-attribute',
    templateUrl: './inventory-attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryAttributeComponent implements OnInit, OnChanges {

    @Input() id: string;
    @Input() draftFlag = true;

    public attributeOption: any = select2Attribute;
    public productAttributeInventoryValue: string[];
    public productAttributeType: Select2Data[];
    public disabled: boolean = false;
    public isInherit: boolean = false;
    public attributeInheritOption: any = select2AttributeInherit;
    public choiceMultipleOption: any = select2ChoiceMultiple;
    public focusing: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        this.createProductAttributeType();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (changes['draftFlag']) {
            this.createProductAttributeType();
        }
    }

    private createProductAttributeType() {
        this.productAttributeType = [];
        this.productAttributeInventoryValue = [];
        if (this.draftFlag == true) {
            let routeType = new Select2Data("ROUTE", "Route");
            let srvCategory = new Select2Data("SERVICECATEGORY", "Service Category");
            this.productAttributeType.push(routeType);
            this.productAttributeType.push(srvCategory);
            this.productAttributeInventoryValue.push(routeType.id);
            this.productAttributeInventoryValue.push(srvCategory.id);
        }
    }

    get productAttributeInventoryValueData(): string | string[]{
        return this.productAttributeInventoryValue;
    }

    set productAttributeInventoryValueData(value: string | string[]) {
        this.productAttributeInventoryValue = <string[]>value;
    }  
}
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommunicationOtherView } from "../shared/communication-other-view";

@Component({
    selector: 'op-insight-communication-other-table',
    templateUrl: './communication-other-table.component.html'
})
export class InsightCommunicationOtherTableComponent {
    public selectedItem: CommunicationOtherView;
    public customToolbarMarginTop: number;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightCommunicationOthers: CommunicationOtherView[] = new Array();
    @Input() languageReferences: LanguageReferenceModel[];
    @Output() onEdit = new EventEmitter();
    @Output() onRowSelected = new EventEmitter();

    @ViewChild('dataGridCommunicationOther', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    selectedRow(e) {
        this.selectedItem = e.data;
        this.onRowSelected.emit();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Type: dat.communicationOtherTypeName,
                PrimaryName: dat.primaryCommunication,
                Identity: dat.mailAddress,
                Language: this.languageReferences?.filter(item => item.languageCode == dat.languageCode)[0]?.languageName,
            }
        ));
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    getLanguageName = (row: CommunicationOtherView) => {
        return this.languageReferences?.filter(item => item.languageCode == row.languageCode)[0]?.languageName;
    }

    editInsightCommunicationOtherDetail() {
        this.onEdit.emit();
    }
}
import { Injectable } from '@angular/core';
import { ProductInventoryDimensionViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { ProductInventoryHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-hierarchy';
import { InventoryHeirarchyUpperLevelView } from '../views/inventory-heirarchy-upper-level.view';

@Injectable()
export class MapperService {

    public createProductInventoryDimension(productInventoryHierarchy: ProductInventoryHierarchyViewModel[]): ProductInventoryDimensionViewModel[] {
        var models: ProductInventoryDimensionViewModel[] = new Array();
        for (let data of productInventoryHierarchy) {
            var filter = models.filter(x => x.attributeTypeCode == data.productAttributeTypeCode);
            if (filter.length == 0) {
                var model = new ProductInventoryDimensionViewModel();
                model.attributeTypeCode = data.productAttributeTypeCode;
                model.attributeTypeName = data.productAttributeTypeName;
                model.displaySequence = data.displaySequence;
                models.push(model);
            }
        }
        models.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        return models;
    }

    public getParentName(currentData: ProductInventoryHierarchyViewModel, heirarchyInvUpperLevel: InventoryHeirarchyUpperLevelView[], productAttributeTypeCode: string) {
        var filter = heirarchyInvUpperLevel.filter(x => x.productAttributeTypeCode == productAttributeTypeCode);
        if (filter.length > 0) {
            if (filter.length == 1) {
                return filter[0].productAttributeChoiceName;
            }
            else {
                var filterHierarchy = filter.filter(x => currentData.hierarchyKey.startsWith(x.hierarchyKey));
                if (filterHierarchy.length > 0) {
                    return filterHierarchy[0].productAttributeChoiceName;
                }
                else {
                    return "";
                }
            }
        }
        else {
            return "";
        }
    }

    public createInventoryHeirarchyUpperLevel(data: ProductInventoryHierarchyViewModel) {
        var invUpperLevel = new InventoryHeirarchyUpperLevelView();
        invUpperLevel.productId = data.productId;
        invUpperLevel.productName = data.productName;
        invUpperLevel.productAttributeTypeCode = data.productAttributeTypeCode;
        invUpperLevel.productAttributeTypeName = data.productAttributeTypeName;
        invUpperLevel.productAttributeChoiceId = data.attributeChoiceId;
        invUpperLevel.productAttributeChoiceName = data.attributeChoiceName;
        invUpperLevel.hierarchyKey = data.hierarchyKey;
        invUpperLevel.hierarchyLength = data.hierarchyLength;
        return invUpperLevel;
    }
}
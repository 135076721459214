import { Injectable } from '@angular/core';
import { map, tap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { activeUrl, mobileNavigation } from './navigation.actions';
import { handleClassCondition } from 'src/app/shared/utils/utils.functions';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import * as NavigationAction from './navigation.actions';
import { Store } from '@ngrx/store';
import { initialState, NavigationItem } from './navigation.reducer';

@Injectable()
export class NavigationEffects {

  mapToActiveUrl$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    map((action: RouterNavigatedAction) => activeUrl({ url: action.payload.event.url })),
    tap(action => handleClassCondition(false, 'mobile-nav-on', document.querySelector('body')))
  ));

  mobileNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(mobileNavigation),
    tap(action => handleClassCondition(action.open, 'mobile-nav-on', document.querySelector('body'))
  )), { dispatch: false });

  filterBySecurity$ = createEffect(() => 
    this.actions$.pipe(
      ofType(NavigationAction.filterBySecurity),
      switchMap((_) => {
        let defualtItems = initialState.items
        return this.securityGroupService.getUserSecurityGroupSecurity()
          .pipe(
            map((resData) => {
              let filteredItems = new Array<NavigationItem>()
              defualtItems.forEach(item => {
                if (!item.securityCodes) {
                  filteredItems.push(item);
                } else if (resData.find(security => item.securityCodes.includes(security.securityCode))) {
                  filteredItems.push(item);
                }
              })
              return NavigationAction.updateNavigationItems({items: filteredItems});
            })
        )
      }),
    )
  );

  constructor(private actions$: Actions, breakpointObserver: BreakpointObserver,
    private securityGroupService: SecurityGroupService,
    private store$: Store) {
    breakpointObserver.observe(
      '(max-width: 600px)',
    ).subscribe(result => {
      handleClassCondition(result.matches, 'mobile-view-activated', document.querySelector('body'));
    });
  }
}




import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductTypeUseModel } from "../../models/reference-model/reference-product-model";

@Injectable({
    providedIn: "root",
})
export class ProductTypeService extends ApiServiceBase {
    private readonly PATH = "/system/ProductTypes";

    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getProductTypeUse(productGroupCode: string): Observable<ProductTypeUseModel[]> {
        
        return this.httpGet<ProductTypeUseModel[]>(this.PATH 
            + '/use/product-groups/'
            + productGroupCode, 
            null)
            .pipe(
                map(res => res.body)
            )
    }

    public getProductTypeUseByIndividual(individualId: string, groupCode: string): Observable<ProductTypeUseModel[]> {
        let params = new HttpParams()
            .set('productGroupCode', groupCode);
        
        return this.httpGet<ProductTypeUseModel[]>(this.PATH + "/individuals/" + individualId + "/use", params)
          .pipe(
            map(response => {
              return response.body;
            })
        );
    }
}
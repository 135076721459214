import { ApiServiceBase } from '../../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable()
export class ProductHashTagService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/product"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getByOrganisation(organisationId) {
        let params = new HttpParams()
            .set("organisationId", organisationId);
        return this.httpGet<any>(this.REFERENCE_PATH + "/producthashtag", params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { select2SearchOrganisation } from 'src/app/modules/user-management/user-management-content/user-search/shared/select2-configuration';
import { CashbookSearchTableView } from '../views/cashbook-search-table.view';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import {
  cashbookSearchDateOption,
  select2SearchPaymentCurrency,
  select2SearchPaymentStatus,
  select2SearchPaymentTypeCode
} from './cashbook-search-configurations';
import { CashbookSearchCommandModel } from 'src/app/core/models/cashbook-model';
import { CurrencyReferenceModel, PaymentStatusReferenceModel, PaymentTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
declare var moment: any;

@Component({
  selector: 'op-cashbook-search-condition',
  templateUrl: './cashbook-search-condition.component.html',
})
export class CashbookSearchConditionComponent extends OopsComponentFormBase implements OnInit, OnChanges {
  @Input() organisations: OrganisationModel[];
  @Input() paymentTypeCode:  PaymentTypeReferenceModel[];
  @Input() paymentCurrency:  CurrencyReferenceModel[];
  @Input() paymentStatus:  PaymentStatusReferenceModel[];
  @Input() organisationId: string;
  @Input() saveFilter: boolean = false;
  @Input() userSecurity: SecurityGroupSecurityModel;

  @Output() onSearch = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Output() getConditionValue = new EventEmitter();

  public condition: CashbookSearchTableView;
  public organisationOption: any;
  public paymentTypeCodeOption: any;
  public paymentCurrencyOption: any;
  public paymentStatusOption: any;
  public searchDateOption: any;
  public dateNow = new UntypedFormControl(moment(new Date()).format('YYYY/MM/DD')).value;
  public focusing: boolean = false;

  constructor(fb: UntypedFormBuilder,
    private changeDetectionRef: ChangeDetectorRef) {
    super(fb);
    this.organisationOption = select2SearchOrganisation;
    this.searchDateOption = cashbookSearchDateOption;
    this.paymentTypeCodeOption = select2SearchPaymentTypeCode;
    this.paymentCurrencyOption = select2SearchPaymentCurrency;
    this.paymentStatusOption = select2SearchPaymentStatus;
    this.searchDateOption.startDate = moment();
  }

  get form(): UntypedFormGroup {
    return this.formGroup;
  }

  ngOnInit(): void {
    this.initForm();
    this.doChange();
  }

  public initForm() {
    this.formGroup = new UntypedFormGroup({
      organisationId: new UntypedFormControl(this.organisationId),
      paymentTypeCode: new UntypedFormControl(null),
      paymentCurrency: new UntypedFormControl(null),
      paymentStatus: new UntypedFormControl(null),
      paymentDateFrom: new UntypedFormControl(moment(new Date()).format('YYYY/MM/DD')),
      paymentDateTo: new UntypedFormControl(null)
    })

    this.checkUserSecurity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['organisations']) {
      this.initForm();
      this.doChange();
    }
  }

  public doSearch() {
    this.onSearch.emit(this.getValues());
  }

  public doChange() {
    let value = this.getValues();
    let organisations = this.organisations?.find(x => x.organisationId == value.organisationId);
    value['organisationCallName'] = organisations?.organisationCallName;
    this.getConditionValue.emit(value);
  }
  public getValues(saveFilter: boolean = false): CashbookSearchCommandModel {
    this.saveFilter = saveFilter;
    this.changeDetectionRef.detectChanges();
    return this.convertFormGroupToModel();
  }

  private convertFormGroupToModel(): CashbookSearchCommandModel {
    let model = {} as CashbookSearchCommandModel;
    let paymentDateFrom = this.getFormControlValue("paymentDateFrom");
    if (!paymentDateFrom) {
      paymentDateFrom = this.dateNow;
    }
    model.organisationId = this.getFormControlValue("organisationId");
    model.formOfPaymentTypeCode = this.getFormControlValue("paymentTypeCode");
    model.paymentCurrency = this.getFormControlValue("paymentCurrency");
    model.paymentStatusCode = this.getFormControlValue("paymentStatus");
    model.paymentDateFrom = paymentDateFrom;
    model.paymentDateTo = this.getFormControlValue("paymentDateTo");
    return model;
  }

  private getFormControlValue(formControlName: string): string {
    return this.formGroup.controls[formControlName]?.value;
  }

  public clearCondition() {
    this.saveFilter = false;
    this.onClear.emit();
  }

  checkUserSecurity() {
    if (this.userSecurity?.securityCode == 'CASHBOOK') {
        this.formGroup.get('organisationId').disable();
        return;
    }
    this.formGroup.get('organisationId').enable();
  }

  public valueChange(value: any, ctrlName: string) {
    this.formGroup.controls[ctrlName]?.setValue(value);
    this.doChange();
  }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxTreeListComponent } from "devextreme-angular";
import { RouteViewModel, ServiceTimeViewModel } from "src/app/core/models/product-model/transport-model";
import { TransportService } from "src/app/core/services/product-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { RouteMapperService } from "./route-mapper.service";
import { RouteView } from "./route.view";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ProductAttributeCommandModel } from "src/app/core/models/product-model/product-base-model/product-attribute";

@Component({
    selector: 'op-route',
    templateUrl: './route.component.html',
    providers: [RouteMapperService]
})
export class RouteComponent implements OnChanges {

    private readonly ROOT_LEVEL_HL = 3;
    private readonly DEPARTURE_LEVEL_HL = 5;
    private readonly ARRIVAL_LEVEL_HL = 7;
    @Input() id: string;
    @Input() finalFlag: boolean = false;
    @Output() routeViewData = new EventEmitter();
    @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public focusing: boolean = false;
    public routeViews: RouteView[];

    constructor(private spinnerService: LoadingSpinnerService,
        private transportService: TransportService,
        private routeMapperService: RouteMapperService,
        private changeDetectorRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {
        this.setCellDepValueChange = this.setCellDepValueChange.bind(this);
        this.setCellArrValueChange = this.setCellArrValueChange.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getRouteData();
    }

    private getRouteData() {
        if (this.id) {
            this.spinnerService.show();
            this.transportService.getRoute(this.id)
                .subscribe(
                    (responses: RouteViewModel[]) => {
                        this.routeViews = this.routeMapperService.routeModelToViews(responses, this.id);
                        this.routeViewData.emit();
                        this.expandAll();
                        this.changeDetectorRef.detectChanges();
                        this.spinnerService.hide();
                    }
                )
        } else {
            this.clearRoute();
        }
    }

    private expandAll() {
        setTimeout(() => {
            for (let key of this.routeViews) {
                if (!this.treeList.instance.isRowExpanded(key.productId)) {
                    this.treeList.instance.expandRow(key.productId);
                }
            }
        }, 200);
    }

    public isRoot(value): boolean {
        if (value.data.hierarchyLength == this.ROOT_LEVEL_HL) {
            return true;
        }
        return false;
    }

    public isDept(value): boolean {
        if (value.data.hierarchyLength == this.DEPARTURE_LEVEL_HL) {
            return true;
        }
        return false;
    }

    public isArrival(value): boolean {
        if (value.data.hierarchyLength == this.ARRIVAL_LEVEL_HL) {
            return true;
        }
        return false;
    }

    public cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.data.type == "DEP") {
            this.disableEnableCellDeparture(e);
        }
        if (e.data.type == "ARR") {
            this.disableEnableCellArrival(e);
        }
        if (this.isRoot(e)) {
            $(e.cellElement).addClass("border-left-0");
            $(e.cellElement).addClass("border-right-0");
        }
    }

    private clearRoute() {
        this.routeViews = [];
        this.changeDetectorRef.detectChanges();
    }

    onFocusedCellChanging(e) {
        e.isHighlighted = true;
    }

    onEditingStart(e) {
        if (this.isRoot(e)) {
            e.cancel = true;
        }
        if (this.finalFlag) {
            e.cancel = true;
        } else {
            this.disableCellEdit(e);
        }
    }

    public setCellDepValueChange(newData, value, currentRowData) {
        if (value) {
            newData.depTime = this.dateConverterService.convertTime24(value);
        } else {
            newData.depTime = null;
        }
    }

    public setCellArrValueChange(newData, value, currentRowData) {
        if (value) {
            newData.arrTime = this.dateConverterService.convertTime24(value);
        } else {
            newData.arrTime = null;
        }
    }

    private disableEnableCellDeparture(e) {
        if (e.column.dataField == "arrTime" || e.column.dataField == "dayChange") {
           this.disableCell(e);
        }
        if (e.column.dataField == "depTime" || e.column.dataField == "terminal" || e.column.dataField == "gate") {
            this.enableEditCell(e);
        }
    }

    private disableEnableCellArrival(e) {
        if (e.column.dataField == "depTime") {
            this.disableCell(e);
        }
        if (e.column.dataField == "arrTime" || e.column.dataField == "dayChange" || e.column.dataField == "terminal" || e.column.dataField == "gate") {
            this.enableEditCell(e);
        }
    }

    private disableCellEdit(e) {
        if (e.data.type == "DEP" && (e.column.dataField == "arrTime" || e.column.dataField == "dayChange")) {
            e.cancel = true;
        }
        if (e.data.type == "ARR" && (e.column.dataField == "depTime")) {
            e.cancel = true;
        }
    }

    private disableCell(e) {
        $(e.cellElement).addClass('dx-cell-disabled');
    }

    private enableEditCell(e) {
        if (!this.finalFlag) {
            $(e.cellElement).addClass('dx-cell-editable');
        } else {
            this.disableCell(e);
        }
    }

    public getServiceTimes() : ServiceTimeViewModel[] {
        return this.routeMapperService.routeToServiceTimeModels(this.routeViews);
    }

    public getOriginDestinationAttributes() : ProductAttributeCommandModel[] {
        return this.routeMapperService.routeToAttributeCommandModels(this.routeViews);
    }
}
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_SPECIALSERVICE_REVENUE_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 463,
        salesChannel1Name: "GDS",
        salesChannel2: 404,
        salesChannel2Name: "API",
        salesChannel3: 318,
        salesChannel3Name: "ISE",
        salesChannel4: 102,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 107,
        salesChannel1Name: "GDS",
        salesChannel2: 140,
        salesChannel2Name: "API",
        salesChannel3: 452,
        salesChannel3Name: "ISE",
        salesChannel4: 320,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 155,
        salesChannel1Name: "GDS",
        salesChannel2: 183,
        salesChannel2Name: "API",
        salesChannel3: 395,
        salesChannel3Name: "ISE",
        salesChannel4: 379,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 322,
        salesChannel1Name: "GDS",
        salesChannel2: 475,
        salesChannel2Name: "API",
        salesChannel3: 484,
        salesChannel3Name: "ISE",
        salesChannel4: 301,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 406,
        salesChannel1Name: "GDS",
        salesChannel2: 494,
        salesChannel2Name: "API",
        salesChannel3: 175,
        salesChannel3Name: "ISE",
        salesChannel4: 493,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 390,
        salesChannel1Name: "GDS",
        salesChannel2: 145,
        salesChannel2Name: "API",
        salesChannel3: 233,
        salesChannel3Name: "ISE",
        salesChannel4: 197,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 234,
        salesChannel1Name: "GDS",
        salesChannel2: 252,
        salesChannel2Name: "API",
        salesChannel3: 458,
        salesChannel3Name: "ISE",
        salesChannel4: 472,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 455,
        salesChannel1Name: "GDS",
        salesChannel2: 112,
        salesChannel2Name: "API",
        salesChannel3: 409,
        salesChannel3Name: "ISE",
        salesChannel4: 233,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 327,
        salesChannel1Name: "GDS",
        salesChannel2: 293,
        salesChannel2Name: "API",
        salesChannel3: 196,
        salesChannel3Name: "ISE",
        salesChannel4: 387,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 258,
        salesChannel1Name: "GDS",
        salesChannel2: 115,
        salesChannel2Name: "API",
        salesChannel3: 121,
        salesChannel3Name: "ISE",
        salesChannel4: 328,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 172,
        salesChannel1Name: "GDS",
        salesChannel2: 196,
        salesChannel2Name: "API",
        salesChannel3: 363,
        salesChannel3Name: "ISE",
        salesChannel4: 326,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 243,
        salesChannel1Name: "GDS",
        salesChannel2: 336,
        salesChannel2Name: "API",
        salesChannel3: 448,
        salesChannel3Name: "ISE",
        salesChannel4: 276,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 485,
        salesChannel1Name: "GDS",
        salesChannel2: 111,
        salesChannel2Name: "API",
        salesChannel3: 218,
        salesChannel3Name: "ISE",
        salesChannel4: 274,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 126,
        salesChannel1Name: "GDS",
        salesChannel2: 393,
        salesChannel2Name: "API",
        salesChannel3: 348,
        salesChannel3Name: "ISE",
        salesChannel4: 324,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 154,
        salesChannel1Name: "GDS",
        salesChannel2: 466,
        salesChannel2Name: "API",
        salesChannel3: 287,
        salesChannel3Name: "ISE",
        salesChannel4: 135,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 486,
        salesChannel1Name: "GDS",
        salesChannel2: 412,
        salesChannel2Name: "API",
        salesChannel3: 141,
        salesChannel3Name: "ISE",
        salesChannel4: 100,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 101,
        salesChannel1Name: "GDS",
        salesChannel2: 179,
        salesChannel2Name: "API",
        salesChannel3: 193,
        salesChannel3Name: "ISE",
        salesChannel4: 157,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 190,
        salesChannel1Name: "GDS",
        salesChannel2: 385,
        salesChannel2Name: "API",
        salesChannel3: 445,
        salesChannel3Name: "ISE",
        salesChannel4: 466,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 488,
        salesChannel1Name: "GDS",
        salesChannel2: 467,
        salesChannel2Name: "API",
        salesChannel3: 294,
        salesChannel3Name: "ISE",
        salesChannel4: 235,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 476,
        salesChannel1Name: "GDS",
        salesChannel2: 291,
        salesChannel2Name: "API",
        salesChannel3: 221,
        salesChannel3Name: "ISE",
        salesChannel4: 458,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 247,
        salesChannel1Name: "GDS",
        salesChannel2: 261,
        salesChannel2Name: "API",
        salesChannel3: 230,
        salesChannel3Name: "ISE",
        salesChannel4: 450,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 424,
        salesChannel1Name: "GDS",
        salesChannel2: 418,
        salesChannel2Name: "API",
        salesChannel3: 212,
        salesChannel3Name: "ISE",
        salesChannel4: 193,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 475,
        salesChannel1Name: "GDS",
        salesChannel2: 446,
        salesChannel2Name: "API",
        salesChannel3: 211,
        salesChannel3Name: "ISE",
        salesChannel4: 163,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 238,
        salesChannel1Name: "GDS",
        salesChannel2: 146,
        salesChannel2Name: "API",
        salesChannel3: 447,
        salesChannel3Name: "ISE",
        salesChannel4: 104,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 242,
        salesChannel1Name: "GDS",
        salesChannel2: 423,
        salesChannel2Name: "API",
        salesChannel3: 114,
        salesChannel3Name: "ISE",
        salesChannel4: 298,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 462,
        salesChannel1Name: "GDS",
        salesChannel2: 196,
        salesChannel2Name: "API",
        salesChannel3: 238,
        salesChannel3Name: "ISE",
        salesChannel4: 253,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 367,
        salesChannel1Name: "GDS",
        salesChannel2: 228,
        salesChannel2Name: "API",
        salesChannel3: 163,
        salesChannel3Name: "ISE",
        salesChannel4: 154,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 373,
        salesChannel1Name: "GDS",
        salesChannel2: 234,
        salesChannel2Name: "API",
        salesChannel3: 144,
        salesChannel3Name: "ISE",
        salesChannel4: 449,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 305,
        salesChannel1Name: "GDS",
        salesChannel2: 438,
        salesChannel2Name: "API",
        salesChannel3: 201,
        salesChannel3Name: "ISE",
        salesChannel4: 136,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 170,
        salesChannel1Name: "GDS",
        salesChannel2: 128,
        salesChannel2Name: "API",
        salesChannel3: 111,
        salesChannel3Name: "ISE",
        salesChannel4: 402,
        salesChannel4Name: "OWN"
    }
]

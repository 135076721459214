import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OperatingPassengerSearchModel } from "../../models/operating-passenger-model";

@Injectable({
    providedIn: 'root'
})
export class OperatingPassengerService extends ApiServiceBase {
    private readonly PATH = "/product/operatingPassengers";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public FindByOperatingProductId(opertingProductId: string): Observable<OperatingPassengerSearchModel[]> {
        let httpParams = new HttpParams()
            .set('opertingProductId', opertingProductId);
        return this.httpGet<OperatingPassengerSearchModel[]>(this.PATH + "/" + opertingProductId, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { VehicleConfigurationCompartmentPropertyModel } from "../vehicle-configuration-compartment-property/vehicle-configuration-compartment-property.model";

export class VehicleConfigurationCompartmentModel {
    public vehicleConfigurationCompartmentId: string;
    public serviceCategoryCode: string;
    public serviceCategoryName: string;
    public columnLayout: string;
    public columnCode: string;
    public physicalCapacity: number;
    public numberOfRows: number;
    public statusCode: string;
    public vehicleConfigurationCompartmentProperties: VehicleConfigurationCompartmentPropertyModel[];
}
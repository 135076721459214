import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { ActionService } from 'src/app/core/utils/action.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { MODULE_NAME } from '../../../../settings-management-content/shared/title-constant';
import { VehicleGroupComponent } from '../vehicle-group/vehicle-group.component';

@Component({
    selector: 'op-vehicle-tabs',
    templateUrl: './vehicle-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleTabsComponent {
    readonly VEHICLE_TYPE = MODULE_NAME.vehicle_type;
    readonly VEHICLE_COMPO = MODULE_NAME.vehicle_composition;
    readonly VEHICLE_COFIG = MODULE_NAME.vehicle_config;
    readonly SEATMAP = MODULE_NAME.seatmap;
    readonly SETTING_ROUTE_PATH = '/main/settings/';
    private readonly displayActionId = 'DISPLAY';
    private readonly TAB_SEARCH = 'SEARCH';

    @ViewChild(VehicleGroupComponent) vehicleGroupComponent: VehicleGroupComponent;
    public selectedTab: string;
    public selectedItem: OopsCategory;
    public executeFlag = true;
    @Input() refresh: boolean = false;
    @Output() selectedTabChange = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<OopsCategory>();
    @Output() vehicleConfigurationSelectedItem = new EventEmitter<any>();
    @Output() refreshed = new EventEmitter();
    constructor(private navigateService: NavigationService,
        private actionService: ActionService) { }

    ngOnInit(): void {
        let params = this.navigateService.getParams();
        let tab = params?.selectedTab ?? null;
        let module = params?.module ?? null;
        let seatmap = params?.seatmap ?? false;
        this.executeFlag = params?.executeFlag ?? true;
        if (tab && module) {
            this.selectedTab = tab;
            this.selectedItem = this.createSelectedItem(module, seatmap);
            this.rowSelected.emit(this.selectedItem);
            this.selectedTabChange.emit(this.selectedTab);
            this.addAction(tab, this.selectedItem);
        }
    }

    onTabChange(activeId: string) {
        this.addAction(activeId, this.selectedItem);
        this.selectedTabChange.emit(activeId);
    }

    onMenuSelected(activeId: string) {
        this.selectedTab = activeId.toUpperCase();
        this.addAction(this.selectedTab, this.selectedItem);
        this.selectedTabChange.emit(this.selectedTab);
    }

    onRowSelected(item: OopsCategory) {
        this.selectedItem = item;
        this.addAction(this.selectedTab, this.selectedItem);
        this.rowSelected.emit(item)
    }

    createSelectedItem(name: string, seatmap: boolean): OopsCategory {
        var item = {} as OopsCategory
        item.name = seatmap ? this.SEATMAP : name;
        item.url = this.SETTING_ROUTE_PATH + name.toLowerCase();
        return item;
    }

    onVehicleConfigurationSelectedItem(item) {
        this.vehicleConfigurationSelectedItem.emit(item);
    }

    onRefreshed() {
        this.refreshed.emit();
    }

    addAction(activeId: string, selectedItem: OopsCategory) {
        if (activeId == this.TAB_SEARCH && selectedItem) {
            let vehicleGroup = selectedItem.name.split(' ')[1].toUpperCase();
            vehicleGroup = vehicleGroup != 'MAP'? vehicleGroup : 'CONFIGURATION';
            this.actionService.add(activeId + '/' + vehicleGroup, null, null);
            return;
        }
        this.actionService.add(activeId, null, null);
    }

}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChange } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductNumberTypeReferenceModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ProductNumberTypeReferenceService } from 'src/app/core/services/system-services';
import { select2ProductCodeType, select2Provider, select2Supplier } from 'src/app/modules/product-management/product-categories-content/shared/configuration/select2-number-configuration';

@Component({
    selector: 'op-ancillary-service-product-number',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-number.component.html'
})
export class ProductNumberComponent {
    @Input() provider: OrganisationModel[];
    @Input() supplier: OrganisationModel[];
    @Input() classIcon: string;
    @Input() productTypeCode: string;
    @Input() productNumberTypeSearch$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() searchMode: boolean = false;

    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public actionForm: UntypedFormGroup;
    public codeTypeOption: any;
    public providerOption: any;
    public supplierOption: any;
    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);
    public focusing: boolean = false;
    constructor(private formBuilder: UntypedFormBuilder,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private changeDetectorRef: ChangeDetectorRef) { 
        this.codeTypeOption = select2ProductCodeType;
        this.providerOption = select2Provider;
        this.supplierOption = select2Supplier;
        this.actionForm = this.formBuilder.group({
            forms: this.formBuilder.array([])
        });
    }
    ngOnChanges(changes: SimpleChange) {
        if (!this.searchMode) {
            this.getProductTypeNumber();
        } else {
            this.getProductTypeNumberSearch();
        }
    }

    private getProductTypeNumber() {
        if (this.productTypeCode) {
            this.productNumberTypeReferenceService.getByProductType(this.productTypeCode)
                .subscribe(
                    (responses: ProductNumberTypeReferenceModel[]) => {
                        this.productNumberTypeReference$.next(responses);
                    },
                    error => {
                        console.log(error);
                    }
                )
        } else {
            this.productNumberTypeReference$.next(null);
        }
    }

    private getProductTypeNumberSearch() {
        this.productNumberTypeReference$ = this.productNumberTypeSearch$;
    }

    add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == "INVALID") {
                    return;
                }
            }
        }
        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroup(null));
        this.panelCollapseFlag$.next(false);

        setTimeout(() => {
            let elementReference = document.querySelector('#ddlProductNumberTypeCode_' + (this.forms.controls.length - 1));
            if (elementReference instanceof HTMLElement) {
                elementReference.focus();
            }
        }, 100)
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                } else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }


    findDuplicate(ctlCheck, ctlIndex): boolean {
        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (ctl.value.productNumber == ctlCheck.value.productNumber &&
                    ctl.value.productNumberTypeCode == ctlCheck.value.productNumberTypeCode &&
                    ctl.value.providerId == ctlCheck.value.providerId &&
                    ctl.value.supplierId == ctlCheck.value.supplierId
                ) {
                    duplicate = true;
                }
            }
        }
        return duplicate;
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get('productNumberDuplicate').setValue(null);
            formCurrent.get('productNumberDuplicate').setValidators(Validators.required);
            formCurrent.get('productNumberDuplicate').updateValueAndValidity();
        } else {
            formCurrent.get('productNumberDuplicate').setValue(false);
            formCurrent.get('productNumberDuplicate').setValidators(Validators.nullValidator);
            formCurrent.get('productNumberDuplicate').updateValueAndValidity();
        }
    }

    addViewToFormGroup(productNumbers: ProductNumberViewModel[]) {
        this.actionForm = this.formBuilder.group({
            forms: this.formBuilder.array([])
        });

        for (let productNumber of productNumbers) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroup(productNumber));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);
        }
        this.changeDetectorRef.detectChanges();
    }

    createFormGroup(productNumber: ProductNumberViewModel) {
        if (productNumber) {
            return this.formBuilder.group({
                productNumberId: new UntypedFormControl(productNumber.productNumberId, { validators: [Validators.nullValidator] }),
                productNumberTypeCode: new UntypedFormControl(productNumber.productNumberTypeCode, { validators: [Validators.required] }),
                productNumber: new UntypedFormControl(productNumber.productNumber, { validators: [Validators.required] }),
                providerId: new UntypedFormControl(productNumber.providerId, { validators: [Validators.nullValidator] }),
                supplierId: new UntypedFormControl(productNumber.supplierId, { validators: [Validators.nullValidator] }),
                productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }),
                submitted: Boolean
            });
        }
        return this.formBuilder.group({
            productNumberId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
            productNumberTypeCode: new UntypedFormControl(null, { validators: [Validators.required] }),
            productNumber: new UntypedFormControl(null, { validators: [Validators.required] }),
            providerId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
            supplierId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
            productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }),
            submitted: Boolean
        });
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    get allowCollapse(): boolean {
        return this.forms.controls.length > 0;
    }

    delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
        let elementReference = document.querySelector('#btnNumberAdd');
        if (elementReference instanceof HTMLElement) {
            elementReference.focus();
        }
        if (this.forms.controls.length == 0) {
            this.panelCollapseFlag$.next(true);
        }
    }

    selectChange(value: string | string[], ctl, name: string) {
        ctl.get(name).setValue(value);
        this.findDuplicateAllRow();
    }

    clearForm() {
        this.actionForm = this.formBuilder.group({
            forms: this.formBuilder.array([])
        });
        this.changeDetectorRef.detectChanges();
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }
}

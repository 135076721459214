import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DocumentDritributionSearchTableView } from "../shared/views/document-distribution-search-table.view";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DxDataGridComponent } from "devextreme-angular";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { DocumentDistributionService } from "src/app/core/services/system-services/document-distribution.service";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
@Component({
    selector: 'op-document-distribution-search-table',
    templateUrl: './document-distribution-search-table.component.html',
})
export class DocumentDistributionSearchTableComponent extends DatatableComponentBase implements AfterViewInit, OnChanges {
    public blurAfterSelected: boolean = true;
    public printFlag = false;
    public copyToClipboardFlag = true;
    public exportFlag = false;

    public collapsed: boolean;
    public focused: boolean;
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    public rows = 0;


    showHeaderFilter: boolean = true;
    selectedItem: boolean = false;
    applyFilterTypes: any;
    currentFilter: any;
    itemSelected: any;
    exportData: any;
    header: string[];

    @Input() statusReferences: StatusReferenceModel[];
    @Input() documentDistributionSearchResults: DocumentDritributionSearchTableView[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() actionSecurity: SecurityGroupSecurityModel;
    @Output() onNew = new EventEmitter();
    @Output() onEdit = new EventEmitter<DocumentDritributionSearchTableView>();
    @Output() onCopy = new EventEmitter<DocumentDritributionSearchTableView>();
    
    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private documentDistributionService: DocumentDistributionService,
        public spinnerService: LoadingSpinnerService,
        private ref: ChangeDetectorRef) {
        super();
        this.setExportBtnFlag();
    }

    ngAfterViewInit(): void {
        this.setExportBtnFlag();
        this.getDataGridHeader();
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }


    private setExportBtnFlag() {
        this.printFlag = this.actionSecurity?.printFlag;
        this.copyToClipboardFlag = this.actionSecurity?.copyToClipboardFlag;
        this.exportFlag = this.actionSecurity?.exportFlag;
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['actionSecurity']) {
            this.setExportBtnFlag();
        }
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.ref.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Name: dat.documentDistributionName,
                ReceiverOrganisation: dat.receiverOrganisation,
                DocumentType: dat.documentTypeName,
                DistributiobType: dat.distributionTypeName,
                TeleTypeSender: dat.teletypeAddressElementSender,
                TeleTypeReciever: dat.teletypeAddressElementReceiver,
                MailAddressSender: dat.mailAddressElementSender,
                MailAddressReceiver: dat.mailAddressElementReceiver,
                CommitBy: dat.commitByName ?? '',
                CommitDateTime: dat.commitDateTime ?? '',
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReferences.filter((dat) => dat.statusCode == statusCode)[0]?.displayName;
    }

    public refreshTable() {
        this.dataGrid.instance.refresh();
    }

    updateStatus(data: any, e: any) {
        this.spinnerService.showSaving();
        this.documentDistributionService.updateStatus(data.documentDistributionId, e)
            .subscribe(
                () => {
                    data.statusCode = e;
                    this.ref.detectChanges();
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }      
            );
    }

    newItem() {
        this.onNew.emit();
    }

    editItem() {
        if (this.selectedItem) {
            this.onEdit.emit(this.itemSelected);
        }
    }

    copyItem() {
        if (this.selectedItem) {
            this.onCopy.emit(this.itemSelected);
        }
    }

    doubleClick() {
        this.editItem();
    }

    changeCaption(data) {
        let caption = data.column.caption;
        let newcaption = caption.split(' ');
        return newcaption[1];
      }
}
import {
    ProductRestrictionLocationViewModel,
    ProductRestrictionOrganisationViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction'

export class PointOfSalesView {
    public no: number;
    public type: string;
    public productRestrictionLocationView: ProductRestrictionLocationViewModel;
    public productRestrictionOrganisationView: ProductRestrictionOrganisationViewModel;
}
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { StandardScheduleInformationService } from "src/app/core/services/schedule-services";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ScheduleQueueResultCode } from "src/app/modules/schedule/shared/schedule.contant";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";

@Component({
    selector: 'op-schedule-queue-task-table',
    templateUrl: './schedule-queue-task-table.component.html',
    providers: [],
})
export class ScheduleQueueTaskTableComponent implements OnInit {
    readonly DATE_WITH_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss"

    public scheduleQueueData: any;
    public header: string[];
    public rows = 0;

    @Input() scheduleQueueTasks: ScheduleQueueSearchModel[];
    @Input() rootScheduleQueueId: string;

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private standardScheduleInformationService: StandardScheduleInformationService,
        private dateConverterService: DateConverterService) {}

    ngOnInit(): void {
        this.getSSIMTaskData();
    }

    private getSSIMTaskData() {
        let dynamicDataArray = new Array();

        this.loadingSpinnerService.show();
        this.standardScheduleInformationService.getSSIMTask(this.rootScheduleQueueId)
        .subscribe(
            (responses: ScheduleQueueSearchModel[]) => {
                let no = 1;
                responses.forEach(item => {
                    let scheduleTemps = {};
                    scheduleTemps["no"] = no;
                    scheduleTemps["taskCode"] = item.scheduleQueueTaskCode;
                    scheduleTemps["taskName"] = item.scheduleQueueTaskName ?? item.scheduleQueueTaskCode;
                    scheduleTemps["productNumber"] = item.providerIataCode + ' ' + item.productNumber;
                    scheduleTemps["id"] = item.scheduleQueueId;
                    scheduleTemps["originLocationCode"] = item.originLocationCode;
                    scheduleTemps["destinationLocationCode"] = item.destinationLocationCode;
                    scheduleTemps["schedulePeriodFrom"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.startDateTime)?.toUpperCase();
                    scheduleTemps["schedulePeriodTo"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.endDateTime)?.toUpperCase();
                    scheduleTemps["weekdays"] = item.weekdays;
                    scheduleTemps["departureTime"] =  this.dateConverterService.convertTime24(item.productScheduledDepartureTime);
                    scheduleTemps["arrivalTime"] = this.dateConverterService.convertTime24(item.productScheduledArrivalTime);
                    scheduleTemps["vehicleType"] = item.vehicleTypeCode;
                    scheduleTemps["vehicleComp"] = item.vehicleCompositionCode;
                    scheduleTemps["action"] = item.scheduleQueueActionCode;
                    scheduleTemps["progress"] = item.processStatusCode;
                    scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
                    scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
                    scheduleTemps["resultText"] = item.scheduleQueueResultText;
                    scheduleTemps["scheduleQueueTasks"] = item.scheduleQueueTasks;        
                    scheduleTemps["processStartDatetime"] = this.dateConverterService.convertDateString(item.processStartDateTime, this.DATE_WITH_TIME_FORMAT);        
                    scheduleTemps["processEndDatetime"] = this.dateConverterService.convertDateString(item.processEndDateTime, this.DATE_WITH_TIME_FORMAT);
                    dynamicDataArray.push(scheduleTemps);
                    no++;
                });

                this.loadingSpinnerService.hide();
                this.scheduleQueueData = dynamicDataArray;
                this.rows = this.scheduleQueueData.length;
                this.changeDetectorRef.detectChanges();
            },
            () => {
                this.loadingSpinnerService.hide();
            }
        )
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'result') {
            this.setResultCellColor(e)
        }
    }

    private setResultCellColor(e) {
        switch (e.data.resultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;    
        }
    }
}
import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';

import { RestrictionModel } from '../../../../../../core/models/merchandizing-config';

import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';
import {
    ConditionReferenceModel, VehicleCompositionModel, VehicleGroupReferenceModel, VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { ATTRIBUTE_AND_RULE_ERR } from '../attribute-and-rule-error.constant';
import { restrictionConfigData } from './restriction.config';

@Component({
    selector: 'op-restriction',
    templateUrl: './restriction.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestrictionComponent {
    //Product restriction product
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;

    //Product restriction product number
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    //Product restriction location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    @Input() vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    @Input() vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @Input() restrictionConfig: RestrictionModel;
    public focusing: boolean = false;
    
    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (!this.restrictionConfig) {
            this.restrictionConfig = Object.assign(new RestrictionModel(), restrictionConfigData);
        }
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        if (this.ruleComponent) {
            this.ruleComponent.add();
        }
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    public validateForm(): boolean {
        if (this.ruleComponent) {
            return this.ruleComponent.validateForm();
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return ATTRIBUTE_AND_RULE_ERR.duplicateInformation;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return ATTRIBUTE_AND_RULE_ERR.invalidInformation;
        }
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CashbookDetailModel } from 'src/app/core/models/cashbook-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner/loading-notifier';
import { PanelCashbookConstant } from '../../../shared';
import { OrderPaymentStatusReferenceModel, OrderSalesStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
  selector: 'op-cashbook-charge-item',
  templateUrl: './cashbook-charge-item.component.html',
})
export class CashbookChargeItemComponent implements OnChanges {
  public readonly SPINNER_NAME: string = "cashbookChargeItem";
  public readonly SPINNER_FULL_SCREEN: boolean = false;
  public focusing: boolean = false;
  public cashbookPanel = PanelCashbookConstant;

  @Input() searchResults: CashbookDetailModel;
  @Input() refreshValue: boolean;
  @Input() userSecurity: SecurityGroupSecurityModel;
  @Input() orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[];
  @Input() orderSalesStatusReferences: OrderSalesStatusReferenceModel[];
  @Output() refreshPanel = new EventEmitter();


  public chargeItems = [];
  public loadingNotifier = new LoadingNotifier();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchResults']) {
      this.mapData(this.searchResults);
    }

    if (changes['refreshValue']) {
      if (this.refreshValue == true) {
        this.loadingNotifier.show(this.SPINNER_NAME);
      } else {
        this.loadingNotifier.hide(this.SPINNER_NAME);
      }
    }
  }

  private mapData(view: CashbookDetailModel) {
    this.chargeItems = view?.chargeItems;
  }

  public getUnderlineClass(rowIndex: number): string {
    if (rowIndex === 0) {
      return "";
    }
    return "cashbook-detail-start-section";
  }

  public refreshTable() {
    this.loadingNotifier.show(this.SPINNER_NAME);
    this.refreshPanel.emit(this.cashbookPanel.ChargeItemPanel);
  }
}
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { DxChartComponent } from "devextreme-angular";
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";
import { PRODUCT_CHART_COLORS } from "../../../../../shared/ui/chart/chart-color.constant";
import { PRODUCT_DASHBOARD_TRANSPORT_REVENUE_LAST30D_DEMO } from "./product-dashboard-transport-revenue-last30d-demo-data";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-transport-revenue-last30d.component.html',
    selector: 'op-product-dashboard-transport-revenue-last30d'
})
export class ProductDashboardTransportRevenueLast30DComponent implements AfterViewInit {
    public readonly ccolor = PRODUCT_CHART_COLORS;
    
    @ViewChild('chartLast30d', { static: false }) chartLast30d: DxChartComponent;

    public productLast30dInfo: OrdersDashboardOrdersCountModel[];
    public productLast30dCaptions: string[];

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngOnInit(): void {
        this.productLast30dCaptions = ["-", "-", "-", "-"];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.chartLast30d.instance.render();
            this.changeDetection.detectChanges();

            this.loadData();
        }, 300);
    }

    private loadData() {
        setTimeout(() => {
            let first = PRODUCT_DASHBOARD_TRANSPORT_REVENUE_LAST30D_DEMO[0];
            this.productLast30dCaptions = [
                first.salesChannel1Name,
                first.salesChannel2Name,
                first.salesChannel3Name,
                first.salesChannel4Name
            ];
            this.productLast30dInfo = PRODUCT_DASHBOARD_TRANSPORT_REVENUE_LAST30D_DEMO;
            this.changeDetection.detectChanges();
        }, 500);
    }

    public customizeLastTooltip(arg: any) {
        let order = arg.point.data as OrdersDashboardOrdersCountModel;
        return {
            html: `<table>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel1Name ? "<b>" + order.salesChannel1Name + "</b>" : order.salesChannel1Name}</td>
                        <td>${ arg.seriesName == order.salesChannel1Name ? "<b>" + order.salesChannel1 + "</b>" : order.salesChannel1}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel2Name ? "<b>" + order.salesChannel2Name + "</b>" : order.salesChannel2Name}</td>
                        <td>${ arg.seriesName == order.salesChannel2Name ? "<b>" + order.salesChannel2 + "</b>" : order.salesChannel2}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel3Name ? "<b>" + order.salesChannel3Name + "</b>" : order.salesChannel3Name}</td>
                        <td>${ arg.seriesName == order.salesChannel3Name ? "<b>" + order.salesChannel3 + "</b>" : order.salesChannel3}</td>
                    </tr>
                    <tr>
                        <td>${ arg.seriesName == order.salesChannel4Name ? "<b>" + order.salesChannel4Name + "</b>" : order.salesChannel4Name}</td>
                        <td>${ arg.seriesName == order.salesChannel4Name ? "<b>" + order.salesChannel4 + "</b>" : order.salesChannel4}</td>
                    </tr>
                </table>`
        };
    }
}
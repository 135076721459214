export class OrganisationAttributeModel {
    public organisationAttributeId: string
    public parentOrganisationAttributeId: string;
    public organisationId: string;
    public organisationAttributeTypeCode: string;
    public organisationAttributeChoiceId: string;
    public organisationAttributeValue: number;
    public attributeText: string;
    public attributeDateTime: Date;
    public conditionCode: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public attributeChoiceNamee: string;
}
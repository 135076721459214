import { Injectable } from '@angular/core';
import { Globals } from "src/app/app.global-variable";

@Injectable({
    providedIn: 'root'
})
export class StringUtils {

    constructor(private globals: Globals) {

    }

    // Partial string match.
    public stringMatch(searchValue: string, compareValue: string) {
        
        const regexp = new RegExp(searchValue, 'gi')
        if (!compareValue) {
            return 0;
        }
        return compareValue.match(regexp) != null ? true : false;
    }

    // compareValue contain all character of searchValue by sequence in any position.
    public stringMatchBySequence(searchValue: string, compareValue: string): boolean {
        if (!compareValue || !searchValue) return false;
        let txt = compareValue.toUpperCase();
        let arrStr = searchValue.toUpperCase().split("");
        let lastIndex = -1;
        for (let c of arrStr) {
            let index = txt.indexOf(c, lastIndex + 1);
            if (index == -1) {
                return false;
            }
            lastIndex = index;
        }
        return true;
    }

    public validateSpecialCharacter(stringValue: string) {
        const regexp = /[ `!@#$%^&*()_\-\+\=\[\]{};':"\\|,.<>\/?~]/;
        return stringValue.match(regexp) != null ? true : false;
    }

    public validateSpecialCharacterExcept(stringValue: string, ...expectChar) {
        let specialCharacter = '[ `!@#$%^&*()_\\-\\+\\=\\[\\]{};\':"\\\|,.<>\\/?~]';
        expectChar?.forEach(item => {
            specialCharacter = specialCharacter.replace(new RegExp(item), '');
        })
        const regexp = new RegExp(specialCharacter);
        return stringValue.match(regexp) != null ? true : false;
    }

    public nullIfNoChange(currentValue: string, newValue: string): string {
        if (!newValue || newValue == currentValue) {
            return null;
        }

        return newValue;
    }
    public isEmptyGuid(value: string) {
        const emptyGuid = "00000000-0000-0000-0000-000000000000";
        return emptyGuid === value;
    }

    public isEmail(value: string): boolean {
        const emailRegex = new RegExp(this.globals.EMAIL_PATTERN, "i");
        const domainMaxLength = 63;
        if (!value) return false;
        if (!emailRegex.test(value)) return false;
        var domains = value.substring(value.indexOf("@") + 1).split(".");
        for (const domain of domains) {
            if (domain.length > domainMaxLength) return false;
        }
        return true;
    }
}
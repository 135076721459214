import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { MembershipTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";
import { MembershipLevelService } from "src/app/core/services/airline-services/membership-level.service";
import { individualExpireDocumentCalendarOption } from "src/app/modules/order/shared";
import { MembershipView } from "../shared/membership-view";
import { select2MembershipLevel, select2MembershipOrganisation, select2MembershipType } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-membership-detail',
    templateUrl: './membership-detail.component.html'
})
export class InsightMembershipDetailComponent {
    readonly ERROR_REQUIRED = ' is required.'
    private readonly MIN_DATE = "1900-1-1";
    private readonly MAX_DATE = "9999-12-31";

    public membershipTypeOption: any;
    public membershipOrganisationOption: any;
    public membershipLevelOption: any;
    public processing: boolean = false;

    public calendarOption: any;
    public membershipLevels$ = new BehaviorSubject<MembershipLevelModel[]>(null);

    @Input() insightMembership = {} as MembershipView;
    @Input() membershipTypeReferences: MembershipTypeReferenceModel[];
    @Input() membershipLevels: MembershipLevelModel[];
    @Input() organisationAirlines: OrganisationModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();
    
    @ViewChild('membershipDetailForm') membershipDetailForm: NgForm;

    private membershipDict: MembershipLevelModel[][] = [];

    get isNullOrganisationId() : boolean {
        if (this.insightMembership.organisationId) {
            return true;
        }

        return false;
    }

    constructor(private membershipLevelService: MembershipLevelService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
        this.setDatetimePicker();
    }

    private setOptionControl() {     
        this.membershipTypeOption = select2MembershipType;
        this.membershipOrganisationOption = select2MembershipOrganisation;
        this.membershipLevelOption = select2MembershipLevel;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insightMembership']) {
            this.getMembershioLevelByProviderId(this.insightMembership.providerId);
        }
    }

    getInsightMembership() : MembershipView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightMembership;
    }

    public membershipOrganisationIdChange(value) {
        this.insightMembership.providerId = value; 
        this.insightMembership.providerIataCode = this.organisationAirlines.filter(it => it.organisationId == value)[0]?.providerIataCode ?? null;

        this.getMembershioLevelByProviderId(value);
    }

    private getMembershioLevelByProviderId(value) {
        if (!value) {
            this.membershipLevels$.next(null);
            this.changeDetectionRef.detectChanges();
            return;
        }

        if (this.membershipDict[value]?.length) {
            this.membershipLevels$.next(this.membershipDict[value]);
            this.changeDetectionRef.detectChanges();
        } else {
            this.membershipLevelService.getByProvider(value)
                .subscribe(
                    (response: MembershipLevelModel[]) => {
                        this.membershipDict[value] = response;
                        this.membershipLevels$.next(response);
                        this.changeDetectionRef.detectChanges();
                    }
                )
        }
    }

    public membershipTypeChange(value) {
        this.insightMembership.membershipTypeCode = value; 
        this.insightMembership.membershipTypeName = this.membershipTypeReferences.filter(it => it.membershipTypeCode == value)[0]?.membershipTypeName ?? null;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.membershipDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.membershipDetailForm.controls['membershipOrganisationId'].valid) {
            return 'Membership Organisation' + this.ERROR_REQUIRED;
        } else if (!this.membershipDetailForm.controls['memberNumber'].valid) {
            return 'Membership Number' + this.ERROR_REQUIRED;
        }
    }

    membershipLevelhange(value) {
        this.insightMembership.membershipLevelCode = value;
        this.insightMembership.membershipLevelId = this.membershipLevels$.value?.filter(level => level.membershipLevelCode == value)[0]?.membershipLevelId;
    }

    public onExpiryDateChanged(e) {
        this.insightMembership.expiryDate = e
    }

    public onIssueDateChanged(e) {
        this.insightMembership.issueDate = e
    }

    private setDatetimePicker() {
        this.calendarOption = individualExpireDocumentCalendarOption;
        this.calendarOption.minDate = new Date(this.MIN_DATE);
        this.calendarOption.maxDate = new Date(this.MAX_DATE);
    }
}
import { UntypedFormControl, Validators } from "@angular/forms";
import { Globals } from "src/app/app.global-variable";

export const GeneralFormOption = {
    code: new UntypedFormControl(null, [Validators.required, Validators.pattern(Globals.CODE_PATTERN)]),
    name: new UntypedFormControl(null, Validators.required),
    vehicleGroup: new UntypedFormControl(null, Validators.required),
    vehicleType: new UntypedFormControl(null, Validators.required),
    status: new UntypedFormControl(null, Validators.required),
    commitByName: new UntypedFormControl({ value: null, disabled: true }),
    commitDateTime: new UntypedFormControl({ value: null, disabled: true })
}
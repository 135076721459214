export class ProductInventoryServiceCateViewModel {
    public productIdSegment: string;
    public productNameSegment: string;
    public hierarchyKeySegment: string;
	public hierarchyKey: string;	
	public productPointId: string;		 
	public sortSequenceFrom: number;	 
	public locationIdFrom: string;	 
	public locationCodeFrom: string;	 
	public locationNameFrom: string;	 
    public sortSequenceTo: number;	 
	public locationIdTo: string;	 
	public locationCodeTo: string;	 
	public locationNameTo: string;	 
    public productId: string;
	public productName: string;	 
	public productInventoryId: string;	 	 
	public serviceCategoryCode: string;	 
	public serviceCategoryName: string;	 
	public sellCapacity: number;	 
	public overSellFactor: number;	 
	public groupCapacity: number;
	public sortSequenceServiceCate: number;	
	public sortSequenceSalesBucket: number;
	public serviceCategoryColor: string;	 
}
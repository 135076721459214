import { Injectable } from '@angular/core';
import { AlertBarNotifier } from './alertbar-notifier'
@Injectable({
    providedIn: 'root'
})
export class AlertBarService {
    public alertBarNotifier = new AlertBarNotifier();

    public show(panelName: string, errorMessage: string): void {
        this.alertBarNotifier.showError(panelName, errorMessage);
    }

    public hide(): void {
        this.alertBarNotifier.hideError();
    }

    public showWarning(warningMessage: string): void {
        this.alertBarNotifier.showWarning(warningMessage);
    }

    public hideWarning(): void {
        this.alertBarNotifier.hideWarning();
    }
    public showInformation(panelName: string, informationMessage: string): void {
        this.alertBarNotifier.showInformation(panelName, informationMessage);
    }

    public hideInformation(): void {
        this.alertBarNotifier.hideInformation();
    }

    public showSuccess(successMessage: string): void {
        this.alertBarNotifier.showSuccess(successMessage);
    }

    public hideSuccess(): void {
        this.alertBarNotifier.hideSuccess();
    }
}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SpecialServiceAddCommandModel,
  SpecialServiceEditCommandModel,
  SpecialServiceSearchCommandModel,
  SpecialServiceViewModel
} from '../../models/product-model/specialservice-model';

import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialServiceService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/product"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public addProduct(model: SpecialServiceAddCommandModel) {
    return this.httpPost(this.PRODUCT_PATH + "/specialservice", model);
  }

  public editProduct(model: SpecialServiceEditCommandModel) {
    return this.httpPut(this.PRODUCT_PATH + "/specialservice", model);
  }

  public getByProductId(productId) {
    return this.httpGet<any>(this.PRODUCT_PATH + "/specialservice/" + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public search(searchCommand: SpecialServiceSearchCommandModel): Observable<SpecialServiceViewModel[]> {
    return this.httpPost<SpecialServiceViewModel[]>(this.PRODUCT_PATH + '/specialservice/search', searchCommand)
      .pipe(
        map(res => res.body)
      )
  }

  public delete(productId: string) {
    return this.httpDelete(this.PRODUCT_PATH + "/specialservice/" + productId, null);
  }

  public copy(rootProductId: string, model: SpecialServiceEditCommandModel) {
    return this.httpPost(this.PRODUCT_PATH + "/specialservice/copy/" + rootProductId, model);
  }

  public getServiceRequest() {
    return this.httpGet<any>(this.PRODUCT_PATH + "/specialservice/servicerequest", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getInventory(productId: string) {
    return this.httpGet<any>(this.PRODUCT_PATH + "/specialservice/inventory/" + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public changeStatus(productId: string, newStatus: string) {
    return this.httpPut(this.PRODUCT_PATH
        + "/specialservice/status?productId=" + productId
        + "&statusCode=" + newStatus, null);
  }
}
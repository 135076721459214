import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { OopsCategory } from "src/app/core/components/categories/oops-category.model";

import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PAGE_TITLE, MODULE_NAME } from "../../../settings-management-content/shared/title-constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { VehicleTabsComponent } from "./vehicle-tabs/vehicle-tabs.component";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { EditButtonModel, NewButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { takeUntil } from "rxjs/operators";
import { VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { ActionService } from "src/app/core/utils/action.service";
import { SecurityGroupService } from "src/app/core/services/system-services/security-group.service";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-content',
    templateUrl: './vehicle-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class VehicleContentComponent implements AfterViewInit, OnDestroy {
    readonly VEHICLECOMPOSITION = 'VEHICLECOMPOSITION';
    readonly VEHICLECONFIGURATION = 'VEHICLECONFIGURATION';

    @ViewChild(VehicleTabsComponent) vehicleTabComponent: VehicleTabsComponent;
    private previousTitle: string = PAGE_TITLE.settings;
    private defaultTitle = PAGE_TITLE.vehicle;
    private unsubscribe$ = new Subject();
    public title: string = this.defaultTitle;
    public selectedItem: OopsCategory;
    public selectedVehicleConfiguration: VehicleConfigurationModel;
    public refresh: boolean = false;
    actionBarHandler = new ActionBarHandlerModel(new RefreshButtonModel());
    userSecurityVehicleComposition: SecurityGroupSecurityModel
    userSecurityVehicleConfig: SecurityGroupSecurityModel
    private currentTabId: string = "";
    private _navigationSubscription: Subscription;
    
    constructor(private navigationService: NavigationService, 
        private actionbarService: ActionbarService,
        private securityGroupService: SecurityGroupService,
        private actionService: ActionService) { }

    ngAfterViewInit(): void {
        this.securityGroupService.userSecurityGroupSecurity.subscribe(userSecurity => {
            this.userSecurityVehicleComposition = userSecurity.find(s => s.securityCode == this.VEHICLECOMPOSITION)
            this.userSecurityVehicleConfig = userSecurity.find(s => s.securityCode == this.VEHICLECONFIGURATION)

            this.actionbarService.updateState(this.actionBarHandler);
            this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
                this.toolbarClicked(actionId);
            });
        })
        this.backToSettings();
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.refresh) {
            this.refreshItem();
        }

        if (e == ACTION_STATUS.back) {
            this.navigationService.navigate('/main/settings/home', this.previousTitle, null, {});
        }

        if (e == ACTION_STATUS.new) {
            this.gotoNewItem();
        }

        if (e == ACTION_STATUS.edit) {
            this.gotoEditItem();
        }
    }

    readonly NEW_TAB_ID =  'NEW';
    readonly SEARCH_TAB_ID =  'SEARCH';
    onSelectedTabChange(activeId: string) {
        this.currentTabId = activeId;

        if (this.NEW_TAB_ID == activeId) {
            this.gotoNewItem();
        } else if (this.SEARCH_TAB_ID == activeId) {
            this.title = this.displayHeader();
            this.handleActionBar();
        } else {
            this.title = this.defaultTitle;
        }
    }

    onRowSelected(item: OopsCategory) {
        this.selectedItem = item;
    }

    private displayHeader(): string {
        let title: string;
        if (this.selectedItem) {
            title = this.createTitle(this.selectedItem.name);
        } else {
            title = this.defaultTitle;
        }
        return title;
    }

    private gotoNewItem() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.vehicle_config:
                    this.gotoNewVehicleConfiguration();
                    break;
                case MODULE_NAME.vehicle_composition:
                case MODULE_NAME.seatmap:
                    this.gotoNewVehicleComposition();
                    break;
            }
        }
    }

    private gotoNewVehicleConfiguration() {
        let title = this.displayHeader();
        this.navigationService.navigate('/main/settings/vehicle-configuration/details', title);
    }

    private gotoNewVehicleComposition() {
        let title = this.displayHeader();
        this.navigationService.navigate("/main/settings/vehicle-composition/details", title);
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private createTitle(name: string): string {
        if (name.toLowerCase() == MODULE_NAME.seatmap) {
            return PAGE_TITLE.vehicle_configuration;
        }
        return name + ' Management';
    }

    private handleActionBar() {
        switch(this.selectedItem?.name.toLowerCase()) {
            case MODULE_NAME.vehicle_composition:
                const newBtnVehicleComposition = new NewButtonModel()
                newBtnVehicleComposition.disabled = !this.userSecurityVehicleComposition?.newFlag
                this.actionBarHandler = new ActionBarHandlerModel(newBtnVehicleComposition, new RefreshButtonModel());
                break;
            case MODULE_NAME.vehicle_config:
                const newBtnVehicleConfig = new NewButtonModel()
                newBtnVehicleConfig.disabled = !this.userSecurityVehicleConfig?.newFlag
                this.actionBarHandler = new ActionBarHandlerModel(newBtnVehicleConfig, new RefreshButtonModel());
                break;
            case MODULE_NAME.seatmap:
                this.actionBarHandler = new ActionBarHandlerModel(new EditButtonModel(), new RefreshButtonModel());
                break;
        }
        this.actionbarService.updateState(this.actionBarHandler);
    }

    private gotoEditItem() {
        if (this.selectedItem?.name.toLowerCase() == MODULE_NAME.seatmap) {
            this.gotoEditVehicleConfiguration();
        }
    }

    onVehicleConfigurationSelectedItem(item) {
        this.selectedVehicleConfiguration = item;
    }

    gotoEditVehicleConfiguration() {
        if (this.selectedVehicleConfiguration) {
            let params = {
                id: this.selectedVehicleConfiguration.vehicleConfigurationId,
                seatmap: true
            }
            this.navigationService.navigate('main/settings/vehicle-configuration/details', null, null, params);
        }
    }

    private refreshItem() {
        if (this.currentTabId.toLowerCase() == "categories") {
            this.vehicleTabComponent.vehicleGroupComponent.getVehicleGroup();
        }

        if (this.currentTabId.toLowerCase()  == "search" && this.selectedItem) {
            this.refresh = true;
        }
    }

    public onRefreshed() {
        this.refresh = false;
    }

    addAction() {
        this.actionService.add(null, null, null);
    }

    private backToSettings() {
        if (this.navigationService.getParams()?.home) {
            this.toolbarClicked(ACTION_STATUS.back)
        }
    }
}

import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';

import { LocationReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class LocationReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/airline/locationreferences"
    private readonly SYSTEM_PATH = "/system/locationreferences"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public getLocationReference(ids: string[] = []): Observable<LocationReferenceModel[]> {
        const locationIdParamName = 'locationIds';
        let params = this.stringHelperService.createQueryParamFromArray(locationIdParamName, ids);
        return this.httpGet<LocationReferenceModel[]>(this.REFERENCE_PATH + params, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationReferenceByName(name: string): Observable<LocationReferenceModel[]> {
        return this.httpGet<LocationReferenceModel[]>(this.REFERENCE_PATH + '/name/' + name, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationReferenceByQueryString(locationNameOrCode: string,
        locationExclude: string): Observable<LocationReferenceModel[]> {

        let params = new HttpParams()
            .set("location", locationNameOrCode)
            .set("locationExclude", locationExclude);

        return this.httpGet<LocationReferenceModel[]>(this.REFERENCE_PATH, params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getLocationInOrderProductPoint(): Observable<LocationReferenceModel[]> {
        return this.httpGet<LocationReferenceModel[]>(this.REFERENCE_PATH + '/orderProductPoint', null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
    public getOrganisationDefaultLocation(): Observable<LocationReferenceModel> {
        return this.httpGet<LocationReferenceModel>(this.REFERENCE_PATH + '/organisationLocation/default', null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public getOrganisationOwnerLocation(): Observable<LocationReferenceModel> {
        return this.httpGet<LocationReferenceModel>(this.SYSTEM_PATH + '/owner-organisation', null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
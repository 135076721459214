import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject } from 'rxjs';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { VerifyResultView } from './shared/verify-result.view';
import { ValidationConstant } from '../validation/validation.constant';

@Component({
    selector: 'op-verify-result',
    templateUrl: './verify-result.component.html'
})

export class VerifyResultComponent implements AfterViewInit {
    public readonly EXPORT_FILE_NAME = "VerifyResult";

    @Input() verifyResults$ = new BehaviorSubject<VerifyResultView[]>([]);
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() showConfirmFinal: boolean = false;
    @Input() finalFlag: boolean = false;

    @Output() finalFlagChange = new EventEmitter<boolean>();

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    public focusing: boolean = true;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    };
    public confirmFinalFlag: boolean = false;

    ngAfterViewInit() {
        this.getDataGridHeader();
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data);
            })
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private mapData(data: VerifyResultView): any {
        return {
            No: data.no,
            Date: data.date,
            Day: data.day,
            Departure: data.departure,
            Arrival: data.arrival,
            ScheduleStatus: data.scheduleStatus,
            OperationalStatus: data.operationalStatus,
            Verification: data.verification,
            Result: data.result
        };
    }

    public onFinalFlagChange(finalFlag: boolean) {
        this.finalFlagChange.emit(finalFlag);
    }

    public getValidateName(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = ValidationConstant.VALIDATION_MENDATORY_NAME;
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = ValidationConstant.VALIDATION_CONDITION_NAME;
                break;
        }
        return returnValue;
    }

    public getValidateClass(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = 'status-mendatory';
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = 'status-condition';
                break;
        }
        return returnValue;
    }

}
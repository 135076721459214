import { 
    AfterViewInit, 
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, 
    EventEmitter, 
    Input, 
    Output, 
    ViewChild 
} from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { OrganisationTypeSelect2MultipleTypeOption } from '../../../../../shared/system-component-configurations';
import { OrganisationTypeReferenceModel } from "src/app/core/models/organisation-model";
import { OrganisationTypeReferenceService } from "src/app/core/services/organisation-services";

import { SecurityGroupMapperService } from "../shared/security-group-mapper.service";
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupOrganisationModel } from 'src/app/core/models/security-model/security-group-organisation.model';

@Component({
  selector: 'op-organisation-type',
  templateUrl: './organisation-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SecurityGroupMapperService
  ]
})
export class OrganisationTypeComponent implements AfterViewInit {
    private readonly OWNER_TYPE = 'OWNER';

    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() selectedOrganisationTypes: string[];
    @Input() isFilterPanel: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() newOrganisation: boolean = false;
    @Input() securityGroupId: string;
    @Input() typeInvalid: boolean = false;

    @Output() organisationTypeChange = new EventEmitter<string[]>();
    @Output() onSearch = new EventEmitter();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public model = new Array();
    public typeOption: any;
    public showTypeRow: boolean = true;
    public focusing: boolean = false;

    constructor(private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private securityGroupMapperService: SecurityGroupMapperService,
        private securityGroupService: SecurityGroupService,
        private changeDetector: ChangeDetectorRef) {
            
        this.typeOption = OrganisationTypeSelect2MultipleTypeOption;
    }

    ngAfterViewInit(): void {
        this.loadOrganisationTypeOptions();
    }
    
    typeChange(value) {
        this.selectedOrganisationTypes = value;
        this.typeInvalid = false;
        if (!this.selectedOrganisationTypes || this.selectedOrganisationTypes.length === 0) {
            this.typeInvalid = true;
        }
        this.organisationTypeChange.emit(value);
    }  

    public delete() {
        this.selectedOrganisationTypes = [];
        this.organisationTypeChange.emit([]);
    }
    
    get editFlag() {
        return this.isFilterPanel || (this.userSecurity?.editFlag || this.newOrganisation);
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.onSearch.emit();
                break;
            default:
                break;
        }
    }

    public isOwnerType(type: string): boolean {
        return type == this.OWNER_TYPE;
    }

    private loadOrganisationTypeOptions() {
        this.organisationTypeReferenceService.searchOrganisationType("")
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.securityGroupMapperService.organisationTypeReferenceSelect2Data(responses);
                    this.loadSecurityGroupOrganisation();
                    this.changeDetector.detectChanges();
                }
            );
    }

    public loadSecurityGroupOrganisation() {
        this.securityGroupService.getSecurityGroupOrganisation(this.securityGroupId)
            .subscribe(
                (responses: SecurityGroupOrganisationModel[]) => {
                    this.fillSecurityGroupResponse(responses);
                }
            );
    }

    public fillSecurityGroupResponse(securityOrganisationModels: SecurityGroupOrganisationModel[]) {
        if (!securityOrganisationModels || securityOrganisationModels.length === 0) {
            this.selectedOrganisationTypes = null;
        } else {
            this.selectedOrganisationTypes = this.securityGroupMapperService.mapSecurityGroupOrganisationCodes(securityOrganisationModels);
        }
        this.organisationTypeChange.emit(this.selectedOrganisationTypes);
        this.typeInvalid = false;
        this.changeDetector.detectChanges();
    }

    public validateOrganisationType(): boolean {
        this.typeInvalid = (!this.selectedOrganisationTypes) || this.selectedOrganisationTypes.length <= 0;
        return !this.typeInvalid
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { TransportSearchConditionView } from "../shared/transport-search-condition.view";
import { ACTION_HANDLER_STATUS } from "src/app/shared/ui/actionbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";

@Component({
    selector: 'op-transport-search-filter-name',
    templateUrl: 'transport-filter-name.component.html'
})
export class TransportSearchFilterNameComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorNameRequired = 'Filter Name is required.';
    @Input() actionHandlerMode: string
    @Input() advanceSearchMode: boolean;
    @Input() transportSearchConditionView: TransportSearchConditionView;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() formStatus = new EventEmitter<boolean>();
    focusing = false;
    isOwnerFilter = false;
    public publicFilterDisabled: any;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(fb: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['transportSearchConditionView']) {
            this.formGroup = this.convertViewToFormGroup(this.transportSearchConditionView);
            this.formGroup.statusChanges
                .subscribe(val => this.onFormStatusChange());
            this.setDisablePublicFilter();
        }

        if (changes['actionHandlerMode']) {
            this.setDisablePublicFilter();
        }
    }

    public initForm() {
        this.formGroup = this.convertViewToFormGroup({} as TransportSearchConditionView);
        this.setDisablePublicFilter();
        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private convertViewToFormGroup(view: TransportSearchConditionView): UntypedFormGroup {
        this.isOwnerFilter = view.isOwnerFilter;
        return new UntypedFormGroup({
            productId: new UntypedFormControl(view.productId),
            productName: new UntypedFormControl(view.productName, Validators.required),
            publicFilter: new UntypedFormControl(view.isOwnerFilter != null ? !view.isOwnerFilter : null)
        })
    }

    private convertFormGroupToModel(productId: string, formGroup: UntypedFormGroup): TransportSearchConditionView {
        let model = {} as TransportSearchConditionView;
        model.productId = productId;
        model.productName = formGroup.controls["productName"]?.value;
        model.isOwnerFilter = !formGroup.controls["publicFilter"]?.value;
        return model;
    }

    public setDisablePublicFilter() {
        // TODO: Check with security code here later
        let disabled = true;
        switch (this.actionHandlerMode) {
            case ACTION_HANDLER_STATUS.none:
            case ACTION_HANDLER_STATUS.new:
            case ACTION_HANDLER_STATUS.editingNew:
                disabled = null;
                break;
            case ACTION_HANDLER_STATUS.editing:
                if (this.isOwnerFilter) {
                    disabled = null;
                }
                break;
        }
        if (this.publicFilterDisabled != disabled) {
            this.publicFilterDisabled = disabled;
        }
    }

    public validateForm(): boolean {
        this.startProcessing();
        this.changeDetectionRef.detectChanges();
        return this.validForm();
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productName"].valid) {
            return this.errorNameRequired;
        }
    }

    public getSaveModel(saveAs: boolean = false): TransportSearchConditionView {
        let model = this.convertFormGroupToModel(saveAs ? null : this.formGroup.controls['productId']?.value, this.formGroup);
        return model;
    }

    public clearForm() {
        this.completeProcessing();
        this.changeDetectionRef.detectChanges();
    }

    public updatePublicFilter(publicFilter: boolean) {
        this.formGroup.patchValue({
            publicFilter: publicFilter
        });
        this.changeDetectionRef.detectChanges();
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PhoneTypeSelectionComponent } from "src/app/core/components/phone-type-selection/phone-type-selection.component";
import { CommunicationPhoneTypeReferenceService } from "src/app/core/services/system-services";
import { CommunicationPhoneTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightPassengerCommunicationPhoneModel } from "src/app/core/models/individual-model";
import { InsightPassengerService } from "src/app/core/services/individul-services";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

@Component({
    selector: 'op-preset-phone-type-selection',
    templateUrl: './phone-type-selection.component.html',
    providers: [
        CommunicationPhoneTypeReferenceService
    ]
})
export class PresetPhoneTypeSelectionComponent extends PhoneTypeSelectionComponent {
    public loadComplete$ = new Subject();
    
    @Input() individualId: string;
    @Output() presetSelected = new EventEmitter<InsightPassengerCommunicationPhoneModel>();
    
    private originalCommunicationPhoneTypeReferences: CommunicationPhoneTypeReferenceModel[];
    private phones$ = new BehaviorSubject<InsightPassengerCommunicationPhoneModel[]>(null);

    constructor(communicationPhoneTypeReferenceService: CommunicationPhoneTypeReferenceService,
        changeDetector: ChangeDetectorRef,
        private insightPassengerService: InsightPassengerService) {
        super(communicationPhoneTypeReferenceService,
            changeDetector);
    }

    ngOnInit(): void {
        this.getPresetPhones(this.individualId);
        super.ngOnInit();
    }

    public getPresetPhones(individualId: string) {
        this.removePresetOptions();
        this.loadComplete$ = new Subject();
        this.loadSuccess = false;
        combineLatest([
            this.communicationPhoneTypeReference$,
            this.phones$
        ])
        .pipe(takeUntil(this.loadComplete$))
        .subscribe(
            ([communicationPhoneTypeReferences, phones]) => {
                if (communicationPhoneTypeReferences?.length && phones?.length) {
                    this.loadSuccess = true;
                    this.loadComplete$.next();
                    this.loadComplete$.complete();
                }

                this.originalCommunicationPhoneTypeReferences = this.originalCommunicationPhoneTypeReferences || communicationPhoneTypeReferences;
                this.setPresetPhoneOptions();
            }
        )
        this.getInsightPassenger(individualId);
    }

    private setPresetPhoneOptions() {
        if (!this.phones$.value?.length) return;
        const individualPhones: Select2Data[] = this.phones$.value.map(phone => {
            return {
                disabled: false,
                id: phone.communicationPhoneId,
                text: `${phone.communicationPhoneTypeName} ${phone.communicationPhonePrefix} ${phone.phoneNumber}`,
                selected: false,
                action: phone.communicationPhoneId
            };
        })
        const data: Select2Data[] = (this.originalCommunicationPhoneTypeReferences ?? [])
            ?.map(d => new Select2Data(d.communicationPhoneTypeCode, d.communicationPhoneTypeName));
        this.populateSelect2Datas([
            ...individualPhones,
            ...data
        ])
    }

    public removePresetOptions() {
        if (this.originalCommunicationPhoneTypeReferences?.length) {
            const data: Select2Data[] = (this.originalCommunicationPhoneTypeReferences ?? [])
                ?.map(d => new Select2Data(d.communicationPhoneTypeCode, d.communicationPhoneTypeName));
            this.populateSelect2Datas(data);
            this.phones$.next([]);
        }
    }

    private getInsightPassenger(individualId: string) {
        if (!individualId) return;
        this.insightPassengerService.getInsightPassenger(individualId)
            .subscribe(val => {
                this.phones$.next(val.insightPassengerCommunicationPhones);
            })
    }

    onActionClick(action: string) {
        const phone = this.phones$.value?.find(phone => phone.communicationPhoneId == action);
        this.presetSelected.emit(phone);
    }
}
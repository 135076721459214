import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { MerchandizeViewModel } from "src/app/core/models/product-model/merchandize-model";
import { ACTION_HANDLER_STATUS } from "src/app/shared/ui/actionbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";

@Component({
    selector: 'op-merchandize-search-filter-name',
    templateUrl: 'merchandize-filter-name.component.html'
})
export class MerchandizeSearchFilterNameComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorNameRequired = 'Filter Name is required.';
    @Input() actionHandlerMode: string
    @Input() advanceSearchMode: boolean;
    @Input() merchandizeView: MerchandizeViewModel;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() formStatus = new EventEmitter<boolean>();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing = false;
    public isOwnerFilter = false;
    public publicFilterDisabled : any;

    constructor(fb: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef
        ) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['merchandizeView']) {
            this.formGroup = this.convertViewToFormGroup(this.merchandizeView);
            this.setDisablePublicFilter();
        }
        if (changes['actionHandlerMode']) {
            this.setDisablePublicFilter();
        }
    }

    public initForm() {
        this.formGroup = this.convertViewToFormGroup(new MerchandizeViewModel());
        this.setDisablePublicFilter();
        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if(this.processing){
            this.formStatus.emit(this.formGroup.valid);
        }
    }


    public setDisablePublicFilter() {
        // TODO: Check with security code here later
        let disabled = true;
        switch(this.actionHandlerMode) {
            case ACTION_HANDLER_STATUS.none:
            case ACTION_HANDLER_STATUS.new:
            case ACTION_HANDLER_STATUS.editingNew:
                disabled = null;
                break;
            case ACTION_HANDLER_STATUS.editing:
                if (this.isOwnerFilter) {
                    disabled = null;
                }
                break;
        }
        if (this.publicFilterDisabled != disabled) {
            this.publicFilterDisabled = disabled;
        }
        
    }

    private convertViewToFormGroup(view: MerchandizeViewModel): UntypedFormGroup {
        this.isOwnerFilter = view.isOwnerFilter ?? false;
        return new UntypedFormGroup({
            productId: new UntypedFormControl(view.productId),
            productName: new UntypedFormControl(view.productName, Validators.required),
            publicFilter: new UntypedFormControl(!this.isOwnerFilter)
        });
    }

    private convertFormGroupToModel(productId: string, formGroup: UntypedFormGroup): MerchandizeViewModel {
        let model = new MerchandizeViewModel();
        model.productId = productId;
        model.productName = formGroup.controls["productName"]?.value;
        model.isOwnerFilter = !formGroup.controls["publicFilter"]?.value;
        return model;
    }

    public validateForm(): boolean {
        this.startProcessing();
        let result = this.validForm();
        this.changeDetectionRef.detectChanges()
        if (result) {
            this.completeProcessing();
        }
        return result;
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productName"].valid) {
            return this.errorNameRequired;
        }
    }

    public getSaveModel(saveAs: boolean = false) : MerchandizeViewModel {
        let model = this.convertFormGroupToModel(saveAs ? null : this.formGroup.controls['productId']?.value, this.formGroup);

        return model;
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { TextTypeReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class TextTypeReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/texttypereferences"

    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    public getByStatus(statusCode): Observable<TextTypeReferenceModel[]> {
        return this.httpGet<TextTypeReferenceModel[]>(this.PATH + "/status/" + statusCode, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Injectable } from "@angular/core";
import { InsightPassengerAddressModel } from "src/app/core/models/individual-model/insight-passenger-address.model";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { AddressView } from "./address-view";


@Injectable()
export class AddressMapperService {
    public addressViews(models: InsightPassengerAddressModel[]): AddressView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.addressView(i, model, models));
        }
        return views;
    }

    private addressView(index: number, model: InsightPassengerAddressModel, models: InsightPassengerAddressModel[]): AddressView {
        let useAddress = {} as InsightPassengerAddressModel;
        if (model.useAddressId) {
            useAddress = models.find(x => x.addressId == model.useAddressId);
        }

        let view = new AddressView();
        view.no = index;
        view.addressId = model.addressId;
        view.individualId = model.individualId;
        view.useAddressId = model.useAddressId;
        view.countryCode = model.countryCode;
        view.addressLine1 = model.useAddressId ? useAddress.addressLine1 : model.addressLine1;
        view.addressLine2 = model.useAddressId ? useAddress.addressLine2 : model.addressLine2;
        view.postalCode = model.useAddressId ? useAddress.postalCode : model.postalCode;
        view.state = model.useAddressId ? useAddress.state : model.state;
        view.province = model.useAddressId ? useAddress.province : model.province;
        view.city = model.useAddressId ? useAddress.city : model.city;
        view.primaryAddress = model.primaryFlag;
        view.locationId = model.useAddressId ? useAddress.locationId : model.locationId;
        view.addressTypeCode = model.addressTypeCode;
        view.addressTypeName = model.addressTypeName;
        view.countryName = model.useAddressId ? useAddress.countryName : model.countryName;
        view.locationName = model.useAddressId ? useAddress.locationName : model.locationName;
        view.organisationId = model.organisationId;
        view.locationDataSave = this.getLocationSave(view.locationId, view.locationName);
        view.addressLocked = this.setLockFlag(view);
        return view;
    }

    private setLockFlag(view: AddressView) : boolean {
        if (view.organisationId) {
            return true;
        }

        return false;
    }

    public getLocationSave(locationId: string, locationName: string) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (locationId) {
            let data = new Select2Data(locationId, locationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    public addressViewToModels(views: AddressView[]): InsightPassengerAddressModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.addressViewToModel(view));
        }
        return models;
    }

    private addressViewToModel(view: AddressView): InsightPassengerAddressModel {
        let model:InsightPassengerAddressModel = {
            addressId: view.addressId,
            individualId: view.individualId,
            useAddressId: view.useAddressId,
            primaryFlag: view.primaryAddress,
            organisationId: view.organisationId,
            addressTypeCode: view.addressTypeCode,
            addressTypeName: view.addressTypeName,
            addressLine1: view.useAddressId ? null : view.addressLine1,
            addressLine2: view.useAddressId ? null : view.addressLine2,
            postalCode: view.useAddressId ? null : view.postalCode,
            state: view.useAddressId ? null : view.state,
            province: view.useAddressId ? null : view.province,
            city: view.useAddressId ? null : view.city,
            countryCode: view.useAddressId ? null : view.countryCode,
            countryName: view.useAddressId ? null : view.countryName,
            locationId: view.useAddressId ? null : view.locationId,
            locationName: view.useAddressId ? null : view.locationName
        };

        return model;
    }
}
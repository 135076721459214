export class ProductNumberViewModel {
    public productNumberId: string;
    public productNumberTypeCode: string;
    public productNumberTypeName: string;
    public productNumber: string;
    public providerId: string;
    public providerCode: string;
    public providerIataCode: string;
    public providerName: string;
    public supplierId: string;
    public supplierName: string;
    public displaySequence: number;
    public commitLastname:string;
    public commitFirstname:string;
    public commitTitle:string;
    public commitDateTime:Date;    
}
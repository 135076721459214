import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_REVENUE_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 160,
        salesChannel1Name: "GDS",
        salesChannel2: 462,
        salesChannel2Name: "API",
        salesChannel3: 330,
        salesChannel3Name: "ISE",
        salesChannel4: 216,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 208,
        salesChannel1Name: "GDS",
        salesChannel2: 315,
        salesChannel2Name: "API",
        salesChannel3: 325,
        salesChannel3Name: "ISE",
        salesChannel4: 371,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 172,
        salesChannel1Name: "GDS",
        salesChannel2: 428,
        salesChannel2Name: "API",
        salesChannel3: 325,
        salesChannel3Name: "ISE",
        salesChannel4: 323,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 248,
        salesChannel1Name: "GDS",
        salesChannel2: 298,
        salesChannel2Name: "API",
        salesChannel3: 470,
        salesChannel3Name: "ISE",
        salesChannel4: 457,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 364,
        salesChannel1Name: "GDS",
        salesChannel2: 296,
        salesChannel2Name: "API",
        salesChannel3: 219,
        salesChannel3Name: "ISE",
        salesChannel4: 372,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 361,
        salesChannel1Name: "GDS",
        salesChannel2: 336,
        salesChannel2Name: "API",
        salesChannel3: 374,
        salesChannel3Name: "ISE",
        salesChannel4: 289,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 396,
        salesChannel1Name: "GDS",
        salesChannel2: 470,
        salesChannel2Name: "API",
        salesChannel3: 408,
        salesChannel3Name: "ISE",
        salesChannel4: 212,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 176,
        salesChannel1Name: "GDS",
        salesChannel2: 337,
        salesChannel2Name: "API",
        salesChannel3: 215,
        salesChannel3Name: "ISE",
        salesChannel4: 306,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 104,
        salesChannel1Name: "GDS",
        salesChannel2: 219,
        salesChannel2Name: "API",
        salesChannel3: 320,
        salesChannel3Name: "ISE",
        salesChannel4: 302,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 406,
        salesChannel1Name: "GDS",
        salesChannel2: 193,
        salesChannel2Name: "API",
        salesChannel3: 315,
        salesChannel3Name: "ISE",
        salesChannel4: 327,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 121,
        salesChannel1Name: "GDS",
        salesChannel2: 284,
        salesChannel2Name: "API",
        salesChannel3: 466,
        salesChannel3Name: "ISE",
        salesChannel4: 141,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 284,
        salesChannel1Name: "GDS",
        salesChannel2: 434,
        salesChannel2Name: "API",
        salesChannel3: 460,
        salesChannel3Name: "ISE",
        salesChannel4: 289,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 419,
        salesChannel1Name: "GDS",
        salesChannel2: 499,
        salesChannel2Name: "API",
        salesChannel3: 391,
        salesChannel3Name: "ISE",
        salesChannel4: 401,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 283,
        salesChannel1Name: "GDS",
        salesChannel2: 224,
        salesChannel2Name: "API",
        salesChannel3: 182,
        salesChannel3Name: "ISE",
        salesChannel4: 329,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 496,
        salesChannel1Name: "GDS",
        salesChannel2: 498,
        salesChannel2Name: "API",
        salesChannel3: 205,
        salesChannel3Name: "ISE",
        salesChannel4: 308,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 375,
        salesChannel1Name: "GDS",
        salesChannel2: 392,
        salesChannel2Name: "API",
        salesChannel3: 270,
        salesChannel3Name: "ISE",
        salesChannel4: 436,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 421,
        salesChannel1Name: "GDS",
        salesChannel2: 131,
        salesChannel2Name: "API",
        salesChannel3: 447,
        salesChannel3Name: "ISE",
        salesChannel4: 269,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 241,
        salesChannel1Name: "GDS",
        salesChannel2: 371,
        salesChannel2Name: "API",
        salesChannel3: 173,
        salesChannel3Name: "ISE",
        salesChannel4: 190,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 369,
        salesChannel1Name: "GDS",
        salesChannel2: 392,
        salesChannel2Name: "API",
        salesChannel3: 110,
        salesChannel3Name: "ISE",
        salesChannel4: 116,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 429,
        salesChannel1Name: "GDS",
        salesChannel2: 108,
        salesChannel2Name: "API",
        salesChannel3: 470,
        salesChannel3Name: "ISE",
        salesChannel4: 393,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 255,
        salesChannel1Name: "GDS",
        salesChannel2: 167,
        salesChannel2Name: "API",
        salesChannel3: 185,
        salesChannel3Name: "ISE",
        salesChannel4: 254,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 283,
        salesChannel1Name: "GDS",
        salesChannel2: 335,
        salesChannel2Name: "API",
        salesChannel3: 440,
        salesChannel3Name: "ISE",
        salesChannel4: 303,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 102,
        salesChannel1Name: "GDS",
        salesChannel2: 201,
        salesChannel2Name: "API",
        salesChannel3: 429,
        salesChannel3Name: "ISE",
        salesChannel4: 420,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 410,
        salesChannel1Name: "GDS",
        salesChannel2: 174,
        salesChannel2Name: "API",
        salesChannel3: 461,
        salesChannel3Name: "ISE",
        salesChannel4: 417,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 239,
        salesChannel1Name: "GDS",
        salesChannel2: 231,
        salesChannel2Name: "API",
        salesChannel3: 268,
        salesChannel3Name: "ISE",
        salesChannel4: 462,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 284,
        salesChannel1Name: "GDS",
        salesChannel2: 259,
        salesChannel2Name: "API",
        salesChannel3: 216,
        salesChannel3Name: "ISE",
        salesChannel4: 340,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 394,
        salesChannel1Name: "GDS",
        salesChannel2: 332,
        salesChannel2Name: "API",
        salesChannel3: 309,
        salesChannel3Name: "ISE",
        salesChannel4: 111,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 459,
        salesChannel1Name: "GDS",
        salesChannel2: 421,
        salesChannel2Name: "API",
        salesChannel3: 134,
        salesChannel3Name: "ISE",
        salesChannel4: 191,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 453,
        salesChannel1Name: "GDS",
        salesChannel2: 378,
        salesChannel2Name: "API",
        salesChannel3: 402,
        salesChannel3Name: "ISE",
        salesChannel4: 164,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 208,
        salesChannel1Name: "GDS",
        salesChannel2: 470,
        salesChannel2Name: "API",
        salesChannel3: 315,
        salesChannel3Name: "ISE",
        salesChannel4: 239,
        salesChannel4Name: "OWN"
    }
]
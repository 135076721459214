import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Observable } from 'rxjs';
import { DomainAttributeModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class DomainAttributeService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/domainattribute/"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public getDocumentDistributionAttributes(attributeGroupCode: string, attributeTypeCode: string): Observable<DomainAttributeModel[]> {
        let params = new HttpParams()
            .set('attributeGroupCode', attributeGroupCode)
            .set('attributeTypeCode', attributeTypeCode)
        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'documentDistribution', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }


    public getServiceAttributes(attributeGroupCode: string,
        productGroupCode: string,
        productTypeCode: string = "",
        productFlag: boolean = null,
        searchFlag: boolean = false) {
        let params = new HttpParams()
            .set("attributeGroupCode", attributeGroupCode)
            .set("productGroupCode", productGroupCode)
            .set("searchFlag", (searchFlag ?? false))
        if (productTypeCode) {
            params = params.set("productTypeCode", productTypeCode)
        }            
        if (productFlag || productFlag == false) {
            params = params.set("productFlag", productFlag);
        }
        return this.httpGet<any>(this.REFERENCE_PATH + 'service', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

}

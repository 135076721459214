export class UserAccountOrganisationModel {
    public userAccountId: string;
    public individualNameId: string;
    public organisationId: string;
    public organisationCallName: string;
    public userLogon: string;
    public firstName: string;
    public lastName: string;
    public securityGroupName: string;
    public status: string;
    public commitByName: string;
    public commitDateTime: string;
    public multiFactorAuthenticationDateTime: string;
}
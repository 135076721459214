import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import { select2Attribute, select2Dimension } from './season-configuration';

@Component({
    selector: 'op-ancillary-service-season',
    templateUrl: './season.component.html'
})
export class SeasonComponent {
    @Input() seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);

    public attributeOption: any = select2Attribute;
    public choiceOption: any = select2Dimension;
    public seasonCode: string | string[];
    public showSeasonRow: boolean = false;
    public focusing: boolean = false;
    
    constructor(private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillData(seasonCode: string){
        this.setShowSeasonRow(true);
        this.seasonCode = seasonCode;
        this.changeDetectionRef.detectChanges();
    }
    
    public add() {
        this.setShowSeasonRow(true);
    }

    public delete() {
        this.seasonCode = null;
        this.setShowSeasonRow(false);
    }

    public setShowSeasonRow(value: boolean) {
        this.showSeasonRow = value;
    }

    public clearSeason() {
        this.seasonCode = null;
        this.setShowSeasonRow(false);
        this.changeDetectionRef.detectChanges();
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, Subscription } from 'rxjs';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { IdentityDto } from './../../core/authentication/Dtos/identity-dto';
import { ApiServiceBase } from 'src/app/core/base/api-service-base';
import { environment } from 'src/environments/environment';
import { NavigationService } from './../../shared/utils/navigation/navigation.service';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { LoadingSpinnerService } from './../../shared/layout/loading-spinner/loading-spinner.service';

import * as AuthActions from './auth.actions';
import packageInfo from '../../../../package.json'
import { UserLoginModel } from 'src/app/core/models/user-model';

import { 
  ACCEPT_COOKIES, 
  AUTH_TOKEN, 
  REMEMBER_ME_COOKIES, 
  REMEMBER_ME_SPLIT_SYMBOL, 
  REMEMBER_ME_SELECTOR_EQUAL_DECODE,
  REMEMBER_ME_SELECTOR_EQUAL_ENCODE,
  USER_LOGON_DATA 
} from 'src/app/modules/auth/shared/constants/auth.constant';
import { REMEMBER_ME_COOKIES_SECTION } from 'src/app/modules/auth/shared/remember-me-cookies-section.enum';
import { CookiesService } from 'src/app/shared/utils/cookies.service';
import { UserAccountLogonModel } from 'src/app/core/models/organisation-model';
import { Store } from '@ngrx/store';

export const handleAuthentication = (
  token: string,
  expires_in: string,
  username: string,
  orgId: string,
  isVerifyCode: boolean,
  acceptCookies: boolean,
  rememberMe: boolean

) => {
  const authState = {
    token,
    expires_in,
    username,
    orgId,
    isVerifyCode,
    acceptCookies,
    rememberMe

  };
  const user = new UserLoginModel(
    token,
    expires_in,
    username,
    orgId,
    isVerifyCode
  );
  sessionStorage.setItem(USER_LOGON_DATA, JSON.stringify(user));
  sessionStorage.setItem(AUTH_TOKEN, authState.token);
  return AuthActions.authenticateSuccess(authState);
};

const handleError = (errorRes: any) => {
  const errorMessage = 'Invalid username or password';
  if (errorRes.status == 400) {
    return of(AuthActions.authenticateFail({ errorMessage }));
  }
};

@Injectable()
export class AuthEffects extends ApiServiceBase implements OnDestroy {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private cookiesService: CookiesService,
    private httpClient: HttpClient,
    private navigationService: NavigationService,
    private organisationService: OrganisationService,
    private loadingSpinner: LoadingSpinnerService,
    private router: Router,
    private store: Store<any>
  ) {
    super(httpClient, authService);
    this.setBaseUrl(environment.identityUrl);
  }

  authLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginStart),
      switchMap((action) => {
        let headers = new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        );
        let body = new URLSearchParams();
        body.set('username', action.username);
        body.set('password', action.password);
        body.set('client_id', environment.authentication.clientId);
        body.set('client_secret', environment.authentication.clientSecret);
        body.set('grant_type', environment.authentication.grantType);
        body.set('remember_me', this.getRememberMeFlag(action.rememberMe).toString());
        body.set('remember_me_selector', this.getRememberMeCookiesValue(REMEMBER_ME_COOKIES_SECTION.selector));
        body.set('remember_me_validator', this.getRememberMeCookiesValue(REMEMBER_ME_COOKIES_SECTION.validator));
        body.set('cookies', action.acceptCookies.toString());
        body.set('application-version', packageInfo.version);
        return this.httpPost<IdentityDto>(
          '',
          body.toString(),
          null,
          headers
        ).pipe(
          map((resData) => {
            const successCode: number = 200;
            if (resData.status === successCode) {
              let successAuthentication = handleAuthentication(
                resData.body.access_token,
                resData.body.expires_in,
                action.username,
                null,
                false,
                action.acceptCookies,
                this.getRememberMeFlag(action.rememberMe)
              );
              return successAuthentication;
            }
          }),
          catchError((error) => {
            this.loadingSpinner.hide()
            return handleError(error);
          })
        );
      })
    )
  );

  private handleAcceptCookies(acceptCookies: boolean, takeExistingExpiryDate: boolean) {
    if (acceptCookies) {
      this.cookiesService.setItem(ACCEPT_COOKIES, 
        "true", 
        environment.cookiesExpiryTime, 
        takeExistingExpiryDate);
    } else {
      this.cookiesService.setItem(ACCEPT_COOKIES, 
        "false", 
        environment.cookiesExpiryTime, 
        takeExistingExpiryDate);
    }
  }

  private handleRememberToCookies(rememberMe: boolean, 
    token: string, 
    userName: string, 
    takeExistingExpiryDate: boolean) {

    if (rememberMe) {
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      this.cookiesService.setItem(REMEMBER_ME_COOKIES, 
        userName + REMEMBER_ME_SPLIT_SYMBOL + 
        decodedJWT.rememberMeSelector + REMEMBER_ME_SPLIT_SYMBOL + 
        decodedJWT.rememberMeValidator.replace(REMEMBER_ME_SELECTOR_EQUAL_DECODE, REMEMBER_ME_SELECTOR_EQUAL_ENCODE),
        environment.cookiesExpiryTime,
        takeExistingExpiryDate);
    }
  }

  private getRememberMeFlag(rememberMeFlag: boolean): boolean {
    if (!rememberMeFlag) {
      return false;
    }
    return rememberMeFlag;
  }
  private getRememberMeCookiesValue(section: REMEMBER_ME_COOKIES_SECTION): string {
    let cookies = this.cookiesService.getItem(REMEMBER_ME_COOKIES);
    if (cookies) {
      return cookies.split(REMEMBER_ME_SPLIT_SYMBOL)[section].replace(REMEMBER_ME_SELECTOR_EQUAL_ENCODE, REMEMBER_ME_SELECTOR_EQUAL_DECODE);
    }
    return "";
  }
  authRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authenticateSuccess),
        tap((action) => this.checkSession(action))
      ),
    { dispatch: false }
  );

  autoLogon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.autoLogin),
        map(() => {
          const userData = this.authService.getCurrentUserData();
          if (!userData) {
            this.router.navigate(['/auth'])
          }
          const loadedUser = new UserLoginModel(
            userData?.token,
            userData?.username,
            userData?.expires_in,
            userData?.orgId,
            userData?.isVerifyCode
          )

          if (loadedUser.token) {
            if (!loadedUser.isVerifyCode || !loadedUser.orgId) {
              this.router.navigate(['/auth'])
            }
            return AuthActions.authenticateSuccess({
              token: loadedUser.token,
              expires_in: loadedUser.expires_in,
              username: loadedUser.username,
              orgId: loadedUser.orgId,
              isVerifyCode: loadedUser.isVerifyCode,
              acceptCookies: this.authService.cookiesAccepted(),
              rememberMe: this.authService.rememberMeAccepted()
            })
          }
          return;
        })
      )
  )

  private _navigationSubScription: Subscription;

  private checkSession(action) {
    const userData = this.authService.getCurrentUserData();
    if (userData.isVerifyCode && !!userData.orgId && userData.token) {
     return;
    } 
    this.checkPasswordExpired(action);
  }

  private checkPasswordExpired(action) {
    const errorCodeExpired = "EXPIRED";
    this.organisationService.getUserAccountLogon(action.username)
    .subscribe(
      (resp : UserAccountLogonModel[]) => {
        if (resp?.length) {
          if (resp[0].errorCode == errorCodeExpired) {
            this.saveUserAccountIdPasswordExpired(resp[0].userAccountId);
            this.navigationService.navigate('auth/change-password-expired', 'Change Password', true);
            return;
          }
        }
        this.checkRedirect(action, resp[0].userAccountId)
    });
  }

  private checkRedirect(action, userAccountId) {
    const systemPropertyCode2StepAuthentication = "2STEPAUTHENTICATION";
    this.authService.updateVerifyCode();

    this.organisationService.getOwnerOrganisation(systemPropertyCode2StepAuthentication)
      .subscribe(
        resp => {
          if (resp.length > 0 && resp[0].propertyFlag == 1) {
            this.handleCookies(action, false);
            this.navigationService.navigate(
              'authentication',
              'Authentication',
              true
            );
          } else {
            this.handleCookies(action, true);
            this.organisationService.checkByPassOrgPage(userAccountId);
          }
      })
    
  }

  private handleCookies(action, takeExistingExpiryDate: boolean) {
    if (action.acceptCookies) {
      this.handleRememberToCookies(action.rememberMe, 
        sessionStorage.getItem(AUTH_TOKEN), 
        action.username,
        takeExistingExpiryDate);
    }
    this.handleAcceptCookies(action.acceptCookies, takeExistingExpiryDate);
  }
  ngOnDestroy(): void {
    this._navigationSubScription.unsubscribe();
  }

  private saveUserAccountIdPasswordExpired(userAccountId: string) {
    this.store.dispatch(AuthActions.changePasswordExpired({ 
      userAccountId: userAccountId,
      changePasswordExpired: true
    }));
  }
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { cloneDeep } from "lodash";
import { FullCalendarComponent } from '@fullcalendar/angular';
import { calendarOptions } from './operating-product-calendar.configuration';
import { TransportService } from "src/app/core/services/product-services";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { OperatingProductCalendarMapperService } from "./operating-product-calendar.mapper.service";
import { OperatingProductCalendarView } from "./operating-product-calendar.view";
import { NavigationService } from "src/app/shared/utils/navigation";
import { TransportOperatingProductServiceCategoryViewModel } from "src/app/core/models/product-model/transport-model";
import { ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { OperatingProductCalendarPaggingView } from "./operating-product-calendar-pagging.view";
import { TabModel } from "src/app/core/models/tab";

@Component({
    selector: 'op-operating-product-calendarview',
    templateUrl: './operating-product-calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./operating-product-calendar.component.scss'],
    providers: [OperatingProductCalendarMapperService]
})
export class OperatingProductCalendarViewComponent implements AfterViewInit, OnChanges {

    public readonly SPINNER_NAME: string = "operatingProductSpinner";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    private readonly STARUS_ARRIVED = 'ARRIVED';
    private readonly STARUS_CANCELLED = 'CANCELLED';
    private readonly STARUS_DELAYED = 'DELAYED';
    private readonly STARUS_DELIVERED = 'DELIVERED';
    private readonly STARUS_DEPARTED = 'DEPARTED';
    @Input() productId: string;
    @Input() refresh: boolean;
    @Input() fullView: boolean;
    @Input() serviceCates: ProductAttributeViewModel[];
    @Input() routeName: string;
    @Output() onListViewClick = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(FullCalendarComponent) calendarComponent: FullCalendarComponent;
    public focusing: boolean = false;
    public operatingProducts: OperatingProductCalendarView[];
    public loadingNotifier = new LoadingNotifier();
    public messageSize: number = 0;
    public page: number = 1;
    public maxSize: number = 5;
    public pageSize: number = 1;
    public calendarConfigs = cloneDeep(calendarOptions);
    private minDate: Date;
    private maxDate: Date;
    private monthPaggination: OperatingProductCalendarPaggingView[] = new Array();
    private tabId: string;

    constructor(private mapperService: OperatingProductCalendarMapperService,
        private transportService: TransportService,
        private changeDetectionRef: ChangeDetectorRef,
        private navigationService: NavigationService) {
        this.calendarConfigs.dayCellContent = this.handleDayRender.bind(this);
        this.calendarConfigs.dateClick = this.handleDateClick.bind(this);
        this.getTabId();
    }

    ngAfterViewInit(): void {
        this.paginationInitial();
        this.createCustomHeader();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['productId'] || this.refreshChange(changes)) {
            this.getOperatingProductServiceCategory();
        }

        if (changes['fullView']) {
            this.updateSize();
        }
    }

    public getOperatingProductServiceCategory() {
        if (this.productId) {
            this.clearForm();
            this.loadingNotifier.show(this.SPINNER_NAME);
            this.transportService.getOperatingProductServiceCategory(this.productId)
                .subscribe(
                    (response: TransportOperatingProductServiceCategoryViewModel[]) => {
                        this.fillOperatingProduct(response);
                        this.refresh = false;
                        this.loadingNotifier.hide(this.SPINNER_NAME);
                    }
                ),
                () => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                }
        }
    }

    private fillOperatingProduct(operatingProducts: TransportOperatingProductServiceCategoryViewModel[]) {
        let operatingProductViews = this.mapperService.toViews(operatingProducts);
        this.operatingProducts = cloneDeep(operatingProductViews);
        this.minDate = this.mapperService.getMinDate(this.operatingProducts);
        this.maxDate = this.mapperService.getMaxDate(this.operatingProducts);
        this.monthPaggination = this.mapperService.getMonthPaggination(new Date(this.minDate), new Date(this.maxDate));
        this.messageSize = this.monthPaggination?.length;
        this.onPageChange(this.page);
        this.changeDetectionRef.detectChanges();
    }

    public clearForm() {
        this.operatingProducts = [];
        this.changeDetectionRef.detectChanges();
    }

    private refreshChange(changes: SimpleChanges): boolean {
        if (changes['refresh'] && this.refresh) {
            return true;
        }
        return false;
    }

    private gotoOperatingProductPage(operatingProductId: string, operatingProductSeriesId: string) {
        let params = {
            productId: operatingProductId,
            operatingProductSeriesId: operatingProductSeriesId,
            product: true
        }
        this.navigationService.navigate('main/transport/' + Date.now().toString(), "Operating Transport Management", true, params);
    }

    public onListViewButtonClick() {
        this.onListViewClick.emit();
    }

    public onPageChange(pageNum: number): void {
        let filter = this.monthPaggination.filter(x => x.no == pageNum);
        let currentDate = filter[0].date;
        let month = currentDate.getMonth() + 1;
        let monthStr = month.toString();
        if (month < 10) {
            monthStr = '0' + month;
        }
        let year = currentDate.getFullYear();
        var goto = year.toString() + '-' + monthStr + '-01';
        let calendarApi = this.calendarComponent.getApi();
        calendarApi?.gotoDate(goto);
    }

    public getPageSymbol(current: number) {
        return this.monthPaggination[current - 1].monthName;
    }

    private paginationInitial() {
        setTimeout(() => {
            var p = $('#' + this.getCalendarPaggingId());
            let toolBar = $('#' + this.getCalendarId() + ' .fc-toolbar-chunk');
            toolBar[2].appendChild(p[0]);
        }, 100);
    }

    private handleDayRender(arg) {
        let trDayAndStatus = this.createDayAndStatusRow(arg.dayNumberText, arg.date, arg.isOther);
        let trLoadFactor = this.createLoadFactorRow(arg.date, arg.isOther);
        let trAU = this.createAURow(arg.date, arg.isOther);
        let trSold = this.createSoldRow(arg.date, arg.isOther);
        let dayTable = document.createElement('table');
        dayTable.appendChild(trDayAndStatus);
        dayTable.appendChild(trLoadFactor);
        dayTable.appendChild(trAU);
        dayTable.appendChild(trSold);
        let arrayOfDomNodes = [dayTable]
        return { domNodes: arrayOfDomNodes }
    }

    private handleDateClick(arg) {
        let data = this.getDataInDate(arg.date);
        if (data?.length) {
            this.gotoOperatingProductPage(data[0].operatingProductId, data[0].operatingProductSeriesId);
            this.unselectDate();
        }
    }

    private unselectDate() {
        let calendarApi = this.calendarComponent.getApi();
        calendarApi.unselect();
    }

    public updateSize() {
        setTimeout(() => {
            let calendarApi = this.calendarComponent.getApi();
            calendarApi.updateSize();
        }, 10)
    }

    private createDayAndStatusRow(dayNumber: string, date: Date, isOther: boolean): HTMLTableRowElement {
        let tr = document.createElement('tr');
        tr.appendChild(this.createDayNumber(dayNumber));
        tr.appendChild(this.createEmptyColumn());
        tr.appendChild(this.createStatus(date, isOther));
        return tr;
    }

    private createLoadFactorRow(date: Date, isOther: boolean): HTMLTableRowElement {
        let tr = document.createElement('tr');
        if (this.serviceCates && this.hasDataInThisDate(date) && !isOther) {
            let i = 1;
            let maxSrCate = 4;
            for (let srcate of this.serviceCates) {
                if (i <= maxSrCate) {
                    tr.appendChild(this.createLoadFactorColumn(date, srcate.attributeChoiceCode));
                }
                ++i;
            }
        }
        return tr;
    }

    private createLoadFactorColumn(date: Date, srvCateCode: string): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0", "pl-1", "text-center", "pb-1", "numberInfo");
        let span = document.createElement('span');
        let loadValue = this.getLoadFactor(date, srvCateCode);
        span.innerText = loadValue.toString() + "%";
        span.classList.add('width-2', 'height-2', 'rounded-circle', 'd-block', 'pt-2');
        if (loadValue >= 80) {
            span.classList.add("dx-cell-load-80");
        } else if (loadValue >= 50) {
            span.classList.add("dx-cell-load-50");
        } else if (loadValue >= 0) {
            span.classList.add("dx-cell-load-0");
        } else {
            span.classList.add("dx-cell-load-negative");
        }
        td.appendChild(span);
        return td;
    }

    private createAURow(date: Date, isOther: boolean): HTMLTableRowElement {
        let tr = document.createElement('tr');
        if (this.serviceCates && this.hasDataInThisDate(date) && !isOther) {
            let i = 1;
            let maxSrCate = 4;
            for (let srcate of this.serviceCates) {
                if (i <= maxSrCate) {
                    tr.appendChild(this.createAUColumn(date, srcate.attributeChoiceCode));
                }
                ++i;
            }
        }
        return tr;
    }

    private createAUColumn(date: Date, srvCateCode: string): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0", "pl-2", "pb-1", "numberInfo");
        let auValue = this.getAU(date, srvCateCode);
        td.innerText = auValue.toString();
        return td;
    }

    private createSoldRow(date: Date, isOther: boolean): HTMLTableRowElement {
        let tr = document.createElement('tr');
        if (this.serviceCates && this.hasDataInThisDate(date) && !isOther) {
            let i = 1;
            let maxSrCate = 4;
            for (let srcate of this.serviceCates) {
                if (i <= maxSrCate) {
                    tr.appendChild(this.createSoldColumn(date, srcate.attributeChoiceCode));
                }
                ++i;
            }
        }
        return tr;
    }

    private createSoldColumn(date: Date, srvCateCode: string): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0", "pl-2", "pb-1", "numberInfo");
        let soldValue = this.getSold(date, srvCateCode);
        td.innerText = soldValue.toString();
        return td;
    }

    private createCustomHeader() {
        setTimeout(() => {
            let header = $('#' + this.getCalendarId() + ' .fc-scrollgrid-section.fc-scrollgrid-section-header');
            let body = $('#' + this.getCalendarId() + ' .fc-scrollgrid-sync-table tbody');
            $(header[0]).addClass('hidden');
            let tr = document.createElement('tr');
            tr.appendChild(this.createDayName("Mon"));
            tr.appendChild(this.createDayName("Tue"));
            tr.appendChild(this.createDayName("Wed"));
            tr.appendChild(this.createDayName("Thu"));
            tr.appendChild(this.createDayName("Fri"));
            tr.appendChild(this.createDayName("Sat"));
            tr.appendChild(this.createDayName("Sun"));
            body.prepend(tr)
        }, 100);
    }

    private createDayName(dayName: string): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.innerText = dayName;
        td.classList.add("text-center");
        return td;
    }

    private createStatus(date: Date, isOther: boolean): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0", "pl-4");
        td.colSpan = 2;
        if (this.hasDataInThisDate(date) && !isOther) {
            let span = document.createElement('span');
            let status: string = this.getStatus(date);
            span.innerHTML = status;
            span.classList.add('badge', 'badge-pill');
            this.setStatusColor(status, span);
            td.appendChild(span);
        }
        return td;
    }

    private setStatusColor(status: string, span: HTMLSpanElement) {
        switch (status.toUpperCase()) {
            case this.STARUS_ARRIVED:
                span.innerHTML = "Arrival";
                span.classList.add('operational-status-arrival');
                break;
            case this.STARUS_CANCELLED:
                span.innerHTML = "Cancelled";
                span.classList.add('operational-status-cancelled');
                break;
            case this.STARUS_DELAYED:
                span.innerHTML = "Delayed";
                span.classList.add('operational-status-delayed');
                break;
            case this.STARUS_DELIVERED:
                span.innerHTML = "Delivered";
                span.classList.add('operational-status-delivered');
                break;
            case this.STARUS_DEPARTED:
                span.innerHTML = "Departed";
                span.classList.add('operational-status-departed');
                break;
            default:
                span.innerHTML = "Active";
                span.classList.add('operational-status-nostatus');
                break;
        }
    }

    private createDayNumber(dayNumber: string): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0", "pl-1", "pb-2", "dayNumber");
        td.innerText = dayNumber;
        return td;
    }

    private createEmptyColumn(): HTMLTableDataCellElement {
        let td = document.createElement('td');
        td.classList.add("border-0");
        return td;
    }

    private createEmptyStatus(): string {
        let count = 18;
        let blank = "&nbsp;"
        let emptyStatus = "";
        for (let i = 0; i <= count; i++) {
            emptyStatus += blank;
            ++i;
        }
        return emptyStatus;
    }

    private getStatus(date: Date): string {
        let status = this.createEmptyStatus();
        let dataInDate = this.getDataInDate(date);
        if (dataInDate?.length) {
            if (dataInDate[0].status) {
                status = dataInDate[0].status;
            }
        }
        return status;
    }



    private hasDataInThisDate(date: Date): boolean {
        if (this.operatingProducts) {
            let filter = this.operatingProducts.filter(x =>
                new Date(x.localDate).getFullYear() == new Date(date).getFullYear() &&
                new Date(x.localDate).getMonth() == new Date(date).getMonth() &&
                new Date(x.localDate).getDate() == new Date(date).getDate()
            );
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private getDataInDate(date: Date): OperatingProductCalendarView[] {
        if (this.operatingProducts) {
            let filter = this.operatingProducts.filter(x =>
                new Date(x.localDate).getFullYear() == new Date(date).getFullYear() &&
                new Date(x.localDate).getMonth() == new Date(date).getMonth() &&
                new Date(x.localDate).getDate() == new Date(date).getDate()
            );
            return filter;
        }
        return null;
    }

    private getLoadFactor(date: Date, srvCateCode: string): number {
        let loadFactor = 0;
        let dataInDate = this.getDataInDate(date);
        let filter = dataInDate.filter(x => x.serviceCategoryCode == srvCateCode);
        if (filter?.length) {
            if (filter[0].load) {
                loadFactor = filter[0].load;
            }
        }
        return loadFactor;
    }

    private getAU(date: Date, srvCateCode: string): number {
        let au = 0;
        let dataInDate = this.getDataInDate(date);
        let filter = dataInDate.filter(x => x.serviceCategoryCode == srvCateCode);
        if (filter?.length) {
            if (filter[0].au) {
                au = filter[0].au;
            }
        }
        return au;
    }

    private getSold(date: Date, srvCateCode: string): number {
        let sold = 0;
        let dataInDate = this.getDataInDate(date);
        let filter = dataInDate.filter(x => x.serviceCategoryCode == srvCateCode);
        if (filter?.length) {
            if (filter[0].sold) {
                sold = filter[0].sold;
            }
        }
        return sold;
    }

    public diplayRouteName(): string {
        if (this.fullView) {
            return this.routeName;
        }
        return "";
    }

    private getTabId() {
        this.mapperService.getTabId().subscribe(
            (tabs: TabModel[]) => {
                let tabActive = tabs.filter(x => x.active == true);
                if (tabActive?.length) {
                    this.tabId = tabActive[0].id
                }
            }
        )
    }
    
    public getCalendarPaggingId() : string {
        return "calendarPagination" + "_" + this.tabId;
    }

    public getCalendarId() : string {
        return "calendar" + "_" + this.tabId;
    }
}
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export const PricingDetailConstant = {
    ALERT_HEADER: 'Product Eligible/Restricted',
    PRODUCT_ELIGIBLE_RESTRICTED_TYPE: 'productEligibleRestricedType',
    PRODUCT_NUMBER_TYPE: 'productNumberType',
    PROVIDER_REFERENCE_CODE: 'providerReferenceCode',
    PRODUCT_NUMBER_TEXT_INPUT: 'productNumberTextInput',
    CONDITION_REFERENCE_CODE: 'conditionReferenceCode',
    OPERATING_NUMBER_TYPE_CODE: 'OPERATING',
    PRODUCT_NUMBER_TYPE_CODE: 'PRODUCT',
    MARKETING_NUMBER_TYPE_CODE: 'MARKETING',
    FAILED_GET_PRODUCT_NUMBER_TYPE_REFERENCE: 'Failed to get Product Number Type Reference',
    FAILED_GET_PRODUCT_CATEGORY_REFERENCE: 'Failed to get Product Category Reference',
    IS_OPERATOR_CODE: '=',
    IS_NOT_OPERATOR_CODE: '!=',
    FAILED_GET_CONDITION_REFERENCE: 'Failed to get Condition Reference',
    FAILED_GET_PROVIDER_REFERENCE: 'Failed to get Provider Reference',
    FAILED_GET_PRODUCT_LOCATION_POINT_REFERENCE: 'Failed to get Product Location Point Reference',
    PRODUCT_TYPE_CODE: 'PRODUCT_TYPE',
    PRODUCT_NUMBER_CODE: 'PRODUCT_NUMBER',
    ROUTE_CODE: 'ROUTE',
    LOCATION_CODE: 'LOCATION',
    CALENDAR_CODE: 'CALENDAR',
    FORM_OF_PAYMENT_CODE: 'FORM_OF_PAYMENT',
    FAILED_GET_LOCATION_REFERENCE: 'Failed to get Location Reference',
    ORIGIN_LOCATION_SELECTOR: 'originLocationSelector',
    DESTINATION_LOCATION_SELECTOR: 'destinationLocationSelector',
    LOCATION_AREA_SELECTOR_ID: 'locationAreaSelector',
    LOCATION_AREA_PRODUCT_LOCATION_POINT_SELECTOR_ID: 'locationAreaProductLocationPointSelector',
    FAILED_GET_REGION_REFERENCE: 'Failed to get Region Reference',
    FAILED_GET_COUNTRY_REFERENCE: 'Failed to get Organisation Country',
    FAILED_GET_ORGANISATION_LOCATION: 'Failed to get Orgnaisation Location',
    FAILED_GET_LOCATION_GROUP: 'Failed to get Location Group',
    FAILED_GET_DATE_TIME_DIMENSION_REFERENCE: 'Failed to get Date Time Dimension Reference',
    REGION_REFERENCE_SELECTOR_ID: 'regionReferenceSelector',
    COUNTRY_REFERENCE_SELECTOR_ID: 'countryReferenceSelector',
    ORGANISATION_LOCATION_SELECTOR_ID: 'organisationLocationSelector',
    LOCATION_GROUP_SELECTOR_ID: 'locationGroupSelector',
    REGION_VALUE: 'REGION',
    COUNTRY_VALUE: 'COUNTRY',
    AIRPORT_CITY_VALUE: 'AIRPORT_CITY',
    LOCATION_GROUP_VALUE: 'LOCATION_GROUP',
    CALENDAR_DATE_TYPE_SELECTOR_ID: 'calendarDateTypeSelector',
    FAILED_GET_CALENDAR_VALIDITY_REFERENCE: 'Failed to get Calendar Validity Reference',
    CALENDAR_VALIDITY_REFERENCE_SELECTOR_ID: 'calendarValidityReferenceSelector',
    IS_BETWEEN_OPERATOR_CODE: '><',
    IS_NOT_BETWEEN_OPERATOR_CODE: '<>',
    CALENDAR_START_DATE_DATE_PICKER_ID: 'calendarStartDateDatepicker',
    CALENDAR_END_DATE_DATE_PICKER_ID: 'calendarEndDateDatepicker',
    PRODUCT_NUMBER_CONDITION_SELECTOR_ID: 'productNumberConditionSelector',
    CALENDAR_DATE_CONDITION_SELECTOR_ID: 'calendarDateConditionSelector',
    START_DATE_PLACEHOLDER: '<Enter Start Date>',
    END_DATE_PLACEHOLDER: '<Enter End Date>',
    START_TIME_PLACEHOLDER: '<Enter Start Time>',
    END_TIME_PLACEHOLDER: '<Enter End Time>',
    CALENDAR_DATE_TIME_CONDITION_SELECTOR_ID: 'calendarDateTimeConditionSelector',
    CALENDAR_START_DATE_DATE_TIME_PICKER_ID: 'calendarStartDateDateTimepicker',
    CALENDAR_END_DATE_DATE_TIME_PICKER_ID: 'calendarEndDateDateTimepicker',
    CALENDAR_START_TIME_TIME_PICKER_ID: 'calendarStartTimeTimepicker',
    CALENDAR_END_TIME_TIME_PICKER_ID: 'calendarEndTimeTimepicker',
    CALENDAR_TIME_CONDITION_SELECTOR_ID: 'calendarTimeConditionSelector',
    VEHICLE_CODE: 'VEHICLE',
    FAILED_GET_VEHICLE_GROUP_REFERENCE: 'Failed to get Vehicle Group Reference',
    VEHICLE_GROUP_REFERENCE_SELECTOR_ID: 'vehicleGroupReferenceSelector',
    VEHICLE_GROUP_CONDITION_SELECTOR_ID: 'vechicleGroupConditionSelector',
    FAILED_GET_VEHICLE_TYPE_REFERENCE: 'Failed to get Vehicle Type Reference',
    VEHICLE_TYPE_SELECTOR_ID: 'vehicleTypeSelector',
    VEHICLE_COMPOSITION_SELECTOR_ID: 'vehicleCompositionSelector',
    NUM_INDIVIDUAL_CODE: 'NUMBEROFIND',
    NUM_INDIVIDUAL_CONDITION_SELECTOR_ID: 'numIndividualConditionSelector',
    NUM_INDIVIDUAL_NUMBER_INPUT: 'numIndividualNumberInput',
    CALENDAR_DATE: 'DATE',
    CALENDAR_DATE_TIME: 'DATETIME',
    CALENDAR_TIME: 'TIME',
    CALENDAR_WEEKDAY: 'WEEKDAY',
    CALENDAR_HOUR: 'HOUR',
    CALENDAR_WEEKDAY_CONDITION_SELECTOR_ID: 'calendarWeekdayConditionSelector',
    NUM_DAYS: 'NUMBEROFDAYS',
    NUM_HOURS: 'NUMBEROFHOURS',
    NUM_MINUTES: 'NUMBEROFMINUTES',
    CALENDAR_DAYS_HOURS_MINUTES_NUMBER_INPUT: 'calendarDaysHoursMinuitesNumberInput',
    CALENDAR_DAYS_HOURS_MINUTES_CONDITION_SELECTOR_ID: 'calendarDaysHoursMinutesSelector',
    LESS_THAN_OPERATOR_CODE: '<=',
    GREATER_THAN_OPERATOR_CODE: '=>',
    UTC_CODE: 'UTC',
    LOCAL_CODE: 'LOCAL',
    ORGANISATION_CODE: 'ORGANISATION',
    POINT_OF_SALES_TYPE: 'TYPE',
    POINT_OF_SALES_ROLE: 'ROLE',
    POINT_OF_SALES_GROUP: 'GROUP',
    POINT_OF_SALES_SPECIFIC: 'SPECIFIC',
    SERVICES_CATEGORIES_CODE: 'SERVICES_CATEGORIES',
    SALES_BUCKET_CODE: 'SALES_BUCKET',
    SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE: 'SVCCATEGORY',
    PARENT_SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE: 'PARENTSVCCATEGORY',
    LOAD_FACTOR_CODE: 'LOADFACTOR',
    PERCENTAGE_CODE: 'PERCENTAGE',
    AVAILABLE_INVENTORY_CODE: 'AVAILABLEINV',
    SEATS_CODE: 'SEATS',
    SALES_CODE: 'SALES',
    SERVICE_CODE: 'SERVICE',
    ROUNDTRIP_DEFINITION_CODE: 'ROUNDTRIPDEF',
    STAY_IN_DAYS_ATTRIBUTE_TYPE_CODE: 'STAYINDAYS',
    STOPOVER_HOURS_ATTRIBUTE_TYPE_CODE: 'STOPOVERHOURS',
    STOPOVER_DAYS_ATTRIBUTE_TYPE_CODE: 'STOPOVERDAYS',
    STOPOVER_NIGHTS_ATTRIBUTE_TYPE_CODE: 'STOPOVERNIGHTS',
    ROUNDTRIP_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE: 'TRANSITMINUTESRT',
    ROUNDTRIP_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE: 'TRANSITHOURSRT',
    PRODUCTRESTRICTED_CODE: 'PRODUCTRESTRICTED',
    POINTOFSALES_CODE: 'POINTOFSALES',
    PROD_RESTRICTED_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE: 'TRANSITMINUTESOW',
    PROD_RESTRICTED_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE: 'TRANSITHOURSOW',
    SELECT2_WEEKDAY_DATALIST: [
        new Select2Data('MONDAY', 'Monday'),
        new Select2Data('TUESDAY', 'Tuesday'),
        new Select2Data('WEDNESDAY', 'Wednesday'),
        new Select2Data('THURSDAY', 'Thursday'),
        new Select2Data('FRIDAY', 'Friday'),
        new Select2Data('SATURDAY', 'Saturday'),
        new Select2Data('SUNDAY', 'Sunday')
    ],
    ATTRIBUTE_CODE: 'ATTRIBUTE',
    ATTRIBUTE_PRODUCT_CATEGORY_CODE: 'FEE',
    PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED: [
        {
            attributeTypeCode: 'UMNR',
            productGroupCode: 'SERVICE',
            productTypeCode: 'UMNR'
        },
        {
            attributeTypeCode: 'ONHOLDFARE',
            productGroupCode: 'SERVICE',
            productTypeCode: 'HOLD',
        },
        {
            attributeTypeCode: 'REFUNDABLE',
            productGroupCode: 'SERVICE',
            productTypeCode: 'REFUND',
        },
        {
            attributeTypeCode: 'SERVICECHANGABLE',
            productGroupCode: 'SERVICE',
            productTypeCode: 'REBOOK',
        },
        {
            attributeTypeCode: 'NAMECHANGABLE',
            productGroupCode: 'SERVICE',
            productTypeCode: 'CHANGE',
        },
        {
            attributeTypeCode: 'CANCELLATION',
            productGroupCode: 'SERVICE',
            productTypeCode: 'CANCELLATION',
        },
        {
            attributeTypeCode: 'NAMECORRECT',
            productGroupCode: 'SERVICE',
            productTypeCode: 'CORRECT',
        }
    ],
    PRICE_RULE_ATTRIBUTE_CHOICE_CODES_TO_SHOW_PRODUCT: [
        "YES",
        "PERMITTED"
    ],
    INDIVIDUAL_CODE: 'INDIVIDUAL',
    MEMBER_CODE: 'MEMBER',
    CUSTOMER_GROUP_CODE: 'CUSTOMER_GROUP',
    AGE_CODE: 'AGE',
    SOCIAL_STATUS_CODE: 'SOCIAL_STATUS',
    GENDER_CODE: 'GENDER',
    INDIVIDUAL_INTEREST_CODE: 'INDIVIDUAL_INTEREST',
    NATIONALITY_CODE: 'NATIONALITY',
    LEVEL_CODE: 'LEVEL',
    TIER_CODE: 'TIER',
    ACCUMULATE_POINT_CODE: 'ACCUMULATEPOINT',
    MEMBER_SINCE_CODE: 'MEMBERDATE',
    DATE_OF_BIRTH_CODE: 'DOB',
    LAST_SERVICE_DATE_CODE: 'SEGMENTDATE',
    CALENDAR_MONTH: 'MONTH',
    CALENDAR_YEAR: 'YEAR',
    CALENDAR_MONTH_YEAR: 'MONTHYEAR',
    CALENDAR_DAY: 'DAY',
    INDIVIDUAL_AGE_CODE: 'INDIVIDUALAGE',
    CUSTOMER_CODE: 'CUSTOMER',
    DEFAULT_PRICE_RULE_GROUP_CODE: 'PRICE',
    PRODUCT_ATTRIBUTE_CODE: 'PRODUCTATTRIBUTE',
    TRANSIT_STOPOVER_CODE: 'TRANSITANDSTOPOVER',
    DEFAULT_USAGE_TYPE_CODE: 'DATA',
    BAGGAGE_CODE: 'BAGGAGE',
    IS_PREFIX_CODE: 'IS'
}

export const AttributePriceRuleGroupCode = [
    PricingDetailConstant.PRODUCTRESTRICTED_CODE,
    PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE,
    PricingDetailConstant.ATTRIBUTE_CODE,
    PricingDetailConstant.PRODUCT_ATTRIBUTE_CODE,
    PricingDetailConstant.CUSTOMER_CODE,
    PricingDetailConstant.TRANSIT_STOPOVER_CODE,
    PricingDetailConstant.BAGGAGE_CODE
];
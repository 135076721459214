import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OopsCategoriesModule } from 'src/app/core/components/categories/oops-categories.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SpecialServiceGroupComponent } from './special-service-group.component';

@NgModule({
    declarations: [
        SpecialServiceGroupComponent
    ],
    imports: [
        CommonModule,
        OopsCategoriesModule,
        UiModule
    ],
    exports: [
        SpecialServiceGroupComponent
    ]
})
export class SpecialServiceGroupModule { }

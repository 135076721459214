import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { ValidityModel } from 'src/app/core/models/merchandizing-config';
import { CalendarValidityReferenceModel, ConditionReferenceModel, DateTimeDimensionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-special-service-validity',
    templateUrl: './validity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidityComponent {
    public readonly ERROR_END_MORETHAN_START: string = 'Start date must less than or equal end date.';
    private _jsonURL = 'assets/data/merchandizing/attributes&rules_config.json';

    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;

    @Input() validityConfig: ValidityModel;
    public focusing: boolean = false;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    constructor(private changeDetectorRef: ChangeDetectorRef, 
        private http: HttpClient) {
        this.getJSON().subscribe(data => {
            this.validityConfig = this.removeLocalTimezone(data.validity);
            this.changeDetectorRef.detectChanges();
        });
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    get inheritControlLength(): number {
        if (this.ruleComponent?.validityInheritComponent) {
            return this.ruleComponent?.validityInheritComponent?.
            inheritforms?.controls?.length;
        }
        return 0;
    }  

    public add() {
        this.ruleComponent.add();
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    private removeLocalTimezone(validityData) {
        validityData?.types[0].dateType?.forEach(dateType => {
            dateType.calendarType?.forEach(type => {
                if (type.timeZone) {
                    let localIndex = type.timeZone.findIndex(item => item.timeZoneCode == 'utc');
                    type.timeZone.splice(localIndex, 1);
                    let index = type.timeZone.findIndex(item => item.timeZoneCode == '');
                    type.timeZone.splice(index, 1);
                }
            })
        })
        return validityData;
    }

}

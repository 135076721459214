import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_FEES_REVENUE_LAST7D_COUNT_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 700,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 2,
        salesChannel1: 500,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 3,
        salesChannel1: 300,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 4,
        salesChannel1: 400,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 5,
        salesChannel1: 300,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 6,
        salesChannel1: 200,
        salesChannel1Name: "GDS"
    },
    {
        dayNo: 7,
        salesChannel1: 300,
        salesChannel1Name: "GDS"
    }  
]
import { Injectable } from '@angular/core';

export class Detail {
    headerLevel: number;
    name: string;
    dataField: string;
    alignment: string;
    ownerBand: number;
    caption: string;
    subColumn: {}[];
}

const detailsColumn: Detail[] = [
    {
        headerLevel: 0,
        name: "No",
        dataField: "no",
        alignment: "right",
        ownerBand: undefined,
        caption: "No",
        subColumn: []
    },
    {
        headerLevel: 0,
        name: "Document",
        dataField: undefined,
        alignment: "center",
        ownerBand: undefined,
        caption: "Document",
        subColumn: [{}, {}, {}, {}]
    },
    {
        headerLevel: 0,
        name: "Ledger",
        dataField: undefined,
        alignment: "center",
        ownerBand: undefined,
        caption: "Ledger",
        subColumn: [{}, {}, {}]
    },
    {
        headerLevel: 0,
        name: "Transaction",
        dataField: undefined,
        alignment: "center",
        ownerBand: undefined,
        caption: "Transaction",
        subColumn: [{}, {}, {}]
    },
    {
        headerLevel: 1,
        name: "accountableDocumentTypeName",
        dataField: "accountableDocumentTypeName",
        ownerBand: 1,
        alignment: "left",
        caption: "Type",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "documentNumber",
        dataField: "documentNumber",
        ownerBand: 1,
        alignment: "right",
        caption: "Number",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "documentDate",
        dataField: "documentDate",
        ownerBand: 1,
        alignment: "left",
        caption: "Date Time",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "orderId",
        dataField: "orderId",
        ownerBand: 1,
        alignment: "left",
        caption: "Order",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "ledgerCurrencyCode",
        dataField: "ledgerCurrencyCode",
        ownerBand: 6,
        alignment: "left",
        caption: "Currency",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "countLedger",
        dataField: "countLedger",
        ownerBand: 6,
        alignment: "right",
        caption: "Allocated",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "exchangeRate",
        dataField: "exchangeRate",
        ownerBand: 6,
        alignment: "left",
        caption: "Exchange Rate",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "transactionReference",
        dataField: "transactionReference",
        ownerBand: 10,
        alignment: "right",
        caption: "Reference",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "formOfPaymentCode",
        dataField: "formOfPaymentCode",
        ownerBand: 10,
        alignment: "left",
        caption: "External",
        subColumn: []
    },
    {
        headerLevel: 1,
        name: "voidDateTime",
        dataField: "voidDateTime",
        ownerBand: 10,
        alignment: "left",
        caption: "Void Date Time",
        subColumn: []
    }
]

@Injectable()
export class DetailsColumnCashbookService {
  getDetailsColumn(): Detail[] {
    return detailsColumn;
  }
}

export class ProductRestrictionRouteViewModel {
    public productRestrictionRouteId: string;
    public originLocationId: string;
    public originLocationCode: string;
    public originLocationName: string;
    public destinationLocationId: string;
    public destinationLocationCode: string;
    public destinationLocationName: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public hierarchyKey: string;
    public inherit: boolean;
}
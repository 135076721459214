import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
  selector: 'op-user-management',
  templateUrl: './user-management.component.html'
})
export class UserManagementComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.navigate("/main/user/home", null);
  }

}

import { UntypedFormControl, Validators } from "@angular/forms";

export const FormOption = {
    no: new UntypedFormControl(null),
    commentId: new UntypedFormControl(null),
    activityCommentId: new UntypedFormControl(null),
    commentTypeCode: new UntypedFormControl(null, Validators.required),
    classificationCode: new UntypedFormControl(null),
    commentText: new UntypedFormControl(null, Validators.required),
    commitByName: new UntypedFormControl({ value: null, disabled: true }),
    commitDateTime: new UntypedFormControl({ value: null, disabled: true })
}
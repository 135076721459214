export const SalesSummaryFilterConstant = {
    product: {
        id: 'PRODUCT',
        text: 'Product'
    },
    date: {
        id: 'DATE',
        text: 'Date'
    },
    status: {
        id: 'STATUS',
        text: 'Status'
    },
    location: {
        id: 'LOCATION',
        text: 'Location'
    }
}

export const SalesSummaryFilterTypeData = [
    SalesSummaryFilterConstant.product,
    SalesSummaryFilterConstant.date,
    SalesSummaryFilterConstant.status,
    SalesSummaryFilterConstant.location,
]

export const ProductTypeFilterConstant = {
    productCategory: {
        id: 'PRODUCTCATEGORY',
        text: 'Product Category'
    },
    productNumber: {
        id: 'PRODUCTNUMBER',
        text: 'Product Number'
    }
}

export const ProductTypeFilterTypeData = [
    ProductTypeFilterConstant.productCategory,
    ProductTypeFilterConstant.productNumber
]

export const DateTypeFilterConstant = {
    orderDate: {
        id: 'ORDERDATE',
        text: 'Order Date'
    },
    serviceDate: {
        id: 'SERVICEDATE',
        text: 'Service Date'
    }
}

export const DateTypeFilterTypeData = [
    DateTypeFilterConstant.orderDate,
    DateTypeFilterConstant.serviceDate
]

export const StatusTypeFilterConstant = {
    order: {
        id: 'ORDER',
        text: 'Order'
    },
    product: {
        id: 'PRODUCT',
        text: 'Product'
    }
}

export const StatusTypeFilterTypeData = [
    StatusTypeFilterConstant.order,
    StatusTypeFilterConstant.product
]

export const LocationTypeFilterConstant = {
    region: {
        id: 'REGION',
        text: 'Region'
    },
    country: {
        id: 'COUNTRY',
        text: 'Country'
    },
    airportCity: {
        id: 'AIRPORTCITY',
        text: 'Airport/City'
    },
    locationGroup: {
        id: 'LOCATIONGROUP',
        text: 'Location Group'
    }
}

export const LocationTypeFilterTypeData = [
    LocationTypeFilterConstant.region,
    LocationTypeFilterConstant.country,
    LocationTypeFilterConstant.airportCity,
    LocationTypeFilterConstant.locationGroup
]

export const StatusFilterConstant = {
    salesStatus: {
        id: 'SALES',
        text: 'Sales Status'
    },
    paymentStatus: {
        id: 'PAYMENT',
        text: 'Payment Status'
    },
    bookingStatus: {
        id: 'BOOKING',
        text: 'Booking Status'
    },
    deliveryStatus: {
        id: 'DELIVERY',
        text: 'Delivery Status'
    },
    outstandingBalance: {
        id: 'OUTSTANDING',
        text: 'Outstanding Balance'
    }
}

export const OrderStatusFilterTypeData = [
    StatusFilterConstant.salesStatus,
    StatusFilterConstant.paymentStatus,
    StatusFilterConstant.outstandingBalance,
]

export const ProductStatusFilterTypeData = [
    StatusFilterConstant.salesStatus,
    StatusFilterConstant.bookingStatus,
    StatusFilterConstant.deliveryStatus,
    StatusFilterConstant.paymentStatus,
    StatusFilterConstant.outstandingBalance
]

export const LocationPointTypeFilterConstant = {
    origin: {
        id: 'ORIGIN',
        text: 'Origin'
    },
    destination: {
        id: 'DESTINATION',
        text: 'Destination'
    }
}

export const LocationPointTypeFilterData = [
    LocationPointTypeFilterConstant.origin,
    LocationPointTypeFilterConstant.destination
]

export const OutstandingBalanceFilterConstant = {
    yes: {
        id: 'YES',
        text: 'Yes'
    },
    no: {
        id: 'NO',
        text: 'No'
    }
}

export const OutstandingBalanceFilterData = [
    OutstandingBalanceFilterConstant.yes,
    OutstandingBalanceFilterConstant.no
]

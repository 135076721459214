import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { MembershipLevelModel } from '../../models/reference-model/reference-general-model/membership-level.model';

@Injectable({
    providedIn: 'root'
})
export class MembershipLevelService extends ApiServiceBase {
    private readonly PATH = '/airline/MembershipLevel';

    constructor(httpClient: HttpClient,
        authService: AuthService) { 
        super(httpClient, authService);
    }

    public getMembershipLevel(): Observable<MembershipLevelModel[]> {
        return this.httpGet<MembershipLevelModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByProvider(providerId: string): Observable<MembershipLevelModel[]> {
        return this.httpGet<MembershipLevelModel[]>(this.PATH + '/provider/' + providerId, null)
            .pipe(
                map(res => res.body)
            )
    }
}

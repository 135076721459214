import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { ScheduleQueueService } from "src/app/core/services/schedule-services";
import { ScheduleQueueGridService } from "../shared/schedule-queue-grid-service";
import { ScheduleQueueMapperService } from "../shared/schedule-queue-mapper.service";

@Component({
    selector: 'op-schedule-queue-cnl-order-table',
    templateUrl: './schedule-queue-cnl-order-table.component.html'
})
export class ScheduleQueueCnlOrderTableComponent implements OnInit {

    @Input() parentScheduleQueueId: string;
    public scheduleQueueTaskData: any;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private scheduleQueueService: ScheduleQueueService,
        private scheduleQueueMapperService: ScheduleQueueMapperService,
        private scheduleQueueGridService: ScheduleQueueGridService) {
        
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        if (this.parentScheduleQueueId) {
            this.scheduleQueueService.getChildren(this.parentScheduleQueueId)
            .subscribe(
                (responses: ScheduleQueueSearchModel[]) => { 
                    this.bindData(responses);
                });
        }
    }

    private bindData(tasks: ScheduleQueueSearchModel[]) {
        if (!tasks?.length) {
            return;
        }
        let tasksSort = tasks.sort((a, b) => (a.serviceDate + a.orderNumber < b.serviceDate + b.orderNumber ? -1 : 1));
        this.scheduleQueueTaskData = this.scheduleQueueMapperService.bindTaskData(tasksSort);
        this.changeDetectorRef.detectChanges();
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == 'result') {
            this.scheduleQueueGridService.setResultCellColor(e)
        } 
    }
}
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit } from '@angular/core';
import { BehaviorSubject, forkJoin } from "rxjs";
import { CountryReferenceModel, LanguageReferenceModel } from '../../models/reference-model/reference-general-model';
import { CountryReferenceService, LanguageReferenceService } from '../../services/system-services';
import { OrderServices } from '../../services/order-services';
import { ProductPointViewModel } from '../../models/product-model/transport-model';

const LANGUAGE_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

@Component({
    selector: 'op-language-selection',
    templateUrl: './language-selection.component.html'
})
export class LanguageSelectionComponent implements OnInit, AfterViewInit {

    public _value: string | string[];
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public languageOption = LANGUAGE_OPTION;
    public loadSuccess: boolean = true;

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input('disabled') disableFlag: boolean = false;
    @Input('tabIndexNo') tabIndexNo: number = null;
    @Input() classError: string = "";
    @Output() dataChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('languageContainer', { read: ViewContainerRef }) container: ViewContainerRef;
    @ViewChild('languageTemplate', { read: TemplateRef }) template: TemplateRef<any>;

    constructor(private languageReferenceService: LanguageReferenceService,
        private changeDetectorRef: ChangeDetectorRef,
        private orderService: OrderServices,
        private countryReferenceService: CountryReferenceService) {
    }
    ngOnInit(): void {
        this.loadLanguageReferences();
    }

    ngAfterViewInit(): void {
        this.insertChildView();
    }

    insertChildView() {
        this.container?.insert(this.template.createEmbeddedView(null));
        this.onChange(this._value);
    }

    removeChildView() {
        this.container?.clear();
    }

    reloadChildView() {
        this.removeChildView();
        this.insertChildView();
        this.changeDetectorRef.detectChanges();
    }

    public loadLanguageReferences(individualLanguageCodes: string[] = []) {
        this.loadSuccess = false;
        forkJoin({
            languageReferences: this.languageReferenceService.getLanguageReferences(),
            organisationLanguageReferences: this.languageReferenceService.getLogonLanguageReferences(),
            productPoints: this.orderService.getAllProductPoint(),
            countryReferences: this.countryReferenceService.getCountryReference()
        })
        .subscribe(
            ({
                languageReferences,
                organisationLanguageReferences,
                productPoints,
                countryReferences
            }) => {
                this.languageReference$.next(this.populateLanguageReferences(
                    languageReferences,
                    organisationLanguageReferences,
                    productPoints,
                    countryReferences,
                    individualLanguageCodes
                ));
                this.onChange(this._value)
                this.reloadChildView();
                this.loadSuccess = true;
                this.changeDetectorRef.detectChanges();
            }
        )
    }
    
    private populateLanguageReferences(languageReferences: LanguageReferenceModel[],
        organisationLanguageReferences: LanguageReferenceModel[],
        productPoints: ProductPointViewModel[],
        countryReferences: CountryReferenceModel[],
        individualLanguageCodes: string[]) {
        let result = new Array<LanguageReferenceModel>();
        this.addIndividualLanguages(result, languageReferences, individualLanguageCodes);
        this.addOrganisationLanguages(organisationLanguageReferences, result);
        this.addFromProductPoints(productPoints, countryReferences, languageReferences, result);
        this.addRestLanguages(result, languageReferences);
        return result;
    }

    private addIndividualLanguages(result: LanguageReferenceModel[], 
        languageReferences: LanguageReferenceModel[],
        individualLanguageCodes: string[]) {
        if (!individualLanguageCodes?.length) return;
        const individualLanguages = languageReferences
            ?.filter(lr => individualLanguageCodes.includes(lr.languageCode))
            ?.sort(this.bySortSequenceThenByName())
        result.push(...individualLanguages);
    }

    private addOrganisationLanguages(organisationLanguageReferences: LanguageReferenceModel[], result: LanguageReferenceModel[]) {
        const primaryLanguages = organisationLanguageReferences
            ?.filter(lr => lr.primaryFlag == true)
            ?.sort(this.bySortSequenceThenByName());
        result.push(...primaryLanguages);
        
        const nonPrimaryLanguages = organisationLanguageReferences
            ?.filter(lr => !primaryLanguages?.includes(lr))
            ?.sort(this.bySortSequenceThenByName());
        result.push(...nonPrimaryLanguages);
    }

    private addFromProductPoints(productPoints: ProductPointViewModel[], countryReferences: CountryReferenceModel[], languageReferences: LanguageReferenceModel[], result: LanguageReferenceModel[]) {
        if (productPoints?.length) {
            const languageProductPoints = productPoints
                .map(pp => {
                    const countryReference = countryReferences?.find(cr => cr.countryCode == pp.countryCode);
                    const languageReference = languageReferences?.find(lr => lr.languageCode == countryReference?.languageCode);
                    return {
                        languageCode: languageReference?.languageCode,
                        languageName: languageReference?.languageName,
                        primaryFlag: null,
                        sortSequence: languageReference?.sortSequence,
                        statusCode: languageReference?.statusCode
                    };
                })
                .filter((val, index, self) => {
                    if (!val?.languageCode) {
                        return false;
                    }
                    const found = self.find(i => i.languageCode == val.languageCode);
                    return self.indexOf(found) === index;
                })
                .sort(this.bySortSequenceThenByName());
            const existLanguages = result.map(r => r.languageCode);
            const restLanguages = languageProductPoints?.filter(lr => !existLanguages.includes(lr.languageCode));
            result.push(...restLanguages);
        }
    }

    private addRestLanguages(result: LanguageReferenceModel[], languageReferences: LanguageReferenceModel[]) {
        const existLanguages = result.map(r => r.languageCode);
        const restLanguages = languageReferences
            ?.filter(lr => !existLanguages.includes(lr.languageCode))
            ?.sort(this.bySortSequenceThenByName())
        result.push(...restLanguages);
    }

    private bySortSequenceThenByName(): (a: LanguageReferenceModel, b: LanguageReferenceModel) => number {
        return (a, b) => {
            if (a.sortSequence && b.sortSequence) {
                if (a.sortSequence < b.sortSequence) {
                    return -1;
                }
                if (a.sortSequence > b.sortSequence) {
                    return 1;
                }
            }
            return a.languageName.localeCompare(b.languageName);
        };
    }

    get disableLanguage() {
        return (!this.loadSuccess) || this.disableFlag
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    public getSelectedText(): string {
        return this.languageReference$.value
            ?.find(item => item.languageCode == this._value)
            ?.languageName;
    }
}
import { ProductDateTimeCommandModel } from ".";
import { ProductEditCommandModel } from "../product-base-model";
import { ProductAttributeCommandModel } from "../product-base-model/product-attribute";
import { ServiceTimeViewModel } from "./service-time-view.model";

export class TransportEditCommandModel extends ProductEditCommandModel {
    public productScheduleVersionId: string;
    public vehicleTypeCode: string;
    public vehicleId: string;
    public vehicleCompositionId: string;
    public usageTypeCode: string;
    public productDateTimes: ProductDateTimeCommandModel[];
    public serviceTimes: ServiceTimeViewModel[];
    public referenceProductId: string;
    public routes: ProductAttributeCommandModel[];
}
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class CalendarSetRequireFieldService {

    public setRequireCalendar(ctl) {
        ctl.get('dateType').setValidators(Validators.required);
        ctl.get('dateType').updateValueAndValidity();

        ctl.get('calendarType').setValidators(Validators.required);
        ctl.get('calendarType').updateValueAndValidity();

        ctl.get('calendarOperator').setValidators(Validators.required);
        ctl.get('calendarOperator').updateValueAndValidity();

        if (ctl.get('calendarType').value.toLowerCase() == 'date' ||
            ctl.get('calendarType').value.toLowerCase() == 'datetime'
        ) {
            ctl.get('startDate').setValidators(Validators.required);
            ctl.get('startDate').updateValueAndValidity();

            ctl.get('timeZone').setValidators(Validators.required);
            ctl.get('timeZone').updateValueAndValidity();

            let value = ctl.get('calendarOperator').value;
            if (value.toLowerCase() != "<>" && value.toLowerCase() != "><") {
                ctl.get('endDate').clearValidators();
                ctl.get('endDate').updateValueAndValidity();
            }
            else {
                ctl.get('endDate').setValidators(Validators.required);
                ctl.get('endDate').updateValueAndValidity();
            }
        }

        if (ctl.get('calendarType').value.toLowerCase() == 'time') {
            ctl.get('startTime').setValidators(Validators.required);
            ctl.get('startTime').updateValueAndValidity();

            ctl.get('timeZone').setValidators(Validators.required);
            ctl.get('timeZone').updateValueAndValidity();

            let value = ctl.get('calendarOperator').value;
            if (value.toLowerCase() != "<>" && value.toLowerCase() != "><") {
                ctl.get('endTime').clearValidators();
                ctl.get('endTime').updateValueAndValidity();
            }
            else {
                ctl.get('endTime').setValidators(Validators.required);
                ctl.get('endTime').updateValueAndValidity();
            }
        }

        if (ctl.get('calendarType').value.toLowerCase() == 'day') {
            ctl.get('startDay').setValidators(Validators.required);
            ctl.get('startDay').updateValueAndValidity();

            ctl.get('endDay').setValidators(Validators.required);
            ctl.get('endDay').updateValueAndValidity();
        }

        if (ctl.get('calendarType').value.toLowerCase() == 'weekday') {
            ctl.get('weekDays').setValidators(Validators.required);
            ctl.get('weekDays').updateValueAndValidity();
        }
    }

    public clearRequireCalendar(ctl) {
        ctl.get('dateType').clearValidators();
        ctl.get('dateType').updateValueAndValidity();

        ctl.get('calendarType').clearValidators();
        ctl.get('calendarType').updateValueAndValidity();

        ctl.get('timeZone').clearValidators();
        ctl.get('timeZone').updateValueAndValidity();

        ctl.get('calendarOperator').clearValidators();
        ctl.get('calendarOperator').updateValueAndValidity();

        ctl.get('startDate').clearValidators();
        ctl.get('startDate').updateValueAndValidity();

        ctl.get('endDate').clearValidators();
        ctl.get('endDate').updateValueAndValidity();

        ctl.get('startTime').clearValidators();
        ctl.get('startTime').updateValueAndValidity();

        ctl.get('endTime').clearValidators();
        ctl.get('endTime').updateValueAndValidity();

        ctl.get('startDay').clearValidators();
        ctl.get('startDay').updateValueAndValidity();

        ctl.get('endDay').clearValidators();
        ctl.get('endDay').updateValueAndValidity();

        ctl.get('weekDays').clearValidators();
        ctl.get('weekDays').updateValueAndValidity();
    }

}
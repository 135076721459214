import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';

@Component({
    selector: 'op-pricing-dashboard',
    templateUrl: './pricing-dashboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingDashboardComponent implements AfterViewInit {
    constructor(private actionbarService: ActionbarService) {
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(new ActionBarHandlerModel(
            new NewButtonModel(),
            new RefreshButtonModel()
        ));
    }
}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocationGroup, LocationGroupDetail, LocationGroupSearch, LocationGroupSort, LocationGroupUpdateSortSequence } from "../../models/location-group-model";

@Injectable({
    providedIn: 'root'
})
export class LocationGroupService extends ApiServiceBase {
    private readonly Path = '/system/LocationGroups'

    constructor(
        httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService)
    }

    public getLocationGroups(): Observable<LocationGroup[]> {
        return this.httpGet<LocationGroup[]>(`${this.Path}`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationGroupsSearch(statusCode?: string, locationGroupNameWildcard?: string, locationId?: string): Observable<LocationGroupSearch[]> {
        const queryParams = new HttpParams({ fromObject: <any>{ statusCode, locationGroupNameWildcard, locationId } })
        return this.httpGet<LocationGroupSearch[]>(`${this.Path}/search`, queryParams)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationGroupsSort(): Observable<LocationGroupSort[]> {
        return this.httpGet<LocationGroupSort[]>(`${this.Path}/sort`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationGroupDetail(locationGroupId: string): Observable<LocationGroupDetail> {
        return this.httpGet<LocationGroupDetail>(`${this.Path}/${locationGroupId}/detail`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getLocationGroupDetailLite(): Observable<LocationGroupDetail> {
        return this.httpGet<LocationGroupDetail>(`${this.Path}/detail`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatusCode(locationGroupId: string, statusCode: string): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${locationGroupId}/statusCode`, { statusCode })
            .pipe(
                map(res => res.body)
            )
    }

    public updateSortSequence(items: LocationGroupUpdateSortSequence[]): Observable<never> {
        return this.httpPut<never>(`${this.Path}/sort`, items)
            .pipe(
                map(res => res.body)
            )
    }

    public createLocationgrouplocations(locationGroupName: string, statusCode: string, locationIdForMapped: string[], locationIdForUnmapped: string[]): Observable<string> {
        return this.httpPost<string>(`${this.Path}/locationgrouplocations`, { locationGroupName, statusCode, locationIdForMapped, locationIdForUnmapped })
            .pipe(
                map(res => res.body)
            )
    }

    public updateLocationgrouplocations(locationGroupId: string, locationGroupName: string, statusCode: string, locationIdForMapped: string[], locationIdForUnmapped: string[]): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${locationGroupId}/locationgrouplocations`, { locationGroupName, statusCode, locationIdForMapped, locationIdForUnmapped })
            .pipe(
                map(res => res.body)
            )
    }
}

declare var moment: any;

export const calendarDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}

export const calendarTimeOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    },
    isCustomDate: null
}

export const timeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'HH:mm'
    },
    timePicker24Hour: true
};
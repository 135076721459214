import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChargeItemsModel, ChargeItemsView } from 'src/app/core/models/cashbook-model';
import { OrderPaymentStatusReferenceModel, OrderSalesStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';

@Component({
  selector: 'op-charge-item',
  templateUrl: './charge-item.component.html',
})
export class ChargeItemComponent implements OnChanges{
 
  @Input() chargeItem : ChargeItemsModel;
  @Input() orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[];
  @Input() orderSalesStatusReferences: OrderSalesStatusReferenceModel[];
  public item : ChargeItemsView;

  constructor(private stringUtils: StringHelperService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chargeItem']) {
      this.item = this.mapData(this.chargeItem);
    }
  }  

  private mapData(model: ChargeItemsModel) : ChargeItemsView  {
    let view : ChargeItemsView = {
        paymentId: this.stringUtils.undefinedToEmpty(model?.paymentId),
        ledgerId: this.stringUtils.undefinedToEmpty(model?.ledgerId),
        orderId: this.stringUtils.undefinedToEmpty(model?.orderId),
        salesCurrencyCode: this.stringUtils.undefinedToEmpty(model?.salesCurrencyCode),
        accountableDocumentTypeCode: this.stringUtils.undefinedToEmpty(model?.accountableDocumentTypeCode),
        salesAmount: this.stringUtils.amountString(model?.salesAmount),
        orderPriceCurrencyCode: this.stringUtils.undefinedToEmpty(model?.orderPriceCurrencyCode),
        orderSalesCurrencyCode: this.stringUtils.undefinedToEmpty(model?.orderSalesCurrencyCode),
        orderPriceAmount: this.stringUtils.amountString(model?.orderPriceAmount),
        orderPricePercentage: this.stringUtils.undefinedToEmpty(model?.orderPricePercentage),
        orderSalesAmount: this.stringUtils.amountString(model?.orderSalesAmount),
        orderCostAmount: this.stringUtils.amountString(model?.orderCostAmount),
        orderExchangeRate: this.stringUtils.undefinedToEmpty(model?.orderExchangeRate),
        allocatedSalesAmount: this.stringUtils.amountString(model?.allocatedSalesAmount),
        productName: this.stringUtils.undefinedToEmpty(model?.productName),
        accountableDocumentNumber: this.stringUtils.undefinedToEmpty(model?.accountableDocumentNumber),
        paymentIndividualId: this.stringUtils.undefinedToEmpty(model?.paymentIndividualId),
        documentNumber: this.stringUtils.undefinedToEmpty(model?.documentNumber),
        ledgerTransactionName: this.stringUtils.undefinedToEmpty(model?.ledgerTransactionName),
        orderSalesStatusName: this.stringUtils.undefinedToEmpty(model?.orderSalesStatusName),
        outStandingAmount: this.stringUtils.amountString(model?.outStandingAmount),
        accountableDocumentTypeName: this.stringUtils.undefinedToEmpty(model?.accountableDocumentTypeName),
        salesPaid: this.stringUtils.amountString(model?.salesPaid),
        totalAmount: this.stringUtils.amountString(model?.totalAmount),
        totalPaid: this.stringUtils.amountString(model?.totalPaid)
    }       
    return view;
  }

  findHighlightColorForOrderSalesStatus(dataSet: OrderSalesStatusReferenceModel[], value: string): string {
    const code = dataSet.find(s => s.orderSalesStatusName == value)?.highlightColor
    return code
  }
  findHighlightColorForOrderPaymentStatus(dataSet: OrderPaymentStatusReferenceModel[], value: string): string {
    const code = dataSet.find(s => s.orderPaymentStatusName == value)?.highlightColor
    return code
  }
}
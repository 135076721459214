export class ProductRestrictionProductViewModel {
    public productRestrictionProductId: string;
    public restrictedProductId: string;
    public productCategoryCode: string;
    public productCategoryName: string;
    public productGroupCode: string;
    public productGroupName: string;
    public productTypeCode: string;
    public productTypeName: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public hierarchyKey: string;
    public inherit: boolean;
}
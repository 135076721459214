import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ActionbarComponent } from './actionbar/actionbar.component';
import { OnOffComponent } from './on-off/on-off.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ExportButtonGroupComponent } from './export-button-group/export-button-group.component';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SecurityIconComponent } from './security-icon/security-icon.component';
import { ExportButtonGroupDataComponent } from './export-button-group/export-button-group-data.component';
import { ActionbarDetailComponent } from './actionbar-detail/actionbar-detail.component';


@NgModule({
  declarations: [
    ActionbarComponent,
    ExportButtonGroupComponent,
    OnOffComponent,
    SecurityIconComponent,
    ExportButtonGroupDataComponent,
    ActionbarDetailComponent
  ],
  imports: [
    BsDropdownModule,
    ButtonsModule,
    CommonModule,
    DxPopoverModule,
    DxTemplateModule,
    MatIconModule,
    NgbNavModule
  ],
  exports: [
    ActionbarComponent,
    OnOffComponent,
    ExportButtonGroupComponent,
    SecurityIconComponent,
    ExportButtonGroupDataComponent,
    ActionbarDetailComponent
  ]
})
export class UiModule { }

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AppEventService {
  private eventBus: Subject<string> = new Subject<string>();
  public events: Observable<string> = this.eventBus.asObservable();

  constructor() {}

  publishEvent(eventName: string) {
    this.eventBus.next(eventName);
  }
}
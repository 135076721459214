import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";
import { OrganisationAttributeModel } from 'src/app/core/models/organisation-model';
import { OrganisationAttributeTypeModel } from 'src/app/core/models/organisation-model/organisation-attribute-type.model';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { DomainAttributeService } from 'src/app/core/services/airline-services';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { dateTimeOption, timeOption } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value-configuration';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2Attribute, select2Choice, select2ChoiceMultiple } from '../../../organisation-detail/organisation-detail-attribute/attribute/attribute-configuration';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'op-organisation-attribute-panel',
    templateUrl: './organisation-attribute-panel.component.html'
})
export class OrganisationAttributePanelComponent implements OnChanges {
    private readonly ATTRIBUTE_GROUP_CODE = "ATTRIBUTE";
    private readonly OWNER_TYPE_CODE = "OWNER";
    private readonly ERROR_REQUIRED = ' is required';
    private readonly DEFAULT_MAX_CHAR = 150;

    @Input() organisationAttribute: DomainAttributeModel[];
    @Input() id: string;
    @Input() newOrganisation: boolean = false;
    @Input() organisationTypeCodes: string[];
    @Input() existOwnerType: boolean = false;
    @Input() isFilterPanel: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;

    actionForm: UntypedFormGroup;

    public attributeOption: any = cloneDeep(select2Attribute);
    public choiceOption: any = cloneDeep(select2Choice);
    public choiceMultipleOption: any = cloneDeep(select2ChoiceMultiple);

    private organisationAttributeType$ = new BehaviorSubject<OrganisationAttributeTypeModel[]>(null);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    public organisationAttributes: OrganisationAttributeModel[];
    public attributes: OrganisationAttributeModel[];
    public edited: boolean = false;
    public focusing: boolean = false;

    @Output() onSearch = new EventEmitter();
    //Return value to inventory-attribute
    @Output() organisationAttributeValue = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public daterangepickerDateTimeOption = cloneDeep(dateTimeOption);
    public daterangepickerTimeOption = cloneDeep(timeOption);

    constructor(private fb: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private domainAttributeService: DomainAttributeService,
        private dateConverterService: DateConverterService) {

        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['organisationTypeCodes']) {
            this.getOrganisationAttributeType();
        }

        if (this.isFilterPanel) {
            this.addDomainAttributeViewToFormGroup();
        }

        this.setDateRangePickerDateTimeOption();
    }

    setDateRangePickerDateTimeOption() {
        this.daterangepickerDateTimeOption.maxDate = new Date();
        this.daterangepickerDateTimeOption.locale.format = "YYYY-MM-DD";
        this.daterangepickerDateTimeOption.timePicker = false;
    }

    public getOrganisationAttributeType() {
        if (this.existOwnerType) {
            this.addOrganisationTypeOwner();
        }

        if (this.isFilterPanel === false && (this.organisationTypeCodes?.length > 0 || this.existOwnerType)) {
            this.domainAttributeService.getOrganisationAttributes(this.organisationTypeCodes, this.ATTRIBUTE_GROUP_CODE, "")
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        this.attributes = cloneDeep(this.organisationAttributes);
                        this.domainAttribute$.next(responses);
                        if (this.newOrganisation) {
                            this.displayDomainAttributeOnNewFlag();
                        } else {
                            this.addDomainAttributeViewToFormGroup();
                        }

                        if (this.isFilterPanel == true) {
                            this.displayDomainAttributeOnSearchFlag();
                        }
                    }
                )

            return;

        } else if (this.isFilterPanel === true) {
            this.attributes = cloneDeep(this.organisationAttributes);
            this.domainAttribute$.next(this.organisationAttribute);
            this.displayDomainAttributeOnNewFlag();
            return;
        }

        this.attributes = [];
        this.addDomainAttributeViewToFormGroup();
    }

    private addOrganisationTypeOwner() {
        if (!this.organisationTypeCodes || this.newOrganisation || this.organisationTypeCodes.some(x => x == this.OWNER_TYPE_CODE) || !this.existOwnerType) {
            return;
        }

        this.organisationTypeCodes.push(this.OWNER_TYPE_CODE);
    }

    displayDomainAttributeOnNewFlag() {
        if (this.domainAttribute$.value) {
            this.clearActionForm();

            this.creatOnNewFlagAttributeFormData();
            this.changeDetectorRef.detectChanges();
        }
    }


    displayDomainAttributeOnSearchFlag() {
        if (this.domainAttribute$.value) {
            this.clearActionForm();

            this.creatOnSearchFlagAttributeFormData();
            this.changeDetectorRef.detectChanges();
        }
    }

    clearActionForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        this.changeDetectorRef.detectChanges();
    }

    private creatOnNewFlagAttributeFormData() {
        for (let domainAttribute of this.domainAttribute$.value) {
            if (domainAttribute.showOnNewFlag) {
                (<UntypedFormArray>this.actionForm.get('forms')).push(this.createDomainAttributeFormGroupEmpty(domainAttribute));
                let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                formCurrent.get('submitted').setValue(true);
                this.domainAttributeChange(domainAttribute.attributeTypeCode, formCurrent);
            }
        }
    }

    private creatOnSearchFlagAttributeFormData() {
        for (let domainAttribute of this.domainAttribute$.value) {
            if (domainAttribute.searchFlag) {
                (<UntypedFormArray>this.actionForm.get('forms')).push(this.createDomainAttributeFormGroupEmpty(domainAttribute));
                let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                formCurrent.get('submitted').setValue(true);
                this.domainAttributeChange(domainAttribute.attributeTypeCode, formCurrent);
            }
        }
    }

    createDomainAttributeFormGroupEmpty(domainAttribute: DomainAttributeModel) {
        let form = {
            'submitted': [null, [Validators.nullValidator]]
        };

        form['organisationAttributeTypeData'] = [this.domainAttribute$, [Validators.nullValidator]];

        if (domainAttribute) {
            this.setFormDataForOnNewFlagAttribute(form, domainAttribute);
        } else {
            this.setFormdataForNewAttribute(form);
        }

        let f = this.fb.group(form);
        return f;
    }

    private setFormdataForNewAttribute(form) {
        form['organisationAttributeTypeCode'] = [null, [Validators.required]];
        form['organisationAttributeTypeData'] = [this.domainAttribute$, [Validators.required]];
        form['choiceSingle'] = [null, [Validators.nullValidator]];
        form['multipleChoiceFlag'] = [false, [Validators.nullValidator]];
        form['dimensionUnitCode'] = [null, [Validators.nullValidator]];
        form['choiceData'] = [null, [Validators.nullValidator]];
        form['organisationAttributeDuplicate'] = [false, [Validators.nullValidator]];
        form['choiceMultiple'] = [null, [Validators.required]];
        form['choiceText'] = [null, [Validators.required]];
        form['choiceValue'] = [null, [Validators.required]];
        form['choicePercentage'] = [null, [Validators.required]];
        form['choiceDateTime'] = [null, [Validators.required]];
        form['choiceTime'] = [null, [Validators.required]];
        form['choiceMultipleOption'] = [this.choiceMultipleOption, [Validators.nullValidator]];
        form['organisationAttributeChoiceId'] = [null, [Validators.nullValidator]];
    }

    public getDomainAttrs(i) {
        if (this.isFilterPanel === true) {
            return this.domainAttribute$.value?.filter(att => att.searchFlag == true);
        }
        let organisationAttributeTypeCodes = this.forms.controls.map(r => r.value.organisationAttributeTypeCode);
        let currentOrganisationAttributeTypeCode = this.forms.controls[i].value.organisationAttributeTypeCode;
        organisationAttributeTypeCodes = organisationAttributeTypeCodes.filter(x => x != currentOrganisationAttributeTypeCode);
        let domainAttrs = this.domainAttribute$.value?.filter(da => {
            return organisationAttributeTypeCodes.includes(da.attributeTypeCode) == false;
        });
        if (this.isFilterPanel && domainAttrs) {
            domainAttrs = domainAttrs.filter(x => x.searchFlag);
        }
        return domainAttrs;
    }

    private setFormDataForOnNewFlagAttribute(form, domainAttribute: DomainAttributeModel) {
        form['organisationAttributeTypeCode'] = [domainAttribute.attributeTypeCode, [Validators.required]];
        form['choiceSingle'] = [null, [Validators.nullValidator]];
        form['multipleChoiceFlag'] = [domainAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form['dimensionUnitCode'] = [domainAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form['choiceData'] = [null, [Validators.nullValidator]];
        form['organisationAttributeDuplicate'] = [false, [Validators.nullValidator]];
        form['multipleChoiceFlag'] = [true, [Validators.nullValidator]];
        form['choiceMultiple'] = [null, [Validators.required]];
        form['choiceText'] = [null, [Validators.required]];
        form['choiceValue'] = [null, [Validators.required]];
        form['choicePercentage'] = [null, [Validators.required]];
        form['choiceDateTime'] = [null, [Validators.required]];
        form['choiceTime'] = [null, [Validators.required]];
        form['choiceMultipleOption'] = [this.choiceMultipleOption, [Validators.nullValidator]];
        form['organisationAttributeChoiceId'] = [null, [Validators.nullValidator]];
    }

    domainAttributeChange(value, ctl) {
        ctl.get('organisationAttributeTypeCode').setValue(value);
        this.createOutputDomainAttributeTypeValue();
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get('dimensionUnitCode').setValue(attributeFilter[0].dimensionUnitCode);
                this.bindDomainChoiceDataToForm(ctl, attributeFilter[0]);
                this.setDefaultDomainChoiceSelectedToForm(ctl, attributeFilter[0]);
            } else {
                this.bindNullDomainAttributeDataChoiceToForm(ctl)
            }
        }
        this.clearAllRequireField(ctl);
        this.setRequireField(ctl);
    }

    createOutputDomainAttributeTypeValue() {
        var attributeTypeValueSelect2DataArray: Select2Data[] = new Array();
        if (this.forms.controls.length != 0) {
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.status == "VALID") {
                    var organisationAttributeTypeCode = ctl.get("organisationAttributeTypeCode").value;
                    var organisationAttributeTypeName = this.setProductAttributeTypeName(organisationAttributeTypeCode);
                    this.setAttributeSelect2Data(organisationAttributeTypeName, organisationAttributeTypeCode, attributeTypeValueSelect2DataArray);
                }
            }
        }

        this.organisationAttributeValue.emit(attributeTypeValueSelect2DataArray);
    }

    private setAttributeSelect2Data(organisationAttributeTypeName: string, organisationAttributeTypeCode: any, attributeTypeValueSelect2DataArray: Select2Data[]) {
        if (organisationAttributeTypeName) {
            var attributeTypeValueSelect2Data = new Select2Data();
            attributeTypeValueSelect2Data.id = organisationAttributeTypeCode;
            attributeTypeValueSelect2Data.text = organisationAttributeTypeName;
            attributeTypeValueSelect2DataArray.push(attributeTypeValueSelect2Data);
        }
    }

    private setProductAttributeTypeName(organisationAttributeTypeCode: string) {
        var organisationAttributeTypeName;

        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == organisationAttributeTypeCode);
            if (attributeFilter.length > 0) {
                organisationAttributeTypeName = attributeFilter[0].attributeTypeName;
            }
        }
        return organisationAttributeTypeName;
    }

    bindNullDomainAttributeDataChoiceToForm(ctl) {
        ctl.get('choiceData').setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get('multipleChoiceFlag').setValue(false);
    }

    bindDomainChoiceDataToForm(ctl, attribute: DomainAttributeModel) {
        var choiceData = this.convertDomainChiceToSelect2Data(attribute.attributeChoices);
        ctl.get('choiceData').setValue(choiceData);
        ctl.get('multipleChoiceFlag').setValue(attribute.multipleChoiceFlag);
    }

    convertDomainChiceToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    setDefaultDomainChoiceSelectedToForm(ctl, attribute: DomainAttributeModel) {
        var filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.setValueForAttributeChoiceForm(attribute, filterDefaultChoice, ctl);
        }
    }

    private setValueForAttributeChoiceForm(attribute: DomainAttributeModel, filterDefaultChoice: AttributeChoiceModel[], ctl: any) {
        if (attribute.multipleChoiceFlag) {
            this.setMutipleChoiceDatas(filterDefaultChoice, ctl);
        } else {
            ctl.get('choiceSingle').setValue(filterDefaultChoice[0].attributeChoiceId);
        }
    }

    private setMutipleChoiceDatas(filterDefaultChoice: AttributeChoiceModel[], ctl: any) {
        var dataMultiple: string[] = new Array();
        dataMultiple.push(filterDefaultChoice[0].attributeChoiceId);
        ctl.get('choiceMultiple').setValue(dataMultiple);
        this.changeDetectorRef.detectChanges();
    }

    addDomainAttributeViewToFormGroup() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        if (!this.organisationAttributes) {
            return;
        }

        let displaySequenceAdded: number[] = new Array();
        for (let organisationAttribute of this.attributes) {
            var filterDisplaySequence = displaySequenceAdded.filter(x => x == organisationAttribute.displaySequence);
            if (!(filterDisplaySequence?.length)) {
                if (this.canGetDomainAttributeType(organisationAttribute)) {
                    let multipleChoiceFlag = this.getMultipleChoiceFlag(organisationAttribute.organisationAttributeTypeCode);
                    if (multipleChoiceFlag) {
                        var filterOrganisationAttributeTypes = this.organisationAttributes.filter(x => x.displaySequence == organisationAttribute.displaySequence);
                        var choiceMultiple: string[] = new Array<string>();
                        var choiceArrayDBId: Select2Data[] = new Array();

                        for (let organisationAttributeChoice of filterOrganisationAttributeTypes) {
                            choiceMultiple.push(organisationAttributeChoice.organisationAttributeChoiceId);

                            let dbIdData = new Select2Data();
                            dbIdData.id = organisationAttributeChoice.organisationAttributeId;
                            dbIdData.text = organisationAttributeChoice.organisationAttributeChoiceId;
                            choiceArrayDBId.push(dbIdData);
                        }

                        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupWithData(organisationAttribute, multipleChoiceFlag, choiceMultiple, choiceArrayDBId));
                    } else {
                        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupWithData(organisationAttribute, multipleChoiceFlag));
                    }
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get('submitted').setValue(true);
                    this.setRequiredFieldText(formCurrent);
                }

                displaySequenceAdded.push(organisationAttribute.displaySequence);
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    canGetDomainAttributeType(organisationAttribute: OrganisationAttributeModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == organisationAttribute.organisationAttributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }

        return returnValue;
    }

    getMultipleChoiceFlag(organisationAttributeTypeCode): boolean {
        if (organisationAttributeTypeCode) {
            if (this.domainAttribute$.value) {
                var filter = this.domainAttribute$.value.filter(x => x.attributeTypeCode == organisationAttributeTypeCode);
                if (filter.length > 0) {
                    return filter[0].multipleChoiceFlag;
                }

                return false;
            }
        }
        return false;
    }

    createFormGroupWithData(organisationAttribute,
        multipleChoiceFlag: boolean,
        choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false,
        deleteFlag: boolean = false) {

        let dimensionUnitCode = this.domainAttribute$.value.find(x => x.attributeTypeCode == organisationAttribute.organisationAttributeTypeCode)?.dimensionUnitCode;

        let form = {
            'multipleChoiceFlag': [multipleChoiceFlag, [Validators.nullValidator]],
            'dimensionUnitCode': [dimensionUnitCode, [Validators.nullValidator]],
            'choiceText': [organisationAttribute.attributeText, [Validators.nullValidator]],
            'choiceValue': [organisationAttribute.organisationAttributeValue, [Validators.nullValidator]],
            'choicePercentage': [organisationAttribute.organisationAttributeValue, [Validators.nullValidator]],
            'choiceDateTime': [organisationAttribute.attributeDateTime, [Validators.nullValidator]],
            'choiceTime': [this.dateConverterService.convertTime24(organisationAttribute.attributeDateTime), [Validators.nullValidator]],
            'organisationAttributeDuplicate': [false, [Validators.nullValidator]],
            'submitted': [true, [Validators.nullValidator]],
            'choiceSingle': []
        };

        this.createAttributeTypeForm(form, organisationAttribute, disabled);
        this.createAttributeChoiceDataForm(form, organisationAttribute);
        this.createAttributeChoiceValueForm(form, choiceArrayDBId, multipleChoiceFlag, organisationAttribute, disabled, choiceMultipleValue, dimensionUnitCode);

        if (deleteFlag) {
            this.createAttributeTypeFormDelete(form, organisationAttribute, disabled, multipleChoiceFlag)
        }

        let f = this.fb.group(form);
        return f;
    }

    createAttributeTypeFormDelete(form, organisationAttribute, disabled: boolean, multipleChoiceFlag: boolean) {
        form['multipleChoiceFlag'] = [multipleChoiceFlag, [Validators.nullValidator]];
        form['dimensionUnitCode'] = [organisationAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form['choiceText'] = [organisationAttribute.choiceText, [Validators.nullValidator]];
        form['choiceValue'] = [organisationAttribute.choiceValue, [Validators.nullValidator]];
        form['choicePercentage'] = [organisationAttribute.choicePercentage, [Validators.nullValidator]];
        form['choiceDateTime'] = [organisationAttribute.choiceDateTime, [Validators.nullValidator]];
        form['choiceTime'] = [organisationAttribute.choiceTime, [Validators.nullValidator]];
        form['organisationAttributeDuplicate'] = [organisationAttribute.organisationAttributeDuplicate, [Validators.nullValidator]];
        form['submitted'] = [organisationAttribute.submitted, [Validators.nullValidator]];
        form['choiceSingle'] = [{ value: organisationAttribute.choiceSingle, disabled: disabled }, [Validators.nullValidator]];
    }

    createAttributeTypeForm(form, organisationAttribute, disabled: boolean) {
        form['choiceMultipleOption'] = [this.choiceMultipleOption, [Validators.nullValidator]],
            form['organisationAttributeTypeCode'] = [{ value: organisationAttribute.organisationAttributeTypeCode, disabled: disabled }, [Validators.required]];
        form['organisationAttributeTypeData'] = [this.domainAttribute$, [Validators.nullValidator]];
    }

    createAttributeChoiceDataForm(form, organisationAttribute) {
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == organisationAttribute.organisationAttributeTypeCode);
            if (attributeFilter.length > 0) {
                var choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form['choiceData'] = [choiceData, [Validators.nullValidator]];
            } else {
                form['choiceData'] = [null, [Validators.nullValidator]];
            }
        }
    }

    createAttributeChoiceValueForm(form, choiceArrayDBId: Select2Data[],
        multipleChoiceFlag: boolean,
        organisationAttribute,
        disabled: boolean,
        choiceMultipleValue: string[],
        dimensionUnitCode: string) {
        form['organisationAttributeChoiceId'] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form['choiceMultiple'] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
            form['choiceSingle'] = [{ value: null, disabled: disabled }, [Validators.nullValidator]];
        } else {
            this.setAttributeChoice(form, organisationAttribute.organisationAttributeChoiceId, disabled, dimensionUnitCode);
        }

        this.changeDetectorRef.detectChanges();
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    createOutputAttributeTypeValue() {
        var attributeTypeValueSelect2DataArray: Select2Data[] = new Array();
        if (this.forms.controls.length != 0) {
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.status == "VALID") {
                    var organisationAttributeTypeCode = ctl.get("organisationAttributeTypeCode").value;
                    var organisationAttributeTypeName;
                    if (this.organisationAttributeType$.value) {
                        var attribute = this.organisationAttributeType$.value;
                        var attributeFilter = attribute.filter(x => x.organisationAttributeTypeCode == organisationAttributeTypeCode);
                        if (attributeFilter.length > 0) {
                            organisationAttributeTypeName = attributeFilter[0].organisationAttributeTypeName;
                        }
                    }
                    if (organisationAttributeTypeName) {
                        var attributeTypeValueSelect2Data = new Select2Data();
                        attributeTypeValueSelect2Data.id = organisationAttributeTypeCode;
                        attributeTypeValueSelect2Data.text = organisationAttributeTypeName;
                        attributeTypeValueSelect2DataArray.push(attributeTypeValueSelect2Data);
                    }
                }
            }
        }

        this.organisationAttributeValue.emit(attributeTypeValueSelect2DataArray);
    }

    convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    private clearAllRequireField(ctl) {
        ctl.get('choiceSingle').clearValidators();
        ctl.get('choiceSingle').updateValueAndValidity();

        ctl.get('choiceMultiple').clearValidators();
        ctl.get('choiceMultiple').updateValueAndValidity();

        ctl.get('choiceText').clearValidators();
        ctl.get('choiceText').updateValueAndValidity();

        ctl.get('choiceValue').clearValidators();
        ctl.get('choiceValue').updateValueAndValidity();

        ctl.get('choicePercentage').clearValidators();
        ctl.get('choicePercentage').updateValueAndValidity();

        ctl.get('choiceDateTime').clearValidators();
        ctl.get('choiceDateTime').updateValueAndValidity();

        ctl.get('choiceTime').clearValidators();
        ctl.get('choiceTime').updateValueAndValidity();
    }

    private setRequireField(ctl) {
        if (ctl.get('dimensionUnitCode').value == 'TEXT') {
            ctl.get('choiceText').setValidators(Validators.required);
            ctl.get('choiceText').updateValueAndValidity();
            return;
        }

        if (ctl.get('dimensionUnitCode').value == 'DATETIME' || ctl.get('dimensionUnitCode').value == 'DATE') {
            ctl.get('choiceDateTime').setValidators(Validators.required);
            ctl.get('choiceDateTime').updateValueAndValidity();
            return;
        }

        if (ctl.get('dimensionUnitCode').value == 'TIME') {
            ctl.get('choiceTime').setValidators(Validators.required);
            ctl.get('choiceTime').updateValueAndValidity();
            return;
        }

        if (ctl.get('dimensionUnitCode').value == 'NUMBER') {
            ctl.get('choiceValue').setValidators(Validators.required);
            ctl.get('choiceValue').updateValueAndValidity();
            return;
        }

        if (ctl.get('dimensionUnitCode').value == 'PERCENTAGE') {
            ctl.get('choicePercentage').setValidators(Validators.required);
            ctl.get('choicePercentage').updateValueAndValidity();
            return;
        }

        if (ctl.get('multipleChoiceFlag').value) {
            ctl.get('choiceMultiple').setValidators(Validators.required);
            ctl.get('choiceMultiple').updateValueAndValidity();
        } else {
            ctl.get('choiceSingle').setValidators(Validators.required);
            ctl.get('choiceSingle').updateValueAndValidity();
        }
    }

    createFormGroupEmpty(organisationAttributeReference) {
        let form = {
            'submitted': [null, [Validators.nullValidator]]
        };

        if (organisationAttributeReference) {
            form['organisationAttributeTypeCode'] = [organisationAttributeReference.organisationAttributeCode, [Validators.required]];
            form['choiceSingle'] = [null, [Validators.nullValidator]];
            form['multipleChoiceFlag'] = [organisationAttributeReference.multipleChoiceFlag, [Validators.nullValidator]];
            form['choiceData'] = [null, [Validators.nullValidator]];
            form['organisationAttributeDuplicate'] = [false, [Validators.nullValidator]];
            form['multipleChoiceFlag'] = [true, [Validators.nullValidator]];
            form['choiceMultiple'] = [null, [Validators.required]];
            form['choiceMultipleOption'] = [this.choiceMultipleOption, [Validators.nullValidator]];
            form['organisationAttributeChoiceId'] = [null, [Validators.nullValidator]];
        } else {
            form['organisationAttributeTypeCode'] = [null, [Validators.required]];
            form['organisationAttributeTypeData'] = [this.organisationAttributeType$, [Validators.required]];
            form['choiceSingle'] = [null, [Validators.nullValidator]];
            form['multipleChoiceFlag'] = [false, [Validators.nullValidator]];
            form['choiceData'] = [null, [Validators.nullValidator]];
            form['organisationAttributeDuplicate'] = [false, [Validators.nullValidator]];
            form['choiceMultiple'] = [null, [Validators.required]];
            form['choiceMultipleOption'] = [this.choiceMultipleOption, [Validators.nullValidator]];
            form['organisationAttributeChoiceId'] = [null, [Validators.nullValidator]];
        }

        if (this.newOrganisation) {
            form['organisationAttributeTypeData'] = [this.organisationAttributeType$, [Validators.nullValidator]];
        } else {
            form['organisationAttributeTypeData'] = [this.organisationAttributeType$, [Validators.nullValidator]];
        }

        let f = this.fb.group(form);
        return f;
    }

    delete(i) {
        const formsArray = <UntypedFormArray>this.actionForm.get('forms');
        formsArray.removeAt(i);
        this.actionForm.updateValueAndValidity();
    }

    add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == "INVALID") {
                    return;
                }
            }
        }

        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createDomainAttributeFormGroupEmpty(null));
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                } else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }

        this.changeDetectorRef.detectChanges();
        return duplicate;
    }

    findDuplicate(ctlCheck, ctlIndex) {

        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (ctl.value.organisationAttributeTypeCode == ctlCheck.value.organisationAttributeTypeCode) {
                    if (ctl.value.multipleChoiceFlag) {
                        if (this.checkArrayEqual(ctl.value.choiceMultiple, ctlCheck.value.choiceMultiple)) {
                            duplicate = true;
                        }
                    } else {
                        if (ctl.value.choiceSingle == ctlCheck.value.choiceSingle) {
                            duplicate = true;
                        }
                    }
                }
            }
        }
        return duplicate;
    }

    selectChange(value, ctl, name: string) {
        ctl.get(name).setValue(value);
        this.edited = true;
        this.createOutputDomainAttributeTypeValue();
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get('organisationAttributeDuplicate').setValue(null);
            formCurrent.get('organisationAttributeDuplicate').setValidators(Validators.required);
            formCurrent.get('organisationAttributeDuplicate').updateValueAndValidity();
        } else {
            formCurrent.get('organisationAttributeDuplicate').setValue(false);
            formCurrent.get('organisationAttributeDuplicate').setValidators(Validators.nullValidator);
            formCurrent.get('organisationAttributeDuplicate').updateValueAndValidity();
        }

    }

    clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }

    public checkHiddenDelete(ctl): boolean {
        if (!this.checkIsRequiredFlag(ctl.get('organisationAttributeTypeCode').value) || this.isFilterPanel) {
            return false;
        }
        return true;
    }

    private checkIsRequiredFlag(attributeTypeCode: string) {
        if (attributeTypeCode && this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x =>
                x.attributeTypeCode == attributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }

            return false;
        }
        return returnValue;
    }

    public get panelCollapsed() {
        return this.forms.controls.length == 0;
    }

    public get allowCollapse() {
        return this.forms.controls.length > 0;
    }

    get editFlag() {
        return this.isFilterPanel || (this.userSecurity?.editFlag || this.newOrganisation);
    }

    dateTimeChangeProcess(ctl, e) {
        ctl.get("choiceDateTime").setValue(e);
    }

    timeChangeProcess(ctl, e) {
        ctl.get("choiceTime").setValue(e);
    }

    checkDimentionTypeCondition(ctl, typeCode) {
        return ctl.get('dimensionUnitCode').value == typeCode;
    }

    getMaxlength(ctl) {
        if (ctl.value.organisationAttributeTypeCode == "SFTPDIRECTORY") {
            return 450;
        }
        return this.DEFAULT_MAX_CHAR;
    }

    private setAttributeChoice(form: any, organisationAttributeChoiceId: boolean, disabled: boolean, dimensionUnitCode: string) {
        form['choiceMultiple'] = [{ value: null, disabled: disabled }, [Validators.nullValidator]];
        if (dimensionUnitCode == "CHOICE") {
            form['choiceSingle'] = [{ value: organisationAttributeChoiceId, disabled: disabled }, [Validators.required]];
        } else {
            form['choiceSingle'] = [{ value: null, disabled: disabled }, [Validators.nullValidator]];
        }
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.onSearch.emit();
                break;
            default:
                break;
        }
    }

    validateRequired() {
        let requiredRows = this.getRequiredAttribute();
        if (requiredRows.length == 0) {
            return true;
        }

        for (let row of requiredRows) {
            if (!row.valid) {
                return false;
            }
        }
        return true;
    }

    getRequiredAttribute() {
        return this.actionForm.controls.forms['controls'].filter(item => this.checkIsRequiredFlag(item.value.organisationAttributeTypeCode))
    }

    getAttributeErrorMessage(): string {
        let requiredRows = this.getRequiredAttribute();
        for (let row of requiredRows) {
            if (!row.valid) {
                return this.getAttributeName(row.value.organisationAttributeTypeCode) + this.ERROR_REQUIRED;
            }
        }

        return 'Required Attribute is invalid';
    }

    getAttributeName(attributeTypeCode: string) {
        return this.domainAttribute$.value.filter(item => item.attributeTypeCode == attributeTypeCode)[0].attributeTypeName;
    }

    private setRequiredFieldText(formCurrent: AbstractControl) {
        if (formCurrent.get("dimensionUnitCode").value == "TEXT" &&
            this.checkIsRequiredFlag(formCurrent.get("organisationAttributeTypeCode").value)) {
            formCurrent.get('choiceText').setValidators(Validators.required);
        } else {
            formCurrent.get('choiceText').setValidators(Validators.nullValidator);
        }
        formCurrent.get('choiceText').updateValueAndValidity();
    }
}

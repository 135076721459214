import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceRuleProductView } from ".";

export class PriceRuleAttributeView {
    public priceRuleAttributeId: string;
    public priceRuleId: string;
    public attributeTypeCode: string;
    public attributeChoiceId: string | string[];
    public attributeMultiChoiceIds: string[];
    public attributeText: string;
    public attributeDateTime: string;
    public attributeTime: string;
    public conditionCode: string = "="
    public attributeValue: string = "";
    public multipleChoiceFlag: boolean;
    public products: PriceRuleProductView[];
    public attributeOptionList: Select2Data[];
}
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'

export const calendarOptions: CalendarOptions = {
  headerToolbar: {
    left: 'title',
    center: '',
    right: ''
  },
  plugins: [
    dayGridPlugin,
    interactionPlugin,
    bootstrapPlugin
  ], 
  initialView: 'dayGridMonth',
  themeSystem: 'bootstrap',
  height: '100%',
  contentHeight: '100%',
  firstDay: 1,
  selectable: true,
  editable: true,
  handleWindowResize: true,
  dayCellContent: null,
  dateClick: null,
};


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialServiceContentComponent } from './special-service-content.component';
import { SpecialServiceTabsComponent } from './special-service-tabs/special-service-tabs.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { OopsCategoriesModule } from 'src/app/core/components/categories/oops-categories.module';
import { MatIconModule } from '@angular/material/icon';
import { SpecialServiceGroupModule } from './special-service-group/special-service-group.module';
import { SpecialServiceSearchModule } from './special-service-search/special-service-search.module';
import { SpecialServiceDetailModule } from './special-service-detail/special-service-detail.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { SpecialServiceAttributeModule } from './special-service-attribute/special-service-attribute.module';

@NgModule({
    declarations: [
        SpecialServiceContentComponent,
        SpecialServiceTabsComponent
    ],
    imports: [
        CommonModule,
        FreezingTabModule,
        MatIconModule,
        NgbNavModule,
        OopsCategoriesModule,
        SpecialServiceAttributeModule,
        SpecialServiceDetailModule,
        SpecialServiceGroupModule,
        SpecialServiceSearchModule,  
        UiModule
    ],
    exports: [
        SpecialServiceContentComponent
    ]
})
export class SpecialServiceContentModule { }

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { LanguageView } from "../shared/language-view";
import { select2Language } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-language-detail',
    templateUrl: './language-detail.component.html'
})
export class InsightLanguageDetailComponent {
    readonly ERROR_LANGUAGE_REQUIRED = 'Language is required.'

    public languageOption: any;
    public processing: boolean = false;

    @Input() insightLanguage = {} as LanguageView;
    @Input() languageReferences: LanguageReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();
    
    @ViewChild('languageDetailForm') languageDetailForm: NgForm;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {     
        this.languageOption = select2Language;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    getInsightLanguage() : LanguageView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightLanguage;
    }

    public languageCodeChange(value) {
        this.insightLanguage.languageCode = value;
        this.insightLanguage.languageName = this.languageReferences.filter(it => it.languageCode == value)[0]?.languageName ?? null;
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.languageDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.languageDetailForm.valid) {
            return this.ERROR_LANGUAGE_REQUIRED;
        }
    }
}
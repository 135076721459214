import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleLocationView extends PriceRuleConditionBase {
    public priceRuleLocationTypeCode: string;
    public priceRuleLocationPointCode: string;
    public locationId: string[];
    public locationGroupId: string[];
    public regionCode: string[];
    public countryCode: string[];
    public preSelectedDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public displaySequence: number;
    public locationType: string;
}
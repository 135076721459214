import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreditAccountContentRoutingModule } from './credit-account-content-routing.module';
import { CreditAccountContentComponent } from './credit-account-content.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CreditAccountSearchComponent } from './credit-account-search/credit-account-search.component';
import { OrganisationAttributeModule } from '../../organisation/organisation-content/organisation-attribute/organisation-attribute.module';
import { CreditAccountSearchTableComponent } from './credit-account-search/credit-account-search-table/credit-account-search-table.component';
import { DxDataGridModule } from 'devextreme-angular';
import { MatIconModule } from '@angular/material/icon';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule } from '@angular/forms';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { TopUpPaymentComponent } from './credit-account-search/top-up-payment/top-up-payment.component';
import { FormOfPaymentSelectionModule } from 'src/app/core/components/form-of-payment-selection';
import { FormOfPaymentTypeSelectionModule } from 'src/app/core/components/form-of-payment-type-selection';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { CreditAccountSearchConditionComponent } from './credit-account-search/credit-account-search-condition/credit-account-search-condition.component';
import { CountrySelectionModule } from 'src/app/core/components/country-selection/country-selection.module';
import { DocumentInvoiceTypeSelectionModule } from 'src/app/core/components/document-invoice-type-selection/document-invoice-type-selection.module';
import { PaymentDocumentInvoiceModule } from '../../order/shared/components/payment-document-invoice/payment-document-invoice.module';


@NgModule({
    declarations: [
        CreditAccountContentComponent,
        CreditAccountSearchComponent,
        CreditAccountSearchTableComponent,
        TopUpPaymentComponent,
        CreditAccountSearchConditionComponent
    ],
    imports: [
        AlertBarModule,
        CommonModule,
        CountrySelectionModule,
        CreditAccountContentRoutingModule,
        DocumentInvoiceTypeSelectionModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        FormOfPaymentSelectionModule,
        FormOfPaymentTypeSelectionModule,
        FreezingTabModule,
        ItemsFoundModule,
        MatIconModule,
        NgbNavModule,
        OrganisationAttributeModule,
        PanelsModule,
        PaymentDocumentInvoiceModule,
        Select2Module,
        UiModule
    ],
    exports: [
        CreditAccountContentComponent
    ]
})
export class CreditAccountContentModule { }

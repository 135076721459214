import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_MERCHANDIZE_REVENUE_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 474,
        salesChannel1Name: "GDS",
        salesChannel2: 283,
        salesChannel2Name: "API",
        salesChannel3: 241,
        salesChannel3Name: "ISE",
        salesChannel4: 386,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 189,
        salesChannel1Name: "GDS",
        salesChannel2: 494,
        salesChannel2Name: "API",
        salesChannel3: 337,
        salesChannel3Name: "ISE",
        salesChannel4: 234,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 204,
        salesChannel1Name: "GDS",
        salesChannel2: 115,
        salesChannel2Name: "API",
        salesChannel3: 188,
        salesChannel3Name: "ISE",
        salesChannel4: 220,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 272,
        salesChannel1Name: "GDS",
        salesChannel2: 495,
        salesChannel2Name: "API",
        salesChannel3: 143,
        salesChannel3Name: "ISE",
        salesChannel4: 270,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 267,
        salesChannel1Name: "GDS",
        salesChannel2: 413,
        salesChannel2Name: "API",
        salesChannel3: 291,
        salesChannel3Name: "ISE",
        salesChannel4: 110,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 355,
        salesChannel1Name: "GDS",
        salesChannel2: 394,
        salesChannel2Name: "API",
        salesChannel3: 319,
        salesChannel3Name: "ISE",
        salesChannel4: 380,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 330,
        salesChannel1Name: "GDS",
        salesChannel2: 464,
        salesChannel2Name: "API",
        salesChannel3: 195,
        salesChannel3Name: "ISE",
        salesChannel4: 128,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 209,
        salesChannel1Name: "GDS",
        salesChannel2: 395,
        salesChannel2Name: "API",
        salesChannel3: 292,
        salesChannel3Name: "ISE",
        salesChannel4: 157,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 318,
        salesChannel1Name: "GDS",
        salesChannel2: 358,
        salesChannel2Name: "API",
        salesChannel3: 168,
        salesChannel3Name: "ISE",
        salesChannel4: 353,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 386,
        salesChannel1Name: "GDS",
        salesChannel2: 464,
        salesChannel2Name: "API",
        salesChannel3: 127,
        salesChannel3Name: "ISE",
        salesChannel4: 240,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 446,
        salesChannel1Name: "GDS",
        salesChannel2: 312,
        salesChannel2Name: "API",
        salesChannel3: 499,
        salesChannel3Name: "ISE",
        salesChannel4: 301,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 468,
        salesChannel1Name: "GDS",
        salesChannel2: 473,
        salesChannel2Name: "API",
        salesChannel3: 176,
        salesChannel3Name: "ISE",
        salesChannel4: 222,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 278,
        salesChannel1Name: "GDS",
        salesChannel2: 243,
        salesChannel2Name: "API",
        salesChannel3: 183,
        salesChannel3Name: "ISE",
        salesChannel4: 274,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 131,
        salesChannel1Name: "GDS",
        salesChannel2: 196,
        salesChannel2Name: "API",
        salesChannel3: 429,
        salesChannel3Name: "ISE",
        salesChannel4: 289,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 187,
        salesChannel1Name: "GDS",
        salesChannel2: 449,
        salesChannel2Name: "API",
        salesChannel3: 114,
        salesChannel3Name: "ISE",
        salesChannel4: 382,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 241,
        salesChannel1Name: "GDS",
        salesChannel2: 107,
        salesChannel2Name: "API",
        salesChannel3: 371,
        salesChannel3Name: "ISE",
        salesChannel4: 188,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 137,
        salesChannel1Name: "GDS",
        salesChannel2: 260,
        salesChannel2Name: "API",
        salesChannel3: 188,
        salesChannel3Name: "ISE",
        salesChannel4: 309,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 208,
        salesChannel1Name: "GDS",
        salesChannel2: 463,
        salesChannel2Name: "API",
        salesChannel3: 435,
        salesChannel3Name: "ISE",
        salesChannel4: 408,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 439,
        salesChannel1Name: "GDS",
        salesChannel2: 497,
        salesChannel2Name: "API",
        salesChannel3: 393,
        salesChannel3Name: "ISE",
        salesChannel4: 261,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 212,
        salesChannel1Name: "GDS",
        salesChannel2: 260,
        salesChannel2Name: "API",
        salesChannel3: 463,
        salesChannel3Name: "ISE",
        salesChannel4: 121,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 300,
        salesChannel1Name: "GDS",
        salesChannel2: 407,
        salesChannel2Name: "API",
        salesChannel3: 175,
        salesChannel3Name: "ISE",
        salesChannel4: 177,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 250,
        salesChannel1Name: "GDS",
        salesChannel2: 401,
        salesChannel2Name: "API",
        salesChannel3: 490,
        salesChannel3Name: "ISE",
        salesChannel4: 145,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 106,
        salesChannel1Name: "GDS",
        salesChannel2: 133,
        salesChannel2Name: "API",
        salesChannel3: 325,
        salesChannel3Name: "ISE",
        salesChannel4: 269,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 220,
        salesChannel1Name: "GDS",
        salesChannel2: 397,
        salesChannel2Name: "API",
        salesChannel3: 201,
        salesChannel3Name: "ISE",
        salesChannel4: 197,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 146,
        salesChannel1Name: "GDS",
        salesChannel2: 213,
        salesChannel2Name: "API",
        salesChannel3: 370,
        salesChannel3Name: "ISE",
        salesChannel4: 383,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 395,
        salesChannel1Name: "GDS",
        salesChannel2: 415,
        salesChannel2Name: "API",
        salesChannel3: 296,
        salesChannel3Name: "ISE",
        salesChannel4: 165,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 368,
        salesChannel1Name: "GDS",
        salesChannel2: 457,
        salesChannel2Name: "API",
        salesChannel3: 261,
        salesChannel3Name: "ISE",
        salesChannel4: 370,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 122,
        salesChannel1Name: "GDS",
        salesChannel2: 500,
        salesChannel2Name: "API",
        salesChannel3: 352,
        salesChannel3Name: "ISE",
        salesChannel4: 467,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 348,
        salesChannel1Name: "GDS",
        salesChannel2: 142,
        salesChannel2Name: "API",
        salesChannel3: 431,
        salesChannel3Name: "ISE",
        salesChannel4: 248,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 141,
        salesChannel1Name: "GDS",
        salesChannel2: 268,
        salesChannel2Name: "API",
        salesChannel3: 138,
        salesChannel3Name: "ISE",
        salesChannel4: 264,
        salesChannel4Name: "OWN"
    }
]

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommentView } from "../shared/comment-view";
import { select2CommentClassification, select2CommentType } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-comment-detail',
    templateUrl: './comment-detail.component.html'
})
export class InsightCommentDetailComponent {
    readonly ERROR_REQUIRED = ' is required.'

    public commentTypeOption: any;
    public commentClassificationOption: any;
    public processing: boolean = false;

    @Input() insightComment = {} as CommentView;
    @Input() commentTypeReferences: CommentTypeReferenceModel[];
    @Input() commentClassificationReferences: CommentClassificationReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();
    
    @ViewChild('commentDetailForm') commentDetailForm: NgForm;

    get isNullOrganisationId() : boolean {
        if (this.insightComment.organisationId) {
            return true;
        }

        return false;
    }

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {     
        this.commentTypeOption = select2CommentType;
        this.commentClassificationOption = select2CommentClassification;
    }

    getInsightComment() : CommentView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightComment;
    }

    public commentTypeChange(value) {
        this.insightComment.commentTypeCode = value; 
        this.insightComment.commentTypeName = this.commentTypeReferences.filter(it => it.commentTypeCode == value)[0]?.commentTypeName ?? null;
    }

    public commentClassificationChange(value) {
        this.insightComment.commentClassificationCode = value; 
        this.insightComment.commentClassificationName = this.commentClassificationReferences.filter(it => it.commentClassificationCode == value)[0]?.commentClassificationName ?? null;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.commentDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.commentDetailForm.controls['commentTypeCode'].valid) {
            return 'Comment Type' + this.ERROR_REQUIRED;
        } else if (!this.commentDetailForm.controls['commentText'].valid) {
            return 'Comment' + this.ERROR_REQUIRED;
        }
    }
}
import { Component, Input } from '@angular/core';
import {
    DomainAttributeService,
    ProductTypeGroupService
} from 'src/app/core/services/airline-services';
import { MerchandizeService } from 'src/app/core/services/product-services';
import { ProductService } from 'src/app/core/services/product-services/product.service';
import { PricingDetailConstant } from '../../shared/pricing-detail.constant';
import { Select2OptionsService } from '../product-eligible-restricted/shared/select2-options.service';
import { AttributeBase } from './shared/attribute.base';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'op-price-rule-detail-attribute-and-rule-attribute',
    templateUrl: './shared/attribute.base.html'
})

export class AttributeComponent extends AttributeBase {

    title: string = "Attribute"
    priceRuleAttributeGroupCode: string = PricingDetailConstant.ATTRIBUTE_CODE;
    focusing: boolean = false;

    @Input() priceRuleTypeCode: string = "";

    constructor(
        domainAttributeService: DomainAttributeService,
        select2OptionsService: Select2OptionsService,
        productTypeGroupService: ProductTypeGroupService,
        productService: ProductService,
        dateConverterService: DateConverterService) {
        super(
            domainAttributeService,
            select2OptionsService,
            productTypeGroupService,
            productService,
            dateConverterService);
    }
}
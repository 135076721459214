import { Injectable } from '@angular/core';
import { ProductInventoryCommandModel } from 'src/app/core/models/product-model/product-base-model/product-inventory';
import { SpecialServiceInventoryViewModel } from 'src/app/core/models/product-model/specialservice-model/specialservice-inventory-view.model';
import { InventoryView } from '../views/inventory.view';

@Injectable()
export class InventoryMapperService {

    public inventoryToViews(models: SpecialServiceInventoryViewModel[]): InventoryView[] {
        let views: InventoryView[] = new Array();
        if (models) {
            for (let model of models) {
                views.push(this.inventoryToView(model))
            }
        }
        return views;
    }

    private inventoryToView(model: SpecialServiceInventoryViewModel): InventoryView {
        let view: InventoryView = {
            productId: model.productId,
            parentProductId: model.parentProductId,
            productInventoryId: model.productInventoryId,
            productName: model.productName,
            sellCapacity: model.sellCapacity,
            requestCapacity: model.requestCapacity,
            unitCode: model.unitCode,
            unitName: model.unitName
        }
        
        return view;
    }

    public inventoryViewToModels(views: InventoryView[]): ProductInventoryCommandModel[] {
        let models: ProductInventoryCommandModel[] = new Array();
        if (views) {
            for (let i = 0; i < views.length; i++) {
                models.push(this.inventoryViewToModel(views[i]))
            }
        }
        return models;
    }

    private inventoryViewToModel(view: InventoryView): ProductInventoryCommandModel {
        let model = new ProductInventoryCommandModel();
        model.productInventoryId = view.productInventoryId;
        model.sellCapacity = view.sellCapacity;
        model.requestCapacity = view.requestCapacity;
        model.unitCode = view.unitCode;
        return model;
    }

}
import { Injectable } from '@angular/core';

import { DateConverterService } from "src/app/core/utils/date-converter.service";

import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { ExchangeRateGroupModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { MODULE_NAME } from 'src/app/modules/settings-management/settings-management-content/shared/title-constant';
import { SettingsSecurityCodes } from 'src/app/modules/settings-management/settings-management-content/settings-categories/settings-categories';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';

@Injectable()
export class ExchangeRateGroupMapperService {

    constructor(private dateConverterService: DateConverterService) {
    }

    public toOopsCategoryViews(models: ExchangeRateGroupModel[], userSecurity: SecurityGroupSecurityModel[], logCommit: LogCommitModel): OopsCategory[] {
        const exchangeRateSecurity = userSecurity.find(s => s.securityCode == SettingsSecurityCodes.get(MODULE_NAME.exchange_rate))

        let views: OopsCategory[] = new Array();
        if (views) {
            for (let model of models) {
                views.push(this.toOopsCategoryView(model, logCommit, exchangeRateSecurity));
            }
        }
        return views;
    }

    private toOopsCategoryView(model: ExchangeRateGroupModel, logCommit: LogCommitModel, exchangeRateSecurity: SecurityGroupSecurityModel): OopsCategory {
        var view = {} as OopsCategory
        view.name = model.exchangeRateGroupName;
        view.modifiedBy = logCommit.commitByName;
        view.modifiedDateTime = this.dateConverterService.convertDateTime24(logCommit.commitDateTime);
        view.security = exchangeRateSecurity
        return view;
    }

}
import { Injectable } from "@angular/core";
import { ProductScheduleModel } from "src/app/core/models/schedule-period-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ScheduleProductTableView } from "./schedule-product-table.view";
import { StateConstant } from "src/app/shared/ui/forms/inputs/state-color-dropdown/shared/state.constant";

@Injectable()
export class ScheduleProductMapperService {

    constructor(private dateConverterService: DateConverterService) { }

    public toViews(models: ProductScheduleModel[]): ScheduleProductTableView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.toView(i, model));
        }
        return views;
    }

    private toView(index: number, model: ProductScheduleModel): ScheduleProductTableView {
        let view = {} as ScheduleProductTableView;
        view.no = index;
        view.productId = model.productId;
        view.productNumber = this.createProductNumber(model.providerCode, model.productNumber);
        view.locationFrom = model.locationNameFrom;
        view.locationTo = model.locationNameTo;
        if (model.departureDate) {
            view.dateFrom = this.dateConverterService.toDateFormat(model.departureDate);
        }
        if (model.arrivalDate) {
            view.dateTo = this.dateConverterService.toDateFormat(model.arrivalDate);
        }
        view.timeFrom = this.dateConverterService.convertTime24(model.departureTime);
        view.timeTo = this.dateConverterService.convertTime24(model.arrivalTime);
        view.state = this.getState(model.draftFlag, model.finalFlag);
        view.weekDay = model.weekDay;
        view.vehicleTypeCode = model.vehicleTypeCode;
        view.compositionName = model.compositionName;
        view.draftFlag = model.draftFlag;
        view.finalFlag = model.finalFlag;
        return view;
    }

    public createProductNumber(providerCode: string, productNumber) {
        return providerCode + " " + productNumber;
    }

    private getState(draftFlag: boolean, finalFlag: boolean): string {
        if (finalFlag) {
            return StateConstant.FINAL;
        }
        if (draftFlag) {
            return StateConstant.DRAFT;
        }
        return StateConstant.NONE;
    }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleDistributionContentComponent } from './schedule-distribution-content.component';
import { ScheduleDistributionSearchComponent } from './schedule-distribution-search/schedule-distribution-search.component';
import { ScheduleDistributionHomeComponent } from './schedule-distribution-home/schedule-distribution-home.component';
import { ScheduleDistributionTableComponent } from './schedule-distribution-table/schedule-distribution-table.component';
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { OopsCategoriesModule } from 'src/app/core/components/categories';
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CalendarIconModule } from 'src/app/core/components/calendar-icon';
import { ScheduleDistributionDetailTableComponent } from './schedule-distribution-table/schedule-distribution-detail-table/schedule-distribution-detail-table.component';
import { DataGridRowNumberModule } from "../../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ScheduleDistributionContentComponent,
        ScheduleDistributionSearchComponent,
        ScheduleDistributionHomeComponent,
        ScheduleDistributionTableComponent,
        ScheduleDistributionDetailTableComponent,
    ],
    exports: [
        ScheduleDistributionContentComponent
    ],
    imports: [
        ButtonsModule,
        CalendarIconModule,
        CollapseModule,
        CommonModule,
        DataGridRowNumberModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DynamicRoutingModule,
        FocusingModule,
        FormsModule,
        LoadingSpinnerModule,
        NgbNavModule,
        NgbModule,
        OopsCategoriesModule,
        PanelsModule,
        MatIconModule,
        MatMenuModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        UiModule
    ]
})
export class ScheduleDistributionContentModule { }

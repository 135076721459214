import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import {
    PriceDimensionConstant,
    TypeOrganisationConstant,
    TypeOrganisationData,
    PriceDimensionTypeData,
    AreaData,
    AreaConstant
} from "../shared/constants";
import { ConditionReferenceService, UnitReferenceService } from "src/app/core/services/system-services";
import { PricingDetailConstant } from "src/app/modules/pricing/rules/price-rule-detail/shared/pricing-detail.constant";
import { duplicateGroupValidation } from "src/app/core/validators";
import { ConditionReferenceModel, CurrencyReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import {
    AirportOrCityOption,
    AreaOption,
    AttributeChoiceMultipleOption,
    AttributeTypeOption,
    ConditionOption,
    CountryOption,
    CurrencyOption,
    DestinationLocationIdOption,
    EndDateOption,
    OrganisationGroupOption,
    OrganisationIdOption,
    OrganisationRoleOption,
    OrganisationTypeOption,
    OriginLocationIdOption,
    ProductNumberOption,
    RegionOption,
    SalesBucketOption,
    ServiceCategoryOption,
    StartDateOption,
    LevelOption,
    TypeOption,
    TypeOrganisationOption
} from "../shared/options/select2-price-dimension.options";
import {
    PriceRuleCurrencyModel,
    PriceRuleLocationModel,
    PriceRuleMembershipModel,
    PriceRuleOrganisationModel,
    PriceRuleProductNumberModel,
    PriceRuleRouteModel,
    PriceRuleSalesBucketModel,
    PriceRuleServiceCategoryModel,
    PriceRuleValidityModel
} from "src/app/core/models/pricing-model";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PricingConverter } from "src/app/modules/pricing/shared/pricing.converter";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { PriceAttributeModel, PriceModel } from "src/app/modules/pricing/prices/shared/models";
import { PriceRuleAjaxService } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/price-rule-ajax.service";
import { LocationReferenceService } from "src/app/core/services/airline-services";
import { map } from "rxjs/operators";

@Component({
    selector: 'op-price-dimensions-details-attributes',
    templateUrl: './attributes.component.html',
    providers: [PricingConverter],
})
export class AttributesComponent extends OopsComponentFormBase implements OnChanges, AfterViewInit {
    private readonly yearMonthDateFormat = 'YYYY/MM/DD';
    public readonly CONDITION_CODE = 'conditionCode';
    public readonly DESTINATION_AIRPORT_CITY = 'destinationAirportCity';
    public readonly PRODUCT_NUMBER = 'productNumber';
    public readonly TYPE_ORGANISATION = 'typeOrganisation';
    public readonly ORGANISATION_SPECIFIC = 'organisationId';
    public readonly ORIGIN_LOCATION_ID = 'originLocationId';
    public readonly DESTINATION_LOCATION_ID = 'destinationLocationId';
    public readonly AREA = 'area';
    public readonly ORIGIN_REGION = 'originRegion';
    public readonly DESTINATION_REGION = 'destinationRegion';
    public readonly START_DATE = 'startDate';
    public readonly END_DATE = 'endDate';
    public readonly SERVICE_CATEGORY_CODE = 'serviceCategory';
    public readonly SALES_BUCKET = 'salesBucket';
    public readonly LEVEL = 'level';
    public readonly CURRENCY = 'currency';
    public readonly ORGANISATION_TYPE = 'organisationType';
    public readonly ORGANISATION_ROLE = 'organisationRole';
    public readonly ORGANISATION_GROUP = 'organisationGroup';
    public readonly ORIGIN_COUNTRY = 'originCountry';
    public readonly ORIGIN_AIRPORT_CITY = 'originAirportCity';
    public readonly DESTINATION_COUNTRY = 'destinationCountry';
    public readonly TYPE = 'type';
    public readonly PRICE_RULE_LOCATION_TYPE_CODE = 'RESTRICTION';
    public readonly ORIGIN_PRICE_RULE_LOCATION_POINT_CODE = 'ORIGIN';
    public readonly DESTINATION_PRICE_RULE_LOCATION_POINT_CODE = 'DESTINATION';
    public readonly SERVICE_CALENDAR_VALIDITY_CODE = 'SERVICE';
    public readonly SALE_CALENDAR_VALIDITY_CODE = 'SALE';
    public readonly ATTRIBUTE_TYPE = 'attributeType';
    public readonly ATTRIBUTE_CHOICE = 'attributeChoice';
    public readonly allowMultipleRowsTypes = [
        PriceDimensionConstant.productNumber.id,
        PriceDimensionConstant.route.id,
        PriceDimensionConstant.serviceDate.id,
        PriceDimensionConstant.salesDate.id,
        PriceDimensionConstant.attribute.id,
    ];
    public readonly NO_CURRENCY_TYPE = "NOCURRENCY";
    public readonly POINTS_MILES_TYPE = "POINTSMILES";
    public readonly VIRTUAL_CURRENCY_TYPE = "VIRTUALCURRENCY";
    public readonly CURRENCY_TYPE = "CURRENCY";

    typeOption = TypeOption;
    typeOrganisationOption = TypeOrganisationOption;
    conditionOption = ConditionOption;
    productNumberOption = ProductNumberOption;
    organisationIdOption = OrganisationIdOption;
    organisationTypeOption = OrganisationTypeOption;
    organisationRoleOption = OrganisationRoleOption;
    organisationGroupOption = OrganisationGroupOption;
    originLocationIdOption = OriginLocationIdOption;
    destinationLocationIdOption = DestinationLocationIdOption;
    areaOption = AreaOption;
    originRegionOption = RegionOption;
    originCountryOption = CountryOption;
    originAirportCityOption = AirportOrCityOption;
    destinationRegionOption = RegionOption;
    destinationCountryOption = CountryOption;
    destinationAirportCityOption = AirportOrCityOption;
    startDateOption = StartDateOption;
    endDateOption = EndDateOption;
    serviceCategoryOption = ServiceCategoryOption;
    salesBucketOption = SalesBucketOption;
    levelOption = LevelOption;
    currencyOption = CurrencyOption;
    attributeTypeOption = AttributeTypeOption;
    attributeChoiceMultipleOption = AttributeChoiceMultipleOption;

    typeOrganisationData = TypeOrganisationData;
    areaData = AreaData;

    priceAttributes = [];

    conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    unitReferences$ = this.unitReferenceService.getUnitReferences();

    @Input() price: PriceModel;
    @Input() organisationTypeReferences: Select2Data[];
    @Input() organisationReferences: Select2Data[];
    @Input() organisationRoleReferences: Select2Data[];
    @Input() organisationGroupReferences: Select2Data[];
    @Input() regionReferences: Select2Data[];
    @Input() organisationCountryReferences: Select2Data[];
    @Input() servicaCategoryReferences: Select2Data[];
    @Input() salesBucketReferences: Select2Data[];
    @Input() membershipLevels: Select2Data[];
    @Input() currencyReferences: CurrencyReferenceModel[];
    @Input() domainAttributes: Select2Data[];
    @Input() attributeChoices: Array<Select2Data[]>;
    @Input() attributeChoiceOption: any[];
    @Input() disabled: boolean = false;

    @Output() priceAttributesChange = new EventEmitter<string[]>();
    @Output() numberOfRowsChange = new EventEmitter<number>();

    currencyReferences$ = new BehaviorSubject<Select2Data[]>([]);
    locationReferences$ = new BehaviorSubject<Select2Data[]>([]);

    get rows(): UntypedFormArray {
        return this.formGroup?.get('rows') as UntypedFormArray;
    }

    get typeDataSet(): { id: string; text: string }[][] {
        if (this.rows.controls?.length) {
            let typeDataSet = [];
            for (let i = 0; i < this.rows.controls.length; i++) {
                let selectedType = (this.rows.controls[i] as UntypedFormGroup).controls[this.TYPE].value;
                let typeData = this.getPriceDimensionTypeDataNotSelectedYetButNotOwnRowAndMultipleRowsAllowed(selectedType);
                typeDataSet.push(typeData);
            }
            return typeDataSet;
        }
        return [];
    }

    private getPriceDimensionTypeDataNotSelectedYetButNotOwnRowAndMultipleRowsAllowed(
        selectedType: string
    ): any[] {
        let currentTypesSelected = (this.rows.controls as UntypedFormGroup[]).map(
            row => row.controls[this.TYPE].value
        );
        return PriceDimensionTypeData.filter(
            data =>
                currentTypesSelected
                    .filter(selected => selected != selectedType)
                    .includes(data.id) == false || this.allowMultipleRowsTypes.includes(data.id)
        );
    }

    constructor(
        fb: UntypedFormBuilder,
        private conditionReferenceService: ConditionReferenceService,
        private unitReferenceService: UnitReferenceService,
        private pricingConverter: PricingConverter,
        private dateConverter: DateConverterService,
        private priceRuleAjaxService: PriceRuleAjaxService,
        private locationReferenceService: LocationReferenceService
    ) {
        super(fb);
        this.getConditionReferences();
        this.setupAjaxForLocationOptions();
    }

    private setupAjaxForLocationOptions() {
        this.originLocationIdOption["minimumInputLength"] = 3;
        this.originLocationIdOption["ajax"] = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        this.destinationLocationIdOption["minimumInputLength"] = 3;
        this.destinationLocationIdOption["ajax"] = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        this.originAirportCityOption["minimumInputLength"] = 3;
        this.originAirportCityOption["ajax"] = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        this.destinationAirportCityOption["minimumInputLength"] = 3;
        this.destinationAirportCityOption["ajax"] = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['price'] && this.price) {
            this.getLocationReferencesPreSelected();
        }
        if (this.formGroup) {
            this.modelToFormGroup(this.price);
        }

        if (changes['currencyReferences']) {
            this.currencyReferences$.next(this.currencyReferencesToSelect2Datas(this.currencyReferences));
        }
    }

    private currencyReferencesToSelect2Datas(currencyReferences: CurrencyReferenceModel[]): Select2Data[] {
        return currencyReferences?.map(cr => new Select2Data(cr.currencyCode, cr.currencyName)) ?? [];
    }

    private modelToFormGroup(price: PriceModel) {
        if (this.rows?.length) {
            this.rows.clear();
        }
        let formGroupsWithDisplaySequence = [];
        this.convertPriceRuleProductNumberModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleOrganisationModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleRouteModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleLocationModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleValidityModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleServiceCategoryModelsToFormGroups(
            price,
            formGroupsWithDisplaySequence
        );
        this.convertPriceRuleSalesBucketModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleMembershipModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceRuleCurrencyModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.convertPriceAttributeModelsToFormGroups(price, formGroupsWithDisplaySequence);
        this.addFormGroupsToRowsByDisplaySequence(formGroupsWithDisplaySequence);
        this.numberOfRowsChange.emit(this.rows?.length);

        if (this.disabled == true) {
            this.rows.disable();
        }
    }

    private addFormGroupsToRowsByDisplaySequence(formGroupsWithDisplaySequence: any[]) {
        this.priceAttributes = [];
        for (let i = 0; i < formGroupsWithDisplaySequence.length; i++) {
            let formGroup = formGroupsWithDisplaySequence[i];
            if (formGroup) {
                this.assignPriceAttribute(formGroup);
                this.rows.push(formGroup);
            }
        }
        this.priceAttributesChange.emit(this.priceAttributes);
    }

    private assignPriceAttribute(formGroup: UntypedFormGroup) {
        let priceAttribute = formGroup.controls[this.TYPE].value;
        if (this.priceAttributes.includes(priceAttribute) == false) {
            switch (priceAttribute) {
                case PriceDimensionConstant.attribute.id:
                    let attributeType = formGroup.get(this.ATTRIBUTE_TYPE).value;
                    this.priceAttributes.push(attributeType);
                    break;
                default:
                    this.priceAttributes.push(priceAttribute);
                    break;
            }
        }
    }

    private convertPriceRuleCurrencyModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.currencies?.length) {
            let currenciesGroupByDisplaySequence = this.groupByDisplaySequence(price.currencies);
            for (let displaySequence of Object.keys(currenciesGroupByDisplaySequence)) {
                let currencies = currenciesGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.currency.id);
                this.addCurrencyControls(formGroup, currencies);
                formGroupsWithDisplaySequence[currencies[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceAttributeModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.attributes?.length) {
            let attributesGroupByDisplaySequence = this.groupByDisplaySequence(price.attributes);
            for (let displaySequence of Object.keys(attributesGroupByDisplaySequence)) {
                let attributes = attributesGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.attribute.id);
                this.addAttributeControls(formGroup, attributes);
                formGroupsWithDisplaySequence[attributes[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleMembershipModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.memberships?.length) {
            let membershipGroupByDisplaySequence = this.groupByDisplaySequence(price.memberships);
            for (let displaySequence of Object.keys(membershipGroupByDisplaySequence)) {
                let memberships = membershipGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.memberLevel.id);
                this.addTierControls(formGroup, memberships);
                formGroupsWithDisplaySequence[memberships[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleSalesBucketModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.salesBuckets?.length) {
            let salesBucketGroupByDisplaySequence = this.groupByDisplaySequence(price.salesBuckets);
            for (let displaySequence of Object.keys(salesBucketGroupByDisplaySequence)) {
                let salesBuckets = salesBucketGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.salesBucket.id);
                this.addSalesBucketControls(formGroup, salesBuckets);
                formGroupsWithDisplaySequence[salesBuckets[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleServiceCategoryModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.serviceCategories?.length) {
            let serviceCategoryGroupByDisplaySequence = this.groupByDisplaySequence(
                price.serviceCategories
            );
            for (let displaySequence of Object.keys(serviceCategoryGroupByDisplaySequence)) {
                let serviceCategories = serviceCategoryGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.serviceCategory.id);
                this.addServiceCategoryControls(formGroup, serviceCategories);
                formGroupsWithDisplaySequence[serviceCategories[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleValidityModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.validities?.length) {
            for (let validity of price.validities) {
                let formGroup = this.createNewFormGroup();
                if (validity.calendarValidityCode == this.SERVICE_CALENDAR_VALIDITY_CODE) {
                    formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.serviceDate.id);
                    this.addServiceDateControls(formGroup, validity);
                } else if (validity.calendarValidityCode == this.SALE_CALENDAR_VALIDITY_CODE) {
                    formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.salesDate.id);
                    this.addSalesDateControls(formGroup, validity);
                }
                formGroupsWithDisplaySequence[validity.displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleLocationModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.locations?.length) {
            let locationsGroupByDisplaySequence = this.groupByDisplaySequence(price.locations);
            for (let displaySequence of Object.keys(locationsGroupByDisplaySequence)) {
                let locations = locationsGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                if (
                    locations[0].priceRuleLocationPointCode ==
                    this.ORIGIN_PRICE_RULE_LOCATION_POINT_CODE
                ) {
                    formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.origin.id);
                    this.addOriginControls(formGroup, locations);
                    formGroupsWithDisplaySequence[locations[0].displaySequence - 1] = formGroup;
                } else if (
                    locations[0].priceRuleLocationPointCode ==
                    this.DESTINATION_PRICE_RULE_LOCATION_POINT_CODE
                ) {
                    formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.destination.id);
                    this.addDestinationControls(formGroup, locations);
                    formGroupsWithDisplaySequence[locations[0].displaySequence - 1] = formGroup;
                }
            }
        }
    }

    private convertPriceRuleRouteModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.routes?.length) {
            for (let route of price.routes) {
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.route.id);
                this.addRouteControls(formGroup, route);
                formGroupsWithDisplaySequence[route.displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleOrganisationModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.organisations?.length) {
            let organisationsGroupByDisplaySequence = this.groupByDisplaySequence(
                price.organisations
            );
            for (let displaySequence of Object.keys(organisationsGroupByDisplaySequence)) {
                let organisations = organisationsGroupByDisplaySequence[displaySequence];
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.organisation.id);
                this.addOrganisationControls(formGroup, organisations);
                formGroupsWithDisplaySequence[organisations[0].displaySequence - 1] = formGroup;
            }
        }
    }

    private convertPriceRuleProductNumberModelsToFormGroups(
        price: PriceModel,
        formGroupsWithDisplaySequence: any[]
    ) {
        if (price?.productNumbers?.length) {
            for (let productNumber of price.productNumbers) {
                let formGroup = this.createNewFormGroup();
                formGroup.controls[this.TYPE].setValue(PriceDimensionConstant.productNumber.id);
                this.addProductNumberControls(formGroup, productNumber);
                formGroupsWithDisplaySequence[productNumber.displaySequence - 1] = formGroup;
            }
        }
    }

    private groupByDisplaySequence(arr: any[]): any {
        return arr.reduce((prev, curr) => {
            let key = curr.displaySequence;
            prev[key] = prev[key] || [];
            prev[key].push(curr);
            return prev;
        }, {});
    }

    ngAfterViewInit() {
        this.completeProcessingWhenFormControlValueChanges();
    }

    private completeProcessingWhenFormControlValueChanges() {
        setTimeout(() => {
            this.formGroup.valueChanges.subscribe(() => {
                if (this.processing) {
                    this.completeProcessing();
                }
                
                this.emitPriceAttributesChangeWhenValuesChange();
            });
        }, 0);
    }

    private emitPriceAttributesChangeWhenValuesChange() {
        this.priceAttributes = [];
        for (let row of this.rows.controls as UntypedFormGroup[]) {
            if (row.invalid == false) {
                this.assignPriceAttribute(row);
            }
        }
        this.priceAttributesChange.emit(this.priceAttributes);
    }

    private getConditionReferences() {
        this.conditionReferenceService
            .getConditionsByCodes([
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE,
            ])
            .subscribe((conditionReferences: ConditionReferenceModel[]) => {
                this.conditionReferences$.next(conditionReferences);
            });
    }

    deleteRow(index: number) {
        this.rows.removeAt(index);
        this.currencyReferences$.next(this.currencyReferencesToSelect2Datas(this.currencyReferences));
        this.numberOfRowsChange.emit(this.rows?.length);
    }

    addRow() {
        if (this.someEmptyEntriesFound() || this.duplicateEntriesFound()) {
            this.startProcessing();
            return;
        }
        this.rows.push(this.createNewFormGroup());
        this.numberOfRowsChange.emit(this.rows?.length);
    }

    private someEmptyEntriesFound(): boolean {
        return this.rows.controls.some((group: UntypedFormGroup) => {
            return Object.keys(group.controls).some(key =>
                group.controls[key].hasError('required')
            );
        });
    }

    private duplicateEntriesFound(): boolean {
        return this.rows.hasError('duplicate');
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            rows: new UntypedFormArray([], duplicateGroupValidation),
        });
    }

    private createNewFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            type: new UntypedFormControl(null, Validators.required),
        });
    }

    private resetFormGroup(group: UntypedFormGroup) {
        Object.keys(group.controls).forEach(key => {
            switch (key) {
                case this.TYPE:
                    break;
                default:
                    group.removeControl(key);
                    break;
            }
        });
    }

    onTypeChange(value: string | string[], row: UntypedFormGroup) {
        let type = value instanceof Array ? value[0] : value;
        this.resetFormGroup(row);
        switch (type) {
            case PriceDimensionConstant.productNumber.id:
                this.addProductNumberControls(row);
                break;
            case PriceDimensionConstant.organisation.id:
                this.addOrganisationControls(row);
                break;
            case PriceDimensionConstant.route.id:
                this.addRouteControls(row);
                break;
            case PriceDimensionConstant.origin.id:
                this.addOriginControls(row);
                break;
            case PriceDimensionConstant.destination.id:
                this.addDestinationControls(row);
                break;
            case PriceDimensionConstant.serviceDate.id:
                this.addServiceDateControls(row);
                break;
            case PriceDimensionConstant.salesDate.id:
                this.addSalesDateControls(row);
                break;
            case PriceDimensionConstant.serviceCategory.id:
                this.addServiceCategoryControls(row);
                break;
            case PriceDimensionConstant.salesBucket.id:
                this.addSalesBucketControls(row);
                break;
            case PriceDimensionConstant.memberLevel.id:
                this.addTierControls(row);
                break;
            case PriceDimensionConstant.currency.id:
                this.addCurrencyControls(row);
                break;
            case PriceDimensionConstant.attribute.id:
                this.addAttributeControls(row);
                break;
        }
        row.get('type').patchValue(type);
    }

    private addAttributeControls(row: UntypedFormGroup, attributes: PriceAttributeModel[] = null) {
        let attributeType = null;
        let attributeChoices = null;

        if (this.domainAttributes?.length) {
            attributeType = this.domainAttributes[0].id;
        }
        if (attributes) {
            attributeType = attributes[0].attributeTypeCode;
            attributeChoices = attributes.map(a => a.attributeChoiceId);
        }

        this.addControl(row, this.ATTRIBUTE_TYPE, attributeType);
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.ATTRIBUTE_CHOICE, attributeChoices);
    }

    private addCurrencyControls(row: UntypedFormGroup, currencies: PriceRuleCurrencyModel[] = null) {
        let value = null;
        if (currencies) {
            value = currencies.map(c => c.currencyCode);
        }
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.CURRENCY, value);
    }

    private addTierControls(row: UntypedFormGroup, memberships: PriceRuleMembershipModel[] = null) {
        let value = null;
        if (memberships) {
            value = memberships.map(m => m.membershipLevelCode);
        }
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.LEVEL, value);
    }

    private addSalesBucketControls(
        row: UntypedFormGroup,
        salesBuckets: PriceRuleSalesBucketModel[] = null
    ) {
        let value = null;
        if (salesBuckets) {
            value = salesBuckets.map(salesBucket => salesBucket.salesBucketCode);
        }
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.SALES_BUCKET, value);
    }

    private addServiceCategoryControls(
        row: UntypedFormGroup,
        serviceCategories: PriceRuleServiceCategoryModel[] = null
    ) {
        let value = null;

        if (serviceCategories) {
            value = serviceCategories.map(sc => sc.serviceCategoryCode);
        }

        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.SERVICE_CATEGORY_CODE, value);
    }

    private addSalesDateControls(row: UntypedFormGroup, validity: PriceRuleValidityModel = null) {
        let startDate = null;
        let endDate = null;

        if (validity) {
            startDate = this.dateConverter.convertDateString(
                validity.startDateTime,
                this.yearMonthDateFormat
            );
            endDate = this.dateConverter.convertDateString(
                validity.endDateTime,
                this.yearMonthDateFormat
            );
        }

        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE);
        this.addControl(row, this.START_DATE, startDate);
        this.addControl(row, this.END_DATE, endDate);
    }

    private addServiceDateControls(row: UntypedFormGroup, validity: PriceRuleValidityModel = null) {
        let startDate = null;
        let endDate = null;

        if (validity) {
            startDate = this.dateConverter.convertDateString(
                validity.startDateTime,
                this.yearMonthDateFormat
            );
            endDate = this.dateConverter.convertDateString(
                validity.endDateTime,
                this.yearMonthDateFormat
            );
        }

        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE);
        this.addControl(row, this.START_DATE, startDate);
        this.addControl(row, this.END_DATE, endDate);
    }

    private addDestinationControls(row: UntypedFormGroup, locations: PriceRuleLocationModel[] = null) {
        let areaType = AreaConstant.region.id;
        let controlName = this.DESTINATION_REGION;
        let value = null;

        if (locations) {
            let location = locations[0];
            if (location.regionCode) {
                areaType = AreaConstant.region.id;
                controlName = this.DESTINATION_REGION;
                value = locations.map(l => l.regionCode);
            } else if (location.countryCode) {
                areaType = AreaConstant.country.id;
                controlName = this.DESTINATION_COUNTRY;
                value = locations.map(l => l.countryCode);
            } else if (location.locationId) {
                areaType = AreaConstant.airportOrCity.id;
                controlName = this.DESTINATION_AIRPORT_CITY;
                value = locations.map(l => l.locationId);
            }
        }

        this.addControl(row, this.AREA, areaType);
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, controlName, value);
    }

    private addOriginControls(row: UntypedFormGroup, locations: PriceRuleLocationModel[] = null) {
        let areaType = AreaConstant.region.id;
        let controlName = this.ORIGIN_REGION;
        let value = null;

        if (locations) {
            let location = locations[0];
            if (location.regionCode) {
                areaType = AreaConstant.region.id;
                controlName = this.ORIGIN_REGION;
                value = locations.map(l => l.regionCode);
            } else if (location.countryCode) {
                areaType = AreaConstant.country.id;
                controlName = this.ORIGIN_COUNTRY;
                value = locations.map(l => l.countryCode);
            } else if (location.locationId) {
                areaType = AreaConstant.airportOrCity.id;
                controlName = this.ORIGIN_AIRPORT_CITY;
                value = locations.map(l => l.locationId);
            }
        }

        this.addControl(row, this.AREA, areaType);
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, controlName, value);
    }

    private addRouteControls(row: UntypedFormGroup, route: PriceRuleRouteModel = null) {
        let origin = null;
        let destination = null;
        if (route) {
            origin = route.originLocationId;
            destination = route.destinationLocationId;
        }
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.ORIGIN_LOCATION_ID, origin);
        this.addControl(row, this.DESTINATION_LOCATION_ID, destination);
    }

    private addOrganisationControls(
        row: UntypedFormGroup,
        organisations: PriceRuleOrganisationModel[] = null
    ) {
        let typeOrganisationId = TypeOrganisationConstant.specific.id;
        let controlName = this.ORGANISATION_SPECIFIC;
        let value = null;

        if (organisations) {
            let organisation = organisations[0];
            if (organisation.organisationGroupCode) {
                typeOrganisationId = TypeOrganisationConstant.group.id;
                controlName = this.ORGANISATION_GROUP;
                value = organisations.map(o => o.organisationGroupCode);
            } else if (organisation.organisationId) {
                typeOrganisationId = TypeOrganisationConstant.specific.id;
                controlName = this.ORGANISATION_SPECIFIC;
                value = organisations.map(o => o.organisationId);
            } else if (organisation.organisationRoleCode) {
                typeOrganisationId = TypeOrganisationConstant.role.id;
                controlName = this.ORGANISATION_ROLE;
                value = organisations.map(o => o.organisationRoleCode);
            } else if (organisation.organisationTypeCode) {
                typeOrganisationId = TypeOrganisationConstant.type.id;
                controlName = this.ORGANISATION_TYPE;
                value = organisations.map(o => o.organisationTypeCode);
            }
        }

        this.addControl(row, this.TYPE_ORGANISATION, typeOrganisationId);
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, controlName, value);
    }

    private addProductNumberControls(
        row: UntypedFormGroup,
        productNumber: PriceRuleProductNumberModel = null
    ) {
        this.addControl(row, this.CONDITION_CODE, PricingDetailConstant.IS_OPERATOR_CODE);
        this.addControl(row, this.PRODUCT_NUMBER, productNumber?.productNumber);
    }

    onTypeOrganisationChange(value: string | string[], row: UntypedFormGroup) {
        this.resetOrganisationFormGroup(row);
        switch (value) {
            case TypeOrganisationConstant.specific.id:
                this.addControl(row, this.ORGANISATION_SPECIFIC);
                break;
            case TypeOrganisationConstant.type.id:
                this.addControl(row, this.ORGANISATION_TYPE);
                break;
            case TypeOrganisationConstant.role.id:
                this.addControl(row, this.ORGANISATION_ROLE);
                break;
            case TypeOrganisationConstant.group.id:
                this.addControl(row, this.ORGANISATION_GROUP);
                break;
        }
        row.get(this.TYPE_ORGANISATION).patchValue(value);
    }

    private resetOrganisationFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.ORGANISATION_SPECIFIC);
        group.removeControl(this.ORGANISATION_TYPE);
        group.removeControl(this.ORGANISATION_ROLE);
        group.removeControl(this.ORGANISATION_GROUP);
    }

    onOriginAreaChange(value: string | string[], row: UntypedFormGroup) {
        this.resetOriginAreaFormGroup(row);
        switch (value) {
            case AreaConstant.region.id:
                this.addControl(row, this.ORIGIN_REGION);
                break;
            case AreaConstant.country.id:
                this.addControl(row, this.ORIGIN_COUNTRY);
                break;
            case AreaConstant.airportOrCity.id:
                this.addControl(row, this.ORIGIN_AIRPORT_CITY);
                break;
        }
        row.get(this.AREA).patchValue(value);
    }

    onDestinationAreaChange(value: string | string[], row: UntypedFormGroup) {
        this.resetDestinationAreaFormGroup(row);
        switch (value) {
            case AreaConstant.region.id:
                this.addControl(row, this.DESTINATION_REGION);
                break;
            case AreaConstant.country.id:
                this.addControl(row, this.DESTINATION_COUNTRY);
                break;
            case AreaConstant.airportOrCity.id:
                this.addControl(row, this.DESTINATION_AIRPORT_CITY);
                break;
        }
        row.get(this.AREA).patchValue(value);
    }

    private addControl(row: UntypedFormGroup, controlName: string, defaultValue: any = null) {
        row.addControl(controlName, new UntypedFormControl(defaultValue, Validators.required));
    }

    private resetOriginAreaFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.ORIGIN_REGION);
        group.removeControl(this.ORIGIN_COUNTRY);
        group.removeControl(this.ORIGIN_AIRPORT_CITY);
    }

    private resetDestinationAreaFormGroup(group: UntypedFormGroup) {
        group.removeControl(this.DESTINATION_REGION);
        group.removeControl(this.DESTINATION_COUNTRY);
        group.removeControl(this.DESTINATION_AIRPORT_CITY);
    }

    public getValues(price: PriceModel): PriceModel {
        this.startProcessing();
        if (!this.validForm()) {
            return null;
        }

        this.FillPriceDimensionValuesToPriceModel(price);
        return price;
    }

    private FillPriceDimensionValuesToPriceModel(price: PriceModel) {
        for (let i = 0; i < this.rows.controls.length; i++) {
            let group = this.rows.controls[i] as UntypedFormGroup;
            let type = group.controls[this.TYPE].value;
            let displaySequence = i + 1;
            switch (type) {
                case PriceDimensionConstant.productNumber.id:
                    this.convertFormGroupToPriceRuleProductNumberModel(
                        displaySequence,
                        group,
                        price
                    );
                    break;
                case PriceDimensionConstant.organisation.id:
                    this.convertFormGroupToPriceRuleOrganisationModels(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.route.id:
                    this.convertFormGroupToPriceRuleRouteModel(displaySequence, group, price);
                    break;
                case PriceDimensionConstant.origin.id:
                    this.convertFormGroupToOriginPriceRuleLocationModel(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.destination.id:
                    this.convertFormGroupToDestinationPriceRuleLocationModel(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.serviceDate.id:
                    this.convertFormGroupToServicePriceRuleValidityModel(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.salesDate.id:
                    this.convertFormGroupToSalePriceRuleValidityModel(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.serviceCategory.id:
                    this.convertFormGroupToPriceRuleServiceCategoryModels(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.salesBucket.id:
                    this.convertFormGroupToPriceRuleSalesBucketModels(
                        group,
                        displaySequence,
                        price
                    );
                    break;
                case PriceDimensionConstant.memberLevel.id:
                    this.convertFormGroupToPriceRuleMembershipModels(group, displaySequence, price);
                    break;
                case PriceDimensionConstant.currency.id:
                    this.convertFormGroupToPriceRuleCurrencyModel(group, displaySequence, price);
                    break;
                case PriceDimensionConstant.attribute.id:
                    this.convertFormGroupToPriceAttributeModels(group, displaySequence, price);
                    break;
            }
        }
    }

    private convertFormGroupToPriceRuleCurrencyModel(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let currencyCodes = group.controls[this.CURRENCY].value as [];
        let currencies = currencyCodes.map(currencyCode => {
            let currency = {} as PriceRuleCurrencyModel;
            currency.displaySequence = displaySequence;
            currency.currencyCode = currencyCode;
            currency.excludeFlag = false;
            return currency;
        });
        price.currencies = price.currencies.concat(currencies);
    }

    private convertFormGroupToPriceAttributeModels(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let choiceIds = group.controls[this.ATTRIBUTE_CHOICE].value as [];
        let attributes = choiceIds.map(choiceId => {
            let attribute = new PriceAttributeModel();
            attribute.displaySequence = displaySequence;
            attribute.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
            attribute.excludeFlag = false;
            attribute.attributeTypeCode = group.controls[this.ATTRIBUTE_TYPE].value;
            attribute.attributeChoiceId = choiceId;
            return attribute;
        });
        price.attributes = price.attributes.concat(attributes);
    }

    private convertFormGroupToPriceRuleMembershipModels(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let membershipTypeCodes = group.controls[this.LEVEL].value as [];
        let memberships = membershipTypeCodes.map(membershipTypeCode => {
            let membership = new PriceRuleMembershipModel();
            membership.excludeFlag = false;
            membership.membershipLevelCode = membershipTypeCode;
            membership.displaySequence = displaySequence;
            return membership;
        });
        price.memberships = price.memberships.concat(memberships);
    }

    private convertFormGroupToPriceRuleSalesBucketModels(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let salesBucketCodes = group.controls[this.SALES_BUCKET].value as [];
        let salesBuckets = salesBucketCodes.map(salesBucketCode => {
            let salesBucket = new PriceRuleSalesBucketModel();
            salesBucket.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
            salesBucket.salesBucketCode = salesBucketCode;
            salesBucket.displaySequence = displaySequence;
            return salesBucket;
        });
        price.salesBuckets = price.salesBuckets.concat(salesBuckets);
    }

    private convertFormGroupToPriceRuleServiceCategoryModels(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let serviceCategoryCodes = group.controls[this.SERVICE_CATEGORY_CODE].value as [];
        let serviceCategories = serviceCategoryCodes.map(serviceCategoryCode => {
            let serviceCategory = new PriceRuleServiceCategoryModel();
            serviceCategory.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
            serviceCategory.serviceCategoryCode = serviceCategoryCode;
            serviceCategory.displaySequence = displaySequence;
            return serviceCategory;
        });
        price.serviceCategories = price.serviceCategories.concat(serviceCategories);
    }

    private convertFormGroupToSalePriceRuleValidityModel(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let saleValidity = new PriceRuleValidityModel();
        saleValidity.conditionCode = PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE;
        saleValidity.calendarValidityCode = this.SALE_CALENDAR_VALIDITY_CODE;
        saleValidity.startDateTime = this.pricingConverter.convertToDate(
            group.controls[this.START_DATE].value
        );
        saleValidity.endDateTime = this.pricingConverter.convertToDate(
            group.controls[this.END_DATE].value
        );
        saleValidity.displaySequence = displaySequence;
        price.validities.push(saleValidity);
    }

    private convertFormGroupToServicePriceRuleValidityModel(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let serviceValidity = new PriceRuleValidityModel();
        serviceValidity.conditionCode = PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE;
        serviceValidity.calendarValidityCode = this.SERVICE_CALENDAR_VALIDITY_CODE;
        serviceValidity.startDateTime = this.pricingConverter.convertToDate(
            group.controls[this.START_DATE].value
        );
        serviceValidity.endDateTime = this.pricingConverter.convertToDate(
            group.controls[this.END_DATE].value
        );
        serviceValidity.displaySequence = displaySequence;
        price.validities.push(serviceValidity);
    }

    private convertFormGroupToDestinationPriceRuleLocationModel(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let destinationLocations = new Array<PriceRuleLocationModel>();
        let destinationAreaType = group.controls[this.AREA].value;
        switch (destinationAreaType) {
            case AreaConstant.region.id:
                destinationLocations =
                    this.convertFormGroupToDestinationPriceRuleLocationModelsWithRegionCodes(
                        group,
                        destinationLocations,
                        displaySequence
                    );
                break;
            case AreaConstant.country.id:
                destinationLocations =
                    this.convertFormGroupToDestinationPriceRuleLocationModelsWithCountryCodes(
                        group,
                        destinationLocations,
                        displaySequence
                    );
                break;
            case AreaConstant.airportOrCity.id:
                destinationLocations =
                    this.convertFormGroupToDestinationPriceRuleLocationModelsWithLocationIds(
                        group,
                        destinationLocations,
                        displaySequence
                    );
                break;
        }
        price.locations = price.locations.concat(destinationLocations);
    }

    private convertFormGroupToDestinationPriceRuleLocationModelsWithLocationIds(
        group: UntypedFormGroup,
        destinationLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let locationIds = group.controls[this.DESTINATION_AIRPORT_CITY].value as [];
        destinationLocations = locationIds.map(locationId => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode =
                this.DESTINATION_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.locationId = locationId;
            return originLocation;
        });
        return destinationLocations;
    }

    private convertFormGroupToDestinationPriceRuleLocationModelsWithCountryCodes(
        group: UntypedFormGroup,
        destinationLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let countryCodes = group.controls[this.DESTINATION_COUNTRY].value as [];
        destinationLocations = countryCodes.map(countryCode => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode =
                this.DESTINATION_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.countryCode = countryCode;
            return originLocation;
        });
        return destinationLocations;
    }

    private convertFormGroupToDestinationPriceRuleLocationModelsWithRegionCodes(
        group: UntypedFormGroup,
        destinationLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let regionCodes = group.controls[this.DESTINATION_REGION].value as [];
        destinationLocations = regionCodes.map(regionCode => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode =
                this.DESTINATION_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.regionCode = regionCode;
            return originLocation;
        });
        return destinationLocations;
    }

    private convertFormGroupToOriginPriceRuleLocationModel(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let originLocations = new Array<PriceRuleLocationModel>();
        let areaType = group.controls[this.AREA].value;
        switch (areaType) {
            case AreaConstant.region.id:
                originLocations =
                    this.convertFormGroupToOriginPriceRuleLocationModelWithRegionCodes(
                        group,
                        originLocations,
                        displaySequence
                    );
                break;
            case AreaConstant.country.id:
                originLocations =
                    this.convertFormGroupToOriginPriceRuleLocationModelWithCountryCodes(
                        group,
                        originLocations,
                        displaySequence
                    );
                break;
            case AreaConstant.airportOrCity.id:
                originLocations =
                    this.convertFormGroupToOriginPriceRuleLocationModelWithLocationIds(
                        group,
                        originLocations,
                        displaySequence
                    );
                break;
        }
        price.locations = price.locations.concat(originLocations);
    }

    private convertFormGroupToOriginPriceRuleLocationModelWithLocationIds(
        group: UntypedFormGroup,
        originLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let locationIds = group.controls[this.ORIGIN_AIRPORT_CITY].value as [];
        originLocations = locationIds.map(locationId => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode = this.ORIGIN_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.locationId = locationId;
            return originLocation;
        });
        return originLocations;
    }

    private convertFormGroupToOriginPriceRuleLocationModelWithCountryCodes(
        group: UntypedFormGroup,
        originLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let countryCodes = group.controls[this.ORIGIN_COUNTRY].value as [];
        originLocations = countryCodes.map(countryCode => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode = this.ORIGIN_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.countryCode = countryCode;
            return originLocation;
        });
        return originLocations;
    }

    private convertFormGroupToOriginPriceRuleLocationModelWithRegionCodes(
        group: UntypedFormGroup,
        originLocations: PriceRuleLocationModel[],
        displaySequence: number
    ) {
        let regionCodes = group.controls[this.ORIGIN_REGION].value as [];
        originLocations = regionCodes.map(regionCode => {
            let originLocation = new PriceRuleLocationModel();
            originLocation.excludeFlag = false;
            originLocation.priceRuleLocationTypeCode = this.PRICE_RULE_LOCATION_TYPE_CODE;
            originLocation.priceRuleLocationPointCode = this.ORIGIN_PRICE_RULE_LOCATION_POINT_CODE;
            originLocation.displaySequence = displaySequence;
            originLocation.regionCode = regionCode;
            return originLocation;
        });
        return originLocations;
    }

    private convertFormGroupToPriceRuleRouteModel(
        displaySequence: number,
        group: UntypedFormGroup,
        price: PriceModel
    ) {
        let route = new PriceRuleRouteModel();
        route.displaySequence = displaySequence;
        route.excludeFlag = false;
        route.originLocationId = group.controls[this.ORIGIN_LOCATION_ID].value;
        route.destinationLocationId = group.controls[this.DESTINATION_LOCATION_ID].value;
        price.routes.push(route);
    }

    private convertFormGroupToPriceRuleOrganisationModels(
        group: UntypedFormGroup,
        displaySequence: number,
        price: PriceModel
    ) {
        let typeOrganisation = group.controls[this.TYPE_ORGANISATION].value;
        let organisations = new Array<PriceRuleOrganisationModel>();
        switch (typeOrganisation) {
            case TypeOrganisationConstant.specific.id:
                organisations = this.convertFormGroupToPriceRuleOrganisationModelsWithIds(
                    group,
                    organisations,
                    displaySequence
                );
                break;
            case TypeOrganisationConstant.type.id:
                organisations = this.convertFormGroupToPriceRuleOrganisationModelsWithTypes(
                    group,
                    organisations,
                    displaySequence
                );
                break;
            case TypeOrganisationConstant.role.id:
                organisations = this.convertFormGroupToPriceRuleOrganisationModelsWithRoles(
                    group,
                    organisations,
                    displaySequence
                );
                break;
            case TypeOrganisationConstant.group.id:
                organisations = this.convertFormGroupToPriceRuleOrganisationModelWithGroups(
                    group,
                    organisations,
                    displaySequence
                );
                break;
        }
        price.organisations = price.organisations.concat(organisations);
    }

    private convertFormGroupToPriceRuleOrganisationModelWithGroups(
        group: UntypedFormGroup,
        organisations: PriceRuleOrganisationModel[],
        displaySequence: number
    ): PriceRuleOrganisationModel[] {
        let organisationGroups = group.controls[this.ORGANISATION_GROUP].value as [];
        organisations = organisationGroups.map(group => {
            let organisation = new PriceRuleOrganisationModel();
            organisation.excludeFlag = false;
            organisation.displaySequence = displaySequence;
            organisation.organisationGroupCode = group;
            return organisation;
        });
        return organisations;
    }

    private convertFormGroupToPriceRuleOrganisationModelsWithRoles(
        group: UntypedFormGroup,
        organisations: PriceRuleOrganisationModel[],
        displaySequence: number
    ): PriceRuleOrganisationModel[] {
        let organisationRoles = group.controls[this.ORGANISATION_ROLE].value as [];
        organisations = organisationRoles.map(role => {
            let organisation = new PriceRuleOrganisationModel();
            organisation.excludeFlag = false;
            organisation.displaySequence = displaySequence;
            organisation.organisationRoleCode = role;
            return organisation;
        });
        return organisations;
    }

    private convertFormGroupToPriceRuleOrganisationModelsWithTypes(
        group: UntypedFormGroup,
        organisations: PriceRuleOrganisationModel[],
        displaySequence: number
    ): PriceRuleOrganisationModel[] {
        let organisationTypes = group.controls[this.ORGANISATION_TYPE].value as [];
        organisations = organisationTypes.map(type => {
            let organisation = new PriceRuleOrganisationModel();
            organisation.excludeFlag = false;
            organisation.displaySequence = displaySequence;
            organisation.organisationTypeCode = type;
            return organisation;
        });
        return organisations;
    }

    private convertFormGroupToPriceRuleOrganisationModelsWithIds(
        group: UntypedFormGroup,
        organisations: PriceRuleOrganisationModel[],
        displaySequence: number
    ): PriceRuleOrganisationModel[] {
        let organisationIds = group.controls[this.ORGANISATION_SPECIFIC].value as [];
        organisations = organisationIds.map(id => {
            let organisation = new PriceRuleOrganisationModel();
            organisation.excludeFlag = false;
            organisation.displaySequence = displaySequence;
            organisation.organisationId = id;
            return organisation;
        });
        return organisations;
    }

    private convertFormGroupToPriceRuleProductNumberModel(
        displaySequence: number,
        group: UntypedFormGroup,
        price: PriceModel
    ) {
        let productNumber = new PriceRuleProductNumberModel();
        productNumber.displaySequence = displaySequence;
        productNumber.productNumber = group.controls[this.PRODUCT_NUMBER].value;
        productNumber.excludeFlag = false;
        price.productNumbers.push(productNumber);
    }

    public validateFormGroup() {
        this.startProcessing();
    }

    public resetForm() {
        this.completeProcessing();
        this.initForm();
        this.completeProcessingWhenFormControlValueChanges();
    }

    onCurrenciesChange(value: string | string[], formControl: AbstractControl) {
        let currencyCodes = value instanceof Array ? value : [value];
        formControl.setValue(currencyCodes);
        let currencyTypeCodes = this.getCurrencyTypeCodes(currencyCodes);
        if (!currencyTypeCodes?.length) {
            this.currencyReferences$.next(this.toCurrencyReferenceSelect2Data(this.currencyReferences));
            return;
        }
        if (currencyTypeCodes.includes(this.NO_CURRENCY_TYPE)) {
            this.currencyReferences$.next(this.getCurrencySelect2DataOnlyNoCurrency());
            return;
        }
        if (currencyTypeCodes.includes(this.POINTS_MILES_TYPE)) {
            this.currencyReferences$.next(this.getCurrencySelect2DataNoNoCurrencyAndPointsMiles(currencyCodes));
            return;
        }
        this.currencyReferences$.next(this.getCurrencySelect2DataNoNoCurrency());
    }

    private getCurrencySelect2DataOnlyNoCurrency(): Select2Data[] {
        return this.toCurrencyReferenceSelect2Data(
            this.currencyReferences.filter(
                (cr) => cr.currencyTypeCode == this.NO_CURRENCY_TYPE
            )
        );
    }

    private getCurrencySelect2DataNoNoCurrencyAndPointsMiles(
        currencyCodes: string[]
    ) {
        return this.toCurrencyReferenceSelect2Data(
            this.currencyReferences.filter(
                (cr) =>
                    currencyCodes.includes(cr.currencyCode) ||
                    (cr.currencyTypeCode != this.POINTS_MILES_TYPE &&
                        cr.currencyTypeCode != this.NO_CURRENCY_TYPE)
            )
        );
    }

    private getCurrencySelect2DataNoNoCurrency() {
        return this.toCurrencyReferenceSelect2Data(
            this.currencyReferences.filter(
                (cr) => cr.currencyTypeCode != this.NO_CURRENCY_TYPE
            )
        );
    }

    private toCurrencyReferenceSelect2Data(currencies: CurrencyReferenceModel[]): Select2Data[] {
        return currencies?.map(
            (c) => new Select2Data(c.currencyCode, c.currencyName)
        );
    }

    private getCurrencyTypeCodes(currencyCodes: string[]): string[] {
        return currencyCodes?.map((cc) => this.getCurrencyTypeCode(cc));
    }

    private getCurrencyTypeCode(currencyCode: string): string {
        return this.currencyReferences.find((c) => c.currencyCode == currencyCode)?.currencyTypeCode;
    }

    public onValueChange(control: AbstractControl, values: string | string[]) {
        control.setValue(values);
    }

    private getLocationReferencesPreSelected() {
        let originLocationIds = this.price.routes?.map(r => r.originLocationId);
        let destinationLocationIds = this.price.routes?.map(r => r.destinationLocationId);
        let locationLocationIds = this.price.locations?.map(l => l.locationId);
        let locationIds = originLocationIds.concat(destinationLocationIds);
        locationIds = locationIds.concat(locationLocationIds);
        locationIds = [...new Set(locationIds)];
        if (locationIds?.length) {
            this.locationReferenceService
                .getLocationReference(locationIds.filter(id => id != null))
                .subscribe(
                    res => {
                        this.locationReferences$.next(res.map(r => new Select2Data(r.locationId, r.locationName)))
                    }
                )
        }
    }

    public getFormGroups(fa: UntypedFormArray): UntypedFormGroup[] {
        return fa.controls.map(c => c as UntypedFormGroup);
    }

    public onProductNumberChange(event: Event, row: UntypedFormGroup) {
        row.get(this.PRODUCT_NUMBER).patchValue((event.target as HTMLInputElement).value);
    }
}
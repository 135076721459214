import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_TRANSPORT_REVENUE_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 467,
        salesChannel1Name: "GDS",
        salesChannel2: 140,
        salesChannel2Name: "API",
        salesChannel3: 271,
        salesChannel3Name: "ISE",
        salesChannel4: 246,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 347,
        salesChannel1Name: "GDS",
        salesChannel2: 324,
        salesChannel2Name: "API",
        salesChannel3: 432,
        salesChannel3Name: "ISE",
        salesChannel4: 471,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 196,
        salesChannel1Name: "GDS",
        salesChannel2: 423,
        salesChannel2Name: "API",
        salesChannel3: 494,
        salesChannel3Name: "ISE",
        salesChannel4: 185,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 236,
        salesChannel1Name: "GDS",
        salesChannel2: 335,
        salesChannel2Name: "API",
        salesChannel3: 446,
        salesChannel3Name: "ISE",
        salesChannel4: 294,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 190,
        salesChannel1Name: "GDS",
        salesChannel2: 341,
        salesChannel2Name: "API",
        salesChannel3: 188,
        salesChannel3Name: "ISE",
        salesChannel4: 324,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 321,
        salesChannel1Name: "GDS",
        salesChannel2: 483,
        salesChannel2Name: "API",
        salesChannel3: 207,
        salesChannel3Name: "ISE",
        salesChannel4: 180,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 193,
        salesChannel1Name: "GDS",
        salesChannel2: 264,
        salesChannel2Name: "API",
        salesChannel3: 306,
        salesChannel3Name: "ISE",
        salesChannel4: 420,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 500,
        salesChannel1Name: "GDS",
        salesChannel2: 187,
        salesChannel2Name: "API",
        salesChannel3: 352,
        salesChannel3Name: "ISE",
        salesChannel4: 329,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 367,
        salesChannel1Name: "GDS",
        salesChannel2: 117,
        salesChannel2Name: "API",
        salesChannel3: 344,
        salesChannel3Name: "ISE",
        salesChannel4: 388,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 102,
        salesChannel1Name: "GDS",
        salesChannel2: 295,
        salesChannel2Name: "API",
        salesChannel3: 180,
        salesChannel3Name: "ISE",
        salesChannel4: 431,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 300,
        salesChannel1Name: "GDS",
        salesChannel2: 482,
        salesChannel2Name: "API",
        salesChannel3: 379,
        salesChannel3Name: "ISE",
        salesChannel4: 300,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 449,
        salesChannel1Name: "GDS",
        salesChannel2: 420,
        salesChannel2Name: "API",
        salesChannel3: 302,
        salesChannel3Name: "ISE",
        salesChannel4: 194,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 400,
        salesChannel1Name: "GDS",
        salesChannel2: 284,
        salesChannel2Name: "API",
        salesChannel3: 407,
        salesChannel3Name: "ISE",
        salesChannel4: 413,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 108,
        salesChannel1Name: "GDS",
        salesChannel2: 247,
        salesChannel2Name: "API",
        salesChannel3: 135,
        salesChannel3Name: "ISE",
        salesChannel4: 466,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 259,
        salesChannel1Name: "GDS",
        salesChannel2: 156,
        salesChannel2Name: "API",
        salesChannel3: 106,
        salesChannel3Name: "ISE",
        salesChannel4: 290,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 265,
        salesChannel1Name: "GDS",
        salesChannel2: 381,
        salesChannel2Name: "API",
        salesChannel3: 329,
        salesChannel3Name: "ISE",
        salesChannel4: 226,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 116,
        salesChannel1Name: "GDS",
        salesChannel2: 375,
        salesChannel2Name: "API",
        salesChannel3: 182,
        salesChannel3Name: "ISE",
        salesChannel4: 264,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 192,
        salesChannel1Name: "GDS",
        salesChannel2: 208,
        salesChannel2Name: "API",
        salesChannel3: 147,
        salesChannel3Name: "ISE",
        salesChannel4: 495,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 157,
        salesChannel1Name: "GDS",
        salesChannel2: 225,
        salesChannel2Name: "API",
        salesChannel3: 275,
        salesChannel3Name: "ISE",
        salesChannel4: 173,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 248,
        salesChannel1Name: "GDS",
        salesChannel2: 462,
        salesChannel2Name: "API",
        salesChannel3: 181,
        salesChannel3Name: "ISE",
        salesChannel4: 152,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 285,
        salesChannel1Name: "GDS",
        salesChannel2: 338,
        salesChannel2Name: "API",
        salesChannel3: 367,
        salesChannel3Name: "ISE",
        salesChannel4: 141,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 109,
        salesChannel1Name: "GDS",
        salesChannel2: 103,
        salesChannel2Name: "API",
        salesChannel3: 343,
        salesChannel3Name: "ISE",
        salesChannel4: 238,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 459,
        salesChannel1Name: "GDS",
        salesChannel2: 184,
        salesChannel2Name: "API",
        salesChannel3: 155,
        salesChannel3Name: "ISE",
        salesChannel4: 287,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 315,
        salesChannel1Name: "GDS",
        salesChannel2: 376,
        salesChannel2Name: "API",
        salesChannel3: 324,
        salesChannel3Name: "ISE",
        salesChannel4: 264,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 460,
        salesChannel1Name: "GDS",
        salesChannel2: 403,
        salesChannel2Name: "API",
        salesChannel3: 294,
        salesChannel3Name: "ISE",
        salesChannel4: 317,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 401,
        salesChannel1Name: "GDS",
        salesChannel2: 391,
        salesChannel2Name: "API",
        salesChannel3: 104,
        salesChannel3Name: "ISE",
        salesChannel4: 276,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 462,
        salesChannel1Name: "GDS",
        salesChannel2: 498,
        salesChannel2Name: "API",
        salesChannel3: 458,
        salesChannel3Name: "ISE",
        salesChannel4: 403,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 286,
        salesChannel1Name: "GDS",
        salesChannel2: 399,
        salesChannel2Name: "API",
        salesChannel3: 402,
        salesChannel3Name: "ISE",
        salesChannel4: 300,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 498,
        salesChannel1Name: "GDS",
        salesChannel2: 416,
        salesChannel2Name: "API",
        salesChannel3: 179,
        salesChannel3Name: "ISE",
        salesChannel4: 402,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 290,
        salesChannel1Name: "GDS",
        salesChannel2: 388,
        salesChannel2Name: "API",
        salesChannel3: 212,
        salesChannel3Name: "ISE",
        salesChannel4: 264,
        salesChannel4Name: "OWN"
    }
]

import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";

export const PRODUCT_DASHBOARD_FEES_SUMMARY_DEMO: ProductDashboardSummaryModel[] = [
    {
        productName: "Individual Fee",
        todayProductCount: 128,
        todaySalesAmount: 85000.39,
        yesterdayProductCount: 69,
        yesterdaySalesAmount: 23000.74,
        last7dProductCount: 276,
        last7dSalesAmount: 789000.45
    },
    {
        productName: "Payment Fee",
        todayProductCount: 106,
        todaySalesAmount: 129530.32,
        yesterdayProductCount: 124,
        yesterdaySalesAmount: 176500.65,
        last7dProductCount: 391,
        last7dSalesAmount: 3311560.12
    },
    {
        productName: "Sales Fee",
        todayProductCount: 30,
        todaySalesAmount: 65831.60,
        yesterdayProductCount: 85,
        yesterdaySalesAmount: 94210.24,
        last7dProductCount: 208,
        last7dSalesAmount: 2716882.94
    },
    {
        productName: "Service Fee",
        todayProductCount: 58,
        todaySalesAmount: 79347.00,
        yesterdayProductCount: 54,
        yesterdaySalesAmount: 87740.30,
        last7dProductCount: 380,
        last7dSalesAmount: 6651389.33
    },
    {
        productName: "Admin Fee",
        todayProductCount: 126,
        todaySalesAmount: 185247.75,
        yesterdayProductCount: 148,
        yesterdaySalesAmount: 365443.96,
        last7dProductCount: 494,
        last7dSalesAmount: 9644319.10
    }
]

export class TranslationTextChildView {
    public no: number;
    public selected: boolean;
    public mediaId: string;
    public mediaName: string;
    public mediaContentId: string;
    public languageCode: string;
    public languageName: string;
    public text: string;
    public mediaTypeCode: string;
}

import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_SPECIALSERVICE_REVENUE_LAST7D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 29,
        salesChannel1Name: "GDS",
        salesChannel2: 34,
        salesChannel2Name: "API",
        salesChannel3: 22,
        salesChannel3Name: "ISE",
        salesChannel4: 15,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 44,
        salesChannel1Name: "GDS",
        salesChannel2: 20,
        salesChannel2Name: "API",
        salesChannel3: 12,
        salesChannel3Name: "ISE",
        salesChannel4: 24,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 17,
        salesChannel1Name: "GDS",
        salesChannel2: 33,
        salesChannel2Name: "API",
        salesChannel3: 40,
        salesChannel3Name: "ISE",
        salesChannel4: 10,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 35,
        salesChannel1Name: "GDS",
        salesChannel2: 15,
        salesChannel2Name: "API",
        salesChannel3: 24,
        salesChannel3Name: "ISE",
        salesChannel4: 26,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 22,
        salesChannel1Name: "GDS",
        salesChannel2: 12,
        salesChannel2Name: "API",
        salesChannel3: 20,
        salesChannel3Name: "ISE",
        salesChannel4: 46,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 39,
        salesChannel1Name: "GDS",
        salesChannel2: 27,
        salesChannel2Name: "API",
        salesChannel3: 24,
        salesChannel3Name: "ISE",
        salesChannel4: 10,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 18,
        salesChannel1Name: "GDS",
        salesChannel2: 18,
        salesChannel2Name: "API",
        salesChannel3: 23,
        salesChannel3Name: "ISE",
        salesChannel4: 41,
        salesChannel4Name: "OWN"
    }  
]

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { TextView } from "./text.view";
import {
    MediaCommandModel,
    MediaViewModel,
    MediaSearchCommandModel,
    MediaSearchViewModel
} from 'src/app/core/models/media-model';
import { MediaContentCommandModel, MediaContentViewModel } from 'src/app/core/models/media-model/media-content';
import { MediaFileAssociationCommandModel } from 'src/app/core/models/media-model/media-fileassociation';
import {
    MediaMediaHashTagCommandModel,
    MediaMediaHashTagViewModel
} from 'src/app/core/models/media-model/media-media-hashtag';
import { MediaUseCommandModel } from 'src/app/core/models/media-model/media-use';

import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { TextLibraryView } from "./text-library.view";
import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel
} from "src/app/core/models/reference-model/reference-media-model";
import { MediaFileAssociationInMediaViewModel } from "src/app/core/models/media-model/media-fileassociation/media-fileassociation-in-media-view.model";

@Injectable()
export class TextMapperService {

    constructor(private dateConverterService: DateConverterService) {
    }

    public textToMediaModels(texts: TextView[], entityName: string, associationId: string): MediaCommandModel[] {
        let models: MediaCommandModel[] = new Array();
        if (texts) {
            for (let text of texts) {
                models.push(this.textToMediaModel(text, entityName, associationId));
            }
        }
        return models;
    }

    private textToMediaModel(text: TextView, entityName: string, associationId: string): MediaCommandModel {
        let model = new MediaCommandModel();
        if (text.mediaId) {
            model.mediaId = text.mediaId;
        }
        model.mediaTypeCode = text.mediaTypeCode;
        model.mediaName = text.mediaName;
        model.validFromDateTime = text.validFrom;
        model.validToDateTime = text.validTo;
        model.mediaContents = this.textToMediaContentModels(text);
        model.mediaFileAssociation = this.textToMediaFileAssociationModel(text, entityName, associationId);
        model.mediaMediaHashTags = this.textToMediaMediaHashTagModels(text);
        model.mediaUses = this.textToMediaUseModels(text);
        if (text.mediaTranslationId) {
            model.mediaTranslationId = text.mediaTranslationId;
        }
        return model;
    }

    private textToMediaContentModels(text: TextView): MediaContentCommandModel[] {
        let models: MediaContentCommandModel[] = new Array();
        if (text) {
            let model = new MediaContentCommandModel();
            if (text.mediaContentId) {
                model.mediaContentId = text.mediaContentId;
            }
            model.languageCode = text.languageCode;
            model.mediaText = text.text;
            model.primaryLanguageFlag = true;
            models.push(model);
        }
        return models;
    }

    private textToMediaFileAssociationModel(text: TextView, entityName: string, associationId: string): MediaFileAssociationCommandModel {
        let model = new MediaFileAssociationCommandModel();
        if (text) {
            if (text.mediaFileAssociationId) {
                model.mediaFileAssociationId = text.mediaFileAssociationId;
            }
            model.entityName = entityName;
            if (associationId) {
                model.associationId = associationId;
            }
            model.mediaUseCode = text.mediaUseCode;
            model.mediaId = text.mediaId;
        }
        return model;
    }

    private textToMediaMediaHashTagModels(text: TextView): MediaMediaHashTagCommandModel[] {
        let models: MediaMediaHashTagCommandModel[] = new Array();
        if (text) {
            let i = 0;
            for (let mediaHashTagId of text.mediaHashTagIds) {
                let model = new MediaMediaHashTagCommandModel();
                i++;
                model.mediaMediaHashTagId = this.findMediaMediaHashTagId(text, mediaHashTagId);
                model.mediaHashtagId = mediaHashTagId;
                model.displaySequence = i;
                models.push(model);
            }
        }
        return models;
    }

    private findMediaMediaHashTagId(text: TextView, mediaHashTagId: string): string {
        if (text.mediaMediaHashTagIds) {
            var filter = text.mediaMediaHashTagIds.filter(x => x.id == mediaHashTagId);
            if (filter.length > 0) {
                return filter[0].text;
            }
        }
    }

    private textToMediaUseModels(text: TextView): MediaUseCommandModel[] {
        let models: MediaUseCommandModel[] = new Array();
        if (text) {
            let model = new MediaUseCommandModel();
            if (text.mediaMediaUseId) {
                model.mediaMediaUseId = text.mediaMediaUseId;
            }
            model.mediaUseCode = text.mediaUseCode;
            models.push(model);
        }
        return models;
    }

    public mediaToTextViews(models: MediaViewModel[]): TextView[] {
        let views: TextView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                var mediaContentPrimary = this.findMediaContentPrimaryLanguage(model);
                if (mediaContentPrimary) {
                    if(mediaContentPrimary.mediaText) {
                        ++i;
                        views.push(this.mediaToTextView(i, model));
                    }
                }
            }
        }
        return views;
    }

    private mediaToTextView(i: number, model: MediaViewModel): TextView {
        let view = new TextView();
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        if (model.validFromDateTime) {
            view.validFrom = this.dateConverterService.toDateFormat(model.validFromDateTime);
        }
        if (model.validToDateTime) {
            view.validTo = this.dateConverterService.toDateFormat(model.validToDateTime);
        }
        view.mediaHashTagIds = this.mediaMediaHashTagToView(model.mediaMediaHashTags);
        view.mediaMediaHashTagIds = this.getMediaMediaHashTagId(model.mediaMediaHashTags);
        view.mediaHashTagName = this.getMediaHashTagName(model.mediaMediaHashTags);
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaTypeName;
        var mediaContentPrimary = this.findMediaContentPrimaryLanguage(model);
        if (mediaContentPrimary) {
            view.languageCode = mediaContentPrimary.languageCode;
            view.languageName = mediaContentPrimary.languageName;
            view.text = mediaContentPrimary.mediaText;
            view.mediaContentId = mediaContentPrimary.mediaContentId;
        }
        view.mediaMediaUseId = model.mediaUses[0].mediaMediaUseId;
        if (model.mediaFileAssociations) {
            view.mediaUseCode = model.mediaFileAssociations[0].mediaUseCode;
            view.mediaUseName = model.mediaFileAssociations[0].mediaUseName;
            view.mediaFileAssociationId = model.mediaFileAssociations[0].mediaFileAssociationId;
        }
        return view;
    }

    private mediaMediaHashTagToView(mediaMediaHashTags: MediaMediaHashTagViewModel[]): string[] {
        let mediaMediaHashTagIds: string[] = new Array();
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                mediaMediaHashTagIds.push(mediaMediaHashTag.mediaHashtagId);
            }
        }
        return mediaMediaHashTagIds;
    }

    private getMediaMediaHashTagId(mediaMediaHashTags: MediaMediaHashTagViewModel[]): Select2Data[] {
        let mediaMediaHashTagIds: Select2Data[] = new Array();
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                let mediaMediaHashTagId = new Select2Data();
                mediaMediaHashTagId.id = mediaMediaHashTag.mediaHashtagId;
                mediaMediaHashTagId.text = mediaMediaHashTag.mediaMediaHashTagId;
                mediaMediaHashTagIds.push(mediaMediaHashTagId);
            }
        }
        return mediaMediaHashTagIds;
    }

    private getMediaHashTagName(mediaMediaHashTags: MediaMediaHashTagViewModel[]): string {
        let name: string = "";
        if (mediaMediaHashTags) {
            for (let mediaMediaHashTag of mediaMediaHashTags) {
                if (name != "") {
                    name += "," + mediaMediaHashTag.mediaHashtagName;
                }
                else {
                    name += mediaMediaHashTag.mediaHashtagName;
                }
            }
        }
        return name;
    }

    public mediaTextToSearchCommand(model: TextView): MediaSearchCommandModel {
        let mediaSearchCommandModel = new MediaSearchCommandModel();
        if (model) {
            mediaSearchCommandModel.mediaName = model.mediaName;
            mediaSearchCommandModel.mediaTypeCode = model.mediaTypeCode;
            mediaSearchCommandModel.mediaUseCode = model.mediaUseCode;
            mediaSearchCommandModel.languageCode = model.languageCode;
            mediaSearchCommandModel.mediaHashtagIds = model.mediaHashTagIds;
            mediaSearchCommandModel.isMediaText = true;
        }
        return mediaSearchCommandModel;
    }

    public mediaSearchToTextLibraryViews(models: MediaSearchViewModel[]): TextLibraryView[] {
        let views: TextLibraryView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.mediaSearchToTextLibraryView(i, model));
            }
        }
        return views;
    }

    private mediaSearchToTextLibraryView(i: number, model: MediaSearchViewModel): TextLibraryView {
        let view = new TextLibraryView();
        view.no = i;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        if (model.validFromDateTime) {
            view.validFrom = this.dateConverterService.toDateFormat(model.validFromDateTime);
        }
        if (model.validToDateTime) {
            view.validTo = this.dateConverterService.toDateFormat(model.validToDateTime);
        }
        view.mediaHashTagIds = this.mediaMediaHashTagToView(model.mediaMediaHashTags);
        view.mediaMediaHashTagIds = this.getMediaMediaHashTagId(model.mediaMediaHashTags);
        view.mediaHashTagName = this.getMediaHashTagName(model.mediaMediaHashTags);
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaTypeName;
        view.languageCode = model.mediaContents[0].languageCode;
        view.languageName = model.mediaContents[0].languageName;
        view.text = model.mediaContents[0].mediaText;
        view.mediaContentId = model.mediaContents[0].mediaContentId;
        view.mediaMediaUseId = model.mediaUses[0].mediaMediaUseId;
        if (model.mediaUses) {
            view.mediaUseCode = model.mediaUses[0].mediaUseCode;
            view.mediaUseName = model.mediaUses[0].mediaUseName;
        }

        view.totalUsedCount = model.totalUsedCount;
        return view;
    }

    public getHashTagName(mediaHashTagIds: string[], mediaHashTagSelect2Data: Select2Data[]): string {
        var hashTagName: string = "";
        if (mediaHashTagIds && mediaHashTagSelect2Data) {
            for (let mediaMediaHashTag of mediaHashTagIds) {
                var filter = mediaHashTagSelect2Data.filter(x => x.id == mediaMediaHashTag);
                if (filter.length > 0) {
                    if (hashTagName != "") {
                        hashTagName += "," + filter[0].text;
                    }
                    else {
                        hashTagName += filter[0].text;
                    }
                }
            }
        }
        return hashTagName;
    }

    public getMediaUseName(mediaUseCode: string, mediaUseReference$: BehaviorSubject<MediaUseReferenceModel[]>): string {
        if (mediaUseCode && mediaUseReference$.value) {
            var filter = mediaUseReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
            if (filter.length > 0) {
                return filter[0].mediaUseName;
            }
        }
    }

    public getMediaTypeName(mediaTypeCode: string, mediaTypeReference$: BehaviorSubject<MediaTypeReferenceModel[]>): string {
        if (mediaTypeCode && mediaTypeReference$.value) {
            var filter = mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaTypeCode);
            if (filter.length > 0) {
                return filter[0].mediaTypeName;
            }
        }
    }

    public getLanguageName(languageCode: string, languageReference$: BehaviorSubject<LanguageReferenceModel[]>): string {
        if (languageCode && languageReference$.value) {
            var filter = languageReference$.value.filter(x => x.languageCode == languageCode);
            if (filter.length > 0) {
                return filter[0].languageName;
            }
        }
    }

    public mediaTextLibraryToMediaTextViews(textLibraryViews: TextLibraryView[]): TextView[] {
        var models: TextView[] = new Array();
        if (textLibraryViews) {
            for (let textLibraryView of textLibraryViews) {
                models.push(this.mediaTextLibraryToMediaTextView(textLibraryView));
            }
        }
        return models;
    }

    private mediaTextLibraryToMediaTextView(model: TextLibraryView): TextView {
        let view = new TextView();
        view.no = 0;
        view.mediaId = model.mediaId;
        view.mediaName = model.mediaName;
        if (model.validFrom) {
            view.validFrom = model.validFrom;
        }
        if (model.validTo) {
            view.validTo = model.validTo;
        }
        view.text = model.text;
        view.mediaContentId = model.mediaContentId;
        view.mediaMediaHashTagIds = model.mediaMediaHashTagIds;
        view.mediaHashTagIds = model.mediaHashTagIds;
        view.mediaHashTagName = model.mediaHashTagName;
        view.mediaTypeCode = model.mediaTypeCode;
        view.mediaTypeName = model.mediaHashTagName;
        view.languageCode = model.languageCode;
        view.languageName = model.languageName;
        view.mediaMediaUseId = model.mediaMediaUseId;
        view.mediaUseCode = model.mediaUseCode;
        view.mediaUseName = model.mediaUseName;
        view.selectedFromLibrary = true;
        return view;
    }

    public displayTextMediaInGrid(text: string) {
        if (text) {
            let textReturn = text.substring(0, 255).replace(/(?:\r\n|\r|\n)/g, "<br>");
            if (text.length > 255) {
                textReturn += " ...";
            }
            return textReturn;
        }
    }

    private findMediaContentPrimaryLanguage(model: MediaViewModel): MediaContentViewModel {
        if (model.mediaContents) {
            var filter = model.mediaContents.filter(x => x.primaryLanguageFlag == true);
            if (filter.length > 0) {
                return filter[0];
            }
        }
    }

    public updateMediaId(mediaId: string, views: MediaFileAssociationInMediaViewModel[]): MediaFileAssociationInMediaViewModel[] {
        if (views) {
            for (let view of views) {
                view.mediaId = mediaId;
            }
        }
        return views;
    }

    public setDefaultPrimaryLanguage(languageReference$: BehaviorSubject<LanguageReferenceModel[]>, model: TextView) {
        if (languageReference$.value && !model.languageCode) {
            var filter = languageReference$.value.filter(x => x.primaryFlag == true);
            if (filter.length > 0) {
                model.languageCode = filter[0].languageCode;
                model.languageName = filter[0].languageName;
            }
        }
    }

    public mediaModelsToTexts(texts: TextView[], entityName: string, associationId: string): MediaCommandModel[] {
        let models: MediaCommandModel[] = new Array();
        if (texts) {
            for (let text of texts) {
                models.push(this.textToMediaModel(text, entityName, associationId));
            }
        }
        return models;
    }

    private mediaModelToText(text: TextView, entityName: string, associationId: string): MediaCommandModel {
        let model = new MediaCommandModel();
        if (text.mediaId) {
            model.mediaId = text.mediaId;
        }
        model.mediaTypeCode = text.mediaTypeCode;
        model.mediaName = text.mediaName;
        model.validFromDateTime = text.validFrom;
        model.validToDateTime = text.validTo;
        model.mediaContents = this.textToMediaContentModels(text);
        model.mediaFileAssociation = this.textToMediaFileAssociationModel(text, entityName, associationId);
        model.mediaMediaHashTags = this.textToMediaMediaHashTagModels(text);
        model.mediaUses = this.textToMediaUseModels(text);
        if (text.mediaTranslationId) {
            model.mediaTranslationId = text.mediaTranslationId;
        }
        return model;
    }
}
export class MerchandizeSearchTableView {
    public no: number;
    public id: string;
    public rootProductId: string;
    public name: string;
    public productGroupCode: string;
    public productGroupName: string;
    public productTypeCode: string;
    public productTypeName: string;
    public productTypeGroupCode: string;
    public productTypeGroupName: string;
    public usageTypeCode: string;
    public usageTypeName: string;
    public statusCode: string;
    public statusName: string;
    public commitBy: string;
    public commitDateTime: Date;
}
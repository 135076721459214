import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';

import { OrganisationRoleReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class OrganisationRoleReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/OrganisationRoleReferences"

    constructor(httpClient: HttpClient,
        authService: AuthService,
        private stringHelperService: StringHelperService) {
        super(httpClient, authService);
    }

    public getByCodes(codes: string[]): Observable<OrganisationRoleReferenceModel[]> {
        const paramName = 'organisationRoleCode';
        let params = this.stringHelperService.createQueryParamFromArray(paramName, codes);
        return this.httpGet<OrganisationRoleReferenceModel[]>(this.PATH + params, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByName(organisationRoleName: string): Observable<OrganisationRoleReferenceModel[]> {
        const apiPath: string = '/name/';
        return this.httpGet<OrganisationRoleReferenceModel[]>(this.PATH + apiPath + organisationRoleName, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject, Subscription, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { ACTION_STATUS, ActionBarHandlerModel, ActionbarService } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { DistributionTypeReferenceModel, DocumentTypeReferenceModel, DomainAttributeModel, MessagePriorityReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { DistributionTypeReferenceService, DocumentDistributionService, DocumentTypeReferenceService, MessagePriorityReferenceService, OperationEventReferenceService, StatusReferenceService } from "src/app/core/services/system-services";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { DocumentDistributionAttributeModel, DocumentDistributionCommandModel, DocumentDistributionEventCommandModel, DocumentDistributionEventModel, DocumentDistributionModel, DocumentDistributionRuleCommandModel, DocumentDistributionRuleModel, DocumentDistributionSearchModel } from "src/app/core/models/document-distribution-model";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { PAGE_TITLE } from "src/app/modules/settings-management/settings-management-content/shared/title-constant";
import { DocumentDistributionRuleComponent } from "./document-distribution-rule/document-distribution-rule.component";
import { RuleMapperService } from "./document-distribution-rule/shared/rule-mapper.service";
import { DocumentDistributionRuleView } from "./document-distribution-rule/shared/document-distribution-rule.view";
import { DocumentDistributionDetailGeneralComponent } from "./document-distribution-detail-general/document-distribution-detail-general.component";
import { DocumentDistributionEventComponent } from "./document-distribution-event/document-distribution-event.component";
import { OperationEventReferenceModel } from "src/app/core/models/reference-model/reference-general-model/operation-event-reference.model";
import { EventMapperService } from "./document-distribution-event/shared/event-mapper.service";
import { DocumentDistributionAttributeComponent } from "./document-distribution-attribute/document-distribution-attribute.component";
import { AttributeRowView } from "./document-distribution-attribute/shared/attribute-row-view";
import { AttributeMapperService } from "./document-distribution-attribute/shared/attribute-mapper.service";
import { DomainAttributeService } from "src/app/core/services/system-services/domain-attribute.service";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { OopsCategory } from "src/app/core/components/categories";

@Component({
    selector: 'op-document-distribution-detail',
    templateUrl: './document-distribution-detail.component.html',
    providers: [
        ActionbarService,
        RuleMapperService,
        EventMapperService,
        AttributeMapperService
    ]
})
export class DocumentDistributionDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly generalDetailTabId = 'general';
    readonly distributionRuleTabId = 'distributionRule';
    readonly distributionEventTabId = 'distributionEvent';
    readonly attributeComponent = 'attribute';
    private readonly RECEIVE_ORGANISTION_TYPE_CODE = ["AIRLINE", "CUSTOMS","GDS","GROUNDHANDLER", "IMMIGRATION","OTA","APIS"]
    private readonly GENERAL_PANEL_NAME = 'General Infomation';
    private readonly GUID_EMPTY: string = "00000000-0000-0000-0000-000000000000";

    private unsubscribe$ = new Subject();
    private previousTitle: string = PAGE_TITLE.settings;
    private selectedCategory: OopsCategory;
    private searchCondition: DocumentDistributionSearchModel;

    public documentDistributionId: string;
    public attributeName: string;
    public userSecurity: SecurityGroupSecurityModel;
    public pagingDataView: PagingDataView[];
    public currentIndex: number;

    public documentDistribution$ = new BehaviorSubject<DocumentDistributionModel>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public organisation$ = new BehaviorSubject<OrganisationModel[]>(null);
    public providerReference$ = new BehaviorSubject<OrganisationModel[]>(null);
    public documentTypeReference$ = new BehaviorSubject<DocumentTypeReferenceModel[]>(null);
    public distributionTypeReference$ = new BehaviorSubject<DistributionTypeReferenceModel[]>(null);
    public messagePriorityReference$ = new BehaviorSubject<MessagePriorityReferenceModel[]>(null);
    public operationEventReference$ = new BehaviorSubject<OperationEventReferenceModel[]>(null);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public primaryDocumentDistribution$ = new BehaviorSubject<DocumentDistributionModel[]>(null);

    public newDocumentDistributionFlag: boolean = true;
    public newDocumentDistributionFlag$ = new BehaviorSubject<boolean>(true);
    public copyFlag: boolean = false;
    public displayName: string;

    private panelFocus: string;

    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    )

    private _navigationSubscription: Subscription;

    @ViewChild(DocumentDistributionDetailGeneralComponent) generalInformationComponent: DocumentDistributionDetailGeneralComponent;
    @ViewChild(DocumentDistributionRuleComponent) distributionRuleComponent: DocumentDistributionRuleComponent;
    @ViewChild(DocumentDistributionEventComponent) distributionEventComponent: DocumentDistributionEventComponent;
    @ViewChild(DocumentDistributionAttributeComponent) distributionAttributeComponent: DocumentDistributionAttributeComponent;
    

    constructor(private navigationService: NavigationService,
        private focusingService: FocusingService,
        private statusReferenceService: StatusReferenceService,
        private distributionTypeReferenceService: DistributionTypeReferenceService,
        private documentTypeReferenceService: DocumentTypeReferenceService,
        private messagePriorityReferenceService: MessagePriorityReferenceService,
        private operationEventReferenceService: OperationEventReferenceService,
        private organisationService: OrganisationService,
        private actionbarService: ActionbarService,
        private changeDetectionRef: ChangeDetectorRef,
        public spinnerService: LoadingSpinnerService,
        private documentDistributionService: DocumentDistributionService,
        private domainAttributeService: DomainAttributeService,
        private ruleMapperService: RuleMapperService,
        private eventMapperService: EventMapperService,
        private attributeMapperService: AttributeMapperService,
        public alertBarService: AlertBarService) {

    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.setupDocumentDistributionDetail();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.toolbarClicked(actionId);
            }
        )
        this.scrollToTop();
    }

    setupDocumentDistributionDetail() {
        if (this.newDocumentDistributionFlag === false) {
            this.getPrimaryDocumentDistribution(this.documentDistributionId);
            this.getDocumentDistribution(this.documentDistributionId);
        } else {
            this.getPrimaryDocumentDistribution(this.GUID_EMPTY);
        }
        if (this.userSecurity != null) {
            this.setupSecurity();
        }
    }

    private setupSecurity() {
        this.resetActionBarState();
        if (!this.userSecurity?.newFlag) {
            this.actionBarHandler.get(ACTION_STATUS.new).disable();
        }
        if (!this.userSecurity?.copyFlag) {
            this.actionBarHandler.get(ACTION_STATUS.copy).disable();
        }
        if ((this.newDocumentDistributionFlag == true && !this.userSecurity?.newFlag) || (this.newDocumentDistributionFlag == false && !this.userSecurity?.editFlag)) {
            this.actionBarHandler.get(ACTION_STATUS.save).disable(); 
        }
    }

    private resetActionBarState() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable();
        this.actionBarHandler.get(ACTION_STATUS.copy).enable();
        this.actionBarHandler.get(ACTION_STATUS.save).enable();
    }

    private scrollToTop() {
        window.scrollTo({top: 0, behavior:'smooth'});
    }

    toolbarClicked(actionId: string) {
        switch(actionId) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.cancel:
                this.cancel();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.copy:
                this.copy();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
            default:
                break;
        }
    }

    ngOnInit(): void {
        this.getStatusReferences();
        this.getReceiverOrganisation();
        this.getProviderReference();
        this.getDistributionTypeReferences();
        this.getDocumentTypeReferences();
        this.getMessagePriorityReferences();
        this.getOperationEventReferences();
        this.getDomainAttributes();
        this.getParam();
    }
    
    private getParam() {
        let param = this.navigationService.getParams();
        this.newDocumentDistributionFlag = param?.newFlag;
        this.newDocumentDistributionFlag$.next(param?.newFlag);
        this.documentDistributionId = param?.id ?? "";
        this.attributeName = param?.attributeName ?? "";
        this.userSecurity = param?.userSecurity;
        this.copyFlag = param?.copyFlag;
        this.pagingDataView = param?.pagingDataView ?? [];
        this.currentIndex = param?.currentIndex;
        this.selectedCategory = param?.selectedCategoryItem;
        this.searchCondition = param?.searchCondition;
    }

    public onPageChange(id: string): void {
        this.documentDistributionId = null;
        this.getPrimaryDocumentDistribution(id);
        this.getDocumentDistribution(id);
    }

    onActiveIdChange(activeId: string) {
        switch (activeId) {
            case this.generalDetailTabId :
                this.focusingService.focus(this.generalInformationComponent?.focusingDirective);
                break;
            case this.distributionRuleTabId : 
                this.focusingService.focus(this.distributionRuleComponent?.focusingDirective);
                break;
            case this.distributionEventTabId : 
                this.focusingService.focus(this.distributionEventComponent?.focusingDirective);
                break;    
            case this.attributeComponent : 
                this.focusingService.focus(this.distributionAttributeComponent?.focusingDirective);
                break;         
        }
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getReceiverOrganisation(): any {
        this.organisationService
            .getByOrganisationTypes(this.RECEIVE_ORGANISTION_TYPE_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    let organistions = responses.filter(x => x.usageTypeCode == 'DATA');
                    this.organisation$.next(organistions.sort((a, b) => this.sortByName(a.organisationCallName.toLowerCase(), b.organisationCallName.toLocaleLowerCase())));
                });
    }

    private getProviderReference() {
        this.organisationService.searchOrganisation("", "", "airline", "").subscribe(
            (responses: OrganisationModel[]) => {
                let distinctResponses = responses.filter((item, index, arr) => arr.findIndex(i => i.organisationId == item.organisationId) == index && item.usageTypeCode == 'DATA')
                this.providerReference$.next(distinctResponses.filter(x => x.providerIataCode).sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
            }
        );
    }


    private getDistributionTypeReferences() {
        this.distributionTypeReferenceService.getDistributionTypeReferences()
            .subscribe(
                (responses: DistributionTypeReferenceModel[]) => {
                    this.distributionTypeReference$.next(responses);  
                }
            )
    }

    private getDocumentTypeReferences() {
        this.documentTypeReferenceService.getDocumentTypeReferences()
            .subscribe(
                (responses: DocumentTypeReferenceModel[]) => {
                    this.documentTypeReference$.next(responses); 
                }
            )
    }

    private getMessagePriorityReferences() {
        this.messagePriorityReferenceService.getMessageProrityReferences()
            .subscribe(
                (responses: MessagePriorityReferenceModel[]) => {
                    this.messagePriorityReference$.next(responses); 
                }
            )
    }

    private getOperationEventReferences() {
        this.operationEventReferenceService.getOperationEventReferences()
            .subscribe(
                (responses: OperationEventReferenceModel[]) => {
                    this.operationEventReference$.next(responses); 
                }
            )
    }

    private getDomainAttributes() {
        this.domainAttributeService.getDocumentDistributionAttributes('', '')
        .subscribe(
            (response: DomainAttributeModel[]) => {
                this.domainAttribute$.next(response);
            }
        )
    }

    private fillDocumentDistributionRule(rules: DocumentDistributionRuleModel[]) {
        let ruleViews = this.ruleMapperService.toDocumenctDistributionRuleViews(rules);
        this.distributionRuleComponent.fillModelToForm(ruleViews);
    }

    private fillDocumentDistributionEvent(rules: DocumentDistributionEventModel[]) {
        let eventViews = this.eventMapperService.toDocumenctDistributionRuleViews(rules);
        this.distributionEventComponent.fillModelToForm(eventViews);
    }    

    private fillDocumentDistributionAttribute(attribute: DocumentDistributionAttributeModel[]) {
        let attributeViews = this.attributeMapperService.toDocumenctDistributionAttributeViews(attribute);
        this.distributionAttributeComponent.fillModelToForm(attributeViews);
    }       

    private sortByName(nameA: string, nameB: string): number {
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    save() {
        if (this.checkValid()) {
            let command =  this.generalInformationComponent.getValue();
            this.fillToCommand(command);
            this.hideAlertBar();
            if (this.newDocumentDistributionFlag == true || this.copyFlag == true) {
                this.addDocumentDistribution(command);
            }
            else {
                this.editDocumentDistribution(command);
            }
            this.changeDetectionRef.detectChanges;
        }   
        else {
            this.displayAlertBar();
        } 
    }

    addDocumentDistribution(command: DocumentDistributionCommandModel) {
        this.spinnerService.showSaving();
        this.documentDistributionService.addDocumentDistribution(command)
            .subscribe (
                (response) => {
                    this.documentDistributionId = response.documentDistributionId;
                    this.getDocumentDistribution(response.documentDistributionId);
                    this.newDocumentDistributionFlag = false;
                    this.newDocumentDistributionFlag$.next(false);
                    this.copyFlag = false;
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }
            )       
    }

    editDocumentDistribution(command: DocumentDistributionCommandModel) {
        this.spinnerService.showSaving();
        this.documentDistributionService.editDocumentDistribution(command, this.documentDistributionId)
            .subscribe (
                (response) => {
                    this.documentDistributionId = response.documentDistributionId;
                    this.getDocumentDistribution(response.documentDistributionId);
                    this.newDocumentDistributionFlag = false;
                    this.newDocumentDistributionFlag$.next(false);
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }
            )       
    }

    getDocumentDistribution(documentDistributionId: string) {
        this.spinnerService.show();
        forkJoin({
            response: this.documentDistributionService.getDocumentDistribution(documentDistributionId),
            domainAttributes: this.domainAttributeService.getDocumentDistributionAttributes('', '')
        })
        .subscribe(
                ({
                    response,
                    domainAttributes
                }) => {               
                    this.domainAttribute$.next(domainAttributes);
                    this.spinnerService.hide();
                    if (this.copyFlag) {
                        response.documentDistributionName = null;
                        response.primaryDocumentDistributionId = null;
                    }
                    this.documentDistribution$.next(response);
                    this.documentDistributionId = response.documentDistributionId;
                    this.fillDocumentDistributionRule(response.documentDistributionRules);
                    this.fillDocumentDistributionEvent(response.documentDistributionEvents);
                    this.fillDocumentDistributionAttribute(response.documentDistributionAttributes);
                    this.displayName = this.displayProductName(response.documentDistributionName);
                }
            ) 
    }   

    private checkValid() {
        return (this.generalInformationComponent.checkValid())
    }

    private fillToCommand(command: DocumentDistributionCommandModel) {
        command.documentDistributionRules = this.setDocumentDistributionRules(this.distributionRuleComponent.rules);
        command.documentDistributionEvents = this.setDocumentDistributionEvents(this.distributionEventComponent.distributionEvents);
        this.setDocumentDistributionAttributes(this.distributionAttributeComponent.getDocumentDistributionAttribute(), command);
    }

    private setDocumentDistributionRules(rules: DocumentDistributionRuleView[]) : DocumentDistributionRuleCommandModel[] {
        if (!rules?.length) {
            return null;           
        }

        let documentDistributionRules: DocumentDistributionRuleCommandModel[] = new Array();
        documentDistributionRules = this.ruleMapperService.toDocumentDistributionRuleCommands(rules);

        return documentDistributionRules;
    }

    private setDocumentDistributionEvents(events: string[]) : DocumentDistributionEventCommandModel[] {
        if (!events?.length) {
            return null;           
        }

        let documentDistributionEvents: DocumentDistributionEventCommandModel[] = new Array();
        documentDistributionEvents = this.eventMapperService.toDocumentDistributionEventCommands(events);

        return documentDistributionEvents;
    }

    private setDocumentDistributionAttributes(attributes: AttributeRowView[], command: DocumentDistributionCommandModel) {
        this.attributeMapperService.toDocumentDistributionAttributeCommands(attributes, command);
    }

    private new() {
        this.newDocumentDistributionFlag = true;
        this.newDocumentDistributionFlag$.next(true);
        this.copyFlag = false;
        this.documentDistributionId = null;
        this.documentDistribution$.next(null);
        this.domainAttribute$.next(null);
        this.generalInformationComponent.formReset();
        this.generalInformationComponent.disabledTimeDefinitionControlAll();
        this.pagingDataView = [];
        this.fillDocumentDistributionRule(null);
        this.fillDocumentDistributionEvent(null);
        this.fillDocumentDistributionAttribute(null);
        this.displayName = "";
    }

    private copy() {
        this.documentDistributionId = null;
        this.newDocumentDistributionFlag = false;
        this.newDocumentDistributionFlag$.next(false);
        this.copyFlag = true;
        this.setDetailDataForCopy();
    }

    private setDetailDataForCopy() {
        this.setRuleDataForCopy();
        this.setEventDataForCopy();
        this.setGeneralValueToEmpty();
    }

    private setRuleDataForCopy() {
        if (this.documentDistribution$.value.documentDistributionRules?.length) {
            let rules = this.documentDistribution$.value.documentDistributionRules;
            for (let rule of rules) {
                rule.documentDistributionRuleId = null;
                rule.documentDistributionId = null;
            }
            this.fillDocumentDistributionRule(rules);
        }
    }

    private setEventDataForCopy() {
        if (this.documentDistribution$.value.documentDistributionEvents?.length) {
            let events = this.documentDistribution$.value.documentDistributionEvents;
            for (let event of events) {
                event.documentDistributionEventId = null;
                event.documentDistributionId = null;
            }
            this.fillDocumentDistributionEvent(events);
        }
    }

    private cancel() {
        let params = {
            redirectToSetting: true
        }
        this.navigationService.navigate("main/settings/document-distribution", this.previousTitle, false, params);
    }

    private back() {
        let param = {
            activeId: 'SEARCH',
            selectedCategory: 'document distribution',
            userSecurity: this.userSecurity,
            selectedCategoryItem: this.selectedCategory,
            searchCondition: this.searchCondition
        }
        this.navigationService.navigate('/main/settings/home', null, false, param);       
    }

    private refresh() {
        this.getDocumentDistribution(this.documentDistributionId)
    }

    private hideAlertBar() {
        this.alertBarService.hide();
        this.alertBarService.hideWarning();
    }

    private displayAlertBar() {
        this.onActiveIdChange(this.generalDetailTabId);

        if (this.generalInformationComponent.validForm() == false || this.generalInformationComponent.timeDefinitionCodeInConditionRequired) {
            this.displayGeneralInfoRequired();
        }
    }

    private displayGeneralInfoRequired() {
        this.alertBarService.show(this.GENERAL_PANEL_NAME, this.generalInformationComponent.getErrorMessage());
    }

    onAlertBarClick() {
        this.onActiveIdChange(this.generalDetailTabId);
    }

    private getPrimaryDocumentDistribution(id: string) {
        this.documentDistributionService.getPrimaryDocumentDistribution(id)
        .subscribe(
            (response: DocumentDistributionModel[]) => {
                this.primaryDocumentDistribution$.next(response);
            }
        )
    }

    private setGeneralValueToEmpty() {
        this.generalInformationComponent.setEmptyValueForCopy();
    }

    

    private displayProductName(name: string) {
        return " :: " + name;
    }
}
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { UiHelperService } from "src/app/core/utils/ui-helper.service";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { CommentMapperService } from "./shared/comment.mapper.service";

import { CommentDetailComponent } from "./comment-detail/comment-detail.component";
import { CommentListComponent } from "./comment-list/comment-list.component";

import { CommentView } from "./shared/comment.view";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommentCommandModel, CommentViewModel } from "src/app/core/models/activity-model/activity-comment";
import { ActivityCommentService } from "src/app/core/services/activity-services";
import { MediaTypeFileTypeService, MediaTypeReferenceService } from "src/app/core/services/reference-service/reference-media-service";
import { MediaTypeFileTypeModel, MediaTypeReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { BehaviorSubject } from "rxjs";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { MediaView } from "src/app/core/components/media/shared/media.view";

@Component({
    selector: 'op-activity-detail-comment',
    templateUrl: './comment.component.html',
    providers: [CommentMapperService, MediaMapperService]
})
export class ActivityDetailCommentComponent implements OnChanges {
    private readonly SINGLE_POSITION_OFFSET = 355;
    private readonly MULTIPLE_POSITION_OFFSET = 330;
    private readonly LIST_POSITION_OFFSET = 320;
    private readonly DELAY_TIME_FOCUS = 200;
    @Input() id: string;
    @Input() commentTypeReferences: CommentTypeReferenceModel[];
    @Input() commentClassificationReferences: CommentClassificationReferenceModel[];
    @Input() commentViewModels: CommentViewModel[];
    @Input() multipleMode: boolean = true;
    @Output() formStatus = new EventEmitter<boolean>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(CommentListComponent) listComponent: CommentListComponent;
    @ViewChild(CommentDetailComponent) detailComponent: CommentDetailComponent;
    @ViewChild("detail") detail: ElementRef;
    @ViewChild("list") list: ElementRef;

    focusing: boolean = false;
    newFromDetail: boolean = false;
    newFromGrid: boolean = false;
    editFromGrid: boolean = false;
    commentViews: CommentView[] = new Array();
    model: CommentView;

    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);


    constructor(private focusingService: FocusingService,
        private uiHelper: UiHelperService,
        private changeDetectorRef: ChangeDetectorRef,
        private activityCommentService: ActivityCommentService,
        private commentMapperService: CommentMapperService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaMapperService: MediaMapperService) {
            this.getMediaTypeFileType();
            this.getMediaTypeReference();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['commentViewModels']) {
            this.createCommentViews();
        }
    }

    onApply(view: CommentView) {
        this.saveToViews(view);
        if (this.isMultipleRecord()) {
            this.resetModeMultiple();
        }
    }

    private createCommentViews() {
        this.commentViews = this.commentMapperService.toViews(this.commentViewModels);
        this.populateMedia();
        this.displayInCondition();
    }

    private populateMedia() {
        for (let i = 0; i < this.commentViewModels?.length; i++) {
            let comment = this.commentViewModels[i];
            if (comment.media) {
                let media = new MediaView();
                this.mediaMapperService.mediaViewModelToMediaView(comment.media, media, null);
                this.commentViews[i].media = media;
            }
        }
    }

    private displayInCondition() {
        if (this.isNoRecord()) {
            this.model = null;
        } else if (this.isSingleRecord()) {
            this.model = this.commentViews[0];
        }
    }

    private saveToViews(view: CommentView) {
        var index = this.commentViews.findIndex(x => x.no == view.no);
        if (index == -1) {
            view.no = this.commentViews.length + 1;
            this.commentViews.push(view);
        }
        else {
            this.commentViews[index] = view;
        }
        if (this.isSingleRecord()){
            this.model = this.commentViews[0];
        }
    }

    isSingleRecord(): boolean {
        return (this.commentViews?.length == 1);
    }

    isNoRecord(): boolean {
        return (!this.commentViews?.length == true);
    }

    isMultipleRecord(): boolean {
        return (this.commentViews?.length > 1);
    }

    onNewFromDetail() {
        this.setNewModeFromDetail().then(() => {
            this.moveToDetail(this.SINGLE_POSITION_OFFSET);
            this.focusingService.focus(this.detailComponent.focusingDirective);
        });
    }

    onNewFromGrid() {
        this.setNewMode().then(() => {
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onEdit(view: CommentView) {
        this.setEditMode(view).then(() => {
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onDelete(view: CommentView) {
        this.deletedata(view);
        if (this.isSingleRecord()) {
            this.model = this.commentViews[0];
        }
    }

    onCopy(view: CommentView) {
        this.setCopyMode(view);
    }

    onCancel() {
        if (this.isNoRecord()) {
            this.resetModeNoRecord();
        } else if (this.isSingleRecord() && !this.newFromDetail) {
            this.resetModeSingle();
        } else if (this.isSingleRecord() && this.newFromDetail) {
            this.resetModeSingleNewDetail();
        } else {
            this.resetModeMultiple();
        }
    }

    onRefresh() {
        this.resetMode().then(() => {
            this.refreshCurrentViews();
            this.displayInCondition();
        });
    }

    private refreshCurrentViews() {
        this.getComment();
    }

    private getComment() {
        this.activityCommentService.get(this.id)
            .subscribe(
                (response: CommentViewModel[]) => {
                    this.commentViewModels = [];
                    this.commentViewModels = response;
                    this.createCommentViews();
                }
            )
    }

    private resetModeNoRecord() {
        this.resetMode().then(() => {
            this.focusingService.focus(this.detailComponent.focusingDirective);
        });
    }

    private resetModeSingle() {
        this.resetMode().then(() => {
            this.commentViews = [];
            this.focusingService.focus(this.detailComponent.focusingDirective);
        });
    }

    private resetModeSingleNewDetail() {
        this.resetModeNewFromDetaill().then(() => {
            this.focusingService.focus(this.detailComponent.focusingDirective);
            this.detailComponent.applied = true;
        });
    }

    private resetModeMultiple() {
        this.resetMode().then(() => {
            this.moveToList(this.LIST_POSITION_OFFSET);
        });
    }

    private resetMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private resetModeNewFromDetaill(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = this.commentViews[0];
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewModeFromDetail(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromDetail = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setEditMode(view: CommentView): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = view;
            this.newFromGrid = false;
            this.editFromGrid = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setCopyMode(view: CommentView): Promise<void> {
        return new Promise((resolve, reject) => {
            let copyData = cloneDeep(view);
            copyData.no = null;
            copyData.commentId = undefined;
            copyData.productCommentId = undefined;
            copyData.commitByName = "";
            copyData.commitDateTime = null;
            this.model = copyData;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    public moveToDetail(offset: number) {
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.detail, offset);
        }, this.DELAY_TIME_FOCUS);
    }

    private moveToList(offset: number) {
        this.uiHelper.moveScrollPosition(this.list, offset);
        this.focusingService.focus(this.listComponent.focusingDirective);
    }

    getValue(): CommentCommandModel[] {
        return this.commentMapperService.toModels(this.commentViews);
    }

    validateForm(): boolean {
        if (!this.detailComponent) {
            return true;
        }

        if (this.detailComponent.processing) {
            return this.detailComponent.validateForm();
        }
        return true;
    }

    getErrorMessageForm(): string {
        if (this.detailComponent) {
            return this.detailComponent.getErrorMessageForm();
        }
    }

    onformStatusChange(formStatus: boolean) {
        this.formStatus.emit(formStatus);
    }

    private deletedata(data: CommentView) {
        let views = new Array<CommentView>();
        if (this.commentViews != null) {
            views = this.commentViews;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.commentViews = views;
        }
    }

    public applyDetailForm() {
        this.detailComponent.onApply();
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {

                    this.mediaTypeReference$.next(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }
}
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { cloneDeep } from "lodash";

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationNameMapperService } from './translation-name.mapper.service';
import { TranslationNameView } from './views/translationName.view';
import { TranslationView } from './views/translation.view';
import { CsvExportService, PdfExportService, PrintExportService } from 'src/app/core/utils/exports';
import { GoogletranslateService } from 'src/app/core/services/google-translation-service/google-translation.service';
import { GoogleObjRequest } from 'src/app/core/services/google-translation-service/dto/google-obj-request';
import { GoogleObjResponse } from 'src/app/core/services/google-translation-service/dto/google-obj-response';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-translation-name',
    templateUrl: './translation-name.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationNameComponent implements OnChanges {

    @Input() id: string;
    @Input() heightPanelGeneral: Number;
    @Input() classIcon: string;
    @Input() newTranslationName: string;
    @Input() panelName = 'TRANSLATIONS';
    @Input() columnName = "Name";
    @Input() pageSize: number = 10;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing: boolean = false;

    public nameViews: TranslationNameView[];
    public translationViews: TranslationView[];
    public translationGridViews: TranslationView[];
    public translationDB: TranslationView[];
    public languageTranslationSetId: string;

    languageDataSourceStorage: any;
    settings: any;

    constructor(public translationNameMapperService: TranslationNameMapperService,
        private csvExportService: CsvExportService,
        private pdfExportService: PdfExportService,
        private printExportService: PrintExportService,
        private googletranslationService: GoogletranslateService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['newTranslationName'] && changes["newTranslationName"].currentValue) {
            this.nameViews = this.translationNameMapperService.nameToNameViews(this.newTranslationName);
        }
    }

    getLanguage(key) {
        if(!this.translationGridViews) {
            return;
        }
        let item = this.languageDataSourceStorage?.find((i) => i.key === key);
        if (!item) {
            item = {
                key: key,
                dataSourceInstance: new DataSource({
                    store: new ArrayStore({
                        data: this.translationGridViews,
                        key: "id"
                    }),
                    filter: ["nameId", "=", key]
                })
            };
            this.languageDataSourceStorage.push(item);
        }
        return item.dataSourceInstance;
    }

    clearClick(e) {
        e.data.translation = "";
        e.component.refresh();
    }

    translationClick() {
        if (this.translationGridViews.length == 0) {
            return;
        }

        var filterSelected = this.translationGridViews.filter(x => x.selected == true);
        if (filterSelected.length == 0) {
            this.translateAllRows();
        }
        else {
            //translation selected
            this.translationText(filterSelected);
        }
    }

    private translateAllRows() {
        this.translationText(this.translationGridViews);
    }

    selectionChangedHandler(e) {
        for (let translation of this.translationGridViews) {
            var filter = e.instance.getSelectedRowsData().filter(x => x.id == translation.id);
            if (filter.length == 0) {
                translation.selected = false;
            }
            else {
                translation.selected = true;
            }
        }
    }
    
    public calculateHeight(heightPanelGeneral: any): Number {
        const paddingTopInPx = 8;
        const paddingBottomInPx = 8;
        return heightPanelGeneral - (paddingTopInPx + paddingBottomInPx);
    }

    public fillModelToForm(englishName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.nameViews = this.translationNameMapperService.nameToNameViews(englishName);
        this.setTranslationIdAndViews(languageTranslationSet);
        this.languageDataSourceStorage = [];
        this.translationViews = [];
        this.changeDetectorRef.markForCheck();
    }

    private setTranslationIdAndViews(languageTranslationSet: LanguageTranslationSetViewModel) {
        if (languageTranslationSet) {
            this.languageTranslationSetId = languageTranslationSet.languageTranslationSetId;
            this.translationGridViews = this.translationNameMapperService.languageTranslationSetModelToTranslationViews(languageTranslationSet);
            this.translationDB = this.translationNameMapperService.languageTranslationSetModelToTranslationViews(languageTranslationSet);
        }
        else {
            this.languageTranslationSetId = null;
            this.translationGridViews = [];
            this.translationDB = [];
        }
    }

    public exportToPDF() {
        this.pdfExportService.downloadJsonData("ProductNameTranslation", this.getExportData());
    }

    public exportToCSV() {
        this.csvExportService.download("ProductNameTranslation", this.getExportData());
    }

    public print() {
        this.printExportService.print(this.getExportData());
    }

    private getExportData() {
        return this.translationGridViews.map(dat => ({ Language: dat.languageName, Translation: dat.translation }));
    }

    private translationText(translations: TranslationView[]) {
        for (let translation of translations) {
            const googleObj: GoogleObjRequest = {
                q: [this.nameViews[0].name],
                target: translation.languageCode
            };
            this.googletranslationService.translate(googleObj).subscribe(
                (res: GoogleObjResponse) => {
                    if (res.data) {
                        translation.translation = res.data.translations[0].translatedText;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
        }
    }

    public clearForm() {
        this.translationGridViews = [];
        this.nameViews = [];
        this.translationDB = [];
        this.translationViews = [];
        this.languageDataSourceStorage = [];
        this.changeDetectorRef.detectChanges();
    }

    public onApply() {
        this.translationViews = cloneDeep(this.translationGridViews);
        this.changeDetectorRef.detectChanges();
    }

    public onCancel() {
        this.nameViews = this.translationNameMapperService.nameToNameViews(this.newTranslationName);
        this.languageDataSourceStorage = [];
        this.translationGridViews = cloneDeep(this.translationDB);
        this.translationViews = cloneDeep(this.translationDB);
        this.changeDetectorRef.detectChanges();
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { cloneDeep } from "lodash";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FormOption } from "./seatproperty-detail-form-config";
import { ProductServiceRequestModel } from "src/app/core/models/product-model/specialservice-model";
import { SeatPropertyGroupReferenceModel, SeatPropertyReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { select2Status, select2Search } from "../../../shared/select2-configuration";
import { SeatPropertyView } from "../shared/seatproperty.view";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

@Component({
    selector: 'op-vehicle-configuration-seatproperty-detail',
    templateUrl: './seatproperty-detail.component.html',
    providers: []
})
export class VehicleConfigurationSeatPropertyDetailComponent extends OopsComponentFormBase implements OnChanges {
    readonly ERROR_GROUP_REQUIRED = 'Group is required.';
    readonly ERROR_PROPERTY_REQUIRED = 'Property is required.';
    readonly ERROR_PROPERTY_DUPLICATE = 'is duplicate.';
    readonly ERROR_ROWFROM_REQUIRED = 'Row from is required.';
    readonly ERROR_ROWTO_REQUIRED = 'Row to is required.';
    readonly ERROR_COLUMN_REQUIRED = 'Column is required.';
    readonly ERROR_STATUS_REQUIRED = 'Status is required.';
    readonly FEE_CODE = 'FEE';

    @Input() id: string;
    @Input() serviceCategorydSelected: Select2Data;
    @Input() seatPropertyServiceCategoryViews: SeatPropertyView[] = new Array();
    @Input() seatMapRowSelected: number[];
    @Input() seatMapColSelected: string;
    @Input() seatMapColAll: string;
    @Input() seatPropertyReferences: SeatPropertyReferenceModel[];
    @Input() seatPropertyGroupReferences: SeatPropertyGroupReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() productServiceRequests: ProductServiceRequestModel[];
    @Input() model: SeatPropertyView;
    @Input() noRecord: boolean = false;
    @Input() singleRecord: boolean = false;
    @Input() multipleRecord: boolean = false;
    @Input() newFromGrid: boolean = false;
    @Input() newFromDetail: boolean = false;
    @Input() editFromGrid: boolean = false;
    @Output() view = new EventEmitter<SeatPropertyView>();
    @Output() new = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() formStatus = new EventEmitter<boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    focusing: boolean = false;
    statusOption = select2Status;
    groupOption = select2Search;
    propertyOption = select2Search;
    seatPropertyFilters: SeatPropertyReferenceModel[];
    applied: boolean = false;
    serviceCateName: string;

    constructor(formBuilder: UntypedFormBuilder) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setServiceCateName();
        if (changes['model']) {
            this.formSetup();
        }
    }

    initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(cloneDeep(FormOption));
        if (!this.model) {
            this.clearForm();
        }
        this.fillModelToForm(this.model);
        this.focusForm();
        this.displayInCondition();

        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private fillModelToForm(model: SeatPropertyView) {
        this.groupChange(model.seatPropertyGroupCode, 'group');
        this.formGroup.patchValue({
            no: model.no,
            vehicleConfigurationCompartmentPropertyId: model.vehicleConfigurationCompartmentPropertyId,
            vehicleConfigurationCompartmentId: this.model.vehicleConfigurationCompartmentId,
            group: model.seatPropertyGroupCode,
            property: model.seatPropertyId,
            product: model.productId,
            byrow: model.byRowFlag,
            rowfrom: model.seatRowFrom,
            rowto: model.seatRowTo,
            column: model.seatColumnRange,
            maxvalue: model.maxCount,
            baynumber: model.bayNumber,
            status: model.statusCode,
        });
    }

    private applyToModel() {
        let formValue = this.formGroup.getRawValue();
        let model: SeatPropertyView = {
            no: formValue.no,
            vehicleConfigurationCompartmentPropertyId: formValue.vehicleConfigurationCompartmentPropertyId,
            vehicleConfigurationCompartmentId: this.serviceCategorydSelected.id,
            seatPropertyGroupCode: formValue.group,
            seatPropertyGroupName: this.getSeatPropertyGroupName(formValue.group),
            seatPropertyId: formValue.property,
            seatPropertyName: this.getSeatPropertyName(formValue.property),
            productId: formValue.product,
            productName: this.getProductName(formValue.product),
            seatRowFrom: formValue.rowfrom,
            seatRowTo: formValue.rowto,
            seatColumnRange: formValue.column,
            byRowFlag: formValue.byrow ? true : false,
            maxCount: formValue.maxvalue,
            bayNumber: formValue.baynumber,
            seatRow: null,
            seatColumn: null,
            statusCode: formValue.status
        }
        this.applied = true;
        this.view.emit(model);
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    groupChange(value, name) {
        this.formGroup.controls[name].setValue(value);
        this.fillterSeatProperty(value);
        this.enableProduct(value);
    }

    propertyChange(value, name) {
        this.formGroup.controls[name].setValue(value);
        this.validateDuplicateProperty(value);
    }

    fillterSeatProperty(seatPropertyGroupCode: string) {
        if (seatPropertyGroupCode && this.seatPropertyReferences) {
            this.seatPropertyFilters = this.seatPropertyReferences.filter(x => x.seatPropertyGroupCode == seatPropertyGroupCode);
            if (this.seatPropertyFilters?.length) {
                this.formGroup.controls['property'].setValue(this.seatPropertyFilters[0].seatPropertyId);
            }
            else {
                this.formGroup.controls['property'].setValue(null);
            }
        }
    }

    private enableProduct(value) {
        if (value == this.FEE_CODE) {
            this.formGroup.controls['product'].enable();
        }
        else {
            this.formGroup.controls['product'].setValue(null);
            this.formGroup.controls['product'].disable();
        }
    }

    validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    private getSeatPropertyGroupName(seatPropertyGroupCode: string): string {
        let filter = this.seatPropertyGroupReferences.filter(x => x.seatPropertyGroupCode == seatPropertyGroupCode);
        return filter[0].seatPropertyGroupName;
    }

    private getSeatPropertyName(seatPropertyId: string): string {
        let filter = this.seatPropertyReferences.filter(x => x.seatPropertyId == seatPropertyId);
        return filter[0].seatPropertyName;
    }

    private getProductName(productId: string): string {
        if (!productId) {
            return null;
        }
        let filter = this.productServiceRequests.filter(x => x.productId == productId);
        return filter[0].productName;
    }

    private clearForm() {
        this.formGroup.reset();
        this.completeProcessing();
        this.model = {} as SeatPropertyView;
        this.model.statusCode = "A";
        this.updateSeatMapColSelected(this.seatMapColSelected, this.seatMapColAll);
        this.updateSeatMapRowSelected(this.seatMapRowSelected);
        this.seatPropertyFilters = [];
        this.applied = false;
        this.formStatus.emit(true);
    }

    private focusForm() {
        if (this.newFromGrid || this.editFromGrid) {
            this.focusing = true;
        }
    }

    private setServiceCateName() {
        if (this.serviceCategorydSelected) {
            this.serviceCateName = "-" + this.serviceCategorydSelected.text;
        }
    }

    private displayInCondition() {
        if (this.singleRecord && !this.newFromDetail) {
            this.applied = true;
        } else {
            this.applied = false;
        }
    }

    updateSeatMapRowSelected(seapMapRowSelected: number[]) {
        this.seatMapRowSelected = seapMapRowSelected;
        this.updateRowFrom();
        this.updateRowTo();
        this.validateDuplicateProperty(this.formGroup.controls['property'].value);
    }

    updateSeatMapColAll(seatMapColAll: string) {
        this.seatMapColAll = seatMapColAll;
        this.formGroup.controls['column'].setValue(this.seatMapColAll);
    }

    updateSeatMapColSelected(seatMapColSelected: string, seatMapColAll: string) {
        this.seatMapColSelected = seatMapColSelected;
        this.seatMapColAll = seatMapColAll;
        if (this.seatMapColSelected) {
            this.formGroup.controls['column'].setValue(this.seatMapColSelected);
        }
        else {
            this.formGroup.controls['column'].setValue(this.seatMapColAll);
        }
    }

    updateSeatPropertyServiceCategoryViews(seatPropertyServiceCategoryViews: SeatPropertyView[]) {
        this.seatPropertyServiceCategoryViews = seatPropertyServiceCategoryViews;
    }

    validateNo(e): boolean {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false
        }
        return true
    }

    private updateRowFrom() {
        if (this.seatMapRowSelected?.length) {
            this.formGroup.controls['rowfrom'].setValue(Math.min.apply(Math, this.seatMapRowSelected));
        }
        else {
            this.formGroup.controls['rowfrom'].setValue(null);
        }
    }

    private updateRowTo() {
        if (this.seatMapRowSelected?.length) {
            this.formGroup.controls['rowto'].setValue(Math.max.apply(Math, this.seatMapRowSelected));
        }
        else {
            this.formGroup.controls['rowto'].setValue(null);
        }
    }

    onApply() {
        this.validateDuplicateProperty(this.formGroup.controls['property'].value);
        if (this.validateForm()) {
            this.applyToModel();
        }
        else {
            this.formStatus.emit(false);
        }
    }

    onCancel() {
        this.clearForm();
        this.cancel.emit();
    }

    onNew() {
        this.new.emit();
    }

    onDelete() {
        this.delete.emit();
    }

    getErrorMessageForm(): string {
        if (!this.formGroup.controls["group"].valid) {
            return this.ERROR_GROUP_REQUIRED;
        } else if (!this.formGroup.controls["property"].valid) {
            return this.getPropertyErrorMessage();
        } else if (!this.formGroup.controls["rowfrom"].valid) {
            return this.ERROR_ROWFROM_REQUIRED;
        } else if (!this.formGroup.controls["rowto"].valid) {
            return this.ERROR_ROWTO_REQUIRED;
        } else if (!this.formGroup.controls["column"].valid) {
            return this.ERROR_COLUMN_REQUIRED;
        } else if (!this.formGroup.controls["status"].valid) {
            return this.ERROR_STATUS_REQUIRED;
        }
    }

    private getPropertyErrorMessage(): string {
        if (this.formGroup.controls["property"].errors?.required) {
            return this.ERROR_PROPERTY_REQUIRED;
        } else if (this.formGroup.controls["property"].errors?.duplicate) {
            return this.getPropertyErrorDuplicate();
        }
    }

    getPropertyErrorDuplicate(): string {
        return this.getSeatPropertyName(this.formGroup.controls["property"].value) + " " + this.ERROR_PROPERTY_DUPLICATE;
    }

    private validateDuplicateProperty(seatPropertyId) {
        let rowfrom = this.formGroup.get('rowfrom').value;
        let rowto = this.formGroup.get('rowto').value;
        this.checkDuplicate(seatPropertyId, rowfrom, rowto);
    }

    validateDuplicatePropertyRowFrom(e) {
        if (e.target.value == "") {
            return;
        }
        let seatPropertyId = this.formGroup.get('property').value;
        let rowto = this.formGroup.get('rowto').value;
        this.checkDuplicate(seatPropertyId, e.target.value, rowto);
    }

    validateDuplicatePropertyRowTo(e) {
        if (e.target.value == "") {
            return;
        }
        let seatPropertyId = this.formGroup.get('property').value;
        let rowfrom = this.formGroup.get('rowfrom').value;
        this.checkDuplicate(seatPropertyId, rowfrom, e.target.value);
    }

    private checkDuplicate(seatPropertyId: string, rowfrom: number, rowto: number) {
        if (seatPropertyId && rowfrom && rowto) {
            if (this.filterInCondition(seatPropertyId, rowfrom, rowto)) {
                this.formGroup.get('property').setErrors({ duplicate: true });
                return;
            }
        }
        this.deleteErrorDuplicate();
    }

    private filterInCondition(seatPropertyId: string, rowfrom: number, rowto: number): boolean {
        let filter = this.seatPropertyServiceCategoryViews.filter(
            x => x.seatPropertyId == seatPropertyId &&
                ((x.seatRowFrom <= rowfrom && x.seatRowTo >= rowfrom) &&
                    (x.seatRowFrom <= rowto && x.seatRowTo >= rowto)) &&
                x.no != this.model?.no);
        if (filter?.length) {
            return true;
        }
        return false;
    }

    private deleteErrorDuplicate() {
        if (this.formGroup.controls["property"].errors?.duplicate) {
            delete this.formGroup.controls["property"].errors['duplicate'];
            this.formGroup.controls["property"].updateValueAndValidity();
        }
    }
}
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommentClassificationReferenceService, CommentTypeReferenceService } from "src/app/core/services/system-services";
import { select2CommentClassification, select2CommentType } from "../shared/comment-configuration";
import { CommentView } from "../shared/comment.view";

@Component({
    selector: 'op-comment-detail',
    templateUrl: './comment-detail.component.html'
})
export class CommentDetailComponent implements OnInit {
    public commentTypeOption: any;
    public commentClassificationOption: any;
    public commentTypeReference$ = new BehaviorSubject<CommentTypeReferenceModel[]>(null);
    public commentClassificationReference$ = new BehaviorSubject<CommentClassificationReferenceModel[]>(null);
    public processing: boolean = false;

    @Input() commentView: CommentView;
    @Input() showAdd: boolean = true;
    @Input() isAllowReadOnlyMode: boolean = true;

    @Output() onAdd = new EventEmitter();
    @Output() onCancel = new EventEmitter();
    @Output() onSave = new EventEmitter<CommentView>();
    @Output() onDelete = new EventEmitter<CommentView>();

    @ViewChild('commentDetailForm') commentDetailForm: NgForm;

    constructor(private commentTypeReferenceService: CommentTypeReferenceService,
        private commentClassificationReferenceService: CommentClassificationReferenceService) {
        this.setOptionControl();
    }

    get isAllowEdit(): boolean {
        return this.commentView.commentLocked && this.isAllowReadOnlyMode;
    }

    ngOnInit(): void {
        this.getCommentTypeReference();
        this.getCommentClassificationReference();
    }

    private setOptionControl() {
        this.commentTypeOption = select2CommentType;
        this.commentClassificationOption = select2CommentClassification;
    }

    private getCommentTypeReference() {
        this.commentTypeReferenceService.getCommentTypeReferences().subscribe(
            (responses: CommentTypeReferenceModel[]) => {
                this.commentTypeReference$.next(responses);
            }
        );
    }

    private getCommentClassificationReference() {
        this.commentClassificationReferenceService.getCommentClassificationReferences().subscribe(
            (responses: CommentClassificationReferenceModel[]) => {
                this.commentClassificationReference$.next(responses);
            }
        );
    }

    public commentTypeChange(value) {
        this.commentView.commentTypeCode = value; 
        this.commentView.commentTypeName = this.commentTypeReference$.value.filter(it => it.commentTypeCode == value)[0]?.commentTypeName ?? null;
    }

    public commentClassificationChange(value) {
        this.commentView.commentClassificationCode = value; 
        this.commentView.commentClassificationName = this.commentClassificationReference$.value.filter(it => it.commentClassificationCode == value)[0]?.commentClassificationName ?? null;
    }

    add() {
        this.onAdd.emit();
    }

    save(form: NgForm) {
        this.processing = true;
        if (!form.valid) {
            return;
        }
        this.processing = false;
        this.onSave.emit(this.commentView);
    }

    delete(form: NgForm) {
        form.resetForm();
        this.onDelete.emit(this.commentView);
    }

    cancel() {
        this.onCancel.emit();
        this.commentView = new CommentView();
    }

    public isSaveDisable(form: NgForm): boolean {
        if (!form.touched) {
            return true;
        }

        return false;
    }

    disableDeleteDataLocked() {
        if (this.showAdd && !this.commentView.commentLocked) {
            return true;
        }
        return false;
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxChartModule, DxDataGridModule } from "devextreme-angular";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductDashboardSpecialServiceCompareLast30DComponent } from "./product-dashboard-specialservice-compare-last30d/product-dashboard-specialservice-compare-last30d.component";
import { ProductDashboardSpecialServiceLastReceivedComponent } from "./product-dashboard-specialservice-lastreceived/product-dashboard-specialservice-lastreceived.component";
import { ProductDashboardSpecialServiceMiniChartsComponent } from "./product-dashboard-specialservice-mini-charts/product-dashboard-specialservice-mini-charts.component";
import { ProductDashboardSpecialServiceRevenueLast30DComponent } from "./product-dashboard-specialservice-revenue-last30d/product-dashboard-specialservice-revenue-last30d.component";
import { ProductDashboardSpecialServiceRevenueLast7DComponent } from "./product-dashboard-specialservice-revenue-last7d/product-dashboard-specialservice-revenue-last7d.component";
import { ProductDashboardSpecialServiceSoldLast30DComponent } from "./product-dashboard-specialservice-sold-last30d/product-dashboard-specialservice-sold-last30d.component";
import { ProductDashboardSpecialServiceSoldLast7DComponent } from "./product-dashboard-specialservice-sold-last7d/product-dashboard-specialservice-sold-last7d.component";
import { ProductDashboardSpecialServiceStatisticsComponent } from "./product-dashboard-specialservice-statistics/product-dashboard-specialservice-statistics.component";
import { ProductDashboardSpecialServiceSummaryComponent } from "./product-dashboard-specialservice-summary/product-dashboard-specialservice-summary.component";
import { ProductDashboardSpecialServiceComponent } from "./product-dashboard-specialservice.component";
import { DataGridRowNumberModule } from "../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductDashboardSpecialServiceComponent,
        ProductDashboardSpecialServiceCompareLast30DComponent,
        ProductDashboardSpecialServiceLastReceivedComponent,
        ProductDashboardSpecialServiceMiniChartsComponent,
        ProductDashboardSpecialServiceRevenueLast7DComponent,
        ProductDashboardSpecialServiceRevenueLast30DComponent,
        ProductDashboardSpecialServiceSoldLast7DComponent,
        ProductDashboardSpecialServiceSoldLast30DComponent,
        ProductDashboardSpecialServiceSummaryComponent,
        ProductDashboardSpecialServiceStatisticsComponent
    ],
    exports: [
        ProductDashboardSpecialServiceComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxChartModule,
        DxDataGridModule,
        FocusingModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        UiModule
    ]
})
export class ProductDashboardSpecialServiceModule { }
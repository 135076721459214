import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class ProductCodeValidationService {

    public findDuplicateProductCode(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "productcode") {     
                    if (ctl.value.productCodeType == ctlCheck.value.productCodeType &&
                        ctl.value.operatorProductCode == ctlCheck.value.operatorProductCode &&
                        ctl.value.productCodeValue.toString().trim() == ctlCheck.value.productCodeValue.toString().trim() &&
                        ctl.value.provider == ctlCheck.value.provider &&
                        ctl.value.supplier == ctlCheck.value.supplier 
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }
    
}
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from "src/app/shared/ui/actionbar/models";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PaymentSearchComponent } from "../payment-search.component";
import { PaymentSearchService } from "../payment-search.service";

@Component({
    selector: 'op-payment-search-tabs',
    templateUrl: './payment-search-tabs.component.html'
})
export class PaymentSearchTabsComponent implements AfterViewInit, OnDestroy {

    activeId = 'SEARCH'

    newButton = new NewButtonModel()
    copyButton = new CopyButtonModel()
    saveButton = new SaveButtonModel()
    cancelButton = new CancelButtonModel()
    refreshButton = new RefreshButtonModel()

    unsubscribe$ = new Subject()
    subscriptions: Subscription[]
    @ViewChild(PaymentSearchComponent) searchComponent: PaymentSearchComponent;

    constructor(private actionbarService: ActionbarService,
        private navigateService: NavigationService,
        public alertBarService: AlertBarService,
        private paymentSearchService: PaymentSearchService,
        private elementRef: ElementRef) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    ngAfterViewInit(): void {
        this.subscriptions = []
        this.newButton.enable(false)
        this.copyButton.enable(false)
        this.saveButton.enable(false)
        this.cancelButton.enable(true)
        this.refreshButton.enable(true)
        this.actionbarService.updateState(new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        ))
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.back:
                    case ACTION_STATUS.cancel:
                        this.navigateService.navigate("main/cashbook/home", null)
                        break
                    case ACTION_STATUS.refresh:
                        this.searchComponent.search()
                        break
                }
            }
        )

        this.subscriptions.push(
            this.paymentSearchService.alertSuccess$.subscribe(message => {
                this.alertBarService.showInformation('Payment', message)
            })
        )

        this.subscriptions.push(
            this.paymentSearchService.alertError$.subscribe(message => {
                this.alertBarService.show('Payment', message)
            })
        )
    }

    hideAlertInformation() {
        this.alertBarService.hideInformation()
        this.alertBarService.hide()
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        this.hideAlertInformation()
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (clickedInside) {
            this.hideAlertInformation()
        }
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ExportButtonGroupComponent } from "src/app/shared/ui/export-button-group/export-button-group.component";
import { AddressView } from "../shared/address-view";

@Component({
    selector: 'op-insight-address-table',
    templateUrl: './address-table.component.html'
})
export class InsightAddressTableComponent implements AfterViewInit {
    public selectedItem: AddressView;
    public itemSelected: AddressView;
    public rows = 0;
    public header: string[] = [];
    public exportData: any;
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightAddresses: AddressView[] = new Array(); 
    @Output() onEdit = new EventEmitter();
    @Output() onRowSelected = new EventEmitter();

    @ViewChild('dataGridAddress', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef,) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    selectedRow(e) {
        this.selectedItem = e.data;
        this.onRowSelected.emit();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Type: dat.addressTypeName,
                Primary: dat.primaryAddress,
                AddressLine1: dat.addressLine1,
                Province: dat.province,
                CountryName: dat.countryName,
            }
        ));
    }


    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    editAddressDetail() {
        this.onEdit.emit();
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { OrganisationModel } from '../../models/reference-model/reference-organisation-model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationAirlineService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/airline/organisations"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getByStatus(statusCode): Observable<OrganisationModel[]> {
    return this.httpGet<OrganisationModel[]>(this.REFERENCE_PATH + "/status/" + statusCode, null)
      .pipe(
        map(res => res.body)
      )
  }

}
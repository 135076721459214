import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from "../oops-select2/select2.module";
import { StatusOperationalDropdownComponent } from "./status-operational-dropdown.component";

@NgModule({
    imports: [
        CommonModule, 
        Select2Module, 
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [StatusOperationalDropdownComponent],
    exports: [StatusOperationalDropdownComponent]
})
export class StatusOperationalDropdownModule {

}
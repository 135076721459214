import { Component, ChangeDetectionStrategy, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from "lodash";

import { TreeNode } from 'primeng/api';

import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

import { GeneralComponent } from './general/general.component';
import { ScheduleInformationComponent } from './schedule-information/schedule-information.component';
import { ScheduleFlightInformationComponent } from './schedule-flight-information/schedule-flight-information.component';
import { generalInfoFormOption, schedulPeriodFormOption, flightFormOption } from './shared/form-config';

import { ProductSchedulePeriodService, TransportService } from 'src/app/core/services/product-services';
import { TreeMapperService } from '../../../shared/mapper/tree-mapper.service';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';

import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel, VehicleCompositionModel, VehicleModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductSchedulePeriodModel } from 'src/app/core/models/product-model/product-schedule-period-model';
import { OriginDestinationAttributeViewModel, ProductInventorySalesBucketViewModel, ProductInventoryServiceCateViewModel, ServiceTimeViewModel, TransportAddCommandModel, TransportEditCommandModel, TransportViewModel, ProductPointViewModel } from 'src/app/core/models/product-model/transport-model';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { LanguageReferenceModel, MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';

import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, CustomButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService, VehicleTypeReferenceService } from 'src/app/core/services/system-services';
import { HashTagReferenceService, LanguageReferenceService, OrganisationGroupReferenceService, RegionReferenceService, VehicleCompositionService, VehicleService } from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from 'src/app/core/services/reference-service/reference-media-service';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { RouteComponent } from './route/route.component';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { AttributeAndRuleComponent } from '../shared/attribute-and-rule/attribute-and-rule.component';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { AttributeMapperService } from '../../../shared/mapper/attribute-mapper.service';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import { InventoryAttributeMapperService } from '../../../shared/mapper/inventory-attribute-mapper.service';
import { OriginDestinationMapperService } from '../../../shared/mapper/origin-destination-mapper.service';
import { InventoryAttributeComponent } from './inventory-attribute/inventory-attribute.component';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { HttpClient } from '@angular/common/http';
import { PointOfSalesModel, ValidityModel } from 'src/app/core/models/merchandizing-config';
import { ServiceCategoryComponent } from './servicecategory/servicecategory.component';
import { SalesBucketComponent } from './salesbucket/salesbucket.component';
import { ServiceCategoryMapperService } from './servicecategory/servicecategory-mapper.service';
import { SalesBucketMapperService } from './salesbucket/salesbucket-mapper.service';
import { MediaViewModel } from 'src/app/core/models/media-model';
import { UIService } from '../../../special-service/special-service-content/special-service-detail/shared/ui.service';
import { TranslationMediaMapperService } from 'src/app/core/components/translation-media/shared/translation-media-mapper.service';
import { TranslationTextMapperService } from 'src/app/core/components/translation-text/shared/translation-text-mapper.service';
import { TextMapperService } from 'src/app/core/components/text/shared/text-mapper.service';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { TranslationTextComponent } from 'src/app/core/components/translation-text/translation-text.component';
import { TranslationMediaComponent } from 'src/app/core/components/translation-media/translation-media.component';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { TranslationService } from 'src/app/core/services/translation-services';
import { StatusConstant } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant';
import { ScheduleQueueModel } from 'src/app/core/models/schedule-queue-model';
import { TextMediaComponent } from 'src/app/core/components/text-media/text-media.component';
import { OperatingProductService, ScheduleQueueService } from 'src/app/core/services/schedule-services';
import { OperatingProductComponent } from './operating-product/operating-product.component';
import { ProductHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-hierarchy';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { verifyResultConverter } from './verify-result/shared/verify-result.converter';
import { VerifyResultView } from './verify-result/shared/verify-result.view';
import { ActivityDomainComponent } from 'src/app/core/components/activity-domain/activity-domain.component';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { OperatingProductVerificationModel } from 'src/app/core/models/product-model/operating-product-model';
import { VerifyResultComponent } from './verify-result/verify-result.component';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { ValidationComponent } from './validation/validation.component';
import { scrollToTop } from 'src/app/shared/utils/utils.functions';
import { PanelConstant } from './validation/validation.constant';

@Component({
    selector: 'op-transport-detail',
    templateUrl: './transport-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [Select2DataMapperService, TreeMapperService, HashTagMapperService,
        MediaMapperService, NumberMapperService, RestrictionMapperService,
        AttributeMapperService, PointOfSalesMapperService, ValidityMapperService,
        InventoryAttributeMapperService, OriginDestinationMapperService, ServiceCategoryMapperService,
        SalesBucketMapperService, UIService, TranslationMediaMapperService, TranslationTextMapperService,
        TextMapperService, TranslationNameMapperService, AlertBarService, ActionbarService, verifyResultConverter]
})
export class TransportDetailComponent extends OopsComponentFormBase implements OnDestroy, AfterViewInit {

    private readonly TRANSPORT_PRODUCT_CATE_CODE = 'TRANSPORT';
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";
    private readonly DATA_USAGE_TYPE = 'DATA';
    private readonly TEMPLATE_USAGE_TYPE = 'TEMPLATE';
    private readonly GENERAL_INFO_TAB = 'general';
    private readonly DELAY_TIME = 100;
    private readonly DELAY_TIME_VILIDITY = 1000;
    private readonly DEPARTURE_LEVEL_HL = 5;
    private readonly ARRIVAL_LEVEL_HL = 7;
    private readonly generalPanel = 'General Information';
    private readonly flightPanel = 'Flight';
    private readonly numberPanel = 'Product Number';
    private readonly attributePanel = 'Attribute';
    private readonly inventoryPanel = 'Inventory';
    private readonly validityPanel = 'Validity';
    private readonly originDestinationPanel = 'Origin and Destination';
    public readonly generalTabId = 'general';
    public readonly routeTabId = 'route';
    public readonly attributeTabId = 'attribute';
    public readonly textTabId = 'text';
    public readonly mediaTabId = 'media';
    public readonly translationTabId = 'translation';
    public readonly verifyResultTabId = 'verifyResult';
    private readonly SERVCAT_CODE: string = "SERVICECATEGORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    public readonly ENTITY_NAME: string = "Product";
    private _jsonURL = 'assets/data/flight/attributes&rules_config.json';
    private readonly TRANSLATION_NAME = "Airline translation set";
    private readonly ORGTYPE_OWNER = 'OWNER';
    private readonly PANEL_NAME = 'Transport Detail';
    private readonly FINAL_ALLOW_ACTIVE_ONLY = "Final checkbox allows with status 'Active' only";
    private readonly SCHEDULE_ON_SCHEDULE_QUEUE = 'The schedule is on the schedule queue';
    private readonly changeScheduleActionStatus = 'CHANGE_SCHEDULE';
    private readonly splitScheduleActionStatus = 'SPLIT_SCHEDULE';
    private readonly changeActionStatus = 'CHANGE';
    public readonly operatingProductTabId = 'operatingProduct';
    public readonly inventoryTabId = "inventory";
    public selectedTab = this.GENERAL_INFO_TAB;
    private productHashtagValueViews: ProductHashTagView[] = new Array();
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);

    private parentId: string;
    public nodeSelected: boolean = false;

    public transportTree: TreeNode[];

    public transportDetailFormGroup: UntypedFormGroup;

    public maxSize: number = 5;
    public pageSize: number = 1;

    public hideTreeList: boolean = false;
    public showReadMode: boolean = false;
    public showTranslation: boolean = false;
    public showFlightList: boolean = false;

    public segmentName = "";
    public inventoryView: string = "";
    public leafSelected: boolean = false;
    public segmentSelected: boolean = false;
    public flightListRowDate = "";
    public noProductPoint: boolean = true;
    public rootLevelWithProductPoint: boolean = false;
    public departureLevel: boolean = false;
    public arrivalLevel: boolean = false;
    public srvCatLevel: boolean = false;
    public draftFlag: boolean = true;
    public rootWithOD: boolean = false;
    public showVerifyResultTab: boolean = false;

    public id: string;
    public newProduct: boolean = false;
    public PRODUCT_CATE_CODE: string = this.TRANSPORT_PRODUCT_CATE_CODE;

    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productCategoryReferenceTransport$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferenceTransport$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSchedulePeriod$ = new BehaviorSubject<ProductSchedulePeriodModel[]>(null);
    public vehicleTypeReference$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public vehicle$ = new BehaviorSubject<VehicleModel[]>(null);

    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);

    public verifyResults$ = new BehaviorSubject<VerifyResultView[]>([]);

    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);

    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];

    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    public copy: boolean = false;
    public rootProductId: string;
    public rootFinalFlag: boolean = null;
    public limitationFlag: boolean = false;

    public paggingDataView: PagingDataView[];
    public messageSize: number;
    public page: number;
    public showRoute: boolean = false;
    public validityConfig: ValidityModel;
    public pointOfSalesConfig: PointOfSalesModel;
    public languageTranslationSet: LanguageTranslationSetViewModel;
    public heightPanelGeneral: number;
    private productData: TransportViewModel;
    private scheduleQueueData: ScheduleQueueModel[];
    private productValiditiesProductData: ProductValidityViewModel[];

    @ViewChild(GeneralComponent) generalRefComponent: GeneralComponent;
    @ViewChild(ScheduleInformationComponent) transportDetailScheduleRefComponent: ScheduleInformationComponent;
    @ViewChild(ScheduleFlightInformationComponent) transportDetailFlightRefComponent: ScheduleFlightInformationComponent;
    @ViewChild(RouteComponent) routeComponent: RouteComponent;
    @ViewChild(AttributeAndRuleComponent) attributeAndRuleComponent: AttributeAndRuleComponent;
    @ViewChild(InventoryAttributeComponent) inventoryAttributeComponent: InventoryAttributeComponent;
    @ViewChild(ServiceCategoryComponent) serviceCategoryComponent: ServiceCategoryComponent;
    @ViewChild(SalesBucketComponent) salesBucketComponent: SalesBucketComponent;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;
    @ViewChild(TextMediaComponent) textMedia: TextMediaComponent;
    @ViewChild(OperatingProductComponent) operatingProductComponent: OperatingProductComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    @ViewChild(VerifyResultComponent) verifyResultComponent: VerifyResultComponent;
    @ViewChild(ValidationComponent) validationComponent: ValidationComponent;
    public subscription: Subscription;
    public productTypeCode: string;
    public productGroupCode: string;
    public productNumber: string;
    public providerId: string;
    public supplierId: string;
    public saveAction: boolean = false;

    public newFromParent: boolean = false;
    public productName$ = new BehaviorSubject<string>(null);
    public rootCommandData: TransportEditCommandModel;
    public hiddenTree: boolean = true;
    public currentIndex: number;
    private unsubscribe$ = new Subject();
    private loadReferenceDataFinished: boolean = false;
    public fillProductFinished: boolean = false;

    private newButton = new NewButtonModel();
    private copyButton = new CopyButtonModel();
    private saveButton = new SaveButtonModel();
    private cancelButton = new CancelButtonModel();
    private refreshButton = new RefreshButtonModel();

    public defaultActionBarHandler = new ActionBarHandlerModel(
        this.newButton,
        this.copyButton,
        this.saveButton,
        this.cancelButton,
        this.refreshButton
    )

    public limitationActionBarHandler: ActionBarHandlerModel;
    public showOperatingProduct: boolean = false;
    public serviceCates: ProductAttributeViewModel[];
    public routeName: string;
    public referenceProductId: string;
    public userSecurity: SecurityGroupSecurityModel;
    public pageChanging: boolean = false;

    public showConfirmFinal: boolean = false;
    public confirmFinalFlag: boolean = false;

    private allowSave: boolean = true;
    private groupCode: string;
    private selectedProductCategory: ProductCategory;
    private searchCriteria: TransportViewModel;
    public productNameDisplay: string;
    public dayOfWeek: string;
    public noValidationRows: boolean = true;

    get hideAll(): boolean {
        return this.showVerifyResultTab;
    }

    get showVerifyButton(): boolean {
        return this.id && this.id == this.rootProductId &&
            this.transportDetailFormGroup.controls.generalInfo.get('finalFlag').enabled &&
            this.transportDetailFormGroup.controls.generalInfo.get('draftFlag').value == false;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private select2DataMapperService: Select2DataMapperService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private organisationService: OrganisationService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private productSchedulePeriodService: ProductSchedulePeriodService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private vehicleCompositionService: VehicleCompositionService,
        private vehicleService: VehicleService,
        private hashTagReferenceService: HashTagReferenceService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private languageReferenceService: LanguageReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private regionReferenceService: RegionReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private transportService: TransportService,
        private dateConverterService: DateConverterService,
        private treeMapperService: TreeMapperService,
        private hashTagMapperService: HashTagMapperService,
        private mediaMapperService: MediaMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private focusingService: FocusingService,
        private navigateService: NavigationService,
        private spinnerService: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private actionbarService: ActionbarService,
        private http: HttpClient,
        private numberMapperService: NumberMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private attributeMapperService: AttributeMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private validityMapperService: ValidityMapperService,
        private inventoryAttributeMapperService: InventoryAttributeMapperService,
        private originDestinationMapperService: OriginDestinationMapperService,
        private serviceCategoryMapperService: ServiceCategoryMapperService,
        private salesBucketMapperService: SalesBucketMapperService,
        public uiService: UIService,
        private translationMediaMapperService: TranslationMediaMapperService,
        private translationTextMapperService: TranslationTextMapperService,
        private textMapperService: TextMapperService,
        private translationNameMapperService: TranslationNameMapperService,
        private translationService: TranslationService,
        private scheduleQueueService: ScheduleQueueService,
        private actionService: ActionService,
        private operatingProductService: OperatingProductService,
        private verifyResultConverter: verifyResultConverter,
        private activityStoreService: ActivityStoreService) {
        super(formBuilder);
        this.getParams();
        this.limitationActionBarHandler = this.createLimitationActionbarHandler();
    }

    private createLimitationActionbarHandler() {
        let changeButton = new CustomButtonModel('Change', this.changeActionStatus, 'edit', 'color-warning-500');
        let changeScheduleButton = new CustomButtonModel('Change Schedule', this.changeScheduleActionStatus, null, null);
        let splitScheduleButton = new CustomButtonModel('Split Schedule', this.splitScheduleActionStatus, null, null);
        changeButton.addDropdownBtn(changeScheduleButton);
        changeButton.addDropdownBtn(splitScheduleButton);
        changeButton.enable(this.userSecurity?.editFlag ?? false);

        this.copyButton.disable();

        return new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            changeButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        )
    }

    ngAfterViewInit() {
        this.setupSecurityActionbar();
        this.subscribeToPanelsFocus();

        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        );
        this.generalOrValidationFocus();
    }

    private setupSecurityActionbar() {
        if (this.newProduct) {
            if (this.userSecurity?.newFlag) {
                this.newButton.enable(true)
                this.saveButton.enable(true)
            }
        }
        else {
            this.newButton.enable(this.userSecurity?.newFlag ?? false);
            this.saveButton.enable(this.saveFlag);
        }

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        )

        this.updateActionbarState(this.transportDetailFormGroup.controls.generalInfo.value);
    }

    get saveFlag() {
        return ((this.newProduct == true || this.newFromParent == true) && this.userSecurity?.newFlag == true) || ((this.newProduct == false && this.newFromParent == false) && this.userSecurity?.editFlag == true);
    }

    public initForm() {
        this.formSetup();
        this.addAction();
    }

    private formSetup() {
        this.initTransportForm();
        this.getReferenceData();
        this.getProduct(this.rootProductId);
    }

    private getParams() {
        let params = this.navigateService.getParams();
        if (!params)
            params = this.navigateService.getPreviousTabParams()?.params;

        this.copy = params?.copy ?? false;
        this.rootProductId = params?.id ?? null;
        this.paggingDataView = params?.paggingDataView ?? [];
        this.currentIndex = params?.currentIndex ?? null;
        this.userSecurity = params?.userSecurity;
        this.groupCode = params?.groupCode;
        this.selectedProductCategory = params.module;
        this.searchCriteria = params?.searchCriteria;
        if (!params?.id) {
            this.activityStore();
            this.newProduct = true
        }
    }

    private initTransportForm() {
        this.transportDetailFormGroup = this.formBuilder.group({
            generalInfo: this.formBuilder.group(generalInfoFormOption),
            schedulePeriod: this.formBuilder.group(schedulPeriodFormOption),
            flight: this.formBuilder.group(flightFormOption)
        });
    }

    private initNewform(parentId = null,
        productGroupCode = null,
        productTypeGroupId = null,
        usageTypeCode = "DATA",
        draftFlag = true) {
        let generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        generalInfo.patchValue({
            parentProductId: parentId,
            productCategoryCode: this.PRODUCT_CATE_CODE,
            productGroupCode: productGroupCode,
            productTypeGroupId: productTypeGroupId,
            usageTypeCode: usageTypeCode,
            draftFlag: draftFlag,
            statusCode: "A",
            productDescription: ""
        });
        this.generalRefComponent.refreshForm();
        this.productName$ = new BehaviorSubject<string>(null);
        this.setProductHashTagValue(null);
    }

    private initialGeneral() {
        let generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        let productGroupCode = generalInfo.get("productGroupCode").value;
        let productTypeGroupId = generalInfo.get("productTypeGroupId").value;
        generalInfo.reset();
        generalInfo.get('draftFlag').enable();
        generalInfo.get('finalFlag').disable();
        generalInfo.get('productGroupCode').enable();
        generalInfo.get('productTypeGroupId').enable();
        if (!this.id) {
            this.initNewform();
        } else if (this.parentId) {
            this.initNewform(this.parentId, productGroupCode, productTypeGroupId);
        } else {
            this.parentId = this.id;
            this.initNewform(this.id, productGroupCode, productTypeGroupId);
        }
    }

    private initNewFlightform(flightStatusCode = null,
        providerId = null,
        flightNumber = null,
        supplierId = null,
        vehicleTypeCode = null,
        vehicleCompositionId = null,
        vehicleId = null) {
        let flightInfo = this.transportDetailFormGroup.controls.flight;
        flightInfo.reset();
        flightInfo.patchValue({
            flightStatusCode: flightStatusCode,
            providerId: providerId,
            flightNumber: flightNumber,
            supplierId: supplierId,
            vehicleTypeCode: vehicleTypeCode,
            vehicleCompositionId: vehicleCompositionId,
            vehicleId: vehicleId
        });
        this.enableFlightField(flightInfo);
    }

    private initNewSchedulePeriodform(productSchedulePeriodId = null,
        productScheduleVersionId = null,
        comment = null) {
        let schduleInfo = this.transportDetailFormGroup.controls.schedulePeriod;
        schduleInfo.reset();
        schduleInfo.patchValue({
            productSchedulePeriodId: productSchedulePeriodId,
            productScheduleVersionId: productScheduleVersionId,
            comment: comment
        });
    }

    private getReferenceData() {
        if (this.rootProductId) {
            this.spinnerService.show();
        }
        this.getProductCategoryReference();
        this.getProductGroupReference();
        this.getProductTypeGroup();
        this.getProvider();
        this.getSupplier();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getProductSchedulePeriod();
        this.getVehicleTypeReference();
        this.getVehicleComposition();
        this.getVehicle();
        this.getHashTagReference();
        this.getProductNumberTypeReference();
        this.getLanguageReference();
        this.getMediaTypeReference();
        this.getMediaUseReference();
        this.getMediaHashTag();
        this.getMediaTypeFileType();
        this.getConditionReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getRegionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getOrganisationRoleReferences();
        this.getCalendarValidityReference();
        this.getDateTimeDimensionReference();
        this.getAttributetAndRulesJSON();
        this.getLanguageTranslationSet();
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReference$.next(responses);
                    this.productCategoryReferenceTransport$.next(responses
                        .filter(x => x.productCategoryCode == this.TRANSPORT_PRODUCT_CATE_CODE));
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    let productGroupReferenceTransport = responses.filter(x => x.productCategoryCode == this.TRANSPORT_PRODUCT_CATE_CODE);
                    this.productGroupReferenceTransport$.next(productGroupReferenceTransport);
                }
            )
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.usageTypeReference$.next(
                            responses.filter(x => x.usageTypeCode == this.DATA_USAGE_TYPE ||
                                x.usageTypeCode == this.TEMPLATE_USAGE_TYPE));
                    }
                }
            )
    }

    private getProductSchedulePeriod() {
        this.productSchedulePeriodService.getProductSchedulePeriodAll()
            .subscribe(
                (responses: ProductSchedulePeriodModel[]) => {
                    this.productSchedulePeriod$.next(responses);
                }
            )
    }

    private getVehicleTypeReference() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (responses: VehicleTypeReferenceModel[]) => {
                    this.vehicleTypeReference$.next(responses);
                }
            )
    }

    private getVehicleComposition() {
        this.vehicleCompositionService.getAll()
            .subscribe(
                (responses: VehicleCompositionModel[]) => {
                    this.vehicleComposition$.next(responses);
                }
            )
    }

    private getVehicle() {
        this.vehicleService.getAll()
            .subscribe(
                (responses: VehicleModel[]) => {
                    this.vehicle$.next(responses);
                }
            )
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReference$.next(responses);
                    this.setProductHashTagValue(null);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }


    private getLanguageReference() {
        this.languageReferenceService.getByOrganisation()
            .subscribe(
                (responses: LanguageReferenceModel[]) => {
                    this.languageReference$.next(responses);
                }
            )
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference$.next(responses);
                }
            )
    }

    private getMediaUseReference() {
        this.mediaUseReferenceService.getAll()
            .subscribe(
                (responses: MediaUseReferenceModel[]) => {
                    this.mediaUseReference$.next(responses);
                }
            )
    }

    private getMediaHashTag() {
        this.mediaHashTagService.getAll()
            .subscribe(
                (responses: MediaHashTagModel[]) => {
                    this.mediaHashTagSelect2Data = this.mediaMapperService.mediaHashTagToSelect2Data(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }

    private getConditionReference() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    let orgType = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                    this.organisationTypeSelect2Data = orgType.filter(x => x.id != this.ORGTYPE_OWNER);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    private getCalendarValidityReference() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReference$.next(responses);
                }
            )
    }

    private getDateTimeDimensionReference() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReference$.next(responses);
                }
            )
    }

    private getLanguageTranslationSet() {
        this.translationService.searchTranslationByEntityNameAndEntityPrimary(this.ENTITY_NAME, null)
            .subscribe(
                (languageTranslationSets: LanguageTranslationSetViewModel[]) => {
                    if (languageTranslationSets?.length) {
                        this.languageTranslationSet = languageTranslationSets[0];
                        this.translationNameComponent?.fillModelToForm(null, this.languageTranslationSet);
                    }
                }
            );
    }
    private getAttributetAndRulesJSON() {
        this.http.get(this._jsonURL).subscribe(
            (data: any) => {
                this.validityConfig = data.validity;
                this.pointOfSalesConfig = data.pointOfSales;
            });
    }

    public onPageChange(id: string): void {
        if (!this.newProduct) {
            this.nodeSelected = false;
            this.pageChanging = true;
            this.rootProductId = id;
            this.enableGeneralField();
            this.enableFieldFlightFormNew();
            this.enableFieldSchduleFormNew();
            this.getProduct(id);
            this.generalPanelFocus();
        }
    }

    public parentChange(value) {
        this.nodeSelected = true;
        this.getProduct(value);
        this.parentId = value;
        this.setupSecurityActionbar();
    }

    public productTypeCodeChange(value) {
        this.productTypeCode = value;
    }

    public productGroupeCodeChange(value) {
        this.productGroupCode = value;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    async save() {
        this.saveAction = true;
        if (await this.validatePanelUpdate()) {
            this.saveData();
        } else {
            this.selectedTab = this.generalTabId;
            this.onTabChange(this.selectedTab);
        }
    }

    private saveData() {
        if (this.validateAll()) {
            this.alertBarService.hide();
            if (this.copy) {
                this.copyData();
                return;
            }
            if (this.newFromParent || !this.id) {
                this.addProduct();
            } else {
                this.updateProduct();
            }
        }
    }

    private validateAll(): boolean {
        if (!this.generalValid() || !this.flightValid()) {
            this.displayGeneralError();
            return false;
        } else if (!this.productNumberValid()) {
            this.displayProductNumberError();
            return false;
        } else if (!this.attributeValid()) {
            this.displayAttributeError();
            return false;
        } else if (!this.inventoryRuleValid()) {
            this.displayInventoryRuleError();
            return false;
        } else if (!this.originDestinationValid()) {
            this.displayOriginDestinationError();
            return false;
        } else if (!this.validityValid() && this.saveAction) {
            this.displayValidityError();
            return false;
        }
        return true;
    }

    private generalValid(): boolean {
        if (!this.generalRefComponent.validateForm()) {
            return false;
        }
        return true;
    }

    private flightValid(): boolean {
        if (this.isRoot() && !this.transportDetailFlightRefComponent.validateForm()) {
            return false;
        }
        return true;
    }

    private productNumberValid(): boolean {
        var productNumberDuplicate = this.attributeAndRuleComponent.productNumberComponent.findDuplicateAllRow();
        var productNumberOperatingType = this.attributeAndRuleComponent.productNumberComponent.findOperatingType(this.rootLevelWithProductPoint, this.newProduct);
        return (!productNumberDuplicate || !productNumberOperatingType);
    }

    private attributeValid(): boolean {
        let attributetRequire = this.attributeAndRuleComponent.attributeComponent.findRequireAttribute();
        var routeValidate = this.attributeAndRuleComponent.attributeComponent.routeValidation(this.rootLevelWithProductPoint, this.newProduct, this.draftFlag);
        var attributeDuplicate = this.attributeAndRuleComponent.attributeComponent.findDuplicateAllRow();
        return (!attributetRequire && routeValidate && !attributeDuplicate);
    }

    private inventoryRuleValid(): boolean {
        var srvCatValidate = this.attributeAndRuleComponent.inventoryRuleComponent.srvCategoryValidate(this.rootLevelWithProductPoint, this.newProduct, this.draftFlag);
        var salesBucketValidate = this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketValidation(this.rootLevelWithProductPoint, this.newProduct, this.draftFlag);
        return (srvCatValidate && salesBucketValidate);
    }

    private originDestinationValid(): boolean {
        if (!this.newProduct && !this.hideOriginDestination()) {
            return !this.attributeAndRuleComponent.originDestinationComponent.findRequireAttribute();
        }
        return true;
    }

    private hideOriginDestination(): boolean {
        return (!this.departureLevel && !this.arrivalLevel && !this.rootWithOD);
    }

    private validityValid(): boolean {
        if ((this.newProduct || this.isRoot()) && this.saveAction) {
            if (this.limitationFlag == true && this.productValiditiesProductData?.length) {
                return true;
            }
            let valid = (!this.validityDuplicate() && !this.validityRequired());
            if (valid) {
                valid = !this.validityOverlap() && !this.validityNotSpecify() && !this.validityEndMoreThanStart();
            }
            return valid;
        }
        return true;
    }

    private validityDuplicate(): boolean {
        return this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.findDuplicateAllRow();
    }

    private validityRequired(): boolean {
        let validities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
        if (validities?.length == 0) {
            return true;
        }
        return false;
    }

    private validityOverlap(): boolean {
        return this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.findDateOverlapAll();
    }

    private validityNotSpecify(): boolean {
        return this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.findDateNotSpecify();
    }

    private validityEndMoreThanStart(): boolean {
        let validities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
        if (validities?.length) {
            let conditionCodes = validities.filter(x => x.conditionCode == "<>" || x.conditionCode == "><");
            let filterEndMoreThenStart = conditionCodes.filter(x => x.startDateTime > x.endDateTime);
            if (filterEndMoreThenStart?.length) {
                return true;
            }
        }
        return false;
    }
    private displayGeneralError() {
        this.displayAlertBar();
        this.transportDetailFormGroup.controls.generalInfo.markAllAsTouched();
        this.transportDetailFormGroup.controls.flight.markAllAsTouched();
        this.transportDetailFormGroup.controls.schedulePeriod.markAllAsTouched();
        this.checkPanelInvalid();
    }

    private displayAttributeError() {
        this.attributePanelFocus();
        this.displayAlertBar();
    }

    private displayProductNumberError() {
        this.productNumberPanelFocus();
        this.displayAlertBar();
    }

    private displayInventoryRuleError() {
        this.inventoryRuleFocus();
        this.displayAlertBar();
    }

    private displayOriginDestinationError() {
        this.originDestinationFocus();
        this.displayAlertBar();
    }

    private displayValidityError() {
        this.validityFocus();
        this.displayAlertBar();
    }

    public isRoot(): boolean {
        return (!this.departureLevel && !this.arrivalLevel && !this.srvCatLevel);
    }

    private checkPanelInvalid() {
        if (this.transportDetailFormGroup.controls.generalInfo.valid &&
            !this.transportDetailFormGroup.controls.flight.valid) {
            this.flightPanelFocus();
        }
    }

    private addProduct() {
        this.spinnerService.showSaving();
        let addCommand = this.fillFormGroupToAddModel();
        if (addCommand.statusCode != StatusConstant.ACTIVE && addCommand.finalFlag == true) {
            setTimeout(() => {
                this.spinnerService.hide();
                this.alertBarService.show(this.PANEL_NAME, this.FINAL_ALLOW_ACTIVE_ONLY);
                this.changeDetectionRef.detectChanges();
            })
            return;
        }
        this.saveButton.enable(false);
        this.transportService.addProduct(addCommand)
            .subscribe(
                (response) => {
                    this.rootProductId = <string>response.body;
                    this.getProduct(response.body);
                    this.setformToEditMode();
                    this.setupSecurityActionbar();
                    this.saveButton.enable(true);
                },
                (error) => this.saveButton.enable(true)
            )
    }

    private setformToEditMode() {
        this.newProduct = false;
        this.nodeSelected = false;
    }

    private fillFormGroupToAddModel(): TransportAddCommandModel {
        this.enableFieldEditMode();
        let formValue = this.transportDetailFormGroup.controls.generalInfo.value;
        let formScheduleValue = this.transportDetailFormGroup.controls.schedulePeriod.value;
        let formFlightValue = this.transportDetailFormGroup.controls.flight.value;

        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms,
            this.provider$);

        let productRestrictionLocations = this.restrictionMapperService.attributeRouteToRestrictionLocationCommandViews(
            this.attributeAndRuleComponent.attributeComponent.routeValue,
            this.attributeAndRuleComponent.attributeComponent.routeIdList,
            this.attributeAndRuleComponent.attributeComponent.getDataRoute()
        );

        let productAttributes = this.attributeMapperService.inventoryToAttributeCommands(
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCatValue,
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCatIdList,
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCategoryChoice,
            this.SERVCAT_CODE, null, this.attributeAndRuleComponent.inventoryRuleComponent.domainAttribute$);

        productAttributes = this.attributeMapperService.inventoryToAttributeCommands(
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketValue,
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketIdList,
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketChoice,
            this.SALESBUCKET_CODE, productAttributes,
            this.attributeAndRuleComponent.inventoryRuleComponent.domainAttribute$);

        productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.attributeComponent.forms, productAttributes);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

        let productInventoryDimensions = this.inventoryAttributeMapperService.transportInventoryDimensionToModels(this.inventoryAttributeComponent.productAttributeInventoryValue, this.SERVCAT_CODE);

        let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, null, this.translationNameComponent.translationViews);

        let addCommand: TransportAddCommandModel = {
            productCategoryCode: this.PRODUCT_CATE_CODE,
            productGroupCode: formValue.productGroupCode,
            productTypeCode: formValue.productTypeGroupId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            draftFlag: formValue.draftFlag,
            finalFlag: formValue.finalFlag,
            usageTypeCode: formValue.usageTypeCode,
            statusCode: formValue.statusCode,
            productScheduleVersionId: formScheduleValue.productScheduleVersionId,
            supplierId: formFlightValue.supplierId,
            providerId: formFlightValue.providerId,
            vehicleTypeCode: formFlightValue.vehicleTypeCode,
            vehicleId: formFlightValue.vehicleId,
            vehicleCompositionId: formFlightValue.vehicleCompositionId,
            displayCode: null,
            searchUsageTypeCode: null,
            searchName: null,
            searchStatusCode: null,
            isOwnerFilter: null,
            filterUserAccountId: null,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: [],
            productRestrictionProductNumbers: [],
            productRestrictionRoutes: [],
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: productInventoryDimensions,
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: medias,
            languageTranslationSet: languageTranslationSet
        };
        this.disableFieldEditMode();
        return addCommand;
    }

    private updateProduct() {
        if (this.selectedTab == this.verifyResultTabId && this.allowSave == false) {
            return;
        }
        this.spinnerService.showSaving();
        let editCommand = this.fillFormGroupToEditModel();
        if (editCommand.statusCode != StatusConstant.ACTIVE && editCommand.finalFlag == true) {
            setTimeout(() => {
                this.spinnerService.hide();
                this.alertBarService.show(this.PANEL_NAME, this.FINAL_ALLOW_ACTIVE_ONLY);
                this.changeDetectionRef.detectChanges();
            })
            return;
        }
        this.scheduleQueueCheck(editCommand, () => {
            this.activityDomainComponent.save();
            let finalFlag = this.transportDetailFormGroup.controls.generalInfo.get('finalFlag').value;
            if (finalFlag == false || (finalFlag == true && this.rootFinalFlag == true) || this.verifyResultComponent) {
                this.editProduct(editCommand);
                return;
            }
            this.verifyCommand(editCommand, (response) => {
                if (response?.verifyResults?.every(res => res.noErrorFlag == true)) {
                    this.editProduct(editCommand);
                    return;
                }
                this.displayVerifyResultTab(response);
            });
        })
    }

    private editProduct(editCommand: TransportEditCommandModel) {
        this.saveButton.enable(false);
        this.transportService.editProduct(editCommand)
            .subscribe(
                () => {
                    this.nodeSelected = false;
                    this.getProduct(this.id);
                    this.setupSecurityActionbar();
                    this.switchToGeneralTabAndHideVerifyResult();
                    this.saveButton.enable(true);
                },
                (error) => this.saveButton.enable(true)
            );
    }

    private switchToGeneralTabAndHideVerifyResult() {
        if (this.selectedTab == this.verifyResultTabId) {
            this.selectedTab = this.generalTabId;
            this.hideVerifyResultTab(this.selectedTab);
        }
    }

    private scheduleQueueCheck(editCommand: TransportEditCommandModel, callback: () => void) {
        this.scheduleQueueService.getBy(editCommand.productId, null)
            .subscribe(
                (response: ScheduleQueueModel[]) => {
                    if (response && response.some(r => r.processEndDateTime == null)) {
                        this.spinnerService.hide();
                        this.alertBarService.show(this.PANEL_NAME, this.SCHEDULE_ON_SCHEDULE_QUEUE);
                        this.changeDetectionRef.detectChanges();
                    } else {
                        callback();
                    }
                }
            )
    }

    copyData() {
        this.spinnerService.showSaving();
        let editCommand = this.getEditCommand();
        this.transportService.copy(this.rootProductId, editCommand)
            .subscribe(
                (response) => {
                    this.clearCopyMode();
                    this.assignId(response.body);
                    this.getProduct(response.body);
                }
            )
    }

    private prepareCopy() {
        this.clearCurrentData();
        this.alertBarService.hide();
        this.copy = true;
        this.id = null;
        this.paggingDataView = [];
        this.productData = null;
        this.scheduleQueueData = null;
        this.changeDetectionRef.detectChanges();
        this.getProduct(this.rootProductId);
        this.addAction();
        this.setupSecurityActionbar();
    }

    private fillFormGroupToEditModel(): TransportEditCommandModel {
        let formValue = this.transportDetailFormGroup.controls.generalInfo.value;
        let formScheduleValue = this.transportDetailFormGroup.controls.schedulePeriod.value;
        let formFlightValue = this.transportDetailFormGroup.controls.flight.value;

        var draftFlag = this.transportDetailFormGroup.controls.generalInfo.get("draftFlag").value;
        var finalFlag = this.getFinalFlagToSave();
        var productGroupCode = this.transportDetailFormGroup.controls.generalInfo.get("productGroupCode").value;
        var productTypeGroupId = this.transportDetailFormGroup.controls.generalInfo.get("productTypeGroupId").value;
        var usageTypeCode = this.transportDetailFormGroup.controls.generalInfo.get("usageTypeCode").value;
        var statusCode = this.transportDetailFormGroup.controls.generalInfo.get("statusCode").value;

        var vehicleTypeCode = formFlightValue.vehicleTypeCode;
        var vehicleId = formFlightValue.vehicleId;
        var vehicleCompositionId = formFlightValue.vehicleCompositionId;
        if (this.departureLevel) {
            vehicleTypeCode = this.attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleTypeCode;
            vehicleId = this.attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleId;
            vehicleCompositionId = this.attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleCompositionId;
        }

        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let flightInfo = this.transportDetailFormGroup.controls.flight;
        let supplierId = flightInfo.get("supplierId").value;
        let providerId = flightInfo.get("providerId").value;

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms,
            this.provider$);

        let productRestrictionLocations = this.restrictionMapperService.attributeRouteToRestrictionLocationCommandViews(
            this.attributeAndRuleComponent.attributeComponent.routeValue,
            this.attributeAndRuleComponent.attributeComponent.routeIdList,
            this.attributeAndRuleComponent.attributeComponent.getDataRoute()
        );

        let productAttributes = this.attributeMapperService.inventoryToAttributeCommands(
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCatValue,
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCatIdList,
            this.attributeAndRuleComponent.inventoryRuleComponent.serviceCategoryChoice,
            this.SERVCAT_CODE, null, this.attributeAndRuleComponent.inventoryRuleComponent.domainAttribute$);

        productAttributes = this.attributeMapperService.inventoryToAttributeCommands(
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketValue,
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketIdList,
            this.attributeAndRuleComponent.inventoryRuleComponent.salesBucketChoice,
            this.SALESBUCKET_CODE, productAttributes,
            this.attributeAndRuleComponent.inventoryRuleComponent.domainAttribute$);

        productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.attributeComponent.forms, productAttributes);

        productAttributes = this.originDestinationMapperService.originDestinationFormToModels(
            this.attributeAndRuleComponent.originDestinationComponent.forms, productAttributes);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let productValidities = this.getProductValidityCommandModel();

        let productInventoryDimensions = this.inventoryAttributeMapperService.transportInventoryDimensionToModels(this.inventoryAttributeComponent.productAttributeInventoryValue, this.SERVCAT_CODE);

        let serviceCateData = this.serviceCategoryComponent.serviceCategories;
        let salesBucketData = this.salesBucketComponent.saleBuckets;
        let productInventories = this.serviceCategoryMapperService.serviceCategoryViewToModels(serviceCateData);
        productInventories = this.salesBucketMapperService.salesBucketViewToProductInventoryModels(productInventories, salesBucketData);

        let productDateTimes = this.salesBucketMapperService.salesBucketViewToProductDateTimeModels(salesBucketData);
        let serviceTimes = this.routeComponent.getServiceTimes();

        let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);

        medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);
        medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, medias);

        let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
            this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.id, this.translationNameComponent.translationViews);

        let editCommand: TransportEditCommandModel = {
            productId: this.id,
            productCategoryCode: this.PRODUCT_CATE_CODE,
            productGroupCode: productGroupCode,
            productTypeCode: productTypeGroupId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            draftFlag: draftFlag,
            finalFlag: finalFlag,
            usageTypeCode: usageTypeCode,
            statusCode: formValue.statusCode ?? statusCode,
            productScheduleVersionId: formScheduleValue.productScheduleVersionId,
            supplierId: supplierId,
            providerId: providerId,
            vehicleTypeCode: vehicleTypeCode,
            vehicleId: vehicleId,
            vehicleCompositionId: vehicleCompositionId,
            displayCode: null,
            searchUsageTypeCode: null,
            searchName: null,
            searchStatusCode: null,
            isOwnerFilter: null,
            filterUserAccountId: null,
            productHashTags: this.isRoot() ? productHashTags : [],
            productNumbers: productNumbers,
            productRestrictionProducts: [],
            productRestrictionProductNumbers: [],
            productRestrictionRoutes: [],
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: productInventoryDimensions,
            productInventories: productInventories,
            productRestrictionOrganisations: productRestrictionOrganisations,
            languageTranslationSet: languageTranslationSet,
            medias: medias,
            productDateTimes: productDateTimes,
            serviceTimes: serviceTimes,
            referenceProductId: this.referenceProductId,
            routes: this.routeComponent.getOriginDestinationAttributes()
        };
        return editCommand;
    }

    private getFinalFlagToSave() {
        var finalFlag = null;
        if (this.id == this.rootProductId) {
            if (this.verifyResultComponent) {
                finalFlag = this.confirmFinalFlag;
            } else {
                finalFlag = this.transportDetailFormGroup.controls.generalInfo.get("finalFlag").value;
            }
        }
        return finalFlag;
    }

    private getProductValidityCommandModel() {
        if (this.limitationFlag == true && this.productValiditiesProductData?.length) {
            return this.validityMapperService.toCommandModels(this.productValiditiesProductData);
        }
        return this.validityMapperService.productValidityFormToModels(this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
    }

    private getProduct(productId) {
        if (productId) {
            if (!this.saveAction) {
                this.spinnerService.show();
            }
            this.storeRootData();
            forkJoin({
                responses: this.transportService.getByProductId(productId),
                scheduleQueue: this.scheduleQueueService.getBy(this.rootProductId, null)
            })
                .subscribe(({
                    responses,
                    scheduleQueue
                }) => {
                    this.allowSave = true;
                    this.assignRootFinalFlag(responses);
                    this.limitationFlag = this.getLimitationFlag(responses, scheduleQueue);
                    this.productData = responses;
                    this.scheduleQueueData = scheduleQueue;
                    this.pageChanging = false;
                    if (this.loadReferenceDataFinished) {
                        setTimeout(() => {
                            this.fillProductData(responses, this.limitationFlag);
                        }, 500);
                    }
                    else {
                        this.canLoadAttributeAndRules();
                    }

                    this.showConfirmFinal = this.rootProductId != this.id;
                },
                    () => {
                        this.spinnerService.hide();
                    })
        }
    }

    private getLimitationFlag(responses: any, scheduleQueue: ScheduleQueueModel[]) {
        let finalFlag = this.getFinalFlag(responses);
        return finalFlag == true && (scheduleQueue?.some(sq => sq.processStartDateTime == null || sq.processEndDateTime != null));
    }

    private updateActionbarState(data: TransportViewModel) {
        let finalFlag = this.getFinalFlag(data);
        if (finalFlag == true) {
            this.actionbarService.updateState(this.limitationActionBarHandler);
        } else {
            this.actionbarService.updateState(this.defaultActionBarHandler);
        }
    }

    private getFinalFlag(data: TransportViewModel) {
        let finalFlag = null;
        if (!data.parentProductId) {
            finalFlag = data.finalFlag ?? false;
        } else {
            finalFlag = this.rootFinalFlag;
        }
        return finalFlag;
    }

    private fillProductData(data: TransportViewModel, limitationFlag: boolean) {
        this.newFromParent = false;
        this.saveAction = false;
        this.hiddenTree = false;
        let draftFlag = data.draftFlag ?? false;
        let finalFlag = data.finalFlag ?? false;
        this.productNameDisplay = data.productName;
        this.dayOfWeek = data.weekDay;
        this.getLevelHierarchy(data);
        this.fillModelToGeneralForm(data, limitationFlag);
        this.fillModelToScheduleForm(data, limitationFlag);
        this.fillModelToFlightForm(data, limitationFlag);
        this.fillTree(data);
        this.setProductHashTagValue(data.productHashtags);
        this.fillModelToProductNumber(data.productNumbers, limitationFlag);
        this.fillModelToProductAttributeRoute(data);
        this.fillModelToProductAttribute(data.productAttributes, draftFlag, limitationFlag);
        this.fillModelToInventoryRule(data.productAttributes, draftFlag, finalFlag);
        this.fillModelToPointOfSales(data.productRestrictionOrganisations, data.productRestrictionLocations);
        this.fillModelToProductValidity(data.productValidities, limitationFlag);
        this.fillModelToOriginDestination(data.productAttributes, data.originDestinationAttributes, limitationFlag, data.serviceTimes, data.productHierarchy, data.referenceProductId);
        this.fillModelToServiceCat(data.productInventoryServiceCategories);
        this.fillModelToSalesBucket(data.productInventorySalesBuckets);
        this.fillModelToVehicle(data);
        this.fillModelToTranslationName(data.productName, data.languageTranslationSet);
        this.fillModelToText(data.medias);
        this.fillModelToTranslationText(data.medias);
        this.fillModelToMedia(data.medias);
        this.fillModelToTranslationMedia(data.medias);
        this.createRouteName(data.productPoints);
        this.updateActionbarState(data);
        this.validatePanelUpdate();
        this.addAction();
        this.changeDetectionRef.detectChanges();
        if (!this.saveAction) {
            this.spinnerService.show();
        } else {
            this.spinnerService.saveComplete();
        }
        this.fillProductFinished = true;
        this.alertBarService.hide();
        this.generalPanelFocus();
    }
    private fillModelToGeneralForm(responses: TransportViewModel, limitationFlag: boolean) {
        this.id = null;
        this.changeDetectionRef.detectChanges();
        this.generalRefComponent.filterProductTypeGroup(responses.productGroupCode);
        var generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        var productName = this.getProductName(responses.productName);
        var usageTypeCode = this.getUsageTypeCode(responses.usageTypeCode);
        var finalFlag = this.getFinalFlag(responses);
        generalInfo.patchValue({
            productCategoryCode: this.PRODUCT_CATE_CODE,
            productGroupCode: responses.productGroupCode,
            productTypeGroupId: responses.productTypeCode,
            parentProductId: responses.parentProductId,
            productName: productName,
            productDescription: responses.productDescription,
            usageTypeCode: usageTypeCode,
            draftFlag: responses.draftFlag,
            finalFlag: finalFlag,
            statusCode: responses.statusCode,
            commitDateTime: this.dateConverterService.convertDateTime(responses.commitDateTime),
            commitByName: responses.commitFirstname + ' ' + responses.commitLastname
        });
        this.id = responses.productId;
        this.disableGeneralField(generalInfo, responses, limitationFlag);
        if (!this.nodeSelected) {
            this.parentId = responses.productId;
        }
        this.productTypeCode = responses.productTypeCode;
        this.productGroupCode = responses.productGroupCode;
        this.draftFlag = responses.draftFlag;
        this.changeDetectionRef.detectChanges();
    }

    private disableGeneralField(generalInfo: AbstractControl, responses: TransportViewModel, limitationFlag: boolean) {
        generalInfo.get('productGroupCode').disable();
        generalInfo.get('productTypeGroupId').disable();
        if (!responses.draftFlag) {
            generalInfo.get('draftFlag').disable();
        }
        else {
            generalInfo.get('draftFlag').enable();
        }
        this.disableFinalFlag(generalInfo, responses);
        this.disableUsageType(generalInfo, responses.draftFlag);
        this.generalFormLimitation(limitationFlag, generalInfo);
        this.generalFormFinalFlag(responses.finalFlag, generalInfo);
    }

    private generalFormLimitation(limitationFlag: boolean, generalInfo: AbstractControl) {
        if (limitationFlag == true) {
            generalInfo.disable();
            generalInfo.get('productName').enable();
            generalInfo.get('productDescription').enable();
        }
    }

    private generalFormFinalFlag(finalFlag: boolean, generalInfo: AbstractControl) {
        if (finalFlag == true) {
            generalInfo.disable();
            generalInfo.get('productName').enable();
            generalInfo.get('productDescription').enable();
        }
    }

    private enableGeneralField() {
        var generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        generalInfo.enable();
        generalInfo.get('productGroupCode').enable();
        generalInfo.get('productTypeGroupId').enable();
        generalInfo.get('draftFlag').enable();
        generalInfo.get('finalFlag').disable();
        generalInfo.get('commitByName').disable();
        generalInfo.get('commitDateTime').disable();
        this.changeDetectionRef.detectChanges();
    }

    private disableFinalFlag(generalInfo: AbstractControl, responses: TransportViewModel) {
        const finalFlagControl = generalInfo.get('finalFlag');
        if (this.rootProductId != this.id) {
            finalFlagControl.disable();
            return;
        }
        if (responses.usageTypeCode == this.TEMPLATE_USAGE_TYPE) {
            finalFlagControl.disable();
            return;
        }
        if (finalFlagControl.value == true && responses.productId) {
            finalFlagControl.disable();
        }
        else if (finalFlagControl.value != true && responses.draftFlag != true) {
            finalFlagControl.enable();
        }
    }

    private fillModelToScheduleForm(responses: TransportViewModel, limitationFlag: boolean) {
        let productSchedulePeriodId = this.transportDetailScheduleRefComponent.findProductSchedulePeriodId(responses.productScheduleVersionId);
        this.transportDetailScheduleRefComponent.createProductScheduleVersion(productSchedulePeriodId);
        let comment = this.transportDetailScheduleRefComponent.findComment(responses.productScheduleVersionId);
        let scheduleForm = this.transportDetailFormGroup.controls.schedulePeriod;
        scheduleForm.patchValue({
            productSchedulePeriodId: productSchedulePeriodId,
            productScheduleVersionId: responses.productScheduleVersionId,
            comment: comment
        });
        this.scheduleFormLimitation(limitationFlag, scheduleForm);
        this.transportDetailScheduleRefComponent.refreshForm();
    }

    private scheduleFormLimitation(limitationFlag: boolean, scheduleForm: AbstractControl) {
        if (limitationFlag == true) {
            scheduleForm.disable();
        }
    }

    private fillModelToFlightForm(responses: TransportViewModel, limitationFlag: boolean) {
        this.transportDetailFlightRefComponent.createVehicleCompositonAndVehicle(responses.vehicleTypeCode);
        let flightForm = this.transportDetailFormGroup.controls.flight;
        flightForm.patchValue({
            flightStatusCode: null,
            providerId: responses.providerId,
            flightNumber: this.numberMapperService.getProductNumberOperating(responses.productNumbers),
            supplierId: responses.supplierId,
            vehicleTypeCode: responses.vehicleTypeCode,
            vehicleCompositionId: responses.vehicleCompositionId,
            vehicleId: responses.vehicleId,
        });
        if (responses.draftFlag) {
            this.enableFlightField(flightForm);
        } else {
            this.disableFlightField(flightForm);
        }
        this.flightFormLimitation(limitationFlag, flightForm);
        this.transportDetailFlightRefComponent.refreshForm();
    }

    private flightFormLimitation(limitationFlag: boolean, flightForm: AbstractControl) {
        if (limitationFlag == true) {
            flightForm.disable();
        }
    }

    private fillTree(responses: TransportViewModel) {
        this.transportTree = this.treeMapperService.productTreeView(responses.productHierarchy, this.TRANSPORT_PRODUCT_CATE_CODE);
    }

    private enableFieldEditMode() {
        if (this.newFromParent) {
            this.transportDetailFormGroup.controls.generalInfo.get('productGroupCode').enable();
            this.transportDetailFormGroup.controls.generalInfo.get('productTypeGroupId').enable();
            this.transportDetailFormGroup.controls.generalInfo.get('draftFlag').enable();
        }
    }

    private disableFieldEditMode() {
        if (this.newFromParent) {
            this.transportDetailFormGroup.controls.generalInfo.get('productGroupCode').disable();
            this.transportDetailFormGroup.controls.generalInfo.get('productTypeGroupId').disable();
            this.transportDetailFormGroup.controls.generalInfo.get('draftFlag').enable();
        }
    }

    setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference$.value,
            value, (!this.rootLevelWithProductPoint && !this.draftFlag));
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTag$.next(productHashtagViews);
    }

    hashTagValueReturn(value) {
        if (this.rootLevelWithProductPoint || this.draftFlag) {
            this.productHashtagValueViews = value;
        } else {
            this.productHashtagValueViews = [];
        }
    }

    private clearLevel() {
        this.rootLevelWithProductPoint = false;
        this.departureLevel = false;
        this.arrivalLevel = false;
        this.srvCatLevel = false;
        this.noProductPoint = true;
        this.rootWithOD = false;
    }

    private getLevelHierarchy(responses: TransportViewModel) {
        this.rootLevelWithProductPoint = this.isRootWithProductPoint(responses);
        this.departureLevel = this.isDepartureLevel(responses);
        this.arrivalLevel = this.isArrivalLevel(responses);
        this.srvCatLevel = this.isSrvCatLevel(responses);
        this.noProductPoint = this.isNoProductPoint(responses);
    }

    private isRootWithProductPoint(responses: TransportViewModel): boolean {
        if (this.isProductRestrictionLocatioinPoint(responses) && responses.productPoints?.length) {
            return true;
        }
        return false;
    }

    private isDepartureLevel(responses: TransportViewModel): boolean {
        if (responses.productPoints?.length && responses.productPointId &&
            responses.hierarchyLength == this.DEPARTURE_LEVEL_HL) {
            return true;
        }
        return false;
    }

    private isArrivalLevel(responses: TransportViewModel): boolean {
        if (responses.productPoints?.length && responses.productPointId &&
            responses.hierarchyLength == this.ARRIVAL_LEVEL_HL) {
            return true;
        }
        return false;
    }

    private isSrvCatLevel(responses: TransportViewModel): boolean {
        if (!this.isProductRestrictionLocatioinPoint(responses) && responses.productPoints?.length && !responses.productPointId) {
            return true;
        }
        return false;
    }

    private isProductRestrictionLocatioinPoint(responses: TransportViewModel): boolean {
        if (responses.productRestrictionLocations) {
            var filterProductPoint = responses.productRestrictionLocations.filter(x => x.productLocationTypeCode == "POINT" && x.inherit == false);
            if (filterProductPoint?.length) {
                return true;
            }
            return false;
        }
    }

    private isNoProductPoint(responses: TransportViewModel): boolean {
        if (!responses.productPoints?.length) {
            return true;
        }
        return false;
    }

    private disableUsageType(generalInfo: AbstractControl, draftFlag: boolean) {
        if ((this.copy && (this.rootProductId == this.id)) || (!this.copy && draftFlag)) {
            generalInfo.get('usageTypeCode').enable();
        } else {
            generalInfo.get('usageTypeCode').disable();
        }
    }

    private getProductName(productName: string): string {
        if (this.copy) {
            return productName + " -Copy";
        }
        return productName;
    }

    private getUsageTypeCode(usageypeCode: string): string {
        if (this.copy && this.rootCommandData) {
            return this.rootCommandData.usageTypeCode;
        } else if (this.copy) {
            return "DATA";
        }
        return usageypeCode;
    }

    private clearCopyMode() {
        this.rootProductId = null;
        this.copy = false;
        this.transportTree = [];
        this.nodeSelected = false;
        this.rootCommandData = null;
        this.rootFinalFlag = null;
        this.limitationFlag = false;
    }

    private getEditCommand() {
        if (this.rootProductId != this.id) {
            let generalInfo = this.transportDetailFormGroup.controls.generalInfo;
            this.rootCommandData.productName = generalInfo.value.productName;
            return this.rootCommandData;
        }
        else {
            return this.fillFormGroupToEditModel();
        }
    }

    public onTabChange(activeId: string) {
        this.hideVerifyResultTab(activeId);
        switch (activeId) {
            case this.GENERAL_INFO_TAB:
                this.showRoute = false;
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.generalOrValidationFocus();
                break;
            case this.routeTabId:
                this.showRoute = true;
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.routeFocus();
                break;
            case this.attributeTabId:
                this.showRoute = false;
                this.showTranslation = false;
                this.showOperatingProduct = false;
                if (this.attributeAndRuleComponent) {
                    this.focusingService.focus(this.attributeAndRuleComponent?.productHashTagComponent.focusingDirective);
                }
                break;
            case this.textTabId:
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.focusingService.focus(this.textMedia.textComponent.focusingDirective);
                break;
            case this.mediaTabId:
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.focusingService.focus(this.textMedia.mediaComponent.focusingDirective);
                break;
            case this.translationTabId:
                this.showRoute = false;
                this.showTranslation = true;
                this.showOperatingProduct = false;
                this.focusingService.focus(this.translationNameComponent.focusingDirective);
                break;
            case this.operatingProductTabId:
                this.showOperatingProduct = true;
                this.showTranslation = false;
                this.showRoute = false;
                this.focusingService.focus(this.operatingProductComponent.listViewComponent.focusingDirective);
                this.operatingProductComponent.calendarViewComponent.updateSize();
                break;
            case this.inventoryTabId:
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.serviceCategoryFocus();
        }
    }

    private hideVerifyResultTab(activeId: string) {
        if (activeId != this.verifyResultTabId) {
            this.showVerifyResultTab = false;
        }
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.cancel:
                this.back();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
            case ACTION_STATUS.copy:
                this.prepareCopy();
                break;
            case this.changeScheduleActionStatus:
                this.goToScheduleChange();
                break;
            default:
                break;
        }
    }

    private new() {
        this.alertBarService.hide();
        this.generalPanelFocus();
        this.clearParams();
        this.clearCurrentData();
        this.addRequiredValidity();
        this.addAction();
        this.setupSecurityActionbar();
        this.activityStore();
    }

    private back() {
        let params = {
            tab: 'search',
            userSecurity: this.userSecurity,
            module: this.selectedProductCategory,
            searchCriteria: this.searchCriteria
        }
        this.navigateService.navigate("main/product/transport", null, null, params);
    }

    private flightPanelFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.transportDetailFlightRefComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private storeRootData() {
        if (this.id == this.rootProductId) {
            if (this.copy) {
                this.rootCommandData = this.fillFormGroupToEditModel();
            }
        }
    }

    public onformStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.generalRefComponent.validateForm()) {
                this.alertBarService.show(this.generalPanel, this.generalRefComponent.getErrorMessageForm());
            }
        }
    }

    public onformFlightStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.transportDetailFlightRefComponent.validateForm() &&
                this.generalRefComponent.validateForm()) {
                this.alertBarService.show(this.flightPanel, this.transportDetailFlightRefComponent.getErrorMessageForm());
            }
        }
    }

    private displayAlertBar() {
        if (this.displayAlertGeneralPanel()) {
            this.alertBarService.show(this.generalPanel, this.generalRefComponent.getErrorMessageForm());
        } else if (this.displayAlertFlight()) {
            this.alertBarService.show(this.flightPanel, this.transportDetailFlightRefComponent.getErrorMessageForm());
        } else if (!this.productNumberValid()) {
            this.alertBarService.show(this.numberPanel,
                this.attributeAndRuleComponent.productNumberComponent.getErrorMessageForm(this.rootLevelWithProductPoint, this.newProduct));
        } else if (!this.attributeValid()) {
            this.alertBarService.show(this.attributePanel,
                this.attributeAndRuleComponent.attributeComponent.getErrorMessageForm());
        } else if (!this.inventoryRuleValid()) {
            this.alertBarService.show(this.inventoryPanel,
                this.attributeAndRuleComponent.inventoryRuleComponent.getErrorMessageForm(this.rootLevelWithProductPoint, this.newProduct, this.draftFlag));
        } else if (!this.originDestinationValid()) {
            this.alertBarService.show(this.originDestinationPanel,
                this.attributeAndRuleComponent.originDestinationComponent.getErrorMessageForm());
        } else if (!this.validityValid()) {
            this.displayAlertValidity();
        }
        else {
            this.alertBarService.hide();
        }
    }

    private displayAlertGeneralPanel(): boolean {
        if (this.saveAction && !this.generalRefComponent.validateForm()) {
            return true;
        }
        return false;
    }

    private displayAlertFlight(): boolean {
        if (this.newProduct && this.saveAction && !this.transportDetailFlightRefComponent.validateForm()) {
            return true;
        }
        return false;
    }

    private displayAlertValidity() {
        if (this.validityDuplicate()) {
            this.alertBarService.show(this.validityPanel, this.attributeAndRuleComponent.validityRuleComponent.ERROR_DUPLIATE);
        } else if (this.validityOverlap()) {
            this.alertBarService.show(this.validityPanel, this.attributeAndRuleComponent.validityRuleComponent.ERROR_OVERLAP);
        } else if (this.validityNotSpecify()) {
            this.alertBarService.show(this.validityPanel, this.attributeAndRuleComponent.validityRuleComponent.ERROR_NOT_SPECIFY);
        } else if (this.validityRequired()) {
            this.alertBarService.show(this.validityPanel, this.attributeAndRuleComponent.validityRuleComponent.ERROR_REQUIRED);
        } else if (this.validityEndMoreThanStart()) {
            this.alertBarService.show(this.validityPanel, this.attributeAndRuleComponent.validityRuleComponent.ERROR_END_MORETHAN_START);
        }
    }

    public onAlertBarClick() {
        if (!this.generalRefComponent.validateForm()) {
            this.focusingService.focus(this.generalRefComponent.focusingDirective);
        } else if (!this.transportDetailFlightRefComponent.validateForm()) {
            this.focusingService.focus(this.transportDetailFlightRefComponent.focusingDirective);
        }
    }

    private clearParams() {
        this.newFromParent = false;
        this.id = null;
        this.rootProductId = null;
        this.parentId = null;
        this.paggingDataView = [];
        this.copy = false;
        this.rootFinalFlag = null;
        this.limitationFlag = false;
    }

    private clearTree() {
        this.transportTree = [];
        this.hiddenTree = true;
    }

    private clearFormStatus() {
        this.generalRefComponent.completeProcessing();
        this.transportDetailFlightRefComponent.completeProcessing();
    }

    private refreshForm() {
        this.transportDetailScheduleRefComponent.refreshForm();
        this.transportDetailFlightRefComponent.refreshForm();
    }

    private refresh() {
        this.clearCurrentDataRefresh();
        if (this.id) {
            this.newProduct = false;
        } else {
            this.addRequiredValidity();
        }
        this.alertBarService.hide();
        this.getProduct(this.id);
    }

    private clearCurrentData() {
        this.clearTree();
        this.clearFormStatus();
        this.clearLevel();
        this.initialGeneral();
        this.enableFieldGeneralFormNew();
        this.initNewFlightform();
        this.initNewSchedulePeriodform();
        this.enableFieldFlightFormNew();
        this.enableFieldSchduleFormNew();
        this.productTypeCode = null;
        this.productGroupCode = null;
        this.newProduct = true;
        this.newFromParent = false;
        this.draftFlag = true;
        this.saveAction = false;
        this.productData = null;
        this.scheduleQueueData = null;
        this.limitationFlag = false;
        this.refreshForm();
        this.setProductHashTagValue(null);
        this.attributeAndRuleComponent.productNumberComponent.clearForm();
        this.attributeAndRuleComponent.attributeComponent.clearFormNew();
        this.attributeAndRuleComponent.inventoryRuleComponent.clearForm();
        this.clearValidity();
        this.clearPointOfSales();
        this.attributeAndRuleComponent.clearForm();
        this.serviceCategoryComponent.clearForm();
        this.salesBucketComponent.clearForm();
        this.translationNameComponent.clearForm();
        this.textMedia.textComponent.clearForm();
        this.translationTextComponent.clearForm();
        this.textMedia.mediaComponent.clearForm();
        this.translationMediaComponent.clearForm();
        this.translationNameComponent?.fillModelToForm(null, this.languageTranslationSet);
        this.referenceProductId = null;
        this.productNameDisplay = null;
        this.dayOfWeek = null;
    }

    private subscribeToPanelsFocus() {
        this.generalRefComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.generalTabId;
                    }
                }
            );

        this.routeComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.routeTabId;
                    }
                }
            );

        this.textMedia.textComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.textTabId;
                    }
                }
            );

        this.textMedia.mediaComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.mediaTabId;
                    }
                }
            );

        this.translationNameComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.translationTabId;
                    }
                }
            );

        this.operatingProductComponent?.listViewComponent?.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.operatingProductTabId;
                    }
                }
            );

        this.serviceCategoryComponent?.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.inventoryTabId;
                    }
                }
            );
    }

    private disableFlightField(flightInfo: AbstractControl) {
        flightInfo.get('providerId').disable();
        flightInfo.get('flightNumber').disable();
        flightInfo.get('supplierId').disable();
    }

    private enableFlightField(flightInfo: AbstractControl) {
        flightInfo.get('providerId').enable();
        flightInfo.get('flightNumber').enable();
        flightInfo.get('supplierId').enable();
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[], limitationFlag: boolean) {
        this.attributeAndRuleComponent.productNumberComponent.draftFlag = this.draftFlag;
        this.attributeAndRuleComponent.productNumberComponent.displayEditData = true;
        this.attributeAndRuleComponent.productNumberComponent.rootLevelWithProductPoint = this.rootLevelWithProductPoint;
        this.attributeAndRuleComponent.productNumberComponent.productNumbers = productNumbers;
        this.attributeAndRuleComponent.productNumberComponent.finalFlag = this.rootFinalFlag;
        this.productNumberFormLimitation(limitationFlag);
    }

    private productNumberFormLimitation(limitationFlag: boolean) {
        if (limitationFlag == true) {
            this.attributeAndRuleComponent.productNumberComponent.disableNewItemButton();
        } else {
            this.attributeAndRuleComponent.productNumberComponent.enableNewItemButton();
        }
    }

    private fillModelToProductAttributeRoute(response: TransportViewModel) {
        let productRestrictionViews = this.restrictionMapperService.restrictionModelsAttributeRouteToViews(response.productRestrictionLocations, false);
        this.attributeAndRuleComponent.attributeComponent.addRestrictionViewToRoute(productRestrictionViews, response.draftFlag, response.finalFlag);
        this.attributeAndRuleComponent.attributeComponent.addRestrictionInheritViewToRoute(response.productPointId, response.productPointIdParent,
            response.productPoints, this.departureLevel);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[], draftFlag: boolean, limitationFlag: boolean) {
        this.attributeAndRuleComponent.attributeComponent.productAttributes = this.attributeMapperService.transportAttributesToForm(productAttributes, false);
        this.attributeAndRuleComponent.attributeComponent.productAttributesInherit = this.attributeMapperService.transportAttributesToForm(productAttributes, true);
        this.attributeAndRuleComponent.attributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.attributeComponent.limitationFlag = limitationFlag;
    }

    private fillModelToInventoryRule(productAttributes: ProductAttributeViewModel[], draftFlag: boolean, finalFlag: boolean) {
        this.attributeAndRuleComponent.inventoryRuleComponent.clearValidate();
        this.attributeAndRuleComponent.inventoryRuleComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.inventoryRuleComponent.finalFlag = finalFlag;
        this.attributeAndRuleComponent.inventoryRuleComponent.productAttributes = productAttributes.filter(x => x.inheritAttribute == false);
        this.getServiceCategoryToOperatingProductCalendar(cloneDeep(this.attributeAndRuleComponent.inventoryRuleComponent.productAttributes));
        this.attributeAndRuleComponent.inventoryRuleComponent.productAttributesInherit = productAttributes.filter(x => x.inheritAttribute == true);
        if (this.attributeAndRuleComponent.inventoryRuleComponent.productAttributesInherit?.length) {
            this.attributeAndRuleComponent.inventoryRuleComponent.hideInherit = false;
        }
        else {
            this.attributeAndRuleComponent.inventoryRuleComponent.hideInherit = true;
        }
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        let pointOfSalesInheritViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, true);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.addPointOfSalesInheritToFormGroup(pointOfSalesInheritViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[], limitationFlag: boolean) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        var productValiditiesInherit = this.validityMapperService.productValiditiesToForm(productValidities, true);
        if (limitationFlag == true) {
            this.productValiditiesProductData = productValiditiesProduct.map(validity => validity);
            let productValiditiesAll = productValiditiesInherit.concat(productValiditiesProduct);
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityToFormGroup([], false);
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityInheritToFormGroup(productValiditiesAll);
        } else {
            this.productValiditiesProductData = [];
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityToFormGroup(productValiditiesProduct, true);
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityInheritToFormGroup(productValiditiesInherit);
        }
        this.attributeAndRuleComponent.validityRuleComponent.limitationFlag = limitationFlag;
        this.attributeAndRuleComponent.validityRuleComponent.detectChanges();
    }

    private fillModelToOriginDestination(productAttributes: ProductAttributeViewModel[], originDestinationAttribute: OriginDestinationAttributeViewModel[],
        limitationFlag: boolean, serviceTimes: ServiceTimeViewModel[], hierarchy: ProductHierarchyViewModel, referenceProductId: string) {
        this.attributeAndRuleComponent.originDestinationComponent.addServiceTimes(serviceTimes, hierarchy);
        this.referenceProductId = referenceProductId;
        if (this.rootLevelWithProductPoint && (originDestinationAttribute?.length || this.isSingleLegServiceTimes(serviceTimes))) {
            this.rootWithOD = true;
            this.attributeAndRuleComponent.originDestinationComponent.addDataToInheritForm(originDestinationAttribute);
        }
        else {
            this.rootWithOD = false;
            this.attributeAndRuleComponent.originDestinationComponent.addDataToForm(this.departureLevel, this.arrivalLevel, productAttributes, this.rootFinalFlag);
        }
        this.attributeAndRuleComponent.originDestinationComponent.limitationFlag = limitationFlag;
    }

    private isSingleLegServiceTimes(serviceTimes: ServiceTimeViewModel[]): boolean {
        return serviceTimes?.length && serviceTimes.length <= 2;
    }

    private fillModelToServiceCat(productInventoryServiceCategories: ProductInventoryServiceCateViewModel[]) {
        this.serviceCategoryComponent.fillData(productInventoryServiceCategories);
    }

    private fillModelToSalesBucket(productInventorySalesBucket: ProductInventorySalesBucketViewModel[]) {
        this.salesBucketComponent.fillData(productInventorySalesBucket);
    }

    private fillModelToVehicle(productTransport: TransportViewModel) {
        if (!this.rootLevelWithProductPoint && !productTransport.originDestinationAttributes?.length) {
            this.attributeAndRuleComponent.vehicleComponent.fillData(productTransport.vehicleTypeCodeInherit,
                productTransport.vehicleCompositionIdInherit, productTransport.vehicleIdInherit,
                productTransport.vehicleTypeCode, productTransport.vehicleCompositionId, productTransport.vehicleId, this.rootFinalFlag);
        }
    }

    private fillModelToTranslationName(productName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.translationNameComponent.fillModelToForm(productName, languageTranslationSet);
    }

    private fillModelToText(medias: MediaViewModel[]) {
        var mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMedia.textComponent.fillModelToForm(mediaTextViews);
    }

    private fillModelToTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMedia.mediaComponent.fillModelToForm(mediaViews);
    }

    private fillModelToTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }
    private initProductData() {
        this.loadReferenceDataFinished = true;
        this.attributeTabFocus();
        if (!this.rootProductId) {
            this.newProduct = true;
            this.initNewform(null, this.getGroupCodeParam());
            this.initNewFlightform();
            this.initNewSchedulePeriodform();
            this.addRequiredValidity();
        } else {
            if (this.productData && !this.fillProductFinished) {
                setTimeout(() => {
                    this.limitationFlag = this.getLimitationFlag(this.productData, this.scheduleQueueData);
                    this.fillProductData(this.productData, this.limitationFlag);
                }, 500);
            }
        }
    }

    public canLoadAttributeAndRules(): boolean {
        if (this.dateTimeDimensionReference$.value &&
            this.calendarValidityReference$.value &&
            this.conditionReference$.value &&
            this.organisationSelect2Data &&
            this.countryReferenceSelect2Data &&
            this.validityConfig &&
            this.pointOfSalesConfig) {
            if (!this.loadReferenceDataFinished) {
                this.initProductData();
            }
            return true;
        }
        return false;
    }

    public onformFlightNumberChange(value: string) {
        this.attributeAndRuleComponent.productNumberComponent.updateNumber(value);
    }

    public onformFlightProviderChange(value: string) {
        this.attributeAndRuleComponent.productNumberComponent.updateProvider(value);
    }

    public onformFlightSupplierChange(value: string) {
        this.attributeAndRuleComponent.productNumberComponent.updateSupplier(value);
    }

    public onNumberChange(value: string) {
        this.transportDetailFlightRefComponent.updateNumber(value);
    }

    public onProviderChange(value: string) {
        this.transportDetailFlightRefComponent.providerChange(value);
    }

    public onSupplierChange(value: string) {
        this.transportDetailFlightRefComponent.supplierChange(value);
    }

    public onSrvCategoryAndRouteChange() {
        if (!this.saveAction) {
            return;
        }
        this.displayAlertBar();
    }

    private clearValidity() {
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent
            ?.validityInheritComponent?.clearForm();
        this.attributeAndRuleComponent.validityRuleComponent.detectChanges();
    }

    private clearPointOfSales() {
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent
            ?.pointofsalesInheritComponent?.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private attributeTabFocus() {
        this.changeDetectionRef.detectChanges();
        this.attributeAndRuleComponent.productHashTagComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.attributeTabId;
                    }
                }
            );
    }

    private productNumberPanelFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.attributeAndRuleComponent?.productNumberComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private attributePanelFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.attributeAndRuleComponent?.attributeComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private inventoryRuleFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.attributeAndRuleComponent?.inventoryRuleComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private originDestinationFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.attributeAndRuleComponent?.originDestinationComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private validityFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.attributeAndRuleComponent?.validityRuleComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private generalFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.generalRefComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private validationFocus() {
        setTimeout(() => {
            scrollToTop(150);
            this.focusingService.focus(this.validationComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private routeFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.routeComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private serviceCategoryFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.serviceCategoryComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private salesBucketFocus() {
        setTimeout(() => {
            this.focusingService.focus(this.salesBucketComponent.focusingDirective);
        }, this.DELAY_TIME);
    }

    private addRequiredValidity() {
        setTimeout(() => {
            this.attributeAndRuleComponent.validityRuleComponent.addRequiredValidity();
        }, this.DELAY_TIME_VILIDITY);
    }

    public onValidityFormStatusChange(formStatus: boolean) {
        if (!this.saveAction) {
            return;
        }
        if (formStatus) {
            this.displayAlertBar();
        } else if (!this.validityValid()) {
            this.displayAlertValidity();
        }
    }

    public displayTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryToTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    public deleteTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationChildTextViews(
                value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    public deleteMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildTexts = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationChildFileViews(
                value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildTexts);
        }
    }

    public displayMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryToTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildFiles = this.translationMediaMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildFiles);
        }
    }

    public onProductNameChange(value) {
        this.productName$.next(value);
    }

    public hiddenPanel(): boolean {
        return (this.draftFlag || this.showTranslation || this.showOperatingProduct);
    }

    public hiddenPanelInvAttribute(): boolean {
        return (!this.draftFlag || this.showTranslation || this.showOperatingProduct);
    }

    public hiddenPanelAttributeAndRules(): boolean {
        return (this.srvCatLevel || this.showTranslation || this.showOperatingProduct);
    }

    public hiddenPanelScheduleAndFlight(): boolean {
        return (!this.rootLevelWithProductPoint && !this.newProduct && !this.draftFlag) ||
            this.showTranslation ||
            this.showOperatingProduct;
    }

    public hiddenPanelValidation(): boolean {
        return (this.draftFlag || this.rootFinalFlag || this.usageTypeTemplete() || !this.isRoot());
    }

    private goToScheduleChange() {
        let params = {
            rootProductId: this.rootProductId,
            paggingDataView: this.paggingDataView,
            currentIndex: this.currentIndex,
            copy: this.copy,
            userSecurity: this.userSecurity
        }
        this.navigateService.navigate('main/product/transport/details/schedule-change', null, false, params);
    }

    private assignId(id: any) {
        this.id = id;
        this.rootProductId = id;
    }

    public hideTree() {
        this.hiddenTree = true;
    }

    public showTree() {
        this.hiddenTree = false;
    }

    private getServiceCategoryToOperatingProductCalendar(productAttributes: ProductAttributeViewModel[]) {
        if (this.id == this.rootProductId) {
            this.serviceCates = productAttributes.filter(x => x.attributeTypeCode == this.SERVCAT_CODE)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        }
    }

    private createRouteName(productPoints: ProductPointViewModel[]) {
        this.routeName = "";
        if (productPoints) {
            for (let productPoint of productPoints) {
                if (!this.routeName) {
                    this.routeName = productPoint.locationName + "(" + productPoint.locationCode + ")";
                } else {
                    this.routeName += " - " + productPoint.locationName + "(" + productPoint.locationCode + ")";
                }

            }
        }
    }

    private enableFieldGeneralFormNew() {
        var generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        generalInfo.get('usageTypeCode').enable();
        generalInfo.get('statusCode').enable();
    }

    private enableFieldFlightFormNew() {
        let flightInfo = this.transportDetailFormGroup.controls.flight;
        flightInfo.get('vehicleTypeCode').enable();
        flightInfo.get('vehicleCompositionId').enable();
        flightInfo.get('vehicleId').enable();
    }

    private enableFieldSchduleFormNew() {
        let schduleInfo = this.transportDetailFormGroup.controls.schedulePeriod;
        schduleInfo.get('productSchedulePeriodId').enable();
        schduleInfo.get('productScheduleVersionId').enable();
    }

    private addAction() {
        if (this.rootProductId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.rootProductId, this.productName$.value);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onVerifyClick() {
        let editCommand = this.fillFormGroupToEditModel();
        this.verifyCommand(editCommand, (response) => {
            this.displayVerifyResultTab(response);
        });
    }

    private displayVerifyResultTab(response: OperatingProductVerificationModel) {
        let finalFlag = this.rootFinalFlag;
        this.showConfirmFinal = finalFlag == false && response?.allowSave == true && this.displayConfirmFinal();
        this.verifyResults$.next(this.verifyResultConverter.toViews(response?.verifyResults));
        this.showVerifyResultTab = true;
        this.selectedTab = this.verifyResultTabId;
        this.spinnerService.hide();
        this.changeDetectionRef.markForCheck();
    }

    private verifyCommand(editCommand: TransportEditCommandModel, callback?: (response: OperatingProductVerificationModel) => void) {
        if (!this.saveAction) {
            this.spinnerService.show();
        }
        this.operatingProductService.getVerifyResultCommand(editCommand)
            .subscribe(
                response => {
                    if (response) {
                        this.allowSave = response.allowSave;
                    }
                    if (callback) {
                        callback(response);
                    }
                }
            )
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            this.TRANSPORT_PRODUCT_CATE_CODE,
            null);
    }

    private assignRootFinalFlag(data: TransportViewModel) {
        if (!data.parentProductId) {
            this.rootFinalFlag = data.finalFlag ?? false;
        }
    }

    private getGroupCodeParam(): string {
        if (this.groupCode) {
            this.generalRefComponent.productGroupChange(this.groupCode);
            return this.groupCode;
        }
        return null;
    }

    private clearCurrentDataRefresh() {
        this.clearFormStatus();
        this.clearLevel();
        this.initialGeneral();
        this.enableFieldGeneralFormNew();
        this.initNewFlightform();
        this.initNewSchedulePeriodform();
        this.enableFieldFlightFormNew();
        this.enableFieldSchduleFormNew();
        this.productTypeCode = null;
        this.productGroupCode = null;
        this.newProduct = true;
        this.newFromParent = false;
        this.draftFlag = true;
        this.saveAction = false;
        this.productData = null;
        this.scheduleQueueData = null;
        this.limitationFlag = false;
        this.refreshForm();
        this.setProductHashTagValue(null);
        this.attributeAndRuleComponent.productNumberComponent.clearForm();
        this.attributeAndRuleComponent.attributeComponent.clearFormRefresh();
        this.attributeAndRuleComponent.inventoryRuleComponent.clearForm();
        this.clearValidity();
        this.clearPointOfSales();
        this.attributeAndRuleComponent.clearForm();
        this.serviceCategoryComponent.clearForm();
        this.salesBucketComponent.clearForm();
        this.translationNameComponent.clearForm();
        this.textMedia.textComponent.clearForm();
        this.translationTextComponent.clearForm();
        this.textMedia.mediaComponent.clearForm();
        this.translationMediaComponent.clearForm();
        this.translationNameComponent?.fillModelToForm(null, this.languageTranslationSet);
        this.referenceProductId = null;
    }

    private generalPanelFocus() {
        this.selectedTab = this.generalTabId;
        this.onTabChange(this.selectedTab);
    }

    private validityEndEqualStart(): boolean {
        let validities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
        if (validities?.length) {
            let conditionCodes = validities.filter(x => x.conditionCode == "<>" || x.conditionCode == "><");
            let filterEndEqualStart = conditionCodes.filter(x => x.startDateTime == x.endDateTime);
            if (filterEndEqualStart?.length) {
                return true;
            }
        }
        return false;
    }

    public async validatePanelUpdate(): Promise<boolean> {
        if (!this.hiddenPanelValidation()) {
            let editCommand = this.fillFormGroupToEditModel();
            let verifyViewModels = this.operatingProductService.getVerifyResultCommand(editCommand);
            let routeViews = this.routeComponent.routeViews;
            let validityRequired = this.validityRequired() && this.isRoot();
            let validityPeriod = this.validityEndEqualStart() && this.isRoot();
            let serviceCategoryViews = this.serviceCategoryComponent.serviceCategories;
            let salesBucketViews = this.salesBucketComponent.saleBuckets;
            let returnValue = this.validationComponent.validation(routeViews,
                validityRequired,
                validityPeriod,
                serviceCategoryViews,
                salesBucketViews,
                await verifyViewModels.toPromise());
            this.noValidationRows = this.validationComponent.noValidationRows();
            this.changeDetectionRef.detectChanges();
            return returnValue;
        }
        return true;
    }

    private generalOrValidationFocus() {
        if (this.hiddenPanelValidation() || this.noValidationRows) {
            this.generalFocus();
        } else {
            this.validationFocus();
        }
    }

    public onClickValidation(panel: string) {
        switch (panel) {
            case PanelConstant.PANEL_ROUTE:
                this.selectedTab = this.routeTabId;
                this.onTabChange(this.selectedTab);
                break;
            case PanelConstant.PANEL_VALIDITY:
                this.selectedTab = this.attributeTabId;
                this.onTabChange(this.selectedTab);
                this.validityFocus();
                break;
            case PanelConstant.PANEL_SERVICE_CATEGORY:
                this.selectedTab = this.inventoryTabId;
                this.onTabChange(this.selectedTab);
                break;
            case PanelConstant.PANEL_SALES_BUCKET:
                this.selectedTab = this.inventoryTabId;
                this.showTranslation = false;
                this.showOperatingProduct = false;
                this.salesBucketFocus();
                break;
        }
    }

    private usageTypeTemplete(): boolean {
        var generalInfo = this.transportDetailFormGroup.controls.generalInfo;
        var usageTypeCode = generalInfo.get('usageTypeCode').value;
        return (usageTypeCode == this.TEMPLATE_USAGE_TYPE);
    }

    private displayConfirmFinal(): boolean {
        return this.hasServiceCategoryCapacity() && (!this.validityRequired() && this.isRoot());
    }

    private hasServiceCategoryCapacity(): boolean {
        let serviceCategoryViews = this.serviceCategoryComponent.serviceCategories.filter(x => x.capacity);
        if (serviceCategoryViews.length) {
            return true;
        }
        return false;
    }

    public getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height;
    }
}

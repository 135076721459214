import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AuthState } from './auth.reducer'

export const selectAuthState = createFeatureSelector<AuthState>('auth')

export const selectAuthStateItem = createSelector(selectAuthState, (state) => ({
  twoStepVerified: state.twoStepVerified,
  orgSelected: state.orgSelected,
}))

export const selectUsername = createSelector(selectAuthState, (state) => (state.user ? state.user.username : null))

export const selectToken = createSelector(selectAuthState, (state) => (state.user ? state.user.token : null))

export const selectOrgCount = createSelector(selectAuthState, (state) => (state.orgCount))

export const selectForgotPasswordUserName = createSelector(selectAuthState, (state) => (state.userName))

export const selectChangePasswordRequest = createSelector(selectAuthState, (state) => ({
  changePasswordOrgId: state.changePasswordOrgId,
  changePasswordRequestId: state.changePasswordRequestId,
  changePasswordLinkInvalid: state.changePasswordLinkInvalid,
  changePasswordLinkInvalidMessage : state.changePasswordLinkInvalidMessage
}))

export const selectChangePasswordExpired = createSelector(selectAuthState, (state) => ({
  userAccountId: state.userAccountId,
  changePasswordExpired: state.changePasswordExpired
}))
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { PriceModel } from "./models";

@Injectable({
    providedIn: 'root'
})
export class PriceService extends ApiServiceBase {
    private readonly PATH = '/pricing/price'

    constructor(httpClient: HttpClient, authService: AuthService){
        super(httpClient, authService);
    }

    public search(priceModel: PriceModel): Observable<PriceModel[]> {
        return this.httpPost<PriceModel[]>(this.PATH + '/search', priceModel)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public getById(priceId: string): Observable<PriceModel> {
        return this.httpGet<PriceModel>(this.PATH + '/' + priceId, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public add(priceModel: PriceModel): Observable<PriceModel> {
        return this.httpPost<PriceModel>(this.PATH, priceModel)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public update(priceModel: PriceModel): Observable<PriceModel> {
        return this.httpPut<PriceModel>(this.PATH, priceModel)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public getFilters(): Observable<PriceModel[]> {
        return this.httpGet<PriceModel[]>(this.PATH + '/filters', null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public updateStatus(priceId: string, statusCode: string): Observable<HttpResponse<any>> {
        return this.httpPost(`${this.PATH}/${priceId}/status/${statusCode}`, null);
    }

    public addTemplate(priceModel: PriceModel): Observable<PriceModel> {
        return this.httpPost<PriceModel>(this.PATH + '/template', priceModel)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public saveTemplate(priceModel: PriceModel): Observable<PriceModel> {
        return this.httpPut<PriceModel>(this.PATH + '/template', priceModel)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public getData(): Observable<PriceModel[]> {
        return this.httpGet<PriceModel[]>(this.PATH + '/data', null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
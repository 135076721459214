import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductSchedulePeriodModel, ProductScheduleVersionModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ScheduleDistributionSearchModel } from 'src/app/core/models/schedule-distribution-model';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { ScheduleDistributionService } from 'src/app/core/services/schedule-services/schedule-distribution.service';
import { ProductSchedulePeriodService, ProductScheduleVersionService } from 'src/app/core/services/system-services';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { ScheduleDistributionSearchComponent } from '../schedule-distribution-search/schedule-distribution-search.component';

@Component({
    selector: 'op-schedule-distribution-home',
    templateUrl: './schedule-distribution-home.component.html',
})
export class ScheduleDistributionHomeComponent {
    readonly SPINNER_NAME: string = "scheduleDistributionSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;
    readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';

    public loadingNotifier = new LoadingNotifier();
    public providerReferences: OrganisationModel[];
    public productScheduleVersions: ProductScheduleVersionModel[];
    public productSchedulePeriods: ProductSchedulePeriodModel[];
    public scheduleDistributionModel: OrganisationModel[];
    public condition: ScheduleDistributionSearchModel;
    public searchResults$ = new BehaviorSubject<ScheduleDistributionSearchModel[]>(null);

    @ViewChild(ScheduleDistributionSearchComponent) scheduleDistributionSearchComponent: ScheduleDistributionSearchComponent;

    constructor(private organisationService: OrganisationService,
        private productScheduleVersionService: ProductScheduleVersionService,
        private productSchedulePeriodService: ProductSchedulePeriodService,
        private scheduleDistributionService: ScheduleDistributionService
    ) {
        this.condition = {} as ScheduleDistributionSearchModel;
        this.getProviderReference();
        this.getProductScheduleVersion();
        this.getProductSchedulePeriod();
        this.getScheduleDistribution();
    }


    private getProviderReference() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.providerReferences = responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1));
                }
            )
    }

    private getProductScheduleVersion() {
        this.productScheduleVersionService.getProductScheduleVersions()
            .subscribe(
                (responses: ProductScheduleVersionModel[]) => {
                    this.productScheduleVersions = responses;
                }
            )
    }

    private getProductSchedulePeriod() {
        this.productSchedulePeriodService.getProductSchedulePeriods()
            .subscribe(
                (responses: ProductSchedulePeriodModel[]) => {
                    this.productSchedulePeriods = responses;
                }
            )
    }

    private getScheduleDistribution() {
        this.organisationService.getScheduleDistribution()
            .subscribe(
                (responses: OrganisationModel[]) => {
                    const otaOrganisation = [...new Map(responses.map(item =>
                        [item['organisationId'], item])).values()];
                    this.scheduleDistributionModel = otaOrganisation;
                }
            )
    }

    public search() {
        let condition = {} as ScheduleDistributionSearchModel;
        if(this.scheduleDistributionSearchComponent){
            condition = this.scheduleDistributionSearchComponent.getCondition();
        }

        condition.scheduleQueueActionCode = 'OTABATCH';
        if (!condition.processStartDateTime) {
            condition.processStartDateTime = null;
        }

        if (!condition.processEndDateTime) {
            condition.processEndDateTime = null;
        }
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.scheduleDistributionService.searchDistribution(condition)
            .subscribe(
                (response: ScheduleDistributionSearchModel[]) => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                    this.searchResults$.next(response);
                }
            ), () => {
                this.loadingNotifier.hide(this.SPINNER_NAME);
            }
    }
}

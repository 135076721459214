import { Injectable } from '@angular/core';

import { ProductHashTagCommandModel, ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { HashTagReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductHashTagView } from '../views/product-hashtag.view';

@Injectable()
export class HashTagMapperService {

    constructor() {
    }

    public productHashTagModels(views: ProductHashTagView[]): ProductHashTagCommandModel[] {
        let models: ProductHashTagCommandModel[] = new Array();
        if (views) {
            let i = 0;
            for (let view of views) {
                ++i;
                models.push(this.productHashTagModel(i, view));
            }
        }
        return models;
    }

    private productHashTagModel(index: number, view: ProductHashTagView): ProductHashTagCommandModel {
        let model = new ProductHashTagCommandModel();
        model.productHashTagId = view.productHashTagId;
        model.hashTagId = view.hashTagId;
        model.displaySequence = index;
        return model;
    }

    public productHashTagViews(modelHashTagReferences: HashTagReferenceModel[], modelProductHashTags: ProductHashTagViewModel[],
        inherit: boolean): ProductHashTagView[] {
        let views: ProductHashTagView[] = new Array();
        if (modelHashTagReferences) {
            for (let model of modelHashTagReferences) {
                var productHashTagId = null;
                var displaySequence = null;
                let param = false;
                if (modelProductHashTags) {
                    var productHashTag = modelProductHashTags.filter(x => x.hashTagId == model.hashTagId && x.inheritHashTag == inherit);
                    if (productHashTag.length > 0) {
                        productHashTagId = productHashTag[0].productHashTagId;
                        displaySequence = productHashTag[0].displaySequence;
                        param = productHashTag[0].param;
                    }
                }
                views.push(this.productHashTagView(model, productHashTagId, displaySequence, param));
            }
        }
        return views;
    }

    private productHashTagView(model: HashTagReferenceModel, productHashTagId: string, displaySequence: number, param: boolean): ProductHashTagView {
        let view = new ProductHashTagView();
        view.hashTagId = model.hashTagId;
        view.hashTagName = model.hashTagName;
        view.productHashTagId = productHashTagId;
        view.displaySequence = displaySequence;
        view.param = param;
        return view;
    }

    public productHashTagValueToViews(productProductHashTagValues, productProductHashTagViews: ProductHashTagView[]): ProductHashTagView[] {
        let views = new Array();
        for (let value of productProductHashTagValues) {
            let view = new ProductHashTagView();
            view.hashTagId = value;
            view.productHashTagId = null;
            if (productProductHashTagViews) {
                var filter = productProductHashTagViews.filter(x => x.productHashTagId == value)
                if (filter.length > 0) {
                    view.productHashTagId = filter[0].productHashTagId;
                }
            }
            views.push(view);
        }
        return views;
    }

    public productHashTagTransportModels(views: ProductHashTagView[]): ProductHashTagViewModel[] {
        let models: ProductHashTagViewModel[] = new Array();
        if (views) {
            let i = 0;
            for (let view of views) {
                ++i;
                models.push(this.productHashTagTransportModel(i, view));
            }
        }
        return models;
    }

    private productHashTagTransportModel(index: number, view: ProductHashTagView): ProductHashTagViewModel {
        let model = new ProductHashTagViewModel();
        model.productHashTagId = view.productHashTagId;
        model.hashTagId = view.hashTagId;
        model.displaySequence = index;
        model.inheritHashTag = false;
        model.param = true;
        return model;
    }
}

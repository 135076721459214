import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookiesService } from '../shared/utils/cookies.service';
import { AuthService } from './authentication/auth.service';
import { REMEMBER_ME_COOKIES } from 'src/app/modules/auth/shared/constants/auth.constant';
import { TabService } from './utils/tab.service';
import { LoadingSpinnerService } from '../shared/layout/loading-spinner';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
        private cookiesService: CookiesService,
        private tabService: TabService,
        private loadingSpinnerService: LoadingSpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const UNAUTHORIZED: number = 401;
        const FORBIDDEN: number = 403;
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.status == UNAUTHORIZED || error.status == FORBIDDEN) {
                    this.authService.logout();
                    this.cookiesService.deleteCookies(REMEMBER_ME_COOKIES);
                    this.tabService.closeAllTab();
                    window.location.reload();
                } else if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else if ((error?.error?.error || error?.error?.response?.warnings) && !this.isUnAuthorizedClient(error)) {
                    // ndc error
                    errorMessage = this.getCustomErrorMessage(error);
                } else {
                    // server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    if (error?.error) {
                        errorMessage += `\nReason:  ${error.error.message}`;
                    }
                }
                console.log(error);
                this.loadingSpinnerService.hide();
                return throwError(error);
            })
        )
    }

    private getCustomErrorMessage(error: HttpErrorResponse): string {
        if (error.error.error) {
           return error.error.error[0].description;
        }
        return error.error.response.warnings[0].description;
    }

    private isUnAuthorizedClient(error : HttpErrorResponse) : boolean {
        if (error?.error?.error_description == "unauthorized_client" || error?.error?.error_description == "access_denied") {
            return true;
        }
        return false;
    }
}

export const ActionConditionOption = [
    {
        code: 'NEW',
        displyName: 'New'
    },
    {
        code: 'EDIT',
        displayName: 'Edit'
    },
    {
        code: 'DELETE',
        displayName: 'Delete'
    },
    {
        code: 'PRINT',
        displayName: 'Print'
    },
    {
        code: 'EXPORT',
        displayName: 'Export'
    },
    {
        code: 'COPY',
        displayName: 'Copy'
    },
    {
        code: 'COPYTOCLIPBOARD',
        displayName: 'Copy to clipboard'
    }
]
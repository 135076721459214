export const OrderStateCode = {
    Sales: "SALES",
    Header: "HEADER",
    Individual: "INDIVIDUAL",
    Item: "ITEM",
    Partner: "PARTNER",
    Product: "PRODUCT",
    Total: "TOTAL",
    PointOfSales: "POS",
    Refund: "REFUND",
    Service: "SERVICE"
}

export const OrderAmountTypeCode = {
    Price: 'PRICE',
    Tax: 'TAX',
    Fee: 'FEE',
    Discount: 'DISCOUNT',
    Total: 'TOTAL'
}

export const ProductTypeCode = {
    Refund: 'REFUND',
    Seat: 'SEAT'
}
export const OrderSalesStatusCode = {
    Confirm: 'CONFIRMED',
    Cancel: 'CANCELLED',
    Exemption: 'EXEMPTION'
}
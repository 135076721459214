import {
  ActionReducerMap,
  MetaReducer,
  ActionReducer,
} from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { STORE_STORAGE_NAME } from './store.constant';

import * as settings from './settings';
import * as navigation from './navigation';
import * as router from './router';
import * as auth from './auth';
import * as tab from './tab';
import * as tabSession from './tab-session';
import * as navigationAction from './navigation-action';
import * as activity from './activity';

export interface AppState {
  auth: auth.AuthState;
  navigation: navigation.NavigationState;
  router: RouterReducerState<router.RouterStateUrl>;
  settings: settings.SettingsState;
  tab: tab.TabState;
  tabSession: tabSession.TabSessionState;
  navigationAction: navigationAction.NavigationActionState;
  activity: activity.ActivityState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth.authReducer,
  navigation: navigation.reducer,
  router: router.reducer,
  settings: settings.reducer,
  tab: tab.tabReducer,
  tabSession: tabSession.tabSessionReducer,
  navigationAction: navigationAction.navigationActionReducer,
  activity: activity.activityReducer
};

// this will save part of redux store into localstorage
// and restore this part on app init
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      STORE_STORAGE_NAME.setting,
      STORE_STORAGE_NAME.tabSession
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = [
  localStorageSyncReducer,
  settings.settingsMetaReducer,
].concat(
  environment.production
    ? [] // production only meta reducers
    : [] // dev only meta reducers
);

export const effects = [navigation.NavigationEffects];

export const authEffects = [auth.AuthEffects];
export const tabSessionEffects = [tabSession.TabSessionEffects];

import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChange,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';

import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { Helper } from '../../../../../../shared/helper/app.helper';

import {
    select2ProductCategory, select2ProductGroup, select2ProductTypeGroup, select2Supplier, select2StatusGeneral, select2UsageType
} from '../../../shared/configuration/select2-general-configuration';

import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductTypeGroupModel
} from '../../../../../../core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { StatusReferenceModel, UsageTypeReferenceModel } from '../../../../../../core/models/reference-model/reference-general-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-product-general',
    templateUrl: './product-general.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGeneralComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorProductCategoryRequired = 'Product Category is required.';
    readonly errorProductGroupRequired = 'Product Group is required.';
    readonly errorProductNameRequired = 'Product Name is required.';
    readonly errorProducTypeGroupRequired = 'Product Type Group is required.';
    readonly errorUsageTypeRequired = 'Usage Type is required.';

    @Input() baseFormGroup: any;

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;
    @Input() suppliers$: BehaviorSubject<OrganisationModel[]>;
    @Input() statusReferences$: BehaviorSubject<StatusReferenceModel[]>;
    @Input() usageTypeReferences$ :BehaviorSubject<UsageTypeReferenceModel[]>;
    
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Output() productTypeCode = new EventEmitter<string>();
    @Output() formStatusChangeEvent = new EventEmitter<Boolean>();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public productCategoryOption: any;
    public productGroupOption: any;
    public statusOption: any;
    public productTypeGroupOption: any;
    public supplierOption: any;
    public usageTypeOption: any;

    public productTypeGroupFilters$: BehaviorSubject<ProductTypeGroupModel[]>;
    public focusing: boolean = false;
    constructor(formBuilder: UntypedFormBuilder, 
        private changeDetectionRef: ChangeDetectorRef,
        private helper: Helper) {
        super(formBuilder);
        this.setOptionControl();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.baseFormGroup.updateValueAndValidity();
    }

    public initForm() {
        this.formGroup = this.baseFormGroup;
        this.formGroup.statusChanges.subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatusChangeEvent.emit(this.formGroup.valid);
        }
    }

    public onResized(event: any) {
        this.onPanelHeightChange.emit(event.newRect.height);
    }

    private setOptionControl() {
        this.productCategoryOption = select2ProductCategory;
        this.productGroupOption = select2ProductGroup;
        this.productTypeGroupOption = select2ProductTypeGroup;
        this.statusOption = select2StatusGeneral;
        this.supplierOption = select2Supplier;
        this.usageTypeOption = select2UsageType;
    }

    public productGroupChange(value) {
        this.dropDownValueChange(value, 'productGroupCode');
        this.filterProductTypeGroup(value);
    }

    public productTypeGroupChange(value) {
        this.dropDownValueChange(value, 'productTypeGroupId');
        this.productTypeCode.emit(value);
    }

    public dropDownValueChange(value, name) {
        this.baseFormGroup.get(name).setValue(value);
    }

    public filterProductTypeGroup(productGroupCode) {
        if (this.productGroupReferences$.value) {
            this.productTypeGroupFilters$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
            if (productGroupCode) {
                var filter = this.productTypeGroups$.value.filter(x => x.productGroupCode == productGroupCode);
                this.productTypeGroupFilters$.next(filter);
                if (filter.length != 0) {
                    this.baseFormGroup.get("productTypeGroupId").setValue(filter[0].productTypeCode);
                    this.productTypeCode.emit(filter[0].productTypeCode);
                }
                else {
                    this.productTypeCode.emit(null);
                }
            }
            else {
                this.baseFormGroup.get("productTypeGroupId").setValue(null);
                this.productTypeCode.emit(null);
            }
        }
    }

    public counterText(name, maxlength) {
        if (this.baseFormGroup.get(name).value) {
            return (maxlength - this.baseFormGroup.get(name).value.length).toString() + "/" + maxlength.toString();
        }
        else {
            return maxlength.toString() + "/" + maxlength.toString();
        }
    }

    public setFocusHasInventory() {
        let elementReference = document.querySelector('#hasInventory');
        if (elementReference instanceof HTMLElement) {
            elementReference.focus();
        }
    }

    public validateForm(): boolean {
        this.startProcessing();
        let result = this.validForm();
        this.changeDetectionRef.detectChanges()
        return result;
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productName"].valid) {
            return this.errorProductNameRequired;
        }
        if (!this.formGroup.controls["productCategoryCode"].valid) {
            return this.errorProductCategoryRequired;
        }
        if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.errorProductGroupRequired;
        }
        if (!this.formGroup.controls["productTypeGroupId"].valid) {
            return this.errorProducTypeGroupRequired;
        }
        if (!this.formGroup.controls["usageTypeCode"].valid) {
            return this.errorUsageTypeRequired;
        }
    }
}
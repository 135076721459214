import { MediaContentCommandModel } from './media-content';
import { MediaFileAssociationCommandModel } from './media-fileassociation';
import { MediaMediaHashTagCommandModel } from './media-media-hashtag';
import { MediaUseCommandModel } from './media-use';

export class MediaCommandModel {
    public mediaId: string;
    public mediaTypeCode: string;
    public mediaName: string;
    public validFromDateTime: string;
    public validToDateTime: string;
    public mediaContents: MediaContentCommandModel[];
    public mediaMediaHashTags: MediaMediaHashTagCommandModel[];
    public mediaUses: MediaUseCommandModel[];
    public mediaFileAssociation: MediaFileAssociationCommandModel;
    public mediaTranslationId: string;
}
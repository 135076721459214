import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AttributeAndRuleComponent } from './attribute-and-rule.component';
import { RulesConfigModule } from 'src/app/core/components/rules-config/rules-config.module'
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';
import { HashtagComponent } from './hashtag/hashtag.component';
import { NumberComponent } from './number/number.component';
import { AttributeComponent } from './attribute/attribute.component';
import { InventoryRuleComponent } from './inventory-rule/inventory-rule.component'
import { ValidityComponent } from './validity/validity.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { OriginDestinationComponent } from './origin-destination/origin-destination.component';
import { VehicleComponent } from './vehicle/vehicle.component'
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';

@NgModule({
    declarations: [
        AttributeAndRuleComponent,
        AttributeComponent,
        HashtagComponent,
        InventoryRuleComponent,
        NumberComponent,
        OriginDestinationComponent,
        PointOfSalesComponent,
        ValidityComponent,
        VehicleComponent
    ],
    imports: [
        ButtonsModule,
        BsDropdownModule,
        CommonModule,
        DaterangepickerModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        OopsChoiceValueModule,
        PanelsModule,
        ReactiveFormsModule,
        RulesConfigModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    exports: [
        AttributeAndRuleComponent,
        NumberComponent
    ]
})
export class TransportAttributeAndRuleModule { }

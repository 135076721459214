import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ScheduleDistributionSearchModel } from "../../models/schedule-distribution-model";

@Injectable({
    providedIn: 'root'
})
export class ScheduleDistributionService extends ApiServiceBase {
    private readonly PATH = "/schedule/scheduledistribution";
    constructor(
        httpClient: HttpClient,
        authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public searchDistribution(model: ScheduleDistributionSearchModel): Observable<ScheduleDistributionSearchModel[]> {
        return this.httpPost<ScheduleDistributionSearchModel[]>(this.PATH + "/search/", model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getDistributionChildren(scheduleQueueId: string): Observable<ScheduleDistributionSearchModel[]> {
        return this.httpGet<ScheduleDistributionSearchModel[]>(this.PATH + "/children/" + scheduleQueueId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
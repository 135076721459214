import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ProductHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-hierarchy';

@Injectable()
export class TreeMapperService {
    private readonly folderOpenIcon = 'tree-view-folder-open-icon';
    private readonly folderCloseIcon = 'tree-view-folder-close-icon';
    private readonly fileIcon = 'tree-view-file-icon';
    private readonly departureIcon = 'fal fa-plane-departure tree-view-departure-icon';
    private readonly arrivalIcon = 'fal fa-plane-arrival tree-view-arrival-icon';
    private readonly transportType = 'TRANSPORT';

    public productTreeView(modelProductHierarchy: ProductHierarchyViewModel, type: string = ""): TreeNode[] {
        let root: TreeNode[];
        let level = 0;
        if (modelProductHierarchy) {
            root = [{
                "label": modelProductHierarchy.productName,
                "data": modelProductHierarchy.productId,
                "children": this.getChild(modelProductHierarchy.childProduct, level, type),
                "type": this.getType(level, type)
            }]
            root[0] = this.addIcon(root[0])
        }
        return root;
    }

    private getChild(productChild: ProductHierarchyViewModel[], level: number, type: string): TreeNode[] {

        if (!productChild || productChild.length == 0) {
            return null;
        }
        let treeList: TreeNode[] = [];
        ++level;
        for (let child of productChild) {
            let tree: TreeNode;
            tree =
            {
                "label": child.productName,
                "data": child.productId,
                "children": this.getChild(child.childProduct, level, type),
                "type": this.getType(level, type)
            }
            treeList.push(this.addIcon(tree));
        }
        return treeList;
    }

    private addIcon(product: TreeNode): TreeNode {
        if (product.children?.length > 0) {
            if (product.type != "departure" && product.type != "arrival") {
                product.expandedIcon = this.folderOpenIcon;
                product.collapsedIcon = this.folderCloseIcon;
            } else if (product.type == "departure") {
                product.expandedIcon = this.departureIcon;
                product.collapsedIcon = this.departureIcon;
            } else if (product.type == "arrival") {
                product.expandedIcon = this.arrivalIcon;
                product.collapsedIcon = this.arrivalIcon;
            }
        } else {
            product.icon = this.fileIcon;
        }
        return product;
    }

    private getType(level: number, type: string): string {
        if (type == this.transportType) {
            if (level == 1) {
                return "departure";
            } else if (level == 2) {
                return "arrival";
            }
        }
        return "default";
    }

    public insertNewNode(treeData: TreeNode[], parentId: string): TreeNode {
        var newNode: TreeNode;
        if (treeData?.length) {
            let parentNode = this.findParentNode(treeData, parentId);
            let tree: TreeNode;
            tree =
            {
                "label": "",
                "data": "",
                "children": [],
                "type": parentNode.type
            }
            if (!parentNode?.children) {
                parentNode.children = [];
            }
            newNode = this.addIcon(tree);
            parentNode.children.push(newNode);
        }
        return newNode;
    }

    private findParentNode(treeData: TreeNode[], parentId: string): TreeNode {
        for (let tree of treeData) {
            if (tree.data == parentId) {
                return tree;
            } else {
                if (tree.children) {
                    let treeFind = this.findParentNode(tree.children, parentId);
                    if (treeFind?.data == parentId) {
                        return treeFind;
                    }
                }
            }
        }
    }
}
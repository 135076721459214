export class OriginDestinationAttributeViewModel {
    public productAttributeId: string;
    public productId: string;
    public attributeTypeCode: string;
    public attributeTypeName: string;
    public attributeChoiceId: string;
    public attributeChoiceValue: string;
    public attributeChoiceCode: string;
    public attributeChoiceName: string;
    public attributeValue: number;
    public attributeText: string;
    public attributeDateTime: Date;
    public displaySequence: number;
    public parentProductAttributeId: string;
    public operatingProductId: string;
    public conditionCode: string;
    public excludeFlag: string;
    public multipleChoiceFlag: boolean;
    public dimensionFlag: boolean;
    public requiredFlag: boolean;
    public inheritAttribute: boolean;
    public hierarchyKey: string;
    public dimensionUnitCode: string;
    public startFlag: boolean;
    public endFlag: boolean;
    public isSchedule: boolean;
}
export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2ChoiceMultiple = {
    placeholder: "<Dimension>",
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true
}
import { Injectable } from '@angular/core';
import {
    AttributeChoiceModel,
    IndividualAgeGroupReferenceModel,
    OrderTypeReferenceModel

} from '../../../../core/models/reference-model/reference-general-model';

import {
    ShoppingTransportType,
    OrderShoppingCartProductDetailView,
    OrderShoppingCartDiscount
} from '../../../order/shared';
import {
    OrderShoppingPartyView,
    OrderShoppingLocationView,
    OrderShoppingLocationCountryView,
    OrderShoppingLocationPairView,
    OrderShoppingTransportSelectionView,
    OrderShoppingSearchResultView,
    OrderShoppingSearchResultDateView,
    OrderShoppingSearchResultAvailabilityView,
    OrderShoppingSearchResultFlightView,
    OrderShoppingSearchResultFareView,
    OrderShoppingSearchResultFareDescriptionDetailView,
    OrderShoppingSearchResultProductDetailView,
    OrderShoppingPaymentProductDetailView,
    OrderShoppingSelectedAttributeView
} from '../views';
import { ShoppingProductGroupCode } from '../../../../core/constants/product-constants';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    OrderShoppingStateData,
    OrderShoppingIndividualOrder,
    OrderShoppingCollectAttribute,
    OrderShoppingSelectedRouteOffer,
    OrderShoppingCart,
    OrderShoppingCartPassengerType,
    OrderShoppingCartTax,
    OrderShoppingCartProductItem,
    OrderShoppingCartSalesBucket,
    OrderShoppingCartServiceCategory,
    OrderShoppingCartMarkup
} from '../data';
import { OrderShoppingSearchResultFareDescriptionView } from '../views';
import {
    OrderShoppingSelectionModel,
    OrderShoppingSelectionFilterModel,
    OrderShoppingSelectionDateCriteriaModel,
    OrderShoppingSelectionPassengerTypeModel,
} from '../../../../core/models/order-model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import moment from 'moment';

import { OrderRouteModel } from 'src/app/core/models/order-model/order-route-schedule';
import { DateHelperService } from 'src/app/core/utils/date-helper.service';
import { NumberHelperService } from 'src/app/core/utils/number-helper.service';

@Injectable()
export class OrderShoppingMapperService {

    private readonly returnFlightIndex: number = 1;

    constructor(private stringUtils: StringHelperService,
        private dateConversion: DateConverterService,
        private dateHelper: DateHelperService,
        private numberHelper: NumberHelperService) {

    }

    public toOrderTypeReferenceSelect2DataList(models: OrderTypeReferenceModel[]): Select2Data[] {
        let optionList = new Array<Select2Data>();
        for (let model of models) {
            optionList.push(this.toOrderTypeReferenceSelect2Data(model));
        }
        return optionList;
    }
    public toOrderTypeReferenceSelect2Data(model: OrderTypeReferenceModel): Select2Data {
        let option = new Select2Data();
        option.id = model.orderTypeCode;
        option.text = model.orderTypeName;
        return option;
    }
    public toAgeGroupReferenceViews(models: IndividualAgeGroupReferenceModel[]): OrderShoppingPartyView[] {
        if (!models) {
            return null;
        }
        let views = new Array<OrderShoppingPartyView>();
        for (let model of models) {
            views.push(this.toAgeGroupReferenceView(model));
        }
        return views;
    }
    public toAgeGroupReferenceView(model: IndividualAgeGroupReferenceModel): OrderShoppingPartyView {
        return {
            individualAgeGroupCode: model.individualAgeGroupCode,
            individualAgeGroupName: model.individualAgeGroupName
        };
    }
    public toOrderAttributeChoiceViews(models: AttributeChoiceModel[]): Select2Data[] {
        if (!models) {
            return null;
        }
        let views = new Array<Select2Data>();
        views.push(new Select2Data("", "", false))
        for (let model of models) {
            views.push(this.toOrderAttributeChoiceView(model));
        }
        return views;
    }

    public toOrderAttributeChoiceView(model: AttributeChoiceModel): Select2Data {
        return new Select2Data(model.attributeChoiceId, model.attributeChoiceName, false);
    }

    public toShoppingLocationViews(data: any): OrderShoppingLocationView[] {

        let models = data?.data?.locations;
        if (!models) {
            return null;
        }
        let locations = new Array<OrderShoppingLocationView>();
        for (let model in models) {
            if (models.hasOwnProperty(model)) {
                locations.push({
                    airportCode: models[model].code,
                    airportName: models[model].name,
                    country: this.toShoppingLocationCountryView(models[model].country)
                });
            }
        }
        return locations;
    }
    private toShoppingLocationCountryView(data: any): OrderShoppingLocationCountryView {
        return {
            countryCode: data.code,
            countryName: data.name
        };
    }
    public toShoppingLocationPairViews(data: any): OrderShoppingLocationPairView[] {
        let models = data?.data?.routes;
        if (!models) {
            return null;
        }
        let locations = new Array<OrderShoppingLocationPairView>();
        for (let model in models) {
            if (models.hasOwnProperty(model)) {
                locations.push({
                    airportCode: model,
                    pairAirports: models[model]
                });
            }
        }
        return locations;
    }
    public toOrderShoppingSelectionModel(orderShoppingState: OrderShoppingStateData,
        shoppingSelections: OrderShoppingTransportSelectionView[],
        roundTrip: boolean): OrderShoppingSelectionModel {

        let model: OrderShoppingSelectionModel = {
            context: {
                orgUnit: orderShoppingState.organisationCode,
                lang: "en",
                curr: "EUR"
            },
            filter: this.toOrderShoppingSelectionFilterModel(orderShoppingState, shoppingSelections, roundTrip)
        };
        return model;
    }

    private toOrderShoppingSelectionFilterModel(orderShoppingState: OrderShoppingStateData,
        shoppingSelections: OrderShoppingTransportSelectionView[],
        roundTrip: boolean): OrderShoppingSelectionFilterModel {

        const shoppingCollect = orderShoppingState?.shoppingCollectOrder;
        

        return {
            transportType: roundTrip ? ShoppingTransportType.RoundTrip : ShoppingTransportType.OneWay,
            calendarDateCriteria: this.toOrderShoppingSelectionDateCriteria(),
            passengerNumberByType: this.toOrderShoppingSelectionPassengerType(orderShoppingState),
            departureLocationCode: shoppingSelections[0].departureAirportCode,
            departureDate: this.dateConversion.convertToUtcDate(shoppingSelections[0].departureDate),
            returnDate: roundTrip ? this.dateConversion.convertToUtcDate(shoppingSelections[1].departureDate) : null,
            arrivalLocationCode: shoppingSelections[0].arrivalAirportCode,
            promoCode: shoppingCollect?.promoCode,
            orderTypeCode: shoppingCollect?.orderType,
            serviceCategoryCode: shoppingSelections[0].serviceCategoryCode
        }
    }

    public isRoundtrip(shoppingSelections: OrderShoppingTransportSelectionView[]): boolean {

        const numberOfOnewayTrip: number = 1;
        const numberOfRoundTrip: number = 2;

        const countNumberOfSelection = shoppingSelections.filter(x => x.departureDate?.length > 0);
        if (countNumberOfSelection?.length == numberOfOnewayTrip || countNumberOfSelection?.length > numberOfRoundTrip) return false;
        if (shoppingSelections[0].arrivalAirportCode == shoppingSelections[1].departureAirportCode) return true;
        return false;

    }
    private toOrderShoppingSelectionDateCriteria(): OrderShoppingSelectionDateCriteriaModel {
        return {
            daysBefore: 3,
            daysAfter: 3
        };
    }
    private toOrderShoppingSelectionPassengerType(orderShoppingState: OrderShoppingStateData): OrderShoppingSelectionPassengerTypeModel {

        return {
            ADT: orderShoppingState?.shoppingCollectOrder?.adult,
            CHD: this.numberHelper.ZeroToUndefined(orderShoppingState?.shoppingCollectOrder?.child),
            INF: this.numberHelper.ZeroToUndefined(orderShoppingState?.shoppingCollectOrder?.infant),
        };
    }
    private getTransportType(shoppingSelections: OrderShoppingTransportSelectionView[]): string {
        if (this.oneWayFlight(shoppingSelections)) {
            return ShoppingTransportType.OneWay;
        }
        return ShoppingTransportType.RoundTrip;
    }
    private getReturnDate(shoppingSelections: OrderShoppingTransportSelectionView[]) {

        if (this.oneWayFlight(shoppingSelections)) {
            return null;
        }
        return this.dateConversion.convertToUtcDate(shoppingSelections[this.returnFlightIndex].departureDate);
    }
    public oneWayFlight(shoppingSelections: OrderShoppingTransportSelectionView[]): boolean {

        if (!shoppingSelections[this.returnFlightIndex].departureAirportCode
            && !shoppingSelections[this.returnFlightIndex].departureDate) {
            return true;
        }
        return false;
    }
    public toSearchResultView(offer: any,
        shoppingSelections: OrderShoppingTransportSelectionView[]
    ): OrderShoppingSearchResultView {

        return {
            availabilities: this.createAvailabilitySegment(offer, shoppingSelections)
        }
    }

    private createAvailabilitySegment(offer: any,
        shoppingSelections: OrderShoppingTransportSelectionView[]): OrderShoppingSearchResultAvailabilityView[] {
        let availabilities = new Array<OrderShoppingSearchResultAvailabilityView>();
        for (let i = 0; i < shoppingSelections.length; i++) {
            let shopping = shoppingSelections[i];
            if (!shopping.departureDate) {
                continue;
            }
            if (!shopping.departureAirportCode) {

                let shoppingSelection = shoppingSelections[i - 1];
                availabilities.push(this.createAvailabilityView(offer,
                    shopping.departureDate,
                    shoppingSelection.arrivalAirportCode,
                    shoppingSelection.arrivalAirportName,
                    shoppingSelection.departureAirportCode,
                    shoppingSelection.departureAirportName));
                continue;
            }
            availabilities.push(this.createAvailabilityView(offer,
                shopping.departureDate,
                shopping.departureAirportCode,
                shopping.departureAirportName,
                shopping.arrivalAirportCode,
                shopping.arrivalAirportName));
        }
        return availabilities;
    }
    private createAvailabilityView(offer: any,
        departureDate: string,
        departureAirportCode: string,
        departureAirportName: string,
        arrivalAirportCode: string,
        arrivalAirportName: string): OrderShoppingSearchResultAvailabilityView {

        let dateRanges = this.toSearchResultDate(offer,
            departureDate,
            departureAirportCode,
            arrivalAirportCode);

        const selectedOfferIds: string[] = this.getActiveTabId(dateRanges);
        return {
            departureAirportCode: departureAirportCode,
            departureAirportName: departureAirportName,
            arrivalAirportCode: arrivalAirportCode,
            arrivalAirportName: arrivalAirportName,
            departureDate: departureDate,
            activeTabId: this.combineSelectedJourneyId(selectedOfferIds),
            flightDateRanges: dateRanges,
            selectedOfferIds: selectedOfferIds
        }
    }
    private combineSelectedJourneyId(journeyIds: string[]): string {
        return journeyIds.join('|');
    }
    private getActiveTabId(dateRange: OrderShoppingSearchResultDateView[]): string[] {
        if (!dateRange || dateRange.length === 0) {
            return null;
        }
        for (let i = 3; i < dateRange.length; i++) {
            const journeyId = dateRange[i].journeyIds;
            if (!journeyId || journeyId.length === 0) {
                continue;
            }
            return journeyId;
        }
        for (let i = 3; i > -1; i--) {
            const journeyId = dateRange[i].journeyIds;
            if (!journeyId || journeyId.length === 0) {
                continue;
            }
            return journeyId;
        }
    }
    private toSearchResultDate(offer: any,
        departureDate: string,
        departureLocationCode: string,
        arrivalLocationCode: string): OrderShoppingSearchResultDateView[] {
        const numberOfDayAfter: number = -7;

        let views = new Array<OrderShoppingSearchResultDateView>();

        for (let i = numberOfDayAfter; i < 0; i++) {

            const dayPlusMinus: number = (i + 4);
            const dateAddResult = moment(departureDate).add(dayPlusMinus, 'days').toDate();
            const journeys: any[] = this.findJourneys(dateAddResult, offer, departureLocationCode, arrivalLocationCode);
            if (!journeys) {
                views.push({
                    flightDate: this.dateConversion.convertDayWeekMonthDate(dateAddResult),
                    journeyIds: null,
                    lowestFareAmount: null
                });
                continue;
            }

            const journeyIds = journeys.map(x => x.id);
            views.push({
                flightDate: this.dateConversion.convertDayWeekMonthDate(dateAddResult),
                journeyIds,
                lowestFareAmount: this.getLowestFair(offer.data.offers, journeyIds, true)
            });
        }
        return views
    }
    private findJourneys(selectedDate: Date,
        offer: any,
        departureLocationCode: string,
        arrivalLocationCode: string): any[] {

        const originDestinationJourneyMap = this.getJourneyFromOriginDestination(offer,
            departureLocationCode,
            arrivalLocationCode);
        let journeys = new Array<any>();
        for (let journey of originDestinationJourneyMap) {
            const segmentMaps = this.getSegmentMapFromJourney(offer, journey);
            for (let segment of segmentMaps) {
                if (this.findDateFromSegmentMap(offer, segment.marketingSegmentId, selectedDate)) {
                    journeys.push(journey)
                }
            }
        }
        return journeys;
    }
    private findDateFromSegmentMap(offer: any,
        marketingSegmentMapId: string,
        selectedDate: Date): boolean {

        const marketingSegmentMap = offer
            .data
            .data
            .marketingSegmentMap[marketingSegmentMapId];

        const scheduleTime = new Date(marketingSegmentMap.departurePoint.scheduledTime);
        const departureDate = new Date(this.dateConversion.convertUtcOffsetDateTime24(scheduleTime, marketingSegmentMap.departurePoint.utcOffset));
        return departureDate.toDateString() === selectedDate.toDateString();
    }
    private getJourneyFromOriginDestination(offer: any,
        departureLocationCode: string,
        arrivalLocationCode: string) {

        const originDestinationMap = offer.data.data.originDestinationMap;
        const journeyMap = offer.data.data.journeyMap;

        for (let originDestination in originDestinationMap) {
            if (originDestinationMap.hasOwnProperty(originDestination)) {
                const data = originDestinationMap[originDestination];
                if (data.departureLocationCode === departureLocationCode
                    && data.arrivalLocationCode === arrivalLocationCode) {

                    return data.journeyIdList.map(x => {
                        return journeyMap[x];
                    });
                }
            }
        }
        return null;
    }

    private getSegmentMapFromJourney(offer: any, journey: any): any[] {
        const segmentMap = offer.data.data.segmentMap;
        let segmentMaps = new Array<any>();
        for (let segmentId of journey.segmentIdList) {
            segmentMaps.push(segmentMap[segmentId]);
        }
        return segmentMaps;
    }

    public toFlightOfferViews(journeyIds: string[],
        offer: any): OrderShoppingSearchResultFlightView[] {

        let flightOffers = new Array<OrderShoppingSearchResultFlightView>();
        for (let id of journeyIds) {
            let marketingIds = this.getMarketingIdsMapFromOffer(offer, id);
            for (let i = 0; i < marketingIds.length; i++) {

                const marketingId = marketingIds[i];
                const marketingMap = offer.data.data.marketingSegmentMap[marketingId];
                const operatingMap = offer.data.data.operatingSegmentMap[marketingMap.operatingSegmentId];

                const [operatingLegId] = operatingMap?.operatingLegIdList;
                const operatingLegMap = offer.data.data.operatingLegMap[operatingLegId];

                const departurePoint = marketingMap.departurePoint;
                const arrivalPoint = marketingMap.arrivalPoint;
                const offers = offer.data.offers;
                const priceClassMap = offer.data.data.priceClassMap;
                const departureUtcOffset = this.getOfferUtcOffsetTime(operatingLegMap, departurePoint.locationCode);
                const arrivalUtcOffset = this.getOfferUtcOffsetTime(operatingLegMap, arrivalPoint.locationCode);
                flightOffers.push({
                    departureDateTime: this.dateConversion.convertUtcOffsetDateTime24(departurePoint.scheduledTime, departureUtcOffset),
                    departureTime: this.dateConversion.convertUtcOffsetSystemShortTime(departurePoint.scheduledTime
                        , departureUtcOffset),
                    arrivalTime: this.dateConversion.convertUtcOffsetSystemShortTime(arrivalPoint.scheduledTime
                        , arrivalUtcOffset),
                    provider: this.getProvider(marketingMap, operatingMap),
                    duration: this.getHoursMinuteDuration(operatingMap.duration),
                    stop: "Non Stops",
                    vehicle: "A340-400",
                    lowestOffer: this.getLowestFair(offers, null),
                    operatingSegmentId: marketingMap.operatingSegmentId,
                    operatingCode: operatingMap.carrier?.code,
                    operatingName: operatingMap.carrier?.name,
                    marketingSegmentId: marketingMap.id,
                    offerId: id,
                    showDetail: false,
                    fareDetails: this.getFareDetail(id, offers, priceClassMap),
                    fareDescription: null,
                    dayChange: this.getDayChange(departurePoint, arrivalPoint, departureUtcOffset, arrivalUtcOffset)
                });
            }
        }
        flightOffers.sort((a, b) => (a.departureDateTime < b.departureDateTime ? -1 : 1));
        return flightOffers;
    }
    private getProvider(marketingMap: any, operatingMap: any) {
        if (!marketingMap?.carrier || !marketingMap?.productNumber) {
            return `${operatingMap?.carrier?.code} ${operatingMap.productNumber}`;
        }
        return `${marketingMap?.carrier?.code} ${marketingMap.productNumber}`;
    }
    private getDayChange(departurePoint: any, arrivalPoint: any, departureUtcOffset: number, arrivalUtcOffset: number): string {

        const departureDate = this.dateConversion.convertOffsetDate(departurePoint.scheduledTime,
            departureUtcOffset);
        const arrivalDate = this.dateConversion.convertOffsetDate(arrivalPoint.scheduledTime,
            arrivalUtcOffset);
        const dayDifferent = this.dateHelper.DayDifferent(new Date(departureDate),
            new Date(arrivalDate));
        if (dayDifferent === 0) {
            return "";
        }
        if (dayDifferent > 0) {
            return `+ ${dayDifferent} day`;
        }
        return `${dayDifferent} day`;
    }
    public getOfferUtcOffsetTime(operatingLegMap: any, airportCode: string): number {
        if (operatingLegMap.departurePoint.locationCode == airportCode) {
            return operatingLegMap.departurePoint.utcOffset;
        }
        if (operatingLegMap.arrivalPoint.locationCode == airportCode) {
            return operatingLegMap.arrivalPoint.utcOffset;
        }
        return 0;
    }
    private getHoursMinuteDuration(duration: number): string {
        let minutes = Math.round(duration / 1000 / 60);
        const hours = Math.trunc(minutes / 60);
        minutes = minutes - hours * 60;

        return `${hours} hrs ${minutes} mins`;
    }
    private getLowestFair(offers: any, journeyIds: string[], currencySymbol: boolean = false): string {

        if (!journeyIds?.length) {
            return null;
        }
        let amounts: number[] = [];
        let currency: string;
        for (let journeyId of journeyIds) {
            if (!journeyId) {
                continue;
            }
            let offerResults = this.getOfferFromJourney(offers, journeyId);
            for (let offer of offerResults) {
                if (!offer) {
                    continue;
                }
                if (currencySymbol) {
                    currency = this.stringUtils.getCurrencySymbol('en-Us', offer.totalPrice.totalAmount.currencyCode)
                } else {
                    currency = offer.totalPrice.totalAmount.currencyCode;
                }
                amounts.push(offer.totalPrice.totalAmount.value);
            }
        }
        if (!amounts?.length) {
            return null;
        }
        return `${currency} ${this.stringUtils.amountString(Math.min(...amounts), 2)}`;
    }
    private getFareDetail(journeyId: string,
        offers: any,
        priceClassMap: any): OrderShoppingSearchResultFareView[] {

        let selectedOffers = this.getOffersFromJourney(offers, journeyId);
        let fareDetails: OrderShoppingSearchResultFareView[] = [];

        for (let selectedOffer of selectedOffers) {
            let offer = offers[selectedOffer.id];
            let totalAmount = offer.totalPrice.totalAmount;
            let classTypeDescription = this.getPriceClass(offers, priceClassMap, selectedOffer.id);

            fareDetails.push({
                priceClassId: classTypeDescription?.id,
                priceClass: classTypeDescription?.name,
                fareAmountNumber: totalAmount.value ?? 0.0,
                fareAmount: this.stringUtils.validateNullAmountDecimal(totalAmount.value),
                fareCurrency: this.stringUtils.getCurrencySymbol('en-Us', totalAmount.currencyCode),
                availableSeat: offer.seatsRemaining.count,
                fareStyleClass: null,
                offerId: selectedOffer.id,
                journeyId: journeyId,
                selectedFare: false
            });
        }
        return fareDetails.sort((a, b) => a.fareAmountNumber - b.fareAmountNumber);
    }
    private getPriceClass(offers: any, priceClassMap: any, offerId: string): any {

        const selectedOffer = offers[offerId];
        if (!selectedOffer) {
            return null;
        }
        let priceClassId = selectedOffer?.journeyOverview?.priceClassId;
        if (!priceClassId) {
            return null;
        }
        let classMap = priceClassMap[priceClassId];
        return classMap;
    }
    public getFareDescription(priceClassId: string,
        priceClass: string,
        priceClassMap: any): OrderShoppingSearchResultFareDescriptionView {

        let fareClassDetail = this.getPriceClassDetail(priceClassMap, priceClassId)
        if (!fareClassDetail) {
            return;
        }
        return {
            priceClassId: priceClassId,
            details: this.createFareDescriptionDetail(fareClassDetail.ISEBASIC),
            more: fareClassDetail.ISEMORE
        };
    }
    private getPriceClassDetail(priceClassMap: any, priceClassId: string): any {
        let priceClass = priceClassMap[priceClassId]
        if (!priceClass) {
            return null;
        }
        return priceClass?.description?.map;
    }
    private createFareDescriptionDetail(priceClassMapMore: any): OrderShoppingSearchResultFareDescriptionDetailView[] {
        if (!priceClassMapMore) {
            return null;
        }
        let fareDescriptions = new Array<OrderShoppingSearchResultFareDescriptionDetailView>();
        for (let detail of priceClassMapMore) {
            fareDescriptions.push({
                type: detail.category,
                text: detail.text
            });
        }
        return fareDescriptions;
    }

    public getShoppingProductDetail(operatingSegmentId: string,
        marketingSegmentId: string,
        offer: any): OrderShoppingSearchResultProductDetailView {

        let marketing = offer.data.data.marketingSegmentMap[marketingSegmentId];
        let operating = offer.data.data.operatingSegmentMap[operatingSegmentId];
        let operatingLeg = offer.data.data.operatingLegMap[operating.operatingLegIdList[0]];
        let locationMap = offer.data.data.locationMap;
        return {
            aircraftType: operatingLeg.aircraftType.name,
            aircraftConfiguration: operatingLeg?.aircraftComposition?.aircraftConfiguration?.name,
            marketingProductNumber: this.getMarketingSegment(marketing),
            operatingProductNumber: `${operating.carrier.code} ${operating.productNumber}`,
            departureAirportCode: marketing.departurePoint.locationCode,
            departureAirportName: locationMap[marketing.departurePoint.locationCode].name,
            arrivalAirportCode: marketing.arrivalPoint.locationCode,
            arrivalAirportName: locationMap[marketing.arrivalPoint.locationCode].name,
            irregularity: null,
            originWeather: null,
            destinationWeather: null,
            parentProductId: null,
            departureTerminal: operatingLeg.departurePoint.terminal,
            arrivalTerminal: operatingLeg.arrivalPoint.terminal,
            departureGate: null,
            arrivalGate: null
        }
    }

    private getMarketingSegment(marketingMap: any): string {
        if (!marketingMap?.productNumber) {
            return "";
        }
        return marketingMap.carrier.code + " " + marketingMap.productNumber
    }
    public getShoppingCartView(offer: any, selectedOffers: OrderShoppingSelectedRouteOffer[]): OrderShoppingCart {

        if (!offer || !selectedOffers) {
            return null;
        }
        let orderShoppingCart = {
            recordLocator: null,
            orderId: null,
            selectedOffers: selectedOffers,
            passengerTypes: this.initPassengerTypes(offer),
            passengersOnInitContact: [],
            individualContact: null,
            primaryContactCode: null,
            totalAmount: 0,
            totalCurrencyCode: null,
            contactType: null
        }
        this.addFlightProduct(offer,
            orderShoppingCart,
            selectedOffers);
        return orderShoppingCart;
    }

    private addFlightProduct(offer: any,
        orderShoppingCart: OrderShoppingCart,
        selectedOffers: OrderShoppingSelectedRouteOffer[]): void {

        let passengerTypeItems = orderShoppingCart.passengerTypes;
        if (!passengerTypeItems?.length) {
            return;
        }
        let offers = offer.data.offers;
        let offerData = offer.data.data;
        let totalAmount: number = 0;

        for (let paxType of passengerTypeItems) {
            paxType.items = [];
            let passengerTypeAmount: number = 0;
            for (let i = 0; i < selectedOffers.length; i++) {
                if (!selectedOffers[i].selectedOfferId) {
                    continue;
                }
                let flightProductOffer = offers[selectedOffers[i].selectedOfferId];
                let offerMarketingSegment
                    = this.getOfferMarketingSegmentMap(offerData, flightProductOffer);
                let priceClassMap
                    = this.getOfferPriceClassMap(offerData, flightProductOffer);
                let flightProductOfferAmount = flightProductOffer.totalPrice.totalAmount;
                let passengerTypeOffer = this.getPassengerTypeOffer(flightProductOffer, paxType.passengerTypeCode);
                let departureProductName = this.getDepartureProductName(offerData, offerMarketingSegment);
                let arrivalProductName = this.getArrivalProductName(offerData, offerMarketingSegment);
                let segmentList = this.getOfferMarketingSegmentIdList(offerData, flightProductOffer);
                const operatingCarrierCode = this.getOperatingCarrierCode(offerMarketingSegment.operatingSegmentId, offerData);
                const operatingProductNumber = this.getOperatingProductNumber(offerMarketingSegment.operatingSegmentId, offerData);
                const marketingCarrierCode = this.getMarketingCarrierCode(offerMarketingSegment);
                const marketingProductNumber = this.getMarketingProductNumber(offerMarketingSegment);
                paxType.items.push({
                    id: this.stringUtils.NewGuid(),
                    offerId: selectedOffers[i].selectedOfferId,
                    offerItemId: passengerTypeOffer.id,
                    offerSegmentIds: segmentList,
                    departureAirportCode: offerMarketingSegment.departurePoint.locationCode,
                    arrivalAirportCode: offerMarketingSegment.arrivalPoint.locationCode,
                    departureAirportName: departureProductName,
                    arrivalAirportName: arrivalProductName,
                    offerOwnerCode: flightProductOffer.carrier?.code,
                    operatingCarrierCode: operatingCarrierCode,
                    operatingProductNumber: operatingProductNumber,
                    marketingCarrierCode: marketingCarrierCode,
                    marketingProductNumber: marketingProductNumber,
                    displayCarrierCode: this.getCarrierCode(marketingCarrierCode, operatingCarrierCode),
                    displayProductNumber: this.getProductNumber(marketingProductNumber, operatingProductNumber),
                    productCode: offerMarketingSegment.productNumber,
                    description: `${departureProductName} - ${arrivalProductName}`,
                    currencyCode: flightProductOfferAmount.currencyCode,
                    amount: this.getPassengerTypeProductAmount(passengerTypeOffer),
                    totalAmount: this.sumPassengerTypeFareDetail(passengerTypeOffer.fareDetails),
                    taxes: this.getProductFlightTaxes(flightProductOffer, paxType.passengerTypeCode),
                    markups: this.getProductItemMarkups(flightProductOffer, paxType.passengerTypeCode),
                    discounts: this.getProductItemDiscounts(flightProductOffer, paxType.passengerTypeCode),
                    productItems: this.getOtherProductItems(offer, flightProductOffer, paxType.passengerTypeCode),
                    itemGroupCode: null,
                    productDate: offerMarketingSegment.departurePoint.scheduledTime,
                    arrivalDate: offerMarketingSegment.arrivalPoint.scheduledTime,
                    salesBucket: this.getSalesBucket(priceClassMap),
                    serviceCategory: this.getServiceCategory(priceClassMap),
                    numberOfUnit: null,
                    productGroupName: null,
                    productCategoryName: null,
                    seat: null,
                    seatRow: null,
                    seatCol: null,
                    seatPrice: 0,
                    serviceRefIds: []
                });
                passengerTypeAmount = passengerTypeAmount + this.sumPassengerTypeFareDetail(passengerTypeOffer.fareDetails);
                paxType.totalAmountCurrency = passengerTypeOffer.price.totalAmount.currencyCode;
            }
            paxType.totalAmount = passengerTypeAmount;

            totalAmount = totalAmount + passengerTypeAmount;
            orderShoppingCart.totalCurrencyCode = paxType.totalAmountCurrency;
        }
        orderShoppingCart.totalAmount = totalAmount;
    }
    private getCarrierCode(marketingCarrierCode: string, operatingCarrierCode: string) {
        if (!marketingCarrierCode || marketingCarrierCode.length == 0) {
            return operatingCarrierCode;
        }
        return marketingCarrierCode;
    }
    private getProductNumber(marketingProductNumber: string, operatingProductNumber: string) {
        if (!marketingProductNumber || marketingProductNumber.length == 0) {
            return operatingProductNumber;
        }
        return marketingProductNumber;
    }
    private getOperatingCarrierCode(operatingSegmentId: string, offerData: any) {
        return offerData.operatingSegmentMap[operatingSegmentId]?.carrier?.code;
    }
    private getOperatingProductNumber(operatingSegmentId: string, offerData: any) {
        return offerData.operatingSegmentMap[operatingSegmentId]?.productNumber;
    }
    private getMarketingCarrierCode(offerData: any) {
        return offerData.carrier?.code;
    }
    private getMarketingProductNumber(offerData: any) {
        return offerData.productNumber;
    }

    public getOfferFromJourney(offers: any, journeyId: string): any[] {
        let offerResults = new Array<any>();
        for (let offer in offers) {
            if (offers.hasOwnProperty(offer)) {
                const data = offers[offer];
                const findOffer: boolean = data.journeyOverview.journeyPriceClasses.some(x => x.journeyId === journeyId);
                if (findOffer) {
                    offerResults.push(data);
                }
            }
        }
        return offerResults;
    }
    private getOffersFromJourney(offers: any, journeyId: string): any[] {
        let selectedOffers = new Array<any>();
        for (let offer in offers) {
            if (offers.hasOwnProperty(offer)) {
                const data = offers[offer];
                const findOffer: boolean = data.journeyOverview.journeyPriceClasses.some(x => x.journeyId === journeyId);
                if (findOffer) {
                    selectedOffers.push(data);
                }
            }
        }
        return selectedOffers;
    }

    private getMarketingIdsMapFromOffer(offer: any, journeyId: string): string[] {
        const journeyMap = offer.data.data.journeyMap[journeyId];
        let marketingSegmentIds = new Array<string>();
        for (let segmentId of journeyMap.segmentIdList) {
            const segment = offer?.data?.data?.segmentMap[segmentId];
            if (!segment) {
                continue;
            }
            marketingSegmentIds.push(segment.marketingSegmentId)
        }
        return marketingSegmentIds;
    }

    private getSalesBucket(priceClassMap: any): OrderShoppingCartSalesBucket {
        if (!priceClassMap) {
            return null;
        }
        return {
            code: priceClassMap.fareBases.code,
            name: priceClassMap.fareBases.name
        };
    }
    private getServiceCategory(priceClassMap: any): OrderShoppingCartServiceCategory {
        if (!priceClassMap || !priceClassMap?.cabinTypes || priceClassMap.cabinTypes.length === 0) {
            return null;
        }
        return {
            code: priceClassMap.cabinTypes[0].code,
            name: priceClassMap.cabinTypes[0].name
        };
    }

    private sumPassengerTypeFareDetail(fareDetails: any): number {
        return fareDetails.reduce((accumulator, fareDetail) => {
            return accumulator + fareDetail.price.totalAmount.value;
        }, 0);
    }
    private getOtherProductItems(offer: any, flightProductOffer: any, passengerTypeCode: string): OrderShoppingCartProductItem[] {
        let productShoppingCarts = new Array<OrderShoppingCartProductItem>();

        this.addProductItemFee(productShoppingCarts,
            flightProductOffer,
            passengerTypeCode);
        this.addProductItemSurcharge(productShoppingCarts,
            flightProductOffer,
            passengerTypeCode);
        return productShoppingCarts;
    }

    private addProductItemFee(productShoppingCarts: OrderShoppingCartProductItem[],
        flightProductOffer: any,
        passengerTypeCode: string) {

        let fareDetails = this.getOfferFareDetails(flightProductOffer, passengerTypeCode);
        let fees = fareDetails[0].price.fees;
        if (!fees || fees.length === 0) {
            return;
        }
        for (let fee of fees) {
            productShoppingCarts.push({
                id: this.stringUtils.NewGuid(),
                offerId: null,
                offerItemId: null,
                offerSegmentIds: null,
                offerOwnerCode: null,
                operatingCarrierCode: null,
                operatingProductNumber: null,
                marketingCarrierCode: null,
                marketingProductNumber: null,
                displayCarrierCode: null,
                displayProductNumber: null,
                productCode: fee.code,
                description: fee.description,
                currencyCode: fee.amount.currencyCode,
                amount: fee.amount.value,
                totalAmount: 0,
                taxes: null,
                discounts: null,
                markups: null,
                productItems: null,
                itemGroupCode: ShoppingProductGroupCode.Fee,
                productDate: null,
                arrivalDate: null,
                departureAirportCode: null,
                arrivalAirportCode: null,
                departureAirportName: null,
                arrivalAirportName: null,
                salesBucket: null,
                serviceCategory: null,
                numberOfUnit: null,
                productGroupName: null,
                productCategoryName: null,
                seat: null,
                seatRow: null,
                seatCol: null,
                seatPrice: 0,
                serviceRefIds: []
            })
        }
    }
    private addProductItemSurcharge(productShoppingCarts: OrderShoppingCartProductItem[],
        flightProductOffer: any,
        passengerTypeCode: string) {

        let fareDetails = this.getOfferFareDetails(flightProductOffer, passengerTypeCode);
        let surcharges = fareDetails[0].price.surcharges;
        if (!surcharges || surcharges.length === 0) {
            return;
        }
        for (let surcharge of surcharges) {
            for (let itemBreakDown of surcharge.breakdown) {
                productShoppingCarts.push({
                    id: this.stringUtils.NewGuid(),
                    offerId: null,
                    offerItemId: null,
                    offerSegmentIds: null,
                    offerOwnerCode: null,
                    operatingCarrierCode: null,
                    operatingProductNumber: null,
                    marketingCarrierCode: null,
                    marketingProductNumber: null,
                    displayCarrierCode: null,
                    displayProductNumber: null,
                    productCode: itemBreakDown.code,
                    description: itemBreakDown.description,
                    currencyCode: itemBreakDown.amount.currencyCode,
                    amount: itemBreakDown.amount.value,
                    totalAmount: 0,
                    taxes: null,
                    discounts: null,
                    markups: null,
                    productItems: null,
                    itemGroupCode: ShoppingProductGroupCode.Surcharge,
                    productDate: null,
                    arrivalDate: null,
                    departureAirportCode: null,
                    arrivalAirportCode: null,
                    departureAirportName: null,
                    arrivalAirportName: null,
                    salesBucket: null,
                    serviceCategory: null,
                    numberOfUnit: null,
                    productGroupName: null,
                    productCategoryName: null,
                    seat: null,
                    seatRow: null,
                    seatCol: null,
                    seatPrice: 0,
                    serviceRefIds: []
                })
            }
        }
    }
    private getProductItemMarkups(flightProductOffer: any,
        passengerTypeCode: string): OrderShoppingCartMarkup[] {

        let fareDetails = this.getOfferFareDetails(flightProductOffer, passengerTypeCode);
        let markups = fareDetails[0].price.markups;
        if (!markups?.length) {
            return [];
        }
        let shoppingMarkups = new Array<OrderShoppingCartMarkup>();
        for (let markup of markups) {
            shoppingMarkups.push({
                id: this.stringUtils.NewGuid(),
                markupCode: markup.code,
                description: markup.description,
                currencyCode: markup.amount.currencyCode,
                amount: markup.amount.value
            });
        }
        return shoppingMarkups;
    }
    private getProductItemDiscounts(flightProductOffer: any,
        passengerTypeCode: string): OrderShoppingCartDiscount[] {

        let fareDetails = this.getOfferFareDetails(flightProductOffer, passengerTypeCode);
        let discount = fareDetails[0].price.discount;
        if (!discount) {
            return [];
        }
        let discounts = new Array<OrderShoppingCartDiscount>();
        discounts.push({
            id: this.stringUtils.NewGuid(),
            description: discount.description,
            currencyCode: discount.discountAmount.currencyCode,
            amount: -discount.discountAmount.value,
            percent: discount.percent
        });
        return discounts;
    }
    private getProductFlightTaxes(flightProductOffer: any, passengerTypeCode: string): OrderShoppingCartTax[] {

        let fareDetails = this.getOfferFareDetails(flightProductOffer, passengerTypeCode);
        let taxSummaries = fareDetails[0].price.taxSummaries;
        if (!taxSummaries?.length) {
            return [];
        }
        let taxes = new Array<OrderShoppingCartTax>();
        for (let tax of taxSummaries) {
            for (let taxItem of tax.taxes) {
                taxes.push({
                    id: this.stringUtils.NewGuid(),
                    taxCode: taxItem.code,
                    amount: taxItem.amount.value,
                    currencyCode: taxItem.amount.currencyCode,
                    description: taxItem.description
                })
            }
        }
        return taxes;
    }

    private getOfferFareDetails(flightProductOffer: any, passengerTypeCode: string): any {

        let passengerTypeFareDetail = this.getPassengerTypeOffer(flightProductOffer, passengerTypeCode);
        return passengerTypeFareDetail.fareDetails;
    }

    private getPassengerTypeOffer(flightProductOffer: any,
        passengerTypeCode: string): any {

        return flightProductOffer
            .items
            .find(x => x.fareDetails.
                find(y => y.passengerIdList.includes(passengerTypeCode + 1)));
    }
    private getPassengerTypeProductAmount(passengerTypeOffer: any): number {
        return passengerTypeOffer.fareDetails[0].price.baseAmount.value;
    }
    private getDepartureProductName(offerData: any, offerMarketingSegment: any) {

        let locationCode = offerMarketingSegment.departurePoint.locationCode;
        let departureName = offerData.locationMap[locationCode].name;
        return `${departureName} (${locationCode})`;
    }
    private getArrivalProductName(offerData: any, offerMarketingSegment: any) {

        let locationCode = offerMarketingSegment.arrivalPoint.locationCode;
        let arrivalName = offerData.locationMap[locationCode].name;
        return `${arrivalName} (${locationCode})`;
    }
    private getOfferMarketingSegmentMap(offerData: any, flightProduct: any): any {
        let journeyId = flightProduct.journeyOverview.journeyPriceClasses[0].journeyId;
        let segmentId = offerData.journeyMap[journeyId].segmentIdList[0];
        let marketingSegmentId = offerData.segmentMap[segmentId].marketingSegmentId;
        return offerData.marketingSegmentMap[marketingSegmentId];
    }
    private getOfferMarketingSegmentIdList(offerData: any, flightProduct: any): any {
        let journeyId = flightProduct.journeyOverview.journeyPriceClasses[0].journeyId;
        return offerData.journeyMap[journeyId].segmentIdList;
    }
    private getOfferPriceClassMap(offerData: any, flightProduct: any): any {
        let priceClassId = flightProduct.journeyOverview.journeyPriceClasses[0].priceClassId;
        return offerData.priceClassMap[priceClassId];
    }
    public initPassengerTypes(offer: any): OrderShoppingCartPassengerType[] {
        let passengerTypeMap = offer.data.data.passengerMap
        let passengerTypes = new Array<OrderShoppingCartPassengerType>();

        for (let passengerType in passengerTypeMap) {
            if (passengerTypeMap.hasOwnProperty(passengerType)) {
                let paxTypeCode = passengerTypeMap[passengerType].typeCode;
                let paxId = passengerTypeMap[passengerType].id;
                passengerTypes.push({
                    index: 0,
                    id: this.stringUtils.NewGuid(),
                    individualId: null,
                    offerPassengerId: paxId,
                    passengerTypeCode: paxTypeCode,
                    passengerTypeName: this.getPassengerTypeName(paxTypeCode),
                    passengerTypeNameIndex: this.getPassengerTypeNameIndex(paxId),
                    items: null,
                    totalAmount: 0,
                    totalAmountCurrency: null,
                    firstName: null,
                    lastName: null,
                    fullName: null,
                    title: null,
                    insightData: null,
                    ageGroup: null,
                    ageGroupName: null,
                    dateOfBirth: null,
                    genderCode: null,
                    languageCode: null,
                    mailAddress: null,
                    phoneNumber: null,
                    phonePrefix: null,
                    guardianIndex: null,
                    guardianIndividualId: null,
                    organisationId: null,
                    organisationName: null,
                    searchInOrganisationFlag: null,
                    comment: null,
                    primaryContactFlag: false
                });
            }
        }
        return passengerTypes;
    }

    private getPassengerTypeNameIndex(passengerTypeName: string): string {
        const adultText: string = "Adult";
        const childText: string = "Child";
        const infantText: string = "Infant";

        var passengerTypeCodeMapper = {
            ADT: adultText,
            CHD: childText,
            INF: infantText
        };
        const reg = new RegExp(Object.keys(passengerTypeCodeMapper).join("|"), "g");
        passengerTypeName = passengerTypeName.replace(reg, function (matched) {
            return passengerTypeCodeMapper[matched];
        });

        return passengerTypeName;
    }

    private getPassengerTypeName(passengerTypeCode: string): string {
        const adultCode: string = "ADT";
        const childCode: string = "CHD";
        const infantCode: string = "INF";
        const adultText: string = "Adult";
        const childText: string = "Child";
        const infantText: string = "Infant";

        if (passengerTypeCode === adultCode) {
            return adultText;
        } else if (passengerTypeCode === childCode) {
            return childText;
        } else if (passengerTypeCode === infantCode) {
            return infantText
        }
        return "";
    }

    public toShoppingCartProductDetails(offer: any, orderStateData: OrderShoppingStateData): OrderShoppingCartProductDetailView[] {
        let views = new Array<OrderShoppingCartProductDetailView>();
        let shoppingCart = orderStateData?.orderShoppingCart;
        if (!orderStateData
            || !shoppingCart?.passengerTypes
            || shoppingCart.passengerTypes.length === 0) {

            return null;
        }
        let shoppingOrder = orderStateData?.shoppingShopOrder;
        for (let flight of shoppingCart.passengerTypes[0].items) {
            const operatingLegMap = this.getOperatingLegMap(offer, flight.offerId);
            let departureTimeZone = this.getOfferUtcOffsetTime(operatingLegMap, flight.departureAirportCode);
            let arrivalTimeZone = this.getOfferUtcOffsetTime(operatingLegMap, flight.arrivalAirportCode);

            let flightCode = `${flight.displayCarrierCode} ${flight.displayProductNumber}`;

            views.push({
                categoryName: shoppingOrder?.productCategory.name,
                productGroupName: shoppingOrder?.productGroup.name,
                productNumber: flightCode,
                date: this.dateConversion.convertOffsetDate(flight.productDate, departureTimeZone),
                departureAirport: `${flight.departureAirportCode} - ${this.removeAirportCodeFormAirportName(flight.departureAirportName)}`,
                arrivalAirport: `${flight.arrivalAirportCode} - ${this.removeAirportCodeFormAirportName(flight.arrivalAirportName)}`,
                departueTime: this.dateConversion.convertTime24ByTimeZone(flight.productDate, departureTimeZone),
                arrivalTime: this.dateConversion.convertTime24ByTimeZone(flight.arrivalDate, arrivalTimeZone),
                serviceCategory: `${flight.serviceCategory.name} (${flight.salesBucket.code})`,
                salesStatus: 'Confirmed',
                totalAmount: this.stringUtils.validateNullAmountDecimal(this.getTotalAmountByOfferId(flight.offerId, shoppingCart.passengerTypes)),
                productName: flight.description
            });
            if (this.getSubItems(flight)?.length) {
                views.push(...this.createSubItemViews(flight, departureTimeZone));
            }
        }
        return views;
    }

    private removeAirportCodeFormAirportName(airportName: string): string {
        return airportName.split('(').length ?? "", airportName.split('(')[0];
    }

    private getTotalAmountByOfferId(offerId: string, passengerTypes: OrderShoppingCartPassengerType[]): number {
        let number = 0;

        for (let passengerType of passengerTypes) {
            let item = passengerType.items.find(x => x.offerId == offerId);
            number += item.totalAmount;
        }

        return number;
    }

    public getOperatingLegMap(offer: any, offerId: string) {
        const offerMap = offer?.data?.offers[offerId];
        const [journeyPriceClass] = offerMap?.journeyOverview?.journeyPriceClasses;
        const journeyId = journeyPriceClass?.journeyId;
        let [marketingId] = this.getMarketingIdsMapFromOffer(offer, journeyId);
        const marketingMap = offer.data.data.marketingSegmentMap[marketingId];
        const operatingMap = offer.data.data.operatingSegmentMap[marketingMap.operatingSegmentId];
        const [operatingLegId] = operatingMap?.operatingLegIdList;
        const operatingLegMap = offer.data.data.operatingLegMap[operatingLegId];
        return operatingLegMap;
    }

    private createSubItemViews(flight: OrderShoppingCartProductItem, utcOffsetMinute: number): OrderShoppingCartProductDetailView[] {
        let views = new Array<OrderShoppingCartProductDetailView>();
        let items = this.getSubItems(flight);
        for (let item of items) {
            views.push({
                categoryName: item.productCategoryName,
                productGroupName: item.productGroupName,
                productNumber: item.productCode,
                date: this.dateConversion.convertUtcOffsetShortDate(flight.productDate, utcOffsetMinute),
                departureAirport: `${flight.departureAirportCode} - ${flight.departureAirportName}`,
                arrivalAirport: `${flight.arrivalAirportCode} - ${flight.arrivalAirportName}`,
                departueTime: null,
                arrivalTime: null,
                serviceCategory: `${item.serviceCategory.name}(${item.salesBucket.code})`,
                salesStatus: 'Confirmed',
                totalAmount: this.stringUtils.validateNullAmountDecimal(item.totalAmount),
                productName: item.description
            });
        }
        return views;
    }
    public toShoppingPaymentProductDetails(offer: any, orderStateData: OrderShoppingStateData): OrderShoppingPaymentProductDetailView[] {
        let views = new Array<OrderShoppingPaymentProductDetailView>();
        let shoppingCart = orderStateData?.orderShoppingCart;
        if (!orderStateData
            || !shoppingCart?.passengerTypes
            || shoppingCart.passengerTypes.length === 0) {

            return null;
        }
        let shoppingOrder = orderStateData?.shoppingShopOrder;

        for (let passengerType of shoppingCart.passengerTypes) {
            for (let flight of passengerType.items) {
                const operatingLegMap = this.getOperatingLegMap(offer, flight.offerId);
                let departureTimeZone = this.getOfferUtcOffsetTime(operatingLegMap, flight.departureAirportCode);
                let arrivalTimeZone = this.getOfferUtcOffsetTime(operatingLegMap, flight.arrivalAirportCode);
                this.pushOrAddOrderShoppingPaymentProductDetailView(views,
                    shoppingOrder?.productCategory.name,
                    shoppingOrder?.productGroup.name,
                    departureTimeZone,
                    arrivalTimeZone,
                    flight);
                this.addChildShoppingPaymentProductDetails(views, flight, departureTimeZone);
            }
        }

        return views;
    }

    private addChildShoppingPaymentProductDetails(views: OrderShoppingPaymentProductDetailView[],
        flight: OrderShoppingCartProductItem,
        departureUtcOffsetMinute: number) {
        const ssrItems = this.getSubItems(flight)
            .map((item: OrderShoppingCartProductItem) => {
                this.pushOrAddOrderShoppingPaymentProductDetailView(views,
                    item.productCategoryName,
                    item.productGroupName,
                    departureUtcOffsetMinute, null,
                    item);
            });
    }
    private pushOrAddOrderShoppingPaymentProductDetailView(views: OrderShoppingPaymentProductDetailView[],
        productCategoryName: string,
        productGroupName: string,
        departureUtcOffsetMinute: number,
        arrivalUtcOffsetMinute: number,
        flight: OrderShoppingCartProductItem): void {

        let orderShoppingPayment = this.findOrderShoppingPaymentProductDetailView(flight.productCode,
            views);
        if (orderShoppingPayment) {
            orderShoppingPayment.totalAmount = orderShoppingPayment.totalAmount + flight.totalAmount;
            orderShoppingPayment.calculatedPercentageAmount = orderShoppingPayment.calculatedPercentageAmount + flight.totalAmount;
            return;
        }

        let view: OrderShoppingPaymentProductDetailView = {
            id: flight.id,
            categoryName: productCategoryName,
            productGroupName: productGroupName,
            productNumber: flight.productCode,
            date: this.dateConversion.convertUtcOffsetShortDate(flight.productDate, departureUtcOffsetMinute),
            departureAirport: `${flight.departureAirportCode} - ${flight.departureAirportName}`,
            arrivalAirport: `${flight.arrivalAirportCode} - ${flight.arrivalAirportName}`,
            custom1: null,
            custom2: null,
            curencyCode: flight.currencyCode,
            totalAmount: flight.totalAmount,
            paidAmount: 0,
            outstandingBalance: 0,
            percentage: 100,
            calculatedPercentageAmount: flight.totalAmount,
            hidePercentage: false,
            hide: false,
            selected: true
        };

        this.fillPaymentProductCustomField(view,
            departureUtcOffsetMinute,
            arrivalUtcOffsetMinute,
            flight);

        views.push(view);
    }
    private fillPaymentProductCustomField(view: OrderShoppingPaymentProductDetailView,
        departureUtcOffsetMinute: number,
        arrivalUtcOffsetMinute: number,
        flight: OrderShoppingCartProductItem) {

        if (arrivalUtcOffsetMinute) {
            view.custom1 = {
                fieldName: "STD",
                value: this.dateConversion.convertUtcOffsetShortTime(flight.productDate, departureUtcOffsetMinute)
            }
            view.custom2 = {
                fieldName: "STA",
                value: this.dateConversion.convertUtcOffsetShortTime(flight.arrivalDate, arrivalUtcOffsetMinute)
            }
        } else {
            view.custom1 = {
                fieldName: "Category",
                value: flight.productCategoryName
            }
            view.custom2 = {
                fieldName: this.custom2Name(flight.itemGroupCode),
                value: flight.description
            }
        }
    }
    private findOrderShoppingPaymentProductDetailView(productNumber: string,
        views: OrderShoppingPaymentProductDetailView[]): OrderShoppingPaymentProductDetailView {

        return views.find(x => x.productNumber === productNumber);
    }
    private custom2Name(itemGroupCode: string): string {
        if (itemGroupCode === ShoppingProductGroupCode.SSR) {
            return "Product Name";
        }
        return "Seat Number";
    }

    private getSubItems(flight: OrderShoppingCartProductItem): OrderShoppingCartProductItem[] {
        return flight?.productItems.filter(x =>
            x.itemGroupCode == ShoppingProductGroupCode.SSR || x.itemGroupCode == ShoppingProductGroupCode.SEAT);
    }

    public orderShoppingIndividualToPassengerTypeStateMapping(orderShoppingIndividuals: OrderShoppingIndividualOrder[],
        passengerTypes: OrderShoppingCartPassengerType[]) {

        for (let passengerType of passengerTypes) {
            for (let orderShoppingIndividual of orderShoppingIndividuals) {
                if (this.matchPassenger(orderShoppingIndividual, passengerType)) {
                    passengerType.comment = orderShoppingIndividual.comment;
                    this.mapOrderShoppingIndividualToPassengerTypeState(orderShoppingIndividual, passengerType);
                }
            }
        }
    }

    private matchPassenger(orderShoppingIndividual: OrderShoppingIndividualOrder,
        passengerType: OrderShoppingCartPassengerType): boolean {
        if (orderShoppingIndividual.firstName === passengerType.firstName
            && orderShoppingIndividual.lastName === passengerType.lastName
            && orderShoppingIndividual.title === passengerType.title) {
            return true;
        }
        return false;
    }
    private mapOrderShoppingIndividualToPassengerTypeState(orderShoppingIndividual: OrderShoppingIndividualOrder,
        passengerType: OrderShoppingCartPassengerType) {

        passengerType.index = orderShoppingIndividual.index;
        passengerType.individualId = orderShoppingIndividual.individualId;
        passengerType.firstName = orderShoppingIndividual.firstName;
        passengerType.lastName = orderShoppingIndividual.lastName;
        passengerType.title = orderShoppingIndividual.title;
        passengerType.fullName = orderShoppingIndividual.fullName;
        passengerType.ageGroup = orderShoppingIndividual.ageGroup;
        passengerType.ageGroupName = orderShoppingIndividual.ageGroupName;
        passengerType.dateOfBirth = orderShoppingIndividual.dateOfBirth;
        passengerType.genderCode = orderShoppingIndividual.genderCode;
        passengerType.languageCode = orderShoppingIndividual.languageCode;
        passengerType.phonePrefix = orderShoppingIndividual.phonePrefix;
        passengerType.phoneNumber = orderShoppingIndividual.phoneNumber;
        passengerType.mailAddress = orderShoppingIndividual.mailAddress;
        passengerType.organisationId = orderShoppingIndividual.organisationId;
        passengerType.organisationName = orderShoppingIndividual.organisationName;
        passengerType.guardianIndex = orderShoppingIndividual.guardianIndex;
        passengerType.guardianIndividualId = orderShoppingIndividual.guardianIndividualId;
        passengerType.insightData = orderShoppingIndividual.insightData;
        passengerType.searchInOrganisationFlag = orderShoppingIndividual.searchInOrganisationFlag;
    }

    public toCollectAttributes(selectedAttributes: OrderShoppingSelectedAttributeView[]): OrderShoppingCollectAttribute[] {

        if (!selectedAttributes?.length) {
            return null;
        }
        let attributes = new Array<OrderShoppingCollectAttribute>();
        for (let selectedAttribute of selectedAttributes) {
            attributes.push({
                id: selectedAttribute.id,
                code: selectedAttribute?.attributeCode as string,
                type: selectedAttribute?.dimentionType,
                name: selectedAttribute?.attributeName,
                value: selectedAttribute?.attributeValue,
                requiredAttribute: selectedAttribute?.requiredAttribute
            });
        }
        return attributes;
    }

    public toOrderRoute(departureAirportCode: string,
        arrivalAirportCode: string,
        currencyCode: string,
        organisationCode: string,
        requestDate: Date): OrderRouteModel {

        return {
            context: {
                lang: "en",
                curr: currencyCode,
                orgUnit: organisationCode
            },
            route: {
                departureLocationCode: departureAirportCode,
                arrivalLocationCode: arrivalAirportCode
            },
            initialDate: this.dateConversion.dateTimeToUTCString(requestDate)
        };
    }
    public combineOffer(offers: any[]): any {
        if (!offers || offers.length === 0) {
            return null;
        }
        let primaryOffer = offers[0];
        if (offers.length == 1) {
            return primaryOffer;
        }
        this.combineOfferDataNode(primaryOffer, offers);
        this.combineOfferOfferNode(primaryOffer, offers);
        return primaryOffer;
    }
    private combineOfferDataNode(primaryOffer: any, offers: any) {
        for (let i = 1; i < offers.length; i++) {
            for (let property in offers[i].data.data) {
                this.createOfferDataNode(primaryOffer, offers, property, i);
            }
        }
    }
    private createOfferDataNode(primaryOffer: any, offers: any, property: any, index: number) {
        for (let subProperty in offers[index].data.data[property]) {
            primaryOffer.data.data[property][subProperty] = offers[index].data.data[property][subProperty];
        }
    }
    private combineOfferOfferNode(primaryOffer: any, offers: any) {
        for (let i = 1; i < offers.length; i++) {
            for (let property in offers[i].data.offers) {
                primaryOffer.data.offers[property] = offers[i].data.offers[property];
            }
        }
    }
}

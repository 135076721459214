import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductManagementContentModule } from './product-management-content/product-management-content.module';
import { ProductManagementComponent } from './product-management.component';
import { ProductManagementRoutingModule } from './product-management-routing.module';
import { UiModule } from '../../shared/ui/ui.module';
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { SpecialServiceModule } from './product-categories-content/special-service/special-service.module';
import { TransportModule } from './product-categories-content/transport/transport.module';
import { AncillaryServiceModule } from './product-categories-content/ancillary-service/ancillary-service.module';
import { VoucherModule } from './product-categories-content/voucher/voucher.module';

@NgModule({
    declarations: [
        ProductManagementComponent
    ],
    imports: [
        AncillaryServiceModule,
        CommonModule,
        DynamicRoutingModule,
        ProductManagementContentModule,
        ProductManagementRoutingModule,
        SpecialServiceModule,
        TransportModule,
        UiModule,
        VoucherModule
    ],
    exports: [ProductManagementComponent]
})
export class ProductManagementModule { }
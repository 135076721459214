import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";

@Injectable()
export class VehicleSetRequireFieldService {
    public clearRequireVehicle(ctl) {
        ctl.get('vehicleGroupCode').clearValidators();
        ctl.get('vehicleGroupCode').updateValueAndValidity();

        ctl.get('operatorVehicle').clearValidators();
        ctl.get('operatorVehicle').updateValueAndValidity();

        ctl.get('vehicleTypeCode').clearValidators();
        ctl.get('vehicleTypeCode').updateValueAndValidity();

        ctl.get('vehicleCompositionId').clearValidators();
        ctl.get('vehicleCompositionId').updateValueAndValidity();
    }

    public setRequireVehicle(ctl) {
        ctl.get('vehicleGroupCode').setValidators(Validators.required);
        ctl.get('vehicleGroupCode').updateValueAndValidity();

        ctl.get('operatorVehicle').setValidators(Validators.required);
        ctl.get('operatorVehicle').updateValueAndValidity();

        ctl.get('vehicleTypeCode').setValidators(Validators.required);
        ctl.get('vehicleTypeCode').updateValueAndValidity();
    }
}
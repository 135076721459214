import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";

export const PRODUCT_DASHBOARD_MERCHANDIZE_SUMMARY_DEMO: ProductDashboardSummaryModel[] = [
    {
        productName: "T-shirt",
        todayProductCount: 48,
        todaySalesAmount: 58126.33,
        yesterdayProductCount: 77,
        yesterdaySalesAmount: 83360.14,
        last7dProductCount: 195,
        last7dSalesAmount: 211106.84
    },
    {
        productName: "Watch",
        todayProductCount: 53,
        todaySalesAmount: 69881.40,
        yesterdayProductCount: 53,
        yesterdaySalesAmount: 69881.40,
        last7dProductCount: 299,
        last7dSalesAmount: 394236.57
    },
    {
        productName: "Skirt",
        todayProductCount: 79,
        todaySalesAmount: 35017.00,
        yesterdayProductCount: 71,
        yesterdaySalesAmount: 29150.22,
        last7dProductCount: 201,
        last7dSalesAmount: 82523.86
    },
    {
        productName: "Flip flop",
        todayProductCount: 92,
        todaySalesAmount: 153298.73,
        yesterdayProductCount: 64,
        yesterdaySalesAmount: 106642.59,
        last7dProductCount: 215,
        last7dSalesAmount: 358252.45
    },
    {
        productName: "Adapters",
        todayProductCount: 89,
        todaySalesAmount: 213489.64,
        yesterdayProductCount: 78,
        yesterdaySalesAmount: 187103.28,
        last7dProductCount: 232,
        last7dSalesAmount: 556512.32
    }
]

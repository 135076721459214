import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories";
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { StandardScheduleInformationContentComponent } from "./standard-schedule-information-content.component";
import { StandardScheduleInformationHomeComponent } from "./standard-schedule-information-home/standard-schedule-information-home.component";
import { ProductListTableComponent } from "./standard-schedule-information-table/product-list-table/product-list-table.component";
import { ScheduleQueueTableComponent } from "./standard-schedule-information-table/schedule-queue-table/schedule-queue-table.component";
import { ScheduleQueueTaskTableComponent } from "./standard-schedule-information-table/schedule-queue-task-table/schedule-queue-task-table.component";
import { StandardScheduleInformationTableComponent } from "./standard-schedule-information-table/standard-schedule-information-table.component";
import { DataGridRowNumberModule } from "../../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductListTableComponent,
        ScheduleQueueTableComponent,
        ScheduleQueueTaskTableComponent,
        StandardScheduleInformationContentComponent,
        StandardScheduleInformationHomeComponent,
        StandardScheduleInformationTableComponent
    ],
    exports: [
        StandardScheduleInformationContentComponent
    ],
    imports: [
        ButtonsModule,
        CollapseModule,
        CommonModule,
        DataGridRowNumberModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DynamicRoutingModule,
        FocusingModule,
        FormsModule,
        LoadingSpinnerModule,
        MatIconModule,
        MatMenuModule,
        NgbModule,
        NgbNavModule,
        OopsCategoriesModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        UiModule
    ]
})
export class StandardScheduleInformationContentModule {}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductSchedulePeriodCountModel } from "../../models/schedule-period-model/product-schedule-period-count.model";
import { ProductScheduleModel } from "../../models/schedule-period-model/product-schedule.model";

@Injectable({
    providedIn: 'root'
})
export class SchedulePeriodService extends ApiServiceBase {
    private readonly PATH = "/schedule/scheduleperiods";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }
    public getAll(): Observable<ProductSchedulePeriodCountModel[]> {
        return this.httpGet<ProductSchedulePeriodCountModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
    public getProductSchedule(scheduleVersionId: string): Observable<ProductScheduleModel[]> {
        return this.httpGet<ProductScheduleModel[]>(this.PATH + '/product-schedule/' + scheduleVersionId, null)
            .pipe(
                map(res => res.body)
            )
    }
}
export class OrderDetailProductChangeAvailabilityProductDetailView {
    aircraftType: string;
    aircraftConfiguration: string;
    marketingProductNumber: string;
    operatingProductNumber: string;
    departureAirportCode: string;
    departureAirportName: string;
    arrivalAirportCode: string;
    arrivalAirportName: string;
    irregularity: string;
    originWeather: string;
    destinationWeather: string;
    parentProductId: string;
    departureTerminal: string;
    arrivalTerminal: string;
    departureGate: string;
    arrivalGate: string;
}
import { ProductAttributeCommandModel } from "./product-attribute";
import { ProductHashTagCommandModel } from "./product-hashtag";
import { ProductNumberCommandModel } from "./product-number";
import { ProductRestrictionLocationCommandModel, ProductRestrictionOrganisationCommandModel, ProductRestrictionProductCommandModel, ProductRestrictionProductNumberCommandModel, ProductRestrictionRouteCommandModel } from "./product-restriction";
import { ProductValidityCommandModel } from "./product-validity";

export abstract class ProductSearchCommandModel {
    public productCategoryCode: string;
    public productGroupCode: string;
    public productTypeCode: string;
    public productName: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public filterUserAccountId: string;
    public statusCode: string;
    public productHashTags: ProductHashTagCommandModel[];
    public productNumbers: ProductNumberCommandModel[];
    public productRestrictionProducts: ProductRestrictionProductCommandModel[];
    public productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[];
    public productRestrictionRoutes: ProductRestrictionRouteCommandModel[];
    public productRestrictionLocations: ProductRestrictionLocationCommandModel[];
    public productValidities: ProductValidityCommandModel[];
    public productAttributes: ProductAttributeCommandModel[];
    public productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[];
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleDistributionComponent } from './schedule-categories-content/schedule-distribution/schedule-distribution.component';
import { ScheduleQueueComponent } from './schedule-categories-content/schedule-queue/schedule-queue.component';
import { StandardScheduleInformationComponent } from './schedule-categories-content/standard-schedule-information/standard-schedule-information.component';
import { ScheduleContentComponent } from './schedule-content/schedule-content.component';
import { ScheduleComponent } from './schedule.component';
import { SchedulePeriodComponent } from './schedule-categories-content/schedule-period/schedule-period.component';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: ScheduleComponent,
        children: [
            {
                path: 'schedule-queue',
                component: ScheduleQueueComponent,
            },
            {
                path: 'standard-schedule-information',
                component: StandardScheduleInformationComponent
            },
            {
                path: 'schedule-distribution',
                component: ScheduleDistributionComponent
            },
            {
                path: 'schedule-period',
                component: SchedulePeriodComponent
            }            
        ]
    },
    {
        path: 'home',
        component: ScheduleContentComponent,
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ScheduleRoutingModule { }
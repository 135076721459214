import {
    ProductRestrictionProductCommandModel,
    ProductRestrictionProductNumberCommandModel,
    ProductRestrictionLocationCommandModel,
    ProductRestrictionRouteCommandModel,
    ProductRestrictionVehicleCommandModel
} from '../../../../../core/models/product-model/product-base-model/product-restriction'

export class ProductRestrictionCommandView {
    public no: number;
    public type: string;
    public productRestrictionProductCommand: ProductRestrictionProductCommandModel;
    public productRestrictionProductNumberCommand: ProductRestrictionProductNumberCommandModel;
    public productRestrictionRouteCommand: ProductRestrictionRouteCommandModel;
    public productRestrictionLocationCommand: ProductRestrictionLocationCommandModel;
    public productRestrictionVehicleCommand: ProductRestrictionVehicleCommandModel;
}
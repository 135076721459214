import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { FormOfPaymentIssuerModel } from "../../models/form-of-payment-model";

@Injectable({
    providedIn: 'root'
})
export class FormOfPaymentIssuerService extends ApiServiceBase {
    private readonly PATH = '/system/FormOfPaymentIssuers';
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }
    public getBy(formOfPaymentCode: string): Observable<FormOfPaymentIssuerModel[]> {
        let params = new HttpParams()
            .set('formOfPaymentCode', formOfPaymentCode ?? '')
        return this.httpGet<FormOfPaymentIssuerModel[]>(this.PATH, params)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Injectable } from "@angular/core"

import { Select2Themes } from "../../product-eligible-restricted/shared/select2-options.service";
import { Select2Option } from "../../shared/attribute-and-rule/views/select2.option";
import { PointOfSalesAjaxService } from "./point-of-sales-ajax.service";

@Injectable({
    providedIn: 'root',
})
export class Select2OptionsService {
    
    constructor(private pointOfSalesAjaxService: PointOfSalesAjaxService) {}

    public getSelect2OrganisationTypeOrganisationTypeOption() {
        let options = new Select2Option('<Type>');
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.pointOfSalesAjaxService.toOrganisationTypeAjaxOption();
        options.minimumInputLength = 1;
        options.allowClear = true;
        return options;
    }

    public getSelect2OrganisationTypeOrganisationRoleOption() {
        let options = new Select2Option('<Role>');
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.pointOfSalesAjaxService.toOrganisationRoleAjaxOption();
        options.minimumInputLength = 1;
        options.allowClear = true;
        return options;
    }

    public getSelect2OrganisationTypeOrganisationGroupOption() {
        let options = new Select2Option('<Group>');
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.pointOfSalesAjaxService.toOrganisationGroupAjaxOption();
        options.minimumInputLength = -1;
        options.allowClear = true;
        return options;
    }

    public getSelect2OrganisationTypeOrganisationIdOption() {
        let options = new Select2Option('<Organisation>');
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.pointOfSalesAjaxService.toOrganisationIdAjaxOption();
        options.minimumInputLength = -1;
        options.allowClear = true;
        return options;
    }
}
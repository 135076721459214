import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { ConditionReferenceService, UnitReferenceService } from "src/app/core/services/system-services";
import { PricingDetailConstant } from "src/app/modules/pricing/rules/price-rule-detail/shared/pricing-detail.constant";
import { ConditionReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import {
    ConditionOption,
    TypeOption,
    UnitTypeOption
} from "../shared/options/select2-price-dimension.options";
import { PricingConverter } from "src/app/modules/pricing/shared/pricing.converter";
import { PriceModel } from "src/app/modules/pricing/prices/shared/models";


@Component({
    selector: 'op-price-conditions-details-attributes',
    templateUrl: './attributes.component.html',
    providers: [
        PricingConverter
    ]
})
export class AttributesComponent extends OopsComponentFormBase implements OnChanges {
    typeOption = TypeOption;
    conditionOption = ConditionOption;
    unitTypeOption = UnitTypeOption;

    conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    unitReferences$ = this.unitReferenceService.getUnitReferences();

    @Input() price: PriceModel;
    @Input() disabled: boolean = false;

    @Output() unitCodeChange = new EventEmitter<string>();

    constructor(fb: UntypedFormBuilder,
        private conditionReferenceService: ConditionReferenceService,
        private unitReferenceService: UnitReferenceService) {
        super(fb);
        this.getConditionReferences();
    }

    private getConditionReferences() {
        this.conditionReferenceService.getConditionsByCodes([
            PricingDetailConstant.IS_OPERATOR_CODE
        ]).subscribe(
            (conditionReferences: ConditionReferenceModel[]) => {
                this.conditionReferences$.next(conditionReferences);
            }
        );
    }

    ngOnChanges() {
        this.initForm();
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            unitCode: new UntypedFormControl(this.price?.unitCode, Validators.required)
        })
        this.setupSecurityDisabled();
    }

    private setupSecurityDisabled() {
        if (this.disabled == true) {
            this.formGroup.disable();
            return;
        }
        this.formGroup.enable();
    }

    public getValues(price: PriceModel): PriceModel {
        this.startProcessing();
        if (!this.validForm()) {
            return null;
        }

        price.unitCode = this.formGroup.get('unitCode').value;
        return price;
    }

    public validateFormGroup() {
        this.startProcessing();
    }

    onUnitTypeChange(value: string | string[]) {
        let unitCode = value instanceof Array ? value[0] : value;
        this.formGroup.get('unitCode').patchValue(unitCode);
        this.unitCodeChange.emit(unitCode);
    }

    public resetForm() {
        this.price = null;
        this.completeProcessing();
        this.initForm();
        this.unitCodeChange.emit(null);
    }

    public refresh() {
        this.formGroup.get('unitCode').patchValue(this.price.unitCode);
        this.unitCodeChange.emit(this.price.unitCode);
    }
}
export class ProductInventoryLedgerCommandModel {
    public productInventoryLedgerId: string;
    public orderServiceItemId: string;
    public ledgerInventoryTypeCode: string;
    public inventoryAdjustmentTypeCode: string;
    public ledgerTransactionCode: string;
    public availableUnits: Number;
    public adjustment: Number;
    public comment: string;
    public voidBy: string;
    public voidDateTime:Date;
    public availableUnitsPrevious: Number;
}       

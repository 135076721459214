export const SecurityAuditItems = [
    {
        name: 'Security Audit Name 1',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    },
    {
        name: 'Security Audit Name 2',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    },
    {
        name: 'Security Audit Name 3',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    }
]
import { UntypedFormControl, Validators } from "@angular/forms";
import { Globals } from "src/app/app.global-variable";

export const SecurityCodeDetailFormOption = {
    securityCode: new UntypedFormControl(null, [Validators.required, Validators.pattern(Globals.CODE_PATTERN)]),
    securityName: new UntypedFormControl(null, Validators.required),
    limitationName: new UntypedFormControl(null),
    isCrudFlag: new UntypedFormControl(null),
    securityOverrideFlag: new UntypedFormControl(null),
    hasLimitationFlag: new UntypedFormControl(null),
    status: new UntypedFormControl(null, Validators.required),
    commitByName: new UntypedFormControl({ value: null, disabled: true }),
    commitDateTime: new UntypedFormControl({ value: null, disabled: true })
}
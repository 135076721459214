import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild, ElementRef, SimpleChange, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
    select2AttributeRoute, select2AttributeRouteInherit, select2AttributeInherit, select2RouteOption,
    select2RouteOptionInherit, select2ChoiceMultiple, select2Attribute, select2Dimension, select2AttributeDisabled
} from './attribute-configuration';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { field } from 'src/app/modules/product-management/product-categories-content/shared/constant/attribute.constant';

import { AttributeRuleConstant, RestrictionConstant } from 'src/app/core/components/rules-config/shared/constants';

import { AttributeMapperService } from '../../../../../shared/mapper/attribute-mapper.service';
import { DomainAttributeService, LocationReferenceService } from 'src/app/core/services/airline-services';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { AttributeRuleView } from './attribute-route.view';
import { RestrictionView } from 'src/app/core/components/rules-config/shared/views/restriction.view';
import { ProductPointViewModel } from 'src/app/core/models/product-model/transport-model';
import { DomainAttributeModel, LocationReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { cloneDeep } from "lodash";

declare var $: any

@Component({
    selector: 'op-attribute',
    templateUrl: './attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AttributeMapperService]
})
export class AttributeComponent {
    @Input() productTypeCode: string;
    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() searchMode: boolean = false;
    @Output() routeValid = new EventEmitter<boolean>();

    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    public readonly ERROR_DUPLICATE = 'Duplicate row.';
    public readonly ERROR_ROUTE_REQUIRED = 'Route is required.';
    public readonly ERROR_ROUTE_INVALID = 'Must select at least 2 route.';
    public readonly ERROR_ATTRIBUTE_REQUIRED = 'Attribute is required.';
    public readonly DISTRIBUTE_SCHEDULE_CODE = 'DISTRIBUTESCHEDULE';

    @ViewChild("routeSelect2") routeSelect2: ElementRef;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public focusing: boolean = false;
    actionForm: UntypedFormGroup;
    inheritForm: UntypedFormGroup;

    public attributeRouteOption: any = cloneDeep(select2AttributeRoute);
    public attributeRouteInheritOption: any = cloneDeep(select2AttributeRouteInherit);
    public routeOption: any = cloneDeep(select2RouteOption);
    public routeOptionInherit: any = cloneDeep(select2RouteOptionInherit);
    public attributeOption: any = cloneDeep(select2Attribute);
    public attributeInheritOption: any = cloneDeep(select2AttributeInherit);
    public attributeDisabledOption: any = cloneDeep(select2AttributeDisabled);
    public choiceOption: any = cloneDeep(select2Dimension);
    public choiceMultipleOption: any = cloneDeep(select2ChoiceMultiple);
    public attributeRouteView: AttributeRuleView;
    public routeValue: string[] = null;
    public routeValueInherit: string[] = null;
    public routeDataList: Select2Data[] = new Array();
    public routeDataInheritList: Select2Data[] = new Array();
    public routeIdList: Select2Data[] = new Array();
    public routeValidate: boolean = true;
    public routeCountValidate: boolean = true;
    public hideInherit: boolean = true;
    public draftFlag: boolean = true;
    public limitationFlag: boolean = false;
    public finalFlag: boolean = false;
    public resetRouteChoice: boolean = false;

    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public productAttributes: ProductAttributeViewModel[];
    public productAttributesInherit: ProductAttributeViewModel[];

    constructor(
        private fb: UntypedFormBuilder,
        private locationReferenceService: LocationReferenceService,
        private domainAttributeService: DomainAttributeService,
        private attributeMapperService: AttributeMapperService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.clearForms();
        this.clearInheritForms();
        this.routeOption.ajax = this.select2AjaxSearchByLocationCode();
        this.routeOption.templateSelection = this.templateSelection.bind(this);
        this.routeOptionInherit.templateSelection = this.templateSelection.bind(this);
        this.attributeRouteOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeRouteOption.templateSelection = this.templateResultAttribute.bind(this);
        this.attributeRouteInheritOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeRouteInheritOption.templateSelection = this.templateResultAttribute.bind(this);
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (!this.searchMode) {
            this.getAttributeByProductType();
        }
        else {
            this.getDomainAttributeSearch();
        }
    }

    public getAttributeByProductType() {
        if (this.productTypeCode) {
            this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, this.productTypeCode)
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        let filterProductFlag = this.getConditionFlag(responses);
                        this.domainAttribute$.next(filterProductFlag);

                        if (this.newProduct) {
                            this.displayShowOnNewFlag();
                        }
                        else {
                            this.addDataInheritToFormGroup();
                            this.addDataToFormGroup();
                        }
                    }
                )
        }
    }

    private getDomainAttributeSearch() {
        this.domainAttribute$ = this.domainAttributeSearch$;
    }

    add() {
        if (this.forms.controls.length != 0) {
            let isDuplicate = this.findDuplicateAllRow();
            let isInvalid = this.findInvalidRows();
            if (isDuplicate || isInvalid) {
                return;
            }
        }
        this.forms.push(this.createFormGroupEmpty(null));
        this.filterLimitationFieldData();
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    get inheritControlLength(): number {
        if (this.inheritforms.controls) {
            return this.inheritforms.controls.length;
        }
        return 0;
    }

    delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
    }

    selectChange(value: string, ctl, name: string) {
        ctl.get(name).setValue(value);
    }

    private templateResultAttribute(item): JQuery<HTMLElement> {
        var $state = $(
            '<span>' + item.text + ' <i class="fal fa-cube"></i></span>'
        );
        return $state;
    }

    public addRestrictionViewToRoute(productRestrictions: RestrictionView[], draftFlag: boolean, finalFlag: boolean) {
        this.resetRouteChoiceData();
        this.clearValidate();
        var locationRestrictions = productRestrictions.filter(x => x.type == RestrictionConstant.TYPE_LOCATION);
        this.clearAllData();
        this.draftFlag = draftFlag;
        this.finalFlag = finalFlag;
        for (let route of locationRestrictions) {
            this.addToValue(route.productRestrictionLocationView.locationId);
            this.addToDataList(route.productRestrictionLocationView.locationId,
                route.productRestrictionLocationView.locationName);
            this.addToIdList(route.productRestrictionLocationView.productRestrictionLocationId,
                route.productRestrictionLocationView.locationId);
        }
        this.resetRouteChoice = false;
        this.changeDetectionRef.detectChanges();
    }

    public addRestrictionInheritViewToRoute(productPointId: string, productPointIdParent: string, productPoints: ProductPointViewModel[], isRouteLevel: boolean) {
        this.hideInherit = true;
        this.clearAllInheritData();
        if (productPointId && productPoints?.length) {
            let locationData: Select2Data[] = new Array();
            let locationFrom = new Select2Data();
            let locationTo = new Select2Data();
            if (isRouteLevel) {
                locationFrom = this.getLocation(productPointId, productPoints);
                locationTo = this.getLocationToRouteLevel(productPointId, productPoints);
            }
            else {
                locationFrom = this.getLocation(productPointIdParent, productPoints);
                locationTo = this.getLocation(productPointId, productPoints);
            }
            locationData.push(locationFrom);
            locationData.push(locationTo);
            for (let location of locationData) {
                this.addToDataInheritList(location.id, location.text);
                this.addToValueInherit(location.id);
                this.addToIdList(location.id, location.id);
            }
            if (this.routeDataInheritList?.length) {
                this.hideInherit = false;
            }
            else {
                this.hideInherit = true;
            }
        }
        this.changeDetectionRef.detectChanges();
    }

    private clearAllData() {
        this.routeValue = [];
        this.routeDataList = [];
        this.routeIdList = [];
        this.finalFlag = false;
    }

    private clearAllInheritData() {
        this.routeValueInherit = [];
        this.routeDataInheritList = [];
    }

    private addToValue(id: string) {
        this.routeValue.push(id);
    }

    private addToValueInherit(id: string) {
        this.routeValueInherit.push(id);
    }

    private addToDataList(id: string, name: string) {
        let routeData = new Select2Data();
        routeData.id = id;
        routeData.text = name;
        this.routeDataList.push(routeData);
    }

    private addToDataInheritList(id: string, name: string) {
        let routeData = new Select2Data();
        routeData.id = id;
        routeData.text = name;
        this.routeDataInheritList.push(routeData);
    }

    private addToIdList(dbId: string, id: string) {
        let dbIdData = new Select2Data();
        dbIdData.id = dbId;
        dbIdData.text = id;
        this.routeIdList.push(dbIdData)
    }

    public getDataRoute() {
        var data = $(this.routeSelect2.nativeElement).select2('data');
        return data;
    }

    private templateSelection(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockChoice(id) && !this.draftFlag) {
            $(container).addClass('locked-tag');
            tag.locked = true;
        }
        else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        return tag.text;
    }

    private lockChoice(id): boolean {
        if (this.routeIdList) {
            var filter = this.routeIdList.filter(x => x.text == id);
            if (filter.length > 0) {
                return true;
            }
        }
        return false;
    }

    public routeValidation(rootWithProductPoint: boolean, newProduct: boolean, draftFlag: boolean, sendOutput: boolean = false): boolean {
        this.routeValidate = true;
        this.routeCountValidate = true;
        if ((!rootWithProductPoint && !newProduct) && !draftFlag) {
            return true;
        }
        this.routeValidate = this.validateRequiredAndCount(sendOutput);
        this.changeDetectionRef.detectChanges();
        return this.routeValidate;
    }

    public validateRequiredAndCount(sendOutput: boolean = false): boolean {
        this.routeValidate = true;
        this.routeCountValidate = true;
        if (!this.routeValue?.length) {
            this.routeValidate = false;
        } else if (this.routeValue?.length < 2) {
            this.routeValidate = false;
            this.routeCountValidate = false;
        }
        if (sendOutput) {
            this.routeValid.emit(this.routeValidate);
        }
        return this.routeValidate;
    }

    private select2AjaxSearchByLocationCode() {
        return {
            transport: (params, success, failure) => {
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, null)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: this.createValidLocationData(locations)
                        })
                    });
            }
        }
    }

    private createValidLocationData(locations: LocationReferenceModel[]): Select2Data[] {
        let locationsValid: LocationReferenceModel[];
        if (this.routeValue) {
            locationsValid = this.filterDuplicate(locations);
        }
        else {
            locationsValid = locations;
        }
        if (locationsValid?.length) {
            return locationsValid.map(d => new Select2Data(d.locationId, d.locationName));
        }
    }

    private filterDuplicate(locations: LocationReferenceModel[]): LocationReferenceModel[] {
        let locationsValid: LocationReferenceModel[] = new Array();
        for (let location of locations) {
            var filterDuplicate = this.routeValue.filter(x => x == location.locationId);
            if (!filterDuplicate?.length) {
                locationsValid.push(location);
            }
        }
        return locationsValid;
    }

    private getLocation(productPointId: string, productPoints: ProductPointViewModel[]): Select2Data {
        var locationFrom = new Select2Data();
        let locationPoint = this.getLocationPointById(productPointId, productPoints);
        if (locationPoint) {
            locationFrom.id = locationPoint.locationId;
            locationFrom.text = locationPoint.locationName;
        }
        return locationFrom;
    }

    private getLocationToRouteLevel(productPointId: string, productPoints: ProductPointViewModel[]): Select2Data {
        var locationTo = new Select2Data();
        let locationPointFrom = this.getLocationPointById(productPointId, productPoints);
        if (locationPointFrom) {
            let sortSequenceFrom = locationPointFrom.sortSequence;
            let sortSequenceTo = sortSequenceFrom + 1;
            let locationPointTo = this.getLocationPointBySortSequence(sortSequenceTo, productPoints);
            locationTo.id = locationPointTo.locationId;
            locationTo.text = locationPointTo.locationName;
        }
        return locationTo;
    }

    private getLocationPointById(productPointId: string, productPoints: ProductPointViewModel[]): ProductPointViewModel {
        let filterProductPoints = productPoints.filter(x => x.productPointId == productPointId);
        if (filterProductPoints?.length) {
            return filterProductPoints[0];
        }
    }

    private getLocationPointBySortSequence(sortSequence: number, productPoints: ProductPointViewModel[]): ProductPointViewModel {
        let filterProductPoints = productPoints.filter(x => x.sortSequence == sortSequence);
        if (filterProductPoints?.length) {
            return filterProductPoints[0];
        }
    }

    private getConditionFlag(response: DomainAttributeModel[]): DomainAttributeModel[] {
        if (response) {
            return response.filter(x => x.productFlag == true && x.scheduleProductFlag == true)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));;
        }
    }

    public attributeChange(value: string | string[], ctl: AbstractControl) {
        ctl.get(field.ATTRTYPE_CODE).setValue(value);
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get(field.CHOICE_VALUE).setValue(null);
                ctl.get(field.DIMENSIONUNIT_CODE).setValue(attributeFilter[0].dimensionUnitCode);
                ctl.get(field.MIN).setValue(this.attributeMapperService.getMinimunValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                ctl.get(field.MAX).setValue(this.attributeMapperService.getMaximumValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                if (attributeFilter[0].dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
                    this.setChoiceData(ctl, attributeFilter[0].attributeChoices);
                    this.setSingleOrMultipleChoice(ctl, attributeFilter[0].multipleChoiceFlag);
                    this.setDefaultChoiceValue(ctl, attributeFilter[0]);
                }
                this.setDefaultValue(ctl, attributeFilter[0]);
            } else {
                this.setEmptySingleDropdown(ctl);
            }
        }
    }

    private setChoiceData(ctl: AbstractControl, attributeChoices: AttributeChoiceModel[]) {
        let choiceData = this.convertToSelect2Data(attributeChoices);
        ctl.get(field.CHOICE_DATA).setValue(choiceData);
    }

    private setSingleOrMultipleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean) {
        ctl.get(field.MULCHOICE_FLAG).setValue(multipleChoiceFlag);
        if (multipleChoiceFlag) {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceMultipleOption);
        }
        else {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceOption);
        }
    }

    private setDefaultChoiceValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        let filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.assignDefaultChoice(ctl, attribute.multipleChoiceFlag, filterDefaultChoice);
        }
        else {
            this.assignDefaultSingleChoice(ctl, attribute.multipleChoiceFlag, attribute.attributeChoices);
        }
    }

    private setDefaultValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        if ((attribute.dimensionUnitCode == OopsChoiceValueConstant.NUMBER ||
            attribute.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE)
            && attribute.defaultValue) {
            ctl.get(field.CHOICE_VALUE).setValue(attribute.defaultValue);
        }
    }

    private assignDefaultChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (multipleChoiceFlag) {
            let dataMultiple: string[] = new Array();
            dataMultiple.push(chloces[0].attributeChoiceId);
            ctl.get(field.CHOICE_VALUE).setValue(dataMultiple);
        }
        else {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private assignDefaultSingleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (!multipleChoiceFlag) {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    private setEmptySingleDropdown(ctl: AbstractControl) {
        ctl.get(field.CHOICE_DATA).setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get(field.MULCHOICE_FLAG).setValue(false);
    }

    public findDuplicateAllRow(): boolean {
        let returnValue: boolean = false;
        let duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);

            for (let i = 0; i <= this.forms.controls.length - 1; i++) {
                let ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                }
                else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectionRef.detectChanges();
        return duplicate;
    }

    private findInvalidRows(): boolean {
        for (let ctl of this.forms.controls) {
            if (ctl.status == AttributeRuleConstant.STATUS_INVALID) {
                return true;
            }
        }
        return false;
    }

    private findDuplicate(ctlCheck, ctlIndex) {
        for (let i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                let ctl = this.forms.controls[i];
                if (this.equalValue(ctl, ctlCheck)) {
                    return true;
                }
            }
        }
        return false;
    }

    private equalValue(ctl, ctlCheck): boolean {
        if (ctl.value.productAttributeTypeCode == ctlCheck.value.productAttributeTypeCode) {
            if (ctl.value.multipleChoiceFlag) {
                if (this.checkArrayEqual(ctl.value.choiceValue, ctlCheck.value.choiceValue)) {
                    return true;
                }
            }
            else {
                if (ctl.value.choiceValue == ctlCheck.value.choiceValue) {
                    return true;
                }
            }
        }
        return false;
    }

    private updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get(field.DUPLICATE).setValue(null);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.required);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
        else {
            formCurrent.get(field.DUPLICATE).setValue(false);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.nullValidator);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

    public checkHiddenDelete(ctl): boolean {
        if (!this.searchMode) {
            if (this.checkIsRequiredFlag(ctl.get(field.ATTRTYPE_CODE).value) || this.isLimitation(ctl)) {
                return true;
            }
        }
        return false;
    }

    public isLimitation(ctrl: AbstractControl): boolean {
        if (this.limitationFlag != true) {
            return false;
        }
        return ctrl.get(field.ATTRTYPE_CODE).value == this.DISTRIBUTE_SCHEDULE_CODE;
    }

    public clearForms() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    private clearInheritForms() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    public addDataToFormGroup() {
        this.clearForms();
        if (!this.productAttributes) {
            return;
        }

        let displaySequenceAdded: number[] = new Array();
        for (let productAttribute of this.productAttributes) {
            let filterDisplaySequence = displaySequenceAdded.filter(x => x == productAttribute.displaySequence);
            if (filterDisplaySequence.length == 0) {
                if (this.canGetProductAttributeType(productAttribute)) {
                    this.assignDataToForm(productAttribute);
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get(field.SUBMITTED).setValue(true);
                }
                displaySequenceAdded.push(productAttribute.displaySequence);
            }
        }
        this.filterLimitationFieldData();
        this.changeDetectionRef.detectChanges();
    }

    private filterLimitationFieldData() {
        if (this.containsLimitationFields()) {
            for (let ctrl of this.forms.controls) {
                if (this.isLimitation(ctrl) == false) {
                    let dataSubject = ctrl.get(field.ATTRTYPE_DATA).value;
                    let newValue = dataSubject.value?.filter(val => val.attributeTypeCode != this.DISTRIBUTE_SCHEDULE_CODE);
                    dataSubject.next(newValue);
                }
            }
        }
    }

    private containsLimitationFields() {
        return this.forms.controls.filter(ctrl => this.isLimitation(ctrl))?.length ||
            this.inheritforms.controls.filter(ctrl => this.isLimitation(ctrl))?.length;
    }

    private assignDataToForm(productAttribute: ProductAttributeViewModel) {
        if (productAttribute.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleData(productAttribute);
            let choiceArrayDBId = this.createChoiceDBIdData(productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, choiceMultiple, choiceArrayDBId));
        } else {
            this.forms.push(
                this.createFormGroupWithData(productAttribute));
        }
    }

    private addDataInheritToFormGroup() {
        this.clearInheritForms();
        if (!this.productAttributesInherit) {
            return;
        }

        this.productAttributesInherit.sort((a, b) => (a.inheritAttribute > b.inheritAttribute ? -1 : 1));

        let displayDataAdded: Select2Data[] = new Array();
        for (let productAttributeInherit of this.productAttributesInherit) {
            var filterDisplaySequence = displayDataAdded.filter(x => x.id == productAttributeInherit.hierarchyKey && x.text == productAttributeInherit.displaySequence.toString());
            if (filterDisplaySequence.length == 0) {
                this.assignDataToInheritForm(productAttributeInherit);
                var dataAdded = new Select2Data(productAttributeInherit.hierarchyKey,
                    productAttributeInherit.displaySequence.toString());
                displayDataAdded.push(dataAdded);
            }
        }
        this.changeDetectionRef.detectChanges();
    }

    private assignDataToInheritForm(productAttributeInherit: ProductAttributeViewModel) {
        if (productAttributeInherit.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleInheritDataToForm(productAttributeInherit);
            let choiceArrayDBId = this.createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit);
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag,
                    choiceMultiple, choiceArrayDBId, true));
        } else {
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag, null, null, true));
        }
    }

    private createFormGroupEmpty(productAttributeTypeReference) {
        let form = {};
        if (productAttributeTypeReference) {
            form = this.assignAttributeToEmptyForm(
                productAttributeTypeReference.attributeTypeCode,
                productAttributeTypeReference.dimensionUnitCode,
                productAttributeTypeReference.multipleChoiceFlag);
        }
        else {
            form = this.assignAttributeToEmptyForm(null, null, false);
        }
        let f = this.fb.group(form);
        return f;
    }

    private createFormGroupWithData(productAttribute: ProductAttributeViewModel, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {};
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [productAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.SUBMITTED] = [true, [Validators.nullValidator]];
        form[field.REQUIRED] = [this.checkIsRequiredFlag(productAttribute.attributeTypeCode) ? true : false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        this.assignValueToAttribute(form, productAttribute, disabled);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceData(form, productAttribute);
            this.assignValueToChoiceValue(productAttribute.multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        if (this.checkIsRequiredFlag(productAttribute.attributeTypeCode)) {
            this.setDataAttributeTypeRequireFlag(f, productAttribute.attributeTypeCode);
        }
        return f;
    }

    private createFormGroupWithInheritData(productAttribute,
        multipleChoiceFlag: boolean, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {}
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        this.assignValueToAttributeInherit(form, productAttribute);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceInherit(form, choiceArrayDBId, productAttribute);
            this.assignValueToChoiceValue(multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        return f;
    }

    private displayShowOnNewFlag() {
        if (this.domainAttribute$.value) {
            this.clearForms();

            let required = this.domainAttribute$.value.filter(x => x.requiredFlag == true);
            this.display(required);

            let showOnNew = this.domainAttribute$.value.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            this.display(showOnNew);
            this.changeDetectionRef.detectChanges();
        }
    }

    display(domainAttributeRequire: DomainAttributeModel[]) {
        for (let productAttributeTypeReference of domainAttributeRequire) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(productAttributeTypeReference));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            if (productAttributeTypeReference.requiredFlag) {
                this.setDataAttributeTypeRequireFlag(formCurrent, productAttributeTypeReference.attributeTypeCode);
            }
            this.attributeChange(productAttributeTypeReference.attributeTypeCode, formCurrent);
        }
    }

    private checkIsRequiredFlag(productAttributeTypeCode: string) {
        if (productAttributeTypeCode && this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x =>
                x.attributeTypeCode == productAttributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private canGetProductAttributeType(productAttribute: ProductAttributeViewModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }
        return returnValue;
    }

    private assignValueToAttribute(form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$), [Validators.nullValidator]];
    }

    private assignValueToAttributeInherit(form: any, productAttribute: ProductAttributeViewModel) {
        var attributeDataArray: Select2Data[] = new Array();
        var attributeData = new Select2Data();
        attributeData.id = productAttribute.attributeTypeCode;
        attributeData.text = productAttribute.attributeTypeName;
        attributeDataArray.push(attributeData);
        form[field.ATTR_DATA] = [attributeDataArray, [Validators.nullValidator]];
    }

    private createDomainAttributeNotRequire(domainAttributeBliding$: BehaviorSubject<DomainAttributeModel[]>): BehaviorSubject<DomainAttributeModel[]> {
        if (!domainAttributeBliding$.value) {
            return new BehaviorSubject<DomainAttributeModel[]>(null);
        }
        if (!this.searchMode) {
            let filterNoRequireFlag = domainAttributeBliding$.value.filter(x => x.requiredFlag != true);
            let domainAttributeNoRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
            domainAttributeNoRequireFlag$.next(filterNoRequireFlag);
            return domainAttributeNoRequireFlag$;
        }
        return domainAttributeBliding$;
    }

    private createChoiceMultipleData(productAttribute: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);
        }
        return choiceMultiple;
    }

    private createChoiceMultipleInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);;
        }
        return choiceMultiple;
    }

    private createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributesInherit(productAttributeInherit: ProductAttributeViewModel): ProductAttributeViewModel[] {
        return this.productAttributesInherit.filter(x => x.hierarchyKey == productAttributeInherit.hierarchyKey
            && x.displaySequence == productAttributeInherit.displaySequence);
    }

    private assignValueToChoiceInherit(form: any, choiceArrayDBId: Select2Data[], productAttribute: ProductAttributeViewModel) {
        let choiceDataArray: Select2Data[] = new Array();
        if (choiceArrayDBId) {
            choiceDataArray = this.createChoiceMultipleInheritData(choiceArrayDBId);
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        }
        else {
            choiceDataArray = this.createChoiceSingleInheritData(productAttribute);
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        form[field.CHOICE_DATA] = [choiceDataArray, [Validators.nullValidator]];
    }

    private createChoiceMultipleInheritData(choiceArrayDBId: Select2Data[]): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        if (choiceArrayDBId) {
            for (let choice of choiceArrayDBId) {
                choiceData = new Select2Data();
                choiceData.id = choice.text;
                var filter = this.productAttributesInherit.filter(x => x.attributeChoiceId == choice.text);
                if (filter.length != 0) {
                    choiceData.text = filter[0].attributeChoiceName;
                    choiceDataArray.push(choiceData);
                }
            }
        }
        return choiceDataArray;
    }

    private createChoiceSingleInheritData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        choiceData = new Select2Data();
        choiceData.id = productAttribute.attributeChoiceId;
        choiceData.text = productAttribute.attributeChoiceName;
        choiceDataArray.push(choiceData);
        return choiceDataArray;
    }

    private createChoiceDBIdData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private assignValueToChoiceData(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                let choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            }
            else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean,
        choiceMultipleValue: string[], form: any, choiceArrayDBId: Select2Data[],
        productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_ID] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
        }
        else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: productAttribute.attributeChoiceId, disabled: disabled }, [Validators.required]];
        }
    }

    private setDataAttributeTypeRequireFlag(formCurrent: AbstractControl, attributeTypeCode: string) {
        formCurrent.get(field.REQUIRED).setValue(true);
        let domainAttributeRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        let filterAttributeType = this.domainAttribute$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
        domainAttributeRequireFlag$.next(filterAttributeType);
        formCurrent.get(field.ATTRTYPE_DATA).setValue(domainAttributeRequireFlag$);
    }

    public findRequireAttribute(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectionRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.value.required) {
                    if ((ctl.value.multipleChoiceFlag && !ctl.value.choiceValue?.length) ||
                        (!ctl.value.choiceValue)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public rowInValid(ctl: AbstractControl): boolean {
        return (ctl.get(field.CHOICE_VALUE).invalid || ctl.get(field.DUPLICATE).invalid) && ctl.get(field.SUBMITTED).value == true;
    }

    dataChange(value: string | string[], ctl) {
        ctl.get(field.CHOICE_VALUE).setValue(value);
    }

    private assignAttributeToEmptyForm(attributeTypeCode: string, dimensionUnitCode: string, multipleChoiceFlag: boolean) {
        let form = {};
        form[field.SUBMITTED] = [null, [Validators.nullValidator]];
        form[field.REQUIRED] = [null, [Validators.nullValidator]];
        form[field.CHOICE_ID] = [null, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [attributeTypeCode, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$), [Validators.nullValidator]];
        form[field.DIMENSIONUNIT_CODE] = [dimensionUnitCode, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.CHOICE_VALUE] = [null, [Validators.required]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        }
        else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        return form;
    }

    public clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectionRef.detectChanges();
    }

    public clearFormNew() {
        this.clearAllData();
        this.clearAllInheritData();
        this.clearForm();
        this.clearInheritForms();
        this.hideInherit = true;
        this.productTypeCode = null;
        this.routeValidate = true;
        this.routeCountValidate = true;
        this.draftFlag = true;
        this.newProduct = true;
        this.limitationFlag = false;
        this.changeDetectionRef.detectChanges();
    }

    public getErrorMessageForm(): string {
        if (!this.routeCountValidate) {
            return this.ERROR_ROUTE_INVALID;
        } else if (!this.routeValidate) {
            return this.ERROR_ROUTE_REQUIRED;
        } else if (this.findRequireAttribute()) {
            return this.ERROR_ATTRIBUTE_REQUIRED;
        } else if (this.findDuplicateAllRow()) {
            return this.ERROR_DUPLICATE;
        }
        return null;
    }

    get routeValueData(): string | string[] {
        return this.routeValue;
    }

    set routeValueData(value: string | string[]) {
        this.routeValue = <string[]>value;
    }

    private resetRouteChoiceData() {
        this.resetRouteChoice = true;
        this.changeDetectionRef.detectChanges();
    }

    public clearFormRefresh() {
        this.clearAllData();
        this.clearAllInheritData();
        this.clearForm();
        this.clearInheritForms();
        this.hideInherit = true;
        this.productTypeCode = null;
        this.routeValidate = true;
        this.routeCountValidate = true;
        this.draftFlag = true;
        this.limitationFlag = false;
        this.changeDetectionRef.detectChanges();
    }

    private clearValidate() {
        this.routeValidate = true;
        this.routeCountValidate = true;    
    }
}
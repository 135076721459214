import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ServiceCategoryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { VehicleConfigurationCompartmentModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { ServiceCategoryMapperService } from "../servicecategory/servicecategory-mapper.service";
import { ServiceCategoryView } from "../servicecategory/servicecatetgory.view";

@Component({
    selector: 'op-vehicle-configuration-servicecategory-seatmap',
    templateUrl: './servicecategory-seatmap.component.html',
    providers: [ServiceCategoryMapperService]
})
export class VehicleConfigurationServiceCategorySeatMapComponent implements OnChanges {
    @Input() id: string;
    @Input() serviceCategoryReferences: ServiceCategoryReferenceModel[];
    @Input() vehicleConfigurationCompartments: VehicleConfigurationCompartmentModel[];
    @Output() rowSelected = new EventEmitter<Select2Data>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    focusing: boolean = false;
    serviceCatetgoryViews: ServiceCategoryView[] = new Array();

    constructor(private serviceCategoryMapperService: ServiceCategoryMapperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.saveProcessing) {
            if (!this.id) {
                this.createNewServiceCategoryView();
            } else {
                this.createEditServiceCategoryView();
            }
        }
    }

    private createNewServiceCategoryView() {
        if (this.serviceCategoryReferences) {
            this.serviceCatetgoryViews = this.serviceCategoryMapperService.toNewServiceCategoryViews(this.serviceCategoryReferences);
        }
    }

    private createEditServiceCategoryView() {
        if (this.serviceCategoryReferences && this.vehicleConfigurationCompartments) {
            this.serviceCatetgoryViews = this.serviceCategoryMapperService.toEditServiceCategoryViews(this.serviceCategoryReferences, this.vehicleConfigurationCompartments);
        }
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField != "use" && e.column.dataField != "serviceCategoryName") {
            if (e.data.use) {
                $(e.cellElement).removeClass('dx-cell-disabled');
            }
            else {
                $(e.cellElement).addClass('dx-cell-disabled');
            }
        }
    }

    onDesignClick(data : ServiceCategoryView){
       this.rowSelected.emit(new Select2Data(data.vehicleConfigurationCompartmentId, data.serviceCategoryName));
    }
}
export const select2SearchOrganisation = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2SearchSecurityGroup = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2SearchStatus = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";


export const PRODUCT_DASHBOARD_TRANSPORT_LASTRECEIVED_DEMO: ProductDashboardLastReceivedModel[] = [
    {
        productGroupCode: "AIR",
        productType: "Air",
        departureName: "Dubai",
        arrivalName: "Addis Ababa",
        productCount: 36,
        amount: 745782.43
    },
    {
        productGroupCode: "BUS",
        productType: "Bus",
        departureName: "Paris",
        arrivalName: "Paris", 
        productCount: 59,
        amount: 874325.67
    },
    {
        productGroupCode: "RAIL",
        productType: "Rail",
        departureName: "Marseille",
        arrivalName: "Constantine", 
        productCount: 38,
        amount: 694128.79
    },
    {
        productGroupCode: "SHIP",
        productType: "Ship",
        departureName: "Bangkok",
        arrivalName: "Bangkok", 
        productCount: 72,
        amount: 135246.00
    },
    {
        productGroupCode: "CAR",
        productType: "Car",
        departureName: "Concord",
        arrivalName: "Concord", 
        productCount: 60,
        amount: 789325.44
    },
    {
        productGroupCode: "CAR",
        productType: "Car",
        departureName: "Chiang Rai",
        arrivalName: "Nan", 
        productCount: 98,
        amount: 487800.50
    },
    {
        productGroupCode: "CAR",
        productType: "Car",
        departureName: "Qinghai",
        arrivalName: "Sichuan", 
        productCount: 178,
        amount: 378900.00
    },
    {
        productGroupCode: "CAR",
        productType: "Car",
        departureName: "Texas",
        arrivalName: "Colorado", 
        productCount: 54,
        amount: 612387.16
    },
    {
        productGroupCode: "SHIP",
        productType: "Ship",
        departureName: "Naerofjord",
        arrivalName: "Geirangerfjord", 
        productCount: 12,
        amount: 3980150.00
    }
]

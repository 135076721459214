import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'op-media-preview',
    templateUrl: './media-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class MediaPreviewComponent {

    @Input() url:any;
    @Input() viewer:string;

    constructor() {
    }
}
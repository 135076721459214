import { Component } from '@angular/core';
import { AlertBarService } from 'src/app/shared/layout/alertbar';

@Component({
    selector: 'op-credit-account-content',
    templateUrl: './credit-account-content.component.html'
})
export class CreditAccountContentComponent {
    public selectedTab: string = 'SEARCH';

    constructor(public alertbarService: AlertBarService) { }

    public onAlertBarClick() {
        this.alertbarService.hide();
        this.alertbarService.hideInformation();
    }
}
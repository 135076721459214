import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeCurrencyComponent } from './exchange-currency.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ExchangeCurrencyContentModule } from './exchange-currency-content/exchange-currency-content.module';

@NgModule({
    declarations: [
        ExchangeCurrencyComponent
    ],
    imports: [
        ExchangeCurrencyContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [ExchangeCurrencyComponent]
})

export class ExchangeCurrencyModule { }

import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";

export const PRODUCT_DASHBOARD_SPECIALSERVICE_SUMMARY_DEMO: ProductDashboardSummaryModel[] = [
    {
        productName: "Infant",
        todayProductCount: 24,
        todaySalesAmount: 58050.63,
        yesterdayProductCount: 33,
        yesterdaySalesAmount: 79819.61,
        last7dProductCount: 75,
        last7dSalesAmount: 181408.22
    },
    {
        productName: "Meal",
        todayProductCount: 61,
        todaySalesAmount: 89530.23,
        yesterdayProductCount: 70,
        yesterdaySalesAmount: 102739.61,
        last7dProductCount: 241,
        last7dSalesAmount: 353717.80
    },
    {
        productName: "Pet",
        todayProductCount: 20,
        todaySalesAmount: 32541.66,
        yesterdayProductCount: 42,
        yesterdaySalesAmount: 68337.486,
        last7dProductCount: 128,
        last7dSalesAmount: 208266.62
    },
    {
        productName: "WCHR",
        todayProductCount: 32,
        todaySalesAmount: 57651.94,
        yesterdayProductCount: 38,
        yesterdaySalesAmount: 68461.68,
        last7dProductCount: 123,
        last7dSalesAmount: 221599.64
    },
    {
        productName: "OTHS",
        todayProductCount: 118,
        todaySalesAmount: 115159.62,
        yesterdayProductCount: 131,
        yesterdaySalesAmount: 127846.70,
        last7dProductCount: 483,
        last7dSalesAmount: 471373.70
    }
]

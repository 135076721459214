import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from '../../base/api-service-base';
import { OrderTypeReferenceModel } from "../../models/reference-model/reference-general-model/order-type-reference.model";

@Injectable({
  providedIn: 'root'
})
export class OrderTypeReferenceService extends ApiServiceBase{
  private readonly PATH = "/system/OrderTypeReferences"

  constructor(private httpClient: HttpClient,
    private authService: AuthService) {
      super(httpClient, authService);
  }

  public getOrderTypeReferences(): Observable<OrderTypeReferenceModel[]> {
    return this.httpGet<OrderTypeReferenceModel[]>(this.PATH, null)
      .pipe(
        map(
          response => response.body
        )
      )
  }
}

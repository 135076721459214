import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { PriceService } from "../../shared/price.service";
import { PriceView } from "../../shared/views";

@Component({
    selector: "op-price-search-table",
    templateUrl: "./search-table.component.html",
})
export class SearchTableComponent implements OnChanges, AfterViewInit {
    public readonly EXPORT_FILE_NAME = "PriceSearchResult";
    priceSearchResultViews: PriceView[];

    @Input() classIcon: string;
    @Input() set priceSearchResults(views: PriceView[]) {
        this.priceSearchResultViews = views;
    }
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() onSelected = new EventEmitter<any>();
    @Output() onNew = new EventEmitter();
    @Output() onDelete = new EventEmitter<string>();
    @Output() onCopy = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    focusing = false;
    selectedItem: PriceView;
    rows = 0;
    exportData: any;
    header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    }

    public statusReferences: StatusReferenceModel[];

    get isNotAllowToDelete(): boolean {
        return !this.selectedItem || this.selectedItem.status == "D";
    }

    get isNotAllowToCopy(): boolean {
        return !this.selectedItem && this.userSecurity?.copyFlag;
    }

    get deleteFlag(): boolean {
        return this.userSecurity?.deleteFlag ?? false;
    }

    constructor(private statusReferenceService: StatusReferenceService,
        private priceService: PriceService,
        private spinnerService: LoadingSpinnerService) {
        this.statusReferenceService.getAll().subscribe((response) => {
            this.statusReferences = response;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["priceSearchResults"]) {
            this.resetPaging();
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    resetPaging() {
        this.selectedItem = null;
        if (this.dataGrid?.instance) {
            this.dataGrid.instance.pageSize(10);
            this.dataGrid.instance.pageIndex(0);
        }
    }

    doubleClick(item) {
        this.onSelected.emit(item.key);
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
    }

    editItem() {
        if (this.selectedItem) {
            this.onSelected.emit(this.selectedItem);
        }
    }

    newItem() {
        this.onNew.emit();
    }

    onDeleteClick() {
        if (!this.selectedItem) {
            return;
        }
        this.onDelete.emit(this.selectedItem.priceId);
    }

    onCopyClick() {
        if (!this.selectedItem) {
            return;
        }
        this.onCopy.emit(this.selectedItem);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private mapData(data: any): any {
        return {
            No: data.no,
            Code: data.priceCode,
            Name: data.priceName,
            UsageType: data.usageType,
            CommitBy: data.commitBy,
            CommitDateTime: data.commitDateTime,
            Status: this.getStatusName(data.status),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences.find(
            (item) => item.statusCode == statusCode
        )?.displayName;
    }

    refreshResult() {
        this.onRefresh.emit();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data);
            })
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public updateStatus(statusCode: string) {
        if (!this.selectedItem) {
            return;
        }

        this.spinnerService.showSaving();
        this.priceService.updateStatus(this.selectedItem.priceId, statusCode)
            .subscribe(
                () => {
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }
            )
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        ::ng-deep .dx-state-hover {
            color: black !important;
        }
        `, "",{"version":3,"sources":["webpack://./src/app/modules/pricing/price-conditions/details/condition/condition.component.ts"],"names":[],"mappings":";QACQ;YACI,uBAAuB;QAC3B","sourcesContent":["\n        ::ng-deep .dx-state-hover {\n            color: black !important;\n        }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

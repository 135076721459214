import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InvoiceOrganisationSearchModel } from 'src/app/core/models/organisation-model/invoice-organisation-search.model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { InvoiceOrganisationSearchConditionComponent } from './invoice-organisation-search-condition/invoice-organisation-search-condition.component';
import { InvoiceOrganisationSearchResultComponent } from './invoice-organisation-search-result/invoice-organisation-search-result.component';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';

@Component({
    selector: 'op-invoice-organisation-search',
    templateUrl: './invoice-organisation-search.component.html'
})
export class InvoiceOrganisationSearchComponent implements OnInit {
    public displaySearchResult = false;
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);

    @Output() onSearchResultSelect = new EventEmitter<InvoiceOrganisationSearchModel>();

    @ViewChild(InvoiceOrganisationSearchConditionComponent) conditionComponent: InvoiceOrganisationSearchConditionComponent;
    @ViewChild(InvoiceOrganisationSearchResultComponent) searchResultComponent: InvoiceOrganisationSearchResultComponent;

    constructor(private statusReferenceService: StatusReferenceService,
        private focusingService: FocusingService) {}

    ngOnInit(): void {
        this.getStatusReference();
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response: StatusReferenceModel[]) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    public onSearch(condition: InvoiceOrganisationSearchModel) {
        this.displaySearchResult = true;
        this.searchResultComponent.searchOrganisation(condition);
    }

    public onClear() {
        this.displaySearchResult = false
    }

    public selectSearchResult(selectedItem: InvoiceOrganisationSearchModel) {
        this.onSearchResultSelect.emit(selectedItem);
    }

    public focusConditionPanel() {
        this.focusingService.focus(this.conditionComponent.focusingDirective);
    }

}

import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { PriceModel } from "../../prices/shared/models";
import { SearchConditionView, SearchResultView } from "./views";

@Injectable()
export class SearchConverter {
    constructor(private dateConverterService: DateConverterService) {
        
    }
    public toModel(view: SearchConditionView): PriceModel {
        let model = new PriceModel();
        model.priceCode = view.priceCode;
        model.priceName = view.priceName;
        model.usageTypeCode = view.usageTypeCode;
        model.status = view.status;
        return model;
    }

    public toViews(models: PriceModel[]): SearchResultView[] {
        let views = new Array<SearchResultView>();
        let no = 1;
        for (let model of models) {
            let view = this.toView(model);
            view.no = no++;
            views.push(view);
        }
        return views;
    }

    public toView(model: PriceModel): SearchResultView {
        let view = new SearchResultView();
        view.priceId = model.priceId;
        view.priceCode = model.priceCode;
        view.priceName = model.priceName;
        view.status = model.status;
        view.commitBy = model.commitBy;
        view.commitDateTime = this.dateConverterService.convertDateTime(model.commitDateTime);
        return view;
    }

    public toFormGroup(view: SearchConditionView): UntypedFormGroup {
        if (view) {
            return new UntypedFormGroup({
                priceCode: new UntypedFormControl(view.priceCode),
                priceName: new UntypedFormControl(view.priceName),
                usageTypeCode: new UntypedFormControl({
                    value: view.usageTypeCode,
                    disabled: true
                }),
                status: new UntypedFormControl(view.status)
            })
        }
        return null;
    }

    public toPagingDataViews(models: PriceModel[]): PagingDataView[] {
        let count = 1;
        return models.map(m => ({
            id: m.priceId,
            no: count++,
            seriesId: null,
            name: null
        }));
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Observable } from 'rxjs';
import { DomainAttributeModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class DomainAttributeService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/airline/domainattributes/"
    private readonly ATTRIBUTE_GROUP_CODE = "ATTRIBUTE";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public getByProductType(attributeGroupCode: string, productTypeCode: string = "", searchFlag: boolean = false, attributeTypeCode: string = "", serviceGroupCode: string = "", productCategoryCode: string = "", productFlag: boolean = null) {
        let params = new HttpParams()
            .set("attributeGroupCode", attributeGroupCode)
            .set("productTypeCode", productTypeCode)
            .set("attributeTypeCode", attributeTypeCode)
            .set("serviceGroupCode", serviceGroupCode)
            .set("searchFlag", (searchFlag ?? false))
            .set("productCategoryCode", productCategoryCode);
            if (productFlag || productFlag == false) {
                params = params.set("productFlag", productFlag);
            }
            
        return this.httpGet<any>(this.REFERENCE_PATH + 'product', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getPriceRuleAttributes(priceRuleTypeCode: string, attributeGroupCode: string, attributeTypeCode: string): Observable<DomainAttributeModel[]> {
        let params = new HttpParams()
            .set('priceRuleTypeCode', priceRuleTypeCode)
            .set('attributeGroupCode', attributeGroupCode)
            .set('attributeTypeCode', attributeTypeCode);
        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'priceRule', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrganisationAttributes(organisationCodes: string[], attributeGroupCode: string, attributeTypeCode: string): Observable<DomainAttributeModel[]> {        
        let params = new HttpParams()
            .set('attributeGroupCode', attributeGroupCode)
            .set('attributeTypeCode', attributeTypeCode)
        organisationCodes.forEach(oc => params = params.append('organisationTypeCodes', oc))

        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'organisation', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getIndividualAttributes(attributeGroupCode: string, attributeTypeCode: string): Observable<DomainAttributeModel[]> {
        let params = new HttpParams()
            .set('attributeGroupCode', attributeGroupCode)
            .set('attributeTypeCode', attributeTypeCode)
        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'individual', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getDocumentDistributionAttributes(attributeGroupCode: string, attributeTypeCode: string): Observable<DomainAttributeModel[]> {
        let params = new HttpParams()
            .set('attributeGroupCode', attributeGroupCode)
            .set('attributeTypeCode', attributeTypeCode)
        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'documentDistribution', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrganisationAttributeFilters(): Observable<DomainAttributeModel[]> {
        let params = new HttpParams()
            .set('attributeGroupCode', this.ATTRIBUTE_GROUP_CODE)

        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + 'organisation/filter', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getByOrderType(orderStateCode: string = "",
        orderTypeCode: string = "", 
        searchFlag: boolean = false, 
        attributeTypeCode: string = "", 
        serviceGroupCode: string = ""): Observable<DomainAttributeModel[]> {

        let params = new HttpParams()
            .set("orderStateCode", orderStateCode)
            .set("orderTypeCode", orderTypeCode)
            .set("attributeTypeCode", attributeTypeCode)
            .set("serviceGroupCode", serviceGroupCode)
            .set("searchFlag",searchFlag ? "true" : "false");
            
        return this.httpGet<any>(this.REFERENCE_PATH + 'order', params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getDomainAttributeListByDomainCodeAttributeGroupCode(domainCode: string, attributeGroupCode: string): Observable<DomainAttributeModel[]> {
        return this.httpGet<DomainAttributeModel[]>(this.REFERENCE_PATH + `DomainCodes/${domainCode}/AttributeGroupCodes/${attributeGroupCode}`, null)
            .pipe(
                map(response => response.body)
            )
    }
}
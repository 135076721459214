import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { ProductGroupService } from 'src/app/core/services/product-services';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { ProductGroupMapperService } from '../../../shared/mapper/product-group.mapper';
import { ProductGroupView } from '../../../shared/views/product-group.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { cloneDeep } from 'lodash';
@Component({
    selector: 'op-transport-group',
    templateUrl: './transport-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ProductGroupMapperService]
})
export class TransportGroupComponent implements OnInit {
    private readonly PRODUCT_CATEGORY = 'TRANSPORT';
    private searchActionId = 'SEARCH';
    private newActionId = 'NEW';
    private dashboardActionId = 'DASHBOARD';
    private contextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'New',
            actionId: this.newActionId
        },       
        {
            name: 'Search',
            actionId: this.searchActionId
        },
        {
            name: 'Dashboard',
            actionId: this.dashboardActionId
        }
    ]
    public selectedGroup: OopsCategory;
    public serviceProductGroup: OopsCategory[];
    public serviceGroupReference = new Array<ProductGroupView>();
    private unsubscribe$ = new Subject();
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() menuSelected = new EventEmitter<string>();
    @Output() onSearch = new EventEmitter<string>();
    @Output() onNew = new EventEmitter<string>();
    @Output() onDashboard = new EventEmitter();

    constructor(private productGroupService: ProductGroupService,
        private productGroupMapperService: ProductGroupMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private actionbarService: ActionbarService,
        private activityStoreService: ActivityStoreService) { }

    ngOnInit() {
        this.getServiceGroup();
    }

    ngAfterViewInit(): void {
        this.updateActionBar();
    }

    private updateActionBar(){
        this.actionbarService.updateState(new ActionBarHandlerModel(
            new RefreshButtonModel()
          ));
          this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
              switch(actionId) {
                case ACTION_STATUS.refresh:
                  this.getServiceGroup();
                  break;
              }
            }
          );
    }
      
    private getServiceGroup() {
        this.productGroupService.getProductGroupByProductCategoryCode(this.PRODUCT_CATEGORY)
            .subscribe(
                (response) => {
                    Object.assign(this.serviceGroupReference, response);
                    this.serviceProductGroup = this.productGroupMapperService.productGroupToOopsCategoryViews(response);
                    this.setContextMenus(this.serviceProductGroup, this.userSecurity);
                    this.changeDetectionRef.detectChanges();
                }
            )
    }

    private setContextMenus(groups: OopsCategory[], security: SecurityGroupSecurityModel) {
        for (let group of groups) {
            group.security = security;
            group.contextMenus = this.setupContextMenuSecurity(security)
        }
    }

    private createSecurityCode(code: string) : string {
        return `PRODUCTTRANSPORT${code}`;
    }
    
    public onRowSelected(item: OopsCategory) {
        this.selectedGroup = item;
    }

    public onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case this.searchActionId:
                this.activityStore();
                this.onSearch.emit(this.getProductGroupCode(this.selectedGroup.name));
                break;
            case this.newActionId:
                this.onNew.emit(this.getProductGroupCode(this.selectedGroup.name));
                break;
            case this.dashboardActionId:
                this.onDashboard.emit();
                break;              
        }
    }

    private getProductGroupCode(productGroupName: string) {
        return this.serviceGroupReference.find(item => item.productGroupName == productGroupName).productGroupCode;
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            this.searchActionId.toLowerCase(),
            this.PRODUCT_CATEGORY,
            this.getProductGroupCode(this.selectedGroup.name));
    }

    private setupContextMenuSecurity(security: SecurityGroupSecurityModel): OopsCategoryContextMenu[] {
        let newBtnIndex = this.contextMenu.findIndex(item => item.actionId == this.newActionId);
        let newMenu = cloneDeep(this.contextMenu);
    
        newMenu[newBtnIndex].disable = !security?.newFlag;
        return newMenu;
    }
}

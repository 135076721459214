import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_FEES_REVENUE_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 162,
        salesChannel1Name: "GDS",
        salesChannel2: 113,
        salesChannel2Name: "API",
        salesChannel3: 438,
        salesChannel3Name: "ISE",
        salesChannel4: 207,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 329,
        salesChannel1Name: "GDS",
        salesChannel2: 284,
        salesChannel2Name: "API",
        salesChannel3: 192,
        salesChannel3Name: "ISE",
        salesChannel4: 450,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 112,
        salesChannel1Name: "GDS",
        salesChannel2: 203,
        salesChannel2Name: "API",
        salesChannel3: 423,
        salesChannel3Name: "ISE",
        salesChannel4: 402,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 316,
        salesChannel1Name: "GDS",
        salesChannel2: 169,
        salesChannel2Name: "API",
        salesChannel3: 388,
        salesChannel3Name: "ISE",
        salesChannel4: 182,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 347,
        salesChannel1Name: "GDS",
        salesChannel2: 119,
        salesChannel2Name: "API",
        salesChannel3: 453,
        salesChannel3Name: "ISE",
        salesChannel4: 228,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 133,
        salesChannel1Name: "GDS",
        salesChannel2: 236,
        salesChannel2Name: "API",
        salesChannel3: 196,
        salesChannel3Name: "ISE",
        salesChannel4: 200,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 382,
        salesChannel1Name: "GDS",
        salesChannel2: 287,
        salesChannel2Name: "API",
        salesChannel3: 116,
        salesChannel3Name: "ISE",
        salesChannel4: 249,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 362,
        salesChannel1Name: "GDS",
        salesChannel2: 300,
        salesChannel2Name: "API",
        salesChannel3: 123,
        salesChannel3Name: "ISE",
        salesChannel4: 401,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 270,
        salesChannel1Name: "GDS",
        salesChannel2: 143,
        salesChannel2Name: "API",
        salesChannel3: 165,
        salesChannel3Name: "ISE",
        salesChannel4: 138,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 421,
        salesChannel1Name: "GDS",
        salesChannel2: 289,
        salesChannel2Name: "API",
        salesChannel3: 300,
        salesChannel3Name: "ISE",
        salesChannel4: 312,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 342,
        salesChannel1Name: "GDS",
        salesChannel2: 304,
        salesChannel2Name: "API",
        salesChannel3: 237,
        salesChannel3Name: "ISE",
        salesChannel4: 421,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 430,
        salesChannel1Name: "GDS",
        salesChannel2: 132,
        salesChannel2Name: "API",
        salesChannel3: 428,
        salesChannel3Name: "ISE",
        salesChannel4: 320,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 170,
        salesChannel1Name: "GDS",
        salesChannel2: 360,
        salesChannel2Name: "API",
        salesChannel3: 364,
        salesChannel3Name: "ISE",
        salesChannel4: 308,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 102,
        salesChannel1Name: "GDS",
        salesChannel2: 401,
        salesChannel2Name: "API",
        salesChannel3: 278,
        salesChannel3Name: "ISE",
        salesChannel4: 299,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 331,
        salesChannel1Name: "GDS",
        salesChannel2: 121,
        salesChannel2Name: "API",
        salesChannel3: 374,
        salesChannel3Name: "ISE",
        salesChannel4: 133,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 137,
        salesChannel1Name: "GDS",
        salesChannel2: 283,
        salesChannel2Name: "API",
        salesChannel3: 184,
        salesChannel3Name: "ISE",
        salesChannel4: 115,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 462,
        salesChannel1Name: "GDS",
        salesChannel2: 259,
        salesChannel2Name: "API",
        salesChannel3: 183,
        salesChannel3Name: "ISE",
        salesChannel4: 136,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 372,
        salesChannel1Name: "GDS",
        salesChannel2: 234,
        salesChannel2Name: "API",
        salesChannel3: 493,
        salesChannel3Name: "ISE",
        salesChannel4: 200,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 418,
        salesChannel1Name: "GDS",
        salesChannel2: 371,
        salesChannel2Name: "API",
        salesChannel3: 388,
        salesChannel3Name: "ISE",
        salesChannel4: 204,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 277,
        salesChannel1Name: "GDS",
        salesChannel2: 489,
        salesChannel2Name: "API",
        salesChannel3: 397,
        salesChannel3Name: "ISE",
        salesChannel4: 134,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 403,
        salesChannel1Name: "GDS",
        salesChannel2: 226,
        salesChannel2Name: "API",
        salesChannel3: 490,
        salesChannel3Name: "ISE",
        salesChannel4: 330,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 351,
        salesChannel1Name: "GDS",
        salesChannel2: 358,
        salesChannel2Name: "API",
        salesChannel3: 346,
        salesChannel3Name: "ISE",
        salesChannel4: 290,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 122,
        salesChannel1Name: "GDS",
        salesChannel2: 164,
        salesChannel2Name: "API",
        salesChannel3: 428,
        salesChannel3Name: "ISE",
        salesChannel4: 500,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 118,
        salesChannel1Name: "GDS",
        salesChannel2: 391,
        salesChannel2Name: "API",
        salesChannel3: 154,
        salesChannel3Name: "ISE",
        salesChannel4: 458,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 425,
        salesChannel1Name: "GDS",
        salesChannel2: 438,
        salesChannel2Name: "API",
        salesChannel3: 164,
        salesChannel3Name: "ISE",
        salesChannel4: 314,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 368,
        salesChannel1Name: "GDS",
        salesChannel2: 190,
        salesChannel2Name: "API",
        salesChannel3: 363,
        salesChannel3Name: "ISE",
        salesChannel4: 466,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 169,
        salesChannel1Name: "GDS",
        salesChannel2: 218,
        salesChannel2Name: "API",
        salesChannel3: 143,
        salesChannel3Name: "ISE",
        salesChannel4: 416,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 383,
        salesChannel1Name: "GDS",
        salesChannel2: 213,
        salesChannel2Name: "API",
        salesChannel3: 474,
        salesChannel3Name: "ISE",
        salesChannel4: 416,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 598,
        salesChannel1Name: "GDS",
        salesChannel2: 342,
        salesChannel2Name: "API",
        salesChannel3: 657,
        salesChannel3Name: "ISE",
        salesChannel4: 147,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 354,
        salesChannel1Name: "GDS",
        salesChannel2: 220,
        salesChannel2Name: "API",
        salesChannel3: 377,
        salesChannel3Name: "ISE",
        salesChannel4: 109,
        salesChannel4Name: "OWN"
    }
]

export const PriceDimensionConstant = {
    productNumber: {
        id: 'PRODUCTNUMBER',
        text: 'Product Number'
    },
    organisation: {
        id: 'ORGANISATION',
        text: 'Organisation'
    },
    route: {
        id: 'ROUTE',
        text: 'Route'
    },
    origin: {
        id: 'ORIGIN',
        text: 'Origin'
    },
    destination: {
        id: 'DESTINATION',
        text: 'Destination'
    },
    serviceDate: {
        id: 'SERVICEDATE',
        text: 'Service Date'
    },
    salesDate: {
        id: 'SALESDATE',
        text: 'Sales Date'
    },
    serviceCategory: {
        id: 'SERVICECATEGORY',
        text: 'Service Category'
    },
    salesBucket: {
        id: 'SALESBUCKET',
        text: 'Sales Bucket'
    },
    memberLevel: {
        id: 'MEMBERLEVEL',
        text: 'Member Level'
    },
    currency: {
        id: 'CURRENCY',
        text: 'Currency'
    },
    unitType: {
        id: 'UNITTYPE',
        text: 'Unit Type'
    },
    attribute: {
        id: 'ATTRIBUTE',
        text: 'Attribute'
    }
}

export const PriceDimensionTypeData = [
    PriceDimensionConstant.productNumber,
    PriceDimensionConstant.organisation,
    PriceDimensionConstant.route,
    PriceDimensionConstant.origin,
    PriceDimensionConstant.destination,
    PriceDimensionConstant.serviceDate,
    PriceDimensionConstant.salesDate,
    PriceDimensionConstant.serviceCategory,
    PriceDimensionConstant.salesBucket,
    PriceDimensionConstant.memberLevel,
    PriceDimensionConstant.currency,
    PriceDimensionConstant.attribute,
    PriceDimensionConstant.unitType
]

export const PriceDimensionTypeDataWithoutUnitType = [
    PriceDimensionConstant.productNumber,
    PriceDimensionConstant.organisation,
    PriceDimensionConstant.route,
    PriceDimensionConstant.origin,
    PriceDimensionConstant.destination,
    PriceDimensionConstant.serviceDate,
    PriceDimensionConstant.salesDate,
    PriceDimensionConstant.serviceCategory,
    PriceDimensionConstant.salesBucket,
    PriceDimensionConstant.memberLevel,
    PriceDimensionConstant.currency,
    PriceDimensionConstant.attribute
]
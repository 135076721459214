import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxCheckBoxModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { RulesConfigModule } from "src/app/core/components/rules-config/rules-config.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { OopsChoiceValueModule } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { ProductAttributeAndRuleComponent } from "./attribute-and-rule.component";
import { AttributeComponent } from "./attribute/attribute.component";
import { HashtagComponent } from "./hashtag/hashtag.component";
import { PointOfSalesComponent } from "./point-of-sales/point-of-sales.component";
import { ProductNumberComponent } from "./product-number/product-number.component";
import { RestrictionComponent } from "./restriction/restriction.component";
import { ValidityComponent } from "./validity/validity.component";

@NgModule({
    declarations: [
        AttributeComponent,
        HashtagComponent,
        PointOfSalesComponent,
        ProductAttributeAndRuleComponent,
        ProductNumberComponent,
        RestrictionComponent,
        ValidityComponent
    ],
    exports: [
        ProductAttributeAndRuleComponent
    ],
    imports: [
        CommonModule,
        DaterangepickerModule,
        BsDropdownModule,
        DxCheckBoxModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        OopsChoiceValueModule,
        PanelsModule,
        ReactiveFormsModule,
        RulesConfigModule,
        Select2Module
    ]
})
export class ProductAttributeAndRuleModule { }

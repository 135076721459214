import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class ProductCodeSetRequireFieldService {
 
    public clearRequireProductCode(ctl) {
        ctl.get('productCodeType').clearValidators();
        ctl.get('productCodeType').updateValueAndValidity();

        ctl.get('operatorProductCode').clearValidators();
        ctl.get('operatorProductCode').updateValueAndValidity();

        ctl.get('productCodeValue').clearValidators();
        ctl.get('productCodeValue').updateValueAndValidity();
    }

    public setRequireProductCode(ctl) {
        ctl.get('productCodeType').setValidators(Validators.required);
        ctl.get('productCodeType').updateValueAndValidity();

        ctl.get('operatorProductCode').setValidators(Validators.required);
        ctl.get('operatorProductCode').updateValueAndValidity();

        ctl.get('productCodeValue').setValidators(Validators.required);
        ctl.get('productCodeValue').updateValueAndValidity();
    }

}
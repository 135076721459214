import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OperatorModel } from 'src/app/core/models/merchandizing-config';

@Injectable()
export class ProductCodeSetDefaultService {

    public setDefaultOperatorProductCode(f: UntypedFormGroup, operatorProductCode$: BehaviorSubject<OperatorModel[]>, operatorProductCodeOption) {
        var filterplaceHolder = operatorProductCode$.value.filter(x => x.operatorCode == "");
        if (filterplaceHolder.length != 0) {
            operatorProductCodeOption.placeholder = filterplaceHolder[0].operatorName;
        }
        else {
            if (operatorProductCode$.value.length != 0) {
                f.controls['operatorProductCode'].setValue(operatorProductCode$.value[0].operatorCode);
            }
        }
    }

}
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    selector: 'op-schedule',
    templateUrl: './schedule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleComponent {
    constructor(private navigationService: NavigationService,
        private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.navigationService.navigate("/main/schedule/home", null);
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { FavoriteModel } from '../../models/reference-model/reference-general-model/favorite.model';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService extends ApiServiceBase {
    private readonly PATH = '/system/UserFavorites';

    constructor(httpClient: HttpClient,
        authService: AuthService) { 
        super(httpClient, authService);
    }

    public getUserFavorite(): Observable<FavoriteModel[]> {
        return this.httpGet<FavoriteModel[]>(this.PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public addUserFavorite(userFavorite: FavoriteModel) {
        return this.httpPost(this.PATH, userFavorite);
    }

    public editUserFavorite(userFavorite: FavoriteModel) {
        return this.httpPut(this.PATH, userFavorite);
    }

    public deleteUserFavorite(userFavoriteId: string) {
        return this.httpDelete(this.PATH + '/' + userFavoriteId, null);
    }
}

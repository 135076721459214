import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CommunicationOtherTypeReferenceService } from "../../services/system-services";
import { Select2Data, Select2Directive } from "src/app/shared/ui/forms/inputs/oops-select2";
import { takeUntil } from "rxjs/operators";
import { InsightPassengerService } from "../../services/individul-services";
import { BehaviorSubject, Subject, forkJoin, of } from "rxjs";
import { InsightPassengerCommunicationOtherModel, InsightPassengerModel } from "../../models/individual-model";
import { CommunicationOtherTypeReferenceModel } from "../../models/reference-model/reference-general-model";

declare var $: any;

@Component({
    selector: 'op-communication-other-type-selection',
    templateUrl: './communication-other-type-selection.component.html'
})
export class CommunicationOtherTypeSelectionComponent implements OnInit, OnDestroy {
    @Input() individualId: string;
    @Input() 
    set data(value: string) {
        this._value = value;
    }
    @Input() hasError: boolean = false;
    @Input() disabled: boolean = false;

    @Output() dataChange = new EventEmitter<string>();
    @Output() presetSelected = new EventEmitter<InsightPassengerCommunicationOtherModel>();

    @ViewChild(Select2Directive) select2: Select2Directive;

    readonly OPTION = {
        placeholder: "",
        allowClear: false,
        width: 'auto',
        dropdownAutoWidth: true,
        minimumResultsForSearch: -1,
        templateResult: this.communicationOtherTypeTemplate
    }
    readonly emptySelect2Data: Select2Data = {
        disabled: false,
        id: '',
        selected: false,
        text: ''
    }

    _value: string;
    communicationOtherTypeReferences$ = new BehaviorSubject<Select2Data[]>([]);
    loadCompleted$ = new BehaviorSubject<boolean>(false);

    private destroy$ = new Subject();

    constructor(private communicationOtherTypeReferenceService: CommunicationOtherTypeReferenceService,
        private insightPassengerService: InsightPassengerService) {
        this.communicationOtherTypeTemplate = this.communicationOtherTypeTemplate.bind(this);
    }

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onDataChange(value: any) {
        this.dataChange.emit(value);
    }

    private fetchData() {
        this.loadCompleted$.next(false);
        forkJoin({
            communicationOtherTypeReferences: this.communicationOtherTypeReferenceService.getCommunicationOtherTypeReferences(),
            insightPassengerData: this.individualId ? this.insightPassengerService.getInsightPassenger(this.individualId) : of<InsightPassengerModel>(null)
        })
        .subscribe(
            ({ communicationOtherTypeReferences, insightPassengerData }) => {
                this.populateSelect2Data(communicationOtherTypeReferences, insightPassengerData);
                this.loadCompleted$.next(true);
            }
        )
    }

    private populateSelect2Data(communicationOtherTypeReferences: CommunicationOtherTypeReferenceModel[], insightPassengerData: InsightPassengerModel) {
        const communicationOtherTypeReferenceData = communicationOtherTypeReferences?.map(item => new Select2Data(item.communicationOtherTypeCode, item.communicationOtherTypeName)) || [];
        let insightPassengerCommunicationOtherData = insightPassengerData?.insightPassengerCommunicationOthers?.map(insightPassengerCommunicationOther => ({
            selected: false,
            disabled: false,
            id: insightPassengerCommunicationOther.communicationOtherId,
            text: '',
            action: insightPassengerCommunicationOther
        })) || [];
        const finalData = [this.emptySelect2Data].concat(insightPassengerCommunicationOtherData).concat(communicationOtherTypeReferenceData);
        this.communicationOtherTypeReferences$.next(finalData);
    }

    private communicationOtherTypeTemplate(state) {
        if (!state.action) return state.text;
        return $(`<div class="row m-0">
            <div class="col col-5 p-0">${state.action.communicationOtherTypeName}</div>
            <div class="col col-6 p-0">${state.action.mailAddress}</div>
        </div>`);
    }

    onPresetSelected(preset: InsightPassengerCommunicationOtherModel) {
        this.presetSelected.emit(preset);
    }

    public getSelectedText(): string {
        return this.communicationOtherTypeReferences$.value?.find(item => item.id == this._value)?.text;
    }
}
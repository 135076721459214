import { Injectable } from "@angular/core";
import { Select2Themes } from "../../product-eligible-restricted/shared/select2-options.service";
import { Select2Option } from "../../shared/attribute-and-rule/views/select2.option";

@Injectable({
    providedIn: 'root'
})
export class Select2OptionService {
    public getIndividualAgeGroupReferenceOption(): Select2Option {
        let option = new Select2Option('<Type>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getIndividualCivilStatusTypeReferenceOption(): Select2Option {
        let option = new Select2Option('<Type');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getGenderReferenceOption(): Select2Option {
        let option = new Select2Option('<Gender>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getIndividualInterestTypeReferenceOption(): Select2Option {
        let option = new Select2Option('<Type>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getMembershipLevelOption(): Select2Option {
        let option = new Select2Option('<Level>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getCalendarYearOption(): Select2Option {
        let option = new Select2Option('<Year>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getCalendarMonthsOption(): Select2Option {
        let option = new Select2Option('<Month>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }

    public getCalendarDateOption(): Select2Option {
        let option = new Select2Option('<Date>');
        option.multiple = true;
        option.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        option.allowClear = true;
        return option;
    }
}
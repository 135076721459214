import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PricingDetailConstant } from "../../shared/pricing-detail.constant";
import { PriceRuleAttributeAndRuleRowView, PriceRuleFormOfPaymentView } from "../product-eligible-restricted/shared/views";
import { AttributeAndRuleBase } from "../shared/attribute-and-rule.base";
import { Select2Option } from "../shared/attribute-and-rule/views/select2.option";
import { Select2OptionsService as ProductEligibleSelect2OptionService } from '../product-eligible-restricted/shared/select2-options.service';
import { ConditionReferenceService } from "src/app/core/services/system-services";
import { ConditionReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { map, take, takeUntil } from "rxjs/operators";
import { ProductEligibleRestrictedConverter } from "../product-eligible-restricted/shared/product-eligible-restricted.converter";
import { FormOfPaymentTypeReferenceService } from "src/app/core/services/system-services/form-of-payment-type-reference.service";
import { FormOfPaymentIssuerModel, FormOfPaymentReferenceModel, FormOfPaymentTypeReferenceModel } from "src/app/core/models/form-of-payment-model";
import { FormOfPaymentReferenceService } from "src/app/core/services/system-services/form-of-payment-reference.service";
import { FormOfPaymentIssuerService } from "src/app/core/services/system-services/form-of-payment-issuer.service";
import { cloneDeep } from 'lodash';

@Component({
    selector: 'op-form-of-payment',
    templateUrl: './form-of-payment.component.html',
    providers: [
        ProductEligibleRestrictedConverter
    ]
})
export class FormOfPaymentComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {
    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.setPreSelectedItems()
        }
    }
    @Input() searchMode: boolean = false;
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter<PriceRuleAttributeAndRuleRowView[]>();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    public focusing: boolean = false;

    public rootData = [
        new Select2Data(PricingDetailConstant.FORM_OF_PAYMENT_CODE, "Form of Payment")
    ]

    public rootOption = new Select2Option('<Type>');
    public select2IsIsNotConditionOption: Select2Option;
    public formOfPaymentTypeCodeOption = new Select2Option('<Form of Payment Type>');
    public formOfPaymentCodeOption = new Select2Option('<Name>');
    public formOfPaymentIssuerOption = new Select2Option('<Issuer>');
    
    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>([]);
    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public formOfPaymentTypeReferences$ = this.formOfPaymentTypeReferenceService.getAll();
    public formOfPaymentReferences$ = new BehaviorSubject<Observable<FormOfPaymentReferenceModel[]>[]>([]);
    public formOfPaymentIssuers$ = new BehaviorSubject<Observable<FormOfPaymentIssuerModel[]>[][]>([]);


    private unsubscribe$ = new Subject();

    constructor(private productEligibleSelect2OptionsService: ProductEligibleSelect2OptionService,
        private conditionReferenceService: ConditionReferenceService,
        private converter: ProductEligibleRestrictedConverter,
        private formOfPaymentTypeReferenceService: FormOfPaymentTypeReferenceService,
        private formOfPaymentReferenceService: FormOfPaymentReferenceService,
        private formOfPaymentIssuerService: FormOfPaymentIssuerService) {
        super();
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    ngOnInit(): void {
        this.select2IsIsNotConditionOption = this.productEligibleSelect2OptionsService.getSelect2ConditionOption();
        this.getSelect2IsIsNotConditionDataList();
        this.getFormOfPaymentReferences();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setPreSelectedItems() {
        if (!this.rows$?.value?.length) {
            return;
        }
        let rows = cloneDeep(this.rows$.value);
        for(let row of rows) {
            row.type = PricingDetailConstant.FORM_OF_PAYMENT_CODE;
        }
        this.rows$.next(rows);
        this.togglePanelCollapseStatus(false);
    }

    public add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let temp = this.rows$.value;
            temp.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(temp);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    public onRootChange(row: PriceRuleAttributeAndRuleRowView, event: any) {
        if (row.type != event) {
            this.resetRow(row);
            this.resetErrorFlags();
        }
        row.type = event;
        switch (event) {
            case PricingDetailConstant.FORM_OF_PAYMENT_CODE:
                row.formOfPayment = row.formOfPayment ?? new PriceRuleFormOfPaymentView();
                row.formOfPayment.condition = PricingDetailConstant.IS_OPERATOR_CODE;
                break;
            default:
                break;
        }
    }

    private resetRow(row: PriceRuleAttributeAndRuleRowView) {
        for (let key of Object.keys(row)) {
            if (row[key]) {
                delete row[key];
            }
        }
    }

    private getSelect2IsIsNotConditionDataList() {
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE], (res) => {
            this.select2IsIsNotConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ConditionReferenceModel[]) => {
                    callback(res);
                }
            );
    }

    private getFormOfPaymentReferences() {
        this.formOfPaymentTypeReferences$.subscribe(
            formOfPaymentTypeReferences => {
                let formOfPaymentReferences = new Array<Observable<FormOfPaymentReferenceModel[]>>();
                for (let formOfPaymentTypeReference of formOfPaymentTypeReferences) {
                    let formOfPaymentTypeCode = formOfPaymentTypeReference.formOfPaymentTypeCode;
                    formOfPaymentReferences[formOfPaymentTypeCode] = this.formOfPaymentReferenceService.getBy(formOfPaymentTypeCode);
                    this.getFormOfPaymentIssuers(formOfPaymentReferences, formOfPaymentTypeReference);
                }
                this.formOfPaymentReferences$.next(formOfPaymentReferences);
            }
        )
    }

    private getFormOfPaymentIssuers(formOfPaymentReferences: Observable<FormOfPaymentReferenceModel[]>[], formOfPaymentTypeReference: FormOfPaymentTypeReferenceModel) {
        let formOfPaymentTypeCode = formOfPaymentTypeReference.formOfPaymentTypeCode;
        formOfPaymentReferences[formOfPaymentTypeCode].subscribe(
            formOfPaymentReferences => {
                let formOfPaymentIssuersByType = this.formOfPaymentIssuers$.value ?? new Array<Observable<FormOfPaymentIssuerModel[]>[]>();
                for (let formOfPaymentReference of formOfPaymentReferences) {
                    let formOfPaymentCode = formOfPaymentReference.formOfPaymentCode;
                    formOfPaymentIssuersByType[formOfPaymentTypeCode] = formOfPaymentIssuersByType[formOfPaymentTypeCode] ?? new Array<Observable<FormOfPaymentIssuerModel[]>>();
                    formOfPaymentIssuersByType[formOfPaymentTypeCode][formOfPaymentCode] = this.formOfPaymentIssuerService.getBy(formOfPaymentCode);
                }
                this.formOfPaymentIssuers$.next(formOfPaymentIssuersByType);
            }
        );
    }

    public onFormOfPaymentTypeCodeChange(row: PriceRuleAttributeAndRuleRowView, formOfPaymentTypeCode: any) {
        delete row.formOfPayment.formOfPaymentIssuerCode;
        delete row.formOfPayment.formOfPaymentCode;
        row.formOfPayment.formOfPaymentTypeCode = formOfPaymentTypeCode;
        this.resetErrorFlags();
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public containData(data: any): boolean {
        return !!data?.length;
    }

    public getFormOfPaymentReferenceModels(data: any): FormOfPaymentReferenceModel[] {
        return data as FormOfPaymentReferenceModel[];
    }

    public getFormOfPaymentIssuerModels(data: any): FormOfPaymentIssuerModel[] {
        return data as FormOfPaymentIssuerModel[];
    }
}
import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NdcShoppingRequest, NdcShoppingResponse } from "../../models/ndc/ndc-shopping";

@Injectable({
    providedIn: 'root'
})
export class OrderVoucherService extends ApiServiceBase {
    private readonly PATH: string = "/shopping/ndc/voucher"

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public request(request: NdcShoppingRequest): Observable<NdcShoppingResponse> {

        return this.httpPost<NdcShoppingResponse>(this.PATH, request)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { MembershipLevelModel } from '../../models/reference-model/reference-general-model/membership-level.model';

@Injectable({
    providedIn: 'root'
})
export class MembershipLevelService extends ApiServiceBase {
    private readonly PATH = '/system/MembershipLevels';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getByProvider(): Observable<MembershipLevelModel[]> {
        return this.httpGet<MembershipLevelModel[]>(this.PATH + '/provider', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getAll(): Observable<MembershipLevelModel[]> {
        return this.httpGet<MembershipLevelModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}

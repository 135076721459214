import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { Helper } from "src/app/shared/helper/app.helper";
import { cloneDeep } from "lodash";
import { CommentView } from "./shared/comment.view";
import { CommentTableComponent } from "./comment-table/comment-table.component";
import { CommentDetailDirective } from "./comment-detail/comment-detail.directive";
import { CommentDetailComponent } from "./comment-detail/comment-detail.component";
import Swal from "sweetalert2";
import { swalDeleteOption, swalDeleteSuccess } from "./shared/comment-configuration";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";

@Component({
    selector: 'op-comment',
    templateUrl: './comment.component.html'
})
export class CommentComponent {
    readonly ADD_BINDING_DELAY_TIME = 50;

    @Input() isAllowReadOnlyMode: boolean = true;

    public focused: boolean = false;
    public singleRecord: boolean = true;
    public isDetailCollapsed: boolean = false;
    public showAdd: boolean = false;
    public commentView = new CommentView();
    public commentViews: CommentView[] = new Array();

    @ViewChild(CommentDetailDirective) commentDetailDirective: CommentDetailDirective;
    @ViewChild(CommentDetailComponent) commentDetailComponent: CommentDetailComponent;
    @ViewChild(CommentTableComponent) commentTableComponent: CommentTableComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private helper: Helper,
        public focusingService: FocusingService) {
    }

    getClassIcon(): string {
        let value = this.helper.getClassIcon();
        return value;
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.clearDetailForm();
        setTimeout(() => {
            this.loadDetailForm();
            this.changeDetectionRef.detectChanges();
        }, this.ADD_BINDING_DELAY_TIME);
    }

    edit(data: CommentView) {
        this.openDetail();
        this.loadDetailForm(data);
    }

    delete(data: CommentView) {
        this.confirmDelete(() => {
            this.deleteData(data);
            this.displayAfterDelete();
            Swal.fire(swalDeleteSuccess);
            this.changeDetectionRef.detectChanges();
        });
    }

    private confirmDelete(callback: () => void) {
        Swal.fire(swalDeleteOption).then((result) => {
            if (result.isConfirmed) {
                if (callback) {
                    callback();
                }
            }
        });
    }

    public openDetail(){
        if (!this.isDetailCollapsed){
            this.isDetailCollapsed = !this.isDetailCollapsed
            this.changeDetectionRef.markForCheck();
        }       
    }

    public toggleDetail() {
        this.isDetailCollapsed = !this.isDetailCollapsed
        this.changeDetectionRef.markForCheck();
        if (!this.isDetailCollapsed) {
            this.formDetailClose();
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.commentDetailDirective.viewContainerRef;
        viewContainerRef.clear();
        this.commentTableComponent.disableAdd = false;
        this.commentTableComponent.disableEdit = false;
    }

    private deleteData(data: CommentView) {
        let views = new Array<CommentView>();
        if (this.commentViews != null) {
            views = this.commentViews;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.commentViews = views;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForCommunicationDetailForm();
            this.singleRecord = true;
        }

        if (this.isDetailCollapsed) {
            this.toggleDetail();
        }
    }

    private setPropertyForCommunicationDetailForm() {
        if (this.commentViews.length == 1) {
            this.commentView = this.commentViews[0];
            this.showAdd = true;
        }
        else {
            this.commentView = new CommentView();
            this.showAdd = false;
        }
    }

    private isSingleRecord() : boolean {
        if (this.commentViews.length == 0 || this.commentViews.length == 1) {
            return true;
        }

        return false;
    }

    private loadDetailForm(dataEdit: CommentView = null) { 
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(CommentDetailComponent);

        let viewContainerRef = this.commentDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: CommentDetailComponent = (<CommentDetailComponent>componentRefs.instance);
        component.showAdd = false;
        component.isAllowReadOnlyMode = this.isAllowReadOnlyMode;

        if (dataEdit != null) {
            component.commentView = cloneDeep(dataEdit);
        }
        else {
            component.commentView = new CommentView();
        }

        component.onCancel.subscribe(
            response => {
                this.displayAfterCancel();
            }
        );
        component.onSave.subscribe(
            (dataSave: CommentView) => {
                this.save(dataSave);
            }
        );
        this.commentDetailComponent = component;
        this.changeDetectionRef.detectChanges();  
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.commentViews.length == 1) {
            this.singleRecord = true;
        }
    }

    public save(data: CommentView) {
        if (data) {
            this.savedata(data);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private savedata(data: CommentView) {
        if (this.commentViews != null) {
            var filterIndex = this.commentViews.findIndex(x => x.no == data.no);
            if (data.privateCommentFlag === undefined) {
                data.privateCommentFlag = false;
            }

            if (data.warningCommentFlag === undefined) {
                data.warningCommentFlag = false;
            }

            if (data.lockedCommentFlag === undefined) {
                data.lockedCommentFlag = false;
            }

            this.updateCommentViews(filterIndex, data);
        }
        else {
            data.no = 1;
            this.commentViews.push(data);
        }

        this.commentTableComponent.dataGrid.instance.refresh();
    }

    private displayAfterSave() {  
        if (this.commentViews?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private updateCommentViews(filterIndex: number, data: CommentView) {
        if (filterIndex == -1) {
            data.no = this.commentViews.length + 1;
            this.commentViews.push(data);
        }
        else {
            this.commentViews[filterIndex] = data;
        }
    }

    public fillModelToForm(commentModels: CommentView[]) {
        this.clearData();
        if (commentModels && commentModels.length > 0) {
            this.fillModelInCase(commentModels);
        }
        else {
            this.clearDetailForm();
        }
        
        this.commentViews = commentModels;
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.commentView = new CommentView();
    }

    private fillModelInCase(views: CommentView[]) {
        if (views.length == 1) {
            this.fillModelSingleRecord(views[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private clearDetailForm() {
        if (this.commentDetailComponent?.commentDetailForm) {
            this.commentDetailComponent?.commentDetailForm.resetForm(this.commentView);
        }
    }

    private fillModelSingleRecord(view: CommentView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.commentView = view;
        this.changeDetectionRef.detectChanges();
        this.clearDetailForm();
    }

    onFocus(){
        this.focusingService.focus(this.focusingDirective);
    }

    get focusedDetail(): boolean{
        return this.focused;
    }
}
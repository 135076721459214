import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { CommunicationPhoneView } from "../shared/communication-phone-view";

@Component({
    selector: 'op-insight-communication-phone-table',
    templateUrl: './communication-phone-table.component.html'
})
export class InsightCommunicationPhoneTableComponent implements AfterViewInit {
    public selectedItem: CommunicationPhoneView;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightCommunicationPhones: CommunicationPhoneView[] = new Array(); 
    @Output() onEdit = new EventEmitter();
    @Output() onRowSelected = new EventEmitter();

    @ViewChild('dataGridCommunicationPhone', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    selectedRow(e) {
        this.selectedItem = e.data;
        this.onRowSelected.emit();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Type: dat.communicationPhoneTypeName,
                PrimaryName: dat.primaryPhone,
                Prefix: dat.communicationPhonePrefix,
                Number: dat.phoneNumber,
                Extension: dat.extension,
                Comment: dat.commentText,
            }
        ));
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no" ||
            e.column.dataField == "phoneNumber" ||
            e.column.dataField == "extension") {
            e.cellElement.css("text-align", "right");  
        }
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    editInsightCommunicationPhoneDetail() {
        this.onEdit.emit();
    }
}
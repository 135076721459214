import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxDataGridModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { TranslationNameComponent } from "./translation-name.component";
import { TranslationNameMapperService } from "./translation-name.mapper.service";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        PanelsModule,
        DxDataGridModule,
        FocusingModule,
        BsDropdownModule,
        MatIconModule
    ],
    declarations: [TranslationNameComponent],
    exports: [TranslationNameComponent],
    providers: [TranslationNameMapperService]
})
export class TranslationNameModule {

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { DomainReferenceModel } from '../../models/reference-model/reference-general-model/domain-reference.model';

@Injectable({
  providedIn: 'root'
})
export class DomainReferenceService extends ApiServiceBase {
  private readonly PATH = "/system/DomainReference";
  
  constructor(httpClient: HttpClient,
    authService: AuthService) {
    super(httpClient, authService);
  }

  public getDomainReference(): Observable<DomainReferenceModel[]> {
    return this.httpGet<DomainReferenceModel[]>(this.PATH, null)
      .pipe(
        map(response => response.body)
      );
  }
}

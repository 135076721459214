import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class OrganisationSetDefaultService {

    public setDefaultOrgType(f: UntypedFormGroup,config,orgTypeOption) {
        var filterType = config.types.filter(x => x.typeCode == "organisation");
        if (filterType.length != 0) {
            var filterplaceHolder = filterType[0].orgType.filter(x => x.orgTypeCode == "");
            if (filterplaceHolder.length != 0) {
                orgTypeOption.placeholder = filterplaceHolder[0].orgTypeName;
            }
            else {
                if (filterType[0].orgType.length != 0) {
                    f.controls['orgType'].setValue(filterType[0].orgType[0].orgTypeCode);
                }
            }
        }
    }

    public setDefaultOrgTypeOperator(f: UntypedFormGroup,config,orgTypeOperatorOption) {
        var filterType = config.types.filter(x => x.typeCode == "organisation");
        if (filterType.length != 0) {
            let filterOrgType = filterType[0].orgType.filter(x => x.orgTypeCode != "");
            let operatorData = filterOrgType[0].operators;
            f.controls['orgTypeOperatorsData'].setValue(operatorData);
            var filterplaceHolder = operatorData.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                orgTypeOperatorOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                f.controls['orgTypeOperators'].setValue(operatorData[0].operatorCode);
            }
        }
    }

}
import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { VoucherAttributeView } from '../../shared/voucher-attribute.view';
import { select2ChoiceMultiple, select2Attribute, select2AttributeInherit } from '../voucher-attribute-configuration';
import { VoucherAttributeMapperService } from '../../shared/voucher-attribute-mapper.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { cloneDeep } from 'lodash'; 
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-voucher-attribute',
    templateUrl: './voucher-attribute.component.html',
    providers: [
        VoucherAttributeMapperService
    ]
})
export class VoucherAttributeComponent implements AfterViewInit {
    private readonly CHOICE_CODE = 'CHOICE';
    public readonly errorInvalidAttribute = 'Attribute is invalid';
    public focusing: boolean = false;
    public attributeInheritOption: any = select2AttributeInherit;

    public inheritAttributes = new Array<VoucherAttributeView>();
    public selectedAttributes = new Array<VoucherAttributeView>();
    public productAttributesInherit: ProductAttributeViewModel[];

    @Input() searchMode: boolean = false;
    @Input() newVoucher: boolean = false;
    @Input() newFromParent: boolean = false;
    @Input() domainAttribute: DomainAttributeModel[];
    @Input() panelName: string = 'Attribute';

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private voucherAttributeMapperService: VoucherAttributeMapperService,
        private changeDetector: ChangeDetectorRef,
        private dateConverterService: DateConverterService) { }

    ngAfterViewInit(): void {
        if (!this.searchMode && (this.newVoucher || this.newFromParent)) {
            this.displayShowOnNewAndRequiredAttribute();
        }
    }

    public checkHiddenDelete(attribute: VoucherAttributeView): boolean {
        if (attribute?.requiredAttribute === true && this.searchMode === false) {
            return true;
        }
        return false;
    }

    get allowPanelToggle(): boolean {
        return this.selectedAttributes.length > 0
    }

    get panelCollapsed(): boolean {
        return this.selectedAttributes.length == 0;
    }

    public add() {
        if (this.selectedAttributes.some(p => p.attributeCode == null)) {
            return;
        }
        let emptyView = this.createNewEmpty();
        if (!emptyView) {
            this.selectedAttributes.push(emptyView);
        }
    }

    private createNewEmpty(): VoucherAttributeView {
        let availableAttributes = this.createAvailableList(this.selectedAttributes);
        if (!availableAttributes || availableAttributes.length == 0) {
            return;
        }
        return {
            id: null,
            attributeCode: null,
            attributeName: null,
            dimensionUnitCode: null,
            showOnNew: false,
            requiredAttribute: false,
            sortSequence: 0,
            attributes: availableAttributes,
            attributeValue: null,
            multipleChoiceFlag: false,
            attributeChoices: null
        }
    }

    private createAvailableList(selectedAttributes: VoucherAttributeView[]): DomainAttributeModel[] {
        let availableAttribute = new Array<DomainAttributeModel>();
        if (!selectedAttributes || selectedAttributes.length === 0) {
            return this.domainAttribute;
        }

        for (let attribute of this.domainAttribute) {
            if (!selectedAttributes.some(p => p.attributeCode === attribute.attributeTypeCode)) {
                availableAttribute.push({
                    domainAttributeId: attribute.domainAttributeId,
                    domainCode: attribute.domainCode,
                    attributeGroupCode: attribute.attributeGroupCode,
                    attributeTypeCode: attribute.attributeTypeCode,
                    attributeTypeName: attribute.attributeTypeName,
                    dimensionUnitCode: attribute.dimensionUnitCode,
                    minimumValue: attribute.minimumValue,
                    maximumValue: attribute.maximumValue,
                    defaultValue: attribute.defaultValue,
                    showOnNewFlag: attribute.showOnNewFlag,
                    dimensionFlag: attribute.dimensionFlag,
                    startFlag: attribute.startFlag,
                    endFlag: attribute.endFlag,
                    productFlag: attribute.productFlag,
                    requiredFlag: attribute.requiredFlag,
                    multipleChoiceFlag: attribute.multipleChoiceFlag,
                    searchFlag: attribute.searchFlag,
                    sortSequence: attribute.sortSequence,
                    statusCode: attribute.statusCode,
                    scheduleProductFlag: attribute.scheduleProductFlag,
                    operatingProductFlag: attribute.operatingProductFlag,
                    attributeChoices: attribute.attributeChoices

                });
            }
        }
        return availableAttribute;
    }

    public delete(event: MouseEvent, selectedAttribute: VoucherAttributeView) {
        event.preventDefault();
        if (selectedAttribute.requiredAttribute) {
            return;
        }
        this.selectedAttributes = this.selectedAttributes.filter(a => a.attributeCode !== selectedAttribute.attributeCode);
    }

    public onChange(event, selected: VoucherAttributeView) {
        let model = this.domainAttribute.find(a => a.attributeTypeCode === event);
        if (!model) {
            return;
        }
        selected.attributeCode = model.attributeTypeCode;
        selected.attributeName = model.attributeTypeName;
        selected.dimensionUnitCode = model.dimensionUnitCode;
        selected.showOnNew = model.showOnNewFlag;
        selected.requiredAttribute = model.requiredFlag;
        selected.sortSequence = model.sortSequence;
        selected.multipleChoiceFlag = model.multipleChoiceFlag;
        selected.attributeValue = null;        
        if (selected.dimensionUnitCode === this.CHOICE_CODE) {
            selected.attributeChoices = model.attributeChoices?.map(item => new Select2Data(item?.attributeChoiceId, item?.attributeChoiceName));
            this.getFirstOrDefualtAttributeChoice(selected, model);
        }
    }

    private getFirstOrDefualtAttributeChoice(row: VoucherAttributeView, attribute: DomainAttributeModel) {
        if (attribute.multipleChoiceFlag === true) {
            return;
        }
        let defaultChoice = attribute.attributeChoices?.find(item => item.defaultFlag === true);
        let firstChoice = attribute.attributeChoices[0];
        row.attributeValue = defaultChoice?.attributeChoiceId ?? firstChoice?.attributeChoiceId;
    }

    public getChoiceOption(attributeView: VoucherAttributeView): any {
        if (attributeView.dimensionUnitCode === this.CHOICE_CODE) {
            if (attributeView.multipleChoiceFlag) {
                return select2ChoiceMultiple;
            }
        }
        return select2Attribute;
    }

    public requiredSymbol(attribute: DomainAttributeModel): string {
        if (attribute.requiredFlag && this.searchMode === false) {
            return " *";
        }
        return "";
    }

    public getAttribute() {
        let validAttribute = this.selectedAttributes.filter(attribute => attribute.attributeValue);
        return this.voucherAttributeMapperService.voucherAttributesToAttributeCommands(validAttribute);
    }

    public fillAttributesToForm(attributes: ProductAttributeViewModel[], attributeGroupCode: string = 'ATTRIBUTE') {
        this.clearAttribute();
        this.addViewInheritToFormGroup(attributes, attributeGroupCode);
        attributes = attributes?.filter(item => item.inheritAttribute === false);
        for (let attribute of attributes) {
            let attributeRef = this.domainAttribute?.find(item => item.attributeTypeCode == attribute.attributeTypeCode && item.attributeGroupCode == attributeGroupCode);
            if (attributeRef) {
                let row = this.createNewEmpty();
                row.id = attribute.productAttributeId;
                this.onChange(attribute.attributeTypeCode, row);
                this.fillAttributeValue(row, attributes, attribute);
                this.selectedAttributes.push(cloneDeep(row));
            }
        }
    }

    private fillAttributeValue(row: VoucherAttributeView, attributes: ProductAttributeViewModel[], attributeRef: ProductAttributeViewModel) {
        if (attributeRef.dimensionUnitCode == OopsChoiceValueConstant.CHOICE && attributeRef.multipleChoiceFlag === true) {
            this.fillAttributeMultipleChoiceValue(row, attributes, attributeRef);
            return;
        }
        this.assignAttributeValue(row, attributeRef);
    }

    private fillAttributeMultipleChoiceValue(row: VoucherAttributeView, attributes: ProductAttributeViewModel[], attributeRef: ProductAttributeViewModel) {
        let attributeChoices = attributes?.filter(item => item.attributeTypeCode == attributeRef.attributeTypeCode);
        let choiceIds = attributeChoices?.map(item => item.attributeChoiceId);
        row.attributeValue = choiceIds;
    }

    public displayShowOnNewAndRequiredAttribute() {
        if (this.domainAttribute) {
            let required = this.domainAttribute.filter(x => x.requiredFlag == true);
            this.displayAttributes(required);

            let showOnNew = this.domainAttribute.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            this.displayAttributes(showOnNew);

            this.changeDetector.detectChanges();
        }
    }

    private displayAttributes(attributes: DomainAttributeModel[]) {
        for (let attribute of attributes) {
            let row = this.createNewEmpty();
            this.selectedAttributes.push(row);
            this.onChange(attribute.attributeTypeCode, row);
        }
    }

    public clearAttribute() {
        this.selectedAttributes = [];
        this.changeDetector.detectChanges();
    }

    public clearInheritAttribute() {
        this.inheritAttributes = [];
        this.changeDetector.detectChanges();
    }

    public addViewInheritToFormGroup(attributes: ProductAttributeViewModel[], attributeGroupCode: string = 'ATTRIBUTE') {
        this.clearInheritAttribute();
        this.productAttributesInherit = attributes.filter(item => item.inheritAttribute === true && this.checkAttributeGroupTypeInReference(item, attributeGroupCode));
        if (!this.productAttributesInherit) {
            return;
        }
        this.productAttributesInherit.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        let displayDataAdded: Select2Data[] = new Array();
        for (let productAttributeInherit of this.productAttributesInherit) {
            var filterDisplaySequence = displayDataAdded.filter(x => x.id == productAttributeInherit.hierarchyKey && x.text == productAttributeInherit.displaySequence.toString());
            if (filterDisplaySequence.length == 0) {
                this.assignDataToInheritForm(productAttributeInherit);
                var dataAdded = new Select2Data(productAttributeInherit.hierarchyKey,
                    productAttributeInherit.displaySequence.toString());
                displayDataAdded.push(dataAdded);
            }
        }
        this.changeDetector.detectChanges();
    }

    private assignDataToInheritForm(productAttributeInherit: ProductAttributeViewModel) {
        if (productAttributeInherit.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleInheritDataToForm(productAttributeInherit);
            let choiceArrayDBId = this.createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit);
            this.inheritAttributes.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag,
                    choiceMultiple, choiceArrayDBId));
        } else {
            this.inheritAttributes.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag, null, null));
        }
    }

    private createChoiceMultipleInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);;
        }
        return choiceMultiple;
    }

    private createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributesInherit(productAttributeInherit: ProductAttributeViewModel): ProductAttributeViewModel[] {
        return this.productAttributesInherit.filter(x => x.hierarchyKey == productAttributeInherit.hierarchyKey
            && x.displaySequence == productAttributeInherit.displaySequence);
    }

    private createFormGroupWithInheritData(productAttribute: ProductAttributeViewModel,
        multipleChoiceFlag: boolean, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null) {
        let row: VoucherAttributeView = {
            id: productAttribute.productAttributeId,
            attributeCode: productAttribute.attributeTypeCode,
            attributeName: productAttribute.attributeTypeName,
            dimensionUnitCode: productAttribute.dimensionUnitCode,
            showOnNew: null,
            requiredAttribute: productAttribute.requiredFlag,
            sortSequence: productAttribute.displaySequence,
            attributeValue: null,
            multipleChoiceFlag: productAttribute.multipleChoiceFlag,
            attributes: this.createInheritAttributeList(productAttribute),
            attributeChoices: null,
        }
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceInherit(row, choiceArrayDBId, productAttribute);
            this.assignValueToChoiceValue(multipleChoiceFlag, choiceMultipleValue, row, productAttribute);
        } else {
            this.assignAttributeValue(row, productAttribute);
        }
        return row;
    }

    public assignAttributeValue(row: VoucherAttributeView, productAttribute: ProductAttributeViewModel) {
        switch (productAttribute.dimensionUnitCode) {
            case OopsChoiceValueConstant.TEXT:
                row.attributeValue = productAttribute.attributeText;
                break;
            case OopsChoiceValueConstant.TIME:
                row.attributeValue = this.dateConverterService.convertTime24(productAttribute.attributeDateTime);
                break;
            case OopsChoiceValueConstant.PERCENTAGE:
            case OopsChoiceValueConstant.NUMBER: 
                row.attributeValue = productAttribute.attributeValue.toString();
                break;
            case OopsChoiceValueConstant.DATETIME: 
                row.attributeValue = this.dateConverterService.convertDate(productAttribute.attributeDateTime);
                break;
            case OopsChoiceValueConstant.CHOICE:
                row.attributeValue = productAttribute.attributeChoiceId;
            
        }
    }

    private assignValueToChoiceInherit(row: VoucherAttributeView, choiceArrayDBId: Select2Data[], productAttribute: ProductAttributeViewModel) {
        let choiceDataArray: Select2Data[] = new Array();
        if (choiceArrayDBId) {
            choiceDataArray = this.createChoiceMultipleInheritData(choiceArrayDBId);
        } else {
            choiceDataArray = this.createChoiceSingleInheritData(productAttribute);
        }
        row.attributeChoices = choiceDataArray;
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean, choiceMultipleValue: string[], row: VoucherAttributeView, productAttribute: ProductAttributeViewModel) {
        if (multipleChoiceFlag) {
            row.attributeValue = choiceMultipleValue;
        } else {
            row.attributeValue = productAttribute.attributeChoiceId;
        }
    }

    private createChoiceMultipleInheritData(choiceArrayDBId: Select2Data[]): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        if (choiceArrayDBId) {
            for (let choice of choiceArrayDBId) {
                choiceData = new Select2Data();
                choiceData.id = choice.text;
                var filter = this.productAttributesInherit.filter(x => x.attributeChoiceId == choice.text);
                if (filter.length != 0) {
                    choiceData.text = filter[0].attributeChoiceName;
                    choiceDataArray.push(choiceData);
                }
            }
        }
        return choiceDataArray;
    }

    private createChoiceSingleInheritData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        choiceData = new Select2Data();
        choiceData.id = productAttribute.attributeChoiceId;
        choiceData.text = productAttribute.attributeChoiceName;
        choiceDataArray.push(choiceData);
        return choiceDataArray;
    }

    public validateAttribute() {
        if (this.selectedAttributes.length != 0) {
            for (let attribute of this.selectedAttributes) {
                if (attribute.requiredAttribute) {
                    if ((attribute.multipleChoiceFlag && !attribute.attributeValue?.length) ||
                        (!attribute.attributeValue)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    public getErrorMessage() {
        if (!this.validateAttribute()) {
            return this.errorInvalidAttribute;
        }
    }

    private checkAttributeGroupTypeInReference(productAttribute: ProductAttributeViewModel, attributeGroupCode: string) {
        let reference = this.domainAttribute?.find(item => item.attributeTypeCode == productAttribute.attributeTypeCode);
        return reference?.attributeGroupCode == attributeGroupCode;
    }
 
    public createInheritAttributeList(productAttribute: ProductAttributeViewModel): DomainAttributeModel[] {
        let attributeList = new Array<DomainAttributeModel>();
        attributeList.push({
            domainAttributeId: null,
            domainCode: null,
            attributeGroupCode: null,
            attributeTypeCode: productAttribute.attributeTypeCode,
            attributeTypeName: productAttribute.attributeTypeName,
            dimensionUnitCode: null,
            minimumValue: null,
            maximumValue: null,
            defaultValue: null,
            showOnNewFlag: null,
            dimensionFlag: null,
            startFlag: null,
            endFlag: null,
            productFlag: null,
            requiredFlag: productAttribute.requiredFlag,
            multipleChoiceFlag: productAttribute.multipleChoiceFlag,
            searchFlag: null,
            sortSequence: productAttribute.sortSequence,
            statusCode: null,
            scheduleProductFlag: null,
            operatingProductFlag: null,
            attributeChoices: null
        });
        return attributeList;
    }
   
}

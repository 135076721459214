import { Injectable } from "@angular/core";
import { VerifyResultModel } from "src/app/core/models/product-model/operating-product-model";
import { VerifyResultView } from "./verify-result.view";

@Injectable()
export class verifyResultConverter {
    public toViews(models: VerifyResultModel[]): VerifyResultView[] {
        let views = new Array<VerifyResultView>();
        for (let i = 0; i < models?.length; i++) {
            views.push(this.toView(i + 1, models[i]));
        }
        return views;
    }

    public toView(no: number, model: VerifyResultModel): VerifyResultView {
        return {
            arrival: model.arrivalTime,
            date: model.date,
            day: model.day,
            departure: model.departureTime,
            no: no,
            operationalStatus: model.operationalStatus,
            result: model.result,
            scheduleStatus: model.scheduleStatus,
            noErrorFlag: model.noErrorFlag,
            verification: model.verification
        }
    }
}
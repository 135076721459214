import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'dataGridRowNumber'
})
export class DataGridRowNumberPipe implements PipeTransform {
    readonly lblFound = 'dataGridRowNumber'

    transform(data: any): number {
        if (data?.rowType == 'data') {
            return data.component.pageIndex() * data.component.pageSize() + data.row.dataIndex + 1;
        }
        return null;
    }
}

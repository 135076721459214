import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SettingsManagementService } from 'src/app/core/services/system-services/settings-management.service';
import * as settings from 'src/app/store/settings';

@Component({
  selector: 'oops-nav-menu-toggle',
  templateUrl: './nav-menu-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMenuToggleComponent {
  @Input() minified: boolean;

  constructor(private store: Store<any>,
    private settingService: SettingsManagementService,
    private router: Router) { }

  toggleMinifyNavigation($event: MouseEvent) {
    const authPaths = ['/auth', '/authentication', '/select-organisation'];
    if (authPaths.includes(this.router.url)) {
      return;
    }
    const minifyState = this.settingService.getNavBarMinifyState()
    this.store.dispatch(settings.toggleMinifyNavigation({ 
      minifyNavigation: !minifyState 
    }));
  }
}

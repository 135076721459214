import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";

export const PRODUCT_DASHBOARD_SPECIALSERVICE_COMPARE_MONM_DEMO: OrdersDashboardCompareValuesModel[] = [
    {
        dayNo: 1,
        value1: 90,
        value1Name: "2021",
        value2: 170,
        value2Name: "2022"
    },
    {
        dayNo: 2,
        value1: 210,
        value1Name: "2021",
        value2: 142,
        value2Name: "2022"
    },
    {
        dayNo: 3,
        value1: 74,
        value1Name: "2021",
        value2: 92,
        value2Name: "2022"
    },
    {
        dayNo: 4,
        value1: 101,
        value1Name: "2021",
        value2: 221,
        value2Name: "2022"
    },
    {
        dayNo: 5,
        value1: 70,
        value1Name: "2021",
        value2: 84,
        value2Name: "2022"
    },
    {
        dayNo: 6,
        value1: 248,
        value1Name: "2021",
        value2: 150,
        value2Name: "2022"
    },
    {
        dayNo: 7,
        value1: 222,
        value1Name: "2021",
        value2: 178,
        value2Name: "2022"
    },
    {
        dayNo: 8,
        value1: 211,
        value1Name: "2021",
        value2: 109,
        value2Name: "2022"
    },
    {
        dayNo: 9,
        value1: 60,
        value1Name: "2021",
        value2: 204,
        value2Name: "2022"
    },
    {
        dayNo: 10,
        value1: 92,
        value1Name: "2021",
        value2: 155,
        value2Name: "2022"
    },
    {
        dayNo: 11,
        value1: 143,
        value1Name: "2021",
        value2: 121,
        value2Name: "2022"
    },
    {
        dayNo: 12,
        value1: 82,
        value1Name: "2021",
        value2: 189,
        value2Name: "2022"
    },
    {
        dayNo: 13,
        value1: 95,
        value1Name: "2021",
        value2: 155,
        value2Name: "2022"
    },
    {
        dayNo: 14,
        value1: 89,
        value1Name: "2021",
        value2: 103,
        value2Name: "2022"
    },
    {
        dayNo: 15,
        value1: 204,
        value1Name: "2021",
        value2: 172,
        value2Name: "2022"
    },
    {
        dayNo: 16,
        value1: 242,
        value1Name: "2021",
        value2: 233,
        value2Name: "2022"
    },
    {
        dayNo: 17,
        value1: 58,
        value1Name: "2021",
        value2: 118,
        value2Name: "2022"
    },
    {
        dayNo: 18,
        value1: 179,
        value1Name: "2021",
        value2: 106,
        value2Name: "2022"
    },
    {
        dayNo: 19,
        value1: 249,
        value1Name: "2021",
        value2: 132,
        value2Name: "2022"
    },
    {
        dayNo: 20,
        value1: 207,
        value1Name: "2021",
        value2: 121,
        value2Name: "2022"
    },
    {
        dayNo: 21,
        value1: 76,
        value1Name: "2021",
        value2: 229,
        value2Name: "2022"
    },
    {
        dayNo: 22,
        value1: 86,
        value1Name: "2021",
        value2: 51,
        value2Name: "2022"
    },
    {
        dayNo: 23,
        value1: 208,
        value1Name: "2021",
        value2: 109,
        value2Name: "2022"
    },
    {
        dayNo: 24,
        value1: 122,
        value1Name: "2021",
        value2: 145,
        value2Name: "2022"
    },
    {
        dayNo: 25,
        value1: 173,
        value1Name: "2021",
        value2: 69,
        value2Name: "2022"
    },
    {
        dayNo: 26,
        value1: 242,
        value1Name: "2021",
        value2: 154,
        value2Name: "2022"
    },
    {
        dayNo: 27,
        value1: 59,
        value1Name: "2021",
        value2: 248,
        value2Name: "2022"
    },
    {
        dayNo: 28,
        value1: 227,
        value1Name: "2021",
        value2: 202,
        value2Name: "2022"
    }, 
    {
        dayNo: 29,
        value1: 219,
        value1Name: "2021",
        value2: 157,
        value2Name: "2022"
    },
    {
        dayNo: 30,
        value1: 159,
        value1Name: "2021",
        value2: 118,
        value2Name: "2022"
    }
]

export const select2ServiceTypeOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
export const select2FlightStatusOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
export const select2ProviderCodeOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
export const select2VehicleTypeOption = {
    placeholder: "",
    allowClear: true
}
export const select2VehicleConfigurationOption = {
    placeholder: "",
    allowClear: true
}
export const select2VehicleOption = {
    placeholder: "",
    allowClear: true
}
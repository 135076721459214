import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { select2Inherit, validityWeekDaysOption } from './validity-inherit-configuration';
import {
    validityDateOption, validityDateTimeOption, validityTimeOption, select2DayStart, select2DayEnd
} from '../../shared/calendar/calendar-config-configuration';

import {
    DayModel,
    DateTypeModel,
    OperatorModel,
    TimeZoneModel
} from 'src/app/core/models/merchandizing-config';

import {
    ConditionReferenceModel,
    CalendarValidityReferenceModel,
    DateTimeDimensionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';

import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';

import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { CalendarDataService } from '../../shared/calendar/calendar-data.service';
import { CalendarSetDefaultService } from '../../shared/calendar/calendar-set-default.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-validity-inherit',
    templateUrl: './validity-inherit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidityInheritComponent implements OnInit {

    public readonly timeFormat: string = "HH:mm";
    private readonly dateTimeFormat: string = "YYYY-MM-DD HH:mm";
    private readonly dateFormat: string = "YYYY-MM-DD";

    @Input() config: any;
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    public typeOption: any = select2Inherit;
    public dateTypeOption: any = select2Inherit;
    public dateTypes$ = new BehaviorSubject<DateTypeModel[]>(null);
    public calendarTypeOption: any = select2Inherit;
    public timeZoneOption: any = select2Inherit;
    public calendarOperatorOption: any = select2Inherit;
    public daterangepickerTimeOption: any;
    public startDayOption: any = select2DayStart;
    public days$ = new BehaviorSubject<DayModel[]>(null);
    public endDayOption: any = select2DayEnd;
    public weekDaysOption: any = validityWeekDaysOption;
    public weekDayData: any = this.calendarDataService.getWeekDayData();
    public inheritForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private dateConverterService: DateConverterService,
        private calendarSetDefaultService: CalendarSetDefaultService,
        private calendarDataService: CalendarDataService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    ngOnInit(): void {
        this.getCalendarData();
        this.getDayData();
    }

    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    public addValidityInheritToFormGroup(productValidities: ProductValidityViewModel[]) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });

        for (let productValidity of productValidities) {
            (<UntypedFormArray>this.inheritForm.get('inheritforms')).push(this.createFormGroupProductValidity(productValidity));
        }

        this.changeDetectorRef.detectChanges();
    }

    private createFormGroupProductValidity(productValidity: ProductValidityViewModel) {
        let group = {
            'type': [{ value: 'calendar', disabled: true }, [Validators.nullValidator]],
            'calendarShow': [true, [Validators.nullValidator]]
        };

        group['productValidityId'] = [null, [Validators.nullValidator]];
        group['dateType'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['calendarType'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['calendarTypeData'] = new BehaviorSubject<OperatorModel[]>(null);
        group['timeZone'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['timeZoneData'] = new BehaviorSubject<TimeZoneModel[]>(null);
        group['calendarOperator'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['calendarOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);
        group['timeZoneDisplay'] = [true, [Validators.nullValidator]];
        group['startDate'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['endDate'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['daterangepickerOption'] = [null, [Validators.nullValidator]];
        group['dateendpickerOption'] = [null, [Validators.nullValidator]];
        group['startDatePlaceHolder'] = ['<Enter Date>', [Validators.nullValidator]];
        group['dateTimeFormat'] = [this.dateFormat, [Validators.nullValidator]];
        group['startTime'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['endTime'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['startDay'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['endDay'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['startDayPlaceHolder'] = ['<Enter Day>', [Validators.nullValidator]];
        group['weekDays'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
        group['weekDaysDisplay'] = [false, [Validators.nullValidator]];

        let f = this.fb.group(group);
        this.setDefaultDateType(f);

        f.controls['productValidityId'].setValue(productValidity.productValidityId);
        f.controls['dateType'].setValue(productValidity.calendarValidityCode);
        f.controls['calendarType'].setValue(productValidity.dateTimeDimensionCode);
        f.controls['calendarOperator'].setValue(productValidity.conditionCode);
        f.controls['timeZone'].setValue((productValidity.utcFlag) ? "utc" : "local");
        switch (productValidity.dateTimeDimensionCode.toLowerCase()) {
            case 'date': {
                f.controls['timeZoneDisplay'].setValue(true);
                f.controls['dateTimeFormat'].setValue(this.dateFormat);
                f.controls['startDate'].setValue(this.dateConverterService.toDateFormat(productValidity.startDateTime));
                if (productValidity.conditionCode == "<>" || productValidity.conditionCode == "><") {
                    f.controls['endDate'].setValue(this.dateConverterService.toDateFormat(productValidity.endDateTime));
                    f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
                    f.controls['weekDaysDisplay'].setValue(true);
                }
                break;
            }
            case 'datetime': {
                f.controls['timeZoneDisplay'].setValue(true);
                f.controls['startDate'].setValue(this.dateConverterService.convertDateTime24(productValidity.startDateTime));
                f.controls['dateTimeFormat'].setValue(this.dateTimeFormat);
                if (productValidity.conditionCode == "<>" || productValidity.conditionCode == "><") {
                    f.controls['endDate'].setValue(this.dateConverterService.convertDateTime24(productValidity.endDateTime));
                    f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
                    f.controls['weekDaysDisplay'].setValue(true);
                }
                break;
            }
            case 'time': {
                f.controls['timeZoneDisplay'].setValue(true);
                f.controls['dateTimeFormat'].setValue(this.timeFormat);
                this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(f, this.config, this.dateTypes$, validityTimeOption);
                f.controls['daterangepickerOption'].setValue(this.daterangepickerTimeOption);
                f.controls['startTime'].setValue(this.dateConverterService.convertTime24(productValidity.startDateTime));
                if (productValidity.conditionCode == "<>" || productValidity.conditionCode == "><") {
                    f.controls['endTime'].setValue(this.dateConverterService.convertTime24(productValidity.endDateTime));
                    f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
                    f.controls['weekDaysDisplay'].setValue(true);
                }
                break;
            }
            case 'weekday': {
                f.controls['timeZoneDisplay'].setValue(false);
                f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
                f.controls['weekDaysDisplay'].setValue(true);
                break;
            }
        }
        this.calendarDataService.getCalendarType(f, this.calendarTypeOption, this.dateTypes$, true);
        this.calendarDataService.getTimeZone(f, this.timeZoneOption, this.dateTypes$);
        this.calendarDataService.getCalendarOperator(f, this.calendarOperatorOption, this.dateTypes$, true);
        this.setDateTimeFormat(f);
        return f;
    }


    private getCalendarData() {
        let values = this.calendarDataService.getCalendarData(this.config, this.calendarValidityReferences$,
            this.dateTimeDimensionReferences$, this.conditionReferences$);
        if (values) {
            this.dateTypes$.next(values);
        }
    }

    private getDayData() {
        let day = new DayModel();
        let days = new Array();
        for (var i = 1; i <= 31; i++) {
            day = new DayModel();
            day.dayCode = i.toString();
            day.dayName = i.toString();
            days.push(day);
        }
        this.days$.next(days);
    }

    private setDefaultDateType(f: UntypedFormGroup) {
        var filterType = this.config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            var filterplaceHolder = filterType[0].dateType.filter(x => x.dateTypeCode == "");
            if (filterplaceHolder.length != 0) {
                this.dateTypeOption.placeholder = filterplaceHolder[0].dateTypeName;
            }
            else {
                if (filterType[0].dateType.length != 0) {
                    if (this.dateTypes$.value) {
                        var datType = this.dateTypes$.value;
                        f.controls['dateType'].setValue(datType[0].dateTypeCode);
                        this.calendarDataService.getCalendarType(f, this.calendarTypeOption, this.dateTypes$);
                        this.calendarDataService.getTimeZone(f, this.timeZoneOption, this.dateTypes$);
                        this.calendarDataService.getCalendarOperator(f, this.calendarOperatorOption, this.dateTypes$);
                        this.calendarDataService.getShowWeekDays(f, this.dateTypes$);
                    }
                }
            }
        }
    }

    private setDateTimeFormat(f: UntypedFormGroup) {
        let calendarTypeValue = f.get('calendarType').value;

        if (calendarTypeValue.toLowerCase() == "time") {
            this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(f, this.config, this.dateTypes$, validityTimeOption);
        }
        else {
            this.calendarSetDefaultService.setDateTimeFormat(f, this.config, this.dateTypes$,
                validityDateOption,
                validityDateTimeOption,
                this.dateFormat,
                this.dateTimeFormat);
        }
    }

    public clearForm() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }
}
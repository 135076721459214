import {
    Directive,
    ElementRef,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    Input,
    Self,
    Optional,
    OnDestroy
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Select2Data } from './select2-data';

declare var $: any;

@Directive({
    selector: '[select2]'
})
export class Select2Directive implements OnInit, AfterViewInit, OnDestroy {

    private _select2Options: any;
    private _optionList: Select2Data[];
    private _data: Select2Data[];
    private _value: string|string[];
    private _previousValue: string|string[];

    @Input()
    set optionList(values: Select2Data[]) {
        this._optionList = values;
        this.reInitializeSelect2();
    }

    @Input()
    set optionListWithReInitialize(values: Select2Data[]) {
        this._optionList = values;
        this.reInitializeSelect2();
    }

    @Input()
    set option(value: any) {
        this._select2Options = value;
    }

    @Input()
    set data(value: string | Array<string>) {
        this._value = value;
        if (this._optionList) {
            this._select2Options.data = this._optionList;
            $(this.el.nativeElement).select2(this._select2Options);
        }
        this.setValue(value);
    }

    @Input() sortable: boolean = false;

    @Input() blurAfterSelected: boolean = false;

    @Output()
    dataChange: EventEmitter<string | Array<string>> = new EventEmitter();
    @Output() action = new EventEmitter<any>();

    constructor(private el: ElementRef,
        @Self()
        @Optional()
        private control: NgControl) { }
    ngOnDestroy(): void {
        if ($(this.el.nativeElement).hasClass('select2-hidden-accessible')) {
            $(this.el.nativeElement).select2('destroy')
            $(this.el.nativeElement).empty()
        }
    }

    ngOnInit(): void {
        this.initializeSelect2();
    }

    ngAfterViewInit(): void {
        $(this.el.nativeElement).on('select2:open', (event) => {
            this.markControlAsTouch();
        });

        $(this.el.nativeElement).on('select2:close', (event) => {
            if (this.blurAfterSelected) {
                setTimeout(function() {
                    $(':focus').blur();
                }, 1);
            }   
        });

        $(this.el.nativeElement).on('select2:selecting', (event) => {
            const action = event.params?.args?.data?.action;
            if (action) {
                this._previousValue = $(this.el.nativeElement).val();
            }
        });
        $(this.el.nativeElement).on('select2:select', (event) => {
            const action = event.params?.data?.action;
            if (action) {
                this._value = this._previousValue;
                this.setValue(this._value);
                this.action.emit(action);
                return;
            }
            if (this.sortable) {
                this.sortDataOptionBySelectedSequence();
            }
            this.notifyChange();
        });

        $(this.el.nativeElement).on('select2:clear', (event) => {
            this.dataChange.emit(null);
        });

        $(this.el.nativeElement).on('select2:unselect', (event) => {
            if (this.sortable) {
                this.moveUnselectOptionToPreviousIndex();
            }
            this.notifyChange();
        });

        $(this.el.nativeElement).on('select2:unselecting', (event) => {
            if (event.params.args.data.locked) {
                return false;
            }
        });
    }

    private sortDataOptionBySelectedSequence() {
        let $instance = $(this.el.nativeElement);
        let val = $instance.val();
        let $options = $instance.children();

        for (let i = val.length - 1; i >= 0; i--) {
            let v = val[i];
            let $option = $($options.filter(`[value=${v}]`)[0]);
            $option.detach();
            $instance.prepend($option);
        }
        $instance.trigger('change');
    }

    private moveUnselectOptionToPreviousIndex() {
        let $instance = $(this.el.nativeElement);
        let val = $instance.val();
        let $options = $instance.children();
        if (this._data) {
            for (let data of this.getSelect2DataListWhichExcludedFromValues(val)) {
                let $option = $($options.filter(`[value=${data.id}]`)[0]);
                if ($option) {
                    $option.detach();
                    $instance.append($option);
                }
            }
        }
        $instance.trigger('change');
    }

    private getSelect2DataListWhichExcludedFromValues(values: any[]): Select2Data[] {
            return this._data.filter(d => values.includes(d.id) == false);
    }

    private notifyChange() {
        let currentData = $(this.el.nativeElement).val();
        this.dataChange.emit(currentData);

    }

    private setReactiveFormValue(value: string | Array<string>) {
        if (!this.control?.control) {
            return;
        }
        this.control.control.setValue(value);
    }

    private markControlAsTouch() {
        if (!this.control) {
            return;
        }
        this.control.control.markAsTouched();
    }

    private setValue(value: string | Array<string>) {
        this.setReactiveFormValue(value);
        $(this.el.nativeElement).val(value).trigger('change');
    }

    private initializeSelect2(): void {
        this._select2Options.data = this.generateDataFromOption();
        this._data = this.generateDataFromOption();
        $(this.el.nativeElement).select2(this._select2Options);
    }

    private reInitializeSelect2(): void {
        if (!this._select2Options) {
            return;
        }

        if ($(this.el.nativeElement).data('select2')) {
            $(this.el.nativeElement).select2('destroy');
            $(this.el.nativeElement).empty();
        }

        this._select2Options.data = this.generateDataFromOptionList();
        this._data = this.generateDataFromOptionList();
        $(this.el.nativeElement).select2(this._select2Options);
        
        if (this._value) {
            this.filterNotExistDataFromValue();
            this.setValue(this._value);
        }
    }

    private filterNotExistDataFromValue() {
        if (Array.isArray(this._value)) {
            this._value = this._value.filter(v => this._data?.map(d => d.id).includes(v));
        }
    }

    private generateDataFromOptionList() {
        if (!this._optionList) {
            return;
        }
        let datas = [];
        for (let option of this._optionList) {
            datas.push({
                id: option.id,
                text: option.text,
                action: option.action
            });
        }
        datas = this.sortOptionList(datas);
        return datas;
    }

    private sortOptionList(datas: Select2Data[]): any {
        if (this.sortable && this._value && Array.isArray(this._value)) {
            let sortedDatas = [];
            for (let val of this._value) {
                let dataIncludedInValue = datas.filter(data => data.id == val);
                if (dataIncludedInValue?.length) {
                    sortedDatas.push(dataIncludedInValue[0]);
                }
            }
            let dataNotIncludedInSortedData = datas.filter(data => this._value.includes(data.id) == false);
            if (dataNotIncludedInSortedData?.length) {
                for (let data of dataNotIncludedInSortedData) {
                    sortedDatas.push(data);
                }
            }
            return sortedDatas;
        }
        return datas;
    }

    private generateDataFromOption() {

        let element = $(this.el.nativeElement);
        if (!element || element.length == 0) {
            return null;
        }
        let datas = [];
        if (!element[0].options || element[0].options.length == 0) {
            return;
        }
        for (let option of element[0].options) {
            datas.push({
                id: option.value,
                text: option.text,
                action: option.action
            });
        }
        return datas;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialServiceComponent } from './special-service.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SpecialServiceContentModule } from './special-service-content/special-service-content.module';

@NgModule({
  declarations: [
      SpecialServiceComponent
  ],
  imports: [
    CommonModule,
    SpecialServiceContentModule,
    UiModule
  ]
})
export class SpecialServiceModule { }

import { CommonModule } from '@angular/common';
import { PricingContentModule } from './pricing-content/pricing-content.module';
import { PricingRoutingModule } from './pricing-routing.module';
import { NgModule } from '@angular/core';

import { PricingComponent } from './pricing.component';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';

@NgModule({
    declarations: [
        PricingComponent,
    ],
    imports: [
        CommonModule,
        DynamicRoutingModule,
        LoadingSpinnerModule,
        PricingContentModule,
        PricingRoutingModule
    ]
})
export class PricingModule { }
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_SOLD_LAST7D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 12,
        salesChannel1Name: "GDS",
        salesChannel2: 24,
        salesChannel2Name: "API",
        salesChannel3: 24,
        salesChannel3Name: "ISE",
        salesChannel4: 40,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 15,
        salesChannel1Name: "GDS",
        salesChannel2: 31,
        salesChannel2Name: "API",
        salesChannel3: 42,
        salesChannel3Name: "ISE",
        salesChannel4: 12,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 22,
        salesChannel1Name: "GDS",
        salesChannel2: 32,
        salesChannel2Name: "API",
        salesChannel3: 41,
        salesChannel3Name: "ISE",
        salesChannel4: 5,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 53,
        salesChannel1Name: "GDS",
        salesChannel2: 16,
        salesChannel2Name: "API",
        salesChannel3: 14,
        salesChannel3Name: "ISE",
        salesChannel4: 17,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 29,
        salesChannel1Name: "GDS",
        salesChannel2: 34,
        salesChannel2Name: "API",
        salesChannel3: 21,
        salesChannel3Name: "ISE",
        salesChannel4: 16,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 30,
        salesChannel1Name: "GDS",
        salesChannel2: 17,
        salesChannel2Name: "API",
        salesChannel3: 22,
        salesChannel3Name: "ISE",
        salesChannel4: 31,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 44,
        salesChannel1Name: "GDS",
        salesChannel2: 20,
        salesChannel2Name: "API",
        salesChannel3: 12,
        salesChannel3Name: "ISE",
        salesChannel4: 24,
        salesChannel4Name: "OWN"
    }
]
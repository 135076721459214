import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { PriceModel } from "../../prices/shared/models";

@Injectable()
export class DetailsConverter {
    private readonly defaultStatusCode = 'A';

    constructor(private dateConverterService: DateConverterService) {

    }

    public toFormGroup(model: PriceModel, defaultUsageTypeCode: string): UntypedFormGroup {
        return new UntypedFormGroup({
            organisationId: new UntypedFormControl(model?.organisationId),
            priceId: new UntypedFormControl(model?.priceId),
            priceCode: new UntypedFormControl(model?.priceCode),
            priceName: new UntypedFormControl(model?.priceName, Validators.required),
            usageTypeCode: new UntypedFormControl({
                value: model?.usageTypeCode ?? defaultUsageTypeCode,
                disabled: true
            }),
            status: new UntypedFormControl(model?.status ?? this.defaultStatusCode),
            commitBy: new UntypedFormControl({
                value: model?.commitBy,
                disabled: true
            }),
            commitDateTime: new UntypedFormControl({
                value: this.dateConverterService.convertDateTime(model?.commitDateTime),
                disabled: true
            })
        })
    }
}
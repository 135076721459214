import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { VehicleCompositionModel } from 'src/app/core/models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class VehicleCompositionService extends ApiServiceBase {
    private readonly PATH = "/airline/vehiclecompositions/"

    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    public getAll(): Observable<VehicleCompositionModel[]> {
        return this.httpGet<VehicleCompositionModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getVehicleComposition(vehicleTypeCode: string): Observable<VehicleCompositionModel[]> {
        return this.httpGet<VehicleCompositionModel[]>(this.PATH + vehicleTypeCode, null)
            .pipe(
                map(res => res.body)
            )
    }
    
    public getVehicleCompositionAll(): Observable<VehicleCompositionModel[]> {
        return this.httpGet<VehicleCompositionModel[]>(this.PATH + 'organisation', null)
            .pipe(
                map(res => res.body)
            )
    }
}
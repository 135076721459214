import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import {
  ProductTypeReferenceAddCommandModel,
  ProductTypeReferenceEditCommandModel,
  ProductTypeReferenceModel
} from "src/app/core/models/reference-model/reference-product-model";
import { ProductTypeReferenceUpdateStatusCommandModel } from "src/app/core/models/reference-model/reference-product-model/product-type-reference-update-status-command.model";

@Injectable({
  providedIn: 'root'
})
export class ProductTypeReferenceService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/airline"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public searchProductTypeReference(productTypeCode: string, productTypeName: string, statusCode: string) {
    let params = new HttpParams()
      .set("code", productTypeCode)
      .set("name", productTypeName)
      .set("status", statusCode);

    return this.httpGet<any>(this.PRODUCT_PATH + "/productTypeReferences", params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public searchProductTypeReferenceByCode(productTypeCode: string) {
    return this.httpGet<any>(this.PRODUCT_PATH + "/productTypeReferences/" + productTypeCode, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public editProductTypeReference(productTypeCode: string, model: ProductTypeReferenceEditCommandModel): Observable<ProductTypeReferenceModel> {
    return this.httpPut<ProductTypeReferenceModel>(this.PRODUCT_PATH + "/productTypeReferences/" + productTypeCode, model)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public addProductTypeReference(model: ProductTypeReferenceAddCommandModel): Observable<ProductTypeReferenceModel> {
    return this.httpPost<ProductTypeReferenceModel>(this.PRODUCT_PATH + "/productTypeReferences/", model)
      .pipe(
        map(response => {
           return response.body;
        })
      );
  }

  public updateStatus(command: ProductTypeReferenceUpdateStatusCommandModel) {
    return this.httpPut(this.PRODUCT_PATH + "/productTypeReferences/Status", command);
  }
}
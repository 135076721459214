import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { ProductCategory } from './product-categories/product-category';
import { PRODUCT_TABS } from './product-tabs/product-tabs.constant';

@Component({
    selector: 'op-product-content',
    templateUrl: './product-management-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class ProductManagementContentComponent {
    readonly DEFAULT_TITLE = 'Product Management';
    @ViewChild('productTabs') productTabs: any;

    public selectedItem: ProductCategory;
    public title: String = this.DEFAULT_TITLE;

    constructor(public alertBarService: AlertBarService) { }

    public onSelectedTabChange(activeTabId: string) {
        if (activeTabId == PRODUCT_TABS.search) {
            if (this.selectedItem) {
                this.title = this.selectedItem.name + ' Management';
                return;
            }
        }
        this.title = this.DEFAULT_TITLE;
    }

    public onRowSelected(item: ProductCategory) {
        this.selectedItem = item;
    }

    public onAlertBarClicked() {
        this.productTabs.alertBarClicked();
    }
}

import { NgModule } from "@angular/core";
import { AddressTypeSelectionComponent } from "./address-type-selection.component";
import { CommonModule } from "@angular/common";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";

@NgModule({
    declarations: [
        AddressTypeSelectionComponent
    ],
    imports: [
        CommonModule,
        Select2Module
    ],
    exports: [
        AddressTypeSelectionComponent
    ]
})
export class AddressTypeSelectionModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { CountryReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class CountryReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/airline/Countries"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {
        super(httpClient, authService);
    }

    public getOrganisationCountries(): Observable<CountryReferenceModel[]> {
        return this.httpGet<CountryReferenceModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from "@angular/material/icon";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DxDataGridModule } from 'devextreme-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PanelsModule } from '../../../shared/panels/panels.module';
import { Select2Module } from '../../../shared/ui/forms/inputs/oops-select2/select2.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DaterangepickerModule } from '../../../shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { Helper } from '../../../shared/helper/app.helper';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { LockModule } from 'src/app/shared/ui/forms/inputs/lock/lock.module';
import { CommentComponent } from './comment.component';
import { CommentTableComponent } from './comment-table/comment-table.component';
import { CommentDetailComponent } from './comment-detail/comment-detail.component';
import { CommentDetailDirective } from './comment-detail/comment-detail.directive';
import { CommentDetailImageComponent } from './comment-detail-image/comment-detail-image.component';


@NgModule({
    declarations: [
        CommentComponent,
        CommentDetailImageComponent,
        CommentTableComponent,
        CommentDetailComponent,
        CommentDetailDirective
    ],
    exports: [
        CommentComponent,
        CommentDetailImageComponent,
        CommentTableComponent,
        CommentDetailComponent,
        CommentDetailDirective
    ],
    imports: [
        CommonModule,
        PanelsModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        NgbModule,
        Select2Module,
        CollapseModule,
        DaterangepickerModule,
        NgxDropzoneModule,
        NgxSummernoteModule,
        ButtonsModule,
        BsDropdownModule,
        UtilsModule,
        TooltipModule,
        DxDataGridModule,
        SweetAlert2Module,
        FocusingModule,
        LockModule
    ],
    providers: [
        Helper
    ]
})
export class CommentModule {
}
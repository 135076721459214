import { Action, createReducer, on } from "@ngrx/store";
import { NavigationActionModel } from "src/app/core/models/navigation-action/navigation-action.model";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import * as ActionActions from "./navigation-action.actions"

export const navigationActionFeatureKey = 'navigationAction';

export interface NavigationActionState {
    navigationAction: NavigationActionModel;
}

export const initialState: NavigationActionState = {
    navigationAction: <NavigationActionModel>{}
}

export const navigationActionReducer = createReducer(
    initialState,
    on(ActionActions.addNavigationAction, (state, navigationAction) => {
        setParameter(navigationAction);
        return {
            ...state,
            navigationAction
        }
    })
);

function setParameter(navigationAction: NavigationActionModel): NavigationActionModel {
    if (navigationAction.action == FavoriteConstant.SEARCH_ACTION && navigationAction.parameter) {
        navigationAction.parameter = 'filter ' + navigationAction.parameter;
    }
    return navigationAction;
}

export function reducer(state: NavigationActionState | undefined, action: Action): any {
    return navigationActionReducer(state, action);
}
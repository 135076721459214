import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MerchandizeDetailComponent } from './product-categories-content/merchandize/merchandize-detail/merchandize-detail.component';
import { ProductManagementContentComponent } from './product-management-content/product-management-content.component';
import { ProductManagementComponent } from './product-management.component';
import { SpecialServiceDetailComponent } from './product-categories-content/special-service/special-service-content/special-service-detail/special-service-detail.component';
import { TransportDetailComponent } from './product-categories-content/transport/transport-content/transport-detail/transport-detail.component';
import { TransportComponent } from './product-categories-content/transport/transport.component';
import { SpecialServiceComponent } from './product-categories-content/special-service/special-service.component';
import { AncillaryServiceComponent } from './product-categories-content/ancillary-service/ancillary-service.component';
import { FeeDetailComponent } from './product-categories-content/fee/fee-detail/fee-detail.component';
import { AncillaryServiceDetailComponent } from './product-categories-content/ancillary-service/ancillary-service-content/ancillary-service-detail/ancillary-service-detail.component';
import { VoucherComponent } from './product-categories-content/voucher/voucher.component';
import { VoucherDetailComponent } from './product-categories-content/voucher/voucher-detail/voucher-detail.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: ProductManagementComponent,
        children: [
            {
                path: 'merchandises/details',
                component: MerchandizeDetailComponent
            },
            {
                path: 'services',
                component: SpecialServiceComponent,
                children: [
                    {
                        path: 'details',
                        component: SpecialServiceDetailComponent
                    }
                ]
            },
            {
                path: 'transport',
                component: TransportComponent,
                children: [
                    {
                        path: 'details',
                        component: TransportDetailComponent,
                        children: [
                            {
                                path: 'schedule-change',
                                loadChildren: () => import('./product-categories-content/transport/transport-content/transport-detail/schedule-change/schedule-change.module')
                                    .then(m => m.ScheduleChangeModule)
                            }
                        ]
                    },
                ]
            },
            
            {
                path: 'ancillary',
                component: AncillaryServiceComponent,
                children: [
                    {
                        path: 'details',
                        component: AncillaryServiceDetailComponent
                    },
                ]
            },
            {
                path: 'fee/details',
                component: FeeDetailComponent
            },
            {
                path: 'voucher',
                component: VoucherComponent,
                children: [
                    {
                        path: 'details',
                        component: VoucherDetailComponent
                    },
                ]
            }
        ]
    },
    {
        path: 'home',
        component: ProductManagementContentComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ProductManagementRoutingModule { }

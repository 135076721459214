import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SecurityLevel2TabsComponent } from './security-level2-tabs.component';
import { SecurityLevel2TableComponent } from '../security-level2-table/security-level2-table.component';
import { SecurityLevel2TabsDirective } from './security-level2-tabs.directive';
import { SecurityLevel2TableModule } from '../security-level2-table/security-level2-table.module';

@NgModule({
    declarations: [
        SecurityLevel2TabsComponent,
        SecurityLevel2TabsDirective
    ],
    imports: [
        CommonModule,
        NgbNavModule,
        SecurityLevel2TableModule
    ],
    exports: [
        SecurityLevel2TabsComponent
    ]
})
export class SecurityLevel2TabsModule { }

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import { SalesBucketMapperService } from './salesbucket-mapper.service';
import { SalesBucketTableView } from './salesbucket-table.view';
import { ProductInventorySalesBucketViewModel } from 'src/app/core/models/product-model/transport-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { NumberHelperService } from 'src/app/core/utils/number-helper.service';

@Component({
    selector: 'op-salesbucket',
    templateUrl: './salesbucket.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SalesBucketMapperService]
})
export class SalesBucketComponent implements AfterViewInit {

    public readonly EXPORT_FILE_NAME = "SalesBucket";
    @Input() id: string;
    @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public productInventorySalesBuckets: ProductInventorySalesBucketViewModel[];
    public saleBuckets: SalesBucketTableView[];
    private copyItem: SalesBucketTableView;
    public selectedItem: SalesBucketTableView;
    public focusing: boolean = false;
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };
    public suppressZero: any;

    constructor(
        private salesBucketMapperService: SalesBucketMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private numberHelperService: NumberHelperService) {
        this.suppressZero = this.numberHelperService.suppressZero.bind(this);
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public fillData(productInventories: ProductInventorySalesBucketViewModel[]) {
        this.clearForm();
        this.productInventorySalesBuckets = productInventories;
        this.saleBuckets = this.salesBucketMapperService.salesBucketModelToViews(productInventories);
        this.changeDetectionRef.detectChanges();
    }

    public clearForm() {
        this.dataGrid.instance.state({});
        this.productInventorySalesBuckets = [];
        this.saleBuckets = [];
        this.changeDetectionRef.detectChanges();
    }


    getDisplayText(data) {
        var dataArr = data.displayValue.split(':');
        return dataArr[1];
    }

    copyClick() {
        if (this.selectedItem) {
            this.copyItem = this.selectedItem;
        }
    }

    pasteClick() {
        if (this.copyItem) {
            this.selectedItem.capacityFirm = this.copyItem.capacityFirm;
            this.selectedItem.openhoursFirm = this.copyItem.openhoursFirm;
            this.selectedItem.closehoursFirm = this.copyItem.closehoursFirm;
            this.selectedItem.capacityWaitlist = this.copyItem.capacityWaitlist;
            this.selectedItem.openhoursWaitlist = this.copyItem.openhoursWaitlist;
            this.selectedItem.closehoursWaitlist = this.copyItem.closehoursWaitlist;
            this.dataGrid.instance.refresh();
        }
    }

    deleteClick() {
        if (this.selectedItem) {
            this.selectedItem.capacityFirm = 0;
            this.selectedItem.openhoursFirm = 0;
            this.selectedItem.closehoursFirm = 0;
            this.selectedItem.capacityWaitlist = 0;
            this.selectedItem.openhoursWaitlist = 0;
            this.selectedItem.closehoursWaitlist = 0;
            this.dataGrid.instance.refresh();
        }
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
    }

    refreshClick() {
        this.saleBuckets = this.salesBucketMapperService.salesBucketModelToViews(this.productInventorySalesBuckets);
        this.dataGrid.instance.refresh();
        this.changeDetectionRef.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Segment: data.segmentName,
            ServiceCategory: data.serviceCategory,
            SalesBucket: data.salesBucket,
            CapacityFirm: data.capacityFirm,
            OpenHoursFirm: data.openhoursFirm,
            CloseHoursFirm: data.closehoursFirm,
            CapacityWaitlist: data.capacityWaitlist,
            OpenHoursWaitlist: data.openhoursWaitlist,
            CloseHoursWaitlist: data.closehoursWaitlist
        };
    }

    cellPrepared(e) {
        if (e.rowType != 'group') {
            return;
        }

        if (e.totalItem.groupIndex != 0) {
            if (e.data.items?.length) {
                e.cellElement.css("background-color", e.data.items[0]?.serviceCategoryColor);
            } else {
                e.cellElement.css("background-color", e.data.collapsedItems[0]?.serviceCategoryColor);
            }
        }
    }

    onFocusedCellChanging(e) {
        e.isHighlighted = true;
    }
}
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addNavigationAction } from 'src/app/store/navigation-action';
import { NavigationActionState } from 'src/app/store/navigation-action/navigation-action.reducer';
import { NavigationActionModel } from '../models/navigation-action/navigation-action.model';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    constructor(private store: Store<NavigationActionState>) { }

    public add(action: string, parameter: string, parameterName: string): void {
        let actionCode = action?.toUpperCase().trim();
        let newAction: NavigationActionModel = {
            action: this.isIncludeNotToSaveAction(actionCode) ? null : actionCode,
            parameter: parameter,
            parameterName: parameterName
        }
        this.store.dispatch(addNavigationAction(newAction));
    }

    private isIncludeNotToSaveAction(actionCode: string) {
        return actionCode?.startsWith('CATEGOR')
    }

}

import { Injectable } from "@angular/core";
import { ACTION_HANDLER_STATUS } from "src/app/shared/ui/actionbar";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, DeleteButtonModel, RefreshButtonModel, SaveAsButtonModel } from "src/app/shared/ui/actionbar/models";

@Injectable()
export class MerchandizeModeHelperService {
    public getActionBarHandlerModel(mode: string = ACTION_HANDLER_STATUS.none, useOptionSave: boolean = false) {
        let newBtn = new NewButtonModel();
        let copyBtn = new CopyButtonModel();
        let saveBtn = new SaveButtonModel();
        let cancelBtn = new CancelButtonModel();
        let deleteBtn = new DeleteButtonModel();
        let refreshBtn = new RefreshButtonModel();

        let ddSaveBtn = new SaveButtonModel();
        let ddSaveAsBtn = new SaveAsButtonModel();

        if (useOptionSave) {
            saveBtn.addDropdownBtn(ddSaveBtn);
            saveBtn.addDropdownBtn(ddSaveAsBtn);
        }

        copyBtn.disable();
        saveBtn.disable();
        cancelBtn.disable();
        deleteBtn.disable();

        switch (mode) {
            case ACTION_HANDLER_STATUS.none:
                break;
            case ACTION_HANDLER_STATUS.new:
                newBtn.enable();
                saveBtn.enable();
                cancelBtn.enable();
                refreshBtn.disable();
                ddSaveBtn.enable();
                ddSaveAsBtn.disable();
                break;
            case ACTION_HANDLER_STATUS.editing:
                copyBtn.enable();
                saveBtn.enable();
                cancelBtn.enable();
                break;
            case ACTION_HANDLER_STATUS.editingNew:
                // editingNew use in OptionSave mode to disable saveAs menu
                saveBtn.enable();
                cancelBtn.enable();
                ddSaveBtn.enable();
                ddSaveAsBtn.disable();
                break;
            case ACTION_HANDLER_STATUS.selected:
                copyBtn.enable();
                break;
            default:
                break;
        }

        return new ActionBarHandlerModel(
            newBtn,
            copyBtn,
            saveBtn,
            cancelBtn,
            deleteBtn,
            refreshBtn);
    }
}
import { Injectable } from "@angular/core";
import { PriceRuleCurrencyModel, PriceRuleLocationModel, PriceRuleMembershipModel, PriceRuleOrganisationModel, PriceRuleProductNumberModel, PriceRuleRouteModel, PriceRuleSalesBucketModel, PriceRuleServiceCategoryModel, PriceRuleValidityModel } from "src/app/core/models/pricing-model";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { PriceRuleCurrencyView, PriceRuleProductNumberView, PriceRuleRouteView, PriceRuleSalesBucketView, PriceRuleServiceCategoryView } from "../../rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/views";
import { PriceAmountModel, PriceAttributeModel, PriceConditionModel, PriceHierarchyModel, PriceIndividualModel, PriceModel, PricePriceDimensionModel, PriceRulePriceModel } from "./models";
import { PriceAmountView, PriceAttributeView, PriceConditionView, PriceHierarchyView, PriceIndividualView, PricePriceDimensionView, PriceRuleLocationView, PriceRuleMembershipView, PriceRuleOrganisationView, PriceRulePriceView, PriceRuleValidityView, PriceView } from "./views";

declare var moment: any;

@Injectable({
    providedIn: 'root'
})
export class PriceConverter {
    private readonly dateTimeFormat = 'YYYY/MM/DD HH:mm';
    private readonly NO_CURRENCY = 'NOCURRENCY';
    constructor(private stringHelperService: StringHelperService) {

    }

    public toModel(view: PriceView): PriceModel {
        if (!view) {
            return null;
        }
        let model = new PriceModel();
        model.priceId = view.priceId;
        model.priceCode = view.priceCode;
        model.priceName = view.priceName;
        model.organisationId = view.organisationId;
        model.status = view.status;
        model.usageTypeCode = view.usageTypeCode;
        model.searchName = view.searchName;
        model.searchUsageTypeCode = view.searchUsageTypeCode;
        model.searchStatusCode = view.searchStatusCode;
        model.isOwnerFilter = view.isOwnerFilter;
        model.combinationFlag = view.combinationFlag;
        model.draftFlag = view.draftFlag;
        model.productNumbers = this.toPriceRuleProductNumberModels(view?.productNumbers);
        model.organisations = this.toPriceRuleOrganisationModels(view?.organisations);
        model.routes = this.toPriceRuleRouteModels(view?.routes);
        model.locations = this.toPriceRuleLocationModels(view?.locations);
        model.validities = this.toPriceRuleValidityModels(view?.validities);
        model.serviceCategories = this.toPriceRuleServiceCategoryModels(view?.serviceCategories);
        model.salesBuckets = this.toPriceRuleSalesBucketModels(view?.salesBuckets);
        model.memberships = this.toPriceRuleMembershipModels(view?.memberships);
        model.currencies = this.toPriceRuleCurrencyModels(view?.currencies);
        model.attributes = this.toPriceAttributeModels(view?.attributes);
        model.pricePriceDimensions = this.toPricePriceDimensionModels(view?.pricePriceDimensions);
        model.conditions = this.toPriceConditionModels(view?.conditions);
        model.hierarchy = this.toPricehierarchyModel(view?.hierarchy);
        model.priceRulePrices = this.toPriceRulePriceModels(view?.priceRulePrices);
        if (view?.children?.length) {
            for (let child of view.children) {
                model.children.push(this.toModel(child));
            }
        }
        return model;
    }

    public toModels(views: PriceView[]): PriceModel[] {
        let models = new Array<PriceModel>();
        for (let view of views) {
            models.push(this.toModel(view));
        }
        return models;
    }

    public toView(no: number, model: PriceModel): PriceView {
        let view = new PriceView();
        view.no = no++;
        view.priceId = model.priceId;
        view.priceCode = model.priceCode;
        view.priceName = model.priceName;
        view.organisationId = model.organisationId;
        view.usageTypeCode = model.usageTypeCode;
        view.status = model.status;
        view.searchName = model.searchName;
        view.searchUsageTypeCode = model.searchUsageTypeCode;
        view.searchStatusCode = model.searchStatusCode;
        view.unitCode = model.unitCode;
        view.isOwnerFilter = model.isOwnerFilter;
        view.combinationFlag = model.combinationFlag;
        view.draftFlag = model.draftFlag;
        view.commitBy = model.commitBy;
        if (model.commitDateTime) {
            view.commitDateTime = moment(new Date(model.commitDateTime)).format(this.dateTimeFormat);
        }
        view.productNumbers = this.toPriceRuleProductNumberViews(model?.productNumbers);
        view.organisations = this.toPriceRuleOrganisationViews(model?.organisations);
        view.routes = this.toPriceRuleRouteViews(model?.routes);
        view.locations = this.toPriceRuleLocationViews(model?.locations);
        view.validities = this.toPriceRuleValidityViews(model?.validities);
        view.serviceCategories = this.toPriceRuleServiceCategoryViews(model?.serviceCategories);
        view.salesBuckets = this.toPriceRuleSalesBucketViews(model?.salesBuckets);
        view.memberships = this.toPriceRuleMembershipViews(model?.memberships);
        view.currencies = this.toPriceRuleCurrencyViews(model?.currencies);
        view.attributes = this.toPriceAttributeViews(model?.attributes);
        view.pricePriceDimensions = this.toPricePriceDimensionViews(model?.pricePriceDimensions);
        view.conditions = this.toPriceConditionViews(model?.conditions);
        view.hierarchy = this.toPricehierarchyView(model?.hierarchy);
        view.priceRulePrices = this.toPriceRulePriceViews(model?.priceRulePrices);
        if (model?.children?.length) {
            for (let child of model.children) {
                view.children.push(this.toView(no++, child));
            }
        }
        return view;
    }

    public toPriceRuleProductNumberView(model: PriceRuleProductNumberModel): PriceRuleProductNumberView {
        let view = new PriceRuleProductNumberView();
        view.priceRuleProductNumberId = model.priceRuleProductNumberId;
        view.productNumber = model.productNumber;
        view.productNumberTypeCode = model.productNumberTypeCode;
        view.providerId = model.providerId;
        view.supplierId = model.supplierId;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleProductNumberViews(models: PriceRuleProductNumberModel[]): PriceRuleProductNumberView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleProductNumberView>();
        for (let model of models) {
            views.push(this.toPriceRuleProductNumberView(model));
        }
        return views;
    }

    public toPriceRuleOrganisationView(model: PriceRuleOrganisationModel): PriceRuleOrganisationView {
        let view = {} as PriceRuleOrganisationView;
        view.priceRuleOrganisationId = model.priceRuleOrganisationId;
        view.priceRuleOrganisationTypeCode = model.priceRuleOrganisationTypeCode;
        view.organisationId = model.organisationId;
        view.organisationTypeCode = model.organisationTypeCode;
        view.organisationRoleCode = model.organisationRoleCode;
        view.organisationGroupCode = model.organisationGroupCode;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleOrganisationViews(models: PriceRuleOrganisationModel[]): PriceRuleOrganisationView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleOrganisationView>();
        for (let model of models) {
            views.push(this.toPriceRuleOrganisationView(model));
        }
        return views;
    }

    public toPriceRuleRouteView(model: PriceRuleRouteModel): PriceRuleRouteView {
        let view = new PriceRuleRouteView();
        view.priceRuleRouteId = model.priceRuleRouteId;
        view.originLocationId = model.originLocationId;
        view.destinationLocationId = model.destinationLocationId;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleRouteViews(models: PriceRuleRouteModel[]): PriceRuleRouteView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleRouteView>();
        for (let model of models) {
            views.push(this.toPriceRuleRouteView(model));
        }
        return views;
    }

    public toPriceRuleLocationView(model: PriceRuleLocationModel): PriceRuleLocationView {
        let view = {} as PriceRuleLocationView;
        view.priceRuleLocationId = model.priceRuleLocationId;
        view.priceRuleLocationTypeCode = model.priceRuleLocationTypeCode;
        view.priceRuleLocationPointCode = model.priceRuleLocationPointCode;
        view.locationId = model.locationId;
        view.locationGroupId = model.locationGroupId;
        view.regionCode = model.regionCode;
        view.countryCode = model.countryCode;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleLocationViews(models: PriceRuleLocationModel[]): PriceRuleLocationView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleLocationView>();
        for (let model of models) {
            views.push(this.toPriceRuleLocationView(model));
        }
        return views;
    }

    public toPriceRuleValidityView(model: PriceRuleValidityModel): PriceRuleValidityView {
        let view = {} as PriceRuleValidityView;
        view.priceRuleValidityId = model.priceRuleValidityId;
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.calendarValidityCode = model.calendarValidityCode;
        view.dateTimeDimensionCode = model.dateTimeDimensionCode;
        view.startDateTime = model.startDateTime;
        view.endDateTime = model.endDateTime;
        view.startValue = model.startValue;
        view.endValue = model.endValue;
        view.mondayFlag = model.mondayFlag;
        view.tuesdayFlag = model.tuesdayFlag;
        view.wednesdayFlag = model.wednesdayFlag;
        view.thursdayFlag = model.thursdayFlag;
        view.fridayFlag = model.fridayFlag;
        view.saturdayFlag = model.saturdayFlag;
        view.sundayFlag = model.sundayFlag;
        view.januaryFlag = model.januaryFlag;
        view.februaryFlag = model.februaryFlag;
        view.marchFlag = model.marchFlag;
        view.aprilFlag = model.aprilFlag;
        view.mayFlag = model.mayFlag;
        view.juneFlag = model.juneFlag;
        view.julyFlag = model.julyFlag;
        view.augustFlag = model.augustFlag;
        view.septemberFlag = model.septemberFlag;
        view.octoberFlag = model.octoberFlag;
        view.novemberFlag = model.novemberFlag;
        view.decemberFlag = model.decemberFlag;
        view.utcFlag = model.utcFlag;
        view.conditionCode = model.conditionCode;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleValidityViews(models: PriceRuleValidityModel[]): PriceRuleValidityView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleValidityView>();
        for (let model of models) {
            views.push(this.toPriceRuleValidityView(model));
        }
        return views;
    }

    public toPriceRuleServiceCategoryView(model: PriceRuleServiceCategoryModel): PriceRuleServiceCategoryView {
        let view = new PriceRuleServiceCategoryView();
        view.priceRuleServiceCategoryId = model.priceRuleServiceCategoryId;
        view.serviceCategoryCode = model.serviceCategoryCode;
        view.inventoryDimensionCode = model.inventoryDimensionCode;
        view.conditionCode = model.conditionCode;
        view.thresholdValue = model.thresholdValue + '';
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.dimensionUnitCode = model.dimensionUnitCode;
        return view;
    }

    public toPriceRuleServiceCategoryViews(models: PriceRuleServiceCategoryModel[]): PriceRuleServiceCategoryView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleServiceCategoryView>();
        for (let model of models) {
            views.push(this.toPriceRuleServiceCategoryView(model));
        }
        return views;
    }

    public toPriceRuleSalesBucketView(model: PriceRuleSalesBucketModel): PriceRuleSalesBucketView {
        let view = new PriceRuleSalesBucketView();
        view.priceRuleSalesBucketId = model.priceRuleSalesBucketId;
        view.salesBucketCode = model.salesBucketCode;
        view.inventoryDimensionCode = model.inventoryDimensionCode;
        view.conditionCode = model.conditionCode;
        view.thresholdValue = model.thresholdValue + '';
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.dimensionUnitCode = model.dimensionUnitCode;
        return view;
    }

    public toPriceRuleSalesBucketViews(models: PriceRuleSalesBucketModel[]): PriceRuleSalesBucketView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleSalesBucketView>();
        for (let model of models) {
            views.push(this.toPriceRuleSalesBucketView(model));
        }
        return views;
    }

    public toPriceRuleMembershipView(model: PriceRuleMembershipModel): PriceRuleMembershipView {
        let view = {} as PriceRuleMembershipView;
        view.priceRuleMembershipId = model.priceRuleMembershipId;
        view.membershipLevelCode = model.membershipLevelCode;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleMembershipViews(models: PriceRuleMembershipModel[]): PriceRuleMembershipView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleMembershipView>();
        for (let model of models) {
            views.push(this.toPriceRuleMembershipView(model));
        }
        return views;
    }

    public toPriceRuleCurrencyView(model: PriceRuleCurrencyModel): PriceRuleCurrencyView {
        let view = {} as PriceRuleCurrencyView;
        view.priceRuleCurrencyId = model.priceRuleCurrencyId;
        view.currencyCode = model.currencyCode;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleCurrencyViews(models: PriceRuleCurrencyModel[]): PriceRuleCurrencyView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRuleCurrencyView>();
        for (let model of models) {
            views.push(this.toPriceRuleCurrencyView(model));
        }
        return views;
    }

    public toPriceAttributeView(model: PriceAttributeModel): PriceAttributeView {
        let view = {} as PriceAttributeView;
        view.priceAttributeId = model.priceAttributeId;
        view.parentPriceAttributeId = model.parentPriceAttributeId;
        view.attributeTypeCode = model.attributeTypeCode;
        view.attributeChoiceId = model.attributeChoiceId;
        view.attributeValue = model.attributeValue;
        view.conditionCode = model.conditionCode;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceAttributeViews(models: PriceAttributeModel[]): PriceAttributeView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceAttributeView>();
        for (let model of models) {
            views.push(this.toPriceAttributeView(model));
        }
        return views;
    }

    public toPricePriceDimensionView(model: PricePriceDimensionModel): PricePriceDimensionView {
        let view = {} as PricePriceDimensionView;
        view.pricePriceDimensionId = model.pricePriceDimensionId;
        view.pricePriceDimensionTypeCode = model.pricePriceDimensionTypeCode;
        view.priceRuleAttributeTypeCode = model.priceRuleAttributeTypeCode;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPricePriceDimensionViews(models: PricePriceDimensionModel[]): PricePriceDimensionView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PricePriceDimensionView>();
        for (let model of models) {
            views.push(this.toPricePriceDimensionView(model));
        }
        return views;
    }

    public toPriceConditionView(model: PriceConditionModel): PriceConditionView {
        let view = new PriceConditionView();
        view.priceConditionId = model.priceConditionId;
        view.conditionCode = model.conditionCode;
        view.numberOfUnits = model.numberOfUnits;
        view.statusCode = model.statusCode;
        view.referencePriceConditionId = model.referencePriceConditionId;
        return view;
    }

    public toPriceConditionViews(models: PriceConditionModel[]): PriceConditionView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceConditionView>();
        for (let model of models) {
            let view = this.toPriceConditionView(model);
            view.individuals = this.toPriceIndividualViews(model.individuals);
            views.push(view);
        }
        return views;
    }

    public toPriceRulePriceViews(models: PriceRulePriceModel[]): PriceRulePriceView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceRulePriceView>();
        for (let model of models) {
            views.push(this.toPriceRulePriceView(model));
        }
        return views;
    }

    public toPriceRulePriceView(model: PriceRulePriceModel): PriceRulePriceView {
        return {
            priceRuleId: model.priceRuleId,
            priceRulePriceId: model.priceRulePriceId,
            priceId: model.priceId
        };
    }

    public toPriceIndividualView(model: PriceIndividualModel): PriceIndividualView {
        let view = {} as PriceIndividualView;
        view.priceIndividualId = model.priceIndividualId;
        view.individualAgeGroupCode = model.individualAgeGroupCode;
        view.inRelationToIndividualAgeGroupCode = model.inRelationToIndividualAgeGroupCode;
        view.individualSocialTypeCode = model.individualSocialTypeCode;
        view.arithmeticOperator = model.arithmeticOperator;
        view.referencePriceIndividualId = model.referencePriceIndividualId;
        return view;
    }

    public toPriceIndividualViews(models: PriceIndividualModel[]): PriceIndividualView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceIndividualView>();
        for (let model of models) {
            let view = this.toPriceIndividualView(model);
            view.amount = this.toPriceAmountView(model.amount);
            views.push(view);
        }
        return views;
    }

    public toPriceAmountView(model: PriceAmountModel): PriceAmountView {
        if (!model) {
            return null;
        }
        let view = {} as PriceAmountView;
        view.priceAmountId = model.priceAmountId;
        view.priceIndividualId = model.priceIndividualId;
        view.currencyCode = model.currencyCode;
        view.priceAmount = model.priceAmount;
        view.pricePercentage = model.pricePercentage;
        view.minimumPriceAmount = model.minimumPriceAmount;
        view.maximumPriceAmount = model.maximumPriceAmount;
        view.costAmount = model.costAmount;
        return view;
    }

    public toPriceAmountViews(models: PriceAmountModel[]): PriceAmountView[] {
        if (!models?.length) {
            return [];
        }
        let views = new Array<PriceAmountView>();
        for (let model of models) {
            views.push(this.toPriceAmountView(model));
        }
        return views;
    }

    public toPricehierarchyView(model: PriceHierarchyModel): PriceHierarchyView {
        if (!model) {
            return null;
        }
        let view = {} as PriceHierarchyView;
        view.hierarchyKey = model.hierarchyKey;
        view.hierarchyLength = model.hierarchyLength;
        view.hierarchyRank = model.hierarchyRank;
        view.rootPriceId = model.rootPriceId;
        return view;
    }

    public toViews(models: PriceModel[]): PriceView[] {
        let views = new Array<PriceView>();
        let no = 1;
        for (let model of models) {
            views.push(this.toView(no++, model));
        }
        return views;
    }

    

    public toPriceRuleProductNumberModel(view: PriceRuleProductNumberView): PriceRuleProductNumberModel {
        let model = new PriceRuleProductNumberModel();
        model.priceRuleProductNumberId = view.priceRuleProductNumberId;
        model.productNumber = view.productNumber;
        model.productNumberTypeCode = view.productNumberTypeCode;
        model.providerId = view.providerId;
        model.supplierId = view.supplierId;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleProductNumberModels(views: PriceRuleProductNumberView[]): PriceRuleProductNumberModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleProductNumberModel>();
        for (let view of views) {
            models.push(this.toPriceRuleProductNumberModel(view));
        }
        return models;
    }

    public toPriceRuleOrganisationModel(view: PriceRuleOrganisationView): PriceRuleOrganisationModel {
        let model = new PriceRuleOrganisationModel();
        model.priceRuleOrganisationId = view.priceRuleOrganisationId;
        model.priceRuleOrganisationTypeCode = view.priceRuleOrganisationTypeCode;
        model.organisationId = view.organisationId;
        model.organisationTypeCode = view.organisationTypeCode;
        model.organisationRoleCode = view.organisationRoleCode;
        model.organisationGroupCode = view.organisationGroupCode;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleOrganisationModels(views: PriceRuleOrganisationView[]): PriceRuleOrganisationModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleOrganisationModel>();
        for (let view of views) {
            models.push(this.toPriceRuleOrganisationModel(view));
        }
        return models;
    }

    public toPriceRuleRouteModel(view: PriceRuleRouteView): PriceRuleRouteModel {
        let model = new PriceRuleRouteModel();
        model.priceRuleRouteId = view.priceRuleRouteId;
        model.originLocationId = view.originLocationId;
        model.destinationLocationId = view.destinationLocationId;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleRouteModels(views: PriceRuleRouteView[]): PriceRuleRouteModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleRouteModel>();
        for (let view of views) {
            models.push(this.toPriceRuleRouteModel(view));
        }
        return models;
    }

    public toPriceRuleLocationModel(view: PriceRuleLocationView): PriceRuleLocationModel {
        let model = new PriceRuleLocationModel();
        model.priceRuleLocationId = view.priceRuleLocationId;
        model.priceRuleLocationTypeCode = view.priceRuleLocationTypeCode;
        model.priceRuleLocationPointCode = view.priceRuleLocationPointCode;
        model.locationId = view.locationId;
        model.locationGroupId = view.locationGroupId;
        model.regionCode = view.regionCode;
        model.countryCode = view.countryCode;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleLocationModels(views: PriceRuleLocationView[]): PriceRuleLocationModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleLocationModel>();
        for (let view of views) {
            models.push(this.toPriceRuleLocationModel(view));
        }
        return models;
    }

    public toPriceRuleValidityModel(view: PriceRuleValidityView): PriceRuleValidityModel {
        let model = new PriceRuleValidityModel();
        model.priceRuleValidityId = view.priceRuleValidityId;
        model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
        model.calendarValidityCode = view.calendarValidityCode;
        model.dateTimeDimensionCode = view.dateTimeDimensionCode;
        model.startDateTime = view.startDateTime;
        model.endDateTime = view.endDateTime;
        model.startValue = view.startValue;
        model.endValue = view.endValue;
        model.mondayFlag = view.mondayFlag;
        model.tuesdayFlag = view.tuesdayFlag;
        model.wednesdayFlag = view.wednesdayFlag;
        model.thursdayFlag = view.thursdayFlag;
        model.fridayFlag = view.fridayFlag;
        model.saturdayFlag = view.saturdayFlag;
        model.sundayFlag = view.sundayFlag;
        model.januaryFlag = view.januaryFlag;
        model.februaryFlag = view.februaryFlag;
        model.marchFlag = view.marchFlag;
        model.aprilFlag = view.aprilFlag;
        model.mayFlag = view.mayFlag;
        model.juneFlag = view.juneFlag;
        model.julyFlag = view.julyFlag;
        model.augustFlag = view.augustFlag;
        model.septemberFlag = view.septemberFlag;
        model.octoberFlag = view.octoberFlag;
        model.novemberFlag = view.novemberFlag;
        model.decemberFlag = view.decemberFlag;
        model.utcFlag = view.utcFlag;
        model.conditionCode = view.conditionCode;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleValidityModels(views: PriceRuleValidityView[]): PriceRuleValidityModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleValidityModel>();
        for (let view of views) {
            models.push(this.toPriceRuleValidityModel(view));
        }
        return models;
    }

    public toPriceRuleServiceCategoryModel(view: PriceRuleServiceCategoryView): PriceRuleServiceCategoryModel {
        let model = new PriceRuleServiceCategoryModel();
        model.priceRuleServiceCategoryId = view.priceRuleServiceCategoryId;
        model.serviceCategoryCode = view.serviceCategoryCode;
        model.inventoryDimensionCode = view.inventoryDimensionCode;
        model.conditionCode = view.conditionCode;
        model.thresholdValue = +view.thresholdValue;
        model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
        model.dimensionUnitCode = view.dimensionUnitCode;
        return model;
    }

    public toPriceRuleServiceCategoryModels(views: PriceRuleServiceCategoryView[]): PriceRuleServiceCategoryModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleServiceCategoryModel>();
        for (let view of views) {
            models.push(this.toPriceRuleServiceCategoryModel(view));
        }
        return models;
    }

    public toPriceRuleSalesBucketModel(view: PriceRuleSalesBucketView): PriceRuleSalesBucketModel {
        let model = new PriceRuleSalesBucketModel();
        model.priceRuleSalesBucketId = view.priceRuleSalesBucketId;
        model.salesBucketCode = view.salesBucketCode;
        model.inventoryDimensionCode = view.inventoryDimensionCode;
        model.conditionCode = view.conditionCode;
        model.thresholdValue = +view.thresholdValue;
        model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
        model.dimensionUnitCode = view.dimensionUnitCode;
        return model;
    }

    public toPriceRuleSalesBucketModels(views: PriceRuleSalesBucketView[]): PriceRuleSalesBucketModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleSalesBucketModel>();
        for (let view of views) {
            models.push(this.toPriceRuleSalesBucketModel(view));
        }
        return models;
    }

    public toPriceRuleMembershipModel(view: PriceRuleMembershipView): PriceRuleMembershipModel {
        let model = new PriceRuleMembershipModel();
        model.priceRuleMembershipId = view.priceRuleMembershipId;
        model.membershipLevelCode = view.membershipLevelCode;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleMembershipModels(views: PriceRuleMembershipView[]): PriceRuleMembershipModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleMembershipModel>();
        for (let view of views) {
            models.push(this.toPriceRuleMembershipModel(view));
        }
        return models;
    }

    public toPriceRuleCurrencyModel(view: PriceRuleCurrencyView): PriceRuleCurrencyModel {
        let model = {} as PriceRuleCurrencyModel;
        model.priceRuleCurrencyId = view.priceRuleCurrencyId;
        model.currencyCode = view.currencyCode;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceRuleCurrencyModels(views: PriceRuleCurrencyView[]): PriceRuleCurrencyModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRuleCurrencyModel>();
        for (let view of views) {
            models.push(this.toPriceRuleCurrencyModel(view));
        }
        return models;
    }

    public toPriceAttributeModel(view: PriceAttributeView): PriceAttributeModel {
        let model = new PriceAttributeModel();
        model.priceAttributeId = view.priceAttributeId;
        model.parentPriceAttributeId = view.parentPriceAttributeId;
        model.attributeTypeCode = view.attributeTypeCode;
        model.attributeChoiceId = view.attributeChoiceId;
        model.attributeValue = view.attributeValue;
        model.conditionCode = view.conditionCode;
        model.excludeFlag = view.excludeFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPriceAttributeModels(views: PriceAttributeView[]): PriceAttributeModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceAttributeModel>();
        for (let view of views) {
            models.push(this.toPriceAttributeModel(view));
        }
        return models;
    }

    public toPricePriceDimensionModel(view: PricePriceDimensionView): PricePriceDimensionModel {
        let model = new PricePriceDimensionModel();
        model.pricePriceDimensionId = view.pricePriceDimensionId;
        model.pricePriceDimensionTypeCode = view.pricePriceDimensionTypeCode;
        model.priceRuleAttributeTypeCode = view.priceRuleAttributeTypeCode;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public toPricePriceDimensionModels(views: PricePriceDimensionView[]): PricePriceDimensionModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PricePriceDimensionModel>();
        for (let view of views) {
            models.push(this.toPricePriceDimensionModel(view));
        }
        return models;
    }

    public toPriceConditionModel(view: PriceConditionView): PriceConditionModel {
        let model = new PriceConditionModel();
        model.priceConditionId = view.priceConditionId;
        model.conditionCode = view.conditionCode;
        model.numberOfUnits = view.numberOfUnits;
        model.statusCode = view.statusCode;
        model.referencePriceConditionId = view.referencePriceConditionId;
        if (view.individuals?.length) {
            model.individuals = this.toPriceIndividualModels(view.individuals);
        }
        return model;
    }

    public toPriceConditionModels(views: PriceConditionView[]): PriceConditionModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceConditionModel>();
        for (let view of views) {
            models.push(this.toPriceConditionModel(view));
        }
        return models;
    }

    public toPriceRulePriceModels(views: PriceRulePriceView[]): PriceRulePriceModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceRulePriceModel>();
        for (let view of views) {
            models.push(this.toPriceRulePriceModel(view));
        }
        return models;
    }

    public toPriceRulePriceModel(view: PriceRulePriceView): PriceRulePriceModel {
        return {
            priceRuleId: view.priceRuleId,
            priceRulePriceId: view.priceRulePriceId,
            priceId: view.priceId
        };
    }

    public referencePriceConditionModel(model: PriceConditionModel, currencyCode: string): PriceConditionModel {
        let ref = new PriceConditionModel();
        ref.priceConditionId = this.stringHelperService.NewGuid();
        ref.conditionCode = model.conditionCode;
        ref.numberOfUnits = model.numberOfUnits;
        ref.statusCode = model.statusCode;
        ref.referencePriceConditionId = model.priceConditionId;
        if (model.individuals?.length) {
            ref.individuals = this.referencePriceIndividualModels(model.individuals, currencyCode);
        }
        return ref;
    }

    public copyPriceConditionModel(from: PriceConditionModel, to: PriceConditionModel) {
        to.conditionCode = from.conditionCode;
        to.numberOfUnits = from.numberOfUnits;
        to.statusCode = from.statusCode;
    }

    public referencePriceConditionModels(models: PriceConditionModel[], currencyCode: string): PriceConditionModel[] {
        if (!models?.length) {
            return [];
        }
        let refs = new Array<PriceConditionModel>();
        for (let model of models) {
            refs.push(this.referencePriceConditionModel(model, currencyCode));
        }
        return refs;
    }
    
    public referencePriceIndividualModel(model: PriceIndividualModel, currencyCode: string): PriceIndividualModel {
        let ref = {} as PriceIndividualModel;
        ref.priceIndividualId = this.stringHelperService.NewGuid();
        ref.individualAgeGroupCode = model.individualAgeGroupCode;
        ref.inRelationToIndividualAgeGroupCode = model.inRelationToIndividualAgeGroupCode;
        ref.individualSocialTypeCode = model.individualSocialTypeCode;
        ref.arithmeticOperator = model.arithmeticOperator;
        ref.referencePriceIndividualId = model.priceIndividualId;

        let amount = {} as PriceAmountModel;
        amount.priceAmountId = this.stringHelperService.NewGuid();
        if (currencyCode != this.NO_CURRENCY) {
            amount.currencyCode = currencyCode;
        }
        ref.amount = amount;
        return ref;
    }
    
    public copyPriceIndividualModel(from: PriceIndividualModel, to: PriceIndividualModel) {
        to.individualAgeGroupCode = from.individualAgeGroupCode;
        to.inRelationToIndividualAgeGroupCode = from.inRelationToIndividualAgeGroupCode;
        to.individualSocialTypeCode = from.individualSocialTypeCode;
        to.arithmeticOperator = from.arithmeticOperator;
    }

    public referencePriceIndividualModels(models: PriceIndividualView[], currencyCode: string): PriceIndividualModel[] {
        if (!models?.length) {
            return [];
        }
        let refs = new Array<PriceIndividualModel>();
        for (let model of models) {
            refs.push(this.referencePriceIndividualModel(model, currencyCode));
        }
        return refs;
    }

    public toPriceIndividualModel(view: PriceIndividualView): PriceIndividualModel {
        let model = {} as PriceIndividualModel;
        model.priceIndividualId = view.priceIndividualId;
        model.individualAgeGroupCode = view.individualAgeGroupCode;
        model.inRelationToIndividualAgeGroupCode = view.inRelationToIndividualAgeGroupCode;
        model.individualSocialTypeCode = view.individualSocialTypeCode;
        model.arithmeticOperator = view.arithmeticOperator;
        model.referencePriceIndividualId = view.referencePriceIndividualId;
        if (model.amount) {
            model.amount = this.toPriceAmountModel(view.amount);
        }
        return model;
    }

    public toPriceIndividualModels(views: PriceIndividualView[]): PriceIndividualModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceIndividualModel>();
        for (let view of views) {
            models.push(this.toPriceIndividualModel(view));
        }
        return models;
    }

    public toPriceAmountModel(view: PriceAmountView): PriceAmountModel {
        if (!view) {
            return null;
        }
        let model = {} as PriceAmountModel;
        model.priceAmountId = view.priceAmountId;
        model.priceIndividualId = view.priceIndividualId;
        model.currencyCode = view.currencyCode;
        model.priceAmount = view.priceAmount;
        model.pricePercentage = view.pricePercentage;
        model.minimumPriceAmount = view.minimumPriceAmount;
        model.maximumPriceAmount = view.maximumPriceAmount;
        model.costAmount = view.costAmount;
        return model;
    }

    public toPriceAmountModels(views: PriceAmountView[]): PriceAmountModel[] {
        if (!views?.length) {
            return [];
        }
        let models = new Array<PriceAmountModel>();
        for (let view of views) {
            models.push(this.toPriceAmountModel(view));
        }
        return models;
    }

    public toPricehierarchyModel(view: PriceHierarchyView): PriceHierarchyModel {
        if (!view) {
            return null;
        }
        let model = {} as PriceHierarchyModel;
        model.hierarchyKey = view.hierarchyKey;
        model.hierarchyLength = view.hierarchyLength;
        model.hierarchyRank = view.hierarchyRank;
        model.rootPriceId = view.rootPriceId;
        return model;
    }
}
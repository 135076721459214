import { Injectable } from '@angular/core';
import { CurrencyReferenceModel, ExchangeRateModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ExchangeCurrencyView } from './exchange-currency.view';

@Injectable()
export class ExchangeCurrencyMapperService {

    public toViews(baseCurrencyCode: string, currencyModels: CurrencyReferenceModel[], exchangeRateModels: ExchangeRateModel[]): ExchangeCurrencyView[] {
        let views: ExchangeCurrencyView[] = new Array();
        let i = 0;
        if (currencyModels) {
            for (let currencyModel of currencyModels) {
                if (currencyModel.currencyCode != baseCurrencyCode) {
                    ++i;
                    views.push(this.toView(i, currencyModel, exchangeRateModels, baseCurrencyCode));
                }
            }
        }
        return views;
    }

    private toView(i: number, currencyModel: CurrencyReferenceModel, exchangeRateModels: ExchangeRateModel[], baseCurrencyCode: string): ExchangeCurrencyView {
        let view = {} as ExchangeCurrencyView;
        view.no = i;
        view.baseCurrencyCode = baseCurrencyCode;
        view.currencyCode = currencyModel.currencyCode;
        view.currencyType = currencyModel.currencyTypeName;
        let filters = exchangeRateModels.filter(x => x.exchangeCurrencyCode == currencyModel.currencyCode);
        if (filters?.length) {
            view.select = true;
            view.exchangeRateId = filters[0].exchangeRateId;
            view.factor = filters[0].factor;
            view.commitByName = filters[0].commitByName;
            view.commitDateTime = filters[0].commitDateTime;
        } else {
            view.select = false;
            view.factor = 1;
        }
        return view;
    }

    public toModels(exchangeRateViews: ExchangeCurrencyView[]): ExchangeRateModel[] {
        let models: ExchangeRateModel[] = new Array();
        for (let view of exchangeRateViews) {
            models.push(this.toModel(view));
        }
        return models;
    }

    private toModel(view: ExchangeCurrencyView): ExchangeRateModel {
        let model = {} as ExchangeRateModel;
        if (view.exchangeRateId) {
            model.exchangeRateId = view.exchangeRateId;
        }
        model.baseCurrencyCode = view.baseCurrencyCode;
        model.exchangeCurrencyCode = view.currencyCode;
        model.factor = view.factor;
        return model;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { GoogleObjRequest } from './dto/google-obj-request';

@Injectable({
  providedIn: 'root'
})

export class GoogletranslateService {

  constructor(private http: HttpClient) { }

  translate(obj: GoogleObjRequest) {
    return this.http.post(environment.googleTranslationURL + environment.googleTranslationAPIKey, obj);
  }
}
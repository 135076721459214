export const PricingConstant = {
    rules: {
        id: 'PRICE_RULE_SEARCH',
        url: '/price-rule/',
        title: 'Price Rule Management'
    },
    prices: {
        id: 'PRICE_SEARCH',
        url: '/price/',
        title: 'Price Management'
    },
    dimensions: {
        id: 'PRICE_DIMENSION_SEARCH',
        url: '/dimension/',
        title: 'Price Dimensions Management'
    },
    conditions: {
        id: 'PRICE_CONDITIONS_SEARCH',
        url: '/condition/',
        title: 'Price Conditions Management'
    },
    categories: {
        id: 'CATEGORIES',
        title: 'Pricing Management'
    }
}

export function getPricingConstantByUrl(url: string): any {
    let foundKey = Object.keys(PricingConstant).find(key => PricingConstant[key].url == url);
    if (!foundKey) {
        return null;
    }
    return PricingConstant[foundKey];
}

export function getPricingConstantById(id: string): any {
    let foundKey = Object.keys(PricingConstant).find(key => PricingConstant[key].id == id);
    if (!foundKey) {
        return null;
    }
    return PricingConstant[foundKey];
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { StandardScheduleInformationContentModule } from "./standard-schedule-information-content/standard-schedule-information-content.module";
import { StandardScheduleInformationComponent } from "./standard-schedule-information.component";

@NgModule({
    declarations: [
        StandardScheduleInformationComponent,
    ],
    imports: [
        StandardScheduleInformationContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [
        StandardScheduleInformationComponent
    ]
})
export class StandardScheduleInformationModule {}
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { ScheduleQueueCommitModel } from 'src/app/core/models/schedule-queue-category-model/schedule-queue-commit.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ScheduleQueueService } from 'src/app/core/services/schedule-services';
import { InsightManagementService } from 'src/app/core/services/system-services/insight-management.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { OopsCategory } from '../../../../core/components/categories/oops-category.model';
import { MODULE_NAME } from '../shared/title-constant';
import { ScheduleCategory } from './schedule-category';
import { cloneDeep } from 'lodash';
import { ProductGroupService } from 'src/app/core/services/product-services';
import { ProductGroupView } from 'src/app/modules/product-management/product-categories-content/shared/views/product-group.view';

@Component({
    selector: 'op-schedule-categories',
    templateUrl: './schedule-categories.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleCategoriesComponent implements OnInit, AfterViewInit {
    private readonly actionIdDisplay = 'DISPLAY';
    private readonly actionIdNew = 'NEW';
    private readonly actionIdSearch = 'SEARCH';
    private readonly actionIdDashboard = 'DASHBOARD';
    private readonly schedulePeriodCode = 'SCHEDULEPERIOD';
    private readonly schedulePeriodName = 'Schedule Period';
    private readonly productCategoryCodeTransport = 'TRANSPORT';
    private readonly productGroupCodeAir = 'AIR';
    private readonly securityCodeProductTransport = 'PRODUCTTRANSPORT';

    @Input() initSelectedItem: ScheduleCategory;
    @Output() rowSelected = new EventEmitter<ScheduleCategory>();
    @Output() menuSelected = new EventEmitter<string>();

    public categoriesItems: ScheduleCategory[] = [];

    private initCategories: ScheduleCategory[] = [
        {
            code: 'SCHEDULE',
            name: 'Schedule Queue',
            url: '/main/schedule/schedule-queue',
            modifiedBy: null,
            modifiedDateTime: null,
            security: null,
            contextMenus: [],
            productCount: null
        },
        {
            code: 'SSIM',
            name: 'Standard Schedule Information',
            url: '/main/schedule/standard-schedule-information',
            modifiedBy: null,
            modifiedDateTime: null,
            security: null,
            contextMenus: [],
            productCount: null
        },
        {
            code: 'SCHEDULEDISTRIBUTION',
            name: 'Schedule Distribution',
            url: '/main/schedule/schedule-distribution',
            modifiedBy: null,
            modifiedDateTime: null,
            security: null,
            contextMenus: [],
            productCount: null
        },
        {
            code: 'SCHEDULESEASON',
            name: 'Schedule Season',
            url: '',
            modifiedBy: null,
            modifiedDateTime: null,
            security: null,
            contextMenus: [],
            productCount: null
        },
        {
            code: this.schedulePeriodCode,
            name: this.schedulePeriodName,
            url: '/main/schedule/schedule-period',
            modifiedBy: null,
            modifiedDateTime: null,
            security: null,
            contextMenus: [],
            productCount: null
        },
    ];
    public selectedItem: ScheduleCategory;
    public userSecurity: SecurityGroupSecurityModel[]
    private unsubscribe$ = new Subject();

    private contextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'Display',
            actionId: this.actionIdDisplay
        },
        {
            name: 'New',
            actionId: this.actionIdNew
        },
        {
            name: 'Search',
            actionId: this.actionIdSearch
        },
        {
            name: 'Dashboard',
            actionId: this.actionIdDashboard
        },
    ]

    constructor(private actionbarService: ActionbarService,
        private changeDetectorRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService,
        private mainSpinner: LoadingSpinnerService,
        private securityGroupService: SecurityGroupService,
        private scheduleQueueService: ScheduleQueueService,
        private navigationService: NavigationService,
        private productGroupService: ProductGroupService) { }

    ngOnInit(): void {
        this.mainSpinner.show()
        this.getCommitDateTime();
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(new ActionBarHandlerModel(new RefreshButtonModel()));
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    onMenuSelected(item: OopsCategory) {
        this.selectedItem = item as ScheduleCategory;
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.refresh) {
            this.mainSpinner.show()
            this.getCommitDateTime();
        }
    }

    private fillScheduleCategoriesCommit(models: ScheduleQueueCommitModel[]) {
        let menus = []

        const showInMenu = (item: ScheduleCategory, securityCode: string) => {
            const security = this.userSecurity?.find(item => item.securityCode == securityCode)

            if (security) {
                menus.push(item)
            }
        }

        
        for (let item of this.initCategories) {
            let commit = models.find(x => x.scheduleCategoryCode == item.code);
            if (commit) {
                item.modifiedBy = commit.commitByName;
                item.modifiedDateTime = this.dateConverterService.convertDateTime24(commit.commitDateTime)
            }

            if (item.code == this.schedulePeriodCode) {
                showInMenu(item, this.securityCodeProductTransport);
            } else {
                menus.push(item)
            }
        }

        this.setContextMenus(menus)

        this.categoriesItems = menus
    }

    private setContextMenus(categoriesItems: OopsCategory[]) {
        for (let item of categoriesItems) {
            item.contextMenus = this.contextMenu;
            this.setScheduleSecurity(item);
        }
    }

    public onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case this.actionIdDisplay:
                this.gotoNextModule();
                break;
            case this.actionIdNew:
                break;
            case this.actionIdSearch:
                this.gotoScheduleQueue();
                break;
            case this.actionIdDashboard:
                break;
        }
    }


    private gotoNextModule() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.schedule_queue:
                    this.gotoScheduleQueue();
                    break;
                case MODULE_NAME.standard_schedule_information:
                    this.gotoScheduleQueue()
                    break;
                case MODULE_NAME.schedule_season:
                    break;
            }
        }
    }

    private gotoScheduleQueue() {
        this.navigationService.navigate(this.selectedItem.url, null, false, {
            userSecurity: this.selectedItem.security
        });
    }

    private setScheduleSecurity(categoryItem: OopsCategory) {
        if (categoryItem.name == this.schedulePeriodName) {
            categoryItem.security = this.userSecurity?.find(item => item.securityCode == this.securityCodeProductTransport)
            categoryItem.contextMenus = this.setContextMenutSecurity(categoryItem.contextMenus, categoryItem.security)
        } else {
            let categoryCode = categoryItem.name.replace(/ /g, '').toUpperCase();
            let security = this.userSecurity?.find(item => categoryCode.includes(item.securityCode));
            categoryItem.security = security;
            categoryItem.contextMenus = this.setContextMenutSecurity(categoryItem.contextMenus, security);
        }
    }

    private setContextMenutSecurity(menu: OopsCategoryContextMenu[], security: SecurityGroupSecurityModel) {
        let newBtnIndex = menu.findIndex(item => item.actionId == this.actionIdNew);
        let newMenu = cloneDeep(menu);

        newMenu[newBtnIndex].disable = !security?.newFlag;
        return newMenu;
    }

    private getCommitDateTime() {
        this.securityGroupService.userSecurityGroupSecurity.pipe(
            filter(f => !!f),
            switchMap((userSecurity) => {
                this.userSecurity = userSecurity
                return forkJoin({
                    scheduleQueueCommits: this.scheduleQueueService.getLatestCommits(),
                    productGroups: this.productGroupService.getProductGroupByProductCategoryCode(this.productCategoryCodeTransport)
                })
            })
        )
        .subscribe(({
            scheduleQueueCommits,
            productGroups
        }) => {
            this.fillSchedulePeriodCommit(productGroups)
            this.fillScheduleCategoriesCommit(scheduleQueueCommits)
            this.mainSpinner.hide();
            this.changeDetectorRef.detectChanges()
        },
        () => {
            this.mainSpinner.hide();
        })
    }

    public getProductGroupService() {
        this.productGroupService.getProductGroupByProductCategoryCode(this.productCategoryCodeTransport)
            .subscribe(
                (responses: ProductGroupView[]) => {
                    this.fillSchedulePeriodCommit(responses);
                    this.mainSpinner.hide();
                    this.changeDetectorRef.detectChanges();
                },
                error => {
                    this.mainSpinner.hide();
                }
            );
    }

    private fillSchedulePeriodCommit(models: ProductGroupView[]) {
        let schedulePeriodInit = this.initCategories.find(x => x.code == this.schedulePeriodCode);
        let commit = models.find(x => x.productGroupCode == this.productGroupCodeAir);
        if (commit) {
            schedulePeriodInit.modifiedBy = commit.commitByName;
            schedulePeriodInit.modifiedDateTime = this.dateConverterService.convertDateTime24(commit.commitDateTime)
        }
    }
}

import { ChangeDetectorRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ConditionReferenceModel,
    DomainAttributeModel,
    GenderReferenceModel,
    IndividualAgeGroupReferenceModel,
    IndividualCivilStatusTypeReferenceModel,
    IndividualInterestTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';

import {
    DomainAttributeService,
    IndividualAgeGroupTypeReferenceService,
    IndividualCivilStatusTypeReferenceService,
    IndividualInterestTypeReferenceService,
    LocationReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService
} from 'src/app/core/services/airline-services';

import {
    CountryReferenceService,
    CalendarValidityService,
    ConditionReferenceService,
    GenderReferenceService,
    OrganisationRoleReferenceService,
    OrganisationTypeReferenceService
} from 'src/app/core/services/system-services';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PricingDetailConstant } from '../../shared/pricing-detail.constant';
import { ProductEligibleRestrictedConverter } from '../product-eligible-restricted/shared/product-eligible-restricted.converter';
import { Select2OptionsService as ProductEligibleSelect2OptionService } from '../product-eligible-restricted/shared/select2-options.service';
import { 
    PriceRuleAttributeAndRuleRowView,
    PriceRuleAttributeView,
    PriceRuleIndividualView,
    PriceRuleLocationView,
    PriceRuleMembershipView,
    PriceRuleOrganisationView,
    PriceRuleValidityView
} from '../product-eligible-restricted/shared/views';
import { AttributeAndRuleBase } from '../shared/attribute-and-rule.base';
import { Select2Option } from '../shared/attribute-and-rule/views/select2.option';
import { Select2OptionService as CustomerSelect2OptionService } from './shared/select2-option.service';
import { Select2OptionsService as PointOfSalesSelect2OptionsService } from '../point-of-sales/shared/select2-options.service';

import { PricingService } from 'src/app/core/services/pricing-services';

import { CountryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { MembershipLevelService } from 'src/app/core/services/system-services/membership-level.service';
import { MembershipLevelModel } from 'src/app/core/models/reference-model/reference-general-model/membership-level.model';

declare var moment: any;

@Component({
    selector: 'op-pricing-customer',
    templateUrl: './customer.component.html',
    providers: [
        ProductEligibleRestrictedConverter,
    ]
})

export class CustomerComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {
    private dataFetched = false;
    private dateSelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_DAY, 'Date');
    private monthSelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_MONTH, 'Month');
    private yearSelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_YEAR, 'Year');
    private weekdaySelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_WEEKDAY, 'Weekday');
    private dateMonthYearSelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_DATE, 'Date/Month/Year');
    private monthYearSelect2Data = new Select2Data(PricingDetailConstant.CALENDAR_MONTH_YEAR, 'Month/Year');
    private individualTypes = [];

    public rootOption = new Select2Option('<Type>');
    public individualOption = new Select2Option('<Type>');
    public select2LocationTypeOption = new Select2Option('<Area>');
    public nationalityTypeOption = new Select2Option();
    public memberTypeOption = new Select2Option('<Type>');
    public select2OrganisationTypeOption = new Select2Option('<Type>');
    public calendarTypeOption = new Select2Option('<Type>');
    public calendarYearOption = new Select2Option("<Year>");
    public calendarYearsOption: Select2Option;
    public individualAgeGroupTypeReferenceOption: Select2Option;
    public individualCivilStatusReferenceOption: Select2Option;
    public genderReferenceOption: Select2Option;
    public individualInterestTypeReferenceOption: Select2Option;
    public membershipLevelOption: Select2Option;
    public select2IsIsNotConditionOption: Select2Option;
    public select2IsIsNotLessThanMoreThanConditionOption: Select2Option;
    public select2RegionOption: Select2Option;
    public select2CountryOption: Select2Option;
    public select2AirportCityOption: Select2Option;
    public select2LocationGroupOption: Select2Option;
    public select2OrganisationTypeOrganisationTypeOption: Select2Option;
    public select2OrganisationTypeOrganisationRoleOption: Select2Option;
    public select2OrganisationTypeOrganisationGroupOption: Select2Option;
    public dateRangePickerDateOption: Select2Option;
    public calendarDimensionOption = new Select2Option("<Type>");
    public monthSelectorOption = new Select2Option("<Month>");
    public monthsSelectorOption: Select2Option;
    public dateSelectorOption: Select2Option;
    public select2WeekdayOption: Select2Option;

    public focusing: boolean = false;

    public rootData = [
        new Select2Data(PricingDetailConstant.INDIVIDUAL_CODE, "Individual"),
        new Select2Data(PricingDetailConstant.MEMBER_CODE, "Member"),
        new Select2Data(PricingDetailConstant.ORGANISATION_CODE, "Organisation"),
        new Select2Data(PricingDetailConstant.CALENDAR_CODE, "Calendar"),
    ]
    public individualData = [
        new Select2Data(PricingDetailConstant.CUSTOMER_GROUP_CODE, "Age Group"),
        new Select2Data(PricingDetailConstant.AGE_CODE, "Age"),
        new Select2Data(PricingDetailConstant.SOCIAL_STATUS_CODE, "Social Status"),
        new Select2Data(PricingDetailConstant.GENDER_CODE, "Gender"),
        new Select2Data(PricingDetailConstant.INDIVIDUAL_INTEREST_CODE, "Individual Interest"),
        new Select2Data(PricingDetailConstant.LOCATION_CODE, "Location"),
        new Select2Data(PricingDetailConstant.NATIONALITY_CODE, "Nationality")
    ]
    public select2LocationTypeDataList = [
        new Select2Data(PricingDetailConstant.REGION_VALUE, 'Region'),
        new Select2Data(PricingDetailConstant.COUNTRY_VALUE, 'Country'),
        new Select2Data(PricingDetailConstant.AIRPORT_CITY_VALUE, 'Airport/City'),
        new Select2Data(PricingDetailConstant.LOCATION_GROUP_VALUE, 'Location Group')
    ];
    public nationalityTypes = [
        new Select2Data(PricingDetailConstant.COUNTRY_VALUE, 'Country')
    ]
    public memberTypeData = [
        new Select2Data(PricingDetailConstant.LEVEL_CODE, 'Level'),
        new Select2Data(PricingDetailConstant.ACCUMULATE_POINT_CODE, 'Accumulate Point')
    ]
    public select2OrganisationTypeDataList = [
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_TYPE, 'Type'),
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_ROLE, 'Role'),
        new Select2Data(PricingDetailConstant.POINT_OF_SALES_GROUP, 'Group')
    ]
    public calendarTypes = [
        new Select2Data(PricingDetailConstant.MEMBER_SINCE_CODE, 'Member Since'),
        new Select2Data(PricingDetailConstant.DATE_OF_BIRTH_CODE, 'Date of Birth'),
        new Select2Data(PricingDetailConstant.LAST_SERVICE_DATE_CODE, 'Last Service Date')
    ]
    public memberSinceCalendarDimensions = [
        this.yearSelect2Data,
        this.dateMonthYearSelect2Data,
        this.monthYearSelect2Data
    ]
    public dateOfBirthCalendarDimensions = [
        this.weekdaySelect2Data,
        this.dateSelect2Data,
        this.monthSelect2Data,
        this.yearSelect2Data,
        this.dateMonthYearSelect2Data,
        this.monthYearSelect2Data
    ]
    public lastServiceDateCalendarDimensions = this.dateOfBirthCalendarDimensions;
    public years = [];
    public months = moment.months().map(month => {
        return new Select2Data(month.toUpperCase(), month)
    });
    public dates = [];
    public select2WeekdayDataList = PricingDetailConstant.SELECT2_WEEKDAY_DATALIST;

    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>([]);
    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotLessThanMoreThanConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public individualAgeGroupReferences$ = new BehaviorSubject<IndividualAgeGroupReferenceModel[]>(null);
    public individualCivilStatusReferences$ = new BehaviorSubject<IndividualCivilStatusTypeReferenceModel[]>(null);
    public genderReferences$ = new BehaviorSubject<GenderReferenceModel[]>(null);
    public individualInterestTypeReferences$ = new BehaviorSubject<IndividualInterestTypeReferenceModel[]>(null);
    public membershipLevels$ = new BehaviorSubject<MembershipLevelModel[]>(null);
    public domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    private unsubscribe$ = new Subject();

    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.setPreSelectedItems()
        }
    }
    @Input() searchMode: boolean = false;
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    constructor(
        private cdr: ChangeDetectorRef,
        private pricingService: PricingService,
        private conditionReferenceService: ConditionReferenceService,
        private productEligibleSelect2OptionsService: ProductEligibleSelect2OptionService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private individualCivilStatusReferenceService: IndividualCivilStatusTypeReferenceService,
        private genderReferenceService: GenderReferenceService,
        private individualInterestTypeReferenceService: IndividualInterestTypeReferenceService,
        private membershipLevelService: MembershipLevelService,
        private calendarValidityService: CalendarValidityService,
        private domainAttributeService: DomainAttributeService,
        private countryReferenceService: CountryReferenceService,
        private regionReferenceService: RegionReferenceService,
        private locationReferenceService: LocationReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private customerSelect2OptionService: CustomerSelect2OptionService,
        private pointOfSalesSelect2OptionsService: PointOfSalesSelect2OptionsService,
        private converter: ProductEligibleRestrictedConverter) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.select2IsIsNotConditionOption = this.productEligibleSelect2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotLessThanMoreThanConditionOption = this.productEligibleSelect2OptionsService.getSelect2ConditionOption();
        this.individualAgeGroupTypeReferenceOption = this.customerSelect2OptionService.getIndividualAgeGroupReferenceOption();
        this.individualCivilStatusReferenceOption = this.customerSelect2OptionService.getIndividualCivilStatusTypeReferenceOption();
        this.genderReferenceOption = this.customerSelect2OptionService.getGenderReferenceOption();
        this.individualInterestTypeReferenceOption = this.customerSelect2OptionService.getIndividualInterestTypeReferenceOption();
        this.membershipLevelOption = this.customerSelect2OptionService.getMembershipLevelOption();
        this.calendarYearsOption = this.customerSelect2OptionService.getCalendarYearOption();
        this.monthsSelectorOption = this.customerSelect2OptionService.getCalendarMonthsOption();
        this.dateSelectorOption = this.customerSelect2OptionService.getCalendarDateOption();

        this.select2RegionOption = this.productEligibleSelect2OptionsService.getSelect2RegionOption();
        this.select2CountryOption = this.productEligibleSelect2OptionsService.getSelect2CountryOption();
        this.select2AirportCityOption = this.productEligibleSelect2OptionsService.getSelect2AirportCityOption();
        this.select2LocationGroupOption = this.productEligibleSelect2OptionsService.getSelect2LocationGroupOption();
        this.select2WeekdayOption = this.productEligibleSelect2OptionsService.getSelect2WeekdayOption();
        this.dateRangePickerDateOption = this.productEligibleSelect2OptionsService.getDatePickerOption();
        this.dateRangePickerDateOption.locale.format = 'DD/MM/YYYY';

        this.select2OrganisationTypeOrganisationTypeOption = this.pointOfSalesSelect2OptionsService.getSelect2OrganisationTypeOrganisationTypeOption();
        this.select2OrganisationTypeOrganisationRoleOption = this.pointOfSalesSelect2OptionsService.getSelect2OrganisationTypeOrganisationRoleOption();
        this.select2OrganisationTypeOrganisationGroupOption = this.pointOfSalesSelect2OptionsService.getSelect2OrganisationTypeOrganisationGroupOption();

        this.generateYearOptions();
        this.generateDateOptions();

        this.nationalityTypeOption.disabled = true;

        this.getSelect2IsIsNotConditionDataList();
        this.getSelect2IsIsNotLessThanMoreThanConditionDataList();

        this.getIndividualAgeGroupTypeReferences();
        this.getIndividualCivilStatusTypeReferences();
        this.getGenderReferences();
        this.getIndividualInterestTypeReferences();
        this.getMembershipLevels();
        this.getPriceRuleAttributeAttributeTypeReferences();
    }

    public add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let temp = this.rows$.value;
            temp.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(temp);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    public onRootChange(row: PriceRuleAttributeAndRuleRowView, event: any) {
        if (row.type != event) {
            this.resetRow(row);
            this.resetErrorFlags();
        }
        row.type = event;
        switch (event) {
            case PricingDetailConstant.INDIVIDUAL_CODE:
                this.onIndividualTypeChange(row, PricingDetailConstant.CUSTOMER_GROUP_CODE);
                break;
            case PricingDetailConstant.MEMBER_CODE:
                this.onMemberTypeChange(row, PricingDetailConstant.LEVEL_CODE);
                break;
            case PricingDetailConstant.ORGANISATION_CODE:
                row.organisation = new PriceRuleOrganisationView();
                row.organisation.priceRuleOrganisationTypeCode = PricingDetailConstant.CUSTOMER_CODE;
                row.organisation.type = PricingDetailConstant.POINT_OF_SALES_TYPE;
                break;
            case PricingDetailConstant.CALENDAR_CODE:
                row.validity = new PriceRuleValidityView();
                row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.CUSTOMER_CODE;
                row.validity.calendarValidityCode = PricingDetailConstant.MEMBER_SINCE_CODE;
                row.validity.dateTimeDimensionCode = PricingDetailConstant.CALENDAR_YEAR;
                break;
        }
    }

    private resetRow(row: PriceRuleAttributeAndRuleRowView) {
        for (let key of Object.keys(row)) {
            if (row[key]) {
                delete row[key];
            }
        }
    }

    public onIndividualTypeChange(row: PriceRuleAttributeAndRuleRowView, event: any) {
        if (row.type != event) {
            let rowType = row.type;
            this.resetRow(row);
            row.type = rowType;
        }
        let individual = new PriceRuleIndividualView();
        switch (event) {
            case PricingDetailConstant.CUSTOMER_GROUP_CODE:
                individual.individualAgeGroupCode = individual.individualAgeGroupCode ?? [];
                row.individual = individual;
                break;
            case PricingDetailConstant.SOCIAL_STATUS_CODE:
                individual.individualCivilStatusTypeCode = individual.individualCivilStatusTypeCode ?? [];
                row.individual = individual;
                break;
            case PricingDetailConstant.GENDER_CODE:
                individual.genderCode = individual.genderCode ?? [];
                row.individual = individual;
                break;
            case PricingDetailConstant.INDIVIDUAL_INTEREST_CODE:
                individual.individualInterestTypeCode = individual.individualInterestTypeCode ?? [];
                row.individual = individual;
                break;
            case PricingDetailConstant.NATIONALITY_CODE:
                individual.nationalityCode = individual.nationalityCode ?? [];
                row.individual = individual;
                break;
            case PricingDetailConstant.AGE_CODE:
                row.attribute = row.attribute ?? new PriceRuleAttributeView();
                row.attribute.attributeTypeCode = this.domainAttributes$
                    .value.find(attr => attr.attributeTypeCode == PricingDetailConstant.INDIVIDUAL_AGE_CODE).attributeTypeCode;
                break;
            case PricingDetailConstant.LOCATION_CODE:
                row.location = row.location ?? new PriceRuleLocationView();
                break;
        }
    }

    public onMemberTypeChange(row: PriceRuleAttributeAndRuleRowView, event) {
        let rowType = row.type;
        this.resetRow(row);
        row.type = rowType;
        switch (event) {
            case PricingDetailConstant.LEVEL_CODE:
                row.membership = new PriceRuleMembershipView();
                row.membership.membershipLevelCode = [];
                break;
            case PricingDetailConstant.ACCUMULATE_POINT_CODE:
                row.attribute = new PriceRuleAttributeView();
                row.attribute.attributeTypeCode = this.getPriceRuleAttributeTypeIdByAttributeTypeCode(PricingDetailConstant.ACCUMULATE_POINT_CODE);
                break;
        }
    }

    private getPriceRuleAttributeTypeIdByAttributeTypeCode(attributeTypeCode: string) {
        if (this.domainAttributes$.value?.length) {
            return this.domainAttributes$
                .value.find(attr => attr.attributeTypeCode == attributeTypeCode).attributeTypeCode;
        } else {
            this.domainAttributes$.pipe(takeUntil(this.unsubscribe$)).subscribe(
                val => {
                    return val.find(attr => attr.attributeTypeCode == attributeTypeCode).attributeTypeCode;
                }
            )
        }
    }

    private getSelect2IsIsNotConditionDataList() {
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE], (res) => {
            this.select2IsIsNotConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getSelect2IsIsNotLessThanMoreThanConditionDataList() {
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE,
        PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
        PricingDetailConstant.GREATER_THAN_OPERATOR_CODE], (res) => {
            this.select2IsIsNotLessThanMoreThanConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ConditionReferenceModel[]) => {
                    callback(res);
                }
            );
    }

    public rightRowType(rowType: string, type: string) {
        if (rowType) {
            return rowType.includes(type);
        }
        return false;
    }

    private getIndividualAgeGroupTypeReferences() {
        this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: IndividualAgeGroupReferenceModel[]) => {
                    this.individualAgeGroupReferences$.next(res);
                }
            )
    }

    private getIndividualCivilStatusTypeReferences() {
        this.individualCivilStatusReferenceService.getIndividualCivilStatusTypeReferences()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.individualCivilStatusReferences$.next(res);
                }
            )
    }

    private getGenderReferences() {
        this.genderReferenceService.getGenderReferences()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.genderReferences$.next(res);
                }
            )
    }

    private getIndividualInterestTypeReferences() {
        this.individualInterestTypeReferenceService.getIndividualInterestTypeReferences()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.individualInterestTypeReferences$.next(res);
                }
            )
    }

    public onLocationTypeChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.location.locationType != event) {
            row.location = new PriceRuleLocationView();
            row.location.locationType = event;
            row.location.priceRuleLocationTypeCode = PricingDetailConstant.INDIVIDUAL_CODE;
        }
        this.getSelect2IsIsNotConditionDataList();
    }

    private getMembershipLevels() {
        this.membershipLevelService.getByProvider()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.membershipLevels$.next(res);
                }
            )
    }

    public onMembershipTierChange(row: PriceRuleAttributeAndRuleRowView, event) {
        if (row.membership.membershipLevelCode != event) {
            row.membership.membershipLevelCode = event;
        }
    }

    public onOrganisationTypeChange(event, row) {
        if (event != row.organisation.type) {
            row.organisation = new PriceRuleOrganisationView();
            row.organisation.priceRuleOrganisationTypeCode = PricingDetailConstant.CUSTOMER_CODE;
            row.organisation.type = event;
        }
        this.getSelect2IsIsNotConditionDataList();
    }

    private generateYearOptions() {
        let currentYear = moment().year();
        for (let i = currentYear; i >= currentYear - 30; i--) {
            let yearStr = i.toString();
            this.years.push(new Select2Data(yearStr, yearStr))
        }
    }

    private generateDateOptions() {
        for (let i = 1; i <= 31; i++) {
            let dateStr = i + '';
            this.dates.push(new Select2Data(dateStr, dateStr));
        }
    }

    public onCalendarValidityChange(row: PriceRuleAttributeAndRuleRowView, event) {
        let rowType = row.type;
        this.resetRow(row);
        this.resetErrorFlags();
        row.type = rowType;
        switch (event) {
            case PricingDetailConstant.MEMBER_SINCE_CODE:
                row.validity = new PriceRuleValidityView();
                row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.CUSTOMER_CODE;
                row.validity.calendarValidityCode = event;
                row.validity.dateTimeDimensionCode = PricingDetailConstant.CALENDAR_YEAR;
                break;
            default:
                row.validity = new PriceRuleValidityView();
                row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.CUSTOMER_CODE;
                row.validity.calendarValidityCode = event;
                row.validity.dateTimeDimensionCode = PricingDetailConstant.CALENDAR_WEEKDAY;
                break;
        }
    }

    public onIndividualAgeGroupChange(value: any, row: PriceRuleAttributeAndRuleRowView) {
        row.individual.individualAgeGroupCode = value;
        this.resetErrorFlags();
    }

    public onIndividualCivilStatusChange(value: any, row: PriceRuleAttributeAndRuleRowView) {
        row.individual.individualCivilStatusTypeCode = value;
        this.resetErrorFlags();
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    private getPriceRuleAttributeAttributeTypeReferences() {
        combineLatest([
            this.domainAttributeService.getPriceRuleAttributes("", PricingDetailConstant.CUSTOMER_CODE, PricingDetailConstant.INDIVIDUAL_AGE_CODE),
            this.domainAttributeService.getPriceRuleAttributes("", PricingDetailConstant.CUSTOMER_CODE, PricingDetailConstant.ACCUMULATE_POINT_CODE)
        ]).pipe(takeUntil(this.unsubscribe$)).subscribe(
            res => {
                if (res?.length) {
                    let domainAttributes = res.filter(r => r?.length).map(r => r.reduce(rr => rr));
                    if (this.searchMode) {
                        domainAttributes = domainAttributes.filter(da => da.searchFlag == true);
                        this.filterAttributesNotIncludeInSearch(res);
                    }
                    this.domainAttributes$.next(domainAttributes)
                }
            }
        )

    }

    private filterAttributesNotIncludeInSearch(res: [DomainAttributeModel[], DomainAttributeModel[]]) {
        let ageAttribute = res[0][0];
        let accumulateAttribute = res[1][0];
        if (!ageAttribute.searchFlag) {
            this.individualData = this.individualData.filter(id => id.id != PricingDetailConstant.AGE_CODE);
        }
        if (!accumulateAttribute.searchFlag) {
            this.memberTypeData = this.memberTypeData.filter(id => id.id != PricingDetailConstant.ACCUMULATE_POINT_CODE);
        }
    }

    public onDateTimeDimensionChange(row: PriceRuleAttributeAndRuleRowView, event) {
        let calendarValidityCode = row.validity.calendarValidityCode;
        row.validity = new PriceRuleValidityView();
        row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.CUSTOMER_CODE;
        row.validity.calendarValidityCode = calendarValidityCode;
        row.validity.dateTimeDimensionCode = event;
    }

    private setPreSelectedItems() {
        for (let row of this.rows$.value) {
            this.setRootType(row);
            if (row.organisation?.organisationTypeCode) {
                this.getOrganisationTypeReferencePreSelected(row.organisation?.organisationTypeCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.organisation?.organisationGroupCode) {
                this.getOrganisationGroupReferencePreSelected(row.organisation?.organisationGroupCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.organisation?.organisationRoleCode) {
                this.getOrganisationRoleReferencePreSelected(row.organisation?.organisationRoleCode,
                    (data) => {
                        row.organisation.preSelectedDataList$.next(data)
                    });
            }
            if (row.location?.regionCode) {
                this.getRegionReferencePreSelected(row.location?.regionCode,
                    (regions: Select2Data[]) => {
                        row.location.locationType = PricingDetailConstant.REGION_VALUE;
                        row.location.preSelectedDataList$.next(regions)
                    })
            }
            if (row.location?.countryCode) {
                this.getCountryReferencePreSelected(row.location?.countryCode,
                    (countries: Select2Data[]) => {
                        row.location.locationType = PricingDetailConstant.COUNTRY_VALUE;
                        row.location.preSelectedDataList$.next(countries)
                    })
            }
            if (row.location?.locationId) {
                this.getLocationReferencePreSeleted(row.location?.locationId,
                    (locations: Select2Data[]) => {
                        row.location.locationType = PricingDetailConstant.AIRPORT_CITY_VALUE;
                        row.location.preSelectedDataList$.next(locations)
                    })
            }
            if (row.location?.locationGroupId) {
                this.getLocationGroupPreSeleted(row.location?.locationGroupId,
                    (locationGroups: Select2Data[]) => {
                        row.location.locationType = PricingDetailConstant.LOCATION_GROUP_VALUE;
                        row.location.preSelectedDataList$.next(locationGroups)
                    })
            }
            if (row.individual?.nationalityCode) {
                this.countryReferenceService.getCountryReference(row.individual.nationalityCode)
                    .pipe(takeUntil(this.unsubscribe$)).subscribe(
                        (res: CountryReferenceModel[]) => {
                            row.individual.preSelectedDataList$.next(res.map(r => new Select2Data(r.countryCode, r.countryName)));
                        }
                    )
            }
            if (row.validity?.startDateTime && row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_DATE) {
                row.validity.startDateTime = moment(row.validity.startDateTime, 'YYYY/MM/DD').format('DD/MM/YYYY');
            }
        }
        this.togglePanelCollapseStatus(false);
    }

    private setRootType(row: PriceRuleAttributeAndRuleRowView) {
        if (row.individual || row.location) {
            row.type = PricingDetailConstant.INDIVIDUAL_CODE;
        } else if (row.membership) {
            row.type = PricingDetailConstant.MEMBER_CODE;
        } else if (row.organisation) {
            row.type = PricingDetailConstant.ORGANISATION_CODE;
        } else if (row.validity) {
            row.type = PricingDetailConstant.CALENDAR_CODE;
        } else if (row.attribute) {
            this.domainAttributes$.pipe(takeUntil(this.unsubscribe$)).subscribe(
                val => {
                    if (val?.length) {
                        let attributeTypeCode = val.find(v => v.attributeTypeCode == row.attribute?.attributeTypeCode).attributeTypeCode;
                        switch (attributeTypeCode) {
                            case PricingDetailConstant.INDIVIDUAL_AGE_CODE:
                                row.type = PricingDetailConstant.INDIVIDUAL_CODE;
                                break;
                            case PricingDetailConstant.ACCUMULATE_POINT_CODE:
                                row.type = PricingDetailConstant.MEMBER_CODE;
                                break;
                        }
                        this.cdr.markForCheck();
                    }
                }
            )
        }
    }

    public getIndividualType(row: PriceRuleAttributeAndRuleRowView): string {
        if (row.individual?.individualAgeGroupCode) {
            return PricingDetailConstant.CUSTOMER_GROUP_CODE;
        } else if (row.individual?.individualCivilStatusTypeCode) {
            return PricingDetailConstant.SOCIAL_STATUS_CODE;
        } else if (row.individual?.genderCode) {
            return PricingDetailConstant.GENDER_CODE;
        } else if (row.individual?.individualInterestTypeCode) {
            return PricingDetailConstant.INDIVIDUAL_INTEREST_CODE;
        } else if (row.individual?.nationalityCode) {
            return PricingDetailConstant.NATIONALITY_CODE;
        } else if (row.attribute) {
            return PricingDetailConstant.AGE_CODE;
        } else if (row.location) {
            return PricingDetailConstant.LOCATION_CODE;
        }
        return PricingDetailConstant.CUSTOMER_GROUP_CODE;
    }

    public getLocationGroupPreSeleted(ids: string[], callback) {
        this.pricingService.getLocationGroup(ids)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.locationGroupId, r.locationGroupName)));
                }
            )
    }

    public getCountryReferencePreSelected(codes: string[], callback) {
        this.countryReferenceService.getCountryReference(codes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.countryCode, r.countryName)))
                }
            )
    }

    public getRegionReferencePreSelected(id: string[], callback) {
        this.regionReferenceService.getRegionReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.regionCode, r.regionName)));
                }
            )
    }

    private getLocationReferencePreSeleted(id: string[], callback) {
        this.locationReferenceService.getLocationReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.locationId, r.locationName)));
                }
            )
    }

    private getOrganisationTypeReferencePreSelected(ids: string[], callback) {
        this.organisationTypeReferenceService.getOrganisationTypeReference(ids)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationTypeCode, r.organisationTypeName)));
                }
            )
    }
    private getOrganisationGroupReferencePreSelected(id: string[], callback) {
        this.organisationGroupReferenceService.getOrganisationGroupReference(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationGroupCode, r.organisationGroupName)));
                }
            )
    }
    private getOrganisationRoleReferencePreSelected(id: string[], callback) {
        this.organisationRoleReferenceService.getByCodes(id)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.organisationRoleCode, r.organisationRoleName)));
                }
            )
    }

    public getMembershipType(row: PriceRuleAttributeAndRuleRowView): string {
        if (row.membership) {
            return PricingDetailConstant.LEVEL_CODE;
        } else if (row.attribute) {
            return PricingDetailConstant.ACCUMULATE_POINT_CODE;
        } else {
            return PricingDetailConstant.LEVEL_CODE;
        }
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public onInputChange(event: Event, field: any, fieldName: string) {
        field[fieldName] = (event.target as HTMLInputElement).value;
        this.resetErrorFlags();
    }
}
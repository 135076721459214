import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { VehicleSeatMapModel } from '../../models/vehicle-model/vehicle-seatmap';

@Injectable({
    providedIn: 'root'
})
export class VehicleSeatMapService extends ApiServiceBase {
    private readonly PATH = "/vehicle/vehicleseatmap"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getByCompartment(vehicleConfigurationCompartmentId: string): Observable<VehicleSeatMapModel[]> {
        return this.httpGet<VehicleSeatMapModel[]>(this.PATH + '/compartment/' + vehicleConfigurationCompartmentId, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { OrganisationGroupReferenceModel, OrganisationModel, OrganisationRoleReferenceModel, OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';
import { SpecialServiceAddCommandModel, SpecialServiceEditCommandModel, SpecialServiceSearchCommandModel, SpecialServiceViewModel } from 'src/app/core/models/product-model/specialservice-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { DomainAttributeService, HashTagReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { SpecialServiceService } from 'src/app/core/services/product-services';
import { ProductSearchFilterService } from 'src/app/core/services/product-services/product-search-filter.service';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService } from 'src/app/core/services/system-services';
import { SeasonReferenceService } from 'src/app/core/services/system-services/season-reference.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { SpecialServiceAttributeComponent } from '../special-service-attribute/special-service-attribute.component';
import { MapperService } from './shared/mapper.service';
import { SpecialServiceSearchTableView } from './shared/specialservice-search-table.view';
import { SpecialServiceFilterNameComponent } from './special-service-filter-name/special-service-filter-name.component';
import { SpecialServiceSearchConditionComponent } from './special-service-search-condition/special-service-search-condition.component';
import { SpecialServiceSearchTableComponent } from './special-service-search-table/special-service-search-table.component';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { AlertBarService } from 'src/app/shared/layout/alertbar';

@Component({
    selector: 'op-special-service-search',
    templateUrl: './special-service-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProductTypeGroupService,
        MapperService,
        Select2DataMapperService,
        HashTagMapperService
    ]
})
export class SpecialServiceSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly SERVICE_PRODUCT_CATE_CODE = 'SERVICE';
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";
    private readonly FILTERNAME_PANEL = 'Filter Name';
    private readonly SEARCHCONDITION_PANEL = 'Search';

    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public productSearchFilters$ = new BehaviorSubject<Select2Data[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public productSearchResults$ = new BehaviorSubject<SpecialServiceSearchTableView[]>(null);
    public paggingView: PagingDataView[];
    public selectedItem: any;
    private unsubscribe$ = new Subject();
    public advanceSearchModeEnabled = false;
    public specialServiceView$ = new BehaviorSubject<SpecialServiceViewModel>(null);
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public specialServiceFilterModel: SpecialServiceViewModel;
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    private actionBarHandler: ActionBarHandlerModel;
    public userSecurity: SecurityGroupSecurityModel;
    public productHashtagValueViews: ProductHashTagView[];
    public completeLoadReference: boolean = false;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    @Input() productGroup: string;
    @Input() selectedProductCategory: ProductCategory;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @ViewChild(SpecialServiceSearchConditionComponent) searchConditionComponent: SpecialServiceSearchConditionComponent;
    @ViewChild(SpecialServiceSearchTableComponent) searchTableComponent: SpecialServiceSearchTableComponent;
    @ViewChild(SpecialServiceFilterNameComponent) searchFilterNameComponent: SpecialServiceFilterNameComponent;
    @ViewChild(SpecialServiceAttributeComponent) attributeAndRuleComponent: SpecialServiceAttributeComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productSearchFilterService: ProductSearchFilterService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private specialServiceService: SpecialServiceService,
        private mapperService: MapperService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private loadingSpinner: LoadingSpinnerService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private hashTagMapperService: HashTagMapperService,
        public alertBarService: AlertBarService) { }

    ngOnInit(): void {
        this.getProductSearchFilter();
        this.getProductGroupReference();
        this.getProductTypeGroup();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getHashTagReference();
        this.getSupplier();
        this.getProvider();
        this.getProductNumberTypeReference();
        this.getDomainAttributeTypeSearch();
        this.getDomainAttributeTypeService();
        this.getDomainAttributeTypeInventory();
        this.getSeasonReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getProductLocationPointReference();
        this.getProductLocationTypeReference();
        this.getRegionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getOrganisationRoleReferences();
        this.getProductCategoryReference();
        this.getReferencesValidity();
       
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.getSelectedServiceGroup();
        if (this.executeFlag == true) {
            this.searchConditionComponent.doSearch();
        }
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setDefaultActionBarHandler() {
        this.userSecurity = this.selectedProductCategory?.security;
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.disable();
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.disable();

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private getSelectedServiceGroup() {
        if (this.productGroup != null) {
            this.searchConditionComponent.productGroupCode = this.productGroup
        }
    }

    private getProductSearchFilter() {
        this.productSearchFilterService.getByProductCategory(this.SERVICE_PRODUCT_CATE_CODE)
            .subscribe(
                (responses) => {
                    let searchfilters = responses.map(r => new Select2Data(r.productId, r.productName));
                    this.productSearchFilters$.next(searchfilters);
                }
            )
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReference$.next(responses);
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    let serviceProductGroup = responses.filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE);
                    this.serviceProductGroupReference$.next(serviceProductGroup);
                }
            )
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.getUsageTypeReferenceWithFilter(responses); 
                    }
                }
            )
    }

    private getUsageTypeReferenceWithFilter(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferenceFilter$.next(
            responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
                x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.USAGETYPE_FILTER));
    }

    public onSearch(condition: SpecialServiceViewModel) {
        if (!condition) {
            return;
        }
        let searchCommand = this.mapperService.convertToSearchCommand(condition);

        searchCommand = this.addAdvanceConditionToSearchCommand(searchCommand);

        this.search(searchCommand);
        this.focusOnSearchResult();
    }

    private search(searchCommand: SpecialServiceSearchCommandModel) {
        this.spinnerService.show();
        this.specialServiceService.search(searchCommand)
            .subscribe(
                (responses: SpecialServiceViewModel[]) => {
                    this.paggingView = new Array<PagingDataView>();
                    let searchResults = this.mapperService.specialServiceSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                    this.spinnerService.hide();
                }
            );
    }

    private focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    public onClearCondition(condition: SpecialServiceViewModel) {
        this.advanceSearchModeEnabled = false;
        if (!condition) {
            condition = new SpecialServiceViewModel();
            condition.statusCode = this.ACTIVE_STATUS;
        } 
        this.searchConditionComponent.completeProcessing();
        this.searchFilterNameComponent.completeProcessing();
        this.attributeAndRuleComponent.clearCondition();
        this.alertBarService.hide();
        this.specialServiceView$.next(condition);
        this.searchTableComponent.dataGrid.instance.clearFilter();
        this.productSearchResults$.next(null);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.searchTableComponent.onNew();
                break;
            case ACTION_STATUS.copy:
                this.onCopyClick();
                break;
            case ACTION_STATUS.save:
                this.onSaveFilterClick();
                break;
            case ACTION_STATUS.saveAs:
                this.onSaveFilterClick(true);
                break;
            case ACTION_STATUS.refresh:
                this.onRefresh();
                break;
        }
    }

    onSpecialServiceSearchFilterIdChange(productId: string) {
        if (productId) {
            this.getSpecialService(productId);
        } else {
            this.onClearCondition(null);
        }
    }

    private getSpecialService(productId: string) {
        this.spinnerService.show();
        this.specialServiceService.getByProductId(productId)
            .subscribe(
                (response: SpecialServiceViewModel) => {
                    this.spinnerService.hide();
                    response = this.getIsOwnerFilter(response);
                    this.specialServiceView$.next(response);
                    this.specialServiceFilterModel = response;
                    this.attributeAndRuleComponent.fillToAdvanceSearchCondition(response);
                    this.toggleCopyButton();
                },
                () => {
                    this.spinnerService.hide()
                }
            )
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReference$.next(responses);
                    this.setProductHashTagValue([]);
                }
            )
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));                   
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeSearch() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", true, "", "", "SERVICE")
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    responses.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                    this.domainAttributeSearch$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeService() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    this.domainAttributeService$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeInventory() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    if (responses) {
                        this.domainAttributeINV$.next(
                            responses.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
                    }
                }
            )
    }

    private getSeasonReference() {
        this.seasonReferenceService.getAll()
            .subscribe(
                (responses: SeasonReferenceModel[]) => {
                    this.seasonReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getProductLocationPointReference() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReference$.next(responses);
                }
            )
    }

    private getProductLocationTypeReference() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
                        productLocationTypeReference.push(response);
                        this.productLocationTypeReference$.next(productLocationTypeReference);
                    }
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    public onCopyClick() {
        if (this.specialServiceFilterModel) {
            this.copyFilter();
        } else {
            this.searchTableComponent.onCopy();
        }
    }

    onSelectedChange(item: SpecialServiceViewModel) {
        this.selectedItem = item;
    }

    async copyFilter() {
        if (!this.specialServiceFilterModel) {
            return;
        }
        this.advanceSearchModeEnabled = true;
        this.specialServiceFilterModel.productName += ' - copy';
        this.specialServiceFilterModel.productId = null;

        this.specialServiceView$.next(this.specialServiceFilterModel);
        this.searchFilterNameComponent.fillCopyToForm(this.specialServiceFilterModel);

        this.specialServiceFilterModel = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).disable();
            handler.get(ACTION_STATUS.delete).disable();
            this.actionbarService.updateState(handler);
        }, 0);
    }

    public onRefresh() {
        this.searchConditionComponent.doSearch();
    }

    private addProduct(command: SpecialServiceAddCommandModel) {
        this.loadingSpinner.show();
        this.specialServiceService.addProduct(command)
            .subscribe(
                (response) => {
                    this.loadingSpinner.saveComplete();
                    this.getFilter(response.body);
                    this.getProductSearchFilter();
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private editProduct(command: SpecialServiceEditCommandModel) {
        this.loadingSpinner.show();
        this.specialServiceService.editProduct(command)
            .subscribe(
                (response) => {
                    this.loadingSpinner.saveComplete();
                    this.getFilter(response.body);
                    this.getProductSearchFilter();
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private fillFormGroupToAddModel(condition: SpecialServiceViewModel, advanceCondition: SpecialServiceAddCommandModel): SpecialServiceAddCommandModel {
        advanceCondition.productCategoryCode = this.SERVICE_PRODUCT_CATE_CODE;
        advanceCondition.productGroupCode = condition.productGroupCode;
        advanceCondition.productTypeCode = condition.productTypeCode;
        advanceCondition.productName = condition.productName;
        advanceCondition.usageTypeCode = condition.usageTypeCode;
        advanceCondition.statusCode = condition.statusCode;
        advanceCondition.isOwnerFilter = condition.isOwnerFilter;
        advanceCondition.searchName = condition.searchName;
        advanceCondition.searchStatusCode = condition.searchStatusCode;
        advanceCondition.searchUsageTypeCode = condition.searchUsageTypeCode;
        advanceCondition.draftFlag = true;
        return advanceCondition;
    }

    private fillFormGroupToEditModel(condition: SpecialServiceViewModel, advanceCondition: SpecialServiceAddCommandModel): SpecialServiceEditCommandModel {
        let editCommand: SpecialServiceEditCommandModel = {
            productId: condition.productId,
            productCategoryCode: this.SERVICE_PRODUCT_CATE_CODE,
            productGroupCode: condition.productGroupCode,
            productTypeCode: condition.productTypeCode,
            providerId: null,
            parentProductId: null,
            productName: condition.productName,
            productDescription: null,
            supplierId: null,
            displayCode: null,
            draftFlag: true,
            searchName: condition.searchName,
            searchUsageTypeCode: condition.searchUsageTypeCode,
            searchStatusCode: condition.searchStatusCode,
            usageTypeCode: this.USAGETYPE_FILTER,
            isOwnerFilter: condition.isOwnerFilter,
            filterUserAccountId: null,
            statusCode: condition.statusCode,
            productHashTags: advanceCondition.productHashTags,
            productNumbers: advanceCondition.productNumbers,
            productRestrictionProducts: advanceCondition.productRestrictionProducts,
            productRestrictionProductNumbers: advanceCondition.productRestrictionProductNumbers,
            productRestrictionRoutes:advanceCondition.productRestrictionRoutes,
            productRestrictionLocations: advanceCondition.productRestrictionLocations,
            productValidities: advanceCondition.productValidities,
            productAttributes: advanceCondition.productAttributes,
            productInventoryDimensions: null,
            productInventories: null,
            productRestrictionOrganisations: advanceCondition.productRestrictionOrganisations,
            languageTranslationSet: null,
            medias: [],
            seasonCode: advanceCondition.seasonCode,
            finalFlag: null
        };
        return editCommand;

    }

    public addAdvanceConditionToSearchCommand(searchCommand: SpecialServiceSearchCommandModel) {
        let condition = this.attributeAndRuleComponent?.getAdvanceSearchValue();
        if (condition) {
            searchCommand.productHashTags = condition.productHashTags;
            searchCommand.productNumbers = condition.productNumbers;
            searchCommand.productRestrictionLocations = condition.productRestrictionLocations;
            searchCommand.productRestrictionOrganisations = condition.productRestrictionOrganisations;
            searchCommand.productRestrictionProductNumbers = condition.productRestrictionProductNumbers;
            searchCommand.productRestrictionProducts = condition.productRestrictionProducts;
            searchCommand.productRestrictionRoutes = condition.productRestrictionRoutes;
            searchCommand.productValidities = condition.productValidities;
            searchCommand.productAttributes = condition.productAttributes;
            searchCommand.seasonCode = condition.seasonCode;
        }
        return searchCommand;
    }

    editFilter(productId: string) {
        this.advanceSearchModeEnabled = true;
        this.getSpecialService(productId);
    }

    getIsOwnerFilter(response: SpecialServiceViewModel): SpecialServiceViewModel{
        response.isOwnerFilter = response.filterUserAccountId ? true: false;
        return response
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference$.value,
            value, false);
        this.productHashTag$.next(productHashtagViews);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
    }

   
    private getReferencesValidity() {
        this.getReferencesValidityData().then(() => {
            this.updateLoadReferenceCompleted();
        });
    }

    private getReferencesValidityData() : Promise<void> {
        this.loadingSpinner.show();
        return new Promise((resolve, reject) => {
        forkJoin({
            conditionReferences: this.conditionReferenceService.getConditionsByCodes([]),
            calendarValidityReferences:  this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE),
            dateTimeDimensionReferences:  this.dateTimeDimensionReferenceService.getDateTimeDimensionReference(),
        })
            .subscribe(({
                conditionReferences,
                calendarValidityReferences,
                dateTimeDimensionReferences
            }) => {
              
                this.conditionReference$.next(conditionReferences);
                this.calendarValidityReference$.next(calendarValidityReferences);
                this.dateTimeDimensionReference$.next(dateTimeDimensionReferences);
                resolve();
            })
        });            
    }

    private updateLoadReferenceCompleted()  {
        this.completeLoadReference = true;
        this.changeDetectorRef.detectChanges();
    }

    onSaveFilterClick(isSaveAs: boolean = false) {
        let product = this.searchFilterNameComponent.getSaveModel(isSaveAs);
        let condition = this.searchConditionComponent.getValues(true);

        if (!this.validateAll()) {
            this.displayAlertBar();
            return;
        }
        if (!condition || !product) {
            return;
        }

        condition.productId = product.productId;
        condition.productName = product.productName;
        condition.isOwnerFilter = product.isOwnerFilter;
        condition.usageTypeCode = this.USAGETYPE_FILTER;
        if (!product.productId) {
            this.addNewFilter(condition);
        }
        else {
            this.updateFilter(condition);
        }
    }

    private addNewFilter(condition: SpecialServiceViewModel) {
        let advanceCondition = this.attributeAndRuleComponent.getAdvanceSearchValue();
        if (!advanceCondition) {
            return;
        }

        let command = this.fillFormGroupToAddModel(condition, advanceCondition);
        this.addProduct(command);
    }

    private updateFilter(condition: SpecialServiceViewModel) {
        let advanceCondition = this.attributeAndRuleComponent.getAdvanceSearchValue();
        if (!advanceCondition) {
            return;
        }
        let command = this.fillFormGroupToEditModel(condition, advanceCondition);
        this.editProduct(command);
    }

    private validateAll(): boolean {
        return this.searchFilterNameComponent.validateForm() &&
            this.searchConditionComponent.validateForm();
    }

    private displayAlertBar() {
        if (this.displayAlertBarFilterPanel()) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.searchFilterNameComponent.getErrorMessageForm());
        } else if (this.displayAlertBarConditionPanel()) {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.searchConditionComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    private displayAlertBarFilterPanel(): boolean {
        if (!this.searchFilterNameComponent.validateForm()) {
            return true;
        }
        return false;
    }

    private displayAlertBarConditionPanel(): boolean {
        if (!this.searchConditionComponent.validateForm()) {
            return true;
        }
        return false;
    }

    public onSearchConditionFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else if (this.searchConditionComponent.processing) {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.searchConditionComponent.getErrorMessageForm());
        }
    }

    public onFilterNameFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else if (this.searchFilterNameComponent.processing) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.searchFilterNameComponent.getErrorMessageForm());
        }
    }

    private getFilter(productId) {
        if (productId) {
            this.getSpecialService(productId);
        }
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchConditionComponent.doSearch();
                break;
            default:
                break;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
      }
}

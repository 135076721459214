import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";

@Injectable({
    providedIn: 'root'
})
export class PriceRuleTypeReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/PriceRuleTypeReference"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {
        super(httpClient, authService);
    }

    public getPriceRuleTypeReferences() {
        return this.httpGet<any>(this.REFERENCE_PATH, null)
            .pipe(
                map(res => {
                    return res.body
                })
            )
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndividualDocumentInvoiceComponent } from './individual-document-invoice.component';
import { InvoiceIndividualSearchComponent } from './invoice-individual-search/invoice-individual-search.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { InvoiceIndividualSearchConditionComponent } from './invoice-individual-search/invoice-individual-search-condition/invoice-individual-search-condition.component';
import { InvoiceIndividualSearchResultComponent } from './invoice-individual-search/invoice-individual-search-result/invoice-individual-search-result.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DxDataGridModule } from 'devextreme-angular';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { InvoiceIndividualDetailComponent } from './invoice-individual-detail/invoice-individual-detail.component';
import { IndividualTitleReferenceSelectionModule } from 'src/app/core/components/individual-title-reference-selection';
import { LanguageSelectionModule } from 'src/app/core/components/language-selection';
import { PhoneTypeSelectionModule } from 'src/app/core/components/phone-type-selection';
import { PhonePrefixSelectionModule } from 'src/app/core/components/phone-prefix-selection';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';

@NgModule({
  declarations: [
    IndividualDocumentInvoiceComponent,
    InvoiceIndividualSearchComponent,
    InvoiceIndividualSearchConditionComponent,
    InvoiceIndividualSearchResultComponent,
    InvoiceIndividualDetailComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    FocusingModule,
    FormsModule,
    IndividualTitleReferenceSelectionModule,
    ItemsFoundModule,
    LanguageSelectionModule,
    MatIconModule,
    PanelsModule,
    PhonePrefixSelectionModule,
    PhoneTypeSelectionModule,
    Select2Module,
    UiModule
  ],
  exports: [
    IndividualDocumentInvoiceComponent
  ]
})
export class IndividualDocumentInvoiceModule { }

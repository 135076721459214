import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { InsightCommentDetailComponent } from "./detail/comment-detail.component";
import { CommentDetailDirective } from "./detail/comment.directive";
import { CommentView } from "./shared/comment-view";
import { InsightCommentTableComponent } from "./table/comment-table.component";
import { cloneDeep } from "lodash";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-comment',
    templateUrl: './insight-detail-comment.component.html'
})

export class InsightDetailCommentComponent {
    @Input() commentTypeReferences: CommentTypeReferenceModel[];
    @Input() commentClassificationReferences: CommentClassificationReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isCommentsEmpty: boolean = true;
    public isMoreThanOneComments: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public comment = {} as CommentView;
    public comments: CommentView[] = new Array();
    public previousComments: CommentView[] = new Array();

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @ViewChild(CommentDetailDirective) commentDetailDirective: CommentDetailDirective;
    @ViewChild(InsightCommentDetailComponent) commentDetailComponent: InsightCommentDetailComponent;
    @ViewChild(InsightCommentTableComponent) commentTableComponent: InsightCommentTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newCommentDetail: InsightCommentDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerComments: CommentView[]) {
        this.clearData();
        if (passengerComments && passengerComments.length > 0) {
            this.fillModelInCase(passengerComments);
        }

        this.comments = passengerComments;
        this.previousComments = passengerComments;

        if (this.comments.length > 0) {
            this.isCommentsEmpty = false;
        }

        if (this.comments.length == 1) {
            this.isMoreThanOneComments = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.comment = {} as CommentView;
    }

    private fillModelInCase(comments: CommentView[]) {
        if (comments.length == 1) {
            this.fillModelSingleRecord(comments[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: CommentView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.comment = view;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneComments = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: CommentView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightCommentDetailComponent);

        let viewContainerRef = this.commentDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightCommentDetailComponent = (<InsightCommentDetailComponent>componentRefs.instance);
        component.commentTypeReferences = this.commentTypeReferences;
        component.commentClassificationReferences = this.commentClassificationReferences;
         
        if (dataEdit != null) {
            component.insightComment = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newCommentDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightComment = this.commentDetailComponent.getInsightComment();
        if (insightComment) {
            this.savedata(insightComment);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewComment() {
        this.isApplyBtnClicked = true;
        let insightComment = this.newCommentDetail.getInsightComment();
        if (insightComment) {
            this.savedata(insightComment);
            this.showAdd = true;         
            this.displayAfterSave();
        }
    }

    private savedata(data: CommentView) {
        if (this.comments?.length) {
            var filterIndex = this.comments.findIndex(x => x.no == data.no);
            this.setFlagToFalseIfUndefined(data);

            this.updateCommentViews(filterIndex, data);
        }
        else {
            data.no = 1;
            this.comment = data;
            this.setFlagToFalseIfUndefined(data);
            this.comments.push(data);
        }

        this.isCommentsEmpty = false;
        this.commentTableComponent.dataGrid.instance.refresh();
    }

    setFlagToFalseIfUndefined(data: CommentView) {
        if (!data.privateFlag) {
            data.privateFlag = false;
        }
        if (!data.lockedFlag) {
            data.lockedFlag = false;
        }
        if (!data.warningFlag) {
            data.warningFlag = false;
        }
    }

    private updateCommentViews(filterIndex: number, data: CommentView) {
        if (filterIndex == -1) {
            data.no = this.comments.length + 1;
            this.comments.push(data);
        }
        else {
            this.comments[filterIndex] = data;
        }
    }

    private displayAfterSave() {
        if (this.comments?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.commentDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.comments.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        this.setMoreThanOneComments();
        this.deleteDataFromCommentViews(data);
    }

    getDeleteData(): CommentView {
        if (this.comments.length > 1) {
            return this.commentTableComponent.selectedItem;
        }

        return this.comment;
    }

    private setMoreThanOneComments() {
        if (this.comments.length > 1) {
            this.isMoreThanOneComments = true;
        } 
        else {
            this.isMoreThanOneComments = false;
        }
    }

    private deleteDataFromCommentViews(data: CommentView) {
        let views = new Array<CommentView>();
        if (this.comments != null) {
            views = this.comments;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.comments = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.comments.length == 1) {
            this.comments[0].privateFlag = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForCommentDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.comments.length == 0 || this.comments.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForCommentDetailForm() {
        if (this.comments.length == 1) {
            this.comment = this.comments[0];
            this.showAdd = true;
        }
        else {
            this.comment = {} as CommentView;
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.commentTableComponent.selectedItem);
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.comments.length == 0) {
            this.comment = {} as CommentView;
        }

        if (this.comments.length <= 1) {
            this.isMoreThanOneComments = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.commentDetailComponent) {
            return this.commentDetailComponent.setProcessing(false);
        }

        if (this.newCommentDetail) {
            return this.newCommentDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.comments.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.comments.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.comments.length == 1) {
            let copyComment = cloneDeep(this.comments[0]);
            copyComment.commentId = null;
            this.openCopyDetailForm(copyComment);
            return;
        }

         if (!this.commentTableComponent.selectedItem) {
            return;
        }

        let copyComment = cloneDeep(this.commentTableComponent.selectedItem);
        copyComment.commentId = null;
        this.openCopyDetailForm(copyComment);
    }

    private setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneComments = true;
    }

    private setCopyCommentProperty(copyComment: CommentView) {
        copyComment.no = null;
    }

    private openCopyDetailForm(copyComment: CommentView) {
        this.setCopyCommentProperty(copyComment);
        this.openDetail();
        this.loadDetailForm(copyComment);
    }

    public validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.comments.length <= 0) {
            return true;
        }

        if (this.commentDetailComponent && this.isApplyBtnClicked) {
            return this.commentDetailComponent.isValidForm();
        }

        if (this.commentDetailComponent && this.isApplyBtnClicked) {
            return this.commentDetailComponent.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.commentDetailComponent) {
            return this.commentDetailComponent.getErrorMessageForm();
        }

        if (this.newCommentDetail) {
            return this.newCommentDetail.getErrorMessageForm();
        }
    }
}
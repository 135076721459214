import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SecurityAuditsComponent } from './security-audits.component';
import { SecurityLevel2TabsModule } from '../shared/security-level2-tabs/security-level2-tabs.module';
import { UiModule } from 'src/app/shared/ui/ui.module';

@NgModule({
    declarations: [
        SecurityAuditsComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        NgbNavModule,
        RouterModule,
        SecurityLevel2TabsModule,
        UiModule
    ],
    exports: [
        SecurityAuditsComponent
    ]
})
export class SecurityAuditsModule { }

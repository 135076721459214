import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'op-translation-media-preview',
    templateUrl: './translation-media-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class TranslationMediaPreviewComponent {

    @Input() url:any;
    @Input() viewer:string;

    constructor() {
    }
}
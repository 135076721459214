import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";
import { PRODUCT_DASHBOARD_FEES_SOLD_LAST7D_COUNT_DEMO } from "./product-dashboard-fees-sold-last7d-count-demo-data";
import { PRODUCT_DASHBOARD_FEES_REVENUE_LAST7D_COUNT_DEMO } from "./product-dashboard-fees-revenue-last7d-count-demo-data";
import { PRODUCT_CHART_COLORS } from "../../../../../shared/ui/chart/chart-color.constant";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-fees-mini-charts.component.html',
    selector: 'op-product-dashboard-fees-mini-charts'
})
export class ProductDashboardFeesMiniChartsComponent implements AfterViewInit {
    public readonly ccolor = PRODUCT_CHART_COLORS;
    
    public soldLast7dCountInfo: OrdersDashboardOrdersCountModel[];
    public revenueLast7dCountInfo: OrdersDashboardOrdersCountModel[];
    public soldLast7dSum: number;
    public revenueLast7dSum: number;

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loadSoldLast7DaysCount();
            this.loadRevenueLast7DaysCount();
        }, 300);
    }

    private loadSoldLast7DaysCount() {
        setTimeout(() => {
            this.soldLast7dCountInfo = PRODUCT_DASHBOARD_FEES_SOLD_LAST7D_COUNT_DEMO;
            this.soldLast7dSum = this.soldLast7dCountInfo.map(m => m.salesChannel1).reduce((prev, current) => prev + current);
            this.changeDetection.detectChanges();
        }, 500);
    }

    private loadRevenueLast7DaysCount() {
        setTimeout(() => {
            this.revenueLast7dCountInfo = PRODUCT_DASHBOARD_FEES_REVENUE_LAST7D_COUNT_DEMO;
            this.revenueLast7dSum = this.revenueLast7dCountInfo.map(m => m.salesChannel1).reduce((prev, current) => prev + current);
            this.changeDetection.detectChanges();
        }, 700);
    }

    
}
export const PAGE_TITLE = {
    schedule_queue: 'Schedule Queue' ,
    standard_schedule_information: 'standard schedule information',
    schedule_season: 'schedule season',
}

export const MODULE_NAME = {
    schedule_queue: 'schedule queue' ,
    standard_schedule_information: 'standard schedule information',
    schedule_season: 'schedule season',
}
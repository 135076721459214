import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxDataGridModule, DxTreeListModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { TreeModule } from "primeng/tree";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { StatusOperationalDropdownModule } from 'src/app/shared/ui/forms/inputs/status-operational-dropdown/status-operational-dropdown.module';
import { ActivityDomainComponent } from './activity-domain.component';
import { ActivityDomainDetailComponent } from './activity-domain-detail/activity-domain-detail.component';
import { ActivityDomainListComponent } from './activity-domain-list/activity-domain-list.component';
import { PriorityColorModule } from 'src/app/shared/ui/forms/inputs/priority-color/priority-color.module';
import { ActivityFollowupDateTimeModule } from 'src/app/shared/ui/forms/inputs/activity-datetime/activity-datetime.module';
import { ActivityDetailCommentModule } from 'src/app/modules/activity/activity-home/activity-detail/comment/comment.module';

@NgModule({
    declarations: [
        ActivityDomainComponent,
        ActivityDomainDetailComponent,
        ActivityDomainListComponent
    ],
    imports: [
        ActivityFollowupDateTimeModule,
        AlertBarModule,
        BsDropdownModule,
        ButtonsModule,
        ActivityDetailCommentModule,
        CommonModule,
        DaterangepickerModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        OopsChoiceValueModule,
        PanelsModule,
        PriorityColorModule,
        ReactiveFormsModule,
        Select2Module,
        StatusOperationalDropdownModule,
        TooltipModule,
        TreeModule,
        UiModule
    ],
    exports: [
        ActivityDomainComponent
    ]
})
export class ActivityDomainModule { }
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants'
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { field } from '../constant/attribute.constant';
import { ProductAttributeCommandModel, ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { BehaviorSubject } from 'rxjs';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';

@Injectable()
export class AttributeMapperService {

    private readonly SERVCAT_CODE: string = "SERVICECATEGORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";

    constructor(private dateConverterService: DateConverterService) {
    }

    public productAttributeFormToModels(forms, models: ProductAttributeCommandModel[] = new Array()): ProductAttributeCommandModel[] {
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    switch (ctl.value.dimensionUnitCode) {
                        case OopsChoiceValueConstant.TEXT: {
                            this.attributeTextToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.TIME: {
                            this.attributeTimeToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.NUMBER: {
                            this.attributeNumberToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.PERCENTAGE: {
                            this.attributeNumberToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.CHOICE: {
                            this.attributeChoiceToModels(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.DATETIME: {
                            this.attributeDateTimeToModel(i, ctl.value, models);
                            break;
                        }
                    }
                }
            }
        }
        return models;
    }

    private attributeTextToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeText = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeNumberToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeValue = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeTimeToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeDateTime = this.dateConverterService.convertMinDateWithTime(value.choiceValue);
        model.displaySequence = index;
        models.push(model);
    }

    private attributeDateTimeToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeDateTime = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeChoiceToModels(index: number, value, models: ProductAttributeCommandModel[]) {
        if (value.multipleChoiceFlag) {
            for (let choiceId of value.choiceValue) {
                models.push(this.attributeMultipleChoiceToModel(index, value, choiceId));
            }
        }
        else {
            this.attributeSingleChoiceToModel(index, value, models);
        }
    }

    private attributeSingleChoiceToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = value.choiceValue;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        models.push(model);
    }

    private attributeMultipleChoiceToModel(index: number, value, choiceId: string): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = this.getExistProductAttributeId(value.productAttributeChoiceId, choiceId);
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = choiceId;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        return model;
    }

    public productAttributesToForm(productAttributes: ProductAttributeViewModel[], inherit: boolean): ProductAttributeViewModel[] {
        var filter = productAttributes.filter(x => x.inheritAttribute == inherit);
        return filter;
    }

    private getExistProductAttributeId(productAttributeArrayDBId: Select2Data[], value): string {
        if (productAttributeArrayDBId) {
            var filter = productAttributeArrayDBId.filter(x => x.text == value);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
    }

    private getChoiceName(serviceCategoryChoice: Select2Data[], productAttributeChoiceId: string): string {
        if (serviceCategoryChoice) {
            var filter = serviceCategoryChoice.filter(x => x.id == productAttributeChoiceId);
            if (filter.length > 0) {
                return filter[0].text;
            }
        }
    }

    public inventoryToAttributeCommands(values: string[],
        dbIdList: Select2Data[],
        choiceData: Select2Data[],
        attributeTypeCode: string,
        commandViews: ProductAttributeCommandModel[] = null,
        domainAttribute$: BehaviorSubject<DomainAttributeModel[]> = null): ProductAttributeCommandModel[] {
        if (!commandViews) {
            commandViews = [];
        }
        if (values) {
            for (let i = 0; i <= values.length - 1; i++) {
                commandViews.push(this.inventoryToAttributeCommand(i + 1, values[i], dbIdList, choiceData, attributeTypeCode, domainAttribute$));
            }
        }
        return commandViews;
    }

    private inventoryToAttributeCommand(index: number, productAttributeChoiceId,
        dbIdList: Select2Data[],
        choiceData: Select2Data[],
        attributeTypeCode: string,
        domainAttribute$: BehaviorSubject<DomainAttributeModel[]>): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        model.productAttributeId = this.getExistProductAttributeId(dbIdList, productAttributeChoiceId);
        model.attributeTypeCode = attributeTypeCode;
        model.attributeChoiceId = productAttributeChoiceId;
        model.attributeChoiceName = this.getChoiceName(choiceData, productAttributeChoiceId);
        model.serviceCategoryCode = this.getServiceCategoryCode(attributeTypeCode, model.attributeChoiceId, domainAttribute$);
        model.displaySequence = index;
        return model;
    }

    private getServiceCategoryCode(attributeTypeCode: string, choiceId: string, domainAttribute$: BehaviorSubject<DomainAttributeModel[]>): string {
        if (domainAttribute$) {
            let filterAttributes = domainAttribute$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
            var choices: AttributeChoiceModel[];
            choices = this.getServiceCatCode(filterAttributes[0], choiceId);
            if (choices?.length) {
                return choices[0].serviceCategoryCode;
            }
        }
    }

    private getServiceCatCode(domailAttributeModel: DomainAttributeModel, choiceId: string): AttributeChoiceModel[] {
        return domailAttributeModel.attributeChoices.filter(x => x.attributeChoiceId == choiceId);
    }

    public transportAttributesToForm(productAttributes: ProductAttributeViewModel[], inherit: boolean): ProductAttributeViewModel[] {
        var filter = productAttributes.filter(
            x => x.attributeTypeCode != this.SERVCAT_CODE
                && x.attributeTypeCode != this.SALESBUCKET_CODE
                && x.startFlag != true && x.endFlag != true
                && x.inheritAttribute == inherit);
        return filter;
    }

    public assignValue(form: any, productAttribute: any, disabled: boolean, choiceOption: any) {
        switch (productAttribute.dimensionUnitCode) {
            case OopsChoiceValueConstant.TEXT: {
                this.assignValueToForm(form, productAttribute.attributeText, disabled, choiceOption);
                break;
            }
            case OopsChoiceValueConstant.TIME: {
                this.assignValueToForm(form, this.dateConverterService.convertTime24(productAttribute.attributeDateTime), disabled, choiceOption);
                break;
            }
            case OopsChoiceValueConstant.NUMBER: {
                this.assignValueToForm(form, productAttribute.attributeValue, disabled, choiceOption);
                break;
            }
            case OopsChoiceValueConstant.PERCENTAGE: {
                this.assignValueToForm(form, productAttribute.attributeValue, disabled, choiceOption);
                break;
            }
            case OopsChoiceValueConstant.DATETIME: {
                this.assignValueToForm(form, productAttribute.attributeDateTime, disabled, choiceOption);
                break;
            }
        }
    }

    public assignValueToForm(form: any, value: any, disabled: boolean, choiceOption: any) {
        form[field.CHOICE_VALUE] = [{ value: value, disabled: disabled }, [Validators.required]];
        form[field.CHOICE_OPTOIN] = [choiceOption, [Validators.nullValidator]];
    }

    public getMinimunValue(productAttributeTypeCode: string, domainAttribute$: BehaviorSubject<DomainAttributeModel[]>): number {
        if (productAttributeTypeCode && domainAttribute$.value) {
            var filter = domainAttribute$.value.filter(x => x.attributeTypeCode == productAttributeTypeCode);
            if (filter?.length) {
                return filter[0].minimumValue;
            }
        }
        return 0;
    }

    public getMaximumValue(productAttributeTypeCode: string, domainAttribute$: BehaviorSubject<DomainAttributeModel[]>): number {
        if (productAttributeTypeCode && domainAttribute$.value) {
            var filter = domainAttribute$.value.filter(x => x.attributeTypeCode == productAttributeTypeCode);
            if (filter?.length) {
                return filter[0].maximumValue;
            }
        }
    }

    public ssrToAttributeCommands(values: string[],
        dbIdList: Select2Data[],
        choiceData: Select2Data[],
        attributeTypeCode: string,
        commandViews: ProductAttributeCommandModel[] = null,
        serviceReference$: BehaviorSubject<DomainAttributeModel[]>): ProductAttributeCommandModel[] {
        if (!commandViews) {
            commandViews = [];
        }
        if (values) {
            for (let i = 0; i <= values.length - 1; i++) {
                commandViews.push(this.ssrToAttributeCommand(i + 1, values[i], dbIdList, choiceData, attributeTypeCode, serviceReference$));
            }
        }
        return commandViews;
    }

    private ssrToAttributeCommand(index: number, productAttributeChoiceId,
        dbIdList: Select2Data[],
        choiceData: Select2Data[],
        attributeTypeCode: string,
        serviceReference$: BehaviorSubject<DomainAttributeModel[]>): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        model.productAttributeId = this.getExistProductAttributeId(dbIdList, productAttributeChoiceId);
        model.attributeTypeCode = attributeTypeCode;
        model.attributeChoiceId = productAttributeChoiceId;
        model.attributeChoiceName = this.getChoiceName(choiceData, productAttributeChoiceId);
        model.serviceCode = this.getServiceCode(serviceReference$, productAttributeChoiceId);
        model.displaySequence = index;
        return model;
    }

    private getServiceCode(serviceReference$: BehaviorSubject<DomainAttributeModel[]>,
        productAttributeChoiceId: string): string {
        if (serviceReference$.value) {
            let filter = serviceReference$.value[0].attributeChoices.filter(x => x.attributeChoiceId == productAttributeChoiceId);
            if (filter?.length) {
                return filter[0].serviceCode;
            }
        }
    }

    public productAttributeFormToViewModels(forms, models: ProductAttributeViewModel[] = new Array()): ProductAttributeViewModel[] {
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    switch (ctl.value.dimensionUnitCode) {
                        case OopsChoiceValueConstant.TEXT: {
                            this.attributeTextToViewModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.TIME: {
                            this.attributeTimeToViewModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.NUMBER: {
                            this.attributeNumberToViewModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.PERCENTAGE: {
                            this.attributeNumberToViewModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.CHOICE: {
                            this.attributeChoiceToViewModels(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.DATETIME: {
                            this.attributeDateTimeToViewModel(i, ctl.value, models);
                            break;
                        }
                    }
                }
            }
        }
        return models;
    }

    private attributeTextToViewModel(index: number, value, models: ProductAttributeViewModel[]) {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeText = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeNumberToViewModel(index: number, value, models: ProductAttributeViewModel[]) {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeValue = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeTimeToViewModel(index: number, value, models: ProductAttributeViewModel[]) {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeDateTime = this.dateConverterService.convertMinDateWithTime(value.choiceValue);
        model.displaySequence = index;
        models.push(model);
    }

    private attributeDateTimeToViewModel(index: number, value, models: ProductAttributeViewModel[]) {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeDateTime = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeChoiceToViewModels(index: number, value, models: ProductAttributeViewModel[]) {
        if (value.multipleChoiceFlag) {
            for (let choiceId of value.choiceValue) {
                models.push(this.attributeMultipleChoiceToViewModel(index, value, choiceId));
            }
        }
        else {
            this.attributeSingleChoiceToViewModel(index, value, models);
        }
    }

    private attributeSingleChoiceToViewModel(index: number, value, models: ProductAttributeViewModel[]) {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = value.choiceValue;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        models.push(model);
    }

    private attributeMultipleChoiceToViewModel(index: number, value, choiceId: string): ProductAttributeViewModel {
        let model = {} as ProductAttributeViewModel;
        if (value.productAttributeId) {
            model.productAttributeId = this.getExistProductAttributeId(value.productAttributeChoiceId, choiceId);
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = choiceId;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        return model;
    }    
}
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from "lodash";
import { BehaviorSubject } from 'rxjs';
import { SalesBucketReferenceModel, ServiceCategoryReferenceModel, StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ServiceReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/service-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PricingService } from 'src/app/core/services/pricing-services';
import { ServiceReferenceService } from 'src/app/core/services/system-services/service-reference.service';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { AttributeChoiceView } from '../../shared/view/attribute-choice.view';
import { AttributeChoiceDetailComponent } from './attribute-choice-detail/attribute-choice-detail.component';
import { AttributeChoiceDetailDirective } from './attribute-choice-detail/attribute-choice-detail.directive';
import { AttributeChoiceTableComponent } from './attribute-choice-table/attribute-choice-table.component';

@Component({
    selector: 'op-attribute-choice',
    templateUrl: './attribute-choice.component.html'
})
export class AttributeChoiceComponent implements OnInit {
	public attributeChoiceView: AttributeChoiceView = {
        attributeChoiceId: null,
        attributeChoiceCode: null,
        attributeChoiceName: null,
        attributeChoiceValue: null,
        specialServiceCode: null,
        specialServiceName: null,
        salesBucketCode: null,
        salesBucketName: null,
        serviceCategoryCode: null,
        serviceCategoryName: null,
        defaultFlag: false,
        sortSequence: null,
        commitBy: null,
        commitByName: null,
        commitDateTime: null
    };
	public isCollapsedDetail: boolean = false;
    public focused: boolean;
	public showAdd: boolean = false;
	public singleRecord: boolean = true;
    public emptyRecord: boolean = true;
    public selectedItem: AttributeChoiceView;
    public deleteAttributeChoices: string[] = [];
    public newAttributeChoices: AttributeChoiceView[] = new Array();
    public editAttributeChoices: AttributeChoiceView[] = new Array();
    public serviceCategoryReference$ = new BehaviorSubject<ServiceCategoryReferenceModel[]>(null);
    public serviceReference$ = new BehaviorSubject<ServiceReferenceModel[]>(null);
    public salesBucketReference$ = new BehaviorSubject<SalesBucketReferenceModel[]>(null);
    public newChoiceDetailComponent: AttributeChoiceDetailComponent;

	readonly ADD_BINDING_DELAY_TIME = 50;
    private readonly LIST_POSITION_OFFSET = 305;
    private readonly DELAY_TIME = 100;

    @Input() attributeTypeCode: string;
	@Input() attributeChoiceList: AttributeChoiceView[] = [];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() domainAttributeFlag: boolean;
    @Input() newAttributeFlag: boolean = false;
    
	@ViewChild(AttributeChoiceDetailComponent) attributeChoiceDetailComponent: AttributeChoiceDetailComponent;
	@ViewChild(AttributeChoiceTableComponent) attributeChoiceTableComponent: AttributeChoiceTableComponent;
    @ViewChild(AttributeChoiceDetailDirective) attributeChoiceDetailDirective: AttributeChoiceDetailDirective;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @ViewChild('panelAttributeChoice', {read: ElementRef, static: false }) choicePanel: ElementRef;
    @ViewChild('panelChoiceDetail', {read: ElementRef, static: false }) choiceDetailPanel: ElementRef;

	constructor(private changeDetectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private pricingService: PricingService,
        private serviceReferenceService: ServiceReferenceService,
        private uiHelper: UiHelperService) { }

    ngOnInit(): void {
        this.getSalesBucketReference();
        this.getServiceReference();
        this.getServiceCategoryReference();
    }

	public fillModelToForm(attributeChoiceModels: AttributeChoiceView[]) {
		this.clearData();
        if (attributeChoiceModels && attributeChoiceModels.length > 0) {
            this.fillModelInCase(attributeChoiceModels);
        }
		this.attributeChoiceList = attributeChoiceModels;
        if (this.attributeChoiceList.length > 1) {
            this.singleRecord = false;
        } else {
            this.singleRecord = true;
        }
        this.checkIfEmpty();
		this.changeDetectionRef.detectChanges();
	}

    private fillModelInCase(attributeChoiceModels: AttributeChoiceView[]) {
        if (attributeChoiceModels.length == 1) {
            this.fillModelSingleRecord(attributeChoiceModels[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(attributeChoiceModel: AttributeChoiceView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.attributeChoiceView = attributeChoiceModel;
        this.changeDetectionRef.detectChanges();
    }

    itemSelected(data: AttributeChoiceView) {
        this.selectedItem = data;
    }

	private clearData() {
		this.attributeChoiceView = {
            attributeChoiceId: null,
            attributeChoiceCode: null,
            attributeChoiceName: null,
            attributeChoiceValue: null,
            specialServiceCode: null,
            specialServiceName: null,
            salesBucketCode: null,
            salesBucketName: null,
            serviceCategoryCode: null,
            serviceCategoryName: null,
            defaultFlag: false,
            sortSequence: null,
            commitBy: null,
            commitByName: null,
            commitDateTime: null
        };
	}

	add() {
		this.singleRecord = false;
		this.openDetail();
		setTimeout(() => {
            this.loadDetailForm();
			this.changeDetectionRef.detectChanges();
		}, this.ADD_BINDING_DELAY_TIME);
	}

	edit(data: AttributeChoiceView) {
		if (this.EmptyOrOneRecord()) {
            this.singleRecord = true;
            this.emptyRecord = true;
            this.isCollapsedDetail = false;
            this.formDetailClose();
        } else {
            this.openDetail();
            this.loadDetailForm(data);
        }	
	}

	delete() {
        if (this.selectedItem != null) {
            let views = cloneDeep(this.attributeChoiceList);
            let deleteIndex = views.findIndex(item => item.attributeChoiceCode == this.selectedItem.attributeChoiceCode);
            views.splice(deleteIndex, 1);
            if (this.selectedItem.attributeChoiceId) {
                this.deleteAttributeChoices.push(this.selectedItem.attributeChoiceId);
            }
            this.attributeChoiceList = views;
            this.removeFromAddOrDeleteList(this.selectedItem); 
        } else if (this.attributeChoiceList.length === 1) {
            this.deleteAttributeChoices.push(this.attributeChoiceList[0].attributeChoiceId);
            this.attributeChoiceList = [];
            this.clearData();
        }
        this.displayAfterDelete();
    }
		
    
    removeFromAddOrDeleteList(data: AttributeChoiceView) {
        this.removeFromAdd(data);
        this.removeFromEdit(data);
    }

    removeFromAdd(data: AttributeChoiceView) {
        let index = this.newAttributeChoices.findIndex(newChoice => newChoice.attributeChoiceId == data.attributeChoiceId);
        if (index < 0) {
            return;
        }
        this.newAttributeChoices.splice(index, 1);
    }

    removeFromEdit(data: AttributeChoiceView) {
        let index = this.editAttributeChoices.findIndex(editChoice => editChoice.attributeChoiceId == data.attributeChoiceId);
        if (index < 0) {
            return;
        }
        this.editAttributeChoices.splice(index, 1);
    }

    private EmptyOrOneRecord(): boolean {
        return this.attributeChoiceList.length == 0 || this.attributeChoiceList.length == 1;
    }

    private checkIfEmpty() {
        this.emptyRecord = this.attributeChoiceList.length === 0;
    }

	public openDetail(){
		if (!this.isCollapsedDetail){
			this.isCollapsedDetail = !this.isCollapsedDetail
			this.changeDetectionRef.markForCheck();
		}       
	}

	public toggleDetail() {
        if (this.attributeChoiceList.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
            if (!this.isCollapsedDetail) {
                this.formDetailClose();
            }
        }	
	}

	private formDetailClose() {
		let viewContainerRef = this.attributeChoiceDetailDirective.viewContainerRef;
		viewContainerRef.clear();
		this.attributeChoiceTableComponent.disableAdd = false;
		this.attributeChoiceTableComponent.disableEdit = false;
		this.attributeChoiceTableComponent.changeDetectionRef.detectChanges();
	}

	private loadDetailForm(dataEdit: AttributeChoiceView = null) {
		let componentFactory = this.componentFactoryResolver
			.resolveComponentFactory(AttributeChoiceDetailComponent);

		let viewContainerRef = this.attributeChoiceDetailDirective.viewContainerRef;
		viewContainerRef.clear();

		let componentRefs = viewContainerRef.createComponent(componentFactory);
		let component: AttributeChoiceDetailComponent = (<AttributeChoiceDetailComponent>componentRefs.instance);
		component.showAdd = false;
        component.serviceCategoryReference = this.serviceCategoryReference$.getValue();
        component.serviceReference = this.serviceReference$.getValue();
        component.salesBucketReference = this.salesBucketReference$.getValue();

		if (dataEdit != null) {
			component.attributeChoiceView = cloneDeep(dataEdit);
            component.newChoice = false;
            this.changeDetectionRef.detectChanges();
		}
		component.onCancel.subscribe(
			() => {
				this.displayAfterCancel();
			}
		);
		this.newChoiceDetailComponent = component;
		this.changeDetectionRef.detectChanges();
        this.focusDetailPanel();
	}

	save() {
        let data = this.attributeChoiceDetailComponent.getAttributeChoice();
		if (data != null) {
			this.saveData(data);
			this.showAdd = true;
			this.displayAfterSave();
		}
	}

    saveNewChoice() {
        let data = this.newChoiceDetailComponent.getAttributeChoice();
		if (data != null) {
			this.saveData(data);
			this.showAdd = true;
			this.displayAfterSave();
		}
    }

	private saveData(data: AttributeChoiceView) {
        var filterIndex = this.attributeChoiceList?.findIndex(x => x.attributeChoiceId == data.attributeChoiceId);

        if (data.defaultFlag === undefined) {
            data.defaultFlag = false;
        }
        this.addToNewOrEditList(filterIndex === -1, data);
        this.updateAttributeChoiceViews(filterIndex, data);
        
	}

    private addToNewOrEditList(newChoice: boolean, data: AttributeChoiceView) {
        var index = this.newAttributeChoices.findIndex(item => item.attributeChoiceId == data.attributeChoiceId);
        if (newChoice || index >= 0) {
			this.addToNewAttributeChoices(index, data);
		}
		else {
			this.addToEditAttributeChoices(data);
		}
    }

    private addToNewAttributeChoices(index: number, data: AttributeChoiceView) {
        if (index == -1) {
            this.newAttributeChoices.push(data);
        } else {
            this.newAttributeChoices[index] = data;
        }
    }

    private addToEditAttributeChoices(data: AttributeChoiceView) {
        var index = this.editAttributeChoices.findIndex(item => item.attributeChoiceId == data.attributeChoiceId);
        if (index == -1) {
            this.editAttributeChoices.push(data);
        } else {
            this.editAttributeChoices[index] = data;
        }
    }

	private updateAttributeChoiceViews(filterIndex: number, data: AttributeChoiceView) {
		if (filterIndex == -1) {
            this.attributeChoiceList.push(data);
        } else {
            this.attributeChoiceList[filterIndex] = data;
        }
	}

	private displayAfterSave() {
        this.singleRecord = this.EmptyOrOneRecord();
        this.checkIfEmpty();
		if (this.attributeChoiceList?.length > 1) {           
            this.formDetailClose();
            this.toggleDetail();
        }
        this.focusChoicePanel();
        this.changeDetectionRef.detectChanges();
	}

	private displayAfterCancel() {
		this.toggleDetail();
		if (this.attributeChoiceList.length === 1) {
            this.singleRecord = true;
        }
        this.focusChoicePanel();
	}

    private displayAfterDelete() {
        this.singleRecord = this.EmptyOrOneRecord();
        this.selectedItem = null;
        this.checkIfEmpty();
		if (this.attributeChoiceList?.length < 1) {
            this.isCollapsedDetail = false;
        }
        if (this.attributeChoiceList?.length === 1) {
            this.attributeChoiceView = this.attributeChoiceList[0];
        }
        this.focusChoicePanel()
        this.changeDetectionRef.detectChanges();
	}

    clearPendingData() {
        this.newAttributeChoices = [];
        this.editAttributeChoices = [];
        this.deleteAttributeChoices = [];
    }

    copy() {
        if (this.selectedItem != null) {
            let newData = cloneDeep(this.selectedItem);
            newData.attributeChoiceId = null;
            newData.attributeChoiceName = newData.attributeChoiceName + ' - copy';
            this.openDetail();
            this.loadDetailForm(newData);
            this.attributeChoiceDetailComponent.newChoice = true;
            this.attributeChoiceDetailComponent.checkCodeDuplicate();
        }
    }

    cancel() {
        if (this.attributeChoiceList.length === 0) {
            this.clearData();
            this.emptyRecord = true;
        }
        this.displayAfterCancel();
    }

    private getServiceCategoryReference() {
		this.pricingService.getServiceCategoryReference()
		  .subscribe(response => {
			this.serviceCategoryReference$.next(response);
		})
	}

	private getServiceReference() {
    this.serviceReferenceService.getServiceReference()
      .subscribe(response => {
      this.serviceReference$.next(response);
		})
	}

	private getSalesBucketReference() {
    this.pricingService.getSalesBucketReference()
      .subscribe(response => {
      this.salesBucketReference$.next(response);
		})
	}

    getServiceCategoryName(serviceCategoryCode: string): string {
        return this.serviceCategoryReference$.value.filter(item => item.serviceCategoryCode == serviceCategoryCode)[0]?.serviceCategoryName;
    }

    getServiceName(serviceCode: string): string {
        return this.serviceReference$.value.filter(item => item.serviceCode == serviceCode)[0]?.serviceName;
    }

    getSalesBucketName(salesBucketCode: string): string {
        return this.salesBucketReference$.value.filter(item => item.salesBucketCode == salesBucketCode)[0]?.salesBucketName;
    }

     public clearForNewAttribute() {
        this.attributeChoiceList = [];
        this.clearData();
        this.displayAfterDelete();
    }

    public refresh() {
        this.attributeChoiceTableComponent.refreshTable();
    }

    get allowChoiceEdit() {
        return ((this.newAttributeFlag == true && this.userSecurity?.newFlag) || this.userSecurity?.editFlag) && !this.domainAttributeFlag
    }

    get emptyChoice() {
        return this.attributeChoiceList?.length < 1;
    }

    get singleChoice() {
        return this.attributeChoiceList?.length == 1
    }

    get allowCancel() {
        return this.domainAttributeFlag || this.singleChoice;
    }

    public moveToPanel(panel: ElementRef, offset: number) {
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(panel, offset);
        }, this.DELAY_TIME);
    }

    focusChoicePanel() {
        if (this.attributeChoiceList?.length > 1) {
            this.moveToPanel(this.choicePanel, this.LIST_POSITION_OFFSET);
        }   
        this.choicePanel.nativeElement.classList.add('active-panel');
    }
    
    focusDetailPanel() {  
        setTimeout(() => {
            this.choiceDetailPanel.nativeElement.click();
            this.choicePanel.nativeElement.classList.remove('active-panel');
            this.moveToPanel(this.choiceDetailPanel, this.LIST_POSITION_OFFSET);
        })

    }

}


import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ScheduleQueueSSIMSearchModel } from "src/app/core/models/product-model/standard-schedule-information-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ScheduleQueueResultCode } from "src/app/modules/schedule/shared/schedule.contant";

@Component({
    selector: 'op-schedule-queue-table',
    templateUrl: './schedule-queue-table.component.html',
    providers: [],
})
export class ScheduleQueueTableComponent implements OnInit {
    readonly DATE_WITH_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

    public scheduleQueueData: any;
    public header: string[];
    public rows = 0;

    @Input() scheduleQueues: ScheduleQueueSSIMSearchModel[];

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {}

    ngOnInit(): void {
        this.bindData(this.scheduleQueues);
    }

    private bindData(queues: ScheduleQueueSSIMSearchModel[]) {
        if (!queues?.length) {
            return;
        }

        let dynamicDataArray = new Array();
        queues.forEach(item => {
            let scheduleTemps = {};
            scheduleTemps["scheduleQueue"] = item.scheduleQueue;
            scheduleTemps["scheduleQueueId"] = item.scheduleQueueId;
            scheduleTemps["autoProcess"] = item.autoProcessFlag ?? false;
            scheduleTemps["scheduledStartDateTime"] = this.dateConverterService.convertDateString(item.scheduledStartDateTime, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["processStartDateTime"] = this.dateConverterService.convertDateString(item.processStartDateTime, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["processEndDateTime"] = this.dateConverterService.convertDateString(item.processEndDateTime, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["startDateTime"] = this.dateConverterService.convertDateString(item.periodStartDate, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["endDateTime"] = this.dateConverterService.convertDateString(item.periodEndDate, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
            scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
            dynamicDataArray.push(scheduleTemps);
        });

        this.scheduleQueueData = dynamicDataArray;
        this.rows = this.scheduleQueueData.length;
        this.changeDetectorRef.detectChanges();
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'result') {
            this.setResultCellColor(e)
        }
    }

    private setResultCellColor(e) {
        switch (e.data.resultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;    
        }
    }
}
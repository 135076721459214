import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { SecurityCodeSearchTableComponent } from './security-code-search-table/security-code-search-table.component';
import { takeUntil } from "rxjs/operators";
import { SecurityCodeSearchConditionComponent } from './security-code-search-condition/security-code-search-condition.component';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-code-search',
    templateUrl: './security-code-search.component.html'
})
export class SecurityCodeSearchComponent implements OnInit, AfterViewInit {
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    private unsubscribe$ = new Subject();
    @Input() executeFlag: boolean = true;
    @Input() securities: SecurityGroupSecurityModel[];
    @Input() moduleSecurity: SecurityGroupSecurityModel;

    @ViewChild(SecurityCodeSearchConditionComponent) conditionComponent: SecurityCodeSearchConditionComponent;
    @ViewChild("securityCodeSearchTable") searchTable: SecurityCodeSearchTableComponent;
    constructor(private statusReferenceService: StatusReferenceService,
        private actionbarService: ActionbarService) { }


    ngAfterViewInit(): void {
        let actionBarHandler = this.actionbarService.getCurrentState();
        actionBarHandler?.get(ACTION_STATUS.copy)?.disable(!this.moduleSecurity?.copyFlag);
        this.actionbarService.updateState(actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    ngOnInit(): void {
        this.getStatusReferences();
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    searchClick(e) {
        this.searchTable.searchSecurityCode(e);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.copy:
                this.searchTable.copySecuityCode();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
        }
    }

    refresh() {
        this.searchFromResultTable();
    }

    searchFromResultTable() {
        this.searchTable.searchSecurityCode(this.conditionComponent.condition);
    }

}

export interface ProductChangePriceDetailView {
    date: string;
    description: string;
    currency: string;
    salesAmount: number;
    debit: number;
    credit: number;
    balance: number;
    hierarchyId: string;
    parentHierarchyId: string;
    subDescription?: string;
    icon?: string;
    iconClass?: string;
    type?: PriceDetailType;
}

export enum PriceDetailType {
    Balance,
    Bundle,
    ChangeFee,
    Individual,
    Payment,
    Price,
    Product
}
import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TimezoneReferenceModel } from "../../models/reference-model/reference-general-model";
import { TimezoneReferenceDaylightSavingRequest, TimezoneReferenceDetail, TimezoneReferenceSearchCriteria, TimezoneReferenceSearchResult } from "../../models/timezone-reference-model";

@Injectable({
    providedIn: 'root'
})
export class TimezoneReferenceService extends ApiServiceBase {

    private readonly Path = "/system/TimezoneReferences"

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) {
        super(httpClient, authService);
    }

    public getAll(): Observable<TimezoneReferenceModel[]> {
        return this.httpGet<TimezoneReferenceModel[]>(`${this.Path}`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getTimezoneReferenceSearch(criteria: TimezoneReferenceSearchCriteria): Observable<TimezoneReferenceSearchResult[]> {
        const queryParams = new HttpParams({ fromObject: <any>criteria })
        return this.httpGet<TimezoneReferenceSearchResult[]>(`${this.Path}/search`, queryParams)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatusCode(timezoneCode: string, statusCode: string): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${timezoneCode}/statusCode`, { statusCode })
            .pipe(
                map(res => res.body)
            )
    }

    public getTimezoneDetail(timezoneCode: string): Observable<TimezoneReferenceDetail> {
        return this.httpGet<TimezoneReferenceDetail>(`${this.Path}/${timezoneCode}/detail`, null)
            .pipe(
                map(res => res.body)
            )
    }

    public createTimezoneDaylightsavings(timezoneCode: string,
        timezoneName: string,
        differenceToUtcMinutes: string,
        statusCode: string,
        daylightSavings: TimezoneReferenceDaylightSavingRequest[]): Observable<{ id: string }> {
        return this.httpPost<never>(`${this.Path}`,
            {
                timezoneCode,
                timezoneName,
                differenceToUtcMinutes,
                statusCode,
                daylightSavings
            })
            .pipe(
                map(res => res.body)
            )
    }

    public updateTimezoneDaylightsavings(timezoneCode: string,
        timezoneName: string,
        differenceToUtcMinutes: string,
        statusCode: string,
        daylightSavings: TimezoneReferenceDaylightSavingRequest[],
        daylightSavingIdsForRemoving: string[]): Observable<never> {
        return this.httpPut<never>(`${this.Path}/${timezoneCode}/timezonedaylightsaving`,
            {
                timezoneName,
                differenceToUtcMinutes,
                statusCode,
                daylightSavings,
                daylightSavingIdsForRemoving
            })
            .pipe(
                map(res => res.body)
            )
    }

    public getTimezoneByCode(timezoneCode: string): Observable<TimezoneReferenceModel> {
        return this.httpGet<TimezoneReferenceModel>(`${this.Path}/timezoneCode/${timezoneCode}`, null)
            .pipe(
                map(res => res.body)
            )
    }
}

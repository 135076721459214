import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, RefreshButtonModel, SaveButtonModel, CancelButtonModel, DeleteButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityLevel2TableModel } from '../shared/security-level2-table/security-level2-teble.model';
import { SecurityCodeItems } from './security-code-items';
import { takeUntil } from "rxjs/operators";
import { ActionService } from 'src/app/core/utils/action.service';
import { SecurityCodeService } from 'src/app/core/services/system-services/security-code.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-code',
    templateUrl: './security-code.component.html',
    providers: [ActionbarService],
})
export class SecurityCodeComponent implements AfterViewInit {
    private readonly FUCNTION_TABID = 'FUNCTION';
    private readonly SEARCH_TABID = 'SEARCH';

    public securityCodeItems: SecurityLevel2TableModel[] = SecurityCodeItems;
    public selectedTab = this.FUCNTION_TABID;
    private unsubscribe$ = new Subject();
    public executeFlag = true;
    public selectedItem: SecurityLevel2TableModel;
    public paggingView: PagingDataView[] = [];

    actionBarHandler = new ActionBarHandlerModel(new NewButtonModel(), new RefreshButtonModel());
    public securityCodes$ = new BehaviorSubject<SecurityLevel2TableModel[]>(null);

    private navigateParams: any;
    public moduleSecurity: SecurityGroupSecurityModel;
    public securities: SecurityGroupSecurityModel[];

    constructor(private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private securityCodeService: SecurityCodeService,
        private loadingSpinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.navigateParams = this.navigationService.getParams();
        this.executeFlag = this.navigateParams?.executeFlag ?? true;
        this.moduleSecurity = this.navigateParams?.moduleSecurity;
        this.securities = this.navigateParams?.securities;
        this.selectTab(this.navigateParams?.selectedTab ?? this.FUCNTION_TABID);
        this.getAllActiveSecurityCode();
    }

    ngAfterViewInit(): void {
        this.setupActionBar()
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    selectTab(tabName: string) {
        this.selectedTab = tabName;
        this.selectedTabChange(tabName);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.cancel:
                this.cancel();
                break;
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
            default:
                break;
        }
    }

    back() {
        let param = {
            selectedTab: this.FUCNTION_TABID,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities
        }
        this.navigationService.navigate('main/settings/security', 'Security Management', false, param);
    }

    cancel() {
        this.navigationService.navigate('main/settings/home', 'Setting Management');
    }

    new() {
        let param = {
            newFlag: true,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities
        }
        this.navigationService.navigate('/main/settings/security-code/details', null, false, param);
    }

    selectedTabChange(activeId: string) {
        this.addAction(activeId);
        this.setupActionBar();
    }

    addAction(activeId: string) {
        this.actionService.add(activeId, null, null);
    }

    getAllActiveSecurityCode() {
        this.loadingSpinnerService.show();
        this.securityCodeService.getAllSecurityCode()
            .subscribe(
                (response: SecurityCodeReferenceModel[]) => {
                    let index = 1;
                    let codes: SecurityLevel2TableModel[] = new Array();
                    response.forEach(item => {
                        this.addPaggingView(index, item.securityCode);
                        let code: SecurityLevel2TableModel = {
                            id: item.securityCode,
                            name: item.securityName,
                            modifiedBy: item.commitByName,
                            modifiedDateTime: item.commitDateTime
                        }
                        index += 1;
                        codes.push(code);
                    }) 
                    this.securityCodes$.next(codes);
                    this.loadingSpinnerService.hide();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    setupActionBar() {
        switch (this.selectedTab) {
            case this.SEARCH_TABID:
                this.setupSearchActionBar();
                break;
            default:
                this.setupDefaultActionBar();
                break;
        }
        this.changeDetectorRef.detectChanges();    
    }

    setupDefaultActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).disable(!this.moduleSecurity?.newFlag);
        this.actionbarService.updateState(this.actionBarHandler);
    }

    setupSearchActionBar() {
        let searchActionBarHandler = new ActionBarHandlerModel(
            new NewButtonModel(),
            new CopyButtonModel(),
            new SaveButtonModel(),
            new CancelButtonModel(),
            new DeleteButtonModel(),
            new RefreshButtonModel(),
        )
        searchActionBarHandler.get(ACTION_STATUS.new).disable(!this.moduleSecurity?.newFlag);
        searchActionBarHandler.get(ACTION_STATUS.copy).disable(!this.moduleSecurity?.copyFlag);
        searchActionBarHandler.get(ACTION_STATUS.save).disable();
        searchActionBarHandler.get(ACTION_STATUS.delete).disable();
        this.actionbarService.updateState(searchActionBarHandler);
    }

    codeItemClick(item: SecurityLevel2TableModel) {
        this.selectedItem = item;
    }

    refresh() {
        this.getAllActiveSecurityCode();
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ExchangeRateGroupComponent } from '../exchange-rate-group/exchange-rate-group.component';

@Component({
    selector: 'op-exchange-rate-tabs',
    templateUrl: './exchange-rate-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExchangeRateTabsComponent {
    readonly SETTING_ROUTE_PATH = '/main/settings/';

    public selectedTab: string;
    public selectedItem: OopsCategory;
    public executeFlag = true;
    @Input() refresh: boolean = false;
    @Output() selectedTabChange = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<OopsCategory>();
    @Output() refreshed = new EventEmitter();
    @ViewChild(ExchangeRateGroupComponent) exchangeRateGroupComponent: ExchangeRateGroupComponent;
    constructor(private navigateService: NavigationService) { }

    ngOnInit(): void {
        let params = this.navigateService.getParams();
        let tab = params?.selectedTab ?? null;
        let module = params?.module ?? null;
        this.executeFlag = params?.executeFlag ?? true;
        if (tab && module) {
            this.selectedTab = tab;
            this.selectedItem = this.createSelectedItem(module);
            this.rowSelected.emit(this.selectedItem);
            this.selectedTabChange.emit(this.selectedTab);
        }
    }

    onTabChange(activeId: string) {
        this.selectedTabChange.emit(activeId);
    }

    onMenuSelected(activeId: string) {
        this.selectedTab = activeId.toUpperCase();
        this.selectedTabChange.emit(this.selectedTab);
    }

    onRowSelected(item: OopsCategory) {
        this.selectedItem = item;
        this.rowSelected.emit(item)
    }

    createSelectedItem(name: string): OopsCategory {
        var item = {} as OopsCategory
        item.name = name;
        item.url = this.SETTING_ROUTE_PATH + name.toLowerCase();
        return item;
    }

    onRefreshed() {
        this.refreshed.emit();
    }

}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { ProductNumberTypeReferenceModel } from '../../models/reference-model/reference-product-model';

@Injectable({
  providedIn: 'root'
})
export class ProductNumberTypeReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/system/ProductNumberTypeReferences"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getAll(): Observable<ProductNumberTypeReferenceModel[]> {
    return this.httpGet<any>(this.REFERENCE_PATH, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getByProductType(productTypeCode: string): Observable<ProductNumberTypeReferenceModel[]> {
    return this.httpGet<ProductNumberTypeReferenceModel[]>(this.REFERENCE_PATH + "/ProductTypes/" + productTypeCode, null)
      .pipe(
        map(res => res.body)
      )
  }
}
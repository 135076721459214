import { Injectable } from '@angular/core';
import { VehicleCompositionConfigurationCommandModel, VehicleCompositionConfigurationModel } from 'src/app/core/models/vehicle-model/vehicle-composition/vehicle-composition-configuration';
import { ConfigurationView } from './configuration.view';

@Injectable()
export class ConfigurationMapperService {

    public toConfigurationViews(models: VehicleCompositionConfigurationModel[]): ConfigurationView[] {
        let views: ConfigurationView[] = new Array();
        let i = 0;
        if (models) {
            for (let model of models) {
                ++i;
                views.push(this.toConfigurationView(i, model));
            }
        }
        return views;
    }

    private toConfigurationView(i: number, model: VehicleCompositionConfigurationModel): ConfigurationView {
        let view = {} as ConfigurationView;
        view.no = i;
        view.vehicleCompositionConfigurationId = model.vehicleCompositionConfigurationId;
        view.vehicleConfigurationId = model.vehicleConfigurationId;
        view.vehicleConfigurationCode = model.vehicleConfigurationCode;
        view.vehicleConfigurationName = model.vehicleConfigurationName;
        view.sequence = model.vehicleConfigurationSequence;
        view.statusCode = model.statusCode;
        return view;
    }

    public toConfigurationModels(configurationViews: ConfigurationView[]): VehicleCompositionConfigurationCommandModel[] {
        let commands: VehicleCompositionConfigurationCommandModel[] = new Array();
        if (configurationViews) {
            for (let view of configurationViews) {
                commands.push(this.toConfigurationModel(view));
            }
        }
        return commands;
    }


    private toConfigurationModel(seatPropertyViews: ConfigurationView): VehicleCompositionConfigurationCommandModel {
        let command = {} as VehicleCompositionConfigurationCommandModel;
        command.vehicleCompositionConfigurationId = seatPropertyViews.vehicleCompositionConfigurationId;
        command.vehicleConfigurationId = seatPropertyViews.vehicleConfigurationId;
        command.vehicleConfigurationSequence = seatPropertyViews.sequence;
        command.statusCode = seatPropertyViews.statusCode;
        return command;
    }
}
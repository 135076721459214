import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { CurrencyReferenceModel } from '../../models/reference-model/reference-general-model';


@Injectable({
    providedIn: 'root'
})
export class CurrencyReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/CurrencyReferences"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getCurrencyReferences(): Observable<CurrencyReferenceModel[]> {
        return this.httpGet<CurrencyReferenceModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getCurrencyReferencesLogonOrganisation(): Observable<CurrencyReferenceModel[]> {
        return this.httpGet<CurrencyReferenceModel[]>(this.PATH + "/logon-organisation", null)
            .pipe(
                map(res => res.body)
            )
    }

    public getCurrencyReferencesOrganisationOwner(): Observable<CurrencyReferenceModel[]> {
        return this.httpGet<CurrencyReferenceModel[]>(this.PATH + "/organisation-owner", null)
            .pipe(
                map(res => res.body)
            )
    }    
}
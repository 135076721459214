import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class ProductTypeSetRequireFieldService {

    public clearRequireProductType(ctl) {
        ctl.get('operatorProductType').clearValidators();
        ctl.get('operatorProductType').updateValueAndValidity();

        ctl.get('productCat').clearValidators();
        ctl.get('productCat').updateValueAndValidity();

        ctl.get('productGroup').clearValidators();
        ctl.get('productGroup').updateValueAndValidity();

        ctl.get('productGroupType').clearValidators();
        ctl.get('productGroupType').updateValueAndValidity();
    }

    public setRequireProductType(ctl) {
        ctl.get('operatorProductType').setValidators(Validators.required);
        ctl.get('operatorProductType').updateValueAndValidity();

        ctl.get('productCat').setValidators(Validators.required);
        ctl.get('productCat').updateValueAndValidity();

        ctl.get('productGroup').setValidators(Validators.required);
        ctl.get('productGroup').updateValueAndValidity();
    }

}
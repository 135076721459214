import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class OrganisationValidationService {

    public findDuplicateOrganisationType(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "organisation" && ctl.value.orgType == "type") {
                    if (ctl.value.orgType == ctlCheck.value.orgType &&
                        ctl.value.orgTypeOperators == ctlCheck.value.orgTypeOperators &&
                        this.checkArrayEqual(ctl.value.orgTypeValueType, ctlCheck.value.orgTypeValueType)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateOrganisationGroup(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "organisation" && ctl.value.orgType == "group") {
                    if (ctl.value.orgType == ctlCheck.value.orgType &&
                        ctl.value.orgTypeOperators == ctlCheck.value.orgTypeOperators &&
                        this.checkArrayEqual(ctl.value.orgTypeValueGroup, ctlCheck.value.orgTypeValueGroup)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateOrganisationRole(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "organisation" && ctl.value.orgType == "role") {
                    if (ctl.value.orgType == ctlCheck.value.orgType &&
                        ctl.value.orgTypeOperators == ctlCheck.value.orgTypeOperators &&
                        this.checkArrayEqual(ctl.value.orgTypeValueRole, ctlCheck.value.orgTypeValueRole)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    public findDuplicateOrganisationSpecific(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "organisation" && ctl.value.orgType == "specific") {
                    if (ctl.value.orgType == ctlCheck.value.orgType &&
                        ctl.value.orgTypeOperators == ctlCheck.value.orgTypeOperators &&
                        this.checkArrayEqual(ctl.value.orgTypeValueSpecific, ctlCheck.value.orgTypeValueSpecific)
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

}

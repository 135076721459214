import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { ProductGroupCategoryModel } from "src/app/core/models/reference-model/reference-product-model";

@Injectable({
  providedIn: 'root'
})
export class ProductGroupCategoryService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/airline"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public searchProductGroupCategory(): Observable<ProductGroupCategoryModel[]> {
    return this.httpGet<any>(this.PRODUCT_PATH + "/productGroupCategories/", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
import { Injectable } from '@angular/core';
import { ExchangeRateAmountModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ExchangeRateAmountHistoryView } from './exchange-rate-amount-history.view';

@Injectable()
export class ExchangeRateAmountHistoryMapperService {

    public toViews(models: ExchangeRateAmountModel[]): ExchangeRateAmountHistoryView[] {
        let views: ExchangeRateAmountHistoryView[] = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.toView(i, model));
        }
        return views;
    }

    private toView(i: number, model: ExchangeRateAmountModel): ExchangeRateAmountHistoryView {
        let view = {} as ExchangeRateAmountHistoryView;
        view.no = i;
        view.exchangeRateId = model.exchangeRateId;
        view.baseCurrencyCode = model.baseCurrencyCode;
        view.exchangeCurrencyCode = model.exchangeCurrencyCode;
        view.factor = model.factor;
        view.sell = model.sellExchangeRateAmount;
        view.buy = model.buyExchangeRateAmount;
        view.validFrom = model.validFromDateTime;
        view.commitByName = model.commitByName;
        view.commitDateTime = model.commitDateTime;
        return view;
    }

}
export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true,
    templateResult: null,
    templateSelection: null
}

export const select2Choice = {
    placeholder: "<Dimension>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2ChoiceMultipleLocked = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    tags: true,
    templateSelection: null
}

export const select2ChoiceMultiple = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateSelection: null,
}

export const select2ChoiceMultipleInherit = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AttributeSearch = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}
import { OrganisationGroupOrganisationModel, OrganisationOrganisationTypeModel, OrganisationRelationModel } from ".";

export class OrganisationAddCommandModel {
    public locationId: string;
    public languageTranslationSetId: string;
    public organisationCallName: string;
    public organisationLegalName: string;
    public iataNumber: string;
    public teletypeAddress: string;
    public websiteAddress: string;
    public taxId: string;
    public passwordExpiryDays: number;
    public passwordReuseCycle: number;
    public statusCode: string;
    public organisationCode: string;
    public providerIataCode: string;
    public providerIcaoCode: string;
    public providerAccountNumber: string;
    public organisationOrganisationTypes: OrganisationOrganisationTypeModel[];
    public organisationGroupOrganisations: OrganisationGroupOrganisationModel[];
    public organisationRelations: OrganisationRelationModel[];
} 
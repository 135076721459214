import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";

export const PRODUCT_DASHBOARD_SUMMARY_DEMO: ProductDashboardSummaryModel[] = [
    {
        productName: "Fees",
        todayProductCount: 90,
        todaySalesAmount: 150000.67,
        yesterdayProductCount: 87,
        yesterdaySalesAmount: 142000.74,
        last7dProductCount: 387,
        last7dSalesAmount: 895014.45
    },
    {
        productName: "Merchandize",
        todayProductCount: 78,
        todaySalesAmount: 45660.07,
        yesterdayProductCount: 54,
        yesterdaySalesAmount: 89000.56,
        last7dProductCount: 416,
        last7dSalesAmount: 245663.21
    },
    {
        productName: "Services",
        todayProductCount: 56,
        todaySalesAmount: 448762.56,
        yesterdayProductCount: 115,
        yesterdaySalesAmount: 760000.21,
        last7dProductCount: 598,
        last7dSalesAmount: 11364789.64
    },
    {
        productName: "Transport",
        todayProductCount: 145,
        todaySalesAmount: 19632214.65,
        yesterdayProductCount: 87,
        yesterdaySalesAmount: 3244961.00,
        last7dProductCount: 765,
        last7dSalesAmount: 31124654.26
    }
]
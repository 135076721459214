import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { PaymentSearch, PaymentSearchLedger } from "../payment-search.model";
import { PaymentCreditAccountService, PaymentSearchService } from "..";
import { Observable, Subscription } from "rxjs";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { AuthService } from "src/app/core/authentication/auth.service";
import { filter, tap } from "rxjs/operators";

const select2Options = {
    disabled: true
}

@Component({
    selector: 'op-payment-credit-account-pay',
    templateUrl: './payment-credit-account-pay.component.html'
})
export class PaymentCreditAccountPayComponent implements OnInit, OnDestroy {

    paymentSearch: PaymentSearch
    ledger?: PaymentSearchLedger

    userOrgansationId: string
    organisations$: Observable<OrganisationModel[]>

    creditAccountBalance?: number
    newBalanceAmount?: number

    focusing = false
    select2Options = select2Options

    subscriptions: Subscription[]

    constructor(
        private paymentSearchService: PaymentSearchService,
        private paymentCreditAccountService: PaymentCreditAccountService,
        private stringHelperService: StringHelperService,
        private navigateService: NavigationService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscriptions = []
        this.userOrgansationId = this.authService.getSelectedOrganisation()
        this.organisations$ = this.paymentSearchService.organisationUser$
        this.subscriptions.push(
            this.paymentCreditAccountService.paymentSearch$.pipe(
                filter(f => !!f),
                tap(response => this.setPaymentSearch(response))
            )
                .subscribe()
        )
    }

    ngOnDestroy(): void {
        this.paymentCreditAccountService.clearPaymentSearch()
        this.subscriptions.forEach(s => s.unsubscribe())
        this.creditAccountBalance = null
        this.newBalanceAmount = null
    }

    submit() {
        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentSearchService.updatePaymentStatusCode(this.paymentSearch.paymentId, 'REVERSE').subscribe(
                () => {
                    this.paymentSearchService.alertSuccess('Payment is saved')
                    setTimeout(() => {
                        this.paymentSearchService.setLoading(false)
                        this.navigateService.navigate('main/cashbook/payment', null, false)
                    }, 2000)
                },
                () => {
                    this.paymentSearchService.setLoading(false)
                    this.paymentSearchService.alertError('Payment could not be saved')
                }
            )
        )
    }

    validateNullAmountDecimal(amount) {
        return this.stringHelperService.validateNullAmountDecimal(amount)
    }

    private setPaymentSearch(paymentSearch: PaymentSearch) {
        this.paymentSearch = paymentSearch
        this.ledger = this.paymentSearch?.paymentSearchLedgers
            .slice(0)
            .find(l => l != null)


        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentCreditAccountService.getCreditAccountBalance(this.paymentSearch.organisationId).subscribe(
                response => {
                    this.creditAccountBalance = response
                    this.newBalanceAmount = (this.ledger.transactionAmount ?? 0) + this.creditAccountBalance
                    this.paymentSearchService.setLoading(false)
                    this.cdr.detectChanges()
                },
                () => {
                    this.paymentSearchService.alertError('Credit account balance could not be loaded')
                    this.paymentSearchService.setLoading(false)
                },
            )
        )
    }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';

import { PaymentModel, PaymentStatusModel } from '../../models/payment-models';
import { NdcPayment } from '../../models/ndc/ndc-payment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends ApiServiceBase {

    private readonly PAYMENT_PATH = "/payment"

    constructor(
        httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public processPayment(request: PaymentModel): Observable<PaymentStatusModel> {

        return this.httpPost<PaymentStatusModel>(this.PAYMENT_PATH + '/transactions', request)
        .pipe(
            map(res => res.body)
        )
    }

    public updatePaymentStatus(paymentId: string, paymentStatusCode: string) {
        return this.httpPut(this.PAYMENT_PATH + '/payments/status?paymentId=' + paymentId + '&paymentStatusCode=' + paymentStatusCode, null);
    }

    public refundOrderNdc(request: NdcPayment): Observable<NdcPayment> {
        return this.httpPost<NdcPayment>(this.PAYMENT_PATH + '/PaymentInitiateISE/refund', request)
            .pipe(
                map(res => res.body)
            )
    }

    public processRefund(request: PaymentModel): Observable<PaymentStatusModel> {
        return this.httpPost<PaymentStatusModel>(this.PAYMENT_PATH + '/transactions/refund', request)
            .pipe(
                map(res => res.body)
            )
    }
}
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { ProductListSSIMSearchModel } from "src/app/core/models/product-model/standard-schedule-information-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Component({
    selector: 'op-product-list-table',
    templateUrl: './product-list-table.component.html',
})
export class ProductListTableComponent {
    public productListData: any;
    public header: string[];
    public rows = 0;

    @Input() productLists: ProductListSSIMSearchModel[];

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {}

    ngOnChanges(): void {
        this.bindData(this.productLists);
    }

    private bindData(lists: ProductListSSIMSearchModel[]) {
        if (!lists?.length) {
            return;
        }

        let dynamicDataArray = new Array();
        let no = 1;
        lists.forEach(item => {
            let scheduleTemps = {};
            scheduleTemps["no"] = no;
            scheduleTemps["productNumber"] = item.providerIataCode + ' ' + item.productNumber;
            scheduleTemps["originLocationCode"] = item.originLocationCode;
            scheduleTemps["destinationLocationCode"] = item.destinationLocationCode;
            scheduleTemps["schedulePeriodFrom"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.startDateTime)?.toUpperCase();
            scheduleTemps["schedulePeriodTo"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.endDateTime)?.toUpperCase();
            scheduleTemps["weekdays"] = item.weekdays;
            scheduleTemps["departureTime"] =  this.dateConverterService.convertTime24(item.productScheduledDepartureTime);
            scheduleTemps["arrivalTime"] = this.dateConverterService.convertTime24(item.productScheduledArrivalTime);
            scheduleTemps["vehicleType"] = item.vehicleTypeCode;
            scheduleTemps["vehicleComp"] = item.vehicleCompositionCode;    
            dynamicDataArray.push(scheduleTemps);
            no++;
        });

        this.productListData = dynamicDataArray;
        this.rows = this.productListData.length;
        this.changeDetectorRef.detectChanges();
    }
}
import { Injectable } from '@angular/core';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Injectable()
export class SecurityGroupSecurityConverter {
    public SecurityCodeModelToSecurityGroupSecurity(securityCode: SecurityCodeReferenceModel, id: string,
            securityGroupId: string): SecurityGroupSecurityModel {
        let model = new SecurityGroupSecurityModel();
        model.securityGroupSecurityId = id;
        model.securityGroupId = securityGroupId;
        model.securityCode = securityCode.securityCode;
        model.securityName = securityCode.securityName;
        model.limitationName = securityCode.limitationName
        return model;
    }
}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ILogonHistoriesView } from "../../models/user-model/user-logon-history.model";
@Injectable({
    providedIn: 'root'
})
export class UserAccountLogonHistoryService extends ApiServiceBase {
    private readonly ApiPathUrl = '/system/UserAccountLogonHistories';
    
    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public AddOrganisation(): Observable<string> {
        return this.httpPost<string>(`${this.ApiPathUrl}/Organisation`, null)
            .pipe(map(response => response.body));
    }

    public UpdateOrganisation(): Observable<string> {
        return this.httpPut<string>(`${this.ApiPathUrl}/Organisation`, null)
            .pipe(map(response => response.body));
    }

    public addUserAccountLogoffHistory(): Observable<string> {
        return this.httpPost<string>(`${this.ApiPathUrl}/Logoff`, null)
            .pipe(map(response => response.body));
    }

    public getUserLogonsHistories(): Observable<ILogonHistoriesView[]> {
        return this.httpGet<ILogonHistoriesView[]>(this.ApiPathUrl + "/UserAccount", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getUserLogonHistoriesByUserAccountId(userAccountId: string): Observable<ILogonHistoriesView[]> {
        return this.httpGet<ILogonHistoriesView[]>(this.ApiPathUrl + "/UserAccount/" + userAccountId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}

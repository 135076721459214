import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from './router.actions';

import { map, tap } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
   navigate$ =  createEffect(
      () => this.actions$.pipe(
      ofType(RouterActions.GO),
      map((action: RouterActions.Go) => action.payload),
      tap(({ path, query: queryParams, extras }) => {
        this.router.navigate(path, { queryParams, ...extras });
      })
    ),
    { dispatch: false }
  );

  location$ =  createEffect(
    () => this.actions$.pipe(
    ofType(RouterActions.UPDATE),
    map((action: RouterActions.UpdateLocation) => action.payload),
    tap(path => {
      this.location.go(path);
    })
  ),
  { dispatch: false }
 );

  navigateBack$ =  createEffect(
    () => this.actions$.pipe(
    ofType(RouterActions.BACK),
    tap(() => this.location.back())
  ),
  { dispatch: false }
  );

  navigateForward$ =  createEffect(
    () => this.actions$.pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this.location.forward())
  ),
  { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) { }
}

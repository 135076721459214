import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CopyButtonModel, NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { PAGE_TITLE } from '../../../settings-management-content/shared/title-constant';
import { AttributeTabsComponent } from './attribute-tabs/attribute-tabs.component';
import { AttributeSearchConditionView } from './shared/view/attribute-search-condition.view';

@Component({
    selector: 'op-attribute-content',
    templateUrl: './attribute-content.component.html',
    providers: [ActionbarService],
})
export class AttributeContentComponent implements AfterViewInit, OnInit {
    private readonly ATTRIBUTE_SECURITY_CODE = 'ATTRIBUTE';

    @ViewChild(AttributeTabsComponent) attributeTabComponent: AttributeTabsComponent;
    private readonly CATEGORY_TABID = 'CATEGORY';
    private previousTitle: string = PAGE_TITLE.settings;
    private defaultTitle = PAGE_TITLE.attribute;
    public title: string = this.defaultTitle;
    public selectedItem: OopsCategory;
    private unsubscribe$ = new Subject();
    public selectedTab: string;
    public userSecurity = {} as SecurityGroupSecurityModel;
    public searchCondition: AttributeSearchConditionView;

    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new RefreshButtonModel()
    );

    private _navigationSubscription: Subscription;

    constructor(private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private securityGroupService: SecurityGroupService) {}

    ngOnInit(): void {
        let param = this.navigationService.getParams();
        if (param?.redirectToSetting) {
            this.back();
        }
        this.selectedTab = param?.activeId?.toUpperCase() ?? param?.selectedTab?.toUpperCase() ?? this.CATEGORY_TABID;
        this.searchCondition = param?.searchCondition;
        
        this.getUserSecurity();
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.back) {
            this.back();
        }
    }

    back() {
        this.navigationService.navigate('/main/settings/home', this.previousTitle);
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private getUserSecurity() {
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {
                    let attributeIndex = response.findIndex(item => item.securityCode == this.ATTRIBUTE_SECURITY_CODE);
                    Object.assign(this.userSecurity, response[attributeIndex]);
                    if (attributeIndex != -1) {
                        this.setupSecurity();
                    } 
                }
            )
    }

    private setupSecurity() {
        if (this.userSecurity?.newFlag == false) {
            this.actionBarHandler.get(ACTION_STATUS.new).disable();
        }
        if (this.userSecurity?.copyFlag == false) {
            this.actionBarHandler.get(ACTION_STATUS.copy).disable();
        }
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { UiModule } from "src/app/shared/ui/ui.module";
import { ScheduleQueueContentComponent } from "./schedule-queue-content.component";
import { ScheduleQueueHomeComponent } from "./schedule-queue-home/schedule-queue-home.component";
import { ScheduleQueueSearchComponent } from "./schedule-queue-search/schedule-queue-search.component";
import { ScheduleQueueTableComponent } from "./schedule-queue-table/schedule-queue-table.component";
import { ScheduleQueueTaskTableComponent } from "./schedule-queue-table/schedule-queue-task-table/schedule-queue-task-table.component";
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { ScheduleQueueReaccomOrderTableComponent } from "./schedule-queue-table/schedule-queue-reaccom-order-table/schedule-queue-reaccom-order-table.component";
import { ScheduleQueueReaccomReaccTableComponent } from "./schedule-queue-table/schedule-queue-reaccom-reacc-table/schedule-queue-reaccom-reacc-table.component";
import { ScheduleQueueReaccomReaccChildTableComponent } from "./schedule-queue-table/schedule-queue-reaccom-reacc-child-table/schedule-queue-reaccom-reacc-child-table.component";
import { ScheduleQueueReaccomTaskTableComponent } from "./schedule-queue-table/schedule-queue-reaccom-task-table/schedule-queue-reaccom-task-table.component";
import { DataGridRowNumberModule } from "../../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";
import { ScheduleQueueTimTaskTableComponent } from "./schedule-queue-table/schedule-queue-tim-task-table/schedule-queue-tim-task-table.component";
import { ScheduleQueueTimOrderTableComponent } from "./schedule-queue-table/schedule-queue-tim-order-table/schedule-queue-tim-order-table.component";
import { ScheduleQueueCnlOrderTableComponent } from "./schedule-queue-table/schedule-queue-cnl-order-table/schedule-queue-cnl-order-table.component";
import { ScheduleQueueCnlTaskTableComponent } from "./schedule-queue-table/schedule-queue-cnl-task-table/schedule-queue-cnl-task-table.component";


@NgModule({
    declarations: [
        ScheduleQueueContentComponent,
        ScheduleQueueHomeComponent,
        ScheduleQueueSearchComponent,
        ScheduleQueueTableComponent,
        ScheduleQueueTaskTableComponent,
        ScheduleQueueReaccomTaskTableComponent,
        ScheduleQueueReaccomOrderTableComponent,
        ScheduleQueueReaccomReaccTableComponent,
        ScheduleQueueReaccomReaccChildTableComponent,
        ScheduleQueueCnlOrderTableComponent,
        ScheduleQueueCnlTaskTableComponent,
        ScheduleQueueTimOrderTableComponent,
        ScheduleQueueTimTaskTableComponent
    ],
    exports: [
        ScheduleQueueContentComponent
    ],
    imports: [
        ButtonsModule,
        CollapseModule,
        CommonModule,
        DataGridRowNumberModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        DynamicRoutingModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        MatIconModule,
        MatMenuModule,
        NgbModule,
        NgbNavModule,
        OopsCategoriesModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        UiModule
    ]
})
export class ScheduleQueueContentModule { }
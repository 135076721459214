import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";
import { ExportButtonGroupComponent } from "src/app/shared/ui/export-button-group/export-button-group.component";
import { MembershipView } from "../shared/membership-view";

@Component({
    selector: 'op-insight-membership-table',
    templateUrl: './membership-table.component.html'
})
export class InsightMembershipTableComponent implements AfterViewInit {
    public selectedItem: MembershipView;
    public customToolbarMarginTop: number;
    public rows = 0;

    public header: string[] = [];
    public exportData: any;
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightMemberships: MembershipView[] = new Array();
    @Input() membershipLevels: MembershipLevelModel[];
    @Output() onEdit = new EventEmitter();

    @ViewChild('dataGridMembership', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
      }

    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
          });        
    
          this.changeDetectionRef.detectChanges();
        }
    
    public mapExportData(filteredData) {
        if (!this.membershipLevels?.length) {
            return null;
        }
        
        return filteredData.map(dat => (
            {
                No: dat.no,
                MembershipOrganisaiton: dat.providerIataCode,
                MemberNumber: dat.memberNumber,
                MembershipType: dat.membershipTypeName,
                MembershipLevel: this.membershipLevels.find(x => x.membershipLevelCode == dat.membershipLevelCode)?.membershipLevelName,
                Primary: dat.primaryFlag,
                DateofIssue: dat.issueDate,
                ExpiryDate: dat.expiryDate,
            }
        ));
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
      }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    getMembershipLevelName = (row: MembershipView) => {
        return this.membershipLevels?.filter(item => item.membershipLevelCode == row.membershipLevelCode)[0]?.membershipLevelName;
    }

    editInsightMembershipDetail() {
        this.onEdit.emit();
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }
}
export const select2Inherit = {
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2InheritMultiple = {
    allowClear: false,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Helper } from 'src/app/shared/helper/app.helper';

import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DomainAttributeModel
} from 'src/app/core/models/reference-model/reference-general-model';
import {
    CalendarValidityService,
    ConditionReferenceService,
    CountryReferenceService,
    DateTimeDimensionReferenceService,
    PriceRuleLocationPointService,
    ProductGroupReferenceService,
    VehicleGroupReferenceService,
    VehicleTypeReferenceService
} from 'src/app/core/services/system-services';
import {
    DomainAttributeService,
    LocationReferenceService,
    RegionReferenceService
} from 'src/app/core/services/airline-services';
import { ProductGroupReferenceModel, ProductNumberTypeReferenceModel } from 'src/app/core/models/reference-model/reference-product-model';


import { PricingDetailConstant } from '../../shared/pricing-detail.constant';
import { Select2Option } from '../shared/attribute-and-rule/views/select2.option';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    PriceRuleAttributeAndRuleRowView,
    PriceRuleRouteView,
    PriceRuleLocationView,
    PriceRuleValidityView,
    PriceRuleVehicleView,
    PriceRuleAttributeView,
    PriceRuleProductNumberView,
    PriceRuleProductView
} from './shared/views';

import { ProductEligibleRestrictedConverter } from './shared/product-eligible-restricted.converter';

import { AttributeAndRuleBase } from "../shared/attribute-and-rule.base";
import { Select2OptionsService } from "./shared/select2-options.service";
import { PricingService } from "src/app/core/services/pricing-services";
import { OrganisationService } from 'src/app/core/services/organisation-services/organisation.service';

import { takeUntil } from 'rxjs/operators';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { ProductCategoryService } from 'src/app/core/services/reference-service/reference-product-service/product-category.service';
import { ProductCategoryViewModel } from 'src/app/core/models/reference-model/reference-product-model/product-category-view.model';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service';
import { ProductService } from 'src/app/core/services/product-services/product.service';
import { CalendarValidityPrefixService } from 'src/app/core/services/system-services/calendar-validity-prefix.service';
import { CalendarValidityPrefixModel } from 'src/app/core/models/reference-model/reference-general-model/calendar-validity-prefix.model';

declare var moment: any;

@Component({
    selector: 'op-product-eligible-restricted',
    templateUrl: './product-eligible-restricted.component.html',
    providers: [
        Helper,
        ProductEligibleRestrictedConverter
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductEligibleRestrictedComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {
    private readonly PRODUCT_GROUP_CODE_AIR = 'AIR';
    private readonly WITHIN_PREFIX_CODE = 'WITHIN';
    private readonly START_VALUE_FIELD  = 'startValue'
    public focusing: boolean = false;
    @Input('data') set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.setHourFormat(val);
            this.rows$.next(val);
            this.setPreSelectedItems();
        }
    }
    @Input() searchMode: boolean = false;
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter<PriceRuleAttributeAndRuleRowView[]>();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public select2ProductNumberTypeOption = new Select2Option('<Product Number Type>');
    public select2ProductTypeTypeOption = new Select2Option('<Product Type>');
    public select2ProductTypeCategoryCodeOption = new Select2Option('<Product Category>');
    public select2ProductTypeProductGroupOption = new Select2Option('<Product Group>');
    public select2ProductTypeProductTypeGroupOption = new Select2Option('<Product Type Group>');
    public select2ProductIdProductTypeGroupOption = new Select2Option('<Product Name>');
    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>(new Array<PriceRuleAttributeAndRuleRowView>());

    public select2LocationTypeOption = new Select2Option("<Area>");
    public select2LocationTypeDataList = [
        new Select2Data(PricingDetailConstant.REGION_VALUE, 'Region'),
        new Select2Data(PricingDetailConstant.COUNTRY_VALUE, 'Country'),
        new Select2Data(PricingDetailConstant.AIRPORT_CITY_VALUE, 'Airport/City'),
        new Select2Data(PricingDetailConstant.LOCATION_GROUP_VALUE, 'Location Group')
    ];

    public select2RootOption = new Select2Option('<Type>');
    public select2RootDataList = [
        new Select2Data(PricingDetailConstant.PRODUCT_TYPE_CODE, "Product Type"),
        new Select2Data(PricingDetailConstant.PRODUCT_NUMBER_CODE, "Product Number"),
        new Select2Data(PricingDetailConstant.ROUTE_CODE, "Route"),
        new Select2Data(PricingDetailConstant.LOCATION_CODE, "Location"),
        new Select2Data(PricingDetailConstant.CALENDAR_CODE, "Calendar"),
        new Select2Data(PricingDetailConstant.VEHICLE_CODE, "Vehicle"),
        new Select2Data(PricingDetailConstant.NUM_INDIVIDUAL_CODE, "Number of Individual"),
        new Select2Data(PricingDetailConstant.PROD_RESTRICTED_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE, "Transit Minutes"),
        new Select2Data(PricingDetailConstant.PROD_RESTRICTED_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE, "Transit Hours"),
    ];
    public select2LocationPointOption = new Select2Option('<O/D>');
    public select2DateTimeDimensionOption = new Select2Option('<Date Type>');
    public select2TimezoneOption = new Select2Option('<Timezone>');
    public select2TimezoneDataList = [
        new Select2Data(),
        new Select2Data(PricingDetailConstant.UTC_CODE, "UTC"),
        new Select2Data(PricingDetailConstant.LOCAL_CODE, "Local"),
    ];
    public dateRangePickerDateOption: Select2Option;
    public dateRangePickerDateTimeOption: Select2Option;
    public dateRangePickerTimeOption: Select2Option;
    public dateRangePickerEndDateOptions = [];
    public dateRangePickerEndDateTimeOptions = [];
    public dateRangePickerEndTimeOptions = [];
    public select2WeekdayDataList = PricingDetailConstant.SELECT2_WEEKDAY_DATALIST;
    public select2IsIsNotIsBetweenIsNotBetweenConditionOption: Select2Option;

    public select2VehicleGroupOption = new Select2Option('<Vehicle Group>');
    public select2VehicleTypeOption = new Select2Option('<Vehicle Type>');
    public select2VehicleCompositionOption = new Select2Option('<Composition>');

    public select2RegionsDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2ProductNumberTypeDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2ProductCategoryDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2DateTimeDimensionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2LocationPointDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2CalendarValidityDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2CalendarValidityPrefixDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotIsBetweenIsNotBetweenConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotLessThanMoreThanConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2VehicleGroupDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2VehicleTypeDataListCollection$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    public select2VehicleCompositionDataListCollection$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    public attributeTypeCodes$ = new BehaviorSubject<{}[]>(this.attributeTypeCodes);

    public products$ = new BehaviorSubject<Select2Data[][][]>([]);

    public select2IsIsNotConditionOption: Select2Option;
    public select2ProviderOption: Select2Option;
    public select2OriginOption: Select2Option;
    public select2DestinationOption: Select2Option;
    public select2RegionOption: Select2Option;
    public select2CountryOption: Select2Option;
    public select2AirportCityOption: Select2Option;
    public select2LocationGroupOption: Select2Option;
    public select2WeekdayOption: Select2Option;
    public select2CalendarValidityOption: Select2Option;
    public select2IsIsNotLessThanMoreThanConditionOption: Select2Option;

    private get attributeTypeCodes() {
        return [
            {
                code: PricingDetailConstant.NUM_INDIVIDUAL_CODE,
            },
            {
                code:
                    PricingDetailConstant.PROD_RESTRICTED_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE,
            },
            {
                code:
                    PricingDetailConstant.PROD_RESTRICTED_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE,
            },
        ];
    }
    private unsubscribe$ = new Subject();

    constructor(private calendarValidityService: CalendarValidityService,
        private conditionReferenceService: ConditionReferenceService,
        private countryService: CountryReferenceService,
        private converter: ProductEligibleRestrictedConverter,
        private dateTimeDimensionService: DateTimeDimensionReferenceService,
        private locationReferenceService: LocationReferenceService,
        private domainAttributeService: DomainAttributeService,
        private priceRuleLocationPointService: PriceRuleLocationPointService,
        private regionService: RegionReferenceService,
        private service: PricingService,
        private toastrService: ToastrService,
        private select2OptionsService: Select2OptionsService,
        private vehicleGroupReferenceService: VehicleGroupReferenceService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private organisationService: OrganisationService,
        private productCategoryService: ProductCategoryService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private productService: ProductService,
        private calendarValidityPrefixService: CalendarValidityPrefixService) {
        super();
        this.select2ProductIdProductTypeGroupOption.minimumInputLength = 0;
        this.select2ProductIdProductTypeGroupOption.minimumResultsForSearch = 0;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setPreSelectedItems() {
        this.dateRangePickerEndDateOptions = [];
        this.dateRangePickerEndDateTimeOptions = [];
        this.dateRangePickerEndTimeOptions = [];
        for (let row of this.rows$.value) {
            if (row.productNumber?.providerId && !row.productNumber?.preSelectedProvider$.value) {
                this.getProviderPreSelected(row.productNumber?.providerId,
                    (organisationCode) => {
                        let select2Provider = new Select2Data(row.productNumber.providerId, organisationCode);
                        row.productNumber.preSelectedProvider$.next(select2Provider);
                    });
            }
            if (row.route?.originLocationId && !row.route?.originLocationPreSelected$?.value) {
                this.getLocationReferencePreSeleted([row.route?.originLocationId],
                    (locations: Select2Data[]) => {
                        row.route.originLocationPreSelected$.next(locations[0])
                    });
            }
            if (row.route?.destinationLocationId && !row.route?.destinationLocationPreSelected$?.value) {
                this.getLocationReferencePreSeleted([row.route?.destinationLocationId],
                    (locations: Select2Data[]) => {
                        row.route.destinationLocationPreSelected$.next(locations[0])
                    });
            }
            if (row.location?.regionCode && !row.location?.preSelectedDataList$?.value) {
                this.getRegionReferencePreSelected(row.location?.regionCode,
                    (regions: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(regions);
                    })
            }
            if (row.location?.countryCode && !row.location?.preSelectedDataList$?.value) {
                this.getCountryReferencePreSelected(row.location?.countryCode,
                    (countries: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(countries);
                    })
            }
            if (row.location?.locationId && !row.location?.preSelectedDataList$?.value) {
                this.getLocationReferencePreSeleted(row.location?.locationId,
                    (locations: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(locations);
                    })
            }
            if (row.location?.locationGroupId && !row.location?.preSelectedDataList$?.value) {
                this.getLocationGroupPreSeleted(row.location?.locationGroupId,
                    (locationGroups: Select2Data[]) => {
                        row.location.preSelectedDataList$.next(locationGroups);
                    })
            }
            if (row.vehicle) {
                this.onVehicleTypeChange(row.vehicle.vehicleTypeCode, row)
                if (row.vehicle.vehicleGroupCode) {
                    this.getVehicleTypeReference(row.vehicle.vehicleGroupCode)
                    if (row.vehicle.vehicleTypeCode) {
                        this.getVehicleComposition(row.vehicle.vehicleTypeCode)
                    }
                }
            }
            if (row.product) {
                this.getProductGroupReference(row.product.productCategoryCode,
                    (data: Select2Data[]) => {
                        row.product.productGroupDataList$.next(data);
                    });
                this.getProductTypeGroup(row.product.productGroupCode, (data: Select2Data[]) => {
                    row.product.productTypeDataList$.next(data);
                });
                if (row.product.productGroupCode && row.product.productTypeCode) {
                    this.getProducts(row.product.productGroupCode, row.product.productTypeCode)
                }
            }
            this.dateRangePickerEndDateOptions.push(this.select2OptionsService.getDatePickerOption());
            this.dateRangePickerEndDateTimeOptions.push(this.select2OptionsService.getDatetimePickerOption());
            this.dateRangePickerEndTimeOptions.push(this.select2OptionsService.getTimePickerOption());
        }
        this.togglePanelCollapseStatus(false);
    }

    public getLocationGroupPreSeleted(ids: string[], callback) {
        this.service.getLocationGroup(ids)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    callback(res.map(r => new Select2Data(r.locationGroupId, r.locationGroupName)));
                }
            )
    }

    public getCountryReferencePreSelected(codes: string[], callback) {
        this.countryService.getCountryReference(codes).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
                callback(res.map((r) => new Select2Data(r.countryCode, r.countryName)));
            }
        );
    }

    public getRegionReferencePreSelected(id: string[], callback) {
        this.regionService.getRegionReference(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
                callback(res.map((r) => new Select2Data(r.regionCode, r.regionName)));
            }
        );
    }

    private getLocationReferencePreSeleted(id: string[], callback) {
        this.locationReferenceService.getLocationReference(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
                callback(res.map((r) => new Select2Data(r.locationId, r.locationName)));
            }
        );
    }

    private getProviderPreSelected(id: string, callback) {
        this.organisationService.getOrganisation(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
                callback(res["organisationCode"]);
            }
        );
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            this.rows$.next(this.rows$.value)
            return false;
        }
    }

    ngOnInit() {
        this.select2IsIsNotConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotIsBetweenIsNotBetweenConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotLessThanMoreThanConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2OriginOption = this.select2OptionsService.getSelect2OriginOption();
        this.select2DestinationOption = this.select2OptionsService.getSelect2DestinationOption();
        this.select2ProviderOption = this.select2OptionsService.getSelect2ProviderOption();
        this.select2RegionOption = this.select2OptionsService.getSelect2RegionOption();
        this.select2CountryOption = this.select2OptionsService.getSelect2CountryOption();
        this.select2AirportCityOption = this.select2OptionsService.getSelect2AirportCityOption();
        this.select2LocationGroupOption = this.select2OptionsService.getSelect2LocationGroupOption();
        this.select2CalendarValidityOption = this.select2OptionsService.getSelect2CalendarValidityOption();
        this.dateRangePickerDateOption = this.select2OptionsService.getDatePickerOption();
        this.dateRangePickerDateTimeOption = this.select2OptionsService.getDatetimePickerOption();
        this.dateRangePickerTimeOption = this.select2OptionsService.getTimePickerOption();
        this.select2WeekdayOption = this.select2OptionsService.getSelect2WeekdayOption();

        this.getPriceRuleAttributeTypeIds();
        this.getSelect2IsIsNotLessThanMoreThanConditionDataList();
        this.getProductNumberTypeSelect2DataList();
        this.getProductCategorySelect2DataList();
        this.getVehicleGroupReference();
        this.getDateTimeDimensionReference();
        this.getSelect2IsIsNotConditionDataList();
        this.getPriceRuleLocationPointReference();
        this.getSelect2IsIsNotLessThanMoreThanConditionDataList();
        this.getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList();
        this.getCalendarValidityReference();
        this.getCalendarValidityPrefixReference();
    }

    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let temp = this.rows$.value;
            temp.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(temp);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    private reset(row) {
        delete row.productNumber;
        delete row.route;
        delete row.location;
        delete row.validity;
        delete row.vehicle;
        delete row.vehicle;
        delete row.attribute;
        delete row.product;
    }

    onRootDataChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.type != event) {
            this.reset(row);
        }
        row.type = event;
        if (row.type == PricingDetailConstant.PRODUCT_NUMBER_CODE) {
            row.productNumber = row.productNumber ?? new PriceRuleProductNumberView();
        } else if (row.type == PricingDetailConstant.ROUTE_CODE) {
            row.route = row.route ?? new PriceRuleRouteView();
            this.getSelect2IsIsNotConditionDataList();
        } else if (row.type == PricingDetailConstant.LOCATION_CODE) {
            row.location = row.location ?? new PriceRuleLocationView();
            row.location.priceRuleLocationTypeCode = PricingDetailConstant.SERVICE_CODE;
        } else if (row.type == PricingDetailConstant.CALENDAR_CODE) {
            row.validity = row.validity ?? new PriceRuleValidityView();
            row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.PRODUCTRESTRICTED_CODE;
            row.validity.calendarValidityCode = PricingDetailConstant.SERVICE_CODE;
            this.getDateTimeDimensionReference();
        } else if (row.type == PricingDetailConstant.VEHICLE_CODE) {
            row.vehicle = row.vehicle ?? new PriceRuleVehicleView();
            this.getVehicleGroupReference();
            this.getSelect2IsIsNotConditionDataList();
        } else if (
            row.type == PricingDetailConstant.NUM_INDIVIDUAL_CODE ||
            row.type ==
            PricingDetailConstant.PROD_RESTRICTED_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE ||
            row.type ==
            PricingDetailConstant.PROD_RESTRICTED_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE
        ) {
            this.createAttribute(row, event);
        } else if (row.type == PricingDetailConstant.PRODUCT_TYPE_CODE) {
            row.product = row.product ?? new PriceRuleProductView();
        }
        this.resetErrorFlags();
    }

    private createAttribute(
        row: PriceRuleAttributeAndRuleRowView,
        attributeTypeCode: string
    ) {
        this.reset(row);
        row.attribute = new PriceRuleAttributeView();
        row.attribute.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;

        let priceRuleAttributeTypeSelected = this.attributeTypeCodes$.value.filter(
            (attr) => attr["code"] == attributeTypeCode
        );
        if (priceRuleAttributeTypeSelected?.length) {
            row.attribute.attributeTypeCode =
                priceRuleAttributeTypeSelected[0]["id"];
        }
    }

    private getPriceRuleAttributeTypeIds() {
        for (let attributeType of this.attributeTypeCodes$.value) {
            let attributeTypeCode = attributeType["code"];
            this.domainAttributeService
                .getPriceRuleAttributes(
                    "",
                    PricingDetailConstant.PRODUCTRESTRICTED_CODE,
                    attributeTypeCode
                )
                .pipe(takeUntil(this.unsubscribe$)).subscribe(
                    (res: DomainAttributeModel[]) => {
                        if (res?.length) {
                            let attributeTypeCode = res[0].attributeTypeCode;
                            this.filterNonSearchDomainAttribute(res[0], attributeTypeCode);
                            attributeType["id"] = attributeTypeCode;
                            this.attributeTypeCodes$.next(
                                this.attributeTypeCodes$.value
                            );
                        }
                    }
                );
        }
    }

    private filterNonSearchDomainAttribute(domainAttribute: DomainAttributeModel, attributeTypeCode: string) {
        if (!domainAttribute.searchFlag && this.searchMode) {
            this.select2RootDataList = this.select2RootDataList.filter(d => d.id != attributeTypeCode)
        }
    }

    onDateTimeDimensionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.dateTimeDimensionCode != event) {
            row.validity = new PriceRuleValidityView();
            row.validity.priceRuleAttributeGroupCode = PricingDetailConstant.PRODUCTRESTRICTED_CODE;
            row.validity.calendarValidityCode = PricingDetailConstant.SERVICE_CODE;
            row.validity.dateTimeDimensionCode = event;
        }
        this.getCalendarValidityReference();
        if (row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_DAY 
            || row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_MONTH 
            || row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_HOUR) {
            row.validity.calendarValidityPrefixCode = PricingDetailConstant.IS_PREFIX_CODE;
            row.validity.startValue = []
            this.getSelect2IsIsNotConditionDataList();
        }else if (row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_WEEKDAY ) {
            this.getSelect2IsIsNotConditionDataList();
        } else {
            if (row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_DATE
                || row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_TIME
                || row.validity.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_DATE_TIME) {
                this.getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList();
            } else {
                this.getSelect2IsIsNotLessThanMoreThanConditionDataList();
            }
        }
    }

    onVehicleGroupChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.vehicle.vehicleGroupCode != event) {
            row.vehicle = new PriceRuleVehicleView();
            row.vehicle.vehicleGroupCode = event;
        }
        this.getVehicleTypeReference(event);
    }

    onVehicleTypeChange(event, row) {
        if (row.vehicle.vehicleTypeCode != event) {
            row.vehicle.vehicleTypeCode = event;
        }
        this.getVehicleComposition(event)
    }

    onCalendarConditionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.conditionCode != event) {
            row.validity.endDateTime = "";
            row.validity.endValue = "";
        }
        this.onValueChange(event, row.validity, 'conditionCode');
    }

    private getSelect2IsIsNotConditionDataList() {
        if (this.select2IsIsNotConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE], (res) => {
            this.select2IsIsNotConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList() {
        if (this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE,
        PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_BETWEEN_OPERATOR_CODE], (res) => {
            this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getSelect2IsIsNotLessThanMoreThanConditionDataList() {
        if (this.select2IsIsNotLessThanMoreThanConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE,
        PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
        PricingDetailConstant.GREATER_THAN_OPERATOR_CODE], (res) => {
            this.select2IsIsNotLessThanMoreThanConditionDataList$.next(this.converter.toConditionReferenceSelect2DataList(res));
        });
    }


    onProductNumberTypeChange(row: PriceRuleAttributeAndRuleRowView, event) {
        if (row.productNumber.productNumberTypeCode != event) {
            row.productNumber = new PriceRuleProductNumberView();
            row.productNumber.productNumberTypeCode = event;
            this.resetErrorFlags();
        }
        this.getSelect2IsIsNotConditionDataList();
    }

    onLocationTypeChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.location.locationType != event) {
            row.location = new PriceRuleLocationView();
            row.location.locationType = event;
            row.location.priceRuleLocationTypeCode = PricingDetailConstant.SERVICE_CODE;
            row.location.priceRuleLocationPointCode = null;
        }
        this.getSelect2IsIsNotConditionDataList();
        this.getPriceRuleLocationPointReference();
    }

    private getPriceRuleLocationPointReference() {
        if (this.select2LocationPointDataList$?.value?.length) {
            return;
        }
        this.priceRuleLocationPointService.getProductLocationPointReference().subscribe(
            (res) => {
                this.select2LocationPointDataList$.next(
                    this.converter.toLocationPointReferenceDataList(res)
                );
            }
        );
    }

    private getDateTimeDimensionReference() {
        if (this.select2DateTimeDimensionDataList$?.value?.length) {
            return;
        }
        this.dateTimeDimensionService.getDateTimeDimensionReference(PricingDetailConstant.CALENDAR_DATE,
            PricingDetailConstant.CALENDAR_DATE_TIME,
            PricingDetailConstant.CALENDAR_TIME,
            PricingDetailConstant.CALENDAR_WEEKDAY,
            PricingDetailConstant.NUM_DAYS,
            PricingDetailConstant.NUM_HOURS,
            PricingDetailConstant.NUM_MINUTES,
            PricingDetailConstant.CALENDAR_DAY,
            PricingDetailConstant.CALENDAR_MONTH,
            PricingDetailConstant.CALENDAR_HOUR)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res) => {
                    this.select2DateTimeDimensionDataList$.next(
                        this.converter.toDateTimeDimensionReferenceDataList(res)
                    );
                }
            );
    }

    private getCalendarValidityReference() {
        this.calendarValidityService.getByCalendarValidityCodes(PricingDetailConstant.SERVICE_CODE)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: CalendarValidityReferenceModel[]) => {
                    this.select2CalendarValidityDataList$.next(this.converter.toCalendarValidityReferenceDataList(res));
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_CALENDAR_VALIDITY_REFERENCE, PricingDetailConstant.ALERT_HEADER)
                }
            );
    }

    private getCalendarValidityPrefixReference() {
        this.calendarValidityPrefixService.getAll()
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(
                (response: CalendarValidityPrefixModel[]) => {
                    this.select2CalendarValidityPrefixDataList$.next(this.converter.toCalendarValidityPrefixReferenceDataList(response));
                }
            )
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ConditionReferenceModel[]) => {
                    callback(res);
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_CONDITION_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            );
    }

    private getProductNumberTypeSelect2DataList() {
        this.service.getProductNumberTypeReference(PricingDetailConstant.OPERATING_NUMBER_TYPE_CODE,
            PricingDetailConstant.PRODUCT_NUMBER_TYPE_CODE,
            PricingDetailConstant.MARKETING_NUMBER_TYPE_CODE)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ProductNumberTypeReferenceModel[]) => {
                    this.select2ProductNumberTypeDataList$.next(this.converter.toProductNumberTypeSelect2DataList(res));
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_PRODUCT_NUMBER_TYPE_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            );
    }

    private getProductCategorySelect2DataList() {
        this.productCategoryService.getAllActive().pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res: ProductCategoryViewModel[]) => {
                this.select2ProductCategoryDataList$.next(this.converter.toProductCategoryReferenceDataList(res));
            },
            err => {
                this.toastrService.error(PricingDetailConstant.FAILED_GET_PRODUCT_CATEGORY_REFERENCE, PricingDetailConstant.ALERT_HEADER);
            }
        )
    }

    private getVehicleComposition(vehicleTypeCode: string) {
        if (this.select2VehicleCompositionDataListCollection$.value?.length && this.select2VehicleCompositionDataListCollection$.value[vehicleTypeCode]?.length) {
            return;
        }
        this.service.getVehicleComposition(vehicleTypeCode)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    if (!this.select2VehicleCompositionDataListCollection$.value) {
                        this.select2VehicleCompositionDataListCollection$.next([]);
                    }
                    let collection = this.select2VehicleCompositionDataListCollection$.value
                    let data = this.converter.toVehicleCompositionReferenceDataList(res)
                    collection[vehicleTypeCode] = data
                    this.select2VehicleCompositionDataListCollection$.next(collection);
                }
            )
    }


    private getVehicleTypeReference(vehicleGroupCode: string) {
        this.vehicleTypeReferenceService.getVehicleTypeReference(vehicleGroupCode)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    if (!this.select2VehicleTypeDataListCollection$.value) {
                        this.select2VehicleTypeDataListCollection$.next([]);
                    }
                    let collection = this.select2VehicleTypeDataListCollection$.value
                    let data = this.converter.toVehicleTypeReferenceDataList(res)
                    collection[vehicleGroupCode] = data
                    this.select2VehicleTypeDataListCollection$.next(collection)
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_VEHICLE_TYPE_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            )
    }

    private getVehicleGroupReference() {
        this.vehicleGroupReferenceService.getVehicleGroupReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2VehicleGroupDataList$.next(this.converter.toVehicleGroupReferenceDataList(res));
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_VEHICLE_GROUP_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            )
    }

    public onProductCategoryCodeChange(productCategoryCode: any, priceRuleProduct: PriceRuleProductView) {
        priceRuleProduct.productGroupCode = null;
        priceRuleProduct.productTypeCode = null;
        this.getProductGroupReference(productCategoryCode, 
            (data: Select2Data[]) => {
                priceRuleProduct.productGroupDataList$.next(data);
        });
        priceRuleProduct.productCategoryCode = productCategoryCode;
    }

    private getProductGroupReference(productCategoryCode: string, callback) {
        this.productGroupReferenceService.getByProductCategory(productCategoryCode)
            .subscribe(
                (res: ProductGroupReferenceModel[]) => {
                    callback(this.converter.toProductGroupDataList(res));
                }
            )
    }

    public onProductGroupCodeChange(productGroupCode: any, priceRuleProduct: PriceRuleProductView) {
        priceRuleProduct.productTypeCode = null;
        this.getProductTypeGroup(productGroupCode, (data: Select2Data[]) => {
            priceRuleProduct.productTypeDataList$.next(data);
        });
        priceRuleProduct.productGroupCode = productGroupCode;
    }

    private getProductTypeGroup(productGroupCode: string, callback) {
        this.productTypeGroupService.getAll().pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    callback(this.converter.toProductTypeDataList(res.filter(r => r.productGroupCode == productGroupCode)));
                }
            )
    }
    
    public onStartDateTimeChange(row: any, value: any) {
        row.validity.startDateTime = value;
        this.resetErrorFlags();

        let index = this.rows$.value.indexOf(row);
        let option: Select2Option = null;
        switch(row.validity.dateTimeDimensionCode){
            case 'DATE':
                option = this.updateDateOption(option, value, index);
                break;
            case 'DATETIME':
                option = this.updateDateTimeOption(option, value, index);
                break;
            case 'TIME':
                option = this.updateTimeOption(option, value, index);
                break;
            default:
                break;
        }

        this.setEndDateTime(row, value, option);
    }
    
    private updateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getTimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndTimeOptions[index] = option;
        return option;
    }

    private updateDateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getDatetimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateTimeOptions[index] = option;
        return option;
    }

    private updateDateOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getDatePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateOptions[index] = option;
        return option;
    }

    private setEndDateTime(row: any, value: any, option: Select2Option) {
        let endDateTime = row.validity.endDateTime;
        if (!endDateTime || moment(value, option.locale.format) > moment(endDateTime, option.locale.format)) {
            row.validity.endDateTime = value;
        }
    }

    public onEndDateTimeChange(row: any, value: any) {
        row.validity.endDateTime = value;
        this.resetErrorFlags();
    }

    public deleteRow(row, index) {
        super.delete(row);
        this.dateRangePickerEndDateOptions = this.dateRangePickerEndDateOptions.splice(index, 1);
        this.dateRangePickerEndDateTimeOptions = this.dateRangePickerEndDateTimeOptions.splice(index, 1);
        this.dateRangePickerEndTimeOptions = this.dateRangePickerEndTimeOptions.splice(index, 1);
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public onInputChange(event: Event, field: any, fieldName: string) {
        field[fieldName] = (event.target as HTMLInputElement).value;
        this.resetErrorFlags();
    }

    public onDayMonthValueChange(event: Event, row: PriceRuleAttributeAndRuleRowView, fieldName: string) {
        let value = (event.target as HTMLInputElement).value.replace(/[^0-9]*/g,'')
        if (fieldName == this.START_VALUE_FIELD) {
            this.onStartValueChange(value, row);
            return;
        }
        this.onValueChange(value, row.validity, fieldName);
    }

    public onStartValueChange(value: string, row: PriceRuleAttributeAndRuleRowView) {
        row.validity.startValue[0] = value;
        this.resetErrorFlags();
    }

    public onHourValueChange(event: Event, row: PriceRuleAttributeAndRuleRowView, fieldName: string) {
        var strValue = (event.target as HTMLInputElement).value
        if (strValue) {
            strValue = this.getHourFormatString(strValue);
        }

        if (fieldName == this.START_VALUE_FIELD) {
            this.onValueChange(strValue, row.validity, fieldName);
            this.addHoursAndMinutesStartValue(row, strValue)
            return;
        }
        this.onValueChange(strValue, row.validity, fieldName);
        this.addHoursAndMinutesEndValue(row, strValue)
        this.resetErrorFlags();
    }

    private getHourFormatString(value: string): string {
        if (!value) {
            return null;
        }
        let [hour, min] = value.split(':').map(item => Number(item));
        hour = hour ?? 0
        min = min ?? 0;
        if (min > 0) {
            hour += Math.floor(min / 60);
            min = min % 60
        }
        return String(hour).padStart(2, '0') + ':' + String(min).padStart(2, '0')
    }

    private addHoursAndMinutesStartValue(row: PriceRuleAttributeAndRuleRowView, value: string) {
        let [hour, min] = value.split(':');
        row.validity.hoursStartValue = hour;
        row.validity.minutesStartValue = min;
    }

    private addHoursAndMinutesEndValue(row: PriceRuleAttributeAndRuleRowView, value: string) {
        let [hour, min] = value.split(':');
        row.validity.hoursEndValue = hour;
        row.validity.minutesEndValue = min;
    }

    public onVehicleCompositionIdChange(value: any, row: PriceRuleAttributeAndRuleRowView) {
        if (row.vehicle.vehicleCompositionId != value) {
            row.vehicle.vehicleCompositionId = value;
            this.resetErrorFlags();
        }
    }

    public onProductTypeChange(value: any, row: PriceRuleAttributeAndRuleRowView) {
        row.product.productTypeCode = value;

        let productGroupCode = row.product.productGroupCode;
        let productTypeCode = row.product.productTypeCode;

        if (!productGroupCode || !productTypeCode) {
            return;
        }

        let products = this.products$.value;
        if (products?.length && products[productGroupCode]?.length && products[productGroupCode][productTypeCode]?.length) {
            return;
        }

        this.getProducts(productGroupCode, productTypeCode)
    }

    private getProducts(productGroupCode: string, productTypeCode: string) {
        let finalFlag = null;
        if (productGroupCode == this.PRODUCT_GROUP_CODE_AIR) {
            finalFlag = true;
        }
        this.productService.getBy(productGroupCode, productTypeCode, 'DATA', finalFlag)
            .subscribe(
                responses => {
                    let products = this.products$.value;
                    if (!products[productGroupCode]) {
                        products[productGroupCode] = [];
                    }
                    if (!products[productGroupCode][productTypeCode]) {
                        products[productGroupCode][productTypeCode] = responses.map(res => new Select2Data(res.productId, res.productName));
                        this.products$.next(products);
                    }
                }
            )
    }

    public getDayMonthValidityPrefixPlaceHolder(row: PriceRuleAttributeAndRuleRowView, fieldName: string): string {
        if (row.validity.calendarValidityPrefixCode == this.WITHIN_PREFIX_CODE) {
            if (fieldName == this.START_VALUE_FIELD) {
                return '<Enter Start Value>';
            }
            return '<Enter End Value>';
        }
        return '<Enter Value>'
    }

    public getHourValidityPrefixPlaceHolder(row: PriceRuleAttributeAndRuleRowView, fieldName: string): string {
        if (row.validity.calendarValidityPrefixCode == this.WITHIN_PREFIX_CODE) {
            if (fieldName == this.START_VALUE_FIELD) {
                return '<Enter Start Hour>';
            }
            return '<Enter End Hour>';
        }
        return '<Enter Hour and Minute>'
    }

    public onKeyDownInteger(event: KeyboardEvent) {
        if (event.key == '.' || event.key == '-')
        {
            event.preventDefault();
        }
    }

    public onKeyDownHour(event: KeyboardEvent) {
        const hourRegEx = new RegExp(/^[\d:]/g);
        const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
        if (specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if (!hourRegEx.test(event.key))
        {
            event.preventDefault();
        }
    }

    private setHourFormat(views: PriceRuleAttributeAndRuleRowView[]) {
        for (let view of views) {
            if (view.validity?.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_HOUR) {
                view.validity.startValue = [''];
                view.validity.startValue[0] = this.getHourFormatString(view.validity.hoursStartValue.toString() + ':' + view.validity.minutesStartValue.toString());
                view.validity.endValue = this.getHourFormatString(view.validity.hoursEndValue.toString() + ':' + view.validity.minutesEndValue.toString());
            }
        }
    }

    public displayHourValidityEndValueField(row: PriceRuleAttributeAndRuleRowView) {
        return row?.validity?.calendarValidityPrefixCode == this.WITHIN_PREFIX_CODE && (Number(row?.validity?.hoursEndValue) || Number(row?.validity?.minutesEndValue) || !(row?.isSaved));
    }

    public displayValidityEndValueField(row: PriceRuleAttributeAndRuleRowView) {
        return row?.validity?.calendarValidityPrefixCode == this.WITHIN_PREFIX_CODE && (Number(row?.validity?.endValue) || !(row?.isSaved));
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttributeDetailComponent } from './settings-categories-content/attribute/attribute-content/attribute-detail/attribute-detail.component';
import { ExchangeCurrencyComponent } from './settings-categories-content/exchange-currency/exchange-currency.component';
import { ExchangeRateAmountComponent } from './settings-categories-content/exchange-rate-amount/exchange-rate-amount.component';
import { ExchangeRateComponent } from './settings-categories-content/exchange-rate/exchange-rate.component';
import { SecurityAuditsComponent } from './settings-categories-content/security-management/security-audits/security-audits.component';
import { SecurityCodeDetailComponent } from './settings-categories-content/security-management/security-code/security-code-detail/security-code-detail.component';
import { SecurityCodeComponent } from './settings-categories-content/security-management/security-code/security-code.component';
import { SecurityGroupDetailComponent } from './settings-categories-content/security-management/security-group/security-group-detail/security-group-detail.component';
import { SecurityGroupComponent } from './settings-categories-content/security-management/security-group/security-group.component';
import { SecurityManagementComponent } from './settings-categories-content/security-management/security-management.component';
import { SecurityReportsComponent } from './settings-categories-content/security-management/security-reports/security-reports.component';
import { VehicleCompositionDetailComponent } from './settings-categories-content/vehicle-composition/vehicle-composition-detail/vehicle-composition-detail.component';
import { VehicleConfigurationDetailComponent } from './settings-categories-content/vehicle-configuration/vehicle-configuration-detail/vehicle-configuration-detail.component';
import { VehicleComponent } from './settings-categories-content/vehicle/vehicle.component';
import { SettingsManagementContentComponent } from './settings-management-content/settings-management-content.component';
import { SettingsManagementComponent } from './settings-management.component';
import { DocumentDistributionComponent } from './settings-categories-content/document-distribution/document-distribution.component';
import { DocumentDistributionDetailComponent } from './settings-categories-content/document-distribution/document-distribution-content/document-distribution-detail/document-distribution-detail.component';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: SettingsManagementComponent,
        children: [
            {
                path: 'vehicle',
                component: VehicleComponent,
            },
            {
                path: 'vehicle-configuration/details',
                component: VehicleConfigurationDetailComponent
            },
            {
                path: 'attribute/details',
                component: AttributeDetailComponent
            },

            {
                path: 'vehicle-composition/details',
                component: VehicleCompositionDetailComponent
            },
            {
                path: 'security',
                component: SecurityManagementComponent
            },
            {
                path: 'security-group',
                component: SecurityGroupComponent,
                children: [
                    {
                        path: 'details',
                        component: SecurityGroupDetailComponent
                    },
                ]
            },
            {
                path: 'security-code',
                component: SecurityCodeComponent,
                children: [
                    {
                        path: 'details',
                        component: SecurityCodeDetailComponent
                    },
                ]
            },
            {
                path: 'security-audit',
                component: SecurityAuditsComponent
            },
            {
                path: 'security-report',
                component: SecurityReportsComponent
            },
            {
                path: 'exchange-rate',
                component: ExchangeRateComponent
            },
            {
                path: 'exchange-currency',
                component: ExchangeCurrencyComponent
            },
            {
                path: 'exchange-rate-amount',
                component: ExchangeRateAmountComponent
            },
            {
                path: 'document-distribution',
                component: DocumentDistributionComponent
            },
            {
                path: 'document-distribution/details',
                component: DocumentDistributionDetailComponent
            },
            { path: 'geography', loadChildren: () => import('./settings-categories-content/geography/geography.module').then(m => m.GeographyModule) },
            { path: 'location-detail', loadChildren: () => import('./settings-categories-content/geography/detail-page/location-detail.module').then(m => m.LocationDetailModule) },
            { path: 'location-group-detail', loadChildren: () => import('./settings-categories-content/geography/detail-page/location-group-detail.module').then(m => m.LocationGroupDetailModule) },
            { path: 'timezone-detail', loadChildren: () => import('./settings-categories-content/geography/detail-page/timezone-detail.module').then(m => m.TimezoneDetailModule) },
        ]
    },
    {
        path: 'home',
        component: SettingsManagementContentComponent,
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingsManagementRoutingModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { DimensionUnitReferenceModel } from '../../models/reference-model/reference-general-model/dimension-unit-reference.model';

@Injectable({
  providedIn: 'root'
})
export class DimensionUnitReferenceService extends ApiServiceBase {
  private readonly PATH = "/system/DimensionUnitReference"

  constructor(httpClient: HttpClient,
    authService: AuthService) {
      super(httpClient, authService);
    }

  public getDimensionUnitReference(): Observable<DimensionUnitReferenceModel[]> {
    return this.httpGet<DimensionUnitReferenceModel[]>(this.PATH, null)
      .pipe(
        map(response => response.body)
      );
  }
}

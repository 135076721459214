export const select2DefaultOption = {
    placeholder: "",
    minimumResultsForSearch: -1
}

export const select2DefaultOptionWithAllowClear = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2VehicleType = {
    placeholder: "",
    allowClear: true
}
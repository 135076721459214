import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

import { DxDataGridComponent } from "devextreme-angular";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { InsightPassengerService } from "src/app/core/services/individul-services";
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { DateConverterService } from '../../utils/date-converter.service';
import { StatusReferenceModel } from '../../models/reference-model/reference-general-model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-individual-search-table',
    templateUrl: './individual-search-table.component.html'
})
export class IndividualSearchTableComponent extends DatatableComponentBase implements AfterViewInit {

    private readonly ERROR_STATUS_CANNOT_CHANGE: string = 'Status cannot be changed due to email address is not available to use.';
    @Input("exportFileName") exportFileName: string;
    @Input("hideExport") hideExport: boolean;
    @Input("print") print: boolean = true;
    @Input("copyToClipboard") copyToClipboard: boolean = true;
    @Input("export") export: boolean = true;
    @Input("filterNotActive") filterNotActive: boolean = false;
    @Input("searchFiltertype")  searchFiltertype: boolean = false;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() isShowStatusColumn: boolean = false;

    @Output("rowDoubleClick") doubleClickEvent = new EventEmitter<InsightPassengerSearchModel>();
    @Output("rowClick") clickEvent = new EventEmitter<InsightPassengerSearchModel>();
    @Output("loadSuccess") loadSuccessEvent = new EventEmitter<number>();
    @Output() onDisplayAlert = new EventEmitter<string>();

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public exportData: any;
    public header: string[];
    
    public individualSearchResults: InsightPassengerSearchModel[];
    public rows: number = 0;
    public selectedItem: boolean = false;
    public itemSelected: InsightPassengerSearchModel;

    public pdfDefaultStyle = {
        fontSize: "9"
    };

    public pdfStyleForExport = {
        fontSize: "5"
    }
    
    constructor(private changeDetector: ChangeDetectorRef,
        private insightPassengerService: InsightPassengerService,
        private dateConverter: DateConverterService) {
        super();
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public searchIndividuals(filter: InsightPassengerSearchModel) {
        var dynamicDataArray = new Array();

        if (this.filterNotActive) {
            filter.statusCode = "A";
        }

        this.insightPassengerService.searchInsightPassenger(filter)
            .subscribe(
                (responses: InsightPassengerSearchModel[]) => {
                    this.cleanDatatable();
                    var index = 1;
                    if (!this.searchFiltertype) {
                        responses = responses.filter(x => x.usageTypeCode != "FILTER")
                    }

                    responses.forEach(item => {
                        var insightPassengerTemps = {};
                        insightPassengerTemps["individualId"] = item.individualId;
                        insightPassengerTemps["no"] = index;
                        insightPassengerTemps["lastName"] = item.lastName;
                        insightPassengerTemps["firstName"] = item.firstName;
                        insightPassengerTemps["middleName"] = item.middleName;
                        insightPassengerTemps["gender"] = item.gender;
                        insightPassengerTemps["nationality"] = item.nationality;
                        insightPassengerTemps["dateOfBirth"] = item.dateOfBirth ? this.dateConverter.toDateFormat(new Date(item.dateOfBirth)) : "";
                        insightPassengerTemps["language"] = item.language;
                        insightPassengerTemps["email"] = item.email;
                        insightPassengerTemps["phone"] = item.phoneNumber;
                        insightPassengerTemps["membership"] = item.memberNumber;
                        insightPassengerTemps["identity"] = item.identityDocumentNumber;
                        insightPassengerTemps["usageTypeCode"] = item.usageTypeCode;
                        insightPassengerTemps["status"] = item.statusCode;
                        insightPassengerTemps["oldstatus"] = item.statusCode;
                        dynamicDataArray.push(insightPassengerTemps);
                        index += 1;
                    });
                    this.individualSearchResults = dynamicDataArray;
                    this.rows = this.individualSearchResults.length;
                    this.loadSuccessEvent.emit(this.rows);
                    this.changeDetector.detectChanges();
                }
            )
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getNumberOfRows(event) {
        this.rows = event.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetector.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                LastName: dat.lastName,
                FirstName: dat.firstName,
                MiddleName: dat.middleName,
                Gender: dat.gender,
                Nationality: dat.dateOfBirth,
                Language: dat.language,
                Email: dat.email,
                Phone: dat.phone,
                Membership: dat.membership,
                Identity: dat.identity,
            }
        ));
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }
    public selectedRow(event) {
        this.selectedItem = true;
        this.itemSelected = event.data;
        this.dataGrid.instance.selectRowsByIndexes(event.rowIndex);
        this.clickEvent.emit(this.itemSelected);
    }
    public onDoubleclick() {
        if (!this.itemSelected) {
            return
        }
        
        this.doubleClickEvent.emit(this.itemSelected);
    }
    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }
    
    public clearSearchResult() {
        this.individualSearchResults = [];
        this.rows = 0;
        this.changeDetector.detectChanges();
    }

    public updateStatus(cell: any, e: any) {
        this.insightPassengerService.updatInsightPassengerStatus(cell.data.individualId, e)
            .subscribe(
                () => {
                    cell.data.status = e;
                    cell.data.oldstatus = e;
                    this.onDisplayAlert.emit(null);
                    this.changeDetector.detectChanges();
                },
                (error) => this.handleUpdateStatusError(cell)
            );
    }

    private handleUpdateStatusError(cell: any) {
        cell.data.status = cell.data.oldstatus;
        this.dataGrid.instance.refresh();
        this.onDisplayAlert.emit(this.ERROR_STATUS_CANNOT_CHANGE);
    }
}
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberHelperService {
    private readonly LOCALES = 'en-US';

    public ZeroToUndefined(value: number): number {
        if (!value) {
            return undefined;
        }
        return value;
    }

    public suppressZero(e) {
        if (e?.value <= 0) {
            return "";
        }
        return e.valueText;
    }

    public formatRevenue(revenue: number) : string {
        if (!revenue) {
            return "0.00";
        }
        return  revenue.toLocaleString(this.LOCALES, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    public suppressNegative(e) {
        if (e?.value < 0) {
            return "";
        }
        return e.valueText;
    }
}
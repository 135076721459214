export const select2CommentType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommentClassification = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const swalDeleteOption = {
    text: "Delete selected record?",
    showCancelButton: true,
    confirmButtonColor: '#886ab5',
    cancelButtonColor: '#aaa',
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    reverseButtons: true
}

export const swalDeleteSuccess = {
    text: "Success deleted record.",
    showConfirmButton: false,
    timer: 1500
}
import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { DocumentDistributionSearchModel } from "src/app/core/models/document-distribution-model/document-distribution-search.model";
import { select2SearchDistributionType, select2SearchDocumentType, select2SearchRecieverOrganisation, select2SearchStatusType } from "../shared/select2-configuration";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { DocumentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model/document-type-reference.model";
import { DistributionTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model/distribution-type-reference.model";

@Component({
    selector: 'op-document-distribution-search-condition',
    templateUrl: './document-distribution-search-condition.component.html',
})
export class DocumentDistributionSearchConditionComponent {
    public blurAfterSelected: boolean = true;
    public focused: boolean;
    public collapsed: boolean;

    public recieverOrganisationOption: any;
    public documentTypeOption: any;
    public distributionTypeOption: any;
    public statusOption: any;

    @Input() filter: DocumentDistributionSearchModel = null;    
    @Input() recieverOrganisations: OrganisationModel[];    
    @Input() documentTypeReferences: DocumentTypeReferenceModel[];    
    @Input() distributionTypeReferences: DistributionTypeReferenceModel[];    
    @Input() statusReferences: StatusReferenceModel[];    
    @Output() searchClick = new EventEmitter();
    @Output() clearSearchFilter = new EventEmitter();

    constructor () {
        this.recieverOrganisationOption = select2SearchRecieverOrganisation;
        this.documentTypeOption = select2SearchDocumentType;
        this.distributionTypeOption = select2SearchDistributionType;
        this.statusOption = select2SearchStatusType;
    }

    public clearCondition() {
        this.clearSearchFilter.emit();
    }

    public searchDocumentDistribution() {
        this.searchClick.emit(this.filter);
    }

    public getConditionValue(): DocumentDistributionSearchModel {
        return this.filter;
    }

    public onValueChange(value: any, fieldName: string) {
        this.filter[fieldName] = value;
    }

    
    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchDocumentDistribution();
                break;
            default:
                break;
        }
    }
}
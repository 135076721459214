import { Injectable } from '@angular/core';
import { arraysAreNotAllowedMsg } from '@ngrx/store/src/models';
import { BehaviorSubject } from 'rxjs';
import {
    CalendarTypeModel,
    DateTypeModel,
    OperatorModel,
    TimeZoneModel
} from 'src/app/core/models/merchandizing-config';

import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';

@Injectable()
export class CalendarDataService {

    public getCalendarData(config, calendarValidityReference$: BehaviorSubject<CalendarValidityReferenceModel[]>,
        dateTimeDimensionReference$: BehaviorSubject<DateTimeDimensionReferenceModel[]>,
        conditionReference$: BehaviorSubject<ConditionReferenceModel[]>) {
        //Map DB With JSON
        let values = new Array();
        if (calendarValidityReference$.value) {
            var filterCalendar = config.types.filter(x => x.typeCode == 'calendar');
            if (filterCalendar.length != 0) {
                var dateTypes = filterCalendar[0].dateType;
                for (let dateType of dateTypes) {
                    var filterDateTypeDB = calendarValidityReference$.value.filter(x => x.calendarValidityCode.toLowerCase() == dateType.dateTypeCode.toString().toLowerCase()); // find in DB
                    if (filterDateTypeDB.length != 0) {
                        let value = new DateTypeModel();
                        value.dateTypeCode = filterDateTypeDB[0].calendarValidityCode;
                        value.dateTypeName = filterDateTypeDB[0].calendarValidityName;
                        let calendarTypeValues = new Array();
                        if (dateTimeDimensionReference$.value) {
                            var calendarTypes = dateType.calendarType;
                            for (let calendarType of calendarTypes) {
                                var filterCalendarTypeDB = dateTimeDimensionReference$.value.filter(x => x.dateTimeDimensionCode.toLowerCase() == calendarType.calendarTypeCode.toLowerCase()); // find in DB
                                if (filterCalendarTypeDB.length != 0) {
                                    let valueCalendarType = new CalendarTypeModel();
                                    valueCalendarType.calendarTypeCode = filterCalendarTypeDB[0].dateTimeDimensionCode;
                                    valueCalendarType.calendarTypeName = filterCalendarTypeDB[0].dateTimeDimensionName;
                                    valueCalendarType.timeZone = calendarType.timeZone;
                                    valueCalendarType.operators = this.mappingOperatorWithDB(calendarType.operators, conditionReference$);
                                    valueCalendarType.operatorDefault = this.mappingOperatorDefaultWithDB(calendarType.operatorDefault, conditionReference$);
                                    valueCalendarType.min = calendarType.min;
                                    valueCalendarType.max = calendarType.max;
                                    calendarTypeValues.push(valueCalendarType);
                                }
                            }
                            value.calendarType = calendarTypeValues;
                        }
                        values.push(value);
                    }
                }
            }
        }
        return values;
    }

    private mappingOperatorWithDB(operators: OperatorModel[], conditionReference$: BehaviorSubject<ConditionReferenceModel[]>): OperatorModel[] {
        let operatorReturn: OperatorModel[] = new Array();
        if (conditionReference$.value) {
            for (let operator of operators) {
                var filterDB = conditionReference$.value.filter(x => x.conditionCode == operator.operatorCode)
                if (filterDB.length > 0) {
                    let value = <OperatorModel>{};
                    value.operatorCode = filterDB[0].conditionCode;
                    value.operatorName = filterDB[0].conditionName;
                    value.weekday = operator.weekday;
                    operatorReturn.push(value);
                }
            }
        }
        return operatorReturn;
    }

    private mappingOperatorDefaultWithDB(operatorDefault, conditionReference$: BehaviorSubject<ConditionReferenceModel[]>): string {
        let operatorDefaultReturn: string;
        if (conditionReference$.value) {
            var filterDB = conditionReference$.value.filter(x => x.conditionCode == operatorDefault)
            if (filterDB.length > 0) {
                operatorDefaultReturn = operatorDefault;
            }
        }
        return operatorDefaultReturn;
    }

    public getCalendarType(ctl, calendarTypeOption, dateType$: BehaviorSubject<DateTypeModel[]>, inhirit: boolean = false) {
        var filterDateType = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
        if (filterDateType.length != 0) {
            ctl.get('calendarTypeData').setValue(filterDateType[0].calendarType);
            var filterplaceHolder = filterDateType[0].calendarType.filter(x => x.calendarTypeCode == "");
            if (filterplaceHolder.length != 0) {
                calendarTypeOption.placeholder = filterplaceHolder[0].calendarTypeName;
            }
            else {
                if (!inhirit) {
                    ctl.get('calendarType').setValue(filterDateType[0].calendarType[0].calendarTypeCode);
                }
            }
        }
    }

    public getCalendarTypeData(ctl, calendarTypeOption, dateType$: BehaviorSubject<DateTypeModel[]>) {
        var filterDateType = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
        if (filterDateType.length != 0) {
            ctl.get('calendarTypeData').setValue(filterDateType[0].calendarType);
            var filterplaceHolder = filterDateType[0].calendarType.filter(x => x.calendarTypeCode == "");
            if (filterplaceHolder.length != 0) {
                calendarTypeOption.placeholder = filterplaceHolder[0].calendarTypeName;
            }
        }
    }

    public getTimeZone(ctl, timeZoneOption, dateType$: BehaviorSubject<DateTypeModel[]>) {
        var filterDateType = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
        if (filterDateType.length != 0) {
            var filterCalendarType = filterDateType[0].calendarType.filter(x => x.calendarTypeCode == ctl.get('calendarType').value);
            if (filterCalendarType.length != 0) {
                if (filterCalendarType[0].timeZone) {
                    ctl.get('timeZoneDisplay').setValue(true);
                    ctl.get('timeZoneData').setValue(filterCalendarType[0].timeZone);
                    var filterplaceHolder = filterCalendarType[0].timeZone.filter(x => x.timeZoneCode == "");
                    if (filterplaceHolder.length != 0) {
                        timeZoneOption.placeholder = filterplaceHolder[0].timeZoneName;
                    }
                    else {
                        ctl.get('timeZone').setValue(filterCalendarType[0].timeZone[0].timeZoneCode);
                    }
                }
                else {
                    ctl.get('timeZoneDisplay').setValue(false);
                    var timeZone: TimeZoneModel[] = Array();
                    ctl.get('timeZoneData').setValue(timeZone);
                }
            }
        }
    }

    public getCalendarOperator(ctl, calendarOperatorOption, dateType$: BehaviorSubject<DateTypeModel[]>, inherit: boolean = false) {
        var filterDateType = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
        if (filterDateType.length != 0) {
            var filterCalendarType = filterDateType[0].calendarType.filter(x => x.calendarTypeCode == ctl.get('calendarType').value);
            if (filterCalendarType.length != 0) {
                if (filterCalendarType[0].operators) {
                    ctl.get('calendarOperatorData').setValue(filterCalendarType[0].operators);

                    if (filterCalendarType[0].operatorDefault) {
                        //Default from json value
                        if (!inherit) {
                            ctl.get('calendarOperator').setValue(filterCalendarType[0].operatorDefault);
                        }
                    }
                    else {
                        var filterplaceHolder = filterCalendarType[0].operators.filter(x => x.operatorCode == "");
                        if (filterplaceHolder.length != 0) {
                            calendarOperatorOption.placeholder = filterplaceHolder[0].operatorName;
                        }
                        else {
                            if (!inherit) {
                                ctl.get('calendarOperator').setValue(filterCalendarType[0].operators[0].operatorCode);
                            }
                        }
                    }
                    this.setStartDatePlaceHolder(ctl);
                }
            }
        }
    }

    public getShowWeekDays(ctl, dateType$: BehaviorSubject<DateTypeModel[]>) {
        var filterDateType = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
        if (filterDateType.length != 0) {
            var filterCalendarType = filterDateType[0].calendarType.filter(x => x.calendarTypeCode == ctl.get('calendarType').value);
            if (filterCalendarType.length != 0) {
                if (filterCalendarType[0].operators) {
                    var filterOperator = filterCalendarType[0].operators.filter(x => x.operatorCode == ctl.get('calendarOperator').value)
                    if (filterOperator.length != 0) {
                        if (filterOperator[0].weekday) {
                            ctl.get('weekDaysDisplay').setValue(filterOperator[0].weekday);
                        }
                        else {
                            ctl.get('weekDaysDisplay').setValue(false);
                        }
                    }
                }
            }
        }
    }

    public setStartDatePlaceHolder(ctl) {
        let value = ctl.get('calendarOperator').value;
        if (value.toLowerCase() != "<>" && value.toLowerCase() != "><") {
            ctl.get('startDatePlaceHolder').setValue('<Enter Date>');
        }
        else {
            ctl.get('startDatePlaceHolder').setValue('<Enter Start Date>');
        }
    }

    public getWeekDayData(): string[] {
        let weekday: string[] = new Array();
        weekday.push("Monday");
        weekday.push("Tuesday");
        weekday.push("Wednesday");
        weekday.push("Thursday");
        weekday.push("Friday");
        weekday.push("Saturday");
        weekday.push("Sunday");
        return weekday;
    }

    public convertWeekDayData(model: ProductValidityViewModel): string[] {
        let weekday: string[] = new Array();

        if (model.mondayFlag) {
            weekday.push("Monday");
        }

        if (model.tuesdayFlag) {
            weekday.push("Tuesday");
        }

        if (model.wednesdayFlag) {
            weekday.push("Wednesday");
        }

        if (model.thursdayFlag) {
            weekday.push("Thursday");
        }

        if (model.fridayFlag) {
            weekday.push("Friday");
        }

        if (model.saturdayFlag) {
            weekday.push("Saturday");
        }

        if (model.sundayFlag) {
            weekday.push("Sunday");
        }
        return weekday;

    }
}
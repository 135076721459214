import { Injectable } from '@angular/core';
import { Validators, UntypedFormArray } from '@angular/forms';
import { ProductCodeValidationService } from './productcode/productcode-validation.service';
import { ProductTypeValidationService } from './producttype/producttype-validation.service';
import { RouteValidationService } from './route/route-validation.service';
import { LocationValidationService } from './location/location-validation.service';
import { LocationPointValidationService } from './location/location-point-validation.service';
import { CalendarValidationService } from './calendar/calendar-validation.service';
import { OrganisationValidationService } from './organisation/organisation-validation.service';
import { AbstractControl } from '@angular/forms';
import { VehicleValidationService } from './vehicle/vehicle-validation.service';

@Injectable()
export class RuleConfigValidationService {

    constructor(
        private productCodeValidationService: ProductCodeValidationService,
        private productTypeValidationService: ProductTypeValidationService,
        private routeValidationService: RouteValidationService,
        private locationValidationService: LocationValidationService,
        private locationPointValidationService: LocationPointValidationService,
        private calendarValidationService: CalendarValidationService,
        private organisationValidationService: OrganisationValidationService,
        private vehicleValidationService: VehicleValidationService) {
    }

    public findDuplicateAllRow(forms: UntypedFormArray): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (forms.controls.length != 0) {
            let formCurrent = forms.controls[forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);

            for (var i = 0; i <= forms.controls.length - 1; i++) {
                var ctl = forms.controls[i];
                switch (ctl.value.type) {
                    case 'productcode': {
                        returnValue = this.productCodeValidationService.findDuplicateProductCode(forms, ctl, i);
                        if (returnValue) {
                            this.updateValidatorDuplicate(ctl, true);
                        }
                        else {
                            this.updateValidatorDuplicate(ctl, false);
                        }
                        break;
                    }
                    case 'producttype': {
                        returnValue = this.productTypeValidationService.findDuplicateProductType(forms, ctl, i);
                        if (returnValue) {
                            this.updateValidatorDuplicate(ctl, true);
                        }
                        else {
                            this.updateValidatorDuplicate(ctl, false);
                        }
                        break;
                    }
                    case 'route': {
                        returnValue = this.routeValidationService.findDuplicateRoute(forms, ctl, i);
                        if (returnValue) {
                            this.updateValidatorDuplicate(ctl, true);
                        }
                        else {
                            this.updateValidatorDuplicate(ctl, false);
                        }
                        break;
                    }
                    case 'location': {
                        if (ctl.value.locationPointAreas) {
                            switch (ctl.value.locationPointAreas) {
                                case 'region': {
                                    returnValue = this.locationPointValidationService.findDuplicateLocationPointRegion(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'country': {
                                    returnValue = this.locationPointValidationService.findDuplicateLocationPointCountry(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'airport': {
                                    returnValue = this.locationPointValidationService.findDuplicateLocationPointAirport(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'group': {
                                    returnValue = this.locationPointValidationService.findDuplicateLocationPointGroup(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                            }
                        }
                        else {
                            switch (ctl.value.locationAreas) {
                                case 'region': {
                                    returnValue = this.locationValidationService.findDuplicateLocationRegion(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'country': {
                                    returnValue = this.locationValidationService.findDuplicateLocationCountry(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'airport': {
                                    returnValue = this.locationValidationService.findDuplicateLocationAirport(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                                case 'locationgroup': {
                                    returnValue = this.locationValidationService.findDuplicateLocationGroup(forms, ctl, i);
                                    if (returnValue) {
                                        this.updateValidatorDuplicate(ctl, true);
                                    }
                                    else {
                                        this.updateValidatorDuplicate(ctl, false);
                                    }
                                    break;
                                }
                            }
                        }
                        break;
                    }

                    case 'calendar': {
                        switch (ctl.value.calendarType.toLowerCase()) {
                            case "date": {
                                returnValue = this.calendarValidationService.findDuplicateDate(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "datetime": {
                                returnValue = this.calendarValidationService.findDuplicateDateTime(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "time": {
                                returnValue = this.calendarValidationService.findDuplicateTime(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "weekday": {
                                returnValue = this.calendarValidationService.findDuplicateWeekDay(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case 'organisation': {
                        switch (ctl.value.orgType.toLowerCase()) {
                            case "type": {
                                returnValue = this.organisationValidationService.findDuplicateOrganisationType(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "group": {
                                returnValue = this.organisationValidationService.findDuplicateOrganisationGroup(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "role": {
                                returnValue = this.organisationValidationService.findDuplicateOrganisationRole(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                            case "specific": {
                                returnValue = this.organisationValidationService.findDuplicateOrganisationSpecific(forms, ctl, i);
                                if (returnValue) {
                                    this.updateValidatorDuplicate(ctl, true);
                                }
                                else {
                                    this.updateValidatorDuplicate(ctl, false);
                                }
                                break;
                            }
                        }
                        break;
                    }
                    case 'vehicle': {
                        returnValue = this.vehicleValidationService.findDuplicateVehicle(forms, ctl, i);
                        if (returnValue) {
                            this.updateValidatorDuplicate(ctl, true);
                        }
                        else {
                            this.updateValidatorDuplicate(ctl, false);
                        }
                        break;
                    }
                }

                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        return duplicate;
    }


    private updateValidatorDuplicate(formCurrent, required: boolean = false) {
        switch (formCurrent.value.type) {
            case 'productcode': {
                if (required) {
                    formCurrent.get('productCodeDuplicate').setValue(null);
                    formCurrent.get('productCodeDuplicate').setValidators(Validators.required);
                    formCurrent.get('productCodeDuplicate').updateValueAndValidity();
                }
                else {
                    formCurrent.get('productCodeDuplicate').setValue(false);
                    formCurrent.get('productCodeDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('productCodeDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'producttype': {
                if (required) {
                    formCurrent.get('productTypeDuplicate').setValue(null);
                    formCurrent.get('productTypeDuplicate').setValidators(Validators.required);
                    formCurrent.get('productTypeDuplicate').updateValueAndValidity();
                }
                else {
                    formCurrent.get('productTypeDuplicate').setValue(false);
                    formCurrent.get('productTypeDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('productTypeDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'route': {
                if (required) {
                    formCurrent.get('routeDuplicate').setValue(null);
                    formCurrent.get('routeDuplicate').setValidators(Validators.required);
                    formCurrent.get('routeDuplicate').updateValueAndValidity();
                }
                else {
                    formCurrent.get('routeDuplicate').setValue(false);
                    formCurrent.get('routeDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('routeDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'location': {
                if (required) {
                    formCurrent.get('locationDuplicate').setValue(null);
                    formCurrent.get('locationDuplicate').setValidators(Validators.required);
                    formCurrent.get('locationDuplicate').updateValueAndValidity();
                }
                else {
                    formCurrent.get('locationDuplicate').setValue(false);
                    formCurrent.get('locationDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('locationDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'calendar': {
                if (required) {
                    formCurrent.get('calendarDuplicate').setValue(null);
                    formCurrent.get('calendarDuplicate').setValidators(Validators.required);
                    formCurrent.get('calendarDuplicate').updateValueAndValidity();
                    this.calendarValidationService.updateValidatorOverlap(formCurrent, false);
                }
                else {
                    formCurrent.get('calendarDuplicate').setValue(false);
                    formCurrent.get('calendarDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('calendarDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'organisation': {
                if (required) {
                    formCurrent.get('organisationDuplicate').setValue(null);
                    formCurrent.get('organisationDuplicate').setValidators(Validators.required);
                    formCurrent.get('organisationDuplicate').updateValueAndValidity();
                }
                else {
                    formCurrent.get('organisationDuplicate').setValue(false);
                    formCurrent.get('organisationDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('organisationDuplicate').updateValueAndValidity();
                }
                break;
            }
            case 'vehicle': {
                if (required) {
                    formCurrent.get('vehicleDuplicate').setValue(null);
                    formCurrent.get('vehicleDuplicate').setValidators(Validators.required);
                    formCurrent.get('vehicleDuplicate').updateValueAndValidity()
                } else {
                    formCurrent.get('vehicleDuplicate').setValue(false);
                    formCurrent.get('vehicleDuplicate').setValidators(Validators.nullValidator);
                    formCurrent.get('vehicleDuplicate').updateValueAndValidity()
                }
            }
        }
    }

    public findOverlapAllRow(forms: UntypedFormArray): boolean {
        var overlap: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) 
        {
            let formCurrent = forms.controls[i];     
            formCurrent.get('submitted').setValue(true);
            for (var j = i + 1; j <= forms.controls.length - 1; j++) 
            {
                let result = this.compareForm(formCurrent, forms.controls[j]);
                if (result){
                    overlap = true;
                }
            }
        }
        if (!overlap){
            this.calendarValidationService.clearAllOverlap(forms);
        }
        return overlap;
    }

    private compareForm(formCurrent: AbstractControl, ctl: AbstractControl) : boolean {
        if (ctl.value.type == 'calendar') {
            switch (ctl.value.calendarType.toLowerCase()) {
                case "date": {
                   return this.calendarValidationService.findDateOverlap(formCurrent, ctl);
                }
            }
        }
        return false;
    }

    public findDateNotSpecify(forms: UntypedFormArray): boolean {
        for (var i = 0; i <= forms.controls.length - 1; i++) 
        {
            let validity = forms.controls[i].value;    
            if (validity.calendarOperator != "!=") {
                return false;
            } 
        }
        return true;
    }
}

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import { ServiceCategoryMapperService } from './servicecategory-mapper.service';
import { ProductInventoryServiceCateViewModel } from 'src/app/core/models/product-model/transport-model';
import { ServiceCategoryTableView } from './servicecategory-table.view';
import { CalculateHelperService } from 'src/app/core/utils/calculate-helper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { NumberHelperService } from 'src/app/core/utils/number-helper.service';

@Component({
    selector: 'op-servicecategory',
    templateUrl: './servicecategory.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ServiceCategoryMapperService]
})
export class ServiceCategoryComponent implements AfterViewInit {

    public readonly EXPORT_FILE_NAME = "ServiceCategory";
    @Input() id: string;
    @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public serviceCategories: ServiceCategoryTableView[];
    public productInventories: ProductInventoryServiceCateViewModel[];
    private copyItem: ServiceCategoryTableView;
    public selectedItem: ServiceCategoryTableView;
    public focusing: boolean = false
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };
    public suppressZero: any;

    constructor(
        private serviceCategoryMapperService: ServiceCategoryMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private calculateHelperService: CalculateHelperService,
        private numberHelperService: NumberHelperService) {
        this.setCellValueChangeCapacity = this.setCellValueChangeCapacity.bind(this);
        this.setCellValueChangeOBF = this.setCellValueChangeOBF.bind(this);
        this.setCellValueChangeGRPCAP = this.setCellValueChangeGRPCAP.bind(this);
        this.suppressZero = this.numberHelperService.suppressZero.bind(this);
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public fillData(productInventories: ProductInventoryServiceCateViewModel[]) {
        this.clearForm();
        this.productInventories = productInventories;
        this.serviceCategories = this.serviceCategoryMapperService.serviceCategoryModelToViews(productInventories);
        this.changeDetectionRef.detectChanges();
    }

    public clearForm() {
        this.dataGrid.instance.state({});
        this.serviceCategories = [];
        this.productInventories = [];
        this.changeDetectionRef.detectChanges();
    }

    copyClick() {
        if (this.selectedItem) {
            this.copyItem = this.selectedItem;
        }
    }

    pasteClick() {
        if (this.copyItem) {
            this.selectedItem.capacity = this.copyItem.capacity;
            this.selectedItem.ocab = this.copyItem.ocab;
            this.selectedItem.obf = this.copyItem.obf;
            this.selectedItem.au = this.copyItem.au;
            this.selectedItem.nav = this.copyItem.nav;
            this.selectedItem.grpcap = this.copyItem.grpcap;
            this.dataGrid.instance.refresh();
        }
    }

    deleteClick() {
        this.selectedItem.capacity = null;
        this.selectedItem.ocab = null;
        this.selectedItem.obf = null;
        this.selectedItem.au = null;
        this.selectedItem.nav = null;
        this.selectedItem.grpcap = null;
        this.dataGrid.instance.refresh();
    }

    getSegmentText(data) {
        var dataArr = data.displayValue.split(':');
        return dataArr[1];
    }

    public setCellValueChangeCapacity(newData, value, currentRowData) {
        if (value <= 0){
            value = null;
        }
        newData.capacity = value;
        newData.nav = this.calculateHelperService.createNAV(newData.capacity, currentRowData.obf);
        newData.ocab = this.serviceCategoryMapperService.createOcap(newData.capacity, currentRowData.grpcap);
        newData.au = this.serviceCategoryMapperService.createAu(newData.ocab, currentRowData.obf);
    }

    public setCellValueChangeOBF(newData, value, currentRowData) {
        if (value <= 0){
            value = null;
        }
        newData.obf = value;
        newData.au = this.serviceCategoryMapperService.createAu(currentRowData.ocab, newData.obf);
        newData.nav = this.calculateHelperService.createNAV(currentRowData.capacity, newData.obf);
    }

    public setCellValueChangeGRPCAP(newData, value, currentRowData) {
        if (value <= 0){
            newData.grpcap = null;
        }
        newData.grpcap = value;
        newData.ocab = this.serviceCategoryMapperService.createOcap(currentRowData.capacity, newData.grpcap);
        newData.au = this.serviceCategoryMapperService.createAu(newData.ocab, currentRowData.obf);
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
    }

    refreshClick() {
        this.serviceCategories = this.serviceCategoryMapperService.serviceCategoryModelToViews(this.productInventories);
        this.dataGrid.instance.refresh();
        this.changeDetectionRef.detectChanges();
    }    

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Segment: data.segmentName,
            ServiceCategory: data.serviceCategory,
            Capacity: data.capacity,
            OBF: data.obf,
            Ocap: data.ocab,
            AU: data.au,
            NAV: data.nav,
            GrpCap: data.grpcap
        };
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        e.cellElement.css("background-color", e.data.serviceCategoryColor);
    }

    onFocusedCellChanging(e) {
        e.isHighlighted = true;
    }
}
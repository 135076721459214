import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleServiceCategoryView extends PriceRuleConditionBase {
    public priceRuleServiceCategoryId: string;
    public serviceCategoryCode: string;
    public inventoryDimensionCode: string;
    public conditionCode: string;
    public thresholdValue: string;
    public priceRuleAttributeGroupCode: string;
    public dimensionUnitCode: string;
    public children: PriceRuleServiceCategoryView[];
}
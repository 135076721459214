export class ProductInventorySalesBucketViewModel {
	public productIdSegment: string;
	public productNameSegment: string;
	public hierarchyKeySegment: string;
	public hierarchyKey: string;
	public productPointId: string;
	public sortSequenceFrom: number;
	public locationIdFrom: string;
	public locationCodeFrom: string;
	public locationNameFrom: string;
	public sortSequenceTo: number;
	public locationIdTo: string;
	public locationCodeTo: string;
	public locationNameTo: string;
	public productId: string;
	public productName: string;
	public serviceCategoryCode: string;
	public serviceCategoryName: string;
	public salesBucketCode: string;
	public productInventoryIdFirm: string;
	public sellCapacityFirm: number;
	public productDateTimeIdFirmOpen: string;
	public localDateTimeFirmOpen: Date;
	public productDateTimeIdFirmClose: string;
	public localDateTimeFirmClose: Date;
	public productInventoryIdWaitList: string;
	public requestCapacityWaitList: number;
	public productDateTimeIdWaitListOpen: string;
	public localDateTimeWaitListOpen: Date;
	public productDateTimeIdWaitListClose: string;
	public localDateTimeWaitListClose: Date;
	public sortSequenceServiceCate: number;
	public sortSequenceSalesBucket: number;
	public serviceCategoryColor: string;
}
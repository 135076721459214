import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ScheduleCategoriesComponent } from "./schedule-categories/schedule-categories.component";
import { ScheduleContentComponent } from "./schedule-content.component";
import { ScheduleHomeComponent } from "./schedule-home/schedule-home.component";

@NgModule({
    declarations: [
        ScheduleCategoriesComponent,
        ScheduleContentComponent,
        ScheduleHomeComponent
    ],
    imports: [
        AlertBarModule,
        CommonModule,
        FreezingTabModule,
        NgbNavModule,
        OopsCategoriesModule,
        RouterModule,
        UiModule
    ],
    exports: [
        ScheduleContentComponent
    ]
})
export class ScheduleContentModule { }
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Globals } from "src/app/app.global-variable";
import { AuthState } from "src/app/store/auth";
import { selectAuthState } from "src/app/store/auth/auth.selectors";
import { SystemPropertyService } from "./system-services/system-property.service";

@Injectable({
    providedIn: 'root'
})
export class DateTimeFormatService {
    private unsubscribe$ = new Subject();
    constructor(private systemPropertyService: SystemPropertyService,
        private globals: Globals,
        private store: Store<any>) {

    }

    public setGlobalDateTimeFormats() {
        this.store.select(selectAuthState)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (state: AuthState) => {
                    if (state.user) {
                        this.systemPropertyService.getDateTimeSystemProperty()
                            .subscribe(
                                dateTimeSystemProperties => {
                                    if (dateTimeSystemProperties?.length) {
                                        for (let dateTimeSystemProperty of dateTimeSystemProperties) {
                                            this.globals[dateTimeSystemProperty.systemPropertyCode] = dateTimeSystemProperty.propertyText;
                                        }
                                    }
                                }
                            )
                        this.unsubscribe$.next();
                        this.unsubscribe$.unsubscribe();
                    }
                }
            )
    }
}
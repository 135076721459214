export const MERCHANDIZE_REF_CODE = {
    activeStatus: "A",
    attributeGroupCode: "ATTRIBUTE",
    calendarValidityCode: "SERVICE",
    dimensionTypeCode: "ATTRIBUTE",
    orgTypeProviderCode: "AIRLINE",
    orgTypeSupplierCode: "SUPPLIER",
    productCategoryCode: "MERCHANDIZE",
    productLocationTypeCode: "RESTRICTION",
}

export const MERCHANDIZE_GENERAL_KEYS = ['productGroupCode', 'productTypeGroupId', 'draftFlag', 'usageTypCode'];

import { Injectable } from '@angular/core';

@Injectable()
export class OrganisationDataService {

    public getOrgTypeOperator(ctl, config, orgTypeOperatorOption) {
        var filterType = config.types.filter(x => x.typeCode == 'organisation');
        if (filterType.length != 0) {
            var filterOrgType = filterType[0].orgType.filter(x => x.orgTypeCode == ctl.get('orgType').value);
            ctl.get('orgTypeOperatorsData').setValue(filterOrgType[0].operators);

            var filterplaceHolder = filterOrgType[0].operators.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                orgTypeOperatorOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                ctl.get('orgTypeOperators').setValue(filterOrgType[0].operators[0].operatorCode);
            }
        }
    }

    public fillOrgTypeOperator(ctl, config) {
        var filterType = config.types.filter(x => x.typeCode == 'organisation');
        if (filterType.length != 0) {
            var filterOrgType = filterType[0].orgType.filter(x => x.orgTypeCode == ctl.get('orgType').value);
            ctl.get('orgTypeOperatorsData').setValue(filterOrgType[0].operators);
        }
    }

}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { select2HashtagOption } from '../voucher-attribute-configuration';

@Component({
    selector: 'op-voucher-hashtag',
    templateUrl: './voucher-hashtag.component.html'
})
export class VoucherHashtagComponent {
    public focusing: boolean = false;
    public showHashtagRow: boolean = false;
    public hashTagOption = select2HashtagOption;

    @Input() productHashTags: ProductHashTagView[];
    @Output() onHashtagChange = new EventEmitter();

    public add() {
        this.setShowHashtagRow(true);
    }

    public delete() {
        this.setShowHashtagRow(false);
        this.onHashtagChange.emit([]);
    }

    public setShowHashtagRow(value: boolean) {
        this.showHashtagRow = value;
    } 

    public hashtagChange(value) {
        this.onHashtagChange.emit(value);
    }
}
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { TransportOperatingProductServiceCategoryViewModel } from 'src/app/core/models/product-model/transport-model';
import { TabModel } from 'src/app/core/models/tab';
import { selectTabs } from 'src/app/store/tab';
import { OperatingProductCalendarPaggingView } from './operating-product-calendar-pagging.view';
import { OperatingProductCalendarView } from './operating-product-calendar.view';

@Injectable()
export class OperatingProductCalendarMapperService {

    constructor(private store: Store<any>) {}

    public toViews(models: TransportOperatingProductServiceCategoryViewModel[]): OperatingProductCalendarView[] {
        let views: OperatingProductCalendarView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.toView(i, model))
            }
        }
        return views;
    }

    private toView(index: number, model: TransportOperatingProductServiceCategoryViewModel): OperatingProductCalendarView {
        let view = {} as OperatingProductCalendarView;
        view.no = index;
        view.operatingProductId = model.operatingProductId;
        view.operatingProductSeriesId = model.operatingProductSeriesId;
        view.localDate = model.localDateTime;
        view.serviceCategoryCode = model.serviceCategoryCode;
        view.load = this.createLoad(model.booked, model.au);
        view.au = model.au;
        view.sold = model.booked;
        view.status = model.status;
        return view;
    }

    public createLoad(booked: number, au: number): number {
        if (booked == null || au == null || au == 0) {
            return 0;
        }
        return parseFloat(((booked * 100) / au).toFixed(1));
    }

    public getMinDate(operatingProducts: OperatingProductCalendarView[]): Date {
        if (operatingProducts?.length) {
            return operatingProducts[0].localDate;
        }
        return new Date();
    }

    public getMaxDate(operatingProducts: OperatingProductCalendarView[]): Date {
        if (operatingProducts?.length) {
            return operatingProducts[operatingProducts.length - 1].localDate;
        }
        return new Date();
    }

    private monthDiff(minDate: Date, maxDate: Date) {
        var months;
        months = (maxDate.getFullYear() - minDate.getFullYear()) * 12;
        months -= minDate.getMonth();
        months += maxDate.getMonth();
        return months <= 0 ? 0 : months;
    }

    private getMonthName(date: Date): string {
        return date.toLocaleString('en-us', { month: 'long' }).substring(0, 1);
    }

    public getMonthPaggination(minDate: Date, maxDate: Date): OperatingProductCalendarPaggingView[] {
        let monthPaggination: OperatingProductCalendarPaggingView[] = new Array();
        let count = this.monthDiff(minDate, maxDate);
        let index = 1;
        monthPaggination.push(this.getPaggination(index, minDate));
        for (let i = 1; i <= count; i++) {
            ++index;
            minDate.setMonth(minDate.getMonth() + 1);
            monthPaggination.push(this.getPaggination(index, minDate));
        }
        return monthPaggination;
    }

    private getPaggination(index: number, date: Date): OperatingProductCalendarPaggingView {
        let pg = {} as OperatingProductCalendarPaggingView;
        pg.no = index;
        pg.monthName = this.getMonthName(date);
        pg.date = new Date(date.getFullYear(), date.getMonth(), 1);
        return pg;
    }

    public getTabId(): Observable<TabModel[]> {
        return this.store.select(selectTabs).pipe(
            map(tabs => tabs)
        )
    }
}

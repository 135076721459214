import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Helper } from 'src/app/shared/helper/app.helper';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { UiModule } from 'src/app/shared/ui/ui.module';

import { OopsCategoriesModule } from '../../../core/components/categories/oops-categories.module';
import { DetailsModule as PriceConditionDetailsModule } from '../price-conditions/details/details.module';
import { PriceConditionsModule } from '../price-conditions/price-conditions.module';
import { DetailsModule as PriceDimensionDetailsModule } from '../price-dimensions/details/details.module';
import { PriceDimensionsModule } from '../price-dimensions/price-dimensions.module';
import { PriceModule } from '../prices/price.module';
import { PriceRuleDetailModule } from '../rules/price-rule-detail/price-rule-detail.module';
import { PriceRuleSearchModule } from '../rules/price-rule-search/price-rule-search.module';

import { PricingCategoriesComponent } from './pricing-categories/pricing-categories.component';
import { PricingContentComponent } from './pricing-content.component';
import { PricingDashboardComponent } from './pricing-dashboard/pricing-dashboard.component';
import { PricingSearchComponent } from './pricing-search/pricing-search.component';
import { PricingTabsComponent } from './pricing-tabs/pricing-tabs.component';

@NgModule({
  declarations: [
    PricingCategoriesComponent,
    PricingContentComponent,
    PricingDashboardComponent,
    PricingSearchComponent,
    PricingTabsComponent,
  ],
  imports: [
    CommonModule,
    FreezingTabModule,
    NgbNavModule,
    OopsCategoriesModule,
    PriceModule,
    PriceConditionDetailsModule,
    PriceConditionsModule,
    PriceDimensionDetailsModule,
    PriceDimensionsModule,
    PriceRuleDetailModule,
    PriceRuleSearchModule,
    RouterModule,
    UiModule
  ],
  providers: [Helper],
  exports: [PricingContentComponent, PricingTabsComponent],
})
export class PricingContentModule {}
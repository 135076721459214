import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxCheckBoxModule, DxDataGridModule, DxDropDownBoxModule } from "devextreme-angular";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { FeeSearchConditionComponent } from "./fee-search-condition/fee-search-condition.component";
import { FeeSearchTableComponent } from "./fee-search-table/fee-search-table.component";
import { FeeSearchComponent } from "./fee-search.component";

@NgModule({
    declarations: [
        FeeSearchComponent,
        FeeSearchConditionComponent,
        FeeSearchTableComponent
    ],
    exports: [
        FeeSearchComponent
    ],
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ]
})
export class FeeSearchModule {}
export const select2ProductGroup = {
    placeholder: "",
    allowClear: true,
}

export const select2DefaultOption = {
    placeholder: "",
}

export const select2DefaultOptionWithAllowClear = {
    placeholder: "",
    allowClear: true
}

export const select2DeptOption = {
    placeholder: "",
    allowClear: true,
    minimumInputLength: 3,
    ajax: null
}

export const select2ArrOption = {
    placeholder: "",
    allowClear: true,
    minimumInputLength: 3,
    ajax: null
}
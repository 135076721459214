export const PanelConstant = {
    PANEL_ROUTE: "Route",
    PANEL_VALIDITY: "Validity",
    PANEL_SERVICE_CATEGORY: "Service Category",
    PANEL_SALES_BUCKET: "Sales Bucket"
}

export const ValidationConstant = {
    VALIDATION_MENDATORY_CODE: "MANDATORY",
    VALIDATION_MENDATORY_NAME: "Mandatory",
    VALIDATION_CONDITION_CODE: "CONDITION",
    VALIDATION_CONDITION_NAME: "Condition"
}

export const ValidateMessageConstant = {
    ROUTE_DEPARTURE: {
        panel: PanelConstant.PANEL_ROUTE,
        data: "Departure Time",
        validation: ValidationConstant.VALIDATION_MENDATORY_CODE,
        description: "Missing Departure Time"
    },
    ROUTE_ARRIVAL: {
        panel: PanelConstant.PANEL_ROUTE,
        data: "Arrival Time",
        validation: ValidationConstant.VALIDATION_MENDATORY_CODE,
        description: "Missing Arrival Time"
    },
    ROUTE_DAYCHANGE: {
        panel: PanelConstant.PANEL_ROUTE,
        data: "Day Change",
        validation: ValidationConstant.VALIDATION_CONDITION_CODE,
        description: "No day change indicator set"
    },
    VALIDITY_FROMTO: {
        panel: PanelConstant.PANEL_VALIDITY,
        data: "Validity From / Validity To",
        validation: ValidationConstant.VALIDATION_MENDATORY_CODE,
        description: "Missing Validity From Or Validity To"
    },
    VALIDITY_PERIOD: {
        panel: PanelConstant.PANEL_VALIDITY,
        data: "Validity From / Validity To",
        validation: ValidationConstant.VALIDATION_CONDITION_CODE,
        description: "Validity From equal Validity To"        
    },
    VALIDITY_PASTDATE: {
        panel: PanelConstant.PANEL_VALIDITY,
        data: "Service Date",
        validation: ValidationConstant.VALIDATION_CONDITION_CODE,
        description: "Past Service Date"        
    }, 
    VALIDITY_EXISTS: {
        panel: PanelConstant.PANEL_VALIDITY,
        data: "Service Date",
        validation: ValidationConstant.VALIDATION_CONDITION_CODE,
        description: "Operating Product already exists"        
    },        
    SERVICE_CATEGORY: {
        panel: PanelConstant.PANEL_SERVICE_CATEGORY,
        data: "Capacity",
        validation: ValidationConstant.VALIDATION_MENDATORY_CODE,
        description: "Missing Service Category Capacity"      
    },
    SALES_BUCKET: {
        panel: PanelConstant.PANEL_SALES_BUCKET,
        data: "Capacity",
        validation: ValidationConstant.VALIDATION_CONDITION_CODE,
        description: "Missing Sales Bucket Capacity"     
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { EmailComponent } from 'src/app/core/components/email/email.component';
import { CashbookDetailModel, CashbookDetailView } from 'src/app/core/models/cashbook-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PrintCashbookExportService } from 'src/app/core/utils/exports';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner/loading-notifier';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { PanelCashbookConstant } from '../../../shared';
import { OrderPaymentStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
    selector: 'op-cashbook-detail',
    templateUrl: './cashbook-detail.component.html',
})
export class CashbookDetailComponent implements OnChanges {
    public readonly SPINNER_NAME: string = "cashbookDetails";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    public loadingNotifier = new LoadingNotifier();
    private readonly emptyVallue = "";
    private readonly dateTimeFormatIn = "YYYY/MM/DD H:mm:ss A";
    private readonly dateTimeFormat = "DD/MM/YYYY, H:mm A";
    public pdfDefaultStyle = {
        fontSize: 7
    }
    public result: CashbookDetailView;
    public cashbookPanel = PanelCashbookConstant;
    public focused: boolean = true;
    public focusing: boolean = true;

    @Input() detailCashbook: CashbookDetailModel;
    @Input() showEmailPanel: string;
    @Input() refreshValue: boolean;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[];
    @Output() onShowPanelEmail = new EventEmitter();
    @Output() refreshPanel = new EventEmitter();

    @ViewChild(EmailComponent) EmailComponent: EmailComponent;

    constructor(private stringUtils: StringHelperService,
        private printCashbookExportService: PrintCashbookExportService,
        private focusingService: FocusingService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['detailCashbook']) {
            this.result = this.mapData(this.detailCashbook);
        }

        if (changes['refreshValue']) {
            if (this.refreshValue == true) {
                this.loadingNotifier.show(this.SPINNER_NAME);
            } else {
                this.loadingNotifier.hide(this.SPINNER_NAME);
            }
        }
    }

    private mapData(model: CashbookDetailModel): CashbookDetailView {
        let view: CashbookDetailView = {
            accountableDocumentTypeName: this.stringUtils.undefinedToEmpty(model?.accountableDocumentTypeName),
            documentNumber: this.stringUtils.undefinedToEmpty(model?.documentNumber),
            documentDate: this.stringUtils.undefinedToEmpty(model?.documentDate),
            exchangeRate: this.stringUtils.undefinedToEmpty(model?.exchangeRate),
            externalReference: this.stringUtils.undefinedToEmpty(model?.externalReference),
            formOfPaymentCode: this.stringUtils.undefinedToEmpty(model?.formOfPaymentCode),
            formOfPaymentName: this.stringUtils.undefinedToEmpty(model?.formOfPaymentName),
            ledgerAmount: this.stringUtils.undefinedToEmpty(model?.ledgerAmount),
            allocatedTransactionAmount: this.stringUtils.amountString(model?.allocatedTransactionAmount),
            formOfPaymentTypeCode: this.stringUtils.undefinedToEmpty(model?.formOfPaymentTypeCode),
            formOfPaymentTypeName: this.stringUtils.undefinedToEmpty(model?.formOfPaymentTypeName),
            ledgerId: this.stringUtils.undefinedToEmpty(model?.ledgerId),
            paymentId: this.stringUtils.undefinedToEmpty(model?.paymentId),
            ledgerCurrencyCode: this.stringUtils.undefinedToEmpty(model?.ledgerCurrencyCode),
            orderId: this.stringUtils.undefinedToEmpty(model?.orderId),
            transactionAmount: this.stringUtils.undefinedToEmpty(model?.transactionAmount),
            transactionReference: this.stringUtils.undefinedToEmpty(model?.transactionReference),
            commitBy: this.stringUtils.undefinedToEmpty(model?.commitBy),
            voidDateTime: (model?.voidDateTime) ? this.getFormatDate(model?.voidDateTime) : this.emptyVallue,
            ledgerTransactionCode: this.stringUtils.undefinedToEmpty(model?.ledgerTransactionCode),
            ledgerTransactionName: this.stringUtils.undefinedToEmpty(model?.ledgerTransactionName),
            organisationId: this.stringUtils.undefinedToEmpty(model?.organisationId),
            paymentIndividualName: this.stringUtils.undefinedToEmpty(model?.paymentIndividualName),
            issueDateTime: (model?.issueDateTime) ? this.getFormatDate(model?.issueDateTime) : this.emptyVallue,
            paymentDate: (model?.paymentDate) ? this.getFormatDate(model?.paymentDate) : this.emptyVallue,
            transactionDateTime: (model?.transactionDateTime) ? this.getFormatDate(model?.transactionDateTime) : this.emptyVallue,
            transactionCurrencyCode: this.stringUtils.undefinedToEmpty(model?.transactionCurrencyCode),
            sumLedgerAmount: this.stringUtils.amountString(model?.sumLedgerAmount),
            chargeItems: model?.chargeItems,
            organisationLegalName: this.stringUtils.undefinedToEmpty(model?.organisationLegalName),
            accountableDocumentTypeCode: this.stringUtils.undefinedToEmpty(model?.accountableDocumentTypeCode),
            paymentOrganisationName: this.stringUtils.undefinedToEmpty(model?.paymentOrganisationName),
        }
        return view;
    }

    getFormatDate(date) {
        if (!date) {
            return this.emptyVallue;
        }
        return moment(date, this.dateTimeFormatIn).format(this.dateTimeFormat);
    }

    public onPrint(e) {
        this.printCashbookExportService.printCashbookDeail(this.result);
    }

    public showPanelEmail() {
        if (this.focused) {
            this.focused = false;
            this.focusingService.focus(this.EmailComponent?.focusingDirective);
        } else {
            this.focused = this.focusing;
        }
        this.onShowPanelEmail.emit(this.result);
    }

    public refreshTable() {
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.refreshPanel.emit(this.cashbookPanel.DetailPanel);
    }

    findHighlightColorForOrderPaymentStatus(dataSet: OrderPaymentStatusReferenceModel[], value: string): string {
        const code = dataSet.find(s => s.orderPaymentStatusName == value)?.highlightColor
        return code
    }
}
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PrintExportService {

    public print(exportContent: any, printFontSize: string = "") {
        const printFormSize: string = "left=50000,top=50000,width=0,height=0";

        if (exportContent && exportContent.length > 0) {
            var divElements = this.GenerateHTML(exportContent, printFontSize);
            
            var num;
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();
            var printWindow = window.open(num, windowName, printFormSize);

            printWindow.document.write(divElements);

            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        }
    }

    private GenerateHTML(exportContent, printFontSize: string) {

        const borderSetting = "1px solid black";
        const paddingSetting = "0px";
        const borderSpacingSetting = "0px";
        const tableWidth = "100%";

        const fontSizeCss = "font-size: " + printFontSize + "px;";
        const borderCss = "border: 1px solid black;";
        const paddingCss = "padding: 0px;";

        let jsonData: any = exportContent

        let col = [];
        for (let i = 0; i < jsonData.length; i++) {
            for (let key in jsonData[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        let table = document.createElement("table");
        table.style.border = borderSetting;
        table.style.borderSpacing = borderSpacingSetting;
        table.style.width = tableWidth;
        let tr = table.insertRow(-1);
        tr.style.border = borderSetting;
        for (let i = 0; i < col.length; i++) {
            let th = document.createElement("th");
            th.style.cssText = fontSizeCss + borderCss + paddingCss;
            th.innerHTML = col[i];
            tr.appendChild(th);
        }

        for (let i = 0; i < jsonData.length; i++) {

            tr = table.insertRow(-1);
            tr.style.border = borderSetting;
            tr.style.padding = paddingSetting;
            for (let j = 0; j < col.length; j++) {
                let tabCell = tr.insertCell(-1);
                tabCell.style.cssText = fontSizeCss + borderCss + paddingCss;
                tabCell.innerHTML = jsonData[i][col[j]];
            }
        }
        return table.outerHTML;
    }
}
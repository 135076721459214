export const select2Status = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
export const select2UsageType = {
    placeholder: "",
    minimumResultsForSearch: -1
}
export const select2UsageTypeDisabled = {
    placeholder: "",
    minimumResultsForSearch: -1,
    disabled: true
}
export const select2HashtagOption = {
    placeholder: "<Hashtag>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true
}

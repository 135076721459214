import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { VehicleConfigurationSearchCommandModel, VehicleConfigurationModel, VehicleConfigurationAddCommandModel, VehicleConfigurationEditCommandModel } from '../../models/vehicle-model/vehicle-configuration';

@Injectable({
    providedIn: 'root'
})
export class VehicleConfigurationService extends ApiServiceBase {
    private readonly PATH = "/vehicle/vehicleconfiguration"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public search(command: VehicleConfigurationSearchCommandModel): Observable<VehicleConfigurationModel[]> {
        return this.httpPost<VehicleConfigurationModel[]>(this.PATH + '/search', command)
            .pipe(
                map(res => res.body)
            )
    }

    public add(command: VehicleConfigurationAddCommandModel): Observable<VehicleConfigurationModel> {
        return this.httpPost<VehicleConfigurationModel>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public edit(command: VehicleConfigurationEditCommandModel): Observable<VehicleConfigurationModel> {
        return this.httpPut<VehicleConfigurationModel>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public get(id: string): Observable<VehicleConfigurationModel> {
        return this.httpGet<VehicleConfigurationModel>(this.PATH + '/' + id, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByCode(code: string): Observable<VehicleConfigurationModel[]> {
        return this.httpGet<VehicleConfigurationModel[]>(this.PATH + '/code/' + code, null)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatus(vehicleConfigurationId: string, statusCode: string) {
        return this.httpPut(this.PATH + "/updatestatus/" + vehicleConfigurationId + "/" + statusCode, null);
    }


    public copy(command: VehicleConfigurationEditCommandModel): Observable<VehicleConfigurationModel> {
        return this.httpPut<VehicleConfigurationModel>(this.PATH + '/copy', command)
            .pipe(
                map(res => res.body)
            )
    }
}
import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_SPECIALSERVICE_SOLD_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 143,
        salesChannel1Name: "GDS",
        salesChannel2: 361,
        salesChannel2Name: "API",
        salesChannel3: 208,
        salesChannel3Name: "ISE",
        salesChannel4: 353,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 128,
        salesChannel1Name: "GDS",
        salesChannel2: 261,
        salesChannel2Name: "API",
        salesChannel3: 454,
        salesChannel3Name: "ISE",
        salesChannel4: 339,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 308,
        salesChannel1Name: "GDS",
        salesChannel2: 375,
        salesChannel2Name: "API",
        salesChannel3: 187,
        salesChannel3Name: "ISE",
        salesChannel4: 123,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 382,
        salesChannel1Name: "GDS",
        salesChannel2: 142,
        salesChannel2Name: "API",
        salesChannel3: 137,
        salesChannel3Name: "ISE",
        salesChannel4: 425,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 289,
        salesChannel1Name: "GDS",
        salesChannel2: 440,
        salesChannel2Name: "API",
        salesChannel3: 119,
        salesChannel3Name: "ISE",
        salesChannel4: 205,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 447,
        salesChannel1Name: "GDS",
        salesChannel2: 137,
        salesChannel2Name: "API",
        salesChannel3: 483,
        salesChannel3Name: "ISE",
        salesChannel4: 454,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 185,
        salesChannel1Name: "GDS",
        salesChannel2: 284,
        salesChannel2Name: "API",
        salesChannel3: 476,
        salesChannel3Name: "ISE",
        salesChannel4: 147,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 475,
        salesChannel1Name: "GDS",
        salesChannel2: 357,
        salesChannel2Name: "API",
        salesChannel3: 163,
        salesChannel3Name: "ISE",
        salesChannel4: 129,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 119,
        salesChannel1Name: "GDS",
        salesChannel2: 130,
        salesChannel2Name: "API",
        salesChannel3: 113,
        salesChannel3Name: "ISE",
        salesChannel4: 271,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 448,
        salesChannel1Name: "GDS",
        salesChannel2: 273,
        salesChannel2Name: "API",
        salesChannel3: 178,
        salesChannel3Name: "ISE",
        salesChannel4: 406,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 298,
        salesChannel1Name: "GDS",
        salesChannel2: 271,
        salesChannel2Name: "API",
        salesChannel3: 427,
        salesChannel3Name: "ISE",
        salesChannel4: 212,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 146,
        salesChannel1Name: "GDS",
        salesChannel2: 364,
        salesChannel2Name: "API",
        salesChannel3: 280,
        salesChannel3Name: "ISE",
        salesChannel4: 267,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 113,
        salesChannel1Name: "GDS",
        salesChannel2: 141,
        salesChannel2Name: "API",
        salesChannel3: 364,
        salesChannel3Name: "ISE",
        salesChannel4: 390,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 335,
        salesChannel1Name: "GDS",
        salesChannel2: 305,
        salesChannel2Name: "API",
        salesChannel3: 276,
        salesChannel3Name: "ISE",
        salesChannel4: 296,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 369,
        salesChannel1Name: "GDS",
        salesChannel2: 134,
        salesChannel2Name: "API",
        salesChannel3: 377,
        salesChannel3Name: "ISE",
        salesChannel4: 467,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 402,
        salesChannel1Name: "GDS",
        salesChannel2: 222,
        salesChannel2Name: "API",
        salesChannel3: 289,
        salesChannel3Name: "ISE",
        salesChannel4: 396,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 326,
        salesChannel1Name: "GDS",
        salesChannel2: 384,
        salesChannel2Name: "API",
        salesChannel3: 384,
        salesChannel3Name: "ISE",
        salesChannel4: 431,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 418,
        salesChannel1Name: "GDS",
        salesChannel2: 243,
        salesChannel2Name: "API",
        salesChannel3: 305,
        salesChannel3Name: "ISE",
        salesChannel4: 304,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 348,
        salesChannel1Name: "GDS",
        salesChannel2: 177,
        salesChannel2Name: "API",
        salesChannel3: 262,
        salesChannel3Name: "ISE",
        salesChannel4: 495,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 290,
        salesChannel1Name: "GDS",
        salesChannel2: 116,
        salesChannel2Name: "API",
        salesChannel3: 207,
        salesChannel3Name: "ISE",
        salesChannel4: 320,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 325,
        salesChannel1Name: "GDS",
        salesChannel2: 433,
        salesChannel2Name: "API",
        salesChannel3: 185,
        salesChannel3Name: "ISE",
        salesChannel4: 154,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 137,
        salesChannel1Name: "GDS",
        salesChannel2: 342,
        salesChannel2Name: "API",
        salesChannel3: 212,
        salesChannel3Name: "ISE",
        salesChannel4: 390,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 233,
        salesChannel1Name: "GDS",
        salesChannel2: 447,
        salesChannel2Name: "API",
        salesChannel3: 205,
        salesChannel3Name: "ISE",
        salesChannel4: 132,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 226,
        salesChannel1Name: "GDS",
        salesChannel2: 276,
        salesChannel2Name: "API",
        salesChannel3: 121,
        salesChannel3Name: "ISE",
        salesChannel4: 206,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 498,
        salesChannel1Name: "GDS",
        salesChannel2: 451,
        salesChannel2Name: "API",
        salesChannel3: 360,
        salesChannel3Name: "ISE",
        salesChannel4: 292,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 363,
        salesChannel1Name: "GDS",
        salesChannel2: 201,
        salesChannel2Name: "API",
        salesChannel3: 499,
        salesChannel3Name: "ISE",
        salesChannel4: 125,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 241,
        salesChannel1Name: "GDS",
        salesChannel2: 466,
        salesChannel2Name: "API",
        salesChannel3: 321,
        salesChannel3Name: "ISE",
        salesChannel4: 195,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 329,
        salesChannel1Name: "GDS",
        salesChannel2: 306,
        salesChannel2Name: "API",
        salesChannel3: 146,
        salesChannel3Name: "ISE",
        salesChannel4: 276,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 305,
        salesChannel1Name: "GDS",
        salesChannel2: 306,
        salesChannel2Name: "API",
        salesChannel3: 203,
        salesChannel3Name: "ISE",
        salesChannel4: 122,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 137,
        salesChannel1Name: "GDS",
        salesChannel2: 185,
        salesChannel2Name: "API",
        salesChannel3: 446,
        salesChannel3Name: "ISE",
        salesChannel4: 281,
        salesChannel4Name: "OWN"
    }
]

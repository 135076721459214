import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";

import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from 'src/app/shared/ui/ui.module';

import { IndividualSearchTableComponent } from './individual-search-table.component';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';

@NgModule({
    declarations: [
        IndividualSearchTableComponent
    ],
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        FormsModule,
        MatIconModule,
        NgbNavModule,
        StatusColorDropdownModule,
        Select2Module,
        UiModule
    ],
    exports: [
        IndividualSearchTableComponent
    ]
})
export class IndividualSearchTableModule { }
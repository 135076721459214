import { Component, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, Input, AfterViewInit, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { DxDataGridComponent } from 'devextreme-angular';
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { ACTION_HANDLER_STATUS } from 'src/app/shared/ui/actionbar/actionbar-constant';
import { MerchandizeModeHelperService } from '../../../shared/merchandize-mode-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { MerchandizeSearchTableView } from '../shared/merchandize-search-table.view';

@Component({
    selector: 'op-merchandize-search-table',
    templateUrl: './merchandize-search-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        MerchandizeModeHelperService
    ]
})
export class MerchandizeSearchTableComponent extends DatatableComponentBase implements OnChanges, AfterViewInit {

    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    @Input() actionHandlerMode: string;
    @Input() actionBarHandler = new ActionBarHandlerModel();
    @Input() productSearchResults: MerchandizeSearchTableView[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSelected = new EventEmitter<any>();
    @Output() onNew = new EventEmitter();
    @Output() onCopy = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter();

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public rows = 0;

    public focusing = false;
    public selectedItem: any;
    public disableCopy: boolean = true;

    exportData: any;
    header: string[];

    constructor(
        private changeDetectionRef: ChangeDetectorRef,
        private merchandizeModeHelperService: MerchandizeModeHelperService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productSearchResults']) {
            this.selectedItem = null;
            this.onSelected.emit(this.selectedItem);
            this.rows = this.productSearchResults?.length ?? 0;
        }
        if (changes['actionBarHandlerState']) {
            this.setActionBarMode(this.actionHandlerMode);
        }
    }

    private setActionBarMode(mode: string = ACTION_HANDLER_STATUS.none) {
        let model = this.merchandizeModeHelperService.getActionBarHandlerModel(mode);
        this.actionBarHandler = model;
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    doubleClick(item) {
        this.onEdit.emit(item.key);
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
        this.checkDisableCopy();
        this.onSelected.emit(this.selectedItem);
        this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.selected : ACTION_HANDLER_STATUS.none);
    }

    newItem() {
        this.onNew.emit();
    }

    editItem() {
        if (this.selectedItem) {
            this.focusing = false;
            this.onEdit.emit(this.selectedItem);
        }
    }

    deleteItem() {
        if (this.selectedItem && this.selectedItem.statusCode != 'D') {
            this.onDelete.emit(this.selectedItem);
        }
    }

    copyItem() {
        if (this.selectedItem) {
            this.onCopy.emit(this.selectedItem);
        }
    }

    refreshTable() {
        this.selectedItem = null;
        this.changeDetectionRef.detectChanges();
        this.onRefresh.emit();
    }

    // TODO: Move to parent
    checkDisableCopy() {
        this.disableCopy = !this.selectedItem || this.selectedItem?.statusCode == 'D' ||
            !(this.selectedItem?.usageTypeCode == this.USAGETYPE_TEMPLATE || this.selectedItem?.usageTypeCode == this.USAGETYPE_FILTER);
        this.changeDetectionRef.detectChanges();
    }


    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    private mapExportData(filteredData: any[]) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Name: dat.name,
                Group: dat.productGroupName,
                Type: dat.productTypeName,
                UsageType: dat.usageTypeName,
                CommitDateTime: dat.commitDateTime ?? null,
                CommitBy: dat.commitBy ?? null,
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReferences.filter(dat => dat.statusCode == statusCode)[0]?.displayName;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = this.mapExportData(filteredData);
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }
}
import {
    ChangeDetectorRef,
    Directive,
} from '@angular/core';
import {
    Observable,
    Subscriber
} from 'rxjs';

import { OrderDetailServices } from 'src/app/core/services/order-services';
import {
    OrderDetailProductListView,
    OrderDetailProductCommentView,
    OrdersMapperService,
    ProductGroupCodeConstant,
    ProductCategoryConstant,
} from '../../../shared';

import {
    OrderProductModel
} from '../../../../../core/models/order-model';

import { OrderDetailProductList } from "./order-detail-product-list";
import { OrderDetailProductListDeclarationBase } from "./order-detail-product-list-declaration-base";

@Directive()
export abstract class OrderDetailProductListBase extends OrderDetailProductListDeclarationBase implements OrderDetailProductList {

    constructor(protected changeDetection: ChangeDetectorRef,
        protected orderDetailService: OrderDetailServices,
        protected mapperService: OrdersMapperService) {
        
        super();
    }

    public processServiceCallResult(orderProducts: OrderProductModel[], observer: Subscriber<boolean>) {
        throw new Error('Method not implemented.');
    }

    public bindProductData(orderProducts: OrderProductModel[]) {
        throw new Error('Method not implemented.');
    }

    public getUnderlineClass(index: number): string {
        if (index == 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public startLoading() {
        this.loadingNotifier.show(this.SPINNER_NAME);
    }

    public stopLoading() {
        this.loadingNotifier.hide(this.SPINNER_NAME);
    }

    public loadIndividualOrderProduct(orderId: string, partnerOrderId: string, orderLock: boolean): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getIndividualOrderDetailProduct(orderId, partnerOrderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    public loadOrderProductFromOrder(parentOrderId: string, orderId: string, orderLock: boolean): Observable<boolean> {

        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductsByOrder(parentOrderId, orderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }

    public loadOrderProductSearchAtOrderItems(parentOrderId: string, orderId: string, orderLock: boolean): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId) {
                observer.next(false);
                observer.complete();
                return;
            }
            this.orderLock = orderLock;
            this.orderDetailService.getOrderDetailProductsAtOrderItems(parentOrderId, orderId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    public loadOrderProductFromLedger(parentOrderId: string, ledgerId: string, orderLock: boolean): Observable<boolean> {
        if (!ledgerId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductsFromLedger(parentOrderId, ledgerId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }

    public loadProductChildren(parentOrderId: string, productOrderId: string, orderLock: boolean): Observable<boolean> {
        if (!productOrderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductChildren(parentOrderId, productOrderId, this.changeSessionId)
                .subscribe(
                    (result: OrderProductModel[]) => this.processServiceCallResult(result, observer)
                );
        });
    }
    
    public countRecords(orderProducts: OrderProductModel[]): number {
        if (!orderProducts) {
            return 0;
        }
        return orderProducts.length;
    }
    public commentExist(comments: OrderDetailProductCommentView[]) {

        if (!comments || comments.length == 0) {
            return false;
        }
        return true;
    }

    public showWhenSeat(productGroupCode: string) {
        return ProductGroupCodeConstant.Seat === productGroupCode;
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public seatProduct(orderProduct: OrderDetailProductListView): boolean {
        if (ProductGroupCodeConstant.Seat == orderProduct?.productGroupCode) {
            return true;
        }       
        return false;
    }
    public getProductNumberDisplay(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Air) {
            if (orderProduct?.ownerProviderCode && orderProduct?.ownerProviderNumber) {
                return `${orderProduct?.ownerProviderCode} ${orderProduct?.ownerProviderNumber}`;
            }
            return `${orderProduct?.providerCode} ${orderProduct?.providerNumber}`;
        }
        return orderProduct.productName;
    }
    public getProductNumberLabel(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Air) {
            return "Product Number";
        }
        return "Product Name";
    }
    public getProductDateLabel(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Seat) {
            return "Seat Number";
        }
        return "Date";
    }
    public getProductDateInformation(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productGroupCode === ProductGroupCodeConstant.Seat) {
            return orderProduct?.seatNumber;
        }
        return orderProduct?.date;
    }
    public hideProductDateWhenNotExist(orderProduct: OrderDetailProductListView) {
        const productDate = this.getProductDateInformation(orderProduct);
        if (!productDate || productDate.length == 0) {
            return true;
        }
        return false;
    }

    public hideProductAncillaryNumberOfUnit(orderProduct: OrderDetailProductListView) {
        if (orderProduct.productCategoryCode == ProductCategoryConstant.Ancillary 
                && orderProduct.productGroupCode != ProductGroupCodeConstant.Seat) {
            return false;
        }
        return true;
    }

    public hideServiceCategoryDateWhenNotExist(orderProduct: OrderDetailProductListView) {
        
        if (!orderProduct?.serviceCategoryName || orderProduct?.serviceCategoryName.length == 0) {
            return true;
        }
        return false;
    }

    public displaySeatInformation(orderProduct: OrderDetailProductListView): boolean {
        if (!orderProduct.seatNumber || orderProduct.seatNumber.length == 0) {
            return false;
        }
        return true;
    }
    public freeTextExist(orderProduct: OrderDetailProductListView) {
        if (!orderProduct?.freetext || orderProduct.freetext.length == 0) {
            return false;
        }
        return true;
    }
    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    changeListView() {
        this.onChangeListView.emit();
    }

    public getAmountValue(amount: string) {
        if (!amount || Number(amount) === 0) {
            return '';
        }
        return amount;
    }
    
    public getAmountValueByStatusCode(amount: string, orderProduct: OrderDetailProductListView) {
        if (!this.PAID_STATUS.includes(orderProduct?.orderPaymentStatusCode)) {
            return '';
        }

        if (!amount || Number(amount) === 0) {
            return '';
        }

        return amount;
    }

    public getPaidAmountColor(orderProduct: OrderDetailProductListView): string {
        if (Number(orderProduct?.productAmount) == Number(orderProduct?.paymentAmount)) {
            return 'paid-amount-fully-paid'
        }
        return '';
    }

    public getOutstandingBalanceColor(orderProduct: OrderDetailProductListView): string {
        let outstandingBalance = Number(orderProduct?.outstandingBalance.replace(',',''));
        if (outstandingBalance > 0) {
            return 'color-danger-500';
        } else if (outstandingBalance < 0) {
            return 'outstanding-amount-refund'
        }
        return '';
    }

    public getDateChangeText(dayChange: number) {
        if (!dayChange || dayChange == 0) {
            return "";
        }

        let dayChangeDisplay = dayChange > 0 ? '+' + dayChange : dayChange;
        let dayText = `day${Math.abs(dayChange) == 1 ? "" : "s"} `;
        return `${dayChangeDisplay} ${dayText}`
    }

    protected fillNumberOfItems(views: OrderDetailProductListView[]) {
        if (!views || views.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = views.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
    public hideFlightRowWhenNotExist(orderDetailProduct: OrderDetailProductListView): boolean {

        if (!orderDetailProduct) {
            return false;
        }

        if (!orderDetailProduct.departureCode && !orderDetailProduct.arrivalCode) {
            return true;
        }
        return false;
    }
    public hideMonitoryRowWhenNotExist(orderDetailProduct: OrderDetailProductListView): boolean {

        if (!orderDetailProduct) {
            return false;
        }

        if (!orderDetailProduct.currencyCode 
            && (!orderDetailProduct.paymentAmount || Number(orderDetailProduct.paymentAmount) == 0)
            && (!orderDetailProduct.productAmount || Number(orderDetailProduct.productAmount) == 0)
            && (!orderDetailProduct.outstandingBalance || Number(orderDetailProduct.outstandingBalance) == 0)) {
            return true;
        }
        return false;
    }
}
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { OperatingProductCalendarViewComponent } from "./calendar/operating-product-calendar.component";
import { OperatingProductListViewComponent } from "./listview/operating-product-listview.component";

@Component({
    selector: 'op-operating-product',
    templateUrl: './operating-product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class OperatingProductComponent implements OnChanges {
    @Input() productId: string;
    @Input() refresh: boolean;
    @Input() fullView: boolean;
    @Input() serviceCates: ProductAttributeViewModel[];
    @Input() routeName: string;
    @ViewChild(OperatingProductListViewComponent) listViewComponent: OperatingProductListViewComponent;
    @ViewChild(OperatingProductCalendarViewComponent) calendarViewComponent: OperatingProductCalendarViewComponent;
    public listView: boolean = false;
    constructor() { }

    ngOnChanges(changes: SimpleChanges): void { }

    public changeToCalendar() {
        this.listView = false;
    }

    public changeToListView() {
        this.listView = true;
    }
}
export const select2OperatorRestrictionProductType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2ProductCat = {
    placeholder: "<Product Category>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2ProductGroup = {
    placeholder: "<Product Group>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2ProductGroupType = {
    placeholder: "<Product Type Group>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}
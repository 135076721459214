import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import Swal from 'sweetalert2'
import { MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';


import { TextView } from '../shared/text.view';
import { TextMapperService } from '../shared/text-mapper.service';

@Component({
    selector: 'op-text-table',
    templateUrl: './text-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TextMapperService]
})
export class TextTableComponent implements OnInit {

    @Input() mediaTexts: TextView[] = new Array();
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);

    @Output() onAdd = new EventEmitter();
    @Output() onEdit = new EventEmitter<TextView>();
    @Output() onDelete = new EventEmitter<TextView>();
    @Output() onSelected = new EventEmitter<TextView>();

    public selected: TextView;
    public pageSize: number = 10;
    
    @ViewChild('dataGridText', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private textMapperService: TextMapperService) {
    }

    ngOnInit(): void {
        this.selected = null;
    }

    click(e) {
        this.selected = e.data;
        this.onSelected.emit(this.selected);
    }

    edit() {
        if (this.selected) {
            this.onEdit.emit(this.selected);
        }
    }

    delete() {
        if (this.selected) {
            this.onDelete.emit(this.selected);
        }
    }

    add() {
        this.onAdd.emit();
    }

    isText(e) {
        if (this.mediaTypeReference$.value){
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == e.data.mediaTypeCode);
            if (filter.length > 0) {
                return filter[0].textOnlyFlag;
            }
        }
        return false;
    }

    displayHTML(htmlText) {
        Swal.fire({
            html: htmlText,
            width: '900px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }


    displayText(text: string) {
        return this.textMapperService.displayTextMediaInGrid(text);
    }
}
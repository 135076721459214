export class TranslationMediaChildView {
    public no: number;
    public mediaId: string;
    public mediaName: string;
    public mediaContentId: string;
    public languageCode: string;
    public languageName: string;
    public mediaTypeCode: string;
    public mediaFile: File;
    public mediaFileBase64: any;
    public mediaFilePreview: File;
    public mediaFilePreviewBase64: any;
    public mediaFileNotFound: boolean;
    public fileChange: boolean;
    public noFile: boolean;
}

export class OrganisationCommentModel {
    public commentId: string;
    public individualId: string;
    public organisationId: string;
    public commentTypeCode: string;
    public commentClassificationCode: string;
    public commentText: string;
    public privateFlag: boolean;
    public warningFlag: boolean;
    public lockedFlag: boolean;
}
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingNotifier } from './loading-notifier';

const SPINNER_HTML = `
    <div class="oops-spinner">
        <img src='../../assets/img/loading.gif' alt="loading" />
        <p>Loading...</p>
    </div>
    `;

const SPINNER_SAVING_HTML = `
    <div class="oops-spinner">
        <img src='../../assets/img/loading.gif' alt="saving" />
        <p>Saving...</p>
    </div>
    `;

const SPINNER_SENDING_HTML = `
    <div class="oops-spinner">
        <img src='../../assets/img/loading.gif' alt="saving" />
        <p>Sending...</p>
    </div>
    `;

const SAVE_HTML = `
    <div class="oops-spinner-complete">
        <img src='../../assets/img/save.gif' alt="saved" />
    </div>
    `;
@Component({
    selector: 'op-loading-spinner',
    template: `
    <ngx-spinner type="line-scale-party"
        [name]="name"
        [fullScreen]="this.fullScreen"
        bdColor="rgba(255,255,255, 0.5)"        
        [template]="this.iconHtml">
    </ngx-spinner>`,
})
export class LoadingSpinnerComponent implements OnInit {

    public iconHtml: string;

    @Input("fullScreen") fullScreen: boolean = true;
    @Input("notifier") notifier = new LoadingNotifier();
    @Input("name") name: string = null;

    public constructor(private changeDetection: ChangeDetectorRef,
        private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        this.notifier.show = (name: string, delay: number = 0) => {
            this.iconHtml = SPINNER_HTML;
            this.showSpinner(name, delay);
        }
        this.notifier.showSaving = (name: string, delay: number = 0) => {
            this.iconHtml = SPINNER_SAVING_HTML;
            this.showSpinner(name, delay);
        }
        this.notifier.showSending = (name: string, delay: number = 0) => {
            this.iconHtml = SPINNER_SENDING_HTML;
            this.showSpinner(name, delay);
        }
        this.notifier.hide = (name: string) => {
            this.spinner.hide(name);
        }
        this.notifier.saveComplate = (name: string) => {
            this.iconHtml = SAVE_HTML;
            this.changeDetection.detectChanges();
            setTimeout(() => {
                this.spinner.hide(name);
            }, 1500);
        }
    }

    private showSpinner(name: string, delay: number) {
        this.spinner.show(name);
        if (delay > 0) {
            setTimeout(() => {
                this.spinner.hide(name);
            }, delay);
        }
    }
}
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, forkJoin } from "rxjs";

import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CountryReferenceService } from "src/app/core/services/system-services";
import { OrderServices } from '../../services/order-services';
import { PhonePrefixSelectionFeatureConstant } from './phone-prefix-selection.constant';

const PHONE_SELECTION_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

@Component({
    selector: 'op-phone-prefix-selection',
    templateUrl: './phone-prefix-selection.component.html',
    providers: [
        CountryReferenceService
    ]
})
export class PhonePrefixSelectionComponent implements OnInit, AfterViewInit {

    public phonePrefixOption = PHONE_SELECTION_OPTION;
    public _value: string | string[];
    public loadSuccess: boolean = false;
    public countryReference$ = new BehaviorSubject<CountryReferenceModel[]>(null);

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input('disabled') disableFlag: boolean = false;
    @Input('feature') feature: string = '';
    @Input('tabIndexNo') tabIndexNo: number = null;
    @Input() classError: string = "";
    @Output()
    dataChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('phonePrefixcontainer', { read: ViewContainerRef }) container: ViewContainerRef;
    @ViewChild('phonePrefixtemplate', { read: TemplateRef }) template: TemplateRef<any>;

    constructor(private countryReferenceService: CountryReferenceService,
        private orderService: OrderServices,
        private changeDetectorRef: ChangeDetectorRef) {
    }
    ngOnInit(): void {
        this.loadCountryReference();
    }

    ngAfterViewInit(): void {
        this.insertChildView();
    }

    insertChildView() {
        this.container?.insert(this.template.createEmbeddedView(null));
        this.onChange(this._value);
    }

    removeChildView() {
        this.container?.clear();
    }

    reloadChildView() {
        this.removeChildView();
        this.insertChildView();
        this.changeDetectorRef.detectChanges();
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    private loadCountryReference() {
        this.loadSuccess = false;
        forkJoin({
            countryReferences: this.countryReferenceService.getCountryReference(),
            productPoints: this.orderService.getAllProductPoint(),
            organisationCountryReference: this.countryReferenceService.getByLogonOrganisation()
        })
            .subscribe(
                ({
                    countryReferences,
                    productPoints,
                    organisationCountryReference
                }) => {
                    if (this.feature == PhonePrefixSelectionFeatureConstant.shopping) {
                        let productPoint = this.mapCountryToProductPoint(countryReferences, productPoints, organisationCountryReference);
                        var allPhonePrefix = productPoint.concat(countryReferences);
                        allPhonePrefix = [...new Map(allPhonePrefix.map(item => [item['countryCode'], item])).values()];
                        this.countryReference$.next(allPhonePrefix);
                    } else {
                        this.countryReference$.next(countryReferences);
                    }
                    this.onChange(this._value)
                    this.reloadChildView();
                    this.loadSuccess = true;
                    this.changeDetectorRef.detectChanges();
                }
            );
    }

    private mapCountryToProductPoint(responseCountry, productPoint, organisationCountryReference) {
        let contryProducts = new Array();
        let country = responseCountry?.find(r => r.countryCode == organisationCountryReference?.countryCode);
        if (country) {
            contryProducts.push(this.addCountry(country));
        }

        productPoint?.forEach(x => {
            let country = responseCountry?.find(r => r.countryCode == x.countryCode);
            if (country != null) {
                contryProducts.push(this.addCountry(country));
            }
        });
        return contryProducts;
    }

    private addCountry(country) {
        let contryProduct = {
            countryCode: country.countryCode,
            countryName: country.countryName,
            countryPhonePrefix: country.countryPhonePrefix
        }
        return contryProduct;
    }

    get disablePrefix() {
        return (!this.loadSuccess) || this.disableFlag
    }
}
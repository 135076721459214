import { HttpClient, HttpParams, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
export abstract class ApiServiceBase {

    private _baseUrl: string;
    private _httpClient: HttpClient;
    private _authService: AuthService;
    constructor(httpClient: HttpClient,
        authService: AuthService) {
        this._baseUrl = environment.apiUrl;
        this._authService = authService;
        this._httpClient = httpClient;
    }

    protected get tenant() {
        return environment?.authentication?.clientId;
    }
    
    protected setBaseUrl(url: string) {
        if (url) {
            this._baseUrl = url;
        }
    }

    protected httpGet<T>(url: string, param: HttpParams, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {

        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.get<T>(this._baseUrl + url,
            { headers, observe: 'response', params: param });
    }

    protected httpPost<T>(url: string, body: any, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {

        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.post<T>(this._baseUrl + url, body, { headers, observe: 'response' });
    }

    protected httpPut<T>(url: string, body: any, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {

        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.put<T>(this._baseUrl + url, body, { headers, observe: 'response' });
    }

    protected httpDelete(url: string, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<any>> {

        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.delete(this._baseUrl + url, { headers, observe: 'response' });
    }

    protected createAuthHttpHeader(authKey: string, optHeaders?: HttpHeaders): HttpHeaders {
        if (authKey) {
            return this.generateAuthHeader(authKey);
        } else {
            let token = this._authService.getToken();
            if (token) {
                return this.generateAuthHeader(token);
            }
            return null;
        }
    }

    private generateAuthHeader(token: string) {
        const AUTH_HEADER: string = 'Authorization';
        const AUTH_TYPE: string = 'Bearer';
        const ORGANISATION_ID: string = 'organisationId'

        const organisationId = this._authService.getSelectedOrganisation();

        let httpHeaders = new HttpHeaders()
            .set(AUTH_HEADER, `${AUTH_TYPE} ${token}`);

        if (organisationId) {
            httpHeaders = httpHeaders.set(ORGANISATION_ID, organisationId);
        }

        return httpHeaders;
    }
	
    protected createAuthHeaderAjax() {
        const AUTH_TYPE: string = 'Bearer';
        return {
            "Authorization"                 : `${AUTH_TYPE} ${this._authService.getToken()}`,
            "organisationId"                : `${this._authService.getSelectedOrganisation()}`
        };
    }	

    protected httpGetFile(url: string, param: HttpParams, authKey?: string, optHeaders?: HttpHeaders) {
        let headers: HttpHeaders;
        headers = this.createAuthHttpHeader(authKey);
        return this._httpClient.get(this._baseUrl + url,
            { headers, observe: 'response',responseType:'blob'});
    }

    protected httpGetAssetFile(url: string, param: HttpParams, authKey?: string, optHeaders?: HttpHeaders) {
        let headers: HttpHeaders;
        headers = this.createAuthHttpHeader(authKey);
        return this._httpClient.get(url,
            { headers, observe: 'response',responseType:'blob'});
    }
}
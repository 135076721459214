import { LanguageTranslationSetViewModel } from "src/app/core/models/language-translation-set-model";
import { PriceRulePriceView } from "../../prices/shared/views";
import { PriceRuleAttributeAndRuleRowView, PriceRuleAttributeView } from "../../rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/views";
import { PriceRuleProductOptionView } from "../../rules/price-rule-detail/inclusive-of-products/shared/views/price-rule-product-option.view";

export class PriceRuleView {
    public priceRuleId: string;
    public parentPriceRuleId: string;
    public priceRuleCode: string;
    public priceRuleName: string;
    public priceRuleDisplayCode: string;
    public priceRuleDisplayName: string;
    public priceRuleTypeCode: string;
    public priceRuleGroupCode: string;
    public priceRuleCategoryCode: string;
    public priceRuleTypeGroupName: string;
    public usageTypeCode: string;
    public isOwnerFilter: boolean;
    public ownerFilterUserAccountId: string;
    public commitDateTime: string;
    public commitBy: string;
    public statusCode: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public priceRuleConcatenate: boolean = false;
    public productEligibleData: PriceRuleAttributeAndRuleRowView[];
    public pointOfSalesData: PriceRuleAttributeAndRuleRowView[];
    public serviceCategoriesSalesBucketsData: PriceRuleAttributeAndRuleRowView[];
    public parentServiceCategoriesSalesBucketsData: PriceRuleAttributeAndRuleRowView[];
    public roundtripDefinitionData: PriceRuleAttributeAndRuleRowView[];
    public attributeData: PriceRuleAttributeAndRuleRowView[];
    public productAttributeData: PriceRuleAttributeAndRuleRowView[];
    public customerData: PriceRuleAttributeAndRuleRowView[];
    public transitStopoverData: PriceRuleAttributeAndRuleRowView[];
    public productOptions: PriceRuleProductOptionView[];
    public formOfPaymentData: PriceRuleAttributeAndRuleRowView[];
    public priceRulePrices: PriceRulePriceView[];
    public baggageAllowances: PriceRuleAttributeView[];
    public languageTranslationSet: LanguageTranslationSetViewModel;
    public children: PriceRuleView[];
}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { VehicleCompositionModel, VehicleTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";

@Injectable()
export class VehicleDataService {
    public getVehicleTypeData(ctl, vehicleTypeReferences$: BehaviorSubject<VehicleTypeReferenceModel[]>) {
        let vehicleGroupCode = ctl.get('vehicleGroupCode').value;
        if (vehicleGroupCode != null) {
            var filterVehicleType = vehicleTypeReferences$.value.filter(x => x.vehicleGroupCode == vehicleGroupCode);
            let vehicleTypeData$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
            vehicleTypeData$.next(filterVehicleType);
            ctl.get('vehicleTypeData').setValue(vehicleTypeData$);
            if (filterVehicleType.length > 0) {
                ctl.get('vehicleTypeCode').setValue(filterVehicleType[0].vehicleTypeCode);
            }
            else {
                ctl.get('vehicleTypeCode').setValue(null);
            }
        }
        else {
            ctl.get('vehicleTypeData').setValue(null);
            ctl.get('vehicleTypeCode').setValue(null);
        }
    }
    public getVehicleCompositionData(ctl, vehicleCompositionReferences$: BehaviorSubject<VehicleCompositionModel[]>) {
        let vehicleTypeCode = ctl.get('vehicleTypeCode').value;
        if (vehicleTypeCode != null) {
            var filterVehicleComposition = vehicleCompositionReferences$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
            let vehicleCompositionData$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
            vehicleCompositionData$.next(filterVehicleComposition);
            ctl.get('vehicleCompositionData').setValue(vehicleCompositionData$);
            if (filterVehicleComposition.length > 0) {
                ctl.get('vehicleCompositionId').setValue(filterVehicleComposition[0].vehicleCompositionId);
            }
            else {
                ctl.get('vehicleCompositionId').setValue(null);
            }
        }
        else {
            ctl.get('vehicleCompositionData').setValue(null);
            ctl.get('vehicleCompositionId').setValue(null);
        }
    }
}
export const dateSelectionCalendarOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    allowClear: true,
    startDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
}

export const selectDistributionOrganisation = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Subscription } from "rxjs";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    selector: 'op-settings',
    templateUrl: './settings-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsManagementComponent {

    private _navigationSubscription: Subscription
    constructor(private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.navigationService.navigate("/main/settings/home", null);
    }


    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
    }
}
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ActionBarDetailHandlerModel } from "./actionbar-detail-handler.model";
import { ActionbarDetailNotifier } from "./actionbar-detail.notifier";

@Injectable()
export class ActionbarDetailService {
    public notifier: ActionbarDetailNotifier = new ActionbarDetailNotifier();

    public updateState(handler: ActionBarDetailHandlerModel) {
        this.notifier.updateState(handler);
    }

    public getCurrentState(): ActionBarDetailHandlerModel {
        return this.notifier.getCurrentState();
    }

    get action$(): Subject<string> {
        return this.notifier.action$;
    }
}
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { takeUntil } from "rxjs/operators";
import {
    CancelButtonModel,
    CopyButtonModel,
    NewButtonModel,
    RefreshButtonModel,
    SaveButtonModel
} from 'src/app/shared/ui/actionbar/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { CashbookDetailModel, CashbookSearchCommandModel, CashbookSearchDetailCommandModel } from 'src/app/core/models/cashbook-model';
import { CashbookService } from 'src/app/core/services/cashbook-services';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { EmailComponent } from 'src/app/core/components/email/email.component';
import { PrintCashbookExportService } from 'src/app/core/utils/exports';
import { EmailFileCommandModel } from 'src/app/core/components/email/shared';
import { LoadingNotifier, LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { PanelCashbookConstant } from '../../shared';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { OrderPaymentStatusReferenceService, OrderSalesStatusReferenceService } from 'src/app/core/services/system-services';
import { OrderPaymentStatusReferenceModel, OrderSalesStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
    selector: 'op-cashbook-detail-content',
    templateUrl: './cashbook-detail-content.component.html',
})
export class CashbookDetailContentComponent implements OnInit, AfterViewInit {
    public readonly SPINNER_NAME: string = "cashbookDetailSpinner";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    private readonly DELAY_TIME_FOCUS = 200;
    readonly searchTabId = 'search';
    readonly detailTabId = 'detail';
    readonly documentTabId = 'document';
    public currentIndex: number = 1;
    public messageSize: number = 20;
    public page: number = 1;
    public maxSize: number = 5;
    public pageSize: number = 1;
    public cancelButton = new CancelButtonModel();
    public copyButton = new CopyButtonModel();
    public newButton = new NewButtonModel();
    public refreshButton = new RefreshButtonModel();
    public saveButton = new SaveButtonModel();
    private unsubscribe$ = new Subject();
    public selectedTab: string;
    public searchResults$ = new BehaviorSubject<CashbookDetailModel>(null);
    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );
    public pagingDataView: PagingDataView[];
    public id: string;
    public documentNumber: string;
    public ledgerId: string;
    public from: string = '';
    public fromName: string = '';
    public subject: string = 'Cashbook detail';
    public showEmailPanel: boolean = false;
    readonly fromFeature = "Cashbook";
    public file: EmailFileCommandModel[];
    public refreshValueChargeItem: boolean;
    public refreshValueDetail: boolean;
    public organisationId: string;
    public organisationCallName: string;
    public paymentDateFrom: string;
    public paymentDateTo: string;
    public cashbookPanel = PanelCashbookConstant;
    public cashbookDetailCommandModel: CashbookSearchDetailCommandModel;
    public otherParameters: any;
    public userSecurity: SecurityGroupSecurityModel;
    public loadingNotifier = new LoadingNotifier();
    public orderPaymentStatusReferences$ = new BehaviorSubject<OrderPaymentStatusReferenceModel[]>(null);
    public orderSalesStatusReference$ = new BehaviorSubject<OrderSalesStatusReferenceModel[]>(null);

    public searchCondition: CashbookSearchCommandModel;

    @ViewChild(EmailComponent) EmailComponent: EmailComponent;
    @ViewChild("emailRef") emailElement: ElementRef;

    constructor(
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        public cashbookService: CashbookService,
        private focusingService: FocusingService,
        private printCashbookExportService: PrintCashbookExportService,
        private loadingSpinner: LoadingSpinnerService,
        private uiHelper: UiHelperService,
        private orderPaymentStatusReferenceService: OrderPaymentStatusReferenceService,
        private orderSalesStatusReferenceService: OrderSalesStatusReferenceService) {
        this.selectedTab = this.detailTabId;
        this.getOrderPaymentStatusReferences();
        this.getOrderSalesStatusReference();
    }

    ngOnInit(): void {
        this.setupCashbookDetail();
    }

    setupCashbookDetail() {
        let param = this.navigationService.getParams();
        this.currentIndex = param.currentIndex;
        this.pagingDataView = param.pagingDataView;
        this.messageSize = param.pagingDataView.length;
        this.id = param.paymentId;
        this.organisationId = param.organisationId;
        this.organisationCallName = param.organisationCallName;
        this.paymentDateFrom = param.paymentDateFrom;
        this.paymentDateTo = param.paymentDateTo;
        this.userSecurity = param?.userSecurity;
        this.searchCondition = param?.searchCondition;
        this.getCashbookDetail(this.id, this.paymentDateFrom ??  this.searchCondition.paymentDateFrom, this.organisationId, this.cashbookPanel.AllPanel);
    }

    getCashbookDetail(paymentId: string, paymentDate: string, organisationId: string, loadPanel: string) {
        this.refeshPanel(loadPanel, true);
        let command: CashbookSearchDetailCommandModel = {
            paymentId: paymentId,
            paymentDate: paymentDate,
            organisationId: organisationId
        };

        this.cashbookService.searchCashbookDetail(command)
            .subscribe(
                (response: CashbookDetailModel) => {
                    this.searchResults$.next(response);
                    this.refeshPanel(loadPanel, false);
                }
            ),
            () => {
                this.refeshPanel(loadPanel, false);

            }
    }

    private refeshPanel(loadPanel: string, showPanel: Boolean) {
        switch (loadPanel) {
            case this.cashbookPanel.AllPanel:
                if (showPanel) {
                    this.loadingSpinner.show();
                } else {
                    this.loadingSpinner.hide();
                }
                break;
            case this.cashbookPanel.DetailPanel:
                if (showPanel) {
                    this.refreshValueDetail = true;
                } else {
                    this.refreshValueDetail = false;
                }
                break;
            case this.cashbookPanel.ChargeItemPanel:
                if (showPanel) {
                    this.refreshValueChargeItem = true;
                } else {
                    this.refreshValueChargeItem = false;
                }
                break;
            default:
                break;
        }
    }

    onActiveIdChange(activeId: string) {
        if (activeId == this.searchTabId) {
            this.back();
        } else if (activeId == this.documentTabId) {
            let paramDocument = {
                organisationId: this.organisationId,
                organisationCallName: this.organisationCallName,
                paymentDateFrom: this.paymentDateFrom,
                paymentDateTo: this.paymentDateTo,
                userSecurity: this.userSecurity,
                searchCondition: this.searchCondition
            }
            this.navigationService.navigate('main/cashbook/document', null, null, paramDocument);
        }
    }

    ngAfterViewInit(): void {
        this.scrollToTop();
        this.createActionBarButton();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private createActionBarButton() {
        this.newButton.enable(this.userSecurity?.newFlag);
        this.copyButton.enable(this.userSecurity?.copyFlag);
        this.saveButton.disable();
        this.cancelButton.disable();
        let actionbarDefaultState = new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        );
        this.actionbarService.updateState(actionbarDefaultState);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back: ;
                this.back();
                break;
            case ACTION_STATUS.cancel: ;
                this.onCancel();
                break;
            case ACTION_STATUS.refresh: ;
                this.onRefresh();
            default:
                break;
        }
    }

    onRefresh() {
        this.getCashbookDetail(this.id, this.paymentDateFrom, this.organisationId, this.cashbookPanel.AllPanel);
    }

    back() {
        let params = {
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate("main/cashbook/cashbook", null, false, params);
    }

    public onPageChange(pageNum: number): void {
        let currentPage = this.pagingDataView.filter(x => x.no == pageNum);
        let paramCommand = currentPage[0].id.split(",");
        let paymentId = paramCommand[0];
        let paymentDate = paramCommand[1];
        let organisationId = paramCommand[2];
        this.getCashbookDetail(paymentId, paymentDate, organisationId, this.cashbookPanel.AllPanel);
    }

    public onShowPanelEmail(event) {
        this.showEmailPanel = true;
        let actionBarHandler = this.actionbarService.getCurrentState();
        actionBarHandler.get(ACTION_STATUS.cancel)?.enable();
        this.actionbarService.updateState(actionBarHandler);
        this.getHtmlForCreateFileAttachEmail(event);

        let cashbookDetail = {
            paymentId: this.id,
            paymentDate: this.paymentDateFrom,
            organisationId: this.organisationId
        };
        this.cashbookDetailCommandModel = cashbookDetail;
        let otherParameter = {
            fileFromFeature: 'cashbookDetail',
            fileName: 'Cashbok Detail',
            cashbookDetailCommand: this.cashbookDetailCommandModel
        };
        this.otherParameters = otherParameter
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.emailElement, 0);
            this.focusingService.focus(this.EmailComponent?.focusingDirective);
        }, this.DELAY_TIME_FOCUS);
    }

    private getHtmlForCreateFileAttachEmail(event) {
        let htmlForCreateFile = this.printCashbookExportService.printHTMLDetail(event);
        let fileExport = [{
            fileName: "cashbookDetail",
            htmlContent: htmlForCreateFile
        }];
        this.file = fileExport;
    }

    public onCancel() {
        this.showEmailPanel = false;
        let actionBarHandler = this.actionbarService.getCurrentState();
        actionBarHandler.get(ACTION_STATUS.cancel)?.disable();
        this.actionbarService.updateState(actionBarHandler);
    }

    public onRefreshPanel(event) {
        this.getCashbookDetail(this.id, this.paymentDateFrom, this.organisationId, event);
    }

    private getOrderPaymentStatusReferences() {
        this.orderPaymentStatusReferenceService.getOrderPaymentStatusReference()
            .subscribe(
                (response: OrderPaymentStatusReferenceModel[]) => {
                    this.orderPaymentStatusReferences$.next(response);
                }
            )
    }

    private getOrderSalesStatusReference() {
        this.orderSalesStatusReferenceService.getOrderSalesStatusReference()
            .subscribe(
                (response: OrderSalesStatusReferenceModel[]) => {
                    this.orderSalesStatusReference$.next(response);
                }
            )
    }
}
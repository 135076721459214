import { Injectable } from '@angular/core';

import {
    ProductInventoryCommandModel,
    ProductInventoryLedgerCommandModel
} from 'src/app/core/models/product-model/product-base-model/product-inventory';
import { MediaHashTagModel } from 'src/app/core/models/reference-model/reference-media-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Injectable()
export class MapperService {

    public productInventoryToModels(valueArray): ProductInventoryCommandModel[] {
        let models: ProductInventoryCommandModel[] = new Array();
        if (valueArray) {
            for (let value of valueArray) {
                if (value.availableUnitOriginal != value.availableUnit) {
                    models.push(this.productInventoryToModel(value));
                }
            }
        }
        return models;
    }

    private productInventoryToModel(value): ProductInventoryCommandModel {
        let model = new ProductInventoryCommandModel();
        model.productInventoryId = value.productInventoryId;
        model.unitCode = value.unitCode;
        model.productInventoryLedgers = this.productInventoryLedgerToModels(value);
        return model;
    }

    private productInventoryLedgerToModels(value): ProductInventoryLedgerCommandModel[] {
        let models: ProductInventoryLedgerCommandModel[] = new Array();
        models.push(this.productInventoryLedgerToModel(value));
        return models;
    }

    private productInventoryLedgerToModel(value): ProductInventoryLedgerCommandModel {
        let model = new ProductInventoryLedgerCommandModel();
        model.ledgerInventoryTypeCode = "CONFIRMED";
        model.inventoryAdjustmentTypeCode = "ADJUST";
        model.availableUnits = value.availableUnit;
        return model;
    }

    public mediaHashTagToSelect2Data(mediaHashTags: MediaHashTagModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let mediaHash of mediaHashTags) {
            let data = new Select2Data();
            data.id = mediaHash.mediaHashTagId
            data.text = mediaHash.mediaHashTagName;
            select2Data.push(data);
        }
        return select2Data;
    }

}

export class CommunicationOtherView {
    public no: number;
    public communicationOtherId: string;
    public communicationOtherTypeCode: string;
    public communicationOtherTypeName: string;
    public organisationId: string;
    public individualId: string;
    public languageCode: string;
    public mailAddress: string;
    public logon: string;
    public passwordCommunication: string;
    public reEnterPassword: string;
    public token: string;
    public commentText: string;
    public primaryCommunication: boolean;
    public communicationLocked: boolean;
    public information: string;
}
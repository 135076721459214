import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import {
    ServiceCategoryReferenceModel,
} from '../../models/reference-model/reference-general-model';
import { AuthService } from '../../authentication/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceCatetgoryReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/servicecategoryreference"
    constructor(
        httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService)
    }

    public getAll(): Observable<ServiceCategoryReferenceModel[]> {
        return this.httpGet<ServiceCategoryReferenceModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}

export const select2OperatorRestrictionLocation = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AreaType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2AreaType1 = {
    placeholder: "<Area>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2SaleOrService = {
    placeholder: "<Location point>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2OperatorLocation = {
    placeholder: "<Select Operator>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2LocationType = {
    placeholder: "<O/D>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2LocationRegionOption = {
    placeholder: "<Location>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2LocationCountryOption = {
    placeholder: "<Location>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2LocationAirportOption = {
    placeholder: "<Location>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    ajax: null
}

export const select2LocationGroupOption = {
    placeholder: "<Location>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
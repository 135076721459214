import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CurrencyReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { StatusReferenceService, UsageTypeReferenceService, CurrencyReferenceService } from 'src/app/core/services/system-services';
import { OrganisationSearchMapperService } from 'src/app/modules/organisation/organisation-content/organisation-search/shared/mapper.service';
import { Helper } from 'src/app/shared/helper/app.helper';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { takeUntil } from "rxjs/operators";
import {
    NewButtonModel,
    CopyButtonModel,
    SaveButtonModel,
    SaveAsButtonModel,
    CancelButtonModel,
    DeleteButtonModel,
    RefreshButtonModel
} from 'src/app/shared/ui/actionbar/models';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { USER_INFORMATION } from 'src/app/modules/auth/shared/constants/auth.constant';
import { CreditAccountOrganisationSearchView } from './credit-account-search-table/credit-account-organisation-search.view';
import { TopUpPaymentComponent } from './top-up-payment/top-up-payment.component';
import { CreditAccountSearchConditionComponent } from './credit-account-search-condition/credit-account-search-condition.component';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { CreditAccountSearchTableComponent } from './credit-account-search-table/credit-account-search-table.component';
import { CreditAccountSearchModel } from 'src/app/core/models/credit-account-model/credit-account-search.model';
import { ActionService } from 'src/app/core/utils/action.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { findCreditAccountSecurity } from '../credit-account-constant';

@Component({
    selector: 'op-credit-account-search',
    templateUrl: './credit-account-search.component.html',
    providers: [
        OrganisationSearchMapperService
    ]
})
export class CreditAccountSearchComponent implements OnInit {
    private readonly FILTER_USAGE_TYPE_CODES = ['DATA', 'TEMPLATE', 'FILTER'];

    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public currencyReferences$ = new BehaviorSubject<CurrencyReferenceModel[]>(null);

    public advanceSearchMode = false;
    public organisationId: string;
    public userOrganisation: OrganisationModel;
    public displayPaymentForm: boolean = false;
    public userSecurity: SecurityGroupSecurityModel;

    private unsubscribe$ = new Subject();

    private actionBarHandler: ActionBarHandlerModel;
    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    @Output() alertBarClick = new EventEmitter();

    @ViewChild(CreditAccountSearchConditionComponent) searchConditionComponent: CreditAccountSearchConditionComponent;
    @ViewChild(CreditAccountSearchTableComponent) searchTable: CreditAccountSearchTableComponent;
    @ViewChild(TopUpPaymentComponent) topUpPaymentComponent: TopUpPaymentComponent;

    constructor(private statusReferenceService: StatusReferenceService,
        public helper: Helper,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private organisationService: OrganisationService,
        private actionbarService: ActionbarService,
        private authService: AuthService,
        private currencyReferenceService: CurrencyReferenceService,
        private actionService: ActionService,
        private navigateService: NavigationService,
        private securityGroupService: SecurityGroupService
    ) {
            const userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION));
            this.organisationId = userInfo.organisationId;
        }

    ngOnInit(): void {
        this.getOrganisationUser();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getCurrencyReference();
    }

    ngAfterViewInit(): void {
        this.securityGroupService.userSecurityGroupSecurity.subscribe(userSecurity => {
            this.userSecurity = findCreditAccountSecurity(userSecurity)
        })
        this.setupActionBar();
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((actionId) => {
                this.actionBarClick(actionId);
            });

        this.actionService.add('SEARCH', null, null)

        const params = this.navigateService.getParams()
        if (!params?.executeFlag || params?.executeFlag === true) {
            this.search();
        }
    }

    private getSessionItem(key: string) {
        return this.authService.sessionStorageGetItem(key)
    }

    private setupActionBar() {
        this.newBtn.disable();
        this.copyBtn.disable();
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.disable();

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )

        this.actionbarService.updateState(this.actionBarHandler);
    }

    private actionBarClick(actionId) {
        switch (actionId) {
            case ACTION_STATUS.refresh:
                this.onRefresh();
                break;
            case ACTION_STATUS.back:
            case ACTION_STATUS.cancel:
                this.gotoFinanceCategory();
                break;
            default:
                break;
        }
    }

    private getOrganisationUser() {
        this.organisationService.searchOrganisationById(this.organisationId).subscribe(
            (response: OrganisationModel) => {
                this.userOrganisation = response;
            }
        );
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    this.usageTypeFilter$.next(responses.filter(it => this.FILTER_USAGE_TYPE_CODES.includes(it.usageTypeCode)));
                }
            )
    }

    private getCurrencyReference() {
        this.currencyReferenceService.getCurrencyReferences()
            .subscribe(
                (response: CurrencyReferenceModel[]) => {
                    this.currencyReferences$.next(response);
                }
            )
    }

    public clearCondition() {
        this.searchTable.organisationData = null;
    }

    public search() {
        this.searchConditionComponent.searchOrganisaion();
    }

    public searchClick(filter: CreditAccountSearchModel) {
        this.searchTable?.advanceSearchOrganisation(filter);
    }

    public onRefresh() {
        let condition = this.searchTable.recentCondition;
        this.searchTable.advanceSearchOrganisation(condition);
        this.searchTable.dataGrid.instance.clearFilter();
    }

    onTopUpClick(data: CreditAccountOrganisationSearchView) {
        this.displayPaymentForm = true;
        this.topUpPaymentComponent.focusTopUp();
        this.topUpPaymentComponent.fillSearchViewToForm(data);
    }

    onAlertBarClick() {
        this.alertBarClick.emit();
    }

    onPaymentSuccess() {
        this.displayPaymentForm = false;
        this.search();
    }

    private gotoFinanceCategory() {
        this.navigateService.navigate('/main/cashbook/home', null, false);
    }
}

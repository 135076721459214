import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { SsimExportCommandModel, StandardScheduleInformationSearchModel } from "../../models/product-model/standard-schedule-information-model";
import { ScheduleQueueSearchModel } from "../../models/schedule-queue-model";

@Injectable({
    providedIn: 'root'
})
export class StandardScheduleInformationService extends ApiServiceBase {
    private readonly PATH = "/schedule/standardScheduleInformations";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getAll(): Observable<StandardScheduleInformationSearchModel[]> {
        return this.httpGet<StandardScheduleInformationSearchModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getSSIMTask(rootScheduleQueueId: string): Observable<ScheduleQueueSearchModel[]> {
        return this.httpGet<ScheduleQueueSearchModel[]>(this.PATH + "/ssim-task/" + rootScheduleQueueId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public exportSSIM(commands: SsimExportCommandModel[]) {
        return this.httpPost(this.PATH, commands);
    }
}
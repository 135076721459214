export const AGE_GROUP_OPARATOR = {
    greater: {
        symbol: '>',
        compare: function(a, b) {
            return a > b;
        }
    },
    greater_than_equal: {
        symbol: '>=',
        compare: function(a, b) {
            return a >= b;
        }
    },
    less_than:  {
        symbol: '<',
        compare: function(a, b) {
            return a < b;
        }
    },
    less_than_equal:  {
        symbol: '<=',
        compare: function(a, b) {
            return a <= b;
        }
    },
    equal: {
        symbol: '=',
        compare: function(a, b) {
            return a == b;
        }
    },
    not_equal: {
        symbol: '<>',
        compare: function(a, b) {
            return a != b;
        }
    }
}
import { Injectable } from '@angular/core';

const NAVIGATOR = window.navigator as any;
@Injectable({
    providedIn: 'root'
})
export class CsvExportTTYService {

    public download(filename: string, message: any) {

        const csvContent: string = this.ConvertTTYToCSV(message);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (NAVIGATOR.msSaveBlob) { // IE 10+
            NAVIGATOR.msSaveBlob(blob, filename + '.csv');
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename + '.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private ConvertTTYToCSV(message: any) {
        let str = '';
        const newLine: string = '\r\n';
        let splitMessages: string[] = message.split('\n');
        splitMessages.forEach(i => {
            str += this.addDoubleQuote(i) + newLine;
        });
        return str;
    }

    private addDoubleQuote(value: string) {
        return '\"' + value + '\"'
    }
}
export class ProductRestrictionLocationViewModel {
    public productRestrictionLocationId: string;
    public productLocationTypeCode: string;
    public productLocationTypeName: string;
    public productLocationPointCode: string;
    public productLocationPointName: string;
    public locationId: string;
    public locationName: string;
    public locationGroupId: string;
    public locationGroupName: string;
    public regionCode: string;
    public regionName: string;
    public countryCode: string;
    public countryName: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public hierarchyKey: string;
    public inherit: boolean;
}
import { Injectable } from '@angular/core';

import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';

import { ProductGroupReferenceModel, ProductGroupUseModel } from '../../models/reference-model/reference-product-model';

@Injectable({
    providedIn: 'root'
})
export class ProductGroupReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/ProductGroups"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getByProductCategory(productCategoryCode) {
        let params = new HttpParams()
            .set("productCategoryCode", productCategoryCode);
        return this.httpGet<any>(this.REFERENCE_PATH, params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getAll() {
        return this.httpGet<any>(this.REFERENCE_PATH, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getProductGroupUse(productCategoryCode: string): Observable<ProductGroupUseModel[]> {
        
        return this.httpGet<ProductGroupUseModel[]>(this.REFERENCE_PATH 
            + '/use/product-categories/'
            + productCategoryCode, 
            null)
            .pipe(
                map(res => res.body)
            )
    }

    public getProductGroupUseByIndividual(individualId: string, categoryCode: string): Observable<ProductGroupUseModel[]> {
        let params = new HttpParams()
            .set('productCategoryCode', categoryCode);
        
        return this.httpGet<ProductGroupUseModel[]>(this.REFERENCE_PATH + "/individuals/" + individualId + "/use", params)
          .pipe(
            map(response => {
              return response.body;
            })
        );
    }

    public getProductGroupInOrderProduct(): Observable<ProductGroupReferenceModel[]> {
        return this.httpGet<ProductGroupReferenceModel[]>(this.REFERENCE_PATH + "/orderProduct", null)
            .pipe(
                map(response => response.body)
            )
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';
import { PaymentLinkEmailCommandModel } from '../../models/payment-models';


@Injectable({
    providedIn: 'root'
})
export class PaymentLinkEmailService extends ApiServiceBase {

    private readonly PAYMENT_PATH = "/payment/paymentlinkemails"

    constructor(
        httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public sendPaymentLinkEmail(command: PaymentLinkEmailCommandModel) {
        return this.httpPost(this.PAYMENT_PATH + "/payment-link", command)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }   
}
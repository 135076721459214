export const ACTION_STATUS = {
    action: 'ACTION',
    edit: 'EDIT',
    delete: 'DELETE',
    document: 'DOCUMENT',
    service: 'SERVICE',
    pay: 'PAY',
    comment: 'COMMENT',
    shopping: 'SHOPPING',
    passport: 'PASSPORT',
    void: 'VOID',
    email: 'EMAIL',
    print: 'PRINT',
    itinerary: 'ITINERARY',
    itineraryWithPrice: 'ITINERARYWITHPRICE',
    orderSummary: 'ORDERSUMMARY',
    download: 'DOWNLOAD',
    visa: 'VISA',
    passportIndiviDual: 'PASSPORTINDIVIDUAL',
    driversLicense: 'DRIVERSLICENSE',
    addIndividual: 'ADDINDIVIDUAL',
    split: 'SPLIT',
    refund: 'REFUND',
    receipt: 'RECEIPT',
    invoice: 'INVOICE',
    creditNote: 'CREDITNOTE'
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from 'src/app/shared/ui/ui.module';

import { PhonePrefixSelectionComponent } from './phone-prefix-selection.component';

@NgModule({
    declarations: [
        PhonePrefixSelectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        NgbNavModule,
        Select2Module,
        UiModule
    ],
    exports: [
        PhonePrefixSelectionComponent
    ]
})
export class PhonePrefixSelectionModule { }
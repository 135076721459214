import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { ActivityCommandModel, ActivityReaccomOrderViewModel, ActivityViewModel } from '../../models/activity-model';

@Injectable({
    providedIn: 'root'
})
export class ActivityService extends ApiServiceBase {
    private readonly PATH = "/activity/activities"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public edit(command: ActivityCommandModel): Observable<ActivityViewModel> {
        return this.httpPut<ActivityViewModel>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public get(id: string): Observable<ActivityViewModel> {
        return this.httpGet<ActivityViewModel>(this.PATH + '/' + id, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByRootOrderId(rootOrderId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/rootorder/' + rootOrderId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatus(activityId: string, statusCode: string) {
        return this.httpPut(this.PATH + '/' + activityId + '/statuscode' + '/' + statusCode, null)
            .pipe(
                map(res => res.body)
            )
    }

    public save(commands: ActivityCommandModel[]) {
        return this.httpPut(this.PATH + '/activitydomain', commands)
            .pipe(
                map(res => res.body)
            )
    }

    public getByTransportId(productId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/transport/' + productId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByProductId(productId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/product/' + productId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByUserId(userId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/user/' + userId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByPriceRuleId(priceRuleId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/pricerule/' + priceRuleId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByPriceId(priceId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/price/' + priceId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByIndividualId(individualId: string): Observable<ActivityViewModel[]> {
        return this.httpGet<ActivityViewModel[]>(this.PATH + '/individual/' + individualId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getActivityReaccomOrder(departureProductId: string, arrivalProductId: string): Observable<ActivityReaccomOrderViewModel[]> {
        return this.httpGet<ActivityReaccomOrderViewModel[]>(this.PATH + '/reaccom/' + departureProductId +
                                                                         '/arrival-product-id/' + arrivalProductId, null)
            .pipe(
                map(res => res.body)
            )
    }

}
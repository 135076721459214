export class OrderDetailProductChangeAvailabilityFareView {
    priceClassId: string;
    priceClass: string;
    fareAmount: string;
    fareCurrency: string;
    availableSeat: number;
    fareStyleClass: string;
    offerId: string;
    journeyId: string;
    selectedFare: boolean;
    fareAmountNumber: number;
}
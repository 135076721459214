import { Validators } from '@angular/forms';

export const generalInfoFormOption = {
  productCategoryCode: [{ value: null, disabled: true }, Validators.required],
  productGroupCode: [{ value: null },Validators.required],
  productTypeGroupId: [{ value: null }, Validators.required],
  providerId: [],
  parentProductId: [],
  productName: ['',  [Validators.required]],
  productDescription: [''],
  draftFlag: [],
  statusCode: [],
  commitDateTime: [{ value: null, disabled: true }],
  commitByName: [{ value: null, disabled: true },],
  usageTypeCode: [{ value: null }, Validators.required]
};
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { PaymentConstant } from 'src/app/modules/order/shared';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PaymentTypeReferenceModel } from '../../models/reference-model/reference-general-model';
import { PaymentTypeReferenceService } from '../../services/system-services';

const FORM_OF_PAYMENT_TYPE_OPTION = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    width: 'auto',
    dropdownAutoWidth: true
}

@Component({
    selector: 'op-form-of-payment-type-selection',
    templateUrl: './form-of-payment-type-selection.component.html'
})
export class FormOfPaymentTypeSelectionComponent implements OnInit {
    private readonly PAYMENT_CONST = PaymentConstant;
    public _value: string | string[];
    public forOfPaymentOption = FORM_OF_PAYMENT_TYPE_OPTION;
    public loadSuccess: boolean = false;
    public formOfPaymentReferences$ = new BehaviorSubject<Select2Data[]>(null);

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input() disabled: boolean = false;
    @Input() isTopUp: boolean = false;
    @Output() dataChange: EventEmitter<string> = new EventEmitter();

    constructor(private formOfPaymentReferenceService: PaymentTypeReferenceService) {

    }
    ngOnInit(): void {
        this.loadFormOfPaymentReferences();
    }
    
    private loadFormOfPaymentReferences() {
        this.formOfPaymentReferenceService.getPaymentTypeReference()
            .subscribe(
                (response: PaymentTypeReferenceModel[]) => {
                    if (this.isTopUp) {
                        response = response.filter(item => item.formOfPaymentTypeCode != this.PAYMENT_CONST.creditAccount);
                    }
                    this.formOfPaymentReferences$.next(response.map(x => new Select2Data(x.formOfPaymentTypeCode, x.formOfPaymentTypeName)));
                    this.loadSuccess = true;
                }
            )
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangepickerDirective } from './daterangepicker.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DaterangepickerDirective
    ],
    exports: [
        DaterangepickerDirective
    ]
})
export class DaterangepickerModule { }
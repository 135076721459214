import { Component, EventEmitter, Output, Input, AfterViewInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ACTION_STATUS, ActionBarDetailHandlerModel, ActionbarDetailService } from "src/app/shared/ui/actionbar-detail";
import { ActionButtonModel, CustomButtonModel, DeleteButtonModel, EditButtonModel } from "src/app/shared/ui/actionbar-detail/models";

@Component({
    selector: 'op-phone-list-action-bar',
    templateUrl: './phone-list-action-bar.component.html',
    providers: [
        ActionbarDetailService
    ]
})
export class PhoneListActionBarComponent implements AfterViewInit {
    private readonly SET_PRIMARY_FLAG_ACTION_STATUS = 'PRIMARY';
    private unsubscribe$ = new Subject();
    private actionButton = new ActionButtonModel();
    private editButton = new EditButtonModel();
    private deleteButton = new DeleteButtonModel();
    private primaryButton = new CustomButtonModel('Primary',
        this.SET_PRIMARY_FLAG_ACTION_STATUS,
        'star',
        'color-info-500');
    private action: boolean = false;

    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() primary = new EventEmitter();
    
    constructor(private actionbarDetailService: ActionbarDetailService) {

    }

    ngAfterViewInit(): void {
        
        this.createStateBtn();
        this.hideActionBarButton();
        
        this.actionbarDetailService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
        this.actionButton.show();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private hideActionBarButton() {
        this.editButton.hide();
        this.deleteButton.hide();
        this.primaryButton.hide();
        this.updateStateBtn();
    }

    private showActionBarButton() {
        this.editButton.show();
        this.deleteButton.show();
        this.primaryButton.show();
        this.updateStateBtn();
    }

    private createStateBtn() {
        let actionbarDefaultState = new ActionBarDetailHandlerModel(
            this.actionButton,
            this.editButton,
            this.deleteButton,
            this.primaryButton
        );
        this.actionbarDetailService.updateState(actionbarDefaultState);
    }

    private updateStateBtn() {
        const state = this.actionbarDetailService.getCurrentState();``
        this.actionbarDetailService.updateState(state);
    }

    private actionbarClick(clickedBtn) {
        switch (clickedBtn) {
            case ACTION_STATUS.action:
                this.action = !this.action;
                if (this.action) {
                    this.showActionBarButton();
                    break;
                }
                this.hideActionBarButton();
                break;
            case this.SET_PRIMARY_FLAG_ACTION_STATUS:
                this.primary.emit();
                break;
            case ACTION_STATUS.edit:
                this.edit.emit();
                break;
            case ACTION_STATUS.delete:
                this.delete.emit();
                break;
            default:
                break;
        }
    }

    public togglePrimaryButton(enableFlag: boolean) {
        const state = this.actionbarDetailService.getCurrentState();
        state.get(this.SET_PRIMARY_FLAG_ACTION_STATUS).enable(enableFlag);
        this.actionbarDetailService.updateState(state);
    }
    public disableActionButton(value: boolean) {
        this.actionButton.disable(value);
    }
}
import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { FavoriteModel } from 'src/app/core/models/reference-model/reference-general-model/favorite.model';
import { FavoriteService } from 'src/app/core/services/system-services/favorite.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { currentNavigationAction } from 'src/app/store/navigation-action';
import { filter, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from 'rxjs';
import { NavigationActionModel } from 'src/app/core/models/navigation-action/navigation-action.model';
import { DomainReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/domain-reference.model';
import { DomainReferenceService } from 'src/app/core/services/system-services/domain-reference.service';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { LoadingSpinnerService } from '../loading-spinner';
import { cloneDeep } from 'lodash';
import { FavoriteConstant, FavoriteNameConstant, GroupName, PricingCategoryUrl } from 'src/app/modules/favorite/shared/favorite.constant';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { badgeCount } from 'src/app/store/navigation';

@Component({
    selector: 'op-dropdown-favorite',
    templateUrl: './dropdown-favorite.component.html'
})
export class DropdownFavoriteComponent extends OopsComponentFormBase implements OnDestroy {
    private readonly DEFAULT_DOMAIN_INDEX = 1;
    private readonly DEFAULT_GROUP_INDEX = 3
    private readonly SEARCH_ACTION_CODE = 'SEARCH';
    private readonly SETTING_CODE = 'SETTING';
    private readonly ATTRIBUTE_CODE = 'ATTRIBUTE'
    private readonly SECURITY_CODE = 'SECURITY';
    private readonly VEHICLE_CODE = 'VEHICLE';
    private readonly ORDER_CODE = 'ORDER';
    private readonly SHOPPING_CODE = 'SHOPPING';
    private readonly SCHEDULE_CODE = 'SCHEDULE';
    private readonly SSIM_CODE = 'SSIM';
    private readonly SCHEDULEQUEUE_CODE = 'SCHEDULEQUEUE';
    private readonly SCHEDULEDISTRIBUTION_CODE = 'DISTRIBUTION';
    private readonly HOME_CODE = 'HOME';
    private readonly MERCHANDISE_CODE = 'MERCHANDISE';
    private readonly START_MERCHANDISE = 'MERCHANDI';
    private readonly PRODUCT_CODE = 'PRODUCT';
    private readonly PRICING_CODE = 'PRICING';
    private readonly PRICE_CODE = 'PRICE';
    private readonly PRICERULE_CODE = 'PRICERULE';
    private readonly INVENTORY_CODE = 'INVENTORY';
    private readonly REVENUEEXPORTGROUP_CODE = 'REVENUEEXPORT';
    private readonly FINANCE_CODE = 'FINANCE';

    public userFavorites$ = new BehaviorSubject<FavoriteModel[]>(null);
    public currentFavorite: FavoriteModel;
    public actionCode: string;
    public parameter: string;
    public domainReference$ = new BehaviorSubject<DomainReferenceModel[]>(null);
    public userFavoriteNumber: number;
    public parameterName: string;
    public domainCode: string;
    public groupCode: string;

    private unsubscribe$ = new Subject();

    @ViewChild(NgbDropdown) dropdown: NgbDropdown;

    constructor(private favoriteService: FavoriteService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private store: Store<any>,
        private domainReferenceService: DomainReferenceService,
        private loadingSpinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef) {
            super(formBuilder)
         }

    public initForm() {
        this.getDomainReference();
        this.getUserFavorite();
        this.getCurrentUrl();
        this.formSetup();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    getNavigationAction() {
        this.store.select(currentNavigationAction)
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (action: NavigationActionModel) => {
                        this.actionCode = action?.action?.toUpperCase();
                        this.parameter = action?.parameter;
                        this.parameterName = action?.parameterName?.trim() ?? '';
                        this.checkActionCode(this.actionCode);
                        this.getCurrentFavorite();
                        this.formSetup();
                        this.changeDetectorRef.detectChanges();
                    }
                )
    }

    getCurrentUrl() {
       this.router.events.pipe(
            filter((event) : event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((e: RouterEvent) => {
            this.getDomainAndGroupUrl(e.url);
            this.getCurrentFavorite();
            this.formSetup();
        });
    }

    getDomainReference() {
        this.domainReferenceService.getDomainReference()
            .subscribe(
                (response) => {
                    this.domainReference$.next(response);
                }
            )
    }

    public formSetup() {
        this.formGroup = this.formBuilder.group({
            favoriteName: [null],
            executeFlag: [null]
        });
        this.fillFavoriteForm();
    }

    fillFavoriteForm() {
        if (this.checkFavorite == true) {
            this.patchFavoriteValue(this.currentFavorite.userFavoriteName, this.currentFavorite.executeFlag);
        } else {
            this.patchFavoriteValue(this.getNewFavoriteName());
        }
    }

    patchFavoriteValue(favoriteName: string, executeFlag: boolean = false) {
        this.formGroup.patchValue({
            favoriteName: favoriteName,
            executeFlag: executeFlag
        })
    }

    getNewFavoriteName() {
        let name = this.getDomainName(this.domainCode) + this.getGroupName(this.domainCode, this.groupCode) + this.getSpecifiedFavoriteName();
        if (name.length > 60) {
            return name.substring(0, 60);
        }
        return name;
    }

    getUserFavorite() {
        this.favoriteService.getUserFavorite()
            .subscribe(
                (response: FavoriteModel[]) => {
                    this.userFavorites$.next(response);
                    this.store.dispatch(badgeCount({id: 'FAVORITES', count: response.length }));
                    this.getNavigationAction();
                }
            )
    }

    getDomainAndGroupUrl(url: string) {
        if (!url) {
            return;
        }
        let splitedUrl = url.split(/[-/]/).splice(1);
        this.domainCode = this.getDomainCode(splitedUrl);
        if (splitedUrl.length > 3) {
            this.groupCode = this.getGroupCode(splitedUrl);
        } else {
            this.groupCode = null;
        }
    }

    getCurrentFavorite() {
        let favorite = this.userFavorites$.value?.filter(item => this.filterCurrentFav(item))[0];
        this.currentFavorite = cloneDeep(favorite);
        this.formSetup();
    }

    filterCurrentFav(item: FavoriteModel): boolean {
        if (item.domainCode != this.domainCode) {
            return false;
        }
        if (item.groupCode != this.groupCode) {
            return false;
        }
        if (item.actionCode != this.actionCode && this.domainCode != this.SETTING_CODE) {
            return false;
        }
        if (item.actionCode != FavoriteConstant.SEARCH_ACTION && item.parameter != this.parameter) {
            return false;
        }
        return true;
    }

    get checkFavorite() {
        if (this.currentFavorite) {
            return true;
        }
        return false;
    }

    get displayExecute() {
        if (this.actionCode == this.SEARCH_ACTION_CODE && this.groupCode != 'SSIM') {
            return true;
        }
        return false;
    }

    getDomainName(domainCode: string) {
        return this.domainReference$.value?.filter(item => item.domainCode == domainCode?.toUpperCase())[0]?.domainName;
    }

    fillFormToNewModel(): FavoriteModel {
        let formValue = this.formGroup.value;
        let model: FavoriteModel = {
            userFavoriteId: null,
            userAccountId: null,
            userFavoriteName: formValue.favoriteName,
            domainCode: this.domainCode,
            groupCode: this.groupCode,
            actionCode: this.actionCode,
            parameter: this.parameter,
            executeFlag: formValue.executeFlag ?? false,
            commitBy: null,
            commitByName: null,
            commitDateTime: null
        }
        return model;
    }

    fillFormToExistedModel(): FavoriteModel {
        let formValue = this.formGroup.value;
        let model = this.currentFavorite;
        model.userFavoriteName = formValue.favoriteName;
        model.executeFlag = formValue.executeFlag ?? false;
        model.parameter = this.parameter;

        return model;
    }

    onSave() {
        this.startProcessing();
        if (!this.formGroup.valid) { 
            return;
        }
        if (!this.currentFavorite) {
            let command = this.fillFormToNewModel();
            this.addFavorite(command);
        } else {
            let command = this.fillFormToExistedModel();
            this.editFavrite(command);
        }
        this.completeProcessing();
    }

    addFavorite(model: FavoriteModel) {
        this.loadingSpinnerService.show();
        this.favoriteService.addUserFavorite(model)
            .subscribe(
                () => {
                    this.getUserFavorite();
                    this.getCurrentFavorite();
                    this.dropdown.close();
                    this.completeProcessing();
                    this.loadingSpinnerService.saveComplete();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    editFavrite(model: FavoriteModel) {
        this.loadingSpinnerService.show();
        this.favoriteService.editUserFavorite(model)
            .subscribe(
                () => {
                    this.getUserFavorite();
                    this.getCurrentFavorite();
                    this.dropdown.close();
                    this.completeProcessing();
                    this.loadingSpinnerService.saveComplete();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    onRemove() {
        if (this.currentFavorite) {
            this.deleteFavorite(this.currentFavorite.userFavoriteId)
        }
    }

    deleteFavorite(favoriteId: string) {
        this.loadingSpinnerService.show();
        this.favoriteService.deleteUserFavorite(favoriteId)
            .subscribe(
                () => {
                    this.loadingSpinnerService.saveComplete();
                    this.getUserFavorite();
                    this.getCurrentFavorite();
                    this.dropdown.close();
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    onExecuteFlagChange(e) {
        this.formGroup.controls['executeFlag'].setValue(e.value);
    }

    getDomainCode(urlElement: string[]): string {
        if (urlElement[this.DEFAULT_DOMAIN_INDEX]?.includes(this.SETTING_CODE.toLowerCase())) {
            return this.getSettingDomainCode(urlElement);
        } else if (urlElement[this.DEFAULT_DOMAIN_INDEX]?.includes(this.ORDER_CODE.toLowerCase())) {
            return this.getOrderDomainCode(urlElement);
        } else if (urlElement[this.DEFAULT_DOMAIN_INDEX] == 'pricing') {
            let lastIndex = urlElement.length - 1;
            return this.getPricingDomainCode(urlElement.slice(this.DEFAULT_GROUP_INDEX, lastIndex).join('-'));
        } else if (urlElement[this.DEFAULT_DOMAIN_INDEX] == 'cashbook') {
            return this.getFinanceDomainCode(urlElement)
        }
        return urlElement[this.DEFAULT_DOMAIN_INDEX]?.toUpperCase();
    }

    getSettingDomainCode(urlElement: string[]): string {
        if (urlElement[this.DEFAULT_GROUP_INDEX]?.startsWith('home')) {
            return this.SETTING_CODE;
        }
        return urlElement[this.DEFAULT_GROUP_INDEX]?.toUpperCase();
    }

    getOrderDomainCode(urlElement: string[]): string {
        if (this.isHomeOrDetail(urlElement)) {
            return this.ORDER_CODE;
        }
        return urlElement[this.DEFAULT_GROUP_INDEX]?.toUpperCase();
    }

    getFinanceDomainCode(urlElement: string[]) {
        if (urlElement.length > 3) {
            const groupCode = urlElement[this.DEFAULT_GROUP_INDEX]
            switch (groupCode) {
                case 'credit':
                    return 'CREDITACCOUNT'
                case 'counter':
                    return 'COUNTERSALES'
                case 'home':
                    return this.FINANCE_CODE
                default:
                    return groupCode?.toUpperCase()
            }
        }
        return this.FINANCE_CODE
    }

    getGroupCode(urlElement: string[]): string {
        if (this.isNonGroupDomain(this.domainCode) || this.isHomeOrDetail(urlElement)) {
            return null;
        }
        if (this.domainCode?.startsWith(this.SECURITY_CODE) || this.domainCode?.startsWith(this.VEHICLE_CODE)) {
            return urlElement[4]?.toUpperCase();
        }
        if (this.domainCode?.startsWith(this.SCHEDULE_CODE)) {
            return this.getScheduleGroupCodeFromUrl(urlElement.slice(this.DEFAULT_GROUP_INDEX));
        }
        if (this.domainCode?.startsWith(this.PRODUCT_CODE)) {
            return this.getProductCategoryCode(urlElement.slice(this.DEFAULT_GROUP_INDEX));
        }
        if (urlElement[this.DEFAULT_DOMAIN_INDEX].toUpperCase() == this.PRICING_CODE) {
            let lastIndex = urlElement.length - 1;
            return this.getPricingGroupCode(urlElement.slice(this.DEFAULT_GROUP_INDEX, lastIndex).join('-'));
        }
        if (this.domainCode?.startsWith(this.INVENTORY_CODE)) {
            return this.getInventoryGroupCode(urlElement.slice(this.DEFAULT_GROUP_INDEX))
        }
        if ([this.FINANCE_CODE, 'CASHBOOK', 'COUNTERSALES', 'PAYMENT', 'CREDITACCOUNT'].includes(this.domainCode)) {
            return null
        }
        return urlElement[this.DEFAULT_GROUP_INDEX]?.toUpperCase();
    }

    isHomeOrDetail(urlElement: string[]) {
        return (urlElement[this.DEFAULT_GROUP_INDEX]?.toLowerCase() == 'home' || urlElement[this.DEFAULT_GROUP_INDEX]?.toLowerCase() == 'details');
    }

    getSpecifiedFavoriteName(): string {
        let name = this.getActionName();
        if (this.parameter) {
            name += ' ' + this.parameterName
        }
        return name;
    }

    getActionName(): string {
        switch (this.actionCode) {
            case FavoriteConstant.SEARCH_ACTION:
                return ' ' + FavoriteNameConstant.SEARCH_NAME;
            case FavoriteConstant.NEW_ACTION:
                return ' ' + FavoriteNameConstant.NEW_NAME;
            case FavoriteConstant.STATUS_ACTION:
                return ' ' + FavoriteNameConstant.STATUS_NAME;
            case FavoriteConstant.DASHBOARD_ACTION:
                return ' ' + FavoriteNameConstant.DASHBOARD_NAME;
            case FavoriteConstant.MYACTIVITY_ACTION:
                return ' ' + FavoriteNameConstant.MYACTIVITY_NAME
            default:
                return ''
        }
    }

    checkActionCode(actionCode: string) {
        if (!actionCode) {
            this.groupCode= null;
            return;
        }
        let actions = actionCode?.split('/');
        if (actions?.length > 1) {
            if (actions[1] == this.ATTRIBUTE_CODE) {
                this.domainCode = this.ATTRIBUTE_CODE;
            } else if (actions[1].startsWith(this.START_MERCHANDISE)) {
                this.groupCode = this.MERCHANDISE_CODE;
            } else if (this.checkPricingCategory(actions[1])) {
                this.domainCode = this.getPricingDomainCode(actions[1]);
                this.groupCode = this.getPricingGroupCode(actions[1]);
            } else {
                this.groupCode = actions[1];
            }
            this.actionCode = actions[0].toUpperCase();
        } else {
            this.groupCode= null;
            this.checkRootPage();
        }
    }

    isSecurityDomain(urls: string[]) {
        return urls[this.DEFAULT_DOMAIN_INDEX]?.startsWith(this.SECURITY_CODE.toLowerCase());
    }

    getGroupName(domainCode: string, groupCode: string) {
        if (groupCode) {
            let group = GroupName[domainCode];
            if (group) {
                return ' ' + group[groupCode];
            }
        }
        return '';
    }

    isNonGroupDomain(domainCode: string) {
        return domainCode.includes(this.SETTING_CODE) || 
            domainCode.includes(this.ATTRIBUTE_CODE) ||
            domainCode.includes(this.SHOPPING_CODE) ||
            domainCode.includes(this.ORDER_CODE) ||
            domainCode.includes(this.HOME_CODE);
    }

    getScheduleGroupCodeFromUrl(groupUrlElement: string[]) {
        let groupUrl = groupUrlElement.join('-');
        if (groupUrl == 'schedule-queue') {
            return this.SCHEDULEQUEUE_CODE;
        } else if (groupUrl == 'standard-schedule-information') {
            return this.SSIM_CODE;
        } else if (groupUrl == 'schedule-distribution') {
            return this.SCHEDULEDISTRIBUTION_CODE;
        }
    }

    getInventoryGroupCode(groupUrlElement: string[]): string {
        const groupUrl = groupUrlElement.join('-');
        switch (groupUrl) {
            case 'revenuemanagement-export':
                return this.REVENUEEXPORTGROUP_CODE;
            default:
                return '';
        }
    }

    getProductCategoryCode(groupUrlElement: string[]) {
        let groupUrl = groupUrlElement.filter(item => item != 'details').join('-');
        if (groupUrl.includes(this.START_MERCHANDISE)) {
            return this.MERCHANDISE_CODE;
        }
        for (let name in GroupName['PRODUCT']) {
            if (groupUrl.includes(name.toLowerCase())) {
                return name;
            }
        }
    }

    getPricingDomainCode(groupUrl: string) {
        if ((groupUrl == 'home' && !this.actionCode) || !groupUrl) {
            return this.PRICING_CODE;
        }
        if (groupUrl?.replace('-', '').toUpperCase() == this.PRICERULE_CODE) {
            return this.PRICERULE_CODE
        }
        return this.PRICE_CODE;
    }

    getPricingGroupCode(groupUrl: string) {
        let upperCaseGroupUrl = groupUrl.replace('-', '').toUpperCase();
        if (upperCaseGroupUrl && upperCaseGroupUrl != this.PRICERULE_CODE && upperCaseGroupUrl != this.PRICE_CODE) {
            return this.PRICE_CODE + upperCaseGroupUrl;
        }
        return;
    }

    checkPricingCategory(code: string) {
        return Object.keys(PricingCategoryUrl).find(key => key.includes(code));
    } 

    checkRootPage() {
        this.getDomainAndGroupUrl(window.location.pathname);
    }
}

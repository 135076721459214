import { Injectable } from "@angular/core"

import { Locale, Select2Option } from "../../shared/attribute-and-rule/views/select2.option"
import { PriceRuleAjaxService } from "./price-rule-ajax.service"

declare var moment: any;

export const Select2Themes = {
    CONDITION_SELECTOR_THEME: 'select2-builder-green',
    MULTIPLE_VALUES_SELECTOR_THEME: 'select2-builder-multiple-gray',
    SINGLE_VALUE_SELECTOR_THEME: "select2-builder-blue",
    DISABLED_THEME: 'select2-builder-gray'
}

export const Select2DateTimeFormats = {
    DATETIME_FORMAT: "YYYY/MM/DD HH:mm",
    TIME_FORMAT: "HH:mm",
}

@Injectable({
    providedIn: 'root'
})
export class Select2OptionsService {
    
    constructor(private priceRuleAjaxService: PriceRuleAjaxService) {}

    public getSelect2ConditionOption() {
        let options = new Select2Option();
        options.theme = Select2Themes.CONDITION_SELECTOR_THEME;
        return options;
    }

    public getSelect2OriginOption() {
        let options = new Select2Option("<Origin>");
        options.minimumInputLength = 3;
        options.ajax = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        return options;
    }

    public getSelect2DestinationOption() {
        let options = new Select2Option("<Destination>");
        options.ajax = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        options.minimumInputLength = 3;
        return options;
    }

    public getSelect2ProviderOption() {
        let options = new Select2Option("<Provider>");
        options.ajax = this.priceRuleAjaxService.toProviderAjaxOption();
        options.minimumInputLength = 1;
        return options;
    }

    public getSelect2RegionOption() {
        let options = new Select2Option("<Region>");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.priceRuleAjaxService.toRegionReferenceAjaxOption();
        options.minimumInputLength = 1;
        options.allowClear = true;
        return options;
    }

    public getSelect2CountryOption() {
        let options = new Select2Option("<Country>");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.priceRuleAjaxService.toCountryReferenceAjaxOption();
        options.minimumInputLength = 1;
        options.allowClear = true;
        return options;
    }

    public getSelect2AirportCityOption() {
        let options = new Select2Option("Airport/City");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.priceRuleAjaxService.toLocationReferenceAjaxOption();
        options.minimumInputLength = 3;
        options.allowClear = true;
        return options;
    }

    public getSelect2LocationGroupOption() {
        let options = new Select2Option("<Location Group>");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.ajax = this.priceRuleAjaxService.toLocationGroupAjaxOption();
        options.minimumInputLength = 1;
        options.allowClear = true;
        return options;
    }

    public getSelect2CalendarValidityOption() {
        let options = new Select2Option();
        options.disabled = true;
        return options;
    }

    public getDatePickerOption(placeholder = null) {
        let datePickerOptionLocale = new Locale();
        datePickerOptionLocale.cancelLabel = "Clear";
        datePickerOptionLocale.format = "YYYY/MM/DD";

        let datePickerOption = new Select2Option();
        datePickerOption.singleDatePicker = true;
        datePickerOption.showDropdowns = true;
        datePickerOption.autoApply = true;
        datePickerOption.autoUpdateInput = true;
        datePickerOption.startDate = moment().format(datePickerOptionLocale.format);
        datePickerOption.locale = datePickerOptionLocale;
        if (placeholder) {
            datePickerOption.placeholder = placeholder;
        }
        return datePickerOption;
    }

    public getDatetimePickerOption(placeholder = null) {
        let option = this.getDatePickerOption(placeholder);
        option.timePicker = true;
        option.timePicker24Hour = true;
        option.locale.format = Select2DateTimeFormats.DATETIME_FORMAT;
        option.startDate = moment().format(option.locale.format);
        return option;
    }

    public getTimePickerOption(placeholder = null) {
        let option = this.getDatePickerOption(placeholder);
        option.timePicker = true;
        option.timePicker24Hour = true;
        option.locale.format = Select2DateTimeFormats.TIME_FORMAT;
        option.showTimeOnly = true;
        option.startDate = moment().format(option.locale.format);
        return option;
    }

    public getSelect2WeekdayOption() {
        let options = new Select2Option("<Weekday>");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME;
        options.multiple = true;
        options.allowClear = true;
        return options;
    }

    public getSelect2MultiChoickOption() {
        let options = new Select2Option("<Dimension>");
        options.theme = Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME; 
        options.multiple = true;
        options.allowClear = true;
        options.width = "auto";
        options.dropdownAutoWidth = true;
        return options;
    }
}
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OperatorModel } from 'src/app/core/models/merchandizing-config';

@Injectable()
export class RouteSetDefaultService {

    public setDefaultOperatorRoute(f: UntypedFormGroup, operatorRoute$: BehaviorSubject<OperatorModel[]>, operatorRouteOption) {
        var filterplaceHolder = operatorRoute$.value.filter(x => x.operatorCode == "");
        if (filterplaceHolder.length != 0) {
            operatorRouteOption.placeholder = filterplaceHolder[0].operatorName;
        }
        else {
            if (operatorRoute$.value.length != 0) {
                f.controls['operatorRoute'].setValue(operatorRoute$.value[0].operatorCode);
            }
        }
    }

}
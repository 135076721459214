import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CommunicationOtherTypeReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommunicationOtherTypeCodeConstant } from "src/app/modules/order/shared";
import { CommunicationOtherView } from "../shared/communication-other-view";
import { select2CommunicationOtherType, select2Language } from "../shared/select2-configuration";

@Component({
    selector: 'op-insight-communication-other-detail',
    templateUrl: './communication-other-detail.component.html'
})
export class InsightCommunicationOtherDetailComponent {
    readonly ERROR_COMMUNICATION_TYPE_REQUIRED = 'Communication Type is required.'
    readonly ERROR_EMAIL_NOT_AVAIBLE = 'Mail Address is not available for use.';
    private readonly COMMUNICATION_TYPE_EMAIL = 'EMAIL';
    
    public communicationOtherTypeOption: any;
    public languageOption: any;
    public processing: boolean = false;

    @Input() insightCommunicationOther = new CommunicationOtherView();
    @Input() communicationOtherTypeReferences: CommunicationOtherTypeReferenceModel[];
    @Input() languageReferences: LanguageReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();

    @ViewChild('communicationOtherDetailForm') communicationOtherDetailForm: NgForm;

    get isNullOrganisationId(): boolean {
        if (this.insightCommunicationOther.organisationId) {
            return true;
        }

        return false;
    }

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    private setOptionControl() {
        this.communicationOtherTypeOption = select2CommunicationOtherType;
        this.languageOption = select2Language;
    }

    getInsightCommunicationOther(): CommunicationOtherView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightCommunicationOther;
    }

    getInsightCommunicationEmail(): CommunicationOtherView {
        if (this.communicationOtherDetailForm.form.get('identity')?.errors?.email) {
            return null;
        }
        if (this.insightCommunicationOther?.communicationOtherTypeCode == this.COMMUNICATION_TYPE_EMAIL && this.insightCommunicationOther?.mailAddress) {
            return this.insightCommunicationOther;
        }
        return null;
    }


    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public isValidForm(): boolean {
        this.processing = true;
        if (!this.communicationOtherDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.communicationOtherDetailForm.valid) {
            if (this.communicationOtherDetailForm.form.get('identity').errors?.duplicate) {
                return this.ERROR_EMAIL_NOT_AVAIBLE;
            }
            return this.ERROR_COMMUNICATION_TYPE_REQUIRED;
        }
    }

    public communicationOtherTypeChange(value) {
        this.insightCommunicationOther.communicationOtherTypeCode = value
        this.insightCommunicationOther.communicationOtherTypeName = this.communicationOtherTypeReferences.filter(it => it.communicationOtherTypeCode == value)[0]?.communicationOtherTypeName ?? null;
    }

    public languageCodeChange(value) {
        this.insightCommunicationOther.languageCode = value
    }

    get identityType(): string {
        if (this.insightCommunicationOther.communicationOtherTypeCode == CommunicationOtherTypeCodeConstant.email) {
            return 'email';
        }
        return 'text'
    }

    get emailValidate(): boolean {
        return this.insightCommunicationOther?.communicationOtherTypeCode == CommunicationOtherTypeCodeConstant.email;
    }

    public displayDuplicationEmailError(duplicate: boolean) {
        if (duplicate) {
            this.communicationOtherDetailForm.form.get('identity').setErrors({ duplicate: true });
        } else {
            this.communicationOtherDetailForm.form.get('identity').setErrors(null);
        }
        this.changeDetectionRef.detectChanges();
    }
}
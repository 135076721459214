import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { PricePriceDimensionTypeModel } from "src/app/core/models/reference-model/reference-general-model/price-price-dimension-type.model";
import { DomainAttributeService } from "src/app/core/services/airline-services";
import { PricePriceDimensionTypeService } from "src/app/core/services/system-services/price-price-dimension-type.service";
import { Helper } from "src/app/shared/helper/app.helper";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceModel, PricePriceDimensionModel } from "../../../prices/shared/models";
import { Select2Themes } from "../../../rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service";
import { PriceDimensionConstant } from "../dimensions/shared/constants";
import * as _ from 'lodash';

@Component({
    selector: 'op-price-dimensions-details-level',
    templateUrl: './level.component.html'
})
export class LevelComponent extends OopsComponentFormBase implements OnChanges {
    @Input() price: PriceModel;
    @Input() set priceAttributes(val: string[]) {
        let attributeList = this.getAttributeListSelect2Datas(val);
        if (_.isEqual(attributeList, this.attributeList$?.value) == false) {
            this.attributeList$.next(attributeList);
        }
    }
    @Input() disabled: boolean = false;
    classIcon = this.helper.getClassIcon();
    focusing = false;
    option = {
        allowClear: false,
        dropdownAutoWidth: true,
        minimumResultsForSearch: -1,
        placeholder: "Price Attribute",
        theme: Select2Themes.DISABLED_THEME,
        width: "auto",
        disabled: true
    };
    attributeOption = {
        allowClear: false,
        dropdownAutoWidth: true,
        minimumResultsForSearch: -1,
        placeholder: "<Attributes>",
        theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
        width: "auto",
        multiple: true
    }
    
    attributeList$ = new BehaviorSubject<Select2Data[]>([]);
    domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    pricePriceDimensionTypes$ = new BehaviorSubject<PricePriceDimensionTypeModel[]>(null);
    
    constructor(fb: UntypedFormBuilder,
        private helper: Helper,
        private domainAttributeService: DomainAttributeService,
        private pricePriceDimensionTypeService: PricePriceDimensionTypeService) {
            super(fb);
            this.getPriceRuleAttributeTypeReferences();
            this.getPricePriceDimensionTypes();
    }

    public initForm() {
        let value = this.getSelectedPriceAttributes(this.price?.pricePriceDimensions);
        this.formGroup = new UntypedFormGroup({
            selectedPriceAttributes: new UntypedFormControl(value)
        })
        this.onDataChange(value);
        this.setupSecurityDisabled();
    }

    private setupSecurityDisabled() {
        if (this.disabled == true) {
            this.formGroup.disable();
            return;
        }
        this.formGroup.enable();
    }

    private getSelectedPriceAttributes(pricePriceDimensions: PricePriceDimensionModel[]): string[] {
        let result = [];
        if (!pricePriceDimensions?.length) {
            return result;
        }
        for (let pricePriceDimension of pricePriceDimensions) {
            if (pricePriceDimension.priceRuleAttributeTypeCode) {
                result.push(pricePriceDimension.priceRuleAttributeTypeCode);
            } else {
                result.push(pricePriceDimension.pricePriceDimensionTypeCode);
            }
        }
        return result;
    }
        
    private getPricePriceDimensionTypes() {
        this.pricePriceDimensionTypeService.getPricePriceDimensionTypes()
            .subscribe(
                (res: PricePriceDimensionTypeModel[]) => {
                    this.pricePriceDimensionTypes$.next(res);
                }
            )
    }

    private getPriceRuleAttributeTypeReferences() {
        this.domainAttributeService.getPriceRuleAttributes('', '', '')
            .subscribe(
                (res: DomainAttributeModel[]) => {
                    let priceDimensionPriceRuleAttributeTypeReferences = res.filter(r => r.dimensionFlag == true);
                    this.domainAttributes$.next(priceDimensionPriceRuleAttributeTypeReferences);
                }
            );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['price']) {
            this.initForm();
        }
    }

    public getValues(price: PriceModel): PriceModel {
        if (!price) {
            this.startProcessing();
            return;
        }
        this.completeProcessing();
        return this.getRawValues(price);
    }

    public getRawValues(price: PriceModel): PriceModel {
        let pricePriceDimensions = new Array<PricePriceDimensionModel>();
        let values = this.formGroup.get('selectedPriceAttributes')?.value;
        for (let i = 0; i < values?.length; i++) {
            let priceAttribute = values[i];
            let displaySequence = i + 1;
            let pricePriceDimension = new PricePriceDimensionModel();
            pricePriceDimension.displaySequence = displaySequence;

            let priceRuleAttributeTypeReference = this.domainAttributes$?.getValue()?.filter(att => att.attributeTypeCode == priceAttribute);
            if (priceRuleAttributeTypeReference?.length) {
                pricePriceDimension.pricePriceDimensionTypeCode = PriceDimensionConstant.attribute.id;
                pricePriceDimension.priceRuleAttributeTypeCode = priceAttribute;
            } else {
                pricePriceDimension.pricePriceDimensionTypeCode = priceAttribute;
            }
            const pricePriceDimensionId = this.findExistPricePriceDimensionId(this.price, pricePriceDimension);
            if (pricePriceDimensionId) {
                pricePriceDimension.pricePriceDimensionId = pricePriceDimensionId;
            }
            pricePriceDimensions.push(pricePriceDimension);
        }
        price.pricePriceDimensions = pricePriceDimensions;
        return price;
    }

    private findExistPricePriceDimensionId(price: PriceModel, pricePriceDimension: PricePriceDimensionModel): string {
        if (!price || !price.pricePriceDimensions?.length) {
            return null;
        }
        const foundPricePriceDimension = price.pricePriceDimensions.find(ppd => {
            if (!ppd.priceRuleAttributeTypeCode && !pricePriceDimension.priceRuleAttributeTypeCode) {
                return ppd.pricePriceDimensionTypeCode == pricePriceDimension.pricePriceDimensionTypeCode;
            }
            return ppd.pricePriceDimensionTypeCode == pricePriceDimension.pricePriceDimensionTypeCode &&
                ppd.priceRuleAttributeTypeCode == pricePriceDimension.priceRuleAttributeTypeCode;
        });
        if (!foundPricePriceDimension) {
            return null;
        }
        return foundPricePriceDimension.pricePriceDimensionId;
    }

    onDataChange(value: string | string[]) {
        if (this.processing) {
            this.completeProcessing();
        }
        this.formGroup.get('selectedPriceAttributes').patchValue(value);
    }

    public resetForm() {
        this.completeProcessing();
        this.initForm();
    }

    private getAttributeListSelect2Datas(priceAttributes: string[]): Select2Data[] {
        if (!priceAttributes?.length) {
            return [];
        }
        const pricePriceDimensionTypes = this.pricePriceDimensionTypes$.getValue();
        if (!pricePriceDimensionTypes?.length) {
            return [];
        }
        return priceAttributes.map(pa => {
            const foundPricePriceDimensionType = pricePriceDimensionTypes.find(ppdt => ppdt
                .pricePriceDimensionTypeCode == pa);
            if (foundPricePriceDimensionType) {
                return new Select2Data(pa, foundPricePriceDimensionType.pricePriceDimensionTypeName);
            }
            const foundAttribute = this.domainAttributes$?.value?.find(da => da.attributeTypeCode == pa);
            if (foundAttribute) {
                return new Select2Data(pa, foundAttribute.attributeTypeName);
            }
            return new Select2Data(pa, pa);
        });
    }
}
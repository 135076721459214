import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { CalendarValidityReferenceModel, ConditionReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductHashTagView } from '../../shared/views/product-hashtag.view';
import { VoucherHashtagComponent } from './voucher-hashtag/voucher-hashtag.component';
import { HashTagMapperService } from '../../shared/mapper/hashtag-mapper.service';
import { VoucherAttributeComponent } from './voucher-attribute/voucher-attribute.component';
import { ProductHashTagCommandModel, ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import { VoucherValidityComponent } from './voucher-validity/voucher-validity.component';
import { VoucherRestrictionComponent } from './voucher-restriction/voucher-restriction.component';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { VoucherPointOfSalesComponent } from './voucher-point-of-sales/voucher-point-of-sales.component';
import { VoucherSearchCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-search-command.model';
import { ProductAttributeCommandModel, ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductValidityCommandModel, ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { ProductRestrictionLocationCommandModel, ProductRestrictionLocationViewModel, ProductRestrictionOrganisationCommandModel, ProductRestrictionOrganisationViewModel, ProductRestrictionProductCommandModel, ProductRestrictionProductNumberCommandModel, ProductRestrictionProductNumberViewModel, ProductRestrictionProductViewModel, ProductRestrictionRouteCommandModel, ProductRestrictionRouteViewModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { VoucherPointOfPaymentsComponent } from './voucher-point-of-payments/voucher-point-of-payments.component';

@Component({
    selector: 'op-voucher-attribute-and-rule',
    templateUrl: './voucher-attribute-and-rule.component.html',
    providers: [
        HashTagMapperService,
        ValidityMapperService,
        RestrictionMapperService,
        PointOfSalesMapperService
    ]
})
export class VoucherAttributeAndRuleComponent {
    private readonly pointOfSalesTypeCode = 'SALES';
    private readonly pointOfPaymentTypeCode = 'PAYMENT';

    public productHashtags: ProductHashTagView[] = new Array();

    @Input() searchMode: boolean = false;
    @Input() newVoucher: boolean = false;
    @Input() newFromParent: boolean = false;
    @Input() domainAttribute$: BehaviorSubject<DomainAttributeModel[]>;

    @Input() hashTagReferences$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    @Input() productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);

    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    @Input() productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    @Input() productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() productTypeGroups$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);

    @Input() domainAttributeOrgDescSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);

    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];
    
    @Output() hashtagValueChange = new EventEmitter();

    @ViewChild(VoucherHashtagComponent) hashtagComponent: VoucherHashtagComponent;
    @ViewChild(VoucherAttributeComponent) attributeComponent: VoucherAttributeComponent;
    @ViewChild('originDestination') originDestinationComponent: VoucherAttributeComponent;
    @ViewChild(VoucherValidityComponent) validityComponent: VoucherValidityComponent;
    @ViewChild(VoucherRestrictionComponent) restrictionComponent: VoucherRestrictionComponent;
    @ViewChild(VoucherPointOfSalesComponent) pointOfSalesComponent: VoucherPointOfSalesComponent;
    @ViewChild(VoucherPointOfPaymentsComponent) pointOfPaymentsComponent: VoucherPointOfPaymentsComponent;

    constructor(private hashTagMapperService: HashTagMapperService,
        private validityMapperService: ValidityMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private focusingService: FocusingService) { }
    
    public fillAttributeAndRulesToSearchCommand(searchCommand: VoucherSearchCommandModel) {
        searchCommand.productHashTags = this.getHashTagToCommand();
        searchCommand.productAttributes = this.getAttributeToCommand();
        this.fillRetrictionsToSearchCommand(searchCommand);
        searchCommand.productValidities = this.getValidityToCommand();
        searchCommand.productRestrictionOrganisations = this.getRestrictionOrganisationsToCommand();
    }

    public getHashTagToCommand(): ProductHashTagCommandModel[] {
        return this.getHashtagValue();
    }
                      
    public getAttributeToCommand(): ProductAttributeCommandModel[] {
        let attributes = this.getAttributeValue() ?? [];
        let originDestination = this.getOriginDestination() ?? [];
        attributes = attributes.concat(originDestination);
        return attributes.length > 0 ? attributes : null;
    }

    public getRestrictionProductsToCommand(): ProductRestrictionProductCommandModel[] {
        let productRestrictionViews = this.getRestriction();
        return this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
    }

    public getRestrictionProductNumbersToCommand(): ProductRestrictionProductNumberCommandModel[] {
        let productRestrictionViews = this.getRestriction();
        return this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
    }

    public getRestrictionRoutesToCommand(): ProductRestrictionRouteCommandModel[] {
        let productRestrictionViews = this.getRestriction();
        return this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
    }

    public getRestrictionLocationsToCommand(): ProductRestrictionLocationCommandModel[] {
        let productRestrictionViews = this.getRestriction();
        let pointofSalesCommand = this.getPointOfSales();
        let restrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let restrictionLocation = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
        return this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(restrictionLocation, restrictionLocationPointOfSales)
    }

    private fillRetrictionsToSearchCommand(searchCommand: VoucherSearchCommandModel) {
        searchCommand.productRestrictionProducts = this.getRestrictionProductsToCommand();
        searchCommand.productRestrictionProductNumbers = this.getRestrictionProductNumbersToCommand();
        searchCommand.productRestrictionRoutes = this.getRestrictionRoutesToCommand();
        searchCommand.productRestrictionLocations = this.getRestrictionLocationsToCommand();
    }

    public getValidityToCommand(): ProductValidityCommandModel[] {
        return this.getValidity();
    }

    public getRestrictionOrganisationsToCommand(): ProductRestrictionOrganisationCommandModel[] {
        let pointofSalesCommands = this.getPointOfSalesOrganisationProductRestriction();
        let pointOfPaymentCommands = this.getPointOfPaymentsOrganisationProductRestriction();
        return pointofSalesCommands.concat(pointOfPaymentCommands);
    }

    public getHashtagValue(): ProductHashTagCommandModel[] {
        return this.hashTagMapperService.productHashTagModels(this.productHashtags);
    }

    public getAttributeValue(): ProductAttributeCommandModel[] {
        return this.attributeComponent.getAttribute();
    }

    public getOriginDestination(): ProductAttributeCommandModel[] {
        return this.originDestinationComponent.getAttribute();
    }

    public getValidity() {
        return this.validityMapperService.productValidityFormToModels(this.validityComponent?.ruleComponent?.forms);
    }

    public getRestriction() {
        return this.restrictionMapperService.productRestrictionFormToCommandViews(this.restrictionComponent?.ruleComponent?.forms);
    }

    public getPointOfSales() {
        return this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(this.pointOfSalesComponent?.ruleComponent?.forms);
    }

    public getPointOfPayments() {
        return this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(this.pointOfPaymentsComponent?.ruleComponent?.forms);
    }

    public fillRestrictionsToForm(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
            let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
                productRestrictionProductNumbers,
                productRestrictionRoutes,
                productRestrictionLocations,
                null, false);
    
            let productRestrictionInheritViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
                productRestrictionProductNumbers,
                productRestrictionRoutes,
                productRestrictionLocations, 
                null, true);
    
            this.restrictionComponent?.ruleComponent?.addRestrictionViewToFormGroup(productRestrictionViews);
            this.restrictionComponent?.ruleComponent?.addRestrictionInheritToFormGroup(productRestrictionInheritViews);
        }

    public fillValiditiesToForm(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        this.validityComponent?.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
    }

    public fillAttributesToForm(productAttributes: ProductAttributeViewModel[]) {
        this.attributeComponent?.fillAttributesToForm(productAttributes);
    }

    public fillOriginDestinationToForm(productAttributes: ProductAttributeViewModel[]) {
        this.originDestinationComponent?.fillAttributesToForm(productAttributes, 'ORIGINDESTINATION');
    }

    public fillPointOfSalesToForm(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        productRestrictionOrganisations = productRestrictionOrganisations.filter(item => !item.productRestrictionOrganisationTypeCode || item.productRestrictionOrganisationTypeCode == this.pointOfSalesTypeCode);
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        this.pointOfSalesComponent?.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
    }

    public fillPointOfPaymentsToForm(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[]) {
        productRestrictionOrganisations = productRestrictionOrganisations.filter(item => item.productRestrictionOrganisationTypeCode == this.pointOfPaymentTypeCode);
        let pointOfPaymentsViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews([], productRestrictionOrganisations, false);
        this.pointOfPaymentsComponent?.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfPaymentsViews);
    }

    public setProductHashTagValue(value: ProductHashTagViewModel[]) {
        let productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReferences$.value, value, false);
        let selectedHashTag = productHashtagViews.filter(x => x.productHashTagId != null);
        if (selectedHashTag?.length > 0) {
            this.hashtagComponent.add();
        }
        this.productHashTag$.next(productHashtagViews);
    }

    public clearAttributeAndRule() {
        this.setProductHashTagValue(null);
        this.attributeComponent?.clearAttribute();
        this.originDestinationComponent?.clearAttribute();
        this.restrictionComponent?.ruleComponent?.clearForm();
        this.validityComponent?.ruleComponent?.clearForm();
        this.pointOfSalesComponent?.ruleComponent?.clearForm();
    }

    public focusAttribute() {
        this.focusingService.focus(this.attributeComponent.focusingDirective);
    }

    private getPointOfSalesOrganisationProductRestriction(): ProductRestrictionOrganisationCommandModel[] {
        let pointofSalesCommand = this.getPointOfSales();
        let restrictionOrganisationsPointofSales = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        return this.fillProductRestrictionOrganisationTypeCode(restrictionOrganisationsPointofSales, this.pointOfSalesTypeCode)
    }

    private getPointOfPaymentsOrganisationProductRestriction(): ProductRestrictionOrganisationCommandModel[] {
        let pointofPaymentsCommand = this.getPointOfPayments();
        let restrictionOrganisationsPointofSales = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofPaymentsCommand);
        return this.fillProductRestrictionOrganisationTypeCode(restrictionOrganisationsPointofSales, this.pointOfPaymentTypeCode)
        
    }

    private fillProductRestrictionOrganisationTypeCode(commandModels: ProductRestrictionOrganisationCommandModel[], productRestrictionOrganisationTypeCode: string): ProductRestrictionOrganisationCommandModel[] {
        for (let command of commandModels) {
            command.productRestrictionOrganisationTypeCode = productRestrictionOrganisationTypeCode;
        }
        return commandModels;
    }

    public setupAttributeForNew() {
        this.attributeComponent.displayShowOnNewAndRequiredAttribute();
        this.originDestinationComponent.displayShowOnNewAndRequiredAttribute();
    }

    public hashtagValueReturn(value) {
        this.productHashtags = this.hashTagMapperService.productHashTagValueToViews(value, this.productHashTag$.value);
    }
}

export class SearchConditionView {
    private readonly defaultStatusCode = 'A';

    public priceCode: string;
    public priceName: string;
    public usageTypeCode: string;
    public status: string = this.defaultStatusCode;
    constructor(usageTypeCode: string) {
        this.usageTypeCode = usageTypeCode;
    }
}
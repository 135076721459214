import { PriceRulePriceModel } from "src/app/modules/pricing/prices/shared/models";
import { PriceRuleAttributeModel, PriceRuleFormOfPaymentModel, PriceRuleIndividualModel, PriceRuleLocationModel, PriceRuleMembershipModel, PriceRuleOrganisationModel, PriceRuleProductModel, PriceRuleProductNumberModel, PriceRuleProductOptionModel, PriceRuleRouteModel, PriceRuleSalesBucketModel, PriceRuleServiceCategoryModel, PriceRuleValidityModel, PriceRuleVehicleModel } from ".";
import { LanguageTranslationSetCommandModel } from "../language-translation-set-model";

export abstract class PriceRuleBase {
    public id: string;
    public parentPriceRuleId: string;
    public priceRuleCode: string;
    public priceRuleName: string;
    public priceRuleConcatenateFlag: boolean;
    public priceRuleTypeCode: string;
    public priceRuleGroupCode: string;
    public priceRuleCategoryCode: string;
    public priceRuleDisplayCode: string;
    public priceRuleDisplayName: string;
    public priceRuleTypeGroupName: string;
    public usageTypeCode: string;
    public statusCode: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public isOwnerFilter: boolean;
    public ownerFilterUserAccountId: string;
    public productNumbers: PriceRuleProductNumberModel[];
    public routes: PriceRuleRouteModel[];
    public locations: PriceRuleLocationModel[];
    public validities: PriceRuleValidityModel[];
    public vehicles: PriceRuleVehicleModel[];
    public attributes: PriceRuleAttributeModel[];
    public organisations: PriceRuleOrganisationModel[];
    public serviceCategories: PriceRuleServiceCategoryModel[];
    public salesBuckets: PriceRuleSalesBucketModel[];
    public products: PriceRuleProductModel[];
    public individuals: PriceRuleIndividualModel[];
    public memberships: PriceRuleMembershipModel[];
    public productOptions: PriceRuleProductOptionModel[];
    public formOfPayments: PriceRuleFormOfPaymentModel[];
    public priceRulePrices: PriceRulePriceModel[];
    public languageTranslationSet: LanguageTranslationSetCommandModel;
    public commitBy: string;
    public commitDateTime: Date;
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { TransportVehicleChangeCommandModel, TransportStatusChangeCommandModel, TransportRouteAndTimingChangeCommandModel } from "../../models/product-model/transport-model";
import { TransportNumberChangeCommandModel } from "../../models/product-model/transport-model/transport-number-change-command.model";

@Injectable({
    providedIn: 'root'
})
export class ScheduleChangeService extends ApiServiceBase {
    private readonly PATH = "/schedule/scheduleChanges";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }
    public changeProductVehicle(command: TransportVehicleChangeCommandModel) {
        return this.httpPut(this.PATH + "/vehicle", command);
    }

    public changeProductStatus(command: TransportStatusChangeCommandModel) {
        return this.httpPut(this.PATH + "/status", command);
    }

    public changeProductNumber(command: TransportNumberChangeCommandModel) {
        return this.httpPut(this.PATH + '/number', command);
    }

    public changeRouteAndTiming(command: TransportRouteAndTimingChangeCommandModel) {
        return this.httpPut(this.PATH + '/routeAndTiming', command);
    }
}
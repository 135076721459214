import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import {
    PriceRuleModel,
    PriceRuleProductOptionModel,
} from "src/app/core/models/pricing-model";
import { ProductService } from "src/app/core/services/product-services/product.service";
import {
    ProductCategoryReferenceService,
    ProductGroupReferenceService,
    UnitReferenceService,
} from "src/app/core/services/system-services";
import { ProductTypeGroupService } from "src/app/core/services/system-services/product-type-group.service";
import { duplicateGroupValidation } from "src/app/core/validators";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceModel } from "../../../prices/shared/models";
import { PriceService } from "../../../prices/shared/price.service";
import { PriceRuleView } from "../../../shared/views";
import { Select2Themes } from "../attribute-and-rule/product-eligible-restricted/shared/select2-options.service";
import { Select2Option } from "../attribute-and-rule/shared/attribute-and-rule/views/select2.option";
import { PriceRuleProductOptionView } from "./shared/views/price-rule-product-option.view";

@Component({
    selector: "op-inclusive-of-products",
    templateUrl: "inclusive-of-products.component.html",
})
export class InclusiveOfProductsComponent
    extends OopsComponentFormBase
    implements OnChanges
{
    readonly activeStatusCode = "A";
    readonly usageDataTypeCode = "DATA";
    readonly formArrayName = "productOptions";
    readonly productCategoryCode = "productCategoryCode";
    readonly productGroupCode = "productGroupCode";
    readonly productTypeCode = "productTypeCode";
    readonly productId = "productId";
    readonly unitCode = "unitCode";
    readonly numberOfUnits = "numberOfUnits";
    @Input() priceRuleView: PriceRuleView;
    @Input() disabled: boolean = false;

    focusing = false;
    select2ProductCategoryOption = new Select2Option("<Product Category>");
    select2ProductGroupOption = new Select2Option("<Product Group>");
    select2ProductTypeOption = new Select2Option("<Product Type>");
    select2ProductOption = new Select2Option("<Product>");
    select2PriceOption = new Select2Option("<Prices>");
    select2UnitTypeOption = new Select2Option("<Unit Type>");

    dropdownOptionCollections = new Array<Select2Data[]>();
    panelCollapseFlag$ = new BehaviorSubject<boolean>(true);

    get productOptions(): UntypedFormArray {
        return <UntypedFormArray>this.formGroup.get(this.formArrayName);
    }

    get allowToggle(): boolean {
        return !!(this.productOptions?.length)
    }

    constructor(
        formBuilder: UntypedFormBuilder,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private productService: ProductService,
        private priceService: PriceService,
        private unitReferenceService: UnitReferenceService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(formBuilder);
    }

    public initForm() {
        let formGroup = this.toFormGroup(this.priceRuleView);
        let formArray = <UntypedFormArray>formGroup.get(this.formArrayName);
        for (let productOption of <UntypedFormGroup[]>formArray.controls) {
            this.fetchAllDropdownOptions(formArray, productOption);
        }
        this.formGroup = formGroup;
        this.togglePanelCollapse()
        this.setupDisabled();
    }

    togglePanelCollapse() {
        if (this.productOptions?.length) {
            this.panelCollapseFlag$.next(false);
        } else {
            this.panelCollapseFlag$.next(true);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["priceRuleView"]) {
            this.initForm();
        }
    }

    private toFormGroup(priceRuleView: PriceRuleView): UntypedFormGroup {
        return new UntypedFormGroup({
            productOptions: this.toFormArray(priceRuleView?.productOptions),
        });
    }

    private toFormArray(productOptions: PriceRuleProductOptionView[]): UntypedFormArray {
        let formArray = new UntypedFormArray([], duplicateGroupValidation);
        if (!productOptions?.length) {
            return formArray;
        }
        for (let productOption of productOptions) {
            let group = this.modelToFormGroup(productOption);
            formArray.push(group);
        }
        return formArray;
    }

    private modelToFormGroup(productOption: PriceRuleProductOptionView) {
        let formGroup = new UntypedFormGroup({
            priceRuleProductOptionId: new UntypedFormControl(
                productOption.priceRuleProductOptionId
            ),
            productCategoryCode: new UntypedFormControl(
                productOption.productCategoryCode,
                Validators.required
            ),
            productGroupCode: new UntypedFormControl(productOption.productGroupCode),
            productTypeCode: new UntypedFormControl(productOption.productTypeCode),
            productId: new UntypedFormControl(productOption.productId),
            priceId: new UntypedFormControl(productOption.priceId),
            unitCode: new UntypedFormControl(
                productOption.unitCode,
                Validators.required
            ),
            numberOfUnits: new UntypedFormControl(productOption.numberOfUnits),
            displaySequence: new UntypedFormControl(productOption.displaySequence),
            priceRuleId: new UntypedFormControl(productOption.priceRuleId),
        });

        this.toggleInheritedProductOption(formGroup);
        return formGroup;
    }

    private toggleInheritedProductOption(formGroup: UntypedFormGroup) {
        const priceRuleId = formGroup.get('priceRuleId').value;
        for (let key of Object.keys(formGroup.controls)) {
            const control = formGroup.get(key);
            if (priceRuleId && priceRuleId != this.priceRuleView.priceRuleId) {
                control.disable();
            } else if (priceRuleId == this.priceRuleView.priceRuleId) {
                control.enable();
            }
        }
    }

    add() {
        this.startProcessing();
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }

        this.completeProcessing();
        const formArray = <UntypedFormArray>this.formGroup.get(this.formArrayName);
        let formGroup = this.modelToFormGroup({
            displaySequence: formArray.length + 1,
        });
        formArray.push(formGroup);

        this.dropdownOptionCollections.push(new Array<Select2Data>());
        this.initDropdownOptions(this.productOptions, formGroup);
        this.panelCollapseFlag$.next(false);
    }

    private fetchAllDropdownOptions(
        formArray: UntypedFormArray,
        formGroup: UntypedFormGroup
    ) {
        this.dropdownOptionCollections.push(new Array<Select2Data>());
        this.initDropdownOptions(formArray, formGroup);
        this.updateProductGroupReferencesDropdownCollection(
            formArray,
            formGroup,
            formGroup.get(this.productCategoryCode).value
        );
        this.updateProductTypeReferencesDropdownCollection(
            formArray,
            formGroup,
            formGroup.get(this.productGroupCode).value
        );
        this.updateProductsDropdownCollection(
            formArray,
            formGroup,
            formGroup.get(this.productTypeCode).value
        );
        this.onPriceIdChange(formGroup.get("priceId").value, formGroup);
    }

    private initDropdownOptions(formArray: UntypedFormArray, formGroup: UntypedFormGroup) {
        const dropdownOptionCollection =
            this.dropdownOptionCollections[this.indexOf(formArray, formGroup)];
        dropdownOptionCollection["productCategoryReferences$"] =
            this.productCategoryReferenceService
                .getAll()
                .pipe(
                    map((res) =>
                        res.map(
                            (model) =>
                                new Select2Data(
                                    model.productCategoryCode,
                                    model.productCategoryName
                                )
                        )
                    )
                );

        dropdownOptionCollection["productGroupReferences$"] = new Observable<
            Select2Data[]
        >();
        dropdownOptionCollection["productTypeReferences$"] = new Observable<
            Select2Data[]
        >();
        dropdownOptionCollection["products$"] = new Observable<Select2Data[]>();
        dropdownOptionCollection["prices$"] = this.priceService
            .search(<PriceModel>{
                status: this.activeStatusCode,
                usageTypeCode: this.usageDataTypeCode
            })
            .pipe(
                map((res) =>
                    res.map(
                        (item) => new Select2Data(item.priceId, item.priceName)
                    )
                )
            );
        dropdownOptionCollection["unitReferences$"] = this.unitReferenceService
            .getUnitReferences()
            .pipe(
                map((res) =>
                    res.map(
                        (item) => new Select2Data(item.unitCode, item.unitName)
                    )
                )
            );
    }

    getDropdownCollection(index: number, key: string): Observable<Select2Data[]> {
        return this.dropdownOptionCollections[index][key];
    }

    updateDropdownCollection(
        index: number,
        key: string,
        data: Observable<Select2Data[]>
    ) {
        this.dropdownOptionCollections[index][key] = data;
    }

    delete(formGroup: UntypedFormGroup) {
        const formArray = <UntypedFormArray>this.formGroup.get(this.formArrayName);
        const index = this.indexOf(this.productOptions, formGroup);
        formArray.removeAt(index);
        this.dropdownOptionCollections = this.dropdownOptionCollections.filter(
            (col) => col != this.dropdownOptionCollections[index]
        );
        this.togglePanelCollapse();
    }

    public indexOf(formArray: UntypedFormArray, formGroup: UntypedFormGroup): number {
        return formArray.controls.findIndex((i) => i === formGroup);
    }

    public getSingleOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.SINGLE_VALUE_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    onProductCategoryChange(productCategoryCode: any, formGroup: UntypedFormGroup) {
        this.onValueChange(productCategoryCode, formGroup, 'productCategoryCode');
        formGroup.get("productGroupCode").reset();
        formGroup.get("productTypeCode").reset();
        formGroup.get("productId").reset();
        this.updateProductGroupReferencesDropdownCollection(
            this.productOptions,
            formGroup,
            productCategoryCode
        );
    }

    private updateProductGroupReferencesDropdownCollection(
        formArray: UntypedFormArray,
        formGroup: UntypedFormGroup,
        productCategoryCode: string
    ) {
        this.updateDropdownCollection(
            this.indexOf(formArray, formGroup),
            "productGroupReferences$",
            this.productGroupReferenceService
                .getByProductCategory(productCategoryCode)
                .pipe(
                    map((res) =>
                        res.map(
                            (item) =>
                                new Select2Data(
                                    item.productGroupCode,
                                    item.productGroupName
                                )
                        )
                    )
                )
        );
    }

    onProductGroupCodeChange(productGroupCode: any, formGroup: UntypedFormGroup) {
        this.onValueChange(productGroupCode, formGroup, 'productGroupCode')
        formGroup.get("productTypeCode").reset();
        formGroup.get("productId").reset();
        this.updateProductTypeReferencesDropdownCollection(
            this.productOptions,
            formGroup,
            productGroupCode
        );
    }

    private updateProductTypeReferencesDropdownCollection(
        formArray: UntypedFormArray,
        formGroup: UntypedFormGroup,
        productGroupCode: string
    ) {
        this.updateDropdownCollection(
            this.indexOf(formArray, formGroup),
            "productTypeReferences$",
            this.productTypeGroupService
                .getByProductGroupCode(productGroupCode)
                .pipe(
                    map((res) =>
                        res.map(
                            (item) =>
                                new Select2Data(
                                    item.productTypeCode,
                                    item.productTypeName
                                )
                        )
                    )
                )
        );
    }

    onProductTypeCodeChange(productTypeCode: any, formGroup: UntypedFormGroup) {
        this.onValueChange(productTypeCode, formGroup, 'productTypeCode');
        this.updateProductsDropdownCollection(
            this.productOptions,
            formGroup,
            productTypeCode
        );
    }

    private updateProductsDropdownCollection(
        formArray: UntypedFormArray,
        formGroup: UntypedFormGroup,
        productTypeCode: string
    ) {
        this.updateDropdownCollection(
            this.indexOf(formArray, formGroup),
            "products$",
            this.productService
                .getBy("", productTypeCode, "")
                .pipe(
                    map((res) =>
                        res.map(
                            (item) =>
                                new Select2Data(
                                    item.productId,
                                    item.productName
                                )
                        )
                    )
                )
        );
    }

    onPriceIdChange(priceId: any, formGroup: UntypedFormGroup) {
        this.onValueChange(priceId, formGroup, 'priceId');
        if (!priceId) {
            return;
        }
        this.priceService.getById(priceId).subscribe(
            (res) => {
                const unitCode = res.unitCode;

                formGroup.get(this.unitCode).setValue(unitCode);
                formGroup.get(this.unitCode).disable();

                formGroup.get(this.numberOfUnits).reset();
                formGroup.get(this.numberOfUnits).disable();

                this.changeDetectorRef.markForCheck();
            }
        );
    }

    getValues(priceRuleModel: PriceRuleModel): PriceRuleModel {
        this.startProcessing();
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return null;
        }
        priceRuleModel.productOptions = this.formToModels();
        this.completeProcessing();
        return priceRuleModel;
    }

    private formToModels(): PriceRuleProductOptionModel[] {
        let models = new Array<PriceRuleProductOptionModel>();
        let displaySequence = 1;
        for (let productOption of <UntypedFormGroup[]>this.productOptions.controls) {
            if (
                productOption.get("priceRuleId").value ==
                    this.priceRuleView.priceRuleId ||
                !productOption.get("priceRuleProductOptionId").value
            ) {
                let model: PriceRuleProductOptionModel =
                    productOption.getRawValue();
                if (model.numberOfUnits) {
                    model.numberOfUnits = +model.numberOfUnits;
                }
                model.displaySequence = displaySequence;
                models.push(model);
            }
            displaySequence++;
        }
        return models;
    }

    public displayErrorBorder(formGroup: UntypedFormGroup, name: string): boolean {
        return (
            (formGroup.controls[name].invalid &&
                (formGroup.controls[name].dirty ||
                    formGroup.controls[name].touched) &&
                this.processing) ||
            formGroup.errors?.duplicate
        );
    }

    hideDeleteButton(formGroup: UntypedFormGroup): boolean {
        let priceRuleId = formGroup.get("priceRuleId").value;
        return !!priceRuleId && priceRuleId != this.priceRuleView.priceRuleId;
    }

    togglePanelCollapseStatus(isCollapse: boolean) {
        if (!isCollapse && !this.productOptions?.length) {
            return;
        }
        this.panelCollapseFlag$.next(isCollapse);
    }

    setupDisabled() {
        if (this.disabled == true) {
            this.formGroup.disable();
            return;
        }
        this.enableCertainControls();
    }

    private enableCertainControls() {
        for (let productOption of this.productOptions.controls) {
            const group = productOption as UntypedFormGroup;
            this.toggleInheritedProductOption(group);
        }
    }

    public getFormGroups(formArray: UntypedFormArray): UntypedFormGroup[] {
        return formArray.controls.map(ctrl => ctrl as UntypedFormGroup);
    }

    public onValueChange(value: any, field: UntypedFormGroup, fieldName: string) {
        field.get(fieldName).patchValue(value);
    }

    public onInputChange(event: Event, field: UntypedFormGroup, fieldName: string) {
        field.get(fieldName).patchValue((event.target as HTMLInputElement).value);
    }
}

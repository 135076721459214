import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms'

import { Select2Module } from '../../inputs/oops-select2/select2.module';
import { DaterangepickerModule } from '../../inputs/oops-daterangepicker/daterangepicker.module';
import { OopsChoiceValueComponent } from "./oops-choice-value.component";
import { NumberOnlyModule } from "../number-only/number-only.module";
import { NonNegativeNumberModule } from "../non-negative-number/non-negative-number.module";

@NgModule({
    imports: [
        CommonModule,
        DaterangepickerModule,
        FormsModule,
        NonNegativeNumberModule,
        NumberOnlyModule,
        Select2Module,
    ],
    declarations: [
        OopsChoiceValueComponent
    ],
    exports: [
        OopsChoiceValueComponent
    ]
})
export class OopsChoiceValueModule { }
import { Select2Themes } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service";

declare var moment: any;

export const TypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const TypeOrganisationOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto",
    disabled: true
}

export const OriginLocationIdOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Origin>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const DestinationLocationIdOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Destination>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const AreaOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Area>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const UnitTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Unit Type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const AttributeTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const AttributeChoiceMultipleOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Choices>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const AttributeChoiceSingleOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Choices>',
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ConditionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    theme: Select2Themes.CONDITION_SELECTOR_THEME,
    width: "auto",
    disabled: true
}

export const ProductNumberOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Product Number>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const OrganisationIdOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Name>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const OrganisationTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Type>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const OrganisationRoleOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Role>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const OrganisationGroupOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Group>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const RegionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Region>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const CountryOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Country>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const AirportOrCityOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Airport/City>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const ServiceCategoryOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Service Category>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const SalesBucketOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Sales Bucket>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const LevelOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Level>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const CurrencyOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Currency>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const StartDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
    placeholder: '<Start Date>'
}

export const EndDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
    placeholder: '<End Date>'
}
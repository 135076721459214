//How to use.
//Install "npm install --save pdfmake" is required.
//  1. Inject service to component.
//  2. call function downloadPdf(exportContent). The export content can found in PDFMake document.
//  https://pdfmake.github.io/docs/ 
import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { cloneDeep } from "lodash";
import { StringHelperService } from 'src/app/core/utils/string-helper.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class PdfExportService {
    constructor(
        private stringHelperService: StringHelperService
    ) { }

    public lastHeader = []
    public lastHeaderDataField = [];
    public datalevel = [];
    private dataRows = [];
    public indexGroup = 0;
    public rowsItem = 0;
    public dataGroupRow = [];
    public dataItem = 0;
    public dataFieldCount = [];
    public dataFielsSum = [];
    public subHeaderLevel = 1;
    public subColumsHeader = [];
    public lastSubHeader = [];
    public lastSubHeaderDataField = [];

    public download(filename: string, exportContent: any) {
        pdfMake.createPdf(exportContent).download(filename + '.pdf');
    }

    public downloadJsonData(filename: string, exportJsonContent: any, defaultStyle: any = null, widthPercentages: any = null, pageOrientation: string = null, pageSize: string = null) {
        var bodyContent = this.buildTableBody(exportJsonContent, this.getFieldName(exportJsonContent))
        var docDefinition = {
            content: [
                {
                    table: {
                        headerRows: 1,
                        body: bodyContent,
                        widths: widthPercentages,
                    }
                }
            ]
        };
        if (defaultStyle) {
            docDefinition['defaultStyle'] = defaultStyle;
        }
        if (pageOrientation){
            docDefinition['pageOrientation'] = pageOrientation;
        }
        if (pageSize) {
            docDefinition['pageSize'] = pageSize;
        }
        pdfMake.createPdf(docDefinition).download(filename + '.pdf');
    }

    private buildTableBody(data, cols) {
        let body = [];
        body.push(cols);

        data.forEach(function (row) {
            let dataRow = [];
            cols.forEach(function (column) {
                dataRow.push(row[column]);
            })
            body.push(dataRow);
        });
        return body;
    }

    private getFieldName(exportContent) {
        let jsonData: any = exportContent;
        let col = [];
        for (let i = 0; i < jsonData.length; i++) {
            for (let key in jsonData[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }
        return col;
    }

    private buildHeaderTable(headerLevel, columsHeader) {
        let header = [];
        let hasRowSpansIndex = [];
        for (let index = 0; index < headerLevel; index++) {
            let dataHeader = columsHeader.filter(dataHeader => dataHeader.headerLevel == index);
            let rowData = [];
            this.lastHeader = [];
            this.lastHeaderDataField = [];
            dataHeader.forEach(element => {
                let colSpan = element.subColumn.length;
                if (!element.ownerBand && colSpan == 0) {
                    hasRowSpansIndex.push({ caption: element.caption, colums: rowData.length, dataField: element.dataField });
                }
                rowData = this.addDataRow(rowData, element, colSpan, index, headerLevel);
                this.setLastHeader(element.name, element.dataField, element.alignment);
            });

            let rowDataHasRowSpans;
            hasRowSpansIndex.forEach(e => {
                if (index > 0) {
                    rowDataHasRowSpans = this.insertDataColumsHasRowSpans(e.colums, e.caption, rowData);
                    this.insertDatafieldColumsHasRowSpans(e.colums, e.dataField);
                }
            });
            this.lastHeader = rowDataHasRowSpans;
            header[index] = rowData;
        }
        return header;
    }

    private insertDataColumsHasRowSpans(colums: any, text: string, rowData: any) {
        let dataSplice = { text: text };
        rowData.splice(colums, 0, dataSplice);
        this.lastHeader.splice(colums, 0, dataSplice);
        return this.lastHeader;
    }

    private insertDatafieldColumsHasRowSpans(colums: any, text: string) {
        let dataSplice = { text: text };
        this.lastHeaderDataField.splice(colums, 0, dataSplice);
        return this.lastHeaderDataField;
    }

    private setLastHeader(text, dataField, alignment) {
        let lastHeader = {
            text: text
        }
        this.lastHeader.push(lastHeader);
        let lastHeader1 = {
            noCount: true,
            text: dataField,
            sumValue: '',
            alignment: alignment
        }
        this.lastHeaderDataField.push(lastHeader1);
    }

    private getWidthsTable(lastHeader) {
        let widths = [];
        lastHeader.forEach((data, i) => {
            let width = 'auto';
            if (i == 1) {
                width = '*';
            }
            widths.push(width);
        });
        return widths;
    }

    private buildBodyTable(data) {
        let lastHeaderDataField = this.lastHeaderDataField;
        if (!data.items) {
            this.indexGroup = this.indexGroup - this.rowsItem;
            this.rowsItem = 0;
        } else {
            this.dataItem = data.items;
            this.rowsItem++;
        }
        this.indexGroup++;
        data.forEach((ele, di) => {
            if (ele.items) {
                let item = [];
                item.push(ele.key);
                this.dataGroupRow.push(item)
                this.buildBodyTable(ele.items);
            } else {
                if (this.dataGroupRow.length > 0) {
                    let divColumn = this.datalevel.length - this.dataGroupRow.length;
                    this.dataGroupRow.forEach((e, index) => {
                        let item = [];
                        let margin = index + divColumn;
                        item.push({ text: this.datalevel[divColumn + index].caption + ': ' + e, colSpan: 3, margin: [margin * 10, 0, 0, 0], fillColor: '#e9e9e9' });
                        for (let i = 1; i < lastHeaderDataField.length; i++) {
                            let text = '';
                            let fillColumnCount = this.dataFieldCount.filter(data => data == lastHeaderDataField[i].text);
                            if (fillColumnCount.length > 0 && this.dataGroupRow.length == index + 1) {
                                text = (data.length).toString();
                            }
                            let fillColumnSum = this.dataFielsSum.filter(data => data == lastHeaderDataField[i].text);
                            if (fillColumnSum.length > 0 && this.dataGroupRow.length == index + 1) {
                                text = this.getSumData(data, lastHeaderDataField[i].text);
                            }
                            item.push({ text: text, fillColor: '#e9e9e9', alignment: 'right' });
                        }
                        this.dataRows.push(item);
                    });
                }
                let row = [];
                lastHeaderDataField.forEach((element, i) => {
                    let text = ele[element.text] ? ele[element.text].toString() : '';
                    if ((element.text)?.toString().toUpperCase() == 'NO') {
                        di++;
                        text = di;
                    }
                    let fillColumnCount = this.dataFieldCount.filter(data => data == lastHeaderDataField[i].text);
                    if (fillColumnCount.length > 0) {
                        text = '';
                    }
                    row.push({
                        text: text,
                        alignment: (element.alignment) ? element.alignment : 'left'
                    });
                });
                this.dataRows.push(row);
                this.dataGroupRow = [];
                if (this.subColumsHeader.length > 0) {
                    this.addDetailTable(ele.chargeItems);
                }
            }
        });
        return this.dataRows;
    }

    public getSumData(data, dataField) {
        let sumValue = 0;
        data.forEach(element => {
            let value = parseFloat(element[dataField]);
            sumValue += value;
        });
        return this.stringHelperService.validateNullAmountDecimal(sumValue);
    }

    public loadPdfGroupData(headerLevel: any, columsHeader: any, filename: string, pdfDefaultStyle: any, exportRowData: any, groupingIndex: any, dataFieldCount: any,
        dataFielsSum: any, detailHeaderLevel: any, detialColumsHeader: any) {
        this.subHeaderLevel = detailHeaderLevel;
        this.subColumsHeader = detialColumsHeader;

        this.dataFieldCount = dataFieldCount;
        this.dataFielsSum = dataFielsSum;
        this.datalevel = cloneDeep(groupingIndex);
        let header = this.buildHeaderTable(headerLevel, columsHeader);
        this.dataRows = [];
        this.indexGroup = 0;
        let body = this.buildBodyTable(exportRowData);
        let bodyContent = header.concat(body);
        let widthsTable = this.getWidthsTable(this.lastHeader);
        var docDefinition = {
            content: [
                {
                    table: {
                        headerRows: headerLevel,
                        widths: widthsTable,
                        body: bodyContent
                    }
                }
            ]
        };
        if (pdfDefaultStyle) {
            docDefinition['defaultStyle'] = pdfDefaultStyle;
        }
        pdfMake.createPdf(docDefinition).download(filename + '.pdf');
    }

    private buildDetailHeaderTable(subHeaderLevel, subColumsHeader) {
        let header = [];
        let hasRowSpansIndex = [];
        for (let index = 0; index < subHeaderLevel; index++) {
            let dataHeader = subColumsHeader.filter(dataHeader => dataHeader.headerLevel == index);
            let rowData = [];
            let lastSubHeaderDataField = [];
            dataHeader.forEach(element => {
                let colSpan = element.subColumn.length;
                if (!element.ownerBand && colSpan == 0) {
                    hasRowSpansIndex.push({ caption: element.caption, colums: rowData.length, dataField: element.dataField, alignment: element.alignment });
                }
                rowData = this.addDataRow(rowData, element, colSpan, index, subHeaderLevel)
                let rowFilde = {
                    text: element.dataField,
                    alignment: (index == 0) ? 'center' : (element.alignment) ? element.alignment : 'left'
                }
                lastSubHeaderDataField.push(rowFilde);
                if (colSpan > 0) {
                    for (let i = 1; i < colSpan; i++) {
                        lastSubHeaderDataField.push({});
                    }
                }
            });

            hasRowSpansIndex.forEach(e => {
                if (index > 0) {
                    rowData.splice(e.colums, 0, { text: e.caption });
                    lastSubHeaderDataField.splice(e.colums, 0, { text: e.dataField, alignment: e.alignment });
                }
            });
            this.lastSubHeader = rowData;
            this.lastSubHeaderDataField = lastSubHeaderDataField;
            header[index] = rowData;
        }
        return header;
    }

    private addDetailTable(chargeItems) {
        let subHeaderLevel = this.subHeaderLevel;
        let subColumsHeader = this.subColumsHeader;
        let header = this.buildDetailHeaderTable(subHeaderLevel, subColumsHeader);
        let body = this.getDetailBody(chargeItems, this.lastSubHeaderDataField);
        let widthsTable = this.getWidthsTable(this.lastSubHeader);
        let colSpan = this.lastHeaderDataField.length;
        let bodyContent = header.concat(body);
        let rowDetail = [];
        let tableDetail = {
            table: {
                headerRows: subHeaderLevel,
                widths: widthsTable,
                body: bodyContent
            },
            colSpan: colSpan,

        };
        rowDetail.push(tableDetail);
        for (let i = rowDetail.length; i < colSpan; i++) {
            rowDetail.push({});
        }
        this.dataRows.push(rowDetail)
    }

    private getDetailBody(chargeItems: any, lastSubHeaderDataField: any) {
        let allChargeItems = [];
        chargeItems.forEach(e => {
            let item = [];
            lastSubHeaderDataField.forEach(element => {
                let text = e[element.text] ? e[element.text].toString() : '';
                item.push(
                    {
                        text: text,
                        alignment: (element.alignment) ? element.alignment : 'left'
                    }
                );
            });
            allChargeItems.push(item)
        });
        return allChargeItems;
    }

    private addDataRow(rowData, element, colSpan, index, headerLevel) {
        let row = {
            text: element.caption,
            colSpan: (colSpan > 0) ? colSpan : 1,
            rowSpan: (index == 0) ? ((colSpan == 0) ? headerLevel : 1) : 1,
            alignment: (index == 0) ? 'center' : 'left'
        }
        rowData.push(row);
        if (colSpan > 0) {
            for (let i = 1; i < colSpan; i++) {
                rowData.push({});
            }
        }
        return rowData;
    }
}
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ConditionReferenceModel, DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { DomainAttributeService } from "src/app/core/services/airline-services";
import { ConditionReferenceService } from "src/app/core/services/system-services";
import { PricingDetailConstant } from "../../shared/pricing-detail.constant";
import { Select2OptionsService } from "../product-eligible-restricted/shared/select2-options.service";
import { PriceRuleAttributeAndRuleRowView, PriceRuleAttributeView } from "../product-eligible-restricted/shared/views";
import { AttributeAndRuleBase } from "../shared/attribute-and-rule.base";
import { Select2Option } from "../shared/attribute-and-rule/views/select2.option";

@Component({
    templateUrl: './transit-stopover.component.html'
})
export class TransitStopoverComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {
    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        this.rows$.next(val);
        this.togglePanelCollapseStatus(false);
    }
    @Input() searchMode: boolean = false;
    @Input() priceRuleTypeCode: string = "";
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter<PriceRuleAttributeAndRuleRowView[]>();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    public rows$ = new BehaviorSubject<PriceRuleAttributeAndRuleRowView[]>([]);
    public domainAttributes$: Observable<DomainAttributeModel[]>;
    public conditionReferences$: Observable<ConditionReferenceModel[]>;

    public attributeOption = new Select2Option("<Type>");
    public conditionOption: Select2Option;

    public focusing: boolean = false;

    private unsubscribe$ = new Subject();

    constructor(private domainAttributeService: DomainAttributeService,
        private conditionReferenceService: ConditionReferenceService,
        private select2OptionService: Select2OptionsService) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.domainAttributes$ = this.domainAttributeService
            .getPriceRuleAttributes(this.priceRuleTypeCode, PricingDetailConstant.TRANSIT_STOPOVER_CODE, '')
            .pipe(
                map(
                    res => {
                        if (this.searchMode) {
                            return res.filter(r => r.searchFlag == true);
                        }
                        return res;
                    }
                )
            )
        this.conditionReferences$ = this.conditionReferenceService.getConditionsByCodes(
            [
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_NOT_OPERATOR_CODE,
                PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
                PricingDetailConstant.GREATER_THAN_OPERATOR_CODE
            ]
        )
        this.conditionOption = this.select2OptionService.getSelect2ConditionOption();
    }

    attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            this.dataChange.emit(this.rows$.value);
            return true;
        }
        this.addErrorFlag = true;
        return false;
    }

    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value;
            rows.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    onAttributeChange(row: PriceRuleAttributeAndRuleRowView, attributeTypeCode: any) {
        this.createNewPriceRuleAttribute(row, attributeTypeCode);
    }

    private createNewPriceRuleAttribute(row: PriceRuleAttributeAndRuleRowView, attributeTypeCode: string) {
        let attribute = new PriceRuleAttributeView();
        attribute.attributeTypeCode = attributeTypeCode;
        row.attribute = attribute;
        this.resetErrorFlags();
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public onInputChange(event: Event, field: any, fieldName: string) {
        field[fieldName] = (event.target as HTMLInputElement).value;
        this.resetErrorFlags();
    }
}
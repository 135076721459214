import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { PaymentSearch, PaymentSearchLedger } from "../payment-search.model";
import { PaymentSearchService } from "../payment-search.service";
import { PaymentStatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NavigationService } from "src/app/shared/utils/navigation";
import { OrdersConstant } from "src/app/modules/order/shared";

@Component({
    selector: 'op-payment-search-results',
    templateUrl: './payment-search-results.component.html'
})
export class PaymentSearchResultsComponent implements OnInit, OnDestroy {

    paymentSearch$ = new Observable<PaymentSearch[]>()
    paymentStatusMap: Map<string, PaymentStatusReferenceModel>
    focusing = false

    private subscriptions: Subscription[]

    constructor(private paymentSearchService: PaymentSearchService,
        private dateConverterService: DateConverterService,
        private stringHelperService: StringHelperService,
        private navigationService: NavigationService,) { }

    ngOnInit(): void {
        this.subscriptions = []
        this.paymentStatusMap = new Map<string, PaymentStatusReferenceModel>()
        this.paymentSearch$ = this.paymentSearchService.paymentSearch$
        this.subscriptions.push(
            this.paymentSearchService.paymentStatus$.subscribe(r => {
                for (const status of r) {
                    this.paymentStatusMap.set(status.paymentStatusCode, status)
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    selectPayment(e) {
        const selectedPayment = e?.component?.getSelectedRowsData()?.pop()
        this.paymentSearchService.setPaymentSearchSelection(selectedPayment)
    }

    dateTime24Format(date) {
        return this.dateConverterService.convertDateTime24(date)
    }

    validateNullAmountDecimal(amount) {
        return this.stringHelperService.validateNullAmountDecimal(amount)
    }

    findHighlightColorForPaymentStatusName(payment: PaymentSearch): string {
        return this.paymentStatusMap.get(payment.paymentStatusCode)?.highlightColor
    }

    openOrderDetail(ledger: PaymentSearchLedger) {
        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentSearchService.exchangeOrderId(ledger.orderNumber).subscribe(orderId => {
                this.paymentSearchService.setLoading(false)
                this.navigationService.navigate(`/main/orders/${Date.now().toString()}/details`,
                    OrdersConstant.HomeTitle,
                    true,
                    {
                        id: orderId,
                        orderNumberDisplay: ledger.orderNumber,
                        searchCriteria: {},
                    }
                );
            },
            () => this.paymentSearchService.setLoading(false))
        )

    }
}

import { Injectable } from "@angular/core";
import { InsightPassengerAddressModel, InsightPassengerAgeGroupModel, InsightPassengerAttributeModel, InsightPassengerCommandModel, InsightPassengerCommentModel, InsightPassengerCommunicationOtherModel, InsightPassengerCommunicationPhoneModel, InsightPassengerDocumentModel, InsightPassengerLanguageModel, InsightPassengerMembershipModel, InsightPassengerModel, InsightPassengerNameModel, InsightPassengerNationalityModel, InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { IndividualCivilStatusModel, IndividualInterestModel, IndividualPositionModel, IndividualPreferenceModel, IndividualProfessionModel, IndividualSocialModel } from "src/app/core/models/individual-model/individual-profiles-model";
import { AttributeRowView } from "../../insight-detail/insight-detail-attribute/shared/attribute-row-view";
import { ProfileRowView } from "../../insight-detail/insight-detail-profile/shared/profile-row-view";
import { InsightPassengerDocumentSearchView } from "./insight-document-search-view";

declare var moment: any;

@Injectable()
export class InsightSearchMapperService {
    private readonly CIVIL_STATUS = "CIVIL_STATUS";
    private readonly INTEREST = "INTEREST";
    private readonly POSITION = "POSITION";
    private readonly PREFERENCE = "PREFERENCE";
    private readonly PROFESSION = "PROFESSION";
    private readonly SOCIAL = "SOCIAL";
    private readonly LANGUAGE = "LANGUAGE"; 
    private readonly NATIONALITY = "NATIONALITY"; 
    private readonly AGE_GROUP = "AGE_GROUP"; 

    private readonly ACTIVE_CODE = "A";
    private readonly FILTER_TYPE_CODE = "FILTER";

    constructor() {
    }

    public insightSearchModelToDocumentFilterView(model: InsightPassengerSearchModel): InsightPassengerDocumentSearchView  {
        if (!model) {
            return null;
        }

        let documentFilter: InsightPassengerDocumentSearchView = {
            commentTypeCode: model.commentTypeCode,
            identityDocumentCode: model.identityDocumentCode,
            identityDocumentNumber: model.identityDocumentNumber,
            memberNumber: model.memberNumber,
            membershipLevelCode: model.membershipLevelCode
        }

        return documentFilter;
    }

    public filterViewToInsightSearchModel(filter: InsightPassengerSearchModel) : InsightPassengerSearchModel {
        let model = {} as InsightPassengerSearchModel;

        model.lastName = filter?.lastName?.trim();
        model.firstName = filter?.firstName?.trim();
        model.organisationId = filter?.organisationId ? filter?.organisationId : null;
        model.usageTypeCode = filter?.usageTypeCode;
        model.email = filter?.email?.trim();
        model.phoneNumber = filter?.phoneNumber?.trim();
        model.city = filter?.city?.trim();
        model.countryCode = filter?.countryCode;
        model.statusCode = filter?.statusCode;

        return model;
    }

    public documentFilterViewToInsightSearchModel(model: InsightPassengerSearchModel, filter: InsightPassengerSearchModel) {
        model.commentTypeCode = filter.commentTypeCode;
        model.identityDocumentCode = filter.identityDocumentCode;
        model.membershipLevelId = filter.membershipLevelId;
        model.membershipLevelCode = filter.membershipLevelCode;
        model.memberNumber = filter.memberNumber?.trim();
        model.identityDocumentNumber = filter.identityDocumentNumber?.trim();
    }

    public profileRowViewsToProfileModels(rows: ProfileRowView[], model: InsightPassengerSearchModel) : InsightPassengerSearchModel {
        this.clearPreviousProfileSearchData(model);
        
        if (!rows?.length) {
            return model;
        }

        for (let row of rows) {
            switch (row.profileType) {
                case this.CIVIL_STATUS :
                    this.setCivilStatusModels(row.codes, model);
                    break;
                case this.INTEREST :
                    this.setInterestModels(row.codes, model);
                    break;
                case this.POSITION :
                    this.setPositionModels(row.codes, model);
                    break;
                case this.PREFERENCE :
                    this.setPreferenceModels(row.codes, model);
                    break;
                case this.PROFESSION :
                    this.setProfessionModels(row.codes, model);
                    break;
                case this.SOCIAL :
                    this.setSocialModels(row.codes, model);
                    break;
                case this.LANGUAGE :
                    this.setLanguageModels(row.codes, model);
                    break;
                case this.NATIONALITY :
                    this.setNationalityModels(row.codes, model);
                    break;
                case this.AGE_GROUP :
                    this.setAgeGroupModels(row.codes, model);
                    break;
            }
        }
    }

    private clearPreviousProfileSearchData(model: InsightPassengerSearchModel) {
        model.insightPassengerCivilStatuses = null;
        model.insightPassengerInterests = null;
        model.insightPassengerPositions = null;
        model.insightPassengerPreferences = null;
        model.insightPassengerProfessions = null;
        model.insightPassengerSocials = null;
        model.insightPassengerAgeGroups = null;
        model.insightPassengerNationalities = null;
        model.insightPassengerLanguages = null;
    }

    private setCivilStatusModels(codes: string[], model: InsightPassengerSearchModel) {
        if (!codes?.length) {
            return;
        }

        model.insightPassengerCivilStatuses = new Array();
            
        for (let code of codes) {
            let civilStatusModel: IndividualCivilStatusModel = {
                individualCivilStatusId: null,
                individualCivilStatusTypeCode: code,
                individualCivilStatusTypeName: null,
                startDate: null,
                endDate: null
            }

            model.insightPassengerCivilStatuses.push(civilStatusModel);
        }
    }

    private setInterestModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerInterests = new Array();

        for (let code of codes) {
            let interestModel: IndividualInterestModel = {
                individualInterestId: null,
                individualInterestTypeCode: code,
                individualInterestTypeName: null               
            }

            model.insightPassengerInterests.push(interestModel);
        }
    }

    private setPositionModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerPositions = new Array();

        for (let code of codes) {
            let positionModel: IndividualPositionModel = {
                individualPositionId: null,
                individualPositionTypeCode: code,
                individualPositionTypeName: null,
                startDate: null,
                endDate: null               
            }

            model.insightPassengerPositions.push(positionModel);
        }
    }

    private setPreferenceModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerPreferences = new Array();

        for (let code of codes) {
            let preferenceModel: IndividualPreferenceModel = {
                individualPreferenceId: null,
                individualPreferenceTypeCode: code,
                individualPreferenceTypeName: null,
                filterFlag: null            
            }

            model.insightPassengerPreferences.push(preferenceModel);
        }
    }

    private setProfessionModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerProfessions = new Array();

        for (let code of codes) {
            let professionModel: IndividualProfessionModel = {
                individualProfessionId: null,
                individualProfessionTypeCode: code,
                individualProfessionTypeName: null,
                startDate: null,
                endDate: null        
            }

            model.insightPassengerProfessions.push(professionModel);
        }
    }

    private setSocialModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerSocials = new Array();
        
        for (let code of codes) {
            let socialModel: IndividualSocialModel = {
                individualSocialId: null,
                individualSocialTypeCode: code,
                individualSocialTypeName: null,
                startDate: null,
                endDate: null        
            }

            model.insightPassengerSocials.push(socialModel);
        }
    }

    private setLanguageModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerLanguages = new Array();
        
        for (let code of codes) {
            let languagelModel: InsightPassengerLanguageModel = {
                individualLanguageId: null,
                individualId: null,
                languageCode: code,
                languageName: null,
                primaryFlag: null
            }

            model.insightPassengerLanguages.push(languagelModel);
        }
    }

    private setNationalityModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerNationalities = new Array();
        
        for (let code of codes) {
            let nationalityModel: InsightPassengerNationalityModel = {
                individualNationalityId: null,
                countryCode: code,
                countryName: null,
                individualId: null,
                placeOfBirth: null,
                primaryFlag: null,
                countryOfBirthCode: null
            }

            model.insightPassengerNationalities.push(nationalityModel);
        }
    }

    private setAgeGroupModels(codes: string[], model: InsightPassengerSearchModel) {
        if (codes.length <= 0) {
            return;
        }

        model.insightPassengerAgeGroups = new Array();
        
        for (let code of codes) {
            let ageGroupModel: InsightPassengerAgeGroupModel = {
                individualAgeGroupId: null,
                individualAgeGroupCode: code,
                individualId: null,
                startDate: null,
                endDate: null        
            }

            model.insightPassengerAgeGroups.push(ageGroupModel);
        }
    }

    public attributeRowViewsToProfileModels(rows: AttributeRowView[], model: InsightPassengerSearchModel) {
        this.clearPreviousAttributeSearchData(model);
        
        if (!rows?.length) {
            return;
        }

        model.insightPassengerAttributes = new Array();

        for (let row of rows) {
            this.attributeRowViewToAttributeModel(row, model);
        }  
    } 

    private clearPreviousAttributeSearchData(model) {
        model.insightPassengerAttributes = null;
    }

    private attributeRowViewToAttributeModel(row: AttributeRowView, command: InsightPassengerSearchModel) {
        if (!row.attributeChoiceIds?.length) {
            if (row.attributeDate || row.attributeDateTime || row.attributeText || row.attributeValue) {
                let model: InsightPassengerAttributeModel = {
                    individualId: null,
                    individualAttributeId: null,
                    displaySequence: 0,
                    attributeChoiceId: null,
                    attributeTypeCode: row.attributeTypeCode,
                    attributeTypeName: row.attributeTypeName,
                    attributeValue: row.attributeValue,
                    attributeDateTime: row.attributeDateTime,
                    attributeText: row.attributeText,
                    attributeDate: row.attributeDate
                }
                command.insightPassengerAttributes.push(model);
            }
            return;
        }

        let index = 1;
        for (let id of row.attributeChoiceIds) {
            let model: InsightPassengerAttributeModel = {
                individualId: null,
                individualAttributeId: null,
                displaySequence: index,
                attributeChoiceId: id,
                attributeTypeCode: row.attributeTypeCode,
                attributeTypeName: row.attributeTypeName,
                attributeValue: row.attributeValue,
                attributeDateTime: row.attributeDateTime,
                attributeText: row.attributeText,
                attributeDate: row.attributeDate
            }
            index++;
            command.insightPassengerAttributes.push(model);
        }
    }

    public searchModelToCommandModel(model: InsightPassengerSearchModel, nameFilter: InsightPassengerCommandModel) : InsightPassengerCommandModel {
        let command: InsightPassengerCommandModel = {
            individualId: null,
            ageGroup: null,
            dateOfBirth: null,
            genderCode: null,
            medias: null,
            filterUserAccountId: null,
            isOwnerFilter: nameFilter.isOwnerFilter,
            searchName: this.setInsightSearchName(model),
            searchUsageTypeCode: model.usageTypeCode,
            searchStatusCode: model.statusCode,
            statusCode: this.ACTIVE_CODE,
            usageTypeCode: this.FILTER_TYPE_CODE,
            insightPassengerAddresses: this.setInsightAddresModel(model),
            insightPassengerAgeGroups: model.insightPassengerAgeGroups,
            insightPassengerAttributes: model.insightPassengerAttributes,
            insightPassengerCivilStatuses: model.insightPassengerCivilStatuses,
            insightPassengerComments: this.setInsightCommentModel(model),
            insightPassengerCommunicationOthers: this.setInsightCommunicationOtherModel(model),
            insightPassengerCommunicationPhones: this.setInsightCommunicationPhoneModel(model),
            insightPassengerDocuments: this.setInsightDocumentModel(model),
            insightPassengerInterests: model.insightPassengerInterests,
            insightPassengerLanguages: model.insightPassengerLanguages,
            insightPassengerMemberships: this.setInsightMembershipModel(model),
            insightPassengerNames: this.setInsightNameModel(model, nameFilter),
            insightPassengerNationalities: model.insightPassengerNationalities,
            insightPassengerPositions: model.insightPassengerPositions,
            insightPassengerPreferences: model.insightPassengerPreferences,
            insightPassengerProfessions: model.insightPassengerProfessions,
            insightPassengerSocials: model.insightPassengerSocials,
        }

        return command;
    }

    private setInsightSearchName(model: InsightPassengerSearchModel) : string {
        if (!model.lastName) {
            return null;
        }

        return model.lastName;
    }

    private setInsightAddresModel(model: InsightPassengerSearchModel) : InsightPassengerAddressModel[] {
        if (!model.city && !model.countryCode) {
            return null;
        }

        let addresses = new Array();
        let address = {} as InsightPassengerAddressModel;
        address.city = model.city;
        address.countryCode = model.countryCode;    
        addresses.push(address)

        return addresses;
    }

    private setInsightCommentModel(model: InsightPassengerSearchModel) : InsightPassengerCommentModel[] {
        if (!model.commentTypeCode) {
            return null;
        }

        let comments = new Array();
        let comment = {} as InsightPassengerCommentModel;
        comment.commentTypeCode = model.commentTypeCode;
        comments.push(comment);
        
        return comments;
    }

    private setInsightCommunicationOtherModel(model: InsightPassengerSearchModel) : InsightPassengerCommunicationOtherModel[] {
        if (!model.email) {
            return null;
        }

        let communicationOthers = new Array();
        let communicationOther = {} as InsightPassengerCommunicationOtherModel;
        communicationOther.mailAddress = model.email;
        communicationOthers.push(communicationOther);
        
        return communicationOthers;
    }
    
    private setInsightCommunicationPhoneModel(model: InsightPassengerSearchModel) : InsightPassengerCommunicationPhoneModel[] {
        if (!model.phoneNumber) {
            return null;
        }

        let communicationPhones = new Array();
        let communicationPhone = {} as InsightPassengerCommunicationPhoneModel;
        communicationPhone.phoneNumber = model.phoneNumber;
        communicationPhones.push(communicationPhone);
        
        return communicationPhones;
    }

    private setInsightDocumentModel(model: InsightPassengerSearchModel) : InsightPassengerDocumentModel[] {
        if (!model.identityDocumentCode && !model.identityDocumentNumber) {
            return null;
        }

        let documents = new Array();
        let document = {} as InsightPassengerDocumentModel;
        document.identityDocumentCode = model.identityDocumentCode;
        document.identityDocumentNumber = model.identityDocumentNumber;
        documents.push(document);
        
        return documents;
    }

    private setInsightMembershipModel(model: InsightPassengerSearchModel) : InsightPassengerMembershipModel[] {
        if (!model.memberNumber && !model.membershipLevelCode) {
            return null;
        }

        let memberships = new Array();
        let membership = {} as InsightPassengerMembershipModel;
        membership.memberNumber = model.memberNumber;
        membership.membershipLevelCode = model.membershipLevelCode;
        memberships.push(membership);
        
        return memberships;
    }

    private setInsightNameModel(model: InsightPassengerSearchModel, nameFilter: InsightPassengerCommandModel) : InsightPassengerNameModel[] {
        if (!model.firstName && !nameFilter.searchName) {
            return null;
        }

        let names = new Array();
        let name = {} as InsightPassengerNameModel;
        name.firstName = model.firstName;
        name.lastName = nameFilter.searchName;
        names.push(name);
        
        return names;
    }

    public insightPassengerModelToSearchModel(model: InsightPassengerModel) : InsightPassengerSearchModel {
        if (!model) {
            return null;
        }
        
        let searchModel: InsightPassengerSearchModel = {
            firstName: model.insightPassengerNames[0]?.firstName,
            lastName: model.searchName,
            city: model.insightPassengerAddresses[0]?.city,
            countryCode: model.insightPassengerAddresses[0]?.countryCode,
            email: model.insightPassengerCommunicationOthers[0]?.mailAddress,
            phoneType: model.insightPassengerCommunicationPhones[0]?.phoneType,
            phoneNumber: model.insightPassengerCommunicationPhones[0]?.phoneNumber,
            gender: null,
            individualId: model.individualId,
            commentTypeCode: model.insightPassengerComments[0]?.commentTypeCode,
            membershipLevelCode: model.insightPassengerMemberships[0]?.membershipLevelCode,
            membershipLevelId: model.insightPassengerMemberships[0]?.membershipLevelId,
            memberNumber: model.insightPassengerMemberships[0]?.memberNumber,
            identityDocumentCode: model.insightPassengerDocuments[0]?.identityDocumentCode,
            identityDocumentNumber: model.insightPassengerDocuments[0]?.identityDocumentNumber,
            language: null,
            middleName: null,
            nationality: null,
            searchName: model.insightPassengerNames[0]?.lastName,
            statusCode: model.searchStatusCode,
            usageTypeCode: model.searchUsageTypeCode,
            isOwnerFilter: model.isOwnerFilter,
            insightPassengerAgeGroups: model.insightPassengerAgeGroups,
            insightPassengerAttributes: model.insightPassengerAttributes,
            insightPassengerCivilStatuses: model.insightPassengerCivilStatuses,
            insightPassengerInterests: model.insightPassengerInterests,
            insightPassengerLanguages: model.insightPassengerLanguages,
            insightPassengerNationalities: model.insightPassengerNationalities,
            insightPassengerPositions: model.insightPassengerPositions,
            insightPassengerPreferences: model.insightPassengerPreferences,
            insightPassengerProfessions: model.insightPassengerProfessions,
            insightPassengerSocials: model.insightPassengerSocials,
            organisationId: model.organisationId,
            dateOfBirth: model.dateOfBirth
        }; 

        return searchModel;
    }
}
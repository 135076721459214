import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherComponent } from './voucher.component';
import { VoucherSearchModule } from './voucher-search/voucher-search.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { VoucherDetailModule } from './voucher-detail/voucher-detail.module';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';

@NgModule({
    declarations: [
        VoucherComponent
    ],
    imports: [
        AlertBarModule,
        CommonModule,
        FreezingTabModule,
        NgbNavModule,
        UiModule,
        VoucherDetailModule,
        VoucherSearchModule
    ],
    exports: [
        VoucherComponent
    ]
})
export class VoucherModule { }

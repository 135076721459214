import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { CashbookSearchCommandModel, CashbookSearchTableModel } from 'src/app/core/models/cashbook-model';
import { CashbookService } from 'src/app/core/services/cashbook-services/index';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import moment from 'moment';
import { ExportButtonGroupDataComponent } from 'src/app/shared/ui/export-button-group';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import DataSource from 'devextreme/data/data_source';
import { DetailsColumnCashbookService } from './detail-colum-export';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner/loading-notifier';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { OrderPaymentStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

@Component({
  selector: 'op-cashbook-search-table',
  templateUrl: './cashbook-search-table.component.html',
  providers: [DetailsColumnCashbookService],
})
export class CashbookSearchTableComponent implements OnChanges {
  public readonly SPINNER_NAME: string = "cashbookSearch";
  public loadingNotifier = new LoadingNotifier();
  dataSource: DataSource;
  private readonly dateTimeFormatIn = "YYYY/MM/DD H:mm:ss A";
  private readonly dateTimeFormat = "DD/MM/YYYY, H:mm A";
  public readonly EXPORT_FILE_NAME = "CashbookSearch";
  public readonly SPINNER_FULL_SCREEN: boolean = false;

  @Input() searchResults: CashbookSearchTableModel[];
  @Input() userSecurity: SecurityGroupSecurityModel;
  @Input() organisationDatetimeFormat: string;
  @Input() orderPaymentStatusReferences: OrderPaymentStatusReferenceModel[];
  @Output() reSearch = new EventEmitter();
  @Output() onSelectItem = new EventEmitter();
  @Output() getPaggingView = new EventEmitter();

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
  @ViewChild(ExportButtonGroupDataComponent) exportButtonGroup: ExportButtonGroupDataComponent;

  itemSelected: boolean = false;
  public dataSourceStorage: any;
  public focusing = false;
  public selectedItem: any;
  public rows = 0;
  public exportData: any;
  public header: string[] = [];
  public pdfDefaultStyle = {
    fontSize: 7
  }
  public showCountColumns: boolean = false;
  public columnsOptions = [];
  public paggingView: PagingDataView[];
  public columsHeader = [];
  public subColumsHeader = [];
  public headerLevel = 2;
  public exportRowData: any;
  public readonly dataFieldCount = ['countSubtype'];
  public readonly dataFieldSum = ['ledgerAmount'];
  public detailHeaderLevel = 2;
  public detialColumsHeader = [];
  public detialSubColumsHeader = [];
  public organisationId: string;
  public organisationCallName: string;
  public searchCondition: CashbookSearchCommandModel;

  constructor(
    private stringUtils: StringHelperService,
    public cashbookService: CashbookService,
    private changeDetectionRef: ChangeDetectorRef,
    private navigationService: NavigationService,
    private detailsColumnCashbook: DetailsColumnCashbookService) {
    this.calculateSummary = this.calculateSummary.bind(this)
    this.detialColumsHeader = this.detailsColumnCashbook.getDetailsColumn();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchResults']) {
      this.mapData();
      this.getPaggingView.emit(this.paggingView);
    }
  }

  public getAmount(data): string {
    return this.stringUtils.validateNullAmountDecimal(parseInt(data));
  }

  get isShowCountColumns(): boolean {
    return this.showCountColumns;
  }

  public getNumberOfRows(e) {
    this.getDataRowExport();
    this.rows = e.component.totalCount();
    this.exportData = this.getExportData();
    this.exportButtonGroup.detectChange();
  }

  getCaption(hasHeader, caption) {
    if (hasHeader) {
      let newcaption = caption.split(' ');
      if (newcaption.length > 1) {
        return newcaption[1];
      }
      return newcaption[0];
    }
    return caption;
  }

  private getDataGridHeader() {
    this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);

    for (let index = 0; index < this.headerLevel; index++) {
      let header = this.dataGrid.instance.getVisibleColumns(index)
        .filter(item => (item.type != "groupExpand") && (item.type != "detailExpand"));
      header.forEach(element => {
        let subColumn = this.subColumsHeader.filter(data => data.name == element.name)
        this.columsHeader.push({
          headerLevel: index,
          name: element.name,
          dataField: element.dataField,
          ownerBand: element.ownerBand,
          alignment: element.alignment,
          caption: this.getCaption(element.headerCellTemplate, element.caption),
          subColumn: (subColumn[0]) ? subColumn[0].subColumn : []
        });
      });
    }
  }

  private getExportData() {
    let filteredData;
    let filterExpr = this.dataGrid.instance.getCombinedFilter();
    let gridDataSource = this.dataGrid.instance.getDataSource();
    gridDataSource?.store().load({
      filter: filterExpr,
    }).done(function (filterData) {
      filteredData = filterData;
    });

    return filteredData?.map(data => (
      {
        No: data.no,
        TypeCode: data.formOfPaymentTypeCode,
        TypeName: data.formOfPaymentTypeName,
        SubtypeCode: data.formOfPaymentCode,
        SubtypeName: data.formOfPaymentName,
        LegalName: data.organisationLegalName,
        User: data.commitBy,
        PaymentCurrency: data.paymentCurrencyCode,
        PaymentAmount: data.transactionAmount,
        PaymentDate: data.commitDateTime,
        LedgerCurrency: data.ledgerCurrencyCode,
        LedgerAmount: data.ledgerAmount,
        LedgerTransaction: data.ledgerTransactionName,
        Count: 1
      }
    ));
  }

  public getDetail(paymentId: string) {
    let orderdetail = this.searchResults.filter(data => data.paymentId == paymentId);
    let no = 1;
    let data = [];
    for (let item of orderdetail) {
      data.push(this.mapDataRow(item, no));
      no++;
    }
    return data;
  }

  getCountLedger(orderId: string) {
    let orderdetail = this.searchResults.filter(data => data.orderId == orderId);
    return orderdetail.length;
  }

  private mapData(): any {
    this.loadingNotifier.show(this.SPINNER_NAME);
    this.paggingView = new Array();
    let index = 1;
    var cashbookTemps = new Array();
    if (!this.searchResults) {
      return;
    }
    for (let view of this.searchResults) {
      this.addPaggingView(index, view.paymentId + ',' + view.paymentDate + ',' + this.organisationId);
      var cashbookTemp = {};
      cashbookTemp["no"] = index;
      cashbookTemp["accountableDocumentTypeName"] = view.accountableDocumentTypeName;
      cashbookTemp["documentNumber"] = view.documentNumber;
      cashbookTemp["documentDate"] = view.documentDate;
      cashbookTemp["exchangeRate"] = view.exchangeRate;
      cashbookTemp["externalReference"] = view.externalReference;
      cashbookTemp["formOfPaymentCode"] = view.formOfPaymentCode;
      cashbookTemp["formOfPaymentName"] = view.formOfPaymentName;
      cashbookTemp["formOfPaymentTypeCode"] = view.formOfPaymentTypeCode;
      cashbookTemp["formOfPaymentTypeName"] = view.formOfPaymentTypeName;
      cashbookTemp["ledgerId"] = view.ledgerId;
      cashbookTemp["paymentId"] = view.paymentId;
      cashbookTemp["ledgerAmount"] = view.ledgerAmount;
      cashbookTemp["countLedger"] = this.getCountLedger(view.orderId);
      cashbookTemp["paymentCurrencyCode"] = view.paymentCurrencyCode;
      cashbookTemp["ledgerCurrencyCode"] = view.ledgerCurrencyCode;
      cashbookTemp["ledgerTransactionCode"] = view.ledgerTransactionCode;
      cashbookTemp["ledgerTransactionName"] = view.ledgerTransactionName;
      cashbookTemp["orderId"] = view.orderId;
      cashbookTemp["organisationLegalName"] = view.organisationLegalName;
      cashbookTemp["paymentStatusCode"] = view.paymentStatusCode;
      cashbookTemp["transactionAmount"] = view.transactionAmount;
      cashbookTemp["transactionReference"] = view.transactionReference;
      cashbookTemp["commitBy"] = view.commitBy;
      cashbookTemp["commitDateTime"] = moment(view.commitDateTime, this.dateTimeFormatIn).format(this.dateTimeFormat);
      cashbookTemp["voidDateTime"] = view.voidDateTime;
      cashbookTemp["countSubtype"] = 1;
      cashbookTemp['chargeItems'] = this.getDetail(view.paymentId);
      cashbookTemp['paymentNumberDisplay'] = view.paymentNumberDisplay;
      cashbookTemp['paymentDate'] = view.paymentDate;
      cashbookTemp['orderNumber'] = view.orderNumber;
      cashbookTemp['paymentOrganisationName'] = view.paymentOrganisationName;
      cashbookTemp['paymentIndividualName'] = view.paymentIndividualName;

      cashbookTemps.push(cashbookTemp);
      index++;
    }
    this.searchResults = cashbookTemps;
    this.loadingNotifier.hide(this.SPINNER_NAME);
    this.changeDetectionRef.detectChanges();
  }

  public addPaggingView(index: number, id: string) {
    let pview = new PagingDataView();
    pview.no = index;
    pview.id = id;
    this.paggingView.push(pview);
  }

  private mapDataRow(data: any, no: number): any {
    return {
      no: no,
      accountableDocumentTypeName: data.accountableDocumentTypeName,
      documentNumber: data.documentNumber,
      documentDate: (data.documentDate ? data.documentDate : null),
      exchangeRate: data.exchangeRate,
      externalReference: data.externalReference,
      formOfPaymentCode: data.formOfPaymentCode,
      formOfPaymentName: data.formOfPaymentName,
      formOfPaymentTypeCode: data.formOfPaymentTypeCode,
      formOfPaymentTypeName: data.formOfPaymentTypeName,
      ledgerId: data.ledgerId,
      paymentId: data.paymentId,
      ledgerAmount: data.ledgerAmount,
      countLedger: this.getCountLedger(data.orderId),
      paymentCurrencyCode: data.ledgerCurrencyCode,
      ledgerCurrencyCode: data.ledgerCurrencyCode,
      ledgerTransactionCode: data.ledgerTransactionCode,
      orderId: data.orderId,
      organisationLegalName: data.organisationLegalName,
      paymentStatusCode: data.paymentStatusCode,
      transactionAmount: data.transactionAmount,
      transactionReference: data.transactionReference,
      commitBy: data.CommitBy,
      commitDateTime: data.commitDateTime,
      voidDateTime: (data.voidDateTime ? data.voidDateTime : null),
      countSubtype: 1,
      orderNumber: data.orderNumber,
      paymentOrganisationName: data.paymentOrganisationName,
      paymentIndividualName: data.paymentIndividualName
    };
  }

  getRowNoByModel(model: CashbookSearchTableModel) {
    return this.searchResults?.indexOf(model) + 1;
  }

  refreshResult() {
    this.reSearch.emit();
  }

  optionChanged(e) {
    this.columsHeader = [];
    this.subColumsHeader = [];
    this.getGridGroupItems();
    this.getSubColumnsHeader(e.component);
    this.getDataGridHeader();
    this.getDataRowExport();
  }

  getSubColumnsHeader(grid) {
    let dataColums = [];
    let dataColumsHeader = [];
    let count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      let columnOptions = grid.columnOption(index);
      dataColums.push(columnOptions);
      if (columnOptions.isBand == true) {

        let dataRow = {
          index: columnOptions.index,
          caption: columnOptions.caption,
          name: columnOptions.name
        }
        dataColumsHeader.push(dataRow);
      }
    }
    for (let index = 0; index < dataColumsHeader.length; index++) {
      dataColumsHeader[index].subColumn = dataColums.filter(x => x.ownerBand == dataColumsHeader[index].index && x.visible == true && x.groupIndex == undefined)
    }
    this.subColumsHeader = dataColumsHeader;
  }

  getGridGroupItems() {
    this.columnsOptions = this.getGroupOption();
    if (this.columnsOptions?.length > 0) {
      this.dataGrid.instance.columnOption("countSubtype", "visible", true);
    } else {
      this.dataGrid.instance.columnOption("countSubtype", "visible", false);
    }
    this.changeDetectionRef.detectChanges();
  }

  public getGroupOption() {
    let columns = [];
    let count = this.dataGrid?.instance.columnCount();
    for (let index = 0; index < count; index++) {
      let columnOptions = this.dataGrid?.instance.columnOption(index);
      if (columnOptions.groupIndex !== undefined) {
        columns.push(columnOptions);
      }
    }
    return columns;
  }

  calculateSummary(options) {
    let columngroup = this.columnsOptions.length;
    if (options.name == "countSubtypeSummary") {
      if (options.summaryProcess == "start") {
        options.totalValue = null;
      }
      if (options.summaryProcess == "calculate") {
        if (options.value) {
          if (!(options.groupIndex == columngroup - 1)) {
            options.totalValue = null;
          } else {
            if (options.value) {
              options.totalValue++;
            }
          }
        }
      }
    }
  }

  customizeText(e) {
    return (e.value).toFixed(2);
  }

  onRowSelected(event) {
    this.selectedItem = event.key;
    this.itemSelected = true;
    this.onSelectItem.emit(this.selectedItem);
  }

  doubleClick() {
    this.detailItem()
  }

  public detailItem() {
    if (this.itemSelected) {
      let param = {
        paymentId: this.selectedItem.paymentId,
        currentIndex: this.selectedItem.no,
        pagingDataView: this.paggingView,
        paymentDateFrom: this.selectedItem.paymentDate,
        organisationId: this.organisationId,
        searchCondition: this.searchCondition,
        organisationCallName: this.organisationCallName
      }
      this.navigationService.navigate('main/cashbook/detail', null, null, param);
    }
  }

  changeCaption(data) {
    let caption = data.column.caption;
    let newcaption = caption.split(' ');
    return newcaption[1];
  }

  getSelectorGroup() {
    let groupIndex = [];
    let columnsOptions = this.columnsOptions;
    columnsOptions.sort((a, b) => a.groupIndex - b.groupIndex);
    columnsOptions?.forEach(e => {
      let data = { selector: e.dataField }
      groupIndex.push(data);
    });
    return groupIndex;
  }

  getDataRowExport() {
    let filteredData;
    let filterExpr = this.dataGrid.instance.getCombinedFilter();
    let gridDataSource = this.dataGrid.instance.getDataSource();
    gridDataSource?.store().load({
      filter: filterExpr,
    }).done(function (filterData) {
      filteredData = filterData;
    });
    this.dataSource = new DataSource(filteredData);

    let groupIndex = this.getSelectorGroup();
    if (groupIndex.length > 0) {
      this.dataSource.group(groupIndex);
      this.dataSource.load().done(function (filterData) {
        filteredData = filterData;
      });
    }
    this.exportRowData = filteredData;
  }

  public onDetailGridInitialized(e) {
    this.detialColumsHeader = [];
    let dataColums = [];
    let dataColumsHeader = [];
    let count = e.component.columnCount();
    for (let index = 0; index < count; index++) {
      let columnOptions = e.component.columnOption(index);
      dataColums.push(columnOptions);
      if (columnOptions.isBand == true) {
        let dataRow = {
          index: columnOptions.index,
          caption: columnOptions.caption,
          name: columnOptions.name
        }
        dataColumsHeader.push(dataRow);
      }
    }
    this.setSubHeaderColumnMasterDetail(e, dataColumsHeader, dataColums);
  }

  private setSubHeaderColumnMasterDetail(e, dataColumsHeader, dataColums) {
    for (let index = 0; index < dataColumsHeader.length; index++) {
      dataColumsHeader[index].subColumn = dataColums.filter(x => x.ownerBand == dataColumsHeader[index].index && x.visible == true && x.groupIndex == undefined)
    }
    this.detialSubColumsHeader = dataColumsHeader;
    for (let index = 0; index < this.detailHeaderLevel; index++) {
      let header = e.component.getVisibleColumns(index).filter(item => (item.type != "groupExpand") && (item.type != "detailExpand"));
      header.forEach(element => {
        let subColumn = this.detialSubColumsHeader.filter(data => data.name == element.name)
        this.detialColumsHeader.push({
          headerLevel: index,
          name: element.name,
          dataField: element.dataField,
          ownerBand: element.ownerBand,
          alignment: element.alignment,
          caption: this.getCaption(element.headerCellTemplate, element.caption),
          subColumn: (subColumn[0]) ? subColumn[0].subColumn : []
        });
      });
    }
  }
  findHighlightColorForOrderPaymentStatus(dataSet: OrderPaymentStatusReferenceModel[], value: string): string {
    const code = dataSet.find(s => s.orderPaymentStatusName == value)?.highlightColor
    return code
  }
}

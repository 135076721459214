import { OopsCategory } from "src/app/core/components/categories";
import { FinancePanelConstant } from "../../../shared";
export const FINANCE_CATEGORIES: OopsCategory[] = [

    {
        name: 'Cashbook',
        url: FinancePanelConstant.Cashbook,
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        contextMenus: [
            {
                name: 'New',
                actionId: 'NEW',
                disable: null,
                divider: null
            },
            {
                name: 'Search',
                actionId: 'SEARCH',
                disable: null,
                divider: null
            },
            {
                name: 'Dashboard',
                actionId: 'DASHBOARD',
                disable: null,
                divider: null
            }
        ],
        productCount: null
    },
    {
        name: 'Counter Sales',
        url: FinancePanelConstant.CounterSale,
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        contextMenus: [
            {
                name: 'New',
                actionId: 'NEW',
                disable: null,
                divider: null
            },
            {
                name: 'Search',
                actionId: 'SEARCHCOUNTERSALSES',
                disable: null,
                divider: null
            },
            {
                name: 'Dashboard',
                actionId: 'DASHBOARD',
                disable: null,
                divider: null
            }
        ],
        productCount: null
    },
    {
        name: 'Payment',
        url: FinancePanelConstant.PaymentSearch,
        modifiedDateTime: null,
        modifiedBy: null,
        contextMenus: [
            {
                name: 'New',
                actionId: 'NEW',
                disable: null,
                divider: null
            },
            {
                name: 'Search',
                actionId: 'PAYMENT_SEARCH',
                disable: null,
                divider: null
            },
            {
                name: 'Dashboard',
                actionId: 'DASHBOARD',
                disable: null,
                divider: null
            }
        ],
        productCount: null
    },
    {
        name: 'Credit Account',
        url: FinancePanelConstant.CreditAccount,
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        contextMenus: [
            {
                name: 'Search',
                actionId: 'SEARCH_CREDIT_ACCOUNT',
                disable: null,
                divider: null
            },
        ],
        productCount: null
    }
];

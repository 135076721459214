import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../../authentication/auth.service';
import { CommentViewModel } from '../../models/activity-model/activity-comment';

@Injectable({
    providedIn: 'root'
})
export class ActivityCommentService extends ApiServiceBase {
    private readonly PATH = "/activity/comments"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public get(activityId): Observable<CommentViewModel[]> {
        return this.httpGet<CommentViewModel[]>(this.PATH + '/' + activityId , null)
            .pipe(
                map(res => res.body)
            )
    }
}
import {
    AfterViewChecked,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { OrderDetailIndividualAddressView } from "../..";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { GlobalStateService } from "src/app/shared/ui/uiservice/global-state.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { OrderChangeIndividualResponseView } from "../../views/order-change-individual-response.view";
import { IndividualChangeMapperService } from "../../individual-change-mapper.service";
import { OrderIndividualChangeService } from "src/app/core/services/order-services/order-individual-change.service";
import { IndividualChangeResponseModel } from "src/app/core/models/order-model";
import { AddressListActionBarComponent } from "./address-list-action-bar/address-list-action-bar.component";

@Component({
    selector: "op-order-detail-address-list",
    templateUrl: "./order-detail-address-list.component.html",
})
export class OrderDetailAddressListComponent implements AfterViewChecked, OnInit {
    public readonly SPINNER_NAME: string = "orderDetailAddresses";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;
    @ViewChildren(AddressListActionBarComponent) actionBars: QueryList<AddressListActionBarComponent>;

    @Input("id") id: string;
    @Input("data") addressData: OrderDetailIndividualAddressView[];
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapseWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("changeSessionId") changeSessionId: string;
    @Input("headerOrderId") headerOrderId: string;
    @Input("disableActionButton") disableActionButton: boolean = false;
    @Input() history: boolean = false;

    @Output() completeChange =
        new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() failedChange =
        new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() startChange = new EventEmitter();

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderLock: boolean;

    private readonly ADD_NEW_BUTTON_ID = this.stringHelperService.NewGuid();

    addNewOrderDetailAddressView: OrderDetailIndividualAddressView;
    editOrderDetailAddressViews: OrderDetailIndividualAddressView[] = [];

    get orderDetailAddressViews(): OrderDetailIndividualAddressView[] {
        return this.addressData;
    }

    get countNumberOfItems(): number {
        return this.orderDetailAddressViews?.length ?? 0;
    }

    get addNewButtonDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.ADD_NEW_BUTTON_ID);
    }

    constructor(
        private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService,
        private individualChangeMapperService: IndividualChangeMapperService,
        private individualChangeService: OrderIndividualChangeService
    ) {
        this.globalStateService.register(this.ADD_NEW_BUTTON_ID);
    }
    ngAfterViewChecked(): void {
        this.toggleDisableActionButton();
    }

    ngOnInit(): void {
        this.checkCollapse();
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    private checkCollapse(): void {
        if (
            !this.orderDetailAddressViews ||
            this.orderDetailAddressViews.length == 0 ||
            this.collapeWhenLoad
        ) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public getAddrressTypeCaption(addressTypeName: string): string {
        const arr = ["Address", addressTypeName];
        return arr.filter((item) => !!item).join(" - ");
    }

    onNewClick() {
        this.addNewOrderDetailAddressView = this.getEmptyView();
    }

    onNewApply(view: OrderDetailIndividualAddressView) {
        this.addressData = this.addressData || [];
        this.addressData.push(cloneDeep(view));
        let models = this.individualChangeMapperService.orderDetailIndividualAddresses(
            this.addressData,
            this.id);

        this.startChange.emit();
        this.individualChangeService.orderChangeAddress(
            this.headerOrderId,
            this.changeSessionId,
            models
        ).subscribe(
            (response: IndividualChangeResponseModel) => {
                if (response) {
                    this.closeNewPanel();
                    this.globalStateService.enableAll();
                    this.completeChange.emit({
                        sessionId: response.sessionId,
                    });
                }
            },
            (failedResponse: IndividualChangeResponseModel) => {
                this.failedChange.emit({
                    sessionId: failedResponse.sessionId,
                    message: failedResponse.message,
                });
            }
        );
    }

    onNewClose() {
        this.closeNewPanel();
    }

    onEditClick(view: OrderDetailIndividualAddressView, index: number) {
        this.openEditPanel(view, index);
    }

    onDeleteClick(view: OrderDetailIndividualAddressView) {
        
        let models = this.individualChangeMapperService.orderDetailIndividualAddresses(
            [view],
            this.id);

        this.startChange.emit();
        this.individualChangeService.orderCancelAddress(
            this.headerOrderId,
            this.changeSessionId,
            models
        ).subscribe(
            (response: IndividualChangeResponseModel) => {
                if (response) {
                    this.closeNewPanel();
                    this.globalStateService.enableAll();
                    this.completeChange.emit({
                        sessionId: response.sessionId,
                    });
                }
            },
            (failedResponse: IndividualChangeResponseModel) => {
                this.failedChange.emit({
                    sessionId: failedResponse.sessionId,
                    message: failedResponse.message,
                });
            }
        );
    }

    onEditApply(view: OrderDetailIndividualAddressView, index: number) {

        this.orderDetailAddressViews[index] = cloneDeep(view);

        let models = this.individualChangeMapperService.orderDetailIndividualAddresses(
            this.addressData,
            this.id);

        this.startChange.emit();
        this.individualChangeService.orderChangeAddress(
            this.headerOrderId,
            this.changeSessionId,
            models
        ).subscribe(
            (response: IndividualChangeResponseModel) => {
                if (response) {
                    this.closeNewPanel();
                    this.globalStateService.enableAll();
                    this.completeChange.emit({
                        sessionId: response.sessionId,
                    });
                }
            },
            (failedResponse: IndividualChangeResponseModel) => {
                this.failedChange.emit({
                    sessionId: failedResponse.sessionId,
                    message: failedResponse.message,
                });
            }
        );
    }

    onEditClose(index: number) {
        this.closeEditPanel(index);
    }

    private openEditPanel(view: OrderDetailIndividualAddressView, index: number) {
        this.editOrderDetailAddressViews[index] = cloneDeep(view);
    }

    private closeEditPanel(index: number) {
        this.editOrderDetailAddressViews[index] = null;
    }

    private closeNewPanel() {
        this.addNewOrderDetailAddressView = null;
    }

    private getEmptyView(): OrderDetailIndividualAddressView {
        return {
            addressLine1: null,
            addressLine2: null,
            addressTypeCode: null,
            addressTypeName: null,
            city: null,
            countryCode: null,
            countryName: null,
            orderAddressRoleCode: null,
            postalCode: null,
            province: null,
            state: null,
            orderId: null,
            primaryFlag: false,
            addressId: null,
            orderSalesStatusCode: null
        };
    }
    private toggleDisableActionButton(): void {
        if (this.history) {
            this.disableActionButton = true;
        }
        if (!this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => { 
            actionBar.disableActionButton(this.disableActionButton);
        })
    }
    
    public onUndoClick(index: number) {

        let address = this.orderDetailAddressViews[index];

        this.startChange.emit();
        this.individualChangeService.orderUndoIndividualChange(this.headerOrderId, address.orderId, this.changeSessionId).subscribe(
            (response: IndividualChangeResponseModel) => {
                if (response) {
                    this.closeNewPanel();
                    this.globalStateService.enableAll();
                    this.completeChange.emit({
                        sessionId: response.sessionId,
                    });
                }
            },
            (failedResponse: IndividualChangeResponseModel) => {
                this.failedChange.emit({
                    sessionId: failedResponse.sessionId,
                    message: failedResponse.message,
                });
            }
        );
    }
}

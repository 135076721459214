import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ProductCategory } from '../../product-management-content/product-categories/product-category';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { VoucherSearchComponent } from './voucher-search/voucher-search.component';

@Component({
    selector: 'op-voucher',
    templateUrl: './voucher.component.html'
})
export class VoucherComponent implements OnInit, AfterViewInit {
    private readonly groupTabId = 'group';
    private readonly searchTabId = 'search';
    public selectedTab: string;
    public selectedProductCategory: ProductCategory;
    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new RefreshButtonModel()
    );

    private unsubscribe$ = new Subject();

    @ViewChild(VoucherSearchComponent) searchComponent: VoucherSearchComponent;

    constructor(private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        public alertBarService: AlertBarService) { }

    ngOnInit(): void {
        this.getParam();
    }

    ngAfterViewInit(): void {
        this.setUpActionBar();
    }

    private getParam() {
        let params = this.navigationService.getParams();
        this.selectedTab = params?.activeId ?? params?.selectedTab?.toLowerCase() ?? this.groupTabId;
        this.selectedProductCategory = params?.selectedItem;
    }

    private setUpActionBar() {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    private toolbarClicked(e: string) {
        if (e == ACTION_STATUS.back) {
            this.navigationService.navigate('main/product/home', null, null);
        }
    }

    public onActiveIdChange(activeId: string) {
        if (activeId == this.groupTabId) {
            this.selectedTab = activeId;
            this.setUpActionBar();
        }
    }

    public onAlertBarClicked() {
        switch (this.selectedTab) {
            case this.searchTabId:
                this.searchComponent.onAlertNotifyClicked();
                break;
            default:
                this.alertBarService.hide();
                break;
        }
    }
}
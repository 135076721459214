import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';

export const DisplayActionId = 'DISPLAY';
export const NewActionId = 'NEW';
export const GroupActionId = 'GROUP';
export const SearchActionId = 'SEARCH';
export const DashboardActionId = 'DASHBOARD';

export const ContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'Display',
        actionId: DisplayActionId
    },
    {
        name: 'New',
        actionId: NewActionId
    },
    {
        name: 'Group',
        actionId: GroupActionId
    },
    {
        name: 'Search',
        actionId: SearchActionId
    },
    {
        name: 'Dashboard',
        actionId: DashboardActionId
    },
]

export const AttributeContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'New',
        actionId: NewActionId,
    },
    {
        name: 'Search',
        actionId: SearchActionId
    },
    {
        name: 'Dashboard',
        actionId: DashboardActionId
    },
]

export const DocumentDistributionContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'New',
        actionId: NewActionId,
    },
    {
        name: 'Search',
        actionId: SearchActionId
    }
]

export const ExchangeRateContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'Group',
        actionId: GroupActionId
    }
]

export const GeographyContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'Group',
        actionId: GroupActionId
    },
]

export const SecurityContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'Group',
        actionId: GroupActionId
    },
]

export const VehicleContextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'Group',
        actionId: GroupActionId
    },
]
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { VehicleConfigurationProductCommandModel, VehicleConfigurationProductModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-product";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2ServiceRequest } from "../../shared/select2-configuration";
import { ServiceRequestMapperService } from "./servicerequest.mapper.service";

declare var $: any

@Component({
    selector: 'op-vehicle-configuration-servicerequest',
    templateUrl: './servicerequest.component.html',
    providers: [ServiceRequestMapperService]
})
export class VehicleConfigurationServiceRequestComponent implements OnChanges {
    @Input() id: string;
    @Input() copy: boolean = false;
    @Input() productServiceRequestDatas: Select2Data[];
    @Input() vehicleConfigurationProducts: VehicleConfigurationProductModel[];
    @Input() showRow: boolean = false;
    @Output() productIdSelected = new EventEmitter<string[]>();
    @Output() rowSelected = new EventEmitter<Select2Data>();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    focusing: boolean = false;
    serviceRequestOption = select2ServiceRequest;
    vehicleConfigurationProductViews: string[];

    constructor(private serviceRequestMapperService: ServiceRequestMapperService) {
        this.serviceRequestOption.templateSelection = this.templateSelection.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.createServiceRequestView();
    }

    private createServiceRequestView() {
        if (this.vehicleConfigurationProducts?.length) {
            this.vehicleConfigurationProductViews = this.serviceRequestMapperService.toVehicleConfigurationProductViews(this.vehicleConfigurationProducts);
        } else {
            this.delete();
        }
    }

    selectedExists(): boolean {
        if (this.vehicleConfigurationProductViews?.length && !this.showRow) {
            this.setShowRow(true);
            return true;
        }
        return false;
    }

    add() {
        this.setShowRow(true);
    }

    delete() {
        this.setShowRow(false);
        this.vehicleConfigurationProductViews = [];
        this.productIdSelected.emit([]);
    }

    private setShowRow(value: boolean) {
        this.showRow = value;
    }

    getValue(): VehicleConfigurationProductCommandModel[] {
        return this.serviceRequestMapperService.toServiceRequestCommands(this.vehicleConfigurationProductViews, this.vehicleConfigurationProducts);
    }

    hiddenDelete(): boolean {
        if (this.vehicleConfigurationProducts?.length) {
            return true;
        }
        return false;
    }

    private templateSelection(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockChoice(id)) {
            $(container).addClass('locked-tag');
            tag.locked = true;
        }
        else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        return tag.text;
    }

    private lockChoice(id): boolean {
        if (this.vehicleConfigurationProducts && !this.copy) {
            var filter = this.vehicleConfigurationProducts.filter(x => x.productId == id);
            if (filter.length > 0) {
                return true;
            }
        }
        return false;
    }

    dataChange(value) {
        this.vehicleConfigurationProductViews = value;
        this.productIdSelected.emit(value);
    }
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'status-color',
  templateUrl: 'status-color.component.html',
  styleUrls: ['status-color.component.scss']
})
export class StatusColorComponent {

  @Input() status: string;

  @Input() isCustom: boolean = false;
  @Input() customStatusName: string = null;
  @Input() customClass: string = null;
}

import { Component, ChangeDetectionStrategy, OnInit, Input, ElementRef, ViewChild, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2'

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import * as settings from 'src/app/store/settings';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { TranslationTextDetailComponent } from './translation-text-detail/translation-text-detail.component';
import { TranslationTextDetailDirective } from './translation-text-detail/translation-text-detail.directive';

import { LanguageReferenceModel, MediaTypeReferenceModel } from '../../models/reference-model/reference-media-model';
import { TranslationTextView } from './shared/translation-text.view';
import { TranslationTextChildView } from './shared/translation-text-child.view';
import { TranslationTextMapperService } from './shared/translation-text-mapper.service';
import { TranslationTextDetailView } from './shared/translation-text-detail.view';

import { GoogleObjResponse } from '../../services/google-translation-service/dto/google-obj-response';
import { GoogleObjRequest } from '../../services/google-translation-service/dto/google-obj-request';
import { DialogsService } from 'src/app/shared/dialogs/dialogs.service';
import { GoogletranslateService } from '../../services/google-translation-service/google-translation.service';

declare var $: any;

@Component({
    selector: 'op-translation-text',
    templateUrl: './translation-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TranslationTextMapperService]
})
export class TranslationTextComponent implements OnInit {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() classIcon: string;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() panelName = 'TEXT TRANSLATIONS';

    @ViewChild("codeRef") codeElement: ElementRef;
    @ViewChild("codeDetailRef") detailElement: ElementRef;
    @ViewChild(TranslationTextDetailDirective) translationTextDetailDirective: TranslationTextDetailDirective;

    languageDataSourceStorage: any;
    settings: any;
    public mediaTranslationTexts: TranslationTextView[]
    public mediaTranslationChildTexts: TranslationTextChildView[]

    public isCollapsed2: boolean = false;
    public focusing: boolean = false;
    
    constructor(
        private changeDectionRef: ChangeDetectorRef,
        private dialogService: DialogsService,
        private translationTextMapperService: TranslationTextMapperService,
        private googletranslationService: GoogletranslateService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private uiHelper: UiHelperService,
        private store: Store<any>) {
        this.languageDataSourceStorage = [];
    }

    ngOnInit(): void {
    }

    getDetail(key) {
        let item = this.languageDataSourceStorage.find((i) => i.key === key);
        if (!item) {
            item = {
                key: key,
                dataSourceInstance: this.createDataSource(key, this.mediaTranslationChildTexts)
            };
            this.languageDataSourceStorage.push(item)
        }
        return item.dataSourceInstance;
    }

    private createDataSource(key: any, data: any): any {
        return new DataSource({
            store: new ArrayStore({
                data: data,
                key: "no"
            }),
            filter: ["mediaId", "=", key]
        });
    }

    onlyHTML(e): Boolean {
        if (e.data.mediaTypeCode == "TEXT") {
            return false;
        }
        else {
            return true;
        }
    }

    gotoEditHTMLPanel(e) {
        try {
            this.toggleDetail();
            var data = new TranslationTextDetailView();
            data.no = e.data.no;
            data.mediaId = e.data.mediaId;
            data.text = e.data.text;
            data.mediaContentId = e.data.mediaContentId;
            data.mediaName = e.data.mediaName;
            data.mediaTypeCode = e.data.mediaTypeCode;
            data.languageCode = e.data.languageCode;
            data.languageName = e.data.languageName;
            this.loadDetailForm(data);
        }
        catch (err) {
            console.log(err);
        }
    }

    clearClick(e) {
        e.data.text = "";
        e.component.refresh();
    }


    public toggleDetail() {
        this.isCollapsed2 = !this.isCollapsed2
        this.changeDectionRef.markForCheck();
        if (this.isCollapsed2) {
            this.scrollToDetail();
        } else {
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {
        this.store.select(settings.selectSettingsState)
            .subscribe(
                (response) => {
                    if (response.fixedHeader) {
                        this.uiHelper.moveScrollPosition(this.codeElement, 80);
                    } else {
                        this.codeElement.nativeElement.scrollIntoView({ block: "start" });
                    }
                }
            );
    }

    private scrollToDetail() {
        this.store.select(settings.selectSettingsState)
            .subscribe(
                (response) => {
                    if (response.fixedHeader) {
                        this.uiHelper.moveScrollPosition(this.detailElement, 80);

                    } else {
                        this.detailElement.nativeElement.scrollIntoView({ block: "start" });
                    }
                }
            );
    }

    private formDetailClose() {
        let viewContainerRef = this.translationTextDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    private loadDetailForm(dataEdit: TranslationTextDetailView) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(TranslationTextDetailComponent);

        let viewContainerRef = this.translationTextDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: TranslationTextDetailComponent = (<TranslationTextDetailComponent>componentRefs.instance);
        component.model = dataEdit;
        component.languageReference$.next(this.createLanguageReference(dataEdit));
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.onSave.subscribe(
            (dataSave: TranslationTextDetailView) => {
                this.save(dataSave);
            }
        );
        component.onCancel.subscribe(
            response => {
                this.toggleDetail();
            }
        );
    }

    getSettingsGlobalFontSize() {
        this.settings = JSON.parse(localStorage.getItem('settings'));
        if (this.settings != null) {
            return this.settings.globalFontSize;
        }
        {
            return null;
        }
    }

    public fillModelToForm(mediaTranslationTextViews: TranslationTextView[], mediaTranslationChildViews: TranslationTextChildView[]) {
        this.mediaTranslationTexts = mediaTranslationTextViews;
        this.mediaTranslationChildTexts = mediaTranslationChildViews;
        this.reloadDataSource();
        this.changeDectionRef.detectChanges();
    }

    private reloadDataSource() {
        if (this.languageDataSourceStorage.length) {
            for (let item of this.languageDataSourceStorage) {
                const data = this.mediaTranslationChildTexts.filter(child => child.mediaId == item.key);
                item.dataSourceInstance = this.createDataSource(item.key, data);
                item.dataSourceInstance.reload();
            }
        }
    }

    isText(e) {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == e.data.mediaTypeCode);
        if (filter.length > 0) {
            return filter[0].textOnlyFlag;
        }
        return false;
    }

    isTextEmpty(e) {
        if (!e.data.text) {
            return true;
        }
        else {
            return false;
        }
    }

    displayHTML(htmlText) {
        Swal.fire({
            html: htmlText,
            width: '900px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }


    displayText(text: string) {
        return this.translationTextMapperService.displayTextMediaInGrid(text);
    }

    translationClick(e) {
        if (this.mediaTranslationChildTexts.length == 0) {
            return;
        }

        var filterSelected = this.mediaTranslationChildTexts.filter(x => x.mediaId == e.data.mediaId && x.selected == true);
        if (filterSelected.length == 0) {
            this.translateEmptyRows(e.data.mediaId);
        }
        else {
            //translation selected
            this.translationText(e.data.mediaId, filterSelected);
        }
    }

    private translateEmptyRows(mediaId: string) {
        var filterMedia = this.mediaTranslationChildTexts.filter(x => x.mediaId == mediaId);
        var filterTranslationTextNotEmpty = filterMedia.filter(x => x.text.trim() != "");
        if (filterTranslationTextNotEmpty.length > 0) {
            this.dialogService.confirm({
                title: '',
                message: '<h2>Translate all languages?</h2>',
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-danger shadow-0'
                    },
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-default'
                    },
                    no: {
                        label: 'No',
                        className: 'btn-default'
                    },
                    close: {
                        label: '',
                        className: ''
                    }
                }
            }, "modal-sm", false).subscribe(
                (response: boolean) => {
                    if (response == true) {
                        //All
                        this.translationText(mediaId, filterMedia);
                    } else if (response == false) {
                        //Ignore
                    } else {
                        //Only empty text
                        var filterTranslationTextEmpty = filterMedia.filter(x => x.text.trim() == "");
                        this.translationText(mediaId, filterTranslationTextEmpty);
                    }
                }
            )
        }
        else {
            //traslation all
            this.translationText(mediaId, filterMedia);
        }
    }

    selectionChangedHandler(e) {
        for (let translation of this.mediaTranslationChildTexts) {
            var filter = e.instance.getSelectedRowsData().filter(x => x.mediaId == translation.mediaId && x.no == translation.no);
            if (filter.length == 0) {
                translation.selected = false;
            }
            else {
                translation.selected = true;
            }
        }
    }


    private translationText(mediaId: string, translations: TranslationTextChildView[]) {
        var filterMediaText = this.mediaTranslationTexts.filter(x => x.mediaId == mediaId);
        for (let translation of translations) {
            const googleObj: GoogleObjRequest = {
                q: [filterMediaText[0].text],
                target: translation.languageCode
            };
            this.googletranslationService.translate(googleObj).subscribe(
                (res: GoogleObjResponse) => {
                    if (res.data) {
                        translation.text = res.data.translations[0].translatedText;
                        this.changeDectionRef.detectChanges();
                    }
                }
            );
        }
    }


    private createLanguageReference(dataEdit: TranslationTextDetailView): LanguageReferenceModel[] {
        var languageReferences: LanguageReferenceModel[] = new Array();
        var language = new LanguageReferenceModel();
        language.languageCode = dataEdit.languageCode;
        language.languageName = dataEdit.languageName;
        languageReferences.push(language);
        return languageReferences;
    }

    save(data: TranslationTextDetailView) {
        if (data) {
            this.savedata(data);
        }
        this.toggleDetail();
    }


    private savedata(data: TranslationTextDetailView) {
        if (this.mediaTranslationChildTexts) {
            var filter = this.mediaTranslationChildTexts.filter(x => x.mediaId == data.mediaId && x.no == data.no);
            if (filter.length != 0) {
                filter[0].text = data.text;
            }
            this.updateDataSource(data, filter);
        }
        this.changeDectionRef.detectChanges();
    }

    private updateDataSource(data: TranslationTextDetailView, filter: TranslationTextChildView[]) {
        const key = data.mediaId;
        const no = data.no;
        const dataSourceLocation = this.languageDataSourceStorage.find(item => item.key == key);
        const dataSource = dataSourceLocation.dataSourceInstance;
        const store = dataSource.store();
        store.update(no, filter[0])
            .then(
                () => {
                    dataSource.reload();
                }
            );
    }

    public clearForm() {
        this.languageDataSourceStorage = [];
        this.mediaTranslationTexts = [];
        this.mediaTranslationChildTexts = [];
        this.isCollapsed2 = false;
        this.changeDectionRef.detectChanges();
    }
}
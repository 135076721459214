import { Component, ChangeDetectionStrategy, Input, ViewChild, Output, EventEmitter, SimpleChange } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/helper/app.helper';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductHashTagView } from '../../shared/views/product-hashtag.view';
import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel,
} from 'src/app/core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel,
    DomainAttributeModel,
    HashTagReferenceModel,
    VehicleCompositionModel,
    VehicleGroupReferenceModel,
    VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { HashtagComponent } from './hashtag/hashtag.component';
import { HashTagMapperService } from '../../shared/mapper/hashtag-mapper.service';
import { ProductNumberComponent } from './product-number/product-number.component';
import { RestrictionComponent } from './restriction/restriction.component';
import { ValidityComponent } from './validity/validity.component';
import { AttributeComponent } from './attribute/attribute.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { RestrictionModel } from 'src/app/core/models/merchandizing-config';

declare var $: any;

@Component({
    selector: 'op-product-attribute-and-rule',
    templateUrl: './attribute-and-rule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HashTagMapperService, Helper]
})
export class ProductAttributeAndRuleComponent {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() newProduct: boolean = false;
    @Input() searchMode: boolean = false;
    @Input() ignoreInventory: boolean = false;

    //Hashtag
    @Input() hashTagReferences$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    @Input() productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    @Input() showHashtagRow: boolean = false;

    //Product number
    //Product restriction product number
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    //Product restriction product
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;

    //Product restriction location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    //Product validity
    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    //Product attribute
    @Input() productTypeCode: string;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    
    //Point of sales
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];
    
    @Input() restrictionConfig: RestrictionModel;

    @Input() vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    @Input() vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);

    @Output() productAttributeValue = new EventEmitter<any>();
    @Output() focusEmit = new EventEmitter<any>();
    @Output() hashTagValueReturn = new EventEmitter<ProductHashTagView[]>();

    @ViewChild(AttributeComponent) productAttributeComponent: AttributeComponent;
    @ViewChild(HashtagComponent) productHashTagComponent: HashtagComponent;
    @ViewChild(PointOfSalesComponent) pointOfSalesComponent: PointOfSalesComponent;
    @ViewChild(ProductNumberComponent) productNumberComponent: ProductNumberComponent;
    @ViewChild(RestrictionComponent) restrictionRuleComponent: RestrictionComponent;
    @ViewChild(ValidityComponent) validityRuleComponent: ValidityComponent;

    focusing: boolean = false;

    get focusingDirective(): FocusingDirective {
        return this.productHashTagComponent.focusingDirective;
    }

    constructor(private hashtagMapperService: HashTagMapperService,
        private helper: Helper) {
    }

    public hashtagValueReturn(value) {
        let productHashTags = this.hashtagMapperService.productHashTagValueToViews(value, this.productHashTags$.value);
        this.hashTagValueReturn.emit(productHashTags);
    }

    focusFunction() {
        this.focusEmit.emit(true);
    }

    public returnProductAttributeValue(value) {
        this.productAttributeValue.emit(value);
    }

    public clearForm() {
        this.productHashTagComponent.setShowHashtagRow(false);
        this.restrictionRuleComponent.detectChanges();
        this.validityRuleComponent.detectChanges();
        this.pointOfSalesComponent.detectChanges();
    }
}
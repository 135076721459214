export class PriceRuleIndividualModel {
    public priceRuleIndividualId: string;
    public individualId: string;
    public genderCode: string;
    public languageCode: string;
    public nationalityCode: string;
    public individualRoleCode: string;
    public individualAgeGroupCode: string;
    public individualCivilStatusTypeCode: string;
    public individualSocialTypeCode: string;
    public individualProfessionTypeCode: string;
    public individualPositionTypeCode: string;
    public individualInterestTypeCode: string;
    public individualPreferenceTypeCode: string;
    public individualAttributeTypeCode: string;
    public individualAttributeChoiceId: string;
    public excludeFlag: boolean;
    public displaySequence: number;
}
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { PointOfSalesModel } from 'src/app/core/models/merchandizing-config';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-voucher-point-of-payments',
    templateUrl: './voucher-point-of-payments.component.html'
})
export class VoucherPointOfPaymentsComponent {
    private _jsonURL = 'assets/data/voucher/attributes&rules_config.json';
    public readonly errorDuplicatedPointOfPayments = 'Point of Payments are duplicated.';
    public readonly errorInvalidPointOfPayments = 'Point of Payment is invalid.';
    public focusing: boolean = false;
    public pointOfSalesConfig: PointOfSalesModel;

    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private http: HttpClient) {
        this.getJSON().subscribe(data => {
            this.pointOfSalesConfig = data.pointOfPayments;
            this.changeDetectorRef.detectChanges();
        });
    }

    get posLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    get allowPanelToggle() {
        return this.posLength > 0;
    }

    get panelCollapsed(): boolean {
        return this.posLength == 0;
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    public add() {
        this.ruleComponent.add();
    }

    public getErrorMessage() {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return this.errorDuplicatedPointOfPayments;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return this.errorInvalidPointOfPayments;
        }
    }

}

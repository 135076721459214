export const select2ProductCodeType = {
    placeholder: "<Number Type>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2OperatorRestrictionProductCode = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2Provider = {
    placeholder: "<Provider>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme :"select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2Supplier = {
    placeholder: "<Supplier>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme :"select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}
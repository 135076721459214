import { AfterViewInit, Component, Input } from "@angular/core";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PricingConstant } from "../shared/constants/pricing.constant";

@Component({
    selector: "op-pricing-search",
    templateUrl: "./pricing-search.component.html",
})
export class PricingSearchComponent implements AfterViewInit {
    @Input() url: string;
    @Input() executeFlag: boolean = true;
    @Input() searchFilterId: string;
    @Input() userSecurity: SecurityGroupSecurityModel; 

    constructor(private actionService: ActionService,
        private actionbarService: ActionbarService) {}

    get PricingConstant(): any {
        return PricingConstant;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/' + this.url?.replace(/-|\//g, '') , null, null);
        }, 200);
        this.setupActionbarSecurity();
    }

    public setupActionbarSecurity() {
        let actionbarHandler = this.actionbarService.getCurrentState();
        actionbarHandler.get(ACTION_STATUS.new)?.enable(this.userSecurity?.newFlag ?? false);
        actionbarHandler.get(ACTION_STATUS.copy)?.enable(this.userSecurity?.copyFlag ?? false);
        actionbarHandler.get(ACTION_STATUS.delete)?.enable(this.userSecurity?.deleteFlag ?? false);

        this.actionbarService.updateState(actionbarHandler);
    }
}
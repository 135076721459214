import { Injectable } from "@angular/core";
import { DocumentDistributionRuleCommandModel, DocumentDistributionRuleModel } from "src/app/core/models/document-distribution-model";
import { DocumentDistributionRuleView } from "./document-distribution-rule.view";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable()
export class RuleMapperService {
    constructor(private dateConverterService: DateConverterService) {
        
    }

    public toDocumenctDistributionRuleViews(models: DocumentDistributionRuleModel[]): DocumentDistributionRuleView[] {
        if (!models) {
            return null;
        }

        let views = new Array();
        let i = 1;
        for (let model of models) {
            views.push(this.toDocumenctDistributionRuleView(i, model, models));
            i++;
        }

        return views
    }

    private toDocumenctDistributionRuleView(index: number, model: DocumentDistributionRuleModel, models: DocumentDistributionRuleModel[]): DocumentDistributionRuleView {
        let view = new DocumentDistributionRuleView();
        view.no = index;
        view.documentDistributionRuleId = model.documentDistributionRuleId;
        view.documentDistributionId = model.documentDistributionId;
        view.providerId = model.providerId;
        view.providerName = model.providerName;
        view.providerIataCode = model.providerIataCode;
        view.productNumberFrom = model.productNumberFrom;
        view.productNumberTo = model.productNumberTo;
        view.serviceDateFrom = this.dateConverterService.toDateFormat(model.serviceDateFrom);
        view.serviceDateTo = this.dateConverterService.toDateFormat(model.serviceDateTo);
        view.originId = model.originId;
        view.originName = model.originName;
        view.originDataSave = this.getLocationSave(view.originId, view.originName)
        view.destinationId = model.destinationId;
        view.destinationName = model.destinationName;
        view.destinationDataSave = this.getLocationSave(view.destinationId, view.destinationName)
        return view;
    }

    public getLocationSave(locationId: string, locationName: string) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (locationId) {
            let data = new Select2Data(locationId, locationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    public toDocumentDistributionRuleCommands(views: DocumentDistributionRuleView[]) : DocumentDistributionRuleCommandModel[] {
        if (!views) {
            return null;
        }

        let models = new Array();
        for (let view of views) {
            models.push(this.toDocumentDistributionRuleCommand(view));
        }
        return models;
    }

    private toDocumentDistributionRuleCommand(view: DocumentDistributionRuleView) : DocumentDistributionRuleCommandModel {
        let command: DocumentDistributionRuleCommandModel = {
            documentDistributionRuleId: view.documentDistributionRuleId ?? null,
            documentDistributionId: view.documentDistributionId ?? null,
            productNumberFrom: view.productNumberFrom ?? null,
            productNumberTo: view.productNumberTo ?? null,
            serviceDateFrom: view.serviceDateFrom ? view.serviceDateFrom : null,
            serviceDateTo: view.serviceDateTo ? view.serviceDateTo : null,
            originId: view.originId ?? null,
            destinationId: view.destinationId ?? null,
            providerId: view.providerId ?? null
        }

        return command;
    }
}
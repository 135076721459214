import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-statistics.component.html',
    selector: 'op-product-dashboard-statistics'
})
export class ProductDashboardStatisticsComponent implements AfterViewInit {
    public salesRevenueYesterday: number;
    public salesRevenueToday: number
    public productCountYesterday: number;
    public productCountToday: number;
    public averageRevenueYesterday: number;
    public averageRevenueToday: number;

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    private loadData() {
        setTimeout(() => {
            this.salesRevenueYesterday = 1000;
            this.salesRevenueToday = 1000;
            this.productCountYesterday = 1000;
            this.productCountToday = 500;
            this.averageRevenueYesterday = 1000;
            this.averageRevenueToday = 500;

            this.changeDetection.detectChanges();
        }, 500);
    }
}
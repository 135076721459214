export class CountryReferenceModel {
    public countryCode: string;
    public countryName: string;
    public countryIsoCode: string;
    public countryIsoNumber: string;
    public countryPhonePrefix: string;
    public languageCode: string;
    public statusCode: string;    
    public idSelected:string;  //use for selected populate in select2 multi value
}

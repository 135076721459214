import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ServiceCategoryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { VehicleConfigurationCompartmentCommandModel, VehicleConfigurationCompartmentModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { ServiceCategoryMapperService } from "./servicecategory-mapper.service";
import { ServiceCategoryView } from "./servicecatetgory.view";

@Component({
    selector: 'op-vehicle-configuration-servicecategory',
    templateUrl: './servicecategory.component.html',
    providers: [ServiceCategoryMapperService]
})
export class VehicleConfigurationServiceCategoryComponent implements OnChanges {
    readonly SEREVICE_CATE_REQUIRED = 'At least 1 service category need to be selected.';
    readonly PHY_CAPA_REQUIRED = 'Physical Capacity is required.';
    readonly COLUMN_LAYOUT_REQUIRED = 'Column Layout is required.';
    readonly ROW_REQUIRED = 'Row is required.';
    readonly COLUMN_CODE_REQUIRED = 'Column Code is required.';

    @Input() id: string;
    @Input() copy: boolean = false;
    @Input() saveProcessing: boolean = false;
    @Input() serviceCategoryReferences: ServiceCategoryReferenceModel[];
    @Input() vehicleConfigurationCompartments: VehicleConfigurationCompartmentModel[];
    @Output() dataChange = new EventEmitter();
    @Output() rowSelected = new EventEmitter<Select2Data>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    focusing: boolean = false;
    serviceCatetgoryViews: ServiceCategoryView[] = new Array();

    constructor(private serviceCategoryMapperService: ServiceCategoryMapperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.saveProcessing) {
            if (!this.id && !this.copy) {
                this.createNewServiceCategoryView();
            } else {
                this.createEditServiceCategoryView();
            }
        }
    }

    public createNewServiceCategoryView() {
        if (this.serviceCategoryReferences) {
            this.serviceCatetgoryViews = this.serviceCategoryMapperService.toNewServiceCategoryViews(this.serviceCategoryReferences);
        }
    }

    private createEditServiceCategoryView() {
        if (this.serviceCategoryReferences && this.vehicleConfigurationCompartments) {
            this.serviceCatetgoryViews = this.serviceCategoryMapperService.toEditServiceCategoryViews(this.serviceCategoryReferences, this.vehicleConfigurationCompartments);
        }
    }

    onEditingStart(e) {
        if ((!e.data.use && e.column.index != 1) || e.data.vehicleConfigurationCompartmentId || this.copy) {
            e.cancel = true;
        }
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField != "use" && e.column.dataField != "serviceCategoryName") {
            if (e.data.use) {
                $(e.cellElement).removeClass('dx-cell-disabled');
            }
            else {
                $(e.cellElement).addClass('dx-cell-disabled');
            }
        }
    }

    onRowUpdated(e) {
        if (!e.data.use) {
            this.setEmptyRow(e.data.serviceCategoryCode);
        }
        if (this.saveProcessing) {
            this.dataChange.emit();
        }
    }

    private setEmptyRow(serviceCategoryCode: string) {
        let filter = this.serviceCatetgoryViews.filter(x => x.serviceCategoryCode == serviceCategoryCode);
        if (filter?.length) {
            filter[0].physicalCapacity = null;
            filter[0].columnCode = null;
            filter[0].columnLayout = null;
            filter[0].row = null;
        }
        this.dataGrid.instance.refresh();
    }

    getValue(): VehicleConfigurationCompartmentCommandModel[] {
        this.dataGrid.instance.refresh();
        return this.serviceCategoryMapperService.toServiceCategoryCommands(this.serviceCatetgoryViews);
    }

    getValueCopy(): VehicleConfigurationCompartmentCommandModel[] {
        this.dataGrid.instance.refresh();
        return this.serviceCategoryMapperService.toServiceCategoryCopyCommands(this.serviceCatetgoryViews);
    }

    validateForm(): boolean {
        if (this.serviceCategoryRequired() ||
            this.physicalCapacityRequired() ||
            this.columnLayOutRequired() ||
            this.rowRequired() ||
            this.columnCodeRequired()) {
            return false;
        }
        return true;
    }

    getErrorMessageForm(): string {
        if (this.serviceCategoryRequired()) {
            return this.SEREVICE_CATE_REQUIRED;
        } else if (this.physicalCapacityRequired()) {
            return this.PHY_CAPA_REQUIRED;
        } else if (this.columnLayOutRequired()) {
            return this.COLUMN_LAYOUT_REQUIRED;
        } else if (this.rowRequired()) {
            return this.ROW_REQUIRED;
        } else if (this.columnCodeRequired()) {
            return this.COLUMN_CODE_REQUIRED;
        }
    }

    private serviceCategoryRequired(): boolean {
        let filter = this.serviceCatetgoryViews.filter(x => x.use == true);
        if (filter?.length) {
            return false;
        }
        return true;
    }

    private physicalCapacityRequired(): boolean {
        let filter = this.serviceCatetgoryViews.filter(x => x.use == true && x.physicalCapacity == null);
        if (!filter?.length) {
            return false;
        }
        return true;
    }

    private columnLayOutRequired(): boolean {
        let filter = this.serviceCatetgoryViews.filter(x => x.use == true && !x.columnLayout);
        if (!filter?.length) {
            return false;
        }
        return true;
    }

    private rowRequired(): boolean {
        let filter = this.serviceCatetgoryViews.filter(x => x.use == true && x.row == null);
        if (!filter?.length) {
            return false;
        }
        return true;
    }

    private columnCodeRequired(): boolean {
        let filter = this.serviceCatetgoryViews.filter(x => x.use == true && !x.columnCode);
        if (!filter?.length) {
            return false;
        }
        return true;
    }

    onDesignClick(data: ServiceCategoryView) {
        if (data.vehicleConfigurationCompartmentId) {
            this.rowSelected.emit(new Select2Data(data.vehicleConfigurationCompartmentId, data.serviceCategoryName));
        }
    }

    public onEditorPreparing(e) {
        if (e.parentType == "dataRow" && e.dataField == "columnCode") { 
            e.editorOptions.onKeyDown = function (arg) {
                var event = arg.event;  
                if (arg.event.keyCode == 32 || /[0-9]/.test(String.fromCharCode(event.keyCode))) {
                    arg.event.preventDefault();
                    arg.event.stopPropagation();
                }
            }
        }
    }
}
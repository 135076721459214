import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { CommunicationPhoneTypeReferenceModel, CountryReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightCommunicationPhoneDetailComponent } from "./detail/communication-phone-detail.component";
import { CommunicationPhoneDetailDirective } from "./detail/communication-phone.directive";
import { CommunicationPhoneView } from "./shared/communication-phone-view";
import { InsightCommunicationPhoneTableComponent } from "./table/communication-phone-table.component";
import { cloneDeep } from "lodash";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-communication-phone',
    templateUrl: './insight-detail-communication-phone.component.html',
})

export class InsightDetailCommunicationPhoneComponent {
    @Input() countryReferences: CountryReferenceModel[];
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() communicationPhoneTypeReferences: CommunicationPhoneTypeReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isCommunicationPhonesEmpty: boolean = true;
    public isMoreThanOneCommunicationPhones: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public communicationPhone = new CommunicationPhoneView();
    public communicationPhones: CommunicationPhoneView[] = new Array();
    public previousCommunicationPhones: CommunicationPhoneView[] = new Array();

    public disabledDelBtn: boolean = false;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @ViewChild(CommunicationPhoneDetailDirective) communicationPhoneDetailDirective: CommunicationPhoneDetailDirective;
    @ViewChild(InsightCommunicationPhoneDetailComponent) communicationPhoneDetailComponent: InsightCommunicationPhoneDetailComponent;
    @ViewChild(InsightCommunicationPhoneTableComponent) communicationPhoneTableComponent: InsightCommunicationPhoneTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public newCommunicationPhoneDetail: InsightCommunicationPhoneDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerCommunicationPhones: CommunicationPhoneView[]) {
        this.clearData();
        if (passengerCommunicationPhones && passengerCommunicationPhones.length > 0) {
            this.fillModelInCase(passengerCommunicationPhones);
        }

        this.communicationPhones = passengerCommunicationPhones;
        this.previousCommunicationPhones = passengerCommunicationPhones;

        if (this.communicationPhones.length > 0) {
            this.isCommunicationPhonesEmpty = false;
        }

        if (this.communicationPhones.length == 1) {
            this.isMoreThanOneCommunicationPhones = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.communicationPhone = new CommunicationPhoneView();
    }

    private fillModelInCase(communicationPhones: CommunicationPhoneView[]) {
        if (communicationPhones.length == 1) {
            this.fillModelSingleRecord(communicationPhones[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: CommunicationPhoneView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.communicationPhone = view;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightCommunicationPhone = this.communicationPhoneDetailComponent.getInsightCommunicationPhone();
        if (insightCommunicationPhone) {
            this.savedata(insightCommunicationPhone);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewCommunicationPhone() {
        this.isApplyBtnClicked = true;
        let insightCommunicationPhone = this.newCommunicationPhoneDetail.getInsightCommunicationPhone();      

        if (insightCommunicationPhone) {
            this.savedata(insightCommunicationPhone);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private savedata(data: CommunicationPhoneView) {
        if (data.organisationId) {
            data.phoneLocked = true;
        }
        else {
            data.phoneLocked = false;
        }

        if (this.communicationPhones?.length) {
            var filterIndex = this.communicationPhones.findIndex(x => x.no == data.no);
            if (data.primaryPhone === undefined) {
                data.primaryPhone = false;
            }

            this.updateCommunicationPhoneViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryPhone = true;
            this.communicationPhone = data;
            this.communicationPhones.push(data);
        }

        this.isCommunicationPhonesEmpty = false;
        this.communicationPhoneTableComponent.dataGrid.instance.refresh();
    }

    private updateCommunicationPhoneViews(filterIndex: number, data: CommunicationPhoneView) {
        if (this.communicationPhones?.length > 0 && data.primaryPhone) {
            this.setOtherCommunicationPhonePrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.communicationPhones.length + 1;
            this.communicationPhones.push(data);
        }
        else {
            this.communicationPhones[filterIndex] = data;
        }
    }

    private setOtherCommunicationPhonePrimaryFlag(filterIndex: number) {
        let otherCommunicationPhones = this.communicationPhones;
        for (let otherCommunicationPhone of otherCommunicationPhones) {
            if (otherCommunicationPhone.no == filterIndex + 1) {
                continue;
            }

            otherCommunicationPhone.primaryPhone = false;;
        }

        this.communicationPhones = otherCommunicationPhones;
    }

    private displayAfterSave() {
        if (this.communicationPhones?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.communicationPhoneDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.communicationPhones.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = new CommunicationPhoneView();

        data = this.getDeleteData();

        this.setMoreThanOneCommunicationPhones();
        this.deleteDataFromCommunicationPhoneViews(data);
    }

    getDeleteData(): CommunicationPhoneView {
        if (this.communicationPhones.length > 1) {
            return this.communicationPhoneTableComponent.selectedItem;
        }

        return this.communicationPhone;
    }

    setMoreThanOneCommunicationPhones() {
        if (this.communicationPhones.length > 1) {
            this.isMoreThanOneCommunicationPhones = true;
        } 
        else {
            this.isMoreThanOneCommunicationPhones = false;
        }
    }

    deleteDataFromCommunicationPhoneViews(data: CommunicationPhoneView) {
        let views = new Array<CommunicationPhoneView>();
        if (this.communicationPhones != null) {
            views = this.communicationPhones;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.communicationPhones = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.communicationPhones.length == 1) {
            this.communicationPhones[0].primaryPhone = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForCommunicationPhoneDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.communicationPhones.length == 0 || this.communicationPhones.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForCommunicationPhoneDetailForm() {
        if (this.communicationPhones.length == 1) {
            this.communicationPhone = this.communicationPhones[0];
            this.showAdd = true;
        }
        else {
            this.communicationPhone = new CommunicationPhoneView();
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.communicationPhoneTableComponent.selectedItem);
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: CommunicationPhoneView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightCommunicationPhoneDetailComponent);

        let viewContainerRef = this.communicationPhoneDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightCommunicationPhoneDetailComponent = (<InsightCommunicationPhoneDetailComponent>componentRefs.instance);
        component.languageReferences = this.languageReferences;
        component.countryReferences = this.countryReferences;
        component.communicationPhoneTypeReferences = this.communicationPhoneTypeReferences;
        
        if (dataEdit != null) {
            component.insightCommunicationPhone = cloneDeep(dataEdit);
        }

        this.newCommunicationPhoneDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneCommunicationPhones = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.communicationPhones.length == 0) {
            this.communicationPhone = new CommunicationPhoneView();
        }

        if (this.communicationPhones.length <= 1) {
            this.isMoreThanOneCommunicationPhones = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.communicationPhoneDetailComponent) {
            return this.communicationPhoneDetailComponent.setProcessing(false);
        }

        if (this.newCommunicationPhoneDetail) {
            return this.newCommunicationPhoneDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.communicationPhones.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.communicationPhones.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.communicationPhones.length == 1) {
            let copyCommunicationPhone = cloneDeep(this.communicationPhones[0]);
            this.openCopyDetailForm(copyCommunicationPhone);
            return;
        }

         if (!this.communicationPhoneTableComponent.selectedItem) {
            return;
        }

        let copyCommunicationPhone = cloneDeep(this.communicationPhoneTableComponent.selectedItem);
        this.openCopyDetailForm(copyCommunicationPhone);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneCommunicationPhones = true;
    }

    setCopyCommunicationPhoneProperty(copyCommunicationPhone: CommunicationPhoneView) {
        copyCommunicationPhone.organisationId = null;
        copyCommunicationPhone.no = null;
        copyCommunicationPhone.communicationPhoneId = null;
        copyCommunicationPhone.primaryPhone = false;
    }

    openCopyDetailForm(copyCommunicationPhone: CommunicationPhoneView) {
        this.setCopyCommunicationPhoneProperty(copyCommunicationPhone);
        this.openDetail();
        this.loadDetailForm(copyCommunicationPhone);
    }

    validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.communicationPhones.length <= 0) {
            return true;
        }

        if (this.communicationPhoneDetailComponent && this.isApplyBtnClicked) {
            return this.communicationPhoneDetailComponent.isValidForm();
        }

        if (this.newCommunicationPhoneDetail && this.isApplyBtnClicked) {
            return this.newCommunicationPhoneDetail.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.communicationPhoneDetailComponent) {
            return this.communicationPhoneDetailComponent.getErrorMessageForm();
        }

        if (this.newCommunicationPhoneDetail) {
            return this.newCommunicationPhoneDetail.getErrorMessageForm();
        }
    }

    onRowSelected() {
        let selectedRow = this.communicationPhoneTableComponent.selectedItem;
        
        if (selectedRow.phoneLocked) {
            this.disabledDelBtn = true;
        }
        else {
            this.disabledDelBtn = false;
        }
    }
}
import { Injectable } from "@angular/core";
import { PriceRuleSalesBucketModel, PriceRuleServiceCategoryModel } from "src/app/core/models/pricing-model";
import { PriceConditionModel, PriceModel } from "./models";

@Injectable()
export class PriceHelper {
    public resetPriceAndChildrenIds(price: PriceModel): PriceModel {
        price.priceId = null;
        price.parentPriceId = null;
        price.organisationId = null;
        price.commitBy = null;
        price.commitDateTime = null;

        price.productNumbers?.forEach(pn => pn.priceRuleProductNumberId = null);
        price.organisations?.forEach(o => o.priceRuleOrganisationId = null);
        price.routes?.forEach(r => r.priceRuleRouteId = null);
        price.locations?.forEach(l => l.priceRuleLocationId = null);
        price.validities?.forEach(v => v.priceRuleValidityId = null);
        price.serviceCategories?.forEach(sc => sc = this.resetServiceCategoryIds(sc));
        price.salesBuckets?.forEach(sb => sb = this.resetSalesBucketIds(sb));
        price.memberships?.forEach(m => m.priceRuleMembershipId = null);
        price.currencies?.forEach(c => c.priceRuleCurrencyId = null);
        price.attributes?.forEach(a => a.priceAttributeId = null);
        price.pricePriceDimensions?.forEach(ppd => ppd.pricePriceDimensionId = null);
        price.conditions?.forEach(c => c = this.resetConditionId(c));

        if (price.children?.length) {
            price.children?.forEach(c => c = this.resetPriceAndChildrenIds(c));
        }
        return price;
    }

    public resetServiceCategoryIds(serviceCategory: PriceRuleServiceCategoryModel): PriceRuleServiceCategoryModel {
        serviceCategory.priceRuleServiceCategoryId = null;
        if (!serviceCategory.children?.length) {
            return;
        }
        serviceCategory.children?.forEach(sc => sc = this.resetServiceCategoryIds(sc));
        return serviceCategory;
    }

    public resetSalesBucketIds(salesBucket: PriceRuleSalesBucketModel): PriceRuleSalesBucketModel {
        salesBucket.priceRuleSalesBucketId = null;
        if (!salesBucket.children?.length) {
            return;
        }
        salesBucket.children?.forEach(sb => sb = this.resetSalesBucketIds(sb));
        return salesBucket;
    }

    public resetConditionId(condition: PriceConditionModel): PriceConditionModel {
        condition.priceConditionId = null;
        condition.individuals?.forEach(i => i.priceIndividualId = null);
        return condition;
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';

import { timeOption, dateTimeOption, dateOption } from './oops-choice-value-configuration';
import { OopsChoiceValueConstant } from './oops-choice-value.constant';

@Component({
    selector: 'oops-choice-value',
    templateUrl: 'oops-choice-value.component.html'
})
export class OopsChoiceValueComponent implements OnChanges {

    @Input() dimensionUnitCode: string = OopsChoiceValueConstant.CHOICE;
    @Input() option: any;
    @Input() data: any;
    @Input() choiceData: any;
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() disabled: boolean = false;
    @Input() invalid: boolean = false;
    @Input() attributeCode: string | string[];
    @Input() nonNegativeValue: boolean = false;

    @Input('blurAfterSelected') blurAfterSelected: boolean = false;

    @Output() dataChange: EventEmitter<string | Array<string>> = new EventEmitter();

    public readonly timeFormat: string = "HH:mm";
    public readonly dateTimeFormat: string = "YYYY-MM-DD HH:mm";
    public readonly dateFormat: string = "YYYY-MM-DD";
    public daterangepickerTimeOption = timeOption;
    public daterangepickerDateTimeOption = dateTimeOption;
    public daterangepickerDateOption = dateOption;
    public numberValue: any;
    public textValue: string;
    public dateValue: string;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.setAttributeValue();
    }

    get value(): number {
        return this.numberValue;
    }

    set value(newValue: number) {
        this.numberValue = newValue;
        if (this.numberValue > this.max && this.max) {
            this.numberValue = undefined;
        }
    }

    get valuePercent(): number {
        return this.numberValue;
    }

    set valuePercent(newValue: number) {
        this.numberValue = newValue;
        if ((this.numberValue > 100) || (this.numberValue > this.max && this.max)) {
            this.numberValue = undefined;
        }
    }

    get valueText(): string {
        return this.textValue;
    }

    set valueText(newValue: string) {
        this.textValue = newValue;
    }

    get nonNegativeNumberMin(): number {
        if (this.min && this.min > 0) {
            return this.min
        }
        return 0;
    }

    dataChangeProcess(value) {
        this.dataChange.emit(value);
    }

    dateChange(value) {
        this.dataChange.emit(value);
    }

    numberChange() {
        if (this.numberValue < this.min && this.min) {
            this.numberValue = undefined;
        } else if (this.numberValue > this.max && this.max) {
            this.numberValue = undefined;
        }
        this.dataChange.emit(this.numberValue);
    }

    percentChange() {
        if (this.numberValue < this.min && this.min) {
            this.numberValue = undefined;
        } else if ((this.numberValue > 100) || (this.numberValue > this.max && this.max)) {
            this.numberValue = undefined;
        }
        this.dataChange.emit(this.numberValue);
    }

    textChange() {
        this.dataChange.emit(this.textValue);
    }

    setAttributeValue() {
        const attributeCodeDateOfJoin = "DOJ";
        if (this.dimensionUnitCode == OopsChoiceValueConstant.NUMBER || this.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE) {
            this.numberValue = this.data;
        }
        else if (this.dimensionUnitCode == OopsChoiceValueConstant.TEXT) {
            this.textValue = this.data;
        }
        else if (this.attributeCode == attributeCodeDateOfJoin && this.dimensionUnitCode == OopsChoiceValueConstant.DATETIME || OopsChoiceValueConstant.DATE) {
            this.daterangepickerDateTimeOption.maxDate = new Date();
            this.daterangepickerDateTimeOption.locale.format = "YYYY-MM-DD";
            this.daterangepickerDateTimeOption.timePicker = false;

            this.daterangepickerDateOption.maxDate = new Date();
            this.daterangepickerDateOption.locale.format = "YYYY-MM-DD";
            this.dateValue = this.data;
        }
    }
}

import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from "@angular/forms";

export function duplicateGroupValidation(formArray: UntypedFormArray): { duplicate: boolean; } {
    const error = { 'duplicate': true };
    let isError = false;
    var duplicateEntries = groupByValue(formArray.controls);
    for (let key of Object.keys(duplicateEntries)) {
        let duplicateGroups = duplicateEntries[key] as UntypedFormGroup[];
        if (duplicateGroups.length > 1) {
            isError = true;
            setDuplicateErrors(duplicateGroups, error);
        } else {
            removeDuplicateErrors(duplicateGroups);
        }
    }
    if (isError) {
        return error;
    }
}

function groupByValue(arr: any[]) {
    return arr.reduce((r, a) => {
        let key = JSON.stringify(a.value);
        r[key] = r[key] || [];
        r[key].push(a);
        return r;
    }, Object.create(null))
}


function setDuplicateErrors(groups: UntypedFormGroup[], error: any) {
    groups.forEach(group => {
        setErrorToControl(group, error);
        Object.keys(group.controls).forEach(controlKey => {
            let control = group.controls[controlKey];
            setErrorToControl(control, error);
        });
    });
}

function setErrorToControl(control: AbstractControl, error: any) {
    let currentErrors = control.errors;
    if (!currentErrors) {
        control.setErrors(error);
    } else {
        let newErrors = currentErrors;
        newErrors['duplicate'] = true;
        control.setErrors(newErrors);
    }
}

function removeDuplicateErrors(groups: UntypedFormGroup[]) {
    groups.forEach(group => {
        removeDuplicateErrorsFromControl(group);
        Object.keys(group.controls).forEach(controlKey => {
            let control = group.controls[controlKey];
            removeDuplicateErrorsFromControl(control);
        })
    })
}

function removeDuplicateErrorsFromControl(control: AbstractControl) {
    if (control.hasError('duplicate')) {
        delete control.errors.duplicate;
    }
    if (control.errors && Object.entries(control.errors)?.length == 0) {
        control.setErrors(null);
    }
}

export function match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
        const control = controls.get(controlName);
        const checkControl = controls.get(checkControlName);

        if (checkControl?.errors && !checkControl?.errors?.matching) {
            return null;
        }
        
        if (control?.value !== checkControl?.value) {
            controls.get(checkControlName).setErrors({ matching: true });
            return { matching: true };
        } else {
            return null;
        }
    };
}

import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { MediaFileAssociationInMediaViewModel } from '../../models/media-model/media-fileassociation/media-fileassociation-in-media-view.model';
import { MediaFileAssociationViewModel } from '../../models/media-model/media-fileassociation';

@Injectable({
    providedIn: 'root'
})
export class MediaFileAssociationService extends ApiServiceBase {
    private readonly PATH = "/media"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }


    public getMediaFileAssociationInMedia(mediaId: string): Observable<MediaFileAssociationInMediaViewModel[]> {
        return this.httpGet<any>(this.PATH + "/mediafileassociation/" + mediaId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getByEntityNameAndAssociation(entityName: string, associationId: string): Observable<MediaFileAssociationViewModel[]> {
        return this.httpGet<MediaFileAssociationViewModel[]>(this.PATH + "/mediafileassociation/" + entityName + "/" + associationId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public deleteByEntityNameAndAssociation(entityName: string, associationId: string) {
        return this.httpPut(this.PATH + "/mediafileassociation/" + entityName + "/" + associationId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
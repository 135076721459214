import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { DomSanitizer } from '@angular/platform-browser';

import { MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaView } from '../shared/media.view';
import { MediaMapperService } from '../shared/media-mapper.service';
import { MediaFilePreviewService } from 'src/app/core/services/media-services/media-file-preview-service';
import { MediaService } from 'src/app/core/services/media-services';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { MediaSearchCommandModel } from 'src/app/core/models/media-model';

@Component({
    selector: 'op-media-table',
    templateUrl: './media-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MediaMapperService]
})
export class MediaTableComponent implements OnInit {

    @Input() mediaFiles: MediaView[] = new Array();
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaSearchCommand: MediaSearchCommandModel;

    @Output() onAdd = new EventEmitter();
    @Output() onAddWithSearchCommand = new EventEmitter<MediaSearchCommandModel>();
    @Output() onEdit = new EventEmitter<MediaView>();
    @Output() onDelete = new EventEmitter<MediaView>();
    @Output() onRowDoubleClick = new EventEmitter<MediaView>();

    public selected: MediaView;
    public pageSize: number = 10;
    @ViewChild('dataGridMedia', { static: false }) dataGrid: DxDataGridComponent;

    private ORG_ID = this.autService.getSelectedOrganisation();

    constructor(private mediaMapperService: MediaMapperService,
        private mediaService: MediaService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private autService: AuthService,
        private domSanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.selected = null;
    }

    click(e) {
        this.selected = e.data;
    }

    edit() {
        if (this.selected) {
            this.onEdit.emit(this.selected);
        }
    }

    delete() {
        if (this.selected) {
            this.onDelete.emit(this.selected);
        }
    }

    add() {
        this.onAdd.emit();
    }

    addWithSearchCommand() {
        this.onAddWithSearchCommand.emit(this.mediaSearchCommand);
    }

    loadThumbnailFinished(e) {
        if (e.data.mediaFilePreviewBase64 || e.data.mediaFileNotFound) {
            return true;
        }
        else {
            if (e.data.mediaContentId) {
                this.getMediaFile(e);
            }
            else {
                this.displayThumbnailFile(e);
            }
            return false;
        }
    }

    private getMediaFile(e) {
        this.mediaService.getMediaFile(e.data.mediaContentId)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        if (this.mediaMapperService.isImage(contenType)) {
                            this.getMediaThumbnailFile(e);
                        }
                        else {
                            this.getPicFilePreview(e, contenType);
                        }
                    }
                    else {
                        e.data.mediaFileNotFound = true;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
    }

    private displayThumbnailFile(e) {
        if (!e.data?.mediaFile) {
            return;
        }
        var contenType = e.data.mediaFile.type;
        if (this.mediaMapperService.isImage(contenType)) {
            e.data.mediaFilePreview = e.data.mediaFile;
            this.readURLFile(e, e.data.mediaFile);
        }
        else {
            this.getPicFilePreview(e, contenType);
        }
    }

    private getMediaThumbnailFile(e) {
        this.mediaService.getMediaThumbnailFile(e.data.mediaContentId)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var fileName = this.mediaMapperService.getfileName(this.ORG_ID, e.data.mediaContentId, contenType)
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    } else {
                        e.data.mediaFileNotFound = true;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
    }

    private getPicFilePreview(e, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    } else {
                        e.data.mediaFileNotFound = true;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
    }

    private readURLFile(e, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            e.data.mediaFilePreviewBase64 = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
            e.component.refresh();
        };
    }

    doubleClick(e) {
        this.selected = e.data;
        this.onRowDoubleClick.emit(this.selected);

    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StatusColorComponent } from "./status-color.component";

@NgModule({
    imports: [         
        CommonModule 
    ],
    declarations: [
        StatusColorComponent  
    ],
    exports: [
        StatusColorComponent
    ]
})
export class StatusColorModule { }
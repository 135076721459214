import { Injectable } from '@angular/core';
import { ApiServiceBase } from '../../base/api-service-base';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { VoucherStatusReferenceModel } from '../../models/reference-model/reference-general-model/voucher-status-reference.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VoucherStatusReferenceService extends ApiServiceBase {
    private readonly PATH = '/system/voucherStatusReferences';

    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
            super(httpClient, authService);
    }

    public getAll(): Observable<VoucherStatusReferenceModel[]> {
        return this.httpGet<VoucherStatusReferenceModel[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }
}

import { Component, AfterViewInit, OnDestroy, ElementRef, HostListener } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ActionBarHandlerModel, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PaymentSearchService } from "../payment-search.service";
import { PaymentCreditInvoiceService } from "..";


@Component({
    selector: 'op-payment-credit-invoice-tabs',
    templateUrl: './payment-credit-invoice-tabs.component.html'
})
export class PaymentCreditInvoiceTabsComponent implements AfterViewInit, OnDestroy {

    readonly LoadingName = 'payment'
    activeId = 'credit'

    newButton = new NewButtonModel()
    copyButton = new CopyButtonModel()
    saveButton = new SaveButtonModel()
    cancelButton = new CancelButtonModel()
    refreshButton = new RefreshButtonModel()
    loadingNotifier = new LoadingNotifier()

    unsubscribe$ = new Subject()
    subscriptions: Subscription[]

    constructor(private actionbarService: ActionbarService,
        private navigateService: NavigationService,
        public alertBarService: AlertBarService,
        private paymentSearchService: PaymentSearchService,
        private paymentCreditInvoiceService: PaymentCreditInvoiceService,
        private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.subscriptions = []
        this.newButton.enable(false)
        this.copyButton.enable(false)
        this.saveButton.enable(false)
        this.cancelButton.enable(true)
        this.refreshButton.enable(false)
        this.actionbarService.updateState(new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        ))
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.back:
                    case ACTION_STATUS.cancel:
                        this.navigateService.navigate("main/cashbook/payment", null)
                        break
                }
            }
        )

        this.subscriptions.push(
            this.paymentSearchService.alertSuccess$.subscribe(message => {
                this.alertBarService.showInformation('Payment', message)
            })
        )

        this.subscriptions.push(
            this.paymentSearchService.alertError$.subscribe(message => {
                this.alertBarService.show('Payment', message)
            })
        )

        this.subscriptions.push(this.paymentSearchService.loading$.subscribe(
            present => present ? this.loadingNotifier.show(this.LoadingName) : this.loadingNotifier.hide(this.LoadingName)))

        const paymentSearch = this.navigateService.getParams()
        this.paymentCreditInvoiceService.selectPaymentSearch(paymentSearch)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    hideAlertInformation() {
        this.alertBarService.hideInformation()
        this.alertBarService.hide()
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        this.hideAlertInformation()
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        const selfClick = event.target instanceof Element && event.target.closest('#paymentInvoicePay')
        const clickedInside = this.elementRef.nativeElement.contains(event.target);

        if (clickedInside && !selfClick) {
            this.hideAlertInformation()
        }
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { PhoneTypeSelectionComponent } from './phone-type-selection.component';
import { PresetPhoneTypeSelectionComponent } from './preset-phone-type-selection.component';

@NgModule({
    declarations: [
        PhoneTypeSelectionComponent,
        PresetPhoneTypeSelectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        NgbNavModule,
        Select2Module,
        UiModule
    ],
    exports: [
        PhoneTypeSelectionComponent,
        PresetPhoneTypeSelectionComponent
    ]
})
export class PhoneTypeSelectionModule { }

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxChartModule, DxDataGridModule } from "devextreme-angular";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductDashboardFeesCompareLast30DComponent } from "./product-dashboard-fees-compare-last30d/product-dashboard-fees-compare-last30d.component";
import { ProductDashboardFeesLastReceivedComponent } from "./product-dashboard-fees-lastreceived/product-dashboard-fees-lastreceived.component";
import { ProductDashboardFeesMiniChartsComponent } from "./product-dashboard-fees-mini-charts/product-dashboard-fees-mini-charts.component";
import { ProductDashboardFeesRevenueLast30DComponent } from "./product-dashboard-fees-revenue-last30d/product-dashboard-fees-revenue-last30d.component";
import { ProductDashboardFeesRevenueLast7DComponent } from "./product-dashboard-fees-revenue-last7d/product-dashboard-fees-revenue-last7d.component";
import { ProductDashboardFeesSoldLast30DComponent } from "./product-dashboard-fees-sold-last30d/product-dashboard-fees-sold-last30d.component";
import { ProductDashboardFeesSoldLast7DComponent } from "./product-dashboard-fees-sold-last7d/product-dashboard-fees-sold-last7d.component";
import { ProductDashboardFeesStatisticsComponent } from "./product-dashboard-fees-statistics/product-dashboard-fees-statistics.component";
import { ProductDashboardFeesSummaryComponent } from "./product-dashboard-fees-summary/product-dashboard-fees-summary.component";
import { ProductDashboardFeesComponent } from "./product-dashboard-fees.component";
import { DataGridRowNumberModule } from "../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductDashboardFeesComponent,
        ProductDashboardFeesCompareLast30DComponent,
        ProductDashboardFeesLastReceivedComponent,
        ProductDashboardFeesMiniChartsComponent,
        ProductDashboardFeesRevenueLast7DComponent,
        ProductDashboardFeesRevenueLast30DComponent,
        ProductDashboardFeesSoldLast7DComponent,
        ProductDashboardFeesSoldLast30DComponent,
        ProductDashboardFeesSummaryComponent,
        ProductDashboardFeesStatisticsComponent
    ],
    exports: [
        ProductDashboardFeesComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxChartModule,
        DxDataGridModule,
        FocusingModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        UiModule
    ]
})
export class ProductDashboardFeesModule { }
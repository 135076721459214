import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { PriceRuleModel } from 'src/app/core/models/pricing-model';

import { Helper } from 'src/app/shared/helper/app.helper';
import { TreeConverter } from './tree.converter';


@Component({
    selector: 'op-tree',
    templateUrl: './tree.component.html',
    providers: [
        TreeConverter
    ]
})
export class TreeComponent implements OnChanges {
    private readonly NAME_REGEX = /(?<name>\w.*)(?<copy> - Copy)( \((?<number>\d*)\))?/;

    treeData$ = new BehaviorSubject<TreeNode[]>(null);
    selectedNode: TreeNode;

    @Input() selectedPriceRule: PriceRuleModel;
    @Input() mainPriceRule: PriceRuleModel;
    @Input() nodeName: string;
    @Input() heightPanelGeneral: number;

    @Output() selectedPriceRuleChange = new EventEmitter<PriceRuleModel>();

    focusing: boolean = false;

    constructor(public helper: Helper,
        private treeConverter: TreeConverter) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mainPriceRule']?.currentValue) {
            this.populateTree();
        }
        if (changes['selectedPriceRule']?.currentValue) {
            if (this.treeData$?.value) {
                this.setSelectedNode(this.treeData$.value[0], this.selectedPriceRule);
            }
        }
        if (changes['nodeName'] && this.selectedNode) {
            this.selectedNode.label = this.nodeName;
        }
    }

    private populateTree() {
        if (!this.mainPriceRule) {
            return;
        }

        let node = this.treeConverter.modelToTreeNode(this.mainPriceRule);
        this.expandAll(node);
        this.treeData$.next([node]);
    }

    private expandAll(node: TreeNode) {
        node.expanded = true;
        if (node.children?.length) {
            for (let child of node.children) {
                this.expandAll(child)
            }
        }
    }

    private setSelectedNode(node: TreeNode, selectedPriceRule: PriceRuleModel) {
        if (!selectedPriceRule) {
            return;
        }
        if (node.data == selectedPriceRule.id) {
            this.selectedNode = node;
        }
        if (node.children?.length) {
            for (let child of node.children) {
                this.setSelectedNode(child, selectedPriceRule);
            }
        }
    }

    onSelectionChange(node: TreeNode<any> | TreeNode<any>[]) {
        if (Array.isArray(node)) return;
        if (!node?.data) {
            return;
        }
        this.selectedPriceRuleChange.emit(this.findSelectedPriceRule(node.data, this.mainPriceRule));
    }

    private findSelectedPriceRule(priceRuleId: string, priceRuleModel: PriceRuleModel): PriceRuleModel {
        let found: PriceRuleModel = null;
        if (priceRuleModel.id == priceRuleId) {
            return priceRuleModel;
        }
        if (priceRuleModel.children?.length) {
            for (let child of priceRuleModel.children) {
                found = this.findSelectedPriceRule(priceRuleId, child);
                if (found) {
                    return found;
                }
            }
        }
        return found;
    }
}
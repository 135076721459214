import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OperatorModel } from 'src/app/core/models/merchandizing-config';

@Injectable()
export class ProductTypeSetDefaultService {

    public setDefaultOperatorProductType(f: UntypedFormGroup, operatorProductType$: BehaviorSubject<OperatorModel[]>, operatorProductTypeOption) {
        var filterplaceHolder = operatorProductType$.value.filter(x => x.operatorCode == "");
        if (filterplaceHolder.length != 0) {
            operatorProductTypeOption.placeholder = filterplaceHolder[0].operatorName;
        }
        else {
            if (operatorProductType$.value.length != 0) {
                f.controls['operatorProductType'].setValue(operatorProductType$.value[0].operatorCode);
            }
        }
    }

}
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CounterSalesPointOfSaleModel, CounterSalesSearchModel, CounterSalsesSearchCommandModel } from 'src/app/core/models/counter-sales-model';
import { OrganisationAttributeModel, OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ProductCategoryViewModel } from 'src/app/core/models/reference-model/reference-product-model/product-category-view.model';
import { CounterSaleService } from 'src/app/core/services/counter-sale-service';
import { ProductCategoryService } from 'src/app/core/services/reference-service/reference-product-service/product-category.service';
import { AttributeMaintenanceService, ProductGroupReferenceService } from 'src/app/core/services/system-services';
import { UserAccountOrganisationService } from 'src/app/core/services/user-account-services/user-account-organisation.service';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { CounterSalesSearchConditionComponent } from './counter-sales-search-condition/counter-sales-search-condition.component';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { ActionService } from 'src/app/core/utils/action.service';
import { OrganisationAttributeService } from 'src/app/core/services/organisation-services/organistion-attribute.service';
import { AttributeChoiceView } from 'src/app/modules/settings-management/settings-categories-content/attribute/attribute-content/shared/view/attribute-choice.view';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
    selector: 'op-counter-sales-search',
    templateUrl: './counter-sales-search.component.html',
})
export class CounterSalesSearchComponent implements OnInit, OnChanges {
    readonly SPINNER_NAME: string = "counterSaleSearchSpinner";
    private readonly COUNTERSALES_ALL = 'COUNTERSALESALL';
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    private readonly DATETIME_FORMAT_ATTRIBUTE = 'DATETIME';
    private readonly LONGDATE_FORMAT_ATTRIBUTE = 'LONGDATE';

    public organisations$ = new BehaviorSubject<OrganisationModel[]>(null);
    public pointOfSales$ = new BehaviorSubject<CounterSalesPointOfSaleModel[]>(null);
    public productCategory$ = new BehaviorSubject<ProductCategoryViewModel[]>(null);
    public productGroup$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productType$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public searchResults$ = new BehaviorSubject<CounterSalesSearchModel[]>(null);
    public loadingNotifier = new LoadingNotifier();
    public organisationId: string;
    public organisationDateTimeFormat$ = new BehaviorSubject<string>(null);
    public organisationLongDateFormat$ = new BehaviorSubject<string>(null);
    public organisationCallName: string;
    public counterSalesSearchCondition: CounterSalsesSearchCommandModel;

    @Input() refreshValue: boolean;
    @Output() setRefreshValue = new EventEmitter();
    @Input() userSecurity: SecurityGroupSecurityModel;

    @ViewChild(CounterSalesSearchConditionComponent) searchCondition: CounterSalesSearchConditionComponent;

    constructor(
        public productGroupReferenceService: ProductGroupReferenceService,
        public userAccountOrganisationService: UserAccountOrganisationService,
        public productCategoryService: ProductCategoryService,
        public counterSaleService: CounterSaleService,
        private organisationService: OrganisationService,
        private actionService: ActionService,
        private organisationAttributeService: OrganisationAttributeService,
        private attributeMaintenanceService: AttributeMaintenanceService,
        private authService: AuthService
    ) {
        this.organisationId = this.authService.getSelectedOrganisation();
        this.getOrganisationDatetimeFormat();
    }

    ngOnInit(): void {
        this.getOrganisation();
        this.fetchDropDownSearch();
        this.actionService.add('SEARCH', null, null)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['refreshValue']) {
            if (this.refreshValue == true) {
                this.reSearch();
            }
        }
    }

    private fetchDropDownSearch() {
        this.loadingNotifier.show(this.SPINNER_NAME);
        forkJoin({
            responsePointOfSale: this.counterSaleService.getCounterSalesPointOfSale(),
            responseProductCategoryReference: this.productCategoryService.getAllActive(),
            responseProductGroupReference: this.productGroupReferenceService.getAll(),
            responseProductTypeReference: this.productCategoryService.getAllProductType()
        })
            .subscribe(
                ({
                    responsePointOfSale,
                    responseProductCategoryReference,
                    responseProductGroupReference,
                    responseProductTypeReference
                }) => {
                    this.pointOfSales$.next(responsePointOfSale);
                    this.productCategory$.next(responseProductCategoryReference);
                    this.productGroup$.next(responseProductGroupReference);
                    this.productType$.next(responseProductTypeReference.filter(x => x.productTypeName != ""));
                    this.loadingNotifier.hide(this.SPINNER_NAME);

                }
            ), () => {
                this.loadingNotifier.hide(this.SPINNER_NAME);
            }
    }

    private getOrganisation() {
        if (this.userSecurity?.securityCode == this.COUNTERSALES_ALL) {
            this.getOrganisationAll();
            return;
        }
        this.getUserOrganisation();
      }
    
    private getUserOrganisation() {
        this.userAccountOrganisationService.getOrganisationUser()
            .subscribe(
                (responses: any) => {
                    this.organisations$.next(responses);
                }
            )
    }

    private getOrganisationAll() {
        const ORG_ALL_CODE = 'ORGANISATIONALL'
        let condition = new OrganisationModel();
        condition.searchStatusCode = 'A';
        condition.searchUsageTypeCode = 'DATA';
        this.organisationService.advanceSearchOrganisation(condition, ORG_ALL_CODE)
        .subscribe(
            (response: any) => {
                this.organisations$.next(response);
            })
    }

    onClear() {
        this.searchCondition.initForm();
    }

    public onSearch(command: CounterSalsesSearchCommandModel) {
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.counterSaleService.searchCounterSalesResult(command)
            .subscribe(
                (response: CounterSalesSearchModel[]) => {
                    this.counterSalesSearchCondition = command;
                    this.setOrganisationCallName(command.organisationId);
                    this.searchResults$.next(response);
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                    this.setRefreshValue.emit(false);
                },
                () => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                    this.setRefreshValue.emit(false);
                }
            );
    }

    reSearch() {
        this.searchCondition.doSearch();
    }

    public getOrganisationDatetimeFormat() {
        forkJoin({
            organisationAttributes: this.organisationAttributeService.getOrganisationAttribute(),
            dateTimeFormatReferernce: this.attributeMaintenanceService.getAttributeChoiceByAttributeTypeCode(this.DATETIME_FORMAT_ATTRIBUTE)
        })
        .subscribe((responses) => {
            this.getOrganisationDatetimeFormatFromAttribute(responses?.organisationAttributes, responses?.dateTimeFormatReferernce);
            this.getOrganisationLongDateFormat(responses?.organisationAttributes, responses?.dateTimeFormatReferernce)
        });
    }

    private getOrganisationDatetimeFormatFromAttribute(organisationAttributes: OrganisationAttributeModel[], dateTimeChoices: AttributeChoiceView[]) {
        let dateTimeFormat = organisationAttributes?.find(item => item.organisationAttributeTypeCode == this.DATETIME_FORMAT_ATTRIBUTE);
        if (dateTimeFormat && dateTimeChoices) {
            this.organisationDateTimeFormat$.next(dateTimeChoices?.find(item => item.attributeChoiceId == dateTimeFormat.organisationAttributeChoiceId)?.attributeChoiceName);
        }
    }

    private getOrganisationLongDateFormat(organisationAttributes: OrganisationAttributeModel[], dateTimeChoices: AttributeChoiceView[]) {
        let longTimeFormat = organisationAttributes?.find(item => item.organisationAttributeTypeCode == this.LONGDATE_FORMAT_ATTRIBUTE);
        if (longTimeFormat && dateTimeChoices) {
            this.organisationLongDateFormat$.next(dateTimeChoices?.find(item => item.attributeChoiceId == longTimeFormat.organisationAttributeChoiceId)?.attributeChoiceName);
        }
    }

    private setOrganisationCallName(organisationId: string) {
        this.organisationCallName = this.organisations$.value?.find(org => org.organisationId == organisationId)?.organisationCallName ?? '';
    }
}


import { Injectable } from '@angular/core';

import { ApiServiceBase } from '../../base/api-service-base';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    OrderHeaderModel,
    OrderDetailAttributeModel,
    OrderDetailCommentModel,
    OrderDetailIndividualAccountingModel,
    OrderDetailIndividualModel,
    OrderDetailPaymentModel,
    OrderDetailServiceModel,
    OrderIndividualAccountableDocumentModel,
    OrderIndividualDocumentModel,
    OrderProductModel,
    OrderPointOfSalesReferenceModel
} from '../../models/order-model';
import { OrderDetailPartnerModel } from '../../models/order-model/order-detail-partner.model';

@Injectable({
    providedIn: 'root'
})
export class OrderDetailServices extends ApiServiceBase {

    private readonly PATH: string = "/order"
    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient, authService);
    }

    public getOrderDetailHeader(orderId: string): Observable<OrderHeaderModel> {

        return this.httpGet<OrderHeaderModel>(this.PATH + "/orderdetails/" + orderId + "/header", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailIndividuals(orderId: string, partnerRoleCodes: string[], sessionId: string = null): Observable<OrderDetailIndividualModel[]> {

        let params = new HttpParams();
        if (partnerRoleCodes) {
            params = params.append('orderPartnerRoleCodes', partnerRoleCodes.join(','));
        }
        let url: string = this.PATH + "/orderdetails/" + orderId + "/individuals/";
        if (!sessionId) {
            url += "search";
        } else {
            url += "sessions/" + sessionId + "/search";
        }
        return this.httpGet<OrderDetailIndividualModel[]>(url, params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailPartners(orderId: string, partnerRoleCodes: string[]): Observable<OrderDetailPartnerModel[]> {

        let params = new HttpParams();
        if (partnerRoleCodes) {
            params = params.append('orderPartnerRoleCodes', partnerRoleCodes.join(','));
        }
        return this.httpGet<OrderDetailPartnerModel[]>(this.PATH 
            + "/orderdetails/" 
            + orderId + 
            "/partners", params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailPartner(orderId: string, partnerOrderId: string, sessionId: string = null): Observable<OrderDetailIndividualModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/partners/" + partnerOrderId;
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderDetailIndividualModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailIndividual(orderId: string, individualId: string): Observable<OrderDetailIndividualModel[]> {

        return this.httpGet<OrderDetailIndividualModel[]>(this.PATH 
            + "/orderdetails/" 
            + orderId 
            + "/individuals/" 
            + individualId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderProductDetailIndividuals(orderId: string, orderProductId: string, sessionId: string): Observable<OrderDetailIndividualModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/products/" + orderProductId + "/individuals";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderDetailIndividualModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    
    public unlockOrder(orderId: string): Observable<any> {
        return this.httpPut<any>(this.PATH + "/orderlocks/" + orderId + "/user", null)
            .pipe(
                map(response => {
                    return response.status;
                })
            );
    }

    public getOrderDetailProduct(orderId: string): Observable<OrderProductModel[]> {

        return this.httpGet<OrderProductModel[]>(this.PATH + "/orderdetails/" + orderId + "/products", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailProductHistory(orderId: string, sessionId: string = null): Observable<OrderProductModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/products/history";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderProductModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getIndividualOrderDetailProduct(orderId: string, partnerOrderId: string): Observable<OrderProductModel[]> {

        return this.httpGet<OrderProductModel[]>(this.PATH
            + "/orderdetails/"
            + orderId
            + "/partners/"
            + partnerOrderId
            + "/products", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getPartnerOrderTransportProductProduct(orderId: string, 
        partnerOrderId: string, 
        productOrderId: string,
        sessionId: string): Observable<OrderProductModel[]> {

        let url: string = this.PATH 
            + "/orderdetails/" 
            + orderId 
            + "/partners/"
            + partnerOrderId
            + "/products/"
            + productOrderId
            + "/products";

        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderProductModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getPartnerOrderTransportProductProductHistory(orderId: string, 
        partnerOrderId: string, 
        productOrderId: string,
        sessionId: string): Observable<OrderProductModel[]> {

        let url: string = this.PATH 
            + "/orderdetails/" 
            + orderId 
            + "/partners/"
            + partnerOrderId
            + "/products/"
            + productOrderId
            + "/products/history";

        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderProductModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailProductsByOrder(parentOrderId: string, orderid: string): Observable<OrderProductModel[]> {

        return this.httpGet<OrderProductModel[]>(this.PATH
            + "/orderdetails/"
            + parentOrderId
            + "/orders/"
            + orderid
            + "/products", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailProductsAtOrderItems(parentOrderId: string, orderid: string): Observable<OrderProductModel[]> {

        return this.httpGet<OrderProductModel[]>(this.PATH
            + "/orderdetails/"
            + parentOrderId
            + "/order-items/"
            + orderid
            + "/products", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailProductsFromLedger(parentOrderId: string, ledgerId: string): Observable<OrderProductModel[]> {

        return this.httpGet<OrderProductModel[]>(this.PATH
            + "/orderdetails/"
            + parentOrderId
            + "/ledgers/"
            + ledgerId
            + "/products", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderIndividualDocuments(orderId: string, sessionId: string = null): Observable<OrderIndividualDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderIndividualDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderIndividualDetailDocuments(orderId: string, partnerOrderId: string, sessionId: string = null): Observable<OrderIndividualDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/partners/" + partnerOrderId + "/documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }
        return this.httpGet<OrderIndividualDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderProductDetailDocuments(orderId: string, orderProductId: string, sessionId: string = null): Observable<OrderIndividualDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/products/" + orderProductId + "/documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }
        return this.httpGet<OrderIndividualDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailDocumentsByOrder(parentOrderId: string, orderId: string, sessionId: string = null): Observable<OrderIndividualDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + parentOrderId + "/orders/" + orderId + "/documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderIndividualDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderIndividualAccountableDocuments(orderId: string, sessionId: string = null): Observable<OrderIndividualAccountableDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/accountable-documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderIndividualAccountableDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderProductAccountableDocuments(orderId: string, orderProductId: string, sessionId: string = null): Observable<OrderIndividualAccountableDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/products/" + orderProductId + "/accountable-documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderIndividualAccountableDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderIndividualDetailAccountableDocuments(orderId: string, partnerOrderId: string, sessionId: string = null): Observable<OrderIndividualAccountableDocumentModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/partners/" + partnerOrderId + "/accountable-documents";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderIndividualAccountableDocumentModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailServices(orderId: string, sessionId: string = null): Observable<OrderDetailServiceModel[]> {

        let url: string = this.PATH + "/orderdetails/" + orderId + "/services";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }
        return this.httpGet<OrderDetailServiceModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailServicesByOrder(parentOrderId: string, orderId: string): Observable<OrderDetailServiceModel[]> {

        return this.httpGet<OrderDetailServiceModel[]>(this.PATH 
            + "/orderdetails/" 
            + parentOrderId
            + "/orders/"
            + orderId
            + "/services", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailPayments(orderId: string): Observable<OrderDetailPaymentModel[]> {

        return this.httpGet<OrderDetailPaymentModel[]>(this.PATH + "/orderdetails/" + orderId + "/payments", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderDetailComments(orderId: string, orderStateCode: string): Observable<OrderDetailCommentModel[]> {

        let params = new HttpParams();
        if (orderStateCode) {
            params = params.append('orderStateCode', orderStateCode);
        }
        return this.httpGet<OrderDetailCommentModel[]>(this.PATH 
            + "/orderdetails/" 
            + orderId 
            + "/comments/search", params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailCommentsFromCommentOrder(orderId: string, commentOrderId: string): Observable<OrderDetailCommentModel[]> {

        return this.httpGet<OrderDetailCommentModel[]>(this.PATH 
            + "/orderdetails/" 
            + orderId 
            + "/comment-orders/"
            + commentOrderId
            + "/comments", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailAttributes(orderId: string, attributeOrderId: string, domainCode: string): Observable<OrderDetailAttributeModel[]> {

        let params = new HttpParams();
        if (domainCode) {
            params = params.append('domainCode', domainCode);
        }
        return this.httpGet<OrderDetailAttributeModel[]>(this.PATH + "/orderdetails/" + orderId + "/attributes/" + attributeOrderId + "/search", params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    
    public getOrderDetailIndividualAccounting(orderId: string, partnerOrderId: string)
        : Observable<OrderDetailIndividualAccountingModel[]> {
        return this.httpGet<OrderDetailIndividualAccountingModel[]>(this.PATH
            + "/orderdetails/"
            + orderId
            + "/partners/"
            + partnerOrderId
            + "/accountings", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailProductAccounting(orderId: string, productId: string)
        : Observable<OrderDetailIndividualAccountingModel[]> {
        return this.httpGet<OrderDetailIndividualAccountingModel[]>(this.PATH
            + "/orderdetails/"
            + orderId
            + "/products/"
            + productId
            + "/accountings", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailIndividualGuardian(orderId: string, parentOrderId: string, sessionId: string = null): Observable<OrderDetailIndividualModel[]> {

        let url: string = this.PATH + "/orderdetails/" + parentOrderId + "/order/" + orderId + "/guardian";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderDetailIndividualModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    public getOrderDetailProductChildren(parentOrderId: string, orderid: string, sessionId: string): Observable<OrderProductModel[]> {

        let url: string = this.PATH + "/orderdetails/" + parentOrderId + "/products/" + orderid + "/products";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderProductModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getOrderPaymentIndividual(parentOrderId: string, ledgerId: string, sessionId: string): Observable<OrderDetailIndividualModel[]> {

        let url: string = this.PATH + "/orderdetails/" + parentOrderId + "/ledgers/" + ledgerId + "/individuals";
        if (sessionId) {
            url += "/sessions/" + sessionId;
        }

        return this.httpGet<OrderDetailIndividualModel[]>(url, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public unlockOrderWithSecurity(orderId: string): Observable<any> {
        return this.httpPut<any>(this.PATH + "/orderlocks/" + orderId + "/security", null)
            .pipe(
                map(response => response.body)
            )
    }

    public takeOrderControl(orderId: string): Observable<any> {
        return this.httpPut<any>(this.PATH + "/orderlocks/" + orderId + "/control", null)
            .pipe(
                map(response => response.body)
            )
    }

    public getOrderPointOfSalesReference(orderId: string): Observable<OrderPointOfSalesReferenceModel> {

        return this.httpGet<OrderPointOfSalesReferenceModel>(this.PATH + "/orderdetails/" + orderId + "/pointOfSalesReference", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
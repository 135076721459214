// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#calendarContainer .fc-daygrid-day-number {
  padding: 0px !important;
}

#calendarContainer .fc-col-header {
  min-width: 1500px !important;
}

#calendarContainer .fc-scrollgrid-sync-table {
  min-width: 1500px !important;
}

#calendarContainer .fc-scroller.fc-scroller-liquid-absolute {
  overflow-x: scroll !important;
}

#calendarContainer .fc .fc-daygrid-day-frame {
  max-height: 120px !important;
}

.dayNumber {
  font-size: 1.2em;
  font-weight: 500;
}

.numberInfo {
  font-size: 0.9em;
  font-weight: 500;
}

#calendarContainer .fc .fc-scrollgrid-section-body table {
  border-bottom-style: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/operating-product/calendar/operating-product-calendar.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACE,qCAAA;AACF","sourcesContent":["#calendarContainer .fc-daygrid-day-number{\r\n  padding:0px!important;\r\n}\r\n\r\n#calendarContainer .fc-col-header {\r\n    min-width: 1500px!important;\r\n}\r\n\r\n#calendarContainer .fc-scrollgrid-sync-table {\r\n    min-width: 1500px!important;\r\n}\r\n\r\n#calendarContainer .fc-scroller.fc-scroller-liquid-absolute {\r\n    overflow-x: scroll!important;\r\n}\r\n\r\n#calendarContainer .fc .fc-daygrid-day-frame {\r\n    max-height: 120px!important;\r\n}\r\n\r\n.dayNumber {\r\n    font-size:1.2em;\r\n    font-weight:500;\r\n}\r\n\r\n.numberInfo {\r\n    font-size:0.9em;\r\n    font-weight:500;\r\n}\r\n\r\n#calendarContainer .fc .fc-scrollgrid-section-body table {\r\n  border-bottom-style: unset!important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

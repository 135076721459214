import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { MerchandizeViewModel } from 'src/app/core/models/product-model/merchandize-model';
import { StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { ACTION_HANDLER_STATUS } from 'src/app/shared/ui/actionbar';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2DefaultOption, select2DefaultOptionWithAllowClear } from '../shared/merchandize-search-information-configuration';

@Component({
    selector: 'op-merchandize-search-condition',
    templateUrl: './merchandize-search-condition.component.html'
})
export class MerchandizeSearchConditionComponent  extends OopsComponentFormBase implements OnChanges {
    readonly actionForm = ACTION_HANDLER_STATUS;
    readonly errorProductGroupRequired = 'Product Group is required.';

    @Input() actionHandlerMode: string;
    @Input() advanceSearchMode: boolean;
    @Input() merchandizeView: MerchandizeViewModel;
    @Input() productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    @Input() productSearchFilterId: string | string[] = null;
    @Input() productSearchFilters: Select2Data[];
    @Input() productTypeGroups$ = new BehaviorSubject<ProductTypeGroupModel[]>(null)
    @Input() saveFilter: boolean = false;
    @Input() statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    @Input() usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null)

    @Output() advanceSearchModeChanged = new EventEmitter<boolean>();
    @Output() formStatus = new EventEmitter<boolean>();
    @Output() onClearClick = new EventEmitter();
    @Output() onSearchClick = new EventEmitter<MerchandizeViewModel>();
    @Output() productSearchFilterIdChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public productTypeGroupFilters$: BehaviorSubject<ProductTypeGroupModel[]>;

    public select2DefaultOption = select2DefaultOptionWithAllowClear;
    public select2DefaultOptionMandatory = select2DefaultOption;
    public focusing: boolean = false;
    public statusOption: any;

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get productTypeGroupCode(): string | string[] {
        return this.form.controls['productTypeGroupCode'].value;
    }

    set productTypeGroupCode(value: string | string[]) {
        this.form.controls['productTypeGroupCode'].patchValue(value);
    }

    get productGroupCode(): string | string[] {
        return this.form.controls['productGroupCode'].value;
    }

    set productGroupCode(value: string | string[]) {
        this.form.controls['productGroupCode'].patchValue(value);
    }

    get usageTypeCode(): string | string[] {
        return this.form.controls['usageTypeCode'].value;
    }

    set usageTypeCode(value: string | string[]) {
        this.form.controls['usageTypeCode'].patchValue(value);
    }

    get statusCode(): string | string[] {
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }

    constructor(
        fb: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private actionService: ActionService) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["merchandizeView"]) {
            let view = this.merchandizeView;
            this.productSearchFilterId = view?.productId ?? this.productSearchFilterId;
            this.saveFilter = false;
            if (this.formGroup) {
                this.formGroup.patchValue({
                    productTypeGroupCode: view.productTypeCode,
                    productName: view.searchName,
                    productGroupCode: view.productGroupCode,
                    usageTypeCode: view.searchUsageTypeCode,
                    statusCode: view.searchStatusCode
                });
                this.filterProductTypeGroup(view.productGroupCode);
            }
        } 
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            productTypeGroupCode: new UntypedFormControl(null),
            productGroupCode: new UntypedFormControl(null, Validators.required),
            productName: new UntypedFormControl(null),
            usageTypeCode: new UntypedFormControl(null),
            statusCode: new UntypedFormControl("A")
        });
        this.formGroup.statusChanges
        .subscribe(val => this.onFormStatusChange());

        if (this.productSearchFilterId) {
            this.onProductSearchFilterIdChange(this.productSearchFilterId);
        }
    }

    private onFormStatusChange() {
        if(this.processing){
            this.formStatus.emit(this.formGroup.valid);
        }
    }
    
    onProductSearchFilterIdChange(productId) {
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/MERCHANDISE', productId, this.getSerchFilterName(productId));
        this.productSearchFilterIdChange.emit(productId);
    }

    public toggleAdvanceSearch() {
        this.advanceSearchMode = !this.advanceSearchMode;
        this.advanceSearchModeChanged.emit(this.advanceSearchMode);
    }

    public searchCondition() {
        this.onSearchClick.emit(this.getValues());
    }

    public clearCondition() {
        this.saveFilter = false;
        this.onClearClick.emit();
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public productGroupChange(value) {
        this.saveFilter = false;
        this.filterProductTypeGroup(value);
    }

    public getValues(saveFilter: boolean = false): MerchandizeViewModel {
        this.saveFilter = saveFilter;
        this.changeDetectionRef.detectChanges();
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): MerchandizeViewModel {
        let model = new MerchandizeViewModel();
        model.searchName = this.getFormControlValue("productName");
        model.productGroupCode = this.getFormControlValue("productGroupCode");
        model.productTypeCode = this.getFormControlValue("productTypeGroupCode");
        model.searchUsageTypeCode = this.getFormControlValue("usageTypeCode");
        model.searchStatusCode = this.getFormControlValue("statusCode");
        model.statusCode = "A";
        return model;
    }

    public filterProductTypeGroup(productGroupCode) {
        if (this.productTypeGroups$.value) {
            this.productTypeGroupFilters$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
            if (productGroupCode) {
                var filter = this.productTypeGroups$.value.filter(x => x.productGroupCode == productGroupCode);
                this.productTypeGroupFilters$.next(filter);
            }
        }
    }

    public validateForm(): boolean {
        this.startProcessing();
        let result = this.validForm();
        this.changeDetectionRef.detectChanges()
        if (result) {
            this.completeProcessing();
        }
        return result;
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.errorProductGroupRequired;
        }
    }

    getSerchFilterName(productId: string) {
        return this.productSearchFilters?.find(filter => filter.id == productId)?.text;
    } 
}

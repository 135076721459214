import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { RestrictionModel } from 'src/app/core/models/merchandizing-config';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ConditionReferenceModel, VehicleCompositionModel, VehicleGroupReferenceModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { 
    ProductCategoryReferenceModel, 
    ProductGroupReferenceModel, 
    ProductLocationPointReferenceModel, 
    ProductLocationTypeReferenceModel, 
    ProductNumberTypeReferenceModel, 
    ProductTypeGroupModel 
} from 'src/app/core/models/reference-model/reference-product-model';
import { VehicleConfigurationModel } from 'src/app/core/models/vehicle-model/vehicle-configuration';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-ancillary-service-restriction',
    templateUrl: './restriction.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestrictionComponent {
    private readonly _jsonURL = 'assets/data/ancillary/attributes&rules_config.json';

     //Product restriction product
     @Input() productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
     @Input() productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
     @Input() productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
 
     //Product restriction product number
     @Input() productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
     @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
     @Input() supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
 
     //Product restriction location
     @Input() conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
     @Input() productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
     @Input() productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
     @Input() countryReferenceSelect2Data: Select2Data[];
     @Input() locationGroupSelect2Data: Select2Data[];
     @Input() regionReferenceSelect2Data: Select2Data[];

    //Product Restriction Vehicle
    @Input() vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    @Input() vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);

     @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
 
     public restrictionConfig: RestrictionModel;
     public focusing: boolean = false;
     
     constructor(private http: HttpClient,
        private changeDetectorRef: ChangeDetectorRef) {
            this.getJSON().subscribe(data => {
                this.restrictionConfig = data.restrictions;
                this.changeDetectorRef.detectChanges();
            });
     }

     private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        this.ruleComponent.add();
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    get addDisable() {
        return this.controlLength > 0;
    }
}

import { Injectable } from "@angular/core";
import { InsightPassengerMembershipModel } from "src/app/core/models/individual-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { MembershipView } from "./membership-view";

declare var moment: any;

@Injectable()
export class MembershipMapperService {
    constructor(private dateConverterService: DateConverterService) {
    }

    public membershipModelToViews(models: InsightPassengerMembershipModel[]) : MembershipView[] {
        let views = new Array();
        if (models) {
            for (let i = 0; i < models.length; i++) {
                views.push(this.membershipModelToView(i + 1, models[i]));
            }
        }
        return views;
    }

    private membershipModelToView(index: number, model: InsightPassengerMembershipModel) : MembershipView {
        let view: MembershipView = {
            no: index,
            membershipId: model.membershipId,
            individualId: model.individualId,
            providerId: model.providerId,
            providerIataCode: model.providerIataCode,
            memberNumber: model.memberNumber,
            primaryFlag: model.primaryFlag,
            organisationId: model.organisationId,
            issueDate: this.dateConverterService.toDateFormatOrNull(model.issueDate),
            expiryDate: this.dateConverterService.toDateFormatOrNull(model.expiryDate),
            membershipTypeCode: model.membershipTypeCode,
            membershipTypeName: model.membershipTypeName,
            membershipLevelCode: model.membershipLevelCode,
            membershipLevelName: model.membershipLevelName,
            membershipLevelId: model.membershipLevelId
        }

        return view;
    }

    public membershipViewToModels(views: MembershipView[]): InsightPassengerMembershipModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.membershipViewToModel(view));
        }
        return models;
    }

    private membershipViewToModel(view: MembershipView): InsightPassengerMembershipModel {
        let model: InsightPassengerMembershipModel = {
            membershipId: view.membershipId,
            individualId: view.individualId,
            providerId: view.providerId,
            providerIataCode: view.providerIataCode,
            memberNumber: view.memberNumber,
            primaryFlag: view.primaryFlag,
            expiryDate: view.expiryDate,
            issueDate: view.issueDate,
            organisationId: view.organisationId,
            membershipTypeCode: view.membershipTypeCode,
            membershipTypeName: view.membershipTypeName,
            membershipLevelCode: view.membershipLevelCode,
            membershipLevelName: view.membershipLevelName,
            membershipLevelId: view.membershipLevelId
        }

        return model;
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { StatusReferenceModel } from '../../models/reference-model/reference-general-model';
import { StatusReferenceService } from '../../services/system-services';

@Component({
    selector: 'op-status-selection',
    templateUrl: './status-selection.component.html'
})
export class StatusSelectionComponent implements OnInit {
    public readonly STATUS_OPTION = {
        placeholder: "",
        allowClear: true,
        minimumResultsForSearch: -1,
        width: 'auto',
        dropdownAutoWidth: true
    }

    public _value: string;
    public statusReferences$ = new BehaviorSubject<Select2Data[]>(null);
    

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input() disabled: boolean = false;
    @Output() dataChange: EventEmitter<string> = new EventEmitter();
    
    constructor(private statusReferenceService: StatusReferenceService) { }

    ngOnInit(): void {
        this.getStatusReference();
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReferences$.next(responses?.map(item => new Select2Data(item.statusCode, item.displayName)));
                    this.onValueChange('A')
                }
            )
    }

    public onValueChange(value: string | string[]) {
        this._value = value ? value as string : null;
        this.dataChange.emit(this._value);
    }

}

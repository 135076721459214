import { AfterViewInit, Directive, HostBinding, HostListener } from '@angular/core';

declare const window: any;

@Directive({
    selector: '[freezingTab]'
})
export class FreezingTabDirective implements AfterViewInit  {
    
    ngAfterViewInit(): void {
        this.toggleElementClass();
    }

    @HostBinding('class')
    elementClass = 'freeze-tabs';

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.toggleElementClass();
    }

    private toggleElementClass() {
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number > 10) {
            document.querySelectorAll('.freeze-tabs').forEach((c) => {
                c.classList.add('freeze-tabs-scroll');
            });
        } else {
            document.querySelectorAll('.freeze-tabs').forEach((c) => {
                c.classList.remove('freeze-tabs-scroll');
            });
        }
    }

}

import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { ActionbarService } from "src/app/shared/ui/actionbar";
import { NavigationService } from "src/app/shared/utils/navigation";
import { InsightCategory } from "./insight-categories/insight-category";
import { INSIGHT_TABS } from "./insight-tabs/insight-tabs.constant";

@Component({
    selector: 'op-insight-content',
    templateUrl: './insight-management-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class InsightManagementContentComponent {
    readonly DEFAULT_TITLE = 'Insgiht Management';
    @ViewChild('insightTabs') insightTabs: any;

    public selectedItem: InsightCategory;
    public title: String = this.DEFAULT_TITLE;

    constructor(public alertBarService: AlertBarService,
        private navigationService: NavigationService) { 

    }

    public onSelectedTabChange(activeTabId: string) {
        if (activeTabId == INSIGHT_TABS.search) {
            if (this.selectedItem) {
                this.title = this.selectedItem.name + ' Management';
                return;
            }
        }
        this.title = this.DEFAULT_TITLE;
    }

    public onRowSelected(item: InsightCategory) {
        this.selectedItem = item;
    }
}
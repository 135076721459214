import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AccountRecoveryService } from 'src/app/core/services/account-recovery-services/account-recovery.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { Store } from '@ngrx/store';
import { selectChangePasswordRequest, selectForgotPasswordUserName } from 'src/app/store/auth/auth.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'op-forgot-password',
    templateUrl: './forgot-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

    private readonly FAILED_MESSAGE = "Sorry, we are unable to identify your information or user is not active";
    private readonly SUCCESS_MESSAGE = "An email with your username has been sent. If the email does not arrive soon, please check your spam folder.";
    private readonly CONTROL_USERNAME: string = "username";

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public errorText$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public loginForm: UntypedFormGroup;
    public disableSubmit: boolean = false;
    public changePasswordLinkInvalid: boolean = false;
    public changePasswordLinkInvalidMessage: string;

    constructor(private fb: UntypedFormBuilder,
        private navigationService: NavigationService,
        private accountRecoveryService: AccountRecoveryService,
        private loadingSpinner: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<any>) {
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            username: [null]
        });
    }

    ngAfterViewInit(): void {
        this.getDefaultUserName();
        this.getChangePasswordLinkInvalid();
    }

    private getDefaultUserName() {
        this.store.select(selectForgotPasswordUserName).pipe(takeUntil(this.destroy$)).subscribe(userName => {
            this.loginForm.controls[this.CONTROL_USERNAME].setValue(userName);
        })
    }

    private getChangePasswordLinkInvalid() {
        this.store.select(selectChangePasswordRequest).pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.changePasswordLinkInvalid = data.changePasswordLinkInvalid;
            this.changePasswordLinkInvalidMessage = data.changePasswordLinkInvalidMessage;
            this.changeDetectionRef.detectChanges();
        });
    }

    public gotoLogon() {
        this.navigationService.navigate('auth', 'Logon');
    }

    public onResetPassword() {
        let userName = this.loginForm.controls[this.CONTROL_USERNAME].value;
        if (userName) {
            this.loadingSpinner.showSaving();
            this.accountRecoveryService.forgotPassword(userName)
                .subscribe(
                    () => {
                        this.loadingSpinner.saveComplete();
                        this.disabledForm();
                        this.displayAlertBarWarning();
                        this.changeDetectionRef.detectChanges();
                    },
                    (error) => this.handleError(),
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private disabledForm() {
        this.loginForm.controls[this.CONTROL_USERNAME].disable();
        this.disableSubmit = true;
        this.errorText$.next("");
    }

    private displayAlertBarWarning() {
        this.alertBarService.showSuccess(this.SUCCESS_MESSAGE);
    }

    private handleError() {
        this.errorText$.next(this.FAILED_MESSAGE);
    }

    public onRequestNewPassword() {
        this.changePasswordLinkInvalid = false;
        this.changeDetectionRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.loadingSpinner.hide();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
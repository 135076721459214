export const validityConfigData = {
    types: [
        {
            typeCode: "calendar",
            typeName: "Calendar",
            dateType: [
                {
                    dateTypeCode: "service",
                    dateTypeName: "Service",
                    calendarType: [
                        {
                            calendarTypeCode: "date",
                            calendarTypeName: "Date",
                            timeZone: [
                                {
                                    timeZoneCode: "",
                                    timeZoneName: "<Timezone>"
                                },
                                {
                                    timeZoneCode: "utc",
                                    timeZoneName: "UTC"
                                },
                                {
                                    timeZoneCode: "local",
                                    timeZoneName: "Local"
                                }
                            ],
                            operators: [
                                {
                                    operatorCode: "=",
                                    operatorName: "is",
                                    weekday: false
                                },
                                {
                                    operatorCode: "!=",
                                    operatorName: "is not",
                                    weekday: false
                                },
                                {
                                    operatorCode: "><",
                                    operatorName: "is between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<>",
                                    operatorName: "is not between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<=",
                                    operatorName: "is less than",
                                    weekday: true
                                },
                                {
                                    operatorCode: "=>",
                                    operatorName: "is greater than",
                                    weekday: true
                                }
                            ],
                            operatorDefault: "><",
                            min: "2001/01/01",
                            max: "2099/12/31"
                        },
                        {
                            calendarTypeCode: "datetime",
                            calendarTypeName: "Datetime",
                            timeZone: [
                                {
                                    timeZoneCode: "",
                                    timeZoneName: "<Timezone>"
                                },
                                {
                                    timeZoneCode: "utc",
                                    timeZoneName: "UTC"
                                },
                                {
                                    timeZoneCode: "local",
                                    timeZoneName: "Local"
                                }
                            ],
                            operators: [
                                {
                                    operatorCode: "=",
                                    operatorName: "is",
                                    weekday: false
                                },
                                {
                                    operatorCode: "!=",
                                    operatorName: "is not",
                                    weekday: false
                                },
                                {
                                    operatorCode: "><",
                                    operatorName: "is between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<>",
                                    operatorName: "is not between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<=",
                                    operatorName: "is less than",
                                    weekday: true
                                },
                                {
                                    operatorCode: "=>",
                                    operatorName: "is greater than",
                                    weekday: true
                                }
                            ],
                            operatorDefault: "><",
                            min: "2001/01/01 00:00",
                            max: "2099/12/31 23:59"
                        },
                        {
                            calendarTypeCode: "time",
                            calendarTypeName: "time",
                            timeZone: [
                                {
                                    timeZoneCode: "",
                                    timeZoneName: "<Timezone>"
                                },
                                {
                                    timeZoneCode: "utc",
                                    timeZoneName: "UTC"
                                },
                                {
                                    timeZoneCode: "local",
                                    timeZoneName: "Local"
                                }
                            ],
                            operators: [
                                {
                                    operatorCode: "><",
                                    operatorName: "is between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<>",
                                    operatorName: "is not between",
                                    weekday: true
                                },
                                {
                                    operatorCode: "<=",
                                    operatorName: "is less than",
                                    weekday: true
                                },
                                {
                                    operatorCode: "=>",
                                    operatorName: "is greater than",
                                    weekday: true
                                }
                            ],
                            operatorDefault: "><",
                            min: "00:00",
                            max: "23:59"
                        },
                        {
                            calendarTypeCode: "weekday",
                            calendarTypeName: "Weekday",
                            operators: [
                                {
                                    operatorCode: "=",
                                    operatorName: "is",
                                    weekday: true
                                },
                                {
                                    operatorCode: "!=",
                                    operatorName: "is not",
                                    weekday: true
                                }
                            ],
                            operatorDefault: "="
                        }
                    ]
                }
            ]
        }
    ]
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { PaymentTypeReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class PaymentTypeReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/PaymentTypeReference"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getPaymentTypeReference(): Observable<PaymentTypeReferenceModel[]> {
        return this.httpGet<PaymentTypeReferenceModel[]>(this.PATH, null)
        .pipe(
            map(res => res.body)
        )
    }
}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { InsightPassengerCommandModel, InsightPassengerSearchModel } from '../../models/individual-model';
import { InsightPassengerModel } from '../../models/individual-model/insight-passenger.model';

@Injectable({
    providedIn: 'root'
})
export class InsightPassengerService extends ApiServiceBase {
    private readonly PATH = '/individual/insight/insightPassenger';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
            super(httpClient, authService)
        }

    public searchInsightPassenger(model: InsightPassengerSearchModel): Observable<InsightPassengerSearchModel[]> {
        return this.httpPost<InsightPassengerSearchModel[]>(this.PATH + "/search/", model)
            .pipe(
                map(response => {
                return response.body;
                })
            );
    }

    public getInsightPassengerFilter(): Observable<InsightPassengerSearchModel[]> {
      return this.httpGet<InsightPassengerSearchModel[]>(this.PATH + '/search/filter', null)
          .pipe(
              map(res => res.body)
          )
    }

    public getInsightPassenger(individualId: string): Observable<InsightPassengerModel> {
      return this.httpGet<InsightPassengerModel>(this.PATH + "/" + individualId, null)
          .pipe(
              map(response => response.body)
          );
    }

    public editInsightPassenger(individualId: string, model: InsightPassengerCommandModel): Observable<InsightPassengerModel> {
      return this.httpPut<InsightPassengerModel>(this.PATH + "/" + individualId, model)
        .pipe(
          map(response => {
            return response.body;
          })
        );
    }
    
    public addInsightPassenger(model: InsightPassengerCommandModel): Observable<InsightPassengerModel> {
      return this.httpPost<InsightPassengerModel>(this.PATH + "/", model)
        .pipe(
          map(response => {
            return response.body;
          })
        );
    }
    
    public updatInsightPassengerStatus(individualId: string, statusCode: string) {
      return this.httpPut<InsightPassengerModel>(this.PATH + "/" + individualId + "/status"  + '?statusCode=' + statusCode, null)
        .pipe(
          map(response => {
            return response.body;
          })
        );
    }
}

import { Injectable } from "@angular/core";
import { VoucherAddCommandModel } from "src/app/core/models/product-model/voucher-model/voucher-add-command.model";
import { VoucherViewModel } from "src/app/core/models/product-model/voucher-model/voucher-view.model";
import { VoucherAttributeAndRuleComponent } from "../voucher-attribute-and-rule/voucher-attribute-and-rule.component";
import { VoucherEditCommandModel } from "src/app/core/models/product-model/voucher-model/voucher-edit-command.model";

@Injectable()
export class VoucherMapperService {
    private readonly VOUCHER_CODE = 'VOUCHER';
    
    public viewModelToAddCommand(view: VoucherViewModel, attributeAndRuleComponent: VoucherAttributeAndRuleComponent): VoucherAddCommandModel {
        return {
            productCategoryCode: this.VOUCHER_CODE,
            productGroupCode: view.productGroupCode,
            productTypeCode: view.productTypeCode,
            providerId: view.providerId,
            parentProductId: view.parentProductId,
            productName: view.productName,
            productDescription: view.productDescription,
            supplierId: view.supplierId,
            displayCode: view.displayCode,
            draftFlag: view.draftFlag,
            finalFlag: view.finalFlag,
            searchName: view.searchName,
            searchUsageTypeCode: view.searchUsageTypeCode,
            searchStatusCode: view.searchStatusCode,
            filterUserAccountId: view.filterUserAccountId,
            usageTypeCode: view.usageTypeCode,
            isOwnerFilter: view.isOwnerFilter,
            statusCode: view.statusCode,
            productHashTags: attributeAndRuleComponent?.getHashTagToCommand(),
            productNumbers: null,
            productRestrictionProducts: attributeAndRuleComponent?.getRestrictionProductsToCommand(),
            productRestrictionProductNumbers: attributeAndRuleComponent?.getRestrictionProductNumbersToCommand(),
            productRestrictionRoutes: attributeAndRuleComponent?.getRestrictionRoutesToCommand(),
            productRestrictionLocations: attributeAndRuleComponent?.getRestrictionLocationsToCommand(),
            productValidities: attributeAndRuleComponent?.getValidityToCommand(),
            productAttributes: attributeAndRuleComponent?.getAttributeToCommand(),
            productInventoryDimensions: null,
            productRestrictionOrganisations: attributeAndRuleComponent?.getRestrictionOrganisationsToCommand(),
            medias: null,
            languageTranslationSet: null,
            productPriceConditions: null
        }
    } 

    public viewModelToEditCommand(view: VoucherViewModel, attributeAndRuleComponent: VoucherAttributeAndRuleComponent): VoucherEditCommandModel {
        return {
            productId: view.productId,
            productCategoryCode: this.VOUCHER_CODE,
            productGroupCode: view.productGroupCode,
            productTypeCode: view.productTypeCode,
            providerId: view.providerId,
            parentProductId: view.parentProductId,
            productName: view.productName,
            productDescription: view.productDescription,
            supplierId: view.supplierId,
            displayCode: view.displayCode,
            draftFlag: view.draftFlag,
            finalFlag: view.finalFlag,
            searchName: view.searchName,
            searchUsageTypeCode: view.searchUsageTypeCode,
            searchStatusCode: view.searchStatusCode,
            filterUserAccountId: view.filterUserAccountId,
            usageTypeCode: view.usageTypeCode,
            isOwnerFilter: view.isOwnerFilter,
            statusCode: view.statusCode,
            productHashTags: attributeAndRuleComponent?.getHashTagToCommand(),
            productNumbers: null,
            productRestrictionProducts: attributeAndRuleComponent?.getRestrictionProductsToCommand(),
            productRestrictionProductNumbers: attributeAndRuleComponent?.getRestrictionProductNumbersToCommand(),
            productRestrictionRoutes: attributeAndRuleComponent?.getRestrictionRoutesToCommand(),
            productRestrictionLocations: attributeAndRuleComponent?.getRestrictionLocationsToCommand(),
            productValidities: attributeAndRuleComponent?.getValidityToCommand(),
            productAttributes: attributeAndRuleComponent?.getAttributeToCommand(),
            productInventoryDimensions: null,
            productInventories: null,
            productRestrictionOrganisations: attributeAndRuleComponent?.getRestrictionOrganisationsToCommand(),
            medias: null,
            languageTranslationSet: null,
            productPriceConditions: null
        }
    }
}
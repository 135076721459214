import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";
import { PRODUCT_DASHBOARD_FEES_SUMMARY_DEMO } from "./product-dashboard-fees-summary-demo-data";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-fees-summary.component.html',
    selector: 'op-product-dashboard-fees-summary'
})
export class ProductDashboardFeesSummaryComponent implements AfterViewInit {
    public summaryResultInfo: ProductDashboardSummaryModel[];

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    private loadData() {
        setTimeout(() => {
            this.summaryResultInfo = PRODUCT_DASHBOARD_FEES_SUMMARY_DEMO;
            this.changeDetection.detectChanges();
        }, 500);
    }
}
export const cashbookSearchDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    allowClear: true,
    startDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    }
}

export const select2SearchPaymentTypeCode = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchPaymentCurrency = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchPaymentStatus = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

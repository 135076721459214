import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { DxCheckBoxModule, DxDataGridModule } from "devextreme-angular";
import { TreeModule } from "primeng/tree";
import { ActivityDomainModule } from "src/app/core/components/activity-domain/activity-domain.module";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { MediaModule } from "src/app/core/components/media/media.module";
import { TextModule } from "src/app/core/components/text/text.module";
import { ProductAttributeAndRuleModule } from "../attribute-and-rule/attribute-and-rule.module";
import { InventoryAttributeComponent } from "../attribute-and-rule/inventory-attribute/inventory-attribute.component";
import { ProductGeneralComponent } from "./general/product-general.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { MerchandizeDetailComponent } from "./merchandize-detail.component";
import { TreeComponent } from "./tree/tree.component";

@NgModule({
    declarations: [
        InventoryAttributeComponent,
        InventoryComponent,
        MerchandizeDetailComponent,
        ProductGeneralComponent,
        TreeComponent,
    ],
    exports: [
        MerchandizeDetailComponent,
        ProductGeneralComponent,
    ],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        MatIconModule,
        MediaModule,
        NgbPaginationModule,
        NgbNavModule,
        PanelsModule,
        ProductAttributeAndRuleModule,
        ReactiveFormsModule,
        Select2Module,
        TextModule,
        TreeModule,
        UiModule
    ]
})
export class MerchandizeDetailModule { }

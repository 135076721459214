import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AncillaryServiceAttributeComponent } from './ancillary-service-attribute.component';
import { AttributeComponent } from './attribute/attribute.component';
import { HashtagComponent } from './hashtag/hashtag.component';
import { PointOfSalesComponent } from './point-of-sales/point-of-sales.component';
import { ProductNumberComponent } from './product-number/product-number.component';
import { RestrictionComponent } from './restriction/restriction.component';
import { SeasonComponent } from './season/season.component';
import { ValidityComponent } from './validity/validity.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { RouterModule } from '@angular/router';
import { RulesConfigModule } from 'src/app/core/components/rules-config/rules-config.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { InventoryRuleComponent } from './inventory/inventory-rule.component';

@NgModule({
  declarations: [
    AttributeComponent,
    HashtagComponent,
    PointOfSalesComponent,
    ProductNumberComponent,
    RestrictionComponent,
    SeasonComponent,
    AncillaryServiceAttributeComponent,
    ValidityComponent,
    InventoryRuleComponent
  ],
  imports: [
    BsDropdownModule,
    CommonModule,
    FocusingModule,
    FormsModule,
    LoadingSpinnerModule,
    MatIconModule,
    OopsChoiceValueModule,
    PanelsModule,
    ReactiveFormsModule,
    RouterModule,
    RulesConfigModule,
    Select2Module,
    UiModule,
  ],
  exports: [
    AncillaryServiceAttributeComponent
  ]
})
export class AncillaryServiceAttributeModule { }

export const SecurityCodeItems = [
    {
        name: 'Flight Operation',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00'),
        menuItems: [
            {
                name: 'Edit',
                url: ''
            },
            {
                name: 'Copy',
                url: ''
            },
            {
                name: 'Codes',
                url: ''
            },
            {
                name: 'User',
                url: ''
            },
            {
                name: 'Organisation',
                url: ''
            },
            {
                name: 'Report',
                url: ''
            }
        ]
    },
    {
        name: 'Order',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00'),
        menuItems: [
            {
                name: 'Edit',
                url: ''
            },
            {
                name: 'Copy',
                url: ''
            },
            {
                name: 'Codes',
                url: ''
            },
            {
                name: 'User',
                url: ''
            },
            {
                name: 'Organisation',
                url: ''
            },
            {
                name: 'Report',
                url: ''
            }
        ]
    },
    {
        name: 'Passenger',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00'),
        menuItems: [
            {
                name: 'Edit',
                url: ''
            },
            {
                name: 'Copy',
                url: ''
            },
            {
                name: 'Codes',
                url: ''
            },
            {
                name: 'User',
                url: ''
            },
            {
                name: 'Organisation',
                url: ''
            },
            {
                name: 'Report',
                url: ''
            }
        ]
    },
    
]
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { SchedulePeriodComponent } from "./schedule-period.component";
import { SchedulePeriodContentModule } from "./schedule-period-content/schedule-period-content.module";

@NgModule({
    declarations: [
        SchedulePeriodComponent,
    ],
    imports: [
        SchedulePeriodContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [
        SchedulePeriodComponent
    ]
})
export class SchedulePeriodModule {}
import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    ViewChild,
    AfterViewInit,
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: "op-vehicle-configuration-search-table",
    templateUrl: "./search-table.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTableComponent implements OnChanges, AfterViewInit {
    public readonly EXPORT_FILE_NAME = "VehicleConfigurationSearchResult";
    private readonly vehicleConfiguratioinTitle: string = "Vehicle Configuration Management";
    @Input() searchResults: VehicleConfigurationModel[];
    @Input() seatMap: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel
    @Output() onSelected = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onUpdateStatus = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter<any>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false })
    dataGrid: DxDataGridComponent;
    focusing = false;
    selectedItem: any;
    statusReferences: StatusReferenceModel[];
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    }
    constructor(
        private statusReferenceService: StatusReferenceService,
        private dataConverterService: DateConverterService,
        private navigationService: NavigationService
    ) {
        this.statusReferenceService.getAll().subscribe((response) => {
            this.statusReferences = response;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["searchResults"]) {
            this.selectedItem = null;
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    doubleClick(item) {
        this.onSelected.emit(item.key);
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
        this.onSelected.emit(this.selectedItem);
    }

    editItem() {
        if (this.selectedItem) {
            let no = this.getRowNoByModel(this.selectedItem);
            let paggingViews = this.createPaggingViews();
            let params = {
                id: this.selectedItem.vehicleConfigurationId,
                seatmap: this.seatMap,
                paggingDataView: paggingViews,
                currentPageIndex: no,
            }
            this.navigationService.navigate('main/settings/vehicle-configuration/details', null, null, params);
        }
    }

    deleteItem() {
        if (this.selectedItem && this.selectedItem.statusCode != "D") {
            this.onDelete.emit(this.selectedItem);
        }
    }

    copyItem() {
        if (this.selectedItem) {
            let params = {
                id: this.selectedItem.vehicleConfigurationId,
                copy: true
            }
            this.navigationService.navigate('main/settings/vehicle-configuration/details', null, null, params);
        }
    }

    getRowNo(item: any): number {
        return this.getRowNoByModel(item.data);
    }

    getRowNoByModel(model: VehicleConfigurationModel) {
        return this.searchResults?.indexOf(model) + 1;
    }

    refreshResult() {
        this.onRefresh.emit();
    }

    updateStatus(e) {
        this.selectedItem.statusCode = e;
        this.onUpdateStatus.emit(this.selectedItem);
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Code: data.vehicleConfigurationCode,
            Name: data.vehicleConfigurationName,
            CommitBy: data.commitByName,
            CommitDateTime: this.dataConverterService.convertDate(
                data.commitDateTime
            ),
            Status: this.getStatusName(data.statusCode),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences?.find((item) => item.statusCode == statusCode)
            ?.displayName;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    public new() {
        this.navigationService.navigate('main/settings/vehicle-configuration/details', this.vehicleConfiguratioinTitle);
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, this.getRowNoByModel(data));
            })
        });
    }


    private createPaggingViews() : PagingDataView[] {
        let paggingViews: PagingDataView[]  = new Array();
        if (!this.searchResults) {
            return null;
        }
        let i = 0;
        for (let model of this.searchResults) {
            ++i;
            paggingViews.push(this.creataPaggingView(i, model));
        }
        return paggingViews;
    }

    private creataPaggingView(index: number, model: VehicleConfigurationModel) : PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = model.vehicleConfigurationId;
        pview.name = model.vehicleConfigurationName;
        return pview;
    }
}

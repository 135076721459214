import { Injectable } from "@angular/core";
import { VoucherAttributeView } from "./voucher-attribute.view";
import { ProductAttributeCommandModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { cloneDeep } from "lodash";
import { OopsChoiceValueConstant } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable()
export class VoucherAttributeMapperService {
    constructor(private dateConverterService: DateConverterService) { }
    
    public voucherAttributesToAttributeCommands(voucherAttributes: VoucherAttributeView[]): ProductAttributeCommandModel[] {
        let commandModels = new Array<ProductAttributeCommandModel>();
        let index = 1;
        if (voucherAttributes) {
            for (let attribute of voucherAttributes) {
                let commandModel = {
                    attributeTypeCode: attribute.attributeCode as string,
                    displaySequence: index
                } as ProductAttributeCommandModel;
                if (attribute.id) {
                    commandModel.productAttributeId = attribute.id;
                }
                this.getAttributeCommandValue(attribute, commandModel, commandModels);
                index++;
            }
        }
        return commandModels;
    }

    private getAttributeCommandValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        switch (voucherAttribute.dimensionUnitCode) {
            case OopsChoiceValueConstant.CHOICE:
                this.getAttributeChoiceValue(voucherAttribute, commandModel, commandList);
                break;
            case OopsChoiceValueConstant.TEXT:
                this.getAttributeTextValue(voucherAttribute, commandModel, commandList);
                break;
            case OopsChoiceValueConstant.NUMBER:
            case OopsChoiceValueConstant.PERCENTAGE:
                this.getAttributeNumberValue(voucherAttribute, commandModel, commandList);
                break;
            case OopsChoiceValueConstant.DATETIME:
                this.getAttributeDateTimeValue(voucherAttribute, commandModel, commandList);
                break;
            case OopsChoiceValueConstant.TIME:
                this.getAttributeTimeValue(voucherAttribute, commandModel, commandList);
                break;
        }
    }

    private getAttributeChoiceValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        if (voucherAttribute.multipleChoiceFlag === true) {
            this.getMultipleChoiceValue(voucherAttribute, commandModel, commandList);
            return;
        }
        this.getSingleChoiceValue(voucherAttribute, commandModel, commandList);
    }

    private getMultipleChoiceValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        if (!voucherAttribute || !voucherAttribute?.attributeValue) {
            return;
        }
        for (let choice of voucherAttribute?.attributeValue) {
            let multipleChoiceAttribute = cloneDeep(commandModel) as ProductAttributeCommandModel;
            let choiceName = voucherAttribute.attributeChoices?.find(item => item.id == choice)?.text;
            multipleChoiceAttribute.attributeChoiceId = choice;
            multipleChoiceAttribute.attributeChoiceName = choiceName;
            commandList.push(multipleChoiceAttribute);
        }
    }

    private getSingleChoiceValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        commandModel.attributeChoiceId = voucherAttribute.attributeValue as string;
        let choiceName = voucherAttribute.attributeChoices?.find(item => item.id == commandModel.attributeChoiceId)?.text;
        commandModel.attributeChoiceName = choiceName;
        commandList.push(commandModel);
    }

    private getAttributeTextValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        commandModel.attributeText = voucherAttribute.attributeValue as string;
        commandList.push(commandModel);
    }

    private getAttributeNumberValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        commandModel.attributeValue = parseFloat(voucherAttribute.attributeValue as string);
        commandList.push(commandModel);
    }

    private getAttributeDateTimeValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        commandModel.attributeDateTime = new Date(voucherAttribute.attributeValue as string);
        commandList.push(commandModel);
    }

    private getAttributeTimeValue(voucherAttribute: VoucherAttributeView, commandModel: ProductAttributeCommandModel, commandList: ProductAttributeCommandModel[]) {
        commandModel.attributeDateTime = this.dateConverterService.convertMinDateWithTime(voucherAttribute.attributeValue as string);
        commandList.push(commandModel);
    }
}
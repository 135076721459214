import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { CalendarValidityReferenceModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarValidityService extends ApiServiceBase {
    private readonly PATH = "/system/CalendarValidities"

    constructor(httpClient: HttpClient,
        authService: AuthService,
        private stringHelperService: StringHelperService) {
        super(httpClient, authService);
    }

    public getByCalendarValidityCodes(...calendarValidityCodes: string[]): Observable<CalendarValidityReferenceModel[]> {
        let calendarValidityCodesParam = this.stringHelperService.createQueryParamFromArray('calendarValidityCodes', calendarValidityCodes);
        return this.httpGet<CalendarValidityReferenceModel[]>(this.PATH + calendarValidityCodesParam, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByCalendarValidityCode(calendarValidityCode): Observable<CalendarValidityReferenceModel> {

        return this.httpGet<CalendarValidityReferenceModel>(this.PATH + "/" + calendarValidityCode, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
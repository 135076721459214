import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { _runtimeChecksFactory } from '@ngrx/store/src/runtime_checks';

@Component({
    template: ''
})
export abstract class OopsComponentFormBase implements OnInit {

    private _formGroup: UntypedFormGroup;
    private _processing: boolean

    constructor(private _formBuilder: UntypedFormBuilder) {
        this._processing = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    public abstract initForm();

    public get processing() {
        return this._processing;
    }

    public get formGroup(): UntypedFormGroup {
        return this._formGroup;
    }

    public set formGroup(value: UntypedFormGroup) {
        this._formGroup = value;
    }

    public setformGroupValue(value: any) {
        this._formGroup = this._formBuilder.group(value);
    }

    public validControl(name: string): boolean {
        return this._formGroup.controls[name].invalid &&
            (this._formGroup.controls[name].dirty || this._formGroup.controls[name].touched)
    }

    public validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public validForm(): boolean {
        return this._formGroup.valid;
    }

    public startProcessing(): void {
        this._processing = true;
    }

    public completeProcessing(): void {
        this._processing = false;
    }
}
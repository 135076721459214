import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { OrderProductModel } from "src/app/core/models/order-model";
import { OrderDetailServices } from "src/app/core/services/order-services";
import { OrderDetailProductListComponent } from "src/app/modules/order/shared/components/order-detail-product-list";

@Component({
    selector: 'op-insight-sales-summary-result-product-list',
    templateUrl: './sales-summary-result-product-list.component.html',
    providers: [
        
    ]
})
export class InsightSalesSummaryResultProductListComponent implements OnInit, AfterViewInit {
    public products: OrderProductModel[];
    
    @Input() rootOrderId: string;
    @Input() patnerOrderId: string;
    @Input() rootOrderIds: string[];
    
    @ViewChild("orderDetailProductList") productListComponent: OrderDetailProductListComponent;
    
    constructor(private orderDetailService: OrderDetailServices) {

    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.orderDetailService.getIndividualOrderDetailProduct(this.rootOrderId, this.patnerOrderId)
        .subscribe(
            (responses: OrderProductModel[]) => {
                this.products = responses.filter(x => x.productCategoryCode == 'TRANSPORT');
                this.productListComponent.bindProductData(this.products)
            }
        );   
    }
}
import { Injectable } from "@angular/core";
import { PriceModel } from "../../../shared/models";
import { PriceAmountView, PriceConditionView, PriceIndividualView, PriceView } from "../../../shared/views";
import { TreeListView } from "./views";

@Injectable()
export class AmountConverter {
    public toTreeListView(view: PriceView,
        parentPriceId: string,
        unitCode: string):  TreeListView[] {
        if (!view || view.draftFlag == true) {
            return null;
        }
        if (view.unitCode) {
            unitCode = view.unitCode;
        }
        let treeListViews = new Array<TreeListView>();
        let treeListView = {} as TreeListView;
        treeListView.Id = view.priceId;
        treeListView.parentId = parentPriceId;
        treeListView.priceName = view.priceName;
        
        treeListViews.push(treeListView);
        if (view?.children?.length) {
            for (let child of view?.children) {
                treeListViews = treeListViews.concat(this.toTreeListView(
                    child,
                    view.priceId,
                    unitCode));
            }
        } else {
            for (let condition of view.conditions) {
                treeListViews.push(this.toConditionTreeListView(condition, view.priceId, unitCode));
                for (let individual of condition.individuals) {
                    treeListViews.push(this.toAmountTreeListView(individual, view.priceId, unitCode));
                }
            }
        }
        return treeListViews;
    }

    private toConditionTreeListView(condition: PriceConditionView, priceId: string, unitCode: string){ 
        let conditionTreeListView = {} as TreeListView;
        conditionTreeListView.Id = condition.priceConditionId;
        conditionTreeListView.condition = condition.conditionCode;
        conditionTreeListView.numberOfUnits = condition.numberOfUnits;
        conditionTreeListView.parentId = priceId;
        conditionTreeListView.unitType = unitCode;
        conditionTreeListView.referencePriceConditionId = condition.referencePriceConditionId;
        return conditionTreeListView
    }

    private toAmountTreeListView(individual: PriceIndividualView, priceId: string, unitCode: string): TreeListView {
        let amount = individual.amount;
        let amountTreeListView = {} as TreeListView;
        amountTreeListView.Id = amount.priceAmountId;
        amountTreeListView.parentId = priceId;
        amountTreeListView.priceIndividualId = individual.priceIndividualId;
        amountTreeListView.referencePriceIndividualId = individual.referencePriceIndividualId;
        amountTreeListView.unitType = unitCode;
        amountTreeListView.currency = amount.currencyCode;
        amountTreeListView.individualAgeGroup = individual.individualAgeGroupCode;
        amountTreeListView.socialType = individual.individualSocialTypeCode;
        amountTreeListView.arithmeticOperator = individual.arithmeticOperator;
        amountTreeListView.unitReference = individual.inRelationToIndividualAgeGroupCode;
        amountTreeListView.amount = amount.priceAmount;
        amountTreeListView.percentage = amount.pricePercentage;
        amountTreeListView.min = amount.minimumPriceAmount;
        amountTreeListView.max = amount.maximumPriceAmount;
        amountTreeListView.cost = amount.costAmount;
        return amountTreeListView;
    }

    public fillAmountData(model: PriceModel, treeListData: TreeListView[]) {
        if (!model) {
            return;
        }
        if (model.children?.length) {
            for (let child of model.children) {
                this.fillAmountData(child, treeListData);
            }
        } else {
            for (let condition of model.conditions) {
                for (let individual of condition.individuals) {
                    let data = treeListData?.find(d => d.Id == individual.amount.priceAmountId);
                    this.setAmount(individual.amount, data);
                }
            }
        }
    }
    
    private setAmount(amount: PriceAmountView, data: TreeListView) {
        if (!data) {
            return;
        }
        amount.priceAmount = this.parseNumber(data.amount);
        amount.pricePercentage = this.parseNumber(data.percentage);
        amount.minimumPriceAmount = this.parseNumber(data.min);
        amount.maximumPriceAmount = this.parseNumber(data.max);
        amount.costAmount = this.parseNumber(data.cost);
        amount.currencyCode = data.currency;
    }

    private parseNumber(data: any): number {
        if (!data && data !== 0) {
            return null;
        }
        return Number(data);
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { ConditionReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { IndividualAgeGroupTypeReferenceService } from "src/app/core/services/airline-services";
import { ConditionReferenceService, UnitReferenceService } from "src/app/core/services/system-services";
import { IndividualSocialTypeReferenceService } from "src/app/core/services/system-services/individual-social-type-reference.service";
import { ActionService } from "src/app/core/utils/action.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PriceConditionModel, PriceModel } from "../../prices/shared/models";
import { PriceService } from "../../prices/shared/price.service";
import { PricingDetailConstant } from "../../rules/price-rule-detail/shared/pricing-detail.constant";
import { ConditionComponent } from "./condition/condition.component";
import { DimensionsComponent } from "./dimensions/dimensions.component";
import { GeneralComponent } from "./general/general.component";

@Component({
    selector: 'op-price-conditions-details',
    templateUrl: './details.component.html',
    providers: [
        ActionbarService
    ]
})
export class DetailsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    readonly generalTabId = 'GENERAL';
    readonly attributesTabId = 'ATTRIBUTE';
    readonly translationTabId = 'TRANSLATION';
    readonly historyTabId = 'HISTORY';
    readonly readModeTabId = 'READ_MODE';

    isSaving = false;
    price: PriceModel;
    unitCode: string;
    selectedTab = this.generalTabId;
    previousPage: string;

    individualAgeGroupTypeReferences$ = this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences();
    conditionReferences$ = this.conditionReferenceService.getConditionsByCodes([
        PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
        PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
        PricingDetailConstant.TIER_CODE
    ]).pipe(
        startWith(this.getPreloadConditionReferences())
    )
    individualSocialTypeReferences$ = this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences();
    unitReferences$ = this.unitReferenceService.getUnitReferences();
    userSecurity: SecurityGroupSecurityModel;

    @Input() priceId: string;
    @Output() onCancel = new EventEmitter();
    @Output() onLoadCompleted = new EventEmitter();
    
    @ViewChild(GeneralComponent) generalComponent: GeneralComponent;
    @ViewChild(DimensionsComponent) dimensionsComponent: DimensionsComponent;
    @ViewChild(ConditionComponent) conditionComponent: ConditionComponent;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    defaultActionbarHandler: ActionBarHandlerModel;

    unsubscribe$ = new Subject();
    
    public pagingDataViews: PagingDataView[] = [];

    get currentPage(): number {
        if (!this.priceId) {
            return -1;
        }
        if (!this.pagingDataViews?.length) {
            return -1;
        }
        const index = this.pagingDataViews.map(p => p.id).indexOf(this.priceId);
        if (index < 0) {
            return -1;
        }
        return index + 1;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private unitReferenceService: UnitReferenceService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private priceService: PriceService,
        private actionbarService: ActionbarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private focusingService: FocusingService,
        private navigationService: NavigationService,
        private actionService: ActionService) { }

    private getPreloadConditionReferences() {
        let is = new ConditionReferenceModel();
        is.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
        is.conditionName = 'is, are';

        let preLoadConditionReferences = new Array<ConditionReferenceModel>();
        preLoadConditionReferences.push(is);

        return preLoadConditionReferences;
    }

    private onNewPrice() {
        this.priceId = null;
        this.loadPrice(null);
    }

    ngOnInit(): void {
        const params = this.navigationService.getParams();
        this.priceId = params?.priceId ?? params?.id;
        this.previousPage = params?.previousPage;
        this.pagingDataViews = params?.pagingDataViews ?? [];
        this.userSecurity = params?.userSecurity;
        this.addAction();
        this.loadPrice(this.priceId);
    }

    ngOnChanges() {
        this.loadPrice(this.priceId);
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity()
        this.actionbarService.updateState(this.defaultActionbarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch(actionId) {
                    case ACTION_STATUS.cancel:
                    case ACTION_STATUS.back:
                        this.goback();
                        break;
                    case ACTION_STATUS.refresh:
                        this.loadPrice(this.priceId);
                        break;
                    case ACTION_STATUS.save:
                        this.save();
                        break;
                    case ACTION_STATUS.new:
                        this.onNewPrice();
                        break;
                    default:
                        break;
                }
            }
        )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private setupActionbarSecurity() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.enable(this.saveFlag);
        this.defaultActionbarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            new CancelButtonModel(),
            new RefreshButtonModel()
        );
    }

    get saveFlag(): boolean {
        return (!this.priceId && this.userSecurity?.newFlag == true) || (this.priceId && this.userSecurity?.editFlag == true);
    }

    private loadPrice(priceId: string) {
        if (this.priceId) {
            this.loadingSpinnerService.show();
            this.priceService.getById(priceId)
            .subscribe(
                (response: PriceModel) => {
                    this.price = response;
                    this.onLoadCompleted.emit();
                    this.addAction();
                    this.loadingSpinnerService.hide();
                        this.cdr.detectChanges();
                    }
                )
        } else {
            this.price = new PriceModel();
        }
    }

    cancel() {
        this.priceId = null;
        this.generalComponent.resetForm();
        this.dimensionsComponent.resetForm();
        this.conditionComponent.resetForm();
        this.onCancel.emit();
    }

    save() {
        this.isSaving = true;

        let price = new PriceModel();
        price = this.generalComponent.getValues(price);
        if (!price) {
            this.isSaving = false;
            return;
        }

        price = this.dimensionsComponent.getValues(price);
        price = this.conditionComponent.getValues(price);

        if (!price) {
            this.isSaving = false;
            return;
        }

        this.loadingSpinnerService.showSaving();
        let observableResponse = price.priceId ? 
            this.priceService.saveTemplate(price) : 
            this.priceService.addTemplate(price);
        observableResponse.subscribe(
            (response: PriceModel) => {
                this.priceId = response.priceId;
                this.price = response;
                this.isSaving = false;
                this.addAction();
                this.loadingSpinnerService.saveComplete();
            },
            () => {
                this.isSaving = false;
                this.loadingSpinnerService.hide();
            },
            () => {
                this.cdr.detectChanges();
            }
        )
    }

    initializeCopyMode() {
        this.resetPriceAndChildrenIds();
    }

    private resetPriceAndChildrenIds() {
        let price = Object.assign({}, this.price);
        price.priceId = null;
        price.organisationId = null;
        price.commitBy = null;
        price.commitDateTime = null;

        this.resetPriceConditionIds(price);

        this.price = price;
    }

    private resetPriceConditionIds(price: PriceModel) {
        if (price.conditions?.length) {
            for (let condition of price.conditions) {
                condition.priceConditionId = null;
                this.resetPriceIndividualIds(condition);
            }
        }
    }

    private resetPriceIndividualIds(condition: PriceConditionModel) {
        if (condition.individuals?.length) {
            for (let individual of condition.individuals) {
                individual.priceIndividualId = null;
            }
        }
    }

    onUnitCodeChange(unitCode: string) {
        this.unitCode = unitCode;
    }

    onUnitCodeMissing() {
        this.dimensionsComponent.triggerAttributesError();
    }

    onActiveIdChange(selectedTabId: string) {
        switch (selectedTabId) {
            case this.generalTabId:
                this.focusingService.focus(this.generalComponent.focusingDirective);
                break;
            case this.attributesTabId:
                this.focusingService.focus(this.dimensionsComponent.focusingDirective);
                break;
            default:
                break;
        }
    }

    onPriceConditionRefresh() {
        this.dimensionsComponent.refresh();
    }

    private goback() {
        this.navigationService.navigate('/main/pricing/home', null, null, {
            previousPage: this.previousPage,
            userSecurity: this.userSecurity
        });
    }

    onDimensionsFocus() {
        this.selectedTab = this.attributesTabId;
    }
    
    onGeneralFocus() {
        this.selectedTab = this.generalTabId;
    }

    private addAction() {
        if (this.priceId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.priceId, this.price?.priceName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onPageChange(priceId: string) {
        if (!this.priceId) {
            return;
        }
        if (this.priceId == priceId) {
            return;
        }
        this.priceId = priceId;
        this.loadPrice(this.priceId);
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { VehicleCompositionSearchCommandModel, VehicleCompositionModel, VehicleCompositionCommandModel } from '../../models/vehicle-model/vehicle-composition';

@Injectable({
    providedIn: 'root'
})
export class VehicleCompositionService extends ApiServiceBase {
    private readonly PATH = "/vehicle/vehiclecomposition"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public search(command: VehicleCompositionSearchCommandModel): Observable<VehicleCompositionModel[]> {
        return this.httpPost<VehicleCompositionModel[]>(this.PATH + '/search', command)
            .pipe(
                map(res => res.body)
            )
    }

    public add(command: VehicleCompositionCommandModel): Observable<VehicleCompositionModel> {
        return this.httpPost<VehicleCompositionModel>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public edit(command: VehicleCompositionCommandModel): Observable<VehicleCompositionModel> {
        return this.httpPut<VehicleCompositionModel>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }

    public get(id: string): Observable<VehicleCompositionModel> {
        return this.httpGet<VehicleCompositionModel>(this.PATH + '/' + id, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByCode(code: string): Observable<VehicleCompositionModel[]> {
        return this.httpGet<VehicleCompositionModel[]>(this.PATH + '/code/' + code, null)
            .pipe(
                map(res => res.body)
            )
    }

    public updateStatus(vehicleCompositionId: string, statusCode: string) {
        return this.httpPut(this.PATH + "/updatestatus/" + vehicleCompositionId + "/" + statusCode, null);
    }


}
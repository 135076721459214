import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input, SimpleChanges, OnChanges, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { Helper } from 'src/app/shared/helper/app.helper';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import { UIService } from 'src/app/shared/ui/uiservice/ui.service';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { TransportSearchTableComponent } from './transport-search-table/transport-search-table.component';
import { TransportSearchConditionComponent } from './transport-search-condition/transport-search-condition.component';

import { MapperService } from './shared/mapper.service';

import {
    ConditionReferenceService,
    CountryReferenceService,
    ProductCategoryReferenceService,
    ProductGroupReferenceService,
    ProductNumberTypeReferenceService,
    StatusReferenceService,
    UsageTypeReferenceService,
    VehicleTypeReferenceService,
    OrganisationTypeReferenceService,
    DateTimeDimensionReferenceService,
    CalendarValidityService
} from 'src/app/core/services/system-services';

import {
    ProductTypeGroupService
} from 'src/app/core/services/reference-service/reference-product-service';

import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';

import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';

import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';
import { ProductSchedulePeriodService, ProductSearchFilterService, TransportService } from 'src/app/core/services/product-services';
import { DomainAttributeService, HashTagReferenceService, LanguageReferenceService, OrganisationGroupReferenceService, RegionReferenceService, VehicleCompositionService, VehicleService } from 'src/app/core/services/airline-services';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';

import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel,
    LocationGroupModel, OrganisationGroupReferenceModel,
    OrganisationRoleReferenceModel,
    OrganisationTypeReferenceModel, RegionReferenceModel, StatusReferenceModel,
    UsageTypeReferenceModel, VehicleCompositionModel, VehicleModel, VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { ProductSchedulePeriodModel } from 'src/app/core/models/product-model/product-schedule-period-model';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';

import { TransportSearchTableView } from './shared/transport-search-table.view';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { ServiceTimeViewModel, TransportViewModel } from 'src/app/core/models/product-model/transport-model';
import { TransportSearchConditionView } from "./shared/transport-search-condition.view";
import { TransportSearchCommandModel } from 'src/app/core/models/product-model/transport-model/transport-search-command.model';
import { TransportSearchViewModel } from 'src/app/core/models/product-model/transport-model/transport-search-view.model';
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { ActionBarHandlerModel, ActionbarService, ACTION_HANDLER_STATUS, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { LoadingNotifier, LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { OriginDestinationMapperService } from '../../../shared/mapper/origin-destination-mapper.service';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { AttributeMapperService } from '../../../shared/mapper/attribute-mapper.service';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import { MerchandizeModeHelperService } from '../../../shared/merchandize-mode-helper.service';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { AttributeAndRuleComponent } from '../shared/attribute-and-rule/attribute-and-rule.component';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { TransportSearchFilterNameComponent } from './transport-filter-name/transport-filter-name.component';
import { ProductHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-hierarchy';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { HttpClient } from '@angular/common/http';
import { PointOfSalesModel, ValidityModel } from 'src/app/core/models/merchandizing-config';
import { PointOfSalesViewMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-view-mapper.service';

declare var $: any;

@Component({
    selector: 'op-transport-search',
    templateUrl: './transport-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UIService, MapperService, MediaMapperService, MerchandizeModeHelperService,
        Select2DataMapperService, HashTagMapperService, NumberMapperService, OriginDestinationMapperService,
        RestrictionMapperService, AttributeMapperService, PointOfSalesMapperService, ValidityMapperService, PointOfSalesViewMapperService]
})
export class TransportSearchComponent implements OnInit, OnChanges, OnDestroy {

    readonly TRANSPORT_PRODUCT_CATE_CODE = 'TRANSPORT';
    readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    readonly ACTIVE_STATUS = "A";
    readonly FILTER_USAGE_TYPE = 'FILTER';
    readonly DATA_USAGE_TYPE = 'DATA';
    readonly TEMPLATE_USAGE_TYPE = 'TEMPLATE';
    readonly ALERT_HEADER = 'Transport Search';
    readonly SEARCH_FILTER_SAVE_SUCCESS = "Search Filter save successfully.";
    readonly SEARCH_FILTER_DELETE_SUCCESS = "Search Filter delete successfully.";
    readonly DELETE_SUCCESS = "Transport delete successfully.";
    readonly FAILED: string = "Failed save filter.";
    readonly FAILED_GET: string = "Failed get filter.";
    readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    readonly ATTRBUTE_GROUP_CODE_ORG_DESC: string = "ORIGINDESTINATION";
    readonly OPERATINGCODE: string = "OPERATING";
    readonly MARKETINGCODE: string = "MARKETING";
    readonly FILTERNAME_PANEL = 'Filter Name';
    readonly SEARCHCONDITION_PANEL = 'Search';
    readonly SPINNER_NAME: string = "transportSearchSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;
    readonly FOCUS_DELAY_TIME = 200;
    readonly ORGTYPE_OWNER = 'OWNER';

    @Input() productGroup: string;
    @Input() isCategoriesPage: boolean = false;
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() module: ProductCategory;

    @ViewChild(TransportSearchTableComponent) searchTableComponent: TransportSearchTableComponent;
    @ViewChild(TransportSearchConditionComponent) productSearchConditionComponent: TransportSearchConditionComponent;
    @ViewChild(AttributeAndRuleComponent) attributeAndRuleComponent: AttributeAndRuleComponent;
    @ViewChild(TransportSearchFilterNameComponent) filterNameComponent: TransportSearchFilterNameComponent;
    @ViewChild('productSearchConditionRef') productSearchConditionRef: ElementRef;
    @ViewChild('productSearchTableRef') productSearchTableRef: ElementRef;

    public currentActionBarMode = ACTION_HANDLER_STATUS.none;
    private selectedItem: any;
    advanceSearchModeEnabled = false;
    @Output() showAdvanceSearchChanged = new EventEmitter<boolean>(this.advanceSearchModeEnabled);
    @Output() cancelRequest = new EventEmitter();

    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public productNumberTypeReferenceSearch$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productCategoryReferenceTransport: ProductCategoryReferenceModel[];
    private productGroupReferenceTransport: ProductGroupReferenceModel[] = new Array();
    public productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productGroupReferenceTransport$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public productSchedulePeriod$ = new BehaviorSubject<ProductSchedulePeriodModel[]>(null);
    public vehicleTypeReference$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public vehicle$ = new BehaviorSubject<VehicleModel[]>(null);
    private hashTagReference: HashTagReferenceModel[];
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeOrgDescSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    private usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public usageTypeReferenceFilter$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    private productHashtagValueViews: ProductHashTagView[] = new Array();
    public paggingView: PagingDataView[];
    public loadingNotifier = new LoadingNotifier();
    doubleClickSubject$ = new Subject();
    transportSearchConditionView$ = new BehaviorSubject<TransportSearchConditionView>(this.getDefaultProductSearchCondition());
    productSearchFilters$ = this.getProductSearchFilter();
    productSearchResults$ = new BehaviorSubject<TransportSearchTableView[]>(null);
    searchCommand: TransportSearchCommandModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;
    unsubscribe$ = new Subject();
    private searchCriteria: TransportViewModel;
    public completeLoadReference: boolean = false;
    private _jsonURL = 'assets/data/flight/attributes&rules_config.json';
    public validityConfig: ValidityModel;
    public pointOfSalesConfig: PointOfSalesModel;

    constructor(public helper: Helper,
        private changeDetectionRef: ChangeDetectorRef,
        private uiService: UIService,
        private focusingService: FocusingService,
        private store: Store<any>,
        private mapperService: MapperService,
        private alertService: ToastrService,
        private select2DataMapperService: Select2DataMapperService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private organisationService: OrganisationService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private productSchedulePeriodService: ProductSchedulePeriodService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private vehicleCompositionService: VehicleCompositionService,
        private vehicleService: VehicleService,
        private hashTagReferenceService: HashTagReferenceService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private regionReferenceService: RegionReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private domainAttributeService: DomainAttributeService,
        private productSearchFilterService: ProductSearchFilterService,
        private transportService: TransportService,
        private hashTagMapperService: HashTagMapperService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private navigationService: NavigationService,
        public alertBarService: AlertBarService,
        private merchandizeModeHelperService: MerchandizeModeHelperService,
        private attributeMapperService: AttributeMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private validityMapperService: ValidityMapperService,
        private http: HttpClient
    ) {
        this.getParams();
    }

    ngOnInit(): void {
        this.getReferencesData().then(() => {
            this.updateLoadReferenceCompleted().then(() => {
                this.searchDefault();
            })
        });
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.actionbarService.updateState(this.defaultActionBarHandler);
        this.subscribeToActionBar();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["productGroup"]) {
            this.setDefaultProductGroup();
        }
    }

    setDefaultActionBarHandler() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    private setDefaultProductGroup() {
        if (this.productGroup) {
            this.transportSearchConditionView$.next(this.getDefaultProductSearchCondition());
            if (this.executeFlag == true) {
                this.search(<TransportSearchCommandModel>{ searchStatusCode: 'A', productGroupCode: this.productGroup, searchUsageTypeCode: this.DATA_USAGE_TYPE });
            }
        }
    }

    onSelectedProduct(data) {
        this.selectedItem = data;
        if (data) {
            if (data.usageTypeCode == this.FILTER_USAGE_TYPE) {
                if (data.statusCode == 'A') {
                    this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.selected);
                }
            }
        } else {
            this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editingNew : ACTION_HANDLER_STATUS.none);
        }
    }

    onEditProduct(data) {
        if (data.usageTypeCode == this.FILTER_USAGE_TYPE) {
            if (data.statusCode == 'A') {
                this.onProductSearchFilterIdChange(data.id);
                this.onAdvanceSearchChanged(true);
                this.focusOnSearchCondition();
                this.setActionBarMode(ACTION_HANDLER_STATUS.editing);
            }
        } else {
            this.gotoEditPage(data.id, false, data.pagingNo);
        }
    }

    private getProductFlag(response: DomainAttributeModel[]): DomainAttributeModel[] {
        if (response) {
            return response.filter(x => x.productFlag == true && x.scheduleProductFlag == true)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));;
        }
    }

    private getAtttributeOrgDesc(response: DomainAttributeModel[]) {
        if (response) {
            return response.filter(x => (x.startFlag == true || x.endFlag == true) && x.scheduleProductFlag == true)
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        }
    }

    onSearch(condition: TransportSearchConditionView) {
        if (!condition) {
            return;
        }
        this.searchCommand = this.mapperService.convertToSearchCommand(condition, this.attributeAndRuleComponent, this.productHashtagValueViews, this.provider$);
        this.search(this.searchCommand);
        this.focusOnSearchResult();
    }

    private search(searchCommand: TransportSearchCommandModel) {
        if (!this.searchCommand) {
            this.searchCommand = searchCommand
        }
        this.loadingNotifier.show(this.SPINNER_NAME);
        this.transportService.search(searchCommand)
            .subscribe(
                (responses: TransportSearchViewModel[]) => {
                    this.paggingView = new Array();
                    let searchResults = this.mapperService.TransportSearchTableViews(responses, this.paggingView);
                    this.productSearchResults$.next(searchResults);
                    this.getSearchCriteriaParams();
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                },
                () => {
                    this.loadingNotifier.hide(this.SPINNER_NAME);
                }
            );
    }

    focusOnSearchCondition() {
        setTimeout(() => {
            this.uiService.scrollToPanel(this.store, this.productSearchConditionRef);
            this.focusingService.focus(this.productSearchConditionComponent.focusingDirective);
        }, this.FOCUS_DELAY_TIME);
    }

    focusOnSearchResult() {
        setTimeout(() => {
            this.uiService.scrollToPanel(this.store, this.productSearchTableRef);
            this.focusingService.focus(this.searchTableComponent.focusingDirective);
        }, this.FOCUS_DELAY_TIME);
    }

    onSave(product: TransportSearchConditionView) {
        let condition = this.productSearchConditionComponent.getValues(true);
        if (!product || !condition?.productGroupCode) {
            return;
        }

        condition.productId = product.productId;
        condition.productName = product.productName;
        condition.isOwnerFilter = product.isOwnerFilter;
        condition.usageTypeCode = this.FILTER_USAGE_TYPE;
        if (!product.productId) {
            this.addNewFilter(condition);
        }
        else {
            this.updateFilter(condition);
        }
    }

    onCopyItem(data) {
        let usageTypeCode = data.usageTypeCode;
        if (usageTypeCode == this.FILTER_USAGE_TYPE) {
            this.getFilter(data.id ?? data.productId, true);
            this.focusOnSearchCondition();
        } else {
            this.gotoEditPage(data.id, true, null);
        }
    }

    private addNewFilter(condition: TransportSearchConditionView) {
        let command = this.mapperService.convertToAddCommand(condition, this.attributeAndRuleComponent, this.productHashtagValueViews, this.provider$);
        if (!command) {
            return;
        }
        this.spinnerService.showSaving();
        this.transportService.addProduct(command)
            .subscribe(
                (response) => {
                    this.spinnerService.saveComplete();
                    this.getFilter(response.body);
                    this.productSearchFilters$ = this.getProductSearchFilter();
                    this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.editingNew);
                    this.changeDetectionRef.detectChanges();
                },
                error => {
                    this.spinnerService.hide();
                }
            )
    }

    private updateFilter(condition: TransportSearchConditionView) {
        let command = this.mapperService.convertToEditCommand(condition, this.attributeAndRuleComponent, this.productHashtagValueViews, this.provider$);
        if (!command) {
            return;
        }
        this.spinnerService.showSaving();
        this.transportService.editProduct(command)
            .subscribe(
                (response) => {
                    this.spinnerService.saveComplete();
                    this.getFilter(response.body);
                    this.productSearchFilters$ = this.getProductSearchFilter();
                    this.changeDetectionRef.detectChanges();
                },
                error => {
                    this.spinnerService.hide();
                }
            )
    }

    deleteSearchFilter(searchFilterId: string) {
        if (!searchFilterId) {
            return;
        }
        this.transportService.delete(searchFilterId)
            .subscribe(
                () => {
                    this.alertService.success(this.DELETE_SUCCESS, this.ALERT_HEADER, { timeOut: 2000 });
                    this.clearSearchCondition();
                    this.productSearchConditionComponent.getValues(false);
                },
                error => {
                    this.alertService.error(this.FAILED, this.ALERT_HEADER, { timeOut: 2000 });
                }
            )
    }

    onClear() {
        this.clearSearchCondition();
        this.clearSearchResult();
        this.filterNameComponent?.clearForm();
    }

    onClearCondition() {
        this.clearSearchCondition();
        this.advanceSearchModeEnabled = false;
        this.searchCommand = this.mapperService.convertToSearchCommand(this.getDefaultProductSearchCondition(),
            this.attributeAndRuleComponent, this.productHashtagValueViews, this.provider$);
        this.search(this.searchCommand);
    }

    onNewFilter() {
        this.clearSearchCondition();
        this.productSearchConditionComponent.getValues(false);
    }

    private clearSearchCondition() {
        this.clearFormProcessing();
        this.transportSearchConditionView$.next(this.getDefaultProductSearchCondition());
        this.productSearchFilters$ = this.getProductSearchFilter();
        this.productSearchConditionComponent.productSearchFilterIdData = null;
        this.productSearchConditionComponent.updateDepartureArrival(null, null);
        this.setProductHashTagValue(null);
        this.mapperService.clearAttributeAndRule(this.attributeAndRuleComponent);
        this.selectedItem = null;
        this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editingNew : ACTION_HANDLER_STATUS.none);
        this.alertBarService.hide();
        this.productSearchResults$.next([]);
        this.searchTableComponent.dataGrid.instance.clearFilter();
    }

    private clearFormProcessing() {
        this.filterNameComponent.completeProcessing();
        this.productSearchConditionComponent.completeProcessing();
        this.productSearchConditionComponent.saveFilter = false;
        this.productSearchConditionComponent.productScheduleVersionId = null;
        this.productSearchConditionComponent.productScheduleVersion$.next(null);
        this.productGroup = null;
    }

    private getDefaultProductSearchCondition() {
        let view = {} as TransportSearchConditionView;
        view.searchStatusCode = 'A';
        view.productGroupCode = this.productGroup
        view.searchUsageTypeCode = this.DATA_USAGE_TYPE;
        return view;
    }

    private getProductSearchFilter() {
        return this.productSearchFilterService.getByProductCategory(this.TRANSPORT_PRODUCT_CATE_CODE)
            .pipe(map(res => res.map(r => new Select2Data(r.productId, r.productName))));
    }

    onProductSearchFilterIdChange(productId: string) {
        if (productId) {
            this.alertBarService.hide();
            this.getFilter(productId);
            this.setActionBarMode(this.advanceSearchModeEnabled ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.selected);
        } else {
            this.clearSearchCondition();
        }
    }

    hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    getFilter(productId, copyAction: boolean = false) {
        if (productId) {
            this.spinnerService.show();
            this.transportService.getByProductId(productId)
                .subscribe(
                    (responses: TransportViewModel) => {
                        this.selectedItem = responses;
                        this.fillModelToCondition(responses, copyAction);
                        this.setProductHashTagValue(responses.productHashtags);
                        this.fillModelToProductNumber(responses.productNumbers);
                        this.fillModelToProductValidity(responses.productValidities);
                        this.fillModelToProductAttribute(responses.productAttributes, responses.draftFlag);
                        this.fillModelToOriginDestination(responses.productAttributes, responses.serviceTimes, responses.productHierarchy);
                        this.fillModelToVehicle(responses);
                        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
                        if (this.currentActionBarMode == ACTION_HANDLER_STATUS.editing) {
                            // re-setup disables again
                            this.setActionBarMode(ACTION_HANDLER_STATUS.editing);
                        }
                        this.spinnerService.hide();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private fillModelToCondition(responses: TransportViewModel, copyAction: boolean = false) {
        let view = {} as TransportSearchConditionView;
        view.productId = copyAction ? null : responses.productId;
        view.productGroupCode = responses.productGroupCode;
        view.productTypeCode = responses.productTypeCode;
        view.searchStatusCode = responses.searchStatusCode;
        if (responses.productName) {
            view.productName = responses.productName + (copyAction ? '-copy' : '');
        }
        view.searchName = responses.searchName;
        view.searchUsageTypeCode = responses.searchUsageTypeCode;
        view.isOwnerFilter = (responses.filterUserAccountId) ? true : false;
        view.usageTypeCode = responses.usageTypeCode;
        view.providerId = this.mapperService.getProviderId(responses);
        view.flightNumber = this.mapperService.getFlightNumber(responses);
        view.departureId = this.mapperService.getLocationId(responses, 1);
        view.departureData = this.mapperService.getLocationData(responses, 1)
        view.arrivalId = this.mapperService.getLocationId(responses, 2);
        view.arrivalData = this.mapperService.getLocationData(responses, 2);
        view.productScheduleVersionId = responses.productScheduleVersionId;
        view.productSchedulePeriodId = this.productSearchConditionComponent.findProductSchedulePeriodId(responses.productScheduleVersionId);
        this.productSearchConditionComponent.createProductScheduleVersion(view.productSchedulePeriodId);
        this.productSearchConditionComponent.filterProductTypeGroup(view.productGroupCode);
        view.draftFlag = responses.draftFlag;
        view.finalFlag = responses.finalFlag;
        this.transportSearchConditionView$.next(view);
        this.changeDetectionRef.detectChanges();
        this.productSearchConditionComponent.updateDepartureArrival(view.departureId, view.arrivalId);
    }

    onDeleteItem(item) {
        this.deleteProduct(item.id);
    }

    deleteProduct(productId: string) {
        if (!productId) {
            return;
        }
        this.transportService.delete(productId)
            .subscribe(
                () => {
                    this.alertService.success(this.DELETE_SUCCESS, this.ALERT_HEADER, { timeOut: 2000 });
                    this.search(this.searchCommand);
                    this.productSearchFilters$ = this.getProductSearchFilter();
                    this.changeDetectionRef.detectChanges();
                }
            )
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference,
            value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTag$.next(productHashtagViews);
    }

    subscribeToActionBar() {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                actionId => {
                    switch (actionId) {
                        case ACTION_STATUS.new:
                            this.onNew();
                            break;
                        case ACTION_STATUS.refresh:
                            this.clearSearchCondition();
                            this.onSearch(this.getDefaultProductSearchCondition());
                            break;
                        case ACTION_STATUS.back:
                            this.gotoPreviousPage();
                            break;
                        case ACTION_STATUS.cancel:
                            this.gotoProductCategoriesPage();
                            break;
                        case ACTION_STATUS.copy:
                            if (this.selectedItem) {
                                this.onCopyItem(this.selectedItem);
                            }
                            break;
                        case ACTION_STATUS.save:
                            this.onSaveFilterClick();
                            break;
                        case ACTION_STATUS.saveAs:
                            this.onSaveFilterClick(true);
                            break;
                        default:
                            break;
                    }
                }
            );
    }

    onUpdateStatus(item) {
        this.updateStatus(item.id, item.statusCode);
    }

    private updateStatus(productId: string, statusCode: string) {
        if (!productId || !statusCode) {
            return;
        }
        this.spinnerService.showSaving();
        this.transportService.changeStatus(productId, statusCode)
            .subscribe(
                () => {
                    this.spinnerService.saveComplete();
                    this.productSearchConditionComponent.doSearch();
                    this.changeDetectionRef.detectChanges();
                }
            )
    }

    private clearSearchResult() {
        this.paggingView = new Array();
        this.productSearchResults$ = new BehaviorSubject<TransportSearchTableView[]>(null);
    }

    private gotoPreviousPage() {
        this.alertBarService.hide();
        if (this.isCategoriesPage) {
            this.cancelRequest.emit();
        }
        else {
            let params = {
                tab: "categories"
            }
            this.navigationService.navigate('main/product/transport', null, null, params);
        }
    }

    private gotoProductCategoriesPage() {
        this.alertBarService.hide();
        if (this.isCategoriesPage) {
            this.cancelRequest.emit();
        }
        else {
            let params = {
                tab: "categories"
            }
            this.navigationService.navigate('main/product/home', null, null, params);
        }
    }

    onRefresh() {
        this.productSearchConditionComponent.doSearch();
    }

    onAdvanceSearchChanged(value) {
        this.advanceSearchModeEnabled = value;
        this.showAdvanceSearchChanged.emit(value);
        if (value) {
            this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.editing : ACTION_HANDLER_STATUS.editingNew);
            this.productSearchConditionComponent.getValues(false);
        } else {
            this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.selected : ACTION_HANDLER_STATUS.none);
            this.alertBarService.hide();
        }
    }

    private setActionBarMode(mode: string = ACTION_HANDLER_STATUS.none) {
        let model = this.merchandizeModeHelperService.getActionBarHandlerModel(mode, true);
        this.currentActionBarMode = mode;
        if (mode == ACTION_HANDLER_STATUS.editing) {
            if (!this.transportSearchConditionView$.value.isOwnerFilter) {
                model
                    .get(ACTION_STATUS.save)
                    .getDropdownBtn(ACTION_STATUS.saveAs)
                    .disable();
            } else {
                model
                    .get(ACTION_STATUS.save)
                    .getDropdownBtn(ACTION_STATUS.saveAs)
                    .enable(this.userSecurity?.newFlag ?? false);
            }
        }
        model.get(ACTION_STATUS.cancel).enable();
        model.get(ACTION_STATUS.copy).disable();
        model.get(ACTION_STATUS.new).enable(this.userSecurity?.newFlag ?? false);
        this.actionbarService.updateState(model);
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        let filter = productNumbers.filter(x => x.productNumberTypeCode);
        this.attributeAndRuleComponent.productNumberComponent?.addViewToFormGroup(filter);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        this.attributeAndRuleComponent.attributeComponent.productAttributes = this.attributeMapperService.transportAttributesToForm(productAttributes, false);
        this.attributeAndRuleComponent.attributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.attributeComponent?.addDataToFormGroup();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        this.attributeAndRuleComponent.validityRuleComponent?.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent?.detectChanges();
    }

    private fillModelToOriginDestination(productAttributes: ProductAttributeViewModel[], serviceTimes: ServiceTimeViewModel[], productHierarchy: ProductHierarchyViewModel) {
        this.attributeAndRuleComponent.originDestinationComponent?.addServiceTimes(serviceTimes, productHierarchy);
        this.attributeAndRuleComponent.originDestinationComponent?.addDataToForm(false, false, productAttributes);
        this.attributeAndRuleComponent.originDestinationComponent?.addDataToFormGroup();
        this.attributeAndRuleComponent.originDestinationComponent?.refreshForm();
    }

    private fillModelToVehicle(productTransport: TransportViewModel) {
        this.attributeAndRuleComponent.vehicleComponent?.fillData(productTransport.vehicleTypeCodeInherit,
            productTransport.vehicleCompositionIdInherit, productTransport.vehicleIdInherit,
            productTransport.vehicleTypeCode, productTransport.vehicleCompositionId, productTransport.vehicleId);
    }

    onSaveFilterClick(isSaveAs: boolean = false) {
        let product = this.filterNameComponent.getSaveModel(isSaveAs);
        let condition = this.productSearchConditionComponent.getValues(true);
        if (!this.validateAll()) {
            this.displayAlertBar();
            return;
        }
        if (!condition || !product) {
            return;
        }

        condition.productId = product.productId;
        condition.productName = product.productName;
        condition.isOwnerFilter = product.isOwnerFilter;
        condition.usageTypeCode = this.FILTER_USAGE_TYPE;
        if (!product.productId) {
            this.addNewFilter(condition);
        }
        else {
            this.updateFilter(condition);
        }
    }

    onNewFilterClick() {
        this.clearSearchCondition();
        this.productSearchConditionComponent.getValues(false);
        this.setActionBarMode(ACTION_HANDLER_STATUS.new);
        this.onAdvanceSearchChanged(true);
        this.focusOnFilterName();
    }

    focusOnFilterName() {
        setTimeout(() => {
            this.focusingService.focus(this.filterNameComponent.focusingDirective);
        }, this.FOCUS_DELAY_TIME);
    }


    private validateAll(): boolean {
        return this.filterNameComponent.validateForm() &&
            this.productSearchConditionComponent.validateForm();
    }

    private displayAlertBar() {
        if (this.displayAlertBarFilterPanel()) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.filterNameComponent.getErrorMessageForm());
        } else if (this.displayAlertBarConditionPanel()) {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.productSearchConditionComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    private displayAlertBarFilterPanel(): boolean {
        if (!this.filterNameComponent.validateForm()) {
            return true;
        }
        return false;
    }

    private displayAlertBarConditionPanel(): boolean {
        if (!this.productSearchConditionComponent.validateForm()) {
            return true;
        }
        return false;
    }

    public onFilterNameFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else if (this.filterNameComponent.processing) {
            this.alertBarService.show(this.FILTERNAME_PANEL, this.filterNameComponent.getErrorMessageForm());
        }
    }

    public onSearchConditionFormStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else if (this.filterNameComponent.processing) {
            this.alertBarService.show(this.SEARCHCONDITION_PANEL, this.productSearchConditionComponent.getErrorMessageForm());
        }
    }

    public onAlertNotifyClicked() {
        this.focusOnFilterName();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public onNew() {
        let params = {
            id: null,
            userSecurity: this.userSecurity,
            module: this.module,
            searchCriteria: this.searchCriteria
        }
        this.navigationService.navigate('main/product/transport/details', null, null, params);
    }

    private gotoEditPage(id: string, copy: boolean, no: number) {
        let params = {
            id: id,
            copy: copy,
            paggingDataView: this.getPaggingInCondition(copy),
            currentIndex: no,
            userSecurity: this.userSecurity,
            module: this.module,
            searchCriteria: this.searchCriteria
        }
        this.navigationService.navigate('main/product/transport/details', null, null, params);
    }

    private getPaggingInCondition(copy: boolean): PagingDataView[] {
        if (copy) {
            return [];
        }
        return this.paggingView;
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.productSearchConditionComponent.doSearch();
                break;
            default:
                break;
        }
    }

    private getReferencesData(): Promise<void> {
        this.loadingNotifier.show(this.SPINNER_NAME);
        return new Promise((resolve, reject) => {
            forkJoin({
                productCategoryReferences: this.productCategoryReferenceService.getAll(),
                productGroupReferences: this.productGroupReferenceService.getAll(),
                productTypeGroups: this.productTypeGroupService.getAll(),
                providers: this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE),
                suppliers: this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE),
                statusReferences: this.statusReferenceService.getAll(),
                usageTypeReferences: this.usageTypeReferenceService.getUsageTypeReferences(),
                productSchedulePeriods: this.productSchedulePeriodService.getProductSchedulePeriodAll(),
                vehicleTypeReferences: this.vehicleTypeReferenceService.getVehicleTypeReference(""),
                vehicleCompositions: this.vehicleCompositionService.getVehicleCompositionAll(),
                vehicles: this.vehicleService.getAll(),
                hashTagReferences: this.hashTagReferenceService.getAll(),
                productNumberTypeReferences: this.productNumberTypeReferenceService.getAll(),
                conditionReferences: this.conditionReferenceService.getConditionsByCodes([]),
                countryReferences: this.countryReferenceService.searchAllCountry(),
                locationGroups: this.locationGroupService.getAll(),
                regionReferences: this.regionReferenceService.getRegionReference(),
                organisationTypeReferences: this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS),
                organisations: this.organisationService.getByStatus(this.ACTIVE_STATUS),
                organisationGroupReferences: this.organisationGroupReferenceService.getOrganisationGroupReference(),
                organisationRoleReferences: this.organisationRoleReferenceService.getAllActive(),
                calendarValidityReferences: this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE),
                dateTimeDimensionReferences: this.dateTimeDimensionReferenceService.getDateTimeDimensionReference(),
                domainAttributeTypeSearch: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", true),
                domainAttributeTypeOrgDescSearch: this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_ORG_DESC, "", true),
                ruleConfigData: this.http.get(this._jsonURL),
            })
                .subscribe(({
                    productCategoryReferences,
                    productGroupReferences,
                    productTypeGroups,
                    providers,
                    suppliers,
                    statusReferences,
                    usageTypeReferences,
                    productSchedulePeriods,
                    vehicleTypeReferences,
                    vehicleCompositions,
                    vehicles,
                    hashTagReferences,
                    productNumberTypeReferences,
                    conditionReferences,
                    countryReferences,
                    locationGroups,
                    regionReferences,
                    organisationTypeReferences,
                    organisations,
                    organisationGroupReferences,
                    organisationRoleReferences,
                    calendarValidityReferences,
                    dateTimeDimensionReferences,
                    domainAttributeTypeSearch,
                    domainAttributeTypeOrgDescSearch,
                    ruleConfigData
                }) => {
                    this.fillProuctCategoryReferences(productCategoryReferences);
                    this.fillProductGroupReferences(productGroupReferences);
                    this.fillProductTypeGroups(productTypeGroups);
                    this.fillProviders(providers);
                    this.fillSupplieres(suppliers);
                    this.fillStatusReferences(statusReferences);
                    this.fillUserTypeReferences(usageTypeReferences);
                    this.fillProductSchedulePeriods(productSchedulePeriods);
                    this.fillVehicleTypeReferences(vehicleTypeReferences);
                    this.fillVehicleCompositions(vehicleCompositions);
                    this.fillVehicles(vehicles);
                    this.fillHashTagReferences(hashTagReferences);
                    this.fillProductTypeNumberReferences(productNumberTypeReferences);
                    this.fillConditionReferences(conditionReferences);
                    this.fillCountryReferences(countryReferences);
                    this.fillLocationGroup(locationGroups);
                    this.fillRegionReferences(regionReferences);
                    this.fillOrganisationTypeReferences(organisationTypeReferences);
                    this.fillOrganisation(organisations);
                    this.fillOrganisationGroupReferences(organisationGroupReferences);
                    this.fillOrganisationRoleReferences(organisationRoleReferences);
                    this.fillCalendarValidityReferences(calendarValidityReferences);
                    this.fillDateTimeDimensionReferences(dateTimeDimensionReferences);
                    this.fillDomainAttributeTypeSearch(domainAttributeTypeSearch);
                    this.fillDomainAttributeTypeOrgDescSearch(domainAttributeTypeOrgDescSearch);
                    this.fillRuleConfigData(ruleConfigData);
                    resolve();
                })
        });
    }

    private fillProuctCategoryReferences(productCategoryReferences: ProductCategoryReferenceModel[]) {
        this.productCategoryReference$.next(productCategoryReferences);
        this.productCategoryReferenceTransport = productCategoryReferences
            .filter(x => x.productCategoryCode == this.TRANSPORT_PRODUCT_CATE_CODE);
    }

    private fillProductGroupReferences(productGroupReferences: ProductGroupReferenceModel[]) {
        this.productGroupReferenceTransport = productGroupReferences.filter(x => x.productCategoryCode == this.TRANSPORT_PRODUCT_CATE_CODE);
        this.productGroupReferenceTransport$.next(this.productGroupReferenceTransport);
        this.productSearchConditionComponent.productGroupReference$.next(this.productGroupReferenceTransport);
    }

    private fillProductTypeGroups(productTypeGroups: ProductTypeGroupModel[]) {
        this.productTypeGroup$.next(productTypeGroups);
        this.productSearchConditionComponent.productTypeGroup$.next(productTypeGroups);
    }

    private fillProviders(providers: OrganisationModel[]) {
        this.provider$.next(providers
            .filter(x => x.providerIataCode)
            .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
    }

    private fillSupplieres(suppliers: OrganisationModel[]) {
        this.supplier$.next(suppliers);
    }

    private fillStatusReferences(statusReferences: StatusReferenceModel[]) {
        this.statusReference$.next(statusReferences);
    }

    private fillUserTypeReferences(userTypeReferences: UsageTypeReferenceModel[]) {
        if (userTypeReferences) {
            if (userTypeReferences) {
                this.usageTypeReference$.next(
                    userTypeReferences.filter(x => x.usageTypeCode == this.DATA_USAGE_TYPE ||
                        x.usageTypeCode == this.TEMPLATE_USAGE_TYPE));

                this.usageTypeReferenceFilter$.next(
                    userTypeReferences.filter(x => x.usageTypeCode == this.DATA_USAGE_TYPE ||
                        x.usageTypeCode == this.TEMPLATE_USAGE_TYPE || x.usageTypeCode == this.FILTER_USAGE_TYPE));
            }
        }
    }

    private fillProductTypeNumberReferences(productTypeNumberReferences: ProductNumberTypeReferenceModel[]) {
        this.productNumberTypeReference$.next(productTypeNumberReferences);
        this.productNumberTypeReferenceSearch$.next(productTypeNumberReferences.filter(x => x.productNumberTypeCode == this.OPERATINGCODE
            || x.productNumberTypeCode == this.MARKETINGCODE));
    }

    private fillProductSchedulePeriods(productSchedulePeriods: ProductSchedulePeriodModel[]) {
        this.productSchedulePeriod$.next(productSchedulePeriods);
    }

    private fillVehicleTypeReferences(vehicleTypeReferences: VehicleTypeReferenceModel[]) {
        this.vehicleTypeReference$.next(vehicleTypeReferences);
    }

    private fillVehicleCompositions(vehicleCompositions: VehicleCompositionModel[]) {
        this.vehicleComposition$.next(vehicleCompositions);
    }

    private fillVehicles(vehicles: VehicleModel[]) {
        this.vehicle$.next(vehicles);
    }

    private fillHashTagReferences(hashTagReferences: HashTagReferenceModel[]) {
        this.hashTagReference = hashTagReferences;
        this.setProductHashTagValue(null);
    }

    private fillConditionReferences(conditionReferences: ConditionReferenceModel[]) {
        this.conditionReference$.next(conditionReferences);
    }

    private fillCountryReferences(countryReferences: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(countryReferences);
    }

    private fillLocationGroup(locationGroups: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(locationGroups);
    }

    private fillRegionReferences(regionReferences: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(regionReferences);
    }

    private fillOrganisationTypeReferences(organisationTypeReferences: OrganisationTypeReferenceModel[]) {
        let orgType = this.select2DataMapperService.organisationTypeToSelect2Data(organisationTypeReferences);
        this.organisationTypeSelect2Data = orgType.filter(x => x.id != this.ORGTYPE_OWNER);
    }

    private fillOrganisation(organisations: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(organisations);
    }

    private fillOrganisationGroupReferences(organisationGroupReferences: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(organisationGroupReferences);
    }

    private fillOrganisationRoleReferences(organisationRoleReferences: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(organisationRoleReferences);
    }

    private fillCalendarValidityReferences(calendarValidityReferences: CalendarValidityReferenceModel[]) {
        this.calendarValidityReference$.next(calendarValidityReferences);
    }

    private fillDateTimeDimensionReferences(dateTimeDimensionReferences: DateTimeDimensionReferenceModel[]) {
        this.dateTimeDimensionReference$.next(dateTimeDimensionReferences);
    }

    private fillDomainAttributeTypeSearch(domainAttributeTypeSearch: DomainAttributeModel[]) {
        let filterProductFlag = this.getProductFlag(domainAttributeTypeSearch);
        this.domainAttributeSearch$.next(filterProductFlag);
    }

    private fillDomainAttributeTypeOrgDescSearch(domainAttributeTypeOrgDescSearch: DomainAttributeModel[]) {
        let filterOrgDesc = this.getAtttributeOrgDesc(domainAttributeTypeOrgDescSearch);
        this.domainAttributeOrgDescSearch$.next(filterOrgDesc);
    }

    private fillRuleConfigData(ruleConfigData: any) {
        this.validityConfig = ruleConfigData.validity;
        this.pointOfSalesConfig = ruleConfigData.pointOfSales;
    }

    private updateLoadReferenceCompleted(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.completeLoadReference = true;
            this.changeDetectionRef.detectChanges();
            resolve();
        });
    }

    private getSearchCriteriaParams() {
        let product = this.filterNameComponent.getSaveModel();
        let condition = this.productSearchConditionComponent.getValues();
        condition.productId = product.productId;
        condition.productName = product.productName;
        condition.isOwnerFilter = !product.isOwnerFilter;
        this.searchCriteria = this.mapperService.convertFormToView(condition,
            this.attributeAndRuleComponent,
            this.productHashtagValueViews,
            this.provider$,
            this.domainAttributeSearch$.value,
            this.domainAttributeOrgDescSearch$.value);
    }

    private getParams() {
        let params = this.navigationService.getParams();
        this.searchCriteria = params?.searchCriteria;
    }


    private searchAll() {
        if (!this.productGroup && this.executeFlag == true) {
            this.search(<TransportSearchCommandModel>{ searchStatusCode: 'A', searchUsageTypeCode: this.DATA_USAGE_TYPE });
        }
    }

    private searchDefault() {
        if (this.searchCriteria) {
            this.fillSearchCriteria().then(() => {
                this.productSearchConditionComponent.doSearch();
            })
        } else {
            this.searchAll();
        }
    }


    private fillSearchCriteria(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.fillModelToCondition(this.searchCriteria);
            this.filterNameComponent.updatePublicFilter(this.searchCriteria.isOwnerFilter);
            if (this.searchCriteria?.productHashtags.length) {
                this.setProductHashTagParamValue(this.searchCriteria?.productHashtags);
            }
            this.fillModelToProductNumber(this.searchCriteria.productNumbers);
            this.fillModelToProductAttribute(this.searchCriteria.productAttributes ?? [], true);
            this.fillModelToOriginDestinationParams(this.searchCriteria.productAttributes ?? []);
            this.fillModelToVehicle(this.searchCriteria);
            this.fillModelToProductValidity(this.searchCriteria.productValidities ?? []);
            this.fillModelToPointOfSales(this.searchCriteria.productRestrictionOrganisations ?? [], this.searchCriteria.productRestrictionLocations ?? []);
            this.changeDetectionRef.detectChanges();
            resolve();
        });
    }

    private setProductHashTagParamValue(value: ProductHashTagViewModel[]) {
        let productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference, value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.param == true);
        this.productHashTag$.next(productHashtagViews);
    }

    private fillModelToOriginDestinationParams(productAttributes: ProductAttributeViewModel[]) {
        this.attributeAndRuleComponent.originDestinationComponent?.addDataToForm(false, false, productAttributes);
        this.attributeAndRuleComponent.originDestinationComponent?.addDataToFormGroup();
        this.attributeAndRuleComponent.originDestinationComponent?.refreshForm();
    }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-tree-wrapper {
  height: 480px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/product-management/product-categories-content/transport/transport-content/transport-detail/tree/tree.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ","sourcesContent":[".p-tree-wrapper{\r\n    height: 480px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

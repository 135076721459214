import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecuritySearchComponent } from './security-search.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SecurityCodeModule } from '../security-code/security-code.module';
import { SecurityGroupModule } from '../security-group/security-group.module';

@NgModule({
    declarations: [
        SecuritySearchComponent
    ],
    imports: [
        CommonModule,
        SecurityCodeModule,
        SecurityGroupModule,
        UiModule
    ],
    exports: [
        SecuritySearchComponent
    ]
})
export class SecuritySearchModule { }

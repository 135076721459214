import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PriceRuleView } from "../../../shared/views";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { duplicateGroupValidation } from "src/app/core/validators";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PricingDetailConstant } from "../shared/pricing-detail.constant";
import { Select2Option } from "../attribute-and-rule/shared/attribute-and-rule/views/select2.option";
import { Select2Themes } from "../attribute-and-rule/product-eligible-restricted/shared/select2-options.service";
import { ConditionReferenceService } from "src/app/core/services/system-services";
import { DomainAttributeService } from "src/app/core/services/airline-services";
import { DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { PriceRuleAttributeModel, PriceRuleModel } from "src/app/core/models/pricing-model";
import { PriceRuleAttributeView } from "../attribute-and-rule/product-eligible-restricted/shared/views";
import { OopsChoiceValueConstant } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant";

@Component({
    selector: 'op-baggage-allowance',
    templateUrl: './baggage-allowance.component.html'
})
export class BaggageAllowanceComponent extends OopsComponentFormBase implements OnChanges {
    public readonly formArrayName = "baggageAllowances";
    public readonly attributeTypeCode = "attributeTypeCode";
    public readonly domainCode = "domainCode";
    public readonly domainAttributeId = "domainAttributeId";
    public readonly attributeChoiceId = "attributeChoiceId";
    public readonly attributeValue = "attributeValue";
    public readonly conditionCode = "conditionCode";
    public readonly displaySequence = "displaySequence";
    public readonly priceRuleAttributeId = 'priceRuleAttributeId';
    public readonly disabled = 'disabled';
    public readonly priceRuleId = 'priceRuleId';
    
    public readonly pieceCode = 'BAGGAGEPIECES';
    public readonly weightCode = 'BAGGAGEWEIGHT';
    public readonly weightUnitCode = 'BAGGAGEWEIGHTUNIT';

    public readonly defaultDomainCode = 'PRICERULE';

    public dimensionFieldMapping = [];

    @Input() priceRuleView: PriceRuleView;

    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);
    public focusing = false;
    public select2AttributeTypeOption = new Select2Option("<Attribute Type>");
    public select2WeightUnitOption = new Select2Option("<Weight Unit>");
    public select2ConditionOption = new Select2Option();
    
    public conditioinReferences$ = new BehaviorSubject<Select2Data[]>([]);
    public domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>([]);
    public weightUnitChoices$ = new BehaviorSubject<Select2Data[]>([]);

    get allowToggle(): boolean {
        return !!(this.baggageAllowances?.length)
    }

    get baggageAllowances(): UntypedFormArray {
        return <UntypedFormArray>this.formGroup.get(this.formArrayName);
    }

    get domainAttributes(): DomainAttributeModel[][] {
        return this.baggageAllowances.controls
            .map(ctrl => this.domainAttributes$.value.filter(at => at.attributeTypeCode == ctrl.get(this.attributeTypeCode).value || 
                this.selectedAttributeTypeCodes.includes(at.attributeTypeCode) == false)
            .map(at => at));
    }

    get selectedAttributeTypeCodes(): string[] {
        return this.baggageAllowances.controls
            .filter(ctrl => ctrl.get(this.disabled).value == false)
            .map(ctrl => ctrl.get(this.attributeTypeCode).value);
    }

    get newItemDisabled(): boolean {
        return this.domainAttributes$.value.every(at => this.selectedAttributeTypeCodes.includes(at.attributeTypeCode));
    }

    get selectedDomainAttributes(): DomainAttributeModel[] {
        return this.baggageAllowances.controls
            .map(ctrl => ctrl as UntypedFormGroup)
            .map(ctrl => ctrl.get(this.attributeTypeCode).value)
            .map(attributeTypeCode => this.domainAttributes$.value.find(da => da.attributeTypeCode == attributeTypeCode));
    }

    get disabledRows(): boolean[] {
        return this.baggageAllowances.controls.map(ctrl => ctrl.get(this.disabled).value);
    }

    constructor(fb: UntypedFormBuilder,
        private conditionReferenceService: ConditionReferenceService,
        private domainAttributeService: DomainAttributeService) {
        super(fb);
        this.select2ConditionOption.theme = Select2Themes.CONDITION_SELECTOR_THEME;
    }

    private getDomainAttributes() {
        if (this.domainAttributes$?.value?.length) {
            return;
        }
        this.domainAttributeService.getPriceRuleAttributes('', PricingDetailConstant.BAGGAGE_CODE, '')
            .subscribe(
                response => {
                    this.domainAttributes$.next(response);
                    this.weightUnitChoices$.next(response.find(r => r.attributeTypeCode == this.weightUnitCode).attributeChoices.map(r => new Select2Data(r.attributeChoiceId, r.attributeChoiceName)))
                }
            )
    }

    private getConditionReferences() {
        if (this.conditioinReferences$?.value?.length) {
            return;
        }
        this.conditionReferenceService
            .getConditionsByCodes([PricingDetailConstant.IS_OPERATOR_CODE])
            .subscribe(
                res => {
                    this.conditioinReferences$.next(res.map(r => new Select2Data(r.conditionCode, r.conditionName)));
                }
            )
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['priceRuleView']) {
            this.initForm();
        }
    }

    public initForm() {
        this.populateDimensionFieldMapping();
        this.getDomainAttributes();
        this.getConditionReferences();
        this.formGroup = this.toFormGroup(this.priceRuleView);
        this.togglePanelCollapse();
    }

    private populateDimensionFieldMapping() {
        if (this.dimensionFieldMapping?.length) {
            return;
        }
        this.dimensionFieldMapping = new Array<string>();
        this.dimensionFieldMapping[OopsChoiceValueConstant.NUMBER] = this.attributeValue;
        this.dimensionFieldMapping[OopsChoiceValueConstant.CHOICE] = this.attributeChoiceId;
    }

    togglePanelCollapseStatus(isCollapse: boolean) {
        this.panelCollapseFlag$.next(isCollapse);
    }

    add() {
        this.startProcessing();
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }

        this.completeProcessing();
        const formArray = <UntypedFormArray>this.formGroup.get(this.formArrayName);
        let displaySequence = formArray?.controls?.filter(ctrl => ctrl.get(this.disabled).value == false)?.length + 1;
        let formGroup = new UntypedFormGroup({
            [this.attributeTypeCode]: new UntypedFormControl(null, Validators.required),
            [this.conditionCode]: new UntypedFormControl(PricingDetailConstant.IS_OPERATOR_CODE),
            [this.displaySequence]: new UntypedFormControl(displaySequence),
            [this.disabled]: new UntypedFormControl(false),
            [this.priceRuleId]: new UntypedFormControl(this.priceRuleView?.priceRuleId)
        })
        formArray.push(formGroup);
        this.panelCollapseFlag$.next(false);
    }

    private toFormGroup(priceRuleView: PriceRuleView): UntypedFormGroup {
        return new UntypedFormGroup({
            baggageAllowances: this.toFormArray(priceRuleView?.baggageAllowances),
        });
    }

    private toFormArray(attributes: PriceRuleAttributeView[]): UntypedFormArray {
        let formArray = new UntypedFormArray([], duplicateGroupValidation);
        if (!attributes?.length) {
            return formArray;
        }
        for (let i = 0; i < attributes.length; i++) {
            let attribute = attributes[i];
            let displaySequence = i + 1;
            let formGroup = new UntypedFormGroup({
                [this.attributeTypeCode]: new UntypedFormControl(attribute.attributeTypeCode, Validators.required),
                [this.conditionCode]: new UntypedFormControl(attribute.conditionCode ?? PricingDetailConstant.IS_OPERATOR_CODE),
                [this.displaySequence]: new UntypedFormControl(displaySequence),
                [this.disabled]: new UntypedFormControl(attribute.priceRuleId != this.priceRuleView?.priceRuleId),
                [this.priceRuleId]: new UntypedFormControl(attribute.priceRuleId)
            })
            if (attribute.attributeValue) {
                formGroup.addControl(this.attributeValue, new UntypedFormControl(attribute.attributeValue, Validators.required));
            } else if (attribute.attributeChoiceId) {
                formGroup.addControl(this.attributeChoiceId, new UntypedFormControl(attribute.attributeChoiceId, Validators.required));
            }
            formArray.push(formGroup);
        }
        return formArray;
    }

    public delete(formGroup: AbstractControl) {
        this.baggageAllowances.removeAt(this.indexOf(this.baggageAllowances, formGroup as UntypedFormGroup));
        this.togglePanelCollapse();
    }

    public getSingleOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.SINGLE_VALUE_SELECTOR_THEME;
        delete option.disabled;
        return option;
    }

    public displayErrorBorder(control: AbstractControl, name: string): boolean {
        const formGroup = control as UntypedFormGroup;
        return (
            (formGroup.controls[name].invalid &&
                (formGroup.controls[name].dirty ||
                    formGroup.controls[name].touched) &&
                this.processing) ||
            formGroup.errors?.duplicate
        );
    }

    indexOf(formArray: UntypedFormArray, control: AbstractControl) {
        return formArray.controls.findIndex((i) => i === control);
    }

    togglePanelCollapse() {
        if (this.baggageAllowances?.length) {
            this.panelCollapseFlag$.next(false);
        } else {
            this.panelCollapseFlag$.next(true);
        }
    }

    public getConditionOption(option: Select2Option, disabled: boolean) {
        if (disabled) {
            option.theme = Select2Themes.DISABLED_THEME;
            option.disabled = true;
            return option;
        }
        option.theme = Select2Themes.CONDITION_SELECTOR_THEME;
        option.disabled = true;
        return option;
    }

    public onAttributeTypeCodeChange(control: AbstractControl, attributeTypeCode: any) {
        control.get(this.attributeTypeCode).setValue(attributeTypeCode);
        let dimensionUnitCode = this.getDimensionUnitCode(attributeTypeCode);
        let formGroup = control as UntypedFormGroup;
        let fieldName = this.dimensionFieldMapping[dimensionUnitCode];
        let defaultValue = null;
        if (fieldName == this.attributeChoiceId) {
            defaultValue = this.weightUnitChoices$.value[0].id;
        }
        formGroup.addControl(this.dimensionFieldMapping[dimensionUnitCode], new UntypedFormControl(defaultValue, Validators.required));

        let noUseFields = Object.keys(this.dimensionFieldMapping).filter(key => key != dimensionUnitCode).map(key => this.dimensionFieldMapping[key])
        for (let field of noUseFields) {
            formGroup.removeControl(field);
        }
    }

    public getDimensionUnitCode(attributeTypeCode: string): string {
        return this.domainAttributes$.value.find(da => da.attributeTypeCode == attributeTypeCode)?.dimensionUnitCode;
    }

    public getValues(priceRuleModel: PriceRuleModel): PriceRuleModel {
        this.startProcessing();
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return null;
        }
        this.completeProcessing();
        let attributes = priceRuleModel.attributes;
        attributes = (attributes ?? []).concat(this.toModels());
        priceRuleModel.attributes = attributes;
        return priceRuleModel;
    }

    private toModels() {
        if (!this.baggageAllowances.length) {
            return [];
        }
        let attributes = new Array<PriceRuleAttributeModel>();
        for (let ba of this.baggageAllowances.controls) {
            if (ba.get(this.disabled).value == true) {
                continue;
            }
            let attribute = new PriceRuleAttributeModel();
            attribute.priceRuleId = this.priceRuleView.priceRuleId;
            attribute.attributeChoiceId = ba.get(this.attributeChoiceId)?.value,
            attribute.attributeTypeCode = ba.get(this.attributeTypeCode)?.value,
            attribute.attributeValue = ba.get(this.attributeValue)?.value,
            attribute.conditionCode = ba.get(this.conditionCode)?.value,
            attribute.displaySequence = ba.get(this.displaySequence)?.value,
            attributes.push(attribute);
        }
        return attributes;
    }
}
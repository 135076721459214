import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { PointOfSalesModel } from 'src/app/core/models/merchandizing-config';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-special-service-point-of-sales',
    templateUrl: './point-of-sales.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointOfSalesComponent {

    private _jsonURL = 'assets/data/merchandizing/attributes&rules_config.json';

    //Product restriction location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    //Product restriction organisation
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];
    
    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;

    @Input() pointOfSalesConfig: PointOfSalesModel;
    public focusing: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private http: HttpClient) {
        this.getJSON().subscribe(data => {
            this.pointOfSalesConfig = data.pointOfSales;
            this.changeDetectorRef.detectChanges();
        });
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    get inheritControlLength(): number {
        if (this.ruleComponent?.pointofsalesInheritComponent) {
            return this.ruleComponent?.pointofsalesInheritComponent?.
            inheritforms?.controls?.length;
        }
        return 0;
    }
    
    public add() {
        this.ruleComponent.add();
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { cloneDeep } from "lodash";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { GeneralFormOption } from "./general-form-config";
import { select2Status } from "../../shared/select2-configuration";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { VehicleConfigurationAddCommandModel, VehicleConfigurationEditCommandModel, VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { VehicleConfigurationService } from "src/app/core/services/vehicle-services";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-configuration-detail-general',
    templateUrl: './general.component.html'
})
export class VehicleConfigurationGeneralDetailComponent extends OopsComponentFormBase implements OnChanges {
    readonly ERROR_CODE_REQUIRED = 'Code is required.';
    readonly ERROR_CODE_FORMAT = 'Code wrong format.';
    readonly ERROR_CODE_DUPLICATE = 'This code is duplicate.';
    readonly ERROR_NAME_REQUIRED = 'Name is required.';
    readonly ERROR_STATUS_REQUIRED = 'Status is required.';

    @Input() id: string;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() vehicleConfiguration = {} as VehicleConfigurationModel;
    @Input() userSecurity: SecurityGroupSecurityModel
    @Output() formStatus = new EventEmitter<boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    statusOption = select2Status;
    focusing: boolean = false;

    constructor(formBuilder: UntypedFormBuilder,
        private dateConverterService: DateConverterService,
        private vehicleConfigurationService: VehicleConfigurationService) {
        super(formBuilder);
    }

    initForm() {
        this.formSetup();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['vehicleConfiguration']) {
            this.formSetup();
        }
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(cloneDeep(GeneralFormOption));
        if (!this.vehicleConfiguration) {
            this.completeProcessing();
            this.formGroup.reset();
            this.fillDefaultValue();
        } else {
            this.fillModelToForm(this.vehicleConfiguration);
        }
        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private fillModelToForm(model: VehicleConfigurationModel) {
        this.formGroup.patchValue({
            code: model.vehicleConfigurationCode,
            name: model.vehicleConfigurationName,
            status: model.statusCode,
            commitByName: model.commitByName,
            commitDateTime: this.dateConverterService.convertDateTime24(model.commitDateTime)
        })
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.formGroup.controls["code"].valid) {
            return this.getCodeErrorMessage();
        } else if (!this.formGroup.controls["name"].valid) {
            return this.ERROR_NAME_REQUIRED;
        } else if (!this.formGroup.controls["status"].valid) {
            return this.ERROR_STATUS_REQUIRED;
        }
    }

    private getCodeErrorMessage(): string {
        if (this.formGroup.controls["code"].errors?.required) {
            return this.ERROR_CODE_REQUIRED;
        } else if (this.formGroup.controls["code"].errors?.minlength || this.formGroup.controls["code"].errors?.pattern) {
            return this.ERROR_CODE_FORMAT;
        } else if (this.formGroup.controls["code"].errors?.duplicate) {
            return this.ERROR_CODE_DUPLICATE;
        }
    }

    getAddValue(): VehicleConfigurationAddCommandModel {
        let formValue = this.formGroup.getRawValue();
        let addCommand: VehicleConfigurationAddCommandModel = {
            vehicleConfigurationCode: formValue.code,
            vehicleConfigurationName: formValue.name,
            statusCode: formValue.status,
            vehicleConfigurationCompartments: [],
            vehicleConfigurationProducts: [],
            productInventories: []
        }
        return addCommand;
    }

    getEditValue(): VehicleConfigurationEditCommandModel {
        let formValue = this.formGroup.getRawValue();
        let editCommand: VehicleConfigurationEditCommandModel = {
            vehicleConfigurationId: this.id,
            vehicleConfigurationCode: formValue.code,
            vehicleConfigurationName: formValue.name,
            statusCode: formValue.status,
            vehicleConfigurationCompartments: [],
            vehicleConfigurationProducts: [],
            productInventories: [],
            vehicleConfigurationCompartmentProperties: []
        }
        return editCommand;
    }


    onCodeChange() {
        if (this.formGroup.get("code").value != null)
            this.formGroup.get("code").setValue(this.formGroup.get("code").value.toUpperCase());
    }

    validateDuplicateCode(e) {
        if (e.target.value == "") {
            return;
        }
        this.vehicleConfigurationService.getByCode(e.target.value)
            .subscribe(
                (responses: VehicleConfigurationModel[]) => {
                    this.checkDuplicate(responses);
                }
            )
    }

    private checkDuplicate(responses: VehicleConfigurationModel[]) {
        if (responses) {
            let filter = responses.filter(x => x.vehicleConfigurationId != this.id);
            if (filter?.length) {
                this.formGroup.get('code').setErrors({ duplicate: true });
            }
            else {
                this.deleteErrorDuplicate();
            }
        }
    }

    private deleteErrorDuplicate() {
        if (this.formGroup.controls["code"].errors?.duplicate) {
            delete this.formGroup.controls["code"].errors['duplicate'];
            this.formGroup.controls["code"].updateValueAndValidity();
        }
    }

    private fillDefaultValue() {
        this.formGroup.patchValue({
            status: "A"
        });
    }
}
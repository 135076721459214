import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { Helper } from "src/app/shared/helper/app.helper";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PriceModel } from "../../shared/models";
import { PriceView } from "../../shared/views";

@Component({
    selector: 'op-price-search-filter-name',
    templateUrl: 'search-filter-name.component.html',
})
export class SearchFilterNameComponent extends OopsComponentFormBase implements OnChanges, AfterViewInit, OnDestroy {
    @Input() advanceSearchMode: boolean;
    @Input() priceView: PriceView;
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onDelete = new EventEmitter<string>();
    @Output() onNew = new EventEmitter();
    @Output() onSave = new EventEmitter();

    classIcon = this.helper.getClassIcon();
    focusing = false;
    isOwnerFilter = false;

    unsubscribe$ = new Subject();

    constructor(fb: UntypedFormBuilder, private helper: Helper, private actionbarService: ActionbarService) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['priceView']) {
            this.formGroup = this.convertPriceRuleViewToFormGroup(this.priceView);
        }
        this.updateActionBarHandlerState();
    }

    ngAfterViewInit(): void {
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                switch (actionId) {
                    case ACTION_STATUS.save:
                        this.onFilterSave(this.formGroup.controls['priceId']?.value);
                        break;  
                    case ACTION_STATUS.saveAs:
                        this.onFilterSave(null);
                        break;
                    case ACTION_STATUS.delete:
                        this.onDeleteClick();
                        break;
                    default:
                        break;
                }
            }
        )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public initForm() {
        this.formGroup = this.convertPriceRuleViewToFormGroup(new PriceView());
    }

    private convertPriceRuleViewToFormGroup(view: PriceView): UntypedFormGroup {
        this.isOwnerFilter = view.isOwnerFilter;
        return new UntypedFormGroup({
            priceId: new UntypedFormControl(view.priceId),
            priceName: new UntypedFormControl(view.priceName, Validators.required),
            publicFilter: new UntypedFormControl(!view.isOwnerFilter),
        });
    }

    private convertFormGroupToPriceModel(priceId: string, formGroup: UntypedFormGroup): PriceModel {
        let model = new PriceModel();
        model.priceId = priceId;
        model.priceName = formGroup.controls['priceName']?.value;
        model.isOwnerFilter = !formGroup.controls['publicFilter']?.value;
        return model;
    }

    onCancelBtnClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    onFilterSave(priceId: string) {
        this.startProcessing();
        if (!this.validForm()) {
            this.validateAllFormFields(this.formGroup);
            return;
        }
        let model = this.convertFormGroupToPriceModel(priceId, this.formGroup);
        this.onSave.emit(model);
        this.completeProcessing();
    }

    onNewClick() {
        this.onNew.emit();
    }

    onDeleteClick() {
        if (!this.isOwnerFilter) {
            return;
        }
        this.onDelete.emit(this.formGroup.controls['priceId']?.value);
    }

    private updateActionBarHandlerState() {
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (this.advanceSearchMode) {
            actionBarHandler.get(ACTION_STATUS.delete)?.enable(this.canDelete());
            actionBarHandler.get(ACTION_STATUS.copy)?.enable(this.allowCopy());
            let saveBtn = actionBarHandler.get(ACTION_STATUS.save);
            saveBtn?.enable();
            saveBtn?.getDropdownBtn(ACTION_STATUS.save)?.enable(this.canSaveToSameOrNewId());
            saveBtn?.getDropdownBtn(ACTION_STATUS.saveAs)?.enable(this.alwaysSaveToNewId());
        } else {
            actionBarHandler.get(ACTION_STATUS.delete)?.disable();
            actionBarHandler.get(ACTION_STATUS.copy)?.disable();
            actionBarHandler.get(ACTION_STATUS.save)?.disable();
        }
        this.actionbarService.updateState(actionBarHandler);
    }

    private canDelete(): boolean {
        return !!this.isOwnerFilter && this.userSecurity?.deleteFlag;
    }

    private allowCopy(): boolean {
        return !!this.priceView.priceId && this.userSecurity?.copyFlag;
    }

    private canSaveToSameOrNewId(): boolean {
        return (!!this.priceView.priceId && this.isOwnerFilter && this.userSecurity?.editFlag) || (!this.priceView.priceId && this.userSecurity?.newFlag);
    }

    private alwaysSaveToNewId(): boolean {
        return !!this.priceView.priceId && this.userSecurity?.newFlag;
    }
}
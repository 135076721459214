import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { LanguageTranslationSetCommandModel, LanguageTranslationSetViewModel } from "../../models/language-translation-set-model";

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends ApiServiceBase {
  private readonly TRANSLATION_PATH = "/translation"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public searchLangugeTranslationSet(): Observable<LanguageTranslationSetViewModel[]> {
    return this.httpGet<LanguageTranslationSetViewModel[]>(this.TRANSLATION_PATH + "/languageTranslationSet/", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public searchTranslationByEntityNameAndEntityPrimary(entityName: string, entityPrimary: string): Observable<LanguageTranslationSetViewModel[]> {
    return this.httpGet<any>(this.TRANSLATION_PATH + "/languageTranslationSet/" + entityName + "/" + entityPrimary, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public saveLanguageTranslationSet(payload: LanguageTranslationSetCommandModel): Observable<never> {
    return this.httpPost<never>(`${this.TRANSLATION_PATH}/LanguageTranslationSet`, payload)
      .pipe(
        map(res => res.body)
      )
  }
}

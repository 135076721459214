import { Injectable } from '@angular/core';
import { ActivityCommandModel, ActivityViewModel } from 'src/app/core/models/activity-model';
import { CommentCommandModel, ActivityCommentCommandModel } from "src/app/core/models/activity-model/activity-comment";
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { DomainConstant } from './activity-domain.constant';
import { ActivityDomainView } from './activity-domain.view';

@Injectable()
export class ActivityDomainMapperService {

    public toViews(models: ActivityViewModel[]): ActivityDomainView[] {
        let views: ActivityDomainView[] = new Array()
        let i = 0;
        if (models) {
            for (let model of models) {
                ++i;
                views.push(this.toView(i, model));
            }
        }
        return views;
    }

    private toView(i: number, model: ActivityViewModel): ActivityDomainView {
        let view = {} as ActivityDomainView;
        view.no = i;
        view.activityId = model.activityId;
        view.organisationId = model.organisationId;
        view.organisationName = model.organisationCallName;
        view.domainCode = model.domainCode;
        view.activityTypeCode = model.activityTypeCode;
        view.activityTypeName = model.activityTypeName;
        view.activityPriorityCode = model.activityPriorityCode;
        view.activityPriorityName = model.activityPriorityName;
        view.activityDateTime = model.commitDateTime;
        view.followUpDateTime = model.followUpDateTime;
        view.activityReasonCode = model.activityReasonCode;
        view.activityReasonName = model.activityReasonName;
        view.timelimitDateTime = model.timelimitDateTime;
        if (model.comments?.length) {
            let comment = model.comments[0];
            if (comment?.activityComments?.length) {
                view.activityCommentId = comment.activityComments[0].activityCommentId;
                view.commentId = comment.commentId;
                view.commentText = comment.commentText;
                view.commentTypeCode = comment.commentTypeCode;
                view.commentClassificationCode = comment.commentClassificationCode;
            }
        }
        return view;
    }

    public toModels(views: ActivityDomainView[], id: string): ActivityCommandModel[] {
        let commands: ActivityCommandModel[] = new Array();
        if (views) {
            let newViews = views.filter(x => !x.activityId);
            if (newViews?.length) {
                for (let view of newViews) {
                    commands.push(this.toModel(view, id));
                }
            }
        }
        return commands;
    }

    private toModel(view: ActivityDomainView, id: string): ActivityCommandModel {
        let command = {} as ActivityCommandModel;
        command.activityId = view.activityId ? view.activityId : undefined;
        command.organisationId = view.organisationId;
        command.domainCode = view.domainCode;
        command.activityTypeCode = view.activityTypeCode;
        command.activityPriorityCode = view.activityPriorityCode;
        command.activityStatusCode = 'PENDING';
        command.activityReasonCode = view.activityReasonCode;
        command.followUpDateTime = this.createDateTime(view.followUpDateTime);
        command.timelimitDateTime= this.createDateTime(view.timelimitDateTime);
        if (view.commentTypeCode && view.commentText) {
            command.comments = this.createCommentModels(view);
        } else {
            command.comments = [];
        }
        this.assignReferenceId(command, id);
        return command;
    }

    private createCommentModels(view: ActivityDomainView) {
        let commands: CommentCommandModel[] = new Array();
        let command = {} as CommentCommandModel;
        command.commentId = view.commentId;
        command.commentTypeCode = view.commentTypeCode;
        command.commentClassificationCode = view.commentClassificationCode;
        command.commentText = view.commentText;
        command.activityComments = this.createActivityComments(view);
        command.media = view.media;
        commands.push(command);
        return commands;
    }
    private createActivityComments(view: ActivityDomainView): ActivityCommentCommandModel[] {
        let commands: ActivityCommentCommandModel[] = new Array();
        let command = {} as ActivityCommentCommandModel;
        command.activityCommentId = view.activityCommentId;
        command.activityId = view.activityId ? view.activityId : undefined;
        commands.push(command);
        return commands;
    }

    private assignReferenceId(command: ActivityCommandModel, id: string) {
        switch (command.domainCode.toUpperCase()) {
            case DomainConstant.DOMAIN_CODE_ORDER:
                command.referenceOrderId = id;
                break;
            case DomainConstant.DOMAIN_CODE_PRODUCT:
                command.referenceProductId = id;
                break;
            case DomainConstant.DOMAIN_CODE_TRANSPORT:
                command.referenceProductId = id;
                break;
            case DomainConstant.DOMAIN_CODE_USER:
                command.referenceUserAccountId = id;
                break;
            case DomainConstant.DOMAIN_CODE_PRICERULE:
                command.referencePriceRuleId = id;
                break;
            case DomainConstant.DOMAIN_CODE_PRICE:
                command.referencePriceId = id;
                break;
            case DomainConstant.DOMAIN_CODE_INSIGHT:
                command.referenceIndividualId = id;
                break;
        }
    }

    private createDateTime(localDate: Date): any {
        if (localDate) {
            return new Date(localDate + "z");
        }
        return null;
    }

    public toPagingViews(models: ActivityViewModel[]): PagingDataView[] {
        let views: PagingDataView[] = new Array()
        let i = 0;
        if (models) {
            for (let model of models) {
                ++i;
                views.push(this.pagingView(i, model.activityId));
            }
        }
        return views;
    }

    private pagingView(index: number, id: string): PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        return pview;
    }
}
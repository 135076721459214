export class CommunicationOtherTypeReferenceModel {
    public communicationOtherTypeCode: string;
    public communicationOtherTypeName: string;
    public mailAddressFlag: boolean;
    public logonFlag: boolean;
    public passwordFlag: boolean;
    public tokenFlag: boolean;
    public primaryFlag: boolean;
    public sortSequence: number;
    public statusCode: string;
}

import { Component, EventEmitter, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ACTION_STATUS, ActionBarDetailHandlerModel, ActionbarDetailService } from "src/app/shared/ui/actionbar-detail";
import { ActionButtonModel, DeleteButtonModel, EditButtonModel } from "src/app/shared/ui/actionbar-detail/models";

@Component({
    selector: 'op-address-list-action-bar',
    templateUrl: './address-list-action-bar.component.html',
    providers: [
        ActionbarDetailService
    ]
})
export class AddressListActionBarComponent {
    @Output() edit = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    
    private unsubscribe$ = new Subject();
    private actionButton = new ActionButtonModel();
    private editButton = new EditButtonModel();
    private deleteButton = new DeleteButtonModel();
    private action: boolean = false;

    constructor(private actionbarDetailService: ActionbarDetailService) {

    }

    ngAfterViewInit(): void {
        this.hideActionBarButton();
        this.actionbarDetailService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )

        this.actionButton.show();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private hideActionBarButton() {
        this.editButton.hide();
        this.deleteButton.hide();
        this.updateStateBtn();
    }

    private showActionBarButton() {
        this.editButton.show();
        this.deleteButton.show();
        this.updateStateBtn();
    }

    private updateStateBtn() {
        let actionbarDefaultState = new ActionBarDetailHandlerModel(
            this.actionButton,
            this.editButton,
            this.deleteButton
        );
        this.actionbarDetailService.updateState(actionbarDefaultState);
    }

    private actionbarClick(clickedBtn) {
        switch (clickedBtn) {
            case ACTION_STATUS.action:
                this.action = !this.action;
                if (this.action) {
                    this.showActionBarButton();
                    break;
                }
                this.hideActionBarButton();
                break;
            case ACTION_STATUS.edit:
                this.edit.emit();
                break;
            case ACTION_STATUS.delete:
                this.delete.emit();
                break;
            default:
                break;
        }
    }
    public disableActionButton(value: boolean) {
        this.actionButton.disable(value);
    }
}
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { BehaviorSubject, forkJoin } from "rxjs";
import { PriceRuleModel } from "src/app/core/models/pricing-model";
import { PriceRuleCategoryReferenceModel, PriceRuleGroupReferenceModel, PriceRuleTypeGroupModel } from "src/app/core/models/reference-model/reference-pricing-model";
import { PricingService } from "src/app/core/services/pricing-services";
import { PriceRuleCategoryReferenceService, PriceRuleGroupReferenceService, PriceRuleTypeGroupService } from "src/app/core/services/system-services";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { PriceModel } from "../../shared/models";

@Component({
    selector: 'op-price-rule-mapping',
    templateUrl: './price-rule-mapping.component.html'
})
export class PriceRuleMappingComponent implements OnChanges {
    private readonly defaultUsageTypeCode = 'DATA';
    private readonly requiredFields = ['priceRuleCode', 'priceRuleName'];

    private defaultPriceRuleSearchCriteria: PriceRuleModel;
    private requiredValidate = false;
    public doneLoading = false;

    public focusing = false;
    public rows = 0;
    public focusedRowKey: any;
    public dataSource: DataSource = this.createDataSource(null);
    public priceRuleCategoryReferences: PriceRuleCategoryReferenceModel[];
    public priceRuleGroupReferences: PriceRuleGroupReferenceModel[];
    public priceRuleTypeGroups: PriceRuleTypeGroupModel[];
    public priceRules: PriceRuleModel[];

    public collapseFlag$ = new BehaviorSubject<boolean>(true);

    get hasData(): boolean {
        return !!(this.price?.priceRulePrices?.length);
    }

    
    @Input() price: PriceModel;
    @Input() disabled: boolean = false;

    @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

    constructor(private stringHelperService: StringHelperService,
        private priceRuleCategoryReferenceService: PriceRuleCategoryReferenceService,
        private priceRuleTypeGroupService: PriceRuleTypeGroupService,
        private priceRuleGroupReferenceService: PriceRuleGroupReferenceService,
        private pricingService: PricingService) {
        this.setPriceSearchDefaultCriteria()
        this.getPriceRuleGroupReferences = this.getPriceRuleGroupReferences.bind(this);
        this.getPriceRuleTypeGroups = this.getPriceRuleTypeGroups.bind(this);
        this.getPriceRules = this.getPriceRules.bind(this);
        this.getData();
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.populateData(this.price);
    }

    private populateData(price: PriceModel) {
        this.dataSource = this.createDataSource(price);
        this.collapseFlag$.next(!this.hasData);
    }

    private createDataSource(price: PriceModel): DataSource {
        return new DataSource({
            key: 'priceRulePriceId',
            store: this.createArrayStore(price)
        });
    }

    private createArrayStore(price: PriceModel): ArrayStore {
        return new ArrayStore({
            key: 'priceRulePriceId',
            data: price?.priceRulePrices?.map(prp => {
                const priceRule = this.priceRules?.find(pr => pr.id == prp.priceRuleId);
                const priceRuleType = this.priceRuleTypeGroups?.find(t => t.priceRuleTypeCode == priceRule.priceRuleTypeCode);
                const priceRuleGroup = this.priceRuleGroupReferences?.find(g => g.priceRuleGroupCode == priceRule.priceRuleGroupCode);
                const priceRuleCategory = this.priceRuleCategoryReferences?.find(c => c.priceRuleCategoryCode == priceRuleGroup.priceRuleCategoryCode);
                return {
                    priceRulePriceId: prp.priceRulePriceId,
                    priceRuleCode: prp.priceRuleId,
                    priceRuleName: prp.priceRuleId,
                    priceRuleCategory: priceRuleCategory?.priceRuleCategoryCode,
                    priceRuleGroup: priceRuleGroup?.priceRuleGroupCode,
                    priceRuleType: priceRuleType?.priceRuleTypeCode
                }
            }) ?? []
        })
    }

    private getData() {
        forkJoin({
            priceRuleCategoryReferences: this.priceRuleCategoryReferenceService.getPriceRuleCategoryReferences(),
            priceRuleGroupReferences: this.priceRuleGroupReferenceService.getPriceRuleGroupReferences(),
            priceRuleTypeGroups: this.priceRuleTypeGroupService.getPriceRuleTypeGroups(),
            priceRules: this.pricingService.searchPriceRules(this.defaultPriceRuleSearchCriteria)
        }).subscribe(
            ({
                priceRuleCategoryReferences,
                priceRuleGroupReferences,
                priceRuleTypeGroups,
                priceRules
            }) => {
                this.priceRuleCategoryReferences = priceRuleCategoryReferences;
                this.priceRuleGroupReferences = priceRuleGroupReferences;
                this.priceRuleTypeGroups = priceRuleTypeGroups;
                this.priceRules = priceRules;
                this.populateData(this.price)
                this.doneLoading = true;
            }
        )
    }

    private setPriceSearchDefaultCriteria() {
        this.defaultPriceRuleSearchCriteria = new PriceRuleModel();
        this.defaultPriceRuleSearchCriteria.statusCode = StatusConstant.ACTIVE;
        this.defaultPriceRuleSearchCriteria.usageTypeCode = this.defaultUsageTypeCode;
    }

    public newItem() {
        this.dataSource.store()
        .insert({
            priceRulePriceId: this.stringHelperService.NewGuid()
        })
        .then(() => {
            this.dataSource.reload();
            this.collapseFlag$.next(false);
        })
    }

    public delete() {
        if (!this.focusedRowKey) {
            return;
        }
        this.dataSource.store()
        .remove(this.focusedRowKey)
        .then(() => {
            this.focusedRowKey = null;
            this.dataSource.reload();
            if (this.hasData == false) {
                this.collapseFlag$.next(true);
            }
        })
    }

    public getPriceRuleGroupReferences(option) {
        return {
            store: this?.priceRuleGroupReferences?.length ? this.priceRuleGroupReferences : [],
            filter: option.data ? ['priceRuleCategoryCode', '=', option.data.priceRuleCategory] : null
        };
    }

    public getPriceRuleTypeGroups(option) {
        return {
            store: this?.priceRuleTypeGroups?.length ? this.priceRuleTypeGroups : [],
            filter: option.data ? ['priceRuleGroupCode', '=', option.data.priceRuleGroup] : null
        };
    }

    public getPriceRules(option) {
        return {
            store: this?.priceRules?.length ? this.priceRules : [],
            filter: option.data ? [
                ['priceRuleGroupCode', '=', option.data.priceRuleGroup],
                ['priceRuleTypeCode', '=', option.data.priceRuleType]
            ] : null
        };
    }

    public setPriceRuleCategory(newData, value) {
        newData.priceRuleGroup = null;
        newData.priceRuleType = null;
        newData.priceRuleCode = null;
        newData.priceRuleName = null;
        (<any>this).defaultSetCellValue(newData, value);
    }
    
    public setPriceRuleGroup(newData, value) {
        newData.priceRuleType = null;
        newData.priceRuleCode = null;
        newData.priceRuleName = null;
        (<any>this).defaultSetCellValue(newData, value);
    }
    
    public setPriceRuleType(newData, value) {
        newData.priceRuleCode = null;
        newData.priceRuleName = null;
        (<any>this).defaultSetCellValue(newData, value);
    }
    
    public setPriceRuleCode(newData, value) {
        newData.priceRuleName = value;
        (<any>this).defaultSetCellValue(newData, value);
    }
    
    public setPriceRuleName(newData, value) {
        newData.priceRuleCode = value;
        (<any>this).defaultSetCellValue(newData, value);
    }

    public getValues(price: PriceModel): PriceModel {
        let priceRulePrices = [];
        const items = this.dataSource?.items();
        if (!items?.length) {
            price.priceRulePrices = priceRulePrices;
            return price;
        }
        for (let item of items) {
            if (!item.priceRuleCode) {
                this.showErrorCell(true);
                return null;
            }

            priceRulePrices.push({
                priceRulePriceId: item.priceRulePriceId,
                priceRuleId: item.priceRuleCode
            })
        }
        price.priceRulePrices = priceRulePrices;
        return price;
    }

    public onCellPrepared(e) {
        if (e.rowType == 'data' && 
            this.requiredFields.includes(e.column.dataField) && 
            this.requiredValidate &&
            !e.value) {
            e.cellElement.addClass('invalid-cell')
        } else {
            e.cellElement.removeClass('invalid-cell');
        }
    }

    private showErrorCell(isShow: boolean) {
        this.requiredValidate = isShow;
        this.datagrid.instance.repaint();
    }

    public onSaved(e) {
        if (this.requiredValidate && !!(e.changes[0]?.data?.priceRuleCode)) {
            this.showErrorCell(false);
        }
    }

    public refresh() {
        this.populateData(this.price);
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ExchangeRateContentModule } from "./exchange-rate-content/exchange-rate-content.module";
import { ExchangeRateComponent } from "./exchange-rate.component";

@NgModule({
    declarations: [
        ExchangeRateComponent,
    ],
    imports: [
        ExchangeRateContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [ExchangeRateComponent]
})
export class ExchangeRateModule { }
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SimpleChange } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';

import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';

import {
    select2ProductCategory, select2ProductGroup, select2ProductTypeGroup, select2UsageType, select2StatusGeneral
} from '../../../../shared/configuration/select2-general-configuration';


import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductTypeGroupModel
} from '../../../../../../../core/models/reference-model/reference-product-model';
import { OrganisationModel } from '../../../../../../../core/models/reference-model/reference-organisation-model/organisation.model';
import { StatusReferenceModel, UsageTypeReferenceModel } from '../../../../../../../core/models/reference-model/reference-general-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { pairwise } from 'rxjs/operators';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ResizedEvent } from 'angular-resize-event-package';

@Component({
    selector: 'op-general',
    templateUrl: './general.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralComponent extends OopsComponentFormBase implements OnChanges {

    readonly ERROR_NAME_REQUIRED = 'Name is required.';
    readonly ERROR_PRODCATE_REQUIRED = 'Product Category is required.';
    readonly ERROR_PRODGROUP_REQUIRED = ' Product Group is required.'
    readonly ERROR_PRODTYPEGROUP_REQUIRED = 'Product Type Group is required.';

    @Input() baseFormGroup: any;
    @Input() id: string;
    @Input() saveAction: boolean = false;
    @Input() productCategoryReference$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReference$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroup$: BehaviorSubject<ProductTypeGroupModel[]>;
    @Input() supplier$: BehaviorSubject<OrganisationModel[]>;
    @Input() statusReference$: BehaviorSubject<StatusReferenceModel[]>;
    @Input() usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    @Input() showVerifyButton: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() changeStatusMode: boolean = false;

    @Output() productTypeCode = new EventEmitter<string>();
    @Output() productGroupCode = new EventEmitter<string>();
    @Output() formStatus = new EventEmitter<boolean>();
    @Output() productName = new EventEmitter<string>();
    @Output() verifyClick = new EventEmitter();
    @Output() onPanelHeightChange = new EventEmitter();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public productCategoryOption: any;
    public productGroupOption: any;
    public statusOption: any;
    public productTypeGroupOption: any;
    public supplierOption: any;
    public usageTypeOption: any;
    public focusing: boolean = false;
    public productTypeGroupFilter$: BehaviorSubject<ProductTypeGroupModel[]>;

    private isDraft: boolean;
    private notFinalYet: boolean;

    get allowDraftFlagStateChange(): boolean {
        return !this.id || this.isDraft;
    }

    get allowFinalFlagStateChange(): boolean {
        return !!(this.id) && this.notFinalYet && !this.isDraft;
    }

    get deleteFlag() {
        return this.userSecurity?.deleteFlag ?? false;
    }
    get editFlag() {
        return this.userSecurity?.editFlag ?? false;
    }

    constructor(formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef) {
        super(formBuilder);
        this.setOptionControl();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (changes['id']) {
            this.completeProcessing();
            this.isDraft = this.baseFormGroup.get('draftFlag').value;
            this.notFinalYet = this.baseFormGroup.get('finalFlag').value == false;
        }
        this.baseFormGroup.updateValueAndValidity();
    }

    public initForm() {
        this.formGroup = this.baseFormGroup;
        this.formGroup.statusChanges
            .subscribe(() => this.onFormStatusChange());
        this.formGroup.controls['productName'].valueChanges
            .subscribe(productName => { this.productName.emit(productName) });
        this.handleCheckboxControls();
    }

    private setOptionControl() {
        this.productCategoryOption = select2ProductCategory;
        this.productGroupOption = select2ProductGroup;
        this.productTypeGroupOption = select2ProductTypeGroup;
        this.statusOption = select2StatusGeneral;
        this.usageTypeOption = select2UsageType;
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    public productGroupChange(value) {
        this.valueChange(value, 'productGroupCode');
        this.productGroupCode.emit(value);
        this.filterProductTypeGroup(value);
    }

    public productTypeGroupChange(value) {
        this.valueChange(value, 'productTypeGroupId');
        this.productTypeCode.emit(value);
    }

    public valueChange(value, name) {
        this.baseFormGroup.get(name).setValue(value);
    }

    public filterProductTypeGroup(productGroupCode) {
        if (this.productGroupReference$.value) {
            this.productTypeGroupFilter$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
            if (productGroupCode) {
                var filter = this.productTypeGroup$.value.filter(x => x.productGroupCode == productGroupCode);
                this.productTypeGroupFilter$.next(filter);
                if (filter.length != 0) {
                    this.baseFormGroup.get("productTypeGroupId").setValue(filter[0].productTypeCode);
                    this.productTypeCode.emit(filter[0].productTypeCode);
                }
                else {
                    this.productTypeCode.emit(null);
                }
            }
            else {
                this.baseFormGroup.get("productTypeGroupId").setValue(null);
                this.productTypeCode.emit(null);
            }
        }
    }

    public counterText(name, maxlength): string {
        if (this.baseFormGroup.get(name).value) {
            return (maxlength - this.baseFormGroup.get(name).value.length).toString() + "/" + maxlength.toString();
        }
        return maxlength.toString() + "/" + maxlength.toString();
    }

    public refreshForm() {
        this.changeDetectionRef.detectChanges();
    }

    public validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productName"].valid) {
            return this.ERROR_NAME_REQUIRED;
        } else if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.ERROR_PRODGROUP_REQUIRED;
        } else if (!this.formGroup.controls["productTypeGroupId"].valid) {
            return this.ERROR_PRODTYPEGROUP_REQUIRED;
        }
        return null;
    }

    private handleCheckboxControls() {
        const noEmitEvent = { emitEvent: false };
        this.formGroup.controls['draftFlag'].valueChanges
            .pipe(pairwise()).subscribe(
                ([prev, next]: [any, any]) => {
                    if (next == prev) {
                        return;
                    }
                    if (this.allowFinalFlagStateChange == false) {
                        return;
                    }
                    let finalFlagControl = this.formGroup.get('finalFlag');
                    if (next == true) {
                        finalFlagControl.disable(noEmitEvent);
                    } else {
                        finalFlagControl.enable(noEmitEvent);
                    }
                }
            )
        this.formGroup.controls['finalFlag'].valueChanges
            .pipe(pairwise()).subscribe(
                ([prev, next]: [any, any]) => {
                    if (next == prev) {
                        return;
                    }
                    if (this.allowDraftFlagStateChange == false) {
                        return;
                    }
                    let draftFlagControl = this.formGroup.get('draftFlag');
                    if (next == true) {
                        draftFlagControl.disable(noEmitEvent);
                    } else {
                        draftFlagControl.enable(noEmitEvent);
                    }
                }
            )
    }

    public getDisabledStatus(): boolean {
        if (!this.formGroup.get('statusCode').disabled) {
            return false;
        }
        return true;
    }

    public onVerifyClick() {
        this.verifyClick.emit();
    }

    public fillGroupCode(groupCode: string) {
        this.baseFormGroup.get("productGroupCode").setValue(groupCode);
        this.productGroupChange(groupCode);
    }

    public onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        } 
    }
}
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PaymentSearchTabsComponent, PaymentInvoiceTabsComponent, PaymentCreditInvoiceTabsComponent, PaymentCreditAccountTabsComponent } from "./";

const routes: Routes = [
    {
        path: '',
        component: PaymentSearchTabsComponent
    },
    {
        path: 'invoice',
        component: PaymentInvoiceTabsComponent
    },
    {
        path: 'credit-invoice',
        component: PaymentCreditInvoiceTabsComponent
    },
    {
        path: 'credit-creditaccount',
        component: PaymentCreditAccountTabsComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class PaymentSearchRoutingModule { }

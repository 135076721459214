import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationComponent } from './authentication/authentication.component';
import { NavigationModel } from './../../shared/utils/navigation/navigation.model';
import { ACTION_STATUS } from './../../shared/ui/actionbar/actionbar-constant';

import { TabModel } from '../../core/models/tab/tab.model';
import { TabService } from '../../core/utils/tab.service';
import { LoginComponent } from './login-component/login.component';
import { NavigationService } from '../../shared/utils/navigation';

import { SelectOrganisationComponent } from './select-organisation/select-organisation.component';
import { LoadingSpinnerService } from './../../shared/layout/loading-spinner/loading-spinner.service';
import { selectTabs } from '../../store/tab';
import { getRouterState } from '../../store/router';
import * as settings from 'src/app/store/settings';
import * as AuthActions from './../../store/auth/auth.actions';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotUserNameComponent } from './forgot-username/forgot-username.component';
import { ChangePasswordExpiredComponent } from './change-password-expired/change-password-expired.component';

@Component({
  selector: 'op-auth',
  templateUrl: './auth.component.html',
  providers: [
    ActionbarService
  ]
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  public tabs: TabModel[];

  private _navigationSubScription: Subscription;
  private initialTab: TabModel = {
    active: true,
    component: LoginComponent,
    name: 'Logon',
    url: 'auth',
    id: 'auth'
  };
  private unsubscribe$ = new Subject();

  private currentUrl: string;
  private intervalId;

  constructor(
    private tabService: TabService,
    private navigationService: NavigationService,
    private store: Store<any>,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    public loadingSpinner: LoadingSpinnerService,
    private actionbarService: ActionbarService
  ) {
    this.store.dispatch(settings.toggleMinifyNavigation({ minifyNavigation: false }));
    this._navigationSubScription = this.navigationService
      .mainTabRoutesRecognized().pipe(takeUntil(this.unsubscribe$))
      .subscribe((navigation: NavigationModel) => {
        this.currentUrl = navigation.url;
        this.loadingSpinner.show()
        this.tabService.delete(this.tabs[0]);
        if (this.tabs.length === 0) {
          switch (navigation.url) {
            case 'authentication':
              this.initialTab = {
                active: true,
                component: AuthenticationComponent,
                name: 'Authentication',
                url: 'authentication',
                id: 'authentication'
              };
              break;
            case 'select-organisation':
              this.initialTab = {
                active: true,
                component: SelectOrganisationComponent,
                name: 'Organisation',
                url: 'select-organisation',
                id: 'selectOrganisation'
              };
              break;
            case 'auth/forgot-password':
              this.initialTabForgotPassword();
              break;
            case 'auth/forgot-username':
              this.initialTabForgotUserName();
              break;
            default:
              return;
          }
          this.changePasswordExpiredURL(navigation.url);
          this.changePasswordURL(navigation.url);

          this.onAddTab();
        }
      });
  }

  public getSelectedTab(): string {
    return this.tabs.find((tab) => tab.active == true)?.name;
  }

  ngAfterViewInit(): void {
    const currentRoute = this.store.select(getRouterState);
    currentRoute.pipe(take(1))
      .subscribe((data) => {
        if (data.state.url === '/auth') {
          this.onAddTab();
          this.onSelectTab();
        }
        if (data.state.url === '/auth/forgot-password' && !this.tabs.length) {
          this.initialTabForgotPassword();
          this.onAddTab();
          this.onSelectTab();
        }
        if (data.state.url.includes('/auth/change-password-expired') && !this.tabs.length) {
          this.initialTabChangePasswordExpired();
          this.onAddTab();
          this.onSelectTab();
        }            
        if (data.state.url.includes('/auth/change-password') && !this.tabs.length) {
          this.initialTabChangePassword();
          this.onAddTab();
          this.onSelectTab();
        }    
        if (data.state.url.includes('/auth/forgot-username') && !this.tabs.length) {
          this.initialTabForgotUserName();
          this.onAddTab();
          this.onSelectTab();
        }
      });
    this.actionbarService.action$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(actionId => {
        this.onClickActionBar(actionId);
      }
      )
    this.changeDetectionRef.detectChanges();
  }
  ngOnInit(): void {
    this.onSelectTab();
  }
  onAddTab() {
    this.tabService.add(
      this.initialTab.id,
      this.initialTab.name,
      this.initialTab.url,
      this.initialTab.component
    );
  }

  onSelectTab() {
    this.store.select(selectTabs).subscribe((tabs: TabModel[]) => {
      this.tabs = tabs;
    });
  }

  onClickActionBar(clickedBtn: string) {
    switch (clickedBtn) {
      case ACTION_STATUS.back:
        if (this.currentUrl === 'select-organisation') {
          this.store.dispatch(AuthActions.twoStepVerified({ twoStepVerified: false }))
          this.navigationService.navigate('authentication', 'Authentication')
        } else {
          this.navigationService.navigate('auth', 'Logon')
        }
        break;
      case ACTION_STATUS.refresh:
        this.navigationService.navigate(this.router.url, null);
        break;
      default:
        return;
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(settings.toggleMinifyNavigation({ minifyNavigation: false }));
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    this._navigationSubScription.unsubscribe();
    clearInterval(this.intervalId);
    this.loadingSpinner.hide()
  }

  private initialTabForgotPassword() {
    this.initialTab = {
      active: true,
      component: ForgotPasswordComponent,
      name: 'Forgot Password',
      url: 'auth/forgot-password',
      id: 'forgotPassword'
    };
  }

  private initialTabChangePassword() {
    this.initialTab = {
      active: true,
      component: ChangePasswordComponent,
      name: 'Change Password',
      url: 'auth/change-password',
      id: 'changePassword'
    };
    this.savePasswordChangeRequestId();
  }

  private changePasswordURL(url: string) {
    if (url.includes('auth/change-password')) {
      this.initialTabChangePassword();
    }
  }

  private savePasswordChangeRequestId() {
    let queryParams = this.navigationService.getQueryParams();
    this.store.dispatch(AuthActions.changePassword({ 
      changePasswordOrgId: queryParams?.id1,
      changePasswordRequestId: queryParams?.id2,
      changePasswordLinkInvalid: false,
      changePasswordLinkInvalidMessage: null
    }));
  }

  private initialTabForgotUserName() {
    this.initialTab = {
      active: true,
      component: ForgotUserNameComponent,
      name: 'Forgot UserName',
      url: 'auth/forgot-username',
      id: 'forgotUserName'
    };
  }
  private changePasswordExpiredURL(url: string) {
    if (url.includes('auth/change-password-expired')) {
      this.initialTabChangePasswordExpired();
    }
  }

  private initialTabChangePasswordExpired() {
    this.initialTab = {
      active: true,
      component: ChangePasswordExpiredComponent,
      name: 'Change Password',
      url: 'auth/change-password-expired',
      id: 'changePasswordExpired'
    };
  }
}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CashbookDocumentFOPCurrencyView, CashbookDocumentformOfPaymentAndPaymentCurrencyModel, CashbookDocumentPaymentCurrenciesModel, CashbookDocumentPaymentTypeItem, CashbookDocumentTFOPModel, CashbookEmailCommand, CashbookSearchCommandModel, CashbookSearchDocumentModel, TotalByCurrencyItemsModel, TotalByCurrencyModel, TotalFromOfPaymentItemsModel } from 'src/app/core/models/cashbook-model';
import { CashbookService } from 'src/app/core/services/cashbook-services';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { CashbookDetailFopComponent } from './cashbook-detail-fop/cashbook-detail-fop.component';
import { CashbookDetailFopapcComponent } from './cashbook-detail-fopapc/cashbook-detail-fopapc.component';
import { CashbookDetailTbcComponent } from './cashbook-detail-tbc/cashbook-detail-tbc.component';
import { CashbookDetailTFOPComponent } from './cashbook-detail-tfop/cashbook-detail-tfop.component';
import { CashbookDocumentTabConstant } from '../../shared/index'
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import {
    CancelButtonModel,
    CopyButtonModel,
    NewButtonModel,
    RefreshButtonModel,
    SaveButtonModel
} from 'src/app/shared/ui/actionbar/models';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { takeUntil } from 'rxjs/operators';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import moment from 'moment';
import { PrintCashbookExportService } from 'src/app/core/utils/exports';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { EmailComponent } from 'src/app/core/components/email/email.component';
import { EmailCommandModel, EmailFileCommandModel } from 'src/app/core/components/email/shared';
import { LoadingNotifier, LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PaymentCurrenciesItemsModel } from 'src/app/core/models/cashbook-model/cashbook-document-payment-currencies-items.model';
import { FormOfPaymentAndPaymentCurrencyItemModel } from 'src/app/core/models/cashbook-model/cashbook-document-fopapc-item.model';
import { EmailService } from 'src/app/core/services/system-services/email.service';

@Component({
    selector: 'op-cashbook-document',
    templateUrl: './cashbook-document.component.html',
    providers: [ActionbarService],
})
export class CashbookDocumentComponent implements OnInit, AfterViewInit {
    public readonly SPINNER_NAME: string = "cashbookDocunemtSpinner";
    private readonly dateTimeFormatIn = "YYYY/MM/DD H:mm:ss A";
    private readonly dateTimeFormat = "DD/MM/YYYY, H:mm A";
    private readonly DELAY_TIME_FOCUS = 200;

    public selectedTab: string = CashbookDocumentTabConstant.TotalFromOfPaymentTabId;
    public cancelButton = new CancelButtonModel();
    public copyButton = new CopyButtonModel();
    public newButton = new NewButtonModel();
    public refreshButton = new RefreshButtonModel();
    public saveButton = new SaveButtonModel();
    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );
    public countTotalFromOfPayment: number;
    public resultTotalFromOfPayment: CashbookDocumentTFOPModel;
    public resultTotalFromOfPayment$ = new BehaviorSubject<CashbookDocumentTFOPModel>(null);
    public sumTotalByCurrncy: number;
    public resultTotalByCurrncy$ = new BehaviorSubject<TotalByCurrencyItemsModel[]>(null);
    public resultTotalByCurrncy: any;
    public resultTotalByCurrncyAmount: string;
    public resultPaymentCurrency$ = new BehaviorSubject<PaymentCurrenciesItemsModel[]>(null);
    public resultPaymentCurrencies: PaymentCurrenciesItemsModel[];
    public sumPaymentCurrencies: number;
    public resultformOfPaymentAndPaymentCurrency: FormOfPaymentAndPaymentCurrencyItemModel[];
    public resultformOfPaymentAndPaymentCurrency$ = new BehaviorSubject<FormOfPaymentAndPaymentCurrencyItemModel[]>(null);
    public sumFormOfPaymentAndPaymentCurrency: number;
    private unsubscribe$ = new Subject();
    public organisations: OrganisationModel[];
    public organisationId: string;
    public organisationCallName: string;
    public paymentDateFrom: string;
    public paymentDateTo: string;
    public pdfDefaultStyle = {
        fontSize: 7
    }
    public panelExport: any = [];
    public id: string;
    public from: string = "";
    public fromName: string = "";
    public subject: string;
    public showEmailPanel: boolean = false;
    public focused: boolean = true;
    public focusing: boolean = true;
    public readonly fromFeature = "cashbook-document";
    public file: EmailFileCommandModel[];
    public otherParameters: any;
    public cashbookDocumentCommand: CashbookSearchDocumentModel;
    public userSecurity: SecurityGroupSecurityModel;
    public loadingNotifier = new LoadingNotifier();
    private navigationParams: any;
    public emailMaxMessage: number = 500;
    public searchCondition: CashbookSearchCommandModel;

    @ViewChild(CashbookDetailTFOPComponent) cashbookDetailTFOPComponent: CashbookDetailTFOPComponent;
    @ViewChild(CashbookDetailTbcComponent) cashbookDetailTbcComponent: CashbookDetailTbcComponent;
    @ViewChild(CashbookDetailFopComponent) cashbookDetailFopComponent: CashbookDetailFopComponent;
    @ViewChild(CashbookDetailFopapcComponent) cashbookDetailFopapcComponent: CashbookDetailFopapcComponent;
    @ViewChild(EmailComponent) emailComponent: EmailComponent;
    @ViewChild("emailRef") emailElement: ElementRef;

    constructor(private focusingService: FocusingService,
        public cashbookService: CashbookService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private printCashbookExportService: PrintCashbookExportService,
        private stringUtils: StringHelperService,
        private loadingSpinner: LoadingSpinnerService,
        private uiHelper: UiHelperService,
        private emailService: EmailService) { }

    get totalFromOfPaymentCount(): number {
        return this.countTotalFromOfPayment;
    }

    get totalByCurrncySummary(): number {
        return this.sumTotalByCurrncy;
    }

    get totalPaymentCurrencies(): number {
        return this.sumPaymentCurrencies;
    }

    get totalFormOfPaymentAndPaymentCurrency(): number {
        return this.sumFormOfPaymentAndPaymentCurrency;
    }

    ngOnInit(): void {
        this.setupCashbookDocument();
        this.getCashbookDocumentDetail();
    }

    ngAfterViewInit(): void {
        this.scrollToTop();
        this.createActionBarButton();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    setupCashbookDocument() {
        let param = this.navigationService.getParams();
        this.navigationParams = param;
        this.organisationId = param.organisationId;
        this.organisationCallName = param?.organisationCallName;
        this.userSecurity = param?.userSecurity;
        this.searchCondition = param?.searchCondition;

        if (this.searchCondition?.paymentDateFrom) {
            this.paymentDateFrom = moment(this.searchCondition?.paymentDateFrom, this.dateTimeFormatIn).format(this.dateTimeFormat);
        } else {
            this.paymentDateFrom = '';
        }

        if (this.searchCondition?.paymentDateTo) {
            this.paymentDateTo = moment(this.searchCondition?.paymentDateTo, this.dateTimeFormatIn).format(this.dateTimeFormat);
        } else {
            this.paymentDateTo = this.paymentDateFrom;
        }

        this.subject = "Cashbook document " + this.paymentDateFrom + '-' + this.paymentDateTo;
        this.id = param.organisationId;
    }

    private createActionBarButton() {
        this.newButton.enable(this.userSecurity?.newFlag);
        this.copyButton.enable(this.userSecurity?.copyFlag);
        this.saveButton.disable();
        this.cancelButton.disable();
        let actionbarDefaultState = new ActionBarHandlerModel(
            this.newButton,
            this.copyButton,
            this.saveButton,
            this.cancelButton,
            this.refreshButton
        );
        this.actionbarService.updateState(actionbarDefaultState);
    }
    
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back: ;
                this.back();
                break;
            case ACTION_STATUS.cancel: ;
                this.onCancel();
            case ACTION_STATUS.refresh: ;
                this.onRefresh();
            default:
                break;
        }
    }

    onRefresh() {
        this.getCashbookDocumentDetail();
    }

    onCancel() {
        this.showEmailPanel = false;
        this.createActionBarButton();
    }

    back() {
        let params = {
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate("main/cashbook/cashbook", null, false, params);
    }

    private getCashbookDocumentDetail() {
        this.loadingSpinner.show();
        let command = {} as CashbookSearchDocumentModel;
        command.organisationCallName = this.organisationCallName;
        command.organisationId = this.searchCondition?.organisationId;
        command.paymentDateFrom = this.searchCondition?.paymentDateFrom;
        command.paymentDateTo = this.searchCondition?.paymentDateTo;
        command.formOfPaymentTypeCode = this.searchCondition?.formOfPaymentTypeCode;
        command.paymentStatusCode = this.searchCondition?.paymentStatusCode;
        command.paymentCurrency = this.searchCondition?.paymentCurrency;

        forkJoin({
            responseFromOfPaymentDocuments: this.cashbookService.cashbookTotalFromOfPaymentDocuments(command),
            responsePaymentCurrencies: this.cashbookService.cashbookPaymentCurrencies(command),
            responseTotalByCurrency: this.cashbookService.cashbookTotalByCurrency(command),
            responseFormOfPaymentAndPaymentCurrency: this.cashbookService.cashbookFormOfPaymentAndPaymentCurrency(command)
        })
            .subscribe(
                ({
                    responseFromOfPaymentDocuments,
                    responsePaymentCurrencies,
                    responseTotalByCurrency,
                    responseFormOfPaymentAndPaymentCurrency
                }) => {

                    this.setCashbookTotalFromOfPaymentDocuments(responseFromOfPaymentDocuments);
                    this.setCashbookPaymentCurrencies(responsePaymentCurrencies);
                    this.setCashbookTotalByCurrency(responseTotalByCurrency);
                    this.setcashbookFormOfPaymentAndPaymentCurrency(responseFormOfPaymentAndPaymentCurrency);
                    this.loadingSpinner.hide();
                }
            ), () => {
                this.loadingSpinner.hide();
            }
    }

    private setCashbookTotalFromOfPaymentDocuments(responseFromOfPaymentDocument: CashbookDocumentTFOPModel) {
        this.countTotalFromOfPayment = parseInt(responseFromOfPaymentDocument['dataCount']);
        this.resultTotalFromOfPayment = responseFromOfPaymentDocument;
        this.mapCashbookTotalFromOfPaymentDocument(responseFromOfPaymentDocument);
        this.resultTotalFromOfPayment$.next(responseFromOfPaymentDocument);
    }

    private setCashbookPaymentCurrencies(responsePaymentCurrencies: CashbookDocumentPaymentCurrenciesModel) {
        this.resultPaymentCurrencies = responsePaymentCurrencies['paymentCurrenciesItems'];
        this.sumPaymentCurrencies = this.resultPaymentCurrencies.length;
        let dataMap = this.mapCashbookPaymentCurrencies(responsePaymentCurrencies['paymentCurrenciesItems']);
        this.resultPaymentCurrency$.next(dataMap);
    }

    private setCashbookTotalByCurrency(responseTotalByCurrency: TotalByCurrencyModel[]) {
        if (responseTotalByCurrency['totalByCurrencyItems']) {
            this.sumTotalByCurrncy = responseTotalByCurrency['totalByCurrencyItems'].length;
            this.resultTotalByCurrncy = responseTotalByCurrency['totalByCurrencyItems'].map((data) => {
                return this.mapCashbookTotalFromOfPaymentDocuments(data);
            });
            this.resultTotalByCurrncyAmount = this.getDatateAmountDecimal(responseTotalByCurrency['amount']);
        }
        this.resultTotalByCurrncy$.next(this.resultTotalByCurrncy);
    }

    private setcashbookFormOfPaymentAndPaymentCurrency(responseFormOfPaymentAndPaymentCurrency: CashbookDocumentformOfPaymentAndPaymentCurrencyModel[]) {
        this.resultformOfPaymentAndPaymentCurrency = responseFormOfPaymentAndPaymentCurrency['formOfPaymentAndPaymentCurrencyItems'];
        this.sumFormOfPaymentAndPaymentCurrency = this.resultformOfPaymentAndPaymentCurrency.length;
        let dataMapFormOfPaymentAndPaymentCurrency = this.mapCashbookFormOfPaymentAndPaymentCurrency(responseFormOfPaymentAndPaymentCurrency['formOfPaymentAndPaymentCurrencyItems']);
        this.resultformOfPaymentAndPaymentCurrency = dataMapFormOfPaymentAndPaymentCurrency;
        this.resultformOfPaymentAndPaymentCurrency$.next(dataMapFormOfPaymentAndPaymentCurrency);
    }

    private mapCashbookTotalFromOfPaymentDocument(data) {
        data.amount = this.getDatateAmountDecimal(parseFloat(data.amount));
        let element = data.totalFromOfPaymentItems;
        for (let i = 0; i < element?.length; i++) {
            data.totalFromOfPaymentItems[i].amount = this.getDatateAmountDecimal(parseFloat(data.totalFromOfPaymentItems[i].amount));
        }
        return data;
    }

    private mapCashbookTotalFromOfPaymentDocuments(data) {
        let currencyCode = (data.currencyCode) ? ' (' + data.currencyCode + ')' : '';
        return {
            currencyName: data.currencyName,
            currencyCode: data.currencyCode,
            type: data.currencyName + currencyCode,
            amount: this.getDatateAmountDecimal(parseFloat(data.amount))
        };
    }


    private mapCashbookPaymentCurrencies(data) {
        for (let i = 0; i < data?.length; i++) {
            data[i].showPanel = true;
        }
        return data;
    }

    private mapCashbookFormOfPaymentAndPaymentCurrency(data: FormOfPaymentAndPaymentCurrencyItemModel[]) {
        for (let i = 0; i < data?.length; i++) {
            data[i].showPanel = true;
            this.mapCashbookFormOfPaymentAndPaymentCurrencyPaymentTypeItem(data[i].paymentTypeItems)
        }
        return data;
    }

    private mapCashbookFormOfPaymentAndPaymentCurrencyPaymentTypeItem(datas: CashbookDocumentPaymentTypeItem[]) {
        for (let data of datas) {
            data.formOfPaymentCode = data.formOfPaymentCode;
            data.formOfPaymentName = data.formOfPaymentName;
            data.formOfPaymentItems = this.mapCashbookFormOfPaymentAndPaymentCurrencyPaymentItem(data.formOfPaymentItems);
            data.paymentCurrencyItems = this.mapCashbookFormOfPaymentAndPaymentCurrencyCurrencyItem(data.paymentCurrencyItems);
        }
        return datas;
    }

    private mapCashbookFormOfPaymentAndPaymentCurrencyPaymentItem(data: TotalFromOfPaymentItemsModel[]) {
        for (let i = 0; i < data?.length; i++) {
            data[i].amount = this.getDatateAmountDecimal(parseFloat(data[i].amount));
        }
        return data;
    }

    private mapCashbookFormOfPaymentAndPaymentCurrencyCurrencyItem(data: CashbookDocumentFOPCurrencyView[]) {
        data.forEach(currency => {
            currency.showPanel = true;
            currency.currencyItem?.forEach(currencyItem => {
                currencyItem.transactionDateTime = currencyItem.transactionDateTime;
                currencyItem.ledgerAmount = this.getDatateAmountDecimal(parseFloat(currencyItem.ledgerAmount));
                currencyItem.paymentAmount = this.getDatateAmountDecimal(parseFloat(currencyItem.paymentAmount));
            })
        })
        return data;
    }

    public getDatateTimeFormat(data): string {
        if (data) {
            return moment(data, this.dateTimeFormatIn).format(this.dateTimeFormat);
        }
        return '';
    }

    onActiveIdChange(activeId: string) {
        if (activeId == CashbookDocumentTabConstant.TotalFromOfPaymentTabId) {
            this.focusingService.focus(this.cashbookDetailTFOPComponent?.focusingDirective);
            this.selectedTab = CashbookDocumentTabConstant.TotalFromOfPaymentTabId;
        }
        if (activeId == CashbookDocumentTabConstant.TotalByCurrencyTabId) {
            this.focusingService.focus(this.cashbookDetailTbcComponent?.focusingDirective);
            this.selectedTab = CashbookDocumentTabConstant.TotalByCurrencyTabId;
        }
        if (activeId == CashbookDocumentTabConstant.PaymentCurrenciesTabId) {
            this.focusingService.focus(this.cashbookDetailFopComponent?.focusingDirective);
            this.selectedTab = CashbookDocumentTabConstant.PaymentCurrenciesTabId;
        }
        if (activeId == CashbookDocumentTabConstant.FromOfPaymentAndPaymentCurrencyTabId) {
            this.focusingService.focus(this.cashbookDetailFopapcComponent?.focusingDirective);
            this.selectedTab = CashbookDocumentTabConstant.FromOfPaymentAndPaymentCurrencyTabId;
        }
    }

    public getDatateAmountDecimal(price): string {
        return this.stringUtils.validateNullAmountDecimal(parseFloat(price));
    }

    private getDataPanelGeneral() {
        let panel = [{
            header: [
                [
                    { caption: this.organisationCallName, header: true, colSpan: 7, align: 'left' }
                ]
            ],
            items: [
                { caption: 'Payment Date Period', colSpan: 7, header: true, align: 'left' },
                { caption: this.paymentDateFrom + '-' + this.paymentDateTo, colSpan: 7, header: true, align: 'left' }
            ]
        }];
        this.panelExport.push(panel);
    }

    private getDataPanelTotalByFromOfPayment() {
        let panel = [{
            header: [
                [
                    { caption: 'Total by Form of Payment', header: true, colSpan: 4, align: 'left' },
                    { caption: this.countTotalFromOfPayment, header: true, colSpan: 1, align: 'right' },
                    { caption: 'Total', header: true, colSpan: 1, align: 'left' },
                    { caption: this.resultTotalFromOfPayment['amount'], header: true, colSpan: 1, align: 'right' }
                ]
            ],
            tableHeader: [
                { caption: 'Type', dataField: 'formOfPaymentTypeCode', align: 'left' },
                { caption: 'Name', dataField: 'formOfPaymentTypeName', align: 'left' },
                { caption: 'Subtype', dataField: 'formOfPaymentCode', align: 'left' },
                { caption: 'Name', dataField: 'formOfPaymentName', align: 'left' },
                { caption: 'Count', dataField: 'dataCount', align: 'right' },
                { caption: 'Ledger Currency', dataField: 'ledgerCurrencyCode', align: 'left' },
                { caption: 'Amount', dataField: 'amount', align: 'right' }
            ],
            items: this.resultTotalFromOfPayment['totalFromOfPaymentItems']
        }];
        this.panelExport.push(panel);
    }

    private getDataPanelTotalByCurrency() {
        let panel = [{
            header: [
                [
                    { caption: 'Total by Currency', header: true, colSpan: 7, align: 'left' },

                ], [
                    { caption: 'Payment Currencies', header: true, colSpan: 5, align: 'left' },
                    { caption: 'Total', header: true, colSpan: 1, align: 'left' },
                    { caption: this.resultTotalByCurrncyAmount, header: true, colSpan: 1, align: 'right' }
                ]
            ],
            tableHeader: [
                { caption: 'Type', dataField: 'type', colSpan: 6, align: 'left' },
                { caption: 'Amount', dataField: 'amount', colSpan: 1, align: 'right' }
            ],
            items: this.resultTotalByCurrncy
        }];
        this.panelExport.push(panel);
    }

    private getDataPanelPaymentCurrencies() {
        let panel = [{
            header: [
                [
                    { caption: 'Form of Payment', header: true, colSpan: 7, align: 'left' }
                ]
            ],
            tableHeader: [
                { caption: 'Type', dataField: 'formOfPaymentTypeCode', align: 'left' },
                { caption: 'Name', dataField: 'formOfPaymentTypeName', align: 'left' },
                { caption: 'Subtype', dataField: 'formOfPaymentCode', align: 'left' },
                { caption: 'Name', dataField: 'formOfPaymentName', align: 'left' },
                { caption: 'Count', dataField: 'dataCount', align: 'right' },
                { caption: 'Currency', dataField: 'ledgerCurrencyCode', align: 'left' },
                { caption: 'Amount', dataField: 'amount', align: 'right' }
            ],
            items: this.resultPaymentCurrencies
        }];
        this.panelExport.push(panel);
    }

    private getDataFromOfPaymentAndPaymentCurrency() {
        let panel = [{
            header: [
                [
                    { caption: 'Form of Payment and Payment Currency', header: true, colSpan: 7, align: 'left' }
                ]
            ],
            tableHeader: [
                { caption: 'Subtype', dataField: 'formOfPaymentCode', colSpan: 3, align: 'left' },
                { caption: 'Name', dataField: 'formOfPaymentName', colSpan: 1, align: 'left' },
                { caption: 'Count', dataField: 'dataCount', colSpan: 1, align: 'right' },
                { caption: 'Currency', dataField: 'ledgerCurrencyCode', colSpan: 1, align: 'left' },
                { caption: 'Amount', dataField: 'amount', colSpan: 1, align: 'right' }
            ],
            tableSubHeader: [
                { caption: 'Date Time', dataField: 'commitDateTime', align: 'left' },
                { caption: 'Legal Name', dataField: 'organisationLegalName', align: 'left' },
                { caption: 'User', dataField: 'commitBy', align: 'left' },
                { caption: 'Amount', dataField: 'ledgerAmount', align: 'right' },
                { caption: 'Currency', dataField: 'ledgerCurrencyCode', align: 'left' },
                { caption: 'Amount', dataField: 'transactionAmount', align: 'right' },
                { caption: 'Transaction', dataField: 'ledgerTransactionCode', align: 'left' }
            ],
            items: this.resultformOfPaymentAndPaymentCurrency
        }];
        this.panelExport.push(panel);
    }

    onPrint(e) {
        this.getDataExport();
        let result = this.panelExport;
        this.printCashbookExportService.print(result);
    }

    private getDataExport() {
        this.getDataPanelGeneral();
        this.getDataPanelTotalByFromOfPayment();
        this.getDataPanelTotalByCurrency();
        this.getDataPanelPaymentCurrencies();
        this.getDataFromOfPaymentAndPaymentCurrency();
    }

    onShowPanelEmail() {
        this.focused = false;

        this.showEmailPanel = !this.showEmailPanel;
        let actionBarHandler = this.actionbarService.getCurrentState();
        actionBarHandler.get(ACTION_STATUS.cancel)?.enable();
        this.actionbarService.updateState(actionBarHandler);
        this.getHtmlForCreateFileAttachEmail();

        let cashbookDocument = {
            organisationId: this.searchCondition.organisationId,
            organisationCallName: this.organisationCallName,
            paymentDateFrom: this.searchCondition.paymentDateFrom,
            paymentDateTo: this.searchCondition.paymentDateTo ?? this.searchCondition.paymentDateFrom,
            formOfPaymentTypeCode: this.searchCondition.formOfPaymentTypeCode,
            paymentCurrency: this.searchCondition.paymentCurrency,
            paymentStatusCode: this.searchCondition.paymentStatusCode
        };
        this.cashbookDocumentCommand = cashbookDocument;
        let otherParameter = {
            fileFromFeature: 'cashbookDocument',
            fileName: 'Cashbook document',
            cashbookDocumentCommand: this.cashbookDocumentCommand
        };
        this.otherParameters = otherParameter;

        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.emailElement, 0);
            this.focusingService.focus(this.emailComponent?.focusingDirective);
        }, this.DELAY_TIME_FOCUS);
    }

    private getHtmlForCreateFileAttachEmail() {
        let fileExport = [{
            fileName: "CashbookDocument",
            htmlContent: null

        }];
        this.file = fileExport;
    }

    get onFocus() {
        return this.focused;
    }

    public onPanelClick(event) {
        if (this.showEmailPanel == false && event == "TopPanel_TabId") {
            if (this.focusing == true) {
                this.focused = true;
            }
        }
        if (event == "EmailPanel_TabId") {
            this.focused = false;
            this.focusingService.focus(this.emailComponent?.focusingDirective);
        }
        this.onActiveIdChange(event);
    }

    public onSendEmail(command: EmailCommandModel) {
        let cashbookEmailCommand: CashbookEmailCommand = {
            languageCode: "en",
            emailCommand: command
        }
        this.loadingSpinner.show();
            this.emailService.sendEmailCashbook(cashbookEmailCommand)
                .subscribe(
                    (response: any) => {
                        this.onCancel();
                        this.loadingSpinner.hide();
                        return response;
                    }
                )
    }

}
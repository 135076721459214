import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { Helper } from "src/app/shared/helper/app.helper";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { SearchConverter } from "../../../price-dimensions/shared";
import { SearchConditionView } from "../../../price-dimensions/shared/views";
import { select2DefaultOptionWithAllowClear } from "../../../shared/search/shared/search-information-configuration";

@Component({
    selector: 'op-price-conditions-search-condition',
    templateUrl: './condition.component.html'
})

export class ConditionComponent extends OopsComponentFormBase implements OnChanges {
    select2DefaultOption = select2DefaultOptionWithAllowClear;
    focusing = false;

    statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))))

    usageTypeCodes$ = this.usageTypeReferenceService.getUsageTypeReferences()
        .pipe(map(res => res.map(r => new Select2Data(r.usageTypeCode, r.usageTypeName))))

    get formValue(): any {
        return this.formGroup.getRawValue();
    }

    @Input() searchConditionView: SearchConditionView;

    @Output() onSearch = new EventEmitter<SearchConditionView>();
    @Output() onClear = new EventEmitter();

    constructor(
        protected helper: Helper,
        private fb: UntypedFormBuilder,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private converter: SearchConverter) {
        super(fb);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['searchConditionView']) {
            this.initForm();
        }
    }

    public initForm() {
        let formGroup = this.converter.toFormGroup(this.searchConditionView);
        if (formGroup) {
            this.formGroup = formGroup;
        }
    }

    clearCondition() {
        this.onClear.emit();
    }

    doSearch() {
        let view = new SearchConditionView(this.formValue.usageTypeCode);
        view.priceCode = this.formValue.priceCode;
        view.priceName = this.formValue.priceName;
        view.status = this.formValue.status;
        this.onSearch.emit(view);
    }

    public onUsageTypeCodeChange(value: string | string[]) {
        let usageTypeCode = value instanceof Array ? value[0] : value;
        this.formGroup.get('usageTypeCode').patchValue(usageTypeCode);
    }

    public onStatusChange(value: string | string[]) {
        let status = value instanceof Array ? value[0] : value;
        this.formGroup.get('status').patchValue(status);
    }
}
import { Injectable } from '@angular/core';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Injectable()
export class MapperService {
    constructor(private dateConverterService: DateConverterService) { }

    getDateTime(date) {
        if (!date) {
            return "NULL";
        }
        return this.dateConverterService.convertDateTime(date);
    }
}
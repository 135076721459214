import { AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, RefreshButtonModel, SaveButtonModel, CancelButtonModel, DeleteButtonModel } from 'src/app/shared/ui/actionbar/models';
import { takeUntil } from "rxjs/operators";
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityMenuItems } from './security-menu-items';
import { ActionService } from 'src/app/core/utils/action.service';
import { OopsCategory } from 'src/app/core/components/categories';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { LogCommitService } from 'src/app/core/services/system-services';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'op-security-functions',
    templateUrl: './security-functions.component.html',
    providers: [ActionbarService],
})
export class SecurityFunctionsComponent implements AfterViewInit, OnInit {
    private readonly FUCNTION_TABID = 'FUNCTION';
    private readonly SEARCH_TABID = 'SEARCH';
    private readonly SECURITY_GROUP_CODE = 'SECURITYGROUP';
    private readonly SECURITY_CODE_CODE = 'SECURITYCODE';

    private readonly NON_SECURITY_FUNCTIONS = ['AUDIT', 'REPORT'];

    actionBarHandler = new ActionBarHandlerModel(new NewButtonModel(), new RefreshButtonModel());
    private unsubscribe$ = new Subject();
    public functionsItems: OopsCategory[] = SecurityMenuItems

    public selectedTab = this.FUCNTION_TABID;
    public selectedItem: OopsCategory;

    public securities: SecurityGroupSecurityModel[];
    
    constructor(private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private changeDetector: ChangeDetectorRef,
        private logCommitService: LogCommitService,
        private securityGroupService: SecurityGroupService,
        private dateConverterService: DateConverterService) {
            this.getSecurityFunctionData();
        }

    ngOnInit(): void {
        let param = this.navigationService.getParams();
        this.selectedTab = param?.selectedTab ?? this.FUCNTION_TABID;
    }

    ngAfterViewInit(): void {
        this.addAction();
        this.setupActionBar();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setupActionBar() {
        switch (this.selectedTab) {
            case this.SEARCH_TABID:
                this.setupSearchActionBar();
                break;
            default:
                this.setupDefaultActionBar();
                break;
        }
    }

    setupDefaultActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).disable();
        this.actionbarService.updateState(this.actionBarHandler);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.new:
                this.new();
            default:
                break;
        }
    }

    back() {
        if (this.selectedTab == this.FUCNTION_TABID) {
            this.navigationService.navigate('main/settings', 'Setting Management');
        } else {
            this.selectedTab = this.FUCNTION_TABID;
            this.onActiveIdChange(this.FUCNTION_TABID);
            this.onSelectedItemChange(null);
        }
    }

    addAction() {
        let actionCode = this.selectedTab == this.SEARCH_TABID && this.selectedItem ? this.SEARCH_TABID : null;
        this.actionService.add(actionCode, null, null);
    }

    onSelectedItemChange(item: OopsCategory) {
        if (!this.isUnavilableItem(item?.url)) {
            this.enableNewButton();
            this.selectedItem = item;
        } else {
            this.disableNewButton();
            this.selectedItem = null;
        }
        this.changeDetector.detectChanges();
    }

    isUnavilableItem(itemUrl: string) {
        return itemUrl == 'AUDIT' || itemUrl == 'REPORT';
    }

    onSearchCancel() {
        this.selectedTab = this.FUCNTION_TABID;
    }

    onActiveIdChange(activeId: string) {
        switch (activeId) {
            case this.FUCNTION_TABID:
                this.selectedItem = null;
                break;
        }
        this.addAction();
        this.setupActionBar();
    }

    enableNewButton() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable();
        this.actionbarService.updateState(this.actionBarHandler);
    }

    disableNewButton() {
        this.actionBarHandler.get(ACTION_STATUS.new).disable();
        this.actionbarService.updateState(this.actionBarHandler);
    }

    new() {
        if (this.selectedItem) {
            let param = {
                newFlag: true,
                moduleSecurity: this.selectedItem.security,
                securities: this.securities
            }
            this.navigationService.navigate('/main/settings/security-'+ this.selectedItem.url.toLowerCase() +'/details', null, false, param);
        } 
    }

    setupSearchActionBar() {
        let searchActionBarHandler = new ActionBarHandlerModel(
            new NewButtonModel(),
            new CopyButtonModel(),
            new SaveButtonModel(),
            new CancelButtonModel(),
            new DeleteButtonModel(),
            new RefreshButtonModel(),
        )
        searchActionBarHandler.get(ACTION_STATUS.save).disable();
        searchActionBarHandler.get(ACTION_STATUS.cancel).disable();
        searchActionBarHandler.get(ACTION_STATUS.delete).disable();
        this.actionbarService.updateState(searchActionBarHandler);
    }

    private getSecurityFunctionData() {
        forkJoin({
                userSecurity: this.securityGroupService.getUserSecurityGroupSecurity(),
                groupLogCommit: this.logCommitService.getByCode(this.SECURITY_GROUP_CODE),
                codeLogCommit: this.logCommitService.getByCode(this.SECURITY_CODE_CODE)
            }).subscribe(({
                userSecurity,
                groupLogCommit,
                codeLogCommit
            }) => {
                this.setupSecurityAndCommit(userSecurity, groupLogCommit, codeLogCommit);
            })
    }

    private setupSecurityAndCommit(userSecurities: SecurityGroupSecurityModel[], groupLogCommit: LogCommitModel, codeLogCommit: LogCommitModel) {
        this.securities = userSecurities.filter(item => item.securityCode == this.SECURITY_GROUP_CODE || item.securityCode == this.SECURITY_CODE_CODE);
        for (var functionItem of this.functionsItems) {
            let security = this.securities.find(item => item.securityCode.includes(functionItem.url));
            if (security) {
                functionItem.security = security;
            }
            this.setupCommitDetail(functionItem, groupLogCommit, codeLogCommit);
        }
        this.functionsItems = this.functionsItems.filter(item => item.security || this.NON_SECURITY_FUNCTIONS.includes(item.url));
        this.changeDetector.detectChanges();
    }

    private setupCommitDetail(functionItem: OopsCategory, groupLogCommit: LogCommitModel, codeLogCommit: LogCommitModel) {
        switch(functionItem.url) {
            case 'GROUP':
                this.assignCommitDetail(functionItem, groupLogCommit);
                break;
            case 'CODE':
                this.assignCommitDetail(functionItem, codeLogCommit);
                break;
        }
    }

    private assignCommitDetail(functionItem: OopsCategory, logCommit: LogCommitModel) {
        functionItem.modifiedDateTime = this.dateConverterService.convertDateTime24(logCommit.commitDateTime);
        functionItem.modifiedBy = logCommit.commitByName;
    }
}

import { Injectable } from '@angular/core';

import { RestrictionConstant, AttributeRuleConstant } from '../constants/';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductRestrictionCommandView } from '../views/restriction-command.view';
import { RestrictionView } from 'src/app/core/components/rules-config/shared/views/restriction.view';
import {
    ProductRestrictionLocationCommandModel, ProductRestrictionLocationViewModel, ProductRestrictionProductCommandModel,
    ProductRestrictionProductNumberCommandModel, ProductRestrictionProductNumberViewModel,
    ProductRestrictionProductViewModel, ProductRestrictionRouteCommandModel,
    ProductRestrictionRouteViewModel,
    ProductRestrictionVehicleViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { TransportSearchConditionView } from 'src/app/modules/product-management/product-categories-content/transport/transport-content/transport-search/shared/transport-search-condition.view';
import { ProductRestrictionVehicleCommandModel } from 'src/app/core/models/product-model/product-base-model/product-restriction/product-restriction-vehicle-command.model';

@Injectable()
export class RestrictionMapperService {

    public productRestrictionFormToCommandViews(forms): ProductRestrictionCommandView[] {
        let commandViews: ProductRestrictionCommandView[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    switch (ctl.value.type) {
                        case RestrictionConstant.TYPE_PRODUCTCODE: {
                            commandViews.push(this.productRestrictionProductNumberCommand(i, ctl.value));
                            break;
                        }
                        case RestrictionConstant.TYPE_PRODUCTTYPE: {
                            commandViews.push(this.productRestrictionProductCommand(i, ctl.value))
                            break;
                        }
                        case RestrictionConstant.TYPE_ROUTE: {
                            commandViews.push(this.productRestrictionRouteCommand(i, ctl.value));
                            break;
                        }
                        case RestrictionConstant.TYPE_LOCATION: {
                            switch (ctl.value.locationPointAreas) {
                                case RestrictionConstant.CODE_AREA_REGION: {
                                    for (let regionCode of ctl.value.locationPointValueRegion) {
                                        commandViews.push(this.productRestrictionLocationRegion(i, ctl.value, regionCode));
                                    }
                                    break;
                                }
                                case RestrictionConstant.CODE_AREA_COUNTRY: {
                                    for (let countryCode of ctl.value.locationPointValueCountry) {
                                        commandViews.push(this.productRestrictionLocationCountry(i, ctl.value, countryCode));
                                    }
                                    break;
                                }
                                case RestrictionConstant.CODE_AREA_AIRPORT: {
                                    for (let locationId of ctl.value.locationPointValueAirport) {
                                        commandViews.push(this.productRestrictionLocationAirport(i, ctl.value, locationId));
                                    }
                                    break;
                                }
                                case RestrictionConstant.CODE_AREA_GROUP: {
                                    for (let locationGroupId of ctl.value.locationPointValueGroup) {
                                        commandViews.push(this.productRestrictionLocationGroup(i, ctl.value, locationGroupId));
                                    }
                                    break;
                                }
                            }
                            break;
                        }
                        case RestrictionConstant.TYPE_VEHICLE: {
                            commandViews.push(this.productRestrictionVehicleCommand(i, ctl.value));
                            break;
                        }
                    }
                }
            }
        }
        return commandViews;
    }

    private productRestrictionProductCommand(index: number, value): ProductRestrictionCommandView {
        let model = new ProductRestrictionProductCommandModel();
        if (value.productRestrictionProductId) {
            model.productRestrictionProductId = value.productRestrictionProductId;
        }
        model.restrictedProductId = null;
        model.productCategoryCode = value.productCat;
        model.productGroupCode = value.productGroup;
        model.productTypeCode = value.productGroupType;
        model.excludeFlag = this.assignExcludeFlag(value.operatorProductType);
        model.displaySequence = index;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionProductCommand = model;
        return commandView;
    }

    private productRestrictionProductNumberCommand(index: number, value): ProductRestrictionCommandView {
        let model = new ProductRestrictionProductNumberCommandModel();
        if (value.productRestrictionProductNumberId) {
            model.productRestrictionProductNumberId = value.productRestrictionProductNumberId;
        }
        model.productNumberTypeCode = value.productCodeType;
        model.productNumber = value.productCodeValue;
        model.providerId = value.provider;
        model.supplierId = value.supplier;
        model.excludeFlag = this.assignExcludeFlag(value.operatorProductCode);
        model.displaySequence = index;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionProductNumberCommand = model;
        return commandView;
    }

    private productRestrictionVehicleCommand(index: number, value): ProductRestrictionCommandView {
        let model: ProductRestrictionVehicleCommandModel = {
            productRestrictionVehicleId: value.productRestrictionVehicleId ?? null,
            vehicleGroupCode: value.vehicleGroupCode,
            vehicleTypeCode: value.vehicleTypeCode,
            displaySequence: index,
            excludeFlag: this.assignExcludeFlag(value.operatorVehicle),
            vehicleCompositionId: value.vehicleCompositionId ?? null
        }

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionVehicleCommand = model;
        return commandView;
    }

    private productRestrictionRouteCommand(index: number, value): ProductRestrictionCommandView {
        let model = new ProductRestrictionRouteCommandModel();
        if (value.productRestrictionRouteId) {
            model.productRestrictionRouteId = value.productRestrictionRouteId;
        }
        model.originLocationId = value.originRoute;
        model.destinationLocationId = value.destinationRoute;
        model.excludeFlag = this.assignExcludeFlag(value.operatorRoute);
        model.displaySequence = index;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionRouteCommand = model;
        return commandView;
    }

    private productRestrictionLocationRegion(index: number, value, regionCode): ProductRestrictionCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, regionCode);
        }
        model.productLocationTypeCode = value.locationPoint;
        model.productLocationPointCode = value.locationPointODs;
        model.excludeFlag = this.assignExcludeFlag(value.locationPointOperator);
        model.displaySequence = index;
        model.regionCode = regionCode;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationCountry(index: number, value, countryCode): ProductRestrictionCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, countryCode);
        }
        model.productLocationTypeCode = value.locationPoint;
        model.productLocationPointCode = value.locationPointODs;
        model.excludeFlag = this.assignExcludeFlag(value.locationPointOperator);
        model.displaySequence = index;
        model.countryCode = countryCode;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationAirport(index: number, value, locationId): ProductRestrictionCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationId);
        }
        model.productLocationTypeCode = value.locationPoint;
        model.productLocationPointCode = value.locationPointODs;
        model.excludeFlag = this.assignExcludeFlag(value.locationPointOperator);
        model.displaySequence = index;
        model.locationId = locationId;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationGroup(index: number, value, locationGroupId): ProductRestrictionCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationGroupId);
        }
        model.productLocationTypeCode = value.locationPoint;
        model.productLocationPointCode = value.locationPointODs;
        model.excludeFlag = this.assignExcludeFlag(value.locationPointOperator);
        model.displaySequence = index;
        model.locationGroupId = locationGroupId;

        let commandView = new ProductRestrictionCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    public productRestrictionProductCommandModels(views: ProductRestrictionCommandView[]): ProductRestrictionProductCommandModel[] {
        let models: ProductRestrictionProductCommandModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == RestrictionConstant.TYPE_PRODUCTTYPE);
            for (let view of viewFilters) {
                if (view.productRestrictionProductCommand) {
                    models.push(view.productRestrictionProductCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionProductNumberCommandModels(views: ProductRestrictionCommandView[]): ProductRestrictionProductNumberCommandModel[] {
        let models: ProductRestrictionProductNumberCommandModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == RestrictionConstant.TYPE_PRODUCTCODE);
            for (let view of viewFilters) {
                if (view.productRestrictionProductNumberCommand) {
                    models.push(view.productRestrictionProductNumberCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionRouteCommandModels(views: ProductRestrictionCommandView[]): ProductRestrictionRouteCommandModel[] {
        let models: ProductRestrictionRouteCommandModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == RestrictionConstant.TYPE_ROUTE);
            for (let view of viewFilters) {
                if (view.productRestrictionRouteCommand) {
                    models.push(view.productRestrictionRouteCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionLocationCommandModels(views: ProductRestrictionCommandView[]): ProductRestrictionLocationCommandModel[] {
        let models: ProductRestrictionLocationCommandModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == RestrictionConstant.TYPE_LOCATION);
            for (let view of viewFilters) {
                if (view.productRestrictionLocationCommand) {
                    models.push(view.productRestrictionLocationCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionVehicleCommandModels(views: ProductRestrictionCommandView[]): ProductRestrictionVehicleCommandModel[] {
        let models = new Array<ProductRestrictionVehicleCommandModel>();
        if (views?.length) {
            var viewFilters = views.filter(x => x.type == RestrictionConstant.TYPE_VEHICLE);
            for (const view of viewFilters) {
                if (view.productRestrictionVehicleCommand) {
                    models.push(view.productRestrictionVehicleCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionModelsToViews(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[],
        productRestrictionVehicles: ProductRestrictionVehicleViewModel[],
        inherit: boolean
    ): RestrictionView[] {

        let restrictionViews: RestrictionView[] = new Array();

        var productRestrictionProductFilter = productRestrictionProducts.filter(x => x.inherit == inherit);
        for (let productRestrictionProduct of productRestrictionProductFilter) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionProduct.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_PRODUCTTYPE;
            restrictionView.productRestrictionProductView = productRestrictionProduct;
            restrictionViews.push(restrictionView);
        }

        var productRestrictionNumberFilter = productRestrictionProductNumbers.filter(x => x.inherit == inherit);
        for (let productRestrictionProductNumber of productRestrictionNumberFilter) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionProductNumber.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_PRODUCTCODE;
            restrictionView.productRestrictionProductNumberView = productRestrictionProductNumber;
            restrictionViews.push(restrictionView);
        }

        var productRestrictionRouteFilter = productRestrictionRoutes.filter(x => x.inherit == inherit);
        for (let productRestrictionRoute of productRestrictionRouteFilter) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionRoute.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_ROUTE;
            restrictionView.productRestrictionRouteView = productRestrictionRoute;
            restrictionViews.push(restrictionView);
        }

        var filterTypeServices = productRestrictionLocations.filter(x => x.productLocationTypeCode == RestrictionConstant.TYPECODE_LOCATION_RESTRICTION && x.inherit == inherit)
        for (let productRestrictionLocation of filterTypeServices) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionLocation.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_LOCATION;
            restrictionView.productRestrictionLocationView = productRestrictionLocation;
            restrictionViews.push(restrictionView);
        }

        var productRestrictionVehicleFilter = productRestrictionVehicles?.filter(x => x.inherit == inherit) ?? []; 
        for (const productRestrictionVehicle of productRestrictionVehicleFilter) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionVehicle.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_VEHICLE;
            restrictionView.productRestrictionVehicleView = productRestrictionVehicle;
            restrictionViews.push(restrictionView);
        }

        if (!inherit) {
            restrictionViews.sort((a, b) => (a.no < b.no ? -1 : 1));
        }
        return restrictionViews;
    }

    private getExistProductRestrictionLocationId(locationArrayDBId: Select2Data[], value): string {
        if (locationArrayDBId) {
            var filter = locationArrayDBId.filter(x => x.text == value);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
    }

    private getLocationName(locationNameList: any, locationId: string): string {
        if (locationNameList) {
            var filter = locationNameList.filter(x => x.id == locationId);
            if (filter.length > 0) {
                return filter[0].text;
            }
        }
    }

    public attributeRouteToRestrictionLocationCommandViews(routeValues: string[],
        routeIdList: Select2Data[],
        routeNameList: any): ProductRestrictionLocationCommandModel[] {
        let commandViews: ProductRestrictionLocationCommandModel[] = new Array();
        if (routeValues) {
            let i = 0;
            for (let locationId of routeValues) {
                ++i;
                commandViews.push(this.attributeRouteToRestrictionLocationCommandView(i, locationId, routeIdList, routeNameList));
            }
        }
        return commandViews;
    }

    private attributeRouteToRestrictionLocationCommandView(index: number,
        locationId, routeIdList: Select2Data[],
        locationNameList: any): ProductRestrictionLocationCommandModel {
        let model = new ProductRestrictionLocationCommandModel();
        model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(routeIdList, locationId);
        model.productLocationTypeCode = RestrictionConstant.TYPECODE_LOCATION_POINT;
        model.excludeFlag = false;
        model.displaySequence = index;
        model.locationId = locationId;
        model.locationName = this.getLocationName(locationNameList, locationId);
        return model;
    }

    public restrictionModelsAttributeRouteToViews(
        productRestrictionLocations: ProductRestrictionLocationViewModel[],
        inherit: boolean
    ): RestrictionView[] {
        let restrictionViews: RestrictionView[] = new Array();
        var filterTypeServices = productRestrictionLocations.filter(x => x.productLocationTypeCode == RestrictionConstant.TYPECODE_LOCATION_POINT && x.inherit == inherit)
        for (let productRestrictionLocation of filterTypeServices) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionLocation.displaySequence;
            restrictionView.type = RestrictionConstant.TYPE_LOCATION;
            restrictionView.productRestrictionLocationView = productRestrictionLocation;
            restrictionViews.push(restrictionView);
        }
        if (!inherit) {
            restrictionViews.sort((a, b) => (a.no < b.no ? -1 : 1));
        }
        return restrictionViews;
    }

    private assignExcludeFlag(value: string): boolean {
        return (value != RestrictionConstant.OPERATOR_LOCATION_POINT_EQUAL) ? true : false;
    }

    public transportConditionSearchToCommands(models: ProductRestrictionLocationCommandModel[] = new Array(), condition: TransportSearchConditionView): ProductRestrictionLocationCommandModel[] {
        let i = 1;
        if (condition?.departureId) {
            models.push(this.transportConditionSearchToCommand(i, condition.departureId, condition.restrtictionLocationDepartureId));
        }
        ++i;
        if (condition?.arrivalId) {
            models.push(this.transportConditionSearchToCommand(i, condition.arrivalId, condition.restrtictionLocationArrivalId));
        }
        return models;
    }

    private transportConditionSearchToCommand(index: number, locationId: string, productRestrictionLocationId: string): ProductRestrictionLocationCommandModel {
        let model = new ProductRestrictionLocationCommandModel();
        model.productRestrictionLocationId = productRestrictionLocationId
        model.productLocationTypeCode = RestrictionConstant.TYPECODE_LOCATION_POINT;
        model.excludeFlag = false;
        model.displaySequence = index;
        model.locationId = locationId;
        return model;
    }    

    public transportConditionSearchToViews(condition: TransportSearchConditionView): ProductRestrictionLocationViewModel[] {
        let models: ProductRestrictionLocationViewModel[] = new Array();
        let i = 1;
        if (condition?.departureId) {
            models.push(this.transportConditionSearchToView(i, condition.departureId, condition.restrtictionLocationDepartureId, condition?.departureData[0].text));
        }
        ++i;
        if (condition?.arrivalId) {
            models.push(this.transportConditionSearchToView(i, condition.arrivalId, condition.restrtictionLocationArrivalId, condition?.arrivalData[0].text));
        }
        return models;
    }

    private transportConditionSearchToView(index: number, locationId: string, productRestrictionLocationId: string, locationName: string): ProductRestrictionLocationViewModel {
        let model = new ProductRestrictionLocationViewModel();
        model.productRestrictionLocationId = productRestrictionLocationId
        model.productLocationTypeCode = RestrictionConstant.TYPECODE_LOCATION_POINT;
        model.excludeFlag = false;
        model.displaySequence = index;
        model.locationId = locationId;
        model.locationName = locationName;
        return model;
    }  
}
import { Injectable } from '@angular/core';
import { LoadingNotifier } from './loading-notifier'
@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {
    public readonly spinnerName: string = "primarySpinner"
    public loadingNotifier = new LoadingNotifier();

    public show(delay?: number): void {
        this.loadingNotifier.show(this.spinnerName, delay);
    }
    public showSaving(delay?: number): void {
        this.loadingNotifier.showSaving(this.spinnerName, delay);
    }
    public showSending(delay?: number): void {
        this.loadingNotifier.showSending(this.spinnerName, delay);
    }
    public hide(): void {
        this.loadingNotifier.hide(this.spinnerName);
    }
    public saveComplete(): void {
        this.loadingNotifier.saveComplate(this.spinnerName);
    }
}
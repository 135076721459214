import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { select2State } from "./select2-configuration";
import { Select2Data } from "../oops-select2";
import { StateConstant } from "./shared/state.constant";

@Component({
    selector: 'state-color-dropdown',
    templateUrl: './state-color-dropdown.component.html'
})
export class StateColorDropdownComponent implements OnChanges {

    @Input() state: string;
    @Input() disabled: boolean = false;
    @Output() updateState = new EventEmitter<string>();

    public select2DefaultOption = select2State;
    public form = new UntypedFormGroup({
        stateCode: new UntypedFormControl()
    });
    public stateData = new Array<Select2Data>();

    get stateClass(): string {
        if (this.form.controls['stateCode'].value == StateConstant.NONE) {
            return 'none';
        }
        if (this.form.controls['stateCode'].value == StateConstant.DRAFT) {
            return 'draft';
        }
        if (this.form.controls['stateCode'].value == StateConstant.FINAL) {
            return 'final';
        }
    }
    get stateCode(): string | string[] {
        return this.form.controls['stateCode'].value;
    }
    set stateCode(value: string | string[]) {
        this.form.controls['stateCode'].patchValue(value);
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["state"]) {
            if (this.state == StateConstant.NONE) {
                this.getStateData(false);
            } else {
                this.getStateData();
            }
            this.form.controls['stateCode'].patchValue(this.state);
        }
        if (changes["disabled"]) {
            if (this.disabled) {
                this.form.controls['stateCode'].disable();
            } else {
                this.form.controls['stateCode'].enable();
            }
        }
    }

    private getStateData(all: boolean = true) {
        this.stateData = [];
        this.stateData.push(new Select2Data(StateConstant.NONE, "(None)"));
        if (all) {
            this.stateData.push(new Select2Data(StateConstant.DRAFT, "Draft"));
        }
        this.stateData.push(new Select2Data(StateConstant.FINAL, "Final"));
    }

    public changeStatus(e) {
        this.form.controls['stateCode'].patchValue(e);
        this.state = e;
        this.changeDetectorRef.detectChanges();
        this.updateState.emit(e);
    }
}
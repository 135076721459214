import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";

import { cloneDeep } from "lodash";
import { LanguageView } from "./shared/language-view";
import { LanguageDetailDirective } from "./detail/language.directive";
import { InsightLanguageDetailComponent } from "./detail/language-detail.component";
import { InsightLanguageTableComponent } from "./table/language-table.component";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-language',
    templateUrl: './insight-detail-language.component.html'
})

export class InsightDetailLanguageComponent {
    @Input() languageReferences: LanguageReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isLanguagesEmpty: boolean = true;
    public isMoreThanOneLanguages: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public language = {} as LanguageView;
    public languages: LanguageView[] = new Array();
    public previousLanguages: LanguageView[] = new Array();

    public focusing: boolean = false;
    public focused: boolean = false;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @ViewChild(LanguageDetailDirective) languageDetailDirective: LanguageDetailDirective;
    @ViewChild(InsightLanguageDetailComponent) languageDetailComponent: InsightLanguageDetailComponent;
    @ViewChild(InsightLanguageTableComponent) languageTableComponent: InsightLanguageTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newLanguageDetail: InsightLanguageDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerLanguages: LanguageView[]) {
        this.clearData();
        if (passengerLanguages && passengerLanguages.length > 0) {
            this.fillModelInCase(passengerLanguages);
        }

        this.languages = passengerLanguages;
        this.previousLanguages = passengerLanguages;

        if (this.languages.length > 0) {
            this.isLanguagesEmpty = false;
        }

        if (this.languages.length == 1) {
            this.isMoreThanOneLanguages = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.language = {} as LanguageView;
    }

    private fillModelInCase(languages: LanguageView[]) {
        if (languages.length == 1) {
            this.fillModelSingleRecord(languages[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: LanguageView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.language = view;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneLanguages = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: LanguageView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightLanguageDetailComponent);

        let viewContainerRef = this.languageDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightLanguageDetailComponent = (<InsightLanguageDetailComponent>componentRefs.instance);
        component.languageReferences = this.languageReferences;
         
        if (dataEdit != null) {
            component.insightLanguage = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newLanguageDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightLanguage = this.languageDetailComponent.getInsightLanguage();
        if (insightLanguage) {
            this.savedata(insightLanguage);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewLanguage() {
        this.isApplyBtnClicked = true;
        let insightLanguage = this.newLanguageDetail.getInsightLanguage();
        if (insightLanguage) {
            this.savedata(insightLanguage);
            this.showAdd = true;         
            this.displayAfterSave();
        }
    }

    private savedata(data: LanguageView) {
        if (this.languages?.length) {
            var filterIndex = this.languages.findIndex(x => x.no == data.no);
            if (data.primaryFlag === undefined) {
                data.primaryFlag = false;
            }

            this.updateLanguageViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryFlag = true;
            this.language = data;
            this.languages.push(data);
        }

        this.isLanguagesEmpty = false;
        this.languageTableComponent.dataGrid.instance.refresh();
    }

    private updateLanguageViews(filterIndex: number, data: LanguageView) {
        if (this.languages?.length > 0 && data.primaryFlag) {
            this.setOtherLanguagePrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.languages.length + 1;
            this.languages.push(data);
        }
        else {
            this.languages[filterIndex] = data;
        }
    }

    private setOtherLanguagePrimaryFlag(filterIndex: number) {
        let otherLanguages = this.languages;
        for (let otherLanguage of otherLanguages) {
            if (otherLanguage.no == filterIndex + 1) {
                continue;
            }

            otherLanguage.primaryFlag = false;;
        }

        this.languages = otherLanguages;
    }

    private displayAfterSave() {
        if (this.languages?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.languageDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.languages.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        this.setMoreThanOneLanguages();
        this.deleteDataFromLanguageViews(data);
    }

    getDeleteData(): LanguageView {
        if (this.languages.length > 1) {
            return this.languageTableComponent.selectedItem;
        }

        return this.language;
    }

    private setMoreThanOneLanguages() {
        if (this.languages.length > 1) {
            this.isMoreThanOneLanguages = true;
        } 
        else {
            this.isMoreThanOneLanguages = false;
        }
    }

    private deleteDataFromLanguageViews(data: LanguageView) {
        let views = new Array<LanguageView>();
        if (this.languages != null) {
            views = this.languages;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.languages = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.languages.length == 1) {
            this.languages[0].primaryFlag = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForLanguageDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.languages.length == 0 || this.languages.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForLanguageDetailForm() {
        if (this.languages.length == 1) {
            this.language = this.languages[0];
            this.showAdd = true;
        }
        else {
            this.language = {} as LanguageView;
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.languageTableComponent.selectedItem);
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.languages.length == 0) {
            this.language = {} as LanguageView;
        }

        if (this.languages.length <= 1) {
            this.isMoreThanOneLanguages = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.languageDetailComponent) {
            return this.languageDetailComponent.setProcessing(false);
        }

        if (this.newLanguageDetail) {
            return this.newLanguageDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.languages.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.languages.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.languages.length == 1) {
            let copyLanguage = cloneDeep(this.languages[0]);
            this.openCopyDetailForm(copyLanguage);
            return;
        }

         if (!this.languageTableComponent.selectedItem) {
            return;
        }

        let copyLanguage = cloneDeep(this.languageTableComponent.selectedItem);
        this.openCopyDetailForm(copyLanguage);
    }

    private setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneLanguages = true;
    }

    private setCopyLanguageProperty(copyLanguage: LanguageView) {
        copyLanguage.no = null;
        copyLanguage.primaryFlag = false;
    }

    private openCopyDetailForm(copyLanguage: LanguageView) {
        this.setCopyLanguageProperty(copyLanguage);
        this.openDetail();
        this.loadDetailForm(copyLanguage);
    }

    public validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.languages.length <= 0) {
            return true;
        }

        if (this.languageDetailComponent && this.isApplyBtnClicked) {
            return this.languageDetailComponent.isValidForm();
        }

        if (this.newLanguageDetail && this.isApplyBtnClicked) {
            return this.newLanguageDetail.isValidForm();
        }

        return true;
    }

    public getErrorMessageForm() {
        if (this.languageDetailComponent) {
            return this.languageDetailComponent.getErrorMessageForm();
        }

        if (this.newLanguageDetail) {
            return this.newLanguageDetail.getErrorMessageForm();
        }
    }
}
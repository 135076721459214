import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OperatorModel } from "src/app/core/models/merchandizing-config";

@Injectable()
export class VehicleSetDefaultService {
    public setDefaultOperatorVehicle(f: UntypedFormGroup, operatorVehicle$: BehaviorSubject<OperatorModel[]>, operatorVehicleOption) {
        var filterplaceHolder = operatorVehicle$.value.filter(x => x.operatorCode == "");
        if (filterplaceHolder.length != 0) {
            operatorVehicleOption.placeholder = filterplaceHolder[0].operatorName;
        }
        else {
            if (operatorVehicle$.value.length != 0) {
                f.controls['operatorVehicle'].setValue(operatorVehicle$.value[0].operatorCode);
            }
        }
    }
}
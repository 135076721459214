import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { NumberOnlyModule } from "src/app/shared/ui/forms/inputs/number-only/number-only.module";
import { OopsChoiceValueModule } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { BaggageAllowanceComponent } from "./baggage-allowance.component";

@NgModule({
    declarations: [
        BaggageAllowanceComponent
    ],
    exports: [
        BaggageAllowanceComponent
    ],
    imports: [
        BsDropdownModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        NumberOnlyModule,
        OopsChoiceValueModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
    ],
})
export class BaggageAllowanceModule {

}
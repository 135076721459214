export const PricePriceDimensionConstant = {
    productNumber: 'PRODUCTNUMBER',
    organisation: 'ORGANISATION',
    route: 'ROUTE',
    origin: 'ORIGIN',
    destination: 'DESTINATION',
    serviceDate: 'SERVICEDATE',
    salesDate: 'SALESDATE',
    serviceCategory: 'SERVICECATEGORY',
    salesBucket: 'SALESBUCKET',
    memberLevel: 'MEMBERLEVEL',
    attribute: 'ATTRIBUTE',
    currency: 'CURRENCY',
    unitType: 'UNITTYPE'
}
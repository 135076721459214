import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityGroupReference } from 'src/app/core/models/security-model/security-group-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupTreeModel } from 'src/app/core/models/security-model/security-group-tree.model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { SecurityCodeService } from 'src/app/core/services/system-services/security-code.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { GroupSecurityCodeSettingComponent } from './group-security-code-setting/group-security-code-setting.component';
import { SecurityGroupTreeComponent } from './security-group-tree/security-group-tree.component';
import { SecurityGroupTreeConverter } from './security-group-tree/security-group-tree.converter';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { SecurityGroupCommand } from './shared/security-group-command.model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { SecurityGroupDetailFormComponent } from './security-group-detail-form/security-group-detail-form.component';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel, DeleteButtonModel } from 'src/app/shared/ui/actionbar/models';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { Store } from '@ngrx/store';
import * as NavigationActions from 'src/app/store/navigation/navigation.actions';
import { USER_INFORMATION } from 'src/app/modules/auth/shared/constants/auth.constant';
import { OrganisationTypeComponent } from './organisation-type/organisation-type.component';
import { GroupSecurityCodeTableComponent } from './group-security-code-table/group-security-code-table.component';
import { SecurityGroupOrganisationModel } from 'src/app/core/models/security-model/security-group-organisation.model';

@Component({
    selector: 'op-security-group-detail',
    templateUrl: './security-group-detail.component.html',
    providers: [
        SecurityGroupTreeConverter,
        StringHelperService,
        ActionbarService
    ]
})
export class SecurityGroupDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly groupNodeType = 'GROUP';
    public readonly codeNodeType = 'CODE';

    public readonly codeTabId = 'code';
    public readonly groupTabId = 'groupDetail';
    public readonly organisationTypeId = 'organisationType';

    private unsubscribe$ = new Subject();
    private copy = false;
    private selectedTab: string;
    private navigateFromTab: string;
    private displayPanel: string = this.groupNodeType;
    public securityGroupId;
    public securityGroupDetail$ = new BehaviorSubject<SecurityGroupReference>(null);
    public securityGroupSecurities$ = new BehaviorSubject<SecurityGroupSecurityModel[]>(null);
    public selectedSecurityGroupSecurity$ = new BehaviorSubject<string>(null);
    public securityCodes$ = new BehaviorSubject<SecurityCodeReferenceModel[]>(null);
    public securityGroupTree$ = new BehaviorSubject<TreeNode[]>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public selectedNodeCodeChildName$ = new BehaviorSubject<string[]>(null);
    public securityCodeTablePanelToggler = false;
    public focused = true;
    public activeId = this.groupTabId;
    public selectedTreeNode: TreeNode;
    public removeTreeNode: TreeNode[] = [];
    public newSecurityGroup = false;
    public editSecurityGroupDetails: SecurityGroupReference[] = [];
    public editSecurityGroupSecurity: SecurityGroupSecurityModel[] = [];
    public heightPanelGeneral;
    public paggingView: PagingDataView[];
    public currentIndex: number;
    public securityGroupOrganisations: SecurityGroupOrganisationModel[][] = new Array<SecurityGroupOrganisationModel[]>();
    public organisationTypeInvalid: boolean = false;
    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new DeleteButtonModel(),
        new RefreshButtonModel()
    )

    public moduleSecurity: SecurityGroupSecurityModel;
    public securities: SecurityGroupSecurityModel[];

    @ViewChild('nav') navBar: NgbNav; 
    @ViewChild("panelSecurityGroupTree") organisationTypePanel: ElementRef;
    @ViewChild("panelOrganisationType") securityGroupTreePanel: ElementRef;
    
    @ViewChild(SecurityGroupTreeComponent) treeComponent: SecurityGroupTreeComponent;
    @ViewChild(GroupSecurityCodeSettingComponent) codeSetting: GroupSecurityCodeSettingComponent;
    @ViewChild(SecurityGroupDetailFormComponent) groupDetailForm: SecurityGroupDetailFormComponent;
    @ViewChild(OrganisationTypeComponent) organisationTypeComponent: OrganisationTypeComponent;
    @ViewChild(GroupSecurityCodeTableComponent) groupSecurityCodeTableComponent: GroupSecurityCodeTableComponent;

    constructor(private securityGroupService: SecurityGroupService,
        private securityCodeService: SecurityCodeService,
        private statusReferenceService: StatusReferenceService,
        private securiryGroupTreeConverter: SecurityGroupTreeConverter,
        private loadingSpinnerService: LoadingSpinnerService,
        private stringHelperService: StringHelperService,
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private focusingService: FocusingService,
        private store: Store<any>,
        private changeDetector: ChangeDetectorRef) { 
            this.loadingSpinnerService.show();
            this.getStatusReferences();
            this.getSecurityCode();
        }
    ngAfterViewInit(): void {
        this.setUpComponent();
        this.setupGroupDetail();
        this.setupActionBar();
        this.subscribeFocusPanel();
    }

    private setUpComponent() {
        this.groupDetailForm.focusing = true;
    }
    ngOnInit(): void {
        let param = this.navigationService.getParams();
        this.securityGroupId = param?.id;
        this.newSecurityGroup = param?.newFlag ?? false;
        this.copy = param?.copy ?? false;
        this.navigateFromTab = param?.selectedTab;
        this.paggingView = param?.paggingView ?? [];
        this.currentIndex = param?.currentIndex;
        this.securities = param?.securities;
        this.moduleSecurity = param?.moduleSecurity;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private saveDisable(): boolean {
        if (this.copy) {
            return this.moduleSecurity?.copyFlag;
        }
        if (this.newSecurityGroup) {
            return this.moduleSecurity?.newFlag;
        }
        return this.moduleSecurity?.editFlag;
    }

    setupActionBar() {
        this.setupSecurityActionBar();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    setupSecurityActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).disable(!this.moduleSecurity?.newFlag);
        this.actionBarHandler.get(ACTION_STATUS.copy).disable(!this.moduleSecurity?.copyFlag);
        this.actionBarHandler.get(ACTION_STATUS.save).disable(!this.saveDisable());
        this.actionBarHandler.get(ACTION_STATUS.delete).disable();
        this.actionbarService.updateState(this.actionBarHandler);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.copy:
                this.copy = true;
                this.copyToNew();
                break;
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.cancel:
                this.cancel();
                break;
            case ACTION_STATUS.refresh:
                this.loadingSpinnerService.show();
                this.refresh();
                break;
        }
    }

    public selectedNodeChange(node: TreeNode) {
        this.selectedTreeNode = node;
        this.displayPanel = node.type;
        this.getSelectedNodeType(node);
        
    }

    selectGroupNode(id: string) {
        let index = this.editSecurityGroupDetails.findIndex(item => item.securityGroupId == id);
        if (index != -1) {
            this.securityGroupDetail$.next(this.editSecurityGroupDetails[index]);
            this.organisationTypeComponent.fillSecurityGroupResponse(this.getSecurityGroupOrganisation(id));
        } else {
            this.getSecurityGroupDetail(id);
            this.loadOrganisationType(id);
        }
    }

    private loadOrganisationType(id: string) {
        this.organisationTypeComponent.securityGroupId = id;
        this.organisationTypeComponent.loadSecurityGroupOrganisation();
    }
    public tabActiveIdChange(e) {
        this.activeId = e;
        if (e === this.groupTabId) {
            this.groupTabSelect(this.selectedTreeNode);
        } else if (e === this.organisationTypeId) {
            this.focusOrganisationType();
        } else if (e === this.codeTabId) {
            this.codeTabSelect(this.selectedTreeNode);
            this.focusSecurityCode();
        }
    }

    public displaySelectedPanel(panelCode: string): boolean {
        return !(panelCode === this.displayPanel);
    }

    public getSelectedNodeType(node: TreeNode) {
        this.securityGroupId = node.data;
        if (node.type == this.codeNodeType) {
            this.selectedSecurityGroupSecurity$.next(node.data);
            this.focusSecurityCode()
        } else {
            this.getSelectedNodeTab(node);
        }
    }

    public getSelectedNodeTab(node: TreeNode) {
        if (this.navBar.activeId == this.codeTabId) {
            this.codeTabSelect(node);
        } else {
            this.groupTabSelect(node);
        }
    }

    public groupTabSelect(node: TreeNode) {
        if (node.type == this.codeNodeType) {
            this.focusSecurityCode();
            return;
        }
        this.securityCodeTablePanelToggler = false;
        this.securityGroupId = node.data;
        this.getCodeChildNode();
        this.selectGroupNode(this.securityGroupId);
        this.focusSecurityGroupDetail();
    }

    public codeTabSelect(node: TreeNode) {   
        if (node.type == this.codeNodeType) {
            this.focusSecurityCode();
            return;
        }
        this.securityGroupId = node.data;
        this.securityCodeTablePanelToggler = true;
        this.getCodeChildNode();
        this.focusSecurityCode();
    }

    treeFocus(e) {
        this.focused = e;
    }

    public setupGroupDetail() {
        if (this.securityGroupId != null) {
            this.getSecurityDetail();
        } else {
            this.new();
        }
        this.addAction();
        this.loadingSpinnerService.hide();
    }

    public getSecurityDetail() {
        forkJoin({
            detail: this.securityGroupService.getSecurityGroupReference(this.securityGroupId),
            securityGroupSecurity: this.securityGroupService.getSecurityGroupSecurity(this.securityGroupId),
            tree: this.securityGroupService.getSecurityGroupTree(this.securityGroupId),
            securityCode: this.securityCodeService.getAllSecurityCode()
        }).subscribe(({
            detail,
            securityGroupSecurity,
            tree,
            securityCode
        }) => {
            this.securityGroupDetail$.next(detail);
            this.securityGroupSecurities$.next(securityGroupSecurity);
            this.securityCodes$.next(securityCode);
            this.getTree(tree, this.securityGroupId);

            this.addAction();
            this.loadingSpinnerService.hide();
        },
        () => {
            this.loadingSpinnerService.hide();
        })
    }

    private getTree(tree: SecurityGroupTreeModel, securityGroupId: string) {
        let treeTemp = new Array<TreeNode>();
        treeTemp.push(this.securiryGroupTreeConverter.modelToTreeNode(tree));
        this.selectedNodeChange(treeTemp[0]);
        this.securityGroupTree$.next(treeTemp);
        if (this.copy == true) {
            let treeNode = this.treeComponent.getTreeNodeById(securityGroupId, treeTemp);
            setTimeout(() => {
                this.copyNode(treeNode);
            }, 200)
        } else if (this.newSecurityGroup == true) {
            setTimeout(() => {
                this.treeComponent.newGroupChild();
            }, 200)
        }
        this.loadingSpinnerService.hide();
    }

    public getSecurityGroupDetail(securityGroupId: string) {
        this.loadingSpinnerService.show();
        this.securityGroupService.getSecurityGroupReference(securityGroupId)
            .subscribe(
                (response: SecurityGroupReference) => {
                    this.securityGroupDetail$.next(response);
                    this.addAction();
                    this.loadingSpinnerService.hide();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    public getSecurityGroupSecurity(securityGroupId: string) {
        this.securityGroupService.getSecurityGroupSecurity(securityGroupId)
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {
                    this.securityGroupSecurities$.next(response);
                }
            )
    }

    public getSecurityCode() {
        this.securityCodeService.getAllSecurityCode()
            .subscribe(
                (response: SecurityCodeReferenceModel[]) => {
                    this.securityCodes$.next(response);
                }
            )
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    getCodeChildNode() {
        let currentNode = this.getGroupSelectedNode();
        if (!currentNode) {
            return;
        }
        let codeChildNodeTemp = []
        if (currentNode.children) {
            currentNode.children.forEach(child => {
                if (child.type == this.codeNodeType){
                    codeChildNodeTemp.push(child.label);
                }
            });
        }
        this.selectedNodeCodeChildName$.next(codeChildNodeTemp);
    }

    addChildSecurityCode(newChildren: SecurityCodeReferenceModel[]) {
        let newChildrenWithId = this.treeComponent.addNewChildrenToSelectedNode(newChildren);
        this.addSecurityGroupSecurity(newChildren, newChildrenWithId);
        this.removeFromRemove(newChildrenWithId);
        
    }

    addSecurityGroupSecurity(newChildren: SecurityCodeReferenceModel[], newChildrenWithId: TreeNode[]) {
        this.codeSetting.addNewSecurityGroupSecurity(newChildren, newChildrenWithId, this.getGroupSelectedNode());
    }

    getGroupSelectedNode(): TreeNode {
        if (!this.selectedTreeNode) {
            return null;
        }
        return this.selectedTreeNode.type == this.groupNodeType ? this.selectedTreeNode : this.selectedTreeNode.parent;
    }

    removeSecurityGroupSecurity(node: TreeNode) {
        this.addToRemoveList(node);
    }

    addToRemoveList(node: TreeNode) {
        this.removeTreeNode.push(node);
        if (node.type == this.groupNodeType) {
            this.removeSecurityGroup(node.data);
        }
        if (node?.children) {
            node.children.forEach(item => {
                this.addToRemoveList(item);
            })
        }
    }

    removeSecurityGroup(securityGroupId: string) {
        this.removeSecurityGroupOrganisation(securityGroupId);
        let removeIndex = this.editSecurityGroupDetails.findIndex(item => item.securityGroupId == securityGroupId);
        if (removeIndex === -1) {
            return;
        }
        this.editSecurityGroupDetails.splice(removeIndex, 1);
    }

    removeFromRemove(nodes: TreeNode[]) {
        nodes.forEach(node => {
            let index = this.removeTreeNode.indexOf(node);
            if (index != -1) {
                this.removeTreeNode.splice(index, 1);
            }
        })
    }

    removeSecurityGroupSecurityFromCommand(securityGroupSecurityCommand: SecurityGroupSecurityModel[]) {
        this.removeTreeNode.forEach(node => {
            let index = securityGroupSecurityCommand.findIndex(item => item.securityGroupSecurityId == node.data);
            if (index != -1) {
                securityGroupSecurityCommand.splice(index, 1);
            }
        })
        return securityGroupSecurityCommand;
    }

    fillCommand(): SecurityGroupCommand {
        if (!this.validateSecurityGroup()) {
            return null;
        }
        let command: SecurityGroupCommand = {
            securityGroupId: this.securityGroupId,
            securityGroupDetail: this.editSecurityGroupDetails ?? [],
            securityGroupSecurity: this.removeSecurityGroupSecurityFromCommand(this.editSecurityGroupSecurity ?? []),
            removeSecurityGroupSecurity: this.removeTreeNode.filter(item => item.type == this.codeNodeType).map(item => item.data),
            newSecurityGroupHierarchy: this.treeComponent.getNewHierarchy(),
            removeSecurityGroup: this.removeTreeNode.filter(item => item.type == this.groupNodeType).map(item => item.data),
            securityGroupOrganisations: this.securityGroupOrganisations?.flat()
        }
        
        return command;
    }

    save() {
        this.tabActiveIdChange(this.groupTabId);
        if (!this.editSecurityGroupDetails || this.editSecurityGroupDetails?.length == 0) {
            this.groupDetailForm.apply();
        }
        let command = this.fillCommand();
        if (!command) {
            return;
        }
        if (this.newSecurityGroup == true) {
            this.addSecurityGroup(command);
        } else {
            this.editSecurityGroup(command);
        }
    }

    private validSecurityGroupOrganisation(securityGroupOrg: SecurityGroupOrganisationModel[]) {
        let validOrganisationType = !(!securityGroupOrg || securityGroupOrg?.length == 0);
        if (!validOrganisationType) {
            this.organisationTypeInvalid = true;
            this.organisationTypeComponent.typeInvalid = true;
            this.focusOrganisationType();
        }
        return validOrganisationType;
    }

    addSecurityGroup(command: SecurityGroupCommand) {
        this.loadingSpinnerService.showSaving();
        this.securityGroupService.addSecurityGroupCommand(command)
            .subscribe(
                () => {
                    this.loadingSpinnerService.saveComplete();
                    this.newSecurityGroup = false;
                },
                () => {
                    this.loadingSpinnerService.hide();
                },
                () => {
                    this.setupGroupDetail();
                    this.editSecurityGroupDetails = [];
                    this.editSecurityGroupSecurity = [];
                    this.treeComponent.clearNewHeirarchy();
                    this.setupSecurityActionBar();
                }
            )
    }

    editSecurityGroup(command: SecurityGroupCommand) {
        this.loadingSpinnerService.showSaving();
        this.securityGroupService.editSecurityGroupCommand(command)
            .subscribe(
                () => {
                    this.loadingSpinnerService.saveComplete();
                },
                () => {
                    this.loadingSpinnerService.hide();
                },
                () => {
                    this.setupGroupDetail();
                    this.editSecurityGroupDetails = [];
                    this.editSecurityGroupSecurity = [];
                    this.removeTreeNode = [];
                    this.treeComponent.clearNewHeirarchy();
                    this.checkSidebarReload(this.securityGroupDetail$.value?.securityGroupName);
                    this.setupSecurityActionBar();
                }
            )
    }

    updateGroupDetailList(newGroupDetail: SecurityGroupReference) {
        this.addToEditSecurityGroupDetail(newGroupDetail);
    }

    updateSecurityGroupSecurity(newSecurityGroupSecurity: SecurityGroupSecurityModel) {
        this.addToEditSecurityGroupSecurity(newSecurityGroupSecurity);
    }

    getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height;
    }

    copyNode(node: TreeNode){
        if (!node?.parent) {
            this.copyToNew();
            return;
        }
        let newNode = this.copyGroupNode(node);
        this.treeComponent.addGroupChild(newNode);
        this.setupSecurityActionBar();
    }

    addNode(node: TreeNode) {
        let newDetail = this.securiryGroupTreeConverter.TreeNodeToSecurityGroupDetail(node);
        node = this.securiryGroupTreeConverter.addIcon(node);
        this.addToEditSecurityGroupDetail(newDetail);
        this.treeComponent.addNewGroupChild(node);
        this.organisationTypeComponent.delete();
    }

    copyGroupNode(node: TreeNode): TreeNode {
        let newNode = this.createNewNodeFromCopy(node);
        if (newNode.type == this.groupNodeType) {
            newNode.label = newNode.label + ' - copy';
            this.addCopyGroupNodeDetail(newNode);
            if (newNode.children) {
                let childrenTemp = this.copyChildNode(newNode, newNode.children);
                newNode.children = childrenTemp;
            }
        } else {
            this.addSecurityGroupSecurityToList(node, newNode);
        }
        return newNode;
    }
    
    createNewNodeFromCopy(node: TreeNode): TreeNode {
        let newNode = Object.assign({}, node);
        newNode.data = this.stringHelperService.NewGuid();
        return newNode;
    }

    copyChildNode(node: TreeNode, children: TreeNode[]): TreeNode[] {
        let childrenTemp: TreeNode[] = [];
        children.forEach(child => {
            child.parent = node;
            childrenTemp.push(this.copyGroupNode(Object.assign({}, child)));
        })    
        return childrenTemp;
    }

    addCopyGroupNodeDetail(node: TreeNode) {
        let newDetail = this.securiryGroupTreeConverter.TreeNodeToSecurityGroupDetail(node);
        this.editSecurityGroupDetails.push(newDetail);
        this.treeComponent.addNewHierarchy(node);
    }

    addSecurityGroupSecurityToList(node: TreeNode, newNode: TreeNode) {
        let securityGroupSecurityList = this.securityGroupSecurities$.getValue();
        let index = securityGroupSecurityList.findIndex(item => item?.securityGroupSecurityId == node?.data);
        let newDetail = Object.assign({},securityGroupSecurityList[index]);

        newDetail.securityGroupSecurityId = newNode.data;
        newDetail.securityGroupId = newNode.parent?.data;
        
        securityGroupSecurityList.push(newDetail);
        this.addToEditSecurityGroupSecurity(newDetail);
        this.securityGroupSecurities$.next(securityGroupSecurityList);
    }

    addToEditSecurityGroupSecurity(newDetail: SecurityGroupSecurityModel) {
        let index = this.editSecurityGroupSecurity.findIndex(item => item.securityGroupSecurityId == newDetail.securityGroupSecurityId);
        if (index == -1) {
            this.editSecurityGroupSecurity.push(newDetail);
        } else {
            this.editSecurityGroupSecurity[index] = newDetail;
        }

    }

    addToEditSecurityGroupDetail(newDetail: SecurityGroupReference) {
        let index = this.editSecurityGroupDetails.findIndex(item => item.securityGroupId == newDetail.securityGroupId);
        if (index === -1) {
            this.editSecurityGroupDetails.push(newDetail);
        } else {
            this.editSecurityGroupDetails[index] = newDetail;
        }
    }

    renameGroupNode(newName: string) {
        this.treeComponent.renameNode(newName);
    }

    copyToNew() {
        let tree = this.treeComponent.getTree();
        let firstNode = true;
        let root: TreeNode;
        let treeTemp: TreeNode[] = [];
        let temSecurityGroupOrganisations = this.getSecurityGroupOrganisation(this.securityGroupId)
        this.clear();
        tree?.forEach(node => {
            node = this.copyGroupNode(node);
            treeTemp.push(node);
            if (firstNode === true) {
                root = node;
                firstNode = false;
            }
        })
        this.setupSecurityActionBar();
        this.organisationTypeComponent.fillSecurityGroupResponse(temSecurityGroupOrganisations);
        this.securityGroupTree$.next(treeTemp);
        this.newSecurityGroup = true;
        this.securityGroupId = root?.data;
        this.copy = false;
    }

    clear() {
        this.securityGroupDetail$.next(null);
        this.selectedSecurityGroupSecurity$.next(null);
        this.selectedNodeCodeChildName$.next(null);
        this.securityGroupTree$.next([]);
        this.selectedTreeNode = null;
        this.removeTreeNode = [];
        this.editSecurityGroupDetails = [];
        this.editSecurityGroupSecurity = [];
        this.securityGroupId = null;
        this.newSecurityGroup = true;
        this.treeComponent.clearTree();
        this.treeComponent.clearNewHeirarchy();
        this.organisationTypeComponent.delete();
    }

    new() {
        this.clear();
        this.treeComponent.newTree();
        this.setupSecurityActionBar();
    }

    newTree(tree: TreeNode[]) {
        let rootNode = tree[0];
        rootNode.parent = null;
        this.addNode(rootNode);
        this.securityGroupTree$.next(tree);
    }

    back() {
        let param = {
            selectedTab: this.navigateFromTab,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigationService.navigate('main/settings/security-group', null, false, param);
    }

    addAction() {
        if (!this.newSecurityGroup) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.securityGroupId, this.securityGroupDetail$.value?.securityGroupName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    cancel() {
        let param = {
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigationService.navigate('main/settings/security', null, false, param);
    }

    refresh() {
        let id = this.getRefreshId();
        this.clear();
        this.securityGroupId = id;
        this.newSecurityGroup = false;
        this.setupGroupDetail();
        this.organisationTypeComponent.loadSecurityGroupOrganisation();
    }

    private getRefreshId(): string {
        if (this.selectedTreeNode.type == this.groupNodeType) {
            return this.securityGroupId;
        }
        let parentNode = this.selectedTreeNode.parent;
        return parentNode.data;
    }

    validateSecurityGroup() {
        if (this.groupDetailForm) {
            return this.validateGroupDetailForm() && this.validateGroupDatails();
        }
        return true;
    }

    validateGroupDetailForm() {
        this.focusSecurityGroupDetail();
        this.groupDetailForm.startProcessing();
        if (this.groupDetailForm.validForm() && !this.isNewAndEmpty() && this.organisationTypeComponent.validateOrganisationType()) {
            this.groupDetailForm.completeProcessing();
            return true;
        }
        this.organisationTypeComponent.validateOrganisationType();
        return false;
    }

    validateGroupDatails(): boolean {
        if (!this.editSecurityGroupDetails) {
            return true;
        }
        for (let group of this.editSecurityGroupDetails) {
            let securityGroupOrg = this.getSecurityGroupOrganisation(group.securityGroupId);
            if (!(this.checkRequiredField(group) && this.validSecurityGroupOrganisation(securityGroupOrg))) {
                this.alertInvalidGroupDetail(group, securityGroupOrg);
                return false;
            }
        }
        return true;
    }

    private checkRequiredField(group: SecurityGroupReference): boolean {
        return !!group.securityGroupName;
    }

    isNewAndEmpty() {
        return this.newSecurityGroup == true && this.editSecurityGroupDetails?.length == 0;
    }

    rootNodeRemoved() {
        let rootNode = this.treeComponent.securityGroupTree[0];
        this.addToRemoveList(rootNode);
        let removeTreeNode = Object.assign({}, this.removeTreeNode);

        this.clear();
        this.treeComponent.newTree();
        this.removeTreeNode = removeTreeNode;
    }

    onPageChange(id: string) {
        this.clear();
        this.securityGroupId = id
        this.newSecurityGroup = false;
        this.copy = false;
        this.setupGroupDetail();
    }

    checkSidebarReload(editedName: string) {
        let userSecurityGroupName = JSON.parse(sessionStorage.getItem(USER_INFORMATION)).securityGroupName;
        if (userSecurityGroupName == editedName) {
            this.store.dispatch(NavigationActions.filterBySecurity());
        }
    }
    private focusOrganisationType() {
        this.focusingService.focus(this.organisationTypeComponent.focusingDirective);
    }
    private focusSecurityGroupDetail() {
        this.focusingService.focus(this.groupDetailForm.focusingDirective);
    }
    private focusSecurityCodeTable() {
        this.focusingService.focus(this.groupSecurityCodeTableComponent.focusingDirective);
    }
    private focusSecurityCodeSetting() {
        this.focusingService.focus(this.codeSetting.focusingDirective);
    }

    private focusSecurityCode() {
        if (this.selectedTreeNode.type == this.groupNodeType) {
            this.focusSecurityCodeTable();
            return;
        }
        this.focusSecurityCodeSetting();
    }
    private subscribeFocusPanel() {

        this.groupDetailForm.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.groupTabId;
                    }
                }
            );

        this.groupSecurityCodeTableComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.codeTabId;
                    }
                }
            );

        this.organisationTypeComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.organisationTypeId;
                    }
                }
            );
    }

    public onOrganisationTypeChanged(values: string[]) {
        let securityGroupOrganisations = new Array<SecurityGroupOrganisationModel>();
        values?.forEach(value => {
            securityGroupOrganisations.push({
                securityGroupOrganisationId: null,
                securityGroupId: this.securityGroupId,
                securityGroupName: null,
                organisationTypeCode: value,
                commitBy: null,
                commitByName: null,
                commitDateTime: null
            });
        });
        if (securityGroupOrganisations.length == 0) {
            this.removeSecurityGroupOrganisation(this.securityGroupId);
        }
        this.fillSecurityGroupOrganisation(securityGroupOrganisations);
    }

    private fillSecurityGroupOrganisation(securityGroprOrganisationTypes: SecurityGroupOrganisationModel[]) {
        let index = this.securityGroupOrganisations?.findIndex(group => group?.find(item => item?.securityGroupId == this.securityGroupId));
        if (index >= 0) {
            this.securityGroupOrganisations[index] = securityGroprOrganisationTypes;
            return;
        }
        this.securityGroupOrganisations.push(securityGroprOrganisationTypes);
    }

    private removeSecurityGroupOrganisation(securityGroupId: string) {
        let index = this.securityGroupOrganisations?.findIndex(group => group?.find(item => item?.securityGroupId == securityGroupId));
        if (index !== -1 && this.securityGroupOrganisations?.length > 1) {
            this.securityGroupOrganisations.splice(index, 1);
        }
    }

    private getSecurityGroupOrganisation(securityGroupId: string) {
        let index = this.securityGroupOrganisations?.findIndex(group => group?.find(item => item?.securityGroupId == securityGroupId));
        if (index >= 0) {
            return this.securityGroupOrganisations[index];
        }
        return null
    }

    public alertInvalidGroupDetail(group: SecurityGroupReference, organisation: SecurityGroupOrganisationModel[]) {
        let treeNode = this.treeComponent.getTreeNodeById(group.securityGroupId, this.treeComponent.securityGroupTree);
        this.treeComponent.onSelectionChange(treeNode);
        this.groupDetailForm.startProcessing();
        this.validSecurityGroupOrganisation(organisation);
        this.changeDetector.detectChanges();
    }
}

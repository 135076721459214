import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api/treenode';
import { expand } from 'rxjs/operators';
import { PriceRuleModel, PriceRuleTreeModel } from 'src/app/core/models/pricing-model';

@Injectable()
export class TreeConverter {
    private readonly folderOpenIcon = 'tree-view-folder-open-icon';
    private readonly folderCloseIcon = 'tree-view-folder-close-icon';
    private readonly fileIcon = 'tree-view-file-icon';

    public toTreeNodes(models: PriceRuleTreeModel[]): TreeNode[] {
        let nodes = new Array<TreeNode>();
        for (let model of models) {
            nodes.push(this.toTreeNode(model))
        }
        return nodes;
    }

    public toTreeNode(model: PriceRuleTreeModel): TreeNode {
        let hasChild = model.children?.length > 0 ? true : false;
        let treeNode: TreeNode = {
            label: model.priceRuleName,
            data: model.priceRuleId,
            expandedIcon: this.getIconClass(hasChild, true),
            collapsedIcon: this.getIconClass(hasChild, false)
        }
        if (!hasChild) {
            return treeNode;
        }
        treeNode.children = [];
        for (let child of model.children) {
            treeNode.children.push(this.toTreeNode(child))
        }
        return treeNode;
    }

    public modelToTreeNode(model: PriceRuleModel): TreeNode {
        let hasChild = model.children?.length > 0 ? true : false;
        let treeNode: TreeNode = {
            label: model.priceRuleName,
            data: model.id,
            expandedIcon: this.getIconClass(hasChild, true),
            collapsedIcon: this.getIconClass(hasChild, false)
        }
        if (!hasChild) {
            return treeNode;
        }
        treeNode.children = [];
        for (let child of model.children) {
            treeNode.children.push(this.modelToTreeNode(child))
        }
        return treeNode;
    }

    public getIconClass(hasChild: boolean, expanded: boolean): string {
        if (hasChild) {
            if (expanded) {
                return this.folderOpenIcon;
            }
            return this.folderCloseIcon;
        }
        return this.fileIcon;
    }
}
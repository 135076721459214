import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { ValidityModel } from 'src/app/core/models/merchandizing-config';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { ATTRIBUTE_AND_RULE_ERR } from '../attribute-and-rule-error.constant';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { validityConfigData } from './validity.config';

@Component({
    selector: 'op-validity',
    templateUrl: './validity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidityComponent {

    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public validityConfig: ValidityModel;
    public focusing: boolean = false;
    
    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.validityConfig = Object.assign(new ValidityModel(), validityConfigData);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        if (this.ruleComponent) {
            this.ruleComponent.add();
        }
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    public validateForm(): boolean {
        if (this.ruleComponent) {
            return this.ruleComponent.validateForm();
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return ATTRIBUTE_AND_RULE_ERR.duplicateInformation;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return ATTRIBUTE_AND_RULE_ERR.invalidInformation;
        }
    }
}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ProductLocationTypeReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/ProductLocationTypes"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getByProductLocationTypeCode(productLocationTypeCode: string) {

        return this.httpGet<any>(this.REFERENCE_PATH + "/" + productLocationTypeCode, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
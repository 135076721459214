import { OrganisationGroupOrganisationModel, 
    OrganisationOrganisationTypeModel, 
    OrganisationRelationModel, 
    OrganisationAttributeModel, 
    OrganisationLanguageModel, 
    OrganisationCurrencyModel, 
    OrganisationUserModel
} from ".";
import { AddressModel } from "../../components/address/shared/address.model";
import { CommentModel } from "../../components/comment/shared/comment.model";
import { CommunicationOtherModel } from "../user-model/communication-other.model";
import { CommunicationPhoneModel } from "../../components/communication-phone/shared/communication-phone.model";


export class OrganisationModel {
    public organisationId: string
    public locationId: string;
    public locationName: string;
    public languageTranslationSetId: string;
    public organisationCallName: string;
    public organisationLegalName: string;
    public iataNumber: string;
    public teletypeAddress: string;
    public websiteAddress: string;
    public taxId: string;
    public passwordExpiryDays: number;
    public passwordReuseCycle: number;
    public statusCode: string;
    public organisationCode: string;
    public providerIataCode: string;
    public providerIcaoCode: string;
    public providerAccountNumber: string;
    public organisationTypeName: string;
    public parentOrganisationId: string;
    public organisationOrganisationTypes: OrganisationOrganisationTypeModel[];
    public organisationGroupOrganisations: OrganisationGroupOrganisationModel[];
    public organisationRelations: OrganisationRelationModel[];
    public organisationAttributes: OrganisationAttributeModel[];
    public organisationLanguages: OrganisationLanguageModel[];
    public organisationCurrencies: OrganisationCurrencyModel[];
    public organisationAddresses: AddressModel[];
    public organisationCommunicationPhones: CommunicationPhoneModel[];
    public organisationCommunicationOthers: CommunicationOtherModel[];
    public organisationComments: CommentModel[];
    public organisationUsers: OrganisationUserModel[];
    public searchName: string;
    public searchStatusCode: string;
    public searchUsageTypeCode: string;
    public filterUserAccountId: string;
    public isOwnerFilter: boolean;
    public rootOrganisationId: string;
    public usageTypeCode: string;
    public usageTypeName: string;
    public commitBy: string;
    public commitByName: string;
    public commitFirstname: string;
    public commitDateTime: Date;
} 
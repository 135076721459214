
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { PriceRuleLocationPointReferenceModel } from '../../models/reference-model/reference-pricing-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class PriceRuleLocationPointService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/PriceRuleLocationPointReferences"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }

    public getProductLocationPointReference(): Observable<PriceRuleLocationPointReferenceModel[]> {
        return this.httpGet<PriceRuleLocationPointReferenceModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OperatingTransportSearchModel } from "../../models/operating-transport-model";
import { TransportViewModel } from "../../models/product-model/transport-model";
import { ScheduleQueueModel, ScheduleQueueSearchModel } from "../../models/schedule-queue-model";

@Injectable({
    providedIn: 'root'
})
export class OperatingTransportService extends ApiServiceBase {
    private readonly PATH = "/product/operatingTransports";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getByParentScheduleQueueId(scheduleQueueId: string): Observable<OperatingTransportSearchModel[]> {
        let httpParams = new HttpParams()
            .set('scheduleQueueId', scheduleQueueId);
        return this.httpGet<OperatingTransportSearchModel[]>(this.PATH + "/" + scheduleQueueId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getTransportScheduleFromOpeating(operatingProductId: string)
        : Observable<TransportViewModel> {
        return this.httpGet<TransportViewModel>(this.PATH 
            + "/" 
            + operatingProductId 
            + "/schedule", null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { ValidationView } from './validation.view';
import { RouteView } from '../route/route.view';
import { ServiceCategoryTableView } from '../servicecategory/servicecategory-table.view';
import { SalesBucketTableView } from '../salesbucket/salesbucket-table.view';
import { ValidationConstant } from './validation.constant';
import { ValidationMapperService } from './validation.mapper.service';
import { OperatingProductVerificationModel } from 'src/app/core/models/product-model/operating-product-model';

@Component({
    selector: 'op-transport-validation',
    templateUrl: './validation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ValidationMapperService]
})
export class ValidationComponent {
    public readonly EXPORT_FILE_NAME = "ValidationCategory";
    @Output() onClick = new EventEmitter<string>();
    @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public validates: ValidationView[] = [];
    public focusing: boolean = false
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    };

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private validationMapperService: ValidationMapperService) { }

    public validation(
        routes: RouteView[],
        validityRequired: boolean,
        validityPeriod: boolean,
        serviceCategoryViews: ServiceCategoryTableView[],
        salesBucketViews: SalesBucketTableView[],
        verifyViewModels: OperatingProductVerificationModel): boolean {
        this.validates = [];
        this.validationMapperService.validation(
            this.validates,
            routes,
            validityRequired,
            validityPeriod,
            verifyViewModels,
            serviceCategoryViews,
            salesBucketViews
        )
        this.changeDetectionRef.detectChanges();
        if (this.hasValidationMendatory()) {
            return false;
        }
        return true;
    }

    public getValidateName(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = ValidationConstant.VALIDATION_MENDATORY_NAME;
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = ValidationConstant.VALIDATION_CONDITION_NAME;
                break;
        }
        return returnValue;
    }

    public getValidateClass(status): string {
        let returnValue = "";
        switch (status) {
            case ValidationConstant.VALIDATION_MENDATORY_CODE:
                returnValue = 'status-mendatory';
                break;
            case ValidationConstant.VALIDATION_CONDITION_CODE:
                returnValue = 'status-condition';
                break;
        }
        return returnValue;
    }

    public doubleClick(item) {
        this.onClick.emit(item.data.panel);
    }

    private hasValidationMendatory(): boolean {
        let filters = this.validates.filter(x => x.validation == ValidationConstant.VALIDATION_MENDATORY_CODE)
        if (filters.length > 0) {
            return true;
        }
        return false;
    }

    public noValidationRows(): boolean {
        if (this.validates.length) {
            return false;
        }
        return true;
    }
}
import { OopsCategory } from "src/app/core/components/categories";
import { MODULE_NAME } from "../shared/title-constant";

export const PathUrl = '/main/settings/'

export const SettingsCategories: OopsCategory[] = [
    {
        name: 'Attribute',
        url: `${PathUrl}attribute`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
    {
        name: 'Document Distribution',
        url: `${PathUrl}document-distribution`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
    {
        name: 'Exchange Rate',
        url: `${PathUrl}exchange-rate`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
    {
        name: 'Geography',
        url: `${PathUrl}geography`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
    {
        name: 'Security',
        url: `${PathUrl}security`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
    {
        name: 'Vehicle',
        url: `${PathUrl}vehicle`,
        modifiedBy: null,
        modifiedDateTime: null,
        productCount: null
    },
]

export const SettingsSecurityCodes: Map<string, string> = new Map<string, string>()
    .set(MODULE_NAME.attribute, 'ATTRIBUTE')
    .set(MODULE_NAME.geography, 'GEOGRAPHY')
    .set(MODULE_NAME.document_distribution, 'DOCUMENTDISTRIBUTION')
    .set(MODULE_NAME.security, 'SECURITY')
    .set(MODULE_NAME.exchange_rate, 'EXCHANGERATE')
    .set(MODULE_NAME.vehicle, 'VEHICLE')

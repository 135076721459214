import { Injectable } from '@angular/core';
import { ApiServiceBase } from '../../base/api-service-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CashbookSearchCommandModel,
    CashbookSearchTableModel,
    CashbookSearchDetailCommandModel,
    CashbookDetailModel,
    CashbookSearchDocumentModel,
    CashbookDocumentTFOPModel,
    TotalByCurrencyModel,
    CashbookDocumentPaymentCurrenciesModel,
    CashbookDocumentformOfPaymentAndPaymentCurrencyModel
} from '../../models/cashbook-model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CashbookService extends ApiServiceBase {

    private readonly PATH: string = "/order"
    constructor(httpClient: HttpClient,
        authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public searchCashbook(searchCommand: CashbookSearchCommandModel): Observable<CashbookSearchTableModel[]> {
        return this.httpPost<CashbookSearchTableModel[]>(this.PATH + "/cashbooks/search", searchCommand)
            .pipe(
                map(res => res.body)
            )
    }

    public searchCashbookDetail(searchCommand: CashbookSearchDetailCommandModel): Observable<CashbookDetailModel> {
        return this.httpPost<CashbookDetailModel>(this.PATH + "/cashbooks/details", searchCommand)
            .pipe(
                map(res => res.body)
            )
    }

    public cashbookTotalFromOfPaymentDocuments(searchCommand: CashbookSearchDocumentModel): Observable<CashbookDocumentTFOPModel> {
        return this.httpPost<CashbookDocumentTFOPModel>(this.PATH + "/cashbooks/total-from-of-payment-documents", searchCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }

    public cashbookTotalByCurrency(searchCommand: CashbookSearchDocumentModel): Observable<TotalByCurrencyModel[]> {
        return this.httpPost<TotalByCurrencyModel[]>(this.PATH + "/cashbooks/total-by-currency-documents", searchCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }

    public cashbookPaymentCurrencies(searchCommand: CashbookSearchDocumentModel): Observable<CashbookDocumentPaymentCurrenciesModel> {
        return this.httpPost<CashbookDocumentPaymentCurrenciesModel>(this.PATH + "/cashbooks/payment-currencies-documents", searchCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }

    public cashbookFormOfPaymentAndPaymentCurrency(searchCommand: CashbookSearchDocumentModel): Observable<CashbookDocumentformOfPaymentAndPaymentCurrencyModel[]> {
        return this.httpPost<CashbookDocumentformOfPaymentAndPaymentCurrencyModel[]>(this.PATH + "/cashbooks/form-of-payment-and-payment-currency", searchCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            )
    }

}
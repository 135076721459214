import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActivityFollowupDateTimeComponent } from "./activity-followup-datetime.component";
import { ActivityDateTimeFormatComponent } from "./activity-datetime-format.component";

@NgModule({
    imports: [         
        CommonModule 
    ],
    declarations: [
        ActivityFollowupDateTimeComponent,
        ActivityDateTimeFormatComponent
    ],
    exports: [
        ActivityFollowupDateTimeComponent,
        ActivityDateTimeFormatComponent
    ]
})
export class ActivityFollowupDateTimeModule { }
export const ShoppingContactType = {
    contact: "CONTACT",
    passenger: "PASSENGER",
}

export const ShoppingShopTab = {
    transport: "TRANSPORT",
    voucher: "VOUCHER"
}

export const ShoppingFillIndividualDetailSource = {
    individualSearch: 'individualSearch',
    individualAttribute: 'individualAttribute'
}
export const ProductNumberType = {
    marketing: 'MARKETING',
    operating: 'OPERATING'
}
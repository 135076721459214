import { Injectable } from "@angular/core";
import { RouteView } from "../route/route.view";
import { ValidateMessageConstant } from "./validation.constant";
import { ServiceCategoryTableView } from "../servicecategory/servicecategory-table.view";
import { SalesBucketTableView } from "../salesbucket/salesbucket-table.view";
import { ValidationView } from "./validation.view";
import { OperatingProductVerificationModel } from "src/app/core/models/product-model/operating-product-model";

@Injectable()
export class ValidationMapperService {
    private readonly TYPE_DEPARTURE = "DEP";
    private readonly TYPE_ARRIVAL = "ARR";
    private readonly MESSAGE_PASTDATE = "Past Service Date.";
    private readonly MESSAGE_ALEREADY_EXISTS = "Operating Product already exists.";

    public validation(validates: ValidationView[],
        routes: RouteView[],
        validityRequired: boolean,
        validityPeriod: boolean,
        verifyViewModels: OperatingProductVerificationModel,
        serviceCategoryViews: ServiceCategoryTableView[],
        salesBucketViews: SalesBucketTableView[]) {
        this.validateDeparture(validates, routes);
        this.validateArrival(validates, routes);
        this.validateDayChange(validates, routes);
        this.validateValidityRequired(validates, validityRequired);
        this.validateValidityPeriod(validates, validityPeriod);
        this.valiDatePastServiceDate(validates, verifyViewModels);
        this.valiDateAlreadyExists(validates, verifyViewModels);
        this.validateServiceCategory(validates, serviceCategoryViews);
        this.validateSalesBucket(validates, salesBucketViews);
    }

    private validateDeparture(validates: ValidationView[], routes: RouteView[]) {
        let depRoutes = routes.filter(x => x.type == this.TYPE_DEPARTURE && !x.depTime);
        if (depRoutes?.length) {
            this.addValidateData(validates, ValidateMessageConstant.ROUTE_DEPARTURE);
        }
    }

    private validateArrival(validates: ValidationView[], routes: RouteView[]) {
        let arrRoutes = routes.filter(x => x.type == this.TYPE_ARRIVAL && !x.arrTime);
        if (arrRoutes?.length) {
            this.addValidateData(validates, ValidateMessageConstant.ROUTE_ARRIVAL);
        }
    }

    private validateDayChange(validates: ValidationView[], routes: RouteView[]) {
        let arrDayChanges = routes.filter(x => x.type == this.TYPE_ARRIVAL && !x.dayChange);
        if (arrDayChanges?.length) {
            this.addValidateData(validates, ValidateMessageConstant.ROUTE_DAYCHANGE);
        }
    }

    private validateValidityRequired(validates: ValidationView[], validityRequired: boolean) {
        if (validityRequired) {
            this.addValidateData(validates, ValidateMessageConstant.VALIDITY_FROMTO);
        }
    }

    private validateValidityPeriod(validates: ValidationView[], validityPeriod: boolean) {
        if (validityPeriod) {
            this.addValidateData(validates, ValidateMessageConstant.VALIDITY_PERIOD);
        }
    }

    private validateServiceCategory(validates: ValidationView[], serviceCategoryViews: ServiceCategoryTableView[]) {
        if (!serviceCategoryViews) {
            return;
        }
        let filterNoCapacities = serviceCategoryViews.filter(x => !x.capacity);
        if (filterNoCapacities.length) {
            this.addValidateData(validates, ValidateMessageConstant.SERVICE_CATEGORY);
        }
    }

    private validateSalesBucket(validates: ValidationView[], salesBucketViews: SalesBucketTableView[]) {
        if (!salesBucketViews) {
            return;
        }
        let filterNoCapacities = salesBucketViews.filter(x => !x.capacityFirm);
        if (filterNoCapacities.length) {
            this.addValidateData(validates, ValidateMessageConstant.SALES_BUCKET);
        }
    }

    private valiDatePastServiceDate(validates: ValidationView[], verifyViewModels: OperatingProductVerificationModel) {
        if (!verifyViewModels) {
            return;
        }
        let filterPastDate = verifyViewModels.verifyResults.filter(x=>x.result == this.MESSAGE_PASTDATE);
        if (filterPastDate.length) {
            this.addValidateData(validates, ValidateMessageConstant.VALIDITY_PASTDATE);
        }
    }

    private valiDateAlreadyExists(validates: ValidationView[], verifyViewModels: OperatingProductVerificationModel) {
        if (!verifyViewModels) {
            return;
        }
        let filterPastDate = verifyViewModels.verifyResults.filter(x=>x.result == this.MESSAGE_ALEREADY_EXISTS);
        if (filterPastDate.length) {
            this.addValidateData(validates, ValidateMessageConstant.VALIDITY_EXISTS);
        }
    }

    private addValidateData(validates: ValidationView[], validate: ValidationView) {
        validates.push(validate);
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event-package';
import { TreeNode } from 'primeng/api';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupSecurityConverter } from './security-group-security.converter';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-group-security-code-setting',
    templateUrl: './group-security-code-setting.component.html',
    providers: [
        SecurityGroupSecurityConverter
    ]
})
export class GroupSecurityCodeSettingComponent extends OopsComponentFormBase implements OnChanges {
    @Input() securityCodeTablePanelToggler = true;
    @Input() securityGroupSecurityId;
    @Input() securityCodeDetail: SecurityGroupSecurityModel[] = new Array<SecurityGroupSecurityModel>();
    @Input() focused: boolean;
    @Input() newSecurity = true;
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Output() onCodeSettingUpdate = new EventEmitter<SecurityGroupSecurityModel>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public currentSecurityCodeSecurity: SecurityGroupSecurityModel;

    constructor(private formBuilder: UntypedFormBuilder,
        private securityGroupSecurityConverter: SecurityGroupSecurityConverter) {
        super(formBuilder)
        this.initForm();
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['securityGroupSecurityId']) {
            this.formSetup();
        }
    }

    public initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = this.formBuilder.group({
            securityCode: [{value: null, disabled: true}, Validators.required],
            securityName: [{value: null, disabled: true}, Validators.required],
            newFlag: [null],
            editFlag: [null],
            copyFlag: [null],
            deleteFlag: [null],
            printFlag: [null],
            exportFlag: [null],
            copyToClipboardFlag: [null],
            limitation: [null],
            commitDateTime: [{value: null, disabled: true}],
            commitByName: [{value: null, disabled: true}],
        });
        if (this.securityGroupSecurityId != null) {
            let index = this.securityCodeDetail.findIndex(item => item.securityGroupSecurityId == this.securityGroupSecurityId);
            if (index !== -1) {
                this.currentSecurityCodeSecurity = this.securityCodeDetail[index];
                this.checkLimitation();
                this.fillModelToForm(this.securityCodeDetail[index]);
            }
        } else {
            return this.formGroup;
        }
    }

    fillModelToForm(model: SecurityGroupSecurityModel) {
        this.formGroup.patchValue({
            securityCode: model.securityCode,
            securityName: model.securityName,
            newFlag: model.newFlag ?? false,
            editFlag: model.editFlag ?? false,
            copyFlag: model.copyFlag ?? false,
            deleteFlag: model.deleteFlag ?? false,
            printFlag: model.printFlag ?? false,
            exportFlag: model.exportFlag ?? false,
            copyToClipboardFlag: model.copyToClipboardFlag ?? false,
            limitation: model.limitation,
            commitDateTime: model.commitDateTime,
            commitByName: model.commitByName
        })
    }

    fillFormToModel(): SecurityGroupSecurityModel {
        let formValue = this.formGroup.value;
        let model : SecurityGroupSecurityModel = {
            securityGroupSecurityId: this.currentSecurityCodeSecurity.securityGroupSecurityId,
            securityGroupId: this.currentSecurityCodeSecurity.securityGroupId,
            securityCode: this.currentSecurityCodeSecurity.securityCode,
            securityName: this.currentSecurityCodeSecurity.securityName,
            newFlag: formValue.newFlag ?? false,
            editFlag: formValue.editFlag ?? false,
            copyFlag: formValue.copyFlag ?? false,
            deleteFlag: formValue.deleteFlag ?? false,
            printFlag: formValue.printFlag ?? false,
            exportFlag: formValue.exportFlag ?? false,
            copyToClipboardFlag: formValue.copyToClipboardFlag ?? false,
            limitation: formValue.limitation,
            limitationName: this.currentSecurityCodeSecurity.limitationName,
            commitBy: this.currentSecurityCodeSecurity.commitBy,
            commitByName: this.currentSecurityCodeSecurity.commitByName,
            commitDateTime: this.currentSecurityCodeSecurity.commitDateTime
        }
        return model;
    }

    onFocus() {
        this.isFocused.emit(false);
    }

    onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        } 
    }

    addNewSecurityGroupSecurity(newSecurity: SecurityCodeReferenceModel[], newSecurityWithId: TreeNode[], selectedNode: TreeNode) {
        let newLength = newSecurity.length;
        if (!this.securityCodeDetail) {
            this.securityCodeDetail = new Array<SecurityGroupSecurityModel>();
        }
        for (var i = 0; i < newLength; i++) {
            let model = this.securityGroupSecurityConverter.SecurityCodeModelToSecurityGroupSecurity
                (newSecurity[i], newSecurityWithId[i].data, selectedNode.data);
            this.securityCodeDetail.push(model);
            this.onCodeSettingUpdate.emit(model);
        }
        
    }

    apply() {
        this.startProcessing()
        if (this.formGroup.valid && this.currentSecurityCodeSecurity != null) {
            let model = this.fillFormToModel();
            let index = this.securityCodeDetail.findIndex(item => item.securityGroupSecurityId == model.securityGroupSecurityId);
            if (index != -1) {
                this.securityCodeDetail[index] = model;
            } else {
                this.securityCodeDetail.push(model);
            }
            this.completeProcessing();
            this.onCodeSettingUpdate.emit(model);
        }
    }

    refresh() {
        this.formSetup();
    }

    getSecurityGroupSecurity(): SecurityGroupSecurityModel[] {
        return this.securityCodeDetail;
    }

    checkLimitation() {
        if (this.currentSecurityCodeSecurity?.limitationName) {
            this.formGroup.controls['limitation'].setValidators([Validators.required]);
        } else {
            this.formGroup.controls['limitation'].clearValidators();
        }
        this.formGroup.controls['limitation'].updateValueAndValidity();
    }

}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { cloneDeep } from "lodash";

import { UiHelperService } from "src/app/core/utils/ui-helper.service";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { ActivityDomainMapperService } from "./shared/activity-domain.mapper.service";

import { ActivityPriorityReferenceService } from "../../services/system-services/activity-priority-reference.service";
import { ActivityStatusReferenceService } from "../../services/system-services/activity-status-reference.service";
import { ActivityReasonReferenceService } from "../../services/system-services/activity-reason-reference.service";
import { DomainReferenceService } from "../../services/system-services/domain-reference.service";
import { CommentClassificationReferenceService, CommentTypeReferenceService } from "../../services/system-services";
import { OrganisationService } from 'src/app/core/services/organisation-services';

import { ActivityDomainDetailComponent } from "./activity-domain-detail/activity-domain-detail.component";
import { ActivityDomainListComponent } from "./activity-domain-list/activity-domain-list.component";

import { ActivityDomainView } from "./shared/activity-domain.view";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ActivityService } from "src/app/core/services/activity-services";
import { ActivityCommandModel, ActivityViewModel } from "../../models/activity-model";
import { ActivityPriorityReferenceModel } from "../../models/reference-model/reference-general-model/activity-priority-reference.model";
import { ActivityStatusReferenceModel } from "../../models/reference-model/reference-general-model/activity-status-reference.model";
import { ActivityReasonReferenceModel } from "../../models/reference-model/reference-general-model/activity-reason-reference.model";
import { DomainReferenceModel } from "../../models/reference-model/reference-general-model/domain-reference.model";
import { OrganisationSelectionModel } from "../../models/organisation-model/organisation-selection.model";
import { ActivityStoreService } from "../../utils/activity-store.service";
import { ActivityDomainTabModel } from "../../models/activity-model/activity-domain-tab.model";
import { Store } from "@ngrx/store";
import { selectTabs } from "src/app/store/tab";
import { TabModel } from "../../models/tab";
import { LoadingNotifier, LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { selectActivities } from "src/app/store/activity";
import { takeUntil } from "rxjs/operators";
import { NavigationService } from "src/app/shared/utils/navigation";
import { DomainConstant } from "./shared/activity-domain.constant";
import { ActivityDetailCommentComponent } from "src/app/modules/activity/activity-home/activity-detail/comment/comment.component";
import { CommentCommandModel } from "../../models/activity-model/activity-comment/comment-command.model";
import { MediaMapperService } from "../media/shared/media-mapper.service";
import { AuthService } from "../../authentication/auth.service";
import { SecurityGroupSecurityModel } from "../../models/security-model/security-group-security.model";
import { SecurityGroupService } from "../../services/system-services/security-group.service";

@Component({
    selector: 'op-activity-domain',
    templateUrl: './activity-domain.component.html',
    providers: [
        ActivityDomainMapperService, 
        LoadingSpinnerService,
        MediaMapperService
    ]
})
export class ActivityDomainComponent implements OnDestroy {
    private readonly SINGLE_POSITION_OFFSET = 355;
    private readonly MULTIPLE_POSITION_OFFSET = 330;
    private readonly LIST_POSITION_OFFSET = 320;
    private readonly DELAY_TIME_FOCUS = 200;
    private readonly STATUS_COMPLETED = "COMPLETED";
    private readonly PRIORITY_LOW = "LOW";
    private readonly ENTITY_NAME = "Comment";
    public readonly SPINNER_NAME: string = "activitySpinner";
    public readonly SPINNER_FULL_SCREEN: boolean = true;
    public readonly PAGE_TITLE_EDIT = "Activity Management";
    @Input() id: string;
    @Input() domainCode: string;
    @Input() saveActivity: boolean;
    @Input() displaySaveCompleted: boolean = false;
    @Output() formStatus = new EventEmitter<Boolean>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(ActivityDomainListComponent) listComponent: ActivityDomainListComponent;
    @ViewChild(ActivityDomainDetailComponent) detailComponent: ActivityDomainDetailComponent;
    @ViewChild("detail") detail: ElementRef;
    @ViewChild("list") list: ElementRef;
    @ViewChild(ActivityDetailCommentComponent) commentComponent: ActivityDetailCommentComponent;

    public focusing: boolean = false;
    public newFromDetail: boolean = false;
    public newFromGrid: boolean = false;
    public editFromGrid: boolean = false;

    public activityPriorityRef$ = new BehaviorSubject<ActivityPriorityReferenceModel[]>(null);
    public activityStatusRef$ = new BehaviorSubject<ActivityStatusReferenceModel[]>(null);
    public activityReasonRef$ = new BehaviorSubject<ActivityReasonReferenceModel[]>(null);
    public domainRef$ = new BehaviorSubject<DomainReferenceModel[]>(null);
    public organisationRef$ = new BehaviorSubject<OrganisationSelectionModel[]>(null);
    public commentTypeReference$ = new BehaviorSubject<CommentTypeReferenceModel[]>(null);
    public commentClassificationReference$ = new BehaviorSubject<CommentClassificationReferenceModel[]>(null);

    public activityViewModels: ActivityViewModel[];
    public activityDomainViews: ActivityDomainView[] = new Array();
    public newActivityDomainViews: ActivityDomainView[] = new Array();
    public model: ActivityDomainView;
    public activityDetails: ActivityDomainTabModel[];
    public currentActivityDetail: ActivityDomainTabModel;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private initOrganisationId = this.authService.getSelectedOrganisation();
    public loadingNotifier = new LoadingNotifier();
    public firstLoad: boolean = true;

    public activitySecurity: SecurityGroupSecurityModel;

    constructor(private focusingService: FocusingService,
        private uiHelper: UiHelperService,
        private changeDetectorRef: ChangeDetectorRef,
        private activityPriorityReferenceService: ActivityPriorityReferenceService,
        private activityStatusReferenceService: ActivityStatusReferenceService,
        private activityReasonReferenceService: ActivityReasonReferenceService,
        private domainReferenceService: DomainReferenceService,
        private organisationService: OrganisationService,
        private commentTypeReferenceService: CommentTypeReferenceService,
        public commentClassificationReferenceService: CommentClassificationReferenceService,
        private activityService: ActivityService,
        private activityStoreService: ActivityStoreService,
        private activityDomainMapperService: ActivityDomainMapperService,
        private store: Store<any>,
        private navigationService: NavigationService,
        private mediaMapperService: MediaMapperService,
        private authService: AuthService,
        private securityGroupService: SecurityGroupService) {
    }

    ngOnInit(): void {
        this.getActivityPriorityReferences();
        this.getActivityStatusReferences();
        this.getActivityReasonReferences();
        this.getOrganisationReferences();
        this.getDomainReferences();
        this.getCommentTypeReferences();
        this.getCommentClassificationReferences();
        this.getActivityDomain();
        this.getUserSecurity();
        this.openCloseActivityPanel();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private getActivityPriorityReferences() {
        this.activityPriorityReferenceService.getActivityPriorityReferences()
            .subscribe((responses: ActivityPriorityReferenceModel[]) => {
                this.activityPriorityRef$.next(responses)
            });
    }

    private getActivityStatusReferences() {
        this.activityStatusReferenceService.getActivityStatusReferences()
            .subscribe((responses: ActivityStatusReferenceModel[]) => {
                this.activityStatusRef$.next(responses)
            });
    }

    private getActivityReasonReferences() {
        this.activityReasonReferenceService.getActivityReasonReferences()
            .subscribe((responses: ActivityReasonReferenceModel[]) => {
                this.activityReasonRef$.next(responses)
            });
    }

    private getDomainReferences() {
        this.domainReferenceService.getDomainReference()
            .subscribe((response: DomainReferenceModel[]) => {
                this.domainRef$.next(response);
            });
    }

    private getOrganisationReferences() {
        this.organisationService.getOrganisationSelectionsById(this.initOrganisationId)
            .subscribe((responses: OrganisationSelectionModel[]) => {
                this.organisationRef$.next(responses)
            });
    }

    private getCommentClassificationReferences() {
        this.commentClassificationReferenceService.getCommentClassificationReferences().subscribe(
            (responses: CommentClassificationReferenceModel[]) => {
                this.commentClassificationReference$.next(responses);
            }
        );
    }

    private getCommentTypeReferences() {
        this.commentTypeReferenceService.getCommentTypeReferences().subscribe(
            (responses: CommentTypeReferenceModel[]) => {
                this.commentTypeReference$.next(responses);
            }
        );
    }

    private getUserSecurity() {
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {
                    let security = response.find(item => item.securityCode == 'ACTIVITYALL');
                    this.activitySecurity = security;
                }
            )
    }

    private createActivityDomainViews() {
        this.activityDomainViews = this.activityDomainMapperService.toViews(this.activityViewModels);
        this.displayInCondition();
    }

    private displayInCondition() {
        if (this.isNoRecord()) {
            this.model = null;
        } else if (this.isSingleRecord()) {
            this.model = this.activityDomainViews[0];
        }
    }

    onApply(view: ActivityDomainView) {
        this.commentComponent.applyDetailForm();
        let commentValid = this.commentComponent.validateForm();
        if (!commentValid) {
            return;
        }
        this.setCommentDetail(view);
        this.newActivityDomainViews = [view];
        this.resetModeMultiple();
    }

    private setCommentDetail(view: ActivityDomainView) {
        let commentDetails = this.commentComponent.getValue();
        let commentDetail: CommentCommandModel = null;
        if (commentDetails?.length) {
            commentDetail = commentDetails[0];
        }
        view.commentId = commentDetail?.commentId;
        view.commentClassificationCode = commentDetail?.commentClassificationCode;
        view.commentTypeCode = commentDetail?.commentTypeCode;
        view.commentText = commentDetail?.commentText;
        
        let media = this.commentComponent.detailComponent.mediaModel;
        if(media?.fileChange) {
            let mediaCommands = this.mediaMapperService.mediaToMediaModels([], [media], this.ENTITY_NAME, view.commentId);
            view.media = mediaCommands[0];
        }
    }

    isSingleRecord(): boolean {
        return (this.activityDomainViews?.length == 1);
    }

    isNoRecord(): boolean {
        return (!this.activityDomainViews?.length == true);
    }

    isMultipleRecord(): boolean {
        return (this.activityDomainViews?.length > 1);
    }

    onNewFromDetail() {
        this.setNewModeFromDetail().then(() => {
            this.moveToDetail(this.SINGLE_POSITION_OFFSET);
            this.focusingService.focus(this.detailComponent.focusingDirective);
        });
    }

    onNewFromGrid() {
        this.setNewMode().then(() => {
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onEdit(view: ActivityDomainView) {
        let params = {
            activityId: view.activityId,
            paggingDataView: this.activityDomainMapperService.toPagingViews(this.activityViewModels),
            currentIndex: view.no
        }
        this.navigationService.navigate('main/activity/' + Date.now().toString(), this.PAGE_TITLE_EDIT, true, params);
    }

    onDelete(view: ActivityDomainView) {
        this.deletedata(view);
        if (this.isSingleRecord()) {
            this.model = this.activityDomainViews[0];
        }
    }

    onCopy(view: ActivityDomainView) {
        this.setCopyMode(view);
    }

    onCancel() {
        if (this.isNoRecord()) {
            this.resetModeNoRecord();
        } else {
            this.resetModeMultiple();
        }
    }

    onCancelFromList() {
        this.currentActivityDetail.displayDetail = !this.currentActivityDetail.displayDetail;
        this.activityStoreService.add(
            this.currentActivityDetail.id,
            this.currentActivityDetail.pendingCount,
            this.currentActivityDetail.displayDetail)
    }

    onRefresh() {
        this.resetMode().then(() => {
            this.refreshCurrentViews();
            this.displayInCondition();
        });
    }

    private refreshCurrentViews() {
        this.getActivityDomain();
    }

    public getActivityDomain() {
        switch (this.domainCode?.toUpperCase()) {
            case DomainConstant.DOMAIN_CODE_ORDER:
                this.getActivityOrder();
                break;
            case DomainConstant.DOMAIN_CODE_TRANSPORT:
                this.getActivityTransport();
                break;
            case DomainConstant.DOMAIN_CODE_PRODUCT:
                this.getActivityProduct();
                break; 
            case DomainConstant.DOMAIN_CODE_USER:
                this.getActivityUser();
                break;
            case DomainConstant.DOMAIN_CODE_PRICERULE:
                this.getActivityPriceRule();
                break;
            case DomainConstant.DOMAIN_CODE_PRICE:
                this.getActivityPrice();
                break;    
            case DomainConstant.DOMAIN_CODE_INSIGHT:
                this.getActivityInsight();
                break;                              
        }
    }

    private getActivityOrder() {
        if (this.id) {
            this.activityService.getByRootOrderId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
        else {
            this.storeActivityData(null);
        }
    }

    private getActivityTransport() {
        if (this.id) {
            this.activityService.getByTransportId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    }

    private getActivityProduct() {
        if (this.id) {
            this.activityService.getByProductId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    }

    private getActivityUser() {
        if (this.id) {
            this.activityService.getByUserId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    } 

    private getActivityPriceRule() {
        if (this.id) {
            this.activityService.getByPriceRuleId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    } 

    private getActivityPrice() {
        if (this.id) {
            this.activityService.getByPriceId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    } 

    private getActivityInsight() {
        if (this.id) {
            this.activityService.getByIndividualId(this.id)
                .subscribe(
                    (response: ActivityViewModel[]) => {
                        this.storeActivityData(response);
                    }
                )
        }
    } 

    private storeActivityData(response: ActivityViewModel[]) {
        this.activityViewModels = [];
        this.activityViewModels = response;
        this.activityStore();
        this.createActivityDomainViews();
        this.changeDetectorRef.detectChanges();
    }

    private resetModeNoRecord() {
        this.resetMode().then(() => {
            this.focusingService.focus(this.listComponent.focusingDirective);
            this.moveToList(this.LIST_POSITION_OFFSET);
        });
    }

    private resetModeMultiple() {
        this.resetMode().then(() => {
            this.moveToList(this.LIST_POSITION_OFFSET);
        });
    }

    private resetMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = this.createNewActivityModel();
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewModeFromDetail(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = this.createNewActivityModel();
            this.newFromDetail = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setCopyMode(view: ActivityDomainView): Promise<void> {
        return new Promise((resolve, reject) => {
            let copyData = cloneDeep(view);
            copyData.no = null;
            copyData.commentId = undefined;
            copyData.productCommentId = undefined;
            copyData.commitByName = "";
            copyData.commitDateTime = null;
            this.model = copyData;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    public moveToDetail(offset: number) {
        setTimeout(() => {
            this.uiHelper.moveScrollPosition(this.detail, offset);
        }, this.DELAY_TIME_FOCUS);
    }

    private moveToList(offset: number) {
        this.uiHelper.moveScrollPosition(this.list, offset);
        this.focusingService.focus(this.listComponent.focusingDirective);
    }

    getValue(views: ActivityDomainView[]): ActivityCommandModel[] {
        return this.activityDomainMapperService.toModels(views, this.id);
    }

    validateForm(): boolean {
        if (!this.detailComponent) {
            return true;
        }
        if (this.detailComponent.processing) {
            return this.detailComponent.validateForm();
        }
        return true;
    }

    getErrorMessageForm(): string {
        if (this.detailComponent) {
            return this.detailComponent.getErrorMessageForm();
        }
    }

    onformStatusChange(formStatus: boolean) {
        this.formStatus.emit(formStatus);
    }

    private deletedata(data: ActivityDomainView) {
        let views = new Array<ActivityDomainView>();
        if (this.activityDomainViews != null) {
            views = this.activityDomainViews;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.activityDomainViews = views;
        }
    }

    private activityStore() {
        this.activityStoreService.add(
            this.id,
            this.activityViewModels?.length,
            this.hasActivity());
    }

    public onComplete(view: ActivityDomainView) {
        this.activityService.updateStatus(view.activityId, this.STATUS_COMPLETED)
            .subscribe(() => {
                this.getActivityDomain();
            })
    }

    public save() {
        this.detailComponent?.onApply();
        let command = this.getValue(this.newActivityDomainViews);
        if (command?.length) {
            this.displaySpinerSave();
            this.activityService.save(command)
                .subscribe(() => {
                    this.getActivityDomain();
                    this.displaySpinerSave(false);
                },
                    () => {
                        this.loadingNotifier.hide(this.SPINNER_NAME);
                    }
                )
        }
    }

    private createNewActivityModel(): ActivityDomainView {
        let model = {} as ActivityDomainView;
        model.organisationId = this.initOrganisationId;
        model.domainCode = this.domainCode;
        model.activityPriorityCode = this.PRIORITY_LOW;
        return model;
    }

    private displaySpinerSave(showSaving: boolean = true) {
        if (this.displaySaveCompleted) {
            if (showSaving) {
                this.loadingNotifier.showSaving(this.SPINNER_NAME);
            } else {
                this.loadingNotifier.saveComplate(this.SPINNER_NAME);
            }
        }
    }

    private openCloseActivityPanel() {
        this.store.select(selectActivities)
            .pipe(takeUntil(this.destroy$))
            .subscribe((activities: ActivityDomainTabModel[]) => {
                this.activityDetails = activities;
                this.assigntoCurrentActivity();
            });
    }

    private assigntoCurrentActivity() {
        if (this.firstLoad) {
            this.firstLoad = false;
            return;
        }
        this.store.select(selectTabs).subscribe((tabs: TabModel[]) => {
            let tab = tabs.find((tab) => tab.active == true);
            let filters = this.activityDetails.filter(x => x.tabNumber == tab?.id);
            if (filters?.length) {
                this.currentActivityDetail = filters[0];
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    private hasActivity() {
        if (this.activityViewModels?.length) {
            return true;
        }
        return false;
    }
}
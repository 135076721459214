import { Injectable } from '@angular/core';
import { ExchangeRateAmountCommandModel, ExchangeRateAmountModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ExchangeRateAmountView } from './exchange-rate-amount.view';

@Injectable()
export class ExchangeRateAmountMapperService {

    public toViews(models: ExchangeRateAmountModel[]): ExchangeRateAmountView[] {
        let views: ExchangeRateAmountView[] = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.toView(i, model));
        }
        return views;
    }

    private toView(i: number, model: ExchangeRateAmountModel): ExchangeRateAmountView {
        let view = {} as ExchangeRateAmountView;
        view.no = i;
        view.exchangeRateId = model.exchangeRateId;
        view.baseCurrencyCode = model.baseCurrencyCode;
        view.exchangeCurrencyCode = model.exchangeCurrencyCode;
        view.factor = model.factor;
        view.sell = model.sellExchangeRateAmount;
        view.buy = model.buyExchangeRateAmount;
        view.validFrom = model.validFromDateTime;
        return view;
    }

    public toModels(views: ExchangeRateAmountView[]): ExchangeRateAmountCommandModel[] {
        let models: ExchangeRateAmountCommandModel[] = new Array();
        if (views) {
            for (let view of views) {
                models.push(this.toModel(view));
            }
        }
        return models;
    }

    private toModel(view: ExchangeRateAmountView): ExchangeRateAmountCommandModel {
        let model = {} as ExchangeRateAmountCommandModel;
        model.exchangeRateId = view.exchangeRateId;
        model.sellExchangeRateAmount = this.createAmount(view.sellNew);
        model.buyExchangeRateAmount = this.createAmount(view.buyNew);
        model.validFromDateTime = this.createDateTime(view.validFromNew);
        return model;
    }

    private createDateTime(d: Date): any {
        let validFromDate = new Date();
        if (d) {
            validFromDate = new Date(d);
        }
        return new Date(validFromDate.toDateString() + " " + validFromDate.toTimeString() + 'Z');
    }

    private createAmount(amount: number): number {
        if (amount) {
            return amount;
        }
        return 0;
    }
}
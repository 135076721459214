import * as moment from 'moment';

export const select2Option = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const dateOption = {
    singleDatePicker: true,
    timePicker: false,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment().startOf('hour'),
    endDate: moment().startOf('hour').add(32, 'hour'),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

export const timeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'HH:mm'
    },
    timePicker24Hour: true
};
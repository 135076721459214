import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { BehaviorSubject, forkJoin } from "rxjs";
import { startWith } from "rxjs/operators";
import { PriceRuleCurrencyModel, PriceRuleModel } from "src/app/core/models/pricing-model";
import { ConditionReferenceModel, CurrencyReferenceModel, IndividualAgeGroupReferenceModel, IndividualSocialTypeReferenceModel, UnitReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { IndividualAgeGroupTypeReferenceService } from "src/app/core/services/airline-services";
import { PricingService } from "src/app/core/services/pricing-services";
import { ConditionReferenceService, CurrencyReferenceService, UnitReferenceService } from "src/app/core/services/system-services";
import { IndividualSocialTypeReferenceService } from "src/app/core/services/system-services/individual-social-type-reference.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { PriceModel } from "../../../prices/shared/models";
import { PriceService } from "../../../prices/shared/price.service";
import { PriceRuleView } from "../../../shared/views";
import { PricingDetailConstant } from "../shared/pricing-detail.constant";

@Component({
    selector: 'op-price-mapping',
    templateUrl: './price-mapping.component.html'
})
export class PriceMappingComponent implements OnChanges {
    private readonly defaultUsageTypeCode = 'DATA';
    private readonly requiredFields = ['priceCode', 'priceName'];

    private defaultPriceSearchCriteria: PriceModel;
    private requiredValidate = false;
    public doneLoading = false;

    public focusing = false;
    public rows = 0;
    public focusedRowKey: any;
    public dataSource: DataSource = this.createDataSource(null);
    public prices: PriceModel[];
    public priceRuleCurrencies: PriceRuleCurrencyModel[];

    public collapseFlag$ = new BehaviorSubject<boolean>(true);

    public individualAgeGroupTypeReferences: IndividualAgeGroupReferenceModel[];
    public conditionReferences: ConditionReferenceModel[];
    public individualSocialTypeReferences: IndividualSocialTypeReferenceModel[];
    public unitReferences: UnitReferenceModel[];
    public currencyReferences: CurrencyReferenceModel[];

    get hasData(): boolean {
        return !!(this.priceRuleView?.priceRulePrices?.length);
    }


    @Input() priceRuleView: PriceRuleView;
    @Input() disabled: boolean = false;

    @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

    constructor(private stringHelperService: StringHelperService,
        private priceService: PriceService,
        private pricingService: PricingService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private unitReferenceService: UnitReferenceService,
        private currencyReferenceService: CurrencyReferenceService) {
        this.setPriceSearchDefaultCriteria()
        this.getData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.populateData(this.priceRuleView);
    }

    private populateData(priceRule: PriceRuleView) {
        this.dataSource = this.createDataSource(priceRule);
        this.collapseFlag$.next(!this.hasData);
        this.datagrid?.instance?.collapseAll(-1);
        this.focusedRowKey = null;
    }

    private createDataSource(priceRule: PriceRuleView): DataSource {
        return new DataSource({
            key: 'priceRulePriceId',
            store: this.createArrayStore(priceRule)
        });
    }

    private createArrayStore(priceRule: PriceRuleView): ArrayStore {
        return new ArrayStore({
            key: 'priceRulePriceId',
            data: priceRule?.priceRulePrices?.map(prp => {
                const price = this.prices.find(pr => pr.priceId == prp.priceId);
                return {
                    priceRulePriceId: prp.priceRulePriceId,
                    priceCode: prp.priceId,
                    priceName: prp.priceId
                }
            }) ?? []
        })
    }

    private getData() {
        forkJoin({
            prices: this.priceService.getData(),
            priceRuleCurrencies: this.pricingService.getPriceRuleCurrencies(),
            individualAgeGroupTypeReferences: this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences(),
            conditionReferences: this.conditionReferenceService
                .getConditionsByCodes([
                    PricingDetailConstant.IS_OPERATOR_CODE,
                    PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
                    PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
                    PricingDetailConstant.TIER_CODE,
                ])
                .pipe(
                    startWith([
                        {
                            conditionCode: PricingDetailConstant.IS_OPERATOR_CODE,
                            conditionName: 'is, are',
                            sortSequence: 1,
                            statusCode: StatusConstant.ACTIVE
                        },
                    ])
                ),
            individualSocialTypeReferences: this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences(),
            unitReferences: this.unitReferenceService.getUnitReferences(),
            currencyReferences: this.currencyReferenceService.getCurrencyReferences()
        })
        .subscribe(
            ({
                prices,
                priceRuleCurrencies,
                individualAgeGroupTypeReferences,
                conditionReferences,
                individualSocialTypeReferences,
                unitReferences,
                currencyReferences
            }) => {
                this.prices = prices;
                this.priceRuleCurrencies = priceRuleCurrencies;
                this.individualAgeGroupTypeReferences = individualAgeGroupTypeReferences;
                this.conditionReferences = conditionReferences;
                this.individualSocialTypeReferences = individualSocialTypeReferences;
                this.unitReferences = unitReferences;
                this.currencyReferences = currencyReferences;
                this.doneLoading = true;
            }
        )
    }

    private setPriceSearchDefaultCriteria() {
        this.defaultPriceSearchCriteria = new PriceModel();
        this.defaultPriceSearchCriteria.status = StatusConstant.ACTIVE;
        this.defaultPriceSearchCriteria.usageTypeCode = this.defaultUsageTypeCode;
        this.defaultPriceSearchCriteria.draftFlag = false;
    }

    public newItem() {
        let newId = this.stringHelperService.NewGuid();
        this.dataSource.store()
            .insert({
                priceRulePriceId: newId
            })
            .then(() => {
                this.dataSource.reload();
                this.collapseFlag$.next(false);
                this.focusedRowKey = newId;
            })
    }

    public delete() {
        if (!this.focusedRowKey) {
            return;
        }
        this.dataSource.store()
            .remove(this.focusedRowKey)
            .then(() => {
                this.focusedRowKey = null;
                this.dataSource.reload();
                if (this.hasData == false) {
                    this.collapseFlag$.next(true);
                }
            })
    }

    public setPriceCode(newData, value) {
        newData.priceName = value;
        (<any>this).defaultSetCellValue(newData, value);
    }

    public setPriceName(newData, value) {
        newData.priceCode = value;
        (<any>this).defaultSetCellValue(newData, value);
    }

    public getValues(priceRule: PriceRuleModel): PriceRuleModel {
        const items = this.dataSource?.items();
        let priceRulePrices = [];
        if (!items?.length) {
            priceRule.priceRulePrices = priceRulePrices;
            return priceRule;
        }
        for (let item of items) {
            if (!item.priceCode) {
                this.showErrorCell(true);
                return null;
            }

            priceRulePrices.push({
                priceRulePriceId: item.priceRulePriceId,
                priceId: item.priceCode
            })
        }
        priceRule.priceRulePrices = priceRulePrices;
        return priceRule;
    }

    public onCellPrepared(e) {
        this.setInvalidCell(e);
        this.removeExpandButtonIfNewRow(e);
    }

    private removeExpandButtonIfNewRow(e: any) {
        if (e.rowType === "data" && e.column.command === "expand") {
            if (!e.data.priceCode) {
                e.cellElement.removeClass("dx-datagrid-expand");
                e.cellElement.empty();
            }
        }
    }

    private setInvalidCell(e: any) {
        if (e.rowType == 'data' &&
            this.requiredFields.includes(e.column.dataField) &&
            this.requiredValidate &&
            !e.value) {
            e.cellElement.addClass('invalid-cell');
        } else {
            e.cellElement.removeClass('invalid-cell');
        }
    }

    private showErrorCell(isShow: boolean) {
        this.requiredValidate = isShow;
        this.datagrid.instance.repaint();
    }

    public onSaved(e) {
        if (this.requiredValidate && !!(e.changes[0]?.data?.priceCode)) {
            this.showErrorCell(false);
        }
    }

    public refresh() {
        this.populateData(this.priceRuleView);
    }

    public getCurrencies(e) {
        const priceId = e.data.priceCode;
        if (!priceId) {
            return '';
        }
        
        return this.getCurrencyNames(this.getDistinctCurrencyCodes(priceId)).join(', ');
    }

    private getDistinctCurrencyCodes(priceId: string) {
        return [...new Set(this.priceRuleCurrencies
            .filter(c => c.priceId == priceId)
            .map(c => c.currencyCode))]
    }

    private getCurrencyNames(currencyCodes: string[]): string[] {
        return currencyCodes.map(currencyCode => 
            this.currencyReferences.find(cr => cr.currencyCode == currencyCode).currencyName);
    }
}
export const select2Status = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2ServiceRequest = {
    placeholder: "",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateSelection: null
}

export const select2Search = {
    placeholder: "",
    allowClear: true
}

 
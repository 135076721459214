import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { select2SearchDistributionType, select2SearchDocumentType, select2SearchRecieverOrganisation, select2SearchStatusType, select2SearchTeletypeAddressElementPriorit } from "../../document-distribution-search/shared/select2-configuration";
import { DocumentDistributionGeneralDetailFormOption } from "../shared/form-config";
import { calendarDateOption, timeOption } from "../shared/select2-configuration";
import { DistributionTypeReferenceModel, DocumentTypeReferenceModel, MessagePriorityReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { DocumentDistributionCommandModel, DocumentDistributionModel } from "src/app/core/models/document-distribution-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { Globals } from "src/app/app.global-variable";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { cloneDeep } from "lodash";

declare var moment: any;

@Component({
    selector: 'op-document-distribution-detail-general',
    templateUrl: './document-distribution-detail-general.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentDistributionDetailGeneralComponent extends OopsComponentFormBase implements OnChanges {
    private readonly DISTRIBUTION_TYPE_FORM_NAME = "distributionTypeCode";
    private readonly DOCUMENT_TYPE_FORM_NAME = "documentTypeCode";
    private readonly MAIL_ADDRESS_SENDER_FORM_NAME = "mailAddressElementSender";
    private readonly MAIL_ADDRESS_RECEIVER_FORM_NAME = "mailAddressElementReceiver";
    private readonly MAIL_SUBJECT_FORM_NAME = "mailSubjectElement";
    private readonly ATTACHMENT_FLAG_FORM_NAME = "attachmentFlag";
    private readonly TELETYPE_ADDRESS_SENDER_FORM_NAME ="teletypeAddressElementSender";
    private readonly TELETYPE_ADDRESS_RECEIVER_FORM_NAME ="teletypeAddressElementReceiver";
    private readonly PRIMARY_DOCUMENT_DISTRIBUTION_FORM_NAME = "primaryDocumentDistributionId";

    private readonly SMTP_DISTRIBUTION_CODE = "SMTP";
    private readonly API_DISTRIBUTION_CODE = "API";
    private readonly OFFSET_FORM_NAMES = ["offsetDays", "offsetHours", "offsetMinutes", "offsetPriorToEventFlag"];
    private readonly EXECUTION_TIME_FORM_NAMES = ["localExecutionDate", "localExecutionTime"];
    private readonly TIME_DEFINITION_FORM_NAME = "timeDefinitionCode";
    private readonly INTERVAL_FORM_NAMES = ["intervalDays", "intervalHours", "intervalMinutes", "intervalSeconds"];

    private readonly TIME_DEFINITION_CODE_INTERVAL = "interval";
    private readonly TIME_DEFINITION_CODE_OFFSET = "offset";
    private readonly TIME_DEFINITION_CODE_SPECIFICTIME = "specifictime";
    private readonly TIME_DEFINITION_CODE_SPECIFICDATETIME = "specificdatetime";

    private readonly ERROR_ORGANISATION_REQUIRED = 'Receiver Organisation is required.';
    private readonly ERROR_DOCUMENT_TYPE_REQUIRED = 'Document Type is required.';
    private readonly ERROR_DISTRIBUTION_TYPE_REQUIRED = 'Distribution Type is required.';
    public readonly ERROR_LOCAL_TIME_REQUIRED = 'Execution Time (UTC) is required.';
    private readonly ERROR_STATUS_REQUIRED = 'Status is required.';
    private readonly ERROR_TELETYPE_SENDER_REQUIRED = 'Teletype Address Sender is required.';
    private readonly ERROR_TELETYPE_RECEIVER_REQUIRED = 'Teletype Address Receiver is required.';
    private readonly ERROR_MAIL_SENDER_REQUIRED = 'Mail Address Sender is required.';
    private readonly ERROR_MAIL_RECEIVER_REQUIRED = 'Mail Address Sender is required.';
    private readonly ERROR_MAIL_SENDER_NOT_EMAIL = 'Mail Address Sender is invalid.';
    private readonly ERROR_MAIL_RECEIVER_NOT_EMAIL = 'Mail Address Receiver is invalid.';
    public readonly ERROR_TIME_DEFINITION_REQUIRED = 'Time Definition is required.';
    public readonly ERROR_EXECUTION_DATE_TIME_REQUIRED = 'Execution Date (UTC) and Time (UTC) are required.';
    public readonly ERROR_INTERVAL_REQUIRED = 'At least one interval is required.';
    public readonly ERROR_OFFSET_REQUIRED = 'At least one offset is required.';
    public readonly ERROR_NAME_REQUIRED = 'Name is required.';

    public focusing: boolean = false;
    public collapsed: boolean = false;

    public recieverOrganisationOption: any;
    public documentTypeOption: any;
    public distributionTypeOption: any;
    public statusOption: any;
    public calendarDateOption: any;
    public calendarTimeOption: any;
    public teletypeAddressElementPriorityOption: any;
    public timeDefinitionOption: any;
    public primaryDocumentDistributionOption: any;
    public timeDefinitions: Select2Data[] = [{
        id: this.TIME_DEFINITION_CODE_INTERVAL,
        text: "Interval",
        selected: null,
        disabled: false
    },
    {
        id: this.TIME_DEFINITION_CODE_OFFSET,
        text: "Offset",
        selected: null,
        disabled: false
    },
    {
        id: this.TIME_DEFINITION_CODE_SPECIFICTIME,
        text: "Specific Time",
        selected: null,
        disabled: false
    },
    {
        id: this.TIME_DEFINITION_CODE_SPECIFICDATETIME,
        text: "Specific Date Time",
        selected: null,
        disabled: false
    }
    ]

    public readonly TIME_ONLY_FORMAT = 'HH:mm';
    private readonly DATETIME_WITHOUT_DATE = "1899-12-31";

    @Input() documentDistribution: DocumentDistributionModel;
    @Input() recieverOrganisations: OrganisationModel[];
    @Input() documentTypeReferences: DocumentTypeReferenceModel[];
    @Input() distributionTypeReferences: DistributionTypeReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() messagePriorityReferences: MessagePriorityReferenceModel[];
    @Input() actionSecurity: SecurityGroupSecurityModel;
    @Input() primaryDocumentDistributions: DocumentDistributionModel[];

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    get localExecutionTime() {
        return (<UntypedFormControl>this.formGroup.get('localExecutionTime')).value;
    }

    set localExecutionTime(value: string | number | Date) {
        this.formGroup.controls['localExecutionTime'].setValue(value);
    }

    get isSmtpDistributionTypeCode() {
        return (<UntypedFormControl>this.formGroup.get(this.DISTRIBUTION_TYPE_FORM_NAME)).value == this.SMTP_DISTRIBUTION_CODE;
    }

    get isTeletyleAddressRequied() {
        return (<UntypedFormControl>this.formGroup.get(this.DOCUMENT_TYPE_FORM_NAME)).value != this.SMTP_DISTRIBUTION_CODE;
    }

    get showTimeLimitTimeError() {
        return !this.isOffSetFormControlHaveValue() && !this.isLocalExecutionTimeHaveValue();
    }

    public executeDateTimeIsRequired: boolean = false;
    public executeTimeIsRequired: boolean = false;
    public intervalIsRequired: boolean = false;
    public offsetIsRequired: boolean = false;

    public primaryDocumentDistributionFilters: DocumentDistributionModel[];
    constructor(private formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService,
        private globals: Globals) {
        super(formBuilder);
        this.recieverOrganisationOption = select2SearchRecieverOrganisation;
        this.documentTypeOption = select2SearchDocumentType;
        this.distributionTypeOption = select2SearchDistributionType;
        this.statusOption = select2SearchStatusType;
        this.teletypeAddressElementPriorityOption = select2SearchTeletypeAddressElementPriorit;
        this.timeDefinitionOption = select2SearchDocumentType;
        this.primaryDocumentDistributionOption = cloneDeep(select2SearchDocumentType); 
        this.initForm();
        this.setControlOption();
    }

    private isOffSetFormControlHaveValue() {
        return this.formGroup.get("offsetDays").value ||
            this.formGroup.get("offsetHours").value ||
            this.formGroup.get("offsetMinutes").value
    }

    private isLocalExecutionTimeHaveValue() {
        return this.formGroup.get("localExecutionDate").value ||
            this.formGroup.get("localExecutionTime").value
    }

    private setControlOption() {
        if (this.documentDistribution && this.documentDistribution.distributionTypeCode == this.SMTP_DISTRIBUTION_CODE) {
            this.formGroup.controls[this.MAIL_ADDRESS_SENDER_FORM_NAME].enable();
            this.formGroup.controls[this.MAIL_ADDRESS_RECEIVER_FORM_NAME].enable();
            this.formGroup.controls[this.MAIL_SUBJECT_FORM_NAME].enable();
            this.formGroup.controls[this.ATTACHMENT_FLAG_FORM_NAME].enable();
        }
    }

    public initForm() {

        this.setDatetimePicker();
        this.formSetup();
        this.clearWarningMessage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['documentDistribution']) {
            this.formSetup();
        }

        if (!this.documentDistribution) {
            this.formReset();
        } else {
            this.fillModelToForm(this.documentDistribution);
        }
    }

    formReset() {
        this.formGroup.reset();
        this.formGroup.patchValue({
            statusCode: 'A'
        });
        this.clearWarningMessage();
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(cloneDeep(DocumentDistributionGeneralDetailFormOption));
    }

    private fillModelToForm(model: DocumentDistributionModel) {
        let timeDefinitionCode = this.getTimeDefinitionCodeToForm(model);
        this.setFormControlTimeDefinition(timeDefinitionCode);
        this.setFormControlRequiredByDistributionCode(model.distributionTypeCode);
        this.setFormControlRequiredByDocumentTypeCode(model.documentTypeCode);
        this.setPrimaryDocumentDistribution(model.documentTypeCode);
        this.formGroup.patchValue({
            organisationId: model.organisationId,
            documentTypeCode: model.documentTypeCode,
            distributionTypeCode: model.distributionTypeCode,
            documentDistributionDescription: model.documentDistributionDescription,
            localExecutionDate: this.setDate(model.utcExecutionTime),
            localExecutionTime: this.setTime(model.utcExecutionTime),
            offsetDays: model.offsetDays,
            offsetHours: model.offsetHours,
            offsetMinutes: model.offsetMinutes,
            offsetPriorToEventFlag: model.offsetPriorToEventFlag ?? false,
            attachmentFlag: model.attachmentFlag ?? false,
            teletypeAddressElementSender: model.teletypeAddressElementSender,
            teletypeAddressElementReceiver: model.teletypeAddressElementReceiver,
            teletypeAddressElementPriority: model.teletypeAddressElementPriority,
            maximumMessageSize: model.maximumMessageSize,
            mailAddressElementSender: model.mailAddressElementSender,
            mailAddressElementReceiver: model.mailAddressElementReceiver,
            mailSubjectElement: model.mailSubjectElement,
            statusCode: model.statusCode,
            commitByName: model.commitByName,
            commitDateTime: this.dateConverterService.convertDate(moment(model.commitDateTime)),
            intervalDays: model.intervalDays,
            intervalHours: model.intervalHours,
            intervalMinutes: model.intervalMinutes,
            intervalSeconds: model.intervalSeconds,
            timeDefinitionCode: timeDefinitionCode,
            documentDistributionName: model.documentDistributionName,
            primaryDocumentDistributionId: model.primaryDocumentDistributionId
        });
        this.clearWarningMessage();
        this.teletypeMandatory(model.distributionTypeCode);
    }

    private setTime(utcExecutionTime: Date): string {
        if (utcExecutionTime) {
            let time = this.dateConverterService.convertDateTime24(utcExecutionTime);
            return time;
        }
        return null;
    }

    private setDate(utcExecutionTime: Date): string {
        if (!utcExecutionTime || new Date(utcExecutionTime).getFullYear() < 2000) {
            return null;
        }

        let date = this.dateConverterService.toDateFormat(utcExecutionTime);
        return date;
    }


    private setDatetimePicker() {
        this.calendarDateOption = calendarDateOption;
        this.calendarDateOption.minDate = new Date();

        this.calendarTimeOption = timeOption;
    }

    public valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);

        if (name == this.DISTRIBUTION_TYPE_FORM_NAME) {
            this.setFormControlRequiredByDistributionCode(value);
            return;
        }

        if (name == this.DOCUMENT_TYPE_FORM_NAME) {
            this.setFormControlRequiredByDocumentTypeCode(value);
            this.setPrimaryDocumentDistribution(value);
            return;
        }

        if (name == this.TIME_DEFINITION_FORM_NAME) {
            this.setFormControlTimeDefinition(value);
            return;
        }

        if (this.OFFSET_FORM_NAMES.includes(name) || this.EXECUTION_TIME_FORM_NAMES.includes(name)) {
            this.setLocalExecutionDateFormControlRequired();
            return;
        }
    }

    public offsetValueChange() {
        this.setLocalExecutionDateFormControlRequired();
        return;
    }

    private setFormControlRequiredByDistributionCode(code: string) {
        if (code == this.SMTP_DISTRIBUTION_CODE) {
            this.setEmailDetailFormControls(true);
            this.setFormControlReqired("mailAddressElementSender", true);
            this.setFormControlReqired("mailAddressElementReceiver", true);
        }
        else {
            this.setEmailDetailFormControls(false);
            this.setFormControlReqired("mailAddressElementSender", false);
            this.setFormControlReqired("mailAddressElementReceiver", false);
        }
        this.teletypeMandatory(code);
    }

    private setFormControlRequiredByDocumentTypeCode(code: string) {
        let formatCode = this.documentTypeReferences.find(x => x.documentTypeCode == code).documentFormatCode;
        let distributionTypeCode = this.formGroup.controls[this.DISTRIBUTION_TYPE_FORM_NAME].value;
        if (code != "PNR" && formatCode == "TTY" && distributionTypeCode != "API") {
            this.setFormControlReqired("teletypeAddressElementSender", true);
            this.setFormControlReqired("teletypeAddressElementReceiver", true);
        }
        else {
            this.setFormControlReqired("teletypeAddressElementSender", false);
            this.setFormControlReqired("teletypeAddressElementReceiver", false);
        }
    }

    private setEmailDetailFormControls(flag: boolean) {
        if (flag) {
            this.formGroup.controls[this.MAIL_ADDRESS_SENDER_FORM_NAME].enable();
            this.formGroup.controls[this.MAIL_ADDRESS_RECEIVER_FORM_NAME].enable();
            this.formGroup.controls[this.MAIL_SUBJECT_FORM_NAME].enable();
            this.formGroup.controls[this.ATTACHMENT_FLAG_FORM_NAME].enable();
        }
        else {
            this.formGroup.controls[this.MAIL_ADDRESS_SENDER_FORM_NAME].disable();
            this.formGroup.controls[this.MAIL_ADDRESS_SENDER_FORM_NAME].setValue(null);
            this.formGroup.controls[this.MAIL_ADDRESS_RECEIVER_FORM_NAME].disable();
            this.formGroup.controls[this.MAIL_ADDRESS_RECEIVER_FORM_NAME].setValue(null);
            this.formGroup.controls[this.MAIL_SUBJECT_FORM_NAME].disable();
            this.formGroup.controls[this.MAIL_SUBJECT_FORM_NAME].setValue(null);
            this.formGroup.controls[this.ATTACHMENT_FLAG_FORM_NAME].disable();
            this.formGroup.controls[this.ATTACHMENT_FLAG_FORM_NAME].setValue(null);
        }
        this.formGroup.controls[this.MAIL_SUBJECT_FORM_NAME].disabled
    }

    private setFormControlReqired(name: string, requiredFlag: boolean) {
        if (requiredFlag) {
            this.formGroup.get(name).addValidators(Validators.required)
            this.formGroup.get(name).updateValueAndValidity();
        }
        else {
            this.formGroup.get(name).clearValidators();
            this.formGroup.get(name).updateValueAndValidity();
        }
    }

    private setLocalExecutionDateFormControlRequired() {
        if (!this.isOffSetFormControlHaveValue() && !this.isLocalExecutionTimeHaveValue()) {
            this.setFormControlReqired("localExecutionDate", true);
        }
        else {
            this.setFormControlReqired("localExecutionDate", false);
        }
    }

    public checkValid(): boolean {
        this.startProcessing();
        if (!this.validForm() || this.timeDefinitionCodeInConditionRequired()) {
            this.changeDetectionRef.detectChanges();
            return false;
        }

        this.completeProcessing();
        return true;

    }

    public getValue(): DocumentDistributionCommandModel {
        this.startProcessing();
        if (!this.validForm()) {
            this.changeDetectionRef.detectChanges();
            return;
        }
        this.completeProcessing();
        return this.fillFormToModel();
    }

    private fillFormToModel(): DocumentDistributionCommandModel {
        let formValue = this.formGroup.getRawValue();
        let editCommand: DocumentDistributionCommandModel = {
            organisationId: formValue.organisationId ?? null,
            documentTypeCode: formValue.documentTypeCode ?? null,
            distributionTypeCode: formValue.distributionTypeCode ?? null,
            documentDistributionDescription: formValue.documentDistributionDescription ?? null,
            mailAddressElementSender: formValue.mailAddressElementSender ?? null,
            mailAddressElementReceiver: formValue.mailAddressElementReceiver ?? null,
            mailSubjectElement: formValue.mailSubjectElement ?? null,
            teletypeAddressElementSender: formValue.teletypeAddressElementSender ?? null,
            teletypeAddressElementReceiver: formValue.teletypeAddressElementReceiver ?? null,
            teletypeAddressElementPriority: formValue.teletypeAddressElementPriority ?? null,
            maximumMessageSize: formValue.maximumMessageSize ?? null,
            utcExecutionTime: this.convertExcutionDateTimeToSave(formValue.localExecutionDate, formValue.localExecutionTime),
            offsetDays: formValue.offsetDays ?? null,
            offsetHours: formValue.offsetHours ?? null,
            offsetMinutes: formValue.offsetMinutes ?? null,
            offsetPriorToEventFlag: formValue.offsetPriorToEventFlag ?? null,
            attachmentFlag: formValue.attachmentFlag ?? null,
            statusCode: formValue.statusCode ?? null,
            documentDistributionRules: null,
            documentDistributionEvents: null,
            documentDistributionAttributes: null,
            intervalDays: formValue.intervalDays ?? null,
            intervalHours: formValue.intervalHours ?? null,
            intervalMinutes: formValue.intervalMinutes ?? null,
            intervalSeconds: formValue.intervalSeconds ?? null,
            documentDistributionName: formValue.documentDistributionName,
            primaryDocumentDistributionId: formValue.primaryDocumentDistributionId
        }

        return editCommand;
    }

    public getErrorMessage() {
        if (this.formGroup.controls['documentDistributionName']?.errors?.required) {
            return this.ERROR_NAME_REQUIRED;
        } else if (!this.formGroup.controls['organisationId'].value) {
            return this.ERROR_ORGANISATION_REQUIRED;
        } else if (!this.formGroup.controls['documentTypeCode'].value) {
            return this.ERROR_DOCUMENT_TYPE_REQUIRED;
        } else if (!this.formGroup.controls['distributionTypeCode'].value) {
            return this.ERROR_DISTRIBUTION_TYPE_REQUIRED;
        } else if (this.formGroup.controls['mailAddressElementSender']?.errors?.required) {
            return this.ERROR_MAIL_SENDER_REQUIRED;
        } else if (this.formGroup.controls['mailAddressElementSender']?.errors?.notEmail) {
            return this.ERROR_MAIL_SENDER_NOT_EMAIL;
        } else if (this.formGroup.controls['mailAddressElementReceiver']?.errors?.required) {
            return this.ERROR_MAIL_RECEIVER_REQUIRED;
        } else if (this.formGroup.controls['mailAddressElementReceiver']?.errors?.notEmail) {
            return this.ERROR_MAIL_RECEIVER_NOT_EMAIL;
        } else if (this.formGroup.controls['teletypeAddressElementSender']?.errors?.required) {
            return this.ERROR_TELETYPE_SENDER_REQUIRED;
        } else if (this.formGroup.controls['teletypeAddressElementReceiver']?.errors?.required) {
            return this.ERROR_TELETYPE_RECEIVER_REQUIRED;
        } else if (this.formGroup.controls['timeDefinitionCode']?.errors?.required) {
            return this.ERROR_TIME_DEFINITION_REQUIRED;
        } else if (this.timeDefinitionCodeInConditionRequired()) {
            return this.getErrorMessagetimeDefinitionCodeInCondition();
        } else if (!this.formGroup.controls['statusCode'].value) {
            return this.ERROR_STATUS_REQUIRED;
        }
    }

    private setFormControlTimeDefinition(timeDefinitionCode: string) {
        this.disabledTimeDefinitionControlAll();
        switch (timeDefinitionCode) {
            case this.TIME_DEFINITION_CODE_INTERVAL:
                this.setExcutionDateTimeControlEnable();
                this.setIntervalControlEnable();
                break;
            case this.TIME_DEFINITION_CODE_OFFSET:
                this.setExcutionDateTimeControlEnable();
                this.setIntervalControlEnable();
                this.setOffsetControlEnable();
                break;
            case this.TIME_DEFINITION_CODE_SPECIFICTIME:
                this.formGroup.controls["localExecutionTime"].enable();
                break;
            case this.TIME_DEFINITION_CODE_SPECIFICDATETIME:
                this.setExcutionDateTimeControlEnable();
                break;
        }
    }

    public disabledTimeDefinitionControlAll() {
        this.setExcutionDateTimeControlDisabled();
        this.setIntervalControlDisabled();
        this.setOffsetControlDisabled();
        this.clearWarningMessage();
    }

    private setIntervalControlEnable() {
        for (let ctrlName of this.INTERVAL_FORM_NAMES) {
            this.formGroup.controls[ctrlName].enable();
        }
    }

    private setExcutionDateTimeControlEnable() {
        for (let ctrlName of this.EXECUTION_TIME_FORM_NAMES) {
            this.formGroup.controls[ctrlName].enable();
        }
    }

    private setOffsetControlEnable() {
        for (let ctrlName of this.OFFSET_FORM_NAMES) {
            this.formGroup.controls[ctrlName].enable();
        }
    }

    private setIntervalControlDisabled() {
        for (let ctrlName of this.INTERVAL_FORM_NAMES) {
            this.formGroup.controls[ctrlName].setValue(null);
            this.formGroup.controls[ctrlName].disable();
        }
    }

    private setExcutionDateTimeControlDisabled() {
        for (let ctrlName of this.EXECUTION_TIME_FORM_NAMES) {
            this.formGroup.controls[ctrlName].setValue(null);
            this.formGroup.controls[ctrlName].disable();
        }
    }

    private setOffsetControlDisabled() {
        for (let ctrlName of this.OFFSET_FORM_NAMES) {
            this.formGroup.controls[ctrlName].setValue(null);
            this.formGroup.controls[ctrlName].disable();
        }
    }

    public timeDefinitionCodeInConditionRequired(): boolean {
        let timeDefinitionCode = this.formGroup.controls[this.TIME_DEFINITION_FORM_NAME].value;
        switch (timeDefinitionCode) {
            case this.TIME_DEFINITION_CODE_INTERVAL:
                return this.executionDateAndTimeRequiredBothOrEmpty() || this.intervalRequired();
            case this.TIME_DEFINITION_CODE_OFFSET:
                return this.executionDateAndTimeRequiredBothOrEmpty() || this.offsetRequired();
            case this.TIME_DEFINITION_CODE_SPECIFICTIME:
                return this.executeTimeRequired();
            case this.TIME_DEFINITION_CODE_SPECIFICDATETIME:
                return this.executionDateAndTimeRequired();
        }
        return false;
    }

    private executionDateAndTimeRequiredBothOrEmpty(): boolean {
        if (this.formGroup.controls["localExecutionDate"].value && !this.formGroup.controls["localExecutionTime"].value) {
            return true;
        }
        if (!this.formGroup.controls["localExecutionDate"].value && this.formGroup.controls["localExecutionTime"].value) {
            return true;
        }
        return false;
    }

    private intervalRequired(): boolean {
        return !(this.formGroup.get("intervalDays").value ||
            this.formGroup.get("intervalHours").value ||
            this.formGroup.get("intervalMinutes").value ||
            this.formGroup.get("intervalSeconds").value);
    }

    private offsetRequired(): boolean {
        return !(this.formGroup.get("offsetDays").value ||
            this.formGroup.get("offsetHours").value ||
            this.formGroup.get("offsetMinutes").value);
    }

    private executeTimeRequired(): boolean {
        if (this.formGroup.get("localExecutionTime").value) {
            return false;
        }
        return true;
    }

    private executionDateAndTimeRequired(): boolean {
        return !(this.formGroup.controls["localExecutionDate"].value && this.formGroup.get("localExecutionTime").value);
    }

    private getErrorMessagetimeDefinitionCodeInCondition(): string {
        this.clearWarningMessage();
        let timeDefinitionCode = this.formGroup.controls[this.TIME_DEFINITION_FORM_NAME].value;
        switch (timeDefinitionCode) {
            case this.TIME_DEFINITION_CODE_INTERVAL:
                if (this.executionDateAndTimeRequiredBothOrEmpty()) {
                    this.executeDateTimeIsRequired = true;
                    return this.ERROR_EXECUTION_DATE_TIME_REQUIRED;
                }
                if (this.intervalRequired()) {
                    this.intervalIsRequired = true;
                    return this.ERROR_INTERVAL_REQUIRED;
                }
                break;
            case this.TIME_DEFINITION_CODE_OFFSET:
                if (this.executionDateAndTimeRequiredBothOrEmpty()) {
                    this.executeDateTimeIsRequired = true;
                    return this.ERROR_EXECUTION_DATE_TIME_REQUIRED;
                }
                if (this.offsetRequired()) {
                    this.offsetIsRequired = true;
                    return this.ERROR_OFFSET_REQUIRED;
                }
                break;
            case this.TIME_DEFINITION_CODE_SPECIFICTIME:
                if (this.executeTimeRequired()) {
                    this.executeTimeIsRequired = true;
                    return this.ERROR_LOCAL_TIME_REQUIRED;
                }
                break;
            case this.TIME_DEFINITION_CODE_SPECIFICDATETIME:
                if (this.executionDateAndTimeRequired()) {
                    this.executeDateTimeIsRequired = true;
                    return this.ERROR_EXECUTION_DATE_TIME_REQUIRED;
                }
                break;
        }
    }

    private convertExcutionDateTimeToSave(localExecutionDate: string, localExecutionTime: string): string {
        if (!localExecutionDate && !localExecutionTime) {
            return null;
        }
        let d =  new Date((localExecutionDate ?? "1899-12-31") + " " + (this.dateConverterService.convertTime24(new Date(localExecutionTime)) ?? "00:00"));
        return this.dateConverterService.convertDateTime24(d);
    }


    private getTimeDefinitionCodeToForm(model: DocumentDistributionModel): string {
        if (model.offsetDays || model.offsetHours || model.offsetMinutes) {
            return this.TIME_DEFINITION_CODE_OFFSET;
        }
        if (model.intervalDays || model.intervalHours || model.intervalMinutes || model.intervalSeconds) {
            return this.TIME_DEFINITION_CODE_INTERVAL;
        }
        if (this.setDate(model.utcExecutionTime)) {
            return this.TIME_DEFINITION_CODE_SPECIFICDATETIME;
        }
        return this.TIME_DEFINITION_CODE_SPECIFICTIME;
    }

    private clearWarningMessage() {
        this.executeDateTimeIsRequired = false;
        this.executeTimeIsRequired = false;
        this.intervalIsRequired = false;
        this.offsetIsRequired = false;
    }

    private teletypeMandatory(code : string) {
        if (code == this.API_DISTRIBUTION_CODE) {
            this.setFormControlReqired(this.TELETYPE_ADDRESS_SENDER_FORM_NAME, false);
            this.setFormControlReqired(this.TELETYPE_ADDRESS_RECEIVER_FORM_NAME, false);
        } else {
            this.setFormControlReqired(this.TELETYPE_ADDRESS_SENDER_FORM_NAME, true);
            this.setFormControlReqired(this.TELETYPE_ADDRESS_RECEIVER_FORM_NAME, true);
        }
    }

    private setPrimaryDocumentDistribution(documentTypeCode: string) {
        if (!this.primaryDocumentDistributions) {
            return;
        }
        this.formGroup.controls[this.PRIMARY_DOCUMENT_DISTRIBUTION_FORM_NAME].enable();
        if (documentTypeCode == "ADL") {
            this.primaryDocumentDistributionFilters = this.primaryDocumentDistributions.filter(x => x.documentTypeCode == "PNL");
        } else if (documentTypeCode == "CAL") {
            this.primaryDocumentDistributionFilters = this.primaryDocumentDistributions.filter(x => x.documentTypeCode == "PAL");
        } else {
            this.primaryDocumentDistributionFilters = [];
            this.formGroup.controls[this.PRIMARY_DOCUMENT_DISTRIBUTION_FORM_NAME].setValue(null);
            this.formGroup.controls[this.PRIMARY_DOCUMENT_DISTRIBUTION_FORM_NAME].disable();
        }
    }

    public setEmptyValueForCopy() {
        this.formGroup.controls['documentDistributionName'].setValue(null); 
        this.formGroup.controls['primaryDocumentDistributionId'].setValue(null); 
        this.changeDetectionRef.detectChanges();
    }
}

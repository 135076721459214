import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FeeViewModel } from "src/app/core/models/product-model/fee-model";
import { UsageTypeReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { ProductGroupReferenceModel, ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2DefaultOptionWithAllowClear } from "../../../merchandize/merchandize-search/shared/merchandize-search-information-configuration";
import { select2DefaultWithSearchBox } from "../../../special-service/special-service-content/special-service-search/shared/specialservice-search-information-configuration";

@Component({
    selector: 'op-fee-search-condition',
    templateUrl: './fee-search-condition.component.html'
})
export class FeeSearchConditionComponent extends OopsComponentFormBase implements OnChanges {
    readonly ACTIVE_STATUS: string = "A";
    @Input() advanceSearchMode: boolean;
    @Input() productSearchFilterId: string | string[] = null;
    @Input() feeView: FeeViewModel;
    @Input() productSearchFilters: Select2Data[];
    @Input() productGroupReference: ProductGroupReferenceModel[];
    @Input() productTypeGroup: ProductTypeGroupModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Input() statusReference: StatusReferenceModel[];
    @Input() saveFilter: boolean = false;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onSearch = new EventEmitter<FeeViewModel>();
    @Output() onClear = new EventEmitter<FeeViewModel>();
    @Output() productSearchFilterIdChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public productTypeGroupFilter: ProductTypeGroupModel[];

    focusing: boolean = false;
    select2DefaultOptionWithSearchBox = select2DefaultWithSearchBox;
    select2DefaultOption = select2DefaultOptionWithAllowClear;

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get productTypeGroupCode(): string | string[] {
        return this.form.controls['productTypeGroupCode'].value;
    }

    set productTypeGroupCode(value: string | string[]) {
        this.form.controls['productTypeGroupCode'].patchValue(value);
    }

    get productGroupCode(): string | string[]{
        return this.form.controls['productGroupCode'].value;
    }

    set productGroupCode(value: string | string[]) {
        this.form.controls['productGroupCode'].patchValue(value);
    }

    get statusCode(): string | string[]{
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }    

    get usageTypeCode(): string | string[]{
        return this.form.controls['usageTypeCode'].value;
    }

    set usageTypeCode(value: string | string[]) {
        this.form.controls['usageTypeCode'].patchValue(value);
    } 

    constructor(
        private formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private actionService: ActionService) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["feeView"]) {
            let view = this.feeView;
            this.productSearchFilterId = view?.productId;
            if (this.formGroup) {
                this.fillViewToForm(view);
            }
        }

        if (changes["advanceSearchMode"]) {
            this.saveFilter = false;
        }
    }

    public initForm() {
        this.formGroup = this.formBuilder.group({
            productTypeGroupCode: [null],
            productGroupCode: [null],
            productName: [null],
            usageTypeCode: [null],
            statusCode: [this.ACTIVE_STATUS]
        });
        if (this.productSearchFilterId) {
            this.onProductSearchFilterIdChange(this.productSearchFilterId)
        }
    }

    public fillViewToForm(view: FeeViewModel) {
        this.formGroup.patchValue({
            productTypeGroupCode: view.productTypeCode,
            productName: view.searchName,
            productGroupCode: view.productGroupCode,
            usageTypeCode: view.searchUsageTypeCode,
            statusCode: view.searchStatusCode
        });
        this.filterProductTypeGroup(view.productGroupCode);
    }

    onAdvanceSearchClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    doSearch() {
        this.onSearch.emit(this.getValues());
    }

    clearCondition() {
        this.saveFilter = false;
        this.initForm();
        this.productSearchFilterId = null;
        this.onClear.emit(this.getValues());
    }

    onProductSearchFilterIdChange(productId: string | string[]) {
        let productIdStr = <string>productId;
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/FEE', productIdStr, this.getSearchFilterName(productIdStr));
        this.productSearchFilterIdChange.emit(productIdStr);
        if (!productId) {
            this.initForm();
        }
    }

    public getValues(saveFilter: boolean = false): FeeViewModel {
        this.saveFilter = saveFilter;
        this.changeDetectionRef.detectChanges();
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): FeeViewModel {
        let model = {} as FeeViewModel;
        model.searchName = this.getFormControlValue("productName");
        model.productGroupCode = this.getFormControlValue("productGroupCode");
        model.productTypeCode = this.getFormControlValue("productTypeGroupCode");
        model.searchUsageTypeCode = this.getFormControlValue("usageTypeCode");
        model.searchStatusCode = this.getFormControlValue("statusCode");
        model.statusCode = this.ACTIVE_STATUS;
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public productGroupChange(value) {
        this.filterProductTypeGroup(value);
    }

    public filterProductTypeGroup(productGroupCode) {
        this.productTypeGroupFilter = [];
        if (productGroupCode) {
            var filter = this.productTypeGroup.filter(x => x.productGroupCode == productGroupCode);
            this.productTypeGroupFilter = filter;
        }

    }

    private getSearchFilterName(productId: string) {
        return this.productSearchFilters?.find(item => item.id == productId)?.text
    }
}
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DomainAttributeModel
} from "src/app/core/models/reference-model/reference-general-model";

import { PricingService } from "src/app/core/services/pricing-services";
import {
    CalendarValidityService,
    ConditionReferenceService,
    DateTimeDimensionReferenceService
} from "src/app/core/services/system-services";
import { Helper } from "src/app/shared/helper/app.helper";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PricingDetailConstant } from "../../shared/pricing-detail.constant";
import { ProductEligibleRestrictedConverter } from "../product-eligible-restricted/shared/product-eligible-restricted.converter";
import { Select2OptionsService } from "../product-eligible-restricted/shared/select2-options.service";
import {
    PriceRuleAttributeAndRuleRowView,
    PriceRuleAttributeView,
    PriceRuleSalesBucketView,
    PriceRuleServiceCategoryView,
    PriceRuleValidityView
} from "../product-eligible-restricted/shared/views";
import { ServiceCategoriesAndSalesBucketConverter } from "../service-categories-and-sales-bucket/shared/service-categories-and-sales-bucket.converter";
import { AttributeAndRuleBase } from "../shared/attribute-and-rule.base";
import { Select2Option, } from "../shared/attribute-and-rule/views/select2.option";
import { takeUntil } from "rxjs/operators";
import { DomainAttributeService } from "src/app/core/services/airline-services";

declare var moment: any;

@Component({
    selector: "op-price-rule-detail-attribute-and-rule-roundtrip-definition",
    templateUrl: "./roundtrip-definition.component.html",
    providers: [
        Helper,
        ServiceCategoriesAndSalesBucketConverter,
        ProductEligibleRestrictedConverter,
        ToastrService,
        PricingService,
    ],
})
export class RoundtripDefinitionComponent extends AttributeAndRuleBase implements OnInit, OnDestroy {
    public rows$ = new BehaviorSubject<Array<PriceRuleAttributeAndRuleRowView>>(
        Array<PriceRuleAttributeAndRuleRowView>()
    );

    public focusing: boolean = false;

    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.togglePanelCollapseStatus(false);
            this.setDateTimeOptions();
        }
    }
    @Input() searchMode = false;
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    public select2ServiceSalesOption = new Select2Option("<Type>");
    public select2ServiceSalesDataList = [
        new Select2Data(),
        new Select2Data(
            PricingDetailConstant.SERVICES_CATEGORIES_CODE,
            "Services Categories"
        ),
        new Select2Data(PricingDetailConstant.SALES_BUCKET_CODE, "Sales Bucket"),
        new Select2Data(
            PricingDetailConstant.STAY_IN_DAYS_ATTRIBUTE_TYPE_CODE,
            "Stay in Days"
        ),
        new Select2Data(
            PricingDetailConstant.STOPOVER_HOURS_ATTRIBUTE_TYPE_CODE,
            "Stopover Hours"
        ),
        new Select2Data(
            PricingDetailConstant.STOPOVER_DAYS_ATTRIBUTE_TYPE_CODE,
            "Stopover Days"
        ),
        new Select2Data(
            PricingDetailConstant.STOPOVER_NIGHTS_ATTRIBUTE_TYPE_CODE,
            "Stopover Nights"
        ),
        new Select2Data(PricingDetailConstant.CALENDAR_CODE, "Calendar"),
        new Select2Data(
            PricingDetailConstant.ROUNDTRIP_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE,
            "Transit Minutes"
        ),
        new Select2Data(
            PricingDetailConstant.ROUNDTRIP_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE,
            "Transit Hours"
        ),
    ];
    public select2ServiceCategoriesOption = new Select2Option("<Type>");
    public select2InventoryDimensionOption = new Select2Option("<SubType>");
    public select2SalesBucketOption = new Select2Option("<Type>");
    public select2IsIsNotConditionOption = new Select2Option();
    public select2IsIsNotLessThanMoreThanConditionOption = new Select2Option();
    public select2DateTimeDimensionOption = new Select2Option("<Date Type>");
    public select2CalendarValidityOption = new Select2Option();
    public select2TimezoneOption = new Select2Option("<Timezone>");
    public select2TimezoneDataList = [
        new Select2Data(),
        new Select2Data(PricingDetailConstant.UTC_CODE, "UTC"),
        new Select2Data(PricingDetailConstant.LOCAL_CODE, "Local"),
    ];
    public select2IsIsNotIsBetweenIsNotBetweenConditionOption = new Select2Option();
    public dateRangePickerDateOption = new Select2Option();
    public dateRangePickerDateTimeOption = new Select2Option();
    public dateRangePickerTimeOption = new Select2Option();
    public dateRangePickerEndDateOptions = [];
    public dateRangePickerEndDateTimeOptions = [];
    public dateRangePickerEndTimeOptions = [];
    public select2WeekdayOption = new Select2Option("<Weekday>");
    public select2WeekdayDataList = PricingDetailConstant.SELECT2_WEEKDAY_DATALIST;

    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2ServiceCategoriesDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2SalesBucketDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2InventoryDimensionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotLessThanMoreThanConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2CalendarValidityDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2DateTimeDimensionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotIsBetweenIsNotBetweenConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public attributeTypeCodes$ = new BehaviorSubject<{}[]>(this.attributeTypeCodes);

    private dataFetched = false;
    private get attributeTypeCodes() {
        return [
            {
                code: PricingDetailConstant.STAY_IN_DAYS_ATTRIBUTE_TYPE_CODE,
            },
            {
                code: PricingDetailConstant.STOPOVER_HOURS_ATTRIBUTE_TYPE_CODE,
            },
            {
                code: PricingDetailConstant.STOPOVER_DAYS_ATTRIBUTE_TYPE_CODE,
            },
            {
                code: PricingDetailConstant.STOPOVER_NIGHTS_ATTRIBUTE_TYPE_CODE,
            },
            {
                code: PricingDetailConstant.ROUNDTRIP_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE,
            },
            {
                code:
                    PricingDetailConstant.ROUNDTRIP_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE,
            },
        ];
    }
    private unsubscribe$ = new Subject();

    get requiredAllOrNoneControlNames() {
        return [
            ["inventoryDimensionCode", "serviceCategoryThresholdValue"],
            ["inventoryDimensionCode", "salesBucketThresholdValue"],
        ];
    }

    constructor(
        private serviceCategoriesAndSalesBucketConverter: ServiceCategoriesAndSalesBucketConverter,
        private productEligibleConverter: ProductEligibleRestrictedConverter,
        private service: PricingService,
        private toastrService: ToastrService,
        protected select2OptionsService: Select2OptionsService,
        private conditionReferenceService: ConditionReferenceService,
        private calendarValidityService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private domainAttributeService: DomainAttributeService
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.select2IsIsNotConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotLessThanMoreThanConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotIsBetweenIsNotBetweenConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2CalendarValidityOption = this.select2OptionsService.getSelect2CalendarValidityOption();
        this.dateRangePickerDateOption = this.select2OptionsService.getDatePickerOption();
        this.dateRangePickerDateTimeOption = this.select2OptionsService.getDatetimePickerOption();
        this.dateRangePickerTimeOption = this.select2OptionsService.getTimePickerOption();
        this.select2WeekdayOption = this.select2OptionsService.getSelect2WeekdayOption();

        this.getInventoryDimensionReference();
        this.getSelect2IsIsNotConditionDataList();
        this.getSelect2IsIsNotLessThanMoreThanConditionDataList();
        this.getServiceCategoriesReference();
        this.getSalesBucketReference();
        this.getDateTimeDimensionReference();
        this.getCalendarValidityReference();
        this.getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList();
        this.getPriceRuleAttributeTypeIds();
    }
    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value;
            this.removeOptionalFieldsFromView(rows);
            rows.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    private removeOptionalFieldsFromView(rows) {
        for (let row of rows) {
            if (row.serviceCategory?.children?.length) {
                let child = row.serviceCategory.children[0];
                if (!child.inventoryDimensionCode && !child.thresholdValue) {
                    delete row.serviceCategory.children;
                }
            }
            if (row.salesBucket?.children?.length) {
                let child = row.salesBucket.children[0];
                if (!child.inventoryDimensionCode && !child.thresholdValue) {
                    delete row.salesBucket.children;
                }
            }
        }
    }

    onServiceSalesChange(event, row) {
        row.type = event;
        switch (event) {
            case PricingDetailConstant.SERVICES_CATEGORIES_CODE:
                this.createServiceCategory(row);
                break;
            case PricingDetailConstant.SALES_BUCKET_CODE:
                this.createSalesBucket(row);
                break;
            case PricingDetailConstant.STAY_IN_DAYS_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.STOPOVER_HOURS_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.STOPOVER_DAYS_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.STOPOVER_NIGHTS_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.ROUNDTRIP_TRANSIT_MINUTES_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.ROUNDTRIP_TRANSIT_HOURS_ATTRIBUTE_TYPE_CODE:
                this.createAttribute(row, event);
                break;
            case PricingDetailConstant.CALENDAR_CODE:
                this.createValidity(row);
                break;
            default:
                this.resetRow(row);
                break;
        }
    }

    private createValidity(row: PriceRuleAttributeAndRuleRowView) {
        this.resetRow(row);
        row.validity = new PriceRuleValidityView();
        row.validity.priceRuleAttributeGroupCode =
            PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE;
        row.validity.calendarValidityCode = PricingDetailConstant.SERVICE_CODE;
        this.getDateTimeDimensionReference();
    }

    private getDateTimeDimensionReference() {
        if (this.select2DateTimeDimensionDataList$.value?.length) {
            return;
        }
        this.dateTimeDimensionReferenceService
            .getDateTimeDimensionReference(
                PricingDetailConstant.CALENDAR_DATE,
                PricingDetailConstant.CALENDAR_DATE_TIME,
                PricingDetailConstant.CALENDAR_TIME,
                PricingDetailConstant.CALENDAR_WEEKDAY
            )
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res) => {
                    this.select2DateTimeDimensionDataList$.next(
                        this.productEligibleConverter.toDateTimeDimensionReferenceDataList(
                            res
                        )
                    );
                }
            );
    }

    private createAttribute(row: PriceRuleAttributeAndRuleRowView, attributeTypeCode: string) {
        this.resetRow(row);
        row.attribute = new PriceRuleAttributeView();
        row.attribute.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
        row.attribute.attributeTypeCode = this.attributeTypeCodes$.value.filter(
            (attr) => attr["code"] == attributeTypeCode
        )[0]["id"];
    }

    private getPriceRuleAttributeTypeIds() {
        for (let attributeType of this.attributeTypeCodes$.value) {
            let attributeTypeCode = attributeType["code"];
            this.domainAttributeService
                .getPriceRuleAttributes(
                    "",
                    PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE,
                    attributeTypeCode
                )
                .pipe(takeUntil(this.unsubscribe$)).subscribe(
                    (res: DomainAttributeModel[]) => {
                        if (res?.length) {
                            let attributeTypeCode = res[0].attributeTypeCode;
                            this.filterNonSearchDomainAttribute(res[0], attributeTypeCode);
                            attributeType["id"] = attributeTypeCode;
                            this.attributeTypeCodes$.next(
                                this.attributeTypeCodes$.value
                            );
                        }
                    }
                );
        }
    }

    private filterNonSearchDomainAttribute(domainAttribute: DomainAttributeModel, attributeTypeCode: string) {
        if (!domainAttribute.searchFlag && this.searchMode) {
            this.select2ServiceSalesDataList = this.select2ServiceSalesDataList.filter(d => d.id != attributeTypeCode)
        }
    }

    private resetRow(row: PriceRuleAttributeAndRuleRowView) {
        delete row.serviceCategory;
        delete row.salesBucket;
        delete row.attribute;
        delete row.validity;
    }

    private getInventoryDimensionReference() {
        if (this.select2InventoryDimensionDataList$.value?.length) {
            return;
        }
        this.service.getInventoryDimensionReference().pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
                this.select2InventoryDimensionDataList$.next(
                    this.serviceCategoriesAndSalesBucketConverter.toInventoryDimensionReferenceDataList(
                        res
                    )
                );
            }
        );
    }

    private createServiceCategory(row: PriceRuleAttributeAndRuleRowView) {
        this.resetRow(row);
        row.serviceCategory = new PriceRuleServiceCategoryView();
        row.serviceCategory.priceRuleAttributeGroupCode =
            PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE;
        row.serviceCategory.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
    }

    private createSalesBucket(row: PriceRuleAttributeAndRuleRowView) {
        this.resetRow(row);
        row.salesBucket = new PriceRuleSalesBucketView();
        row.salesBucket.priceRuleAttributeGroupCode =
            PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE;
        row.salesBucket.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
    }

    onInventoryDimensionChange(event, row) {
        if (row.serviceCategory) {
            row.serviceCategory.dimensionUnitCode = this.getDimensionUnitCode(event);
        }
        if (row.salesBucket) {
            row.salesBucket.dimensionUnitCode = this.getDimensionUnitCode(event);
        }
    }

    private getDimensionUnitCode(inventoryDimensionCode) {
        switch (inventoryDimensionCode) {
            case PricingDetailConstant.LOAD_FACTOR_CODE:
                return PricingDetailConstant.PERCENTAGE_CODE;
            case PricingDetailConstant.AVAILABLE_INVENTORY_CODE:
            case PricingDetailConstant.SALES_CODE:
                return PricingDetailConstant.SEATS_CODE;
        }
        return null;
    }

    private getServiceCategoriesReference() {
        if (this.select2ServiceCategoriesDataList$.value?.length) {
            return;
        }
        this.service.getServiceCategoryReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res) => {
                    this.select2ServiceCategoriesDataList$.next(
                        this.serviceCategoriesAndSalesBucketConverter.toServiceCategoryReferenceDataList(
                            res
                        )
                    );
                }
            );
    }

    private getSalesBucketReference() {
        if (this.select2SalesBucketDataList$.value?.length) {
            return;
        }
        this.service.getSalesBucketReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res) => {
                    this.select2SalesBucketDataList$.next(
                        this.serviceCategoriesAndSalesBucketConverter.toSalesBucketReferenceDataList(
                            res
                        )
                    );
                }
            );
    }

    private getSelect2IsIsNotConditionDataList() {
        if (this.select2IsIsNotConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference(
            [
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_NOT_OPERATOR_CODE,
            ],
            (res) => {
                this.select2IsIsNotConditionDataList$.next(
                    this.productEligibleConverter.toConditionReferenceSelect2DataList(res)
                );
            }
        );
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res: ConditionReferenceModel[]) => {
                callback(res);
            },
            (err) => {
                this.toastrService.error(
                    PricingDetailConstant.FAILED_GET_CONDITION_REFERENCE,
                    PricingDetailConstant.ALERT_HEADER
                );
            }
        );
    }

    private getSelect2IsIsNotLessThanMoreThanConditionDataList() {
        if (this.select2IsIsNotLessThanMoreThanConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference(
            [
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_NOT_OPERATOR_CODE,
                PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
                PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
            ],
            (res) => {
                this.select2IsIsNotLessThanMoreThanConditionDataList$.next(
                    this.productEligibleConverter.toConditionReferenceSelect2DataList(res)
                );
            }
        );
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value;
            this.removeOptionalFieldsFromView(rows);
            this.rows$.next(rows);
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    public onDateTimeDimensionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.dateTimeDimensionCode != event) {
            row.validity = new PriceRuleValidityView();
            row.validity.dateTimeDimensionCode = event;
            row.validity.priceRuleAttributeGroupCode =
                PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE;
            row.validity.calendarValidityCode = PricingDetailConstant.SERVICE_CODE;
        }
        this.getCalendarValidityReference();
        if (
            row.validity.dateTimeDimensionCode ==
            PricingDetailConstant.CALENDAR_WEEKDAY
        ) {
            this.getSelect2IsIsNotConditionDataList();
        } else {
            if (
                row.validity.dateTimeDimensionCode ==
                PricingDetailConstant.CALENDAR_DATE ||
                row.validity.dateTimeDimensionCode ==
                PricingDetailConstant.CALENDAR_TIME ||
                row.validity.dateTimeDimensionCode ==
                PricingDetailConstant.CALENDAR_DATE_TIME
            ) {
                this.getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList();
            }
        }
    }

    private getCalendarValidityReference() {
        if (this.select2CalendarValidityDataList$.value?.length) {
            return;
        }
        this.calendarValidityService
            .getByCalendarValidityCodes(PricingDetailConstant.SERVICE_CODE)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: CalendarValidityReferenceModel[]) => {
                    this.select2CalendarValidityDataList$.next(
                        this.productEligibleConverter.toCalendarValidityReferenceDataList(
                            res
                        )
                    );
                },
                (err) => {
                    this.toastrService.error(
                        PricingDetailConstant.FAILED_GET_CALENDAR_VALIDITY_REFERENCE,
                        PricingDetailConstant.ALERT_HEADER
                    );
                }
            );
    }

    private getSelect2IsIsNotIsBetweenIsNotBetweenConditionDataList() {
        if (this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference(
            [
                PricingDetailConstant.IS_OPERATOR_CODE,
                PricingDetailConstant.IS_NOT_OPERATOR_CODE,
                PricingDetailConstant.IS_BETWEEN_OPERATOR_CODE,
                PricingDetailConstant.IS_NOT_BETWEEN_OPERATOR_CODE,
            ],
            (res) => {
                this.select2IsIsNotIsBetweenIsNotBetweenConditionDataList$.next(
                    this.productEligibleConverter.toConditionReferenceSelect2DataList(res)
                );
            }
        );
    }

    public onCalendarConditionChange(event, row: PriceRuleAttributeAndRuleRowView) {
        if (row.validity.conditionCode != event) {
            row.validity.endDateTime = "";
            row.validity.endValue = "";
        }
        this.onValueChange(event, row.validity, 'conditionCode');
    }

    private setDateTimeOptions() {
        this.dateRangePickerEndDateOptions = [];
        this.dateRangePickerEndDateTimeOptions = [];
        this.dateRangePickerEndTimeOptions = [];
        for (let i = 0; i < this.rows$?.value?.length; i++) {
            this.dateRangePickerEndDateOptions.push(this.select2OptionsService.getDatePickerOption());
            this.dateRangePickerEndDateTimeOptions.push(this.select2OptionsService.getDatetimePickerOption());
            this.dateRangePickerEndTimeOptions.push(this.select2OptionsService.getTimePickerOption());
        }
    }

    public onStartDateTimeChange(row: any, value: any) {
        row.validity.startDateTime = value;
        this.resetErrorFlags();

        let index = this.rows$.value.indexOf(row);
        let option: Select2Option = null;
        switch (row.validity.dateTimeDimensionCode) {
            case 'DATE':
                option = this.updateDateOption(option, value, index);
                break;
            case 'DATETIME':
                option = this.updateDateTimeOption(option, value, index);
                break;
            case 'TIME':
                option = this.updateTimeOption(option, value, index);
                break;
            default:
                break;
        }

        this.setEndDateTime(row, value, option);
    }

    private updateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getTimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndTimeOptions[index] = option;
        return option;
    }

    private updateDateTimeOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getDatetimePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateTimeOptions[index] = option;
        return option;
    }

    private updateDateOption(option: Select2Option, value: any, index: number) {
        option = this.select2OptionsService.getDatePickerOption();
        if (value) {
            option['minDate'] = value;
        }
        this.dateRangePickerEndDateOptions[index] = option;
        return option;
    }

    private setEndDateTime(row: any, value: any, option: Select2Option) {
        let endDateTime = row.validity.endDateTime;
        if (!endDateTime || moment(value, option.locale.format) > moment(endDateTime, option.locale.format)) {
            row.validity.endDateTime = value;
        }
    }

    public onEndDateTimeChange(row: any, value: any) {
        row.validity.endDateTime = value;
        this.resetErrorFlags();
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public onInputChange(event: Event, field: any, fieldName: string) {
        field[fieldName] = (event.target as HTMLInputElement).value;
        this.resetErrorFlags();
    }
}

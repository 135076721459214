import { Injectable } from "@angular/core";
import { DocumentDistributionAttributeCommandModel, DocumentDistributionAttributeModel, DocumentDistributionCommandModel } from "src/app/core/models/document-distribution-model";
import { AttributeRowView } from "./attribute-row-view";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable()
export class AttributeMapperService {
    constructor(private dateConverterService: DateConverterService) {}

    public toDocumenctDistributionAttributeViews(models: DocumentDistributionAttributeModel[]) : AttributeRowView[] {
        if (!models) {
            return null;
        }
        let views: AttributeRowView[] = new Array();
        for (let attribute of models) {
            this.setAttributeView(views, attribute);
        }
        return views;
    }

     private setAttributeView(views: AttributeRowView[], attribute: DocumentDistributionAttributeModel) {
        let view = views.filter(x => x.attributeTypeCode == attribute.attributeTypeCode)[0];
        if (view) {
            this.addAttributeChoiceId(view, attribute.attributeChoiceId)
        } else {
            views.push(this.documentDistributionModelToAttributeRowView(attribute));
        }
    }

    public addAttributeChoiceId(view: AttributeRowView, choiceId: string) {
        view.attributeChoiceIds.push(choiceId);
    }

    public documentDistributionModelToAttributeRowView(model: DocumentDistributionAttributeModel) : AttributeRowView {
        let view: AttributeRowView = {
            attributeTypeCode: model.attributeTypeCode,
            attributeTypeName: model.attributeTypeName,
            attributeValue: model.attributeValue,
            attributeChoiceIds: [model.attributeChoiceId],
            duplicated: false,
            isFreeTextChoice: false,
            sortSequence: model.displaySequence,
            isMultiChoices: false,
            attributeDateTime: this.dateConverterService.toDateFormatOrNull(model.attributeDateTime),
            attributeText: model.attributeText
        }

        return view;
    }

    public toDocumentDistributionAttributeCommands(views: AttributeRowView[], command: DocumentDistributionCommandModel) {
        if (!views?.length) {
            return;
        }

        command.documentDistributionAttributes = new Array();
        let index = 1;
        for (let view of views) {
            this.attributeRowViewToAttributeModelByDimensionType(view, command, index);
            index = command.documentDistributionAttributes.length + 1;
        } 
    }

    private attributeRowViewToAttributeModelByDimensionType(row: AttributeRowView, command: DocumentDistributionCommandModel, index: number) {
        if (row.attributeChoiceIds) {
            let model = this.choiceAttributeRowViewToAttributeModel(row, index);
            command.documentDistributionAttributes.push(...model);
        } else {
            let model = this.attributeRowViewToAttributeModel(row, index);
            command.documentDistributionAttributes.push(model);
        }
    }

    private choiceAttributeRowViewToAttributeModel(row: AttributeRowView, index: number): DocumentDistributionAttributeCommandModel[] {
        let models = [];
        row.attributeChoiceIds.forEach(id => {
            let model: DocumentDistributionAttributeCommandModel = {
                documentDistributionAttributeId: null,
                documentDistributionId: null,
                displaySequence: index,
                attributeChoiceId: id,
                attributeTypeCode: row.attributeTypeCode,
                attributeTypeName: row.attributeTypeName,
                attributeValue: row.attributeValue,
                attributeDateTime: row.attributeDateTime,
                attributeText: row.attributeText,
                attributeDate:row.attributeDate
            }
            index++;
            models.push(model);
        });
        return models;
    }

    private attributeRowViewToAttributeModel(row: AttributeRowView, index: number): DocumentDistributionAttributeCommandModel {
        let model: DocumentDistributionAttributeCommandModel = {
            documentDistributionAttributeId: null,
            documentDistributionId: null,
            displaySequence: index,
            attributeChoiceId: null,
            attributeTypeCode: row.attributeTypeCode,
            attributeTypeName: row.attributeTypeName,
            attributeValue: row.attributeValue,
            attributeDateTime: row.attributeDateTime,
            attributeText: row.attributeText,
            attributeDate: row.attributeDate
        }
        return model
    }
}
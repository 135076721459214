import {
    AfterViewInit, ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { Select2DataMapperService } from 'src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import {
    OrganisationGroupReferenceModel,
    OrganisationModel,
    OrganisationRoleReferenceModel,
    OrganisationTypeReferenceModel
} from 'src/app/core/models/organisation-model';
import { ProductAttributeCommandModel, ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductHashTagViewModel } from 'src/app/core/models/product-model/product-base-model/product-hashtag';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import {
    ProductRestrictionLocationViewModel,
    ProductRestrictionOrganisationViewModel,
    ProductRestrictionProductNumberViewModel,
    ProductRestrictionProductViewModel,
    ProductRestrictionRouteViewModel,
    ProductRestrictionVehicleViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    CountryReferenceModel,
    DateTimeDimensionReferenceModel,
    DomainAttributeModel,
    HashTagReferenceModel,
    LanguageReferenceModel,
    LocationGroupModel,
    RegionReferenceModel,
    StatusReferenceModel,
    UsageTypeReferenceModel,
    VehicleCompositionModel,
    VehicleGroupReferenceModel,
    VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { SeasonReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/season-reference.model';
import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel,
    ProductTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-product-model';
import {
    DomainAttributeService,
    HashTagReferenceService,
    LanguageReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService,
    VehicleCompositionService
} from 'src/app/core/services/airline-services';
import { OrganisationRoleReferenceService, OrganisationService } from 'src/app/core/services/organisation-services';
import { AncillaryServiceService, SpecialServiceService } from 'src/app/core/services/product-services';
import { LocationGroupService } from 'src/app/core/services/reference-service/reference-general-service';
import { ProductTypeGroupService } from 'src/app/core/services/reference-service/reference-product-service/product-type-group.service';
import {
    CalendarValidityService,
    ConditionReferenceService,
    CountryReferenceService,
    DateTimeDimensionReferenceService,
    OrganisationTypeReferenceService,
    ProductCategoryReferenceService,
    ProductGroupReferenceService,
    ProductLocationPointReferenceService,
    ProductLocationTypeReferenceService,
    ProductNumberTypeReferenceService,
    StatusReferenceService,
    UsageTypeReferenceService,
    VehicleGroupReferenceService,
    VehicleTypeReferenceService
} from 'src/app/core/services/system-services';
import { SeasonReferenceService } from 'src/app/core/services/system-services/season-reference.service';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import {
    CancelButtonModel,
    CopyButtonModel,
    DeleteButtonModel,
    NewButtonModel,
    RefreshButtonModel,
    SaveButtonModel
} from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { TreeMapperService } from '../../../shared/mapper/tree-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from 'src/app/core/services/reference-service/reference-media-service';
import { MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MapperService } from '../ancillary-service-search/shared/mapper.service';
import { MediaViewModel } from 'src/app/core/models/media-model';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationMediaMapperService } from 'src/app/core/components/translation-media/shared/translation-media-mapper.service';
import { TranslationTextMapperService } from 'src/app/core/components/translation-text/shared/translation-text-mapper.service';
import { TranslationTextComponent } from 'src/app/core/components/translation-text/translation-text.component';
import { TranslationMediaComponent } from 'src/app/core/components/translation-media/translation-media.component';
import { TextMapperService } from 'src/app/core/components/text/shared/text-mapper.service';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { InventoryAttributeMapperService } from '../../../shared/mapper/inventory-attribute-mapper.service';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { AncillaryServiceAttributeComponent } from '../ancillary-service-attribute/ancillary-service-attribute.component';
import { TextMediaComponent } from 'src/app/core/components/text-media/text-media.component';
import { ProductTypeReferenceService } from 'src/app/core/services/reference-service/reference-product-service';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { TreeComponent } from './tree/tree.component';
import { PointOfSalesModel, ValidityModel } from 'src/app/core/models/merchandizing-config';
import { HttpClient } from '@angular/common/http';
import { AncillaryServiceAddCommandModel, AncillaryServiceEditCommandModel, AncillaryServiceViewModel } from 'src/app/core/models/product-model/ancillary-service-model';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActivityDomainComponent } from 'src/app/core/components/activity-domain/activity-domain.component';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ProductInventoryDimensionCommandModel, ProductInventoryDimensionViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { AncillaryServiceInventoryViewModel } from 'src/app/core/models/product-model/ancillary-service-model/ancillary-service-inventory-view.model';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryView } from './inventory/views/inventory.view';
import { UntypedFormBuilder } from '@angular/forms';
import { InventoryMapperService } from './inventory/shared/inventory-mapper.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';

@Component({
    selector: 'op-ancillary-service-detail',
    templateUrl: './ancillary-service-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TreeMapperService,
        ProductTypeGroupService,
        HashTagMapperService,
        Select2DataMapperService,
        AttributeMapperService,
        InventoryAttributeMapperService,
        NumberMapperService,
        PointOfSalesMapperService,
        RestrictionMapperService,
        ValidityMapperService,
        MapperService,
        MediaMapperService,
        TranslationMediaMapperService,
        TranslationTextMapperService,
        TextMapperService,
        TranslationNameMapperService,
        ActionbarService,
        AlertBarService,
        InventoryMapperService
    ]
})
export class AncillaryServiceDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly SERVICE_PRODUCT_CATE_CODE = 'ANCILLARY';
    private readonly SSR_CODE = 'SERVICE';
    private readonly GENERAL_INFO_TAB = 'general';
    private readonly TRANSLATION_TAB = 'translation';
    private readonly ATTRIBUTE_TAB = 'attribute';
    private readonly TEXT_TAB = 'text';
    private readonly MEDIA_TAB = 'media';
    private readonly COPY_ADD_NAME = ' - copy';
    private readonly ORG_TYPE_SUPPLIER_CODE = 'SUPPLIER';
    private readonly ORG_TYPE_PROVIDER_CODE = 'AIRLINE';
    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";
    private readonly ATTRIBUTE_TYPE_SERVICE: string = "SERVICE";
    private readonly ATTRBUTE_GROUP_CODE_INV: string = "INVENTORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    private readonly PRODUCT_LOCATION_TYPE_CODE = 'RESTRICTION';
    private readonly CALENDAR_VALIDITY_CODE = "SERVICE";
    private readonly ACTIVE_STATUS = "A";
    public readonly ENTITY_NAME: string = "Product";
    private readonly TRANSLATION_NAME = "Airline translation set";
    private readonly USAGETYPE_DATA: string = "DATA";
    private readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    private readonly GENERAL_PANEL_NAME: string = "General Information";
    private readonly ATTRIBUTE_PANEL_NAME: string = "Attribute";
    private readonly _jsonURL = 'assets/data/merchandizing/attributes&rules_config.json';
    private readonly INVENTORY_PANEL_NAME: string = "Inventory Rule";
    private readonly INVCTRL_CODE: string = "INVENTORYCONTROL";
    private readonly INVGROUPCTRL_CODE: string = "INVGROUPCONTROL";
    private readonly ATTRIBUTECHOICECODE_YES = "YES";
    private readonly INVENTORY_TAB = 'inventory';
    private readonly SERVICE_CATEGORY_CODE = "SERVICECATEGORY"

    public newProduct = false;
    public copyProduct = false;
    public hiddenTree = false;
    public showTranslate = false;
    public selectedTab = this.GENERAL_INFO_TAB;
    public productId: string;
    public parentId: string;
    public generalInfo$ = new BehaviorSubject<any>(null);
    public tree$ = new BehaviorSubject<TreeNode[]>(null);
    public productCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public serviceProductCategoryReference$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public serviceProductGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    private unsubscribe$ = new Subject();
    public heightPanelGeneral: number;
    public hashTagReference$ = new BehaviorSubject<HashTagReferenceModel[]>(null);
    public productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productHashtagValueViews: ProductHashTagView[];
    public provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    public supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    public productTypeCode$ = new BehaviorSubject<string>(null);
    public productGroupCode$ = new BehaviorSubject<string>(null);
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public rootLevel: boolean = false;
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeService$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public domainAttributeINV$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public seasonReference$ = new BehaviorSubject<SeasonReferenceModel[]>(null);
    public conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public productLocationPointReference$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReference$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public locationGroupSelect2Data: Select2Data[];
    public regionReferenceSelect2Data: Select2Data[];
    public calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationGroupSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    public serviceCateSelected: boolean = false;
    public draftFlag: boolean = true;
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public productName$ = new BehaviorSubject<string>(null);
    public newFromParent: boolean = false;
    public productTypeCode: String;
    public productGroupCode: String;
    public productTypeReferences$ = new BehaviorSubject<ProductTypeReferenceModel[]>(null);
    public saveAction: boolean = false;
    public validityConfig: ValidityModel;
    public pointOfSalesConfig: PointOfSalesModel;
    private newTreeNode: TreeNode;
    public navigateParam: any;
    private loadReferenceDataFinished: boolean = false;
    private productData: AncillaryServiceViewModel;
    public fillProductFinished: boolean = false;
    public userSecurity: SecurityGroupSecurityModel;
    public vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    public vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    public noInvControl: boolean = false;
    public invGroupContrl: boolean = false;
    private draftFlagRoot: boolean = true;
    public inventoryData: InventoryView[] = new Array();
    public pagingDataView: PagingDataView[];
    public currentPageIndex: number;
    public displayName: string;

    private copyButton = new CopyButtonModel();

    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        this.copyButton,
        new SaveButtonModel(),
        new CancelButtonModel(),
        new DeleteButtonModel(),
        new RefreshButtonModel()
    )

    @ViewChild(GeneralInformationComponent) generalInfo: GeneralInformationComponent;
    @ViewChild(AncillaryServiceAttributeComponent) attributeAndRuleComponent: AncillaryServiceAttributeComponent;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;
    @ViewChild(TextMediaComponent) textMedia: TextMediaComponent;
    @ViewChild(TreeComponent) treeComponent: TreeComponent;
    @ViewChild("productDetailTextRef") productDetailTextRef: ElementRef;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    @ViewChild(InventoryComponent) inventoryComponent: InventoryComponent;

    constructor(private navigationService: NavigationService,
        private ancillaryServiceService: AncillaryServiceService,
        private changeDetectionRef: ChangeDetectorRef,
        private treeMapperService: TreeMapperService,
        private actionbarService: ActionbarService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private focusingService: FocusingService,
        private loadingSpinner: LoadingSpinnerService,
        private hashTagReferenceService: HashTagReferenceService,
        private hashTagMapperService: HashTagMapperService,
        private organisationService: OrganisationService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private domainAttributeService: DomainAttributeService,
        private seasonReferenceService: SeasonReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private select2DataMapperService: Select2DataMapperService,
        private countryReferenceService: CountryReferenceService,
        private locationGroupService: LocationGroupService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private regionReferenceService: RegionReferenceService,
        private calendarValidityReferenceService: CalendarValidityService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private numberMapperService: NumberMapperService,
        private attributeMapperService: AttributeMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private validityMapperService: ValidityMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private languageReferenceService: LanguageReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mapperService: MapperService,
        private mediaMapperService: MediaMapperService,
        private translationMediaMapperService: TranslationMediaMapperService,
        private translationTextMapperService: TranslationTextMapperService,
        private textMapperService: TextMapperService,
        private http: HttpClient,
        public translationNameMapperService: TranslationNameMapperService,
        public productTypeReference: ProductTypeReferenceService,
        public alertBarService: AlertBarService,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService,
        private vehicleGroupReferenceService: VehicleGroupReferenceService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private vehicleCompositionService: VehicleCompositionService,
        private inventoryAttributeMapperService: InventoryAttributeMapperService,
        private formBuilder: UntypedFormBuilder,
        private inventoryMapperService: InventoryMapperService,) {
        this.getParam();
        this.checkNewProduct();
    }

    ngOnInit() {
        this.getReferenceData();
        this.getProductDetail(this.productId);

        this.copyButton.disable();
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private getParam() {
        let params = this.navigationService.getParams();
        if (!params)
            params = this.navigationService.getPreviousTabParams()?.params;

        this.navigateParam = params;
        this.newProduct = params?.newProduct ?? params?.newFlag ?? false;
        this.copyProduct = params?.copyProduct ?? false;
        let id = params?.productId ?? params.id;
        this.rootLevel = this.newProduct;
        this.productId = id;
        this.userSecurity = params?.userSecurity;
        this.pagingDataView = params?.pagingDataView ?? [];
        this.currentPageIndex = params?.currentPageIndex;
        if (id != null) {
            this.hiddenTree = false;
        } else {
            this.activityStore();
        }
        this.addAction();
    }

    private setupActionbarSecurity() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable(this.userSecurity?.newFlag ?? false);
        this.actionBarHandler.get(ACTION_STATUS.copy).enable(false);
        this.actionBarHandler.get(ACTION_STATUS.save).enable(this.saveFlag);
        this.actionBarHandler.get(ACTION_STATUS.delete).enable(false);
    }

    get saveFlag() {
        return ((this.newProduct == true || this.newFromParent == true) && this.userSecurity?.newFlag == true) || ((this.newProduct == false && this.newFromParent == false) && this.userSecurity?.editFlag == true);
    }

    private getReferenceData() {
        if (this.productId) {
            this.loadingSpinner.show();
        }
        this.getProductCategoryReference();
        this.getProductGroupReference();
        this.getProductTypeGroup();
        this.getStatusReference();
        this.getUsageTypeReference();
        this.getHashTagReference();
        this.getSupplier();
        this.getProvider();
        this.getProductNumberTypeReference();
        this.getDomainAttributeTypeSearch();
        this.getDomainAttributeTypeService();
        this.getDomainAttributeTypeInventory();
        this.getSeasonReference();
        this.getConditionReference();
        this.getCountryReference();
        this.getLocationGroup();
        this.getProductLocationPointReference();
        this.getProductLocationTypeReference();
        this.getRegionReference();
        this.getCalendarValidityReference();
        this.getDateTimeDimensionReference();
        this.getOrganisation();
        this.getOrganisationGroupReference();
        this.getOrganisationTypeReference();
        this.getOrganisationRoleReferences();
        this.getLanguageReference();
        this.getMediaTypeReference();
        this.getMediaUseReference();
        this.getMediaHashTag();
        this.getMediaTypeFileType();
        this.getProductTypeReference();
        this.getAttributetAndRulesJSON();
        this.getVehicleTypeReferences();
        this.getVehicleGroupReferences();
        this.getVehicleCompositions();
    }

    public getProductDetail(productId) {
        if (productId) {
            if (!this.saveAction) {
                this.loadingSpinner.show();
            }
            this.ancillaryServiceService.getByProductId(productId)
                .subscribe(
                    (responses) => {
                        this.productData = responses;
                        if (this.loadReferenceDataFinished) {
                            setTimeout(() => {
                                this.fillProductData(responses);
                            }, 1000);
                        }
                        else {
                            this.canLoadAttributeAndRules();
                        }
                        this.setupActionbarSecurity();
                    },
                    () => {
                        this.loadingSpinner.hide();
                    }
                )
        }
    }

    private fillProductData(responses: AncillaryServiceViewModel) {
        this.changeToEditMode();
        this.productId = responses.productId;
        this.getLevelHierarchy(responses);
        this.assignRootDraftFlag(responses.draftFlag);
        this.getProductGeneralInfo(responses, this.copyProduct);
        this.parentId = responses.parentProductId;
        this.displayName = this.displayProductName(responses.productName);
        this.tree$.next(this.treeMapperService.productTreeView(responses.productHierarchy));

        this.productTypeCode$.next(responses.productTypeCode);
        this.productGroupCode$.next(responses.productGroupCode);
        this.draftFlag = responses.draftFlag;

        this.setProductHashTagValue(responses.productHashtags);
        this.fillModelToProductNumber(responses.productNumbers);
        this.fillModelToProductAttribute(responses.productAttributes, responses.draftFlag);
        this.fillModelToInventoryRule(responses.productAttributes, responses.draftFlag);
        this.fillModelToSeason(responses.seasonCode);
        this.fillModelToInventory(responses.productInventoryAncillaryServices, responses.productAttributes);
        this.fillModelToMedia(responses.medias);
        this.fillModelToTranslationName(responses.productName, responses.languageTranslationSet);
        this.fillModelToTranslationText(responses.medias);
        this.fillModelToTranslationMedia(responses.medias);
        this.fillModelToText(responses.medias);
        this.fillModelToProductRestriction(responses.productRestrictionProducts,
            responses.productRestrictionProductNumbers,
            responses.productRestrictionRoutes,
            responses.productRestrictionLocations,
            responses.productRestrictionVehicles
        );
        this.fillModelToProductValidity(responses.productValidities);
        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
        this.fillModelToProductInventoryAttributeType(responses.productInventoryDimensions);
        if (this.copyProduct == true) {
            this.productId == null
        }
        this.changeDetectionRef.detectChanges();
        if (!this.saveAction) {
            this.loadingSpinner.hide();
        } else {
            this.loadingSpinner.saveComplete();
        }
        this.hideInvPanel(responses.productAttributes, this.draftFlagRoot)
        this.fillProductFinished = true;
        this.disableNew(responses);
    }

    private getProductGeneralInfo(responses: AncillaryServiceViewModel, copy: boolean) {
        let product = {
            no: null,
            id: responses.productId,
            name: responses.productName,
            productGroupCode: responses.productGroupCode,
            productTypeCode: responses.productTypeCode,
            productDescription: responses.productDescription,
            draftFlag: this.draftFlagRoot,
            usageTypeCode: responses.usageTypeCode,
            statusCode: responses.statusCode,
            commitBy: responses.commitFirstname + responses.commitLastname,
            commitDateTime: responses.commitDateTime
        }
        if (copy == true) {
            product.name = product.name + this.COPY_ADD_NAME;
        } else {
            this.generalInfo.formGroup.controls['productGroupCode'].disable();
            this.generalInfo.formGroup.controls['productTypeGroupId'].disable();
        }
        this.generalInfo$.next(product);
        this.productName$.next(responses.productName);
        this.addAction();
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.cancel:
                this.backToCategory();
                break;
            case ACTION_STATUS.back:
                this.backToSearch();
                break;
            case ACTION_STATUS.save:
                this.onSave();
                break;
            case ACTION_STATUS.new:
                this.newClick();
                break;
            case ACTION_STATUS.refresh:
                this.refresh()
                break;
        }
    }

    backToSearch() {
        this.navigationService.navigate('/main/product/home', null, null, this.navigateParam);
    }

    backToCategory() {
        let params = {
            tab: 'search',
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate('/main/product/home', null, null, params);
    }

    public onTabChange(activeId: string) {
        switch (activeId) {
            case this.GENERAL_INFO_TAB:
                this.focusingService.focus(this.generalInfo.focusingDirective);
                this.showTranslate = false;
                break;
            case this.ATTRIBUTE_TAB:
                this.focusingService.focus(this.attributeAndRuleComponent.hashtagComponent.focusingDirective);
                this.showTranslate = false;
                break;
            case this.TEXT_TAB:
                this.focusOnText();
                this.showTranslate = false;
                break;
            case this.MEDIA_TAB:
                this.focusOnMedia();
                this.showTranslate = false;
                break;
            case this.INVENTORY_TAB:
                this.showTranslate = false;
                this.focusingService.focus(this.inventoryComponent.focusingDirective);
                break;
            case this.TRANSLATION_TAB:
                this.showTranslate = true;
                break;
        }
    }

    public selectTreeNode(node: TreeNode) {
        if(node?.data){
            this.resetDetail();
            this.productId = node.data;
            this.parentId = node.parent?.data;
    
            this.getProductDetail(this.productId);
        }
    }

    private resetDetail() {
        this.attributeAndRuleComponent.hashtagComponent.delete();
        this.attributeAndRuleComponent.productNumberComponent.clearForm();
        this.attributeAndRuleComponent.attributeComponent.clearForms();
        this.attributeAndRuleComponent.seasonComponent.delete();
        this.attributeAndRuleComponent.restrictionComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.validityComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.clearForm();
        this.newTreeNode = null;
    }

    private getProductCategoryReference() {
        this.productCategoryReferenceService.getAll()
            .subscribe(
                (responses: ProductCategoryReferenceModel[]) => {
                    this.productCategoryReference$.next(responses);
                    this.serviceProductCategoryReference$.next(responses
                        .filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE));
                }
            )
    }

    private getProductGroupReference() {
        this.productGroupReferenceService.getAll()
            .subscribe(
                (responses: ProductGroupReferenceModel[]) => {
                    this.productGroupReference$.next(responses);
                    this.serviceProductGroupReference$.next(responses
                        .filter(x => x.productCategoryCode == this.SERVICE_PRODUCT_CATE_CODE));
                }
            )
    }

    private getProductTypeGroup() {
        this.productTypeGroupService.getAll()
            .subscribe(
                (responses: ProductTypeGroupModel[]) => {
                    this.productTypeGroup$.next(responses);
                }
            )
    }

    private getProductTypeReference() {
        this.productTypeReference.searchProductTypeReference('', '', this.ACTIVE_STATUS)
            .subscribe(
                (response: ProductTypeReferenceModel[]) => {
                    this.productTypeReferences$.next(response);
                }
            )
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private getUsageTypeReference() {
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (responses: UsageTypeReferenceModel[]) => {
                    if (responses) {
                        this.usageTypeReference$.next(this.filterUsageTypeReference(responses));
                    }
                }
            )
    }

    private filterUsageTypeReference(responses: UsageTypeReferenceModel[]) {
        return responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA || x.usageTypeCode == this.USAGETYPE_TEMPLATE);
    }

    public getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height;
    }

    private checkNewProduct() {
        if (this.newProduct == false || this.copyProduct == true) {
            this.hiddenTree = false;
        } else {
            this.hiddenTree = true;
        }
    }

    public validateDetail(): boolean {
        if (!this.generalInfo.validGeneral()) {
            this.displayGeneralError();
            return false;
        } else if (!this.attributeValid()) {
            this.displayAttributeError()
            return false;
        } else if (!this.inventoryRuleValid()) {
            this.displayInventoryRuleError();
            return false;
        }
        this.alertBarService.hide();
        return true
    }

    private displayGeneralError() {
        this.alertBarService.show(this.GENERAL_PANEL_NAME, this.generalInfo.getErrorMessage());
        this.focusGeneral();
    }

    private focusGeneral() {
        this.focusingService.focus(this.generalInfo.focusingDirective);
    }

    private displayAttributeError() {
        this.alertBarService.show(this.ATTRIBUTE_PANEL_NAME, this.attributeAndRuleComponent.attributeComponent.getErrorMessage());
        this.focusAttribute();
    }

    private focusAttribute() {
        this.focusingService.focus(this.attributeAndRuleComponent.attributeComponent.focusingDirective);
    }

    public onSave() {
        this.saveAction = true;
        if (this.newProduct == true || this.newFromParent) {
            let command = this.fillFormGroupToAddModel();
            this.addProductInCondition(command);
        } else {
            let command = this.fillFormGroupToEditModel();
            this.editProductInCondition(command);
        }
    }

    private addProductInCondition(command: AncillaryServiceAddCommandModel) {
        if (this.newProduct == true) {
            this.addRootProduct(command);
        }
        if (this.newFromParent == true) {
            this.addProduct(command);
        }
    }

    private editProductInCondition(command: AncillaryServiceEditCommandModel) {
        if (!command.parentProductId) {
            this.editRootProduct(command);
        } else {
            this.editProduct(command);
        }
    }

    private addProduct(command: AncillaryServiceAddCommandModel) {
        this.loadingSpinner.show();
        this.ancillaryServiceService.addProduct(command)
            .subscribe(
                (response) => {
                    this.loadingSpinner.saveComplete();
                    this.newProduct = false;
                    this.newFromParent = false;
                    this.newTreeNode = null;
                    this.productId = null;
                    this.changeDetectionRef.detectChanges();
                    this.getProductDetail(response.body);
                    this.saveAction = false;
                    this.hiddenTree = false;
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private addRootProduct(command: AncillaryServiceAddCommandModel) {
        this.loadingSpinner.show();
        this.ancillaryServiceService.addRootProduct(command)
            .subscribe(
                (response) => {
                    this.loadingSpinner.saveComplete();
                    this.newProduct = false;
                    this.newFromParent = false;
                    this.newTreeNode = null;
                    this.productId = null;
                    this.changeDetectionRef.detectChanges();
                    this.getProductDetail(response.body);
                    this.saveAction = false;
                    this.hiddenTree = false;
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private editProduct(command: AncillaryServiceEditCommandModel) {
        this.loadingSpinner.show();
        this.activityDomainComponent.save();
        this.ancillaryServiceService.editProduct(command)
            .subscribe(
                () => {
                    this.loadingSpinner.saveComplete();
                    this.getProductDetail(this.productId);
                    this.saveAction = false;
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private editRootProduct(command: AncillaryServiceEditCommandModel) {
        this.loadingSpinner.show();
        this.activityDomainComponent.save();
        this.ancillaryServiceService.editRootProduct(command)
            .subscribe(
                () => {
                    this.loadingSpinner.saveComplete();
                    this.getProductDetail(this.productId);
                    this.saveAction = false;
                },
                () => {
                    this.loadingSpinner.hide();
                }
            )
    }

    private fillFormGroupToAddModel(): AncillaryServiceAddCommandModel {
        if (this.validateDetail()) {
            let formValue = this.generalInfo.formGroup.value;
            let productGroupCode = this.generalInfo.formGroup.get('productGroupCode').value;
            let productHashTags = this.hashTagMapperService.productHashTagModels(
                this.productHashtagValueViews);

            let productNumbers = this.numberMapperService.productNumberFormToModels(
                this.attributeAndRuleComponent.productNumberComponent?.forms,
                this.provider$);

            let productAttributes: ProductAttributeCommandModel[] = new Array();
            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                this.attributeAndRuleComponent.attributeComponent?.forms, productAttributes);
            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                this.attributeAndRuleComponent.inventoryComponent?.forms, productAttributes);

            let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
                this.attributeAndRuleComponent.restrictionComponent?.ruleComponent?.forms);
            let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
            let productRestrictionVehicles = this.restrictionMapperService.productRestrictionVehicleCommandModels(productRestrictionViews);

            let productValidities = this.validityMapperService.productValidityFormToModels(
                this.attributeAndRuleComponent.validityComponent?.ruleComponent?.forms);

            let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
                this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.forms);
            let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

            let productInventoryDimensions = this.inventoryAttributeMapperService.ancillaryInventoryDimensionToModels(productAttributes, this.productInventoryDimensions);

            let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, this.productId);

            medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, this.productId);

            let addCommand: AncillaryServiceAddCommandModel = {
                productCategoryCode: this.SERVICE_PRODUCT_CATE_CODE,
                productGroupCode: productGroupCode,
                productTypeCode: this.getProductTypeCode(),
                providerId: null,
                parentProductId: this.getParentProductId(),
                productName: formValue.productName,
                productDescription: formValue.productDescription,
                supplierId: null,
                displayCode: null,
                draftFlag: formValue.draftFlag,
                finalFlag: null,
                searchName: null,
                searchUsageTypeCode: null,
                searchStatusCode: null,
                usageTypeCode: this.generalInfo.formGroup.get('usageTypeCode').value,
                isOwnerFilter: null,
                filterUserAccountId: null,
                statusCode: formValue.statusCode,
                productHashTags: productHashTags,
                productNumbers: productNumbers,
                productRestrictionProducts: productRestrictionProducts,
                productRestrictionProductNumbers: productRestrictionProductNumbers,
                productRestrictionRoutes: productRestrictionRoutes,
                productRestrictionLocations: productRestrictionLocations,
                productValidities: productValidities,
                productAttributes: productAttributes,
                productInventoryDimensions: productInventoryDimensions,
                productRestrictionOrganisations: productRestrictionOrganisations,
                medias: medias,
                seasonCode: <string>(this.attributeAndRuleComponent.seasonComponent.seasonCode),
                languageTranslationSet: null,
                productRestrictionVehicles: productRestrictionVehicles
            };
            return addCommand;
        }
        return null;

    }

    private fillFormGroupToEditModel(): AncillaryServiceEditCommandModel {
        if (this.validateDetail()) {
            let formValue = this.generalInfo.formGroup.value;
            let productHashTags = this.hashTagMapperService.productHashTagModels(
                this.productHashtagValueViews);

            let productNumbers = this.numberMapperService.productNumberFormToModels(
                this.attributeAndRuleComponent.productNumberComponent?.forms,
                this.provider$);

            let productAttributes: ProductAttributeCommandModel[] = new Array();
            productAttributes = this.attributeMapperService.productAttributeFormToModels(this.attributeAndRuleComponent.attributeComponent?.forms, productAttributes);

            let inventoryRuleValue = this.attributeAndRuleComponent.inventoryComponent?.forms.getRawValue()
            let inventoryRuleForm = this.formBuilder.array(inventoryRuleValue.map(item => this.formBuilder.control(item)));

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                inventoryRuleForm, productAttributes);

            let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
                this.attributeAndRuleComponent.restrictionComponent.ruleComponent?.forms);
            let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
            let productRestrictionVehicles = this.restrictionMapperService.productRestrictionVehicleCommandModels(productRestrictionViews);

            let productValidities = this.validityMapperService.productValidityFormToModels(
                this.attributeAndRuleComponent.validityComponent?.ruleComponent?.forms);

            let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
                this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.forms);
            let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

            let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
                this.translationNameComponent?.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.productId, this.translationNameComponent?.translationViews);

            let medias = this.textMapperService.textToMediaModels(this.textMedia.textComponent?.mediaTexts, this.ENTITY_NAME, this.productId);
            medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent?.mediaTranslationChildTexts, medias);

            medias = this.mediaMapperService.mediaToMediaModels(medias, this.textMedia.mediaComponent?.mediaFiles, this.ENTITY_NAME, this.productId);
            medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent?.mediaTranslationChildFiles, medias);

            let productInventoryDimensions: ProductInventoryDimensionCommandModel[] = new Array()
            if (this.draftFlag) {
                productInventoryDimensions = this.inventoryAttributeMapperService.ancillaryInventoryDimensionToModels(productAttributes, this.productInventoryDimensions);
            }
            let productInventories = this.inventoryMapperService.inventoryViewToModels(this.inventoryData);

            let editCommand: AncillaryServiceEditCommandModel = {
                productId: this.productId,
                productCategoryCode: this.SERVICE_PRODUCT_CATE_CODE,
                productGroupCode: this.getProductGroupCode(),
                productTypeCode: this.getProductTypeCode(),
                providerId: null,
                parentProductId: this.parentId,
                productName: formValue.productName,
                productDescription: formValue.productDescription,
                supplierId: null,
                displayCode: null,
                draftFlag: this.getDraftFlag(),
                finalFlag: null,
                searchName: null,
                searchUsageTypeCode: null,
                searchStatusCode: null,
                usageTypeCode: this.generalInfo.formGroup.get('usageTypeCode').value,
                isOwnerFilter: null,
                filterUserAccountId: null,
                statusCode: formValue.statusCode,
                productHashTags: productHashTags,
                productNumbers: productNumbers,
                productRestrictionProducts: productRestrictionProducts,
                productRestrictionProductNumbers: productRestrictionProductNumbers,
                productRestrictionRoutes: productRestrictionRoutes,
                productRestrictionLocations: productRestrictionLocations,
                productValidities: productValidities,
                productAttributes: productAttributes,
                productInventoryDimensions: productInventoryDimensions,
                productInventories: productInventories,
                productRestrictionOrganisations: productRestrictionOrganisations,
                languageTranslationSet: languageTranslationSet,
                medias: medias,
                seasonCode: <string>(this.attributeAndRuleComponent.seasonComponent.seasonCode),
                productRestrictionVehicles: productRestrictionVehicles
            };
            return editCommand;
        }
        return null;
    }

    private getProductTypeCode(): string {
        if (this.productTypeCode$.value) {
            return this.productTypeCode$.value;
        }
        return null;
    }

    private getProductGroupCode(): string {
        if (this.productGroupCode$.value) {
            return this.productGroupCode$.value;
        }
        return null;
    }

    private getDraftFlag(): boolean {
        if (this.generalInfo.formGroup.controls['draftFlag'].enabled) {
            return this.generalInfo.formGroup.controls['draftFlag'].value;
        }
        if (this.rootLevel) {
            return this.draftFlagRoot;
        }
        return null;
    }

    private getHashTagReference() {
        this.hashTagReferenceService.getAll()
            .subscribe(
                (responses: HashTagReferenceModel[]) => {
                    this.hashTagReference$.next(responses);
                }
            )
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        var productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReference$.value,
            value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTag$.next(productHashtagViews);
    }

    public hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    private getProvider() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_PROVIDER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.provider$.next(responses
                        .filter(x => x.providerIataCode)
                        .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
                }
            )
    }

    private getSupplier() {
        this.organisationService.getByOrganisationType(this.ORG_TYPE_SUPPLIER_CODE)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.supplier$.next(responses);
                }
            )
    }

    private getProductNumberTypeReference() {
        this.productNumberTypeReferenceService.getAll()
            .subscribe(
                (responses: ProductNumberTypeReferenceModel[]) => {
                    this.productNumberTypeReference$.next(responses);
                }
            )
    }

    private getLevelHierarchy(responses: AncillaryServiceViewModel) {
        if (!responses.parentProductId) {
            this.rootLevel = true;
        } else {
            this.rootLevel = false;
        }
    }

    private getDomainAttributeTypeSearch() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", true)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    responses.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                    this.domainAttributeSearch$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeService() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, "", false, this.ATTRIBUTE_TYPE_SERVICE)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    this.domainAttributeService$.next(responses);
                }
            )
    }

    private getDomainAttributeTypeInventory() {
        this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE_INV)
            .subscribe(
                (responses: DomainAttributeModel[]) => {
                    if (responses) {
                        this.domainAttributeINV$.next(
                            responses.filter(x => x.attributeTypeCode != this.SALESBUCKET_CODE));
                    }
                }
            )
    }

    private getVehicleGroupReferences() {
        this.vehicleGroupReferenceService.getVehicleGroupReference()
            .subscribe(
                (response) => {
                    this.vehicleGroupReferences$.next(response);
                }
            )
    }

    private getVehicleTypeReferences() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (response) => {
                    this.vehicleTypeReferences$.next(response);
                }
            )
    }

    private getVehicleCompositions() {
        this.vehicleCompositionService.getAll()
            .subscribe(
                (response) => {
                    this.vehicleCompositions$.next(response);
                }
            )
    }

    private fillModelToSeason(seasonCode: string) {
        if (seasonCode) {
            this.attributeAndRuleComponent.seasonComponent.fillData(seasonCode);
        }
    }

    private fillModelToProductInventoryAttributeType(productInventoryDimensions: ProductInventoryDimensionViewModel[]) {
        this.productInventoryDimensions = productInventoryDimensions;
    }

    private fillModelToInventory(productInventorySSR: AncillaryServiceInventoryViewModel[], productAttributes: ProductAttributeViewModel[]) {
        this.getInvGroupCtrl(productAttributes);
        this.inventoryComponent.displayData(productInventorySSR, this.invGroupContrl);
        this.inventoryData = this.inventoryComponent.ssrInventoryView;
    }

    private getSeasonReference() {
        this.seasonReferenceService.getAll()
            .subscribe(
                (responses: SeasonReferenceModel[]) => {
                    this.seasonReference$.next(responses);
                }
            )
    }

    private getConditionReference() {
        this.conditionReferenceService.getConditionsByCodes([])
            .subscribe(
                (responses: ConditionReferenceModel[]) => {
                    this.conditionReference$.next(responses);
                }
            )
    }

    private getCountryReference() {
        this.countryReferenceService.searchAllCountry()
            .subscribe(
                (responses: CountryReferenceModel[]) => {
                    this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(responses);
                }
            )
    }

    private getLocationGroup() {
        this.locationGroupService.getAll()
            .subscribe(
                (responses: LocationGroupModel[]) => {
                    this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(responses);
                }
            )
    }

    private getProductLocationPointReference() {
        this.productLocationPointReferenceService.getAll()
            .subscribe(
                (responses: ProductLocationPointReferenceModel[]) => {
                    this.productLocationPointReference$.next(responses);
                }
            )
    }

    private getProductLocationTypeReference() {
        this.productLocationTypeReferenceService.getByProductLocationTypeCode(this.PRODUCT_LOCATION_TYPE_CODE)
            .subscribe(
                (response: ProductLocationTypeReferenceModel) => {
                    if (response) {
                        let productLocationTypeReference = new Array<ProductLocationTypeReferenceModel>();
                        productLocationTypeReference.push(response);
                        this.productLocationTypeReference$.next(productLocationTypeReference);
                    }
                }
            )
    }

    private getRegionReference() {
        this.regionReferenceService.getRegionReference()
            .subscribe(
                (responses: RegionReferenceModel[]) => {
                    this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(responses);
                }
            )
    }

    private getCalendarValidityReference() {
        this.calendarValidityReferenceService.getByCalendarValidityCodes(this.CALENDAR_VALIDITY_CODE)
            .subscribe(
                (responses: CalendarValidityReferenceModel[]) => {
                    this.calendarValidityReference$.next(responses);
                }
            )
    }

    private getDateTimeDimensionReference() {
        this.dateTimeDimensionReferenceService.getDateTimeDimensionReference()
            .subscribe(
                (responses: DateTimeDimensionReferenceModel[]) => {
                    this.dateTimeDimensionReference$.next(responses);
                }
            )
    }

    private getOrganisation() {
        this.organisationService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(responses);
                }
            )
    }

    private getOrganisationGroupReference() {
        this.organisationGroupReferenceService.getOrganisationGroupReference()
            .subscribe(
                (responses: OrganisationGroupReferenceModel[]) => {
                    this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(responses);
                }
            )
    }

    private getOrganisationTypeReference() {
        this.organisationTypeReferenceService.getByStatus(this.ACTIVE_STATUS)
            .subscribe(
                (responses: OrganisationTypeReferenceModel[]) => {
                    this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(responses);
                }
            )
    }

    private getOrganisationRoleReferences() {
        this.organisationRoleReferenceService.getAllActive()
            .subscribe(
                (responses: OrganisationRoleReferenceModel[]) => {
                    this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(responses);
                }
            )
    }

    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        this.attributeAndRuleComponent.productNumberComponent.addViewToFormGroup(productNumbers);
    }

    private fillModelToProductAttribute(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        this.attributeAndRuleComponent.attributeComponent.clearForms();
        this.attributeAndRuleComponent.attributeComponent.productAttributes = productAttributes.filter(x => x.inheritAttribute == false 
                                                                                                    && x.attributeTypeCode != this.SERVICE_CATEGORY_CODE
                                                                                                    && x.attributeTypeCode != this.INVCTRL_CODE
                                                                                                    && x.attributeTypeCode != this.INVGROUPCTRL_CODE
                                                                                                );

        this.attributeAndRuleComponent.attributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.attributeComponent.getAttributeByProductType();
    }

    private fillModelToInventoryRule(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        if (this.attributeAndRuleComponent.inventoryComponent) {
            this.attributeAndRuleComponent.inventoryComponent.draftFlag = draftFlag;
            this.attributeAndRuleComponent.inventoryComponent.disableChoice = !draftFlag;
            let productAttributeInventoryRules = this.filterProductAttributeInventoryRule(productAttributes);
            this.attributeAndRuleComponent.inventoryComponent.productAttributes = productAttributeInventoryRules.filter(x => x.inheritAttribute == false && x.attributeTypeCode != this.SSR_CODE);
            this.attributeAndRuleComponent.inventoryComponent.productAttributesInherit = productAttributeInventoryRules.filter(x => x.inheritAttribute == true && x.attributeTypeCode != this.SSR_CODE);
            this.attributeAndRuleComponent.inventoryComponent.geProductAttributeType();
            this.attributeAndRuleComponent.inventoryComponent.addViewToFormGroup();
            this.attributeAndRuleComponent.inventoryComponent.addViewInheritToFormGroup();
        }
    }

    private filterProductAttributeInventoryRule(productAttributes: ProductAttributeViewModel[]) {
        let attributeTypeCodeINV = this.domainAttributeINV$.value.map(x => x.attributeTypeCode);
        return productAttributes.filter(x => attributeTypeCodeINV.includes(x.attributeTypeCode));
    }

    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[],
        productRestrictionVehicles: ProductRestrictionVehicleViewModel[]) {

        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations,
            productRestrictionVehicles, false);
        this.attributeAndRuleComponent.restrictionComponent?.ruleComponent?.addRestrictionViewToFormGroup(productRestrictionViews);
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        this.attributeAndRuleComponent.validityComponent?.ruleComponent?.addValidityToFormGroup(productValiditiesProduct);
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        this.attributeAndRuleComponent.pointOfSalesComponent?.ruleComponent?.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private getLanguageReference() {
        this.languageReferenceService.getByOrganisation()
            .subscribe(
                (responses: LanguageReferenceModel[]) => {
                    this.languageReference$.next(responses);
                }
            )
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference$.next(responses);
                }
            )
    }

    private getMediaUseReference() {
        this.mediaUseReferenceService.getAll()
            .subscribe(
                (responses: MediaUseReferenceModel[]) => {
                    this.mediaUseReference$.next(responses);
                }
            )
    }

    private getMediaHashTag() {
        this.mediaHashTagService.getAll()
            .subscribe(
                (responses: MediaHashTagModel[]) => {
                    this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }

    private getAttributetAndRulesJSON() {
        this.http.get(this._jsonURL).subscribe(
            (data: any) => {
                this.validityConfig = data.validity;
                this.pointOfSalesConfig = data.pointOfSales;
                this.changeDetectionRef.detectChanges();
            });
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMedia.mediaComponent.fillModelToForm(mediaViews);
    }

    private fillModelToTranslationName(productName: string, languageTranslationSet: LanguageTranslationSetViewModel) {
        this.translationNameComponent.fillModelToForm(productName, languageTranslationSet);
    }

    private fillModelToTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }

    private fillModelToTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }

    public displayMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryToTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildFiles = this.translationMediaMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildFiles);
        }
    }

    public deleteMediaTranslation(value) {
        if (this.translationMediaComponent.mediaTranslationFiles) {
            var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationFileViews(
                value, this.translationMediaComponent.mediaTranslationFiles);
            var mediaTranslationChildTexts = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationChildFileViews(
                value, this.translationMediaComponent.mediaTranslationChildFiles);
            this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildTexts);
        }
    }

    private fillModelToText(medias: MediaViewModel[]) {
        var mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMedia.textComponent.fillModelToForm(mediaTextViews);
    }

    public displayTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryToTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextToTranslationTextChildViews(
                value, this.languageReference$.value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    public deleteTextTranslation(value) {
        if (this.translationTextComponent.mediaTranslationTexts) {
            var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationTextViews(
                value, this.translationTextComponent.mediaTranslationTexts);
            var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationChildTextViews(
                value, this.translationTextComponent.mediaTranslationChildTexts);
            this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        }
    }

    focusOnMedia() {
        this.focusingService.focus(this.textMedia.mediaComponent.focusingDirective);
    }

    focusOnText() {
        this.focusingService.focus(this.textMedia.textComponent.focusingDirective);
    }

    public newClick() {
        this.clearLevel();
        let formValue = this.generalInfo.formGroup.value;
        var productGroupCode = this.generalInfo.formGroup.get('productGroupCode').value;
        var productTypeGroupId = this.generalInfo.formGroup.get('productTypeGroupId').value;
        var usageTypeCode = this.generalInfo.formGroup.get('usageTypeCode').value;
        var providerId = formValue.providerId;
        this.newFromParent = true;
        this.rootLevel = false;
        this.generalInfo.formGroup.reset();
        this.translationNameComponent.clearForm();
        this.translationTextComponent.clearForm();
        this.textMedia.textComponent.clearForm();
        this.textMedia.mediaComponent.clearForm();
        this.translationMediaComponent.clearForm();
        this.translationTextComponent.clearForm();

        if (!this.productId) {
            //New root
            this.generalInfo.initNewform(null, null, null, null, true, null);
        }
        else {
            this.generalInfo.initNewform(this.productId, productGroupCode, productTypeGroupId, providerId, this.draftFlagRoot, usageTypeCode);
            this.insertTreeNode();
        }
        this.setProductHashTagValue(null);
        this.clearAttributeAndRules();

        this.setupActionbarSecurity();
        this.addAction();
        this.activityStore();
        this.productInventoryDimensions = [];
        this.displayName = "";
        this.pagingDataView = [];
    }

    private clearLevel() {
        this.rootLevel = false;
    }

    private initProductData() {
        this.loadReferenceDataFinished = true;
        this.changeDetectionRef.detectChanges();
        if (this.newProduct === false || this.copyProduct == true) {
            this.fillProductDataInitial();
        }
    }

    private fillProductDataInitial() {
        if (this.productData && !this.fillProductFinished) {
            setTimeout(() => {
                this.fillProductData(this.productData);
            }, 500);
        }
    }

    public canLoadAttributeAndRules(): boolean {
        if (this.dateTimeDimensionReference$.value &&
            this.calendarValidityReference$.value &&
            this.conditionReference$.value &&
            this.organisationSelect2Data &&
            this.countryReferenceSelect2Data &&
            this.validityConfig &&
            this.pointOfSalesConfig) {
            if (!this.loadReferenceDataFinished) {
                this.initProductData();
            }
            return true;
        }
        return false;
    }

    public productTypeCodeChange(value) {
        this.productTypeCode$.next(value);
    }

    public productGroupCodeChange(value) {
        this.productGroupCode$.next(value);
        if (this.saveAction === true) {
            this.validateDetail()
        }
    }

    public attributeValid() {
        let attributetRequire = this.attributeAndRuleComponent.attributeComponent.findRequireAttribute();
        return (!attributetRequire);
    }

    public onAlertBarClick() {
        if (this.generalInfo.validGeneral() == false) {
            this.focusGeneral();
        } else if (this.attributeValid()) {
            this.focusAttribute();
        }
    }

    private insertTreeNode() {
        if (!this.newTreeNode) {
            let treeData = this.tree$.value;
            this.newTreeNode = this.treeMapperService.insertNewNode(treeData, this.productId);
            this.treeComponent.selectedNode = this.newTreeNode;
        }
    }

    public onProductNameChange(value) {
        if (this.newTreeNode) {
            this.newTreeNode.label = value;
        }
    }

    private clearAttributeAndRules() {
        this.attributeAndRuleComponent.productNumberComponent?.clearForm();
        this.clearAttribute();
    }

    private clearAttribute() {
        this.fillModelToProductAttribute([], true);
        this.fillModelToInventoryRule([], true);
        this.resetInventoryRule();
        this.clearSeason();
        this.clearRestriction();
        this.clearValidity();
        this.clearPointOfSales();
        this.productInventoryDimensions = [];
    }

    private clearRestriction() {
        this.fillModelToProductRestriction([], [], [], [], []);
        this.attributeAndRuleComponent.restrictionComponent.detectChanges()
    }

    private clearValidity() {
        this.attributeAndRuleComponent.validityComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.validityComponent.ruleComponent
            ?.validityInheritComponent?.clearForm();
        this.attributeAndRuleComponent.validityComponent.detectChanges();
    }

    private clearPointOfSales() {
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent
            ?.pointofsalesInheritComponent?.clearForm();
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private clearSeason() {
        this.attributeAndRuleComponent.seasonComponent.clearSeason();
    }

    private resetInventoryRule() {
        this.attributeAndRuleComponent.inventoryComponent.geProductAttributeType();
        this.attributeAndRuleComponent.inventoryComponent.removedAttribute = [];
        this.attributeAndRuleComponent.inventoryComponent.disableChoice = false;
    }

    private getParentProductId(): string {
        if (this.newTreeNode?.parent) {
            return this.newTreeNode.parent.data;
        }
        return this.parentId;
    }

    private addAction() {
        if (this.productId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.productId, this.productName$.value);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            this.SERVICE_PRODUCT_CATE_CODE,
            null);
    }

    public inventoryRuleValid() {
        if (this.newFromParent) {
            return true;
        }
        let attributeInvCountrolValid = this.attributeAndRuleComponent.inventoryComponent.validateAttributeINVControl();
        return attributeInvCountrolValid;
    }

    private displayInventoryRuleError() {
        this.alertBarService.show(this.INVENTORY_PANEL_NAME, this.attributeAndRuleComponent.inventoryComponent.getErrorMessage());
        this.focusInventoryRule();
    }

    private focusInventoryRule() {
        this.focusingService.focus(this.attributeAndRuleComponent.inventoryComponent.focusingDirective);
    }

    private hideInvPanel(productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        if (draftFlag && this.rootLevel) {
            this.noInvControl = true;
            return;
        }
        let filter = productAttributes.filter(x => x.attributeTypeCode == this.INVCTRL_CODE);
        if (filter?.length) {
            if (filter[0].attributeChoiceCode.toUpperCase() == this.ATTRIBUTECHOICECODE_YES) {
                this.noInvControl = false;
                return;
            }
        }
        this.noInvControl = true;
    }

    private assignRootDraftFlag(draftFlag: boolean) {
        if (this.rootLevel) {
            this.draftFlagRoot = draftFlag;
        } else {
            this.draftFlagRoot = false;
        }
    }

    private getInvGroupCtrl(productAttributes: ProductAttributeViewModel[]) {
        let value: boolean = false;
        let filter = productAttributes.filter(x => x.attributeTypeCode == this.INVGROUPCTRL_CODE);
        if (filter?.length) {
            if (filter[0].attributeChoiceCode == this.ATTRIBUTECHOICECODE_YES) {
                value = true;
            }
        }
        this.invGroupContrl = value;
    }

    public returnInvGroupCtrl(value: boolean) {
        this.invGroupContrl = value;
    }

    public saveInventoryData(value) {
        this.inventoryData = value;
    }

    private disableNew(responses: AncillaryServiceViewModel) {
        if ((responses.parentProductId && !this.noInvControl)|| responses.draftFlag) {
            this.actionBarHandler.get(ACTION_STATUS.new).enable(false);
        } else if (this.hasVehicleConfiguration(responses.productInventoryAncillaryServices)) {
            this.actionBarHandler.get(ACTION_STATUS.new).enable(false);
        } else {
            this.actionBarHandler.get(ACTION_STATUS.new).enable(this.userSecurity?.newFlag ?? false);
        }
    }

    private refresh() {
        this.getProductDetail(this.productId);
    }

    public onPageChange(id: string): void {
        this.getProductDetail(id);
    }

    private displayProductName(productName: string) {
        return " :: " + productName;
    }

    private hasVehicleConfiguration(productInventorySSR: AncillaryServiceInventoryViewModel[]): boolean {
        if (productInventorySSR?.length) {
            let filters = productInventorySSR.filter(x => x.vehicleConfigurationProductId);
            if (filters?.length) {
                return true;
            } 
        }
        return false;
    }

    private changeToEditMode() {
        this.newFromParent = false;
        this.newProduct = false;
        this.changeDetectionRef.detectChanges();
    }
}

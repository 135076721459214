import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleDistributionComponent } from './schedule-distribution.component';
import { ScheduleDistributionContentModule } from './content/schedule-distribution-content.module';

@NgModule({
  declarations: [
    ScheduleDistributionComponent
  ],
  imports: [
    CommonModule,
    ScheduleDistributionContentModule
  ],
  exports: [
    ScheduleDistributionComponent
  ]
})
export class ScheduleDistributionModule { }

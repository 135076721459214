import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_MERCHANDIZE_SOLD_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 145,
        salesChannel1Name: "GDS",
        salesChannel2: 101,
        salesChannel2Name: "API",
        salesChannel3: 432,
        salesChannel3Name: "ISE",
        salesChannel4: 493,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 376,
        salesChannel1Name: "GDS",
        salesChannel2: 462,
        salesChannel2Name: "API",
        salesChannel3: 476,
        salesChannel3Name: "ISE",
        salesChannel4: 443,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 265,
        salesChannel1Name: "GDS",
        salesChannel2: 320,
        salesChannel2Name: "API",
        salesChannel3: 131,
        salesChannel3Name: "ISE",
        salesChannel4: 253,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 129,
        salesChannel1Name: "GDS",
        salesChannel2: 359,
        salesChannel2Name: "API",
        salesChannel3: 310,
        salesChannel3Name: "ISE",
        salesChannel4: 295,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 476,
        salesChannel1Name: "GDS",
        salesChannel2: 200,
        salesChannel2Name: "API",
        salesChannel3: 111,
        salesChannel3Name: "ISE",
        salesChannel4: 405,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 154,
        salesChannel1Name: "GDS",
        salesChannel2: 430,
        salesChannel2Name: "API",
        salesChannel3: 311,
        salesChannel3Name: "ISE",
        salesChannel4: 403,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 228,
        salesChannel1Name: "GDS",
        salesChannel2: 353,
        salesChannel2Name: "API",
        salesChannel3: 338,
        salesChannel3Name: "ISE",
        salesChannel4: 320,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 389,
        salesChannel1Name: "GDS",
        salesChannel2: 291,
        salesChannel2Name: "API",
        salesChannel3: 475,
        salesChannel3Name: "ISE",
        salesChannel4: 394,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 187,
        salesChannel1Name: "GDS",
        salesChannel2: 141,
        salesChannel2Name: "API",
        salesChannel3: 351,
        salesChannel3Name: "ISE",
        salesChannel4: 272,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 230,
        salesChannel1Name: "GDS",
        salesChannel2: 223,
        salesChannel2Name: "API",
        salesChannel3: 398,
        salesChannel3Name: "ISE",
        salesChannel4: 479,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 428,
        salesChannel1Name: "GDS",
        salesChannel2: 204,
        salesChannel2Name: "API",
        salesChannel3: 264,
        salesChannel3Name: "ISE",
        salesChannel4: 442,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 492,
        salesChannel1Name: "GDS",
        salesChannel2: 383,
        salesChannel2Name: "API",
        salesChannel3: 187,
        salesChannel3Name: "ISE",
        salesChannel4: 187,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 365,
        salesChannel1Name: "GDS",
        salesChannel2: 318,
        salesChannel2Name: "API",
        salesChannel3: 369,
        salesChannel3Name: "ISE",
        salesChannel4: 473,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 128,
        salesChannel1Name: "GDS",
        salesChannel2: 223,
        salesChannel2Name: "API",
        salesChannel3: 398,
        salesChannel3Name: "ISE",
        salesChannel4: 479,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 326,
        salesChannel1Name: "GDS",
        salesChannel2: 434,
        salesChannel2Name: "API",
        salesChannel3: 286,
        salesChannel3Name: "ISE",
        salesChannel4: 234,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 154,
        salesChannel1Name: "GDS",
        salesChannel2: 307,
        salesChannel2Name: "API",
        salesChannel3: 280,
        salesChannel3Name: "ISE",
        salesChannel4: 180,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 493,
        salesChannel1Name: "GDS",
        salesChannel2: 271,
        salesChannel2Name: "API",
        salesChannel3: 365,
        salesChannel3Name: "ISE",
        salesChannel4: 135,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 402,
        salesChannel1Name: "GDS",
        salesChannel2: 141,
        salesChannel2Name: "API",
        salesChannel3: 244,
        salesChannel3Name: "ISE",
        salesChannel4: 329,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 170,
        salesChannel1Name: "GDS",
        salesChannel2: 119,
        salesChannel2Name: "API",
        salesChannel3: 411,
        salesChannel3Name: "ISE",
        salesChannel4: 472,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 331,
        salesChannel1Name: "GDS",
        salesChannel2: 408,
        salesChannel2Name: "API",
        salesChannel3: 185,
        salesChannel3Name: "ISE",
        salesChannel4: 115,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 318,
        salesChannel1Name: "GDS",
        salesChannel2: 224,
        salesChannel2Name: "API",
        salesChannel3: 212,
        salesChannel3Name: "ISE",
        salesChannel4: 176,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 340,
        salesChannel1Name: "GDS",
        salesChannel2: 251,
        salesChannel2Name: "API",
        salesChannel3: 316,
        salesChannel3Name: "ISE",
        salesChannel4: 192,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 302,
        salesChannel1Name: "GDS",
        salesChannel2: 426,
        salesChannel2Name: "API",
        salesChannel3: 103,
        salesChannel3Name: "ISE",
        salesChannel4: 151,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 295,
        salesChannel1Name: "GDS",
        salesChannel2: 227,
        salesChannel2Name: "API",
        salesChannel3: 172,
        salesChannel3Name: "ISE",
        salesChannel4: 302,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 472,
        salesChannel1Name: "GDS",
        salesChannel2: 244,
        salesChannel2Name: "API",
        salesChannel3: 212,
        salesChannel3Name: "ISE",
        salesChannel4: 176,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 315,
        salesChannel1Name: "GDS",
        salesChannel2: 399,
        salesChannel2Name: "API",
        salesChannel3: 431,
        salesChannel3Name: "ISE",
        salesChannel4: 168,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 234,
        salesChannel1Name: "GDS",
        salesChannel2: 133,
        salesChannel2Name: "API",
        salesChannel3: 161,
        salesChannel3Name: "ISE",
        salesChannel4: 265,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 350,
        salesChannel1Name: "GDS",
        salesChannel2: 448,
        salesChannel2Name: "API",
        salesChannel3: 372,
        salesChannel3Name: "ISE",
        salesChannel4: 365,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 261,
        salesChannel1Name: "GDS",
        salesChannel2: 415,
        salesChannel2Name: "API",
        salesChannel3: 351,
        salesChannel3Name: "ISE",
        salesChannel4: 390,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 264,
        salesChannel1Name: "GDS",
        salesChannel2: 323,
        salesChannel2Name: "API",
        salesChannel3: 355,
        salesChannel3Name: "ISE",
        salesChannel4: 288,
        salesChannel4Name: "OWN"
    }
]

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OopsCategory } from './oops-category.model';

import { CategoriesContextMenuComponent } from './context-menu/categories-context-menu.component';
@Component({
    selector: 'op-categories',
    templateUrl: './oops-categories.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OopsCategoriesComponent {
    private defaultActionId = 'SEARCH';
    @Input() items: OopsCategory[];
    @Input() selectedItem: OopsCategory;
    @Input() showCountColumn: boolean = false;
    @Output() menuSelected = new EventEmitter<OopsCategory>();
    @Output() munuDoubleClick = new EventEmitter<OopsCategory>();
    @Output() contextMenuSelected = new EventEmitter<string>();
    @ViewChild("contextMenu")
    contextMenu: CategoriesContextMenuComponent;

    public selectMenu(actionId: string) {
        this.contextMenuSelected.emit(actionId);
    }

    public showContextMenu(event: MouseEvent): boolean {
        return false;
    }

    public onItemClick(item: OopsCategory) {
        if (this.selectedItem != item) {
            this.selectedItem = item;
            this.menuSelected.emit(this.selectedItem);
        }
    }

    public onItemDoubleClick(item: OopsCategory) {
        this.selectedItem = item;
        this.munuDoubleClick.emit(this.selectedItem);
        this.contextMenuSelected.emit(this.defaultActionId);
    }

    public formatNumberComma(e:number) {
        return e?.toLocaleString('en', {useGrouping:true});
    }
}
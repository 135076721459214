export class ProductRestrictionProductNumberViewModel {
    public productRestrictionProductNumberId: string;
    public productNumberTypeCode: string;
    public productNumberTypeName: string;
    public productNumber: string;
    public providerId: string;
    public providerName: string;
    public supplierId: string;
    public supplierName: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public hierarchyKey: string;
    public inherit: boolean;
}
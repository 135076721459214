import { Injectable } from "@angular/core";
import { InsightPassengerLanguageModel } from "src/app/core/models/individual-model";
import { LanguageView } from "./language-view";

@Injectable()
export class LanguageMapperService {
    constructor() {
    }

    public languageModelToViews(models: InsightPassengerLanguageModel[]): LanguageView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.languageModelToView(i, model));
        }
        return views;
    }

    private languageModelToView(index: number, model: InsightPassengerLanguageModel): LanguageView {
        let view = {} as LanguageView;
        view.no = index;
        view.individualLanguageId = model.individualLanguageId;
        view.individualId = model.individualId;
        view.languageCode = model.languageCode;
        view.languageName = model.languageName;
        view.primaryFlag = model.primaryFlag;

        return view;
    }

    public languageViewToModels(views: LanguageView[]): InsightPassengerLanguageModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.languageViewToModel(view));
        }
        return models;
    }

    private languageViewToModel(view: LanguageView): InsightPassengerLanguageModel {
        let model:InsightPassengerLanguageModel = {
            individualLanguageId: view.individualLanguageId,
            individualId: view.individualId,
            languageCode: view.languageCode,
            languageName: view.languageName,
            primaryFlag: view.primaryFlag
        };

        return model;
    }
}
import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import {
    IndividualChangeResponseModel,
    IndividualChangeCommunicationOtherModel,
    IndividualChangeCommunicationPhoneModel,
    IndividualChangeIdentityDocumentModel,
    IndividualChangeAddressModel,
    IndividualChangeModel
} from '../../models/order-model';

@Injectable({
    providedIn: 'root'
})
export class OrderIndividualChangeService extends ApiServiceBase {

    private readonly PATH: string = "/order/individual-change/orders/"
    private readonly SAVE_PATH: string = "/order/individual-change/"
    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient, authService);
    }

    public orderChangeIndividual(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId, sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }

    public orderChangeCommunicationOther(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeCommunicationOtherModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/communicationOthers", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderCancelCommunicationOther(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeCommunicationOtherModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/communicationOthers/cancel", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderChangeCommunicationPhone(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeCommunicationPhoneModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/communicationPhones", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderCancelCommunicationPhone(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeCommunicationPhoneModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/communicationPhones/cancel", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderChangeIdentityDocument(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeIdentityDocumentModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/identityDocuments", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderCancelIdentityDocument(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeIdentityDocumentModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/identityDocuments/cancel", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderChangeAddress(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeAddressModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/addresses", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public orderCancelAddress(headerOrderId: string,
        sessionId: string,
        requests: IndividualChangeAddressModel[]): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.getRequestUrl(this.PATH + headerOrderId + "/addresses/cancel", sessionId), requests)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }
    public deleteChangeSession(headerOrderId: string): Observable<IndividualChangeResponseModel> {

        return this.httpDelete(this.PATH + headerOrderId, null)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }

    public saveIndividualChange(sessionId: string) {

        return this.httpPost<IndividualChangeResponseModel>(this.SAVE_PATH + "sessions/" + sessionId, null)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }

    public orderUndoIndividualChange(headerOrderId: string,
        undoOrderId: string,
        sessionId: string): Observable<IndividualChangeResponseModel> {

        return this.httpPut<IndividualChangeResponseModel>(this.PATH 
            + headerOrderId 
            + "/undo-orders/" 
            + undoOrderId 
            + "/sessions/" 
            + sessionId, null)
            .pipe(
                map(response => {
                    return response.body;
                }),
                catchError(this.handleError)
            );
    }

    private getRequestUrl(url: string, sessionId: string) {
        if (!sessionId) {
            return url + "/sessions";
        }
        return url + "/sessions/" + sessionId;
    }
    private handleError(error: HttpErrorResponse): Observable<IndividualChangeResponseModel> {

        let errorDetail = {
            sessionId: null,
            message: null
        };
        if (error.error instanceof ErrorEvent) {
            errorDetail.message = `A client-side or network error occurred: ${error.error.message}`;
        } else {
            errorDetail.message = `Server returned code: ${error.status}`;
            if (typeof error.error === 'string') {
                errorDetail.message = error.error;
            } else {
                errorDetail.message = error.error?.message;
            }
        }
        return throwError(errorDetail);
    }
}
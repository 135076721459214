import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { CreditAccountSearchModel } from '../../models/credit-account-model/credit-account-search.model';
import { CreditAccountLedger } from '../../models/credit-account-model/credit-account-ledger.model';
import { DateConverterService } from '../../utils/date-converter.service';

@Injectable({
    providedIn: 'root'
})
export class OrganisationCreditAccountService extends ApiServiceBase {
    private readonly PATH = '/organisation/OrganisationCreditAccount'

    constructor(private httpClient: HttpClient,
        private authService: AuthService,
        private dateConverterService: DateConverterService) { 
            super(httpClient, authService)
    }

    public getOrganisationCreditAccount(condition: CreditAccountSearchModel): Observable<CreditAccountSearchModel[]> {
        return this.httpPost<CreditAccountSearchModel[]>(`${this.PATH}/search`, condition)
            .pipe(
                map(response => response.body)
            )
    }

    public getCreditAccountByOrganisationId(organisationId: string): Observable<CreditAccountSearchModel> {
        return this.httpGet<CreditAccountSearchModel>(`${this.PATH}/${organisationId}`, null)
            .pipe(
                map(response => response.body)
            )
    }

    public getCreditAccountLedger(organisationId: string, startDate: Date, endDate: Date): Observable<CreditAccountLedger[]> {
        let params = new HttpParams()
            .set('startDateTime', this.dateConverterService.convertDateTime24(startDate))
            .set('endDateTime', this.dateConverterService.convertDateTime24(endDate));
            
        return this.httpGet<CreditAccountLedger[]>(`${this.PATH}/${organisationId}/ledger`, params)
            .pipe(
                map(response => response.body)
            )
    }
} 
import { ProductHierarchyViewModel } from './product-hierarchy'
import { ProductHashTagViewModel } from './product-hashtag';
import { ProductNumberViewModel } from './product-number';
import {
    ProductRestrictionOrganisationViewModel,
    ProductRestrictionLocationViewModel,
    ProductRestrictionProductViewModel,
    ProductRestrictionProductNumberViewModel,
    ProductRestrictionRouteViewModel,
    ProductRestrictionVehicleViewModel
} from './product-restriction';
import { ProductValidityViewModel } from './product-validity';
import { ProductAttributeViewModel } from './product-attribute';
import { ProductInventoryAttributeTypeViewModel } from './product-inventory-attribute-type';
import { ProductInventoryHierarchyViewModel } from './product-inventory-hierarchy';
import { LanguageTranslationSetViewModel } from '../../language-translation-set-model';
import { MediaViewModel } from '../../media-model';
import { ProductInventoryDimensionViewModel } from './product-inventory-dimension';

export abstract class ProductViewModel {
    public productId: string;
    public organisationId: string;
    public parentProductId: string;
    public productGroupCode: string;
    public productGroupName: string;
    public productTypeCode: string;
    public productTypeName: string;
    public supplierId: string;
    public supplierName: string;
    public providerId: string;
    public providerName: string;
    public productName: string;
    public productDescription: string;
    public displayCode: string;
    public displayName: string;
    public draftFlag: boolean;
    public finalFlag: boolean;
    public filterUserAccountId: string;
    public isOwnerFilter: boolean;
    public usageTypeCode: string;
    public usageTypeName: string;
    public rootProductId: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public ownerFilterUserAccountId: string;
    public statusCode: string;
    public isHighestLevelAttrInv: boolean;
    public inventoryAttributeLowerLevel: string;
    public commitBy: string;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public productHierarchy: ProductHierarchyViewModel;
    public productHashtags: ProductHashTagViewModel[];
    public productNumbers: ProductNumberViewModel[];
    public productRestrictionProducts: ProductRestrictionProductViewModel[];
    public productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[];
    public productRestrictionRoutes: ProductRestrictionRouteViewModel[];
    public productRestrictionLocations: ProductRestrictionLocationViewModel[];
    public productValidities: ProductValidityViewModel[];
    public productAttributes: ProductAttributeViewModel[];
    public productInventoryAttributeTypes: ProductInventoryAttributeTypeViewModel[];
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    public productInventoryHierarchy: ProductInventoryHierarchyViewModel[];
    public productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[];
    public productRestrictionVehicles?: ProductRestrictionVehicleViewModel[];
    public languageTranslationSet: LanguageTranslationSetViewModel;
    public medias: MediaViewModel[];
    public hierarchyLevel: number;
}

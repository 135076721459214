import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { select2HashtagOption } from 'src/app/modules/product-management/product-categories-content/shared/configuration/select2-hashtag-configuration';
import { ProductHashTagView } from 'src/app/modules/product-management/product-categories-content/shared/views/product-hashtag.view';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-ancillary-service-hashtag',
    templateUrl: './hashtag.component.html'
})
export class HashtagComponent implements OnChanges {
    @Input() productHashTags: ProductHashTagView[];
    @Input() searchMode: boolean = false;
    @Input() showHashtagRow: boolean = false;
    @Output() showHashtagRowChange = new EventEmitter<boolean>();
    @Output() valueReturn = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public hashTagOption: any;
    public hashTagId: string = this.getHastTagId();
    public focusing: boolean = false;
    constructor() {
        this.hashTagOption = select2HashtagOption;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productHashTags']) {
            if (this.productHashTags) {
                let show = this.selectedExists();
                this.setShowHashtagRow(show);
            } else {
                this.setShowHashtagRow(false);
            }
        }
    }

    public selectedExists() : boolean {
        if (this.productHashTags) {
            let isExists = this.productHashTags.filter(x => x.productHashTagId != null).length > 0;
            if (isExists && !this.showHashtagRow) {
                this.setShowHashtagRow(true);
            }
            return isExists;
        }
        return false;
    }

    public hashTagChange(value) {
        this.valueReturn.emit(value);
    }

    public focusHashtag() {
        setTimeout(() => {
            let elementReference = document.querySelector('#' + this.hashTagId);
            if (elementReference instanceof HTMLElement) {
                elementReference.focus();
            }
        }, 100)
    }

    private getHastTagId(): string {
        if (this.searchMode) {
            return "ddlHashTag";
        }
        return "ddlHashTagSearch";
    }

    public add() {
        this.setShowHashtagRow(true);
    }

    public delete() {
        this.setShowHashtagRow(false);
        this.valueReturn.emit([]);
    }

    public setShowHashtagRow(value: boolean) {
        this.showHashtagRow = value;
        this.showHashtagRowChange.emit(this.showHashtagRow);
    }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2RoleOption } from '../../../organisation-detail/shared/select2-configuration';

@Component({
    selector: 'op-organisation-role',
    templateUrl: './organisation-role.component.html'
})
export class OrganisationRoleComponent {

    @Input() organisationRoleSelect2Data: Select2Data[];
    @Input() selectedOrganisationRole: string[];
    @Input() isFilterPanel: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() newOrganisation: boolean = false;

    @Output() organisationRoleChange = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter();
    
    public roleOption: any;
    public model = null;
    public showRoleRow: boolean = false;
    public edited: boolean = false;
    public focusing: boolean = false;


    constructor() {
        this.roleOption = select2RoleOption;    
    }

    roleChange(value) {
        this.selectedOrganisationRole = value;
        this.edited = true;
        this.organisationRoleChange.emit(value);
    }

    public delete() {
        this.setShowRoleRow(false);
        this.selectedOrganisationRole = [];
        this.edited = true;
        this.organisationRoleChange.emit([]);
    }
    
    public setShowRoleRow(value: boolean) {
        this.showRoleRow = value;
    }

    public add() {
        this.setShowRoleRow(true);
    }

    get editFlag() {
        return this.isFilterPanel || (this.userSecurity?.editFlag || this.newOrganisation);
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.onSearch.emit();
                break;
            default:
                break;
        }
    }

}

import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { AttributeChoiceModel } from "src/app/core/models/reference-model/reference-general-model/attribute-choice.model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { duplicateGroupValidation } from "src/app/core/validators";
import { AttributeChoiceMultipleOption, AttributeChoiceSingleOption } from "src/app/modules/pricing/prices/price-detail/price-dimension/shared/options/select2-price-dimension.options";
import { Select2Option } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/shared/attribute-and-rule/views/select2.option";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { AttributeRowView } from "./shared/attribute-row-view";

@Component({
    selector: 'op-insight-detail-attribute',
    templateUrl: './insight-detail-attribute.component.html'
})
export class InsightDetailAttributeComponent extends OopsComponentFormBase implements OnChanges {
    public readonly title = "Attribute";
    private readonly ATTRIBUTE_TYPE = "attributeType";
    private readonly ERROR_REQUIRED = ' is required';

    public domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public attributeType$ = new BehaviorSubject<Select2Data[]>(null);
    public attributeChoices$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    public attributeChoices = new Array<Select2Data[]>();
    public attributeChoiceOption$ = new BehaviorSubject<any[]>(null);
    public attributeChoiceOptions: any[];
    public attributeChoiceMultipleOption = AttributeChoiceMultipleOption;
    public attributeChoiceSingleOption = AttributeChoiceSingleOption;
    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);

    public attributeRows: AttributeRowView[];
    public focusing: boolean = false;

    public atributeSelectLists: Select2Data[][] = [];

    public select2AttributeTypeOption = new Select2Option("<Select>");
    public select2AttributeTypeDisableOption = new Select2Option("<Select>");
    private firstSetupAttribute = true;

    @Input() searchMode = false;
    @Input() attributeRowViews: AttributeRowView[];
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() advanceSearchMode: boolean = false;
    @Input() copyMode: boolean = false;
    @Input() domainAttributes: DomainAttributeModel[];

    @Input('blurAfterSelected') blurAfterSelected: boolean = false;

    get isReadonly(): boolean {
        if (this.advanceSearchMode) {
            return false;
        }

        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    get allowToggle(): boolean {
        return this.rows?.length > 0;
    }

    get rows(): UntypedFormArray {
        return this.formGroup?.get("rows") as UntypedFormArray;
    }

    public getSelect2Option(requireFlag: boolean): Select2Option {
        if(requireFlag) {
            return this.select2AttributeTypeDisableOption;
        }
        else {
            return this.select2AttributeTypeOption;
        }
    }

    constructor(private fb: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef) {
        super(fb);

        this.select2AttributeTypeDisableOption.disabled = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['domainAttributes'] && this.domainAttributes?.length) {
            this.assignDataToReferenceModel();
            this.assignOptionListToSelect2();
            if (this.firstSetupAttribute == true) {
                this.firstSetupAttribute = false;
                this.setupDefaultAttribute();
            }
        }
    }

    private assignDataToReferenceModel() {
        this.domainAttributes$.next(this.domainAttributes);
        this.attributeType$.next(this.domainAttributes.map(item => new Select2Data(item.attributeTypeCode, item.attributeTypeName)));
    }

    private assignOptionListToSelect2() {
        this.populateChoices(this.domainAttributes);
        this.populateChoiceOptions(this.domainAttributes);
    }    

    public initForm() {      
        this.formGroup = new UntypedFormGroup({
            rows: new UntypedFormArray([], duplicateGroupValidation),
        });

        this.togglePanelCollapseStatus(true);
    }

    private setupDefaultAttribute() {
        this.addRowFollowDomainRequiredFlag();
        this.addShowOnNewAttribute();
    }
    
    private addRowFollowDomainRequiredFlag() {
        if (this.rows.controls?.length || this.searchMode) {
            return;
        }

        let requiredAttributes = this.domainAttributes$.value?.filter(it => (it.requiredFlag && !it.showOnNewFlag)) ?? [];
        for (let requiredAttribute of requiredAttributes) {
            this.panelCollapseFlag$.next(false);
            let formGroup = this.createNewFormGroup();
            formGroup.controls['requiredFlag'].setValue(true);
            this.onAttributeTypeChange(requiredAttribute.attributeTypeCode, formGroup);

            this.rows.push(formGroup);
            this.toggleCollapseFollowNumberOfRows();
        }
        this.setAttributeLists();
    }

    get typeDataSet(): { id: string; text: string }[][] {
        let resultSet = new Array<Array<{ id: string; text: string }>>();
        for (let row of this.rows.controls) {
            let typeData = row.get('attributeType').value;
            let result = this.attributeType$.value?.filter(a => a.id == typeData || this.selectedArray.includes(a.id) == false)
            resultSet.push(result);
        }
        return resultSet;
    }

    setAttributeLists() {
        let attributeLists: Select2Data[][] = [];

        for (let row of this.rows.controls) {
            let test = this.attributeType$.value?.filter(x => this.selectedArray.includes(x.id) == false || x.id == row.get('attributeType').value).map(item => item);
            attributeLists.push(test);
        }

        this.atributeSelectLists = (attributeLists);
    }

    setAttributeListsForNewRow() {
        this.atributeSelectLists.push(this.attributeType$.value.filter(attr => this.rows.controls.map(ctrl => ctrl.get('attributeType').value).includes(attr.id) == false))
    }

    private addShowOnNewAttribute() {
        if (!this.newInsightPassenger || this.searchMode) {
            return;
        }

        let showOnNewAttributes = this.domainAttributes$.value?.filter(it => (it.showOnNewFlag && !it.requiredFlag) || (it.showOnNewFlag && it.requiredFlag)) ?? [];
        for (let showOnNewAttribute of showOnNewAttributes) {
            this.panelCollapseFlag$.next(false);
            let formGroup = this.createNewFormGroup();
            this.onAttributeTypeChange(showOnNewAttribute.attributeTypeCode, formGroup);

            this.rows.push(formGroup);
            this.toggleCollapseFollowNumberOfRows();
        }
        this.setAttributeLists();
    }

    public fillAttributeRowView(views: AttributeRowView[]) {
        if (views?.length) {
            this.attributeRows = views;
        }
    }

    public fillModelToForm(views: AttributeRowView[]) {
        if (!this.domainAttributes?.length) {
            return;
        }

        if (views?.length) {
            this.rows?.clear();
            this.panelCollapseFlag$.next(false);
              
            for (let view of views) {
                let formGroup = this.createNewFormGroup();
                this.onAttributeTypeChange(view.attributeTypeCode, formGroup);
                
                let attribute = this.domainAttributes.filter(item => item.attributeTypeCode == view.attributeTypeCode)[0];
                this.addAttributeValue(formGroup, view, attribute);
                this.rows.push(formGroup);
            }

            this.setAttributeLists();
            this.toggleCollapseFollowNumberOfRows();
        }
    }

    public fillModelToFormWithDomainAttribute(views: AttributeRowView[], domains: DomainAttributeModel[]) {
        if (views?.length) {
            this.rows?.clear();
            this.panelCollapseFlag$.next(false);
              
            for (let view of views) {
                let formGroup = this.createNewFormGroup();
                this.onAttributeTypeChange(view.attributeTypeCode, formGroup);

                let attribute = domains.filter(item => item.attributeTypeCode == view.attributeTypeCode)[0];
                this.addAttributeValue(formGroup, view, attribute);
                this.rows.push(formGroup);
            }

            this.setAttributeLists();
            this.toggleCollapseFollowNumberOfRows();
        }
    }

    private addAttributeValue(row: UntypedFormGroup, view: AttributeRowView, attribute: DomainAttributeModel) {
        switch (attribute.dimensionUnitCode) {
            case 'CHOICE':
                this.addAttributeChoice(row, view);
                break;
            case 'NUMBER':
            case 'PERCENTAGE':
                row.get('choiceValue').setValue(view.attributeValue);
                break;
            case 'DATE':    
            case 'DATETIME':
            case 'TIME':
                row.get('choiceValue').setValue(view.attributeDateTime);
                break;
            case 'TEXT':
                row.get('choiceValue').setValue(view.attributeText);
                break;
        }
    }

    addAttributeChoice(row: UntypedFormGroup, view: AttributeRowView) {
        if (row.get('multipleChoiceFlag').value) {
            row.get('choiceValue').setValue(view?.attributeChoiceIds);
        } else {
            row.get('choiceValue').setValue(view?.attributeChoiceIds[0]);
        }
    }

    public getInsightPassengerAttribute(): AttributeRowView[] {
        if (!this.rows.controls?.length) {
            return null;
        }
        let views = new Array();
        for (let i = 0; i < this.rows.controls.length; i++) {          
            let control = this.rows.controls[i] as UntypedFormGroup;
            if (!control.get('choiceValue').value && control.get('requiredFlag').value != true) {
                continue;
            }
            let attribCode = control.controls[this.ATTRIBUTE_TYPE].value;
            let view: AttributeRowView = {
                attributeChoiceIds: null,
                attributeTypeCode: attribCode,
                attributeTypeName: this.getAttributeName(attribCode),
                attributeValue: null,
                duplicated: false,
                isFreeTextChoice: false,
                sortSequence: i+1,
                isMultiChoices: false,
                attributeText: null,
                attributeDateTime: null
            };
            this.assignAttributeValue(control, view);

            views.push(view);
        }

        return views;
    }

    private createNewFormGroup(): UntypedFormGroup {
        return this.fb.group({
            attributeType: [null, [Validators.required]],
            dimensionUnitCode: [null],
            attributeData: [null],
            multipleChoiceFlag: [null],
            requiredFlag: [null],
            attributeChoice: [null],
            choiceValue: [null, [Validators.required]],
            maxValue: [null],
            minValue: [null],
            choiceOption:[null]
        });
    }

    private populateChoices(res: DomainAttributeModel[]) {
        let choicesArr = new Array<Select2Data[]>();
        for (let r of res) {
            let choices = r.attributeChoices.map(c => new Select2Data(c.attributeChoiceId, c.attributeChoiceName));
            choicesArr[r.attributeTypeCode] = choices;
        }
        this.attributeChoices$.next(choicesArr);
        this.attributeChoices = choicesArr;
    }

    private populateChoiceOptions(res: DomainAttributeModel[]) {
        let optionArr = new Array<any>();
        for (let r of res) {
            optionArr[r.attributeTypeCode] = r.multipleChoiceFlag
                ? AttributeChoiceMultipleOption
                : AttributeChoiceSingleOption;
        }
        this.attributeChoiceOption$.next(optionArr);
        this.attributeChoiceOptions = optionArr;
    }

    add() {
        if (this.someEmptyEntriesFound()) {
            return;
        }

        this.panelCollapseFlag$.next(false);
        this.rows.push(this.createNewFormGroup());
        this.setAttributeListsForNewRow();
        this.toggleCollapseFollowNumberOfRows();
    }

    private someEmptyEntriesFound(): boolean {
        return this.rows.controls.some((group: UntypedFormGroup) => {
            return Object.keys(group.controls).some(key =>
                group.controls[key].hasError('required')
            );
        });
    }

    public toggleCollapseFollowNumberOfRows() {
        if (this.rows?.length == 0) {
            this.panelCollapseFlag$.next(true);
        } else {
            this.panelCollapseFlag$.next(false);
        }
    }

    togglePanelCollapseStatus(isCollapse: boolean) {
        if (!isCollapse && !this.rows?.length) {
            return;
        }
        this.panelCollapseFlag$.next(isCollapse);
    }

    onAttributeTypeChange(type: any, row: any) {
        row.controls['attributeType'].setValue(type);
        if (!this.domainAttributes$.value?.length) {
            return;
        }
        let attribute = this.domainAttributes$.value?.filter(item => item.attributeTypeCode == type)[0];
        this.setupAttributeRow(row, attribute);
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();
        }, 300); 
    }

    setupAttributeRow(row: UntypedFormGroup, attribute: DomainAttributeModel) {
        row.get('dimensionUnitCode').setValue(attribute.dimensionUnitCode);
        row.get('maxValue').setValue(attribute.maximumValue);
        row.get('minValue').setValue(attribute.minimumValue);
        row.get('requiredFlag').setValue(attribute.requiredFlag);

        if (attribute.dimensionUnitCode == 'CHOICE') {
            this.setupAttributeChoice(row, attribute);
        } else if (attribute.dimensionUnitCode == 'NUMBER') {
            this.setDefaultValue(row, attribute);
        }
    }

    setupAttributeChoice(row: UntypedFormGroup, attribute: DomainAttributeModel) {
        row.get('choiceOption').setValue(this.attributeChoiceOptions[attribute.attributeTypeCode]);
        row.get('attributeChoice').setValue(this.attributeChoices[attribute.attributeTypeCode]);
        row.get('multipleChoiceFlag').setValue(attribute.multipleChoiceFlag);
        let defaultChoice = this.getDefaultAttributeCode(attribute);
        let defaultChoicOnSelect2Data = this.getFirstAttributeChoice(attribute);
        if (defaultChoice?.length > 0) {
            this.setDefaultChoiceValue(row, attribute, defaultChoice);
        }
        else {
            this.setDefaultChoiceValueBySelect2Data(row, attribute, defaultChoicOnSelect2Data);
        }
    }

    getDefaultAttributeCode(attribute: DomainAttributeModel)  {
        let choices = attribute.attributeChoices?.filter(choice => choice.defaultFlag == true);
        return choices?.length > 0 ? choices : null;
    }

    getFirstAttributeChoice(attribute: DomainAttributeModel) {
        let choice = [];
        if (this.attributeChoices[attribute.attributeTypeCode]?.length > 0) {
            choice.push(this.attributeChoices[attribute?.attributeTypeCode][0]);
        }
        return choice;
    }

    setDefaultChoiceValue(row: UntypedFormGroup, attribute: DomainAttributeModel, choices: AttributeChoiceModel[]) {
        if (attribute.multipleChoiceFlag) {
            row.get('choiceValue').setValue(choices?.map(item => item.attributeChoiceId));
        } else {
            row.get('choiceValue').setValue(choices[0]?.attributeChoiceId);
        }
    }

    setDefaultChoiceValueBySelect2Data(row: UntypedFormGroup, attribute: DomainAttributeModel, choices: Select2Data[]) {
        if (attribute.multipleChoiceFlag) {
            row.get('choiceValue').setValue(choices?.map(item => item.id));
        } else {
            row.get('choiceValue').setValue(choices[0]?.id);
        }
    }

    setDefaultValue(row: UntypedFormGroup, attribute: DomainAttributeModel) {
        if (attribute.defaultValue) {
            row.get('choiceValue').setValue(attribute?.defaultValue);
        }
    } 

    public deleteRow(index: number) {
        this.rows.removeAt(index);
        this.toggleCollapseFollowNumberOfRows();
    }

    public validateForm(): boolean {
        this.startProcessing();

        if (this.rows?.errors) {
            return false;
        }

        this.completeProcessing();
        return true;
    }

    get selectedArray(): string[] {
        return this.rows?.value?.map(item => item['attributeType']);
    }

    public getAttributeTypeSelectList(type: string) : Select2Data[] {
        return this.attributeType$.value?.filter(x => this.selectedArray.includes(x.id) == false || x.id == type).map(item => item);
    }

    public checkHiddenDelete(row): boolean {
        if (!this.checkIsRequiredFlag(row.get('attributeType').value) || this.searchMode) {
            return false;
        }
        return true;
    }

    private checkIsRequiredFlag(attributeTypeCode: string) {
        if (attributeTypeCode && this.domainAttributes$.value) {
            var filter = this.domainAttributes$.value.filter(x =>
                x.attributeTypeCode == attributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private assignAttributeValue(row: UntypedFormGroup,view: AttributeRowView) {
        switch (row.value.dimensionUnitCode) {
            case 'NUMBER':
            case 'PERCENTAGE':
                view.attributeValue = row.value.choiceValue;
                break;
            case 'DATE':
            case 'TIME':
            case 'DATETIME':
                view.attributeDateTime = row.value.choiceValue;
                break;
            case 'TEXT':
                view.attributeText = row.value.choiceValue;
                break;
            case 'CHOICE':
                this.assignChoiceValue(row, view);
                break;
        }
    }

    assignChoiceValue(row: UntypedFormGroup,view: AttributeRowView) {
        if (Array.isArray(row.value.choiceValue)) {
            view.attributeChoiceIds = row.value.choiceValue;
        } else {
            view.attributeChoiceIds = [row.value.choiceValue];
        }
    }

    dataChange(value: any, row: any) {
        row.get('choiceValue').setValue(value);
        if (row.value.dimensionUnitCode == 'NUMBER') {
            this.validateNumber(value, row);
        }
    }

    validateNumber(value: string, row: UntypedFormGroup) {
        if (value == undefined) {
            row.setErrors({outOfRange: true});
            return;
        }
        row.setErrors(null);
    }

    getRequiredAttribute() {
        return this.rows.controls.filter(item => this.checkIsRequiredFlag(item.value.attributeType));;
    }

    validateRequired() {
        let requiredRows = this.getRequiredAttribute();
        if (requiredRows.length == 0) {
            return true;
        }

        for (let row of requiredRows) {
            if (!row.valid) {
                return false;
            }
        }
        return true;
    }

    getAttributeName(attributeTypeCode: string) {
        return this.domainAttributes$.value.filter(item => item.attributeTypeCode == attributeTypeCode)[0].attributeTypeName;
    }

    getErrorMessage(): string {
        let requiredRows = this.getRequiredAttribute();
        for (let row of requiredRows) {
            if (!row.valid) {
                return this.getAttributeName(row.value.attributeType) + this.ERROR_REQUIRED;
            }
        }

        return 'Required Attribute is invalid';
    }

}   
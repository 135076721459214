import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from './core/authentication/auth.service';
import { DateTimeFormatService } from './core/services/datetime-format.service';
import { ThemeService } from './core/services/theme.service';
import { USER_LOGON_DATA } from './modules/auth/shared/constants/auth.constant';
import * as AuthActions from './store/auth/auth.actions';

@Component({
  selector: 'smart-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  title = 'Oops Solution Portal';
  constructor(
    private themeService: ThemeService,
    private store: Store<any>,
    private authService: AuthService,
    private dateTimeFormatService: DateTimeFormatService
  ) { }
  ngOnInit(): void {
    if (this.authService.sessionStorageGetItem(USER_LOGON_DATA)) {
      this.store.dispatch(AuthActions.autoLogin());
    }
    document.getElementById('oopsLoader').style.display = 'none';
    this.themeService.loadActiveStyle();
    this.dateTimeFormatService.setGlobalDateTimeFormats();
  }

}

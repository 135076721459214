export class ProductRestrictionLocationCommandModel {
    public productRestrictionLocationId:string;
    public productLocationTypeCode:string;
    public productLocationPointCode:string;
	public locationId:string; 
	public locationName:string; 
	public locationGroupId:string; 
	public regionCode:string; 
	public countryCode:string; 	 
	public excludeFlag:boolean;	 
    public displaySequence: number;
}
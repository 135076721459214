import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CashbookDocumentTFOPModel, TotalFromOfPaymentItemsModel } from 'src/app/core/models/cashbook-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
  selector: 'op-cashbook-detail-tfop',
  templateUrl: './cashbook-detail-tfop.component.html',
})
export class CashbookDetailTFOPComponent implements OnChanges { 
  public collapsed: boolean = false;
  public dataDetailItem: TotalFromOfPaymentItemsModel[];
  public focusing: boolean = false;

  get getDataCount(): string {
    if (this.dataDetail) {
        return this.dataDetail.dataCount
    }
    return '';
  }
  get TotalAmount(): string {
    if (this.dataDetail) {
        return this.dataDetail.amount;
    }
    return '';
  }

  get TotalCurrencyCode(): string {
    if (this.dataDetail && this.dataDetail?.totalFromOfPaymentItems) {
        return this.dataDetail?.totalFromOfPaymentItems[0].ledgerCurrencyCode;
    }
    return '';
  }

  @Input() dataDetail: CashbookDocumentTFOPModel;  

  @ViewChild("panel") panel: any;
  @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataDetail']) {
      if(this.dataDetail){
        this.dataDetailItem = this.dataDetail?.totalFromOfPaymentItems;
      }
    }
  }
  
  public collapsedStatus(collapsed: any) {
    this.collapsed = collapsed;
  }

  public togglePanel($event: MouseEvent) {
    this.panel.toggleCollapse($event);
    this.collapsed = !this.collapsed;
  }
}

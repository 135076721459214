import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { CookiesService } from 'src/app/shared/utils/cookies.service';
import { DateConverterService } from './../../../core/utils/date-converter.service';
import { IUserDropdown } from 'src/app/core/models/user-model/user-dropdown.model';
import { MediaFilePreviewService } from './../../../core/services/media-services/media-file-preview-service';
import { MediaMapperService } from './../../../core/components/media/shared/media-mapper.service';
import { MediaService } from './../../../core/services/media-services/media-sevice';
import { NavigationService } from '../../utils/navigation';
import { selectAuthState } from './../../../store/auth/auth.selectors';
import { TabService } from '../../../core/utils/tab.service';
import { ThemeService } from '../../../core/services/theme.service';
import { UserAccountLogonHistoryService } from 'src/app/core/services/system-services/user-account-logon-history.service';

import * as AuthActions from './../../../store/auth/auth.actions';
import * as settings from './../../../store/settings';

import {
  REMEMBER_ME_COOKIES,
  ORGANISATION_COUNT,
  USER_INFORMATION,
  USER_LOGON_NAME,
  USER_PROFILE_IMAGE
} from 'src/app/modules/auth/shared/constants/auth.constant';


@Component({
  selector: 'smart-dropdown-user',
  templateUrl: './dropdown-user.component.html',
  providers: [MediaMapperService]
})
export class DropdownUserComponent implements OnInit, OnDestroy {

  public readonly FONT_SIZE_SMALL = "sm";
  public readonly FONT_SIZE_MEDIUM = "md";
  public readonly FONT_SIZE_LARGE = "lg";
  public readonly FONT_SIZE_EXTRA_LARGE = "xl";
  public readonly LIGHT_MODE_CSS_NAME = "styles.css";
  public readonly THEME_STYLE_NAME: string = "theme_style";

  public afterUserSelectOrgInfo: IUserDropdown;
  public element: any
  public fontSizeDispaly: string;
  public imageUrl: string;
  public orgCount: number;
  public themeDisplay: string;
  public user: IUserDropdown;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private userAccountId: string;
  constructor(
    private authService: AuthService,
    private cookiesService: CookiesService,
    private dateConverterService: DateConverterService,
    private mediaService: MediaService,
    private mediaFilePreviewService: MediaFilePreviewService,
    private navigationService: NavigationService,
    private store: Store<any>,
    private tabService: TabService,
    private themeService: ThemeService,
    private userAccountLogonHistoryService: UserAccountLogonHistoryService
  ) {
    const userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION))
    const userNameInfo = JSON.parse(this.getSessionItem(USER_LOGON_NAME))
    const orgCount = JSON.parse(this.getSessionItem(ORGANISATION_COUNT))
    this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.userAccountId = state.userAccountId || userNameInfo.userAccountId;
        this.orgCount = state.orgCount || orgCount;
        this.user = {
          firstName: state.firstName || userNameInfo.firstName,
          lastName: state.lastName || userNameInfo.lastName,
          email: state.email || userInfo.email,
          orgCallName: state.orgCallName || userInfo.orgCallName,
          securityGroupName: state.securityGroupName || userInfo.securityGroupName,
          lastLogon: this.formatDate(state.lastLogon || userInfo.lastLogon),
          buildVersion: state.buildVersion || userInfo.buildVersion,
          envName: state.envName || userInfo.envName
        };
      })
    this.getThemeDisplay();
    this.store.select(settings.selectSettingsState)
      .subscribe(state => {
        this.setFontSizeDisplay(state);
      });
  }

  private setFontSizeDisplay(state) {
    if (state.globalFontSize === this.FONT_SIZE_SMALL) {
      this.fontSizeDispaly = "Small";
    } else if (state.globalFontSize === this.FONT_SIZE_MEDIUM) {
      this.fontSizeDispaly = "Medium";
    } else if (state.globalFontSize === this.FONT_SIZE_LARGE) {
      this.fontSizeDispaly = "Large";
    } else if (state.globalFontSize === this.FONT_SIZE_EXTRA_LARGE) {
      this.fontSizeDispaly = "Extra Large";
    }
  }

  private getThemeDisplay() {
    var styleStorage = localStorage.getItem(this.THEME_STYLE_NAME);
    if (!styleStorage || styleStorage === this.LIGHT_MODE_CSS_NAME) {
      this.themeDisplay = "Light";
    } else {
      this.themeDisplay = "Dark";
    }
  }
  private getSessionItem(key: string) {
    return this.authService.sessionStorageGetItem(key)
  }

  private formatDate(date: string) {
    const notFoundValue: string = "N/A"
    if (!date || date === notFoundValue) {
      return notFoundValue;
    }
    return `${this.dateConverterService.monthDateTimeFormat(date)}(UTC)`
  }

  ngOnInit(): void {
    this.element = document.documentElement;
    this.getMediaContentId(this.userAccountId);
  }


  getMediaThumbnailFile(mediaContentId) {
    this.mediaService.getMediaThumbnailFile(mediaContentId)
      .subscribe(
        (resp) => {
          if (resp && resp.status === 200) {
            const urlMedia = this.mediaFilePreviewService.getURLMediaAzureStorage(mediaContentId);
            this.imageUrl = urlMedia
            this.authService.sessionStorageSetItem(USER_PROFILE_IMAGE, urlMedia)
            this.store.dispatch(AuthActions.userProfileImageUrl({ userProfileImageUrl: urlMedia }))
            return;
          }
        }
      );
  }

  getMediaContentId(userAccountId) {
    this.mediaService.getByEntityNameAndAssociation('UserAccount', userAccountId).subscribe(async resp => {
      if (resp.length > 0) {
        const mediaContentId = resp[0].mediaContents[0].mediaContentId;
        return this.getMediaThumbnailFile(mediaContentId)
      }
    })
  }

  public async logout(): Promise<boolean> {
    this.tabService.closeAllTab(true);
    await this.userAccountLogonHistoryService.addUserAccountLogoffHistory().toPromise();
    this.authService.logout();
    this.cookiesService.deleteCookies(REMEMBER_ME_COOKIES);
    window.location.reload();
    return false;
  }

  private deleteCookies() {
    this.cookiesService.deleteCookies(REMEMBER_ME_COOKIES);
  }
  public goToSelectOrg() {
    this.tabService.closeAllTab(true);
    this.navigationService.navigate('select-organisation', 'Organisation', true);
  }

  public fullScreen() {
    if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    } else if (this.element.mozRequestFullScreen) {
      /* Firefox */
      this.element.mozRequestFullScreen();
    } else if (this.element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.element.webkitRequestFullscreen();
    } else if (this.element.msRequestFullscreen) {
      /* IE/Edge */
      this.element.msRequestFullscreen();
    }
  }

  public setGlobalFontSize(size: string) {
    this.store.dispatch(settings.setGlobalFontSize({ size }));
  }

  public toggleThemeChange(styleName: string) {
    this.themeService.changeTheme(styleName);
    this.getThemeDisplay();
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

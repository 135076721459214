import { UntypedFormControl, Validators } from "@angular/forms";

export const FormOption = {
    no: new UntypedFormControl(null),
    activityId: new UntypedFormControl(null),
    organisationId: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
    domainCode: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
    activityTypeCode: new UntypedFormControl(null, Validators.required),
    activityPriorityCode: new UntypedFormControl(null),
    followUpDate: new UntypedFormControl(null),
    followUpTime: new UntypedFormControl(null),
    activityReasonCode: new UntypedFormControl(null),
    activityCommentId: new UntypedFormControl(null),
    commentId: new UntypedFormControl(null),
    commentTypeCode: new UntypedFormControl(null),
    classificationCode: new UntypedFormControl(null),
    commentText: new UntypedFormControl(null),
    timelimitDate: new UntypedFormControl(null),
    timelimitTime: new UntypedFormControl(null),
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { FormOption } from "./comment-detail-form-config";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { select2Comment } from "./comment-detail-configuration";
import { CommentView } from "../shared/comment.view";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MediaFileView } from "src/app/core/components/media/shared/media-file.view";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { MediaFilePreviewService } from "src/app/core/services/media-services/media-file-preview-service";
import Swal from 'sweetalert2'
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { MediaView } from "src/app/core/components/media/shared/media.view";
import { MediaTypeFileTypeModel, MediaTypeReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { BehaviorSubject } from "rxjs";
import { MediaService } from "src/app/core/services/media-services";
import { AuthService } from "src/app/core/authentication/auth.service";

@Component({
    selector: 'op-comment-detail',
    templateUrl: './comment-detail.component.html',
    providers: [
        MediaMapperService
    ]
})
export class CommentDetailComponent extends OopsComponentFormBase implements OnChanges {
    readonly ERROR_TYPE_REQUIRED = 'Comment Type is required.';
    readonly ERROR_TEXT_REQUIRED = 'Comment is required.';
    readonly DROPZONE_BINDING_DELAY_TIME = 500;
    readonly OPTIONAL_REQUIRED_FIELDS = [
        'commentTypeCode',
        'commentText'
    ]
    @Input() id: string;
    @Input() commentViews: CommentView[] = new Array();
    @Input() commentTypeReferences: CommentTypeReferenceModel[];
    @Input() commentClassificationReferences: CommentClassificationReferenceModel[];
    @Input() model: CommentView;
    @Input() noRecord: boolean = false;
    @Input() singleRecord: boolean = false;
    @Input() multipleRecord: boolean = false;
    @Input() newFromGrid: boolean = false;
    @Input() newFromDetail: boolean = false;
    @Input() editFromGrid: boolean = false;
    @Input() multipleMode: boolean = true;
    @Input() mediaModel = new MediaView();
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Output() view = new EventEmitter<CommentView>();
    @Output() new = new EventEmitter();
    @Output() delete = new EventEmitter<CommentView>();
    @Output() cancel = new EventEmitter();
    @Output() formStatus = new EventEmitter<boolean>();
    @Output() onSaveMultipleFile = new EventEmitter<File[]>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public readonly swal!: SwalComponent;
    focusing: boolean = false;
    statusOption = select2Comment;
    applied: boolean = false;

    public files: MediaFileView[] = [];
    public canPreviewFile: boolean = false;
    public canDownloadFile: boolean = false;
    public urlPreviewFile: any;
    public viewerType: string;
    public loadFileExistFinished: boolean = false;
    public fileChange: boolean = false;
    public maximumFileSize: boolean = false;
    public fileTypeInvalid: boolean = false;
    public fileNotFound: boolean = false;
    public mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);

    get disableDropzone(): boolean {
        return !this.formGroup.get('commentTypeCode').value;
    }

    constructor(formBuilder: UntypedFormBuilder,
        private mediaMapperService: MediaMapperService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private changeDetectorRef: ChangeDetectorRef,
        private mediaService: MediaService,
        private autService: AuthService,) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model']) {
            this.formSetup();
        }
    }

    initForm() {
        this.formSetup();
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(FormOption);
        if (!this.model) {
            this.clearForm();
        }
        this.fillModelToForm(this.model);
        this.focusForm();
        this.displayInCondition();

        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
        this.toggleRequiredFields();
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    private fillModelToForm(model: CommentView) {
        this.formGroup.patchValue({
            no: model.no,
            commentId: model.commentId,
            activityCommentId: model.activityCommentId,
            commentTypeCode: model.commentTypeCode,
            classificationCode: model.classificationCode,
            commentText: model.commentText,
            commitByName: model.commitByName,
            commitDateTime: model.commitDateTime
        });
        this.clearData();
        if (model.media) {
            this.fillModelInCase(model.media);
            this.getMediaFile(model.media);
        }
        this.changeDetectorRef.detectChanges();
    }

    private applyToModel() {
        let formValue = this.formGroup.getRawValue();
        this.getMediaAttachment();
        let model: CommentView = {
            no: formValue.no,
            commentId: formValue.commentId,
            activityCommentId: formValue.activityCommentId,
            activityId: this.id,
            commentTypeCode: formValue.commentTypeCode,
            commentTypeName: this.getCommentTypeName(formValue.commentTypeCode),
            classificationCode: formValue.classificationCode,
            classificationName: this.getClassificationName(formValue.classificationCode),
            commentText: formValue.commentText,
            commitByName: formValue.commitByName,
            commitDateTime: formValue.commitDateTime,
            media: this.getMedia()
        }
        this.applied = true;
        this.view.emit(model);
    }

    private getMedia() {
        if (this.files?.length) {
            return this.mediaModel;
        }
        return null;
    }

    valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
        this.toggleRequiredFields();
    }

    public toggleRequiredFields() {
        if (this.multipleMode) {
            return;
        }
        if (this.OPTIONAL_REQUIRED_FIELDS.some(field => this.formGroup.get(field).value)) {
            this.OPTIONAL_REQUIRED_FIELDS.forEach(field => {
                this.formGroup.get(field).addValidators(Validators.required)
                this.formGroup.get(field).updateValueAndValidity();
            })
        } else {
            this.OPTIONAL_REQUIRED_FIELDS.forEach(field => {
                this.formGroup.get(field).clearValidators();
                this.formGroup.get(field).updateValueAndValidity();
            })
        }
    }

    validateForm(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            return false;
        }
        return true;
    }

    private getCommentTypeName(commentTypeCode: string): string {
        let filter = this.commentTypeReferences.find(x => x.commentTypeCode == commentTypeCode);
        return filter?.commentTypeName;
    }

    private getClassificationName(commentClassificationCode: string): string {
        let filter = this.commentClassificationReferences.find(x => x.commentClassificationCode == commentClassificationCode);
        return filter?.commentClassificationName;
    }

    private clearForm() {
        this.formGroup.reset();
        this.completeProcessing();
        this.model = {} as CommentView;
        this.applied = false;
        this.files = [];
        this.formStatus.emit(true);
    }

    private focusForm() {
        if (this.newFromGrid || this.editFromGrid) {
            this.focusing = true;
        }
    }

    private displayInCondition() {
        if (this.singleRecord && !this.newFromDetail) {
            this.applied = true;
        } else {
            this.applied = false;
        }
    }

    public onApply() {
        if (this.validateForm()) {
            this.applyToModel();
        }
        else {
            this.formStatus.emit(false);
        }
    }

    onCancel() {
        this.clearForm();
        this.cancel.emit();
    }

    onNew() {
        this.new.emit();
    }

    onDelete() {
        this.delete.emit(this.model);
    }

    getErrorMessageForm(): string {
        if (!this.formGroup.controls["commentTypeCode"].valid) {
            return this.ERROR_TYPE_REQUIRED;
        } else if (!this.formGroup.controls["commentText"].valid) {
            return this.ERROR_TEXT_REQUIRED;
        }
        return null;
    }

    public isRequired(ctrl: AbstractControl): boolean {
        return ctrl.hasValidator(Validators.required);
    }

    public downloadFile() {
        if (this.files?.length) {
            const blob = this.files[0].mediaFile;
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.files[0].mediaFile.name;
            anchor.href = url;
            anchor.click();
        }
    }

    public previewFile() {
        if (this.files?.length) {
            if (this.mediaMapperService.isImage(this.files[0].mediaFile.type)) {
                this.previewImageFile();
            }
            else {
                this.previewOtherFile();
            }
        }
    }

    private previewImageFile() {
        const blob = this.files[0].mediaFile;
        const url = window.URL.createObjectURL(blob);
        Swal.fire({
            html: '<div class="col-12"><img src="' + url + '" style="max-width:700px;" ></div>',
            width: '800px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    private previewOtherFile() {
        const blob = this.files[0].mediaFile;
        if (this.mediaMapperService.isVideo(blob.type) ||
            this.mediaMapperService.isPdf(blob.type)) {
            this.viewerType = "url";
            this.urlPreviewFile = window.URL.createObjectURL(blob);
        }
        else if (this.mediaMapperService.isXLS(blob.type)) {
            this.viewerType = "google";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        else {
            this.viewerType = "office";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        this.swal.fire(
        );
    }

    public loadFileFinished(): boolean {
        if (this.mediaModel.mediaContentId && !this.mediaModel.mediaFileBase64) {
            return this.loadFileExistFinished;
        } else {
            return true;
        }
    }

    onSelect(event) {
        if (event.addedFiles.length > 1) {
            this.multipleFileProcess(event.addedFiles);
        }
        else {
            this.singleFileProcess(event.addedFiles[0]);
        }
    }

    private singleFileProcess(addedFile: File) {
        this.clearFile();
        if (!this.validateFile(addedFile)) {
            return;
        }
        this.mediaModel.fileChange = true;
        this.fileChange = true;
        let mediaFile = this.createMediaFileView(addedFile);
        this.displayFilePreview(mediaFile, addedFile.type);
    }

    private multipleFileProcess(addedFiles: File[]) {
        if (!this.validateMultipleFile(addedFiles)) {
            return;
        }
        else {
            this.onSaveMultipleFile.emit(addedFiles);
        }
    }

    private clearFile() {
        this.files = [];
        this.loadFileExistFinished = true;
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        this.canPreviewFile = false;
        this.canDownloadFile = false;
        this.fileNotFound = false;
    }

    private validateFile(f: File): Boolean {
        if (!this.validateFileType(f)) {
            this.fileTypeInvalid = true;
            return false;
        } else if (!this.validateFileSize(f)) {
            this.maximumFileSize = true;
            return false;
        }
        this.fileTypeInvalid = false;
        this.maximumFileSize = false;
        return true;
    }

    private createMediaFileView(fileData: File): MediaFileView {
        let mediaFileView = new MediaFileView();
        mediaFileView.mediaFile = fileData;
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            mediaFileView.mediaFileBase64 = reader.result;
        };
        return mediaFileView;
    }

    private displayFilePreview(mediaFile: MediaFileView, contentType: string) {
        this.canDownloadFile = true;
        if (this.mediaMapperService.isImage(contentType)) {
            this.canPreviewFile = true;
            mediaFile.mediaFilePreview = mediaFile.mediaFile;
            this.addFileToDropZone(mediaFile);
            this.refreshPage();
        }
        else if (this.mediaMapperService.isVideo(contentType) || this.mediaMapperService.isPdf(contentType)) {
            this.canPreviewFile = true;
            this.getPicFilePreview(mediaFile, contentType);
        }
        else if (this.mediaMapperService.isDoc(contentType)) {
            this.canPreviewFile = (this.checkCanPreviewFile() && !this.mediaModel.fileChange);
            this.getPicFilePreview(mediaFile, contentType);
        } else {
            this.getPicFilePreview(mediaFile, contentType);
        }
    }

    private validateMultipleFile(addedFiles: File[]): Boolean {
        for (let f of addedFiles) {
            if (!this.validateFile(f)) {
                return false;
            }
        }
        return true;
    }

    private validateFileType(f: File): Boolean {
        if (!this.mediaTypeFileType$.value) {
            return false;
        }
        var fileExt = this.mediaMapperService.getFileExt(f.name);
        var filterFileTypeValid = this.mediaTypeFileType$.value.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (filterFileTypeValid?.length) {
            return this.checkFileTypeValid(filterFileTypeValid);
        }
        else {
            return false;
        }
    }

    private validateFileSize(f: File): Boolean {
        if (this.mediaTypeReference$.value) {
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);
            if (filter.length > 0) {
                if (filter[0].maximumSize) {
                    if (f.size <= filter[0].maximumSize) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private addFileToDropZone(mediaFile: MediaFileView) {
        this.files = [];
        this.files.push(mediaFile);
        try {
            this.changeDetectorRef.detectChanges();
        }
        catch {
        }
    }

    private refreshPage() {
        setTimeout(() => {
            try {
                this.changeDetectorRef.detectChanges();
                this.changeDetectorRef.detectChanges(); //For fix sometimes not load image
            }
            catch (e) {
                e = null;
            }
        }, this.DROPZONE_BINDING_DELAY_TIME);
    }

    private getPicFilePreview(mediaFile: MediaFileView, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFile.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.addFileToDropZone(mediaFile);
                        this.refreshPage();
                    }
                }
            );
    }

    private checkCanPreviewFile(): boolean {
        if (this.fileChange) {
            return false;
        }
        else {
            return true;
        }
    }

    private checkFileTypeValid(fileTypeValid: MediaTypeFileTypeModel[]): boolean {
        if (this.mediaModel.mediaTypeCode) {
            var filter = fileTypeValid.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);
            if (filter.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            this.assignFileTypeToModel(fileTypeValid);
            return true;
        }
    }

    private assignFileTypeToModel(fileTypeValid: MediaTypeFileTypeModel[]) {
        if (!this.mediaTypeReference$.value) {
            return;
        }
        var mediaTypeCode = fileTypeValid[0].mediaTypeCode;
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaTypeCode);
        if (filter.length > 0) {
            this.mediaModel.mediaUseCode = filter[0].mediaUseCode;
            this.filterMediaType(this.mediaModel.mediaUseCode);
            this.mediaModel.mediaTypeCode = mediaTypeCode;
        }
    }

    private filterMediaType(mediaUseCode: string) {
        if (this.mediaTypeReference$.value) {
            this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
            if (mediaUseCode) {
                this.filterMediaTypeData(mediaUseCode);
            }
            else {
                this.clearMediaType();
            }
        }
    }

    private filterMediaTypeData(mediaUseCode: string) {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
        this.mediaTypeReferenceFilter$.next(filter);
        this.assignToMediaTypeCode(filter)
    }

    private assignToMediaTypeCode(filter: MediaTypeReferenceModel[]) {
        if (filter.length != 0) {
            this.mediaModel.mediaTypeCode = filter[0].mediaTypeCode;
        }
        else {
            this.clearMediaType();
        }
    }

    private clearMediaType() {
        this.mediaModel.mediaTypeCode = null;
        this.mediaModel.mediaTypeName = null;
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.fileChange = true;
        this.clearFile();
    }

    public displayPreviewFileType() {
        if (this.files?.length) {
            let fileTypeDisplay = this.mediaMapperService.getFileExt(this.files[0].mediaFile.name);
            return fileTypeDisplay.toUpperCase();
        }
    }

    public displayPreviewFileSize() {
        if (this.files?.length) {
            let totalBytes = this.files[0].mediaFile.size;
            var sizeDisplay = this.mediaMapperService.formatBytes(totalBytes);
            return sizeDisplay;
        }
    }

    public onCommentTypeCodeChange(commentTypeCode: any) {
        this.formGroup.get('commentTypeCode').patchValue(commentTypeCode);
        if (!commentTypeCode) {
            this.files = [];
        }
    }

    public getMediaAttachment() {
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        if (!this.files?.length) {
            this.changeDetectorRef.detectChanges();
            return;
        }
        this.mediaModel.fileChange = this.fileChange;
        this.mediaModel.mediaFileBase64 = this.files[0].mediaFileBase64;
        this.mediaModel.mediaFile = this.files[0].mediaFile;
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0].mediaFilePreview);
        reader.onload = () => {
            this.mediaModel.mediaFilePreview = this.files[0].mediaFilePreview;
            this.mediaModel.mediaFilePreviewBase64 = reader.result;
        };
    }
    
    private clearData() {
        this.singleRecord = true;
        this.mediaModel = new MediaView();
        this.files = [];
        this.fileChange = false;
    }

    private fillModelInCase(mediaView: MediaView) {
        this.fillModelSingleRecord(mediaView);
    }

    private fillModelSingleRecord(mediaFileViews: MediaView) {
        this.singleRecord = true;
        this.mediaModel = mediaFileViews;
    }

    private getMediaFile(model: MediaView) {
        if (model.mediaFile) {
            let mediaFile = this.createMediaFileView(model.mediaFile);
            this.displayFilePreview(mediaFile, mediaFile.mediaFile.type);
        } else if (model.mediaContentId && !model.mediaFileBase64) {
            this.loadFileExistFinished = false;
            this.mediaService.getMediaFile(model.mediaContentId)
                .subscribe(
                    (response) => {
                        this.clearFile();
                        if (response.status == 200) {
                            var contenType = response.headers.get("Content-Type");
                            var fileName = this.mediaMapperService.getfileName(this.autService.getSelectedOrganisation(), model.mediaContentId, contenType)
                            var file = new File([response.body], fileName, { type: contenType });
                            let mediaFile = this.createMediaFileView(file);
                            this.displayFilePreview(mediaFile, contenType);
                        }
                        else {
                            this.fileNotFound = true;
                            this.refreshPage();
                        }
                    }
                );
        }
        else {
            if (!model.mediaFileBase64) {
                this.clearFile();
            }
        }
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxChartModule, DxDataGridModule } from "devextreme-angular";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductDashboardTransportCompareLast30DComponent } from "./product-dashboard-transport-compare-last30d/product-dashboard-transport-compare-last30d.component";
import { ProductDashboardTransportLastReceivedComponent } from "./product-dashboard-transport-lastreceived/product-dashboard-transport-lastreceived.component";
import { ProductDashboardTransportMiniChartsComponent } from "./product-dashboard-transport-mini-charts/product-dashboard-transport-mini-charts.component";
import { ProductDashboardTransportRevenueLast30DComponent } from "./product-dashboard-transport-revenue-last30d/product-dashboard-transport-revenue-last30d.component";
import { ProductDashboardTransportRevenueLast7DComponent } from "./product-dashboard-transport-revenue-last7d/product-dashboard-transport-revenue-last7d.component";
import { ProductDashboardTransportSoldLast30DComponent } from "./product-dashboard-transport-sold-last30d/product-dashboard-transport-sold-last30d.component";
import { ProductDashboardTransportSoldLast7DComponent } from "./product-dashboard-transport-sold-last7d/product-dashboard-transport-sold-last7d.component";
import { ProductDashboardTransportStatisticsComponent } from "./product-dashboard-transport-statistics/product-dashboard-transport-statistics.component";
import { ProductDashboardTransportSummaryComponent } from "./product-dashboard-transport-summary/product-dashboard-transport-summary.component";
import { ProductDashboardTransportComponent } from "./product-dashboard-transport.component";
import { DataGridRowNumberModule } from "../../../../core/pipes/datagrid-rownumber/datagrid-rownumber.module";

@NgModule({
    declarations: [
        ProductDashboardTransportComponent,
        ProductDashboardTransportCompareLast30DComponent,
        ProductDashboardTransportLastReceivedComponent,
        ProductDashboardTransportMiniChartsComponent,
        ProductDashboardTransportRevenueLast7DComponent,
        ProductDashboardTransportRevenueLast30DComponent,
        ProductDashboardTransportSoldLast7DComponent,
        ProductDashboardTransportSoldLast30DComponent,
        ProductDashboardTransportSummaryComponent,
        ProductDashboardTransportStatisticsComponent
    ],
    exports: [
        ProductDashboardTransportComponent
    ],
    imports: [
        CommonModule,
        DataGridRowNumberModule,
        DxChartModule,
        DxDataGridModule,
        FocusingModule,
        MatIconModule,
        NgbNavModule,
        PanelsModule,
        UiModule
    ]
})
export class ProductDashboardTransportModule { }
import { Injectable } from '@angular/core';

import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants'
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductAttributeCommandModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';

@Injectable()
export class OriginDestinationMapperService {

    constructor(private dateConverterService: DateConverterService) {
    }

    public originDestinationFormToModels(forms, models: ProductAttributeCommandModel[] = new Array()): ProductAttributeCommandModel[] {
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    switch (ctl.value.dimensionUnitCode) {
                        case OopsChoiceValueConstant.TEXT: {
                            this.attributeTextToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.TIME: {
                            this.attributeTimeToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.NUMBER: {
                            this.attributeNumberToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.PERCENTAGE: {
                            this.attributeNumberToModel(i, ctl.value, models);
                            break;
                        }
                        case OopsChoiceValueConstant.CHOICE: {
                            this.attributeChoiceToModels(i, ctl.value, models);
                            break;
                        }
                    }
                }
            }
        }
        return models;
    }

    private attributeTextToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeText = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeNumberToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeValue = value.choiceValue;
        model.displaySequence = index;
        models.push(model);
    }

    private attributeTimeToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeDateTime = this.dateConverterService.convertMinDateWithTime(value.choiceValue);
        model.displaySequence = index;
        models.push(model);
    }

    private attributeChoiceToModels(index: number, value, models: ProductAttributeCommandModel[]) {
        if (value.multipleChoiceFlag) {
            for (let choiceId of value.choiceValue) {
                models.push(this.attributeMultipleChoiceToModel(index, value, choiceId));
            }
        }
        else {
            this.attributeSingleChoiceToModel(index, value, models);
        }
    }

    private attributeSingleChoiceToModel(index: number, value, models: ProductAttributeCommandModel[]) {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = value.productAttributeId;
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = value.choiceValue;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        models.push(model);
    }

    private attributeMultipleChoiceToModel(index: number, value, choiceId: string): ProductAttributeCommandModel {
        let model = {} as ProductAttributeCommandModel;
        if (value.productAttributeId) {
            model.productAttributeId = this.getExistProductAttributeId(value.productAttributeChoiceId, choiceId);
        }
        model.attributeTypeCode = value.productAttributeTypeCode;
        model.attributeChoiceId = choiceId;
        var filterchoice = value.choiceData.filter(x => x.id == model.attributeChoiceId);
        if (filterchoice.length > 0) {
            model.attributeChoiceName = filterchoice[0].text;
        }
        model.displaySequence = index;
        return model;
    }

    private getExistProductAttributeId(productAttributeArrayDBId: Select2Data[], value): string {
        if (productAttributeArrayDBId) {
            var filter = productAttributeArrayDBId.filter(x => x.text == value);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
    }
}
import {
    ProductRestrictionOrganisationViewModel,
    ProductRestrictionProductNumberViewModel,
    ProductRestrictionProductViewModel,
    ProductRestrictionLocationViewModel,
    ProductRestrictionRouteViewModel,
    ProductRestrictionVehicleViewModel
} from '../../../../../core/models/product-model/product-base-model/product-restriction'
export class RestrictionView {
    public no: number;
    public type: string;
    public hierarchyKey: string;
    public productRestrictionProductView: ProductRestrictionProductViewModel;
    public productRestrictionProductNumberView: ProductRestrictionProductNumberViewModel;
    public productRestrictionRouteView: ProductRestrictionRouteViewModel;
    public productRestrictionLocationView: ProductRestrictionLocationViewModel;
    public productRestrictionOrganisationView: ProductRestrictionOrganisationViewModel;
    public productRestrictionVehicleView: ProductRestrictionVehicleViewModel;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SecurityFunctionsComponent } from './security-functions.component';
import { SecurityFunctionsTableComponent } from './functions-table/security-functions-table.component';
import { SecurityFunctionsContextMenuComponent } from './functions-table/context-menu/security-functions-context-menu.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { SecuritySearchModule } from '../security-search/security-search.module';
import { OopsCategoriesModule } from 'src/app/core/components/categories';

@NgModule({
    declarations: [
        SecurityFunctionsComponent,
        SecurityFunctionsContextMenuComponent,
        SecurityFunctionsTableComponent
    ],
    imports: [
        CommonModule,
        FreezingTabModule,
        MatIconModule,
        MatMenuModule,
        NgbNavModule,
        OopsCategoriesModule,
        RouterModule,
        SecuritySearchModule,
        UiModule
    ],
    exports: [
        SecurityFunctionsComponent
    ]
})
export class SecurityFunctionsModule { }

import { Component, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExchangeRateAmountService } from 'src/app/core/services/system-services';
import { MODULE_NAME, PAGE_TITLE } from 'src/app/modules/settings-management/settings-management-content/shared/title-constant';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ExchangeRateAmountListComponent } from './exchange-rate-amount-list/exchange-rate-amount-list.component';
import { ExchangeRateAmountView } from './exchange-rate-amount-list/exchange-rate-amount.view';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SettingsSecurityCodes } from 'src/app/modules/settings-management/settings-management-content/settings-categories/settings-categories';

@Component({
  selector: 'op-exchange-rate-amount-detail',
  templateUrl: './exchange-rate-amount-detail.component.html',
})
export class ExchangeRateAmountDetailComponent {
  private previousTitle: string = PAGE_TITLE.exchange_rate;
  private defaultTitle = "Exchange Rate";
  public title: string = this.defaultTitle;
  public actionBarHandler = new ActionBarHandlerModel(
    new SaveButtonModel(),
    new CancelButtonModel(),
    new RefreshButtonModel()
  );
  private _navigationSubscription: Subscription;
  private unsubscribe$ = new Subject();
  public selectedItem: ExchangeRateAmountView;
  userSecurityRef: SecurityGroupSecurityModel
  @ViewChild(ExchangeRateAmountListComponent) exchangeListAmountComponent: ExchangeRateAmountListComponent;
  constructor(private navigationService: NavigationService,
    private actionbarService: ActionbarService,
    private spinnerService: LoadingSpinnerService,
    private exchangeRateAmountService: ExchangeRateAmountService,
    private securityGroupService: SecurityGroupService) {
  }

  ngAfterViewInit(): void {
    this.actionbarService.updateState(this.actionBarHandler);
    this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
      this.toolbarClicked(actionId);
    });
    this.securityGroupService.userSecurityGroupSecurity.subscribe(userSecurity => {
      this.userSecurityRef = userSecurity.find(s => s.securityCode == SettingsSecurityCodes.get(MODULE_NAME.exchange_rate))
      this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurityRef.newFlag || this.userSecurityRef.editFlag)
      this.actionbarService.updateState(this.actionBarHandler)
    })
  }

  toolbarClicked(clickedButton: string) {
    switch (clickedButton) {
      case ACTION_STATUS.back:
        this.back();
        break;
      case ACTION_STATUS.save:
        this.save();
        break;
      case ACTION_STATUS.cancel:
        this.back();
        break;
      case ACTION_STATUS.refresh:
        this.refresh();
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private save() {
    let command = this.exchangeListAmountComponent.getValue();
    if (command?.length) {
      this.exchangeRateAmountService.add(command).subscribe(
        (response) => {
          this.spinnerService.saveComplete();
          this.exchangeListAmountComponent.getExchangeRateAmount();
        },
        () => {
          this.spinnerService.hide();
        }
      )
    }
  }

  private back() {
    this.navigationService.navigate('/main/settings/exchange-rate', this.previousTitle);
  }

  private refresh() {
    this.exchangeListAmountComponent.onCancel();
  }

  public onSelectedItem(item: ExchangeRateAmountView) {
    this.selectedItem = item;
  }

}
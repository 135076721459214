import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CashbookComponent } from './cashbook.component';
import { CashbookTabsComponent } from './cashbook-content/cashbook-tabs/cashbook-tabs.component';
import { CashbookDetailContentComponent } from './cashbook-content/cashbook-detail-content/cashbook-detail-content.component';
import { CashbookDocumentComponent } from './cashbook-content/cashbook-document/cashbook-document.component';
import { FinanceHomeComponent } from './cashbook-content/finance-home/finance-home.component';
import { CounterSalesContentComponent } from './counter-sales-content/counter-sales-content.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: '',
    component: CashbookComponent,
    children: [
      {
        path: 'home',
        component: FinanceHomeComponent
      },
      {
        path: 'cashbook',
        component: CashbookTabsComponent
      },
      {
        path: 'detail',
        component: CashbookDetailContentComponent
      },
      {
        path: 'document',
        component: CashbookDocumentComponent
      },
      {
        path: 'counter-salse',
        component: CounterSalesContentComponent
      },
      {
        path: 'credit-account',
        loadChildren: () =>
          import('./credit-account-content/credit-account-content.module').then(
            (m) => m.CreditAccountContentModule
          )
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment-search/payment-search.module').then(
            (m) => m.PaymentSearchModule
          )
      }
    ],
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CashbookRoutingModule { }

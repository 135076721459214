import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu"
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap"
import { DxTemplateModule, DxDataGridModule, DxBulletModule } from "devextreme-angular"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { FormOfPaymentSelectionModule } from "src/app/core/components/form-of-payment-selection"
import { FormOfPaymentTypeSelectionModule } from "src/app/core/components/form-of-payment-type-selection"
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module"
import { AlertBarModule } from "src/app/shared/layout/alertbar"
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner"
import { PanelsModule } from "src/app/shared/panels/panels.module"
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module"
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module"
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module"
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module"
import { UiModule } from "src/app/shared/ui/ui.module"
import { InvoiceIndividualDetailMapperService } from "../../order/shared/components/payment-document-invoice/individual-document-invoice/invoice-individual-detail/invoice-individual-detail-mapper.service"
import {
    PaymentCreditAccountTabsComponent,
    PaymentCreditAccountPayComponent,
    PaymentCreditInvoiceTabsComponent,
    PaymentCreditInvoicePayComponent,
    PaymentInvoiceTabsComponent,
    PaymentInvoicePayComponent,
    PaymentSearchActionsComponent,
    PaymentSearchCriteriaComponent,
    PaymentSearchResultsComponent,
    PaymentSearchTabsComponent,
    PaymentSearchComponent,
    PaymentSearchRoutingModule
}
    from "./";
import { StatusBadgeModule } from "src/app/shared/ui/forms/inputs/status-badge/status-badge.module"
import { CalendarIconModule } from "src/app/core/components/calendar-icon"

const InternalComponents = [
    PaymentSearchTabsComponent,
    PaymentSearchComponent,
    PaymentSearchCriteriaComponent,
    PaymentSearchResultsComponent,
    PaymentSearchActionsComponent,
    PaymentInvoiceTabsComponent,
    PaymentInvoicePayComponent,
    PaymentCreditInvoiceTabsComponent,
    PaymentCreditInvoicePayComponent,
    PaymentCreditAccountTabsComponent,
    PaymentCreditAccountPayComponent,
]

const LibModules = [
    Select2Module,
    MatIconModule,
    NgbNavModule,
    DxTemplateModule,
    DxDataGridModule,
    DxBulletModule,
    BsDropdownModule,
    MatMenuModule,
    MatDividerModule,
]

const OopsModules = [
    PanelsModule,
    FocusingModule,
    FormOfPaymentSelectionModule,
    FormOfPaymentTypeSelectionModule,
    DaterangepickerModule,
    ItemsFoundModule,
    LoadingSpinnerModule,
    FreezingTabModule,
    AlertBarModule,
    StatusBadgeModule,
    CalendarIconModule,
]
@NgModule({
    declarations: [
        InternalComponents,
    ],
    imports: [
        PaymentSearchRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        LibModules,
        OopsModules,
        UiModule,
    ],
    providers: [
        InvoiceIndividualDetailMapperService
    ]
})
export class PaymentSearchModule { }

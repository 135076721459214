import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { SpecialServiceViewModel } from 'src/app/core/models/product-model/specialservice-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-special-service-filter-name',
    templateUrl: './special-service-filter-name.component.html'
})
export class SpecialServiceFilterNameComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorNameRequired = 'Filter Name is required.';
    @Input() advanceSearchMode: boolean;
    @Input() specialServiceView: SpecialServiceViewModel;
    @Input() userSecurity: SecurityGroupSecurityModel;

    @Output() formStatus = new EventEmitter<boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing = false;
    isOwnerFilter = false;

    constructor(formBuilder: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private actionbarService: ActionbarService) { 
        super(formBuilder);
    }

    public initForm() {
        this.formGroup = this.convertSpecialServiceViewToFormGroup(new SpecialServiceViewModel());
        this.validateFormStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['specialServiceView']) {
            this.formGroup = this.convertSpecialServiceViewToFormGroup(this.specialServiceView);
            this.validateFormStatus();
        }
        this.updateActionBarHandlerState();
    }

    private updateActionBarHandlerState() {
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (this.advanceSearchMode) {
            actionBarHandler.get(ACTION_STATUS.delete)?.enable(this.canDelete());
            actionBarHandler.get(ACTION_STATUS.copy)?.enable(this.allowCopy());
            let saveBtn = actionBarHandler.get(ACTION_STATUS.save);
            saveBtn?.enable();
            saveBtn?.getDropdownBtn(ACTION_STATUS.save)?.enable(this.canSaveToSameOrNewId());
            saveBtn?.getDropdownBtn(ACTION_STATUS.saveAs)?.enable(this.alwaysSaveToNewId());
        } else {
            actionBarHandler.get(ACTION_STATUS.delete)?.disable();
            actionBarHandler.get(ACTION_STATUS.copy)?.disable();
            actionBarHandler.get(ACTION_STATUS.save)?.disable();
        }
        this.actionbarService.updateState(actionBarHandler);
    }

    private convertSpecialServiceViewToFormGroup(view: SpecialServiceViewModel): UntypedFormGroup {
        this.isOwnerFilter = view?.isOwnerFilter;
        return new UntypedFormGroup({
            productId: new UntypedFormControl(view?.productId),
            productName: new UntypedFormControl(view?.productName, Validators.required),
            publicFilter: new UntypedFormControl(view?.isOwnerFilter != null ? !view?.isOwnerFilter : null)
        });
    }

    private canDelete(): boolean {
        return !!(this.isOwnerFilter) && this.userSecurity?.deleteFlag;
    }

    private allowCopy(): boolean {
        return !!(this.specialServiceView?.productId) && this.userSecurity?.copyFlag;
    }

    private canSaveToSameOrNewId(): boolean {
        return (!!(this.specialServiceView?.productId) && this.isOwnerFilter && this.userSecurity?.editFlag) || (!this.specialServiceView?.productId && this.userSecurity?.newFlag);
    }

    private alwaysSaveToNewId(): boolean {
        return !!(this.specialServiceView?.productId) && this.userSecurity?.newFlag;
    }

    private convertFormGroupToProductModel(productId: string, formGroup: UntypedFormGroup): SpecialServiceViewModel {
        let model = {} as SpecialServiceViewModel;
        model.productId = productId ?? null;
        model.productName = formGroup.controls["productName"]?.value;
        model.isOwnerFilter = !formGroup.controls["publicFilter"]?.value;
        return model;
    }

    public fillCopyToForm(view: SpecialServiceViewModel) {
        this.formGroup = this.convertSpecialServiceViewToFormGroup(view);
        this.updateActionBarHandlerState();
    }

    public getSaveModel(saveAs: boolean = false): SpecialServiceViewModel {
        let model = this.convertFormGroupToProductModel(saveAs ? null : this.formGroup.controls['productId']?.value, this.formGroup);
        return model;
    }

    public validateForm(): boolean {
        this.startProcessing();
        this.changeDetectorRef.detectChanges();
        return this.validForm();
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productName"].valid) {
            return this.errorNameRequired;
        }
    }

    
    private validateFormStatus() {
        this.formGroup.statusChanges
        .subscribe(val => this.onFormStatusChange());
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    public productNameValidate() : boolean {
       return this.processing &&  this.formGroup.controls['productName'].errors && this.formGroup.controls['productName'].errors.required;
    }
}

import { Select2Themes } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service"

export const AttributeChoiceMultipleOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Choices>',
    theme: Select2Themes.MULTIPLE_VALUES_SELECTOR_THEME,
    width: "auto",
    multiple: true
}

export const AttributeChoiceSingleOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: '<Choices>',
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}
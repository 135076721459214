import { Injectable } from '@angular/core';
import {
    InventoryDimensionReferenceModel,
    SalesBucketReferenceModel,
    ServiceCategoryReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Injectable()
export class ServiceCategoriesAndSalesBucketConverter {
    public toServiceCategoryReferenceDataList(models: ServiceCategoryReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.serviceCategoryCode, model.serviceCategoryName))
    }

    public toSalesBucketReferenceDataList(models: SalesBucketReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.salesBucketCode, model.salesBucketName))
    }

    public toInventoryDimensionReferenceDataList(models: InventoryDimensionReferenceModel[]): Select2Data[] {
        return models.map(model => new Select2Data(model.inventoryDimensionCode, model.inventoryDimensionName))
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { SeasonReferenceModel } from '../../models/reference-model/reference-general-model/season-reference.model';

@Injectable({
    providedIn: 'root'
})
export class SeasonReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/seasonreferences";

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<SeasonReferenceModel[]> {
        return this.httpGet<SeasonReferenceModel[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }
}
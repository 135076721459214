import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelsModule } from '../../../shared/panels/panels.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule, MAT_LEGACY_CHIPS_DEFAULT_OPTIONS as MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { EmailComponent } from './email.component';

@NgModule({
    declarations: [
        EmailComponent
    ],
    imports: [
        CommonModule,
        PanelsModule,
        FormsModule,
        NgbModule,
        CollapseModule,
        FocusingModule,
        ButtonsModule,
        MatIconModule,
        MatChipsModule,
        MatInputModule,
        LoadingSpinnerModule,
        Select2Module,
        MatAutocompleteModule,
        ReactiveFormsModule
    ],
    exports: [
        EmailComponent
    ],
    providers: [
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        }
    ]
})
export class EmailModule { }

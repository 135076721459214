import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialServiceSearchComponent } from './special-service-search.component';
import { SpecialServiceSearchConditionComponent } from './special-service-search-condition/special-service-search-condition.component';
import { SpecialServiceSearchTableComponent } from './special-service-search-table/special-service-search-table.component';
import { DxCheckBoxModule, DxDataGridModule, DxDropDownBoxModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SpecialServiceFilterNameComponent } from './special-service-filter-name/special-service-filter-name.component';
import { SpecialServiceAttributeModule } from '../special-service-attribute/special-service-attribute.module';

@NgModule({
    declarations: [
        SpecialServiceSearchComponent,
        SpecialServiceSearchConditionComponent,
        SpecialServiceSearchTableComponent,
        SpecialServiceFilterNameComponent
    ],
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        SpecialServiceAttributeModule,
        StatusColorDropdownModule,
        UiModule
    ],
    exports: [
        SpecialServiceSearchComponent
    ]
})
export class SpecialServiceSearchModule { }

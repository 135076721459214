import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { MediaFileView } from "src/app/core/components/media/shared/media-file.view";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { MediaView } from "src/app/core/components/media/shared/media.view";
import { InsightPassengerCommandModel } from "src/app/core/models/individual-model/insight-passenger-command.model";
import { InsightPassengerModel } from "src/app/core/models/individual-model/insight-passenger.model";
import { GenderReferenceModel, IndividualAgeGroupReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { select2SearchAgeGroup, select2SearchGender, select2SearchStatus, select2SearchUsageType } from "../../insight-search/shared/select2-configuration";
import { InsightGeneralDetailFormOption } from "../shared/form-config";
import Swal from "sweetalert2";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { MediaFilePreviewService } from "src/app/core/services/media-services/media-file-preview-service";
import { MediaTypeFileTypeModel, MediaTypeReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { BehaviorSubject, Subject } from "rxjs";
import { MediaTypeFileTypeService, MediaTypeReferenceService } from "src/app/core/services/reference-service/reference-media-service";
import { MediaCommandModel, MediaLibraryCommandModel, MediaViewModel } from "src/app/core/models/media-model";
import { MediaService } from "src/app/core/services/media-services";
import { MediaFileAssociationCommandModel } from "src/app/core/models/media-model/media-fileassociation";
import { MediaContentCommandModel } from "src/app/core/models/media-model/media-content";
import { AuthService } from "src/app/core/authentication/auth.service";
import { AGE_GROUP_OPARATOR } from "../shared/age-group-compare-operator-constant";
import { takeUntil } from "rxjs/operators";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { InsightPassengerAgeGroupModel } from "src/app/core/models/individual-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { individualBirthdayCalendarOption } from "src/app/modules/order/shared";

declare var moment: any;

@Component({
    selector: 'op-insight-detail-general',
    templateUrl: './insight-detail-general.component.html',
    providers: [MediaMapperService],
    styleUrls: ['./insight-detail-general.component.scss']
})
export class InsightGeneralDetailComponent extends OopsComponentFormBase implements OnChanges, OnDestroy {
    readonly DROPZONE_BINDING_DELAY_TIME = 200;
    private readonly ENTITY_NAME: string = "Individual";
    public readonly ERROR_STATUS_CANNOT_CHANGE: string = 'Status cannot be changed due to email address is not available to use.';

    @ViewChild('swal')
    public readonly swal!: SwalComponent;

    @Input() individualId: string;
    @Input() insightPassenger: InsightPassengerModel;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() genderReference: GenderReferenceModel[];
    @Input() usageTypeReference: UsageTypeReferenceModel[];
    @Input() individualAgeGroupReferences: IndividualAgeGroupReferenceModel[];
    @Input() mediaModel = new MediaView();
    @Input() profilePicture: MediaViewModel[];
    @Input() actionSecurity: SecurityGroupSecurityModel;

    @Output() focusEmit = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public statusOption: any;
    public genderOption: any;
    public usageTypeOption: any;
    public ageGroupOption: any;
    public loadFileExistFinished: boolean = false;
    public canDownloadFile: boolean = false;
    public canPreviewFile: boolean = false;
    public file: MediaFileView = null;
    public files: MediaFileView[] = new Array();
    public viewerType: string;
    public urlPreviewFile: any;
    public maximumFileSize: boolean = false;
    public fileTypeInvalid: boolean = false;
    public fileNotFound: boolean = false;
    public fileChange: boolean = false;
    private mediaTypeFileType: MediaTypeFileTypeModel[] = null;
    private mediaTypeReference: MediaTypeReferenceModel[] = null;
    public mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaFile: MediaView;
    public ageGroupDisabled: boolean = false;
    public focusing: boolean = false;
    public collapsed: boolean = false;
    private unsubscribe$ = new Subject();
    public oldAgeGroup: InsightPassengerAgeGroupModel[];
    public calendarOption: any;
    public changeStatusInvalid: boolean = false;

    private individualAgeGroupReferences$ = new BehaviorSubject<IndividualAgeGroupReferenceModel[]>(null);

    constructor(formBuilder: UntypedFormBuilder,
        private dateConverterService: DateConverterService,
        private mediaMapperService: MediaMapperService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaService: MediaService,
        private autService: AuthService,
        private spinnerService: LoadingSpinnerService,
        private changeDetectionRef: ChangeDetectorRef) {
        super(formBuilder);
        this.statusOption = select2SearchStatus;
        this.genderOption = select2SearchGender;
        this.usageTypeOption = select2SearchUsageType;
        this.ageGroupOption = select2SearchAgeGroup;
    }
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public initForm() {
        this.getMediaTypeFileType();
        this.getMediaTypeReference();
        this.setDatetimePicker();
        this.formSetup();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insightPassenger']) {
            this.formSetup();
        }
        if (changes['profilePicture']) {
            this.mediaSetup();
        }
        if (changes['individualAgeGroupReferences'] && this.individualAgeGroupReferences) {
            this.individualAgeGroupReferences$.next(this.individualAgeGroupReferences)
        }
    }

    formSetup() {
        this.formGroup = new UntypedFormGroup(InsightGeneralDetailFormOption);
        this.formGroup.controls["dateOfBirth"].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(
            value => {
                if (value && value != "") {
                    this.setAgeGroupFormByDateOfBirth(value);
                }
                else {
                    this.formGroup.controls['ageGroupCode'].enable();
                    this.formGroup.controls['ageGroupCode'].setValue(null);
                }
            }
        )
        if (!this.insightPassenger) {
            this.formGroup.reset();
            this.formGroup.patchValue({
                usageTypeCode: 'DATA',
                status: 'A'
            })
        } else {
            this.fillModelToForm(this.insightPassenger);
        }
        this.changeStatusInvalid = false;
    }

    private setAgeGroupFormByDateOfBirth(value: string) {
        if (!this.individualAgeGroupReferences$?.value?.length){
            this.individualAgeGroupReferences$.subscribe(
                () => {
                    let ageGroup = this.getAgeGroupByAge(this.dateConverterService
                        .calculateAge(value, new Date()))
                    this.setAgeGroup(ageGroup);
                }
            )
        }
        else {
            let ageGroup = this.getAgeGroupByAge(this.dateConverterService
                .calculateAge(value, new Date()))
            this.setAgeGroup(ageGroup);
        }
    }

    private setDatetimePicker() {
        this.calendarOption = individualBirthdayCalendarOption;
        this.calendarOption.minDate =  new Date("1900-01-01");
        this.calendarOption.maxDate =  new Date();
    }

    private getAgeGroupByAge(age: number, ageGroupCodes: InsightPassengerAgeGroupModel[] = []): string {
        if (age < 0 || age == null) {
            if (!ageGroupCodes[0]) {
                return null;
            }

            if (ageGroupCodes[0].individualAgeGroupCode != '' && ageGroupCodes[0].individualAgeGroupCode != null) {
                return ageGroupCodes[0].individualAgeGroupCode;
            }
        }        

        if (!this.individualAgeGroupReferences) {
            return;
        }
        for (let ageGroup of this.individualAgeGroupReferences) {
            var ageGroupCode = this.checkAgeGroupCondition(ageGroup, age);
            if (ageGroupCode != "") {
                return ageGroupCode;
            }
        }

        return null;
    }

    private setAgeGroup(ageGroupCode: string) {
        this.formGroup.controls['ageGroupCode'].setValue(ageGroupCode);

        if (ageGroupCode) {
            this.formGroup.controls['ageGroupCode'].disable();
        }

        this.changeDetectionRef.detectChanges();
    }

    private checkAgeGroupCondition(ageGroup: IndividualAgeGroupReferenceModel, age: number): string {
        if (age == null) {
            return "";
        }
        let operatorKey = Object.keys(AGE_GROUP_OPARATOR).find(key => AGE_GROUP_OPARATOR[key].symbol == ageGroup.conditionCode)
        let operator = AGE_GROUP_OPARATOR[operatorKey];

        if (operator.compare(age, ageGroup.ageYearValue)) {
            return ageGroup.individualAgeGroupCode;
        }
        return "";
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType = responses;
                }
            )
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference = responses;
                }
            )
    }

    public getMediaValues(): MediaLibraryCommandModel {
        this.saveFile();
        return this.fillMediaToModel(this.insightPassenger?.individualId);
    }

    private saveFile() {
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        if (!this.file) {
            this.mediaFile = null;
            return;
        }
        this.mediaModel.fileChange = this.fileChange;
        this.mediaModel.mediaFileBase64 = this.file.mediaFileBase64;
        this.mediaModel.mediaFile = this.file.mediaFile;
        const reader = new FileReader();
        reader.readAsDataURL(this.file.mediaFilePreview);
        reader.onload = () => {
            this.mediaModel.mediaFilePreview = this.file.mediaFilePreview;
            this.mediaModel.mediaFilePreviewBase64 = reader.result;
            this.saveMediaToDB();
        };
        this.mediaFile = this.mediaModel;
    }

    private saveMediaToDB() {
        var mediaSave = this.mediaMapperService.mediaViewToMediaLibraryCommand(this.mediaModel);
        this.mediaService.save(mediaSave)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (mediaReturn: MediaViewModel) => {
                    this.mediaModel = this.mediaMapperService.mediaViewModelToMediaView(mediaReturn, this.mediaModel, null);
                }
            )
    }

    private fillMediaToModel(individualId): MediaLibraryCommandModel {
        if (!this.mediaFile) {
            return;
        }

        let mediaLibraryCommandModel = new MediaLibraryCommandModel();
        let mediaCommands: MediaCommandModel[] = new Array();
        let mediaFileAssociationCommands: MediaFileAssociationCommandModel[] = new Array();
        let mediaCommand = new MediaCommandModel();
        if (this.mediaFile.mediaId) {
            mediaCommand.mediaId = this.mediaFile.mediaId;
        }
        mediaCommand.mediaTypeCode = this.mediaFile.mediaTypeCode;
        mediaCommand.mediaContents = this.mediaToMediaContentModels(this.mediaFile);
        mediaCommand.mediaFileAssociation = this.mediaToMediaFileAssociationModel(this.mediaFile, this.ENTITY_NAME, individualId);
        mediaCommands.push(mediaCommand);
        mediaFileAssociationCommands.push(mediaCommand.mediaFileAssociation);
        mediaLibraryCommandModel.mediaCommand = mediaCommands;
        mediaLibraryCommandModel.mediaFileAssociationCommand = mediaFileAssociationCommands;
        return mediaLibraryCommandModel;
    }

    private mediaToMediaContentModels(media: MediaView): MediaContentCommandModel[] {
        let models: MediaContentCommandModel[] = new Array();
        if (media) {
            let model = new MediaContentCommandModel();
            if (media.mediaContentId) {
                model.mediaContentId = media.mediaContentId;
            }
            model.mediaFileBase64 = media.mediaFileBase64;
            models.push(model);
        }
        return models;
    }

    private mediaToMediaFileAssociationModel(media: MediaView, entityName: string, associationId: string): MediaFileAssociationCommandModel {
        let model = new MediaFileAssociationCommandModel();
        if (media) {
            if (media.mediaFileAssociationId) {
                model.mediaFileAssociationId = media.mediaFileAssociationId;
            }
            model.entityName = entityName;
            if (associationId) {
                model.associationId = associationId;
            }
            model.mediaId = media.mediaId;
        }
        return model;
    }

    public getValue(): InsightPassengerCommandModel {
        this.startProcessing();
        if (!this.validForm()) {
            return;
        }
        this.completeProcessing();
        return this.fillFormToModel();
    }

    private fillFormToModel(): InsightPassengerCommandModel {
        let formValue = this.formGroup.getRawValue();
        let editCommand: InsightPassengerCommandModel = {
            individualId: this.individualId,
            genderCode: formValue.gender,
            dateOfBirth: formValue.dateOfBirth == "" ? null : formValue.dateOfBirth,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            filterUserAccountId: null,
            isOwnerFilter: null,
            usageTypeCode: formValue.usageTypeCode,
            ageGroup: this.setAgeGroupValueFollowDateOfBirth(formValue),
            statusCode: formValue.status,
            insightPassengerAgeGroups: this.setAgeGroupCommands(formValue),
            insightPassengerNames: null,
            insightPassengerAddresses: null,
            insightPassengerCommunicationPhones: null,
            insightPassengerCommunicationOthers: null,
            insightPassengerDocuments: null,
            insightPassengerNationalities: null,
            insightPassengerLanguages: null,
            insightPassengerComments: null,
            insightPassengerMemberships: null,
            insightPassengerAttributes: null,
            insightPassengerCivilStatuses: null,
            insightPassengerInterests: null,
            insightPassengerPositions: null,
            insightPassengerPreferences: null,
            insightPassengerProfessions: null,
            insightPassengerSocials: null,
            medias: null,
        }

        return editCommand;
    }

    private setAgeGroupValueFollowDateOfBirth(formValue): string {
        if (formValue.dateOfBirth && formValue.dateOfBirth != "") {
            return null;
        }

        return formValue.ageGroupCode;
    }

    private setAgeGroupCommands(formValue): InsightPassengerAgeGroupModel[] {
        let ageGroups = new Array();
        let ageGroupCode = formValue.ageGroupCode;

        let ageGroup: InsightPassengerAgeGroupModel = {
            individualAgeGroupCode: null,
            startDate: null,
            endDate: null,
            individualAgeGroupId: null,
            individualId: null
        }

        if (formValue.dateOfBirth && formValue.dateOfBirth != "") {
            if (this.oldAgeGroup && this.oldAgeGroup[0]) {
                ageGroup.individualAgeGroupCode = this.oldAgeGroup[0].individualAgeGroupCode;
                ageGroups.push(ageGroup);
                return ageGroups;
            }
            ageGroupCode = null;
        } else {
            if (ageGroupCode == null && this.oldAgeGroup?.length) {
                ageGroup.individualAgeGroupCode = this.oldAgeGroup[0].individualAgeGroupCode;
                ageGroups.push(ageGroup);
                return ageGroups;
            }
        }

        ageGroup.individualAgeGroupCode = ageGroupCode;
        ageGroups.push(ageGroup)
        return ageGroups;
    }

    private fillModelToForm(model: InsightPassengerModel) {
        let dateOfBirth = this.dateConverterService.toDateFormatOrNull(model.dateOfBirth);
        let ageGroup = model.insightPassengerAgeGroups;
        this.oldAgeGroup = ageGroup;
        this.formGroup.patchValue({
            gender: model.genderCode,
            dateOfBirth: dateOfBirth,
            usageTypeCode: model.usageTypeCode,
            ageGroupCode: model.ageGroup ?? this.getAgeGroupByAge(this.dateConverterService.calculateAge(dateOfBirth, new Date()), ageGroup),
            revenueLast12: null,
            ordersLast12: null,
            status: model.statusCode ?? 'A',
            commitByName: model.commitByName,
            commitDateTime: this.dateConverterService.convertDate(moment(model.commitDateTime))
        })
    }

    public valueChange(value, name) {
        this.formGroup.controls[name].setValue(value);
    }

    public loadFileFinished(): boolean {
        if (this.mediaModel.mediaContentId && !this.mediaModel.mediaFileBase64) {
            return this.loadFileExistFinished;
        }
        return true;
    }

    public downloadFile() {
        if (this.file) {
            const blob = this.file.mediaFile;
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.file.mediaFile.name;
            anchor.href = url;
            anchor.click();
        }
    }

    public previewFile() {
        if (this.file) {
            if (this.mediaMapperService.isImage(this.file.mediaFile.type)) {
                this.previewImageFile();
            }
            else {
                this.previewOtherFile();
            }
        }
    }

    private previewImageFile() {
        const blob = this.file.mediaFile;
        const url = window.URL.createObjectURL(blob);
        Swal.fire({
            html: '<div class="col-12"><img src="' + url + '" style="max-width:700px;" ></div>',
            width: '800px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    private previewOtherFile() {
        const blob = this.file.mediaFile;
        if (this.mediaMapperService.isVideo(blob.type) ||
            this.mediaMapperService.isPdf(blob.type)) {
            this.viewerType = "url";
            this.urlPreviewFile = window.URL.createObjectURL(blob);
        }
        else if (this.mediaMapperService.isXLS(blob.type)) {
            this.viewerType = "google";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        else {
            this.viewerType = "office";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        this.swal.fire();
    }

    onSelect(event) {
        this.singleFileProcess(event.addedFiles[0]);
    }

    onRemove() {
        this.fileChange = true;
        this.clearFile();
    }

    private singleFileProcess(addedFile: File) {
        this.clearFile();
        if (!this.validateFile(addedFile)) {
            return;
        }
        this.mediaModel.fileChange = true;
        this.fileChange = true;
        let mediaFile = this.createMediaFileView(addedFile);
        this.displayFilePreview(mediaFile, addedFile.type);
    }

    private displayFilePreview(mediaFile: MediaFileView, contentType: string) {
        this.canDownloadFile = true;
        if (this.mediaMapperService.isImage(contentType)) {
            this.canPreviewFile = true;
            mediaFile.mediaFilePreview = mediaFile.mediaFile;
            this.addFileToDropZone(mediaFile);
            this.refreshPage();
        }
        else if (this.mediaMapperService.isVideo(contentType) || this.mediaMapperService.isPdf(contentType)) {
            this.canPreviewFile = true;
            this.getPicFilePreview(mediaFile, contentType);
        }
        else if (this.mediaMapperService.isDoc(contentType)) {
            this.canPreviewFile = (this.checkCanPreviewFile() && !this.mediaModel.fileChange);
            this.getPicFilePreview(mediaFile, contentType);
        } else {
            this.getPicFilePreview(mediaFile, contentType);
        }
    }

    private getPicFilePreview(mediaFile: MediaFileView, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.spinnerService.show();
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFile.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.addFileToDropZone(mediaFile);
                        this.refreshPage();
                    }
                    this.spinnerService.hide();
                }
            );
    }

    private addFileToDropZone(mediaFile: MediaFileView) {
        this.file = mediaFile;
        this.files.push(this.file);
        this.changeDetectionRef.detectChanges();
    }

    private checkCanPreviewFile(): boolean {
        if (this.fileChange) {
            return false;
        }
        return true;
    }

    private refreshPage() {
        setTimeout(() => {
            try {
                this.changeDetectionRef.detectChanges();
            }
            catch (e) {
                e = null;
            }
        }, this.DROPZONE_BINDING_DELAY_TIME);
    }

    private createMediaFileView(fileData: File): MediaFileView {
        let mediaFileView = new MediaFileView();
        mediaFileView.mediaFile = fileData;
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            mediaFileView.mediaFileBase64 = reader.result;
        };
        return mediaFileView;
    }

    private clearFile() {
        this.file = null;
        this.files = [];
        this.loadFileExistFinished = true;
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        this.canPreviewFile = false;
        this.canDownloadFile = false;
        this.fileNotFound = false;
    }

    private validateFile(f: File): Boolean {
        if (!this.validateFileType(f)) {
            this.fileTypeInvalid = true;
            return false;
        } else if (!this.validateFileSize(f)) {
            this.maximumFileSize = true;
            return false;
        }
        this.fileTypeInvalid = false;
        this.maximumFileSize = false;
        return true;
    }

    private validateFileType(f: File): Boolean {
        if (!this.mediaTypeFileType) {
            return false;
        }
        var fileExt = this.mediaMapperService.getFileExt(f.name);
        var filterFileTypeValid = this.mediaTypeFileType.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (!filterFileTypeValid?.length) {
            return false;
        }

        return this.checkFileTypeValid(filterFileTypeValid);
    }

    private validateFileSize(f: File): Boolean {
        if (this.mediaTypeReference) {
            var filter = this.mediaTypeReference.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);
            if (filter.length > 0 && filter[0].maximumSize && f.size <= filter[0].maximumSize) {
                return true;
            }
        }

        return false;
    }

    private checkFileTypeValid(fileTypeValid: MediaTypeFileTypeModel[]): boolean {
        if (!this.mediaModel?.mediaTypeCode) {
            this.assignFileTypeToModel(fileTypeValid);
            return true;
        }

        var filter = fileTypeValid.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);
        return !!(filter?.length);
    }

    private assignFileTypeToModel(fileTypeValid: MediaTypeFileTypeModel[]) {
        if (!this.mediaTypeReference) {
            return;
        }
        var mediaTypeCode = fileTypeValid[0].mediaTypeCode;
        var filter = this.mediaTypeReference.filter(x => x.mediaTypeCode == mediaTypeCode);
        if (filter.length > 0) {
            this.mediaModel.mediaUseCode = filter[0].mediaUseCode;
            this.filterMediaType(this.mediaModel.mediaUseCode);
            this.mediaModel.mediaTypeCode = mediaTypeCode;
        }
    }

    private filterMediaType(mediaUseCode: string) {
        if (this.mediaTypeReference) {
            this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
            if (mediaUseCode) {
                this.filterMediaTypeData(mediaUseCode);
            }
            else {
                this.clearMediaType();
            }
        }
    }

    private filterMediaTypeData(mediaUseCode: string) {
        var filter = this.mediaTypeReference.filter(x => x.mediaUseCode == mediaUseCode);
        this.mediaTypeReferenceFilter$.next(filter);
        this.assignToMediaTypeCode(filter)
    }

    private clearMediaType() {
        this.mediaModel.mediaTypeCode = null;
        this.mediaModel.mediaTypeName = null;
    }

    private assignToMediaTypeCode(filter: MediaTypeReferenceModel[]) {
        if (filter.length != 0) {
            this.mediaModel.mediaTypeCode = filter[0].mediaTypeCode;
        }
        else {
            this.clearMediaType();
        }
    }

    public displayPreviewFileType() {
        if (this.file) {
            let fileTypeDisplay = this.mediaMapperService.getFileExt(this.file.mediaFile.name);
            return fileTypeDisplay.toUpperCase();
        }
    }

    public displayPreviewFileSize() {
        if (this.file) {
            let totalBytes = this.file.mediaFile.size;
            var sizeDisplay = this.mediaMapperService.formatBytes(totalBytes);
            return sizeDisplay;
        }
    }

    private mediaSetup() {
        if (this.profilePicture.length > 0) {
            this.fillModelToMedia(this.profilePicture);
            this.getMediaFile();
        }else
        {
            this.clearFile();
        }
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        mediaViews[0].mediaContentId = medias[0].mediaContents[0].mediaContentId;
        this.mediaModel = mediaViews[0];
        this.mediaFile = mediaViews[0];
        this.changeDetectionRef.detectChanges();
    }

    private getMediaFile() {
        if (this.mediaModel.mediaFile) {
            let mediaFile = this.createMediaFileView(this.mediaModel.mediaFile);
            this.displayFilePreview(mediaFile, mediaFile.mediaFile.type);
        } else if (this.mediaModel.mediaContentId && !this.mediaModel.mediaFileBase64) {
            this.loadFileExistFinished = false;
            this.spinnerService.show();
            this.mediaService.getMediaFile(this.mediaModel.mediaContentId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (response) => {
                        this.clearFile();
                        if (response.status == 200) {
                            var contenType = response.headers.get("Content-Type");
                            var fileName = this.mediaMapperService.getfileName(this.autService.getSelectedOrganisation(), this.mediaModel.mediaContentId, contenType)
                            var file = new File([response.body], fileName, { type: contenType });
                            let mediaFile = this.createMediaFileView(file);
                            this.displayFilePreview(mediaFile, contenType);
                        }
                        else {
                            this.fileNotFound = true;
                            this.refreshPage();
                        }
                        this.spinnerService.hide();
                    }
                );
        }
        else {
            if (!this.mediaModel.mediaFileBase64) {
                this.clearFile();
            }
        }
    }

    
  public displayChangeStatusInvalid(invalid: boolean) {
    this.changeStatusInvalid = invalid;
    this.changeDetectionRef.detectChanges();
  }
}
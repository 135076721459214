import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HashTagReferenceModel } from '../../models/reference-model/reference-general-model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HashTagReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/hashtagreferences"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }
    
    public getAll(): Observable<HashTagReferenceModel[]> {
        return this.httpGet<HashTagReferenceModel[]>(this.REFERENCE_PATH , null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public GetOrganisationHashtag(): Observable<HashTagReferenceModel[]> {
        return this.httpGet<HashTagReferenceModel[]>(`${this.REFERENCE_PATH}/organisation` , null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { ConditionReferenceModel, DomainAttributeModel, IndividualAgeGroupReferenceModel, IndividualSocialTypeReferenceModel, UnitReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import {
    DomainAttributeService,
    IndividualAgeGroupTypeReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService,
} from 'src/app/core/services/airline-services';
import { CountryReferenceService } from 'src/app/core/services/airline-services/country-reference.service';
import { CurrencyReferenceService } from 'src/app/core/services/system-services/currency-reference.service';
import { OrganisationService } from 'src/app/core/services/organisation-services/organisation.service';
import { PricingService } from 'src/app/core/services/pricing-services';
import {
    ConditionReferenceService,
    OrganisationRoleReferenceService,
    OrganisationTypeReferenceService,
    UnitReferenceService,
} from 'src/app/core/services/system-services';
import { IndividualSocialTypeReferenceService } from 'src/app/core/services/system-services/individual-social-type-reference.service';
import { PricePriceDimensionTypeService } from 'src/app/core/services/system-services/price-price-dimension-type.service';
import { Helper } from 'src/app/shared/helper/app.helper';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { SettingsState } from 'src/app/store/settings';
import { PricingDetailConstant } from '../../rules/price-rule-detail/shared/pricing-detail.constant';
import { PriceConditionModel, PriceIndividualModel, PriceModel } from '../shared/models';
import { PriceConverter } from '../shared/price.converter';
import { PriceHelper } from '../shared/price.helper';
import { PriceService } from '../shared/price.service';
import { PriceView } from '../shared/views';
import { AmountComponent } from './amount/amount.component';
import { ConditionComponent } from './condition/condition.component';
import { GeneralComponent } from './general/general.component';
import { PriceDimensionLevelComponent } from './price-dimension-level/price-dimension-level.component';
import { PriceDimensionComponent } from './price-dimension/price-dimension.component';
import {
    AttributeChoiceMultipleOption,
    AttributeChoiceSingleOption,
} from './price-dimension/shared/options/select2-price-dimension.options';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import {
    NewButtonModel,
    CopyButtonModel,
    SaveButtonModel,
    CancelButtonModel,
    RefreshButtonModel,
} from 'src/app/shared/ui/actionbar/models';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { CombinedPriceModel } from '../shared/models/combined-price.model';
import { PriceRuleMappingComponent } from './price-rule-mapping/price-rule-mapping.component';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { TranslationService } from 'src/app/core/services/translation-services';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { MembershipLevelService } from 'src/app/core/services/system-services/membership-level.service';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ActivityDomainComponent } from 'src/app/core/components/activity-domain/activity-domain.component';

@Component({
    selector: 'op-price-detail',
    templateUrl: './price-detail.component.html',
    providers: [PriceHelper, ActionbarService],
})
export class PriceDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly DEFAULT_USAGE_TYPE = 'DATA';
    private readonly ACTIVE_STATUS = 'A';
    readonly generalTabId = 'GENERAL';
    readonly attributesTabId = 'ATTRIBUTE';
    readonly translationTabId = 'TRANSLATION';
    readonly historyTabId = 'HISTORY';
    readonly readModeTabId = 'READ_MODE';
    readonly TRANSLATION_ENTITY_NAME = "Price";
    readonly TRANSLATION_NAME = "Airline translation set";
    @Input() priceId: string;

    @Output() onFormClose = new EventEmitter();

    @ViewChild(GeneralComponent) generalComponent: GeneralComponent;
    @ViewChild(PriceDimensionComponent)
    priceDimensionComponent: PriceDimensionComponent;
    @ViewChild(PriceDimensionLevelComponent)
    priceDimensionLevelComponent: PriceDimensionLevelComponent;
    @ViewChild(ConditionComponent) conditionComponent: ConditionComponent;
    @ViewChild(AmountComponent) amountComponent: AmountComponent;
    @ViewChild(PriceRuleMappingComponent) priceRuleMappingComponent: PriceRuleMappingComponent;
    @ViewChild(TranslationNameComponent) translationNameComponent: TranslationNameComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    
    settings: SettingsState;
    isSaving = false;
    hiddenTree = false;

    priceAttributes$ = new BehaviorSubject<string[]>(new Array<string>());
    unitCode$ = new BehaviorSubject<string>(null);

    organisationTypeReferences$ = this.organisationTypeReferenceService
        .getByStatus('A')
        .pipe(map(res => res.map(r => new Select2Data(r.organisationTypeCode, r.organisationTypeName))));
    organisationReferences$ = this.organisationService
        .getByStatus('A')
        .pipe(map(res => res.map(r => new Select2Data(r.organisationId, r.organisationCallName))));
    organisationRoleReferences$ = this.organisationRoleReferenceService
        .getByCodes([])
        .pipe(map(res => res.map(r => new Select2Data(r.organisationRoleCode, r.organisationRoleName))));
    organisationGroupReferences$ = this.organisationGroupReferenceService
        .getOrganisationGroupReference()
        .pipe(map(res => res.map(r => new Select2Data(r.organisationGroupCode, r.organisationGroupName))));
    regionReferences$ = this.regionReferenceService
        .getRegionReference()
        .pipe(map(res => res.map(r => new Select2Data(r.regionCode, r.regionName))));
    organisationCountryReferences$ = this.countryReferenceService
        .getOrganisationCountries()
        .pipe(map(res => res.map(r => new Select2Data(r.countryCode, r.countryName))));
    servicaCategoryReferences$ = this.pricingService
        .getServiceCategoryReference()
        .pipe(map(res => res.map(r => new Select2Data(r.serviceCategoryCode, r.serviceCategoryName))));
    salesBucketReferences$ = this.pricingService
        .getSalesBucketReference()
        .pipe(map(res => res.map(r => new Select2Data(r.salesBucketCode, r.salesBucketName))));
    membershipLevels$ = this.membershipLevelService
        .getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.membershipLevelCode, r.membershipLevelName))));
    currencyReferences$ = this.currencyReferenceService.getCurrencyReferences();
    domainAttributes$ = new BehaviorSubject<Select2Data[]>(null);
    attributeChoices$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    attributeChoiceOption$ = new BehaviorSubject<any[]>(null);

    individualAgeGroupTypeReferences$ = this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences()
        .pipe(map(res => {
            let blank = new IndividualAgeGroupReferenceModel();
            blank.individualAgeGroupCode = "";
            blank.individualAgeGroupName = "";
            res.unshift(blank);
            return res;
        }))
    conditionReferences$ = this.conditionReferenceService
        .getConditionsByCodes([
            PricingDetailConstant.IS_OPERATOR_CODE,
            PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
            PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
            PricingDetailConstant.TIER_CODE,
        ])
        .pipe(
            startWith(this.getDefaultConditionReferences())
        );
    individualSocialTypeReferences$ = this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences()
        .pipe(map(res => {
            let blank = new IndividualSocialTypeReferenceModel();
            blank.individualSocialTypeCode = "";
            blank.individualSocialTypeName = "";
            res.unshift(blank);
            return res;
        }))
    unitReferences$ = this.unitReferenceService.getUnitReferences()
        .pipe(map(res => {
            let blank = new UnitReferenceModel();
            blank.unitCode = "";
            blank.unitName = "";
            res.unshift(blank);
            return res;
        }))
    pricePriceDimensionTypes$ = this.pricePriceDimensionTypeService.getPricePriceDimensionTypes();

    public price$ = new BehaviorSubject<PriceModel>(this.newPriceModel());
    selectedKey$ = new BehaviorSubject<string[]>([]);
    displayAmount$ = new BehaviorSubject<boolean>(false);
    priceConditions$ = new BehaviorSubject<PriceConditionModel[]>(null);
    public priceView$ = new BehaviorSubject<PriceView>(null);
    unsubscribe$ = new Subject();
    priceName$ = new Subject<string>();

    public rootPrice: PriceModel = new PriceModel();
    public pagingDataViews: PagingDataView[] = [];
    public userSecurity: SecurityGroupSecurityModel;
    public pageChanging: boolean = false;
    selectedTab = this.generalTabId;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    defaultActionbarHandler: ActionBarHandlerModel;

    previousPage: string;
    combinedPrice: CombinedPriceModel;

    private languageTranslationSet: LanguageTranslationSetViewModel;

    get isNewOrDraft(): boolean {
        const price = this.price$.getValue();
        if (!price?.priceId || price.draftFlag) {
            return true;
        }
        return false;
    }

    get detailMode(): boolean {
        return this.selectedTab == this.generalTabId || this.selectedTab == this.attributesTabId;
    }

    get translationMode(): boolean {
        return this.selectedTab == this.translationTabId;
    }

    get currentPage(): number {
        if (!this.priceId) {
            return -1;
        }
        if (!this.pagingDataViews?.length) {
            return -1;
        }
        const index = this.pagingDataViews.map(p => p.id).indexOf(this.priceId);
        if (index < 0) {
            return -1;
        }
        return index + 1;
    }

    constructor(
        protected helper: Helper,
        private priceService: PriceService,
        private cdr: ChangeDetectorRef,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationService: OrganisationService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private regionReferenceService: RegionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private pricingService: PricingService,
        private currencyReferenceService: CurrencyReferenceService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private unitReferenceService: UnitReferenceService,
        private pricePriceDimensionTypeService: PricePriceDimensionTypeService,
        private domainAttributeService: DomainAttributeService,
        private priceHelper: PriceHelper,
        private priceConverter: PriceConverter,
        private navigationService: NavigationService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService,
        private translationNameMapperService: TranslationNameMapperService,
        public alertBarService: AlertBarService,
        private membershipLevelService: MembershipLevelService,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService) {
        this.domainAttributeService.getPriceRuleAttributes('', '', '').subscribe((res: DomainAttributeModel[]) => {
            let priceDimensionPriceRuleAttributeTypeReferences = res.filter(r => r.dimensionFlag == true);
            this.domainAttributes$.next(
                priceDimensionPriceRuleAttributeTypeReferences.map(
                    r => new Select2Data(r.attributeTypeCode, r.attributeTypeName)
                )
            );
            this.populateChoices(priceDimensionPriceRuleAttributeTypeReferences);
            this.populateChoiceOptions(priceDimensionPriceRuleAttributeTypeReferences);
        });
        this.getEmptyLanguageTranslationSet();
    }

    private getDefaultConditionReferences(): ConditionReferenceModel[] {
        let result = new Array<ConditionReferenceModel>();
        let is = new ConditionReferenceModel();
        is.conditionCode = PricingDetailConstant.IS_OPERATOR_CODE;
        is.conditionName = 'is, are';
        result.push(is);
        return result;
    }

    private updatePriceAmountBasedOnConditionsAndIndividuals(price: PriceModel, currencyCode: string) {
        if (price.currencies?.length == 1) {
            currencyCode = price.currencies[0].currencyCode;
        }
        if (price.children?.length) {
            for (let child of price.children) {
                this.updatePriceAmountBasedOnConditionsAndIndividuals(child, currencyCode);
            }
        } else {
            let conditions = this.CreateNewConditions(price, currencyCode);
            price.conditions = conditions;
        }
    }

    private CreateNewConditions(price: PriceModel, currencyCode: string) {
        let conditions = new Array<PriceConditionModel>();
        let referencePriceConditions = this.priceConditions$.value;
        for (let referenceCondition of referencePriceConditions) {
            let existCondition = price.conditions?.find(
                c => c.referencePriceConditionId == referenceCondition.priceConditionId
            );
            if (existCondition) {
                existCondition.individuals = this.CreateNewIndividuals(
                    referenceCondition,
                    existCondition,
                    currencyCode
                );
                conditions.push(existCondition);
            } else {
                let newCondition = this.priceConverter.referencePriceConditionModel(referenceCondition, currencyCode);
                conditions.push(newCondition);
            }
        }
        return conditions;
    }

    private CreateNewIndividuals(
        referenceCondition: PriceConditionModel,
        existCondition: PriceConditionModel,
        currencyCode: string
    ) {
        let newIndividuals = new Array<PriceIndividualModel>();
        for (let referenceIndividual of referenceCondition.individuals) {
            let existIndividual = existCondition.individuals?.find(
                i => i.referencePriceIndividualId == referenceIndividual.priceIndividualId
            );
            if (existIndividual) {
                this.priceConverter.copyPriceIndividualModel(referenceIndividual, existIndividual);
                newIndividuals.push(existIndividual);
            } else {
                let newIndividual = this.priceConverter.referencePriceIndividualModel(
                    referenceIndividual,
                    currencyCode
                );
                newIndividuals.push(newIndividual);
            }
        }
        this.priceConverter.copyPriceConditionModel(referenceCondition, existCondition);
        return newIndividuals;
    }

    private updatePriceAmount() {
        let price = this.price$.value;
        if (!price.priceId) {
            return;
        }

        price = this.amountComponent.getValues(price);
        this.updatePriceAmountBasedOnConditionsAndIndividuals(price, null);
        this.priceView$.next(this.priceConverter.toView(1, price));
    }

    private populateChoices(res: DomainAttributeModel[]) {
        let choicesArr = new Array<Select2Data[]>();
        for (let r of res) {
            let choices = r.attributeChoices.map(c => new Select2Data(c.attributeChoiceId, c.attributeChoiceName));
            choicesArr[r.attributeTypeCode] = choices;
        }
        this.attributeChoices$.next(choicesArr);
    }

    private populateChoiceOptions(res: DomainAttributeModel[]) {
        let optionArr = new Array<any>();
        for (let r of res) {
            optionArr[r.attributeTypeCode] = r.multipleChoiceFlag
                ? AttributeChoiceMultipleOption
                : AttributeChoiceSingleOption;
        }
        this.attributeChoiceOption$.next(optionArr);
    }

    ngOnInit() {
        let params = this.navigationService.getParams();
        if (!params)
            params = this.navigationService.getPreviousTabParams()?.params

        this.priceId = params?.priceId ?? params?.id;
        this.previousPage = params?.previousPage;
        this.pagingDataViews = params?.pagingDataViews ?? [];
        this.userSecurity = params?.userSecurity;
        this.addAction();
        this.activityStore();
        this.loadData(this.priceId);
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.defaultActionbarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            switch (actionId) {
                case ACTION_STATUS.back:
                    this.goback(this.previousPage);
                    break;
                case ACTION_STATUS.cancel:
                    this.goback(null);
                    break;
                case ACTION_STATUS.new:
                    this.onNewPrice();
                    break;
                case ACTION_STATUS.copy:
                    break;
                case ACTION_STATUS.refresh:
                    this.loadData(this.priceId);
                    break;
                case ACTION_STATUS.save:
                    this.save();
                    break;
                default:
                    break;
            }
        });
        this.focusingService.focus(this.generalComponent.focusingDirective);
    }

    private onNewPrice() {
        this.priceId = null;
        this.loadData(null);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private setupActionbarSecurity() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.enable(this.saveFlag);
        this.defaultActionbarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            new CancelButtonModel(),
            new RefreshButtonModel()
        );
    }

    get saveFlag(): boolean {
        return (!this.priceId && this.userSecurity?.newFlag == true) || (this.priceId && this.userSecurity?.editFlag == true);
    }

    loadData(priceId: string) {
        if (priceId) {
            this.loadingSpinnerService.show();
            this.priceService.getById(this.priceId)
                .pipe(
                   finalize(() => {
                        this.loadingSpinnerService.hide();
                   }) 
                )
                .subscribe(
                (priceModel: PriceModel) => {
                    this.price$.next(priceModel);
                    this.priceView$.next(this.priceConverter.toView(1, priceModel));
                    this.fillLanguageTranslationSetToTranslationNameComponent(priceModel)
                    this.addAction();
                    this.pageChanging = false;
                }
            );
        } else {
            this.resetPanels();
        }
    }

    private resetPanels() {
        this.price$.next(this.newPriceModel());
        this.priceView$.next(null);
        this.displayAmount$.next(false);
    }

    cancel() {
        this.onFormClose.emit();
    }

    save() {
        let priceModel = this.generalComponent.getValues();
        if (!priceModel) {
            return;
        }

        let children = this.price$.value?.children?.map(c => c);
        priceModel.children = children;
        priceModel = this.priceDimensionComponent.getValues(priceModel);
        if (!priceModel) {
            return;
        }

        if (this.priceDimensionLevelComponent) {
            priceModel = this.priceDimensionLevelComponent.getValues(priceModel);
        }
        if (!priceModel) {
            return;
        }

        priceModel = this.conditionComponent.getValues(priceModel);
        if (!priceModel) {
            return;
        }

        if (this.amountComponent && priceModel.priceId) {
            priceModel = this.amountComponent.getValues(priceModel);
        }
        if (!priceModel) {
            return;
        }

        priceModel = this.priceRuleMappingComponent.getValues(priceModel);
        if (!priceModel) {
            return;
        }

        this.fillLanguageTranslationSetToModel(priceModel);
        this.loadingSpinnerService.showSaving();
        this.isSaving = true;
        if (priceModel.priceId) this.activityDomainComponent.save();
        let response = priceModel.priceId ? this.priceService.update(priceModel) : this.priceService.add(priceModel);
        response.subscribe(
            (model: PriceModel) => {
                this.priceId = model.priceId;
                this.price$.next(Object.assign({}, model));
                this.priceView$.next(this.priceConverter.toView(1, model));
                this.showTree();
                this.isSaving = false;
                this.addAction();
                this.loadingSpinnerService.saveComplete();
                this.setupActionbarSecurity();
            },
            () => {
                this.isSaving = false;
                this.loadingSpinnerService.hide();
            },
            () => {
                this.cdr.detectChanges();
            }
        );
    }

    onPriceAttributesChange(priceAttributes: string[]) {
        this.priceAttributes$.next(priceAttributes);
    }

    onUnitCodeChange(unitCode: string) {
        this.unitCode$.next(unitCode);
    }

    onUnitCodeMissing() {
        this.priceDimensionComponent.triggerAttributesError();
    }

    hideTree() {
        this.hiddenTree = true;
    }

    showTree() {
        this.hiddenTree = false;
    }

    keySelected(key: string[]) {
        this.selectedKey$.next(key);
    }

    onLowestLevelSelected(isLowestLevelSelected: boolean) {
        this.displayAmount$.next(isLowestLevelSelected);
    }

    onPriceConditionsChange(priceConditions: PriceConditionModel[]) {
        this.priceConditions$.next(priceConditions);
        this.updatePriceAmount();
    }

    onPriceDimensionTemplateChange(priceId: string) {
        if (priceId) {
            this.loadingSpinnerService.show();
            this.priceService.getById(priceId)
                .pipe(finalize(() => this.loadingSpinnerService.hide()))
                .subscribe(
                (response: PriceModel) => {
                    let price = this.applyPriceDimensionTemplate(this.gatherRawPriceModel(), response);
                    this.price$.next(price);
                }
            );
        } else {
            let template = this.newPriceModel();
            let price = this.applyPriceDimensionTemplate(this.price$.value, template);
            this.price$.next(price);
        }
    }

    private applyPriceDimensionTemplate(price: PriceModel, template: PriceModel): PriceModel {
        let result = Object.assign({}, price);
        template = this.priceHelper.resetPriceAndChildrenIds(template);

        result.productNumbers = template.productNumbers;
        result.pricePriceDimensions = template.pricePriceDimensions;
        result.routes = template.routes;
        result.salesBuckets = template.salesBuckets;
        result.serviceCategories = template.serviceCategories;
        result.attributes = template.attributes;
        result.currencies = template.currencies;
        result.validities = template.validities;
        result.locations = template.locations;
        result.memberships = template.memberships;
        result.organisations = template.organisations;

        return result;
    }

    onPriceConditionTemplateChange(priceId: string) {
        if (priceId) {
            this.priceService.getById(priceId).subscribe((response: PriceModel) => {
                let price = this.applyPriceConditionTemplate(this.gatherRawPriceModel(), response);
                this.price$.next(price);
            });
        } else {
            let template = this.newPriceModel();
            let price = this.applyPriceConditionTemplate(this.price$.value, template);
            this.price$.next(price);
        }
    }

    private gatherRawPriceModel(): PriceModel {
        let price = this.generalComponent.getRawValues();
        price = this.priceDimensionComponent.getRawValues(price);
        price = this.priceDimensionLevelComponent.getRawValues(price);
        price = this.conditionComponent.getRawValues(price);
        return price;
    }

    private applyPriceConditionTemplate(price: PriceModel, template: PriceModel): PriceModel {
        let result = Object.assign({}, price);
        template = this.priceHelper.resetPriceAndChildrenIds(template);

        result.conditions = template.conditions;
        result.unitCode = template.unitCode;

        return result;
    }

    private newPriceModel(): PriceModel {
        let model = new PriceModel();
        model.usageTypeCode = this.DEFAULT_USAGE_TYPE;
        model.status = this.ACTIVE_STATUS;
        return model;
    }

    onActiveIdChange(selectedTabId: string) {
        switch (selectedTabId) {
            case this.generalTabId:
                this.focusingService.focus(this.generalComponent.focusingDirective);
                break;
            case this.attributesTabId:
                this.focusingService.focus(this.priceDimensionComponent.focusingDirective);
                break;
            case this.translationTabId:
                this.focusingService.focus(this.translationNameComponent.focusingDirective);
                break;
            default:
                break;
        }
    }

    goback(previousPage: string) {
        this.navigationService.navigate('/main/pricing/home', null, null, {
            previousPage: previousPage,
            userSecurity: this.userSecurity
        });
    }

    onGeneralFocus() {
        this.selectedTab = this.generalTabId;
    }

    onPriceDimensionFocus() {
        this.selectedTab = this.attributesTabId;
    }

    private fillLanguageTranslationSetToTranslationNameComponent(price: PriceModel) {
        this.translationNameComponent.fillModelToForm(
            price.priceName,
            price.languageTranslationSet
        );
    }

    private getEmptyLanguageTranslationSet() {
        this.translationService
            .searchTranslationByEntityNameAndEntityPrimary(
                this.TRANSLATION_ENTITY_NAME,
                null
            )
            .subscribe(
                (
                    languageTranslationSets: LanguageTranslationSetViewModel[]
                ) => {
                    if (languageTranslationSets?.length) {
                        this.languageTranslationSet = languageTranslationSets[0];
                        this.translationNameComponent?.fillModelToForm(
                            this.price$?.value?.priceName,
                            this.languageTranslationSet
                        );
                    }
                }
            );
    }

    private fillLanguageTranslationSetToModel(priceModel: PriceModel) {
        let languageTranslationSet =
            this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
                this.translationNameComponent.languageTranslationSetId,
                this.TRANSLATION_NAME,
                this.TRANSLATION_ENTITY_NAME,
                priceModel.priceId,
                this.translationNameComponent.translationViews
            );
        priceModel.languageTranslationSet = languageTranslationSet;
    }

    public onPriceNameChange(priceName: string) {
        this.priceName$.next(priceName);
    }

    private addAction() {
        if (this.priceId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.priceId, this.price$?.value?.priceName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onPageChange(priceId) {
        if (!this.priceId) {
            return;
        }
        if (this.priceId == priceId) {
            return;
        }
        this.priceId = priceId;
        this.pageChanging = true;
        this.loadData(this.priceId);
    }

    private activityStore() {
        if(!this.priceId) {
            this.activityStoreService.add(
                null,
                null,
                null,
                null,
                null,
                null);
        }
    }
}

import { Injectable } from '@angular/core';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { cloneDeep } from "lodash";

const NAVIGATOR = window.navigator as any;
@Injectable({
    providedIn: 'root'
})
export class CsvExportService {
    constructor(
        private stringHelperService: StringHelperService
    ) { }

    public lastHeader = [];
    public merges = [];
    public lastHeaderDataField = [];
    public datalevel = [];
    private dataRows = [];
    public indexGroup = 0;
    public rowsItem = 0;
    public dataGroupRow = [];
    public dataItem = 0;
    public dataFieldCount = [];
    public dataFielsSum = [];
    public subHeaderLevel = 1;
    public subColumsHeader = [];
    public lastSubHeader = [];
    public lastSubHeaderDataField = [];

    public download(filename: string, jsonobject: any) {
        if (!jsonobject || !jsonobject.length) {
            return;
        }

        const csvContent: string = this.ConvertToCSV(jsonobject);

        const blob = new Blob(["\ufeff", csvContent], { type: 'text/csv;charset=utf-8;' });
        if (NAVIGATOR.msSaveBlob) { // IE 10+
            NAVIGATOR.msSaveBlob(blob, filename + '.csv');
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename + '.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private ConvertToCSV(objArray: any) {

        const newLine: string = '\r\n';
        const csvSeperator: string = ',';

        let str = '';
        let row = '';
        let array = typeof objArray != 'object'
            ? JSON.parse(objArray) : objArray;

        for (var index in objArray[0]) {
            row += this.addDoubleQuote(index) + csvSeperator;
        }
        row = row.slice(0, -1);
        str += row + newLine;

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += csvSeperator

                line += this.addDoubleQuote(array[i][index]);
            }
            str += line + newLine;
        }
        return str;
    }

    private addDoubleQuote(value: string) {
        return '\"' + value + '\"'
    }

    public downloadDataGroup(headerLevel: any, columsHeader: any, filename: string, pdfDefaultStyle: any, jsonobject: any, groupingIndex: any, dataFieldCount: any,
        dataFielsSum: any, detailHeaderLevel: any, detialColumsHeader: any) {
        this.subHeaderLevel = detailHeaderLevel;
        this.subColumsHeader = detialColumsHeader;
        this.dataFieldCount = dataFieldCount;
        this.dataFielsSum = dataFielsSum;
        this.datalevel = cloneDeep(groupingIndex);
        this.dataRows = [];
        this.indexGroup = 0;

        if (!jsonobject || !jsonobject.length) {
            return;
        }

        const csvContent: string = this.ConvertDataGroupToCSV(jsonobject, headerLevel, columsHeader);
        const blob = new Blob(["\ufeff", csvContent], { type: 'text/csv;charset=utf-8;' });
        if (NAVIGATOR.msSaveBlob) { // IE 10+
            NAVIGATOR.msSaveBlob(blob, filename + '.csv');
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename + '.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private buildHeaderTable(headerLevel, columsHeader) {
        let header = [];
        let hasRowSpansIndex = [];
        for (let index = 0; index < headerLevel; index++) {
            let dataHeader = columsHeader.filter(dataHeader => dataHeader.headerLevel == index);
            let rowData = [];
            this.lastHeader = [];
            this.lastHeaderDataField = [];
            dataHeader.forEach(element => {
                let colSpan = element.subColumn.length;
                if (!element.ownerBand && colSpan == 0) {
                    hasRowSpansIndex.push({ caption: element.caption, colums: rowData.length, dataField: element.dataField });
                }
                rowData = this.addDataRow(rowData, element, colSpan, index, headerLevel);
                this.setLastHeader(element.name, element.dataField, element.alignment);
            });

            let rowDataHasRowSpans;
            hasRowSpansIndex.forEach(e => {
                if (index > 0) {
                    rowDataHasRowSpans = this.insertDataColumsHasRowSpans(e.colums, e.caption, rowData);
                    this.insertDatafieldColumsHasRowSpans(e.colums, e.dataField);
                }
            });
            this.lastHeader = rowDataHasRowSpans;
            header[index] = rowData;
        }
        return header;
    }

    private insertDataColumsHasRowSpans(colums: any, text: string, rowData: any) {
        let dataSplice = { text: '' };
        rowData.splice(colums, 0, dataSplice);
        this.lastHeader.splice(colums, 0, dataSplice);
        return this.lastHeader;
    }

    private insertDatafieldColumsHasRowSpans(colums: any, text: string) {
        let dataSplice = { text: text };
        this.lastHeaderDataField.splice(colums, 0, dataSplice);
        return this.lastHeaderDataField;
    }

    private setLastHeader(text, dataField, alignment) {
        let lastHeader = {
            text: text
        }
        this.lastHeader.push(lastHeader);

        let lastHeader1 = {
            noCount: true,
            text: dataField,
            sumValue: '',
            alignment: alignment
        }
        this.lastHeaderDataField.push(lastHeader1);
    }

    private buildBodyTable(data) {
        let lastHeaderDataField = this.lastHeaderDataField;
        if (!data.items) {
            this.indexGroup = this.indexGroup - this.rowsItem;
            this.rowsItem = 0;
        } else {
            this.dataItem = data.items;
            this.rowsItem++;
        }
        this.indexGroup++;
        data.forEach((ele, di) => {
            if (ele.items) {
                let item = [];
                item.push(ele.key);
                this.dataGroupRow.push(item)
                this.buildBodyTable(ele.items);
            } else {
                if (this.dataGroupRow.length > 0) {
                    let divColumn = this.datalevel.length - this.dataGroupRow.length;
                    this.dataGroupRow.forEach((e, index) => {
                        let item = [];
                        let margin = index + divColumn;
                        item.push({ text: this.getTabString(margin) + this.datalevel[divColumn + index].caption + ': ' + e, colSpan: 3, margin: [margin * 10, 0, 0, 0], fillColor: '#e9e9e9' });
                        for (let i = 1; i < lastHeaderDataField.length; i++) {
                            let text = '';
                            let fillColumnCount = this.dataFieldCount.filter(data => data == lastHeaderDataField[i].text);
                            if (fillColumnCount.length > 0 && this.dataGroupRow.length == index + 1) {
                                text = (data.length).toString();
                            }
                            let fillColumnSum = this.dataFielsSum.filter(data => data == lastHeaderDataField[i].text);
                            if (fillColumnSum.length > 0 && this.dataGroupRow.length == index + 1) {
                                text = this.getSumData(data, lastHeaderDataField[i].text);
                            }
                            item.push({ text: text, fillColor: '#e9e9e9', alignment: 'right' });
                        }
                        this.dataRows.push(item);
                    });
                }
                let row = [];
                lastHeaderDataField.forEach((element, i) => {
                    let text = ele[element.text] ? ele[element.text].toString() : '';
                    if ((element.text)?.toString().toUpperCase() == 'NO') {
                        di++;
                        text = di;
                    }
                    let fillColumnCount = this.dataFieldCount.filter(data => data == lastHeaderDataField[i].text);
                    if (fillColumnCount.length > 0) {
                        text = '';
                    }
                    row.push({
                        text: text,
                        alignment: (element.alignment) ? element.alignment : 'left'
                    });
                });
                this.dataRows.push(row);
                this.dataGroupRow = [];

                if (this.subColumsHeader.length > 0) {
                    let addDetailTable = this.addDetailTable(ele.chargeItems);
                    let detailRows = this.dataRows;
                    this.dataRows = detailRows.concat(addDetailTable)
                }
            }
        });
        return this.dataRows;
    }

    public getTabString(tab) {
        let tabStr = '';
        for (let i = 0; i < tab; i++) {
            tabStr += '     ';
        }
        return tabStr;
    }

    public getSumData(data, dataField) {
        let sumValue = 0;
        data.forEach(element => {
            let value = parseFloat(element[dataField]);
            sumValue += value;
        });
        return this.stringHelperService.validateNullAmountDecimal(sumValue);
    }

    private ConvertDataGroupToCSV(objArray: any, headerLevel: number, columsHeader: any) {
        let header = this.buildHeaderTable(headerLevel, columsHeader);
        let body = this.buildBodyTable(objArray);
        const newLine: string = '\r\n';
        const csvSeperator: string = ',';
        let str = '';
        let row = '';
        for (let i = 0; i < header.length; i++) {
            row = '';
            header[i].forEach(e => {
                row += this.addDoubleQuote(e.text) + csvSeperator;
                if (e.rowSpan || e.colSpan) {
                    let sr;
                    let sc;
                    let er;
                    let ec;

                    if (e.rowSpan) {
                        sr = i;
                        er = i + e.rowSpan;
                    }
                    if (e.colSpan) {
                        er = i + e.colSpan;
                        sc = i;
                    }
                    let merge = { s: { r: sr, c: sc }, e: { r: er, c: ec } };
                    this.merges.push(merge);
                }
            });
            str += row + newLine;
        }
        for (let i = 0; i < body.length; i++) {
            row = '';
            body[i].forEach(e => {
                row += this.addDoubleQuote(e.text) + csvSeperator;
            });
            str += row + newLine;
        }
        return str;
    }

    private buildDetailHeaderTable(subHeaderLevel, subColumsHeader) {
        let header = [];
        let hasRowSpansIndex = [];
        for (let index = 0; index < subHeaderLevel; index++) {
            let dataHeader = subColumsHeader.filter(dataHeader => dataHeader.headerLevel == index);
            let rowData = [];
            let lastSubHeaderDataField = [];
            let row = {
                text: '',
            }
            rowData.push(row);
            dataHeader.forEach(element => {
                let colSpan = element.subColumn.length;
                if (!element.ownerBand && colSpan == 0) {
                    hasRowSpansIndex.push({ caption: element.caption, colums: rowData.length, dataField: element.dataField });
                }
                rowData = this.addDataRow(rowData, element, colSpan, index, subHeaderLevel);
                let rowFilde = {
                    text: element.dataField,
                    alignment: (index == 0) ? 'center' : 'left'
                }
                lastSubHeaderDataField.push(rowFilde);
                if (colSpan > 0) {
                    for (let i = 1; i < colSpan; i++) {
                        lastSubHeaderDataField.push({});
                    }
                }
            });
            hasRowSpansIndex.forEach(e => {
                if (index > 0) {
                    rowData.splice(e.colums, 0, { text: '' });
                    lastSubHeaderDataField.splice(e.colums, 0, { text: e.dataField });
                }
            });
            this.lastSubHeader = rowData;
            this.lastSubHeaderDataField = lastSubHeaderDataField;
            header[index] = rowData;
        }
        return header;
    }

    private addDetailTable(chargeItems) {
        let subHeaderLevel = this.subHeaderLevel;
        let subColumsHeader = this.subColumsHeader;
        let header = this.buildDetailHeaderTable(subHeaderLevel, subColumsHeader);
        let body = this.getDetailBody(chargeItems, this.lastSubHeaderDataField);
        let bodyContent = header.concat(body);
        return bodyContent;
    }

    private getDetailBody(chargeItems: any, lastSubHeaderDataField: any) {
        let allChargeItems = [];
        chargeItems.forEach(e => {
            let item = [];
            lastSubHeaderDataField.forEach(element => {
                let text = e[element.text] ? e[element.text].toString() : '';
                item.push(
                    {
                        text: text,
                        alignment: (element.alignment) ? element.alignment : 'left'
                    }
                );
            });
            allChargeItems.push(item)
        });
        return allChargeItems;
    }

    private addDataRow(rowData, element, colSpan, index, headerLevel) {
        let row = {
            text: element.caption,
            colSpan: (colSpan > 0) ? colSpan : 1,
            rowSpan: (index == 0) ? ((colSpan == 0) ? headerLevel : 1) : 1,
            alignment: (index == 0) ? 'center' : 'left'
        }
        rowData.push(row);
        if (colSpan > 0) {
            for (let i = 1; i < colSpan; i++) {
                rowData.push({ text: '' });
            }
        }
        return rowData;
    }
}
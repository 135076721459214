import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActionService } from 'src/app/core/utils/action.service';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { ProductCategory } from 'src/app/modules/product-management/product-management-content/product-categories/product-category';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-transport-content-tabs',
    templateUrl: './transport-content-tabs.component.html',
    providers: [ActionbarService]
})
export class TransportContentTabsComponent implements OnInit {
    private readonly searchTabId = 'search';
    private readonly categoryCode = 'TRANSPORT';
    public readonly title = 'Transport Management';
    public selectedGroup: string;
    public selectedTab = 'group';
    public searchFilterId: string;
    public executeFlag: boolean = true;
    public selectedProductCategory: ProductCategory;
    public userSecurity: SecurityGroupSecurityModel;

    actionBarHandler = new ActionBarHandlerModel(
        new RefreshButtonModel()
    );
    private unsubscribe$ = new Subject();
    private _navigationSubscription: Subscription;

    constructor(private navigateService: NavigationService,
        private actionbarService: ActionbarService,
        public alertBarService: AlertBarService,
        public actionService: ActionService,
        private activityStoreService: ActivityStoreService) {
    }

    ngOnInit(): void {
        let params = this.navigateService.getParams();
        let tab = params?.tab ?? params?.selectedTab?.toLowerCase() ?? null;
        this.searchFilterId = params?.id;
        this.executeFlag = params?.executeFlag ?? true;
        this.selectedProductCategory = params?.selectedItem ?? params?.module;
        this.userSecurity = this.selectedProductCategory?.security ?? params?.userSecurity;
        if (tab) {
            this.selectedTab = tab;
        }
        this.addAction(this.selectedTab);
        this.activityStore(this.selectedTab);
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    public onSearch(event) {
        this.selectedTab = this.searchTabId;
        this.selectedGroup = event;
    }

    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.back) {
            this.navigateService.navigate('main/product/home', null, null,
            {
                module: this.selectedProductCategory,
                userSecurity: this.userSecurity
            });
        }
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public onActiveIdChange(activeId: string) {
        this.addAction(activeId);
        this.activityStore(activeId);
    }

    private addAction(activeId: string = null) {
        if (activeId == FavoriteConstant.SEARCH_ACTION.toLowerCase()) {
            this.actionService.add(FavoriteConstant.SEARCH_ACTION, null, null);
        } else {
            this.actionService.add(null, null, null)
        }
    }

    private activityStore(activeTabId: string) {
        this.activityStoreService.add(
            null,
            null,
            null,
            activeTabId,
            this.categoryCode,
            null);
    }

    public onNew(groupCode: string) {
        this.navigateService.navigate('/main/product/transport/details', null, null, {
            tab: 'categories',
            newProduct: true,
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity,
            groupCode: groupCode
        });
    }

    public onDashboard() {
        this.navigateService.navigate('/main/product/home', null, null, {
            tab: 'dashboard',
            module: this.selectedProductCategory,
            userSecurity: this.userSecurity
        });
    }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'found'
})
export class ItemsFoundPipe implements PipeTransform {
    readonly lblFound = 'found'

    transform(value: any): string {
        let isNumber = this.isNumber(value);
        if (isNumber && value > 0) {
            return `${value} item${value === 1 ? "" : "s"} ${this.lblFound}`;
        } else if ((isNumber && value === 0) || !value?.length) {
            return 'no item found';
        }
        return `${value?.length} item${value?.length === 1 ? "" : "s"} ${this.lblFound}`;
    }

    private isNumber(x: any): x is number {
        return typeof x === 'number';
    }
}

import { Injectable } from "@angular/core";
import { InsightPassengerDocumentModel } from "src/app/core/models/individual-model/insight-passenger-document.model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { NameView } from "../../insight-detail-name/shared/name-view";
import { DocumentView } from "./document-view";

declare var moment: any;

@Injectable()
export class DocumentMapperService {
    constructor(private dateConverterService: DateConverterService,) {

    }

    public documentModelToViews(models: InsightPassengerDocumentModel[]): DocumentView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.documentModelToView(i, model));
        }
        return views;
    }

    private documentModelToView(index: number, model: InsightPassengerDocumentModel): DocumentView {
        let view = {} as DocumentView;
        view.no = index;
        view.individualIdentityDocumentId = model.individualIdentityDocumentId;
        view.individualId = model.individualId;
        view.identityDocumentNumber = model.identityDocumentNumber;
        view.identityDocumentCode = model.identityDocumentCode;
        view.identityDocumentName = model.identityDocumentName;
        view.countryCode = model.countryCode;
        view.countryName = model.countryName;
        view.expiryDate = this.dateConverterService.toDateFormatOrNull(model.expiryDate);
        view.issueDate = this.dateConverterService.toDateFormatOrNull(model.issueDate);
        view.individualNameId = model.individualNameId;
        view.placeOfIssue = model.placeOfIssue;
        view.primaryFlag = model.primaryFlag;

        return view;
    }

    public documentViewToModels(views: DocumentView[], names: NameView[]): InsightPassengerDocumentModel[] {
        let models = new Array();
        let primaryName = names.find(x => x.primaryFlag);
        for (let view of views) {
            models.push(this.documentViewToModel(view, primaryName?.individualNameId));
        }
        return models;
    }

    private documentViewToModel(view: DocumentView, individualNameId: string): InsightPassengerDocumentModel {
        let model:InsightPassengerDocumentModel = {
            individualIdentityDocumentId: view.individualIdentityDocumentId,
            individualId: view.individualId,
            identityDocumentNumber: view.identityDocumentNumber,
            identityDocumentCode: view.identityDocumentCode,
            identityDocumentName: view.identityDocumentName,
            countryCode: view.countryCode,
            expiryDate: view.expiryDate,
            issueDate: view.issueDate,
            individualNameId: individualNameId,
            placeOfIssue: view.placeOfIssue,
            primaryFlag: view.primaryFlag,
            countryName: view.countryName,
        };

        return model;
    }
}
import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightNationalityDetailComponent } from "./detail/nationality-detail.component";
import { NationalityDetailDirective } from "./detail/nationality.directive";
import { NationalityView } from "./shared/nationality-view";
import { InsightNationalityTableComponent } from "./table/nationality-table.component";
import { cloneDeep } from "lodash";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-nationality',
    templateUrl: './insight-detail-nationality.component.html'
})

export class InsightDetailNationalityComponent {
    @Input() countryReferences: CountryReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isNationalitiesEmpty: boolean = true;
    public isMoreThanOneNationalities: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public nationality = {} as NationalityView;
    public nationalities: NationalityView[] = new Array();
    public previousNationalities: NationalityView[] = new Array();

    @ViewChild(NationalityDetailDirective) nationalityDetailDirective: NationalityDetailDirective;
    @ViewChild(InsightNationalityDetailComponent) nationalityDetailComponent: InsightNationalityDetailComponent;
    @ViewChild(InsightNationalityTableComponent) nationalityTableComponent: InsightNationalityTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newNationalityDetail: InsightNationalityDetailComponent;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerNationalities: NationalityView[]) {
        this.clearData();
        if (passengerNationalities && passengerNationalities.length > 0) {
            this.fillModelInCase(passengerNationalities);
        }

        this.nationalities = passengerNationalities;
        this.previousNationalities = passengerNationalities;

        if (this.nationalities.length > 0) {
            this.isNationalitiesEmpty = false;
        }

        if (this.nationalities.length == 1) {
            this.isMoreThanOneNationalities = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.nationality = {} as NationalityView;
    }

    private fillModelInCase(nationalities: NationalityView[]) {
        if (nationalities.length == 1) {
            this.fillModelSingleRecord(nationalities[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: NationalityView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.nationality = view;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneNationalities = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: NationalityView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightNationalityDetailComponent);

        let viewContainerRef = this.nationalityDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightNationalityDetailComponent = (<InsightNationalityDetailComponent>componentRefs.instance);
        component.countryReferences = this.countryReferences;
         
        if (dataEdit != null) {
            component.insightNationality = cloneDeep(dataEdit);
        }

        this.newNationalityDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightNationality = this.nationalityDetailComponent.getInsightNationality();
        if (insightNationality) {
            this.savedata(insightNationality);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewNationality() {
        this.isApplyBtnClicked = true;
        let insightNationality = this.newNationalityDetail.getInsightNationality();
        if (insightNationality) {
            this.savedata(insightNationality);
            this.showAdd = true;         
            this.displayAfterSave();
        }
    }

    private savedata(data: NationalityView) {
        if (this.nationalities?.length) {
            var filterIndex = this.nationalities.findIndex(x => x.no == data.no);
            if (data.primaryFlag === undefined) {
                data.primaryFlag = false;
            }

            this.updateNationalityViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryFlag = true;
            this.nationality = data;
            this.nationalities.push(data);
        }

        this.isNationalitiesEmpty = false;
        this.nationalityTableComponent.dataGrid.instance.refresh();
    }

    private updateNationalityViews(filterIndex: number, data: NationalityView) {
        if (this.nationalities?.length > 0 && data.primaryFlag) {
            this.setOtherNationalityPrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.nationalities.length + 1;
            this.nationalities.push(data);
        }
        else {
            this.nationalities[filterIndex] = data;
        }
    }

    private setOtherNationalityPrimaryFlag(filterIndex: number) {
        let otherNationalities = this.nationalities;
        for (let otherNationality of otherNationalities) {
            if (otherNationality.no == filterIndex + 1) {
                continue;
            }

            otherNationality.primaryFlag = false;;
        }

        this.nationalities = otherNationalities;
    }

    private displayAfterSave() {
        if (this.nationalities?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.nationalityDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.nationalities.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        this.setMoreThanOneNationalities();
        this.deleteDataFromNationalityViews(data);
    }

    getDeleteData(): NationalityView {
        if (this.nationalities.length > 1) {
            return this.nationalityTableComponent.selectedItem;
        }

        return this.nationality;
    }

    setMoreThanOneNationalities() {
        if (this.nationalities.length > 1) {
            this.isMoreThanOneNationalities = true;
        } 
        else {
            this.isMoreThanOneNationalities = false;
        }
    }

    deleteDataFromNationalityViews(data: NationalityView) {
        let views = new Array<NationalityView>();
        if (this.nationalities != null) {
            views = this.nationalities;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.nationalities = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.nationalities.length == 1) {
            this.nationalities[0].primaryFlag = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForNationalityDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.nationalities.length == 0 || this.nationalities.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForNationalityDetailForm() {
        if (this.nationalities.length == 1) {
            this.nationality = this.nationalities[0];
            this.showAdd = true;
        }
        else {
            this.nationality = {} as NationalityView;
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.nationalityTableComponent.selectedItem);
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.nationalities.length == 0) {
            this.nationality = {} as NationalityView;
        }

        if (this.nationalities.length <= 1) {
            this.isMoreThanOneNationalities = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.nationalityDetailComponent) {
            return this.nationalityDetailComponent.setProcessing(false);
        }

        if (this.newNationalityDetail) {
            return this.newNationalityDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.nationalities.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.nationalities.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.nationalities.length == 1) {
            let copyNationality = cloneDeep(this.nationalities[0]);
            this.openCopyDetailForm(copyNationality);
            return;
        }

         if (!this.nationalityTableComponent.selectedItem) {
            return;
        }

        let copyNationality = cloneDeep(this.nationalityTableComponent.selectedItem);
        this.openCopyDetailForm(copyNationality);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneNationalities = true;
    }

    setCopyNationalityProperty(copyNationality: NationalityView) {
        copyNationality.no = null;
        copyNationality.primaryFlag = false;
    }

    openCopyDetailForm(copyNationality: NationalityView) {
        this.setCopyNationalityProperty(copyNationality);
        this.openDetail();
        this.loadDetailForm(copyNationality);
    }

    validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.nationalities.length <= 0) {
            return true;
        }

        if (this.nationalityDetailComponent && this.isApplyBtnClicked) {
            return this.nationalityDetailComponent.isValidForm();
        }

        if (this.newNationalityDetail && this.isApplyBtnClicked) {
            return this.newNationalityDetail.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.nationalityDetailComponent) {
            return this.nationalityDetailComponent.getErrorMessageForm();
        }

        if (this.newNationalityDetail) {
            return this.newNationalityDetail.getErrorMessageForm();
        }
    }
}
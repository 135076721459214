import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OrderProductServiceFilterSearchCommandModel, OrderProductServiceSearchCommandModel, OrderProductServiceSearchModel } from "../../models/product-model/order-product-service-model";
import { OperatingProductServiceViewModel } from "../../models/product-model/operating-product-model/operating-product-service-view.model";

@Injectable({
    providedIn: 'root'
})
export class OrderProductService extends ApiServiceBase {
    private readonly PATH = "/schedule/orderproductservices"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public search(searchCommand: OrderProductServiceSearchCommandModel): Observable<OrderProductServiceSearchModel[]> {
        return this.httpPost<OrderProductServiceSearchModel[]>(this.PATH + '/search', searchCommand)
            .pipe(
                map(res => res.body)
            )
    }

    public searchFilter(searchCommand: OrderProductServiceFilterSearchCommandModel): Observable<OrderProductServiceSearchModel[]> {
        return this.httpPost<OrderProductServiceSearchModel[]>(this.PATH + '/search/filters', searchCommand)
            .pipe(
                map(res => res.body)
            )
    }

    public searchFilterByUserId(): Observable<OrderProductServiceSearchModel[]> {
        return this.httpGet<OrderProductServiceSearchModel[]>(this.PATH + '/search/filters/user', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getOperatingProductServiceInventory(rootOrderId: string, orderId: string): Observable<OperatingProductServiceViewModel[]> {
        let params = new HttpParams()
            .set('rootOrderId', rootOrderId)
            .set('orderId', orderId);
        return this.httpGet<OperatingProductServiceViewModel[]>(this.PATH + '/inventory', params)
            .pipe(
                map(res => res.body)
            )
    }

    public updateOperatingProductOrderStatus(rootOrderId: string, orderId: string, statusCode: string): Observable<OperatingProductServiceViewModel[]> {
        return this.httpPost<OperatingProductServiceViewModel[]>(`${this.PATH}/inventory/status?rootOrderId=${rootOrderId}&orderId=${orderId}&statusCode=${statusCode}`, null)
            .pipe(
                map(res => res.body)
            )
    }
}

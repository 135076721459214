import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { ScheduleQueueService } from "src/app/core/services/schedule-services";
import { ScheduleQueueGridService } from "../shared/schedule-queue-grid-service";
import { ScheduleQueueMapperService } from "../shared/schedule-queue-mapper.service";

@Component({
    selector: 'op-schedule-queue-reaccom-reacc-child-table',
    templateUrl: './schedule-queue-reaccom-reacc-child-table.component.html'
})
export class ScheduleQueueReaccomReaccChildTableComponent implements OnInit {
    private readonly ACTION_CODE_REACC = "INVOLREACCSERVICE"
    public scheduleQueueSearchData: any;
    @Input() parentScheduleQueueId: string;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private scheduleQueueService: ScheduleQueueService,
        private scheduleQueueMapperService: ScheduleQueueMapperService,
        private scheduleQueueGridService: ScheduleQueueGridService) {
    }

    ngOnInit(): void {
        this.getData();
    }


    private getData() {
        if (this.parentScheduleQueueId) {
            let condition = this.createCondition();
            this.scheduleQueueService.getByCondition(condition)
                .subscribe(
                    (responses: ScheduleQueueSearchModel[]) => {
                        this.bindData(responses);
                    }
                )
        }
    }

    private bindData(tasks: ScheduleQueueSearchModel[]) {
        if (!tasks?.length) {
            return;
        }
        this.scheduleQueueSearchData = this.scheduleQueueMapperService.bindData(tasks);
        this.changeDetectorRef.detectChanges();
    }

    private createCondition(): ScheduleQueueSearchModel {
        let condition = {} as ScheduleQueueSearchModel;
        condition.parentScheduleQueueId = this.parentScheduleQueueId;
        condition.scheduleQueueActionCode = this.ACTION_CODE_REACC;
        return condition;
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == 'result') {
            this.scheduleQueueGridService.setResultCellColor(e)
        }
    }
}
export const OrderDisplayNoAlarm = 0
export const OrderDisplayBlueAlarm = 1
export const OrderDisplayRedAlarm = 2

export type OrderDisplayAlarmType =
    typeof OrderDisplayNoAlarm |
    typeof OrderDisplayBlueAlarm |
    typeof OrderDisplayRedAlarm

export interface OrderSearchView {
    no: number
    orderId: string
    orderNumberDisplay: string
    bookingReference: string
    organisationCallName: string
    lastName: string
    firstName: string
    individualTitleCode: string
    groupName: string
    operatingProviderCode: string
    operatingProductNumber: string
    orderProductName: string
    serviceDateTime: string
    departureLocationCode: string
    arrivalLocationCode: string
    orderSalesStatusName: string
    orderDeliveryStatusName: string
    numberOfUnits: number
    orderTypeName: string
    salesCurrencyName: string
    salesAmount: number
    productCategoryCode: string
    productGroupCode: string
    productTypeCode: string
    serviceCategoryCode: string
    salesBucketCode: string
    marketingProviderCode: string
    marketingProductNumber: string
    orderPaymentStatusName: string
    orderBookingStatusName: string
    salesCurrencyCode: string
    paymentAmount: number
    orderDateTime: string
    orderByName: string
    outstanding: number
    displayGroupName: boolean
    displayProductName: boolean
    displayAlarmAs: OrderDisplayAlarmType
    displayOrderLock: boolean
}


import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DomainAttributeService, IndividualAgeGroupTypeReferenceService, LocationReferenceService, MembershipTypeReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from "src/app/core/services/airline-services";
import { CountryReferenceService } from "src/app/core/services/airline-services/country-reference.service";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { PricingService } from "src/app/core/services/pricing-services";
import { ConditionReferenceService, CurrencyReferenceService, OrganisationRoleReferenceService, OrganisationTypeReferenceService, UnitReferenceService } from "src/app/core/services/system-services";
import { IndividualSocialTypeReferenceService } from "src/app/core/services/system-services/individual-social-type-reference.service";
import { MembershipLevelService } from "src/app/core/services/system-services/membership-level.service";
import { PricePriceDimensionTypeService } from "src/app/core/services/system-services/price-price-dimension-type.service";
import { ActionService } from "src/app/core/utils/action.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PriceModel } from "../../prices/shared/models";
import { PriceHelper } from "../../prices/shared/price.helper";
import { PriceService } from "../../prices/shared/price.service";
import { PricingDetailConstant } from "../../rules/price-rule-detail/shared/pricing-detail.constant";
import { DimensionsComponent } from "./dimensions/dimensions.component";
import { AttributeChoiceMultipleOption, AttributeChoiceSingleOption } from "./dimensions/shared/options/select2-price-dimension.options";
import { GeneralComponent } from "./general/general.component";
import { LevelComponent } from "./level/level.component";

@Component({
    selector: 'op-price-dimensions-details',
    templateUrl: './details.component.html',
    providers: [ActionbarService, PriceHelper],
})
export class DetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly generalTabId = 'GENERAL';
    readonly attributesTabId = 'ATTRIBUTE';
    readonly translationTabId = 'TRANSLATION';
    readonly historyTabId = 'HISTORY';
    readonly readModeTabId = 'READ_MODE';

    isSaving = false;
    price: PriceModel;

    organisationTypeReferences$ = this.organisationTypeReferenceService
        .getByStatus('A')
        .pipe(
            map(res =>
                res.map(r => new Select2Data(r.organisationTypeCode, r.organisationTypeName))
            )
        );
    organisationReferences$ = this.organisationService
        .getByStatus('A')
        .pipe(map(res => res.map(r => new Select2Data(r.organisationId, r.organisationCallName))));
    organisationRoleReferences$ = this.organisationRoleReferenceService
        .getByCodes([])
        .pipe(
            map(res =>
                res.map(r => new Select2Data(r.organisationRoleCode, r.organisationRoleName))
            )
        );
    organisationGroupReferences$ = this.organisationGroupReferenceService
        .getOrganisationGroupReference()
        .pipe(
            map(res =>
                res.map(r => new Select2Data(r.organisationGroupCode, r.organisationGroupName))
            )
        );
    regionReferences$ = this.regionReferenceService
        .getRegionReference()
        .pipe(map(res => res.map(r => new Select2Data(r.regionCode, r.regionName))));
    organisationCountryReferences$ = this.countryReferenceService
        .getOrganisationCountries()
        .pipe(map(res => res.map(r => new Select2Data(r.countryCode, r.countryName))));
    servicaCategoryReferences$ = this.pricingService
        .getServiceCategoryReference()
        .pipe(
            map(res => res.map(r => new Select2Data(r.serviceCategoryCode, r.serviceCategoryName)))
        );
    salesBucketReferences$ = this.pricingService
        .getSalesBucketReference()
        .pipe(map(res => res.map(r => new Select2Data(r.salesBucketCode, r.salesBucketName))));
    membershipLevels$ = this.membershipLevelService
        .getByProvider()
        .pipe(
            map(res => res.map(r => new Select2Data(r.membershipLevelCode, r.membershipLevelName)))
        );
    currencyReferences$ = this.currencyReferenceService
        .getCurrencyReferences();
    domainAttributes$ = new BehaviorSubject<Select2Data[]>(null);
    attributeChoices$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    attributeChoiceOption$ = new BehaviorSubject<any[]>(null);
    priceAttributes$ = new BehaviorSubject<string[]>(new Array<string>());

    individualAgeGroupTypeReferences$ =
        this.individualAgeGroupTypeReferenceService.getIndividualAgeGroupTypeReferences();
    conditionReferences$ = this.conditionReferenceService
        .getConditionsByCodes([
            PricingDetailConstant.IS_OPERATOR_CODE,
            PricingDetailConstant.GREATER_THAN_OPERATOR_CODE,
            PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
            PricingDetailConstant.LEVEL_CODE,
        ])
        .pipe(
            startWith([
                {
                    conditionCode: PricingDetailConstant.IS_OPERATOR_CODE,
                    conditionName: 'is, are',
                },
            ])
        );
    individualSocialTypeReferences$ =
        this.individualSocialTypeReferenceService.getIndividualSocialTypeReferences();
    unitReferences$ = this.unitReferenceService.getUnitReferences();
    pricePriceDimensionTypes$ = this.pricePriceDimensionTypeService.getPricePriceDimensionTypes();

    @Input() priceId: string;
    @Output() onCancel = new EventEmitter();
    @Output() onLoadCompleted = new EventEmitter();

    @ViewChild(GeneralComponent) generalComponent: GeneralComponent;
    @ViewChild(DimensionsComponent) dimensionsComponent: DimensionsComponent;
    @ViewChild(LevelComponent) levelComponent: LevelComponent;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    defaultActionbarHandler: ActionBarHandlerModel;

    unsubscribe$ = new Subject();

    previousPage: string;
    selectedTab = this.generalTabId;

    public pagingDataViews: PagingDataView[] = [];
    public userSecurity: SecurityGroupSecurityModel;

    get currentPage(): number {
        if (!this.priceId) {
            return -1;
        }
        if (!this.pagingDataViews?.length) {
            return -1;
        }
        const index = this.pagingDataViews.map(p => p.id).indexOf(this.priceId);
        if (index < 0) {
            return -1;
        }
        return index + 1;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationService: OrganisationService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private locationReferenceService: LocationReferenceService,
        private regionReferenceService: RegionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private pricingService: PricingService,
        private currencyReferenceService: CurrencyReferenceService,
        private individualAgeGroupTypeReferenceService: IndividualAgeGroupTypeReferenceService,
        private conditionReferenceService: ConditionReferenceService,
        private individualSocialTypeReferenceService: IndividualSocialTypeReferenceService,
        private unitReferenceService: UnitReferenceService,
        private pricePriceDimensionTypeService: PricePriceDimensionTypeService,
        private domainAttributeService: DomainAttributeService,
        private priceService: PriceService,
        private priceHelper: PriceHelper,
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private loadingSpinnerService: LoadingSpinnerService,
        private focusingService: FocusingService,
        private membershipLevelService: MembershipLevelService,
        private actionService: ActionService
    ) {
        this.domainAttributeService
            .getPriceRuleAttributes('', '', '')
            .subscribe((res: DomainAttributeModel[]) => {
                let priceDimensionPriceRuleAttributeTypeReferences = res.filter(
                    r => r.dimensionFlag == true
                );
                this.domainAttributes$.next(
                    priceDimensionPriceRuleAttributeTypeReferences.map(
                        r => new Select2Data(r.attributeTypeCode, r.attributeTypeName)
                    )
                );
                this.populateChoices(priceDimensionPriceRuleAttributeTypeReferences);
                this.populateChoiceOptions(priceDimensionPriceRuleAttributeTypeReferences);
            });
    }

    ngOnInit(): void {
        const params = this.navigationService.getParams();
        this.previousPage = params.previousPage;
        this.priceId = params.priceId ?? params?.id;
        this.pagingDataViews = params?.pagingDataViews ?? [];
        this.userSecurity = params?.userSecurity;
        this.addAction();
        this.loadPrice(this.priceId);
    }

    ngAfterViewInit(): void {
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.defaultActionbarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            switch (actionId) {
                default:
                    break;
                case ACTION_STATUS.back:
                case ACTION_STATUS.cancel:
                    this.goback();
                    break;
                case ACTION_STATUS.save:
                    this.save();
                    break;
                case ACTION_STATUS.refresh:
                    this.loadPrice(this.priceId);
                    break;
                case ACTION_STATUS.new:
                    this.onNewPrice();
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private onNewPrice() {
        this.priceId = null;
        this.loadPrice(null);
    }

    private setupActionbarSecurity() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.enable(this.saveFlag);
        this.defaultActionbarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            new CancelButtonModel(),
            new RefreshButtonModel()
        );
    }

    get saveFlag(): boolean {
        return (!this.priceId && this.userSecurity?.newFlag == true) || (this.priceId && this.userSecurity?.editFlag == true);
    }

    private loadPrice(priceId: string) {
        if (this.priceId) {
            this.loadingSpinnerService.show();
            this.priceService.getById(priceId).subscribe((response: PriceModel) => {
                this.price = response;
                this.onLoadCompleted.emit();
                this.loadingSpinnerService.hide();
                this.addAction();
                this.cdr.detectChanges();
            });
        } else {
            this.price = new PriceModel();
        }
    }

    private populateChoices(res: DomainAttributeModel[]) {
        let choicesArr = new Array<Select2Data[]>();
        for (let r of res) {
            let choices = r.attributeChoices.map(
                c => new Select2Data(c.attributeChoiceId, c.attributeChoiceName)
            );
            choicesArr[r.attributeTypeCode] = choices;
        }
        this.attributeChoices$.next(choicesArr);
    }

    private populateChoiceOptions(res: DomainAttributeModel[]) {
        let optionArr = new Array<any>();
        for (let r of res) {
            optionArr[r.attributeTypeCode] = r.multipleChoiceFlag
                ? AttributeChoiceMultipleOption
                : AttributeChoiceSingleOption;
        }
        this.attributeChoiceOption$.next(optionArr);
    }

    cancel() {
        this.priceId = null;
        this.generalComponent.resetForm();
        this.dimensionsComponent.resetForm();
        this.levelComponent.resetForm();
        this.onPriceAttributesChange([]);
        this.onCancel.emit();
    }

    onPriceAttributesChange(priceAttributes: string[]) {
        this.priceAttributes$.next(priceAttributes);
    }

    save() {
        this.isSaving = true;

        let price = new PriceModel();
        price = this.generalComponent.getValues(price);
        if (!price) {
            this.isSaving = false;
            return;
        }

        price = this.dimensionsComponent.getValues(price);
        if (!price) {
            this.isSaving = false;
            return;
        }
        price = this.levelComponent.getValues(price);
        if (!price) {
            this.isSaving = false;
            return;
        }

        this.loadingSpinnerService.showSaving();
        let observableResponse = price.priceId
            ? this.priceService.saveTemplate(price)
            : this.priceService.addTemplate(price);
        observableResponse.subscribe(
            (response: PriceModel) => {
                this.priceId = response.priceId;
                this.price = response;
                this.isSaving = false;
                this.addAction();
                this.loadingSpinnerService.saveComplete();
            },
            () => {
                this.isSaving = false;
                this.loadingSpinnerService.hide();
            },
            () => {
                this.cdr.detectChanges();
            }
        );
    }

    initializeCopyMode() {
        this.resetPriceAndChildrenIds();
    }

    private resetPriceAndChildrenIds() {
        let price = Object.assign({}, this.price);
        price = this.priceHelper.resetPriceAndChildrenIds(price);
        this.price = price;
    }

    goback() {
        this.navigationService.navigate('/main/pricing/home', null, null, {
            previousPage: this.previousPage,
            userSecurity: this.userSecurity
        });
    }

    onActiveIdChange(selectedTabId: string) {
        switch (selectedTabId) {
            case this.generalTabId:
                this.focusingService.focus(this.generalComponent.focusingDirective);
                break;
            case this.attributesTabId:
                this.focusingService.focus(this.dimensionsComponent.focusingDirective);
                break;
            default:
                break;
        }
    }

    onGeneralFocus() {
        this.selectedTab = this.generalTabId;
    }

    onPriceDimensionFocus() {
        this.selectedTab = this.attributesTabId;
    }

    private addAction() {
        if (this.priceId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.priceId, this.price?.priceName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onPageChange(priceId) {
        if (!this.priceId) {
            return;
        }
        if (this.priceId == priceId) {
            return;
        }
        this.priceId = priceId;
        this.loadPrice(this.priceId);
    }
}

import * as moment from 'moment';

export const timeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'HH:mm'
    },
    timePicker24Hour: true
};

export const dateTimeOption = {
    singleDatePicker: true,
    timePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    startDate: moment().startOf('hour'),
    endDate: moment().startOf('hour').add(32, 'hour'),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD HH:mm'
    },
    timePicker24Hour:true 
};

export const dateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
    minDate: null,
    maxDate: new Date(),
}
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { select2Inherit, select2InheritMultiple } from './point-of-sales-inherit-configuration';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    AreaModel,
    OperatorModel,
    OrgTypeModel,
    LocationPointModel
} from 'src/app/core/models/merchandizing-config';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { RestrictionView } from '../../shared/views/restriction.view';

import { LocationDataService } from '../../shared/location/location-data.service';
import { OrganisationDataService } from '../../shared/organisation/organisation-data.service';

@Component({
    selector: 'op-point-of-sales-inherit',
    templateUrl: './point-of-sales-inherit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointOfSalesInheritComponent implements OnInit {

    @Input() config: any;

    //Type option
    public typeOption: any = select2Inherit;

    //Location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    public locationPointOption: any = select2Inherit;
    public locationPoints$ = new BehaviorSubject<LocationPointModel[]>(null);
    public areaTypeOption: any = select2Inherit;
    public operatorLocationOption: any = select2Inherit;
    public locationTypeOption: any = select2Inherit;
    public locationRegionOption: any = select2InheritMultiple;
    public locationCountryOption: any = select2InheritMultiple;
    public locationAirportOption: any = select2InheritMultiple;
    public locationGroupOption: any = select2InheritMultiple;
    public locationAreasOption: any = select2Inherit;
    public locationAreasOperatorOption: any = select2Inherit;

    //Organisation
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];

    public orgTypeOption: any = select2Inherit;
    public orgTypes$ = new BehaviorSubject<OrgTypeModel[]>(null);
    public orgTypeOperatorOption: any = select2Inherit;
    public orgTypeValueOption: any = select2InheritMultiple;
    public orgGroupValueOption: any = select2InheritMultiple;
    public orgRoleValueOption: any = select2InheritMultiple;
    public orgSpecificValueOption: any = select2InheritMultiple;

    public inheritForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private locationDataService: LocationDataService,
        private organisationDataService: OrganisationDataService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    ngOnInit(): void {
        this.getOrganisationData();
    }

    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    public addPointOfSalesInheritToFormGroup(productPointOfSales: RestrictionView[]) {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });

        var hierarChies = this.getHierarChy(productPointOfSales);
        for (let hierarChy of hierarChies) {
            var productPointOfSaleHierarChy = productPointOfSales.filter(x => x.hierarchyKey == hierarChy);

            let no = 0;
            var locationRestriction = productPointOfSaleHierarChy.filter(x => x.type == "location");
            var organisationRestriction = productPointOfSaleHierarChy.filter(x => x.type == "organisation");
            productPointOfSaleHierarChy.sort((a, b) => (a.no < b.no ? -1 : 1));

            for (let productRestriction of productPointOfSaleHierarChy) {
                if (productRestriction.type == "location") {
                    if (productRestriction.no != no) {
                        var locationSameLine = locationRestriction.filter(x => x.no == productRestriction.no);
                        var locationArrayValue: string[] = new Array<string>();
                        var locationArrayDBId: Select2Data[] = new Array<Select2Data>();
                        var locationAirportSelect2Datas: Select2Data[] = new Array<Select2Data>();

                        for (let location of locationSameLine) {
                            if (location.productRestrictionLocationView.regionCode) {
                                locationArrayValue.push(location.productRestrictionLocationView.regionCode);

                                let dbIdData = new Select2Data();
                                dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                                dbIdData.text = location.productRestrictionLocationView.regionCode;
                                locationArrayDBId.push(dbIdData);
                            } else if (location.productRestrictionLocationView.countryCode) {
                                locationArrayValue.push(location.productRestrictionLocationView.countryCode);

                                let dbIdData = new Select2Data();
                                dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                                dbIdData.text = location.productRestrictionLocationView.countryCode;
                                locationArrayDBId.push(dbIdData);
                            } else if (location.productRestrictionLocationView.locationId) {
                                locationArrayValue.push(location.productRestrictionLocationView.locationId);

                                let airportData = new Select2Data();
                                airportData.id = location.productRestrictionLocationView.locationId;
                                airportData.text = location.productRestrictionLocationView.locationName;
                                locationAirportSelect2Datas.push(airportData);

                                let dbIdData = new Select2Data();
                                dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                                dbIdData.text = location.productRestrictionLocationView.locationId;
                                locationArrayDBId.push(dbIdData);
                            } else if (location.productRestrictionLocationView.locationGroupId) {
                                locationArrayValue.push(location.productRestrictionLocationView.locationGroupId);

                                let dbIdData = new Select2Data();
                                dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                                dbIdData.text = location.productRestrictionLocationView.locationGroupId;
                                locationArrayDBId.push(dbIdData);
                            }
                        }
                        (<UntypedFormArray>this.inheritForm.get('inheritforms')).push(this.createFormGroupProductPointOfSales(productRestriction, locationArrayValue, locationArrayDBId));
                        let formCurrent = this.inheritforms.controls[this.inheritforms.controls.length - 1];
                        this.locationDataService.fillLocationArea(formCurrent, this.config);
                        this.locationDataService.fillLocationAreasOperatorData(formCurrent, this.config);
                        this.locationDataService.fillLocationAreasAirportData(formCurrent, productRestriction, locationAirportSelect2Datas);
                        no = productRestriction.no;
                    }
                }
                else if (productRestriction.type == "organisation") {
                    if (productRestriction.no != no) {
                        var organisationSameLine = organisationRestriction.filter(x => x.no == productRestriction.no);
                        var organisationArrayValue: string[] = new Array<string>();
                        var organisationArrayDBId: Select2Data[] = new Array<Select2Data>();

                        for (let organisation of organisationSameLine) {
                            if (organisation.productRestrictionOrganisationView.organisationTypeCode) {
                                organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationTypeCode);

                                let dbIdData = new Select2Data();
                                dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                                dbIdData.text = organisation.productRestrictionOrganisationView.organisationTypeCode;
                                organisationArrayDBId.push(dbIdData);
                            } else if (organisation.productRestrictionOrganisationView.organisationGroupCode) {
                                organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationGroupCode);

                                let dbIdData = new Select2Data();
                                dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                                dbIdData.text = organisation.productRestrictionOrganisationView.organisationGroupCode;
                                organisationArrayDBId.push(dbIdData);
                            } else if (organisation.productRestrictionOrganisationView.organisationRoleCode) {
                                organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationRoleCode);

                                let dbIdData = new Select2Data();
                                dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                                dbIdData.text = organisation.productRestrictionOrganisationView.organisationRoleCode;
                                organisationArrayDBId.push(dbIdData);
                            } else if (organisation.productRestrictionOrganisationView.organisationId) {
                                organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationId);

                                let dbIdData = new Select2Data();
                                dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                                dbIdData.text = organisation.productRestrictionOrganisationView.organisationId;
                                organisationArrayDBId.push(dbIdData);
                            }
                        }
                        (<UntypedFormArray>this.inheritForm.get('inheritforms')).push(this.createFormGroupProductPointOfSales(productRestriction, organisationArrayValue, organisationArrayDBId));
                        let formCurrent = this.inheritforms.controls[this.inheritforms.controls.length - 1];
                        this.organisationDataService.fillOrgTypeOperator(formCurrent, this.config);
                        no = productRestriction.no;
                    }
                }
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    private createFormGroupProductPointOfSales(productpointOfSales: RestrictionView,
        locationValueArray: string[] = null,
        locationArrayDBId: Select2Data[] = null) {
        let group = {
            'type': [{ value: productpointOfSales.type, disabled: true }, [Validators.required]],
            'submitted': [true, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [false, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]]
        };

        switch (productpointOfSales.type) {
            case 'location': {
                let restrictionLocation = productpointOfSales.productRestrictionLocationView;
                group['locationShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionLocationId'] = [locationArrayDBId, [Validators.nullValidator]];
                let locationAreas = '';
                if (restrictionLocation.regionCode) {
                    locationAreas = 'region';
                    group['locationAreasValueRegion'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.countryCode) {
                    locationAreas = 'country';
                    group['locationAreasValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueCountry'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.locationId) {
                    locationAreas = 'airport';
                    group['locationAreasValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirport'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionLocation.locationGroupId) {
                    locationAreas = 'locationgroup';
                    group['locationAreasValueRegion'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueCountry'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirport'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                }
                group['locationAreas'] = [{ value: locationAreas, disabled: true }, [Validators.nullValidator]];
                group['locationAreasData'] = new BehaviorSubject<AreaModel[]>(null);
                group['locationAreasOperators'] = [{ value: (restrictionLocation.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['locationAreasOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                break;
            }
            case 'organisation': {
                let restrictionOrganisation = productpointOfSales.productRestrictionOrganisationView;
                group['organisationShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionOrganisationId'] = [locationArrayDBId, [Validators.nullValidator]];
                let orgType = '';
                if (restrictionOrganisation.organisationTypeCode) {
                    orgType = 'type';
                    group['orgTypeValueType'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueRole'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueSpecific'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionOrganisation.organisationGroupCode) {
                    orgType = 'group';
                    group['orgTypeValueType'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueGroup'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueRole'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueSpecific'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionOrganisation.organisationRoleCode) {
                    orgType = 'role';
                    group['orgTypeValueType'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueRole'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueSpecific'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                } else if (restrictionOrganisation.organisationId) {
                    orgType = 'specific';
                    group['orgTypeValueType'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueGroup'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueRole'] = [{ value: null, disabled: true }, [Validators.nullValidator]];
                    group['orgTypeValueSpecific'] = [{ value: locationValueArray, disabled: true }, [Validators.nullValidator]];
                }
                group['orgType'] = [{ value: orgType, disabled: true }, [Validators.nullValidator]];
                group['orgTypeOperators'] = [{ value: (restrictionOrganisation.excludeFlag) ? "!=" : "=", disabled: true }, [Validators.nullValidator]];
                group['orgTypeOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                break;
            }
        }

        let f = this.fb.group(group);
        return f;
    }

    private getOrganisationData() {
        var filterOrganisation = this.config.types.filter(x => x.typeCode == 'organisation');
        if (filterOrganisation.length != 0) {
            this.orgTypes$.next(filterOrganisation[0].orgType);
        }
    }

    private getHierarChy(productPointOfSales: RestrictionView[]) {
        var hierarchyArray = productPointOfSales.map(dat => ({ hierarchyKey: dat.hierarchyKey }));
        return Array.from(new Set(hierarchyArray.map((item: any) => item.hierarchyKey)));
    }

    public clearForm() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }
}
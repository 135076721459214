import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProductCategory } from '../product-categories/product-category';

@Component({
    selector: 'op-product-search',
    templateUrl: './product-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSearchComponent {
    @Input() selectedProductCategory: ProductCategory
    @Input() searchFilterId: string;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @ViewChild("merchandizeSearch") merchandizeSearch: any;
    @ViewChild("transportSearch") transportSearch: any;
    @ViewChild("voucherSearch") voucherSearch: any;

    ngOnInit(): void {
    }

    public onCancelRequested() {
        this.cancelRequest.emit();
    }

    public alertBarClicked() {      
        switch (this.selectedProductCategory.code) {
            case 'MERCHANDIZE':
                this.merchandizeSearch.onAlertNotifyClicked();
                break;
            case 'TRANSPORT':
                this.transportSearch.onAlertNotifyClicked();
                break;
            case 'VOUCHER':
                this.voucherSearch.onAlertNotifyClicked();
                break;
            default:
                break;
        }
    }
}
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { DxDataGridComponent } from "devextreme-angular";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CsvExportService, PdfExportService, PrintExportService } from "src/app/core/utils/exports";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { ConfigurationView } from "../shared/configuration.view";

@Component({
    selector: 'op-vehicle-composition-configuration-list',
    templateUrl: './configuration-list.component.html',
    providers: []
})
export class ConfigurationListComponent implements AfterViewInit {
    public readonly EXPORT_FILE_NAME = "ConfigurationSearchResult";
    @Input() id: string;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() configurationViews: ConfigurationView[] = new Array();
    @Output() new = new EventEmitter();
    @Output() edit = new EventEmitter<ConfigurationView>();
    @Output() copy = new EventEmitter<ConfigurationView>();
    @Output() delete = new EventEmitter<ConfigurationView>();
    @Output() rowSelected = new EventEmitter<ConfigurationView>();
    @Output() refresh = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    public focusing: boolean = false;
    public selectedItem: ConfigurationView;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    onNew() {
        this.new.emit();
    }

    onEdit() {
        if (this.selectedItem) {
            this.edit.emit(this.selectedItem);
        }
    }

    onDelete() {
        if (this.selectedItem) {
            this.delete.emit(this.selectedItem);
        }
    }

    onCopy() {
        if (this.selectedItem) {
            this.copy.emit(this.selectedItem);
        }
    }

    onRefresh() {
        this.refresh.emit();
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
        this.rowSelected.emit(this.selectedItem);
    }

    updateStatus(e) {
        this.selectedItem.statusCode = e;
    }

    private mapData(data: any, no: number): any {
        return {
            Code: data.vehicleConfigurationCode,
            Name: data.vehicleConfigurationName,
            Sequence: data.sequence,
            Status: this.getStatusName(data.statusCode),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences.find((item) => item.statusCode == statusCode)
            ?.displayName;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

}
import { Injectable } from "@angular/core";
import { OrganisationModel } from "src/app/core/models/organisation-model/organisation.model";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { OrganisationSearchTableView } from "./organisation-search-table-view";
import { 
    OrganisationTypeReferenceModel,
    OrganisationGroupReferenceModel, 
    OrganisationRoleReferenceModel 
} from "src/app/core/models/organisation-model";

@Injectable()
export class OrganisationSearchMapperService {
    private readonly OWNER_TYPE_CODE = "OWNER"

    public organisationSearchTableViews(models: OrganisationModel[],pagingView: PagingDataView[]) : OrganisationSearchTableView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.productTypeReferenceSearchTableView(i, model));
            pagingView.push(this.paggingView(i,model));
        }
        
        return views;
    }

    public productTypeReferenceSearchTableView(index: number, model: OrganisationModel) : OrganisationSearchTableView {   
        let view: OrganisationSearchTableView = {
            no: index,
            code: model.organisationCode,
            name: model.organisationCallName,
            type: model.organisationTypeName,
            status: model.statusCode,
            commitBy: model.commitBy,
            commitFirstname: model.commitFirstname,
            commitDateTime: model.commitDateTime,
            id: model.organisationId,
            usageTypeCode: model.usageTypeCode,
            usageTypeName: model.usageTypeName,
            commitByName: model.commitByName,
            commitLastname: null,
            rootOrganisationId: null,
            isOwnerFilter: model.isOwnerFilter
        }
        
        return view;
    }

    public paggingView(index: number, model:OrganisationModel) : PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = model.organisationId;
        pview.name = model.organisationCallName;
        return pview;
    }

    public organisationTypeReferenceSelect2Data(organisationTypeRefs: OrganisationTypeReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationTypeRef of organisationTypeRefs) {

            let data = new Select2Data();
            data.id = organisationTypeRef.organisationTypeCode
            data.text = organisationTypeRef.organisationTypeName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationGroupReferenceSelect2Data(organisationTypeGroupRefs: OrganisationGroupReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationGroupRef of organisationTypeGroupRefs) {
            let data = new Select2Data();
            data.id = organisationGroupRef.organisationGroupCode
            data.text = organisationGroupRef.organisationGroupName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationRoleReferenceSelect2Data(organisationRoleRefs: OrganisationRoleReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationRoleRef of organisationRoleRefs) {
            let data = new Select2Data();
            data.id = organisationRoleRef.organisationRoleCode
            data.text = organisationRoleRef.organisationRoleName;
            select2Data.push(data);
        }
        return select2Data;
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeTypeReferenceService, StatusReferenceService } from 'src/app/core/services/system-services';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { AttributeSearchTableComponent } from './attribute-search-table/attribute-search-table.component';
import { AttributeSearchConditionView } from '../shared/view/attribute-search-condition.view';
import { AttributeSearchTableView } from '../shared/view/attribute-search-table.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CopyButtonModel, NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AttributeSearchConditionComponent } from './attribute-search-condition/attribute-search-condition.component';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { AttributeSearchModel } from 'src/app/core/models/attribute-type-reference-model';

@Component({
    selector: 'op-attribute-search',
    templateUrl: './attribute-search.component.html'
})
export class AttributeSearchComponent implements OnInit, AfterViewInit {
    private readonly ACTIVE_STATUS = 'A';

    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() searchCondition: AttributeSearchConditionView = null;
    @Input() executeFlag = true;
    @Output() cancelRequest = new EventEmitter();

    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public paggingView: PagingDataView[];
    public attributeTableData$ = new BehaviorSubject<AttributeSearchTableView[]>(null);
    private unsubscribe$ = new Subject();

    private actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new RefreshButtonModel()
    )

    @ViewChild(AttributeSearchTableComponent) attributeResultTable: AttributeSearchTableComponent;
    @ViewChild(AttributeSearchConditionComponent) attributeConditionComponent: AttributeSearchConditionComponent;

    constructor(private ref: ChangeDetectorRef,
        private statusReferenceService: StatusReferenceService,
        private attributeTypeReferenceService: AttributeTypeReferenceService,
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private actionService: ActionService) { }
    
    ngOnInit() {
        this.getStatusReference();
        this.addAction();
    }

    ngAfterViewInit(): void {
        if (this.executeFlag == true) {
            this.setUpSearch();
        }
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    private setUpSearch() {
        if (this.searchCondition) {
            this.attributeConditionComponent.fillViewToForm(this.searchCondition);
        } else {
            this.searchAttribute(this.getDefaultCondition());
        }
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses); 
                }
            )
    }

    public searchAttribute(condition: AttributeSearchConditionView) {
        this.searchCondition = condition;
        var code = condition?.code ?? "";
        var name = condition?.name ?? "";
        var domain = condition?.domainCode ?? "";
        var mapping = condition?.mapping ?? "";
        var category = condition?.categoryCode ?? "";
        var group = condition?.groupCode ?? "";
        var type = condition?.typeCode ?? "";
        var status = condition?.statusCode ?? "";
        var dynamicDataArray = new Array();
        this.attributeResultTable.cleanDatatable();

        this.attributeTypeReferenceService.searchAttributes(code, name, domain, mapping, category, group, type, status)
        .subscribe(
            (responses: AttributeSearchModel[]) => {
            this.paggingView = new Array();
            var index = 1;
            responses.forEach(attribute => {
                this.addPaggingView(index, attribute.attributeCode);
                var attributeTemp  = {};
                attributeTemp["no"] = index;
                attributeTemp["attributeCode"] = attribute.attributeCode;
                attributeTemp["attributeName"] = attribute.attributeName;
                attributeTemp["dimensionType"] = attribute.dimensionType;
                attributeTemp["showOnNew"] = attribute.showOnNew;
                attributeTemp["requiredAttribute"] = attribute.requiredAttribute;
                attributeTemp["sortSequence"] = attribute.sortSequence;
                attributeTemp["statusCode"] = attribute.statusCode;
                attributeTemp["commitBy"] = attribute.commitByName;
                attributeTemp["commitDateTime"] = attribute.commitDateTime;
                dynamicDataArray.push(attributeTemp);
                index += 1;
            });
            
            this.attributeTableData$.next(dynamicDataArray);
            this.ref.detectChanges();
            }
        );
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    private getDefaultCondition(): AttributeSearchConditionView {
        let condition = new AttributeSearchConditionView();
        condition.statusCode = this.ACTIVE_STATUS;
        return condition;
    }

    public onClearCondition() {
        this.attributeTableData$.next(null);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.attributeResultTable.newItem();
                break;
            case ACTION_STATUS.copy:
                this.attributeResultTable.copyAttribute();
                break;
        }
    }

    editItem(editItem: AttributeSearchTableView) {
        if (editItem) {
            let param = {
                id: editItem.attributeCode,
                attributeName: editItem.attributeName,
                newFlag: false,
                userSecurity: this.userSecurity,
                paggingView: this.paggingView,
                currentIndex: editItem.no,
                searchCondition: this.searchCondition
            }
            this.navigationService.navigate('/main/settings/attribute/details', null, null, param);
        }
    }

    newItem() {
        let param = {
            id: null,
            attributeName: null,
            newFlag: true,
            userSecurity: this.userSecurity,
            paggingView: [],
            searchCondition: this.searchCondition
        }
        this.navigationService.navigate('/main/settings/attribute/details', null, null, param);
    }

    addAction() {
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/ATTRIBUTE', null, null);
    }

}

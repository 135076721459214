import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, SimpleChange, OnChanges } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    select2Attribute, select2AttributeInherit, select2ChoiceMultiple
} from '../../../shared/configuration/select2-inventory-attribute-configuration';

declare var $: any;

@Component({
    selector: 'op-inventory-attribute',
    templateUrl: './inventory-attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryAttributeComponent implements OnChanges {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() classIcon: string;
    @Input() productAttributeValue: Select2Data[];

    public attributeOption: any = select2Attribute;
    public productAttributeInventoryValue: any;
    public productAttributeType: Select2Data[];
    public disabled: boolean = false;
    public isInherit: boolean = false;
    public attributeInheritOption: any = select2AttributeInherit;
    public choiceMultipleOption: any = select2ChoiceMultiple;
    public focusing: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (changes['productAttributeValue']) {
            if (!this.disabled) {
                this.productAttributeType = this.productAttributeValue;
            }
        }
    }

    disabledData(value: boolean) {
        this.productAttributeType = this.productAttributeValue;
        this.disabled = value;
        this.changeDetectorRef.detectChanges();
    }
}
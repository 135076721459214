import { Injectable } from '@angular/core';
import { ApiServiceBase } from '../../base/api-service-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { CounterSalesPointOfSaleModel, CounterSalesSearchModel, CounterSalsesSearchCommandModel } from '../../models/counter-sales-model';

@Injectable({
    providedIn: 'root'
})
export class CounterSaleService extends ApiServiceBase {

    private readonly PATH: string = "/order"
    constructor(httpClient: HttpClient,
        authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getCounterSalesPointOfSale(): Observable<CounterSalesPointOfSaleModel[]> {
        return this.httpGet<CounterSalesPointOfSaleModel[]>(this.PATH + "/countersales", null)
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    }

    public searchCounterSalesResult(searchCommand: CounterSalsesSearchCommandModel): Observable<CounterSalesSearchModel[]> {
        return this.httpPost<CounterSalesSearchModel[]>(this.PATH + "/countersales/search", searchCommand)
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    }
}
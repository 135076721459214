import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../../authentication/auth.service';
import { OperatingProductRouteViewModel, OperatingProductSalesBucketViewModel, OperatingProductSearchCommandModel, OperatingProductSearchDetailViewModel, OperatingProductSearchViewModel, OperatingProductSeatMapOccupiedViewModel, OperatingProductSeatMapPropertyViewModel, OperatingProductSeatMapViewModel, OperatingProductVerificationModel, PassengerDetailViewModel, PassengerSpecialServiceViewModel, PassengerViewModel, ScheduleServiceCategoryViewModel } from '../../models/product-model/operating-product-model';
import { OperatingProductViewModel } from '../../models/product-model/operating-product-model/operating-product-view.model';
import { OperatingProductCommandModel } from '../../models/product-model/operating-product-model/operating-product-command.model';
import { OperatingProductServiceViewModel } from '../../models/product-model/operating-product-model/operating-product-service-view.model';
import { OperatingProductServiceCategoryViewModel } from '../../models/product-model/operating-product-model/operating-product-service-category-view.model';
import { TransportEditCommandModel } from '../../models/product-model/transport-model';

@Injectable({
    providedIn: 'root'
})
export class OperatingProductService extends ApiServiceBase {
    private readonly PATH = "/schedule/operatingproducts"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public search(searchCommand: OperatingProductSearchCommandModel): Observable<OperatingProductSearchViewModel[]> {
        return this.httpPost<OperatingProductSearchViewModel[]>(this.PATH + '/search', searchCommand)
            .pipe(
                map(res => res.body)
            )
    }

    public getSearchDetail(parentProductId: string, operatingProductSeriesId: string): Observable<OperatingProductSearchDetailViewModel[]> {
        return this.httpGet<OperatingProductSearchDetailViewModel[]>(this.PATH + '/details/' + parentProductId + '/' + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public get(productId: string, operatingProductSeriesId: string): Observable<OperatingProductViewModel> {
        return this.httpGet<OperatingProductViewModel>(this.PATH + '/' + productId + '/' + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public edit(model: OperatingProductCommandModel) {
        return this.httpPut(this.PATH, model);
    }

    public getPassenger(productId: string, operatingProductSeriesId: string): Observable<PassengerViewModel[]> {
        return this.httpGet<PassengerViewModel[]>(this.PATH + '/' + productId +
            '/operating-product-series/' + operatingProductSeriesId +
            '/passengers', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPassengerDetail(individualId: string, productId: string): Observable<PassengerDetailViewModel[]> {
        return this.httpGet<PassengerDetailViewModel[]>(this.PATH + '/passengerdetails/' + individualId + '/' + productId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getService(productId: string, operatingProductSeriesId: string): Observable<OperatingProductServiceViewModel[]> {
        return this.httpGet<OperatingProductServiceViewModel[]>(this.PATH + '/services/' + productId + '/' + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPassengerService(productId: string, operatingProductSeriesId: string, orderNumber: string = null): Observable<PassengerSpecialServiceViewModel[]> {
        let params = new HttpParams()
        .set('orderNumber', orderNumber)
        if (!orderNumber) {
            params = null;
        }
        return this.httpGet<PassengerSpecialServiceViewModel[]>(this.PATH + '/passengerservices/' + productId + '/' + operatingProductSeriesId, params)
            .pipe(
                map(res => res.body)
            )
    }

    public getServiceCategory(productId: string, operatingProductSeriesId: string): Observable<OperatingProductServiceCategoryViewModel[]> {
        return this.httpGet<OperatingProductServiceCategoryViewModel[]>(this.PATH + '/servicecategories/' + productId + '/' + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getSalesBucket(productId: string, operatingProductSeriesId: string): Observable<OperatingProductSalesBucketViewModel[]> {
        return this.httpGet<OperatingProductSalesBucketViewModel[]>(this.PATH + '/salesbuckets/' + productId + '/' + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getScheduleServiceCategory(productId: string, operatingProductSeriesId: string): Observable<ScheduleServiceCategoryViewModel[]> {
        return this.httpGet<ScheduleServiceCategoryViewModel[]>(this.PATH + '/' + productId +
            '/operating-product-series/' + operatingProductSeriesId +
            '/service-categories', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getSeatMap(productId: string, serviceCategoryCode: string): Observable<OperatingProductSeatMapViewModel[]> {
        let params = new HttpParams()
            .set('passengerFlag', true)
        return this.httpGet<OperatingProductSeatMapViewModel[]>(this.PATH + '/' + productId +
            '/service-category/' + serviceCategoryCode +
            '/seatmaps', params)
            .pipe(
                map(res => res.body)
            )
    }

    public getSeatMapProperty(productId: string, serviceCategoryCode: string): Observable<OperatingProductSeatMapPropertyViewModel[]> {
        let params = new HttpParams()
            .set('propertyFlag', true)

        return this.httpGet<OperatingProductSeatMapPropertyViewModel[]>(this.PATH + '/' + productId +
            '/service-category/' + serviceCategoryCode +
            '/seatmaps', params)
            .pipe(
                map(res => res.body)
            )
    }

    public getSeatMapOccupied(productId: string, serviceCategoryCode: string): Observable<OperatingProductSeatMapOccupiedViewModel[]> {
        let params = new HttpParams()
            .set('occupiedFlag', true)

        return this.httpGet<OperatingProductSeatMapOccupiedViewModel[]>(this.PATH + '/' + productId +
            '/service-category/' + serviceCategoryCode +
            '/seatmaps', params)
            .pipe(
                map(res => res.body)
            )
    }

    public getVerifyResult(productId: string): Observable<OperatingProductVerificationModel> {
        return this.httpGet<OperatingProductVerificationModel>(this.PATH + '/verification/' + productId, null)
            .pipe(
                map(res => res.body)
            )
    }

    public validate(model: OperatingProductCommandModel): Observable<OperatingProductVerificationModel>  {
        return this.httpPost<OperatingProductVerificationModel>(this.PATH + '/verification/status', model)
        .pipe(
            map(res => res.body)
        )
    }

    public getVerifyResultCommand(command: TransportEditCommandModel): Observable<OperatingProductVerificationModel> {
        return this.httpPost<OperatingProductVerificationModel>(this.PATH + '/verification/transport', command)
            .pipe(
                map(res => res.body)
            )
    } 
    
    public getRoute(operatingProductSeriesId: string): Observable<OperatingProductRouteViewModel[]> {
        return this.httpGet<OperatingProductRouteViewModel[]>(this.PATH + '/route/'  + operatingProductSeriesId, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { 
    ChangeDetectionStrategy, 
    ChangeDetectorRef, 
    Component, 
    ElementRef, 
    EventEmitter, 
    Input, 
    OnInit, 
    Output, 
    SimpleChanges, 
    ViewChild 
} from '@angular/core';

import { UntypedFormControl, NgForm } from '@angular/forms';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Store } from '@ngrx/store';
import { selectAuthState } from 'src/app/store/auth/auth.selectors';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { USER_INFORMATION, USER_LOGON_NAME } from 'src/app/modules/auth/shared/constants/auth.constant';
import { EmailCommandModel, EmailView, EmailContentCommandModel, EmailFileCommandModel } from './shared';
import { EmailService } from '../../services/system-services/email.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { documentDeliveryList, exportFormatList, select2ExportFormatOption } from './shared/email-configuration';
import { OrganisationService } from '../../services/organisation-services';
import { OrganisationLanguageModel, OrganisationModel } from '../../models/organisation-model';
import { CommunicationOtherModel } from '../../models/reference-model/reference-general-model/communication-other.model';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { CommunicationOtherService } from '../../services/system-services';
import { cloneDeep } from 'lodash';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'op-email',
    templateUrl: './email.component.html'
})
export class EmailComponent implements OnInit {
    get isAllowEdit(): boolean {
        return true;
    }

    get isAllowSend(): boolean {
        if (!this.from) {
            return true;
        }
        return false;
    }

    colorControl = new UntypedFormControl('primary');
    @Input() id: string;
    @Input() from: string = "";
    @Input() fromName: string;
    @Input() subject: string;
    @Input() fromFeature: string;
    @Input() file: EmailFileCommandModel[];
    @Input() otherParameters: any;
    @Input() pdfOnlyMode: boolean = false;
    @Input() printMode: boolean = false;
    @Input() withPageBorder: boolean = false;
    @Input() clearMode: boolean = true;
    @Input() sendEmailNewProcess: boolean = false;
    @Input() maxMessage: number = 450;

    @Output() onCancelPanel = new EventEmitter();
    @Output() onSendEmail = new EventEmitter();

    @Input() fromOrderFlag: boolean = false;
    @Input() closeMode: boolean = false;
    @Input() languageCode: string;
    @Input() languageFlag: boolean = true;
    @Output() onLanguageChange = new EventEmitter();

    public readonly classNameErrorEmail = "mat-form-field-flex-error";
    public readonly classNameEmail = ".from-chip-input-email .mat-form-field-flex";
    public readonly classNameEmailCc = ".from-chip-input-email-cc .mat-form-field-flex";
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    readonly SPINNER_NAME: string = "emailSpinner";
    readonly EMAIL = "EMAIL";
    readonly EMAIL_CC = "EMAIL_CC";
    public readonly CASH_BOOK_FEATURE_CODE = "Cashbook";
    readonly DOCUMENT_SENDER = "DOCUMENTSENDER";

    public emailView: EmailView;
    public focusing: boolean = false;
    public addOnBlur = true;
    public emails: string[] = [];
    public emailsCs: string[] = [];
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public userAccountId: string;
    public processing: boolean = false;
    public emailError: boolean = false;
    public emailsCsError: boolean = false;
    public exportFormatList: any;
    public exportFormatOption: any;

    public documentDeliveryList;
    public emailDocumentCode = "EMAIL";

    public languageSelectData: Select2Data[];
   

    @ViewChild('emailForm') emailForm: NgForm;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    emailLists: CommunicationOtherModel[];
    emailCtrl = new UntypedFormControl();
    emailCcCtrl = new UntypedFormControl();
    emailInput: ElementRef<HTMLInputElement>;
    emailCcInput: ElementRef<HTMLInputElement>;
    visible = true;
    selectable = true;
    removable = true;
    filterEmails: Observable<CommunicationOtherModel[]>;
    filterEmailCcs: Observable<CommunicationOtherModel[]>;
    @Input() emailByOrderHeaderLists: CommunicationOtherModel[];
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('autoCc', { static: false }) matCcAutocomplete: MatAutocomplete;

    public languages: OrganisationLanguageModel[];

    constructor(public focusingService: FocusingService,
        private authService: AuthService,
        private store: Store<any>,
        public smailService: EmailService,
        private organisationService: OrganisationService,
        private changeDetection: ChangeDetectorRef,
        public loadingSpinner: LoadingSpinnerService,
        private communicationOther: CommunicationOtherService) {
        this.exportFormatOption = select2ExportFormatOption;
        this.exportFormatList = exportFormatList;
        this.documentDeliveryList = cloneDeep(documentDeliveryList);

        this.getAllEmailSelectList();

        this.filterEmails = this.emailCtrl.valueChanges.pipe(
            startWith(null),
            map((value: string | null) => (value ? this._filterForEmail(value, this.EMAIL) : this.emailByOrderHeaderLists?.slice())),
        );

        this.filterEmailCcs = this.emailCcCtrl.valueChanges.pipe(
            startWith(null),
            map((value: string | null) => (value ? this._filterForEmail(value, this.EMAIL_CC) : this.emailByOrderHeaderLists?.slice())),
          );

        this.getLanguageFromOrganisationOwner();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes["subject"] && this.subject) {
            this.emailView = this.mapData();
        }
    }

    ngOnInit(): void {
        this.emailView = this.mapData();
    }

    getAllEmailSelectList() {
        this.communicationOther.getAllEmails()
        .subscribe(
            (result: CommunicationOtherModel[]) => {
                this.emailLists = result;
            },
        )       
    }

    getOrganisation(id: string) {
        this.organisationService.searchOrganisationById(id)
            .subscribe(
                (responses: OrganisationModel) => {
                    let primaryOrganisation = responses.organisationCommunicationOthers.filter(x => x.primaryFlag && x.communicationOtherTypeCode == this.EMAIL);
                    if (primaryOrganisation.length > 0) {
                        this.setDisplaySendEmail(primaryOrganisation[0].mailAddress);
                    } else {
                        primaryOrganisation = responses.organisationCommunicationOthers.filter(x => x.communicationOtherTypeCode == this.EMAIL);
                        if (primaryOrganisation.length > 0) {
                            this.setDisplaySendEmail(primaryOrganisation[0].mailAddress);
                        }
                    }
                }
            )
    }

    getLanguageFromOrganisationOwner() {
        this.organisationService.getByOrganisationOwner()
            .subscribe(
                (responses: OrganisationModel) => {
                    this.languages = responses.organisationLanguages;
                    this.changeDetection.detectChanges();
                    this.languageSelectData = responses?.organisationLanguages.map(item => new Select2Data(item.languageCode, item.languageName))
                }
            )
    }

    private setDisplaySendEmail(mailAddress) {
        this.emailView.from = mailAddress;
        this.from = mailAddress;
        this.emailView.diplayFromName = this.fromName + '<' + mailAddress + '>';
        this.changeDetection.detectChanges();
    }

    private getSessionItem(key: string) {
        return this.authService.sessionStorageGetItem(key)
    }

    private getUserInfo() {
        const userNameInfo = JSON.parse(this.getSessionItem(USER_LOGON_NAME));
        const userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION));
        this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
            .subscribe(state => {
                let firstName = state.firstName || userNameInfo.firstName;
                let lastName = state.lastName || userNameInfo.lastName;
                this.fromName = firstName + ' ' + lastName;
                this.from = state.email || userInfo.email;
                this.userAccountId = state.userAccountId || userNameInfo.userAccountId;

                if (this.fromFeature != this.CASH_BOOK_FEATURE_CODE) {
                    this.getEmailAddressFromOrganisationAttribute(userInfo.organisationId);
                    return;
                }

                this.from = state.email || userInfo.email;  
                if (!this.from) {
                    this.getOrganisation(userInfo.organisationId);
                }
            });
    }

    private getEmailAddressFromOrganisationAttribute(organisationid: string) {
        this.organisationService.searchOrganisationById(organisationid)
            .subscribe(
                (responses: OrganisationModel) => {
                    if (responses.organisationAttributes && responses.organisationAttributes.find(x => x.organisationAttributeTypeCode == this.DOCUMENT_SENDER)) {
                        let documentSender = responses.organisationAttributes.find(x => x.organisationAttributeTypeCode == this.DOCUMENT_SENDER);
                        this.from = documentSender?.attributeText;
                        this.emailView.diplayFromName = this.from;
                        this.emailView.from = this.from;
                        this.changeDetection.detectChanges();
                    }
                }
            )
    }

    private mapData(): EmailView {
        this.getUserInfo();
        let view: EmailView = {
            subject: this.subject,
            diplayFromName: this.fromName + '<' + this.from + '>',
            from: this.from,
            fromName: this.fromName,
            to: '',
            cc: '',
            message: '',
            exportFormat: 'PDF'
        };
        return view;
    }

    public onSend() {
        if (this.validateEmailForm()) {
            this.sendEmailFormat();
        } else {
            this.manageInvalidEmailForm();
        }
    }

    private validateEmailForm(): boolean {
        return  this.validateEmailList() && this.isSubjectEmpty();
    }

    private validateEmailList(): boolean {
        return this.emails && this.emails.length > 0
    }

    private isSubjectEmpty(): boolean {
        return !!this.emailView.subject;
    }

    private manageInvalidEmailForm() {
        if (this.validateEmailList()) {
            return;
        }
        this.emailError = true;
        this.addRemoveEmailClass(this.classNameEmail, "add", this.classNameErrorEmail);
    }

    private sendEmailFormat(){
        let dataSend = this.emailView;
        let command = this.mapDataEmailSender(dataSend);

        if (this.sendEmailNewProcess) {
            this.onSendEmail.emit(command);
            return;
        }

        this.loadingSpinner.show();
        this.smailService.sendEmail(command)
            .subscribe(
                (response: any) => {
                    this.onCancelPanel.emit();
                    this.loadingSpinner.hide();
                    return response;
                }
            )
    }

    private addBorderToHTMLTag(file: EmailFileCommandModel[]) {
        if (!this.withPageBorder) {
            return file;
        }

        let editFiles = file;
        
        for(let editFile of editFiles) {
            editFile.htmlContent =  "<div style='height: 1450px; width: 1000px; border: 1px solid #d3d3d3;'>" + editFile.htmlContent + "</div>" 
        }

        return editFiles;
    }

    private mapDataEmailSender(emailContent: EmailView) {
        let mailContent = this.mapDataEmailContent(emailContent);
        let clondFile = cloneDeep(this.file)
        let model: EmailCommandModel = {
            id: this.id,
            userAccountId: this.userAccountId,
            file: this.addBorderToHTMLTag(clondFile),
            fromFeature: this.fromFeature,
            mailContent: mailContent,
            formatFile: this.emailView.exportFormat,
            fileFromFeature: this.otherParameters?.fileFromFeature,
            fileName: this.otherParameters?.fileName,
            cashbookDetailCommand: this.otherParameters?.cashbookDetailCommand,
            cashbookDocumentCommand: this.otherParameters?.cashbookDocumentCommand
        };
        return model;
    }

    private mapDataEmailContent(emailContent: EmailView) {
        let mailContent: EmailContentCommandModel = {
            from: emailContent.from,
            to: (this.emails).toString(),
            body: emailContent.message,
            subject: emailContent.subject,
            cc: (this.emailsCs).toString(),
            languageCode: this.languageCode
        }
        return mailContent;
    }

    public onClear() {
        this.emails = [];
        this.emailsCs = [];
        this.emailView = this.mapData();
        this.emailError = false;
        this.emailsCsError = false;
        this.addRemoveEmailClass(this.classNameEmail, "remove", this.classNameErrorEmail);
        this.addRemoveEmailClass(this.classNameEmailCc, "remove", this.classNameErrorEmail);
    }

    public onCancel() {
        this.onCancelPanel.emit();
    }

    public onFocus() {
        this.focusingService.focus(this.focusingDirective);
    }

    public add(event: MatChipInputEvent, field: string): void {
        if (this.matAutocomplete.isOpen || this.matCcAutocomplete.isOpen) {
            event.chipInput!.clear();
            return;
        }

        const value = (event.value || '').trim();
        if (value) {
            if (field == 'emails') {
                this.addEmails(value);
            } else {
                this.addEmailsCc(value);
            }
        }
        event.chipInput!.clear();
    }

    public addEmails(value) {
        let status = "";
        if (this.emailValidation(value)) {
            this.emails.push(value);
            this.emailError = false;
            status = "remove";
        } else {
            this.emailError = true;
            status = "add";
        }
        this.addRemoveEmailClass(this.classNameEmail, status, this.classNameErrorEmail);
    }

    public addEmailsCc(value) {
        let status = "";
        if (this.emailValidation(value)) {
            this.emailsCs.push(value);
            this.emailsCsError = false;
            status = "remove";
        } else {
            this.emailsCsError = true;
            status = "add";
        }
        this.addRemoveEmailClass(this.classNameEmailCc, status, this.classNameErrorEmail);
    }

    remove(email, field): void {
        if (field == 'emails') {
            const index = this.emails.indexOf(email);
            if (index >= 0) {
                this.emails.splice(index, 1);
            }
        } else {
            const index = this.emailsCs.indexOf(email);
            if (index >= 0) {
                this.emailsCs.splice(index, 1);
            }
        }
    }

    public emailValidation(email) {
        var re = /^[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,4}$/;
        return re.test(String(email));
    }

    public addRemoveEmailClass(className, status, classNameAdd) {
        if (status == "add") {
            $(className).addClass(classNameAdd);
        } else {
            $(className).removeClass(classNameAdd);
        }
    }

    selected(event: MatAutocompleteSelectedEvent, field: string): void {
        if (field == 'emails') {
          this.emails.push(event.option.viewValue);
          this.emailCtrl.setValue(null);
        } else {
          this.emailsCs.push(event.option.viewValue);
          this.emailCcCtrl.setValue(null);
        }
        
      }
    
      private _filterForEmail(value: string, field: string): CommunicationOtherModel[] {
        const filterValue = value.toLowerCase();
    
        if (value) {
          return this.emailLists?.filter(e => e.mailAddress.toLowerCase().includes(filterValue) && this.filterSelectedEmail(e.mailAddress, field))
        }
        
        return this.emailByOrderHeaderLists.filter(e => e.mailAddress.toLowerCase().includes(filterValue) && this.filterSelectedEmail(e.mailAddress, field));
      }

      private filterSelectedEmail(mailAddress: string, field: string) {
        if (field == this.EMAIL) {
            return this.emails.includes(mailAddress) == false
        }
        else {
            return this.emailsCs.includes(mailAddress) == false
        }
      }

      onPrint() {
        const printFormSize: string = "left=10000,top=10000,width=0,height=0";
        let divElement = this.file[0].htmlContent;

        var num;
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(num, windowName, printFormSize);
        printWindow.document.write(this.addBorderToHTMLTagForPrint(divElement));
        printWindow.document.close();
        printWindow.focus();

        setTimeout(function() {
            printWindow.print();
            printWindow.close();
        }, 1000);
      }

      private addBorderToHTMLTagForPrint(divElement: string) {
        if (!this.withPageBorder) {
            return divElement;
        }
        
        return "<div style='height: 1300px; width: 1000px; border: 1px solid #d3d3d3;'>" + divElement + "</div>";
    }

    public onExportFormatChange(event: any) {
        this.emailView.exportFormat = event;
    }

    public languageChange(event: any) {
        this.onLanguageChange.emit(event);
    }
}

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { AccountRecoveryService } from 'src/app/core/services/account-recovery-services/account-recovery.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { Store } from '@ngrx/store';
import { selectChangePasswordRequest } from 'src/app/store/auth/auth.selectors';
import { takeUntil } from 'rxjs/operators';
import { PasswordRequestViewModel } from 'src/app/core/models/account-recovery-model/password-request-view.model';
import { Globals } from 'src/app/app.global-variable';
import { Helper } from 'src/app/shared/helper/app.helper';
import { TextBoxType } from 'devextreme/ui/text_box';

@Component({
    selector: 'op-change-password',
    templateUrl: './change-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

    private readonly FAILED_NOTFOUND_MESSAGE = "The password reset link was invalid.";
    private readonly FAILED_EXPIRED_MESSAGE = "This password reset link has expired.";
    private readonly FAILED_MESSAGE = "Sorry, we are unable to identify your information or user is not active";
    private readonly SUCCESS_MESSAGE = "Password has been changed.";
    private readonly CONTROL_PASSWORD: string = "password";
    private readonly CONTROL_CONFIRMPASSWORD: string = "confirmPassword";

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public errorText$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public loginForm: UntypedFormGroup;
    public disableSubmit: boolean = false;
    public saveProcess: boolean = false;
    public passwordRequired: boolean = false;
    public confirmPasswordRequired: boolean = false;
    public differencePassword: boolean = false;
    public strongPassword: boolean = true;
    public passwordRecycleError: boolean = false;

    private passwordRequestView: PasswordRequestViewModel;
    public linkInvalid: boolean = true;

    public password = null;
    public passwordMode: TextBoxType = 'password';
    public passwordButton: any = {
        icon: 'fas fa-eye',
        stylingMode: 'text',
        onClick: () => {
            this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
            this.changeDetectionRef.detectChanges();
        },
    };

    constructor(private fb: UntypedFormBuilder,
        private navigationService: NavigationService,
        private accountRecoveryService: AccountRecoveryService,
        private loadingSpinner: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<any>,
        public globals: Globals,
        private helper: Helper) {
        this.getPasswordChangeRequestId();
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            password: [null, [Validators.required, Validators.pattern(this.globals.PASSWORD_PATTERN)]],
            confirmPassword: [null]
        });
    }

    private getPasswordChangeRequestId() {
        this.store.select(selectChangePasswordRequest).pipe(takeUntil(this.destroy$)).
            subscribe(data => {
                this.validateChangePasswordRequest(data);
            });
    }

    private validateChangePasswordRequest(data) {
        if (data?.changePasswordOrgId && data?.changePasswordRequestId) {
            this.accountRecoveryService.getPasswordRequest(data?.changePasswordOrgId, data?.changePasswordRequestId)
                .subscribe(
                    (responses) => {
                        if (responses == null) {
                            this.handleGetPasswordRequestError(this.FAILED_NOTFOUND_MESSAGE, data);
                        } else {
                            this.passwordRequestView = responses;
                            this.displayInput();
                        }
                    },
                    (error) => this.handleGetPasswordRequestError(this.FAILED_EXPIRED_MESSAGE, data)
                )
        }
    }

    private displayInput() {
        this.linkInvalid = false;
        this.changeDetectionRef.detectChanges();
    }

    private handleGetPasswordRequestError(errorMessage, data) {
        data.changePasswordLinkInvalid = true;
        data.changePasswordLinkInvalidMessage = errorMessage
        this.navigationService.navigate('auth/forgot-password', 'Forgot Password', true);
    }

    public onChangePassword() {
        this.saveProcess = true;
        if (this.validation()) {
            this.passwordRequestView.password = this.loginForm.controls[this.CONTROL_PASSWORD].value;
             this.loadingSpinner.showSaving();
             this.accountRecoveryService.changePassword(this.passwordRequestView)
                 .subscribe(
                     () => {
                         this.loadingSpinner.saveComplete();
                         this.disabledForm();
                         this.displayAlertBarWarning();
                         this.changeDetectionRef.detectChanges();
                     },
                     (error) => this.handleError(error),
                     () => {
                         this.loadingSpinner.hide();
                     }
                 )
        }
    }

    private validation(): boolean {
        this.resetValidation();
        let password = this.loginForm.controls[this.CONTROL_PASSWORD].value?.trim();
        let conFirmPassword = this.loginForm.controls[this.CONTROL_CONFIRMPASSWORD].value?.trim();
        if (!password) {
            this.passwordRequired = true;
        } else if (this.loginForm.controls[this.CONTROL_PASSWORD]?.errors?.pattern) {
            this.strongPassword = false;
        }

        if (!conFirmPassword) {
            this.confirmPasswordRequired = true;
        }

        if (password != conFirmPassword && (password && conFirmPassword)) {
            this.differencePassword = true;
        }

        if (this.passwordRequired || this.confirmPasswordRequired || this.differencePassword || !this.strongPassword) {
            return false;
        }
        return true;
    }

    private resetValidation() {
        this.passwordRequired = false;
        this.confirmPasswordRequired = false;
        this.differencePassword = false;
        this.strongPassword = true;
    }

    private disabledForm() {
        this.loginForm.controls[this.CONTROL_PASSWORD].disable();
        this.loginForm.controls[this.CONTROL_CONFIRMPASSWORD].disable();
        this.disableSubmit = true;
        this.errorText$.next("");
        this.passwordRecycleError = false;
    }

    private displayAlertBarWarning() {
        this.alertBarService.showSuccess(this.SUCCESS_MESSAGE);
    }

    private handleError(error) {
        if (this.helper.getErrorReason(error) == this.globals.ERROR_PASSWORD_RECYCLE) {
            this.passwordRecycleError = true;
            this.errorText$.next("");
         } else {
            this.passwordRecycleError = false;
            this.errorText$.next(this.FAILED_MESSAGE);
         }
    }

    public gotoLogon() {
        this.navigationService.navigate('auth', 'Logon');
    }

    ngOnDestroy(): void {
        this.loadingSpinner.hide();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public validateStrongPassword() {
        if (this.loginForm.controls[this.CONTROL_PASSWORD]?.errors?.pattern) {
            this.strongPassword = false;
            this.passwordRequired = false;
        } else {
            this.strongPassword = true;
        }
    }
}
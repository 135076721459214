import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AmountModel, ExternalPaymentModel, LedgersModel, PaymentsModel, PaymentStatusModel, PaymentVoucher } from 'src/app/core/models/payment-models';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { USER_INFORMATION, USER_LOGON_NAME } from 'src/app/modules/auth/shared/constants/auth.constant';
import { PaymentConstant } from 'src/app/modules/order/shared';
import { PaymentDocumentInvoiceConstant } from 'src/app/modules/order/shared/components/payment-document-invoice/shared/payment-document-invoice.constants';
import { selectAuthState } from 'src/app/store/auth/auth.selectors';
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from 'rxjs';
import { OrderShoppingPaymentView } from 'src/app/modules/shopping/shared/views';
import { InvoiceOrganisationSearchModel, OrganisationModel } from 'src/app/core/models/organisation-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { CreditAccountOrganisationSearchView } from '../credit-account-search-table/credit-account-organisation-search.view';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model';
import { GATEWAY_PAYMENT_STATUS, PaymentGatewayFactory } from 'src/app/core/services/payment-creditcard-services';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { CurrencyReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { FormOfPaymentReferenceModel, FormOfPaymentTypeReferenceModel } from 'src/app/core/models/form-of-payment-model';
import { FormOfPaymentReferenceService } from 'src/app/core/services/system-services/form-of-payment-reference.service';
import { FormOfPaymentTypeReferenceService } from 'src/app/core/services/system-services/form-of-payment-type-reference.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { DocumentInvoiceTypeSelectionComponent } from 'src/app/core/components/document-invoice-type-selection/document-invoice-type-selection.component';
import { PaymentDocumentInvoiceComponent } from 'src/app/modules/order/shared/components/payment-document-invoice/payment-document-invoice.component';

const select2WithoutSearchOption = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    width: 'auto',
    dropdownAutoWidth: true
}

@Component({
    selector: 'op-top-up-payment',
    templateUrl: './top-up-payment.component.html'
})
export class TopUpPaymentComponent implements OnInit, AfterViewInit {
    public readonly PAYMENT_CONSTANT = PaymentConstant;
    public readonly PAYMENT_DOCUMENT_CONSTANT = PaymentDocumentInvoiceConstant;
    private readonly alertStatus: string = "Payment";
    private readonly successMessage: string = "Payment successful";
    private readonly cancelMessage: string = "Payment cancelled";
    private readonly paymentfailed: string = "Payment failed";
    public readonly select2WithoutSearchOption = select2WithoutSearchOption;
    public readonly invoiceReceiverPanelName = 'Invoice Receiver';
    public readonly topupAmountErrorMessage: string = 'Top Up Amount is required.'
    
    public focused: boolean = false;
    public organisationId: string;

    public data: OrderShoppingPaymentView;
    public formOfPaymentTypeCode: string = "CC";
    public formOfPaymentCode: string;
    public invoiceReceiverType: string;
    public topUpOrganisation: CreditAccountOrganisationSearchView;
    public paymentOrganisation: InvoiceOrganisationSearchModel;
    public paymentIndividual: InvoiceIndividualSearchModel;
    public userInfo: any;
    
    public organisationTypeCode: string[];
    public formOfPayment$ = new BehaviorSubject<FormOfPaymentReferenceModel[]>(null);
    public formOfPaymentType$ = new BehaviorSubject<Select2Data[]>(null);
    public formOfPaymentSelectData: Select2Data[];

    public voucherNumber: string;
    public voucherPin: string;
    public processing: boolean = false;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() userOrganisation: OrganisationModel;
    @Input() currencyReferences: CurrencyReferenceModel[];
    @Output() alertBarClick = new EventEmitter();
    @Output() paymentSuccess = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DocumentInvoiceTypeSelectionComponent) invoiceReceiverComponent: DocumentInvoiceTypeSelectionComponent;
    @ViewChild(PaymentDocumentInvoiceComponent) documentInvoiceComponent: PaymentDocumentInvoiceComponent;
    
    constructor(private stringUtils: StringHelperService,
        private authService: AuthService,
        private store: Store<any>,
        private focusingService: FocusingService,
        public alertBarService: AlertBarService,
        private changeDetector: ChangeDetectorRef,
        private spinner: LoadingSpinnerService,
        private paymentGatewayFactory: PaymentGatewayFactory,
        private formOfPaymentReferenceService: FormOfPaymentReferenceService,
        private formOfPaymentTypeReferenceService: FormOfPaymentTypeReferenceService) { 
            this.getFormOfPayment();
            this.getFormOfPaymentType();
        }


    ngOnInit(): void {
        this.initialPaymentView();
    }

    ngAfterViewInit(): void {
        if (this.topUpOrganisation) {
            this.fillSearchViewToForm(this.topUpOrganisation);
        }
    }

    private initialPaymentView() {
        this.data = {
            saleAmount: "0",
            saleCurrency: null,
            paymentAmount: "0",
            paymentCurrency: null
        };
    }

    get documentInvoiceSelected(): boolean {
        return this.formOfPaymentTypeCode === this.PAYMENT_DOCUMENT_CONSTANT.DOCUMENT_CODE && this.formOfPaymentCode === this.PAYMENT_DOCUMENT_CONSTANT.INVOICE_CODE;
    }

    get creditAccountIsSelected() {
        return this.formOfPaymentTypeCode === this.PAYMENT_CONSTANT.creditAccount;
    }

    public formOfPaymentTypeChange(formOfPaymentTypeCode: string | string[]) {
        this.formOfPaymentTypeCode = formOfPaymentTypeCode ? formOfPaymentTypeCode as string : null;
        this.getFormOfPaymentSelectData(this.formOfPaymentTypeCode)
        this.onInvoiceReceiverChange(null);
    }

    public formOfPaymentChange(typeCode: string | string[]) {
        this.formOfPaymentCode = typeCode ? typeCode as string : null;
    }

    public onInvoiceReceiverChange(invoiceReceiverCode: string) {
        this.clearInvoiceReceiver();
        this.invoiceReceiverType = invoiceReceiverCode;
        this.focusInvoiceReceiver(invoiceReceiverCode);
    }

    private focusInvoiceReceiver(invoiceReceiverCode: string) {
        switch(invoiceReceiverCode) {
            case this.PAYMENT_DOCUMENT_CONSTANT.INDIVIDUAL_RECEIVER:
                this.documentInvoiceComponent?.individualComponent?.focusSearchCondition();
                break;
            case this.PAYMENT_DOCUMENT_CONSTANT.ORGANISATION_RECEIVER:
                this.documentInvoiceComponent?.organisationComponent?.focusSearchCondition();
                break;
        }
    }

    private getSessionItem(key: string) {
        return this.authService.sessionStorageGetItem(key)
    }

    public onPay() {
        const userNameInfo = JSON.parse(this.getSessionItem(USER_LOGON_NAME));
        this.userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION));
        const paymentId = this.stringUtils.NewGuid();
        let commitBy = (userNameInfo.userAccountId) ? userNameInfo.userAccountId : this.getUserId();

        if (!this.validateInvoiceReceiver() || !this.validateTopUpForm()) {
            return;
        }
        let ledger = [this.createLedger(this.userInfo, paymentId)];
        let payment = this.createPayment(this.userInfo, paymentId, commitBy, ledger);
        this.processPayment(payment, this.getPaymentVoucher(payment))

    }

    private processPayment(paymentModel: PaymentsModel, paymentVoucher: PaymentVoucher) {
        this.spinner.show();
        let initialModel: ExternalPaymentModel = {
            organisationCode: this.userOrganisation.organisationCode,
            recordLocator: null,
            orderId: null,
            successQueryString: "",
            cancelQueryString: "",
            errorQueryString: "",
            amount: {
                currencyCode: paymentModel.sales.currencyCode,
                value: paymentModel.sales.amount
            },
            paymentVoucher: paymentVoucher,
            payments: paymentModel
        };

        const gatewayProvider = this.paymentGatewayFactory.create();
        gatewayProvider.processPayment(initialModel)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: PaymentStatusModel) => {
                if (!response) {
                    return;
                }
                if (paymentModel.formOfPaymentTypeCode != PaymentConstant.cc) {
                    this.showAlertSucess();
                    this.paymentSuccess.emit();
                }
                else {
                    if (response.status === GATEWAY_PAYMENT_STATUS.SUCCESS) {
                        this.showAlertSucess();
                        this.paymentSuccess.emit();
                    } else if (response.status === GATEWAY_PAYMENT_STATUS.CANCEL || response.status == null) {
                        this.alertBarService.show(this.alertStatus, this.cancelMessage);
                    } else {
                        this.alertBarService.show(this.alertStatus, this.paymentfailed);
                    }
                }
                this.changeDetector.detectChanges();
                this.spinner.hide();

            }, () => this.spinner.hide());
    }

    public showAlertSucess() {
        this.alertBarService.showInformation(this.alertStatus, this.successMessage);
        let informationIntervalId = setInterval(() => {
            this.alertBarClick.emit();
            clearInterval(informationIntervalId);
        }, 5000);
    }

    private createLedger(userInfo: any, paymentId: string): LedgersModel {
        return {
            ledgerId: this.stringUtils.NewGuid(),
            parentLedgerId: null,
            paymentId: paymentId,
            organisationId: userInfo.organisationId,
            ledgerOrganisationId: this.topUpOrganisation.organisationId,
            ledgerIndividualId: null,
            orderId: null,
            ledgerTransactionCode: this.PAYMENT_CONSTANT.cr,
            sales: this.getAmount(parseFloat(this.data.saleAmount), this.data.saleCurrency),
            payment: this.getAmount(parseFloat(this.data.saleAmount), this.data.paymentCurrency),
            exchangeRate: null
        }
    }

    private createPayment(userInfo, paymentId, commitBy, ledgersModel) {
        let paymentModel: PaymentsModel = {
            paymentId: paymentId,
            referencePaymentId: null,
            organisationId: userInfo.organisationId,
            paymentOrganisationId: null,
            paymentIndividualId: null,
            paymentAddressId: null,
            paymentStatusCode: this.getDataPaymentStatus(this.formOfPaymentTypeCode),
            formOfPaymentTypeCode: this.formOfPaymentTypeCode,
            formOfPaymentCode: this.formOfPaymentCode,
            accountableDocumentTypeCode: null,
            documentNumber: null,
            documentDate: null,
            paymentDate: null,
            paymentDateTime: null,
            issueDateTime: null,
            externalReference: null,
            transactionReference: null,
            externalTransactionId: null,
            paymentToken: null,
            paymentText: null,
            commitBy: commitBy,
            sales: this.getAmount(parseFloat(this.data.saleAmount), this.data.saleCurrency),
            payment: this.getAmount(parseFloat(this.data.saleAmount), this.data.paymentCurrency),
            ledger: ledgersModel
        };
        this.setupDocumentPayment(paymentModel);
        return paymentModel;
    }

    private getAmount(amount: number, currencyCode: string): AmountModel {
        let model: AmountModel = {
            amount: amount,
            currencyCode: currencyCode
        }
        return model;
    }

    private getUserId() {
        this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
            .subscribe(state => {
                return state.userAccountId;
            });
    }

    public focusTopUp() {
        this.focusingService.focus(this.focusingDirective);
    }

    public clearInvoiceReceiver() {
        this.paymentIndividual = null;
        this.paymentOrganisation = null;
    }

    public onInvoiceIndividualSelected(event) {
        this.paymentIndividual = event;
    }

    public onInvoiceOrganisationSelected(event) {
        this.paymentOrganisation = event;
    }

    public fillSearchViewToForm(view: CreditAccountOrganisationSearchView) {
        this.topUpOrganisation = view;
        this.data.paymentCurrency = view?.currency;
        this.data.saleCurrency = view?.currency;
        this.changeDetector.detectChanges();
    }

    private getDataPaymentStatus(formOfPaymentTypeCode: string): string {
        switch (formOfPaymentTypeCode) {
            case this.PAYMENT_CONSTANT.cc:
                return this.PAYMENT_CONSTANT.pending;
            case this.PAYMENT_DOCUMENT_CONSTANT.DOCUMENT_CODE:
                return this.PAYMENT_CONSTANT.issued;
            default:
                return this.PAYMENT_CONSTANT.paid;
        }
    }

    public setupDocumentPayment(dataPayment: PaymentsModel) {
        if (this.isInvoiceDocumentPayment(dataPayment.formOfPaymentTypeCode, dataPayment.formOfPaymentCode)) {
                switch (this.invoiceReceiverType) {
                    case this.PAYMENT_DOCUMENT_CONSTANT.INDIVIDUAL_RECEIVER:
                        dataPayment.paymentIndividualId = this.paymentIndividual.individualId;
                        dataPayment.paymentAddressId = this.paymentIndividual.addressId;
                        break;
                    case this.PAYMENT_DOCUMENT_CONSTANT.ORGANISATION_RECEIVER:
                        dataPayment.paymentOrganisationId = this.paymentOrganisation.organisationId;
                        dataPayment.paymentAddressId = this.paymentOrganisation.addressId;
                        break;
                    default:
                        break;
                }
        }
    }

    private getFormOfPayment() {
        this.formOfPaymentReferenceService.getTopUpFOPByOrganisation()
            .subscribe(
                (response: FormOfPaymentReferenceModel[]) => {
                    this.formOfPayment$.next(response);
                    this.formOfPaymentTypeChange(this.PAYMENT_CONSTANT.cc);
                    this.changeDetector.detectChanges();
                }
            )
    }

    private getFormOfPaymentType() {
        this.formOfPaymentTypeReferenceService.getTopUpFOPTypeByOrganisation()
            .subscribe(
                (response: FormOfPaymentTypeReferenceModel[]) => {
                    response = response.filter(item => item.formOfPaymentTypeCode != PaymentConstant.creditAccount)
                    this.formOfPaymentType$.next(response.map(item => new Select2Data(item.formOfPaymentTypeCode, item.formOfPaymentTypeName)));
                    this.formOfPaymentTypeChange(this.PAYMENT_CONSTANT.cc);
                    this.changeDetector.detectChanges();
                }
            )
    }

    private getFormOfPaymentSelectData(typeCode: string) {
        if (!typeCode) {
            this.formOfPaymentSelectData = null;
            return
        }
        let fopList = this.formOfPayment$?.value?.filter(item => item.formOfPaymentTypeCode == typeCode);
        this.formOfPaymentChange(fopList && fopList.length > 0 ? fopList[0]?.formOfPaymentCode : null);
        this.formOfPaymentSelectData = fopList?.map(item => new Select2Data(item.formOfPaymentCode, item.formOfPaymentName))
    }

    private isInvoiceDocumentPayment(formOfPaymentTypeCode: string, formOfPaymentCode: string) {
        return formOfPaymentTypeCode == this.PAYMENT_DOCUMENT_CONSTANT.DOCUMENT_CODE && formOfPaymentCode == this.PAYMENT_DOCUMENT_CONSTANT.INVOICE_CODE
    }

    private validateInvoiceReceiver(): boolean {
        if (!this.isInvoiceDocumentPayment(this.formOfPaymentTypeCode, this.formOfPaymentCode)) {
            return true;
        }
        if (!this.invoiceReceiverComponent.validate()) {
            this.alertBarService.show(this.alertStatus, this.invoiceReceiverComponent.getErrorMessage());
            return false;
        } else if (!(this.validateIndividualReciever() || this.validateOrganisationReceiver())) {
            this.alertBarService.show(this.invoiceReceiverPanelName, 'Invoice Receiver Information is required.');
            this.focusInvoiceReceiver(this.invoiceReceiverType);
            return false;
        }
        return true;
    }

    private validateIndividualReciever(): boolean {
        return this.invoiceReceiverType == this.PAYMENT_DOCUMENT_CONSTANT.INDIVIDUAL_RECEIVER && !!(this.paymentIndividual);
    }

    private validateOrganisationReceiver(): boolean {
        return this.invoiceReceiverType == this.PAYMENT_DOCUMENT_CONSTANT.ORGANISATION_RECEIVER && !!(this.paymentOrganisation);
    }

    get displayVoucherForm(): boolean {
        return this.formOfPaymentTypeCode == this.PAYMENT_CONSTANT.voucher && this.formOfPaymentCode == this.PAYMENT_CONSTANT.sales;
    }

    private getPaymentVoucher(dataPayment: PaymentsModel): PaymentVoucher {
        if (dataPayment.formOfPaymentTypeCode == this.PAYMENT_CONSTANT.voucher && dataPayment.formOfPaymentCode == this.PAYMENT_CONSTANT.sales) {
            return {
                paymentCode: null,
                voucherId: null,
                voucherNumber: this.voucherNumber,
                voucherPin: this.voucherPin
            }
        }
        return null;
    }

    public validateTopUpForm() {
        this.processing = true;
        if (this.validateTopupAmount()) {
            this.processing = false;
            return true;
        }
        this.alertBarService.show(this.alertStatus, this.topupAmountErrorMessage);
        return false;
    }

    public validateTopupAmount() {
        if (Number(this.data.saleAmount) > 0) {
            return true;
        }
        return false;
    }

    get displayAmountError(): boolean {
        return this.processing && !this.validateTopupAmount();
    }
}
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeService } from 'src/app/core/services/system-services/security-code.service';
import { SecurityCodeSearchTableView } from '../shared/security-code-search-table.view';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { StatusCode } from 'src/app/core/constants/status-constants';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-code-search-table',
    templateUrl: './security-code-search-table.component.html',
})
export class SecurityCodeSearchTableComponent
    extends DatatableComponentBase
    implements AfterViewInit
{
    @Input() statusReferences: StatusReferenceModel[];
    @Input() securities: SecurityGroupSecurityModel[];
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Output() onTableSearch = new EventEmitter();

    public collapsed: boolean;
    public focused: boolean;
    public securityCodeSearchData: SecurityCodeSearchTableView[];
    public rows = 0;
    public selectedItem = false;
    public itemSelected: SecurityCodeSearchTableView;
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    public customToolbarMarginTop: number;
    public exportData: any;
    public header: string[];
    public paggingView: PagingDataView[] = [];

    private readonly SEARCH_TABID = 'SEARCH';

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    constructor(
        private securityCodeService: SecurityCodeService,
        private ref: ChangeDetectorRef,
        private navigationService: NavigationService,
        private spinner: LoadingSpinnerService) {
        super();
    }

    get newButtonDisable(): boolean {
        return this.moduleSecurity?.newFlag;
    }

    get copyButtonDisable(): boolean {
        return this.moduleSecurity?.copyFlag && this.selectedItem;
    }

    get copyToClipboardFlag(): boolean {
        return this.moduleSecurity?.copyToClipboardFlag;
    }

    get exportFlag(): boolean {
        return this.moduleSecurity?.exportFlag;
    }

    get printFlag(): boolean {
        return this.moduleSecurity?.printFlag;
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    searchSecurityCode(condition: SecurityCodeSearchTableView) {
        this.spinner.show();
        var securityCode = condition?.securityCode ?? '';
        var securityName = condition?.securityName ?? '';
        var groupName = condition?.groupName ?? '';
        var userLogon = condition?.userLogon ?? '';
        var statusCode = condition?.statusCode ?? '';
        var dynamicDataArray = new Array();

        this.securityCodeService
        .searchSecurityCode(
            securityCode,
            securityName,
            groupName,
            userLogon,
            statusCode
        )
        .subscribe((response: SecurityCodeSearchTableView[]) => {
            this.cleanDatatable();
            var index = 1;
            response.forEach((item) => {
                this.addPaggingView(index, item.securityCode);
                var securityCodeTemp = {};
                securityCodeTemp['no'] = index;
                securityCodeTemp['securityCode'] = item.securityCode;
                securityCodeTemp['securityName'] = item.securityName;
                securityCodeTemp['commitBy'] = item.commitByName;
                securityCodeTemp['commitDateTime'] = item.commitDateTime;
                securityCodeTemp['statusCode'] = item.statusCode;
                dynamicDataArray.push(securityCodeTemp);
                index += 1;
            });
            this.securityCodeSearchData = dynamicDataArray;
            this.rows = this.securityCodeSearchData.length;
            this.spinner.hide();
            this.ref.detectChanges();
        });
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    public mapExportData(filteredData) {
        return filteredData.map((dat) => ({
          Code: dat.securityCode,
          Name: dat.securityName,
          CommitBy: dat.commitBy ?? null,
          CommitDateTime: dat.commitDateTime ?? null,
          Status: this.getStatusName(dat.statusCode),
        }));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReferences.filter((dat) => dat.statusCode == statusCode)[0]?.displayName;
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.ref.detectChanges();
    }

    public editSecurityCodeDetail() {
        if (this.itemSelected != null) {
            let param = {
                id: this.itemSelected.securityCode,
                newFlag: false,
                selectedTab: this.SEARCH_TABID,
                paggingView: this.paggingView,
                currentIndex: this.getCurrentIndex(this.itemSelected.securityCode),
                fromEdit: true,
                securities: this.securities,
                moduleSecurity: this.moduleSecurity
            }
            this.openSecurityCodeDetail(param);
        }
    }

    public new() {
        let param = {
            newFlag: true,
            selectedTab: this.SEARCH_TABID,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.navigationService.navigate('main/settings/security-code/details', null, false, param);
    }

    public doubleClickCode(selectedTab: string = this.SEARCH_TABID) {
        let param = {
            id: this.itemSelected.securityCode,
            newFlag: false,
            selectedTab: selectedTab,
            paggingView: this.paggingView,
            currentIndex: this.getCurrentIndex(this.itemSelected.securityCode),
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.openSecurityCodeDetail(param);
    }

    openSecurityCodeDetail(param) {
        this.navigationService.navigate('main/settings/security-code/details', null, false, param);
    }

    public refreshTable() {
        this.searchSecurityCode(null);
    }

    public copySecuityCode(selectedTab: string = this.SEARCH_TABID) {
        if (this.itemSelected) {
            let param = {
                id: this.itemSelected.securityCode,
                newFlag: true,
                copy: true,
                selectedTab: selectedTab,
                paggingView: this.paggingView,
                currentIndex: this.getCurrentIndex(this.itemSelected.securityCode),
                securities: this.securities,
                moduleSecurity: this.moduleSecurity
            }
            this.navigationService.navigate('/main/settings/security-code/details', null, false, param);
        }
    }

    public deleteSecurityCode() {
        if (this.itemSelected) {
            this.securityCodeService.changeSecurityStatus(this.itemSelected.securityCode, StatusCode.Delete)
                .subscribe(
                    () => {
                        this.onTableSearch.emit();
                    }
                )
        }
    }

    getCurrentIndex(securityCode: string) {
        return this.paggingView?.find(item => item.id == securityCode)?.no;
    }
}

export class CommunicationPhoneView {
    public no: number;
    public communicationPhoneId: string;
    public communicationPhoneTypeCode: string;
    public communicationPhoneTypeName: string;
    public organisationId: string;
    public individualId: string;
    public languageCode: string;
    public countryCode: string;
    public phoneType: string;
    public phoneNumber: string;
    public phoneNumberReverse: string;
    public extension: string;
    public commentText: string;
    public primaryPhone: boolean;
    public communicationPhonePrefix: string;
    public phoneLocked: boolean;
}
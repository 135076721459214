import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/core/authentication/auth.service";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { CurrencyReferenceModel, PaymentStatusReferenceModel, PaymentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationPayment, PaymentSearchCriteria, PaymentSearchCriteria2 } from "../payment-search.model";
import { PaymentSearchService } from "../payment-search.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { filter } from "rxjs/operators";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

const datepickerOptions = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    allowClear: true,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    }
}

const select2Options = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

@Component({
    selector: 'op-payment-search-criteria',
    templateUrl: './payment-search-criteria.component.html'
})
export class PaymentSearchCriteriaComponent implements OnInit, OnDestroy, AfterViewInit {

    readonly paymentDateFrom = 'paymentDateFrom'
    readonly paymentDateTo = 'paymentDateTo'
    readonly voidDateFrom = 'voidDateFrom'
    readonly voidDateTo = 'voidDateTo'
    readonly issuedDateFrom = 'issuedDateFrom'
    readonly issuedDateTo = 'issuedDateTo'

    readonly moduleName = 'PAYMENT';

    searchForm: UntypedFormGroup
    searchForm2: UntypedFormGroup
    @Output() onSubmit = new EventEmitter<PaymentSearchCriteria & PaymentSearchCriteria2>()

    focusingSearch = false
    focusingAdvanceSearch = false
    showAdvanceSearch = false
    select2Options = select2Options
    organisationSelect2Options = { ...select2Options, minimumResultsForSearch: 0 }
    datepickerOptions

    organisationOptionList: Select2Data[]

    organisations$: Observable<OrganisationModel[]>
    paymentCurrency$: Observable<CurrencyReferenceModel[]>
    paymentStatus$: Observable<PaymentStatusReferenceModel[]>
    paymentType$: Observable<PaymentTypeReferenceModel[]>
    paymentAdvanceSearch$: Observable<any[]>
    balanceAdvanceSearch$: Observable<any[]>
    voidedAdvanceSearch$: Observable<any[]>
    paymentOrganisation$: Observable<OrganisationPayment[]>

    private subscriptions: Subscription[]

    constructor(private formBuilder: UntypedFormBuilder,
        private paymentSearchService: PaymentSearchService,
        private authService: AuthService,
        private navigateService: NavigationService,
        private changeDetector: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {
        this.searchForm = this.formBuilder.group(this.formValues())
        this.searchForm2 = this.formBuilder.group(this.formValues2())
        this.defaultDatepickerOptions()
    }

    ngOnInit(): void {
        this.subscriptions = []
        this.organisations$ = this.paymentSearchService.organisationUser$
        this.paymentCurrency$ = this.paymentSearchService.paymentCurrency$
        this.paymentStatus$ = this.paymentSearchService.paymentStatus$
        this.paymentType$ = this.paymentSearchService.paymentType$
        this.paymentAdvanceSearch$ = this.paymentSearchService.paymentAdvanceSearch$
        this.balanceAdvanceSearch$ = this.paymentSearchService.balanceAdvanceSearch$
        this.voidedAdvanceSearch$ = this.paymentSearchService.voidedAdvanceSearch$
        this.paymentOrganisation$ = this.paymentSearchService.paymentOrganisation$
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    ngAfterViewInit(): void {
        const params = this.navigateService.getParams()
        if (params.fromFinanceHome) {
            this.submit(100);
        } else if (params?.executeFlag) {
            this.submit()
        }

        this.subscriptions.push(
            this.organisations$.pipe(filter(f => !!f)).subscribe(response => {
                this.organisationOptionList = response.map(r => new Select2Data(r.organisationId, r.organisationCallName))
                this.organisationOptionList.splice(0, 0, new Select2Data('DUMMY', ' '))
            })
        )
    }

    submit(topNumber: number = null) {
        let searchFromValue = this.searchForm.value;
        searchFromValue.topNumber = topNumber ? topNumber : '';
        let values = { ...searchFromValue }
        if (this.showAdvanceSearch) {
            let value2 = this.searchForm2.value as PaymentSearchCriteria2
            values = { ...values, ...value2 }
        }
        this.onSubmit.emit(values)
    }

    clearCondition() {
        this.searchForm.reset(this.formValues())
        this.searchForm2.reset(this.formValues2())
        this.defaultDatepickerOptions();
        this.changeDetector.detectChanges(); 
    }

    valueChange(value: any, ctrlName: string) {
        this.updateFromToDatepickerOptions(ctrlName, value == null ? '' : value, this.searchForm, this.paymentDateFrom, this.paymentDateTo)
        this.searchForm.get(ctrlName)?.setValue(value == null ? '' : value)
    }

    valueChange2(value: any, ctrlName: string) {
        this.updateFromToDatepickerOptions(ctrlName, value, this.searchForm2, this.issuedDateFrom, this.issuedDateTo)
        this.updateFromToDatepickerOptions(ctrlName, value, this.searchForm2, this.voidDateFrom, this.voidDateTo)
        this.searchForm2.get(ctrlName)?.setValue(value == null ? '' : value)
    }

    private updateFromToDatepickerOptions(ctrlName: string,
        value: any,
        formGroup: UntypedFormGroup,
        dateFrom: string,
        dateTo: string) {

        if (!value)
            value = null

        if (ctrlName == dateFrom) {
            const newOptions = { ...this.datepickerOptions[dateTo], minDate: value }
            this.updateDatepickerOptions(dateTo, newOptions, value)
            if (value) {
                this.setIfEmptyDate(dateTo, this.dateConverterService.toDateFormatYearMonthDate(new Date()), formGroup);
            }
            
        }

        if (ctrlName == dateTo) {
            const newOptions = { ...this.datepickerOptions[dateFrom], maxDate: value }
            this.updateDatepickerOptions(dateFrom, newOptions, value)
            this.setIfEmptyDate(dateFrom, value, formGroup);
        }
    }

    private updateDatepickerOptions(fieldName: string, newOptions, value: any) {
        this.datepickerOptions[fieldName] = newOptions
    }

    private setIfEmptyDate(ctrlName: string,
        newValue: any,
        formGroup: UntypedFormGroup) {
        const dateField = formGroup.get(ctrlName);
        if (!dateField.value) {
            dateField.setValue(newValue);
        }
    }

    private formValues(): PaymentSearchCriteria {
        return {
            organisationId: this.authService.getSelectedOrganisation(),
            paymentNumber: '',
            documentNumber: '',
            formOfPaymentTypeCode: '',
            formOfPaymentCode: '',
            paymentCurrencyCode: '',
            paymentDateFrom: '',
            paymentDateTo: '',
            paymentStatus: '',
            includeLedger: true,
            topNumber: ''
        }
    }

    private formValues2(): PaymentSearchCriteria2 {
        return {
            paymentOrganisationId: '',
            orderNumber: '',
            paymentIndividualName: '',
            paymentIndividualMail: '',
            voidDateFrom: '',
            voidDateTo: '',
            issuedDateFrom: '',
            issuedDateTo: '',
            payment: '',
            balance: '',
            void: '0',
        }
    }

    private defaultDatepickerOptions() {
        this.datepickerOptions = {
            paymentDateFrom: { ...datepickerOptions },
            paymentDateTo: { ...datepickerOptions },
            voidDateFrom: { ...datepickerOptions },
            voidDateTo: { ...datepickerOptions },
            issuedDateFrom: { ...datepickerOptions },
            issuedDateTo: { ...datepickerOptions },
        }
    }
}

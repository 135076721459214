import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { ProductServiceRequestModel } from "src/app/core/models/product-model/specialservice-model";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { UiHelperService } from "src/app/core/utils/ui-helper.service";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { ConfigurationDetailComponent } from "./configuration-detail/configuration-detail.component";
import { ConfigurationView } from "./shared/configuration.view";
import { ConfigurationListComponent } from "./configuration-list/configuration-list.component";
import { ConfigurationMapperService } from "./shared/configuration-mapper.service";
import { VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { VehicleCompositionConfigurationCommandModel, VehicleCompositionConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-composition/vehicle-composition-configuration";

@Component({
    selector: 'op-vehicle-composition-configuration',
    templateUrl: './configuration.component.html',
    providers: [ConfigurationMapperService]
})
export class ConfigurationComponent implements OnChanges {
    readonly SINGLE_POSITION_OFFSET = 720;
    readonly MULTIPLE_POSITION_OFFSET = 330;
    readonly PROPERTYLIST_POSITION_OFFSET = 320;
    public readonly ERROR_REQUIRED = 'Configuration is required.';
    @Input() id: string;
    @Input() vehicleConfigurations: VehicleConfigurationModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() productServiceRequests: ProductServiceRequestModel[];
    @Input() vehicleCompositionConfigurations: VehicleCompositionConfigurationModel[];
    @Output() formStatus = new EventEmitter<boolean>();

    @ViewChild(ConfigurationListComponent) configurationListComponent: ConfigurationListComponent;
    @ViewChild(ConfigurationDetailComponent) configurationDetailComponent: ConfigurationDetailComponent;
    @ViewChild("configurationDetail") configurationDetail: ElementRef;
    @ViewChild("configurationList") configurationList: ElementRef;

    focusing: boolean = false;
    newFromDetail: boolean = false;
    newFromGrid: boolean = false;
    editFromGrid: boolean = false;
    configurationViews: ConfigurationView[] = new Array();
    model: ConfigurationView;
    constructor(private focusingService: FocusingService,
        private uiHelper: UiHelperService,
        private changeDetectorRef: ChangeDetectorRef,
        private configurationMapperService: ConfigurationMapperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['vehicleCompositionConfigurations']) {
            this.createModelToViews()
        }
    }

    public onApply(view: ConfigurationView) {
        this.saveToViews(view);
        if (this.isMultipleRecord()) {
            this.resetModeMultiple();
        } else {
            this.formStatus.emit(true);
        }
    }

    private createModelToViews() {
        this.configurationViews = this.configurationMapperService.toConfigurationViews(this.vehicleCompositionConfigurations)
        .sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
        this.displayInCondition();
    }

    private displayInCondition() {
        if (this.isNoRecord()) {
            this.model = null;
        } else if (this.isSingleRecord()) {
            this.model = this.configurationViews[0];
        }
    }

    private saveToViews(view: ConfigurationView) {
        var index = this.configurationViews.findIndex(x => x.no == view.no);
        if (index == -1) {
            view.no = this.configurationViews.length + 1;
            this.configurationViews.push(view);
        }
        else {
            this.configurationViews[index] = view;
        }
    }

    public isSingleRecord(): boolean {
        return (this.configurationViews?.length == 1);
    }

    public isNoRecord(): boolean {
        return (!this.configurationViews?.length == true);
    }

    public isMultipleRecord(): boolean {
        return (this.configurationViews?.length > 1);
    }

    onNewFromDetail() {
        this.setNewModeFromDetail().then(() => {
            this.moveToDetail(this.SINGLE_POSITION_OFFSET);
            this.focusingService.focus(this.configurationDetailComponent.focusingDirective);
        });
    }

    onNewFromGrid() {
        this.setNewMode().then(() => {
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onEdit(view: ConfigurationView) {
        this.setEditMode(view).then(() => {
            this.moveToDetail(this.MULTIPLE_POSITION_OFFSET);
        });
    }

    onCopy(view: ConfigurationView) {
        this.setCopyMode(view);
    }

    onDelete(view: ConfigurationView) {
        this.deletedata(view);
        if (this.isSingleRecord()) {
            this.model = this.configurationViews[0];
        }
    }

    onCancel() {
        if (this.isNoRecord()) {
            this.resetModeNoRecord();
        } else if (this.isSingleRecord() && !this.newFromDetail) {
            this.resetModeSingle();
        } else if (this.isSingleRecord() && this.newFromDetail) {
            this.resetModeSingleNewDetail();
        } else {
            this.resetModeMultiple();
        }
    }

    onRefresh() {
        this.createModelToViews();
    }

    private deletedata(data: ConfigurationView) {
        let views = new Array<ConfigurationView>();
        if (this.configurationViews != null) {
            views = this.configurationViews;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.configurationViews = views;
        }
    }

    private resetModeNoRecord() {
        this.resetMode().then(() => {
            this.focusingService.focus(this.configurationDetailComponent.focusingDirective);
        });
    }

    private resetModeSingle() {
        this.resetMode().then(() => {
            this.configurationViews = [];
            this.focusingService.focus(this.configurationDetailComponent.focusingDirective);
        });
    }

    private resetModeSingleNewDetail() {
        this.resetModeNewFromDetaill().then(() => {
            this.focusingService.focus(this.configurationDetailComponent.focusingDirective);
            this.configurationDetailComponent.applied = true;
        });
    }

    private resetModeMultiple() {
        this.resetMode().then(() => {
            this.moveToList(this.PROPERTYLIST_POSITION_OFFSET);
        });
    }

    private resetMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private resetModeNewFromDetaill(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = this.configurationViews[0];
            this.newFromGrid = false;
            this.editFromGrid = false;
            this.newFromDetail = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setNewModeFromDetail(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = null;
            this.newFromDetail = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setEditMode(view: ConfigurationView): Promise<void> {
        return new Promise((resolve, reject) => {
            this.model = view;
            this.newFromGrid = false;
            this.editFromGrid = true;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private setCopyMode(view: ConfigurationView): Promise<void> {
        return new Promise((resolve, reject) => {
            let copyData = cloneDeep(view);
            copyData.no = null;
            copyData.vehicleCompositionConfigurationId = undefined;
            this.model = copyData;
            this.newFromGrid = true;
            this.editFromGrid = false;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    public moveToDetail(offset: number) {
        this.uiHelper.moveScrollPosition(this.configurationDetail, offset);
    }

    private moveToList(offset: number) {
        this.uiHelper.moveScrollPosition(this.configurationList, offset);
        this.focusingService.focus(this.configurationListComponent.focusingDirective);
    }

    public getValue(): VehicleCompositionConfigurationCommandModel[] {
        return this.configurationMapperService.toConfigurationModels(this.configurationViews);
    }

    public validateForm(): boolean {
        if (!this.configurationDetailComponent) {
            return true;
        }

        if (this.configurationDetailComponent.processing) {
            return this.configurationDetailComponent.validateForm();
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (this.configurationDetailComponent) {
            return this.configurationDetailComponent.getErrorMessageForm();
        }
    }

    public onformStatusChange(formStatus: boolean) {
        this.formStatus.emit(formStatus);
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DxDataGridModule } from 'devextreme-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PanelsModule } from '../../../shared/panels/panels.module';
import { DialogsModule } from '../../../shared/dialogs/dialogs.module';
import { DialogsService } from '../../../shared/dialogs/dialogs.service';
import { Select2Module } from '../../../shared/ui/forms/inputs/oops-select2/select2.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DaterangepickerModule } from '../../../shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { Helper } from '../../../shared/helper/app.helper';

import { TranslationMediaComponent } from './translation-media.component';
import { TranslationMediaDetailComponent } from './translation-media-detail/translation-media-detail.component';
import { TranslationMediaDetailDirective } from './translation-media-detail/translation-media-detail.directive';
import { TranslationMediaPreviewComponent } from './translation-media-preview/translation-media-preview.component';
import { MatIconModule } from "@angular/material/icon";
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';

@NgModule({
    imports: [
        BsDropdownModule,
        ButtonsModule,
        CollapseModule,
        CommonModule,
        DaterangepickerModule,
        DialogsModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        NgbModule,
        NgxDropzoneModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        SweetAlert2Module,
        TooltipModule,
        UtilsModule,
    ],
    providers: [
        DialogsService, Helper
    ],
    declarations: [TranslationMediaComponent, TranslationMediaDetailComponent, TranslationMediaDetailDirective, TranslationMediaPreviewComponent],
    exports: [TranslationMediaComponent, TranslationMediaDetailComponent, TranslationMediaDetailDirective, TranslationMediaPreviewComponent]
})
export class TranslationMediaModule {

}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { StringHelperService } from '../../utils/string-helper.service';
import {
    MediaLibraryCommandModel,
    MediaSearchCommandModel,
    MediaSearchViewModel,
    MediaViewModel
} from '../../models/media-model';

@Injectable({
    providedIn: 'root'
})
export class MediaService extends ApiServiceBase {
    private readonly PATH = "/media"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }


    public search(mediaSearchCommandModel: MediaSearchCommandModel): Observable<MediaSearchViewModel[]> {
        return this.httpPost<MediaSearchViewModel[]>(this.PATH + "/media/search", mediaSearchCommandModel)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public searchMediaFile(searchText: string, mediaTypeCode: string, mediaUseCode: string, entityExclude: string[]): Observable<MediaSearchViewModel[]> {
        let params = new HttpParams()
            .set("searchText", searchText)
            .set("mediaTypeCode", mediaTypeCode)
            .set("mediaUseCode", mediaUseCode)

        if (entityExclude?.length) {
            for (let param of entityExclude) {
                params = params.append('entityExclude', param);
            }
        }
        return this.httpGet<MediaSearchViewModel[]>(this.PATH + "/media/searchMediaFile", params)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getByIds(ids: string[] = []): Observable<MediaViewModel[]> {
        const mediaIdParamName = 'mediaId';
        let params = this.stringHelperService.createQueryParamFromArray(mediaIdParamName, ids);
        let query = this.PATH + '/media' + params;
        return this.httpGet<MediaViewModel[]>(query, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getMediaFile(fileName: string): Observable<HttpResponse<Blob>> {
        return this.httpGetFile(this.PATH + '/media/file/' + fileName, null)
            .pipe(
                map(res => res)
            )
    }

    public getMediaThumbnailFile(fileName: string): Observable<HttpResponse<Blob>> {
        return this.httpGetFile(this.PATH + '/media/thumbnailfile/' + fileName, null)
            .pipe(
                map(res => res)
            )
    }

    public save(model: MediaLibraryCommandModel): Observable<MediaViewModel> {
        return this.httpPost<MediaViewModel>(this.PATH + "/media", model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getByEntityNameAndAssociation(entityName: string, associationId: string): Observable<MediaViewModel[]> {
        return this.httpGet<MediaViewModel[]>(this.PATH + "/media/" + entityName + "/" + associationId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DateTypeModel } from 'src/app/core/models/merchandizing-config';
import { cloneDeep } from "lodash";

@Injectable()
export class CalendarSetDefaultService {

    public setDefaultCalendarType(f: UntypedFormGroup, config, dateType$: BehaviorSubject<DateTypeModel[]>, calendarTypeOption) {
        var filterType = config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            var filterDateType = filterType[0].dateType.filter(x => x.dateTypeCode != "")
            if (filterDateType.length != 0) {

                var calendarTypeCodeDefault: string;
                if (dateType$.value) {
                    var filterDateTypeDB = dateType$.value.filter(x => x.dateTypeCode == f.get('dateType').value);
                    if (filterDateTypeDB.length != 0) {
                        f.controls['calendarTypeData'].setValue(filterDateTypeDB[0].calendarType);
                        calendarTypeCodeDefault = filterDateTypeDB[0].calendarType[0].calendarTypeCode;
                    }
                    else {
                        f.controls['calendarTypeData'].setValue(null);
                    }
                }
                let calendarTypeData = filterDateType[0].calendarType;
                var filterplaceHolder = calendarTypeData.filter(x => x.calendarTypeCode == "");
                if (filterplaceHolder.length != 0) {
                    calendarTypeOption.placeholder = filterplaceHolder[0].calendarTypeName;
                }
                else {
                    f.controls['calendarType'].setValue(calendarTypeCodeDefault);
                    // check for display timeZone
                    if (calendarTypeData[0].timeZone) {
                        f.controls['timeZoneDisplay'].setValue(true);
                    }
                }
            }
        }
    }

    public setDefaultTimeZone(f: UntypedFormGroup, config, timeZoneOption) {
        var filterType = config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            var filterDateType = filterType[0].dateType.filter(x => x.dateTypeCode != "")
            if (filterDateType.length != 0) {
                let calendarTypeData = filterDateType[0].calendarType;
                var filterCalendarType = calendarTypeData.filter(x => x.calendarTypeCode != "");
                if (filterCalendarType.length != 0) {
                    var timeZoneData = filterCalendarType[0].timeZone;
                    f.controls['timeZoneData'].setValue(timeZoneData);

                    var filterplaceHolder = timeZoneData.filter(x => x.timeZoneCode == "");
                    if (filterplaceHolder.length != 0) {
                        timeZoneOption.placeholder = filterplaceHolder[0].timeZoneName;
                    }
                    else {
                        f.controls['timeZone'].setValue(timeZoneData[0].timeZoneCode);
                    }
                }
            }
        }

    }

    public setDefaultCalendarOperator(f: UntypedFormGroup, config, dateType$: BehaviorSubject<DateTypeModel[]>, calendarOperatorOption) {
        var filterType = config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            var filterDateType = dateType$.value.filter(x => x.dateTypeCode == f.controls['dateType'].value);
            if (filterDateType.length != 0) {
                let calendarTypeData = filterDateType[0].calendarType;
                var filterCalendarType = calendarTypeData.filter(x => x.calendarTypeCode != "");
                if (filterCalendarType.length != 0) {
                    var operatorData = filterCalendarType[0].operators;
                    f.controls['calendarOperatorData'].setValue(operatorData);

                    if (filterCalendarType[0].operatorDefault) {
                        //Default from json value
                        f.controls['calendarOperator'].setValue(filterCalendarType[0].operatorDefault);
                    }
                    else {
                        var filterplaceHolder = operatorData.filter(x => x.operatorCode == "");
                        if (filterplaceHolder.length != 0) {
                            calendarOperatorOption.placeholder = filterplaceHolder[0].operatorName;
                        }
                        else {
                            f.controls['calendarOperator'].setValue(operatorData[0].operatorCode);
                        }
                    }
                }
            }
        }
    }

    public setDateTimeFormat(ctl, config, dateType$: BehaviorSubject<DateTypeModel[]>,
        validityDateOption, validityDateTimeOption, dateFormat, dateTimeFormat) {
        var min = null;
        var max = null;
        let value = ctl.get('calendarType').value;
        if (value == null) {
            return;
        }

        var filterType = config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            if (dateType$.value) {
                var filterDateTypeDB = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
                if (filterDateTypeDB.length != 0) {
                    let calendarType = filterDateTypeDB[0].calendarType;
                    var filterCalendarType = calendarType.filter(x => x.calendarTypeCode == value);
                    if (filterCalendarType.length != 0) {
                        if (filterCalendarType[0].min) {
                            min = filterCalendarType[0].min;
                        }
                        if (filterCalendarType[0].max) {
                            max = filterCalendarType[0].max;
                        }
                    }
                }
            }
        }



        if (value.toLowerCase() == "date") {
            validityDateOption.minDate = min;
            validityDateOption.maxDate = max;
            var optionDateStart = cloneDeep(validityDateOption);
            var optionDateEnd = cloneDeep(validityDateOption);
            ctl.get('dateTimeFormat').setValue(dateFormat);
            ctl.get('daterangepickerOption').setValue(optionDateStart);
            ctl.get('dateendpickerOption').setValue(optionDateEnd);
        } else if (value.toLowerCase() == "datetime") {
            validityDateTimeOption.minDate = min;
            validityDateTimeOption.maxDate = max;
            var optionDateTimeStart = cloneDeep(validityDateTimeOption);
            var optionDateTimeEnd = cloneDeep(validityDateTimeOption);
            ctl.get('dateTimeFormat').setValue(dateTimeFormat);
            ctl.get('daterangepickerOption').setValue(optionDateTimeStart);
            ctl.get('dateendpickerOption').setValue(optionDateTimeEnd);
        }
    }

    public setTimeFormat(ctl, config, dateType$: BehaviorSubject<DateTypeModel[]>, validityTimeOption) {
        var min = null;
        var max = null;
        let value = ctl.get('calendarType').value;
        if (value == null) {
            return;
        }

        var filterType = config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            if (dateType$.value) {
                var filterDateTypeDB = dateType$.value.filter(x => x.dateTypeCode == ctl.get('dateType').value);
                if (filterDateTypeDB.length != 0) {
                    let calendarType = filterDateTypeDB[0].calendarType;
                    var filterCalendarType = calendarType.filter(x => x.calendarTypeCode == value);
                    if (filterCalendarType.length != 0) {
                        if (filterCalendarType[0].min) {
                            min = filterCalendarType[0].min;
                        }
                        if (filterCalendarType[0].max) {
                            max = filterCalendarType[0].max;
                        }
                    }
                }
            }
        }
        validityTimeOption.minDate = min;
        validityTimeOption.maxDate = max;
        return validityTimeOption;
    }

    public setMinEndDate(value: string, ctl, validityDateOption, validityDateTimeOption) {
        if (value) {
            var dateendpickerOption = cloneDeep(ctl.get('dateendpickerOption').value);
            if (dateendpickerOption) {
                dateendpickerOption.minDate = value;
            }
            ctl.get("dateendpickerOption").setValue(dateendpickerOption);
        }
        else {
            let calendarType = ctl.get('calendarType').value;
            if (calendarType) {
                if (calendarType.toLowerCase() == "date") {
                    ctl.get('dateendpickerOption').setValue(validityDateOption);
                }
            } else if (value.toLowerCase() == "datetime") {
                ctl.get('dateendpickerOption').setValue(validityDateTimeOption);
            }
            ctl.get("endDate").setValue(null);
        }
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PanelsModule } from '../../../shared/panels/panels.module';
import { Select2Module } from '../../../shared/ui/forms/inputs/oops-select2/select2.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DaterangepickerModule } from '../../../shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { Helper } from '../../../shared/helper/app.helper';

import { RulesConfigComponent } from './rules-config.component';
import { ValidityInheritComponent } from './inherit-rules/validity/validity-inherit.component';
import { RestrictionInheritComponent } from './inherit-rules/restriction/restriction-inherit.component';
import { PointOfSalesInheritComponent } from './inherit-rules/point-of-sales/point-of-sales-inherit.component';

@NgModule({
    declarations: [
        PointOfSalesInheritComponent,
        RestrictionInheritComponent,
        RulesConfigComponent,
        ValidityInheritComponent
    ],
    exports: [
        PointOfSalesInheritComponent,
        RestrictionInheritComponent,
        RulesConfigComponent,
        ValidityInheritComponent,
    ],
    imports: [
        ButtonsModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        DaterangepickerModule,
        FormsModule,
        PanelsModule,
        ReactiveFormsModule,
        NgbModule,
        Select2Module,
        TooltipModule,
        UtilsModule
    ],
    providers: [
        Helper
    ]
})
export class RulesConfigModule {
}
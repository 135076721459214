import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { 
  ProductCategoryReferenceModel,
  ProductCategoryUseModel
} from '../../models/reference-model/reference-product-model';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/system/ProductCategories"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getByProductCategory(productCategoryCode): Observable<ProductCategoryReferenceModel> {

    return this.httpGet<ProductCategoryReferenceModel>(this.REFERENCE_PATH + "/" + productCategoryCode, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getAll(): Observable<ProductCategoryReferenceModel[]> {
    return this.httpGet<ProductCategoryReferenceModel[]>(this.REFERENCE_PATH, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
  public getUseCategories(): Observable<ProductCategoryUseModel[]> {
    return this.httpGet<ProductCategoryUseModel[]>(this.REFERENCE_PATH + "/use", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getProductCategoryUseByIndividual(individualId: string): Observable<ProductCategoryUseModel[]> {
    return this.httpGet<ProductCategoryUseModel[]>(this.REFERENCE_PATH + "/use/" + individualId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getProductCategoryInOrderProduct(): Observable<ProductCategoryReferenceModel[]> {
    return this.httpGet<ProductCategoryReferenceModel[]>(this.REFERENCE_PATH + "/orderProduct", null)
      .pipe(
        map(response => response.body)
      )
  }
}
export class Select2Option {
    public placeholder: string;
    public allowClear: boolean = false;
    public minimumResultsForSearch: number | null = -1;
    public theme: string = "select2-builder-blue";
    public width: string = 'auto';
    public dropdownAutoWidth: boolean = true;
    public multiple: boolean = false;
    public singleDatePicker: boolean | null;
    public showDropdowns: boolean | null;
    public autoApply: boolean | null;
    public autoUpdateInput: boolean | null;
    public startDate: Date;
    public endDate: Date;
    public locale: Locale;
    public timePicker: boolean | null;
    public timePicker24Hour: boolean | null;
    public showTimeOnly: boolean = false;
    public disabled: boolean = false;
    public minimumInputLength: number | null;
    public ajax: any | null;
    constructor(placeholder: string = null) {
        if (placeholder) {
            this.placeholder = placeholder;
        }
    }
    public maximumSelectionLength: number | null = -1;
}

export class Locale {
    public cancelLabel: string;
    public format: string;
}
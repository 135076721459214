import { Injectable } from '@angular/core';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { ProductGroupView } from '../views/product-group.view';

@Injectable({
    providedIn: 'root'
})
export class ProductGroupMapperService {

    constructor(private dateConverterService: DateConverterService) { }

    public productGroupToOopsCategoryViews(productGroups: ProductGroupView[]): OopsCategory[] {
        let views: OopsCategory[] = new Array();
        productGroups?.forEach(productGroup => {
            views.push(this.productGroupToOopsCategoryView(productGroup));
        })
        return views;
    }

    public productGroupToOopsCategoryView(productGroup: ProductGroupView): OopsCategory {
        var view = {} as OopsCategory;
        view.name = productGroup.productGroupName;
        view.modifiedBy = productGroup.commitByName;
        view.modifiedDateTime = this.dateConverterService.convertDateTime24(productGroup.commitDateTime);
        view.productCount = productGroup.productCount;
        return view;
    }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable()
export class GlobalStateService {
    private _state: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

    public register(id: string) {
        if (!id) return;
        if (this._state.has(id)) return;
        this._state.set(id, new BehaviorSubject<boolean>(false));
    }

    public isDisabled(id: string): boolean {
        if (!this.validateId(id)) return null;
        return this._state.get(id).value;
    }

    public disabled$(id: string): Observable<boolean> {
        if (!this.validateId(id)) return of(null);
        return this._state.get(id).asObservable();
    }

    public disableAllExcept(...ids: string[]) {
        if (this._state.size == 0) return;
        for (const [key, value] of this._state.entries()) {
            value.next(!ids.includes(key));
        }
    }

    public enableAllExcept(...ids: string[]) {
        if (this._state.size == 0) return;
        for (const [key, value] of this._state.entries()) {
            value.next(ids.includes(key));
        }
    }

    public disable(id: string) {
        if (!this.validateId(id)) return;
        this._state.get(id).next(true);
    }

    public enable(id: string) {
        if (!this.validateId(id)) return;
        this._state.get(id).next(false);
    }

    public disableAll() {
        if (this._state.size == 0) return;
        for (const [_, value] of this._state.entries()) {
            value.next(true);
        }
    }

    public enableAll() {
        if (this._state.size == 0) return;
        for (const [_, value] of this._state.entries()) {
            value.next(false);
        }
    }

    private validateId(id: string): boolean {
        return id && this._state.has(id);
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { CommunicationOtherTypeReferenceModel } from '../../models/reference-model/reference-general-model';


@Injectable({
    providedIn: 'root'
})
export class CommunicationOtherTypeReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/CommunicationOtherTypeReferences"

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getCommunicationOtherTypeReferences(): Observable<CommunicationOtherTypeReferenceModel[]> {
        return this.httpGet<CommunicationOtherTypeReferenceModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxCheckBoxModule, DxDataGridModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { PriceDetailModule } from "../price-detail/price-detail.module";
import { SearchConditionComponent } from "./search-condition/search-condition.component";
import { SearchFilterNameComponent } from "./search-filter-name/search-filter-name.component";
import { SearchTableComponent } from "./search-table/search-table.component";
import { SearchComponent } from "./search.component";

@NgModule({
    declarations: [
        SearchComponent,
        SearchConditionComponent,
        SearchFilterNameComponent,
        SearchTableComponent
    ],
    exports: [
        SearchComponent
    ],
    imports: [
        BsDropdownModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        PriceDetailModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ]
})
export class SearchModule {

}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { CounterSalesPointOfSaleModel, CounterSalsesSearchCommandModel } from 'src/app/core/models/counter-sales-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { ProductCategoryViewModel } from 'src/app/core/models/reference-model/reference-product-model/product-category-view.model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { counterSelectionCalendarOption, select2Search, } from './counter-sales-search-condition-configurations';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-counter-sales-search-condition',
    templateUrl: './counter-sales-search-condition.component.html',
})
export class CounterSalesSearchConditionComponent extends OopsComponentFormBase implements OnInit, OnChanges {
    public pointOfSalesOption: any;
    public productCategoryOption: any;
    public productGroupOption: any;
    public productTypeOption: any;
    public organisationOption: any;
    public searchCommand: CounterSalsesSearchCommandModel[];
    public calendarOption = counterSelectionCalendarOption;
    public showError: string = '';
    public salesDateFrom: string = "";
    public salesDateTo: string = "";
    public dateNow = this.dateConverterService.toDateFormatYearMonthDate(new Date());
    public dateNowShow = this.dateConverterService.toDateFormatDateMonthYear(new Date());
    public focusing: boolean = false;

    @Input() organisationId: string;
    @Input() pointOfSales: CounterSalesPointOfSaleModel[];
    @Input() organisations: OrganisationModel[];
    @Input() productCategory: ProductCategoryViewModel[];
    @Input() productGroup: ProductGroupReferenceModel[];
    @Input() productType: ProductTypeGroupModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSearchClear = new EventEmitter();
    @Output() onSearch = new EventEmitter();

    constructor(fb: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService,
        private navigateService: NavigationService,) {
        super(fb);
        this.organisationOption = select2Search;
        this.pointOfSalesOption = select2Search;
        this.productCategoryOption = select2Search;
        this.productGroupOption = select2Search;
        this.productTypeOption = select2Search;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['userSecurity']) {
            this.checkUserSecurity();
        }
    }

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    public initForm() {
        this.formSetup();
        const params = this.navigateService.getParams()
        if (params?.executeFlag) {
            this.doSearch();
        }
    }

    private formSetup() {
        this.formGroup = new UntypedFormGroup({
            pointOfSales: new UntypedFormControl(null),
            productCategory: new UntypedFormControl(null),
            productGroup: new UntypedFormControl(null),
            productType: new UntypedFormControl(null),
            organisationId: new UntypedFormControl(this.organisationId),
            salesDateFrom: new UntypedFormControl(moment(new Date()).format('YYYY/MM/DD')),
            salesDateTo: new UntypedFormControl(null)
        });
        this.checkUserSecurity();
    }

    checkUserSecurity() {
        if (this.userSecurity?.securityCode == 'COUNTERSALES') {
            this.formGroup?.get('organisationId')?.disable();
            return;
        }
        this.formGroup?.get('organisationId')?.enable();
    }

    public doClear() {
        this.onSearchClear.emit();
    }

    public doSearch() {
        if (!this.form.controls['salesDateFrom'].value) {
            this.form.controls['salesDateFrom'].patchValue(moment(new Date()).format('DD/MM/YYYY'));
        }
        this.onSearch.emit(this.getValues());
    }

    public getValues(): CounterSalsesSearchCommandModel {
        this.changeDetectionRef.detectChanges();
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): CounterSalsesSearchCommandModel {
        let model = {} as CounterSalsesSearchCommandModel;
        let salesDateFrom = this.getFormControlValue("salesDateFrom");
        let salesDateTo = this.getFormControlValue("salesDateTo");
        if (!salesDateFrom) {
            salesDateFrom = this.dateNow;

        }
        model.organisationId = this.getFormControlValue("organisationId");
        model.pointOfSales = this.getFormControlValue("pointOfSales");
        model.productCategory = this.getFormControlValue("productCategory");
        model.productGroup = this.getFormControlValue("productGroup");
        model.productType = this.getFormControlValue("productType");
        model.salesDateFrom = salesDateFrom;
        model.salesDateTo = salesDateTo;
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public valueChange(value: any, ctrlName: string) {
        this.formGroup.controls[ctrlName]?.setValue(value);
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteDataService } from 'src/app/core/components/address/shared/route-data-service';
import { CreditAccountSearchModel } from 'src/app/core/models/credit-account-model/credit-account-search.model';
import { CurrencyReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { select2LocationOption, select2SearchFilter, select2WithoutSearch } from './select2-configuration';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { USER_INFORMATION } from 'src/app/modules/auth/shared/constants/auth.constant';
import { OrganisationTypeReferenceModel } from 'src/app/core/models/organisation-model';

@Component({
    selector: 'op-credit-account-search-condition',
    templateUrl: './credit-account-search-condition.component.html',
    providers: [
        RouteDataService
    ]
})
export class CreditAccountSearchConditionComponent implements OnInit {
    public focusing: boolean = false;
    public condition: CreditAccountSearchModel;

    @Input() currencyReferences: CurrencyReferenceModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Output() onSearchClick = new EventEmitter<CreditAccountSearchModel>();
    @Output() onCancelClick = new EventEmitter();

    public locationOption = select2LocationOption;
    public currencyOption = select2SearchFilter;
    public usageTypeOption = select2WithoutSearch;
    public statusOption = select2WithoutSearch;

    public travelAgencyMode: boolean = false;

    constructor(private routeDataService: RouteDataService,
        private organisationService: OrganisationService,
        private authService: AuthService) { 
            this.checkOrganisationType();
        }

    ngOnInit(): void {
        this.condition = this.defaultCondition;
        this.locationOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
        this.searchOrganisaion();
    }

    get defaultCondition(): CreditAccountSearchModel {
        return {
            organisationId: null,
            organisationCode: null,
            organisationCallName: null,
            organisationLegalName: null,
            city: null,
            countryCode: null,
            countryName: null,
            currencyCode: null,
            currencyName: null,
            locationCode: null,
            locationName: null,
            usageTypeCode: null,
            ledgerTotal: null,
            salesTotal: null,
            creditAccountBalance: null,
            LastTopUpDateTime: null,
            LastSalesDateTime: null
        }
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchOrganisaion();
                break;
            default:
                break;
        }
    }

    public searchOrganisaion() {
        this.onSearchClick.emit(this.condition);
    }

    public clearCondition() {
        this.condition = this.defaultCondition;
        this.onCancelClick.emit();
    }

    public onLocationChange(locationCode: string | string[]) {
        this.condition.locationCode = locationCode ? locationCode as string : null;
    }

    public onUsageTypeChange(usageTypeCode: string | string[]) {
        this.condition.usageTypeCode = usageTypeCode ? usageTypeCode as string : null;
    }

    public onCurrencyChange(currencyCode: string | string[]) {
        this.condition.currencyCode = currencyCode ? currencyCode as string : null;
    }

    public checkOrganisationType() {
        let orgId = this.getOrganisationId();
        this.organisationService.getOrganisationTypes(orgId)
            .subscribe(
                (response: OrganisationTypeReferenceModel[]) => {
                    this.travelAgencyMode = this.checkForTravelAgency(response);
                }
            )
    }

    private getOrganisationId() {
        let userInfo = JSON.parse(this.getSessionItem(USER_INFORMATION));
        return userInfo?.organisationId;
    }

    private getSessionItem(key: string) {
        return this.authService.sessionStorageGetItem(key)
    }

    private checkForTravelAgency(organisationTypes: OrganisationTypeReferenceModel[]): boolean {
        return !!organisationTypes.find(item => item.organisationTypeCode == 'TRAVELAGENCY');
    }
}

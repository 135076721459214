import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { ActivityDomainView } from "../shared/activity-domain.view";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-activity-domain-list',
    templateUrl: './activity-domain-list.component.html'
})
export class ActivityDomainListComponent implements AfterViewInit {
    public readonly EXPORT_FILE_NAME = "Activity";
    @Input() id: string;
    @Input() activityDomainViews: ActivityDomainView[] = new Array();
    @Input() activitySecurity: SecurityGroupSecurityModel;
    @Output() new = new EventEmitter();
    @Output() edit = new EventEmitter<ActivityDomainView>();
    @Output() rowSelected = new EventEmitter<ActivityDomainView>();
    @Output() cancel = new EventEmitter();
    @Output() complete = new EventEmitter<ActivityDomainView>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    focusing: boolean = false;
    selectedItem: ActivityDomainView;
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };

    get hasActivitySecurity(): boolean {
        return !this.activitySecurity;
    }

    get disableButtonWhenSelect(): boolean {
        return this.hasActivitySecurity || !this.selectedItem?.activityId;
    }
    
    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    onNew() {
        this.new.emit();
    }

    onEdit() {
        if (this.selectedItem) {
            this.edit.emit(this.selectedItem);
        }
    }

    onCancel() {
        this.cancel.emit();
    }

    onComplete() {
        if (this.selectedItem?.activityId) {
            this.complete.emit(this.selectedItem);
            this.selectedItem = null;
        }
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
        this.rowSelected.emit(this.selectedItem);
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Organisation: data.organisationName,
            ActivityType: data.activityTypeName,
            Priority: data.activityPriorityName,
            ActivityDatetime: data.activityDateTime,
            FollowupDatetime: data.followUpDateTime,
            Reason: data.activityReasonName,
            Comment: data.commentText
        };
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'op-voucher-tree',
    templateUrl: './voucher-tree.component.html'
})
export class VoucherTreeComponent implements OnChanges {
    private readonly NAME_REGEX = /(?<name>\w.*)(?<copy> - Copy)( \((?<number>\d*)\))?/;
    public focusing: boolean = false;
    public selectedNode: TreeNode;
    public firstLoad = true;

    @Input() voucherTree: TreeNode[];
    @Input() heightPanelGeneral: number;
    @Input() voucherId: string;
    @Output() onNodeSelect = new EventEmitter();
    @Output() newNodeNameChange = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['voucherTree']) {
            this.refreshTree();
        }
    }

    private refreshTree() {
        if (this.voucherId && this.voucherTree) {
            if (this.firstLoad) {
                this.firstLoad = false;
            }
            this.voucherTree.forEach(node => {
                this.expandRecursive(node, true);
            });
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
        if (node.data == this.voucherId) {
            this.selectedNode = node;
        }
    }

    public addToSelection(treeNode: TreeNode) {
        if (this.selectedNode) {
            this.selectedNode.children = this.selectedNode.children ?? [];
            this.selectedNode.children.push(treeNode);
            this.selectedNode = treeNode;
        }
    }

    nodeSelect(value) {
        this.onNodeSelect.emit(value.node.data);
    }

    public onSelectedNodeNameChange(name: string) {
        if (!this.selectedNode) {
            return;
        }
        this.selectedNode.label = name;
    }

    createCopiedChild(parentData: string) {
        let copyNodeName = this.getNewUniqueNodeName(parentData);
        this.addNewNode(parentData, copyNodeName);
        this.newNodeNameChange.emit(copyNodeName)
    }

    private getNewUniqueNodeName(parentData: string): string {
        let newNodeName = this.createNewNodeName(this.selectedNode?.label);
        let siblingNodes = this.getSiblingNodes(this.voucherTree[0], parentData);
        if (siblingNodes?.length) {
            let matches = siblingNodes.map(node => node.label.match(this.NAME_REGEX))
            let matchOnes = matches.filter(match => match)
            if (matchOnes?.length) {
                for (let match of matchOnes) {
                    if (matchOnes.every(m => m.input != newNodeName)) {
                        break;
                    }
                    newNodeName = this.createNewNodeName(match.input);
                }
            }
        }
        return newNodeName;
    }
    
    private createNewNodeName(oldName: string): string {
        let copyName = oldName;
        let match = oldName.match(this.NAME_REGEX);
        if (match) {
            let number = match.groups['number'];
            if (number) {
                copyName = match.groups['name'] + match.groups['copy'] + ` (${parseInt(number) + 1})`;
            } else {
                copyName = match.groups['name'] + ' - Copy (2)';
            }
        } else {
            copyName = copyName + ' - Copy';
        }
        return copyName
    }

    private getSiblingNodes(parent: TreeNode, parentData: string): TreeNode[] {
        if (parent.data == parentData) {
            return parent.children;
        }
        if (parent.children?.length) {
            for (let child of parent.children) {
                let siblings = this.getSiblingNodes(child, parentData);
                if (siblings?.length) {
                    return siblings;
                }
            }
        }
    }

    private createNewNode(nodeName: string): TreeNode {
        let treeNode: TreeNode = {
            label: nodeName,
            data: null,
            icon: 'tree-view-file-icon'
        }
        return treeNode;
    }

    public addNewNode(parentData: string, nodeLabel: string = '') {
        let newNode = this.createNewNode(nodeLabel);

        if (this.parentDataAndTreeDataHasValue(parentData)) {
            this.addNewNodeToParent(this.voucherTree[0], newNode, parentData);
        } else {
            this.makeNewNodeAsParentNode(newNode);
        }
    }

    private parentDataAndTreeDataHasValue(parentData: string): boolean {
        return parentData && (this.voucherTree?.length ? true : false);
    }

    private makeNewNodeAsParentNode(newNode: TreeNode) {
        this.selectedNode = newNode;
        this.voucherTree = [newNode];
    }

    private addNewNodeToParent(parent: TreeNode, newNode: TreeNode, parentData: string) {
        if (parent.data == parentData) {
            parent.children = parent.children ?? [];
            parent.children.push(newNode);
            this.selectedNode = newNode;
            return;
        }
        if (parent.children?.length) {
            for (let child of parent.children) {
                this.addNewNodeToParent(child, newNode, parentData);
            }
        }
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { VoucherSearchView } from '../../shared/voucher-search.view';
import { select2DefaultOption, select2DefaultWithSearchBox } from './select2-configuration';
import { UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { VoucherViewModel } from 'src/app/core/models/product-model/voucher-model/voucher-view.model';
import { NgForm, Validators } from '@angular/forms';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-voucher-search-condition',
    templateUrl: './voucher-search-condition.component.html'
})
export class VoucherSearchConditionComponent implements OnInit, AfterViewInit {
    public readonly errorProductGroupRequired = 'Product group is required';
    public focusing: boolean = false;
    public defaultOption = select2DefaultOption;
    public searchOption = select2DefaultWithSearchBox;

    public condition: VoucherSearchView;
    public productTypeOption: ProductTypeGroupModel[];
    public processing: boolean = false;

    @Input() productTypeGroups: ProductTypeGroupModel[];
    @Input() productGroupReferences: ProductGroupReferenceModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Input() searchFilters: Select2Data[];

    @Output() onAdvanceSearchClick = new EventEmitter();
    @Output() onSearchClick = new EventEmitter<VoucherSearchView>();
    @Output() onClearClick = new EventEmitter();
    @Output() searchFilterChange = new EventEmitter<string>();

    @ViewChild('voucherSearchConditionForm') conditionForm: NgForm;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.condition = this.defaultCondition;
    }

    ngAfterViewInit(): void {
        this.searchClick();
    }

    get defaultCondition(): VoucherSearchView {
        return {
            voucherId: null,
            productTypeCode: null,
            productTypeName: null,
            productGroupCode: null,
            productGroupName: null,
            voucherName: null,
            usageTypeCode: null,
            usageTypeName: null,
            statusCode: 'A',
            organisationId: null,
            organisationName: null
        }
    }

    public clearCondition() {
        this.condition = this.defaultCondition;
        this.onClearClick.emit();
    }

    public onProductGroupChange(productGroupCode: string | string[]) {
        this.condition.productGroupCode = productGroupCode ? productGroupCode as string : null;
        this.onGetProductTypeByGroup(this.condition.productGroupCode);
    }

    private onGetProductTypeByGroup(productGroupCode: string) {
        if (!productGroupCode) {
            this.productTypeOption = null;
            return;
        }
        this.productTypeOption = this.productTypeGroups?.filter(item => item.productGroupCode == productGroupCode);
        this.changeDetector.detectChanges();
    }

    public advanceSearch() {
        this.onAdvanceSearchClick.emit();
    }

    public onProductTypeGhange(productTypeCode: string | string[]) {
        this.condition.productTypeCode = productTypeCode ? productTypeCode as string : null;
    }

    public onUsageTypeChange(usageTypeCode: string | string[]) {
        this.condition.usageTypeCode = usageTypeCode ? usageTypeCode as string : null;
    }

    public searchClick() {
        this.onSearchClick.emit(this.condition);
    }

    public getErrorMessage() {
        if (!this.conditionForm.controls['productGroupCode'].valid) {
            return this.errorProductGroupRequired;
        }
    }

    public validate(): boolean {
        this.processing = true;
        this.setRequiredProductGroup();
        if (!this.conditionForm.controls['productGroupCode'].valid) {
            return false;
        }
        this.processing = false;
        this.clearRequiredProductGroup();
        return true;
    }

    private setRequiredProductGroup() {
        this.conditionForm.controls['productGroupCode'].setValidators([Validators.required]);
        this.conditionForm.controls['productGroupCode'].updateValueAndValidity();
    }

    private clearRequiredProductGroup() {
        this.conditionForm.controls['productGroupCode'].clearValidators();
        this.conditionForm.controls['productGroupCode'].updateValueAndValidity();
    }

    public fillConditionToViewModel(view: VoucherViewModel) {
        view.productGroupCode = this.condition.productGroupCode;
        view.productTypeCode = this.condition.productTypeCode;
        view.searchName = this.condition.voucherName;
        view.searchUsageTypeCode = this.condition.usageTypeCode;
        view.searchStatusCode = this.condition.statusCode;
    }

    public onSearchFilterChange(id: string | string[]) {
        this.condition.voucherId = id ? id as string : null;
        this.searchFilterChange.emit(this.condition.voucherId);
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDividerModule } from '@angular/material/divider';

import { CategoriesContextMenuComponent } from './context-menu/categories-context-menu.component';

import { OopsCategoriesComponent } from './oops-categories.component';
import { UiModule } from 'src/app/shared/ui/ui.module';

@NgModule({
    declarations: [
        CategoriesContextMenuComponent,
        OopsCategoriesComponent,
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        NgbNavModule,
        UiModule
    ],
    exports: [
        OopsCategoriesComponent
    ]
})
export class OopsCategoriesModule { }

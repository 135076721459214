import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ExportButtonGroupComponent } from "src/app/shared/ui/export-button-group/export-button-group.component";
import { CommentView } from "../shared/comment-view";

@Component({
    selector: 'op-insight-comment-table',
    templateUrl: './comment-table.component.html'
})
export class InsightCommentTableComponent implements AfterViewInit {
    public selectedItem: CommentView;
    public customToolbarMarginTop: number;
    public rows = 0;

    public header: string[] = [];
    public exportData: any;
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightComments: CommentView[] = new Array(); 
    @Output() onEdit = new EventEmitter(); 

    @ViewChild('dataGridComment', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
      }

    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Type: dat.commentTypeName,
                Classification: dat.commentClassificationName,
                Private: dat.privateFlag,
                Warning: dat.warningFlag,
                Locked: dat.lockedFlag,
                Comment: dat.commentText
            }
        ));
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
      }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    editInsightComment() {
        this.onEdit.emit();
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }
}
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { of, Subject, timer } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import {
    DomainAttributeService,
    LocationReferenceService,
    OrganisationGroupReferenceService,
    RegionReferenceService,
} from 'src/app/core/services/airline-services';
import { CountryReferenceService } from 'src/app/core/services/airline-services/country-reference.service';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { PricingService } from 'src/app/core/services/pricing-services';
import {
    CurrencyReferenceService,
    OrganisationRoleReferenceService,
    OrganisationTypeReferenceService,
} from 'src/app/core/services/system-services';
import { MembershipLevelService } from 'src/app/core/services/system-services/membership-level.service';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { Helper } from 'src/app/shared/helper/app.helper';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import {
    CancelButtonModel,
    CopyButtonModel,
    DeleteButtonModel,
    NewButtonModel,
    RefreshButtonModel,
    SaveAsButtonModel,
    SaveButtonModel,
} from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { StatusConstant } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SearchConverter } from '../../price-dimensions/shared';
import { PricingConstant } from '../../pricing-content/shared/constants/pricing.constant';
import { PriceDimensionComponent } from '../price-detail/price-dimension/price-dimension.component';
import {
    AttributeChoiceMultipleOption,
    AttributeChoiceSingleOption,
} from '../price-detail/price-dimension/shared/options/select2-price-dimension.options';
import { SearchConditionComponent } from '../price-search/search-condition/search-condition.component';
import { SearchTableComponent } from '../price-search/search-table/search-table.component';
import { PriceModel } from '../shared/models';
import { PriceConverter } from '../shared/price.converter';
import { PriceService } from '../shared/price.service';
import { PriceView } from '../shared/views';

declare var $: any;

@Component({
    selector: 'op-price-search',
    templateUrl: './search.component.html',
    providers: [FocusingService],
})
export class SearchComponent implements AfterViewInit, OnDestroy {
    private readonly FILTER_USAGE_TYPE = 'FILTER';

    private readonly PRICE_CONDITION_TEMPLATE_CODE = 'PRICECONDTEMPLATE';
    private readonly PRICE_DIMENSION_TEMPLATE_CODE = 'PRICEDIMENTEMPLATE';

    @ViewChild('detailContainer', { static: true, read: ViewContainerRef })
    detailContainer: ViewContainerRef;
    @ViewChild(PriceDimensionComponent)
    priceDimensionComponent: PriceDimensionComponent;
    @ViewChild(SearchConditionComponent)
    searchConditionComponent: SearchConditionComponent;
    @ViewChild(SearchTableComponent) searchTableComponent: SearchTableComponent;
    @ViewChild('priceSearchConditionRef') priceSearchConditionRef: ElementRef;
    @ViewChild('priceSearchTableRef') priceSearchTableRef: ElementRef;

    priceView$ = new BehaviorSubject<PriceView>(this.getDefaultPriceViewInstance());
    priceSearch$ = new BehaviorSubject<PriceModel>(null);
    priceSearchFilters$ = this.getSearchFilters();
    priceSearchResults$ = new Observable<PriceView[]>(null);
    priceSearchFilterId: string = null;

    advanceSearchModeEnabled = false;
    classIcon = this.helper.getClassIcon();
    doubleClickSubject$ = new Subject();
    searchMode: boolean = true;

    organisationTypeReferences$ = this.organisationTypeReferenceService
        .getByStatus('A')
        .pipe(map(res => res.map(r => new Select2Data(r.organisationTypeCode, r.organisationTypeName))));
    organisationReferences$ = this.organisationService
        .getByStatus('A')
        .pipe(map(res => res.map(r => new Select2Data(r.organisationId, r.organisationCallName))));
    organisationRoleReferences$ = this.organisationRoleReferenceService
        .getByCodes([])
        .pipe(map(res => res.map(r => new Select2Data(r.organisationRoleCode, r.organisationRoleName))));
    organisationGroupReferences$ = this.organisationGroupReferenceService
        .getOrganisationGroupReference()
        .pipe(map(res => res.map(r => new Select2Data(r.organisationGroupCode, r.organisationGroupName))));
    regionReferences$ = this.regionReferenceService
        .getRegionReference()
        .pipe(map(res => res.map(r => new Select2Data(r.regionCode, r.regionName))));
    organisationCountryReferences$ = this.countryReferenceService
        .getOrganisationCountries()
        .pipe(map(res => res.map(r => new Select2Data(r.countryCode, r.countryName))));
    servicaCategoryReferences$ = this.pricingService
        .getServiceCategoryReference()
        .pipe(map(res => res.map(r => new Select2Data(r.serviceCategoryCode, r.serviceCategoryName))));
    salesBucketReferences$ = this.pricingService
        .getSalesBucketReference()
        .pipe(map(res => res.map(r => new Select2Data(r.salesBucketCode, r.salesBucketName))));
    membershipLevels$ = this.membershipLevelService
        .getByProvider()
        .pipe(map(res => res.map(r => new Select2Data(r.membershipLevelCode, r.membershipLevelName))));
    currencyReferences$ = this.currencyReferenceService.getCurrencyReferences();
    domainAttributes$ = new BehaviorSubject<Select2Data[]>(null);
    attributeChoices$ = new BehaviorSubject<Array<Select2Data[]>>(null);
    attributeChoiceOption$ = new BehaviorSubject<any[]>(null);

    unsubscribe$ = new Subject();

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    private searchModelTemp: PriceModel = this.getDefaultPriceModelInstance();
    private priceFilterModel: PriceModel;
    private pagingDataViews: PagingDataView[] = [];

    defaultActionBarHandler: ActionBarHandlerModel;

    @Input() executeFlag: boolean = true;
    @Input() searchFilterId: string;
    @Input() userSecurity: SecurityGroupSecurityModel;

    constructor(
        private helper: Helper,
        private priceConverter: PriceConverter,
        private priceService: PriceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationService: OrganisationService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private regionReferenceService: RegionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private pricingService: PricingService,
        private currencyReferenceService: CurrencyReferenceService,
        private domainAttributeService: DomainAttributeService,
        private focusingService: FocusingService,
        private spinnerService: LoadingSpinnerService,
        private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private membershipLevelService: MembershipLevelService,
        private converter: SearchConverter,
        private activityStoreService: ActivityStoreService
    ) {
        this.domainAttributeService.getPriceRuleAttributes('', '', '').subscribe((res: DomainAttributeModel[]) => {
            let priceDimensionPriceRuleAttributeTypeReferences = res.filter(r => r.dimensionFlag == true && r.searchFlag == true);
            this.domainAttributes$.next(
                priceDimensionPriceRuleAttributeTypeReferences.map(
                    r => new Select2Data(r.attributeTypeCode, r.attributeTypeName)
                )
            );
            this.populateChoices(priceDimensionPriceRuleAttributeTypeReferences);
            this.populateChoiceOptions(priceDimensionPriceRuleAttributeTypeReferences);
        });
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.actionbarService.updateState(this.defaultActionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            switch (actionId) {
                case ACTION_STATUS.refresh:
                    this.onRefresh();
                    break;
                case ACTION_STATUS.new:
                    this.newItem();
                    break;
                case ACTION_STATUS.copy:
                    this.copyFilter();
                    break;
                default:
                    break;
            }
        });
        if (this.searchFilterId) {
            this.priceSearchFilterId = this.searchFilterId;
        }
        this.activityStore();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    setDefaultActionBarHandler() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        );
    }

    private populateChoices(res: DomainAttributeModel[]) {
        let choicesArr = new Array<Select2Data[]>();
        for (let r of res) {
            let choices = r.attributeChoices.map(c => new Select2Data(c.attributeChoiceId, c.attributeChoiceName));
            choicesArr[r.attributeTypeCode] = choices;
        }
        this.attributeChoices$.next(choicesArr);
    }

    private populateChoiceOptions(res: DomainAttributeModel[]) {
        let optionArr = new Array<any>();
        for (let r of res) {
            optionArr[r.attributeTypeCode] = r.multipleChoiceFlag
                ? AttributeChoiceMultipleOption
                : AttributeChoiceSingleOption;
        }
        this.attributeChoiceOption$.next(optionArr);
    }

    public editItem(view: PriceView) {
        let usageType = view.usageTypeCode;
        if (usageType == this.FILTER_USAGE_TYPE) {
            this.priceSearchFilterId = view.priceId;
            this.getPrice(view.priceId);
            this.focusingService.focus(this.searchConditionComponent.focusingDirective);
        } else {
            this.searchMode = false;
            this.loadDetailForm(view);
        }
    }

    public newItem() {
        this.searchMode = false;
        this.loadDetailForm(null);
    }

    loadDetailForm(view: PriceView) {
        this.navigationService.navigate('/main/pricing/price/details', null, false, {
            priceId: view?.priceId,
            previousPage: PricingConstant.prices.id,
            pagingDataViews: view?.priceId ? this.pagingDataViews : [],
            userSecurity: this.userSecurity
        });
    }

    onSave(priceModel: PriceModel) {
        let model = this.searchConditionComponent.getValues();
        this.convertPriceModelToSearchModel(model, priceModel);
        model = this.priceDimensionComponent.getValues(model);
        if (!model) {
            return;
        }
        this.spinnerService.showSaving();
        let response = model.priceId ? this.priceService.update(model) : this.priceService.add(model);
        response.subscribe(
            priceModel => {
                this.priceSearchFilterId = priceModel.priceId;
                this.priceSearchFilters$ = this.getSearchFilters();
                this.priceSearch$.next(priceModel);
                this.priceView$.next(this.priceConverter.toView(1, priceModel));
                this.spinnerService.saveComplete();
            },
            () => {
                this.spinnerService.hide();
            }
        );
    }

    private convertPriceModelToSearchModel(model: PriceModel, modelFromFilterName: PriceModel) {
        model.searchName = model.priceName;
        model.searchUsageTypeCode = model.usageTypeCode;
        model.searchStatusCode = model.status;

        model.priceName = modelFromFilterName.priceName;
        model.usageTypeCode = this.FILTER_USAGE_TYPE;
        model.status = 'A';
        model.isOwnerFilter = modelFromFilterName.isOwnerFilter;
    }

    deletePriceSearchFilter(priceSearchFilterId: string) {
        if (!priceSearchFilterId) {
            return;
        }
        this.spinnerService.showSaving();
        this.priceService.updateStatus(priceSearchFilterId, StatusConstant.DELETED).subscribe(
            () => {
                this.clearSearchCondition();
                this.spinnerService.saveComplete();
            },
            () => {
                this.spinnerService.hide();
            }
        );
    }

    deletePrice(priceId: string) {
        if (!priceId) {
            return;
        }
        this.spinnerService.showSaving();
        this.priceService.updateStatus(priceId, StatusConstant.DELETED).subscribe(
            () => {
                let searchModel = this.priceSearch$.value ?? this.getDefaultPriceModelInstance();
                this.onSearch(searchModel);
                this.spinnerService.saveComplete();
            },
            () => {
                this.spinnerService.hide();
            }
        );
    }

    onClear() {
        this.clearSearchCondition();
    }

    getSearchFilters(): Observable<Select2Data[]> {
        return this.priceService.getFilters().pipe(map(res => res.map(r => new Select2Data(r.priceId, r.priceName))));
    }

    clearSearchCondition() {
        this.priceSearchFilterId = null;
        this.priceSearchFilters$ = this.getSearchFilters();
        this.priceView$.next(this.getDefaultPriceViewInstance());
        this.priceSearch$.next(this.getDefaultPriceModelInstance());
        this.priceSearchResults$ = of([]);
        this.searchModelTemp = this.getDefaultPriceModelInstance();
    }

    getDefaultPriceViewInstance(): PriceView {
        let view = new PriceView();
        view.searchStatusCode = StatusConstant.ACTIVE;
        return view;
    }

    getDefaultPriceModelInstance(): PriceModel {
        let model = new PriceModel();
        model.status = 'A';
        model.usageTypeCode = 'DATA';
        return model;
    }

    onSearch(priceModel: PriceModel) {
        let model = priceModel;
        model = this.priceDimensionComponent.getValues(model);
        if (!model) {
            return;
        }
        this.priceSearch$.next(priceModel);

        this.searchModelTemp = priceModel;

        this.priceSearchResults$ = this.searchPrices(priceModel);
    }

    onRefresh() {
        this.onSearch(this.searchModelTemp);
    }

    searchPrices(priceModel: PriceModel) {
        this.spinnerService.show();
        return this.priceService.search(priceModel).pipe(
            map(res => {
                this.spinnerService.hide();
                let models = this.getOnlyData(res);
                this.pagingDataViews = this.converter.toPagingDataViews(models);
                return this.priceConverter.toViews(models);
            })
        );
    }

    private getOnlyData(models: PriceModel[]): PriceModel[] {
        return models.filter(
            model =>
                model.usageTypeCode != this.PRICE_CONDITION_TEMPLATE_CODE &&
                model.usageTypeCode != this.PRICE_DIMENSION_TEMPLATE_CODE
        )
    }

    onPriceSearchFilterIdChange(priceId: string) {
        if (priceId) {
            this.priceSearchFilterId = priceId;
            this.getPrice(priceId);
        } else {
            this.clearSearchCondition();
        }
    }

    private getPrice(priceId: string) {
        this.spinnerService.show();
        this.priceService.getById(priceId).subscribe((priceModel: PriceModel) => {
            this.priceFilterModel = priceModel;
            let view = this.priceConverter.toView(1, priceModel);
            this.priceSearch$.next(priceModel);
            this.priceView$.next(view);
        },
        () => {

        },
        () => {
            this.spinnerService.hide();
        });
    }

    focusOnPriceSearchCondition(event) {
        let searchBtn = $('#searchConditionSearchBtn')?.get(0);
        let searchBtnClick = searchBtn?.contains(event.srcElement);
        if (searchBtnClick) {
            this.focusingService.focus(this.searchTableComponent.focusingDirective);
        } else {
            this.focusingService.focus(this.searchConditionComponent.focusingDirective);
        }
    }

    onSearchResultPanelClick(event) {
        let copyBtn = $('#searchResultCopyBtn')?.get(0);
        let copyBtnClicked = copyBtn?.contains(event.srcElement);
        if (copyBtnClicked) {
            return;
        }
        if (event.type == 'click') {
            timer(200)
                .pipe(takeUntil(this.doubleClickSubject$))
                .subscribe(() => {
                    this.focusingService.focus(this.searchTableComponent.focusingDirective);
                    this.doubleClickSubject$.next();
                });
        }
        if (event.type == 'dblclick') {
            this.doubleClickSubject$.next();
        }
    }

    copyPrice(price: PriceView) {
        if (price.usageTypeCode == this.FILTER_USAGE_TYPE) {
            this.priceSearchFilterId = null;
            this.getPrice(price.priceId);
            this.focusingService.focus(this.searchConditionComponent.focusingDirective);
        }
    }

    copyFilter() {
        if (!this.priceFilterModel) {
            return;
        }
        let copyFilterView = this.priceConverter.toView(1, this.priceFilterModel);
        copyFilterView.priceName = copyFilterView.priceName + ' - Copy';
        copyFilterView.priceId = null;
        this.priceView$.next(copyFilterView);

        this.priceFilterModel = null;
        this.priceSearchFilterId = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).enable(!!this.priceFilterModel);
            this.actionbarService.updateState(handler);
        }, 0);
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null,
            "PRICE");
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-functions-context-menu',
    templateUrl: './security-functions-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityFunctionsContextMenuComponent {
    @Input() menuItems: OopsCategoryContextMenu[];
    @Input() security: SecurityGroupSecurityModel;
    @Output()
    onSelectMessageLogRule: EventEmitter<string> = new EventEmitter<string>();

    public showContextMenu(): boolean {
        return false;
    }

    public selectMessageLogRuleMenu(e) {
        this.onSelectMessageLogRule.emit(e);
    }

    public checkDisable(menu: OopsCategoryContextMenu): boolean {
        return menu.disable || (menu.actionId == "NEW" && this.security.newFlag == false);
    }
}
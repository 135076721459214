export class ProductInventoryDimensionViewModel {
    public productInventoryDimensionId: string;
    public attributeTypeCode: string;
    public attributeTypeName: string;
    public displaySequence: number;
    public inheritInventoryAttribute: boolean;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
}

import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSearchConditionView } from '../shared/security-group-search-condition.view';
import { select2DefaultOptionWithAllowClear } from '../shared/select2-configuration';
import { ActionConditionOption } from './action-condition-options';

@Component({
    selector: 'op-security-group-search-condition',
    templateUrl: './security-group-search-condition.component.html'
})
export class SecurityGroupSearchConditionComponent implements AfterViewInit {
    @Input() statusReferences: StatusReferenceModel[];
    @Input() executeFlag = true;
    @Output() onSearchClick = new EventEmitter();

    private readonly ACTIVE_STATUS = 'A';
    public select2Option: any;
    public condition = new SecurityGroupSearchConditionView();
    public actionOption = ActionConditionOption;
    public collapsed: boolean;
    public focused: boolean;

    constructor() {
        this.select2Option = select2DefaultOptionWithAllowClear;
    }

    ngOnInit(): void {
        this.condition.statusCode = this.ACTIVE_STATUS
    }

    ngAfterViewInit(): void {
        if (this.executeFlag == true) {
            this.searchClick();
        }  
    }

    searchClick() {
        this.onSearchClick.emit(this.condition);
    }

    clearCondition() {
        this.condition = new SecurityGroupSearchConditionView();
        this.condition.statusCode = this.ACTIVE_STATUS;
        this.onSearchClick.emit(this.condition);
    }

    onActionChange(e){
        this.condition.action = e;
    }

    onStatusCodeChange(e){
        this.condition.statusCode = e;
    }
}

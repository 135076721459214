import { Injectable } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";

@Injectable()
export class VehicleValidationService {
    public findDuplicateVehicle(forms: UntypedFormArray, ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "vehicle") {
                    if (ctl.value.vehicleGroupCode == ctlCheck.value.vehicleGroupCode &&
                        ctl.value.operatorVehicle == ctlCheck.value.operatorVehicle &&
                        ctl.value.vehicleTypeCode == ctlCheck.value.vehicleTypeCode &&
                        ctl.value.vehicleCompositionId == ctlCheck.value.vehicleCompositionId
                    ) {
                        duplicate = true;
                    }
                }
            }
        }
        return duplicate;
    }
}
import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { CountryReferenceModel, IdentityDocumentReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightDocumentDetailComponent } from "./detail/document-detail.component";
import { DocumentDetailDirective } from "./detail/document.directive";
import { DocumentView } from "./shared/document-view";
import { InsightDocumentTableComponent } from "./table/document-table.component";
import { cloneDeep } from "lodash";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-insight-detail-document',
    templateUrl: './insight-detail-document.component.html'
})

export class InsightDetailDocumentComponent {
    @Input() identityDocumentTypeReferences: IdentityDocumentReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isDocumentsEmpty: boolean = true;
    public isMoreThanOneDocuments: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public document = {} as DocumentView;
    public documents: DocumentView[] = new Array();
    public previousDocuments: DocumentView[] = new Array();

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @ViewChild(DocumentDetailDirective) documentDetailDirective: DocumentDetailDirective;
    @ViewChild(InsightDocumentDetailComponent) documentDetailComponent: InsightDocumentDetailComponent;
    @ViewChild(InsightDocumentTableComponent) documentTableComponent: InsightDocumentTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newDocumentDetail: InsightDocumentDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    public fillModelToForm(passengerDocuments: DocumentView[]) {
        this.clearData();
        if (passengerDocuments && passengerDocuments.length > 0) {
            this.fillModelInCase(passengerDocuments);
        }

        this.documents = passengerDocuments;
        this.previousDocuments = passengerDocuments;

        if (this.documents.length > 0) {
            this.isDocumentsEmpty = false;
        }

        if (this.documents.length == 1) {
            this.isMoreThanOneDocuments = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.document = {} as DocumentView;
    }

    private fillModelInCase(documents: DocumentView[]) {
        if (documents.length == 1) {
            this.fillModelSingleRecord(documents[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: DocumentView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.document = view;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneDocuments = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: DocumentView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightDocumentDetailComponent);

        let viewContainerRef = this.documentDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightDocumentDetailComponent = (<InsightDocumentDetailComponent>componentRefs.instance);
        component.countryReferences = this.countryReferences;
        component.identityDocumentTypeReferences = this.identityDocumentTypeReferences;
        
        if (dataEdit != null) {
            component.insightDocument = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newDocumentDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        this.isApplyBtnClicked = true;
        let insightDocument = this.documentDetailComponent.getInsightDocument();
        if (insightDocument) {
            this.savedata(insightDocument);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewDocument() {
        this.isApplyBtnClicked = true;
        let insightDocument = this.newDocumentDetail.getInsightDocument();
        if (insightDocument) {
            this.savedata(insightDocument);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private savedata(data: DocumentView) {
        if (this.documents?.length) {
            var filterIndex = this.documents.findIndex(x => x.no == data.no);
            if (data.primaryFlag === undefined) {
                data.primaryFlag = false;
            }

            this.updateDocumentViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryFlag = true;
            this.document = data;
            this.documents.push(data);
        }

        this.isDocumentsEmpty = false;
        this.documentTableComponent.dataGrid.instance.refresh();
    }

    private updateDocumentViews(filterIndex: number, data: DocumentView) {
        if (this.documents?.length > 0 && data.primaryFlag) {
            this.setOtherDocumentPrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.documents.length + 1;
            this.documents.push(data);
        }
        else {
            this.documents[filterIndex] = data;
        }
    }

    private setOtherDocumentPrimaryFlag(filterIndex: number) {
        let otherDocuments = this.documents;
        for (let otherDocument of otherDocuments) {
            if (otherDocument.no == filterIndex + 1) {
                continue;
            }

            otherDocument.primaryFlag = false;;
        }

        this.documents = otherDocuments;
    }

    private displayAfterSave() {
        if (this.documents?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.documentDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.documents.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }

        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        this.setMoreThanOneDocuments();
        this.deleteDataFromDocumentViews(data);
    }

    getDeleteData(): DocumentView {
        if (this.documents.length > 1) {
            return this.documentTableComponent.selectedItem;
        }

        return this.document;
    }

    setMoreThanOneDocuments() {
        if (this.documents.length > 1) {
            this.isMoreThanOneDocuments = true;
        } 
        else {
            this.isMoreThanOneDocuments = false;
        }
    }

    deleteDataFromDocumentViews(data: DocumentView) {
        let views = new Array<DocumentView>();
        if (this.documents != null) {
            views = this.documents;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.documents = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.documents.length == 1) {
            this.documents[0].primaryFlag = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForDocumentDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.documents.length == 0 || this.documents.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForDocumentDetailForm() {
        if (this.documents.length == 1) {
            this.document = this.documents[0];
            this.showAdd = true;
        }
        else {
            this.document = {} as DocumentView;
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.documentTableComponent.selectedItem);
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.documents.length == 0) {
            this.document = {} as DocumentView;
        }

        if (this.documents.length <= 1) {
            this.isMoreThanOneDocuments = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.documentDetailComponent) {
            return this.documentDetailComponent.setProcessing(false);
        }

        if (this.newDocumentDetail) {
            return this.newDocumentDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.documents.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.documents.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.documents.length == 1) {
            let copyDocument = cloneDeep(this.documents[0]);
            this.openCopyDetailForm(copyDocument);
            return;
        }

         if (!this.documentTableComponent.selectedItem) {
            return;
        }

        let copyDocument = cloneDeep(this.documentTableComponent.selectedItem);
        this.openCopyDetailForm(copyDocument);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneDocuments = true;
    }

    setCopyDocumentProperty(copyDocemunet: DocumentView) {
        copyDocemunet.no = null;
        copyDocemunet.individualIdentityDocumentId = null;
        copyDocemunet.primaryFlag = false;
    }

    openCopyDetailForm(copyDocemunet: DocumentView) {
        this.setCopyDocumentProperty(copyDocemunet);
        this.openDetail();
        this.loadDetailForm(copyDocemunet);
    }

    validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.documents.length <= 0) {
            return true;
        }

        if (this.documentDetailComponent && this.isApplyBtnClicked) {
            return this.documentDetailComponent.isValidForm();
        }

        if (this.newDocumentDetail && this.isApplyBtnClicked) {
            return this.newDocumentDetail.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.documentDetailComponent) {
            return this.documentDetailComponent.getErrorMessageForm();
        }

        if (this.newDocumentDetail) {
            return this.newDocumentDetail.getErrorMessageForm();
        }
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PriorityColorComponent } from "./priority-color.component";

@NgModule({
    imports: [         
        CommonModule 
    ],
    declarations: [
        PriorityColorComponent  
    ],
    exports: [
        PriorityColorComponent
    ]
})
export class PriorityColorModule { }
import { Injectable } from '@angular/core';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaHashTagModel } from 'src/app/core/models/reference-model/reference-media-model';

@Injectable()
export class MapperService {
    public mediaHashTagToSelect2Data(mediaHashTags: MediaHashTagModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let mediaHash of mediaHashTags) {
            let data = new Select2Data();
            data.id = mediaHash.mediaHashTagId
            data.text = mediaHash.mediaHashTagName;
            select2Data.push(data);
        }
        return select2Data;
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StatusBadgeComponent } from "./status-badge.component";
import { StatusBadgeLiteComponent } from "./status-badge-lite.component";

@NgModule({
    imports: [         
        CommonModule 
    ],
    declarations: [
        StatusBadgeComponent,
        StatusBadgeLiteComponent
    ],
    exports: [
        StatusBadgeComponent,
        StatusBadgeLiteComponent
    ]
})
export class StatusBadgeModule { }
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class RouteValidationService {

    public findDuplicateRoute(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "route") {   
                    if (ctl.value.operatorRoute == ctlCheck.value.operatorRoute &&
                        ctl.value.originRoute == ctlCheck.value.originRoute &&
                        ctl.value.destinationRoute == ctlCheck.value.destinationRoute
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }

}
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select2AttributeInherit, select2ChoiceMultiple } from 'src/app/modules/product-management/product-categories-content/shared/configuration/select2-inventory-attribute-configuration';
import { select2Attribute } from 'src/app/modules/product-management/product-categories-content/shared/configuration/select2-inventory-attribute-configuration';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-special-service-inventory-attribute',
    templateUrl: './inventory-attribute.component.html'
})
export class InventoryAttributeComponent implements OnInit, OnChanges {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() draftFlag = true;
    @Input() serviceCateSelected = false;

    public attributeOption: any = select2Attribute;
    public productAttributeInventoryValue: string[];
    public productAttributeType: Select2Data[];
    public disabled: boolean = false;
    public isInherit: boolean = false;
    public attributeInheritOption: any = select2AttributeInherit;
    public choiceMultipleOption: any = select2ChoiceMultiple;
    public focusing: boolean = false;
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['draftFlag'] || changes['serviceCateSelected']) {
            this.createProductAttributeType();
        }
    }

    ngOnInit(): void {
        this.createProductAttributeType();
    }

    public createProductAttributeType() {
        this.productAttributeType = [];
        this.productAttributeInventoryValue = [];
        if (this.draftFlag == true) {
            let routeType = new Select2Data("SERVICE", "Service");
            this.productAttributeType.push(routeType);
            this.productAttributeInventoryValue.push(routeType.id);
            this.createServiceCateAttributeType();
        }
    }

    private createServiceCateAttributeType() {
        if (this.serviceCateSelected) {
            let srvCategory = new Select2Data("SERVICECATEGORY", "Service Category");
            this.productAttributeType.push(srvCategory);
            this.productAttributeInventoryValue.push(srvCategory.id);
        }
    }

    get productAttributeInventoryValueData(): string | string[] {
        return this.productAttributeInventoryValue;
    }

    set productAttributeInventoryValueData(value: string | string[]) {
        this.productAttributeInventoryValue = <string[]>value;
    }
}

import { Observable } from "rxjs";
import { ExternalPaymentModel } from "../../models/payment-models/external-payment.model";
import { PaymentStatusModel } from "../../models/payment-models/payment-status.model";
import { DataTransGatewayService } from "./datatrans-gateway.service";

export interface IPaymentGatewayProvider {
    processPayment(payment: ExternalPaymentModel): Observable<PaymentStatusModel>
    handlePaymentPageResponse(): void
    getResponseStatus(): string;
    getResponseParam(key: string): string;
    resetResponseStatus(): void;
}

export const paymentProvider = new Map([
    ['DATATRANS', DataTransGatewayService]
]);
export const pointOfSalesConfigData = {
    types: [
        {
            typeCode: "location",
            typeName: "Location",
            areas: [
                {
                    areaCode: "region",
                    areaName: "Region",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                },
                {
                    areaCode: "country",
                    areaName: "Country",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                },
                {
                    areaCode: "airport",
                    areaName: "Airport/City",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                },
                {
                    areaCode: "locationgroup",
                    areaName: "Location Group",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                }
            ]
        },
        {
            typeCode: "organisation",
            typeName: "Organisation",
            orgType: [
                {
                    orgTypeCode: "type",
                    orgTypeName: "Type",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                },
                {
                    orgTypeCode: "group",
                    orgTypeName: "Group",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                },
                { orgTypeCode: "role",
					orgTypeName: "Role",
					operators: [
						  { "operatorCode": "=",
							"operatorName": "is, are"
						  },
						  { "operatorCode": "!=",
							"operatorName": "is, are not"
						  }						  
						]
				},	               
                {
                    orgTypeCode: "specific",
                    orgTypeName: "Specific",
                    operators: [
                        {
                            operatorCode: "=",
                            operatorName: "is, are"
                        },
                        {
                            operatorCode: "!=",
                            operatorName: "is, are not"
                        }
                    ]
                }
            ]
        }
    ]
}
import { ApiServiceBase } from '../../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Observable } from 'rxjs';
import { MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';

@Injectable({
  providedIn: 'root'
})
export class MediaTypeReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/media"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getAll(): Observable<MediaTypeReferenceModel[]> {
    return this.httpGet<any>(this.REFERENCE_PATH + "/mediatypereference", null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

}
import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event-package';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupReference } from 'src/app/core/models/security-model/security-group-reference.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { SecurityGroupSearchTableView } from '../../security-group-search/shared/security-group-search-table.view';
import { select2DefaultOptionWithAllowClear } from '../../security-group-search/shared/select2-configuration'
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
  selector: 'op-security-group-detail-form',
  templateUrl: './security-group-detail-form.component.html'
})
export class SecurityGroupDetailFormComponent extends OopsComponentFormBase implements OnChanges {
    private readonly SECURITY_GROUP_NAME = 'securityGroupName';
    private readonly STATUS_NAME = 'status';
    private readonly DELETE_STATUS_CODE = 'D';

    @Input() securityGroupDetail: SecurityGroupReference;
    @Input() newSecurityCode = false;
    @Input() focused: boolean;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() newSecurity = true;
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Output() onGroupDetailUpdate = new EventEmitter<SecurityGroupReference>();
    @Output() onGroupRename = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public statusOption: any;
    public securityGroupDetailList: SecurityGroupReference[] = [];
    public currentSecurityGroup = new SecurityGroupReference();
    public collapsed: boolean;
    public focusing: boolean = false;
    
    constructor(private formBuilder: UntypedFormBuilder,
        private securityGroupService: SecurityGroupService) { 
        super(formBuilder);
        this.setOptionControl();
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['securityGroupDetail']) {
            Object.assign(this.currentSecurityGroup, this.securityGroupDetail);
            this.formSetup();
        }
    }

    public initForm() {
        this.formSetup();
    }
    
    formSetup() {
        this.formGroup = this.formBuilder.group({
            securityGroupName: [null, Validators.required],
            status: ['A', Validators.required],
            commitDateTime: [{value: null, disabled: true}],
            commitByName: [{value: null, disabled: true}]
        });
        if (this.securityGroupDetail == null) {
            return;
        } else {
            this.fillModelToForm(this.securityGroupDetail);
        }
    }

    fillModelToForm(view: SecurityGroupReference) {
        this.formGroup.patchValue({
            securityGroupName: view.securityGroupName,
            status: view.statusCode,
            commitDateTime: view.commitDateTime,
            commitByName: view.commitByName
        })
        this.setRequiredValidator(this.SECURITY_GROUP_NAME);
        this.setRequiredValidator(this.STATUS_NAME);
    }

    public setRequiredValidator(formControlName: string) {
        this.formGroup.controls[formControlName].setValidators([Validators.required]);
        this.formGroup.controls[formControlName].updateValueAndValidity();
    }

    fillFormToModel(): SecurityGroupReference {
        let formValue = this.formGroup.value;
        let model: SecurityGroupReference = {
            securityGroupId: this.currentSecurityGroup.securityGroupId,
            securityGroupName: formValue.securityGroupName,
            statusCode: formValue.status,
            commitDateTime: this.currentSecurityGroup.commitDateTime,
            commitByName: this.currentSecurityGroup.commitByName
        };
        return model;
    }

    setOptionControl() {
        this.statusOption = select2DefaultOptionWithAllowClear
    }

    onFocus() {
        this.isFocused.emit(false);
    }

    onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        } 
    }

    apply() {
        this.startProcessing();
        this.setRequiredValidator(this.SECURITY_GROUP_NAME);
        if (this.currentSecurityGroup.securityGroupId != null && this.validForm()){
            let model = this.fillFormToModel();
            this.onGroupDetailUpdate.emit(model);
            this.onGroupRename.emit(model.securityGroupName);
            this.completeProcessing();
        }
    }

    cancel() {
        this.formSetup();
    }

    getEditSecurityGroupDetail(): SecurityGroupReference[] {
        return this.securityGroupDetailList;
    }

    addNewGroupDetail(newDetail: SecurityGroupReference) {
        this.securityGroupDetail = newDetail;
        Object.assign(this.currentSecurityGroup, this.securityGroupDetail);

        this.onGroupDetailUpdate.emit(newDetail);

        this.formSetup();
    }

    checkNameDuplicate() {
        let groupName = this.formGroup.get(this.SECURITY_GROUP_NAME).value;
        this.securityGroupService.searchSecurityGroup(groupName, '', '', '', '', 'A')
            .subscribe(
                (response: SecurityGroupSearchTableView[]) => {
                    let duplicated = response?.find(item => item.securityGroupName == groupName);
                    if (duplicated) {
                        this.formGroup.get(this.SECURITY_GROUP_NAME).setErrors({duplicate: true})
                    } else {
                        this.formGroup.get(this.SECURITY_GROUP_NAME).setErrors(null);
                    }
                }
            )
    }
    public statusChange(statusCode: string | string[]) {
        this.formGroup.controls[this.STATUS_NAME]?.setValue(statusCode);
        this.setRequiredValidator(this.STATUS_NAME);
    }

    public disableDelete(statusCode: string): boolean {
        return statusCode == this.DELETE_STATUS_CODE && !this.moduleSecurity?.deleteFlag;
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserLoginModel, UserModel } from '../models/user-model';
import { selectToken } from 'src/app/store/auth/auth.selectors';
import * as AuthActions from '../../store/auth/auth.actions';
import * as SettingActions from '../../store/settings/settings.actions';
import * as TabSessionAction from '../../store/tab-session/tab-session.actions';
import { 
  ACCEPT_COOKIES,
  AUTH_TOKEN,
  REMEMBER_ME_COOKIES,
  USER_LOGON_DATA, 
  STORAGE_LOGIN_ORGANISATION_ID, 
  STORAGE_LOGIN_USER, 
  USER_LOGON_NAME
} from 'src/app/modules/auth/shared/constants/auth.constant';
import { CookiesService } from 'src/app/shared/utils/cookies.service';
import { StringHelperService } from '../utils/string-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cookiesService: CookiesService,
    private store: Store<any>,
    private stringHelper: StringHelperService) { }

  public setSelectedOrganisation(organisationId: string) {
    this.sessionStorageSetItem(STORAGE_LOGIN_ORGANISATION_ID, organisationId);
    return true;
  }

  public getSelectedOrganisation() {
    return this.sessionStorageGetItem(STORAGE_LOGIN_ORGANISATION_ID);
  }

  public logout(): void {
    this.clearStorage();
    this.store.dispatch(AuthActions.logout());
    this.store.dispatch(TabSessionAction.deleteAllTabSession());
  }

  private clearStorage() {
    localStorage.removeItem(STORAGE_LOGIN_ORGANISATION_ID);
    localStorage.removeItem(STORAGE_LOGIN_USER);
    sessionStorage.clear();
  }

  public getUserInformation(): UserModel {
    var userJson = localStorage.getItem(STORAGE_LOGIN_USER);
    let user: UserModel = JSON.parse(userJson);
    return user;
  }

  public getToken(): string {
    let stateToken;
    this.store.select(selectToken)
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(token => {
        stateToken = token || this.sessionStorageGetItem(AUTH_TOKEN)
      })
    return stateToken
  }

  public getCurrentUserData() {
    const userData: UserLoginModel = JSON.parse(this.sessionStorageGetItem(USER_LOGON_DATA));
    if (!userData) {
      return;
    }
    return userData;
  }

  public cookiesAccepted(): boolean {
    var acceptCookiesValue = this.cookiesService.getItem(ACCEPT_COOKIES);
    if (!acceptCookiesValue) {
      return false;
    }
    return this.stringHelper.stringToBoolean(acceptCookiesValue); 
  }
  public rememberMeAccepted(): boolean {
    var rememberMeCookies = this.cookiesService.getItem(REMEMBER_ME_COOKIES);
    if (!rememberMeCookies) {
      return false;
    }
    return true;
  }
  public updateUserData(loadedUser: UserLoginModel) {
    if (loadedUser.token) {
      return this.store.dispatch(AuthActions.authenticateSuccess({
        token: loadedUser.token,
        expires_in: loadedUser.expires_in,
        username: loadedUser.username,
        orgId: loadedUser.orgId,
        isVerifyCode: loadedUser.isVerifyCode,
        acceptCookies: this.cookiesAccepted(),
        rememberMe: this.rememberMeAccepted()
      }))
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  sessionStorageSetItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
    return true;
  }

  sessionStorageGetItem(key: string) {
    return sessionStorage.getItem(key);
  }

  updateVerifyCode() {
    const userData = this.getCurrentUserData();
    const loadedUser = new UserLoginModel(
      userData?.token,
      userData?.expires_in,
      userData?.username,
      userData?.orgId,
      true
    )
    this.sessionStorageSetItem(USER_LOGON_DATA, JSON.stringify(loadedUser))
    if (loadedUser.token) {
      return AuthActions.authenticateSuccess({
        token: loadedUser.token,
        expires_in: loadedUser.expires_in,
        username: loadedUser.username,
        orgId: loadedUser.orgId,
        isVerifyCode: loadedUser.isVerifyCode,
        acceptCookies: this.cookiesAccepted(),
        rememberMe: this.rememberMeAccepted()
      })
    }
  }

  currentLogonUserAccountId() : string {
    const userNameLogonInfo = JSON.parse(this.sessionStorageGetItem(USER_LOGON_NAME));
    return userNameLogonInfo?.userAccountId;
  }
}

import { Injectable } from '@angular/core';

import { DateConverterService } from "src/app/core/utils/date-converter.service";

import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { InsightManagementModel } from 'src/app/core/models/reference-model/reference-general-model/Insight-management.model';
import { InsightCategory } from './insight-category';

@Injectable()
export class InsightCategoriesMapperService {

    readonly INSIGHT_ROUTE_PATH = '/main/insight/';

    constructor(private dateConverterService: DateConverterService) {
    }

    public toInsightCategoryViews(models: InsightManagementModel[], securities: SecurityGroupSecurityModel[]): InsightCategory[] {
        let views: InsightCategory[] = new Array();
        if (views) {
            for (let model of models) {
                let securityIndex = securities.findIndex(security => security.securityCode == model.securityCode);
                views.push(this.toOopsCategoryView(model, securities[securityIndex]));
            }
        }
        return views;
    }

    private toOopsCategoryView(model: InsightManagementModel, security: SecurityGroupSecurityModel): InsightCategory {
        var view = {} as InsightCategory
        view.name = model.insightName;
        view.modifiedBy = model.commitByName;
        view.modifiedDateTime = this.dateConverterService.convertDateTime24(model.commitDateTime);
        view.url = this.INSIGHT_ROUTE_PATH + model.url;
        view.security = security;
        return view;
    }

}
import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { StringUtils } from "src/app/shared/utils/string-utils";

@Directive({
    selector: '[emailValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailPatternValidatorDirective, multi: true }]
})
export class EmailPatternValidatorDirective implements Validator {
    private _isEnabled = true;
    @Input('emailValidate') set isEnable(value: any) {
        if (typeof value === 'boolean') {
            this._isEnabled = value;
        } else {
            this._isEnabled = true;
        }
    }
    constructor(private stringUtils: StringUtils) {

    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this._isEnabled || !control.value) return null;
        return this.stringUtils.isEmail(control.value) ? null : { notEmail: true };
    }
}
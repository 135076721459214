import { Component, EventEmitter, Input, Output } from '@angular/core';

const INVOICE_RECEIVER_OPTION = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'auto',
    dropdownAutoWidth: true
}

@Component({
    selector: 'op-document-invoice-type-selection',
    templateUrl: './document-invoice-type-selection.component.html'
})
export class DocumentInvoiceTypeSelectionComponent {
    public readonly requiredErrorMessage = 'Invoice Receiver is required.'
    public invoiceReceiverOption = INVOICE_RECEIVER_OPTION;
    public invoiceReceiverType: string;
    public processing: boolean = false;

    @Input()
    set data(value: string) {
        this.invoiceReceiverType = value;
    }
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter<string>();

    public onInvoiceReceiverChange(invoiceReceiverTypeCode: string | string[]) {
        this.invoiceReceiverType = invoiceReceiverTypeCode as string;
        this.dataChange.emit(this.invoiceReceiverType)
    }

    public validate(): boolean {
        this.processing = true;
        if (!this.invoiceReceiverType) {
            return false;
        }
        this.processing = false;
        return true;
    }

    public getErrorMessage(): string {
        if (!this.invoiceReceiverType) {
            return this.requiredErrorMessage;
        }
        return null;
    }

}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { Helper } from "src/app/shared/helper/app.helper";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { DetailsConverter } from "../../../price-dimensions/shared";
import { PriceModel } from "../../../prices/shared/models";
import { select2Status, select2UsageType } from "../../../rules/price-rule-detail/shared/pricing-detail-information-configuration";

@Component({
    selector: 'op-price-conditions-details-general',
    templateUrl: './general.component.html'
})

export class GeneralComponent extends OopsComponentFormBase implements OnChanges {
    private readonly defaultUsageTypeCode = 'PRICECONDTEMPLATE';
    focusing = false;
    showRequiredFieldAsterisk = true;
    statusOption = select2Status;
    usageTypeOption = select2UsageType;

    statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))))

    usageTypeReferences$ = this.usageTypeReferenceService.getUsageTypeReferences()
        .pipe(map(res => res.map(r => new Select2Data(r.usageTypeCode, r.usageTypeName))))

    @Input() price: PriceModel;
    @Input() disabled: boolean = false;
    @Output() focus = new EventEmitter();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective; 

    constructor(protected helper: Helper,
        private fb: UntypedFormBuilder,
        private converter: DetailsConverter,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private statusReferenceService: StatusReferenceService) {
            super(fb);
    }

    public initForm() {
        let formGroup = this.converter.toFormGroup(this.price, this.defaultUsageTypeCode);
        if (formGroup) {
            this.formGroup = formGroup;
        }
        this.setupSecurityDisabled();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['price']) {
            this.initForm();
        }
    }

    private setupSecurityDisabled() {
        if (this.disabled == true) {
            this.formGroup.disable();
            return;
        }
        this.formGroup.get('priceCode').enable();
        this.formGroup.get('priceName').enable();
        this.formGroup.get('status').enable();
    }

    public getValues(price: PriceModel): PriceModel {
        this.startProcessing();
        let form = this.formGroup.getRawValue();
        if (!form.priceName) {
            return null;
        }

        price.organisationId = form.organisationId;
        price.priceId = form.priceId;
        price.priceCode = form.priceCode;
        price.priceName = form.priceName;
        price.usageTypeCode = form.usageTypeCode;
        price.status = form.status;

        this.completeProcessing();
        return price;
    }

    public resetForm() {
        this.completeProcessing();
        this.price = new PriceModel();
        this.initForm();
    }

    public onFocusingChange(focusing: boolean) {
        if (focusing) {
            this.focus.emit();
        }
    }

    public onUsageTypeCodeChange(value: string | string[]) {
        let usageTypeCode = value instanceof Array ? value[0] : value;
        this.formGroup.get('usageTypeCode').patchValue(usageTypeCode);
    }

    public onStatusChange(value: string | string[]) {
        let status = value instanceof Array ? value[0] : value;
        this.formGroup.get('status').patchValue(status);
    }
}
import { Directive } from '@angular/core';

@Directive({
  selector: '[securityLevel2Table]'
})
export class SecurityLevel2TableDirective {

  constructor() { }

}

import { Select2Themes } from "src/app/modules/pricing/rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/select2-options.service";

declare var moment: any;

export const TypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const TypeProductOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<product type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const TypeDateOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<date type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const TypeStatusOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<status type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ProductStatusOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<select>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const OrderStatusOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<select>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const TypeLocationOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<location type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ConditionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    theme: Select2Themes.CONDITION_SELECTOR_THEME,
    width: "auto",
}

export const DateConditionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    theme: Select2Themes.CONDITION_SELECTOR_THEME,
    width: "auto",
}


export const StatusCodeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    multiple: "multiple",
    placeholder: "<select>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const OutStandingBalanceOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Y/N>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const LocationPointTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<O/D>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const LocationOption = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
}

export const StartDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
    placeholder: '<Start Date>'
}

export const EndDateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    },
    placeholder: '<End Date>'
}

export const ProductCategoryOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<product categories>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ProductGroupOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<product groups>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ProductTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<product type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const RegionOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<region>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const CountryOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: 0,
    placeholder: "<country>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const LocationGroupOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<location>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const AirportCityOption = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    focus: true,
}

export const select2LocationGroupOption = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    focus: true,
}

export const ProductNumberTypeOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: -1,
    placeholder: "<Number Type>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const ProviderOption = {
    allowClear: false,
    dropdownAutoWidth: true,
    minimumResultsForSearch: 0,
    placeholder: "<Provider>",
    theme: Select2Themes.SINGLE_VALUE_SELECTOR_THEME,
    width: "auto"
}

export const select2InheritMultiple = {
    allowClear: false,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxDataGridModule } from "devextreme-angular";
import { NgxDropzoneModule } from "ngx-dropzone";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { CommentDetailComponent } from "./comment-detail/comment-detail.component";
import { CommentListComponent } from "./comment-list/comment-list.component";
import { ActivityDetailCommentComponent } from './comment.component'

@NgModule({
    declarations: [
        ActivityDetailCommentComponent,
        CommentDetailComponent,
        CommentListComponent
    ],
    imports: [
        CommonModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        NgxDropzoneModule,
        ReactiveFormsModule,
        Select2Module,
        PanelsModule,
        UiModule
    ],
    exports: [
        ActivityDetailCommentComponent,
        CommentDetailComponent,
        CommentListComponent
    ]
})
export class ActivityDetailCommentModule {}
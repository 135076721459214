import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgbNavModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { DxCheckBoxModule, DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TreeModule } from "primeng/tree";
import { ActivityDomainModule } from "src/app/core/components/activity-domain/activity-domain.module";
import { OrdinalModule } from "src/app/core/pipes/ordinal/ordinal.module";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ProductAttributeAndRuleModule } from "../../merchandize/attribute-and-rule/attribute-and-rule.module";
import { MerchandizeDetailModule } from "../../merchandize/merchandize-detail/merchandize-detail.module";
import { FeeDetailComponent } from "./fee-detail.component";
import { GeneralComponent } from "./general/general.component";
import { ProductConditionDetailComponent } from "./product-condition/product-condition-detail/product-condition-detail.component";
import { ProductConditionComponent } from "./product-condition/product-condition.component";
import { ServiceCategorySalesBucketComponent } from "./service-category-sales-bucket/service-category-sales-bucket.component";
import { TreeComponent } from "./tree/tree.component";
import { TranslationNameModule } from "src/app/core/components/translation-name/translation-name.module";
import { TranslationTextModule } from "src/app/core/components/translation-text/translation-text.module";
import { TextMediaModule } from "src/app/core/components/text-media/text-media.module";
import { TranslationMediaModule } from "src/app/core/components/translation-media/translation-media.module";

@NgModule({
    declarations: [
        ProductConditionComponent,
        ProductConditionDetailComponent,
        FeeDetailComponent,
        GeneralComponent,
        ServiceCategorySalesBucketComponent,
        TreeComponent
    ],
    exports: [
        FeeDetailComponent
    ],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        BsDropdownModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        MatIconModule,
        MerchandizeDetailModule,
        NgbNavModule,
        NgbPaginationModule,
        OrdinalModule,
        PanelsModule,
        ProductAttributeAndRuleModule,
        ReactiveFormsModule,
        Select2Module,
        TextMediaModule,
        TranslationMediaModule,
        TranslationNameModule,
        TranslationTextModule,
        TreeModule,
        UiModule
    ]
})
export class FeeDetailModule {}
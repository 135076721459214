import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { OrderDetailIndividualPhoneView } from "../../..";
import { InsightPassengerCommunicationPhoneModel } from "src/app/core/models/individual-model";
import { PresetPhoneTypeSelectionComponent } from "src/app/core/components/phone-type-selection/preset-phone-type-selection.component";
import { cloneDeep } from 'lodash';
import { NgForm, NgModel } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Component({
    selector: 'op-order-detail-phone-form',
    templateUrl: './order-detail-phone-form.component.html'
})
export class OrderDetailPhoneFormComponent implements AfterViewInit, OnDestroy {
    readonly ERROR_CLASS = 'builder-dropdown-invalid';
    view: OrderDetailIndividualPhoneView;
    processing = false;
    @Input() individualId: string;
    @Input() 
    set data(view: OrderDetailIndividualPhoneView) {
        if (!view) return;
        this.view = cloneDeep(view);
    }
    @Output() apply = new EventEmitter<OrderDetailIndividualPhoneView>();
    @Output() close = new EventEmitter();
    @Output() undo = new EventEmitter();

    @ViewChild(PresetPhoneTypeSelectionComponent) presetPhoneTypeSelectionComponent: PresetPhoneTypeSelectionComponent;
    @ViewChild(NgForm) form: NgForm;

    private destroy$ = new Subject();

    constructor(private changeDetector: ChangeDetectorRef) {

    }
    
    ngAfterViewInit(): void {
        this.checkIfErrorPresist();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private checkIfErrorPresist() {
        this.form.valueChanges
            .pipe(
                filter(_ => this.processing == true),
                takeUntil(this.destroy$)
            )
            .subscribe(_ => this.changeDetector.markForCheck());
    }

    onUndoClick() {
        this.undo.emit();
    }

    onApplyClick() {
        this.processing = true;
        if (!this.form.valid) {
            return;
        }
        this.view.communicationPhoneTypeName = this.presetPhoneTypeSelectionComponent.getSelectedText();
        this.apply.emit(this.view);
    }

    onClearClick() {
        this.resetInputFields();
    }

    onCloseClick() {
        this.close.emit();
    }
    
    onPhonePresetSelected(phone: InsightPassengerCommunicationPhoneModel) {
        if (!phone) return;
        this.view.communicationPhoneId = phone.communicationPhoneId;
        this.view.communicationPhoneTypeCode = phone.communicationPhoneTypeCode;
        this.view.countryPhonePrefix = phone.communicationPhonePrefix;
        this.view.phoneNumber = phone.phoneNumber;
    }

    private resetInputFields() {
        this.view = this.getEmptyView();
        this.processing = false;
    }

    private getEmptyView(): OrderDetailIndividualPhoneView {
        return {
            orderId: null,
            communicationPhoneId: null,
            communicationPhoneTypeCode: null,
            communicationPhoneTypeName: null,
            countryPhonePrefix: null,
            phoneNumber: null,
            primaryFlag: this.view.primaryFlag,
            orderSalesStatusCode: null
        }
    }

    controlError(model: NgModel): boolean {
        return model?.errors && this.processing;
    }
}
import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";

import { LocationReferenceService } from "src/app/core/services/airline-services";

import { ProductGroupReferenceModel, ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2DefaultOption, select2DefaultOptionWithAllowClear, select2DeptOption, select2ArrOption } from "../shared/transport-search-information-configuration";
import { LocationReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { ProductSchedulePeriodModel } from "src/app/core/models/product-model/product-schedule-period-model";
import { ProductScheduleVersionModel } from "src/app/core/models/product-model/product-schedule-period-model/product-schedule-version.model";
import { TransportSearchConditionView } from "../shared/transport-search-condition.view";
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { StateConditionConstant, StateConstant } from "../../../../shared/constant/state.constant";

declare var $: any;

@Component({
    selector: 'op-transport-search-condition',
    templateUrl: './transport-search-condition.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
        .loading-spinner {
            margin-top: 12px;
            position: absolute;
            right: 34px;
        }
        `
    ]
})
export class TransportSearchConditionComponent extends OopsComponentFormBase implements OnChanges {
    readonly errorGroupRequired = ' Product group is required.';
    readonly DATA_USAGE_TYPE = 'DATA';
    @Input() advanceSearchMode: boolean;
    @Input() productSearchFilterId: string = null;
    @Input() transportSearchConditionView: TransportSearchConditionView;
    @Input() productSearchFilters: Select2Data[];
    @Input() productGroupReference$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    @Input() productTypeGroup$ = new BehaviorSubject<ProductTypeGroupModel[]>(null)
    @Input() usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null)
    @Input() statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() productSchedulePeriod$ = new BehaviorSubject<ProductSchedulePeriodModel[]>(null);
    @Input() saveFilter: boolean = false;
    @Input() productGroup: string;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onSearch = new EventEmitter<TransportSearchConditionView>();
    @Output() onClear = new EventEmitter();
    @Output() productSearchFilterIdChange = new EventEmitter<string>();
    @Output() formStatus = new EventEmitter<boolean>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("departureSelect2") departureSelect2: ElementRef;
    @ViewChild("arrivalSelect2") arrivalSelect2: ElementRef;
    
    public productTypeGroupFilter$: BehaviorSubject<ProductTypeGroupModel[]>;
    public productScheduleVersion$ = new BehaviorSubject<ProductScheduleVersionModel[]>(null);

    focusing: boolean = false;
    select2DefaultOption = select2DefaultOptionWithAllowClear;
    select2DefaultOptionMandatory = select2DefaultOption;
    select2DeptOption = select2DeptOption;
    select2ArrOption = select2ArrOption;

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get productTypeGroupCode(): string | string[] {
        return this.form.controls['productTypeGroupCode'].value;
    }

    set productTypeGroupCode(value: string | string[]) {
        this.form.controls['productTypeGroupCode'].patchValue(value);
    }

    get productGroupCode(): string | string[] {
        return this.form.controls['productGroupCode'].value;
    }

    set productGroupCode(value: string | string[]) {
        this.form.controls['productGroupCode'].patchValue(value);
    }

    constructor(
        formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef,
        private locationReferenceService: LocationReferenceService,
        private actionService: ActionService) {
        super(formBuilder);
        this.select2DeptOption.ajax = this.searchLocation();
        this.select2ArrOption.ajax = this.searchLocation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["transportSearchConditionView"]) {
            let view = this.transportSearchConditionView;
            this.productSearchFilterId = view?.productId ?? this.productSearchFilterId;
            if (this.formGroup) {
                this.formGroup.patchValue({
                    productTypeGroupCode: view.productTypeCode,
                    productName: view.searchName,
                    productGroupCode: view.productGroupCode,
                    usageTypeCode: view.searchUsageTypeCode,
                    statusCode: view.searchStatusCode,
                    providerId: view.providerId,
                    flightNumber: view.flightNumber,
                    restrtictionLocationDepartureId: view.restrtictionLocationDepartureId,
                    departureId: view.departureId,
                    departureData: view.departureData,
                    restrtictionLocationArrivalId: view.restrtictionLocationArrivalId,
                    arrivalId: view.arrivalId,
                    arrivalData: view.arrivalData,
                    productSchedulePeriodId: view.productSchedulePeriodId,
                    productScheduleVersionId: view.productScheduleVersionId,
                    state: this.getState(view),
                    stateCondition: this.getStateCondition(view)
                });
                this.filterProductTypeGroup(view.productGroupCode);
            }
        }

        if (changes["advanceSearchMode"]) {
            this.saveFilter = false;
        }
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            productTypeGroupCode: new UntypedFormControl(null),
            productGroupCode: new UntypedFormControl(this.productGroup, Validators.required),
            productName: new UntypedFormControl(null),
            usageTypeCode: new UntypedFormControl(this.DATA_USAGE_TYPE),
            statusCode: new UntypedFormControl(StatusConstant.ACTIVE),
            providerId: new UntypedFormControl(null),
            flightNumber: new UntypedFormControl(null),
            departureId: new UntypedFormControl(null),
            restrtictionLocationDepartureId: new UntypedFormControl(null),
            departureData: new UntypedFormControl(null),
            arrivalId: new UntypedFormControl(null),
            restrtictionLocationArrivalId: new UntypedFormControl(null),
            arrivalData: new UntypedFormControl(null),
            productSchedulePeriodId: new UntypedFormControl(null),
            productScheduleVersionId: new UntypedFormControl(null),
            state: new UntypedFormControl(null),
            stateCondition: new UntypedFormControl(null)
        });
        if (this.productGroup) {
            this.filterProductTypeGroup(this.productGroup);
        }
        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());

        if (this.productSearchFilterId) {
            this.onProductSearchFilterIdChange(this.productSearchFilterId);
        }
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    public onAdvanceSearchClick() {
        this.advanceSearchMode = !this.advanceSearchMode;
        this.advanceSearchModeChange.emit(this.advanceSearchMode);
    }

    public doSearch() {
        this.onSearch.emit(this.getValues());
    }

    public clearCondition() {
        this.saveFilter = false;
        this.onClear.emit();
    }

    onProductSearchFilterIdChange(productId: any) {
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/TRANSPORT', productId, this.getSearchFilterName(productId));
        this.productSearchFilterIdChange.emit(productId);
    }

    public getValues(saveFilter: boolean = false): TransportSearchConditionView {
        this.saveFilter = saveFilter;
        this.changeDetectionRef.detectChanges();
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): TransportSearchConditionView {
        let model = {} as TransportSearchConditionView;
        model.searchName = this.getFormControlValue("productName");
        model.productGroupCode = this.getFormControlValue("productGroupCode");
        model.productTypeCode = this.getFormControlValue("productTypeGroupCode");
        model.searchUsageTypeCode = this.getFormControlValue("usageTypeCode");
        model.searchStatusCode = this.getFormControlValue("statusCode");
        model.statusCode = StatusConstant.ACTIVE;
        model.providerId = this.getFormControlValue("providerId");
        model.flightNumber = this.getFormControlValue("flightNumber");
        model.departureId = this.getFormControlValue("departureId");
        model.departureData = this.getDepartureName();
        model.restrtictionLocationDepartureId = this.getFormControlValue("restrtictionLocationDepartureId");
        model.arrivalId = this.getFormControlValue("arrivalId");
        model.arrivalData = this.getArrivalName();
        model.restrtictionLocationArrivalId = this.getFormControlValue("restrtictionLocationArrivalId");
        model.productSchedulePeriodId = this.getFormControlValue("productSchedulePeriodId");
        model.productScheduleVersionId = this.getFormControlValue("productScheduleVersionId");
        this.getStateFlag(model);
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public productGroupChange(value) {
        this.filterProductTypeGroup(value);
    }

    public filterProductTypeGroup(productGroupCode) {
        if (this.productTypeGroup$.value) {
            this.productTypeGroupFilter$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
            if (productGroupCode) {
                var filter = this.productTypeGroup$.value.filter(x => x.productGroupCode == productGroupCode);
                this.productTypeGroupFilter$.next(filter);
                this.productTypeGroupCode = null;
            }
        }
    }

    public productGroupEmpty(): boolean {
        if (this.productGroupCode) {
            return false;
        }
        return true;
    }

    public createProductScheduleVersion(productSchedulePeriodId) {
        this.productScheduleVersion$ = new BehaviorSubject<ProductScheduleVersionModel[]>(null);
        if (this.productSchedulePeriod$.value && productSchedulePeriodId) {
            var productSchedulePeriod = this.productSchedulePeriod$.value.filter(x => x.productSchedulePeriodId == productSchedulePeriodId);
            this.productScheduleVersion$.next(productSchedulePeriod[0].productScheduleVersions);
        }
        this.productScheduleVersionId = null;
    }

    public findProductSchedulePeriodId(productScheduleVersionId): string {
        if (this.productSchedulePeriod$.value && productScheduleVersionId) {
            var productSchedulePeriods = this.productSchedulePeriod$.value;
            for (let productSchedulePeriod of productSchedulePeriods) {
                var canFind = this.findInProductScheduleVersion(productScheduleVersionId, productSchedulePeriod.productScheduleVersions);
                if (canFind) {
                    return productSchedulePeriod.productSchedulePeriodId;
                }
            }
        }
    }

    private findInProductScheduleVersion(productScheduleVersionId, productScheduleVersions: ProductScheduleVersionModel[]): boolean {
        if (productScheduleVersions?.length) {
            var filter = productScheduleVersions.filter(x => x.productScheduleVersionId == productScheduleVersionId);
            if (filter?.length) {
                return true;
            }
        }
    }

    private searchLocation() {
        return {
            transport: (params, success, failure) => {
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, null)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }

    public validateForm(): boolean {
        this.startProcessing();
        this.changeDetectionRef.detectChanges();
        return this.validForm();
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.errorGroupRequired;
        }
    }

    public getSearchFilterName(productId: string) {
        return this.productSearchFilters?.find(item => item.id == productId)?.text;
    }

    get productSearchFilterIdData(): string | string[] {
        return this.productSearchFilterId;
    }

    set productSearchFilterIdData(value: string | string[]) {
        this.productSearchFilterId = <string>value;
    }

    get providerId(): string | string[] {
        return this.form.controls['providerId'].value;
    }

    set providerId(value: string | string[]) {
        this.form.controls['providerId'].patchValue(value);
    }

    get productSchedulePeriodId(): string | string[] {
        return this.form.controls['productSchedulePeriodId'].value;
    }

    set productSchedulePeriodId(value: string | string[]) {
        this.form.controls['productSchedulePeriodId'].patchValue(value);
    }

    get productScheduleVersionId(): string | string[] {
        return this.form.controls['productScheduleVersionId'].value;
    }

    set productScheduleVersionId(value: string | string[]) {
        this.form.controls['productScheduleVersionId'].patchValue(value);
    }

    get departureId(): string | string[] {
        return this.form.controls['departureId'].value;
    }

    set departureId(value: string | string[]) {
        this.form.controls['departureId'].patchValue(value);
    }

    get arrivalId(): string | string[] {
        return this.form.controls['arrivalId'].value;
    }

    set arrivalId(value: string | string[]) {
        this.form.controls['arrivalId'].patchValue(value);
    }

    get usageTypeCode(): string | string[] {
        return this.form.controls['usageTypeCode'].value;
    }

    set usageTypeCode(value: string | string[]) {
        this.form.controls['usageTypeCode'].patchValue(value);
    }

    get statusCode(): string | string[] {
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }

    get state(): string | string[] {
        return this.form.controls['state'].value;
    }

    set state(value: string | string[]) {
        this.form.controls['state'].patchValue(value);
    }

    get stateCondition(): string | string[] {
        return this.form.controls['stateCondition'].value;
    }

    set stateCondition(value: string | string[]) {
        this.form.controls['stateCondition'].patchValue(value);
    }

    private getStateFlag(model: TransportSearchConditionView) {
        if (!this.state || this.state == StateConstant.NONE) {
            return;
        }
        if (this.state == StateConstant.DRAFT && this.stateCondition == StateConditionConstant.NONE) {
            return;
        }
        if (this.state == StateConstant.FINAL && this.stateCondition == StateConditionConstant.NONE) {
            return;
        }
        if (this.state == StateConstant.DRAFT && this.stateCondition == StateConditionConstant.CHECKED) {
            model.draftFlag = true;
            return;
        }
        if (this.state == StateConstant.DRAFT && this.stateCondition == StateConditionConstant.UNCHECKED) {
            model.draftFlag = false;
            return;
        }
        if (this.state == StateConstant.FINAL && this.stateCondition == StateConditionConstant.CHECKED) {
            model.finalFlag = true;
            return;
        }
        if (this.state == StateConstant.FINAL && this.stateCondition == StateConditionConstant.UNCHECKED) {
            model.finalFlag = false;
            return;
        }
    }

    private getState(view: TransportSearchConditionView): string {
        if (view.finalFlag == true || view.finalFlag == false) {
            return StateConstant.FINAL;
        }
        if (view.draftFlag == true || view.draftFlag == false) {
            return StateConstant.DRAFT;
        }
        return StateConstant.NONE;
    }

    private getStateCondition(view: TransportSearchConditionView): string {
        if (this.getState(view) == StateConstant.FINAL) {
            return this.checkedStateCondition(view.finalFlag);
        }        
        if (this.getState(view) == StateConstant.DRAFT) {
            return this.checkedStateCondition(view.draftFlag);
        }
        return StateConditionConstant.NONE;
    }

    private checkedStateCondition(value: boolean) {
        if (value == true) {
            return StateConditionConstant.CHECKED;
        }
        if (value == false) {
            return StateConditionConstant.UNCHECKED;
        }
        return StateConditionConstant.NONE;
    }

    public updateDepartureArrival(departureId: string, arrivalId: string) {
        this.departureId = departureId;
        this.arrivalId = arrivalId;
        this.changeDetectionRef.detectChanges();
    }

    private getDepartureName(): Select2Data[] {
        var data = $(this.departureSelect2.nativeElement).select2('data');
        if (data?.length) {
            return this.createLocationOptionList(data[0].id, data[0].text);
        }
        return null;
    }

    private getArrivalName(): Select2Data[] {
        var data = $(this.arrivalSelect2.nativeElement).select2('data');
        if (data?.length) {
            return this.createLocationOptionList(data[0].id, data[0].text);
        }
        return null;
    }

    private createLocationOptionList(id: string, name: string) {
        let valueReturen: Select2Data[] = new Array();
        let value = new Select2Data(id, name);
        valueReturen.push(value);
        return valueReturen;
    }
}
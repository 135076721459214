import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { OrganisationAttributeModel } from "../../models/organisation-model";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class OrganisationAttributeService extends ApiServiceBase {
    private readonly PATH = '/organisation/organisationAttributes';

    constructor(private httpClient: HttpClient,
        private authService: AuthService) { 
            super(httpClient, authService)
    }

    public getOrganisationAttribute(): Observable<OrganisationAttributeModel[]> {
        return this.httpGet<OrganisationAttributeModel[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }

    public getOrganisationOwnerAttribute(): Observable<OrganisationAttributeModel[]> {
        return this.httpGet<OrganisationAttributeModel[]>(this.PATH + '/owner', null)
            .pipe(
                map(response => response.body)
            )
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ScheduleQueueActionReferenceModel } from "../../models/reference-model/reference-general-model";

@Injectable({
    providedIn: "root",
})
export class ScheduleQueueActionReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/ScheduleQueueActionReferences";

    constructor(httpClient: HttpClient, authService: AuthService) {
        super(httpClient, authService);
    }

    public getScheduleQueueActionReferences(): Observable<ScheduleQueueActionReferenceModel[]> {
        return this.httpGet<ScheduleQueueActionReferenceModel[]>(this.PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { OrderDetailIndividualModel } from 'src/app/core/models/order-model/order-detail-individual.model';
import { OrderDetailServices } from 'src/app/core/services/order-services';
import { OrdersMapperService } from '../../order-mapper.service';
import { OrderDetailIndividualView } from '../../views/order-detail-individual.view';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-guardian',
    templateUrl: './order-detail-guardian.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailGuardianComponent {

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("changeSessionId") changeSessionId: string;

    @ViewChild("panel") panel: any;

    public collapsed: boolean;
    public focused: boolean;
    public orderDetailGuardianView: OrderDetailIndividualView[] = new Array<OrderDetailIndividualView>();
    public countNumberOfItems: number = 0;

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }

    public loadOrderDetailGuardian(orderId: string, parentOrderId: string): Observable<boolean> {
        if (!orderId || !parentOrderId) {
            return;
        }
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailIndividualGuardian(orderId, parentOrderId, this.changeSessionId)
            .subscribe(
                (result: OrderDetailIndividualModel[]) => {
                        this.fillNumberOfItems(result);
                        this.orderDetailGuardianView = this.mapperService.toOrderDetailIndividualViews(result);
                        this.checkCollape();
                        this.changeDetection.detectChanges();
                        observer.next(true);
                        observer.complete();
                    }
                );
        });
    }

    public getPhoneCaption(phoneType: string): string {
        const phoneCaption: string = "Phone";
        if (!phoneType) {
            return phoneCaption;
        }
        return phoneCaption + " - " + phoneType;
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    private checkCollape(): void {
        if (!this.orderDetailGuardianView || this.orderDetailGuardianView.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    private fillNumberOfItems(models: OrderDetailIndividualModel[]) {
        if (!models || models.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = models.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
}
import { Injectable } from '@angular/core';
import moment from 'moment';

import { DateConverterService } from './date-converter.service';

@Injectable({
    providedIn: 'root'
})
export class DateHelperService {

    constructor(private dateConversionService: DateConverterService) {

    }
    public DayDifferent(date1: Date, date2: Date): number {

        const dateOnly1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const dateOnly2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const timeDifferent = dateOnly2.getTime() - dateOnly1.getTime();
        return timeDifferent / (1000 * 3600 * 24);
    }

    public addDay(dateValue: Date, numberOfDay: number): Date {
        
        return moment(dateValue)
            .add(numberOfDay, 'd')
            .toDate();
    }

    public getEarlyDate(...dates: Date[]): Date {
        return dates?.reduce((a, b) => {
            if (!a || b < a) return b;
            return a;
        }, null);
    }

    public getLateDate(...dates: Date[]): Date {
        return dates?.reduce((a, b) => {
            if (!a || b > a) return b;
            return a;
        }, null);
    }
    public passCurrentDate(date: Date): boolean {

        const dateValue = this.dateConversionService.dateToDateWithoutTime(date);
        const currentDate = this.dateConversionService
            .dateToDateWithoutTime(new Date());

        if (currentDate > dateValue) {
            return true;
        }
        return false;
    }

    private calculateCurrentMonday(): Date {
        let currentDate = new Date();
        let day = currentDate.getDay();
        let diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(currentDate.setDate(diff));
    }
    private calculateFirstDateOfMonth(date: Date): Date {
        return new Date(date.setDate(1));
    }
    private calculateLastDateOfMonth(date: Date): Date {
        let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return new Date(date.setDate(lastDate));
    }

    public getCurrentDate(): string {
        return this.dateConversionService.toDateFormatYearMonthDate(new Date());
    }
    public getYesterDayDate(): string {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        return this.dateConversionService.toDateFormatYearMonthDate(currentDate);
    }
    public getTomorrowDate(): string {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        return this.dateConversionService.toDateFormatYearMonthDate(currentDate);
    }
    public getCurrentMonday(): string {
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateCurrentMonday());
    }
    public getNextMonday(): string {
        let currentMonday = this.calculateCurrentMonday();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentMonday.setDate(currentMonday.getDate() + 7)));
    }
    public getNextSunday(): string {
        let currentMonday = this.calculateCurrentMonday();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentMonday.setDate(currentMonday.getDate() + 13)));
    }
    public getPreviousMonday(): string {
        let currentMonday = this.calculateCurrentMonday();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentMonday.setDate(currentMonday.getDate() - 7)));
    }
    public getPreviousSunday(): string {
        let currentMonday = this.calculateCurrentMonday();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentMonday.setDate(currentMonday.getDate() - 1)));
    }
    public getFirstDateOfCurrentMonth() {
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateFirstDateOfMonth(new Date()))
    }
    public getLastDateOfCurrentMonth() {
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateLastDateOfMonth(new Date()))
    }
    public getFirstDateOfPreviousMonth() {
        let currentDate = new Date();
        let previousMonthDate = new Date(currentDate.setDate(0));
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateFirstDateOfMonth(new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), previousMonthDate.getDate())))
    }
    public getLastDateOfPreviousMonth() {
        let currentDate = new Date();
        let previousMonthDate = new Date(currentDate.setDate(0));
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateLastDateOfMonth(new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), previousMonthDate.getDate())))
    }
    public getFirstDateOfNextMonth() {
        let thisLastDate = this.calculateLastDateOfMonth(new Date());
        let nextFirstDate = thisLastDate.setDate(thisLastDate.getDate() + 1);
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(nextFirstDate))
    }   
    public getLastDateOfNextMonth() {
        let thisLastDate = this.calculateLastDateOfMonth(new Date());
        let nextFirstDate = new Date(thisLastDate.setDate(thisLastDate.getDate() + 1));
        return this.dateConversionService.toDateFormatYearMonthDate(this.calculateLastDateOfMonth(new Date(nextFirstDate.getFullYear(), nextFirstDate.getMonth(), nextFirstDate.getDate())))
    }
    public getPast7Days() {
        let currentDate = new Date();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentDate.setDate(currentDate.getDate() - 7)))
    }
    public getNext7Days() {
        let currentDate = new Date();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentDate.setDate(currentDate.getDate() + 7)))
    }
    public getTodayPast6() {
        let currentDate = new Date();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentDate.setDate(currentDate.getDate() - 6)))
    }
    public getTodayNext6() {
        let currentDate = new Date();
        return this.dateConversionService.toDateFormatYearMonthDate(new Date(currentDate.setDate(currentDate.getDate() + 6)))
    }
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SecurityFunctionsContextMenuComponent } from './context-menu/security-functions-context-menu.component';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { OopsCategory } from 'src/app/core/components/categories';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-functions-table',
    templateUrl: './security-functions-table.component.html',
    styleUrls: ['./security-function-table.component.css']
})
export class SecurityFunctionsTableComponent implements AfterViewInit {
    private readonly defaultSelectedTab = 'FUNCTION';
    private readonly NEW_ACTION = 'NEW';
    private readonly SEARCH_ACTION = 'SEARCH';
    @Input() items: OopsCategory[];
    @Input() securities: SecurityGroupSecurityModel[]
    @Output() onFunctionSelected = new EventEmitter<OopsCategory>();
    @ViewChild("contextMenu")
    contextMenu: SecurityFunctionsContextMenuComponent;
    selectedItem: OopsCategory;

    constructor(private navigationService: NavigationService,
        private changeDetector: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.selectDefaultSecurity();
    }

    public selectMenu(actionId: string) {
        switch (actionId) {
            case this.NEW_ACTION:
                this.gotoNewSelectedItem()
                break;
            case this.SEARCH_ACTION:
                this.gotoSelectedItem(this.SEARCH_ACTION);
                break;
        }
    }

    private selectDefaultSecurity() {
        if (this.items) {
            this.onItemClick(this.items[0]);
            this.changeDetector.detectChanges();
        }
    }

    createNavigateParameter(actionId: string) {
        let param = {
            selectedTab: actionId,
            moduleSecurity: this.selectedItem?.security,
            securities: this.securities
        }
        return param;
    }

    public onItemClick(item: OopsCategory) {
        this.selectedItem = item;
        this.onFunctionSelected.emit(this.selectedItem);
    }

    public onItemDoubleClick(item: OopsCategory) {
        this.selectedItem = item;
        this.gotoSelectedItem(this.defaultSelectedTab);
    }

    gotoSelectedItem(actionId: string) {
        if (this.selectedItem) {
            let param = this.createNavigateParameter(actionId);
            this.navigationService.navigate('main/settings/security-' + this.selectedItem.url.toLowerCase(), null, false, param);
        }
    }

    gotoNewSelectedItem() {
        if (this.selectedItem) {
            let param = {
                newFlag: true,
                moduleSecurity: this.selectedItem?.security,
                securities: this.securities
            }
            this.navigationService.navigate('main/settings/security-' + this.selectedItem.url.toLowerCase() + '/details', null, false, param);
        }
    }
}
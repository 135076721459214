export abstract class ButtonBase {
    abstract label: string;
    abstract action: string;
    abstract matIconName: string;
    abstract colorClass: string;
    disabled: boolean = false;
    showBtn: boolean = false;
    dropdownBtns = new Array<ButtonBase>();

    get id(): string {
        return this.action;
    }

    enable(isEnable: boolean = true): void {
        this.disabled = !isEnable;
    }

    disable(isDisable: boolean = true): void {
        this.disabled = isDisable;
    }

    addDropdownBtn(btn: ButtonBase): void {
        this.dropdownBtns.push(btn);
    }

    getDropdownBtn(id: string): ButtonBase {
        return this.dropdownBtns.find(btn => btn.id == id);
    }

    hide(isShow: boolean = false): void {
        this.showBtn = isShow;
    }

    show(isShow: boolean = true): void {
        this.showBtn = isShow;
    }
}
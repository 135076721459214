import { Action, createReducer, on } from '@ngrx/store';
import { UserLoginModel } from './../../core/models/user-model/user-login.model';
import * as AuthActions from './auth.actions';
import packageInfo from '../../../../package.json'
import { environment } from './../../../environments/environment';
export interface AuthState {
  user: UserLoginModel;
  authError: string;
  loading: boolean;
  twoStepVerified: boolean;
  orgSelected: boolean;
  firstName: string;
  lastName: string;
  email: string;
  orgCallName: string;
  securityGroupName: string;
  lastLogon: Date;
  buildVersion: string;
  envName: string;
  userProfileImg: string;
  userAccountId: string;
  organisationId: string;
  orgCount: number;
  userName: string;
  changePasswordOrgId: string;
  changePasswordRequestId: string;
  changePasswordLinkInvalid: boolean;
  changePasswordLinkInvalidMessage: string;
  changePasswordExpired: boolean,
}

const initialState: AuthState = {
  user: null,
  authError: null,
  loading: false,
  twoStepVerified: false,
  orgSelected: false,
  firstName: null,
  lastName: null,
  email: null,
  orgCallName: null,
  securityGroupName: null,
  lastLogon: null,
  buildVersion: `Build ${packageInfo.version}`,
  envName: environment.production ? 'Production System' : 'Dev System',
  userProfileImg: null,
  userAccountId: null,
  organisationId: null,
  orgCount: 0,
  userName: null,
  changePasswordOrgId: null,
  changePasswordRequestId: null,
  changePasswordLinkInvalid: false,
  changePasswordLinkInvalidMessage: null,
  changePasswordExpired: false
};

const _authReducer = createReducer(
  initialState,

  on(AuthActions.loginStart, (state) => ({
    ...state,
    authError: null,
    loading: true,
  })),

  on(AuthActions.authenticateSuccess, (state, action) => ({
    ...state,
    authError: null,
    loading: false,
    user: new UserLoginModel(
      action.token,
      action.expires_in,
      action.username,
      action.orgId,
      action.isVerifyCode
    ),
  })),

  on(AuthActions.authenticateFail, (state, action) => ({
    ...state,
    user: null,
    authError: action.errorMessage,
    loading: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    user: null,
  })),

  on(AuthActions.twoStepVerified, (state, action) => ({
    ...state,
    twoStepVerified: action.twoStepVerified,
  })),

  on(AuthActions.orgSelected, (state, action) => ({
    ...state,
    orgSelected: action.orgSelected,
  })),

  on(AuthActions.userLogonName, (state, action) => ({
    ...state,
    userAccountId: action.userAccountId,
    firstName: action.firstName,
    lastName: action.lastName
  })),

  on(AuthActions.afterUserSelectOrg, (state, action) => ({
    ...state,
    email: action.email,
    orgCallName: action.orgCallName,
    securityGroupName: action.securityGroupName,
    lastLogon: action.lastLogon,
    buildVersion: action.buildVersion,
    envName: action.envName,
    organisationId: action.organisationId
  })),

  on(AuthActions.userProfileImageUrl, (state, action) => ({
    ...state,
    userProfileImg: action.userProfileImageUrl
  })),

  on(AuthActions.orgCount, (state, action) => ({
    ...state,
    orgCount: action.orgCount
  })),

  on(AuthActions.forgotPassword, (state, action) => ({
    ...state,
    userName: action.username
  })),

  on(AuthActions.changePassword, (state, action) => ({
    ...state,
    changePasswordOrgId: action.changePasswordOrgId,
    changePasswordRequestId: action.changePasswordRequestId,
    changePasswordLinkInvalid: action.changePasswordLinkInvalid,
    changePasswordLinkInvalidMessage: action.changePasswordLinkInvalidMessage
  })),  

  on(AuthActions.changePasswordExpired, (state, action) => ({
    ...state,
    userAccountId: action.userAccountId,
    changePasswordExpired: action.changePasswordExpired
  })), 
);

export function authReducer(state: AuthState, action: Action) {
  return _authReducer(state, action);
}

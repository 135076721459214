import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Input,
    ChangeDetectorRef,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { DateConverterService } from 'src/app/core/utils/date-converter.service';

//Rules
import { select2RestrictionType } from './shared/rules-config-configuration';
import { RuleConfigValidationService } from './shared/rules-config-validation.service';
import { RuleConfigSetRequireFieldService } from './shared/rules-config-set-require-field.service';
import {
    AreaModel,
    CalendarTypeModel,
    DateTypeModel,
    DayModel,
    ODModel,
    LocationPointModel,
    OperatorModel,
    OrgTypeModel,
    TimeZoneModel
} from 'src/app/core/models/merchandizing-config';

//Product code
import {
    select2ProductCodeType,
    select2OperatorRestrictionProductCode, select2Provider, select2Supplier
} from './shared/productcode/productcode-configuration';
import { ProductCodeValidationService } from './shared/productcode/productcode-validation.service';
import { ProductCodeSetRequireFieldService } from './shared/productcode/productcode-set-require-field.service';
import { ProductCodeSetDefaultService } from './shared/productcode/productcode-set-default.service';
import {
    ProductCategoryReferenceModel,
    ProductGroupReferenceModel,
    ProductLocationPointReferenceModel,
    ProductLocationTypeReferenceModel,
    ProductNumberTypeReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel,
    VehicleCompositionModel,
    VehicleGroupReferenceModel,
    VehicleTypeReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';

import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';

//Product type 
import { select2OperatorRestrictionProductType, select2ProductCat, select2ProductGroup, select2ProductGroupType }
    from './shared/producttype/producttype-configuration';
import { ProductTypeValidationService } from './shared/producttype/producttype-validation.service';
import { ProductTypeSetRequireFieldService } from './shared/producttype/producttype-set-require-field.service';
import { ProductTypeSetDefaultService } from './shared/producttype/producttype-set-default.service';
import { ProductTypeDataService } from './shared/producttype/producttype-data.service';


//Route 
import { select2OperatorRestrictionRoute, select2OriginRoute, select2DestinationRoute }
    from './shared/route/route-configuration';
import { RouteValidationService } from './shared/route/route-validation.service';
import { RouteSetRequireFieldService } from './shared/route/route-set-require-field.service';
import { RouteSetDefaultService } from './shared/route/route-set-default.service';
import { RouteDataService } from './shared/route/route-data.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

//Location
import {
    select2OperatorRestrictionLocation, select2AreaType, select2AreaType1, select2SaleOrService, select2OperatorLocation, select2LocationType,
    select2LocationRegionOption, select2LocationCountryOption, select2LocationAirportOption, select2LocationGroupOption
}
    from './shared/location/location-config-configuration';
import { LocationValidationService } from './shared/location/location-validation.service';
import { LocationPointValidationService } from './shared/location/location-point-validation.service';
import { LocationSetRequireFieldService } from './shared/location/location-set-require-field.service';
import { LocationSetDefaultService } from './shared/location/location-set-default.service';
import { LocationDataService } from './shared/location/location-data.service';

//Organisation 
import {
    select2AreaType2,
    select2Operator2,
    select2OrgTypeOption,
    select2OrgGroupOption,
    select2OrgRoleOption,
    select2OrgSpecificOption
} from './shared/organisation/organisation-configuration';

import { OrganisationValidationService } from './shared/organisation/organisation-validation.service';
import { OrganisationSetRequireFieldService } from './shared/organisation/organisation-set-require-field.service';
import { OrganisationSetDefaultService } from './shared/organisation/organisation-set-default.service';
import { OrganisationDataService } from './shared/organisation/organisation-data.service';

//Calendar
import {
    select2DateType, select2CalendarType, select2TimeZone, select2CalendarOperator,
    validityDateOption, validityDateTimeOption, validityTimeOption, select2DayStart, select2DayEnd,
    validityWeekDaysOption
} from './shared/calendar/calendar-config-configuration';
import { CalendarValidationService } from './shared/calendar/calendar-validation.service';
import { CalendarSetRequireFieldService } from './shared/calendar/calendar-set-require-field.service';
import { CalendarSetDefaultService } from './shared/calendar/calendar-set-default.service';
import { CalendarDataService } from './shared/calendar/calendar-data.service';
import { ValidityInheritComponent } from './inherit-rules/validity/validity-inherit.component';

//Restriction view
import { RestrictionView } from './shared/views/restriction.view';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { RestrictionInheritComponent } from './inherit-rules/restriction/restriction-inherit.component';
import { PointOfSalesInheritComponent } from './inherit-rules/point-of-sales/point-of-sales-inherit.component';
import { AbstractControl } from '@angular/forms';
import { VehicleSetDefaultService } from './shared/vehicle/vehicle-set-default.service';
import { select2OperatorRestrictionVehicle, select2VehicleComposition, select2VehicleGroup, select2VehicleType } from './shared/vehicle/vehicle-configuration';
import { VehicleSetRequireFieldService } from './shared/vehicle/vehicle-set-require-field.service';
import { VehicleDataService } from './shared/vehicle/vehicle-data.service';
import { VehicleValidationService } from './shared/vehicle/vehicle-validation.service';

declare var $: any;

@Component({
    selector: 'op-rules-config',
    templateUrl: './rules-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [RuleConfigValidationService, RuleConfigSetRequireFieldService,
        ProductCodeValidationService, ProductCodeSetRequireFieldService, ProductCodeSetDefaultService,
        ProductTypeValidationService, ProductTypeSetRequireFieldService, ProductTypeSetDefaultService, ProductTypeDataService,
        RouteValidationService, RouteSetRequireFieldService, RouteSetDefaultService, RouteDataService,
        LocationValidationService, LocationPointValidationService, LocationSetRequireFieldService, LocationSetDefaultService, LocationDataService,
        OrganisationValidationService, OrganisationSetRequireFieldService, OrganisationSetDefaultService, OrganisationDataService,
        CalendarValidationService, CalendarSetRequireFieldService, CalendarSetDefaultService, CalendarDataService, VehicleSetDefaultService,
        VehicleSetRequireFieldService, VehicleDataService, VehicleValidationService]
})
export class RulesConfigComponent implements OnInit {

    public readonly timeFormat: string = "HH:mm";
    private readonly dateTimeFormat: string = "YYYY-MM-DD HH:mm";
    private readonly dateFormat: string = "YYYY-MM-DD";


    //Config
    @Input() configType: string = "";
    @Input() config: any;
    @Input() allowAdd: boolean = true;

    //Type option
    public typeOption: any = select2RestrictionType;

    //Product Type
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;

    public operatorProductTypeOption: any = select2OperatorRestrictionProductType;
    public operatorProductTypes$ = new BehaviorSubject<OperatorModel[]>(null);
    public prouctCatOption: any = select2ProductCat;
    public productGroupOption: any = select2ProductGroup;
    public productGroupTypeOption: any = select2ProductGroupType;

    //Product Code
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    public codeTypeOption: any = select2ProductCodeType;
    public operatorProductCodeOption: any = select2OperatorRestrictionProductCode;
    public operatorProductCodes$ = new BehaviorSubject<OperatorModel[]>(null);
    public providerOption: any = select2Provider;
    public supplierOption: any = select2Supplier;

    //Route
    public operatorRouteOption: any = select2OperatorRestrictionRoute;
    public operatorRoutes$ = new BehaviorSubject<OperatorModel[]>(null);
    public originRouteOption: any = select2OriginRoute;
    public destinationRouteOption: any = select2DestinationRoute;

    private originRoutes$ = new BehaviorSubject<Select2Data[]>(null);
    private destinationRoutes$ = new BehaviorSubject<Select2Data[]>(null);


    //Location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    public locationPointOption: any = select2SaleOrService;
    public locationPoints$ = new BehaviorSubject<LocationPointModel[]>(null);
    public areaTypeOption: any = select2AreaType;
    public operatorLocationOption: any = select2OperatorRestrictionLocation;
    public locationTypeOption: any = select2LocationType;
    public locationRegionOption: any = select2LocationRegionOption;
    public locationCountryOption: any = select2LocationCountryOption;
    public locationAirportOption: any = select2LocationAirportOption;
    public locationGroupOption: any = select2LocationGroupOption;
    public locationAreasOption: any = select2AreaType1;
    public locationAreasOperatorOption: any = select2Operator2;

    //Organisation
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];

    public orgTypeOption: any = select2AreaType2;
    public orgTypes$ = new BehaviorSubject<OrgTypeModel[]>(null);
    public orgTypeOperatorOption: any = select2Operator2;
    public orgTypeValueOption: any = select2OrgTypeOption;
    public orgGroupValueOption: any = select2OrgGroupOption;
    public orgRoleValueOption: any = select2OrgRoleOption;
    public orgSpecificValueOption: any = select2OrgSpecificOption;

    //Validity
    @Input() calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);

    @Input() disabled: boolean = false;

    @Output() formStatus = new EventEmitter<boolean>();
    
    public dateTypeOption: any = select2DateType;
    public dateTypes$ = new BehaviorSubject<DateTypeModel[]>(null);
    public calendarTypeOption: any = select2CalendarType;
    public timeZoneOption: any = select2TimeZone;
    public calendarOperatorOption: any = select2CalendarOperator;
    public daterangepickerTimeOption: any;
    public startDayOption: any = select2DayStart;
    public days$ = new BehaviorSubject<DayModel[]>(null);
    public endDayOption: any = select2DayEnd;
    public weekDaysOption: any = validityWeekDaysOption;
    public weekDayData: any = this.calendarDataService.getWeekDayData();
    public actionForm: UntypedFormGroup;
    private formStatusValid: boolean = true;

    private calendarTypes$ = new BehaviorSubject<CalendarTypeModel[]>(null);

    //Vehicle
    @Input() vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    @Input() vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleCompositions$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public operatorVehicle$ = new BehaviorSubject<OperatorModel[]>(null);
    public operatorVehicleOption: any = select2OperatorRestrictionVehicle;
    public vehicleGroupOption = select2VehicleGroup;
    public vehicleTypeOption = select2VehicleType;
    public vehicleCompositionOption = select2VehicleComposition;

    @ViewChild(ValidityInheritComponent) validityInheritComponent: ValidityInheritComponent;
    @ViewChild(RestrictionInheritComponent) restrictionInheritComponent: RestrictionInheritComponent;
    @ViewChild(PointOfSalesInheritComponent) pointofsalesInheritComponent: PointOfSalesInheritComponent;

    constructor(private fb: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService,
        private ruleConfigValidationService: RuleConfigValidationService,
        private ruleConfigSetRequireFieldService: RuleConfigSetRequireFieldService,
        private productCodeSetDefaultService: ProductCodeSetDefaultService,
        private productTypeSetDefaultService: ProductTypeSetDefaultService,
        private productTypeDataService: ProductTypeDataService,
        private routeSetDefaultService: RouteSetDefaultService,
        private routeDataService: RouteDataService,
        private locationSetRequireFieldService: LocationSetRequireFieldService,
        private locationSetDefaultService: LocationSetDefaultService,
        private locationDataService: LocationDataService,
        private organisationSetRequireFieldService: OrganisationSetRequireFieldService,
        private organisationSetDefaultService: OrganisationSetDefaultService,
        private organisationDataService: OrganisationDataService,
        private calendarSetRequireFieldService: CalendarSetRequireFieldService,
        private calendarSetDefaultService: CalendarSetDefaultService,
        private calendarDataService: CalendarDataService,
        private vehicleSetDefaultService: VehicleSetDefaultService,
        private vehicleDataService: VehicleDataService
    ) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.getOperatorProductCodeData();
        this.getOperatorProductTypeData();
        this.getOperatorRouteData();
        this.getOperatorVehicleData();
        this.getLocationData(); 
        this.getOrganisationData();
        this.getCalendarData();
        this.getDayData();     

        this.originRouteOption.ajax = this.routeDataService.select2AjaxOriginSearchByLocationCode();
        this.destinationRouteOption.ajax = this.routeDataService.select2AjaxDestinationSearchByLocationCode();
        this.locationAirportOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
    }

    private onFormStatusChange() {
       if (this.formStatusValid != this.actionForm.valid){
         this.formStatusValid = this.actionForm.valid;
         this.formStatus.emit(this.actionForm.valid);
       }
    }
    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    public add() {
        //Validation
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }
            
            if (this.findDateOverlapAll()) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == "INVALID") {
                    return;
                }
            }
        }
        //Create new form
        this.forms.push(this.createFormGroupEmpty());
        this.changeDetectorRef.detectChanges();
    }

    public findDuplicateAllRow(): boolean {
        var duplicate: boolean = false;
        duplicate = this.ruleConfigValidationService.findDuplicateAllRow(this.forms);
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }

    public validateInvalidRow(): boolean {
        if (this.forms.controls.length != 0) {
            this.changeDetectorRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (!ctl.valid) {
                    return false;
                }
            }
        }
        return true;
    }

    public findDateOverlapAll() : boolean {
        var datetOverlap: boolean = false;
        if (this.configType != "Validity") {
            return false;
        }
        datetOverlap = this.ruleConfigValidationService.findOverlapAllRow(this.forms);
        return datetOverlap;
    }

    public findDateNotSpecify() : boolean {
        return this.ruleConfigValidationService.findDateNotSpecify(this.forms);
    }

    public typeInvalid(ctl: AbstractControl) : boolean {
        return (ctl.get('type').invalid || 
                ctl.get('productCodeDuplicate').invalid || 
                ctl.get('productTypeDuplicate').invalid || 
                ctl.get('routeDuplicate').invalid || 
                ctl.get('locationDuplicate').invalid || 
                ctl.get('organisationDuplicate').invalid || 
                ctl.get('calendarDuplicate').invalid ||
                ctl.get('vehicleDuplicate').invalid ||
                ctl.get('overlap')?.invalid) && ctl.get('submitted').value == true; 
    }

    public validityRowInvalid(name: string, ctl: AbstractControl) : boolean {
        return (ctl.get(name).invalid || 
                ctl.get('calendarDuplicate').invalid ||
                ctl.get('overlap').invalid) && ctl.get('submitted').value; 
    }

    public validateForm(): boolean {
        return !this.findDuplicateAllRow() && this.validateInvalidRow();
    }

    public addRestrictionViewToFormGroup(productRestrictions: RestrictionView[]) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        let no = 0;
        var locationRestriction = productRestrictions.filter(x => x.type == "location");

        for (let productRestriction of productRestrictions) {
            if (productRestriction.type == "location") {
                if (productRestriction.no != no) {
                    var locationSameLine = locationRestriction.filter(x => x.no == productRestriction.no);
                    var locationArrayValue: string[] = new Array<string>();
                    var locationArrayDBId: Select2Data[] = new Array<Select2Data>();
                    var locationAirportSelect2Datas: Select2Data[] = new Array<Select2Data>();

                    for (let location of locationSameLine) {
                        if (location.productRestrictionLocationView.regionCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.regionCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.regionCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.countryCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.countryCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.countryCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationId);

                            let airportData = new Select2Data();
                            airportData.id = location.productRestrictionLocationView.locationId;
                            airportData.text = location.productRestrictionLocationView.locationName;
                            locationAirportSelect2Datas.push(airportData);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationId;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationGroupId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationGroupId);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationGroupId;
                            locationArrayDBId.push(dbIdData);
                        }
                    }
                    (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupProductRestriction(productRestriction, locationArrayValue, locationArrayDBId));
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    this.locationDataService.fillLocationPointAreaData(formCurrent, this.locationPoints$);
                    this.locationDataService.filllocationPointOperatorData(formCurrent, this.locationPoints$);
                    this.locationDataService.fillLocationAirportData(formCurrent, productRestriction, locationAirportSelect2Datas);
                    this.locationSetRequireFieldService.clearRequireLocationPoint(formCurrent);
                    this.locationSetRequireFieldService.setRequireLocationPoint(formCurrent);
                    no = productRestriction.no;
                }
            }
            else {
                (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupProductRestriction(productRestriction));
                let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                this.fillProductTypeData(formCurrent, productRestriction);
                if (productRestriction.type == "producttype") {
                    formCurrent.get('productCat').setValue(productRestriction.productRestrictionProductView.productCategoryCode);
                    formCurrent.get('productGroup').setValue(productRestriction.productRestrictionProductView.productGroupCode);
                    this.productTypeDataService.getProductTypeGroupData(formCurrent, this.productTypeGroups$);
                    formCurrent.get('productGroupType').setValue(productRestriction.productRestrictionProductView.productTypeCode);
                }
                this.locationDataService.fillOriginRouteData(formCurrent, productRestriction);
                this.locationDataService.fillDestinationRouteData(formCurrent, productRestriction);
                if (productRestriction.type == 'vehicle') {
                    this.vehicleDataService.getVehicleTypeData(formCurrent, this.vehicleTypeReferences$);
                    formCurrent.get('vehicleTypeCode').setValue(productRestriction.productRestrictionVehicleView.vehicleTypeCode);
                    this.vehicleDataService.getVehicleCompositionData(formCurrent, this.vehicleCompositions$);
                    formCurrent.get('vehicleCompositionId').setValue(productRestriction.productRestrictionVehicleView.vehicleCompositionId);
                }
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    public addRestrictionInheritToFormGroup(productRestrictions: RestrictionView[]) {
        this.restrictionInheritComponent.addRestrictionInheritToFormGroup(productRestrictions);
    }

    public addValidityToFormGroup(productValidities: ProductValidityViewModel[], required: boolean = false) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        this.actionForm.statusChanges
        .subscribe(() => this.onFormStatusChange());

        productValidities.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        for (let productValidity of productValidities) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupProductValidity(productValidity, required));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);
            if(required){
              required = false;
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    public addValidityInheritToFormGroup(productValidities: ProductValidityViewModel[]) {
        this.validityInheritComponent.addValidityInheritToFormGroup(productValidities);
    }

    public addPointOfSalesViewToFormGroup(productPointOfSales: RestrictionView[]) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        let no = 0;
        var locationRestriction = productPointOfSales.filter(x => x.type == "location");
        var organisationRestriction = productPointOfSales.filter(x => x.type == "organisation");

        for (let productRestriction of productPointOfSales) {
            if (productRestriction.type == "location") {
                if (productRestriction.no != no) {
                    var locationSameLine = locationRestriction.filter(x => x.no == productRestriction.no);
                    var locationArrayValue: string[] = new Array<string>();
                    var locationArrayDBId: Select2Data[] = new Array<Select2Data>();
                    var locationAirportSelect2Datas: Select2Data[] = new Array<Select2Data>();

                    for (let location of locationSameLine) {
                        if (location.productRestrictionLocationView.regionCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.regionCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.regionCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.countryCode) {
                            locationArrayValue.push(location.productRestrictionLocationView.countryCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.countryCode;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationId);

                            let airportData = new Select2Data();
                            airportData.id = location.productRestrictionLocationView.locationId;
                            airportData.text = location.productRestrictionLocationView.locationName;
                            locationAirportSelect2Datas.push(airportData);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationId;
                            locationArrayDBId.push(dbIdData);
                        } else if (location.productRestrictionLocationView.locationGroupId) {
                            locationArrayValue.push(location.productRestrictionLocationView.locationGroupId);

                            let dbIdData = new Select2Data();
                            dbIdData.id = location.productRestrictionLocationView.productRestrictionLocationId;
                            dbIdData.text = location.productRestrictionLocationView.locationGroupId;
                            locationArrayDBId.push(dbIdData);
                        }
                    }
                    (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupProductPointOfSales(productRestriction, locationArrayValue, locationArrayDBId));
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    this.locationDataService.fillLocationArea(formCurrent, this.config);
                    this.locationDataService.fillLocationAreasOperatorData(formCurrent, this.config);
                    this.locationDataService.fillLocationAreasAirportData(formCurrent, productRestriction, locationAirportSelect2Datas);
                    this.locationSetRequireFieldService.clearRequireLocation(formCurrent);
                    this.locationSetRequireFieldService.setRequireLocation(formCurrent);
                    no = productRestriction.no;
                }
            }
            else if (productRestriction.type == "organisation") {
                if (productRestriction.no != no) {
                    var organisationSameLine = organisationRestriction.filter(x => x.no == productRestriction.no);
                    var organisationArrayValue: string[] = new Array<string>();
                    var organisationArrayDBId: Select2Data[] = new Array<Select2Data>();

                    for (let organisation of organisationSameLine) {
                        if (organisation.productRestrictionOrganisationView.organisationTypeCode) {
                            organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationTypeCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                            dbIdData.text = organisation.productRestrictionOrganisationView.organisationTypeCode;
                            organisationArrayDBId.push(dbIdData);
                        } else if (organisation.productRestrictionOrganisationView.organisationGroupCode) {
                            organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationGroupCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                            dbIdData.text = organisation.productRestrictionOrganisationView.organisationGroupCode;
                            organisationArrayDBId.push(dbIdData);
                        } else if (organisation.productRestrictionOrganisationView.organisationRoleCode) {
                            organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationRoleCode);

                            let dbIdData = new Select2Data();
                            dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                            dbIdData.text = organisation.productRestrictionOrganisationView.organisationRoleCode;
                            organisationArrayDBId.push(dbIdData);
                        } else if (organisation.productRestrictionOrganisationView.organisationId) {
                            organisationArrayValue.push(organisation.productRestrictionOrganisationView.organisationId);

                            let dbIdData = new Select2Data();
                            dbIdData.id = organisation.productRestrictionOrganisationView.productRestrictionOrganisationId;
                            dbIdData.text = organisation.productRestrictionOrganisationView.organisationId;
                            organisationArrayDBId.push(dbIdData);
                        }
                    }
                    (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupProductPointOfSales(productRestriction, organisationArrayValue, organisationArrayDBId));
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    this.organisationDataService.fillOrgTypeOperator(formCurrent, this.config);
                    this.organisationSetRequireFieldService.clearRequireOrganisation(formCurrent);
                    this.organisationSetRequireFieldService.setRequireOrganisation(formCurrent);
                    no = productRestriction.no;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    public addPointOfSalesInheritToFormGroup(productPointOfSales: RestrictionView[]) {
        this.pointofsalesInheritComponent.addPointOfSalesInheritToFormGroup(productPointOfSales);
    }

    public clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }

    private createFormGroupEmpty() {
        let group = {
            'type': ['', [Validators.required]],
            'submitted': [false, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [false, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]],
            'vehicleShow': [false, [Validators.nullValidator]],
            'vehicleDuplicate': [false, [Validators.nullValidator]],
        };

        this.config.types.forEach(types => {
            this.createFormGroupEmptyByType(group, types);
        });

        let f = this.fb.group(group);
        this.setDefaultValue(f);
        return f;
    }

    private createFormGroupEmptyByType(group: any, type: any) {
        switch (type.typeCode) {
            case 'productcode': {
                group['productRestrictionProductNumberId'] = [null, [Validators.nullValidator]];
                group['productCodeType'] = [null, [Validators.required]];
                group['operatorProductCode'] = [null, [Validators.required]];
                group['provider'] = [null, [Validators.nullValidator]];
                group['productCodeValue'] = [null, [Validators.required]];
                group['supplier'] = [null, [Validators.nullValidator]];
                break;
            }
            case 'producttype': {
                group['productRestrictionProductId'] = [null, [Validators.nullValidator]];
                group['operatorProductType'] = [null, [Validators.required]];
                group['productCat'] = [null, [Validators.required]];
                group['productGroup'] = [null, [Validators.required]];
                group['productGroupData'] = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
                group['productGroupType'] = [null, [Validators.required]];
                group['productGroupTypeData'] = new BehaviorSubject<ProductTypeGroupModel[]>(null);
                break;
            }
            case 'route': {
                group['productRestrictionRouteId'] = [null, [Validators.nullValidator]];
                group['operatorRoute'] = [null, [Validators.required]];
                group['originRoute'] = [null, [Validators.required]];
                group['originRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                group['destinationRoute'] = [null, [Validators.required]];
                group['destinationRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                break;
            }
            case 'location': {
                if (type.locationPoint) {
                    group['productRestrictionLocationId'] = [null, [Validators.nullValidator]];
                    group['locationPoint'] = [null, [Validators.required]];
                    group['locationPointAreas'] = [null, [Validators.required]];
                    group['locationPointAreasData'] = new BehaviorSubject<AreaModel[]>(null);
                    group['locationPointOperator'] = [null, [Validators.required]];
                    group['locationPointOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);
                    group['locationPointODs'] = [null, [Validators.required]];
                    group['locationPointODsData'] = new BehaviorSubject<ODModel[]>(null);
                    group['locationPointValueRegion'] = [null, [Validators.required]];
                    group['locationPointValueCountry'] = [null, [Validators.required]];
                    group['locationPointValueAirport'] = [null, [Validators.required]];
                    group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationPointValueGroup'] = [null, [Validators.required]];
                }
                else {
                    group['locationAreas'] = [null, [Validators.required]];
                    group['locationAreasData'] = new BehaviorSubject<AreaModel[]>(null);
                    group['locationAreasOperators'] = [null, [Validators.required]];
                    group['locationAreasOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                    group['locationAreasValueRegion'] = [null, [Validators.required]];
                    group['locationAreasValueCountry'] = [null, [Validators.required]];
                    group['locationAreasValueAirport'] = [null, [Validators.required]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [null, [Validators.required]];
                }
                break;
            }
            case 'organisation': {
                group['orgType'] = [null, [Validators.required]];
                group['orgTypeOperators'] = [null, [Validators.required]];
                group['orgTypeOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                group['orgTypeValueType'] = [null, [Validators.required]];
                group['orgTypeValueGroup'] = [null, [Validators.required]];
                group['orgTypeValueRole'] = [null, [Validators.required]];
                group['orgTypeValueSpecific'] = [null, [Validators.required]];
                break;
            }
            case 'calendar': {
                group['dateType'] = [null, [Validators.required]];
                group['calendarType'] = [null, [Validators.required]];
                group['calendarTypeData'] = new BehaviorSubject<OperatorModel[]>(null);
                group['timeZone'] = [null, [Validators.required]];
                group['timeZoneData'] = new BehaviorSubject<TimeZoneModel[]>(null);
                group['timeZoneDisplay'] = [false, [Validators.nullValidator]];
                group['calendarOperator'] = [null, [Validators.required]];
                group['calendarOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);

                group['startDate'] = [null, [Validators.required]];
                group['endDate'] = [null, [Validators.required]];
                group['daterangepickerOption'] = [null, [Validators.nullValidator]];
                group['dateendpickerOption'] = [null, [Validators.nullValidator]];
                group['startDatePlaceHolder'] = ['<Enter Date>', [Validators.nullValidator]];
                group['dateTimeFormat'] = [this.dateFormat, [Validators.nullValidator]];

                group['startTime'] = [null, [Validators.required]];
                group['endTime'] = [null, [Validators.required]];

                group['startDay'] = [null, [Validators.required]];
                group['endDay'] = [null, [Validators.required]];
                group['startDayPlaceHolder'] = ['<Enter Day>', [Validators.nullValidator]];

                group['weekDays'] = [[], [Validators.required]];
                group['weekDaysDisplay'] = [false, [Validators.nullValidator]];
                group['overlap'] = [false, [Validators.nullValidator]];
                break;
            }
            case 'vehicle': {
                group['productRestrictionVehicleId'] = [null];
                group['vehicleGroupCode'] = [null, [Validators.required]];
                group['operatorVehicle'] = [null, [Validators.required]];
                group['vehicleTypeCode'] = [null, [Validators.required]];
                group['vehicleCompositionId'] = [null];
                group['vehicleTypeData'] = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
                group['vehicleCompositionData'] = new BehaviorSubject<VehicleCompositionModel[]>(null);
                break;
            }
        }
    }

    private createFormGroupProductRestriction(productRestriction: RestrictionView,
        locationValueArray: string[] = null,
        locationArrayDBId: Select2Data[] = null) {
        let group = {
            'type': [productRestriction.type, [Validators.required]],
            'submitted': [true, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [false, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]],
            'vehicleShow': [false, [Validators.nullValidator]],
            'vehicleDuplicate': [false, [Validators.nullValidator]]
        };

        this.config.types.forEach(type => {
            const typeCode = type.typeCode;
            if (productRestriction.type == typeCode) {
                switch (productRestriction.type) {
                    case 'productcode': {
                        let restrictionProductNumber = productRestriction.productRestrictionProductNumberView;
                        group['productCodeShow'] = [true, [Validators.nullValidator]];
                        group['productRestrictionProductNumberId'] = [restrictionProductNumber.productRestrictionProductNumberId, [Validators.nullValidator]];
                        group['productCodeType'] = [restrictionProductNumber.productNumberTypeCode, [Validators.required]];
                        group['operatorProductCode'] = [(restrictionProductNumber.excludeFlag) ? "!=" : "=", [Validators.required]];
                        group['provider'] = [restrictionProductNumber.providerId, [Validators.nullValidator]];
                        group['productCodeValue'] = [restrictionProductNumber.productNumber, [Validators.required]];
                        group['supplier'] = [restrictionProductNumber.supplierId, [Validators.nullValidator]];
                        break;
                    }
                    case 'producttype': {
                        let restrictionProduct = productRestriction.productRestrictionProductView;
                        group['productTypeShow'] = [true, [Validators.nullValidator]];
                        group['productRestrictionProductId'] = [restrictionProduct.productRestrictionProductId, [Validators.nullValidator]];
                        group['operatorProductType'] = [(restrictionProduct.excludeFlag) ? "!=" : "=", [Validators.required]];
                        group['productCat'] = [restrictionProduct.productCategoryCode, [Validators.required]];
                        group['productGroup'] = [restrictionProduct.productGroupCode, [Validators.required]];
                        group['productGroupData'] = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
                        group['productGroupType'] = [restrictionProduct.productTypeCode, [Validators.required]];
                        group['productGroupTypeData'] = new BehaviorSubject<ProductTypeGroupModel[]>(null);
                        break;
                    }
                    case 'route': {
                        let restrictionRoute = productRestriction.productRestrictionRouteView;
                        group['routeShow'] = [true, [Validators.nullValidator]];
                        group['productRestrictionRouteId'] = [restrictionRoute.productRestrictionRouteId, [Validators.nullValidator]];
                        group['operatorRoute'] = [(restrictionRoute.excludeFlag) ? "!=" : "=", [Validators.required]];
                        group['originRoute'] = [restrictionRoute.originLocationId, [Validators.required]];
                        group['originRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                        group['destinationRoute'] = [restrictionRoute.destinationLocationId, [Validators.required]];
                        group['destinationRouteData'] = new BehaviorSubject<Select2Data[]>(null);
                        break;
                    }
                    case 'location': {
                        let restrictionLocation = productRestriction.productRestrictionLocationView;
                        group['locationShow'] = [true, [Validators.nullValidator]];
                        group['productRestrictionLocationId'] = [locationArrayDBId, [Validators.nullValidator]];
                        group['locationPoint'] = [restrictionLocation.productLocationTypeCode, [Validators.required]];
                        let locationPointAreas = '';
                        if (restrictionLocation.regionCode) {
                            locationPointAreas = 'region';
                            group['locationPointValueRegion'] = [locationValueArray, [Validators.required]];
                            group['locationPointValueCountry'] = [null, [Validators.required]];
                            group['locationPointValueAirport'] = [null, [Validators.required]];
                            group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                            group['locationPointValueGroup'] = [null, [Validators.required]];
                        } else if (restrictionLocation.countryCode) {
                            locationPointAreas = 'country';
                            group['locationPointValueRegion'] = [null, [Validators.required]];
                            group['locationPointValueCountry'] = [locationValueArray, [Validators.required]];
                            group['locationPointValueAirport'] = [null, [Validators.required]];
                            group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                            group['locationPointValueGroup'] = [null, [Validators.required]];
                        } else if (restrictionLocation.locationId) {
                            locationPointAreas = 'airport';
                            group['locationPointValueRegion'] = [null, [Validators.required]];
                            group['locationPointValueCountry'] = [null, [Validators.required]];
                            group['locationPointValueAirport'] = [locationValueArray, [Validators.required]];
                            group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                            group['locationPointValueGroup'] = [null, [Validators.required]];
                        } else if (restrictionLocation.locationGroupId) {
                            locationPointAreas = 'group';
                            group['locationPointValueRegion'] = [null, [Validators.required]];
                            group['locationPointValueCountry'] = [null, [Validators.required]];
                            group['locationPointValueAirport'] = [null, [Validators.required]];
                            group['locationPointValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                            group['locationPointValueGroup'] = [locationValueArray, [Validators.required]];
                        }
                        group['locationPointAreas'] = [locationPointAreas, [Validators.required]];
                        group['locationPointAreasData'] = new BehaviorSubject<AreaModel[]>(null);

                        group['locationPointOperator'] = [(restrictionLocation.excludeFlag) ? "!=" : "=", [Validators.required]];
                        group['locationPointOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);

                        group['locationPointODs'] = [restrictionLocation.productLocationPointCode, [Validators.required]];
                        group['locationPointODsData'] = new BehaviorSubject<ODModel[]>(null);
                        break;
                    }
                    case 'vehicle': {
                        group['vehicleShow'] = [true, [Validators.nullValidator]];
                        group['productRestrictionVehicleId'] = [productRestriction.productRestrictionVehicleView.productRestrictionVehicleId];
                        group['vehicleGroupCode'] = [productRestriction.productRestrictionVehicleView.vehicleGroupCode, [Validators.required]];
                        group['operatorVehicle'] = [(productRestriction.productRestrictionVehicleView.excludeFlag) ? "!=" : "=", [Validators.required]];
                        group['vehicleTypeCode'] = [productRestriction.productRestrictionVehicleView.vehicleTypeCode, [Validators.required]];
                        group['vehicleCompositionId'] = [productRestriction.productRestrictionVehicleView.vehicleCompositionId];
                        group['vehicleTypeData'] = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
                        group['vehicleCompositionData'] = new BehaviorSubject<VehicleCompositionModel[]>(null);
                        break;
                    }
                }
            } else {
                this.createFormGroupEmptyByType(group, type);
            }
        });
        
        let f = this.fb.group(group);
        this.config.types.forEach(types => {
            if (types.typeCode != productRestriction.type) {
                this.setDefaultValueByType(f, types);
            }
        });
        this.ruleConfigSetRequireFieldService.setRequiredField(productRestriction.type, f, this.config, this.locationPoints$);
        return f;
    }

    private createFormGroupProductValidity(productValidity: ProductValidityViewModel, required: boolean = false) {
        let group = {
            'type': ['calendar', [Validators.required]],
            'submitted': [true, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [true, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]],
            'vehicleShow': [false, [Validators.nullValidator]],
            'vehicleDuplicate': [false, [Validators.nullValidator]],
            'required': [required, [Validators.nullValidator]],
            'overlap': [false, [Validators.nullValidator]]
        };

        group['productValidityId'] = [null, [Validators.nullValidator]];
        group['dateType'] = [null, [Validators.required]];
        group['calendarType'] = [null, [Validators.required]];
        group['calendarTypeData'] = new BehaviorSubject<OperatorModel[]>(null);
        group['timeZone'] = [null, [Validators.required]];
        group['timeZoneData'] = new BehaviorSubject<TimeZoneModel[]>(null);
        group['calendarOperator'] = [null, [Validators.required]];
        group['calendarOperatorData'] = new BehaviorSubject<OperatorModel[]>(null);
        group['timeZoneDisplay'] = [true, [Validators.nullValidator]];
        group['startDate'] = [null, [Validators.required]];
        group['endDate'] = [null, [Validators.required]];
        group['daterangepickerOption'] = [null, [Validators.nullValidator]];
        group['dateendpickerOption'] = [null, [Validators.nullValidator]];
        group['startDatePlaceHolder'] = ['<Enter Date>', [Validators.nullValidator]];
        group['dateTimeFormat'] = [this.dateFormat, [Validators.nullValidator]];
        group['startTime'] = [null, [Validators.required]];
        group['endTime'] = [null, [Validators.required]];
        group['startDay'] = [null, [Validators.required]];
        group['endDay'] = [null, [Validators.required]];
        group['startDayPlaceHolder'] = ['<Enter Day>', [Validators.nullValidator]];
        group['weekDays'] = [null, [Validators.required]];
        group['weekDaysDisplay'] = [false, [Validators.nullValidator]];

        let f = this.fb.group(group);

        f.controls['calendarType'].setValue(productValidity.dateTimeDimensionCode);
        this.setDefaultDateType(f);
        f.controls['calendarOperator'].setValue(productValidity.conditionCode);
        f.controls['productValidityId'].setValue(productValidity.productValidityId);
        f.controls['dateType'].setValue(productValidity.calendarValidityCode);
        f.controls['timeZone'].setValue((productValidity.utcFlag) ? "utc" : "local");
        switch (productValidity.dateTimeDimensionCode.toLowerCase()) {
            case 'date': {
                this.setStartDateValue(productValidity.dateTimeDimensionCode.toLowerCase(), f, productValidity.startDateTime);
                this.setEndDateAndWeekDayValue(productValidity.dateTimeDimensionCode.toLowerCase(), f, productValidity);
                break;
            }
            case 'datetime': {
                this.setStartDateValue(productValidity.dateTimeDimensionCode.toLowerCase(), f, productValidity.startDateTime);
                this.setEndDateAndWeekDayValue(productValidity.dateTimeDimensionCode.toLowerCase(), f, productValidity);
                break;
            }
            case 'time': {
                this.setStartTimeValue(f, productValidity.startDateTime);
                this.setEndTimeAndWeekDayValue(f, productValidity);
                break;
            }
            case 'weekday': {
                f.controls['timeZoneDisplay'].setValue(false);
                f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
                f.controls['weekDaysDisplay'].setValue(true);
                break;
            }
        }
        this.calendarSetRequireFieldService.clearRequireCalendar(f);
        this.calendarSetRequireFieldService.setRequireCalendar(f);
        return f;
    }

    private createFormGroupProductPointOfSales(productpointOfSales: RestrictionView,
        locationValueArray: string[] = null,
        locationArrayDBId: Select2Data[] = null) {
        let group = {
            'type': [productpointOfSales.type, [Validators.required]],
            'submitted': [true, [Validators.nullValidator]],
            'productCodeShow': [false, [Validators.nullValidator]],
            'productCodeDuplicate': [false, [Validators.nullValidator]],
            'productTypeShow': [false, [Validators.nullValidator]],
            'productTypeDuplicate': [false, [Validators.nullValidator]],
            'routeShow': [false, [Validators.nullValidator]],
            'routeDuplicate': [false, [Validators.nullValidator]],
            'locationShow': [false, [Validators.nullValidator]],
            'locationDuplicate': [false, [Validators.nullValidator]],
            'organisationShow': [false, [Validators.nullValidator]],
            'organisationDuplicate': [false, [Validators.nullValidator]],
            'calendarShow': [false, [Validators.nullValidator]],
            'calendarDuplicate': [false, [Validators.nullValidator]],
            'vehicleShow': [false, [Validators.nullValidator]],
            'vehicleDuplicate': [false, [Validators.nullValidator]]
        };

        switch (productpointOfSales.type) {
            case 'location': {
                let restrictionLocation = productpointOfSales.productRestrictionLocationView;
                group['locationShow'] = [true, [Validators.nullValidator]];
                group['productRestrictionLocationId'] = [locationArrayDBId, [Validators.nullValidator]];
                let locationAreas = '';
                if (restrictionLocation.regionCode) {
                    locationAreas = 'region';
                    group['locationAreasValueRegion'] = [locationValueArray, [Validators.required]];
                    group['locationAreasValueCountry'] = [null, [Validators.required]];
                    group['locationAreasValueAirport'] = [null, [Validators.required]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [null, [Validators.required]];
                } else if (restrictionLocation.countryCode) {
                    locationAreas = 'country';
                    group['locationAreasValueRegion'] = [null, [Validators.required]];
                    group['locationAreasValueCountry'] = [locationValueArray, [Validators.required]];
                    group['locationAreasValueAirport'] = [null, [Validators.required]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [null, [Validators.required]];
                } else if (restrictionLocation.locationId) {
                    locationAreas = 'airport';
                    group['locationAreasValueRegion'] = [null, [Validators.required]];
                    group['locationAreasValueCountry'] = [null, [Validators.required]];
                    group['locationAreasValueAirport'] = [locationValueArray, [Validators.required]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [null, [Validators.required]];
                } else if (restrictionLocation.locationGroupId) {
                    locationAreas = 'locationgroup';
                    group['locationAreasValueRegion'] = [null, [Validators.required]];
                    group['locationAreasValueCountry'] = [null, [Validators.required]];
                    group['locationAreasValueAirport'] = [null, [Validators.required]];
                    group['locationAreasValueAirportData'] = new BehaviorSubject<Select2Data[]>(null);
                    group['locationAreasValueGroup'] = [locationValueArray, [Validators.required]];
                }
                group['locationAreas'] = [locationAreas, [Validators.required]];
                group['locationAreasData'] = new BehaviorSubject<AreaModel[]>(null);
                group['locationAreasOperators'] = [(restrictionLocation.excludeFlag) ? "!=" : "=", [Validators.required]];
                group['locationAreasOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                break;
            }
            case 'organisation': {
                let restrictionOrganisation = productpointOfSales.productRestrictionOrganisationView;
                group['organisationShow'] = [true, [Validators.nullValidator]],
                group['productRestrictionOrganisationId'] = [locationArrayDBId, [Validators.nullValidator]];
                let orgType = '';
                if (restrictionOrganisation.organisationTypeCode) {
                    orgType = 'type';
                    group['orgTypeValueType'] = [locationValueArray, [Validators.required]];
                    group['orgTypeValueGroup'] = [null, [Validators.required]];
                    group['orgTypeValueRole'] = [null, [Validators.required]];
                    group['orgTypeValueSpecific'] = [null, [Validators.required]];
                } else if (restrictionOrganisation.organisationGroupCode) {
                    orgType = 'group';
                    group['orgTypeValueType'] = [null, [Validators.required]];
                    group['orgTypeValueGroup'] = [locationValueArray, [Validators.required]];
                    group['orgTypeValueRole'] = [null, [Validators.required]];
                    group['orgTypeValueSpecific'] = [null, [Validators.required]];
                } else if (restrictionOrganisation.organisationRoleCode) {
                    orgType = 'role';
                    group['orgTypeValueType'] = [null, [Validators.required]];
                    group['orgTypeValueGroup'] = [null, [Validators.required]];
                    group['orgTypeValueRole'] = [locationValueArray, [Validators.required]];
                    group['orgTypeValueSpecific'] = [null, [Validators.required]];
                } else if (restrictionOrganisation.organisationId) {
                    orgType = 'specific';
                    group['orgTypeValueType'] = [null, [Validators.required]];
                    group['orgTypeValueGroup'] = [null, [Validators.required]];
                    group['orgTypeValueRole'] = [null, [Validators.required]];
                    group['orgTypeValueSpecific'] = [locationValueArray, [Validators.required]];
                }
                group['orgType'] = [orgType, [Validators.required]];
                group['orgTypeOperators'] = [(restrictionOrganisation.excludeFlag) ? "!=" : "=", [Validators.required]];
                group['orgTypeOperatorsData'] = new BehaviorSubject<OperatorModel[]>(null);
                break;
            }
        }

        let f = this.fb.group(group);
        return f;
    }

    private fillProductTypeData(f, productRestriction: RestrictionView) {
        if (productRestriction.type == "producttype") {
            this.productTypeDataService.getProductGroupData(f, this.productGroupReferences$);
            this.productTypeDataService.getProductTypeGroupData(f, this.productTypeGroups$);
        }
    }

    public delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
        let elementReference = document.querySelector('#btn' + this.configType + 'Add');
        if (elementReference instanceof HTMLElement) {
            elementReference.focus();
        }
        this.findDateOverlapAll();
    }

    public typeChange(value: any, ctl) {
        ctl.get('type').setValue(value);
        ctl.get('productCodeShow').setValue(false);
        ctl.get('productTypeShow').setValue(false);
        ctl.get('routeShow').setValue(false);
        ctl.get('locationShow').setValue(false);
        ctl.get('organisationShow').setValue(false);
        ctl.get('calendarShow').setValue(false);
        ctl.get('vehicleShow').setValue(false);
        ctl.get('submitted').setValue(false);
        if (value.toLowerCase() == "productcode") {
            ctl.get('productCodeShow').setValue(true);
        } else if (value.toLowerCase() == "producttype") {
            ctl.get('productTypeShow').setValue(true);
        } else if (value.toLowerCase() == "route") {
            ctl.get('routeShow').setValue(true);
        } else if (value.toLowerCase() == "location") {
            ctl.get('locationShow').setValue(true);
        } else if (value.toLowerCase() == "organisation") {
            ctl.get('organisationShow').setValue(true);
        } else if (value.toLowerCase() == "calendar") {
            ctl.get('calendarShow').setValue(true);
        } else if (value.toLowerCase() == 'vehicle') {
            ctl.get('vehicleShow').setValue(true);
        }
        this.ruleConfigSetRequireFieldService.setRequiredField(value, ctl, this.config, this.locationPoints$);
    }

    public productCatChange(value: string | string[], ctl) {
        ctl.get('productCat').setValue(value);
        this.productTypeDataService.getProductGroupData(ctl, this.productGroupReferences$);
        this.productTypeDataService.getProductTypeGroupData(ctl, this.productTypeGroups$);
    }

    public productGroupChange(value: string | string[], ctl) {
        ctl.get('productGroup').setValue(value);
        this.productTypeDataService.getProductTypeGroupData(ctl, this.productTypeGroups$);
    }

    public locationPointChange(value: any, ctl) {
        ctl.get('locationPoint').setValue(value);
        if (value.toLowerCase() == "sale") {
            ctl.get('locationPointODs').clearValidators();
            ctl.get('locationPointODs').updateValueAndValidity();
        }
        else {
            ctl.get('locationPointODs').setValidators(Validators.required);
            ctl.get('locationPointODs').updateValueAndValidity();
        }
        this.locationDataService.getLocationPointArea(ctl, this.areaTypeOption, this.locationPoints$);
        this.locationDataService.getLocationPointOperator(ctl, this.locationTypeOption, this.locationPoints$);
        this.locationDataService.getLocationPointAreaOD(ctl, this.locationTypeOption, this.areaTypeOption, this.locationPoints$);
    }

    public locationPointAreasChange(value: string | string[], ctl) {
        ctl.get('locationPointAreas').setValue(value);
        this.locationSetRequireFieldService.clearRequireLocationPoint(ctl);
        this.locationSetRequireFieldService.setRequireLocationPoint(ctl);
    }

    public locationAreasChange(value: string | string[], ctl) {
        ctl.get('locationAreas').setValue(value);
        this.locationDataService.getLocationAreasOperator(ctl, this.config, this.locationTypeOption);
        this.locationSetRequireFieldService.clearRequireLocation(ctl);
        this.locationSetRequireFieldService.setRequireLocation(ctl);
    }

    public orgTypeChange(value: string | string[], ctl) {
        ctl.get('orgType').setValue(value);
        this.organisationDataService.getOrgTypeOperator(ctl, this.config, this.orgTypeOperatorOption);
        this.organisationSetRequireFieldService.clearRequireOrganisation(ctl);
        this.organisationSetRequireFieldService.setRequireOrganisation(ctl);
    }

    public dateTypeChange(value: string | string[], ctl) {
        ctl.get('dateType').setValue(value);
        this.calendarDataService.getCalendarType(ctl, this.calendarTypeOption, this.dateTypes$);
        this.calendarDataService.getTimeZone(ctl, this.timeZoneOption, this.dateTypes$);
        this.calendarDataService.getCalendarOperator(ctl, this.calendarOperatorOption, this.dateTypes$);
        this.calendarDataService.getShowWeekDays(ctl, this.dateTypes$);
        let calendarTypeValue = ctl.get('calendarType').value;
        if (calendarTypeValue.toLowerCase() == "time") {
            this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(ctl, this.config, this.dateTypes$, validityTimeOption);
        }
        else {
            this.calendarSetDefaultService.setDateTimeFormat(ctl, this.config, this.dateTypes$,
                validityDateOption,
                validityDateTimeOption,
                this.dateFormat,
                this.dateTimeFormat);
        }
        this.findDateOverlapAll();
    }

    public calendarTypeChange(value: any, ctl) {
        ctl.get('calendarType').setValue(value);
        this.calendarDataService.getTimeZone(ctl, this.timeZoneOption, this.dateTypes$);
        this.calendarDataService.getCalendarOperator(ctl, this.calendarOperatorOption, this.dateTypes$);
        this.calendarDataService.getShowWeekDays(ctl, this.dateTypes$);
        let calendarTypeValue = ctl.get('calendarType').value;
        if (calendarTypeValue.toLowerCase() == "time") {
            this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(ctl, this.config, this.dateTypes$, validityTimeOption);
        }
        else {
            this.calendarSetDefaultService.setDateTimeFormat(ctl, this.config, this.dateTypes$,
                validityDateOption,
                validityDateTimeOption,
                this.dateFormat,
                this.dateTimeFormat);
        }
        this.calendarSetRequireFieldService.clearRequireCalendar(ctl);
        this.calendarSetRequireFieldService.setRequireCalendar(ctl);
        this.findDateOverlapAll();
    }

    public calendarOperatorChange(value: string | string[], ctl) {
        ctl.get('calendarOperator').setValue(value);
        this.calendarDataService.setStartDatePlaceHolder(ctl);
        this.calendarDataService.getShowWeekDays(ctl, this.dateTypes$);
        this.calendarSetRequireFieldService.clearRequireCalendar(ctl);
        this.calendarSetRequireFieldService.setRequireCalendar(ctl);
        this.findDateOverlapAll();
    }

    public selectChange(value: any, ctl, name: string) {
        ctl.get(name).setValue(value);
    }

    public selectChangeWeekdays(value, ctl) {
        ctl.get('weekDays').setValue(value);
        if (value != '')  {
            ctl.get('weekDays').setValue(this.removeBlankItems(value));
        }
        this.findDateOverlapAll();
    }

    private removeBlankItems(data: string[]): string[] {
        if (data) {
            return data.filter(val => val != '')
        }
        return [];
    }

    private getOperatorProductCodeData() {
        var filterProductCode = this.config.types.filter(x => x.typeCode == 'productcode');
        if (filterProductCode.length != 0) {
            this.operatorProductCodes$.next(filterProductCode[0].operators);
        }
    }

    private getOperatorVehicleData() {
        var filterVehicle = this.config.types.filter(x => x.typeCode == 'vehicle');
        if (filterVehicle.length != 0) {
            this.operatorVehicle$.next(filterVehicle[0].operators);
        }
    }

    private getOperatorProductTypeData() {
        var filterProductType = this.config.types.filter(x => x.typeCode == 'producttype');
        if (filterProductType.length != 0) {
            this.operatorProductTypes$.next(filterProductType[0].operators);
        }
    }

    private getOperatorRouteData() {
        var filterRoute = this.config.types.filter(x => x.typeCode == 'route');
        if (filterRoute.length != 0) {
            this.operatorRoutes$.next(filterRoute[0].operators);
        }
    }

    private getLocationData() {
        //Map DB With JSON
        let values = new Array();
        if (this.productLocationTypeReferences$.value) {
            var filterLocation = this.config.types.filter(x => x.typeCode == 'location'); //find in JSON
            if (filterLocation.length != 0) {
                var locationPoints = filterLocation[0].locationPoint;

                for (let locationPoint of locationPoints) {
                    var filterProductLocationType = this.productLocationTypeReferences$.value.filter(locationPoint.locationPointCode = x => x.productLocationTypeCode); // find in DB
                    if (filterProductLocationType.length != 0) {
                        let value = <LocationPointModel>{};
                        value.locationPointCode = filterProductLocationType[0].productLocationTypeCode;
                        value.locationPointName = filterProductLocationType[0].productLocationTypeName;
                        value.areas = locationPoint.areas;
                        value.operators = locationPoint.operators;
                        value.ods = locationPoint.ods;
                        values.push(value);
                    }
                }
            }
        }
        if (values) {
            this.locationPoints$.next(values);
        }
    }

    private getOrganisationData() {
        var filterOrganisation = this.config.types.filter(x => x.typeCode == 'organisation');
        if (filterOrganisation.length != 0) {
            this.orgTypes$.next(filterOrganisation[0].orgType);
        }
    }

    private getCalendarData() {
        let values = this.calendarDataService.getCalendarData(this.config, this.calendarValidityReferences$,
            this.dateTimeDimensionReferences$, this.conditionReferences$);
        if (values) {
            this.dateTypes$.next(values);
        }
    }

    private getDayData() {
        let day = new DayModel();
        let days = new Array();
        for (var i = 1; i <= 31; i++) {
            day = new DayModel();
            day.dayCode = i.toString();
            day.dayName = i.toString();
            days.push(day);
        }
        this.days$.next(days);
    }

    private setDefaultValue(f: UntypedFormGroup) {
        this.config.types.forEach(types => {
            this.setDefaultValueByType(f, types);
        });
    }

    private setDefaultValueByType(f: UntypedFormGroup, types: any) {
        switch (types.typeCode) {
            case 'productcode': {
                this.productCodeSetDefaultService.setDefaultOperatorProductCode(f, this.operatorProductCodes$, this.operatorProductCodeOption);
                break;
            }
            case 'producttype': {
                this.productTypeSetDefaultService.setDefaultOperatorProductType(f, this.operatorProductTypes$, this.operatorProductTypeOption);
                break;
            }
            case 'route': {
                this.routeSetDefaultService.setDefaultOperatorRoute(f, this.operatorRoutes$, this.operatorRouteOption);
                break;
            }
            case 'location': {
                if (types.locationPoint) {
                    this.locationSetDefaultService.setDefaultLocationPoint(f, this.locationPoints$, this.locationPointOption);
                    this.locationSetDefaultService.setDefaultLocationPointArea(f, this.locationPoints$, this.areaTypeOption);
                    this.locationSetDefaultService.setDefaultLocationOperator(f, this.locationPoints$, this.locationTypeOption);
                    this.locationSetDefaultService.setDefaultLocationAreaOD(f, this.locationPoints$, this.locationTypeOption);
                }
                else {
                    this.locationSetDefaultService.setDefaultLocationArea(f, this.config, this.locationAreasOption);
                    this.locationSetDefaultService.setDefaultLocationAreaOperator(f, this.config, this.locationAreasOperatorOption);
                }
                break;
            }
            case 'organisation': {
                this.organisationSetDefaultService.setDefaultOrgType(f, this.config, this.orgTypeOption);
                this.organisationSetDefaultService.setDefaultOrgTypeOperator(f, this.config, this.orgTypeOperatorOption);
                break;
            }
            case 'calendar': {
                this.setDefaultDateType(f);
                this.calendarDataService.getCalendarType(f, this.calendarTypeOption, this.dateTypes$);
                this.calendarDataService.getTimeZone(f, this.timeZoneOption, this.dateTypes$);
                this.calendarDataService.getCalendarOperator(f, this.calendarOperatorOption, this.dateTypes$);
                this.calendarDataService.getShowWeekDays(f, this.dateTypes$);
                break;
            }
            case 'vehicle': {
                this.vehicleSetDefaultService.setDefaultOperatorVehicle(f, this.operatorVehicle$, this.operatorVehicleOption);
                break;
            }
        }
    }

    private setDefaultDateType(f: UntypedFormGroup) {
        var filterType = this.config.types.filter(x => x.typeCode == "calendar");
        if (filterType.length != 0) {
            var filterplaceHolder = filterType[0].dateType.filter(x => x.dateTypeCode == "");
            if (filterplaceHolder.length != 0) {
                this.dateTypeOption.placeholder = filterplaceHolder[0].dateTypeName;
            }
            else {
                if (filterType[0].dateType.length != 0) {
                    if (this.dateTypes$.value) {
                        var datType = this.dateTypes$.value;
                        f.controls['dateType'].setValue(datType[0].dateTypeCode);
                        this.calendarDataService.getTimeZone(f, this.timeZoneOption, this.dateTypes$);
                        this.calendarDataService.getCalendarOperator(f, this.calendarOperatorOption, this.dateTypes$);
                        this.calendarDataService.getShowWeekDays(f, this.dateTypes$);
                        let calendarTypeValue = f.get('calendarType').value;
                        if (!calendarTypeValue) {
                            this.calendarDataService.getCalendarType(f, this.calendarTypeOption, this.dateTypes$);
                            calendarTypeValue = f.get('calendarType').value;
                        }
                        else {
                            this.calendarDataService.getCalendarTypeData(f, this.calendarTypeOption, this.dateTypes$);
                        }

                        if (calendarTypeValue.toLowerCase() == "time") {
                            this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(f, this.config, this.dateTypes$, validityTimeOption);
                        }
                        else {
                            this.calendarSetDefaultService.setDateTimeFormat(f, this.config, this.dateTypes$,
                                validityDateOption,
                                validityDateTimeOption,
                                this.dateFormat,
                                this.dateTimeFormat);
                        }
                    }
                }
            }
        }
    }

    public startDateChange(value: string, ctl: AbstractControl) {
        ctl.get("startDate").setValue(value);
        this.calendarSetDefaultService.setMinEndDate(value, ctl, validityDateOption, validityDateTimeOption);
        this.findDateOverlapAll();
    }

    public endDateChange(value: string, ctl) {
        ctl.get("endDate").setValue(value);
        this.findDateOverlapAll();
    }

    private setStartDateValue(dateTimeCode: string, f: UntypedFormGroup, startDateTime) {
        f.controls['timeZoneDisplay'].setValue(true);
        if (dateTimeCode == "date") {
            f.controls['startDate'].setValue(this.dateConverterService.toDateFormat(startDateTime));
            f.controls['dateTimeFormat'].setValue(this.dateFormat);
        }
        else {
            f.controls['startDate'].setValue(this.dateConverterService.convertDateTime24(startDateTime));
            f.controls['dateTimeFormat'].setValue(this.dateTimeFormat);
        }
        this.calendarSetDefaultService.setMinEndDate(f.get('startDate').value, f, validityDateOption, validityDateTimeOption);
    }

    private setEndDateAndWeekDayValue(dateTimeCode: string, f: UntypedFormGroup, productValidity: ProductValidityViewModel) {
        if (productValidity.conditionCode == "=" || productValidity.conditionCode == "!=") {
            f.controls['weekDaysDisplay'].setValue(false);
        }
        else {
            if (dateTimeCode == "date") {
                f.controls['endDate'].setValue(this.dateConverterService.toDateFormat(productValidity.endDateTime));
            }
            else {
                f.controls['endDate'].setValue(this.dateConverterService.convertDateTime24(productValidity.endDateTime));
            }
            f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
            f.controls['weekDaysDisplay'].setValue(true);
        }
    }

    private setStartTimeValue(f: UntypedFormGroup, startDateTime: Date) {
        f.controls['timeZoneDisplay'].setValue(true);
        f.controls['dateTimeFormat'].setValue(this.timeFormat);
        this.daterangepickerTimeOption = this.calendarSetDefaultService.setTimeFormat(f, this.config, this.dateTypes$, validityTimeOption);
        f.controls['daterangepickerOption'].setValue(this.daterangepickerTimeOption);
        f.controls['startTime'].setValue(this.dateConverterService.convertTime24(startDateTime));
    }

    private setEndTimeAndWeekDayValue(f: UntypedFormGroup, productValidity: ProductValidityViewModel) {
        if (productValidity.conditionCode == "<=" || productValidity.conditionCode == "=>") {
            f.controls['weekDaysDisplay'].setValue(true);
            f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
        }
        else {
            f.controls['endTime'].setValue(this.dateConverterService.convertTime24(productValidity.endDateTime));
            f.controls['weekDays'].setValue(this.calendarDataService.convertWeekDayData(productValidity));
            f.controls['weekDaysDisplay'].setValue(true);
        }
    }

    public requiredRow(ctl: AbstractControl) : boolean {
        return ctl.get("required")?.value;
    }

    public vehicleGroupChange(value: string | string[], ctl) {
        ctl.get('vehicleGroupCode').setValue(value);
        this.vehicleDataService.getVehicleTypeData(ctl, this.vehicleTypeReferences$);
        this.vehicleDataService.getVehicleCompositionData(ctl, this.vehicleCompositions$);
    }

    public vehicleTypeChange(value: string | string[], ctl) {
        ctl.get('vehicleTypeCode').setValue(value);
        this.vehicleDataService.getVehicleCompositionData(ctl, this.vehicleCompositions$);
    }
}
import { Component, ChangeDetectionStrategy, OnInit, Input, SimpleChange, OnChanges, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2'

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import { MediaService } from 'src/app/core/services/media-services/media-sevice';
import { TextMapperService } from '../shared/text-mapper.service';

import {
    MediaSearchCommandModel,
    MediaSearchViewModel
} from 'src/app/core/models/media-model';

import { MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { TextLibraryView } from '../shared/text-library.view';

import { MediaFileAssociationInMediaViewModel } from 'src/app/core/models/media-model/media-fileassociation/media-fileassociation-in-media-view.model';
import { MediaFileAssociationService } from 'src/app/core/services/media-services/media-fileassociation-service';
import { TextView } from '../../text/shared/text.view';

@Component({
    selector: 'op-text-library-table',
    templateUrl: './text-library-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TextMapperService]

})
export class TextLibraryTableComponent implements OnInit {

    @Input() mediaSearchCommand: MediaSearchCommandModel;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);

    @Output() onSaveMediaLibrary = new EventEmitter<TextView[]>();
    @Output() onCancelBrowseClick = new EventEmitter();
    
    public mediaTextLibrarys: TextLibraryView[] = new Array();
    public mediaFileAssociationInMedia: MediaFileAssociationInMediaViewModel[];
    public showFilterRow: boolean = true;
    public showHeaderFilter: boolean = true;
    public applyFilterTypes: any;
    public currentFilter: any;
    public mediaIdSelected: string;
    public pageSize: number = 10;
    libraryUsedDataSourceStorage: any;
    private entityExclude: string[] = ["UserAccount"];

    constructor(
        private mediaService: MediaService,
        private textMapperService: TextMapperService,
        private mediaFileAssociationService: MediaFileAssociationService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.searchMedia();
    }

    private searchMedia() {
        this.mediaSearchCommand.entityExclude = this.entityExclude;
        this.mediaService.search(this.mediaSearchCommand)
            .subscribe(
                (responses: MediaSearchViewModel[]) => {
                    this.libraryUsedDataSourceStorage = [];
                    this.mediaTextLibrarys = this.textMapperService.mediaSearchToTextLibraryViews(responses);
                    this.changeDetectorRef.detectChanges();
                }
            )
    }

    public selectionChangedHandler(e) {
        for (let text of this.mediaTextLibrarys) {
            var filter = e.instance.getSelectedRowsData().filter(x => x.mediaId == text.mediaId);
            if (filter.length == 0) {
                text.selected = false;
            }
            else {
                text.selected = true;
            }
        }
    }

    isText(e) {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == e.data.mediaTypeCode);
        if (filter.length > 0) {
            return filter[0].textOnlyFlag;
        }
        return false;
    }

    displayHTML(htmlText) {
        Swal.fire({
            html: htmlText,
            width: '900px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    displayText(text: string) {
        return this.textMapperService.displayTextMediaInGrid(text);
    }

    getLibraryUsed(key) {
        let item = this.libraryUsedDataSourceStorage.find((i) => i.key === key);
        if (!item) {
            this.mediaFileAssociationService.getMediaFileAssociationInMedia(key)
                .subscribe(
                    (responses: MediaFileAssociationInMediaViewModel[]) => {
                        var mediaFileAssociationInMedia = this.textMapperService.updateMediaId(key, responses)
                        item = {
                            key: key,
                            dataSourceInstance: new DataSource({
                                store: new ArrayStore({
                                    data: mediaFileAssociationInMedia,
                                    key: "mediaId"
                                }),
                                filter: ["mediaId", "=", key]
                            })
                        };
                        this.libraryUsedDataSourceStorage.push(item);
                        this.changeDetectorRef.detectChanges();
                        return item.dataSourceInstance;
                    }
                )
        }
        else {
            return item.dataSourceInstance;
        }
    }

    public cancelBrowse() {
        this.onCancelBrowseClick.emit();
    }

    public saveMediaBrowse() {
        var mediaLibrarySelected = this.mediaTextLibrarys.filter(x => x.selected == true);
        var viewTexts = this.textMapperService.mediaTextLibraryToMediaTextViews(mediaLibrarySelected);
        this.onSaveMediaLibrary.emit(viewTexts);
    }    
}
export class ProductValidityViewModel {
    public productValidityId: string;
    public calendarValidityCode: string;
    public calendarValidityName: string;
    public conditionCode: string;
    public conditionName: string;
    public dateTimeDimensionCode: string;
    public dateTimeDimensionName: string;
    public startDateTime: Date;
    public endDateTime: Date;
    public mondayFlag: boolean;
    public tuesdayFlag: boolean;
    public wednesdayFlag: boolean;
    public thursdayFlag: boolean;
    public fridayFlag: boolean;
    public saturdayFlag: boolean;
    public sundayFlag: boolean;
    public januaryFlag: boolean;
    public februaryFlag: boolean;
    public marchFlag: boolean;
    public aprilFlag: boolean;
    public mayFlag: boolean;
    public juneFlag: boolean;
    public julyFlag: boolean;
    public augustFlag: boolean;
    public septemberFlag: boolean;
    public octoberFlag: boolean;
    public novemberFlag: boolean;
    public decemberFlag: boolean;
    public utcFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public inherit: boolean;
}
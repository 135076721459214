import { createAction, props } from '@ngrx/store';

export const loginStart = createAction(
  '[Auth] Login Start',
  props<{
    username: string;
    password: string;
    rememberMe: boolean;
    acceptCookies: boolean;
  }>()
);

export const authenticateSuccess = createAction(
  '[Auth] Authenticate Success',
  props<{
    token: string;
    expires_in: string;
    username: string;
    orgId: string;
    isVerifyCode: boolean;
    acceptCookies: boolean;
    rememberMe: boolean;
  }>()
);

export const authenticateFail = createAction(
  '[Auth] Authenticate Fail',
  props<{
    errorMessage: string;
  }>()
);

export const logout = createAction('[Auth] Logout');

export const twoStepVerified = createAction(
  '[Auth] Two Step Verified',
  props<{
    twoStepVerified: boolean;
  }>()
);

export const orgSelected = createAction(
  '[Auth] Organisation Selected',
  props<{
    orgSelected: boolean;
  }>()
);

export const userLogonName = createAction(
  '[Auth] User Logon Name',
  props<{
    userAccountId: string;
    firstName: string;
    lastName: string;
  }>()
)

export const afterUserSelectOrg = createAction(
  '[Auth] After User Select Organisation',
  props<{
    email: string;
    orgCallName: string;
    securityGroupName: string;
    lastLogon: Date;
    buildVersion: string;
    envName: string;
    organisationId: string;
  }>()
)

export const userProfileImageUrl = createAction(
  '[Auth] User Profile Image Url',
  props<{
    userProfileImageUrl: string;
  }>()
)

export const autoLogin = createAction(
  '[Auth] Auto Login]'
)

export const orgCount = createAction(
  '[Auth] User Organisation Count',
  props<{
    orgCount: number;
  }>()
)

export const forgotPassword = createAction(
  '[Auth] forgotPassword',
  props<{
    username: string;
  }>()
);

export const changePassword = createAction(
  '[Auth] changePassword',
  props<{
    changePasswordOrgId: string;
    changePasswordRequestId: string;
    changePasswordLinkInvalid: boolean;
    changePasswordLinkInvalidMessage: string;
  }>()
);

export const changePasswordExpired = createAction(
  '[Auth] changePasswordExpired',
  props<{
    userAccountId: string;
    changePasswordExpired: boolean;
  }>()
);
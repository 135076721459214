import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { NgModule } from '@angular/core';

import { SelectOrganisationComponent } from './select-organisation/select-organisation.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { OopsSolutionGuard } from './../../core/guards/oops-solution.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotUserNameComponent } from './forgot-username/forgot-username.component';
import { ChangePasswordExpiredComponent } from './change-password-expired/change-password-expired.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [OopsSolutionGuard],
    children: [
      {
        path: 'authentication',
        component: AuthenticationComponent,
      },
      {
        path: 'select-organisation',
        component: SelectOrganisationComponent,
      }  
    ],
  },
  {
      path: 'auth',
      component: AuthComponent,
      children: [
        {
          path: 'forgot-password',
          component: ForgotPasswordComponent,
        },
        {
          path: 'change-password',
          component: ChangePasswordComponent
        },
        {
          path: 'forgot-username',
          component: ForgotUserNameComponent
        },
        {
          path: 'change-password-expired',
          component: ChangePasswordExpiredComponent
        },               
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

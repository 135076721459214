import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { TreeNode } from "primeng/api";
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { ActivityDomainComponent } from "src/app/core/components/activity-domain/activity-domain.component";
import { PointOfSalesMapperService } from "src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service";
import { RestrictionMapperService } from "src/app/core/components/rules-config/shared/mappers/restriction-mapper.service";
import { Select2DataMapperService } from "src/app/core/components/rules-config/shared/mappers/select2-data-mapper.service";
import { ValidityMapperService } from "src/app/core/components/rules-config/shared/mappers/validity-mapper.service";
import { TranslationTextMapperService } from "src/app/core/components/translation-text/shared/translation-text-mapper.service";
import { MediaSearchCommandModel, MediaViewModel } from "src/app/core/models/media-model";
import { OrganisationModel, OrganisationRoleReferenceModel } from "src/app/core/models/organisation-model";
import { MerchandizeAddCommandModel, MerchandizeEditCommandModel, MerchandizeViewModel } from "src/app/core/models/product-model/merchandize-model";
import { ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { ProductHashTagViewModel } from "src/app/core/models/product-model/product-base-model/product-hashtag";
import { ProductInventoryDimensionCommandModel, ProductInventoryDimensionViewModel } from "src/app/core/models/product-model/product-base-model/product-inventory-dimension";
import { ProductInventoryHierarchyViewModel } from "src/app/core/models/product-model/product-base-model/product-inventory-hierarchy";
import { ProductNumberViewModel } from "src/app/core/models/product-model/product-base-model/product-number";
import { ProductRestrictionLocationViewModel, ProductRestrictionOrganisationViewModel, ProductRestrictionProductNumberViewModel, ProductRestrictionProductViewModel, ProductRestrictionRouteViewModel } from "src/app/core/models/product-model/product-base-model/product-restriction";
import { ProductValidityViewModel } from "src/app/core/models/product-model/product-base-model/product-validity";
import { CalendarValidityReferenceModel, ConditionReferenceModel, CountryReferenceModel, DateTimeDimensionReferenceModel, DomainAttributeModel, HashTagReferenceModel, LocationGroupModel, OrganisationGroupReferenceModel, OrganisationTypeReferenceModel, RegionReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { LanguageReferenceModel, MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel, ProductNumberTypeReferenceModel, ProductTypeGroupModel } from "src/app/core/models/reference-model/reference-product-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DomainAttributeService, HashTagReferenceService, LanguageReferenceService, OrganisationGroupReferenceService, RegionReferenceService } from "src/app/core/services/airline-services";
import { OrganisationRoleReferenceService, OrganisationService } from "src/app/core/services/organisation-services";
import { MerchandizeService } from "src/app/core/services/product-services";
import { LocationGroupService } from "src/app/core/services/reference-service/reference-general-service";
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from "src/app/core/services/reference-service/reference-media-service";
import { ProductTypeGroupService } from "src/app/core/services/reference-service/reference-product-service";
import { CalendarValidityService, ConditionReferenceService, CountryReferenceService, DateTimeDimensionReferenceService, OrganisationTypeReferenceService, ProductCategoryReferenceService, ProductGroupReferenceService, ProductLocationPointReferenceService, ProductLocationTypeReferenceService, ProductNumberTypeReferenceService, StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { ActionService } from "src/app/core/utils/action.service";
import { ActivityStoreService } from "src/app/core/utils/activity-store.service";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { NavigationService } from "src/app/shared/utils/navigation";
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { TreeMapperService } from "src/app/modules/product-management/product-categories-content/shared/mapper/tree-mapper.service";
import { MediaComponent } from "src/app/core/components/media/media.component";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { TextMapperService } from "src/app/core/components/text/shared/text-mapper.service";
import { TextComponent } from "src/app/core/components/text/text.component";
import { MERCHANDIZE_GENERAL_KEYS, MERCHANDIZE_REF_CODE } from "../../shared/constant/merchandize-ref-code.constant";
import { USAGE_TYPE } from "../../shared/constant/usage-type.constant";
import { HashTagMapperService } from "../../shared/mapper/hashtag-mapper.service";
import { InventoryAttributeMapperService } from "../../shared/mapper/inventory-attribute-mapper.service";
import { NumberMapperService } from "../../shared/mapper/number-mapper.service";
import { ProductHashTagView } from "../../shared/views/product-hashtag.view";
import { ProductAttributeAndRuleComponent } from "../attribute-and-rule/attribute-and-rule.component";
import { InventoryAttributeComponent } from "../attribute-and-rule/inventory-attribute/inventory-attribute.component";
import { ProductGeneralComponent } from "./general/product-general.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { generalInfoFormOption } from "./shared/form-config";
import { MapperService } from "./shared/mapper.service";
import { UIService } from "./shared/ui.service";

@Component({
    selector: 'op-merchandize-detail',
    templateUrl: './merchandize-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AttributeMapperService, HashTagMapperService, InventoryAttributeMapperService,
        MapperService, MediaMapperService, NumberMapperService, PointOfSalesMapperService, ProductTypeGroupService, RestrictionMapperService, 
        Select2DataMapperService, TextMapperService,TranslationTextMapperService, TreeMapperService, UIService, ValidityMapperService, ActionbarService, 
        AlertBarService
    ]
})
export class MerchandizeDetailComponent extends OopsComponentFormBase implements OnInit, OnDestroy, AfterViewInit {
    readonly generalTabId: string = 'general';
    readonly attributesTabId: string = 'attributes';
    readonly textTabId: string = 'text';
    readonly mediaTabId: string = 'media';
    readonly translationTabId: string = 'translation';
    readonly historyTabId: string = 'history';
    readonly readModeTabId: string = 'readMode';
    readonly orderTabId: string = "order";
    readonly inventoryTab: string = "inventory";

    readonly generalPanelName: string = 'General Information';
    readonly productNumberPanelName: string= 'Product Number';
    readonly attributePanelName: string = 'Attribute';
    readonly restrictionPanelName: string = 'Restriction';
    readonly validityPanelName: string = 'Validity';
    readonly pointOfSalesPanelName: string = 'Point Of Sales';

    readonly ALERT_HEADER: string = "Product";
    readonly FAILED: string = "Failed save data.";
    private readonly DELAY_TIME = 100;
    private readonly ENTITY_NAME: string = "Product";
    
    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() rootProductId: string;

    @ViewChild(ProductGeneralComponent) productGeneralComponent: ProductGeneralComponent;
    @ViewChild(ProductAttributeAndRuleComponent) attributeAndRuleComponent: ProductAttributeAndRuleComponent;
    @ViewChild(InventoryAttributeComponent) inventoryAttributeComponent: InventoryAttributeComponent;
    @ViewChild("inventoryComponent") inventoryComponent: InventoryComponent;
    //TODO Next
    //@ViewChild("inventoryComponentSpecificRef") inventorySpecificComponent: InventoryComponent;
    @ViewChild(MediaComponent) mediaComponent: MediaComponent;
    @ViewChild(TextComponent) textComponent: TextComponent;
    //TODO Next
    //@ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;

    public isReferencesBinding: boolean = false;
    public calendarValidityReferences$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    public conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    public countryReferenceSelect2Data: Select2Data[];
    public dateTimeDimensionReferences$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    public domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public hashTagReferences: HashTagReferenceModel[];
    public languageReferences$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public locationGroupSelect2Data: Select2Data[];
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeReferences$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaTypeFileTypes$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public mediaUseReferences$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public organisationGroupSelect2Data: Select2Data[];
    public organisationSelect2Data: Select2Data[];
    public organisationTypeSelect2Data: Select2Data[];
    public organisationRoleSelect2Data: Select2Data[];
    private productAttributesInherit: ProductAttributeViewModel[];
    public productCategoryReferences$ = new BehaviorSubject<ProductCategoryReferenceModel[]>(null);
    public productGroupReferences$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
    public productHashTags$ = new BehaviorSubject<ProductHashTagView[]>(null);
    public productHashtagValueViews: ProductHashTagView[] = new Array();
    public productInventoryDimensions: ProductInventoryDimensionViewModel[];
    public productInventoryHierarchy: ProductInventoryHierarchyViewModel[];
    public productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    public productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    public productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public productTypeGroups$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
    public providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public regionReferenceSelect2Data: Select2Data[];
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);

    private parentId: string;
    private copy: boolean = false;
    public newFromParent: boolean = false;
    public nodeSelected: boolean = false;
    public inventoryAttributeLowerLevel: string;
    public merchandizeTree: TreeNode[];
    public merchandizingDetailFormGroup: UntypedFormGroup;
    public productAttributeValue: any;
    public productTypeCode: string;
    public productName$ = new BehaviorSubject<string>(null);
    public rootCommandData: MerchandizeEditCommandModel;
    private inventoryData: any;
    public heightPanelGeneral: number = 450;
    public userSecurity: SecurityGroupSecurityModel;

    public hiddenTree: boolean = false;
    public showReadMode: boolean = false;
    public showTranslation: boolean = false;
    public showInvAttributeType: boolean = true;
    public showInv: boolean = false;
    public saveAction: boolean = false;

    public selectedTab = this.generalTabId;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;
    unsubscribe$ = new Subject();

    get hideGeneralTab(): boolean {
        let hide = this.selectedTab != this.generalTabId &&
            this.selectedTab != this.attributesTabId &&
            this.selectedTab != this.translationTabId;
        return hide;
    }
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    
    constructor(
        private actionbarService: ActionbarService,
        public alertBarService: AlertBarService,
        private alertService: ToastrService,
        private attributeMapperService: AttributeMapperService,
        private calendarValidityReferenceService: CalendarValidityService,
        private conditionReferenceService: ConditionReferenceService,
        private countryReferenceService: CountryReferenceService,
        private dateConverterService: DateConverterService,
        private dateTimeDimensionReferenceService: DateTimeDimensionReferenceService,
        private domainAttributeService: DomainAttributeService,
        private changeDetectionRef: ChangeDetectorRef,
        private focusingService: FocusingService,
        private formBuilder: UntypedFormBuilder,
        private hashTagMapperService: HashTagMapperService,
        private hashTagReferenceService: HashTagReferenceService,
        private inventoryAttributeMapperService: InventoryAttributeMapperService,
        private languageReferenceService: LanguageReferenceService,
        private locationGroupService: LocationGroupService,
        private mapperService: MapperService,
        private mediaHashTagService: MediaHashTagService,
        private mediaMapperService: MediaMapperService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private merchandizeService: MerchandizeService,
        private navigationService: NavigationService,
        private numberMapperService: NumberMapperService,
        private organisationService: OrganisationService,
        private organisationGroupReferenceService: OrganisationGroupReferenceService,
        private organisationTypeReferenceService: OrganisationTypeReferenceService,
        private organisationRoleReferenceService: OrganisationRoleReferenceService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private productCategoryReferenceService: ProductCategoryReferenceService,
        private productGroupReferenceService: ProductGroupReferenceService,
        private productLocationPointReferenceService: ProductLocationPointReferenceService,
        private productLocationTypeReferenceService: ProductLocationTypeReferenceService,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private productTypeGroupService: ProductTypeGroupService,
        private regionReferenceService: RegionReferenceService,
        private restrictionMapperService: RestrictionMapperService,
        private select2DataMapperService: Select2DataMapperService,
        private spinnerService: LoadingSpinnerService,
        private statusReferenceService: StatusReferenceService,
        private textMapperService: TextMapperService,
        private treeMapperService: TreeMapperService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private validityMapperService: ValidityMapperService,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService
    ) {
        super(formBuilder);
        this.init();
    }

    private navigationRequestParams: any;

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.subscribeToActionBar();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private init() {
        this.navigationRequestParams = this.navigationService.getParams();
        if (!this.navigationRequestParams)
            this.navigationRequestParams = this.navigationService.getPreviousTabParams()?.params;

        this.id = this.navigationRequestParams?.id ?? null;
        this.copy = this.navigationRequestParams?.copy ?? false;
        this.newProduct = this.copy ? true : this.id == null;
        this.userSecurity = this.navigationRequestParams?.userSecurity;
        this.formSetup();
        this.loadPage();
        this.addAction();
        if(!this.id) {
            this.activityStore();
        }
    }

    public initForm() {
        
    }

    private formSetup() {
        this.merchandizingDetailFormGroup = this.formBuilder.group({
            generalInfo: this.formBuilder.group(generalInfoFormOption)
        });
        this.initNewform(null, null, null, null);
    }

    private loadProductByIdIfValidAfterFinishReferences() {
        if (this.id) {
            this.getProduct(this.id)
            if (this.copy) {
                this.rootProductId = this.id;
                this.id = null;
            }
        }
    }

    private initNewform(parentId, productGroupCode, productTypeGroupId, providerId) {
        this.merchandizingDetailFormGroup.controls.generalInfo.patchValue({
            parentProductId: parentId,
            productName: "",
            productCategoryCode: MERCHANDIZE_REF_CODE.productCategoryCode,
            productGroupCode: productGroupCode,
            productTypeGroupId: productTypeGroupId,
            providerId: providerId,
            draftFlag: true,
            statusCode: "A",
            productDescription: "",
            usageTypeCode: "DATA"
        });
        this.productTypeCode = productTypeGroupId;
        this.productName$ = new BehaviorSubject<string>(null);
    }
    
    private loadPage() {
        this.spinnerService.show();
        this.hiddenTree = this.newProduct;

        if (!this.isReferencesBinding) {
            this.isReferencesBinding = true;
            this.fetchData();
        }
    }

    private fetchData() {
        let services = [];
        // General
        services = services.concat(this.organisationService.getByOrganisationType(MERCHANDIZE_REF_CODE.orgTypeSupplierCode))
        services = services.concat(this.productCategoryReferenceService.getAll());
        services = services.concat(this.productGroupReferenceService.getAll());
        services = services.concat(this.productTypeGroupService.getAll());
        services = services.concat(this.statusReferenceService.getAll());
        services = services.concat(this.usageTypeReferenceService.getUsageTypeReferences());

        // Attribute & Rule
        services = services.concat(this.calendarValidityReferenceService.getByCalendarValidityCodes(MERCHANDIZE_REF_CODE.calendarValidityCode));
        services = services.concat(this.conditionReferenceService.getConditionsByCodes([]));
        services = services.concat(this.countryReferenceService.searchAllCountry());
        services = services.concat(this.dateTimeDimensionReferenceService.getDateTimeDimensionReference());
        services = services.concat(this.domainAttributeService.getByProductType(MERCHANDIZE_REF_CODE.attributeGroupCode, "", true));

        services = services.concat(this.hashTagReferenceService.getAll());
        services = services.concat(this.languageReferenceService.getByOrganisation());
        services = services.concat(this.locationGroupService.getAll());
        services = services.concat(this.mediaHashTagService.getAll());
        services = services.concat(this.mediaTypeFileTypeService.getAll());
        services = services.concat(this.mediaTypeReferenceService.getAll());
        services = services.concat(this.mediaUseReferenceService.getAll());
        services = services.concat(this.organisationService.getByStatus(MERCHANDIZE_REF_CODE.activeStatus));
        services = services.concat(this.organisationGroupReferenceService.getOrganisationGroupReference());
        services = services.concat(this.organisationTypeReferenceService.getByStatus(MERCHANDIZE_REF_CODE.activeStatus) );
        services = services.concat(this.organisationRoleReferenceService.getAllActive());

        services = services.concat(this.organisationService.getByOrganisationType(MERCHANDIZE_REF_CODE.orgTypeProviderCode)); // Providers
        services = services.concat(this.productLocationPointReferenceService.getAll());
        services = services.concat(this.productLocationTypeReferenceService.getByProductLocationTypeCode(MERCHANDIZE_REF_CODE.productLocationTypeCode));
        services = services.concat(this.productNumberTypeReferenceService.getAll());
        services = services.concat(this.regionReferenceService.getRegionReference());
        
        forkJoin(services).subscribe(
            res => {
                let i = 0;
                this.extractOrgTypeSupplierReferences(res[i++] as OrganisationModel[])
                this.extractProductCategoryReferences(res[i++] as ProductCategoryReferenceModel[]);
                this.extractProductGroupReferences(res[i++] as ProductGroupReferenceModel[]);
                this.extractProductTypeGroup(res[i++] as ProductTypeGroupModel[]);
                this.extractStatusReferences(res[i++] as StatusReferenceModel[]);
                this.extractUsageTypeReferences(res[i++] as UsageTypeReferenceModel[]);
                
                // Attribute & Rules
                this.extractCalendarValidityReferences(res[i++] as CalendarValidityReferenceModel[]);
                this.extractConditionReferences(res[i++] as ConditionReferenceModel[]);
                this.extractCountryReferences(res[i++] as CountryReferenceModel[]);
                this.extractDateTimeDimensionReferences(res[i++] as DateTimeDimensionReferenceModel[]);
                this.extractDomainAttributeTypes(res[i++] as DomainAttributeModel[]);

                this.extractHashTagReferences(res[i++] as HashTagReferenceModel[]);
                this.extractLanguageReferences(res[i++] as LanguageReferenceModel[]);
                this.extractLocationGroups(res[i++] as LocationGroupModel[]);
                this.extractMediaHashTags(res[i++] as MediaHashTagModel[]);
                this.extractMediaTypeFileTypes(res[i++] as MediaTypeFileTypeModel[]);
                this.extractMediaTypeReferences(res[i++] as MediaTypeReferenceModel[]);
                this.extractMediaUseReferences(res[i++] as MediaUseReferenceModel[]);
                this.extractOrganisations(res[i++] as OrganisationModel[]);
                this.extractOrganisationGroupReferences(res[i++] as OrganisationGroupReferenceModel[]);
                this.extractOrganisationTypeReferences(res[i++] as OrganisationTypeReferenceModel[]);
                this.extractOrganisationRoleReferences(res[i++] as OrganisationRoleReferenceModel[]);

                this.extractOrgTypeProviderReferences(res[i++] as OrganisationModel[]);
                this.extractProductLocationPointReferences(res[i++] as ProductLocationPointReferenceModel[]);
                this.extractProductLocationTypeReferences(res[i++] as ProductLocationTypeReferenceModel)
                this.extractProductNumberTypeReferences(res[i++] as ProductNumberTypeReferenceModel[]);
                this.extractRegionReferences(res[i++] as RegionReferenceModel[]);

                this.spinnerService.hide();
                this.loadProductByIdIfValidAfterFinishReferences();
            }
        )
        
    }

    private extractCalendarValidityReferences(res: CalendarValidityReferenceModel[]) {
        this.calendarValidityReferences$.next(res);
    }

    private extractConditionReferences(res: ConditionReferenceModel[]) {
        this.conditionReferences$.next(res);
    }

    private extractCountryReferences(res: CountryReferenceModel[]) {
        this.countryReferenceSelect2Data = this.select2DataMapperService.countryReferenceToSelect2Data(res);
    }

    private extractDateTimeDimensionReferences(res: DateTimeDimensionReferenceModel[]) {
        this.dateTimeDimensionReferences$.next(res);
    }

    private extractDomainAttributeTypes(res: DomainAttributeModel[]) {
        res.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        this.domainAttributeSearch$.next(res);
    }

    private extractHashTagReferences(res: HashTagReferenceModel[]) {
        this.hashTagReferences = res;
        this.setProductHashTagValue(null);
    }

    private extractLocationGroups(res: LocationGroupModel[]) {
        this.locationGroupSelect2Data = this.select2DataMapperService.locationGroupToSelect2Data(res);
    }

    private extractMediaHashTags(res: MediaHashTagModel[]) {
        this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(res);
    }

    private extractMediaTypeFileTypes(res: MediaTypeFileTypeModel[]) {
        this.mediaTypeFileTypes$.next(res);
    }

    private extractMediaTypeReferences(res: MediaTypeReferenceModel[]) {
        this.mediaTypeReferences$.next(res);
    }

    private extractMediaUseReferences(res: MediaUseReferenceModel[]) {
        this.mediaUseReferences$.next(res);
    }

    private extractOrganisations(res: OrganisationModel[]) {
        this.organisationSelect2Data = this.select2DataMapperService.organisationToSelect2Data(res);
    }

    private extractOrganisationGroupReferences(res: OrganisationGroupReferenceModel[]) {
        this.organisationGroupSelect2Data = this.select2DataMapperService.organisationGroupToSelect2Data(res);
    }

    private extractOrganisationTypeReferences(res: OrganisationTypeReferenceModel[]) {
        this.organisationTypeSelect2Data = this.select2DataMapperService.organisationTypeToSelect2Data(res);
    }

    private extractOrganisationRoleReferences(res: OrganisationRoleReferenceModel[]) {
        this.organisationRoleSelect2Data = this.select2DataMapperService.organisationRoleToSelect2Data(res);
    }

    private extractOrgTypeProviderReferences(res: OrganisationModel[]) {
        this.providers$.next(res
            .filter(x => x.providerIataCode)
            .sort((a, b) => (a.providerIataCode < b.providerIataCode ? -1 : 1)));
    }

    private extractOrgTypeSupplierReferences(res: OrganisationModel[]) {
        this.suppliers$.next(res);
    }

    private extractProductCategoryReferences(res: ProductCategoryReferenceModel[]) {
        let values = res.filter(x => x.productCategoryCode == MERCHANDIZE_REF_CODE.productCategoryCode);
        this.productCategoryReferences$.next(values);
    }

    private extractProductGroupReferences(res: ProductGroupReferenceModel[]) {
        let values = res.filter(x => x.productCategoryCode == MERCHANDIZE_REF_CODE.productCategoryCode);
        this.productGroupReferences$.next(values);
    }

    private extractProductLocationPointReferences(res: ProductLocationPointReferenceModel[]) {
        this.productLocationPointReferences$.next(res);
    }

    private extractProductLocationTypeReferences(res: ProductLocationTypeReferenceModel) {
        if (res) {
            let values = new Array<ProductLocationTypeReferenceModel>();
            values.push(res);
            this.productLocationTypeReferences$.next(values);
        }
    }

    private extractProductNumberTypeReferences(res: ProductNumberTypeReferenceModel[]) {
        if (res) {
            this.productNumberTypeReferences$.next(res);
        }
    }

    private extractProductTypeGroup(res: ProductTypeGroupModel[]) {
        if (res) {
            this.productTypeGroups$.next(res);
        }
    }

    private extractRegionReferences(res: RegionReferenceModel[]) {
        this.regionReferenceSelect2Data = this.select2DataMapperService.regionReferenceToSelect2Data(res);
    }

    private extractStatusReferences(res: StatusReferenceModel[]) {
        this.statusReferences$.next(res);
    }

    private extractUsageTypeReferences(res: UsageTypeReferenceModel[]) {
        let values = res.filter(x => x.usageTypeCode == USAGE_TYPE.data || x.usageTypeCode == USAGE_TYPE.template);
        this.usageTypeReferences$.next(values);
    }

    private extractLanguageReferences(res: LanguageReferenceModel[]) {
        this.languageReferences$.next(res);
    }

    private setProductHashTagValue(value: ProductHashTagViewModel[]) {
        let productHashtagViews = this.hashTagMapperService.productHashTagViews(this.hashTagReferences, value, false);
        this.productHashtagValueViews = productHashtagViews.filter(x => x.productHashTagId != null);
        this.productHashTags$.next(productHashtagViews);
    }

    public hashTagValueReturn(value) {
        this.productHashtagValueViews = value;
    }

    public returnProductAttributeValue(value) {
        this.productAttributeValue = value;
    }
    
    public productTypeCodeChange(value) {
        this.productTypeCode = value;
    }

    private getProduct(productId) {
        if (productId) {
            this.spinnerService.show();
            this.storeRootData();
            this.merchandizeService.getByProductId(productId)
                .subscribe(
                    (responses: MerchandizeViewModel) => {
                        this.fillModelToForm(responses);
                        this.merchandizeTree = this.treeMapperService.productTreeView(responses.productHierarchy);
                        this.setProductHashTagValue(responses.productHashtags);
                        this.fillModelToProductNumber(responses.productNumbers);
                        this.fillModelToProductRestriction(responses.productRestrictionProducts,
                            responses.productRestrictionProductNumbers,
                            responses.productRestrictionRoutes,
                            responses.productRestrictionLocations
                        );
                        this.fillModelToProductValidity(responses.productValidities);
                        this.fillModelToProductAttribute(responses.productInventoryDimensions, responses.productAttributes, responses.isHighestLevelAttrInv, responses.draftFlag);
                        this.fillModelToProductInventoryAttributeType(responses.productInventoryDimensions, responses.productAttributes, responses.draftFlag);
                        this.fillModelToPointOfSales(responses.productRestrictionOrganisations, responses.productRestrictionLocations);
                        
                        this.fillModelToInventory(responses.productInventoryHierarchy, responses.inventoryAttributeLowerLevel);
                        // TODO
                        //this.fillModelToTranslationName(responses.productName, responses.languageTranslationSet);
                        this.fillModelToText(responses.medias);
                        //this.fillModelToTranslationText(responses.medias);
                        this.fillModelToMedia(responses.medias);
                        //this.fillModelToTranslationMedia(responses.medias);

                        this.newFromParent = false;
                        this.attributeAndRuleComponent.productAttributeComponent.newFromParent = this.newFromParent;
                        this.setDefaultActionBarHandler();
                    },
                    error => {
                        this.changeDetectionRef.detectChanges;
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private storeRootData() {
        if (this.copy && this.id == this.rootProductId && this.attributeAndRuleComponent) {
            this.rootCommandData = this.fillFormGroupToEditModel();
        }
    }

    private fillFormGroupToEditModel(): MerchandizeEditCommandModel {
        let generalInfo = this.merchandizingDetailFormGroup.controls.generalInfo;
        let formValue = generalInfo.value;
        var productGroupCode = generalInfo.get("productGroupCode").value;
        var productTypeGroupId = generalInfo.get("productTypeGroupId").value;
        var draftFlag = generalInfo.get("draftFlag").value;
        var usageTypeCode = generalInfo.get("usageTypeCode").value;

        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms, this.providers$);

        let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
            this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);

        let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
        let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
        let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
        let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

        let productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.productAttributeComponent.forms);

        var productInventoryDimensions: ProductInventoryDimensionCommandModel[] = new Array();
        if (this.inventoryAttributeComponent.isInherit == false) {
            productInventoryDimensions = this.inventoryAttributeMapperService.productInventoryDimensionToModels(this.inventoryAttributeComponent.productAttributeInventoryValue,
                this.productInventoryDimensions, MERCHANDIZE_REF_CODE.dimensionTypeCode);
        }

        let productInventories = this.mapperService.productInventoryToModels(this.inventoryData);


        let medias = this.textMapperService.textToMediaModels(this.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        medias = this.mediaMapperService.mediaToMediaModels(medias, this.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);
        // TODO: Translation
        // medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);
        // medias = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, medias);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        // TODO
        // let languageTranslationSet = this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
        //     this.translationNameComponent.languageTranslationSetId, this.TRANSLATION_NAME, this.ENTITY_NAME, this.id, this.translationNameComponent.translationViews);

        let editCommand: MerchandizeEditCommandModel = {
            productId: this.id,
            productCategoryCode: MERCHANDIZE_REF_CODE.productCategoryCode,
            productGroupCode: productGroupCode,
            productTypeCode: productTypeGroupId,
            providerId: formValue.providerId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            supplierId: null,
            displayCode: null,
            draftFlag: draftFlag,
            finalFlag: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            usageTypeCode: usageTypeCode,
            isOwnerFilter: null,
            filterUserAccountId: null,
            statusCode: formValue.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: productInventoryDimensions,
            productInventories: productInventories,
            productRestrictionOrganisations: productRestrictionOrganisations,
            languageTranslationSet: null, //languageTranslationSet,
            medias: medias
        };
        return editCommand;
    }

    private fillModelToForm(responses: MerchandizeViewModel) {
        this.id = null;
        this.changeDetectionRef.markForCheck();
        this.productGeneralComponent.filterProductTypeGroup(responses.productGroupCode);
        var generalInfo = this.merchandizingDetailFormGroup.controls.generalInfo;
        var productName = this.getProductName(responses.productName);
        var usageTypeCode = this.getUsageTypeCode(responses.usageTypeCode);
        generalInfo.patchValue({
            productCategoryCode: MERCHANDIZE_REF_CODE.productCategoryCode,
            productGroupCode: responses.productGroupCode,
            productTypeGroupId: responses.productTypeCode,
            providerId: responses.providerId,
            parentProductId: responses.parentProductId,
            productName: productName,
            productDescription: responses.productDescription,
            statusCode: responses.statusCode,
            draftFlag: responses.draftFlag,
            usageTypeCode: usageTypeCode,
            commitDateTime: this.dateConverterService.convertDateTime(responses.commitDateTime),
            commitByName: responses.commitFirstname + ' ' + responses.commitLastname
        });
        this.id = responses.productId;
        this.disableContrtolGeneral(generalInfo, responses.draftFlag);

        // if not node selected read parent product        
        if (!this.nodeSelected) {
            this.parentId = responses.productId;
        }

        //for display attribute 
        this.productTypeCode = responses.productTypeCode;
        this.productName$ = new BehaviorSubject<string>(responses.productName);
        this.changeDetectionRef.detectChanges();

        this.addAction();
    }

    private disableContrtolGeneral(generalInfo: AbstractControl, draftFlag: boolean) {
        generalInfo.get('productGroupCode').disable();
        generalInfo.get('productTypeGroupId').disable();
        this.disableDraftFlag(generalInfo, draftFlag);
        this.disableUsageType(generalInfo, draftFlag);
    }

    private disableDraftFlag(generalInfo: AbstractControl, draftFlag: boolean) {
        if (!draftFlag) {
            generalInfo.get('draftFlag').disable();
        }
        else {
            generalInfo.get('draftFlag').enable();
        }
    }

    private disableUsageType(generalInfo: AbstractControl, draftFlag: boolean) {
        if ((this.copy && (this.rootProductId == this.id)) || (!this.copy && draftFlag)) {
            generalInfo.get('usageTypeCode').enable();
        } else {
            generalInfo.get('usageTypeCode').disable();
        }
    }
    
    private fillModelToProductNumber(productNumbers: ProductNumberViewModel[]) {
        if (this.attributeAndRuleComponent?.productNumberComponent) {
            this.attributeAndRuleComponent.productNumberComponent.addViewToFormGroup(productNumbers);
        }
    }
    
    private fillModelToProductAttribute(productInventoryDimensions: ProductInventoryDimensionViewModel[],
        productAttributes: ProductAttributeViewModel[],
        isHighestProductInventoryLevel: boolean,
        draftFlag: boolean) {
        this.productAttributesInherit = productAttributes;
        this.attributeAndRuleComponent.productAttributeComponent.productAttributes = this.attributeMapperService.productAttributesToForm(productAttributes, false);
        this.attributeAndRuleComponent.productAttributeComponent.productAttributesInherit = this.attributeMapperService.productAttributesToForm(productAttributes, true);
        this.attributeAndRuleComponent.productAttributeComponent.productInventoryDimensionExist = productInventoryDimensions;
        this.attributeAndRuleComponent.productAttributeComponent.isHighestProductInventoryLevel = isHighestProductInventoryLevel;
        this.attributeAndRuleComponent.productAttributeComponent.draftFlag = draftFlag;
        this.attributeAndRuleComponent.productAttributeComponent.addViewToFormGroup();
        this.attributeAndRuleComponent.productAttributeComponent.addViewInheritToFormGroup();
    }
    
    private fillModelToProductRestriction(productRestrictionProducts: ProductRestrictionProductViewModel[],
        productRestrictionProductNumbers: ProductRestrictionProductNumberViewModel[],
        productRestrictionRoutes: ProductRestrictionRouteViewModel[],
        productRestrictionLocations: ProductRestrictionLocationViewModel[]) {

        let productRestrictionViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations, null, false);

        let productRestrictionInheritViews = this.restrictionMapperService.productRestrictionModelsToViews(productRestrictionProducts,
            productRestrictionProductNumbers,
            productRestrictionRoutes,
            productRestrictionLocations, null, true);

        this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.addRestrictionViewToFormGroup(productRestrictionViews);
        this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.addRestrictionInheritToFormGroup(productRestrictionInheritViews);
        this.attributeAndRuleComponent.restrictionRuleComponent.detectChanges();
    }

    private fillModelToProductValidity(productValidities: ProductValidityViewModel[]) {
        var productValiditiesProduct = this.validityMapperService.productValiditiesToForm(productValidities, false);
        var productValiditiesInherit = this.validityMapperService.productValiditiesToForm(productValidities, true);
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityToFormGroup(productValiditiesProduct);
        this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.addValidityInheritToFormGroup(productValiditiesInherit);
        this.attributeAndRuleComponent.validityRuleComponent.detectChanges();
    }

    private fillModelToPointOfSales(productRestrictionOrganisations: ProductRestrictionOrganisationViewModel[], productRestrictionLocations: ProductRestrictionLocationViewModel[]) {
        let pointOfSalesViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, false);
        let pointOfSalesInheritViews = this.pointOfSalesMapperService.pointOfSalesModelsToViews(productRestrictionLocations, productRestrictionOrganisations, true);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.addPointOfSalesViewToFormGroup(pointOfSalesViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.addPointOfSalesInheritToFormGroup(pointOfSalesInheritViews);
        this.attributeAndRuleComponent.pointOfSalesComponent.detectChanges();
    }

    private fillModelToProductInventoryAttributeType(productInventoryDimensions: ProductInventoryDimensionViewModel[],
        productAttributes: ProductAttributeViewModel[], draftFlag: boolean) {
        var productInventoryDimensionsFilter = productInventoryDimensions.filter(x => x.inheritInventoryAttribute == false);
        productInventoryDimensionsFilter.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));

        this.inventoryAttributeComponent.productAttributeValue = this.inventoryAttributeMapperService.productInventoryDimensionModelToAttributeOption(productInventoryDimensionsFilter);
        this.inventoryAttributeComponent.productAttributeInventoryValue = this.inventoryAttributeMapperService.productInventoryDimensionModelToAttributeValue(productInventoryDimensionsFilter);
        this.productInventoryDimensions = productInventoryDimensions;

        if (productInventoryDimensionsFilter.length > 0 && !draftFlag) {
            this.inventoryAttributeComponent.disabledData(true);
            this.showInvAttributeType = false;
        }
        else {
            this.inventoryAttributeComponent.productAttributeValue = this.inventoryAttributeMapperService.productInventoryAttributeModelToAttributeTypeOption(productAttributes);
            this.inventoryAttributeComponent.disabledData(false);
            this.showInvAttributeType = true;
        }
    }

    private fillModelToInventory(productInventoryHierarchy: ProductInventoryHierarchyViewModel[], inventoryAttributeLowerLevel: string) {
        this.productInventoryHierarchy = productInventoryHierarchy;
        this.inventoryAttributeLowerLevel = inventoryAttributeLowerLevel;
        this.inventoryComponent.fillModelToForm(productInventoryHierarchy, inventoryAttributeLowerLevel);
        // TODO Next
        //this.inventorySpecificComponent.fillModelToForm(this.productInventoryHierarchy, this.inventoryAttributeLowerLevel);
    }

    private fillModelToText(medias: MediaViewModel[]) {
        let mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textComponent.fillModelToForm(mediaTextViews);
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        let mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.mediaComponent.fillModelToForm(mediaViews);
    }

    public saveInventoryData(value) {
        this.inventoryData = value;
    }

    private getProductName(productName: string): string {
        if (this.copy) {
            return productName + " -copy";
        }
        return productName;
    }

    private getUsageTypeCode(usageypeCode: string): string {
        if (this.copy && this.rootCommandData) {
            return this.rootCommandData.usageTypeCode;
        } else if (this.copy) {
            return "DATA";
        }
        return usageypeCode;
    }

    public parentChange(value) {
        this.nodeSelected = true;
        this.getProduct(value);
        this.parentId = value;
    }

    public onGeneralFormStatusChange() {
        this.updateInputValidation();
    }

    public onPanelGeneralHeightChange(height) {
        this.heightPanelGeneral = height + 31.8;
    }

    get saveFlag() {
        return ((this.newProduct == true || this.newFromParent == true) && this.userSecurity?.newFlag == true) || ((this.newProduct == false && this.newFromParent == false) && this.userSecurity?.editFlag == true);
    }

    setDefaultActionBarHandler() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);        
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);
        this.saveBtn.enable(this.saveFlag);

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )

        this.actionbarService.updateState(this.defaultActionBarHandler);
    }
    
    subscribeToActionBar() {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                actionId => {
                    switch (actionId) {
                        case ACTION_STATUS.new:
                            break;
                        case ACTION_STATUS.refresh:
                            break;
                        case ACTION_STATUS.back:
                            this.backToSearch();
                            break;  
                        case ACTION_STATUS.cancel:
                            this.cancel();
                            break;
                        case ACTION_STATUS.copy:
                            break;
                        case ACTION_STATUS.save:
                            this.onSaveClick();
                            break;
                    }
                }
            );
    }

    onActiveIdChange(activeId: string) {
        this.selectedTab = activeId;
        switch(activeId) {
            case this.generalTabId:
                this.focusingService.focus(this.productGeneralComponent.focusingDirective);
                break;
            case this.attributesTabId:
                this.focusingService.focus(this.attributeAndRuleComponent.focusingDirective);
                break;
            case this.textTabId:
                this.focusingService.focus(this.textComponent.focusingDirective);
                break;
            case this.mediaTabId:
                this.focusingService.focus(this.mediaComponent.focusingDirective);
        }
        this.showTranslation = activeId == this.translationTabId;
    }

    cancel() {
        let params = {
            tab: null,
            module: null
        }
        this.navigationService.navigate('/main/product/home', null, null, params);
    }
    

    private onSaveClick() {
        this.saveAction = true;
        
        if (!this.validateAll()) {
            this.displayAlertBar();
            this.onAlertBarClicked();
            return;
        }
        this.productGeneralComponent.completeProcessing();
        this.alertBarService.hide();

        if (this.newProduct) {
            this.addProduct();
        } else {
            this.updateProduct();
        }
    }

    private updateInputValidation() {
        this.displayAlertBar();
    }

    private validateAll(): boolean {
        let generalValid = this.productGeneralComponent.validateForm();
        if (!generalValid) {
            this.merchandizingDetailFormGroup.controls.generalInfo.markAllAsTouched();
        }

        let productNumberValid = this.attributeAndRuleComponent.productNumberComponent.validateForm();
        let productAttributeValid = this.attributeAndRuleComponent.productAttributeComponent.validateForm();
        let restrictionValid = this.attributeAndRuleComponent.restrictionRuleComponent.validateForm();
        let validityValid = this.attributeAndRuleComponent.validityRuleComponent.validateForm();
        let pointOfSalesValid = this.attributeAndRuleComponent.pointOfSalesComponent.validateForm();
        
        return generalValid && productNumberValid && productAttributeValid && restrictionValid && validityValid && pointOfSalesValid;
    }

    private displayAlertBar() {
        if (!this.productGeneralComponent.validForm()) {
            this.alertBarService.show(this.generalPanelName, this.productGeneralComponent.getErrorMessageForm());
        } else  if (!this.attributeAndRuleComponent.productNumberComponent.validateForm()) {
            this.alertBarService.show(this.productNumberPanelName, this.attributeAndRuleComponent.productNumberComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.productAttributeComponent.validateForm()) {
            this.alertBarService.show(this.attributePanelName, this.attributeAndRuleComponent.productAttributeComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.restrictionRuleComponent.validateForm()) {
            this.alertBarService.show(this.restrictionPanelName, this.attributeAndRuleComponent.restrictionRuleComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.validityRuleComponent.validateForm()) {
            this.alertBarService.show(this.validityPanelName, this.attributeAndRuleComponent.validityRuleComponent.getErrorMessageForm());
        } else if (!this.attributeAndRuleComponent.pointOfSalesComponent.validateForm()) {
            this.alertBarService.show(this.pointOfSalesPanelName, this.attributeAndRuleComponent.pointOfSalesComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    public onAlertBarClicked() {
        if (!this.productGeneralComponent.validForm()) {
            this.onActiveIdChange(this.generalTabId);
        } else {
            this.onActiveIdChange(this.attributesTabId);
            if (!this.attributeAndRuleComponent.productNumberComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.productNumberComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.productAttributeComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.productAttributeComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.restrictionRuleComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.restrictionRuleComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.validityRuleComponent.validateForm()) {
                this.focusingService.focus(this.attributeAndRuleComponent.validityRuleComponent.focusingDirective)
            } else if (!this.attributeAndRuleComponent.pointOfSalesComponent.validateForm()) {
                 this.focusingService.focus(this.attributeAndRuleComponent.pointOfSalesComponent.focusingDirective)
            } else {
                this.alertBarService.hide();
            }
        }
    }

    private addProduct() {
        this.spinnerService.showSaving();
        let addCommand = this.fillFormGroupToAddModel();
        this.merchandizeService.addProduct(addCommand)
            .subscribe(
                (response) => {
                    this.getProduct(response.body);
                    this.setformToEditMode();
                    this.spinnerService.saveComplete();
                },
                error => {
                    this.spinnerService.hide();
                }
            )
    }

    private updateProduct() {
        let editCommand = this.fillFormGroupToEditModel();
        this.spinnerService.showSaving();
        this.activityDomainComponent.save();
        this.merchandizeService.editProduct(editCommand)
            .subscribe(
                (response) => {
                    this.nodeSelected = false;
                    this.spinnerService.saveComplete();
                    this.getProduct(this.id);
                },
                error => {
                    this.spinnerService.hide();
                    this.alertService.error(this.FAILED, this.ALERT_HEADER, { timeOut: 2000 });
                }
            )
    }

    private fillFormGroupToAddModel(): MerchandizeAddCommandModel {
        this.enableFieldEditMode();
        let formValue = this.merchandizingDetailFormGroup.controls.generalInfo.value;
        let productHashTags = this.hashTagMapperService.productHashTagModels(
            this.productHashtagValueViews);

        let productNumbers = this.numberMapperService.productNumberFormToModels(
            this.attributeAndRuleComponent.productNumberComponent.forms, this.providers$);

        let productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(
            this.attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);
        let productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
        let productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
        let productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
        let productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);

        let productValidities = this.validityMapperService.productValidityFormToModels(
            this.attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

        let productAttributes = this.attributeMapperService.productAttributeFormToModels(
            this.attributeAndRuleComponent.productAttributeComponent.forms);

        let productInventoryDimensions = this.inventoryAttributeMapperService.productInventoryDimensionToModels(
            this.inventoryAttributeComponent.productAttributeInventoryValue, 
            this.productInventoryDimensions, 
            MERCHANDIZE_REF_CODE.dimensionTypeCode);

        let medias = this.textMapperService.textToMediaModels(this.textComponent.mediaTexts, this.ENTITY_NAME, this.id);
        // TODO: Translation
        //medias = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, medias);
        medias = this.mediaMapperService.mediaToMediaModels(medias, this.mediaComponent.mediaFiles, this.ENTITY_NAME, this.id);

        let pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(
            this.attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
        let productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
        productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);

        let addCommand: MerchandizeAddCommandModel = {
            productCategoryCode: formValue.productCategoryCode,
            productGroupCode: formValue.productGroupCode,
            productTypeCode: formValue.productTypeGroupId,
            providerId: formValue.providerId,
            parentProductId: this.parentId,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            supplierId: null,
            displayCode: null,
            draftFlag: formValue.draftFlag,
            finalFlag: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            usageTypeCode: formValue.usageTypeCode,
            isOwnerFilter: null,
            filterUserAccountId: null,
            statusCode: formValue.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: productInventoryDimensions,
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: medias,
            languageTranslationSet: null
        };
        this.disableFieldEditMode();
        return addCommand;
    }

    private enableFieldEditMode() {
        if (this.newFromParent) {
            MERCHANDIZE_GENERAL_KEYS.forEach(key => {
                this.merchandizingDetailFormGroup.controls.generalInfo.get(key).enable();
            });
        }
    }

    private disableFieldEditMode() {
        if (this.newFromParent) {
            MERCHANDIZE_GENERAL_KEYS.forEach(key => {
                this.merchandizingDetailFormGroup.controls.generalInfo.get(key).disable();
            });
        }
    }

    private setformToEditMode() {
        this.newProduct = false;
        this.hiddenTree = this.newProduct;
        this.nodeSelected = false;
        
        this.changeDetectionRef.detectChanges();
    }

    public displayTextTranslation(value) {
        // TODO: Translation
        // if (this.translationTextComponent.mediaTranslationTexts) {
        //     var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryToTranslationTextViews(
        //         value, this.translationTextComponent.mediaTranslationTexts);
        //     var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextToTranslationTextChildViews(
        //         value, this.languageReferences$.value, this.translationTextComponent.mediaTranslationChildTexts);
        //     this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        // }
    }

    public deleteTextTranslation(value) {
        // TODO: Translation
        // if (this.translationTextComponent.mediaTranslationTexts) {
        //     var mediaTranslationTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationTextViews(
        //         value, this.translationTextComponent.mediaTranslationTexts);
        //     var mediaTranslationChildTexts = this.translationTextMapperService.mediaTextLibraryDeleteTranslationChildTextViews(
        //         value, this.translationTextComponent.mediaTranslationChildTexts);
        //     this.translationTextComponent.fillModelToForm(mediaTranslationTexts, mediaTranslationChildTexts);
        // }
    }

    public textImageUploadClick() {
        setTimeout(() => {
            this.mediaComponent.textImageUploadProcess();
        }, this.DELAY_TIME);
    }

    public textVideoUploadClick() {
        setTimeout(() => {
            this.mediaComponent.textVideoUploadProcess();
        }, this.DELAY_TIME);
    }

    public textImageSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.changeFocusPanelTextToMedia();
            this.mediaComponent.textImageSelectLibraryProcess();
            this.scrollFromTextToMediaLibrary(searchCommand);
        }, this.DELAY_TIME);
    }

    public textVideoSelectLibraryClick(searchCommand: MediaSearchCommandModel) {
        setTimeout(() => {
            this.mediaComponent.textVideoSelectLibraryProcess();
        }, this.DELAY_TIME);
    }

    private scrollFromTextToMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.mediaComponent.addDisplayMediaLibrary(searchCommand);
    }

    private changeFocusPanelTextToMedia() {
        this.textComponent.minimizeTextPanel();
        this.changeDetectionRef.detectChanges();
    }

    public cancelCallFromText() {
        this.mediaComponent.cancelCallFromText();
    }

    public displayMediaTranslation(value) {
        // TODO: Next
        // if (this.translationMediaComponent.mediaTranslationFiles) {
        //     var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryToTranslationFileViews(
        //         value, this.translationMediaComponent.mediaTranslationFiles);
        //     var mediaTranslationChildFiles = this.translationMediaMapperService.mediaTextToTranslationTextChildViews(
        //         value, this.languageReference$.value, this.translationMediaComponent.mediaTranslationChildFiles);
        //     this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildFiles);
        // }
    }

    public deleteMediaTranslation(value) {
        // TODO: Next
        // if (this.translationMediaComponent.mediaTranslationFiles) {
        //     var mediaTranslationFiles = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationFileViews(
        //         value, this.translationMediaComponent.mediaTranslationFiles);
        //     var mediaTranslationChildTexts = this.translationMediaMapperService.mediaFileLibraryDeleteTranslationChildFileViews(
        //         value, this.translationMediaComponent.mediaTranslationChildFiles);
        //     this.translationMediaComponent.fillModelToForm(mediaTranslationFiles, mediaTranslationChildTexts);
        // }
    }

    public cancelTextUpload() {
        setTimeout(() => {
            this.textComponent.mediaUploadCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextImageUpload(value) {
        setTimeout(() => {
            this.textComponent.mediaImageUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextVideoUpload(value) {
        setTimeout(() => {
            this.textComponent.mediaVideoUploadCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public cancelTextSelectLibrary() {
        setTimeout(() => {
            this.textComponent.mediaSelectLibraryCancel();
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextSelectImageLibrary(value) {
        setTimeout(() => {
            this.textComponent.mediaImageSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public saveTextSelectVideoLibrary(value) {
        setTimeout(() => {
            this.textComponent.mediaVideoSelectLibraryCompleted(value);
            this.changeDetectionRef.detectChanges();
        }, this.DELAY_TIME);
    }

    public addAction() {
        if (this.id) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.id, this.productName$.value);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            MERCHANDIZE_REF_CODE.productCategoryCode,
            null);
    }

    private backToSearch() {
        this.navigationService.navigate('/main/product/home', null, null, this.navigationRequestParams);
    }
}
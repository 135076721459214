import { createReducer, on } from '@ngrx/store';
import { TabSessionModel } from 'src/app/core/models/tab/tab-session.model';
import * as TabSessionActions from './tab-session.actions';

export const tabSessionFeatureKey = "tabSession";

export interface TabSessionState {
    tabSessions: TabSessionModel[];
}

export const initTabSessionState: TabSessionState = {
    tabSessions: []
}

export const tabSessionReducer = createReducer(
    initTabSessionState,
    on(TabSessionActions.addTabSession, (state, { tabSession }) => {
        const tabSessions = addTabSession(state.tabSessions, tabSession);
        return {
            ...state, tabSessions
        }
    }),
    on(TabSessionActions.changeNavigateUrl, (state, { tabSession }) => {
        const tabSessions = changeNavigateUrl(state.tabSessions, tabSession);
        return {
            ...state, tabSessions
        }
    }),
    on(TabSessionActions.deleteTabSession, (state, {tabSession}) => {
        const tabSessions = deleteTabSession(state.tabSessions, tabSession);
        return {
            ...state, tabSessions
        }
    }),
    on(TabSessionActions.deleteAllTabSession, () => ({ ...initTabSessionState })),
    on(TabSessionActions.loadTabSesssion, (state) => {
        return {
            ...state
        }
    })
);

function addTabSession(tabSessions: TabSessionModel[], addTabSession: TabSessionModel): TabSessionModel[] {
    if (tabSessions.some(session => session.id === addTabSession.id)) {
        return tabSessions;
    }
    return [...tabSessions, addTabSession];
}

function changeNavigateUrl(tabSessions: TabSessionModel[], changeTab: TabSessionModel): TabSessionModel[] {
    tabSessions.forEach((session: TabSessionModel) => {
        session.navigateUrl = changeTab.navigateUrl;
    });
    return tabSessions;
}
function deleteTabSession(tabSessions: TabSessionModel[], tabSession: TabSessionModel): TabSessionModel[] {
    return tabSessions.filter(t => t.id !== tabSession.id);
}
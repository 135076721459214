import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  RouteViewModel,
  TransportAddCommandModel,
  TransportEditCommandModel,
  TransportOperatingProductServiceCategoryViewModel,
  TransportViewModel
} from '../../models/product-model/transport-model';

import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { TransportSearchViewModel } from '../../models/product-model/transport-model/transport-search-view.model';
import { TransportSearchCommandModel } from '../../models/product-model/transport-model/transport-search-command.model';
import { TransportOperatingProductViewModel } from '../../models/product-model/transport-model/transport-operating-product-view.model';


@Injectable({
  providedIn: 'root'
})
export class TransportService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/product/transports"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public addProduct(model: TransportAddCommandModel) {
    return this.httpPost(this.PRODUCT_PATH , model);
  }

  public editProduct(model: TransportEditCommandModel) {
    return this.httpPut(this.PRODUCT_PATH , model);
  }

  public getByProductId(productId) {
    return this.httpGet<any>(this.PRODUCT_PATH + "/" + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public searchProduct(productName: string, productDescription: string, productGroupCode: string, productTypeCode: string): Observable<TransportViewModel[]> {
    let params = new HttpParams()
      .set("productName", productName)
      .set("productDescription", productDescription)
      .set("productGroupCode", productGroupCode)
      .set("productTypeCode", productTypeCode);

    return this.httpGet<TransportViewModel[]>(this.PRODUCT_PATH , params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public search(searchCommand: TransportSearchCommandModel): Observable<TransportSearchViewModel[]> {
    return this.httpPost<TransportSearchViewModel[]>(this.PRODUCT_PATH + '/search', searchCommand)
      .pipe(
        map(res => res.body)
      )
  }

  public delete(productId: string) {
    return this.httpDelete(this.PRODUCT_PATH + "/" + productId, null);
  }

  public copy(rootProductId: string, model: TransportEditCommandModel) {
    return this.httpPost(this.PRODUCT_PATH + "/copy/" + rootProductId, model);
  }  

  public changeStatus(productId: string, statusCode: string) {
    const body = {statusCode: statusCode}
    return this.httpPut(this.PRODUCT_PATH + "/" + productId + "/status/" , body);
  }  

  public getRoute(productId: string): Observable<RouteViewModel[]> {
    return this.httpGet<RouteViewModel[]>(this.PRODUCT_PATH + '/route/' + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getOperatingProduct(productId: string): Observable<TransportOperatingProductViewModel[]> {
    return this.httpGet<TransportOperatingProductViewModel[]>(this.PRODUCT_PATH + '/operating-products/' + productId, null)
      .pipe(
        map(res => res.body)
      )
  }
  
  public getOperatingProductServiceCategory(productId: string): Observable<TransportOperatingProductServiceCategoryViewModel[]> {
    return this.httpGet<TransportOperatingProductServiceCategoryViewModel[]>(this.PRODUCT_PATH + '/operating-products/' + productId + '/service-categories', null)
      .pipe(
        map(res => res.body)
      )
  }    

  public updateFinalFlagAndGenerateOperatingProduct(productId: string) {
    return this.httpPut(this.PRODUCT_PATH + "/" + productId + "/finalflag/" , null);
  }  
}
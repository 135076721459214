import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { StatusReferenceModel, VehicleGroupReferenceModel, VehicleTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { StatusReferenceService, VehicleGroupReferenceService, VehicleTypeReferenceService } from "src/app/core/services/system-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PAGE_TITLE } from "../../../settings-management-content/shared/title-constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { VehicleCompositionGeneralDetailComponent } from "./general/general.component";
import { VehicleCompositionCommandModel, VehicleCompositionModel } from "src/app/core/models/vehicle-model/vehicle-composition";
import { VehicleCompositionService, VehicleConfigurationService } from "src/app/core/services/vehicle-services";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { VehicleConfigurationModel, VehicleConfigurationSearchCommandModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { VehicleCompositionConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-composition/vehicle-composition-configuration";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { SecurityGroupService } from "src/app/core/services/system-services/security-group.service";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-composition-detail',
    templateUrl: './vehicle-composition-detail.component.html',
    providers: [ActionbarService]
})
export class VehicleCompositionDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly generalTabId = 'general';
    readonly configurationTabId = 'configuration';
    readonly generalPanel = 'General Information';
    readonly configurationPanel = 'Configuration';
    readonly VEHICLECOMPOSITION = 'VEHICLECOMPOSITION';
    private readonly previousTitle: string = PAGE_TITLE.vehicle;
    private unsubscribe$ = new Subject();

    public id: string;
    public copy: boolean = false;
    public disabledSave: boolean = false;
    public saveProcessing: boolean = false;
    public selectedTab = this.generalTabId;
    public vehicleGroupReferences$ = new BehaviorSubject<VehicleGroupReferenceModel[]>(null);
    public vehicleTypeReferences$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public vehicleConfigurations$ = new BehaviorSubject<VehicleConfigurationModel[]>(null);
    public vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel>(null);
    public vehicleCompositionConfigurations$ = new BehaviorSubject<VehicleCompositionConfigurationModel[]>(null);
    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );
    public paggingDataView: PagingDataView[];
    public currentPageIndex: number;
    public displayName: string;
    userSecurity: SecurityGroupSecurityModel
    @ViewChild(VehicleCompositionGeneralDetailComponent) generalDetailComponent: VehicleCompositionGeneralDetailComponent;
    @ViewChild(ConfigurationComponent) configurationComponent: ConfigurationComponent;

    constructor(private statusReferenceService: StatusReferenceService,
        private vehicleGroupReferenceService: VehicleGroupReferenceService,
        private vehicleTypeReferenceService: VehicleTypeReferenceService,
        private vehicleCompositionService: VehicleCompositionService,
        private vehicleConfigurationService: VehicleConfigurationService,
        private securityGroupService: SecurityGroupService,
        private navigateService: NavigationService,
        private spinnerService: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private actionbarService: ActionbarService,
        private focusingService: FocusingService,
        private changeDetectorRef: ChangeDetectorRef,
        private actionService: ActionService) { }

    ngOnInit(): void {
        this.getVehicleGroupReferences();
        this.getVehicleTypeReferences();
        this.getStatusReferences();
        this.getVehicleConfigurations();
        this.getParams();
        this.getVehicleCompositionData();
        this.addAction();
    }

    ngAfterViewInit(): void {
        this.securityGroupService.userSecurityGroupSecurity
            .subscribe(userSecurity => {
                this.userSecurity = userSecurity.find(s => s.securityCode == this.VEHICLECOMPOSITION)
                this.updateActionBar();
                this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
                    actionId => {
                        this.actionbarClick(actionId);
                    }
                );
            })
        this.subscribeToPanelsFocus();
        this.generalDetailComponent.focusing = true;
    }

    private updateActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable(this.userSecurity.newFlag);
        this.actionBarHandler.get(ACTION_STATUS.copy).enable(this.userSecurity.copyFlag);
        if (!this.id) {
            this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurity.newFlag);
        }
        else {
            this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurity.editFlag);
        }
        this.actionbarService.updateState(this.actionBarHandler);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private getVehicleCompositionData() {
        if (this.id) {
            this.getVehicleComposition();
        }
    }

    getVehicleComposition() {
        this.vehicleCompositionService.get(this.id)
            .subscribe(
                (response: VehicleCompositionModel) => {
                    if (this.copy) {
                        this.fillCopyData(response);
                    } else {
                        this.fillCurrentData(response);
                    }
                }
            )
    }

    getVehicleGroupReferences() {
        this.vehicleGroupReferenceService.getVehicleGroupReference()
            .subscribe(
                (response) => {
                    this.vehicleGroupReferences$.next(response);
                }
            )
    }

    getVehicleTypeReferences() {
        this.vehicleTypeReferenceService.getVehicleTypeReference("")
            .subscribe(
                (response) => {
                    this.vehicleTypeReferences$.next(response);
                }
            )
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    getVehicleConfigurations() {
        this.vehicleConfigurationService.search(<VehicleConfigurationSearchCommandModel>{ statusCode: 'A' })
            .subscribe(
                (response) => {
                    this.vehicleConfigurations$.next(response);
                }
            )
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.new:
                this.new();
                this.updateActionBar();
                break;
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.cancel:
                this.backToSettings();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
            case ACTION_STATUS.copy:
                this.copyData();
                break;
            default:
                break;
        }
    }

    save() {
        this.saveProcessing = true;
        if (this.validateAll()) {
            this.alertBarService.hide();
            if (!this.id) {
                this.add(this.createCommand());
            }
            else {
                this.edit(this.createCommand());
            }
        }
        else {
            this.displayAlertBar();
        }
    }

    private validateAll(): boolean {
        if (this.generalDetailComponent.validateForm() &&
            this.configurationComponent?.configurationViews?.length) {
            return true;
        }
        return false;
    }

    private displayAlertBar() {
        if (this.displayAlertGeneralPanel()) {
            this.alertBarService.show(this.generalPanel, this.generalDetailComponent.getErrorMessageForm());
        } else if (this.displayAlertConfigurationPanel()) {
            this.alertBarService.show(this.configurationPanel, this.configurationComponent.ERROR_REQUIRED);
        } else {
            this.alertBarService.hide();
        }
    }

    private displayAlertGeneralPanel(): boolean {
        if (this.generalDetailComponent?.processing) {
            if (!this.generalDetailComponent.validateForm()) {
                return true;
            }
        }
        return false;
    }

    private displayAlertConfigurationPanel(): boolean {
        if (this.generalDetailComponent?.processing) {
            if (!this.configurationComponent?.configurationViews?.length) {
                return true;
            }
        }
        return false;
    }

    add(command: VehicleCompositionCommandModel) {
        if (command) {
            this.spinnerService.showSaving();
            this.vehicleCompositionService.add(command)
                .subscribe(
                    (response) => {
                        this.copy = false;
                        this.saveProcessing = false;
                        this.fillCurrentData(response);
                        this.spinnerService.saveComplete();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    edit(command: VehicleCompositionCommandModel) {
        if (command) {
            this.spinnerService.showSaving();
            this.vehicleCompositionService.edit(command)
                .subscribe(
                    (response) => {
                        this.saveProcessing = false;
                        this.fillCurrentData(response);
                        this.spinnerService.saveComplete();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private createCommand(): VehicleCompositionCommandModel {
        let command = this.generalDetailComponent.getValue();
        command.vehicleCompositionConfigurations = this.configurationComponent.getValue();
        return command;
    }

    private back() {
        let params = {
            selectedTab: 'SEARCH',
            module: 'Vehicle Composition'
        }
        this.navigateService.navigate("main/settings/vehicle", this.previousTitle, null, params);
    }

    private new() {
        this.id = null;
        this.clearCurrentData();
        this.alertBarService.hide();
        this.copy = false;
        this.paggingDataView = [];
        this.changeDetectorRef.detectChanges();
    }

    private refresh() {
        this.clearCurrentData();
        this.alertBarService.hide();
        this.getVehicleCompositionData();
    }

    private copyData() {
        this.clearCurrentData();
        this.alertBarService.hide();
        this.copy = true;
        this.paggingDataView = [];
        this.changeDetectorRef.detectChanges();
        this.getVehicleCompositionData();
    }

    onformStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.generalDetailComponent.validateForm()) {
                this.alertBarService.show(this.generalPanel, this.generalDetailComponent.getErrorMessageForm());
            }
        }
    }

    onformStatusCompositionConfigurationChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.configurationComponent.validateForm()) {
                this.alertBarService.show(this.configurationPanel, this.configurationComponent.getErrorMessageForm());
            }
        }
    }

    onActiveIdChange(activeId: string) {
        if (activeId == this.generalTabId) {
            this.focusingService.focus(this.generalDetailComponent.focusingDirective);
        }
        if (activeId == this.configurationTabId) {
            this.focusConfiguration();
        }
    }

    private focusConfiguration() {
        if (this.configurationComponent.isMultipleRecord()) {
            this.focusingService.focus(this.configurationComponent.configurationListComponent.focusingDirective);
        }
        else {
            this.focusingService.focus(this.configurationComponent.configurationDetailComponent.focusingDirective);
        }
    }

    private subscribeToPanelsFocus() {
        this.generalDetailComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.generalTabId;
                    }
                }
            );

        this.configurationComponent?.configurationDetailComponent?.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.configurationTabId;
                    }
                }
            );

        this.configurationComponent?.configurationListComponent?.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.configurationTabId;
                    }
                }
            );
    }

    onAlertBarClick() {
        if (!this.generalDetailComponent.validateForm()) {
            this.focusingService.focus(this.generalDetailComponent.focusingDirective);
        }
    }

    private fillCurrentData(response: VehicleCompositionModel) {
        this.id = response.vehicleCompositionId;
        this.vehicleComposition$.next(response);
        this.vehicleCompositionConfigurations$.next(response.vehicleCompositionConfigurations);
        this.displayName = this.displayCompositionName(response.compositionName);
        this.addAction();
    }

    private fillCopyData(response: VehicleCompositionModel) {
        this.id = null;
        this.vehicleComposition$.next(this.setCopyVehicleConfiguration(response));
        this.vehicleCompositionConfigurations$.next(this.setCopyVehicleCompositionConfiguration(response.vehicleCompositionConfigurations));
        this.addAction();
    }

    private clearCurrentData() {
        this.vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel>(null);
        this.vehicleCompositionConfigurations$ = new BehaviorSubject<VehicleCompositionConfigurationModel[]>(null);
        this.selectedTab = this.generalTabId;
        this.saveProcessing = false;
        this.displayName = "";
    }

    private setCopyVehicleConfiguration(response: VehicleCompositionModel): VehicleCompositionModel {
        response.compositionCode = "";
        response.compositionName = "";
        response.commitByName = "";
        response.commitDateTime = null;
        return response;
    }

    private setCopyVehicleCompositionConfiguration(vehicleCompositionConfigurations: VehicleCompositionConfigurationModel[]): VehicleCompositionConfigurationModel[] {
        for (let config of vehicleCompositionConfigurations) {
            config.vehicleCompositionConfigurationId = undefined;
        }
        return vehicleCompositionConfigurations;
    }

    private addAction() {
        if (this.id) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.id, this.vehicleComposition$.value?.compositionName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private getParams() {
        let params = this.navigateService.getParams();
        this.id = params?.id ?? null;
        this.copy = params?.copy ?? false;
        this.paggingDataView = params?.paggingDataView ?? [];
        this.currentPageIndex = params?.currentPageIndex ?? null;
    }

    public onPageChange(id: string): void {
        if (this.id != id) {
            this.id = id;
            this.getVehicleComposition();
        }
    }

    private backToSettings() {
        let params = {
            home: true
        }
        this.navigateService.navigate("main/settings/vehicle", this.previousTitle, null, params);
    }

    private displayCompositionName(name: string) {
        return " :: " + name;
    }
}

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UnitReferenceModel, IndividualAgeGroupReferenceModel, IndividualSocialTypeReferenceModel, ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { TreeListView } from 'src/app/modules/pricing/prices/price-detail/amount/shared/views';
import { CombinedPriceModel } from 'src/app/modules/pricing/prices/shared/models/combined-price.model';
import { PriceView } from 'src/app/modules/pricing/prices/shared/views';
import { Node } from 'devextreme/ui/tree_list';
import { DxTreeListComponent } from 'devextreme-angular';
import { PricingDetailConstant } from 'src/app/modules/pricing/rules/price-rule-detail/shared/pricing-detail.constant';
import { PriceConverter } from 'src/app/modules/pricing/prices/shared/price.converter';
import { PriceService } from 'src/app/modules/pricing/prices/shared/price.service';
import { AmountConverter } from 'src/app/modules/pricing/prices/price-detail/amount/shared/amount.converter';

@Component({
    selector: 'op-product-condition-detail',
    templateUrl: './product-condition-detail.component.html',
    providers: [
        AmountConverter
    ]
})
export class ProductConditionDetailComponent implements OnInit {
    private readonly PRICE_NAME = 'priceName';
    private readonly NUMBER_OF_UNIT = 'numberOfUnit';
    private readonly CONDITION_CODE = 'conditionCode';
    private readonly UNIT_TYPE = 'unitType';
    private readonly INDIVIDUAL_AGE_GROUP = 'individualAgeGroup';
    private readonly SOCIAL_TYPE = 'socialType';
    private readonly UNIT_REFERENCE = 'unitReference';
    private readonly CURRENCY = 'currency';
    private readonly ARITHMETIC_OPERATOR = 'arithmeticOperator';
    private readonly AMOUNT = 'amount';
    private readonly PERCENTAGE = 'percentage';
    private readonly MIN = 'min';
    private readonly MAX = 'max';
    private readonly COST = 'cost';
    
    public treeListData: TreeListView[];

    priceView: PriceView;
    combinedPrice: CombinedPriceModel = {
        combinedPriceId: null,
        isCombinedPrice: false,
        currencyPriceIds: new Array<string>(),
    };
    
    @Input() priceId: string;
    @Input() unitReferences: UnitReferenceModel[];
    @Input() individualAgeGroupTypeReferences: IndividualAgeGroupReferenceModel[];
    @Input() individualSocialTypeReferences: IndividualSocialTypeReferenceModel[];
    @Input() conditionReferences: ConditionReferenceModel[];

    @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;

    constructor(private priceService: PriceService,
        private priceConverter: PriceConverter,
        private amountConverter: AmountConverter,
        private changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.getPriceById(this.priceId);
    }

    public getPriceById(priceId: string) {
        this.priceService.getById(priceId)
            .subscribe(
                price => {
                    this.combinedPrice.isCombinedPrice = price.combinationFlag;
                    this.priceView = this.priceConverter.toView(1, price);
                    this.loadData();
                    this.changeDetector.detectChanges();
                }
            )
    }

    loadData() {
        if (this.priceView) {
            this.treeListData = this.amountConverter.toTreeListView(this.priceView, null, null);
            this.getCombinedPrice();
        }
    }

    public getConjunctionWord(cell: any): string {
        if (!this.combinedPrice) {
            return null;
        }
        const node: Node = this.treeList.instance.getNodeByKey(cell.key);
        if (!node) {
            return null;
        }
        if (this.isCurrencyNode(node) == false || node.parent.children.length == 1) {
            return null;
        }

        if (this.isUnderCombinedPrice(node) && this.isFirstAmongChildren(node)) {
            return 'And';
        }
        return 'Or';
    }

    private isCurrencyNode(node: Node): boolean {
        return this.combinedPrice.currencyPriceIds.includes(node.key);
    }

    private isUnderCombinedPrice(node: Node): boolean {
        let parent = node.parent;
        while (parent) {
            if (parent.key == this.combinedPrice.combinedPriceId) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    private isFirstAmongChildren(node: Node): boolean {
        return node.parent.children.indexOf(node) == 0;
    }

    public showOrdinal(cell): boolean {
        return cell.data.condition == PricingDetailConstant.TIER_CODE && cell.data.numberOfUnits > 0;
    }

    public onContentReady(e) {
        if (!this.treeListData?.length) {
            return;
        }
        this.expandAllAttributes(this.treeListData);
    }

    private expandAllAttributes(treeListData: TreeListView[]) {
        for (let data of treeListData) {
            const child = this.treeListData.find(d => d.parentId == data.Id)
            if (child?.referencePriceConditionId || child?.referencePriceIndividualId) {
                continue;
            }
            this.treeList.instance.expandRow(data.Id);
        }
    }

    public customizePercentageField(e) {
        if (e?.value == null) {
            return "";
        }
        return e.valueText + '%';
    }

    editorPreparing(options) {
        let disableCell = !options.row?.data?.priceIndividualId;
        if (this.isDisableCell(options.dataField) || disableCell) {
            this.disableCell(options);
        }
    }

    isDisableCell(dataField: string): boolean {
        return [
            this.PRICE_NAME,
            this.NUMBER_OF_UNIT,
            this.CONDITION_CODE,
            this.UNIT_TYPE,
            this.INDIVIDUAL_AGE_GROUP,
            this.SOCIAL_TYPE,
            this.UNIT_REFERENCE,
            this.ARITHMETIC_OPERATOR,
            this.AMOUNT,
            this.PERCENTAGE,
            this.MIN,
            this.MAX,
            this.COST,
            this.CURRENCY,
        ].includes(dataField);
    }

    private disableCell(options: any) {
        options.editorOptions.disabled = true;
        options.editorOptions.readOnly = true;
        if (options.dataField == this.UNIT_TYPE && options.value) {
            options.editorElement.parent().text(this.unitReferences.find(u => u.unitCode == options.value).unitName);
        } else if (
            (options.dataField == this.INDIVIDUAL_AGE_GROUP || options.dataField == this.UNIT_REFERENCE) &&
            options.value
        ) {
            options.editorElement
                .parent()
                .text(
                    this.individualAgeGroupTypeReferences.find(u => u.individualAgeGroupCode == options.value)
                        .individualAgeGroupName
                );
        } else if (options.dataField == this.SOCIAL_TYPE && options.value) {
            options.editorElement
                .parent()
                .text(
                    this.individualSocialTypeReferences.find(u => u.individualSocialTypeCode == options.value)
                        .individualSocialTypeName
                );
        } else if (options.dataField == this.CONDITION_CODE && options.value) {
            options.editorElement
                .parent()
                .text(this.conditionReferences.find(u => u.conditionCode == options.value).conditionName);
        } else {
            options.editorElement.parent().text(options.value);
        }
        options.editorElement.hide();
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        let disableCell = !e.data?.priceIndividualId;
        if (disableCell) {
            e.cellElement.addClass('dx-cell-disabled');
        }
    }

    private getCombinedPrice() {
        if (!this.treeListData?.length) {
            return;
        }
        for (let data of this.treeListData) {
            this.getCombinedPriceId(data);
            this.getCurrencyPriceIds(data);
        }
    }

    private getCombinedPriceId(data: TreeListView) {
        if (
            !!this.combinedPrice.combinedPriceId ||
            this.combinedPrice.isCombinedPrice == false ||
            !data.parentId ||
            data.priceName != 'Combined Price'
        ) {
            return;
        }
        this.combinedPrice.combinedPriceId = data.Id;
    }

    private getCurrencyPriceIds(data: TreeListView) {
        if (!data.parentId) {
            return;
        }

        if (data.priceName?.startsWith('Currency -') && this.combinedPrice.currencyPriceIds.includes(data.Id) == false) {
            this.combinedPrice.currencyPriceIds.push(data.Id);
        }
    }

}

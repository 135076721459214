import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PrintCashbookExportService {
    public row = 0;
    public table = document.createElement("table");
    public tr = this.table.insertRow(-1);
    public print(exportContent: any, printFontSize: string = "") {
        this.table = document.createElement("table");
        this.row = 0;
        this.tr = this.table.insertRow(-1);
        const printFormSize: string = "left=50000,top=50000,width=0,height=0";
        if (exportContent && exportContent.length > 0) {
            var divElements = this.GenerateHTML(exportContent, printFontSize);
            var num;
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();
            var printWindow = window.open(num, windowName, printFormSize);
            printWindow.document.write(divElements);
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        }
    }

    public printHTML(exportContent: any, printFontSize: string = "") {
        this.table = document.createElement("table");
        this.row = 0;
        this.tr = this.table.insertRow(-1);
        var divElements = "";
        if (exportContent && exportContent.length > 0) {
            divElements = this.GenerateHTML(exportContent, printFontSize);
        }
        return divElements;
    }
    public printHTMLDetail(exportContent: any) {
        this.table = document.createElement("table");
        this.row = 0;
        this.tr = this.table.insertRow(-1);
        let bodyTitle = this.getBodyTitle();
        let bodyTitle2 = this.getBodyTitle2();
        var divElements = "";
        if (exportContent) {
            divElements = this.GenerateHTMLCashbookDeail(exportContent, bodyTitle, bodyTitle2);
        }
        return divElements;
    }


    private GenerateHeaderHTML(dataHeader, tr, table, allColum, lastCol = 0, lastRow) {
        for (let i = 0; i < dataHeader.length; i++) {
            this.row++;
            let col = 1;
            this.tr = this.table.insertRow(-1);
            dataHeader[i].forEach(e => {
                let th = document.createElement("th");
                let hasLastCol = (lastCol > 0) ? lastCol : i + 1;
                let align = (e.align) ? e.align : "left";
                th.style.cssText = this.getStyleText(this.row, true, col, e.colSpan, allColum, align, hasLastCol, lastRow);
                th.colSpan = e.colSpan;
                th.innerHTML = e.caption;
                col += (e.colSpan) ? e.colSpan : 1;
                this.tr.appendChild(th);
            });
        }
    }

    private GenerateBodyHTML(allColum, bodyContent = [], align, lastCol) {
        bodyContent.forEach((e, i) => {
            this.tr = this.table.insertRow(-1);
            this.row++;
            let col = 1;
            let th = document.createElement("th");
            let lastRow = (i + 1) == bodyContent.length;
            let hasAlign = (align) ? align : (e.align) ? e.align : "left";
            let hasLastCol = (lastCol > 0) ? lastCol : i + 1;
            th.style.cssText = this.getStyleText(this.row, false, col, e.colSpan, allColum, hasAlign, hasLastCol, lastRow);
            th.colSpan = e.colSpan;
            th.innerHTML = e.caption;
            col += (e.colSpan) ? e.colSpan : 1;
            this.tr.appendChild(th);
        });
    }

    private GenerateSubHeaderHTML(tableHeader, allColum, align, tab, lastRow = true, header = true) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        let col = 1;
        tableHeader.forEach((e, i) => {
            let th = document.createElement("th");
            let lastCol = (tableHeader.length == (i + 1));
            th.style.cssText = this.getStyleText(this.row, header, col, e.colSpan, allColum, 'left', tab, lastRow, lastCol);
            th.colSpan = e.colSpan;
            th.innerHTML = e.caption;
            col += (e.colSpan) ? e.colSpan : 1;
            this.tr.appendChild(th);
        });
    }

    private GenerateSubBodyHTML(tableHeader, allColum, bodyContent = [], align, tab) {
        bodyContent.forEach((element, i) => {
            this.tr = this.table.insertRow(-1);
            this.row++;
            let col = 1;
            let lastRow = (i + 1) == bodyContent.length;
            tableHeader.forEach(e => {
                let th = document.createElement("th");
                let hasAlign = (align) ? align : e.align
                th.style.cssText = this.getStyleText(this.row, false, col, e.colSpan, allColum, hasAlign, tab, lastRow);
                th.colSpan = e.colSpan;
                th.innerHTML = element[e.dataField];
                col += (e.colSpan) ? e.colSpan : 1;
                this.tr.appendChild(th);
            });
        });
    }

    private GenerateThTable(col, allColum, tab, colSpan, caption, align, row = 0, header = true) {
        let th = document.createElement("th");
        let hasRow = (row > 0) ? row : this.row;
        th.style.cssText = this.getStyleText(hasRow, header, col, colSpan, allColum, align, tab, true);
        th.colSpan = colSpan;
        th.innerHTML = caption;
        this.tr.appendChild(th);
    }

    private GeneratePanelGeneral(jsonData, allColum) {
        let dataHeader = jsonData[0][0].header;
        let bodyContent = jsonData[0][0].items;
        this.GenerateHeaderHTML(dataHeader, this.tr, this.table, allColum, 0, true)
        this.GenerateBodyHTML(allColum, bodyContent, "left", 2);
    }

    private GeneratePanelTotalByFromOfPayment(jsonData, allColum) {
        let dataHeader = jsonData[1][0].header;
        let tableHeader = jsonData[1][0].tableHeader;
        let bodyContent = jsonData[1][0].items;
        this.GenerateHeaderHTML(dataHeader, this.tr, this.table, allColum, 0, true);
        this.GenerateSubHeaderHTML(tableHeader, allColum, "left", 2);
        this.GenerateSubBodyHTML(tableHeader, allColum, bodyContent, "", 2);
    }

    private GeneratePanelTotalByCurrency(jsonData, allColum) {
        let dataHeader = jsonData[2][0].header;
        let tableHeader = jsonData[2][0].tableHeader;
        let bodyContent = jsonData[2][0].items;
        this.GenerateHeaderHTML(dataHeader, this.tr, this.table, allColum, 0, true);
        this.GenerateSubHeaderHTML(tableHeader, allColum, "left", 2);
        this.GenerateSubBodyHTML(tableHeader, allColum, bodyContent, "", 2);
    }

    private GeneratePanelPaymentCurrencies(jsonData, allColum) {
        let dataHeader = jsonData[3][0].header;
        this.GenerateHeaderHTML(dataHeader, this.tr, this.table, allColum, 0, true);
        let bodyContent = jsonData[3][0].items;
        let tableHeader = jsonData[3][0].tableHeader;
        for (let index = 0; index < bodyContent.length; index++) {
            this.tr = this.table.insertRow(-1);
            this.row++;
            let tab = 2;
            let tableTitle = [
                { caption: bodyContent[index]['currencyName'] + ' (' + bodyContent[index]['currencyCode'] + ')', colSpan: 5, align: 'left' },
                { caption: 'Total', colSpan: 1, align: 'left' },
                { caption: bodyContent[index]['total'], colSpan: 1, align: 'right' }
            ];
            let col = 1;
            for (let i = 0; i < tableTitle.length; i++) {
                this.GenerateThTable(col, allColum, tab, tableTitle[i].colSpan, tableTitle[i].caption, tableTitle[i].align);
                col += tableTitle[i].colSpan;
            }
            this.GenerateSubHeaderHTML(tableHeader, allColum, "left", 3);
            let bodyContentItem = bodyContent[index]['currencyItems'];
            this.GenerateSubBodyHTML(tableHeader, allColum, bodyContentItem, "", 3);
        }
    }

    private GenerateFromOfPaymentAndPaymentCurrency(jsonData, allColum) {
        let dataHeader = jsonData[4][0].header;
        let bodyContent = jsonData[4][0].items;
        let tableHeader = jsonData[4][0].tableHeader;
        let tableSubHeader = jsonData[4][0].tableSubHeader;
        this.GenerateHeaderHTML(dataHeader, this.tr, this.table, allColum, 0, true);
        for (let index = 0; index < bodyContent.length; index++) {
            this.tr = this.table.insertRow(-1);
            this.row++;
            this.GenerateThTable(1, allColum, 2, 7, bodyContent[index]['currencyName'] + ' (' + bodyContent[index]['ledgerCurrencyCode'] + ')', 'left');
            let bodyContentItem = bodyContent[index]['paymentTypeItem'];
            bodyContentItem.forEach((element) => {
                this.tr = this.table.insertRow(-1);
                this.row++;
                let tab = 3;
                let tableTitle = [
                    { caption: element.formOfPaymentTypeName + '(' + element.formOfPaymentTypeCode + ')', colSpan: 4, align: 'left' },
                    { caption: element.dataCount, colSpan: 1, align: 'right' },
                    { caption: 'Total', colSpan: 1, align: 'left' },
                    { caption: element.amount, colSpan: 1, align: 'right' }
                ];
                let col = 1;
                for (let i = 0; i < tableTitle.length; i++) {
                    this.GenerateThTable(col, allColum, tab, tableTitle[i].colSpan, tableTitle[i].caption, tableTitle[i].align);
                    col += tableTitle[i].colSpan;
                }
                this.GenerateBodyFromOfPaymentAndPaymentCurrency(tableHeader, tableSubHeader, allColum, element)
            });
        }
    }

    private GenerateBodyFromOfPaymentAndPaymentCurrency(tableHeader, tableSubHeader, allColum, element) {
        let subBodyContent = element.paymentItem;
        this.GenerateSubHeaderHTML(tableHeader, allColum, "left", 4, false);
        this.tr = this.table.insertRow(-1);
        this.row++;
        let col = 1;
        tableHeader.forEach(e => {
            this.GenerateThTable(col, allColum, 4, e.colSpan, element[e.dataField], e.align, 0, false);
            col += (e.colSpan) ? e.colSpan : 1;
        });
        this.GenerateSubHeaderHTML(tableSubHeader, allColum, "left", 4);
        this.GenerateSubBodyHTML(tableSubHeader, allColum, subBodyContent, "", 4);
    }

    private GenerateHTML(exportContent, printFontSize: string) {
        let allColum = 7;
        const borderSpacingSetting = "0px";
        const tableWidth = "100%";
        let jsonData: any = exportContent;
        this.table.style.borderSpacing = borderSpacingSetting;
        this.table.style.width = tableWidth;
        this.GeneratePanelGeneral(jsonData, allColum);
        this.GeneratePanelTotalByFromOfPayment(jsonData, allColum);
        this.GeneratePanelTotalByCurrency(jsonData, allColum);
        this.GeneratePanelPaymentCurrencies(jsonData, allColum);
        this.GenerateFromOfPaymentAndPaymentCurrency(jsonData, allColum);
        return this.table.outerHTML;
    }

    public getStyleText(row, header, colum, colSpan, allColum, align, tab, lastRow = false, lastCol = false) {
        let cssText = '';
        let fontSize = '10px';
        let pading = 16;
        let border = "";
        let paddingLeft = '4px';
        let fontweight = '500';

        if (colSpan == allColum) {
            border = "border-left: 1px solid; " +
                "border-right: 1px solid;";
        } else if (colum == 1) {
            border = "border-left: 1px solid;";
        } else if (colum == allColum) {
            border = "border-right: 1px solid; ";
        }

        if (row > 1 && lastRow == true) {
            border += "border-bottom: 1px solid;";
        }

        if (lastCol == true) {
            border += "border-right: 1px solid; ";
        }

        if (row == 1) {
            border = "border: 1px solid; ";
        }

        if (header == true) {
            fontSize = '12px';
            fontweight = '1000'
        }

        if (tab > 1 && colum == 1) {
            paddingLeft = ((tab - 1) * pading).toString();
        }

        cssText = border +
            "font-weight:" + fontweight + ";" +
            "font-size: " + fontSize + ";" +
            "text-align: " + align + ";" +
            "padding: 4px;" +
            "padding-left: " + paddingLeft + 'px;';
        return cssText;
    }

    private getBodyTitle() {
        let bodyTitle = [
            [
                { caption: 'Form of Payment Type', dataField: 'formOfPaymentTypeCode', colSpan: 1 },
                { caption: 'Form of Payment', dataField: 'formOfPaymentCode', colSpan: 1 },
                { caption: 'Document Type', dataField: 'accountableDocumentTypeName', colSpan: 1 },
                { caption: 'Document Number', dataField: 'documentNumber', colSpan: 1 }
            ], [
                { caption: 'Organisation', dataField: 'organisationId', colSpan: 1 },
                { caption: 'Payment Organisation', dataField: 'organisationId', colSpan: 1 },
                { caption: 'Individual Name', dataField: 'paymentIndividualId', colSpan: 2 }
            ], [
                { caption: 'Document Date', dataField: 'documentDate' },
                { caption: 'Issue Date Time', dataField: 'issueDateTime' },
                { caption: 'Payment Date', dataField: 'paymentDate' },
                { caption: 'Void Date Time', dataField: 'voidDateTime' }
            ], [
                { caption: 'External Reference', dataField: 'externalReference' },
                { caption: 'Transaction Reference', dataField: 'transactionReference' },
                { caption: 'Commit By', dataField: 'commitBy' },
                { caption: 'Transaction Date Time', dataField: 'transactionDateTime' }
            ], [
                { caption: 'Ledger Transaction Name', dataField: 'ledgerTransactionName', colSpan: 4 }
            ], [
                { caption: 'Ledger Currency Code', dataField: 'ledgerCurrencyCode' },
                { caption: 'Ledger', dataField: 'ledgerAmount' },
                { caption: 'Allocated Amount', dataField: 'sumLedgerAmount', colSpan: 2 }
            ], [
                { caption: 'Transaction Currency Code', dataField: 'transactionCurrencyCode' },
                { caption: 'Transaction Amount', dataField: 'transactionAmount' },
                { caption: 'Exchange Rate', dataField: 'exchangeRate', colSpan: 2 }
            ]
        ];
        return bodyTitle;
    }

    private getBodyTitle2() {
        let bodyTitle = [
            [
                { caption: 'Product Name', dataField: 'productName' },
                { caption: 'Individual', dataField: 'paymentIndividualId' },
                { caption: 'Order Status', dataField: 'orderSalesStatusCode', colSpan: 2 }
            ], [
                { caption: 'Document Type', dataField: 'accountableDocumentTypeCode' },
                { caption: 'Document Number', dataField: 'documentNumber', colSpan: 3 }
            ], [
                { caption: 'Ledger Transaction Name', dataField: 'ledgerTransactionName', colSpan: 4 }
            ], [
                { caption: 'Sales Currency', dataField: 'salesCurrencyCode' },
                { caption: 'Sales Amount', dataField: 'orderSalesAmount' },
                { caption: 'Sales Paid', dataField: 'salesAmount' },
                { caption: 'Outstanding', dataField: 'outStandingAmount' }
            ], [
                { caption: '', dataField: '' },
                { caption: 'Total', dataField: 'allocatedSalesAmount' },
                { caption: 'Total Paid', dataField: 'allocatedSalesAmount', colSpan: 2 }
            ]
        ];
        return bodyTitle;
    }

    public printCashbookDeail(exportContent: any) {
        this.table = document.createElement("table");
        this.row = 0;
        this.tr = this.table.insertRow(-1);
        const printFormSize: string = "left=50000,top=50000,width=0,height=0";
        let bodyTitle = this.getBodyTitle();
        let bodyTitle2 = this.getBodyTitle2();
        if (exportContent) {
            var divElements = this.GenerateHTMLCashbookDeail(exportContent, bodyTitle, bodyTitle2);
            var num;
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();
            var printWindow = window.open(num, windowName, printFormSize);
            printWindow.document.write(divElements);
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        }
    }

    private GenerateHTMLCashbookDeail(exportContent, bodyTitle, bodyTitle2) {
        let allColum = 4;
        const borderSpacingSetting = "0px";
        const tableWidth = "100%";
        let jsonData: any = exportContent;

        this.table.style.borderSpacing = borderSpacingSetting;
        this.table.style.width = tableWidth;

        this.GenerateHTMLCashbookDeailTable(jsonData, allColum, bodyTitle);
        this.GenerateHTMLCashbookChartItemTable(jsonData, allColum, bodyTitle2);

        return this.table.outerHTML;
    }

    private GenerateHTMLCashbookDeailTable(jsonData, allColum, bodyTitle) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        this.GenerateThTable(1, allColum, 1, 4, 'Cashbook Details', 'left', 1);

        bodyTitle.forEach((element, index) => {
            this.GenerateThTableCashBookDetail(element, allColum);
            this.GenerateThTableCashBookDetailBody(jsonData, element, allColum, bodyTitle, index);
        });
    }

    private GenerateThTableCashBookDetail(element, allColum) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        let col = 1;
        element.forEach((e, i) => {
            let lastCol = (element.length == (i + 1));
            let th = document.createElement("th");
            th.style.cssText = this.getStyleText(this.row, false, col, e.colSpan, allColum, 'left', 1, false, lastCol);
            th.colSpan = e.colSpan;
            th.innerHTML = e.caption;
            col += (e.colSpan) ? e.colSpan : 1;
            this.tr.appendChild(th);
        });
    }

    private GenerateThTableCashBookDetailBody(jsonData, element, allColum, bodyTitle, index) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        let col = 1;
        let lastRow = (bodyTitle.length == index + 1)
        element.forEach((e, i) => {
            let lastCol = (element.length == (i + 1));
            let th = document.createElement("th");
            th.style.cssText = this.getStyleText(this.row, true, col, e.colSpan, allColum, 'left', 1, lastRow, lastCol);
            th.colSpan = e.colSpan;
            th.style.paddingBottom = '10px';
            th.innerHTML = jsonData[e.dataField];
            col += (e.colSpan) ? e.colSpan : 1;
            this.tr.appendChild(th);
        });
    }

    private GenerateHTMLCashbookChartItemTable(jsonData, allColum, bodyTitle2) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        let th = document.createElement("th");
        th.colSpan = 4;
        th.innerHTML = "<p style='height:16px'></p>";
        th.style.cssText = this.getStyleText(1, true, 1, allColum, allColum, 'left', 1, true);
        th.style.color = '#ffffff';
        this.tr.appendChild(th);


        this.tr = this.table.insertRow(-1);
        this.row++;
        this.GenerateThTable(1, allColum, 1, 4, "Charge Item", 'left', 1);

        let chargeItems = jsonData.chargeItems;
        chargeItems.forEach((e) => {
            bodyTitle2.forEach((element, index) => {
                let lastRow = (bodyTitle2.length == index + 1)
                this.GenerateSubHeaderHTML(element, allColum, "left", 1, false, false);
                this.GenerateBodyChartItemHTML(element, e, allColum, lastRow)
            });
        });
    }

    private GenerateBodyChartItemHTML(element, e, allColum, lastRow) {
        this.tr = this.table.insertRow(-1);
        this.row++;
        let col = 1;
        element.forEach((el, i) => {
            let lastCol = (element.length == (i + 1));
            let text = (el.caption == "") ? "" : (e[el.dataField]) ? e[el.dataField] : "NULL";
            let th = document.createElement("th");
            th.colSpan = el.colSpan;
            th.style.cssText = this.getStyleText(this.row, true, col, el.colSpan, allColum, 'left', 1, lastRow, lastCol);
            th.style.paddingBottom = '10px';
            th.innerHTML = text;
            col += (el.colSpan) ? el.colSpan : 1;
            this.tr.appendChild(th);
        });
    }
}
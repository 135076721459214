export class PriceConditionView {
    public priceConditionId: string;
    public priceIndividualId: string;
    public numberOfUnit: number;
    public conditionCode: string;
    public unitType: string;
    public individualAgeGroup: string;
    public socialType: string;
    public unitReference: string;
    public arithmeticOperator: string;
    public isIndividual: boolean = false;
}
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import {
    addActivity,
    ActivityState
} from '../../store/activity';
import { selectTabs } from "src/app/store/tab";
import { TabModel } from '../models/tab';

@Injectable({
    providedIn: 'root'
})
export class ActivityStoreService {

    constructor(private activityStore: Store<ActivityState>,
        private store: Store<any>) { }

    public add(
        id: string,
        pendingCount: number,
        displayDetail: boolean = false,
        subTab: string = null,
        category: string = null,
        group: string = null,
        domainCode: string = null): void {
        this.store.select(selectTabs).subscribe((tabs: TabModel[]) => {
            let tab = tabs.find((tab) => tab.active == true);
            if (tab?.domainCode) {
                let activity = {
                    tabNumber: tab.id,
                    domainCode: domainCode ? domainCode : tab.domainCode,
                    id: id,
                    pendingCount: pendingCount,
                    displayDetail: displayDetail,
                    subTab: subTab,
                    category: category,
                    group: group
                };
                this.activityStore.dispatch(addActivity(activity));
            }
        });
    }
}
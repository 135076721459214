import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { LoadingSpinnerModule } from '../../../shared/layout/loading-spinner';

import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ProductDashboardComponent } from './product-dashboard/product-dashboard.component';
import { ProductManagementContentComponent } from './product-management-content.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductTabsComponent } from './product-tabs/product-tabs.component';
import { UiModule } from 'src/app/shared/ui/ui.module';

import { DynamicRoutingModule } from '../../../shared/utils/navigation';
import { OopsCategoriesModule } from 'src/app/core/components/categories/oops-categories.module';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { SpecialServiceSearchModule } from '../product-categories-content/special-service/special-service-content/special-service-search/special-service-search.module';
import { MerchandizeModule } from '../product-categories-content/merchandize/merchandize.module';
import { TransportSearchModule } from '../product-categories-content/transport/transport-content/transport-search/transport-search.module';
import { AncillaryServiceSearchModule } from '../product-categories-content/ancillary-service/ancillary-service-content/ancillary-service-search/ancillary-service-search.module';
import { FeeSearchModule } from '../product-categories-content/fee/fee-search/fee-search.module';
import { ProductDashboardModule } from './product-dashboard/product-dashboard.module';
import { ProductDashboardFeesModule } from './product-dashboard-fees/product-dashboard-fees.module';
import { ProductDashboardMerchandizeModule } from './product-dashboard-merchandize/product-dashboard-merchandize.module';
import { ProductDashboardSpecialServiceModule } from './product-dashboard-specialservice/product-dashboard-specialservice.module';
import { ProductDashboardTransportModule } from './product-dashboard-transport/product-dashboard-transport.module';
import { FeeDetailModule } from '../product-categories-content/fee/fee-detail/fee-detail.module';
import { VoucherSearchModule } from '../product-categories-content/voucher/voucher-search/voucher-search.module';

@NgModule({
  declarations: [
    ProductCategoriesComponent,
    ProductManagementContentComponent,
    ProductSearchComponent,
    ProductTabsComponent,
  ],
  imports: [
    AlertBarModule,
    AncillaryServiceSearchModule,
    CommonModule,
    FeeDetailModule,
    FeeSearchModule,
    FreezingTabModule,
    DynamicRoutingModule,
    LoadingSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MerchandizeModule,
    NgbNavModule,
    OopsCategoriesModule,
    ProductDashboardModule,
    ProductDashboardFeesModule,
    ProductDashboardMerchandizeModule,
    ProductDashboardSpecialServiceModule,
    ProductDashboardTransportModule,
    RouterModule,
    SpecialServiceSearchModule,
    TransportSearchModule,
    UiModule,
    VoucherSearchModule
  ],
  providers: [ActionbarService],
  exports: [ProductManagementContentComponent],
})
export class ProductManagementContentModule {}

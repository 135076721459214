import { LanguageTranslationSetCommandModel } from "src/app/core/models/language-translation-set-model";
import {
    PriceRuleCurrencyModel,
    PriceRuleLocationModel,
    PriceRuleMembershipModel,
    PriceRuleOrganisationModel,
    PriceRuleProductNumberModel,
    PriceRuleRouteModel,
    PriceRuleSalesBucketModel,
    PriceRuleServiceCategoryModel,
    PriceRuleValidityModel
} from "src/app/core/models/pricing-model";
import {
    PriceAttributeModel,
    PriceConditionModel,
    PriceHierarchyModel,
    PriceRulePriceModel
} from ".";
import { PricePriceDimensionModel } from "./price-price-dimension.model";

export class PriceModel {
    public priceId: string;
    public priceCode: string;
    public priceName: string;
    public status: string;
    public parentPriceId: string;
    public organisationId: string;
    public usageTypeCode: string;
    public unitCode: string;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public ownerFilterUserAccountId: string;
    public isOwnerFilter: boolean;
    public combinationFlag: boolean;
    public draftFlag: boolean;
    public commitBy: string;
    public commitDateTime: Date;
    public productNumbers = new Array<PriceRuleProductNumberModel>();
    public organisations = new Array<PriceRuleOrganisationModel>();
    public routes = new Array<PriceRuleRouteModel>();
    public locations = new Array<PriceRuleLocationModel>();
    public validities = new Array<PriceRuleValidityModel>();
    public serviceCategories = new Array<PriceRuleServiceCategoryModel>();
    public salesBuckets = new Array<PriceRuleSalesBucketModel>();
    public memberships = new Array<PriceRuleMembershipModel>();
    public currencies = new Array<PriceRuleCurrencyModel>();
    public attributes = new Array<PriceAttributeModel>();
    public pricePriceDimensions = new Array<PricePriceDimensionModel>();
    public conditions = new Array<PriceConditionModel>();
    public children = new Array<PriceModel>();
    public hierarchy: PriceHierarchyModel;
    public priceRulePrices = new Array<PriceRulePriceModel>();
    public languageTranslationSet: LanguageTranslationSetCommandModel;
}
import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from '@angular/core';
import { InsightPassengerNameModel } from 'src/app/core/models/individual-model';
import { LanguageReferenceModel, StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { InsightNameDetailComponent } from './detail/name-detail.component';
import { NameDetailDirective } from './detail/name-detail.directive';
import { cloneDeep } from "lodash";
import { InsightNameTableComponent } from './table/name-table.component';
import { NameView } from './shared/name-view';
import { IndividualTitleModel } from 'src/app/core/models/user-model/individual-title.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-insight-detail-name',
    templateUrl: './insight-detail-name.component.html'
})

export class InsightDetailNameComponent  {
    private readonly ACTIVE_STATUS = 'A';
    readonly ERROR_NAME_REQUIRED = 'Name is required.'
    
    @Input() statusReferences: StatusReferenceModel[];  
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() individualTitleReferences: IndividualTitleModel[]; 

    public isCollapsedDetail: boolean = false;
    public isNamesEmpty: boolean = true;
    public isMoreThanOneNames: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;
    public name = this.getDefaultNewName();
    public names: NameView[] = new Array();
    public previousNames: NameView[] = new Array();

    public focusing: boolean = false;
    public focused: boolean = false;

    @ViewChild(NameDetailDirective) nameDetailDirective: NameDetailDirective;
    @ViewChild(InsightNameDetailComponent) nameDetailComponent: InsightNameDetailComponent;
    @ViewChild(InsightNameTableComponent) nameTableComponent: InsightNameTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public newNameDetail: InsightNameDetailComponent;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef) { }    

    public fillModelToForm(passengerNames: NameView[]) {
        this.clearData();
        if (passengerNames && passengerNames.length > 0) {
            this.fillModelInCase(passengerNames);
        }

        this.names = passengerNames;
        this.previousNames = passengerNames;

        if (this.names.length > 0) {
            this.isNamesEmpty = false;
        }

        if (this.names.length == 1) {
            this.isMoreThanOneNames = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.name = this.getDefaultNewName();
    }

    private fillModelInCase(names: NameView[]) {
        if (names.length == 1) {
            this.fillModelSingleRecord(names[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(model: NameView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.name = model;
        this.changeDetectionRef.detectChanges();
    }

    edit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.nameTableComponent.selectedItem);
    }

    private isSingleRecord(): boolean {
        if (this.names.length == 0 || this.names.length == 1) {
            return true;
        }
        return false;
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: InsightPassengerNameModel = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightNameDetailComponent);

        let viewContainerRef = this.nameDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightNameDetailComponent = (<InsightNameDetailComponent>componentRefs.instance);
        component.statusReferences = this.statusReferences;
        component.individualTitleReferences = this.individualTitleReferences;
        component.languageReferences = this.languageReferences;

        if (dataEdit != null) {
            component.insightName = cloneDeep(dataEdit);
        }

        this.newNameDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    onSave() {
        let insightName = this.nameDetailComponent.getInsightName();
        if (insightName) {
            this.savedata(insightName);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    onSaveNewName() {
        let insightName = this.newNameDetail.getInsightName();
        if (insightName) {
            this.savedata(insightName);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private savedata(data: NameView) {
        if (this.names?.length) {
            var filterIndex = this.names.findIndex(x => x.no == data.no);
            if (data.primaryFlag === undefined) {
                data.primaryFlag = false;
            }

            this.updateNameViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryFlag = true;
            this.name = data;
            this.names.push(data);
        }

        this.isNamesEmpty = false;
        this.nameTableComponent.dataGrid.instance.refresh();
    }

    private displayAfterSave() {
        if (this.names?.length > 1) {
            this.singleRecord = false;
            this.isMoreThanOneNames = true;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
            this.isMoreThanOneNames = false;
        }
    }

    private updateNameViews(filterIndex: number, data: NameView) {
        if (this.names?.length > 0 && data.primaryFlag) {
            this.setNamePrimaryFlag(filterIndex);           
        }

        if (filterIndex == -1) {
            data.no = this.names.length + 1;
            this.names.push(data);
        }
        else {
            this.names[filterIndex] = data;
        }
    }

    private setNamePrimaryFlag(filterIndex: number) {
        let insightNames = this.names;
        for (let insightName of insightNames) {
            if (insightName.no == filterIndex + 1){
                continue;
            }

            insightName.primaryFlag = false;
        }

        this.names = insightNames;
    }

    onCancel() {
        if (this.names.length == 0) {
            this.name = this.getDefaultNewName();
        }

        if (this.names.length <= 1) {
            this.isMoreThanOneNames = false;
        }
        
        this.displayAfterCancel();
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.names.length == 1) {
            this.singleRecord = true;
        }
    }

    public toggleDetail() {
        this.isCollapsedDetail = !this.isCollapsedDetail
        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.nameDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    copy() {
        if (this.names.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.names.length == 1) {
            let copyName = cloneDeep(this.names[0]);
            this.openCopyDetailForm(copyName);
            return;
        }

         if (!this.nameTableComponent.selectedItem) {
            return;
        }

        let copyName = cloneDeep(this.nameTableComponent.selectedItem);
        this.openCopyDetailForm(copyName);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneNames = true;
    }

    setCopyNameProperty(copyName: NameView) {
        copyName.individualNameId = null;
        copyName.no = null;
        copyName.primaryFlag = false;
    }

    openCopyDetailForm(copyName: NameView) {
        this.setCopyNameProperty(copyName);
        this.openDetail();
        this.loadDetailForm(copyName);
    }

    getDefaultNewName(): NameView {
        let view = new NameView();
        view.statusCode = this.ACTIVE_STATUS;
        return view;
    }

    validateForm(): boolean {
        if (this.names.length <= 0) {
            return false;
        }

        if (this.nameDetailComponent) {
            return this.nameDetailComponent.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.names.length <= 0) {
            return this.ERROR_NAME_REQUIRED;
        }
        return this.nameDetailComponent.getErrorMessageForm();
    }

}
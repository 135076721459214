import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { RequiredAllOrNoneDirective } from "src/app/shared/ui/forms/inputs/required-all-or-none.directive";
import { PricingDetailAttributeAndRuleComponent } from "./attribute-and-rule.component";
import { AttributeComponent } from "./attribute/attribute.component";
import { CustomerComponent } from "./customer/customer.component";
import { FormOfPaymentComponent } from "./form-of-payment/form-of-payment.component";
import { ParentServiceCategoriesAndSalesBucketComponent } from "./parent-service-categories-and-sales-bucket/parent-service-categories-and-sales-bucket.component";
import { PointOfSalesComponent } from "./point-of-sales/point-of-sales.component";
import { ProductAttributeComponent } from "./product-attribute/product-attribute.component";
import { ProductEligibleRestrictedComponent } from "./product-eligible-restricted/product-eligible-restricted.component";
import { RoundtripDefinitionComponent } from "./roundtrip-definition/roundtrip-definition.component";
import { ServiceCategoriesAndSalesBucketComponent } from "./service-categories-and-sales-bucket/service-categories-and-sales-bucket.component";
import { TransitStopoverComponent } from "./transit-stopover/transit-stopover.component";

@NgModule({
    declarations: [
        AttributeComponent,
        CustomerComponent,
        FormOfPaymentComponent,
        ParentServiceCategoriesAndSalesBucketComponent,
        PointOfSalesComponent,
        PricingDetailAttributeAndRuleComponent,
        ProductAttributeComponent,
        ProductEligibleRestrictedComponent,
        RequiredAllOrNoneDirective,
        RoundtripDefinitionComponent,
        ServiceCategoriesAndSalesBucketComponent,
        TransitStopoverComponent
    ],
    exports: [
        PricingDetailAttributeAndRuleComponent
    ],
    imports: [
        CommonModule,
        DaterangepickerModule,
        BsDropdownModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module
    ]
})
export class AttributeAndRuleModule { }

import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { PointOfSalesModel } from 'src/app/core/models/merchandizing-config';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ATTRIBUTE_AND_RULE_ERR } from '../attribute-and-rule-error.constant';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { pointOfSalesConfigData } from './point-of-sales.config';

@Component({
    selector: 'op-point-of-sales',
    templateUrl: './point-of-sales.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointOfSalesComponent {
    //Product restriction location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    //Product restriction organisation
    @Input() organisationSelect2Data: Select2Data[];
    @Input() organisationTypeSelect2Data: Select2Data[];
    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() organisationRoleSelect2Data: Select2Data[];
    
    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public pointOfSalesConfig: PointOfSalesModel;
    public focusing: boolean = false;
    
    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.pointOfSalesConfig = Object.assign(new PointOfSalesModel(), pointOfSalesConfigData);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        if (this.ruleComponent) {
            this.ruleComponent.add();
        }
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    public validateForm(): boolean {
        if (this.ruleComponent) {
            return this.ruleComponent.validateForm();
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return ATTRIBUTE_AND_RULE_ERR.duplicateInformation;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return ATTRIBUTE_AND_RULE_ERR.invalidInformation;
        }
    }
}
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { ScheduleQueueService } from "src/app/core/services/schedule-services";
import { ScheduleQueueGridService } from "../shared/schedule-queue-grid-service";
import { ScheduleQueueMapperService } from "../shared/schedule-queue-mapper.service";

@Component({
    selector: 'op-schedule-queue-cnl-task-table',
    templateUrl: './schedule-queue-cnl-task-table.component.html',

})
export class ScheduleQueueCnlTaskTableComponent implements OnInit {
    private readonly TASKCODE_NOFIFICATION = "SENDNOTIFICATIONS";
    public scheduleQueueTaskData: any;

    @Input() parentScheduleQueueId: string;
    @Input() isNotiFy: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private scheduleQueueService: ScheduleQueueService,
        private scheduleQueueMapperService: ScheduleQueueMapperService,
        private scheduleQueueGridService: ScheduleQueueGridService) {
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        if (this.parentScheduleQueueId) {
            this.scheduleQueueService.getChildren(this.parentScheduleQueueId)
                .subscribe(
                    (responses: ScheduleQueueSearchModel[]) => {
                        this.bindData(responses);
                    });
        }
    }

    private bindData(tasks: ScheduleQueueSearchModel[]) {
        if (!tasks?.length) {
            return;
        }
        this.scheduleQueueTaskData = this.scheduleQueueMapperService.bindTaskData(tasks);
        this.changeDetectorRef.detectChanges();
    }

    private rowSendNotification(e): boolean {
        return (e.data.taskCode == this.TASKCODE_NOFIFICATION);
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == 'result') {
            this.scheduleQueueGridService.setResultCellColor(e);
        }
        this.removeExpand(e);
    }

    private removeExpand(e) {
        if (e.rowType === "data" && e.column.command === "expand" && !this.rowSendNotification(e)) {
            e.cellElement.removeClass("dx-datagrid-expand");
            e.cellElement.empty();
        }
    }
}
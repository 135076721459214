import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { SecurityGroupReference } from 'src/app/core/models/security-model/security-group-reference.model';
import { SecurityGroupTreeModel } from 'src/app/core/models/security-model/security-group-tree.model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';

@Injectable()
export class SecurityGroupTreeConverter {
    private readonly groupNodeType = 'GROUP';
    private readonly codeNodeType = 'CODE';
    private readonly folderOpenIcon = 'tree-view-folder-open-icon';
    private readonly folderCloseIcon = 'tree-view-folder-close-icon';
    private readonly fileIcon = 'tree-view-file-icon';

    constructor(private stringHelperService: StringHelperService) { }

    public modelToTreeNode(model: SecurityGroupTreeModel): TreeNode {
        let treeNode: TreeNode = {
            label: model.name,
            data: model.id,
            type: model.type,
            children: []
        }

        treeNode = this.addIcon(treeNode);
        if (model.children?.length <= 0) {
            return treeNode;
        } else {
            model.children.forEach(child => {
                treeNode.children.push(this.modelToTreeNode(child));
            })
        }
        return treeNode;
    }

    public securityCodeModelToTreeNode(model: SecurityCodeReferenceModel): TreeNode {
        let treeNode: TreeNode = {
            label: model.securityName,
            data: this.stringHelperService.NewGuid(),
            type: this.codeNodeType
        }
        treeNode = this.addIcon(treeNode);
        return treeNode;
    }

    public addIcon(node: TreeNode): TreeNode {
        if (node.type == this.groupNodeType) {
            node.expandedIcon = this.folderOpenIcon;
            node.collapsedIcon = this.folderCloseIcon;
        }
        else if (node.type == this.codeNodeType) {
            node.icon = this.fileIcon;
        }
        return node;
    }

    public TreeNodeToSecurityGroupDetail(node: TreeNode): SecurityGroupReference {
        let model: SecurityGroupReference = {
            securityGroupId: node.data,
            securityGroupName: node.label,
            statusCode: 'A',
            commitByName: null,
            commitDateTime: null
        }
        return model;
    }
}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityCodeSearchTableView } from 'src/app/modules/settings-management/settings-categories-content/security-management/security-code/security-code-search/shared/security-code-search-table.view';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { SecurityCodeReferenceModel } from '../../models/security-model/security-code-reference.model';

@Injectable({
    providedIn: 'root'
})
export class SecurityCodeService extends ApiServiceBase {
    private readonly PATH = '/system/SecurityReference';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
            super(httpClient, authService)
        }

    public searchSecurityCode(securityCode: string, securityName: string, groupName: string, userLogon: string, statusCode: string): Observable<SecurityCodeSearchTableView[]> {
        let param = new HttpParams()
            .set('securityCode', securityCode)
            .set('securityName', securityName)
            .set('groupName', groupName)
            .set('userLogon', userLogon)
            .set('statusCode', statusCode);
        return this.httpGet<SecurityCodeSearchTableView[]>(this.PATH + '/search', param)
            .pipe(
                map(response => response.body)
            )
    }

    public getSecurityCodeDetail(securityCode: string): Observable<SecurityCodeReferenceModel> {
        return this.httpGet<SecurityCodeReferenceModel>(this.PATH + '/' + securityCode, null)
            .pipe(
                map(response => response.body)
            )
    }

    public AddSecurityReference(securityDetail: SecurityCodeReferenceModel) {
        return this.httpPost(this.PATH, securityDetail);
    }

    public SaveSecurityReference(securityDetail: SecurityCodeReferenceModel, securityCode: string) {
        return this.httpPut(this.PATH + '/' + securityCode, securityDetail);
    }

    public getAllSecurityCode(): Observable<SecurityCodeReferenceModel[]> {
        return this.httpGet<SecurityCodeReferenceModel[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }

    public changeSecurityStatus(securityCode: string, statusCode: string) {
        return this.httpPut(`${this.PATH}/status/${securityCode}?statusCode=${statusCode}`, null);
    }
}

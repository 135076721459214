import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { OrganisationModel } from "src/app/core/models/organisation-model";
import { TransportAddCommandModel, TransportEditCommandModel, TransportViewModel } from "src/app/core/models/product-model/transport-model";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { ProductHashTagView } from "../../../../shared/views/product-hashtag.view";
import { TransportSearchTableView } from "./transport-search-table.view";
import { ProductNumberCommandModel } from "src/app/core/models/product-model/product-base-model/product-number";
import { HashTagMapperService } from "../../../../shared/mapper/hashtag-mapper.service";
import { NumberMapperService } from "../../../../shared/mapper/number-mapper.service";
import { AttributeMapperService } from "../../../../shared/mapper/attribute-mapper.service";
import { RestrictionMapperService } from "src/app/core/components/rules-config/shared/mappers/restriction-mapper.service";
import { ValidityMapperService } from "src/app/core/components/rules-config/shared/mappers/validity-mapper.service";
import { PointOfSalesMapperService } from "src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service";
import { ProductRestrictionLocationCommandModel, ProductRestrictionOrganisationCommandModel, ProductRestrictionProductCommandModel, ProductRestrictionProductNumberCommandModel, ProductRestrictionRouteCommandModel } from "src/app/core/models/product-model/product-base-model/product-restriction";
import { ProductAttributeCommandModel, ProductAttributeViewModel } from "src/app/core/models/product-model/product-base-model/product-attribute";
import { ProductValidityCommandModel } from "src/app/core/models/product-model/product-base-model/product-validity";
import { PointOfSalesCommandView } from "src/app/core/components/rules-config/shared/views/point-of-sales-command.view";
import { OriginDestinationMapperService } from "../../../../shared/mapper/origin-destination-mapper.service";
import { TransportSearchConditionView } from "./transport-search-condition.view";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { TransportSearchCommandModel } from "src/app/core/models/product-model/transport-model/transport-search-command.model";
import { TransportSearchViewModel } from "src/app/core/models/product-model/transport-model/transport-search-view.model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { AttributeAndRuleComponent } from "../../shared/attribute-and-rule/attribute-and-rule.component";
import { StateConstant } from "../../../../shared/constant/state.constant";
import { DomainAttributeModel } from "src/app/core/models/reference-model/reference-general-model";
import { PointOfSalesViewMapperService } from "src/app/core/components/rules-config/shared/mappers/pointofsales-view-mapper.service";

@Injectable()
export class MapperService {

    readonly TYPECODE_LOCATION_POINT: string = "POINT";
    readonly FILTER_USAGE_TYPE = 'FILTER';

    constructor(private hashTagMapperService: HashTagMapperService,
        private numberMapperService: NumberMapperService,
        private attributeMapperService: AttributeMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private validityMapperService: ValidityMapperService,
        private originDestinationMapperService: OriginDestinationMapperService,
        private dateConverterService: DateConverterService,
        private pointOfSalesViewMapperService: PointOfSalesViewMapperService) {
    }

    public TransportSearchTableViews(models: TransportSearchViewModel[], pagingView: PagingDataView[]): TransportSearchTableView[] {
        let views = new Array();
        let i = 0;
        let j = 0;
        for (let model of models) {
            ++i;
            if (model.usageTypeCode != this.FILTER_USAGE_TYPE) {
                ++j;
                pagingView.push(this.PaggingView(j, model.productId, model.productName));
            }
            views.push(this.TransportSearchTableView(i, j, model));
        }
        return views;
    }

    public TransportSearchTableView(index: number, indexPaging: number, model: TransportSearchViewModel): TransportSearchTableView {
        let view = {} as TransportSearchTableView;
        view.no = index;
        view.id = model.productId;
        view.rootProductId = model.rootProductId;
        view.name = model.productName;
        view.productNumber = this.createProductNumber(model.providerCode, model.productNumber);
        view.locationFrom = model.locationNameFrom;
        view.locationTo = model.locationNameTo;
        if (model.departureDate) {
            view.dateFrom = this.dateConverterService.toDateFormat(model.departureDate);
        }
        if (model.arrivalDate) {
            view.dateTo = this.dateConverterService.toDateFormat(model.arrivalDate);
        }
        view.timeFrom = this.dateConverterService.convertTime24(model.departureTime);
        view.timeTo = this.dateConverterService.convertTime24(model.arrivalTime);
        view.statusCode = model.statusCode;
        view.usageTypeCode = model.usageTypeCode;
        view.usageTypeName = model.usageTypeName;
        view.commitBy = model.commitBy;
        view.commitDateTime = model.commitDateTime;
        view.pagingNo = indexPaging;
        view.finalFlag = model.finalFlag;
        view.state = this.getState(model.draftFlag, model.finalFlag);
        view.weekDay = model.weekDay;
        return view;
    }

    public PaggingView(index: number, id: string, name: string): PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        pview.name = name;
        return pview;
    }

    public convertToAddCommand(product: TransportSearchConditionView, attributeAndRuleComponent: AttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): TransportAddCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
        let vehicleTypeCode = null;
        let vehicleId = null;
        let vehicleCompositionId = null;
        if (attributeAndRuleComponent) {
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);

            productRestrictionLocations = this.restrictionMapperService.attributeRouteToRestrictionLocationCommandViews(
                attributeAndRuleComponent.attributeComponent.routeValue,
                attributeAndRuleComponent.attributeComponent.routeIdList,
                attributeAndRuleComponent.attributeComponent.getDataRoute()
            );

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                attributeAndRuleComponent.attributeComponent.forms, productAttributes);

            productAttributes = this.originDestinationMapperService.originDestinationFormToModels(
                attributeAndRuleComponent.originDestinationComponent.forms, productAttributes);

            vehicleTypeCode = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleTypeCode;
            vehicleId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleId;
            vehicleCompositionId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleCompositionId;

            productValidities = this.validityMapperService.productValidityFormToModels(
                attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
            productRestrictionLocations = this.restrictionMapperService.transportConditionSearchToCommands(productRestrictionLocations, product);
        }
        productNumbers = this.numberMapperService.transportConditionSearchToModel(product, productNumbers, provider$);

        let command: TransportAddCommandModel = {
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            parentProductId: null,
            productName: product.productName,
            productDescription: null,
            draftFlag: product.draftFlag,
            finalFlag: product.finalFlag,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            isOwnerFilter: product.isOwnerFilter,
            filterUserAccountId: null,
            usageTypeCode: product.usageTypeCode,
            statusCode: product.statusCode,
            productScheduleVersionId: product.productScheduleVersionId,
            supplierId: null,
            providerId: product.providerId,
            vehicleTypeCode: vehicleTypeCode,
            vehicleId: vehicleId,
            vehicleCompositionId: vehicleCompositionId,
            displayCode: null,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: [],
            productRestrictionProductNumbers: [],
            productRestrictionRoutes: [],
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: [],
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: [],
            languageTranslationSet: null
        };
        return command;
    }

    public convertToEditCommand(product: TransportSearchConditionView, attributeAndRuleComponent: AttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): TransportEditCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
        let vehicleTypeCode = null;
        let vehicleId = null;
        let vehicleCompositionId = null;
        let serviceTimes = null;
        if (attributeAndRuleComponent) {
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);
            productRestrictionLocations = this.restrictionMapperService.attributeRouteToRestrictionLocationCommandViews(
                attributeAndRuleComponent.attributeComponent.routeValue,
                attributeAndRuleComponent.attributeComponent.routeIdList,
                attributeAndRuleComponent.attributeComponent.getDataRoute()
            );

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                attributeAndRuleComponent.attributeComponent.forms, productAttributes);

            productAttributes = this.originDestinationMapperService.originDestinationFormToModels(
                attributeAndRuleComponent.originDestinationComponent.forms, productAttributes);
            serviceTimes = attributeAndRuleComponent.originDestinationComponent.getServiceTimes();

            vehicleTypeCode = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleTypeCode;
            vehicleId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleId;
            vehicleCompositionId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleCompositionId;

            productValidities = this.validityMapperService.productValidityFormToModels(
                attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
            productRestrictionLocations = this.restrictionMapperService.transportConditionSearchToCommands(productRestrictionLocations, product);
        }
        productNumbers = this.numberMapperService.transportConditionSearchToModel(product, productNumbers, provider$);
        let command: TransportEditCommandModel = {
            productId: product.productId,
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            parentProductId: null,
            productName: product.productName,
            productDescription: null,
            draftFlag: product.draftFlag,
            finalFlag: product.finalFlag,
            usageTypeCode: product.usageTypeCode,
            statusCode: product.statusCode,
            productScheduleVersionId: product.productScheduleVersionId,
            supplierId: null,
            providerId: product.providerId ? product.providerId : null,
            vehicleTypeCode: vehicleTypeCode,
            vehicleId: vehicleId,
            vehicleCompositionId: vehicleCompositionId,
            displayCode: null,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchName: product.searchName,
            searchStatusCode: product.searchStatusCode,
            isOwnerFilter: product.isOwnerFilter,
            filterUserAccountId: null,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: [],
            productRestrictionProductNumbers: [],
            productRestrictionRoutes: [],
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productInventoryDimensions: [],
            productInventories: [],
            productRestrictionOrganisations: productRestrictionOrganisations,
            languageTranslationSet: null,
            medias: [],
            productDateTimes: [],
            serviceTimes: serviceTimes,
            referenceProductId: null,
            routes: []
        };
        return command;
    }

    public convertToSearchCommand(product: TransportSearchConditionView, attributeAndRuleComponent: AttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): TransportSearchCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionProducts: ProductRestrictionProductCommandModel[] = new Array();
        let productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[] = new Array();
        let productRestrictionRoutes: ProductRestrictionRouteCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
        let vehicleTypeCode = null;
        let vehicleId = null;
        let vehicleCompositionId = null;
        if (attributeAndRuleComponent) {
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);

            productRestrictionLocations = this.restrictionMapperService.attributeRouteToRestrictionLocationCommandViews(
                attributeAndRuleComponent.attributeComponent.routeValue,
                attributeAndRuleComponent.attributeComponent.routeIdList,
                attributeAndRuleComponent.attributeComponent.getDataRoute()
            );

            productAttributes = this.attributeMapperService.productAttributeFormToModels(
                attributeAndRuleComponent.attributeComponent.forms, productAttributes);

            productAttributes = this.originDestinationMapperService.originDestinationFormToModels(
                attributeAndRuleComponent.originDestinationComponent.forms, productAttributes);

            vehicleTypeCode = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleTypeCode;
            vehicleId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleId;
            vehicleCompositionId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleCompositionId;

            productValidities = this.validityMapperService.productValidityFormToModels(
                attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);

            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
        }
        productNumbers = this.numberMapperService.transportConditionSearchToModel(product, productNumbers, provider$);
        productRestrictionLocations = this.restrictionMapperService.transportConditionSearchToCommands(productRestrictionLocations, product);

        let command: TransportSearchCommandModel = {
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            productName: product.productName,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            filterUserAccountId: product.filterUserAccountId,
            productSchedulePeriodId: product.productSchedulePeriodId,
            productScheduleVersionId: product.productScheduleVersionId,
            vehicleTypeCode: vehicleTypeCode,
            vehicleId: vehicleId,
            vehicleCompositionId: vehicleCompositionId,
            statusCode: product.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productRestrictionOrganisations: productRestrictionOrganisations,
            draftFlag: product.draftFlag,
            finalFlag: product.finalFlag
        };
        return command;
    }

    public clearAttributeAndRule(attributeAndRuleComponent: AttributeAndRuleComponent) {
        if (attributeAndRuleComponent) {
            attributeAndRuleComponent.productNumberComponent.clearForm();
            attributeAndRuleComponent.attributeComponent.clearForm();
            attributeAndRuleComponent.originDestinationComponent.clearForm();
            attributeAndRuleComponent.vehicleComponent.clearForm();
            attributeAndRuleComponent.validityRuleComponent.ruleComponent?.clearForm();
            attributeAndRuleComponent.pointOfSalesComponent.ruleComponent?.clearForm();
        }
    }

    public getProviderId(product: TransportViewModel): string {
        if (product?.productNumbers) {
            let filter = product.productNumbers.filter(x => !x.productNumberTypeCode);
            if (filter?.length) {
                return filter[0].providerId;
            }
        }
    }

    public getFlightNumber(product: TransportViewModel): string {
        if (product?.productNumbers) {
            let filter = product.productNumbers.filter(x => !x.productNumberTypeCode);
            if (filter?.length) {
                return filter[0].productNumber;
            }
        }
    }

    public getLocationId(product: TransportViewModel, displaySequence: number): string {
        if (product?.productRestrictionLocations) {
            let filter = product.productRestrictionLocations.filter(x => x.productLocationTypeCode == this.TYPECODE_LOCATION_POINT && x.displaySequence == displaySequence);
            if (filter?.length) {
                return filter[0].locationId;
            }
        }
    }

    public getLocationData(product: TransportViewModel, displaySequence: number): Select2Data[] {
        let valueReturen: Select2Data[] = new Array();
        if (product?.productRestrictionLocations) {
            let filter = product.productRestrictionLocations.filter(x => x.productLocationTypeCode == this.TYPECODE_LOCATION_POINT && x.displaySequence == displaySequence);
            if (filter?.length) {
                let data = new Select2Data(filter[0].locationId, filter[0].locationName);
                valueReturen.push(data);
                return valueReturen;
            }
        }
        return valueReturen;
    }

    public createProductNumber(providerCode: string, productNumber) {
        return providerCode + " " + productNumber;
    }

    private getState(draftFlag: boolean, finalFlag: boolean): string {
        if (finalFlag) {
            return StateConstant.FINAL;
        }
        if (draftFlag) {
            return StateConstant.DRAFT;
        }
        return StateConstant.NONE;
    }

    public convertFormToView(condition: TransportSearchConditionView,
        attributeAndRuleComponent: AttributeAndRuleComponent,
        productHashtagValueViews: ProductHashTagView[],
        provider$: BehaviorSubject<OrganisationModel[]>,
        domainAttributeSearch: DomainAttributeModel[],
        domainAttributeOrgDes: DomainAttributeModel[]): TransportViewModel {
        let view = new TransportViewModel();
        view.productId = condition.productId;
        view.productGroupCode = condition.productGroupCode;
        view.productTypeCode = condition.productTypeCode;
        view.searchStatusCode = condition.searchStatusCode;
        view.productName = condition.productName;
        view.searchName = condition.searchName;
        view.searchUsageTypeCode = condition.searchUsageTypeCode;
        view.isOwnerFilter = condition.isOwnerFilter;
        view.usageTypeCode = condition.usageTypeCode;
        view.providerId = condition.providerId;
        view.productScheduleVersionId = condition.productScheduleVersionId;
        view.draftFlag = condition.draftFlag;
        view.finalFlag = condition.finalFlag;
        view.productNumbers = this.numberMapperService.transportConditionSearchToView(condition, provider$);
        view.productRestrictionLocations = this.restrictionMapperService.transportConditionSearchToViews(condition);
        view.productHashtags = this.hashTagMapperService.productHashTagTransportModels(productHashtagValueViews);
        this.convertAttributeAndRuleToView(view, attributeAndRuleComponent, provider$, domainAttributeSearch, domainAttributeOrgDes);
        return view;
    }

    private convertAttributeAndRuleToView(view: TransportViewModel,
        attributeAndRuleComponent: AttributeAndRuleComponent,
        provider$: BehaviorSubject<OrganisationModel[]>,
        domainAttributeSearch: DomainAttributeModel[],
        domainAttributeOrgDes: DomainAttributeModel[]) {
        if (attributeAndRuleComponent) {
            this.convertProductNumberToView(view, attributeAndRuleComponent, provider$);
            this.convertProductAttributeToView(view, attributeAndRuleComponent, domainAttributeSearch, domainAttributeOrgDes);
            this.convertProductVehicle(view, attributeAndRuleComponent);
            this.convertProductValidity(view, attributeAndRuleComponent);
            this.convertProductPointOfSales(view, attributeAndRuleComponent);
        }
    }

    private convertProductNumberToView(view: TransportViewModel,
        attributeAndRuleComponent: AttributeAndRuleComponent,
        provider$: BehaviorSubject<OrganisationModel[]>) {
        let productNumbers = this.numberMapperService.productNumberFormToViews(attributeAndRuleComponent.productNumberComponent.forms, provider$);
        view.productNumbers.push(...productNumbers);
    }

    private convertProductAttributeToView(view: TransportViewModel,
        attributeAndRuleComponent: AttributeAndRuleComponent,
        domainAttributeSearch: DomainAttributeModel[],
        domainAttributeOrgDes: DomainAttributeModel[]) {
        let productAttributes: ProductAttributeViewModel[] = new Array();
        productAttributes = this.attributeMapperService.productAttributeFormToViewModels(attributeAndRuleComponent.attributeComponent.forms, productAttributes);
        this.fillDomainAttribute(productAttributes, domainAttributeSearch);

        productAttributes = this.attributeMapperService.productAttributeFormToViewModels(attributeAndRuleComponent.originDestinationComponent.forms, productAttributes);
        this.fillDomainAttribute(productAttributes, domainAttributeOrgDes);

        view.productAttributes = productAttributes;
    }

    private fillDomainAttribute(productAttributes: ProductAttributeViewModel[], domainAttributes: DomainAttributeModel[]) {
        for (let model of productAttributes) {
            let domainAttribute = domainAttributes.find(x => x.attributeTypeCode == model.attributeTypeCode);
            if (domainAttribute) {
                model.startFlag = domainAttribute.startFlag;
                model.endFlag = domainAttribute.endFlag;
                model.dimensionFlag = domainAttribute.dimensionFlag;
                model.requiredFlag = domainAttribute.requiredFlag;
                model.dimensionFlag = domainAttribute.dimensionFlag;
                model.inheritAttribute = false;
                model.dimensionUnitCode = domainAttribute.dimensionUnitCode;
                model.multipleChoiceFlag = domainAttribute.multipleChoiceFlag;
            }
        }
    }

    private convertProductVehicle(view: TransportViewModel, attributeAndRuleComponent: AttributeAndRuleComponent) {
        view.vehicleTypeCode = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleTypeCode;
        view.vehicleId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleId;
        view.vehicleCompositionId = attributeAndRuleComponent.vehicleComponent.vehicleView.vehicleCompositionId;
    }

    private convertProductValidity(view: TransportViewModel, attributeAndRuleComponent: AttributeAndRuleComponent) {
        view.productValidities = this.validityMapperService.productValidityFormToViewModels(
            attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
    }

    private convertProductPointOfSales(view: TransportViewModel, attributeAndRuleComponent: AttributeAndRuleComponent) {
        let pointofSalesView = this.pointOfSalesViewMapperService.productPointOfSalesFormToViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
        let productRestrictionLocationPointOfSales = this.pointOfSalesViewMapperService.productRestrictionLocationPointOfSalesViewModels(pointofSalesView);
        let productRestrictionOrganisations = this.pointOfSalesViewMapperService.productRestrictionOrganisationViewModels(pointofSalesView);
        view.productRestrictionLocations = this.pointOfSalesViewMapperService.mergePointOfSalesToProductLocationRestrictionView(view.productRestrictionLocations, productRestrictionLocationPointOfSales);
        view.productRestrictionOrganisations = productRestrictionOrganisations;
    }
}
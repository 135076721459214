import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { ActionService } from 'src/app/core/utils/action.service';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ProductCategoriesComponent } from '../product-categories/product-categories.component';
import { ProductCategory } from '../product-categories/product-category';
import { ProductSearchComponent } from '../product-search/product-search.component';
import { PRODUCT_TABS } from './product-tabs.constant';

@Component({
    selector: 'op-product-tabs',
    templateUrl: './product-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService, AlertBarService]
})
export class ProductTabsComponent implements AfterViewInit {
    readonly SETTING_ROUTE_PATH = '/main/product/';
    readonly DEFAULT_TITLE = 'Product Management';
    @Input() selectedTab: string = PRODUCT_TABS.categories;
    @Input() dashboardNotSelected: boolean = true;

    @Output() selectedTabChange = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<ProductCategory>();
    
    @ViewChild("productCategory") productCategory: ProductCategoriesComponent;
    @ViewChild("productSearch") productSearch: ProductSearchComponent;

    public selectedCategory: ProductCategory;
    public title: String = this.DEFAULT_TITLE;
    public executeFlag: boolean = true;
    public searchFilterId: string;
    public selectedFromFavorite: string;
    public productSecurities$ = new BehaviorSubject<SecurityGroupSecurityModel[]>(null);
	
    constructor(private navigateService: NavigationService,
	public alertBarService: AlertBarService,
    public actionService: ActionService,
    public changeDetectorRef: ChangeDetectorRef,
    public securityGroupService: SecurityGroupService,
    private activityStoreService: ActivityStoreService) { }

    ngAfterViewInit(): void {
        if (this.selectedFromFavorite) {
            setTimeout(() => {
                this.selectedCategoryFromFavorite(this.selectedFromFavorite);
            }, 1000)
        }
    }

    ngOnInit(): void {
        this.actionService.add(null, null, null);
        this.getSecurityGroup();
        let params = this.navigateService.getParams();
        let tab = params?.tab ?? null;
        let module = params?.module ?? null;
        this.executeFlag = params?.executeFlag ?? true;
        this.searchFilterId = params?.id;
        this.selectedFromFavorite = params?.selectedCategory;
        this.gotoTransportDetail(params);
        if (tab && module) {
            this.selectedTab = tab;
            if (!module?.code) {
                this.selectedCategory = this.createSelectedItem(module);
            }
            else {
                this.selectedCategory = module;
                this.productCategory.selectedItem = this.selectedCategory;
                this.changeDetectorRef.detectChanges();
            }
            this.onSelectedTabChange(this.selectedTab);
        } else {
            this.activityStore("categories")
        }
    }

    onTabChange(activeId: string) {
        this.selectedTab = activeId;
        this.onSelectedTabChange(this.selectedTab);
    }

    onMenuSelected(activeId: string) {
        this.selectedTab = activeId.toLowerCase();
        this.onSelectedTabChange(this.selectedTab);
    }

    onRowSelected(item: ProductCategory) {
        this.selectedCategory = item;
        this.executeFlag == true;
    }

    createSelectedItem(name: string) : ProductCategory{
        var item = {} as ProductCategory
        item.name = name;
        item.url = this.SETTING_ROUTE_PATH + name;
        return item;
    }

    onCancelSearchRequested() {
        this.onTabChange(PRODUCT_TABS.categories);
    }

    public onAlertBarClicked() {
        if (this.selectedTab == PRODUCT_TABS.search) {
            this.productSearch.alertBarClicked();
        }
    }

    public onSelectedTabChange(activeTabId: string) {
        this.alertBarService.hide();
        this.addAction(activeTabId);
        this.activityStore(activeTabId);
        this.dashboardNotSelected = true;
        switch(activeTabId) {
            case PRODUCT_TABS.search:
                this.title = this.selectedCategory?.name + ' Management';
                return;
            case PRODUCT_TABS.dashboard:
            case PRODUCT_TABS.fees:
            case PRODUCT_TABS.merchandize:
            case PRODUCT_TABS.specialService:
            case PRODUCT_TABS.transport:
                this.dashboardNotSelected = false;
                break;
            default:
                break;
        }
        
        this.title = this.DEFAULT_TITLE;
    }

    public addAction(actionCode: string) {
        if (actionCode == PRODUCT_TABS.search) {
            this.actionService.add(actionCode + '/' + this.selectedCategory?.code, null, this.selectedCategory?.name);
            return;
        }
        this.actionService.add(null, null, null);
    }

    private selectedCategoryFromFavorite(code: string) {
        let category = this.productCategory?.categoriesItems?.find(item => item.code == code);
        this.selectedCategory = category;
        this.selectedTab = PRODUCT_TABS.search;
        this.onSelectedTabChange(PRODUCT_TABS.search);
        this.changeDetectorRef.detectChanges();
    }

    private getSecurityGroup() {
        this.securityGroupService.userSecurityGroupSecurity
        .subscribe(
            (response: SecurityGroupSecurityModel[]) => {
                let productSecurities = response?.filter(item => item.securityCode.startsWith('PRODUCT'));
                this.productSecurities$.next(productSecurities);
                this.changeDetectorRef.detectChanges();
            }
        )
    }

    private activityStore(activeTabId: string) {
        let categoryCode = (activeTabId == "search")? this.selectedCategory?.code : null;
        this.activityStoreService.add(
            null,
            null,
            null,
            activeTabId,
            categoryCode,
            null);
    }

    private gotoTransportDetail(params) {
        if (params?.transportDetail) {
            let paramDetail = {
                id: params?.id,
                copy: params?.copy,
                paggingDataView: params?.paggingDataView,
                currentIndex: params?.currentIndex,
                userSecurity : params?.userSecurity,
                module: this.createSelectedItem('transport'),
                searchCriteria: null
            }
            this.navigateService.navigate('main/product/transport/details', null , null, paramDetail); 
        }
    }
}
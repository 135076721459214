import { Injectable } from '@angular/core';
 
@Injectable()
export class Helper {

  getClassIcon(){
    let settings = JSON.parse(localStorage.getItem('settings'));
    let classIcon = 'icon-stack icon-stack';
    if (settings != null) {
        return classIcon + '-' + settings.globalFontSize + '-custom';
    }
    else {
        return 'icon-stack icon-stack-md-custom';
    }
  }

  getClassIconButtonTree(focus) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    let classIcon = 'fal fa-align-justify';
    if (focus == true) {
      classIcon+=' color-info-900';
    }
    classIcon+= ' icon-stack';

    if (settings != null) {
        return classIcon + '-' + settings.globalFontSize + '-custom';
    }
    else {
        return  classIcon + '-md-custom';
    }
  }

  getClassIconButtonTreeFlight(focus) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    let classIcon = 'fal fa-align-justify';
    if (focus == true) {
      classIcon+=' color-info-900';
    }
    classIcon+= ' icon-stack';

    if (settings != null) {
        return classIcon + '-' + settings.globalFontSize + '-custom';
    }
    else {
        return  classIcon + '-md-custom';
    }
  }


  getClassIconButtonFlightList(focus) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    let classIcon = 'fal fa-calendar-day';
    if (focus == true) {
      classIcon+=' color-info-900';
    }
    classIcon+= ' icon-stack';

    if (settings != null) {
        return classIcon + '-' + settings.globalFontSize + '-custom';
    }
    else {
        return  classIcon + '-md-custom';
    }
  }
  
  clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  isNullZero(value: number): number {
    if (!value) {
      return 0;
    }
    return value
  }

  createLoad(capacity: number, 
             overSellFactor: number,
             booked: number): number {
    capacity = this.isNullZero(capacity);
    overSellFactor = this.isNullZero(overSellFactor);
    booked = this.isNullZero(booked);
    var totalCapacity = capacity + (capacity*(overSellFactor/100));
    if (totalCapacity == 0) {
        return 0;
    }
    return parseFloat(((booked * 100) / totalCapacity).toFixed(2));
  }

  getErrorReason(error) : string {
    return error?.error; 
  }
}
export class SearchTableView {
    public id: string;
    public no: number;
    public code: string;
    public name:string;
    public pricingGroup: string;
    public categoryCode: string;
    public priceRuleTypeGroupName: string;
    public status: string;
    public commitBy:string; 
    public commitByName:string;
    public commitDateTime:string;
}
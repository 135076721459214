import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActionService } from 'src/app/core/utils/action.service';
import { SecurityLevel2TableModel } from '../security-level2-table/security-level2-teble.model';

@Component({
    selector: 'op-security-level2-tabs',
    templateUrl: './security-level2-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityLevel2TabsComponent implements OnInit {
    private FAVORITE_TABID = 'FAVORITE';
    @Input() items: SecurityLevel2TableModel[];
    @Input() selectedTab = this.FAVORITE_TABID;
    constructor(private actionService: ActionService) { }

    ngOnInit(): void {
        this.addAction(this.selectedTab);
    }

    selectedTabChange(activeId: string) {
        this.addAction(activeId)
    }

    addAction(activeId: string) {
        this.actionService.add(activeId, null, null);
    }

}

import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CsvExportService, PdfExportService, PrintExportService } from 'src/app/core/utils/exports';
import { cloneDeep } from 'lodash'
import { PrintHeaderModel } from './print-header.model';

@Component({
    selector: 'op-export-button-group',
    templateUrl: './export-button-group.component.html'
})
export class ExportButtonGroupComponent {
    private readonly CSV_FORMAT: string = 'CSV';
    private readonly PDF_FORMAT: string = 'PDF';
    @Input() rowsNumber: number;
    @Input() exportData;
    @Input() selectedRow: any;
    @Input() jsonHeaders: PrintHeaderModel[];
    @Input() header: string[];
    @Input() exportName: string = '';
    @Input() pdfDefaultStyle: any = null;
    @Input() printDefaultStyle: any = null;
    @Input() pdfWidthPercentage: any = null;
    @Input() copyToClipboardFlag = true;
    @Input() exportFlag = true;
    @Input() printFlag = true;
    @Input() buttonCopyId = "btnCopy";
    @Input() buttonExportId = "btnGroupExport";
    @Input() buttonPrintId = "btnPrint";
	@Input() pageOrientation: string;
    @Input() pageSize: string;
    @Input() customExport: boolean = false;
    @Input() customCopy: boolean = false;
    @Input() customPrint: boolean = false;

    @Output() onExport = new EventEmitter<string>();
    @Output() onCopy = new EventEmitter();
    @Output() onPrint = new EventEmitter();
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    
    constructor(private csvExportService: CsvExportService,
        private pdfExportService: PdfExportService,
        private printExportService: PrintExportService,
        private clipboard: Clipboard,
        private changeDetectorRef: ChangeDetectorRef) { }

    public exportToPDF() {
        if (this.customExport) {
            this.onCustomExport(this.PDF_FORMAT);
            return;
        }
        this.exportPopoverToggle();
        let mappedData = this.mapExportData(this.exportData);
        this.pdfExportService.downloadJsonData(this.exportName, mappedData, this.pdfDefaultStyle, this.pdfWidthPercentage, this.pageOrientation, this.pageSize);   
    }
    
    public exportToCSV() {
        if (this.customExport) {
            this.onCustomExport(this.CSV_FORMAT);
            return;
        }
        this.exportPopoverToggle();
        let mappedData = this.mapExportData(this.exportData);
        this.csvExportService.download(this.exportName, mappedData);
    }

    public onCustomExport(fileFormat: string) {
        this.onExport.emit(fileFormat)
    }
    
    public print() {
        if (this.customPrint) {
            this.onPrint.emit();
            return;
        }
        let mappedData = this.mapExportData(this.exportData);
        this.printExportService.print(mappedData, this.getPrintFontSize(this.printDefaultStyle));
    }
    private getPrintFontSize(printDefaultStyle: any) {
        if (printDefaultStyle) {
            return printDefaultStyle.fontSize;
        }
        return "";
    }
    public mapExportData(filteredData: Object) {
        let tempData = cloneDeep(filteredData);
        for (let key in tempData) {
            let value = this.selectFieldMapper(tempData[key]);
            if (value) {
                tempData[key] = value;
            }
        }
        return tempData;
    }

    private selectFieldMapper(row) {
        if (this.header) {
            return this.renameObjKeyToHeader(row);
        }
        return this.renameObjKeyToHeaderFromJson(row);
    }

    public renameObjKeyToHeader(row) {
        let rowTemp = {};
        let colNum = 0;
        for (let key in row) {
            if (this.header[colNum]) {
                rowTemp[this.header[colNum]] = row[key] ?? '';
            }
            colNum += 1;
        }

        return rowTemp;
    }
    
    private renameObjKeyToHeaderFromJson(rows) {
        let rowTemp = {};
        for (let header of this.jsonHeaders) {
            var row = rows[header.mapField];
            rowTemp[header.displayName] = row ?? '';
        }
        return rowTemp;
    }

    public copyToClipboard() {
        if (this.customCopy) {
            this.onCopy.emit();
            return;
        }
        this.copyPopoverToggle();
        let copyData = this.header.join('\t') + '\n' ;
        if (this.selectedRow == null){
            copyData += this.copyAllToClipboard();
        } else {
            copyData += this.createTableStructureString(this.selectedRow);
        }
        this.clipboard.copy(copyData);
    }
    
    public copyAllToClipboard(): string {
        let data = '';
        this.exportData.forEach(row => {
            data += this.createTableStructureString(row)
        });
        return data;
    }
    
    createTableStructureString(data: Object) {
        let dataTemp = Object.entries(data).map(( [name, value] ) => {
            return value
        });
        return dataTemp.join('\t') + '\n';
    }
    
    public copyPopoverToggle() {
        this.showCopyPopover = !this.showCopyPopover;
    }
    
    public exportPopoverToggle() {
        this.showExportPopover = !this.showExportPopover;
    }
    
    public printPopoverToggle() {
        this.showPrintPopover = !this.showPrintPopover;
    }

    public detectChange() {
        this.changeDetectorRef.detectChanges();
    }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { OperationEventReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";

@Component({
    selector: 'op-document-distribution-event',
    templateUrl: './document-distribution-event.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentDistributionEventComponent { 
    public focusing: boolean = false;
    public collapsed: boolean = true;

    public distributionEvents: string[];

    public select2EventOption = {
        placeholder: "<Select>",
        allowClear: false,
        multiple: "multiple",
        theme: "select2-builder-multiple-gray",
        width: 'auto',
        dropdownAutoWidth: true
    }

    @Input() operationEventReferences: OperationEventReferenceModel[];
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
    }

    clear() {
        this.distributionEvents = [];
    }

    eventsChange(event: any) {
        this.distributionEvents = event;
    }

    public fillModelToForm(events: string[]) {
        if (events?.length) {
            this.collapsed = false;
        }
        else {
            this.collapsed = true;
        }
        this.distributionEvents = events
        this.changeDetectionRef.detectChanges();
    }

    public getDocumentDistributionEvent() : string[] {
        return this.distributionEvents
    }
}
  
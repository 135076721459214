import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import Swal from 'sweetalert2';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxDropzoneImagePreviewComponent } from 'ngx-dropzone';

import { select2TextType } from '../shared/translation-media-configuration';

import { LanguageReferenceModel, MediaTypeFileTypeModel, MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { TranslationMediaChildView } from '../shared/translation-media-child.view';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { MediaService } from 'src/app/core/services/media-services';
import { MediaMapperService } from '../../media/shared/media-mapper.service';
import { MediaFilePreviewService } from 'src/app/core/services/media-services/media-file-preview-service';

@Component({
    selector: 'op-translation-media-detail',
    templateUrl: './translation-media-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationMediaDetailComponent implements OnInit {

    @Input() model: TranslationMediaChildView;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);

    @Output() onSave = new EventEmitter<TranslationMediaChildView>();
    @Output() onCancel = new EventEmitter();

    @ViewChild('mediaTranslationForm') mediaTranslationForm: NgForm;
    @ViewChild('previewImage') previewImage: NgxDropzoneImagePreviewComponent;
    @ViewChild('swal')
    public readonly swal!: SwalComponent;
    readonly DROPZONE_BINDING_DELAY_TIME = 200;
    public textTypeOption: any;
    public fileChange: boolean = false;
    public files: TranslationMediaChildView[] = [];
    public loadFileExistFinished: boolean = false;
    public maximumFileSize: boolean = false;
    public fileTypeInvalid: boolean = false;
    public canPreviewFile: boolean = false;
    public canDownloadFile: boolean = false;
    public fileNotFound: boolean = false;
    public urlPreviewFile: any;
    public viewerType: string;

    constructor(private mediaService: MediaService,
        private autService: AuthService,
        private mediaMapperService: MediaMapperService,
        private changeDectionRef: ChangeDetectorRef,
        private mediaFilePreviewService: MediaFilePreviewService,) {
        this.textTypeOption = select2TextType;
    }

    get languageCodes(): string | string[] {
        return this.model.languageCode;
    }

    set languageCodes(value: string | string[]) {
        this.model.languageCode = <string>value;
    }
    
    ngOnInit(): void {
        this.getMediaFile();
    }
    public save(form: NgForm) {
        this.fileTypeInvalid = false;
        if (!this.files?.length) {
            this.model.noFile = true;
            this.model.mediaFileBase64 = null;
            this.model.mediaFile = null;
            this.model.mediaFilePreview = null;
            this.model.mediaFilePreviewBase64 = null;
            this.onSave.emit(this.model);
            return;
        }
        this.model.fileChange = this.fileChange;
        this.model.noFile = false;
        this.model.mediaFileBase64 = this.files[0].mediaFileBase64;
        this.model.mediaFile = this.files[0].mediaFile;
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0].mediaFilePreview);
        reader.onload = () => {
            this.model.mediaFilePreview = this.files[0].mediaFilePreview;
            this.model.mediaFilePreviewBase64 = reader.result;
            this.onSave.emit(this.model);
        };
    }

    public cancel(form: NgForm) {
        this.onCancel.emit();
    }

    onSelect(event) {
        this.singleFileProcess(event.addedFiles[0]);
    }

    private singleFileProcess(addedFile: File) {
        this.clearFile();
        if (!this.validateFile(addedFile)) {
            return;
        }
        this.model.fileChange = true;
        this.fileChange = true;
        let mediaFile = this.createMediaFileView(addedFile);
        this.displayFilePreview(mediaFile, addedFile.type);
    }

    private createMediaFileView(fileData: File): TranslationMediaChildView {
        let mediaFileView = new TranslationMediaChildView();
        mediaFileView.mediaFile = fileData;
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            mediaFileView.mediaFileBase64 = reader.result;
        };
        return mediaFileView;
    }

    private displayFilePreview(mediaFile: TranslationMediaChildView, contentType: string) {
        this.canDownloadFile = true;
        if (this.mediaMapperService.isImage(contentType)) {
            this.canPreviewFile = true;
            mediaFile.mediaFilePreview = mediaFile.mediaFile;
            this.addFileToDropZone(mediaFile);
            this.refreshPage();
            this.touchedPage();
        }
        else if (this.mediaMapperService.isVideo(contentType) || this.mediaMapperService.isPdf(contentType)) {
            this.canPreviewFile = true;
            this.getPicFilePreview(mediaFile, contentType);
        }
        else if (this.mediaMapperService.isDoc(contentType)) {
            this.canPreviewFile = (this.checkCanPreviewFile() && !this.model.fileChange);
            this.getPicFilePreview(mediaFile, contentType);
        } else {
            this.getPicFilePreview(mediaFile, contentType);
        }
    }

    private getPicFilePreview(mediaFile: TranslationMediaChildView, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFile.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.addFileToDropZone(mediaFile);
                        this.refreshPage();
                    }
                }
            );
    }

    private addFileToDropZone(mediaFile: TranslationMediaChildView) {
        this.files = [];
        this.files.push(mediaFile);
        this.changeDectionRef.detectChanges();
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.fileChange = true;
        this.clearFile();
    }

    public displayPreviewFileSize() {
        if (this.files?.length) {
            let totalBytes = this.files[0].mediaFile.size;
            var sizeDisplay = this.mediaMapperService.formatBytes(totalBytes);
            return sizeDisplay;
        }
    }

    public displayPreviewFileType() {
        if (this.files?.length) {
            let fileTypeDisplay = this.mediaMapperService.getFileExt(this.files[0].mediaFile.name);
            return fileTypeDisplay.toUpperCase();
        }
    }

    private getMediaFile() {
        if (this.model.noFile) {
            this.loadFileExistFinished = true;
            return;
        }
        if (this.model.mediaFile) {
            let mediaFile = this.createMediaFileView(this.model.mediaFile);
            this.displayFilePreview(mediaFile, mediaFile.mediaFile.type);
        } else if (this.model.mediaContentId && !this.model.mediaFileBase64) {
            this.loadFileExistFinished = false;
            this.mediaService.getMediaFile(this.model.mediaContentId)
                .subscribe(
                    (response) => {
                        this.clearFile();
                        if (response.status == 200) {
                            var contenType = response.headers.get("Content-Type");
                            var fileName = this.mediaMapperService.getfileName(this.autService.getSelectedOrganisation(), this.model.mediaContentId, contenType)
                            var file = new File([response.body], fileName, { type: contenType });
                            let mediaFile = this.createMediaFileView(file);
                            this.displayFilePreview(mediaFile, contenType);
                        }
                        else {
                            this.fileNotFound = true;
                            this.refreshPage();
                        }
                    }
                );
        }
        else {
            if (!this.model.mediaFileBase64) {
                this.clearFile();
            }
        }
    }

    private clearFile() {
        this.files = [];
        this.loadFileExistFinished = true;
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        this.canPreviewFile = false;
        this.canDownloadFile = false;
        this.fileNotFound = false;
    }

    public loadFileFished(): boolean {
        if (this.model.mediaContentId && !this.model.mediaFileBase64) {
            return this.loadFileExistFinished;
        }
        else {
            return true;
        }
    }

    public downloadFile() {
        if (this.files?.length) {
            const blob = this.files[0].mediaFile;
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.files[0].mediaFile.name;
            anchor.href = url;
            anchor.click();
        }
    }

    public previewFile() {
        if (this.files?.length) {
            if (this.mediaMapperService.isImage(this.files[0].mediaFile.type)) {
                this.previewImageFile();
            }
            else {
                this.previewOtherFile();
            }
        }
    }

    private previewImageFile() {
        const blob = this.files[0].mediaFile;
        const url = window.URL.createObjectURL(blob);
        Swal.fire({
            html: '<div class="col-12"><img src="' + url + '" style="max-width:700px;" ></div>',
            width: '800px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    private previewOtherFile() {
        const blob = this.files[0].mediaFile;
        if (this.mediaMapperService.isVideo(blob.type) ||
            this.mediaMapperService.isPdf(blob.type)) {
            this.viewerType = "url";
            this.urlPreviewFile = window.URL.createObjectURL(blob);
        }
        else if (this.mediaMapperService.isXLS(blob.type)) {
            this.viewerType = "google";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.model.mediaContentId);
        }
        else {
            this.viewerType = "office";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.model.mediaContentId);
        }
        this.swal.fire(
        );
    }

    private validateFile(f: File): Boolean {
        if (!this.validateFileType(f)) {
            this.fileTypeInvalid = true;
            return false;
        } else if (!this.validateFileSize(f)) {
            this.maximumFileSize = true;
            return false;
        }
        this.fileTypeInvalid = false;
        this.maximumFileSize = false;
        return true;
    }

    private validateFileType(f: File): Boolean {
        if (!this.mediaTypeFileType$.value) {
            return false;
        }
        var fileExt = this.mediaMapperService.getFileExt(f.name);
        var filterFileTypeValid = this.mediaTypeFileType$.value.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (filterFileTypeValid?.length) {
            return this.checkFileTypeValid(filterFileTypeValid);
        }
        return false;
    }

    private validateFileSize(f: File): Boolean {
        if (this.mediaTypeReference$.value) {
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
            if (filter.length > 0) {
                if (filter[0].maximumSize) {
                    if (f.size <= filter[0].maximumSize) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private checkFileTypeValid(fileTypeValid: MediaTypeFileTypeModel[]): boolean {
        if (this.model.mediaTypeCode) {
            var filter = fileTypeValid.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
            if (filter.length > 0) {
                return true;
            }
            return false;
        }
        return true;
    }

    private refreshPage() {
        setTimeout(() => {
            this.changeDectionRef.detectChanges();
        }, this.DROPZONE_BINDING_DELAY_TIME);
    }

    private touchedPage() {
        this.mediaTranslationForm.form.markAsTouched();
    }

    private checkCanPreviewFile(): boolean {
        if (this.fileChange) {
            return false;
        }
        else {
            return true;
        }
    }

    public loadFileFinished(): boolean {
        if (this.model.mediaContentId && !this.model.mediaFileBase64) {
            return this.loadFileExistFinished;
        } else {
            return true;
        }
    }
}
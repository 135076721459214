import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { VehicleContentModule } from "./vehicle-content/vehicle-content.module";
import { VehicleComponent } from "./vehicle.component";

@NgModule({
    declarations: [
        VehicleComponent,
    ],
    imports: [
        VehicleContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [VehicleComponent]
})
export class VehicleModule { }
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { SettingsManagementContentModule } from "./settings-management-content/settings-management-content.module";
import { SettingsManagementRoutingModule } from "./settings-management-routing.module";
import { SettingsManagementComponent } from "./settings-management.component";
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { VehicleModule } from "./settings-categories-content/vehicle/vehicle.module";
import { AttributeModule } from "./settings-categories-content/attribute/attribute.module";
import { SecurityManagementModule } from "./settings-categories-content/security-management/security-management.module";
import { ExchangeRateModule } from "./settings-categories-content/exchange-rate/exchange-rate.module";
import { DocumentDistributionModule } from "./settings-categories-content/document-distribution/document-distribution.module";

@NgModule({
    declarations: [
        SettingsManagementComponent,
    ],
    imports: [
        AttributeModule,
        DocumentDistributionModule,
        DynamicRoutingModule,
        ExchangeRateModule,
        SettingsManagementContentModule,
        SettingsManagementRoutingModule,
        CommonModule,
        SecurityManagementModule,
        UiModule,
        VehicleModule
    ],
    providers: [

    ]
})
export class SettingsManagementModule { }
export class OrganisationCommunicationPhoneModel {
    public communicationPhoneId: string;
    public communicationPhoneTypeCode: string;
    public communicationPhoneTypeName: string;
    public individualId: string;
    public languageCode: string;
    public countryCode: string;
    public phoneNumber: string;
    public phoneNumberReverse: string;
    public extension: string;
    public commentText: string;
    public primaryFlag: boolean;
    public communicationPhonePrefix: string;
}
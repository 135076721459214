import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { OopsFinanceCategoryContextMenu } from './finance-context-menu.model';

@Component({
  selector: 'op-finance-context-menu',
  templateUrl: './finance-context-menu.component.html',
})
export class FinanceContextMenuComponent implements OnInit {

  private defaultData: OopsFinanceCategoryContextMenu[] = [
    {
      name: 'Display',
      actionId: 'DISPLAY'
    },
    {
      name: 'Group',
      actionId: 'GROUP'
    },
    {
      name: 'Search',
      actionId: 'SEARCH'
    },
    {
      name: 'Dashboard',
      actionId: 'DASHBOARD'
    },
    {
      name: 'Setting',
      actionId: 'SETTING'
    },
  ]
  @Input() data: OopsFinanceCategoryContextMenu[];
  @Input() userSecurity: SecurityGroupSecurityModel;
  @Output() onSelectMessageLogRule: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    if (!this.data) {
      this.data = this.defaultData;
    }
  }

  public showContextMenu(): boolean {
    return false;
  }

  public onMenuClick(actionId: string) {
    this.onSelectMessageLogRule.emit(actionId);
  }
  public itemDisable(item: OopsFinanceCategoryContextMenu): boolean {
    return item.disable ?? false
  }

  newFlag(item: OopsFinanceCategoryContextMenu): boolean {
    return item.actionId == 'NEW' && (this.userSecurity && !this.userSecurity?.newFlag);
  }

}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { NumberOnlyModule } from "src/app/shared/ui/forms/inputs/number-only/number-only.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { InclusiveOfProductsComponent } from "./inclusive-of-products.component";

@NgModule({
    declarations: [InclusiveOfProductsComponent],
    exports: [InclusiveOfProductsComponent],
    imports: [
        BsDropdownModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        NumberOnlyModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
    ],
})
export class InclusiveOfProductsModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { InputFieldModule } from 'src/app/shared/ui/forms/inputs/input-field/input-field.module';
import { FormsModule } from '@angular/forms';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { MatIconModule } from '@angular/material/icon';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { ActionbarService } from 'src/app/shared/ui/actionbar/actionbar.service';
import { Helper } from 'src/app/shared/helper/app.helper';
import { ExchangeRateAmountContentComponent } from './exchange-rate-amount-content.component';
import { ExchangeRateAmountDetailComponent } from './exchange-rate-amount-detail/exchange-rate-amount-detail.component';
import { ExchangeRateAmountListComponent } from './exchange-rate-amount-detail/exchange-rate-amount-list/exchange-rate-amount-list.component';
import { ExchangeRateAmountHistoryComponent } from './exchange-rate-amount-detail/exchange-rate-amount-history/exchange-rate-amount-history.component';
 
@NgModule({
  declarations: [
    ExchangeRateAmountContentComponent,
    ExchangeRateAmountDetailComponent,
    ExchangeRateAmountHistoryComponent,
    ExchangeRateAmountListComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxTemplateModule,
    UiModule,
    PanelsModule,
    MatIconModule,
    FocusingModule,
    BsDropdownModule,
    NgbModule,
    CollapseModule,
    InputFieldModule,
    FormsModule,
    FreezingTabModule,
    NgbNavModule,
    Select2Module,
    DaterangepickerModule,
    MatMenuModule,
    LoadingSpinnerModule
  ],
  providers: [
    ActionbarService,
    Helper
  ],
  exports:[
    ExchangeRateAmountContentComponent
  ]
})
export class ExchangeRateAmountContentModule { }
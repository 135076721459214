import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherSearchComponent } from './voucher-search.component';
import { VoucherSearchConditionComponent } from './voucher-search-condition/voucher-search-condition.component';
import { VoucherSearchTableComponent } from './voucher-search-table/voucher-search-table.component';
import { DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import { MatIconModule } from '@angular/material/icon';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { StatusSelectionModule } from 'src/app/core/components/status-selection/status-selection.module';
import { VoucherAdvanceSearchComponent } from './voucher-advance-search/voucher-advance-search.component';
import { VoucherAttributeAndRuleModule } from '../voucher-attribute-and-rule/voucher-attribute-and-rule.module';

@NgModule({
    declarations: [
        VoucherSearchComponent,
        VoucherSearchConditionComponent,
        VoucherSearchTableComponent,
        VoucherAdvanceSearchComponent
    ],
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        StatusSelectionModule,
        UiModule,
        VoucherAttributeAndRuleModule
    ],
    exports: [
        VoucherSearchComponent
    ]
})
export class VoucherSearchModule { }
import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { MediaView } from './shared/media.view';
import { MediaDetailComponent } from './media-detail/media-detail.component';
import { MediaDetailDirective } from './media-detail/media-detail.directive';
import { MediaTableComponent } from './media-table/media-table.component';
import {
    LanguageReferenceModel,
    MediaUseReferenceModel,
    MediaTypeReferenceModel,
    MediaTypeFileTypeModel
} from 'src/app/core/models/reference-model/reference-media-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaService } from '../../services/media-services';
import { MediaSearchCommandModel, MediaViewModel } from '../../models/media-model';
import { MediaMapperService } from './shared/media-mapper.service';
import { MediaLibraryTableDirective } from './media-library-table/media-library-table.directive';
import { MediaLibraryTableComponent } from './media-library-table/media-library-table.component';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { SecurityGroupSecurityModel } from '../../models/security-model/security-group-security.model';
import { MediaSessionData } from './shared/media-session.data';

declare var $: any;

@Component({
    selector: 'op-media',
    templateUrl: './media.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MediaMapperService]
})
export class MediaComponent implements OnInit, OnChanges {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    @Input() mediaHashTagSelect2Data: Select2Data[];
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() ENTITY_NAME: string;
    @Input() disabled: boolean = false;

    @Output() mediaLibrarySelected = new EventEmitter<MediaViewModel[]>();
    @Output() mediaIdDeleted = new EventEmitter<String>();
    @Output() cancelTextUpload = new EventEmitter();
    @Output() saveTextImageUpload = new EventEmitter<String>();
    @Output() saveTextVideoUpload = new EventEmitter<String>();
    @Output() cancelTextSelectLibrary = new EventEmitter();
    @Output() saveTextSelectImageLibrary = new EventEmitter<String[]>();
    @Output() saveTextSelectVideoLibrary = new EventEmitter<String[]>();

    @ViewChild("mediaDetailRef") detailElement: ElementRef;
    @ViewChild("mediaRef") codeElement: ElementRef;
    @ViewChild("mediaDetailLibraryTableRef") mediaLibraryElement: ElementRef;

    @ViewChild(MediaDetailDirective) mediaDetailDirective: MediaDetailDirective;
    @ViewChild(MediaTableComponent) mediaTable: MediaTableComponent;
    @ViewChild(MediaDetailComponent) mediaDetailComponent: MediaDetailComponent;
    @ViewChild(MediaLibraryTableDirective) mediaLibraryTableDirective: MediaLibraryTableDirective;
    @ViewChild(MediaLibraryTableComponent) mediaLibraryTableComponent: MediaLibraryTableComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    private readonly DELAY_TIME = 400;
    readonly SCROLL_OFFSET_DETAIL_SINGLE = -320;
    readonly SCROLL_OFFSET_DETAIL = -760;
    readonly SCROLL_OFFSET_SEARCH = -350;
    readonly SCROLL_OFFSET_TEXT_DETAIL = 2450;
    public singleRecord: boolean = true;
    public miniMizePanel: boolean = false;
    public miniMizeDetailPanel: boolean = false;
    public isCollapsed2: boolean = false;
    public showAdd: boolean = false;
    public model = new MediaView();
    public mediaFiles: MediaView[] = new Array();
    public showBrowse: boolean = true;
    public mediaNotCompleted: boolean = false;
    public textImageUploadProcessing: boolean = false;
    public textVideoUploadProcessing: boolean = false;
    public textImageSelectLibraryProcessing: boolean = false;
    public textVideoSelectLibraryProcessing: boolean = false;
    public isCollapsedMediaLibrary: boolean = false;
    public mediaSearchCommand: MediaSearchCommandModel;
    public isMediaEmpty: boolean = true;
    public browseLibrary: boolean = false;

    public newDetailComponent: MediaDetailComponent;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    get isMoreThanOneMedias(): boolean {
        return this.mediaFiles?.length > 1;
    }

    private isSessionMode = false;
    private sessionDatas = new Array<MediaSessionData>();

    constructor(private changeDectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private uiHelper: UiHelperService,
        private mediaService: MediaService,
        private mediaMapperService: MediaMapperService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['id']) {
            this.saveSession(changes['id'].previousValue);
        }
    }

    private saveSession(previousId: string) {
        if (this.isSessionMode) {
            let session = this.sessionDatas.find(sd => sd.id == previousId);
            if (session) {
                session.mediaFiles = cloneDeep(this.mediaFiles);
            } else {
                this.sessionDatas.push({
                    id: previousId,
                    mediaFiles: cloneDeep(this.mediaFiles)
                });
            }
            if (this.isCollapsed2) {
                this.cancel();
            }
        }
    }

    add() {
        this.changeToMultiRecord().then(() => {
            this.toggleDetail();
            this.loadDetailForm();
        });
    }

    addWithSearchCommand(searchCommand: MediaSearchCommandModel) {
        this.changeToMultiRecord().then(() => {
            if (searchCommand) {
                this.model = new MediaView();
                this.model.mediaUseCode = searchCommand.mediaUseCode;
                this.model.mediaTypeCode = searchCommand.mediaTypeCode;
                this.toggleDetail();
                this.loadDetailForm(this.model);
            }
        });
    }

    addDisplayMediaLibrary(searchCommand: MediaSearchCommandModel) {
        if (!this.mediaFiles?.length) {
            this.displayMediaLibraryInSingleRecord(searchCommand)
        }
        else {
            this.displayMediaLibraryInMultiRecord(searchCommand);
        }
    }

    edit(data: MediaView) {
        this.toggleDetail();
        this.loadDetailForm(data);
    }

    public rowSelectedEdit(data: MediaView) {
        this.toggleDetail();
        this.loadDetailForm(data);
    }

    save(data: MediaView) {
        if (data) {
            if (data.textImage) {
                this.textImageUploadCompleted(data.mediaContentId);
            } else if (data.textVideo) {
                this.textVideoUploadCompleted(data.mediaContentId);
            }
            this.savedata(data);
            this.showAdd = true;
            this.displayAfterSave();
            this.validateMediaCompletedAll();
        }
    }

    saveMultipleFile(addedFiles: File[]) {
        this.mediaFiles = this.mediaMapperService.convertFilesToMediaViews(this.mediaFiles, addedFiles,
            this.languageReference$, this.mediaTypeFileType$, this.mediaTypeReference$, this.mediaUseReference$);
        this.displayAfterSaveMultiple();
        this.mediaNotCompleted = true;
        this.mediaTable.dataGrid.instance.refresh();
    }

    delete(data: MediaView) {
        this.deletedata(data);
        this.displayAfterDelete();
        this.validateMediaCompletedAll();
        if (data.mediaId) {
            this.deleteTextTranslation(data.mediaId);
        }
        else {
            this.deleteTextTranslation(data.mediaTranslationId);
        }
    }

    cancel() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.mediaFiles.length == 0) {
            this.model = {} as MediaView;
        }

        if (this.singleRecord) {
            this.mediaFiles = [];
            this.showAdd = false;
        }
        if (this.isUploadFromText()){
            this.cancelTextUploadProcess();
        }
        this.displayAfterCancel();
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    public toggleDetail() {
        this.isCollapsed2 = !this.isCollapsed2
        this.changeDectionRef.markForCheck();
        if (this.isCollapsed2) {
            this.scrollToDetail();
        } else {
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {
        this.scrollTo(this.codeElement, this.SCROLL_OFFSET_SEARCH);        
    }

    private scrollToDetail() {
        let yOffset = 0; 
        if (this.mediaFiles?.length > 1) {
            yOffset = this.SCROLL_OFFSET_DETAIL_SINGLE;
        } else {
            yOffset = this.SCROLL_OFFSET_DETAIL;
        }
        this.scrollTo(this.detailElement, yOffset);
    }

    public scrollFromTextToDetail() {
        window.scrollTo({top: this.SCROLL_OFFSET_TEXT_DETAIL, behavior: 'smooth'});
    }

    private loadDetailForm(dataEdit: MediaView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(MediaDetailComponent);

        let viewContainerRef = this.mediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: MediaDetailComponent = (<MediaDetailComponent>componentRefs.instance);

        this.newDetailComponent = component;

        this.assignInitialData(component);
        this.assignMediaModel(component, dataEdit);
        this.onSaveEvent(component);
        this.onSaveMultitpleFileEvent(component);
        this.onCancelEvent(component);
        this.onClickMediaLibraryEvent(component);
        this.onCancelTextUploadProcessingEvent(component);
        this.onClickMediaLibraryFromTextPanelEvent(component);
        this.onSaveToDBEvent(component);
        this.mediaDetailComponent = component;
    }

    private assignInitialData(component: MediaDetailComponent) {
        component.languageReference$ = this.languageReference$;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.mediaUseReference$ = this.mediaUseReference$;
        component.mediaHashTagSelect2Data = this.mediaHashTagSelect2Data;
        component.mediaTypeFileType$ = this.mediaTypeFileType$;
        component.showAdd = false;
        component.singleRecord = false;
        component.textImageUploadProcessing = this.textImageUploadProcessing;
        component.textVideoUploadProcessing = this.textVideoUploadProcessing;
        if (component.textImageUploadProcessing || component.textVideoUploadProcessing) {
            component.callFromTextPanel = true;
        }
    }

    private assignMediaModel(component: MediaDetailComponent, dataEdit: MediaView) {
        if (dataEdit != null) {
            component.model = cloneDeep(dataEdit);

            if (this.textImageSelectLibraryProcessing || this.textVideoSelectLibraryProcessing) {
                component.showBrowse = true;
            }
            else {
                component.showBrowse = false;
            }
        }
        else {
            component.showBrowse = true;
        }
    }

    private onSaveEvent(component: MediaDetailComponent) {
        component.onSave.subscribe(
            (dataSave: MediaView) => {
                this.save(dataSave);
            }
        );
    }

    private onSaveMultitpleFileEvent(component: MediaDetailComponent) {
        component.onSaveMultipleFile.subscribe(
            (addedFiles: File[]) => {
                this.saveMultipleFile(addedFiles);
            }
        );
    }

    private onCancelEvent(component: MediaDetailComponent) {
        component.onCancel.subscribe(
            response => {
                this.displayAfterCancel();
            }
        );
    }

    private onClickMediaLibraryEvent(component: MediaDetailComponent) {
        component.onClickMediaLibrary.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibrary(searchCommand);
            }
        );
    }

    private onClickMediaLibraryFromTextPanelEvent(component: MediaDetailComponent) {
        component.onClickMediaLibraryFromTextPanel.subscribe(
            (searchCommand: MediaSearchCommandModel) => {
                this.clickMediaLibraryFromTextPanel(searchCommand);
            }
        );
    }

    private onCancelTextUploadProcessingEvent(component: MediaDetailComponent) {
        component.onCancelTextUploadProcessing.subscribe(
            () => {
                this.cancelTextUploadProcess();
                this.displayAfterCancel();
            }
        );
    }

    private onSaveToDBEvent(component: MediaDetailComponent){
        component.onSaveToDB.subscribe(
            () => {
                this.saveToDB();
            }
        );
    }

    private savedata(data: MediaView) {
        let cloneData = cloneDeep(data);
        if (this.mediaFiles != null) {
            var filterIndex = this.mediaFiles.findIndex(x => x.no == cloneData.no);
            if (filterIndex == -1) {
                cloneData.no = this.mediaFiles.length + 1;
                this.mediaFiles.push(cloneData);
                this.updateModelInCondition();
            }
            else {
                this.mediaFiles[filterIndex] = cloneData;
            }
        }
        else {
            cloneData.no = 1;
            this.mediaFiles.push(cloneData);
        }
        this.mediaTable.dataGrid.instance.refresh();
    }

    private deletedata(data: MediaView) {
        let views = new Array<MediaView>();
        if (this.mediaFiles != null) {
            views = this.mediaFiles;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.mediaFiles = views;
        }
    }

    private displayAfterSave() {
        if (this.mediaFiles?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
        this.isMediaEmpty = false;
    }

    private displayAfterSaveMultiple() {
        if (!this.singleRecord) {
            this.toggleDetail();
            this.formDetailClose();
        }
        this.singleRecord = false;
        this.isMediaEmpty = false;
    }

    private displayAfterDelete() {
        if (this.mediaFiles.length == 0 || this.mediaFiles.length == 1) {
            if (this.mediaFiles.length == 1) {
                this.model = this.mediaFiles[0];
                this.showBrowse = false;
                this.showAdd = true;
            }
            else {
                this.model = new MediaView();
                this.showAdd = false;
                this.showBrowse = true;
                this.isMediaEmpty = true;
            }
            this.singleRecord = true;
        }

        if (this.isCollapsed2) {
            this.toggleDetail();
        }
    }

    private displayAfterCancel() {
        if (this.mediaFiles.length >= 1 ) {
            this.toggleDetail();
        }
        
        if (this.mediaFiles.length == 1) {
            this.singleRecord = true;
        } else if (this.mediaFiles.length == 0) {
            this.isMediaEmpty = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.mediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public fillModelToForm(mediaFileViews: MediaView[]) {
        this.clearData();
        mediaFileViews = this.getSessionValue(mediaFileViews);
        if (mediaFileViews && mediaFileViews.length > 0) {
            this.fillModelInCase(mediaFileViews);
        }
        else {
            this.clearDetailForm();
        }
        this.mediaFiles = mediaFileViews;
        if (this.mediaFiles.length > 0) {
            this.isMediaEmpty = false;
        }

        this.changeDectionRef.detectChanges();
    }

    private getSessionValue(mediaFileViews: MediaView[]) {
        if (this.isSessionMode) {
            let session = this.sessionDatas.find(sd => sd.id == this.id);
            if (session) {
                mediaFileViews = cloneDeep(session.mediaFiles);
            }
        }
        return mediaFileViews;
    }

    public saveMediaLibrary(mediaFileViews: MediaView[]) {
        if (mediaFileViews) {
            var mediaIds: string[] = new Array();
            var mediaContentIds: string[] = new Array();
            for (let data of mediaFileViews) {
                if (!this.exists(data)) {
                    this.savedata(data);
                    mediaIds.push(data.mediaId);
                }
                if (this.textImageSelectLibraryProcessing || this.textVideoSelectLibraryProcessing) {
                    if (data.mediaContentId) {
                        mediaContentIds.push(data.mediaContentId);
                    }
                }
            }
            this.showAdd = true;
            this.displayAfterSaveMediaLibrary();
            this.displayTextTranslationFromMediaLibrary(mediaIds);
            this.minimixeMediaLibraryPanel();
            this.saveBrowseCallFromTextPanel(mediaContentIds);
        }
    }

    private fillModelInCase(mediaFileViews: MediaView[]) {
        if (mediaFileViews.length == 1) {
            this.fillModelSingleRecord(mediaFileViews[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(mediaFileViews: MediaView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.showBrowse = false;
        this.model = mediaFileViews;
        this.clearDetailForm();
    }

    private clearData() {
        this.singleRecord = true;
        this.showAdd = false;
        this.model = new MediaView();
        this.mediaMapperService.setDefaultPrimaryLanguage(this.languageReference$, this.model);
        this.showBrowse = true;
    }

    public clearDetailForm() {
        if (this.mediaDetailComponent?.mediaForm) {
            this.mediaDetailComponent.mediaForm.resetForm(this.model);
        }
    }

    private displayAfterSaveMediaLibrary() {
        if (this.mediaFiles != null) {
            this.displayAfterSaveInCase();
        }
        else {
            this.displayAfterSaveNoData();
        }
    }

    private displayAfterSaveInCase() {
        if (this.mediaFiles.length > 1) {
            this.displayAfterSaveInList();
        }
        else {
            this.displayAfterSaveSingleRecord();
        }
    }

    private displayAfterSaveInList() {
        this.singleRecord = false;
        this.isMediaEmpty = false;
        this.showBrowse = false;
        this.formDetailClose();
        if (this.isCollapsed2) {
            this.toggleDetail();
        }
        else {
            this.scrollToSearch();
        }
    }

    private displayAfterSaveSingleRecord() {
        this.model = this.mediaFiles[0];
        this.singleRecord = true;
        this.isMediaEmpty = false;
        this.showBrowse = false;
        this.scrollToSearch();
        this.changeDectionRef.detectChanges();
    }

    private displayAfterSaveNoData() {
        this.singleRecord = true;
        this.isMediaEmpty = true;
        this.showBrowse = true;
    }

    private displayTextTranslationFromMediaLibrary(mediaIds: string[]) {
        if (mediaIds) {
            this.mediaService.getByIds(mediaIds)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        this.mediaLibrarySelected.emit(responses);
                    }
                )
        }
    }

    public deleteTextTranslation(mediaId: string) {
        if (mediaId) {
            this.mediaIdDeleted.emit(mediaId);
        }
    }

    private exists(textView: MediaView): boolean {
        var filter = this.mediaFiles.filter(x => x.mediaId == textView.mediaId);
        if (filter.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public validateMediaCompletedAll(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        for (let mediaFile of this.mediaFiles) {
            if (!this.mediaDataCompleted(mediaFile)) {
                this.mediaNotCompleted = true;
                return false;
            }
        }
        this.mediaNotCompleted = false;
        return true;
    }

    private mediaDataCompleted(mediaFile: MediaView): boolean {
        if (mediaFile.mediaName &&
            mediaFile.mediaHashTagIds?.length &&
            mediaFile.mediaUseCode &&
            mediaFile.mediaTypeCode) {
            return true;
        }
        else {
            return false;
        }
    }

    public clickMediaLibrary(searchCommand: MediaSearchCommandModel) {
        this.displayMediaLibrary().then(() => {
            this.loadMediaLibraryTableForm(searchCommand).then(() => {
                if (!this.mediaFiles?.length) {
                    this.scrollToSearch();
                }
                else {
                    if (this.mediaFiles.length == 1) {
                        this.uiHelper.moveScrollPosition(this.detailElement, 10);
                    }
                    else {
                        this.scrollToDetail();
                    }
                }
            });
        });
    }

    public clickMediaLibraryFromTextPanel(searchCommand: MediaSearchCommandModel) {
        this.displayMediaLibrary().then(() => {
            this.loadMediaLibraryTableForm(searchCommand);
        });
    }

    public displayMediaLibrary(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.mediaFiles?.length) {
                this.miniMizePanel = true;
            }
            else {
                this.miniMizeDetailPanel = true;
            }
            this.isCollapsedMediaLibrary = true;
            if (this.mediaDetailComponent) {
                this.mediaDetailComponent.browseLibrary = false;
            }
            resolve();
        });
    }

    public clearForm() {
        this.singleRecord = true;
        this.isCollapsed2 = false;
        this.showAdd = false;
        this.model = new MediaView();
        this.mediaFiles = [];
        this.showBrowse = true;
        this.mediaNotCompleted = false;
        this.isMediaEmpty = true;
        this.changeDectionRef.detectChanges();
    }

    public textImageUploadProcess() {
        this.focused = true;
        this.textImageUploadProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textVideoUploadProcess() {
        this.focused = true;
        this.textVideoUploadProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textImageSelectLibraryProcess() {
        this.focused = true;
        this.textImageSelectLibraryProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public textVideoSelectLibraryProcess() {
        this.focused = true;
        this.textVideoSelectLibraryProcessing = true;
        this.changeDectionRef.detectChanges();
    }

    public cancelTextUploadProcess() {
        if (!this.mediaFiles?.length) {
            this.model = new MediaView();
        }
        else if (this.mediaFiles?.length == 1) {
            this.model = this.mediaFiles[0];
        }
        if (this.singleRecord) {
            this.showAdd = false;
        }

        this.focused = false;
        this.textImageUploadProcessing = false;
        this.textVideoUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.changeDectionRef.detectChanges();
        this.cancelTextUpload.emit();
    }

    private textImageUploadCompleted(mediaConTextId: string) {
        this.focused = false;
        this.textImageUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.saveTextImageUpload.emit(mediaConTextId);
    }

    private textVideoUploadCompleted(mediaConTextId: string) {
        this.focused = false;
        this.textVideoUploadProcessing = false;
        this.resetCallFromTextPanel();
        this.saveTextVideoUpload.emit(mediaConTextId);
    }

    private resetCallFromTextPanel() {
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.callFromTextPanel = false;
        }
    }

    private loadMediaLibraryTableForm(searchCommand: MediaSearchCommandModel): Promise<void> {
        return new Promise((resolve, reject) => {
            let componentFactory = this.componentFactoryResolver
                .resolveComponentFactory(MediaLibraryTableComponent);

            let viewContainerRef = this.mediaLibraryTableDirective.viewContainerRef;
            viewContainerRef.clear();

            let componentRefs = viewContainerRef.createComponent(componentFactory);
            let component: MediaLibraryTableComponent = (<MediaLibraryTableComponent>componentRefs.instance);
            this.assignInitialDataMediaLibraryTableForm(component, searchCommand);
            this.onSaveMediaLibraryEvent(component);
            this.onCancelBrowseClickEvent(component);
            this.mediaLibraryTableComponent = component;
            resolve();
        });
    }

    private assignInitialDataMediaLibraryTableForm(component: MediaLibraryTableComponent, searchCommand: MediaSearchCommandModel) {
        component.mediaSearchCommand = searchCommand;
        component.mediaTypeReference$ = this.mediaTypeReference$;
        if (this.textImageSelectLibraryProcessing || this.textVideoSelectLibraryProcessing) {
            component.searchWithMediaType = true;
        }
    }

    private onSaveMediaLibraryEvent(component: MediaLibraryTableComponent) {
        component.onSaveMediaLibrary.subscribe(
            (dataSave: MediaView[]) => {
                this.saveMediaLibrary(dataSave);
            }
        );
    }

    private onCancelBrowseClickEvent(component: MediaLibraryTableComponent) {
        component.onCancelBrowseClick.subscribe(
            () => {
                this.cancelBrowse();
                this.formMediaLibraryTableClose();
            }
        );
    }

    public cancelBrowse() {
        this.minimixeMediaLibraryPanel();
        this.cancelBrowseCallFromTextPanel();
    }

    private minimixeMediaLibraryPanel() {
        this.isCollapsedMediaLibrary = false;
        this.miniMizePanel = false;
        this.miniMizeDetailPanel = false;
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.browseLibrary = true;
        }
    }

    private formMediaLibraryTableClose() {
        let viewContainerRef = this.mediaLibraryTableDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    private changeToMultiRecord(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.singleRecord = false;
            resolve();
        });
    }

    private displayMediaLibraryInSingleRecord(searchCommand: MediaSearchCommandModel) {
        this.model.mediaUseCode = searchCommand.mediaUseCode;
        this.model.mediaTypeCode = searchCommand.mediaTypeCode;
        this.mediaDetailComponent.callFromTextPanel = true;
        this.mediaSearchCommand = searchCommand;
        this.mediaDetailComponent.mediaSearchCommand = searchCommand;
        $("#btnBrowseMediaLibrary").click();
    }

    private displayMediaLibraryInMultiRecord(searchCommand: MediaSearchCommandModel) {
        if(this.mediaFiles.length == 1) {
            this.singleRecord = false;
            this.changeDectionRef.detectChanges();
        }
        this.model.mediaUseCode = searchCommand.mediaUseCode;
        this.model.mediaTypeCode = searchCommand.mediaTypeCode;
        this.mediaSearchCommand = searchCommand;
        this.mediaTable.mediaSearchCommand = searchCommand;
        $("#btnAddNewMediaLibraryWithSearch").click();
        setTimeout(() => {
            this.mediaSearchCommand = searchCommand;
            if(this.mediaDetailComponent){
                this.mediaDetailComponent.callFromTextPanel = true;
                this.mediaDetailComponent.mediaSearchCommand = searchCommand;
            }
            $("#btnBrowseMediaLibrary").click();
        }, this.DELAY_TIME);
    }

    private cancelBrowseCallFromTextPanel() {
        if (this.textImageSelectLibraryProcessing ||
            this.textVideoSelectLibraryProcessing) {
            this.textImageSelectLibraryProcessing = false;
            this.textVideoSelectLibraryProcessing = false;
            this.formMediaLibraryTableClose();
            this.isCollapsedMediaLibrary = false;
            this.isCollapsed2 = false;
            if (this.mediaFiles?.length) {
                this.formDetailClose();
            }
            if (this.mediaFiles.length <= 1) {
                this.mediaDetailComponent?.cancelCallFromText();
            }

            this.cancelTextSelectLibrary.emit();
        }
        else {
            if (!this.mediaFiles?.length) {
                this.scrollToSearch();
            }
            else {
                this.scrollToDetail();
            }
        }
    }

    private saveBrowseCallFromTextPanel(mediaContentIds: string[]) {
        if (this.textImageSelectLibraryProcessing ||
            this.textVideoSelectLibraryProcessing) {
            this.formMediaLibraryTableClose();
            this.isCollapsedMediaLibrary = false;
            this.isCollapsed2 = false;
            if (this.mediaFiles?.length) {
                this.formDetailClose();
            }
            if (this.textImageSelectLibraryProcessing) {
                this.saveTextSelectImageLibrary.emit(mediaContentIds);
            } else if (this.textVideoSelectLibraryProcessing) {
                this.saveTextSelectVideoLibrary.emit(mediaContentIds);
            }
            this.textImageSelectLibraryProcessing = false;
            this.textVideoSelectLibraryProcessing = false;
            if (this.mediaDetailComponent) {
                this.mediaDetailComponent.callFromTextPanel = false;
            }
        }
    }

    public checkCollapsedStatus(collapsed) {
        if (!collapsed) {
            this.cancelBrowse();
        }
    }

    public cancelCallFromText() {
        this.cancelBrowse();
        this.cancelAllCallFromText();
        if (this.mediaDetailComponent) {
            this.mediaDetailComponent.callFromTextPanel = false;
            this.mediaDetailComponent.showBrowse = true;
            this.mediaDetailComponent.browseLibrary = false;
        }

        if (this.mediaFiles?.length) {
            this.toggleDetail();
        }
        this.formDetailClose();
        this.formMediaLibraryTableClose();
    }

    private cancelAllCallFromText() {
        this.textImageSelectLibraryProcessing = false;
        this.textVideoSelectLibraryProcessing = false;
        this.textVideoUploadProcessing = false;
        this.textImageUploadProcessing = false;
    }

    public saveToDB() {
        if(!this.mediaFiles?.length || this.mediaFiles.length == 1){
            this.scrollToSearch();
        }
        else {
            this.scrollToDetail();
        }
    }

    public onBrowse() {
        if(!this.mediaFiles?.length || this.mediaFiles.length == 1){
            this.browseMediaLibrarySingleFile();
        } else {
            this.mediaDetailComponent.browseLibraryClick();
        }
    }

    private validateClickBrowse(): boolean {
        return !!(this.model.mediaUseCode);
    }

    public onSave() {
        this.isApplyBtnClicked = true;
        this.mediaDetailComponent.save(this.mediaDetailComponent.mediaForm);
    }

    public saveMediaBrowse() {
        this.mediaLibraryTableComponent.saveMediaBrowse();
    }

    public copy() {
        if (this.mediaFiles.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.mediaFiles.length == 1) {
            let copyMediaship = cloneDeep(this.mediaFiles[0]);
            this.openCopyDetailForm(copyMediaship);
            return;
        }

         if (!this.mediaTable.selected) {
            return;
        }

        let copyMediaship = cloneDeep(this.mediaTable.selected);
        this.openCopyDetailForm(copyMediaship);
    }

    private setDisplayForDataCopying() {
        this.singleRecord = false;
    }

    private openCopyDetailForm(copyMedia: MediaView) {
        this.setCopyMediashipProperty(copyMedia);
        this.toggleDetail();
        this.loadDetailForm(copyMedia);
    }

    private setCopyMediashipProperty(copyMedia: MediaView) {
        copyMedia.no = null;
    }

    public onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.toggleDetail();
        this.loadDetailForm(this.mediaTable.selected);
    }

    private isSingleRecord(): boolean {
        if (this.mediaFiles.length == 0 || this.mediaFiles.length == 1) {
            return true;
        }
        return false;
    }

    public onDelete() {
        if (this.mediaFiles.length == 1) {
            this.delete(this.model);
            return;
        }
        this.mediaTable.delete();
    }

    public onSaveNewMedia() {
        this.isApplyBtnClicked = true;
        let newDetail = this.newDetailComponent.mediaForm;
        this.newDetailComponent.save(newDetail);
    }

    private isUploadFromText() : boolean {
       return (this.textImageUploadProcessing || this.textVideoUploadProcessing);
    }

    private scrollTo(element: ElementRef, offsetNumber: number) {
        const y = element.nativeElement.getBoundingClientRect().top + window.scrollY + offsetNumber;
        window.scrollTo({top: y, behavior: 'smooth'}); 
    }

    public refresh() {
        if (this.id) {
            this.mediaService.getByEntityNameAndAssociation(this.ENTITY_NAME, this.id)
                .subscribe(
                    (responses: MediaViewModel[]) => {
                        var mediaViews = this.mediaMapperService.mediaToTextViews(responses);
                        this.fillModelToForm(mediaViews);
                    }
                )
        } else {
            this.clearForm();
        }
    }

    public activateSessionMode(enabled: boolean) {
        this.isSessionMode = enabled;
    }

    public clearSessionData() {
        this.sessionDatas = [];
    }

    public getSessionData() {
        return this.sessionDatas;
    }

    private updateModelInCondition() {
        if (this.mediaFiles.length == 1) {
            this.model = this.mediaFiles[0];
        }
    }

    private browseMediaLibrarySingleFile() {
        if (this.validateClickBrowse()) {
            this.mediaSearchCommand = this.mediaMapperService.mediaFileToSearchCommand(this.model);
            this.mediaSearchCommand.isMediaLibrary = true;
            this.browseLibrary = !this.browseLibrary;
            this.clickMediaLibrary(this.mediaSearchCommand);
        }
    }

    public getErrorMessage() {
        return this.mediaDetailComponent.getErrorMessageForm();
    }
}
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OopsCategory } from 'src/app/core/components/categories';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { ActionService } from 'src/app/core/utils/action.service';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { PricingSearchComponent } from '../pricing-search/pricing-search.component';
import { getPricingConstantById, getPricingConstantByUrl, PricingConstant } from '../shared/constants/pricing.constant';

@Component({
    selector: "op-pricing-tabs",
    templateUrl: "./pricing-tabs.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService]
})
export class PricingTabsComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly searchTab = "search";
    public readonly categoriesTab = "categories";
    public readonly dashboardTab = "dashboard";

    public selectedTab: string;

    selectedUrl: string;
    public executeFlag: boolean = true;
    public searchFilterId: string;
    public pricingSecurities$ = new BehaviorSubject<SecurityGroupSecurityModel[]>(null);
    public selectedCategory: OopsCategory;

    onCategorySelected(selectedItem: OopsCategory) {
        this.selectedUrl = selectedItem.url;
        this.selectedTab = this.searchTab;
        this.selectedCategory = selectedItem;
        this.onSelectedTabChange(this.selectedTab);
    }

    public title: string = PricingConstant.categories.title;

    private unsubscribe$ = new Subject();

    @ViewChild(PricingSearchComponent) pricingSearchComponent: PricingSearchComponent;

    constructor(
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private actionService: ActionService,
        public securityGroupService: SecurityGroupService,
        private activityStoreService: ActivityStoreService
    ) {}

    ngOnInit(): void {
        this.getSecurityGroup();
        const params = this.navigationService.getParams();
        const previousPageId = params?.previousPage;
        this.actionService.add(null , null, null);
        if (previousPageId) {
            if (previousPageId == PricingConstant.categories.id) {
                return;
            }
            const previousPage = getPricingConstantById(previousPageId);
            this.selectedUrl = previousPage?.url;

            this.selectedTab = this.searchTab;
            this.onSelectedTabChange(this.selectedTab);
            this.assignPreviousPageSecurity(params?.userSecurity);
        }
        this.executeFlag = params?.executeFlag ?? true;
        this.searchFilterId = params?.id;
        this.activityStore();
    }

    ngAfterViewInit(): void {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((actionId) => {
                switch (actionId) {
                    case ACTION_STATUS.back:
                    case ACTION_STATUS.cancel:
                        if (this.selectedTab != this.categoriesTab) {
                            this.selectedTab = this.categoriesTab;
                            this.onSelectedTabChange(this.selectedTab);
                        }
                        break;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public onSelectedTabChange(activeId: string) {
        if (activeId == this.searchTab) {
            this.selectedUrl ??= PricingConstant.rules.url;
            let pricingConstant = getPricingConstantByUrl(this.selectedUrl);
            this.title = pricingConstant?.title;
        } else {
            this.title = PricingConstant.categories.title;
            this.selectedUrl = null;
            this.clearParamsFromFavorite();
            this.actionService.add(null , null, null);
            this.activityStore();
        }
    }

    onCategoryChanged(selectedItem: OopsCategory) {
        this.selectedUrl = selectedItem.url;
        this.selectedCategory = selectedItem;
    }

    clearParamsFromFavorite() {
        this.executeFlag = true;
        this.searchFilterId = null;
    }

    private getSecurityGroup() {
        this.securityGroupService.getUserSecurityGroupSecurity()
        .subscribe(
            (response: SecurityGroupSecurityModel[]) => {
                let productSecurities = response?.filter(item => item.securityCode.startsWith('PRICE'));
                this.pricingSecurities$.next(productSecurities);
            }
        )
    }

    private assignPreviousPageSecurity(security: SecurityGroupSecurityModel) {
        setTimeout(() => {
            if (this.pricingSearchComponent) {
                this.pricingSearchComponent.userSecurity = security;
                this.pricingSearchComponent.setupActionbarSecurity();
            }
        }, 300);
        
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null,
            "PRICERULE");
    }
}
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
  selector: 'op-cashbook-content',
  templateUrl: './cashbook-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashbookContentComponent implements OnInit, OnDestroy {
  public navigateComponent;
  private _navigationSubscription: Subscription
  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.navigate("/main/cashbook/home", null);
  }

  ngOnDestroy(): void {
    if (this._navigationSubscription) {
        this._navigationSubscription.unsubscribe();
    }
  }
}
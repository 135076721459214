import { Injectable } from "@angular/core";
import { ApiServiceBase } from "../../base/api-service-base";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../authentication/auth.service";
import { Observable } from "rxjs";
import { CalendarValidityPrefixModel } from "../../models/reference-model/reference-general-model/calendar-validity-prefix.model";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CalendarValidityPrefixService extends ApiServiceBase {
    private readonly PATH = "/system/CalendarValidityPrefixReference";

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<CalendarValidityPrefixModel[]> {
        return this.httpGet<CalendarValidityPrefixModel[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }
}
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { InsightPassengerAddressModel } from "src/app/core/models/individual-model/insight-passenger-address.model";
import { AddressTypeReferenceModel, CountryReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { AddressMapperService } from "../shared/address-mapper.service";
import { AddressView } from "../shared/address-view";
import { RouteDataService } from "../shared/route-data-service";
import { select2AddressType, select2Country, select2LocationGroupOption, select2UseAddress } from "../shared/select2-configuration";

declare var $: any;

@Component({
    selector: 'op-insight-address-detail',
    templateUrl: './address-detail.component.html',
    providers: [RouteDataService]   
})
export class InsightAddressDetailComponent implements OnInit, OnChanges {
    readonly ERROR_ADDRESS_TYPE_REQUIRED = 'Address Type is required.'

    public addressTypeOption: any;
    public useAddressOption: any;
    public countryOption: any;
    public locationGroupOption: any;
    public processing: boolean = false;
    public selectedUseAddress: boolean = false;
    public locations$ = new BehaviorSubject<Select2Data[]>(null);
    
    private useAddresses: InsightPassengerAddressModel[]; 

    @Input() insightAddress = new AddressView(); 
    @Input() addressTypeReferences: AddressTypeReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];   
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() useAddressDropdown: InsightPassengerAddressModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();

    @ViewChild("locationAddressSelect2") locationAddressSelect2: ElementRef;
    @ViewChild('addressDetailForm') addressDetailForm: NgForm;

    get isNullOrganisationId() : boolean {
        if (this.insightAddress.organisationId) {
            return true;
        }

        return false;
    }

    constructor(private routeDataService: RouteDataService,
        private addressMapperService: AddressMapperService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
    }

    ngOnInit(): void {
        this.locationGroupOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();

        if (this.insightAddress.useAddressId) {
            this.useAddressChange(this.insightAddress.useAddressId);
        }
    }

    private setOptionControl() {     
        this.addressTypeOption = select2AddressType;
        this.useAddressOption = select2UseAddress;
        this.countryOption = select2Country;
        this.locationGroupOption = select2LocationGroupOption;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setSelectedLocation(this.insightAddress);
    }

    
    getInsightAddress() : AddressView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightAddress
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    public isValidForm() : boolean {
        this.processing = true;
        if(!this.addressDetailForm.valid && this.addressDetailForm.disabled != true) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false; 
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    getErrorMessageForm(): string {
        if (!this.addressDetailForm.valid) {
            return this.ERROR_ADDRESS_TYPE_REQUIRED;
        }
    }

    public setSelectedLocation(address: AddressView) {
        if (address.locationId) {
            let valueReturn: Select2Data[] = new Array();
            let data = new Select2Data(address.locationId, address.locationName);
            valueReturn.push(data);
            this.locations$.next(valueReturn);
        }
        
        this.locations$.next(null);
    }

    public addressLocationChange(value) {
        var data = $(this.locationAddressSelect2.nativeElement).select2('data');
        this.insightAddress.locationId = value;
        this.insightAddress.locationName = data[0]?.text;
        this.addressDetailForm.control.markAsTouched();
    }

    public addressTypeChange(value) {
        this.insightAddress.addressTypeCode = value;
        this.insightAddress.addressTypeName = this.addressTypeReferences.filter(it => it.addressTypeCode == value)[0]?.addressTypeName ?? null;
    }

    public addressCountryChange(value) {
        this.insightAddress.countryCode = value;
        this.insightAddress.countryName = this.countryReferences.filter(it => it.countryCode == value)[0]?.countryName ?? null;
    }

    public useAddressChange(value) {
        if (value) {
            this.useAddresses = this.useAddressDropdown;
            var addressRef = this.useAddresses.filter(it => it.addressId == value)[0];
            this.insightAddress.addressLine1 = addressRef.addressLine1;
            this.insightAddress.addressLine2 = addressRef.addressLine2;
            this.insightAddress.state = addressRef.state;
            this.insightAddress.postalCode = addressRef.postalCode;
            this.insightAddress.province = addressRef.province;
            this.insightAddress.city = addressRef.city;
            this.insightAddress.countryCode = addressRef.countryCode;
            this.insightAddress.countryName = addressRef.countryName;
            this.insightAddress.locationId = addressRef.locationId;
            this.insightAddress.locationName = addressRef.locationName;
            this.insightAddress.locationDataSave = this.addressMapperService.getLocationSave(addressRef.locationId, addressRef.locationName);
            this.insightAddress.useAddressId = addressRef.useAddressId ?? addressRef?.addressId;

            this.selectedUseAddress = true;
        }
        else {
            this.insightAddress.useAddressId = null;
            this.selectedUseAddress = false;
        }
    }

    disableEditUseAddress() {
        if (this.selectedUseAddress || this.insightAddress.organisationId) {
            return true;
        }
        return false;
    }
}
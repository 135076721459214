import { UntypedFormControl, Validators } from "@angular/forms";

export const FormOption = {
    no: new UntypedFormControl(null),
    vehicleConfigurationCompartmentPropertyId: new UntypedFormControl(null),
    vehicleConfigurationCompartmentId: new UntypedFormControl(null),
    group: new UntypedFormControl(null, Validators.required),
    property: new UntypedFormControl(null, Validators.required),
    product: new UntypedFormControl({ value: null, disabled: true }),
    byrow: new UntypedFormControl(null),
    rowfrom: new UntypedFormControl(null, Validators.required),
    rowto: new UntypedFormControl(null, Validators.required),
    column: new UntypedFormControl(null, Validators.required),
    maxvalue: new UntypedFormControl(null),
    baynumber: new UntypedFormControl(null),
    status: new UntypedFormControl(null, Validators.required)
}
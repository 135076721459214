import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { OrganisationRoleReferenceModel } from "../../models/organisation-model";

@Injectable({
    providedIn: 'root'
})
export class OrganisationRoleReferenceService extends ApiServiceBase {
    private readonly ORGANISATION_PATH = "/organisation"
    constructor( 
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getAllActive(): Observable<OrganisationRoleReferenceModel[]> {
      return this.httpGet<any>(this.ORGANISATION_PATH + "/organisationRoleReferences", null)
        .pipe(
          map(response => {
            return response.body;
          })
        );
  }

    public searchOrganisationRole(organisationRoleCode: string): Observable<OrganisationRoleReferenceModel[]> {
        let params = new HttpParams()
          .set("code", organisationRoleCode);
    
        return this.httpGet<any>(this.ORGANISATION_PATH + "/organisationRoleReferences", params)
          .pipe(
            map(response => {
              return response.body;
            })
          );
    }
}
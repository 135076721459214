export class ProductInventoryAttributeTypeViewModel {
    public productInventoryAttributeTypeId: string;
    public productAttributeTypeCode: string;
    public productAttributeTypeName: string;
    public displaySequence: number;
    public inheritInventoryAttribute: boolean;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentInvoiceTypeSelectionComponent } from './document-invoice-type-selection.component';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';

@NgModule({
    declarations: [
        DocumentInvoiceTypeSelectionComponent
    ],
    imports: [
        CommonModule,
        Select2Module
    ],
    exports: [
        DocumentInvoiceTypeSelectionComponent
    ]
})
export class DocumentInvoiceTypeSelectionModule { }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { SecurityLevel2TableModel } from '../shared/security-level2-table/security-level2-teble.model';
import { SecurityAuditItems } from './security-audits-items';
import { takeUntil } from "rxjs/operators";
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-security-audits',
    templateUrl: './security-audits.component.html',
    providers: [ActionbarService],
})
export class SecurityAuditsComponent implements OnInit, AfterViewInit {
    public securityAuditItems: SecurityLevel2TableModel[] = SecurityAuditItems;
    private unsubscribe$ = new Subject();
    public selectedTab: string;

    actionBarHandler = new ActionBarHandlerModel(new NewButtonModel(), new CopyButtonModel(), new RefreshButtonModel());
    constructor(private actionbarService: ActionbarService,
        private navigationService: NavigationService) {}

    ngOnInit(): void {
        let param = this.navigationService.getParams();
        this.selectedTab = param?.selectedTab;
    }

    ngAfterViewInit(): void {
        this.actionBarHandler.get(ACTION_STATUS.new).disable();
        this.actionBarHandler.get(ACTION_STATUS.copy).disable();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
        }
    }

    back() {
        this.navigationService.navigate('main/settings/security', 'Security Management');
    }

}

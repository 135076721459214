export const environment = {
  production: false,
  apiUrl: 'https://srvfab.dev.oops.cloud',
  cookiesExpiryTime: 2592000000,
  identityUrl: 'https://srvfab.dev.oops.cloud:8906/connect/token',
  authentication: {
    clientId: 'oops_solution',
    clientSecret: 'oopssolutionsecret',
    grantType: 'password'
  },
  googleTranslationURL: 'https://translation.googleapis.com/language/translate/v2?key=', 
  googleTranslationAPIKey: 'AIzaSyD2gi6yu2t4Rdtf67uVPmKDiRwMIqFaMKE',
  azureStorageURL: 'https://devoopssolution.blob.core.windows.net/',
  gatewayProvider: {
    code: "DATATRANS"
  }
};

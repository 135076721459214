import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SecurityGroupComponent } from './security-group.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SecurityGroupSearchComponent } from './security-group-search/security-group-search.component';
import { SecurityGroupSearchConditionComponent } from './security-group-search/security-group-search-condition/security-group-search-condition.component';
import { SecurityGroupSearchTableComponent } from './security-group-search/security-group-search-table/security-group-search-table.component';
import { SecurityLevel2TableModule } from '../shared/security-level2-table/security-level2-table.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {
    DxCheckBoxModule,
  DxDataGridModule,
  DxPopoverModule,
  DxTemplateModule,
} from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorModule } from 'src/app/shared/ui/forms/inputs/status-color/status-color.module';
import { SecurityGroupDetailComponent } from './security-group-detail/security-group-detail.component';
import { SecurityGroupDetailFormComponent } from './security-group-detail/security-group-detail-form/security-group-detail-form.component';
import { CounterTextModule } from 'src/app/core/pipes/counter-texts/counter-text.module';
import { GroupSecurityCodeTableComponent } from './security-group-detail/group-security-code-table/group-security-code-table.component';
import { GroupSecurityCodeSettingComponent } from './security-group-detail/group-security-code-setting/group-security-code-setting.component';
import { SecurityGroupTreeComponent } from './security-group-detail/security-group-tree/security-group-tree.component';
import { OrganisationTypeComponent } from '../security-group/security-group-detail/organisation-type/organisation-type.component';
import { TreeModule } from 'primeng/tree';
import { AngularResizeEventModule } from 'angular-resize-event-package';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';

@NgModule({
  declarations: [
    GroupSecurityCodeSettingComponent,
    GroupSecurityCodeTableComponent,
    SecurityGroupComponent,
    SecurityGroupDetailComponent,
    SecurityGroupDetailFormComponent,
    SecurityGroupSearchComponent,
    SecurityGroupSearchConditionComponent,
    SecurityGroupSearchTableComponent,
    SecurityGroupTreeComponent,
    OrganisationTypeComponent
  ],
  imports: [
    AngularResizeEventModule,
    BsDropdownModule,
    ButtonsModule,
    ClipboardModule,
    CollapseModule,
    CommonModule,
    CounterTextModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxPopoverModule,
    DxTemplateModule,
    FocusingModule,
    FormsModule,
    FreezingTabModule,
    ItemsFoundModule,
    MatIconModule,
    MatMenuModule,
    NavigationIndexModule,
    NgbNavModule,
    PanelsModule,
    ReactiveFormsModule,
    RouterModule,
    SecurityLevel2TableModule,
    Select2Module,
    StatusColorModule,
    TreeModule,
    UiModule,
  ],
  exports: [
    SecurityGroupComponent,
    SecurityGroupSearchComponent
  ]
})
export class SecurityGroupModule { }

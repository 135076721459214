export const ACTION_STATUS = {
    back: 'BACK',
    copy: 'COPY',
    new: 'NEW',
    refresh: 'REFRESH',
    save: 'SAVE',
    saveAs: 'SAVEAS',
    cancel: 'CANCEL',
    edit: 'EDIT',
    delete: 'DELETE',
    process: 'PROCESS',
    export: 'EXPORT'
}

export const ACTION_HANDLER_STATUS = {
    none: 'NONE',
    noShow: 'NOSHOW',
    selected: 'SELECTED',
    new: 'NEW',
    editing: 'EDITING',
    editingNew: 'EDITINGNEW',
    refreshOnly: 'REFRESHONLY'
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from "../oops-select2/select2.module";
import { StateColorDropdownComponent } from "./state-color-dropdown.component";

@NgModule({
    imports: [
        CommonModule, 
        Select2Module, 
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [StateColorDropdownComponent],
    exports: [StateColorDropdownComponent]
})
export class StateColorDropdownModule {
}
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
} from '@angular/core';

import {
    Subscriber
} from 'rxjs';

import { OrderDetailServices } from 'src/app/core/services/order-services';
import {
    OrderDetailProductListView,
    OrdersMapperService,
} from '../../../shared';

import { OrderDetailProductListBase } from './order-detail-product-list-base';
import { OrderDetailProductList } from './order-detail-product-list';
import { OrderProductModel } from 'src/app/core/models/order-model';
import { OrderSalesStatusCode } from 'src/app/core/constants/order-constants';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-product-list',
    templateUrl: './order-detail-product-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailProductListComponent extends OrderDetailProductListBase implements OrderDetailProductList, OnDestroy {

    public orderProductListViews: OrderDetailProductListView[]
        = new Array<OrderDetailProductListView>();
        
    constructor(changeDetection: ChangeDetectorRef,
        orderDetailService: OrderDetailServices,
         mapperService: OrdersMapperService) {
        
        super(changeDetection, orderDetailService, mapperService);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public override processServiceCallResult(orderProducts: OrderProductModel[], observer: Subscriber<boolean>) {
        
        this.orderProductListViews
            = this.mapperService.toOrderDetailProductListViews(orderProducts);
        this.orderProductListViews = this.filterShowOnlyConfirmOrderProductViews(this.orderProductListViews);
        this.childLoadCompleted.emit({
            recordCount: this.countRecords(orderProducts)
        });
        this.fillNumberOfItems(this.orderProductListViews)
        this.checkCollape();
        this.changeDetection.detectChanges();
        observer.next(true);
        observer.complete();
    }

    public bindProductData(orderProducts: OrderProductModel[]) {
        this.orderProductListViews
            = this.mapperService.toOrderDetailProductListViews(orderProducts);
        this.childLoadCompleted.emit({
            recordCount: this.countRecords(orderProducts)
        });

        this.checkCollape();
        this.changeDetection.detectChanges();
    }

    private checkCollape() {
        if (!this.orderProductListViews || this.orderProductListViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    private filterShowOnlyConfirmOrderProductViews(orderProducts: OrderDetailProductListView[]) {

        if (!orderProducts) return null;

        if (!this.orderHeaderData || this.orderHeaderData.orderSalesStatusCode == OrderSalesStatusCode.Cancel) {
            return orderProducts;
        }
        
        return orderProducts
            .filter(x => 
                x.orderSalesStatusCode != OrderSalesStatusCode.Cancel && 
                x.orderSalesStatusCode != OrderSalesStatusCode.Exemption
            );
    }
}

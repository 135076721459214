import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { SettingsCategoriesComponent } from "./settings-categories/settings-categories.component";
import { SettingsDashboardComponent } from "./settings-dashboard/settings-dashboard.component";
import { SettingsManagementContentComponent } from "./settings-management-content.component";
import { SettingsSearchComponent } from "./settings-search/settings-search.component";
import { SettingsTabsComponent } from "./settings-tabs/settings-tabs.component";
import { DynamicRoutingModule } from '../../../shared/utils/navigation';
import { UiModule } from "src/app/shared/ui/ui.module";
import { AttributeContentModule } from "../settings-categories-content/attribute/attribute-content/attribute-content.module";
import { DocumentDistributionContentModule } from "../settings-categories-content/document-distribution/document-distribution-content/document-distribution-content.module";

@NgModule({
    declarations: [
        SettingsCategoriesComponent,
        SettingsDashboardComponent,
        SettingsManagementContentComponent,
        SettingsTabsComponent,
        SettingsSearchComponent
    ],
    imports: [
        AttributeContentModule,
        CommonModule,
        DocumentDistributionContentModule,
        DynamicRoutingModule,
        NgbNavModule,
        RouterModule,
        NgbModule,
        FormsModule,
        PanelsModule,
        OopsCategoriesModule,
        DxDataGridModule,
        Select2Module,
        MatIconModule,
        MatMenuModule,
        FocusingModule,
        DxTemplateModule,
        CollapseModule,
        ReactiveFormsModule,
        ButtonsModule,
        DxPopoverModule,
        UiModule
    ],
    exports: [
        SettingsManagementContentComponent
    ]
})
export class SettingsManagementContentModule { }
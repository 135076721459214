import { Injectable } from "@angular/core";
import { InsightOrderSearchModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search.model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { OrderSearchView } from "./views/order-search-view";

@Injectable()
export class OrdersMapperService {
    readonly DATE_WITH_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

    constructor(private dateConverterService: DateConverterService) {

    }

    public toOrderSearchViews(orderModels: InsightOrderSearchModel[]): OrderSearchView[] {
        if (!orderModels) {
            return null;
        }
        let views: OrderSearchView[] = [];
        let index = 1;
        for (let orderModel of orderModels) {
            views.push(this.toOrderSearchView(orderModel, index));
            index++;
        }
        return views;
    }

    public toOrderSearchView(orderModel: InsightOrderSearchModel, index: number): OrderSearchView {
        return {
            no: index,
            individualId: orderModel.individualId,
            orderId: orderModel.orderId,
            patnerOrderId: orderModel.patnerOrderId,
            orderNumber: orderModel.orderNumber,
            bookingReference: orderModel.bookingReference,
            orderDate: this.dateConverterService.toDateFormat(orderModel.orderDate),
            serviceDate: this.dateConverterService.toDateFormat(orderModel.serviceDate),
            orderNumberPOS: orderModel.orderNumberPOS,
            organisationCallName: orderModel.organisationCallName,
            orderSalesStatusName: orderModel.orderSalesStatusName,
            orderSalesStatusCode: orderModel.orderSalesStatusCode,
            orderDeliveryStatusName: orderModel.orderDeliveryStatusName,
            orderDeliveryStatusCode: orderModel.orderDeliveryStatusCode,
            orderPaymentStatusName: orderModel.orderPaymentStatusName,
            orderPaymentStatusCode: orderModel.orderPaymentStatusCode,
            currencyCode: orderModel.currencyCode,
            totalAmount: orderModel.totalAmount,
            paidAmount: orderModel.paidAmount,
            outstandingAmount: orderModel.outstandingAmount,
            unit: orderModel.unit,
            orderType: orderModel.orderType,
            arrivalLocationCode: orderModel.arrivalLocationCode,
            departureLocationCode: orderModel.departureLocationCode,
            productName: orderModel.productName,
            groupName: orderModel.groupName,
            individualName: orderModel.individualName,
        };
    }
}
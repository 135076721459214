import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { OopsCategoryContextMenu } from './categories-context-menu.model';

@Component({
    selector: 'categories-context-menu',
    templateUrl: './categories-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesContextMenuComponent implements OnInit {

    private defaultData: OopsCategoryContextMenu[] = [
        {
            name: 'Display',
            actionId: 'DISPLAY'
        },
        {
            name: 'Group',
            actionId: 'GROUP'
        },
        {
            name: 'Search',
            actionId: 'SEARCH'
        },
        {
            name: 'Dashboard',
            actionId: 'DASHBOARD'
        },
        {
            name: 'Setting',
            actionId: 'SETTING'
        },
    ]
    @Input() data: OopsCategoryContextMenu[];
    @Output()
    onSelectMessageLogRule: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit(): void {
        if (!this.data) {
            this.data = this.defaultData;
        }
    }

    public showContextMenu(): boolean {
        return false;
    }

    public onMenuClick(actionId: string) {
        this.onSelectMessageLogRule.emit(actionId);
    }
    public itemDisable(item: OopsCategoryContextMenu): boolean {
        return item.disable ?? false
    }
}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../../authentication/auth.service';
import { ProductIrregularityViewModel } from '../../models/product-model/operating-product-model';

@Injectable({
    providedIn: 'root'
})
export class ProductIrregularityService extends ApiServiceBase {
    private readonly PATH = "/product/productirregularities"
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public get(operatingProductId): Observable<ProductIrregularityViewModel[]> {
        return this.httpGet<ProductIrregularityViewModel[]>(this.PATH + '/' + operatingProductId , null)
            .pipe(
                map(res => res.body)
            )
    }
}
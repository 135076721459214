import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationItems } from 'src/app/app.navigation';
import { navigationFilter, selectFilter } from 'src/app/store/navigation';

import * as NavigationActions from '../../../store/navigation/navigation.actions';

@Component({
  selector: 'smart-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  vm$ = this.store.select(selectFilter);

  constructor(private store: Store<any>) {}
}

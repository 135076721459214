import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event-package';
import { UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { select2DefaultOption, select2DefaultWithSearchBox } from '../../voucher-search/voucher-search-condition/select2-configuration';
import { VoucherViewModel } from 'src/app/core/models/product-model/voucher-model/voucher-view.model';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-voucher-general',
    templateUrl: './voucher-general.component.html'
})
export class VoucherGeneralComponent extends OopsComponentFormBase {
    private readonly VOUCHER_CODE = 'VOUCHER';
    private readonly ACTIVE_STATUS = 'A';
    private readonly DATA_USAGETYPE = 'DATA';
    public readonly productGroupFormName = 'productGroupCode';
    public readonly errorRequiredVoucherName = 'Voucher Name is required.';
    public readonly errorRequiredProductGroup = 'Product Group is required.';
    public readonly errorRequiredProdutType = 'Product Type is required.';
    public readonly errorRequiredStatus = 'Status is required.';
    public readonly errorRequiredUsageType = 'Usage Type id required.';
    public focusing: boolean = false;
    public saveAction: boolean = false;
    public select2Option = select2DefaultOption;
    public select2WithSearch = select2DefaultWithSearchBox;

    public productTypeOptions: ProductTypeGroupModel[];

    @Input() newVoucher: boolean = false;
    @Input() newFromParent: boolean = false;
    @Input() productCategoryReferences: ProductCategoryReferenceModel[];
    @Input() productGroupReferences: ProductGroupReferenceModel[];
    @Input() productTypeGroups: ProductTypeGroupModel[];
    @Input() usageTypeReferences: UsageTypeReferenceModel[];
    @Output() onPanelHeightChange = new EventEmitter<number>();
    @Output() onVoucherNameChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder) {
            super(formBuilder);
        }

    public initForm() {
        this.formSetup();
    }

    private formSetup() {
        this.formGroup = this.formBuilder.group({
            productName: [null, [Validators.required, Validators.maxLength(60)]],
            productCategoryCode: [{ value: this.VOUCHER_CODE, disabled: true }],
            productGroupCode: [null, Validators.required],
            productTypeCode: [null, Validators.required],
            productDescription: [null],
            usageTypeCode: [this.DATA_USAGETYPE, Validators.required],
            statusCode: [this.ACTIVE_STATUS, Validators.required],
            commitDateTime: [{ value: null, disabled: true }],
            commitBy: [{ value: null, disabled: true }],
        });
        this.setFormControlDisable();
    }

    onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        }
    }

    public voucherNameChange(voucherName: string) {
        this.onVoucherNameChange.emit(voucherName);
    }

    public getProductTypeByProductGroup(productGroupCode: string) {
        this.productTypeOptions = this.productTypeGroups?.filter(item => item.productGroupCode == productGroupCode);
        this.changeDetector.detectChanges();
    }

    public onValueChange(formControlName: string, value: string | string[]) {
        this.formGroup.get(formControlName).patchValue(value);
        if (formControlName == this.productGroupFormName) {
            this.getProductTypeByProductGroup(value as string);
        }
    }

    public fillValueToForm(voucher: VoucherViewModel) {
        this.getProductTypeByProductGroup(voucher.productGroupCode);
        this.formGroup.patchValue({
            productName: voucher.productName,
            productGroupCode: voucher.productGroupCode,
            productTypeCode: voucher.productTypeCode,
            productDescription: voucher.productDescription,
            usageTypeCode: voucher.usageTypeCode,
            statusCode: voucher.statusCode,
            commitDateTime: voucher.commitDateTime,
            commitBy: voucher?.commitFirstname + voucher?.commitLastname
        })
        this.setFormControlDisable();
        this.changeDetector.detectChanges();
    }

    public clearGeneralInfo() {
        this.formSetup();
    }

    public validate(): boolean {
        this.startProcessing();
        if (!this.formGroup.valid) {
            return false;
        }
        this.completeProcessing();
        return true;
    }

    public getErrorMessage(): string {
        if (!this.formGroup.get('productName').valid) {
            return this.errorRequiredVoucherName;
        } else if (!this.formGroup.get('productGroupCode').valid) {
            return this.errorRequiredProductGroup;
        } else if (!this.formGroup.get('productTypeCode').valid) {
            return this.errorRequiredProdutType;
        } else if (!this.formGroup.get('statusCode').valid) {
            return this.errorRequiredStatus;
        } else if (!this.formGroup.get('usageTypeCode').valid) {
            return this.errorRequiredUsageType;
        }
    }

    public fillFormToVoucherViewModel(voucher: VoucherViewModel) {
        let formValue = this.formGroup.getRawValue();
        voucher.productName = formValue.productName;
        voucher.productGroupCode = formValue.productGroupCode;
        voucher.productTypeCode = formValue.productTypeCode;
        voucher.productDescription = formValue.productDescription;
        voucher.usageTypeCode = formValue.usageTypeCode;
        voucher.statusCode = formValue.statusCode;
    }

    private setFormControlDisable() {
        if (!this.newVoucher || this.newFromParent) {
            this.formGroup.get('productGroupCode').disable();
            this.formGroup.get('productTypeCode').disable();
            return;
        }
            this.formGroup.get('productGroupCode').enable();
            this.formGroup.get('productTypeCode').enable();
    }

    public createNewChildGeneralInfo() {
        this.formGroup.patchValue({
            productName: null,
            productDescription: null,
            usageTypeCode: this.DATA_USAGETYPE,
            statusCode: this.ACTIVE_STATUS,
            commitDateTime: null,
            commitBy: null
        })
    }
}

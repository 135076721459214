import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleRouteView extends PriceRuleConditionBase {
    public priceRuleRouteId: string;
    public originLocationId: string;
    public destinationLocationId: string;
    public displaySequence: number;
    public originLocationPreSelected$ = new BehaviorSubject<Select2Data>(null);
    public destinationLocationPreSelected$ = new BehaviorSubject<Select2Data>(null);
}
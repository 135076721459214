import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { SalesBucketReferenceModel, ServiceCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ServiceReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/service-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ExportButtonGroupComponent } from 'src/app/shared/ui/export-button-group/export-button-group.component';
import { AttributeChoiceView } from '../../../shared/view/attribute-choice.view';

@Component({
    selector: 'op-attribute-choice-table',
    templateUrl: './attribute-choice-table.component.html'
})
export class AttributeChoiceTableComponent implements AfterViewInit {
    @Input() attributeChoices: AttributeChoiceView[];
    @Input() serviceCategoryReference: ServiceCategoryReferenceModel[];
    @Input() serviceReference: ServiceReferenceModel[];
    @Input() salesBucketReference: SalesBucketReferenceModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() domainAttributeFlag: boolean;
    @Output() onClick = new EventEmitter<AttributeChoiceView>();
    @Output() onDoubleClick = new EventEmitter<AttributeChoiceView>();
    @ViewChild('dataGridAttributeChoice', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    public disableEdit: boolean = false;
    public disableAdd: boolean = false;
    public itemSelected = false;
    public selectedItem: AttributeChoiceView;
    public rows = 0;
    public exportData: any;
    public header: string[];

    constructor(public changeDetectionRef: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
        this.assignFlagToExportbuttonGroup();
    }

    public click(e) {
        this.selectedItem = e.data;
        this.onClick.emit(this.selectedItem);
    }

    public doubleClick(e) {
        if (this.domainAttributeFlag == false && this.userSecurity.editFlag == true) {
            this.selectedItem = e.data;
            this.onDoubleClick.emit(this.selectedItem);
        } 
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData().done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource.store().load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Code: dat.attributeChoiceCode,
                Value: dat.attributeChoiceValue,
                Name: dat.attributeChoiceName,
                Service: dat.specialServiceName,
                AalesBucket: dat.salesBucketName,
                ServiceCategory: dat.serviceCategoryName,
                Default: dat.defaultFlag
            }
        ));
    }

    getServiceCategoryName = (row: AttributeChoiceView) => {
        return this.serviceCategoryReference?.filter(item => item.serviceCategoryCode == row.serviceCategoryCode)[0]?.serviceCategoryName ?? row.serviceCategoryCode;
    }

    getServiceName = (row: AttributeChoiceView) => {
        return this.serviceReference?.filter(item => item.serviceCode == row.specialServiceCode)[0]?.serviceName ?? row.specialServiceCode;
    }

    getSalesBucketName = (row: AttributeChoiceView) => {
        return this.salesBucketReference?.filter(item => item.salesBucketCode == row.salesBucketCode)[0]?.salesBucketName ?? row.salesBucketCode;
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    private assignFlagToExportbuttonGroup() {
        this.exportButtonGroup.copyToClipboardFlag = this.userSecurity?.copyToClipboardFlag;
        this.exportButtonGroup.printFlag = this.userSecurity?.printFlag;
        this.exportButtonGroup.exportFlag = this.userSecurity?.exportFlag;
    }

    public refreshTable() {
        this.dataGrid.instance.refresh();
    }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { ExchangeRateModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateService extends ApiServiceBase {
    private readonly PATH = '/system/exchangerates';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getByBaseCurrencyCode(baseCurrencyCode: string): Observable<ExchangeRateModel[]> {
        return this.httpGet<ExchangeRateModel[]>(this.PATH + '/basecurrencycode/' + baseCurrencyCode, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public save(command: ExchangeRateModel[])  {
        return this.httpPut<ExchangeRateModel[]>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }
}

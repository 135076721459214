import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { CommentView } from "../shared/comment.view";

@Component({
    selector: 'op-comment-list',
    templateUrl: './comment-list.component.html',
    providers: []
})
export class CommentListComponent implements AfterViewInit {
    public readonly EXPORT_FILE_NAME = "Comment";
    @Input() id: string;
    @Input() commentViews: CommentView[] = new Array();
    @Output() new = new EventEmitter();
    @Output() edit = new EventEmitter<CommentView>();
    @Output() delete = new EventEmitter<CommentView>();
    @Output() copy = new EventEmitter<CommentView>();
    @Output() rowSelected = new EventEmitter<CommentView>();
    @Output() refresh = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    focusing: boolean = false;
    selectedItem: CommentView;
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };
    
    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    onNew() {
        this.new.emit();
    }

    onEdit() {
        if (this.selectedItem) {
            this.edit.emit(this.selectedItem);
        }
    }

    onDelete() {
        if (this.selectedItem) {
            this.delete.emit(this.selectedItem);
        }
    }

    onCopy() {
        if (this.selectedItem) {
            this.copy.emit(this.selectedItem);
        }
    }

    onRefresh() {
        this.refresh.emit();
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
        this.rowSelected.emit(this.selectedItem);
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Type: data.commentTypeName,
            Classification: data.classificationName,
            Comment: data.commentText
        };
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { InvoiceIndividualSearchModel } from '../../models/individual-model/invoice-individual-search.model';

@Injectable({
    providedIn: 'root'
})
export class InvoiceIndividualService extends ApiServiceBase {
    private readonly PATH = '/individual/insight/invoiceIndividual';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
            super(httpClient, authService)
        }

    public searchInvoiceIndividual(condition: InvoiceIndividualSearchModel): Observable<InvoiceIndividualSearchModel[]> {
        return this.httpPost<InvoiceIndividualSearchModel[]>(this.PATH + "/search/", condition)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}

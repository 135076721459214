import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleFilter } from 'src/app/store/navigation';
import { AuthService } from '../../../core/authentication/auth.service';

@Component({
  selector: 'smart-nav-info-card',
  templateUrl: './nav-info-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavInfoCardComponent implements OnInit {

  private user: string;

  constructor(private store: Store<any>,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.user = this.extractUserInformation();
  }

  private extractUserInformation(): string {
    let user = this.authService.getUserInformation();
    return user.titleCode + ' ' + user.firstname + ' ' + user.lastname;
  }

  toggleFilter($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleFilter());

  }
}

import { Component, ChangeDetectionStrategy, OnInit, Input, ElementRef, ViewChild, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import * as settings from 'src/app/store/settings';
import { UiHelperService } from 'src/app/core/utils/ui-helper.service';

import { AuthService } from '../../authentication/auth.service';
import { MediaService } from '../../services/media-services';
import { MediaFilePreviewService } from '../../services/media-services/media-file-preview-service';
import { MediaMapperService } from '../media/shared/media-mapper.service';

import { TranslationMediaDetailComponent } from './translation-media-detail/translation-media-detail.component';
import { TranslationMediaDetailDirective } from './translation-media-detail/translation-media-detail.directive';

import { LanguageReferenceModel, MediaTypeFileTypeModel, MediaTypeReferenceModel } from '../../models/reference-model/reference-media-model';
import { TranslationMediaView } from './shared/translation-media.view';
import { TranslationMediaChildView } from './shared/translation-media-child.view';

declare var $: any;

@Component({
    selector: 'op-translation-media',
    templateUrl: './translation-media.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        MediaMapperService
    ]
})
export class TranslationMediaComponent implements OnInit {

    @Input() id: string;
    @Input() focused: boolean = false;
    @Input() classIcon: string;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    @Input() panelName = 'MEDIA TRANSLATIONS';

    @ViewChild("codeRef") codeElement: ElementRef;
    @ViewChild("codeDetailRef") detailElement: ElementRef;
    @ViewChild(TranslationMediaDetailDirective) translationMediaDetailDirective: TranslationMediaDetailDirective;

    languageDataSourceStorage: any;
    settings: any;
    public mediaTranslationFiles: TranslationMediaView[];
    public mediaTranslationChildFiles: TranslationMediaChildView[];
    private ORG_ID = this.autService.getSelectedOrganisation();
    public isCollapsed2: boolean = false;
    public focusing: boolean = false;

    constructor(
        private changeDectionRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private uiHelper: UiHelperService,
        private store: Store<any>,
        private mediaService: MediaService,
        private mediaMapperService: MediaMapperService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private autService: AuthService,
        private domSanitizer: DomSanitizer) {
        this.languageDataSourceStorage = [];
    }

    ngOnInit(): void {
    }

    getDetail(key) {
        let item = this.languageDataSourceStorage.find((i) => i.key === key);
        if (!item) {
            item = {
                key: key,
                dataSourceInstance: this.createDataSource(key, this.mediaTranslationChildFiles)
            };
            this.languageDataSourceStorage.push(item)
        }
        return item.dataSourceInstance;
    }

    gotoDetailPanel(e) {
        try {
            this.toggleDetail();
            var data = new TranslationMediaChildView();
            data.no = e.data.no;
            data.mediaId = e.data.mediaId;
            data.mediaContentId = e.data.mediaContentId;
            data.mediaName = e.data.mediaName;
            data.mediaTypeCode = e.data.mediaTypeCode;
            data.languageCode = e.data.languageCode;
            data.languageName = e.data.languageName;
            data.noFile = e.data.noFile;
            data.mediaFile = e.data.mediaFile;
            data.mediaFileBase64 = e.data.mediaFileBase64;
            data.mediaFilePreview = e.data.mediaFilePreview;
            data.mediaFilePreviewBase64 = e.data.mediaFilePreviewBase64;
            this.loadDetailForm(data);
        }
        catch (err) {
            console.log(err);
        }
    }

    clearClick(e) {
        e.data.mediaFile = null;
        e.data.mediaFileBase64 = null;
        e.data.mediaFilePreview = null;
        e.data.mediaFilePreviewBase64 = null;
        e.data.noFile = true;
        e.component.refresh();
    }

    public toggleDetail() {
        this.isCollapsed2 = !this.isCollapsed2
        this.changeDectionRef.markForCheck();
        if (this.isCollapsed2) {
            this.scrollToDetail();
        } else {
            this.formDetailClose();
            this.scrollToSearch();
        }
    }

    private scrollToSearch() {
        this.store.select(settings.selectSettingsState)
            .subscribe(
                (response) => {
                    if (response.fixedHeader) {
                        this.uiHelper.moveScrollPosition(this.codeElement, 80);
                    } else {
                        this.codeElement.nativeElement.scrollIntoView({ block: "start" });
                    }
                }
            );
    }

    private scrollToDetail() {
        this.store.select(settings.selectSettingsState)
            .subscribe(
                (response) => {
                    if (response.fixedHeader) {
                        this.uiHelper.moveScrollPosition(this.detailElement, 80);

                    } else {
                        this.detailElement.nativeElement.scrollIntoView({ block: "start" });
                    }
                }
            );
    }

    private formDetailClose() {
        let viewContainerRef = this.translationMediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    private loadDetailForm(dataEdit: TranslationMediaChildView) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(TranslationMediaDetailComponent);

        let viewContainerRef = this.translationMediaDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: TranslationMediaDetailComponent = (<TranslationMediaDetailComponent>componentRefs.instance);
        component.model = dataEdit;
        component.languageReference$.next(this.createLanguageReference(dataEdit));
        component.mediaTypeReference$ = this.mediaTypeReference$;
        component.mediaTypeFileType$ = this.mediaTypeFileType$;
        component.onSave.subscribe(
            (dataSave: TranslationMediaChildView) => {
                this.save(dataSave);
            }
        );
        component.onCancel.subscribe(
            response => {
                this.toggleDetail();
            }
        );
    }

    getSettingsGlobalFontSize() {
        this.settings = JSON.parse(localStorage.getItem('settings'));
        if (this.settings != null) {
            return this.settings.globalFontSize;
        }
        return null;
    }

    public fillModelToForm(mediaTranslationFileViews: TranslationMediaView[], mediaTranslationChildViews: TranslationMediaChildView[]) {
        this.mediaTranslationFiles = mediaTranslationFileViews;
        this.mediaTranslationChildFiles = mediaTranslationChildViews;
        this.reloadDataSource();
        this.changeDectionRef.detectChanges();
    }

    private createLanguageReference(dataEdit: TranslationMediaChildView): LanguageReferenceModel[] {
        var languageReferences: LanguageReferenceModel[] = new Array();
        var language = new LanguageReferenceModel();
        language.languageCode = dataEdit.languageCode;
        language.languageName = dataEdit.languageName;
        languageReferences.push(language);
        return languageReferences;
    }

    save(data: TranslationMediaChildView) {
        if (data) {
            this.savedata(data);
        }
        this.toggleDetail();
    }

    private savedata(data: TranslationMediaChildView) {
        if (this.mediaTranslationChildFiles) {
            var filter = this.mediaTranslationChildFiles.filter(x => x.mediaId == data.mediaId && x.no == data.no);
            if (filter.length != 0) {
                filter[0].fileChange = data.fileChange;
                filter[0].mediaFileBase64 = data.mediaFileBase64;
                filter[0].mediaFile = data.mediaFile;
                filter[0].mediaFilePreview = data.mediaFilePreview;
                filter[0].mediaFilePreviewBase64 = data.mediaFilePreviewBase64;
                filter[0].noFile = data.noFile;
                filter[0].mediaFileNotFound = false;
            }
        }
    }

    noFile(e) {
        return e.data.noFile;
    }

    loadThumbnailFinished(e) {
        if (e.data.mediaFilePreviewBase64 || e.data.mediaFileNotFound) {
            return true;
        }
        this.getMediaFile(e);
        return false;
    }

    private getMediaFile(e) {
        if (e.data.mediaContentId)
            this.mediaService.getMediaFile(e.data.mediaContentId)
                .subscribe(
                    (response) => {
                        if (response.status == 200) {
                            var contenType = response.headers.get("Content-Type");
                            var fileName = this.mediaMapperService.getfileName(this.ORG_ID, e.data.mediaContentId, contenType);
                            var file = new File([response.body], fileName, { type: contenType });
                            this.updateFileToModel(e, file);
                            if (this.mediaMapperService.isImage(contenType)) {
                                this.getMediaThumbnailFile(e);
                            }
                            else {
                                this.getPicFilePreview(e, contenType);
                            }
                        }
                        else {
                            e.data.mediaFileNotFound = true;
                            this.changeDectionRef.detectChanges();
                        }
                    }
                );
    }

    private getMediaThumbnailFile(e) {
        this.mediaService.getMediaThumbnailFile(e.data.mediaContentId)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var fileName = this.mediaMapperService.getfileName(this.ORG_ID, e.data.mediaContentId, contenType);
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    } else {
                        e.data.mediaFileNotFound = true;
                        this.changeDectionRef.detectChanges();
                    }
                }
            );
    }

    private getPicFilePreview(e, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    } else {
                        e.data.mediaFileNotFound = true;
                        this.changeDectionRef.detectChanges();
                    }
                }
            );
    }

    private readURLFile(e, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            e.data.mediaFilePreviewBase64 = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
            e.data.mediaFilePreview = file;
            e.component.refresh();
        };
    }

    private updateFileToModel(e, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            e.data.mediaFileBase64 = reader.result.toString();
            e.data.mediaFile = file;
        };
    }

    public clearForm() {
        this.languageDataSourceStorage = [];
        this.mediaTranslationFiles = [];
        this.mediaTranslationChildFiles = [];
        this.isCollapsed2 = false;
        this.changeDectionRef.detectChanges();
    }

    private reloadDataSource() {
        if (this.languageDataSourceStorage.length) {
            for (let item of this.languageDataSourceStorage) {
                const data = this.mediaTranslationChildFiles.filter(child => child.mediaId == item.key);
                item.dataSourceInstance = this.createDataSource(item.key, data);
                item.dataSourceInstance.reload();
            }
        }
    }

    private createDataSource(key: any, data: any): any {
        return new DataSource({
            store: new ArrayStore({
                data: data,
                key: "no"
            }),
            filter: ["mediaId", "=", key]
        });
    }
}
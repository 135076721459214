import { Component, Input } from '@angular/core';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Component({
    selector: 'activity-datetime-format',
    templateUrl: 'activity-datetime-format.component.html'
})
export class ActivityDateTimeFormatComponent {
    
    @Input() value: Date;
    
    constructor(private dateConversion: DateConverterService) {

    }
    
    public displayDate(): string {
        return this.dateConversion.convertDate(this.value);
    }
}
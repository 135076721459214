import { 
    ChangeDetectorRef, 
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    Output, 
    SimpleChanges, 
    ViewChild 
} from '@angular/core';
import { TreeNode } from 'primeng/api';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { SecurityGroupHierarchyCommand } from '../shared/security-group-hierarchy-command';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

import { SecurityGroupTreeConverter } from './security-group-tree.converter';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'op-security-group-tree',
    templateUrl: './security-group-tree.component.html'
})
export class SecurityGroupTreeComponent implements OnChanges {
    private readonly codeNodeType = 'CODE';
    private readonly groupNodeType = 'GROUP';
    @Input() id: string;
    @Input() focused: boolean;
    @Input() heightPanelGeneral: number = 354;
    @Input() securityGroupTree: TreeNode[] = [];
    @Input() newSecurity = true;
    @Input() groupSelected = true;
    @Output() selectedNodeChange = new EventEmitter<TreeNode>();
    @Output() onNodeRemove = new EventEmitter<TreeNode>();
    @Output() treeFocused = new EventEmitter<boolean>();
    @Output() onNodeCopy = new EventEmitter<TreeNode>();
    @Output() onNodeNew = new EventEmitter<TreeNode>();
    @Output() onTreeNew = new EventEmitter<TreeNode[]>();
    @Output() onRootNodeRemove = new EventEmitter();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public selectedNode: TreeNode;
    public newSecurityGroupHierarchy: SecurityGroupHierarchyCommand[] = [];
    public collapsed: boolean;
    public focusing: boolean = false;
    
    constructor(private changeDetectionRef: ChangeDetectorRef,
        private securiryGroupTreeConverter: SecurityGroupTreeConverter,
        private stringHelperService: StringHelperService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['id'] || changes['securityGroupTree']) {
            this.setupSelectedNode(this.securityGroupTree ,this.id);
            this.expandRecursive(this.securityGroupTree, true);
        }
    }

    private expandRecursive(tree: TreeNode[], isExpand: boolean){
        tree?.forEach(node => {
            node.expanded = isExpand;
            if (node.children != null) {
                this.expandRecursive(node.children, isExpand);
            }
        })
    }

    setupSelectedNode(tree: TreeNode[], id: string) {
        if (tree != null) {
            tree.forEach(node => {
                if (node.data == id) {
                    this.selectedNode = node;
                    this.selectedNodeChange.emit(this.selectedNode);
                } else {
                    this.setupSelectedNode(node.children, id);
                }
            })
        }
    }

    public onSelectionChange(e) {
        if (e != null){
            this.selectedNode = e;
            this.selectedNodeChange.emit(e);
            if (e.type == this.groupNodeType) {
                this.groupSelected = true;
            } else {
                this.groupSelected = false;
            }
        }       
    }

    public toggleSelectedNode() {
        this.selectedNode.expanded = !this.selectedNode.expanded;
        this.changeDetectionRef.detectChanges();
    }

    rightPanelFocusOut() {
        this.treeFocused.emit(true);
    }

    removeChild() {
        if (this.selectedNode.parent) {
            let parent = this.selectedNode.parent;
            let childIndex = parent.children.findIndex(node => node.data == this.selectedNode.data);
            let removeNodeType = cloneDeep(this.selectedNode.type);
            parent.children.splice(childIndex, 1);
            this.onNodeRemove.emit(this.selectedNode);
            this.selectAfterRemove(parent, childIndex, removeNodeType);
        } else {
            this.onRootNodeRemove.emit();
        }
    }

    selectAfterRemove(parent: TreeNode, childIndex: number, removeNodeType: string) {
        if (removeNodeType == this.groupNodeType) {
            this.removeGroupNode(parent);
            return;
        }
        this.removeCodeNode(parent, childIndex);
    }

    removeGroupNode(parent: TreeNode) {
        this.onSelectionChange(parent);
    }
    
    removeCodeNode(parent: TreeNode, childIndex: number) {
        if (!parent.children || parent.children.length == 0) {
            this.onSelectionChange(parent);
            return;
        } else if (parent.children.length < childIndex + 1) {
            this.onSelectionChange(parent.children[parent.children.length - 1]);
            return ;
        }
        this.onSelectionChange(parent.children[childIndex]);
    }

    getSelectedNode(): TreeNode {
        return this.selectedNode;
    }

    addNewChildrenToSelectedNode(newChildren: SecurityCodeReferenceModel[]) {
        let newChildrenWithId = []
        newChildren.forEach(child => {
            let nodeTemp = this.securiryGroupTreeConverter.securityCodeModelToTreeNode(child);
            this.selectedNode.children.push(nodeTemp);
            newChildrenWithId.push(nodeTemp);
        })
        return newChildrenWithId;
    }

    addGroupChild(newNode: TreeNode) {
        newNode.parent = this.selectedNode;
        this.selectedNode.parent.children.push(newNode);
        this.selectedNode = newNode;
        this.selectedNodeChange.emit(this.selectedNode);
    }

    addNewGroupChild(newNode: TreeNode) {
        newNode.label = '●';
        if (this.securityGroupTree.length !== 0) {
            newNode.parent = this.selectedNode;
            this.selectedNode.children.push(newNode);
        } else {
            this.securityGroupTree.push(newNode);
        }       
        this.selectedNode = newNode;
        this.selectedNodeChange.emit(this.selectedNode);
        this.addNewHierarchy(this.selectedNode);
        this.changeDetectionRef.detectChanges();
    }

    newGroupChild() {
        if (this.selectedNode.type == this.groupNodeType) {
            let node: TreeNode = {
                data: this.stringHelperService.NewGuid(),
                label: '',
                type: this.groupNodeType,
                children: []
            }
            this.onNodeNew.emit(node);
        }
    }

    copyGroupChild() {
        if (this.selectedNode.type == this.groupNodeType) {
            this.onNodeCopy.emit(this.selectedNode);
        }
    }

    renameNode(name: string) {
        let currentNode = this.selectedNode;
        if (currentNode.type == this.codeNodeType) {
            currentNode = currentNode.parent;
        }
        currentNode.label = name;
    }

    addNewHierarchy(node: TreeNode) {
        let newHierarchy: SecurityGroupHierarchyCommand = {
            securityGroupId: node.data,
            parentSecurityGroupId: node.parent?.data,
        }
        this.newSecurityGroupHierarchy.push(newHierarchy);
    }

    getNewHierarchy(): SecurityGroupHierarchyCommand[] {
        return this.newSecurityGroupHierarchy;
    }

    clearNewHeirarchy() {
        this.newSecurityGroupHierarchy = [];
    }

    getTree(): TreeNode[] {
        return this.securityGroupTree;
    }

    newTree() {
        let node: TreeNode = {
            data: this.stringHelperService.NewGuid(),
            label: '',
            type: this.groupNodeType,
            children: []
        }
        let treeTemp: TreeNode[] = [];
        treeTemp.push(node);
        this.onTreeNew.emit(treeTemp);
    }

    clearTree() {
        this.securityGroupTree = [];
    }

    getTreeNodeById(securityGroupId: string, tree: TreeNode[]): TreeNode {
        let matchedItem: TreeNode;
        for (let item of tree) {
            if (item.data == securityGroupId) {
                return item;
            }
            let groupChildren = item.children.filter(child => child.type == this.groupNodeType);
            if (groupChildren && groupChildren.length > 0) {
                matchedItem = this.getTreeNodeById(securityGroupId, groupChildren);
            }
        }
        return matchedItem;
    }
}

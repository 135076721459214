import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserManagementComponent } from './user-management.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main'
},
  {
    path: '',
    component: UserManagementComponent,
    children: [
      {
          path: 'home',
          loadChildren: () => 
            import('./user-management-content/user-management-content.module').then(
                (m) => m.UserManagementContentModule
            )
      },
      {
          path: 'details',
          loadChildren: () =>
            import('./user-management-content/user-detail/user-detail.module').then(
                (m) => m.UserDetailModule
            )
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
],
  exports: [
    RouterModule
]
})
export class UserManagementRoutingModule { }
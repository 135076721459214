export const CashbookDocumentTabConstant = {
    TotalFromOfPaymentTabId: 'TFOP_TabId',
    TotalByCurrencyTabId: 'TBC_TabId',
    PaymentCurrenciesTabId: 'FOP_TabId',
    FromOfPaymentAndPaymentCurrencyTabId: 'FOPAPC_TabId'
}

export const FinanceTabConstant = {
    CategoryTab: 'categoryTab',
    SearchlTab: 'searchlTab',
    DeshboardTab: 'deshboardTab',
    StatusTab: 'StatusTab',
    CashbookTab: 'Cashbook'
}

export const FinancePanelConstant = {
    Cashbook: 'CASHBOOK',
    CounterSale: 'COUNTER_SALE',
    CashbookSearch: 'SEARCH',
    CounterSaleSearch: 'SEARCHCOUNTERSALSES',
    CreditAccountSearch: 'SEARCH_CREDIT_ACCOUNT',
    CreditAccount: 'CREDIT_ACCOUNT',
    PaymentSearch: 'PAYMENT_SEARCH'
}

export const PanelCashbookConstant = {
    AllPanel: "allPanel",
    DetailPanel: "detailPanel",
    ChargeItemPanel: "chargeItemPanel"
}

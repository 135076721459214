import { Injectable } from '@angular/core';
import { ProductInventoryCommandModel } from 'src/app/core/models/product-model/product-base-model/product-inventory';
import { SpecialServiceInventoryViewModel } from 'src/app/core/models/product-model/specialservice-model/specialservice-inventory-view.model';
import { VehicleConfigurationInventoryModel } from 'src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-inventory';
import { ServiceRequestInventoryView } from './servicerequest-inventory.view';

@Injectable()
export class ServiceRequestInventoryMapperService {

    public newInventoryToViews(models: SpecialServiceInventoryViewModel[], srInventoryViews: ServiceRequestInventoryView[], rootProductId: string): ServiceRequestInventoryView[] {
        if (models) {
            for (let model of models) {
                srInventoryViews.push(this.newInventoryToView(model, rootProductId))
            }
        }
        return srInventoryViews;
    }

    private newInventoryToView(model: SpecialServiceInventoryViewModel, rootProductId: string): ServiceRequestInventoryView {
        let view = {} as ServiceRequestInventoryView;
        view.productId = model.productId;
        view.parentProductId = model.parentProductId;
        view.productName = model.productName;
        view.sellCapacity = model.sellCapacity;
        view.requestCapacity = model.requestCapacity;
        view.unitCode = model.unitCode;
        view.unitName = model.unitName;
        view.rootProductId = rootProductId;
        view.inventoryGroupControl = model.inventoryGroupControl;
        return view;
    }

    public existInventoryToViews(models: VehicleConfigurationInventoryModel[]): ServiceRequestInventoryView[] {
        let views: ServiceRequestInventoryView[] = new Array();
        if (models) {
            for (let model of models) {
                views.push(this.existInventoryToView(model))
            }
        }
        return views;
    }

    private existInventoryToView(model: VehicleConfigurationInventoryModel): ServiceRequestInventoryView {
        let view = {} as ServiceRequestInventoryView;
        view.productInventoryId = model.productInventoryId;
        view.productId = model.productId;
        view.parentProductId = model.parentProductId;
        view.productName = model.productName;
        view.sellCapacity = model.sellCapacity;
        view.requestCapacity = model.requestCapacity;
        view.unitCode = model.unitCode;
        view.unitName = model.unitName;
        view.vehicleConfigurationProductId = model.vehicleConfigurationProductId;
        view.rootProductId = model.rootProductId;
        view.inventoryGroupControl = model.inventoryGroupControl;
        return view;
    }

    public getRootProductId(models: VehicleConfigurationInventoryModel[]): string[] {
        let rootPrductIds: string[] = new Array();
        if (models) {
            for (let model of models) {
                let filter = rootPrductIds.filter(x => x == model.rootProductId);
                if (!filter?.length) {
                    rootPrductIds.push(model.rootProductId);
                }
            }
        }
        return rootPrductIds;
    }

    public combineInventoryToViews(existInvs: ServiceRequestInventoryView[], newInvs: ServiceRequestInventoryView[]): ServiceRequestInventoryView[] {
        if (newInvs) {
            for (let newInv of newInvs) {
                existInvs.push(this.existInventoryToView(newInv))
            }
        }
        return existInvs; 
    }

    public inventoryViewToModels(views: ServiceRequestInventoryView[]): ProductInventoryCommandModel[] {
        let models: ProductInventoryCommandModel[] = new Array();
        if (views) {
            for (let i = 0; i < views.length; i++) {
                models.push(this.inventoryViewToModel(views[i]))
            }
        }
        return models;
    }

    private inventoryViewToModel(view: ServiceRequestInventoryView): ProductInventoryCommandModel {
        let model = new ProductInventoryCommandModel();
        model.productId = view.productId;
        model.productInventoryId = view.productInventoryId;
        model.sellCapacity = view.sellCapacity;
        model.requestCapacity = view.requestCapacity;
        model.unitCode = view.unitCode;
        model.rootProductId = view.rootProductId;
        model.vehicleConfigurationProductId = view.vehicleConfigurationProductId;
        return model;
    }
}
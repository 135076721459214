import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";


export const PRODUCT_DASHBOARD_MERCHANDIZE_LASTRECEIVED_DEMO: ProductDashboardLastReceivedModel[] = [
    {
        productGroupCode: "BEAUTY",
        productType: "Cleanser",
        productName: "OOPS Cleanser",
        productCount: 41,
        amount: 672325.51
    },
    {
        productGroupCode: "ELECTRONIC",
        productType: "Adapters",
        productName: "OOPS Adapters",
        productCount: 28,
        amount: 750690.06
    },
    {
        productGroupCode: "FASHION",
        productType: "Skirt",
        productName: "Pleated Skirt",
        productCount: 63,
        amount: 326551.11
    },
    {
        productGroupCode: "PERFUME",
        productType: "Perfume",
        productName: "OOPS Perfume",
        productCount: 130,
        amount: 5395632.45
    },
    {
        productGroupCode: "SPORTFASHION",
        productType: "Premium Running Sports",
        productName: " OOPS Premium Running Sports",
        productCount: 74,
        amount: 3753601.12
    },
    {
        productGroupCode: "COSMETICS",
        productType: "Lipstick",
        productName: "Lipstick",
        productCount: 165,
        amount: 1106324.87
    },
    {
        productGroupCode: "TOY",
        productType: "Doll",
        productName: "Teddy Bear",
        productCount: 85,
        amount: 750000.63
    },
    {
        productGroupCode: "FASHION",
        productType: "T-Shirt",
        productName: "OOPS T-Shirt",
        productCount: 61,
        amount: 430651.63
    },
    {
        productGroupCode: "FASHION",
        productType: "Flip flop",
        productName: "Rubber Flip flop",
        productCount: 115,
        amount: 875620.32
    }
]

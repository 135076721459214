import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { ScheduleQueueContentModule } from "./schedule-queue-content/schedule-queue-content.module";
import { ScheduleQueueComponent } from "./schedule-queue.component";

@NgModule({
    declarations: [
        ScheduleQueueComponent,
    ],
    imports: [
        ScheduleQueueContentModule,
        CommonModule,
        UiModule
    ],
    providers: [

    ],
    exports: [
        ScheduleQueueComponent
    ]
})
export class ScheduleQueueModule { }
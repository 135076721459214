import { Component, ViewChild, ViewContainerRef, OnInit, ElementRef, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { Helper } from 'src/app/shared/helper/app.helper';

import { PricingDetailComponent } from '../price-rule-detail';
import { SearchTableView } from '../../shared/search/shared/search-table.view';
import { PriceRuleView } from '../../shared/views/price-rule.view';
import { PriceRuleModel } from 'src/app/core/models/pricing-model';
import { PricingService } from 'src/app/core/services/pricing-services';
import { PricingDetailAttributeAndRuleComponent } from '../price-rule-detail/attribute-and-rule/attribute-and-rule.component';
import { PricingConverter } from '../../shared/pricing.converter';
import { BehaviorSubject, Subject } from 'rxjs';
import { SearchConditionComponent } from './search-condition/search-condition.component';
import { AttributePriceRuleGroupCode, PricingDetailConstant } from '../price-rule-detail/shared/pricing-detail.constant';
import { DomainAttributeService, ProductTypeGroupService } from 'src/app/core/services/airline-services';
import { forkJoin } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { CollectionHelperService } from 'src/app/core/utils/collection-helper.service';
import { ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { SearchTableComponent } from './search-table/search-table.component';
import { UIService } from 'src/app/shared/ui/uiservice/ui.service';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { timer } from 'rxjs';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SearchFilterNameComponent } from './search-filter-name/search-filter-name.component';
import { PricingConstant } from '../../pricing-content/shared/constants/pricing.constant';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';

declare var $: any;


@Component({
    selector: 'op-price-rule-search',
    templateUrl: './price-rule-search.component.html',
    providers: [
        FocusingService,
        Helper,
        PricingConverter,
        UIService
    ]
})
export class PriceRuleSearchComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly FILTER_USAGE_TYPE = 'FILTER';
    @Input() executeFlag: boolean = true;
    @Input() searchFilterId: string;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @ViewChild(PricingDetailAttributeAndRuleComponent) pricingDetailAttributeAndRuleComponent: PricingDetailAttributeAndRuleComponent;
    @ViewChild(SearchConditionComponent) searchConditionCompoennt: SearchConditionComponent;
    @ViewChild(SearchTableComponent) searchTableComponent: SearchTableComponent;
    @ViewChild('detailContainer', { static: true, read: ViewContainerRef }) detailContainer: ViewContainerRef;
    @ViewChild('priceRuleSearchConditionRef') priceRuleSearchConditionRef: ElementRef;
    @ViewChild('priceRuleSearchTableRef') priceRuleSearchTableRef: ElementRef;
    @ViewChild(FocusingDirective) searchResultFocusingDirective: FocusingDirective;
    @ViewChild(SearchFilterNameComponent) searchFilterNameComponent: SearchFilterNameComponent;

    classIcon = this.helper.getClassIcon();
    advanceSearchModeEnabled = false;
    searchMode = true;

    doubleClickSubject$ = new Subject();
    priceRuleView$ = new BehaviorSubject<PriceRuleView>(this.getDefaultPriceRuleInstance());
    priceRuleSearchFilters$ = this.getPriceRuleSearchFilter();
    priceRuleSearchResults$ = new Subject<PriceRuleModel[]>();

    priceRuleSearchModel: PriceRuleModel;
    searchDefaultCriteria = <PriceRuleModel>{
        statusCode: 'A',
        priceRuleCategoryCode: 'PRICE',
        priceRuleTypeCode: 'PRICE'
    };
    domainAttributes: DomainAttributeModel[];
    productTypeGroups: ProductTypeGroupModel[];
    priceDetailComponent: PricingDetailComponent;

    private priceRuleFilterModel: PriceRuleModel;

    unsubscribe$ = new Subject();

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;

    public pagingDataViews: PagingDataView[] = [];

    constructor(
        private helper: Helper,
        private pricingService: PricingService,
        private pricingConverter: PricingConverter,
        private collectionHelperService: CollectionHelperService,
        private productTypeGroupService: ProductTypeGroupService,
        private focusingService: FocusingService,
        private domainAttributeService: DomainAttributeService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private spinnerService: LoadingSpinnerService,
        private activityStoreService: ActivityStoreService) { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    ngOnInit(): void {
        this.getDomainAttributes();
        this.getEachProductTypeGroupByProductGroupCode();
        this.activityStore();
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
        this.actionbarService.updateState(this.defaultActionBarHandler);
        this.subscribeToActionBar();
        if (this.executeFlag == true) {
            this.searchByDefaultCriteria();
        }
    }

    private searchByDefaultCriteria() {
        this.spinnerService.show();
        this.pricingService.searchPriceRules(this.searchDefaultCriteria).subscribe(
            res => {
                this.populatePagingDataViews(res);
                this.priceRuleSearchResults$.next(res);
            },
            () => {},
            () => {
                this.spinnerService.hide();
            }
        )
    }

    setDefaultActionBarHandler() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.disable();
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(this.userSecurity?.deleteFlag ?? false);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.defaultActionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )
    }

    subscribeToActionBar() {
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                actionId => {
                    switch (actionId) {
                        case ACTION_STATUS.new:
                            this.newItem();
                            break;
                        case ACTION_STATUS.refresh:
                            this.reSearch();
                            break;
                        case ACTION_STATUS.cancel:
                            break;
                        case ACTION_STATUS.copy:
                            this.copyFilter();
                            break;
                        case ACTION_STATUS.save:
                            this.saveFilter(this.priceRuleFilterModel?.id);
                            break;
                        case ACTION_STATUS.saveAs:
                            this.saveFilter(null);
                            break;
                        case ACTION_STATUS.delete:
                            this.deletePriceRuleSearchFilter(this.priceRuleFilterModel?.id);
                            break;
                        case ACTION_STATUS.back:
                            break;
                        default:
                            break;
                    }
                }
            );
    }

    loadDetailForm(item: SearchTableView) {
        const queryParams = {
            id: item?.id,
            newPriceRule: !item?.id ? true : false,
            previousPage: PricingConstant.rules.id,
            pagingDataViews: this.pagingDataViews,
            userSecurity: this.userSecurity
        }
        this.navigationService.navigate(`/main/pricing${PricingConstant.rules.url}details`, null, null, queryParams);
    }

    private getDefaultPriceRuleInstance() {
        let view = new PriceRuleView();
        view.searchStatusCode = 'A';
        view.priceRuleTypeCode = 'PRICE';
        view.priceRuleCategoryCode = 'PRICE';
        view.priceRuleGroupCode = 'PRICE';
        return view;
    }

    getDomainAttributes() {
        forkJoin(AttributePriceRuleGroupCode
            .map(priceRuleAttributeGroupCode => this.domainAttributeService.getPriceRuleAttributes("", priceRuleAttributeGroupCode, "")))
            .subscribe(
                res => {
                    this.domainAttributes = this.collectionHelperService.flatten(res);
                }
            )
    }

    getEachProductTypeGroupByProductGroupCode() {
        forkJoin(
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED.map(
                item => this.productTypeGroupService.getProductTypeGroupByProductGroupCode(item.productGroupCode)
            )
        )
            .subscribe(
                responses => {
                    let productTypeReferences = [];
                    for (let item of PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED) {
                        for (let res of responses) {
                            let productTypeReference = res.find(r => r.productGroupCode == item.productGroupCode && r.productTypeCode == item.productTypeCode);
                            if (productTypeReference) {
                                productTypeReferences.push(productTypeReference);
                                break;
                            }
                        }
                    }
                    this.productTypeGroups = productTypeReferences;
                }
            )
    }

    onSearch(model: PriceRuleModel) {
        this.spinnerService.show();
        this.priceRuleSearchModel = this.convertSaveModelToSearchModel(model);
        let priceRuleView = this.pricingDetailAttributeAndRuleComponent.getAttributeAndRuleViews();
        this.addChildrenViewToModel(this.priceRuleSearchModel, priceRuleView);
        this.pricingService.searchPriceRules(this.priceRuleSearchModel)
            .pipe(finalize(() => this.spinnerService.hide()))
            .subscribe(
            res => {
                this.populatePagingDataViews(res);
                this.priceRuleSearchResults$.next(res);
            }
        )

        this.focusOnSearchResult();
    }

    focusOnSearchResult() {
        this.focusingService.focus(this.searchTableComponent.focusingDirective)
    }

    private convertSaveModelToSearchModel(saveModel: PriceRuleModel): PriceRuleModel {
        if (!saveModel) {
            return this.searchDefaultCriteria;
        }
        let searchModel = saveModel;
        searchModel.priceRuleName = saveModel.searchName;
        searchModel.statusCode = saveModel.searchStatusCode;
        searchModel.usageTypeCode = saveModel.searchUsageTypeCode;

        delete searchModel.searchName;
        delete searchModel.searchStatusCode;
        delete searchModel.searchUsageTypeCode;

        return searchModel;
    }

    addChildrenViewToModel(modelToAdd: PriceRuleModel, view: PriceRuleView): PriceRuleModel {
        if (!view) {
            return modelToAdd;
        }
        let modelOfView = this.pricingConverter.toModel(view);
        modelToAdd.productNumbers = modelOfView.productNumbers;
        modelToAdd.routes = modelOfView.routes;
        modelToAdd.locations = modelOfView.locations;
        modelToAdd.validities = modelOfView.validities;
        modelToAdd.vehicles = modelOfView.vehicles;
        modelToAdd.attributes = modelOfView.attributes;
        modelToAdd.organisations = modelOfView.organisations;
        modelToAdd.serviceCategories = modelOfView.serviceCategories;
        modelToAdd.salesBuckets = modelOfView.salesBuckets;
        modelToAdd.products = modelOfView.products;
        modelToAdd.individuals = modelOfView.individuals;
        modelToAdd.memberships = modelOfView.memberships;
        modelToAdd.formOfPayments = modelOfView.formOfPayments;
        return modelToAdd;
    }

    editItem(item) {
        let usageTypeCode = item.usageTypeCode;
        if (usageTypeCode == this.FILTER_USAGE_TYPE) {
            if (item.statusCode == 'A') {
                this.onPriceRuleSearchFilterIdChange(item.id);
                this.focusOnSearchCondition();
            }
        } else {
            this.loadDetailForm(item);
            this.searchMode = false;
        }
    }

    focusOnSearchCondition() {
        this.focusingService.focus(this.searchConditionCompoennt.focusingDirective);
    }

    onSearchConditionPanelClick(event) {
        let searchBtn = $('#searchConditionSearchBtn')?.get(0);
        let searchBtnClicked = searchBtn?.contains(event.srcElement);
        if (searchBtnClicked) {
            this.focusOnSearchResult();
        } else {
            this.focusOnSearchCondition();
        }
    }

    onSearchResultPanelClick(event) {
        let copyBtn = $('#searchResultCopyBtn')?.get(0);
        let copyBtnClicked = copyBtn.contains(event.srcElement);
        if (copyBtnClicked) {
            return;
        }
        if (event.type == 'click') {
            timer(200)
                .pipe(takeUntil(this.doubleClickSubject$))
                .subscribe(() => {
                    this.focusOnSearchResult();
                    this.doubleClickSubject$.next();
                })
        }
        if (event.type == 'dblclick') {
            this.doubleClickSubject$.next();
        }
    }


    newItem() {
        this.navigationService.navigate(`/main/pricing${PricingConstant.rules.url}details`, null, null, {
            previousPage: PricingConstant.rules.id,
            userSecurity: this.userSecurity
        });
        this.searchMode = false;
    }

    onClear() {
        this.clearSearchCondition();
        this.priceRuleSearchResults$.next([]);
    }

    private clearSearchCondition() {
        this.priceRuleView$.next(this.getDefaultPriceRuleInstance());
        this.priceRuleSearchFilters$ = this.getPriceRuleSearchFilter();
    }

    onPriceRuleSearchFilterIdChange(priceRuleId: string) {
        if (priceRuleId) {
            this.getPriceRule(priceRuleId);
        } else {
            this.clearSearchCondition();
        }
    }

    private getPriceRule(priceRuleId: string) {
        this.spinnerService.show();
        this.pricingService.getPriceRule(priceRuleId)
            .subscribe(
                (priceRuleModel: PriceRuleModel) => {
                    this.priceRuleFilterModel = priceRuleModel;
                    let view = this.pricingConverter.toView(priceRuleModel, this.domainAttributes, this.productTypeGroups);
                    this.priceRuleView$.next(view);
                    this.toggleCopyButton();
                },
                () => {},
                () => {
                    this.spinnerService.hide();
                }
            )
    }

    private getCopyPriceRule(priceRuleId: string) {
        this.spinnerService.show();
        this.pricingService.getPriceRule(priceRuleId)
            .subscribe(
                (priceRuleModel: PriceRuleModel) => {
                    let view = this.pricingConverter.toView(priceRuleModel, this.domainAttributes, this.productTypeGroups);
                    view.priceRuleId = null;
                    this.priceRuleView$.next(view);
                },
                () => { },
                () => {
                    this.spinnerService.hide();
                }
            )
    }



    onSave(priceRule: PriceRuleModel) {
        let model = this.searchConditionCompoennt.getValues();
        if (!model) {
            return;
        }

        model.id = priceRule.id;
        model.priceRuleName = priceRule.priceRuleName;
        model.isOwnerFilter = priceRule.isOwnerFilter;

        let priceRuleView = this.pricingDetailAttributeAndRuleComponent.getAttributeAndRuleViews();
        if (!priceRuleView) {
            return;
        }

        this.addChildrenViewToModel(model, priceRuleView);
        this.spinnerService.show();
        let command = this.pricingConverter.toCommand(model);
        let response = model.id ? this.pricingService.updatePriceRule(command) : this.pricingService.addPriceRule(command);
        response.subscribe(
            (priceRuleModel: PriceRuleModel) => {
                let view = this.pricingConverter.toView(priceRuleModel, this.domainAttributes, this.productTypeGroups);
                this.priceRuleView$.next(view);
                this.priceRuleSearchFilters$ = this.getPriceRuleSearchFilter();
                this.spinnerService.saveComplete();
            }
        )
    }

    private getPriceRuleSearchFilter() {
        return this.pricingService.getPriceRuleSearchFilters()
            .pipe(map(res => res.map(r => new Select2Data(r.id, r.priceRuleName))));
    }

    deletePriceRuleSearchFilter(priceRuleSearchFilterId: string) {
        if (!priceRuleSearchFilterId) {
            return;
        }
        let model = this.pricingConverter.toModel(this.priceRuleView$.value);
        model.statusCode = 'D';
        let command = this.pricingConverter.toCommand(model);
        this.spinnerService.show();
        this.pricingService.updatePriceRule(command)
            .subscribe(
                () => {
                    this.priceRuleSearchFilters$ = this.getPriceRuleSearchFilter();
                    this.searchByDefaultCriteria();
                    this.priceRuleView$.next(this.getDefaultPriceRuleInstance());
                    this.spinnerService.saveComplete();
                },
                () => { },
                () => {
                    this.spinnerService.hide();
                }
            )
    }

    onCopyItem(item) {
        let usageTypeCode = item.usageTypeCode;
        if (usageTypeCode == this.FILTER_USAGE_TYPE) {
            this.getCopyPriceRule(item.id);
            this.focusOnSearchCondition();
        }
    }

    onStatusChange(item) {
        this.updatePriceRuleStatus(item.item.id, item.statusCode);
    }

    updatePriceRuleStatus(priceRuleId: string, statusCode: string) {
        if (!priceRuleId) {
            return;
        }
        this.spinnerService.show();
        this.pricingService.updatePriceRuleStatus(priceRuleId, statusCode)
            .pipe(finalize(() => {
                this.onSearch(this.priceRuleSearchModel);
            }))
            .subscribe(
                () => {
                    this.priceRuleSearchFilters$ = this.getPriceRuleSearchFilter();
                    this.spinnerService.saveComplete();
                },
                () => { },
                () => {
                    this.spinnerService.hide();
                }
            )
    }

    reSearch() {
        this.searchConditionCompoennt.doSearch();
    }

    copyFilter() {
        if (!this.priceRuleFilterModel) {
            return;
        }
        let copyFilterView = this.pricingConverter.toView(this.priceRuleFilterModel, this.domainAttributes, this.productTypeGroups);
        copyFilterView.priceRuleName = copyFilterView.priceRuleName + ' - Copy';
        copyFilterView.priceRuleId = null;
        this.priceRuleView$.next(copyFilterView);

        this.priceRuleFilterModel = null;
        this.toggleCopyButton();
    }

    toggleCopyButton() {
        setTimeout(() => {
            let handler = this.actionbarService.getCurrentState();
            handler.get(ACTION_STATUS.copy).enable(!!(this.priceRuleFilterModel))
            this.actionbarService.updateState(handler);
        }, 0);
    }

    saveFilter(priceRuleFilterId: string) {
        const priceRuleFilterModel = this.searchFilterNameComponent.getValues(priceRuleFilterId);
        if (!priceRuleFilterModel) {
            return;
        }
        this.onSave(priceRuleFilterModel);
    }

    public onPriceRuleViewChange(priceRuleView: PriceRuleView) {
        this.priceRuleView$.next(Object.assign({}, priceRuleView))
    }

    private populatePagingDataViews(res: PriceRuleModel[]) {
        let count = 1;
        this.pagingDataViews = res.map(r => ({
            id: r.id,
            no: count++,
            seriesId: null,
            name: null
        }))
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null,
            "PRICERULE");
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SecurityCodeComponent } from './security-code.component';
import { SecurityLevel2TableModule } from '../shared/security-level2-table/security-level2-table.module';
import { SecurityCodeSearchComponent } from './security-code-search/security-code-search.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatIconModule } from '@angular/material/icon';
import { SecurityCodeSearchConditionComponent } from './security-code-search/security-code-search-condition/security-code-search-condition.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecurityCodeSearchTableComponent } from './security-code-search/security-code-search-table/security-code-search-table.component';
import { StatusColorModule } from 'src/app/shared/ui/forms/inputs/status-color/status-color.module';
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { SecurityCodeDetailComponent } from './security-code-detail/security-code-detail.component';
import { SecurityCodeDetailFormComponent } from './security-code-detail/security-code-detail-form/security-code-detail-form.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';

@NgModule({
    declarations: [
        SecurityCodeComponent,
        SecurityCodeDetailComponent,
        SecurityCodeDetailFormComponent,
        SecurityCodeSearchComponent,
        SecurityCodeSearchConditionComponent,
        SecurityCodeSearchTableComponent
    ],
    imports: [
        ButtonsModule,
        ClipboardModule,
        CollapseModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        MatIconModule,
        MatMenuModule,
        NavigationIndexModule,
        NgbNavModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        SecurityLevel2TableModule,
        Select2Module,
        StatusColorModule,
        UiModule
    ],
    exports: [
        SecurityCodeComponent,
        SecurityCodeSearchComponent
    ]
})
export class SecurityCodeModule { }

import { Component, ChangeDetectionStrategy, Input, SimpleChange, OnChanges, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { ProductInventoryDimensionViewModel } from 'src/app/core/models/product-model/product-base-model/product-inventory-dimension';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { DomainAttributeService } from 'src/app/core/services/airline-services';
import { field } from 'src/app/modules/product-management/product-categories-content/shared/constant/attribute.constant';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ATTRIBUTE_AND_RULE_ERR } from '../attribute-and-rule-error.constant';
import {
    select2Attribute, select2Choice, select2ChoiceMultiple, select2ChoiceMultipleLocked, select2AttributeInherit, select2AttributeSearch
} from './attribute-configuration';

@Component({
    selector: 'op-attribute',
    templateUrl: './attribute.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AttributeMapperService]
})

export class AttributeComponent implements OnChanges {

    @Input() id: string;
    @Input() productTypeCode: string;
    @Input() newProduct: boolean = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() searchMode: boolean = false;
    @Input() ignoreInventory = false;

    //Return value to inventory-attribute
    @Output() productAttributeValue = new EventEmitter<any>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    private readonly ATTRBUTE_GROUP_CODE: string = "ATTRIBUTE";

    actionForm: UntypedFormGroup;
    inheritForm: UntypedFormGroup;

    public productAttributes: ProductAttributeViewModel[];
    public productAttributesInherit: ProductAttributeViewModel[];
    public productInventoryDimensionExist: ProductInventoryDimensionViewModel[];
    public isHighestProductInventoryLevel: boolean = false;
    public draftFlag: boolean = true;

    private domainAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    private domainAttributeNotInvAttributes$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public attributeOption: any = select2Attribute;
    public choiceOption: any = select2Choice;
    public choiceMultipleOption: any = select2ChoiceMultiple;
    public choiceMultipleLockedOption: any = select2ChoiceMultipleLocked
    public attributeInheritOption: any = select2AttributeInherit;
    private lockedAttribute: Select2Data[] = new Array();
    public newFromParent: boolean = false;
    public focusing: boolean = false;

    constructor(private fb: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private domainAttributeService: DomainAttributeService,
        private attributeMapperService: AttributeMapperService) {
        this.clearInheritForms();
        this.clearForms();
        this.choiceMultipleLockedOption.templateSelection = this.templateSelection.bind(this);
        this.attributeOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeOption.templateSelection = this.templateResultAttribute.bind(this);
        this.attributeInheritOption.templateSelection = this.templateResultAttribute.bind(this);
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (this.searchMode) {
            this.getDomainAttributeSearch();
        }
        else {
            this.getProductAttributeType();
        }
    }

    private getProductAttributeType() {
        if (this.productTypeCode) {
            this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, this.productTypeCode)
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        responses.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                        this.domainAttributes$.next(responses);
                        this.lockedAttribute = [];

                        if (this.newProduct) {
                            this.displayShowOnNewFlag();
                        }
                        else {
                            if (this.ignoreInventory) {
                                this.domainAttributeNotInvAttributes$.next(responses);
                            } else {
                                var responsesNotinProductAttributes = this.filterNotInventoryAttribute(responses);
                                this.domainAttributeNotInvAttributes$.next(responsesNotinProductAttributes);
                            }
                            this.addViewInheritToFormGroup();
                            this.addViewToFormGroup();
                        }
                    }
                )
        }
    }

    private getDomainAttributeSearch() {
        this.domainAttributes$ = this.domainAttributeSearch$;
        this.attributeOption = select2AttributeSearch;
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    public add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_INVALID) {
                    return;
                }
            }
        }

        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(null));

        setTimeout(() => {
            let elementReference = document.querySelector('#' + this.getAttributeId() + '_' + (this.forms.controls.length - 1));
            if (elementReference instanceof HTMLElement) {
                elementReference.focus();
            }
        }, 100)

    }

    public addViewToFormGroup() {
        this.clearForms();
        if (!this.productAttributes) {
            return;
        }

        let displaySequenceAdded: number[] = new Array();
        for (let productAttribute of this.productAttributes) {
            var filterDisplaySequence = displaySequenceAdded.filter(x => x == productAttribute.displaySequence);
            if (filterDisplaySequence.length == 0) {
                if (this.canGetProductAttributeType(productAttribute)) {
                    this.assignDataToForm(productAttribute);
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get(field.REQUIRED).setValue(true);
                }
                displaySequenceAdded.push(productAttribute.displaySequence);
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    public addViewInheritToFormGroup() {
        this.clearInheritForms();
        if (!this.productAttributesInherit) {
            return;
        }
        this.productAttributesInherit.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        let displayDataAdded: Select2Data[] = new Array();
        for (let productAttributeInherit of this.productAttributesInherit) {
            var filterDisplaySequence = displayDataAdded.filter(x => x.id == productAttributeInherit.hierarchyKey && x.text == productAttributeInherit.displaySequence.toString());
            if (filterDisplaySequence.length == 0) {
                this.assignDataToInheritForm(productAttributeInherit);
                var dataAdded = new Select2Data(productAttributeInherit.hierarchyKey,
                    productAttributeInherit.displaySequence.toString());
                displayDataAdded.push(dataAdded);
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    createFormGroupEmpty(productAttributeTypeReference) {
        let form = {};
        if (productAttributeTypeReference) {
            form = this.assignAttributeToEmptyForm(
                productAttributeTypeReference.productAttributeId,
                productAttributeTypeReference.attributeTypeCode,
                productAttributeTypeReference.dimensionUnitCode,
                productAttributeTypeReference.multipleChoiceFlag);
        }
        else {
            form = this.assignAttributeToEmptyForm(null, null, null, false);
        }
        let f = this.fb.group(form);
        return f;
    }

    private assignAttributeToEmptyForm(productAttributeId: string, attributeTypeCode: string, dimensionUnitCode: string, multipleChoiceFlag: boolean) {
        let form = {};
        form[field.SUBMITTED] = [null, [Validators.nullValidator]];
        form[field.REQUIRED] = [null, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttributeId, [Validators.nullValidator]];
        form[field.CHOICE_ID] = [null, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [attributeTypeCode, [Validators.required]];
        form[field.DIMENSIONUNIT_CODE] = [dimensionUnitCode, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [(multipleChoiceFlag) ? true : false, [Validators.nullValidator]];
        form[field.CHOICE_OPTOIN] = [(multipleChoiceFlag) ? this.choiceMultipleOption : this.choiceOption, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.CHOICE_VALUE] = [null, [Validators.required]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.MIN] = this.getMinimumAttributeTypeCodeFormGroup(attributeTypeCode);
        form[field.MAX] = this.getMaximumAttributeTypeCodeFormGroup(attributeTypeCode);
        if (this.newProduct || this.newFromParent || this.searchMode) {
            form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttributes$), [Validators.nullValidator]];
        }
        else {
            form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttributeNotInvAttributes$), [Validators.nullValidator]];
        }
        return form;
    }

    createFormGroupWithData(productAttribute: ProductAttributeViewModel, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {};
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [productAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.SUBMITTED] = [true, [Validators.nullValidator]];
        form[field.REQUIRED] = [this.checkIsRequiredFlag(productAttribute.attributeTypeCode) ? true : false, [Validators.nullValidator]];
        form[field.MIN] = this.getMinimumAttributeTypeCodeFormGroup(productAttribute.attributeTypeCode);
        form[field.MAX] = this.getMaximumAttributeTypeCodeFormGroup(productAttribute.attributeTypeCode);

        var isInventoryAttribute = this.checkIsInventoryAttribute(productAttribute.attributeTypeCode);
        this.assignValueToAttributeType(isInventoryAttribute, form, productAttribute, disabled);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignOption(isInventoryAttribute, form, productAttribute.multipleChoiceFlag);
            this.assignValueToChoiceData(isInventoryAttribute, form, productAttribute.multipleChoiceFlag, productAttribute);
            this.assignValueToChoiceValue(productAttribute.multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        if (this.checkIsRequiredFlag(productAttribute.attributeTypeCode)) {
            this.setDataAttributeTypeRequireFlag(f, productAttribute.attributeTypeCode);
        }
        return f;
    }

    getMinimumAttributeTypeCodeFormGroup(attributeTypeCode: string): any {
        return [(attributeTypeCode) ? this.attributeMapperService.getMinimunValue(attributeTypeCode, this.domainAttributes$) : null, [Validators.nullValidator]];
    }

    getMaximumAttributeTypeCodeFormGroup(attributeTypeCode: string): any {
        return [(attributeTypeCode) ? this.attributeMapperService.getMaximumValue(attributeTypeCode, this.domainAttributes$) : null, [Validators.nullValidator]];
    }

    createFormGroupWithInheritData(productAttribute, multipleChoiceFlag: boolean,
        choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {}
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        this.assignValueToAttributeInherit(form, productAttribute);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceInherit(form, choiceArrayDBId, productAttribute);
            this.assignValueToChoiceValue(multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        return f;
    }

    private assignValueToAttributeInherit(form: any, productAttribute: ProductAttributeViewModel) {
        var attributeDataArray: Select2Data[] = new Array();
        var attributeData = new Select2Data();
        attributeData.id = productAttribute.attributeTypeCode;
        attributeData.text = productAttribute.attributeTypeName;
        attributeDataArray.push(attributeData);
        form[field.ATTR_DATA] = [attributeDataArray, [Validators.nullValidator]];
    }

    private assignValueToChoiceInherit(form: any, choiceArrayDBId: Select2Data[], productAttribute: ProductAttributeViewModel) {
        let choiceDataArray: Select2Data[] = new Array();
        if (choiceArrayDBId) {
            choiceDataArray = this.createChoiceMultipleInheritData(choiceArrayDBId);
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        }
        else {
            choiceDataArray = this.createChoiceSingleInheritData(productAttribute);
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        form[field.CHOICE_DATA] = [choiceDataArray, [Validators.nullValidator]];
    }

    private createChoiceMultipleInheritData(choiceArrayDBId: Select2Data[]): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        if (choiceArrayDBId) {
            for (let choice of choiceArrayDBId) {
                choiceData = new Select2Data();
                choiceData.id = choice.text;
                var filter = this.productAttributesInherit.filter(x => x.attributeChoiceId == choice.text);
                if (filter.length != 0) {
                    choiceData.text = filter[0].attributeChoiceName;
                    choiceDataArray.push(choiceData);
                }
            }
        }
        return choiceDataArray;
    }

    private createChoiceSingleInheritData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        choiceData = new Select2Data();
        choiceData.id = productAttribute.attributeChoiceId;
        choiceData.text = productAttribute.attributeChoiceName;
        choiceDataArray.push(choiceData);
        return choiceDataArray;
    }

    getMultipleChoiceFlag(productAttributeTypeCode): boolean {
        if (productAttributeTypeCode) {
            if (this.domainAttributes$.value) {
                var filter = this.domainAttributes$.value.filter(x => x.attributeTypeCode == productAttributeTypeCode);
                if (filter.length > 0) {
                    return filter[0].multipleChoiceFlag;
                }
                return false;
            }
        }
        return false;
    }

    checkIsInventoryAttribute(productAttributeTypeCode): boolean {
        if (productAttributeTypeCode) {
            if (this.productInventoryDimensionExist) {
                var filter = this.productInventoryDimensionExist.filter(x => x.attributeTypeCode == productAttributeTypeCode);
                return filter.length > 0;
            }
        }
        return false;
    }

    attributeChange(value: string | string[], ctl: AbstractControl) {
        ctl.get(field.ATTRTYPE_CODE).setValue(value);
        if (this.domainAttributes$.value) {
            let attribute = this.domainAttributes$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get(field.CHOICE_VALUE).setValue(null);
                ctl.get(field.DIMENSIONUNIT_CODE).setValue(attributeFilter[0].dimensionUnitCode);
                ctl.get(field.MIN).setValue(this.attributeMapperService.getMinimunValue(attributeFilter[0].attributeTypeCode, this.domainAttributes$));
                ctl.get(field.MAX).setValue(this.attributeMapperService.getMaximumValue(attributeFilter[0].attributeTypeCode, this.domainAttributes$));
                if (attributeFilter[0].dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
                    this.setChoiceData(ctl, attributeFilter[0].attributeChoices);
                    this.setSingleOrMultipleChoice(ctl, attributeFilter[0].multipleChoiceFlag);
                    this.setDefaultChoiceValue(ctl, attributeFilter[0]);
                }
                this.setDefaultValue(ctl, attributeFilter[0]);
            } else {
                this.setEmptySingleDropdown(ctl);
            }
        }
        this.createOutputAttributeTypeValue();
    }

    private setChoiceData(ctl: AbstractControl, attributeChoices: AttributeChoiceModel[]) {
        let choiceData = this.convertToSelect2Data(attributeChoices);
        ctl.get(field.CHOICE_DATA).setValue(choiceData);
    }

    private setSingleOrMultipleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean) {
        ctl.get(field.MULCHOICE_FLAG).setValue(multipleChoiceFlag);
        if (multipleChoiceFlag) {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceMultipleOption);
        }
        else {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceOption);
        }
    }

    private setDefaultChoiceValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        let filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.assignDefaultChoice(ctl, attribute.multipleChoiceFlag, filterDefaultChoice);
        }
        else {
            this.assignDefaultSingleChoice(ctl, attribute.multipleChoiceFlag, attribute.attributeChoices);
        }
    }

    private setDefaultValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        if ((attribute.dimensionUnitCode == OopsChoiceValueConstant.NUMBER ||
            attribute.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE)
            && attribute.defaultValue) {
            ctl.get(field.CHOICE_VALUE).setValue(attribute.defaultValue);
        }
    }

    private assignDefaultChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (multipleChoiceFlag) {
            let dataMultiple: string[] = new Array();
            dataMultiple.push(chloces[0].attributeChoiceId);
            ctl.get(field.CHOICE_VALUE).setValue(dataMultiple);
        }
        else {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private assignDefaultSingleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (!multipleChoiceFlag) {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private setEmptySingleDropdown(ctl: AbstractControl) {
        ctl.get(field.CHOICE_DATA).setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get(field.MULCHOICE_FLAG).setValue(false);
    }

    private createOutputAttributeTypeValue() {
        var attributeTypeValueSelect2DataArray: Select2Data[] = new Array();
        if (this.forms.controls.length != 0) {
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    var productAttributeTypeCode = ctl.get(field.ATTRTYPE_CODE).value;
                    if (this.domainAttributes$.value) {
                        var attribute = this.domainAttributes$.value;
                        var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttributeTypeCode
                            && x.dimensionFlag == true &&
                            x.dimensionUnitCode == OopsChoiceValueConstant.CHOICE);
                        if (attributeFilter.length > 0) {
                            var attributeTypeValueSelect2Data = new Select2Data();
                            attributeTypeValueSelect2Data.id = productAttributeTypeCode;
                            attributeTypeValueSelect2Data.text = attributeFilter[0].attributeTypeName;
                            attributeTypeValueSelect2DataArray.push(attributeTypeValueSelect2Data);
                        }
                    }
                }
            }
        }
        this.productAttributeValue.emit(attributeTypeValueSelect2DataArray);
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }


    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
        this.createOutputAttributeTypeValue();
        let elementReference = document.querySelector('#btnAttributeAdd');
        if (elementReference instanceof HTMLElement) {
            elementReference.focus();
        }
    }

    displayShowOnNewFlag() {
        if (this.domainAttributes$.value) {
            this.actionForm = this.fb.group({
                forms: this.fb.array([])
            });

            let required = this.domainAttributes$.value.filter(x => x.requiredFlag == true);
            this.display(required);

            let showOnNew = this.domainAttributes$.value.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            this.display(showOnNew);

            this.changeDetectorRef.detectChanges();
        }
    }

    display(domainAttributeRequire: DomainAttributeModel[]) {
        for (let productAttributeTypeReference of domainAttributeRequire) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(productAttributeTypeReference));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            if (productAttributeTypeReference.requiredFlag) {
                this.setDataAttributeTypeRequireFlag(formCurrent, productAttributeTypeReference.attributeTypeCode);
            }
            this.attributeChange(productAttributeTypeReference.attributeTypeCode, formCurrent);
        }
    }


    convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                }
                else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }

    findDuplicate(ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (ctl.value.productAttributeTypeCode == ctlCheck.value.productAttributeTypeCode) {
                    if (ctl.value.multipleChoiceFlag) {
                        if (this.checkArrayEqual(ctl.value.choiceValue, ctlCheck.value.choiceValue)) {
                            duplicate = true;
                        }
                    }
                    else {
                        if (ctl.value.choiceValue == ctlCheck.value.choiceValue) {
                            duplicate = true;
                        }
                    }
                }
            }
        }
        return duplicate;
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get(field.DUPLICATE).setValue(null);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.required);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
        else {
            formCurrent.get(field.DUPLICATE).setValue(false);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.nullValidator);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

    canGetProductAttributeType(productAttribute: ProductAttributeViewModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttributes$.value) {
            var attribute = this.domainAttributes$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }
        return returnValue;
    }

    clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }

    templateSelection(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockChoice(id) && !this.draftFlag) {
            tag.locked = true;
            $(container).addClass('locked-tag');
        }
        else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        return tag.text;
    }

    lockChoice(id): boolean {
        if (this.lockedAttribute) {
            var filter = this.lockedAttribute.filter(x => x.text == id);
            return filter.length > 0;
        }
        return false;
    }

    templateResultAttribute(item): JQuery<HTMLElement> {
        let requiredFlag = this.isRequiredFlag(item);
        if (this.isDimensionFlag(item) && !this.searchMode) {
            var $state = $(
                '<span>' + item.text + requiredFlag + ' <i class="fal fa-cube"></i></span>'
            );
            return $state;
        }
        return item.text + requiredFlag;
    }

    isDimensionFlag(item): boolean {
        if (!item.id) {
            return false;
        }
        var id = $(item.element.outerHTML).attr("value");
        if (this.domainAttributes$.value) {
            var filter = this.domainAttributes$.value.filter(x => x.attributeTypeCode == id && x.dimensionFlag == true);
            return filter.length > 0;
        }
        return false;
    }

    isRequiredFlag(item): any {
        if (!item.id || this.searchMode) {
            return "";
        }
        var id = $(item.element.outerHTML).attr("value");
        if (this.domainAttributes$.value) {
            var filter = this.domainAttributes$.value.filter(x => x.attributeTypeCode == id && x.requiredFlag == true);
            if (filter?.length) {
                return " *";
            }
        }
        return "";
    }

    filterNotInventoryAttribute(responses: DomainAttributeModel[]): DomainAttributeModel[] {
        var productAttributeTypes: DomainAttributeModel[] = new Array();
        if (this.productInventoryDimensionExist) {
            for (let data of responses) {
                var filter = this.productInventoryDimensionExist.filter(x => x.attributeTypeCode == data.attributeTypeCode);
                if (filter.length == 0) {
                    productAttributeTypes.push(data);
                }
            }
        }
        return productAttributeTypes;
    }

    public checkHiddenDelete(ctl): boolean {
        if (this.searchMode ||
            (   !this.checkIsRequiredFlag(ctl.get(field.ATTRTYPE_CODE).value) &&
                ( 
                    this.isNewProduct() || 
                    !this.checkIsInventoryAttribute(ctl.get(field.ATTRTYPE_CODE).value) 
                )   
            )) {
            return false;
        }
        return true;
    }

    private isNewProduct(): boolean {
        return (this.newProduct || this.newFromParent);
    }

    private assignValueToAttributeType(isInventoryAttribute: boolean, form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        if (isInventoryAttribute && !this.draftFlag) {
            this.assignValueToAttributeIsInvAttribute(form, productAttribute);
        }
        else {
            this.assignValueToAttribute(form, productAttribute, disabled);
        }
    }

    private assignValueToAttributeIsInvAttribute(form: any, productAttribute: ProductAttributeViewModel) {
        form[field.CHOICE_OPTOIN] = [this.choiceMultipleLockedOption, [Validators.nullValidator]]
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: false }, [Validators.required]];
        //Create only one attribute data
        var productAttTypeReference$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        var productAttTypes: DomainAttributeModel[] = new Array();
        var productAttType = {} as DomainAttributeModel;
        productAttType.attributeTypeCode = productAttribute.attributeTypeCode;
        productAttType.attributeTypeName = productAttribute.attributeTypeName;
        productAttTypes.push(productAttType);
        productAttTypeReference$.next(productAttTypes);
        form[field.ATTRTYPE_DATA] = [productAttTypeReference$, [Validators.nullValidator]];
    }

    private assignValueToAttribute(form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttributes$), [Validators.nullValidator]];
    }

    private assignValueToChoiceData(isInventoryAttribute: boolean, form: any,
        multipleChoiceFlag: boolean, productAttribute: ProductAttributeViewModel) {
        if (isInventoryAttribute && !this.draftFlag) {
            this.assignValueToChoiceDataIsInvAttribute(multipleChoiceFlag, form, productAttribute);
        }
        else {
            this.assignValueToChoiceDataIsNotInvAttribute(form, productAttribute);
        }
    }

    private assignValueToChoiceDataIsInvAttribute(multipleChoiceFlag, form: any, productAttribute: ProductAttributeViewModel) {
        if (!multipleChoiceFlag) {
            this.assignDataToSingleChoice(form, productAttribute);
        }
        else {
            if (this.isHighestProductInventoryLevel) {
                this.assignDataToHighestInvChoice(form, productAttribute);
            }
            else {
                this.assignDataToSingleChoice(form, productAttribute);
            }
        }
    }

    private assignDataToSingleChoice(form: any, productAttribute: ProductAttributeViewModel) {
        var choiceData: Select2Data[] = new Array();
        let value = new Select2Data();
        value.id = productAttribute.attributeChoiceId;
        value.text = productAttribute.attributeChoiceName;
        choiceData.push(value);
        form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
    }

    private assignDataToHighestInvChoice(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttributes$.value) {
            var attribute = this.domainAttributes$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                var choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            }
            else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceDataIsNotInvAttribute(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttributes$.value) {
            var attribute = this.domainAttributes$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                var choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            }
            else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean,
        choiceMultipleValue: string[], form: any, choiceArrayDBId: Select2Data[],
        productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_ID] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_VALUE] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
        }
        else {
            form[field.CHOICE_VALUE] = [{ value: productAttribute.attributeChoiceId, disabled: disabled }, [Validators.required]];
        }
    }

    private assignOption(isInvAttribute: boolean, form: any, multipleChoiceFlag: boolean) {
        if (!isInvAttribute) {
            if (multipleChoiceFlag) {
                form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
            } else {
                form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
            }
        }
    }

    private checkIsRequiredFlag(productAttributeTypeCode: string) {
        if (productAttributeTypeCode && this.domainAttributes$.value) {
            var filter = this.domainAttributes$.value.filter(x =>
                x.attributeTypeCode == productAttributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private setDataAttributeTypeRequireFlag(formCurrent: AbstractControl, attributeTypeCode: string) {
        formCurrent.get(field.REQUIRED).setValue(true);
        let domainAttributeRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        let filterAttributeType = this.domainAttributes$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
        domainAttributeRequireFlag$.next(filterAttributeType);
        formCurrent.get(field.ATTRTYPE_DATA).setValue(domainAttributeRequireFlag$);
    }

    private createDomainAttribute(domainAttributeBliding$: BehaviorSubject<DomainAttributeModel[]>): BehaviorSubject<DomainAttributeModel[]> {
        if (!domainAttributeBliding$.value) {
            return new BehaviorSubject<DomainAttributeModel[]>(null);
        }
        if (!this.searchMode) {
            let filterNoRequireFlag = domainAttributeBliding$.value.filter(x => x.requiredFlag != true);
            let domainAttributeNoRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
            domainAttributeNoRequireFlag$.next(filterNoRequireFlag);
            return domainAttributeNoRequireFlag$;
        }
        return domainAttributeBliding$;
    }

    findRequireAttribute(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectorRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.value.required) {
                    if ((ctl.value.multipleChoiceFlag && !ctl.value.choiceValue?.length) ||
                        (!ctl.value.choiceValue)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public validateInvalidRow(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectorRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (!ctl.valid) {
                    return false;
                }
            }
        }
        return true;
    }

    public validateForm(): boolean {
        return !this.findRequireAttribute() && this.validateInvalidRow();
    }

    public getErrorMessageForm(): string {
        if (this.findDuplicateAllRow()) {
            return ATTRIBUTE_AND_RULE_ERR.duplicateInformation;
        } else if (!this.validateInvalidRow()) {
            return ATTRIBUTE_AND_RULE_ERR.invalidInformation;
        }
    }

    private clearForms() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    private clearInheritForms() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    private assignDataToInheritForm(productAttributeInherit: ProductAttributeViewModel) {
        if (productAttributeInherit.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleInheritDataToForm(productAttributeInherit);
            let choiceArrayDBId = this.createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit);
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag,
                    choiceMultiple, choiceArrayDBId, true));
        } else {
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag, null, null, true));
        }
    }


    private createChoiceMultipleInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);;
        }
        return choiceMultiple;
    }

    private createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributesInherit(productAttributeInherit: ProductAttributeViewModel): ProductAttributeViewModel[] {
        return this.productAttributesInherit.filter(x => x.hierarchyKey == productAttributeInherit.hierarchyKey
            && x.displaySequence == productAttributeInherit.displaySequence);
    }

    private assignDataToForm(productAttribute: ProductAttributeViewModel) {
        let isInventoryAttribute = this.checkIsInventoryAttribute(productAttribute.attributeTypeCode);
        if (productAttribute.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleData(productAttribute);
            let choiceArrayDBId = this.createChoiceDBIdData(productAttribute);
            this.addToAttributesLocked(isInventoryAttribute, productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, choiceMultiple, choiceArrayDBId));
        } else {
            this.addToAttributeLocked(isInventoryAttribute, productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute));
        }
    }

    private addToAttributeLocked(isInventoryAttribute: boolean, productAttribute: ProductAttributeViewModel) {
        if (isInventoryAttribute) {
            var attributeLocked = new Select2Data();
            attributeLocked.id = productAttribute.attributeTypeCode;
            attributeLocked.text = productAttribute.attributeChoiceId;
            this.lockedAttribute.push(attributeLocked);
        }
    }

    private createChoiceMultipleData(productAttribute: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);
        }
        return choiceMultiple;
    }

    private createChoiceDBIdData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributeDisplay(productAttribute: ProductAttributeViewModel) {
        return this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
    }

    private addToAttributesLocked(isInventoryAttribute: boolean, productAttribute: ProductAttributeViewModel) {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        for (let productAttributeChoice of filterProductAttributeTypes) {
            this.addToAttributeLocked(isInventoryAttribute, productAttributeChoice);
        }
    }

    public rowInValid(ctl: AbstractControl): boolean {
        return (ctl.get(field.CHOICE_VALUE).invalid || ctl.get(field.DUPLICATE).invalid) && ctl.get(field.SUBMITTED).value == true;
    }

    dataChange(value: string | string[], ctl) {
        ctl.get(field.CHOICE_VALUE).setValue(value);
        this.createOutputAttributeTypeValue();
    }

    public getAttributeId(): string {
        if (this.searchMode) {
            return "ddlAttributeSearch";
        }
        return "ddlAttribute";
    }
}
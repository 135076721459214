import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-product',
    templateUrl: './product-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductManagementComponent {
    constructor(private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.navigationService.navigate("/main/product/home", null);
    }
}

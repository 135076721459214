import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { SeatPropertyGroupReferenceModel, SeatPropertyReferenceModel, ServiceCategoryReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { PAGE_TITLE } from "../../../settings-management-content/shared/title-constant";
import { NavigationService } from "src/app/shared/utils/navigation";
import { VehicleConfigurationGeneralDetailComponent } from "./general/general.component";
import { VehicleConfigurationAddCommandModel, VehicleConfigurationEditCommandModel, VehicleConfigurationModel } from "src/app/core/models/vehicle-model/vehicle-configuration";
import { VehicleConfigurationService } from "src/app/core/services/vehicle-services";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { ServiceCatetgoryReferenceService } from "src/app/core/services/system-services/service-category-reference.service";
import { VehicleConfigurationServiceCategoryComponent } from "./servicecategory/servicecategory.component";
import { VehicleConfigurationCompartmentModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-compartment";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { SpecialServiceService } from "src/app/core/services/product-services";
import { ProductServiceRequestModel } from "src/app/core/models/product-model/specialservice-model";
import { ServiceRequestMapperService } from "./servicerequest/servicerequest.mapper.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { VehicleConfigurationServiceRequestComponent } from "./servicerequest/servicerequest.component";
import { VehicleConfigurationProductModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-product";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { VehicleConfigurationServiceRequestInventoryComponent } from "./servicerequest-inventory/servicerequest-inventory.component";
import { VehicleConfigurationInventoryModel } from "src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-inventory";
import { VehicleConfigurationServiceCategorySeatMapComponent } from "./servicecategory-seatmap/servicecategory-seatmap.component";
import { SeatPropertyReferenceService } from "src/app/core/services/system-services/seat-property-reference.service";
import { SeatPropertyGroupReferenceService } from "src/app/core/services/system-services/seat-property-group-reference.service";
import { VehicleConfigurationSeatMapComponent } from "./seatmap/seatmap.component";
import { VehicleConfigurationSeatPropertyComponent } from "./seatproperty/seatproperty.component";
import { VehicleConfigurationSeatPropertyDetailComponent } from "./seatproperty/seatproperty-detail/seatproperty-detail.component";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { SecurityGroupService } from "src/app/core/services/system-services/security-group.service";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-configuration-detail',
    templateUrl: './vehicle-configuration-detail.component.html',
    providers: [ServiceRequestMapperService, ActionbarService]
})
export class VehicleConfigurationDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly generalTabId = 'general';
    readonly serviceCateTabId = 'serviceCate';
    readonly serviceRequestTabId = 'serviceRequest';
    readonly seatMapTabId = 'seatMap';
    readonly generalPanel = 'General Information';
    readonly serviceCategoryPanel = 'Service Category';
    readonly seatPropertyDetailPanel = 'Seat Property Detail';
    readonly FOCUS_DELAY_TIME = 100;
    readonly VEHICLECONFIGURATION = 'VEHICLECONFIGURATION';
    private readonly previousTitle: string = PAGE_TITLE.vehicle;
    private unsubscribe$ = new Subject();

    id: string;
    copy: boolean = false;
    seatmap: boolean = false;
    disabledSave: boolean = false;
    saveProcessing: boolean = false;
    selectedTab = this.generalTabId;
    statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    serviceCategoryReferences$ = new BehaviorSubject<ServiceCategoryReferenceModel[]>(null);
    vehicleConfiguration$ = new BehaviorSubject<VehicleConfigurationModel>(null);
    vehicleConfigurationCompartments$ = new BehaviorSubject<VehicleConfigurationCompartmentModel[]>(null);
    productServiceRequests$ = new BehaviorSubject<ProductServiceRequestModel[]>(null);
    productServiceRequestDatas$ = new BehaviorSubject<Select2Data[]>(null);
    vehicleConfigurationProducts$ = new BehaviorSubject<VehicleConfigurationProductModel[]>(null);
    vehicleServiceRequests$ = new BehaviorSubject<string[]>(null);
    seatPropertyReferences$ = new BehaviorSubject<SeatPropertyReferenceModel[]>(null);
    seatPropertyGroupReferences$ = new BehaviorSubject<SeatPropertyGroupReferenceModel[]>(null);
    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    )
    vehicleConfigurationInventories$ = new BehaviorSubject<VehicleConfigurationInventoryModel[]>(null);
    serviceRequestProductIdSelected: string[] = new Array();
    showSeatMap: boolean = false;
    serviceCategorydSelected: Select2Data;
    seatMapRowSelected: number[] = new Array();
    seatMapColSelected: string;
    seatMapColAll: string;
    public paggingDataView: PagingDataView[];
    public currentPageIndex: number;
    public displayName: string;
    userSecurity: SecurityGroupSecurityModel
    @ViewChild(VehicleConfigurationGeneralDetailComponent) generalDetailComponent: VehicleConfigurationGeneralDetailComponent;
    @ViewChild(VehicleConfigurationServiceCategoryComponent) serviceCategoryComponent: VehicleConfigurationServiceCategoryComponent;
    @ViewChild(VehicleConfigurationServiceRequestComponent) serviceRequestComponent: VehicleConfigurationServiceRequestComponent;
    @ViewChild(VehicleConfigurationServiceRequestInventoryComponent) serviceRequestInventoryComponent: VehicleConfigurationServiceRequestInventoryComponent;
    @ViewChild(VehicleConfigurationServiceCategorySeatMapComponent) serviceCategorySeatMapComponent: VehicleConfigurationServiceCategorySeatMapComponent;
    @ViewChild(VehicleConfigurationSeatMapComponent) vehicleConfigurationSeatmapComponent: VehicleConfigurationSeatMapComponent;
    @ViewChild(VehicleConfigurationSeatPropertyComponent) vehicleConfigurationSeatPropertyComponent: VehicleConfigurationSeatPropertyComponent;
    @ViewChild(VehicleConfigurationSeatPropertyDetailComponent) vehicleConfigurationSeatPropertyDetailComponent: VehicleConfigurationSeatPropertyDetailComponent;

    constructor(private statusReferenceService: StatusReferenceService,
        private vehicleConfigurationService: VehicleConfigurationService,
        private serviceCategoryReferenceService: ServiceCatetgoryReferenceService,
        private specialServiceService: SpecialServiceService,
        private seatPropertyReferenceService: SeatPropertyReferenceService,
        private seatPropertyGroupReferenceService: SeatPropertyGroupReferenceService,
        private serviceRequestMapperService: ServiceRequestMapperService,
        private securityGroupService: SecurityGroupService,
        private navigateService: NavigationService,
        private spinnerService: LoadingSpinnerService,
        public alertBarService: AlertBarService,
        private actionbarService: ActionbarService,
        private focusingService: FocusingService,
        private changeDetectorRef: ChangeDetectorRef,
        private actionService: ActionService) { }

    ngOnInit(): void {
        this.getStatusReferences();
        this.getServiceCategoryReferences();
        this.getProductServiceRequests();
        this.getSeatPropertyGroupReferences();
        this.getSeatPropertyReferences();
        this.getParams();
        this.getVehicleConfigurationData();
        this.addAction();
    }

    ngAfterViewInit(): void {
        this.securityGroupService.userSecurityGroupSecurity
            .subscribe(userSecurity => {
                this.userSecurity = userSecurity.find(s => s.securityCode == this.VEHICLECONFIGURATION)
                this.updateActionBar();
                this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
                    actionId => {
                        this.actionbarClick(actionId);
                    }
                );
            })

        this.subscribeToPanelsFocus();
        this.initialFocusInCondition();
    }

    private updateActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable(this.userSecurity.newFlag);
        this.actionBarHandler.get(ACTION_STATUS.copy).enable(this.userSecurity.copyFlag);
        if (!this.id) {
            this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurity.newFlag);
        }
        else {
            this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurity.editFlag);
        }
        this.actionbarService.updateState(this.actionBarHandler);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private getVehicleConfigurationData() {
        if (this.id) {
            this.getVehicleConfiguration();
        }
    }

    getVehicleConfiguration() {
        this.vehicleConfigurationService.get(this.id)
            .subscribe(
                (response: VehicleConfigurationModel) => {
                    if (this.copy) {
                        this.fillCopyData(response);
                    } else {
                        this.fillCurrentData(response);
                    }
                }
            )
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    getServiceCategoryReferences() {
        this.serviceCategoryReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.serviceCategoryReferences$.next(response);
                }
            )
    }

    getProductServiceRequests() {
        this.specialServiceService.getServiceRequest()
            .subscribe(
                (response) => {
                    this.productServiceRequests$.next(response);
                    this.productServiceRequestDatas$.next(this.serviceRequestMapperService.toServiceRequestViews(response));
                }
            )
    }

    getSeatPropertyReferences() {
        this.seatPropertyReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.seatPropertyReferences$.next(response);
                }
            )
    }

    getSeatPropertyGroupReferences() {
        this.seatPropertyGroupReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.seatPropertyGroupReferences$.next(response);
                }
            )
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.new:
                this.new();
                this.updateActionBar();
                break;
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.cancel:
                this.backToSettings();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
            case ACTION_STATUS.copy:
                this.copyData();
                break;
            default:
                break;
        }
    }

    save() {
        this.saveProcessing = true;
        if (this.validateAll()) {
            this.alertBarService.hide();
            if (this.copy) {
                this.saveCopyData(this.createCopyCommand());
            } else {
                this.addEditData();
            }
        }
        else {
            this.displayAlertBar();
        }
    }

    private addEditData() {
        if (!this.id) {
            this.add(this.createAddCommand());
        }
        else {
            this.edit(this.createEditCommand());
        }
    }

    private validateAll(): boolean {
        if (this.generalDetailComponent.validateForm() &&
            this.serviceCategoryComponent.validateForm() &&
            this.vehicleConfigurationSeatPropertyComponent.validateForm()
        ) {
            return true;
        }
        return false;
    }

    private displayAlertBar() {
        if (this.displayAlertGeneralPanel()) {
            this.alertBarService.show(this.generalPanel, this.generalDetailComponent.getErrorMessageForm());
        } else if (this.displayAlertServiceCategogryPanel()) {
            this.alertBarService.show(this.serviceCategoryPanel, this.serviceCategoryComponent.getErrorMessageForm());
        } else if (this.displayAlertSeatPropertyPanel()) {
            this.alertBarService.show(this.seatPropertyDetailPanel, this.vehicleConfigurationSeatPropertyComponent.getErrorMessageForm());
        } else {
            this.alertBarService.hide();
        }
    }

    private displayAlertGeneralPanel(): boolean {
        if (this.generalDetailComponent?.processing) {
            if (!this.generalDetailComponent.validateForm()) {
                return true;
            }
        }
        return false;
    }

    private displayAlertServiceCategogryPanel(): boolean {
        if (this.serviceCategoryComponent?.saveProcessing) {
            if (!this.serviceCategoryComponent.validateForm()) {
                return true;
            }
        }
        return false;
    }

    private displayAlertSeatPropertyPanel(): boolean {
        if (this.vehicleConfigurationSeatPropertyComponent) {
            if (!this.vehicleConfigurationSeatPropertyComponent.validateForm()) {
                return true;
            }
        }
        return false;
    }

    add(command: VehicleConfigurationAddCommandModel) {
        if (command) {
            this.spinnerService.showSaving();
            this.vehicleConfigurationService.add(command)
                .subscribe(
                    (response) => {
                        this.saveProcessing = false;
                        this.fillCurrentData(response);
                        this.spinnerService.saveComplete();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    edit(command: VehicleConfigurationEditCommandModel) {
        if (command) {
            this.spinnerService.showSaving();
            this.vehicleConfigurationService.edit(command)
                .subscribe(
                    (response) => {
                        this.saveProcessing = false;
                        this.fillCurrentData(response);
                        this.spinnerService.saveComplete();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    saveCopyData(command: VehicleConfigurationEditCommandModel) {
        if (command) {
            this.spinnerService.showSaving();
            this.vehicleConfigurationService.copy(command)
                .subscribe(
                    (response) => {
                        this.saveProcessing = false;
                        this.copy = false;
                        this.fillCurrentData(response);
                        this.spinnerService.saveComplete();
                    },
                    () => {
                        this.spinnerService.hide();
                    }
                )
        }
    }

    private createAddCommand(): VehicleConfigurationAddCommandModel {
        let command = this.generalDetailComponent.getAddValue();
        command.vehicleConfigurationCompartments = this.serviceCategoryComponent.getValue();
        command.vehicleConfigurationProducts = this.serviceRequestComponent.getValue();
        command.productInventories = this.serviceRequestInventoryComponent.getValue();
        return command;
    }

    private createEditCommand(): VehicleConfigurationEditCommandModel {
        let command = this.generalDetailComponent.getEditValue();
        command.vehicleConfigurationCompartments = this.serviceCategoryComponent.getValue();
        command.vehicleConfigurationProducts = this.serviceRequestComponent.getValue();
        command.productInventories = this.serviceRequestInventoryComponent.getValue();
        command.vehicleConfigurationCompartmentProperties = this.vehicleConfigurationSeatPropertyComponent.getValue();
        return command;
    }

    private createCopyCommand(): VehicleConfigurationEditCommandModel {
        let command = this.generalDetailComponent.getEditValue();
        command.vehicleConfigurationCompartments = this.serviceCategoryComponent.getValue();
        command.vehicleConfigurationProducts = this.serviceRequestComponent.getValue();
        command.productInventories = this.serviceRequestInventoryComponent.getValue();
        command.vehicleConfigurationCompartments = this.serviceCategoryComponent.getValueCopy();
        command.vehicleConfigurationCompartmentProperties = this.vehicleConfigurationSeatPropertyComponent.getValue();
        return command;
    }

    private back() {
        let params = {
            selectedTab: 'SEARCH',
            module: 'Vehicle Configuration',
            seatmap: this.seatmap
        }
        this.navigateService.navigate("main/settings/vehicle", this.previousTitle, null, params);
    }

    private new() {
        this.id = null;
        this.clearCurrentData();
        this.alertBarService.hide();
        this.copy = false;
        this.addAction();
        this.changeDetectorRef.detectChanges();
    }

    private refresh() {
        this.clearCurrentData();
        this.alertBarService.hide();
        this.getVehicleConfigurationData();
        this.initialFocusInCondition();
    }

    private copyData() {
        if (!this.id) {
            return;
        }
        this.clearCurrentDataCopy();
        this.alertBarService.hide();
        this.copy = true;
        this.changeDetectorRef.detectChanges();
        this.getVehicleConfigurationData();
    }

    onformStatusChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.generalDetailComponent.validateForm()) {
                this.alertBarService.show(this.generalPanel, this.generalDetailComponent.getErrorMessageForm());
            }
        }
    }

    onformStatusSeatPropertyChange(formStatus: boolean) {
        if (formStatus) {
            this.displayAlertBar();
        }
        else {
            if (!this.vehicleConfigurationSeatPropertyComponent.validateForm()) {
                this.alertBarService.show(this.seatPropertyDetailPanel, this.vehicleConfigurationSeatPropertyComponent.getErrorMessageForm());
            }
        }
    }

    onServiceCateDataChange() {
        this.displayAlertBar();
    }

    onActiveIdChange(activeId: string) {
        if (activeId == this.generalTabId) {
            this.showSeatMap = false;
            this.focusingService.focus(this.generalDetailComponent.focusingDirective);
        }
        if (activeId == this.serviceCateTabId) {
            this.showSeatMap = false;
            this.focusingService.focus(this.serviceCategoryComponent.focusingDirective);
        }
        if (activeId == this.serviceRequestTabId) {
            this.showSeatMap = false;
            this.focusingService.focus(this.serviceRequestComponent.focusingDirective);
        }
        if (activeId == this.seatMapTabId) {
            this.showSeatMap = true;
            this.focusingService.focus(this.serviceCategorySeatMapComponent.focusingDirective);
        }
    }

    private subscribeToPanelsFocus() {
        this.generalDetailComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.generalTabId;
                    }
                }
            );

        this.serviceCategoryComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.serviceCateTabId;
                    }
                }
            );

        this.serviceRequestComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.serviceRequestTabId;
                    }
                }
            );

        this.serviceCategorySeatMapComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                focusing => {
                    if (focusing) {
                        this.selectedTab = this.seatMapTabId;
                    }
                }
            );
    }

    onAlertBarClick() {
        if (!this.generalDetailComponent.validateForm()) {
            this.showSeatMap = false;
            this.focusingService.focus(this.generalDetailComponent.focusingDirective);
        } else if (!this.serviceCategoryComponent.validateForm()) {
            this.showSeatMap = false;
            this.focusingService.focus(this.serviceCategoryComponent.focusingDirective);
        } else if (!this.vehicleConfigurationSeatPropertyComponent.validateForm()) {
            this.showSeatMap = true;
            this.focusingService.focus(this.vehicleConfigurationSeatPropertyDetailComponent.focusingDirective);
        }
    }

    private fillCurrentData(response: VehicleConfigurationModel) {
        this.id = response.vehicleConfigurationId;
        this.vehicleConfiguration$.next(response);
        this.vehicleConfigurationCompartments$.next(response.vehicleConfigurationCompartments);
        this.vehicleConfigurationProducts$.next(response.vehicleConfigurationProducts);
        this.vehicleConfigurationInventories$.next(response.vehicleConfigurationInventories);
        this.vehicleConfigurationSeatPropertyComponent.updateIdToExistView(response.vehicleConfigurationCompartments);
        this.displayName = this.displayConfigName(response.vehicleConfigurationName);
        this.addAction();
    }

    private fillCopyData(response: VehicleConfigurationModel) {
        this.vehicleConfiguration$.next(this.setCopyVehicleConfiguration(response));
        this.vehicleConfigurationCompartments$.next(response.vehicleConfigurationCompartments);
        this.vehicleConfigurationProducts$.next(this.setCopyVehicleConfigurationProduct(response.vehicleConfigurationProducts));
        this.vehicleConfigurationInventories$.next(this.setCopyVehicleConfigurationInventory(response.vehicleConfigurationInventories));
        this.vehicleConfigurationSeatPropertyComponent.updateIdToExistView(response.vehicleConfigurationCompartments);
        this.addAction();
    }

    private clearCurrentData() {
        this.vehicleConfiguration$ = new BehaviorSubject<VehicleConfigurationModel>(null);
        this.vehicleConfigurationCompartments$ = new BehaviorSubject<VehicleConfigurationCompartmentModel[]>(null);
        this.vehicleConfigurationProducts$ = new BehaviorSubject<VehicleConfigurationProductModel[]>(null);
        this.vehicleConfigurationInventories$ = new BehaviorSubject<VehicleConfigurationInventoryModel[]>(null);
        this.serviceRequestProductIdSelected = [];
        this.serviceCategorydSelected = null;
        this.seatMapRowSelected = [];
        this.seatMapColSelected = "";
        this.seatMapColAll = "";
        this.showSeatMap = false;
        this.selectedTab = this.generalTabId;
        this.saveProcessing = false;
        this.serviceCategoryComponent.createNewServiceCategoryView();
        this.serviceRequestComponent.delete();
        this.generalDetailComponent.formSetup();
        this.paggingDataView = [];
        this.displayName = "";
    }

    onServiceRequestProductIdSelected(value) {
        this.serviceRequestProductIdSelected = value;
    }

    onServiceCategoryRowSelected(value) {
        this.displaySeatMap(true).then(() => {
            this.changeToSeatMapTab().then(() => {
                this.serviceCategorydSelected = value;
                this.focusSeatMap();
            });
        });
    }

    onServiceCategorySeatMapRowSelected(value) {
        this.serviceCategorydSelected = value;
        this.focusSeatMap();
    }

    onSeatMapRowSelected(value) {
        this.seatMapRowSelected = value;
        this.vehicleConfigurationSeatPropertyComponent.updateSeatMapRowSelected(this.seatMapRowSelected);
    }

    onSeatMapColAllChanges(value) {
        this.seatMapColAll = value;
        this.vehicleConfigurationSeatPropertyComponent.updateSeatMapColAll(this.seatMapColAll);
    }

    onSeatMapColSelected(value) {
        this.seatMapColSelected = value;
        this.vehicleConfigurationSeatPropertyComponent.updateSeatMapColSelected(this.seatMapColSelected, this.seatMapColAll);
    }

    private displaySeatMap(value: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            this.showSeatMap = value;
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    private changeToSeatMapTab(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.selectedTab = this.seatMapTabId;
            this.focusServiceCateSeatMap();
            this.changeDetectorRef.detectChanges();
            resolve();
        });
    }

    onSeatPropertySelected(value) {
        this.vehicleConfigurationSeatmapComponent.updateSeatMapPropertySelected(value);
    }

    private setCopyVehicleConfiguration(response: VehicleConfigurationModel): VehicleConfigurationModel {
        response.vehicleConfigurationCode = "";
        response.vehicleConfigurationName = "";
        response.commitByName = "";
        response.commitDateTime = null;
        return response;
    }

    private setCopyVehicleConfigurationProduct(vehicleConfigurationProducts: VehicleConfigurationProductModel[]): VehicleConfigurationProductModel[] {
        for (let product of vehicleConfigurationProducts) {
            product.vehicleConfigurationProductId = undefined;
        }
        return vehicleConfigurationProducts;
    }

    private setCopyVehicleConfigurationInventory(vehicleConfigurationInventories: VehicleConfigurationInventoryModel[]): VehicleConfigurationInventoryModel[] {
        if (!vehicleConfigurationInventories) {
            return null;
        }
        for (let inventory of vehicleConfigurationInventories) {
            inventory.productInventoryId = undefined;
        }
        return vehicleConfigurationInventories;
    }

    private initialFocusInCondition() {
        if (!this.seatmap) {
            this.generalDetailComponent.focusing = true;
        }
        else {
            this.displaySeatMap(true).then(() => {
                this.changeToSeatMapTab();
            });
        }
    }

    private focusServiceCateSeatMap() {
        setTimeout(() => {
            this.focusingService.focus(this.serviceCategorySeatMapComponent.focusingDirective);
        }, this.FOCUS_DELAY_TIME);
    }

    private focusSeatMap() {
        setTimeout(() => {
            this.focusingService.focus(this.vehicleConfigurationSeatmapComponent.focusingDirective);
        }, this.FOCUS_DELAY_TIME);
    }

    private addAction() {
        if (this.id) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.id, this.vehicleConfiguration$.value?.vehicleConfigurationName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private getParams() {
        let params = this.navigateService.getParams();
        this.id = params?.id ?? null;
        this.copy = params?.copy ?? false;
        this.seatmap = params?.seatmap ?? false;
        this.paggingDataView = params?.paggingDataView ?? [];
        this.currentPageIndex = params?.currentPageIndex ?? null;
    }

    public onPageChange(id: string): void {
        if (this.id != id) {
            this.id = id;
            this.clearSeatMap();
            this.getVehicleConfiguration();
        }
    }

    private backToSettings() {
        let params = {
            home: true
        }
        this.navigateService.navigate("main/settings/vehicle", this.previousTitle, null, params);
    }

    private clearCurrentDataCopy() {
        this.selectedTab = this.generalTabId;
        this.saveProcessing = false;
        this.generalDetailComponent.formSetup();
        this.paggingDataView = [];
    }

    private clearSeatMap() {
        this.serviceRequestProductIdSelected = [];
        this.serviceCategorydSelected = null;
        this.seatMapRowSelected = [];
        this.seatMapColSelected = "";
        this.seatMapColAll = "";
        this.vehicleConfigurationCompartments$ = new BehaviorSubject<VehicleConfigurationCompartmentModel[]>(null);
    }

    private displayConfigName(name: string) {
        return " :: " + name;
    }
}

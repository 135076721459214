import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { LanguageReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NameView } from "../shared/name-view";

@Component({
    selector: 'op-insight-name-table',
    templateUrl: './name-table.component.html'
})
export class InsightNameTableComponent implements AfterViewInit {
    public selectedItem: NameView;
    public itemSelected: NameView;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() insightNames: NameView[] = new Array();
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Output() onEdit = new EventEmitter();

    @ViewChild('dataGridName', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        

        this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                LastName: dat.lastName,
                FirstName: dat.firstName,
                MiddleName: dat.middleName,
                Language: this.languageReferences?.filter(item => item.languageCode == dat.languageCode)[0]?.languageName,
                PrimaryName: dat.primaryFlag,
                Status: this.statusReferences.filter((dat) => dat.statusCode == dat.statusCode)[0]?.displayName
            }
        ));
    }
    
    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "no") {
            e.cellElement.css("text-align", "right");  
        }
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    editInsightDetail() {
        this.onEdit.emit();
    }

    getLanguageName = (row: NameView) => {
        return this.languageReferences?.filter(item => item.languageCode == row.languageCode)[0]?.languageName;
    }
}
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { Subject, Subscription } from 'rxjs';
import { SCHEDULE_HOME } from './schedule-home.constant';
import { ScheduleCategory } from '../schedule-categories/schedule-category';
import { ScheduleCategoriesComponent } from '../schedule-categories/schedule-categories.component';
import { ActionService } from 'src/app/core/utils/action.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';

@Component({
    selector: 'op-schedule-home',
    templateUrl: './schedule-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ActionbarService
    ]
})
export class ScheduleHomeComponent {
    readonly SETTING_ROUTE_PATH = '/main/schedule/';
    readonly DEFAULT_TITLE = 'Schedule Management';
    
    @Input() selectedTab: string = SCHEDULE_HOME.categories;

    @Output() selectedTabChange = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<ScheduleCategory>();

    @ViewChild("scheduleCategory") scheduleCategory: ScheduleCategoriesComponent;
    
    public actionBarHandler = new ActionBarHandlerModel(
        new RefreshButtonModel()
    );

    private unsubscribe$ = new Subject();
    private _navigationSubscription: Subscription;

    public selectedCategory: ScheduleCategory;
    public title: String = this.DEFAULT_TITLE;

    constructor(private navigateService: NavigationService,
        public alertBarService: AlertBarService,
        private actionService: ActionService,) { }

    ngOnInit(): void {
        let params = this.navigateService.getParams();
        let tab = params?.tab ?? null;
        let module = params?.module ?? null;
        if (tab && module) {
            this.selectedTab = tab;
            if (!module?.code) {
                this.selectedCategory = this.createSelectedItem(module);
            }
            else {
                this.selectedCategory = module;
            }
        }
        this.actionService.add(this.selectedTab, null, null);
    }

    onTabChange(activeId: string) {
        this.selectedTab = activeId;
        this.actionService.add(this.selectedTab, null, null);
    }

    onMenuSelected(activeId: string) {
        this.selectedTab = activeId.toLowerCase();
    }

    onRowSelected(item: ScheduleCategory) {
        this.selectedCategory = item;
    }

    createSelectedItem(name: string) : ScheduleCategory{
        var item = {} as ScheduleCategory
        item.name = name;
        item.url = this.SETTING_ROUTE_PATH + name;
        return item;
    }

    onCancelSearchRequested() {
        this.onTabChange(SCHEDULE_HOME.categories);
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { SecurityGroupModule } from './security-group/security-group.module';
import { SecurityCodeModule } from './security-code/security-code.module';
import { SecurityAuditsModule } from './security-audits/security-audits.module';
import { SecurityReportsModule } from './security-reports/security-reports.module';
import { SecurityLevel2TableModule } from './shared/security-level2-table/security-level2-table.module';
import { SecurityLevel2TabsModule } from './shared/security-level2-tabs/security-level2-tabs.module';
import { SecurityFunctionsModule } from './security-functions/security-functions.module';
import { SecurityManagementComponent } from './security-management.component';


@NgModule({
    declarations: [
    SecurityManagementComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        NgbNavModule,
        RouterModule,
        SecurityAuditsModule,
        SecurityCodeModule,
        SecurityFunctionsModule,
        SecurityGroupModule,
        SecurityLevel2TableModule,
        SecurityLevel2TabsModule,
        SecurityReportsModule
    ],
    exports: [SecurityManagementComponent]
})
export class SecurityManagementModule { }

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { SeatPropertyView } from "../shared/seatproperty.view";

@Component({
    selector: 'op-vehicle-configuration-seatproperty-list',
    templateUrl: './seatproperty-list.component.html',
    providers: []
})
export class VehicleConfigurationSeatPropertyListComponent implements OnChanges, AfterViewInit {
    public readonly EXPORT_FILE_NAME = "SeatPropertySearchResult";
    @Input() id: string;
    @Input() serviceCategorydSelected: Select2Data;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() seatPropertyServiceCategoryViews: SeatPropertyView[] = new Array();
    @Output() new = new EventEmitter();
    @Output() edit = new EventEmitter<SeatPropertyView>();
    @Output() copy = new EventEmitter<SeatPropertyView>();
    @Output() rowSelected = new EventEmitter<SeatPropertyView>();
    @Output() refresh = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    focusing: boolean = false;
    selectedItem: SeatPropertyView;
    serviceCateName: string;
    rows = 0;
    exportData: any;
    header: string[];
    pdfDefaultStyle = {
        fontSize: 7
    };

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setServiceCateName();
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    private setServiceCateName() {
        if (this.serviceCategorydSelected) {
            this.serviceCateName = "-" + this.serviceCategorydSelected.text;
        }
    }

    onNew() {
        this.new.emit();
    }

    onEdit() {
        if (this.selectedItem) {
            this.edit.emit(this.selectedItem);
        }
    }

    onCopy() {
        if (this.selectedItem) {
            this.copy.emit(this.selectedItem);
        }
    }

    onRefresh() {
        this.refresh.emit();
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
        this.rowSelected.emit(this.selectedItem);
    }

    updateStatus(e) {
        this.selectedItem.statusCode = e;
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Group: data.seatPropertyGroupName,
            Property: data.seatPropertyName,
            RowFrom: data.seatRowFrom,
            RowTo: data.seatRowTo,
            ByRow: data.byRowFlag,
            Column: data.seatColumnRange,
            Product: data.productName,
            Status: this.getStatusName(data.statusCode),
        };
    }

    private getStatusName(statusCode: string) {
        return this.statusReferences.find((item) => item.statusCode == statusCode)
            ?.displayName;
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }
}
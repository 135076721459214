import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { SecurityGroupSearchTableComponent } from './security-group-search-table/security-group-search-table.component';
import { takeUntil } from "rxjs/operators";
import { SecurityGroupSearchConditionComponent } from './security-group-search-condition/security-group-search-condition.component';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-group-search',
    templateUrl: './security-group-search.component.html'
})
export class SecurityGroupSearchComponent implements OnInit {

    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    private unsubscribe$ = new Subject();
    @Input() executeFlag = true;
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Input() securities: SecurityGroupSecurityModel[];

    @ViewChild(SecurityGroupSearchConditionComponent) conditionComponent: SecurityGroupSearchConditionComponent;
    @ViewChild("securityGroupSearchTable") searchTable: SecurityGroupSearchTableComponent;
    constructor(private statusReferenceService: StatusReferenceService,
        private spinner: LoadingSpinnerService,
        private actionbarService: ActionbarService) { }

    ngOnInit(): void {
        this.getStatusReferences();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    searchClick(e) {
        this.spinner.show();
        this.searchTable.searchSecurityGroup(e);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.copy:
                this.copy();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
            default:
                break;
        }
    }

    copy() {
        this.searchTable.copySecuityGroup();
    }
  
    refresh() {
        this.onTableSearch();
    }

    public onTableSearch() {
        this.searchTable.searchSecurityGroup(this.conditionComponent.condition);
    }
}

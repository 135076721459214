import { OrdersDashboardOrdersCountModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-orders-count.model";

export const PRODUCT_DASHBOARD_SOLD_LAST30D_DEMO: OrdersDashboardOrdersCountModel[] = [
    {
        dayNo: 1,
        salesChannel1: 370,
        salesChannel1Name: "GDS",
        salesChannel2: 466,
        salesChannel2Name: "API",
        salesChannel3: 484,
        salesChannel3Name: "ISE",
        salesChannel4: 470,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 2,
        salesChannel1: 243,
        salesChannel1Name: "GDS",
        salesChannel2: 286,
        salesChannel2Name: "API",
        salesChannel3: 206,
        salesChannel3Name: "ISE",
        salesChannel4: 405,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 3,
        salesChannel1: 348,
        salesChannel1Name: "GDS",
        salesChannel2: 324,
        salesChannel2Name: "API",
        salesChannel3: 173,
        salesChannel3Name: "ISE",
        salesChannel4: 256,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 4,
        salesChannel1: 464,
        salesChannel1Name: "GDS",
        salesChannel2: 444,
        salesChannel2Name: "API",
        salesChannel3: 184,
        salesChannel3Name: "ISE",
        salesChannel4: 239,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 5,
        salesChannel1: 477,
        salesChannel1Name: "GDS",
        salesChannel2: 429,
        salesChannel2Name: "API",
        salesChannel3: 500,
        salesChannel3Name: "ISE",
        salesChannel4: 225,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 6,
        salesChannel1: 158,
        salesChannel1Name: "GDS",
        salesChannel2: 167,
        salesChannel2Name: "API",
        salesChannel3: 159,
        salesChannel3Name: "ISE",
        salesChannel4: 181,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 7,
        salesChannel1: 439,
        salesChannel1Name: "GDS",
        salesChannel2: 245,
        salesChannel2Name: "API",
        salesChannel3: 256,
        salesChannel3Name: "ISE",
        salesChannel4: 152,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 8,
        salesChannel1: 273,
        salesChannel1Name: "GDS",
        salesChannel2: 396,
        salesChannel2Name: "API",
        salesChannel3: 376,
        salesChannel3Name: "ISE",
        salesChannel4: 155,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 9,
        salesChannel1: 428,
        salesChannel1Name: "GDS",
        salesChannel2: 490,
        salesChannel2Name: "API",
        salesChannel3: 257,
        salesChannel3Name: "ISE",
        salesChannel4: 448,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 10,
        salesChannel1: 325,
        salesChannel1Name: "GDS",
        salesChannel2: 353,
        salesChannel2Name: "API",
        salesChannel3: 427,
        salesChannel3Name: "ISE",
        salesChannel4: 277,
        salesChannel4Name: "OWN"
    },    {
        dayNo: 11,
        salesChannel1: 360,
        salesChannel1Name: "GDS",
        salesChannel2: 290,
        salesChannel2Name: "API",
        salesChannel3: 275,
        salesChannel3Name: "ISE",
        salesChannel4: 184,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 12,
        salesChannel1: 230,
        salesChannel1Name: "GDS",
        salesChannel2: 355,
        salesChannel2Name: "API",
        salesChannel3: 327,
        salesChannel3Name: "ISE",
        salesChannel4: 485,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 13,
        salesChannel1: 359,
        salesChannel1Name: "GDS",
        salesChannel2: 455,
        salesChannel2Name: "API",
        salesChannel3: 420,
        salesChannel3Name: "ISE",
        salesChannel4: 161,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 14,
        salesChannel1: 145,
        salesChannel1Name: "GDS",
        salesChannel2: 391,
        salesChannel2Name: "API",
        salesChannel3: 223,
        salesChannel3Name: "ISE",
        salesChannel4: 465,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 15,
        salesChannel1: 388,
        salesChannel1Name: "GDS",
        salesChannel2: 429,
        salesChannel2Name: "API",
        salesChannel3: 275,
        salesChannel3Name: "ISE",
        salesChannel4: 274,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 16,
        salesChannel1: 494,
        salesChannel1Name: "GDS",
        salesChannel2: 406,
        salesChannel2Name: "API",
        salesChannel3: 319,
        salesChannel3Name: "ISE",
        salesChannel4: 418,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 17,
        salesChannel1: 412,
        salesChannel1Name: "GDS",
        salesChannel2: 313,
        salesChannel2Name: "API",
        salesChannel3: 460,
        salesChannel3Name: "ISE",
        salesChannel4: 353,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 18,
        salesChannel1: 172,
        salesChannel1Name: "GDS",
        salesChannel2: 222,
        salesChannel2Name: "API",
        salesChannel3: 401,
        salesChannel3Name: "ISE",
        salesChannel4: 247,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 19,
        salesChannel1: 187,
        salesChannel1Name: "GDS",
        salesChannel2: 184,
        salesChannel2Name: "API",
        salesChannel3: 490,
        salesChannel3Name: "ISE",
        salesChannel4: 396,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 20,
        salesChannel1: 320,
        salesChannel1Name: "GDS",
        salesChannel2: 265,
        salesChannel2Name: "API",
        salesChannel3: 306,
        salesChannel3Name: "ISE",
        salesChannel4: 100,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 21,
        salesChannel1: 400,
        salesChannel1Name: "GDS",
        salesChannel2: 379,
        salesChannel2Name: "API",
        salesChannel3: 417,
        salesChannel3Name: "ISE",
        salesChannel4: 194,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 22,
        salesChannel1: 321,
        salesChannel1Name: "GDS",
        salesChannel2: 221,
        salesChannel2Name: "API",
        salesChannel3: 138,
        salesChannel3Name: "ISE",
        salesChannel4: 315,
        salesChannel4Name: "OWN"
    },    
    {
        dayNo: 23,
        salesChannel1: 450,
        salesChannel1Name: "GDS",
        salesChannel2: 493,
        salesChannel2Name: "API",
        salesChannel3: 459,
        salesChannel3Name: "ISE",
        salesChannel4: 459,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 24,
        salesChannel1: 415,
        salesChannel1Name: "GDS",
        salesChannel2: 397,
        salesChannel2Name: "API",
        salesChannel3: 300,
        salesChannel3Name: "ISE",
        salesChannel4: 169,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 25,
        salesChannel1: 326,
        salesChannel1Name: "GDS",
        salesChannel2: 342,
        salesChannel2Name: "API",
        salesChannel3: 495,
        salesChannel3Name: "ISE",
        salesChannel4: 381,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 26,
        salesChannel1: 208,
        salesChannel1Name: "GDS",
        salesChannel2: 104,
        salesChannel2Name: "API",
        salesChannel3: 394,
        salesChannel3Name: "ISE",
        salesChannel4: 487,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 27,
        salesChannel1: 135,
        salesChannel1Name: "GDS",
        salesChannel2: 482,
        salesChannel2Name: "API",
        salesChannel3: 345,
        salesChannel3Name: "ISE",
        salesChannel4: 398,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 28,
        salesChannel1: 342,
        salesChannel1Name: "GDS",
        salesChannel2: 327,
        salesChannel2Name: "API",
        salesChannel3: 197,
        salesChannel3Name: "ISE",
        salesChannel4: 417,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 29,
        salesChannel1: 385,
        salesChannel1Name: "GDS",
        salesChannel2: 342,
        salesChannel2Name: "API",
        salesChannel3: 111,
        salesChannel3Name: "ISE",
        salesChannel4: 213,
        salesChannel4Name: "OWN"
    },
    {
        dayNo: 30,
        salesChannel1: 125,
        salesChannel1Name: "GDS",
        salesChannel2: 102,
        salesChannel2Name: "API",
        salesChannel3: 315,
        salesChannel3Name: "ISE",
        salesChannel4: 239,
        salesChannel4Name: "OWN"
    }
]
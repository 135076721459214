import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSummernoteModule } from 'ngx-summernote';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DxDataGridModule } from 'devextreme-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PanelsModule } from '../../../shared/panels/panels.module';
import { DialogsModule } from '../../../shared/dialogs/dialogs.module';
import { DialogsService } from '../../../shared/dialogs/dialogs.service';
import { Select2Module } from '../../../shared/ui/forms/inputs/oops-select2/select2.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DaterangepickerModule } from '../../../shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { Helper } from '../../../shared/helper/app.helper';

import { TranslationTextComponent } from './translation-text.component';
import { TranslationTextDetailComponent } from './translation-text-detail/translation-text-detail.component';
import { TranslationTextDetailDirective } from './translation-text-detail/translation-text-detail.directive';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        PanelsModule,
        ReactiveFormsModule,
        FormsModule,
        DialogsModule,
        MatIconModule,
        NgbModule,
        Select2Module,
        CollapseModule,
        DaterangepickerModule,
        NgxSummernoteModule,
        ButtonsModule,
        BsDropdownModule,
        UtilsModule,
        TooltipModule,
        DxDataGridModule,
        SweetAlert2Module,
        FocusingModule
    ],
    providers: [
        DialogsService, Helper
    ],
    declarations: [TranslationTextComponent, TranslationTextDetailComponent, TranslationTextDetailDirective],
    exports: [TranslationTextComponent, TranslationTextDetailComponent, TranslationTextDetailDirective]
})
export class TranslationTextModule {

}
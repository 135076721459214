import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { PaymentSearch } from "./payment-search.model";
import { tap, share, map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PaymentCreditAccountService extends ApiServiceBase {
    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    private _paymentSearch$ = new BehaviorSubject<PaymentSearch>(null)

    get paymentSearch$() {
        return this._paymentSearch$.asObservable()
    }

    selectPaymentSearch(paymentSearch: PaymentSearch) {
        this._paymentSearch$.next(paymentSearch)
    }

    getCreditAccountBalance(paymentOrganisationId: string): Observable<number>{
        return this.httpGet<unknown>('/payment/PaymentsSearch/' + paymentOrganisationId + '/credit-account', null)
            .pipe(
                map((res: HttpResponse<number>) => res.body),
                share(),
            )
    }

    clearPaymentSearch() {
        this._paymentSearch$.next(null)
    }
}
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[specialServiceInventoryDirective]'
})
export class InventoryDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductNumberCommandModel, ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { TransportSearchConditionView } from '../../transport/transport-content/transport-search/shared/transport-search-condition.view';

@Injectable()
export class NumberMapperService {
    private readonly OPERATINGCODE: string = "OPERATING";
    public productNumberFormToModels(forms, provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberCommandModel[] {
        let models: ProductNumberCommandModel[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    models.push(this.productNumberFormToModel(i, ctl.controls, provider$));
                }
            }
        }
        return models;
    }

    private productNumberFormToModel(index: number, value, provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberCommandModel {
        let model = new ProductNumberCommandModel();
        model.productNumberTypeCode = value.productNumberTypeCode.value;
        model.productNumber = value.productNumber.value;
        model.providerId = value.providerId.value;
        model.providerCode = this.getProviderCode(model.providerId, provider$);
        model.supplierId = value.supplierId.value;
        model.displaySequence = index;
        return model;
    }

    private getProviderCode(providerId: string, provider$: BehaviorSubject<OrganisationModel[]>): string {
        if (provider$.value && providerId) {
            var filter = provider$.value.filter(x => x.organisationId == providerId);
            if (filter?.length) {
                return filter[0].providerIataCode;
            }
        }
    }

    public transportConditionSearchToModel(condition: TransportSearchConditionView,
        models: ProductNumberCommandModel[] = new Array(),
        provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberCommandModel[] {
        if (condition?.flightNumber || condition?.providerId) {
            let model = new ProductNumberCommandModel();
            model.productNumberTypeCode = null;
            model.productNumber = condition.flightNumber;
            model.providerId = condition.providerId;
            model.providerCode = this.getProviderCode(condition.providerId, provider$);
            model.supplierId = null;
            model.displaySequence = null;
            models.push(model);
        }
        return models;
    }

    public getProductNumberOperating(productNumbers: ProductNumberViewModel[]): string {
        if (productNumbers) {
            let filter = productNumbers.filter(x => x.productNumberTypeCode == this.OPERATINGCODE);
            if (filter?.length) {
                return filter[0].productNumber;
            }
            return null;
        }
    }

    public transportConditionSearchToView(condition: TransportSearchConditionView, provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberViewModel[] {
        let views: ProductNumberViewModel[] = new Array();
        if (condition?.flightNumber || condition?.providerId) {
            let view = new ProductNumberViewModel();
            view.productNumberTypeCode = null;
            view.productNumber = condition.flightNumber;
            view.providerId = condition.providerId;
            view.providerCode = this.getProviderCode(condition.providerId, provider$);
            view.supplierId = null;
            view.displaySequence = null;
            views.push(view);
        }
        return views;
    }

    public productNumberFormToViews(forms, provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberViewModel[] {
        let models: ProductNumberViewModel[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_VALID) {
                    ++i;
                    models.push(this.productNumberFormToView(i, ctl.controls, provider$));
                }
            }
        }
        return models;
    }

    private productNumberFormToView(index: number, value, provider$: BehaviorSubject<OrganisationModel[]>): ProductNumberViewModel {
        let model = new ProductNumberViewModel();
        model.productNumberTypeCode = value.productNumberTypeCode.value;
        model.productNumber = value.productNumber.value;
        model.providerId = value.providerId.value;
        model.providerCode = this.getProviderCode(model.providerId, provider$);
        model.supplierId = value.supplierId.value;
        model.displaySequence = index;
        return model;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { ExchangeRateAmountCommandModel, ExchangeRateAmountModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateAmountService extends ApiServiceBase {
    private readonly PATH = '/system/exchangerateamounts';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<ExchangeRateAmountModel[]> {
        return this.httpGet<ExchangeRateAmountModel[]>(this.PATH, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public getByExchangeRateId(exchangeRateId: string): Observable<ExchangeRateAmountModel[]> {
        return this.httpGet<ExchangeRateAmountModel[]>(this.PATH + '/' + exchangeRateId, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }

    public add(command: ExchangeRateAmountCommandModel[]) {
        return this.httpPost<ExchangeRateAmountCommandModel[]>(this.PATH, command)
            .pipe(
                map(res => res.body)
            )
    }
}

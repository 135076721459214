import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { VehicleModel } from 'src/app/core/models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class VehicleService extends ApiServiceBase {
    private readonly PATH = "/airline/vehicles"

    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    public getVehicleAll(): Observable<VehicleModel[]> {
        return this.httpGet<VehicleModel[]>(this.PATH + '/organisation', null)
            .pipe(
                map(res => res.body)
            )
    }

    public getAll(): Observable<VehicleModel[]> {
        return this.httpGet<VehicleModel[]>(this.PATH , null)
            .pipe(
                map(res => res.body)
            )
    }

}
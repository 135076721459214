export class ProductDateTimeCommandModel {
    public productDateTimeId: string;
    public localDateTime: Date;
    public productInventoryId: string;
    public operatingProductId: string;
    public dateTimeCode: string
    public displaySequence: number;
    public productId: string;
    public locationId: string;
}

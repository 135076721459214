import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class RouteSetRequireFieldService {

    public clearRequireRoute(ctl) {
        ctl.get('operatorRoute').clearValidators();
        ctl.get('operatorRoute').updateValueAndValidity();

        ctl.get('originRoute').clearValidators();
        ctl.get('originRoute').updateValueAndValidity();

        ctl.get('destinationRoute').clearValidators();
        ctl.get('destinationRoute').updateValueAndValidity();
    }

    public setRequireRoute(ctl) {
        ctl.get('operatorRoute').setValidators(Validators.required);
        ctl.get('operatorRoute').updateValueAndValidity();

        ctl.get('originRoute').setValidators(Validators.required);
        ctl.get('originRoute').updateValueAndValidity();

        ctl.get('destinationRoute').setValidators(Validators.required);
        ctl.get('destinationRoute').updateValueAndValidity();
    }

}
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { InsightOrderSearchModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search.model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { OrderSummaryView } from "../shared/views/order-summary-view";

@Component({
    selector: 'op-insight-sales-summary-summary',
    templateUrl: './insight-sales-summary-summary.component.html'
})
export class InsightSalesSummarySummaryComponent implements OnInit, OnChanges {
    public orderSummaryView: OrderSummaryView;
    public focusing: boolean = false;

    @Input() insightOrderSearchResults: InsightOrderSearchModel[];
    @Input() productAmount = 0;
    
    constructor(private dateConverterService: DateConverterService) {

    }

    ngOnInit(): void {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insightOrderSearchResults']) {
            this.setOrderSummaryView();
        }
    }

    private setOrderSummaryView() {
        let currency = "";
        let orderCount = 0;
        let sumAmount = 0;
        let sumPaidAmount = 0;
        let sumOutStandingBalance = 0;
        let lastOrder = "";
        
        if (this.insightOrderSearchResults?.length) {
            for(let result of this.insightOrderSearchResults) {
                currency = result.currencyCode;
                sumAmount += result.totalAmount;
                sumPaidAmount += result.paidAmount;
                sumOutStandingBalance += result.outstandingAmount;
                orderCount++;
            }
            
            lastOrder = this.dateConverterService.toDateFormat(this.insightOrderSearchResults[0].orderDate);
        }

        let avgAmount = orderCount == 0 ? orderCount : (sumAmount/orderCount);

        let view: OrderSummaryView = {
            currency: currency,
            totalAmount: sumAmount,
            totalPaidAmount: sumPaidAmount,
            totalOutStandingBalance: sumOutStandingBalance,
            numberOfOrders: orderCount,
            numberOfProducts: null,
            avgAmountPerOrder: avgAmount,
            lastOrder: lastOrder  
        }

        this.orderSummaryView = view;
    }
}
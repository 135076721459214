import { Injectable } from "@angular/core";
import {
  IndividualChangeCommunicationOtherModel,
  IndividualChangeCommunicationPhoneModel,
  IndividualChangeModel,
  IndividualChangeIdentityDocumentModel,
  IndividualChangeAddressModel
} from "src/app/core/models/order-model";
import { IndividualDetailFormView } from "../order-detail/orders-detail-individual/order-detail-individual-detail-form/views/individual-detail-form.view";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { OrderDetailIndividualCommunicationOtherView } from "./views/order-detail-individual-communication-other.view";
import { OrderDetailIndividualPhoneView } from "./views/order-detail-individual-phone.view";
import { OrderDetailIndividualDocumentView } from "./views/order-detail-individual-document.view";
import { OrderDetailIndividualAddressView } from "./views/order-detail-individual-address.view";

@Injectable()
export class IndividualChangeMapperService {
  constructor(private dateConverterService: DateConverterService) { }

  public toOrderDetailIndividual(view: IndividualDetailFormView, originalIndividualDetail: IndividualDetailFormView): IndividualChangeModel {

    if (!view) {
      return null;
    }
    return {
      individualId: view.individualId,
      referenceOrderId: null,
      orderId: view.orderId,
      individualNameId: view.individualName?.individualNameId,
      lastName: view.individualName?.lastName,
      firstName: view.individualName?.firstName,
      titleName: view.individualName?.titleName,
      titleCode: view.individualName?.titleCode,
      ageGroupName: view.ageGroupName,
      ageGroupCode: view.ageGroupCode,
      dateOfBirth: this.dateConverterService.convertToUtcDate(view.dateOfBirth),
      genderName: view.genderName,
      genderCode: view.genderCode,
      languageCode: view.languageCode,
      languageName: view.languageName,
      nationalityCountryCode: view.nationality,
      providerCode: view.providerCode,
      memberNumber: view.memberNumber,
      guardianIndividualId: view.guardian,
      originalIndividualId: originalIndividualDetail.individualId,
      originalIndividualNameId: originalIndividualDetail.individualName?.individualNameId,
      communicationPhoneId: view.phoneId,
      countryPhonePrefix: view.phonePrefix,
      phoneNumber: view.phoneNumber,
      phoneTypeCode: view.phoneTypeCode,
      phoneTypeName: view.phoneTypeName,
      communicationOtherId: view.communicationOtherId,
      communicationOtherTypeCode: view.communicationOtherTypeCode,
      communicationOtherTypeName: view.communicationOtherTypeName,
      mailAddress: view.communicationOtherIdentity
    };
  }
  public orderDetailIndividualToArray(view: IndividualDetailFormView, originalIndividualDetail: IndividualDetailFormView): IndividualChangeModel[] {

    if (!view) {
      return null;
    }
    return [this.toOrderDetailIndividual(view, originalIndividualDetail)];
  }
  public toOrderDetailIndividualCommunicationOther(view: OrderDetailIndividualCommunicationOtherView,
    individualId: string
  ): IndividualChangeCommunicationOtherModel {

    if (!view) {
      return null;
    }
    return {
      orderId: view.orderId,
      individualId: individualId,
      communicationOtherId: view.communicationOtherId,
      mailAddress: view.mailAddress,
      primaryFlag: view.primaryFlag,
      communicationOtherTypeCode: view.communicationOtherTypeCode,
      communicationOtherTypeName: view.communicationOtherTypeName
    };
  }
  public orderDetailIndividualCommunicationOthers(views: OrderDetailIndividualCommunicationOtherView[],
    individualId: string
  ): IndividualChangeCommunicationOtherModel[] {

    if (!views) {
      return null;
    }

    let models = new Array<IndividualChangeCommunicationOtherModel>();
    for (let view of views) {
      models.push(this.toOrderDetailIndividualCommunicationOther(view, individualId));
    }
    return models;
  }
  public toOrderDetailIndividualCommunicationPhone(view: OrderDetailIndividualPhoneView,
    individualId: string
  ): IndividualChangeCommunicationPhoneModel {

    if (!view) {
      return null;
    }
    return {
      orderId: view.orderId,
      individualId: individualId,
      communicationPhoneId: view.communicationPhoneId,
      countryPhonePrefix: view.countryPhonePrefix,
      communicationPhoneTypeCode: view.communicationPhoneTypeCode,
      communicationPhoneTypeName: view.communicationPhoneTypeName,
      phoneNumber: view.phoneNumber,
      primaryFlag: view.primaryFlag
    };
  }
  public orderDetailIndividualCommunicationPhones(views: OrderDetailIndividualPhoneView[],
    individualId: string
  ): IndividualChangeCommunicationPhoneModel[] {

    if (!views) {
      return null;
    }

    let models = new Array<IndividualChangeCommunicationPhoneModel>();
    for (let view of views) {
      models.push(this.toOrderDetailIndividualCommunicationPhone(view, individualId));
    }
    return models;
  }

  public toOrderDetailIndividualIdentityDocument(view: OrderDetailIndividualDocumentView,
    individualId: string): IndividualChangeIdentityDocumentModel {

    if (!view) {
      return null;
    }
    return {
      orderId: view.orderId,
      individualId: individualId,
      identityDocumentId: view.identityDocumentId,
      identityDocumentCode: view.identityDocumentCode,
      identityDocumentName: view.identityDocumentName,
      identityDocumentNumber: view.identityDocumentNumber,
      countryCode: view.countryCode,
      countryName: view.countryName,
      expiryDate: this.dateConverterService.convertToUtcDate(view.expiryDate),
      issueDate: this.dateConverterService.convertToUtcDate(view.issueDate),
      placeOfIssue: view.placeOfIssue
    };
  }

  public orderDetailIndividualIdentityDocuments(views: OrderDetailIndividualDocumentView[],
    individualId: string): IndividualChangeIdentityDocumentModel[] {

    if (!views) {
      return null;
    }

    let models = new Array<IndividualChangeIdentityDocumentModel>();
    for (let view of views) {
      models.push(this.toOrderDetailIndividualIdentityDocument(view, individualId));
    }
    return models;
  }

  public toOrderDetailIndividualAddress(view: OrderDetailIndividualAddressView,
    individualId: string): IndividualChangeAddressModel {

    if (!view) {
      return null;
    }
    return {
      orderId: view.orderId,
      individualId: individualId,
      addressId: view.addressId,
      orderAddressRoleCode: view.orderAddressRoleCode,
      addressTypeCode: view.addressTypeCode,
      addressTypeName: view.addressTypeName,
      addressLine1: view.addressLine1,
      addressLine2: view.addressLine2,
      state: view.state,
      province: view.province,
      city: view.city,
      postalCode: view.postalCode,
      countryCode: view.countryCode,
      countryName: view.countryName,
      primaryFlag: view.primaryFlag
    };
  }

  public orderDetailIndividualAddresses(views: OrderDetailIndividualAddressView[],
    individualId: string): IndividualChangeAddressModel[] {

    if (!views) {
      return null;
    }

    let models = new Array<IndividualChangeAddressModel>();
    for (let view of views) {
      models.push(this.toOrderDetailIndividualAddress(view, individualId));
    }
    return models;
  }
}

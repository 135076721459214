export const select2DefaultOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2DoNotAllowClear = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1
}
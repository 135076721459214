import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { LogCommitModel } from '../../models/log-commit-model/log-commit.model';

@Injectable({
    providedIn: 'root'
})
export class LogCommitService extends ApiServiceBase {
    private readonly PATH = '/system/logcommits';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getByCode(logCommitCode: string): Observable<LogCommitModel> {
        return this.httpGet<LogCommitModel>(this.PATH + '/' + logCommitCode, null)
            .pipe(
                map(
                    res => res.body
                )
            )
    }
}   
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { AlertBarComponent } from './alertbar.component';

@NgModule({
    declarations: [
        AlertBarComponent
    ],
    exports: [
        AlertBarComponent
    ],
    imports: [
        CommonModule,
        NgxSpinnerModule
    ],
    providers: [
        NgxSpinnerService
    ]
})
export class AlertBarModule { }
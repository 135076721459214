import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { SsimExportCommandModel, StandardScheduleInformationSearchModel } from "src/app/core/models/product-model/standard-schedule-information-model";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { StandardScheduleInformationService } from "src/app/core/services/schedule-services";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-standard-schedule-information-table',
    templateUrl: './standard-schedule-information-table.component.html',
})
export class StandardScheduleInformationTableComponent extends DatatableComponentBase implements AfterViewInit {
    readonly DATE_WITH_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

    @Input() userSecurity: SecurityGroupSecurityModel;
    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;

    public standardScheduleInformationSearchData: StandardScheduleInformationSearchModel[];

    public header: string[];
    public rows = 0;
    public selectedItem = false;
    public itemSelected: any;
    public exportData: any;

    private unsubscribe$ = new Subject();

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private dateConverterService: DateConverterService,
        private standardScheduleInformationService: StandardScheduleInformationService,
        private actionbarService: ActionbarService) {
        super()
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
        this.getStandardScheduleInformationData();

        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getStandardScheduleInformationData() {
        let dynamicDataArray = new Array();

        this.loadingSpinnerService.show();
        this.standardScheduleInformationService.getAll()
            .subscribe(
                (responses: StandardScheduleInformationSearchModel[]) => {
                    this.cleanDatatable();
                    let no = 1;
                    responses.forEach(item => {
                        let dataTemp = {};
                        dataTemp["no"] = no;
                        dataTemp["scheduleQueuePeriod"] = item.schedulePeriod;
                        dataTemp["periodStartDate"] =  this.dateConverterService.toDateFormat(item.periodStartDate);
                        dataTemp["periodEndDate"] =  this.dateConverterService.toDateFormat(item.periodEndDate);
                        dataTemp["seasonName"] = item.seasonName;
                        dataTemp["versionName"] = item.versionName;
                        dataTemp["numberOfSchedule"] = item.numberOfSchedule;
                        dataTemp["lastExportedDate"] = this.dateConverterService.convertDateString(item.lastExportedDate, this.DATE_WITH_TIME_FORMAT);
                        dataTemp["onQueueFlag"] = item.onProcessFlag ?? false;
                        dataTemp["onProcessFlag"] = item.onProcessFlag ?? false;
                        dataTemp["scheduleQueueSSIMs"] = item.scheduleQueueSSIMs;
                        dataTemp["productListSSIMs"] = item.productListSSIMs;
                        dataTemp["productScheduleVersionId"] = item.productScheduleVersionId;
                        dataTemp["productSchedulePeriodId"] = item.productSchedulePeriodId;
                        dynamicDataArray.push(dataTemp);
                        no++;
                    });

                    this.loadingSpinnerService.hide();
                    this.standardScheduleInformationSearchData = dynamicDataArray;
                    this.rows = this.standardScheduleInformationSearchData.length;
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }
            )
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.exportData = this.getExportData();
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public onEditorPreparing(e) {
        if (e.parentType === "dataRow" && e.dataField === "onQueueFlag") {
            if (e.row.data["onProcessFlag"]) {
                e.editorOptions.readOnly = true;
            }
        }
    }

    private actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.export:
                this.exportSSIM();
                break;
            case ACTION_STATUS.refresh:
                this.refresh();
                break;
        }
    }

    private refresh() {
        this.selectedItem = false;
        this.itemSelected = null;
        this.getStandardScheduleInformationData();
    }

    private exportSSIM() {
        let selectedRows = this.standardScheduleInformationSearchData.filter(x => x.onQueueFlag && !x.onProcessFlag);

        this.loadingSpinnerService.show();
        if (selectedRows) {
            let commands = this.setExportCommand(selectedRows);
            this.standardScheduleInformationService.exportSSIM(commands)
                .subscribe (
                    () => {
                        this.getStandardScheduleInformationData();
                    },
                    () => {
                        this.loadingSpinnerService.hide();
                    }
                )
        }
    }

    private setExportCommand(selectedRows: StandardScheduleInformationSearchModel[]): SsimExportCommandModel[] {
        let commands = new Array();

        for (let row of selectedRows) {
            let command: SsimExportCommandModel = {
                productSchedulePeriodId: row.productSchedulePeriodId,
                productScheduleVersionId: row.productScheduleVersionId,
            }

            commands.push(command);
        }

        return commands;
    }
}
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { ScheduleQueueService } from "src/app/core/services/schedule-services";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { ScheduleQueueResultCode } from "src/app/modules/schedule/shared/schedule.contant";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    selector: 'op-schedule-queue-task-table',
    templateUrl: './schedule-queue-task-table.component.html',
    providers: [],
})
export class ScheduleQueueTaskTableComponent implements OnChanges {
    readonly TASK_NEW_CODE = "NEW"
    readonly DATE_WITH_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

    public scheduleQueueTaskData: any;
    public header: string[];
    public rows = 0;

    @Input() parentScheduleQueueId: string;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private scheduleQueueService: ScheduleQueueService,
        private dateConverterService: DateConverterService) {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['parentScheduleQueueId']) {
            this.getData(this.parentScheduleQueueId);
        }
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'result') {
            this.setResultCellColor(e)
        } 
    }

    private getData(scheduleQueueId: string) {
        this.scheduleQueueService.getChildren(scheduleQueueId)
        .subscribe(
            (responses: ScheduleQueueSearchModel[]) => { 
                this.bindData(responses);
            });
    }

    private bindData(tasks: ScheduleQueueSearchModel[]) {
        if (!tasks?.length) {
            return;
        }

        let dynamicDataArray = new Array();
        let no = 1;
        tasks.forEach(item => {
            let scheduleTemps = {};
            scheduleTemps["no"] = no;
            scheduleTemps["id"] = item.scheduleQueueId;
            scheduleTemps["taskCode"] = item.scheduleQueueTaskCode;
            scheduleTemps["taskName"] = item.scheduleQueueTaskName;
            scheduleTemps["numberOfOrders"] = item.numberOfOrders;
            scheduleTemps["numberOfProducts"] = item.numberOfProducts;
            scheduleTemps["numberOfIndividuals"] = item.numberOfIndividuals;
            scheduleTemps["startDateTime"] = this.dateConverterService.convertDateString(item.processStartDateTime, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["endDateTime"] = this.dateConverterService.convertDateString(item.processEndDateTime, this.DATE_WITH_TIME_FORMAT);
            scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
            scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
            dynamicDataArray.push(scheduleTemps);
            no++;
        });
        this.scheduleQueueTaskData = dynamicDataArray;
        this.rows = this.scheduleQueueTaskData.length;
        this.changeDetectorRef.detectChanges();
    }

    private setResultCellColor(e) {
        switch (e.data.resultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;    
        }
    }

    public openTableOnNewTab(e) {
        if (e.data.numberOfProducts > 0) {
            this.openOperatingTransportTab(e);
        }
    }

    public openOperatingTransportTab(e) {
        this.navigationService.navigate("/main/operating-transports/" + Date.now().toString() + "/schedule-queue-tasks/" + e.data.id, "Schedule Management", true);
    }

   
}
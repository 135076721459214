import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductLocationPointReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/system/productlocationpoints"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getAll() {
    return this.httpGet<any>(this.REFERENCE_PATH, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
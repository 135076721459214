import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { InsightPassengerCommandModel, InsightPassengerModel, InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { Helper } from "src/app/shared/helper/app.helper";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";

@Component({
    selector: 'op-insight-search-filter-name',
    templateUrl: 'insight-search-filter-name.component.html'
})
export class InsightSearchFilterNameComponent extends OopsComponentFormBase implements OnChanges {
    readonly PUBLIC_FILTER_SECURITY = "INDIVIDUALPUBLFILTER";

    @Input() advanceSearchMode: boolean;
    @Input() insightPassenger: InsightPassengerModel;
    @Input() filter: InsightPassengerSearchModel;
    @Input() actionSecurity: SecurityGroupSecurityModel;
    @Input() security: SecurityGroupSecurityModel[];
   
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    focusing = false;
    isOwnerFilter = false;

    public publicFilterDisabled : any;
    
    constructor(
        fb: UntypedFormBuilder,
        private actionbarService: ActionbarService,
        private changeDetectorRef: ChangeDetectorRef,
        private focusingService: FocusingService) {
            super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['filter']) {
            this.formGroup = this.convertInsightModelToFormGroup(this.filter);
        }
        if (changes['security'] || changes['actionSecurity']) {
            this.setDisablePublicFilter();
        }

        this.updateActionBarHandlerState();
    }
    
    private updateActionBarHandlerState() {
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (!actionBarHandler) {
            return;
        }

        if (this.advanceSearchMode) {
            actionBarHandler.get(ACTION_STATUS.delete)?.enable(this.canDelete() && this.actionSecurity.deleteFlag);
            actionBarHandler.get(ACTION_STATUS.copy)?.enable(this.allowCopy() && this.actionSecurity.copyFlag);
            let saveBtn = actionBarHandler.get(ACTION_STATUS.save);
            saveBtn?.enable(this.allowSave());
            saveBtn?.getDropdownBtn(ACTION_STATUS.save)?.enable(this.canSaveToSameOrNewId());
            saveBtn?.getDropdownBtn(ACTION_STATUS.saveAs)?.enable(this.alwaysSaveToNewId());
        } else {
            actionBarHandler.get(ACTION_STATUS.delete)?.disable();
            actionBarHandler.get(ACTION_STATUS.copy)?.disable();
            actionBarHandler.get(ACTION_STATUS.save)?.disable();
        }
        this.actionbarService.updateState(actionBarHandler);
    }

    private canDelete(): boolean {
        return !!(this.isOwnerFilter);
    }

    private allowCopy(): boolean {
        return !!(this.insightPassenger?.individualId);
    }

    private allowSave(): boolean {
        return !!(this.actionSecurity.newFlag || this.actionSecurity.editFlag || this.actionSecurity.copyFlag);
    }

    private canSaveToSameOrNewId(): boolean {
        let publicFilterSecurity = this.isPublicFilterSecurity();

        return (!!(this.filter?.individualId) && !!(publicFilterSecurity?.length)) 
            || !(this.filter?.individualId) || this.isOwnerFilter;
    }

    private alwaysSaveToNewId(): boolean {
        return !!(this.filter?.individualId);
    }

    public initForm() {
        let newModel = {} as InsightPassengerSearchModel;
        this.formGroup = this.convertInsightModelToFormGroup(newModel);
    }

    private convertInsightModelToFormGroup(model: InsightPassengerSearchModel): UntypedFormGroup {
        this.isOwnerFilter = model?.isOwnerFilter;
        return new UntypedFormGroup({
            individualId: new UntypedFormControl(model?.individualId),
            insightName: new UntypedFormControl(model?.searchName, Validators.required),
            publicFilter: new UntypedFormControl(!this.isOwnerFilter)
        })
    }

    private isPublicFilterSecurity(): SecurityGroupSecurityModel[] {
        return this.security?.filter(x => x.securityCode == this.PUBLIC_FILTER_SECURITY);
    }

    private convertFormGroupToInsightModel(formGroup: UntypedFormGroup): InsightPassengerCommandModel {
        let model = {} as InsightPassengerCommandModel;
        model.searchName = formGroup.controls["insightName"]?.value;
        model.isOwnerFilter = !this.getPublicFilterValue(formGroup);
        return model;
    }

    private getPublicFilterValue(formGroup: UntypedFormGroup) {
        let publicFilterSecurity = this.isPublicFilterSecurity();

        if (publicFilterSecurity?.length) {
            return formGroup.controls["publicFilter"]?.value;
        }

        return false;
    }

    public getValues(): InsightPassengerCommandModel {
        this.startProcessing();
        if (!(this.validForm())) {
            this.validateAllFormFields(this.formGroup);
            this.focusingService.focus(this.focusingDirective);
            this.changeDetectorRef.markForCheck();
            return null;
        }
        let filterModel = this.convertFormGroupToInsightModel(this.formGroup);
        this.completeProcessing();
        return filterModel;
    }

    private setDisablePublicFilter() {
        let disabled = true;

        if (!this.security) {
            this.publicFilterDisabled = disabled;
            this.formGroup.controls["publicFilter"].setValue(!disabled);
            this.formGroup.controls["publicFilter"].disable();
        }

        let publicFilterSecurity = this.isPublicFilterSecurity();
        
        if (publicFilterSecurity?.length) {         
            this.publicFilterDisabled = !disabled;
            this.formGroup.controls["publicFilter"].enable();
            return;
        }
         
        this.publicFilterDisabled = disabled;
    }
}
import { ProductDashboardLastReceivedModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-lastreceived.model";


export const PRODUCT_DASHBOARD_FEES_LASTRECEIVED_DEMO: ProductDashboardLastReceivedModel[] = [
    {
        productGroupCode: "BEAUTY",
        productType: "Individual",
        productName: "Individual Fee",
        productCount: 25,
        amount: 513242.14
    },
    {
        productGroupCode: "PAYMENT",
        productType: "Payment",
        productName: "Payment Fee",
        productCount: 37,
        amount: 4590327.42
    },
    {
        productGroupCode: "SALES",
        productType: "Sales",
        productName: "Sales Fee",
        productCount: 74,
        amount: 860377.32
    },
    {
        productGroupCode: "SERVICE",
        productType: "Service",
        productName: "Service Fee",
        productCount: 157,
        amount: 21600410.93
    },
    {
        productGroupCode: "ADMIN",
        productType: "Admin",
        productName: "Admin Fee ",
        productCount: 83,
        amount: 565892.00
    },
    {
        productGroupCode: "PAYMENT",
        productType: "Payment",
        productName: "Credit Card Payment Fee",
        productCount: 178,
        amount: 134932.02
    },
    {
        productGroupCode: "PAYMENT",
        productType: "Payment",
        productName: "Internet Payment Fee",
        productCount: 253,
        amount: 9876315.47
    },
    {
        productGroupCode: "SALES",
        productType: "Sales",
        productName: "Sales Transaction Fee",
        productCount: 98,
        amount: 564813.22
    },
    {
        productGroupCode: "SERVICE",
        productType: "Service",
        productName: "Service Charge",
        productCount: 214,
        amount: 3914673.21
    }
]

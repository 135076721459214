import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { UiModule } from "src/app/shared/ui/ui.module";

import { ExchangeRateGroupComponent } from "./exchange-rate-group/exchange-rate-group.component";
import { ExchangeRateContentComponent } from "./exchange-rate-content.component";
import { ExchangeRateTabsComponent } from "./exchange-rate-tabs/exchange-rate-tabs.component";
import { ExchangeCurrencyModule } from "../../exchange-currency/exchange-currency.module";
import { ExchangeRateAmountModule } from "../../exchange-rate-amount/exchange-rate-amount.module";

@NgModule({
    declarations: [
        ExchangeRateGroupComponent,
        ExchangeRateContentComponent,
        ExchangeRateTabsComponent
    ], 
    imports: [
        CommonModule,
        DynamicRoutingModule,
        ExchangeCurrencyModule,
        ExchangeRateAmountModule,
        NgbNavModule,
        RouterModule,
        NgbModule,
        FormsModule,
        PanelsModule,
        OopsCategoriesModule,
        DxDataGridModule,
        Select2Module,
        MatIconModule,
        MatMenuModule,
        FocusingModule,
        DxTemplateModule,    
        CollapseModule,
        ReactiveFormsModule,
        ButtonsModule,
        DxPopoverModule,
        UiModule
    ],
    exports: [
        ExchangeRateContentComponent
    ]
})
export class ExchangeRateContentModule { }
import { Store } from '@ngrx/store';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import * as AuthActions from './../../../store/auth/auth.actions';
import { ActionBarHandlerModel, ActionbarService } from 'src/app/shared/ui/actionbar';
import { ACTION_STATUS } from 'src/app/shared/ui/actionbar/actionbar-constant';
import { AuthService } from '../../../core/authentication/auth.service';
import { APP_CONFIG } from '../../../app.config';
import { CookiesService } from 'src/app/shared/utils/cookies.service';
import { LoadingSpinnerService } from './../../../shared/layout/loading-spinner/loading-spinner.service';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { selectAuthState } from './../../../store/auth/auth.selectors';
import { TabModel } from 'src/app/core/models/tab/tab.model';
import { ACCEPT_COOKIES, REMEMBER_ME_COOKIES, REMEMBER_ME_SPLIT_SYMBOL } from '../shared/constants/auth.constant';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
  selector: 'op-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly REMEMBER_ME_FAILED_MESSAGE = "\"Remember me\" cookie has expired - please re-logon!";
  private readonly CONTROL_REMEMBER_ME: string = "rememberMe";
  private readonly CONTROL_PASSWORD: string = "password";
  private readonly CONTROL_USERNAME: string = "username";

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public errorText$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  public applicationName: string;
  public copyright: string;
  public loginForm: UntypedFormGroup;
  public showOrganisation: boolean = false;
  public tabs: TabModel[];

  actionBarHandler = new ActionBarHandlerModel(
    new RefreshButtonModel()
  );

  constructor(
    private authService: AuthService,
    private cookiesService: CookiesService,
    private fb: UntypedFormBuilder,
    private store: Store<any>,
    private loadingSpinner: LoadingSpinnerService,
    private actionbarService: ActionbarService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    var rememberMeUserName = this.getUserNameFromCookies(this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES));
    this.loginForm = this.fb.group({
      username: [rememberMeUserName],
      password: [this.getPasswordMask(this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES))],
      rememberMe: [{ value: this.checkRememberMe(), disabled: !this.checkAcceptCookies() }],
      acceptCookies: [this.checkAcceptCookies()]
    });
    this.authService.logout();
    this.renderCopyRight();
    this.applicationName = APP_CONFIG.appName;
    if (rememberMeUserName) {
      this.onLogin();
    }
  }

  private renderCopyRight(): void {
    this.copyright = APP_CONFIG.copyright.replace(
      '{{YEAR}}',
      new Date().getUTCFullYear().toString()
    );
  }

  private getPasswordMask(rememberMe: boolean): string {
    const rememberMaskPassword = "XXXXXXX";
    if (rememberMe) {
      return rememberMaskPassword;
    }
    return "";
  }

  private getUserNameFromCookies(rememberMe: boolean): string {
    if (rememberMe) {
      let cookiesValue = this.cookiesService.getItem(REMEMBER_ME_COOKIES);
      return cookiesValue.split(REMEMBER_ME_SPLIT_SYMBOL)[0];
    }
    return "";
  }

  private checkRememberMe(): boolean {
    if (this.checkAcceptCookies) {
      return this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES);
    }
    return false;
  }

  private checkAcceptCookies(): boolean {
    if (this.cookiesService.cookiesExist(ACCEPT_COOKIES)) {
      return this.cookiesService.getItem(ACCEPT_COOKIES) === 'true';
    }
    return true;
  }

  public acceptCookiesClick($event) {
    if ($event.target.checked === true) {
      this.loginForm.controls[this.CONTROL_REMEMBER_ME].enable();
      this.setCookiesToLogin();
    } else {
      this.loginForm.controls[this.CONTROL_REMEMBER_ME].disable();
      this.clearLoginFromCookies();
    }
  }

  public acceptRememberMeClick($event) {
    if ($event.target.checked === true) {
      this.setCookiesToLogin();
    } else {
      this.clearLoginFromCookies();
    }
  }

  private setCookiesToLogin() {
    if (!this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES)) {
      return;
    }
    this.loginForm.controls[this.CONTROL_REMEMBER_ME].setValue(true);
    this.loginForm.controls[this.CONTROL_PASSWORD].setValue(this.getPasswordMask(this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES)));
    this.loginForm.controls[this.CONTROL_USERNAME].setValue(this.getUserNameFromCookies(this.cookiesService.cookiesExist(REMEMBER_ME_COOKIES)));
  }
  private clearLoginFromCookies() {
    this.loginForm.controls[this.CONTROL_REMEMBER_ME].setValue(false);
    this.loginForm.controls[this.CONTROL_PASSWORD].setValue("");
    this.loginForm.controls[this.CONTROL_USERNAME].setValue("");
  }
  onLogin() {
    this.loadingSpinner.show();
    if (!this.loginForm.valid) {
      return;
    }
    const logonData = this.loginForm.value;
    const username = logonData.username;
    const password = logonData.password;
    const rememberMe = logonData.rememberMe;
    const acceptCookies = logonData.acceptCookies;
    this.removeRememberMeCookiesWhenLogin(rememberMe, acceptCookies);
    this.store.dispatch(AuthActions.loginStart({ username, password, rememberMe, acceptCookies }));
    this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (state.authError) {
          if (rememberMe) {
            this.resetRememberMeWhenFail();
            this.errorText$.next(this.REMEMBER_ME_FAILED_MESSAGE);
            return;
          }
          this.errorText$.next(state.authError);
        }
      })
  }

  private resetRememberMeWhenFail() {
    this.cookiesService.deleteCookies(REMEMBER_ME_COOKIES);
    this.loginForm.controls[this.CONTROL_PASSWORD].setValue("");
  }
  private removeRememberMeCookiesWhenLogin(rememberMe: boolean, acceptCookies: boolean) {
    if (!rememberMe) {
      this.cookiesService.deleteCookies(REMEMBER_ME_COOKIES);
    }
    if (!acceptCookies) {
      this.cookiesService.deleteCookies(ACCEPT_COOKIES);
    }
  }

  ngOnDestroy(): void {
    this.loginForm.reset();
    this.loadingSpinner.hide();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.actionbarService.updateState(this.actionBarHandler);
    this.actionbarService.action$
      .subscribe(actionId => {
        switch (actionId) {
          case ACTION_STATUS.refresh:
          default:
            return;
        }
      }
      );
  }

  public forgotPasswordClick() {
    const logonData = this.loginForm.value;
    this.store.dispatch(AuthActions.forgotPassword({ username: logonData.username }))
    this.navigationService.navigate('auth/forgot-password', 'Forgot Password', true);
  }

  public forgotUserNameClick() {
    this.navigationService.navigate('auth/forgot-username', 'Forgot UserName', true);
  }
}

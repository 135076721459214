import { Injectable } from '@angular/core';
import { VehicleSeatMapModel } from 'src/app/core/models/vehicle-model/vehicle-seatmap';
import { SeatMapColumnView } from './seatmap-column.view';
import { SeatMapView } from './seatmap.view';

@Injectable()
export class SeatMapMapperService {

    public seatMapToViews(models: VehicleSeatMapModel[], vehicleCols: string[]): SeatMapView[] {
        let views: SeatMapView[] = new Array();
        let seatRows = this.getRows(models);
        if (models && vehicleCols?.length && seatRows?.length) {
            this.addSeatMapByRows(models, vehicleCols, views, seatRows);
        }
        return views;
    }

    private addSeatMapByRows(models: VehicleSeatMapModel[], vehicleCols: string[], views: SeatMapView[], seatRows: number[]) {
        for (let i = 0; i <= seatRows.length - 1; i++) {
            this.addSeatMapByCols(models, vehicleCols, views, seatRows[i]);
        }
    }

    private addSeatMapByCols(models: VehicleSeatMapModel[], vehicleCols: string[], views: SeatMapView[], seatRow: number) {
        for (let col of vehicleCols) {
            if (col) {
                this.addUpdateSeatMapSpace(models, views, col, seatRow);
            } else {
                let view = views.find(x => x.seatRow == seatRow);
                view.seatColumns.push(this.newSpaceColumn());
            }
        }
    }

    private addUpdateSeatMapSpace(models: VehicleSeatMapModel[], views: SeatMapView[], col: string, seatRow: number) {
        let seatModel = models.find(x => x.seatRow == seatRow && x.seatColumn == col);
        if (seatModel) {
            this.addUpdateSeatMap(views, seatModel);
        } else {
            this.addUpdateSpace(views, models, seatRow);
        }
    }

    private addUpdateSeatMap(views: SeatMapView[], seatModel: VehicleSeatMapModel) {
        let view = views.find(x => x.seatRow == seatModel.seatRow);
        if (!view) {
            views.push(this.seatMapToView(seatModel));
        } else {
            view.seatColumns.push(this.newSeatMapColumn(seatModel));
        }
    }

    private addUpdateSpace(views: SeatMapView[], models: VehicleSeatMapModel[], seatRow: number) {
        let seatRowsModel = models.find(x => x.seatRow == seatRow);
        let view = views.find(x => x.seatRow == seatRowsModel.seatRow);
        if (!view) {
            views.push(this.seatMapSpaceToView(seatRowsModel));
        } else {
            view.seatColumns.push(this.newSpaceColumn());
        }
    }

    private seatMapToView(model: VehicleSeatMapModel): SeatMapView {
        let view = {} as SeatMapView;
        view.selected = false;
        view.seatRow = model.seatRow;
        view.seatColumns = new Array();
        view.seatColumns.push(this.newSeatMapColumn(model));
        return view;
    }

    private seatMapSpaceToView(model: VehicleSeatMapModel): SeatMapView {
        let view = {} as SeatMapView;
        view.selected = false;
        view.seatRow = model.seatRow;
        view.seatColumns = new Array();
        view.seatColumns.push(this.newSpaceColumn());
        return view;
    }

    private newSeatMapColumn(model: VehicleSeatMapModel): SeatMapColumnView {
        let col = {} as SeatMapColumnView;
        col.seatColumn = model.seatColumn;
        col.statusCode = model.statusCode;
        return col;
    }

    private newSpaceColumn(): SeatMapColumnView {
        let col = {} as SeatMapColumnView;
        col.seatColumn = null;
        col.statusCode = null;
        return col;
    }

    public seatMapToColViews(models: VehicleSeatMapModel[]): string[] {
        let colViews: string[] = new Array();
        if (models?.length) {
            let columnLayout = this.getColumnLayout(models);
            let cols = Array.from(models[0].columnCode.split(''), String);
            let columnSeatIndex = 0;
            let layoutIndex = 0;
            for (let i = 0; i <= cols.length - 1; i++) {
                ++columnSeatIndex;
                colViews.push(cols[i]);
                if (this.isSpace(columnSeatIndex, columnLayout[layoutIndex])) {
                    colViews.push(null);
                    columnSeatIndex = 0;
                    ++layoutIndex;
                }
            }
        }
        return colViews;
    }

    public seatMapToColAll(views: SeatMapView[]): string {
        let colAll: string = "";
        if (views?.length) {
            for (let col of views[0].seatColumns) {
                if (col.seatColumn) {
                    colAll += col.seatColumn;
                }
            }
        }
        return colAll;
    }

    private isSpace(columnCount: number, currentLayout: number): boolean {
        return (columnCount == currentLayout);
    }

    private getColumnLayout(models: VehicleSeatMapModel[]): number[] {
        if (models?.length) {
            return Array.from(models[0].columnLayout.split(''), Number);
        }
        return null;
    }

    private getRows(models: VehicleSeatMapModel[]): number[] {
        let rows: number[] = new Array();
        if (models?.length) {
            for (let model of models) {
                let filter = rows.filter(x => x == model.seatRow);
                if (!filter?.length) {
                    rows.push(model.seatRow);
                }
            }
        }
        return rows;
    }
}
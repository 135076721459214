import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { DxChartComponent } from "devextreme-angular";
import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";
import { PRODUCT_CHART_COLORS } from "../../../../../shared/ui/chart/chart-color.constant";
import { PRODUCT_DASHBOARD_COMPARE_MONM_DEMO } from "./product-dashboard-compare-monm-demo-data";


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-compare-last30d.component.html',
    selector: 'op-product-dashboard-compare-last30d'
})
export class ProductDashboardCompareLast30DComponent implements AfterViewInit {
    public readonly ccolor = PRODUCT_CHART_COLORS;
    
    @ViewChild('chartCompare30d', { static: false }) chartCompare30d: DxChartComponent;

    public compareMonthOnMonthInfo: OrdersDashboardCompareValuesModel[];

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.chartCompare30d.instance.render();
            this.changeDetection.detectChanges();

            this.loadData();
        }, 300);
    }

    private loadData() {
        setTimeout(() => {
            this.compareMonthOnMonthInfo = PRODUCT_DASHBOARD_COMPARE_MONM_DEMO;
            this.changeDetection.detectChanges();
        }, 700);
    }

    
}
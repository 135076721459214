import { OrganisationGroupOrganisationModel, 
    OrganisationOrganisationTypeModel, 
    OrganisationRelationModel,
    OrganisationAttributeModel, 
    OrganisationLanguageModel,
    OrganisationCurrencyModel,
    OrganisationAddressModel,
    OrganisationCommentModel,
    OrganisationUserModel} from ".";
import { CommunicationOtherModel } from "../user-model/communication-other.model";
import { CommunicationPhoneModel } from "../../components/communication-phone/shared/communication-phone.model";

export class OrganisationEditCommandModel {
    public locationId: string;
    public languageTranslationSetId: string;
    public organisationCallName: string;
    public organisationLegalName: string;
    public iataNumber: string;
    public teletypeAddress: string;
    public websiteAddress: string;
    public taxId: string;
    public passwordExpiryDays: number;
    public passwordReuseCycle: number;
    public statusCode: string;
    public organisationCode: string;
    public providerIataCode: string;
    public providerIcaoCode: string;
    public providerAccountNumber: string;
    public parentOrganisationId: string;
    public organisationOrganisationTypes: OrganisationOrganisationTypeModel[];
    public organisationGroupOrganisations: OrganisationGroupOrganisationModel[];
    public organisationRelations: OrganisationRelationModel[];
    public organisationAttributes: OrganisationAttributeModel[];
    public organisationLanguages: OrganisationLanguageModel[];
    public organisationCurrencies: OrganisationCurrencyModel[];
    public organisationAddresses: OrganisationAddressModel[];
    public organisationCommunicationPhones: CommunicationPhoneModel[];
    public organisationCommunicationOthers: CommunicationOtherModel[];
    public organisationComments: OrganisationCommentModel[];
    public organisationUsers: OrganisationUserModel[];
    public organisationId: string;
    public usageTypeCode: string;
    public searchName: string;
    public searchStatusCode: string;
    public searchUsageTypeCode: string;
    public filterUserAccountId: string;
    public isOwnerFilter: boolean;
} 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';

import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoadingSpinnerModule } from './loading-spinner';
import { LogoComponent } from './logo/logo.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageBreadcrumbComponent } from './page-breadcrumb/page-breadcrumb.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { DropdownAppsComponent } from './dropdown-apps/dropdown-apps.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsModalComponent } from './settings-modal/settings-modal.component';
import { AlertBarModule } from './alertbar';
import { NavigationIndexModule } from './navigation-index';

import { ApplicationMainModule } from '../../modules/application-main.module';
import { UiModule } from '../ui/ui.module';
import { NavigationModule } from '../navigation/navigation.module';
import { SettingsShortcutComponent } from './settings-shortcut/settings-shortcut.component';
import { ShortcutModalComponent } from './shortcut-modal/shortcut-modal.component';
import { FabShortcutComponent } from './fab-shortcut/fab-shortcut.component';
import { FloatActionButtonComponent } from './float-action-button/float-action-button.component';
import { DropdownUserComponent } from './dropdown-user/dropdown-user.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { PageHeaderSearchComponent } from './page-header/page-header-search/page-header-search.component';
import { MatIconModule } from '@angular/material/icon';

import { tabFeatureKey, reducer } from '../../store/tab';
import { DropdownFavoriteComponent } from './dropdown-favorite/dropdown-favorite.component';
import { PanelsModule } from '../panels/panels.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxCheckBoxModule } from 'devextreme-angular';
import { activityFeatureKey, reducerActivity } from 'src/app/store/activity';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';

@NgModule({
    declarations: [
        DropdownAppsComponent,
        DropdownFavoriteComponent,
        DropdownUserComponent,
        FabShortcutComponent,
        FloatActionButtonComponent,
        LogoComponent,
        MainComponent,
        PageBreadcrumbComponent,
        PageFooterComponent,
        PageHeaderComponent,
        PageHeaderSearchComponent,
        PageHeadingComponent,
        SettingsComponent,
        SettingsModalComponent,
        SettingsShortcutComponent,
        ShortcutModalComponent,
        SidebarComponent,
        ThemeSwitcherComponent
    ],
    imports: [
        ApplicationMainModule,
        AlertBarModule,
        BsDropdownModule,
        ButtonsModule,
        CommonModule,
        DxCheckBoxModule,
        DxPopoverModule,
        DxTemplateModule,
        FormsModule,
        LoadingSpinnerModule,
        MatIconModule,
        ModalModule,
        NavigationModule,
        NgbModule,
        NavigationIndexModule,
        PanelsModule,
        ReactiveFormsModule,
        RouterModule,
        StoreModule.forFeature(tabFeatureKey, reducer),
        StoreModule.forFeature(activityFeatureKey, reducerActivity),
        UiModule
    ],
    exports: [MainComponent, SidebarComponent, PageHeaderComponent]
})
export class LayoutModule {}

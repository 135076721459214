import {
  AfterViewInit,
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

import { APP_CONFIG } from 'src/app/app.config';
import { LoadingSpinnerService } from '../loading-spinner';
import { badgeCount, mobileNavigation } from 'src/app/store/navigation';
import { NavigationModel, NavigationService } from '../../utils/navigation';
import { TabModel } from '../../../core/models/tab/tab.model';
import { TabService } from '../../../core/utils/tab.service';
import { selectTabs } from '../../../store/tab';
import { FavoriteService } from 'src/app/core/services/system-services/favorite.service';
import {
  selectNavigationItems,
  NavigationItem,
} from 'src/app/store/navigation';
import * as TabSessionActions from 'src/app/store/tab-session/tab-session.actions';
import { ActionService } from 'src/app/core/utils/action.service';
import { PaymentGatewayFactory } from 'src/app/core/services/payment-creditcard-services/payment-gateway-factory';
import { ActivityDomainService } from 'src/app/core/services/activity-services';
import { ActivityDomainViewModel, ActivityProductCategoryViewModel } from 'src/app/core/models/activity-model';
import { selectActivities } from "src/app/store/activity";
import { ActivityDomainTabModel } from 'src/app/core/models/activity-model/activity-domain-tab.model';
import * as NavigationActions from 'src/app/store/navigation/navigation.actions';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { AlertBarMainService } from '../alertbar';

@Component({
  selector: 'smart-main',
  templateUrl: './main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements AfterViewInit, OnInit, OnDestroy {
  public readonly DOMAINCODE_ACTIVITY: string = "ACTIVITY";
  public readonly DOMAINCODE_PRODUCT: string = "PRODUCT";
  public readonly DOMAINCODE_PRICERULE: string = "PRICERULE";
  public tabs: TabModel[];
  public dataInboxUnreadCount: number = 0;
  public dataOutboxUnreadCount: number = 0;
  public activityDomains$ = new BehaviorSubject<ActivityDomainViewModel[]>(null);
  public activityProductCategies$ = new BehaviorSubject<ActivityProductCategoryViewModel[]>(null);
  public pendingCount: number;
  public isDetail: boolean = false;
  public domainCode: string;
  public activityDetails: ActivityDomainTabModel[];
  public currentActivityDetail: ActivityDomainTabModel;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;

  app = APP_CONFIG.app;

  constructor(
    public spinnerService: LoadingSpinnerService,
    public alertBarService: AlertBarMainService,
    private navigationService: NavigationService,
    private store: Store<any>,
    private tabService: TabService,
    private favoriteService: FavoriteService,
    private actionService: ActionService,
    private activityDomainService: ActivityDomainService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

    this.navigationService
      .mainTabRoutesRecognized()
      .pipe(takeUntil(this.destroy$))
      .subscribe((navigation: NavigationModel) => {
        if (navigation.tab == true) {
          this.spinnerService.show(1000);
          this.tabService.add(
            navigation.tabId,
            navigation.name,
            navigation.url,
            navigation.component
          );
        }
      });
  }
  ngAfterViewInit(): void {

    this.addPreloadTab();
    this.navigationService.loadPreloadTab();
  }

  private addPreloadTab() {
    this.store.select(selectNavigationItems)
      .pipe(first())
      .subscribe((navItems: NavigationItem[]) => {
        for (let navItem of navItems) {

          if (!navItem.openOnLoad || this.tabExistInSession(navItem.title)) {
            continue;
          }
          this.navigationService.addPreloadTab(`${navItem.routerLink}/${Date.now().toString()}`,
            navItem.title, null);
        }
      });
  }
  private tabExistInSession(tabName: string): boolean {
    let tabStorage = JSON.parse(localStorage.getItem("tabSession"));
    if (!tabStorage?.tabSessions) {
      return false;
    }
    return tabStorage.tabSessions.some(x => x.name === tabName);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.getUserFavorite();
    this.getActivityDomain();
    this.store.select(selectTabs)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tabs: TabModel[]) => {
        this.tabs = tabs;
      });
    this.store.dispatch(TabSessionActions.loadTabSesssion());
    this.subscribeActivityDetail();
    this.store.dispatch(NavigationActions.filterBySecurity());
  }

  closeMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: false }));
  }

  public closeTab(tab: TabModel, event: Event) {
    event.preventDefault();

    const backToMainPage: string = '../main';
    this.tabService.delete(tab);
    if (this.tabs.length > 0) {
      this.setActiveTab(this.tabs[this.tabs.length - 1], event);
      this.navigationService.navigate(
        this.tabs[this.tabs.length - 1].url,
        null
      );
    } else {
      this.navigationService.navigate(backToMainPage, null);
      this.actionService.add(null, null, null);
    }
  }
  public closeAllTabs() {
    this.tabService.closeAllTab();
    this.actionService.add(null, null, null);
  }
  public setActiveTab(selectedTab: TabModel, event: Event) {
    event.preventDefault();
    this.tabService.setSelectedTab(selectedTab);
    this.manageNotification(selectedTab).then(() => {
      this.changeDetectorRef.detectChanges();
      this.pageHeaderComponent?.handleSelectTabNotification();
    });
  }
  public getSelectedTab(): string {
    let tabActive = this.tabs.find((tab) => tab.active == true);
    this.manageNotification(tabActive);
    return tabActive?.id;
  }
  public tabExist(): boolean {
    if (!this.tabs || this.tabs.length == 0) {
      return false;
    }
    return true;
  }
  private getUserFavorite() {
    this.favoriteService.getUserFavorite()
      .subscribe(
        (response) => {
          this.store.dispatch(badgeCount({ id: 'FAVORITES', count: response.length }));
        }
      )
  }
  private getActivityDomain() {
    this.activityDomainService.getAll()
      .subscribe(
        (response: ActivityDomainViewModel[]) => {
          this.activityDomains$.next(response);
        }
      )
  }

  private getActivityProductCategory() {
    this.activityDomainService.getActivityProductCategory()
      .subscribe(
        (response: ActivityProductCategoryViewModel[]) => {
          this.activityProductCategies$.next(response);
        }
      )
  }

  private manageNotification(tabActive: TabModel): Promise<void> {
    return new Promise((resolve, reject) => {
      this.domainCode = tabActive?.domainCode;
      this.checkDetailPage(tabActive);
      resolve();
    });
  }

  private getActivityPendingDomain(domainCode: string) {
    if (domainCode == this.DOMAINCODE_ACTIVITY) {
      this.getFromActivityState();
    } else if (domainCode == this.DOMAINCODE_PRODUCT){
      this.getActivityProduct();
    } else if (domainCode == this.DOMAINCODE_PRICERULE) {
      this.getActivityPricing();
    } else {
      this.getFromActivityDomain(domainCode);
    }
  }

  private getFromActivityDomain(domainCode: string) {
    let filters = this.activityDomains$?.value?.filter(x => x.domainCode == domainCode);
    if (filters?.length) {
      this.pendingCount = filters[0].pending;
    } else {
      this.pendingCount = null;
    }
  }

  private getFromActivityState() {
    let tabActive = this.tabs.find((tab) => tab.active == true);
    let tabNumber = tabActive?.id;
    let activity = this.activityDetails.find((tab) => tab.tabNumber == tabNumber);
    if (activity) {
      this.pendingCount = activity.pendingCount;
    } else {
      this.pendingCount = null;
    }
  }

  private getActivityProduct() {
    let tabActive = this.tabs.find((tab) => tab.active == true);
    let tabNumber = tabActive?.id;
    let domainCode = tabActive?.domainCode;
    this.currentActivityDetail = this.activityDetails.find((x => x.tabNumber == tabNumber && x.domainCode == domainCode));
    if (this.currentActivityDetail?.subTab == "search" || this.currentActivityDetail?.subTab == "group") {
      this.getFromActivityProductCategory(this.currentActivityDetail?.category, this.currentActivityDetail?.group);
    } else {
      this.getFromActivityDomain(domainCode);
    }
  }

  private getFromActivityProductCategory(category: string, group: string) {
    if (group) {
      this.getByProductGroup(category, group);
    } else {
      this.getByProductCategory(category);
    }
  }

  private getByProductGroup(category: string, group: string) {
    let filters = this.activityProductCategies$?.value?.filter(x => x.productCategoryCode == category && x.productGroupCode == group);
    if (filters?.length) {
      this.pendingCount = filters[0].pending;
    } else {
      this.pendingCount = null;
    }
  }

  private getByProductCategory(category: string) {
    let filters = this.activityProductCategies$?.value?.filter(x => x.productCategoryCode == category);
    if (filters?.length) {
      this.pendingCount = this.sumByProductCategory(filters);;
    } else {
      this.pendingCount = null;
    }
  }

  private sumByProductCategory(filters: ActivityProductCategoryViewModel[]) : number {
    let sum = 0;
    filters.forEach((obj) => {
      sum += obj.pending;
    });
    return sum;
  }

  private checkDetailPage(tabActive: TabModel) {
    if (tabActive?.url?.includes("details")) {
      this.getActivityPendingDetail();
      this.isDetail = true;
    } else {
      this.getActivityPendingDomain(this.domainCode);
      this.isDetail = false;
    }
  }

  private getActivityPendingDetail() {
    let tabActive = this.tabs.find((tab) => tab.active == true);
    let tabNumber = tabActive?.id;
    let domainCode = tabActive?.domainCode;
    this.currentActivityDetail = this.activityDetails.find((x => x.tabNumber == tabNumber && x.domainCode == domainCode));
    this.updatePendingCount();
  }

  private subscribeActivityDetail() {
    this.store.select(selectActivities)
      .pipe(takeUntil(this.destroy$))
      .subscribe((activities: ActivityDomainTabModel[]) => {
        this.activityDetails = activities;
        this.getActivityPendingDetail();
        this.getActivityDomain();
        this.getActivityProductCategory();
        this.updateActivityPending();
      });
  }

  private updatePendingCount() {
    if (this.currentActivityDetail) {
      this.pendingCount = this.currentActivityDetail.pendingCount;
    } else {
      this.pendingCount = null;
    }
  }

  private updateActivityPending() {
    let tabActive = this.tabs.find((tab) => tab.active == true);
      this.manageNotification(tabActive);
  }

  private getActivityPricing() {
    let domainCode = this.getDomainFromCurrentActivity();
    let filters = this.activityDomains$?.value?.filter(x => x.domainCode == domainCode);
    if (filters?.length) {
      this.pendingCount = filters[0].pending;
      return;
    }
    this.pendingCount = null;
  }

  private getDomainFromCurrentActivity() : string {
    let tabActive = this.tabs.find((tab) => tab.active == true);
    let tabNumber = tabActive?.id;
    this.currentActivityDetail = this.activityDetails.find((x => x.tabNumber == tabNumber));
    return  this.currentActivityDetail?.domainCode;
  }

  public alertClick() {
    
    this.alertBarService.hideError();
    this.alertBarService.hideInformation();
    this.alertBarService.hideWarning();
  }
  public getTabId(id: string) {
    return `tabCaption${id}`;
  }
  
  public getSpecificFontSize(tab: TabModel) : string {
    if (tab.url.includes("orders") && tab.url.includes("details")) {
       return "0.78"
    }
    return null;
  }
}

import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { PriceRuleModel } from "src/app/core/models/pricing-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { Helper } from "src/app/shared/helper/app.helper";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { PriceRuleView } from "../../../shared/views/price-rule.view";

@Component({
    selector: 'op-search-filter-name',
    templateUrl: 'search-filter-name.component.html'
})
export class SearchFilterNameComponent extends OopsComponentFormBase implements OnChanges {
    @Input() advanceSearchMode: boolean;
    @Input() priceRuleView: PriceRuleView;
    @Input() userSecurity: SecurityGroupSecurityModel;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    classIcon = this.helper.getClassIcon();
    focusing = false;
    isOwnerFilter = false;
    
    constructor(
        fb: UntypedFormBuilder,
        private helper: Helper,
        private actionbarService: ActionbarService,
        private changeDetectorRef: ChangeDetectorRef,
        private focusingService: FocusingService) {
            super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['priceRuleView']) {
            this.formGroup = this.convertPriceRuleViewToFormGroup(this.priceRuleView);
        }
        this.updateActionBarHandlerState();
    }
    
    private updateActionBarHandlerState() {
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (this.advanceSearchMode) {
            actionBarHandler.get(ACTION_STATUS.delete)?.enable(this.canDelete());
            actionBarHandler.get(ACTION_STATUS.copy)?.enable(this.allowCopy());
            let saveBtn = actionBarHandler.get(ACTION_STATUS.save);
            saveBtn?.enable();
            saveBtn?.getDropdownBtn(ACTION_STATUS.save)?.enable(this.canSaveToSameOrNewId());
            saveBtn?.getDropdownBtn(ACTION_STATUS.saveAs)?.enable(this.alwaysSaveToNewId());
        } else {
            actionBarHandler.get(ACTION_STATUS.delete)?.disable();
            actionBarHandler.get(ACTION_STATUS.copy)?.disable();
            actionBarHandler.get(ACTION_STATUS.save)?.disable();
        }
        this.actionbarService.updateState(actionBarHandler);
    }

    private canDelete(): boolean {
        return !!(this.isOwnerFilter) && this.userSecurity?.deleteFlag;
    }

    private allowCopy(): boolean {
        return !!(this.priceRuleView.priceRuleId) && this.userSecurity?.copyFlag;
    }

    private canSaveToSameOrNewId(): boolean {
        return (!!(this.priceRuleView.priceRuleId) && this.isOwnerFilter && this.userSecurity?.editFlag) || (!this.priceRuleView.priceRuleId && this.userSecurity?.newFlag);
    }

    private alwaysSaveToNewId(): boolean {
        return !!(this.priceRuleView.priceRuleId) && this.userSecurity?.newFlag;
    }

    public initForm() {
        this.formGroup = this.convertPriceRuleViewToFormGroup(new PriceRuleView());
    }

    private convertPriceRuleViewToFormGroup(view: PriceRuleView): UntypedFormGroup {
        this.isOwnerFilter = view.isOwnerFilter;
        return new UntypedFormGroup({
            priceRuleId: new UntypedFormControl(view.priceRuleId),
            priceRuleName: new UntypedFormControl(view.priceRuleName, Validators.required),
            publicFilter: new UntypedFormControl(!view.isOwnerFilter)
        })
    }

    private convertFormGroupToPriceRuleModel(priceRuleId: string, formGroup: UntypedFormGroup): PriceRuleModel {
        let model = {} as PriceRuleModel;
        model.id = priceRuleId ?? null;
        model.priceRuleName = formGroup.controls["priceRuleName"]?.value;
        model.isOwnerFilter = !formGroup.controls["publicFilter"]?.value;
        return model;
    }

    public getValues(priceRuleFilterId: string): PriceRuleModel {
        this.startProcessing();
        if (!(this.validForm())) {
            this.validateAllFormFields(this.formGroup);
            this.focusingService.focus(this.focusingDirective);
            this.changeDetectorRef.markForCheck();
            return null;
        }
        let filterModel = this.convertFormGroupToPriceRuleModel(priceRuleFilterId, this.formGroup);
        this.completeProcessing();
        return filterModel;
    }
}
import { Injectable } from "@angular/core";
import { DocumentDistributionEventCommandModel, DocumentDistributionEventModel } from "src/app/core/models/document-distribution-model";

@Injectable()
export class EventMapperService {
    constructor() {}

    public toDocumenctDistributionRuleViews(models: DocumentDistributionEventModel[]): string[] {
        if (!models) {
            return null;
        }

        let views = new Array();
        for (let model of models) {
            views.push(model.operationEventId);
        }

        return views
    }

    public toDocumentDistributionEventCommands(events: string[]): DocumentDistributionEventCommandModel[] {
        if (!events?.length) {
            return null;
        }

        let commands = new Array();
        for (let event of events) {
            commands.push(this.toDocumentDistributionEventCommand(event));
        }

        return commands;
    }

    private toDocumentDistributionEventCommand(event: string) : DocumentDistributionEventCommandModel {
        let command: DocumentDistributionEventCommandModel = {
            documentDistributionEventId: null,
            documentDistributionId: null,
            operationEventId: event ?? null
        }

        return command;
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { RouterModule } from "@angular/router";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { OopsCategoriesModule } from "src/app/core/components/categories/oops-categories.module";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorModule } from "src/app/shared/ui/forms/inputs/status-color/status-color.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";
import { InsightCategoriesComponent } from "./insight-categories/insight-categories.component";
import { InsightGeneralDetailComponent } from "./insight-detail/insight-detail-general/insight-detail-general.component";
import { InsightNameDetailComponent } from "./insight-detail/insight-detail-name/detail/name-detail.component";
import { NameDetailDirective } from "./insight-detail/insight-detail-name/detail/name-detail.directive";
import { InsightDetailNameComponent } from "./insight-detail/insight-detail-name/insight-detail-name.component";
import { InsightNameTableComponent } from "./insight-detail/insight-detail-name/table/name-table.component";
import { InsightDetailComponent } from "./insight-detail/insight-detail.component";
import { InsightManagementContentComponent } from "./insight-management-content.component";
import { InsightTabsComponent } from "./insight-tabs/insight-tabs.component";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { InsightAddressDetailComponent } from "./insight-detail/insight-detail-address/detail/address-detail.component";
import { InsightAddressTableComponent } from "./insight-detail/insight-detail-address/table/address-table.component";
import { AddressDetailDirective } from "./insight-detail/insight-detail-address/detail/address-detail.directive";
import { InsightDetailAddressComponent } from "./insight-detail/insight-detail-address/insight-detail-address.component";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { InsightCommunicationPhoneDetailComponent } from "./insight-detail/insight-detail-communication-phone/detail/communication-phone-detail.component";
import { InsightCommunicationPhoneTableComponent } from "./insight-detail/insight-detail-communication-phone/table/communication-phone-table.component";
import { CommunicationPhoneDetailDirective } from "./insight-detail/insight-detail-communication-phone/detail/communication-phone.directive";
import { InsightDetailCommunicationPhoneComponent } from "./insight-detail/insight-detail-communication-phone/insight-detail-communication-phone.component";
import { InsightCommunicationOtherDetailComponent } from "./insight-detail/insight-detail-communication-other/detail/communication-other-detail.component";
import { InsightCommunicationOtherTableComponent } from "./insight-detail/insight-detail-communication-other/table/communication-other-table.component";
import { InsightDetailCommunicationOtherComponent } from "./insight-detail/insight-detail-communication-other/insight-detail-communication-other.component";
import { CommunicationOtherDetailDirective } from "./insight-detail/insight-detail-communication-other/detail/communication-other.directive";
import { DocumentDetailDirective } from "./insight-detail/insight-document/detail/document.directive";
import { InsightDocumentDetailComponent } from "./insight-detail/insight-document/detail/document-detail.component";
import { InsightDocumentTableComponent } from "./insight-detail/insight-document/table/document-table.component";
import { InsightDetailDocumentComponent } from "./insight-detail/insight-document/insight-detail-document.component";
import { InsightNationalityDetailComponent } from "./insight-detail/insight-detail-nationality/detail/nationality-detail.component";
import { InsightDetailNationalityComponent } from "./insight-detail/insight-detail-nationality/insight-detail-nationality.component";
import { InsightNationalityTableComponent } from "./insight-detail/insight-detail-nationality/table/nationality-table.component";
import { NationalityDetailDirective } from "./insight-detail/insight-detail-nationality/detail/nationality.directive";
import { LanguageModule } from "./insight-detail/insight-detail-language/language-detail-language.module";
import { CommentModule } from "./insight-detail/insight-detail-comment/comment-detail.module";
import { MembershipModule } from "./insight-detail/insight-detail-membership/membership-detail.module";
import { ProfileModule } from "./insight-detail/insight-detail-profile/profile-detail.module";
import { MediaModule } from "src/app/core/components/media/media.module";
import { AttributeModule } from "./insight-detail/insight-detail-attribute/attribute-detail.module";
import { InsightSearchModule } from "./insight-search/insight-search.module";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";
import { OopsChoiceValueModule } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module";
import { InsightSalesSummaryModule } from "./insight-detail/insight-detail-sales-summary/insight-sales-summary.module";
import { ActivityDomainModule } from "src/app/core/components/activity-domain/activity-domain.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { CalendarIconModule } from "src/app/core/components/calendar-icon";
import { NumberOnlyModule } from "src/app/shared/ui/forms/inputs/number-only/number-only.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { OopsCustomValidatorModule } from "src/app/core/validators";

@NgModule({
    declarations: [
        AddressDetailDirective,
        CommunicationPhoneDetailDirective,
        CommunicationOtherDetailDirective,
        DocumentDetailDirective,
        InsightAddressDetailComponent,
        InsightAddressTableComponent,
        InsightCategoriesComponent,
        InsightCommunicationPhoneDetailComponent,
        InsightCommunicationPhoneTableComponent,
        InsightCommunicationOtherDetailComponent,
        InsightCommunicationOtherTableComponent,
        InsightDetailComponent,
        InsightDocumentDetailComponent,
        InsightDocumentTableComponent,
        InsightGeneralDetailComponent,
        InsightDetailAddressComponent,
        InsightDetailCommunicationPhoneComponent,
        InsightDetailCommunicationOtherComponent,
        InsightDetailDocumentComponent,
        InsightDetailNameComponent,
        InsightDetailNationalityComponent,
        InsightManagementContentComponent,
        InsightNameDetailComponent,
        InsightNameTableComponent,
        InsightNationalityDetailComponent,
        InsightNationalityTableComponent,
        InsightTabsComponent,
        NameDetailDirective,
        NationalityDetailDirective
    ],
    imports: [
        ActivityDomainModule,
        AlertBarModule,
        AttributeModule,
        ButtonsModule,
        CalendarIconModule,
        CollapseModule,
        CommentModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DynamicRoutingModule,
        DxPopoverModule,
        DxTemplateModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        InsightSalesSummaryModule,
        InsightSearchModule,
        ItemsFoundModule,
        LanguageModule,
        MatIconModule,
        MatMenuModule,
        MediaModule,
        MembershipModule,
        NavigationIndexModule,
        NgxDropzoneModule,
        NumberOnlyModule,
        DxTemplateModule,
        NgbNavModule,
        OopsCategoriesModule,
        OopsChoiceValueModule,
        OopsCustomValidatorModule,
        PanelsModule,
        ProfileModule,
        ReactiveFormsModule,
        RouterModule,
        Select2Module,
        StatusColorDropdownModule,
        StatusColorModule,
        UiModule
    ],
    exports: [
        InsightManagementContentComponent
    ]
})
export class InsightManagementContentModule { }

import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleProductNumberView extends PriceRuleConditionBase {
    public priceRuleProductNumberId: string;
    public productNumberTypeCode: string;
    public productNumber: string = "";
    public providerId: string;
    public supplierId: string;
    public displaySequence: number; 
    public preSelectedProvider$ = new BehaviorSubject<Select2Data>(null);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { QrCodeModule } from 'ng-qrcode';

import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login-component/login.component';
import { Select2Module } from '../../shared/ui/forms/inputs/oops-select2/select2.module';
import { LayoutModule } from '../../shared/layout/layout.module';
import { UiModule } from '../../shared/ui/ui.module';
import { MaterialModule } from './../../material/material.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SelectOrganisationComponent } from './select-organisation/select-organisation.component';
import { LoadingSpinnerModule } from './../../shared/layout/loading-spinner/loading-spinner.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DxButtonModule, DxTextBoxModule } from 'devextreme-angular';
import { ForgotUserNameComponent } from './forgot-username/forgot-username.component';
import { OopsCustomValidatorModule } from 'src/app/core/validators';
import { ChangePasswordExpiredComponent } from './change-password-expired/change-password-expired.component';

@NgModule({
    declarations: [
        AuthComponent,
        AuthenticationComponent,
        ChangePasswordComponent,
        ChangePasswordExpiredComponent,
        ForgotPasswordComponent,
        ForgotUserNameComponent,
        LoginComponent,
        SelectOrganisationComponent,
    ],
    imports: [
        AlertBarModule,
        AuthRoutingModule,
        CommonModule,
        DxTextBoxModule,
        DxButtonModule,
        HttpClientModule,
        ReactiveFormsModule,
        LayoutModule,
        MaterialModule,
        NgbNavModule,
        Select2Module,
        UiModule,
        QrCodeModule,
        LoadingSpinnerModule,
        OopsCustomValidatorModule
    ]
})
export class AuthModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { WeatherForecastModel } from '../../models/weather-forecast-model';

@Injectable({
    providedIn: 'root'
})
export class WeatherForecastService extends ApiServiceBase {
    private readonly PATH = "/system/WeatherForecast"

    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    public getForecast(latitude: number, longitude: number): Observable<WeatherForecastModel[]> {
        return this.httpGet<WeatherForecastModel[]>(this.PATH 
            + '?latitude=' 
            + latitude
            + '&longitude='
            + longitude, null)
            .pipe(
                map(res => res.body)
            )
    }
}
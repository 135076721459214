export class OrganisationAddressModel {
    public addressId: string;
    public individualId: string;
    public useAddressId: string;
    public countryCode: string;
    public addressLine1: string;
    public addressLine2: string;
    public postalCode: string;
    public state: string;
    public province: string;
    public city: string;
    public primaryFlag: boolean;
    public locationId: string;
    public addressTypeCode: string;
    public addressTypeName: string;
    public countryName: string;
    public locationName: string;
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbNavModule, NgbPaginationModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { VehicleConfigurationGeneralDetailComponent } from "./general/general.component";
import { VehicleConfigurationServiceCategoryComponent } from "./servicecategory/servicecategory.component";
import { VehicleConfigurationServiceRequestComponent } from "./servicerequest/servicerequest.component";
import { VehicleConfigurationDetailComponent } from "./vehicle-configuration-detail.component";
import { VehicleConfigurationServiceRequestInventoryComponent } from "./servicerequest-inventory/servicerequest-inventory.component";
import { VehicleConfigurationServiceCategorySeatMapComponent } from "./servicecategory-seatmap/servicecategory-seatmap.component";
import { VehicleConfigurationSeatMapComponent } from "./seatmap/seatmap.component";
import { VehicleConfigurationSeatPropertyComponent } from './seatproperty/seatproperty.component';
import { VehicleConfigurationSeatPropertyListComponent } from './seatproperty/seatproperty-list/seatproperty-list.component';
import { VehicleConfigurationSeatPropertyDetailComponent } from './seatproperty/seatproperty-detail/seatproperty-detail.component';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { MatIconModule } from "@angular/material/icon";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";

@NgModule({
    declarations: [
        VehicleConfigurationGeneralDetailComponent,
        VehicleConfigurationDetailComponent,
        VehicleConfigurationServiceCategoryComponent,
        VehicleConfigurationServiceRequestComponent,
        VehicleConfigurationServiceRequestInventoryComponent,
        VehicleConfigurationServiceCategorySeatMapComponent,
        VehicleConfigurationSeatMapComponent,
        VehicleConfigurationSeatPropertyComponent,
        VehicleConfigurationSeatPropertyListComponent,
        VehicleConfigurationSeatPropertyDetailComponent
    ],
    exports: [
        VehicleConfigurationGeneralDetailComponent,
        VehicleConfigurationDetailComponent
    ],
    imports: [
        AlertBarModule,
        ButtonsModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        MatIconModule,
        NavigationIndexModule,
        NgbPaginationModule,
        NgbNavModule,
        NgbModule,
        PanelsModule,
        ReactiveFormsModule,
        StatusColorDropdownModule,
        Select2Module,
        UiModule
    ]
})
export class VehicleConfigurationDetailModule { }

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { IndividualSearchTableComponent } from "src/app/core/components/individual-search-table";
import { InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { CountryReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    selector: 'op-insight-search-table',
    templateUrl: './insight-search-table.component.html',
})
export class InsightSearchTableComponent extends DatatableComponentBase implements AfterViewInit, OnChanges {
    public readonly EXPORT_FILE_NAME = "Insight";

    public rows = 0;
    public selectedItem = false;
    public itemSelected: InsightPassengerSearchModel;
    public showExportPopover = false;
    public exportData: any;
    public header: string[];
    public printFlag = false;
    public copyToClipboardFlag = true;
    public exportFlag = false;
    public pagingDataView: PagingDataView[] = [];
    public searchFilter: InsightPassengerSearchModel;
    public focused: boolean = false;

    @Input() filter: InsightPassengerSearchModel;
    @Input() actionSecurity: SecurityGroupSecurityModel;
    @Input() security: SecurityGroupSecurityModel[];
    @Input() executeFlag: boolean = true
    @Input() countryReferences: CountryReferenceModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Output() onCopy = new EventEmitter<any>();
    @Output() searchFilterIdChange = new EventEmitter<string>();
    @Output() onDisplayAlert = new EventEmitter<string>();
    @ViewChild('dataGridSearch', { static: false }) dataGrid: IndividualSearchTableComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    get isBtnNewDisabled() {
        return !this.actionSecurity?.newFlag;
    }

    get isBtnCopyDisabled() {
        return !this.actionSecurity?.copyFlag;
    }

    get isBtnEditDisabled() {
        return !this.actionSecurity?.editFlag;
    }

    get isBtnDeleteDisabled() {
        return !this.actionSecurity?.deleteFlag;
    }

    constructor(private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private focusingService: FocusingService) {
        super();
        this.setExportBtnFlag();
    }

    private setExportBtnFlag() {
        this.printFlag = this.actionSecurity?.printFlag;
        this.copyToClipboardFlag = this.actionSecurity?.copyToClipboardFlag;
        this.exportFlag = this.actionSecurity?.exportFlag;
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['actionSecurity']) {
            this.setExportBtnFlag();
        }
    }

    ngAfterViewInit(): void {
        this.setExportBtnFlag();
        if (this.executeFlag == true) {
            this.searchInsightPassenger(this.filter);
        }
    }

    searchInsightPassenger(filter: InsightPassengerSearchModel) {
        this.dataGrid.searchIndividuals(filter);
        this.searchFilter = filter;
        this.hideAlertBar();
    }

    public editInsightDetail() {
        if (!this.itemSelected) {
            return;
        }

        if (this.itemSelected.usageTypeCode == "FILTER") {
            this.searchFilterIdChange.emit(this.itemSelected.individualId);
            return;
        }

        this.openInsightDetail(this.itemSelected.individualId);
    }

    public openInsightDetail(individualId: string) {
        let searchResult: any[] = this.dataGrid.individualSearchResults;
        this.pagingDataView = [];
        searchResult.forEach(row => {
            this.addPaggingView(row.individualId, row.no);
        })
        const queryParams = {
            id: individualId,
            security: this.security,
            paggingView: this.pagingDataView,
            currentIndex: this.pagingDataView.filter(item => item.id == individualId)[0]?.no,
            searchFilter: this.searchFilter
        }

        this.navigationService.navigate('main/insight/details', null, false, queryParams);    
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e;

        let actionBarHandler = this.actionbarService.getCurrentState();
        actionBarHandler.get(ACTION_STATUS.copy)?.enable();
        this.actionbarService.updateState(actionBarHandler);
    }


    public new() {
        const queryParams = {
            id: null,
            security: this.security,
            searchFilter: this.searchFilter
        }

        this.navigationService.navigate('main/insight/details', null, false, queryParams);
    }

    copyItem() {
        if (this.selectedItem) {
            this.onCopy.emit(this.itemSelected);
        }
    }

    getCountryName = (row: InsightPassengerSearchModel) => {
        return this.countryReferences?.filter(item => item.countryCode == row.nationality)[0]?.countryName;
    }

    addPaggingView(id: string, index: number) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.pagingDataView.push(pview);
    }

    public getNumberOfRows(e) {
        this.rows = e;
    }

    editRefresh()
    {
        this.searchInsightPassenger(this.filter);
    }

    public displayAlertBar(message: string) {
        this.onDisplayAlert.emit(message);
    }

    private hideAlertBar() {
        this.onDisplayAlert.emit(null);
    }
}
export class LocationReferenceModel {
    public locationId: string;
    public parentLocationId: string;
    public organisationId: string;
    public locationCode: string;
    public countryCode: string;
    public stateId: string;
    public timezoneCode: string;
    public locationName: string;
    public longitude: number;
    public latitude: number;
    public statusCode: string;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { InsightManagementModel } from 'src/app/core/models/reference-model/reference-general-model/Insight-management.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { InsightManagementService } from 'src/app/core/services/system-services/insight-management.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { NewButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { OopsCategory } from '../../../../core/components/categories/oops-category.model';
import { InsightCategoriesMapperService } from './insight-categories.mapper';
import { InsightCategory } from './insight-category';

@Component({
    selector: 'op-insight-categories',
    templateUrl: './insight-categories.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [InsightCategoriesMapperService]
})
export class InsightCategoriesComponent {
    private readonly actionIdNew = 'NEW';
    private readonly actionIdSearch = 'SEARCH';
    private readonly actionIdDashboard = 'DASHBOARD';

    @Input() initSelectedItem: InsightCategory;
    @Output() rowSelected = new EventEmitter<InsightCategory>();
    @Output() menuSelected = new EventEmitter<string>();

    public categoriesItems: InsightCategory[] = [];
    public selectedItem: OopsCategory;
    public userSecurity: SecurityGroupSecurityModel[] = [];
    private unsubscribe$ = new Subject();

    private contextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'New',
            actionId: this.actionIdNew
        },
        {
            name: 'Search',
            actionId: this.actionIdSearch
        },
        {
            name: 'Dashboard',
            actionId: this.actionIdDashboard
        },        
    ]

    constructor(private actionbarService: ActionbarService,
        private insightManagementService: InsightManagementService,
        private changeDetectorRef: ChangeDetectorRef,
        private mainSpinner: LoadingSpinnerService,
        private insightCategoriesMapperService: InsightCategoriesMapperService,
        private securityGroupService: SecurityGroupService,
        private navigationService: NavigationService) { 
            this.getUserSecurity();
    }

    private getUserSecurity() {
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {
                    Object.assign(this.userSecurity, response);
                }
            )
    }

    onMenuSelected(item: OopsCategory) {
        this.selectedItem = item;
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(new ActionBarHandlerModel(new NewButtonModel(), new RefreshButtonModel()));
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
        this.getInsightManagement();
    }

    toolbarClicked(e: string) {
        switch (e) {
            case ACTION_STATUS.refresh:
                this.getInsightManagement();
                break;
            case ACTION_STATUS.new:
                this.gotoNewProduct();
                break;
            default:
                break;
        }
    }

    public getInsightManagement() {
        this.insightManagementService.getAll()
            .subscribe(
                (responses: InsightManagementModel[]) => {
                    this.categoriesItems = this.insightCategoriesMapperService.toInsightCategoryViews(responses, this.userSecurity);
                    this.setContextMenus(this.categoriesItems);
                    this.mainSpinner.hide();
                    this.changeDetectorRef.detectChanges();
                },
                error => {
                    this.mainSpinner.hide();
                }
            );
    }

    private setContextMenus(categoriesItems: OopsCategory[]) {
        for (let item of categoriesItems) {
            item.contextMenus = this.contextMenu;
            this.checkSecurityCode(item);
        }
    }

    private checkSecurityCode(category: OopsCategory) {
        if (category.security != null) {
            if (category.security.newFlag == false) {
                let newIndex = category.contextMenus.findIndex(item => item.actionId == this.actionIdNew);
                category.contextMenus[newIndex].disable = true;
            }
        }
    }

    public onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case this.actionIdNew:
                this.gotoNewProduct();
                break;
            case this.actionIdSearch:
                this.menuSelected.emit(actionId);
                break;
            case this.actionIdDashboard:
                break;           
        }
    }

    private gotoNewProduct() {
        let url = 'main/insight/details';
        this.goToDetails(url);
    }

    private goToDetails(url: string) {
        const queryParams = {
            id: null,
            security: this.userSecurity
        }

        this.navigationService.navigate(url, null, false, queryParams);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxDataGridModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorModule } from "src/app/shared/ui/forms/inputs/status-color/status-color.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { DetailsConverter, SearchConverter } from "../price-dimensions/shared";
import { PriceConditionsComponent } from "./price-conditions.component";
import { ConditionComponent } from "./search/condition/condition.component";
import { ResultComponent } from "./search/result/result.component";

@NgModule({
    imports: [
        BsDropdownModule,
        CommonModule,
        DaterangepickerModule,
        DxDataGridModule,
        FocusingModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorModule,
        UiModule
    ],
    declarations: [
        ConditionComponent,
        PriceConditionsComponent,
        ResultComponent,
    ],
    exports: [
        PriceConditionsComponent
    ],
    providers : [
        SearchConverter,
        DetailsConverter
    ]
})
export class PriceConditionsModule {}
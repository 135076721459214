import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationPointModel } from 'src/app/core/models/merchandizing-config';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { RestrictionView } from 'src/app/core/components/rules-config/shared/views/restriction.view';

@Injectable()
export class LocationDataService {

    public getLocationPointArea(ctl, areaTypeOption, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        let locationPointCode = ctl.get('locationPoint').value;
        if (locationPointCode != null) {
            var filterArea = locationPoint$.value.filter(x => x.locationPointCode == locationPointCode);
            let areaData = filterArea[0].areas.filter(x => x.areaCode != "");
            ctl.get('locationPointAreasData').setValue(areaData);

            var filterplaceHolder = filterArea[0].areas.filter(x => x.areaCode == "");
            if (filterplaceHolder.length != 0) {
                areaTypeOption.placeholder = filterplaceHolder[0].areaName;
            }
            else {
                ctl.get('locationPointAreas').setValue(areaData[0].areaCode);
            }
        }
        else {
            var filterAreaDefault = locationPoint$.value.filter(x => x.locationPointCode != "");
            let areaData = filterAreaDefault[0].areas.filter(x => x.areaCode != "");
            ctl.get('locationPointAreasData').setValue(areaData);

            var filterplaceHolder = filterAreaDefault[0].areas.filter(x => x.areaCode == "");
            if (filterplaceHolder.length != 0) {
                areaTypeOption.placeholder = filterplaceHolder[0].areaName;
            }
            else {
                ctl.get('locationPointAreas').setValue(areaData[0].areaCode);
            }
        }
    }

    public getLocationPointOperator(ctl, locationTypeOption, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        let locationPointCode = ctl.get('locationPoint').value;
        if (locationPointCode != null) {
            var filterOperator = locationPoint$.value.filter(x => x.locationPointCode == locationPointCode);
            ctl.get('locationPointOperatorData').setValue(filterOperator[0].operators);

            var filterplaceHolder = filterOperator[0].operators.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                locationTypeOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                ctl.get('locationPointOperator').setValue(filterOperator[0].operators[0].operatorCode);
            }
        }
        else {
            var filterOperatorDefault = locationPoint$.value.filter(x => x.locationPointCode != "");
            ctl.get('locationPointOperatorData').setValue(filterOperatorDefault[0].operators);

            var filterplaceHolder = filterOperatorDefault[0].operators.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                locationTypeOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                ctl.get('locationPointOperator').setValue(filterOperatorDefault[0].operators[0].operatorCode);
            }
        }
    }

    public getLocationPointAreaOD(ctl, locationTypeOption, areaTypeOption, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        let locationPointCode = "service"; //ctl.get('saleOrService').value;
        if (locationPointCode != null) {
            var filterOD = locationPoint$.value.filter(x => x.locationPointCode == locationPointCode);
            let odData = filterOD[0].ods.filter(x => x.odCode != "");
            ctl.get('locationPointODsData').setValue(odData);

            var filterplaceHolder = filterOD[0].ods.filter(x => x.odCode == "");
            if (filterplaceHolder.length != 0) {
                locationTypeOption.placeholder = filterplaceHolder[0].odName;
            }
            else {
                ctl.get('locationPointODs').setValue(odData[0].odCode);
            }
        }
        else {
            var filterODDefault = locationPoint$.value.filter(x => x.locationPointCode == "service");
            let odData = filterODDefault[0].ods.filter(x => x.odCode != "");
            ctl.get('locationPointODsData').setValue(odData);

            var filterplaceHolder = filterODDefault[0].ods.filter(x => x.odCode == "");
            if (filterplaceHolder.length != 0) {
                areaTypeOption.placeholder = filterplaceHolder[0].odName;
            }
            else {
                ctl.get('locationPointODs').setValue(odData[0].odCode);
            }
        }
    }

    public getLocationAreasOperator(ctl, config, locationTypeOption) {
        var filterLocation = config.types.filter(x => x.typeCode == 'location');
        if (filterLocation.length != 0) {
            var filterArea = filterLocation[0].areas.filter(x => x.areaCode == ctl.get('locationAreas').value);
            ctl.get('locationAreasOperatorsData').setValue(filterArea[0].operators);

            var filterplaceHolder = filterArea[0].operators.filter(x => x.operatorCode == "");
            if (filterplaceHolder.length != 0) {
                locationTypeOption.placeholder = filterplaceHolder[0].operatorName;
            }
            else {
                ctl.get('locationAreasOperators').setValue(filterArea[0].operators[0].operatorCode);
            }
        }
    }

    public fillOriginRouteData(f, productRestriction: RestrictionView) {
        if (productRestriction.type == "route") {
            let ctl = f;
            let originRoute = ctl.get('originRoute').value;
            if (originRoute != null) {
                let originId = productRestriction.productRestrictionRouteView.originLocationId;
                let originName = productRestriction.productRestrictionRouteView.originLocationName;
                let select2Data: Select2Data[] = new Array();
                let data = new Select2Data();
                data.id = originId;
                data.text = originName;
                select2Data.push(data);

                let originRouteData$ = new BehaviorSubject<Select2Data[]>(null);
                originRouteData$.next(select2Data);
                ctl.get('originRouteData').setValue(originRouteData$);
            }
        }
    }

    public fillDestinationRouteData(f, productRestriction: RestrictionView) {
        if (productRestriction.type == "route") {
            let ctl = f;
            let destinationRoute = ctl.get('destinationRoute').value;
            if (destinationRoute != null) {
                let destinationId = productRestriction.productRestrictionRouteView.destinationLocationId;
                let destinationName = productRestriction.productRestrictionRouteView.destinationLocationName;
                let select2Data: Select2Data[] = new Array();
                let data = new Select2Data();
                data.id = destinationId;
                data.text = destinationName;
                select2Data.push(data);

                let destinationRouteData$ = new BehaviorSubject<Select2Data[]>(null);
                destinationRouteData$.next(select2Data);
                ctl.get('destinationRouteData').setValue(destinationRouteData$);
            }
        }
    }

    public fillLocationPointAreaData(f, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        if (locationPoint$.value) {
            var filterLocationPoint = locationPoint$.value.filter(x => x.locationPointCode != "");
            if (filterLocationPoint.length != 0) {
                let areaData = filterLocationPoint[0].areas.filter(x => x.areaCode != "");
                f.controls['locationPointAreasData'].setValue(areaData);
            }
        }
    }

    public filllocationPointOperatorData(f, locationPoint$: BehaviorSubject<LocationPointModel[]>) {
        if (locationPoint$.value) {
            var filterLocationPoint = locationPoint$.value.filter(x => x.locationPointCode != "");
            if (filterLocationPoint.length != 0) {
                f.controls['locationPointOperatorData'].setValue(filterLocationPoint[0].operators);
            }
        }
    }

    public fillLocationAirportData(f, productRestriction: RestrictionView,
        locationAirportSelect2Datas: Select2Data[]) {
        if (productRestriction.type == "location") {
            let ctl = f;
            let locationAirport = ctl.get('locationPointValueAirport').value;
            if (locationAirport != null) {
                let locationAirportData$ = new BehaviorSubject<Select2Data[]>(null);
                locationAirportData$.next(locationAirportSelect2Datas);
                ctl.get('locationPointValueAirportData').setValue(locationAirportData$);
            }
        }
    }

    public fillLocationArea(f, config) {
        var filterType = config.types.filter(x => x.typeCode == "location");
        if (filterType.length != 0) {
            let locationAreasData = filterType[0].areas.filter(x => x.areaCode != "");
            f.controls['locationAreasData'].setValue(locationAreasData);
        }
    }

    public fillLocationAreasOperatorData(f, config) {
        var filterType = config.types.filter(x => x.typeCode == "location");
        if (filterType.length != 0) {
            let locationAreasData = filterType[0].areas.filter(x => x.areaCode != "");
            let operatorData = locationAreasData[0].operators;
            f.controls['locationAreasOperatorsData'].setValue(operatorData);
        }
    }

    public fillLocationAreasAirportData(f, productRestriction: RestrictionView,
        locationAirportSelect2Datas: Select2Data[]) {
        if (productRestriction.type == "location") {
            let ctl = f;
            let locationAirport = ctl.get('locationAreasValueAirport').value;
            if (locationAirport != null) {
                let locationAirportData$ = new BehaviorSubject<Select2Data[]>(null);
                locationAirportData$.next(locationAirportSelect2Datas);
                ctl.get('locationAreasValueAirportData').setValue(locationAirportData$);
            }
        }
    }
}
import { Injectable } from '@angular/core';

import { RestrictionMapperService } from 'src/app/core/components/rules-config/shared/mappers/restriction-mapper.service';
import { PointOfSalesMapperService } from 'src/app/core/components/rules-config/shared/mappers/pointofsales-mapper.service';
import { ValidityMapperService } from 'src/app/core/components/rules-config/shared/mappers/validity-mapper.service';
import { MerchandizeSearchTableView } from './merchandize-search-table.view';
import { MerchandizeAddCommandModel, MerchandizeEditCommandModel, MerchandizeViewModel } from 'src/app/core/models/product-model/merchandize-model';
import { PagingDataView } from '../../../../../../core/views/pagging-data.view';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { MediaHashTagModel } from 'src/app/core/models/reference-model/reference-media-model';
import { BehaviorSubject } from 'rxjs';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { MerchandizeSearchCommandModel } from 'src/app/core/models/product-model/merchandize-model/merchandize-seach-command.model';
import { ProductNumberCommandModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductRestrictionCommandView } from 'src/app/core/components/rules-config/shared/views/restriction-command.view';
import { ProductRestrictionLocationCommandModel, ProductRestrictionOrganisationCommandModel, ProductRestrictionProductCommandModel, ProductRestrictionProductNumberCommandModel, ProductRestrictionRouteCommandModel } from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { ProductValidityCommandModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { ProductAttributeCommandModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { PointOfSalesCommandView } from 'src/app/core/components/rules-config/shared/views/point-of-sales-command.view';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { HashTagMapperService } from '../../../shared/mapper/hashtag-mapper.service';
import { ProductHashTagView } from '../../../shared/views/product-hashtag.view';
import { NumberMapperService } from '../../../shared/mapper/number-mapper.service';
import { ProductAttributeAndRuleComponent } from '../../attribute-and-rule/attribute-and-rule.component';

@Injectable()
export class MapperService {

    constructor(
        private attributeMapperService: AttributeMapperService, 
        private numberMapperService: NumberMapperService,
        private hashTagMapperService: HashTagMapperService,
        private pointOfSalesMapperService: PointOfSalesMapperService,
        private restrictionMapperService: RestrictionMapperService,
        private validityMapperService: ValidityMapperService
        ) {
    }

    public MerchandizeSearchTableViews(models: MerchandizeViewModel[], pagingView: PagingDataView[]): MerchandizeSearchTableView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.MerchandizeSearchTableView(i, model));
            pagingView.push(this.PaggingView(i, model.productId));
        }

        return views;
    }

    public MerchandizeSearchTableView(index: number, model: MerchandizeViewModel): MerchandizeSearchTableView {
        let view = new MerchandizeSearchTableView();
        view.no = index;
        view.id = model.productId;
        view.rootProductId = model.rootProductId;
        view.name = model.productName;
        view.productGroupName = model.productGroupName;
        view.productTypeName = model.productTypeName;
        view.statusCode = model.statusCode;
        view.usageTypeCode = model.usageTypeCode;
        view.usageTypeName = model.usageTypeName;
        view.commitBy = model.commitBy;
        view.commitDateTime = model.commitDateTime;
        return view;
    }

    public PaggingView(index: number, id: string): PagingDataView {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        return pview;
    }

    public mediaHashTagToSelect2Data(mediaHashTags: MediaHashTagModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let mediaHash of mediaHashTags) {
            let data = new Select2Data();
            data.id = mediaHash.mediaHashTagId
            data.text = mediaHash.mediaHashTagName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public convertToAddCommand(product: MerchandizeViewModel, attributeAndRuleComponent: ProductAttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): MerchandizeAddCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionViews: ProductRestrictionCommandView[] = new Array();
        let productRestrictionProducts: ProductRestrictionProductCommandModel[] = new Array();
        let productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[] = new Array();
        let productRestrictionRoutes: ProductRestrictionRouteCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
         if (attributeAndRuleComponent) {
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);
            productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);
            productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
            productValidities = this.validityMapperService.productValidityFormToModels(attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
            productAttributes = this.attributeMapperService.productAttributeFormToModels(attributeAndRuleComponent.productAttributeComponent.forms);
            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
         }

        let command: MerchandizeAddCommandModel = {
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            providerId: null,
            parentProductId: null,
            productName: product.productName,
            productDescription: null,
            supplierId: null,
            displayCode: null,
            draftFlag: true,
            finalFlag: null,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            isOwnerFilter: product.isOwnerFilter,
            filterUserAccountId: null,
            usageTypeCode: product.usageTypeCode,
            statusCode: product.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: [],
            productInventoryDimensions: [],
            languageTranslationSet: null
        };
        return command;
    }

    public convertToEditCommand(product: MerchandizeViewModel, attributeAndRuleComponent: ProductAttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): MerchandizeEditCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionViews: ProductRestrictionCommandView[] = new Array();
        let productRestrictionProducts: ProductRestrictionProductCommandModel[] = new Array();
        let productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[] = new Array();
        let productRestrictionRoutes: ProductRestrictionRouteCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
        if (attributeAndRuleComponent) {
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);
            productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(attributeAndRuleComponent.restrictionRuleComponent.ruleComponent.forms);
            productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
            productValidities = this.validityMapperService.productValidityFormToModels(attributeAndRuleComponent.validityRuleComponent.ruleComponent.forms);
            productAttributes = this.attributeMapperService.productAttributeFormToModels(attributeAndRuleComponent.productAttributeComponent.forms);
            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent.forms);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
        }

        let command: MerchandizeEditCommandModel = {
            productId: product.productId,
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            providerId: null,
            parentProductId: null,
            productName: product.productName,
            productDescription: null,
            supplierId: null,
            displayCode: null,
            draftFlag: true,
            finalFlag: null,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            isOwnerFilter: product.isOwnerFilter,
            filterUserAccountId: null,
            usageTypeCode: product.usageTypeCode,
            statusCode: product.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productRestrictionOrganisations: productRestrictionOrganisations,
            medias: [],
            productInventoryDimensions: [],
            productInventories: [],
            languageTranslationSet: null
        };
        return command;
    }

    public convertToSearchCommand(product: MerchandizeViewModel, attributeAndRuleComponent: ProductAttributeAndRuleComponent, productHashtagValueViews: ProductHashTagView[], provider$: BehaviorSubject<OrganisationModel[]>): MerchandizeSearchCommandModel {
        let productHashTags = this.hashTagMapperService.productHashTagModels(productHashtagValueViews);
        let productNumbers: ProductNumberCommandModel[] = new Array();
        let productRestrictionViews: ProductRestrictionCommandView[] = new Array();
        let productRestrictionProducts: ProductRestrictionProductCommandModel[] = new Array();
        let productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[] = new Array();
        let productRestrictionRoutes: ProductRestrictionRouteCommandModel[] = new Array();
        let productRestrictionLocations: ProductRestrictionLocationCommandModel[] = new Array();
        let productValidities: ProductValidityCommandModel[] = new Array();
        let productAttributes: ProductAttributeCommandModel[] = new Array();
        let pointofSalesCommand: PointOfSalesCommandView[] = new Array();
        let productRestrictionLocationPointOfSales: ProductRestrictionLocationCommandModel[] = new Array();
        let productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[] = new Array();
        if (attributeAndRuleComponent) {
            productAttributes = this.attributeMapperService.productAttributeFormToModels(attributeAndRuleComponent.productAttributeComponent.forms);
            productNumbers = this.numberMapperService.productNumberFormToModels(attributeAndRuleComponent.productNumberComponent.forms, provider$);
            productRestrictionViews = this.restrictionMapperService.productRestrictionFormToCommandViews(attributeAndRuleComponent.restrictionRuleComponent.ruleComponent?.forms);
            productRestrictionProducts = this.restrictionMapperService.productRestrictionProductCommandModels(productRestrictionViews);
            productRestrictionProductNumbers = this.restrictionMapperService.productRestrictionProductNumberCommandModels(productRestrictionViews);
            productRestrictionRoutes = this.restrictionMapperService.productRestrictionRouteCommandModels(productRestrictionViews);
            productRestrictionLocations = this.restrictionMapperService.productRestrictionLocationCommandModels(productRestrictionViews);
            productRestrictionLocationPointOfSales = this.pointOfSalesMapperService.productRestrictionLocationPointOfSalesCommandModels(pointofSalesCommand);
            productRestrictionOrganisations = this.pointOfSalesMapperService.productRestrictionOrganisationCommandModels(pointofSalesCommand);
            productRestrictionLocations = this.pointOfSalesMapperService.MergePointOfSalesToProductLocationRestriction(productRestrictionLocations, productRestrictionLocationPointOfSales);
            pointofSalesCommand = this.pointOfSalesMapperService.productPointOfSalesFormToCommandViews(attributeAndRuleComponent.pointOfSalesComponent.ruleComponent?.forms);
            productValidities = this.validityMapperService.productValidityFormToModels(attributeAndRuleComponent.validityRuleComponent.ruleComponent?.forms);
        }

        let command: MerchandizeSearchCommandModel = {
            productCategoryCode: null,
            productGroupCode: product.productGroupCode,
            productTypeCode: product.productTypeCode,
            productName: product.productName,
            searchName: product.searchName,
            searchUsageTypeCode: product.searchUsageTypeCode,
            searchStatusCode: product.searchStatusCode,
            filterUserAccountId: product.filterUserAccountId,
            statusCode: product.statusCode,
            productHashTags: productHashTags,
            productNumbers: productNumbers,
            productRestrictionProducts: productRestrictionProducts,
            productRestrictionProductNumbers: productRestrictionProductNumbers,
            productRestrictionRoutes: productRestrictionRoutes,
            productRestrictionLocations: productRestrictionLocations,
            productValidities: productValidities,
            productAttributes: productAttributes,
            productRestrictionOrganisations: productRestrictionOrganisations,
        };
        return command;
    }

    public clearAttributeAndRule(attributeAndRuleComponent: ProductAttributeAndRuleComponent) {
        if (attributeAndRuleComponent) {
            attributeAndRuleComponent.productNumberComponent?.clearForm();
            attributeAndRuleComponent.productAttributeComponent?.clearForm();
            attributeAndRuleComponent.restrictionRuleComponent.ruleComponent?.clearForm();
            attributeAndRuleComponent.validityRuleComponent.ruleComponent?.clearForm();
            attributeAndRuleComponent.pointOfSalesComponent.ruleComponent?.clearForm();
        }
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivityDomainComponent } from "src/app/core/components/activity-domain/activity-domain.component";
import { MediaComponent } from "src/app/core/components/media/media.component";
import { MediaMapperService } from "src/app/core/components/media/shared/media-mapper.service";
import { InsightPassengerAttributeModel, InsightPassengerCommandModel, InsightPassengerCommentModel, InsightPassengerCommunicationOtherModel, InsightPassengerDocumentModel, InsightPassengerLanguageModel, InsightPassengerMembershipModel, InsightPassengerNameModel, InsightPassengerNationalityModel, InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { InsightPassengerAddressModel } from "src/app/core/models/individual-model/insight-passenger-address.model";
import { InsightPassengerCommunicationPhoneModel } from "src/app/core/models/individual-model/insight-passenger-communication-phone.model";
import { InsightPassengerModel } from "src/app/core/models/individual-model/insight-passenger.model";
import { MediaViewModel } from "src/app/core/models/media-model";
import { MediaFileAssociationViewModel } from "src/app/core/models/media-model/media-fileassociation";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { AddressTypeReferenceModel, CommentClassificationReferenceModel, CommentTypeReferenceModel, CommunicationOtherTypeReferenceModel, CommunicationPhoneTypeReferenceModel, CountryReferenceModel, DomainAttributeModel, GenderReferenceModel, IdentityDocumentReferenceModel, IndividualAgeGroupReferenceModel, LanguageReferenceModel, MembershipTypeReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";
import { MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from "src/app/core/models/reference-model/reference-media-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { IndividualTitleModel } from "src/app/core/models/user-model/individual-title.model";
import { DomainAttributeService, MembershipTypeReferenceService } from "src/app/core/services/airline-services";
import { MembershipLevelService } from "src/app/core/services/airline-services/membership-level.service";
import { InsightPassengerService } from "src/app/core/services/individul-services";
import { MediaFileAssociationService, MediaService } from "src/app/core/services/media-services";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from "src/app/core/services/reference-service/reference-media-service";
import { AddressTypeReferenceService, CommentClassificationReferenceService, CommentTypeReferenceService, CommunicationOtherTypeReferenceService, CommunicationPhoneTypeReferenceService, CountryReferenceService, GenderReferenceService, IdentityDocumentReferenceService, LanguageReferenceService, StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { IndividualAgeGroupReferenceService } from "src/app/core/services/system-services/individual-age-group-reference.service";
import { IndividualTitleReferenceService } from "src/app/core/services/user-account-services/individual-title.service";
import { ActionService } from "src/app/core/utils/action.service";
import { ActivityStoreService } from "src/app/core/utils/activity-store.service";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { NavigationService } from "src/app/shared/utils/navigation";
import { InsightDetailAddressComponent } from "./insight-detail-address/insight-detail-address.component";
import { AddressMapperService } from "./insight-detail-address/shared/address-mapper.service";
import { AddressView } from "./insight-detail-address/shared/address-view";
import { InsightDetailAttributeComponent } from "./insight-detail-attribute/insight-detail-attribute.component";
import { AttributeMapperService } from "./insight-detail-attribute/shared/attribute-mapper.service";
import { InsightDetailCommentComponent } from "./insight-detail-comment/insight-detail-comment.component";
import { CommentMapperService } from "./insight-detail-comment/shared/comment-mapper.service";
import { CommentView } from "./insight-detail-comment/shared/comment-view";
import { InsightDetailCommunicationOtherComponent } from "./insight-detail-communication-other/insight-detail-communication-other.component";
import { CommunicationOtherMapperService } from "./insight-detail-communication-other/shared/communication-other-mapper.service";
import { CommunicationOtherView } from "./insight-detail-communication-other/shared/communication-other-view";
import { InsightDetailCommunicationPhoneComponent } from "./insight-detail-communication-phone/insight-detail-communication-phone.component";
import { CommunicationPhoneMapperService } from "./insight-detail-communication-phone/shared/communication-phone-mapper.service";
import { CommunicationPhoneView } from "./insight-detail-communication-phone/shared/communication-phone-view";
import { InsightGeneralDetailComponent } from "./insight-detail-general/insight-detail-general.component";
import { InsightDetailLanguageComponent } from "./insight-detail-language/insight-detail-language.component";
import { LanguageMapperService } from "./insight-detail-language/shared/language-mapper.service";
import { LanguageView } from "./insight-detail-language/shared/language-view";
import { InsightDetailMembershipComponent } from "./insight-detail-membership/insight-detail-membership.component";
import { MembershipMapperService } from "./insight-detail-membership/shared/membership-mapper.service";
import { MembershipView } from "./insight-detail-membership/shared/membership-view";
import { InsightDetailNameComponent } from "./insight-detail-name/insight-detail-name.component";
import { NameMapperService } from "./insight-detail-name/shared/name-mapper.service";
import { NameView } from "./insight-detail-name/shared/name-view";
import { InsightDetailNationalityComponent } from "./insight-detail-nationality/insight-detail-nationality.component";
import { NationalityMapperService } from "./insight-detail-nationality/shared/nationality-mapper.service";
import { NationalityView } from "./insight-detail-nationality/shared/nationality-view";
import { InsightDetailProfileComponent } from "./insight-detail-profile/insight-detail-profile.component";
import { ProfileMapperService } from "./insight-detail-profile/shared/profile-mapper.service";
import { InsightSalesSummaryComponent } from "./insight-detail-sales-summary/insight-sales-summary.component";
import { InsightDetailDocumentComponent } from "./insight-document/insight-detail-document.component";
import { DocumentMapperService } from "./insight-document/shared/document-mapper.service";
import { DocumentView } from "./insight-document/shared/document-view";
import { MapperService } from "./shared/mapper.service";
import { UserAccountOrganisationService } from "src/app/core/services/user-account-services/user-account-organisation.service";

@Component({
    selector: 'op-insight-detail',
    templateUrl: './insight-detail.component.html',
    providers: [
        MapperService,
        NameMapperService,
        AddressMapperService,
        CommunicationPhoneMapperService,
        CommunicationOtherMapperService,
        DocumentMapperService,
        NationalityMapperService,
        LanguageMapperService,
        CommentMapperService,
        MembershipMapperService,
        AttributeMapperService,
        MediaMapperService,
        ProfileMapperService,
        ActionbarService
    ]
})
export class InsightDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly ENTITY_NAME = "Individual";

    readonly generalPanel = 'General Information';
    readonly addressPanel = 'Address';
    readonly communicationPhonePanel = 'Phone';
    readonly communicationOtherPanel = 'Communication Other';
    readonly documentPanel = 'Document';
    readonly nationalityPanel = 'Nationality';
    readonly languagePanel = 'Language';
    readonly namePanel = 'Name';
    readonly membershipPanel = 'Membership';
    readonly attributePanel = 'Attribute';
    readonly commentPanel = 'Comment';
    readonly mediaPanel = 'Media';
    readonly ERROR_PRIMARY_ADDRESS_REQUIRED = 'Primary address is required.';
    readonly ERROR_PRIMARY_COMMU_PHONE_REQUIRED = 'Primary phone is required.';
    readonly ERROR_PRIMARY_COMMU_OTHER_REQUIRED = 'Primary communication other is required.';
    readonly ERROR_PRIMARY_DOCUMENT_REQUIRED = 'Primary document is required.';
    readonly ERROR_PRIMARY_NATIONALITY_REQUIRED = 'Primary nationality is required.';
    readonly ERROR_PRIMARY_LANGUAGE_REQUIRED = 'Primary language is required.';
    readonly ERROR_PRIMARY_MEMBERSHIP_REQUIRED = 'Primary membership is required.';
    readonly ERROR_PRIMARY_NAME_REQUIRED = 'Primary name is required.';
    readonly ERROR_PRIMARY_ATTRIBUTE_REQUIRED = 'Primary attribute is required.';

    readonly generalTabId = 'general';
    readonly nameTabId = 'name';
    readonly addressTabId = 'address';
    readonly phoneTabId = 'communicationPhone';
    readonly communicationOtherTabId = 'communicationOther';
    readonly documentTabId = 'document';
    readonly nationalityTabId = 'nationality';
    readonly languageTabId = 'language';
    readonly commentTabId = 'comment';
    readonly membershipTabId = 'membership';
    readonly profileTabId = 'profile';
    readonly attributeTabId = 'attribute';
    readonly mediaTabId = 'media';
    readonly salesSummaryTabId = 'salesSummary';

    readonly INDIVIDUAL_SECURITY = 'INDIVIDUAL'
    readonly TEMPLATE_SECURITY = "INDIVIDUALTEMPLATE";
    readonly TEMPLATE_TYPE_CODE = "TEMPLATE";
    readonly DATA_TYPE_CODE = "DATA";
    private readonly STATUSCODE_DELETED: string = "D";

    public individualId: string;
    public disabledSave: boolean = false;
    public newInsightPassenger: boolean = false;
    public copy: boolean = false;
    public insightPassenger$ = new BehaviorSubject<InsightPassengerModel>(null);
    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public genderReference$ = new BehaviorSubject<GenderReferenceModel[]>(null);
    public usageTypeReference$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public individualTitleReference$ = new BehaviorSubject<IndividualTitleModel[]>(null);
    public individualAgeGroupReference$ = new BehaviorSubject<IndividualAgeGroupReferenceModel[]>(null);
    public addressTypeReferences$ = new BehaviorSubject<AddressTypeReferenceModel[]>(null);
    public countryReferences$ = new BehaviorSubject<CountryReferenceModel[]>(null);
    public useAddressDropdown$ = new BehaviorSubject<InsightPassengerAddressModel[]>(null);
    public communicationPhoneTypeReference$ = new BehaviorSubject<CommunicationPhoneTypeReferenceModel[]>(null);
    public communicationOtherTypeReference$ = new BehaviorSubject<CommunicationOtherTypeReferenceModel[]>(null);
    public identityDocumentTypeReference$ = new BehaviorSubject<IdentityDocumentReferenceModel[]>(null);
    public commentTypeReference$ = new BehaviorSubject<CommentTypeReferenceModel[]>(null);
    public commentClassificationReference$ = new BehaviorSubject<CommentClassificationReferenceModel[]>(null);
    public membershipTypeReference$ = new BehaviorSubject<MembershipTypeReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public organisationAirline$ = new BehaviorSubject<OrganisationModel[]>(null);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public profilePicture$ = new BehaviorSubject<MediaViewModel[]>(new Array());
    public actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    );

    private unsubscribe$ = new Subject();
    private security: SecurityGroupSecurityModel[];
    public actionSecurity: SecurityGroupSecurityModel;
    private insightNames: NameView[] = [];
    public membershipLevels$ = new BehaviorSubject<MembershipLevelModel[]>(null);

    public summaryTabClicked = false;
    public searchFilter: InsightPassengerSearchModel;

    private panelFocus: string;

    @ViewChild(InsightGeneralDetailComponent) insightGeneralDetailComonent: InsightGeneralDetailComponent;
    @ViewChild(InsightDetailNameComponent) nameComponent: InsightDetailNameComponent;
    @ViewChild(InsightDetailAddressComponent) addressComponent: InsightDetailAddressComponent;
    @ViewChild(InsightDetailCommunicationPhoneComponent) communicationPhoneComponent: InsightDetailCommunicationPhoneComponent;
    @ViewChild(InsightDetailCommunicationOtherComponent) communicationOtherComponent: InsightDetailCommunicationOtherComponent;
    @ViewChild(InsightDetailDocumentComponent) documentComponent: InsightDetailDocumentComponent;
    @ViewChild(InsightDetailNationalityComponent) nationalityComponent: InsightDetailNationalityComponent;
    @ViewChild(InsightDetailLanguageComponent) languageComponent: InsightDetailLanguageComponent;
    @ViewChild(InsightDetailCommentComponent) commentComponent: InsightDetailCommentComponent;
    @ViewChild(InsightDetailMembershipComponent) membershipComponent: InsightDetailMembershipComponent;
    @ViewChild(InsightDetailAttributeComponent) attributeComponent: InsightDetailAttributeComponent;
    @ViewChild(InsightDetailProfileComponent) profileComponent: InsightDetailProfileComponent;
    @ViewChild(InsightSalesSummaryComponent) salesSummaryComponent: InsightSalesSummaryComponent;
    @ViewChild(MediaComponent) mediaComponent: MediaComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    
    selectedTab = this.generalTabId;
    private navigationRequestParams: any;
    public pagingDataView: PagingDataView[] = [];
    public currentPageIndex: number;
    public pageChanging: boolean = false;
    private currentStatusCode: string;

    constructor(private statusReferenceService: StatusReferenceService,
        private genderReferenceService: GenderReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private languageReferenceService: LanguageReferenceService,
        private insightPassengerService: InsightPassengerService,
        private changeDetectionRef: ChangeDetectorRef,
        private navigateService: NavigationService,
        private mediaService: MediaService,
        private mediaFileAssociationService: MediaFileAssociationService,
        private individualTitleService: IndividualTitleReferenceService,
        private individualAgeGroupService: IndividualAgeGroupReferenceService,
        private addressTypeService: AddressTypeReferenceService,
        private countryService: CountryReferenceService,
        private comunicationPhoneTypeService: CommunicationPhoneTypeReferenceService,
        private comunicationOtherTypeService: CommunicationOtherTypeReferenceService,
        private identityDocumentTypeService: IdentityDocumentReferenceService,
        private commentTypeReferenceService: CommentTypeReferenceService,
        private commentClassificationReferenceService: CommentClassificationReferenceService,
        private membershipTypeReferenceService: MembershipTypeReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private orgnisationAirlineService: OrganisationService,
        private domainAttributeService: DomainAttributeService,
        private nameMapperService: NameMapperService,
        private mapperService: MapperService,
        private addressMapperService: AddressMapperService,
        private communicationPhoneMapperService: CommunicationPhoneMapperService,
        private communicationOtherMapperService: CommunicationOtherMapperService,
        private documentMapperService: DocumentMapperService,
        private nationalityMapperService: NationalityMapperService,
        private languageMapperService: LanguageMapperService,
        private commentMapperService: CommentMapperService,
        private membershipMapperService: MembershipMapperService,
        private attributeMapperService: AttributeMapperService,
        private mediaMapperService: MediaMapperService,
        private profileMapperService: ProfileMapperService,
        private focusingService: FocusingService,
        public alertBarService: AlertBarService,
        private actionbarService: ActionbarService,
        public spinnerService: LoadingSpinnerService,
        private actionService: ActionService,
        private membershipLevelService: MembershipLevelService,
        private changeDetectorRef: ChangeDetectorRef,
        private activityStoreService: ActivityStoreService,
        private userAccountOrganisationService: UserAccountOrganisationService) { 
            this.init();
        }

    private init() {
        this.navigationRequestParams = this.navigateService.getParams();
        if (!this.navigationRequestParams)
            this.navigationRequestParams = this.navigateService.getPreviousTabParams()?.params

        this.individualId = this.navigationRequestParams?.id ?? null;
        this.security = this.navigationRequestParams?.security
        this.actionSecurity = this.navigationRequestParams?.security?.filter(x => x.securityCode == this.INDIVIDUAL_SECURITY)[0];
        this.copy = this.navigationRequestParams?.copy ?? false;
        this.newInsightPassenger = this.copy ? true : this.individualId == null;
        this.pagingDataView = this.navigationRequestParams?.paggingView ?? [];
        this.currentPageIndex = this.navigationRequestParams?.currentIndex;
        this.searchFilter = this.navigationRequestParams?.searchFilter;
        if (this.individualId != null) {
            this.getInsightPassenger(this.individualId);
            this.getMedia(this.ENTITY_NAME, this.individualId);

            if (this.copy) {
                this.individualId = null;
            }
        }
        else {
            this.newInsightPassenger = true;
            this.addAction();
            this.activityStore();
        }
    }

    private updateActionBarHandlerState() {
        let actionBarHandler = this.actionbarService.getCurrentState();
        if (!actionBarHandler) {
            return;
        }
        actionBarHandler.get(ACTION_STATUS.new)?.enable(this.actionSecurity?.newFlag ?? false);
        actionBarHandler.get(ACTION_STATUS.copy)?.enable(this.actionSecurity?.copyFlag ?? false);
        actionBarHandler.get(ACTION_STATUS.save)?.enable(this.actionSecurity?.editFlag ?? false);

        if (this.newInsightPassenger) {
            actionBarHandler.get(ACTION_STATUS.new)?.enable(this.actionSecurity?.newFlag ?? false);
            actionBarHandler.get(ACTION_STATUS.save)?.enable(this.actionSecurity?.newFlag ?? false);
        } 
        
        if (this.copy) {
            actionBarHandler.get(ACTION_STATUS.new)?.enable(this.actionSecurity?.newFlag ?? false);
            actionBarHandler.get(ACTION_STATUS.save)?.enable(this.actionSecurity?.copyFlag ?? false);
        }        

        this.actionbarService.updateState(this.actionBarHandler);
    }

    ngOnInit(): void {
        this.getStatusReferences();
        this.getGenderReferences();
        this.getUsageTypeReferences();
        this.getLanguageReferences();
        this.getTitleReference();
        this.getIndividualAgeGroupReferences();
        this.getAddressTypeReferences();
        this.getCountryReferences();
        this.getCommunicationPhoneTypeReferences();
        this.getCommunicationOtherTypeReferences();
        this.getCommentTypeReferences();
        this.getCommentClassificationReferences();
        this.getMembershipTypeReferences();
        this.getOrganisationAirlines();
        this.getIdentityDocumentReferences();
        this.getMediaTypeReference();
        this.getMediaUseReference();
        this.getMediaHashTag();
        this.getMediaTypeFileType();
        this.getDomainAttributes();
        this.getMembershipLevel();
    }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )

        this.updateActionBarHandlerState();
        window.scrollTo({top: 0, behavior:'smooth'});
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    getInsightPassenger(individualId: string) {
        this.spinnerService.show();
        forkJoin({
            response: this.insightPassengerService.getInsightPassenger(individualId),
            domainAttributes: this.domainAttributeService.getIndividualAttributes('', '')
        })
        .subscribe(
            ({
                response,
                domainAttributes
            }) => {
                if (this.copy) {
                    this.setUpCopyIndividual(response);
                    this.individualId = null;
                }
                this.domainAttribute$.next(domainAttributes);
                this.setResponesUsageType(response);     
                this.insightPassenger$.next(response);
                this.useAddressDropdown$.next(response.insightPassengerAddresses.filter(x => !x.useAddressId));
                this.fillInsightPassengerName(response.insightPassengerNames);
                this.fillInsightPassengerAddress(response.insightPassengerAddresses);
                this.fillInsightPassengerCommunicationPhone(response.insightPassengerCommunicationPhones);
                this.fillInsightPassengerCommunicationOther(response.insightPassengerCommunicationOthers);
                this.fillInsightPassengerDocument(response.insightPassengerDocuments);
                this.fillInsightPassengerNationality(response.insightPassengerNationalities);
                this.fillInsightPassengerLanguage(response.insightPassengerLanguages);
                this.fillInsightPassengerComment(response.insightPassengerComments);
                this.fillInsightPassengerMembership(response.insightPassengerMemberships);
                this.fillInsightPassengerProfile(response);
                this.fillInsightPassengerAttribute(response.insightPassengerAttributes);

                if (response.medias?.length) {
                    this.fillInsightMedia(response.medias.filter(item => item.mediaUses.length > 0));
                }

                this.addAction();
                this.spinnerService.hide();
                this.pageChanging = false;
                this.individualId = individualId;
                this.currentStatusCode = response.statusCode;
            }
        )
    }

    private setUpCopyIndividual(response: InsightPassengerModel) {
        response.insightPassengerNames.filter(item => item.primaryFlag == true)[0].lastName += ' - COPY';
        this.setResponseDataForCopying(response);
    }

    private setResponseDataForCopying(response: InsightPassengerModel) {
        this.setAddressesIdForCopying(response.insightPassengerAddresses);
        this.setCommentIdForCopying(response.insightPassengerComments);
        this.setCommunicationOtherIdForCopying(response.insightPassengerCommunicationOthers);
        this.setCommunicationPhoneIdForCopying(response.insightPassengerCommunicationPhones);
        this.setDocumentIdForCopying(response.insightPassengerDocuments);
        this.setLanguageIdForCopying(response.insightPassengerLanguages);
        this.setMembershipIdForCopying(response.insightPassengerMemberships);
        this.setNamesIdForCopying(response.insightPassengerNames);
        this.setNationalityIdForCopying(response.insightPassengerNationalities);
    }

    private setAddressesIdForCopying(addresses: InsightPassengerAddressModel[]) {
        if (addresses.length) {
            for (let address of addresses) {
                address.addressId = null;
            }
        }
    }

    private setCommentIdForCopying(comments: InsightPassengerCommentModel[]) {
        if (comments.length) {
            for (let comment of comments) {
                comment.commentId = null;
            }
        }
    }

    private setCommunicationOtherIdForCopying(others: InsightPassengerCommunicationOtherModel[]) {
        if (others.length) {
            for (let other of others) {
                other.communicationOtherId = null;
            }
        }
    }

    private setCommunicationPhoneIdForCopying(phones: InsightPassengerCommunicationPhoneModel[]) {
        if (phones.length) {
            for (let phone of phones) {
                phone.communicationPhoneId = null;
            }
        }
    }

    private setDocumentIdForCopying(documents: InsightPassengerDocumentModel[]) {
        if (documents.length) {
            for (let document of documents) {
                document.individualIdentityDocumentId = null;
            }
        }
    }

    private setLanguageIdForCopying(languages: InsightPassengerLanguageModel[]) {
        if (languages.length) {
            for (let language of languages) {
                language.individualLanguageId = null;
            }
        }
    }

    private setMembershipIdForCopying(memberships: InsightPassengerMembershipModel[]) {
        if (memberships.length) {
            for (let membership of memberships) {
                membership.membershipLevelId = null;
            }
        }
    }

    private setNamesIdForCopying(names: InsightPassengerNameModel[]) {
        if (names.length) {
            for (let name of names) {
                name.individualNameId = null;
            }
        }
    }

    private setNationalityIdForCopying(nationalities: InsightPassengerNationalityModel[]) {
        if (nationalities.length) {
            for (let nationality of nationalities) {
                nationality.individualNationalityId = null;
            }
        }
    }
    
    private setResponesUsageType(response: InsightPassengerModel) {
        if (!this.security?.filter(x => x.securityCode == this.TEMPLATE_SECURITY)?.length && this.copy) {
            response.usageTypeCode = this.DATA_TYPE_CODE;
        }
    }

    private fillInsightPassengerName(passengerNames: InsightPassengerNameModel[]) {
        this.insightNames = this.nameMapperService.nameViews(passengerNames);
        this.nameComponent.fillModelToForm(this.insightNames);
    }

    private fillInsightPassengerAddress(passengerAddresses: InsightPassengerAddressModel[]) {
        let addressViews = this.addressMapperService.addressViews(passengerAddresses);
        this.addressComponent.fillModelToForm(addressViews);
    }

    private fillInsightPassengerCommunicationPhone(passengerCommunicationPhones: InsightPassengerCommunicationPhoneModel[]) {
        let communicationPhoneViews = this.communicationPhoneMapperService.communicationPhoneModelToViews(passengerCommunicationPhones);
        this.communicationPhoneComponent.fillModelToForm(communicationPhoneViews);
    }    

    private fillInsightPassengerCommunicationOther(passengerCommunicationOthers: InsightPassengerCommunicationOtherModel[]) {
        let communicationOtherViews = this.communicationOtherMapperService.communicationOtherModelToViews(passengerCommunicationOthers);
        this.communicationOtherComponent.fillModelToForm(communicationOtherViews);
    }

    private fillInsightPassengerDocument(passengerDocuments: InsightPassengerDocumentModel[]) {
        let documentViews = this.documentMapperService.documentModelToViews(passengerDocuments);
        this.documentComponent.fillModelToForm(documentViews);
    }

    private fillInsightPassengerNationality(passengerNationalities: InsightPassengerNationalityModel[]) {
        let nationalityViews = this.nationalityMapperService.nationalityModelToViews(passengerNationalities);
        this.nationalityComponent.fillModelToForm(nationalityViews);
    }

    private fillInsightPassengerLanguage(passengerLanguages: InsightPassengerLanguageModel[]) {
        let languageViews = this.languageMapperService.languageModelToViews(passengerLanguages);
        this.languageComponent.fillModelToForm(languageViews);
    }    

    private fillInsightPassengerComment(passengerComments: InsightPassengerCommentModel[]) {
        let commentViews = this.commentMapperService.commentModelToViews(passengerComments);
        this.commentComponent.fillModelToForm(commentViews);
    }

    private fillInsightPassengerMembership(passengerMemberships: InsightPassengerMembershipModel[]) {
        let membershipViews = this.membershipMapperService.membershipModelToViews(passengerMemberships);
        this.membershipComponent.fillModelToForm(membershipViews);
    }

    private fillInsightPassengerAttribute(passengerAttributes: InsightPassengerAttributeModel[]) {
        let attributeViews = this.attributeMapperService.insightModelToAttributeRowViews(passengerAttributes);
        setTimeout(() => {
            this.attributeComponent.fillModelToFormWithDomainAttribute(attributeViews, this.domainAttribute$.value)
        }, 100);
        
    }

    private fillInsightPassengerProfile(passenger: InsightPassengerModel) {
        let profileViews = this.profileMapperService.insightModelToProfileRowViews(passenger);
        this.profileComponent.fillModelToForm(profileViews);
    }

    private fillInsightMedia(passengerMedias: MediaViewModel[]) {
        let mediaViews = this.mediaMapperService.mediaToTextViews(passengerMedias);
        this.mediaComponent.fillModelToForm(mediaViews);
    }

    getMedia(ENTITY_NAME: string, userAccountId: string) {
        this.mediaService.getByEntityNameAndAssociation(ENTITY_NAME, userAccountId)
            .subscribe(
                (response: MediaViewModel[]) => {
                    let medias = response;
                    if (medias) {
                        for (var media of medias) {
                            this.getMediaFileAssociation(ENTITY_NAME, userAccountId, media);
                        }
                        this.profilePicture$.next(medias.filter(item => item.mediaUses.length == 0));
                    }
                }
            )
    }

    getMediaFileAssociation(ENTITY_NAME: string, userAccountId: string, media: MediaViewModel) {
        this.mediaFileAssociationService.getByEntityNameAndAssociation(ENTITY_NAME, userAccountId)
            .subscribe(
                (response: MediaFileAssociationViewModel[]) => {
                    media.mediaFileAssociations = response;
                }
            )
    }

    getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    getGenderReferences() {
        this.genderReferenceService.getGenderReferences()
            .subscribe(
                (response) => {
                    this.genderReference$.next(response);
                }
            )
    }

    getUsageTypeReferences() {
        const totalTemplateSecs = this.security.filter(x => x.securityCode == this.TEMPLATE_SECURITY)?.length
        this.usageTypeReferenceService.getUsageTypeReferences()
            .subscribe(
                (response) => {
                    this.setUsageTypeReferenceOnSecurityCode(response, totalTemplateSecs);               
                }
            )
    }

    private setUsageTypeReferenceOnSecurityCode(response: UsageTypeReferenceModel[], totalTemplateSecs: number) {
        response = response.filter(x => x.usageTypeCode == this.DATA_TYPE_CODE || x.usageTypeCode == this.TEMPLATE_TYPE_CODE)

        if (!totalTemplateSecs && (this.copy || this.newInsightPassenger)) {
            response = response.filter(x => x.usageTypeCode != this.TEMPLATE_TYPE_CODE);
        }

        this.usageTypeReference$.next(response);
    }   

    getLanguageReferences() {
        this.languageReferenceService.getLanguageReferences()
            .subscribe(
                (response) => {
                    this.languageReference$.next(response);
                }
            )
    }

    private getTitleReference() {
        this.individualTitleService.getIndividualTitle()
            .subscribe(
                (responses: any) => {
                    this.individualTitleReference$.next(responses);
                }
            )
    }

    getIndividualAgeGroupReferences() {
        this.individualAgeGroupService.getIndividualAgeGroupReferences()
            .subscribe(
                (response) => {
                    this.individualAgeGroupReference$.next(response);
                    if(response){
                        this.insightGeneralDetailComonent.formSetup();
                        this.changeDetectorRef.detectChanges();
                    }
                }
            )
    }

    getAddressTypeReferences() {
        this.addressTypeService.getAddressTypeReferences()
            .subscribe(
                (response) => {
                    this.addressTypeReferences$.next(response);
                }
            )
    }

    getCountryReferences() {
        this.countryService.getCountryReference()
            .subscribe(
                (response) => {
                    this.countryReferences$.next(response);
                }
            )
    }

    getCommunicationPhoneTypeReferences() {
        this.comunicationPhoneTypeService.getCommunicationPhoneTypeReferences()
            .subscribe(
                (response) => {
                    this.communicationPhoneTypeReference$.next(response);
                }
            )
    }

    getCommunicationOtherTypeReferences() {
        this.comunicationOtherTypeService.getCommunicationOtherTypeReferences()
            .subscribe(
                (response) => {
                    this.communicationOtherTypeReference$.next(response);
                }
            )
    }

    getIdentityDocumentReferences() {
        this.identityDocumentTypeService.getIdentityDocumentReferences()
            .subscribe(
                (response) => {
                    this.identityDocumentTypeReference$.next(response);
                }
            )
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference$.next(responses);
                }
            )
    }

    private getMediaUseReference() {
        this.mediaUseReferenceService.getAll()
            .subscribe(
                (responses: MediaUseReferenceModel[]) => {
                    this.mediaUseReference$.next(responses);
                }
            )
    }

    private getMediaHashTag() {
        this.mediaHashTagService.getAll()
            .subscribe(
                (responses: MediaHashTagModel[]) => {
                    this.mediaHashTagSelect2Data = this.mapperService.mediaHashTagToSelect2Data(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }

    private getCommentTypeReferences() {
        this.commentTypeReferenceService.getCommentTypeReferences().subscribe(
            (responses: CommentTypeReferenceModel[]) => {
                this.commentTypeReference$.next(responses);
            }
        );
    }

    private getCommentClassificationReferences() {
        this.commentClassificationReferenceService.getCommentClassificationReferences().subscribe(
            (responses: CommentClassificationReferenceModel[]) => {
                this.commentClassificationReference$.next(responses);
            }
        );
    }

    private getMembershipTypeReferences() {
        this.membershipTypeReferenceService.getMembershipTypeReferences().subscribe(
            (responses: MembershipTypeReferenceModel[]) => {
                this.membershipTypeReference$.next(responses);
            }
        );
    }

    private getOrganisationAirlines() {
        this.orgnisationAirlineService.searchOrganisation("", "", "airline", "").subscribe(
            (responses: OrganisationModel[]) => {
                let distinctResponses = responses.filter((item, index, arr) => arr.findIndex(i => i.organisationId == item.organisationId) == index)
                this.organisationAirline$.next(distinctResponses);
            }
        );
    }    

    private getDomainAttributes() {
        this.domainAttributeService.getIndividualAttributes('', '')
        .subscribe(
            (response: DomainAttributeModel[]) => {
                this.domainAttribute$.next(response);
            }
        )
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.cancel:
                this.cancelRequest();
                break;
            case ACTION_STATUS.save:
                this.save();
                break;
            case ACTION_STATUS.new:
                this.onNew();
                break;
            case ACTION_STATUS.copy:
                this.onCopy();
                break;
            case ACTION_STATUS.refresh:
                this.onRefresh();
                break;
            default:
                break;
        }
    }

    back() {
        const queryParams = {
            selectedTab: 'SEARCH',
            searchFilter: this.searchFilter
        }

        this.navigateService.navigate("main/insight/home", null, false, queryParams);
    }

    cancelRequest() {
        const queryParams = {
            selectedTab: 'CATEGORIES'
        }

        this.navigateService.navigate("main/insight/home", null, false, queryParams);
    }

    async save() {
        if (this.validateAll()) {
            let model = this.insightGeneralDetailComonent.getValue();
            if (model) {
                this.alertBarService.hide();
                this.fillEditCommandModel(model);
                if (!this.individualId || this.copy) {
                    this.addPassenger(model);
                }
                else {
                    if (this.changeStatusDeletedToAny(model.statusCode)) {
                        await this.validateEmailAndEditData(model);
                    } else {
                        this.editPassenger(model);
                    }
                }
            }
        }
        else {
            this.displayAlertBar();
        }
    }

    private validateAll(): boolean {
        if (this.addressComponent.validateForm() 
            && this.validateAddressPrimaryIsSelected()
            && this.communicationPhoneComponent.validateForm() 
            && this.validateCommunicationPhonePrimaryIsSelected()
            && this.communicationOtherComponent.validateForm() 
            && this.validateCommunicationOtherPrimaryIsSelected()
            && this.nationalityComponent.validateForm() 
            && this.validateNationalityPrimaryIsSelected()
            && this.languageComponent.validateForm() 
            && this.validateLanguagePrimaryIsSelected()
            && this.nameComponent.validateForm()
            && this.validateNamePrimaryIsSelected()
            && this.documentComponent.validateForm()
            && this.validateDocumentPrimaryIsSelected()
            && this.validateMembershipPrimaryIsSelected()
            && this.commentComponent.validateForm()
            && this.membershipComponent.validateForm()
            && this.attributeComponent.validateRequired()
            && this.mediaComponent.validateMediaCompletedAll()) {
                return true;
        }

        return false;
    }

    private displayAlertBar() {
        this.onActiveIdChange(this.generalTabId);

        if (!this.nameComponent.validateForm()) {
            this.alertBarService.show(this.namePanel, this.nameComponent.getErrorMessageForm())
            this.panelFocus = this.nameTabId;
        } else if (!this.addressComponent.validateForm()) {
            this.alertBarService.show(this.addressPanel, this.addressComponent.getErrorMessageForm());
            this.panelFocus = this.addressTabId;
        } else if (!this.communicationPhoneComponent.validateForm()) {
            this.alertBarService.show(this.communicationPhonePanel, this.communicationPhoneComponent.getErrorMessageForm());
            this.panelFocus = this.phoneTabId;
        } else if (!this.communicationOtherComponent.validateForm()) {
            this.alertBarService.show(this.communicationOtherPanel, this.communicationOtherComponent.getErrorMessageForm());
            this.panelFocus = this.communicationOtherTabId;
        } else if (!this.nationalityComponent.validateForm()) {
            this.alertBarService.show(this.nationalityPanel, this.nationalityComponent.getErrorMessageForm());
            this.panelFocus = this.nationalityTabId;
        } else if (!this.languageComponent.validateForm()) {
            this.alertBarService.show(this.languagePanel, this.languageComponent.getErrorMessageForm());
            this.panelFocus = this.languageTabId;
        } else if (!this.commentComponent.validateForm()) {
            this.alertBarService.show(this.commentPanel, this.commentComponent.getErrorMessageForm());
            this.panelFocus = this.commentTabId;
        } else if (!this.membershipComponent.validateForm()) {
            this.alertBarService.show(this.membershipPanel, this.membershipComponent.getErrorMessageForm());
            this.panelFocus = this.membershipTabId;
        } else if (!this.attributeComponent.validateRequired()) {
            this.alertBarService.show(this.attributePanel, this.attributeComponent.getErrorMessage());
            this.panelFocus = this.attributeTabId;
        } else if (!this.validateAddressPrimaryIsSelected()) {
            this.alertBarService.show(this.addressPanel, this.ERROR_PRIMARY_ADDRESS_REQUIRED);
            this.panelFocus = this.addressTabId;
        } else if (!this.validateCommunicationPhonePrimaryIsSelected()) {
            this.alertBarService.show(this.communicationPhonePanel, this.ERROR_PRIMARY_COMMU_PHONE_REQUIRED);
            this.panelFocus = this.phoneTabId;
        } else if (!this.validateCommunicationOtherPrimaryIsSelected()) {
            this.alertBarService.show(this.communicationOtherPanel, this.ERROR_PRIMARY_COMMU_OTHER_REQUIRED);
            this.panelFocus = this.communicationOtherTabId;
        } else if (!this.validateNationalityPrimaryIsSelected()) {
            this.alertBarService.show(this.nationalityPanel, this.ERROR_PRIMARY_NATIONALITY_REQUIRED);
            this.panelFocus = this.nationalityTabId;
        } else if (!this.validateLanguagePrimaryIsSelected()) {
            this.alertBarService.show(this.languagePanel, this.ERROR_PRIMARY_LANGUAGE_REQUIRED);
            this.panelFocus = this.languageTabId;
        } else if (!this.validateMembershipPrimaryIsSelected()) {
            this.alertBarService.show(this.membershipPanel, this.ERROR_PRIMARY_MEMBERSHIP_REQUIRED);
            this.panelFocus = this.membershipTabId;
        } else if (!this.validateNamePrimaryIsSelected()) {
            this.alertBarService.show(this.namePanel, this.ERROR_PRIMARY_NAME_REQUIRED);
            this.panelFocus = this.nameTabId;
        } else if (!this.documentComponent.validateForm()) {
            this.alertBarService.show(this.documentPanel, this.documentComponent.getErrorMessageForm())
            this.panelFocus = this.documentTabId;
        } else if (!this.validateDocumentPrimaryIsSelected()) {
            this.alertBarService.show(this.documentPanel, this.ERROR_PRIMARY_DOCUMENT_REQUIRED)
            this.panelFocus = this.documentTabId;
        } else if (!this.mediaComponent.validateMediaCompletedAll()) {
            this.alertBarService.show(this.mediaPanel, this.mediaComponent.getErrorMessage())
            this.panelFocus = this.mediaTabId;
        } 
        else {
            this.alertBarService.hide();
            this.panelFocus = this.generalTabId;
        }

        this.onActiveIdChange(this.panelFocus);
    }

    addressPanelError(errorMessage: string) {
        if (errorMessage) {
            this.alertBarService.show(this.addressPanel, errorMessage);
        }
    }

    private validateAddressPrimaryIsSelected() : boolean {
        let addresses = this.addressComponent.addresses;

        if (!addresses?.length) {
            return true;
        }

        if (addresses.filter(x => x.primaryAddress).length) {
            return true;
        }

        return false;
    }

    private validateCommunicationPhonePrimaryIsSelected() : boolean {
        let communicationPhones = this.communicationPhoneComponent.communicationPhones;

        if (!communicationPhones?.length) {
            return true;
        }

        if (communicationPhones.filter(x => x.primaryPhone).length) {
            return true;
        }

        return false;
    }

    private validateCommunicationOtherPrimaryIsSelected() : boolean {
        let communicationOthers = this.communicationOtherComponent.communicationOthers;

        if (!communicationOthers?.length) {
            return true;
        }

        if (communicationOthers.filter(x => x.primaryCommunication).length) {
            return true;
        }

        return false;
    }

    private validateNationalityPrimaryIsSelected() : boolean {
        let nationalities = this.nationalityComponent.nationalities;

        if (!nationalities?.length) {
            return true;
        }

        if (nationalities.filter(x => x.primaryFlag).length) {
            return true;
        }

        return false;
    }

   private validateLanguagePrimaryIsSelected() : boolean {
        let languages = this.languageComponent.languages;

        if (!languages?.length) {
            return true;
        }

        if (languages.filter(x => x.primaryFlag).length) {
            return true;
        }

        return false;
   }

   private validateMembershipPrimaryIsSelected() : boolean {
        let memberships = this.membershipComponent.memberships;

        if (!memberships?.length) {
            return true;
        }

        if (memberships.filter(x => x.primaryFlag).length) {
            return true;
        }

        return false;
   }
   
    private validateNamePrimaryIsSelected(): boolean {
        let names = this.nameComponent.names;

        if (!names?.length) {
            return true;
        }

        if (names.filter(name => name.primaryFlag).length) {
            return true;
        }

        return false;
    }

    private validateDocumentPrimaryIsSelected(): boolean {
        let docs = this.documentComponent.documents;

        if (!docs?.length) {
            return true;
        }

        if (docs.filter(doc => doc.primaryFlag).length) {
            return true;
        }

        return false;
    }

    private fillEditCommandModel(model: InsightPassengerCommandModel): InsightPassengerCommandModel {
        model.individualId = this.individualId;
        model.insightPassengerNames = this.setInsightPassengerNames(this.nameComponent.names);
        model.insightPassengerAddresses = this.setInsightPassengerAddresses(this.addressComponent.addresses);
        model.insightPassengerCommunicationPhones = this.setInsightPassengerCommunicationPhones(this.communicationPhoneComponent.communicationPhones);
        model.insightPassengerCommunicationOthers = this.setInsightPassengerCommunicationOthers(this.communicationOtherComponent.communicationOthers);
        model.insightPassengerDocuments = this.setInsightPassengerDocuments(this.documentComponent.documents, this.nameComponent.names);
        model.insightPassengerNationalities = this.setInsightPassengerNationalities(this.nationalityComponent.nationalities);
        model.insightPassengerLanguages = this.setInsightPassengerLanguages(this.languageComponent.languages);
        model.insightPassengerComments = this.setInsightPassengerComments(this.commentComponent.comments);
        model.insightPassengerMemberships = this.setInsightPassengerMemberships(this.membershipComponent.memberships);
        model.medias = this.mediaMapperService.mediaToMediaModels(null, this.mediaComponent.mediaFiles, this.ENTITY_NAME, this.individualId);

        this.setIndividualAgeGroupIdIfExist(model);

        let profilePicture = this.insightGeneralDetailComonent.getMediaValues();
        if (profilePicture) {
            model.medias.push(...profilePicture.mediaCommand);
        }    

        this.profileMapperService.profileRowViewsToProfileModels(this.profileComponent.getInsightPassengerProfile(), model);
        this.attributeMapperService.attributeRowViewsToAttributeModels(this.attributeComponent.getInsightPassengerAttribute(), model)
        
        return model;
    }

    private setIndividualAgeGroupIdIfExist(model: InsightPassengerCommandModel) {
        let individualAgeGroups = model.insightPassengerAgeGroups;

        if(!individualAgeGroups?.length){
            return;
        }

        if (this.insightPassenger$.value?.insightPassengerAgeGroups?.length){
            individualAgeGroups[0].individualAgeGroupId = this.insightPassenger$.value.insightPassengerAgeGroups[0].individualAgeGroupId;
            individualAgeGroups[0].individualId = this.individualId;
        }
    }

    private setInsightPassengerNames(names: NameView[]) : InsightPassengerNameModel[] {
        if (!names?.length) {
            return null;           
        }

        let passengerNames: InsightPassengerNameModel[] = new Array();
        passengerNames = this.nameMapperService.nameViewToModels(names);

        return passengerNames;
    }

    private setInsightPassengerAddresses(addresses: AddressView[]) : InsightPassengerAddressModel[] {
        if (!addresses?.length) {
            return null;           
        }

        let passengerAddresses: InsightPassengerAddressModel[] = new Array();
        passengerAddresses = this.addressMapperService.addressViewToModels(addresses);

        return passengerAddresses;
    }

    private setInsightPassengerCommunicationPhones(communicationPhones: CommunicationPhoneView[]) : InsightPassengerCommunicationPhoneModel[] {
        if (!communicationPhones?.length) {
            return null;           
        }

        let passengerCommunicationPhones: InsightPassengerCommunicationPhoneModel[] = new Array();
        passengerCommunicationPhones = this.communicationPhoneMapperService.communicationPhoneViewToModels(communicationPhones);

        return passengerCommunicationPhones;
    }

    private setInsightPassengerCommunicationOthers(communicationOthers: CommunicationOtherView[]) : InsightPassengerCommunicationOtherModel[] {
        if (!communicationOthers?.length) {
            return null;           
        }

        let passengerCommunicationOthers: InsightPassengerCommunicationOtherModel[] = new Array();
        passengerCommunicationOthers = this.communicationOtherMapperService.communicationOtherViewToModels(communicationOthers);

        return passengerCommunicationOthers;
    }

    private setInsightPassengerDocuments(documents: DocumentView[], names: NameView[]) : InsightPassengerDocumentModel[] {
        if (!documents?.length) {
            return null;           
        }

        let passengerDocuments: InsightPassengerDocumentModel[] = new Array();
        passengerDocuments = this.documentMapperService.documentViewToModels(documents, names);

        return passengerDocuments;
    }

    private setInsightPassengerNationalities(nationalities: NationalityView[]) : InsightPassengerNationalityModel[] {
        if (!nationalities?.length) {
            return null;           
        }

        let passengeNationalities: InsightPassengerNationalityModel[] = new Array();
        passengeNationalities = this.nationalityMapperService.nationalityViewToModels(nationalities);

        return passengeNationalities;
    }

    private setInsightPassengerLanguages(languages: LanguageView[]) : InsightPassengerLanguageModel[] {
        if (!languages?.length) {
            return null;           
        }

        let passengeLanguages: InsightPassengerLanguageModel[] = new Array();
        passengeLanguages = this.languageMapperService.languageViewToModels(languages);

        return passengeLanguages;
    }

    private setInsightPassengerComments(comments: CommentView[]) : InsightPassengerCommentModel[] {
        if (!comments?.length) {
            return null;           
        }

        let passengeComments: InsightPassengerCommentModel[] = new Array();
        passengeComments = this.commentMapperService.commentViewToModels(comments);

        return passengeComments;
    }

    private setInsightPassengerMemberships(memberships: MembershipView[]) : InsightPassengerMembershipModel[] {
        if (!memberships?.length) {
            return null;           
        }

        let passengeMemberships: InsightPassengerMembershipModel[] = new Array();
        passengeMemberships = this.membershipMapperService.membershipViewToModels(memberships);

        return passengeMemberships;
    }

    addPassenger(command: InsightPassengerCommandModel) {
        this.spinnerService.show();
        this.insightPassengerService.addInsightPassenger(command)
            .subscribe (
                (response) => {
                    this.individualId = response.individualId;
                    this.copy = false;
                    this.getInsightPassenger(response.individualId);
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }
            )       
    }

    private saveMedia(mediaCommand) {
        this.mediaService.save(mediaCommand)
            .subscribe(
                () => {
                    this.spinnerService.saveComplete();
                }
            ),
            () => {
                this.disabledSave = false;
                this.spinnerService.hide();
                this.changeDetectionRef.detectChanges();
            }
    }

    editPassenger(command: InsightPassengerCommandModel) {
        this.spinnerService.showSaving();
        this.activityDomainComponent.save();
        this.insightPassengerService.editInsightPassenger(this.individualId, command)
            .subscribe(
                (response) => {
                    this.copy = false;
                    this.getInsightPassenger(response.individualId);
                    this.spinnerService.saveComplete();
                 },
                () => {
                    this.spinnerService.hide();
                }
            );          
    }

    private editMedia() {
        let mediaCommand = this.insightGeneralDetailComonent.getMediaValues();
        if (mediaCommand) {
            this.saveMedia(mediaCommand);
        }
        else {
            this.deleteMedia(this.individualId);
        }
    }

    private deleteMedia(individualId) {
        this.mediaFileAssociationService.deleteByEntityNameAndAssociation(this.ENTITY_NAME, individualId)
            .subscribe(
                () => {
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.disabledSave = false;
                    this.spinnerService.hide();
                    this.changeDetectionRef.detectChanges();
                }
            );
    }

    onActiveIdChange(activeId: string) {
        this.summaryTabClicked = false;

        if (activeId == this.generalTabId) {
            this.focusingService.focus(this.insightGeneralDetailComonent.focusingDirective)
        }
        if (activeId == this.nameTabId) {
            this.focusingService.focus(this.nameComponent.focusingDirective)
        }
        if (activeId == this.addressTabId) {
            this.focusingService.focus(this.addressComponent.focusingDirective)
        }
        if (activeId == this.phoneTabId) {
            this.focusingService.focus(this.communicationPhoneComponent.focusingDirective)
        }
        if (activeId == this.communicationOtherTabId) {
            this.focusingService.focus(this.communicationOtherComponent.focusingDirective)
        }
        if (activeId == this.documentTabId) {
            this.focusingService.focus(this.documentComponent.focusingDirective)
        }
        if (activeId == this.nationalityTabId) {
            this.focusingService.focus(this.nationalityComponent.focusingDirective)
        }
        if (activeId == this.languageTabId) {
            this.focusingService.focus(this.languageComponent.focusingDirective)
        }
        if (activeId == this.commentTabId) {
            this.focusingService.focus(this.commentComponent.focusingDirective)
        }
        if (activeId == this.membershipTabId) {
            this.focusingService.focus(this.membershipComponent.focusingDirective)
        }
        if (activeId == this.mediaTabId) {
            this.focusingService.focus(this.mediaComponent.focusingDirective);
        }    
        if (activeId == this.profileTabId) {
            this.focusingService.focus(this.profileComponent.focusingDirective)
        }
        if (activeId == this.attributeTabId) {
            this.focusingService.focus(this.attributeComponent.focusingDirective)
        }     
        if (activeId == this.salesSummaryTabId) {
            this.summaryTabClicked = true;
        }  
    }

    getInsightPrimaryFullName() {
        let primaryName = this.insightNames.filter(item => item.primaryFlag == true)[0];
        return primaryName?.firstName + ' ' + primaryName?.lastName;
    }

    public addAction() {
        if (this.individualId) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.individualId, this.getInsightPrimaryFullName());
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onNew() {
        this.individualId = null;
        this.newInsightPassenger = true;

        this.domainAttribute$.next(null);
        this.setResponesUsageType(null);     
        this.insightPassenger$.next(null);
        this.useAddressDropdown$.next(null);
        this.profilePicture$.next(new Array());
        this.onNewDetailPanels();
        this.pagingDataView = [];
        this.currentStatusCode = null;

        this.addAction();
        this.activityStore();
    }

    private onNewDetailPanels() {
        this.fillInsightPassengerName([]);
        this.fillInsightPassengerAddress([]);
        this.fillInsightPassengerCommunicationPhone([]);
        this.fillInsightPassengerCommunicationOther([]);
        this.fillInsightPassengerDocument([]);
        this.fillInsightPassengerNationality([]);
        this.fillInsightPassengerLanguage([]);
        this.fillInsightPassengerComment([]);
        this.fillInsightPassengerMembership([]);
        this.profileComponent.clearProfile();
        this.fillInsightPassengerAttribute([]);
        this.fillInsightMedia([]);
    }

    private getMembershipLevel() {
        this.membershipLevelService.getMembershipLevel()
            .subscribe(
                (response: MembershipLevelModel[]) => {
                    this.membershipLevels$.next(response);
                }
            )
    }

    public onCopy() {
        this.copy = true;
        this.getInsightPassenger(this.individualId);
    }

    public onRefresh() {
        if (this.individualId != null) {
            this.getInsightPassenger(this.individualId);
        }
    }

    onPageChange(id: string) {
        this.individualId = null;
        this.pageChanging = true;
        this.profileComponent.clearProfile();
        this.getInsightPassenger(id);
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null);
    }

    onAlertBarClick() {
        this.onActiveIdChange(this.panelFocus);
    }


    private changeStatusDeletedToAny(newStatusCode: string): boolean {
        return (this.currentStatusCode == this.STATUSCODE_DELETED &&
            newStatusCode != this.STATUSCODE_DELETED);
    }

    private async validateEmailAndEditData(model: InsightPassengerCommandModel) {
        this.insightGeneralDetailComonent.displayChangeStatusInvalid(false);
        let communicationEmails = model?.insightPassengerCommunicationOthers?.filter(x => x.communicationOtherTypeCode == "EMAIL");
        if (communicationEmails?.length) {
            let validEmailDuplicate = await this.validateEmails(communicationEmails);
            if (validEmailDuplicate) {
                this.editPassenger(model);
            } else {
                this.displayErrorEmailDuplicateWhenChangeStatus();
            }
        } else {
            this.editPassenger(model);
        }
    }

    private async validateEmails(userOthers: InsightPassengerCommunicationOtherModel[]): Promise<boolean> {
        for (let userOther of userOthers) {
            let duplicate = await this.duplicateEmailDB(userOther);
            if (duplicate) {
                return false;
            }
        }
        return true;
    }

    private async duplicateEmailDB(userOther: InsightPassengerCommunicationOtherModel): Promise<boolean> {
        try {
            await this.userAccountOrganisationService.validateMailAddress(userOther.mailAddress, userOther.communicationOtherId ?? "").toPromise();
            return false;
        } catch (e) {
            return true;
        }
    }

    private displayErrorEmailDuplicateWhenChangeStatus() {
        this.alertBarService.show(this.generalPanel, this.insightGeneralDetailComonent.ERROR_STATUS_CANNOT_CHANGE);
        this.panelFocus = this.generalPanel;
        this.insightGeneralDetailComonent.displayChangeStatusInvalid(true);
    }    
}

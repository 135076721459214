export const SecurityReportItems = [
    {
        name: 'Security Report Name 1',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    },
    {
        name: 'Security Report Name 2',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    },
    {
        name: 'Security Report Name 3',
        modifiedBy: 'Johnny Depp',
        modifiedDateTime: new Date('2019-09-20 13:00:00')
    }
]
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { IdentityDocumentReferenceService } from '../../services/system-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { InsightPassengerService } from '../../services/individul-services';
import { InsightPassengerDocumentModel, InsightPassengerModel } from '../../models/individual-model';

const COUNTRY_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: -1,
    ajax: null
}

@Component({
    selector: 'op-document-type-selection',
    templateUrl: './document-type-selection.component.html'
})
export class DocumentTypeSelectionComponent implements OnInit {

    public _value: string;
    public _isDisable: boolean;
    public documentTypeOption = COUNTRY_OPTION;
    
    public select2Datas$ = new BehaviorSubject<Select2Data[]>([]);
    public loadCompleted$ = new BehaviorSubject<boolean>(false);

    private identityDocumentTypeReferences: Select2Data[];

    private readonly EMPTY_SELECT2_DATA: Select2Data = {
        id: '',
        text: '',
        selected: false,
        disabled: false
    }
    
    @Input()
    set data(value: string) {
        this._value = value;
    }

    @Input()
    set disabled(value: boolean) {
        this._isDisable = value;
    }

    @Input() hasError: boolean = false;
    @Input() individualId: string;

    @Output() dataChange: EventEmitter<string> = new EventEmitter();
    @Output() presetSelected = new EventEmitter<InsightPassengerDocumentModel>();

    constructor(private identityDocumentTypeService: IdentityDocumentReferenceService,
        private insightPassengerService: InsightPassengerService) {
            
    }

    ngOnInit(): void {
        this.fetchData(this.individualId);   
    }

    public fetchData(individualId: string) {
        this.loadCompleted$.next(false);
        forkJoin({
            identityDocumentTypeReferences: this.identityDocumentTypeService.getIdentityDocumentReferences(),
            insightPassenger: individualId ? this.insightPassengerService.getInsightPassenger(individualId) : of<InsightPassengerModel>(null)
        })
            .subscribe(
                ({ identityDocumentTypeReferences, insightPassenger }) => {

                    const identityDocumentTypeReferenceSelect2Datas = identityDocumentTypeReferences
                        ?.map(item => new Select2Data(item.identityDocumentCode, item.identityDocumentName)) || [];

                    const insightPassengerSelect2Datas = insightPassenger?.insightPassengerDocuments?.map(item => ({
                        id: item.individualIdentityDocumentId,
                        text: `${item.identityDocumentName} ${item.identityDocumentNumber}`,
                        selected: false,
                        disabled: false,
                        action: item
                    })) || [];

                    const combinedSelect2Datas = [this.EMPTY_SELECT2_DATA, 
                        ...insightPassengerSelect2Datas, 
                        ...identityDocumentTypeReferenceSelect2Datas];
                        
                    this.select2Datas$.next(combinedSelect2Datas);
                    this.loadCompleted$.next(true);
                }
            )
    }
    
    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    public getSelectedText(): string {
        return this.select2Datas$.value?.find(select2Data => select2Data.id == this._value)?.text;
    }
    
    public onPresetSelected(item: InsightPassengerDocumentModel) {
        this.presetSelected.emit(item);
    }
}
export const select2AttributeInherit = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2ChoiceMultiple = {
    placeholder: "<Attribute>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2Attribute = {
    placeholder: "<Attribute>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true
}

export const select2HashtagOption = {
    placeholder: "<Hashtag>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}
import { AfterViewInit, Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { NavigationService } from '../../utils/navigation';
import { ActivityDomainTabModel } from 'src/app/core/models/activity-model/activity-domain-tab.model';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SecurityGroupCode } from 'src/app/core/constants/security-group-constants';
import { AUTH_TOKEN } from 'src/app/modules/auth/shared/constants/auth.constant';

@Component({
  selector: 'smart-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements AfterViewInit {
  public readonly DOMAINCODE_ACTIVITY: string = "ACTIVITY";
  public readonly DOMAINCODE_PRICERULE: string = "PRICERULE";
  
  private securityGroups: SecurityGroupSecurityModel[] = [];

  public 
  @Input() showControlIcons = true;
  @Input() activityPending: number;
  @Input() domainCode: string;
  @Input() isDetail: boolean = false;
  @Input() currentActivityDetail: ActivityDomainTabModel;

  avatar = APP_CONFIG.avatar;
  email = APP_CONFIG.email;
  user = APP_CONFIG.user;

  constructor(private store: Store<any>,
    private navigationService: NavigationService,
    private activityStoreService: ActivityStoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private securityGroupService: SecurityGroupService) {
  }
  ngAfterViewInit(): void {
    this.loadSecurityGroup();
  }

  openMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: true }));
  }

  public onPlusIconClick(event) {
    event.preventDefault();
    this.navigationService.navigate("/main/shopping/" + Date.now().toString(),
      "Shopping",
      true,
      {
        searchCriteria: null
      });
    return;
  }

  public onNitificationClick(event) {
    event.preventDefault();
    this.handleNotification();
    return;
  }

  private openActivityPage() {
    this.navigationService.navigate('/main/activity/' + + Date.now().toString(), "Activities", true, {
      tab: 'search',
      domain: this.getDomainCode(),
      status: 'PENDING',
      activityPending: this.activityPending,
      category: this.currentActivityDetail?.category,
      group: this.currentActivityDetail?.group
    });
  }

  public handleNotification() {
    if (!this.isDetail && this.activityPending && this.domainCode != this.DOMAINCODE_ACTIVITY) {
      this.openActivityPage();
    } else if (this.isDetail) {
      this.openCloseActivityPanel(true);
    }
  }

  public handleSelectTabNotification() {
    if (this.isDetail) {
      this.openCloseActivityPanel(false);
    }
  }

  private openCloseActivityPanel(changeDisplay: boolean) {
    if (this.currentActivityDetail) {
      if (changeDisplay) {
        this.currentActivityDetail.displayDetail = !this.currentActivityDetail.displayDetail;
      }
      this.activityStoreService.add(
        this.currentActivityDetail.id,
        this.currentActivityDetail.pendingCount,
        this.currentActivityDetail.displayDetail
      );
      this.changeDetectorRef.detectChanges();
    }
  }

  private getDomainCode(): string {
    if (this.domainCode == this.DOMAINCODE_PRICERULE) {
      return this.currentActivityDetail?.domainCode;
    }
    return this.domainCode;
  }

  private loadSecurityGroup() {
    if (!sessionStorage.getItem(AUTH_TOKEN)) {
      return;
    }
    this.securityGroupService.getUserSecurityGroupSecurity()
      .subscribe((response: SecurityGroupSecurityModel[]) => {
        this.securityGroups = response;
        this.changeDetectorRef.detectChanges();
      });
  }

  public showShoppingSecurity(): boolean {
      return this.securityGroups.some(x => x.securityCode === SecurityGroupCode.Shopping);
  }
}

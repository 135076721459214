import { 
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

import { 
    OrderDetailCommentView,
    OrdersMapperService
} from '../../../shared';
import { CommentView } from '../../../../../core/components/comment/shared/comment.view';
import { CommentDetailImageComponent } from 'src/app/core/components/comment/comment-detail-image/comment-detail-image.component';
import { MediaFileView } from 'src/app/core/components/media/shared/media-file.view';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'op-order-detail-comment-detail',
    templateUrl: './order-detail-comment-detail.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailCommentDetailComponent {
    
    @Input("id") id: string;
    @Input("data") set commentsData(data: OrderDetailCommentView) {
        this._data = cloneDeep(data);
        this.view = this.convertToCommentView(data);
    }
    @Input() set files(views: MediaFileView[]) {
        this.mediaFileViews = cloneDeep(views);
    }
    @Input() readonly: boolean = true;

    @Output() apply = new EventEmitter<{ data: OrderDetailCommentView, files: MediaFileView[] }>();
    @Output() close = new EventEmitter<void>();

    @ViewChild("panel") panel: any;
    @ViewChild(CommentDetailImageComponent) commentDetailImageComponent: CommentDetailImageComponent;
    
    public collapsed: boolean;
    public focused: boolean;

    view: CommentView;
    mediaFileViews: MediaFileView[] = [];

    private _data: OrderDetailCommentView;
    private commentView: CommentView;
    
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }
    
    public convertToCommentView(orderCommentView: OrderDetailCommentView): CommentView {
        return {
            no: null,
            commentId: orderCommentView?.commentId,
            individualId: null,
            organisationId: null,
            commentTypeCode: orderCommentView?.commentTypeCode,
            commentTypeName: orderCommentView?.commentTypeName,
            commentClassificationCode: orderCommentView?.commentClassificationCode,
            commentClassificationName: orderCommentView?.commentClassificationName,
            commentText: orderCommentView?.commentText,
            commitBy: orderCommentView?.commentByName,
            commitDateTime: orderCommentView?.commentDateTime,
            privateCommentFlag: null,
            warningCommentFlag: null,
            lockedCommentFlag: null,
            commentLocked: null,
        };
    }

    private convertToOrderDetailCommentView(commentView: CommentView): OrderDetailCommentView {
        return {
            orderCommentId: this._data?.orderCommentId,
            commentTypeCode: commentView?.commentTypeCode,
            commentTypeName: commentView?.commentTypeName,
            commentClassificationCode: commentView?.commentClassificationCode,
            commentClassificationName: commentView?.commentClassificationName,
            commentText: commentView?.commentText,
            commentDate: this._data?.commentDate,
            commentDateTime: this._data?.commentDateTime,
            commentByName: this._data?.commentByName,
            showDetail: true,
            parentOrderId: this._data?.parentOrderId,
            individualId: this._data?.individualId,
            orderId: this._data?.orderId,
            commentId: commentView?.commentId,
        }
    }

    onUndoClick() {
        this.resetInputFields();
    }
    onApplyClick() {
        if (!this.commentDetailImageComponent.isValidForm()) return;
        this.commentView = this.commentDetailImageComponent.getCommentsData();
        this.mediaFileViews = this.commentDetailImageComponent.getCommentsFiledata();

        if (!this.commentView.commentTypeCode) return;

        this.apply.emit({
            data: this.convertToOrderDetailCommentView(this.commentView),
            files: cloneDeep(this.mediaFileViews)
        });
    }
    onClearClick() {
        this.resetInputFields();
    }
    onCloseClick() {
        this.close.emit();
    }

    private resetInputFields() {
        this.view = this.getEmptyView();
        this.mediaFileViews = [];
    }

    private getEmptyView(): CommentView {
        return {
            no: null,
            commentId: null,
            individualId: null,
            organisationId: null,
            commentTypeCode: null,
            commentTypeName: null,
            commentClassificationCode: null,
            commentClassificationName: null,
            commentText: null,
            commitBy: null,
            commitDateTime: null,
            privateCommentFlag: null,
            warningCommentFlag: null,
            lockedCommentFlag: null,
            commentLocked: null,
        };
    }

    public collapsePanel(collapsed: boolean) {
        this.collapsed = collapsed;
    }
}
import { BehaviorSubject } from "rxjs";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleIndividualView extends PriceRuleConditionBase {
    public type: string;
    public individualId: string;
    public genderCode: string[];
    public languageCode: string[];
    public nationalityCode: string[];
    public individualRoleCode: string[];
    public individualAgeGroupCode: string[];
    public individualCivilStatusTypeCode: string[];
    public individualSocialTypeCode: string[];
    public individualProfessionTypeCode: string[];
    public individualPositionTypeCode: string[];
    public individualInterestTypeCode: string[];
    public individualPreferenceTypeCode: string[];
    public individualAttributeTypeCode: string[];
    public individualAttributeChoiceId: string[];
    public preSelectedDataList$ = new BehaviorSubject<Select2Data[]>(null);
}
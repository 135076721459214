import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { AttributeTreeNode } from 'src/app/core/models/reference-model/reference-general-model/attribute-tree-node.model';
import { AttributeTree } from 'src/app/core/models/reference-model/reference-general-model/attribute-tree.model';
import { AttributeMaintenanceService } from 'src/app/core/services/system-services/attribute-maintenance.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { DomainAttributeView } from '../../shared/view/domain-attribute.view';
import { SingleOrMultiple } from 'devextreme/common';
import { TreeViewCheckBoxMode } from 'devextreme/ui/tree_view';

@Component({
    selector: 'op-attribute-assignment',
    templateUrl: './attribute-assignment.component.html'
})
export class AttributeAssignmentComponent implements OnChanges {
    @Input() heightPanelGeneral: number;
    @Input() attributeCode: string;
    @Input() attributeName: string = '[new]';
    @Input() newAttributeFlag: boolean = true;
    @Input() attributeTree: AttributeTreeNode[] = [];
    @Output() getNewAttributeDetail = new EventEmitter<AttributeTreeNode>();
    @Output() clickedItem = new EventEmitter<AttributeTreeNode>();
    @Output() onUnmapDomain = new EventEmitter();
    @Output() onClickHeader = new EventEmitter();

    @ViewChild('treeBody', { static: false }) treeView: DxTreeViewComponent;
    @ViewChild('treeHeader', { static: false }) treeHeader: DxTreeViewComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public selectedDomainAttribute: AttributeTreeNode[] = [];
    public selectNodesRecursive: boolean = true;
    public selectByClick: boolean = false;
    public showCheckBoxesMode: TreeViewCheckBoxMode = 'normal';
    public selectionMode: SingleOrMultiple = 'multiple'
    public focused: boolean;
    public attributeNameDisplay = [];
    private firstNodeSelected = false;
    private firstGetSelection = true;
    private changedNode: AttributeTreeNode;
    private unmapDomainList: string[] = [];
    private unmapDomainTypeList: AttributeTreeNode[] = [];
    private prevSelectedNodeLength: number = 0;
    private firstItemSelected: boolean = false;
    private originalSelected: AttributeTreeNode[] = [];    
    private attributeNameNodeId = 1;
    private nonChildDomain: string[] = [];

    constructor(private attributeMaintenanceService: AttributeMaintenanceService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['attributeName']) {
            this.attributeNameDisplay[0] = !this.attributeName ? '[new]' : this.attributeName;
        }
    }

    public selectSelectedTreeNodes(trees: AttributeTree) {
        if (this.attributeCode != null) {
            this.nonChildDomain = this.getNonChildDomain(trees);
            this.loadSelectedDomainAttribute(trees)
        }
    }

    private loadSelectedDomainAttribute(trees: AttributeTree) {
        this.firstNodeSelected = false;
        this.attributeMaintenanceService.getDomainTypeAttributeByAttributeType(this.attributeCode)
        .subscribe(
            domainAttributeRes => {
                this.prevSelectedNodeLength = domainAttributeRes.length;
                trees.children?.forEach(item => {
                    var filteredDomainAttr = domainAttributeRes.filter(node => node.domainCode == item.code);
                    filteredDomainAttr = this.filterNonChildDomain(item.code, filteredDomainAttr);
                    for (let filteredNode of filteredDomainAttr) {
                        if (Boolean(filteredNode.domainTypeCode) === false) {
                            filteredNode.domainTypeCode = filteredNode.domainCode;
                        }
                    }
                    if (filteredDomainAttr.length > 0) {
                        this.findSelectedDomainAttribute(item, item.code, filteredDomainAttr);
                    }
                })
                this.firstGetSelection = false;
                this.onUnmapDomain.emit();
            });
        
    }

    private findSelectedDomainAttribute(item: AttributeTreeNode, domainCode: string, selectedItem: DomainAttributeView[]) {
        if (this.checkIfCanBeSelected(item, domainCode, selectedItem)) {
            this.treeView.instance.selectItem(item);
            this.originalSelected.push(item);
            return item;
        }

        item.children.forEach(node => {
            this.findSelectedDomainAttribute(node, domainCode, selectedItem);
        })
    }

    checkIfCanBeSelected(item: AttributeTreeNode, domainCode: string, selectedItem: DomainAttributeView[]): boolean {
        if (selectedItem.find(node => node.domainTypeCode == item.code && item.children.length === 0) != null) {
            return true;
        } else if (selectedItem.find(node => node.domainCode == item.code && node.domainTypeCode == node.domainCode)) {
            return true;
        }
        return false;
    }

    getChangedNode(e) {
        if (e.itemData != null) {
            this.changedNode = e.itemData;
            this.syncSelection(e.component);
        }       
    }

    treeViewContentReady(e) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView) {
        if (this.firstNodeSelected === false && this.newAttributeFlag === false) {
            this.firstNodeSelected = true;
            this.focusTreeHeader();
        }
        if (this.changedNode != null) {
            this.unfocusHeaderNode()
            const selectedAttributes = treeView.getSelectedNodes()
                .map(node => node.itemData);
            
            this.selectedDomainAttribute = selectedAttributes;
            let currentSelectedNodeLength = this.selectedDomainAttribute.length;
            this.manageChangedNode(this.changedNode, currentSelectedNodeLength)

            this.prevSelectedNodeLength = currentSelectedNodeLength;
        }        
    }

    manageChangedNode(changedNode: AttributeTreeNode, currentSelectedNodeLength: number) {
        if (this.firstGetSelection == false) {
            if (this.prevSelectedNodeLength <= currentSelectedNodeLength) {
                this.loadNewAttributeDetail(changedNode);
                this.updateUnmapList(changedNode);
            }
            else {
                this.onUnmapDomain.emit();
                this.unMapDomain(changedNode);
            }
        }
        
    }

    focusTreeHeader() {
        this.treeHeader.instance.focus();
        let attributeNameNode = $("dx-tree-view#treeHeader").find("[data-item-id=" + this.attributeNameNodeId + "]");
        this.treeHeader.instance.selectAll();
        attributeNameNode.addClass("dx-state-focused");
    }

    unfocusHeaderNode() {
        this.treeHeader.instance.unselectAll();
    }

    checkChangeNodeAlreadyExist() {
        return this.selectedDomainAttribute.findIndex(item => item.code == this.changedNode.code && item.domainCode == this.changedNode.domainCode) !== -1;
    }

    itemClick(e) {
        this.clickedItem.emit(e.itemData);
        this.unfocusHeaderNode();
        if (this.firstItemSelected === true) {
            this.firstItemSelected = false;
        } 
    }

    unMapDomain(node: AttributeTreeNode) {
        if (this.checkIfMatchUnmapCondition(node)) {
            this.addUnMapDomain(node);
        } else {
            node.children.forEach(item => {
                this.unMapDomain(item);
            })
        }
    }

    checkIfMatchUnmapCondition(node: AttributeTreeNode) {
        return node !== null && (node.code == node.domainCode || node.children.length === 0)
    }

    addUnMapDomain(node: AttributeTreeNode) {
        if (node.code == node.domainCode) {
            this.addUnMapDomainList(node);
        } else if (node.children.length === 0) {
            this.addUnMapDomainTypeList(node);
        }
    }

    addUnMapDomainList(node: AttributeTreeNode) {
        if (this.unmapDomainList.indexOf(node.domainCode) === -1) {
            this.unmapDomainList.push(node.domainCode);
        }  
    }

    addUnMapDomainTypeList(node: AttributeTreeNode) {
        if (this.checkDomainExistanceInSelectedDomainAttribute(node) === false) {
            this.addUnMapDomainList(node);
        }
        if (this.checkDomainTypeExistanceInUnmapList(node) === false) {
            this.unmapDomainTypeList.push(node);
        }
    }

    checkDomainExistanceInSelectedDomainAttribute(node: AttributeTreeNode) {
        return this.selectedDomainAttribute.findIndex(item => item.domainCode == node.domainCode) !== -1;
    }

    checkDomainTypeExistanceInUnmapList(node: AttributeTreeNode) {
        return this.unmapDomainTypeList.findIndex(item => item.code == node.code && item.domainCode == item.domainCode) !== -1
    }

    updateUnmapList(node: AttributeTreeNode) {
        if (node.code == node.domainCode || node.children.length === 0) {
            this.removeUnmapDomain(node.domainCode);
            this.removeUnmapDomainType(node);
        } else {
            node.children.forEach(item => {
                this.updateUnmapList(item);
            })
        }
    }

    removeUnmapDomain(domainCode: string) {
        let index = this.unmapDomainList.indexOf(domainCode);
        if (index !== -1) {
            this.unmapDomainList.splice(index, 1);
        }
    }

    removeUnmapDomainType(node: AttributeTreeNode) {
        if (node.code == node.domainCode) {
            let unmapDomainTypeListTemp = this.unmapDomainTypeList.filter(item => item.domainCode != node.domainCode);
            this.unmapDomainTypeList = unmapDomainTypeListTemp;
        } else {
            let index = this.unmapDomainTypeList.findIndex(item => 
                item.code == node.code && item.domainCode == node.domainCode);
            if (index !== -1) {
                this.unmapDomainTypeList.splice(index, 1);
            }
        }
    }

    public getUnmapDomainList(): string[] {
        return this.unmapDomainList;
    }

    public getUnmapDomainTypeList(): AttributeTreeNode[] {
        return this.unmapDomainTypeList;
    }

    loadNewAttributeDetail(node: AttributeTreeNode) {
        this.getNewAttributeDetail.emit(node);
    }

    clearSelected() {
        this.treeView.instance.unselectAll();
    }

    clearPendingData() {
        this.unmapDomainList = [];
        this.unmapDomainTypeList = [];
        this.newAttributeFlag = false;
    }

    treeHeaderClick() {
        this.onClickHeader.emit();
    }

    selectTreeHeader() {
        this.treeHeaderClick();
        this.focusTreeHeader();
    }

    unselectChangedNode() {
        this.treeView.instance.unselectItem(this.changedNode);
    }

    filterNonChildDomain(domainCode: string, domainAttributes: DomainAttributeView[]) {
        if (this.nonChildDomain.includes(domainCode) == true) {
            return domainAttributes
        }
        return domainAttributes.filter(node => node.domainCode != node.domainTypeCode && node.domainTypeCode != '');
    }

    getNonChildDomain(trees: AttributeTree) {
        return trees.children.filter(item => item.children.length == 0).map(item => item.domainCode);
    }
}
import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { StatusReferenceModel } from '../../models/reference-model/reference-general-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/system/StatusReferences"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getAll(): Observable<StatusReferenceModel[]> {
    return this.httpGet<StatusReferenceModel[]>(this.REFERENCE_PATH, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CsvExportService, PdfExportService, PrintExportService } from 'src/app/core/utils/exports';
import { cloneDeep } from 'lodash'
import { PrintHeaderModel } from './print-header.model';
import { SecurityCodeSearchTableView } from 'src/app/modules/settings-management/settings-categories-content/security-management/security-code/security-code-search/shared/security-code-search-table.view';

@Component({
  selector: 'op-export-button-group-data',
  templateUrl: './export-button-group-data.component.html',
})
export class ExportButtonGroupDataComponent implements OnChanges {
  @Input() rowsNumber: number;
  @Input() exportData;
  @Input() selectedRow: SecurityCodeSearchTableView;
  @Input() jsonHeaders: PrintHeaderModel[];
  @Input() columsHeader: string[];
  @Input() exportName: string = '';
  @Input() pdfDefaultStyle: any = null;
  @Input() printDefaultStyle: any = null;
  @Input() pdfWidthPercentage: any = null;
  @Input() copyToClipboardFlag = true;
  @Input() exportFlag = true;
  @Input() printFlag = true;
  @Input() headerLevel: number = 1;
  @Input() header: string[];
  @Input() exportRowData;
  @Input() groupingIndex;
  @Input() dataFieldCount: any = [];
  @Input() dataFieldSum: any = [];
  @Input() detailHeaderLevel: number = 1;
  @Input() detialColumsHeader: any = [];

  public showCopyPopover = false;
  public showExportPopover = false;
  public showPrintPopover = false;

  constructor(private csvExportService: CsvExportService,
    private pdfExportService: PdfExportService,
    private printExportService: PrintExportService,
    private clipboard: Clipboard,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['copyToClipBoardFlag'] ||
      changes['exportFlag'] ||
      changes['printFlag']) {
      this.setupSecurity()
    }
  }

  public exportToPDF() {
    this.exportPopoverToggle();
    this.pdfExportService.loadPdfGroupData(
      this.headerLevel,
      this.columsHeader,
      this.exportName,
      this.pdfDefaultStyle,
      this.exportRowData,
      this.groupingIndex,
      this.dataFieldCount,
      this.dataFieldSum,
      this.detailHeaderLevel,
      this.detialColumsHeader);
  }

  public exportToCSV() {
    this.exportPopoverToggle();
    this.csvExportService.downloadDataGroup(
      this.headerLevel,
      this.columsHeader,
      this.exportName,
      this.pdfDefaultStyle,
      this.exportRowData,
      this.groupingIndex,
      this.dataFieldCount,
      this.dataFieldSum,
      this.detailHeaderLevel,
      this.detialColumsHeader);
  }

  public print() {
    let mappedData = this.mapExportData(this.exportData);
    this.printExportService.print(mappedData, this.getPrintFontSize(this.printDefaultStyle));
  }

  private getPrintFontSize(printDefaultStyle: any) {
    if (printDefaultStyle) {
      return printDefaultStyle.fontSize;
    }
    return "";
  }

  public mapExportData(filteredData: Object) {
    let tempData = cloneDeep(filteredData);
    for (let key in tempData) {

      let value = this.selectFieldMapper(tempData[key]);
      if (value) {
        tempData[key] = value;
      }
    }
    return tempData;
  }

  private selectFieldMapper(row) {
    if (this.header) {
      return this.renameObjKeyToHeader(row);
    }
    return this.renameObjKeyToHeaderFromJson(row);
  }

  public renameObjKeyToHeader(row) {
    let rowTemp = {};
    let colNum = 0;
    for (let key in row) {
      if (this.header[colNum]) {
        rowTemp[this.header[colNum]] = row[key] ?? '';
      }
      colNum += 1;
    }
    return rowTemp;
  }

  private renameObjKeyToHeaderFromJson(rows) {
    let rowTemp = {};
    for (let headerRow1 of this.jsonHeaders) {
      var row = rows[headerRow1.mapField];
      rowTemp[headerRow1.displayName] = row ?? '';
    }
    return rowTemp;
  }

  public copyToClipboard() {
    this.copyPopoverToggle();
    let copyData = this.header.join('\t') + '\n';
    if (this.selectedRow == null) {
      copyData += this.copyAllToClipboard();
    } else {
      copyData += this.createTableStructureString(this.selectedRow);
    }
    this.clipboard.copy(copyData);
  }

  public copyAllToClipboard(): string {
    let data = '';
    this.exportData.forEach(row => {
      data += this.createTableStructureString(row)
    });
    return data;
  }

  createTableStructureString(data: Object) {
    let dataTemp = Object.entries(data).map(([name, value]) => {
      return value
    });
    return dataTemp.join('\t') + '\n';
  }

  public copyPopoverToggle() {
    this.showCopyPopover = !this.showCopyPopover;
  }

  public exportPopoverToggle() {
    this.showExportPopover = !this.showExportPopover;
  }

  public printPopoverToggle() {
    this.showPrintPopover = !this.showPrintPopover;
  }

  public detectChange() {
    this.changeDetectorRef.detectChanges();
  }

  private setupSecurity() {
    if (this.copyToClipboardFlag == false) {
      $('button#btnGroupExport').attr('disabled', 'true');
    }
    if (this.exportFlag == false) {
      $('button#btnGroupExport').attr('disabled', 'true');
    }
    if (this.printFlag == false) {
      $('button#btnPrint').attr('disabled', 'true');
    }
    this.changeDetectorRef.detectChanges();
  }

}

import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InsightPassengerAddressModel, InsightPassengerModel } from 'src/app/core/models/individual-model';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model/invoice-individual-search.model';
import { InsightPassengerService } from 'src/app/core/services/individul-services';
import { AddressMapperService } from 'src/app/modules/insight-management/insight-management-content/insight-detail/insight-detail-address/shared/address-mapper.service';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { InvoiceIndividualDetailModel } from '../../../../../../../core/models/individual-model';
import { InvoiceIndividualDetailMapperService } from './invoice-individual-detail-mapper.service';

const select2WithoutSearchOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

@Component({
    selector: 'op-invoice-individual-detail',
    templateUrl: './invoice-individual-detail.component.html',
    providers: [
        AddressMapperService,
        InvoiceIndividualDetailMapperService
    ]
})
export class InvoiceIndividualDetailComponent implements OnInit {
    public focused = false;
    public individualDetailView: InvoiceIndividualDetailModel;
    public locationSavedData: Select2Data[] = [];
    public useAddress: InsightPassengerAddressModel[] = [];
    public countrySelect2: Select2Data[] = [];
    public addressTypeSelect2: Select2Data[] = [];
    public select2Option: any;

    @Output() onClearIndividual = new EventEmitter();

    constructor(private insightPassengerService: InsightPassengerService,
        private invoiceIndividualDetailMapperService: InvoiceIndividualDetailMapperService,
        private addressMapperService: AddressMapperService,
        private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.select2Option = select2WithoutSearchOption;
        this.individualDetailView = this.defaultDetailView;
    }

    get defaultDetailView(): InvoiceIndividualDetailModel {
        return {
            individualId: null,
            titleCode: null,
            firstName: null,
            lastName: null,
            languageCode: null,
            phoneTypeCode: null,
            phonePrefix: null,
            phoneNumber: null,
            mailAddress: null,
            InvoiceAddress: {
                addressId: null,
                individualId: null,
                useAddressId: null,
                countryCode: null,
                addressLine1: null,
                addressLine2: null,
                postalCode: null,
                state: null,
                province: null,
                city: null,
                primaryFlag: false,
                locationId: null,
                addressTypeCode: null,
                addressTypeName: null,
                countryName: null,
                locationName: null,
                organisationId: null
            }
        }
    }

    public onClearDetail() {
        this.individualDetailView = this.defaultDetailView;
        this.useAddress = [];
        this.locationSavedData = [];
        this.countrySelect2 = [];
        this.addressTypeSelect2 = [];
        this.onClearIndividual.emit();
    }

    public getIndividualDetail(individual: InvoiceIndividualSearchModel) {
        this.insightPassengerService.getInsightPassenger(individual.individualId)
            .subscribe(
                (response: InsightPassengerModel) => {
                    this.individualDetailView = this.invoiceIndividualDetailMapperService.InsightPassengerDetailToInvoiceIndividual(response, individual);
                    this.setupAddressSelect(this.individualDetailView.InvoiceAddress);
                    this.getUseAddress(response.insightPassengerAddresses, this.individualDetailView);
                    this.changeDetector.detectChanges();
                }
            )
    }

    get address() {
        return this.individualDetailView.InvoiceAddress;
    }
    private setupAddressSelect(address: InsightPassengerAddressModel) {
        this.getLocationSave(address);
        this.getCountrySelect(address);
        this.getAttributeTypeSelect(address);
    }

    private getLocationSave(address: InsightPassengerAddressModel) {
        if (!address?.locationId) {
            return;
        }
        this.locationSavedData = this.addressMapperService.getLocationSave(address.locationId, address.locationName)
    }

    private getUseAddress(addresses: InsightPassengerAddressModel[], individual: InvoiceIndividualDetailModel) {
        if (!addresses || !individual?.InvoiceAddress?.useAddressId) {
            return;
        }
        let address = addresses.find(address => address.addressId === individual.InvoiceAddress.useAddressId);
        this.useAddress.push(address);
    }

    private getCountrySelect(address: InsightPassengerAddressModel) {
        if (!address?.countryCode) {
            return;
        }
        this.countrySelect2.push(new Select2Data(address.countryCode, address.countryName));
    }

    private getAttributeTypeSelect(address: InsightPassengerAddressModel) {
        if (!address?.addressTypeCode) {
            return;
        }
        this.addressTypeSelect2.push(new Select2Data(address.addressTypeCode, address.addressTypeName));
    }

}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ScheduleQueueCommitModel } from "../../models/schedule-queue-category-model/schedule-queue-commit.model";
import { ScheduleQueueModel, ScheduleQueueSearchModel } from "../../models/schedule-queue-model";

@Injectable({
    providedIn: 'root'
})
export class ScheduleQueueService extends ApiServiceBase {
    private readonly PATH = "/schedule/scheduleQueues";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }
    public getBy(productId: string, orderId: string): Observable<ScheduleQueueModel[]> {
        let params = new HttpParams()
            .set('orderId', orderId ?? '')
            .set('productId', productId ?? '')
        return this.httpGet<ScheduleQueueModel[]>(this.PATH, params)
            .pipe(
                map(res => res.body)
            )
    }

    public getByCondition(model: ScheduleQueueSearchModel): Observable<ScheduleQueueSearchModel[]> {
        return this.httpPost<ScheduleQueueSearchModel[]>(this.PATH + "/search/", model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
    
    public getLatestCommits(): Observable<ScheduleQueueCommitModel[]> {
        return this.httpGet<ScheduleQueueCommitModel[]>(this.PATH + "/latestcommit", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getChildren(scheduleQueueId: string): Observable<ScheduleQueueSearchModel[]> {
        return this.httpGet<ScheduleQueueSearchModel[]>(this.PATH + "/children/" + scheduleQueueId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getProductNumberOriginDestination(scheduleQueueId: string): Observable<ScheduleQueueSearchModel[]> {
        return this.httpGet<ScheduleQueueSearchModel[]>(this.PATH + "/productNumberLocation/" + scheduleQueueId, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
export const FEE_REFERENCE_CODES = {
    activeStatus: "A",
    attributeGroupCode: "ATTRIBUTE",
    calendarValidityCode: "SERVICE",
    dimensionTypeCode: "ATTRIBUTE",
    orgTypeProviderCode: "AIRLINE",
    orgTypeSupplierCode: "SUPPLIER",
    productCategoryCode: "FEE",
    productLocationTypeCode: "RESTRICTION",
}

export const FEE_GENERAL_KEYS = ['productGroupCode', 'productTypeGroupId', 'draftFlag', 'usageTypCode'];

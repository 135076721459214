import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { ConditionReferenceModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ConditionReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/ConditionReferences"

    constructor(httpClient: HttpClient,
        authService: AuthService,
        private stringHelperService: StringHelperService) {
        super(httpClient, authService);
    }
    public getConditionsByCodes(conditionCodes: string[]): Observable<ConditionReferenceModel[]> {
        let conditionCodesParam = this.stringHelperService.createQueryParamFromArray('conditionCode', conditionCodes);
        return this.httpGet<ConditionReferenceModel[]>(this.PATH + conditionCodesParam, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Action, createReducer, on } from '@ngrx/store';
import { ActivityDomainTabModel } from 'src/app/core/models/activity-model/activity-domain-tab.model';
import * as ActivityActions from './activity.action';

export const activityFeatureKey = 'activities';

export interface ActivityState {
    activities: ActivityDomainTabModel[];
}

export const initialState: ActivityState = {
    activities: []
}

export const activityReducer = createReducer(
    initialState,
    on(ActivityActions.addActivity, (state, { activity }) => {
        const activities = addActivity(state.activities, activity);
        return {
            ...state,
            activities
        }
    })
)

function addActivity(activities: ActivityDomainTabModel[], addActivity: ActivityDomainTabModel): ActivityDomainTabModel[] {
    if (activities.some(activity => activity.tabNumber == addActivity.tabNumber)) {
        activities = activities.filter(x => x.tabNumber != addActivity.tabNumber);
    }
    return [...activities, addActivity];
}
export function reducerActivity(state: ActivityState | undefined, action: Action): any {
    return activityReducer(state, action);
}
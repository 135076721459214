import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxCheckBoxModule, DxDataGridModule, DxDropDownBoxModule } from 'devextreme-angular';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { MatIconModule } from '@angular/material/icon';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AncillaryServiceSearchComponent } from './ancillary-service-search.component';
import { AncillaryServiceSearchConditionComponent } from './ancillary-service-search-condition/ancillary-service-search-condition.component';
import { AncillaryServiceSearchTableComponent } from './ancillary-service-search-table/ancillary-service-search-table.component';

@NgModule({
    declarations: [
        AncillaryServiceSearchComponent,
        AncillaryServiceSearchConditionComponent,
        AncillaryServiceSearchTableComponent
    ],
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    exports: [
        AncillaryServiceSearchComponent
    ]
})
export class AncillaryServiceSearchModule { }

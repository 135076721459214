import { Injectable } from '@angular/core';
import { ApiServiceBase } from '../../base/api-service-base';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { VoucherSearchView } from 'src/app/modules/product-management/product-categories-content/voucher/shared/voucher-search.view';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoucherSearchCommandModel } from 'src/app/core/models/product-model/voucher-model/voucher-search-command.model';
import { VoucherAddCommandModel } from '../../models/product-model/voucher-model/voucher-add-command.model';
import { VoucherEditCommandModel } from '../../models/product-model/voucher-model/voucher-edit-command.model';
import { VoucherViewModel } from '../../models/product-model/voucher-model/voucher-view.model';
import { VoucherUsageView } from 'src/app/modules/product-management/product-categories-content/voucher/shared/voucher-use.view';

@Injectable({
    providedIn: 'root'
})
export class VoucherService extends ApiServiceBase {
    private readonly PATH = '/product/vouchers';

    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
            super(httpClient, authService);
    }

    public searchVoucher(condition: VoucherSearchCommandModel): Observable<VoucherSearchView[]> {
        return this.httpPost<VoucherSearchView[]>(`${this.PATH}/search`, condition)
            .pipe(
                map(response => response.body)
            )
    }

    public updateVoucher(voucherId: string, statusCode: string) {
        return this.httpPut(`${this.PATH}/${voucherId}/status/${statusCode}`, null)
            .pipe(
                map(response => response.body)
            )
    }

    public getVoucherById(voucherId: string): Observable<VoucherViewModel> {
        return this.httpGet<VoucherViewModel>(`${this.PATH}/${voucherId}`, null)
            .pipe(
                map(response => response.body)
            )
    }

    public addVoucher(command: VoucherAddCommandModel): Observable<string> {
        return this.httpPost<string>(this.PATH, command)
            .pipe(
                map(response => response.body)
            )
    }

    public editVoucher(command: VoucherEditCommandModel) {
        return this.httpPut(this.PATH, command)
            .pipe(
                map(response => response.body)
            )
    }

    public searchVoucherUse(voucherId: string, organisationId: string, productTypeCode: string, issueDateFrom: string, issueDateTo: string): Observable<VoucherUsageView[]> {
        let params = new HttpParams()
            .set('organisationId', organisationId ?? '')
            .set('productTypeCode', productTypeCode ?? '')
            .set('issueDateFrom', issueDateFrom ?? '')
            .set('issueDateTo', issueDateTo ?? '');

        return this.httpGet<VoucherUsageView[]>(`${this.PATH}/${voucherId}/usage`, params)
            .pipe(
                map(response => response.body)
            )
    }
}

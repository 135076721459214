export class UserModel {
    public userAccountId: string;
    public userLogon: string;
    public titleCode: string;
    public lastname: string;
    public firstname: string;
    public middlename: string;
    public nickname: string;
    public organisationType: string;
    public organisationId: string
}
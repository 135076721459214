import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { catchError, map, share } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';
import { environment } from 'src/environments/environment';
import { PasswordRequestViewModel } from '../../models/account-recovery-model/password-request-view.model';

@Injectable({
    providedIn: 'root'
})
export class AccountRecoveryService extends ApiServiceBase {
    private readonly PATH = "/accountrecovery/" + environment.authentication.clientId;

    constructor(httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public forgotPassword(userName: string) {
        return this.httpPost(this.PATH + '/forgotpassword/' + userName, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getPasswordRequest(partitionkey: string, rowkey: string): Observable<PasswordRequestViewModel> {
        return this.httpGet<PasswordRequestViewModel>(this.PATH + '/passwordrequest/' + partitionkey + '/rowkey/' + rowkey, null)
            .pipe(
                map(res => res.body)
            )
    }

    public changePassword(command: PasswordRequestViewModel) {
        return this.httpPut(this.PATH + '/changepassword/' , command)
            .pipe(
                map(res => res.body)
            )
    }

    public forgotUserName(email: string) {
        return this.httpPost(this.PATH + '/forgotusername/' + email, null)
            .pipe(
                map(res => res.body)
            )
    }
}
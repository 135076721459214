import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../authentication/auth.service';
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class MediaFilePreviewService extends ApiServiceBase {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getMediaFilePreview(fileName: string) {
        return this.httpGetAssetFile('/assets/img/media-file-preview/' + fileName, null)
                .pipe(
                    map(res => res)
                )    
    }

    public getURLDocAzureStorage(fileName: string) : string {
        let orgId = this.authService.getSelectedOrganisation();
        return environment.azureStorageURL + 'document/' + orgId + '/' + orgId + "_" + fileName + "?datetime=" + (new Date).toLocaleString();
    } 

    public getURLMediaAzureStorage(fileName: string, useQueryParam: boolean = true) : string {
        let orgId = this.authService.getSelectedOrganisation();
        let param = useQueryParam ? "?datetime=" + (new Date).toLocaleString() : "";
        return `${environment.azureStorageURL}media/${orgId}/${orgId}_${fileName}${param}`;
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    ProductGroupReferenceModel,
    ProductTypeGroupModel
} from 'src/app/core/models/reference-model/reference-product-model';

@Injectable()
export class ProductTypeDataService {

    public getProductGroupData(ctl, productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>) {
        let productCatCode = ctl.get('productCat').value;
        if (productCatCode != null) {
            var filterProductGroup = productGroupReferences$.value.filter(x => x.productCategoryCode == productCatCode);
            let productGroupData$ = new BehaviorSubject<ProductGroupReferenceModel[]>(null);
            productGroupData$.next(filterProductGroup);
            ctl.get('productGroupData').setValue(productGroupData$);
            if (filterProductGroup.length > 0) {
                ctl.get('productGroup').setValue(filterProductGroup[0].productGroupCode);
            }
            else {
                ctl.get('productGroup').setValue(null);
            }
        }
        else {
            ctl.get('productGroupData').setValue(null);
            ctl.get('productGroup').setValue(null);
        }
    }

    public getProductTypeGroupData(ctl, productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>) {
        let productGroupCode = ctl.get('productGroup').value;
        if (productGroupCode != null) {
            var filterProductTypeGroup = productTypeGroups$.value.filter(x => x.productGroupCode == productGroupCode);
            let productTypeGroupData$ = new BehaviorSubject<ProductTypeGroupModel[]>(null);
            productTypeGroupData$.next(filterProductTypeGroup);
            ctl.get('productGroupTypeData').setValue(productTypeGroupData$);
            ctl.get('productGroupType').setValue(null);
        }
        else {
            ctl.get('productGroupTypeData').setValue(null);
            ctl.get('productGroupType').setValue(null);
        }
    }

}

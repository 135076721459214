import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const THEME_STYLE_NAME: string = "theme_style";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    constructor(@Inject(DOCUMENT) private document: Document) {
    }
    public changeTheme(styleName: string) {

        const linkName: string = "client-theme";
        const head = this.document.getElementsByTagName('head')[0];

        let themeLink = this.document.getElementById(
            linkName
        ) as HTMLLinkElement;

        if (themeLink) {
            themeLink.href = styleName;
        } else {
            const style = this.document.createElement('link');
            style.id = linkName;
            style.rel = 'stylesheet';
            style.href = `${styleName}`;
            head.appendChild(style);
        }
        localStorage.setItem(THEME_STYLE_NAME, styleName);
    }

    public loadActiveStyle() {
        const defaultStyle = "styles.css";
        let styleName = localStorage.getItem(THEME_STYLE_NAME);
        if (!styleName) {
            this.changeTheme(defaultStyle);
        } else {
            this.changeTheme(styleName);
        }
    }

    public deleteActiveStyle() {
        localStorage.removeItem(THEME_STYLE_NAME);
    }
}
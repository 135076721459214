import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { StandardScheduleInformationTableComponent } from "../standard-schedule-information-table/standard-schedule-information-table.component";

@Component({
    selector: 'op-standard-schedule-information-home',
    templateUrl: './standard-schedule-information-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardScheduleInformationHomeComponent {
    readonly SPINNER_NAME: string = "standardScheduleQueueSpinner";
    public loadingNotifier = new LoadingNotifier();

    @Input() userSecurity: SecurityGroupSecurityModel;

    @ViewChild(StandardScheduleInformationTableComponent) searchTable: StandardScheduleInformationTableComponent;
}
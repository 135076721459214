export class SecurityGroupSecurityModel {
    public securityGroupSecurityId: string;
    public securityGroupId: string;
    public securityCode: string;
    public securityName: string;
    public limitation: number;
    public limitationName: string;
    public newFlag: boolean;
    public editFlag: boolean;
    public deleteFlag: boolean;
    public printFlag: boolean;
    public exportFlag: boolean;
    public copyFlag: boolean;
    public copyToClipboardFlag: boolean;
    public commitBy: string;
    public commitByName: string;
    public commitDateTime: Date;
}
import { Component, ChangeDetectionStrategy, Input, SimpleChange, ViewChild, OnChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core'
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';

import {
    select2ServiceTypeOption,
    select2FlightStatusOption,
    select2ProviderCodeOption,
    select2VehicleTypeOption,
    select2VehicleConfigurationOption,
    select2VehicleOption
} from '../shared/schedule-flight-information-configuration';

import { VehicleCompositionModel, VehicleModel, VehicleTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-schedule-flight-information',
    templateUrl: './schedule-flight-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleFlightInformationComponent extends OopsComponentFormBase implements OnChanges {

    public readonly ERROR_VEHICLETYPE_REQUIRED = 'Vehicle Type is required.';
    public readonly ERROR_PROVIDER_REQUIRED = 'Provider Code is required.';
    public readonly ERROR_VEHICLECOMP_REQUIRED = 'Vehicle Composition is required.'
    public readonly ERROR_FLIGHTNUMBER_REQUIRED = 'Flight number is required.';

    @Input() id: string;
    @Input() saveAction: boolean = false;
    @Input() baseFormGroup: any;
    @Input() leafSelected: boolean = false;
    @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() vehicleTypeReference$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    @Input() vehicleComposition$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    @Input() vehicle$ = new BehaviorSubject<VehicleModel[]>(null);
    @Input() productGroupCode: string;

    @Output() productNumber = new EventEmitter<string>();
    @Output() providerId = new EventEmitter<string>();
    @Output() supplierId = new EventEmitter<string>();
    @Output() formStatus = new EventEmitter<boolean>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public serviceTypeOption: any;
    public flightStatusOption: any;
    public providerCodeOption: any;
    public flightNumberOption: any;
    public vehicleTypeOption: any;
    public vehicleConfigurationOption: any;
    public vehicleOption: any;
    public vehicleTypeReferenceFilter$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
    public vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
    public vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
    public focusing: boolean = false;

    constructor(formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef) {
        super(formBuilder);
        this.setOptionControl();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.baseFormGroup.updateValueAndValidity();
        if (changes['id']) {
            this.completeProcessing();
        }
        if (changes['productGroupCode'] || changes['id']) {
            this.createVehicleTypeRefeferenceFilter();
        }
    }

    public initForm() {
        this.formGroup = this.baseFormGroup;
        this.formGroup.statusChanges
            .subscribe(val => this.onFormStatusChange());
    }

    private setOptionControl() {
        this.serviceTypeOption = select2ServiceTypeOption;
        this.flightStatusOption = select2FlightStatusOption;
        this.providerCodeOption = select2ProviderCodeOption;
        this.vehicleTypeOption = select2VehicleTypeOption;
        this.vehicleConfigurationOption = select2VehicleConfigurationOption;
        this.vehicleOption = select2VehicleOption;
    }

    private onFormStatusChange() {
        if (this.processing) {
            this.formStatus.emit(this.formGroup.valid);
        }
    }

    public valueChange(value, name) {
        this.baseFormGroup.get(name).setValue(value);
    }

    public providerChange(value, sendToAttribute: boolean = false) {
        this.baseFormGroup.get("providerId").setValue(value);
        if (sendToAttribute) {
            this.providerId.emit(value);
        }
        this.changeDetectionRef.detectChanges();
    }

    public supplierChange(value, sendToAttribute: boolean = false) {
        this.baseFormGroup.get("supplierId").setValue(value);
        if (sendToAttribute) {
            this.supplierId.emit(value);
        }
        this.changeDetectionRef.detectChanges();
    }

    private createVehicleTypeRefeferenceFilter() {
        this.vehicleTypeReferenceFilter$ = new BehaviorSubject<VehicleTypeReferenceModel[]>(null);
        if (this.vehicleTypeReference$.value && this.productGroupCode) {
            this.filterVehicleType();
        }
    }

    private filterVehicleType() {
        var vehicleTypeFilter = this.vehicleTypeReference$.value.filter(x => x.vehicleGroupCode == this.convertProductGroupToVehicleGroupCode());
        this.vehicleTypeReferenceFilter$.next(vehicleTypeFilter);
        if (!this.id) {
            this.vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
            this.vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
        }
    }

    public createVehicleCompositonAndVehicle(vehicleTypeCode) {
        this.baseFormGroup.get("vehicleTypeCode").setValue(vehicleTypeCode);
        this.createVehicleCompositionFilter(vehicleTypeCode);
        this.createVehicleFilter(vehicleTypeCode);
    }

    private createVehicleCompositionFilter(vehicleTypeCode) {
        this.vehicleCompositionFilter$ = new BehaviorSubject<VehicleCompositionModel[]>(null);
        if (this.vehicleComposition$.value && vehicleTypeCode) {
            this.filterVehicleComposition(vehicleTypeCode);
        }
    }

    private filterVehicleComposition(vehicleTypeCode) {
        var vehicleComposition = this.vehicleComposition$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
        this.vehicleCompositionFilter$.next(vehicleComposition);
        if (vehicleComposition?.length) {
            this.baseFormGroup.get("vehicleCompositionId").setValue(vehicleComposition[0].vehicleCompositionId);
        }
        else {
            this.baseFormGroup.get("vehicleCompositionId").setValue(null);
        }
    }

    private createVehicleFilter(vehicleTypeCode) {
        this.vehicleFilter$ = new BehaviorSubject<VehicleModel[]>(null);
        if (this.vehicle$.value && vehicleTypeCode) {
            this.filterVehicle(vehicleTypeCode);
        }
    }

    private filterVehicle(vehicleTypeCode) {
        var vehicle = this.vehicle$.value.filter(x => x.vehicleTypeCode == vehicleTypeCode);
        this.vehicleFilter$.next(vehicle);
        if (vehicle?.length) {
            this.baseFormGroup.get("vehicleId").setValue(vehicle[0].vehicleId);
        }
        else {
            this.baseFormGroup.get("vehicleId").setValue(null);
        }
    }

    public refreshForm() {
        this.changeDetectionRef.detectChanges();
    }

    private convertProductGroupToVehicleGroupCode(): string {
        var vehicleGroupCode: string
        switch (this.productGroupCode) {
            case "AIR": {
                vehicleGroupCode = "AIRCRAFT";
                break;
            }
            case "RAIL": {
                vehicleGroupCode = "TRAIN";
                break;
            }
            case "BUS": {
                vehicleGroupCode = "BUS";
                break;
            }
            case "SHIP": {
                vehicleGroupCode = "VESSEL";
                break;
            }
        }
        return vehicleGroupCode;
    }

    public validateForm(): boolean {
        this.startProcessing();
        if (this.formGroup.invalid == true) {
            return false;
        }
        return true;
    }

    public getErrorMessageForm(): string {
        if (!this.formGroup.controls["providerId"].valid) {
            return this.ERROR_PROVIDER_REQUIRED;
        } else if (!this.formGroup.controls["flightNumber"].valid) {
            return this.ERROR_FLIGHTNUMBER_REQUIRED;            
        } else if (!this.formGroup.controls["vehicleTypeCode"].valid) {
            return this.ERROR_VEHICLETYPE_REQUIRED;
        } else if (!this.formGroup.controls["vehicleCompositionId"].valid) {
            return this.ERROR_VEHICLECOMP_REQUIRED;
        }
        return null;
    }

    public onFlightNumberChange(event) {
        this.productNumber.emit(event.target.value);
    }

    public updateNumber(value: string) {
        this.baseFormGroup.get("flightNumber").setValue(value);
        this.changeDetectionRef.detectChanges();
    }
}
import { AfterViewInit, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionService } from 'src/app/core/utils/action.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-schedule-distribution-content',
    templateUrl: './schedule-distribution-content.component.html',
})
export class ScheduleDistributionContentComponent implements AfterViewInit {
    readonly defaultTitle = 'Schedule Distribution';

    public title: string = this.defaultTitle;

    private unsubscribe$ = new Subject();

    actionBarHandler = new ActionBarHandlerModel(
        new RefreshButtonModel()
    );

    constructor(private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private actionService: ActionService) { }

    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
        this.actionService.add('SEARCH', null, null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            default:
                break;
        }
    }

    back() {
        const queryParams = {
            tab: 'search'
        }

        this.navigationService.navigate("main/schedule/home", null, false, queryParams);
    }
}

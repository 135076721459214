import { UntypedFormControl, Validators } from "@angular/forms";

export const InsightGeneralDetailFormOption = {
    gender: new UntypedFormControl(null),
    dateOfBirth: new UntypedFormControl(null),
    usageTypeCode: new UntypedFormControl(null, Validators.required),
    ageGroupCode: new UntypedFormControl(null),
    revenueLast12: new UntypedFormControl({ value: null, disabled: true }),
    ordersLast12: new UntypedFormControl({ value: null, disabled: true }),
    status: new UntypedFormControl(null, Validators.required),
    commitByName: new UntypedFormControl({ value: null, disabled: true }),
    commitDateTime: new UntypedFormControl({ value: null, disabled: true })
}
import { Action, createReducer, on } from '@ngrx/store';
import { TabModel } from '../../core/models/tab/tab.model';
import * as TabActions from './tab.action';

export const tabFeatureKey = 'tabs';
const homePath = '/main/home';

export interface TabState {
    tabs: TabModel[];
}

export const initialState: TabState = {
    tabs: []
}

export const tabReducer = createReducer(
    initialState,
    on(TabActions.addTab, (state, { tab }) => {
        const tabs = addTab(state.tabs, tab);
        return {
            ...state,
            tabs
        }
    }),
    on(TabActions.deleteTab, (state, { tab }) => {
        const tabs = deleteTab(state.tabs, tab);
        return {
            ...state,
            tabs
        };
    }),
    on(TabActions.deleteAllTab, (state, { forceCloseAll }) => {
        if (forceCloseAll) {
            return {
                ...initialState
            }
        }
        const tabs = deleteAllAllowCloseTabs(state.tabs);
        return {
            ...state,
            tabs
        }
    }),
    on(TabActions.setActiveTab, (state, { tab }) => {
        const tabs = setSelectedTab(state.tabs, tab);
        return {
            ...state,
            tabs
        };

    }),
    on(TabActions.setTabUrl, (state, { tab }) => {
        const tabs = setTabUrl(state.tabs, tab);
        return {
            ...state,
            tabs
        };

    }),
    on(TabActions.setTabName, (state, { tab }) => {
        const tabs = setTabName(state.tabs, tab);
        return {
            ...state,
            tabs
        }
    })
);

function addTab(tabs: TabModel[], addTab: TabModel): TabModel[] {
    if (tabs.some(tab => tab.id == addTab.id)) {
        return tabs;
    }
    return [...tabs, addTab];
}
function deleteTab(tabs: TabModel[], toDeleteTab: TabModel): TabModel[] {
    return tabs.filter(t => t.id != toDeleteTab.id);
}

function setSelectedTab(tabs: TabModel[], activeTab: TabModel): TabModel[] {
    tabs.forEach((tab: TabModel) => {
        if (tab.id == activeTab.id) {
            tab.active = true;
        } else {
            tab.active = false;
        }
    });
    return tabs;
}

function setTabUrl(tabs: TabModel[], activeTab: TabModel): TabModel[] {
    tabs.forEach((tab: TabModel) => {
        if (tab.id == activeTab.id) {
            tab.url = activeTab.url;
        }
    });
    return tabs;
}

function setTabName(tabs: TabModel[], activeTab: TabModel): TabModel[] {
    tabs.forEach((tab: TabModel) => {
        if (tab.id == activeTab.id) {
            tab.name = activeTab.name;
        }
    });
    return tabs;
}

function deleteAllAllowCloseTabs(tabs: TabModel[]): TabModel[] {
    let currentOpenTabs = tabs.filter(t => t.disableCloseTab === true);
    if (currentOpenTabs && currentOpenTabs.length > 0) {
        currentOpenTabs[0].active = true;
    }
    return currentOpenTabs;
}
export function reducer(state: TabState | undefined, action: Action): any {
    return tabReducer(state, action);
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Helper } from "src/app/shared/helper/app.helper";
import { OrganisationRoutingModule } from "./organisation-routing.module";
import { OrganisationComponent } from "./organisation.component";
import { DynamicRoutingModule } from "src/app/shared/utils/navigation";

@NgModule({
    declarations: [
        OrganisationComponent,
    ],
    imports: [
        CommonModule,
        DynamicRoutingModule,
        OrganisationRoutingModule
    ],
    providers: [
        Helper
    ]
})
export class OrganisationModule { }

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbNavModule, NgbPaginationModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { VehicleCompositionGeneralDetailComponent } from "./general/general.component";
import { VehicleCompositionDetailComponent } from "./vehicle-composition-detail.component";
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { MatIconModule } from "@angular/material/icon";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { ActionbarService } from "src/app/shared/ui/actionbar";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { ConfigurationListComponent } from "./configuration/configuration-list/configuration-list.component";
import { ConfigurationDetailComponent } from "./configuration/configuration-detail/configuration-detail.component";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";

@NgModule({
    declarations: [
        VehicleCompositionGeneralDetailComponent,
        VehicleCompositionDetailComponent,
        ConfigurationComponent,
        ConfigurationListComponent,
        ConfigurationDetailComponent
    ],
    exports: [
        VehicleCompositionGeneralDetailComponent,
        VehicleCompositionDetailComponent
    ],
    imports: [
        AlertBarModule,
        ButtonsModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        MatIconModule,
        NavigationIndexModule,
        NgbPaginationModule,
        NgbNavModule,
        NgbModule,
        PanelsModule,
        ReactiveFormsModule,
        StatusColorDropdownModule,
        Select2Module,
        UiModule
    ],
    providers: [
        ActionbarService
    ]
})
export class VehicleCompositionDetailModule { }

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { catchError, map, share, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { OrganisationPayment, PaymentSearch, PaymentSearchCriteria } from './payment-search.model';
import { ApiServiceBase } from 'src/app/core/base/api-service-base';
import { CurrencyReferenceService, PaymentStatusReferenceService, PaymentTypeReferenceService } from 'src/app/core/services/system-services';
import { CurrencyReferenceModel, PaymentStatusReferenceModel, PaymentTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationService } from 'src/app/core/services/organisation-services';

@Injectable({
    providedIn: 'root'
})
export class PaymentSearchService extends ApiServiceBase {

    private _paymentSearch$ = new BehaviorSubject<PaymentSearch[]>([])
    private _organisationUser$ = new BehaviorSubject<any>(null)
    private _paymentCurrency$ = new BehaviorSubject<CurrencyReferenceModel[]>([])
    private _paymentStatus$ = new BehaviorSubject<PaymentStatusReferenceModel[]>([])
    private _paymentType$ = new BehaviorSubject<PaymentTypeReferenceModel[]>([])
    private _paymentAdvanceSearch$ = new BehaviorSubject<any[]>([])
    private _balanceAdvanceSearch$ = new BehaviorSubject<any[]>([])
    private _voidedAdvanceSearch$ = new BehaviorSubject<any[]>([])
    private _paymentSearchSelection$ = new BehaviorSubject<PaymentSearch>(null)
    private _paymentOrganisation$ = new BehaviorSubject<OrganisationPayment[]>([])
    private _loading$ = new Subject<boolean>()
    private _executeSearch$ = new Subject<string>()
    private _alertSuccess$ = new Subject<string>()
    private _alertError$ = new Subject<string>()

    private organisationTypeCodes = ['AIRLINE', 'OWNER', 'CUSTOMER', 'TRAVELAGENCY'];

    get paymentSearch$() {
        return this._paymentSearch$.asObservable()
    }
    get organisationUser$() {
        return this._organisationUser$.asObservable()
    }
    get paymentCurrency$() {
        return this._paymentCurrency$.asObservable()
    }
    get paymentStatus$() {
        return this._paymentStatus$.asObservable()
    }
    get paymentType$() {
        return this._paymentType$.asObservable()
    }
    get paymentAdvanceSearch$() {
        return this._paymentAdvanceSearch$.asObservable()
    }
    get balanceAdvanceSearch$() {
        return this._balanceAdvanceSearch$.asObservable()
    }
    get voidedAdvanceSearch$() {
        return this._voidedAdvanceSearch$.asObservable()
    }
    get paymentSearchSelection$() {
        return this._paymentSearchSelection$.asObservable()
    }
    get paymentOrganisation$() {
        return this._paymentOrganisation$.asObservable()
    }
    get loading$() {
        return this._loading$.asObservable()
    }
    get executeSearch$() {
        return this._executeSearch$.asObservable()
    }
    get alertSuccess$() {
        return this._alertSuccess$.asObservable()
    }
    get alertError$() {
        return this._alertError$.asObservable()
    }

    constructor(httpClient: HttpClient,
        authService: AuthService,
        private currencyReferenceService: CurrencyReferenceService,
        private paymentStatusReferenceService: PaymentStatusReferenceService,
        private formOfPaymentReferenceService: PaymentTypeReferenceService,
        private organisationService: OrganisationService
    ) {
        super(httpClient, authService);
    }

    getPaymentSearch(criteria: PaymentSearchCriteria): Observable<PaymentSearch[]> {
        const queryParams = new HttpParams({ fromObject: <any>criteria })
        return this.httpGet<PaymentSearch[]>('/payment/PaymentsSearch', queryParams)
            .pipe(
                tap(res => this._paymentSearch$.next(res.body)),
                map(res => res.body),
                share(),
            )
    }

    getUserOrganisationUser(): Observable<any> {
        return this.organisationService.getByOrganisationTypes(this.organisationTypeCodes)
            .pipe(
                tap(res => this._organisationUser$.next(res)),
                map(res => res),
            )
    }

    getPaymentCurrency(): Observable<CurrencyReferenceModel[]> {
        return this.currencyReferenceService.getCurrencyReferences()
            .pipe(
                tap(res => this._paymentCurrency$.next(res)),
                map(res => res),
            )
    }

    getPaymentStatus(): Observable<PaymentStatusReferenceModel[]> {
        return this.paymentStatusReferenceService.getPaymentStatusReference()
            .pipe(
                tap(res => this._paymentStatus$.next(res)),
                map(res => res),
            )
    }

    getPaymentType(): Observable<PaymentTypeReferenceModel[]> {
        return this.formOfPaymentReferenceService.getPaymentTypeReference()
            .pipe(
                tap(res => this._paymentType$.next(res)),
                map(res => res),
            )
    }

    getPaymentAdvanceSearch() {
        return of([
            { id: 0, name: '(all)' },
            { id: 1, name: 'Payments' },
            { id: 2, name: 'Refunds' },
        ]).pipe(
            tap(res => this._paymentAdvanceSearch$.next(res)),
        )
    }

    getBalanceAdvanceSearch() {
        return of([
            { id: 0, name: '(all)' },
            { id: 1, name: 'Credit Balance' },
            { id: 2, name: 'Debit Balance' },
        ]).pipe(
            tap(res => this._balanceAdvanceSearch$.next(res)),
        )
    }

    getVoidedAdvanceSearch() {
        return of([
            { id: 0, name: 'Exclude' },
            { id: 1, name: 'Include' },
        ]).pipe(
            tap(res => this._voidedAdvanceSearch$.next(res)),
        )
    }

    setPaymentSearchSelection(paymentSearch: PaymentSearch) {
        this._paymentSearchSelection$.next(paymentSearch)
    }

    getPaymentOrganisations(): Observable<OrganisationPayment[]> {
        return this.httpGet<OrganisationPayment[]>("/organisation/OrganisationPayments", null)
            .pipe(
                tap(res => this._paymentOrganisation$.next(res.body)),
                map(res => res.body),
            )
    }

    updatePaymentStatusCode(paymentId: string, actionType: string) {
        return this.httpPut(`/payment/PaymentsSearch/${paymentId}/status/${actionType}`, {})
            .pipe(
                map(res => {
                    const resBody = (res?.body as any)
                    if (resBody?.status == 400) {
                        throw new Error(resBody.message)
                    }
                    return resBody
                }),
                catchError((error: Error) => {
                    return throwError(error?.message ?? error)
                }),
                share(),
            )
    }

    validatePaymentAction(paymentId: string, actionType: string, referenceOrderId: string) {
        const queryParams = new HttpParams({ fromObject: <any>{ referenceOrderId } })
        return this.httpGet(`/payment/PaymentsSearch/${paymentId}/status/${actionType}`, queryParams)
            .pipe(
                map(res => {
                    const resBody = (res.body as any)
                    if (resBody?.status == 400) {
                        throw new Error(resBody.message)
                    }
                    return resBody
                }),
                catchError((error: Error) => {
                    return throwError(error?.message ?? error)
                }),
                share(),
            )
    }

    exchangeOrderId(orderNumber: string): Observable<string> {
        return this.httpGet<string>(`/order/OrderPayments/${orderNumber}`, null)
            .pipe(
                map(res => res.body),
                share(),
            )
    }

    setLoading(present: boolean) {
        this._loading$.next(present)
    }

    executeSearch() {
        this._executeSearch$.next((Math.random() + 1).toString(36))
    }

    alertSuccess(message: string) {
        this._alertSuccess$.next(message)
    }

    alertError(message: string) {
        this._alertError$.next(message)
    }
}

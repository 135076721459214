import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDocumentInvoiceComponent } from './payment-document-invoice.component';
import { OrganisationDocumentInvoiceModule } from './organisation-document-invoice/organisation-document-invoice.module';
import { IndividualDocumentInvoiceModule } from './individual-document-invoice/individual-document-invoice.module';

@NgModule({
    declarations: [
        PaymentDocumentInvoiceComponent,
    ],
    imports: [
        CommonModule,
        IndividualDocumentInvoiceModule,
        OrganisationDocumentInvoiceModule
    ],
    exports: [
        PaymentDocumentInvoiceComponent
    ]
})
export class PaymentDocumentInvoiceModule { }

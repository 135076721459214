import { Component, ChangeDetectionStrategy, Input, SimpleChange, OnChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';

import { select2SchedulePeriodOption, select2ScheduleVersionOption } from '../shared/schedule-information-configuration';

import { ProductSchedulePeriodModel } from 'src/app/core/models/product-model/product-schedule-period-model';
import { ProductScheduleVersionModel } from 'src/app/core/models/product-model/product-schedule-period-model/product-schedule-version.model';

@Component({
    selector: 'op-schedule-information',
    templateUrl: './schedule-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleInformationComponent extends OopsComponentFormBase implements OnChanges {

    @Input() id: string;
    @Input() saveAction: boolean = false;
    @Input() baseFormGroup: any;
    @Input() focused: boolean = false;
    @Input() classIcon: string;
    @Input() leafSelected: boolean = false;
    @Input() productSchedulePeriod$ = new BehaviorSubject<ProductSchedulePeriodModel[]>(null);

    public schedulePeriodOption: any;
    public scheduleVersionOption: any;
    public productScheduleVersion$ = new BehaviorSubject<ProductScheduleVersionModel[]>(null);
    public focusing: boolean = false;
    
    constructor(formBuilder: UntypedFormBuilder,
        private changeDetectionRef: ChangeDetectorRef) {
        super(formBuilder);
        this.setOptionControl();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.baseFormGroup.updateValueAndValidity();
    }

    public initForm() {
        this.formGroup = this.baseFormGroup;
    }

    private setOptionControl() {
        this.schedulePeriodOption = select2SchedulePeriodOption;
        this.scheduleVersionOption = select2ScheduleVersionOption;
    }

    public valueChange(value, name) {
        this.baseFormGroup.get(name).setValue(value);
    }

    public createProductScheduleVersion(productSchedulePeriodId) {
        var comment = null;
        this.baseFormGroup.get("productSchedulePeriodId").setValue(productSchedulePeriodId);
        this.productScheduleVersion$ = new BehaviorSubject<ProductScheduleVersionModel[]>(null);
        if (this.productSchedulePeriod$.value && productSchedulePeriodId) {
            var productSchedulePeriod = this.productSchedulePeriod$.value.filter(x => x.productSchedulePeriodId == productSchedulePeriodId);
            comment = this.filterProductScheduleversion(productSchedulePeriod);
        } else {
            this.baseFormGroup.get("productScheduleVersionId").setValue(null);
        }
        this.setComment(comment);
    }

    private filterProductScheduleversion(productSchedulePeriod) : string {
        if (productSchedulePeriod) {
            this.productScheduleVersion$.next(productSchedulePeriod[0].productScheduleVersions);
            if (productSchedulePeriod[0].productScheduleVersions?.length) {
                this.baseFormGroup.get("productScheduleVersionId").setValue(productSchedulePeriod[0].productScheduleVersions[0].productScheduleVersionId);
                return productSchedulePeriod[0].productScheduleVersions[0].commentText;
            }
        }
    }

    public getProductScheduleVersionComment(productScheduleVersionId) {
        var comment = null;
        this.baseFormGroup.get("productScheduleVersionId").setValue(productScheduleVersionId);
        if (this.productScheduleVersion$.value && productScheduleVersionId) {
            var productScheduleVersion = this.productScheduleVersion$.value.filter(x => x.productScheduleVersionId == productScheduleVersionId);
            if (productScheduleVersion?.length) {
                comment = productScheduleVersion[0].commentText;
            }
        }
        this.setComment(comment);
    }

    private setComment(value) {
        this.baseFormGroup.get("comment").setValue(value);
    }

    public findComment(productScheduleVersionId) : string {
        if (this.productScheduleVersion$.value && productScheduleVersionId) {
            var productScheduleVersion = this.productScheduleVersion$.value.filter(x => x.productScheduleVersionId == productScheduleVersionId);
            if (productScheduleVersion?.length) {
                return productScheduleVersion[0].commentText;
            }
        }
    }

    public findProductSchedulePeriodId(productScheduleVersionId) : string {
        if (this.productSchedulePeriod$.value && productScheduleVersionId) {
            var productSchedulePeriods = this.productSchedulePeriod$.value;
            for (let productSchedulePeriod of productSchedulePeriods) {
                var canFind = this.findInProductScheduleVersion(productScheduleVersionId, productSchedulePeriod.productScheduleVersions);
                if (canFind) {
                    return productSchedulePeriod.productSchedulePeriodId;
                }
            }
        }
    }

    private findInProductScheduleVersion(productScheduleVersionId, productScheduleVersions: ProductScheduleVersionModel[]) : boolean {
        if (productScheduleVersions?.length) {
            var filter = productScheduleVersions.filter(x => x.productScheduleVersionId == productScheduleVersionId);
            if (filter?.length) {
                return true;
            }
        }
    }

    public refreshForm() {
        this.changeDetectionRef.detectChanges();
    }
}
import { Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import { MediaService } from 'src/app/core/services/media-services/media-sevice';
import { MediaMapperService } from '../shared/media-mapper.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

import {
    MediaSearchCommandModel,
    MediaSearchViewModel
} from 'src/app/core/models/media-model';

import { MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaLibraryView } from '../shared/media-library.view';
import { MediaView } from '../shared/media.view';

import { MediaFileAssociationInMediaViewModel } from 'src/app/core/models/media-model/media-fileassociation/media-fileassociation-in-media-view.model';
import { MediaFileAssociationService } from 'src/app/core/services/media-services/media-fileassociation-service';
import { MediaFilePreviewService } from 'src/app/core/services/media-services/media-file-preview-service';

@Component({
    selector: 'op-media-library-table',
    templateUrl: './media-library-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MediaMapperService]

})
export class MediaLibraryTableComponent implements OnInit {

    @Input() mediaSearchCommand: MediaSearchCommandModel;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);

    @Output() onSaveMediaLibrary = new EventEmitter<MediaView[]>();
    @Output() onCancelBrowseClick = new EventEmitter();

    public mediaFileLibrarys: MediaLibraryView[] = new Array();
    public mediaFileAssociationInMedia: MediaFileAssociationInMediaViewModel[];
    public showFilterRow: boolean = true;
    public showHeaderFilter: boolean = true;
    public applyFilterTypes: any;
    public currentFilter: any;
    public mediaIdSelected: string;
    public pageSize: number = 10;
    libraryUsedDataSourceStorage: any;
    private ORG_ID = this.autService.getSelectedOrganisation();
    public searchText: string = "";
    public searchWithMediaType: boolean = false;
    private entityExclude: string[] = ["UserAccount"];

    constructor(
        private mediaService: MediaService,
        private mediaMapperService: MediaMapperService,
        private mediaFileAssociationService: MediaFileAssociationService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private autService: AuthService,
        private domSanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.searchMedia();
    }

    public searchMedia() {
        if (this.mediaSearchCommand) {
            this.mediaSearchCommand.entityExclude = this.entityExclude;
            this.mediaService.search(this.mediaSearchCommand)
                .subscribe(
                    (responses: MediaSearchViewModel[]) => {
                        this.libraryUsedDataSourceStorage = [];
                        this.mediaFileLibrarys = this.mediaMapperService.mediaSearchToMediaFileLibraryViews(responses);
                        this.changeDetectorRef.detectChanges();
                    }
                )
        }
    }

    public selectionChangedHandler(e) {
        for (let file of this.mediaFileLibrarys) {
            var filter = e.instance.getSelectedRowsData().filter(x => x.mediaId == file.mediaId);
            if (filter.length == 0) {
                file.selected = false;
            }
            else {
                file.selected = true;
            }
        }
    }

    loadThumbnailFinished(e) {
        if (e.data.mediaFilePreviewBase64 || e.data.mediaFileNotFound) {
            return true;
        }
        else {
            this.getMediaFile(e);
            return false;
        }
    }

    private getMediaFile(e) {
        this.mediaService.getMediaFile(e.data.mediaContentId)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var fileName = this.mediaMapperService.getfileName(this.ORG_ID, e.data.mediaContentId, contenType);
                        var file = new File([response.body], fileName, { type: contenType });
                        this.updateFileToModel(e, file);
                        if (this.mediaMapperService.isImage(contenType)) {
                            this.getMediaThumbnailFile(e);
                        }
                        else {
                            this.getPicFilePreview(e, contenType);
                        }
                    }
                    else {
                        e.data.mediaFileNotFound = true;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
    }

    private getMediaThumbnailFile(e) {
        this.mediaService.getMediaThumbnailFile(e.data.mediaContentId)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var fileName = this.mediaMapperService.getfileName(this.ORG_ID, e.data.mediaContentId, contenType);
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    } else {
                        e.data.mediaFileNotFound = true;
                        this.changeDetectorRef.detectChanges();
                    }
                }
            );
    }

    private getPicFilePreview(e, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        var contenType = response.headers.get("Content-Type");
                        var file = new File([response.body], fileName, { type: contenType });
                        this.readURLFile(e, file);
                    }
                }
            );
    }

    private readURLFile(e, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            e.data.mediaFilePreviewBase64 = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
            e.data.mediaFilePreview = file;
            e.component.refresh();

        };
    }

    public searchMediaByTextCondition() {
        if (this.searchText) {
            let mediaUseCode = this.geteMediaUseCodeForSearch();
            let mediaTypeCode = this.geteMediaTypeCodeForSearch();
            this.mediaService.searchMediaFile(this.searchText, mediaTypeCode, mediaUseCode, this.entityExclude)
                .subscribe(
                    (responses: MediaSearchViewModel[]) => {
                        this.libraryUsedDataSourceStorage = [];
                        this.mediaFileLibrarys = this.mediaMapperService.mediaSearchToMediaFileLibraryViews(responses);
                        this.changeDetectorRef.detectChanges();
                    }
                )
        }
        return false;
    }

    private geteMediaUseCodeForSearch(): string {
        var returnValue = "";
        if (this.searchWithMediaType) {
            returnValue = this.mediaSearchCommand.mediaUseCode;
        }
        return returnValue;
    }

    private geteMediaTypeCodeForSearch(): string {
        var returnValue = "";
        if (this.searchWithMediaType) {
            returnValue = this.mediaSearchCommand.mediaTypeCode;
        }
        return returnValue;
    }

    getLibraryUsed(key) {
        let item = this.libraryUsedDataSourceStorage.find((i) => i.key === key);
        if (!item) {
            this.mediaFileAssociationService.getMediaFileAssociationInMedia(key)
                .subscribe(
                    (responses: MediaFileAssociationInMediaViewModel[]) => {
                        var mediaFileAssociationInMedia = this.mediaMapperService.updateMediaId(key, responses)
                        item = {
                            key: key,
                            dataSourceInstance: new DataSource({
                                store: new ArrayStore({
                                    data: mediaFileAssociationInMedia,
                                    key: "mediaId"
                                }),
                                filter: ["mediaId", "=", key]
                            })
                        };
                        this.libraryUsedDataSourceStorage.push(item);
                        this.changeDetectorRef.detectChanges();
                        return item.dataSourceInstance;
                    }
                )
        }
        else {
            return item.dataSourceInstance;
        }
    }

    private updateFileToModel(e, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            e.data.mediaFileBase64 = reader.result.toString();
            e.data.mediaFile = file;
        };
    }

    public cancelBrowse() {
        this.onCancelBrowseClick.emit();
    }

    public saveMediaBrowse() {
        var mediaLibrarySelected = this.mediaFileLibrarys.filter(x => x.selected == true);
        var viewMedias = this.mediaMapperService.mediaFileLibraryToMediaTextViews(mediaLibrarySelected);
        this.onSaveMediaLibrary.emit(viewMedias);
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'op-ancillary-service-tree',
    templateUrl: './tree.component.html'
})
export class TreeComponent implements OnChanges {
    public selectedNode: TreeNode;
    public firstLoad = true;

    @Input() ancillaryServiceTree: TreeNode[];
    @Input() productId: string;
    @Input() heightPanelGeneral: number;
    @Output() onNodeSelect = new EventEmitter<TreeNode>();
    @Output() treeCollapsed = new EventEmitter();
    public focusing: boolean = false;
    
    ngOnChanges(changes: SimpleChanges): void {       
        if (changes['ancillaryServiceTree']) {
            this.refreshTree();
        }
    }

    public onSelectionChange(event) {
        this.onNodeSelect.emit(event);
    }

    private refreshTree() {
        if (this.productId && this.ancillaryServiceTree) {
            if (this.firstLoad) {
                this.firstLoad = false;
            }
            this.ancillaryServiceTree.forEach(node => {
                this.expandRecursive(node, true);
            });
        }
        
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
        if (node.data == this.productId) {
            this.selectedNode = node;
        }
    }

    public onTreeCollapsed(collapsedStatus: boolean) {
        this.treeCollapsed.emit(collapsedStatus);
    }
}

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, SimpleChange, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
    select2ProductCodeType, select2Provider, select2Supplier
} from '../../../shared/configuration/select2-number-configuration';

import { ProductNumberTypeReferenceModel } from '../../../../../../core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductNumberTypeReferenceService } from 'src/app/core/services/system-services/product-number-type-reference.service';
import { field } from '../../../shared/constant/attribute.constant';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { ATTRIBUTE_AND_RULE_ERR } from '../attribute-and-rule-error.constant';

@Component({
    selector: 'op-product-number',
    templateUrl: './product-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNumberComponent implements OnChanges {
    public productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() productTypeCode: string;
    @Input() productNumberTypeSearch$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() searchMode: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public actionForm: UntypedFormGroup;
    public codeTypeOption: any;
    public providerOption: any;
    public supplierOption: any;
    public focusing: boolean = false;
    
    constructor(private fb: UntypedFormBuilder,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.codeTypeOption = select2ProductCodeType;
        this.providerOption = select2Provider;
        this.supplierOption = select2Supplier;
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (this.searchMode) {
            this.getProductTypeNumberSearch();
        }
        else {
            this.geProductTypeNumber();
        }
    }

    private geProductTypeNumber() {
        if (this.productTypeCode) {
            this.productNumberTypeReferenceService.getByProductType(this.productTypeCode)
                .subscribe(
                    (responses: ProductNumberTypeReferenceModel[]) => {
                        this.productNumberTypeReferences$.next(responses);
                    }
                )
        }
        else {
            this.productNumberTypeReferences$.next(null);
        }
    }

    private getProductTypeNumberSearch() {
        this.productNumberTypeReferences$ = this.productNumberTypeSearch$;
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == "INVALID") {
                    return;
                }
            }
        }
        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroup(null));

        setTimeout(() => {
            let elementReference = document.querySelector('#ddlProductNumberTypeCode_' + (this.forms.controls.length - 1));
            if (elementReference instanceof HTMLElement) {
                elementReference.focus();
            }
        }, 100)
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                }
                else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }


    findDuplicate(ctlCheck, ctlIndex): boolean {
        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (this.isProductDuplicate(ctlCheck, ctl)) {
                    duplicate = true;
                }
            }
        }
        return duplicate;
    }

    isProductDuplicate(ctlCheck, ctlRow): boolean {
        return ctlRow.value.productNumber == ctlCheck.value.productNumber &&
            ctlRow.value.productNumberTypeCode == ctlCheck.value.productNumberTypeCode &&
            ctlRow.value.providerId == ctlCheck.value.providerId &&
            ctlRow.value.supplierId == ctlCheck.value.supplierId;
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        let item = formCurrent.get('productNumberDuplicate');
        if (required) {
            item.setValue(null);
            item.setValidators(Validators.required);
            item.updateValueAndValidity();
        }
        else {
            item.setValue(false);
            item.setValidators(Validators.nullValidator);
            item.updateValueAndValidity();
        }
    }

    public validateInvalidRow(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectorRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (!ctl.valid) {
                    return false;
                }
            }
        }
        return true;
    }

    public validateForm(): boolean {
        return !this.findDuplicateAllRow() && this.validateInvalidRow();
    }

    public getErrorMessageForm(): string {
        if (this.findDuplicateAllRow()) {
            return ATTRIBUTE_AND_RULE_ERR.duplicateInformation;
        } else if (!this.validateInvalidRow()) {
            return ATTRIBUTE_AND_RULE_ERR.invalidInformation;
        }
    }

    addViewToFormGroup(productNumbers: ProductNumberViewModel[]) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        for (let productNumber of productNumbers) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroup(productNumber));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);
        }
        this.changeDetectorRef.detectChanges();
    }

    createFormGroup(productNumber: ProductNumberViewModel) {
        if (productNumber) {
            return this.fb.group({
                productNumberId: new UntypedFormControl(productNumber.productNumberId, { validators: [Validators.nullValidator] }),
                productNumberTypeCode: new UntypedFormControl(productNumber.productNumberTypeCode, { validators: [Validators.required] }),
                productNumber: new UntypedFormControl(productNumber.productNumber, { validators: [Validators.required] }),
                providerId: new UntypedFormControl(productNumber.providerId, { validators: [Validators.nullValidator] }),
                supplierId: new UntypedFormControl(productNumber.supplierId, { validators: [Validators.nullValidator] }),
                productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }),
                submitted: Boolean
            });
        }
        else {
            return this.fb.group({
                productNumberId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
                productNumberTypeCode: new UntypedFormControl(null, { validators: [Validators.required] }),
                productNumber: new UntypedFormControl(null, { validators: [Validators.required] }),
                providerId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
                supplierId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
                productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }),
                submitted: Boolean
            });
        }
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
    }

    selectChange(value: string | string[], ctl, name: string) {
        ctl.get(name).setValue(value);
        this.findDuplicateAllRow();
    }

    clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }
}
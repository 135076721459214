import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { selectArrival, selectDeparture, selectProvider, selectSchedulePeriod, selectScheduleVersion } from '../../../schedule-queue/schedule-queue-content/shared/select2-configuration';
import { RouteDataService } from "src/app/modules/insight-management/insight-management-content/insight-detail/insight-detail-address/shared/route-data-service";
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ProductSchedulePeriodModel, ProductScheduleVersionModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ScheduleDistributionSearchModel } from 'src/app/core/models/schedule-distribution-model';
import { dateSelectionCalendarOption, selectDistributionOrganisation } from './schedule-distribution-search-configurations';
import moment from 'moment';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-schedule-distribution-search',
    templateUrl: './schedule-distribution-search.component.html',
    providers: [RouteDataService]
})
export class ScheduleDistributionSearchComponent implements OnInit {
    public selectDistributionOrganisationOption: any;
    public providerOption: any;
    public departureOption: any;
    public arrivalOption: any;
    public schedulePeriodOption: any;
    public scheduleVersionOption: any;
    public productScheduleVersionFilter: ProductScheduleVersionModel[];
    public calendarOption: any;
    public focused: boolean = false;

    @Input() providerReferences: OrganisationModel[];
    @Input() productSchedulePeriods: ProductSchedulePeriodModel[];
    @Input() condition: ScheduleDistributionSearchModel;
    @Input() productScheduleVersions: ProductScheduleVersionModel[];
    @Input() scheduleDistributionModel: OrganisationModel[];

    @Output() searchClick = new EventEmitter<ScheduleDistributionSearchModel>();
    @Output() conditionChange = new EventEmitter<ScheduleDistributionSearchModel>();

    constructor(private routeDataService: RouteDataService,
        private changeDetectionRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService,
        private navigateService: NavigationService) {
        this.setOptionControl();
    }

    ngOnInit(): void {
        this.condition.scheduleQueueActionCode = 'OTABATCH';
        const params = this.navigateService.getParams()
        if (params?.executeFlag) {
            this.onSearch();
        }
    }

    get processStartDateTime(): string {
        return this.dateConverterService.toDateFormat(this.condition.processStartDateTime);
    }

    get processEndDateTime(): string {
        return this.dateConverterService.toDateFormat(this.condition.processEndDateTime);
    }

    public onProcessStartDateTimeChanged(event) {
        this.condition.processStartDateTime = event;
    }

    public onProcessEndDateTimeChanged(event) {
        this.condition.processEndDateTime = event;
    }

    private setOptionControl() {
        this.providerOption = selectProvider;
        this.departureOption = selectDeparture;
        this.departureOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
        this.arrivalOption = selectArrival;
        this.arrivalOption.ajax = this.routeDataService.select2AjaxSearchByLocationCode();
        this.schedulePeriodOption = selectSchedulePeriod;
        this.scheduleVersionOption = selectScheduleVersion;
        this.calendarOption = dateSelectionCalendarOption;
        this.calendarOption.startDate = moment();
        this.selectDistributionOrganisationOption = selectDistributionOrganisation;
    }

    public onDistributionOrganisationChange(value) {
        if (value == '') {
            this.condition.distributeOrganisationId = null;
            return;
        }
        this.condition.distributeOrganisationId = value;
    }

    public onProviderChange(value) {
        if (value == '') {
            this.condition.providerId = null;
            return;
        }

        this.condition.providerId = value;
    }

    public onDepartureChange(value) {
        this.condition.originLocationId = value;
    }

    public onArrivalChange(value) {
        this.condition.destinationLocationId = value;
    }

    public onSchedulePeriodChange(value) {
        if (value == '') {
            this.condition.productSchedulePeriodId = null;
            this.condition.productScheduleVersionId = null;
            return;
        }

        this.productScheduleVersionFilter = this.productScheduleVersions.filter(x => x.productSchedulePeriodId == value)
        this.condition.productSchedulePeriodId = value;
    }

    public onScheduleVersionChange(value) {
        if (value == '') {
            this.condition.productScheduleVersionId = null;
            return;
        }
        this.condition.productScheduleVersionId = value;
    }

    public clearCondition() {
        this.condition = {} as ScheduleDistributionSearchModel;
        this.changeDetectionRef.detectChanges();
    }

    public onSearch() {
        this.condition.scheduleQueueActionCode = 'OTABATCH';
        this.searchClick.emit();
    }

    public getCondition(): ScheduleDistributionSearchModel {
        return this.condition;
    }
}

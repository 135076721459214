import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeSearchTableView } from '../shared/security-code-search-table.view';
import { select2Status } from '../shared/select2-configuration';
import { StatusCode } from 'src/app/core/constants/status-constants';

@Component({
    selector: 'op-security-code-search-condition',
    templateUrl: './security-code-search-condition.component.html'
})
export class SecurityCodeSearchConditionComponent implements OnInit, AfterViewInit {
    @Input() statusReferences: StatusReferenceModel[];
    @Input() executeFlag: boolean = true;
    @Output() onSearchClick = new EventEmitter();

    public collapsed: boolean;
    public focused: boolean;
    public statusOption: any;
    public condition = new SecurityCodeSearchTableView();

    constructor() {
        this.statusOption = select2Status;
    }

    ngOnInit(): void {
        this.condition.statusCode = StatusCode.Active;
    }

    ngAfterViewInit(): void {
        if (this.executeFlag == true) {
            this.searchClick();
        }  
    }

    searchClick() {
        this.onSearchClick.emit(this.condition);
    }

    clearCondition() {
        this.condition = new SecurityCodeSearchTableView();
        this.onSearchClick.emit(this.condition);
    }

    onStatusChange(e){
        this.condition.statusCode = e;
    }
}

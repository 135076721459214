import { Injectable } from '@angular/core';
import { IPaymentGatewayProvider } from "./payment-gateway-provider";
import { Observable, BehaviorSubject } from "rxjs";
import { PaymentModel, PaymentVoucher } from '../../models/payment-models/payment.model';
import { PaymentStatusModel } from '../../models/payment-models/payment-status.model';
import { ExternalPaymentModel, PaymentsModel } from '../../models/payment-models/external-payment.model';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { NavigationItems } from 'src/app/app.navigation';
import { GATEWAY_PAYMENT_STATUS } from './payment-gateway-constant';
import { PaymentService } from '../payment-services';
import { PaymentConstant } from 'src/app/modules/order/shared';

@Injectable({
    providedIn: 'root'
})
export class DataTransGatewayService implements IPaymentGatewayProvider {

    private readonly TRANSACTION_ID_PARAM: string = "datatransTrxId";
    private readonly STATUS: string = "status";
    public readonly PAYMENT_CONSTANT = PaymentConstant;
    private readonly PROCESS_PAYMENT_PARAMS = `scrollbars=no,
        resizable=yes,
        status=no,
        location=no,
        toolbar=no,
        menubar=no,
        width=646,
        height=715,
        left=-1000,
        top=-1000`;

    private _responseStatus: string;
    private _responseParam: URLSearchParams;

    constructor(private paymentService: PaymentService,
        private navigationService: NavigationService,) {

    }

    public getResponseStatus(): string {
        return this._responseStatus;
    }

    public resetResponseStatus(): void {
        this._responseStatus = null;
    }

    public getResponseParam(key: string): string {
        if (!this._responseParam) {
            return null;
        }
        return this._responseParam.get(key);
    }

    public processPayment(payment: ExternalPaymentModel): Observable<PaymentStatusModel> {

        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        const observable$ = new BehaviorSubject<PaymentStatusModel>(null);
        const initialPayment = this.createInitialPayment(payment.recordLocator,
            payment.orderId,
            payment.amount.currencyCode,
            payment.amount.value,
            `${baseUrl}/payment/external`,
            payment.successQueryString,
            payment.cancelQueryString,
            payment.errorQueryString,
            payment.organisationCode,
            payment.payments,
            payment.paymentVoucher);

        let winRef;
        if (payment.payments.formOfPaymentTypeCode == this.PAYMENT_CONSTANT.cc) {
            winRef = window.open('', '_blank', this.PROCESS_PAYMENT_PARAMS);
            winRef.document.write('Loading ...');
        }

        this.paymentService.processPayment(initialPayment)
            .subscribe((response: PaymentStatusModel) => {
                if (payment.payments.formOfPaymentTypeCode == this.PAYMENT_CONSTANT.cc) {
                    const transactionId = response?.data?.transactionId;
                    if (transactionId) {

                        const dataTransUrl = `${response.data.redirectUrl}/start/${transactionId}`;               
                        winRef.location.href = dataTransUrl;

                        let loop = setInterval(() => {
                            if (winRef.closed) {
                                clearInterval(loop);
                                response.status = this.getResponseResult(transactionId);
                                this.clearResponseResult(transactionId);
                                observable$.next(response);
                            }
                        }, 500);
                    }
                } else {
                    observable$.next(response);
                }
            });
        return observable$;
    }

    private getOrderNavigation() {
        const orderNavivationId = "SHOPPING";
        return NavigationItems.find(n => n.id === orderNavivationId);
    }

    private createInitialPayment(recordLocator: string,
        orderId: string,
        paymentCurrency: string,
        paymentAmount: number,
        redirectUrl: string,
        successQueryString: string,
        cancelQueryString: string,
        errorQueryString: string,
        organisationCode: string,
        payments: PaymentsModel,
        paymentVoucher: PaymentVoucher): PaymentModel {

        return {
            context: {
                language: "en",
                currencyCode: "EUR",
                organisationCode: organisationCode,
                organisationId: null
            },
            orderNumber: recordLocator,
            orderId: orderId,
            formOfPayments: null,
            total: {
                sales: {
                    currencyCode: paymentCurrency,
                    amount: paymentAmount
                }
            },
            payments: [payments],
            paymentVoucher: paymentVoucher,
            redirect: {
                successUrl: redirectUrl + `?${this.STATUS}=${GATEWAY_PAYMENT_STATUS.SUCCESS}&${successQueryString}`,
                cancelUrl: redirectUrl + `?${this.STATUS}=${GATEWAY_PAYMENT_STATUS.CANCEL}&${cancelQueryString}`,
                errorUrl: redirectUrl + `?${this.STATUS}=${GATEWAY_PAYMENT_STATUS.ERROR}&${errorQueryString}`
            }
        };
    }
    public handlePaymentPageResponse(): void {
        const parsedUrl = new URL(window.location.href);
        const params = new URLSearchParams(parsedUrl.search);
        const dataTransTransactionId = params.get(this.TRANSACTION_ID_PARAM);

        if (!dataTransTransactionId) {
            return;
        }
        this._responseParam = params;
        this._responseStatus = params.get(this.STATUS);
        this.setResponseResult(dataTransTransactionId, this._responseStatus);
    }

    private setResponseResult(key: string, value: string): void {
        localStorage.setItem(key, value);
    }
    private getResponseResult(key: string): string {
        return localStorage.getItem(key);
    }
    private clearResponseResult(key: string): void {
        localStorage.removeItem(key);
    }
}
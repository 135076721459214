import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ordinal',
})
export class OrdinalPipe implements PipeTransform {
    private ordinals = {
        1: 'st',
        2: 'nd',
        3: 'rd',
    };
    transform(value: any): string {
        let val: number = null;
        if (this.isNumber(value)) {
            val = value;
        } else if (this.isString(value)) {
            val = parseInt(value);
        } else {
            return null;
        }
        let valModded = val % 10;
        return this.ordinals[valModded] ?? 'th';
    }

    private isNumber(x: any): x is number {
        return typeof x === 'number';
    }

    private isString(x: any): x is string {
        return typeof x === 'string';
    }
}

export const StateConstant = {
    NONE: '(none)',
    DRAFT: 'Draft',
    FINAL: 'Final'
}

export const StateConditionConstant = {
    NONE: '(none)',
    CHECKED: 'Checked',
    UNCHECKED: 'Unchecked'
}
import { Injectable } from '@angular/core';

Injectable()
export class Globals {
    private _timestamp: string;
    private _datetime: string;
    private _longdate: string;
    private _longtime: string;
    private _shortdate: string;
    private _shorttime: string;

    get TIMESTAMP(): string {
        return this._timestamp;
    }

    set TIMESTAMP(val: string) {
        if (this._timestamp) {
            return;
        }
        this._timestamp = val;
    }

    get DATETIME(): string {
        return this._datetime;
    }

    set DATETIME(val: string) {
        if (this._datetime) {
            return;
        }
        this._datetime = val;
    }

    get LONGDATE(): string {
        return this._longdate;
    }

    set LONGDATE(val: string) {
        if (this._longdate) {
            return;
        }
        this._longdate = val;
    }

    get LONGTIME(): string {
        return this._longtime;
    }

    set LONGTIME(val: string) {
        if (this._longtime) {
            return;
        }
        this._longtime = val;
    }

    get SHORTDATE(): string {
        return this._shortdate;
    }

    set SHORTDATE(val: string) {
        if (this._shortdate) {
            return;
        }
        this._shortdate = val;
    }

    get SHORTTIME(): string {
        return this._shorttime;
    }

    set SHORTTIME(val: string) {
        if (this._shorttime) {
            return;
        }
        this._shorttime = val;
    }

    public EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$';
    public static CODE_PATTERN = '^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])+$';
    public NAME_PATTERN = '[a-zA-Z ]*';
    public PASSWORD_PATTERN = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d)(?!.* ).{8,}$/;
    public readonly ERROR_PASSWORD_RECYCLE = 'Password has to be different from the old one.';
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { PointOfSalesOrganisation } from "../../models/order-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class PointOfSalesOrganisationServices extends ApiServiceBase {
    private readonly PATH: string = "/order"

    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<PointOfSalesOrganisation[]> {
        return this.httpGet<PointOfSalesOrganisation[]>(this.PATH + "/PointOfSalesOrganisations", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getByOrganisation(): Observable<PointOfSalesOrganisation[]> {
        return this.httpGet<PointOfSalesOrganisation[]>(this.PATH + "/PointOfSalesOrganisations/organisation", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }   
    
    public getByOrganisationHiearachy(): Observable<PointOfSalesOrganisation[]> {
        return this.httpGet<PointOfSalesOrganisation[]>(this.PATH + "/PointOfSalesOrganisations/organisation-hiearachy", null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }  
}
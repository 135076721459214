import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import DataSource from 'devextreme/data/data_source';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { ExportButtonGroupDataComponent } from 'src/app/shared/ui/export-button-group';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { CounterSalesSearchModel, CounterSalsesSearchCommandModel } from 'src/app/core/models/counter-sales-model';
import { MapperService } from '../../../shared/mapper.service';
import { PdfExportService } from 'src/app/core/utils/exports';
import { DatePipe } from '@angular/common';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { appConfig } from 'src/configs/appConfig';

@Component({
    selector: 'op-counter-sales-search-table',
    templateUrl: './counter-sales-search-table.component.html',
    providers: [
        MapperService,
        DatePipe
    ]
})
export class CounterSalesSearchTableComponent implements OnChanges {
    public readonly SPINNER_NAME: string = "counterSalesSearch";
    public readonly EXPORT_FILE_NAME = "CounterSalesSearch"
    public readonly SPINNER_FULL_SCREEN: boolean = true;
    public readonly dataFieldCount = [];
    public readonly dataFieldSum = [];
    private readonly dateStampFormat = 'dd/MM/yyyy'
    public selectedItem: any;
    public loadingNotifier = new LoadingNotifier();
    public focusing = false;
    public rows = 1;
    public exportData: any;
    public header: string[] = [];
    public pdfDefaultStyle = {
        fontSize: 7
    }
    public paggingView: PagingDataView[];
    public columsHeader = [];
    public subColumsHeader = [];
    public headerLevel = 2;
    public columnsOptions = [];
    public dataSource: DataSource;
    public exportRowData: any[];
    public counterSalseSearchResult: CounterSalesSearchModel[];
    public detailHeaderLevel = 2;
    public detialColumsHeader = [];

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private pdfExportService: PdfExportService,
        private datepipe: DatePipe,
        private dateConverterService: DateConverterService) { }

    @Input() searchResults: CounterSalesSearchModel[];
    @Output() reSearch = new EventEmitter();

    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() organisationDateTimeFormat: string;
    @Input() organisationLongDateFormat: string;
    @Input() organisationCallName: string;
    @Input() counterSalesSearchCondition: CounterSalsesSearchCommandModel;

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild(ExportButtonGroupDataComponent) exportButtonGroup: ExportButtonGroupDataComponent;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchResults']) {
            this.mapData();
        }
    }

    public onRowSelected(event) {
        this.selectedItem = event.key;
    }

    private mapData() {
        this.loadingNotifier.show(this.SPINNER_NAME);
        if (!this.searchResults) {
            return;
        }
        this.paggingView = new Array();
        let index = 1;
        for (let model of this.searchResults) {
            this.addPaggingView(index, model.ledgerId);
            model.no = index;
            index++;
        }
        this.counterSalseSearchResult = this.searchResults
        this.loadingNotifier.hide(this.SPINNER_NAME);
        this.changeDetectionRef.detectChanges();
    }
    
    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    contentReady(e) {
        this.getDataRowExport();
    }

    getCaption(hasHeader, caption) {
        if (hasHeader) {
            let newcaption = caption.split(' ');
            if (newcaption.length > 1) {
                return newcaption[1];
            }
            return newcaption[0];
        }
        return caption;
    }

    getSelectorGroup() {
        let groupIndex = [];
        let columnsOptions = this.columnsOptions;
        columnsOptions.sort((a, b) => a.groupIndex - b.groupIndex);
        columnsOptions?.forEach(e => {
            let data = { selector: e.dataField }
            groupIndex.push(data);
        });
        return groupIndex;
    }

    getDataRowExport() {
        let filteredData;
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        gridDataSource?.store().load({
            filter: filterExpr,
        }).done(function (filterData) {
            filteredData = filterData;
        });
        this.dataSource = new DataSource(filteredData);

        let groupIndex = this.getSelectorGroup();
        if (groupIndex.length > 0) {
            this.dataSource.group(groupIndex);
            this.dataSource.load().done(function (filterData) {
                filteredData = filterData;
            });
        }
        this.exportRowData = filteredData;
    }

    refreshResult() {
        this.reSearch.emit();
    }

    getDetail(value) {
        if (!value) {
            return "";
        }
        return value;
    }

    public async exportCounterSales(fileFormat: string) {
        if (fileFormat == 'PDF') {
            await this.exportCounterSalesPdf();
        }
    }

    public async exportCounterSalesPdf() {
        var pdfContent = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: null,
            styles: null,
            images: null,
            defaultStyle: this.pdfDefaultStyle
        };
        pdfContent.content = await this.createPdfContent();
        pdfContent.styles = this.addPdfStyles();

        this.pdfExportService.download(this.EXPORT_FILE_NAME, pdfContent);
    }

    public async createPdfContent(): Promise<any[]> {
        var content = [
            {
                alignment: 'center',
                image: await this.getBase64LogoFromURL(appConfig.logoUrl),
                width: 60,
            },
            {
                alignment: 'center',
                text: `\n\nCounter Sales Report: ${this.getPdfHeader()}\n\n`
            }
        ]
        this.createCurrencyTable(content);
        this.addContentFooter(content)
        return content;
    }

    private getPdfHeader(): string {
        let pdfHeader = `${this.organisationCallName} (${this.convertToOrganisationLongDate(this.counterSalesSearchCondition.salesDateFrom)}`
        if (!this.counterSalesSearchCondition.salesDateTo) {
            return `${pdfHeader})`;
        }
        return `${pdfHeader}-${this.convertToOrganisationLongDate(this.counterSalesSearchCondition.salesDateTo)})`
    }

    private createCurrencyTable(content: any[]) {
        let currencyGroups = this.groupData(this.exportRowData);
        for (const [key, value] of Object.entries(currencyGroups)) {
            let currencyItems = value as Array<any>;
            content.push({
                text: `Currency: ${currencyItems[0]?.salesCurrencyName} (${key})\n\n`
            })
            this.addPdfTable(content, currencyItems);
        }
    }

    private groupData(datas: any[]) {
        return datas.reduce((list, item) => {
            list[item.salesCurrencyCode] = list[item.salesCurrencyCode] || [];
            list[item.salesCurrencyCode].push(item);
            return list;
        }, [])
    }

    private addPdfTable(content: any[], datas: any) {
        var table = {
            style: 'counterSalesTable',
            table: {
                body: []
            }
        }
        this.addPdfTableHeader(table.table.body);
        this.addPdfTableData(table.table.body, datas);
        content.push(table);
    }

    private addPdfTableHeader(tableBody: any[]) {
        tableBody.push([this.getTableUpperHeaderCell('No', 1, 2), 
                this.getTableUpperHeaderCell('Product', 8), {}, {}, {}, {}, {}, {}, {},
                this.getTableUpperHeaderCell('Order', 3), {}, {},
                this.getTableUpperHeaderCell('Sales', 2), {}]);
        tableBody.push([this.getTableHeaderCell(''), this.getTableHeaderCell('Category'), this.getTableHeaderCell('Group'),
            this.getTableHeaderCell('Type'), this.getTableHeaderCell('Name'), this.getTableHeaderCell('Departure'), this.getTableHeaderCell('Arrival'),
            this.getTableHeaderCell('Service\nDate'), this.getTableHeaderCell('Status'), this.getTableHeaderCell('Point Of\nSales'), this.getTableHeaderCell('Number'),
            this.getTableHeaderCell('Reference'), this.getTableHeaderCell('Currency'), this.getTableHeaderCell('Amount')]);
    }

    private addPdfTableData(tableBody: any[], datas: any) {
        let total = 0;
        for (let data of datas) {
            let row = [
                data.no ?? '',
                data.productCategoryName ?? '',
                data.productGroupName ?? '',
                data.productTypeName ?? '',
                data.orderProductName ?? '',
                data.departureLocationName ?? '',
                data.arrivalLocationName ?? '',
                this.convertToOrganisationLongDate(data.serviceDateTime),
                data.orderSalesStatusName ?? '',
                data.organisationCallName ?? '',
                data.orderNumber ?? '',
                data.bookingReference ?? '',
                data.salesCurrencyCode ?? '',
                {
                    text: data.salesAmount?.toFixed(2) ?? '',
                    alignment: 'right'
                }
            ]
            total += parseFloat(data.salesAmount)
            tableBody.push(row);
        }
        this.addTableTotal(tableBody, total);
    }

    private addTableTotal(tableBody: any[], total: number) {
        tableBody.push([{text: 'Total', colSpan: 13}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {text: total.toFixed(2), alignment: 'right'}])
    }

    private addPdfStyles(): any {
        let styles = {
            tableHeader: {
                bold: true
            },
            upperTableHeader: {
                bold: true,
                alignment: 'center'
            }
        }
        return styles;
    }

    private getTableHeaderCell(cellText: string, cellColSpan: number = 1, cellRowSpan: number = 1) {
        return {text: cellText, rowSpan: cellRowSpan, colSpan: cellColSpan, style: 'tableHeader', fillColor: '#DEEAF6'}
    }

    private getTableUpperHeaderCell(cellText: string, cellColSpan: number = 1, cellRowSpan: number = 1) {
        return {text: cellText, rowSpan: cellRowSpan, colSpan: cellColSpan, style: 'upperTableHeader', fillColor: '#DEEAF6'}
    }

    private addContentFooter(content: any[]) {
        content.push(
            {
                text: `\n\n\nVerified By: ______________________________  (${this.convertToOrganisationLongDate(new Date())})`,
            }
        );
    }

    private convertToOrganisationLongDate(value: Date | string): string {
        if (!value) {
            return '';
        }
        return this.datepipe.transform(value, this.organisationLongDateFormat)
    }

    async getBase64LogoFromURL(url) {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
        }
        });
    }
}
import { Injectable } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    PriceRuleAttributeModel,
    PriceRuleMembershipModel,
    PriceRuleModel,
    PriceRuleOrganisationModel,
    PriceRuleIndividualModel,
    PriceRuleProductModel,
    PriceRuleProductNumberModel,
    PriceRuleRouteModel,
    PriceRuleLocationModel,
    PriceRuleSalesBucketModel,
    PriceRuleServiceCategoryModel,
    PriceRuleValidityModel,
    PriceRuleVehicleModel,
    PriceRuleProductOptionModel,
    PriceRuleCommand,
    PriceRuleFormOfPaymentModel
} from 'src/app/core//models/pricing-model';
import { DomainAttributeModel, StatusReferenceModel } from 'src/app/core//models/reference-model/reference-general-model';
import { PriceRuleView } from 'src/app/modules/pricing/shared/views/price-rule.view';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

import { PricingDetailConstant } from '../rules/price-rule-detail/shared/pricing-detail.constant';


import { Helper } from 'src/app/shared/helper/app.helper';
import {
    PriceRuleAttributeAndRuleRowView,
    PriceRuleProductNumberView,
    PriceRuleRouteView,
    PriceRuleLocationView,
    PriceRuleValidityView,
    PriceRuleVehicleView,
    PriceRuleAttributeView,
    PriceRuleOrganisationView,
    PriceRuleServiceCategoryView,
    PriceRuleSalesBucketView,
    PriceRuleProductView,
    PriceRuleMembershipView,
    PriceRuleIndividualView,
    PriceRuleFormOfPaymentView
} from '../rules/price-rule-detail/attribute-and-rule/product-eligible-restricted/shared/views';

import { ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { PriceRuleCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-pricing-model';
import { LanguageTranslationCommandModel, LanguageTranslationSetCommandModel, LanguageTranslationSetViewModel, LanguageTranslationViewModel } from 'src/app/core/models/language-translation-set-model';
import { SearchTableView } from './search/shared/search-table.view';
import { PriceRuleProductOptionView } from '../rules/price-rule-detail/inclusive-of-products/shared/views/price-rule-product-option.view';
import { PriceConverter } from '../prices/shared/price.converter';
import { MediaCommandModel, MediaViewModel } from 'src/app/core/models/media-model';
import { PricingMediaConverter } from './pricing-media.converter';

declare var moment: any;

@Injectable()
export class PricingConverter {
    private readonly dateTimeFormat = 'YYYY/MM/DD HH:mm';
    private readonly timeFormat = 'HH:mm';
    private readonly yearMonthDateFormat = 'YYYY/MM/DD';
    private readonly dateMonthYearFormat = 'DD/MM/YYYY';
    private readonly sqlDateTimeMinValue = '1753-01-01';

    constructor(private dateConverter: DateConverterService,
        private helper: Helper,
        private priceConverter: PriceConverter,
        private pricingMediaConverter: PricingMediaConverter) {

    }
    public toStatusReferenceSelect2Data(model: StatusReferenceModel): Select2Data {
        let data = new Select2Data();
        data.id = model.statusCode;
        data.text = model.displayName;
        return data;
    }

    public toStatusReferenceSelect2DataList(models: StatusReferenceModel[]): Select2Data[] {
        let dataList = new Array<Select2Data>();
        for (let model of models) {
            dataList.push(this.toStatusReferenceSelect2Data(model));
        }
        return dataList;
    }

    public toPriceRuleCategoryReferenceSelect2Data(model: PriceRuleCategoryReferenceModel): Select2Data {
        let data = new Select2Data();
        data.id = model.priceRuleCategoryCode;
        data.text = model.priceRuleCategoryName;
        return data;
    }

    public toPriceRuleCategoryReferenceSelect2DataList(models: PriceRuleCategoryReferenceModel[]): Select2Data[] {
        let dataList = new Array<Select2Data>();
        for (let model of models) {
            dataList.push(this.toPriceRuleCategoryReferenceSelect2Data(model));
        }
        return dataList;
    }

    public toModel(view: PriceRuleView): PriceRuleModel {
        let model = {} as PriceRuleModel;
        model.id = view.priceRuleId;
        model.parentPriceRuleId = view.parentPriceRuleId;
        model.priceRuleCode = view.priceRuleCode?.toUpperCase();
        model.priceRuleName = view.priceRuleName;
        model.priceRuleConcatenateFlag = view.priceRuleConcatenate;
        model.statusCode = view.statusCode;
        model.priceRuleTypeCode = view.priceRuleTypeCode;
        model.priceRuleGroupCode = view.priceRuleGroupCode;
        model.priceRuleCategoryCode = view.priceRuleCategoryCode;
        model.priceRuleDisplayCode = view.priceRuleDisplayCode?.toUpperCase();
        model.priceRuleDisplayName = view.priceRuleDisplayName;
        model.priceRuleTypeGroupName = view.priceRuleTypeGroupName;
        model.usageTypeCode = view.usageTypeCode;
        model.isOwnerFilter = view.isOwnerFilter;
        model.ownerFilterUserAccountId = view.ownerFilterUserAccountId;
        this.productEligibleViewToModel(model, view.productEligibleData);
        this.pointOfSalesViewToModel(model, view.pointOfSalesData);
        this.serviceCategoriesSalesBucketsViewToModel(model, view.serviceCategoriesSalesBucketsData);
        this.parentServiceCategoriesSalesBucketsViewToModel(model, view.parentServiceCategoriesSalesBucketsData);
        this.roundtripDefinitionViewToModel(model, view.roundtripDefinitionData);
        this.attributeViewToModel(model, view.attributeData);
        this.productAttributeViewToModel(model, view.productAttributeData);
        this.customerViewToModel(model, view.customerData);
        this.transitStopoverViewToModel(model, view.transitStopoverData);
        this.formOfPaymentViewToModel(model, view.formOfPaymentData);
        model.productOptions = this.toProductOptionModels(view);
        model.priceRulePrices = this.priceConverter.toPriceRulePriceModels(view.priceRulePrices);
        if (view.children?.length) {
            let children = new Array<PriceRuleModel>();
            for (let child of view.children) {
                children.push(this.toModel(child));
            }
            model.children = children;
        }
        return model;
    }

    private toLanguageTranslationSetView(model: LanguageTranslationSetCommandModel): LanguageTranslationSetViewModel {
        if (!model) {
            return null;
        }
        let view = new LanguageTranslationSetViewModel();
        view.languageTranslationName = model.languageTranslationName;
        view.languageTranslationSetId = model.languageTranslationSetId;
        view.statusCode = model.statusCode;
        view.languageTranslations = this.toLanguageTranslationViews(model.languageTranslations);
        return view;
    }

    private toLanguageTranslationViews(models: LanguageTranslationCommandModel[]): LanguageTranslationViewModel[] {
        let views = new Array<LanguageTranslationViewModel>();
        for (let model of models) {
            views.push(this.toLanguageTranslationView(model));
        }
        return views;
    }

    private toLanguageTranslationView(model: LanguageTranslationCommandModel): LanguageTranslationViewModel {
        let view = new LanguageTranslationViewModel();
        view.entityName = model.entityName;
        view.entityPrimaryKey = model.entityPrimaryKey;
        view.languageCode = model.languageCode;
        view.languageName = model.languageName;
        view.languageTranslationId = model.languageTranslationId
        view.translation = model.translation;
        return view;
    }

    public toModels(views: PriceRuleView[]): PriceRuleModel[] {
        let models = new Array<PriceRuleModel>();
        for (let view of views) {
            models.push(this.toModel(view));
        }
        return models;
    }

    public toView(model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[],
        productTypeGroups: ProductTypeGroupModel[]): PriceRuleView {
        let view = new PriceRuleView();
        view.priceRuleId = model.id;
        view.parentPriceRuleId = model.parentPriceRuleId;
        view.priceRuleCode = model.priceRuleCode;
        view.priceRuleName = model.priceRuleName;
        view.priceRuleConcatenate = model.priceRuleConcatenateFlag
        view.statusCode = model.statusCode;
        view.priceRuleTypeCode = model.priceRuleTypeCode;
        view.priceRuleGroupCode = model.priceRuleGroupCode;
        view.priceRuleCategoryCode = model.priceRuleCategoryCode;
        view.priceRuleDisplayCode = model.priceRuleDisplayCode;
        view.priceRuleDisplayName = model.priceRuleDisplayName;
        view.priceRuleTypeGroupName = model.priceRuleTypeGroupName;
        view.usageTypeCode = model.usageTypeCode;
        view.searchName = model.searchName;
        view.searchStatusCode = model.searchStatusCode;
        view.searchUsageTypeCode = model.searchUsageTypeCode;
        view.isOwnerFilter = model.isOwnerFilter;
        view.ownerFilterUserAccountId = model.ownerFilterUserAccountId;
        view.commitDateTime = this.dateConverter.convertDateTime(model.commitDateTime);
        view.commitBy = model.commitBy;
        view.languageTranslationSet = this.toLanguageTranslationSetView(model.languageTranslationSet);
        view.productEligibleData = this.toProductEligibleData(model, domainAttributes);
        view.pointOfSalesData = this.toPointOfSalesData(model);
        view.serviceCategoriesSalesBucketsData = this.toServiceCategoriesSalesBucketData(model);
        view.parentServiceCategoriesSalesBucketsData = this.toParentServiceCategoriesSalesBucketData(model);
        view.roundtripDefinitionData = this.toRoundtripDefinitionData(model, domainAttributes);
        view.attributeData = this.toAttributeData(model, domainAttributes);
        view.productAttributeData = this.toProductAttributeData(model, domainAttributes);
        view.customerData = this.toCustomerData(model, domainAttributes);
        view.transitStopoverData = this.toTransitStopoverData(model, domainAttributes)
        view.productOptions = this.toProductOptionViews(model);
        view.priceRulePrices = this.priceConverter.toPriceRulePriceViews(model.priceRulePrices);
        view.formOfPaymentData = this.toFormOfPaymentData(model);
        view.baggageAllowances = this.toBaggageAllowances(model, domainAttributes);
        if (model.children?.length) {
            let children = new Array<PriceRuleView>();
            for (let child of model.children) {
                children.push(this.toView(child, domainAttributes, productTypeGroups));
            }
            view.children = children;
        }
        return view;
    }

    private toBaggageAllowances(model: PriceRuleModel, domainAttributes: DomainAttributeModel[]): PriceRuleAttributeView[] {
        let attributes = new Array<PriceRuleAttributeView>();
        if (!model?.attributes?.length) {
            return [];
        }
        let domainAttributeBaggages = domainAttributes?.filter(da => da.attributeGroupCode == PricingDetailConstant.BAGGAGE_CODE);
        for (let attr of model.attributes.sort(a => a.displaySequence)) {
            if (domainAttributeBaggages.some(da => da.attributeTypeCode == attr.attributeTypeCode) == false) {
                continue;
            }
            let attribute = new PriceRuleAttributeView();
            attribute.priceRuleId = model.id;
            attribute.attributeChoiceId = attr.attributeChoiceId;
            attribute.attributeTypeCode = attr.attributeTypeCode;
            attribute.attributeValue = attr.attributeValue ? attr.attributeValue + '' : null;
            attribute.conditionCode = attr.conditionCode;
            attributes.push(attribute);
        }
        return attributes;
    }

    private toProductEligibleData(model: PriceRuleModel, domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        let productEligibleData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toProductNumbers(model.productNumbers, productEligibleData);
        this.toRoutes(model.routes, productEligibleData);
        this.toLocations(PricingDetailConstant.LOCATION_CODE, model.locations?.filter(l => l.priceRuleLocationTypeCode == PricingDetailConstant.SERVICE_CODE),
            productEligibleData);
        this.toValidities(model.validities?.filter(v => v.priceRuleAttributeGroupCode == PricingDetailConstant.PRODUCTRESTRICTED_CODE),
            productEligibleData);
        this.toVehicles(model.vehicles, productEligibleData);
        this.toProductEligibleAttributes(productEligibleData, model, domainAttributes);
        this.toProducts(model.products, productEligibleData);

        productEligibleData = productEligibleData.filter(row => row != null);
        return productEligibleData;
    }

    private toProductEligibleAttributes(productEligibleData: PriceRuleAttributeAndRuleRowView[],
        model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[]) {
        if (model.attributes?.length) {
            let productEligibleAttributeTypeIds = domainAttributes.filter(attr => attr.attributeGroupCode == PricingDetailConstant.PRODUCTRESTRICTED_CODE);
            let productEligibleAttributes = model.attributes?.filter(a => productEligibleAttributeTypeIds.filter(attr => attr.attributeTypeCode == a.attributeTypeCode)?.length > 0);
            if (productEligibleAttributes?.length) {
                let attributeTypeCodes = productEligibleAttributes
                    .map(a => productEligibleAttributeTypeIds
                        .filter(attr => attr.attributeTypeCode == a.attributeTypeCode)[0].attributeTypeCode);
                this.toAttributes(
                    attributeTypeCodes,
                    productEligibleAttributes,
                    productEligibleData
                );
            }
        }
    }

    private toPointOfSalesData(model: PriceRuleModel): PriceRuleAttributeAndRuleRowView[] {
        let pointOfSalesData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toLocations(PricingDetailConstant.LOCATION_CODE, model.locations?.filter(l => l.priceRuleLocationTypeCode == PricingDetailConstant.SALES_CODE),
            pointOfSalesData);
        this.toOrganisations(model.organisations?.filter(org => org.priceRuleOrganisationTypeCode == PricingDetailConstant.SALES_CODE), pointOfSalesData);
        this.toValidities(model.validities?.filter(v => v.priceRuleAttributeGroupCode == PricingDetailConstant.POINTOFSALES_CODE),
            pointOfSalesData);

        pointOfSalesData = pointOfSalesData.filter(row => row != null);
        return pointOfSalesData;
    }

    private toFormOfPaymentData(model: PriceRuleModel): PriceRuleAttributeAndRuleRowView[] {
        let formOfPaymentData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toFormOfPayments(model.formOfPayments, formOfPaymentData);
        formOfPaymentData = formOfPaymentData.filter(row => row != null);
        return formOfPaymentData;
    }

    private toServiceCategoriesSalesBucketData(model: PriceRuleModel): PriceRuleAttributeAndRuleRowView[] {
        let serviceCategoriesSalesBucketData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toServiceCategories(
            model.serviceCategories?.filter(sc => sc.priceRuleAttributeGroupCode == PricingDetailConstant.SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE),
            serviceCategoriesSalesBucketData);
        this.toSalesBuckets(
            model.salesBuckets?.filter(sb => sb.priceRuleAttributeGroupCode == PricingDetailConstant.SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE),
            serviceCategoriesSalesBucketData);

        serviceCategoriesSalesBucketData = serviceCategoriesSalesBucketData.filter(row => row != null);
        return serviceCategoriesSalesBucketData;
    }

    private toParentServiceCategoriesSalesBucketData(model: PriceRuleModel): PriceRuleAttributeAndRuleRowView[] {
        let parentServiceCategoriesSalesBucketData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toServiceCategories(
            model.serviceCategories?.filter(sc => sc.priceRuleAttributeGroupCode == PricingDetailConstant.PARENT_SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE),
            parentServiceCategoriesSalesBucketData);
        this.toSalesBuckets(
            model.salesBuckets?.filter(sb => sb.priceRuleAttributeGroupCode == PricingDetailConstant.PARENT_SERVICE_CATEGORY_PRICE_RULE_GROUP_CODE),
            parentServiceCategoriesSalesBucketData);

        parentServiceCategoriesSalesBucketData = parentServiceCategoriesSalesBucketData.filter(row => row != null);
        return parentServiceCategoriesSalesBucketData;
    }

    private toRoundtripDefinitionData(model: PriceRuleModel, domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        let roundtripDefinitionData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toServiceCategories(
            model.serviceCategories?.filter(sc => sc.priceRuleAttributeGroupCode == PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE),
            roundtripDefinitionData);
        this.toSalesBuckets(
            model.salesBuckets?.filter(sb => sb.priceRuleAttributeGroupCode == PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE),
            roundtripDefinitionData);
        this.toValidities(
            model.validities?.filter(v => v.priceRuleAttributeGroupCode == PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE),
            roundtripDefinitionData);
        this.toRoundtripDefinitionAttributes(roundtripDefinitionData, model, domainAttributes);

        roundtripDefinitionData = roundtripDefinitionData.filter(row => row != null);
        return roundtripDefinitionData;
    }

    private toRoundtripDefinitionAttributes(roundtripDefinitionData: PriceRuleAttributeAndRuleRowView[],
        model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[]) {
        if (model.attributes?.length) {
            let roundtripDefAttributeTypeIds = domainAttributes.filter(attr => attr.attributeGroupCode == PricingDetailConstant.ROUNDTRIP_DEFINITION_CODE);
            let roundtripDefAttributes = model.attributes?.filter(a => roundtripDefAttributeTypeIds.filter(attr => attr.attributeTypeCode == a.attributeTypeCode)?.length > 0);
            if (roundtripDefAttributes?.length) {
                let attributeTypeCodes = roundtripDefAttributes
                    .map(a => roundtripDefAttributeTypeIds
                        .filter(attr => attr.attributeTypeCode == a.attributeTypeCode)[0].attributeTypeCode);
                this.toAttributes(
                    attributeTypeCodes,
                    roundtripDefAttributes,
                    roundtripDefinitionData
                );
            }
        }
    }

    private toAttributeData(model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        let attributeData = this.toPriceRuleAttributeViews(model, domainAttributes, PricingDetailConstant.ATTRIBUTE_CODE);
        return attributeData.filter(row => row != null);
    }

    private toPriceRuleAttributeViews(model: PriceRuleModel, domainAttributes: DomainAttributeModel[], priceRuleAttributeGroupCode: string): PriceRuleAttributeAndRuleRowView[] {
        let attributeData = new Array<PriceRuleAttributeAndRuleRowView>();
        if (model.attributes?.length) {
            let AttributeAttributeTypeIds = domainAttributes.filter(attr => attr.attributeGroupCode == priceRuleAttributeGroupCode);
            let AttributeAttributes = model.attributes?.filter(a => AttributeAttributeTypeIds.filter(attr => attr.attributeTypeCode == a.attributeTypeCode)?.length > 0);
            if (AttributeAttributes?.length) {
                let attributeTypeCodes = AttributeAttributes
                    .map(a => AttributeAttributeTypeIds
                        .filter(attr => attr.attributeTypeCode == a.attributeTypeCode)[0].attributeTypeCode);
                this.toAttributes(
                    attributeTypeCodes,
                    AttributeAttributes,
                    attributeData
                );
            }
        }
        return attributeData.filter(row => row != null);
    }

    private toProductAttributeData(model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        return this.toPriceRuleAttributeViews(model, domainAttributes, PricingDetailConstant.PRODUCT_ATTRIBUTE_CODE);
    }

    private toTransitStopoverData(model: PriceRuleModel,
        domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        return this.toPriceRuleAttributeViews(model, domainAttributes, PricingDetailConstant.TRANSIT_STOPOVER_CODE);
    }

    private toProductOptionViews(model: PriceRuleModel): PriceRuleProductOptionView[] {
        if (!model?.productOptions?.length) {
            return [];
        }
        let views = new Array<PriceRuleProductOptionView>();
        for (let productOption of model.productOptions) {
            let view: PriceRuleProductOptionView = {
                priceRuleProductOptionId: productOption.priceRuleProductOptionId,
                priceRuleId: productOption.priceRuleId,
                priceId: productOption.priceId,
                numberOfUnits: productOption.numberOfUnits,
                productCategoryCode: productOption.productCategoryCode,
                productGroupCode: productOption.productGroupCode,
                productId: productOption.productId,
                productTypeCode: productOption.productTypeCode,
                unitCode: productOption.unitCode,
                displaySequence: productOption.displaySequence
            };
            views.push(view);
        }
        return views;
    }

    private toCustomerData(model: PriceRuleModel, domainAttributes: DomainAttributeModel[]): PriceRuleAttributeAndRuleRowView[] {
        let customerData = new Array<PriceRuleAttributeAndRuleRowView>();
        this.toLocations(PricingDetailConstant.INDIVIDUAL_CODE, model.locations?.filter(l => l.priceRuleLocationTypeCode == PricingDetailConstant.INDIVIDUAL_CODE), customerData);
        this.toValidities(model.validities?.filter(v => v.priceRuleAttributeGroupCode == PricingDetailConstant.CUSTOMER_CODE), customerData);
        this.toOrganisations(model.organisations?.filter(org => org.priceRuleOrganisationTypeCode == PricingDetailConstant.CUSTOMER_CODE), customerData);
        this.toMemberships(model.memberships, customerData);
        this.toIndividuals(model.individuals, customerData);
        if (model.attributes?.length) {
            let customerAttributeTypeIds = domainAttributes.filter(attr => attr.attributeGroupCode == PricingDetailConstant.CUSTOMER_CODE);
            let customerAttributes = model.attributes?.filter(a => customerAttributeTypeIds.filter(attr => attr.attributeTypeCode == a.attributeTypeCode)?.length > 0);
            if (customerAttributes?.length) {
                let attributeTypeCodes = customerAttributes
                    .map(a => customerAttributeTypeIds
                        .filter(attr => attr.attributeTypeCode == a.attributeTypeCode)[0].attributeTypeCode);
                this.toAttributes(
                    attributeTypeCodes,
                    customerAttributes,
                    customerData
                );
            }
        }
        customerData = customerData.filter(row => row != null);
        return customerData;
    }

    private productEligibleViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.productNumbers = model.productNumbers ?? [];
        model.routes = model.routes ?? [];
        model.locations = model.locations ?? [];
        model.validities = model.validities ?? [];
        model.vehicles = model.vehicles ?? [];
        model.attributes = model.attributes ?? [];
        model.products = model.products ?? [];

        model.productNumbers = model.productNumbers.concat(this.toPriceRuleProductNumberModels(views));
        model.routes = model.routes.concat(this.toPriceRuleRouteModels(views));
        model.locations = model.locations.concat(this.toPriceRuleLocationModels(views))
        model.validities = model.validities.concat(this.toPriceRuleValidityModels(views))
        model.vehicles = model.vehicles.concat(this.toPriceRuleVehicleModels(views))
        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views))
        model.products = model.products.concat(this.toPriceRuleProductModels(views))
    }

    private pointOfSalesViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.locations = model.locations ?? [];
        model.validities = model.validities ?? [];
        model.organisations = model.organisations ?? [];

        model.locations = model.locations.concat(this.toPriceRuleLocationModels(views))
        model.validities = model.validities.concat(this.toPriceRuleValidityModels(views))
        model.organisations = model.organisations.concat(this.toPriceRuleOrganisationModels(views))
    }

    private serviceCategoriesSalesBucketsViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.serviceCategories = model.serviceCategories ?? [];
        model.salesBuckets = model.salesBuckets ?? [];

        model.serviceCategories = model.serviceCategories.concat(this.toPriceRuleServiceCategoryModels(views))
        model.salesBuckets = model.salesBuckets.concat(this.toPriceRuleSalesBucketModels(views))
    }

    private parentServiceCategoriesSalesBucketsViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.serviceCategories = model.serviceCategories ?? [];
        model.salesBuckets = model.salesBuckets ?? [];

        model.serviceCategories = model.serviceCategories.concat(this.toPriceRuleServiceCategoryModels(views))
        model.salesBuckets = model.salesBuckets.concat(this.toPriceRuleSalesBucketModels(views))
    }

    private roundtripDefinitionViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.serviceCategories = model.serviceCategories ?? [];
        model.salesBuckets = model.salesBuckets ?? [];
        model.attributes = model.attributes ?? [];
        model.validities = model.validities ?? [];

        model.serviceCategories = model.serviceCategories.concat(this.toPriceRuleServiceCategoryModels(views))
        model.salesBuckets = model.salesBuckets.concat(this.toPriceRuleSalesBucketModels(views))
        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views))
        model.validities = model.validities.concat(this.toPriceRuleValidityModels(views))
    }

    private attributeViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.attributes = model.attributes ?? [];
        model.products = model.products ?? [];

        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views));
        model.products = model.products.concat(this.toAttributePriceRuleProductModels(views));
    }

    private productAttributeViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.attributes = model.attributes ?? [];

        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views));
    }

    private transitStopoverViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.attributes = model.attributes ?? [];

        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views));
    }

    private toProductOptionModels(view: PriceRuleView): PriceRuleProductOptionModel[] {
        if (!view?.productOptions?.length) {
            return [];
        }
        let models = new Array<PriceRuleProductOptionModel>();
        for (let productOption of view.productOptions) {
            let model: PriceRuleProductOptionModel = {
                priceRuleProductOptionId: productOption.priceRuleProductOptionId,
                priceRuleId: productOption.priceRuleId,
                priceId: productOption.priceId,
                numberOfUnits: productOption.numberOfUnits,
                productCategoryCode: productOption.productCategoryCode,
                productGroupCode: productOption.productGroupCode,
                productId: productOption.productId,
                productTypeCode: productOption.productTypeCode,
                unitCode: productOption.unitCode,
                displaySequence: productOption.displaySequence
            };
            models.push(model);
        }
        return models;
    }

    private customerViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.attributes = model.attributes ?? [];
        model.organisations = model.organisations ?? [];
        model.locations = model.locations ?? [];
        model.memberships = model.memberships ?? [];
        model.individuals = model.individuals ?? [];
        model.validities = model.validities ?? [];

        model.attributes = model.attributes.concat(this.toPriceRuleAttributeModels(views));
        model.organisations = model.organisations.concat(this.toPriceRuleOrganisationModels(views));
        model.locations = model.locations.concat(this.toPriceRuleLocationModels(views));
        model.memberships = model.memberships.concat(this.toPriceRuleMembershipModels(views));
        model.individuals = model.individuals.concat(this.toPriceRuleIndividualModels(views));
        model.validities = model.validities.concat(this.toPriceRuleValidityModels(views));
    }

    private formOfPaymentViewToModel(model: PriceRuleModel, views: PriceRuleAttributeAndRuleRowView[]) {
        model.formOfPayments = model.formOfPayments ?? [];
        model.formOfPayments = model.formOfPayments.concat(this.toPriceRuleFormOfPaymentModels(views));
    }



    public toViews(models: PriceRuleModel[],
        domainAttributes: DomainAttributeModel[],
        productTypeGroups: ProductTypeGroupModel[]): PriceRuleView[] {
        let views = new Array<PriceRuleView>();
        for (let model of models) {
            views.push(this.toView(model, domainAttributes, productTypeGroups));
        }
        return views;
    }

    public toTableView(model: PriceRuleModel): SearchTableView {
        let view = new SearchTableView();
        view.id = model.id;
        view.code = model.priceRuleCode;
        view.name = model.priceRuleName;
        view.categoryCode = model.priceRuleGroupCode;
        view.priceRuleTypeGroupName = model.priceRuleTypeGroupName;
        view.commitBy = model.commitBy;
        view.commitDateTime = moment(model.commitDateTime).format(this.dateTimeFormat);
        view.status = model.statusCode;
        return view;
    }

    public toTableViews(models: PriceRuleModel[]): SearchTableView[] {
        let views = new Array<SearchTableView>();
        let no = 1;
        for (let model of models) {
            let view = this.toTableView(model);
            view.no = no++;
            views.push(view);
        }
        return views;
    }

    public toPriceRuleProductNumberModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleProductNumberModel[] {
        let models = new Array<PriceRuleProductNumberModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.type == PricingDetailConstant.PRODUCT_NUMBER_CODE && !row.isInherit) {
                let view = row.productNumber;
                let model = new PriceRuleProductNumberModel();
                model.displaySequence = i + 1;
                model.excludeFlag = view.excludeFlag;
                model.productNumber = view.productNumber;
                model.productNumberTypeCode = view.productNumberTypeCode;
                model.providerId = view.providerId;
                models.push(model);
            }
        }
        return models;
    }

    public toPriceRuleRouteModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleRouteModel[] {
        let models = new Array<PriceRuleRouteModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.type == PricingDetailConstant.ROUTE_CODE && !row.isInherit) {
                let view = row.route;
                let model = new PriceRuleRouteModel();
                model.displaySequence = i + 1;
                model.excludeFlag = view.excludeFlag;
                model.originLocationId = view.originLocationId;
                model.destinationLocationId = view.destinationLocationId;
                models.push(model);
            }
        }
        return models;
    }

    public toPriceRuleLocationModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleLocationModel[] {
        let models = new Array<PriceRuleLocationModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.location && !row.isInherit) {
                let view = row.location;
                let model = new PriceRuleLocationModel();
                model.displaySequence = i + 1;
                model.excludeFlag = view.excludeFlag;
                model.priceRuleLocationPointCode = view.priceRuleLocationPointCode;
                model.priceRuleLocationTypeCode = view.priceRuleLocationTypeCode;
                if (view.regionCode) {
                    if (Array.isArray(view.regionCode)) {
                        for (let regionCode of view.regionCode) {
                            let m = this.helper.clone(model);
                            m.regionCode = regionCode;
                            models.push(m);
                        }
                    } else {
                        model.regionCode = view.regionCode;
                        models.push(model);
                    }
                    continue;
                }
                if (view.countryCode) {
                    if (Array.isArray(view.countryCode)) {
                        for (let countryCode of view.countryCode) {
                            let m = this.helper.clone(model);
                            m.countryCode = countryCode;
                            models.push(m);
                        }
                    } else {
                        model.countryCode = view.countryCode;
                        models.push(model);
                    }
                    continue;
                }
                if (view.locationId) {
                    if (Array.isArray(view.locationId)) {
                        for (let locationId of view.locationId) {
                            let m = this.helper.clone(model);
                            m.locationId = locationId;
                            models.push(m);
                        }
                    } else {
                        model.locationId = view.locationId;
                        models.push(model);
                    }
                    continue;
                }
                if (view.locationGroupId) {
                    if (Array.isArray(view.locationGroupId)) {
                        for (let locationGroupId of view.locationGroupId) {
                            let m = this.helper.clone(model);
                            m.locationGroupId = locationGroupId;
                            models.push(m);
                        }
                    } else {
                        model.locationGroupId = view.locationGroupId;
                        models.push(model);
                    }
                    continue;
                }
            }
        }
        return models;
    }

    public toPriceRuleMembershipModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleMembershipModel[] {
        let models = new Array<PriceRuleMembershipModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.membership && !row.isInherit) {
                let view = row.membership;
                let model = new PriceRuleMembershipModel();
                model.excludeFlag = view.excludeFlag;
                model.displaySequence = (i + 1);
                models = models.concat(view.membershipLevelCode.map(id => {
                    let m = this.helper.clone(model);
                    m.membershipLevelCode = id;
                    return m;
                }));
            }
        }
        return models;
    }

    public toPriceRuleFormOfPaymentModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleFormOfPaymentModel[] {
        let models = new Array<PriceRuleFormOfPaymentModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row?.formOfPayment && !row.isInherit) {
                let view = row.formOfPayment;
                models.push({
                    priceRuleFormOfPaymentId: view.priceRuleFormOfPaymentId,
                    formOfPaymentCode: view.formOfPaymentCode,
                    formOfPaymentIssuerCode: view.formOfPaymentIssuerCode,
                    formOfPaymentTypeCode: view.formOfPaymentTypeCode,
                    priceRuleId: view.priceRuleId,
                    displaySequence: i + 1,
                    excludeFlag: view.excludeFlag,
                    priceId: view.priceId
                });
            }
        }
        return models;
    }

    public toPriceRuleIndividualModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleIndividualModel[] {
        let models = new Array<PriceRuleIndividualModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.individual && !row.isInherit) {
                let view = row.individual;
                let model = new PriceRuleIndividualModel();
                model.excludeFlag = view.excludeFlag;
                model.displaySequence = (i + 1);
                if (view.nationalityCode?.length) {
                    models = models.concat(view.nationalityCode.map(nc => {
                        let m = this.helper.clone(model);
                        m.nationalityCode = nc;
                        return m;
                    }))
                } else if (view.individualAgeGroupCode?.length) {
                    models = models.concat(view.individualAgeGroupCode.map(id => {
                        let m = this.helper.clone(model);
                        m.individualAgeGroupCode = id;
                        return m;
                    }))
                } else if (view.individualCivilStatusTypeCode?.length) {
                    models = models.concat(view.individualCivilStatusTypeCode.map(id => {
                        let m = this.helper.clone(model);
                        m.individualCivilStatusTypeCode = id;
                        return m;
                    }))
                } else if (view.genderCode?.length) {
                    models = models.concat(view.genderCode.map(code => {
                        let m = this.helper.clone(model);
                        m.genderCode = code;
                        return m;
                    }))
                } else if (view.individualInterestTypeCode?.length) {
                    models = models.concat(view.individualInterestTypeCode.map(id => {
                        let m = this.helper.clone(model);
                        m.individualInterestTypeCode = id;
                        return m;
                    }))
                }
            }
        }
        return models;
    }

    public toPriceRuleOrganisationModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleOrganisationModel[] {
        let models = new Array<PriceRuleOrganisationModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.organisation && !row.isInherit) {
                let view = row.organisation;
                let model = new PriceRuleOrganisationModel();
                model.displaySequence = (i + 1);
                model.excludeFlag = view.excludeFlag;
                model.priceRuleOrganisationId = view.priceRuleOrganisationId;
                model.priceRuleOrganisationTypeCode = view.priceRuleOrganisationTypeCode;
                if (view.organisationId) {
                    if (Array.isArray(view.organisationId)) {
                        for (let organisationId of view.organisationId) {
                            let m = this.helper.clone(model)
                            m.organisationId = organisationId;
                            models.push(m);
                        }
                    } else {
                        model.organisationId = view.organisationId;
                        models.push(model)
                    }
                    continue;
                }
                if (view.organisationTypeCode) {
                    if (Array.isArray(view.organisationTypeCode)) {
                        for (let organisationTypeCode of view.organisationTypeCode) {
                            let m = this.helper.clone(model)
                            m.organisationTypeCode = organisationTypeCode;
                            models.push(m);
                        }
                    } else {
                        model.organisationTypeCode = view.organisationTypeCode;
                        models.push(model)
                    }
                    continue;
                }
                if (view.organisationRoleCode) {
                    if (Array.isArray(view.organisationRoleCode)) {
                        for (let organisationRoleCode of view.organisationRoleCode) {
                            let m = this.helper.clone(model)
                            m.organisationRoleCode = organisationRoleCode;
                            models.push(m);
                        }
                    } else {
                        model.organisationRoleCode = view.organisationRoleCode;
                        models.push(model)
                    }
                    continue;
                }
                if (view.organisationGroupCode) {
                    if (Array.isArray(view.organisationGroupCode)) {
                        for (let organisationGroupCode of view.organisationGroupCode) {
                            let m = this.helper.clone(model)
                            m.organisationGroupCode = organisationGroupCode;
                            models.push(m);
                        }
                    } else {
                        model.organisationGroupCode = view.organisationGroupCode;
                        models.push(model)
                    }
                    continue;
                }
                models.push(model);
            }
        }
        return models;
    }

    public toPriceRuleValidityModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleValidityModel[] {
        let models = new Array<PriceRuleValidityModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.validity && !row.isInherit) {
                let view = row.validity;
                let model = new PriceRuleValidityModel();
                model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
                model.dateTimeDimensionCode = view.dateTimeDimensionCode;
                model.endValue = view.endValue ? parseInt(view.endValue) : 0;
                model.utcFlag = view.utcFlag;
                model.conditionCode = view.conditionCode;
                model.startDateTime = this.convertToDate(view.startDateTime);
                model.endDateTime = this.convertToDate(view.endDateTime);
                model.calendarValidityCode = view.calendarValidityCode;
                model.calendarValidityPrefixCode = view.calendarValidityPrefixCode;
                model.minutesStartValue = view.minutesStartValue ? Number(view.minutesStartValue) : 0;
                model.minutesEndValue = view.minutesEndValue ? Number(view.minutesEndValue) : 0;
                model.hoursStartValue = view.hoursStartValue ? Number(view.hoursStartValue) : 0;
                model.hoursEndValue = view.hoursEndValue ? Number(view.hoursEndValue) : 0;
                model.displaySequence = (i + 1);
                this.assignWeekdayFlag(view.weekdays, model);
                this.assignMonthFlag(view.months, model);
                if (view.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_HOUR) {
                    model.startValue = 0;
                    models.push(model);
                } else if (Array.isArray(view.startValue)) {
                    if (view.startValue?.length) {
                        models = models.concat(view.startValue.map(val => {
                            let m = this.helper.clone(model);
                            m.startValue = val ? parseInt(val) : 0;
                            return m;
                        }))
                    } else {
                        model.startValue = 0;
                        models.push(model);
                    }
                } else if (view.startValue) {
                    model.startValue = parseInt(view.startValue);
                    models.push(model);
                } else {
                    model.startValue = 0;
                    models.push(model);
                }
            }
        }
        return models;
    }

    public toPriceRuleAttributeModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleAttributeModel[] {
        let models = new Array<PriceRuleAttributeModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.attribute && !row.isInherit) {
                let view = row.attribute;
                
                if (view?.multipleChoiceFlag && view.attributeMultiChoiceIds?.length) {
                    models = models.concat(this.toPriceRuleAttributeModelForMultiChoices(view, i));
                    continue;
                }
                
                models.push(this.toPriceRuleAttributeModel(view, i));
            }
        }
        
        return models;
    }

    private toPriceRuleAttributeModelForMultiChoices(row: PriceRuleAttributeView, index: number): PriceRuleAttributeModel[] {
        let models = new Array<PriceRuleAttributeModel>();
        let model = new PriceRuleAttributeModel();

        for (let choice of row.attributeMultiChoiceIds) {
            model = this.toPriceRuleAttributeModel(row, index);
            model.attributeChoiceId = choice;
            models.push(model);
        }

        return models;
    }

    private toPriceRuleAttributeModel(row: PriceRuleAttributeView, index: number): PriceRuleAttributeModel {
        let model = new PriceRuleAttributeModel();

        model.conditionCode = row.conditionCode;
        model.attributeTypeCode = row.attributeTypeCode;
        model.attributeValue = row.attributeValue ? parseFloat(row.attributeValue) : 0;
        model.attributeDateTime = row.attributeTime ? this.convertTimeToMinDateWithTime(row.attributeTime) : row.attributeDateTime;
        model.attributeText = row.attributeText;
        model.attributeChoiceId = row.attributeChoiceId?.toString();
        model.displaySequence = (index + 1);

        return model;
    }

    private convertTimeToMinDateWithTime(time: string): string {
        return this.dateConverter.convertMinDateWithTime(time);
    }

    public toAttributePriceRuleProductModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleProductModel[] {
        let models = new Array<PriceRuleProductModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.attribute && row.attribute?.products?.length && !row.isInherit) {
                let products = row.attribute.products;
                for (let j = 0; j < products.length; j++) {
                    let view = products[j];
                    let model = new PriceRuleProductModel();
                    model.excludeFlag = view.excludeFlag;
                    model.priceRuleProductId = view.priceRuleProductId;
                    model.productCategoryCode = view.productCategoryCode;
                    model.productGroupCode = view.productGroupCode;
                    model.productId = view.productId;
                    model.productTypeCode = view.productTypeCode;
                    model.displaySequence = (i + 1);
                    models.push(model);
                }
            }
        }
        return models;
    }

    public toPriceRuleProductModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleProductModel[] {
        let models = new Array<PriceRuleProductModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.product && !row.isInherit) {
                let view = row.product;
                let model = new PriceRuleProductModel();
                model.excludeFlag = view.excludeFlag;
                model.priceRuleProductId = view.priceRuleProductId;
                model.productCategoryCode = view.productCategoryCode;
                model.productGroupCode = view.productGroupCode;
                model.productId = view.productId;
                model.productTypeCode = view.productTypeCode;
                model.displaySequence = (i + 1);
                models.push(model);
            }
        }
        return models;
    }

    public toPriceRuleProductModel(view: PriceRuleProductView): PriceRuleProductModel {
        let model = new PriceRuleProductModel();
        model.excludeFlag = view.excludeFlag;
        model.priceRuleProductId = view.priceRuleProductId;
        model.productCategoryCode = view.productCategoryCode;
        model.productGroupCode = view.productGroupCode;
        model.productId = view.productId;
        model.productTypeCode = view.productTypeCode;
        return model;
    }

    public toPriceRuleVehicleModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleVehicleModel[] {
        let models = new Array<PriceRuleVehicleModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.vehicle && !row.isInherit) {
                let view = row.vehicle;
                let model = new PriceRuleVehicleModel();
                model.excludeFlag = view.excludeFlag;
                model.vehicleCompositionId = view.vehicleCompositionId;
                model.vehicleGroupCode = view.vehicleGroupCode;
                model.vehicleTypeCode = view.vehicleTypeCode;
                model.displaySequence = (i + 1);
                models.push(model);
            }
        }
        return models;
    }

    public toPriceRuleServiceCategoryModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleServiceCategoryModel[] {
        let models = new Array<PriceRuleServiceCategoryModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.serviceCategory && !row.isInherit) {
                let view = row.serviceCategory;
                let model = this.toPriceRuleServiceCategoryModel(view, i);
                if (view.children?.length) {
                    let modelChildren = new Array<PriceRuleServiceCategoryModel>();
                    for (let j = 0; j < view.children.length; j++) {
                        let viewChild = view.children[j];
                        let modelChild = this.toPriceRuleServiceCategoryModel(viewChild, j);
                        modelChildren.push(modelChild);
                    }
                    model.children = modelChildren;
                }
                models.push(model);
            }
        }
        return models;
    }

    private toPriceRuleServiceCategoryModel(view: PriceRuleServiceCategoryView, index: number) {
        let model = new PriceRuleServiceCategoryModel();
        model.excludeFlag = view.excludeFlag;
        model.conditionCode = view.conditionCode;
        model.dimensionUnitCode = view.dimensionUnitCode;
        model.inventoryDimensionCode = view.inventoryDimensionCode;
        model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
        model.serviceCategoryCode = view.serviceCategoryCode;
        model.thresholdValue = view.thresholdValue ? parseFloat(view.thresholdValue) : 0;
        model.displaySequence = (index + 1);
        return model;
    }

    public toPriceRuleSalesBucketModels(rows: PriceRuleAttributeAndRuleRowView[]): PriceRuleSalesBucketModel[] {
        let models = new Array<PriceRuleSalesBucketModel>();
        for (let i = 0; i < rows?.length; i++) {
            let row = rows[i];
            if (row.salesBucket && !row.isInherit) {
                let view = row.salesBucket;
                let model = this.toPriceRuleSalesBucketModel(view, i);
                if (view.children?.length) {
                    let modelChildren = new Array<PriceRuleSalesBucketModel>();
                    for (let j = 0; j < view.children.length; j++) {
                        let viewChild = view.children[j];
                        let modelChild = this.toPriceRuleSalesBucketModel(viewChild, j);
                        modelChildren.push(modelChild);
                    }
                    model.children = modelChildren;
                }
                models.push(model);
            }
        }
        return models;
    }

    private toPriceRuleSalesBucketModel(view: PriceRuleSalesBucketView, index: number) {
        let model = new PriceRuleSalesBucketModel();
        model.excludeFlag = view.excludeFlag;
        model.conditionCode = view.conditionCode;
        model.dimensionUnitCode = view.dimensionUnitCode;
        model.inventoryDimensionCode = view.inventoryDimensionCode;
        model.priceRuleAttributeGroupCode = view.priceRuleAttributeGroupCode;
        model.salesBucketCode = view.salesBucketCode;
        model.thresholdValue = view.thresholdValue ? parseFloat(view.thresholdValue) : 0;
        model.displaySequence = (index + 1);
        return model;
    }

    private assignWeekdayFlag(weekdayValues: string[], model: PriceRuleValidityModel) {
        if (!weekdayValues?.length) {
            return;
        }
        for (let val of weekdayValues) {
            switch (val) {
                case 'MONDAY':
                    model.mondayFlag = true;
                    break;
                case 'TUESDAY':
                    model.tuesdayFlag = true;
                    break;
                case 'WEDNESDAY':
                    model.wednesdayFlag = true;
                    break;
                case 'THURSDAY':
                    model.thursdayFlag = true;
                    break;
                case 'FRIDAY':
                    model.fridayFlag = true;
                    break;
                case 'SATURDAY':
                    model.saturdayFlag = true;
                    break;
                case 'SUNDAY':
                    model.sundayFlag = true;
                    break;
            }
        }
    }
    private assignMonthFlag(monthValues: string[], model: PriceRuleValidityModel) {
        if (!monthValues?.length) {
            return;
        }
        if (!Array.isArray(monthValues)) {
            monthValues = [monthValues];
        }
        const postfix = 'Flag';
        for (let val of monthValues) {
            let monthNameLowercase = val.toLowerCase();
            let monthFlagPropertyName = monthNameLowercase + postfix;
            model[monthFlagPropertyName] = true;
        }
    }
    public convertToDate(dateTimeString: string): Date {
        if (moment(dateTimeString, this.yearMonthDateFormat, true).isValid()) {
            return moment.utc(dateTimeString + ' 00:00', this.dateTimeFormat).toDate()
        } else if (moment(dateTimeString, this.dateMonthYearFormat, true).isValid()) {
            return moment.utc(dateTimeString, this.dateMonthYearFormat).toDate()
        } else if (moment(dateTimeString, this.timeFormat, true).isValid()) {
            return moment.utc(this.sqlDateTimeMinValue + ' ' + dateTimeString, this.dateTimeFormat).toDate()
        } else if (moment(dateTimeString, this.dateTimeFormat, true).isValid()) {
            return moment.utc(dateTimeString, this.dateTimeFormat).toDate()
        } else {
            return null;
        }
    }

    public toProductNumbers(productNumbers: PriceRuleProductNumberModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let productNumber of productNumbers ?? []) {
            let view = this.toPriceRuleProductNumberView(productNumber);
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.PRODUCT_NUMBER_CODE;
            row.productNumber = view;
            this.addViewToSpecificSequence(productNumber.displaySequence, row, rows);
        }
    }

    public toProducts(products: PriceRuleProductModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let product of products ?? []) {
            let view = this.toPriceRuleProductView(product);
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.PRODUCT_TYPE_CODE;
            row.product = view;
            this.addViewToSpecificSequence(product.displaySequence, row, rows);
        }
    }

    public toRoutes(routes: PriceRuleRouteModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        for (let route of routes ?? []) {
            let view = this.toPriceRuleRouteView(route);
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.ROUTE_CODE;
            row.route = view;
            this.addViewToSpecificSequence(route.displaySequence, row, views);
        }
    }

    public toFormOfPayments(formOfPayments: PriceRuleFormOfPaymentModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        for (let fop of formOfPayments ?? []) {
            let view = this.toPriceRuleFormOfPaymentView(fop);
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.FORM_OF_PAYMENT_CODE;
            row.formOfPayment = view;
            this.addViewToSpecificSequence(fop.displaySequence, row, views);
        }
    }

    public toLocations(rowType: string, locations: PriceRuleLocationModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        let locationGroups = [];
        for (let location of locations ?? []) {
            let loc = locationGroups.filter(l => l.key == location.displaySequence)[0];
            if (loc) {
                loc.value.push(location)
            } else {
                locationGroups.push({
                    key: location.displaySequence,
                    value: [location]
                });
            }
        }
        for (let location of locationGroups) {
            let locations = location.value;
            if (locations.length) {
                let view = this.toPriceRuleLocationView(locations);
                let row = new PriceRuleAttributeAndRuleRowView();
                row.type = rowType;
                row.location = view;

                let displaySequence = parseInt(location.key);
                this.addViewToSpecificSequence(displaySequence, row, views);
            }
        }
    }

    public toOrganisations(organisations: PriceRuleOrganisationModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        let organisationGroups = [];
        for (let organisation of organisations ?? []) {
            let org = organisationGroups.filter(l => l.key == organisation.displaySequence)[0];
            if (org) {
                org.value.push(organisation)
            } else {
                organisationGroups.push({
                    key: organisation.displaySequence,
                    value: [organisation]
                });
            }
        }
        for (let organisation of organisationGroups) {
            let organisations = organisation.value;
            if (organisations.length) {
                let view = this.toPriceRuleOrganisationView(organisations);
                let row = new PriceRuleAttributeAndRuleRowView();
                row.type = PricingDetailConstant.ORGANISATION_CODE;
                row.organisation = view;
                let displaySequence = parseInt(organisation.key);
                this.addViewToSpecificSequence(displaySequence, row, views);
            }
        }
    }

    public toValidities(validities: PriceRuleValidityModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        let validityGroups = [];
        for (let validity of validities ?? []) {
            let valid = validityGroups.filter(l => l.key == validity.displaySequence)[0];
            if (valid) {
                valid.value.push(validity)
            } else {
                validityGroups.push({
                    key: validity.displaySequence,
                    value: [validity]
                });
            }
        }
        for (let validityGroup of validityGroups) {
            let validitiesInGroup = validityGroup.value;
            if (validitiesInGroup.length) {
                let view = this.toPriceRuleValidityView(validitiesInGroup);
                let row = new PriceRuleAttributeAndRuleRowView();
                row.type = PricingDetailConstant.CALENDAR_CODE;
                row.validity = view;
                row.isSaved = Boolean(validitiesInGroup?.[0]?.priceRuleValidityId);
                let displaySequence = parseInt(validityGroup.key);
                this.addViewToSpecificSequence(displaySequence, row, rows);
            }
        }
    }

    public toVehicles(vehicles: PriceRuleVehicleModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let vehicle of vehicles ?? []) {
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.VEHICLE_CODE;
            row.vehicle = this.toPriceRuleVehicleView(vehicle);
            this.addViewToSpecificSequence(vehicle.displaySequence, row, rows);
        }
    }

    public toServiceCategories(serviceCategories: PriceRuleServiceCategoryModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let serviceCategory of serviceCategories ?? []) {
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.SERVICES_CATEGORIES_CODE;
            row.serviceCategory = this.toPriceRuleServiceCategoryView(serviceCategory);
            this.addViewToSpecificSequence(serviceCategory.displaySequence, row, rows);
        }
    }

    public toSalesBuckets(salesBuckets: PriceRuleSalesBucketModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let salesBucket of salesBuckets ?? []) {
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = PricingDetailConstant.SALES_BUCKET_CODE;
            row.salesBucket = this.toPriceRuleSalesBucketView(salesBucket);
            this.addViewToSpecificSequence(salesBucket.displaySequence, row, rows);
        }
    }

    public toAttributes(attributeTypes: string[], attributes: PriceRuleAttributeModel[], rows: PriceRuleAttributeAndRuleRowView[]) {
        for (let i = 0; i < attributes?.length; i++) {
            let attribute = attributes[i];
            let row = new PriceRuleAttributeAndRuleRowView();
            row.type = attributeTypes[i];
            row.attribute = this.toPriceRuleAttributeView(attribute);
            this.addViewToSpecificSequence(attribute.displaySequence, row, rows, true);
        }
    }

    public toMemberships(memberships: PriceRuleMembershipModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        let membershipGroups = [];
        for (let membership of memberships ?? []) {
            let mem = membershipGroups.filter(l => l.key == membership.displaySequence)[0];
            if (mem) {
                mem.value.push(membership)
            } else {
                membershipGroups.push({
                    key: membership.displaySequence,
                    value: [membership]
                });
            }
        }
        for (let membership of membershipGroups) {
            let members = membership.value;
            if (members.length) {
                let view = this.toPriceRuleMembershipView(members);
                let row = new PriceRuleAttributeAndRuleRowView();
                row.type = PricingDetailConstant.MEMBER_CODE;
                row.membership = view;
                let displaySequence = parseInt(membership.key);
                this.addViewToSpecificSequence(displaySequence, row, views);
            }
        }
    }

    private addViewToSpecificSequence(sequence: number, view: PriceRuleAttributeAndRuleRowView, views: PriceRuleAttributeAndRuleRowView[], multipleChoiceFlag: boolean = false) {
        let index = sequence - 1;
        if (index < 0) {
            return;
        }
        if (index < views?.length) {
            if (multipleChoiceFlag) {       
                if (!views?.[index]?.attribute) {
                    view[index].attribute = new PriceRuleAttributeView();
                }

                views[index].attribute.attributeMultiChoiceIds ??= [] 
                views[index].attribute.attributeMultiChoiceIds?.push(view.attribute.attributeChoiceId.toString());
            }
            else {
                views[index] = view;
            }
        } else {
            while (views?.length < index) {            
                views.push(null);
            }
            views[index] = view;
            if (multipleChoiceFlag) {
                views[index].attribute ??= new PriceRuleAttributeView();
                views[index].attribute.attributeMultiChoiceIds ??= [] 
                views[index].attribute.attributeMultiChoiceIds?.push(view?.attribute?.attributeChoiceId?.toString());
            }
        }
    }

    public toIndividuals(individuals: PriceRuleIndividualModel[], views: PriceRuleAttributeAndRuleRowView[]) {
        let individualGroups = [];
        for (let individual of individuals ?? []) {
            let ind = individualGroups.filter(l => l.key == individual.displaySequence)[0];
            if (ind) {
                ind.value.push(individual)
            } else {
                individualGroups.push({
                    key: individual.displaySequence,
                    value: [individual]
                });
            }
        }
        for (let individual of individualGroups) {
            let inds = individual.value;
            if (inds.length) {
                let view = this.toPriceRuleIndividualView(inds);
                let row = new PriceRuleAttributeAndRuleRowView();
                row.type = PricingDetailConstant.INDIVIDUAL_CODE;
                row.individual = view;
                let displaySequence = parseInt(individual.key);
                this.addViewToSpecificSequence(displaySequence, row, views);
            }
        }
    }

    private toPriceRuleProductNumberView(model: PriceRuleProductNumberModel): PriceRuleProductNumberView {
        let view = new PriceRuleProductNumberView();
        view.displaySequence = model.displaySequence;
        view.excludeFlag = model.excludeFlag;
        view.priceRuleProductNumberId = model.priceRuleProductNumberId;
        view.productNumber = model.productNumber;
        view.productNumberTypeCode = model.productNumberTypeCode;
        view.providerId = model.providerId;
        view.supplierId = model.supplierId;
        return view;
    }

    public toPriceRuleRouteView(model: PriceRuleRouteModel): PriceRuleRouteView {
        let view = new PriceRuleRouteView();
        view.priceRuleRouteId = model.priceRuleRouteId;
        view.originLocationId = model.originLocationId;
        view.destinationLocationId = model.destinationLocationId;
        view.excludeFlag = model.excludeFlag;
        view.displaySequence = model.displaySequence;
        return view;
    }

    public toPriceRuleFormOfPaymentView(model: PriceRuleFormOfPaymentModel): PriceRuleFormOfPaymentView {
        return {
            formOfPaymentCode: model.formOfPaymentCode,
            formOfPaymentIssuerCode: model.formOfPaymentIssuerCode,
            formOfPaymentTypeCode: model.formOfPaymentTypeCode,
            priceRuleFormOfPaymentId: model.priceRuleFormOfPaymentId,
            displaySequence: model.displaySequence,
            excludeFlag: model.excludeFlag,
            priceId: model.priceId,
            priceRuleId: model.priceRuleId,
            condition: model.excludeFlag ? PricingDetailConstant.IS_NOT_OPERATOR_CODE : PricingDetailConstant.IS_OPERATOR_CODE
        }
    }

    public toPriceRuleValidityView(models: PriceRuleValidityModel[]): PriceRuleValidityView {
        let model = models[0];
        let view = new PriceRuleValidityView();
        view.calendarValidityCode = model.calendarValidityCode;
        view.calendarValidityPrefixCode = model.calendarValidityPrefixCode;
        view.minutesStartValue = model.minutesStartValue.toString();
        view.minutesEndValue = model.minutesEndValue.toString();
        view.hoursStartValue = model.hoursStartValue.toString();
        view.hoursEndValue = model.hoursEndValue.toString();
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.conditionCode = model.conditionCode;
        view.dateTimeDimensionCode = model.dateTimeDimensionCode;
        view.endValue = model.endValue == 0 ? "" : model.endValue + '';
        view.startValue = models.map(m => m.startValue == 0 || m.dateTimeDimensionCode == PricingDetailConstant.CALENDAR_HOUR ? "" : m.startValue + '');
        if (view.startValue.every(sv => !sv)) {
            delete view.startValue;
        }
        view.timezone = model.utcFlag ? PricingDetailConstant.UTC_CODE : PricingDetailConstant.LOCAL_CODE;
        switch (model.dateTimeDimensionCode) {
            case PricingDetailConstant.CALENDAR_DATE:
                view.startDateTime = this.dateConverter.convertDateString(model.startDateTime, this.yearMonthDateFormat)
                view.endDateTime = this.dateConverter.convertDateString(model.endDateTime, this.yearMonthDateFormat)
                break;
            case PricingDetailConstant.CALENDAR_TIME:
                view.startDateTime = this.dateConverter.convertDateString(model.startDateTime, this.timeFormat)
                view.endDateTime = this.dateConverter.convertDateString(model.endDateTime, this.timeFormat)
                break;
            case PricingDetailConstant.CALENDAR_DATE_TIME:
                view.startDateTime = this.dateConverter.convertDateString(model.startDateTime, this.dateTimeFormat)
                view.endDateTime = this.dateConverter.convertDateString(model.endDateTime, this.dateTimeFormat)
                break;
        }
        view.weekdays = this.convertToWeekdays(model);
        this.convertToMonths(model, view);
        return view;
    }

    private convertToMonths(model: PriceRuleValidityModel, view: PriceRuleValidityView) {
        let postFix = 'Flag';
        let monthNames = moment.months().map(month => month.toLowerCase());
        for (let name of monthNames) {
            if (model[name + postFix]) {
                view.months = view.months ?? [];
                view.months.push(name.toUpperCase());
            }
        }
    }

    public toPriceRuleVehicleView(model: PriceRuleVehicleModel): PriceRuleVehicleView {
        let view = new PriceRuleVehicleView();
        view.excludeFlag = model.excludeFlag;
        view.vehicleCompositionId = model.vehicleCompositionId;
        view.vehicleGroupCode = model.vehicleGroupCode;
        view.vehicleTypeCode = model.vehicleTypeCode;
        return view;
    }

    public toPriceRuleServiceCategoryView(model: PriceRuleServiceCategoryModel): PriceRuleServiceCategoryView {
        let view = new PriceRuleServiceCategoryView();
        view.excludeFlag = model.excludeFlag;
        view.conditionCode = model.conditionCode;
        view.dimensionUnitCode = model.dimensionUnitCode;
        view.inventoryDimensionCode = model.inventoryDimensionCode;
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.priceRuleServiceCategoryId = model.priceRuleServiceCategoryId;
        view.serviceCategoryCode = model.serviceCategoryCode;
        view.thresholdValue = model.thresholdValue + '';
        if (model.children?.length) {
            let children = new Array<PriceRuleServiceCategoryView>();
            for (let child of model.children) {
                children.push(this.toPriceRuleServiceCategoryView(child));
            }
            view.children = children;
        }
        return view;
    }

    public toPriceRuleSalesBucketView(model: PriceRuleSalesBucketModel): PriceRuleSalesBucketView {
        let view = new PriceRuleSalesBucketView();
        view.excludeFlag = model.excludeFlag;
        view.conditionCode = model.conditionCode;
        view.dimensionUnitCode = model.dimensionUnitCode;
        view.inventoryDimensionCode = model.inventoryDimensionCode;
        view.priceRuleAttributeGroupCode = model.priceRuleAttributeGroupCode;
        view.priceRuleSalesBucketId = model.priceRuleSalesBucketId;
        view.salesBucketCode = model.salesBucketCode;
        view.thresholdValue = model.thresholdValue + '';
        if (model.children?.length) {
            let children = new Array<PriceRuleSalesBucketView>();
            for (let child of model.children) {
                children.push(this.toPriceRuleSalesBucketView(child));
            }
            view.children = children;
        }
        return view;
    }

    public toPriceRuleAttributeView(model: PriceRuleAttributeModel): PriceRuleAttributeView {
        let view = new PriceRuleAttributeView();
        view.conditionCode = model.conditionCode;
        view.attributeChoiceId = model.attributeChoiceId;
        view.priceRuleAttributeId = model.priceRuleAttributeId
        view.attributeTypeCode = model.attributeTypeCode;
        view.attributeDateTime = this.dateConverter.convertDateStringToFormat(model.attributeDateTime, this.dateConverter.DATETIME_ISO_NO_MILLISEC_TIMEZONE, this.dateConverter.DATETIME_FORMAT_24);
        view.attributeText = model.attributeText;
        view.attributeValue = model.attributeValue + '';
        return view;
    }

    public toPriceRuleProductView(model: PriceRuleProductModel): PriceRuleProductView {
        let view = new PriceRuleProductView();
        view.priceRuleProductId = model.priceRuleProductId;
        view.productCategoryCode = model.productCategoryCode;
        view.productGroupCode = model.productGroupCode;
        view.productTypeCode = model.productTypeCode;
        view.excludeFlag = model.excludeFlag;
        view.productId = model.productId;
        return view;
    }


    private convertToWeekdays(model: PriceRuleValidityModel) {
        let weekdays = new Array<string>();
        if (model.mondayFlag) {
            weekdays.push('MONDAY');
        }
        if (model.tuesdayFlag) {
            weekdays.push('TUESDAY');
        }
        if (model.wednesdayFlag) {
            weekdays.push('WEDNESDAY');
        }
        if (model.thursdayFlag) {
            weekdays.push('THURSDAY');
        }
        if (model.fridayFlag) {
            weekdays.push('FRIDAY');
        }
        if (model.saturdayFlag) {
            weekdays.push('SATURDAY');
        }
        if (model.sundayFlag) {
            weekdays.push('SUNDAY');
        }
        return weekdays;
    }

    public toPriceRuleLocationView(models: PriceRuleLocationModel[]): PriceRuleLocationView {
        let model = models[0];
        let view = new PriceRuleLocationView();
        view.priceRuleLocationPointCode = model.priceRuleLocationPointCode;
        view.displaySequence = model.displaySequence;
        view.excludeFlag = model.excludeFlag;
        view.priceRuleLocationTypeCode = model.priceRuleLocationTypeCode;
        if (models.length) {
            let locationGroupIds = models.filter(model => model.locationGroupId).map(model => model.locationGroupId);
            if (locationGroupIds?.length) {
                view.locationGroupId = locationGroupIds;
            }

            let locationIds = models.filter(model => model.locationId).map(model => model.locationId);
            if (locationIds?.length) {
                view.locationId = locationIds;
            }

            let regionCodes = models.filter(model => model.regionCode).map(model => model.regionCode);
            if (regionCodes?.length) {
                view.regionCode = regionCodes;
            }

            let countryCodes = models.filter(model => model.countryCode).map(model => model.countryCode);
            if (countryCodes?.length) {
                view.countryCode = countryCodes;
            }
        }
        if (view.regionCode) {
            view.locationType = PricingDetailConstant.REGION_VALUE;
        } else if (view.countryCode) {
            view.locationType = PricingDetailConstant.COUNTRY_VALUE;
        } else if (view.locationId) {
            view.locationType = PricingDetailConstant.AIRPORT_CITY_VALUE;
        } else if (view.locationGroupId) {
            view.locationType = PricingDetailConstant.LOCATION_GROUP_VALUE;
        }
        return view;
    }

    public toPriceRuleOrganisationView(models: PriceRuleOrganisationModel[]): PriceRuleOrganisationView {
        let model = models[0];
        let view = new PriceRuleOrganisationView();
        view.excludeFlag = model.excludeFlag;
        view.priceRuleOrganisationTypeCode = model.priceRuleOrganisationTypeCode;
        if (models.length) {
            let organisationTypeCodes = models.filter(model => model.organisationTypeCode).map(model => model.organisationTypeCode);
            if (organisationTypeCodes?.length) {
                view.organisationTypeCode = organisationTypeCodes;
            }
            let organisationRoleCodes = models.filter(model => model.organisationRoleCode).map(model => model.organisationRoleCode);
            if (organisationRoleCodes?.length) {
                view.organisationRoleCode = organisationRoleCodes;
            }
            let organisationGroupCodes = models.filter(model => model.organisationGroupCode).map(model => model.organisationGroupCode);
            if (organisationGroupCodes?.length) {
                view.organisationGroupCode = organisationGroupCodes;
            }
            let organisationIds = models.filter(model => model.organisationId).map(model => model.organisationId);
            if (organisationIds?.length) {
                view.organisationId = organisationIds;
            }
        }
        return view;
    }

    public toPriceRuleMembershipView(models: PriceRuleMembershipModel[]): PriceRuleMembershipView {
        let model = models[0];
        let view = new PriceRuleMembershipView();
        view.excludeFlag = model.excludeFlag;
        if (models.length) {
            let membershipTypeCodes = models.filter(model => model.membershipLevelCode).map(model => model.membershipLevelCode);
            if (membershipTypeCodes?.length) {
                view.membershipLevelCode = membershipTypeCodes;
            }
        }
        return view;
    }

    public toPriceRuleIndividualView(models: PriceRuleIndividualModel[]): PriceRuleIndividualView {
        let model = models[0];
        let view = new PriceRuleIndividualView();
        view.excludeFlag = model.excludeFlag;
        if (models.length) {
            let genderCodes = models.filter(model => model.genderCode).map(model => model.genderCode);
            if (genderCodes?.length) {
                view.genderCode = genderCodes;
            }
            let individualAgeGroupCodes = models.filter(model => model.individualAgeGroupCode).map(model => model.individualAgeGroupCode);
            if (individualAgeGroupCodes?.length) {
                view.individualAgeGroupCode = individualAgeGroupCodes;
            }
            let individualCivilStatusTypeIds = models.filter(model => model.individualCivilStatusTypeCode).map(model => model.individualCivilStatusTypeCode);
            if (individualCivilStatusTypeIds?.length) {
                view.individualCivilStatusTypeCode = individualCivilStatusTypeIds;
            }
            let individualInterestTypeCodes = models.filter(model => model.individualInterestTypeCode).map(model => model.individualInterestTypeCode);
            if (individualInterestTypeCodes?.length) {
                view.individualInterestTypeCode = individualInterestTypeCodes;
            }
            let nationalityCodes = models.filter(model => model.nationalityCode).map(model => model.nationalityCode);
            if (nationalityCodes?.length) {
                view.nationalityCode = nationalityCodes;
            }
        }
        return view;
    }

    public toCommand(model: PriceRuleModel): PriceRuleCommand {
        let command = {} as PriceRuleCommand;
        command.id = model.id;
        command.parentPriceRuleId = model.parentPriceRuleId;
        command.priceRuleCode = model.priceRuleCode?.toUpperCase();
        command.priceRuleName = model.priceRuleName;
        command.priceRuleConcatenateFlag = model.priceRuleConcatenateFlag;
        command.statusCode = model.statusCode;
        command.priceRuleTypeCode = model.priceRuleTypeCode;
        command.priceRuleGroupCode = model.priceRuleGroupCode;
        command.priceRuleCategoryCode = model.priceRuleCategoryCode;
        command.priceRuleDisplayCode = model.priceRuleDisplayCode?.toUpperCase();
        command.priceRuleDisplayName = model.priceRuleDisplayName;
        command.priceRuleTypeGroupName = model.priceRuleTypeGroupName;
        command.usageTypeCode = model.usageTypeCode;
        command.isOwnerFilter = model.isOwnerFilter;
        command.ownerFilterUserAccountId = model.ownerFilterUserAccountId;
        command.searchName = model.searchName;
        command.searchStatusCode = model.searchStatusCode;
        command.searchUsageTypeCode = model.searchUsageTypeCode;
        command.productOptions = model.productOptions;
        command.priceRulePrices = model.priceRulePrices;
        command.attributes = this.toAttributeCommand(model.attributes);
        command.individuals = model.individuals;
        command.languageTranslationSet = model.languageTranslationSet;
        command.locations = model.locations;
        command.memberships = model.memberships;
        command.organisations = model.organisations;
        command.productNumbers = model.productNumbers;
        command.products = model.products;
        command.routes = model.routes;
        command.salesBuckets = model.salesBuckets;
        command.serviceCategories = model.serviceCategories;
        command.validities = model.validities;
        command.vehicles = model.vehicles;
        command.formOfPayments = model.formOfPayments;
        command.medias = this.pricingMediaConverter.mediaViewModelsToCommand(model.medias);
        model.medias
        if (model.children?.length) {
            let children = new Array<PriceRuleCommand>();
            for (let child of model.children) {
                children.push(this.toCommand(child));
            }
            command.children = children;
        }
        return command;
    }

    private toAttributeCommand(attributes: PriceRuleAttributeModel[]): PriceRuleAttributeModel[] {
        if (!attributes) {
            return null;
        }
        
        let commands = new Array<PriceRuleAttributeModel>();
        for (let attribute of attributes) {
            let command = {} as PriceRuleAttributeModel;
            command.priceRuleId = attribute.priceRuleId;
            command.priceRuleAttributeId = attribute.priceRuleAttributeId;
            command.displaySequence = attribute.displaySequence;
            command.conditionCode = attribute.conditionCode;
            command.attributeValue = attribute.attributeValue;
            command.attributeTypeCode = attribute.attributeTypeCode;
            command.attributeChoiceId = attribute.attributeChoiceId;
            command.attributeText = attribute.attributeText;
            command.attributeValue = attribute.attributeValue;
            command.attributeDateTime = this.setDateTimeFormat(attribute.attributeDateTime);
            command.attributeTypeCode = attribute.attributeTypeCode;
            commands.push(command);
        }

        return commands;
    }

    private setDateTimeFormat(datetime: string): string {
        if (!datetime) {
            return null;
        }

        return datetime.replace(' ', 'T');
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .loading-spinner {
            margin-top: 12px;
            position: absolute;
            right: 34px;
        }
        `, "",{"version":3,"sources":["webpack://./src/app/modules/pricing/rules/price-rule-detail/general/general.component.ts"],"names":[],"mappings":";QACQ;YACI,gBAAgB;YAChB,kBAAkB;YAClB,WAAW;QACf","sourcesContent":["\n        .loading-spinner {\n            margin-top: 12px;\n            position: absolute;\n            right: 34px;\n        }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

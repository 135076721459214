import { AfterViewInit, ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    templateUrl: './organisation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrganisationComponent implements AfterViewInit {
    constructor(private navigationService: NavigationService) { }
    
    ngAfterViewInit(): void {
        this.navigationService.navigate('/main/organisation/home', null, false, {});
    }
}
export const select2Title = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Status = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Language = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

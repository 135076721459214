import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { ExportButtonGroupComponent } from "src/app/shared/ui/export-button-group/export-button-group.component";
import { LanguageView } from "../shared/language-view";

@Component({
    selector: 'op-insight-language-table',
    templateUrl: './language-table.component.html'
})
export class InsightLanguageTableComponent implements AfterViewInit {
    public selectedItem: LanguageView;
    public customToolbarMarginTop: number;
    public rows = 0;
    public pdfDefaultStyle = {
      fontSize: 10
    };

    public itemSelected: LanguageView;
    public header: string[] = [];
    public exportData;

    @Input() insightLanguages: LanguageView[] = new Array(); 
    @Output() onEdit = new EventEmitter();

    @ViewChild('dataGridLanguage', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ExportButtonGroupComponent) exportButtonGroup: ExportButtonGroupComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
      this.getDataGridHeader();
    }

    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
      this.rows = e.component.totalCount();
      this.getExportData()?.done((filteredData) => {
        this.exportData = this.mapExportData(filteredData);
      });        

      this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
      return filteredData.map(dat => (
          {
              No: dat.no,
              Language: dat.languageName,
              Primary: dat.primaryFlag
          }
      ));
    }

    private getExportData() {
      let filterExpr = this.dataGrid.instance.getCombinedFilter();
      let gridDataSource = this.dataGrid.instance.getDataSource();
      return gridDataSource?.store().load({
          filter: filterExpr,
      });
    }

    private getDataGridHeader() {
      this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    editInsightLanguageDetail() {
      this.onEdit.emit();
    }

    public onCellPrepared(e) {
      if (e.rowType != 'data') {
          return;
      }
      if (e.column.dataField == "no") {
          e.cellElement.css("text-align", "right");  
      }
    }
}
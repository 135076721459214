import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { LocationReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { LocationReferenceService } from "src/app/core/services/airline-services";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

@Injectable()
export class RouteDataService extends ApiServiceBase {
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private locationReferenceService: LocationReferenceService
    ) {
        super(httpClient, authService);
    }

    public select2AjaxSearchByLocationCode() {
        return {
            transport: (params, success, failure) => {
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, null)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }
}
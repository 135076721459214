import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DetailsComponent } from './details.component';
import { AttributesComponent } from './dimensions/attributes/attributes.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { GeneralComponent } from './general/general.component';
import { LevelComponent } from './level/level.component';

@NgModule({
    declarations: [
        AttributesComponent,
        DetailsComponent,
        DimensionsComponent,
        GeneralComponent,
        LevelComponent],
    exports: [DetailsComponent],
    imports: [
        CommonModule,
        DaterangepickerModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        UiModule,
    ],
})
export class DetailsModule {}

import { Injectable } from '@angular/core';
import { OperatingProductSearchDetailViewModel } from 'src/app/core/models/product-model/operating-product-model';
import { TransportOperatingProductViewModel } from 'src/app/core/models/product-model/transport-model/transport-operating-product-view.model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { OperatingProductListView } from './operating-product-list.view';
import { OperatingTransportSearchDetailView } from 'src/app/modules/transport/operating-transport/operating-transport-content/operating-transport-search/shared/operating-transport-search-detail.view';
import { Helper } from 'src/app/shared/helper/app.helper';


@Injectable()
export class OperatingProductListMapperService {

    constructor(private dateConverterService: DateConverterService, 
                private helper: Helper) {
    }

    public toViews(models: TransportOperatingProductViewModel[]): OperatingProductListView[] {
        let views: OperatingProductListView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.toView(i, model))
            }
        }
        return views;
    }

    private toView(index: number, model: TransportOperatingProductViewModel): OperatingProductListView {
        let view = {} as OperatingProductListView;
        view.no = index;
        view.operatingProductId = model.productId;
        view.parentProductId = model.parentProductId;
        view.operatingProductSeriesId = model.operatingProductSeriesId;
        view.localDate = this.dateConverterService.toDateFormat(model.localDateTime);
        view.localDay = model.localDay?.substring(0, 3);
        view.localTime = this.dateConverterService.convertTime24(model.localTime);
        view.dept = model.locationCodeFrom;
        view.arr = model.locationCodeTo;
        view.booked = model.booked ? model.booked : 0;
        view.avai = model.avai;
        view.load = this.createLoad(model.booked, model.sellCapacity);
        view.status = model.productOperatingStatusName;
        view.deptLoad = model.locationCodeLoadFrom;
        view.arrLoad = model.locationCodeLoadTo;
        return view;
    }

    public createLoad(booked: number, au: number): number {
        if (booked == null || au == null || au == 0) {
            return 0;
        }
        return parseFloat(((booked * 100) / au).toFixed(2));
    }

    public searchDetailModelToViews(models: OperatingProductSearchDetailViewModel[]): OperatingTransportSearchDetailView[] {
        let views: OperatingTransportSearchDetailView[] = new Array();
        if (models) {
            let i = 0;
            for (let model of models) {
                ++i;
                views.push(this.searchDetailModelToView(i, views, model))
            }
        }
        return views;
    }

    private searchDetailModelToView(index: number, views: OperatingTransportSearchDetailView[], model: OperatingProductSearchDetailViewModel): OperatingTransportSearchDetailView {
        let view = {} as OperatingTransportSearchDetailView;
        let segmentExist = this.segmentExist(model.sortSequenceFrom, views);

        if (segmentExist?.length) {
            view.segmentName = segmentExist[0].segmentName;
            view.segmentNameWithNo = segmentExist[0].segmentNameWithNo;
            view.sortSequence = segmentExist[0].sortSequence;
        } else {
            view.segmentName = this.createSegmentName(model.segNameFrom, model.segNameTo);
            view.segmentNameWithNo = this.createSegmentNameWithNo(index, model.segNameFrom, model.segNameTo);
            view.sortSequence = model.sortSequenceFrom;
        }
        view.no = index;
        view.operatingProductSeriesId = model.operatingProductSeriesId;
        view.parentProductId = model.parentProductId;
        view.serviceCategory = model.serviceCategoryName;
        view.productInventoryId = model.productInventoryId;
        view.capacity = model.sellCapacity ? model.sellCapacity : 0;
        view.obf = model.overSellFactor ? model.overSellFactor : 0;
        view.grpcap = model.groupCapacity ? model.groupCapacity : 0;
        view.ocab = this.createOcap(view.capacity, view.grpcap);
        view.au = this.createAu(view.ocab, view.obf);
        view.nav = this.helper.isNullZero(model.nav);
        view.total = model.total;
        view.indivs = model.indivs
        view.groups = model.groups;
        view.staff = model.staff;
        view.infant = model.infant;
        view.waitlist = model.waitlist;
        view.noCom = this.helper.isNullZero(model.noCom);
        return view;
    }

    private segmentExist(sortSequence: number, views: OperatingTransportSearchDetailView[]): OperatingTransportSearchDetailView[] {
        return views.filter(x => x.sortSequence == sortSequence);
    }

    private createSegmentName(locationNameFrom: string, locationNameTo: string): string {
        return locationNameFrom + " - " + locationNameTo;
    }

    private createSegmentNameWithNo(no: number, locationNameFrom: string, locationNameTo: string): string {
        return no.toString() + ":" + locationNameFrom + " - " + locationNameTo;
    }

    public createOcap(capacity: number, grpcap: number): number {
        if (capacity == null || grpcap == null) {
            return 0;
        }
        return (capacity - grpcap);
    }

    public createAu(ocap: number, obf: number): number {
        if (ocap == null || obf == null) {
            return 0;
        }
        return (ocap * obf) / 100;
    }
}

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, SimpleChange, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import {
    select2ProductCodeType, select2Provider, select2Supplier
} from '../../../../../shared/configuration/select2-number-configuration';

import { ProductNumberTypeReferenceModel } from '../../../../../../../../core/models/reference-model/reference-product-model';
import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import { ProductNumberViewModel } from 'src/app/core/models/product-model/product-base-model/product-number';
import { ProductNumberTypeReferenceService } from 'src/app/core/services/system-services/product-number-type-reference.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'op-number',
    templateUrl: './number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberComponent implements OnChanges {

    public readonly OPERATINGCODE: string = "OPERATING";
    public readonly ERROR_DUPLICATE = 'Duplicate row.';
    public readonly ERROR_OPERATING_REQUIRED = 'Operating code is required.';
    public readonly ERROR_NUMBER_REQUIRED = 'Number is required.';
    @Input() id: string;
    @Input() provider$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() supplier$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() classIcon: string;
    @Input() productTypeCode: string;
    @Input() productNumberTypeSearch$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() searchMode: boolean = false;

    @Output() productNumber = new EventEmitter<string>();
    @Output() providerId = new EventEmitter<string>();
    @Output() supplierId = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public focusing: boolean = false;
    public productNumberTypeReference$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    public displayEditData: boolean = false;
    public rootLevelWithProductPoint: boolean = false;
    public draftFlag: boolean = true;
    public productNumbers: ProductNumberViewModel[];
    public actionForm: UntypedFormGroup;
    public codeTypeOption: any;
    public providerOption: any;
    public supplierOption: any;
    public disableNewItemButtonFlag = false;
    private productNumberForm: string;
    private providerForm: string;
    private supplierForm: string;
    public finalFlag: boolean = false;

    constructor(private fb: UntypedFormBuilder,
        private productNumberTypeReferenceService: ProductNumberTypeReferenceService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.codeTypeOption = cloneDeep(select2ProductCodeType);
        this.providerOption = cloneDeep(select2Provider);
        this.supplierOption = cloneDeep(select2Supplier);
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.onChanges();
    }

    public onChanges() {
        if (!this.searchMode) {
            this.geProductTypeNumber();
        }
        else {
            this.getProductTypeNumberSearch();
        }
    }

    public geProductTypeNumber() {
        if (this.productTypeCode) {
            this.productNumberTypeReferenceService.getByProductType(this.productTypeCode)
                .subscribe(
                    (responses: ProductNumberTypeReferenceModel[]) => {
                        this.createProductTypeData(responses);
                        if (this.displayEditData) {
                            this.addViewToFormGroup(this.productNumbers);
                        } else if (!this.id) {
                            this.addOperatingNumberToFormGroup(null);
                        }
                    }
                )
        }
        else {
            this.productNumberTypeReference$.next(null);
        }
    }

    private getProductTypeNumberSearch() {
        this.productNumberTypeReference$ = this.productNumberTypeSearch$;
    }

    private createProductTypeData(responses: ProductNumberTypeReferenceModel[]) {
        if (!this.rootLevelWithProductPoint && this.id && !this.draftFlag) {
            this.productNumberTypeReference$.next(responses.filter(x => x.productNumberTypeCode != this.OPERATINGCODE));
        }
        else {
            this.productNumberTypeReference$.next(responses);
        }
    }
    add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == "INVALID") {
                    return;
                }
            }
        }
        this.forms.push(this.createFormGroup(null));
    }

    public validAllRows(): boolean {
        for (let ctl of this.forms.controls) {
            if (ctl.status == "INVALID") {
                return false;
            }
        }
        return true;
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (this.draftFlag) {
                    ctl.enable();
                }
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                }
                else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }

    findDuplicate(ctlCheck, ctlIndex): boolean {
        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (ctl.get("productNumber").value == ctlCheck.get("productNumber").value &&
                    ctl.get("productNumberTypeCode").value == ctlCheck.get("productNumberTypeCode").value &&
                    ctl.get("providerId").value == ctlCheck.get("providerId").value &&
                    ctl.get("supplierId").value == ctlCheck.get("supplierId").value
                ) {
                    duplicate = true;
                }
            }
        }
        return duplicate;
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get('productNumberDuplicate').setValue(null);
            formCurrent.get('productNumberDuplicate').setValidators(Validators.required);
            formCurrent.get('productNumberDuplicate').updateValueAndValidity();
        }
        else {
            formCurrent.get('productNumberDuplicate').setValue(false);
            formCurrent.get('productNumberDuplicate').setValidators(Validators.nullValidator);
            formCurrent.get('productNumberDuplicate').updateValueAndValidity();
        }
    }

    findOperatingType(rootWithProductPoint: boolean, newProduct: boolean): boolean {
        if (!rootWithProductPoint && !newProduct) {
            return true;
        }
        if (this.forms.controls.length != 0) {
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.get("productNumber").value &&
                    ctl.get("productNumberTypeCode").value == this.OPERATINGCODE &&
                    ctl.get("providerId").value ) {
                    return true;
                }
            }
        }
        return false;
    }

    addViewToFormGroup(productNumbers: ProductNumberViewModel[]) {
        if (!productNumbers?.length && this.rootLevelWithProductPoint) {
            this.addOperatingNumberToFormGroup(null);
            return;
        }
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        for (let productNumber of productNumbers) {
            this.forms.push(this.createFormGroup(productNumber));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);
        }
        this.changeDetectorRef.detectChanges();
    }

    addOperatingNumberToFormGroup(productNumber: ProductNumberViewModel) {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });

        if (!productNumber) {
            productNumber = new ProductNumberViewModel();
            productNumber.productNumberTypeCode = this.OPERATINGCODE;
            productNumber.productNumber = this.productNumberForm;
            productNumber.providerId = this.providerForm;
            productNumber.supplierId = this.supplierForm;
        }
        this.forms.push(this.createFormGroup(productNumber));;
        this.changeDetectorRef.detectChanges();
    }

    createFormGroup(productNumber: ProductNumberViewModel) {
        if (productNumber) {
            return this.fb.group({
                productNumberId: new UntypedFormControl(productNumber.productNumberId, { validators: [Validators.nullValidator] }),
                productNumberTypeCode: new UntypedFormControl({ value: productNumber.productNumberTypeCode, disabled: this.disabled(productNumber.productNumberTypeCode) }, { validators: [Validators.required] }),
                productNumber: new UntypedFormControl({ value: productNumber.productNumber, disabled: this.disabled(productNumber.productNumberTypeCode) }, { validators: [Validators.required] }),
                providerId: new UntypedFormControl({ value: productNumber.providerId, disabled: this.disabled(productNumber.productNumberTypeCode) }, { validators: [Validators.required] }),
                supplierId: new UntypedFormControl({ value: productNumber.supplierId, disabled: this.disabled(productNumber.productNumberTypeCode) }, { validators: [Validators.nullValidator] }),
                productNumberTypeData: this.getProductNumberTypeData(productNumber),
                productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }
                ),
                submitted: Boolean
            });
        }
        else {
            return this.fb.group({
                productNumberId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
                productNumberTypeCode: new UntypedFormControl(null, { validators: [Validators.required] }),
                productNumber: new UntypedFormControl(null, { validators: [Validators.required] }),
                providerId: new UntypedFormControl(null, { validators: [Validators.required] }),
                supplierId: new UntypedFormControl(null, { validators: [Validators.nullValidator] }),
                productNumberTypeData: this.getProductNumberTypeData(null),
                productNumberDuplicate: new UntypedFormControl(false, { validators: [Validators.nullValidator] }),
                submitted: Boolean
            });
        }
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    delete(i) {
        this.forms.removeAt(i);
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    selectChange(value: string | string[], ctl, name: string) {
        ctl.get(name).setValue(value);
        this.findDuplicateAllRow();
    }

    numberChange(value, ctl) {
        let typeCode = ctl.get("productNumberTypeCode").value;
        if (typeCode == this.OPERATINGCODE) {
            this.productNumber.emit(value.target.value);
        }
        this.findDuplicateAllRow();
    }

    providerChange(value: string | string[], ctl) {
        ctl.get("providerId").setValue(value);
        let typeCode = ctl.get("productNumberTypeCode").value;
        if (typeCode == this.OPERATINGCODE) {
            this.providerId.emit(<string>value);
        }
        this.findDuplicateAllRow();
    }

    supplierChange(value: string | string[], ctl) {
        ctl.get("supplierId").setValue(value);
        let typeCode = ctl.get("productNumberTypeCode").value;
        if (typeCode == this.OPERATINGCODE) {
            this.supplierId.emit(<string>value);
        }
        this.findDuplicateAllRow();
    }

    clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.productNumbers = [];
        this.displayEditData = false;
        this.rootLevelWithProductPoint = false;
        this.productNumberForm = null;
        this.providerForm = null;
        this.supplierForm = null;
        this.productTypeCode = null;
        this.draftFlag = true;
        this.disableNewItemButtonFlag = false;
        this.finalFlag = false;
        this.changeDetectorRef.detectChanges();
    }

    hideDelete(f: any): boolean {
        if ((f.get("productNumberTypeCode").value == this.OPERATINGCODE && !this.searchMode) || this.finalFlag) {
            return true;
        } else {
            return false;
        }
    }

    getProductNumberTypeData(productNumber: ProductNumberViewModel): BehaviorSubject<ProductNumberTypeReferenceModel[]> {
        if (this.productNumberTypeReference$.value) {
            if (this.searchMode) {
                return this.productNumberTypeReference$;
            }
            let returnValue = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
            let filterOperatingCode = this.productNumberTypeReference$.value.filter(x => x.productNumberTypeCode == this.OPERATINGCODE);
            let filterNoOperatingCode = this.productNumberTypeReference$.value.filter(x => x.productNumberTypeCode != this.OPERATINGCODE);
            if (productNumber?.productNumberTypeCode == this.OPERATINGCODE) {
                returnValue.next(filterOperatingCode);
                return returnValue;
            }
            returnValue.next(filterNoOperatingCode);
            return returnValue;
        }
        return new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    }

    disabled(productNumberTypeCode: string): boolean {
        if ((productNumberTypeCode == this.OPERATINGCODE && !this.draftFlag) || this.finalFlag) {
            return true;
        } else {
            return false;
        }
    }

    public updateNumber(value: string) {
        let ctl = this.existingOperatingType();
        this.productNumberForm = value;
        if (ctl) {
            ctl.get('productNumber').setValue(value);
        }
    }

    public updateProvider(value: string) {
        let ctl = this.existingOperatingType();
        this.providerForm = value;
        if (ctl) {
            ctl.get('providerId').setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }

    public updateSupplier(value: string) {
        let ctl = this.existingOperatingType();
        this.supplierForm = value;
        if (ctl) {
            ctl.get('supplierId').setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }


    private existingOperatingType(): AbstractControl {
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            var ctl = this.forms.controls[i];
            if (ctl.get("productNumberTypeCode").value == this.OPERATINGCODE) {
                return ctl;
            }
        }
        return null;
    }

    public getErrorMessageForm(rootWithProductPoint: boolean, newProduct: boolean): string {
        if (!this.findOperatingType(rootWithProductPoint, newProduct)) {
            return this.ERROR_OPERATING_REQUIRED;
        } else if (this.findDuplicateAllRow()) {
            return this.ERROR_DUPLICATE;
        } else if (!this.validAllRows()) {
            return this.ERROR_NUMBER_REQUIRED;
        }
        return null;
    }

    public disableNewItemButton() {
        this.disableNewItemButtonFlag = true;
    }

    public enableNewItemButton() {
        this.disableNewItemButtonFlag = false;
    }
    
    public getProductNumberViewModelsFromFormGroups(): ProductNumberViewModel[] {
        let list = [];
        for (let i = 0; i < this.forms.controls.length; i++) {
            let ctl = this.forms.controls[i];
            let m = {} as ProductNumberViewModel;
            m.productNumberId = ctl.get('productNumber').value;
            m.productNumberTypeCode = ctl.get('productNumberTypeCode').value;
            m.productNumber = ctl.get('productNumber').value;
            m.providerId = ctl.get('providerId').value;
            m.supplierId = ctl.get('supplierId').value;
            m.displaySequence = i + 1;
            list.push(m);
        }
        return list;
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxCheckBoxModule, DxDataGridModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { AttributeAndRuleModule } from "../price-rule-detail/attribute-and-rule/attribute-and-rule.module";
import { PriceRuleSearchComponent } from "./price-rule-search.component";
import { SearchConditionComponent } from "./search-condition/search-condition.component";
import { SearchFilterNameComponent } from "./search-filter-name/search-filter-name.component";
import { SearchTableComponent } from "./search-table/search-table.component";

@NgModule({
    declarations: [
        PriceRuleSearchComponent,
        SearchConditionComponent,
        SearchFilterNameComponent,
        SearchTableComponent
    ],
    exports: [
        PriceRuleSearchComponent
    ],
    imports: [
        AttributeAndRuleModule,
        BsDropdownModule,
        CommonModule,
        DaterangepickerModule,
        DxCheckBoxModule,
        DxDataGridModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        TooltipModule,
        UiModule
    ]
})
export class PriceRuleSearchModule { }
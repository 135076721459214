import { Injectable } from "@angular/core";
import { ScheduleQueueResultCode } from "src/app/modules/schedule/shared/schedule.contant";

@Injectable()
export class ScheduleQueueGridService {

    public setResultCellColor(e) {
        switch (e.data.resultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;
        }
    }
}
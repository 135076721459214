import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";

export class AddressView {
    public no: number;
    public addressId: string;
    public individualId: string;
    public useAddressId: string;
    public useAddressNo: number;
    public countryCode: string;
    public addressLine1: string;
    public addressLine2: string;
    public postalCode: string;
    public state: string;
    public province: string;
    public city: string;
    public primaryAddress: boolean;
    public locationId: string;
    public addressTypeCode: string;
    public addressTypeName: string;
    public countryName: string;
    public locationName: string;
    public locationDataSave: Select2Data[];
    public organisationId: string;
    public addressLocked: boolean;
}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { ProductViewModel } from "../../models/product-model/product-base-model";

@Injectable({
    providedIn: "root",
})
export class ProductService extends ApiServiceBase {
    private readonly PRODUCT_PATH = "/product/products";
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        super(httpClient, authService);
    }

    public getBy(
        productGroupCode: string,
        productTypeCode: string,
        usageTypeCode: string,
        finalFlag?: boolean
    ): Observable<ProductViewModel[]> {

        const params = new HttpParams()
            .set("productGroupCode", productGroupCode)
            .set("productTypeCode", productTypeCode)
            .set("usageTypeCode", usageTypeCode)
            .set("finalFlag", finalFlag == null ? '' : finalFlag);
        return this.httpGet<ProductViewModel[]>(this.PRODUCT_PATH, params).pipe(
            map((res) => res.body)
        );
    }
}

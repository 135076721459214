import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model';
import { InvoiceIndividualDetailComponent } from './invoice-individual-detail/invoice-individual-detail.component';
import { InvoiceIndividualSearchComponent } from './invoice-individual-search/invoice-individual-search.component';

@Component({
    selector: 'op-individual-document-invoice',
    templateUrl: './individual-document-invoice.component.html'
})
export class IndividualDocumentInvoiceComponent {
    public selectedIndividual: InvoiceIndividualSearchModel;

    @Output() onIndividualSelected = new EventEmitter<InvoiceIndividualSearchModel>();

    @ViewChild(InvoiceIndividualSearchComponent) searchComponent: InvoiceIndividualSearchComponent;
    @ViewChild(InvoiceIndividualDetailComponent) detailComponent: InvoiceIndividualDetailComponent;

    constructor() { }

    onSelectIndividual(individual: InvoiceIndividualSearchModel) {
        this.selectedIndividual = individual;
        this.detailComponent.getIndividualDetail(this.selectedIndividual);
        this.onIndividualSelected.emit(this.selectedIndividual);
    }

    onClearIndividual() {
        this.selectedIndividual = null;
        this.searchComponent.displaySearchResult = false;
        this.onIndividualSelected.emit(null);
    }

    get hideSearch(): boolean {
        return !!this.selectedIndividual
    }

    public focusSearchCondition() {
        this.searchComponent.focusSearchCondition();
    }

}

import { Injectable } from '@angular/core';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    CountryReferenceModel,
    LocationGroupModel,
    OrganisationGroupReferenceModel,
    OrganisationTypeReferenceModel,
    RegionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model'

import { OrganisationModel } from 'src/app/core/models/organisation-model/organisation.model';
import { OrganisationRoleReferenceModel } from 'src/app/core/models/organisation-model/organisation-role-reference.model';

@Injectable()
export class Select2DataMapperService {

    public countryReferenceToSelect2Data(countryReference: CountryReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let country of countryReference) {
            let data = new Select2Data();
            data.id = country.countryCode;
            data.text = country.countryName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public regionReferenceToSelect2Data(regionReference: RegionReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let region of regionReference) {
            let data = new Select2Data();
            data.id = region.regionCode;
            data.text = region.regionName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public locationGroupToSelect2Data(locationGroups: LocationGroupModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let locationGroup of locationGroups) {
            let data = new Select2Data();
            data.id = locationGroup.locationGroupId;
            data.text = locationGroup.locationGroupName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationToSelect2Data(organisations: OrganisationModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisation of organisations) {
            let data = new Select2Data();
            data.id = organisation.organisationId;
            data.text = organisation.organisationCallName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationTypeToSelect2Data(organisationTypeReferences: OrganisationTypeReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationType of organisationTypeReferences) {
            let data = new Select2Data();
            data.id = organisationType.organisationTypeCode;
            data.text = organisationType.organisationTypeName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationGroupToSelect2Data(organisationGroupReferences: OrganisationGroupReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationGroup of organisationGroupReferences) {
            let data = new Select2Data();
            data.id = organisationGroup.organisationGroupCode;
            data.text = organisationGroup.organisationGroupName;
            select2Data.push(data);
        }
        return select2Data;
    }

    public organisationRoleToSelect2Data(organisationRoleReferences: OrganisationRoleReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationRole of organisationRoleReferences) {
            let data = new Select2Data();
            data.id = organisationRole.organisationRoleCode;
            data.text = organisationRole.organisationRoleName;
            select2Data.push(data);
        }
        return select2Data;
    }

}
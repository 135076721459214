import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2'
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { CommentClassificationReferenceModel, CommentTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { CommentClassificationReferenceService, CommentTypeReferenceService } from "src/app/core/services/system-services";
import { select2CommentClassification, select2CommentType } from "../shared/comment-configuration";
import { CommentView } from '../shared/comment.view';
import { CommentMediaFileView } from '../shared/comment-media-file.view';
import { MediaViewModel } from '../../../models/media-model';
import { MediaMapperService } from '../../media/shared/media-mapper.service';
import { MediaService } from '../../../services/media-services';
import { MediaFileView } from '../../media/shared/media-file.view';
import { MediaFilePreviewService } from '../../../services/media-services/media-file-preview-service';
import { MediaTypeFileTypeService, MediaTypeReferenceService } from 'src/app/core/services/reference-service/reference-media-service';
import { MediaTypeFileTypeModel, MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaView } from '../../media/shared/media.view';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'op-comment-detail-image',
    templateUrl: './comment-detail-image.component.html',
    providers: [MediaMapperService]
})
export class CommentDetailImageComponent implements OnInit, OnChanges {
    @Input("data") commentsData: CommentView;
    @Input("disable") disableControl: boolean;
    @Input('commentFile') commentFile: MediaFileView[] = [];

    @ViewChild('swal') public readonly swal!: SwalComponent;
    @ViewChild('mediaForm') mediaForm: NgForm;

    public commentTypeOption: any;
    public commentClassificationOption: any;
    public commentTypeReference$ = new BehaviorSubject<CommentTypeReferenceModel[]>(null);
    public commentClassificationReference$ = new BehaviorSubject<CommentClassificationReferenceModel[]>(null);
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public urlPreviewFile: any;
    public viewerType: string;
    public mediaFiles: MediaFileView[] = [];
    public allowedZoom: boolean = false;
    public mediaModel = new MediaView();
    public processing: boolean = false;

    public maximumFileSize: boolean = false;
    public fileTypeInvalid: boolean = false;
    public canPreviewFile: boolean = false;
    public canDownloadFile: boolean = false;
    public fileNotFound: boolean = false;
    public fileChange: boolean = false;

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private commentTypeReferenceService: CommentTypeReferenceService,
        private commentClassificationReferenceService: CommentClassificationReferenceService,
        private mediaService: MediaService,
        private mediaMapperService: MediaMapperService,
        private mediaFilePreviewService: MediaFilePreviewService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaTypeReferenceService: MediaTypeReferenceService,) {

        this.setOptionControl();
    }

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["commentFile"]) {
            this.getCommentMediaState(this.commentFile);
        }
    }

    private fetchData() {
        forkJoin({
            commentTypeReferences: this.commentTypeReferenceService.getCommentTypeReferences(),
            commentClassificationReferences: this.commentClassificationReferenceService.getCommentClassificationReferences(),
            mediaTypeFileTypes: this.mediaTypeFileTypeService.getAll(),
            mediaTypeReferences: this.mediaTypeReferenceService.getAll()
        })
        .subscribe(
            ({
                commentTypeReferences,
                commentClassificationReferences,
                mediaTypeFileTypes,
                mediaTypeReferences
            }) => {
                this.commentTypeReference$.next(commentTypeReferences);
                this.commentClassificationReference$.next(commentClassificationReferences);
                this.mediaTypeFileType$.next(mediaTypeFileTypes);
                this.mediaTypeReference$.next(mediaTypeReferences);
                this.getCommentMedia(this.commentsData.commentId);
                this.getCommentMediaState(this.commentFile);
            }
        )
    }
    private setOptionControl() {
        this.commentTypeOption = select2CommentType;
        this.commentClassificationOption = select2CommentClassification;
    }
    public getCommentMedia(commentId: string) {
        if (!commentId || this.commentFile?.length > 0) {
            return;
        }
        const entityName: string = "COMMENT";
        this.mediaService.getByEntityNameAndAssociation(entityName, commentId)
            .subscribe(
                (response: MediaViewModel[]) => {
                    if (!response ||
                        response.length == 0 ||
                        !response[0].mediaContents ||
                        response[0].mediaContents.length == 0) {

                        return;
                    }
                    this.getFileContent(response[0].mediaContents[0].mediaContentId,
                        response[0].organisationId);
                }
            )
    }

    private getFileContent(mediaContentId: string, organisationId: string) {

        this.mediaService.getMediaFile(mediaContentId)
            .subscribe(
                (response: HttpResponse<Blob>) => {
                    var contentType = response.headers.get('Content-Type');
                    var fileName = this.mediaMapperService.getfileName(organisationId, mediaContentId, contentType);
                    var fileData = new File([response.body], fileName, { type: contentType });
                    var mediaFileView = this.createMediaFileView(fileData);

                    this.selectMediaDisplay(mediaFileView, contentType, fileName);
                }
            )
    }

    private selectMediaDisplay(mediaFileView: MediaFileView, contentType: string, fileName: string) {
        if (this.mediaMapperService.isImage(contentType)) {
            this.allowedZoom = true;
            this.getPicFilePreview(mediaFileView);
        } else {
            this.allowedZoom = false;
            this.getNonePicFilePreview(mediaFileView, contentType);
        }
    }
    private createMediaFileView(fileData: File): MediaFileView {
        let mediaFileView = new MediaFileView();
        mediaFileView.mediaFile = fileData;

        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            mediaFileView.mediaFileBase64 = reader.result;
        };
        return mediaFileView;
    }
    private addFileToDropZone(mediaFile: MediaFileView) {
        this.mediaFiles = [];

        this.mediaFiles.push(mediaFile);
        this.changeDetectionRef.detectChanges();
        this.refreshPage();
    }

    private getPicFilePreview(mediaFileView: MediaFileView) {
        mediaFileView.mediaFilePreview = mediaFileView.mediaFile;
        this.changeDetectionRef.markForCheck();
        this.addFileToDropZone(mediaFileView);
    }

    private getPicFilePreviewState(mediaFile: MediaFileView, contentType: string) {
        var fileName = this.mediaMapperService.getPicPreview(contentType)
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFile.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.addFileToDropZone(mediaFile);
                        this.refreshPage();
                    }
                }
            );
    }

    private getNonePicFilePreview(mediaFileView: MediaFileView, contentType: string) {
        let fileName = this.mediaMapperService.getPicPreview(contentType);
        this.mediaFilePreviewService.getMediaFilePreview(fileName)
            .subscribe(
                (response) => {
                    if (response.status == 200) {
                        mediaFileView.mediaFilePreview = new File([response.body], fileName, { type: "image/jpeg" });
                        this.changeDetectionRef.markForCheck();
                        this.addFileToDropZone(mediaFileView);
                    }
                }
            );
    }
    private refreshPage() {
        const DROPZONE_BINDING_DELAY_TIME = 200;
        setTimeout(() => {
            try {
                this.changeDetectionRef.detectChanges();
            }
            catch (e) {
                e = null;
            }
        }, DROPZONE_BINDING_DELAY_TIME);
    }
    public downloadClick($event: MouseEvent) {
        $event.preventDefault();
        this.downloadFile();
    }
    private downloadFile() {
        if (this.mediaFiles?.length) {
            const blob = this.mediaFiles[0].mediaFile;
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.mediaFiles[0].mediaFile.name;
            anchor.href = url;
            anchor.click();
        }
    }

    public previewFile($event: MouseEvent) {
        $event.preventDefault();
        if (this.mediaFiles?.length) {
            if (this.mediaMapperService.isImage(this.mediaFiles[0].mediaFile.type)) {
                this.previewImageFile();
            }
            else {
                this.previewOtherFile();
            }
        }
    }
    private previewImageFile() {
        const blob = this.mediaFiles[0].mediaFile;
        const url = window.URL.createObjectURL(blob);
        Swal.fire({
            html: '<div class="col-12"><img src="' + url + '" style="max-width:700px;" ></div>',
            width: '800px',
            showCloseButton: true,
            showConfirmButton: false
        });
    }

    private previewOtherFile() {
        const blob = this.mediaFiles[0].mediaFile;
        if (this.mediaMapperService.isVideo(blob.type) ||
            this.mediaMapperService.isPdf(blob.type)) {
            this.viewerType = "url";
            this.urlPreviewFile = window.URL.createObjectURL(blob);
        }
        else if (this.mediaMapperService.isXLS(blob.type)) {
            this.viewerType = "google";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        else {
            this.viewerType = "office";
            this.urlPreviewFile = this.mediaFilePreviewService.getURLDocAzureStorage(this.mediaModel.mediaContentId);
        }
        this.swal.fire(
        );
    }

    public commentTypeChange(value) {
        this.commentsData.commentTypeCode = value;
        this.commentsData.commentTypeName = this.commentTypeReference$.value.filter(it => it.commentTypeCode == value)[0]?.commentTypeName ?? null;
    }

    public commentClassificationChange(value) {
        this.commentsData.commentClassificationCode = value;
        this.commentsData.commentClassificationName = this.commentClassificationReference$.value.filter(it => it.commentClassificationCode == value)[0]?.commentClassificationName ?? null;
    }

    onSelect(event) {
        this.singleFileProcess(event.addedFiles[0]);
    }

    private singleFileProcess(addedFile: File) {
        this.clearFile();
        if (!this.validateFile(addedFile)) {
            return;
        }
        this.fileChange = true;
        let mediaFile = this.createMediaFileView(addedFile);
        this.displayFilePreview(mediaFile, addedFile.type);
    }

    private validateFile(f: File): Boolean {
        if (!this.validateFileType(f)) {
            this.fileTypeInvalid = true;
            return false;
        } else if (!this.validateFileSize(f)) {
            this.maximumFileSize = true;
            return false;
        }
        this.fileTypeInvalid = false;
        this.maximumFileSize = false;
        return true;
    }

    private validateFileType(f: File): Boolean {
        if (!this.mediaTypeFileType$.value) {
            return false;
        }
        var fileExt = this.mediaMapperService.getFileExt(f.name);
        var filterFileTypeValid = this.mediaTypeFileType$.value.filter(x => x.fileTypeCode.toLowerCase() == fileExt.toLowerCase());
        if (filterFileTypeValid?.length) {
            return this.checkFileTypeValid(filterFileTypeValid);
        }
        else {
            return false;
        }
    }

    private checkFileTypeValid(fileTypeValid: MediaTypeFileTypeModel[]): boolean {
        if (this.mediaModel.mediaTypeCode) {
            var filter = fileTypeValid.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);

            if (filter.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            this.assignFileTypeToModel(fileTypeValid);
            return true;
        }
    }

    private assignFileTypeToModel(fileTypeValid: MediaTypeFileTypeModel[]) {
        if (!this.mediaTypeReference$.value) {
            return;
        }
        var mediaTypeCode = fileTypeValid[0].mediaTypeCode;
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == mediaTypeCode);
        if (filter.length > 0) {
            this.mediaModel.mediaUseCode = filter[0].mediaUseCode;
            this.filterMediaType(this.mediaModel.mediaUseCode);
            this.mediaModel.mediaTypeCode = mediaTypeCode;
        }
    }

    private filterMediaType(mediaUseCode: string) {
        if (this.mediaTypeReference$.value) {
            this.mediaTypeReferenceFilter$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
            if (mediaUseCode) {
                this.filterMediaTypeData(mediaUseCode);
            }
            else {
                this.clearMediaType();
            }
        }
    }

    private filterMediaTypeData(mediaUseCode: string) {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaUseCode == mediaUseCode);
        this.mediaTypeReferenceFilter$.next(filter);
        this.assignToMediaTypeCode(filter)
    }

    private assignToMediaTypeCode(filter: MediaTypeReferenceModel[]) {
        if (filter.length != 0) {
            this.mediaModel.mediaTypeCode = filter[0].mediaTypeCode;
        }
        else {
            this.clearMediaType();
        }
    }

    private clearMediaType() {
        this.mediaModel.mediaTypeCode = null;
        this.mediaModel.mediaTypeName = null;
    }

    private validateFileSize(f: File): Boolean {
        if (this.mediaTypeReference$) {
            var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.mediaModel.mediaTypeCode);
            if (filter.length > 0 && filter[0].maximumSize && f.size <= filter[0].maximumSize) {
                return true;
            }
        }

        return false;
    }

    private displayFilePreview(mediaFile: MediaFileView, contentType: string) {
        this.canDownloadFile = true;
        if (this.mediaMapperService.isImage(contentType)) {
            this.allowedZoom = true;
            mediaFile.mediaFilePreview = mediaFile.mediaFile;
            this.addFileToDropZone(mediaFile);
            this.refreshPage();
        }
        else if (this.mediaMapperService.isVideo(contentType) || this.mediaMapperService.isPdf(contentType)) {
            this.allowedZoom = true;
            this.getPicFilePreviewState(mediaFile, contentType);
        }
        else if (this.mediaMapperService.isDoc(contentType)) {
            this.allowedZoom = (this.checkCanPreviewFile());
            this.getPicFilePreviewState(mediaFile, contentType);
        } else {
            this.getPicFilePreviewState(mediaFile, contentType);
        }
    }

    private checkCanPreviewFile(): boolean {
        if (this.fileChange) {
            return false;
        }
        else {
            return true;
        }
    }

    private clearFile() {
        this.mediaFiles = [];
        this.maximumFileSize = false;
        this.fileTypeInvalid = false;
        this.allowedZoom = false;
        this.canDownloadFile = false;
        this.fileNotFound = false;
        this.clearMediaType();
    }

    private getCommentMediaState(media: MediaFileView[]) {
        this.clearFile();
        if (media.length > 0) {
            media.forEach(element => {
                this.mediaFiles.push(element);
            });
            this.refreshPage();
            this.singleFileProcess(this.mediaFiles[0].mediaFile);
        }
    }

    public displayPreviewFileType() {
        if (this.mediaFiles?.length) {
            let fileTypeDisplay = this.mediaMapperService.getFileExt(this.mediaFiles[0].mediaFile.name);
            return fileTypeDisplay.toUpperCase();
        }
    }

    public displayPreviewFileSize() {
        if (this.mediaFiles?.length) {
            let totalBytes = this.mediaFiles[0].mediaFile.size;
            var sizeDisplay = this.mediaMapperService.formatBytes(totalBytes);
            return sizeDisplay;
        }
    }

    onRemove(event) {
        this.mediaFiles.splice(this.mediaFiles.indexOf(event), 1);
        this.fileChange = true;
        this.clearFile();
    }

    public getCommentsData(): CommentView {
        this.commentsData.commentClassificationName = this.commentClassificationReference$.value
            ?.find(item => item.commentClassificationCode == this.commentsData.commentClassificationCode)
            ?.commentClassificationName;
        this.commentsData.commentTypeName = this.commentTypeReference$.value
            ?.find(item => item.commentTypeCode == this.commentsData.commentTypeCode)
            ?.commentTypeName;
        return this.commentsData;
    }

    public getCommentsFiledata() {
        return this.mediaFiles;
    }

    public isValidForm(): boolean {
        this.processing = true;
        if (!this.mediaForm.valid) {
            return false;
        }
        this.processing = false;
        return true;
    }

    get commentTypeCodeRequired(): boolean {
        return [
            this.commentsData.commentClassificationCode,
            this.commentsData.commentText,
            this.mediaFiles?.length
        ].some(val => val)
    }
}
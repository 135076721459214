import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressModel } from 'src/app/core/components/address/shared/address.model';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { InvoiceOrganisationDetailModel, InvoiceOrganisationSearchModel, OrganisationModel } from 'src/app/core/models/organisation-model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrganisationService } from 'src/app/core/services/organisation-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { InvoiceOrganisationDetailMapperService } from './invoice-organisation-detail-mapper.service';

const select2WithoutSearchOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

@Component({
    selector: 'op-invoice-organisation-detail',
    templateUrl: './invoice-organisation-detail.component.html',
    providers: [
        InvoiceOrganisationDetailMapperService
    ]
})
export class InvoiceOrganisationDetailComponent implements OnInit {
    public generalfocusing: boolean = false;
    public addressfocusing: boolean = false;
    public organisation: InvoiceOrganisationDetailModel;
    public locationSelectData: Select2Data[] = [];
    public usageTypeSelectData: Select2Data[] = [];
    public select2Option: any;
    public useAddress: AddressModel[] = [];
    public addressLocationSelectData: Select2Data[] = [];
    public countrySelectData: Select2Data[] = [];
    public addressTypeSelect: Select2Data[] = [];

    @Input() languageTranslationSets: LanguageTranslationSetViewModel[];
    @Input() statusReferences: StatusReferenceModel[];
    @Output() onClearOrganisation = new EventEmitter();

    constructor(private organisationService: OrganisationService,
        private invoiceOrganisationDetailMapperService: InvoiceOrganisationDetailMapperService,
        private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.organisation = this.defaultOrganisationDetail;
        this.select2Option = select2WithoutSearchOption;
    }

    get defaultOrganisationDetail(): InvoiceOrganisationDetailModel {
        return {
            organisationId: null,
            organisationCode: null,
            organisationCallName: null,
            organisationLegalName: null,
            languageTranslationSetId: null,
            providerAccountNumber: null,
            taxId: null,
            providerIataCode: null,
            providerIcaoCode: null,
            passwordExpiryDays: null,
            passwordReuseCycle: null,
            teletypeAddress: null,
            websiteAddress: null,
            locationId: null,
            locationName: null,
            usageTypeCode: null,
            usageTypeName: null,
            commitBy: null,
            commitDateTime: null,
            statusCode: null,
            organisationAddress: {
                addressId: null,
                individualId: null,
                useAddressId: null,
                countryCode: null,
                addressLine1: null,
                addressLine2: null,
                postalCode: null,
                state: null,
                province: null,
                city: null,
                primaryFlag: false,
                locationId: null,
                addressTypeCode: null,
                addressTypeName: null,
                countryName: null,
                locationName: null,
                organisationId: null
            }
        }
    }

    onClearDetail() {
        this.organisation = this.defaultOrganisationDetail;
        this.locationSelectData = [];
        this.usageTypeSelectData = [];
        this.useAddress = [];
        this.onClearOrganisation.emit();
    }

    get address(): AddressModel {
        return this.organisation?.organisationAddress;
    }

    getOrganisationDetail(organisation: InvoiceOrganisationSearchModel) {
        this.organisationService.searchOrganisationById(organisation.organisationId)
            .subscribe(
                (response: OrganisationModel) => {
                    this.organisation = this.invoiceOrganisationDetailMapperService.organisationModelToInvoiceOrganisationDetailModel(response, organisation);
                    this.getSavedDataSelect(this.organisation);
                    this.getUseAddress(response.organisationAddresses, this.organisation);
                    this.changeDetector.detectChanges();
                }
            )
    } 

    private getSavedDataSelect(organisation: InvoiceOrganisationDetailModel) {
        this.getSavedLocationSelect(organisation);
        this.getSavedUsageType(organisation);
        this.getAddressTypeSelect(organisation.organisationAddress);
        this.getAddressLocation(organisation.organisationAddress);
        this.getCountrySelect(organisation.organisationAddress);
    }

    private getSavedLocationSelect(organisation: InvoiceOrganisationDetailModel) {
        if (!organisation?.locationId) {
            return;
        }
        this.locationSelectData.push(new Select2Data(organisation?.locationId, organisation?.locationName))
    }

    private getSavedUsageType(organisation: InvoiceOrganisationDetailModel) {
        if (!organisation?.usageTypeCode) {
            return;
        }
        this.usageTypeSelectData.push(new Select2Data(organisation?.usageTypeCode, organisation?.usageTypeName))
    }

    private getUseAddress(addresses: AddressModel[], organisation: InvoiceOrganisationDetailModel) {
        if (!addresses || !organisation?.organisationAddress?.useAddressId) {
            return;
        }
        let useAddress = addresses.find(address => address.addressId === organisation.organisationAddress.useAddressId);
        this.useAddress.push(useAddress);
    }

    private getAddressTypeSelect(invoiceAddress: AddressModel) {
        if (!invoiceAddress.addressTypeCode) {
            return;
        }
        this.addressTypeSelect.push(new Select2Data(invoiceAddress.addressTypeCode, invoiceAddress.addressTypeName));
    }

    private getAddressLocation(invoiceAddress: AddressModel) {
        if (!invoiceAddress.locationId) {
            return;
        }
        this.addressLocationSelectData.push(new Select2Data(invoiceAddress.locationId, invoiceAddress.locationName));
    }

    private getCountrySelect(invoiceAddress: AddressModel) {
        if (!invoiceAddress.countryCode) {
            return;
        }
        this.countrySelectData.push(new Select2Data(invoiceAddress.countryCode, invoiceAddress.countryName))
    }

}

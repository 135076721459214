import { Injectable } from "@angular/core";
import { FocusingDirective } from "./focusing.directive";

@Injectable({
    providedIn: 'root'
})
export class FocusingService {
    private directives = new Array<FocusingDirective>();
    public push(focusingDirective: FocusingDirective) {
        this.directives.push(focusingDirective);
    }

    get focusingDirectives(): FocusingDirective[] {
        return this.directives;
    }

    public focus(directive: FocusingDirective) {
        if (!directive) {
            return;
        }
        this.directives.forEach(dir => {
            if (dir == directive) {
                if (!dir.focusing) {
                    dir.scrollToElement();
                }
                dir.focusing = true;
                dir.focusingChange.emit(true);
            } else {
                dir.focusing = false;
                dir.focusingChange.emit(false);
            }
        })
    }

    public remove(id: string) {
        this.directives = this.directives.filter(dir => dir.id != id);
    }
}
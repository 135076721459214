import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from './../../../core/authentication/auth.service';
import { OrganisationService } from './../../../core/services/organisation-services/organisation.service';
import { NavigationService } from './../../../shared/utils/navigation/navigation.service';
import * as AuthActions from './../../../store/auth/auth.actions';
import { selectUsername } from './../../../store/auth/auth.selectors';
import { UserAccountOrganisationService } from 'src/app/core/services/user-account-services/user-account-organisation.service';
import { LoadingSpinnerService } from './../../../shared/layout/loading-spinner/loading-spinner.service';
import { USER_LOGON_NAME } from '../shared/constants/auth.constant';

@Component({
  selector: 'smart-authentication',
  templateUrl: './authentication.component.html'
})
export class AuthenticationComponent implements OnInit, OnDestroy {
  @ViewChild('verifyCodeInput') private elementRef: ElementRef;

  public verifyCode = new UntypedFormControl('');
  public qrCodeUrl: string;
  public isVerifyFailed: boolean = false;

  public readonly remarkText = 'Please enter the 6-digit verification code your mobile application “oops.solution” generated';
  public readonly remarkErrorText = 'Verification code is invalid or expired.';

  private userAccountId: string;
  private username: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private navigationService: NavigationService,
    private organisationService: OrganisationService,
    private authService: AuthService,

    private store: Store<any>,
    private userAccountOrganisationService: UserAccountOrganisationService,
    private loadingSpinner: LoadingSpinnerService
  ) {
    this.store.select(selectUsername).pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.username = state
    })
  }

  public ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
  }

  ngOnInit(): void {
    this.multiFactorAuthentication()
  }

  private multiFactorAuthentication() {
    const username = this.username;
    if (username) {
      this.userAccountOrganisationService.searchUserAccountOrganisationByUserLogon(username)
        .subscribe(resp => {
          const userDetail = resp[0];
          this.userAccountId = userDetail.userAccountId;
          const userInfo = { userAccountId: this.userAccountId, firstName: userDetail.firstName, lastName: userDetail.lastName }
          this.store.dispatch(AuthActions.userLogonName(userInfo))
          this.authService.sessionStorageSetItem(USER_LOGON_NAME, JSON.stringify(userInfo));

          if (!userDetail.multiFactorAuthenticationDateTime) {
            this.generateQrCode();
          }

        })
    }
  }

  public onVerifyCode() {
    const code = this.verifyCode.value;

    this.organisationService.verifyOtpCode(code).subscribe(result => {
      if (result) {
        this.store.dispatch(AuthActions.twoStepVerified({ twoStepVerified: result }))
        if (this.qrCodeUrl) {
          // update user account
          this.userAccountOrganisationService.updateMultiFactorAuthenDateTime(this.userAccountId).subscribe(resp => {
            if (resp) {
              this.organisationService.checkByPassOrgPage(this.userAccountId);
            }
          })
        } else {
          this.organisationService.checkByPassOrgPage(this.userAccountId);
        }
      } else {
        this.isVerifyFailed = true;
      }
    })
  }

  updateUserDataAndNavigate() {
    this.authService.updateVerifyCode()
    this.goToSelectOrg()
  }

  goToSelectOrg() {
    this.navigationService.navigate(
      'select-organisation',
      'Select Organisation',
      true
    );
  }

  public generateQrCode() {
    this.organisationService.getQrCodeUrl(this.username).subscribe(resp => {
      if (resp) {
        this.qrCodeUrl = resp.url;
      }
    })
  }

  ngOnDestroy(): void {
    this.loadingSpinner.hide();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

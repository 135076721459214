export const select2Status = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Location = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Translation = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2LocationGroupOption = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    ajax: null
}

export const select2TypeOption = {
    placeholder: "<Type>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true,
    ajax: null
}

export const select2RoleOption = {
    placeholder: "<Role>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true,
    ajax: null
}

export const select2GroupOption = {
    placeholder: "<Group>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true,
    ajax: null
}

export const select2AttributeOption = {
    placeholder: "<Group>",
    allowClear: true,
    multiple:"multiple",
    theme: "select2-builder-multiple-gray",
    width :'auto',
    dropdownAutoWidth : true,
    ajax: null
}

export const select2AddressType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2PhoneType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2UseAddress = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1,
    templateResult: function(address) {
        var text = address.text.split(";");
        var addressType = text[0];
        var addressLine1 = text[1];
        var addressLine2 = text[2];
        var city = text[3];
        var province = text[4];
        var postalCode = text[5];

        return $('<p><b>' + addressType + '</b></p>' + 
                 '<p>' + addressLine1 + '</p>' +
                 '<p>' + addressLine2 + '</p>' +
                 '<p>' + city + ' ' + province + ' ' + postalCode + '</p>' +
                 '<p>--------------------------</p>');
    },
    templateSelection: function(address) {
        return address.text.split(";")[0];
    },
}

export const select2CountryType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2Language = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2PhonePrefix = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null,
    function(vaule) {
        return vaule;
    },
}

export const select2CommunicationOtherType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommentType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommentClassification = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2UsageType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

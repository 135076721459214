import { ApiServiceBase } from '../../base/api-service-base';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  MerchandizeAddCommandModel,
  MerchandizeEditCommandModel,
  MerchandizeViewModel
} from '../../models/product-model/merchandize-model';

import { AuthService } from '../../authentication/auth.service';
import { Observable } from 'rxjs';
import { MerchandizeSearchCommandModel } from '../../models/product-model/merchandize-model/merchandize-seach-command.model';


@Injectable({
  providedIn: 'root'
})
export class MerchandizeService extends ApiServiceBase {
  private readonly PRODUCT_PATH = "/product"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public addProduct(model: MerchandizeAddCommandModel) {
    return this.httpPost(this.PRODUCT_PATH + "/merchandize", model);
  }

  public editProduct(model: MerchandizeEditCommandModel) {
    return this.httpPut(this.PRODUCT_PATH + "/merchandize", model);
  }

  public getByProductId(productId) {
    return this.httpGet<any>(this.PRODUCT_PATH + "/merchandize/" + productId, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public searchProduct(productName: string, productDescription: string, productGroupCode: string, productTypeCode: string): Observable<MerchandizeViewModel[]> {
    let params = new HttpParams()
      .set("productName", productName)
      .set("productDescription", productDescription)
      .set("productGroupCode", productGroupCode)
      .set("productTypeCode", productTypeCode);

    return this.httpGet<MerchandizeViewModel[]>(this.PRODUCT_PATH + "/merchandize", params)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public search(searchCommand: MerchandizeSearchCommandModel): Observable<MerchandizeViewModel[]> {
    return this.httpPost<MerchandizeViewModel[]>(this.PRODUCT_PATH + '/merchandize/search', searchCommand)
      .pipe(
        map(res => res.body)
      )
  }

}
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { OrganisationService } from '../../services/organisation-services';
import { OrganisationModel } from '../../models/organisation-model';


const ORGANISATION_INDIVIDUAL_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

@Component({
    selector: 'op-organisation-selection',
    templateUrl: './organisation-selection.component.html',
    providers: [

    ]
})
export class OrganisationSelectionComponent implements OnInit {

    public _value: string | string[];

    public organisations$ = new BehaviorSubject<OrganisationModel[]>(null);
    public organisationIndividualOption = ORGANISATION_INDIVIDUAL_OPTION;
    public loadSuccess: boolean = false;

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input('disabled') disableFlag: boolean = false; 
    @Input('tabIndexNo') tabIndexNo: number = null;
    @Input('blurAfterSelected') blurAfterSelected: boolean = false;
    @Output()
    dataChange: EventEmitter<string> = new EventEmitter();
    @Output() dataText: EventEmitter<string> = new EventEmitter();

    constructor(private organisationService: OrganisationService) {

    }
    ngOnInit(): void {
        this.loadOrganisation();
    }

    private loadOrganisation(): any {
        const activeStatus = "A";
        const dataUsageType = "DATA";
        const securityCodeForAll = "ORGANISATIONALL";
        
        this.loadSuccess = false;
        
        let searchRequest = new OrganisationModel();
        searchRequest.statusCode = activeStatus;
        searchRequest.searchStatusCode = activeStatus;
        searchRequest.searchUsageTypeCode = dataUsageType;

        this.organisationService
            .advanceSearchOrganisation(searchRequest, securityCodeForAll)
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.organisations$.next(responses.filter(x => x.usageTypeCode = 'DATA'));
                    this.loadSuccess = true;
                });
    }
    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);

        let organisationCallName = "";

        if ($event) {
            this.organisations$.value.find(o => o.organisationId == $event).organisationCallName
        }

        this.dataText.emit(organisationCallName);
    }
}
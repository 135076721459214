import { Directive, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup, NgModel, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[requiredAllOrNone]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: RequiredAllOrNoneDirective,
            multi: true
        }
    ]
})

export class RequiredAllOrNoneDirective implements Validator {
    @Input('requiredAllOrNone') keyCollections: Array<string[]>;
    
    validate(formGroup: UntypedFormGroup): ValidationErrors {
        if (this.keyCollections?.length) {
            for (let keys of this.keyCollections) {
                let values = keys.map(key => formGroup.controls[key]?.value)
                if (!(values.every(val => val) || values.every(val => !val))) {
                    for (let key of keys) {
                        let control = formGroup.controls[key]
                        if (control) {
                            if (control.value) {
                                control.setErrors(null)
                            } else {
                                control.setErrors({
                                    'required': true
                                })
                            }
                        }
                    }
                }
            }
        }
        
        return formGroup;
    }
}
export const select2SearchFilter = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchOrganisation = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchUsageType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchCity = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchCountry = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2SearchStatus = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchGender = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchAgeGroup = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CountryReferenceModel, IdentityDocumentReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { individualExpireDocumentCalendarOption } from "src/app/modules/order/shared";
import { DocumentView } from "../shared/document-view";
import { select2Country, select2DocumentType } from "../shared/select2-configuration";
 
@Component({
    selector: 'op-insight-document-detail',
    templateUrl: './document-detail.component.html'
})
export class InsightDocumentDetailComponent {
    readonly ERROR_REQUIRED = ' is required.'
    private readonly MIN_DATE = "1753-1-1";
    private readonly MAX_DATE = "9999-12-31";

    public documentTypeOption: any;
    public countryOption: any;
    public processing: boolean = false;
    public calendarOption: any;

    @Input() insightDocument = {} as DocumentView;
    @Input() identityDocumentTypeReferences: IdentityDocumentReferenceModel[];
    @Input() countryReferences: CountryReferenceModel[];
    @Output() onUpdateFormStatus = new EventEmitter<boolean>();
    
    @ViewChild('documentDetailForm') documentDetailForm: NgForm;

    constructor(private changeDetectionRef: ChangeDetectorRef) {
        this.setOptionControl();
        this.setDatetimePicker();
    }

    private setOptionControl() {     
        this.documentTypeOption = select2DocumentType;
        this.countryOption = select2Country;
    }

    setProcessing(flag: boolean) {
        this.processing = flag;
        this.changeDetectionRef.detectChanges();
    }

    getInsightDocument() : DocumentView {
        if (!this.isValidForm()) {
            return null;
        }
        return this.insightDocument;
    }

    public documentTypeChange(value) {
        this.insightDocument.identityDocumentCode = value;
        this.insightDocument.identityDocumentName = this.identityDocumentTypeReferences.filter(it => it.identityDocumentCode == value)[0]?.identityDocumentName ?? null;
    }

    public countryCodeChange(value) {
        this.insightDocument.countryCode = value;
        this.insightDocument.countryName = this.countryReferences.filter(it => it.countryCode == value)[0]?.countryName ?? null;
    }

    public onExpiryDateChanged(e) {
        this.insightDocument.expiryDate = e
    }

    public onIssueDateChanged(e) {
        this.insightDocument.issueDate = e
    }

    public isValidForm() : boolean {
        this.processing = true;
        
        if(!this.documentDetailForm.valid) {
            this.onUpdateFormStatus.emit(false);
            return false;
        }

        this.processing = false;
        this.onUpdateFormStatus.emit(true);
        return true;
    }

    private setDatetimePicker() {
        this.calendarOption = individualExpireDocumentCalendarOption;
        this.calendarOption.minDate = new Date(this.MIN_DATE);
        this.calendarOption.maxDate = new Date(this.MAX_DATE);
    }

    getErrorMessageForm(): string {
        if (!this.documentDetailForm.controls['documentTypeCode'].value) {
            return 'Document Type' + this.ERROR_REQUIRED;
        } else if (!this.documentDetailForm.controls['identityDocumentNumber'].value) {
            return 'Document Number' + this.ERROR_REQUIRED;
        }
    }
}
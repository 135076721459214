import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'counterText'})
export class CounterTextPipe implements PipeTransform {
  transform(value: string, maxLength = 20): string {
    if (value) {
        return (maxLength - value.length).toString() + "/" + maxLength.toString();
    }
    
    return maxLength.toString() + "/" + maxLength.toString();
  }
}
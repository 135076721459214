import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ExchangeRateAmountModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ExchangeRateAmountService } from 'src/app/core/services/system-services';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { ExchangeRateAmountView } from '../exchange-rate-amount-list/exchange-rate-amount.view';
import { ExchangeRateAmountHistoryMapperService } from './exchange-rate-amount-history.mapper.service';
import { ExchangeRateAmountHistoryView } from './exchange-rate-amount-history.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-exchange-rate-amount-history',
    templateUrl: './exchange-rate-amount-history.component.html',
    providers: [ExchangeRateAmountHistoryMapperService]
})
export class ExchangeRateAmountHistoryComponent implements OnChanges, AfterViewInit {
    public readonly SPINNER_NAME: string = "exchangeRateHistorySpinner";
    public readonly SPINNER_FULL_SCREEN: boolean = false;
    public readonly EXPORT_FILE_NAME = "ExchangeRateHistory";
    public readonly precision  = 6;
    public focusing = false;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
        fontSize: 7
    }
    @Input() userSecurity: SecurityGroupSecurityModel
    @Input() selectedItem: ExchangeRateAmountView;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
    public exchangeRateAmountHistoryViews: ExchangeRateAmountHistoryView[];
    public loadingNotifier = new LoadingNotifier();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private exchangeRateAmountService: ExchangeRateAmountService,
        private mapperService: ExchangeRateAmountHistoryMapperService) {
            this.getValidDateString = this.getValidDateString.bind(this);
            this.getCommitDateTimeString = this.getCommitDateTimeString.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedItem']) {
            this.getExchangeRateAmountHistory();
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data, data.no);
            })
        });
    }

    private mapData(data: any, no: number): any {
        return {
            No: no,
            Base: data.baseCurrencyCode,
            Exchange: data.exchangeCurrencyCode,
            Factor: data.factor,
            Sell: data.sell,
            Buy: data.buy,
            ValidFrom: data.validFrom,
            CommitBy: data.commitByName,
            CommitDateTime: data.commitDateTime
        };
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    public getDateString(d) {
        var date = new Date(d);
        return date.toLocaleString([], { hour12: true, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    } 

    public getCommitDateTimeString(rowData) {
        if (rowData.commitDateTime) {
            return this.getDateString(rowData.commitDateTime);
        }
        return null;
    }

    private getExchangeRateAmountHistory() {
        if (this.selectedItem) {
            this.loadingNotifier.show(this.SPINNER_NAME);
            this.exchangeRateAmountService.getByExchangeRateId(this.selectedItem.exchangeRateId)
                .subscribe(
                    (responses: ExchangeRateAmountModel[]) => {
                        this.exchangeRateAmountHistoryViews = this.mapperService.toViews(responses);
                        this.loadingNotifier.hide(this.SPINNER_NAME);
                        this.changeDetectorRef.detectChanges();
                    }
                )
        } else {
            this.exchangeRateAmountHistoryViews = [];
            this.changeDetectorRef.detectChanges();
        }
    }

    public getValidDateString(rowData) {
        if (rowData.validFrom) {
            return this.getDateString(rowData.validFrom);
        }
        return null;
    }
}
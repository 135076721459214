import { Component, EventEmitter, Input, Output } from "@angular/core";
import { map } from "rxjs/operators";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceModel } from "../../../shared/models";
import { PriceService } from "../../../shared/price.service";
import { PriceDimensionTemplateOption } from "../shared/options/select2-price-dimension.options";

@Component({
    selector: "op-template",
    templateUrl: "./template.component.html",
})
export class TemplateComponent {
    private readonly ACTIVE_STATUS = "A";
    private readonly PRICE_DIMENSION_TEMPLATE_CODE = "PRICEDIMENTEMPLATE";

    priceDimensionTemplateOption = PriceDimensionTemplateOption;

    priceDimensionTemplateViews$ = this.priceService
        .search(this.createPriceDimensionTemplateSearchModel())
        .pipe(
            map((res) =>
                res
                    .sort((a, b) => this.sortByName(a.priceName, b.priceName))
                    .map((r) => new Select2Data(r.priceId, r.priceName))
            )
        );
    
    focusing = false;

    @Output() priceDimensionTemplateChange = new EventEmitter<string>();

    constructor(private priceService: PriceService) {}

    private createPriceDimensionTemplateSearchModel(): PriceModel {
        let model = new PriceModel();
        model.status = this.ACTIVE_STATUS;
        model.usageTypeCode = this.PRICE_DIMENSION_TEMPLATE_CODE;
        return model;
    }

    private sortByName(priceNameA: string, priceNameB: string): number {
        if (priceNameA < priceNameB) {
            return -1;
        }
        if (priceNameA > priceNameB) {
            return 1;
        }
        return 0;
    }

    onPriceDimensionTemplateChange(priceId: string | string[]) {
        this.priceDimensionTemplateChange.emit(priceId as string);
    }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationIndexComponent } from './navigation-index.component';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';

@NgModule({
    declarations: [
        NavigationIndexComponent
    ],
    exports: [
        NavigationIndexComponent
    ],
    imports: [
        CommonModule,
        DxPopoverModule,
        DxTemplateModule,        
        NgbPaginationModule
    ],
    providers: []
})
export class NavigationIndexModule { }
import { Injectable } from '@angular/core';

import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Injectable()
export class ServiceCategorySalesBucketMapperService {

    public getServiceCategoryAttributes(domainAttributeModels: DomainAttributeModel[], serviceCateCode: string): DomainAttributeModel[] {
        if (!domainAttributeModels) {
            return null;
        }
        return domainAttributeModels.filter(x => x.attributeTypeCode == serviceCateCode);
    }

    public getSalesBucketAttributes(domainAttributeModels: DomainAttributeModel[], salesBucketCode: string): DomainAttributeModel[] {
        if (!domainAttributeModels) {
            return null;
        }
        return domainAttributeModels.filter(x => x.attributeTypeCode == salesBucketCode);
    }

    public getServiceCategoryChoices(domainAttributeModels: DomainAttributeModel[], serviceCateCode: string): Select2Data[] {
        if (!domainAttributeModels) {
            return null;
        }
        let serviceCatAttrs = domainAttributeModels.filter(x => x.attributeTypeCode == serviceCateCode);
        let views: Select2Data[] = new Array();
        if (serviceCatAttrs?.length) {
            let serviceCatChoices = serviceCatAttrs[0].attributeChoices.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
            for (let serviceCatChoice of serviceCatChoices) {
                let view = new Select2Data();
                view.id = serviceCatChoice.attributeChoiceId;
                view.text = serviceCatChoice.serviceCategoryName;
                views.push(view);
            }
        }
        return views;
    }

    public getSalesBucketChoices(domainAttributeModels: DomainAttributeModel[], serviceCateCode: string, salesBucketCode: string): Select2Data[] {
        if (!domainAttributeModels) {
            return null;
        }
        let views: Select2Data[] = new Array();
        let salesBucketAttrs = domainAttributeModels?.filter(x => x.attributeTypeCode == salesBucketCode);
        views = this.assigntoSelect2Data(salesBucketAttrs.map(s => s.attributeChoices).reduce((a, b) => a.concat(b)), views);
        return views;
    }

    private assigntoSelect2Data(salesBucketAttrsFilters: AttributeChoiceModel[], views: Select2Data[]): Select2Data[] {
        salesBucketAttrsFilters.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        for (let serviceCatChoice of salesBucketAttrsFilters) {
            var view = new Select2Data();
            view.id = serviceCatChoice.attributeChoiceId;
            view.text = serviceCatChoice.salesBucketCode;
            views.push(view);
        }
        return views
    }

    public getSalesBucketDisplay(domainAttributeModels: DomainAttributeModel[], serviceCateCode:
        string, salesBucketCode: string, serviceCatValue: string[], salesBucketValue: string[]): string {
        if (!domainAttributeModels || !salesBucketValue?.length) {
            return "none";
        }
        let returnValue = "selective";
        let serviceCatAttrs = domainAttributeModels.filter(x => x.attributeTypeCode == serviceCateCode);
        let salesBucketAttrs = domainAttributeModels.filter(x => x.attributeTypeCode == salesBucketCode);

        let salesBucketSrvCateValueChoices = this.createSalesBucketSrvCateValueChoices(serviceCatValue,
            serviceCatAttrs[0].attributeChoices, salesBucketAttrs[0].attributeChoices);
        let salesBucketValueChoices = this.createSalesBucketValueChoices(salesBucketValue, 
            salesBucketAttrs[0].attributeChoices);
        if (!this.matchSrvCateSalesBucket(serviceCatAttrs[0].attributeChoices, 
            salesBucketSrvCateValueChoices, salesBucketValueChoices)) {
                returnValue = "all" 
        }
        return returnValue;
    }

    private createSalesBucketSrvCateValueChoices(serviceCatValue: string[], srvCateChoices: AttributeChoiceModel[], salesBucketChoices: AttributeChoiceModel[]): AttributeChoiceModel[] {
        var salesBucketSrvCateValueChoices: AttributeChoiceModel[] = new Array();
        for (let serviceCat of serviceCatValue) {
            let salesBucketChoiceAll = this.getSalesBucketSrvCateChoices(srvCateChoices, salesBucketChoices, serviceCat);
            for (let salesBucket of salesBucketChoiceAll) {
                salesBucketSrvCateValueChoices.push(salesBucket);
            }
        }
        return salesBucketSrvCateValueChoices;
    }

    private createSalesBucketValueChoices(salesBucketValue: string[], salesBucketChoices: AttributeChoiceModel[]): AttributeChoiceModel[] {
        var salesBucketValueChoices: AttributeChoiceModel[] = new Array();
        for (let saleBucket of salesBucketValue) {
            let saleBucketChoices = salesBucketChoices.filter(x => x.attributeChoiceId == saleBucket);
            salesBucketValueChoices.push(saleBucketChoices[0]);
        }
        return salesBucketValueChoices;
    }

    private getSalesBucketSrvCateChoices(srvCateChoices: AttributeChoiceModel[], salesBucketChoices: AttributeChoiceModel[], attributeChoiceId: string): AttributeChoiceModel[] {
        let filterServiceCatChoice = srvCateChoices.filter(x => x.attributeChoiceId == attributeChoiceId);
        return salesBucketChoices.filter(x => x.serviceCategoryCode == filterServiceCatChoice[0].serviceCategoryCode);
    }

    private matchSrvCateSalesBucket(srvCateChoices: AttributeChoiceModel[], salesBucketSrvCateValueChoices: AttributeChoiceModel[],
        salesBucketValueChoices: AttributeChoiceModel[]): boolean {
        for (let srvCate of srvCateChoices) {
            let filterSrvCateSalesBuckets = salesBucketSrvCateValueChoices.filter(x => x.serviceCategoryCode == srvCate.serviceCategoryCode);
            let filterSalesBuckets = salesBucketValueChoices.filter(x => x.serviceCategoryCode == srvCate.serviceCategoryCode);
            if (!this.matchAllSalesBucket(filterSrvCateSalesBuckets, filterSalesBuckets)) {
                return false;
            }
        }
        return true;
    }

    private matchAllSalesBucket(srvCateSalesBuckets: AttributeChoiceModel[], salesBuckets: AttributeChoiceModel[]) {
        for (let salesBucket of salesBuckets) {
            let filter = srvCateSalesBuckets.filter(x => x.attributeChoiceId == salesBucket.attributeChoiceId);
            if (!filter?.length) {
                return false;
            }
        }
        return true;
    }
}
import { Injectable } from '@angular/core';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

import {
    ProductRestrictionLocationCommandModel,
    ProductRestrictionLocationViewModel,
} from 'src/app/core/models/product-model/product-base-model/product-restriction';
import { RestrictionView } from 'src/app/core/components/rules-config/shared/views/restriction.view';
import { PointOfSalesCommandView } from '../views/point-of-sales-command.view';
import {
    ProductRestrictionOrganisationCommandModel,
    ProductRestrictionOrganisationViewModel
} from 'src/app/core/models/product-model/product-base-model/product-restriction';

@Injectable()
export class PointOfSalesMapperService {

    public productPointOfSalesFormToCommandViews(forms): PointOfSalesCommandView[] {
        let commandViews: PointOfSalesCommandView[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == "VALID") {
                    ++i;
                    switch (ctl.value.type) {
                        case 'location': {
                            this.location(ctl, commandViews, i);
                            break;
                        }
                        case 'organisation': {
                            this.organisation(ctl, commandViews, i);
                            break;
                        }
                    }
                }
            }
        }
        return commandViews;
    }

    private productRestrictionLocationAreasRegion(index: number, value, regionCode): PointOfSalesCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, regionCode);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.regionCode = regionCode;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationAreasCountry(index: number, value, countryCode): PointOfSalesCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, countryCode);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.countryCode = countryCode;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationAreasAirport(index: number, value, locationId): PointOfSalesCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationId);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.locationId = locationId;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionLocationAreasGroup(index: number, value, locationGroupId): PointOfSalesCommandView {
        let model = new ProductRestrictionLocationCommandModel();
        if (value.productRestrictionLocationId) {
            model.productRestrictionLocationId = this.getExistProductRestrictionLocationId(value.productRestrictionLocationId, locationGroupId);
        }
        model.productLocationTypeCode = "SALES";
        model.excludeFlag = (value.locationAreasOperators != "=") ? true : false;
        model.displaySequence = index;
        model.locationGroupId = locationGroupId;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionLocationCommand = model;
        return commandView;
    }

    private productRestrictionOrganisationType(index: number, value, organisationTypeCode): PointOfSalesCommandView {
        let model = new ProductRestrictionOrganisationCommandModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationTypeCode);
        }
        model.organisationTypeCode = organisationTypeCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationCommand = model;
        return commandView;
    }

    private productRestrictionOrganisationGroup(index: number, value, organisationGroupCode): PointOfSalesCommandView {
        let model = new ProductRestrictionOrganisationCommandModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationGroupCode);
        }
        model.organisationGroupCode = organisationGroupCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationCommand = model;
        return commandView;
    }

    private productRestrictionOrganisationRole(index: number, value, organisationRoleCode: string): PointOfSalesCommandView {
        let model = new ProductRestrictionOrganisationCommandModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationRoleCode);
        }
        model.organisationRoleCode = organisationRoleCode;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationCommand = model;
        return commandView;
    }

    private productRestrictionOrganisationSpecific(index: number, value, organisationId): PointOfSalesCommandView {
        let model = new ProductRestrictionOrganisationCommandModel();
        if (value.productRestrictionOrganisationId) {
            model.productRestrictionOrganisationId = this.getExistProductRestrictionLocationId(value.productRestrictionOrganisationId, organisationId);
        }
        model.organisationId = organisationId;
        model.excludeFlag = (value.orgTypeOperators != "=") ? true : false;
        model.displaySequence = index;

        let commandView = new PointOfSalesCommandView();
        commandView.no = index;
        commandView.type = value.type;
        commandView.productRestrictionOrganisationCommand = model;
        return commandView;
    }

    public productRestrictionLocationPointOfSalesCommandModels(views: PointOfSalesCommandView[]): ProductRestrictionLocationCommandModel[] {
        let models: ProductRestrictionLocationCommandModel[] = new Array();
        if (views) {
            var viewFilters = views.filter(x => x.type == "location");
            for (let view of viewFilters) {
                if (view.productRestrictionLocationCommand) {
                    models.push(view.productRestrictionLocationCommand);
                }
            }
        }
        return models;
    }

    public productRestrictionOrganisationCommandModels(views: PointOfSalesCommandView[]): ProductRestrictionOrganisationCommandModel[] {
        let models: ProductRestrictionOrganisationCommandModel[] = new Array();
        const  productRestrictionOrganisationTypeCodeSALES =  "SALES";
        if (views) {
            var viewFilters = views.filter(x => x.type == "organisation");
            for (let view of viewFilters) {
                if (view.productRestrictionOrganisationCommand) {
                    view.productRestrictionOrganisationCommand.productRestrictionOrganisationTypeCode = productRestrictionOrganisationTypeCodeSALES;
                    models.push(view.productRestrictionOrganisationCommand);
                }
            }
        }
        return models;
    }

    public MergePointOfSalesToProductLocationRestriction(restrictions: ProductRestrictionLocationCommandModel[], pointOfSales: ProductRestrictionLocationCommandModel[]): ProductRestrictionLocationCommandModel[] {
        for (let command of pointOfSales) {
            restrictions.push(command);
        }
        return restrictions;
    }

    public pointOfSalesModelsToViews(productRestrictionLocations: ProductRestrictionLocationViewModel[],
        productRetrictionOrganisations: ProductRestrictionOrganisationViewModel[], inherit: boolean
    ): RestrictionView[] {

        let restrictionViews: RestrictionView[] = new Array();

        var productRetrictionOrganisationFilter = productRetrictionOrganisations.filter(x => x.inherit == inherit)
        for (let productRetrictionOrganisation of productRetrictionOrganisationFilter) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRetrictionOrganisation.displaySequence;
            restrictionView.type = "organisation";
            restrictionView.hierarchyKey = productRetrictionOrganisation.hierarchyKey;
            restrictionView.productRestrictionOrganisationView = productRetrictionOrganisation;
            restrictionViews.push(restrictionView);
        }
        var filterTypeServices = productRestrictionLocations.filter(x => x.productLocationTypeCode == "SALES" && x.inherit == inherit)
        for (let productRestrictionLocation of filterTypeServices) {
            let restrictionView = new RestrictionView();
            restrictionView.no = productRestrictionLocation.displaySequence;
            restrictionView.type = "location";
            restrictionView.hierarchyKey = productRestrictionLocation.hierarchyKey;
            restrictionView.productRestrictionLocationView = productRestrictionLocation;
            restrictionViews.push(restrictionView);
        }
        if (!inherit) {
            restrictionViews.sort((a, b) => (a.no < b.no ? -1 : 1));
        }
        return restrictionViews;
    }

    private getExistProductRestrictionLocationId(locationArrayDBId: Select2Data[], value) {
        if (locationArrayDBId) {
            var filter = locationArrayDBId.filter(x => x.text == value);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
    }

    private location(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        switch (ctl.value.locationAreas) {
            case 'region': {
                this.region(ctl, commandViews, i);
                break;
            }
            case 'country': {
                this.country(ctl, commandViews, i);
                break;
            }
            case 'airport': {
                this.airport(ctl, commandViews, i);
                break;
            }
            case 'locationgroup': {
                this.locationgroup(ctl, commandViews, i);
                break;
            }
        }
    }

    private region(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let regionCode of ctl.value.locationAreasValueRegion) {
            commandViews.push(this.productRestrictionLocationAreasRegion(i, ctl.value, regionCode));
        }
    }

    private country(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let countryCode of ctl.value.locationAreasValueCountry) {
            commandViews.push(this.productRestrictionLocationAreasCountry(i, ctl.value, countryCode));
        }
    }

    private airport(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let locationId of ctl.value.locationAreasValueAirport) {
            commandViews.push(this.productRestrictionLocationAreasAirport(i, ctl.value, locationId));
        }
    }

    private locationgroup(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let locationGroupId of ctl.value.locationAreasValueGroup) {
            commandViews.push(this.productRestrictionLocationAreasGroup(i, ctl.value, locationGroupId));
        }
    }

    private organisation(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        switch (ctl.value.orgType) {
            case 'type':
                this.type(ctl, commandViews, i);
                break;
            case 'group':
                this.group(ctl, commandViews, i);
                break;
            case 'role': 
                this.role(ctl, commandViews, i);
                break;
            case 'specific':
                this.specific(ctl, commandViews, i);
                break;
        }
    }

    private type(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let organisationTypeCode of ctl.value.orgTypeValueType) {
            commandViews.push(this.productRestrictionOrganisationType(i, ctl.value, organisationTypeCode));
        }
    }

    private group(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let organisationGroupCode of ctl.value.orgTypeValueGroup) {
            commandViews.push(this.productRestrictionOrganisationGroup(i, ctl.value, organisationGroupCode));
        }
    }

    private role(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let organisationRoleCode of ctl.value.orgTypeValueRole) {
            commandViews.push(this.productRestrictionOrganisationRole(i, ctl.value, organisationRoleCode));
        }
    }

    private specific(ctl: any, commandViews: PointOfSalesCommandView[], i: number) {
        for (let organisationId of ctl.value.orgTypeValueSpecific) {
            commandViews.push(this.productRestrictionOrganisationSpecific(i, ctl.value, organisationId));
        }
    }
}
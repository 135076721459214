import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SecurityLevel2TableModel } from './security-level2-teble.model';

import { SecurityLevel2ContextMenuComponent } from './context-menu/security-level2-context-menu.component';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
@Component({
    selector: 'op-security-level2-table',
    templateUrl: './security-level2-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityLevel2TableComponent {
    private readonly GROUP_DETAIL_URL = 'main/settings/security-group/details';
    private readonly CODE_DETAIL_URL = 'main/settings/security-code/details';
    private readonly CODE_URL = 'main/settings/security-code';
    private readonly GROUP_URL = 'main/settings/security-group';
    private readonly EDIT_ACTION = 'EDIT';
    private readonly COPY_ACTION = 'COPY';
    private readonly CODE_ACTION = 'CODE';
    private readonly USER_ACTION = 'USER';
    private readonly ORG_ACTION = 'ORGANISATION';
    private readonly REPORT_ACTION = 'REPORT';
    private readonly FUCNTION_TABID = 'FUNCTION';
    private readonly GROUP_ACTION = 'GROUP';
    public readonly GROUP_CONTEXT_MENU = [
        {
            name: 'Edit',
            code: this.EDIT_ACTION
        },
        {
            name: 'Copy',
            code: this.COPY_ACTION
        },
        {
            name: 'Codes',
            code: this.CODE_ACTION
        },
        {
            name: 'User',
            code: this.USER_ACTION,
            disable: true
        },
        {
            name: 'Organisation',
            code: this.ORG_ACTION,
            disable: true
        },
        {
            name: 'Report',
            code: this.REPORT_ACTION,
            disable: true
        }
    ]
    public readonly CODE_CONTEXT_MENU = [
        {
            name: 'Edit',
            code: this.EDIT_ACTION
        },
        {
            name: 'Copy',
            code: this.COPY_ACTION
        },
        {
            name: 'Groups',
            code: this.GROUP_ACTION
        },
        {
            name: 'User',
            code: this.USER_ACTION,
            disable: true
        },
        {
            name: 'Report',
            code: this.REPORT_ACTION,
            disable: true
        }
    ]
    @Input() items: SecurityLevel2TableModel[];
    @Input() groupMode = false;
    @Input() codeMode = false;
    @Input() paggingView: PagingDataView[] = [];
    @Input() securities: SecurityGroupSecurityModel[];
    @Input() moduleSecurity: SecurityGroupSecurityModel;
    @Output() onSelectedItemChanged = new EventEmitter<SecurityLevel2TableModel>();
    @ViewChild("contextMenu") contextMenu: SecurityLevel2ContextMenuComponent;
    selectedItem: SecurityLevel2TableModel;

    constructor(private navigationService: NavigationService) { }

    public showContextMenu(event: MouseEvent): boolean {
        return false;
    }

    public onItemClick(item: SecurityLevel2TableModel) {
        this.selectedItem = item;
        this.onSelectedItemChanged.emit(this.selectedItem);
    }

    public onItemDoubleClick(item: SecurityLevel2TableModel) {
        this.selectedItem = item;
        this.onEdit(false);
    }

    public onContextMenuSelected(actionId: string) {
        if (!this.selectedItem) {
            return;
        }
        switch (actionId) {
            case this.EDIT_ACTION:
                this.onEdit();
                break;
            case this.COPY_ACTION:
                this.onCopy();
                break;
            case this.CODE_ACTION:
                this.gotoCodeSearch();
                break;
            case this.GROUP_ACTION:
                this.gotoGroupSearch();
                break;
        }
    }

    private onEdit(fromEdit: boolean = true) {
        if (this.groupMode) {
            this.editGroup();
        } else if (this.codeMode) {
            this.editCode(fromEdit);
        }
    }

    private onCopy() {
        if (this.groupMode) {
            this.copyGroup();
        } else if (this.codeMode) {
            this.copyCode();
        }
    }

    private editGroup() {
        let param = {
            id: this.selectedItem.id,
            newFlag: false,
            copy: false,
            selectedTab: this.FUCNTION_TABID,
            paggingView: this.paggingView,
            currentIndex: this.getCurrentIndex(this.selectedItem.id),
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.gotoGroup(param);
    }

    private editCode(fromEdit: boolean = true) {
        let param = {
            id: this.selectedItem.id,
            newFlag: false,
            copy: false,
            selectedTab: this.FUCNTION_TABID,
            paggingView: this.paggingView,
            currentIndex: this.getCurrentIndex(this.selectedItem.id),
            fromEdit: fromEdit,
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.gotoCode(param);
    }

    private copyGroup() {
        let param = {
            id: this.selectedItem.id,
            newFlag: true,
            copy: true,
            selectedTab: this.FUCNTION_TABID,
            paggingView: this.paggingView,
            currentIndex: this.getCurrentIndex(this.selectedItem.id),
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.gotoGroup(param);
    }

    private copyCode() {
        let param = {
            id: this.selectedItem.id,
            newFlag: true,
            copy: true,
            selectedTab: this.FUCNTION_TABID,
            paggingView: this.paggingView,
            currentIndex: this.getCurrentIndex(this.selectedItem.id),
            securities: this.securities,
            moduleSecurity: this.moduleSecurity
        }
        this.gotoCode(param);
    }

    gotoGroup(param: any = null) {
        this.navigationService.navigate(this.GROUP_DETAIL_URL, null, false, param);
    }

    gotoCode(param: any = null) {
        this.navigationService.navigate(this.CODE_DETAIL_URL, null, false, param);
    }

    gotoGroupSearch() {
        let param = {
            securities: this.securities,
            moduleSecurity: this.moduleSecurity,
            selectedTab: 'SEARCH'
        }
        this.navigationService.navigate(this.GROUP_URL, null, false, param);
    }

    gotoCodeSearch() {
        let param = {
            securities: this.securities,
            moduleSecurity: this.moduleSecurity,
            selectedTab: 'SEARCH'
        }
        this.navigationService.navigate(this.CODE_URL, null, false, param);
    }

    get contextMenuByMode() {
        if (this.groupMode == true) {
            return this.GROUP_CONTEXT_MENU;
        } else if (this.codeMode == true) {
            return this.CODE_CONTEXT_MENU;
        }
    }

    getCurrentIndex(id: string) {
        return this.paggingView?.find(item => item.id == id)?.no;
    }

}
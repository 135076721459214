import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChange } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { Helper } from '../../../../../../shared/helper/app.helper';

declare var $: any;

@Component({
    selector: 'op-tree',
    templateUrl: './tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent implements OnInit, OnChanges {

    files: TreeNode[];
    selectedFile: TreeNode;

    @Input() id: string;
    @Input() heightPanelGeneral: Number = 451;
    @Input() focusing: boolean = false;
    @Input() treeNodes: TreeNode[];
    @Input() productName: string;

    @Input() nodeSelected: boolean = false;

    @Output() parentIdChanged = new EventEmitter<string>();
    @Output() onHide = new EventEmitter();

    private firstLoad: boolean = true;

    constructor(public helper: Helper) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (this.firstLoad) {
            this.refreshTree();
        }
        else {
            if (!this.nodeSelected) {
                this.refreshTree();
            } else if (changes['treeNodes']) {
                this.refreshTree();
            }
        }
        if (changes['productName'] && this.selectedFile) {
            this.selectedFile.label = this.productName;
        }
    }

    private refreshTree() {
        if (this.id) {
            this.files = this.treeNodes;
            if (this.files) {
                if (this.firstLoad) {
                    this.firstLoad = false;
                }
                this.files.forEach(node => {
                    this.expandRecursive(node, true);
                });
            }
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
        if (node.data == this.id) {
            this.selectedFile = node;
        }
    }

    public nodeSelect(value) {
        this.nodeSelected = true;
        this.parentIdChanged.emit(value.node.data);
    }

    public hideTree() {
        this.onHide.emit();
    }
}
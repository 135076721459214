import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsightDetailComponent } from './insight-management-content/insight-detail/insight-detail.component';
import { InsightManagementContentComponent } from './insight-management-content/insight-management-content.component';
import { InsightManagementComponent } from './insight-management.component';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
    },
    {
        path: '',
        component: InsightManagementComponent,
        children: [
            {
                path: 'details',
                component: InsightDetailComponent
            }
        ]
    },
    {
        path: 'home',
        component: InsightManagementContentComponent,
    },
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class InsightManagementRoutingModule { }
export const select2CommentType = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

export const select2CommentClassification = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}
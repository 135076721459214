import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { VoucherUsageView } from '../../../shared/voucher-use.view';
import { VoucherService } from 'src/app/core/services/product-services/voucher.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { VoucherStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/voucher-status-reference.model';

@Component({
    selector: 'op-vouchers-tab-table',
    templateUrl: './vouchers-tab-table.component.html'
})
export class VouchersTabTableComponent {
    public readonly EXPORT_FILE_NAME = 'VoucherSearchResult';
    public focusing: boolean = false;

    public rows: number;
    public exportData: any;
    public selectedItem: any;
    public header: string[];

    get deleteFlag(): boolean {
        return this.userSecurity?.deleteFlag ?? false;
    }

    public voucherSearchResults: VoucherUsageView[];

    @Input() voucherStatusReferences: VoucherStatusReferenceModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() productId: string;
    @Input() organisationShortDateFormat: string;
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    constructor(private voucherService: VoucherService,
        private stringUtils: StringHelperService,
        private changeDetector: ChangeDetectorRef) {}

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = filteredData;
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public onRowSelected(e) {
        this.selectedItem = e.data;
    }

    public searchVoucher(orgId: string, voucherTypeCode: string, issueDateFrom: string, issueDateTo: string) {
        if (!this.productId) {
            return;
        }
        this.voucherService.searchVoucherUse(this.productId, orgId, voucherTypeCode, issueDateFrom, issueDateTo)
            .subscribe(
                (response: VoucherUsageView[]) => {
                    let index = 1;
                    for (let view of response) {
                        view.no = index;
                        index++;
                    }
                    this.voucherSearchResults = response;
                    this.changeDetector.detectChanges();
                }
            )
    }

    public getAmountValue(amount: number) {
        if (!amount || amount === 0) {
            return '';
        }
        return this.stringUtils.validateNullAmountDecimal(amount);
    }

    public findHighlightColorForStatus(dataSet: VoucherStatusReferenceModel[], statusName: string): string {
        const colorCode = dataSet.find(item => item.voucherStatusName == statusName)?.highlightColor;
        return colorCode;
    }

}

import { Injectable } from "@angular/core";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { 
    OrganisationTypeReferenceModel 
} from "src/app/core/models/organisation-model";
import { SecurityGroupOrganisationModel } from "src/app/core/models/security-model/security-group-organisation.model";

@Injectable()
export class SecurityGroupMapperService {

    public organisationTypeReferenceSelect2Data(organisationTypeRefs: OrganisationTypeReferenceModel[]): Select2Data[] {
        let select2Data: Select2Data[] = new Array();
        for (let organisationTypeRef of organisationTypeRefs) {
    
            let data = new Select2Data();
            data.id = organisationTypeRef.organisationTypeCode
            data.text = organisationTypeRef.organisationTypeName;
            select2Data.push(data);
        }
        return select2Data;
    }
    public mapSecurityGroupOrganisationCodes(models: SecurityGroupOrganisationModel[]): string[] {
        
        if (!models) {
            return null;
        }
        let organisationTypeCodes = new Array<string>();

        for (let securityGroupOrganisation of models) {
            organisationTypeCodes.push(securityGroupOrganisation.organisationTypeCode);
        }
        return organisationTypeCodes
    }
}

import { createAction } from '@ngrx/store';
import { TabSessionModel } from '../../core/models/tab/tab-session.model';

export const loadTabSesssion = createAction(
    '[TabSessionLoad] Load Tab Session'
)
export const addTabSession = createAction(
    '[TabSession] Add Tab Session',
    (tabSession: TabSessionModel) => ({ tabSession })
);
export const deleteAllTabSession = createAction(
    '[DeleteAllTabSession] Delete All Tab Session'
);
export const deleteTabSession = createAction(
    '[TabSession] Delete Tab Session',
    (tabSession: TabSessionModel) => ({ tabSession })
);
export const changeNavigateUrl = createAction(
    '[ChangeNavigateUrl] Change Navigate URL',
    (tabSession: TabSessionModel) => ({ tabSession })
);

import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, ViewChild } from "@angular/core";
import { CommunicationOtherTypeReferenceModel, LanguageReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { InsightCommunicationOtherDetailComponent } from "./detail/communication-other-detail.component";
import { CommunicationOtherDetailDirective } from "./detail/communication-other.directive";
import { CommunicationOtherView } from "./shared/communication-other-view";
import { InsightCommunicationOtherTableComponent } from "./table/communication-other-table.component";
import { cloneDeep } from "lodash";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { UserAccountOrganisationService } from "src/app/core/services/user-account-services/user-account-organisation.service";

@Component({
    selector: 'op-insight-detail-communicaiton-other',
    templateUrl: './insight-detail-communication-other.component.html'
})

export class InsightDetailCommunicationOtherComponent {
    private readonly COMMUNICATION_TYPE_EMAIL = 'EMAIL';
    @Input() languageReferences: LanguageReferenceModel[];
    @Input() communicationOtherTypeReferences: CommunicationOtherTypeReferenceModel[];

    public isCollapsedDetail: boolean = false;
    public isCommunicationOthersEmpty: boolean = true;
    public isMoreThanOneCommunicationOthers: boolean = true;
    public singleRecord: boolean = true;
    public showAdd: boolean = false;

    public focusing: boolean = false;
    public focused: boolean = false;

    public communicationOther = new CommunicationOtherView();
    public communicationOthers: CommunicationOtherView[] = new Array();
    public previousCommunicationOthers: CommunicationOtherView[] = new Array();

    public disabledDelBtn: boolean = false;

    public formValid: boolean = true;
    public isApplyBtnClicked: boolean = false;

    @Input() actionSecurity: SecurityGroupSecurityModel; 
    @Input() newInsightPassenger: boolean = false;
    @Input() copyMode: boolean = false;

    @ViewChild(CommunicationOtherDetailDirective) communicationOtherDetailDirective: CommunicationOtherDetailDirective;
    @ViewChild(InsightCommunicationOtherDetailComponent) communicationOtherDetailComponent: InsightCommunicationOtherDetailComponent;
    @ViewChild(InsightCommunicationOtherTableComponent) communicationOtherTableComponent: InsightCommunicationOtherTableComponent;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    
    public newCommunicationOtherDetail: InsightCommunicationOtherDetailComponent;

    get isReadonly(): boolean {
        if (!this.actionSecurity) {
            return true;
        }

        if (this.copyMode) {
            return !this.actionSecurity.copyFlag;
        }

        if (this.newInsightPassenger) {
            return !this.actionSecurity.newFlag;
        }

        return !this.actionSecurity.editFlag;
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef,
        private userAccountOrganisationService: UserAccountOrganisationService) {

    }

    public fillModelToForm(passengerCommunicationOthers: CommunicationOtherView[]) {
        this.clearData();
        if (passengerCommunicationOthers && passengerCommunicationOthers.length > 0) {
            this.fillModelInCase(passengerCommunicationOthers);
        }

        this.communicationOthers = passengerCommunicationOthers;
        this.previousCommunicationOthers = passengerCommunicationOthers;

        if (this.communicationOthers.length > 0) {
            this.isCommunicationOthersEmpty = false;
        }

        if (this.communicationOthers.length == 1) {
            this.isMoreThanOneCommunicationOthers = false;
        }
        
        this.changeDetectionRef.detectChanges();
    }

    private clearData() {
        this.singleRecord = true;
        this.communicationOther = new CommunicationOtherView();
    }

    private fillModelInCase(communicationOthers: CommunicationOtherView[]) {
        if (communicationOthers.length == 1) {
            this.fillModelSingleRecord(communicationOthers[0]);
        }
        else {
            this.singleRecord = false;
        }
    }

    private fillModelSingleRecord(view: CommunicationOtherView) {
        this.singleRecord = true;
        this.showAdd = true;
        this.communicationOther = view;
        this.changeDetectionRef.detectChanges();
    }

    async onSave() {
        let insightCommunicationEmail = this.communicationOtherDetailComponent.getInsightCommunicationEmail();
        if (insightCommunicationEmail) {
          let duplicateEmail = await this.duplicateEmail(insightCommunicationEmail);
          if (!duplicateEmail) {
            this.communicationOtherDetailComponent.displayDuplicationEmailError(false);
            this.onSaveInsightCommunication();
          } else {
            this.communicationOtherDetailComponent.displayDuplicationEmailError(true);
          }
        } else {
          this.onSaveInsightCommunication();
        }
    }

    async onSaveNewCommunicationOther() {
        let insightCommunicationEmail  = this.newCommunicationOtherDetail.getInsightCommunicationEmail();
        if (insightCommunicationEmail) {
            let duplicateEmail = await this.duplicateEmail(insightCommunicationEmail);
            if (!duplicateEmail) {
                this.newCommunicationOtherDetail.displayDuplicationEmailError(false);
                this.onSaveNewInsightCommunication();
            } else {
                this.newCommunicationOtherDetail.displayDuplicationEmailError(true);
            }
        } else {
            this.onSaveNewInsightCommunication();
        }
    }

    private onSaveInsightCommunication() {
        this.isApplyBtnClicked = true;
        let insightCommunicationOther = this.communicationOtherDetailComponent.getInsightCommunicationOther();
        if (insightCommunicationOther) {
            this.savedata(insightCommunicationOther);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private onSaveNewInsightCommunication() {
        this.isApplyBtnClicked = true;
        let insightCommunicationOther = this.newCommunicationOtherDetail.getInsightCommunicationOther();
        if (insightCommunicationOther) {
            this.savedata(insightCommunicationOther);
            this.showAdd = true;
            this.displayAfterSave();
        }
    }

    private savedata(data: CommunicationOtherView) {
        if (data.organisationId) {
            data.communicationLocked = true;
        }
        else {
            data.communicationLocked = false;
        }

        if (this.communicationOthers?.length) {
            var filterIndex = this.communicationOthers.findIndex(x => x.no == data.no);
            if (data.primaryCommunication === undefined) {
                data.primaryCommunication = false;
            }

            this.updateCommunicationOtherViews(filterIndex, data);
        }
        else {
            data.no = 1;
            data.primaryCommunication = true;
            this.communicationOther = data;
            this.communicationOthers.push(data);
        }

        this.isCommunicationOthersEmpty = false;
        this.communicationOtherTableComponent.dataGrid.instance.refresh();
    }

    private updateCommunicationOtherViews(filterIndex: number, data: CommunicationOtherView) {
        if (this.communicationOthers?.length > 0 && data.primaryCommunication) {
            this.setOtherCommunicationOtherPrimaryFlag(filterIndex);
        }

        if (filterIndex == -1) {
            data.no = this.communicationOthers.length + 1;
            this.communicationOthers.push(data);
        }
        else {
            this.communicationOthers[filterIndex] = data;
        }
    }

    private setOtherCommunicationOtherPrimaryFlag(filterIndex: number) {
        let otherCommunicationOthers = this.communicationOthers;
        for (let otherCommunicationOther of otherCommunicationOthers) {
            if (otherCommunicationOther.no == filterIndex + 1) {
                continue;
            }

            otherCommunicationOther.primaryCommunication = false;;
        }

        this.communicationOthers = otherCommunicationOthers;
    }

    private displayAfterSave() {
        if (this.communicationOthers?.length > 1) {
            this.singleRecord = false;
            this.formDetailClose();
            this.toggleDetail();
        }
        else {
            this.singleRecord = true;
        }
    }

    private formDetailClose() {
        let viewContainerRef = this.communicationOtherDetailDirective.viewContainerRef;
        viewContainerRef.clear();
    }

    public toggleDetail() {
        if (this.communicationOthers.length > 0) {
            this.isCollapsedDetail = !this.isCollapsedDetail
        }
        
        this.changeDetectionRef.markForCheck();
        if (!this.isCollapsedDetail) {
            this.formDetailClose();
        }
    }

    onDelete() {
        this.deleteData();
        this.displayAfterDelete();
    }

    private deleteData() {
        let data = this.getDeleteData();

        if (!data) {
            return;
        }

        this.setMoreThanOneCommunicationOthers();
        this.deleteDataFromCommunicationOtherViews(data);
    }

    getDeleteData(): CommunicationOtherView {
        if (this.communicationOthers.length > 1) {
            return this.communicationOtherTableComponent.selectedItem;
        }

        return this.communicationOther;
    }

    setMoreThanOneCommunicationOthers() {
        if (this.communicationOthers.length > 1) {
            this.isMoreThanOneCommunicationOthers = true;
        } 
        else {
            this.isMoreThanOneCommunicationOthers = false;
        }
    }

    deleteDataFromCommunicationOtherViews(data: CommunicationOtherView) {
        let views = new Array<CommunicationOtherView>();
        if (this.communicationOthers != null) {
            views = this.communicationOthers;
            views.splice(views.indexOf(data), 1);
            let no = 1;
            views.forEach(function (value) {
                value.no = no;
                no++;
            });
            this.communicationOthers = views;
            this.setNewPrimaryFlag();
        }
    }

    private setNewPrimaryFlag() {
        if (this.communicationOthers.length == 1) {
            this.communicationOthers[0].primaryCommunication = true;
        }
    }

    private displayAfterDelete() {
        if (this.isSingleRecord()) {
            this.setPropertyForCommunicationOtherDetailForm();
            this.singleRecord = true;
        }

        if (this.isCollapsedDetail) {
            this.toggleDetail();
        }
    }

    private isSingleRecord(): boolean {
        if (this.communicationOthers.length == 0 || this.communicationOthers.length == 1) {
            return true;
        }
        return false;
    }

    private setPropertyForCommunicationOtherDetailForm() {
        if (this.communicationOthers.length == 1) {
            this.communicationOther = this.communicationOthers[0];
            this.showAdd = true;
        }
        else {
            this.communicationOther = new CommunicationOtherView();
            this.showAdd = false;
        }
    }

    onEdit() {
        if (this.isSingleRecord()) {
            this.singleRecord = true;
            this.formDetailClose();
        }
        this.openDetail();
        this.loadDetailForm(this.communicationOtherTableComponent.selectedItem);
    }

    public openDetail(){
        if (!this.isCollapsedDetail){
            this.isCollapsedDetail = !this.isCollapsedDetail
            this.changeDetectionRef.markForCheck();
        }       
    }

    private loadDetailForm(dataEdit: CommunicationOtherView = null) {
        let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(InsightCommunicationOtherDetailComponent);

        let viewContainerRef = this.communicationOtherDetailDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRefs = viewContainerRef.createComponent(componentFactory);
        let component: InsightCommunicationOtherDetailComponent = (<InsightCommunicationOtherDetailComponent>componentRefs.instance);
        component.languageReferences = this.languageReferences;
        component.communicationOtherTypeReferences = this.communicationOtherTypeReferences;
        
        if (dataEdit != null) {
            component.insightCommunicationOther = cloneDeep(dataEdit);
        }

        component.onUpdateFormStatus.subscribe(
            response => {
                this.onUpdateFormStatus(response);
            }
        );

        this.newCommunicationOtherDetail = component;
        this.changeDetectionRef.detectChanges();
    }

    add() {
        this.openDetail();
        this.singleRecord = false;
        this.isMoreThanOneCommunicationOthers = true;
        this.loadDetailForm();
        this.changeDetectionRef.detectChanges();
    }

    onCancel() {
        this.resetFormValidFlag();

        if (this.communicationOthers.length == 0) {
            this.communicationOther = new CommunicationOtherView();
        }

        if (this.communicationOthers.length <= 1) {
            this.isMoreThanOneCommunicationOthers = false;
        }
        
        this.displayAfterCancel();
    }

    private resetFormValidFlag() {
        this.formValid = true;
        this.isApplyBtnClicked = false;

        if (this.communicationOtherDetailComponent) {
            return this.communicationOtherDetailComponent.setProcessing(false);
        }

        if (this.newCommunicationOtherDetail) {
            return this.newCommunicationOtherDetail.setProcessing(false);
        }
    }

    private displayAfterCancel() {
        this.toggleDetail();
        if (this.communicationOthers.length == 1) {
            this.singleRecord = true;
        }
    }

    onUpdateFormStatus(valid: boolean) {
        this.formValid = valid;
    }

    copy() {
        if (this.communicationOthers.length == 0) {
            return;
        }

        this.setDisplayForDataCopying();

        if (this.communicationOthers.length == 1) {
            let copyCommunicationOther = cloneDeep(this.communicationOthers[0]);
            this.openCopyDetailForm(copyCommunicationOther);
            return;
        }

         if (!this.communicationOtherTableComponent.selectedItem) {
            return;
        }

        let copyCommunicationOther = cloneDeep(this.communicationOtherTableComponent.selectedItem);
        this.openCopyDetailForm(copyCommunicationOther);
    }

    setDisplayForDataCopying() {
        this.singleRecord = false;
        this.isMoreThanOneCommunicationOthers = true;
    }

    setCopyCommunicationOtherProperty(copyCommunicationOther: CommunicationOtherView) {
        copyCommunicationOther.organisationId = null;
        copyCommunicationOther.no = null;
        copyCommunicationOther.communicationOtherId = null;
        copyCommunicationOther.primaryCommunication = false;
    }

    openCopyDetailForm(copyCommunicationOther: CommunicationOtherView) {
        this.setCopyCommunicationOtherProperty(copyCommunicationOther);
        this.openDetail();
        this.loadDetailForm(copyCommunicationOther);
    }

    validateForm(): boolean {
        if (!this.formValid && this.isApplyBtnClicked) {
            return false;
        }

        if (this.communicationOthers.length <= 0) {
            return true;
        }

        if (this.communicationOtherDetailComponent && this.isApplyBtnClicked) {
            return this.communicationOtherDetailComponent.isValidForm();
        }

        if (this.newCommunicationOtherDetail && this.isApplyBtnClicked) {
            return this.newCommunicationOtherDetail.isValidForm();
        }

        return true;
    }

    getErrorMessageForm() {
        if (this.communicationOtherDetailComponent) {
            return this.communicationOtherDetailComponent.getErrorMessageForm();
        }

        if (this.newCommunicationOtherDetail) {
            return this.newCommunicationOtherDetail.getErrorMessageForm();
        }
    }

    onRowSelected() {
        let selectedRow = this.communicationOtherTableComponent.selectedItem;
        
        if (selectedRow.communicationLocked) {
            this.disabledDelBtn = true;
        }
        else {
            this.disabledDelBtn = false;
        }
    }

    private async duplicateEmail(userOther: CommunicationOtherView): Promise<boolean> {
        let duplicateEmailDB = await this.duplicateEmailDB(userOther);
        if (duplicateEmailDB || this.duplicateEmailNewCommunication(userOther)) {
          return true;
        }
        return false;
      }
    
      private async duplicateEmailDB(userOther: CommunicationOtherView): Promise<boolean> {
        try {
          await this.userAccountOrganisationService.validateMailAddress(userOther.mailAddress, userOther.communicationOtherId ?? "").toPromise();
          return false;
        } catch (e) {
          return true;
        }
      }
    
      private duplicateEmailNewCommunication(userOther: CommunicationOtherView): boolean {
        let filters = this.communicationOthers.filter(x => x.communicationOtherTypeCode == this.COMMUNICATION_TYPE_EMAIL
          && x.mailAddress == userOther.mailAddress
          && x.no != userOther.no);
        if (filters?.length) {
          return true;
        }
        return false;
      }    
}
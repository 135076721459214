import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { RestrictionModel } from 'src/app/core/models/merchandizing-config';
import { OrganisationModel } from 'src/app/core/models/organisation-model';
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductTypeGroupModel, ProductNumberTypeReferenceModel, ProductLocationPointReferenceModel, ProductLocationTypeReferenceModel } from 'src/app/core/models/reference-model/reference-product-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Component({
    selector: 'op-voucher-restriction',
    templateUrl: './voucher-restriction.component.html'
})
export class VoucherRestrictionComponent {
    private readonly _jsonURL = 'assets/data/voucher/attributes&rules_config.json';
    public readonly errorDuplicatedRestrictions = 'Restrictions are duplicated.';
    public readonly errorInvalidRestriction = 'Restriction is invalid.';
    public focusing: boolean = false;
    public restrictionConfig: RestrictionModel;

    //Product restriction product
    @Input() productCategoryReferences$: BehaviorSubject<ProductCategoryReferenceModel[]>;
    @Input() productGroupReferences$: BehaviorSubject<ProductGroupReferenceModel[]>;
    @Input() productTypeGroups$: BehaviorSubject<ProductTypeGroupModel[]>;

    //Product restriction product number
    @Input() productNumberTypeReferences$ = new BehaviorSubject<ProductNumberTypeReferenceModel[]>(null);
    @Input() providers$ = new BehaviorSubject<OrganisationModel[]>(null);
    @Input() suppliers$ = new BehaviorSubject<OrganisationModel[]>(null);

    //Product restriction location
    @Input() conditionReferences$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() productLocationPointReferences$ = new BehaviorSubject<ProductLocationPointReferenceModel[]>(null);
    @Input() productLocationTypeReferences$ = new BehaviorSubject<ProductLocationTypeReferenceModel[]>(null);
    @Input() countryReferenceSelect2Data: Select2Data[];
    @Input() locationGroupSelect2Data: Select2Data[];
    @Input() regionReferenceSelect2Data: Select2Data[];

    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;

    constructor(private http: HttpClient,
        private changeDetector: ChangeDetectorRef) {
        this.getJSON().subscribe(data => {
            this.restrictionConfig = data.restrictions;
            this.changeDetector.detectChanges();
        });
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        if (this.ruleComponent) {
            this.ruleComponent.add();
        }
    }

    public getErrorMessage(): string {
        if (this.ruleComponent.findDuplicateAllRow()) {
            return this.errorDuplicatedRestrictions;
        } else if (!this.ruleComponent.validateInvalidRow()) {
            return this.errorInvalidRestriction;
        }
    }
}

export const select2ServiceCat = {
    placeholder: "<Service category>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-blue",
    width: 'auto',
    dropdownAutoWidth: true,
    templateResult: null,
    templateSelection: null
}

export const select2ServiceCatInherit = {
    placeholder: "<Service category>",
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateResult: null,
    templateSelection: null
}


export const select2ServiceCatOption = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateSelection: null
}

export const select2SaleBucketDisplayChoice = {
    placeholder: "<Dimension>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2SalesBucketOption = {
    placeholder: "<Dimension>",
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true,
    templateSelection: null
}
import { Validators } from '@angular/forms';

export const generalInfoFormOption = {
  productCategoryCode: [{ value: null, disabled: true }, Validators.required],
  productGroupCode: [null, Validators.required],
  productTypeGroupId: [null, Validators.required],
  productName: [null, Validators.required],
  productDescription: [null],
  draftFlag: [true],
  statusCode: ['A', Validators.required],
  commitDateTime: [{ value: null, disabled: true }],
  commitByName: [{ value: null, disabled: true },],
  usageTypeCode: [null , Validators.required]
};
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TotalByCurrencyItemsModel } from 'src/app/core/models/cashbook-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
  selector: 'op-cashbook-detail-tbc',
  templateUrl: './cashbook-detail-tbc.component.html',
})
export class CashbookDetailTbcComponent implements OnChanges {
  public collapsed: boolean = false;
  public collapsedItem: boolean = true;
  public dataDetailItem: TotalByCurrencyItemsModel[];
  public focusing: boolean = false;

  @Input() dataDetail: TotalByCurrencyItemsModel[];
  @Input() resultTotalByCurrncyAmount: string;

  @ViewChild("panel") panel: any;
  @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataDetail']) {
      this.dataDetailItem = this.dataDetail;
    }
  }

  public collapsedStatus(collapsed: any) {
    this.collapsed = collapsed;
  }

  public togglePanel($event: MouseEvent) {
    this.panel.toggleCollapse($event);
    this.collapsed = !this.collapsed;
  }

  public togglePanelItem($event: MouseEvent) {
    this.collapsedItem = !this.collapsedItem;
  }

  public getUnderlineClass(rowIndex: number): string {
    if (rowIndex === 0) {
      return "";
    }
    return "order-detail-start-section";
  }
}

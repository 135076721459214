import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { select2GroupOption } from '../../../organisation-detail/shared/select2-configuration';

@Component({
  selector: 'op-organisation-group',
  templateUrl: './organisation-group.component.html'
})
export class OrganisationGroupComponent {

    @Input() organisationGroupSelect2Data: Select2Data[];
    @Input() selectedOrganisationGroup: string[];
    @Input() isFilterPanel: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() newOrganisation: boolean = false;
    
    @Output() organisationGroupChange = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter(); 

    public groupOption: any;
    public model = null;
    public showGroupRow: boolean = false;
    public edited: boolean = false;
    public focusing: boolean = false;

    constructor() {
        this.groupOption = select2GroupOption;
    }

    groupChange(value) {
        this.organisationGroupChange.emit(value);
    }

    public add() {
        this.setShowGroupRow(true);
    }

    typeChange(value) {
        this.selectedOrganisationGroup = value;
        this.edited = true;
        this.organisationGroupChange.emit(value);
    }  

    public delete() {
        this.setShowGroupRow(false);
        this.selectedOrganisationGroup = [];
        this.edited = true;
        this.organisationGroupChange.emit([]);
    }
    
    public setShowGroupRow(value: boolean) {
        this.showGroupRow = value;
    }

    get editFlag() {
        return this.isFilterPanel || (this.userSecurity?.editFlag || this.newOrganisation);
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.onSearch.emit();
                break;
            default:
                break;
        }
    }

}

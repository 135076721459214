import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { ProductGroupService } from 'src/app/core/services/product-services';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ProductGroupMapperService } from '../../../shared/mapper/product-group.mapper';
import { ProductGroupView } from '../../../shared/views/product-group.view';

@Component({
    selector: 'op-special-service-group',
    templateUrl: './special-service-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ProductGroupMapperService]
})
export class SpecialServiceGroupComponent implements OnInit {
    private readonly SERVICE_PRODUCT_CATEGORY = 'SERVICE';
    private searchActionId = 'SEARCH';
    private newActionId = 'NEW';
    private dashboardActionId = 'DASHBOARD';
    private settingActionId = 'SETTING';
    private contextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'Search',
            actionId: this.searchActionId
        },
        {
            name: 'New',
            actionId: this.newActionId
        },
        {
            name: 'Dashboard',
            actionId: this.dashboardActionId
        },
        {
            name: 'Settings',
            actionId: this.settingActionId,
            disable: true,
            divider: true
        }
    ]
    public selectedGroup: OopsCategory;
    public serviceProductGroup: OopsCategory[];
    public serviceGroupReference = new Array<ProductGroupView>();

    @Output() menuSelected = new EventEmitter<string>();
    @Output() onSearch = new EventEmitter<string>();

    constructor(private productGroupService: ProductGroupService,
        private productGroupMapperService: ProductGroupMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private activityStoreService: ActivityStoreService) { }

    ngOnInit() {
        this.getServiceGroup();
    }

    private getServiceGroup() {
        this.productGroupService.getProductGroupByProductCategoryCode(this.SERVICE_PRODUCT_CATEGORY)
            .subscribe(
                (response) => {
                    Object.assign(this.serviceGroupReference, response);
                    this.serviceProductGroup = this.productGroupMapperService.productGroupToOopsCategoryViews(response);
                    this.setContextMenu(this.serviceProductGroup);
                    this.changeDetectionRef.detectChanges();
                }
            )
    }

    private setContextMenu(groups: OopsCategory[]) {
        groups.forEach(group => {
            group.contextMenus = this.contextMenu;
        })
    }

    public onRowSelected(item: OopsCategory) {
        this.selectedGroup = item;
    }

    public onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case this.searchActionId:
                this.activityStore();
                this.onSearch.emit(this.getProductGroupCode(this.selectedGroup.name));
                break;
        }
    }

    private getProductGroupCode(productGroupName: string) {
        return this.serviceGroupReference.find(item => item.productGroupName == productGroupName).productGroupCode;
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            this.searchActionId.toLowerCase(),
            this.SERVICE_PRODUCT_CATEGORY,
            this.getProductGroupCode(this.selectedGroup.name));
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiServiceBase } from 'src/app/core/base/api-service-base';
import { LocationReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { LocationReferenceService } from 'src/app/core/services/airline-services';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';

declare var $: any;

@Injectable()
export class RouteDataService extends ApiServiceBase {


    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private locationReferenceService: LocationReferenceService,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }


    private readonly PRODUCT_PATH = "/product";
    public select2AjaxOriginSearchByLocationCode() {
        return {
            data: function (params) {
                var queryParameters = {
                    term: params.term,
                    controlId: this[0].id.toString()
                }
                return queryParameters;
            },
            transport: (params, success, failure) => {

                let locationexclude = this.getExcludeNameFromRouteControl(params.data.controlId,
                    'destinationRoute');
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, locationexclude)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }

    public select2AjaxDestinationSearchByLocationCode() {

        return {
            data: function (params) {
                var queryParameters = {
                    term: params.term,
                    controlId: this[0].id.toString()
                }
                return queryParameters;
            },
            transport: (params, success, failure) => {

                let locationexclude = this.getExcludeNameFromRouteControl(params.data.controlId,
                    'originRoute');
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, locationexclude)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }

    private getExcludeNameFromRouteControl(selectingControlName: string,
        selectedControlName: string): string {

        let index = this.stringHelperService.extractNumberFromString(selectingControlName);
        let originName = '#' + selectedControlName + index;
        let originInput = $(originName);

        if (originInput.length > 0) {
            let originSelected = $(originName).find("option[value='" + $(originName).val() + "']");
            if (originSelected.length) {
                return originSelected[0].innerText;
            }
        }
        return null;
    }

    public select2AjaxSearchByLocationCode() {
        return {
            transport: (params, success, failure) => {
                this.locationReferenceService
                    .getLocationReferenceByQueryString(params.data.term, null)
                    .subscribe((locations: LocationReferenceModel[]) => {
                        success({
                            results: locations.map(d => new Select2Data(d.locationId, d.locationName))
                        })
                    });
            }
        }
    }
}

export const select2ProductGroup = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2DefaultOption = {
    placeholder: "",
    minimumResultsForSearch: -1
}

export const select2DefaultOptionWithAllowClear = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}
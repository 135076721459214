import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CounterSalesSearchModel } from 'src/app/core/models/counter-sales-model';
import { OrderPaymentStatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { OrderPaymentStatusReferenceService } from 'src/app/core/services/system-services';

@Component({
    selector: 'op-counter-sales-search-data-view',
    templateUrl: './counter-sales-search-data-view.component.html',
})
export class CounterSalesSearchDataViewComponent {
    public orderPaymentStatusReferences$ = new BehaviorSubject<OrderPaymentStatusReferenceModel[]>(null);
    
    @Input() key: number;
    @Input() rowData: CounterSalesSearchModel;
    @Input() organisationDateTimeFormat: string;

    constructor(private orderPaymentStatusReferenceService: OrderPaymentStatusReferenceService) {
        this.getOrderPaymentStatusReferences();
    }

    public getDetail(value: any) {
        if (!value) {
            return "";
        }
        return value;
    }

    private getOrderPaymentStatusReferences() {
        this.orderPaymentStatusReferenceService.getOrderPaymentStatusReference()
            .subscribe(
                (response: OrderPaymentStatusReferenceModel[]) => {
                    this.orderPaymentStatusReferences$.next(response);
                }
            )
    }

    findHighlightColorForOrderPaymentStatus(dataSet: OrderPaymentStatusReferenceModel[], value: string): string {
        const code = dataSet.find(s => s.orderPaymentStatusName == value)?.highlightColor
        return code
    }
}

import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges, ViewChild, SimpleChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { TransportSearchTableView } from '../shared/transport-search-table.view';
import { StatusConstant } from "src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant";
import { ActionBarHandlerModel, ACTION_HANDLER_STATUS } from 'src/app/shared/ui/actionbar';
import { MerchandizeModeHelperService } from '../../../../shared/merchandize-mode-helper.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { StateConstant } from '../../../../shared/constant/state.constant';

@Component({
    selector: 'op-transport-search-table',
    templateUrl: './transport-search-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportSearchTableComponent implements OnChanges, AfterViewInit {
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";
    readonly USAGETYPE_FILTER: string = "FILTER";
    public readonly PAGE_ORIENTATION = "landscape";
    @Input() actionHandlerMode: string;
    @Input() actionBarHandler = new ActionBarHandlerModel();
    @Input() productSearchResults: TransportSearchTableView[];
    @Input() statusReference: StatusReferenceModel[];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onSelected = new EventEmitter<any>();
    @Output() onNew = new EventEmitter();
    @Output() onCopy = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter();
    @Output() onUpdateStatus = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
    focusing = false;
    selectedItem: any = null;
    disableCopy: boolean = true;
    rows = 0;
    exportData: any;
    header: string[];

    constructor(private changeDetectionRef: ChangeDetectorRef,
        private merchandizeModeHelperService: MerchandizeModeHelperService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productSearchResults']) {
            this.selectedItem = null;
            this.onSelected.emit(this.selectedItem);
            this.rows = this.productSearchResults?.length ?? 0;
        }
        if (changes['actionBarHandlerState']) {
            this.setActionBarMode(this.actionHandlerMode);
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader()
    }

    private setActionBarMode(mode: string = ACTION_HANDLER_STATUS.none) {
        let model = this.merchandizeModeHelperService.getActionBarHandlerModel(mode);
        this.actionBarHandler = model;
    }

    doubleClick(item) {
        this.onEdit.emit(item.key);
    }

    onRowSelected(event) {
        this.selectedItem = event.key;
        this.onSelected.emit(this.selectedItem);
        this.setActionBarMode(this.selectedItem ? ACTION_HANDLER_STATUS.selected : ACTION_HANDLER_STATUS.none);
    }

    newItem() {
        this.onNew.emit();
    }

    editItem() {
        if (this.selectedItem) {
            this.focusing = false;
            this.onEdit.emit(this.selectedItem);
        }
    }

    deleteItem() {
        if (this.selectedItem && this.selectedItem.statusCode != StatusConstant.DELETED) {
            this.onDelete.emit(this.selectedItem);
        }
    }

    copyItem() {
        if (this.selectedItem) {
            this.onCopy.emit(this.selectedItem);
        }
    }

    refreshTable() {
        this.onRefresh.emit();
    }

    updateStatus(e) {
        this.selectedItem.statusCode = e;
        this.onUpdateStatus.emit(this.selectedItem);
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
        this.changeDetectionRef.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                No: dat.no,
                Name: dat.name,
                ProductNumber: dat.productNumber ?? '',
                From: dat.dateFrom ?? '',
                To: dat.dateTo ?? '',
                DeptTime: dat.timeFrom ?? '',
                ArrTime: dat.timeTo ?? '',
                DayOfWeek: dat.weekDay ?? '',
                DeptLocation: dat.locationFrom ?? '',
                ArrLocation: dat.locationTo ?? '',
                UsageType: dat.usageTypeName ?? '',
                State: dat.state ?? '',
                Status: this.getStatusName(dat.statusCode)
            }
        ));
    }

    private getStatusName(statusCode: string): string {
        return this.statusReference?.filter((dat) => dat.statusCode == statusCode)[0]?.displayName;
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.name);
    }

    get deleteFlag() {
        return this.userSecurity?.deleteFlag ?? false;
    }
    get editFlag() {
        return this.userSecurity?.editFlag ?? false;
    }

    public getStateClass(state): string {
        let returnValue = "";
        switch (state) {
            case StateConstant.FINAL:
                returnValue = 'operational-status-nostatus';
                break;
            case StateConstant.DRAFT:
                returnValue = 'operational-status-arrival';
                break;
            case StateConstant.NONE:
                returnValue = 'operational-status-delivered';
                break;
        }
        return returnValue;
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { FINANCE_CATEGORIES } from './finance-categories.data';
import { FinanceCategory } from './finance-categories.model';

@Component({
    selector: 'op-finance-categories',
    templateUrl: './finance-categories.component.html',
})
export class FinanceCategoriesComponent {
    public categoriesItems: FinanceCategory[];
    public selectedItem: FinanceCategory;
    private defaultActionId = 'SEARCH';

    @Input() securityCategoriesItem: Map<string, SecurityGroupSecurityModel>;
    @Output("onDoubleClick") itemDoubleClick = new EventEmitter<string>();
    @Output("onContextMenuClick") contextMenuItemClick = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<string>();

    constructor(
        private changedetection: ChangeDetectorRef,
        private spinner: LoadingSpinnerService
    ) { }

    public loadCategories(): void {
        this.spinner.show();
        this.categoriesItems = FINANCE_CATEGORIES;
        this.updateSecurityCategoryItems();
        this.changedetection.detectChanges();
        this.spinner.hide();
    }

    public onItemDoubleClick(item: FinanceCategory) {
        this.selectedItem = item;
        this.itemDoubleClick.emit(item.url);
    }

    public onContextMenuSelected(item: string) {
        this.contextMenuItemClick.emit(item)
    }

    public onRowSelected(item: FinanceCategory) {
        if (this.selectedItem != item) {
        this.selectedItem = item;
        this.rowSelected.emit(item.url);
        }
    }

    public selectMenu(actionId: string) {
        this.contextMenuItemClick.emit(actionId);
    }

    private updateSecurityCategoryItems() {
        this.categoriesItems.forEach(item => {
            const security = this.securityCategoriesItem.get(item.url) ?? null
            if (security) {
                item.security = security
            }
        })
    }
}

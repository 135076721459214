import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { OrderDetailIndividualCommunicationOtherView } from "../../../views/order-detail-individual-communication-other.view";
import { NgForm, NgModel } from "@angular/forms";
import { Subject } from "rxjs";
import { cloneDeep } from 'lodash';
import { filter, takeUntil } from "rxjs/operators";
import { InsightPassengerCommunicationOtherModel } from "src/app/core/models/individual-model";
import { CommunicationOtherTypeSelectionComponent } from "src/app/core/components/communication-other-type-selection";

@Component({
    selector: 'op-order-detail-communication-other-form',
    templateUrl: './order-detail-communication-other-form.component.html'
})
export class OrderDetailCommunicationOtherFormComponent implements AfterViewInit, OnDestroy {
    @Input() individualId: string;
    @Input()
    set data(view: OrderDetailIndividualCommunicationOtherView) {
        if (!view) return;
        this.view = cloneDeep(view);
    }

    @Output() close = new EventEmitter();
    @Output() apply = new EventEmitter<OrderDetailIndividualCommunicationOtherView>();
    @Output() undo = new EventEmitter();

    focused: boolean = false;
    view: OrderDetailIndividualCommunicationOtherView;
    processing: boolean = false;

    @ViewChild(NgForm) form: NgForm;
    @ViewChild(CommunicationOtherTypeSelectionComponent) communicationOtherTypeSelectionComponent: CommunicationOtherTypeSelectionComponent;
    @ViewChild('emailAddress') emailAddressField: NgModel;

    private destroy$ = new Subject();

    get validateEmailRequired(): boolean {
        return this.view.communicationOtherTypeCode == 'EMAIL';
    }

    get requiredBothFields(): boolean {
        return [this.view?.communicationOtherTypeCode, this.view?.mailAddress].some(field => !field);
    }

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.checkIfErrorPresist();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onUndoClick() {
        this.undo.emit();
    }

    onApplyClick() {
        this.processing = true;
        if (!this.form.valid) {
            return;
        }
        this.view.communicationOtherTypeName = this.communicationOtherTypeSelectionComponent.getSelectedText();
        this.apply.emit(this.view);
    }

    onClearClick() {
        this.resetInputFields();
    }

    onCloseClick() {
        this.close.emit();
    }

    private resetInputFields() {
        this.view = this.getEmptyView();
        this.processing = false;
    }

    private getEmptyView(): OrderDetailIndividualCommunicationOtherView {
        const { primaryFlag } = this.view;
        return {
            orderId: null,
            communicationOtherId: null,
            communicationOtherTypeCode: null,
            communicationOtherTypeName: null,
            primaryFlag: primaryFlag,
            mailAddress: null,
            orderSalesStatusCode: null
        }
    }

    private checkIfErrorPresist() {
        this.form.valueChanges
            .pipe(
                filter(_ => this.processing == true),
                takeUntil(this.destroy$)
            )
            .subscribe(_ => {
                this.changeDetector.markForCheck()
            });
    }

    onPresetSelected(preset: InsightPassengerCommunicationOtherModel) {
        this.view.communicationOtherTypeCode = preset.communicationOtherTypeCode;
        this.view.mailAddress = preset.mailAddress;
        setTimeout(() => {
            this.emailAddressField.control.updateValueAndValidity();
        })
    }

    controlError(control: NgModel): boolean {
        return control.errors && this.processing;
    }
}
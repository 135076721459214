import { Injectable } from '@angular/core';
import { CommentCommandModel, CommentViewModel, ActivityCommentCommandModel } from "src/app/core/models/activity-model/activity-comment";
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { CommentView } from './comment.view';

@Injectable()
export class CommentMapperService {

    constructor(private dateConverterService: DateConverterService) {
    }

    public toViews(models: CommentViewModel[]): CommentView[] {
        let views: CommentView[] = new Array()
        let i = 0;
        if (models) {
            for (let model of models) {
                ++i;
                views.push(this.toView(i, model));
            }
        }
        return views;
    }

    private toView(i: number, model: CommentViewModel): CommentView {
        let view = {} as CommentView;
        view.no = i;
        view.commentId = model.commentId
        view.activityCommentId = model.activityComments[0].activityCommentId;
        view.activityId = model.activityComments[0].activityId;
        view.commentTypeCode = model.commentTypeCode;
        view.commentTypeName = model.commentTypeName;
        view.classificationCode = model.commentClassificationCode;
        view.classificationName = model.commentClassificationName;
        view.commentText = model.commentText;
        view.commitByName = model.commitByName;
        view.commitDateTime = this.dateConverterService.convertDateTime(model.commitDateTime);
        return view;
    }

    public toModels(views: CommentView[]): CommentCommandModel[] {
        let commands: CommentCommandModel[] = new Array();
        if (views) {
            for (let view of views) {
                commands.push(this.toModel(view));
            }
        }
        return commands;
    }

    private toModel(view: CommentView): CommentCommandModel {
        let command = {} as CommentCommandModel;
        command.commentId = view.commentId;
        command.commentTypeCode = view.commentTypeCode;
        command.commentClassificationCode = view.classificationCode;
        command.commentText = view.commentText;
        command.activityComments = this.createActivityComments(view);
        return command;
    }

    private createActivityComments(view: CommentView): ActivityCommentCommandModel[] {
        let commands: ActivityCommentCommandModel[] = new Array();
        let command = {} as ActivityCommentCommandModel;
        command.activityCommentId = view.activityCommentId;
        command.activityId = view.activityId;
        commands.push(command);
        return commands;
    }
}
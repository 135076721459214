import { ProductDashboardSummaryModel } from "src/app/core/models/product-model/product-dashboard-model/product-dashboard-summary.model";

export const PRODUCT_DASHBOARD_TRANSPORT_SUMMARY_DEMO: ProductDashboardSummaryModel[] = [
    {
        productName: "Air",
        todayProductCount: 184,
        todaySalesAmount: 2564040.94,
        yesterdayProductCount: 213,
        yesterdaySalesAmount: 2968156.09,
        last7dProductCount: 883,
        last7dSalesAmount: 12304609.51
    },
    {
        productName: "Bus",
        todayProductCount: 67,
        todaySalesAmount: 146220.97,
        yesterdayProductCount: 98,
        yesterdaySalesAmount: 213875.45,
        last7dProductCount: 272,
        last7dSalesAmount: 593613.49
    },
    {
        productName: "Pet",
        todayProductCount: 75,
        todaySalesAmount: 221954.42,
        yesterdayProductCount: 105,
        yesterdaySalesAmount: 310736.19,
        last7dProductCount: 259,
        last7dSalesAmount: 766482.60
    },
    {
        productName: "Rail",
        todayProductCount: 56,
        todaySalesAmount: 83547.74,
        yesterdayProductCount: 76,
        yesterdaySalesAmount: 113386.22,
        last7dProductCount: 231,
        last7dSalesAmount: 344634.43
    },
    {
        productName: "Ship",
        todayProductCount: 119,
        todaySalesAmount: 123304.12,
        yesterdayProductCount: 114,
        yesterdaySalesAmount: 118123.27,
        last7dProductCount: 350,
        last7dSalesAmount: 362659.18
    }
]

export const ATTRBUTE_GROUP_CODE: string = "INVENTORY";
export const SERVCAT_CODE: string = "SERVICECATEGORY";
export const SALESBUCKET_CODE: string = "SALESBUCKET";
export const TYPE = 'type';
export const CHOICES = 'choices';
export const FOCUS_ATTRIBUTES = [
    {
        id: '',
        text: ''
    },
    {
        id: SERVCAT_CODE,
        text: 'Service Category'
    },
    {
        id: SALESBUCKET_CODE,
        text: 'Sales Bucket'
    }
]
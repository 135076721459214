import { Store } from '@ngrx/store';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from 'src/app/core/authentication/auth.service';
import { ActionBarHandlerModel, ActionbarService } from 'src/app/shared/ui/actionbar';
import { ACTION_STATUS } from 'src/app/shared/ui/actionbar/actionbar-constant';
import { OrganisationService } from './../../../core/services/organisation-services/organisation.service';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { selectAuthState } from './../../../store/auth/auth.selectors';
import { LoadingSpinnerService } from './../../../shared/layout/loading-spinner/loading-spinner.service';
import * as AuthActions from './../../../store/auth/auth.actions';
import { UserLoginModel } from 'src/app/core/models/user-model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { ORGANISATION_COUNT, USER_LOGON_DATA, USER_LOGON_NAME } from '../shared/constants/auth.constant';

interface IOrganisationElement {
  id?: string;
  name: string;
  city: string;
  location: string;
  securityGroup: string;
  lastLogon: string;
  primary: number;
}

const ORGANISATION_DATA: IOrganisationElement[] = [];
@Component({
  selector: 'smart-select-organisation',
  templateUrl: './select-organisation.component.html'
})
export class SelectOrganisationComponent implements AfterViewInit, OnInit, OnDestroy {
  private readonly mainPageLink: string = '/main';

  displayedColumns: string[] = [
    'name',
    'city',
    'location',
    'securityGroup',
    'lastLogon',
    'primaryFlag'
  ];
  dataSource = ORGANISATION_DATA;
  selectedOrg = new Set<IOrganisationElement>();

  private userAccountId: string;
  private buildVersionLabel;
  private envNamLabel;
  private afterUserSelectOrgInfo;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isActive: boolean;

  actionBarHandler = new ActionBarHandlerModel(
    new RefreshButtonModel()
  );

  constructor(
    private actionbarService: ActionbarService,
    private organisationService: OrganisationService,
    private authService: AuthService,
    private store: Store<any>,
    private loadingSpinner: LoadingSpinnerService,
    private dateConverterService: DateConverterService
  ) {
    const userInfoSession = JSON.parse(this.authService.sessionStorageGetItem(USER_LOGON_NAME))
    const userAccountIdSession = userInfoSession.userAccountId;
    this.store.select(selectAuthState).pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (state) {
          this.userAccountId = state.userAccountId || userAccountIdSession;
          this.buildVersionLabel = state.buildVersion || ''
          this.envNamLabel = state.envName || ''
        }
      })
  }

  ngOnInit(): void {
    this.dataSource = [];
    this.checkUserOrganisation();
  }

  private checkUserOrganisation() {
    this.loadingSpinner.show()
    
    this.organisationService
      .getOrganisationListByUserLogin()
      .subscribe((orgList) => {
        const orgCount = orgList.length;
        this.store.dispatch(AuthActions.orgCount({ orgCount: orgCount }))
        this.organisationService.setSessionItem(ORGANISATION_COUNT, JSON.stringify(orgCount))
        if (orgList.length > 1) {
          this.mapOrgList(orgList)
        }
      });
  }

  private mapOrgList(orgList) {
    this.dataSource = [];
    orgList.forEach((item) => {
      if (item.primaryFlag == 1) {
        this.isActive = true;
      }
      const mapDate = !item.userOrgLastLogon ? 'N/A' : this.dateConverterService.dateTime24UTCFormat(item.userOrgLastLogon);

      const row = {
        id: item.userOrgId,
        name: item.userOrgName,
        city: item.userOrgCity,
        location: item.userOrgLocation,
        securityGroup: item.userOrgSecurityGroup,
        lastLogon: mapDate,
        primary: item.primary
      };
      this.dataSource.push(row);
    });
    this.loadingSpinner.hide();
  }

  public async onSelectedOrg(row) {
    this.loadingSpinner.show();
    this.selectedOrg.add(row);
    this.selectedOrg.forEach(async (item) => {
      this.organisationService.updateSelectedOrg(item.id);
      this.updateUserData(item);
      this.organisationService.getUserLogonEmail(this.userAccountId, this.afterUserSelectOrgInfo);
    });
  }

  updateUserData(item) {
    const userData = this.authService.getCurrentUserData();
    const loadedUser = new UserLoginModel(
      userData.token,
      userData.expires_in,
      userData.username,
      item.id,
      userData.isVerifyCode
    )
    this.organisationService.setSessionItem(USER_LOGON_DATA, JSON.stringify(loadedUser))

    this.afterUserSelectOrgInfo = {
      orgCallName: item.name,
      securityGroupName: item.securityGroup,
      lastLogon: item.lastLogon || 'N/A',
      buildVersion: this.buildVersionLabel,
      envName: this.envNamLabel,
      organisationId: item.id
    }
    this.authService.updateUserData(loadedUser)
  }

  ngOnDestroy(): void {
    this.selectedOrg.clear();
    this.loadingSpinner.hide();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.actionbarService.updateState(this.actionBarHandler);
    this.actionbarService.action$
      .subscribe(actionId => {
        switch (actionId) {
          case ACTION_STATUS.refresh:
            this.checkUserOrganisation();
          default:
            return;
        }
      }
    );
  }
}

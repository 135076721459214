import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { SettingsTabsComponent } from "./settings-tabs/settings-tabs.component";
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from "src/app/shared/ui/actionbar/actionbar-handler.model";
import { RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PAGE_TITLE } from "./shared/title-constant";
import { TabService } from "src/app/core/utils/tab.service";

@Component({
    selector: 'op-settings-content',
    templateUrl: './settings-management-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class SettingsManagementContentComponent implements AfterViewInit, OnDestroy {
    actionBarHandler = new ActionBarHandlerModel(new RefreshButtonModel());

    private unsubscribe$ = new Subject();
    public title = PAGE_TITLE.settings;
    @ViewChild(SettingsTabsComponent) settingsTabComponent: SettingsTabsComponent;
    constructor(private actionbarService: ActionbarService,
                private tabService: TabService) { }


    ngAfterViewInit(): void {
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
            this.toolbarClicked(actionId);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public toolbarClicked(e: string) {
        if (e == ACTION_STATUS.refresh) {
            this.settingsTabComponent.categoriesComponent.fetchSettingsManagement();
        }
    }

    public titleChange(title: string) {
        this.title = title ?? PAGE_TITLE.settings;
        this.tabService.changeTabName(this.title);
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { OrganisationAttributePanelComponent } from './organisation-attribute-panel/organisation-attribute-panel.component';
import { OrganisationGroupComponent } from './organisation-group/organisation-group.component';
import { OrganisationRoleComponent } from './organisation-role/organisation-role.component';
import { OrganisationTypeComponent } from './organisation-type/organisation-type.component';

@Component({
  selector: 'op-organisation-attribute',
  templateUrl: './organisation-attribute.component.html'
})
export class OrganisationAttributeComponent {
    readonly ERROR_PRIMARY_COMMUNICATION_OTHER_REQUIRED = 'Organisation type is required';

    @Input() focused: boolean = false;
    @Input() newOrganisation: boolean = false;
    @Input() panelTitle = 'ORGANISATION ATTRIBUTES';
    @Input() organisatioTypeSelect2Data: Select2Data[];
    @Input() selectedOrganisationType: string[];
    @Input() organisatioGroupSelect2Data: Select2Data[];
    @Input() selectedOrganisationGroup: string[];
    @Input() organisatioRoleSelect2Data: Select2Data[];
    @Input() selectedOrganisationRole: string[];
    @Input() isFilterPanel: boolean = false;
    @Input() organisationAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() existOwnerType: boolean = false;

    @Output() organisationTypeSelected = new EventEmitter<any>();
    @Output() organisationRoleSelected = new EventEmitter<any>();
    @Output() organisationGroupSelected = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter();

    @ViewChild(OrganisationAttributePanelComponent) attributeComponent: OrganisationAttributePanelComponent;
    @ViewChild(OrganisationTypeComponent) typeComponent: OrganisationTypeComponent;
    @ViewChild(OrganisationRoleComponent) roleComponent: OrganisationRoleComponent;
    @ViewChild(OrganisationGroupComponent) groupComponent: OrganisationGroupComponent;

    public organisationTypeCodes: string[];

    constructor(private changeDetectionRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.changeDetectionRef.detectChanges();
    }

    organisationTypeChange(value) {      
        this.organisationTypeSelected.emit(value);
    }

    organisationGroupChange(value) {      
        this.organisationGroupSelected.emit(value);
    }

    organisationRoleChange(value) {      
        this.organisationRoleSelected.emit(value);
    }

    clearAdvanceCondition() {
        this.attributeComponent.organisationAttributes = null
        this.attributeComponent.clearActionForm();
        this.typeComponent.delete();
        this.roleComponent.delete();
        this.groupComponent.delete();
    }

    checkPendingData(): boolean {
        if (this.typeComponent.edited == true) {
            return true;
        } else if (this.groupComponent.edited == true) {
            return true;
        } else if (this.roleComponent.edited == true) {
            return true;
        } else if (this.attributeComponent.edited == true) {
            return true;
        }
        return false;
    }

    clearPendingData() {
        this.typeComponent.edited = false;
        this.groupComponent.edited = false;
        this.roleComponent.edited = false;
        this.attributeComponent.edited = false;
    }

    getErrorMessage() {
        if (this.typeComponent.selectedOrganisationType.length == 0) {
            return this.ERROR_PRIMARY_COMMUNICATION_OTHER_REQUIRED;
        }
        else if (!this.validateAttribute()) {
            return this.attributeComponent.getAttributeErrorMessage();
        }
    }

    search() {
        this.onSearch.emit();
    }

    validateAttribute(): boolean{
        return this.attributeComponent.validateRequired();
    }
}

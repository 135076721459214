import { OrderDetailProductChangeAvailabilityFareDescriptionView } from "./order-detail-product-change-availability-fare-description.view";
import { OrderDetailProductChangeAvailabilityFareView } from "./order-detail-product-change-availability-fare.view";

export class OrderDetailProductChangeAvailabilityFlightView {
    departureDateTime: string;
    departureTime: string;
    arrivalTime: string;
    provider: string;
    duration: string;
    stop: string;
    vehicle: string;
    lowestOffer: string;
    operatingSegmentId: string;
    operatingCode: string;
    operatingName: string;
    marketingSegmentId: string;
    offerId: string;
    showDetail: boolean;
    dayChange: string;
    fareDetails: OrderDetailProductChangeAvailabilityFareView[];
    fareDescription: OrderDetailProductChangeAvailabilityFareDescriptionView;
}
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model';
import { InvoiceIndividualService } from 'src/app/core/services/individul-services/invoice-individual.service';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-invoice-individual-search-result',
    templateUrl: './invoice-individual-search-result.component.html'
})
export class InvoiceIndividualSearchResultComponent extends DatatableComponentBase implements AfterViewInit {
    public searchResults: InvoiceIndividualSearchModel[];
    public focused = false;
    public rows: number;
    public exportData: any;
    public header: string[];

    public selectedItem: any;

    @Output() onSelectIndividual = new EventEmitter<InvoiceIndividualSearchModel>();

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    constructor(private invoiceIndividualService: InvoiceIndividualService,
        private navigationService: NavigationService,
        private changeDetector: ChangeDetectorRef) {
        super()
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public searchIndividual(condition: InvoiceIndividualSearchModel) {
        this.cleanDatatable();
        this.invoiceIndividualService.searchInvoiceIndividual(condition)
            .subscribe(
                (responses: InvoiceIndividualSearchModel[]) => {
                    var dynamicDataArray = new Array();
                    var index = 1;

                    responses.forEach(item => {
                        var individualTemps = {};
                        individualTemps["individualId"] = item.individualId;
                        individualTemps["no"] = index;
                        individualTemps["lastName"] = item.lastName;
                        individualTemps["firstName"] = item.firstName;
                        individualTemps["mailAddress"] = item.mailAddress;
                        individualTemps["phoneNumber"] = item.phoneNumber;
                        individualTemps["address"] = item.address;
                        individualTemps["addressId"] = item.addressId
                        dynamicDataArray.push(individualTemps);
                        index += 1;
                    });
                    this.searchResults = dynamicDataArray;
                    this.rows = this.searchResults.length;
                    this.changeDetector.detectChanges();
                }
            )
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data);
            })
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private mapData(data: any): any {
        return {
            No: data.no,
            LastName: data.lastName,
            FirstName: data.firstName,
            Email: data.email,
            Phone: data.phone,
            Address: data.address
        };
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    doubleClick(event) {
        this.selectedItem = event.data;
        this.onSelectIndividual.emit(this.selectedItem);
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
    }

    get hideExport(): boolean {
        return this.rows <= 0;
    }

    new() {
        let queryParams = {
            selectedTab: 'SEARCH'
        };
        this.navigationService.navigate(`main/insight/${Date.now().toString()}/home`, "Insight", true, queryParams);
    }

}

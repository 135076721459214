import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { LoadingSpinnerService } from '../../../../shared/layout/loading-spinner';
import { ProductCategoryViewModel } from 'src/app/core/models/reference-model/reference-product-model/product-category-view.model';
import { ProductCategoryService } from 'src/app/core/services/reference-service/reference-product-service/product-category.service';
import { ProductCategoryMapperService } from './product-category.mapper';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { ProductCategory } from './product-category';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'op-product-categories',
  templateUrl: './product-categories.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductCategoryMapperService]
})
export class ProductCategoriesComponent {
  private readonly actionIdNew = 'NEW';
  private readonly actionIdSearch = 'SEARCH';
  private readonly actionIdGroup = 'GROUP';
  private readonly actionIdDashboard = 'DASHBOARD';
  private readonly merchandizeCode = 'MERCHANDIZE';
  private readonly voucherCode = 'VOUCHER';
  private readonly transportCode = 'TRANSPORT';
  private readonly ancillaryCode = 'ANCILLARY';
  private readonly specialServiceCode = 'SERVICE';
  private readonly feeCode = 'FEE';

  @Input() initSelectedItem: ProductCategory;
  @Input() productSecurities: SecurityGroupSecurityModel[];
  @Output() rowSelected = new EventEmitter<ProductCategory>();
  @Output() menuSelected = new EventEmitter<string>();

  public categoriesItems: ProductCategory[] = [];
  public selectedItem: ProductCategory;
  private unsubscribe$ = new Subject();

  private contextMenu: OopsCategoryContextMenu[] = [
    {
        name: 'New',
        actionId: this.actionIdNew
    },
    {
        name: 'Group',
        actionId: this.actionIdGroup
    },
    {
        name: 'Search',
        actionId: this.actionIdSearch
    },
    {
        name: 'Dashboard',
        actionId: this.actionIdDashboard
    }
  ]

  private contextSSRMenu: OopsCategoryContextMenu[] = [
    {
        name: 'New',
        actionId: this.actionIdNew
    },
    {
        name: 'Search',
        actionId: this.actionIdSearch
    },
    {
        name: 'Dashboard',
        actionId: this.actionIdDashboard
    }
  ]
  
  constructor(
    private actionbarService: ActionbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private productCategoryService: ProductCategoryService,
    private productCategoryMapperService: ProductCategoryMapperService,
    private mainSpinner: LoadingSpinnerService,
    private navigationService: NavigationService
  ) {
    
  }

  ngOnInit(): void {
    this.selectedItem = this.initSelectedItem;
  }

  ngAfterViewInit(): void {
    this.actionbarService.updateState(new ActionBarHandlerModel(
      new RefreshButtonModel()
    ));
    this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      actionId => {
        switch(actionId) {
          case ACTION_STATUS.new:
            this.gotoNewProduct();
            break;
          case ACTION_STATUS.refresh:
            this.getProductCategories();
            break;
        }
      }
    );
    this.getProductCategories();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
  
  public getProductCategories() {
    this.mainSpinner.show();
    this.productCategoryService.getAllActive()
      .subscribe(
        (responses: ProductCategoryViewModel[]) => {
          this.categoriesItems = this.productCategoryMapperService.toOopsCategoryViews(responses);
          this.setContextMenus(this.categoriesItems);
          this.categoriesItems = this.categoriesItems.filter(item => item.security);
 
          if (this.selectedItem) {
            this.reInitialSelectedItem();
          } else {
            if (responses.length > 0) {
              this.selectedItem = this.categoriesItems[0]; 
              this.onRowSelected(this.categoriesItems[0]);
            }
          }
        },
        () => { },
        () => {
          this.mainSpinner.hide();
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  private reInitialSelectedItem() {
    let found = this.categoriesItems.filter((x) => x.code == this.selectedItem.code);
    if (found) {
      this.selectedItem = found[0];
    }
  }

  private setContextMenus(categoriesItems: ProductCategory[]) {
    for (let item of categoriesItems) {
      if (item.code == this.specialServiceCode) {
        item.contextMenus = this.contextSSRMenu;
      } else {
        item.contextMenus = this.contextMenu;
      }
      this.setupCatagorySecurity(item);
    }
  }

  onRowSelected(item: any) {
    this.selectedItem = item;
    this.rowSelected.emit(item);
  }

  onContextMenuSelected(actionId: string) {
    switch(actionId) {
      case this.actionIdNew:
        this.gotoNewProduct();
        break;
        
      case this.actionIdSearch:
          this.menuSelected.emit(actionId);
          break;
  
      case this.actionIdGroup:
        this.gotoGroup();
        break;

      default:
        this.menuSelected.emit(actionId);
        break;
    }
  }

  private gotoGroup() {
    let params = {
        selectedItem: this.selectedItem,
        userSecurity: this.selectedItem.security
    }
    this.navigationService.navigate(this.selectedItem.url, null, false, params);
  }

  private gotoNewProduct() {
    if (this.selectedItem.code == this.merchandizeCode) {
      this.gotoNewMerchandize(this.selectedItem);
    } else if (this.selectedItem.code == this.voucherCode) {
        this.gotoNewVoucher(this.selectedItem);
    } else if (this.selectedItem.code == this.transportCode || 
                 this.selectedItem.code == this.ancillaryCode ||
                 this.selectedItem.code == this.specialServiceCode|| 
                 this.selectedItem.code == this.feeCode) {
        this.gotoProductDetail(this.selectedItem);
    }
  }

  private gotoNewMerchandize(item: ProductCategory) {
    this.navigationService.navigate('/main/product/merchandises/details', null, null, {
      tab: 'categories',
      module: item,
      userSecurity: item.security
    });
  }

  private gotoNewVoucher(item: ProductCategory) {
    this.navigationService.navigate('/main/product/voucher/details', null, null, {
      newVoucher: true,
      tab: 'categories',
      module: item,
      userSecurity: item.security
    });
  }

  private setupCatagorySecurity(category: ProductCategory) {
    let categorySecurity = this.productSecurities?.find(item => item.securityCode == this.getCategorySecurityCode(category.code));
    category.security = categorySecurity;
    category.contextMenus = this.setupContextMenuSecurity(category.contextMenus, categorySecurity);
  }

  private getCategorySecurityCode(categoryCode: string): string {
    if (categoryCode == 'RENTALCAR') {
      categoryCode = 'CAR';
    }
    else if (categoryCode == 'TRANSPORT') {
      return 'PRODUCTTRANSPORT'
    }
    return 'PRODUCT' + categoryCode;
  }

  private setupContextMenuSecurity(menu: OopsCategoryContextMenu[], security: SecurityGroupSecurityModel): OopsCategoryContextMenu[] {
    let newBtnIndex = menu.findIndex(item => item.actionId == this.actionIdNew);
    let newMenu = cloneDeep(menu);

    newMenu[newBtnIndex].disable = !security?.newFlag;
    return newMenu;
  }

  private gotoProductDetail(item: ProductCategory) {
    this.navigationService.navigate(item.url + '/details', null, null, {
      tab: 'categories',
      newProduct: true,
      module: item,
      userSecurity: item?.security
    });
  }
}
import { Directive } from '@angular/core';

@Directive({
  selector: '[securityLevel2Tabs]'
})
export class SecurityLevel2TabsDirective {

  constructor() { }

}

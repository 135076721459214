import { MediaContentViewModel } from './media-content';
import { MediaMediaHashTagViewModel } from './media-media-hashtag';
import { MediaUseViewModel } from './media-use';

export class MediaSearchViewModel {
    public mediaId: string;
    public organisationId: string;
    public mediaTypeCode: string;
    public mediaTypeName: string;
    public mediaName: string;
    public validFromDateTime: Date;
    public validToDateTime: Date;
    public totalUsedCount: Number;
    public mediaContents: MediaContentViewModel[];
    public mediaMediaHashTags: MediaMediaHashTagViewModel[];
    public mediaUses: MediaUseViewModel[];
}
import { NgModule } from "@angular/core";
import { CommunicationOtherTypeSelectionComponent } from "./communication-other-type-selection.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiModule } from "src/app/shared/ui/ui.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";

@NgModule({
    declarations: [
        CommunicationOtherTypeSelectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        Select2Module,
        UiModule,
    ],
    exports: [
        CommunicationOtherTypeSelectionComponent
    ]
})
export class CommunicationOtherTypeSelectionModule {}
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: 'status-badge-lite',
    templateUrl: 'status-badge.component.html',
    styleUrls: ['status-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBadgeLiteComponent {

    @Input() statusHighlightColor: string;
    @Input() statusName: string;


}
import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PricingDetailConstant } from '../../../../shared/pricing-detail.constant';
import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleOrganisationView extends PriceRuleConditionBase {
    public priceRuleOrganisationId: string;
    public priceRuleOrganisationTypeCode: string;
    public organisationId: string[];
    public organisationTypeCode: string[]
    public organisationRoleCode: string[];
    public organisationGroupCode: string[];
    public preSelectedDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public get type() {
        if (this.organisationTypeCode) {
            return PricingDetailConstant.POINT_OF_SALES_TYPE;
        } else if (this.organisationRoleCode) {
            return PricingDetailConstant.POINT_OF_SALES_ROLE;
        } else if (this.organisationGroupCode) {
            return PricingDetailConstant.POINT_OF_SALES_GROUP;
        } else if (this.organisationId) {
            return PricingDetailConstant.POINT_OF_SALES_SPECIFIC;
        } else {
            return null;
        }
    }
    public set type(val) {
        if (val == PricingDetailConstant.POINT_OF_SALES_TYPE) {
            this.organisationTypeCode = new Array<string>();
            this.organisationRoleCode = null;
            this.organisationGroupCode = null;
            this.organisationId = null;
        } else if (val == PricingDetailConstant.POINT_OF_SALES_ROLE) {
            this.organisationTypeCode = null;
            this.organisationRoleCode = new Array<string>();
            this.organisationGroupCode = null;
            this.organisationId = null;
        } else if (val == PricingDetailConstant.POINT_OF_SALES_GROUP) {
            this.organisationTypeCode = null;
            this.organisationRoleCode = null;
            this.organisationGroupCode = new Array<string>();
            this.organisationId = null;
        } else if(val == PricingDetailConstant.POINT_OF_SALES_SPECIFIC) {
            this.organisationTypeCode = null;
            this.organisationRoleCode = null;
            this.organisationGroupCode = null;
            this.organisationId = new Array<string>();
        } else {
            this.organisationTypeCode = null
            this.organisationRoleCode = null;
            this.organisationGroupCode = null;
            this.organisationId = null;
        }
    }
}
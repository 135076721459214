import { NgModule } from "@angular/core";
import { OrderDetailCommentDetailComponent } from "./order-detail-comment-detail.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiModule } from "src/app/shared/ui/ui.module";
import { CommentModule } from "src/app/core/components/comment/comment.module";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [
        OrderDetailCommentDetailComponent
    ],
    exports: [
        OrderDetailCommentDetailComponent
    ],
    imports: [
        CommentModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        PanelsModule,
        ReactiveFormsModule,
        UiModule
    ]
})
export class OrderDetailCommentDetailModule {}
export const select2OperatorRestrictionRoute = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-green",
    width :'auto',
    dropdownAutoWidth : true
}

export const select2OriginRoute = {
    placeholder: "<Origin>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true,
    minimumInputLength:3,
    ajax:null
}

export const select2DestinationRoute = {
    placeholder: "<Destination>",
    allowClear: false,
    minimumResultsForSearch: -1,
    theme: "select2-builder-gray",
    width :'auto',
    dropdownAutoWidth : true,
    minimumInputLength:3,
    ajax:null
}

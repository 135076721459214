import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event-package';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { StatusReferenceModel, UsageTypeReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { ProductCategoryReferenceModel, ProductGroupReferenceModel, ProductTypeGroupModel, ProductTypeReferenceModel } from 'src/app/core/models/reference-model/reference-product-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { generalInfoFormOption } from '../shared/form-config';
import { select2DefaultOption, select2DoNotAllowClear } from '../shared/select2-config';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-general-information',
    templateUrl: './general-information.component.html'
})
export class GeneralInformationComponent extends OopsComponentFormBase implements OnChanges {
    private readonly SERVICE_CATEGORY_CODE = 'SERVICE';
    private readonly USAGE_TYPE_NEW = 'DATA';
    private readonly NAME_REQUIRED_ERROR = 'Service Inventory Group Name is required.'
    private readonly USAGE_REQUIRED_ERROR = 'Usage Type is required.'
    private readonly PRODUCT_GROUP_REQUIRED_ERROR = 'Product Group is required.'

    public filteredProductTypeGroup: ProductTypeGroupModel[];
    public productCategoryOption: any;
    public productGroupOption: any;
    public productTypeGroupOption: any;
    public statusOption: any;
    public supplierOption: any;
    public usageTypeOption: any;
    
    @Input() productGeneralInfo;
    @Input() newProduct = true;
    @Input() copyProduct = false;
    @Input() productCategoryReference: ProductCategoryReferenceModel[];
    @Input() productGroupReference: ProductGroupReferenceModel[];
    @Input() productTypeGroup: ProductTypeGroupModel[];
    @Input() statusReference: StatusReferenceModel[];
    @Input() usageTypeReference: UsageTypeReferenceModel[];
    @Input() productTypeReference: ProductTypeReferenceModel[];
    @Input() saveAction: boolean = false;
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Output() onPanelHeightChange = new EventEmitter();
    @Output() productTypeCode = new EventEmitter<string>();
    @Output() productGroupCode = new EventEmitter<string>();
    @Output() detailChange = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public focusing: boolean = false;

    constructor(private formBuilder: UntypedFormBuilder) {
        super(formBuilder);
        this.setOptionControl();
    }

    ngOnChanges(changes: SimpleChanges): void { 
        if (changes['productGeneralInfo']) {
            this.formSetup();
        }
        if (changes['newProduct']) {
            this.checkNewProduct();
        }
    }

    public initForm() {
        this.formSetup();
        this.checkNewProduct();
    }

    public formSetup() {
        this.formGroup = this.formBuilder.group(
            generalInfoFormOption
        );
        this.formGroup.patchValue({
            productCategoryCode: this.SERVICE_CATEGORY_CODE,
            usageTypeCode: this.USAGE_TYPE_NEW
        });
        if (this.productGeneralInfo == null) {
            return;
        }
        this.fillInfoToForm(this.productGeneralInfo);
    }

    private fillInfoToForm(info) {
        this.formGroup.patchValue({
            productCategoryCode: this.SERVICE_CATEGORY_CODE,
            productGroupCode: info.productGroupCode,
            productTypeGroupId: info.productTypeCode,
            productName: info.name,
            productDescription: info.productDescription,
            draftFlag: info.draftFlag,
            statusCode: info.statusCode,
            commitDateTime: info.commitDateTime,
            commitByName: info.commitBy,
            usageTypeCode: info.usageTypeCode
        })
    }

    private setOptionControl() {
        this.productCategoryOption = select2DefaultOption;
        this.productGroupOption = select2DefaultOption;
        this.productTypeGroupOption = select2DefaultOption;
        this.statusOption = select2DoNotAllowClear;
        this.supplierOption = select2DefaultOption;
        this.usageTypeOption = select2DefaultOption;
    }

    public onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        } 
    }

    private checkNewProduct() {
        if (this.newProduct == false) {
            this.formGroup.controls['productGroupCode'].disable();
        }      
    }

    public valueChange(value, name) {
        this.formGroup.get(name).setValue(value);
        if (this.saveAction == true) {
            this.detailChange.emit();
        }
    }

    public productGroupChange(value) {
        this.valueChange(value, 'productGroupCode');
        this.productGroupCode.emit(value);
    }

    public productTypeGroupChange(value) {
        this.valueChange(value, 'productTypeGroupId');
        this.productTypeCode.emit(value);
    }

    public initNewform(parentId, productGroupCode, productTypeGroupId, providerId) {
        this.formGroup.patchValue({
            parentProductId: parentId,
            productCategoryCode: this.SERVICE_CATEGORY_CODE,
            productGroupCode: productGroupCode,
            productTypeGroupId: productTypeGroupId,
            providerId: providerId,
            draftFlag: true,
            statusCode: "A",
            productDescription: "",
            usageTypeCode: "DATA",
            productName: "",
            commitDateTime: null,
            commitByName: null,

        });
    }

    public validGeneral() {
        this.saveAction = true;
        return this.validForm()
    }

    public getErrorMessage() {
        if (!this.formGroup.controls["productName"].valid) {
            return this.NAME_REQUIRED_ERROR;
        } else if (!this.formGroup.controls["usageTypeCode"].valid) {
            return this.USAGE_REQUIRED_ERROR;
        } else if (!this.formGroup.controls["productGroupCode"].valid) {
            return this.PRODUCT_GROUP_REQUIRED_ERROR;
        }
        return null;
    }

    public productNameChange() {
        if (this.saveAction == true) {
            this.detailChange.emit();
        }
    }

    public getDisabledStatus(): boolean {
        if (!this.formGroup.get('statusCode').disabled) {
            return false;
        }
        return true;
    }

    get deleteFlag() {
        return this.userSecurity?.deleteFlag ?? false;
    }
}

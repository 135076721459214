import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, RefreshButtonModel, SaveButtonModel, CancelButtonModel, DeleteButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { SecurityLevel2TableModel } from '../shared/security-level2-table/security-level2-teble.model';
import { takeUntil } from "rxjs/operators";
import { ActionService } from 'src/app/core/utils/action.service';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SecurityGroupTreeModel } from 'src/app/core/models/security-model/security-group-tree.model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { SecurityGroupReference } from 'src/app/core/models/security-model/security-group-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';

@Component({
    selector: 'op-security-group',
    templateUrl: './security-group.component.html',
    providers: [ActionbarService],
})
export class SecurityGroupComponent implements OnInit, AfterViewInit {
    private readonly FUCNTION_TABID = 'FUNCTION';
    private readonly SEARCH_TABID = 'SEARCH';

    public selectedTab = this.FUCNTION_TABID;
    private unsubscribe$ = new Subject();
    public executeFlag = true;
    private selectedItem: SecurityLevel2TableModel;
    private navigateParams: any;
    public moduleSecurity: SecurityGroupSecurityModel;
    public securities: SecurityGroupSecurityModel[];

    actionBarHandler = new ActionBarHandlerModel(new NewButtonModel(), new RefreshButtonModel());
    securityGroups$ = new BehaviorSubject<SecurityLevel2TableModel[]>(null);
    public paggingView: PagingDataView[] = [];

    constructor(private actionbarService: ActionbarService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private securityGroupService: SecurityGroupService,
        private loadingSpinnerService: LoadingSpinnerService) {}

    ngOnInit(): void {
        this.navigateParams = this.navigationService.getParams();
        this.executeFlag = this.navigateParams?.executeFlag ?? true;
        this.moduleSecurity = this.navigateParams?.moduleSecurity;
        this.securities = this.navigateParams?.securities;
        this.selectTab(this.navigateParams?.selectedTab);
        this.getAllSecurityGroup();
    }

    ngAfterViewInit(): void {
        this.setupActionBar();
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    selectTab(tabName: string) {
        this.selectedTab = tabName;
        this.selectedTabChange(tabName);
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            case ACTION_STATUS.new:
                this.new();
                break;
            case ACTION_STATUS.refresh:
                this.getAllSecurityGroup();
                break;
            case ACTION_STATUS.cancel:
                this.cancel()
            default:
                break;
        }
    }

    back() {
        this.navigateParams.selectedTab = this.FUCNTION_TABID;
        this.navigationService.navigate('main/settings/security', 'Security Management', false, this.navigateParams);
    }

    cancel() {
        this.navigationService.navigate('main/settings/home', 'Settings Management');
    }

    new() {
        if (this.selectedItem) {
            this.checkAddChildNode(this.selectedItem.id);
        } else {
            this.newGroup();
        }
    }

    newGroup() {
        let param = {
            newFlag: true,
            selectedTab: this.selectedTab,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities 
        }
        this.navigationService.navigate('/main/settings/security-group/details', null, false, param);
    }

    newChildNode() {
        let param = {
            newFlag: true,
            id: this.selectedItem.id,
            selectedTab: this.selectedTab,
            moduleSecurity: this.moduleSecurity,
            securities: this.securities
        }
        this.navigationService.navigate('/main/settings/security-group/details', null, false, param);
    }

    private checkAddChildNode(id: string) {
        this.securityGroupService.getSecurityGroupTree(id)
            .subscribe(
                (response: SecurityGroupTreeModel) => {
                    if (response.id == id) {
                        this.newGroup();
                    } else {
                        this.newChildNode();
                    }
                },
                () => {
                    this.newGroup();
                }
            )
    }

    selectedTabChange(activeId: string) {
        this.addAction(activeId);
        this.setupActionBar();
    }

    addAction(activeId: string) {
        this.actionService.add(activeId, null, null);
    }

    getAllSecurityGroup() {
        this.loadingSpinnerService.show();
        this.securityGroupService.getAllSecurityGroupReference()
            .subscribe(
                (response: SecurityGroupReference[]) => {
                    let groups: SecurityLevel2TableModel[] = new Array();
                    let index = 1;
                    response.forEach(item => {
                        let group: SecurityLevel2TableModel = {
                            id: item.securityGroupId,
                            name: item.securityGroupName,
                            modifiedBy: item.commitByName,
                            modifiedDateTime: item.commitDateTime
                        }
                        groups.push(group);
                        this.addPaggingView(index, item.securityGroupId);
                        index += 1;
                    }) 
                    this.securityGroups$.next(groups)
                    this.loadingSpinnerService.hide();
                }
            )
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    setupActionBar() {
        switch (this.selectedTab) {
            case this.SEARCH_TABID:
                this.setupSearchActionBar();
                break;
            default:
                this.setupDefaultActionBar();
                break;
        }
    }

    setupDefaultActionBar() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable(this.moduleSecurity.newFlag);
        this.actionbarService.updateState(this.actionBarHandler);
    }

    setupSearchActionBar() {
        let searchActionBarHandler = new ActionBarHandlerModel(
            new NewButtonModel(),
            new CopyButtonModel(),
            new SaveButtonModel(),
            new CancelButtonModel(),
            new DeleteButtonModel(),
            new RefreshButtonModel(),
        )
        searchActionBarHandler.get(ACTION_STATUS.new).enable(this.moduleSecurity.newFlag);
        searchActionBarHandler.get(ACTION_STATUS.copy).enable(this.moduleSecurity.copyFlag);
        searchActionBarHandler.get(ACTION_STATUS.save).disable();
        searchActionBarHandler.get(ACTION_STATUS.delete).disable();
        this.actionbarService.updateState(searchActionBarHandler);
    }

    groupItemClick(item: SecurityLevel2TableModel) {
        this.selectedItem = item;
    }

}

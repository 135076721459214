import { Validators } from '@angular/forms';

export const generalInfoFormOption = {
  productCategoryCode: [{ value: null, disabled: true }, Validators.required],
  productGroupCode: [{ value: null }, Validators.required],
  productTypeGroupId: [{ value: null }, Validators.required],
  parentProductId: [],
  productName: ['', [Validators.required]],
  productDescription: [''],
  usageTypeCode: [{ value: null }, Validators.required],
  draftFlag: [],
  finalFlag: [{ value: null, disabled: true}],
  statusCode: [],
  commitDateTime: [{ value: null, disabled: true }],
  commitByName: [{ value: null, disabled: true },]
};

export const schedulPeriodFormOption = {
  productSchedulePeriodId: [],
  productScheduleVersionId: [],
  comment: []
};

export const flightFormOption = {
  flightStatusCode: [],
  providerId: [{ value: null }, Validators.required],
  flightNumber: [{ value: null }, Validators.required],
  supplierId: [],
  vehicleTypeCode: [{ value: null }, Validators.required],
  vehicleCompositionId: [{ value: null }, Validators.required],
  vehicleId: []
};
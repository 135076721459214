import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { GeographyCommitInfo } from "../../models/geography-model/geography-commit-info";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GeographyService extends ApiServiceBase {
    private readonly Path = '/system/Geographies'

    constructor(
        httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService)
    }

    public getCommitInfos(): Observable<GeographyCommitInfo[]> {
        return this.httpGet<GeographyCommitInfo[]>(this.Path, null)
            .pipe(
                map(res => res.body)
            )
    }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { scrollToTop } from 'src/app/shared/utils/utils.functions';

import { PricingDetailAttributeAndRuleComponent } from './attribute-and-rule/attribute-and-rule.component'
import { GeneralComponent } from './general/general.component'


import { Helper } from 'src/app/shared/helper/app.helper';
import { MessageService } from 'src/app/shared/helper/message.service';

import { Subscription } from 'rxjs/internal/Subscription';
import { PriceRuleCommand, PriceRuleModel } from 'src/app/core/models/pricing-model';
import { PricingService } from 'src/app/core/services/pricing-services';
import { PricingConverter } from '../../shared/pricing.converter';
import { PriceRuleView } from '../../shared/views/price-rule.view';
import { BehaviorSubject, Subject } from 'rxjs';
import { AttributePriceRuleGroupCode, PricingDetailConstant } from './shared/pricing-detail.constant';
import { DomainAttributeService, LanguageReferenceService, ProductTypeGroupService } from 'src/app/core/services/airline-services';
import { CollectionHelperService } from 'src/app/core/utils/collection-helper.service';

import { ProductTypeGroupModel } from 'src/app/core/models/reference-model/reference-product-model';
import { PriceRuleCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-pricing-model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    PriceRuleCategoryReferenceService,
    StatusReferenceService
} from 'src/app/core/services/system-services';
import { DomainAttributeModel, StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

import { PricingDetailFocusView } from './shared/pricing-detail-focus.view';
import { Observable } from 'rxjs';
import { TranslationService } from 'src/app/core/services/translation-services';
import { TranslationNameComponent } from 'src/app/core/components/translation-name/translation-name.component';
import { LanguageTranslationSetViewModel } from 'src/app/core/models/language-translation-set-model';
import { TranslationNameMapperService } from 'src/app/core/components/translation-name/translation-name.mapper.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { NewButtonModel, CopyButtonModel, SaveButtonModel, CancelButtonModel, RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { InclusiveOfProductsComponent } from './inclusive-of-products/inclusive-of-products.component';
import { PriceMappingComponent } from './price-mapping/price-mapping.component';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { StatusConstant } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { LanguageReferenceModel, MediaHashTagModel, MediaTypeFileTypeModel, MediaTypeReferenceModel, MediaUseReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { MediaHashTagService, MediaTypeFileTypeService, MediaTypeReferenceService, MediaUseReferenceService } from 'src/app/core/services/reference-service/reference-media-service';
import { MediaMapperService } from 'src/app/core/components/media/shared/media-mapper.service';
import { TextMapperService } from 'src/app/core/components/text/shared/text-mapper.service';
import { TextMediaComponent } from 'src/app/core/components/text-media/text-media.component';
import { MediaViewModel } from 'src/app/core/models/media-model';
import { PricingMediaConverter } from '../../shared/pricing-media.converter';
import { cloneDeep } from 'lodash';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { BaggageAllowanceComponent } from './baggage-allowance/baggage-allowance.component';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { ActivityDomainComponent } from 'src/app/core/components/activity-domain/activity-domain.component';
import { TranslationTextComponent } from 'src/app/core/components/translation-text/translation-text.component';
import { TranslationTextMapperService } from 'src/app/core/components/translation-text/shared/translation-text-mapper.service';
import { TranslationMediaMapperService } from 'src/app/core/components/translation-media/shared/translation-media-mapper.service';
import { TranslationMediaComponent } from 'src/app/core/components/translation-media/translation-media.component';

declare var $: any;

@Component({
    selector: "op-price-rule-detail",
    templateUrl: "./price-rule-detail.component.html",
    providers: [
        ActionbarService,
        MediaMapperService,
        TextMapperService,
        TranslationTextMapperService,
        TranslationMediaMapperService
    ],
})
export class PriceRuleDetailComponent
    implements OnInit, OnDestroy, AfterViewInit {
    public readonly ENTITY_NAME = "PriceRule";
    private readonly TRANSLATION_NAME = "Airline translation set";
    private readonly DEFAULT_PRICE_RULE_GROUP_CODE = 'PRICE';
    private readonly DISPLAY_NAME_TRANSLATION = "Price Rule Display Name Translations";
    private readonly NAME_TRANSLATION = "Price Rule Name Translations";
    private readonly COLUMN_DISPLAY_NAME_TRANSLATION = "Price Rule Display Name";
    private readonly COLUMN_NAME_TRANSLATION = "Price Rule Name";

    readonly generalTabId = "general";
    readonly attributesTabId = "attributes";
    readonly textTabId = "text";
    readonly mediaTabId = "media";
    readonly translationTabId = "translation";
    readonly historyTabId = "history";
    readonly readModeTabId = "readMode";

    public messageSize: number = 20;
    public page: number = 3;
    public maxSize: number = 5;
    public pageSize: number = 1;
    public isSaving = false;

    public focus = new PricingDetailFocusView();

    public showReadMode: boolean = false;
    public showTranslation = false;
    public hiddenTree = false;

    public priceRuleView$ = new BehaviorSubject<PriceRuleView>(null);
    public priceRuleCategoryReferences$ = new BehaviorSubject<Select2Data[]>(null);
    public statusData$ = new BehaviorSubject<Select2Data[]>(null);
    public statusCode$ = new BehaviorSubject<string>("A");

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    defaultActionbarHandler: ActionBarHandlerModel;

    private domainAttributes: DomainAttributeModel[];
    private productTypeGroups: ProductTypeGroupModel[];
    public mainPriceRuleModel: PriceRuleModel;
    public selectedPriceRuleModel: PriceRuleModel;

    public languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);
    public mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    public mediaUseReference$ = new BehaviorSubject<MediaUseReferenceModel[]>(null);
    public mediaHashTagSelect2Data: Select2Data[];
    public mediaTypeFileType$ = new BehaviorSubject<MediaTypeFileTypeModel[]>(null);
    public translationName$ = new BehaviorSubject<string>(null);
    public translationPanelName$ = new BehaviorSubject<string>(this.DISPLAY_NAME_TRANSLATION);
    public translationColumnName$ = new BehaviorSubject<string>(this.COLUMN_DISPLAY_NAME_TRANSLATION);

    public pagingDataViews: PagingDataView[] = [];
    public userSecurity: SecurityGroupSecurityModel;

    classIcon: string = this.helper.getClassIcon();
    heightPanelGeneral: number = 465;
    scrollAnchor: string;

    @Input() newPriceRule: boolean = false;
    @Output() onFormClose = new EventEmitter();
    @Output() onLoadComplete = new EventEmitter();

    // for scrolling
    @ViewChild("productDetailGeneralCodeRef")
    productDetailGeneralRef: ElementRef;
    @ViewChild("productDetailAttributeAndRuleRef")
    productDetailAttributeAndRuleRef: ElementRef;
    @ViewChild("productDetailCodeRef") productDetailCodeRef: ElementRef;
    @ViewChild("productDetailTextRef") productDetailTextRef: ElementRef;
    @ViewChild("productDetailAttributeRef")
    productDetailAttributeRef: ElementRef;
    @ViewChild("productDetailMediaRef") productDetailMediaRef: ElementRef;
    @ViewChild("productDetailRestrictionRef")
    productDetailRestrictionRef: ElementRef;
    @ViewChild("productDetailValidityRef") productDetailValidityRef: ElementRef;
    @ViewChild("productDetailInventoryRef")
    productDetailInventoryRef: ElementRef;
    @ViewChild("productDetailTextTranslationRef")
    productDetailTextTranslationRef: ElementRef;

    // for set focus
    @ViewChild(GeneralComponent)
    generalComponent: GeneralComponent;
    @ViewChild(PricingDetailAttributeAndRuleComponent)
    pricingDetailAttributeAndRuleComponent: PricingDetailAttributeAndRuleComponent;
    @ViewChild(TranslationNameComponent)
    translationNameComponent: TranslationNameComponent;
    @ViewChild(InclusiveOfProductsComponent)
    inclusiveOfProductsComponent: InclusiveOfProductsComponent;
    @ViewChild(PriceMappingComponent)
    priceMappingComponent: PriceMappingComponent;
    @ViewChild(TextMediaComponent) textMedia: TextMediaComponent;
    @ViewChild(BaggageAllowanceComponent) baggageAllowanceComponent: BaggageAllowanceComponent;
    @ViewChild(ActivityDomainComponent) activityDomainComponent: ActivityDomainComponent;
    @ViewChild(TranslationTextComponent) translationTextComponent: TranslationTextComponent;
    @ViewChild(TranslationMediaComponent) translationMediaComponent: TranslationMediaComponent;
    
    private priceRuleModel: PriceRuleModel;
    private languageTranslationSet: LanguageTranslationSetViewModel;
    private params: any;

    subscription: Subscription;
    unsubscribe$ = new Subject();

    selectedTab = this.generalTabId;
    previousPage: string;
    pageChanging: boolean = false;

    get hideGeneralTab(): boolean {
        return (
            this.selectedTab != this.generalTabId &&
            this.selectedTab != this.attributesTabId &&
            this.selectedTab != this.translationTabId &&
            this.selectedTab != this.textTabId &&
            this.selectedTab != this.mediaTabId
        );
    }

    get hideInclusiveProduct(): boolean {
        return this.priceRuleView$.value?.priceRuleCategoryCode != this.DEFAULT_PRICE_RULE_GROUP_CODE;
    }

    get hideBaggageAllowance(): boolean {
        return this.priceRuleView$.value?.priceRuleCategoryCode != this.DEFAULT_PRICE_RULE_GROUP_CODE;
    }

    get currentPage(): number {
        if (!this.selectedPriceRuleModel) {
            return -1;
        }
        if (!this.pagingDataViews?.length) {
            return -1;
        }
        return this.pagingDataViews.map(v => v.id).indexOf(this.selectedPriceRuleModel.id) + 1;
    }

    constructor(
        private helper: Helper,
        private messageService: MessageService,
        private pricingService: PricingService,
        private pricingConverter: PricingConverter,
        private domainAttributeService: DomainAttributeService,
        private collectionHelperService: CollectionHelperService,
        private productTypeGroupService: ProductTypeGroupService,
        private priceRuleCategoryReferenceService: PriceRuleCategoryReferenceService,
        private statusReferenceService: StatusReferenceService,
        private translationService: TranslationService,
        private translationNameMapperService: TranslationNameMapperService,
        private navigationService: NavigationService,
        private loadingSpinnerService: LoadingSpinnerService,
        private focusingService: FocusingService,
        private actionbarService: ActionbarService,
        private stringHelperService: StringHelperService,
        private changeDetectorRef: ChangeDetectorRef,
        public alertBarService: AlertBarService,
        private languageReferenceService: LanguageReferenceService,
        private mediaTypeReferenceService: MediaTypeReferenceService,
        private mediaUseReferenceService: MediaUseReferenceService,
        private mediaHashTagService: MediaHashTagService,
        private mediaTypeFileTypeService: MediaTypeFileTypeService,
        private mediaMapperService: MediaMapperService,
        private textMapperService: TextMapperService,
        private pricingMediaConverter: PricingMediaConverter,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService,
        private translationTextMapperService: TranslationTextMapperService,
        private translationMediaMapperService: TranslationMediaMapperService
    ) {
        // subscribe to home component messages
        this.subscription = this.messageService.onMessage().subscribe(() => {
            $("#btnTemp").click();
        });
    }

    ngOnInit(): void {
        this.params = this.navigationService.getParams();
        if (!this.params)
            this.params = this.navigationService.getPreviousTabParams()?.params

        this.newPriceRule = this.params?.newPriceRule ?? true;
        this.previousPage = this.params?.previousPage;
        this.pagingDataViews = this.params?.pagingDataViews ?? [];
        this.userSecurity = this.params?.userSecurity;
        this.loadPage();
        this.addAction();
    }

    loadPage() {
        this.loadingSpinnerService.show();
        this.getEmptyLanguageTranslationSet();
        this.getLanguageReference();
        this.getMediaTypeReference();
        this.getMediaUseReference();
        this.getMediaHashTag();
        this.getMediaTypeFileType();
        this.resetTextMedia();
        this.priceRuleView$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((priceRuleView) => {
            if (priceRuleView) {
                this.statusCode$.next(priceRuleView.statusCode);
                this.setTranslationName(priceRuleView);
            }
        });
        scrollToTop(150);
        this.fetchData();
        this.hiddenTree = this.newPriceRule;
    }

    private setTranslationName(priceRuleView: PriceRuleView) {
        if (!priceRuleView) {
            return;
        }
        if (priceRuleView.priceRuleDisplayName) {
            this.translationName$.next(priceRuleView.priceRuleDisplayName);
            this.translationPanelName$.next(this.DISPLAY_NAME_TRANSLATION);
            this.translationColumnName$.next(this.COLUMN_DISPLAY_NAME_TRANSLATION);
        } else {
            this.translationName$.next(priceRuleView.priceRuleName);
            this.translationPanelName$.next(this.NAME_TRANSLATION);
            this.translationColumnName$.next(this.COLUMN_NAME_TRANSLATION);
        }
    }

    private resetTextMedia() {
        this.textMedia?.mediaComponent?.clearSessionData();
        this.textMedia?.textComponent?.clearSessionData();
    }

    onActionBarClicked(actionId: string) {
        if (
            actionId == ACTION_STATUS.back ||
            actionId == ACTION_STATUS.cancel
        ) {
            this.navigationService.navigate("/main/pricing/home", null, false, {
                previousPage: this.previousPage,
                userSecurity: this.userSecurity
            });
        }
        if (actionId == ACTION_STATUS.save) {
            this.save();
        }
        if (actionId == ACTION_STATUS.refresh) {
            this.loadPage();
        }
        if (actionId == ACTION_STATUS.copy) {
            this.copyPriceRule();
        }
        if (actionId == ACTION_STATUS.new) {
            this.newPriceRuleChild();
        }
    }

    ngAfterViewInit(): void {
        this.subscribeToPanelsFocus();
        this.setupActionbarSecurity();
        this.actionbarService.updateState(this.defaultActionbarHandler);
        this.actionbarService.action$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((actionId) => {
                this.onActionBarClicked(actionId);
            });
        this.textMedia.mediaComponent.activateSessionMode(true);
        this.textMedia.textComponent.activateSessionMode(true);
    }

    subscribeToPanelsFocus() {
        this.focusingService.focus(
            this.generalComponent.focusingDirective
        );
        this.generalComponent.focusingDirective.focusingChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((focusing) => {
                if (focusing) {
                    this.selectedTab = this.generalTabId;
                }
            });
        this.pricingDetailAttributeAndRuleComponent.focusEmit.subscribe(
            (focusing) => {
                if (focusing) {
                    this.selectedTab = this.attributesTabId;
                }
            }
        );
    }

    private setupActionbarSecurity() {
        this.newBtn.enable(this.userSecurity?.newFlag ?? false);
        this.copyBtn.enable(this.userSecurity?.copyFlag ?? false);
        this.saveBtn.enable(this.saveFlag);
        this.defaultActionbarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            new CancelButtonModel(),
            new RefreshButtonModel()
        );
    }

    get saveFlag(): boolean {
        return (this.newPriceRule == true && this.userSecurity?.newFlag == true) || (this.newPriceRule == false && this.userSecurity?.editFlag == true);
    }

    private getEmptyLanguageTranslationSet() {
        this.translationService
            .searchTranslationByEntityNameAndEntityPrimary(
                this.ENTITY_NAME,
                null
            )
            .subscribe(
                (
                    languageTranslationSets: LanguageTranslationSetViewModel[]
                ) => {
                    if (languageTranslationSets?.length) {
                        this.languageTranslationSet =
                            languageTranslationSets[0];
                        this.translationNameComponent?.fillModelToForm(
                            null,
                            this.languageTranslationSet
                        );
                    }
                }
            );
    }

    cancel() {
        this.onFormClose.emit();
    }

    hideTree() {
        this.hiddenTree = true;
    }

    showTree() {
        this.hiddenTree = false;
    }

    getHeightPanelGeneral(height) {
        this.heightPanelGeneral = height + 31.8;
    }

    readModeClick() {
        this.showReadMode = true;
        this.showTranslation = false;
    }

    cancelReadMode() {
        this.showReadMode = false;
    }

    translationClick() {
        this.showTranslation = true;
        this.showReadMode = false;
    }

    cancelTranslation() {
        this.showTranslation = false;
    }

    getSettingsGlobalFontSize() {
        let value = this.helper.getClassIcon();
        this.classIcon = value;
        return value;
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    save() {
        let model = this.gatherValues();
        if (!model) {
            return;
        }

        this.mainPriceRuleModel = model;

        let errorPriceRule = this.checkMissingPriceRuleCode(this.mainPriceRuleModel);
        if (errorPriceRule) {
            this.selectedPriceRuleModel = errorPriceRule;
            this.renderSelectedPriceRule(this.selectedPriceRuleModel);
            setTimeout(() => {
                this.gatherValues();
            }, 0);
            return;
        }
        let command = this.pricingConverter.toCommand(model);
        this.loadingSpinnerService.showSaving();
        if (command.id) {
            this.updatePriceRule(command);
        } else {
            this.addNewPriceRule(command);
        }
    }

    private checkMissingPriceRuleCode(priceRule: PriceRuleModel): PriceRuleModel {
        if (!priceRule.priceRuleCode) {
            return priceRule;
        }
        if (priceRule.children?.length) {
            for (let child of priceRule.children) {
                let found = this.checkMissingPriceRuleCode(child);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    public onSubmit() {
        this.priceRuleModel = this.pricingConverter.toModel(
            this.priceRuleView$.value
        );
        this.priceRuleModel = this.inclusiveOfProductsComponent.getValues(
            this.priceRuleModel
        );
        if (!this.priceRuleModel) {
            return;
        }
        this.priceRuleModel = this.priceMappingComponent.getValues(this.priceRuleModel);
        if (!this.priceRuleModel) {
            return;
        }
        this.fillLanguageTranslationSetToModel(this.priceRuleModel);
        this.loadingSpinnerService.showSaving();
        let command = this.pricingConverter.toCommand(this.priceRuleModel);
        if (command.id) {
            this.updatePriceRule(command);
        } else {
            this.addNewPriceRule(command);
        }
    }

    private fillLanguageTranslationSetToModel(priceRuleModel: PriceRuleModel) {
        let languageTranslationSet =
            this.translationNameMapperService.translationViewsToLanguageTranslationSetModel(
                this.translationNameComponent.languageTranslationSetId,
                this.TRANSLATION_NAME,
                this.ENTITY_NAME,
                priceRuleModel.id,
                this.translationNameComponent.translationViews ?? this.translationNameComponent.translationDB
            );
        priceRuleModel.languageTranslationSet = languageTranslationSet;
    }

    private updatePriceRule(model) {
        this.isSaving = true;
        this.activityDomainComponent.save();
        this.pricingService.updatePriceRule(model).subscribe(
            (res: PriceRuleModel) => {
                this.params.id = this.selectedPriceRuleModel.id;
                this.populateMainPriceRule(res, this.selectedPriceRuleModel.id);
                this.newPriceRule = false;
                this.loadingSpinnerService.saveComplete();
            },
            () => {
                this.loadingSpinnerService.hide();
            },
            () => {
                this.changeDetectorRef.detectChanges();
            }
        );
    }

    private addNewPriceRule(command: PriceRuleCommand) {
        this.isSaving = true;
        this.pricingService.addPriceRule(command).subscribe(
            (res: PriceRuleModel) => {
                this.params.id = res.id;
                this.populateMainPriceRule(res, res.id);
                setTimeout(() => {
                    this.loadingSpinnerService.saveComplete();
                }, 0);
                this.newPriceRule = false;
                this.setupActionbarSecurity();
            },
            () => {
                this.loadingSpinnerService.hide();
            }
        );
    }

    private fillLanguageTranslationSetToTranslationNameComponent(
        priceRuleView: PriceRuleView
    ) {
        this.translationNameComponent.fillModelToForm(
            this.getName(priceRuleView),
            priceRuleView.languageTranslationSet
        );
    }

    private getName(view: PriceRuleView): string {
        if (view?.priceRuleDisplayName) {
            return view.priceRuleDisplayName;
        }
        return view?.priceRuleName;
    }

    private assembleParentChildAttributesAndRules(priceRuleView: PriceRuleView, parentPriceRuleView: PriceRuleView) {
        priceRuleView.productEligibleData = parentPriceRuleView.productEligibleData.concat(priceRuleView.productEligibleData
        );
        priceRuleView.pointOfSalesData =
            parentPriceRuleView.pointOfSalesData.concat(
                priceRuleView.pointOfSalesData
            );
        priceRuleView.serviceCategoriesSalesBucketsData =
            parentPriceRuleView.serviceCategoriesSalesBucketsData.concat(
                priceRuleView.serviceCategoriesSalesBucketsData
            );
        priceRuleView.parentServiceCategoriesSalesBucketsData =
            parentPriceRuleView.parentServiceCategoriesSalesBucketsData.concat(
                priceRuleView.parentServiceCategoriesSalesBucketsData
            );
        priceRuleView.roundtripDefinitionData =
            parentPriceRuleView.roundtripDefinitionData.concat(
                priceRuleView.roundtripDefinitionData
            );
        priceRuleView.attributeData = parentPriceRuleView.attributeData.concat(
            priceRuleView.attributeData
        );
        priceRuleView.customerData = parentPriceRuleView.customerData.concat(
            priceRuleView.customerData
        );
        priceRuleView.productAttributeData =
            parentPriceRuleView.productAttributeData.concat(
                priceRuleView.productAttributeData
            );
        priceRuleView.transitStopoverData =
            parentPriceRuleView.transitStopoverData.concat(
                priceRuleView.transitStopoverData
            );
        priceRuleView.formOfPaymentData =
            parentPriceRuleView.formOfPaymentData.concat(
                priceRuleView.formOfPaymentData
            );
        priceRuleView.baggageAllowances = parentPriceRuleView.baggageAllowances.concat(priceRuleView.baggageAllowances);
    }

    private getPriceRuleProductOptionInherited(
        priceRuleView: PriceRuleView,
        parentPriceRuleView: PriceRuleView
    ) {
        priceRuleView.productOptions =
            parentPriceRuleView.productOptions.concat(
                priceRuleView.productOptions
            );
    }

    newPriceRuleChild() {
        let mainPriceRule = this.gatherValues();
        if (!mainPriceRule) {
            return;
        }

        let selectedPriceRule = this.findSelectedPriceRule(this.selectedPriceRuleModel.id, mainPriceRule);

        let newPriceRule = new PriceRuleModel();
        newPriceRule.id = this.stringHelperService.NewGuid();
        newPriceRule.parentPriceRuleId = this.selectedPriceRuleModel.id;
        newPriceRule.priceRuleCategoryCode = this.selectedPriceRuleModel.priceRuleCategoryCode;
        newPriceRule.priceRuleGroupCode = this.selectedPriceRuleModel.priceRuleGroupCode;
        newPriceRule.priceRuleTypeCode = this.selectedPriceRuleModel.priceRuleTypeCode;
        newPriceRule.usageTypeCode = this.selectedPriceRuleModel.usageTypeCode;
        newPriceRule.statusCode = StatusConstant.ACTIVE

        if (!selectedPriceRule.children?.length) {
            selectedPriceRule.children = [];
        }
        selectedPriceRule.children.push(newPriceRule);

        this.selectedPriceRuleModel = newPriceRule;
        this.mainPriceRuleModel = mainPriceRule;
        this.renderSelectedPriceRule(this.selectedPriceRuleModel);
        this.newPriceRule = true;
        this.setupActionbarSecurity();
    }

    public copyPriceRule() {
        let model = this.gatherValues();
        if (!model) {
            return;
        }

        let mainPriceRule = Object.assign({}, this.mainPriceRuleModel);
        let selectedId = this.selectedPriceRuleModel.id;
        let newSelectedId = this.setupPriceRulesAsCopied(mainPriceRule, null, selectedId, null);
        this.selectedPriceRuleModel = this.findSelectedPriceRule(newSelectedId, mainPriceRule);
        this.mainPriceRuleModel = mainPriceRule;
        this.renderSelectedPriceRule(this.selectedPriceRuleModel);
        this.newPriceRule = true;
        this.setupActionbarSecurity();
    }

    private setupPriceRulesAsCopied(priceRule: PriceRuleModel, parentPriceRuleId: string, selectedId: string, newSelectedId: string): string {
        let newId = this.stringHelperService.NewGuid();

        if (priceRule.id == selectedId) {
            newSelectedId = newId;
        }

        priceRule.id = newId;
        priceRule.parentPriceRuleId = parentPriceRuleId;
        priceRule.priceRuleCode = "";
        priceRule.priceRuleName = priceRule.priceRuleName + ' - Copy';
        this.resetLanguageTranslationSet(priceRule);
        this.resetPriceRuleProductOptions(priceRule);
        this.resetPriceRulePrices(priceRule);

        if (priceRule.children?.length) {
            for (let child of priceRule.children) {
                newSelectedId = this.setupPriceRulesAsCopied(child, priceRule.id, selectedId, newSelectedId);
            }
        }
        return newSelectedId;
    }

    private resetPriceRulePrices(priceRule: PriceRuleModel) {
        if (!priceRule?.priceRulePrices) {
            return;
        }
        for (let priceRulePrice of priceRule.priceRulePrices) {
            priceRulePrice.priceRulePriceId = this.stringHelperService.NewGuid();
            priceRulePrice.priceRuleId = priceRule.id;
        }
    }

    private resetLanguageTranslationSet(priceRule: PriceRuleModel) {
        if (!priceRule?.languageTranslationSet) {
            return;
        }
        delete priceRule.languageTranslationSet;
    }

    private resetPriceRuleProductOptions(priceRule: PriceRuleModel) {
        if (!priceRule.productOptions?.length) {
            return;
        }
        for (let productOption of priceRule.productOptions) {
            productOption.priceRuleProductOptionId = this.stringHelperService.NewGuid();
            productOption.priceRuleId = priceRule.id;
        }
    }

    onNewNodeNameChange(priceRuleName: string) {
        this.selectedPriceRuleModel.priceRuleName = priceRuleName;
        this.priceRuleView$.value.priceRuleName = priceRuleName;
    }

    disableAttributesAndRules(priceRuleView: PriceRuleView) {
        priceRuleView.productEligibleData.forEach((r) => {
            r.isInherit = true;
            r.isSaved = true;
        });
        priceRuleView.pointOfSalesData.forEach((r) => {
            r.isInherit = true;
            r.isSaved = true;
        });
        priceRuleView.serviceCategoriesSalesBucketsData.forEach(
            (r) => (r.isInherit = true)
        );
        priceRuleView.parentServiceCategoriesSalesBucketsData.forEach(
            (r) => (r.isInherit = true)
        );
        priceRuleView.roundtripDefinitionData.forEach(
            (r) => (r.isInherit = true)
        );
        priceRuleView.attributeData.forEach((r) => (r.isInherit = true));
        priceRuleView.customerData.forEach((r) => (r.isInherit = true));
        priceRuleView.productAttributeData.forEach((r) => (r.isInherit = true));
        priceRuleView.transitStopoverData.forEach((r) => (r.isInherit = true));
        priceRuleView.formOfPaymentData.forEach((r) => (r.isInherit = true));
    }

    onPriceRuleViewChange(event: PriceRuleView) {
        this.priceRuleView$.next(Object.assign({}, event));
    }

    onPriceRuleNameChange(priceRuleName: string) {
        if (this.selectedPriceRuleModel) {
            this.selectedPriceRuleModel.priceRuleName = priceRuleName;
        }
        this.setTranslationName(this.priceRuleView$.value);
    }

    public onPriceRuleDisplayNameChange() {
        this.setTranslationName(this.priceRuleView$.value);
    }

    onStatusCodeChange(statusCode: string) {
        this.statusCode$.next(statusCode);
    }

    private fetchData() {
        let services = [];
        services = services.concat(
            this.getEachProductTypeGroupByProductGroupCode()
        );
        services = services.concat(
            this.getDomainAttributesByAttributePriceRuleGroupCodes()
        );
        services = services.concat(
            this.priceRuleCategoryReferenceService.getPriceRuleCategoryReferences()
        );
        services = services.concat(this.statusReferenceService.getAll());
        services = this.getOrCreateNewPriceRule(services);

        forkJoin(services).subscribe((res) => {
            this.extractProductTypeGroups(res[0] as ProductTypeGroupModel[][]);
            this.extractDomainAttributes(res[1] as DomainAttributeModel[][]);
            this.extractPriceRuleCategoryReferences(
                res[2] as PriceRuleCategoryReferenceModel[]
            );
            this.extractStatusReferences(res[3] as StatusReferenceModel[]);
            this.populateMainPriceRule(res[4] as PriceRuleModel, this.params.id);
            this.loadingSpinnerService.hide();
        });
    }

    private populateMainPriceRule(mainPriceRule: PriceRuleModel, selectedPriceRuleId: string) {
        if (mainPriceRule) {
            this.mainPriceRuleModel = cloneDeep(mainPriceRule);
            this.selectedPriceRuleModel = this.findSelectedPriceRule(selectedPriceRuleId, mainPriceRule);
            this.renderSelectedPriceRule(this.selectedPriceRuleModel);
            this.hiddenTree = false;
            this.addAction();
            this.pageChanging = false;
            this.onLoadComplete.emit();
        }
    }

    private renderSelectedPriceRule(selectedPriceRule: PriceRuleModel) {
        let priceRuleView = this.pricingConverter.toView(
            selectedPriceRule,
            this.domainAttributes,
            this.productTypeGroups
        );
        this.fillLanguageTranslationSetToTranslationNameComponent(priceRuleView);
        this.populateAttributesFromParentPriceRules(selectedPriceRule.parentPriceRuleId, this.mainPriceRuleModel, priceRuleView);
        this.priceRuleView$.next(priceRuleView);
        setTimeout(() => {
            this.fillModelToText(selectedPriceRule.medias);
            this.fillModelToMedia(selectedPriceRule.medias);
            this.fillModelToTranslationText(selectedPriceRule.medias);
            this.fillModelToTranslationMedia(selectedPriceRule.medias);
        })
    }

    private populateAttributesFromParentPriceRules(parentPriceRuleId: string, priceRuleModel: PriceRuleModel, priceRuleView: PriceRuleView) {
        while (parentPriceRuleId) {
            let parentPriceRuleModel = this.findSelectedPriceRule(parentPriceRuleId, priceRuleModel);
            let parentPriceRuleView = this.pricingConverter.toView(
                parentPriceRuleModel,
                this.domainAttributes,
                this.productTypeGroups
            );
            this.disableAttributesAndRules(parentPriceRuleView);
            this.assembleParentChildAttributesAndRules(
                priceRuleView,
                parentPriceRuleView
            );
            this.getPriceRuleProductOptionInherited(
                priceRuleView,
                parentPriceRuleView
            );
            parentPriceRuleId = parentPriceRuleModel.parentPriceRuleId;
        }
    }

    private findSelectedPriceRule(priceRuleId: string, priceRuleModel: PriceRuleModel): PriceRuleModel {
        let found: PriceRuleModel = null;
        if (priceRuleModel.id == priceRuleId) {
            return priceRuleModel;
        }
        if (priceRuleModel.children?.length) {
            for (let child of priceRuleModel.children) {
                found = this.findSelectedPriceRule(priceRuleId, child);
                if (found) {
                    return found;
                }
            }
        }
        return found;
    }

    private findSelectedCommand(priceRuleId: string, command: PriceRuleCommand): PriceRuleCommand {
        let found: PriceRuleCommand = null;
        if (command.id == priceRuleId) {
            return command;
        }
        if (command.children?.length) {
            for (let child of command.children) {
                found = this.findSelectedCommand(priceRuleId, child);
                if (found) {
                    return found;
                }
            }
        }
        return found;
    }

    private extractStatusReferences(res: StatusReferenceModel[]) {
        this.statusData$.next(
            this.pricingConverter.toStatusReferenceSelect2DataList(res)
        );
    }

    private extractPriceRuleCategoryReferences(
        res: PriceRuleCategoryReferenceModel[]
    ) {
        this.priceRuleCategoryReferences$.next(
            this.pricingConverter.toPriceRuleCategoryReferenceSelect2DataList(
                res
            )
        );
    }

    private extractDomainAttributes(res: DomainAttributeModel[][]) {
        this.domainAttributes = this.collectionHelperService.flatten(res);
    }

    private extractProductTypeGroups(res: ProductTypeGroupModel[][]) {
        this.productTypeGroups = this.extractProductTypeReferences(res);
    }

    private getOrCreateNewPriceRule(services: any[]) {
        if (this.params?.id) {
            services = services.concat(this.pricingService.getAllPriceRulesInHierarchy(this.params?.id));
        } else {
            let priceRuleView = new PriceRuleView();
            priceRuleView.statusCode = "A";
            priceRuleView.priceRuleCategoryCode = PricingDetailConstant.DEFAULT_PRICE_RULE_GROUP_CODE;
            priceRuleView.priceRuleGroupCode = PricingDetailConstant.DEFAULT_PRICE_RULE_GROUP_CODE;
            priceRuleView.usageTypeCode = PricingDetailConstant.DEFAULT_USAGE_TYPE_CODE;
            this.priceRuleView$.next(priceRuleView);
            this.activityStore();
        }
        return services;
    }

    private getDomainAttributesByAttributePriceRuleGroupCodes(): Observable<
        DomainAttributeModel[][]
    > {
        return forkJoin(
            AttributePriceRuleGroupCode.map((priceRuleAttributeGroupCode) =>
                this.domainAttributeService.getPriceRuleAttributes(
                    "",
                    priceRuleAttributeGroupCode,
                    ""
                )
            )
        );
    }

    private getEachProductTypeGroupByProductGroupCode(): any {
        return forkJoin(
            PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED.map(
                (item) =>
                    this.productTypeGroupService.getProductTypeGroupByProductGroupCode(
                        item.productGroupCode
                    )
            )
        );
    }

    private extractProductTypeReferences(responses: ProductTypeGroupModel[][]) {
        let productTypeReferences = [];
        for (let item of PricingDetailConstant.PRICE_RULE_ATTRIBUTE_TYPE_CODES_PRODUCT_INCLUDED) {
            for (let res of responses) {
                let productTypeReference = res.find(
                    (r) =>
                        r.productGroupCode == item.productGroupCode &&
                        r.productTypeCode == item.productTypeCode
                );
                if (productTypeReference) {
                    productTypeReferences.push(productTypeReference);
                    break;
                }
            }
        }
        return productTypeReferences;
    }

    onActiveIdChange(activeId: string) {
        if (activeId == this.generalTabId) {
            this.focusingService.focus(
                this.generalComponent.focusingDirective
            );
        } else if (activeId == this.attributesTabId) {
            this.focusingService.focus(
                this.pricingDetailAttributeAndRuleComponent.focusingDirective
            );
        } else if (activeId == this.textTabId) {
            this.focusingService.focus(this.textMedia.textComponent.focusingDirective);
        } else if (activeId == this.mediaTabId) {
            this.focusingService.focus(this.textMedia.mediaComponent.focusingDirective);
        } else if (activeId == this.translationTabId) {
            this.focusingService.focus(
                this.translationNameComponent.focusingDirective
            );
        }
        this.showTranslation = activeId == this.translationTabId;
    }

    public onSelectedPriceRuleChange(priceRule: PriceRuleModel) {
        let model = this.gatherValues();
        if (!model) {
            return;
        }
        if (this.selectedPriceRuleModel.id == model.id) {
            this.mainPriceRuleModel = model;
        }
        this.selectedPriceRuleModel = priceRule;
        this.addAction();
        this.renderSelectedPriceRule(priceRule);

    }

    private getEditedMainPriceRule(main: PriceRuleModel, editedPriceRule: PriceRuleModel): PriceRuleModel {
        let oldPriceRule = main;
        if (oldPriceRule?.id == editedPriceRule?.id) {
            return editedPriceRule;
        }
        if (oldPriceRule.children?.length) {
            for (let i = 0; i < oldPriceRule.children.length; i++) {
                oldPriceRule.children[i] = this.getEditedMainPriceRule(oldPriceRule.children[i], editedPriceRule);
            }
        }
        return Object.assign({}, oldPriceRule);
    }

    private gatherValues(): PriceRuleModel {
        let model = this.generalComponent.getValues();
        if (!model) {
            return null;
        }
        model = this.pricingDetailAttributeAndRuleComponent.getValues(model);
        if (!model) {
            return null;
        }
        model = this.inclusiveOfProductsComponent.getValues(model);
        if (!model) {
            return null;
        }
        model = this.baggageAllowanceComponent.getValues(model);
        if (!model) {
            return null;
        }
        model = this.priceMappingComponent.getValues(model);
        if (!model) {
            return null;
        }


        this.fillLanguageTranslationSetToModel(model);
        this.fillMediaModels(model.id, model);

        return this.getEditedMainPriceRule(this.mainPriceRuleModel, model);
    }

    private fillMediaModels(priceRuleId: string, model: PriceRuleModel) {
        if (model.id == priceRuleId) {
            let mediaCommands = this.textMapperService.textToMediaModels(this.textMedia.textComponent.mediaTexts, this.ENTITY_NAME, model.id);
            mediaCommands = this.mediaMapperService.mediaToMediaModels(mediaCommands, this.textMedia.mediaComponent.mediaFiles, this.ENTITY_NAME, model.id)
            mediaCommands = this.translationTextMapperService.textTranslationToMediaModels(this.translationTextComponent.mediaTranslationChildTexts, mediaCommands);
            mediaCommands = this.translationMediaMapperService.mediaTranslationToMediaModels(this.translationMediaComponent.mediaTranslationChildFiles, mediaCommands);

            let medias = this.pricingMediaConverter.commandToMediaViewModels(mediaCommands, this.ENTITY_NAME, model.id);
            model.medias = medias;
        } else {
            let selectedPriceRule = this.findSelectedPriceRule(model.id, this.mainPriceRuleModel);
            model.medias = cloneDeep(selectedPriceRule.medias);
        }
        if (model.children?.length) {
            for (let child of model.children) {
                this.fillMediaModels(priceRuleId, child);
            }
        }
    }

    private getLanguageReference() {
        this.languageReferenceService.getByOrganisation()
            .subscribe(
                (responses: LanguageReferenceModel[]) => {
                    this.languageReference$.next(responses);
                }
            )
    }

    private getMediaTypeReference() {
        this.mediaTypeReferenceService.getAll()
            .subscribe(
                (responses: MediaTypeReferenceModel[]) => {
                    this.mediaTypeReference$.next(responses);
                }
            )
    }

    private getMediaUseReference() {
        this.mediaUseReferenceService.getAll()
            .subscribe(
                (responses: MediaUseReferenceModel[]) => {
                    this.mediaUseReference$.next(responses);
                }
            )
    }

    private getMediaHashTag() {
        this.mediaHashTagService.getAll()
            .subscribe(
                (responses: MediaHashTagModel[]) => {
                    this.mediaHashTagSelect2Data = this.mediaMapperService.mediaHashTagToSelect2Data(responses);
                }
            )
    }

    private getMediaTypeFileType() {
        this.mediaTypeFileTypeService.getAll()
            .subscribe(
                (responses: MediaTypeFileTypeModel[]) => {
                    this.mediaTypeFileType$.next(responses);
                }
            )
    }

    private fillModelToText(medias: MediaViewModel[]) {
        var mediaTextViews = this.textMapperService.mediaToTextViews(medias);
        this.textMedia.textComponent.fillModelToForm(mediaTextViews);
    }

    private fillModelToMedia(medias: MediaViewModel[]) {
        var mediaViews = this.mediaMapperService.mediaToTextViews(medias);
        this.textMedia.mediaComponent.fillModelToForm(mediaViews);
    }

    private addAction() {
        if (this.params.id) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.selectedPriceRuleModel?.id, this.selectedPriceRuleModel?.priceRuleName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    public onPageChange(priceRuleId: string) {
        if (!this.selectedPriceRuleModel?.id) {
            return;
        }
        if (this.selectedPriceRuleModel.id == priceRuleId) {
            return;
        }
        if (this.pagingDataViews.some(p => p.id == this.selectedPriceRuleModel.id) == false) {
            return;
        }
        this.pageChanging = true;
        this.loadingSpinnerService.show();
        this.pricingService.getAllPriceRulesInHierarchy(priceRuleId)
            .pipe(finalize(() => this.loadingSpinnerService.hide()))
            .subscribe(
                res => {
                    this.populateMainPriceRule(res, priceRuleId);
                }
            )
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null);
    }

    private fillModelToTranslationText(medias: MediaViewModel[]) {
        var mediaTranslationTextViews = this.translationTextMapperService.mediaToTranslationTextViews(medias);
        var mediaTranslationTextChildViews = this.translationTextMapperService.mediaToTranslationTextChildViews(medias, this.languageReference$.value);
        this.translationTextComponent.fillModelToForm(mediaTranslationTextViews, mediaTranslationTextChildViews);
    }
    private fillModelToTranslationMedia(medias: MediaViewModel[]) {
        var mediaTranslationMediaViews = this.translationMediaMapperService.mediaToTranslationFileViews(medias);
        var mediaTranslationMediaChildViews = this.translationMediaMapperService.mediaToTranslationFileChildViews(medias, this.languageReference$.value);
        this.translationMediaComponent.fillModelToForm(mediaTranslationMediaViews, mediaTranslationMediaChildViews);
    }
}

import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { OrderDetailIndividualPhoneView } from "../..";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { PhoneListActionBarComponent } from "./phone-list-action-bar/phone-list-action-bar.component";
import { cloneDeep } from 'lodash';
import { GlobalStateService } from "src/app/shared/ui/uiservice/global-state.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OrderChangeIndividualResponseView } from "../../views/order-change-individual-response.view";
import { IndividualChangeMapperService } from "../../individual-change-mapper.service";
import { OrderIndividualChangeService } from "src/app/core/services/order-services/order-individual-change.service";
import { IndividualChangeResponseModel } from "src/app/core/models/order-model";

@Component({
    selector: 'op-order-detail-phone-list',
    templateUrl: './order-detail-phone-list.component.html'
})
export class OrderDetailPhoneListComponent implements OnInit, OnDestroy, AfterViewChecked {
    private readonly newButtonId = this.stringHelperService.NewGuid();
    private readonly newPanelId = this.stringHelperService.NewGuid();
    public readonly SPINNER_NAME: string = "orderDetailPhones";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;
    @ViewChildren(PhoneListActionBarComponent) actionBars: QueryList<PhoneListActionBarComponent>;
    
    @Input("id") id: string;
    @Input("data") phoneData: OrderDetailIndividualPhoneView[];
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapseWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("changeSessionId") changeSessionId: string;
    @Input("headerOrderId") headerOrderId: string;
    @Input("disableActionButton") disableActionButton: boolean = false;

    @Output() dataChange = new EventEmitter<OrderDetailIndividualPhoneView[]>();
    @Output() completeChange = new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() failedChange = new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() startChange = new EventEmitter();
    
    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderLock: boolean;
    public newOrderDetailPhoneView: OrderDetailIndividualPhoneView = null;
    public editOrderDetailPhoneViews: OrderDetailIndividualPhoneView[] = [];

    private destroy$ = new Subject();

    get orderDetailPhoneViews(): OrderDetailIndividualPhoneView[] {
        return this.phoneData;
    }
    
    get countNumberOfItems(): number {
        return this.orderDetailPhoneViews?.length ?? 0;
    }

    get newButtonDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.newButtonId);
    }

    get newPanelDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.newPanelId);
    }
    

    constructor(private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService,
        private individualChangeMapperService: IndividualChangeMapperService,
        private individualChangeService: OrderIndividualChangeService) {
            
        this.globalStateService.register(this.newButtonId);
        this.globalStateService.register(this.newPanelId);
        this.newPanelDisabled$
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                disabled => {
                    if (disabled) {
                        this.closeNewPanel();
                    }
                }
            )
    }

    ngOnInit(): void {
        this.checkCollapse();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewChecked(): void {
        this.toggleStatePrimaryButtons();
        this.toggleDisableActionButton();
    }
    

    private toggleStatePrimaryButtons() {
        if (!this.orderDetailPhoneViews?.length || !this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => {
            const primaryFlag = this.orderDetailPhoneViews[index].primaryFlag;
            actionBar.togglePrimaryButton(!primaryFlag);
        })
    }

    private toggleDisableActionButton(): void {
        if (!this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => {
            actionBar.disableActionButton(this.disableActionButton);
        })
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    private checkCollapse(): void {
        if (!this.orderDetailPhoneViews || this.orderDetailPhoneViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public onPrimaryClicked(view: OrderDetailIndividualPhoneView, index: number) {
        this.setPrimaryFlag(view, this.orderDetailPhoneViews);
        this.onApplyEditClick(view, index);
    }

    private setPrimaryFlag(primaryView: OrderDetailIndividualPhoneView, views: OrderDetailIndividualPhoneView[]) {

        views.map(x => x.primaryFlag = false);
        let view = views.find(x => x.communicationPhoneId === primaryView.communicationPhoneId);
        view.primaryFlag = true;
    }

    onDeleteClicked(phone: OrderDetailIndividualPhoneView) {

        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationPhones([phone],
            this.id
        );

        this.startChange.emit();
        this.individualChangeService.orderCancelCommunicationPhone(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {
                    if (response) {
                        this.setFirstPhoneAsPrimary();
                        this.dataChange.emit(this.orderDetailPhoneViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }
    private setFirstPhoneAsPrimary() {
        const primaryPhone = this.orderDetailPhoneViews?.find(view => view?.primaryFlag);
        if (!primaryPhone && this.orderDetailPhoneViews?.length) {
            this.orderDetailPhoneViews[0].primaryFlag = true;
        }
    }

    onEditClicked(phone: OrderDetailIndividualPhoneView) {
        this.openEditPanel(phone);
    }

    private openEditPanel(phone: OrderDetailIndividualPhoneView) {
        const index = this.orderDetailPhoneViews.indexOf(phone);
        if (index < 0) return;
        this.editOrderDetailPhoneViews[index] = phone;
        this.globalStateService.disableAll();
    }

    onApplyNewClick(view: OrderDetailIndividualPhoneView) {

        let phoneViews = this.createAddNewPhoneToViews(view);
        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationPhones(phoneViews,
            this.id);

        this.startChange.emit();
        this.individualChangeService.orderChangeCommunicationPhone(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {

                        this.closeNewPanel();
                        this.dataChange.emit(this.orderDetailPhoneViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }

    onCloseNewClick() {
        this.closeNewPanel();
        this.globalStateService.enableAll();
    }

    onNewClick() {
        this.openNewPanel();
        this.globalStateService.disableAllExcept(this.newPanelId);
    }

    onApplyEditClick(phone: OrderDetailIndividualPhoneView, index: number) {
        
        this.orderDetailPhoneViews[index] = phone;
        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationPhones(this.orderDetailPhoneViews,
            this.id
        );
        this.startChange.emit();
        this.individualChangeService.orderChangeCommunicationPhone(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {
                        this.closeEditPanel(index);
                        this.dataChange.emit(this.orderDetailPhoneViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }

    private closeEditPanel(index: number) {
        this.editOrderDetailPhoneViews[index] = null;
        this.globalStateService.enableAll();
    }

    onCloseEditClick(index: number) {
        this.closeEditPanel(index);
    }

    private closeNewPanel() {
        this.newOrderDetailPhoneView = null;
    }

    private openNewPanel() {
        this.newOrderDetailPhoneView = this.getEmptyView();
    }

    private getEmptyView(): OrderDetailIndividualPhoneView {
        return {
            orderId: null,
            communicationPhoneId: null,
            communicationPhoneTypeCode: null,
            communicationPhoneTypeName: null,
            countryPhonePrefix: null,
            phoneNumber: null,
            primaryFlag: false,
            orderSalesStatusCode: null
        }
    }

    private createAddNewPhoneToViews(view: OrderDetailIndividualPhoneView): OrderDetailIndividualPhoneView[] {
        
        this.phoneData = this.orderDetailPhoneViews || [];
        let phonesToSave = new Array<OrderDetailIndividualPhoneView>();
        if (this.orderDetailPhoneViews?.length) {
            this.orderDetailPhoneViews.map(phone => phonesToSave.push(cloneDeep(phone)));
        }
        phonesToSave.push(cloneDeep(view));
        return phonesToSave;
    }

    public onUndoClick(index: number) {

        let orderPhone = this.orderDetailPhoneViews[index];
        this.individualChangeService.orderUndoIndividualChange(this.headerOrderId, orderPhone.orderId, this.changeSessionId)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {
                        this.closeEditPanel(index);
                        this.dataChange.emit(this.orderDetailPhoneViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }
}
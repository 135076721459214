import { Injectable } from '@angular/core';

import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { ProductValidityCommandModel } from 'src/app/core/models/product-model/product-base-model/product-validity';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';

@Injectable()
export class ValidityMapperService {

    constructor(private dateConverterService: DateConverterService) {
    }

    public productValidityFormToModels(forms): ProductValidityCommandModel[] {
        let models: ProductValidityCommandModel[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == "VALID") {
                    ++i;
                    models.push(this.productValidityFormToModel(i, ctl.value));
                }
            }
        }
        return models;
    }

    private productValidityFormToModel(index: number, value): ProductValidityCommandModel {
        let model = new ProductValidityCommandModel();
        if (value.productValidityId) {
            model.productValidityId = value.productValidityId;
        }
        model.calendarValidityCode = value.dateType;
        model.conditionCode = value.calendarOperator;
        model.dateTimeDimensionCode = value.calendarType;

        switch (model.dateTimeDimensionCode.toLowerCase()) {
            case 'date': {
                this.date(model, value);
                break;
            }
            case 'datetime': {
                this.datetime(model, value);
                break;
            }
            case 'time': {
                this.time(model, value);
                break;
            }
            case 'weekday': {
                this.weekDay(model, value);
                break;
            }
        }
        model.displaySequence = index;
        return model;
    }

    private matchWeekDay(day: String, valueArray: String[]): boolean {
        if (!valueArray) {
            return false;
        }
        var filter = valueArray.filter(x => x.toLowerCase() == day.toLowerCase());
        if (filter.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    public productValiditiesToForm(productValidities: ProductValidityViewModel[], inherit: boolean): ProductValidityViewModel[] {
        var filter = productValidities.filter(x => x.inherit == inherit);
        return filter;
    }

    private weekDay(model: ProductValidityCommandModel, value: any) {
        model.utcFlag = false;
        this.matchWeekDayToModel(model, value);
    }

    private time(model: ProductValidityCommandModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = this.dateConverterService.convertMinDateWithTime(value.startTime);
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = this.dateConverterService.convertMinDateWithTime(value.endTime);
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToModel(model, value);
        }
    }

    private datetime(model: ProductValidityCommandModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = value.startDate;
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = value.endDate;
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToModel(model, value);
        }
    }

    private date(model: ProductValidityCommandModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = this.dateConverterService.toDateFormat(value.startDate);
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = this.dateConverterService.toDateFormat(value.endDate);
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToModel(model, value);
        }
    }

    private matchWeekDayToModel(model: ProductValidityCommandModel, value: any) {
        model.mondayFlag = this.matchWeekDay("monday", value.weekDays);
        model.tuesdayFlag = this.matchWeekDay("tuesday", value.weekDays);
        model.wednesdayFlag = this.matchWeekDay("wednesday", value.weekDays);
        model.thursdayFlag = this.matchWeekDay("thursday", value.weekDays);
        model.fridayFlag = this.matchWeekDay("friday", value.weekDays);
        model.saturdayFlag = this.matchWeekDay("saturday", value.weekDays);
        model.sundayFlag = this.matchWeekDay("sunday", value.weekDays);
    }   

    public toCommandModels(views: ProductValidityViewModel[]): ProductValidityCommandModel[] {
        let models = new Array<ProductValidityCommandModel>();
        for (let view of views) {
            models.push(this.toCommandModel(view));
        }
        return models;
    }

    public toCommandModel(view: ProductValidityViewModel): ProductValidityCommandModel {
        let model = new ProductValidityCommandModel();
        model.productValidityId = view.productValidityId;
        model.calendarValidityCode = view.calendarValidityCode;
        model.conditionCode = view.conditionCode;
        model.dateTimeDimensionCode = view.dateTimeDimensionCode;
        model.startDateTime = view.startDateTime.toString();
        model.endDateTime = view.endDateTime.toString();
        model.mondayFlag = view.mondayFlag;
        model.tuesdayFlag = view.tuesdayFlag;
        model.wednesdayFlag = view.wednesdayFlag;
        model.thursdayFlag = view.thursdayFlag;
        model.fridayFlag = view.fridayFlag;
        model.saturdayFlag = view.saturdayFlag;
        model.sundayFlag = view.sundayFlag;
        model.januaryFlag = view.januaryFlag;
        model.februaryFlag = view.februaryFlag;
        model.marchFlag = view.marchFlag;
        model.aprilFlag = view.aprilFlag;
        model.mayFlag = view.mayFlag;
        model.juneFlag = view.juneFlag;
        model.julyFlag = view.julyFlag;
        model.augustFlag = view.augustFlag;
        model.septemberFlag = view.septemberFlag;
        model.octoberFlag = view.octoberFlag;
        model.novemberFlag = view.novemberFlag;
        model.decemberFlag = view.decemberFlag;
        model.utcFlag = view.utcFlag;
        model.displaySequence = view.displaySequence;
        return model;
    }

    public productValidityFormToViewModels(forms): ProductValidityViewModel[] {
        let models: ProductValidityViewModel[] = new Array();
        if (forms) {
            let i = 0;
            for (let ctl of forms.controls) {
                if (ctl.status == "VALID") {
                    ++i;
                    models.push(this.productValidityFormToViewModel(i, ctl.value));
                }
            }
        }
        return models;
    }

    private productValidityFormToViewModel(index: number, value): ProductValidityViewModel {
        let model = new ProductValidityViewModel();
        if (value.productValidityId) {
            model.productValidityId = value.productValidityId;
        }
        model.calendarValidityCode = value.dateType;
        model.conditionCode = value.calendarOperator;
        model.dateTimeDimensionCode = value.calendarType;
        model.inherit = false;
        switch (model.dateTimeDimensionCode.toLowerCase()) {
            case 'date': {
                this.dateView(model, value);
                break;
            }
            case 'datetime': {
                this.datetimeView(model, value);
                break;
            }
            case 'time': {
                this.timeView(model, value);
                break;
            }
            case 'weekday': {
                this.weekDayView(model, value);
                break;
            }
        }
        model.displaySequence = index;
        return model;
    }

    private weekDayView(model: ProductValidityViewModel, value: any) {
        model.utcFlag = false;
        this.matchWeekDayToViewModel(model, value);
    }

    private timeView(model: ProductValidityViewModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = this.dateConverterService.convertMinDateWithTime(value.startTime);
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = this.dateConverterService.convertMinDateWithTime(value.endTime);
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToViewModel(model, value);
        }
    }

    private datetimeView(model: ProductValidityViewModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = value.startDate;
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = value.endDate;
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToViewModel(model, value);
        }
    }

    private dateView(model: ProductValidityViewModel, value: any) {
        model.utcFlag = (value.timeZone == "utc") ? true : false;
        model.startDateTime = value.startDate;
        if (model.conditionCode == "<>" || model.conditionCode == "><") {
            model.endDateTime = value.endDate;
        }
        if (model.conditionCode == "<>" || model.conditionCode == "><" ||
            model.conditionCode == "=>" || model.conditionCode == "<=") {
            this.matchWeekDayToViewModel(model, value);
        }
    }

    private matchWeekDayToViewModel(model: ProductValidityViewModel, value: any) {
        model.mondayFlag = this.matchWeekDay("monday", value.weekDays);
        model.tuesdayFlag = this.matchWeekDay("tuesday", value.weekDays);
        model.wednesdayFlag = this.matchWeekDay("wednesday", value.weekDays);
        model.thursdayFlag = this.matchWeekDay("thursday", value.weekDays);
        model.fridayFlag = this.matchWeekDay("friday", value.weekDays);
        model.saturdayFlag = this.matchWeekDay("saturday", value.weekDays);
        model.sundayFlag = this.matchWeekDay("sunday", value.weekDays);
    }   
}
import { Component, ChangeDetectionStrategy, Input, SimpleChange, OnChanges, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
    select2ServiceCat, select2ServiceCatInherit, select2ServiceCatOption, select2SaleBucketDisplayChoice,
    select2SalesBucketOption
} from './inventory-rule-configuration';

import { DomainAttributeService } from 'src/app/core/services/airline-services';
import { InventoryRuleMapperService } from './inventory-rule-mapper.service';

import { DomainAttributeModel, ServiceCategoryReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { cloneDeep } from "lodash";
import { ServiceCatetgoryReferenceService } from 'src/app/core/services/system-services';

declare var $: any

@Component({
    selector: 'op-inventory-rule',
    templateUrl: './inventory-rule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [InventoryRuleMapperService]
})
export class InventoryRuleComponent implements OnChanges {

    @Input() productTypeCode: string;
    @Input() id: string;
    @Input() newProduct: boolean = false;

    @Output() srvCategoryValid = new EventEmitter<boolean>();
    @Output() salesBucketValid = new EventEmitter<boolean>();
    private readonly ATTRBUTE_GROUP_CODE: string = "INVENTORY";
    private readonly SERVCAT_CODE: string = "SERVICECATEGORY";
    private readonly SALESBUCKET_CODE: string = "SALESBUCKET";
    public readonly ERROR_SRVCATE_REQUIRED = 'Service category is required.';
    public readonly ERROR_SALESBUCKET_REQUIRED = 'Sales bucket is required.';

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("serviceCatSelect2") serviceCatSelect2: ElementRef;
    public attributeServiceCatOption: any = cloneDeep(select2ServiceCat);
    public serviceCatOption: any = cloneDeep(select2ServiceCatOption);
    public attributeServiceCatInheritOption: any = cloneDeep(select2ServiceCatInherit);
    public saleBucketChoiceDisplayOption: any = cloneDeep(select2SaleBucketDisplayChoice);
    public saleBucketOption: any = cloneDeep(select2SalesBucketOption);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public serviceCategoryAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public salesBucketAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public serviceCategoryChoice: Select2Data[];
    public serviceCategoryChoiceInherit: Select2Data[];
    public salesBucketChoice: Select2Data[];
    public salesBucketChoiceInherit: Select2Data[];
    public productAttributes: ProductAttributeViewModel[];
    public productAttributesInherit: ProductAttributeViewModel[];
    public serviceCatValue: string[] = null;
    public serviceCatValueInherit: string[] = null;
    public serviceCatIdList: Select2Data[];
    public srvCatValidate: boolean = true;
    public hideInherit: boolean = true;
    public draftFlag: boolean = true;
    public saleBucketDisplayValue: string = "selective";
    public salesBucketValue: string[] = null;
    public salesBucketValueInherit: string[] = null;
    public salesBucketIdList: Select2Data[];
    public focusing = false;
    public serviceCatValuePrevious: string[] = null;
    public finalFlag: boolean = false;
    private servieCategoryOrder: string[] = new Array();
    public salesBucketValidate: boolean = true;
    private serviceCategoryReferences: ServiceCategoryReferenceModel[];

    constructor(
        private domainAttributeService: DomainAttributeService,
        private serviceCategoryReferenceService: ServiceCatetgoryReferenceService,
        private inventoryRuleMapperService: InventoryRuleMapperService,
        private changeDetectionRef: ChangeDetectorRef) {
        this.attributeServiceCatOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeServiceCatOption.templateSelection = this.templateResultAttribute.bind(this);
        this.attributeServiceCatInheritOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeServiceCatInheritOption.templateSelection = this.templateResultAttribute.bind(this);
        this.serviceCatOption.templateSelection = this.templateSelection.bind(this);
        this.serviceCatOption.templateResult = this.templateServiceCategoryResult.bind(this);
        this.saleBucketOption.templateSelection = this.templateSelectionSalesBucket.bind(this);
        this.saleBucketOption.templateResult = this.templateSalesBucketsResult.bind(this);
        this.getServiceCategoryReferences();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        this.geAttributeByProductType();
    }

    public geAttributeByProductType() {
        if (this.productTypeCode) {
            this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, this.productTypeCode)
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        this.domainAttribute$.next(responses);
                        this.serviceCategoryAttribute$.next(
                            this.inventoryRuleMapperService.getServiceCategoryAttributes(responses, this.SERVCAT_CODE));
                        this.salesBucketAttribute$.next(
                            this.inventoryRuleMapperService.getSalesBucketAttributes(responses, this.SALESBUCKET_CODE));
                        this.serviceCategoryChoice = this.inventoryRuleMapperService.getServiceCategoryChoices(responses, this.SERVCAT_CODE)
                        this.salesBucketChoice = this.inventoryRuleMapperService.getSalesBucketChoices(responses, this.SERVCAT_CODE, this.SALESBUCKET_CODE);
                        this.addAttributeViewToDisplay();
                        this.addAttributeInheritViewToDisplay();
                    }
                )
        }
    }

    private getServiceCategoryReferences() {
        this.serviceCategoryReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.serviceCategoryReferences = response;
                }
            )
    }

    private templateResultAttribute(item): JQuery<HTMLElement> {
        return $(
            '<span>' + item.text + ' <i class="fal fa-cube"></i></span>'
        );
    }

    public addAttributeViewToDisplay() {
        this.clearAllData();
        if (this.newProduct) {
            this.salesBucketDisplayChange(this.saleBucketDisplayValue);
        } else if (this.productAttributes) {
            this.addSrvCategoryView();
            this.addSalesBucketView();
        }
        this.changeDetectionRef.detectChanges();
    }

    public addAttributeInheritViewToDisplay() {
        this.clearAllInheritData();
        if (this.productAttributesInherit) {
            this.addAttributeViewToServiceCategoryInherit();
            this.addAttributeViewToSalesBucketInherit();
        }

        this.changeDetectionRef.detectChanges();
    }

    private addSrvCategoryView() {
        var serviceCates = this.productAttributes.filter(x => x.attributeTypeCode == this.SERVCAT_CODE);
        for (let serviceCate of serviceCates) {
            this.addToValue(serviceCate.attributeChoiceId);
            this.addToIdList(serviceCate.productAttributeId,
                serviceCate.attributeChoiceId);
        }
    }

    private addSalesBucketView() {
        var salesBuckets = this.productAttributes.filter(x => x.attributeTypeCode == this.SALESBUCKET_CODE);
        for (let salesBucket of salesBuckets) {
            this.addToSalesBucketValue(salesBucket.attributeChoiceId);
            this.addToSalesBucketIdList(salesBucket.productAttributeId,
                salesBucket.attributeChoiceId);
        }
        this.saleBucketDisplayValue = this.inventoryRuleMapperService.getSalesBucketDisplay(
            this.domainAttribute$.value,
            this.SERVCAT_CODE,
            this.SALESBUCKET_CODE, this.serviceCatValue, this.salesBucketValue);
    }

    private addAttributeViewToServiceCategoryInherit() {
        let serviceCates = this.productAttributesInherit.filter(x => x.attributeTypeCode == this.SERVCAT_CODE);
        for (let serviceCate of serviceCates) {
            this.addToValueInherit(serviceCate.attributeChoiceId);
            this.addToIdList(serviceCate.productAttributeId,
                serviceCate.attributeChoiceId);
            this.createChoiceInherit(serviceCate.attributeChoiceId);
        }
    }

    private addAttributeViewToSalesBucketInherit() {
        let salesBuckets = this.productAttributesInherit.filter
            (x => x.attributeTypeCode == this.SALESBUCKET_CODE)
            .sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        for (let saleBucket of salesBuckets) {
            this.addToValueSalesBucketInherit(saleBucket.attributeChoiceId);
            this.addToSalesBucketIdList(saleBucket.productAttributeId,
                saleBucket.attributeChoiceId);
            this.createSalesBucketChoiceInherit(saleBucket.attributeChoiceId);
        }
    }

    private clearAllData() {
        this.serviceCatValue = [];
        this.serviceCatIdList = [];
        this.salesBucketValue = [];
        this.salesBucketIdList = [];
    }

    private clearAllInheritData() {
        this.serviceCatValueInherit = [];
        this.serviceCategoryChoiceInherit = [];
        this.salesBucketValueInherit = [];
        this.salesBucketChoiceInherit = [];
    }

    private addToValue(id: string) {
        this.serviceCatValue.push(id);
    }

    private addToValueInherit(id: string) {
        this.serviceCatValueInherit.push(id);
    }

    private addToIdList(dbId: string, id: string) {
        let dbIdData = new Select2Data();
        dbIdData.id = dbId;
        dbIdData.text = id;
        this.serviceCatIdList.push(dbIdData)
    }

    private createChoiceInherit(id: string) {
        let choiceData = new Select2Data();
        choiceData.id = id;
        let filter = this.serviceCategoryChoice.filter(x => x.id == id);
        if (filter?.length) {
            choiceData.text = filter[0].text
            this.serviceCategoryChoiceInherit.push(choiceData);
        }
    }

    public clearForm() {
        this.clearAllData();
        this.clearAllInheritData();
        this.finalFlag = false;
        this.draftFlag = true;
        this.hideInherit = true;
        this.srvCatValidate = true;
        this.salesBucketValidate = true;
        this.saleBucketDisplayValue = "selective";
        this.changeDetectionRef.detectChanges();
    }

    private templateSelection(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockChoice(id) && !this.draftFlag) {
            $(container).addClass('locked-tag');
            tag.locked = true;
        }
        else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        $(container)[0].style.backgroundColor = this.findServiceCategoryBackGroundColor(id);
        return tag.text;
    }

    private lockChoice(id): boolean {
        if (this.serviceCatIdList) {
            var filter = this.serviceCatIdList.filter(x => x.text == id);
            if (filter.length > 0) {
                return true;
            }
        }
        return false;
    }

    public srvCategoryValidate(rootWithProductPoint: boolean, newProduct: boolean, draftFlag: boolean): boolean {
        this.srvCatValidate = true;
        if ((!rootWithProductPoint && !newProduct) && !draftFlag) {
            return true;
        }
        if (!this.serviceCatValue?.length) {
            this.srvCatValidate = false;
        }
        this.changeDetectionRef.detectChanges();
        return this.srvCatValidate;
    }

    public srvCategoryChange(value) {
        this.setServiceCategoryOrder(value);
        this.srvCatValidate = true;
        if (!this.serviceCatValue?.length) {
            this.srvCatValidate = false;
        }
        this.srvCategoryValid.emit(this.srvCatValidate);
        this.salesBucketDisplayChange(this.saleBucketDisplayValue);
    }

    public salesBucketDisplayChange(value: string | string[]) {
        switch (value) {
            case "all": {
                this.getAllSalesBucket();
                break;
            }
            case "selective": {
                this.getSalesBucketFromSrvCatetegory();
                break;
            }
            case "none": {
                this.salesBucketValue = [];
                break;
            }
        }
        this.salesBucketChange();
        this.changeDetectionRef.detectChanges();
    }

    private getAllSalesBucket() {
        this.salesBucketValue = [];
        if (this.salesBucketChoice) {
            for (let choice of this.salesBucketChoice) {
                this.salesBucketValue.push(choice.id);
            }
        }
    }

    private getSalesBucketFromSrvCatetegory() {
        let salesBucket = cloneDeep(this.salesBucketValue);
        this.salesBucketValue = [];
        this.addExistSalesBucket(salesBucket);
        if (this.salesBucketChoice && this.serviceCatValue && this.salesBucketAttribute$.value) {
            if (this.addNewServiceCate()) {
                let salesBucketChoices = this.getSalesBucketFilterSrvCategory();
                for (let choice of salesBucketChoices) {
                    this.salesBucketValue.push(choice.attributeChoiceId);
                }
            }
            else {
                this.removeSalesBucketNotInSrvCate();
            }
            this.serviceCatValuePrevious = cloneDeep(this.serviceCatValue);
        }
    }

    private addNewServiceCate(): boolean {
        if (this.serviceCatValue?.length > this.serviceCatValuePrevious?.length) {
            return true;
        }
        return false;
    }
    private getSalesBucketFilterSrvCategory(): AttributeChoiceModel[] {
        let salsBucketeChoices: AttributeChoiceModel[] = new Array();
        let srvCategoryChoices = this.getSrvCategoryChoices();
        for (let srvCategoryChoice of srvCategoryChoices) {
            var filterSalesBuckets = this.salesBucketAttribute$.value[0].attributeChoices.filter(x => x.serviceCategoryCode == srvCategoryChoice.serviceCategoryCode);
            if (filterSalesBuckets?.length) {
                filterSalesBuckets.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                for (let salesBucketChoice of filterSalesBuckets) {
                    salsBucketeChoices.push(salesBucketChoice);
                }
            }
        }
        return salsBucketeChoices;
    }

    private getSrvCategoryChoices(): AttributeChoiceModel[] {
        let srvcateChoices: AttributeChoiceModel[] = new Array();
        let serviceCateNew = this.getServiceCateNew();
        if (serviceCateNew) {
            for (let choice of serviceCateNew) {
                var attributeChoices = this.serviceCategoryAttribute$.value[0].attributeChoices;
                var filter = attributeChoices.filter(x => x.attributeChoiceId == choice);
                if (filter?.length) {
                    srvcateChoices.push(filter[0]);
                }
            }
            srvcateChoices.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        }
        return srvcateChoices;
    }

    private getServiceCateNew(): string[] {
        if (this.serviceCatValue?.length) {
            let serviceCateNew: string[] = new Array();
            serviceCateNew.push(this.servieCategoryOrder[this.serviceCatValue.length - 1]);
            return serviceCateNew;
        }
        return null;
    }

    private addExistSalesBucket(salesBuckets: string[]) {
        for (let salesBucket of salesBuckets) {
            this.salesBucketValue.push(salesBucket);
        }
    }

    private addToSalesBucketValue(id: string) {
        this.salesBucketValue.push(id);
    }

    private addToSalesBucketIdList(dbId: string, id: string) {
        let dbIdData = new Select2Data();
        dbIdData.id = dbId;
        dbIdData.text = id;
        this.salesBucketIdList.push(dbIdData)
    }

    private addToValueSalesBucketInherit(id: string) {
        this.salesBucketValueInherit.push(id);
    }

    private createSalesBucketChoiceInherit(id: string) {
        let choiceData = new Select2Data();
        choiceData.id = id;
        let filter = this.salesBucketChoice.filter(x => x.id == id);
        if (filter?.length) {
            choiceData.text = filter[0].text
            this.salesBucketChoiceInherit.push(choiceData);
        }
    }

    private templateSelectionSalesBucket(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockSalesBucketChoice(id) && !this.draftFlag) {
            $(container).addClass('locked-tag');
            tag.locked = true;
        }
        else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        $(container)[0].style.backgroundColor = this.findSalesBucketsBackGroundColor(id);
        return tag.text;
    }

    private lockSalesBucketChoice(id): boolean {
        if (this.serviceCatIdList) {
            var filter = this.salesBucketIdList.filter(x => x.text == id);
            if (filter.length > 0) {
                return true;
            }
        }
        return false;
    }

    public displayNone(): boolean {
        return (this.newProduct || this.draftFlag);
    }

    public getErrorMessageForm(rootWithProductPoint: boolean, newProduct: boolean, draftFlag: boolean): string {
        if (!this.srvCategoryValidate(rootWithProductPoint, newProduct, draftFlag)) {
            return this.ERROR_SRVCATE_REQUIRED;
        }
        if (!this.salesBucketValidation(rootWithProductPoint, newProduct, draftFlag)) {
            return this.ERROR_SALESBUCKET_REQUIRED;
        }
        return null;
    }

    private removeSalesBucketNotInSrvCate() {
        let salesBucketsChoices = this.getSalesBucketSrvCategoryExist();
        let salesBucketSelected = cloneDeep(this.salesBucketValue);
        for (let choice of salesBucketSelected) {
            let filter = salesBucketsChoices.filter(x => x.attributeChoiceId == choice);
            if (!filter?.length) {
                this.salesBucketValue.splice(this.salesBucketValue.indexOf(choice), 1);
            }
        }
    }


    private getSalesBucketSrvCategoryExist(): AttributeChoiceModel[] {
        let salsBucketeChoices: AttributeChoiceModel[] = new Array();
        let srvCategoryChoices = this.getServiceCateExist();
        for (let srvCategoryChoice of srvCategoryChoices) {
            var filterSalesBuckets = this.salesBucketAttribute$.value[0].attributeChoices.filter(x => x.serviceCategoryCode == srvCategoryChoice.serviceCategoryCode);
            if (filterSalesBuckets?.length) {
                filterSalesBuckets.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
                for (let salesBucketChoice of filterSalesBuckets) {
                    salsBucketeChoices.push(salesBucketChoice);
                }
            }
        }
        return salsBucketeChoices;
    }

    private getServiceCateExist(): AttributeChoiceModel[] {
        let srvcateChoices: AttributeChoiceModel[] = new Array();
        if (this.serviceCatValue?.length) {
            let srvCatetAttributes = this.serviceCategoryAttribute$.value;
            for (let choice of this.serviceCatValue) {
                var filter = srvCatetAttributes[0].attributeChoices.filter(x => x.attributeChoiceId == choice);
                if (filter.length) {
                    srvcateChoices.push(filter[0]);
                }
            }
        }
        return srvcateChoices;
    }

    get serviceCatValueData(): string | string[] {
        return this.serviceCatValue;
    }

    set serviceCatValueData(value: string | string[]) {
        this.serviceCatValue = <string[]>value;
    }

    get saleBucketDisplayValueData(): string | string[] {
        return this.saleBucketDisplayValue;
    }

    set saleBucketDisplayValueData(value: string | string[]) {
        this.saleBucketDisplayValue = <string>value;
    }

    get salesBucketValueData(): string | string[] {
        return this.salesBucketValue;
    }

    set salesBucketValueData(value: string | string[]) {
        this.salesBucketValue = <string[]>value;
    }

    private setServiceCategoryOrder(value: string[]) {
        if (!value?.length) {
            this.servieCategoryOrder = [];
        } else if (!this.servieCategoryOrder?.length) {
            this.servieCategoryOrder.push(...value);
        } else {
            this.removeServiceCategoryOrder();
            this.addServiceCategoryOrder();
        }
    }

    private removeServiceCategoryOrder() {
        for (let value of this.servieCategoryOrder) {
            let filters = this.serviceCatValue.filter(x => x == value);
            if (!filters?.length) {
                this.servieCategoryOrder.splice(this.servieCategoryOrder.indexOf(value), 1);
            }
        }
    }

    private addServiceCategoryOrder() {
        for (let value of this.serviceCatValue) {
            let filters = this.servieCategoryOrder.filter(x => x == value);
            if (!filters?.length) {
                this.servieCategoryOrder.push(value);
            }
        }
    }

    public salesBucketValidation(rootWithProductPoint: boolean, newProduct: boolean, draftFlag: boolean): boolean {
        this.salesBucketValidate = true;
        if ((!rootWithProductPoint && !newProduct) && !draftFlag) {
            return true;
        }
        if (!this.salesBucketValue?.length) {
            this.salesBucketValidate = false;
        }
        this.changeDetectionRef.detectChanges();
        return this.salesBucketValidate;
    }

    public salesBucketChange() {
        this.salesBucketValidate = true;
        if (!this.salesBucketValue?.length) {
            this.salesBucketValidate = false;
        }
        this.salesBucketValid.emit(this.salesBucketValidate);
    }

    public clearValidate(){
        this.srvCatValidate = true;
        this.salesBucketValidate = true;
    }

    private findServiceCategoryBackGroundColor(id: string) : string {
        if (this.serviceCategoryReferences && this.serviceCategoryAttribute$.value?.length) {
            let serviceCateAttributes = this.serviceCategoryAttribute$.value;
            let serviceCateAttributeChoices = serviceCateAttributes[0].attributeChoices;
            let serviceCateAttributeChoice = serviceCateAttributeChoices.find(x=>x.attributeChoiceId == id);
            if (serviceCateAttributeChoice) {
                let serviceCategory = this.serviceCategoryReferences.find(x=>x.serviceCategoryCode == serviceCateAttributeChoice.serviceCategoryCode);
                if (serviceCategory) {
                    return serviceCategory.serviceCategoryColor;
                }
            }
        }
        return null;
    }

    private findSalesBucketsBackGroundColor(id: string) : string {
        if (this.serviceCategoryReferences && this.salesBucketAttribute$.value?.length) {
            let salesBucketsAttributes = this.salesBucketAttribute$.value;
            let salesBucketsAttributeChoices = salesBucketsAttributes[0].attributeChoices;
            let salesBucketsAttributeChoice = salesBucketsAttributeChoices.find(x=>x.attributeChoiceId == id);
            if (salesBucketsAttributeChoice) {
                let serviceCategory = this.serviceCategoryReferences.find(x=>x.serviceCategoryCode == salesBucketsAttributeChoice.serviceCategoryCode);
                if (serviceCategory) {
                    return serviceCategory.serviceCategoryColor;
                }
            }
        }
        return null;
    }

    private templateServiceCategoryResult(item,  container): JQuery<HTMLElement> {
        $(container)[0].style.backgroundColor = this.findServiceCategoryBackGroundColor(item.id);
        return item.text;
    }

    private templateSalesBucketsResult(item,  container): JQuery<HTMLElement> {
        $(container)[0].style.backgroundColor = this.findSalesBucketsBackGroundColor(item.id);
        return item.text;
    }
}
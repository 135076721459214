export const select2ProductCategory = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2ProductGroup = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2ProductTypeGroup = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2UsageType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1
}

export const select2Supplier = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2Status = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2StatusGeneral = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { PaymentSearch, PaymentSearchLedger } from "../payment-search.model";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { PaymentSearchService } from "..";
import { Subscription } from "rxjs";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { PaymentInvoiceService } from "../payment-invoice.service";

const datepickerOptions = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: true,
    allowClear: true,
    minDate: null,
    maxDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY/MM/DD'
    }
}

@Component({
    selector: 'op-payment-invoice-pay',
    templateUrl: './payment-invoice-pay.component.html'
})
export class PaymentInvoicePayComponent implements OnInit, OnDestroy {

    readonly PaymentDate = 'paymentDate'

    paymentSearch: PaymentSearch
    ledger?: PaymentSearchLedger

    focusing = false
    paymentInvoice: UntypedFormGroup
    datepickerOptions

    subscriptions: Subscription[]

    constructor(private formBuilder: UntypedFormBuilder,
        private paymentSearchService: PaymentSearchService,
        private paymentInvoiceService: PaymentInvoiceService,
        private stringHelperService: StringHelperService,
        private navigateService: NavigationService,
        private dateConverterService: DateConverterService,) {        
    }
    
    ngOnInit(): void {
        this.subscriptions = []
        this.paymentInvoice = this.formBuilder.group(this.formValues())
        this.defaultDatepickerOptions()
        this.subscriptions.push(
            this.paymentInvoiceService.paymentSearch$.subscribe(paymentSearch => this.setPaymentSearch(paymentSearch))
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    valueChange(value: any, ctrlName: string) {
        this.paymentInvoice.get(ctrlName)?.setValue(value == null ? '' : value)
    }

    formValues(): { [key: string]: any; } {
        return {
            paymentDate: null
        }
    }

    submit() {
        this.paymentSearchService.setLoading(true)
        const paymentDate = this.dateConverterService.toDateFormatOrNull(this.paymentInvoice.get(this.PaymentDate).value)
        this.subscriptions.push(
            this.paymentInvoiceService.updatePaymentToPaidStatus(this.paymentSearch.paymentId, paymentDate).subscribe(
                () => {
                    this.paymentSearchService.alertSuccess('Payment is saved')
                    setTimeout(() => {
                        this.paymentSearchService.setLoading(false)
                        this.navigateService.navigate('main/cashbook/payment', null, false)
                    }, 2000)
                },
                () => {
                    this.paymentSearchService.setLoading(false)
                    this.paymentSearchService.alertError('Payment could not be saved')
                }
            )
        )
    }

    validateNullAmountDecimal(amount) {
        return this.stringHelperService.validateNullAmountDecimal(amount)
    }

    private setPaymentSearch(paymentSearch: PaymentSearch) {
        this.paymentSearch = paymentSearch
        this.ledger = this.paymentSearch?.paymentSearchLedgers
        .slice(0)
        .find(l => l != null)
    }

    private defaultDatepickerOptions() {
        this.datepickerOptions = {
            paymentDate: { ...datepickerOptions },
        }
    }

}
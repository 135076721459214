import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { IndividualTitleModel } from "../../models/user-model/individual-title.model";
import { IndividualTitleCommandModel } from "../../models/user-model/individual-title-command.model";

@Injectable({
  providedIn: 'root'
})
export class IndividualTitleReferenceService extends ApiServiceBase {
  private readonly TITLE_PATH = "/organisation/individualTitleReference"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super(httpClient, authService);
  }

  public getIndividualTitle(): Observable<IndividualTitleModel[]> {
    return this.httpGet<any>(this.TITLE_PATH, null)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }

  public getIndividualTitleByCode(searchCommand: IndividualTitleCommandModel): Observable<IndividualTitleModel[]> {
    return this.httpPost<any>(this.TITLE_PATH, searchCommand)
      .pipe(
        map(response => {
          return response.body;
        })
      );
  }
}
import { NavigationItem } from "./store/navigation";

export const NavigationItems: NavigationItem[] = [
  {
    title: 'Home',
    displayName: 'Home',
    tags: 'home',
    icon: 'home.png',
    routerLink: '/main/home',
    singleTab: true,
    openOnLoad: true,
    disableCloseTab: true
  },
  {
    title: 'News',
    displayName: 'News',
    tags: 'news',
    icon: 'news.png',
    routerLink: '/main/news',
    singleTab: true
  },
  {
    id: 'FAVORITES',
    title: 'Favorites',
    displayName: 'My Favorites',
    tags: 'favorite',
    icon: 'favorite.png',
    routerLink: '/main/favorite',
    singleTab: true,
    badge: {
      label: null,
      class: null
    }
  },
  {
    title: 'Sales',
    displayName: 'Sales',
  },
  {
    id: "SHOPPING",
    title: 'Shopping',
    displayName: 'Shopping',
    tags: 'shopping',
    icon: 'offers.png',
    routerLink: '/main/shopping',
    singleTab: false,
    params: {
      addNew: true
    },
    securityCodes: ['SHOPPING']
  },
  {
    id: 'ACTIVITY',
    title: 'Activities',
    displayName: 'Activities',
    tags: 'activity',
    icon: 'activities.png',
    routerLink: '/main/activity',
    params: {
      home: true
    }
  },
  {
    id: 'ORDER',
    title: 'Orders',
    displayName: 'Orders Management',
    tags: 'orders',
    icon: 'orders.png',
    routerLink: '/main/orders',
    params: {},
    singleTab: false,
    securityCodes: ['ORDER', 'ORDERALL', 'ORDERCATALOG']
  },
  {
    title: 'Operations',
    displayName: 'Operations',
    securityCodes: ['TRANSPORT']
  },
  {
    title: 'Today',
    displayName: 'Today',
    tags: 'today',
    icon: 'today.png',
    routerLink: '/main/',
    securityCodes: ['TODAY']
  },
  {
    id: 'TRANSPORT',
    title: 'Transport',
    displayName: 'Operating Transport',
    tags: 'transport',
    icon: 'transport.png',
    routerLink: '/main/transport',
    params: {
      home: true,
      selectedTab: 'search'
    },
    securityCodes: ['TRANSPORT']
  },
  {
    title: 'Fulfillment',
    displayName: 'Fulfillment',
    tags: 'fulfillment',
    icon: 'fulfillment.png',
    routerLink: '/main/',
    securityCodes: ['FULFILLMENT']
  },
  {
    title: 'Management',
    displayName: 'Management',
    securityCodes: ['PRODUCT', 'PRICING', 'INVENTORY', 'SCHEDULE', 'INDIVIDUAL',
        'USERALL', 'USERCATALOG', 'USERMYSELF', 'USERORG',
        'ORGANISATION', 'ORGANISATIONALL', 'ORGANISATIONCATALOG']
  },
  {
    id: 'PRODUCT',
    title: 'Product',
    displayName: 'Product Management',
    tags: 'product',
    icon: 'product.png',
    routerLink: '/main/product',
    securityCodes: ['PRODUCT'],
    params: {
      home: true
    }
  },
  {
    id: 'PRICERULE',
    title: 'Pricing',
    displayName: 'Pricing Management',
    tags: 'pricing',
    icon: 'pricing.png',
    routerLink: '/main/pricing',
    securityCodes: ['PRICING']
  },
  {
    title: 'Inventory',
    displayName: 'Inventory',
    tags: 'inventory',
    icon: 'inventory.png',
    routerLink: '/main/inventory',
    securityCodes: ['INVENTORY']
  },
  {
    title: 'Schedule',
    displayName: 'Schedule Management',
    tags: 'schedule',
    icon: 'schedule.png',
    routerLink: '/main/schedule',
    securityCodes: ['SCHEDULE'],
    params: {
      home: true
    }
  },
  {
    id: 'USER',
    title: 'User',
    displayName: 'User Management',
    tags: 'user',
    icon: 'user.png',
    routerLink: '/main/user',
    securityCodes: ['USERALL', 'USERCATALOG', 'USERMYSELF', 'USERORG']
  },
  {
    title: 'Organisation',
    displayName: 'Organisation Management',
    tags: 'organisation',
    icon: 'organisation.png',
    routerLink: '/main/organisation',
    securityCodes: ['ORGANISATIONALL', 'ORGANISATIONCATALOG', 'ORGANISATION']
  },
  {
    id: 'INSIGHT',
    title: 'Insight',
    displayName: 'Insight',
    tags: 'insight',
    icon: 'insight.png',
    routerLink: '/main/insight',
    securityCodes: ['INDIVIDUAL']
  },
  {
    title: 'Queues',
    displayName: 'Queues',
  },
  {
    title: 'Reporting',
    displayName: 'Reporting',
  },
  {
    title: 'Finance',
    displayName: 'Finance',
    routerLink: '/main/cashbook',
    mainMenu: true,
    securityCodes: ['CASHBOOK', 'CASHBOOKCATALOG', 'CASHBOOKALL',
        'COUNTERSALES', 'COUNTERSALESCATALOG', 'COUNTERSALESALL', 
        'PAYMENTALL', 'PAYMENTPAY', 'PAYMENTREFUND', 'PAYMENTREVERSE','PAYMENTVOID',
        'CREDITACCOUNT', 'CREDITACCOUNTALL', 'CREDITACCOUNTCATALOG']
  },
  {
    title: 'Settings',
    displayName: 'Settings Management',
    tags: 'Settings',
    icon: 'settings.png',
    routerLink: '/main/settings',
    mainMenu: true,
    securityCodes: ['ATTRIBUTE', 'DOCUMENTDISTRIBUTION',
        'EXCHANGERATE', 'GEOGRAPHY', 'SECURITY', 'VEHICLE'],
    params: {
      home: true
    }     
  },
];

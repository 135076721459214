import * as moment from 'moment';

export const select2TextType = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1
}

export const validDateFromOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

export const validDateToOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

export const select2HashtagOption = {
    placeholder: "<Hashtag>",
    allowClear: true,
    multiple: "multiple",
    theme: "select2-builder-multiple-gray",
    width: 'auto',
    dropdownAutoWidth: true
}

export const ConfigEditor = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    toolbar: [
        ['misc', ['undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table']],
        ['mybutton', ['imagecustom','videocustom']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    disableDragAndDrop: true,
    buttons: {
        'imagecustom': null,
        'videocustom': null
      }
}
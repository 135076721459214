import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";

export const PRODUCT_DASHBOARD_COMPARE_MONM_DEMO: OrdersDashboardCompareValuesModel[] = [
    {
        dayNo: 1,
        value1: 240,
        value1Name: "2021",
        value2: 443,
        value2Name: "2022"
    },
    {
        dayNo: 2,
        value1: 359,
        value1Name: "2021",
        value2: 945,
        value2Name: "2022"
    },
    {
        dayNo: 3,
        value1: 325,
        value1Name: "2021",
        value2: 4,
        value2Name: "2022"
    },
    {
        dayNo: 4,
        value1: 690,
        value1Name: "2021",
        value2: 6,
        value2Name: "2022"
    },
    {
        dayNo: 5,
        value1: 75,
        value1Name: "2021",
        value2: 401,
        value2Name: "2022"
    },
    {
        dayNo: 6,
        value1: 307,
        value1Name: "2021",
        value2: 43,
        value2Name: "2022"
    },
    {
        dayNo: 7,
        value1: 51,
        value1Name: "2021",
        value2: 352,
        value2Name: "2022"
    },
    {
        dayNo: 8,
        value1: 501,
        value1Name: "2021",
        value2: 500,
        value2Name: "2022"
    },
    {
        dayNo: 9,
        value1: 322,
        value1Name: "2021",
        value2: 50,
        value2Name: "2022"
    },
    {
        dayNo: 10,
        value1: 168,
        value1Name: "2021",
        value2: 110,
        value2Name: "2022"
    },
    {
        dayNo: 11,
        value1: 362,
        value1Name: "2021",
        value2: 244,
        value2Name: "2022"
    },
    {
        dayNo: 12,
        value1: 545,
        value1Name: "2021",
        value2: 232,
        value2Name: "2022"
    },
    {
        dayNo: 13,
        value1: 515,
        value1Name: "2021",
        value2: 483,
        value2Name: "2022"
    },
    {
        dayNo: 14,
        value1: 5,
        value1Name: "2021",
        value2: 161,
        value2Name: "2022"
    },
    {
        dayNo: 15,
        value1: 44,
        value1Name: "2021",
        value2: 337,
        value2Name: "2022"
    },
    {
        dayNo: 16,
        value1: 201,
        value1Name: "2021",
        value2: 97,
        value2Name: "2022"
    },
    {
        dayNo: 17,
        value1: 33,
        value1Name: "2021",
        value2: 10,
        value2Name: "2022"
    },
    {
        dayNo: 18,
        value1: 54,
        value1Name: "2021",
        value2: 484,
        value2Name: "2022"
    },
    {
        dayNo: 19,
        value1: 31,
        value1Name: "2021",
        value2: 319,
        value2Name: "2022"
    },
    {
        dayNo: 20,
        value1: 206,
        value1Name: "2021",
        value2: 283,
        value2Name: "2022"
    },
    {
        dayNo: 21,
        value1: 288,
        value1Name: "2021",
        value2: 110,
        value2Name: "2022"
    },
    {
        dayNo: 22,
        value1: 229,
        value1Name: "2021",
        value2: 34,
        value2Name: "2022"
    },
    {
        dayNo: 23,
        value1: 142,
        value1Name: "2021",
        value2: 229,
        value2Name: "2022"
    },
    {
        dayNo: 24,
        value1: 117,
        value1Name: "2021",
        value2: 93,
        value2Name: "2022"
    },
    {
        dayNo: 25,
        value1: 128,
        value1Name: "2021",
        value2: 441,
        value2Name: "2022"
    },
    {
        dayNo: 26,
        value1: 217,
        value1Name: "2021",
        value2: 354,
        value2Name: "2022"
    },
    {
        dayNo: 27,
        value1: 199,
        value1Name: "2021",
        value2: 778,
        value2Name: "2022"
    },
    {
        dayNo: 28,
        value1: 809,
        value1Name: "2021",
        value2: 83,
        value2Name: "2022"
    }, 
    {
        dayNo: 29,
        value1: 460,
        value1Name: "2021",
        value2: 95,
        value2Name: "2022"
    },
    {
        dayNo: 30,
        value1: 320,
        value1Name: "2021",
        value2: 224,
        value2Name: "2022"
    }

]
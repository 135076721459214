import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterSalesTabsComponent } from './counter-sales-tabs/counter-sales-tabs.component';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { InputFieldModule } from 'src/app/shared/ui/forms/inputs/input-field/input-field.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { MatIconModule } from '@angular/material/icon';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { ActionbarService } from 'src/app/shared/ui/actionbar/actionbar.service';
import { Helper } from 'src/app/shared/helper/app.helper';
import { CounterSalesSearchComponent } from './counter-sales-search/counter-sales-search.component';
import { CounterSalesSearchConditionComponent } from './counter-sales-search/counter-sales-search-condition/counter-sales-search-condition.component';
import { CounterSalesSearchTableComponent } from './counter-sales-search/counter-sales-search-table/counter-sales-search-table.component';
import { CalendarIconModule } from 'src/app/core/components/calendar-icon';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { CounterSalesSearchDataViewComponent } from './counter-sales-search/counter-sales-search-table/counter-sales-search-data-view/counter-sales-search-data-view.component';
import { StatusBadgeModule } from 'src/app/shared/ui/forms/inputs/status-badge/status-badge.module';

@NgModule({
  declarations: [
    CounterSalesTabsComponent,
    CounterSalesSearchComponent,
    CounterSalesSearchConditionComponent,
    CounterSalesSearchTableComponent,
    CounterSalesSearchDataViewComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxTemplateModule,
    UiModule,
    PanelsModule,
    MatIconModule,
    FocusingModule,
    BsDropdownModule,
    NgbModule,
    CollapseModule,
    InputFieldModule,
    FormsModule,
    FreezingTabModule,
    NgbNavModule,
    Select2Module,
    DaterangepickerModule,
    MatMenuModule,
    LoadingSpinnerModule,
    CalendarIconModule,
    ReactiveFormsModule,
    ItemsFoundModule,
    StatusBadgeModule
  ],
  providers: [
    ActionbarService,
    Helper
  ],
  exports:[
    CounterSalesTabsComponent,
    CounterSalesSearchComponent
  ]
})
export class CounterSalesContentModule { }
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InsightPassengerAttributeModel, InsightPassengerCommandModel, InsightPassengerModel, InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { CommentTypeReferenceModel, CountryReferenceModel, DomainAttributeModel, IdentityDocumentReferenceModel, MembershipTypeReferenceModel, StatusReferenceModel, UsageTypeReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { MembershipLevelModel } from "src/app/core/models/reference-model/reference-general-model/membership-level.model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { DomainAttributeService, MembershipTypeReferenceService } from "src/app/core/services/airline-services";
import { MembershipLevelService } from "src/app/core/services/airline-services/membership-level.service";
import { InsightPassengerService } from "src/app/core/services/individul-services";
import { CommentTypeReferenceService, CountryReferenceService, IdentityDocumentReferenceService, StatusReferenceService, UsageTypeReferenceService } from "src/app/core/services/system-services";
import { SecurityGroupService } from "src/app/core/services/system-services/security-group.service";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, DeleteButtonModel, NewButtonModel, RefreshButtonModel, SaveAsButtonModel, SaveButtonModel } from "src/app/shared/ui/actionbar/models";
import { FocusingService } from "src/app/shared/ui/forms/inputs/focusing.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { InsightDetailAttributeComponent } from "../insight-detail/insight-detail-attribute/insight-detail-attribute.component";
import { AttributeMapperService } from "../insight-detail/insight-detail-attribute/shared/attribute-mapper.service";
import { InsightDetailProfileComponent } from "../insight-detail/insight-detail-profile/insight-detail-profile.component";
import { ProfileMapperService } from "../insight-detail/insight-detail-profile/shared/profile-mapper.service";
import { InsightSearchConditionComponent } from "./insight-search-condition/insight-search-condition.component";
import { InsightSearchDocumentComponent } from "./insight-search-document/insight-search-document.component";
import { InsightSearchFilterNameComponent } from "./insight-search-filter-name/insight-search-filter-name.component";
import { InsightSearchTableComponent } from "./insight-search-table/insight-search-table.component";
import { InsightSearchMapperService } from "./shared/insight-search-mapper.service";

@Component({
    selector: 'op-insight-search',
    templateUrl: './insight-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        InsightSearchMapperService,
        AttributeMapperService,
        ProfileMapperService
    ]
})

export class InsightSearchComponent implements OnInit, OnChanges, OnDestroy {
    readonly FILTER_USAGE_TYPE: string = 'FILTER';
    readonly USAGETYPE_DATA: string = "DATA";
    readonly USAGETYPE_TEMPLATE: string = "TEMPLATE";

    private INDIVIDUAL_CODE = 'INDIVIDUAL'

    public statusReferences$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public countryReferences$ = new BehaviorSubject<CountryReferenceModel[]>(null);
    public usageTypeReferences$ = new BehaviorSubject<UsageTypeReferenceModel[]>(null);
    public identityDocumentTypeReferences$ = new BehaviorSubject<IdentityDocumentReferenceModel[]>(null);
    public commentTypeReferences$ = new BehaviorSubject<CommentTypeReferenceModel[]>(null);
    public membershipTypeReferences$ = new BehaviorSubject<MembershipTypeReferenceModel[]>(null);
    public insightPassengerFilters$ = new BehaviorSubject<InsightPassengerSearchModel[]>(null);
    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public insightPassenger$ = new BehaviorSubject<InsightPassengerModel>(null);
    public filter: InsightPassengerSearchModel;
    public filters$ = new BehaviorSubject<InsightPassengerSearchModel>(null);
    public individualFilterId: string;
    
    public advanceSearchModeEnabled = false;
    public actionSecurity: SecurityGroupSecurityModel;
    public security$ = new BehaviorSubject<SecurityGroupSecurityModel[]>(null);
    public membershipLevels$ = new BehaviorSubject<MembershipLevelModel[]>(null);
   
    public defaultFilter: InsightPassengerSearchModel = {
        individualId: null,
        searchName: null,
        firstName: null,
        lastName: null,
        middleName: null,
        gender: null,
        nationality: null,
        language: null,
        email: null,
        phoneType: null,
        phoneNumber: null,
        memberNumber: null,
        identityDocumentNumber: null,
        usageTypeCode: "DATA",
        city: null,
        countryCode: null,
        statusCode: "A",
        commentTypeCode: null,
        identityDocumentCode: null,
        membershipLevelCode: null,
        membershipLevelId: null,
        isOwnerFilter: false,
        insightPassengerLanguages: null,
        insightPassengerNationalities: null,
        insightPassengerAgeGroups: null,
        insightPassengerAttributes: null,
        insightPassengerCivilStatuses: null,
        insightPassengerInterests: null,
        insightPassengerPositions: null,
        insightPassengerPreferences: null,
        insightPassengerProfessions: null,
        insightPassengerSocials: null,
        organisationId: null,
        dateOfBirth: null
    }

    private searchModel: InsightPassengerSearchModel;
    private unsubscribe$ = new Subject();
    private actionBarHandler: ActionBarHandlerModel;

    newBtn = new NewButtonModel();
    copyBtn = new CopyButtonModel();
    saveBtn = new SaveButtonModel();
    ddSaveBtn = new SaveButtonModel();
    ddSaveAsBtn = new SaveAsButtonModel();
    cancelBtn = new CancelButtonModel();
    deleteBtn = new DeleteButtonModel();

    defaultActionBarHandler: ActionBarHandlerModel;

    @Input() searchFilterId: string;
    @Input() searchFilter: InsightPassengerSearchModel;
    @Input() executeFlag: boolean = true;
    @Output() cancelRequest = new EventEmitter();
    @Output() onDisplayAlert = new EventEmitter<string>();
    
    @ViewChild(InsightSearchTableComponent) searchTable: InsightSearchTableComponent;
    @ViewChild(InsightSearchFilterNameComponent) searchFilterName: InsightSearchFilterNameComponent;
    @ViewChild(InsightSearchDocumentComponent) searchDocument: InsightSearchDocumentComponent;
    @ViewChild(InsightSearchConditionComponent) searchCondition: InsightSearchConditionComponent;
    @ViewChild(InsightDetailAttributeComponent) searchAttribute: InsightDetailAttributeComponent;
    @ViewChild(InsightDetailProfileComponent) searchProfile: InsightDetailProfileComponent;

    searchMode:boolean = true;
    
    constructor(private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private insightPassengerService: InsightPassengerService,
        private identityDocumentTypeService: IdentityDocumentReferenceService,
        private membershipTypeReferenceService: MembershipTypeReferenceService,
        private commentTypeReferenceService: CommentTypeReferenceService,
        private actionbarService: ActionbarService,
        private countryService: CountryReferenceService,
        private searchMapperService: InsightSearchMapperService,
        private attributeMapperService: AttributeMapperService,
        private profileMapperService: ProfileMapperService,
        private securityGroupService: SecurityGroupService,
        private domainAttributeService: DomainAttributeService,
        private navigationService: NavigationService,
        public spinnerService: LoadingSpinnerService,
        private focusingService: FocusingService,
        private membershipLevelService: MembershipLevelService) {
            this.filter = Object.assign({}, this.searchFilter ?? this.defaultFilter);
            this.filters$.next(Object.assign({}, this.searchFilter ?? this.defaultFilter));
            this.getUserSecurity();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchFilter'] && this.searchFilter) {
            this.filters$.next(Object.assign({}, this.searchFilter));
            this.filter = (Object.assign({}, this.searchFilter));
            this.getReferencesData().then(() => {
                if (this.searchFilter) {
                    this.fillInsightPassengerAttribute(this.searchFilter.insightPassengerAttributes);
                    this.fillInsightPassengerProfileFromSearchFilter(this.searchFilter);
                }         
            })
        }
    }

    ngOnInit(): void {
        this.getReferencesData().then(() => {
            if (this.searchFilter) {
                this.fillInsightPassengerAttribute(this.searchFilter.insightPassengerAttributes);
                this.fillInsightPassengerProfileFromSearchFilter(this.searchFilter);
            }         
        });
    }
    
    private getReferencesData(): Promise<void> {
        return new Promise((resolve, reject) => {
            forkJoin({
                domainAttributes: this.domainAttributeService.getIndividualAttributes('', ''),
                statusReferences: this.statusReferenceService.getAll(),
                countryReferences: this.countryService.getCountryReference(),
                usageTypeReferences: this.usageTypeReferenceService.getUsageTypeReferences(),
                membershipTypeReferences: this.membershipTypeReferenceService.getMembershipTypeReferences(),
                commentTypeReferences: this.commentTypeReferenceService.getCommentTypeReferences(),
                identityDocumentTypeReferences: this.identityDocumentTypeService.getIdentityDocumentReferences(),
                insightPassengerFilters: this.insightPassengerService.getInsightPassengerFilter(),
                membershipLevels: this.membershipLevelService.getMembershipLevel()
            })
                .subscribe(({
                    domainAttributes,
                    statusReferences,
                    countryReferences,
                    usageTypeReferences,
                    membershipTypeReferences,
                    commentTypeReferences,
                    identityDocumentTypeReferences,
                    insightPassengerFilters,
                    membershipLevels
                }) => {
                    this.fillDomainAttributeReferences(domainAttributes);
                    this.fillStatusReferences(statusReferences);
                    this.fillCountryReferences(countryReferences);
                    this.fillUsageTypeReferences(usageTypeReferences);
                    this.fillMembershipLevelReferences(membershipLevels);
                    this.fillCommentTypeReferences(commentTypeReferences);
                    this.fillIdentityDocumentReferences(identityDocumentTypeReferences);
                    this.fillInsightPassengerFilters(insightPassengerFilters);
                    this.fillMembershipTypeReferences(membershipTypeReferences);
                    resolve();
                })
        });
    }

    ngAfterViewInit(): void {
        this.setDefaultActionBarHandler();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    public clearSearchFilter() {
        this.individualFilterId = null;
        this.filter = Object.assign({}, this.defaultFilter);
        this.filters$.next(Object.assign({}, this.defaultFilter));
        this.searchAttribute.initForm();
        this.searchProfile.rows$.next([]);
        this.searchProfile.togglePanelCollapseStatus(true);
        this.searchTable.rows = 0;
        this.searchTable.dataGrid.clearSearchResult();
        this.hideAlertBar();
    }

    private setDefaultActionBarHandler() {
        this.newBtn.enable(!!this.actionSecurity?.newFlag);
        this.copyBtn.enable(!!this.actionSecurity?.copyFlag);
        this.saveBtn.enable(!!this.actionSecurity?.newFlag || !!this.actionSecurity?.editFlag || !!this.actionSecurity?.copyFlag);
        this.ddSaveBtn.disable();
        this.ddSaveAsBtn.disable();
        this.deleteBtn.enable(!!this.actionSecurity?.deleteFlag);

        this.saveBtn.addDropdownBtn(this.ddSaveBtn);
        this.saveBtn.addDropdownBtn(this.ddSaveAsBtn);

        this.actionBarHandler = new ActionBarHandlerModel(
            this.newBtn,
            this.copyBtn,
            this.saveBtn,
            this.cancelBtn,
            this.deleteBtn,
            new RefreshButtonModel()
        )

        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    public searchClick() {
        let model = this.searchMapperService.filterViewToInsightSearchModel(this.searchCondition.getConditionValue());

        this.searchFilter = model;
        this.fillFilterModel(model);
        this.searchTable.searchInsightPassenger(model);
        
        setTimeout(() => {
            this.focusingService.focus(this.searchTable.focusingDirective);
        })
        this.hideAlertBar();
    }

    private fillFilterModel(model: InsightPassengerSearchModel) {
        this.setDocumentSearchFilter(model);
        this.setProfileSearchFilter(model);
        this.setAttributeSearchFilter(model);
    }

    private setDocumentSearchFilter(model: InsightPassengerSearchModel) {
        this.searchMapperService.documentFilterViewToInsightSearchModel(model, this.searchDocument.filter);
    }

    private setProfileSearchFilter(model: InsightPassengerSearchModel) {
        this.searchMapperService.profileRowViewsToProfileModels(this.searchProfile.getInsightPassengerProfile(), model);
    }

    private setAttributeSearchFilter(model: InsightPassengerSearchModel) {
        this.searchMapperService.attributeRowViewsToProfileModels(this.searchAttribute.getInsightPassengerAttribute(), model);
    } 

    private fillStatusReferences(responses: StatusReferenceModel[]) {
        this.statusReferences$.next(responses);
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (response) => {
                    this.statusReferences$.next(response);
                }
            )
    }

    private fillUsageTypeReferences(responses: UsageTypeReferenceModel[]) {
        this.usageTypeReferences$.next(responses.filter(x => x.usageTypeCode == this.USAGETYPE_DATA ||
            x.usageTypeCode == this.USAGETYPE_TEMPLATE || x.usageTypeCode == this.FILTER_USAGE_TYPE));
    }

    private fillCountryReferences(responses: CountryReferenceModel[]) {
        this.countryReferences$.next(responses);
    }

    private fillMembershipTypeReferences(responses: MembershipTypeReferenceModel[]) {
        this.membershipTypeReferences$.next(responses);
    }

    private fillCommentTypeReferences(responses: CommentTypeReferenceModel[]) {
        this.commentTypeReferences$.next(responses);
    }

    private fillIdentityDocumentReferences(responses: IdentityDocumentReferenceModel[]) {
        this.identityDocumentTypeReferences$.next(responses);
    }

    private fillInsightPassengerFilters(responses: InsightPassengerSearchModel[]) {
        this.insightPassengerFilters$.next(responses);
    }

    private getUserSecurity() {
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {    
                    this.security$.next(response);
                    this.actionSecurity = response?.filter(x => x.securityCode == this.INDIVIDUAL_CODE)[0] ?? null;
                }
            )
    }

    private fillDomainAttributeReferences(responses: DomainAttributeModel[]) {
        this.domainAttribute$.next(responses.filter(x => x.searchFlag));
    }

    private fillMembershipLevelReferences(responses: MembershipLevelModel[]) {
        this.membershipLevels$.next(responses);
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.goToCategoryTab();
            case ACTION_STATUS.cancel:
                this.clearSearchFilter();
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.searchTable.new();
                break;
            case ACTION_STATUS.copy:
                this.onCopyItem(this.searchTable.itemSelected);
                break;
            case ACTION_STATUS.save:
                this.saveFilter(this.individualFilterId, "save");
                break;
            case ACTION_STATUS.saveAs:
                this.saveFilter(null, "save as");
                break;
            case ACTION_STATUS.refresh:
                this.onRefresh();
                break;
        }
    }

    private goToCategoryTab() {
        this.navigationService.navigate("main/insight/home", null);
    }

    private saveFilter(individualFilterId: string, from: string) {
        let filterNameModel =  this.searchFilterName.getValues();

        if (!filterNameModel) {
            return null;
        }

        let model = this.getFilterSearchData();
        let command = this.searchMapperService.searchModelToCommandModel(model, filterNameModel);
        
        if (individualFilterId) {
            this.editPassenger(command);
            return;
        }

        this.addPassenger(command);       
    }

    addPassenger(command: InsightPassengerCommandModel) {
        this.spinnerService.show();
        this.insightPassengerService.addInsightPassenger(command)
            .subscribe (
                (response) => {
                    response = this.getIsOwnerFilter(response);
                    this.filters$.next(this.searchMapperService.insightPassengerModelToSearchModel(response));
                    this.individualFilterId = response.individualId;
                    this.fillInsightPassengerAttribute(response.insightPassengerAttributes);
                    this.fillInsightPassengerProfile(response);
                    this.spinnerService.saveComplete();
                },
                () => {
                    this.spinnerService.hide();
                }
            )       
    }

    editPassenger(command: InsightPassengerCommandModel) {
        this.spinnerService.show();
        this.insightPassengerService.editInsightPassenger(this.individualFilterId, command)
            .subscribe(
                (response) => {
                    response = this.getIsOwnerFilter(response);
                    this.filters$.next(this.searchMapperService.insightPassengerModelToSearchModel(response));
                    this.individualFilterId = response.individualId;
                    this.fillInsightPassengerAttribute(response.insightPassengerAttributes);
                    this.fillInsightPassengerProfile(response);
                    this.spinnerService.saveComplete();
                 },
                () => {
                    this.spinnerService.hide();
                }
            );          
    }

    private getFilterSearchData(): InsightPassengerSearchModel{
        let filterNameModel =  this.searchFilterName.getValues();

        if (!filterNameModel) {
            return null;
        }

        let model = this.searchCondition.getConditionValue();
        model.searchName = filterNameModel.searchName;
        this.fillFilterModel(model);

        return model;
    }

    public onSearchFilterIdChange(individualId: string) {
        if (individualId) {
            this.getIndividual(individualId);
        } else {
            this.clearSearchFilter();
        }
    }

    private getIndividual(individualId: string) {
        this.spinnerService.show();
        this.advanceSearchModeEnabled = true;
        this.insightPassengerService.getInsightPassenger(individualId)
            .subscribe(
                (response: InsightPassengerModel) => {
                    response = this.getIsOwnerFilter(response);
                    this.filters$.next(this.searchMapperService.insightPassengerModelToSearchModel(response));
                    this.individualFilterId = response.individualId;
                    this.fillInsightPassengerAttribute(response.insightPassengerAttributes);
                    this.fillInsightPassengerProfile(response);
                    this.hideAlertBar();
                    this.spinnerService.hide();
                },
                () => {
                    this.spinnerService.hide()
                }
            )
    }

    private fillInsightPassengerAttribute(passengerAttributes: InsightPassengerAttributeModel[]) {
        if  (passengerAttributes == null) {
            return;
        }
        let attributeViews = this.attributeMapperService.insightModelToAttributeRowViews(passengerAttributes);
        if (!this.searchAttribute) {
            return;
        }
        this.searchAttribute.fillModelToForm(attributeViews);
    }

    private fillInsightPassengerProfile(passenger: InsightPassengerModel) {
        let profileViews = this.profileMapperService.insightModelToFliterProfileRowViews(passenger);
        this.searchProfile.fillModelToForm(profileViews);
    }

    private fillInsightPassengerProfileFromSearchFilter(passenger: InsightPassengerSearchModel) {
        let profileViews = this.profileMapperService.insightSearchModelToFliterProfileRowViews(passenger);
        this.searchProfile.fillModelToForm(profileViews);
    }

    getIsOwnerFilter(response: InsightPassengerModel): InsightPassengerModel{
        response.isOwnerFilter = response.filterUserAccountId ? true: false;
        return response
    }

    onCopyItem(data) {
        if (!data) {
            return;
        }

        let usageTypeCode = data.usageTypeCode;
        switch(usageTypeCode) {
            case this.FILTER_USAGE_TYPE:
                break;
            case this.USAGETYPE_TEMPLATE:
                this.copyInsightTemplate(data.individualId);
            case this.USAGETYPE_DATA:
                this.copyInsight(data.individualId);
                break;
        }
    }

    onRefresh() {
        this.searchClick();
    }
    
    private copyInsightTemplate(individualId) {
        this.loadDetailForm(individualId, true);
    }

    private copyInsight(individualId) {
        this.loadDetailForm(individualId, true);
    }

    private loadDetailForm(individualId, copy: boolean = false) {
        const queryParams = {
            id: individualId,
            copy: copy,
            tab: 'search',
            security: this.security$.value
        }

        this.navigationService.navigate('main/insight/details', null, false, queryParams);
    }

    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchClick();
                break;
            default:
                break;
        }
    }

    public displayAlertBar(message: string) {
        this.onDisplayAlert.emit(message);
    }

    private hideAlertBar() {
        this.onDisplayAlert.emit(null);
    }
}



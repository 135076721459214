import { NgModule } from '@angular/core';
import { ProductManagementModule } from './product-management/product-management.module';
import { PricingModule } from './pricing/pricing.module';

import { UserManagementModule } from './user-management/user-management.module';
import { PricingComponent } from './pricing/pricing.component';
import { ProductManagementComponent } from './product-management/product-management.component'
import { InsightManagementComponent } from './insight-management/insight-management.component';
import { InsightManagementModule } from './insight-management/insight-management.module';
import { ItemsFoundModule } from '../core/pipes/items-found/items-found.module';
import { SettingsManagementModule } from './settings-management/settings-management.module';
import { SettingsManagementComponent } from './settings-management/settings-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { OrganisationModule } from './organisation/organisation.module';
import { OrganisationComponent } from './organisation/organisation.component';
import { ScheduleModule } from './schedule/schedule.module';
import { ScheduleComponent } from './schedule/schedule.component';
import { CashbookModule } from './cashbook/cashbook.module';
import { CashbookComponent } from './cashbook/cashbook.component';

@NgModule({
    declarations: [],
    imports: [
        InsightManagementModule,
        ItemsFoundModule,
        PricingModule,
        ProductManagementModule,
        OrganisationModule,
        ScheduleModule,
        SettingsManagementModule,
        UserManagementModule,
        CashbookModule
    ],
    exports: [
        ItemsFoundModule
    ]
})
export class ApplicationMainModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'op-document-distribution',
  templateUrl: './document-distribution.component.html'
})
export class DocumentDistributionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

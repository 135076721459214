import { PriceRuleConditionBase } from "./price-rule-condition.base";

export class PriceRuleSalesBucketView extends PriceRuleConditionBase {
    public priceRuleSalesBucketId: string;
    public salesBucketCode: string;
    public inventoryDimensionCode: string;
    public conditionCode: string;
    public thresholdValue: string;
    public priceRuleAttributeGroupCode: string;
    public dimensionUnitCode: string;
    public children: PriceRuleSalesBucketView[];
}
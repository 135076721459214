import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep } from "lodash";
import { CurrencyReferenceModel, ExchangeRateModel } from 'src/app/core/models/reference-model/reference-general-model';
import { CurrencyReferenceService } from 'src/app/core/services/system-services';
import { ExchangeRateService } from 'src/app/core/services/system-services/exchange-rate.service';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { MODULE_NAME, PAGE_TITLE } from '../../../../settings-management-content/shared/title-constant';
import { ExchangeCurrencyMapperService } from './exchange-currency-list-mapper.service';
import { select2DefaultOption } from './exchange-currency-list.configuration';
import { ExchangeCurrencyView } from './exchange-currency.view';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { SettingsSecurityCodes } from 'src/app/modules/settings-management/settings-management-content/settings-categories/settings-categories';

@Component({
  selector: 'op-exchange-currency-list',
  templateUrl: './exchange-currency-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exchange-currency-list.component.scss'],
  providers: [ExchangeCurrencyMapperService]
})
export class ExchangeCurrencyListComponent implements OnInit {
  public readonly EXPORT_FILE_NAME = "ExchangeCurrency";
  public readonly precision  = 6;
  private previousTitle: string = PAGE_TITLE.exchange_rate;
  private defaultTitle = PAGE_TITLE.exchange_currency;
  public title: string = this.defaultTitle;
  public actionBarHandler = new ActionBarHandlerModel(
    new SaveButtonModel(),
    new CancelButtonModel(),
    new RefreshButtonModel()
  );
  private _navigationSubscription: Subscription;
  private unsubscribe$ = new Subject();
  public select2Config: any = select2DefaultOption;
  public currencies$ = new BehaviorSubject<CurrencyReferenceModel[]>(null);
  public exchangeCurrencyViews: ExchangeCurrencyView[];
  public exchangeCurrencySaves: ExchangeCurrencyView[] = new Array();
  public currencyTypeName: string;
  public baseCurrencyCode: string;
  public focusing = false;
  public selectedItem: any;
  public rows = 0;
  public exportData: any;
  public header: string[];
  public pdfDefaultStyle = {
    fontSize: 7
  }
  public selectedRows: number[] = [];
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  public disabledApply: boolean = true;

  currencyReferenceModels: CurrencyReferenceModel[]
  userSecurityRef: SecurityGroupSecurityModel

  constructor(private navigationService: NavigationService,
    private actionbarService: ActionbarService,
    private spinnerService: LoadingSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private currencyReferenceService: CurrencyReferenceService,
    private exchangeRateService: ExchangeRateService,
    private mapperService: ExchangeCurrencyMapperService,
    private securityGroupService: SecurityGroupService) {
    this.select2Config.templateResult = this.templateResultAttribute.bind(this);
  }

  ngOnInit(): void {
    this.getCurrencies();
  }

  ngAfterViewInit(): void {
    this.actionbarService.updateState(this.actionBarHandler);
    this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(actionId => {
      this.toolbarClicked(actionId);
    });
    this.getDataGridHeader();
  }

  toolbarClicked(clickedButton: string) {
    switch (clickedButton) {
      case ACTION_STATUS.back:
        this.back();
        break;
      case ACTION_STATUS.save:
        this.save();
        break;
      case ACTION_STATUS.cancel:
        this.back();
        break;
      case ACTION_STATUS.refresh:
        this.refresh();
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private getCurrencies() {
    this.clearData();
    this.spinnerService.show();
    this.currencyReferenceService.getCurrencyReferencesOrganisationOwner()
    .pipe(
      switchMap((models: CurrencyReferenceModel[]) => {
        this.currencyReferenceModels = models
        return this.securityGroupService.userSecurityGroupSecurity
      })
    )
      .subscribe(
        (userSecurity) => {
          this.userSecurityRef = userSecurity.find(s => s.securityCode == SettingsSecurityCodes.get(MODULE_NAME.exchange_rate))
          this.actionBarHandler.get(ACTION_STATUS.save).enable(this.userSecurityRef.newFlag || this.userSecurityRef.editFlag)
          this.actionbarService.updateState(this.actionBarHandler)

          this.currencies$.next(this.currencyReferenceModels)
          if (this.currencyReferenceModels?.length) {
            this.baseCurrencyCode = this.currencyReferenceModels[0].currencyCode;
            this.currencyChange(this.baseCurrencyCode);
            this.getExchangeRates(this.baseCurrencyCode, this.currencyReferenceModels);
          }
        }
      )
  }

  private getExchangeRates(currencyCode: string, currencyModels: CurrencyReferenceModel[]) {
    this.exchangeRateService.getByBaseCurrencyCode(currencyCode)
      .subscribe(
        (responses: ExchangeRateModel[]) => {
          this.exchangeCurrencyViews = this.mapperService.toViews(currencyCode, currencyModels, responses);
          if (this.existSave()) {
            this.mappingWithSaveData();
          }
          this.updateSelectedRows();
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        }
      )
  }


  private templateResultAttribute(item): JQuery<HTMLElement> {
    if (!item.id) {
      return item.text;
    }
    return this.getTemplateResult(item.id);
  }

  private getTemplateResult(currencyCode: string): JQuery<HTMLElement> {
    let filters = this.currencies$.value.filter(x => x.currencyCode == currencyCode);
    let $template = $(
      '<div class="row">' +
      '<div class="col-md-2 col-xs-4">' + filters[0].currencyCode + '</div>' +
      '<div class="col-md-6 col-xs-4">' + filters[0].currencyName + '</div>' +
      '<div class="col-md-4 col-xs-4">' + filters[0].currencyTypeName + '</div>' +
      '</div>'
    );
    return $template;
  }

  public currencyChange(event: string | string[]) {
    let currencyCode = <string>event;
    this.baseCurrencyCode = currencyCode;
    this.currencyTypeName = this.getCurrencyTypeName(currencyCode);
    this.displayCurrencyInGrid(currencyCode);
  }

  private getCurrencyTypeName(currencyCode: string): string {
    let filters = this.currencies$.value.filter(x => x.currencyCode == currencyCode);
    return filters[0].currencyTypeName;
  }

  private getDataGridHeader() {
    this.header = [];
    for (let i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      let column = this.dataGrid.instance.columnOption(i);
      this.header.push(column.caption);
    }
  }

  public getNumberOfRows(e) {
    this.rows = e.component.totalCount();
    this.getExportData()?.done((filteredData: any) => {
      this.exportData = filteredData.map((data) => {
        return this.mapData(data, data.no);
      })
    });
  }

  private mapData(data: any, no: number): any {
    return {
      No: no,
      BaseCurrncyCode: data.baseCurrencyCode,
      CurrencyCode: data.currencyCode,
      CurrencyType: data.currencyType,
      Factor: data.factor,
      CommitByName: data.commitByName,
      CommitDateTime: data.commitDateTime
    };
  }

  private getExportData() {
    let filterExpr = this.dataGrid.instance.getCombinedFilter();
    let gridDataSource = this.dataGrid.instance.getDataSource();
    return gridDataSource?.store()?.load({
      filter: filterExpr,
    });
  }

  public onApply() {
    let selectRows: ExchangeCurrencyView[] = this.dataGrid.instance.getSelectedRowsData();
    this.exchangeCurrencySaves = this.deleteByBaseCurrency();
    if (selectRows?.length) {
      this.exchangeCurrencySaves.push(...selectRows);
    } else {
      this.insertDeleteBaseCurrency();
    }
  }

  public onCancel() {
    this.getCurrencies();
  }

  private deleteByBaseCurrency(): ExchangeCurrencyView[] {
    return cloneDeep(this.exchangeCurrencySaves.filter(x => x.baseCurrencyCode != this.baseCurrencyCode));
  }

  private displayCurrencyInGrid(currencyCode: string) {
    this.getExchangeRates(currencyCode, this.currencies$.value);
  }

  private existSave(): boolean {
    if (this.exchangeCurrencySaves.filter(x => x.baseCurrencyCode == this.baseCurrencyCode)?.length) {
      return true
    }
    return false;
  }

  private mappingWithSaveData() {
    for (let exchangeCurrency of this.exchangeCurrencyViews) {
      exchangeCurrency.select = false;
      let filters = this.exchangeCurrencySaves.filter
        (x => x.baseCurrencyCode == this.baseCurrencyCode &&
          x.currencyCode == exchangeCurrency.currencyCode);
      if (filters?.length) {
        exchangeCurrency.select = true;
        exchangeCurrency.factor = filters[0].factor;
      }
    }
  }

  private updateSelectedRows() {
    this.selectedRows = this.exchangeCurrencyViews.filter((currency) => currency.select === true).map((currency) => currency.no);
  }

  private save() {
    let command = this.mapperService.toModels(this.exchangeCurrencySaves);
    if (command) {
      this.spinnerService.showSaving();
      this.exchangeRateService.save(command)
        .subscribe(
          (response) => {
            this.clearData();
            this.getExchangeRates(this.baseCurrencyCode, this.currencies$.value);
            this.spinnerService.saveComplete();
          },
          () => {
            this.spinnerService.hide();
          }
        )
    }
  }

  private clearData() {
    this.exchangeCurrencySaves = [];
    this.selectedRows = [];
    this.disabledApply = true;
  }

  private insertDeleteBaseCurrency() {
    let view = {} as ExchangeCurrencyView;
    view.baseCurrencyCode = this.baseCurrencyCode;
    view.factor = 0;
    this.exchangeCurrencySaves.push(view);
  }

  public getDateString(rowData) {
    if (rowData.commitDateTime) {
      var date = new Date(rowData.commitDateTime);
      return date.toLocaleString([], { hour12: true, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    }
    return null;
  }

  private back() {
    this.navigationService.navigate('/main/settings/exchange-rate', this.previousTitle);
  }

  private refresh() {
    this.getCurrencies();
  }

  public onSavedGridValue(value) {
    this.disabledApply = false;
  }

  public onCellClick(cell) {
    if (cell.columnIndex == 0) {
      this.disabledApply = false;
    }
  }

}

import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ScheduleDistributionSearchModel } from 'src/app/core/models/schedule-distribution-model';
import { ScheduleDistributionService } from 'src/app/core/services/schedule-services/schedule-distribution.service';
import { ScheduleQueueResultCode } from 'src/app/modules/schedule/shared/schedule.contant';
import { MapperService } from '../../shared/mapper.service';
import ScheduleDistributionSearchView from '../../shared/schedule-distribution-search.view';

@Component({
    selector: 'op-schedule-distribution-detail-table',
    templateUrl: './schedule-distribution-detail-table.component.html',
})

export class ScheduleDistributionDetailTableComponent implements OnChanges {
    @Input() parentScheduleQueueId: string;

    public resultsAll: ScheduleDistributionSearchView[];

    constructor(private mapperService: MapperService,
        private scheduleDistributionService: ScheduleDistributionService,
        private changeDetectionRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['parentScheduleQueueId']) {
            this.getData();
        }
    }

    private getData() {
        this.scheduleDistributionService.getDistributionChildren(this.parentScheduleQueueId)
        .subscribe(
            (responses: ScheduleDistributionSearchModel[]) => {
                this.mapData(responses);
            });
    }

    private mapData(results: ScheduleDistributionSearchModel[]) {
        let index = 1;
        var temps = new Array<ScheduleDistributionSearchView>();
        for (let model of results) {
            let detail = this.mapperService.toScheduleDistrbutionSearchView(model, index);
            detail['processStatus'] = this.getProcessStatus(detail);
            detail['result'] = model.scheduleQueueResultName ?? model.scheduleQueueResultCode;
            temps.push(detail);
            index++;
        }
        this.resultsAll = temps;
        this.changeDetectionRef.detectChanges();
    }

    private getProcessStatus(data: ScheduleDistributionSearchView) {
        if (!data.processStartDateTime) {
            return "";
        }

        if (data.processStartDateTime && !data.processEndDateTime) {
            return 'In Progress'
        }

        if (data.processStartDateTime && data.processEndDateTime) {
            return 'Completed'
        }

        return "";
    }

    cellPrepared(e) {
        if (e.rowType == 'header' && e.column.dataField == 'numberOfProducts') {
            e.cellElement[0].innerHTML = e.cellElement[0].innerHTML.replace(/\\n/g, "<br/>");
        }
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'result') {
            this.setResultCellColor(e)
        } 
    }

    private setResultCellColor(e) {
        switch (e.data.scheduleQueueResultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;    
        }
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild
} from '@angular/core';

import { BehaviorSubject, Observable, Subscriber, forkJoin } from 'rxjs';
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import {
    OrderDetailAttributeView,
    OrdersMapperService,
    select2AttributeInherit,
    select2ChoiceMultiple,
    select2Attribute
} from '../..';
import { GlobalStateService } from 'src/app/shared/ui/uiservice/global-state.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { DomainAttributeService } from 'src/app/core/services/airline-services';
import { AttributeChoiceModel, DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

interface ViewProperty {
    min: number;
    max: number;
    required: boolean;
    display: BehaviorSubject<boolean>;
    attributeTypes: BehaviorSubject<Select2Data[]>;
    attributeChoices: BehaviorSubject<Select2Data[]>;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-attribute',
    templateUrl: './order-detail-attribute.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailAttributeComponent {

    @Input("id") id: string;
    @Input("isHeader") isHeader: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input() orderTypeCode: string;
    @Input("orderStateCode") orderStateCode: string;
    @ViewChild("panel") panel: any;

    public attributeInheritOption: any = select2AttributeInherit;
    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderDetailAttributeViews: OrderDetailAttributeView[] = new Array<OrderDetailAttributeView>();

    public orderLock: boolean;

    get countNumberOfItems(): number {
        return this.orderDetailAttributeViews?.length || 0;
    }

    get addNewButtonDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.ADD_NEW_BUTTON_ID);
    }

    get noMoreToAdd(): boolean {
        return this.selectedAttributeTypeCodes?.length == this.domainAttributeModels?.length;
    }

    get selectedAttributeTypeCodes(): string[] {
        return this.orderDetailAttributeViews
            ?.map(oda => oda.attributeTypeCode) || [];
    }

    viewProperties: ViewProperty[] = [];

    private readonly ADD_NEW_BUTTON_ID = this.stringHelperService.NewGuid();

    private domainAttributeModels: DomainAttributeModel[] = [];

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService,
        private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService,
        private domainAttributeService: DomainAttributeService) {
        this.globalStateService.register(this.ADD_NEW_BUTTON_ID);
    }

    public loadOrderDetailAttribute(orderId: string, attributeOrderId: string, orderLock: boolean, domainCode: string): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.getOrderDetailAttributes(orderId, attributeOrderId, domainCode, observer);
        });
    }

    private getOrderDetailAttributes(orderId: string, attributeOrderId: string, domainCode: string, observer: Subscriber<boolean>) {
        forkJoin({
            result: this.orderDetailService.getOrderDetailAttributes(orderId, attributeOrderId, domainCode),
            domainAttributes: this.domainAttributeService.getByOrderType(this.orderStateCode, this.orderTypeCode)
        })
            .subscribe(
                ({
                    result,
                    domainAttributes
                }) => {
                    this.domainAttributeModels = domainAttributes;
                    this.orderDetailAttributeViews = this.mapperService.toOrderDetailAttributeViews(result);
                    this.populateViewProperties();
                    this.checkCollape();
                    this.changeDetection.detectChanges();
                    observer.next(true);
                    observer.complete();
                }
            )
    }

    private populateViewProperties() {
        this.viewProperties = this.orderDetailAttributeViews
            ?.map((view, index) => {
                const domainAttribute = this.domainAttributeModels
                    ?.find(da => da.attributeTypeCode == view.attributeTypeCode);
                if (!domainAttribute) return null;
                return {
                    attributeTypes: this.populateSelect2DataList(view, index),
                    display: new BehaviorSubject<boolean>(true),
                    max: domainAttribute.maximumValue,
                    min: domainAttribute.minimumValue,
                    required: domainAttribute.requiredFlag,
                    attributeChoices: new BehaviorSubject<Select2Data[]>(this.getChoiceData(view.attributeTypeCode))
                }
            })
    }

    private populateSelect2DataList(view: OrderDetailAttributeView, index: number): BehaviorSubject<Select2Data[]> {
        const select2Datas = this.domainAttributeModels
            ?.filter(da => this.selectedAttributeTypeCodes?.includes(da.attributeTypeCode) == false ||
                da.attributeTypeCode == view.attributeTypeCode)
            ?.map(da => new Select2Data(da.attributeTypeCode, `${da.attributeTypeName}${da.requiredFlag ? ' *' : ''}`)) || [];
        const select2Data$ = this.viewProperties[index]?.attributeTypes;
        if (select2Data$) {
            select2Data$.next(select2Datas);
            return select2Data$;
        }
        return new BehaviorSubject<Select2Data[]>(select2Datas);
    }

    private checkCollape(): void {
        if (!this.orderDetailAttributeViews || this.orderDetailAttributeViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }
    public getChoiceOption(multipleChoice: boolean): any {
        if (multipleChoice === true) {
            return select2ChoiceMultiple
        }
        return select2Attribute;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
    deleteRow(index: number) {
        this.orderDetailAttributeViews = this.orderDetailAttributeViews.filter((_, i) => i != index);
        this.populateViewProperties();
        this.noItemAutoClosePanel();
    }
    onNewClick() {
        const attributeType = this.domainAttributeModels
            ?.filter(da => this.selectedAttributeTypeCodes?.includes(da.attributeTypeCode) == false)
            ?.[0];
        if (!attributeType) return;
        this.orderDetailAttributeViews.push({
            attributeChoices: [],
            attributeText: null,
            attributeTypeCode: attributeType?.attributeTypeCode,
            attributeTypeName: attributeType?.attributeTypeName,
            dimensionUnitCode: attributeType?.dimensionUnitCode,
            multipleChoiceFlag: attributeType?.multipleChoiceFlag,
            orderAttributeId: null,
            orderId: null,
            overrideFlag: false,
            requiredFlag: attributeType?.requiredFlag,
            showOnNewFlag: attributeType?.showOnNewFlag,
            readOnlyFlag: false
        })
        this.populateViewProperties();
        this.autoOpenPanel();
    }
    onAttributeTypeCodeChange(attributeTypeCode: any, index: number) {
        this.orderDetailAttributeViews[index].attributeTypeCode = attributeTypeCode;
        const domainAttribute = this.domainAttributeModels
            ?.find(da => da.attributeTypeCode == attributeTypeCode);
        this.orderDetailAttributeViews[index].dimensionUnitCode = domainAttribute.dimensionUnitCode;
        this.orderDetailAttributeViews[index].multipleChoiceFlag = domainAttribute.multipleChoiceFlag;
        this.orderDetailAttributeViews[index].requiredFlag = domainAttribute.requiredFlag;
        this.orderDetailAttributeViews[index].showOnNewFlag = domainAttribute.showOnNewFlag;
        this.orderDetailAttributeViews[index].attributeTypeName = domainAttribute.attributeTypeName;
        this.orderDetailAttributeViews[index].attributeText = null;

        this.viewProperties[index].attributeChoices.next(this.getChoiceData(attributeTypeCode))

        this.forceUpdate(index);
    }

    private toSelect2Data(choices: AttributeChoiceModel[]): Select2Data[] {
        return choices?.map(choice => new Select2Data(choice.attributeChoiceId, choice.attributeChoiceName)) || [];
    }

    private forceUpdate(index: number) {
        this.viewProperties[index].display.next(false);
        setTimeout(() => this.viewProperties[index].display.next(true));
    }

    private getChoiceData(attributeTypeCode: string): Select2Data[] {
        return this.toSelect2Data(this.domainAttributeModels
            ?.find(da => da.attributeTypeCode == attributeTypeCode)
            ?.attributeChoices);
    }

    private autoOpenPanel() {
        this.collapsed = false;
        this.changeDetection.detectChanges();
    }

    private noItemAutoClosePanel() {
        if (!this.countNumberOfItems) {
            this.collapsed = true;
            this.changeDetection.detectChanges();
        }
    }
    public disableChange(view: OrderDetailAttributeView, index: number): boolean {
        return this.viewProperties?.[index]?.required || view.readOnlyFlag
    }
}

import { LanguageTranslationSetCommandModel } from "../../language-translation-set-model";
import { MediaCommandModel } from "../../media-model";
import { ProductAttributeCommandModel } from "./product-attribute";
import { ProductHashTagCommandModel } from "./product-hashtag";
import { ProductInventoryCommandModel } from "./product-inventory";
import { ProductInventoryDimensionCommandModel } from "./product-inventory-dimension";
import { ProductNumberCommandModel } from "./product-number";
import { ProductRestrictionLocationCommandModel, ProductRestrictionOrganisationCommandModel, ProductRestrictionProductCommandModel, ProductRestrictionProductNumberCommandModel, ProductRestrictionRouteCommandModel, ProductRestrictionVehicleCommandModel } from "./product-restriction";
import { ProductValidityCommandModel } from "./product-validity";

export abstract class ProductEditCommandModel {
    public productId: string;
    public productCategoryCode: string;
    public productGroupCode: string;
    public productTypeCode: string;
    public providerId: string;
    public parentProductId: string;
    public productName: string;
    public productDescription: string;
    public supplierId: string;
    public displayCode: string;
    public draftFlag: boolean;
    public finalFlag: boolean;
    public searchName: string;
    public searchUsageTypeCode: string;
    public searchStatusCode: string;
    public filterUserAccountId: string;
    public usageTypeCode: string;
    public isOwnerFilter: boolean;
    public statusCode: string;
    public productHashTags: ProductHashTagCommandModel[];
    public productNumbers: ProductNumberCommandModel[];
    public productRestrictionProducts: ProductRestrictionProductCommandModel[];
    public productRestrictionProductNumbers: ProductRestrictionProductNumberCommandModel[];
    public productRestrictionRoutes: ProductRestrictionRouteCommandModel[];
    public productRestrictionLocations: ProductRestrictionLocationCommandModel[];
    public productValidities: ProductValidityCommandModel[];
    public productAttributes: ProductAttributeCommandModel[];
    public productInventoryDimensions: ProductInventoryDimensionCommandModel[];
    public productInventories: ProductInventoryCommandModel[];
    public productRestrictionOrganisations: ProductRestrictionOrganisationCommandModel[];
    public productRestrictionVehicles?: ProductRestrictionVehicleCommandModel[];
    public languageTranslationSet: LanguageTranslationSetCommandModel;
    public medias: MediaCommandModel[];
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, forkJoin } from "rxjs";
import { CountryReferenceModel } from '../../models/reference-model/reference-general-model';
import { CountryReferenceService } from '../../services/system-services';
import { OrderServices } from '../../services/order-services';
import { ProductPointViewModel } from '../../models/product-model/transport-model';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

const COUNTRY_OPTION = {
    placeholder: "",
    allowClear: true,
    width: 'auto',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    ajax: null
}

@Component({
    selector: 'op-country-selection',
    templateUrl: './country-selection.component.html'
})
export class CountrySelectionComponent implements OnInit {
    private readonly emptySelect2Data: Select2Data = {
        id: '',
        text: '',
        selected: false,
        disabled: false
    }
    public _value: string;
    public _isDisable: boolean;
    public countryReference$ = new BehaviorSubject<Select2Data[]>(null);
    public countryOption = COUNTRY_OPTION;
    public loadSuccess: boolean = false;
    @Input() classError: string = "";

    @Input()
    set data(value: string) {
        this._value = value;
    }

    @Input()
    set disabled(value: boolean) {
        this._isDisable = value;
    }

    @Output() dataChange: EventEmitter<string> = new EventEmitter();

    constructor(private countryReferenceService: CountryReferenceService,
        private orderService: OrderServices) {

    }
    ngOnInit(): void {
        this.loadCountryReferences();
    }

    public loadCountryReferences(prioritizeCountryCodes: string[] = []) {
        forkJoin({
            countryReferences: this.countryReferenceService.getCountryReference(),
            organisationCountryReference: this.countryReferenceService.getByLogonOrganisation(),
            productPoints: this.orderService.getAllProductPoint()
        })
        .subscribe(
            (response) => {
                if (response) {
                    const countryReferences = this.populateCountryReferences(response, prioritizeCountryCodes);
                    const select2Datas = countryReferences
                        ?.map(cr => new Select2Data(cr.countryCode, cr.countryName)) ?? [];
                    this.countryReference$.next([this.emptySelect2Data].concat(select2Datas));
                    this.loadSuccess = true;
                }
            }
        )
    }

    private populateCountryReferences(response: { countryReferences: CountryReferenceModel[]; organisationCountryReference: CountryReferenceModel; productPoints: ProductPointViewModel[]; }, prioritizeCountryCodes: string[]) {
        if (!response) {
            return [];
        }
        let countryReferences = new Array<CountryReferenceModel>();
        this.addPrioritizeCountryReferences(countryReferences, response.countryReferences, prioritizeCountryCodes);
        this.addFromOrganisation(response, countryReferences);
        this.addFromProductPoints(response, countryReferences);
        this.addRestCountryReferences(countryReferences, response);
        return countryReferences;
    }

    private addPrioritizeCountryReferences(
        result: CountryReferenceModel[],
        countryReferences: CountryReferenceModel[],
        prioritizeCountryCodes: string[]) {
        if (!prioritizeCountryCodes?.length) return;
        const prioritizeCountryReferences = countryReferences
            ?.filter(lr => prioritizeCountryCodes.includes(lr.countryCode))
        result.push(...prioritizeCountryReferences);
    }

    private addRestCountryReferences(countryReferences: CountryReferenceModel[], response: { countryReferences: CountryReferenceModel[]; organisationCountryReference: CountryReferenceModel; productPoints: ProductPointViewModel[]; }) {
        const existsCountryCodes = countryReferences.map(cr => cr.countryCode);
        const restCountryReferences = response.countryReferences?.filter(cr => existsCountryCodes.includes(cr.countryCode) == false);
        countryReferences.push(...restCountryReferences);
    }

    private addFromOrganisation(response: { countryReferences: CountryReferenceModel[]; organisationCountryReference: CountryReferenceModel; productPoints: ProductPointViewModel[]; }, countryReferences: CountryReferenceModel[]) {
        if (response.organisationCountryReference) {
            countryReferences.push(response.organisationCountryReference);
        }
    }

    private addFromProductPoints(response: { countryReferences: CountryReferenceModel[]; organisationCountryReference: CountryReferenceModel; productPoints: ProductPointViewModel[]; }, countryReferences: CountryReferenceModel[]) {
        if (response.productPoints?.length) {
            const sortedProductPoints = response.productPoints.sort(this.bySortSequenceThenByName());
            for (const productPoint of sortedProductPoints) {
                const foundCountryReference = countryReferences.find(cr => cr.countryCode == productPoint.countryCode);
                if (foundCountryReference) {
                    continue;
                }
                const productPointCountryReference = response.countryReferences
                    ?.find(cr => cr.countryCode == productPoint.countryCode);
                if (productPointCountryReference) {
                    countryReferences.push(productPointCountryReference);
                } else {
                    countryReferences.push({
                        countryCode: productPoint.countryCode,
                        countryName: productPoint.countryName,
                        countryIsoCode: null,
                        countryIsoNumber: null,
                        countryPhonePrefix: null,
                        languageCode: null,
                        statusCode: 'A',
                        idSelected: null
                    });
                }
            }
        }
    }

    private bySortSequenceThenByName(): (a: ProductPointViewModel, b: ProductPointViewModel) => number {
        return (a, b) => {
            if (a.sortSequence && b.sortSequence) {
                if (a.sortSequence < b.sortSequence) {
                    return -1;
                }
                if (a.sortSequence > b.sortSequence) {
                    return 1;
                }
            }
            return a.countryName.localeCompare(b.countryName);
        };
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    public getSelectedText(): string {
        return this.countryReference$.value
            ?.find(item => item.id == this._value)
            ?.text;
    }
}
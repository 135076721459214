import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { GenderReferenceModel } from "../../models/reference-model/reference-general-model";

@Injectable({
    providedIn: 'root'
})
export class GenderReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = '/system/GenderReferences';

    constructor(
        httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getGenderReferences(): Observable<GenderReferenceModel[]> {
        return this.httpGet<GenderReferenceModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import {
    Component,
    EventEmitter,
    OnInit,
    Input,
    Output,
    SimpleChanges,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';

declare var moment: any;
export const DATE_PICKER_OPTION = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    minDate: null,
    maxDate: null,
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
};

@Component({
    selector: 'op-calendar-icon',
    templateUrl: './calendar-icon.component.html'
})
export class CalendarIconComponent implements OnInit {
    public datePickerOption;
    public _value: string;
    public _showError: boolean = false;

    @Input("errorMessage") errorMessage: string;
    @Input("disabled") disabled: boolean = false;
    @Input("options") customOption: any
    @Input("showError")
    set showError(value: boolean) {
        this._showError = value;
    }
    @Output()
    showErrorChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    set data(value: string) {
        this._value = value;
    }

    @Input() module: string;
    @Input() calendarMidRange: boolean = false;
    @Input() openToDate: Date;

    @Output()
    dataChange: EventEmitter<string> = new EventEmitter();
    @Output() calendarPageChange = new EventEmitter<Date>();
    @ViewChild('dateinput') dateinput: ElementRef;
    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['customOption']) {
            this.datePickerOption = this.getOption();
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnInit(): void {
        this.datePickerOption = this.getOption();
    }
    public clearDepartureDate(event: MouseEvent) {
        event.preventDefault();
        this._value = "";
    }
    public clearCalendar(event: MouseEvent) {
        event.preventDefault();

        if (this.disabled) {
            return;
        }

        this._value = "";
        this.dataChange.emit("");
        this.showErrorChange.emit(this._showError);
    }
    public dateChange(value) {
        this._value = value;
        this.dataChange.emit(value);
    }

    public dateManual() {
        setTimeout(() => {
            if (this.dateinput?.nativeElement?.value?.length == 10) {
                this.dateChange(this._value);
                return;
            }
        })
    }

    public getValidationClass(showError: boolean): string {
        if (showError) {
            return "input-group input-group-calendar builder-dropdown-invalid";
        }
        return "input-group input-group-calendar";
    }
    private showRequiredField(): boolean {
        if (this.errorMessage) {
            return true;
        }
        return false;
    }

    public getOption() {
        if (!this.customOption) {
            return DATE_PICKER_OPTION;
        }
        return this.customOption;
    }

    onCalendarPageChange(date: Date) {
        this.calendarPageChange.emit(date);
    }
}
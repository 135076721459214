import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, share, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ApiServiceBase } from "src/app/core/base/api-service-base";
import { ExternalPaymentModel } from "src/app/core/models/payment-models";
import { PaymentSearch } from ".";
import { OrganisationService } from "src/app/core/services/organisation-services";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { InsightPassengerService } from "src/app/core/services/individul-services";
import { InsightPassengerModel } from "src/app/core/models/individual-model";
import { TranslationService } from "src/app/core/services/translation-services";
import { LanguageTranslationSetViewModel } from "src/app/core/models/language-translation-set-model";

@Injectable({
    providedIn: 'root'
})
export class PaymentCreditInvoiceService extends ApiServiceBase {

    private _externalPaymentModel$ = new BehaviorSubject<ExternalPaymentModel>(null)
    private _paymentSearch$ = new BehaviorSubject<PaymentSearch>(null)
    private _organisationModel$ = new BehaviorSubject<OrganisationModel>(null)
    private _insightPassengerModel$ = new BehaviorSubject<InsightPassengerModel>(null)
    private _languageTranslationSetViewModel$ = new BehaviorSubject<LanguageTranslationSetViewModel[]>([])

    get externalPaymentModel$() {
        return this._externalPaymentModel$.asObservable()
    }

    get paymentSearch$() {
        return this._paymentSearch$.asObservable()
    }

    get organisationModel$() {
        return this._organisationModel$.asObservable()
    }

    get insightPassengerModel$() {
        return this._insightPassengerModel$.asObservable()
    }

    get languageTranslationSetViewModel$() {
        return this._languageTranslationSetViewModel$.asObservable()
    }

    constructor(httpClient: HttpClient,
        authService: AuthService,
        private organisationService: OrganisationService,
        private insightPassengerService: InsightPassengerService,
        private translationService: TranslationService,) {
        super(httpClient, authService);
    }

    selectPaymentSearch(paymentSearch: PaymentSearch) {
        this._paymentSearch$.next(paymentSearch)
    }

    getDatatransInitial(paymentId: string): Observable<ExternalPaymentModel>{
        return this.httpGet<ExternalPaymentModel>('/payment/Datatrans/' + paymentId, null)
            .pipe(
                tap(res => this._externalPaymentModel$.next(res.body)),
                map(res => res.body),
                share(),
            )
    }

    getOrganisationInfo(organisationId: string): Observable<OrganisationModel> {
        return this.organisationService.searchOrganisationById(organisationId)
        .pipe(
            tap(res => this._organisationModel$.next(res)),
            map(res => res),
            share(),
        )
    }

    getIndividualInfo(individualId: string): Observable<InsightPassengerModel> {
        return this.insightPassengerService.getInsightPassenger(individualId)
        .pipe(
            tap(res => this._insightPassengerModel$.next(res)),
            map(res => res),
            share(),
        )
    }

    getLangugeTranslationSet(): Observable<LanguageTranslationSetViewModel[]> {
        return this.translationService.searchLangugeTranslationSet()
        .pipe(
            tap(res => this._languageTranslationSetViewModel$.next(res)),
            map(res => res),
            share(),
        )
    }

    clearPaymentSearch() {
        this._paymentSearch$.next(null)
    }

    clearOrganisationInfo() {
        this._organisationModel$.next(null)
    }
}
import { AfterViewInit, ChangeDetectionStrategy, Component } from "@angular/core";
import { Subject } from "rxjs";
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from "src/app/shared/ui/actionbar";
import { RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { ExportButtonModel } from "src/app/shared/ui/actionbar/models/export-button.model";
import { NavigationService } from "src/app/shared/utils/navigation";
import { takeUntil } from "rxjs/operators";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-standard-schedule-information-content',
    templateUrl: 'standard-schedule-information-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionbarService],
})
export class StandardScheduleInformationContentComponent implements AfterViewInit {
    readonly defaultTitle = 'Standard Schedule Information';

    public title: string = this.defaultTitle;
    public userSecurity: SecurityGroupSecurityModel;

    private unsubscribe$ = new Subject();

    private exportBtn = new ExportButtonModel();
    private actionBarHandler: ActionBarHandlerModel;

    constructor(private navigationService: NavigationService, 
        private actionbarService: ActionbarService,
        private actionService: ActionService) {
            this.getParams();
        }

    ngAfterViewInit(): void {
        this.setupActionbar();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    
        window.scrollTo({top: 0, behavior:'smooth'});
        this.actionService.add(FavoriteConstant.SEARCH_ACTION, null, null)
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    actionbarClick(clickedButton: string) {
        switch(clickedButton) {
            case ACTION_STATUS.back:
                this.back();
                break;
            default:
                break;
        }
    }

    back() {
        const queryParams = {
            tab: 'search'
        }

        this.navigationService.navigate("main/schedule/home", null, false, queryParams);
    }

    private getParams() {
        let params = this.navigationService.getParams();
        this.userSecurity = params?.userSecurity;
    }

    private setupActionbar() {
        this.exportBtn.enable(this.userSecurity?.editFlag ?? false);
        this.actionBarHandler = new ActionBarHandlerModel(
            this.exportBtn, 
            new RefreshButtonModel()
        );
    }
}
export class ProductRestrictionOrganisationViewModel {
    public productRestrictionOrganisationId: string;
    public organisationId: string;
    public organisationTypeCode: string;
    public organisationRoleCode: string;
    public organisationGroupCode: string;
    public excludeFlag: boolean;
    public displaySequence: number;
    public commitLastname: string;
    public commitFirstname: string;
    public commitTitle: string;
    public commitDateTime: Date;
    public hierarchyKey: string;    
    public inherit:boolean;
    public productRestrictionOrganisationTypeCode: string;
}
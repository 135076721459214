import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { select2FormStatus } from "./status-operational-configuration";
import { StatusOperationalConstant } from "./shared/constants/status-operational.constant";
import { ProductOperatingStatusReferenceModel } from "src/app/core/models/reference-model/reference-product-model";

@Component({
    selector: 'status-operational-dropdown',
    templateUrl: './status-operational-dropdown.component.html'
})
export class StatusOperationalDropdownComponent implements OnChanges {

    @Input() status: string;
    @Input() productOperationalStatusReferences: ProductOperatingStatusReferenceModel[];
    @Input() disabled: boolean = false;
    @Input() includeEmpty: boolean = false;
    @Output() updateStatus = new EventEmitter<string>();

    select2DefaultOption = select2FormStatus;
    form = new UntypedFormGroup({
        statusCode: new UntypedFormControl()
    });
    public productOperationalStatusReferenceData: ProductOperatingStatusReferenceModel[] = new Array();
    get statusClass(): string {
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.ACTIVE) {
            return 'active';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.ARRIVED) {
            return 'arrived';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.CANCELLED) {
            return 'cancelled';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.DELAYED) {
            return 'delayed';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.DELIVERED) {
            return 'delivered';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.DEPARTED) {
            return 'departed';
        }
        if (this.form.controls['statusCode'].value == StatusOperationalConstant.INACTIVE) {
            return 'inactive';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["status"]) {
            this.form.controls['statusCode'].patchValue(this.status);
        }

        if (changes["disabled"]) {
            if (this.disabled) {
                this.form.controls['statusCode'].disable();
            } else {
                this.form.controls['statusCode'].enable();
            }
        }

        if (changes["productOperationalStatusReferences"]) {
            this.createProductOperationalReferenceData();
        }
    }

    changeStatus(e) {
        this.form.controls['statusCode'].patchValue(e);
        this.updateStatus.emit(e);
    }

    private createProductOperationalReferenceData() {
        this.productOperationalStatusReferenceData = [];
        if (this.includeEmpty) {
            let empty = {} as ProductOperatingStatusReferenceModel;
            this.productOperationalStatusReferenceData.push(empty);
        }
        if (this.productOperationalStatusReferences?.length) {
            this.productOperationalStatusReferenceData.push(...this.productOperationalStatusReferences);
        }
    }

    get statusCode(): string | string[] {
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashbookTabsComponent } from './cashbook-tabs/cashbook-tabs.component';
import { CashbookContentComponent } from './cashbook-content.component';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { Helper } from 'src/app/shared/helper/app.helper';
import { RouterModule } from '@angular/router';
import { DynamicRoutingModule } from 'src/app/shared/utils/navigation';
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { CashbookSearchComponent } from './cashbook-search/cashbook-search.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CashbookSearchConditionComponent } from './cashbook-search/cashbook-search-condition/cashbook-search-condition.component';
import { CashbookSearchTableComponent } from './cashbook-search/cashbook-search-table/cashbook-search-table.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { MatIconModule } from '@angular/material/icon';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { DxCheckBoxModule, DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AlertModule } from 'ngx-bootstrap/alert';
import { InputFieldModule } from '../../../shared/ui/forms/inputs/input-field/input-field.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";


import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { CashbookDetailContentComponent } from './cashbook-detail-content/cashbook-detail-content.component';
import { CashbookDetailComponent } from './cashbook-detail-content/cashbook-detail/cashbook-detail.component';
import { CashbookChargeItemComponent } from './cashbook-detail-content/cashbook-charge-item/cashbook-charge-item.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ChargeItemComponent } from './cashbook-detail-content/cashbook-charge-item/charge-item/charge-item.component';
import { CashbookDocumentComponent } from './cashbook-document/cashbook-document.component';
import { CashbookDetailTFOPComponent } from './cashbook-document/cashbook-detail-tfop/cashbook-detail-tfop.component';
import { CashbookDetailTbcComponent } from './cashbook-document/cashbook-detail-tbc/cashbook-detail-tbc.component';
import { CashbookDetailFopComponent } from './cashbook-document/cashbook-detail-fop/cashbook-detail-fop.component';
import { CashbookDetailFopapcComponent } from './cashbook-document/cashbook-detail-fopapc/cashbook-detail-fopapc.component';
import { FinanceHomeComponent } from './finance-home/finance-home.component';
import { FinanceCategoriesComponent } from './finance-home/finance-categories/finance-categories.component';
import { OopsCategoriesModule } from 'src/app/core/components/categories';
import { EmailModule } from 'src/app/core/components/email/email.module';
import { LoadingSpinnerModule } from 'src/app/shared/layout/loading-spinner';
import { FinanceContextMenuComponent } from './finance-home/finance-categories/finance-context-menu/finance-context-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { StatusBadgeModule } from 'src/app/shared/ui/forms/inputs/status-badge/status-badge.module';

@NgModule({
  declarations: [
    CashbookTabsComponent,
    CashbookContentComponent,
    CashbookSearchComponent,
    CashbookSearchConditionComponent,
    CashbookSearchTableComponent,
    CashbookDetailContentComponent,
    CashbookDetailComponent,
    CashbookChargeItemComponent,
    ChargeItemComponent,
    CashbookDocumentComponent,
    CashbookDetailTFOPComponent,
    CashbookDetailTbcComponent,
    CashbookDetailFopComponent,
    CashbookDetailFopapcComponent,
    FinanceHomeComponent,
    FinanceCategoriesComponent,
    FinanceContextMenuComponent
  ],
  imports: [
    AlertBarModule,
    AlertModule,
    BsDropdownModule,
    CollapseModule,
    CommonModule,
    DaterangepickerModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DynamicRoutingModule,
    EmailModule,
    FocusingModule,
    FormsModule,
    FreezingTabModule,
    InputFieldModule,
    ItemsFoundModule,
    LoadingSpinnerModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    NgbModule,
    NgbNavModule,
    OopsCategoriesModule,
    PanelsModule,
    ReactiveFormsModule,
    RouterModule,
    Select2Module,
    StatusBadgeModule,
    TooltipModule,
    UiModule,
  ],
  providers: [
    ActionbarService,
    Helper
  ],
  exports: [
    CashbookTabsComponent,
    CashbookContentComponent
  ]
})
export class CashbookContentModule { }

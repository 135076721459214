import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { RulesConfigComponent } from 'src/app/core/components/rules-config/rules-config.component';
import { ValidityModel } from 'src/app/core/models/merchandizing-config';
import {
    CalendarValidityReferenceModel,
    ConditionReferenceModel,
    DateTimeDimensionReferenceModel
} from 'src/app/core/models/reference-model/reference-general-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { ProductValidityViewModel } from 'src/app/core/models/product-model/product-base-model/product-validity';

@Component({
    selector: 'op-validity',
    templateUrl: './validity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidityComponent {

    public readonly ERROR_REQUIRED: string = "Validity is required.";
    public readonly ERROR_DUPLIATE: string = "Duplicate row.";
    public readonly ERROR_OVERLAP: string = "Date overlap.";
    public readonly ERROR_NOT_SPECIFY: string = 'Please specify start and end period.';
    public readonly ERROR_END_MORETHAN_START: string = 'Start date must less than or equal end date.';
    private _jsonURL = 'assets/data/flight/attributes&rules_config.json';
    @Input() calendarValidityReference$ = new BehaviorSubject<CalendarValidityReferenceModel[]>(null);
    @Input() dateTimeDimensionReference$ = new BehaviorSubject<DateTimeDimensionReferenceModel[]>(null);
    @Input() conditionReference$ = new BehaviorSubject<ConditionReferenceModel[]>(null);
    @Input() allowAdd: boolean = true;
    @ViewChild(RulesConfigComponent) ruleComponent: RulesConfigComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @Input() validityConfig: ValidityModel;
    @Output() formStatus = new EventEmitter<Boolean>();
    @Input() isRoot: boolean = true;
    public focusing: boolean = false;
    public limitationFlag: boolean = false;

    constructor(private http: HttpClient,
        private changeDetectorRef: ChangeDetectorRef) {
        this.getJSON().subscribe(data => {
            this.validityConfig = data.validity;
            this.changeDetectorRef.detectChanges();
        });
    }

    private getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

    get controlLength(): number {
        if (this.ruleComponent) {
            return this.ruleComponent.forms.controls.length;
        }
        return 0;
    }

    public add() {
        this.ruleComponent.add();
    }

    public detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    get inheritControlLength(): number {
        if (this.ruleComponent?.validityInheritComponent) {
            return this.ruleComponent?.validityInheritComponent?.
            inheritforms?.controls?.length;
        }
        return 0;
    } 
    
    public addRequiredValidity() {
        let validityRequired = new ProductValidityViewModel();
        let validities: ProductValidityViewModel[] = new Array();
        validityRequired.calendarValidityCode = "SERVICE";
        validityRequired.dateTimeDimensionCode = "DATE";
        validityRequired.utcFlag = false;
        validityRequired.conditionCode = "><";
        validities.push(validityRequired);
        this.ruleComponent.addValidityToFormGroup(validities, true);
    }

    public onFormStatusChange(formStatus: boolean) {
        this.formStatus.emit(formStatus);
    }
}
import { NgModule } from "@angular/core";
import { DocumentDistributionContentComponent } from "./document-distribution-content.component";
import { CommonModule } from "@angular/common";
import { UiModule } from "src/app/shared/ui/ui.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxTemplateModule } from "devextreme-angular";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { DocumentDistributionTabsComponent } from "./document-distribution-tabs/document-distribution-tabs.component";
import { DocumentDistributiobSearchComponent } from "./document-distribution-search/document-distribution-search.component";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { DocumentDistributionSearchConditionComponent } from "./document-distribution-search/document-distribution-search-condition/document-distribution-search-condition.component";
import { DocumentDistributionSearchTableComponent } from "./document-distribution-search/document-distribution-search-table/document-distribution-search-table.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { DocumentDistributionDetailComponent } from "./document-distribution-detail/document-distribution-detail.component";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { CalendarIconModule } from "src/app/core/components/calendar-icon";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { NumberOnlyModule } from "src/app/shared/ui/forms/inputs/number-only/number-only.module";
import { DocumentDistributionRuleComponent } from "./document-distribution-detail/document-distribution-rule/document-distribution-rule.component";
import { DocumentDistributionRuleDetailComponent } from "./document-distribution-detail/document-distribution-rule/detail/rule-detail.component";
import { DocumentDistributionRuleTableComponent } from "./document-distribution-detail/document-distribution-rule/table/rule-table.component";
import { RuleDetailDirective } from "./document-distribution-detail/document-distribution-rule/detail/rule-detail.directive";
import { DocumentDistributionEventComponent } from "./document-distribution-detail/document-distribution-event/document-distribution-event.component";
import { DocumentDistributionDetailGeneralComponent } from "./document-distribution-detail/document-distribution-detail-general/document-distribution-detail-general.component";
import { DocumentDistributionAttributeComponent } from "./document-distribution-detail/document-distribution-attribute/document-distribution-attribute.component";
import { OopsChoiceValueModule } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module";
import { OopsCustomValidatorModule } from "src/app/core/validators";
import { AlertBarModule } from "src/app/shared/layout/alertbar";


@NgModule({
    declarations: [
        DocumentDistributionAttributeComponent,
        DocumentDistributionContentComponent,
        DocumentDistributionDetailComponent,
        DocumentDistributionDetailGeneralComponent,
        DocumentDistributionEventComponent,
        DocumentDistributionRuleComponent,
        DocumentDistributionRuleDetailComponent,
        DocumentDistributionRuleTableComponent,
        DocumentDistributiobSearchComponent,
        DocumentDistributionTabsComponent,
        DocumentDistributionSearchTableComponent,
        DocumentDistributionSearchConditionComponent,
        RuleDetailDirective
    ],
    imports: [
        AlertBarModule,
        BsDropdownModule,
        CalendarIconModule,
        CollapseModule,
        CommonModule,
        DaterangepickerModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxTemplateModule,
        FreezingTabModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        MatMenuModule,
        NumberOnlyModule,
        NavigationIndexModule,
        NgbNavModule,
        OopsChoiceValueModule,
        OopsCustomValidatorModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    providers: [],
    exports: [
        DocumentDistributionContentComponent,
        DocumentDistributiobSearchComponent
    ]
})

export class DocumentDistributionContentModule { }

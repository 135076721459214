import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { DatatableComponentBase } from "src/app/core/base/datatable-component-base";
import { ScheduleQueueSearchModel } from "src/app/core/models/schedule-queue-model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { ScheduleQueueService } from "src/app/core/services/schedule-services";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { LoadingNotifier, LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { ScheduleQueueGridService } from "./shared/schedule-queue-grid-service";
import { ScheduleQueueMapperService } from "./shared/schedule-queue-mapper.service";

declare var $: any;

@Component({
    selector: 'op-schedule-queue-table',
    templateUrl: './schedule-queue-table.component.html',
    providers: [ScheduleQueueMapperService, ScheduleQueueGridService]
})
export class ScheduleQueueTableComponent extends DatatableComponentBase implements OnInit, AfterViewInit {
    readonly COMPLETE_PROGRESS = "Completed"
    readonly SPINNER_NAME: string = "scheduleQueueTableSpinner";
    readonly SPINNER_FULL_SCREEN: boolean = false;

    public scheduleQueueSearchData: ScheduleQueueSearchModel[];
    public header: string[];
    public rows = 0;
    public selectedItem = false;
    public itemSelected: any;
    public exportData: any;
    public loadingNotifier = new LoadingNotifier();

    @Input() condition: ScheduleQueueSearchModel;
    @Input() executeFlag: boolean = true;
    @Input() userSecurity: SecurityGroupSecurityModel;
    
    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private scheduleQueueService: ScheduleQueueService,
        private loadingSpinnerService: LoadingSpinnerService,
        private dateConverterService: DateConverterService,
        private scheduleQueueGridService: ScheduleQueueGridService) {
        super()
    }

    ngOnInit(): void {
        if (this.executeFlag) {
            this.getScheduleQueueData(this.condition);
        }
    }
 
    public cleanDatatable() {
        this.destroyDatatable();
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.exportData = this.getExportData();
    }

    public getScheduleQueueData(condition: ScheduleQueueSearchModel) {
        let dynamicDataArray = new Array();

        this.loadingSpinnerService.show();
        this.scheduleQueueService.getByCondition(condition)
            .subscribe(
                (responses: ScheduleQueueSearchModel[]) => {
                    this.cleanDatatable();
                    responses.forEach(item => {
                        let scheduleTemps = {};
                        scheduleTemps["productNumber"] = item.providerIataCode + ' ' + item.productNumber;
                        scheduleTemps["id"] = item.scheduleQueueId;
                        scheduleTemps["originLocationCode"] = item.originLocationCode;
                        scheduleTemps["destinationLocationCode"] = item.destinationLocationCode;
                        scheduleTemps["schedulePeriodFrom"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.startDateTime)?.toUpperCase();
                        scheduleTemps["schedulePeriodTo"] = this.dateConverterService.dateTimeShortDateWithMonthNameFormat(item.endDateTime)?.toUpperCase();
                        scheduleTemps["weekdays"] = item.weekdays;
                        scheduleTemps["departureTime"] =  this.dateConverterService.convertTime24(item.productScheduledDepartureTime);
                        scheduleTemps["arrivalTime"] = this.dateConverterService.convertTime24(item.productScheduledArrivalTime);
                        scheduleTemps["vehicleType"] = item.vehicleTypeCode;
                        scheduleTemps["vehicleComp"] = item.vehicleCompositionCode;
                        scheduleTemps["action"] = item.scheduleQueueActionCode;
                        scheduleTemps["process"] = this.setProcessData(item);
                        scheduleTemps["progress"] = item.processStatusCode;
                        scheduleTemps["resultCode"] = item.scheduleQueueResultCode;
                        scheduleTemps["result"] = item.scheduleQueueResultName ?? item.scheduleQueueResultCode;
                        scheduleTemps["scheduleQueueTasks"] = item.scheduleQueueTasks;
                        dynamicDataArray.push(scheduleTemps);
                    });

                    this.loadingSpinnerService.hide();
                    this.scheduleQueueSearchData = dynamicDataArray;
                    this.rows = this.scheduleQueueSearchData.length;
                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                }   
            )
    }

    private setProcessData(item: ScheduleQueueSearchModel): string {
        if (item.scheduledStartDateTime && !item.autoProcessFlag) {
            return this.dateConverterService.formatDateYearToSecond(item.scheduledStartDateTime);
        }

        if (item.autoProcessFlag) {
            return "Auto";
        }

        return "";
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'result') {
            this.scheduleQueueGridService.setResultCellColor(e);
        } 

        if (e.column.dataField == 'progress') {
            this.setProgressCellColor(e)
        }
    }

    private setProgressCellColor(e) {
        switch (e.data.progress) {
            case this.COMPLETE_PROGRESS:
                e.cellElement.addClass('dx-cell-success');
                break;           
        }
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public refreshTable() {
        this.getScheduleQueueData(this.condition);
    }
}
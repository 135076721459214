import { 
    AfterViewChecked,
    ChangeDetectorRef, 
    Component, 
    EventEmitter,
    Input,
    OnInit,
    Output, 
    QueryList, 
    ViewChild,
    ViewChildren } from '@angular/core';

import { Observable, of } from 'rxjs';

import { FocusingDirective } from '../../../../../shared/ui/forms/inputs/focusing.directive';
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { OrderDetailCommentModel, OrderDetailIndividualModel } from '../../../../../core/models/order-model';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import { 
    OrdersMapperService, 
    OrderDetailChildLoadData,
    OrderDetailCommentView
} from '../..';
import { map } from 'rxjs/operators';
import { GlobalStateService } from 'src/app/shared/ui/uiservice/global-state.service';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { MediaFileView } from 'src/app/core/components/media/shared/media-file.view';
import { cloneDeep } from 'lodash';
import { OrderDetailCommentListActionbarComponent } from './order-detail-comment-list-actionbar/order-detail-comment-list-actionbar.component';

@Component({
    selector: 'op-order-detail-comment-list',
    templateUrl: './order-detail-comment-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailCommentListComponent implements AfterViewChecked, OnInit {
    
    public readonly SPINNER_NAME: string = "orderDetailComment";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;
    @ViewChildren(OrderDetailCommentListActionbarComponent) actionBars: QueryList<OrderDetailCommentListActionbarComponent>;

    @Input("id") id: string;
    @Input("data") commentsData: OrderDetailCommentView[];
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input() rootOrderId: string;
    @Input() parentOrderId: string;
    @Input() disableAdd: boolean = false;
    @Input("disableActionButton") disableActionButton: boolean = false;

    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderDetailCommentViews: OrderDetailCommentView[] = new Array<OrderDetailCommentView>();
    public orderLock: boolean;
    
    get countNumberOfItems(): number {
        return this.orderDetailCommentViews?.length || 0;
    }

    get addNewButtonDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.ADD_NEW_BUTTON_ID);
    }

    addNewComment: OrderDetailCommentView;
    addNewMediaFiles: MediaFileView[] = [];

    editComments: OrderDetailCommentView[] = [];
    editMediaFiles: MediaFileView[][] = [];

    private readonly ADD_NEW_BUTTON_ID = this.stringHelperService.NewGuid();
    private orderDetailIndividualModels: OrderDetailIndividualModel[] = [];
    private mediaFiles: MediaFileView[][] = [];

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService,
        private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService) {
        this.globalStateService.register(this.ADD_NEW_BUTTON_ID);
    }
    ngAfterViewChecked(): void {
        this.toggleDisableActionButton();
    }
    ngOnInit(): void {
        this.orderDetailCommentViews = this.commentsData;
        this.mediaFiles = this.orderDetailCommentViews?.map(_ => []) || [];
        this.checkCollapse();
        this.onDisableNewButton();
    }

    private checkCollapse(): void {
        if (!this.orderDetailCommentViews || this.orderDetailCommentViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public startLoading() {
        this.loadingNotifier.show(this.SPINNER_NAME);
    }
    public stopLoading() {
        this.loadingNotifier.hide(this.SPINNER_NAME);
    }

    public loadOrderDetailComment(orderId: string, orderLock: boolean, orderStateCode: string): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailComments(orderId, orderStateCode)
            .subscribe(
                (result: OrderDetailCommentModel[]) => {
                    this.orderDetailCommentViews = this.mapperService.toOrderDetailCommentViews(result);
                    this.mediaFiles = this.orderDetailCommentViews?.map(_ => []) || [];
                    this.childLoadCompleted.emit({
                        recordCount: result?.length
                    });
                    this.checkCollapse();
                    this.changeDetection.detectChanges();
                    observer.next(true);
                    observer.complete();
                }
            );
        });
    }
    public loadOrderDetailCommentFromOrder(orderId: string, commentOrderId: string, orderLock: boolean): Observable<boolean> {
        return new Observable(observer => {
            if (!orderId) {
                observer.next(false);
                observer.complete();
                return;
            }
            this.orderLock = orderLock;
            this.orderDetailService.getOrderDetailCommentsFromCommentOrder(orderId, commentOrderId)
            .subscribe(
                (result: OrderDetailCommentModel[]) => {
                    this.orderDetailCommentViews = this.mapperService.toOrderDetailCommentViews(result);
                    this.mediaFiles = this.orderDetailCommentViews?.map(_ => []) || [];
                    this.childLoadCompleted.emit({
                        recordCount: result?.length
                    });
                    this.checkCollapse();
                    this.changeDetection.detectChanges();
                    observer.next(true);
                    observer.complete();
                },
                () => {
                    observer.next(false);
                    observer.complete();
                }
            );
        });
    }
    public loadOrderDetailCommentViews(comments: OrderDetailCommentView[], orderLock: boolean): void {
        if (!comments || comments.length == 0) {
            return;
        }
        this.orderLock = orderLock;
        this.orderDetailCommentViews = comments
        this.mediaFiles = this.orderDetailCommentViews?.map(_ => []) || [];
    }
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
    getOrderDetailIndividual$(partnerOrderId: string): Observable<OrderDetailIndividualModel> {
        if (Object.keys(this.orderDetailIndividualModels).includes(partnerOrderId)) {
            return of(this.orderDetailIndividualModels[partnerOrderId]);
        }
        return this.orderDetailService.getOrderDetailPartner(this.rootOrderId, partnerOrderId)
            .pipe(
                map(res => {
                    const model = res?.[0];
                    this.orderDetailIndividualModels[partnerOrderId] = model;
                    return model;
                })
            )
    }
    onEditClick(view: OrderDetailCommentView, index: number) {
        this.openEditPanel(view, index);
        this.globalStateService.disableAll();
    }
    onDeleteClick(index: number) {
        this.deleteRow(index);
    }
    onNewClick() {
        this.openNewPanel();
        this.globalStateService.disableAll();
    }
    onApplyNewClick(view: { data: OrderDetailCommentView, files: MediaFileView[] }) {
        this.addAppliedCommentToViews(view);
        this.closeNewPanel();
        this.globalStateService.enableAll();
    }
    onApplyEditClick(view: { data: OrderDetailCommentView, files: MediaFileView[] }, index: number) {
        if (view) {
            this.applyCommentData(view.data, index);
            this.mediaFiles[index] = view.files;
        }
        this.closeEditPanel(index);
        this.globalStateService.enableAll();
    }
    
    onCloseNewClick() {
        this.closeNewPanel();
        this.globalStateService.enableAll();
    }
    onCloseEditClick(index: number) {
        this.closeEditPanel(index);
        this.globalStateService.enableAll();
    }

    private applyCommentData(data: OrderDetailCommentView, index: number) {
        if (!this.orderDetailCommentViews[index]) {
            this.orderDetailCommentViews[index] = data;
            return;
        }
        const view = this.orderDetailCommentViews[index];
        view.orderCommentId = data.orderCommentId;
        view.commentTypeCode = data.commentTypeCode;
        view.commentTypeName = data.commentTypeName;
        view.commentClassificationCode = data.commentClassificationCode;
        view.commentClassificationName = data.commentClassificationName;
        view.commentText = data.commentText;
        view.commentDate = data.commentDate;
        view.commentDateTime = data.commentDateTime;
        view.commentByName = data.commentByName;
        view.parentOrderId = data.parentOrderId;
        view.individualId = data.individualId;
        view.orderId = data.orderId;
        view.commentId = data.commentId;
    }

    private closeEditPanel(index: number) {
        this.editComments[index] = null;
        this.editMediaFiles[index] = [];
    }
    private addAppliedCommentToViews(view: { data: OrderDetailCommentView; files: MediaFileView[]; }) {
        if (view) {
            if (view.data) {
                view.data.parentOrderId = this.parentOrderId;
                this.orderDetailCommentViews = this.orderDetailCommentViews || [];
                this.orderDetailCommentViews.push(cloneDeep(view.data));
            }
            this.mediaFiles.push(view.files);
        }
    }
    private openNewPanel() {
        this.addNewComment = this.getEmptyView();
        this.addNewMediaFiles = [];
    }
    private closeNewPanel() {
        this.addNewComment = null;
        this.addNewMediaFiles = [];
    }
    private getEmptyView(): OrderDetailCommentView {
        return {
            orderCommentId: null,
            commentTypeCode: null,
            commentTypeName: null,
            commentClassificationCode: null,
            commentClassificationName: null,
            commentText: null,
            commentDate: null,
            commentDateTime: null,
            commentByName: null,
            showDetail: true,
            parentOrderId: null,
            individualId: null,
            orderId: null,
            commentId: null
        }
    }
    private openEditPanel(view: OrderDetailCommentView, index: number) {
        this.editComments[index] = cloneDeep(view);
        this.editMediaFiles[index] = this.mediaFiles[index] || [];
    }
    private deleteRow(index: number) {
        this.orderDetailCommentViews = this.orderDetailCommentViews.filter((_, i) => i != index);
    }

    private onDisableNewButton() {
        if (this.disableAdd) {
            this.globalStateService.disable(this.ADD_NEW_BUTTON_ID);
        }
    }
    private toggleDisableActionButton(): void {
        if (!this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => {
            actionBar.disableActionButton(this.disableActionButton);
        })
    }
}
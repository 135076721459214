import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef, AfterViewInit, HostListener } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { PriceRuleModel } from "src/app/core/models/pricing-model";
import { PriceRuleCategoryReferenceService, PriceRuleGroupReferenceService, PriceRuleTypeGroupService, StatusReferenceService } from "src/app/core/services/system-services";
import { UsageTypeReferenceService } from "src/app/core/services/system-services/usage-type-reference.service";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PricingConverter } from "../../../shared/pricing.converter";
import { select2DefaultOption, select2DefaultOptionWithAllowClear } from "../../../shared/search/shared/search-information-configuration";
import { PriceRuleView } from "../../../shared/views/price-rule.view";

@Component({
    selector: 'op-price-rule-search-condition',
    templateUrl: './search-condition.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PricingConverter,
        ToastrService
    ],
    styles: [
        `
        .loading-spinner {
            margin-top: 12px;
            position: absolute;
            right: 34px;
        }
        `
    ]
})

export class SearchConditionComponent extends OopsComponentFormBase implements OnChanges {
    private readonly DEFAULT_PRICE_RULE_GROUP_CODE = 'PRICE';
    private readonly FILTER_USAGE_TYPE = 'FILTER';
    @Input() classIcon: string;
    @Input() advanceSearchMode: boolean;
    @Input() priceRuleSearchFilterId: string = null;
    @Input() priceRuleView: PriceRuleView;
    @Input() priceRuleSearchFilters: Select2Data[];
    
    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onSearch = new EventEmitter<PriceRuleModel>();
    @Output() onClear = new EventEmitter();
    @Output() priceRuleSearchFilterIdChange = new EventEmitter<string>();
    @Output() priceRuleViewChange = new EventEmitter<PriceRuleView>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public blurAfterSelected: boolean = true;

    focusing: boolean = false;
    select2DefaultOption = select2DefaultOptionWithAllowClear;
    select2DefaultOptionMandatory = select2DefaultOption;
    
    priceRuleTypeGroups$: Observable<Select2Data[][]> = this.getPriceRuleTypeGroups();
    priceRuleCategoryReferences$: Observable<Select2Data[]> = this.getPriceRuleCategoryReferences();
    priceRuleGroupReferences$: Observable<Select2Data[][]> = this.getPriceRuleGroupReferences();
    usageTypeReferences$ = this.usageTypeReferenceService.getUsageTypeReferences()
        .pipe(map(res => res.map(r => new Select2Data(r.usageTypeCode, r.usageTypeName))));
    statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))));


    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get priceRuleCategoryCode(): string {
        return this.form.controls['priceRuleCategoryCode'].value;
    }

    set priceRuleCategoryCode(value: string) {
        this.form.controls['priceRuleCategoryCode'].patchValue(value);
    }

    get priceRuleGroupCode(): string {
        return this.form.controls['priceRuleGroupCode'].value;
    }

    set priceRuleGroupCode(value: string) {
        this.form.controls['priceRuleGroupCode'].patchValue(value);
    }

    get priceRuleTypeCode(): string {
        return this.form.controls['priceRuleTypeCode'].value;
    }

    set priceRuleTypeCode(value: string) {
        this.form.controls['priceRuleTypeCode'].patchValue(value);
    }
    
    constructor(
        fb: UntypedFormBuilder,
        private priceRuleCategoryReferenceService: PriceRuleCategoryReferenceService,
        private statusReferenceService: StatusReferenceService,
        private priceRuleTypeGroupService: PriceRuleTypeGroupService,
        private priceRuleGroupReferenceService: PriceRuleGroupReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private changeDetectorRef: ChangeDetectorRef,
        private actionService: ActionService) {
            super(fb);
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes["priceRuleView"]) {
            let view = this.priceRuleView;
            this.priceRuleSearchFilterId = view.priceRuleId ?? this.priceRuleSearchFilterId;
            if (this.formGroup) {
                this.formGroup.patchValue({
                    priceRuleCategoryCode: view.priceRuleCategoryCode,
                    priceRuleGroupCode: view.priceRuleGroupCode,
                    priceRuleTypeCode: view.priceRuleTypeCode,
                    usageTypeCode: view.searchUsageTypeCode,
                    statusCode: view.searchStatusCode,
                    priceRuleCode: view.priceRuleCode,
                    priceRuleName: view.searchName,
                    priceRuleDisplayCode: view.priceRuleDisplayCode,
                    priceRuleDisplayName: view.priceRuleDisplayName
                })
            }
        }
    }

    public initForm() {
        let view = this.priceRuleView;
        this.formGroup = new UntypedFormGroup({
            priceRuleCategoryCode: new UntypedFormControl(view.priceRuleCategoryCode, Validators.required),
            priceRuleGroupCode: new UntypedFormControl(view.priceRuleGroupCode ?? this.DEFAULT_PRICE_RULE_GROUP_CODE, Validators.required),
            priceRuleTypeCode: new UntypedFormControl(view.priceRuleTypeCode),
            usageTypeCode: new UntypedFormControl(null),
            statusCode: new UntypedFormControl(view.searchStatusCode),
            priceRuleCode: new UntypedFormControl(null),
            priceRuleName: new UntypedFormControl(null),
            priceRuleDisplayCode: new UntypedFormControl(null),
            priceRuleDisplayName: new UntypedFormControl(null)
        })
        if (this.priceRuleSearchFilterId) {
            this.onPriceRuleSearchFilterIdChange(this.priceRuleSearchFilterId);
        }
    }

    private getPriceRuleGroupReferences() {
        return this.priceRuleGroupReferenceService
            .getPriceRuleGroupReferences()
            .pipe(map(
                res => {
                    let result = [];
                    res.map(item => {
                        return {
                            key: item.priceRuleCategoryCode,
                            value: res.filter(r => r.priceRuleCategoryCode == item.priceRuleCategoryCode)
                        }
                    }).forEach(m => {
                        result[m.key] = m.value.map(r => new Select2Data(r.priceRuleGroupCode, r.priceRuleGroupName))
                    })
                    return result;
                }
            ));
    }
        
    private getPriceRuleCategoryReferences() {
        return this.priceRuleCategoryReferenceService.getPriceRuleCategoryReferences()
            .pipe(map(
                res => {
                    return res.map(r => new Select2Data(r.priceRuleCategoryCode, r.priceRuleCategoryName));
                }
            ));
    }

    private getPriceRuleTypeGroups() {
        return this.priceRuleTypeGroupService
            .getPriceRuleTypeGroups()
            .pipe(map(
                res => {
                    let result = [];
                    res.map(item => {
                        return {
                            key: item.priceRuleGroupCode,
                            value: res.filter(r => r.priceRuleGroupCode == item.priceRuleGroupCode)
                        }
                    }).forEach(m => {
                        result[m.key] = m.value.map(r => new Select2Data(r.priceRuleTypeCode, r.priceRuleTypeGroupName))
                    })
                    return result;
                }
            ));
    }

    onAdvanceSearchClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    onPriceRuleCategoryCodeChange(priceRuleCategoryCode: any) {
        this.priceRuleCategoryCode = priceRuleCategoryCode;
        forkJoin({
            priceRuleGroupReferences: this.priceRuleGroupReferences$,
            priceRuleTypeGroups: this.priceRuleTypeGroups$
        }).subscribe(
            ({
                priceRuleGroupReferences,
                priceRuleTypeGroups
            }) => {
                this.priceRuleGroupCode = priceRuleGroupReferences[this.priceRuleCategoryCode][0].id;
                this.priceRuleTypeCode = priceRuleTypeGroups[this.priceRuleGroupCode][0].id;
                this.onPriceRuleTypeCodeChange(this.priceRuleTypeCode);
                this.changeDetectorRef.detectChanges();
            }
        )
    }
    
    onPriceRuleGroupChange(priceRuleGroupCode: any) {
        this.priceRuleGroupCode = priceRuleGroupCode;
        this.priceRuleTypeGroups$.subscribe(
            (priceRuleTypeGroups) => {
                this.priceRuleTypeCode = priceRuleTypeGroups[this.priceRuleGroupCode][0].id;
                this.onPriceRuleTypeCodeChange(this.priceRuleTypeCode);
                this.changeDetectorRef.detectChanges();
            }
        )
    }

    public doSearch() {
        this.onSearch.emit(this.getValues());
    }

    clearCondition() {
        this.priceRuleSearchFilterId = null;
        this.onClear.emit();
    }

    onPriceRuleSearchFilterIdChange(priceRuleId: any) {
        this.priceRuleSearchFilterId = priceRuleId;
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/PRICERULE', priceRuleId, this.getSearchFilterName(priceRuleId));
        this.priceRuleSearchFilterIdChange.emit(priceRuleId);
    }

    public getValues(): PriceRuleModel {
        if (!this.validForm()) {
            return null;
        }
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): PriceRuleModel {
        let model = {} as PriceRuleModel;
        model.priceRuleCode = this.getFormControlValue("priceRuleCode");
        model.searchName = this.getFormControlValue("priceRuleName");
        model.priceRuleDisplayCode = this.getFormControlValue("priceRuleDisplayCode");
        model.priceRuleDisplayName = this.getFormControlValue("priceRuleDisplayName");
        model.searchStatusCode = this.getFormControlValue("statusCode");
        model.searchUsageTypeCode = this.getFormControlValue("usageTypeCode");
        model.priceRuleTypeCode = this.getFormControlValue("priceRuleTypeCode");
        model.priceRuleCategoryCode = this.getFormControlValue("priceRuleCategoryCode");
        model.priceRuleGroupCode = this.getFormControlValue("priceRuleGroupCode");
        model.statusCode = 'A';
        model.usageTypeCode = this.FILTER_USAGE_TYPE;
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }

    public onPriceRuleTypeCodeChange(priceRuleTypeCode: any) {
        this.priceRuleTypeCode = priceRuleTypeCode;
        this.priceRuleView.priceRuleTypeCode = priceRuleTypeCode;
        this.priceRuleView.priceRuleGroupCode = this.priceRuleGroupCode;
        this.priceRuleView.priceRuleCategoryCode = this.priceRuleCategoryCode;
        this.priceRuleViewChange.emit(this.priceRuleView)
    }

    public getSearchFilterName(id: string) {
        return this.priceRuleSearchFilters?.find(item => item.id == id)?.text;
    }

    public onValueChange(value: any, field: UntypedFormGroup, fieldName: string) {
        field.get(fieldName).patchValue(value);
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.doSearch();
                break;
            default:
                break;
        }
    }

    @HostListener('document:keyup', ['$event'])
    onEnterKey(event: KeyboardEvent): void {
        this.keyDown(event)
    }
}
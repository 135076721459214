import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { AttributeSearchModel } from '../../models/attribute-type-reference-model';

@Injectable({
    providedIn: 'root'
})
export class AttributeTypeReferenceService extends ApiServiceBase {

    private readonly PATH = "/system/AttributeTypeReferences";

    constructor(httpClient: HttpClient,
        authService: AuthService) {
          super(httpClient, authService);
        }

    public searchAttributes(code: string, 
        name: string, 
        domainCode: string, 
        mapping: string, 
        categoryCode: string, 
        groupCode: string, 
        typeCode: string, 
        statusCode: string): Observable<AttributeSearchModel[]> {

        let param = new HttpParams()
            .set("code", code)
            .set("name", name)
            .set("domainCode", domainCode)
            .set("mapping", mapping)
            .set("categoryCode", categoryCode)
            .set("groupCode", groupCode)
            .set("typeCode", typeCode)
            .set("statusCode", statusCode);
        return this.httpGet<AttributeSearchModel[]>(this.PATH, param)
            .pipe(
                map(response => response.body)
            )
    }
}
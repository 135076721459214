import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxDataGridModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { CalendarIconModule } from "src/app/core/components/calendar-icon";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { InsightMembershipDetailComponent } from "./detail/membership-detail.component";
import { MembershipDetailDirective } from "./detail/membership.directive";
import { InsightDetailMembershipComponent } from "./insight-detail-membership.component";
import { InsightMembershipTableComponent } from "./table/membership-table.component";

@NgModule({
    declarations: [
        InsightDetailMembershipComponent,
        InsightMembershipDetailComponent,
        InsightMembershipTableComponent,
        MembershipDetailDirective
    ],
    exports: [
        InsightDetailMembershipComponent,
        InsightMembershipDetailComponent,
        InsightMembershipTableComponent,
        MembershipDetailDirective
    ],
    imports: [
        ButtonsModule,
        CalendarIconModule,
        CollapseModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        DxDataGridModule,
        DxTemplateModule,
        MatIconModule,
        PanelsModule,
        Select2Module,
        UiModule
    ],
    providers: []
})
export class MembershipModule {    
}
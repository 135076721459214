import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { NavigationService } from "src/app/shared/utils/navigation";
import { PaymentSearch, PaymentSearchLedger } from "../payment-search.model";
import { PaymentSearchService } from "../payment-search.service";
import { PaymentCreditInvoiceService } from "../payment-credit-invoice.service";
import { OrganisationModel } from "src/app/core/models/organisation-model";
import { filter } from "rxjs/operators";
import { InvoiceIndividualDetailModel } from "src/app/core/models/individual-model";
import { PaymentAddress } from "../payment-credit-invoice.model";
import { AddressModel } from "src/app/core/components/address/shared/address.model";
import { InvoiceIndividualDetailMapperService } from 'src/app/modules/order/shared/components/payment-document-invoice/individual-document-invoice/invoice-individual-detail/invoice-individual-detail-mapper.service';
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { LanguageTranslationSetViewModel } from "src/app/core/models/language-translation-set-model";

const select2Options = {
    disabled: true
}

@Component({
    selector: 'op-payment-credit-invoice-pay',
    templateUrl: './payment-credit-invoice-pay.component.html'
})
export class PaymentCreditInvoicePayComponent implements OnDestroy {

    organisationInfo$: Observable<OrganisationModel>
    languageTranslationSet$: Observable<LanguageTranslationSetViewModel[]>
    individualInfo: InvoiceIndividualDetailModel
    address: PaymentAddress

    paymentSearch: PaymentSearch
    ledger?: PaymentSearchLedger

    focusing = false
    generalfocusing = false
    individualfocusing = false
    addressfocusing = false
    select2Options = select2Options

    subscriptions: Subscription[]

    constructor(private paymentSearchService: PaymentSearchService,
        private paymentCreditInvoiceService: PaymentCreditInvoiceService,
        private dateConverterService: DateConverterService,
        private stringHelperService: StringHelperService,
        private navigateService: NavigationService,
        private InvoiceIndividualDetailMapperService: InvoiceIndividualDetailMapperService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscriptions = []
        this.subscriptions.push(
            this.paymentCreditInvoiceService.paymentSearch$
                .pipe(
                    filter(p => !!p)
                )
                .subscribe(
                    paymentSearch => this.updatePaymentAndInformation(paymentSearch))
        )

        this.individualInfo = null
        this.address = null
        this.organisationInfo$ = this.paymentCreditInvoiceService.organisationModel$
        this.languageTranslationSet$ = this.paymentCreditInvoiceService.languageTranslationSetViewModel$
    }

    ngOnDestroy(): void {
        this.paymentCreditInvoiceService.clearPaymentSearch()
        this.paymentCreditInvoiceService.clearOrganisationInfo()
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    submit() {
        this.paymentSearchService.setLoading(true)
        this.subscriptions.push(
            this.paymentSearchService.updatePaymentStatusCode(this.paymentSearch.paymentId, 'REVERSE').subscribe(
                () => {
                    this.paymentSearchService.alertSuccess('Payment is saved')
                    setTimeout(() => {
                        this.paymentSearchService.setLoading(false)
                        this.navigateService.navigate('main/cashbook/payment', null, false)
                    }, 2000)
                },
                () => {
                    this.paymentSearchService.setLoading(false)
                    this.paymentSearchService.alertError('Payment could not be saved')
                }
            )
        )
    }

    validateNullAmountDecimal(amount) {
        return this.stringHelperService.validateNullAmountDecimal(amount)
    }

    dateTime24UTCFormat(date) {
        return this.dateConverterService.dateTime24LocalFormat(date)
    }

    private updatePaymentAndInformation(paymentSearch: PaymentSearch) {
        this.paymentSearch = paymentSearch
        this.ledger = this.paymentSearch?.paymentSearchLedgers
            .slice(0)
            .find(l => l != null)

        if (this.paymentSearch.paymentOrganisationId) {
            this.paymentSearchService.setLoading(true)
            this.subscriptions.push(
                this.paymentCreditInvoiceService.getOrganisationInfo(this.paymentSearch.paymentOrganisationId).subscribe(response => {
                    this.paymentSearchService.setLoading(false)
                    this.mapInfoToAddress(response.organisationAddresses);
                    this.cdr.detectChanges()
                })
            )

            this.subscriptions.push(
                this.paymentCreditInvoiceService.getLangugeTranslationSet().subscribe()
            )
        }
        else if (this.paymentSearch.paymentIndividualId) {
            this.paymentSearchService.setLoading(true)
            this.subscriptions.push(
                this.paymentCreditInvoiceService.getIndividualInfo(this.paymentSearch.paymentIndividualId).subscribe(response => {
                    this.paymentSearchService.setLoading(false)
                    this.individualInfo = this.InvoiceIndividualDetailMapperService.InsightPassengerDetailToInvoiceIndividual(response, null)
                    this.mapInfoToAddress(response.insightPassengerAddresses)
                    this.cdr.detectChanges()
                })
            )
        }

    }

    private mapInfoToAddress(addresses: AddressModel[]) {
        const addr = addresses.find(a => a.addressTypeCode == 'INVOICE')

        if (addr) {
            const useAddr = addresses.find(a => a.useAddressId == addr.useAddressId)
            this.address = {
                addressType: addr.addressTypeName,
                addressLine1: addr.addressLine1,
                addressLine2: addr.addressLine2,
                city: addr.city,
                country: addr.countryName,
                location: addr.locationName,
                postalCode: addr.postalCode,
                primaryAddress: addr.primaryFlag,
                province: addr.province,
                state: addr.state,
                useAddress: useAddr ?
                    `${useAddr.addressTypeName} ${useAddr.addressLine1} ${useAddr.addressLine2} ${useAddr.city} ${useAddr.province} ${useAddr.postalCode}` : ''
            }
        }
    }
}

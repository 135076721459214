import { Injectable } from "@angular/core";
import { InsightPassengerCommunicationOtherModel } from "src/app/core/models/individual-model";
import { CommunicationOtherView } from "./communication-other-view";

@Injectable()
export class CommunicationOtherMapperService {
    public communicationOtherModelToViews(models: InsightPassengerCommunicationOtherModel[]) : CommunicationOtherView[] {
        let views = new Array();
        let i = 0;
        for (let model of models) {
            ++i;
            views.push(this.communicationOtherModelToView(i, model));
        }        
        return views;
    }

    private communicationOtherModelToView(index: number, model: InsightPassengerCommunicationOtherModel) : CommunicationOtherView {
        let view = new CommunicationOtherView();
        view.no = index;
        view.communicationOtherId = model.communicationOtherId;
        view.individualId = model.individualId;
        view.organisationId = model.organisationId;
        view.communicationOtherTypeCode = model.communicationOtherTypeCode;
        view.communicationOtherTypeName = model.communicationOtherTypeName;
        view.languageCode = model.languageCode;
        view.mailAddress = model.mailAddress;
        view.logon = model.logon;
        view.passwordCommunication = model.password;
        view.reEnterPassword = model.password;
        view.token = model.token;
        view.commentText = model.commentText;
        view.primaryCommunication = model.primaryFlag;
        view.information = model.mailAddress ?? model.logon;
        this.setOtherLockFlag(view);
        return view;
    }

    private setOtherLockFlag(view: CommunicationOtherView){
        view.communicationLocked = view.organisationId ? true : false;
    }

    public communicationOtherViewToModels(views: CommunicationOtherView[]): InsightPassengerCommunicationOtherModel[] {
        let models = new Array();
        for (let view of views) {
            models.push(this.communicationOtherViewToModel(view));
        }
        return models;
    }

    private communicationOtherViewToModel(view: CommunicationOtherView): InsightPassengerCommunicationOtherModel {
        let model: InsightPassengerCommunicationOtherModel = {
            communicationOtherId: view.communicationOtherId,
            individualId: view.individualId,
            organisationId: view.organisationId,
            communicationOtherTypeCode: view.communicationOtherTypeCode,
            communicationOtherTypeName: view.communicationOtherTypeName,
            languageCode: view.languageCode,
            mailAddress: view.mailAddress,
            logon: view.logon,
            password: view.passwordCommunication,
            token: view.token,
            commentText: view.commentText?.trim(),
            primaryFlag: view.primaryCommunication
        };
        
        return model;
    }
}
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, SimpleChange, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { field } from 'src/app/modules/product-management/product-categories-content/shared/constant/attribute.constant';

import { DomainAttributeService } from 'src/app/core/services/airline-services';

import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';

import {
    select2Attribute, select2Choice, select2ChoiceMultiple, select2AttributeInherit, select2AttributeDisabled, timeOption
} from './origin-destination-configuration';
import { OriginDestinationAttributeViewModel, ServiceTimeViewModel } from 'src/app/core/models/product-model/transport-model';
import { AttributeMapperService } from '../../../../../shared/mapper/attribute-mapper.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { ProductHierarchyViewModel } from 'src/app/core/models/product-model/product-base-model/product-hierarchy';

@Component({
    selector: 'op-origin-destination',
    templateUrl: './origin-destination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AttributeMapperService]
})
export class OriginDestinationComponent {

    public readonly ERROR_ATTRIBUTE_REQUIRED = 'Attribute is required.';
    private readonly ARRIVAL_DAY_CHANGE_CODE = 'ARRDAYCHG';
    private readonly ARRIVAL_GATE_CODE = 'ARRGATE';
    private readonly ARRIVAL_TERMINAL_CODE = 'ARRTERMINAL';
    private readonly ARRIVAL_SCHEDULE_TIME_CODE = 'STA';
    private readonly DEPARTURE_SCHEDULE_TIME_CODE = 'STD';
    private readonly DEPARTURE_GATE_CODE = 'DEPTGATE';
    private readonly DEPARTURE_TERMINAL_CODE = 'DEPTTERMINAL';
    public readonly TIME_ONLY_FORMAT = 'HH:mm';
    private readonly ATTRIBUTE_CODE_LIMITATION = [
        this.ARRIVAL_DAY_CHANGE_CODE,
        this.ARRIVAL_SCHEDULE_TIME_CODE,
        this.DEPARTURE_SCHEDULE_TIME_CODE
    ]
    private readonly ATTRIBUTE_CODE_FINALFLAG_DISABLED = [
        this.DEPARTURE_GATE_CODE,
        this.DEPARTURE_TERMINAL_CODE,
        this.ARRIVAL_DAY_CHANGE_CODE,
        this.ARRIVAL_GATE_CODE,
        this.ARRIVAL_TERMINAL_CODE
    ]
    private readonly DEP_HIERARCHY_LENGTH = 5;
    private readonly ARR_HIERARCHY_LENGTH = 7;

    @Input() classIcon: string;
    @Input() productTypeCode: string;
    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() searchMode: boolean = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    public focusing: boolean = false;
    private readonly ATTRBUTE_GROUP_CODE: string = "ORIGINDESTINATION";

    public actionForm: UntypedFormGroup;
    public inheritForm: UntypedFormGroup;
    public departureArrivalForm: UntypedFormGroup;
    public attributeOption: any = select2Attribute;
    public attributeDisabledOption: any = select2AttributeDisabled;
    public attributeInheritOption: any = select2AttributeInherit;
    public choiceOption: any = select2Choice;
    public choiceMultipleOption: any = select2ChoiceMultiple;
    public daterangepickerTimeOption: any = cloneDeep(timeOption);

    public domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public productAttributes: ProductAttributeViewModel[];
    public productAttributesInherit: OriginDestinationAttributeViewModel[];
    public isDeparture: boolean = false;
    public isArrival: boolean = false;
    public isRoot: boolean = false;
    public limitationFlag: boolean = false;
    public finalFlag: boolean = false;

    public serviceTimes: ServiceTimeViewModel[];
    private departureLevelProductId: string;
    private arrivalLevelProductId: string;
    
    constructor(
        private fb: UntypedFormBuilder,
        private domainAttributeService: DomainAttributeService,
        private attributeMapperService: AttributeMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {
        this.clearForm();
        this.attributeOption.templateSelection = this.templateResultAttribute.bind(this);
        this.attributeInheritOption.templateSelection = this.templateResultAttribute.bind(this);
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (this.searchMode) {
            this.getAttribute();
        }
    }

    public addServiceTimes(serviceTimes: ServiceTimeViewModel[], productHierarchy: ProductHierarchyViewModel) {
        this.serviceTimes = serviceTimes;
        this.departureLevelProductId = this.getDepartureLevelProductId(productHierarchy);
        this.arrivalLevelProductId = this.getArrivalLevelProductId(productHierarchy);
    }

    private getDepartureLevelProductId(productHierarchy: ProductHierarchyViewModel): string {
        let dept = productHierarchy.childProduct[0];
        if (!dept) {
            return null;
        }
        return dept.productId;
    }

    private getArrivalLevelProductId(productHierarchy: ProductHierarchyViewModel): string {
        let lastDeparture = productHierarchy.childProduct[productHierarchy.childProduct.length - 1];
        if (!lastDeparture) {
            return null;
        }
        let lastArrival = lastDeparture.childProduct[lastDeparture.childProduct.length - 1];
        return lastArrival.productId;
    }

    public addDataToForm(routeLevel: boolean, subRouteLevel: boolean, productAttributes: ProductAttributeViewModel[], finalFlag: boolean = false) {
        this.clearForm();
        this.isDeparture = routeLevel;
        this.isArrival = subRouteLevel;
        this.finalFlag = finalFlag;
        if (this.isDeparture || this.isArrival || this.searchMode) {
            this.filterStartOrEndFlag(productAttributes);
            this.getAttributeByProductType();
        }
        this.filterLimitationFieldData();
        this.toggleDepartureArrivalControls();
        this.changeDetectionRef.detectChanges();
    }

    public addDataToInheritForm(originDestinationAttributes: OriginDestinationAttributeViewModel[]) {
        this.clearForm();
        this.isRoot = true;
        this.productAttributesInherit = originDestinationAttributes;
        this.getAttributeByProductType();
        this.changeDetectionRef.detectChanges();
    }

    private filterStartOrEndFlag(productAttributes: ProductAttributeViewModel[]) {
        var productAttributesFilter: ProductAttributeViewModel[];
        if (this.isDeparture) {
            productAttributesFilter = productAttributes.filter(x => x.startFlag == true && x.inheritAttribute == false);
        } else if (this.isArrival) {
            productAttributesFilter = productAttributes.filter(x => x.endFlag == true && x.inheritAttribute == false);
        } else if (this.searchMode) {
            productAttributesFilter = productAttributes.filter(x => (x.startFlag == true || x.endFlag == true) && x.inheritAttribute == false);
        }
        this.productAttributes = productAttributesFilter.sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
    }

    private getAttribute() {
        this.domainAttribute$ = this.domainAttributeSearch$;
    }

    private getAttributeByProductType() {
        if (this.productTypeCode) {
            this.domainAttributeService.getByProductType(this.ATTRBUTE_GROUP_CODE, this.productTypeCode)
                .subscribe(
                    (responses: DomainAttributeModel[]) => {
                        let filterCondition = this.getConditionFlag(responses);
                        this.domainAttribute$.next(filterCondition);
                        this.displayData();
                    }
                )
        }
    }

    private displayData() {
        if (this.isRoot) {
            this.displayDataForRoot()
        }
        else {
            this.disiplayDataForChild();
        }
    }

    private displayDataForRoot() {
        this.setDepartureArrivalTime();
        this.addDataInheritToFormGroup();
    }

    private disiplayDataForChild() {
        if (!this.productAttributes?.length && !this.serviceTimes?.length) {
            this.displayShowOnNewFlag();
        }
        else {
            this.addDataToFormGroup();
        }
        this.setDepartureArrivalTime();
        this.changeDetectionRef.detectChanges();
    }

    private setDepartureArrivalTime() {
        if (!this.serviceTimes?.length) {
            return;
        }
        if (this.isRoot) {
            let departure = this.serviceTimes.find(st => st.serviceTimeHierarchyKey?.length == this.DEP_HIERARCHY_LENGTH);
            let departureTime = departure?.localDateTime;
            this.setScheduleTime(departureTime, this.departure);
            this.departureArrivalForm.get('departureId').setValue(departure?.productDateTimeId);

            let arrival = this.serviceTimes.find(st => st.serviceTimeHierarchyKey?.length == this.ARR_HIERARCHY_LENGTH);
            let arrivalTime = arrival?.localDateTime;
            this.setScheduleTime(arrivalTime, this.arrival);
            this.departureArrivalForm.get('arrivalId').setValue(arrival?.productDateTimeId);
        } else if (this.isDeparture) {
            let departure = this.serviceTimes.find(st => st.productId = this.id);
            let departureTime = departure?.localDateTime;
            this.setScheduleTime(departureTime, this.departure);
            this.departureArrivalForm.get('departureId').setValue(departure?.productDateTimeId);
        } else if (this.isArrival) {
            let arrival = this.serviceTimes.find(st => st.productId == this.id);
            let arrivalTime = arrival?.localDateTime;
            this.setScheduleTime(arrivalTime, this.arrival);
            this.departureArrivalForm.get('arrivalId').setValue(arrival?.productDateTimeId);
        }
    }

    private setScheduleTime(localDateTime: Date, control: UntypedFormControl) {
        let time = this.dateConverterService.convertTime24(localDateTime);
        control.setValue(time);
    }

    private displayShowOnNewFlag() {
        this.clearActionForms();
        if (this.domainAttribute$.value) {
            let domainAttributeFilter = this.filterAttributeOD(this.domainAttribute$.value);
            let required = domainAttributeFilter.filter(x => x.requiredFlag == true);
            if (this.finalFlag) {
                required = this.filterFinalFlagDisabled(required);
            } 

            this.display(required);

            let showOnNew = domainAttributeFilter.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            if (this.finalFlag) {
                showOnNew = this.filterFinalFlagDisabled(showOnNew);
            }
            this.display(showOnNew);
        }
    }

    private display(domainAttributeFilter: DomainAttributeModel[]) {
        for (let productAttributeTypeReference of domainAttributeFilter) {
            if (productAttributeTypeReference.showOnNewFlag || productAttributeTypeReference.requiredFlag) {
                this.forms.push(this.createFormGroupEmpty(productAttributeTypeReference));
                let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                formCurrent.get(field.SUBMITTED).setValue(true);
                if (productAttributeTypeReference.requiredFlag) {
                    this.setDataAttributeTypeRequireFlag(formCurrent, productAttributeTypeReference.attributeTypeCode);
                }
                this.attributeChange(productAttributeTypeReference.attributeTypeCode, formCurrent);
            }
        }
    }

    public addDataToFormGroup() {
        this.clearActionForms();
        if (!this.productAttributes) {
            return;
        }

        let displaySequenceAdded: number[] = new Array();
        for (let productAttribute of this.productAttributes) {
            let filterDisplaySequence = displaySequenceAdded.filter(x => x == productAttribute.displaySequence);
            if (filterDisplaySequence.length == 0) {
                if (this.canGetProductAttributeType(productAttribute)) {
                    this.assignDataToForm(productAttribute);
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get(field.SUBMITTED).setValue(true);
                }
                displaySequenceAdded.push(productAttribute.displaySequence);
            }
        }
    }

    private addDataInheritToFormGroup() {
        this.clearInheritForms();
        if (!this.productAttributesInherit) {
            return;
        }

        let originAttributes = this.productAttributesInherit.filter(x => x.startFlag == true)
            .sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        this.addDataInherit(originAttributes);

        let destinationAttributes = this.productAttributesInherit.filter(x => x.endFlag == true)
            .sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1));
        this.addDataInherit(destinationAttributes);

        this.changeDetectionRef.detectChanges();
    }

    private addDataInherit(inheritAttributes: OriginDestinationAttributeViewModel[]) {
        if (!inheritAttributes?.length) {
            return;
        }
        let displayDataAdded: Select2Data[] = new Array();
        for (let inheritAttribute of inheritAttributes) {
            var filterDisplaySequence = displayDataAdded.filter(x => x.id == inheritAttribute.attributeTypeCode && x.text == inheritAttribute.displaySequence.toString());
            if (filterDisplaySequence.length == 0) {
                this.assignDataToInheritForm(inheritAttribute);
                var dataAdded = new Select2Data(inheritAttribute.attributeTypeCode,
                    inheritAttribute.displaySequence.toString());
                displayDataAdded.push(dataAdded);
            }
        }
    }

    private canGetProductAttributeType(productAttribute: ProductAttributeViewModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }
        return returnValue;
    }

    private assignDataToForm(productAttribute: ProductAttributeViewModel) {
        if (productAttribute.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleData(productAttribute);
            let choiceArrayDBId = this.createChoiceDBIdData(productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, choiceMultiple, choiceArrayDBId));
        } else {
            this.forms.push(
                this.createFormGroupWithData(productAttribute));
        }
    }

    private assignDataToInheritForm(productAttributeInherit: OriginDestinationAttributeViewModel) {
        if (productAttributeInherit.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleInheritDataToForm(productAttributeInherit);
            let choiceArrayDBId = this.createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit);
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag,
                    choiceMultiple, choiceArrayDBId, true));
        } else {
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag, null, null, true));
        }
    }

    private setDataAttributeTypeRequireFlag(formCurrent: AbstractControl, attributeTypeCode: string) {
        formCurrent.get('required').setValue(true);
        let domainAttributeRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        let filterAttributeType = this.domainAttribute$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
        domainAttributeRequireFlag$.next(filterAttributeType);
        formCurrent.get(field.ATTRTYPE_DATA).setValue(domainAttributeRequireFlag$);
    }

    private createFormGroupEmpty(productAttributeTypeReference: DomainAttributeModel) {
        let form = {};
        if (productAttributeTypeReference) {
            form = this.assignAttributeToEmptyForm(
                productAttributeTypeReference.attributeTypeCode,
                productAttributeTypeReference.dimensionUnitCode,
                productAttributeTypeReference.multipleChoiceFlag);
        }
        else {
            form = this.assignAttributeToEmptyForm(null, null, false);
        }
        let f = this.fb.group(form);
        return f;
    }

    private createDomainAttributeNotRequire(domainAttributeBlinding$: BehaviorSubject<DomainAttributeModel[]>, attributeTypeCode: string): BehaviorSubject<DomainAttributeModel[]> {
        if (!domainAttributeBlinding$.value) {
            return new BehaviorSubject<DomainAttributeModel[]>(null);
        }
        if (!this.searchMode) {
            let filterNoRequireFlag = this.filterAttributeODNoRequireFlag(domainAttributeBlinding$);
            if (this.finalFlag && !this.attributeFinalFlagDisabled(attributeTypeCode)) {
                 filterNoRequireFlag = this.filterFinalFlagDisabled(filterNoRequireFlag);
            }   
            
            let domainAttributeNoRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
            domainAttributeNoRequireFlag$.next(filterNoRequireFlag);
            return domainAttributeNoRequireFlag$;
        }
        return domainAttributeBlinding$;
    }

    private filterAttributeODNoRequireFlag(domainAttributeBlinding$: BehaviorSubject<DomainAttributeModel[]>): DomainAttributeModel[] {
        var filterAttribute: DomainAttributeModel[] = new Array();
        if (this.isDeparture) {
            filterAttribute = domainAttributeBlinding$.value.filter(x => x.requiredFlag != true && x.startFlag == true);
        }
        else if (this.isArrival) {
            filterAttribute = domainAttributeBlinding$.value.filter(x => x.requiredFlag != true && x.endFlag == true);
        }
        return filterAttribute.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
    }

    private filterAttributeOD(domainAttribute: DomainAttributeModel[]): DomainAttributeModel[] {
        var filterAttribute: DomainAttributeModel[] = new Array();
        if (this.isDeparture) {
            filterAttribute = domainAttribute.filter(x => x.startFlag == true);
        }
        else if (this.isArrival) {
            filterAttribute = domainAttribute.filter(x => x.endFlag == true);
        }
        return filterAttribute.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }


    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    get departure() {
        return (<UntypedFormControl>this.departureArrivalForm.get('departure'));
    }

    get arrival() {
        return (<UntypedFormControl>this.departureArrivalForm.get('arrival'));
    }

    public clearForm() {
        this.clearActionForms();
        this.clearInheritForms();
        this.clearDepartureArrivalForm();
        this.productAttributes = [];
        this.productAttributesInherit = [];
        this.isDeparture = false;
        this.isArrival = false;
        this.isRoot = false;
        this.finalFlag = false;
        if (this.searchMode) {
            this.changeDetectionRef.detectChanges();
        }
    }

    private clearActionForms() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    private clearInheritForms() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    private clearDepartureArrivalForm() {
        this.departureArrivalForm = this.fb.group({
            departureId: this.fb.control(null),
            departure: this.fb.control('00:00'),
            arrivalId: this.fb.control(null),
            arrival: this.fb.control('00:00')
        })
        this.departure.setValue(null);
        this.arrival.setValue(null);
    }

    public attributeChange(value: string | string[], ctl: AbstractControl) {
        ctl.get(field.ATTRTYPE_CODE).setValue(value);
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get(field.CHOICE_VALUE).setValue(null);
                ctl.get(field.DIMENSIONUNIT_CODE).setValue(attributeFilter[0].dimensionUnitCode);
                ctl.get(field.MIN).setValue(this.attributeMapperService.getMinimunValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                ctl.get(field.MAX).setValue(this.attributeMapperService.getMaximumValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                if (attributeFilter[0].dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
                    this.setChoiceData(ctl, attributeFilter[0].attributeChoices);
                    this.setSingleOrMultipleChoice(ctl, attributeFilter[0].multipleChoiceFlag);
                    this.setDefaultChoiceValue(ctl, attributeFilter[0]);
                }
                this.setDefaultValue(ctl, attributeFilter[0]);
            } else {
                this.setEmptySingleDropdown(ctl);
            }
        }
    }

    private setChoiceData(ctl: AbstractControl, attributeChoices: AttributeChoiceModel[]) {
        let choiceData = this.convertToSelect2Data(attributeChoices);
        ctl.get(field.CHOICE_DATA).setValue(choiceData);
    }

    private setSingleOrMultipleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean) {
        ctl.get(field.MULCHOICE_FLAG).setValue(multipleChoiceFlag);
        if (multipleChoiceFlag) {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceMultipleOption);
        }
        else {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceOption);
        }
    }

    private setDefaultChoiceValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        let filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.assignDefaultChoice(ctl, attribute.multipleChoiceFlag, filterDefaultChoice);
        }
        else {
            this.assignDefaultSingleChoice(ctl, attribute.multipleChoiceFlag, attribute.attributeChoices);
        }
    }

    private setDefaultValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        if ((attribute.dimensionUnitCode == OopsChoiceValueConstant.NUMBER ||
            attribute.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE)
            && attribute.defaultValue) {
            ctl.get(field.CHOICE_VALUE).setValue(attribute.defaultValue);
        }
    }

    private assignDefaultChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (multipleChoiceFlag) {
            let dataMultiple: string[] = new Array();
            dataMultiple.push(chloces[0].attributeChoiceId);
            ctl.get(field.CHOICE_VALUE).setValue(dataMultiple);
        }
        else {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private assignDefaultSingleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (!multipleChoiceFlag) {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        choiceData.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    private createFormGroupWithData(productAttribute: ProductAttributeViewModel, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {};
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [productAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.SUBMITTED] = [true, [Validators.nullValidator]];
        form[field.REQUIRED] = [this.checkIsRequiredFlag(productAttribute.attributeTypeCode) ? true : false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];

        this.assignValueToAttribute(form, productAttribute, disabled);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceData(form, productAttribute);
            this.assignValueToChoiceValue(productAttribute.multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        if (this.checkIsRequiredFlag(productAttribute.attributeTypeCode)) {
            this.setDataAttributeTypeRequireFlag(f, productAttribute.attributeTypeCode);
        }
        return f;
    }

    private createFormGroupWithInheritData(productAttribute: OriginDestinationAttributeViewModel,
        multipleChoiceFlag: boolean, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {}
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];

        this.assignValueToAttributeInherit(form, productAttribute);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceInherit(form, choiceArrayDBId, productAttribute);
            this.assignValueToChoiceValue(multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        }
        else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        return f;
    }

    private assignValueToAttribute(form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$, productAttribute.attributeTypeCode), [Validators.nullValidator]];
    }

    private assignValueToChoiceData(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                let choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            }
            else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean,
        choiceMultipleValue: string[], form: any, choiceArrayDBId: Select2Data[],
        productAttribute: any, disabled: boolean) {
        form[field.CHOICE_ID] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
        }
        else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
            form[field.CHOICE_VALUE] = [{ value: productAttribute.attributeChoiceId, disabled: disabled }, [Validators.required]];
        }
    }

    private createChoiceMultipleData(productAttribute: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);
        }
        return choiceMultiple;
    }

    private createChoiceDBIdData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private setEmptySingleDropdown(ctl: AbstractControl) {
        ctl.get(field.CHOICE_DATA).setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get(field.MULCHOICE_FLAG).setValue(false);
    }

    selectChange(value: string, ctl, name: string) {
        ctl.get(name).setValue(value);
    }

    dataChange(value: string | string[], ctl) {
        ctl.get(field.CHOICE_VALUE).setValue(value);
    }

    delete(i) {
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
    }

    add() {
        if (this.forms.controls.length != 0) {
            let isDuplicate = this.findDuplicateAllRow();
            let isInvalid = this.findInvalidRows();
            if (isDuplicate || isInvalid) {
                return;
            }
        }
        this.forms.push(this.createFormGroupEmpty(null));
        this.filterLimitationFieldData();
        this.toggleDepartureArrivalControls();
    }

    private filterLimitationFieldData() {
        let limitationFields = this.getLimitationFields();
        if (limitationFields?.length) {
            for (let ctrl of this.forms.controls) {
                if (this.isAddLimitation(ctrl) == false) {
                    let dataSubject =ctrl.get(field.ATTRTYPE_DATA).value;
                    let newValue = dataSubject.value?.filter(val => limitationFields.includes(val.attributeTypeCode) == false);
                    dataSubject.next(newValue);
                }
            }
        }
    }

    private toggleDepartureArrivalControls() {
        if (this.limitationFlag == true) {
            this.departure.disable();
            this.arrival.disable();
        } else {
            this.departure.enable();
            this.arrival.enable();
        }
    }

    private getLimitationFields(): string[] {
        let fromNormalForm = this.forms.controls.map(ctrl => ctrl.get(field.ATTRTYPE_CODE).value).filter(code => this.ATTRIBUTE_CODE_LIMITATION.includes(code));
        let fromInheritForm = this.inheritforms.controls.map(ctrl => ctrl.get(field.ATTRTYPE_CODE).value).filter(code => this.ATTRIBUTE_CODE_LIMITATION.includes(code));
        return fromNormalForm.concat(fromInheritForm);
    }

    get controlLength(): number {
        if (this.forms.controls) {
            return this.forms.controls.length;
        }
        return 0;
    }

    get inheritControlLength(): number {
        if (this.inheritforms.controls) {
            return this.inheritforms.controls.length;
        }
        return 0;
    }

    public hiddenDelete(ctl): boolean {
        if (this.searchMode == false) {
            if (this.checkIsRequiredFlag(ctl.get(field.ATTRTYPE_CODE).value) || this.isLimitation(ctl) || this.isDisableFinalFlag(ctl)) {
                return true;
            }
        }
        return false;
    }

    public isLimitation(ctl: AbstractControl): boolean {
        if (this.limitationFlag != true) {
            return false;
        }
        return this.ATTRIBUTE_CODE_LIMITATION.includes(ctl.get(field.ATTRTYPE_CODE).value);
    }

    private checkIsRequiredFlag(productAttributeTypeCode: string) {
        if (productAttributeTypeCode && this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x =>
                x.attributeTypeCode == productAttributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    public findDuplicateAllRow(): boolean {
        let returnValue: boolean = false;
        let duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);

            for (let i = 0; i <= this.forms.controls.length - 1; i++) {
                let ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                }
                else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectionRef.detectChanges();
        return duplicate;
    }

    private findInvalidRows(): boolean {
        for (let ctl of this.forms.controls) {
            if (ctl.status == AttributeRuleConstant.STATUS_INVALID) {
                return true;
            }
        }
        return false;
    }

    private findDuplicate(ctlCheck, ctlIndex) {
        for (let i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                let ctl = this.forms.controls[i];
                if (this.equalValue(ctl, ctlCheck)) {
                    return true;
                }
            }
        }
        return false;
    }

    private equalValue(ctl, ctlCheck): boolean {
        if (ctl.value.productAttributeTypeCode == ctlCheck.value.productAttributeTypeCode) {
            if (ctl.value.multipleChoiceFlag) {
                if (this.checkArrayEqual(ctl.value.choiceValue, ctlCheck.value.choiceValue)) {
                    return true;
                }
            }
            else {
                if (ctl.value.choiceValue == ctlCheck.value.choiceValue) {
                    return true;
                }
            }
        }
        return false;
    }

    private updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get(field.DUPLICATE).setValue(null);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.required);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
        else {
            formCurrent.get(field.DUPLICATE).setValue(false);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.nullValidator);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
        return returnValue;
    }

    private createChoiceMultipleInheritDataToForm(productAttributeInherit: OriginDestinationAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);;
        }
        return choiceMultiple;
    }

    private createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit: OriginDestinationAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributesInherit(productAttributeInherit: OriginDestinationAttributeViewModel): OriginDestinationAttributeViewModel[] {
        return this.productAttributesInherit.filter(x => x.attributeTypeCode == productAttributeInherit.attributeTypeCode
            && x.displaySequence == productAttributeInherit.displaySequence);
    }

    private assignValueToChoiceInherit(form: any, choiceArrayDBId: Select2Data[], productAttribute: OriginDestinationAttributeViewModel) {
        let choiceDataArray: Select2Data[] = new Array();
        if (choiceArrayDBId) {
            choiceDataArray = this.createChoiceMultipleInheritData(choiceArrayDBId);
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        }
        else {
            choiceDataArray = this.createChoiceSingleInheritData(productAttribute);
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        form[field.CHOICE_DATA] = [choiceDataArray, [Validators.nullValidator]];
    }

    private createChoiceMultipleInheritData(choiceArrayDBId: Select2Data[]): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        if (choiceArrayDBId) {
            for (let choice of choiceArrayDBId) {
                choiceData = new Select2Data();
                choiceData.id = choice.text;
                var filter = this.productAttributesInherit.filter(x => x.attributeChoiceId == choice.text);
                if (filter.length != 0) {
                    choiceData.text = filter[0].attributeChoiceName;
                    choiceDataArray.push(choiceData);
                }
            }
        }
        return choiceDataArray;
    }

    private createChoiceSingleInheritData(productAttribute: OriginDestinationAttributeViewModel): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        choiceData = new Select2Data();
        choiceData.id = productAttribute.attributeChoiceId;
        choiceData.text = productAttribute.attributeChoiceName;
        choiceDataArray.push(choiceData);
        return choiceDataArray;
    }

    private assignValueToAttributeInherit(form: any, productAttribute: OriginDestinationAttributeViewModel) {
        var attributeDataArray: Select2Data[] = new Array();
        var attributeData = new Select2Data();
        attributeData.id = productAttribute.attributeTypeCode;
        attributeData.text = productAttribute.attributeTypeName;
        attributeDataArray.push(attributeData);
        form[field.ATTR_DATA] = [attributeDataArray, [Validators.nullValidator]];
    }

    templateResultAttribute(item): JQuery<HTMLElement> {
        let requiredFlag = this.isRequiredFlag(item);
        return item.text + requiredFlag;
    }

    isRequiredFlag(item): any {
        if (!item.id) {
            return "";
        }
        var id = $(item.element.outerHTML).attr("value");
        if (this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x => x.attributeTypeCode == id && x.requiredFlag == true);
            if (filter?.length) {
                return " *";
            }
        }
        return "";
    }

    public findRequireAttribute(): boolean {
        if (this.forms.controls.length != 0 && (this.isDeparture || this.isArrival)) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get('submitted').setValue(true);
            this.changeDetectionRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (ctl.value.required) {
                    if ((ctl.value.multipleChoiceFlag && !ctl.value.choiceValue?.length) ||
                        (!ctl.value.choiceValue)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private assignAttributeToEmptyForm(attributeTypeCode: string, dimensionUnitCode: string, multipleChoiceFlag: boolean) {
        let form = {};
        form[field.SUBMITTED] = [null, [Validators.nullValidator]];
        form[field.REQUIRED] = [null, [Validators.nullValidator]];
        form[field.CHOICE_ID] = [null, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [attributeTypeCode, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttributeNotRequire(this.domainAttribute$, attributeTypeCode), [Validators.nullValidator]];
        form[field.DIMENSIONUNIT_CODE] = [dimensionUnitCode, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.CHOICE_VALUE] = [null, [Validators.required]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.MIN] = [(attributeTypeCode) ? this.attributeMapperService.getMinimunValue(attributeTypeCode, this.domainAttribute$) : null, [Validators.nullValidator]];
        form[field.MAX] = [(attributeTypeCode) ? this.attributeMapperService.getMaximumValue(attributeTypeCode, this.domainAttribute$) : null, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        }
        else {
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        return form;
    }

    public rowInValid(ctl: AbstractControl): boolean {
        return (ctl.get(field.CHOICE_VALUE).invalid || ctl.get(field.DUPLICATE).invalid) && ctl.get(field.SUBMITTED).value == true;
    }

    public getErrorMessageForm(): string {
        if (this.findRequireAttribute()) {
            return this.ERROR_ATTRIBUTE_REQUIRED;
        }
        return null;
    }

    private getConditionFlag(response: DomainAttributeModel[]): DomainAttributeModel[] {
        if (response) {
            return response.filter(x => x.scheduleProductFlag == true &&
                    x.attributeTypeCode != this.ARRIVAL_DAY_CHANGE_CODE &&
                    x.attributeTypeCode != this.ARRIVAL_GATE_CODE &&
                    x.attributeTypeCode != this.ARRIVAL_TERMINAL_CODE &&
                    x.attributeTypeCode != this.DEPARTURE_GATE_CODE &&
                    x.attributeTypeCode != this.DEPARTURE_TERMINAL_CODE
                ) 
                .sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));;
        }
    }

    public getServiceTimes(): ServiceTimeViewModel[] {
        if (!this.isDeparture && !this.isArrival) {
            return [];
        }
        let models = new Array<ServiceTimeViewModel>();
        let serviceTime = this.serviceTimes.find(st => st.productId == this.id);
        let model: ServiceTimeViewModel = {
            dateTimeCode: serviceTime?.dateTimeCode,
            localDateTime: this.dateConverterService
                .convertTimeStringToMinDateWithTime(
                    this.getDepartureArrivalControl()?.value,
                    this.TIME_ONLY_FORMAT),
            displaySequence: null,
            productDateTimeId: serviceTime?.productDateTimeId,
            productId: serviceTime?.productId ?? this.id,
            serviceTimeHierarchyKey: null,
            locationId: null
        }
        models.push(model);
        return models;
    }

    private getDepartureArrivalControl(): UntypedFormControl {
        if (this.isDeparture) {
            return this.departure;
        } else if (this.isArrival) {
            return this.arrival;
        }
        return null;
    }

    public timeChange(value: string, ctrlName: string) {
        (<UntypedFormControl>this.departureArrivalForm.get(ctrlName)).setValue(value);
    }

    public isDisableFinalFlag(ctl: AbstractControl): boolean {
        if (this.finalFlag != true) {
            return false;
        }
        return this.ATTRIBUTE_CODE_FINALFLAG_DISABLED.includes(ctl.get(field.ATTRTYPE_CODE).value);
    }

    private filterFinalFlagDisabled(domainAttributes: DomainAttributeModel[]): DomainAttributeModel[] {
        let filterAttribute = domainAttributes.filter(x => this.ATTRIBUTE_CODE_FINALFLAG_DISABLED.includes(x.attributeTypeCode) == false);
        return filterAttribute.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
    }

    private attributeFinalFlagDisabled(attributeTypeCode : string) : boolean {
        if(attributeTypeCode) {
            let filters = this.ATTRIBUTE_CODE_FINALFLAG_DISABLED.filter(x=>x == attributeTypeCode);
            if (filters?.length) {
                return true;
            }
        }
        return false;
    }   

    public isAddLimitation(ctl: AbstractControl): boolean {
        return this.ATTRIBUTE_CODE_LIMITATION.includes(ctl.get(field.ATTRTYPE_CODE).value);
    }

    public refreshForm() {
        this.changeDetectionRef.detectChanges();
    }
}
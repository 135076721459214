import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AlertBarNotifier } from './alertbar-notifier';

@Component({
    selector: 'op-alert-bar',
    templateUrl: './alertbar.component.html'
})
export class AlertBarComponent {

    @Input("notifier") notifier = new AlertBarNotifier();
    showError: boolean = false;
    panelName: string;
    errorMessage: string;

    showWarning: boolean = false;
    showInformation: boolean = false;
    warningMessage: string;
    informationMessage: string;
    showSuccess: boolean = false;
    successMessage: string;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.notifier.showError = (panelName: string, errorMessage: string) => {
            if (errorMessage) {
                this.panelName = panelName;
                this.errorMessage = errorMessage;
                this.showError = true;
            }
            else {
                this.showError = false;
            }
            this.cdr.detectChanges()
        }

        this.notifier.hideError = () => {
            this.showError = false;
        }

        this.notifier.showWarning = (warningMessage: string) => {
            if (warningMessage) {
                this.warningMessage = warningMessage;
                this.showWarning = true;
            }
            else {
                this.showWarning = false;
            }
            this.cdr.detectChanges()
        }

        this.notifier.hideWarning = () => {
            this.showWarning = false;
        }

        this.notifier.showInformation = (panelName: string, infomrationMessage: string) => {
            if (infomrationMessage) {
                this.panelName = panelName;
                this.informationMessage = infomrationMessage;
                this.showInformation = true;
            }
            else {
                this.showInformation = false;
            }
            this.cdr.detectChanges()
        }

        this.notifier.hideInformation = () => {
            this.showInformation = false;
        }

        this.notifier.showSuccess = (successMessage: string) => {
            if (successMessage) {
                this.successMessage = successMessage;
                this.showSuccess = true;
            }
            else {
                this.showSuccess = false;
            }
            this.cdr.detectChanges()
        }

        this.notifier.hideSuccess = () => {
            this.showSuccess = false;
        }
    }

    public clickError(): void {

    }
    public clickWarning(): void {

    }
    public clickInformation(): void {

    }
}
export const RestrictionConstant = {
    TYPE_LOCATION: "location",
    TYPE_ROUTE: "route",
    TYPE_PRODUCTCODE: "productcode",
    TYPE_PRODUCTTYPE: "producttype",
    TYPECODE_LOCATION_POINT: "POINT",
    TYPECODE_LOCATION_RESTRICTION: "RESTRICTION",
    OPERATOR_LOCATION_POINT_EQUAL: "=",
    CODE_AREA_REGION: "region",
    CODE_AREA_COUNTRY: "country",
    CODE_AREA_AIRPORT: "airport",
    CODE_AREA_GROUP: "group",
    TYPE_VEHICLE: "vehicle"
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AncillaryServiceComponent } from './ancillary-service.component';
import { AncillaryServiceContentModule } from './ancillary-service-content/ancillary-service-content.module';

@NgModule({
  declarations: [
      AncillaryServiceComponent
  ],
  imports: [
    CommonModule,
    AncillaryServiceContentModule,
    UiModule
  ]
})
export class AncillaryServiceModule { }
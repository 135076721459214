import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model';
import { InvoiceIndividualSearchResultComponent } from './invoice-individual-search-result/invoice-individual-search-result.component';
import { InvoiceIndividualSearchConditionComponent } from './invoice-individual-search-condition/invoice-individual-search-condition.component';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';

@Component({
    selector: 'op-invoice-individual-search',
    templateUrl: './invoice-individual-search.component.html'
})
export class InvoiceIndividualSearchComponent {
    public displaySearchResult = false;

    @Output() onSearchResultSelect = new EventEmitter<InvoiceIndividualSearchModel>();

    @ViewChild(InvoiceIndividualSearchResultComponent) searchResultComponent: InvoiceIndividualSearchResultComponent;
    @ViewChild(InvoiceIndividualSearchConditionComponent) conditionComponent: InvoiceIndividualSearchConditionComponent;

    constructor(private focusingService: FocusingService) { }

    public onSearch(condition: InvoiceIndividualSearchModel) {
        this.displaySearchResult = true;
        this.searchResultComponent.searchIndividual(condition);
    }

    public onClear() {
        this.displaySearchResult = false
    }

    public selectSearchResult(selectedItem: InvoiceIndividualSearchModel) {
        this.onSearchResultSelect.emit(selectedItem);
    }

    public focusSearchCondition() {
        this.focusingService.focus(this.conditionComponent.focusingDirective);
    }
}

import { Injectable } from "@angular/core";
import { InsightPassengerAttributeModel, InsightPassengerCommandModel } from "src/app/core/models/individual-model";
import { AttributeRowView } from "./attribute-row-view";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Injectable()
export class AttributeMapperService {
    constructor(private dateConverterService: DateConverterService) {}

    public insightModelToAttributeRowViews(models: InsightPassengerAttributeModel[]) : AttributeRowView[] {
        if (!models) {
            return null;
        }
        let views: AttributeRowView[] = new Array();
        for (let attribute of models) {
            this.setAttributeView(views, attribute);
        }
        return views;
    }

    private setAttributeView(views: AttributeRowView[], attribute: InsightPassengerAttributeModel) {
        let view = views.filter(x => x.attributeTypeCode == attribute.attributeTypeCode)[0];
        if (view) {
            this.addAttributeChoiceId(view, attribute.attributeChoiceId)
        } else {
            views.push(this.insightModelToAttributeRowView(attribute));
        }
    }

    public insightModelToAttributeRowView(model: InsightPassengerAttributeModel) : AttributeRowView {
        let view: AttributeRowView = {
            attributeTypeCode: model.attributeTypeCode,
            attributeTypeName: model.attributeTypeName,
            attributeValue: model.attributeValue,
            attributeChoiceIds: [model.attributeChoiceId],
            duplicated: false,
            isFreeTextChoice: false,
            sortSequence: model.displaySequence,
            isMultiChoices: false,
            attributeDateTime: this.dateConverterService.toDateFormatOrNull(model.attributeDateTime),
            attributeText: model.attributeText
        }

        return view;
    }

    public addAttributeChoiceId(view: AttributeRowView, choiceId: string) {
        view.attributeChoiceIds.push(choiceId);
    }

    public attributeRowViewsToAttributeModels(rows: AttributeRowView[], command: InsightPassengerCommandModel) {
        if (!rows?.length) {
            return;
        }

        command.insightPassengerAttributes = new Array();
        let index = 1;
        for (let row of rows) {
            this.attributeRowViewToAttributeModelByDimensionType(row, command, index);
            index = command.insightPassengerAttributes.length +1;
        }  
    }

    private attributeRowViewToAttributeModelByDimensionType(row: AttributeRowView, command: InsightPassengerCommandModel, index: number) {
        if (row.attributeChoiceIds) {
            let model = this.choiceAttributeRowViewToAttributeModel(row, index);
            command.insightPassengerAttributes.push(...model);
        } else {
            let model = this.attributeRowViewToAttributeModel(row, index);
            command.insightPassengerAttributes.push(model);
        }
    }

    private choiceAttributeRowViewToAttributeModel(row: AttributeRowView, index: number): InsightPassengerAttributeModel[] {
        let models = [];
        row.attributeChoiceIds.forEach(id => {
            let model: InsightPassengerAttributeModel = {
                individualId: null,
                individualAttributeId: null,
                displaySequence: index,
                attributeChoiceId: id,
                attributeTypeCode: row.attributeTypeCode,
                attributeTypeName: row.attributeTypeName,
                attributeValue: row.attributeValue,
                attributeDateTime: row.attributeDateTime,
                attributeText: row.attributeText,
                attributeDate:row.attributeDate
            }
            index++;
            models.push(model);
        });
        return models;
    }

    private attributeRowViewToAttributeModel(row: AttributeRowView, index: number): InsightPassengerAttributeModel {
        let model: InsightPassengerAttributeModel = {
            individualId: null,
            individualAttributeId: null,
            displaySequence: index,
            attributeChoiceId: null,
            attributeTypeCode: row.attributeTypeCode,
            attributeTypeName: row.attributeTypeName,
            attributeValue: row.attributeValue,
            attributeDateTime: row.attributeDateTime,
            attributeText: row.attributeText,
            attributeDate: row.attributeDate
        }
        return model
    }

}
export const TypeOrganisationConstant = {
    type: {
        id: 'TYPE',
        text: 'Type'
    },
    role: {
        id: 'ROLE',
        text: 'Role'
    },
    group: {
        id: 'GROUP',
        text: 'Group'
    },
    specific: {
        id: 'SPECIFIC',
        text: 'Specific'
    }
}

export const TypeOrganisationData = [
    TypeOrganisationConstant.specific
]
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from "lodash";
import { AttributeRuleConstant } from 'src/app/core/components/rules-config/shared/constants';
import { ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeChoiceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-choice.model';
import { field } from 'src/app/modules/product-management/product-categories-content/shared/constant/attribute.constant';
import { AttributeMapperService } from 'src/app/modules/product-management/product-categories-content/shared/mapper/attribute-mapper.service';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import {
    select2Attribute,
    select2AttributeInherit,
    select2Choice,
    select2ChoiceMultiple,
    select2ChoiceMultipleLocked
} from './inventory-rule-configuration';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-special-service-inventory-rule',
    templateUrl: './inventory-rule.component.html',
    providers: [
        AttributeMapperService
    ]
})
export class InventoryRuleComponent implements OnChanges {
    @Input() id: string;
    @Input() productTypeCode: string;
    @Input() newProduct: boolean = false;
    @Input() domainAttributeSearch$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    @Input() domainAttributeINV: DomainAttributeModel[] = [];
    @Input() searchMode: boolean = false;
    @Input() rootLevel: boolean = false;
    @Output() serviceCateSelected = new EventEmitter<boolean>();
    @Output() invGroupCtrl = new EventEmitter<boolean>();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    readonly SERVICECATEGORY_CODE: string = "SERVICECATEGORY";
    readonly INVGROUPCTRL_CODE: string = "INVGROUPCONTROL";
    readonly INVENTORY_CONTROL_CODE: string = "INVENTORYCONTROL";
    private readonly SRV_REQUIRED_ERROR = 'Service category is required';

    actionForm: UntypedFormGroup;
    inheritForm: UntypedFormGroup;

    public productAttributes: ProductAttributeViewModel[];
    public productAttributesInherit: ProductAttributeViewModel[];
    public draftFlag: boolean = true;

    private domainAttribute$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    private domainAttributeNotSrvCate$ = new BehaviorSubject<DomainAttributeModel[]>(null);
    public attributeOption: any = cloneDeep(select2Attribute);
    public choiceOption: any = cloneDeep(select2Choice);
    public choiceMultipleOption: any = cloneDeep(select2ChoiceMultiple);
    public choiceMultipleLockedOption: any = cloneDeep(select2ChoiceMultipleLocked)
    public attributeInheritOption: any = cloneDeep(select2AttributeInherit);
    private lockedAttribute: Select2Data[] = new Array();
    public newFromParent: boolean = false;
    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);
    public removedAttribute: DomainAttributeModel[] = [];
    public disableChoice = false;
    public focusing: boolean = false;

    constructor(private fb: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private attributeMapperService: AttributeMapperService) {
        this.clearInheritForms();
        this.clearForms();
        this.choiceMultipleLockedOption.templateSelection = this.templateSelection.bind(this);
        this.attributeOption.templateResult = this.templateResultAttribute.bind(this);
        this.attributeOption.templateSelection = this.templateResultAttribute.bind(this);
        this.attributeInheritOption.templateSelection = this.templateResultAttribute.bind(this);
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.geProductAttributeType();
        this.disableChoice = !this.newProduct;

    }

    public geProductAttributeType() {
        this.domainAttribute$.next(this.domainAttributeINV);
        if (this.newProduct) {
            this.lockedAttribute = [];
            this.displayShowOnNewFlag();
        }
    }

    add() {
        if (this.forms.controls.length != 0) {
            var isDuplicate = this.findDuplicateAllRow();
            if (isDuplicate) {
                return;
            }

            for (let ctl of this.forms.controls) {
                if (ctl.status == AttributeRuleConstant.STATUS_INVALID) {
                    return;
                }
            }
        }

        (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(null));
        this.panelCollapseFlag$.next(false);
    }

    addViewToFormGroup() {
        this.clearForms();
        if (!this.productAttributes) {
            return;
        }
        this.createDomainAttributeNoSrvCate();
        let displaySequenceAdded: number[] = new Array();
        for (let productAttribute of this.productAttributes) {
            var filterDisplaySequence = displaySequenceAdded.filter(x => x == productAttribute.displaySequence);
            if (filterDisplaySequence.length == 0) {
                if (this.canGetProductAttributeType(productAttribute)) {
                    this.assignDataToForm(productAttribute);
                    let formCurrent = this.forms.controls[this.forms.controls.length - 1];
                    formCurrent.get(field.REQUIRED).setValue(true);
                }
                displaySequenceAdded.push(productAttribute.displaySequence);
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    addViewInheritToFormGroup() {
        this.clearInheritForms();
        if (!this.productAttributesInherit) {
            return;
        }
        this.createDomainAttributeNoSrvCate();
        this.productAttributesInherit.sort((a, b) => (a.inheritAttribute > b.inheritAttribute ? -1 : 1));
        let displayDataAdded: Select2Data[] = new Array();
        for (let productAttributeInherit of this.productAttributesInherit) {
            var filterDisplaySequence = displayDataAdded.filter(x => x.id == productAttributeInherit.hierarchyKey && x.text == productAttributeInherit.displaySequence.toString());
            if (filterDisplaySequence.length == 0 && this.canGetProductAttributeType(productAttributeInherit)) {
                this.assignDataToInheritForm(productAttributeInherit);
                var dataAdded = new Select2Data(productAttributeInherit.hierarchyKey,
                    productAttributeInherit.displaySequence.toString());
                displayDataAdded.push(dataAdded);
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    createFormGroupEmpty(productAttributeTypeReference) {
        let form = {};
        if (productAttributeTypeReference) {
            form = this.assignAttributeToEmptyForm(
                productAttributeTypeReference.productAttributeId,
                productAttributeTypeReference.attributeTypeCode,
                productAttributeTypeReference.dimensionUnitCode,
                productAttributeTypeReference.multipleChoiceFlag);
        } else {
            form = this.assignAttributeToEmptyForm(null, null, null, false);
        }
        let f = this.fb.group(form);
        return f;
    }

    private assignAttributeToEmptyForm(productAttributeId: string, attributeTypeCode: string, dimensionUnitCode: string, multipleChoiceFlag: boolean) {
        let form = {};
        form[field.SUBMITTED] = [null, [Validators.nullValidator]];
        form[field.REQUIRED] = [null, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttributeId, [Validators.nullValidator]];
        form[field.CHOICE_ID] = [null, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [attributeTypeCode, [Validators.required]];
        form[field.DIMENSIONUNIT_CODE] = [dimensionUnitCode, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [(multipleChoiceFlag) ? true : false, [Validators.nullValidator]];
        form[field.CHOICE_OPTOIN] = [(multipleChoiceFlag) ? this.choiceMultipleOption : this.choiceOption, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.CHOICE_VALUE] = [null, [Validators.required]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.MIN] = [(attributeTypeCode) ? this.attributeMapperService.getMinimunValue(attributeTypeCode, this.domainAttribute$) : null, [Validators.nullValidator]];
        form[field.MAX] = [(attributeTypeCode) ? this.attributeMapperService.getMaximumValue(attributeTypeCode, this.domainAttribute$) : null, [Validators.nullValidator]];
        if (!this.srvCateAttributeSaved()) {
            form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttribute$), [Validators.nullValidator]];
        } else {
            form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttributeNotSrvCate$), [Validators.nullValidator]];
        }
        return form;
    }

    createFormGroupWithData(productAttribute: ProductAttributeViewModel, choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {};
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.MULCHOICE_FLAG] = [productAttribute.multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        form[field.DUPLICATE] = [false, [Validators.nullValidator]];
        form[field.SUBMITTED] = [true, [Validators.nullValidator]];
        form[field.REQUIRED] = [this.checkIsRequiredFlag(productAttribute.attributeTypeCode) ? true : false, [Validators.nullValidator]];
        form[field.MIN] = [this.attributeMapperService.getMinimunValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];
        form[field.MAX] = [this.attributeMapperService.getMaximumValue(productAttribute.attributeTypeCode, this.domainAttribute$), [Validators.nullValidator]];

        var isSRVCateAttribute = this.checkIsSRVCateAttribute(productAttribute.attributeTypeCode);
        this.assignValueToAttributeType(isSRVCateAttribute, form, productAttribute, disabled);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignOption(isSRVCateAttribute, form, productAttribute.multipleChoiceFlag);
            this.assignValueToChoiceData(isSRVCateAttribute, form, productAttribute.multipleChoiceFlag, productAttribute);
            this.assignValueToChoiceValue(productAttribute.multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        } else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        if (this.checkIsRequiredFlag(productAttribute.attributeTypeCode)) {
            this.setDataAttributeTypeRequireFlag(f, productAttribute.attributeTypeCode);
        }
        return f;
    }

    createFormGroupWithInheritData(productAttribute, multipleChoiceFlag: boolean,
        choiceMultipleValue: string[] = null,
        choiceArrayDBId: Select2Data[] = null,
        disabled: boolean = false) {
        let form = {}
        form[field.DIMENSIONUNIT_CODE] = [productAttribute.dimensionUnitCode, [Validators.nullValidator]];
        form[field.ATTR_ID] = [productAttribute.productAttributeId, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.MULCHOICE_FLAG] = [multipleChoiceFlag, [Validators.nullValidator]];
        form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
        this.assignValueToAttributeInherit(form, productAttribute);
        if (productAttribute.dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
            this.assignValueToChoiceInherit(form, choiceArrayDBId, productAttribute);
            this.assignValueToChoiceValue(multipleChoiceFlag, choiceMultipleValue, form, choiceArrayDBId, productAttribute, disabled);
        } else {
            this.attributeMapperService.assignValue(form, productAttribute, disabled, this.choiceOption);
        }
        let f = this.fb.group(form);
        return f;
    }

    private assignValueToAttributeInherit(form: any, productAttribute: ProductAttributeViewModel) {
        var attributeDataArray: Select2Data[] = new Array();
        var attributeData = new Select2Data();
        attributeData.id = productAttribute.attributeTypeCode;
        attributeData.text = productAttribute.attributeTypeName;
        attributeDataArray.push(attributeData);
        form[field.ATTR_DATA] = [attributeDataArray, [Validators.nullValidator]];
    }

    private assignValueToChoiceInherit(form: any, choiceArrayDBId: Select2Data[], productAttribute: ProductAttributeViewModel) {
        let choiceDataArray: Select2Data[] = new Array();
        if (choiceArrayDBId) {
            choiceDataArray = this.createChoiceMultipleInheritData(choiceArrayDBId, productAttribute.attributeTypeCode);
            form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        } else {
            choiceDataArray = this.createChoiceSingleInheritData(productAttribute);
            form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
        }
        form[field.CHOICE_DATA] = [choiceDataArray, [Validators.nullValidator]];
    }

    private createChoiceMultipleInheritData(choiceArrayDBId: Select2Data[], attributeTypeCode: string): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        if (choiceArrayDBId) {
            for (let choice of choiceArrayDBId) {
                choiceData = new Select2Data();
                choiceData.id = choice.text;
                var filter = this.productAttributesInherit.filter(x => x.attributeChoiceId == choice.text);
                if (filter.length != 0) {
                    if (attributeTypeCode == this.SERVICECATEGORY_CODE) {
                        choiceData.text = this.getServiceCatetgoryName(choice.text);
                    } else {
                        choiceData.text = filter[0].attributeChoiceName;
                    }
                    choiceDataArray.push(choiceData);
                }
            }
        }
        return choiceDataArray;
    }

    private getServiceCatetgoryName(attributeChoiceId: string): string {
        if (this.domainAttribute$.value) {
            let filterServiceCateAttr = this.domainAttribute$.value.filter(x => x.attributeTypeCode == this.SERVICECATEGORY_CODE);
            if (filterServiceCateAttr.length > 0) {
                let filterChoice = filterServiceCateAttr[0].attributeChoices.filter(x => x.attributeChoiceId == attributeChoiceId);
                return filterChoice[0].serviceCategoryName;
            }
        }
    }
    private createChoiceSingleInheritData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let choiceDataArray: Select2Data[] = new Array();
        let choiceData: Select2Data;
        choiceData = new Select2Data();
        choiceData.id = productAttribute.attributeChoiceId;
        choiceData.text = productAttribute.attributeChoiceName;
        choiceDataArray.push(choiceData);
        return choiceDataArray;
    }

    checkIsSRVCateAttribute(attributeTypeCode: string): boolean {
        if (attributeTypeCode == this.SERVICECATEGORY_CODE) {
            return true;
        }
        return false;
    }

    attributeChange(value: string | string[], ctl: AbstractControl) {
        ctl.get(field.ATTRTYPE_CODE).setValue(value);
        if (this.domainAttribute$.value) {
            let attribute = this.domainAttribute$.value;
            let attributeFilter = attribute.filter(x => x.attributeTypeCode == value);
            if (attributeFilter.length > 0) {
                ctl.get(field.CHOICE_VALUE).setValue(null);
                ctl.get(field.DIMENSIONUNIT_CODE).setValue(attributeFilter[0].dimensionUnitCode);
                ctl.get(field.MIN).setValue(this.attributeMapperService.getMinimunValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                ctl.get(field.MAX).setValue(this.attributeMapperService.getMaximumValue(attributeFilter[0].attributeTypeCode, this.domainAttribute$));
                if (attributeFilter[0].dimensionUnitCode == OopsChoiceValueConstant.CHOICE) {
                    this.setChoiceData(<string>value, ctl, attributeFilter[0].attributeChoices);
                    this.setSingleOrMultipleChoice(ctl, attributeFilter[0].multipleChoiceFlag);
                    this.setDefaultChoiceValue(ctl, attributeFilter[0]);
                    this.checkChoice(ctl);
                }
                this.setDefaultValue(ctl, attributeFilter[0]);
                this.removeAttributeFromOption(attributeFilter[0])
            } else {
                this.setEmptySingleDropdown(ctl);
            }
        }
    }

    removeAttributeFromOption(domainAttribute: DomainAttributeModel) {
        let newDomainAttributeList = this.domainAttribute$.value.filter(item => item.attributeTypeCode != domainAttribute.attributeTypeCode);
        this.domainAttribute$.next(newDomainAttributeList);
        this.removedAttribute.push(domainAttribute);
        this.removeAttributeFromForm(domainAttribute);
    }

    removeAttributeFromForm(domainAttribute: DomainAttributeModel) {
        <UntypedFormArray>this.actionForm.get('forms').value.forEach(item => {
            if (item.productAttributeTypeCode != domainAttribute.attributeTypeCode) {
                let attributeTypeData = item.productAttributeTypeData.value.filter(attr => attr.attributeTypeCode != domainAttribute.attributeTypeCode);
                item.productAttributeTypeData.next(attributeTypeData);
            }
        })
    }

    checkChoice(ctl) {
        if (ctl.get(field.ATTRTYPE_CODE).value == this.INVENTORY_CONTROL_CODE) {
            if (ctl.get(field.CHOICE_DATA).value.filter(item => item.id == ctl.get(field.CHOICE_VALUE).value)[0].text == 'No') {
                this.overrideInvGroupAndServCate(false);
            } else {
                this.overrideInvGroupAndServCate(true);
            }
        }
    }



    private setChoiceData(value: string, ctl: AbstractControl, attributeChoices: AttributeChoiceModel[]) {
        let choiceData: Select2Data[];
        if (value == this.SERVICECATEGORY_CODE) {
            choiceData = this.convertSrvCateToSelect2Data(attributeChoices);
        } else {
            choiceData = this.convertToSelect2Data(attributeChoices);
        }
        ctl.get(field.CHOICE_DATA).setValue(choiceData);
    }

    private setSingleOrMultipleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean) {
        ctl.get(field.MULCHOICE_FLAG).setValue(multipleChoiceFlag);
        if (multipleChoiceFlag) {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceMultipleOption);
        } else {
            ctl.get(field.CHOICE_OPTOIN).setValue(this.choiceOption);
        }
    }

    private setDefaultChoiceValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        let filterDefaultChoice = attribute.attributeChoices.filter(x => x.defaultFlag == true);
        if (filterDefaultChoice.length > 0) {
            this.assignDefaultChoice(ctl, attribute.multipleChoiceFlag, filterDefaultChoice);
        } else {
            this.assignDefaultSingleChoice(ctl, attribute.multipleChoiceFlag, attribute.attributeChoices);
        }
    }

    private setDefaultValue(ctl: AbstractControl, attribute: DomainAttributeModel) {
        if ((attribute.dimensionUnitCode == OopsChoiceValueConstant.NUMBER ||
            attribute.dimensionUnitCode == OopsChoiceValueConstant.PERCENTAGE)
            && attribute.defaultValue) {
            ctl.get(field.CHOICE_VALUE).setValue(attribute.defaultValue);
        }
    }

    private assignDefaultChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (multipleChoiceFlag) {
            let dataMultiple: string[] = new Array();
            dataMultiple.push(chloces[0].attributeChoiceId);
            ctl.get(field.CHOICE_VALUE).setValue(dataMultiple);
        } else {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private assignDefaultSingleChoice(ctl: AbstractControl, multipleChoiceFlag: boolean, chloces: AttributeChoiceModel[]) {
        if (!multipleChoiceFlag) {
            ctl.get(field.CHOICE_VALUE).setValue(chloces[0].attributeChoiceId);
        }
    }

    private setEmptySingleDropdown(ctl: AbstractControl) {
        ctl.get(field.CHOICE_DATA).setValue(new BehaviorSubject<AttributeChoiceModel[]>(null));
        ctl.get(field.MULCHOICE_FLAG).setValue(false);
    }

    get forms() {
        return (<UntypedFormArray>this.actionForm.get('forms'));
    }

    get inheritforms() {
        return (<UntypedFormArray>this.inheritForm.get('inheritforms'));
    }

    get allowCollapse(): boolean {
        return this.forms.controls.length > 0;
    }

    delete(i, ctl) {
        if (ctl.value.productAttributeTypeCode == this.SERVICECATEGORY_CODE) {
            this.serviceCateSelected.emit(false);
        }
        if (this.forms.controls.length == 0) {
            this.panelCollapseFlag$.next(true);
        }

        if (ctl.value.productAttributeTypeCode) {
            this.addAttributeToOption(ctl);
        }
        (<UntypedFormArray>this.actionForm.get('forms')).removeAt(i);
    }

    addAttributeToOption(ctl) {
        if (ctl?.get(field.ATTRTYPE_CODE)) {
            let deletedAttributeCode = ctl.get(field.ATTRTYPE_CODE).value;
            let addAttribute = this.removedAttribute.filter(attr => attr.attributeTypeCode == deletedAttributeCode)[0];

            <UntypedFormArray>this.actionForm.get('forms').value.forEach(item => {
                if (item.productAttributeTypeCode != this.INVENTORY_CONTROL_CODE) {
                    let attributeTypeData = item.productAttributeTypeData.value;
                    attributeTypeData.push(addAttribute);
                    item.productAttributeTypeData.next(attributeTypeData);
                }
            })
            this.addAttributeToList(addAttribute);
        }
    }

    addAttributeToList(addAttribute: DomainAttributeModel) {
        let domainAttr = this.domainAttribute$.value;
        domainAttr.push(addAttribute)
        this.domainAttribute$.next(domainAttr);
        let addIndex = this.removedAttribute.findIndex(item => item.attributeTypeCode == addAttribute.attributeTypeCode);
        this.removedAttribute.splice(addIndex, 1);
    }

    displayShowOnNewFlag() {
        if (this.domainAttribute$.value) {
            this.actionForm = this.fb.group({
                forms: this.fb.array([])
            });

            let required = this.domainAttribute$.value.filter(x => x.requiredFlag == true && x.attributeTypeCode != this.SERVICECATEGORY_CODE);
            this.display(required);

            let showOnNew = this.domainAttribute$.value.filter(x => x.showOnNewFlag == true && x.requiredFlag != true);
            this.display(showOnNew);

            this.changeDetectorRef.detectChanges();
        }
    }

    display(domainAttributeRequire: DomainAttributeModel[]) {
        for (let productAttributeTypeReference of domainAttributeRequire) {
            (<UntypedFormArray>this.actionForm.get('forms')).push(this.createFormGroupEmpty(productAttributeTypeReference));
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            if (productAttributeTypeReference.requiredFlag) {
                this.setDataAttributeTypeRequireFlag(formCurrent, productAttributeTypeReference.attributeTypeCode);
            }
            this.attributeChange(productAttributeTypeReference.attributeTypeCode, formCurrent);
            this.panelCollapseFlag$.next(false);
        }
    }


    convertToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        for (let choice of choiceData) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.attributeChoiceName;
            returnValue.push(value);
        }
        return returnValue;
    }

    findDuplicateAllRow(): boolean {
        var returnValue: boolean = false;
        var duplicate: boolean = false;
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);

            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                returnValue = this.findDuplicate(ctl, i);
                if (returnValue) {
                    this.updateValidatorDuplicate(ctl, true);
                } else {
                    this.updateValidatorDuplicate(ctl, false);
                }
                if (returnValue) {
                    duplicate = true;
                }
            }
        }
        this.changeDetectorRef.detectChanges();
        return duplicate;
    }

    findDuplicate(ctlCheck, ctlIndex) {
        var duplicate: boolean = false;
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            if (i != ctlIndex) {
                var ctl = this.forms.controls[i];
                if (ctl.value.productAttributeTypeCode == ctlCheck.value.productAttributeTypeCode && ctl.value.choiceValue && ctlCheck.value.choiceValue) {
                    if (ctl.value.multipleChoiceFlag) {
                        if (this.checkArrayEqual(ctl.value.choiceValue, ctlCheck.value.choiceValue)) {
                            duplicate = true;
                        }
                    } else {
                        if (ctl.value.choiceValue == ctlCheck.value.choiceValue) {
                            duplicate = true;
                        }
                    }
                }
            }
        }
        return duplicate;
    }

    updateValidatorDuplicate(formCurrent, required: boolean = false) {
        if (required) {
            formCurrent.get(field.DUPLICATE).setValue(null);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.required);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        } else {
            formCurrent.get(field.DUPLICATE).setValue(false);
            formCurrent.get(field.DUPLICATE).setValidators(Validators.nullValidator);
            formCurrent.get(field.DUPLICATE).updateValueAndValidity();
        }
    }

    private checkArrayEqual(array1: string[], array2: string[]): boolean {
        let returnValue: boolean = true;
        if (array1.length > 0 && array2.length > 0) {
            if (array1.length == array2.length) {
                for (let value of array1) {
                    var filter = array2.filter(x => x == value);
                    if (filter.length == 0) {
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
        return returnValue;
    }

    canGetProductAttributeType(productAttribute: ProductAttributeViewModel): boolean {
        var returnValue: boolean = false;
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                returnValue = true;
            }
        }
        return returnValue;
    }

    clearForm() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
        this.changeDetectorRef.detectChanges();
    }

    templateSelection(tag, container) {
        var id = $(tag.element.outerHTML).attr("value");
        if (this.lockChoice(id) && !this.draftFlag) {
            tag.locked = true;
            $(container).addClass('locked-tag');
        } else {
            tag.locked = false;
            $(container).removeClass('locked-tag');
        }
        return tag.text;
    }

    lockChoice(id): boolean {
        if (!this.lockedAttribute) {
            return false;
        }
        var filter = this.lockedAttribute.filter(x => x.text == id);
        if (filter.length == 0) {
            return false;
        }
        return true;
    }

    templateResultAttribute(item): JQuery<HTMLElement> {
        let requiredFlag = this.isRequiredFlag(item);
        if (this.isDimensionFlag(item) && !this.searchMode) {
            var $state = $(
                '<span>' + item.text + requiredFlag + ' <i class="fal fa-cube"></i></span>'
            );
            return $state;
        }
        return item.text + requiredFlag;
    }

    isDimensionFlag(item): boolean {
        if (!item.id) {
            return false;
        }
        var id = $(item.element.outerHTML).attr("value");
        if (!this.domainAttribute$.value) {
            return false;
        }
        var filter = this.domainAttribute$.value.filter(x => x.attributeTypeCode == id && x.dimensionFlag == true);
        if (filter.length > 0) {
            return true;
        }
        return false;
    }

    isRequiredFlag(item): any {
        if (!item.id || this.searchMode) {
            return "";
        }
        var id = $(item.element.outerHTML).attr("value");
        if (this.domainAttribute$.value) {
            var filter = this.domainAttribute$.value.filter(x => x.attributeTypeCode == id && x.requiredFlag == true);
            if (filter?.length) {
                return " *";
            }
        }
        return "";
    }

    public checkHiddenDelete(ctl): boolean {
        return !(
            (this.isNewProduct() || !this.checkIsSRVCateAttribute(ctl.get(field.ATTRTYPE_CODE).value) || this.draftFlag)
            &&
            (!this.checkIsRequiredFlag(ctl.get(field.ATTRTYPE_CODE).value) || this.searchMode)
        )
    }

    public checkSrvCateHiddenDelete(ctl): boolean {
        return !(!this.isNewProduct() && this.checkIsSRVCateAttribute(ctl.get(field.ATTRTYPE_CODE).value) && !this.draftFlag && !this.srvCateAttributeSaved())
    }

    private isNewProduct(): boolean {
        return (this.newProduct || this.newFromParent);
    }

    private assignValueToAttributeType(isSRVCateAttribute: boolean, form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        if (isSRVCateAttribute && !this.draftFlag) {
            this.assignValueToSRVCateAttribute(form, productAttribute);
        } else {
            this.assignValueToAttribute(form, productAttribute, disabled);
        }
    }

    private assignValueToSRVCateAttribute(form: any, productAttribute: ProductAttributeViewModel) {
        form[field.CHOICE_OPTOIN] = [this.choiceMultipleLockedOption, [Validators.nullValidator]]
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: false }, [Validators.required]];
        //Create only one attribute data
        var productAttTypeReference$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        var productAttTypes: DomainAttributeModel[] = new Array();
        var productAttType = {} as DomainAttributeModel;
        productAttType.attributeTypeCode = productAttribute.attributeTypeCode;
        productAttType.attributeTypeName = productAttribute.attributeTypeName;
        productAttTypes.push(productAttType);
        productAttTypeReference$.next(productAttTypes);
        form[field.ATTRTYPE_DATA] = [productAttTypeReference$, [Validators.nullValidator]];
    }

    private assignValueToAttribute(form: any, productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
        form[field.ATTRTYPE_CODE] = [{ value: productAttribute.attributeTypeCode, disabled: disabled }, [Validators.required]];
        form[field.ATTRTYPE_DATA] = [this.createDomainAttribute(this.domainAttribute$), [Validators.nullValidator]];
    }

    private assignValueToChoiceData(isSRVCateAttribute: boolean, form: any,
        multipleChoiceFlag: boolean, productAttribute: ProductAttributeViewModel) {
        if (isSRVCateAttribute) {
            this.assignValueToChoiceDataIsSRVCateAttribute(multipleChoiceFlag, form, productAttribute);
        } else {
            this.assignValueToChoiceDataIsNotSRVCateAttribute(form, productAttribute);
        }
    }

    private assignValueToChoiceDataIsSRVCateAttribute(multipleChoiceFlag, form: any, productAttribute: ProductAttributeViewModel) {
        if (!multipleChoiceFlag) {
            this.assignDataToSingleChoice(form, productAttribute);
        } else {
            this.assignDataSRVCateMultipleChoice(form, productAttribute);
        }
    }

    private assignDataToSingleChoice(form: any, productAttribute: ProductAttributeViewModel) {
        var choiceData: Select2Data[] = new Array();
        let value = new Select2Data();
        value.id = productAttribute.attributeChoiceId;
        value.text = productAttribute.attributeChoiceName;
        choiceData.push(value);
        form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
    }

    private assignDataSRVCateMultipleChoice(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                var choiceData = this.convertSrvCateToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            } else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceDataIsNotSRVCateAttribute(form: any, productAttribute: ProductAttributeViewModel) {
        if (this.domainAttribute$.value) {
            var attribute = this.domainAttribute$.value;
            var attributeFilter = attribute.filter(x => x.attributeTypeCode == productAttribute.attributeTypeCode);
            if (attributeFilter.length > 0) {
                var choiceData: Select2Data[];
                choiceData = this.convertToSelect2Data(attributeFilter[0].attributeChoices);
                form[field.CHOICE_DATA] = [choiceData, [Validators.nullValidator]];
            } else {
                form[field.CHOICE_DATA] = [null, [Validators.nullValidator]];
            }
        }
    }

    private assignValueToChoiceValue(multipleChoiceFlag: boolean,
        choiceMultipleValue: string[], form: any, choiceArrayDBId: Select2Data[],
        productAttribute: ProductAttributeViewModel, disabled: boolean) {
        form[field.CHOICE_ID] = [choiceArrayDBId, [Validators.nullValidator]];
        if (multipleChoiceFlag) {
            form[field.CHOICE_VALUE] = [{ value: choiceMultipleValue, disabled: disabled }, [Validators.required]];
        } else {
            form[field.CHOICE_VALUE] = [{ value: productAttribute.attributeChoiceId, disabled: disabled }, [Validators.required]];
        }
    }

    private assignOption(isSRVCateAttribute: boolean, form: any, multipleChoiceFlag: boolean) {
        if (!isSRVCateAttribute) {
            if (multipleChoiceFlag) {
                form[field.CHOICE_OPTOIN] = [this.choiceMultipleOption, [Validators.nullValidator]];
            } else {
                form[field.CHOICE_OPTOIN] = [this.choiceOption, [Validators.nullValidator]];
            }
        }
    }

    private checkIsRequiredFlag(productAttributeTypeCode: string) {
        if (productAttributeTypeCode && this.domainAttributeINV) {
            var filter = this.domainAttributeINV.filter(x =>
                x.attributeTypeCode == productAttributeTypeCode && x.requiredFlag == true);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private setDataAttributeTypeRequireFlag(formCurrent: AbstractControl, attributeTypeCode: string) {
        formCurrent.get(field.REQUIRED).setValue(true);
        let domainAttributeRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        let filterAttributeType = this.domainAttribute$.value.filter(x => x.attributeTypeCode == attributeTypeCode);
        domainAttributeRequireFlag$.next(filterAttributeType);
        formCurrent.get(field.ATTRTYPE_DATA).setValue(domainAttributeRequireFlag$);
    }

    private createDomainAttribute(domainAttributeBliding$: BehaviorSubject<DomainAttributeModel[]>): BehaviorSubject<DomainAttributeModel[]> {
        if (!domainAttributeBliding$.value) {
            return new BehaviorSubject<DomainAttributeModel[]>(null);
        }
        if (!this.searchMode) {
            let filterNoRequireFlag = domainAttributeBliding$.value.filter(x => x.requiredFlag != true);
            let domainAttributeNoRequireFlag$ = new BehaviorSubject<DomainAttributeModel[]>(null);
            domainAttributeNoRequireFlag$.next(filterNoRequireFlag);
            return domainAttributeNoRequireFlag$;
        }
        return domainAttributeBliding$;
    }

    findRequireAttribute(): boolean {
        if (this.forms.controls.length != 0) {
            let formCurrent = this.forms.controls[this.forms.controls.length - 1];
            formCurrent.get(field.SUBMITTED).setValue(true);
            this.changeDetectorRef.detectChanges();
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (this.checkRequiredAttribute(ctl)) {
                    return true;
                }
            }
        }
        return false;
    }

    private checkRequiredAttribute(ctl) {
        return ctl.value.required && (ctl.value.multipleChoiceFlag && !ctl.value.choiceValue?.length) || (!ctl.value.choiceValue)
    }

    private clearForms() {
        this.actionForm = this.fb.group({
            forms: this.fb.array([])
        });
    }

    private clearInheritForms() {
        this.inheritForm = this.fb.group({
            inheritforms: this.fb.array([])
        });
    }

    private assignDataToInheritForm(productAttributeInherit: ProductAttributeViewModel) {
        if (productAttributeInherit.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleInheritDataToForm(productAttributeInherit);
            let choiceArrayDBId = this.createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit);
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag,
                    choiceMultiple, choiceArrayDBId, true));
        } else {
            this.inheritforms.push(
                this.createFormGroupWithInheritData(productAttributeInherit,
                    productAttributeInherit.multipleChoiceFlag, null, null, true));
        }
    }


    private createChoiceMultipleInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);;
        }
        return choiceMultiple;
    }

    private createChoiceMultipleDBIdInheritDataToForm(productAttributeInherit: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributesInherit(productAttributeInherit);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributesInherit(productAttributeInherit: ProductAttributeViewModel): ProductAttributeViewModel[] {
        return this.productAttributesInherit.filter(x => x.hierarchyKey == productAttributeInherit.hierarchyKey
            && x.displaySequence == productAttributeInherit.displaySequence);
    }

    private assignDataToForm(productAttribute: ProductAttributeViewModel) {
        let isInventoryAttribute = this.checkIsSRVCateAttribute(productAttribute.attributeTypeCode);
        if (productAttribute.multipleChoiceFlag) {
            let choiceMultiple = this.createChoiceMultipleData(productAttribute);
            let choiceArrayDBId = this.createChoiceDBIdData(productAttribute);
            this.addToAttributesLocked(isInventoryAttribute, productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, choiceMultiple, choiceArrayDBId, true));
        } else {
            this.addToAttributeLocked(isInventoryAttribute, productAttribute);
            this.forms.push(
                this.createFormGroupWithData(productAttribute, null, null, true));
        }
    }

    private addToAttributeLocked(isInventoryAttribute: boolean, productAttribute: ProductAttributeViewModel) {
        if (isInventoryAttribute) {
            var attributeLocked = new Select2Data();
            attributeLocked.id = productAttribute.attributeTypeCode;
            attributeLocked.text = productAttribute.attributeChoiceId;
            this.lockedAttribute.push(attributeLocked);
        }
    }

    private createChoiceMultipleData(productAttribute: ProductAttributeViewModel): string[] {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        let choiceMultiple: string[] = new Array<string>();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            choiceMultiple.push(productAttributeChoice.attributeChoiceId);
        }
        return choiceMultiple;
    }

    private createChoiceDBIdData(productAttribute: ProductAttributeViewModel): Select2Data[] {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        let choiceArrayDBId: Select2Data[] = new Array();
        for (let productAttributeChoice of filterProductAttributeTypes) {
            let dbIdData = new Select2Data();
            dbIdData.id = productAttributeChoice.productAttributeId;
            dbIdData.text = productAttributeChoice.attributeChoiceId;
            choiceArrayDBId.push(dbIdData);
        }
        return choiceArrayDBId;
    }

    private filterAttributeDisplay(productAttribute: ProductAttributeViewModel) {
        return this.productAttributes.filter(x => x.displaySequence == productAttribute.displaySequence);
    }

    private addToAttributesLocked(isInventoryAttribute: boolean, productAttribute: ProductAttributeViewModel) {
        let filterProductAttributeTypes = this.filterAttributeDisplay(productAttribute);
        for (let productAttributeChoice of filterProductAttributeTypes) {
            this.addToAttributeLocked(isInventoryAttribute, productAttributeChoice);
        }
    }

    public rowInValid(ctl: AbstractControl): boolean {
        return (ctl.get(field.CHOICE_VALUE).invalid || ctl.get(field.DUPLICATE).invalid) && ctl.get(field.SUBMITTED).value == true;
    }

    dataChange(value: string | string[], ctl) {
        ctl.get(field.CHOICE_VALUE).setValue(value);
        if (ctl.value.productAttributeTypeCode == this.SERVICECATEGORY_CODE) {
            if (ctl.value.choiceValue?.length) {
                this.serviceCateSelected.emit(true);
            } else {
                this.serviceCateSelected.emit(false);
            }
        }
        this.getInvGroupControl(ctl);
        this.checkChoice(ctl);
    }

    convertSrvCateToSelect2Data(choiceData: AttributeChoiceModel[]): Select2Data[] {
        let returnValue: Select2Data[] = new Array();
        let choiceDataSort = choiceData.sort((a, b) => (a.sortSequence < b.sortSequence ? -1 : 1));
        for (let choice of choiceDataSort) {
            let value = new Select2Data();
            value.id = choice.attributeChoiceId;
            value.text = choice.serviceCategoryName;
            returnValue.push(value);
        }
        return returnValue;
    }

    private createDomainAttributeNoSrvCate() {
        this.domainAttributeNotSrvCate$ = new BehaviorSubject<DomainAttributeModel[]>(null);
        if (this.domainAttribute$.value && !this.draftFlag) {
            let domainAttributes = this.domainAttribute$.value;
            if (this.srvCateAttributeSaved()) {
                domainAttributes = domainAttributes.filter(x => x.attributeTypeCode != this.SERVICECATEGORY_CODE);
            }
            if (this.invGroupCountrolSaved()) {
                domainAttributes = domainAttributes.filter(x => x.attributeTypeCode != this.INVGROUPCTRL_CODE);
            }
            this.domainAttributeNotSrvCate$.next(domainAttributes);
            return;
        }
        this.domainAttributeNotSrvCate$ = this.domainAttribute$;
    }

    private srvCateAttributeSaved(): boolean {
        if (this.productAttributes) {
            let filter = this.productAttributes.filter(x => x.attributeTypeCode == this.SERVICECATEGORY_CODE);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }
    private invGroupCountrolSaved(): boolean {
        if (this.productAttributes) {
            let filter = this.productAttributes.filter(x => x.attributeTypeCode == this.INVGROUPCTRL_CODE);
            if (filter?.length) {
                return true;
            }
        }
        return false;
    }

    private getInvGroupControl(ctl) {
        let invGroupControlAttr: boolean = false;
        if (ctl.value.productAttributeTypeCode == this.INVGROUPCTRL_CODE) {
            if (ctl.value.choiceValue?.length) {
                let filter = ctl.value.choiceData.filter(x => x.id == ctl.value.choiceValue);
                if (filter[0].text == "Yes") {
                    invGroupControlAttr = true;
                }
            }
        }
        this.invGroupCtrl.emit(invGroupControlAttr);
    }

    private overrideInvGroupAndServCate(value: boolean) {
        this.domainAttribute$.value.forEach(item => {
            if (item.attributeTypeCode == this.INVGROUPCTRL_CODE || item.attributeTypeCode == this.SERVICECATEGORY_CODE) {
                item.showOnNewFlag = value;
            }
        })
        if (value == true) {
            this.displayInvGroupCtlAndServCate();
        } else {
            this.removeInvGroupCtlAndServCate();
        }
    }

    removeInvGroupCtlAndServCate() {
        let forms = <UntypedFormArray>this.actionForm.get('forms');
        for (let i = forms.value.length - 1; i>= 0; i--) {
            if (forms.controls[i].get(field.ATTRTYPE_CODE).value == this.INVGROUPCTRL_CODE || forms.controls[i].get(field.ATTRTYPE_CODE).value == this.SERVICECATEGORY_CODE) {
                this.delete(i, forms.controls[i]);
            }
        }
    }

    displayInvGroupCtlAndServCate() {
        let attributeServiceCategory = this.domainAttribute$.value.filter(x => x.attributeTypeCode == this.SERVICECATEGORY_CODE);
        this.display(attributeServiceCategory);

        let attributeInvGroup = this.domainAttribute$.value.filter(x => x.attributeTypeCode == this.INVGROUPCTRL_CODE);
        this.display(attributeInvGroup);
    }

    get panelCollapse() {
        return this.inheritforms.length == 0 && this.forms.length == 0;
    }

    get newDisabled() {
        return !this.rootLevel && !this.newProduct;
    }

    public validateAttributeINVControl(): boolean {
        if (this.forms.controls.length != 0) {
            for (var i = 0; i <= this.forms.controls.length - 1; i++) {
                var ctl = this.forms.controls[i];
                if (this.checkAttributeINVControl(ctl)) {
                    return this.hasAttributeServiceCategory();
                }
            }
        }
        return true;
    }

    private checkAttributeINVControl(ctl: AbstractControl): boolean {
        if (ctl.get(field.ATTRTYPE_CODE).value == this.INVENTORY_CONTROL_CODE && this.attributeINVControlYes(ctl)) {
            return true;
        }
        return false;
    }

    private attributeINVControlYes(ctl: AbstractControl): boolean {
        let choiceDatas = ctl.get(field.CHOICE_DATA).value;
        if (choiceDatas?.length) {
            let choiceYes = choiceDatas.find(x => x.text.toUpperCase() == "YES");
            if (choiceYes?.id == ctl.get(field.CHOICE_VALUE).value) {
                return true;
            }
        }
        return false;
    }

    private hasAttributeServiceCategory(): boolean {
        for (var i = 0; i <= this.forms.controls.length - 1; i++) {
            var ctl = this.forms.controls[i];
            if (this.checkAttributeServiceCategory(ctl)) {
                return true;
            }
        }
        return false;
    }

    private checkAttributeServiceCategory(ctl: AbstractControl): boolean {
        if (ctl.get(field.ATTRTYPE_CODE).value == this.SERVICECATEGORY_CODE &&
            ctl.get(field.CHOICE_VALUE).value) {
            return true;
        }
        return false;
    }

    public getErrorMessage() {
        if (!this.validateAttributeINVControl()) {
            return this.SRV_REQUIRED_ERROR;
        }
    }
}


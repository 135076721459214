import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DxDataGridModule, DxTemplateModule } from "devextreme-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from "src/app/shared/ui/ui.module";
import { InsightDetailProfileComponent } from "./insight-detail-profile.component";

@NgModule({
    declarations: [
        InsightDetailProfileComponent
    ],
    exports: [
        InsightDetailProfileComponent
    ],
    imports: [
        ButtonsModule,
        BsDropdownModule,
        CollapseModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        DxDataGridModule,
        DxTemplateModule,
        MatIconModule,
        PanelsModule,
        Select2Module,
        UiModule
    ],
    providers: []
})
export class ProfileModule {    
}
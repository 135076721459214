import { 
    ChangeDetectionStrategy, 
    ChangeDetectorRef, 
    Component, 
    EventEmitter,
    Input,
    OnInit,
    Output, 
    ViewChild } from '@angular/core';

import { Observable } from 'rxjs';

import { FocusingDirective } from '../../../../../shared/ui/forms/inputs/focusing.directive';
import { LoadingNotifier } from '../../../../../shared/layout/loading-spinner';
import { OrderDetailIndividualAccountingModel } from '../../../../../core/models/order-model';
import { OrderDetailServices } from '../../../../../core/services/order-services';
import { 
    OrdersMapperService, 
    OrderDetailChildLoadData,
    OrderDetailIndividualAccountingView
} from '../..';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-accounting',
    templateUrl: './order-detail-accounting.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailAccountingComponent implements OnInit {
    
    public readonly SPINNER_NAME: string = "orderDetailAccounting";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @Input("id") id: string;
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;

    @Output("childLoadCompleted") childLoadCompleted = new EventEmitter<OrderDetailChildLoadData>();

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderDetailAccountingViews: OrderDetailIndividualAccountingView[] = new Array<OrderDetailIndividualAccountingView>();
    public orderLock: boolean;
    public countNumberOfItems: number = 0;

    constructor(private changeDetection: ChangeDetectorRef,
        private orderDetailService: OrderDetailServices,
        private mapperService: OrdersMapperService) {

    }
    ngOnInit(): void {
        this.checkCollape();
    }
    
    private checkCollape(): void {
        if (!this.orderDetailAccountingViews || this.orderDetailAccountingViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public startLoading() {
        this.loadingNotifier.show(this.SPINNER_NAME);
    }
    public stopLoading() {
        this.loadingNotifier.hide(this.SPINNER_NAME);
    }

    public loadOrderDetailIndividualAccounting(orderId: string, orderLock: boolean, partnerOrderId: string): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailIndividualAccounting(orderId, partnerOrderId)
            .subscribe(
                (result: OrderDetailIndividualAccountingModel[]) => {
                    this.fillNumberOfItems(result);
                    this.orderDetailAccountingViews = this.mapperService.toAccountingViews(result);
                    this.childLoadCompleted.emit({
                        recordCount: result?.length
                    });
                    this.checkCollape();
                    this.changeDetection.detectChanges();
                    observer.next(true);
                    observer.complete();
                }
            );
        });
    }

    public loadOrderDetailProductAccounting(orderId: string, orderLock: boolean, productId: string): Observable<boolean> {
        if (!orderId) {
            return;
        }
        this.orderLock = orderLock;
        return new Observable(observer => {
            this.orderDetailService.getOrderDetailProductAccounting(orderId, productId)
            .subscribe(
                (result: OrderDetailIndividualAccountingModel[]) => {
                    this.fillNumberOfItems(result);
                    this.orderDetailAccountingViews = this.mapperService.toAccountingViews(result);
                    this.childLoadCompleted.emit({
                        recordCount: result?.length
                    });
                    this.checkCollape();
                    this.changeDetection.detectChanges();
                    observer.next(true);
                    observer.complete();
                }
            );
        });
    }
    
    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }
    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }
    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }
    private fillNumberOfItems(models: OrderDetailIndividualAccountingModel[]) {
        if (!models || models.length === 0) {
            this.countNumberOfItems = 0;
            return;
        }
        this.countNumberOfItems = models.length;
    }
    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }
}
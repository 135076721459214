import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { select2Status, select2FormStatus } from "./select2-configuration";
import { StatusConstant } from "./shared/constants/status.constant";

@Component({
    selector: 'status-color-dropdown',
    templateUrl: './status-color-dropdown.component.html'
})
export class StatusColorDropdownComponent implements OnChanges {

    @Input() status: string;
    @Input() statusReferences: StatusReferenceModel[];
    @Input() deleteFlag = true;
    @Input() editFlag = true;
    @Input() disabled: boolean = false;
    @Input() grid: boolean = true;
    @Input() includeEmpty: boolean = false;
    @Output() updateStatus = new EventEmitter<string>();

    select2DefaultOption = this.getConfiguration();
    form = new UntypedFormGroup({
        statusCode: new UntypedFormControl()
    });
    public statusReferenceData: StatusReferenceModel[] = new Array();
    get statusClass(): string {
        let statusCode = this.form.controls['statusCode'].value;
        switch (statusCode) {
            case StatusConstant.ACTIVE:
            case StatusConstant.OPEN:
                return 'active';
            case StatusConstant.INACTIVE:
            case StatusConstant.USED:
                return 'inactive';
            case StatusConstant.DELETED: 
            case StatusConstant.EXPIRED:
            case StatusConstant.SUSPENDED:
            case StatusConstant.VOID:
                return 'deleted';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["status"]) {
            this.form.controls['statusCode'].patchValue(this.status);
        }

        if (changes["disabled"]) {
            if (this.disabled) {
                this.form.controls['statusCode'].disable();
            } else {
                this.form.controls['statusCode'].enable();
            }
        }

        if (changes["grid"]) {
            this.select2DefaultOption = this.getConfiguration();
        }

        if (changes["statusReferences"]) {
            this.createStatusReferenceData();
        }
    }

    changeStatus(e) {
        this.form.controls['statusCode'].patchValue(e);
        if (e.length > 1) {
            this.updateStatus.emit(null);
        } else {
            this.updateStatus.emit(e);
        }
    }

    private getConfiguration(): any {
        if (this.grid) {
            return select2Status;
        } else {
            return select2FormStatus;
        }
    }

    private createStatusReferenceData() {
        this.statusReferenceData = [];
        if (this.includeEmpty) {
            let empty = new StatusReferenceModel();
            this.statusReferenceData.push(empty);
        }
        if (this.statusReferences?.length) {
            this.statusReferenceData.push(...this.statusReferences);
        }
    }

    get statusCode(): string | string[] {
        return this.form.controls['statusCode'].value;
    }

    set statusCode(value: string | string[]) {
        this.form.controls['statusCode'].patchValue(value);
    }
}
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { PaymentCurrenciesItemsModel } from 'src/app/core/models/cashbook-model/cashbook-document-payment-currencies-items.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
  selector: 'op-cashbook-detail-fop',
  templateUrl: './cashbook-detail-fop.component.html',
})
export class CashbookDetailFopComponent {

  public collapsed: boolean = false;
  public collapsed2: boolean = false;
  public focusing: boolean = false;

  @Input() dataDetail: PaymentCurrenciesItemsModel[];

  @ViewChild("panel") panel: any;
  @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

  constructor(private changeDetection: ChangeDetectorRef) {
  }

  public collapsedStatus(collapsed: any) {
    this.collapsed = collapsed;
  }

  public togglePanel($event: MouseEvent) {
    this.panel.toggleCollapse($event);
    this.collapsed = !this.collapsed;
  }

  public togglePanelTop($event: MouseEvent, index, data) {
    $event.preventDefault();
    this.dataDetail[index]['showPanel'] = !((data.showPanel) ? data.showPanel : false);
    this.changeDetection.detectChanges();
  }

  public getUnderlineClass(rowIndex: number): string {
    if (rowIndex === 0) {
      return "";
    }
    return "order-detail-start-section";
  }

  public getShowDivSection(rowIndex: number): boolean {
    if (rowIndex === 0) {
      return false;
    }
    return true;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherAttributeAndRuleComponent } from './voucher-attribute-and-rule.component';
import { VoucherAttributeComponent } from './voucher-attribute/voucher-attribute.component';
import { VoucherHashtagComponent } from './voucher-hashtag/voucher-hashtag.component';
import { VoucherRestrictionComponent } from './voucher-restriction/voucher-restriction.component';
import { VoucherValidityComponent } from './voucher-validity/voucher-validity.component';
import { VoucherPointOfSalesComponent } from './voucher-point-of-sales/voucher-point-of-sales.component';
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { MatIconModule } from '@angular/material/icon';
import { RulesConfigModule } from 'src/app/core/components/rules-config/rules-config.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VoucherPointOfPaymentsComponent } from './voucher-point-of-payments/voucher-point-of-payments.component';

@NgModule({
    declarations: [
        VoucherAttributeAndRuleComponent,
        VoucherAttributeComponent,
        VoucherHashtagComponent,
        VoucherRestrictionComponent,
        VoucherValidityComponent,
        VoucherPointOfSalesComponent,
        VoucherPointOfPaymentsComponent
    ],
    imports: [
        BsDropdownModule,
        CommonModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        OopsChoiceValueModule,
        PanelsModule,
        ReactiveFormsModule,
        RulesConfigModule,
        Select2Module
    ],
    exports: [
        VoucherAttributeAndRuleComponent
    ]
})
export class VoucherAttributeAndRuleModule { }

export const select2SearchRecieverOrganisation = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: 0
}

export const select2SearchDocumentType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchStatusType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchDistributionType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchTeletypeAddressElementPriorit = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}


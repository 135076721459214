import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from '../../authentication/auth.service';

import { VehicleTypeReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
    providedIn: 'root'
})
export class VehicleTypeReferenceService extends ApiServiceBase {
    private readonly PATH = "/system/VehicleTypeReferences"

    constructor(httpClient: HttpClient,
        authService: AuthService,) {
        super(httpClient, authService);
    }

    public getVehicleTypeReference(vehicleGroupCode: string): Observable<VehicleTypeReferenceModel[]> {
        return this.httpGet<VehicleTypeReferenceModel[]>(this.PATH + '?vehicleGroupCode=' + vehicleGroupCode, null)
            .pipe(
                map(res => res.body)
            )
    }
}
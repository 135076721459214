import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ScheduleDistributionSearchModel } from 'src/app/core/models/schedule-distribution-model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { ScheduleQueueResultCode } from 'src/app/modules/schedule/shared/schedule.contant';
import { LoadingNotifier } from 'src/app/shared/layout/loading-spinner';
import { ScheduleDistributionSearchComponent } from '../schedule-distribution-search/schedule-distribution-search.component';
import { MapperService } from '../shared/mapper.service';
import ScheduleDistributionSearchView from '../shared/schedule-distribution-search.view';

@Component({
    selector: 'op-schedule-distribution-table',
    templateUrl: './schedule-distribution-table.component.html',
    providers: [MapperService]
})
export class ScheduleDistributionTableComponent implements OnChanges, AfterViewInit {
    readonly SPINNER_NAME: string = "scheduleDistributionSearchSpinner";
    public loadingNotifier = new LoadingNotifier();
    public rows = 0;
    public paggingView: PagingDataView[];
    public searchResult: ScheduleDistributionSearchView[];
    public focused: boolean = true;

    @Input() searchResults: ScheduleDistributionSearchModel[];
    @Output() searchClick = new EventEmitter<ScheduleDistributionSearchModel>();

    @ViewChild('dataGridSearch', { static: false }) dataGrid: DxDataGridComponent;
    @ViewChild(ScheduleDistributionSearchComponent) scheduleDistributionSearchComponent: ScheduleDistributionSearchComponent;

    constructor(private mapperService: MapperService,
        private changeDetectionRef: ChangeDetectorRef,) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchResults']) {
            this.mapData();
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    private mapData() {
        this.loadingNotifier.show(this.SPINNER_NAME);
        if (!this.searchResults) {
            return;
        }
        this.paggingView = new Array();
        let index = 1;
        var temps = new Array<ScheduleDistributionSearchView>();
        for (let model of this.searchResults) {
            this.addPaggingView(index, model.scheduleQueueId);
            temps.push(this.mapperService.toScheduleDistrbutionSearchView(model, index));
            index++;
        }
        this.searchResult = temps;
        this.loadingNotifier.hide(this.SPINNER_NAME);
        this.changeDetectionRef.detectChanges();
    }

    public addPaggingView(index: number, id: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        this.paggingView.push(pview);
    }

    selectedRow(e) {
        this.selectedItem = true;
        this.itemSelected = e.data;
        this.dataGrid.instance.selectRowsByIndexes(e.rowIndex);
    }

    public scheduleQueueSearchData: ScheduleDistributionSearchModel[];
    public header: string[];
    public selectedItem = false;
    public itemSelected: any;
    public exportData: any;

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.exportData = this.getExportData();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    public refreshTable() {
        this.searchClick.emit();
    }

    cellPrepared(e) {
        if (e.rowType == 'header' && e.column.dataField == 'numberOfProducts') {
            e.cellElement[0].innerHTML = e.cellElement[0].innerHTML.replace(/\\n/g, "<br/>");
        }
        if (e.rowType != 'data') {
            return;
        }
        
        if (e.column.dataField == 'scheduleQueueResultName') {
            this.setResultCellColor(e)
        } 
    }

    private setResultCellColor(e) {
        switch (e.data.scheduleQueueResultCode) {
            case ScheduleQueueResultCode.positive:
                e.cellElement.addClass('dx-cell-success');
                break;
            case ScheduleQueueResultCode.issue:
                e.cellElement.addClass('dx-cell-warning');
                break;
            case ScheduleQueueResultCode.error:
            case ScheduleQueueResultCode.cancelled:
                e.cellElement.addClass('dx-cell-danger');
                break;    
        }
    }
}

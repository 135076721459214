import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { UntypedFormArray } from '@angular/forms';

@Injectable()
export class CalendarValidationService {

    public findDuplicateDate(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "calendar" && ctl.value.calendarType.toLowerCase() == "date") {   
                    if (ctl.value.dateType == ctlCheck.value.dateType &&
                        ctl.value.calendarOperator == ctlCheck.value.calendarOperator &&
                        ctl.value.timeZone == ctlCheck.value.timeZone &&
                        ctl.value.startDate == ctlCheck.value.startDate &&
                        ctl.value.endDate == ctlCheck.value.endDate &&
                        ctl.value.weekDaysDisplay ==  ctlCheck.value.weekDaysDisplay && 
                        this.checkArrayEqual(ctl.value.weekDays,ctlCheck.value.weekDays)
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }

    public findDuplicateDateTime(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "calendar" && ctl.value.calendarType.toLowerCase() == "datetime") {   
                    if (ctl.value.dateType == ctlCheck.value.dateType &&
                        ctl.value.calendarOperator == ctlCheck.value.calendarOperator &&
                        ctl.value.timeZone == ctlCheck.value.timeZone &&
                        ctl.value.startDate == ctlCheck.value.startDate &&
                        ctl.value.endDate == ctlCheck.value.endDate &&
                        ctl.value.weekDaysDisplay ==  ctlCheck.value.weekDaysDisplay && 
                        this.checkArrayEqual(ctl.value.weekDays,ctlCheck.value.weekDays)
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }

    public findDuplicateTime(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "calendar" && ctl.value.calendarType.toLowerCase() == "time") {   
                    if (ctl.value.dateType == ctlCheck.value.dateType &&
                        ctl.value.calendarOperator == ctlCheck.value.calendarOperator &&
                        ctl.value.timeZone == ctlCheck.value.timeZone &&
                        ctl.value.startTime == ctlCheck.value.startTime &&
                        ctl.value.endTime == ctlCheck.value.endTime &&
                        ctl.value.weekDaysDisplay ==  ctlCheck.value.weekDaysDisplay && 
                        this.checkArrayEqual(ctl.value.weekDays,ctlCheck.value.weekDays)
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }

    public findDuplicateWeekDay(forms:UntypedFormArray,ctlCheck ,ctlIndex) {
        var duplicate:boolean = false;
        for (var i = 0;i<=forms.controls.length -1;i++) {
            if (i != ctlIndex) {
                var ctl = forms.controls[i];
                if (ctl.value.type == "calendar" && ctl.value.calendarType.toLowerCase() == "weekday") {   
                    if (ctl.value.dateType == ctlCheck.value.dateType &&
                        ctl.value.calendarOperator == ctlCheck.value.calendarOperator &&
                        this.checkArrayEqual(ctl.value.weekDays,ctlCheck.value.weekDays)
                    ){ 
                        duplicate = true;
                    }
                } 
            }
        }
        return duplicate;
    }

    private checkArrayEqual(array1:string[],array2:string[]) :boolean {
        let returnValue:boolean = true;
        if (array1 == null && array2 == null) {
            return true;
        }
        if (array1 != null && array2 != null) {
            if (array1.length > 0 && array2.length > 0) {
                if (array1.length == array2.length) {
                    for (let value of array1) {
                        var filter = array2.filter(x=>x == value);
                        if (filter.length == 0) {
                            return false;
                        }
                    }
                }
                else {
                    return false;
                }
            }
        }
        else {
            return false;
        }
        return returnValue;
    }
    
    public clearAllOverlap(forms:UntypedFormArray){
        for (var i = 0;i <= forms.controls.length -1; i++) {
            let ctl = forms.controls[i];
            this.updateValidatorOverlap(ctl, false);
        }
    }

    public updateValidatorOverlap(ctl, overlap: boolean = false) {
        if (overlap) {
            ctl.get('overlap').setValue(null);
            ctl.get('overlap').setValidators(Validators.required);
            ctl.get('overlap').updateValueAndValidity();
        } else {
            ctl.get('overlap').setValue(false);
            ctl.get('overlap').setValidators(Validators.nullValidator);
            ctl.get('overlap').updateValueAndValidity();
        }
    }

    private weekDayDuplicate(weekdayChecks:string[] ,weekdays: string[]) {
        for (let value of weekdayChecks) {
            var filter = weekdays.filter(x=>x == value);
            if (filter.length != 0) {
                return true;
            }
        }
        return false;
    }

    private hasWeekDays(ctlCurrent: AbstractControl, ctl: AbstractControl) : boolean {
        return (ctlCurrent.value.weekDays?.length && ctl.value.weekDays?.length);
    }

    public findDateOverlap(ctlCurrent: AbstractControl, ctl: AbstractControl) {
        var overlap:boolean = false;
        if (ctlCurrent.value.type == "calendar" && 
            ctlCurrent.value.calendarType.toLowerCase() == "date" && 
            ctlCurrent.value.calendarOperator == "><") {   
            if (ctl.value.calendarOperator == ctlCurrent.value.calendarOperator) {
                if (!this.hasWeekDays(ctlCurrent, ctl)){
                    overlap = this.dateOverlap(ctlCurrent, ctl);
                } else {
                    overlap = this.dateAndWeekDaysOverlap(ctlCurrent, ctl);
                }
               
                if (overlap) {
                    this.updateValidatorOverlap(ctlCurrent, true);
                    this.updateValidatorOverlap(ctl, true);
                }
            }
        } 
        return overlap;
    }

    private dateOverlap(ctlCurrent: AbstractControl, ctl: AbstractControl) : boolean {
        return ctlCurrent.value.dateType == ctl.value.dateType && (ctlCurrent.value.startDate <= ctl.value.startDate &&
            ctlCurrent.value.endDate >= ctl.value.endDate);
    }

    private dateAndWeekDaysOverlap(ctlCurrent: AbstractControl, ctl: AbstractControl) : boolean {
        let dateOvelap = this.dateOverlap(ctlCurrent, ctl)
        if (dateOvelap){
             return this.weekDayDuplicate(ctlCurrent.value.weekDays, ctl.value.weekDays);
        }
        return false;
    }
}
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event-package';
import { DxDataGridComponent } from 'devextreme-angular';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityCodeReferenceModel } from 'src/app/core/models/security-model/security-code-reference.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-group-security-code-table',
    templateUrl: './group-security-code-table.component.html'
})
export class GroupSecurityCodeTableComponent implements OnChanges, AfterViewInit {
    @Input() securityCodeData: SecurityCodeReferenceModel[] = [];
    @Input() statusReferences: StatusReferenceModel[];
    @Input() childNodeName: string[] = [];
    @Input() securityCodeTablePanelToggler = true;
    @Input() focused: boolean;
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Output() onApply = new EventEmitter<SecurityCodeReferenceModel[]>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild('dataGridSecurityCode', { static: false }) dataGrid: DxDataGridComponent;

    public tableData: SecurityCodeReferenceModel[];
    public rows = 0;
    public selectedSecurityCode: SecurityCodeReferenceModel[] = [];
    public itemSelected: SecurityCodeReferenceModel;
    public showCopyPopover = false;
    public showExportPopover = false;
    public showPrintPopover = false;
    public datagridHeight = '555px';
    public exportData: any;
    public header: string[];
    public collapsed: boolean;
    public focusing: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['securityCodeData'] || changes['childNodeName']) {
            this.refreshTable();
        }
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    checkboxValueChange(data) {
        let index = this.selectedSecurityCode?.indexOf(data);
        if (index != -1) {
            this.selectedSecurityCode.splice(index, 1);
        } else {
            this.selectedSecurityCode.push(data)
        }
    }

    onFocus() {
        this.isFocused.emit(false);
    }

    onResized(event: ResizedEvent) {
        if (event.newRect.height > 0) {
            this.onPanelHeightChange.emit(event.newRect.height);
        } 
    }

    refreshTable() {
        this.tableData = this.securityCodeData?.slice();
        this.removeSelectedFromTable();
        this.selectedSecurityCode = [];
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });
    }

    removeSelectedFromTable() {
        if (this.childNodeName != null) {
            this.childNodeName.forEach(child => {
                let index = this.tableData?.findIndex(item => item.securityName == child);
                if (index != -1) {
                    this.tableData.splice(index, 1);
                }
            })
        }   
    }

    addSecurityCode() {
        this.onApply.emit(this.selectedSecurityCode);
        let newCodeChildName = this.selectedSecurityCode.map(item => item.securityName);
        this.childNodeName = this.childNodeName.concat(newCodeChildName);
        this.refreshTable();
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr
        });  
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
            {
                Code: dat.securityCode,
                Name: dat.securityName,
            }
        ));
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
        this.header = this.header.filter(item => item != '');
    }
}

import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { OrderDetailIndividualCommunicationOtherView } from "../..";
import { LoadingNotifier } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { CommunicationOtherListActionBarComponent } from "./communication-other-list-action-bar/communication-other-list-action-bar.component";
import { GlobalStateService } from "src/app/shared/ui/uiservice/global-state.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { Observable, Subject } from "rxjs";
import { cloneDeep } from 'lodash';
import { takeUntil } from "rxjs/operators";
import { IndividualChangeMapperService } from "../../individual-change-mapper.service";
import { OrderIndividualChangeService } from "src/app/core/services/order-services/order-individual-change.service";
import { IndividualChangeResponseModel } from "src/app/core/models/order-model";
import { OrderChangeIndividualResponseView } from "../../views/order-change-individual-response.view";

@Component({
    selector: 'op-order-detail-communication-other-list',
    templateUrl: './order-detail-communication-other-list.component.html'
})
export class OrderDetailCommunicationOtherListComponent implements OnInit, AfterViewChecked, OnDestroy {
    private readonly newButtonId = this.stringHelperService.NewGuid();
    private readonly newPanelId = this.stringHelperService.NewGuid();
    public readonly SPINNER_NAME: string = "orderDetailCommunicationOthers";
    public readonly SPINNER_FULL_SCREEN: boolean = false;

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild("panel") panel: any;
    @ViewChildren(CommunicationOtherListActionBarComponent) actionBars: QueryList<CommunicationOtherListActionBarComponent>;
    
    @Input("id") id: string;
    @Input("data") communicationData: OrderDetailIndividualCommunicationOtherView[];
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("collapseWhenLoad") collapeWhenLoad: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("changeSessionId") changeSessionId: string;
    @Input("headerOrderId") headerOrderId: string;
    @Input("disableActionButton") disableActionButton: boolean = false;

    @Output() dataChange = new EventEmitter<OrderDetailIndividualCommunicationOtherView[]>();
    @Output() completeChange = new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() failedChange = new EventEmitter<OrderChangeIndividualResponseView>();
    @Output() startChange = new EventEmitter();

    newOrderDetailCommunicationOtherView: OrderDetailIndividualCommunicationOtherView;
    editOrderDetailCommunicationOtherViews: OrderDetailIndividualCommunicationOtherView[] = [];

    public collapsed: boolean;
    public focused: boolean;
    public loadingNotifier = new LoadingNotifier();
    public orderLock: boolean;

    get orderDetailCommunicationOtherViews(): OrderDetailIndividualCommunicationOtherView[] {
        return this.communicationData;
    }

    get countNumberOfItems(): number {
        return this.orderDetailCommunicationOtherViews?.length ?? 0;
    };

    get newButtonDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.newButtonId);
    }

    get newPanelDisabled$(): Observable<boolean> {
        return this.globalStateService.disabled$(this.newPanelId);
    }

    private destroy$ = new Subject();

    constructor(private globalStateService: GlobalStateService,
        private stringHelperService: StringHelperService,
        private individualChangeMapperService: IndividualChangeMapperService,
        private individualChangeService: OrderIndividualChangeService) {
            
        this.globalStateService.register(this.newButtonId);
        this.globalStateService.register(this.newPanelId);
        this.newPanelDisabled$
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                disabled => {
                    if (disabled) {
                        this.closeNewPanel();
                    }
                }
            )
    }

    ngOnInit(): void {
        this.checkCollapse();
    }

    ngAfterViewChecked(): void {
        this.toggleStatePrimaryButtons();
        this.toggleDisableActionButton();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    public showCounter(): boolean {
        return this.showPanelCounter && this.countNumberOfItems > 0;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    private checkCollapse(): void {
        if (!this.orderDetailCommunicationOtherViews || this.orderDetailCommunicationOtherViews.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }

    public onPrimaryClicked(view: OrderDetailIndividualCommunicationOtherView, index: number) {
        this.setPrimaryFlag(view, this.orderDetailCommunicationOtherViews);
        this.onApplyEditClick(view, index);
    }

    private setPrimaryFlag(primaryView: OrderDetailIndividualCommunicationOtherView, views: OrderDetailIndividualCommunicationOtherView[]) {
        views.map(x => x.primaryFlag = false);
        let view = views.find(x => x.communicationOtherId === primaryView.communicationOtherId);
        view.primaryFlag = true;
    }

    private toggleStatePrimaryButtons() {
        if (!this.orderDetailCommunicationOtherViews?.length || !this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => {
            const primaryFlag = this.orderDetailCommunicationOtherViews[index].primaryFlag;
            actionBar.togglePrimaryButton(!primaryFlag);
        })
    }

    private toggleDisableActionButton(): void {
        if (!this.actionBars?.length) return;
        this.actionBars.forEach((actionBar, index) => {
            actionBar.disableActionButton(this.disableActionButton);
        })
    }

    onApplyNewClick(view: OrderDetailIndividualCommunicationOtherView) {
        
        let communicationOtherViews = this.createAddNewCommunicationOtherToViews(view);

        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationOthers(communicationOtherViews,
            this.id);
        this.startChange.emit();
        this.individualChangeService.orderChangeCommunicationOther(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {
                        this.closeNewPanel();
                        this.dataChange.emit(this.orderDetailCommunicationOtherViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }

    onCloseNewClick() {
        this.closeNewPanel();
        this.globalStateService.enableAll();
    }

    private closeNewPanel() {
        this.newOrderDetailCommunicationOtherView = null;
    }

    private createAddNewCommunicationOtherToViews(view: OrderDetailIndividualCommunicationOtherView): OrderDetailIndividualCommunicationOtherView[]  {
        
        this.communicationData = this.orderDetailCommunicationOtherViews || [];
        let communictionOthersToSave = new Array<OrderDetailIndividualCommunicationOtherView>();
        if (this.orderDetailCommunicationOtherViews?.length) {
            this.orderDetailCommunicationOtherViews.map(other => communictionOthersToSave.push(cloneDeep(other)));
        }
        communictionOthersToSave.push(cloneDeep(view));
        return communictionOthersToSave
    }

    onNewClick() {
        this.openNewPanel();
        this.globalStateService.disableAllExcept(this.newPanelId);
    }

    private openNewPanel() {
        this.newOrderDetailCommunicationOtherView = this.getEmptyView();
    }

    private getEmptyView(): OrderDetailIndividualCommunicationOtherView {
        return {
            primaryFlag: false,
            communicationOtherId: null,
            orderId: null,
            communicationOtherTypeCode: null,
            communicationOtherTypeName: null,
            mailAddress: null,
            orderSalesStatusCode: null
        }
    }

    onEditClicked(view: OrderDetailIndividualCommunicationOtherView) {
        this.openEditPanel(view);
    }

    private openEditPanel(item: OrderDetailIndividualCommunicationOtherView) {
        const index = this.orderDetailCommunicationOtherViews.indexOf(item);
        if (index < 0) return;
        this.editOrderDetailCommunicationOtherViews[index] = item;
        this.globalStateService.disableAll();
    }

    onDeleteClicked(view: OrderDetailIndividualCommunicationOtherView) {
        
        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationOthers([view], 
            this.id);

        this.startChange.emit();
        this.individualChangeService.orderCancelCommunicationOther(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {
                    if (response) {
                        this.setFirstCommunicationOtherAsPrimary();
                        this.dataChange.emit(this.orderDetailCommunicationOtherViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }

    private setFirstCommunicationOtherAsPrimary() {
        const primaryCommunicationOther = this.orderDetailCommunicationOtherViews?.find(view => view?.primaryFlag);
        if (!primaryCommunicationOther && this.orderDetailCommunicationOtherViews?.length) {
            this.orderDetailCommunicationOtherViews[0].primaryFlag = true;
        }
    }

    onApplyEditClick(view: OrderDetailIndividualCommunicationOtherView, index: number) {

        this.orderDetailCommunicationOtherViews[index] = view;
        let models = this.individualChangeMapperService.orderDetailIndividualCommunicationOthers(this.orderDetailCommunicationOtherViews,
            this.id
        );

        this.startChange.emit();
        this.individualChangeService.orderChangeCommunicationOther(this.headerOrderId,
            this.changeSessionId,
            models)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {
                        this.closeEditPanel(index);
                        this.dataChange.emit(this.orderDetailCommunicationOtherViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }

    private closeEditPanel(index: number) {
        this.editOrderDetailCommunicationOtherViews[index] = null;
        this.globalStateService.enableAll();
    }

    onCloseEditClick(index: number) {
        this.closeEditPanel(index);
    }

    public onUdoClick(index: number) {

        let orderCommunicationOther = this.orderDetailCommunicationOtherViews[index];
        this.startChange.emit();
        this.individualChangeService.orderUndoIndividualChange(this.headerOrderId, orderCommunicationOther.orderId, this.changeSessionId)
            .subscribe(
                (response: IndividualChangeResponseModel) => {

                    if (response) {
                        this.closeEditPanel(index);
                        this.dataChange.emit(this.orderDetailCommunicationOtherViews);
                        this.globalStateService.enableAll();
                        this.completeChange.emit({
                            sessionId: response.sessionId
                        });
                    }
                },
                (failedResponse: IndividualChangeResponseModel) => {
                    this.failedChange.emit({
                        sessionId: failedResponse.sessionId,
                        message: failedResponse.message
                    });
                }
            );
    }
}
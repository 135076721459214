import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { FormOfPaymentReferenceService } from '../../services/system-services/form-of-payment-reference.service';

const FORM_OF_PAYMENT_OPTION = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    width: 'auto',
    dropdownAutoWidth: true
}

@Component({
    selector: 'op-form-of-payment-selection',
    templateUrl: './form-of-payment-selection.component.html'
})
export class FormOfPaymentSelectionComponent implements OnChanges {
    public _value: string | string[];
    public formOfPaymentOption = FORM_OF_PAYMENT_OPTION;
    public loadSuccess: boolean = false;
    public formOfPaymentReferences$ = new BehaviorSubject<Select2Data[]>(null);

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input() formOfPaymentTypeCode: string;
    @Input() disabled: boolean = false;
    @Output() dataChange: EventEmitter<string> = new EventEmitter();

    constructor(private formOfPaymentReferenceService: FormOfPaymentReferenceService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formOfPaymentTypeCode']) {
            this.loadFormOfPaymentReferences(this.formOfPaymentTypeCode);   
        }
    }

    public loadFormOfPaymentReferences(formOfPaymentTypeCode: string) {
        if (!formOfPaymentTypeCode) {
            this.formOfPaymentReferences$.next(null);
            return;
        }
        this.formOfPaymentReferenceService.getBy(formOfPaymentTypeCode)
            .subscribe(
                (response) => {
                    this.formOfPaymentReferences$.next(response.map(x => new Select2Data(x.formOfPaymentCode, x.formOfPaymentName)));
                    if (!this._value) {
                        this._value = response.length > 0 ? this.formOfPaymentReferences$?.value[0]?.id : null;
                        this.onDataChange(this._value);
                        this.loadSuccess = true;
                    } 
                }
            )
    }

    public onDataChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

}
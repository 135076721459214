import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { CountryReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NationalityView } from "../shared/nationality-view";

@Component({
    selector: 'op-insight-nationality-table',
    templateUrl: './nationality-table.component.html'
})
export class InsightNationalityTableComponent {
    public selectedItem: NationalityView;
    public customToolbarMarginTop: number;
    public rows = 0;
    public exportData: any;
    public header: string[];
    public pdfDefaultStyle = {
      fontSize: 10
    };

    @Input() insightNationalities: NationalityView[] = new Array();
    @Input() countryReferences: CountryReferenceModel[];
    @Output() onEdit = new EventEmitter();

    @ViewChild('dataGridNationality', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
      this.getDataGridHeader();
  }

    selectedRow(e) {
        this.selectedItem = e.data;
    }

    public getNumberOfRows(e) {
      this.rows = e.component.totalCount();
      this.getExportData()?.done((filteredData) => {
        this.exportData = this.mapExportData(filteredData);
      });        

      this.changeDetectionRef.detectChanges();
    }

    public mapExportData(filteredData) {
      return filteredData.map(dat => (
          {
              No: dat.no,
              Nationality: dat.countryName,
              Primary: dat.primaryFlag,
              PlaceOfBirth: dat.placeOfBirth,
              CountryOfBirth: this.countryReferences?.find(item => item.countryCode == dat.countryOfBirthCode)?.countryName,
          }
      ));
    }

  private getExportData() {
      let filterExpr = this.dataGrid.instance.getCombinedFilter();
      let gridDataSource = this.dataGrid.instance.getDataSource();
      return gridDataSource?.store().load({
          filter: filterExpr,
      });
  }

  private getDataGridHeader() {
      this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
  }

  getCountryOfBirthName = (row: NationalityView) => {
    return this.countryReferences?.find(item => item.countryCode == row.countryOfBirthCode)?.countryName;
  }

  editInsightNationalitieDetail() {
    this.onEdit.emit();
  }

  public onCellPrepared(e) {
    if (e.rowType != 'data') {
        return;
    }
    if (e.column.dataField == "no") {
        e.cellElement.css("text-align", "right");  
    }
}
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { InsightOrderSearchFilterModel } from "../../models/individual-model/individual-order-model/insight-order-search-filter.model";
import { InsightOrderSearchModel } from "../../models/individual-model/individual-order-model/insight-order-search.model";

@Injectable({
    providedIn: 'root'
})
export class InsightOrderService extends ApiServiceBase {
    private readonly PATH = '/individual/insight/insightOrders';

    constructor(httpClient: HttpClient,
        authService: AuthService) {
            super(httpClient, authService)
    }

    public searchInsightOrders(model: InsightOrderSearchFilterModel): Observable<InsightOrderSearchModel[]> {
        return this.httpPost<InsightOrderSearchModel[]>(this.PATH + "/search/", model)
            .pipe(
                map(response => {
                return response.body;
                })
            );
    }
}
import {
    ViewChildren,
    QueryList,
    EventEmitter,
    Input,
    Output,
    NgZone,
    OnInit,
    OnDestroy,
    Component,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import { ConditionReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';

import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { PricingDetailConstant } from '../../../shared/pricing-detail.constant';
import { ProductEligibleRestrictedConverter } from '../../product-eligible-restricted/shared/product-eligible-restricted.converter';
import { Select2OptionsService } from "../../product-eligible-restricted/shared/select2-options.service";
import {
    PriceRuleAttributeAndRuleRowView,
    PriceRuleSalesBucketView,
    PriceRuleServiceCategoryView
} from '../../product-eligible-restricted/shared/views';
import { AttributeAndRuleBase } from "../../shared/attribute-and-rule.base";
import { Select2Option } from '../../shared/attribute-and-rule/views/select2.option';
import { ServiceCategoriesAndSalesBucketConverter } from './service-categories-and-sales-bucket.converter';

import { PricingService } from 'src/app/core/services/pricing-services';
import { ConditionReferenceService } from 'src/app/core/services/system-services';
import { takeUntil } from "rxjs/operators";

@Component({
    template: ''
})
export abstract class ServiceCategoriesAndSalesBucketBase
    extends AttributeAndRuleBase
    implements OnInit, OnDestroy {

    abstract title: string;
    abstract priceRuleAttributeGroupCode: string;
    
    public rows$ = new BehaviorSubject<Array<PriceRuleAttributeAndRuleRowView>>(Array<PriceRuleAttributeAndRuleRowView>());

    public focusing: boolean = false;

    @Input() set data(val: PriceRuleAttributeAndRuleRowView[]) {
        if (val?.length) {
            this.rows$.next(val);
            this.togglePanelCollapseStatus(false);
        }
    }
    @Input() disabled: boolean = false;
    @Output() dataChange = new EventEmitter();
    @ViewChildren(NgForm) forms: QueryList<NgForm>;

    public select2ServiceSalesOption = new Select2Option('<Type>')
    public select2ServiceSalesDataList = [
        new Select2Data(),
        new Select2Data(PricingDetailConstant.SERVICES_CATEGORIES_CODE, 'Services Categories'),
        new Select2Data(PricingDetailConstant.SALES_BUCKET_CODE, 'Sales Bucket')
    ]
    public select2ServiceCategoriesOption = new Select2Option("<Type>");
    public select2InventoryDimensionOption = new Select2Option("<SubType>");
    public select2SalesBucketOption = new Select2Option("<Type>");
    public select2IsIsNotConditionOption: Select2Option;

    public select2IsIsNotConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2ServiceCategoriesDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2SalesBucketDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2InventoryDimensionDataList$ = new BehaviorSubject<Select2Data[]>(null);
    public select2IsIsNotLessThanMoreThanConditionDataList$ = new BehaviorSubject<Select2Data[]>(null);

    public select2IsIsNotLessThanMoreThanConditionOption: Select2Option;
    private unsubscribe$ = new Subject();

    get requiredAllOrNoneControlNames() {
        return [
            [
                'inventoryDimensionCode',
                'serviceCategoryThresholdValue'
            ],
            [
                'inventoryDimensionCode',
                'salesBucketThresholdValue'
            ]
        ]
    }

    constructor(private zone: NgZone,
        private converter: ServiceCategoriesAndSalesBucketConverter,
        private productEligibleConverter: ProductEligibleRestrictedConverter,
        private service: PricingService,
        private toastrService: ToastrService,
        private conditionReferenceService: ConditionReferenceService,
        protected select2OptionsService: Select2OptionsService
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.select2IsIsNotConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.select2IsIsNotLessThanMoreThanConditionOption = this.select2OptionsService.getSelect2ConditionOption();
        this.getInventoryDimensionReference();
        this.getSelect2IsIsNotConditionDataList();
        this.getSelect2IsIsNotLessThanMoreThanConditionDataList();
        this.getServiceCategoriesReference();
        this.getSalesBucketReference();
    }
    add() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value;
            this.removeOptionalFieldsFromView(rows);
            rows.push(new PriceRuleAttributeAndRuleRowView());
            this.rows$.next(rows);
        } else {
            this.addErrorFlag = true;
        }
        this.togglePanelCollapseStatus(false);
    }

    private removeOptionalFieldsFromView(rows) {
        for (let row of rows) {
            if (row.serviceCategory?.children?.length) {
                let child = row.serviceCategory.children[0];
                if (!child.inventoryDimensionCode && !child.thresholdValue) {
                    delete row.serviceCategory.children;
                }
            }
            if (row.salesBucket?.children?.length) {
                let child = row.salesBucket.children[0];
                if (!child.inventoryDimensionCode && !child.thresholdValue) {
                    delete row.salesBucket.children;
                }
            }
        }
    }

    onServiceSalesChange(event, row) {
        row.type = event;
        switch (event) {
            case PricingDetailConstant.SERVICES_CATEGORIES_CODE:
                this.createServiceCategory(row);
                break;
            case PricingDetailConstant.SALES_BUCKET_CODE:
                this.createSalesBucket(row);
                break;
        }
    }

    private getInventoryDimensionReference() {
        if (this.select2InventoryDimensionDataList$.value?.length) {
            return;
        }
        this.service.getInventoryDimensionReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2InventoryDimensionDataList$.next(this.converter.toInventoryDimensionReferenceDataList(res))
                }
            )
    }

    private createServiceCategory(row: PriceRuleAttributeAndRuleRowView) {
        delete row.salesBucket;
        if (!row.serviceCategory) {
            row.serviceCategory = new PriceRuleServiceCategoryView();
            let child = new PriceRuleServiceCategoryView();
            child.conditionCode = '='
            row.serviceCategory.children = new Array<PriceRuleServiceCategoryView>(child)
        }
        row.serviceCategory.priceRuleAttributeGroupCode = this.priceRuleAttributeGroupCode;
    }

    private createSalesBucket(row: PriceRuleAttributeAndRuleRowView) {
        delete row.serviceCategory;
        if (!row.salesBucket) {
            row.salesBucket = new PriceRuleSalesBucketView();
            let child = new PriceRuleSalesBucketView();
            child.conditionCode = '='
            row.salesBucket.children = new Array<PriceRuleSalesBucketView>(child)
        }
        row.salesBucket.priceRuleAttributeGroupCode = this.priceRuleAttributeGroupCode;
    }

    onInventoryDimensionChange(event, row, child) {
        this.onValueChange(event, child, 'inventoryDimensionCode');
        if (row.serviceCategory) {
            row.serviceCategory.dimensionUnitCode = this.getDimensionUnitCode(event);
        }
        if (row.salesBucket) {
            row.salesBucket.dimensionUnitCode = this.getDimensionUnitCode(event);
        }
    }

    private getDimensionUnitCode(inventoryDimensionCode) {
        switch (inventoryDimensionCode) {
            case PricingDetailConstant.LOAD_FACTOR_CODE:
                return PricingDetailConstant.PERCENTAGE_CODE;
            case PricingDetailConstant.AVAILABLE_INVENTORY_CODE:
            case PricingDetailConstant.SALES_CODE:
                return PricingDetailConstant.SEATS_CODE;
        }
        return null;
    }

    private getServiceCategoriesReference() {
        if (this.select2ServiceCategoriesDataList$.value?.length) {
            return;
        }
        this.service.getServiceCategoryReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2ServiceCategoriesDataList$.next(this.converter.toServiceCategoryReferenceDataList(res))
                }
            )
    }

    private getSalesBucketReference() {
        if (this.select2SalesBucketDataList$.value?.length) {
            return;
        }
        this.service.getSalesBucketReference()
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                res => {
                    this.select2SalesBucketDataList$.next(this.converter.toSalesBucketReferenceDataList(res))
                }
            )
    }

    private getSelect2IsIsNotConditionDataList() {
        if (this.select2IsIsNotConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE], (res) => {
            this.select2IsIsNotConditionDataList$.next(this.productEligibleConverter.toConditionReferenceSelect2DataList(res));
        });
    }

    private getConditionReference(conditionCodes: string[], callback) {
        this.conditionReferenceService.getConditionsByCodes(conditionCodes)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(
                (res: ConditionReferenceModel[]) => {
                    callback(res);
                },
                err => {
                    this.toastrService.error(PricingDetailConstant.FAILED_GET_CONDITION_REFERENCE, PricingDetailConstant.ALERT_HEADER);
                }
            );
    }

    private getSelect2IsIsNotLessThanMoreThanConditionDataList() {
        if (this.select2IsIsNotLessThanMoreThanConditionDataList$.value?.length) {
            return;
        }
        this.getConditionReference([PricingDetailConstant.IS_OPERATOR_CODE,
        PricingDetailConstant.IS_NOT_OPERATOR_CODE,
        PricingDetailConstant.LESS_THAN_OPERATOR_CODE,
        PricingDetailConstant.GREATER_THAN_OPERATOR_CODE], (res) => {
            this.select2IsIsNotLessThanMoreThanConditionDataList$.next(this.productEligibleConverter.toConditionReferenceSelect2DataList(res));
        });
    }

    public attemptToSubmit() {
        if (this.validForms() && this.noDuplicatedRowFound()) {
            this.addErrorFlag = false;
            let rows = this.rows$.value;
            this.removeOptionalFieldsFromView(rows);
            this.rows$.next(rows);
            this.dataChange.emit(this.rows$.value);
            return true;
        } else {
            this.addErrorFlag = true;
            return false;
        }
    }

    public onValueChange(value: any, field: any, fieldName: string) {
        field[fieldName] = value;
        this.resetErrorFlags();
    }

    public onInputChange(event: Event, field: any, fieldName: string) {
        field[fieldName] = (event.target as HTMLInputElement).value;
        this.resetErrorFlags();
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#select2-baseCurrency-results {
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/settings-management/settings-categories-content/exchange-currency/exchange-currency-content/exchange-currency-list/exchange-currency-list.component.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;AACH","sourcesContent":["#select2-baseCurrency-results {\r\n   overflow-x:hidden;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

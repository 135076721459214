export class NameView {
    public no: number;
    public individualNameId: string;
    public individualId: string;
    public languageCode: string;
    public lastName: string;
    public firstName: string;
    public middleName: string;
    public nickName: string;
    public primaryFlag: boolean;
    public statusCode: string;
    public commitBy: string;
    public commitDateTime: Date;
    public lastNameNormal: string;
    public lastNameSound: string;
    public firstNameNormal: string;
    public firstNameSound: string;
    public middleNameNormal: string;
    public middleNameSound: string;
    public individualTitleCode: string;
    public lastNameLegacy: string;
    public middleNameLegacy: string;
    public firstNameLegacy: string;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherDetailComponent } from './voucher-detail.component';
import { VoucherTreeComponent } from './voucher-tree/voucher-tree.component';
import { VoucherGeneralComponent } from './voucher-general/voucher-general.component';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { TreeModule } from 'primeng/tree';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { AngularResizeEventModule } from 'angular-resize-event-package';
import { CounterTextModule } from 'src/app/core/pipes/counter-texts/counter-text.module';
import { VoucherAttributeAndRuleModule } from '../voucher-attribute-and-rule/voucher-attribute-and-rule.module';
import { StatusSelectionModule } from 'src/app/core/components/status-selection/status-selection.module';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationIndexModule } from 'src/app/shared/layout/navigation-index';
import { TextMediaModule } from 'src/app/core/components/text-media/text-media.module';
import { VoucherProductConditionComponent } from './voucher-product-condition/voucher-product-condition.component';
import { ProductConditionDetailComponent } from './voucher-product-condition/product-condition-detail/product-condition-detail.component';
import { DxDataGridModule, DxLookupModule, DxTreeListModule } from 'devextreme-angular';
import { OrdinalModule } from 'src/app/core/pipes/ordinal/ordinal.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslationNameModule } from 'src/app/core/components/translation-name/translation-name.module';
import { TranslationMediaModule } from 'src/app/core/components/translation-media/translation-media.module';
import { TranslationTextModule } from 'src/app/core/components/translation-text/translation-text.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { VouchersTabComponent } from './vouchers-tab/vouchers-tab.component';
import { VouchersTabSearchComponent } from './vouchers-tab/vouchers-tab-search/vouchers-tab-search.component';
import { VouchersTabTableComponent } from './vouchers-tab/vouchers-tab-table/vouchers-tab-table.component';
import { CalendarIconModule } from 'src/app/core/components/calendar-icon';
import { OrganisationSelectionModule } from 'src/app/core/components/organisation-selection';
import { StatusColorDropdownModule } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module';
import { ItemsFoundModule } from 'src/app/core/pipes/items-found/items-found.module';
import { StatusBadgeModule } from 'src/app/shared/ui/forms/inputs/status-badge/status-badge.module';

@NgModule({
    declarations: [
        VoucherDetailComponent,
        VoucherTreeComponent,
        VoucherGeneralComponent,
        VoucherProductConditionComponent,
        ProductConditionDetailComponent,
        VouchersTabComponent,
        VouchersTabSearchComponent,
        VouchersTabTableComponent
    ],
    imports: [
        AlertBarModule,
        AngularResizeEventModule,
        StatusBadgeModule,
        CalendarIconModule,
        CommonModule,
        CounterTextModule,
        DxDataGridModule,
        DxLookupModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        MatIconModule,
        NavigationIndexModule,
        NgbNavModule,
        OrdinalModule,
        OrganisationSelectionModule,
        PanelsModule,
        ReactiveFormsModule,
        TextMediaModule,
        TranslationNameModule,
        TranslationMediaModule,
        TranslationTextModule,
        TreeModule,
        Select2Module,
        StatusColorDropdownModule,
        StatusSelectionModule,
        UiModule,
        VoucherAttributeAndRuleModule
    ],
    exports: [
        VoucherDetailComponent
    ]
})
export class VoucherDetailModule { }

import { createAction } from '@ngrx/store';
import { TabModel } from '../../core/models/tab/tab.model';

export const addTab = createAction(
    '[Tab] Add Tab',
    (tab: TabModel) => ({ tab })
);
export const deleteTab = createAction(
    '[Tab] Delete Tab',
    (tab: TabModel) => ({ tab })
);
export const deleteAllTab = createAction(
    '[Tab] Delete All Tab',
    (forceCloseAll: boolean = false) => ({ forceCloseAll })
);
export const setActiveTab = createAction(
    '[Tab] Set Active Tab',
    (tab: TabModel) => ({ tab })
);
export const setTabUrl = createAction(
    '[Tab] Set Active Tab URL',
    (tab: TabModel) => ({ tab })
);
export const setTabName = createAction(
    '[Tab] Set Active Tab Name',
    (tab: TabModel) => ({ tab })
);
export const TabActionType: string[] = [
    addTab.type,
    deleteTab.type,
    setActiveTab.type,
    setTabUrl.type,
    setTabName.type
];
export const SecurityMenuItems = [
    {
        name: 'Security Group',
        url: 'GROUP',
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        productCount: null,
        contextMenus: [
            {
                name: "New",
                actionId: "NEW",
            },
            {
                name: "Search",
                actionId: "SEARCH",
            },
            {
                name: "Report",
                actionId: null,
                disable: true
            }
        ]
    },
    {
        name: 'Security Code',
        url: 'CODE',
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        productCount: null,
        contextMenus: [
            {
                name: "New",
                actionId: "NEW"
            },
            {
                name: "Search",
                actionId: "SEARCH"
            },
            {
                name: "Report",
                actionId: null,
                disable: true
            }
        ]
    },
    {
        name: 'Security Audit',
        url: 'AUDIT',
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        productCount: null,
        contextMenus: null
    },
    {
        name: 'Security Reports',
        url: 'REPORT',
        modifiedDateTime: null,
        modifiedBy: null,
        security: null,
        productCount: null,
        contextMenus: null
    }
]
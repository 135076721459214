export const SecurityPaymentAll = 'PAYMENTALL'
export const SecurityPaymentPay = 'PAYMENTPAY'
export const SecurityPaymentRefund = 'PAYMENTREFUND'
export const SecurityPaymentReverse = 'PAYMENTREVERSE'
export const SecurityPaymentVoid = 'PAYMENTVOID'

export const SecurityPaymentCodes = [
    SecurityPaymentAll,
    SecurityPaymentPay,
    SecurityPaymentRefund,
    SecurityPaymentReverse,
    SecurityPaymentVoid,
]

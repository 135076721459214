import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SecurityGroupCommand } from 'src/app/modules/settings-management/settings-categories-content/security-management/security-group/security-group-detail/shared/security-group-command.model';
import { SecurityGroupSearchTableView } from 'src/app/modules/settings-management/settings-categories-content/security-management/security-group/security-group-search/shared/security-group-search-table.view';
import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { SecurityGroupReference } from '../../models/security-model/security-group-reference.model';
import { SecurityGroupSecurityModel } from '../../models/security-model/security-group-security.model';
import { SecurityGroupTreeModel } from '../../models/security-model/security-group-tree.model';
import { SecurityGroupOrganisationModel } from '../../models/security-model/security-group-organisation.model';

@Injectable({
     providedIn: 'root'
})
export class SecurityGroupService extends ApiServiceBase {
    private readonly PATH = '/system/SecurityGroupReference';

    private _userSecurityGroupSecurity = new BehaviorSubject<SecurityGroupSecurityModel[]>([])

    get userSecurityGroupSecurity() {
        return this._userSecurityGroupSecurity.asObservable()
    }

    constructor(httpClient: HttpClient,
        authService: AuthService) {
            super(httpClient, authService)
        }

    public searchSecurityGroup(securityGroupName: string, securityCode: string, securityName: string,
        userLogon: string, action: string, statusCode: string): Observable<SecurityGroupSearchTableView[]> {
            let param = new HttpParams()
                .set('securityGroupName', securityGroupName)
                .set('securityCode', securityCode)
                .set('securityName', securityName)
                .set('userLogon', userLogon)
                .set('action', action)
                .set('statusCode', statusCode);
            return this.httpGet<SecurityGroupSearchTableView[]>(this.PATH + '/search', param)
                .pipe(
                    map(response => response.body)
                )
        }

    public getSecurityGroupTree(securityGroupId: string): Observable<SecurityGroupTreeModel> {
        let param = new HttpParams().set('securityGroupId', securityGroupId);
        return this.httpGet<SecurityGroupTreeModel>(this.PATH + '/tree', param)
            .pipe(
                map(response => response.body)
            )
    }

    public getSecurityGroupReference(securityGroupId): Observable<SecurityGroupReference> {
        return this.httpGet<SecurityGroupReference>(this.PATH + '/' + securityGroupId, null)
            .pipe(
                map(response => response.body)
            )
    }

    public getSecurityGroupSecurity(securityGroupId: string): Observable<SecurityGroupSecurityModel[]> {
        let param = new HttpParams().set('securityGroupId', securityGroupId);
        return this.httpGet<SecurityGroupSecurityModel[]>(this.PATH + '/SecurityGroupSecurity', param)
            .pipe(
                map(response => response.body)
            )
    }

    public addSecurityGroupCommand(command: SecurityGroupCommand) {
        return this.httpPost(this.PATH, command);
    }

    public editSecurityGroupCommand(command: SecurityGroupCommand) {
        return this.httpPut(this.PATH, command);
    }

    public getUserSecurityGroupSecurity(): Observable<SecurityGroupSecurityModel[]> {
        return this.httpGet<SecurityGroupSecurityModel[]>(this.PATH + '/userSecurity', null)
            .pipe(
                tap(response => this._userSecurityGroupSecurity.next(response.body)),
                map(response => response.body)
            )
    }

    public changeSecurityGroupStatus(securityGroupId: string, statusCode: string) {
        return this.httpPut(`${this.PATH}/status/${securityGroupId}?statusCode=${statusCode}`, null);
    }

    public getSecurityGroupOrganisation(securityGroupId: string): Observable<SecurityGroupOrganisationModel[]> {

        return this.httpGet<SecurityGroupOrganisationModel[]>(this.PATH + '/' + securityGroupId + '/organisations', null)
            .pipe(
                map(response => response.body)
            )
    }

    public getAllSecurityGroupReference(): Observable<SecurityGroupReference[]> {
        return this.httpGet<SecurityGroupReference[]>(this.PATH, null)
            .pipe(
                map(response => response.body)
            )
    }
}

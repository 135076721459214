import { UntypedFormControl, Validators } from "@angular/forms";

export const DocumentDistributionGeneralDetailFormOption = {
    organisationId: new UntypedFormControl(null, Validators.required),
    documentTypeCode: new UntypedFormControl(null, Validators.required),
    distributionTypeCode: new UntypedFormControl(null, Validators.required),
    documentDistributionDescription: new UntypedFormControl(null),
    localExecutionDate: new UntypedFormControl({value: null, disabled: true}),
    localExecutionTime: new UntypedFormControl({value: null, disabled: true}),
    offsetPriorToEventFlag: new UntypedFormControl({value: null, disabled: true}),
    attachmentFlag: new UntypedFormControl({value: null, disabled: true}),
    teletypeAddressElementSender: new UntypedFormControl(null),
    teletypeAddressElementReceiver: new UntypedFormControl(null),
    teletypeAddressElementPriority: new UntypedFormControl(null),
    mailAddressElementSender: new UntypedFormControl({value: null, disabled: true}),
    mailAddressElementReceiver: new UntypedFormControl({value: null, disabled: true}),
    mailSubjectElement: new UntypedFormControl({value: null, disabled: true}),
    descriptionText: new UntypedFormControl(null),
    offsetDays: new UntypedFormControl({value: null, disabled: true}),
    offsetHours: new UntypedFormControl({value: null, disabled: true}),
    offsetMinutes: new UntypedFormControl({value: null, disabled: true}),
    maximumMessageSize: new UntypedFormControl(null),
    statusCode: new UntypedFormControl(null, Validators.required),
    commitByName: new UntypedFormControl({ value: null, disabled: true }),
    commitDateTime: new UntypedFormControl({ value: null, disabled: true },),
    timeDefinitionCode: new UntypedFormControl(null, Validators.required),
    intervalDays: new UntypedFormControl({value: null, disabled: true}),
    intervalHours: new UntypedFormControl({value: null, disabled: true}),
    intervalMinutes: new UntypedFormControl({value: null, disabled: true}),
    intervalSeconds: new UntypedFormControl({value: null, disabled: true}),
    documentDistributionName: new UntypedFormControl(null, Validators.required),
    primaryDocumentDistributionId: new UntypedFormControl(null)
}
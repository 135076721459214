import { Injectable } from '@angular/core';
import { ProductServiceRequestModel } from 'src/app/core/models/product-model/specialservice-model';
import type { VehicleConfigurationProductCommandModel, VehicleConfigurationProductModel } from 'src/app/core/models/vehicle-model/vehicle-configuration/vehicle-configuration-product';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';

@Injectable()
export class ServiceRequestMapperService {

    public toServiceRequestViews(models: ProductServiceRequestModel[]): Select2Data[] {
        let views: Select2Data[] = new Array();
        if (views) {
            for (let model of models) {
                views.push(this.toServiceRequestView(model));
            }
        }
        return views;
    }

    private toServiceRequestView(model: ProductServiceRequestModel): Select2Data {
        return new Select2Data(model.productId, model.productName);
    }

    public toVehicleConfigurationProductViews(models: VehicleConfigurationProductModel[]): string[] {
        let views: string[] = new Array();
        if (views) {
            for (let model of models) {
                views.push(model.productId);
            }
        }
        return views;
    }

    public toServiceRequestCommands(views: string[], vehicleConfigurationProducts: VehicleConfigurationProductModel[]): VehicleConfigurationProductCommandModel[] {
        let commands: VehicleConfigurationProductCommandModel[] = new Array();
        if (views) {
            for (let productId of views) {
                commands.push(this.toServiceRequestCommand(productId, vehicleConfigurationProducts));
            }
        }
        return commands;
    }

    private toServiceRequestCommand(productId: string, vehicleConfigurationProducts: VehicleConfigurationProductModel[]): VehicleConfigurationProductCommandModel {
        var command = {} as VehicleConfigurationProductCommandModel;
        var filterProduct = this.findVehicleConfigurationProductId(productId, vehicleConfigurationProducts);
        if (filterProduct) {
            command.vehicleConfigurationProductId = filterProduct.vehicleConfigurationProductId;
        }
        command.productId = productId;
        return command;
    }

    private findVehicleConfigurationProductId(productId: string, vehicleConfigurationProducts: VehicleConfigurationProductModel[]): VehicleConfigurationProductModel {
        if (vehicleConfigurationProducts) {
            var filter = vehicleConfigurationProducts.filter(x => x.productId == productId);
            if (filter?.length) {
                return filter[0];
            }
        }
        return null; 
    }
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxTemplateModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { StatusColorModule } from "src/app/shared/ui/forms/inputs/status-color/status-color.module";
import { DialogsService } from "src/app/shared/dialogs/dialogs.service";
import { Helper } from "src/app/shared/helper/app.helper";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { AppEventService } from "src/app/shared/helper/app.event.service";
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner";
import { UiModule } from "src/app/shared/ui/ui.module";
import { MerchandizeSearchComponent } from "./merchandize-search.component";
import { MerchandizeSearchConditionComponent } from "./merchandize-search-condition/merchandize-search-condition.component";
import { MerchandizeSearchFilterNameComponent } from "./merchandize-filter-name/merchandize-filter-name.component";
import { MerchandizeSearchTableComponent } from "./merchandize-search-table/merchandize-search-table.component";
import { ProductAttributeAndRuleModule } from "../attribute-and-rule/attribute-and-rule.module";
import { MerchandizeDetailModule } from "../merchandize-detail/merchandize-detail.module";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";

@NgModule({
    declarations: [
        MerchandizeSearchComponent,
        MerchandizeSearchConditionComponent,
        MerchandizeSearchFilterNameComponent,
        MerchandizeSearchTableComponent,
    ],
    exports: [
        MerchandizeSearchComponent
    ],
    providers: [
        AppEventService, DialogsService, Helper
    ],
    imports: [
        AlertBarModule,
        BsDropdownModule,
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopoverModule,
        DxTemplateModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        MatIconModule,
        MatMenuModule,
        MerchandizeDetailModule,
        PanelsModule,
        ProductAttributeAndRuleModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorModule,
        UiModule
    ]
})
export class MerchandizeSearchModule { }

import { Component } from '@angular/core';
import { scrollToTop, toggleFullscreen } from '../../utils/utils.functions';
import { Store } from '@ngrx/store';
import { Go } from 'src/app/store/router';

import { MessageService } from '../../helper/message.service';

@Component({
    selector: 'float-action-button',
    templateUrl: './float-action-button.component.html'
})
export class FloatActionButtonComponent {

    public menuShow: boolean = false;
    constructor(private store: Store<any>,private messageService: MessageService) { }

    scrollTop($event: MouseEvent) {
        this.showMenu();
        $event.preventDefault();
        scrollToTop(150);
        this.messageService.sendMessage('scrolltop');
    }
    logout($event: MouseEvent) {
        this.showMenu()
        $event.preventDefault();
        this.store.dispatch(new Go({ path: ['/logout'] }));

    }
    fullScreen($event: MouseEvent) {
        this.showMenu()
        $event.preventDefault();
        toggleFullscreen();

    }
    printPage($event: MouseEvent) {
        this.showMenu();
        $event.preventDefault();
        window.print();

    }
    save($event: MouseEvent) {
        this.showMenu();
    }
    cancel($event: MouseEvent) {
        this.showMenu();
    }
    showMenu(): void {
        if (!this.menuShow) {
            this.menuShow = true;
        } else {
            this.menuShow = false;
        }
    }
}


import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from '../../authentication/auth.service';
import { ApiServiceBase } from '../../base/api-service-base';
import { OrganisationTypeReferenceModel } from '../../models/reference-model/reference-general-model';
import { StringHelperService } from '../../utils/string-helper.service';

@Injectable({
    providedIn: 'root'
})
export class OrganisationTypeReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = "/system/OrganisationTypeReferences"
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private stringHelperService: StringHelperService
    ) {
        super(httpClient, authService);
    }


    public getOrganisationTypeReference(organisationTypeCodes: string[]): Observable<OrganisationTypeReferenceModel[]> {
        const paramName = 'organisationTypeCodes';
        let params = this.stringHelperService.createQueryParamFromArray(paramName, organisationTypeCodes);
        return this.httpGet<OrganisationTypeReferenceModel[]>(this.REFERENCE_PATH + params, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getOrganisationTypeByName(organisationTypeName: string): Observable<OrganisationTypeReferenceModel[]> {
        const apiPath: string = '/name/';
        return this.httpGet<OrganisationTypeReferenceModel[]>(this.REFERENCE_PATH + apiPath + organisationTypeName, null)
            .pipe(
                map(res => res.body)
            )
    }

    public getByStatus(statusCode): Observable<OrganisationTypeReferenceModel[]> {
        return this.httpGet<OrganisationTypeReferenceModel[]>(this.REFERENCE_PATH + "/status/" + statusCode, null)
            .pipe(
                map(res => res.body)
            )
    }
}
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { ConfigEditor, select2TextType } from '../shared/translation-text-configuration';

import { LanguageReferenceModel, MediaTypeReferenceModel } from 'src/app/core/models/reference-model/reference-media-model';
import { TranslationTextDetailView } from '../shared/translation-text-detail.view';

@Component({
    selector: 'op-translation-text-detail',
    templateUrl: './translation-text-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTextDetailComponent  {

    @Input() model: TranslationTextDetailView;
    @Input() mediaTypeReference$ = new BehaviorSubject<MediaTypeReferenceModel[]>(null);
    @Input() languageReference$ = new BehaviorSubject<LanguageReferenceModel[]>(null);

    @Output() onSave = new EventEmitter<TranslationTextDetailView>();
    @Output() onCancel = new EventEmitter();

    public textTypeOption: any;
    public configEditorOption: any;

    constructor() {
        this.textTypeOption = select2TextType;
        this.configEditorOption = ConfigEditor;
    }

    get languageCodes(): string | string[] {
        return this.model.languageCode;
    }

    set languageCodes(value: string | string[]) {
        this.model.languageCode = <string>value;
    }

    public save() {
        this.onSave.emit(this.model);
    }


    public cancel() {
        this.onCancel.emit();
    }

    public isTextOnly() {
        var filter = this.mediaTypeReference$.value.filter(x => x.mediaTypeCode == this.model.mediaTypeCode);
        if (filter.length > 0) {
            return !filter[0].textOnlyFlag;
        }
    }
}
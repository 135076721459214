import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2DefaultOption, select2DefaultOptionWithAllowClear } from "./search-condition-configuration";
import { VehicleConfigurationSearchCommandModel } from 'src/app/core/models/vehicle-model/vehicle-configuration';
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";

@Component({
    selector: 'op-vehicle-configuration-search-condition',
    templateUrl: './search-condition.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchConditionComponent extends OopsComponentFormBase {
    @Input() userSecurity: SecurityGroupSecurityModel
    @Output() onSearch = new EventEmitter<VehicleConfigurationSearchCommandModel>();
    @Output() onClear = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing: boolean = false;
    select2DefaultOption = select2DefaultOptionWithAllowClear;
    select2DefaultOptionMandatory = select2DefaultOption;
    statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))));

    get form(): UntypedFormGroup {
        return this.formGroup;
    }

    get code(): string {
        return this.form.controls['code'].value;
    }

    set code(value: string) {
        this.form.controls['code'].patchValue(value);
    }

    get name(): string {
        return this.form.controls['name'].value;
    }

    set name(value: string) {
        this.form.controls['name'].patchValue(value);
    }

    constructor(
        fb: UntypedFormBuilder,
        private statusReferenceService: StatusReferenceService) {
        super(fb);
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            code: new UntypedFormControl(null),
            name: new UntypedFormControl(null),
            statusCode: new UntypedFormControl("A"),
        })
    }

    public doSearch() {
        this.onSearch.emit(this.getValues());
    }

    clearCondition() {
        this.onClear.emit();
    }

    public getValues(): VehicleConfigurationSearchCommandModel {
        return this.convertFormGroupToModel();
    }

    private convertFormGroupToModel(): VehicleConfigurationSearchCommandModel {
        let model = new VehicleConfigurationSearchCommandModel();
        model.vehicleConfigurationCode = this.getFormControlValue("code")?.trim();
        model.vehicleConfigurationName = this.getFormControlValue("name")?.trim();
        model.statusCode = this.getFormControlValue("statusCode");
        return model;
    }

    private getFormControlValue(formControlName: string): string {
        return this.formGroup.controls[formControlName]?.value;
    }
}
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CurrencyReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { Helper } from "src/app/shared/helper/app.helper";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { PriceModel } from "../../../prices/shared/models";
import { AttributesComponent } from "./attributes/attributes.component";

@Component({
    selector: 'op-price-dimensions-details-dimensions',
    templateUrl: './dimensions.component.html',
})
export class DimensionsComponent implements OnChanges {
    @Input() price: PriceModel;
    @Input() organisationTypeReferences: Select2Data[];
    @Input() organisationReferences: Select2Data[];
    @Input() organisationRoleReferences: Select2Data[];
    @Input() organisationGroupReferences: Select2Data[];
    @Input() regionReferences: Select2Data[];
    @Input() organisationCountryReferences: Select2Data[];
    @Input() servicaCategoryReferences: Select2Data[];
    @Input() salesBucketReferences: Select2Data[];
    @Input() membershipLevels: Select2Data[];
    @Input() currencyReferences: CurrencyReferenceModel[];
    @Input() domainAttributes: Select2Data[];
    @Input() attributeChoices: Select2Data[][];
    @Input() attributeChoiceOption: any[];
    @Input() searchMode = false;
    @Input() panelTitle = 'Price Dimension Attribute';
    @Input() disabled: boolean = false; 

    @Output() priceAttributesChange = new EventEmitter<string[]>();
    @Output() focus = new EventEmitter();

    @ViewChild(AttributesComponent) attributesComponent: AttributesComponent;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing = false;
    classIcon: string = this.helper.getClassIcon();
    price$ = new BehaviorSubject<PriceModel>(null);
    panelCollapseFlag$ = new BehaviorSubject<boolean>(true);

    get allowToggle(): boolean {
        return this.attributesComponent?.rows?.length > 0;
    }

    constructor(private helper: Helper) {}

    ngOnChanges() {
        this.price$.next(this.price);
    }

    public getValues(priceModel: PriceModel): PriceModel {
        return this.attributesComponent.getValues(priceModel);
    }

    public triggerAttributesError() {
        this.attributesComponent.validateFormGroup();
    }

    onPriceAttributesChange(priceAttributes: string[]) {
        this.priceAttributesChange.emit(priceAttributes);
    }

    public resetForm() {
        this.attributesComponent.resetForm();
    }

    add() {
        this.attributesComponent.addRow();
        this.panelCollapseFlag$.next(false);
    }

    onNumberOfRowsChange(numberOfRows: number) {
        if (numberOfRows == 0) {
            this.panelCollapseFlag$.next(true);
        } else {
            this.panelCollapseFlag$.next(false);
        }
    }

    onFocusChange(focusing: boolean) {
        if (focusing) {
            this.focus.emit();
        }
    }
}

import { Injectable } from "@angular/core";
import { InsightPassengerAgeGroupModel, InsightPassengerCommandModel, InsightPassengerLanguageModel, InsightPassengerModel, InsightPassengerNationalityModel, InsightPassengerSearchModel } from "src/app/core/models/individual-model";
import { IndividualCivilStatusModel, IndividualInterestModel, IndividualPositionModel, IndividualPreferenceModel, IndividualProfessionModel, IndividualSocialModel } from "src/app/core/models/individual-model/individual-profiles-model";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { ProfileRowView } from "./profile-row-view";

@Injectable()
export class ProfileMapperService {
    private readonly CIVIL_STATUS = "CIVIL_STATUS";
    private readonly INTEREST = "INTEREST";
    private readonly POSITION = "POSITION";
    private readonly PREFERENCE = "PREFERENCE";
    private readonly PROFESSION = "PROFESSION";
    private readonly SOCIAL = "SOCIAL";
    private readonly LANGUAGE = "LANGUAGE"; 
    private readonly NATIONALITY = "NATIONALITY"; 
    private readonly AGE_GROUP = "AGE_GROUP"; 

    public insightModelToProfileRowViews(model: InsightPassengerModel) : ProfileRowView[] {
        let views = new Array();

        if (model.insightPassengerCivilStatuses?.length > 0) {    
            views.push(this.setCivilStatuses(model.insightPassengerCivilStatuses));
        }

        if (model.insightPassengerInterests?.length > 0) {
            views.push(this.setInterests(model.insightPassengerInterests));
        }

        if (model.insightPassengerPositions?.length > 0) {
            views.push(this.setPositions(model.insightPassengerPositions));
        }

        if (model.insightPassengerPreferences?.length > 0) {
            views.push(this.setPreferences(model.insightPassengerPreferences));
        }

        if (model.insightPassengerProfessions?.length > 0) {
            views.push(this.setProfessions(model.insightPassengerProfessions));           
        }

        if (model.insightPassengerSocials?.length > 0) {
            views.push(this.setSocials(model.insightPassengerSocials));
        }

        return views;
    }

    public insightSearchModelToProfileRowViews(model: InsightPassengerSearchModel) : ProfileRowView[] {
        let views = new Array();

        if (model.insightPassengerCivilStatuses?.length > 0) {    
            views.push(this.setCivilStatuses(model.insightPassengerCivilStatuses));
        }

        if (model.insightPassengerInterests?.length > 0) {
            views.push(this.setInterests(model.insightPassengerInterests));
        }

        if (model.insightPassengerPositions?.length > 0) {
            views.push(this.setPositions(model.insightPassengerPositions));
        }

        if (model.insightPassengerPreferences?.length > 0) {
            views.push(this.setPreferences(model.insightPassengerPreferences));
        }

        if (model.insightPassengerProfessions?.length > 0) {
            views.push(this.setProfessions(model.insightPassengerProfessions));           
        }

        if (model.insightPassengerSocials?.length > 0) {
            views.push(this.setSocials(model.insightPassengerSocials));
        }

        return views;
    }

    public insightModelToFliterProfileRowViews(model: InsightPassengerModel) : ProfileRowView[] {
        let views = this.insightModelToProfileRowViews(model);

        if (model.insightPassengerLanguages?.length > 0) {
            views.push(this.setLanguages(model.insightPassengerLanguages));
        }

        if (model.insightPassengerNationalities?.length > 0) {
            views.push(this.setNationalities(model.insightPassengerNationalities));
        }

        if (model.insightPassengerAgeGroups?.length > 0) {
            views.push(this.setAgeGroups(model.insightPassengerAgeGroups));
        }

        return views;
    }

    public insightSearchModelToFliterProfileRowViews(model: InsightPassengerSearchModel) : ProfileRowView[] {
        let views = this.insightSearchModelToProfileRowViews(model);

        if (model.insightPassengerLanguages?.length > 0) {
            views.push(this.setLanguages(model.insightPassengerLanguages));
        }

        if (model.insightPassengerNationalities?.length > 0) {
            views.push(this.setNationalities(model.insightPassengerNationalities));
        }

        if (model.insightPassengerAgeGroups?.length > 0) {
            views.push(this.setAgeGroups(model.insightPassengerAgeGroups));
        }

        return views;
    }

    private setCivilStatuses(models: IndividualCivilStatusModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.CIVIL_STATUS;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualCivilStatusTypeCode;
            profileTypeCode.text = model.individualCivilStatusTypeCode;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualCivilStatusTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setInterests(models: IndividualInterestModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.INTEREST;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualInterestTypeCode;
            profileTypeCode.text = model.individualInterestTypeName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualInterestTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setPositions(models: IndividualPositionModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.POSITION;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualPositionTypeCode;
            profileTypeCode.text = model.individualPositionTypeName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualPositionTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setPreferences(models: IndividualPreferenceModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.PREFERENCE;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualPreferenceTypeCode;
            profileTypeCode.text = model.individualPreferenceTypeName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualPreferenceTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setProfessions(models: IndividualProfessionModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.PROFESSION;

        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualProfessionTypeCode;
            profileTypeCode.text = model.individualProfessionTypeName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualProfessionTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setSocials(models: IndividualSocialModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.SOCIAL;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualSocialTypeCode;
            profileTypeCode.text = model.individualSocialTypeName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualSocialTypeCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setLanguages(models: InsightPassengerLanguageModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.LANGUAGE;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.languageCode;
            profileTypeCode.text = model.languageName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.languageCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    } 

    private setNationalities(models: InsightPassengerNationalityModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.NATIONALITY;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.countryCode;
            profileTypeCode.text = model.countryName;
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.countryCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    private setAgeGroups(models: InsightPassengerAgeGroupModel[]) : ProfileRowView {
        let profile = {} as ProfileRowView;
        profile.profileType = this.AGE_GROUP;
        
        let profileTypeCodes: Select2Data[] = new Array();
        profile.codes = new Array()

        for (let model of models) {
            let profileTypeCode = new Select2Data();
            profileTypeCode.id = model.individualAgeGroupCode;
            profileTypeCode.text = "";
            profileTypeCodes.push(profileTypeCode);
            profile.codes.push(model.individualAgeGroupCode);
        }

        profile.profileTypeCodes = profileTypeCodes;
        return profile;
    }

    public profileRowViewsToProfileModels(rows: ProfileRowView[], command: InsightPassengerCommandModel) {
        if (!rows?.length) {
            return;
        }

        for (let row of rows) {
            switch (row.profileType) {
                case this.CIVIL_STATUS :
                    this.setCivilStatusModels(row.codes, command);
                    break;
                case this.INTEREST :
                    this.setInterestModels(row.codes, command);
                    break;
                case this.POSITION :
                    this.setPositionModels(row.codes, command);
                    break;
                case this.PREFERENCE :
                    this.setPreferenceModels(row.codes, command);
                    break;
                case this.PROFESSION :
                    this.setProfessionModels(row.codes, command);
                    break;
                case this.SOCIAL :
                    this.setSocialModels(row.codes, command);
                    break;
            }
        }
    }

    private setCivilStatusModels(codes: string[], command: InsightPassengerCommandModel) {
        if (!codes?.length) {
            return;
        }

        command.insightPassengerCivilStatuses = new Array();
            
        for (let code of codes) {
            let civilStatusModel: IndividualCivilStatusModel = {
                individualCivilStatusId: null,
                individualCivilStatusTypeCode: code,
                individualCivilStatusTypeName: null,
                startDate: null,
                endDate: null
            }

            command.insightPassengerCivilStatuses.push(civilStatusModel);
        }
    }

    private setInterestModels(codes: string[], command: InsightPassengerCommandModel) {
        if (codes.length <= 0) {
            return;
        }

        command.insightPassengerInterests = new Array();

        for (let code of codes) {
            let interestModel: IndividualInterestModel = {
                individualInterestId: null,
                individualInterestTypeCode: code,
                individualInterestTypeName: null               
            }

            command.insightPassengerInterests.push(interestModel);
        }
    }

    private setPositionModels(codes: string[], command: InsightPassengerCommandModel) {
        if (codes.length <= 0) {
            return;
        }

        command.insightPassengerPositions = new Array();

        for (let code of codes) {
            let positionModel: IndividualPositionModel = {
                individualPositionId: null,
                individualPositionTypeCode: code,
                individualPositionTypeName: null,
                startDate: null,
                endDate: null               
            }

            command.insightPassengerPositions.push(positionModel);
        }
    }

    private setPreferenceModels(codes: string[], command: InsightPassengerCommandModel) {
        if (codes.length <= 0) {
            return;
        }

        command.insightPassengerPreferences = new Array();

        for (let code of codes) {
            let preferenceModel: IndividualPreferenceModel = {
                individualPreferenceId: null,
                individualPreferenceTypeCode: code,
                individualPreferenceTypeName: null,
                filterFlag: null            
            }

            command.insightPassengerPreferences.push(preferenceModel);
        }
    }

    private setProfessionModels(codes: string[], command: InsightPassengerCommandModel) {
        if (codes.length <= 0) {
            return;
        }

        command.insightPassengerProfessions = new Array();

        for (let code of codes) {
            let professionModel: IndividualProfessionModel = {
                individualProfessionId: null,
                individualProfessionTypeCode: code,
                individualProfessionTypeName: null,
                startDate: null,
                endDate: null        
            }

            command.insightPassengerProfessions.push(professionModel);
        }
    }

    private setSocialModels(codes: string[], command: InsightPassengerCommandModel) {
        if (codes.length <= 0) {
            return;
        }

        command.insightPassengerSocials = new Array();
        
        for (let code of codes) {
            let socialModel: IndividualSocialModel = {
                individualSocialId: null,
                individualSocialTypeCode: code,
                individualSocialTypeName: null,
                startDate: null,
                endDate: null        
            }

            command.insightPassengerSocials.push(socialModel);
        }
    }
}
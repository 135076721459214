import { OrdersDashboardCompareValuesModel } from "src/app/core/models/order-model/order-dashboard-model/order-dashboard-compare-value.model";

export const PRODUCT_DASHBOARD_MERCHANDIZE_COMPARE_MONM_DEMO: OrdersDashboardCompareValuesModel[] = [
    {
        dayNo: 1,
        value1: 147,
        value1Name: "2021",
        value2: 106,
        value2Name: "2022"
    },
    {
        dayNo: 2,
        value1: 174,
        value1Name: "2021",
        value2: 119,
        value2Name: "2022"
    },
    {
        dayNo: 3,
        value1: 146,
        value1Name: "2021",
        value2: 208,
        value2Name: "2022"
    },
    {
        dayNo: 4,
        value1: 168,
        value1Name: "2021",
        value2: 64,
        value2Name: "2022"
    },
    {
        dayNo: 5,
        value1: 212,
        value1Name: "2021",
        value2: 124,
        value2Name: "2022"
    },
    {
        dayNo: 6,
        value1: 248,
        value1Name: "2021",
        value2: 230,
        value2Name: "2022"
    },
    {
        dayNo: 7,
        value1: 242,
        value1Name: "2021",
        value2: 176,
        value2Name: "2022"
    },
    {
        dayNo: 8,
        value1: 100,
        value1Name: "2021",
        value2: 231,
        value2Name: "2022"
    },
    {
        dayNo: 9,
        value1: 162,
        value1Name: "2021",
        value2: 163,
        value2Name: "2022"
    },
    {
        dayNo: 10,
        value1: 194,
        value1Name: "2021",
        value2: 215,
        value2Name: "2022"
    },
    {
        dayNo: 11,
        value1: 76,
        value1Name: "2021",
        value2: 83,
        value2Name: "2022"
    },
    {
        dayNo: 12,
        value1: 136,
        value1Name: "2021",
        value2: 137,
        value2Name: "2022"
    },
    {
        dayNo: 13,
        value1: 180,
        value1Name: "2021",
        value2: 162,
        value2Name: "2022"
    },
    {
        dayNo: 14,
        value1: 206,
        value1Name: "2021",
        value2: 135,
        value2Name: "2022"
    },
    {
        dayNo: 15,
        value1: 248,
        value1Name: "2021",
        value2: 223,
        value2Name: "2022"
    },
    {
        dayNo: 16,
        value1: 97,
        value1Name: "2021",
        value2: 147,
        value2Name: "2022"
    },
    {
        dayNo: 17,
        value1: 148,
        value1Name: "2021",
        value2: 148,
        value2Name: "2022"
    },
    {
        dayNo: 18,
        value1: 212,
        value1Name: "2021",
        value2: 186,
        value2Name: "2022"
    },
    {
        dayNo: 19,
        value1: 155,
        value1Name: "2021",
        value2: 239,
        value2Name: "2022"
    },
    {
        dayNo: 20,
        value1: 173,
        value1Name: "2021",
        value2: 112,
        value2Name: "2022"
    },
    {
        dayNo: 21,
        value1: 126,
        value1Name: "2021",
        value2: 154,
        value2Name: "2022"
    },
    {
        dayNo: 22,
        value1: 227,
        value1Name: "2021",
        value2: 223,
        value2Name: "2022"
    },
    {
        dayNo: 23,
        value1: 132,
        value1Name: "2021",
        value2: 116,
        value2Name: "2022"
    },
    {
        dayNo: 24,
        value1: 135,
        value1Name: "2021",
        value2: 119,
        value2Name: "2022"
    },
    {
        dayNo: 25,
        value1: 214,
        value1Name: "2021",
        value2: 194,
        value2Name: "2022"
    },
    {
        dayNo: 26,
        value1: 133,
        value1Name: "2021",
        value2: 243,
        value2Name: "2022"
    },
    {
        dayNo: 27,
        value1: 59,
        value1Name: "2021",
        value2: 50,
        value2Name: "2022"
    },
    {
        dayNo: 28,
        value1: 58,
        value1Name: "2021",
        value2: 126,
        value2Name: "2022"
    }, 
    {
        dayNo: 29,
        value1: 212,
        value1Name: "2021",
        value2: 94,
        value2Name: "2022"
    },
    {
        dayNo: 30,
        value1: 140,
        value1Name: "2021",
        value2: 247,
        value2Name: "2022"
    }
]

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../../base/api-service-base';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { StringHelperService } from '../../utils/string-helper.service';
import { OrganisationGroupReferenceModel } from '../../models/reference-model/reference-general-model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationGroupReferenceService extends ApiServiceBase {
  private readonly REFERENCE_PATH = "/airline/OrganisationGroupReferences"
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private stringHelperService: StringHelperService
  ) {
    super(httpClient, authService);
  }

  public getOrganisationGroupReference(ids: string[] = null) {
    const paramName = 'organisationGroupCode';
    let params = "";
    if (ids?.length) {
      params = this.stringHelperService.createQueryParamFromArray(paramName, ids);
    }
    return this.httpGet<OrganisationGroupReferenceModel[]>(this.REFERENCE_PATH + '/' + params, null)
      .pipe(
        map(res => res.body)
      )
  }
}
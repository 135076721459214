import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from "lodash";
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

import {
    select2HashtagOption
} from '../../../../../shared/configuration/select2-hashtag-configuration';
import { ProductHashTagView } from '../../../../../shared/views/product-hashtag.view';

declare var $: any;

@Component({
    selector: 'op-hashtag',
    templateUrl: './hashtag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HashtagComponent {

    @Input() productHashTag$ = new BehaviorSubject<ProductHashTagView[]>(null);
    @Input() disabled: boolean = false;
    @Input() searchMode: boolean = false;
    @Input() showHashtagRow: boolean = false;
    @Output() valueReturn = new EventEmitter<any>();
    @Output() showHashtagRowChange = new EventEmitter<boolean>();
    public hashTagOption: any;
    focusing = false;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    private subscription: Subscription

    constructor() {
        this.hashTagOption = cloneDeep(select2HashtagOption);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['productHashTag$']) {
            this.subscription = this.productHashTag$.subscribe((values) => {
                if (values) {
                    let show = this.selectedExists();
                    this.setShowHashtagRow(show);
                } else {
                    this.setShowHashtagRow(false);
                }
            });
        }
    }

    public hashTagChange(value) {
        this.clearParam(value);
        this.valueReturn.emit(value);
    }

    public add() {
        this.setShowHashtagRow(true);
    }

    public delete() {
        this.setShowHashtagRow(false);
        this.clearParam([]);
        this.valueReturn.emit([]);
    }

    public setShowHashtagRow(value: boolean) {
        this.showHashtagRow = value;
        this.showHashtagRowChange.emit(this.showHashtagRow);
    }

    public selectedExists(): boolean {
        if (this.productHashTag$.value) {
            let isExists = this.productHashTag$.value.filter(x => x.productHashTagId != null || x.param).length > 0;
            if (isExists && !this.showHashtagRow) {
                this.setShowHashtagRow(true);
            }
            return isExists;
        }
        return false;
    }

    private clearParam(values: string[]) {
        let hashTags = this.productHashTag$?.value;
        if (!hashTags) {
            return;
        }
        if (values?.length) {
            this.updateParam(hashTags, values);
        } else {
            this.updateAllParam(hashTags);
        }
    }

    private updateParam(hashTags: ProductHashTagView[], values: string[]) {
        for (let hashTag of hashTags) {
            let filter = values.find(x => x == hashTag.hashTagId);
            if (!filter) {
                hashTag.param = false;
            }
        }
    }
    private updateAllParam(hashTags: ProductHashTagView[]) {
        hashTags.filter(x => x.param).forEach(x => {
            x.param = false;
        });
    }
}
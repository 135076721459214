import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';

import { OopsCategory } from 'src/app/core/components/categories/oops-category.model';
import { OopsCategoryContextMenu } from 'src/app/core/components/categories/context-menu/categories-context-menu.model';
import { ExchangeRateGroupMapperService } from './exchange-rate-group.mapper';
import { ExchangeRateGroupService, LogCommitService } from 'src/app/core/services/system-services';
import { ExchangeRateGroupModel } from 'src/app/core/models/reference-model/reference-general-model';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { switchMap } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';

@Component({
    selector: 'op-exchange-rate-group',
    templateUrl: './exchange-rate-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ExchangeRateGroupMapperService]
})
export class ExchangeRateGroupComponent implements AfterViewInit {
    @Output() rowSelected = new EventEmitter<OopsCategory>();
    @Output() menuSelected = new EventEmitter<string>();
    private displayActionId = 'DISPLAY';
    public categoriesItems: OopsCategory[] = [];
    private contextMenu: OopsCategoryContextMenu[] = [
        {
            name: 'Display',
            actionId: this.displayActionId
        },
    ]
    exchangeRateGroupModels: ExchangeRateGroupModel[]
    subscriptions: Subscription[]
    constructor(
        private exchangeRateGroupService: ExchangeRateGroupService,
        private mainSpinner: LoadingSpinnerService,
        private mapperService: ExchangeRateGroupMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private securityGroupService: SecurityGroupService,
        private logCommitService: LogCommitService,
    ) { }

    ngAfterViewInit(): void {
        this.getExchangeRateGroup();
    }

    public getExchangeRateGroup() {
        this.mainSpinner.show();
        this.exchangeRateGroupService.getAll()
        .pipe(
            switchMap((exchangeRateGroupModels: ExchangeRateGroupModel[]) => {
                this.exchangeRateGroupModels = exchangeRateGroupModels
                return combineLatest([
                    this.securityGroupService.userSecurityGroupSecurity,
                    this.logCommitService.getByCode("EXCHANGERATE"),
                ])
            })
        )
        .subscribe(
            ([
                userSecurity,
                logCommitExchangeRate
            ]) => {
                this.categoriesItems = this.mapperService.toOopsCategoryViews(this.exchangeRateGroupModels, userSecurity, logCommitExchangeRate);
                this.setContextMenus(this.categoriesItems);
                this.mainSpinner.hide();
                this.changeDetectionRef.detectChanges();
            },
            error => {
                this.mainSpinner.hide();
            }
        );
    }

    private setContextMenus(categoriesItems: OopsCategory[]) {
        for (let item of categoriesItems) {
            item.contextMenus = this.contextMenu;
        }
    }

    onRowSelected(item: OopsCategory) {
        this.rowSelected.emit(item);
    }

    onContextMenuSelected(actionId: string) {
        this.menuSelected.emit(actionId);
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from '../../base/api-service-base';
import { OrderStateReferenceModel } from "../../models/reference-model/reference-general-model/order-state-reference.model";

@Injectable({
  providedIn: 'root'
})
export class OrderStateReferenceService extends ApiServiceBase {
  private readonly PATH = "/system/OrderStateReferences";

  constructor(private httpClient: HttpClient,
    private authService: AuthService) {
      super(httpClient, authService);
  }

  public getOrderStateReference(): Observable<OrderStateReferenceModel[]> {
    return this.httpGet<OrderStateReferenceModel[]>(this.PATH, null)
      .pipe(
        map(
          response => response.body
        )
      )
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { InsightOrderSearchModel } from "src/app/core/models/individual-model/individual-order-model/insight-order-search.model";
import { OrdersMapperService } from "../../shared/order-mapper.service";
import { OrderSearchView } from "../../shared/views/order-search-view";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { PrintHeaderModel } from "src/app/shared/ui/export-button-group";
import { StatusReferenceType } from "src/app/shared/ui/forms/inputs/status-badge/status-reference.type";

@Component({
    selector: 'op-insight-sales-summary-result-order-list',
    templateUrl: './sales-summary-result-order-list.component.html',
    providers: [
        OrdersMapperService
    ]
})
export class InsightSalesSummaryResultOrderListComponent implements OnChanges {
    readonly DATE_WITH_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
    public readonly EXPORT_FILE_NAME = "Sales Summary Order List"

    public StatusReferenceType = StatusReferenceType;
    
    public showProductList = true;
    public exportData: any;
    public header: PrintHeaderModel[];
    public orderSearchResult: OrderSearchView[]
    public focused: boolean = false;
    public resultCount: number = 0;
    
    public focusing: boolean = false;
    

    public pdfDefaultStyle = {
        fontSize: 10
    };

    @Input() individualId: string;
    @Input() insightOrderSearchResults: InsightOrderSearchModel[];

    @Output() onChangeListView = new EventEmitter();

    @ViewChild('ordersSearchGrid', { static: false }) dataGrid: DxDataGridComponent;

    constructor(private changeDetector: ChangeDetectorRef,
        private mapperService: OrdersMapperService,
        private stringUtils: StringHelperService) {

    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insightOrderSearchResults']) {
            this.orderSearchResult = this.mapperService.toOrderSearchViews(this.insightOrderSearchResults);
            this.changeDetector.detectChanges();
        }
    }

    individualFullName = (view: OrderSearchView) => {
        if (view.groupName) {
            return view.groupName
        }
        return view.individualName
    }

    public fetchRowsData(e) {
        this.resultCount = e.component.totalCount();
        this.getExportData()?.done((filteredData) => {
            this.exportData = this.mapExportData(filteredData);
        });        
  
        this.changeDetector.detectChanges();
    }

    private getExportData() {
        let filterExpr = this.dataGrid?.instance?.getCombinedFilter();
        let gridDataSource = this.dataGrid?.instance?.getDataSource();
        return gridDataSource?.store().load({
            filter: filterExpr
        });
    }

    changeListView() {
        this.onChangeListView.emit();
    }

    public onCellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        if (e.column.dataField == "totalAmount" || e.column.dataField == "paidAmount" || e.column.dataField == "outstandingAmount") {
            e.cellElement.css("text-align", "right");
        }
    }

    public getAmountValue(amount: number) {
        if (amount === 0) {
            return '';
        }

        return this.stringUtils.validateNullAmountDecimal(amount);
    }

    public getColorAmountDisplay(cell): string {
        if (cell.column.dataField == 'outstandingAmount') {
            return this.getOutstandingBalanceColor(cell?.key?.outstandingAmount)
        } else if (cell.column.dataField == 'paidAmount') {
            return this.getPaidAmountColor(cell?.key?.paidAmount, cell?.key?.totalAmount);
        }
        return '';
    }

    public getOutstandingBalanceColor(outstandingBalance: number): string {
        if (outstandingBalance > 0) {
            return 'color-danger-500';
        } else if (outstandingBalance < 0) {
            return 'outstanding-amount-refund'
        }
        return '';
    }

    public getPaidAmountColor(paidAmount: number, totalAmount: number): string {
        if (paidAmount > 0) {
            return 'paid-amount-fully-paid'
        }
        return '';
    }

    public mapExportData(filteredData) {
        return filteredData.map(dat => (
        {
            orderNumber: dat.orderNumber,
            orderDate: dat.orderDate,
            orderNumberPoinOfSales: dat.orderNumberPOS,
            organisation: dat.organisationCallName,
            salesStatus: dat.orderSalesStatusName,
            deliveryStatus: dat.orderDeliveryStatusName,
            paymentStatus: dat.orderPaymentStatusName,
            currency: dat.currencyCode,
            totalAmount: dat.totalAmount,
            paidAmount: dat.paidAmount,
            outstandingAmount: dat.outstandingAmount,
        }
    ));
    }

    private getDataGridHeader() {
        this.header = [
            {
                mapField: "orderNumber",
                displayName: "Order Number"
            },
            {
                mapField: "orderDate",
                displayName: "Order Date"
            },
            {
                mapField: "orderNumberPoinOfSales",
                displayName: "Order Number POS"
            },
            {
                mapField: "organisation",
                displayName: "Organisation"
            },
            {
                mapField: "salesStatus",
                displayName: "Sales Status"
            },
            {
                mapField: "deliveryStatus",
                displayName: "Delivery Status"
            },
            {
                mapField: "paymentStatus",
                displayName: "Payment Status"
            },
            {
                mapField: "currency",
                displayName: "Currency"
            },
            {
                mapField: "totalAmount",
                displayName: "Total Amount"
            },
            {
                mapField: "paidAmount",
                displayName: "Paid Amount"
            },
            {
                mapField: "outstandingAmount",
                displayName: "Outstanding Amount"
            },
        ];
      }
}


export const select2Status = {
    placeholder: "",
    allowClear: false,
    minimumResultsForSearch: -1,
    dropdownCssClass: 'status-dropdown-selector'
}

export const select2FormStatus = {
    placeholder: "",
    allowClear: false,
    dropdownCssClass: 'status-dropdown-selector-form'
}
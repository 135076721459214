import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model/invoice-individual-search.model';
import { InvoiceOrganisationSearchModel } from 'src/app/core/models/organisation-model';
import { PaymentDocumentInvoiceConstant } from './shared/payment-document-invoice.constants';
import { IndividualDocumentInvoiceComponent } from './individual-document-invoice/individual-document-invoice.component';
import { OrganisationDocumentInvoiceComponent } from './organisation-document-invoice/organisation-document-invoice.component';

@Component({
    selector: 'op-payment-document-invoice',
    templateUrl: './payment-document-invoice.component.html',
})
export class PaymentDocumentInvoiceComponent {
    @Input() invoiceReceiverType: string;
    @Output() individualSelected = new EventEmitter<InvoiceIndividualSearchModel>();
    @Output() organisationSelected = new EventEmitter<InvoiceOrganisationSearchModel>();

    @ViewChild(IndividualDocumentInvoiceComponent) individualComponent: IndividualDocumentInvoiceComponent;
    @ViewChild(OrganisationDocumentInvoiceComponent) organisationComponent: OrganisationDocumentInvoiceComponent;

    get displayIndividual(): boolean {
        return this.invoiceReceiverType === PaymentDocumentInvoiceConstant.INDIVIDUAL_RECEIVER;
    }

    get displayOrganisation(): boolean {
        return this.invoiceReceiverType === PaymentDocumentInvoiceConstant.ORGANISATION_RECEIVER;
    }

    onIndividualSelected(individual: InvoiceIndividualSearchModel) {
        this.individualSelected.emit(individual);
    }

    onOrganisationSeleted(organisation: InvoiceOrganisationSearchModel) {
        this.organisationSelected.emit(organisation);
    }

}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { PriceRuleCategoryReferenceService, StatusReferenceService } from "src/app/core/services/system-services";
import { UsageTypeReferenceService } from "src/app/core/services/system-services/usage-type-reference.service";
import { ActionService } from "src/app/core/utils/action.service";
import { FavoriteConstant } from "src/app/modules/favorite/shared/favorite.constant";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { select2DefaultOptionWithAllowClear } from "../../../shared/search/shared/search-information-configuration";
import { PriceModel } from "../../shared/models";
import { PriceService } from "../../shared/price.service";
import { PriceView } from "../../shared/views";

@Component({
    selector: 'op-price-search-condition',
    templateUrl: './search-condition.component.html',
    styles: [
        `
        .loading-spinner {
            margin-top: 12px;
            position: absolute;
            right: 34px;
        }
        `
    ]
})

export class SearchConditionComponent extends OopsComponentFormBase implements OnChanges {
    private readonly PRICE_CONDITION_TEMPLATE_CODE = 'PRICECONDTEMPLATE';
    private readonly PRICE_DIMENSION_TEMPLATE_CODE = 'PRICEDIMENTEMPLATE';
    @Input() classIcon: string;
    @Input() advanceSearchMode: boolean;
    @Input() priceView: PriceView;
    @Input() priceSearchFilters: Select2Data[];
    @Input() priceSearchFilterId: string = null;
    @Input() executeFlag: boolean = true;

    @Output() advanceSearchModeChange = new EventEmitter<boolean>();
    @Output() onSearch = new EventEmitter<PriceModel>();
    @Output() onClear = new EventEmitter();
    @Output() priceSearchFilterIdChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing: boolean = false;

    priceRuleCategoryReferences = this.priceRuleCategoryReferenceService.getPriceRuleCategoryReferences()
        .pipe(map(res => res.map(r => new Select2Data(r.priceRuleCategoryCode, r.priceRuleCategoryName))));
    statusReferences$ = this.statusReferenceService.getAll()
        .pipe(map(res => res.map(r => new Select2Data(r.statusCode, r.displayName))));
    usageTypeCodes$ = this.usageTypeReferenceService.getUsageTypeReferences()
        .pipe(map(res => res?.filter(r => 
            r.usageTypeCode != this.PRICE_CONDITION_TEMPLATE_CODE && 
            r.usageTypeCode != this.PRICE_DIMENSION_TEMPLATE_CODE)
            ?.map(r => new Select2Data(r.usageTypeCode, r.usageTypeName))));

    select2DefaultOption = select2DefaultOptionWithAllowClear;

    constructor(
        fb: UntypedFormBuilder,
        private priceRuleCategoryReferenceService: PriceRuleCategoryReferenceService,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private actionService: ActionService) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['priceView'] && this.formGroup) {
            this.formGroup.patchValue({
                usageTypeCode: this.priceView.searchUsageTypeCode,
                priceCode: this.priceView.priceCode,
                priceId: this.priceView.priceId,
                priceName: this.priceView.searchName,
                status: this.priceView.searchStatusCode
            })
        }
    }

    ngAfterViewInit(): void {
        if (this.executeFlag == true) {
            this.searchItems();
        }
        if (this.priceSearchFilterId) {
            this.onSearchFilterChange(this.priceSearchFilterId);
        }
    }

    initForm() {
        this.formGroup = new UntypedFormGroup({
            usageTypeCode: new UntypedFormControl(null),
            priceCode: new UntypedFormControl(null),
            priceName: new UntypedFormControl(null),
            status: new UntypedFormControl("A")
        })
    }

    onSearchFilterChange(value: string | string[]) {
        let priceId = value as string;
        this.priceSearchFilterId = priceId;
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/PRICE', priceId, this.getSearchFilterName(priceId));
        this.priceSearchFilterIdChange.emit(priceId);
    }

    convertFormGroupToModel(): PriceModel {
        let model = new PriceModel();
        model.priceCode = this.getValueFromControl('priceCode');
        model.priceName = this.getValueFromControl('priceName');
        model.usageTypeCode = this.getValueFromControl('usageTypeCode'),
        model.status = this.getValueFromControl('status');
        return model;
    }

    getValueFromControl(controlName: string): string {
        return this.formGroup.controls[controlName]?.value;
    }

    searchItems() {
        this.onSearch.emit(this.getValues());
    }

    onSearchButtonClick(event) {
        event.preventDefault();
        this.searchItems();
    }

    clearCondition() {
        this.onClear.emit();
    }

    onAdvanceSearchClick() {
        this.advanceSearchModeChange.emit(!this.advanceSearchMode);
    }

    public getValues(): PriceModel {
        return this.convertFormGroupToModel();
    }

    private getSearchFilterName(id: string) {
        return this.priceSearchFilters?.find(item => item.id == id)?.text;
    }
}
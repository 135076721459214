import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";

import { Observable, Subscriber } from "rxjs";

import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { LoadingNotifier } from "../../../../../shared/layout/loading-spinner";
import {
  IndividualChangeResponseModel,
  OrderIndividualDocumentModel,
} from "src/app/core/models/order-model";
import { OrderDetailServices } from "../../../../../core/services/order-services";
import {
  OrdersMapperService,
  OrderDetailIndividualDocumentView,
  OrderDetailChildLoadData,
  OrderDetailIndividualView,
} from "../..";
import { GlobalStateService } from "src/app/shared/ui/uiservice/global-state.service";
import { StringHelperService } from "src/app/core/utils/string-helper.service";
import { cloneDeep } from "lodash";
import { IndividualChangeMapperService } from "../../individual-change-mapper.service";
import { OrderIndividualChangeService } from "src/app/core/services/order-services/order-individual-change.service";
import { OrderChangeIndividualResponseView } from "../../views/order-change-individual-response.view";
import { OrderDetailIndividualDocumentActionbarComponent } from "./order-detail-individual-document-actionbar/order-detail-individual-document-actionbar.component";

@Component({
  selector: "op-order-detail-individual-document-list",
  templateUrl: "./order-detail-individual-document-list.component.html",
  providers: [OrdersMapperService],
})
export class OrderDetailIndividualDocumentListComponent implements AfterViewChecked {
  public readonly SPINNER_NAME: string = "orderIndividualDocumentList";
  public readonly SPINNER_FULL_SCREEN: boolean = false;

  @Input("id") id: string;
  @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;
  @Input("showPanelCounter") showPanelCounter: boolean = false;
  @Input("hideNotExist") hideWhenNotExist: boolean = false;
  @Input("changeSessionId") changeSessionId: string;
  @Input("headerOrderId") headerOrderId: string;
  @Input("individual") individual: OrderDetailIndividualView;
  @Input("disableActionButton") disableActionButton: boolean = false;

  @Output("childLoadCompleted") childLoadCompleted =
    new EventEmitter<OrderDetailChildLoadData>();
  @Output() completeChange =
    new EventEmitter<OrderChangeIndividualResponseView>();
  @Output() failedChange =
    new EventEmitter<OrderChangeIndividualResponseView>();
  @Output() startChange = new EventEmitter();

  @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
  @ViewChild("panel") panel: any;

  @ViewChildren(OrderDetailIndividualDocumentActionbarComponent) actionBars: QueryList<OrderDetailIndividualDocumentActionbarComponent>;

  public collapsed: boolean;
  public focused: boolean;
  public loadingNotifier = new LoadingNotifier();
  public orderIndividualDocumentViews: OrderDetailIndividualDocumentView[] =
    new Array<OrderDetailIndividualDocumentView>();
  public orderLock: boolean;

  get countNumberOfItems(): number {
    return this.orderIndividualDocumentViews?.length || 0;
  }

  get newButtonDisabled$(): Observable<boolean> {
    return this.globalStateService.disabled$(this.ADD_NEW_BUTTON_ID);
  }

  addNewOrderIndividualDocumentView: OrderDetailIndividualDocumentView;
  editOrderIndividualDocumentViews: OrderDetailIndividualDocumentView[] = [];

  private readonly ADD_NEW_BUTTON_ID = this.stringHelperService.NewGuid();

  constructor(
    private changeDetection: ChangeDetectorRef,
    private orderDetailService: OrderDetailServices,
    private mapperService: OrdersMapperService,
    private globalStateService: GlobalStateService,
    private stringHelperService: StringHelperService,
    private individualChangeMapperService: IndividualChangeMapperService,
    private individualChangeService: OrderIndividualChangeService
  ) {
    this.globalStateService.register(this.ADD_NEW_BUTTON_ID);
  }

  ngAfterViewChecked(): void {
    this.toggleDisableActionButton();
  }

  private checkCollape(): void {
    if (
      !this.orderIndividualDocumentViews ||
      this.orderIndividualDocumentViews.length == 0 ||
      this.collapeWhenLoad
    ) {
      this.collapsed = true;
      return;
    }
    this.collapsed = false;
  }
  public loadIndividualDocuments(
    orderId: string,
    orderLock: boolean
  ): Observable<boolean> {
    if (!orderId) {
      return;
    }
    this.orderLock = orderLock;
    return new Observable((observer) => {
      this.orderDetailService
        .getOrderIndividualDocuments(orderId, this.changeSessionId)
        .subscribe((result: OrderIndividualDocumentModel[]) =>
          this.executeServiceResult(result, observer)
        );
    });
  }

  public loadIndividualDetailDocuments(
    orderId: string,
    partnerOrderId: string,
    orderLock: boolean): Observable<boolean> {
    if (!orderId) {
      return;
    }
    this.orderLock = orderLock;
    return new Observable((observer) => {
      this.orderDetailService
        .getOrderIndividualDetailDocuments(orderId, partnerOrderId, this.changeSessionId)
        .subscribe((result: OrderIndividualDocumentModel[]) =>
          this.executeServiceResult(result, observer)
        );
    });
  }

  public loadProductDetailDocuments(
    orderId: string,
    orderProductId: string,
    orderLock: boolean
  ): Observable<boolean> {
    if (!orderId) {
      return;
    }
    this.orderLock = orderLock;
    return new Observable((observer) => {
      this.orderDetailService
        .getOrderProductDetailDocuments(orderId, orderProductId, this.changeSessionId)
        .subscribe((result: OrderIndividualDocumentModel[]) =>
          this.executeServiceResult(result, observer)
        );
    });
  }

  public loadDetailDocumentsByOrder(
    parentOrderId: string,
    orderId: string,
    orderLock: boolean
  ): Observable<boolean> {
    if (!orderId) {
      return;
    }
    this.orderLock = orderLock;
    return new Observable((observer) => {
      this.orderDetailService
        .getOrderDetailDocumentsByOrder(parentOrderId, orderId, this.changeSessionId)
        .subscribe((result: OrderIndividualDocumentModel[]) =>
          this.executeServiceResult(result, observer)
        );
    });
  }

  public startLoading() {
    this.loadingNotifier.show(this.SPINNER_NAME);
  }

  public stopLoading() {
    this.loadingNotifier.hide(this.SPINNER_NAME);
  }

  private executeServiceResult(
    result: OrderIndividualDocumentModel[],
    observer: Subscriber<boolean>) {

    this.orderIndividualDocumentViews = this.mapperService.toIndividualDocumentViews(result, true);
    this.childLoadCompleted.emit({
      recordCount: this.orderIndividualDocumentViews?.length,
    });
    this.checkCollape();
    this.changeDetection.detectChanges();
    observer.next(true);
    observer.complete();
  }

  public getUnderlineClass(rowIndex: number): string {
    if (rowIndex === 0) {
      return "";
    }
    return "order-detail-start-section";
  }
  public togglePanel($event: MouseEvent) {
    this.panel.toggleCollapse($event);
  }
  public collapsedStatus(collapsed: any) {
    this.collapsed = collapsed;
  }
  public hidePanel(): boolean {
    if (this.collapsed == true && this.hideWhenNotExist == true) {
      return true;
    }
    return false;
  }
  public showCounter(): boolean {
    return this.showPanelCounter && this.countNumberOfItems > 0;
  }

  onNewClick() {
    this.addNewOrderIndividualDocumentView = this.getEmptyView();
    this.globalStateService.disableAll();
  }

  onApplyNew(view: OrderDetailIndividualDocumentView) {

    this.fillIndividualInfo(view, this.individual);
    this.orderIndividualDocumentViews = this.orderIndividualDocumentViews || [];
    this.orderIndividualDocumentViews.push(view);

    let models = this.individualChangeMapperService.orderDetailIndividualIdentityDocuments(
      this.orderIndividualDocumentViews,
      this.id);

    this.startChange.emit();

    this.individualChangeService.orderChangeIdentityDocument(
      this.headerOrderId,
      this.changeSessionId,
      models)
      .subscribe(
        (response: IndividualChangeResponseModel) => {
          if (response) {
            this.globalStateService.enableAll();
            this.completeChange.emit({
              sessionId: response.sessionId,
            });
            this.closeNewPanel();
          }
        },
        (failedResponse: IndividualChangeResponseModel) => {
          this.failedChange.emit({
            sessionId: failedResponse.sessionId,
            message: failedResponse.message,
          });
        }
      );
  }

  onCloseNew() {
    this.closeNewPanel();
    this.globalStateService.enableAll();
  }

  onEditClick(
    individualDocument: OrderDetailIndividualDocumentView,
    index: number
  ) {
    this.openEditPanel(individualDocument, index);
    this.globalStateService.disableAll();
  }

  onDeleteClick(view: OrderDetailIndividualDocumentView) {

    let models = this.individualChangeMapperService.orderDetailIndividualIdentityDocuments(
      [view],
      this.id);

    this.startChange.emit();
    this.individualChangeService.orderCancelIdentityDocument(
      this.headerOrderId,
      this.changeSessionId,
      models)
      .subscribe(
        (response: IndividualChangeResponseModel) => {
          if (response) {
            this.globalStateService.enableAll();
            this.completeChange.emit({
              sessionId: response.sessionId,
            });
          }
        },
        (failedResponse: IndividualChangeResponseModel) => {
          this.failedChange.emit({
            sessionId: failedResponse.sessionId,
            message: failedResponse.message,
          });
        }
      );
  }

  onApplyEdit(view: OrderDetailIndividualDocumentView, index: number) {

    this.fillIndividualInfo(view, this.individual);
    this.orderIndividualDocumentViews[index] = view;

    let models = this.individualChangeMapperService.orderDetailIndividualIdentityDocuments(
      this.orderIndividualDocumentViews,
      this.id);

    this.startChange.emit();
    this.individualChangeService.orderChangeIdentityDocument(
      this.headerOrderId,
      this.changeSessionId,
      models)
      .subscribe(
        (response: IndividualChangeResponseModel) => {
          if (response) {
            this.closeEditPanel(index);
            this.completeChange.emit({
              sessionId: response.sessionId,
            });
          }
        },
        (failedResponse: IndividualChangeResponseModel) => {
          this.failedChange.emit({
            sessionId: failedResponse.sessionId,
            message: failedResponse.message,
          });
        }
      );
  }

  onCloseEdit(index: number) {
    this.closeEditPanel(index);
    this.globalStateService.enableAll();
  }

  private openEditPanel(
    individualDocument: OrderDetailIndividualDocumentView,
    index: number
  ) {
    this.editOrderIndividualDocumentViews[index] =
      cloneDeep(individualDocument);
  }

  private closeEditPanel(index: number) {
    this.editOrderIndividualDocumentViews[index] = null;
  }

  private fillIndividualInfo(
    view: OrderDetailIndividualDocumentView,
    individual: OrderDetailIndividualView
  ) {
    if (!view) return;
    view.firstName = individual?.firstName;
    view.lastName = individual?.lastName;
    view.title = individual?.titleName;
    view.partnerOrderId = individual?.orderId;
  }

  private closeNewPanel() {
    this.addNewOrderIndividualDocumentView = null;
  }

  private getEmptyView(): OrderDetailIndividualDocumentView {
    return {
      identityDocumentId: null,
      identityDocumentCode: null,
      identityDocumentName: null,
      identityDocumentNumber: null,
      countryCode: null,
      countryName: null,
      expiryDate: null,
      lastName: null,
      firstName: null,
      title: null,
      allowedEdit: null,
      showDetail: null,
      individualId: null,
      orderId: null,
      partnerOrderId: null,
      issueDate: null,
      placeOfIssue: null,
      orderSalesStatusCode: null
    };
  }

  private toggleDisableActionButton(): void {
    if (!this.actionBars?.length) return;
    this.actionBars.forEach((actionBar, index) => {
      actionBar.disableActionButton(this.disableActionButton);
    })
  }
  
  public onUndoClick(index: number) {

    let otherDocument = this.orderIndividualDocumentViews[index];

    this.startChange.emit();
    this.individualChangeService.orderUndoIndividualChange(this.headerOrderId, otherDocument.orderId, this.changeSessionId)
      .subscribe(
        (response: IndividualChangeResponseModel) => {
          if (response) {
            this.closeEditPanel(index);
            this.completeChange.emit({
              sessionId: response.sessionId,
            });
          }
        },
        (failedResponse: IndividualChangeResponseModel) => {
          this.failedChange.emit({
            sessionId: failedResponse.sessionId,
            message: failedResponse.message,
          });
        }
      );
  }
}

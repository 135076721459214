import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { OopsComponentFormBase } from "src/app/core/base/oops-component-form-base";
import { StatusReferenceService } from "src/app/core/services/system-services";
import { UsageTypeReferenceService } from "src/app/core/services/system-services/usage-type-reference.service";
import { DateConverterService } from "src/app/core/utils/date-converter.service";
import { Helper } from "src/app/shared/helper/app.helper";
import { AlertBarService } from "src/app/shared/layout/alertbar";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { select2Status, select2UsageType } from "../../../rules/price-rule-detail/shared/pricing-detail-information-configuration";
import { PriceModel } from "../../shared/models";

@Component({
    selector: "op-price-detail-general",
    templateUrl: "./general.component.html"
})
export class GeneralComponent extends OopsComponentFormBase implements OnChanges {
    private readonly TEMPLATE_USAGE_TYPE = "TEMPLATE";
    private readonly FILTER_USAGE_TYPE = "FILTER";
    private readonly DATA_USAGE_TYPE = "DATA";
    private readonly PRODUCT_USAGE_TYPE = "PRODUCT";
    private readonly PRICE_CONDITION_TEMPLATE_CODE = "PRICECONDTEMPLATE";
    private readonly PRICE_DIMENSION_TEMPLATE_CODE = "PRICEDIMENTEMPLATE";

    public readonly PANEL_NAME = "General Information";
    public readonly PRICE_CODE_REQUIRED = "Price Code is required."
    public readonly PRICE_NAME_REQUIRED = "Price Name is required."

    @Input() price: PriceModel;
    @Input() disabled: boolean = false;

    @Output() onPanelHeightChange = new EventEmitter();
    @Output() focus = new EventEmitter();
    @Output() priceNameChange = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    focusing = false;

    public statusOption = select2Status;
    public usageTypeOption = select2UsageType;
    public statusReferences$ = this.statusReferenceService.getAll();

    private errorMapping = [
        {
            controlName: 'priceCode',
            errorMessage: this.PRICE_CODE_REQUIRED
        },
        {
            controlName: 'priceName',
            errorMessage: this.PRICE_NAME_REQUIRED
        }
    ]

    usageTypeReferences$ = this.usageTypeReferenceService
        .getUsageTypeReferences()
        .pipe(
            map((res) =>
                res.filter(
                    (r) =>
                        r.usageTypeCode != this.FILTER_USAGE_TYPE &&
                        r.usageTypeCode != this.PRICE_CONDITION_TEMPLATE_CODE &&
                        r.usageTypeCode != this.PRICE_DIMENSION_TEMPLATE_CODE
                )
            )
        );

    get status(): string {
        return this.formGroup.controls["status"].value;
    }

    set status(val: string | string[]) {
        this.formGroup.controls["status"].patchValue(val);
    }

    get usageTypeCode(): string {
        return this.formGroup.controls["usageTypeCode"].value;
    }

    set usageTypeCode(val: string | string[]) {
        this.formGroup.controls["usageTypeCode"].patchValue(val);
    }

    get invalidPriceCode(): boolean {
        return this.isInvalidPriceCode();
    }
    
    get showRequiredFieldAsterisk(): boolean {
        return this.usageTypeCode != this.TEMPLATE_USAGE_TYPE;
    }
    
    get disabledDraftFlag(): boolean {
        if (!this.price.priceId) {
            return false;
        }
        return !this.price.draftFlag;
    }

    get priceCode(): string {
        return this.formGroup.get("priceCode").value?.toUpperCase();
    }

    get disableUsageTypeCode(): boolean {
        if (!this.price?.priceId) {
            return false;
        }
        const disableUsageType = [
            this.DATA_USAGE_TYPE,
            this.PRODUCT_USAGE_TYPE
        ]
        return disableUsageType.includes(this.price.usageTypeCode);
    }

    constructor(
        fb: UntypedFormBuilder,
        public helper: Helper,
        private statusReferenceService: StatusReferenceService,
        private usageTypeReferenceService: UsageTypeReferenceService,
        private dateConverterService: DateConverterService,
        private alertbarService: AlertBarService) {
        super(fb);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["price"]) {
            this.initFormWithValues();
        }
    }

    public initForm() {
        this.initFormWithValues();
    }

    private initFormWithValues() {
        this.formGroup = new UntypedFormGroup({
            priceId: new UntypedFormControl(this.price?.priceId),
            priceCode: new UntypedFormControl(this.price?.priceCode, Validators.required),
            priceName: new UntypedFormControl(
                this.price?.priceName,
                Validators.required
            ),
            organisationId: new UntypedFormControl(this.price?.organisationId),
            status: new UntypedFormControl(this.price?.status),
            usageTypeCode: new UntypedFormControl(
                this.price?.usageTypeCode,
                Validators.required
            ),
            draftFlag: new UntypedFormControl({
                value: this.getDraftFlag(),
                disabled: this.disabledDraftFlag
            }),
            commitBy: new UntypedFormControl({
                value: this.price?.commitBy,
                disabled: true,
            }),
            commitDateTime: new UntypedFormControl({
                value: this.dateConverterService.convertDate(
                    this.price?.commitDateTime
                ),
                disabled: true,
            }),
        });
        this.formGroup.controls['priceName'].valueChanges.subscribe(
            priceName => {
                this.priceNameChange.emit(priceName);
            }
        )

        this.formGroup.markAllAsTouched();
        this.markRequiredControlsAsPristineAndUntouchedWhenValueChange();

        setTimeout(() => {
            if (this.disableUsageTypeCode) {
                this.formGroup.get('usageTypeCode').disable();
            } else {
                this.formGroup.get('usageTypeCode').enable();
            }
        }, 0)

        this.setupSecurity();
    }

    private setupSecurity() {
        if (this.disabled == true) {
            this.formGroup.disable();
            return;
        }
        this.formGroup.get('priceCode').enable();
        this.formGroup.get('priceName').enable();
        this.formGroup.get('status').enable();
    }

    private getDraftFlag(): boolean {
        if (!this.price || !this.price.priceId) {
            return true;
        }
        return this.price?.draftFlag ?? false;
    }


    private markRequiredControlsAsPristineAndUntouchedWhenValueChange() {
        for (let key of Object.keys(this.formGroup.controls)) {
            let control = this.formGroup.controls[key];
            if (control.validator && control.validator({} as AbstractControl)?.required) {
                control.valueChanges.subscribe(() => {
                    control.markAsPristine();
                    control.markAsUntouched();
                    if (this.processing) {
                        this.showAlertbarError();
                    }
                });
            }
        }
    }

    public getValues(): PriceModel {
        this.startProcessing();
        if (!this.validForm()) {
            this.showAlertbarError();
            return;
        }

        this.completeProcessing();
        return this.getRawValues();
    }

    private showAlertbarError() {
        const errorKey = Object.keys(this.formGroup.controls).find(key => this.formGroup.controls[key].errors);
        if (errorKey) {
            this.alertbarService?.show(this.PANEL_NAME, this.errorMapping.find(em => em.controlName == errorKey).errorMessage);
        } else {
            this.alertbarService.hide();
        }
    }

    public getRawValues(): PriceModel {
        let price = new PriceModel();
        price.organisationId = this.formGroup.get('organisationId').value;
        price.priceId = this.formGroup.get('priceId').value;
        price.priceCode = this.priceCode;
        price.priceName = this.formGroup.get('priceName').value;
        price.status = this.formGroup.get('status').value;
        price.draftFlag = this.formGroup.get('draftFlag').value;
        price.usageTypeCode = this.formGroup.get('usageTypeCode').value;
        return price;
    }

    onUsageTypeChange() {
        this.isInvalidPriceCode();
    }

    onResized(event: any) {
        this.onPanelHeightChange.emit(event.newRect.height);
    }

    isInvalidPriceCode(): boolean {
        let priceCodeControl = this.formGroup.controls["priceCode"];
        if (
            this.usageTypeCode != this.TEMPLATE_USAGE_TYPE &&
            !priceCodeControl?.value
        ) {
            priceCodeControl.setErrors({ required: true });
            return true;
        }
        priceCodeControl.setErrors(null);
        return false;
    }

    onFocusChange(focusing: boolean) {
        if (focusing) {
            this.focus.emit();
        }
    }
}
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './product-dashboard-fees-statistics.component.html',
    selector: 'op-product-dashboard-fees-statistics'
})
export class ProductDashboardFeesStatisticsComponent implements AfterViewInit {
    public revenueYesterday: number;
    public revenueToday: number
    public cancellationYesterday: number;
    public cancellationToday: number;
    public revenueCancellationYesterday: number;
    public revenueCancellationToday: number;

    constructor(private changeDetection: ChangeDetectorRef) {
        
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    private loadData() {
        setTimeout(() => {
            this.revenueYesterday = 1000;
            this.revenueToday = 5000;
            this.cancellationYesterday = 1000;
            this.cancellationToday = 500;
            this.revenueCancellationYesterday = 1000;
            this.revenueCancellationToday = 1500;

            this.changeDetection.detectChanges();
        }, 500);
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportContentComponent } from './transport-content.component';
import { TransportContentTabsComponent } from './transport-content-tabs/transport-content-tabs.component';
import { TransportGroupComponent } from './transport-group/transport-group.component';
import { TransportSearchModule } from './transport-search/transport-search.module';
import { TransportDetailModule } from './transport-detail/transport-detail.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { OopsCategoriesModule } from 'src/app/core/components/categories/oops-categories.module';
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';
import { AlertBarModule } from 'src/app/shared/layout/alertbar';

@NgModule({
    declarations: [
        TransportContentComponent,
        TransportContentTabsComponent,
        TransportGroupComponent
    ],
    imports: [
        AlertBarModule,
        CommonModule,
        FreezingTabModule,
        NgbNavModule,
        OopsCategoriesModule,
        TransportSearchModule,
        TransportDetailModule,
        UiModule
    ],
    exports: [
        TransportContentComponent
    ]
})
export class TransportContentModule { }

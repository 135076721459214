import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { VehicleSeatMapModel } from "src/app/core/models/vehicle-model/vehicle-seatmap";
import { VehicleSeatMapService } from "src/app/core/services/vehicle-services";
import { LoadingSpinnerService } from "src/app/shared/layout/loading-spinner";
import { FocusingDirective } from "src/app/shared/ui/forms/inputs/focusing.directive";
import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2";
import { SeatPropertyView } from "../seatproperty/shared/seatproperty.view";
import { SeatMapMapperService } from "./seatmap-mapper.service";
import { SeatMapView } from "./seatmap.view";

@Component({
    selector: 'op-vehicle-configuration-seatmap',
    templateUrl: './seatmap.component.html',
    providers: [SeatMapMapperService]
})
export class VehicleConfigurationSeatMapComponent implements OnChanges {
    @Input() id: string;
    @Input() serviceCategorydSelected: Select2Data;
    @Input() seatMapPropertySelected: SeatPropertyView;
    @Output() seatMapRowSelected = new EventEmitter<number[]>();
    @Output() seatMapColAll = new EventEmitter<string>();
    @Output() seatMapColSelected = new EventEmitter<string>();

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    focusing: boolean = false;
    serviceCateName: string;
    vehicleSeatMaps: SeatMapView[] = new Array();
    vehicleCols: string[] = new Array();
    rowSelected: number[] = new Array();
    colSelected: string[] = new Array();
    vehicleSeatMapModels: VehicleSeatMapModel[];

    constructor(private vehicleSeatMapService: VehicleSeatMapService,
        private seatMapMapperService: SeatMapMapperService,
        private spinnerService: LoadingSpinnerService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getSeatMap();
    }

    private getSeatMap() {
        if (this.serviceCategorydSelected) {
            this.serviceCateName = "-" + this.serviceCategorydSelected.text;
            this.spinnerService.show();
            this.vehicleSeatMapService.getByCompartment(this.serviceCategorydSelected.id)
                .subscribe(
                    (responses: VehicleSeatMapModel[]) => {
                        this.rowSelected = [];
                        this.colSelected = [];
                        this.vehicleSeatMapModels = responses;
                        this.vehicleCols = this.seatMapMapperService.seatMapToColViews(responses);
                        this.vehicleSeatMaps = this.seatMapMapperService.seatMapToViews(responses, this.vehicleCols);
                        this.seatMapColAll.emit(this.seatMapMapperService.seatMapToColAll(this.vehicleSeatMaps));
                        this.updateSeatMapPropertySelected(this.seatMapPropertySelected);
                        this.changeDetectorRef.detectChanges();
                        this.spinnerService.hide();
                    }
                )
        }
        else {
            this.serviceCateName = "";
            this.vehicleSeatMaps = [];
            this.vehicleCols = [];
            this.rowSelected = [];
            this.colSelected = [];
            this.vehicleSeatMapModels = [];
        }
    }

    onRowSelected(row: SeatMapView) {
        let filter = this.rowSelected.filter(x => x == row.seatRow);
        if (!filter?.length) {
            this.rowSelected.push(row.seatRow);
        }
        else {
            this.rowSelected.splice(this.rowSelected.indexOf(filter[0]), 1);
        }
        row.selected = !row.selected;
        this.seatMapRowSelected.emit(this.rowSelected);
    }

    onColumnSelected(col: string) {
        let filter = this.colSelected.filter(x => x == col);
        if (!filter?.length) {
            this.colSelected.push(col);
        }
        else {
            this.colSelected.splice(this.colSelected.indexOf(col), 1);
        }
        this.seatMapColSelected.emit(this.getColSelected());
    }

    private getColSelected(): string {
        let colSelected = "";
        this.colSelected.sort((a, b) => a.localeCompare(b));
        for (let col of this.colSelected) {
            colSelected += col;
        }
        return colSelected;
    }

    updateSeatMapPropertySelected(seatMapProperty: SeatPropertyView) {
        this.seatMapPropertySelected = seatMapProperty;
        this.clearAllSelected();
        if (seatMapProperty) {
            let rowFrom = seatMapProperty.seatRowFrom;
            let rowTo = seatMapProperty.seatRowTo;
            this.updateSelected(rowFrom, rowTo);
        } else {
            this.rowSelected = [];
            this.colSelected = [];
            this.clearColSelected();
        }
    }

    private clearAllSelected() {
        for (let seatMap of this.vehicleSeatMaps) {
            seatMap.selected = false;
        }
    }

    private updateSelected(rowFrom: number, rowTo: number) {
        this.rowSelected = [];
        if (rowFrom <= rowTo) {
            for (let i = rowFrom; i <= rowTo; i++) {
                let filter = this.vehicleSeatMaps.filter(x => x.seatRow == i);
                if (filter?.length) {
                    filter[0].selected = true;
                    this.rowSelected.push(filter[0].seatRow);
                }
            }
        }
    }

    private clearColSelected() {
        this.vehicleCols = [];
        this.changeDetectorRef.detectChanges();
        this.vehicleCols = this.seatMapMapperService.seatMapToColViews(this.vehicleSeatMapModels);
    }

}
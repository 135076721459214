import { ButtonBase } from "./models";


export class ActionBarHandlerModel {
    public buttons = Array<ButtonBase>();

    constructor(...btns: ButtonBase[]) {
        for (let btn of btns) {
            this.buttons.push(btn);
        }
    }

    public get(id: string): ButtonBase {
        return this.buttons.find(btn => btn.id == id);
    }
}
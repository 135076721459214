import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationAttributeComponent } from './organisation-attribute.component';
import { OrganisationTypeComponent } from './organisation-type/organisation-type.component';
import { OrganisationRoleComponent } from './organisation-role/organisation-role.component';
import { OrganisationGroupComponent } from './organisation-group/organisation-group.component';
import { OrganisationAttributePanelComponent } from './organisation-attribute-panel/organisation-attribute-panel.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { Select2Module } from 'src/app/shared/ui/forms/inputs/oops-select2/select2.module';
import { FocusingModule } from 'src/app/shared/ui/forms/inputs/focusing.module';
import { MatIconModule } from '@angular/material/icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { NumberOnlyModule } from 'src/app/shared/ui/forms/inputs/number-only/number-only.module';
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';

@NgModule({
    declarations: [
        OrganisationAttributeComponent,
        OrganisationTypeComponent,
        OrganisationRoleComponent,
        OrganisationGroupComponent,
        OrganisationAttributePanelComponent
    ],
    imports: [
        BsDropdownModule,
        CommonModule,
        DaterangepickerModule,
        FocusingModule,
        FormsModule,
        MatIconModule,
        NumberOnlyModule,
        OopsChoiceValueModule,
        PanelsModule,
        ReactiveFormsModule,
        Select2Module,
        UiModule
    ],
    exports: [
        OrganisationAttributeComponent
    ]
})
export class OrganisationAttributeModule { }

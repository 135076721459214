import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { select2SalesBucketOption, select2ServiceCatOption, typeOption } from './service-category-sales-bucket-configuration';
import { cloneDeep } from 'lodash';
import { DomainAttributeModel } from 'src/app/core/models/reference-model/reference-general-model';
import { BehaviorSubject } from 'rxjs';
import { Select2Data } from 'src/app/shared/ui/forms/inputs/oops-select2';
import { ProductAttributeCommandModel, ProductAttributeViewModel } from 'src/app/core/models/product-model/product-base-model/product-attribute';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CHOICES, FOCUS_ATTRIBUTES, SALESBUCKET_CODE, SERVCAT_CODE, TYPE } from '../shared/fee-detail.constant';

@Component({
    selector: 'op-service-category-sales-bucket',
    templateUrl: './service-category-sales-bucket.component.html'
})
export class ServiceCategorySalesBucketComponent extends OopsComponentFormBase implements AfterViewInit, OnChanges {
    private readonly INVALID_INFO = 'Invalid information.';
    @Input() productTypeCode: string;
    @Input() id: string;
    @Input() newProduct: boolean = false;
    @Input() serviceCategoryChoice: Select2Data[];
    @Input() salesBucketChoice: Select2Data[];
    @Input() domainAttribute: DomainAttributeModel[];

    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    public typeOption = typeOption;
    public attributeServiceCatOption: any = typeOption;
    public serviceCatOption: any = cloneDeep(select2ServiceCatOption);
    public saleBucketOption: any = cloneDeep(select2SalesBucketOption);
    public productAttributes: ProductAttributeViewModel[];
    public focusing = false;
    public panelCollapseFlag$ = new BehaviorSubject<boolean>(true);

    get allowToggle(): boolean {
        return this.rows?.length > 0;
    }

    get rows(): UntypedFormArray {
        return this.formGroup?.get('rows') as UntypedFormArray;
    }

    get focusAttributeIds(): string[] {
        return FOCUS_ATTRIBUTES.map(item => item.id);
    }

    get typeValues(): string[] {
        return this.rows.controls?.map(ctrl => ctrl.get(TYPE).value);
    }

    get typeDataSet(): { id: string; text: string }[][] {
        let resultSet = new Array<Array<{ id: string; text: string }>>();
        for (let row of this.rows.controls) {
            let typeData = row.get(TYPE).value;
            let result = FOCUS_ATTRIBUTES
                .filter(a => a.id == typeData || 
                    this.typeValues.includes(a.id) == false)
            resultSet.push(result);
        }
        return resultSet;
    }

    get addDisabled(): boolean {
        let remainingTypes = this.focusAttributeIds
            .filter(type => this.typeValues.includes(type) == false && type != '');
        return !remainingTypes?.length || this.invalidControls;
    }

    get invalidControls(): boolean {
        return this.rows.controls.some(ctrl => ctrl.invalid) 
            || this.serviceCategoryValid == false 
            || this.salesBucketValid == false;
    }

    get salesBucketValid(): boolean {
        let control = this.rows.controls.find(ctrl => ctrl.get(TYPE).value == SALESBUCKET_CODE);
        if (control) {
            return control.get(CHOICES).value?.length;
        }
        return true;        
    }

    get serviceCategoryValid(): boolean {
        let control = this.rows.controls.find(ctrl => ctrl.get(TYPE).value == SERVCAT_CODE);
        if (control) {
            return control.get(CHOICES).value?.length;
        }
        return true;      
    }

    constructor(private changeDetectionRef: ChangeDetectorRef,
        fb: UntypedFormBuilder) {
        super(fb);
    }

    public initForm() {
        this.formGroup = new UntypedFormGroup({
            rows: new UntypedFormArray([]),
        });
    }

    ngAfterViewInit(): void {
        this.addControls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['domainAttribute']) {
            this.addControls();
        }
    }

    public add() {
        this.addEmptyRow();
        this.panelCollapseFlag$.next(false);
    }

    public delete(index: number) {
        this.rows.removeAt(index);
        if (!this.allowToggle) {
            this.panelCollapseFlag$.next(true);
        }
    }

    private addEmptyRow() {
        this.rows.push(this.createNewRow());
    }

    private createNewRow(type: string = null, choices: string[] = []): UntypedFormGroup {
        return new UntypedFormGroup({
            type: new UntypedFormControl(type, Validators.required),
            choices: new UntypedFormControl(choices)
        })
    }

    public typeIsServiceCategory(row: AbstractControl): boolean {
        return row.get('type').value == SERVCAT_CODE;
    }

    public typeIsSalesBucket(row: AbstractControl): boolean {
        return row.get('type').value == SALESBUCKET_CODE;
    }

    private addShowOnNewControls() {
        if (!this.domainAttribute?.length) {
            return;
        }

        let focusAttributes = this.domainAttribute
            .filter(da => this.focusAttributeIds.includes(da.attributeTypeCode));
        if (!focusAttributes?.length) {
            return;
        }
        focusAttributes = focusAttributes.sort((a, b) => this.byAttributeTypes(a.attributeTypeCode, b.attributeTypeCode));
        this.rows.controls = [];
        for (let focusAttribute of focusAttributes) {
            this.rows.push(this.createNewRow(focusAttribute.attributeTypeCode));
        }
        this.panelCollapseFlag$.next(false);
    }

    private byAttributeTypes(a: string, b: string): number {
        return this.focusAttributeIds.indexOf(a) < this.focusAttributeIds.indexOf(b) ? -1 : 1;
    }

    private byDisplaySequence(a: number, b: number): number {
        return a-b/Math.abs(a-b);
    }

    public addControls() {
        if (this.newProduct) {
            this.addShowOnNewControls();
        }
    }

    public fillModelsToForms(productAttributes: ProductAttributeViewModel[]) {
        this.productAttributes = productAttributes;

        let focusProductAttributes = productAttributes?.filter(pa => this.focusAttributeIds.includes(pa.attributeTypeCode) && !pa.inheritAttribute);
        let productAttributeGroups = this.getProductAttributeGroupByAttributeTypeCode(focusProductAttributes)

        this.rows.controls = [];
        for(let key of Object.keys(productAttributeGroups)) {
            this.rows.controls.push(this.createNewRow(key, productAttributeGroups[key].map(item => item.attributeChoiceId)));
        }

        this.panelCollapseFlag$.next(!(this.rows.controls?.length));
        this.changeDetectionRef.detectChanges();
    }

    public resetForms() {
        this.addShowOnNewControls();
    }

    public fillFormsToModels(): ProductAttributeCommandModel[] {
        let models = new Array<ProductAttributeCommandModel>();
        let index = 1;
        for (let row of this.rows.controls) {
            let choiceIds = row.get(CHOICES).value;
            let type = row.get(TYPE).value;
            for (let choiceId of choiceIds) {
                let productAttribute = this.productAttributes?.find(a => a.attributeTypeCode == type && a.attributeChoiceId == choiceId);
                let command = {} as ProductAttributeCommandModel;
                command.productAttributeId = productAttribute?.productAttributeId ?? null;
                command.attributeChoiceId = productAttribute?.attributeChoiceId ?? choiceId;
                command.attributeTypeCode = productAttribute?.attributeTypeCode ?? type;
                command.displaySequence = index;
                models.push(command);
            }
            index++;
        }
        return models;
    }

    private getProductAttributeGroupByAttributeTypeCode(focusProductAttributes: ProductAttributeViewModel[]) {
        return focusProductAttributes.sort((a, b) => this.byDisplaySequence(a.displaySequence, b.displaySequence)).reduce((result, current) => {
            result[current.attributeTypeCode] = result[current.attributeTypeCode] ?? [];
            result[current.attributeTypeCode].push(current);
            return result;
        }, {});
    }

    public validateForm(): boolean {
        this.startProcessing();
        let valid = this.validForm() && this.serviceCategoryValid && this.salesBucketValid;
        if (valid) {
            this.completeProcessing();
        }
        return valid;
    }

    public getErrorMessageForm(): string {
        return this.INVALID_INFO;
    }

    public valueChange(value, row, name) {
        row.get(name).setValue(value);
    }
}
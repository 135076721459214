import { Component, Input, OnInit } from '@angular/core';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { OopsCategory } from 'src/app/core/components/categories';

@Component({
    selector: 'op-security-search',
    templateUrl: './security-search.component.html'
})
export class SecuritySearchComponent implements OnInit {
    @Input() selectedSecurity: OopsCategory;

    constructor(private actionService: ActionService) { }

    ngOnInit(): void {
        this.actionService.add(FavoriteConstant.SEARCH_ACTION + '/' + this.selectedSecurity?.url?.toUpperCase(), null, null)
    }

    get displayGroupSearch() {
        return this.selectedSecurity?.url == 'GROUP';
    }

    get displayCodeSearch() {
        return this.selectedSecurity?.url == 'CODE';
    }

    get displayBlank() {
        return this.displayGroupSearch || this.displayCodeSearch
    }

}
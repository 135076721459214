import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import {
    ConditionReferenceModel,
    IndividualAgeGroupReferenceModel,
    IndividualSocialTypeReferenceModel,
    UnitReferenceModel,
} from 'src/app/core/models/reference-model/reference-general-model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { Helper } from 'src/app/shared/helper/app.helper';
import { PriceConditionModel, PriceModel } from '../../shared/models';
import { _USER_RUNTIME_CHECKS } from '@ngrx/store/src/tokens';
import { AmountConverter } from './shared/amount.converter';
import { PriceView } from '../../shared/views';
import { TreeListView } from './shared/views';
import { CombinedPriceModel } from '../../shared/models/combined-price.model';
import { Node } from 'devextreme/ui/tree_list';
import { PricingDetailConstant } from '../../../rules/price-rule-detail/shared/pricing-detail.constant';

declare var $: any;

@Component({
    selector: 'op-price-amount',
    templateUrl: './amount.component.html',
    providers: [DateConverterService, AmountConverter],
})
export class AmountComponent implements OnChanges {
    private readonly PRICE_NAME = 'priceName';
    private readonly NUMBER_OF_UNIT = 'numberOfUnit';
    private readonly CONDITION_CODE = 'conditionCode';
    private readonly UNIT_TYPE = 'unitType';
    private readonly INDIVIDUAL_AGE_GROUP = 'individualAgeGroup';
    private readonly SOCIAL_TYPE = 'socialType';
    private readonly UNIT_REFERENCE = 'unitReference';
    private readonly CURRENCY = 'currency';
    private readonly ARITHMETIC_OPERATOR = 'arithmeticOperator';
    private readonly AMOUNT = 'amount';
    private readonly PERCENTAGE = 'percentage';
    private readonly MIN = 'min';
    private readonly MAX = 'max';
    private readonly COST = 'cost';

    @Input() priceView: PriceView;
    @Input() unitReferences: UnitReferenceModel[];
    @Input() individualAgeGroupTypeReferences: IndividualAgeGroupReferenceModel[];
    @Input() individualSocialTypeReferences: IndividualSocialTypeReferenceModel[];
    @Input() conditionReferences: ConditionReferenceModel[];
    @Input() key: string[] = [];
    @Input() priceConditions: PriceConditionModel[];
    @Input() combinedPrice: CombinedPriceModel;
    @Input() disabled: boolean = false;

    @Output() keyChange = new EventEmitter<string[]>();

    @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;

    focusing = false;
    classIcon = this.helper.getClassIcon();
    dxDatagridBorder = 12;
    desiredWidthDxDatagrid = 2200;

    populated = false;

    public treeListData: TreeListView[];

    get tableWidth(): number {
        let panelContentDevWidth = $('#panelContentDiv').width();
        if (panelContentDevWidth - this.desiredWidthDxDatagrid <= this.dxDatagridBorder) {
            return this.desiredWidthDxDatagrid;
        }
        return panelContentDevWidth - this.dxDatagridBorder;
    }

    constructor(private helper: Helper, private amountConverter: AmountConverter) {
        this.customizePercentageField = this.customizePercentageField.bind(this)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['priceView']) {
            this.loadData();
        }
        if (changes['key'] && this.key?.length) {
            let lastKey = this.key[this.key.length - 1];
            if (this.containsAmountData(this.treeListData, lastKey)) {
                this.collapseAll(this.treeListData);
                this.expandToSpecificRow();
            }
        }
    }

    loadData() {
        if (this.priceView) {
            this.treeListData = this.amountConverter.toTreeListView(this.priceView, null, null);
        } else {
            this.treeListData = [];
        }
    }

    private containsAmountData(data: TreeListView[], key: string): boolean {
        return data?.filter(d => d.parentId == this.key[this.key.length - 1])?.some(d => d.priceIndividualId);
    }

    private collapseAll(treeListData: TreeListView[]) {
        for (let data of treeListData) {
            this.treeList.instance.collapseRow(data.Id);
        }
    }

    private expandToSpecificRow() {
        if (this.key.length) {
            for (let key of this.key) {
                this.toggleRow(key);
                this.treeList.instance.navigateToRow(key);
            }
        }
    }

    toggleRow(key) {
        if (this.treeList.instance.isRowExpanded(key)) {
            this.treeList.instance.collapseRow(key);
        } else {
            this.treeList.instance.expandRow(key);
        }
    }

    editorPreparing(options) {
        let disableCell = !options.row?.data?.priceIndividualId;
        disableCell = disableCell || 
            this.amountCellNeedDisable(options.dataField, options.row?.data) ||
            this.percentageCellNeedDisable(options.dataField, options.row?.data);
        if (this.isDisableField(options.dataField) || disableCell) {
            this.disableCell(options);
        } else if (this.isEditableCell(options.dataField)) {
            options.editorElement.keypress(e => {
                let str = String.fromCharCode(e.keyCode);
                if (!/^[0-9.]+$/.test(str)) {
                    e.preventDefault();
                }
            });
        }
    }

    isDisableField(dataField: string): boolean {
        return [
            this.PRICE_NAME,
            this.NUMBER_OF_UNIT,
            this.CONDITION_CODE,
            this.UNIT_TYPE,
            this.INDIVIDUAL_AGE_GROUP,
            this.SOCIAL_TYPE,
            this.UNIT_REFERENCE,
            this.ARITHMETIC_OPERATOR,
            this.CURRENCY,
        ].includes(dataField);
    }

    private disableCell(options: any) {
        options.editorOptions.disabled = true;
        options.editorOptions.readOnly = true;
        if (options.dataField == this.UNIT_TYPE && options.value) {
            options.editorElement.parent().text(this.unitReferences.find(u => u.unitCode == options.value).unitName);
        } else if (
            (options.dataField == this.INDIVIDUAL_AGE_GROUP || options.dataField == this.UNIT_REFERENCE) &&
            options.value
        ) {
            options.editorElement
                .parent()
                .text(
                    this.individualAgeGroupTypeReferences.find(u => u.individualAgeGroupCode == options.value)
                        .individualAgeGroupName
                );
        } else if (options.dataField == this.SOCIAL_TYPE && options.value) {
            options.editorElement
                .parent()
                .text(
                    this.individualSocialTypeReferences.find(u => u.individualSocialTypeCode == options.value)
                        .individualSocialTypeName
                );
        } else if (options.dataField == this.CONDITION_CODE && options.value) {
            options.editorElement
                .parent()
                .text(this.conditionReferences.find(u => u.conditionCode == options.value).conditionName);
        } else {
            options.editorElement.parent().text(options.value);
        }
        options.editorElement.hide();
    }

    cellPrepared(e) {
        if (e.rowType != 'data') {
            return;
        }
        let disableCell = !e.data?.priceIndividualId;
        if (disableCell) {
            e.cellElement.addClass('dx-cell-disabled');
        } else if (this.isEditableCell(e.column.dataField)) {
            if (this.amountCellNeedDisable(e.column.dataField, e.data) || this.percentageCellNeedDisable(e.column.dataField, e.data)) {
                e.cellElement.addClass('dx-cell-disabled');
            } else {
                e.cellElement.addClass('dx-cell-editable');
            }
        }
    }

    private amountCellNeedDisable(dataField: string, data: any) {
        return dataField == this.AMOUNT && data?.percentage != null;
    }

    private percentageCellNeedDisable(dataField: string, data: any) {
        return dataField == this.PERCENTAGE && data?.amount != null;
    }

    isEditableCell(dataField: string): boolean {
        return [this.AMOUNT, this.PERCENTAGE, this.MIN, this.MAX, this.COST].includes(dataField);
    }

    public getValues(price: PriceModel): PriceModel {
        if (!price) {
            return null;
        }
        this.amountConverter.fillAmountData(price, this.treeListData);
        return price;
    }

    getConjunctionWord(cell: any): string {
        if (!this.combinedPrice) {
            return null;
        }
        const node: Node = this.treeList.instance.getNodeByKey(cell.key);
        if (!node) {
            return null;
        }
        if (this.isCurrencyNode(node) == false || node.parent.children.length == 1) {
            return null;
        }

        if (this.isUnderCombinedPrice(node) && this.isFirstAmongChildren(node)) {
            return 'And';
        }
        return 'Or';
    }

    isCurrencyNode(node: Node): boolean {
        return this.combinedPrice.currencyPriceIds.includes(node.key);
    }

    isUnderCombinedPrice(node: Node): boolean {
        let parent = node.parent;
        while (parent) {
            if (parent.key == this.combinedPrice.combinedPriceId) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    isFirstAmongChildren(node: Node): boolean {
        return node.parent.children.indexOf(node) == 0;
    }

    showOrdinal(cell): boolean {
        return cell.data.condition == PricingDetailConstant.LEVEL_CODE && cell.data.numberOfUnits > 0;
    }

    customizePercentageField(e) {
        if (e?.value == null) {
            return "";
        }
        return e.valueText + '%';
    }
}

import { Component, OnInit } from '@angular/core';

import { ThemeService } from '../../../core/services/theme.service';
@Component({
    selector: 'op-theme-switcher',
    templateUrl: 'theme-switcher.component.html'
})
export class ThemeSwitcherComponent implements OnInit {

    constructor(private themeService: ThemeService) {

    }
    ngOnInit(): void {
        this.themeService.loadActiveStyle();
    }

    public toggleChange(styleName: string) {
        this.themeService.changeTheme(styleName);
        return false;
    }
}
import { ElementRef, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import * as settings from 'src/app/store/settings';
import { handleClassCondition, scrollToTop } from '../../../../../../shared/utils/utils.functions';

@Injectable()
export class UIService {

    constructor() {
    }

    public initialFormUI(newProduct: boolean) {
        scrollToTop(150);
        if (newProduct) {
            handleClassCondition(true, 'hidetree', document.querySelector('#div-product-tree'));
            handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-detail'));
            handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-readmode'));
            handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-detail'));
            handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-translation'));
            handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-inv'));
        }
    }

    public setFormEditMode() {
        handleClassCondition(false, 'hidetree', document.querySelector('#div-product-tree'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-detail'));
        handleClassCondition(true, 'hidetree', document.querySelector('#div-showtree'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-detail'));

        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-readmode'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-translation'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-inv'));

        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-readmode'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-translation'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-inv'));
    }

    public findPanelFocus(event, panelName: string): boolean {
        let returnValue = false;
        for (var i = 0; i <= event.path.length - 1; i++) {
            try {
                if (event.path[i].id == panelName) {
                    return true;
                }
            }
            catch (e) {

            }
        }
        return returnValue;
    }

    public hideTree() {
        handleClassCondition(true, 'hidetree', document.querySelector('#div-product-tree'));
        handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-readmode'));
        handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-detail'));
        handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-translation'));
        handleClassCondition(true, 'col-lg-12', document.querySelector('#div-product-inv'));
        handleClassCondition(false, 'hidetree', document.querySelector('#div-showtree'));
    }

    public showTree() {
        handleClassCondition(false, 'hidetree', document.querySelector('#div-product-tree'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-detail'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-readmode'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-translation'));
        handleClassCondition(false, 'col-lg-12', document.querySelector('#div-product-inv'));

        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-detail'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-readmode'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-translation'));
        handleClassCondition(true, 'col-lg-8', document.querySelector('#div-product-inv'));

        handleClassCondition(true, 'hidetree', document.querySelector('#div-showtree'));
    }

    public scrollToPanel(store: Store<any>, panel: ElementRef) {
        store.select(settings.selectSettingsState)
            .subscribe(
                (response) => {
                    if (response.fixedHeader) {
                        window.scrollTo({ top: panel.nativeElement.offsetTop - 20, behavior: 'smooth' });
                    } else {
                        window.scrollTo({ top: panel.nativeElement.offsetTop + 40, behavior: 'smooth' });
                    }
                }
            );
    }

    public findSelect2LastControl(event){
        let returnValue = false;
        for (var i = 0; i <= event.path.length - 1; i++) {
            try {
                if (event.path[i].children[0].hasAttribute('lastfocusctrl')) {
                    return true;
                }
            }
            catch (e) {

            }
        }
        return returnValue;
    }
}
import { NgModule } from "@angular/core";
import { DataGridRowNumberPipe } from "./datagrid-rownumber.pipe";

@NgModule({
    declarations: [
        DataGridRowNumberPipe
    ],
    exports: [
        DataGridRowNumberPipe
    ]
})
export class DataGridRowNumberModule { }
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VoucherViewModel } from 'src/app/core/models/product-model/voucher-model/voucher-view.model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-voucher-advance-search',
    templateUrl: './voucher-advance-search.component.html'
})
export class VoucherAdvanceSearchComponent implements OnInit {
    public readonly errorNameRequired = 'Filter Name is required.';
    public focusing: boolean = false;
    public saveAction: boolean = false;

    public filterName: string = null;
    public publicFilter: boolean = false;
    public voucher: VoucherViewModel;

    @ViewChild('voucherFilterNameForm') voucherFilterNameForm: NgForm;
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    ngOnInit(): void {
        this.clearAdvanceSearchPanel();
    }

    public validate(): boolean {
        this.saveAction = true;
        if (!this.voucherFilterNameForm.valid) {
            return false;
        }
        this.saveAction = false;
        return true;
    }

    public getErrorMessage(): string {
        if (!this.voucherFilterNameForm.controls['filterName'].valid) {
            return this.errorNameRequired;
        }
    }

    public getSaveModel(): VoucherViewModel {
        this.voucher.isOwnerFilter = !this.publicFilter
        return this.voucher;
    }

    public fillAdvanceSearchForm(response: VoucherViewModel, copyAction: boolean = false) {
        let searchFilter = this.newVoucherViewModel;
        searchFilter.productName = copyAction ? `${response.productName} - copy` : response.productName;
        this.publicFilter = !response.isOwnerFilter;
        this.voucher = searchFilter;
    }

    get newVoucherViewModel(): VoucherViewModel {
        return {
            productId: null,
            organisationId: null,
            parentProductId: null,
            productGroupCode: null,
            productGroupName: null,
            productTypeCode: null,
            productTypeName: null,
            supplierId: null,
            supplierName: null,
            providerId: null,
            providerName: null,
            productName: null,
            productDescription: null,
            displayCode: null,
            displayName: null,
            draftFlag: null,
            finalFlag: null,
            filterUserAccountId: null,
            isOwnerFilter: null,
            usageTypeCode: null,
            usageTypeName: null,
            rootProductId: null,
            searchName: null,
            searchUsageTypeCode: null,
            searchStatusCode: null,
            ownerFilterUserAccountId: null,
            statusCode: 'A',
            isHighestLevelAttrInv: null,
            inventoryAttributeLowerLevel: null,
            commitBy: null,
            commitLastname: null,
            commitFirstname: null,
            commitTitle: null,
            commitDateTime: null,
            productHierarchy: null,
            productHashtags: null,
            productNumbers: null,
            productRestrictionProducts: null,
            productRestrictionProductNumbers: null,
            productRestrictionRoutes: null,
            productRestrictionLocations: null,
            productValidities: null,
            productAttributes: null,
            productInventoryAttributeTypes: null,
            productInventoryDimensions: null,
            productInventoryHierarchy: null,
            productRestrictionOrganisations: null,
            languageTranslationSet: null,
            medias: null,
            hierarchyLevel: null,
            productPriceConditions: null
        }
    }

    clearAdvanceSearchPanel() {
        this.voucher = this.newVoucherViewModel;
    }
}
